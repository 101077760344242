import { useCallback } from 'react'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { createNewAccountIcon } from 'components/AccountFields/PersonaliseAccountFields/AccountAvatarField/AccountAvatarField.utils'

import { useGoToCreateAccountStage } from '../../../../../hooks/useGoToCreateAccountStage/useGoToCreateAccountStage'
import { CreateAccountValues } from '../../../AccountNameStage.types'
import { useCreateAccount } from './api/useCreateAccount'

export const useCreateAccountForm = () => {
  const { goToCreateAccountStage } = useGoToCreateAccountStage()

  const { createAccount, isLoading } = useCreateAccount({
    onSuccess: () => {
      showSuccessToast(translate('page.createAccountModal.success'))
      goToCreateAccountStage(undefined)
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  const onCancel = useCallback(() => {
    goToCreateAccountStage(undefined)
  }, [goToCreateAccountStage])

  const handleSubmit = useCallback(
    (values: CreateAccountValues) => {
      const icon = createNewAccountIcon(values)

      createAccount({ name: values.name, icon })
    },
    [createAccount]
  )

  return { handleSubmit, isLoading, onCancel }
}
