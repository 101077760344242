import { useContext, useEffect } from 'react'

import { ProgressBarContext } from '../context/ProgressBar.context'

interface UseProgressBarProps {
  newCurrentStep?: number
  newTotalSteps?: number
  shouldHideProgressBar?: boolean
}

export const useProgressBar = ({
  newCurrentStep,
  newTotalSteps,
  shouldHideProgressBar,
}: UseProgressBarProps = {}) => {
  const progressBarContext = useContext(ProgressBarContext)

  if (progressBarContext === null) {
    throw new Error('useProgressBar must be used within ProgressBarContext')
  }

  const { setProgress, hideProgressBar } = progressBarContext

  useEffect(() => {
    if (newCurrentStep || newTotalSteps) {
      setProgress({ currentStep: newCurrentStep, totalSteps: newTotalSteps })
    }

    if (shouldHideProgressBar) {
      hideProgressBar()
    }
  }, [
    newCurrentStep,
    newTotalSteps,
    setProgress,
    shouldHideProgressBar,
    hideProgressBar,
  ])

  return progressBarContext
}
