import { useTranslations } from '@npco/utils-translations'
import { Box, Message, Spinner } from '@npco/zeller-design-system'

import { selfieVerificationLoaderTranslations } from './SelfieVerificationLoader.i18n'

type SelfieVerificationLoaderProps = {
  isRefetchingToken?: boolean
}

export const SelfieVerificationLoader = ({
  isRefetchingToken = false,
}: SelfieVerificationLoaderProps) => {
  const t = useTranslations(selfieVerificationLoaderTranslations)

  return (
    <Box pt={{ _: '211px', XS: 0 }}>
      <Message
        image={<Spinner dataTestId="selfie-verification-loader" />}
        title={t('title')}
        description={
          isRefetchingToken ? t('delayDescription') : t('description')
        }
      />
    </Box>
  )
}
