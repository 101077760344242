import { rvSelectedAccountTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { padWithNulls } from 'hooks/transactions/useDebitCardTransactionUpdate/useDebitCardTransactionUpdate.utils'
import { DebitCardTransactionUpdate_onDebitCardTransactionUpdate as DebitCardTransactionUpdate } from 'types/gql-types/DebitCardTransactionUpdate'

import {
  DebitCardTransaction,
  PartialTransactionUpdateType,
} from '../debitCardTransactionUpdate.types'
import { useDebitCardTransactionUpdate } from '../useDebitCardTransactionUpdate'

interface UseSelectedTransactionUpdateProps {
  cachedTransactions?: DebitCardTransaction[]
}
export const useSelectedTransactionUpdate = ({
  cachedTransactions = [],
}: UseSelectedTransactionUpdateProps = {}) => {
  const { updateTransactionWithSubscribeData, partialUpdateTransaction } =
    useDebitCardTransactionUpdate({
      cachedTransactions,
    })

  const updateSelectedTransactionWithSubscribeData = (
    updatedData: DebitCardTransactionUpdate,
    onUpdated?: (
      prevTransaction: DebitCardTransaction,
      nextTransaction: DebitCardTransaction
    ) => void
  ) => {
    const selectedTransaction = rvSelectedAccountTransaction()
    if (!selectedTransaction || updatedData.id !== selectedTransaction.id) {
      return
    }

    const newContact = updatedData.contact
      ? {
          ...updatedData.contact,
          category: null,
        }
      : null

    const updatedContact = selectedTransaction.contact
      ? {
          ...selectedTransaction.contact,
          ...updatedData.contact,
        }
      : newContact

    const newMerchant = updatedData.merchant
      ? padWithNulls(updatedData.merchant)
      : null

    const updatedMerchant = selectedTransaction.merchant
      ? {
          ...selectedTransaction.merchant,
          ...updatedData.merchant,
        }
      : newMerchant

    const updatedTransactionWithMerchant: DebitCardTransaction = {
      ...selectedTransaction,
      ...updatedData,
      contact: updatedContact,
      merchant: updatedMerchant,
      __typename: 'DebitCardTransactionV2',
    }

    rvSelectedAccountTransaction(updatedTransactionWithMerchant)
    updateTransactionWithSubscribeData(updatedData, onUpdated)
  }

  const partialUpdateSelectedTransaction = (
    updates: PartialTransactionUpdateType
  ) => {
    const selectedTransaction = rvSelectedAccountTransaction()
    if (!selectedTransaction) {
      return
    }

    const updatedTransactionWithMerchant: DebitCardTransaction = {
      ...selectedTransaction,
      ...updates,
    }

    rvSelectedAccountTransaction(updatedTransactionWithMerchant)
    partialUpdateTransaction(updates, selectedTransaction.id)
  }

  return {
    updateSelectedTransactionWithSubscribeData,
    partialUpdateSelectedTransaction,
  }
}
