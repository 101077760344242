import {
  PERIOD,
  RangeModifierExt,
  usePeriods,
} from '@npco/zeller-design-system'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'

import { capitalise } from 'utils/common'
import { translate } from 'utils/translations'
import {
  StyledLabel,
  StyledPeriodButton,
  StyledPeriodButtonsWrapper,
} from 'components/Filters/NewFilters/DatePicker/DatePicker.styled'

interface CreatePeriodButtonsProps {
  handleSetPeriod: (period: RangeModifierExt, periodName: string) => void
  selectedPeriod: string
}

const buttons = [
  {
    label: translate('component.datePicker.buttonLabels.thisWeek'),
    period: PERIOD.THIS_WEEK,
  },
  {
    label: translate('component.datePicker.buttonLabels.lastWeek'),
    period: PERIOD.LAST_WEEK,
  },
  {
    label: translate('component.datePicker.buttonLabels.thisMonth'),
    period: PERIOD.THIS_MONTH,
  },
  {
    label: translate('component.datePicker.buttonLabels.lastMonth'),
    period: PERIOD.LAST_MONTH,
  },
]
export const PeriodButtons: React.FC<CreatePeriodButtonsProps> = ({
  handleSetPeriod,
  selectedPeriod,
}) => {
  const { periods } = usePeriods()

  return (
    <StyledPeriodButtonsWrapper>
      {buttons.map(({ label, period }) => (
        <StyledPeriodButton
          key={label}
          onClick={() => {
            handleSetPeriod(periods[period], period)
            rvDeferredFilterPayload({
              ...rvDeferredFilterPayload(),
              Date: capitalise(period.split('_').join(' ')),
            })
          }}
          isSelected={selectedPeriod === period}
        >
          <StyledLabel>{label}</StyledLabel>
        </StyledPeriodButton>
      ))}
    </StyledPeriodButtonsWrapper>
  )
}
