import {
  LoaderVariant,
  StyledSpinner,
  StyledSpinnerWrapper,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as EyeCrossedIcon } from 'assets/svg/eye-crossed.svg'
import { translate } from 'utils/translations'
import { MaskedPanContent } from 'components/CardSummary/components/CardDisplay/ViewDetails/MaskedPanContent'

import { MaskedPanText } from './ViewDetails.styled'

interface ViewDetailsContentProps {
  maskedPan: string
  areDetailsDisplayed: boolean
  isHoveredOrFocused: boolean
  isLoading: boolean
}

export const ViewDetailsContent = ({
  maskedPan,
  areDetailsDisplayed,
  isHoveredOrFocused,
  isLoading,
}: ViewDetailsContentProps) => {
  if (isLoading) {
    return (
      <StyledSpinnerWrapper data-testid="card-loader">
        <StyledSpinner loaderVariant={LoaderVariant.Light} />
      </StyledSpinnerWrapper>
    )
  }

  if (areDetailsDisplayed) {
    return (
      <SvgIcon>
        <EyeCrossedIcon />
      </SvgIcon>
    )
  }

  if (isHoveredOrFocused) {
    return (
      <MaskedPanText>
        {translate('component.cardSummary.viewDetails.showDetails')}
      </MaskedPanText>
    )
  }

  return <MaskedPanContent maskedPan={maskedPan} />
}
