import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Unpair Workstation',
  description:
    'Are you sure you want to unpair the Oracle Simphony workstation from {deviceName}?',
  primaryButtonLabel: 'Unpair',
  secondaryButtonLabel: 'Cancel',
  unpairSuccessToast: 'Terminal unpaired from workstation',
  unpairErrorToast: 'Changes could not be saved, please try again.',
})
