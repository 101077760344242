interface Arguments<T> {
  onClose: () => void
  globalData: T
  setGlobalData: (data: T) => void
  resetGlobalData: () => void
  localData: T
  setLocalData: (data: T) => void
  resetLocalData: () => void
  setNoElementsSelected: (areSelected: boolean) => void
}

export const useMobileFilterButtonActions = <T>({
  onClose,
  globalData,
  setGlobalData,
  resetGlobalData,
  localData,
  setLocalData,
  resetLocalData,
  setNoElementsSelected,
}: Arguments<T>) => {
  const onApply = (onInnerClose: () => void) => {
    onInnerClose()
    setGlobalData(localData)
    onClose()
  }

  const onReset = () => {
    resetGlobalData()
    resetLocalData()
    setNoElementsSelected(false)
  }

  const onCancel = (onInnerClose: () => void) => {
    onInnerClose()
    setLocalData(globalData)
    onClose()
  }

  return {
    onApply,
    onReset,
    onCancel,
  }
}
