import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { PortalKYCLayout } from 'pages/PortalKYC/components/PortalKYCLayout'

import { BiometricsOnfidoPageTemplate } from './BiometricsOnfidoPageTemplate'

export const BiometricsOnfidoPageDeps = {
  BiometricsOnfidoPageTemplate,
}

export const BiometricsOnfidoPage = () => {
  const { BiometricsOnfidoPageTemplate } = BiometricsOnfidoPageDeps

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const ctaSaveAndClose = useMemo(
    () => ({
      onClick: () =>
        navigate(ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.SECURITY.path),
    }),
    [navigate, shortEntityId]
  )

  return (
    <PortalKYCLayout ctaSaveAndClose={ctaSaveAndClose}>
      <BiometricsOnfidoPageTemplate
        onSubmitRoute={
          ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.SECURITY.path
        }
        onNoSelectedDocumentRoute={ROOT.ORGS.ORG(shortEntityId).BIOMETRICS.path}
        onChooseDifferentIDRoute={ROOT.ORGS.ORG(shortEntityId).BIOMETRICS.path}
      />
    </PortalKYCLayout>
  )
}
