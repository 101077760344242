import { translate } from 'utils/translations'

import { InProgressDisplay } from '../InProgressDisplay/InProgressDisplay'

interface Props {
  numberOfFilesThatFinished: number
  totalNumberOfFiles: number
}

export const UploadInProgress = ({
  numberOfFilesThatFinished,
  totalNumberOfFiles,
}: Props) => {
  return (
    <InProgressDisplay
      subtext={[
        translate('component.uploadInProgress.description1', {
          fileIndex: numberOfFilesThatFinished + 1,
          totalNumberOfFiles,
        }),
        translate('component.uploadInProgress.description2'),
      ]}
    />
  )
}
