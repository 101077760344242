import styled from 'styled-components'

import { Range } from 'components/Input/Range'

export const StyledRange = styled(Range)`
  margin: 0;
`

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 2.75rem;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_250};
  box-sizing: border-box;
  padding: 0 1rem;
`

export const StyledClearButton = styled.button`
  border: 0;
  appearance: none;
  background: none;
  font: inherit;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  cursor: pointer;
`
