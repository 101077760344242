import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardSingleLayoutDebitCardV2FragmentDoc } from '../../../CardSingleLayout/CardSingleLayout.generated'
import { ZellerCardSummaryDisplayDebitCardV2FragmentDoc } from '../../../DebitCardSummaryDisplay/ZellerCardSummaryDisplay.generated'
import { OutstandingExpensesToggleDebitCardV2FragmentDoc } from '../../../OutstandingExpensesToggle/OutstandingExpensesToggle.generated'

const defaultOptions = {} as const
export type CardSingleQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  cardId: Types.Scalars['ID']['input']
}>

export type CardSingleQueryResponse = {
  __typename?: 'Query'
  getDebitCardV2: {
    __typename: 'DebitCardV2'
    productType: Types.DebitCardProductType | null
    id: string
    outstandingTransactions: number | null
    accessibleProfile: boolean | null
    name: string
    status: Types.DebitCardStatus
    colour: Types.DebitCardColour
    format: Types.DebitCardFormat | null
    maskedPan: string
    debitCardAccount: {
      __typename: 'DebitCardAccountV2'
      id: string
      name: string
      balance: { __typename?: 'Money'; value: string } | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
      } | null
    }
    customer: {
      __typename: 'Customer'
      id: string
      entityUuid: string | null
      firstname: string | null
      lastname: string | null
      icon: {
        __typename?: 'Icon'
        image: string | null
        images: Array<{
          __typename?: 'Image'
          url: string
          size: Types.ImageSize
        }> | null
      } | null
    } | null
    velocityControl: {
      __typename?: 'VelocityControlType'
      velocityWindow: Types.VelocityWindowEnum
      resetsAt: any
      isPendingSubscriptionUpdate: boolean | null
      amountLimit: { __typename?: 'Money'; value: string }
      maxTransactionValue: { __typename?: 'Money'; value: string }
      modifiedBy: {
        __typename?: 'VelocityControlModifiedByType'
        actingCustomerUuid: string
        updatedAt: any
      } | null
      availableAmount: { __typename?: 'Money'; value: string }
      totalSpentAmount: { __typename?: 'Money'; value: string }
    } | null
  }
  getEntity: { __typename: 'Entity'; id: string; canPayByCard: boolean | null }
}

export const CardSingleQuery = gql`
  query CardSingleQuery($entityUuid: ID!, $cardId: ID!) {
    getDebitCardV2(entityUuid: $entityUuid, cardId: $cardId) {
      __typename
      productType
      ...CardSingleLayoutDebitCardV2Fragment
      ...ZellerCardSummaryDisplayDebitCardV2Fragment
      ...OutstandingExpensesToggleDebitCardV2Fragment
    }
    getEntity(entityUuid: $entityUuid) {
      __typename
      id
      canPayByCard
    }
  }
  ${CardSingleLayoutDebitCardV2FragmentDoc}
  ${ZellerCardSummaryDisplayDebitCardV2FragmentDoc}
  ${OutstandingExpensesToggleDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardSingleQueryResponse,
  CardSingleQueryVariables
>

/**
 * __useCardSingleQuery__
 *
 * To run a query within a React component, call `useCardSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardSingleQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardSingleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CardSingleQueryResponse,
    CardSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CardSingleQueryResponse, CardSingleQueryVariables>(
    CardSingleQuery,
    options
  )
}
export function useCardSingleQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CardSingleQueryResponse,
    CardSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CardSingleQueryResponse, CardSingleQueryVariables>(
    CardSingleQuery,
    options
  )
}
export type CardSingleQueryHookResult = ReturnType<typeof useCardSingleQuery>
export type CardSingleQueryLazyQueryHookResult = ReturnType<
  typeof useCardSingleQueryLazyQuery
>
export type CardSingleQueryQueryResult = Apollo.QueryResult<
  CardSingleQueryResponse,
  CardSingleQueryVariables
>
