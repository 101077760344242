import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetCustomerMarketingQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetCustomerMarketingQueryResponse = {
  __typename?: 'Query'
  getCustomerMarketing: {
    __typename?: 'CustomerMarketing'
    banner: string | null
    modal: string | null
  }
}

export const GetCustomerMarketing = gql`
  query GetCustomerMarketing($entityUuid: ID!) {
    getCustomerMarketing(entityUuid: $entityUuid) {
      banner
      modal
    }
  }
` as unknown as TypedDocumentNode<
  GetCustomerMarketingQueryResponse,
  GetCustomerMarketingQueryVariables
>

/**
 * __useGetCustomerMarketingQuery__
 *
 * To run a query within a React component, call `useGetCustomerMarketingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerMarketingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerMarketingQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetCustomerMarketingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerMarketingQueryResponse,
    GetCustomerMarketingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCustomerMarketingQueryResponse,
    GetCustomerMarketingQueryVariables
  >(GetCustomerMarketing, options)
}
export function useGetCustomerMarketingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerMarketingQueryResponse,
    GetCustomerMarketingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomerMarketingQueryResponse,
    GetCustomerMarketingQueryVariables
  >(GetCustomerMarketing, options)
}
export type GetCustomerMarketingQueryHookResult = ReturnType<
  typeof useGetCustomerMarketingQuery
>
export type GetCustomerMarketingLazyQueryHookResult = ReturnType<
  typeof useGetCustomerMarketingLazyQuery
>
export type GetCustomerMarketingQueryResult = Apollo.QueryResult<
  GetCustomerMarketingQueryResponse,
  GetCustomerMarketingQueryVariables
>
