import { useCallback, useMemo } from 'react'
import { gql } from '@apollo/client'
import { Dayjs } from 'dayjs'
import { getIsCurrentTimeAfterOrEqualToResetTime } from 'features/Cards/CardSingle/utils/getEndOfCurrentWindow'

import dayjs from 'utils/dayjs'

import { UseCardProgressDebitCardV2Fragment } from './useCardProgress.generated'

export const useCardProgress = (card?: UseCardProgressDebitCardV2Fragment) => {
  const velocityControl = useMemo(() => card?.velocityControl, [card])
  const getAvailableFunds = useCallback(
    (now: Dayjs) => {
      if (!velocityControl) {
        return 0
      }

      if (
        getIsCurrentTimeAfterOrEqualToResetTime(velocityControl.resetsAt, now)
      ) {
        return parseInt(velocityControl.amountLimit.value, 10)
      }

      return parseInt(velocityControl.availableAmount.value, 10)
    },
    [velocityControl]
  )

  return useMemo(() => {
    return {
      availableAmount: getAvailableFunds(dayjs()),
      totalAmount: parseInt(velocityControl?.amountLimit?.value ?? '0', 10),
    }
  }, [velocityControl, getAvailableFunds])
}

useCardProgress.fragments = {
  DebitCardV2: gql`
    fragment UseCardProgressDebitCardV2Fragment on DebitCardV2 {
      velocityControl {
        resetsAt
        availableAmount {
          value
        }
        amountLimit {
          value
        }
      }
    }
  `,
}
