import { type ReactNode, type SyntheticEvent } from 'react'
import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  COLOR,
  Flex,
  PageTemplate,
  Popper,
  PopperItem,
  RenderTriggerRenderProps,
  SelectStyle,
} from '@npco/zeller-design-system'

import { ReactComponent as DotsVerticalIcon } from 'assets/svg/dots-vertical.svg'
import { ReactComponent as PlaneIcon } from 'assets/svg/plane.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translationsShared } from 'translations'

import { translations } from './InvoiceLayout.i18n'
import {
  IconButton,
  PrimaryButton,
  SecondaryButton,
  TitleEllipsis,
} from './InvoiceLayout.styled'

export interface InvoiceLayoutProps {
  HeaderMobileRow?: ReactNode
  Title: ReactNode
  children: ReactNode
  isLoadingPreview?: boolean
  isSavingInvoice?: boolean
  isSendingInvoice?: boolean
  isScheduled?: boolean
  onClickPreview: (event: SyntheticEvent) => void
  onClickClose: (event: SyntheticEvent) => void
  onClickSave: (event: SyntheticEvent) => void
  onClickSend: (event: SyntheticEvent) => void
  onClickSettings: (event: SyntheticEvent) => void
}

const handlePopperItemClick =
  (setIsOpen: RenderTriggerRenderProps['setIsOpen']) =>
  (callback: (event: SyntheticEvent) => void) =>
  (event: SyntheticEvent) => {
    callback(event)
    setIsOpen(false)
  }

export const InvoiceLayout = ({
  HeaderMobileRow,
  Title,
  children,
  isLoadingPreview,
  isSavingInvoice,
  isSendingInvoice,
  isScheduled,
  onClickPreview: handleClickPreview,
  onClickClose: handleClickClose,
  onClickSave: handleClickSave,
  onClickSend: handleClickSend,
  onClickSettings: handleClickSettings,
}: InvoiceLayoutProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  const primaryButtonText = isScheduled ? t('schedule') : t('send')
  const moreOptionsButtonText = t('moreOptions')

  const handleRenderTrigger = useCallback(
    ({ toggleOpen }) => (
      <IconButton
        aria-label={moreOptionsButtonText}
        className="invoice-more-options"
        dataTestId="invoice-more-options"
        disabled={isSendingInvoice}
        icon={DotsVerticalIcon}
        iconEnabledColor={COLOR.BLUE_1000}
        isLoading={isSavingInvoice || isLoadingPreview}
        onClick={toggleOpen}
      />
    ),
    [isSendingInvoice, isSavingInvoice, isLoadingPreview, moreOptionsButtonText]
  )

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex
            alignItems="center"
            flex={1}
            gridGap="8px"
            maxWidth="100%"
            overflowX={{ _: 'initial', SM: 'clip' }}
          >
            <TitleEllipsis>{Title}</TitleEllipsis>
            {isMobileOrTablet ? (
              <>
                <Popper
                  menuStyle={SelectStyle.Standard}
                  popperWidth="158px"
                  renderTrigger={handleRenderTrigger}
                >
                  {({ setIsOpen }) => {
                    const handleClick = handlePopperItemClick(setIsOpen)
                    return (
                      <>
                        <PopperItem
                          onClick={handleClick(handleClickSettings)}
                          title={t('settings')}
                        />
                        <PopperItem
                          onClick={handleClick(handleClickPreview)}
                          title={t('preview')}
                        />
                        <PopperItem
                          onClick={handleClick(handleClickClose)}
                          title={tShared('close')}
                        />
                        <PopperItem
                          title={tShared('save')}
                          onClick={handleClick(handleClickSave)}
                        />
                      </>
                    )
                  }}
                </Popper>

                <IconButton
                  aria-label={primaryButtonText}
                  className="invoice-send"
                  dataTestId="invoice-send-icon"
                  disabled={isSavingInvoice || isLoadingPreview}
                  icon={PlaneIcon}
                  isLoading={isSendingInvoice}
                  onClick={handleClickSend}
                />
              </>
            ) : (
              <>
                <SecondaryButton
                  className="invoice-settings"
                  dataTestId="invoice-settings"
                  disabled={
                    isSavingInvoice || isSendingInvoice || isLoadingPreview
                  }
                  onClick={handleClickSettings}
                >
                  {t('settings')}
                </SecondaryButton>
                <SecondaryButton
                  className="invoice-preview"
                  dataTestId="invoice-preview"
                  disabled={isSavingInvoice || isSendingInvoice}
                  isLoading={isLoadingPreview}
                  onClick={handleClickPreview}
                >
                  {t('preview')}
                </SecondaryButton>
                <SecondaryButton
                  className="invoice-close"
                  dataTestId="invoice-close"
                  disabled={
                    isSavingInvoice || isSendingInvoice || isLoadingPreview
                  }
                  onClick={handleClickClose}
                >
                  {tShared('close')}
                </SecondaryButton>
                <SecondaryButton
                  className="invoice-save"
                  dataTestId="invoice-save"
                  disabled={isSendingInvoice || isLoadingPreview}
                  isLoading={isSavingInvoice}
                  onClick={handleClickSave}
                >
                  {tShared('save')}
                </SecondaryButton>
                <PrimaryButton
                  className="invoice-send"
                  dataTestId="invoice-send"
                  disabled={isSavingInvoice || isLoadingPreview}
                  isLoading={isSendingInvoice}
                  onClick={handleClickSend}
                >
                  {primaryButtonText}
                </PrimaryButton>
              </>
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderMobileRow={HeaderMobileRow}
    >
      {children}
    </PageTemplate>
  )
}
