import { Box, Flex, StyledErrorBox } from '@npco/zeller-design-system'
import { Field, FieldProps, useFormikContext } from 'formik'

import { useHitEnter } from 'hooks/useHitEnter'
import { translate } from 'utils/translations'
import { SegmentedInput } from 'components/SegmentedInput'

interface ValidateCodeFormProps {
  hasError: boolean
}

export const ValidateCodeForm = ({ hasError }: ValidateCodeFormProps) => {
  const { isValid, submitForm } = useFormikContext()

  useHitEnter(submitForm)

  return (
    <Box mb="2.5rem">
      <Flex>
        <Field name="code">
          {({ field, form }: FieldProps<string | undefined>) => (
            <SegmentedInput.PIN
              name={field.name}
              value={field.value}
              onValue={(value) => form.setFieldValue(field.name, value)}
              inputProps={{
                autoFocus: true,
              }}
            />
          )}
        </Field>
      </Flex>
      {(hasError || !isValid) && (
        <StyledErrorBox data-testid="error-field">
          {translate('errorMessages.invalidVerificationCode')}
        </StyledErrorBox>
      )}
    </Box>
  )
}
