import { ReactNode, useCallback } from 'react'
import {
  Box,
  Flex,
  TooltipBasic,
  TriggerProps,
} from '@npco/zeller-design-system'

import { IconCounter, IconCounterToolTip } from './IconCounterCell.styled'

interface IconCounterCellProps {
  count?: number
  icon?: ReactNode
  tooltips?: string[]
}

export const IconCounterCell = ({
  count,
  icon,
  tooltips,
}: IconCounterCellProps) => {
  const tooltipLines = tooltips?.slice(0, 3)

  const renderTrigger = useCallback(
    ({ ref, handlers }: TriggerProps<HTMLElement>) => (
      <Flex
        width={100}
        height={[20, 24]}
        margin="auto"
        ref={ref as React.RefObject<HTMLDivElement>}
        {...handlers}
      >
        <Flex width={20} alignItems="center">
          {icon}
        </Flex>
        <Flex width={48}>
          <IconCounter>{count}</IconCounter>
        </Flex>
      </Flex>
    ),
    [count, icon]
  )

  if (count === undefined || count <= 0) {
    return <Flex width={68} height={20} />
  }

  return (
    <TooltipBasic
      isDisabled={tooltips === undefined || tooltips.length === 0}
      placement="top-start"
      renderTrigger={renderTrigger}
      showArrow={false}
    >
      <Box maxWidth={100} textAlign="center">
        {tooltipLines?.map((line) => (
          <IconCounterToolTip key={`${line}`}>{line}</IconCounterToolTip>
        ))}
      </Box>
    </TooltipBasic>
  )
}
