import { useTranslations } from '@npco/utils-translations'
import { HelpBuildYourBusiness, Message } from '@npco/zeller-design-system'

import { noBusinessResultTranslations } from './NoBusinessResult.i18'

export const NoBusinessResult = () => {
  const t = useTranslations(noBusinessResultTranslations)

  return (
    <Message
      title={t('title')}
      description={t('description')}
      image={<HelpBuildYourBusiness size="large" />}
    />
  )
}
