import { Flex } from '@npco/zeller-design-system'

import { CopyButtonWithTooltip } from '../../../../../../components/CopyButtonWithTooltip'
import { CardRow } from '../CardRow/CardRow'
import {
  AccountDetailTitle,
  AccountDetailValue,
} from './AccountDetailsRow.styled'

interface AccountDetailsRowProps {
  title: string
  value: string
  valueToCopy: string
}

export const AccountDetailsRow = ({
  title,
  value,
  valueToCopy,
}: AccountDetailsRowProps) => {
  return (
    <Flex flexDirection={{ _: 'column', LG: 'row' }}>
      <AccountDetailTitle>{title}</AccountDetailTitle>
      <CardRow>
        <AccountDetailValue>{value} </AccountDetailValue>
        <CopyButtonWithTooltip valueToCopy={valueToCopy} />
      </CardRow>
    </Flex>
  )
}
