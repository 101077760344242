import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  MarkInvoiceAsPaid as MarkInvoiceAsPaidResponse,
  MarkInvoiceAsPaidVariables,
} from 'types/gql-types/MarkInvoiceAsPaid'

import { MarkInvoiceAsPaid } from '../../graphql/markInvoiceAsPaid'

export const useMarkInvoiceAsPaid = () => {
  const entityUuid = useSelectedEntityUuid()
  const [markInvoiceAsPaid, { loading: isMarkingAsPaid }] = useMutation<
    MarkInvoiceAsPaidResponse,
    MarkInvoiceAsPaidVariables
  >(MarkInvoiceAsPaid)

  return {
    markInvoiceAsPaid: (referenceNumber: string) =>
      markInvoiceAsPaid({
        variables: {
          entityUuid,
          referenceNumber,
        },
      }),
    isMarkingAsPaid,
  }
}
