import { getBasePoints, getPercentageValue } from 'utils/numbers'
import { CardSurcharge } from 'types/settings'

import { GetZellerInvoiceSettingsQueryResponse } from '../graphql/getZellerInvoiceSettings.generated'
import {
  ADDRESS_POSTCODE_FIELD,
  ADDRESS_STATE_FIELD,
  ADDRESS_STREET_FIELD,
  ADDRESS_SUBURB_FIELD,
  BCC_COPY_FIELD,
  BCC_EMAIL_FIELD,
  DEFAULT_SEND_BY_EMAIL_FIELD,
  DEFAULT_SEND_BY_SMS_FIELD,
  DISCOUNT_FIELD,
  LOGO,
  LOGO_FILE,
  PDF_INCLUDE_ADDRESS_FIELD,
  SURCHARGE_ENABLED_FIELD,
  SURCHARGE_PERCENT_FIELD,
  SURCHARGE_TYPE_FIELD,
} from './SettingsGeneral.constants'
import { InvoiceSettingsGeneralFormFields } from './SettingsGeneral.types'

export const convertInvoiceSettingsToFormFields = (
  values: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']
): InvoiceSettingsGeneralFormFields => ({
  [ADDRESS_POSTCODE_FIELD]: values?.address?.postcode || '',
  [ADDRESS_STATE_FIELD]: values?.address?.state || '',
  [ADDRESS_STREET_FIELD]: values?.address?.street || '',
  [ADDRESS_SUBURB_FIELD]: values?.address?.suburb || '',
  [BCC_COPY_FIELD]: values.invoice.sendBccCopy || false,
  [BCC_EMAIL_FIELD]: values.invoice.bccEmail || '',
  [DEFAULT_SEND_BY_EMAIL_FIELD]: values.invoice.sendEmailByDefault || false,
  [DEFAULT_SEND_BY_SMS_FIELD]: values.invoice.sendSmsByDefault || false,
  [DISCOUNT_FIELD]: values?.invoice.discountsEnabled || false,
  [LOGO]: values?.receipt?.logo ?? '',
  [LOGO_FILE]: undefined,
  [PDF_INCLUDE_ADDRESS_FIELD]: values.invoice.pdfIncludesAddress || false,
  [SURCHARGE_ENABLED_FIELD]:
    values?.surchargesTaxes?.surchargeEnabledCnp ?? false,
  [SURCHARGE_PERCENT_FIELD]:
    getPercentageValue(values?.surchargesTaxes?.surchargePercentCnp) ?? 0,
  [SURCHARGE_TYPE_FIELD]: values?.surchargesTaxes?.surchargeFullFeesCnp
    ? CardSurcharge.TotalFees
    : CardSurcharge.Percentage,
})

export const convertGeneralFormFieldsToGeneralSettingsInput = (
  values: InvoiceSettingsGeneralFormFields
) => ({
  address: {
    street: values.street,
    suburb: values.suburb,
    state: values.state,
    postcode: values.postcode,
  },
  invoice: {
    sendEmailByDefault: values.defaultSendByEmail,
    sendSmsByDefault: values.defaultSendBySMS,
    discountsEnabled: values.discountsEnabled,
    bccEmail: values.bccEmail,
    pdfIncludesAddress: values.pdfIncludesAddress,
    sendBccCopy: values.sendBccCopy,
  },
  surchargesTaxes: {
    surchargePercentCnp: getBasePoints(values.surchargePercentCnp) ?? null,
    surchargeEnabledCnp: values.surchargeEnabledCnp ?? null,
    surchargeFullFeesCnp: values.surchargeType === CardSurcharge.TotalFees,
  },
})
