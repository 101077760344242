import { LabelType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { errorMessages, successMessages } from 'translations'

import { useUpdateLabelMutation } from '../../graphql/updateLabel.generated'
import { GetLabel } from '../../LabelControl.types'
import { rvBusinessLabels, rvPersonLabels } from '../../LabelControl.utils'

export const useUpdateLabel = () => {
  const entityUuid = useSelectedEntityUuid()

  const [updateLabel, { loading: isUpdatingLabel }] = useUpdateLabelMutation({
    onError: () => showErrorToast(errorMessages.updateLabelError),
  })

  return {
    isUpdatingLabel,
    updateLabel: async (label: GetLabel): Promise<boolean> => {
      const { id, labelText, type } = label

      const response = await updateLabel({
        variables: {
          id,
          labelText,
          entityUuid,
        },
      })

      const hasErrors = Boolean(response.errors)
      const result = Boolean(response.data?.updateLabel)

      if (result) {
        if (type === LabelType.BUSINESS) {
          rvBusinessLabels(
            rvBusinessLabels().map((item) =>
              item.id === label.id ? label : item
            )
          )
        } else {
          rvPersonLabels(
            rvPersonLabels().map((item) =>
              item.id === label.id ? label : item
            )
          )
        }
        showSuccessToast(successMessages.updateLabelSuccess)
      } else if (!result && !hasErrors) {
        showErrorToast(errorMessages.updateLabelError)
      }

      return result
    },
  }
}
