import { useNavigate } from 'react-router-dom-v5-compat'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Reports } from 'assets/svg/reports-alt.svg'
import {
  StyledIconWrapper,
  StyledStatementsButton,
  StyledText,
} from 'components/Statements/StatementsButtons/StatementsButton/StatementsButton.styled'
import { page } from 'translations'

interface Props {
  route: string
}

export const StatementsButton = ({ route }: Props) => {
  const navigate = useNavigate()
  return (
    <StyledStatementsButton
      onClick={() => navigate(route)}
      data-testid="reports-button"
    >
      <StyledText>{page.settings.statements.statements}</StyledText>
      <StyledIconWrapper>
        <SvgIcon height="16" width="16" color={COLOR.BLUE_1000}>
          <Reports />
        </SvgIcon>
      </StyledIconWrapper>
    </StyledStatementsButton>
  )
}
