import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type TransferRemittanceNotificationMutationVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  input: Types.TransferRemittanceNotificationInput
}>

export type TransferRemittanceNotificationMutationResponse = {
  __typename?: 'Mutation'
  transferRemittanceNotification: {
    __typename?: 'TransferRemittanceNotificationResponse'
    error: Types.TransferRemittanceErrorEnum | null
    isTransferRemittanceQueued: boolean
  }
}

export const TransferRemittanceNotification = gql`
  mutation TransferRemittanceNotification(
    $entityUuid: ID!
    $input: TransferRemittanceNotificationInput!
  ) {
    transferRemittanceNotification(entityUuid: $entityUuid, input: $input) {
      error
      isTransferRemittanceQueued
    }
  }
` as unknown as TypedDocumentNode<
  TransferRemittanceNotificationMutationResponse,
  TransferRemittanceNotificationMutationVariables
>
export type TransferRemittanceNotificationMutationFn = Apollo.MutationFunction<
  TransferRemittanceNotificationMutationResponse,
  TransferRemittanceNotificationMutationVariables
>

/**
 * __useTransferRemittanceNotificationMutation__
 *
 * To run a mutation, you first call `useTransferRemittanceNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferRemittanceNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferRemittanceNotificationMutation, { data, loading, error }] = useTransferRemittanceNotificationMutation({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferRemittanceNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferRemittanceNotificationMutationResponse,
    TransferRemittanceNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TransferRemittanceNotificationMutationResponse,
    TransferRemittanceNotificationMutationVariables
  >(TransferRemittanceNotification, options)
}
export type TransferRemittanceNotificationMutationHookResult = ReturnType<
  typeof useTransferRemittanceNotificationMutation
>
export type TransferRemittanceNotificationMutationResult =
  Apollo.MutationResult<TransferRemittanceNotificationMutationResponse>
export type TransferRemittanceNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    TransferRemittanceNotificationMutationResponse,
    TransferRemittanceNotificationMutationVariables
  >
