import { H6 } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'

import { StyledHeader } from '../../ReportsChart.styled'
import { getFormattedDateRange } from './TooltipContent.utils'

interface Props {
  label?: number
  value?: number
}

export const TooltipContent = ({ label, value }: Props) => {
  if (label === undefined || value === undefined) {
    return null
  }

  return (
    <div data-testid="tooltip-content">
      <StyledHeader data-testid="tooltip-content-header">
        {getFormattedDateRange(label)}
      </StyledHeader>
      <H6 as="div">{currencyFormatter(value)}</H6>
    </div>
  )
}
