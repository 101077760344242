import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledContainer = styled.div<{
  isSideBarOpen: boolean
  isFullWidth?: boolean
}>`
  display: flex;
  height: 100%;
  ${(props) => props.isFullWidth && 'width: 100%;'}
  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    ${(props) => props.isSideBarOpen && 'flex-direction: column;'}
  }
`

export const StyledAside = styled.div<{ isRightSide: boolean }>`
  display: flex;
  overflow: auto;
  flex: 0 0 auto;
  height: 100%;
  ${(props) => props.isRightSide && 'order: 2;'}
`

export const StyledMain = styled.div<{
  isRightSide: boolean
}>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    width: 100%;
    ${(props) => props.isRightSide && 'order: 1;'}
  }
`
