import { ConnectionErrorType, ConnectionStatus } from '@npco/mp-gql-types'

import { useXeroPaymentServices } from './useXeroPaymentServices'

export const useXeroPaymentServicesError = () => {
  const { xeroPaymentServices, isLoading } = useXeroPaymentServices()

  const hasErrorInPaymentService =
    xeroPaymentServices?.status === ConnectionStatus.CONNECTED_WITH_ERROR
  const isTokenExpired =
    xeroPaymentServices?.errorType === ConnectionErrorType.TOKEN_EXPIRED

  const hasAuthErrorInPaymentService =
    hasErrorInPaymentService && isTokenExpired

  return {
    isXeroPaymentServicesLoading: isLoading,
    hasErrorInPaymentService,
    hasAuthErrorInPaymentService,
  }
}
