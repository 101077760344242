import { ensureIsArray } from 'utils/common'

import { PlaceholderPage } from '../PlaceholderPage/PlaceholderPage'
import { PlaceholderSize } from '../Placeholders.types'
import {
  getDescriptionBasedOnVariant,
  getTitleBasedOnVariant,
} from '../Placeholders.utils'
import { StyledTextContainer } from '../SharedElements.styled'

interface Props {
  description: string | string[]
  icon: React.ReactNode
  title?: string
  children?: React.ReactNode
}

export const PlaceHolder = ({ title, description, icon, children }: Props) => {
  const variant = PlaceholderSize.Large
  const StyledTitleVariant = getTitleBasedOnVariant(variant)
  const StyledDescriptionVariant = getDescriptionBasedOnVariant(variant)

  const stackedDescriptions = ensureIsArray(description)

  return (
    <PlaceholderPage>
      {icon}
      <StyledTextContainer>
        {title && <StyledTitleVariant>{title}</StyledTitleVariant>}
        {stackedDescriptions.map((desc) => (
          <StyledDescriptionVariant key={desc}>{desc}</StyledDescriptionVariant>
        ))}
      </StyledTextContainer>
      {children}
    </PlaceholderPage>
  )
}
