import { ReactNode } from 'react'
import { Flex } from '@npco/zeller-design-system'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { translate } from 'utils/translations'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
} from 'components/Modal/ModalElements/ModalElements'

interface ModalConfirmProps {
  dataTestId?: string
  description: string
  isLoading?: boolean
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  title: ReactNode
}

export const ModalConfirm = ({
  dataTestId,
  description,
  isLoading = false,
  isOpen,
  onCancel,
  onConfirm,
  title,
}: ModalConfirmProps) => {
  return (
    <ModalBasic
      isOpen={isOpen}
      onCancel={onCancel}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      testId={dataTestId}
      title={title}
    >
      <>
        <ModalDescription>{description}</ModalDescription>
        <Flex>
          <CancelButton disabled={isLoading} onClick={onCancel}>
            {translate('shared.cancel')}
          </CancelButton>
          <ConfirmButton isLoading={isLoading} onClick={onConfirm}>
            {translate('shared.confirm')}
          </ConfirmButton>
        </Flex>
      </>
    </ModalBasic>
  )
}
