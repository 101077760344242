import { BodyDefault, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledGoToContactsTitle = styled(H3)`
  margin: 0;
`

export const StyledGoToContactsDescription = styled(BodyDefault)`
  margin: 0.75rem 0 1rem 0;
`
