import { EntityType } from '@npco/mp-gql-types'

import { form, page } from 'translations'

import {
  StyledListCustomersMainText,
  StyledListCustomersSubText,
  StyledListCustomersSubTextExtension,
} from '../ListCustomers.styled'
import {
  ListCustomersItem,
  Props as ListCustomerItemProps,
} from '../ListCustomersItem'

type Props = Pick<ListCustomerItemProps, 'onEdit' | 'onRemove' | 'customer'>

const subtTextMap = {
  [EntityType.COMPANY]: form.addBeneficialOwner.ownerType.company,
  [EntityType.TRUST]: form.addBeneficialOwner.ownerType.trust,
}

export const ListCustomersCompanyTrustOwnerItem = ({
  customer,
  onEdit,
  onRemove,
}: Props) => {
  const subText = customer.type
    ? subtTextMap[customer.type as EntityType.COMPANY | EntityType.TRUST]
    : undefined

  return (
    <ListCustomersItem
      data-testid="non-individual-owner-item-list"
      customer={customer}
      onEdit={onEdit}
      onRemove={onRemove}
      removeModalTitle={
        customer.partner
          ? page.onboarding.partners.modalTitle
          : page.onboarding.beneficialOwners.modalTitle
      }
      removeModalWarning={
        customer.partner
          ? page.onboarding.partners.modalDescription
          : page.onboarding.beneficialOwners.modalDescription
      }
      letterToDisplay={customer.companyTrustName?.charAt(0)}
    >
      <StyledListCustomersMainText
        data-testid={`main-text-${customer.temporaryId}`}
      >
        {customer.companyTrustName}
      </StyledListCustomersMainText>

      {subText && (
        <StyledListCustomersSubText>
          <StyledListCustomersSubTextExtension
            data-testid={`sub-text-extension-${customer.temporaryId}`}
            hasDot={false}
          >
            {subText}
          </StyledListCustomersSubTextExtension>
        </StyledListCustomersSubText>
      )}
    </ListCustomersItem>
  )
}
