import { gql, type TypedDocumentNode } from '@apollo/client'

import {
  GetAbnContactFragmentDoc,
  GetAbnMerchantDetailsFragmentDoc,
  GetEmailContactFragmentDoc,
  GetEmailMerchantDetailsFragmentDoc,
  GetWebsiteContactFragmentDoc,
  GetWebsiteMerchantDetailsFragmentDoc,
} from './MerchantSecondaryDetailsItems.utils.generated'

export type MerchantSecondaryDetailsItemsMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  email: string | null
  url: string | null
  abn: string | null
}

export type MerchantSecondaryDetailsItemsContactFragment = {
  __typename?: 'Contact'
  website: string | null
  abn: string | null
  email: { __typename?: 'ContactEmail'; email: string } | null
}

export const MerchantSecondaryDetailsItemsMerchantDetailsFragmentDoc = gql`
  fragment MerchantSecondaryDetailsItemsMerchantDetailsFragment on MerchantDetails {
    ...GetEmailMerchantDetailsFragment
    ...GetWebsiteMerchantDetailsFragment
    ...GetAbnMerchantDetailsFragment
  }
  ${GetEmailMerchantDetailsFragmentDoc}
  ${GetWebsiteMerchantDetailsFragmentDoc}
  ${GetAbnMerchantDetailsFragmentDoc}
` as unknown as TypedDocumentNode<
  MerchantSecondaryDetailsItemsMerchantDetailsFragment,
  undefined
>
export const MerchantSecondaryDetailsItemsContactFragmentDoc = gql`
  fragment MerchantSecondaryDetailsItemsContactFragment on Contact {
    ...GetEmailContactFragment
    ...GetWebsiteContactFragment
    ...GetAbnContactFragment
  }
  ${GetEmailContactFragmentDoc}
  ${GetWebsiteContactFragmentDoc}
  ${GetAbnContactFragmentDoc}
`
