import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type UseStartupModalsConnectionFragment = {
  __typename?: 'Connection'
  status: Types.ConnectionStatus | null
}

export type UseStartupModalsEntitySavingsAccountProductFragment = {
  __typename?: 'EntitySavingsAccountProduct'
  id: string | null
}

export const UseStartupModalsConnectionFragmentDoc = gql`
  fragment UseStartupModalsConnectionFragment on Connection {
    status
  }
` as unknown as TypedDocumentNode<UseStartupModalsConnectionFragment, undefined>
export const UseStartupModalsEntitySavingsAccountProductFragmentDoc = gql`
  fragment UseStartupModalsEntitySavingsAccountProductFragment on EntitySavingsAccountProduct {
    id
  }
`
