import {
  BodyDefault,
  Box,
  COLOR,
  Flex,
  SvgIcon,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ReactComponent as ErrorIcon } from 'assets/svg/error.svg'

export enum NotificationVariant {
  SUCCESS,
  WARNING,
  ERROR,
}

const VARIANT_COLOR_MAP = {
  [NotificationVariant.SUCCESS]: COLOR.GREEN_1000,
  [NotificationVariant.WARNING]: COLOR.YELLOW_1000,
  [NotificationVariant.ERROR]: COLOR.RED_1000,
}

export const StyledNotification = styled.div`
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.colors.GREY_30};
  border: 1px solid ${({ theme }) => theme.colors.GREY_30};
  border-radius: 7px;
`

export const StyledText = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK};
`

interface NotificationProps {
  children: string | (string | JSX.Element)[]
  className?: string
  variant?: NotificationVariant
}

export const Notification = ({
  children,
  className,
  variant,
}: NotificationProps) => (
  <StyledNotification className={className}>
    <Flex alignItems="start" data-testid="notification">
      <Flex padding="8px">
        <SvgIcon
          width="16"
          height="16"
          backgroundColor={
            variant !== undefined ? VARIANT_COLOR_MAP[variant] : undefined
          }
        >
          <ErrorIcon />
        </SvgIcon>
      </Flex>
      <Box paddingLeft="0.5rem">
        <StyledText>{children}</StyledText>
      </Box>
    </Flex>
  </StyledNotification>
)
