import {
  Flex,
  PopperItemOneLineLabel,
  PopperItemOneLineWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Filters = styled(Flex)`
  flex-flow: column nowrap;
  flex-grow: 1;

  ${PopperItemOneLineWrapper} {
    ${PopperItemOneLineLabel} {
      display: block;
      position: absolute;
      left: 48px;
    }
    &:focus,
    :hover {
      background: none;
      color: ${({ theme }) => theme.colors.BLACK};
      svg {
        path {
          fill: ${({ theme }) => theme.colors.BLACK};
        }
      }
    }
  }
`
