import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import {
  CashFlowReportType,
  DebitCardAccountType,
  TransactionDirection,
} from '@npco/mp-gql-types'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  ButtonLink,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { useCashflowReportingTransactionsContext } from '../../hooks/useCashflowReportingTransactionsContext'
import { useSelectedDateRange } from '../../hooks/useSelectedDateRange'
import { InverseIncomeOrExpenseMessage } from '../../InverseIncomeOrExpenseMessage'
import { convertToAWSDate } from '../../utils/convertToAWSDate/convertToAWSDate'
import { IncomeExpenseTabs } from '../IncomeExpenseTabs'
import * as styled from '../TransactionsSummary.styled'
import { ContactsBanner } from './ContactsBanner/ContactsBanner'
import { ContactsChart } from './ContactsChart/ContactsChart'
import { ContactsTable } from './ContactsTable/ContactsTable'
import { useCashflowContactsNetAmounts } from './hooks/useCashflowContactsNetAmounts'
import { translations } from './TransactionsSummaryContact.i18n'
import { HoveredContactNetAmount } from './TransactionsSummaryContact.types'

interface TransactionsSummaryContactProps {
  timezone?: string
  accountUuid?: string
  isLoadingTimezone: boolean
  accountType?: DebitCardAccountType
  setContactUuid: React.Dispatch<React.SetStateAction<string | null>>
}

export const TransactionsSummaryContact = ({
  timezone,
  accountUuid,
  isLoadingTimezone,
  setContactUuid,
  accountType,
}: TransactionsSummaryContactProps) => {
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const entityUuid = useSelectedEntityUuid()
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)
  const isAccountSaving = accountType === DebitCardAccountType.SAVINGS

  const [hoveredContactNetAmount, setHoveredContactNetAmount] =
    useState<HoveredContactNetAmount>()

  const { incomeOrExpenseSelection } = useCashflowReportingTransactionsContext()
  const isOnIncomeTab = useMemo(
    () => incomeOrExpenseSelection === 'income',
    [incomeOrExpenseSelection]
  )

  const { selectedDateRange } = useSelectedDateRange()

  const {
    isLoading: isLoadingContactNetAmounts,
    contactNetAmounts,
    error,
    refetch,
  } = useCashflowContactsNetAmounts({
    skip: isLoadingTimezone || isAccountSaving,
    variables: {
      entityUuid,
      timeZone: timezone,
      reportType:
        selectedDateRange === 'Last 12 Months' ||
        selectedDateRange.type === 'TTM'
          ? CashFlowReportType.TTM
          : CashFlowReportType.MONTHLY,
      date: convertToAWSDate(selectedDateRange, timezone),
      accountUuid,
      transactionDirection: isOnIncomeTab
        ? TransactionDirection.INCOME
        : TransactionDirection.EXPENSE,
    },
  })

  const isLoading = useMemo(
    () => isLoadingTimezone || isLoadingContactNetAmounts,
    [isLoadingTimezone, isLoadingContactNetAmounts]
  )

  const netAmounts = useMemo(
    () => contactNetAmounts.slice(0, 10),
    [contactNetAmounts]
  )

  const hasInverseNetAmount = useMemo(() => {
    return netAmounts.some((netAmount) =>
      isOnIncomeTab
        ? parseInt(netAmount.total.value, 10) < 0
        : parseInt(netAmount.total.value, 10) > 0
    )
  }, [netAmounts, isOnIncomeTab])

  const CONTACT_PATH = useMemo(
    () => `/orgs/${shortEntityId}/contacts/businesses`,
    [shortEntityId]
  )

  return (
    <>
      <styled.TransactionsSummaryContentLayout>
        <styled.TransactionsSummaryContentLayoutTable>
          <ContactsTable
            netAmounts={netAmounts}
            isLoading={isLoading}
            transactionDirection={incomeOrExpenseSelection}
            setContactUuid={setContactUuid}
            onNetAmountHovered={setHoveredContactNetAmount}
            onNetAmountUnhovered={() => setHoveredContactNetAmount(undefined)}
          />
          {netAmounts.length > 0 && (
            <ButtonLink onClick={() => navigate(CONTACT_PATH)}>
              {t('viewAll')}
            </ButtonLink>
          )}
        </styled.TransactionsSummaryContentLayoutTable>
        {!isMobile && (
          <styled.TransactionsSummaryContentLayoutChart>
            {!isMobileOrTablet && <IncomeExpenseTabs />}
            {(isLoading || netAmounts.length > 0) && (
              <ContactsChart
                netAmounts={netAmounts}
                isLoading={isLoading}
                transactionDirection={incomeOrExpenseSelection}
                hoveredContactNetAmount={hoveredContactNetAmount}
              />
            )}
            {!isLoading && netAmounts.length > 0 && hasInverseNetAmount && (
              <InverseIncomeOrExpenseMessage />
            )}
          </styled.TransactionsSummaryContentLayoutChart>
        )}
      </styled.TransactionsSummaryContentLayout>
      <ContactsBanner
        isLoading={isLoading}
        isAccountSaving={isAccountSaving}
        isNetAmountsEmpty={netAmounts.length === 0}
        error={error}
        onRetry={() => refetch()}
      />
    </>
  )
}
