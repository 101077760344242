import { gql, type TypedDocumentNode } from '@apollo/client'

export type MapToChartDataCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  range: { __typename?: 'CashFlowReportRange'; start: any }
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    expense: { __typename?: 'Money'; value: string }
    income: { __typename?: 'Money'; value: string }
  }
}

export const MapToChartDataCashFlowPeriodicTotalAmountFragmentDoc = gql`
  fragment MapToChartDataCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
    range {
      start
    }
    transactionDirectionTotalAmounts {
      expense {
        value
      }
      income {
        value
      }
    }
  }
` as unknown as TypedDocumentNode<
  MapToChartDataCashFlowPeriodicTotalAmountFragment,
  undefined
>
