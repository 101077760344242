import { ButtonLink } from '@npco/zeller-design-system'
import {
  CreateAccountStage,
  useGoToCreateAccountStage,
} from 'features/CreateAccount'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { page } from 'translations'

import { StyledInfo } from './SingleZellerAccountInfo.styled'

export const SingleZellerAccountInfo = () => {
  const { navigateTo } = useGoToCreateAccountStage()

  return (
    <StyledInfo>
      <InfoIcon />
      <p>{page.transfer.singleAccountInfo}</p>
      <ButtonLink
        isInline
        onClick={() => navigateTo(CreateAccountStage.AccountNameStage)}
      >
        {page.transfer.createAccountLink}
      </ButtonLink>
    </StyledInfo>
  )
}
