import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { ShowMore, useShowMore } from 'components/ShowMore'

import { YourCardListTable } from '../YourCardListTable/YourCardListTable'
import { ExpandableCardTableDebitCardV2Fragment } from './ExpandableCardTable.generated'
import { translations } from './ExpandableCardTable.i18n'

interface ExpandableCardTableProps {
  cards: ExpandableCardTableDebitCardV2Fragment[]
  isLoading: boolean
}

const INITIAL_NUMBER_OF_CARDS = 3

export const ExpandableCardTable = ({
  cards,
  isLoading,
}: ExpandableCardTableProps) => {
  const { isShowingMore } = useShowMore()
  const numberOfOverflowCards = cards.length - INITIAL_NUMBER_OF_CARDS
  const t = useTranslations(translations)

  return (
    <>
      <YourCardListTable
        isLoading={isLoading}
        cards={isShowingMore ? cards : cards.slice(0, INITIAL_NUMBER_OF_CARDS)}
      />

      {!isLoading && numberOfOverflowCards > 0 && (
        <Box mt="16px">
          <ShowMore.Toggle
            showText={t('showMore', { numberOfCards: numberOfOverflowCards })}
            hideText={t('showLess')}
            dataTestId="show-more-cards-button"
          />
        </Box>
      )}
    </>
  )
}

ExpandableCardTable.fragments = {
  DebitCardV2: gql`
    fragment ExpandableCardTableDebitCardV2Fragment on DebitCardV2 {
      ...YourCardListTableDebitCardV2Fragment
    }

    ${YourCardListTable.fragments.DebitCardV2}
  `,
}
