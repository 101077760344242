import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { ErrorType, ServerError } from 'types/errors'

import { usePairOraclePosMutation } from './graphql/pairOraclePos.generated'
import { useOraclePosLocalCache } from './useOraclePosLocalCache'
import { updateCacheOnPair } from './utils/updateCacheOnPair'

type PairingServerError = Omit<ServerError, 'errorInfo'> & {
  errorInfo: {
    workstationIdPairedDeviceName: string
    workstationIdPairedDeviceUuid: string
  }
}

type UsePairOraclePosProps = {
  deviceUuid: string
  deviceName: string
  onError: () => void
  onSuccess: () => void
  workstationId?: string
}

export const usePairOraclePos = ({
  deviceUuid,
  deviceName,
  onError,
  onSuccess,
  workstationId,
}: UsePairOraclePosProps) => {
  const { updateCache } = useOraclePosLocalCache()
  const entityUuid = useSelectedEntityUuid()
  const isPairingServerError = (
    error: unknown
  ): error is PairingServerError => {
    if (typeof error === 'object' && error !== null) {
      const errorObj = error as PairingServerError
      return (
        errorObj?.errorType === ErrorType.RESOURCE_ALREADY_EXISTS &&
        Boolean(errorObj.errorInfo?.workstationIdPairedDeviceUuid) &&
        Boolean(errorObj.errorInfo?.workstationIdPairedDeviceName)
      )
    }
    return false
  }

  const [pairOraclePosMutation, { loading: isPairingOraclePos }] =
    usePairOraclePosMutation({
      variables: {
        entityUuid,
        config: { deviceUuid, workstationId: workstationId ?? '' },
      },
      onCompleted: (data) => {
        if (data?.pairOraclePos) {
          onSuccess()
        } else {
          onError()
        }
      },
      onError: async ({ graphQLErrors }) => {
        const resourceAlreadyExistError =
          graphQLErrors.find(isPairingServerError)
        if (resourceAlreadyExistError) {
          await updateCache({
            isOverridePairingModalOpen: true,
            currentDeviceUuid:
              resourceAlreadyExistError.errorInfo
                ?.workstationIdPairedDeviceUuid,
            currentDeviceName:
              resourceAlreadyExistError.errorInfo
                ?.workstationIdPairedDeviceName,
            newDeviceUuid: deviceUuid,
            newDeviceName: deviceName,
            workstationId,
          })
        } else {
          onError()
        }
      },
      update: (cache, { data }) => {
        if (data?.pairOraclePos) {
          updateCacheOnPair({
            cache,
            id: deviceUuid,
            workstationId,
          })
        }
      },
    })

  return {
    pairOraclePos: pairOraclePosMutation,
    isPairingOraclePos,
  }
}
