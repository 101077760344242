import { InputSelectComboboxItemBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { CustomValidator } from 'types/common'
import { errorMessages } from 'translations'

export const MAXIMUM_TAGS_PER_TRANSACTION = 10

export const TAGS_SUGGESTIONS_HIDDEN_TESTID =
  'contacts-tags-modal-suggestions-hidden'

export const TAGS_SUGGESTIONS_VISIBLE_TESTID =
  'contacts-tags-modal-suggestions-visible'

export const getNonCaseSensitiveMatch =
  (inputValue: string) => (item: InputSelectComboboxItemBasic | string) => {
    if (typeof item === 'string') {
      return item.toUpperCase() === inputValue.trim().toUpperCase()
    }
    return item.label.toUpperCase() === inputValue.trim().toUpperCase()
  }

export const validateTagValue: CustomValidator<string> = (value: string) => {
  if (value.trim().length > 20) {
    return errorMessages.tagValueTooLong
  }
  return undefined
}

export const validateAddNewTag =
  (maximumTags: number): CustomValidator<string, string[]> =>
  (value: string, tags: string[] = []) => {
    const tagValueError = validateTagValue(value)

    if (tagValueError) {
      return tagValueError
    }

    if (tags.length >= maximumTags) {
      return errorMessages.maximumTagsAllowedReached
    }

    if (tags.some(getNonCaseSensitiveMatch(value))) {
      return translate('errorMessages.tagAlreadyExist', {
        tagName: value,
      })
    }

    return undefined
  }
