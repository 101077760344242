import { useCallback, useEffect, useState } from 'react'
import { Pill } from '@npco/zeller-design-system'

import { MAXIMUM_TAGS_PER_TRANSACTION } from '../EditTags.utils'

export const useTagListState = (
  initialTags: string[] = [],
  maximumTagsAllowed = MAXIMUM_TAGS_PER_TRANSACTION
) => {
  const [tags, setTags] = useState<string[]>(initialTags)

  useEffect(() => {
    // NOTE: if initial tags ever changes reset tags
    setTags(initialTags)
  }, [initialTags])

  const removeTag = useCallback(({ index }: Pill) => {
    setTags((prevTags) => [
      ...prevTags.slice(0, index),
      ...prevTags.slice(index + 1),
    ])
  }, [])

  const addTag = useCallback(
    (newTagText: string) => {
      setTags((prevTags) => {
        if (
          prevTags.length < maximumTagsAllowed &&
          prevTags.indexOf(newTagText) === -1
        ) {
          return [...prevTags, newTagText]
        }
        return prevTags
      })

      return true
    },
    [maximumTagsAllowed]
  )

  return {
    removeTag,
    addTag,
    tags,
    setTags,
  }
}
