import { LogoIconLink } from 'components/LogoIconLink'

import * as styled from './MobileNavHeader.styled'
import { SidebarMenuButton } from './SidebarMenuButton'

export const MobileNavHeader = () => {
  return (
    <styled.PageTemplateWrapper data-testid="mobile-nav-header">
      <SidebarMenuButton />
      <LogoIconLink
        width="69"
        height="24"
        ariaLabel="Zeller - back to overview"
      />
    </styled.PageTemplateWrapper>
  )
}
