import { useEffect } from 'react'
import { NetworkStatus, useLazyQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetDeposit } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/deposits'

import {
  GetDeposit as GetDepositType,
  GetDepositVariables,
} from 'types/gql-types/GetDeposit'

export const useFetchDeposit = (depositUuid: string) => {
  const entityUuid = useSelectedEntityUuid()
  const [fetchDeposit, { loading, error, refetch, networkStatus, data }] =
    useLazyQuery<GetDepositType, GetDepositVariables>(GetDeposit, {
      notifyOnNetworkStatusChange: true,
    })

  const hasError = Boolean(error)

  const isRefetching = networkStatus === NetworkStatus.refetch

  useEffect(() => {
    if (!depositUuid) {
      return
    }
    fetchDeposit({
      variables: { entityUuid, depositUuid },
    })
  }, [entityUuid, fetchDeposit, depositUuid])

  return {
    deposit: data?.getDeposit,
    hasError,
    refetchDeposit: refetch,
    isLoading: loading || isRefetching,
  }
}
