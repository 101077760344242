import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'

import { UseCardStatusDebitCardV2Fragment } from './useCardStatus.generated'

export const useCardStatus = (card?: UseCardStatusDebitCardV2Fragment) => {
  const status = useMemo(() => card?.status, [card])

  return useMemo(() => {
    const isActive = status === DebitCardStatus.ACTIVE
    const isInactive = status === DebitCardStatus.INACTIVE
    const isLocked = status === DebitCardStatus.LOCKED
    const isSuspended = status === DebitCardStatus.SUSPENDED
    const isClosed =
      status === DebitCardStatus.CLOSED ||
      status === DebitCardStatus.LOST ||
      status === DebitCardStatus.EXPIRED

    return {
      isActive,
      isLocked,
      isClosed,
      isInactive,
      isSuspended,
    }
  }, [status])
}

useCardStatus.fragments = {
  DebitCardV2: gql`
    fragment UseCardStatusDebitCardV2Fragment on DebitCardV2 {
      status
    }
  `,
}
