import { LabelType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { errorMessages, successMessages } from 'translations'

import { useAddLabelMutation } from '../../graphql/addLabel.generated'
import type { GetLabel } from '../../LabelControl.types'
import { rvBusinessLabels, rvPersonLabels } from '../../LabelControl.utils'

export const useAddLabel = () => {
  const entityUuid = useSelectedEntityUuid()

  const [addLabel, { loading: isAddingLabel }] = useAddLabelMutation({
    onError: () => showErrorToast(errorMessages.addLabelError),
  })

  return {
    addLabel: async (
      labelText: string,
      type: LabelType
    ): Promise<GetLabel | null> => {
      const response = await addLabel({
        variables: {
          labelText,
          type,
          entityUuid,
        },
      })

      const hasErrors = Boolean(response.errors)
      const label = response.data?.addLabel || null

      if (label) {
        if (type === LabelType.BUSINESS) {
          rvBusinessLabels([...rvBusinessLabels(), label])
        } else {
          rvPersonLabels([...rvPersonLabels(), label])
        }
        showSuccessToast(successMessages.addLabelSuccess)
      } else if (!label && !hasErrors) {
        showErrorToast(errorMessages.addLabelError)
      }

      return label
    },
    isAddingLabel,
  }
}
