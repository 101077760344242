import { useCallback } from 'react'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useGoToEditAccountStage } from 'hooks/useGoToEditAccountStage/useGoToEditAccountStage'
import { UNEXPECTED_ERROR } from 'types/errors'

import { accountEditModalTranslations } from '../EditAccountModal.i18n'
import { useEditAccountMutation } from './useEditAccountMutation'
import { useEditAccountState } from './useEditAccountState'
import { usePersonaliseSavingsAccount } from './usePersonaliseSavingsAccount/usePersonaliseSavingsAccount'

export const useEditAccount = () => {
  const t = useTranslations(accountEditModalTranslations)
  const { state } = useEditAccountState()
  const { goToEditAccountStage } = useGoToEditAccountStage()

  const [personaliseDebitAccount, { loading: isUpdatingDebitAccount }] =
    useEditAccountMutation()
  const { personaliseSavingsAccount, isUpdatingSavingsAccount } =
    usePersonaliseSavingsAccount()

  const closeEditAccountModal = useCallback(
    () => goToEditAccountStage(undefined),
    [goToEditAccountStage]
  )

  const handleSubmit = useCallback(
    async (values) => {
      if (!state) {
        showErrorToast(t('toastSaveError'))
        return
      }

      if (state.accountType === DebitCardAccountType.ZLR_DEBIT) {
        const result = await personaliseDebitAccount({
          id: state.accountId,
          ...values,
        })
        if (result && 'error' in result) {
          showErrorToast(t('toastSaveError'))
          return
        }

        showSuccessToast(t('toastSaveSuccess'))
        closeEditAccountModal()
      }

      if (state.accountType === DebitCardAccountType.SAVINGS) {
        const result = await personaliseSavingsAccount({
          id: state.accountId,
          displayName: values.name,
        })

        if (result === UNEXPECTED_ERROR) {
          showErrorToast(t('toastSaveError'))
          return
        }

        showSuccessToast(t('toastSaveSuccess'))
        closeEditAccountModal()
      }
    },
    [
      personaliseSavingsAccount,
      personaliseDebitAccount,
      state,
      t,
      closeEditAccountModal,
    ]
  )

  return {
    handleSubmit,
    closeEditAccountModal,
    isUpdatingAccount: isUpdatingDebitAccount || isUpdatingSavingsAccount,
    initialName: state?.name ?? '',
    initialAccountColour: state?.accountColour ?? '',
    isSavingsAccount: state?.accountType === DebitCardAccountType.SAVINGS,
  }
}
