import { useCallback, useState } from 'react'
import {
  BREAKPOINT,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { useWindowResolution } from 'hooks/useWindowResolution/useWindowResolution'
import { translate } from 'utils/translations'
import { FileWithId } from 'components/File'
import { ImagePreview } from 'components/ImagePreview/ImagePreview'

import { InvoicePreviewPagination } from '../InvoicePreview.styled'

const INVOICE_PDF_WIDTH = 600
const INVOICE_PDF_HEIGHT = 849
const INVOICE_PDF_ASPECT_RATIO = INVOICE_PDF_WIDTH / INVOICE_PDF_HEIGHT

export const translations = {
  infoMessage: translate('page.invoice.previewModal.infoMessage'),
}

interface InvoicePreviewPdfProps {
  pdfFile: FileWithId
}

export const InvoicePreviewPdf = ({ pdfFile }: InvoicePreviewPdfProps) => {
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const isMobile = useIsMobileResolution()
  const { width } = useWindowResolution()

  const handleOnPdfLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setTotalPages(numPages)
    },
    [setTotalPages]
  )

  const calculatedWidth = isMobile
    ? INVOICE_PDF_WIDTH - (BREAKPOINT.XS - width)
    : INVOICE_PDF_WIDTH
  const calculatedHeight = calculatedWidth / INVOICE_PDF_ASPECT_RATIO

  return (
    <>
      <InfoBox
        priority={INFOBOX_PRIORITY.MEDIUM}
        variant={INFOBOX_VARIANT.POSITIVE}
      >
        <InfoBox.Message>{translations.infoMessage}</InfoBox.Message>
      </InfoBox>
      <Flex flexDirection="column" gridGap="16px">
        {totalPages > 1 && (
          <InvoicePreviewPagination
            onNextPageClick={setPageNumber}
            onPreviousPageClick={setPageNumber}
            pageNumber={pageNumber}
            totalPages={totalPages}
          />
        )}
        <ImagePreview
          image={pdfFile}
          height={calculatedHeight}
          onPdfLoadSuccess={handleOnPdfLoadSuccess}
          pageNumber={pageNumber}
          width={calculatedWidth}
          renderPdfAnnotation={false}
        />
      </Flex>
    </>
  )
}
