import { Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import {
  OpeningHours,
  OpeningHoursStatus,
} from '../../TransactionContact.types'
import {
  getIsAlwaysOpen,
  getOpeningHoursParser,
} from '../../utils/openingHours.utils'
import {
  StyledAlwaysOpen,
  StyledOpeningDay,
  StyledOpeningHours,
  StyledOpeningHourWrapper,
} from './HoursBreakdown.styled'

interface Props {
  openingHours: OpeningHours | null
}

export const HoursBreakdown = ({ openingHours }: Props) => {
  if (getIsAlwaysOpen(openingHours)) {
    return (
      <Box>
        <StyledAlwaysOpen>
          {translate('page.transactionContact.alwaysOpen')}
        </StyledAlwaysOpen>
      </Box>
    )
  }

  return (
    <Box>
      {getOpeningHoursParser(openingHours).map((weekDaysAndHours) => {
        const hasHours = Boolean(weekDaysAndHours.hours)
        const isOpen24Hours =
          !hasHours &&
          weekDaysAndHours.status?.toUpperCase() ===
            OpeningHoursStatus.TwentyFourHours
        const isClosed =
          !hasHours &&
          weekDaysAndHours.status?.toUpperCase() === OpeningHoursStatus.Closed
        const isNotSupported = !hasHours && !isOpen24Hours && !isClosed

        return (
          <StyledOpeningHourWrapper key={`${weekDaysAndHours.days}`}>
            <StyledOpeningDay>{weekDaysAndHours.days}</StyledOpeningDay>
            {hasHours && (
              <StyledOpeningHours>{weekDaysAndHours.hours}</StyledOpeningHours>
            )}
            {!hasHours && (
              <StyledOpeningHours>
                {isClosed &&
                  translate('page.transactionContact.merchantClosed')}
                {isOpen24Hours &&
                  translate('page.transactionContact.merchantOpen24Hours')}
                {isNotSupported && '-'}
              </StyledOpeningHours>
            )}
          </StyledOpeningHourWrapper>
        )
      })}
    </Box>
  )
}
