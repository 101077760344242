import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { CancelDebitCard } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/card'

import { cacheDebitCardStatus } from 'utils/banking/cacheDebitCardStatus/cacheDebitCardStatus'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  CancelDebitCard as CancelDebitCardResponse,
  CancelDebitCardVariables,
} from 'types/gql-types/CancelDebitCard'

export const useCancelCard = () => {
  const entityUuid = useSelectedEntityUuid()
  const [cancelCardMutation, { loading }] = useMutation<
    CancelDebitCardResponse,
    CancelDebitCardVariables
  >(CancelDebitCard, {
    update: (cache, result, options) => {
      if (result.data?.cancelDebitCard && options.variables?.cardId.cardUuid) {
        cacheDebitCardStatus({
          cache,
          cardId: options.variables?.cardId.cardUuid,
          status: DebitCardStatus.CLOSED,
        })
      }
    },
  })

  const cancelCard = useCallback(
    async (id: string) => {
      try {
        const cancelCardResult = await cancelCardMutation({
          variables: {
            entityUuid,
            cardId: { cardUuid: id },
          },
        })

        if (!cancelCardResult.data?.cancelDebitCard) {
          throw new Error('Failed to cancel card with false flag')
        }

        return cancelCardResult.data.cancelDebitCard
      } catch (err) {
        ErrorLogger.report('[Banking] Error cancelling card', err)
        return UNEXPECTED_ERROR
      }
    },
    [cancelCardMutation, entityUuid]
  )

  return { cancelCard, isCancellingCard: loading }
}
