import {
  ButtonGhostIconRight,
  ChevronRightIcon,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SelectionButton = styled(ButtonGhostIconRight)`
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 22px;
    line-height: 1.36;
  }
`

export const ChevronDownIcon = styled(ChevronRightIcon)`
  transform: rotate(90deg);
`
