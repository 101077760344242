import { gql } from '@apollo/client'

export const SubscribeCardUpdate = gql`
  subscription SubscribeCardUpdate(
    $entityUuid: ID!
    $customerUuid: ID
    $debitCardAccountUuid: ID
  ) {
    onDebitCardUpdate(
      entityUuid: $entityUuid
      customerUuid: $customerUuid
      debitCardAccountUuid: $debitCardAccountUuid
    ) {
      id
      name
      colour
      debitCardAccountUuid
      maskedPan
      status
      productType
      customerUuid
      owner
      velocityControl {
        __typename
        resetsAt
        velocityWindow
        availableAmount {
          value
        }
        amountLimit {
          value
        }
        totalSpentAmount {
          value
        }
      }
      outstandingTransactions
    }
  }
`
