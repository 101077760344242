import {
  CatalogItemFilterInput,
  CatalogItemSortInput,
  CatalogItemType,
} from '@npco/mp-gql-types'

export type LocalStateCatalogue = {
  filter: CatalogItemFilterInput | null
  sort: CatalogItemSortInput | null
}

export type CacheLocalStateCatalogue = {
  local: {
    catalogue: LocalStateCatalogue
  }
}

export const CatalogueDefaultLocalState: LocalStateCatalogue = {
  filter: {
    categoryUuidFilter: null,
    priceFilter: null,
    textSearchFilter: '',
    itemTypesFilter: [CatalogItemType.SINGLE, CatalogItemType.PARENT],
    siteUuidFilter: null,
  },
  sort: null,
}

export const CatalogueDefaultCacheLocalState: CacheLocalStateCatalogue = {
  local: {
    catalogue: CatalogueDefaultLocalState,
  },
}
