import { COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const BlackTile = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, #000 0%, #8c959c 100%);
`

export const WhiteTile = styled.div`
  background: linear-gradient(137deg, #fff 0%, #f5f5f5 100%);
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.GREY_90};
  border-radius: 8px;
  border-style: solid;
  box-sizing: border-box;
`

export const ColourTile = styled.div<{ colour: string }>`
  width: 100%;
  height: 100%;
  background: ${({ colour }) => colour};
`

export const AddValue = styled.div`
  border: 2px dashed ${COLOR.GREY_150};
  box-sizing: border-box;
`
