import {
  BodyDefaultTypography,
  BodySmallTypography,
  BREAKPOINT,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceItemCalculatePriceWrapper = styled(Flex)`
  flex-direction: column;
  grid-column: 1 / span 2;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    grid-column: 3;
  }
`

export const InvoiceItemCalculateQuantityWrapper = styled(Flex)`
  flex-direction: column;
`

export const FooterTitle = styled.div`
  ${BodyDefaultTypography}
  color: ${({ theme }) => theme.colors.BLACK_900}
`

export const FooterBody = styled.div`
  ${BodySmallTypography}
  color: ${({ theme }) => theme.colors.GREY_550}
`
