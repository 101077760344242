import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import australiaStates from 'const/australia-states.json'

export const useResetInvalidStateValue = (fieldName: string) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>()

  const fieldValue = values[fieldName]

  useEffect(() => {
    const isValidEntry =
      !fieldValue ||
      australiaStates.some((stateObj) => stateObj.value === fieldValue)

    if (!isValidEntry && setFieldValue) {
      setFieldValue(fieldName, '')
    }
  }, [fieldName, fieldValue, setFieldValue])
}
