import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { ReactComponent as ArrowBadge } from 'assets/svg/setup-flow/arrow-card-details-left.svg'

import { Message } from '../../../../../SetupFlowModal.styled'
import { setupFlowDebitCardDetailsTranslations } from '../DebitCardDetails.i18n'

interface DebitCardDetailsCopyInfoTabletProps {
  isDetailsVisible: boolean
}
export const DebitCardDetailsCopyInfoTablet = ({
  isDetailsVisible,
}: DebitCardDetailsCopyInfoTabletProps) => {
  const t = useTranslations(setupFlowDebitCardDetailsTranslations)

  return (
    <>
      <Box position="absolute" top="82px" left="242px">
        <ArrowBadge />
      </Box>
      <Box position="absolute" width="210px" top="82px" left="298px">
        <Message>
          {t(isDetailsVisible ? 'hideMessageTablet' : 'showMessageTablet')}
        </Message>
      </Box>
    </>
  )
}
