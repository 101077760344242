import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SelectCardDesignForm } from './SelectCardDesignForm/SelectCardDesignForm'

export const SelectCardDesignStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.CreateSelectCardDesignStage}
    >
      <SelectCardDesignForm />
    </Condition>
  )
}
