import { type ContactType } from '@npco/mp-gql-types'
import {
  INPUT_SIZE,
  InputSelectComboboxItemRenderProps,
  InputSelectComboboxStandard,
  SelectHeaderRenderProps,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { UseComboboxStateChange } from 'downshift'

import { page } from 'translations'

import { InputSelectComboBoxItem } from '../../Contacts.types'
import { StyledLabel, StyledSubLabel } from '../PersonComboBoxSelect.styled'
import { StyledItemWrapper } from './ContactSelectCombobox.styled'

export interface ContactSelectComboboxProps<ItemType> {
  hasError?: boolean
  hasMore: boolean
  inputValue: string
  isLoading: boolean
  items: ItemType[]
  label?: string
  loadMore: () => void
  onChange: (changes: SelectStateChangeProps<ItemType>) => void
  onClose: (changes: UseComboboxStateChange<ItemType>) => void
  onInputChange: (changes: SelectStateInputChangeProps<ItemType>) => void
  onInputClear: () => void
  renderHeader: ({ closeMenu }: SelectHeaderRenderProps) => React.ReactNode
  selectedItem: ItemType | null
  contactType?: ContactType
}

export const ContactSelectCombobox = ({
  hasError,
  hasMore,
  inputValue,
  isLoading,
  items,
  label = page.contacts.form.contactLabel,
  loadMore,
  onChange,
  onClose,
  onInputChange,
  onInputClear,
  renderHeader,
  selectedItem,
}: ContactSelectComboboxProps<InputSelectComboBoxItem>) => {
  const renderItem = (
    renderProps: InputSelectComboboxItemRenderProps<InputSelectComboBoxItem>
  ) => (
    <StyledItemWrapper {...renderProps} key={renderProps.id}>
      <StyledLabel>{renderProps.item.label}</StyledLabel>
      <StyledSubLabel>{renderProps.item.subLabel}</StyledSubLabel>
    </StyledItemWrapper>
  )

  return (
    <InputSelectComboboxStandard
      hasError={hasError}
      hasMore={hasMore}
      inputValue={inputValue}
      isLoading={isLoading}
      inputSize={INPUT_SIZE.MEDIUM}
      items={items}
      label={label}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onInputChange}
      onInputClear={onInputClear}
      onLoadMoreItems={loadMore}
      placeholder={page.contacts.form.selectContactPlaceholder}
      renderHeader={renderHeader}
      renderItem={renderItem}
      selectedItem={selectedItem}
    />
  )
}
