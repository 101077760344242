import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  AddCardLocationStateSchema,
  AddCardStage,
  AddCardState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

const ADD_CARD_STAGES = Object.values(AddCardStage)

export const useAddCardState = <T extends AddCardState>() => {
  const locationState = useLocationState(AddCardLocationStateSchema)

  const state = useMemo(() => {
    if (
      !locationState?.AddCardModal?.stage ||
      !ADD_CARD_STAGES.includes(locationState.AddCardModal.stage)
    ) {
      return null
    }

    return locationState.AddCardModal as T
  }, [locationState?.AddCardModal])

  return { state }
}
