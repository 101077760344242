import { useCallback, useState } from 'react'

import { useModalEvents } from 'services/Analytics/useModalEvents'

interface UseModalStateProps {
  isOpenByDefault?: boolean
  modalName?: string
}

export const useModalState = (args: UseModalStateProps = {}) => {
  const { isOpenByDefault = false, modalName = '' } = args
  const [isModalOpen, setIsModalOpen] = useState(isOpenByDefault)
  const { onModalOpen, onModalClose } = useModalEvents({ modalName })

  const openModal = useCallback(() => {
    setIsModalOpen(true)
    onModalOpen()
  }, [onModalOpen])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    onModalClose()
  }, [onModalClose])

  return { isModalOpen, openModal, closeModal, setIsModalOpen }
}
