import { useEffect, useMemo } from 'react'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  Flex,
  TEXT_INPUT_SIZE,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { CustomValidator } from 'types/common'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import { StyledButtonLink } from './EditCategory.styled'

interface Props {
  label: string
  name: string
  onSave: (category: string) => void
  onCancel: () => void
  cancelButtonLabel: string
  saveButtonLabel: string
  onDelete?: () => void
  deleteButtonLabel?: string
  shouldValidateImmediately?: boolean
  shouldValidateOnChange?: boolean
  validate?: CustomValidator<string>
  isSaving?: boolean
  isDeleting?: boolean
}

export const EditCategory = ({
  label,
  name,
  onSave,
  onCancel,
  cancelButtonLabel,
  saveButtonLabel,
  onDelete,
  deleteButtonLabel,
  shouldValidateImmediately,
  shouldValidateOnChange,
  validate,
  isSaving,
  isDeleting,
}: Props) => {
  const { values, errors, setTouched } =
    useFormikContext<Record<string, string>>()

  useEffect(() => {
    if (shouldValidateImmediately) {
      setTouched({ [name]: true })
    }
  }, [setTouched, name, shouldValidateImmediately])

  const handleChange = useMemo(
    () =>
      shouldValidateOnChange ? () => setTouched({ [name]: true }) : undefined,
    [shouldValidateOnChange, name, setTouched]
  )

  return (
    <>
      <InputAdaptiveFieldWrapper
        name={name}
        label={label}
        value={values[name]}
        size={TEXT_INPUT_SIZE.SMALL}
        validate={validate}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !errors[name]) {
            e.preventDefault()
            onSave(values[name])
          }
        }}
      />
      <Flex mt="1rem">
        <Box marginRight="0.5rem">
          <ButtonFill
            size={BUTTON_SIZE.SMALL}
            onClick={() => onSave(values[name])}
            disabled={Boolean(errors[name]) || isDeleting}
            isLoading={isSaving}
          >
            {saveButtonLabel}
          </ButtonFill>
        </Box>
        <Box>
          <ButtonGhost
            size={BUTTON_SIZE.SMALL}
            onClick={onCancel}
            disabled={isSaving || isDeleting}
          >
            {cancelButtonLabel}
          </ButtonGhost>
        </Box>
      </Flex>

      {onDelete && deleteButtonLabel && (
        <Box mt="1rem">
          <StyledButtonLink
            onClick={onDelete}
            isLoading={isDeleting}
            disabled={isSaving}
          >
            {deleteButtonLabel}
          </StyledButtonLink>
        </Box>
      )}
    </>
  )
}
