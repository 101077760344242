import { gql, type TypedDocumentNode } from '@apollo/client'

export type ExpenseDatapointCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    noOfExpense: number
    expense: { __typename?: 'Money'; value: string }
  }
}

export const ExpenseDatapointCashFlowPeriodicTotalAmountFragmentDoc = gql`
  fragment ExpenseDatapointCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
    transactionDirectionTotalAmounts {
      noOfExpense
      expense {
        value
      }
    }
  }
` as unknown as TypedDocumentNode<
  ExpenseDatapointCashFlowPeriodicTotalAmountFragment,
  undefined
>
