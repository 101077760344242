import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ErrorMessageForm,
  Flex,
  Heading,
  SelectAdaptive,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { HeaderTooltipButton } from '../components/HeaderTooltipButton'
import { SPEND_CONTROL_FREQUENCY_FIELD } from '../SpendControlFields.constants'
import * as styled from '../SpendControlFields.styled'
import { TimezoneMessage } from './components/TimezoneMessage'
import { useSpendControlFrequencyOptions } from './hooks/useSpendControlFrequencyOptions'
import { spendControlFrequencyTranslations } from './SpendControlFrequency.i18n'

interface SpendControlFrequencyProps {
  isHeaderVisible?: boolean
  isFullWidth?: boolean
  timezone?: string
  isLoadingTimezone: boolean
}

export const SpendControlFrequency = ({
  isHeaderVisible,
  isFullWidth,
  timezone,
  isLoadingTimezone,
}: SpendControlFrequencyProps) => {
  const t = useTranslations(spendControlFrequencyTranslations)
  const [field, meta, helpers] = useField(SPEND_CONTROL_FREQUENCY_FIELD)
  const frequencyOptions = useSpendControlFrequencyOptions()

  return (
    <Box>
      {isHeaderVisible && (
        <Flex mb="24px">
          <Heading.H4 mr="8px">{t('header')}</Heading.H4>
          <HeaderTooltipButton content={t('headerTooltip')} />
        </Flex>
      )}

      <Flex flexDirection={{ _: 'column', SM: isFullWidth ? 'column' : 'row' }}>
        <styled.ItemWrapper $isFullWidth={isFullWidth}>
          <SelectAdaptive
            maxHeight="336px"
            label={t('fieldLabel')}
            items={frequencyOptions}
            selectedItem={frequencyOptions.find(
              (option) => option.value === field.value
            )}
            hasSelectedIndicator
            hasError={Boolean(meta.error)}
            onChange={(selected) => helpers.setValue(selected?.value)}
          />
          {!!meta.error && (
            <ErrorMessageForm hasError errorMessage={meta.error} />
          )}
        </styled.ItemWrapper>

        <styled.ItemWrapper
          $isFullWidth={isFullWidth}
          $isRight
          mt={{ _: '8px', SM: isFullWidth ? '8px' : '4px' }}
          minHeight="56px"
        >
          <TimezoneMessage
            isLoadingTimezone={isLoadingTimezone}
            timezone={timezone}
          />
        </styled.ItemWrapper>
      </Flex>
    </Box>
  )
}
