import { SelectStyle } from '@npco/zeller-design-system'

import { conditionalClassName as cn } from 'utils/conditionalClassName'

import {
  SelectInputBasicProps,
  SelectItemBasic,
  SelectSize,
} from '../Select.types'
import { SelectBasic } from '../SelectBasic/SelectBasic'
import { SelectComponentsContent } from '../SelectComponentsContent/SelectComponentsContent'
import { SelectTriggerAdaptive } from '../SelectTriggerAdaptive/SelectTriggerAdaptive'
import { StyledSelectStandardItem } from './SelectStandard.styled'

export interface Props<ItemType>
  extends Omit<
    SelectInputBasicProps<ItemType>,
    'renderItem' | 'renderTrigger'
  > {
  label?: React.ReactNode
  placeholder?: string
  renderItem?: SelectInputBasicProps<ItemType>['renderItem']
  renderTrigger?: SelectInputBasicProps<ItemType>['renderTrigger']
  renderBottomSection?: SelectInputBasicProps<ItemType>['renderBottomSection']
  hasSelectedIndicator?: boolean
}

export const SelectStandard = <ItemType extends SelectItemBasic>({
  label,
  placeholder,
  size = SelectSize.ExtraLarge,
  selectedItem,
  hasSelectedIndicator,
  ...restProps
}: Props<ItemType>) => (
  <SelectBasic<ItemType>
    selectedItem={selectedItem}
    menuStyle={SelectStyle.Standard}
    renderTrigger={(renderProps) => (
      <SelectTriggerAdaptive
        {...renderProps}
        label={label}
        placeholder={placeholder}
        size={size}
        selectStyle={SelectStyle.Standard}
      />
    )}
    renderItem={({ item, ...renderProps }) => {
      const shouldBeMarkedAsSelected = Boolean(
        hasSelectedIndicator &&
          (selectedItem?.value ? item.value === selectedItem?.value : false)
      )

      return (
        <StyledSelectStandardItem
          data-testid="select-standard-item"
          {...renderProps}
          className={cn({
            [size]: true,
            isDisabled: Boolean(item.isDisabled),
          })}
          hasSelectedIndicator={hasSelectedIndicator}
        >
          <SelectComponentsContent
            {...item}
            size={size}
            hasValue
            shouldBeMarkedAsSelected={shouldBeMarkedAsSelected}
          />
        </StyledSelectStandardItem>
      )
    }}
    size={size}
    {...restProps}
  />
)
