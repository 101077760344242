import { ButtonLink, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { Status } from 'components/Status/Status'

export const StyledAnimatedButton = styled(ButtonLink)<{
  isCompleted: boolean
}>`
  background-color: ${({ isCompleted, theme }) =>
    isCompleted && theme.colors.BLUE_1000};

  &:disabled {
    background-color: ${({ isCompleted, theme }) =>
      isCompleted && theme.colors.BLUE_1000};
  }
`

export const StyledStatus = styled(Status)`
  margin-top: 0.25rem;
`

export const StyledTitle = styled(H3)`
  margin: 0 0 1rem;
`
