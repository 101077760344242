import { CloseAccountError } from './CloseAccountError/CloseAccountError'
import { CloseAccountLoading } from './CloseAccountLoading/CloseAccountLoading'
import { CloseAccountView } from './CloseAccountView/CloseAccountView'
import { useCloseAccountQuery } from './hooks/useCloseAccountQuery/useCloseAccountQuery'

interface CloseAccountProps {
  accountId: string
}

export const CloseAccount = ({ accountId }: CloseAccountProps) => {
  const {
    account,
    simBillingAccountId,
    settlementAccountId,
    hasSimBillingAccountQueryError,
    hasSettlementAccountQueryError,
    isLoading,
    refetch,
  } = useCloseAccountQuery({
    accountId,
  })

  if (isLoading) {
    return <CloseAccountLoading />
  }

  if (
    !account?.balance ||
    hasSimBillingAccountQueryError ||
    hasSettlementAccountQueryError
  ) {
    return <CloseAccountError retry={refetch} />
  }

  return (
    <CloseAccountView
      account={account}
      isSettlementAccount={settlementAccountId === accountId}
      isSimBillingAccount={simBillingAccountId === accountId}
    />
  )
}
