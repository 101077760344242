import { DEFAULT_TRANSACTIONS_PER_PAGE } from 'api/useQueryCardTransactions/useQueryCardTransactions'

import dayjs from 'utils/dayjs'
import { OptimisticDebitCardTransaction } from 'pages/Transfer/Transfer.types'

import { DebitCardTransaction } from '../../../TransactionDrawer'

interface WithTimestamp {
  timestamp: any
}

export const getIsWithinTimeRange =
  (transactions: WithTimestamp[], limit = DEFAULT_TRANSACTIONS_PER_PAGE) =>
  (optimisticTransaction: OptimisticDebitCardTransaction) => {
    if (transactions.length < limit) {
      return true
    }

    const latestTransactionTimestamp =
      transactions[transactions.length - 1].timestamp

    const optimisticTransactionTime = dayjs(optimisticTransaction.timestamp)

    return optimisticTransactionTime.isAfter(dayjs(latestTransactionTimestamp))
  }

export const filterByAccount =
  (accountUuid: string | undefined) =>
  (optimisticTransaction: OptimisticDebitCardTransaction) =>
    !accountUuid || optimisticTransaction.debitCardAccountUuid === accountUuid

export const filterByCardId =
  (cardId: string | undefined) =>
  (optimisticTransaction: OptimisticDebitCardTransaction) =>
    !cardId || optimisticTransaction.debitCardId === cardId

export const filterByContactUuid =
  (contactUuid: string | undefined) =>
  (optimisticTransaction: OptimisticDebitCardTransaction) =>
    !contactUuid || optimisticTransaction.contact?.id === contactUuid

const getHasSamePayee = (
  transactionA: DebitCardTransaction,
  transactionB: DebitCardTransaction
) => {
  return (
    transactionA.payeeDetails?.accountDetails?.name ===
    transactionB.payeeDetails?.accountDetails?.name
  )
}

const getHasSamePayer = (
  transactionA: DebitCardTransaction,
  transactionB: DebitCardTransaction
) => {
  return (
    transactionA.payerDetails?.accountDetails?.name ===
    transactionB.payerDetails?.accountDetails?.name
  )
}

const getHasSameType = (
  transactionA: DebitCardTransaction,
  transactionB: DebitCardTransaction
) => {
  return transactionA.type === transactionB.type
}

export const getHasSameCardName = (
  transactionA: { debitCardName: DebitCardTransaction['debitCardName'] },
  transactionB: { debitCardName: DebitCardTransaction['debitCardName'] }
) => transactionA.debitCardName === transactionB.debitCardName

export const getHasSameValue = (
  transactionA: { amount: DebitCardTransaction['amount'] | null },
  transactionB: { amount: DebitCardTransaction['amount'] | null }
) => transactionA.amount?.value === transactionB.amount?.value

export const getDoesOverwriteOptimistic = (
  persistedTransaction: WithTimestamp,
  optimisticTransaction: WithTimestamp
) =>
  dayjs(persistedTransaction.timestamp).isAfter(
    dayjs(optimisticTransaction.timestamp).subtract(5, 'seconds')
  )

const getIsEquivalentTo =
  (optimisticTransaction: DebitCardTransaction) =>
  (persistedTransaction: DebitCardTransaction) => {
    return (
      optimisticTransaction.id === persistedTransaction.id ||
      (getHasSamePayee(persistedTransaction, optimisticTransaction) &&
        getHasSamePayer(persistedTransaction, optimisticTransaction) &&
        getHasSameCardName(persistedTransaction, optimisticTransaction) &&
        getHasSameValue(persistedTransaction, optimisticTransaction) &&
        getHasSameType(persistedTransaction, optimisticTransaction) &&
        getDoesOverwriteOptimistic(persistedTransaction, optimisticTransaction))
    )
  }

export const filterDuplicates =
  (persistedTransactions: DebitCardTransaction[]) =>
  (optimisticTransaction: OptimisticDebitCardTransaction) =>
    !persistedTransactions.some(getIsEquivalentTo(optimisticTransaction))
