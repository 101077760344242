import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  cancel: 'Cancel',
  confirm: 'Confirm',
  updateTitle: 'Update Person',
  updateBody:
    'This Person will be updated in {draftInvoiceCountText}, are you sure you want to save these changes?',
  draftInvoice: 'draft invoice',
})
