import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  CompleteCardLocationStateSchema,
  CompleteCardStage,
  CompleteCardState,
} from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'

const COMPLETE_CARD_STAGES = Object.values(CompleteCardStage)

export const useCompleteCardState = <T extends CompleteCardState>() => {
  const locationState = useLocationState(CompleteCardLocationStateSchema)

  const state = useMemo(() => {
    if (
      !locationState?.CompleteCardModal?.stage ||
      !COMPLETE_CARD_STAGES.includes(locationState.CompleteCardModal.stage)
    ) {
      return null
    }

    return locationState.CompleteCardModal as T
  }, [locationState?.CompleteCardModal])

  return { state }
}
