import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { AnnouncementModal, Funds } from '@npco/zeller-design-system'
import { useCloseAccountModal } from 'features/CloseAccount/hooks/useCloseAccountModal/useCloseAccountModal'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

export const CloseAccountSettlementPrompt = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobileResolution()
  const { closeModal } = useCloseAccountModal()
  const shortEntityId = useSelectedShortEntityUuid()

  const gotoManageSettlement = () =>
    navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.DEPOSITS.SETTINGS.path)

  return (
    <AnnouncementModal
      Illustration={<Funds size={isMobile ? 'medium' : 'large'} />}
      heading={translate(
        'page.closeAccountModal.closeAccountSettlementPrompt.heading'
      )}
      description={translate(
        'page.closeAccountModal.closeAccountSettlementPrompt.description'
      )}
      primaryButtonLabel={translate(
        'page.closeAccountModal.closeAccountSettlementPrompt.primaryButtonLabel'
      )}
      onClickPrimary={gotoManageSettlement}
      secondaryButtonLabel={translate('shared.close')}
      onClickSecondary={closeModal}
      onCancel={closeModal}
      isOpen
    />
  )
}
