import { useTranslations } from '@npco/utils-translations'
import { COLOR, Typography } from '@npco/zeller-design-system'

import { transferConfirmationHeaderTranslations } from './TransferConfirmationHeader.i18n'

type TransferConfirmationHeaderTitleProps = {
  isTransferring?: boolean
}

export const TransferConfirmationHeaderTitle = ({
  isTransferring,
}: TransferConfirmationHeaderTitleProps) => {
  const t = useTranslations(transferConfirmationHeaderTranslations)

  return (
    <Typography
      variant="heading-xl"
      component="h2"
      color={COLOR.GREY_800}
      id="transfer-confirmation-heading"
    >
      {isTransferring ? t('transferring') : t('title')}
    </Typography>
  )
}
