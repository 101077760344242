import { z } from 'zod'

export enum CancelCardStage {
  CancelCard = 'CancelCard',
}

export const CancelCardStateSchema = z.object({
  stage: z.literal(CancelCardStage.CancelCard),
  cardId: z.string(),
})

export type CancelCardState = z.TypeOf<typeof CancelCardStateSchema>

export const CancelCardLocationStateSchema = z.object({
  CancelCardModal: CancelCardStateSchema.optional(),
})
