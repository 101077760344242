import { ApolloError, MutationFunction } from '@apollo/client'
import {
  CreateCustomerInput,
  CustomerRole,
  KycStatus,
} from '@npco/mp-gql-types'
import { showApiErrorToast } from '@npco/zeller-design-system'
import {
  rvCurrentUserSites,
  rvNewUserDetails,
  rvNewUserType,
  rvSelectedUser,
  rvSiteDetails,
  rvUpdatedUserDetails,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { AppState } from 'features/MFA'

import { CustomerDetailsForm } from 'types/customers'
import { ErrorType, ServerError } from 'types/errors'
import {
  CreateCustomer as CreateCustomerResponse,
  CreateCustomerVariables,
} from 'types/gql-types/CreateCustomer'
import { GetCustomerDetails_getCustomer as CustomerDetails } from 'types/gql-types/GetCustomerDetails'
import {
  UpdateCustomer as UpdateCustomerResponse,
  UpdateCustomerVariables,
} from 'types/gql-types/UpdateCustomer'
import { SiteCache } from 'types/site'
import { errorMessages } from 'translations'

export const getRemovedSites = (
  oldSites: SiteCache[],
  updatedSites: SiteCache[]
) => {
  return oldSites.filter(
    (oldSite: SiteCache) =>
      !updatedSites.some(
        (updatedSite: SiteCache) => updatedSite.id === oldSite.id
      )
  )
}

export const getAddedSites = (
  oldSites: SiteCache[],
  updatedSites: SiteCache[]
) => {
  return updatedSites.filter(
    (updatedSite: SiteCache) =>
      !oldSites.some((oldSite: SiteCache) => updatedSite.id === oldSite.id)
  )
}

export const addCreatedCustomerToStorage = (
  createdCustomer: CustomerDetailsForm & { __typename: 'Customer' },
  customerId: string,
  entityUuid: string
) => {
  const siteDetails = rvSiteDetails() as SiteCache
  const currentCustomers = siteDetails?.customers
  const input = {
    ...createdCustomer,
    __typename: 'Customer',
    id: customerId,
    entityUuid,
    phone: createdCustomer.phone || null,
    role: createdCustomer.role ? (createdCustomer.role as CustomerRole) : null,
    sites: createdCustomer.sites || null,
    isInvitationPending: createdCustomer.isInvitationPending || null,
    kycStatus: createdCustomer.kycStatus || null,
    permissions: {
      allowZellerInvoices: createdCustomer.allowZellerInvoices,
      allowXeroPaymentServices: createdCustomer.role === CustomerRole.ADMIN,
    },
  }

  const newCustomers = currentCustomers ? [...currentCustomers, input] : [input]
  rvSiteDetails({
    ...siteDetails,
    customers: newCustomers,
  })
}

export const updateCustomerInStorage = (
  updatedDetails: Partial<CustomerDetails>
) => {
  const siteDetails = rvSiteDetails() as SiteCache
  const currentCustomers = siteDetails?.customers as CustomerDetails[]

  if (currentCustomers?.length > 0) {
    const updatedCustomer = currentCustomers.filter(
      (customer) => customer.id === updatedDetails.id
    )

    rvSiteDetails({
      ...siteDetails,
      customers: [
        ...currentCustomers.filter(
          (customer) => customer.id !== updatedDetails.id
        ),
        {
          ...updatedCustomer[0],
          role: updatedDetails.role || null,
          sites: updatedDetails.sites || null,
        },
      ],
    })
  }
}

export const getHasCurrentSite = (currentSites: SiteCache[]) =>
  Boolean(currentSites.find((site) => site.id === rvSiteDetails()?.id))

export const initialValuesCreate: CustomerDetailsForm = {
  email: '',
  firstname: '',
  middlename: '',
  lastname: '',
  phone: '',
  role: rvNewUserType(),
  registeringIndividual: false,
  sites: [],
  kycStatus: null,
  allowZellerInvoices: false,
  allowDiscountManagement: false,
  allowItemManagement: false,
}

export const handleCustomerError = (
  error: ApolloError,
  redirectToMfa: (appState: AppState, newOnCancel?: () => void) => void,
  appState: AppState
) => {
  const hasMFAError = error.graphQLErrors.some(
    ({ errorType }: ServerError) =>
      errorType === ErrorType.MFA_REQUIRED ||
      errorType === ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED
  )

  if (hasMFAError) {
    return redirectToMfa(appState)
  }

  const hasCustomerEmailAlreadyExistsError = error.graphQLErrors.some(
    ({ errorType }: ServerError) =>
      errorType === ErrorType.CUSTOMER_EMAIL_ALREADY_EXISTS
  )

  if (hasCustomerEmailAlreadyExistsError) {
    return showApiErrorToast(error, errorMessages.customerEmailAlreadyExists)
  }

  const hasCustomerIdentityAlreadyExistsError = error.graphQLErrors.some(
    ({ errorType }: ServerError) =>
      errorType === ErrorType.CUSTOMER_IDENTITY_ALREADY_EXISTS
  )

  if (hasCustomerIdentityAlreadyExistsError) {
    return showApiErrorToast(error, errorMessages.customerIdentityAlreadyExists)
  }

  return showApiErrorToast(error)
}

interface SubmitCreateForm {
  createCustomer: MutationFunction<
    CreateCustomerResponse,
    CreateCustomerVariables
  >
  values: CustomerDetailsForm
  isXeroPaymentServicesEnabled: boolean
  entityUuid: string
}

export const submitCreateForm = ({
  createCustomer,
  values,
  isXeroPaymentServicesEnabled,
  entityUuid,
}: SubmitCreateForm) => {
  const {
    firstname,
    middlename,
    lastname,
    email,
    phone,
    role,
    sites,
    allowZellerInvoices,
    allowItemManagement,
    allowDiscountManagement,
  } = values
  const xeroPaymentServicesValue = isXeroPaymentServicesEnabled
    ? role === CustomerRole.ADMIN
    : null

  rvNewUserType(role)

  const inputValues = {
    firstname,
    middlename,
    lastname,
    email,
    phone,
    role,
  }

  const currentSites = sites || []

  let newUserSites: SiteCache[] = []
  if (currentSites.length > 0) {
    newUserSites = currentSites
  } else if (rvSiteDetails()) {
    newUserSites = [rvSiteDetails() as unknown as SiteCache]
  }

  rvNewUserDetails({
    ...inputValues,
    registeringIndividual: false,
    sites: newUserSites,
    kycStatus:
      role === CustomerRole.ADMIN ? KycStatus.REQUIRED : KycStatus.NOT_REQUIRED,
    allowZellerInvoices,
    allowItemManagement,
    allowDiscountManagement,
  })

  createCustomer({
    variables: {
      entityUuid,
      input: [
        {
          ...(inputValues as CreateCustomerInput),
          createIdentity: true,
          assignSites: currentSites.map((site) => site.id),
          permissions: {
            allowDiscountManagement,
            allowItemManagement,
            allowZellerInvoices,
            allowXeroPaymentServices: xeroPaymentServicesValue,
          },
        },
      ],
    },
  })
}
interface SubmitEditFormProps {
  initialValues: CustomerDetailsForm
  values: CustomerDetailsForm
  updateCustomer: MutationFunction<
    UpdateCustomerResponse,
    UpdateCustomerVariables
  >
  isXeroPaymentServicesEnabled: boolean
  entityUuid: string
}

export const submitEditForm = async ({
  initialValues,
  updateCustomer,
  values,
  isXeroPaymentServicesEnabled,
  entityUuid,
}: SubmitEditFormProps) => {
  const {
    email,
    sites,
    role,
    allowZellerInvoices,
    allowItemManagement,
    allowDiscountManagement,
  } = values
  const isAdmin = role === CustomerRole.ADMIN
  const existingSites = rvCurrentUserSites()
  const currentSites = sites || []
  const xeroPaymentServicesValue = isXeroPaymentServicesEnabled ? isAdmin : null

  rvNewUserType(role)

  rvUpdatedUserDetails({
    id: rvSelectedUser(),
    email,
    role: role as CustomerRole,
    sites: currentSites,
    permissions: {
      allowDiscountManagement,
      allowItemManagement,
      allowZellerInvoices,
      allowXeroPaymentServices: isAdmin,
    },
  })

  const hasEmailChanged = initialValues.email !== email
  const hasRoleChanged = initialValues.role !== role
  const removedSites = getRemovedSites(existingSites, currentSites)
  const addedSites = getAddedSites(existingSites, currentSites)

  updateCustomer({
    variables: {
      entityUuid,
      customer: {
        id: rvSelectedUser(),
        // NOTE: apollo will strip undefined values from payload
        email: hasEmailChanged ? email : undefined,
        role: hasRoleChanged ? (role as CustomerRole) : undefined,
        assignSites: addedSites.map((site) => site.id),
        unassignSites: removedSites.map((site) => site.id),
        permissions: {
          allowDiscountManagement,
          allowItemManagement,
          allowZellerInvoices: values.allowZellerInvoices,
          allowXeroPaymentServices: xeroPaymentServicesValue,
        },
      },
    },
  })
}
