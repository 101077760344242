import { ModalForm } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { translate } from 'utils/translations'

import { EditCardNameForm } from './EditCardNameForm/EditCardNameForm'
import { EditCardNameFormValues } from './EditCardNameForm/EditCardNameForm.types'
import { useEditCardName } from './hooks/useEditCardName'
import { useEditCardNameState } from './hooks/useEditCardNameState'

export const EditCardNameModal = () => {
  const { state } = useEditCardNameState()
  const {
    initialCardName,
    handleSubmit,
    closeEditCardNameModal,
    isUpdatingCardName,
  } = useEditCardName()

  if (state === null) {
    return null
  }

  return (
    <Formik<EditCardNameFormValues>
      initialValues={{
        nickname: initialCardName,
      }}
      onSubmit={({ nickname }) => handleSubmit(nickname)}
    >
      {({ submitForm, isValid }) => (
        <ModalForm
          isOpen
          onCancel={() => closeEditCardNameModal()}
          onClickPrimary={submitForm}
          primaryButtonLabel={translate('shared.save')}
          secondaryButtonLabel={translate('shared.cancel')}
          title={translate('page.editCardNameModal.modalTitle')}
          isPrimaryButtonDisabled={!isValid}
          isLoading={isUpdatingCardName}
        >
          <EditCardNameForm />
        </ModalForm>
      )}
    </Formik>
  )
}
