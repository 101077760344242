import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'

import { StyledLogoCornerWrapper } from './LogoCorner.styled'

export const LogoCorner: React.FC = () => {
  return (
    <StyledLogoCornerWrapper>
      <SvgIcon width="69" height="24">
        <LogoIcon data-testid="logo-zeller" />
      </SvgIcon>
    </StyledLogoCornerWrapper>
  )
}
