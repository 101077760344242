import { createTranslations } from '@npco/utils-translations'

export const setupFlowPaymentsStageTranslations = createTranslations({
  badge: 'Payments',
  title: 'Start accepting card payments today.',
  description:
    'Accept payments in more places with Zeller Terminal, Invoices, Virtual Terminal, and Tap to Pay.',
  point1: 'Zero-cost payments with built-in surcharging.',
  point2: 'Use Zeller POS Lite, or integrate with your third-party POS.',
  point3: 'Fast nightly settlements, 365 days a year.',
  point4: 'Accept contactless payments with your smartphone.',
  animationDataFilenameSmall: 'setup-flow-payments-small',
  animationDataFilenameMedium: 'setup-flow-payments-medium',
  animationDataFilenameLarge: 'setup-flow-payments-large',
})
