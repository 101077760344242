import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  ContactCoreFieldsDoc,
  ContactCoreFieldsFragment,
} from 'features/Contacts/graphql/ContactCoreFields.generated'
import { SubContactCoreFieldsDoc } from 'features/Contacts/graphql/SubContactCoreFields.generated'

import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'

import { transformCreateContactToGetContact } from '../../Contacts.utils'

type GetContact = ContactCoreFieldsFragment

const getUpdatedContactFragmentData = (
  contact: GetContact,
  subContact?: GetContact | null
) => {
  return {
    ...contact,
    contacts: !subContact ? null : [subContact],
  }
}

export const useContactCache = () => {
  const client = useApolloClient()

  const readFromCache = useCallback(
    (contactId?: string, isSubContact?: boolean) => {
      if (!contactId) {
        return null
      }

      return client.readFragment<GetContact>({
        id: `Contact:${contactId}`,
        fragment: !isSubContact
          ? ContactCoreFieldsDoc
          : SubContactCoreFieldsDoc,
        fragmentName: !isSubContact
          ? 'ContactCoreFields'
          : 'SubContactCoreFields',
      })
    },
    [client]
  )

  const updateInCache = useCallback(
    (contactId: string, data: Partial<GetContact>) => {
      const contactFromCache = readFromCache(contactId)

      if (!contactFromCache) {
        return
      }

      client.writeFragment<GetContact>({
        id: `Contact:${contactId}`,
        fragment: ContactCoreFieldsDoc,
        fragmentName: 'ContactCoreFields',
        data: {
          ...contactFromCache,
          ...data,
        },
      })
    },
    [client, readFromCache]
  )

  const writeToCache = (
    contact: CreateContact | CreateContactsContact,
    subContact?: GetContact | null
  ) => {
    const parentContact = transformCreateContactToGetContact(contact)
    // New contact created
    client.writeFragment<GetContact>({
      id: `Contact:${contact.id}`,
      fragment: ContactCoreFieldsDoc,
      data: getUpdatedContactFragmentData(parentContact, subContact),
      fragmentName: 'ContactCoreFields',
    })

    if (!subContact) {
      return
    }

    const subContactFromCache = client.readFragment<GetContact>({
      id: `Contact:${subContact.id}`,
      fragment: ContactCoreFieldsDoc,
      fragmentName: 'ContactCoreFields',
    })

    if (subContactFromCache) {
      // Update existing sub contact in cache with the created contact
      client.writeFragment<GetContact>({
        id: `Contact:${subContactFromCache.id}`,
        fragment: ContactCoreFieldsDoc,
        fragmentName: 'ContactCoreFields',
        data: {
          ...subContactFromCache,
          contacts: (subContactFromCache.contacts ?? []).concat(parentContact),
        },
      })
      return
    }

    // New sub contact created
    client.writeFragment<GetContact>({
      id: `Contact:${subContact.id}`,
      fragment: ContactCoreFieldsDoc,
      fragmentName: 'ContactCoreFields',
      data: getUpdatedContactFragmentData(subContact, parentContact),
    })
  }

  return {
    readFromCache,
    updateInCache,
    writeToCache,
  }
}
