import { gql } from '@apollo/client'

export const GetXeroBrandingThemes = gql`
  query GetXeroBrandingThemes(
    $entityUuid: ID!
    $xeroOrganisationUuid: String!
  ) {
    getXeroBrandingThemes(
      entityUuid: $entityUuid
      connectionType: XERO_PAYMENT_SERVICES
      xeroOrganisationUuid: $xeroOrganisationUuid
    ) {
      brandingThemeId
      createdDateUTC
      logoUrl
      name
      themeType
      sortOrder
    }
  }
`
