import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { AccountStatementType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { UNEXPECTED_ERROR } from 'types/errors'

import { CreateInterimBankingAccountStatement } from './graphql/CreateInterimBankingAccountStatement'
import {
  CreateInterimBankingAccountStatementMutationResponse,
  CreateInterimBankingAccountStatementMutationVariables,
} from './graphql/CreateInterimBankingAccountStatement.generated'

interface UseCreateInterimBankingAccountStatementProps {
  type: AccountStatementType
}

export const useCreateInterimBankingAccountStatement = ({
  type,
}: UseCreateInterimBankingAccountStatementProps) => {
  const entityUuid = useSelectedEntityUuid()

  const [
    createInterimBankingAccountStatementMutation,
    { loading, data, error, called },
  ] = useMutation<
    CreateInterimBankingAccountStatementMutationResponse,
    CreateInterimBankingAccountStatementMutationVariables
  >(CreateInterimBankingAccountStatement)

  const createInterimBankingAccountStatement = useCallback(
    async ({ debitCardAccountUuid }: { debitCardAccountUuid: string }) => {
      try {
        const result = await createInterimBankingAccountStatementMutation({
          variables: { debitCardAccountUuid, type, entityUuid },
        })

        if (!result.data?.createInterimBankingAccountStatement.downloadLink) {
          return UNEXPECTED_ERROR
        }

        return {
          interimAccountStatement:
            result.data.createInterimBankingAccountStatement,
        }
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [createInterimBankingAccountStatementMutation, type, entityUuid]
  )

  return {
    createInterimBankingAccountStatement,
    isLoading: loading,
    error,
    interimAccountStatement: data?.createInterimBankingAccountStatement || null,
    called,
  }
}
