import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateVisibleTabsMutationVariables = Types.Exact<{
  input: Types.UpdateVisibleTabsInput
}>

export type UpdateVisibleTabsMutationResponse = {
  __typename?: 'Mutation'
  updateVisibleTabs: boolean
}

export const UpdateVisibleTabs = gql`
  mutation UpdateVisibleTabs($input: UpdateVisibleTabsInput!) {
    updateVisibleTabs(input: $input)
  }
` as unknown as TypedDocumentNode<
  UpdateVisibleTabsMutationResponse,
  UpdateVisibleTabsMutationVariables
>
export type UpdateVisibleTabsMutationFn = Apollo.MutationFunction<
  UpdateVisibleTabsMutationResponse,
  UpdateVisibleTabsMutationVariables
>

/**
 * __useUpdateVisibleTabsMutation__
 *
 * To run a mutation, you first call `useUpdateVisibleTabsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisibleTabsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisibleTabsMutation, { data, loading, error }] = useUpdateVisibleTabsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisibleTabsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVisibleTabsMutationResponse,
    UpdateVisibleTabsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVisibleTabsMutationResponse,
    UpdateVisibleTabsMutationVariables
  >(UpdateVisibleTabs, options)
}
export type UpdateVisibleTabsMutationHookResult = ReturnType<
  typeof useUpdateVisibleTabsMutation
>
export type UpdateVisibleTabsMutationResult =
  Apollo.MutationResult<UpdateVisibleTabsMutationResponse>
export type UpdateVisibleTabsMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisibleTabsMutationResponse,
  UpdateVisibleTabsMutationVariables
>
