import { useCallback, useRef } from 'react'
import { useMutation } from '@apollo/client'
import {
  rvEntityDetails,
  useSelectedEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { AddThirdPartyBankAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/deposits'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvThirdPartyDepositBank } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/depositAccount'
import { useAppStateMapper } from 'auth/useAppStateMapper'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { ThirdPartyAccount } from 'types/accounts'
import { DepositsApiCallTypeEnum } from 'types/auth'
import { ErrorType, ServerError } from 'types/errors'
import {
  AddThirdPartyBankAccount as AddThirdPartyBankAccountResponse,
  AddThirdPartyBankAccountVariables,
} from 'types/gql-types/AddThirdPartyBankAccount'
import { Account } from 'types/settings'
import { useDepositAccountSelection } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'

export const useAddThirdPartyAccount = () => {
  const entityUuid = useSelectedEntityUuid()
  const { stepUpAuth } = useZellerAuthenticationContext()
  const { mapDepositStateToAuthObject } = useAppStateMapper()
  const addAccountVariablesRef = useRef<AddThirdPartyBankAccountVariables>(
    {} as AddThirdPartyBankAccountVariables
  )

  const handleAddDepositAccountError = () => {
    const appState = mapDepositStateToAuthObject(
      DepositsApiCallTypeEnum.ADD,
      addAccountVariablesRef.current,
      addAccountVariablesRef.current.input.name
    )
    stepUpAuth(appState)
  }

  const [addAccount, { loading, error }] = useMutation<
    AddThirdPartyBankAccountResponse,
    AddThirdPartyBankAccountVariables
  >(AddThirdPartyBankAccount, {
    onError: ({ graphQLErrors: [graphQLError] }) => {
      const error2Fa = graphQLError as ServerError

      if (
        error2Fa.errorType === ErrorType.MFA_REQUIRED ||
        error2Fa.errorType === ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED
      ) {
        handleAddDepositAccountError()
      } else {
        showApiErrorToast()
      }
    },
  })

  const { selectDepositAccount, isLoading, isMfaRequiredOrExpired } =
    useDepositAccountSelection(Account.EXTERNAL)

  const addThirdPartyAccount = useCallback(
    async (
      accountDetails: ThirdPartyAccount,
      shouldUpdateDepositAccount: boolean,
      onAccountUpdated?: () => void
    ) => {
      addAccountVariablesRef.current = {
        entityUuid,
        input: {
          accountBsb: accountDetails.accountBsb,
          accountName: accountDetails.accountName,
          accountNumber: accountDetails.accountNumber,
          name: accountDetails.accountName,
        },
      }
      const response = await addAccount({
        variables: {
          entityUuid,
          input: {
            accountBsb: accountDetails.accountBsb,
            accountName: accountDetails.accountName,
            accountNumber: accountDetails.accountNumber,
            name: accountDetails.accountName,
          },
        },
      })

      if (!response.errors && response?.data?.addThirdPartyBankAccount.id) {
        const accountsFromMemory = rvThirdPartyAccounts()
        rvThirdPartyAccounts([
          ...accountsFromMemory,
          {
            accountName: accountDetails.accountName,
            accountNumber: accountDetails.accountNumber,
            accountBsb: accountDetails.accountBsb,
            name: accountDetails.accountName,
            id: response?.data?.addThirdPartyBankAccount.id,
          },
        ])

        if (shouldUpdateDepositAccount) {
          const updateAccountResponse = await selectDepositAccount(
            response?.data?.addThirdPartyBankAccount.id,
            accountDetails.accountName,
            true
          )

          if (isMfaRequiredOrExpired(updateAccountResponse)) {
            return true
          }

          rvEntityDetails({
            ...rvEntityDetails(),
            depositAccountUuid: response?.data?.addThirdPartyBankAccount.id,
            remitToCard: false,
          })
          rvThirdPartyDepositBank({
            accountName: accountDetails.accountName,
            accountNumber: accountDetails.accountNumber,
            accountBsb: accountDetails.accountBsb,
          })
          onAccountUpdated?.()
        }

        return true
      }

      return false
    },
    [entityUuid, addAccount, selectDepositAccount, isMfaRequiredOrExpired]
  )

  return {
    addThirdPartyAccount,
    isLoadingAddThirdPartyAccount: loading || isLoading,
    error,
  }
}
