import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { ConnectionStatus } from '@npco/mp-gql-types'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import {
  GetConnections as GetConnectionsResponse,
  GetConnectionsVariables,
} from 'types/gql-types/GetConnections'

import { buildConnections } from '../connectionConfigs.utils'
import { Connection } from '../Connections.types'
import { GetConnections } from '../graphql/GetConnections'

export const useConnections = () => {
  const entityUuid = useSelectedEntityUuid()
  const {
    loading,
    data,
    error,
    networkStatus,
    refetch: refetchConnections,
  } = useQuery<GetConnectionsResponse, GetConnectionsVariables>(
    GetConnections,
    {
      variables: { entityUuid },
      onError: (err) => {
        showApiErrorToast(err)
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const shortEntityId = useSelectedShortEntityUuid()
  const connections: Connection[] = useMemo(
    () => buildConnections(shortEntityId, data),
    [data, shortEntityId]
  )

  const linkedConnections = connections.filter((connection) =>
    [
      ConnectionStatus.CONNECTED,
      ConnectionStatus.CONNECTED_WITH_ERROR,
    ].includes(connection.status as ConnectionStatus)
  )
  const unlinkedConnections = connections.filter((connection) =>
    [
      ConnectionStatus.NOT_CONNECTED,
      ConnectionStatus.PARTIALLY_CONNECTED,
    ].includes(connection.status as ConnectionStatus)
  )

  return {
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    connections,
    linkedConnections,
    unlinkedConnections,
    error,
    refetchConnections,
    data,
  }
}
