import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types/bigCommerce/gql-global-types'

const defaultOptions = {} as const
export type ImageDataFragment = {
  __typename?: 'Image'
  altText: string
  url320wide: string
}

export type ProductOption_CheckboxOption_Fragment = {
  __typename?: 'CheckboxOption'
  entityId: number
}

export type ProductOption_DateFieldOption_Fragment = {
  __typename?: 'DateFieldOption'
  entityId: number
}

export type ProductOption_FileUploadFieldOption_Fragment = {
  __typename?: 'FileUploadFieldOption'
  entityId: number
}

export type ProductOption_MultiLineTextFieldOption_Fragment = {
  __typename?: 'MultiLineTextFieldOption'
  entityId: number
}

export type ProductOption_MultipleChoiceOption_Fragment = {
  __typename?: 'MultipleChoiceOption'
  entityId: number
  values: {
    __typename?: 'ProductOptionValueConnection'
    edges: Array<{
      __typename?: 'ProductOptionValueEdge'
      node:
        | { __typename?: 'MultipleChoiceOptionValue' }
        | { __typename?: 'ProductPickListOptionValue'; entityId: number }
        | { __typename?: 'SwatchOptionValue' }
    }> | null
  }
}

export type ProductOption_NumberFieldOption_Fragment = {
  __typename?: 'NumberFieldOption'
  entityId: number
}

export type ProductOption_TextFieldOption_Fragment = {
  __typename?: 'TextFieldOption'
  entityId: number
}

export type ProductOptionFragment =
  | ProductOption_CheckboxOption_Fragment
  | ProductOption_DateFieldOption_Fragment
  | ProductOption_FileUploadFieldOption_Fragment
  | ProductOption_MultiLineTextFieldOption_Fragment
  | ProductOption_MultipleChoiceOption_Fragment
  | ProductOption_NumberFieldOption_Fragment
  | ProductOption_TextFieldOption_Fragment

export type CategoryFragment = {
  __typename?: 'Category'
  entityId: number
  name: string
}

export type CustomFieldFragment = {
  __typename?: 'CustomField'
  entityId: number
  name: string
  value: string
}

export type ProductFragment = {
  __typename?: 'Product'
  entityId: number
  name: string
  description: string
  sku: string
  plainTextDescription: string
  defaultImage: {
    __typename?: 'Image'
    altText: string
    url320wide: string
  } | null
  images: {
    __typename?: 'ImageConnection'
    edges: Array<{
      __typename?: 'ImageEdge'
      node: { __typename?: 'Image'; altText: string; url320wide: string }
    }> | null
  }
  prices: {
    __typename?: 'Prices'
    basePrice: { __typename?: 'Money'; value: any } | null
    salePrice: { __typename?: 'Money'; value: any } | null
    price: { __typename?: 'Money'; value: any }
  } | null
  productOptions: {
    __typename?: 'ProductOptionConnection'
    edges: Array<{
      __typename?: 'ProductOptionEdge'
      node:
        | { __typename?: 'CheckboxOption'; entityId: number }
        | { __typename?: 'DateFieldOption'; entityId: number }
        | { __typename?: 'FileUploadFieldOption'; entityId: number }
        | { __typename?: 'MultiLineTextFieldOption'; entityId: number }
        | {
            __typename?: 'MultipleChoiceOption'
            entityId: number
            values: {
              __typename?: 'ProductOptionValueConnection'
              edges: Array<{
                __typename?: 'ProductOptionValueEdge'
                node:
                  | { __typename?: 'MultipleChoiceOptionValue' }
                  | {
                      __typename?: 'ProductPickListOptionValue'
                      entityId: number
                    }
                  | { __typename?: 'SwatchOptionValue' }
              }> | null
            }
          }
        | { __typename?: 'NumberFieldOption'; entityId: number }
        | { __typename?: 'TextFieldOption'; entityId: number }
    }> | null
  }
  categories: {
    __typename?: 'CategoryConnection'
    edges: Array<{
      __typename?: 'CategoryEdge'
      node: { __typename?: 'Category'; entityId: number; name: string }
    }> | null
  }
  customFields: {
    __typename?: 'CustomFieldConnection'
    edges: Array<{
      __typename?: 'CustomFieldEdge'
      node: {
        __typename?: 'CustomField'
        entityId: number
        name: string
        value: string
      }
    }> | null
  }
}

export type GetProductsQueryVariables = Types.Exact<{
  pageSize: Types.Scalars['Int']['input']
}>

export type GetProductsQueryResponse = {
  __typename?: 'Query'
  site: {
    __typename?: 'Site'
    products: {
      __typename?: 'ProductConnection'
      edges: Array<{
        __typename?: 'ProductEdge'
        node: {
          __typename?: 'Product'
          entityId: number
          name: string
          description: string
          sku: string
          plainTextDescription: string
          defaultImage: {
            __typename?: 'Image'
            altText: string
            url320wide: string
          } | null
          images: {
            __typename?: 'ImageConnection'
            edges: Array<{
              __typename?: 'ImageEdge'
              node: {
                __typename?: 'Image'
                altText: string
                url320wide: string
              }
            }> | null
          }
          prices: {
            __typename?: 'Prices'
            basePrice: { __typename?: 'Money'; value: any } | null
            salePrice: { __typename?: 'Money'; value: any } | null
            price: { __typename?: 'Money'; value: any }
          } | null
          productOptions: {
            __typename?: 'ProductOptionConnection'
            edges: Array<{
              __typename?: 'ProductOptionEdge'
              node:
                | { __typename?: 'CheckboxOption'; entityId: number }
                | { __typename?: 'DateFieldOption'; entityId: number }
                | { __typename?: 'FileUploadFieldOption'; entityId: number }
                | { __typename?: 'MultiLineTextFieldOption'; entityId: number }
                | {
                    __typename?: 'MultipleChoiceOption'
                    entityId: number
                    values: {
                      __typename?: 'ProductOptionValueConnection'
                      edges: Array<{
                        __typename?: 'ProductOptionValueEdge'
                        node:
                          | { __typename?: 'MultipleChoiceOptionValue' }
                          | {
                              __typename?: 'ProductPickListOptionValue'
                              entityId: number
                            }
                          | { __typename?: 'SwatchOptionValue' }
                      }> | null
                    }
                  }
                | { __typename?: 'NumberFieldOption'; entityId: number }
                | { __typename?: 'TextFieldOption'; entityId: number }
            }> | null
          }
          categories: {
            __typename?: 'CategoryConnection'
            edges: Array<{
              __typename?: 'CategoryEdge'
              node: { __typename?: 'Category'; entityId: number; name: string }
            }> | null
          }
          customFields: {
            __typename?: 'CustomFieldConnection'
            edges: Array<{
              __typename?: 'CustomFieldEdge'
              node: {
                __typename?: 'CustomField'
                entityId: number
                name: string
                value: string
              }
            }> | null
          }
        }
      }> | null
    }
  }
}

export const ImageDataDoc = gql`
  fragment ImageData on Image {
    url320wide: url(width: 320)
    altText
  }
` as unknown as TypedDocumentNode<ImageDataFragment, undefined>
export const ProductOptionDoc = gql`
  fragment ProductOption on CatalogProductOption {
    entityId
    ... on MultipleChoiceOption {
      values(first: 5) {
        edges {
          node {
            ... on ProductPickListOptionValue {
              entityId
            }
          }
        }
      }
    }
  }
`
export const CategoryDoc = gql`
  fragment Category on Category {
    entityId
    name
  }
`
export const CustomFieldDoc = gql`
  fragment CustomField on CustomField {
    entityId
    name
    value
  }
`
export const ProductDoc = gql`
  fragment Product on Product {
    entityId
    name
    description
    sku
    plainTextDescription
    defaultImage {
      url320wide: url(width: 320)
      altText
    }
    images {
      edges {
        node {
          url320wide: url(width: 320)
          altText
        }
      }
    }
    prices(includeTax: true) {
      basePrice {
        value
      }
      salePrice {
        value
      }
      price {
        value
      }
    }
    productOptions(first: 5) {
      edges {
        node {
          ...ProductOption
        }
      }
    }
    categories {
      edges {
        node {
          ...Category
        }
      }
    }
    customFields {
      edges {
        node {
          ...CustomField
        }
      }
    }
  }
  ${ProductOptionDoc}
  ${CategoryDoc}
  ${CustomFieldDoc}
`
export const GetProducts = gql`
  query GetProducts($pageSize: Int!) {
    site {
      products(first: $pageSize) {
        edges {
          node {
            ...Product
          }
        }
      }
    }
  }
  ${ProductDoc}
`

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsQueryResponse,
    GetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProductsQueryResponse, GetProductsQueryVariables>(
    GetProducts,
    options
  )
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsQueryResponse,
    GetProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProductsQueryResponse,
    GetProductsQueryVariables
  >(GetProducts, options)
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQueryResponse,
  GetProductsQueryVariables
>
