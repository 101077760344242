import { ButtonLink, Flex } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { page } from 'translations'

import { ActivateSimCardModal } from '../ActivateSimCardModal/ActivateSimCardModal'

interface Props {
  reactivateSim: () => void
  isLoading: boolean
}

export const CancelledStatusAction = ({ reactivateSim, isLoading }: Props) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <ActivateSimCardModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onAccept={reactivateSim}
        isLoading={isLoading}
      />
      <Flex justifyContent="space-between" pt="8px">
        <ButtonLink onClick={openModal}>
          {page.settings.sim.details.billingStatusAction.cancelled}
        </ButtonLink>
      </Flex>
    </>
  )
}
