import { ApolloError } from '@apollo/client'

type IntervalModifier = (retryAtempt: number) => number

export const retryOnError = async <T>(
  future: () => Promise<T>,
  errorCheck: (error: ApolloError) => boolean,
  retriesLeft = 5,
  interval: IntervalModifier = (retryAtempt) => retryAtempt * 1000
): ReturnType<typeof future> => {
  return new Promise((resolve, reject) => {
    future()
      .then(resolve)
      .catch((error: ApolloError) => {
        if (retriesLeft === 1) {
          reject(error)
          return
        }

        if (errorCheck(error)) {
          setTimeout(() => {
            retryOnError(future, errorCheck, retriesLeft - 1, interval).then(
              resolve,
              reject
            )
          }, interval(retriesLeft - 1))
          return
        }

        reject(error)
      })
  })
}
