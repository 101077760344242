import { useMemo, useRef } from 'react'
import { getIsEmailValid } from '@npco/component-mp-common'
import {
  ErrorMessageForm,
  Flex,
  INPUT_SIZE,
  InputAdaptive,
  InputSelectComboboxBasic,
  PillBasic,
  PillNegative,
  PillSize,
  useModalState,
} from '@npco/zeller-design-system'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { INVOICE_EMAIL_CC_FIELD } from '../../../../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../../../../Invoice.types'
import {
  EmailInputSelectComboboxItem,
  useEmailSelectCombobox,
} from '../../../../../../hooks/useEmailSelectCombobox'
import { InvoiceContactComboboxHeader } from '../../../../../InvoiceContactComboboxHeader/InvoiceContactComboboxHeader'
import { InvoiceCreateContact } from '../../../../../InvoiceCreateContact/InvoiceCreateContact'
import { InvoiceLinkEmailToContactModal } from '../InvoiceLinkEmailToContactModal/InvoiceLinkEmailToContactModal'
import { useInvoiceEmailInputField } from './hooks/useInvoiceEmailInputField'
import {
  InvoiceEmailInputFieldWrapper,
  LinkEmailButton,
} from './InvoiceEmailInputField.styled'

export const translations = {
  inputPlaceholder: translate(
    'page.invoice.formSections.email.recipientPlaceholder'
  ),
  linkToContactButton: translate(
    'page.invoice.formSections.email.linkToContactButton'
  ),
}

type RecipientType = 'cc' | 'bcc'

interface InvoiceEmailInputFieldProps {
  error: string
  label: string
  name: string
}

export const getPillDataTestId = (
  index: number,
  recipientType: RecipientType,
  isValid = true
) => `${!isValid ? 'invalid-' : ''}invoice-email-pill-${index}-${recipientType}`

export const InvoiceEmailInputField = ({
  error,
  label,
  name,
}: InvoiceEmailInputFieldProps) => {
  const { readFromCache, writeToCache } = useContactCache()

  const initialCreateContactValues = useRef<{
    name: string
    email: string
  } | null>(null)

  const {
    initialValues: { requiredEmailUpdateBeforeSend },
    values: {
      customer,
      delivery: {
        email: { bcc, cc, recipient: emailAddress },
      },
    },
  } = useFormikContext<InvoiceFormFields>()

  const payerId = customer.payerContact?.contactUuid ?? ''

  const payerContact = readFromCache(payerId)

  const recipientType = name === INVOICE_EMAIL_CC_FIELD ? 'cc' : 'bcc'

  const {
    closeModal: closeSaveToContactModal,
    isModalOpen: isSaveToContactModalOpen,
    openModal: openSaveToContactModal,
  } = useModalState()

  const {
    contactTypeToCreate,
    emailValues,
    handleMouseEnter,
    handleMouseLeave,
    handleRemoveEmail,
    handleKeyDown,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleOnInputChange,
    inputValue,
    isFocused,
    setContactTypeToCreate,
  } = useInvoiceEmailInputField(name)

  const excludeItems = useMemo(() => {
    return [emailAddress].concat(bcc).concat(cc)
  }, [bcc, cc, emailAddress])

  const { filteredItems, renderComboboxItem } = useEmailSelectCombobox(
    inputValue,
    excludeItems
  )

  const hasValue = Boolean(emailValues.length || inputValue)
  const isFloatingLabel = isFocused || hasValue

  return (
    <InvoiceEmailInputFieldWrapper
      isFloatingLabel={isFloatingLabel}
      $hasValue={hasValue}
      $size={INPUT_SIZE.MEDIUM}
    >
      <InputSelectComboboxBasic<EmailInputSelectComboboxItem>
        inputValue={inputValue}
        renderInput={(renderProps) => (
          <InputAdaptive
            {...renderProps}
            hasError={Boolean(error)}
            name={name}
            onBlur={(event) => {
              renderProps.onBlur(event)
              handleOnBlur(event)
            }}
            onFocus={(event) => {
              renderProps.onFocus(event)
              handleOnFocus()
            }}
            label={label}
            placeholder={translations.inputPlaceholder}
            renderLeftControls={() =>
              emailValues.map((email, index) => {
                const pillProps = {
                  key: `invoice-email-pill-${email}`,
                  size: PillSize.Small,
                  text: email,
                  onClose: () => handleRemoveEmail(email),
                }

                const isFailedEmail =
                  requiredEmailUpdateBeforeSend.includes(email)

                const isValidEmail = getIsEmailValid(email)

                return !isFailedEmail && isValidEmail ? (
                  <PillBasic
                    dataTestId={getPillDataTestId(index, recipientType)}
                    {...pillProps}
                  />
                ) : (
                  <PillNegative
                    dataTestId={getPillDataTestId(index, recipientType, false)}
                    {...pillProps}
                  />
                )
              })
            }
            onKeyDown={(event) => {
              renderProps.onKeyDown(event)
              handleKeyDown(event)
            }}
          />
        )}
        renderHeader={
          inputValue
            ? ({ closeMenu }) => (
                <Flex
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  flexDirection={{ _: 'column', LG: 'row' }}
                  justifyContent="space-between"
                >
                  <InvoiceContactComboboxHeader
                    closeMenu={closeMenu}
                    onSelection={setContactTypeToCreate}
                  />
                  <LinkEmailButton
                    onClick={() => {
                      openSaveToContactModal()
                      closeMenu()
                    }}
                  >
                    {translations.linkToContactButton}
                  </LinkEmailButton>
                </Flex>
              )
            : undefined
        }
        renderItem={(renderProps) => (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {renderComboboxItem(renderProps)}
          </div>
        )}
        items={filteredItems}
        onChange={(changes) =>
          handleOnChange(changes.selectedItem?.value || '')
        }
        onInputChange={(changes) => handleOnInputChange(changes.inputValue)}
        selectedItem={null}
        shouldCloseOnSelection={false}
      />
      {contactTypeToCreate && (
        <InvoiceCreateContact
          contactTypeToCreate={contactTypeToCreate}
          initialName={initialCreateContactValues.current?.name}
          initialEmail={initialCreateContactValues.current?.email ?? inputValue}
          onClose={() => {
            initialCreateContactValues.current = null
            setContactTypeToCreate(null)
          }}
          onCreate={(newContact, subContact) => {
            writeToCache(newContact, subContact)

            initialCreateContactValues.current = null

            if (newContact.email?.email) {
              handleOnChange(newContact.email.email)
            }

            setContactTypeToCreate(null)
          }}
          payerId={
            payerContact && payerContact.contactType !== contactTypeToCreate
              ? payerId
              : undefined
          }
        />
      )}
      {isSaveToContactModalOpen && (
        <InvoiceLinkEmailToContactModal
          onCreateNewContact={(contactType, contactName, contactEmail) => {
            initialCreateContactValues.current = {
              email: contactEmail,
              name: contactName,
            }
            setContactTypeToCreate(contactType)
            closeSaveToContactModal()
          }}
          onClose={(newEmail) => {
            closeSaveToContactModal()

            if (newEmail) {
              handleOnChange(newEmail)
            }
          }}
          initialEmailValue={inputValue}
        />
      )}
      <ErrorMessageForm hasError={Boolean(error)} errorMessage={error} />
    </InvoiceEmailInputFieldWrapper>
  )
}
