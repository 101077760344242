import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import { PillSelectTrigger } from '@npco/zeller-design-system'

import * as styled from './DateRange.styled'
import { DatePicker } from './DateRange/DatePicker'
import { DatePickerProps } from './DateRange/DatePicker.types'
import { usePeriodOrDateTriggerLabel } from './DateRange/DateRange.utils'
import { useFloatingPill } from './useFloatingPill'

export const DateRange = ({
  defaultRange,
  range,
  format = 'datetime',
  onRangeChange,
}: DatePickerProps) => {
  const { context, floatingProps, isOpen, referenceProps, transitionProps } =
    useFloatingPill()

  const getTriggerLabel = usePeriodOrDateTriggerLabel()
  const triggerLabel = getTriggerLabel(range)

  return (
    <>
      <PillSelectTrigger
        open={isOpen}
        label={triggerLabel}
        filled={!!range?.start}
        {...referenceProps}
      />
      {transitionProps.isMounted && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div {...floatingProps}>
              <styled.DatePickerBackground style={transitionProps.style}>
                <DatePicker
                  defaultRange={defaultRange}
                  range={range}
                  format={format}
                  onRangeChange={onRangeChange}
                />
              </styled.DatePickerBackground>
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
