import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type SimIdCellFragment = {
  __typename?: 'Sim'
  id: string
  billingStatus: Types.SimBillingStatus
}

export const SimIdCellFragmentDoc = gql`
  fragment SimIdCellFragment on Sim {
    id
    billingStatus
  }
` as unknown as TypedDocumentNode<SimIdCellFragment, undefined>
