import { ButtonLink } from '@npco/zeller-design-system'
import { ModalWithButtons, useModalState } from 'design-system/Components/Modal'
import { Formik } from 'formik'

import { translate } from 'utils/translations'
import { ThirdPartyBankAccount } from 'types/accounts'

import { ThirdPartyDetailAccountForm } from './AccountForm/ThirdPartyDetailAccountForm'
import { useSubmitEditAccountDetail } from './EditAccountDetails.hook'

interface Values {
  accountName: string
}

interface Props {
  account: ThirdPartyBankAccount
  onSubmit?: (updatedAccount: ThirdPartyBankAccount) => void
}

export const EditThirdPartyAccountDetailsModal = ({
  onSubmit,
  account,
}: Props) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const initialValues = {
    accountName: account.accountName || '',
  }

  const { editAccountDetail, submitting } = useSubmitEditAccountDetail({
    onSubmit: (updatedAccount) => {
      closeModal()
      onSubmit?.(updatedAccount)
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: Values) => {
          editAccountDetail(values, account.id)
        }}
        component={({ isValid, submitForm, resetForm }) => {
          return (
            <ModalWithButtons
              isOpen={isModalOpen}
              onConfirm={submitForm}
              onCancel={() => {
                closeModal()
                resetForm({
                  values: { accountName: account.accountName || '' },
                })
              }}
              primaryButtonLabel={translate('shared.confirm')}
              secondaryButtonLabel={translate('shared.cancel')}
              isPrimaryButtonDisabled={!isValid}
              isPrimaryButtonLoading={submitting}
              hasCancelButton
              title={translate('page.settings.editDepositAccountDetail.title')}
            >
              <ThirdPartyDetailAccountForm />
            </ModalWithButtons>
          )
        }}
      />
      <ButtonLink onClick={openModal}>
        {translate('page.settings.editDepositAccountDetail.name')}
      </ButtonLink>
    </>
  )
}
