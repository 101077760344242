import { Flex } from '@npco/zeller-design-system'

import { Details, Label } from './TextRow.styled'

interface TextRowProps {
  label: string
  details: string
}

export const TextRow = ({ label, details }: TextRowProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="flex-start" gridGap="16px">
      <Label>{label}</Label>
      <Details>{details}</Details>
    </Flex>
  )
}
