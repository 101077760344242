import { useEffect } from 'react'
import { getSardineConfig, setupSardine } from '@sardine-ai/web-sdk'

import {
  IS_PRODUCTION,
  SARDINE_API_HOST,
  SARDINE_PIXEL_HOST,
  ZELLER_SESSION_CLIENT_ID,
} from 'const/envs'

export const useSetupSardine = () => {
  useEffect(() => {
    const sardineConfig = getSardineConfig()
    if (ZELLER_SESSION_CLIENT_ID && !sardineConfig) {
      setupSardine({
        clientId: ZELLER_SESSION_CLIENT_ID,
        environment: IS_PRODUCTION ? 'production' : 'sandbox',
        apiSubdomain: SARDINE_API_HOST,
        pixelSubdomain: SARDINE_PIXEL_HOST,
      })
    }
  }, [])
}
