import React, { ReactNode, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvStepUpAuthDepositsObject } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/auth'

import { translate } from 'utils/translations'
import { DepositsApiCallTypeEnum } from 'types/auth'
import { useDepositAccount } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'
import { useDepositsQueryRetry } from 'pages/Settings/DepositsSettings/hooks/useDepositsQueryRetry'
import { LoaderWithText } from 'components/LoaderWithText'
import { SpinnerWrapped } from 'components/Spinner'

import { StyledLoaderWrapper } from './DepositSettingFetcher.styled'

export const DepositSettingsFetcher = ({
  children,
}: {
  children: ReactNode
}) => {
  const { isLoading } = useDepositAccount()

  const { retryApiCall, isLoading: isLoadingQuery } = useDepositsQueryRetry()
  const stepUpAuthObject = useReactiveVar(rvStepUpAuthDepositsObject)

  useEffect(() => {
    if (stepUpAuthObject?.shouldCallApiAgain && !isLoading && !isLoadingQuery) {
      retryApiCall()
    }
  }, [
    isLoading,
    stepUpAuthObject.shouldCallApiAgain,
    stepUpAuthObject,
    retryApiCall,
    isLoadingQuery,
  ])

  if (stepUpAuthObject?.shouldCallApiAgain) {
    const loaderText = translate(
      stepUpAuthObject.apiCallType === DepositsApiCallTypeEnum.ADD
        ? 'page.settings.deposits.retry.add'
        : 'page.settings.deposits.retry.change',
      {
        value: stepUpAuthObject.name,
      }
    )
    return (
      <StyledLoaderWrapper>
        <LoaderWithText loaderText={loaderText} />
      </StyledLoaderWrapper>
    )
  }

  if (isLoading) {
    return <SpinnerWrapped variant="centre" />
  }

  return <>{children}</>
}
