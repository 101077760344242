import { Navigate, Outlet, Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { useEztaTermsOfService } from 'hooks/useEztaTermsOfService/useEztaTermsOfService'
import { ROOT } from 'const/routes'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

import { NotFound } from '../NotFound'
import { DashboardFinance } from './DashboardFinance'
import { DashboardLayout } from './DashboardLayout'
import { DashboardPayments } from './DashboardPayments'
import { useSavedDashboardRoute } from './RememberDashboardTab'

export const DashboardRoutes = () => {
  const { hasRejectedTermsOfService } = useEztaTermsOfService()

  const savedDashboardRoute = useSavedDashboardRoute()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        }
      >
        <Route
          path={ROOT.ORGS.ORG().OVERVIEW.PAYMENTS.relative}
          element={<DashboardPayments />}
        />
        <Route
          path={ROOT.ORGS.ORG().OVERVIEW.FINANCE.relative}
          element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
        >
          <Route
            index
            element={
              hasRejectedTermsOfService ? (
                <Navigate
                  to={ROOT.ORGS.ORG(shortEntityId).OVERVIEW.PAYMENTS.path}
                  replace
                />
              ) : (
                <DashboardFinance />
              )
            }
          />
        </Route>
      </Route>

      <Route index element={<Navigate to={savedDashboardRoute} replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
