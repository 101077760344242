import { InvoiceStatus } from '@npco/mp-gql-types'
import { ACCORDION_FORM_STATE } from '@npco/zeller-design-system'
import { ContactComboBoxItem } from '@npco/zeller-design-system/dist/FeatureComponents/Contacts/ContactCombobox/ContactCombobox.types'
import { ContactBasicType } from 'features/Contacts/Contacts.types'
import { getContactName } from 'features/Contacts/Contacts.utils'
import {
  InvoiceContactValues,
  InvoiceFormFields,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { FormikErrors, FormikTouched } from 'formik'

export const getAccordionFormState = ({
  errors,
  payerId,
  touchedCustomerFields,
}: {
  errors?: FormikErrors<InvoiceFormFields['customer']>
  payerId: string
  touchedCustomerFields?: FormikTouched<InvoiceFormFields['customer']>
}) => {
  const isPayerIdTouched = touchedCustomerFields?.payerContact

  const hasError = Boolean(errors)

  if (isPayerIdTouched && payerId && !hasError) {
    return ACCORDION_FORM_STATE.COMPLETE
  }

  const isPayerTouchedWithError =
    isPayerIdTouched && Boolean(errors?.payerContact)

  if (isPayerTouchedWithError) {
    return ACCORDION_FORM_STATE.ATTENTION
  }

  return ACCORDION_FORM_STATE.EMPTY
}

export const getCustomerSelectedItem = (
  customerValue: InvoiceContactValues | null
): ContactComboBoxItem | null => {
  if (!customerValue) {
    return null
  }

  return {
    id: customerValue?.contactUuid || '',
    label: customerValue?.contactName || '',
    value: customerValue?.contactName || '',
  }
}

export const isCustomerFieldsDisabled = (status: InvoiceStatus) =>
  status !== InvoiceStatus.DRAFT && status !== InvoiceStatus.SEND_FAILED

export const getCustomerFormValues = (contact: ContactBasicType) => ({
  contactName: getContactName(contact) ?? '',
  contactType: contact.contactType,
  contactUuid: contact.id,
})

export const getCustomerFieldError = (
  field: keyof FormikErrors<InvoiceFormFields['customer']>,
  customer?: FormikErrors<InvoiceFormFields['customer']>
) => {
  if (!customer?.[field]) {
    return null
  }

  return typeof customer[field] === 'string'
    ? customer[field]
    : (customer[field] as FormikErrors<InvoiceContactValues>).contactUuid
}
