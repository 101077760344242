import { useCallback, useEffect, useMemo } from 'react'
import { Box, Flex } from '@npco/zeller-design-system'
import { SelectCompactField } from 'design-system/Components/Select'
import { Form, Formik, FormikProps } from 'formik'

import { useGetSites } from 'hooks/useGetSites'
import { sortAscendingByName } from 'utils/common'
import { translate } from 'utils/translations'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
  ModalHeader,
} from 'components/Modal/ModalElements/ModalElements'
import { SpinnerWrapped } from 'components/Spinner'

interface ModalProps {
  siteId: string
  terminalId: string
  modalDescription: string
  modalTitle?: string
  closeModal: () => void
  handleReassign?: ({
    siteId,
    deviceId,
  }: {
    siteId: string
    deviceId: string
  }) => void
  isSiteDevicesPage?: boolean
}

export const SITES_QUERY_PAGE_SIZE = 100

export const ReassignDeviceModal: React.FC<ModalProps> = ({
  modalDescription,
  modalTitle,
  siteId,
  terminalId,
  closeModal,
  handleReassign,
  isSiteDevicesPage,
}) => {
  const handleClick = useCallback(
    (values: { site: string }) => {
      handleReassign?.({ siteId: values.site, deviceId: terminalId })
      closeModal()
    },
    [closeModal, handleReassign, terminalId]
  )
  const fetchPolicy = isSiteDevicesPage ? 'cache-and-network' : 'cache-first'

  const {
    sites,
    isLoading: areSitesInitiallyLoading,
    hasMore,
    onLoadMore,
    isLoadingMore,
  } = useGetSites({
    pageSize: SITES_QUERY_PAGE_SIZE,
    fetchPolicy,
    nextFetchPolicy: fetchPolicy,
  })

  const sortedSites = useMemo(() => sortAscendingByName(sites), [sites])

  useEffect(() => {
    if (hasMore && !isLoadingMore) {
      onLoadMore()
    }
  }, [hasMore, isLoadingMore, onLoadMore])

  const selectValues = useMemo(
    () =>
      sortedSites.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [sortedSites]
  )
  const isLoading = areSitesInitiallyLoading || hasMore
  const actionTitle =
    modalTitle ?? translate('component.modal.reassignTerminal.title')

  return (
    <Box
      // CardModal component is badly written and we have to add this tabIndex to properly handle a11y using `focus-trap-react` package
      tabIndex={isLoading ? 0 : -1}
      data-testid="reassign-modal"
    >
      <ModalHeader>{actionTitle}</ModalHeader>
      <ModalDescription>{modalDescription}</ModalDescription>
      {isLoading && <SpinnerWrapped justifyContent="center" />}
      {!isLoading && (
        <Formik initialValues={{ site: siteId }} onSubmit={handleClick}>
          {({ dirty, submitForm, values }: FormikProps<{ site: string }>) => {
            return (
              <Form>
                <Box mb="2rem">
                  <SelectCompactField
                    items={selectValues}
                    name="site"
                    mobileLabel={actionTitle}
                  />
                </Box>
                <Flex justifyContent="space-between">
                  <CancelButton onClick={closeModal}>
                    {translate('shared.cancel')}
                  </CancelButton>
                  <ConfirmButton
                    onClick={submitForm}
                    disabled={!dirty || siteId === values.site}
                    data-testid="modal-confirm-btn"
                  >
                    {translate('component.card.reassign')}
                  </ConfirmButton>
                </Flex>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}
