import { useMutation } from '@apollo/client'

import {
  ConfigureXeroPaymentServices as ConfigureXeroPaymentServicesResponse,
  ConfigureXeroPaymentServicesVariables,
} from 'types/gql-types/ConfigureXeroPaymentServices'

import { ConfigureXeroPaymentServices } from '../graphql/ConfigureXeroPaymentServices'

export const useConfigurePaymentServices = () => {
  const [configureXeroPaymentServices, { loading, error }] = useMutation<
    ConfigureXeroPaymentServicesResponse,
    ConfigureXeroPaymentServicesVariables
  >(ConfigureXeroPaymentServices, {
    notifyOnNetworkStatusChange: true,
  })

  return {
    isLoading: loading,
    error,
    configureXeroPaymentServices,
  }
}
