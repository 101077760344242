import {
  IncomeAndExpenseChart,
  IncomeAndExpenseError,
  InverseIncomeOrExpenseMessage,
  useChartDateRange,
} from '@npco/mp-feature-cashflow-reporting'
import { useTranslations } from '@npco/utils-translations'
import dayjs from 'dayjs'

import { AccountsIncomeAndExpenseTopSection } from './AccountsIncomeAndExpenseTopSection/AccountsIncomeAndExpenseTopSection'
import { accountsTransactionsIncomeAndExpenseTranslations } from './AccountsTransactionsIncomeAndExpense.i18n'
import * as styled from './AccountsTransactionsIncomeAndExpense.styled'
import { useAccountsIncomeAndExpenseData } from './hooks/useAccountsIncomeAndExpense/useAccountsIncomeAndExpenseData'

type AccountsTransactionsIncomeAndExpenseProps = {
  entityTimezone: string | undefined
  isLoadingTimezone: boolean
}

export const AccountsTransactionsIncomeAndExpense = ({
  entityTimezone,
  isLoadingTimezone,
}: AccountsTransactionsIncomeAndExpenseProps) => {
  const t = useTranslations(accountsTransactionsIncomeAndExpenseTranslations)

  const {
    chartDate,
    goToPreviousMonth,
    goToNextMonth,
    hasInitialisedTimezone,
  } = useChartDateRange({
    entityTimezone,
    isLoadingTimezone,
  })

  const {
    accountsIncomeAndExpenses: incomeAndExpenses,
    isLoadingIncomeAndExpenses,
    error,
    refetch,
    isRefetching,
    isFetchingMore,
    hasInverseAccountsExpenseOrIncome,
  } = useAccountsIncomeAndExpenseData({
    date: chartDate,
    timeZone: entityTimezone,
    skip: !hasInitialisedTimezone,
  })

  const isLoading =
    (isLoadingIncomeAndExpenses && incomeAndExpenses.length === 0) ||
    isRefetching ||
    isLoadingTimezone ||
    !hasInitialisedTimezone

  const shouldDisableNextButton = !dayjs(chartDate)
    .endOf('month')
    .isBefore(dayjs().tz(entityTimezone))

  return (
    <styled.IncomeAndExpenseWrapper
      aria-label="Account balance over time"
      as="section"
    >
      <AccountsIncomeAndExpenseTopSection
        isFetchingMore={isFetchingMore}
        incomeAndExpenses={incomeAndExpenses}
      />
      {error ? (
        <IncomeAndExpenseError
          title={t('errorTitle')}
          description={t('errorDescription')}
          handleTryAgain={refetch}
        />
      ) : (
        <>
          <IncomeAndExpenseChart
            isLoadingIncomeAndExpenses={isLoading}
            incomeAndExpenses={incomeAndExpenses}
            goToPreviousMonth={goToPreviousMonth}
            goToNextMonth={shouldDisableNextButton ? undefined : goToNextMonth}
            isFetchingMore={isFetchingMore}
            timezone={entityTimezone}
          />
          {!isLoading && hasInverseAccountsExpenseOrIncome && (
            <InverseIncomeOrExpenseMessage />
          )}
        </>
      )}
    </styled.IncomeAndExpenseWrapper>
  )
}
