import { ProductLaunchModalContent } from 'features/StartupModals/CMSProductLaunchModal/ProductLaunchModalContent'

import { type StartUpModal } from './hooks/useStartupModalsSession/useStartupModalsSession.types'
import { PushAppModal } from './PushAppModal/PushAppModal'
import { SetupFlowProvider } from './SetupFlow/contexts/SetupFlowProvider'
import { SetupFlow } from './SetupFlow/SetupFlow'
import { XeroErrorModal } from './XeroErrorModal/XeroErrorModal'

type StartupModalSwitchProps = {
  startupModal: StartUpModal | null
  shouldShow: boolean
  slug?: string | null
}

export const StartupModalSwitch = ({
  startupModal,
  shouldShow,
  slug,
}: StartupModalSwitchProps) => {
  const shouldShowSetupModal = shouldShow && startupModal === 'setup'

  if (shouldShowSetupModal) {
    return (
      <SetupFlowProvider>
        <SetupFlow />
      </SetupFlowProvider>
    )
  }

  const shouldShowXeroModal = shouldShow && startupModal === 'xero'

  if (shouldShowXeroModal) {
    return <XeroErrorModal />
  }

  const shouldShowCMSModal = !!slug

  if (shouldShowCMSModal) {
    return <ProductLaunchModalContent slug={slug} />
  }

  const shouldShowPushAppModal = shouldShow && startupModal === 'pushApp'

  if (shouldShowPushAppModal) {
    return <PushAppModal />
  }

  return null
}
