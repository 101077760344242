import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type SubmitStaticCrnBpayPaymentMutationVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  input: Types.SubmitStaticCrnBpayPaymentInput
}>

export type SubmitStaticCrnBpayPaymentMutationResponse = {
  __typename?: 'Mutation'
  submitStaticCrnBpayPayment: {
    __typename?: 'SubmitBpayPaymentResponse'
    result: {
      __typename?: 'SubmitBpayPaymentResponseResult'
      id: string
      debitCardAccountUuid: string
      paymentInstrumentUuid: string
      billerName: string
      billerCode: string
      crn: string
      amount: { __typename?: 'Money'; currency: string; value: string }
    } | null
  }
}

export const SubmitStaticCrnBpayPayment = gql`
  mutation SubmitStaticCrnBpayPayment(
    $entityUuid: ID!
    $input: SubmitStaticCrnBpayPaymentInput!
  ) {
    submitStaticCrnBpayPayment(entityUuid: $entityUuid, input: $input) {
      result {
        id
        debitCardAccountUuid
        paymentInstrumentUuid
        billerName
        billerCode
        crn
        amount {
          currency
          value
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  SubmitStaticCrnBpayPaymentMutationResponse,
  SubmitStaticCrnBpayPaymentMutationVariables
>
export type SubmitStaticCrnBpayPaymentMutationFn = Apollo.MutationFunction<
  SubmitStaticCrnBpayPaymentMutationResponse,
  SubmitStaticCrnBpayPaymentMutationVariables
>

/**
 * __useSubmitStaticCrnBpayPaymentMutation__
 *
 * To run a mutation, you first call `useSubmitStaticCrnBpayPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStaticCrnBpayPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStaticCrnBpayPaymentMutation, { data, loading, error }] = useSubmitStaticCrnBpayPaymentMutation({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitStaticCrnBpayPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitStaticCrnBpayPaymentMutationResponse,
    SubmitStaticCrnBpayPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitStaticCrnBpayPaymentMutationResponse,
    SubmitStaticCrnBpayPaymentMutationVariables
  >(SubmitStaticCrnBpayPayment, options)
}
export type SubmitStaticCrnBpayPaymentMutationHookResult = ReturnType<
  typeof useSubmitStaticCrnBpayPaymentMutation
>
export type SubmitStaticCrnBpayPaymentMutationResult =
  Apollo.MutationResult<SubmitStaticCrnBpayPaymentMutationResponse>
export type SubmitStaticCrnBpayPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitStaticCrnBpayPaymentMutationResponse,
    SubmitStaticCrnBpayPaymentMutationVariables
  >
