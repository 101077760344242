import { type ReactNode, useCallback } from 'react'
import { FloatingFocusManager } from '@floating-ui/react'
import { useTranslations } from '@npco/utils-translations'
import {
  BUTTON_SIZE,
  ButtonGhost,
  PillSelectTrigger,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { useFloatingPill } from '../useFloatingPill'
import * as styled from './FilterPopper.styled'

export type FilterPopperProps = {
  active?: boolean
  children?: () => ReactNode
  count?: number
  label: ReactNode
  onClear?: () => void
}

export const FilterPopper = ({
  active,
  children,
  count,
  label,
  onClear,
}: FilterPopperProps) => {
  const tShared = useTranslations(translationsShared)
  const {
    isOpen,
    floatingProps,
    referenceProps: triggerProps,
    transitionProps,
    close,
    context,
  } = useFloatingPill()

  // @workaround The `label` prop does indeed work with a `ReactNode`.
  const labelAsString = label as unknown as string

  const handlePillClear = useCallback(() => {
    close()
    onClear?.()
  }, [close, onClear])

  return (
    <>
      <styled.PopperTrigger {...triggerProps}>
        <PillSelectTrigger
          open={isOpen}
          label={labelAsString}
          counter={count ? count.toString() : undefined}
          filled={active}
          onClear={handlePillClear}
        />
      </styled.PopperTrigger>
      {transitionProps.isMounted && (
        <FloatingFocusManager context={context} modal={false}>
          <styled.PopperAnchor {...floatingProps}>
            <styled.PopperWrapper style={transitionProps.style}>
              {children?.()}
              <styled.PopperHr />
              <styled.PopperFooter>
                <ButtonGhost
                  onClick={onClear}
                  size={BUTTON_SIZE.XSMALL}
                  disabled={!onClear}
                >
                  {tShared('clear')}
                </ButtonGhost>
              </styled.PopperFooter>
            </styled.PopperWrapper>
          </styled.PopperAnchor>
        </FloatingFocusManager>
      )}
    </>
  )
}
