import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { ContactType, InvoiceStatus } from '@npco/mp-gql-types'
import { TestContext } from 'yup'

import dayjs from 'utils/dayjs'

import { InvoiceFormFields } from '../Invoice.types'

export const areScheduleDatesValid = (invoiceDate?: Date, dueDate?: Date) => {
  if (!invoiceDate || !dueDate) {
    return false
  }

  if (
    !dayjs(invoiceDate, undefined, true).isValid() ||
    !dayjs(dueDate, undefined, true).isValid()
  ) {
    return false
  }

  return true
}

export const isPlaceholderDateValue = (dateString: string) =>
  dateString === '__/__/____' || dateString === DATE_FULL_DISPLAY_FORMAT

export const toDayjsObject = (dateString: string) =>
  dayjs(dateString, DATE_FULL_DISPLAY_FORMAT, true)

export const validateSendIsNotEmpty = (
  date: Date | undefined,
  { parent: { sendEnabled } }: TestContext
) => {
  if (!sendEnabled) return true

  return !!date
}

export const validateSendDate =
  (compareCurrentDate?: boolean) =>
  (
    _date: Date | undefined,
    {
      parent: { sendEnabled, dueDate, sendDate },
      options: { context },
    }: TestContext<{
      values?: InvoiceFormFields
      initialValues?: InvoiceFormFields
    }>
  ) => {
    const status = context?.values?.status

    if (!sendEnabled) {
      return true
    }

    const isDraftInvoice = status === InvoiceStatus.DRAFT

    const initialSendDate = context?.initialValues?.schedule.sendDate
    const nextSendDate = context?.values?.schedule.sendDate

    const initialDueDate = context?.initialValues?.schedule.dueDate
    const nextDueDate = context?.values?.schedule.dueDate

    if (!isDraftInvoice) {
      // NOTE: if values have not changed skip validation
      if (initialSendDate === nextSendDate && initialDueDate === nextDueDate) {
        return true
      }
    }

    const schedule = dayjs(sendDate)
    const today = dayjs()
    const due = dayjs(dueDate)

    // NOTE: If the Send Date is invalid, we shouldn't keep showing an error
    // message on the Due Date.
    if (!schedule.isValid() || typeof sendDate === 'undefined') {
      return true
    }

    if (compareCurrentDate) {
      return schedule.isAfter(today)
    }

    return schedule.isSameOrBefore(due)
  }

export const validateScheduleDueDate =
  (compareCurrentDate?: boolean) =>
  (
    dueDate: Date | undefined,
    {
      parent: { invoiceDate },
      options,
    }: TestContext<{
      attentionToContactType?: ContactType
      initialValues?: InvoiceFormFields
      payerContactType?: ContactType
      values?: InvoiceFormFields
    }>
  ) => {
    const status = options.context?.values?.status

    const initialInvoiceDate =
      options.context?.initialValues?.schedule.invoiceDate
    const nextInvoiceDate = options.context?.values?.schedule.invoiceDate

    const initialDueDate = options.context?.initialValues?.schedule.dueDate
    const nextDueDate = options.context?.values?.schedule.dueDate

    const isDraftInvoice = status === InvoiceStatus.DRAFT

    if (!isDraftInvoice) {
      // NOTE: if values have not changed skip validation
      if (
        initialInvoiceDate === nextInvoiceDate &&
        initialDueDate === nextDueDate
      ) {
        return true
      }

      // NOTE: if due date has not changed ensure invoice date is still on or
      // before due date if so skip validation
      if (initialDueDate === nextDueDate) {
        return dayjs(dueDate).isSameOrAfter(invoiceDate)
      }
    }

    // NOTE: skip date comparison validation if one of the dates is invalid
    if (!areScheduleDatesValid(invoiceDate, dueDate)) {
      return true
    }

    if (compareCurrentDate) {
      return dayjs(dueDate).isSameOrAfter(dayjs().startOf('day'))
    }

    return dayjs(dueDate).isSameOrAfter(invoiceDate)
  }
