import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'SIM Card',
  billingAccount: 'Billing Account',
  simNumber: 'SIM Number',
  site: 'Site',
  terminal: 'Terminal',
  plan: 'Plan',
  nextRecharge: 'Next Recharge',
  abortCancelSimSuccessToast: 'SIM Card was reactivated successfully.',
  cancelSimSuccessToast: 'SIM Card was cancelled successfully.',
  activateSimSuccessToast: 'SIM Card was activated successfully.',
})
