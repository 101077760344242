import {
  ListCardButton,
  SkeletonAvatar,
  SkeletonBasic,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

const Avatar = () => <SkeletonAvatar />

export const AccountStatementSkeleton = () => {
  const isMobile = useIsMobileResolution()

  return (
    <ListCardButton
      image={Avatar}
      title={
        <SkeletonBasic
          isRounded
          width={isMobile ? '163px' : '323px'}
          height="12px"
        />
      }
      readonly
    />
  )
}
