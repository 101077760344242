import { useShowMore } from '../context/useShowMore'

interface TogglableContentProps {
  children: React.ReactNode
}

export const TogglableContent = ({ children }: TogglableContentProps) => {
  const { isShowingMore } = useShowMore()

  return isShowingMore ? <>{children}</> : null
}
