import { useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ColourPicker, ModalForm } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translationsShared } from 'translations'

import { translations } from './ColourPickerModal.i18n'

const DEFAULT_INVOICE_COLOUR = '#000000'

export const ColourPickerModal = ({
  initialColour,
  onSaveColour,
  isOpen,
  onClose,
}: {
  initialColour?: string
  onSaveColour: (colour: string) => void
  isOpen: boolean
  onClose: () => void
}) => {
  const [selectedColour, setSelectedColour] = useState<string>(
    initialColour ?? DEFAULT_INVOICE_COLOUR
  )
  const [hasError, setHasError] = useState<boolean>(false)
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)
  const isMobile = useIsMobileResolution()

  const handleSetColour = (colour: string) => {
    setSelectedColour(colour)
    setHasError(false)
  }

  const handleSaveColour = () => {
    if (!hasError) {
      onSaveColour(selectedColour)
      onClose()
    }
  }

  return (
    <ModalForm
      canScroll={false}
      showOverflow
      title={isMobile ? t('titleMobile') : t('titleDesktop')}
      onClickPrimary={handleSaveColour}
      isOpen={isOpen}
      onCancel={onClose}
      primaryButtonLabel={tShared('save')}
      secondaryButtonLabel={tShared('cancel')}
    >
      <ColourPicker
        value={selectedColour}
        onValue={handleSetColour}
        errorMessage={t('errorMessage')}
        onError={() => setHasError(true)}
      />
    </ModalForm>
  )
}
