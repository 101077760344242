import { BodyDefault, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledLoadingWrapper = styled(Flex)`
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.25rem;
  margin-top: 4.25rem;
`

export const StyledSubtextWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 1.5rem;
`

export const StyledLoadingSubtext = styled(BodyDefault)`
  align-self: center;
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`
