import { FileExtension } from '../types/exportFormat'

interface Props<T extends keyof typeof FileExtension> {
  link: string
  filename: string
  format: T
}

const downloadData = async (link: string) => {
  const response = await fetch(link)
  if (!response.ok) {
    throw new Error('Something went wrong while downloading the file.')
  }
  const blob = await response.blob()
  return window.URL.createObjectURL(blob)
}

const createAnchor = (
  url: string,
  filename: string,
  format: keyof typeof FileExtension
) => {
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename}.${FileExtension[format]}`
  return a
}

const downloadFile = (a: HTMLAnchorElement) => {
  a.click()
  a.remove()
}

export const renameAndDownloadFile = async <
  T extends keyof typeof FileExtension
>({
  link,
  filename,
  format,
}: Props<T>) => {
  const url = await downloadData(link)
  const a = createAnchor(url, filename, format)
  downloadFile(a)
}
