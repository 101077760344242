import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { EmptyStateMessageOnly } from 'components/EmptyState/EmptyStateMessageOnly'

import { translations } from './EmptyState.i18n'

export const EmptyState = () => {
  const t = useTranslations(translations)

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            <Breadcrumb>
              <Breadcrumb.Text>{t('transactions')}</Breadcrumb.Text>
            </Breadcrumb>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <EmptyStateMessageOnly
        title={t('title')}
        description={t('description')}
        illustrationName="AccountCreated"
      />
    </PageTemplate>
  )
}
