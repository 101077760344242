import { gql } from '@apollo/client'
import { ExtendedColumnDef } from '@npco/ui-table'
import { TableCellIconText, TooltipBasic } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { SimIcon } from '../../components/SimIcon/SimIcon'
import { SimIdCellFragment } from './SimIdCell.generated'

export const SimIdCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, SimIdCellFragment>) => {
  const columnDefValue = column.columnDef as ExtendedColumnDef<T>
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<SimIdCellFragment>()

  return (
    <TableCellIconText
      key={cell.id}
      cellSize={columnDefValue.cellSize}
      dataTestId={`sim-desktop-row-${row.index}-id`}
      isLoading={isLoading}
      icon={<SimIcon billingStatus={data?.billingStatus} />}
      text={
        <TooltipBasic
          showArrow={false}
          placement="top"
          renderTrigger={({ handlers }) => (
            <span data-testid="masked-id" {...handlers}>{`...${data?.id?.slice(
              -4
            )}`}</span>
          )}
        >
          {data?.id}
        </TooltipBasic>
      }
      skeletonProps={{ width: '55%' }}
    />
  )
}

SimIdCell.fragments = {
  Sim: gql`
    fragment SimIdCellFragment on Sim {
      id
      billingStatus
    }
  `,
}
