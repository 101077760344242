import { MouseEventHandler, useMemo } from 'react'
import {
  AvatarBasic,
  Box,
  COLOR,
  SelectStyle,
  SvgIcon,
  TickIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as EditIcon } from 'assets/svg/pen.svg'
import { conditionalClassName as cn } from 'utils/conditionalClassName'
import { translate } from 'utils/translations'

import { SelectItemBasic, SelectSize } from '../Select.types'
import {
  StyledContent,
  StyledDividerSubLabel,
  StyledEditWrapper,
  StyledIconWrapper,
  StyledImageWrapper,
  StyledSelectLabel,
  StyledSelectSubLabel,
  StyledSelectSubLabelsWrapper,
  StyledWrapper,
} from './SelectComponentsContent.styled'
import { getAvatarSize } from './SelectComponentsContent.utils'

interface Props extends Omit<SelectItemBasic, 'value'> {
  size?: SelectSize
  selectStyle?: SelectStyle
  hasValue?: boolean
  shouldBeMarkedAsSelected?: boolean
}

export const SelectComponentsContent = ({
  image,
  label,
  subLabel1,
  subLabel2,
  icon,
  avatar,
  hasValue,
  size = SelectSize.Medium,
  selectStyle = SelectStyle.Standard,
  shouldBeMarkedAsSelected,
  onEdit,
  isDisabled,
  color,
}: Props) => {
  const areTwoLines = useMemo(
    () => Boolean(subLabel1 ?? subLabel2),
    [subLabel1, subLabel2]
  )

  const handleEdit: MouseEventHandler = (e) => {
    e.stopPropagation()
    onEdit?.()
  }

  return (
    <StyledWrapper>
      {image && (
        <StyledImageWrapper
          className={cn({
            isDisabled: Boolean(isDisabled),
          })}
          data-testid="image-wrapper"
        >
          {image}
        </StyledImageWrapper>
      )}
      {icon && (
        <StyledImageWrapper
          data-testid="icon-wrapper"
          className={cn({
            [size]: true,
            isDisabled: Boolean(isDisabled),
          })}
        >
          <StyledIconWrapper badgeSize={getAvatarSize(size)}>
            <SvgIcon height="16" width="16">
              {icon}
            </SvgIcon>
          </StyledIconWrapper>
        </StyledImageWrapper>
      )}
      {avatar && (
        <StyledImageWrapper
          data-testid="avatar-wrapper"
          className={cn({
            isDisabled: Boolean(isDisabled),
          })}
        >
          <AvatarBasic
            bgColor={avatar.bgColor}
            color={avatar.color}
            text={avatar.letter}
            badgeSize={getAvatarSize(size)}
          />
        </StyledImageWrapper>
      )}
      <StyledContent>
        <StyledSelectLabel
          className={cn({
            oneLine: !areTwoLines,
            twoLines: areTwoLines,
            [size]: true,
            [selectStyle]: true,
            placeholder: !hasValue,
            isDisabled: Boolean(isDisabled),
          })}
          color={color}
        >
          {label}
        </StyledSelectLabel>
        {areTwoLines && (
          <StyledSelectSubLabelsWrapper data-testid="labels-wrapper">
            <StyledSelectSubLabel
              className={cn({ isDisabled: Boolean(isDisabled) })}
            >
              {subLabel1}
            </StyledSelectSubLabel>
            {subLabel1 && subLabel2 && (
              <StyledDividerSubLabel data-testid="labels-divider" />
            )}
            <StyledSelectSubLabel
              className={cn({ isDisabled: Boolean(isDisabled) })}
            >
              {subLabel2}
            </StyledSelectSubLabel>
          </StyledSelectSubLabelsWrapper>
        )}
      </StyledContent>
      {shouldBeMarkedAsSelected && (
        <Box pr="18px" data-testid="selected-indicator">
          <TickIcon />
        </Box>
      )}
      {onEdit && (
        <StyledEditWrapper
          onClick={handleEdit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation()
            }
          }}
          aria-label={translate('shared.edit')}
          type="button"
        >
          <SvgIcon height="16" width="16" hoverColor={COLOR.BLUE_1000}>
            <EditIcon />
          </SvgIcon>
        </StyledEditWrapper>
      )}
    </StyledWrapper>
  )
}
