import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Message,
  MessageSize,
  SomethingWentWrongDominoes,
} from '@npco/zeller-design-system'

import { translations } from './TransactionsSummaryError.i18n'

interface TransactionsSummaryErrorProps {
  onRetry: () => void
}
export const TransactionsSummaryError = ({
  onRetry,
}: TransactionsSummaryErrorProps) => {
  const t = useTranslations(translations)

  return (
    <Box maxWidth="600px" margin="auto">
      <Message
        description={t('description')}
        title={t('title')}
        image={<SomethingWentWrongDominoes size="large" />}
        size={MessageSize.LARGE}
        primaryButton={{
          label: t('tryAgain'),
          onClick: onRetry,
        }}
      />
    </Box>
  )
}
