import {
  BodyDefaultTypography,
  BodySmallTypography,
  PersonIcon,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ReactComponent as BPaySVG } from 'assets/svg/bpay.svg'

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.BLUE_50};
  }
`

export const ItemContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ItemContentLabel = styled.div`
  ${BodyDefaultTypography}
`

export const ItemContentSublabel = styled.div`
  ${BodySmallTypography}
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const ItemIconBox = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// @workaround Unable to resize icon with width/height props because it clips.
export const ItemIconBoxPersonIcon = styled((props) => (
  <div {...props}>
    <PersonIcon />
  </div>
))`
  transform: scale(70%);
`

export const ItemIconBoxBPayIcon = styled((props) => (
  <div {...props}>
    <BPaySVG />
  </div>
))``
