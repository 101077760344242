import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledAccountFilterDateContent = styled.div`
  overflow: auto;
  max-height: inherit;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 2px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.GREY_150};
    border-radius: 4px;
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    &::-webkit-scrollbar {
      background-color: transparent;
      height: 2px;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.GREY_150};
      border-radius: 3px;
    }
  }
}
`
