import { SelectSize } from '@npco/zeller-design-system'
import Downshift from 'downshift'

import { ReactComponent as SourceIcons } from 'assets/svg/source.svg'
import { TRANSACTION_SOURCES } from 'const/common'
import { getSelectedItemsInfo } from 'utils/picker'
import { translate } from 'utils/translations'
import { PickerItemProps } from 'types/picker'
import { Checkbox } from 'components/Input/Checkbox'
import { useMultiDownshiftState } from 'components/MultiDownshift/MultiDownshift.hooks'
import { AllItemsRow, TopRow } from 'components/PickerElements'
import { StyledRow } from 'components/PickerElements/Elements.styled'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { StyledDropdownWrapper } from 'components/Shared'

import { DropdownWrapper } from './SourcePicker.styled'
import { useSourcePicker } from './useSourcePicker'

export const SourcePicker = () => {
  const transactionSources = TRANSACTION_SOURCES

  const { stateReducer } = useMultiDownshiftState()
  const { selectedSources, setSelectedSources } = useSourcePicker()

  const handleToggleAll = () => {
    if (selectedSources.length === transactionSources.length) {
      setSelectedSources([])
    } else {
      setSelectedSources(transactionSources.map((source) => source))
    }
  }

  const toggleSource = (selectedItem: PickerItemProps): void => {
    if (selectedSources.includes(selectedItem)) {
      setSelectedSources(
        selectedSources.filter((source) => source.id !== selectedItem.id)
      )
      return
    }
    setSelectedSources([...selectedSources, selectedItem])
  }

  return (
    <Downshift stateReducer={stateReducer}>
      {({
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        closeMenu,
        getRootProps,
      }) => {
        return (
          <StyledDropdownWrapper
            {...getRootProps(undefined, { suppressRefError: true })}
            isOpen={isOpen}
            width="100%"
          >
            <PickerTrigger
              getToggleButtonProps={getToggleButtonProps}
              isOpen={isOpen}
              size={SelectSize.Medium}
              triggerContent={
                selectedSources.length
                  ? getSelectedItemsInfo(selectedSources, 'source')
                  : undefined
              }
              placeholderIcon={<SourceIcons />}
              label={getSelectedItemsInfo(selectedSources, 'source')}
            />
            {isOpen && (
              <DropdownWrapper {...getMenuProps()}>
                <TopRow
                  copy={translate('component.sourcePicker.selectSource')}
                  handleCloseMenu={closeMenu}
                />
                <AllItemsRow
                  label={translate('component.overviewSourcePicker.all')}
                  checked={selectedSources.length === transactionSources.length}
                  handleOnChange={handleToggleAll}
                  indeterminate={
                    !!selectedSources.length &&
                    selectedSources.length !== transactionSources.length
                  }
                />
                {transactionSources.map((source) => (
                  <StyledRow
                    as="li"
                    withTopBorder
                    data-testid="dashboard-row"
                    key={source.name}
                  >
                    <Checkbox
                      type="checkbox"
                      name={source.name}
                      label={source.name}
                      checked={selectedSources.includes(source)}
                      {...getItemProps({ item: source })}
                      onChange={() => toggleSource(source)}
                    />
                  </StyledRow>
                ))}
              </DropdownWrapper>
            )}
          </StyledDropdownWrapper>
        )
      }}
    </Downshift>
  )
}
