import { ModalBasic, ModalSize } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

interface PopupModalProps {
  title: string
  isOpen: boolean
  onCancel: () => void
  children: React.ReactNode
  dataTestId?: string
}

export const PopupModal = ({
  title,
  isOpen,
  onCancel,
  children,
  dataTestId,
}: PopupModalProps) => {
  const isMobile = useIsMobileResolution()

  return (
    <ModalBasic
      testId={dataTestId}
      title={title}
      size={ModalSize.SMALL}
      isOpen={isOpen}
      onCancel={onCancel}
      isFullScreen={isMobile}
      hasCloseButton
      shouldCloseOnOverlayClick={false}
    >
      {children}
    </ModalBasic>
  )
}
