import { gql } from '@apollo/client'

export const GetContactImageUploadForm = gql`
  query GetContactImageUploadForm($entityUuid: ID!, $contactUuid: ID!) {
    getContactImageUploadForm(
      entityUuid: $entityUuid
      contactUuid: $contactUuid
    ) {
      url
      formFields
    }
  }
`

export const GetContactTransactionTotals = gql`
  query GetContactTransactionTotals(
    $entityUuid: ID!
    $contactUuid: ID!
    $cardholderUuid: ID
  ) {
    getContactTransactionTotals(
      entityUuid: $entityUuid
      contactUuid: $contactUuid
      cardholderUuid: $cardholderUuid
    ) {
      count
      latestTimestamp
      totalAmount
    }
  }
`

export const GetInvoiceDraftContactUsage = gql`
  query GetInvoiceDraftContactUsage($entityUuid: ID!, $contactUuid: ID!) {
    getInvoiceDraftContactUsage(
      entityUuid: $entityUuid
      contactUuid: $contactUuid
    )
  }
`
