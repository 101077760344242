import { gql } from '@apollo/client'
import { ImageSize } from '@npco/mp-gql-types'

import { GetIconImageUrlBySizeIconFragment } from './iconHelper.generated'

// NOTE: if entity is not supported add appropriate types to the unions
type IconUnion = GetIconImageUrlBySizeIconFragment | null | undefined
type IconImageUnion =
  | NonNullable<GetIconImageUrlBySizeIconFragment['images']>[0]
  | undefined

export const getIconImageUrlBySize = (
  icon: IconUnion,
  size: ImageSize
): string | null => {
  if (!icon) {
    return null
  }

  const image: IconImageUnion = icon.images?.find(
    (element) => element.size === size
  )

  return image?.url ?? null
}

getIconImageUrlBySize.fragments = {
  Icon: gql`
    fragment GetIconImageUrlBySizeIconFragment on Icon {
      images {
        url
        size
      }
    }
  `,
}
