// https://developer.mozilla.org/en-US/docs/Web/API/Element#checkVisibility
type ElementCheckVisibility = () => boolean
export const checkVisibility: ElementCheckVisibility = function checkVisibility(
  this: Element & {
    checkVisibility?: () => boolean
    offsetWidth?: number
    offsetHeight?: number
  }
) {
  if (this.checkVisibility) {
    return this.checkVisibility()
  }
  // Fallback for Safari/Safari iOS.
  return (this.offsetWidth ?? 0) > 0 && (this.offsetHeight ?? 0) > 0
}

export const getBoundingClientRect: Element['getBoundingClientRect'] =
  function getBoundingClientRect(this: Element) {
    return this.getBoundingClientRect()
  }
