import { DatePickerInputExact, INPUT_SIZE } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { useInvoiceDueDate } from '../hooks/useInvoiceDueDate'
import { DATE_PICKER_POPPER_WIDTH } from '../InvoiceScheduleAccordion.constants'

export const translations = {
  invoiceDueDateFieldLabel: translate(
    'page.invoice.formSections.schedule.dueDateLabel'
  ),
}

export const InvoiceDueDate = () => {
  const {
    disabledDatePickerDays,
    fieldError,
    fieldName,
    fieldValue,
    handleBlur,
    handleDateChange,
    isMobileResolution,
  } = useInvoiceDueDate()

  return (
    <DatePickerInputExact
      disabledDays={disabledDatePickerDays}
      error={fieldError}
      hasError={Boolean(fieldError)}
      inputSize={INPUT_SIZE.SMALL}
      label={translations.invoiceDueDateFieldLabel}
      name={fieldName}
      onBlur={handleBlur}
      onChange={handleDateChange}
      popperWidth={isMobileResolution ? undefined : DATE_PICKER_POPPER_WIDTH}
      value={fieldValue}
    />
  )
}
