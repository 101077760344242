import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SetCardSettingsForm } from './SetCardSettingsForm/SetCardSettingsForm'

export const SetCardSettingsStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.CreateSetCardSettingsStage}
    >
      <SetCardSettingsForm />
    </Condition>
  )
}
