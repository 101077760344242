import { gql } from '@apollo/client/core'

export const DebitCardTransactionUpdate = gql`
  subscription DebitCardTransactionUpdate($entityUuid: ID!) {
    onDebitCardTransactionUpdate(entityUuid: $entityUuid) {
      attachments
      cardAcceptorName
      amount {
        value
      }
      debitCardName
      id
      debitCardAccountUuid
      debitCardId
      debitCardMaskedPan
      deposit {
        id
        siteName
      }
      category
      subcategory
      subcategoryDetails {
        id
        name
        predefined
      }
      status
      timestamp
      reference
      referencePayee
      description
      type
      note
      tags
      contact {
        businessName
        contactType
        firstName
        id
        lastName
        icon {
          colour
          images {
            size
            url
          }
          letter
        }
        isSelf
      }
      merchant {
        id
        name
        icon {
          images {
            size
            url
          }
          colour
          letter
        }
      }
      payerDetails {
        accountDetails {
          bsb
          account
          name
        }
        debitCardAccount {
          id
          name
          icon {
            colour
            letter
            image
            images {
              size
              url
            }
            animation
          }
          type
        }
        debitCardAccountUuid
        paymentInstrumentUuid
        senderUuid
      }
      payeeDetails {
        accountDetails {
          bsb
          account
          name
        }
        bpayDetails {
          billerName
          billerCode
          crn
          nickname
        }
        debitCardAccount {
          id
          name
          icon {
            colour
            letter
            image
            images {
              size
              url
            }
            animation
          }
          type
        }
        debitCardAccountUuid
        paymentInstrumentUuid
      }
    }
  }
`
