import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { ProgressBar } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useCardProgress } from 'hooks/useCardProgress'
import { useCardStatus } from 'hooks/useCardStatus'

import * as styled from './CardNameIconCell.styled'
import { CardNameIconProgressCellDebitCardV2Fragment } from './CardNameIconProgressCell.generated'
import { CardNameIconCellBase } from './components/CardNameIconCellBase/CardNameIconCellBase'

export const CardNameIconProgressCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, CardNameIconProgressCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CardNameIconProgressCellDebitCardV2Fragment>()
  const { isClosed, isInactive } = useCardStatus(data)
  const { availableAmount, totalAmount } = useCardProgress(data)

  return (
    <CardNameIconCellBase
      className="card-name-icon-progress-cell"
      card={data}
      content={
        <styled.ContentContainer>
          <styled.TruncateText>{data?.name}</styled.TruncateText>
          {!isClosed && !isInactive && (
            <styled.ProgressContainer>
              <ProgressBar
                disableAnimation
                completedAmount={availableAmount}
                totalAmount={totalAmount}
                size="large"
              />
            </styled.ProgressContainer>
          )}
        </styled.ContentContainer>
      }
      skeletonPropWidth={skeletonLoaderWidth}
      cellId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
    />
  )
}

CardNameIconProgressCell.fragments = {
  DebitCardV2: gql`
    fragment CardNameIconProgressCellDebitCardV2Fragment on DebitCardV2 {
      name
      ...CardNameIconCellBaseDebitCardV2Fragment
      ...UseCardProgressDebitCardV2Fragment
      ...UseCardStatusDebitCardV2Fragment
    }

    ${CardNameIconCellBase.fragments.DebitCardV2}
    ${useCardProgress.fragments.DebitCardV2}
    ${useCardStatus.fragments.DebitCardV2}
  `,
}
