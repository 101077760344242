import { gql } from '@apollo/client'
import { meanBy, sumBy } from 'lodash-es'

import { groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment } from './groupSubcategoriesNetAmount.generated'

export const groupSubcategoriesNetAmount = (
  subcategoryNetAmounts: groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment[]
): groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment[] => {
  if (!subcategoryNetAmounts || subcategoryNetAmounts?.length === 0) {
    return []
  }

  if (subcategoryNetAmounts.length <= 10) {
    return subcategoryNetAmounts
  }

  const remainingCategoryNetAmounts = subcategoryNetAmounts.slice(9)
  return [
    ...subcategoryNetAmounts.slice(0, 9),
    {
      __typename: 'CashFlowSubcategoryNetAmount',
      noOfTransaction: sumBy(remainingCategoryNetAmounts, 'noOfTransaction'),
      average: {
        currency: 'AUD',
        value: meanBy(remainingCategoryNetAmounts, (sub) =>
          parseInt(sub?.average?.value ?? '0', 10)
        ).toString(),
      },
      total: {
        currency: 'AUD',
        value: sumBy(remainingCategoryNetAmounts, (sub) =>
          parseInt(sub?.total?.value ?? '0', 10)
        ).toString(),
      },
      category: subcategoryNetAmounts[9].category,
      subcategoryDetails: {
        id: 'other',
        name: 'other',
        predefined: false,
      },
    },
  ]
}

groupSubcategoriesNetAmount.fragment = {
  CashFlowSubcategoryNetAmount: gql`
    fragment groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      category
      noOfTransaction
      total {
        value
        currency
      }
      average {
        value
        currency
      }
      subcategoryDetails {
        id
        name
        predefined
      }
    }
  `,
}
