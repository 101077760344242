import { useCallback, useEffect, useState } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { useSetupFlowContext } from '../../../../../../contexts/SetupFlowContext'
import { DebitCardType } from '../../../../../../hooks/useSetupFlowCardsQuery/graphql/getCards.type'
import { useSensitiveCardInformation } from './hooks/useSensitiveCardInformation'
import {
  COPY_CVV_CONFIG,
  COPY_EXPIRY_CONFIG,
  COPY_PAN_CONFIG,
  CVV_CONFIG,
  CVV_CONFIG_DESKTOP,
  EXPIRY_CONFIG,
  EXPIRY_CONFIG_DESKTOP,
  PAN_CONFIG,
  PAN_CONFIG_DESKTOP,
} from './SensitiveCardInformation.constants'
import { SensitiveContentDesktopAndTablet } from './SensitiveContentDesktopAndTablet'
import { SensitiveContentMobile } from './SensitiveContentMobile'

interface SensitiveCardInformationProps {
  isFlipped: boolean
  debitCard?: DebitCardType
  onError: () => void
}

export const SensitiveCardInformation = ({
  isFlipped,
  debitCard,
  onError,
}: SensitiveCardInformationProps) => {
  const entityUuid = useSelectedEntityUuid()

  const isMobile = useIsMobileResolution()
  const [isSensitiveDataRevealed, setIsSensitiveDataRevealed] = useState(false)
  const [copySuccessMap, setCopySuccessMap] = useState({})
  const {
    clientAccessToken,
    generatePanToken,
    generateTokenError,
    isLoadingDebitCardPanToken,
  } = useSetupFlowContext()
  const [isTokenPreloaded, setIsTokenPreloaded] = useState(!!clientAccessToken)

  const handleError = useCallback(() => {
    if (!isFlipped) {
      return
    }

    onError()
  }, [isFlipped, onError])

  const { isLoading: isRevealing } = useSensitiveCardInformation({
    pan: isMobile ? PAN_CONFIG : PAN_CONFIG_DESKTOP,
    expiry: isMobile ? EXPIRY_CONFIG : EXPIRY_CONFIG_DESKTOP,
    cvv: isMobile ? CVV_CONFIG : CVV_CONFIG_DESKTOP,
    copyPan: isMobile ? COPY_PAN_CONFIG : undefined,
    copyExpiry: isMobile ? COPY_EXPIRY_CONFIG : undefined,
    copyCvv: isMobile ? COPY_CVV_CONFIG : undefined,
    clientAccessToken,
    skip: !debitCard || isSensitiveDataRevealed,
    onSuccess: () => {
      setIsSensitiveDataRevealed(true)
    },
    onCopySuccess: (id: string) => {
      setCopySuccessMap({
        [id]: true,
      })
    },
    onError: () => {
      // preloaded token expired, one more try
      if (isTokenPreloaded && debitCard) {
        generatePanToken({ debitCardId: debitCard.id, entityUuid })
        setIsTokenPreloaded(false)
        return
      }

      // handle marqeta injection fail
      handleError()
    },
  })

  useEffect(() => {
    if (
      !isFlipped ||
      isLoadingDebitCardPanToken ||
      isSensitiveDataRevealed ||
      isRevealing ||
      !debitCard
    ) {
      return
    }

    // every time when sensitive information visible,
    // re-generate token immediately if sensitive data is not revealing or revealed,
    generatePanToken({ debitCardId: debitCard.id, entityUuid })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlipped])

  // handle failed to generate access token
  useEffect(() => {
    if (!!generateTokenError && !isLoadingDebitCardPanToken) {
      handleError()
    }
  }, [generateTokenError, isLoadingDebitCardPanToken, handleError])

  if (isMobile) {
    return (
      <SensitiveContentMobile
        isFlipped={isFlipped}
        isRevealed={isSensitiveDataRevealed}
        debitCard={debitCard}
        copySuccessMap={copySuccessMap}
      />
    )
  }

  return (
    <SensitiveContentDesktopAndTablet
      isFlipped={isFlipped}
      isRevealed={isSensitiveDataRevealed}
      debitCard={debitCard}
    />
  )
}
