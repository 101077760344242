import { useEffect, useRef, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useModalState } from '@npco/zeller-design-system'
import { BusinessForm } from 'features/Contacts/Businesses/Business.form'
import { getSubContactPersonNames } from 'features/Contacts/Contact/ContactSections/SubContacts/AddSubContact/AddSubContact.utils'
import { ContactFormData } from 'features/Contacts/Contacts.types'
import { initialValues } from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { PersonForm } from 'features/Contacts/People/Person.form'

import { translate } from 'utils/translations'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'

export const translations = {
  createBusinessButton: translate('page.contacts.form.title.createBusiness'),
  createPersonButton: translate('page.contacts.form.title.createPerson'),
}

interface InvoiceCreateContactProps {
  contactTypeToCreate: ContactType | null
  initialEmail?: string
  initialName?: string
  onClose: () => void
  onCreate: (
    contact: CreateContact | CreateContactsContact,
    subContact?: ContactCoreFieldsFragment | null
  ) => void
  payerId?: string
  renderCreateFormBottomSection?: (props: { values: ContactFormData }) => void
}

export const InvoiceCreateContact = ({
  contactTypeToCreate,
  initialEmail = '',
  initialName = '',
  onClose: handleCloseForm,
  onCreate: handleCreateContact,
  payerId,
  renderCreateFormBottomSection,
}: InvoiceCreateContactProps) => {
  const initialNameValue = useRef(initialName)
  const initialEmailValue = useRef(initialEmail)
  const [formInitialValues, setFormInitialValues] =
    useState<ContactFormData>(initialValues)

  useEffect(() => {
    initialNameValue.current = initialName
    initialEmailValue.current = initialEmail
  })

  const { isModalOpen: isAddPersonModalOpen, openModal: openAddPersonModal } =
    useModalState()

  const {
    isModalOpen: isAddBusinessModalOpen,
    openModal: openAddBusinessModal,
  } = useModalState()

  useEffect(() => {
    if (contactTypeToCreate === ContactType.BUSINESS) {
      setFormInitialValues((formValues) => ({
        ...formValues,
        linkedContactUuid: payerId || formValues.linkedContactUuid,
        business: {
          ...formValues.business,
          businessName: initialNameValue.current,
          email: { email: initialEmailValue.current, label: null },
        },
      }))
      openAddBusinessModal()
    }

    if (contactTypeToCreate === ContactType.PERSON) {
      const { firstName, lastName } = getSubContactPersonNames(
        initialNameValue.current
      )

      setFormInitialValues((formValues) => ({
        ...formValues,
        linkedContactUuid: payerId || formValues.linkedContactUuid,
        person: {
          ...formValues.person,
          firstName,
          email: { email: initialEmailValue.current, label: null },
          lastName,
        },
      }))

      openAddPersonModal()
    }
  }, [contactTypeToCreate, openAddBusinessModal, openAddPersonModal, payerId])

  return (
    <>
      {isAddBusinessModalOpen && (
        <BusinessForm
          closeModal={handleCloseForm}
          initialValues={formInitialValues}
          isModalOpen
          onCreate={handleCreateContact}
          renderBottomSection={renderCreateFormBottomSection}
          title={translations.createBusinessButton}
        />
      )}
      {isAddPersonModalOpen && (
        <PersonForm
          closeModal={handleCloseForm}
          initialValues={formInitialValues}
          isModalOpen
          onCreate={handleCreateContact}
          renderBottomSection={renderCreateFormBottomSection}
          title={translations.createPersonButton}
        />
      )}
    </>
  )
}
