import { useMemo } from 'react'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { curry } from 'ramda'

interface WithStatus {
  status: DebitCardStatus
}

const filterCardsByStatus = curry(
  <T extends WithStatus>(statuses: string[], cards: T[]) =>
    cards.filter((card) => statuses.includes(card.status))
)

const filterActiveCards = filterCardsByStatus([
  DebitCardStatus.ACTIVE,
  DebitCardStatus.LOCKED,
  DebitCardStatus.SUSPENDED,
]) as <T extends WithStatus>(cards: T[]) => T[]

const filterPendingCards = filterCardsByStatus([DebitCardStatus.INACTIVE]) as <
  T extends WithStatus
>(
  cards: T[]
) => T[]

const filterClosedCards = filterCardsByStatus([
  DebitCardStatus.CLOSED,
  DebitCardStatus.EXPIRED,
  DebitCardStatus.LOST,
]) as <T extends WithStatus>(cards: T[]) => T[]

export const useFilterCardStatus = <T extends WithStatus>(cards: T[]) => {
  return useMemo(
    () => ({
      active: filterActiveCards(cards),
      pending: filterPendingCards(cards),
      closed: filterClosedCards(cards),
    }),
    [cards]
  )
}
