import { addMethod, string, StringSchema } from 'yup'

import { convertLocaleStringToNumber } from './localeString'

declare module 'yup' {
  interface StringSchema {
    isStringNumber(message: string): this
    maxStringNumber(number: number, message: string): this
    minStringNumber(number: number, message: string): this
  }
}

addMethod<StringSchema>(
  string,
  'isStringNumber',
  function schemaAddMethod(message: string) {
    return this.test('isStringNumber', message, function callback(value = '') {
      const { createError, path } = this

      const numberFromString = convertLocaleStringToNumber(value, NaN)

      if (!value || !Number.isFinite(numberFromString)) {
        return createError({ message, path })
      }

      return true
    })
  }
)

addMethod<StringSchema>(
  string,
  'minStringNumber',
  function schemaAddMethod(minNumber: number, message: string) {
    return this.test('minNumber', message, function callback(value = '0.00') {
      const { createError, path } = this

      const numberFromString = convertLocaleStringToNumber(value, NaN)

      if (numberFromString < minNumber) {
        return createError({ message, path })
      }

      return true
    })
  }
)

addMethod<StringSchema>(
  string,
  'maxStringNumber',
  function schemaAddMethod(maxNumber: number, message: string) {
    return this.test('maxNumber', message, function callback(value = '0.00') {
      const { createError, path } = this

      const numberFromString = convertLocaleStringToNumber(value, NaN)

      if (numberFromString > maxNumber) {
        return createError({ message, path })
      }

      return true
    })
  }
)
