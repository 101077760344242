import { COLOR } from '@npco/zeller-design-system'
import { BarProps, Rectangle } from 'recharts'

import { BAR_SIZE } from './AccountBalanceChart.constants'
import {
  getExpenseBarFillColorByValue,
  getIncomeBarFillColorByValue,
} from './AccountBalanceChart.utils'

type ChartBarShapeProps = {
  x: number
  y: number
  income: number
  expense: number
  index: number
  dataKey: string
}

const ChartBar = (props: ChartBarShapeProps) => {
  const { x, y, income, expense } = props
  const isEmpty = income === 0 && expense === 0
  if (!isEmpty) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Rectangle {...props} />
  }

  return <circle cx={x + BAR_SIZE / 2} cy={y - 1} r="1" fill={COLOR.GREY_250} />
}

const ChartBarActive = (props: ChartBarShapeProps) => {
  const { x, y, income, expense, dataKey } = props
  const fill =
    dataKey === 'income'
      ? getIncomeBarFillColorByValue(income)
      : getExpenseBarFillColorByValue(expense)
  const isEmpty = income === 0 && expense === 0
  if (!isEmpty) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Rectangle {...props} fill={fill} />
  }

  return <circle cx={x + BAR_SIZE / 2} cy={y - 1} r="1" fill={COLOR.GREY_250} />
}

export const ChartBarShape = ChartBar as BarProps['shape']
export const ChartBarActiveShape = ChartBarActive as BarProps['shape']
