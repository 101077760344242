import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { MultiEntityRoutes } from 'features/MultiEntity'
import { SessionTimeoutProvider } from 'features/SessionTimeout/context/SessionTimeoutProvider'

import { DEFAULT_AFTER_LOGIN_ROUTE, ROOT } from 'const/routes'
import { getIsEntityOnboarded } from 'utils/onboardingStatus'
import { AccountCreated, RegisterPhone, ValidateCode } from 'pages'
import { SetPinSuccess } from 'pages/GlobalModals/SetPinModal/SetPinSuccess/SetPinSuccess'
import { AuthenticationScreen } from 'pages/Login/AuthenticationScreen'
import { NotFound } from 'pages/NotFound'
import { LazyLoadComponent } from 'components/LazyLoadComponent/LazyLoadComponent'
import { PrivateRoutesGuard } from 'components/PrivateRoutesGuard/PrivateRoutesGuard'

import { useIdentityForcedLogoff } from './hooks/useIdentityForcedLogoff'
import { useRedirectBackToOnboarding } from './hooks/useRedirectBackToOnboarding'
import { useRedirectToDefaultOrg } from './hooks/useRedirectToDefaultOrg'
import { useRedirectToKyc } from './hooks/useRedirectToKyc'

const OnboardingApp = lazy(() => import('../../../../features/OnboardingApp'))

export const AppRoutes = () => {
  useRedirectBackToOnboarding()
  useRedirectToKyc()
  useIdentityForcedLogoff()
  useRedirectToDefaultOrg()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <SessionTimeoutProvider>
      <Routes>
        <Route path={ROOT.path} element={<AuthenticationScreen />} />
        <Route path={ROOT.REGISTER_PHONE.path} element={<RegisterPhone />} />
        <Route path={ROOT.VALIDATE_CODE.path} element={<ValidateCode />} />
        <Route path={ROOT.ACCOUNT_CREATED.path} element={<AccountCreated />} />
        <Route path={ROOT.PIN_SUCCESS.path} element={<SetPinSuccess />} />
        <Route element={<PrivateRoutesGuard />}>
          <Route
            path={`${ROOT.ONBOARDING.path}/*`}
            element={
              getIsEntityOnboarded() ? (
                <Navigate
                  to={DEFAULT_AFTER_LOGIN_ROUTE(shortEntityId)}
                  replace
                />
              ) : (
                <LazyLoadComponent>
                  <OnboardingApp />
                </LazyLoadComponent>
              )
            }
          />
          <Route path={`${ROOT.ORGS.path}/*`} element={<MultiEntityRoutes />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </SessionTimeoutProvider>
  )
}
