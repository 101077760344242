import { LabelType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { uniq } from 'ramda'

import { errorMessages, successMessages } from 'translations'

import { useDeleteLabelMutation } from '../../graphql/deleteLabel.generated'
import {
  rvBusinessLabels,
  rvBusinessLabelsDeleted,
  rvPersonLabels,
  rvPersonLabelsDeleted,
} from '../../LabelControl.utils'

export const useDeleteLabel = () => {
  const [deleteLabel, { loading: isDeletingLabel }] = useDeleteLabelMutation({
    onError: () => showErrorToast(errorMessages.deleteLabelError),
  })

  const entityUuid = useSelectedEntityUuid()

  return {
    isDeletingLabel,
    deleteLabel: async (id: string, labelType: LabelType): Promise<boolean> => {
      const response = await deleteLabel({ variables: { id, entityUuid } })

      const hasErrors = Boolean(response.errors)
      const result = Boolean(response.data?.deleteLabel)

      if (result) {
        if (labelType === LabelType.BUSINESS) {
          rvBusinessLabels(rvBusinessLabels().filter((item) => item.id !== id))
          rvBusinessLabelsDeleted(uniq([...rvBusinessLabelsDeleted(), id]))
        } else {
          rvPersonLabels(rvPersonLabels().filter((item) => item.id !== id))
          rvPersonLabelsDeleted(uniq([...rvPersonLabelsDeleted(), id]))
        }

        showSuccessToast(successMessages.deleteLabelSuccess)
      } else if (!result && !hasErrors) {
        showErrorToast(errorMessages.deleteLabelError)
      }

      return result
    },
  }
}
