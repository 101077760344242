import { gql } from '@apollo/client'
import { Dayjs } from 'dayjs'

import { getVelocityControlNewResetsAtApiFormat } from 'utils/banking/getVelocityControlNewResetsAt/getVelocityControlNewResetsAt'
import dayjs from 'utils/dayjs'

import { GetEndOfCurrentWindowVelocityControlTypeFragment } from './getEndOfCurrentWindow.generated'

export const getIsCurrentTimeAfterOrEqualToResetTime = (
  resetTime: any,
  now: Dayjs
) => {
  return !now.isBefore(resetTime)
}

interface GetEndOfCurrentWindowArguments {
  velocityControl: GetEndOfCurrentWindowVelocityControlTypeFragment
  now: Dayjs
  timezone?: string
}

export const getEndOfCurrentWindow = ({
  velocityControl,
  now,
  timezone,
}: GetEndOfCurrentWindowArguments) => {
  if (getIsCurrentTimeAfterOrEqualToResetTime(velocityControl.resetsAt, now)) {
    const nextResetAt = getVelocityControlNewResetsAtApiFormat({
      entityAddressTimezone: timezone,
      velocityWindow: velocityControl.velocityWindow,
    })

    return dayjs(nextResetAt)
  }

  return dayjs(velocityControl.resetsAt)
}

getEndOfCurrentWindow.fragments = {
  VelocityControlType: gql`
    fragment GetEndOfCurrentWindowVelocityControlTypeFragment on VelocityControlType {
      resetsAt
      velocityWindow
    }
  `,
}
