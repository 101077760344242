import { useCallback } from 'react'
import { ModalSize } from 'design-system/Components/Modal'

import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import { ReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { translate } from 'utils/translations'
import { MultiStageModal } from 'components/MultiStageModal'

import { useReportLostAndReplaceCardState } from '../hooks/useReportLostAndReplaceCardState'
import { ReportLostAndReplaceCardAddressForm } from './ReportLostAndReplaceCardAddressForm'

export const ReportLostAndReplaceCardAddressPrompt = () => {
  const { state } = useReportLostAndReplaceCardState()
  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()

  const handleOnCancel = useCallback(
    () => goToReportLostAndReplaceCardStage(undefined),
    [goToReportLostAndReplaceCardStage]
  )

  if (state === null) {
    return null
  }

  return (
    <MultiStageModal
      title={translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardAddressPrompt.heading'
      )}
      isOpen={state.stage === ReportLostAndReplaceCardStage.ReplaceCardAddress}
      hasCloseButton
      size={ModalSize.MEDIUM}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onCancel={handleOnCancel}
    >
      <ReportLostAndReplaceCardAddressForm dataTestId="report-lost-and-replace-card-address-form" />
    </MultiStageModal>
  )
}
