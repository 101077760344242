import { gql } from '@apollo/client'

export const GetDocumentUploadUrls = gql`
  query GetDocumentUploadUrls(
    $documents: [DocumentUploadFiles]!
    $subject: String
    $entityUuid: ID!
  ) {
    getDocumentUploadUrls(
      documents: $documents
      subject: $subject
      entityUuid: $entityUuid
    ) {
      fileName
      uploadUrl
    }
  }
`
