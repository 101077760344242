import { Box } from 'reflexbox'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const ImageContainer = styled(Box)<{
  imagePath: string
}>`
  height: 140px;
  width: 140px;
  background: url(${({ imagePath }) => imagePath}) 0 0 / cover;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    height: 180px;
    width: 180px;
  }
`
