import { DrawerWithIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

const WIDTH = '31.75rem'

export const StyledAccountDetailsDrawer = styled(DrawerWithIcon)`
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: ${WIDTH};
    min-width: ${WIDTH};
  }

  .drawer-header {
    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      width: ${WIDTH};
      min-width: ${WIDTH};
      padding-left: 4.75rem;
      padding-right: 4.75rem;
    }
  }
  .drawer-content {
    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      width: ${WIDTH};
      min-width: ${WIDTH};
      padding-left: 4.75rem;
      padding-right: 4.75rem;
    }
  }
  h2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
`
