import { useHistory } from 'react-router-dom'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  InputAdaptiveField,
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useGetZellerInvoiceSettings } from 'features/Invoicing/components/Settings/hooks/useGetZellerInvoiceSettings'
import { useUpdateZellerInvoiceSettings } from 'features/Invoicing/components/Settings/hooks/useUpdateZellerInvoiceSettings'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { ROOT } from 'const/routes'
import { errorMessages } from 'translations'

import { translations } from './BusinessNameModal.i18n'
import { Body, Subtitle } from './BusinessNameModal.styled'

interface BusinessNameFormFields {
  name: string
}

const businessNameSchema = object({
  name: string()
    .trim()
    .required(errorMessages.fieldRequired)
    .min(4, errorMessages.invalidCompanyName)
    .max(50, errorMessages.invalidCompanyName),
})

interface BusinessNameModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const BusinessNameModal = ({
  isOpen,
  closeModal,
}: BusinessNameModalProps) => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translations)

  const { isUpdatingZellerInvoiceSettings, updateZellerInvoiceSettings } =
    useUpdateZellerInvoiceSettings()
  const { updateReceiptNameCache, invoiceSettings } =
    useGetZellerInvoiceSettings()

  const handleOnSubmit = async (values: BusinessNameFormFields) => {
    try {
      const result = await updateZellerInvoiceSettings({ receipt: values })

      if (result && invoiceSettings?.receipt) {
        updateReceiptNameCache({
          ...invoiceSettings?.receipt,
          ...values,
        })

        closeModal()
        showSuccessToast(t('success'))
        history.push(ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.NEW.path)
      } else {
        showErrorToast(t('error'))
      }
    } catch (err) {
      showErrorToast(t('error'))
    }
  }

  return (
    <Formik<BusinessNameFormFields>
      initialValues={{ name: '' }}
      onSubmit={handleOnSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={businessNameSchema}
    >
      {({ handleSubmit }) => (
        <ModalForm
          isLoading={isUpdatingZellerInvoiceSettings}
          isOpen={isOpen}
          onCancel={closeModal}
          onClickPrimary={handleSubmit}
          primaryButtonLabel={t('continue')}
          secondaryButtonLabel={t('cancel')}
          title={t('title')}
        >
          <Flex flexDirection="column">
            <Body>{t('body')}</Body>
            <InputAdaptiveField name="name" label={t('businessName')} />
            <Subtitle>{t('subtitle')}</Subtitle>
          </Flex>
        </ModalForm>
      )}
    </Formik>
  )
}
