import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Heading,
  InputAdaptiveFieldWithPrefix,
} from '@npco/zeller-design-system'

import { useInputHandlers } from 'hooks/useInputHandlers'

import { useCompleteAmountDecimalOnBlur } from '../../../hooks/useCompleteAmountDecimalOnBlur'
import { SPEND_CONTROL_MAXIMUM_TRANSACTION_LIMIT_FIELD } from '../SpendControlFields.constants'
import * as styled from '../SpendControlFields.styled'
import { maximumTransactionLimitTranslations } from './MaximumTransactionLimit.i18n'

const renderPrefix = () => <styled.TextInputPrefix>$</styled.TextInputPrefix>

interface MaximumTransactionLimitProps {
  isHeaderVisible?: boolean
  isFullWidth?: boolean
  isDescriptionOnTop?: boolean
}

export const MaximumTransactionLimit = ({
  isHeaderVisible,
  isFullWidth,
  isDescriptionOnTop = true,
}: MaximumTransactionLimitProps) => {
  const t = useTranslations(maximumTransactionLimitTranslations)
  const { handleCurrencyMin1DigitInputKeydown } = useInputHandlers()
  const { onBlurHandler } = useCompleteAmountDecimalOnBlur({
    fieldName: SPEND_CONTROL_MAXIMUM_TRANSACTION_LIMIT_FIELD,
  })

  return (
    <Box>
      {isHeaderVisible && <Heading.H4>{t('header')}</Heading.H4>}
      {isDescriptionOnTop && (
        <Box mb="24px">
          <styled.Description>{t('description')}</styled.Description>
        </Box>
      )}

      <styled.ItemWrapper $isFullWidth={isFullWidth}>
        <InputAdaptiveFieldWithPrefix
          name={SPEND_CONTROL_MAXIMUM_TRANSACTION_LIMIT_FIELD}
          renderPrefix={renderPrefix}
          label={t('label')}
          aria-label={t('ariaLabel')}
          inputMode="numeric"
          onKeyDown={handleCurrencyMin1DigitInputKeydown}
          autoComplete="off"
          onBlur={onBlurHandler}
        />
      </styled.ItemWrapper>

      {!isDescriptionOnTop && (
        <Box mt="16px">
          <styled.Description>{t('description')}</styled.Description>
        </Box>
      )}
    </Box>
  )
}
