import { BREAKPOINT, RadioListCardWithAvatar } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const AccountRow = styled(RadioListCardWithAvatar)`
  div {
    max-width: 240px;
    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      max-width: 600px;
    }
  }
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
