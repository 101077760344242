import { useSetupFlowContext } from '../../../../../../contexts/SetupFlowContext'
import {
  DebitCardDetailsClickCover,
  DebitCardPositionWrapper,
} from '../DebitCardDetails.styled'
import { SensitiveCardInformation } from '../SensitiveCardInformation/SensitiveCardInformation'
import { DebitCardDetailsContentProps } from './DebitCardDetailsContent.types'
import { DebitCardDetailsCopyInfoTablet } from './DebitCardDetailsCopyInfoTablet'

export const DebitCardDetailsContentTablet = ({
  toggleCard,
  isCardFlipped,
  onError,
}: DebitCardDetailsContentProps) => {
  const { setupFlowCard } = useSetupFlowContext()

  return (
    <DebitCardPositionWrapper data-testid="debit-card-details-tablet">
      <SensitiveCardInformation
        isFlipped={isCardFlipped}
        debitCard={setupFlowCard}
        onError={onError}
      />
      {setupFlowCard && (
        <>
          <DebitCardDetailsClickCover
            aria-label={isCardFlipped ? 'hide details' : 'show details'}
            onClick={toggleCard}
          />
          <DebitCardDetailsCopyInfoTablet isDetailsVisible={isCardFlipped} />
        </>
      )}
    </DebitCardPositionWrapper>
  )
}
