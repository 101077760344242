import { BodyDefault, BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Body = styled(BodyDefault)`
  margin: 0 0 24px;
`

export const Subtitle = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 8px 0 0;
`
