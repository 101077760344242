import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { BoxProps, CellSize } from '@npco/zeller-design-system'

import { useCardStatus } from 'hooks/useCardStatus'
import { translations } from 'translations/shared.i18n'

import { CardBalance } from '../CardBalanceCell/CardBalanceCell.styled'
import { CardBalanceCellBase } from '../CardBalanceCellBase/CardBalanceCellBase'
import { Container, Padlock } from './CardStatus.styled'

export interface CardStatusProps {
  cellId: string
  isLoading?: boolean
  cellSize?: CellSize
  skeletonPropWidth: BoxProps['width']
  status: DebitCardStatus
}
export const CardStatus = ({
  cellId,
  isLoading,
  cellSize,
  skeletonPropWidth,
  status,
}: CardStatusProps) => {
  const tShared = useTranslations(translations)
  const { isClosed, isLocked } = useCardStatus({
    status,
  })

  const statusText = useMemo(() => {
    if (isClosed) {
      return tShared('closed')
    }

    if (isLocked) {
      return tShared('locked')
    }

    return tShared('suspended')
  }, [isClosed, isLocked, tShared])

  return (
    <CardBalanceCellBase
      content={
        <Container>
          <CardBalance>{statusText}</CardBalance>
          {isLocked && <Padlock />}
        </Container>
      }
      cellId={cellId}
      skeletonPropWidth={skeletonPropWidth}
      isLoading={isLoading}
      cellSize={cellSize}
    />
  )
}

CardStatus.fragments = {
  DebitCardV2: gql`
    fragment CardStatusDebitCardV2Fragment on DebitCardV2 {
      ...UseCardStatusDebitCardV2Fragment
    }
    ${useCardStatus.fragments.DebitCardV2}
  `,
}
