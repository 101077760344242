import { useCallback } from 'react'
import { ModalBasic } from '@npco/zeller-design-system'

import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import {
  ReportLostAndReplaceCardStage,
  ReportLostCardState,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { translate } from 'utils/translations'

import { useReportLostAndReplaceCardState } from '../hooks/useReportLostAndReplaceCardState'
import { ReportLostAndReplaceCardForm } from './ReportLostAndReplaceCardForm/ReportLostAndReplaceCardForm'

export const ReportLostAndReplaceCardPrompt = () => {
  const { state } = useReportLostAndReplaceCardState<ReportLostCardState>()
  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()

  const handleOnCancel = useCallback(
    () => goToReportLostAndReplaceCardStage(undefined),
    [goToReportLostAndReplaceCardStage]
  )

  if (state === null) {
    return null
  }

  return (
    <ModalBasic
      isOpen={state.stage === ReportLostAndReplaceCardStage.ReportLostCard}
      onCancel={handleOnCancel}
      title={translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardPrompt.title'
      )}
    >
      <ReportLostAndReplaceCardForm />
    </ModalBasic>
  )
}
