import { FiltersListMobile } from 'components/FiltersList/FiltersListMobile'

import { useAccountFilters } from '../hooks/useAccountFilters/useAccountFilters'

type Props = React.ComponentProps<typeof FiltersListMobile>

export const AccountMobileFiltersList = (props: Props) => {
  const { resetAllFilters, areFiltersInDefaultState } = useAccountFilters()

  return (
    <FiltersListMobile
      {...props}
      onReset={resetAllFilters}
      showIndicator={!areFiltersInDefaultState}
    />
  )
}
