import { Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DetailsField } from 'components/DetailsField/DetailsField'

import { StyledDescription, StyledDetailsTitle } from '../UserDetails.styled'

type UserPermissionsProps = {
  title: string
  description: string
  isLoading: boolean
  isEnabled: boolean
}

export const PermissionSection = ({
  description,
  isLoading,
  isEnabled,
  title,
}: UserPermissionsProps) => {
  return (
    <Flex flexDirection="column" gap="16px" paddingTop="16px">
      <StyledDetailsTitle>{title}</StyledDetailsTitle>
      <StyledDescription>{description}</StyledDescription>
      <DetailsField
        label={translate('shared.enabled')}
        value={isEnabled ? translate('shared.yes') : translate('shared.no')}
        isLoading={isLoading}
      />
    </Flex>
  )
}
