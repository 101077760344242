import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { EditDebitCardTransactionImagesLocationStateSchema } from '../../EditDebitCardTransactionImages.types'

export const useEditDebitCardTransactionImagesState = () => {
  const locationState = useLocationState(
    EditDebitCardTransactionImagesLocationStateSchema
  )

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.EditDebitCardTransactionImagesModal
  }, [locationState])

  return { state }
}
