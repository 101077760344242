import { currencyFormatter } from '@npco/component-mp-common'
import {
  COLOR,
  Flex,
  TooltipBasic,
  Typography,
} from '@npco/zeller-design-system'
import dayjs from 'dayjs'
import { TooltipProps } from 'recharts'

import {
  getExpenseBarFillColorByValue,
  getIncomeBarFillColorByValue,
} from '../AccountBalanceChart.utils'
import * as styled from './ChartTooltip.styled'

type ChartTooltipProps = TooltipProps<number, string>

export const ChartTooltip = ({ active, payload }: ChartTooltipProps) => {
  const activeIncome = payload?.find((item) => item.dataKey === 'income')
  const activeExpense = payload?.find((item) => item.dataKey === 'expense')

  if (!active || !activeIncome || !activeExpense) {
    return null
  }

  const dateStartMonth = dayjs(activeIncome.payload.dateStart).format(
    'MMMM YYYY'
  )
  const activeIncomeValue = activeIncome.value ?? 0
  const activeExpenseValue = activeExpense.value ?? 0
  const expenseZeroPrefix = activeExpenseValue === 0 ? '-' : ''

  return (
    <TooltipBasic
      placement="top"
      renderTrigger={() => (
        <Flex
          flexDirection="column"
          borderRadius="4px"
          padding="8px"
          backgroundColor={COLOR.BLACK_900}
          alignItems="center"
          gap="2px"
        >
          <Typography variant="body-sm" color={COLOR.GREY_250}>
            {dateStartMonth}
          </Typography>
          <styled.ChartTooltipItem>
            <styled.ChartTooltipItemIcon
              backgroundColor={getIncomeBarFillColorByValue(activeIncomeValue)}
            />
            <styled.ChartTooltipItemText>
              {currencyFormatter(activeIncomeValue)}
            </styled.ChartTooltipItemText>
          </styled.ChartTooltipItem>
          <styled.ChartTooltipItem>
            <styled.ChartTooltipItemIcon
              backgroundColor={getExpenseBarFillColorByValue(
                activeExpenseValue
              )}
            />
            <styled.ChartTooltipItemText>
              {`${expenseZeroPrefix}${currencyFormatter(-activeExpenseValue)}`}
            </styled.ChartTooltipItemText>
          </styled.ChartTooltipItem>
        </Flex>
      )}
    />
  )
}
