import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const ButtonContainer = styled.div`
  margin-top: auto;
  @media (min-width: ${BREAKPOINT.XS}px) {
    display: flex;
  }
  button {
    &:first-of-type {
      margin-bottom: 0.5rem;
      @media (min-width: ${BREAKPOINT.XS}px) {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
`
