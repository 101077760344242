import { Customized, Pie, PieChart, Tooltip as RechartsTooltip } from 'recharts'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { TransactionTotal } from '../../DashboardPerformance.types'
import { LoadingCentreText } from './CentreText/LoadingCentreText'
import { ThisMonthCentreText } from './CentreText/ThisMonthCentreText'
import { useThisMonthPerformanceChartData } from './hooks/useThisMonthPerformanceChartData'
import { PerformanceTooltip } from './PerformanceTooltip/PerformanceTooltip'
import { ThisMonthTooltipContent } from './PerformanceTooltip/ThisMonthTooltipContent'

interface Props {
  salesTotals: TransactionTotal[]
  disabled: boolean
  isLoading: boolean
}

export const ThisMonthPerformanceChart = ({
  salesTotals,
  disabled,
  isLoading,
}: Props) => {
  const isMobile = useIsMobileResolution()

  const {
    totalSalesLastMonth,
    salesThisTimeLastMonth,
    salesThisMonth,
    totalLastMonthData,
    thisTimeLastMonthData,
    currentMonthData,
  } = useThisMonthPerformanceChartData({
    salesTotals,
    disabled,
    isLoading,
  })

  return (
    <PieChart
      width={256}
      height={256}
      data-testid="performance-summary-pi-chart"
    >
      <Customized
        key={`center-text-${salesThisMonth}-${salesThisTimeLastMonth}-${totalSalesLastMonth}-${isLoading}`}
        component={
          isLoading ? (
            <LoadingCentreText />
          ) : (
            <ThisMonthCentreText
              salesThisMonth={salesThisMonth}
              salesThisTimeLastMonth={salesThisTimeLastMonth}
              totalSalesLastMonth={totalSalesLastMonth}
            />
          )
        }
      />
      <Pie
        data={totalLastMonthData}
        dataKey="percentage"
        innerRadius="95%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        cornerRadius={5}
        paddingAngle={1}
        stroke=""
      />
      <Pie
        data={thisTimeLastMonthData}
        dataKey="percentage"
        innerRadius="95%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        cornerRadius={5}
        paddingAngle={1}
        stroke=""
      />
      <Pie
        data={currentMonthData}
        dataKey="percentage"
        innerRadius="86%"
        outerRadius="90%"
        startAngle={90}
        endAngle={-270}
        cornerRadius={5}
        blendStroke
      />
      <RechartsTooltip
        isAnimationActive={false}
        position={{ x: isMobile ? 40 : -40, y: isMobile ? -30 : 30 }}
        content={
          <PerformanceTooltip
            content={
              <ThisMonthTooltipContent
                lastMonthTotal={totalSalesLastMonth}
                thisTimeLastMonthTotal={salesThisTimeLastMonth}
              />
            }
          />
        }
      />
    </PieChart>
  )
}
