import { EntityCategories } from '@npco/mp-gql-types'

import { translate } from 'utils/translations'
import { GetSubcategories_getEntitySubcategories as SubcategoryDetails } from 'types/gql-types/GetSubcategories'
import { CategoryCheckbox } from 'components/EditCategoriesModal/Fields/CategoryCheckbox'
import { EditZellerCategories } from 'components/EditZellerCategories/EditZellerCategories'
import { NewSubcategory } from 'components/EditZellerCategories/EditZellerCategories.types'

import { TransactionEntityCategoryValues } from './EditTransactionZellerCategories.types'

interface Props {
  isOpen: boolean
  category: EntityCategories | null
  subcategoryDetails: SubcategoryDetails | null
  onSave: (values: TransactionEntityCategoryValues) => void
  onCancel: () => void
  onEditSubcategory?: (values: TransactionEntityCategoryValues) => void
  onDeleteSubcategory?: (
    values: TransactionEntityCategoryValues & NewSubcategory
  ) => void
  shouldShowApplyAll?: boolean
  isSavingCategories?: boolean
}

export const EditTransactionZellerCategories = ({
  isOpen,
  category,
  subcategoryDetails,
  onSave,
  onCancel,
  onEditSubcategory,
  onDeleteSubcategory,
  shouldShowApplyAll = false,
  isSavingCategories = false,
}: Props) => {
  return (
    <EditZellerCategories
      isOpen={isOpen}
      initialValues={{
        category,
        subcategory: subcategoryDetails?.name,
        subcategoryId: subcategoryDetails?.id,
        applyAll: false,
      }}
      onSave={onSave}
      onCancel={onCancel}
      onEditSubcategory={onEditSubcategory}
      onDeleteSubcategory={onDeleteSubcategory}
      isSavingCategories={isSavingCategories}
    >
      {shouldShowApplyAll && (
        <CategoryCheckbox
          name="applyAll"
          label={translate(
            'page.transactionDetails.zellerCategories.applyAllLabel'
          )}
        />
      )}
    </EditZellerCategories>
  )
}
