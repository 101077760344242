import { MultiSelectItemBasic } from 'design-system/Components/MultiSelect'

import { translate } from 'utils/translations'

import {
  StyledFilterTextContainer,
  StyledSelectedValueWrapper,
} from './FilterMobileSelectTrigger.styled'

const getSelectedItems = (
  isOnlyOneValueSelected: boolean,
  selectedItemLabel: string | undefined,
  valuesLength: number
) => {
  if (isOnlyOneValueSelected) {
    return (
      <StyledSelectedValueWrapper>
        ({selectedItemLabel})
      </StyledSelectedValueWrapper>
    )
  }

  return (
    <StyledSelectedValueWrapper>({valuesLength})</StyledSelectedValueWrapper>
  )
}

export const getMobileContent = <T extends string>(
  areAllItemsSelected: boolean,
  filterName: string,
  selectedItems: MultiSelectItemBasic[],
  values: T[]
) => {
  const areNoValuesSelected = values.length === 0
  const isOnlyOneValueSelected = values.length === 1
  const selectedItem = selectedItems.find(({ value }) => value === values[0])

  if (areAllItemsSelected || areNoValuesSelected) {
    return filterName
  }

  return (
    <StyledFilterTextContainer>
      {translate(
        'page.accounts.transactionsList.filters.filterMobileSelectedItem',
        {
          selectedItems: getSelectedItems(
            isOnlyOneValueSelected,
            selectedItem?.label,
            values.length
          ),
          filterName,
        }
      )}
    </StyledFilterTextContainer>
  )
}
