import { useBPayTransferState } from '../../bpay-transfer-routing'
import { CompleteStage } from './CompleteStage/CompleteStage'
import { SummaryStage } from './SummaryStage/SummaryStage'

export const BPayTransferModal = () => {
  const { state } = useBPayTransferState()

  if (state === null) {
    return null
  }

  return (
    <>
      {state.stage === 'summary' && (
        <SummaryStage transferDetails={state.transferDetails} />
      )}
      {state.stage === 'complete' && (
        <CompleteStage
          transferDetails={state.transferDetails}
          transactionTimestamp={state.transactionTimestamp}
          transactionID={state.transactionID}
        />
      )}
    </>
  )
}
