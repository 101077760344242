import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { BusinessesListCustomerEntityMappingFragmentDoc } from '../../BusinessesList/BusinessesList.generated'

const defaultOptions = {} as const
export type ManageBusinessesQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type ManageBusinessesQueryResponse = {
  __typename?: 'Query'
  getCustomerEntityMapping: {
    __typename?: 'CustomerEntityMapping'
    entityRelations: Array<{
      __typename?: 'CustomerEntityRelation'
      entityUuid: string
      role: Types.CustomerRole
      isVisible: boolean
      entity: { __typename?: 'Entity'; id: string; name: string | null }
    }> | null
  }
}

export const ManageBusinessesQuery = gql`
  query ManageBusinessesQuery {
    getCustomerEntityMapping {
      ...BusinessesListCustomerEntityMappingFragment
      entityRelations {
        entityUuid
      }
    }
  }
  ${BusinessesListCustomerEntityMappingFragmentDoc}
` as unknown as TypedDocumentNode<
  ManageBusinessesQueryResponse,
  ManageBusinessesQueryVariables
>

/**
 * __useManageBusinessesQuery__
 *
 * To run a query within a React component, call `useManageBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageBusinessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageBusinessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManageBusinessesQueryResponse,
    ManageBusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ManageBusinessesQueryResponse,
    ManageBusinessesQueryVariables
  >(ManageBusinessesQuery, options)
}
export function useManageBusinessesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManageBusinessesQueryResponse,
    ManageBusinessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ManageBusinessesQueryResponse,
    ManageBusinessesQueryVariables
  >(ManageBusinessesQuery, options)
}
export type ManageBusinessesQueryHookResult = ReturnType<
  typeof useManageBusinessesQuery
>
export type ManageBusinessesQueryLazyQueryHookResult = ReturnType<
  typeof useManageBusinessesQueryLazyQuery
>
export type ManageBusinessesQueryQueryResult = Apollo.QueryResult<
  ManageBusinessesQueryResponse,
  ManageBusinessesQueryVariables
>
