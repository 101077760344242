import { lazy } from 'react'
import {
  matchPath,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom-v5-compat'
import { PORTAL_SHOP } from '@npco/mp-feature-onboarding-shop'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { AccountsRoutes } from 'features/Accounts/feature-accounts'
import { ExpensesRoutes } from 'features/Expenses/ExpensesRoutes/ExpensesRoutes'
import { ReportsRoutes } from 'features/Reports'
import { StartupModals } from 'features/StartupModals'

import { usePortalRoutesSetup } from 'hooks/usePortalRoutesSetup'
import { ROOT } from 'const/routes'
import { DashboardRoutes } from 'pages/Dashboard/DashboardRoutes'
import { Demos } from 'pages/Demos/Demos'
import { GlobalModals } from 'pages/GlobalModals/GlobalModals'
import { Help } from 'pages/Help/Help'
import { LoginError } from 'pages/Login/LoginError/LoginError'
import { NotFound } from 'pages/NotFound'
import { Referral } from 'pages/Referral'
import { BiometricsRoutes } from 'pages/Settings/Profile/Security/BiometricsRoutes'
import {
  DashboardLayout,
  DashboardLayoutContent,
} from 'layouts/DashboardLayout'
import { useGetCustomerMarketing } from 'components/App/components/PortalRoutes/CustomerMarketing/useGetCustomerMarketing'
import { LazyLoadComponent } from 'components/LazyLoadComponent/LazyLoadComponent'
import { SpinnerWrapped } from 'components/Spinner'

import { useRedirectAfterLogin } from './hooks/useRedirectAfterLogin'
import { PaymentsRoutes } from './PaymentsRoutes/PaymentsRoutes'
import { SettingsRoutes } from './SettingsRoutes/SettingsRoutes'
import { withIsOnboardedGuard } from './utils/IsOnboardedGuard'

const CardsRoutes = lazy(
  () => import('features/Cards/feature-cards/routes/CardsRoutes')
)

const ContactsRoutes = lazy(
  () => import('features/Contacts/routes/ContactsRoutes')
)

const InvoicingRoutes = lazy(
  () => import('features/Invoicing/routes/InvoicingRoutes')
)

const NotificationsRoutes = lazy(
  () => import('features/Notifications/routes/NotificationsRoutes')
)

const PortalKYCRoutes = lazy(
  () => import('pages/PortalKYC/routes/PortalKYCRoutes')
)

const ItemsRoutes = lazy(() => import('features/Items/routes/ItemsRoutes'))
const PosLiteRoutes = lazy(() => import('features/PosLite/PosLiteRoutes'))
const OnboardingShopApp = lazy(
  () => import('features/OnboardingApp/OnboardingShop/OnboardingShopRoutes')
)

export const PortalRoutes = withIsOnboardedGuard(() => {
  const { fetchSetupDataError, isFetchSetupDataLoading } =
    usePortalRoutesSetup()
  const { pathname } = useLocation()
  const {
    isLoading: isLoadingCMS,
    bannerSlug,
    modalSlug,
  } = useGetCustomerMarketing()
  const shortEntityId = useSelectedShortEntityUuid()
  const entityUuid = useSelectedEntityUuid()

  const isInKycPath = Boolean(
    matchPath(`${ROOT.ORGS.ORG().KYC.path}/*`, pathname)
  )
  const isFetchSetupDataFailed = Boolean(fetchSetupDataError)
  const shouldLoadStartupModals = !isInKycPath

  const flags = useFeatureFlags()

  useRedirectAfterLogin({
    skip: isFetchSetupDataLoading || isFetchSetupDataFailed,
  })

  if (isFetchSetupDataLoading && !flags.MultiEntity) {
    return <SpinnerWrapped variant="centre" />
  }

  if (fetchSetupDataError) {
    return <LoginError error={fetchSetupDataError} />
  }

  return (
    <>
      <GlobalModals />
      {shouldLoadStartupModals && (
        <StartupModals isLoadingCMS={isLoadingCMS} modalSlug={modalSlug} />
      )}
      <Routes>
        <Route path="/*" element={<Outlet key={entityUuid} />}>
          <Route
            element={isFetchSetupDataLoading ? <SpinnerWrapped /> : <Outlet />}
          >
            <Route
              path={`${PORTAL_SHOP().DEALS.relative}/*`}
              element={
                <LazyLoadComponent>
                  <OnboardingShopApp entityUuid={entityUuid} />
                </LazyLoadComponent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().KYC.relative}/*`}
              element={
                <LazyLoadComponent>
                  <PortalKYCRoutes />
                </LazyLoadComponent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().BIOMETRICS.relative}/*`}
              element={<BiometricsRoutes />}
            />
          </Route>
          <Route
            index
            element={
              <Navigate
                to={ROOT.ORGS.ORG(shortEntityId).OVERVIEW.PAYMENTS.path}
                replace
              />
            }
          />
          <Route
            element={
              <DashboardLayout
                bannerSlug={bannerSlug}
                isLoadingCMS={isLoadingCMS}
                isLoading={isFetchSetupDataLoading}
              >
                {isFetchSetupDataLoading ? (
                  <SpinnerWrapped variant="centre" />
                ) : (
                  <Outlet />
                )}
              </DashboardLayout>
            }
          >
            <Route
              path={`${ROOT.ORGS.ORG().OVERVIEW.relative}/*`}
              element={<DashboardRoutes />}
            />
            <Route
              path={`${ROOT.ORGS.ORG().NOTIFICATIONS.relative}/*`}
              element={
                <LazyLoadComponent>
                  <NotificationsRoutes />
                </LazyLoadComponent>
              }
            />
            <Route
              path={ROOT.ORGS.ORG().REFERRAL.relative}
              element={
                <DashboardLayoutContent>
                  <Referral />
                </DashboardLayoutContent>
              }
            />
            <Route
              path={ROOT.ORGS.ORG().HELP.relative}
              element={
                <DashboardLayoutContent>
                  <Help />
                </DashboardLayoutContent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().PAYMENTS.relative}/*`}
              element={<PaymentsRoutes />}
            />
            <Route
              path={`${ROOT.ORGS.ORG().SETTINGS.relative}/*`}
              element={<SettingsRoutes />}
            />
            <Route
              path={`${ROOT.ORGS.ORG().ACCOUNTS.relative}/*`}
              element={<AccountsRoutes />}
            />
            <Route
              path={`${ROOT.ORGS.ORG().CARDS.relative}/*`}
              element={
                <LazyLoadComponent>
                  <CardsRoutes />
                </LazyLoadComponent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().INVOICING.relative}/*`}
              element={
                <LazyLoadComponent>
                  <InvoicingRoutes />
                </LazyLoadComponent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().CONTACTS.relative}/*`}
              element={
                <LazyLoadComponent>
                  <ContactsRoutes />
                </LazyLoadComponent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().DEMOS.relative}/*`}
              element={<Demos />}
            />
            {flags.CashFlowReporting && (
              <Route
                path={`${ROOT.ORGS.ORG().REPORTS.relative}/*`}
                element={<ReportsRoutes />}
              />
            )}
            {flags.Expenses && (
              <Route
                path={`${ROOT.ORGS.ORG().EXPENSES.relative}/*`}
                element={<ExpensesRoutes />}
              />
            )}
            <Route
              path="*"
              element={
                <DashboardLayoutContent>
                  <NotFound />
                </DashboardLayoutContent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().ITEMS.relative}/*`}
              element={
                <LazyLoadComponent>
                  <ItemsRoutes />
                </LazyLoadComponent>
              }
            />
            <Route
              path={`${ROOT.ORGS.ORG().POS_LITE.relative}/*`}
              element={
                <LazyLoadComponent>
                  <PosLiteRoutes />
                </LazyLoadComponent>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  )
})
