import { Dispatch, SetStateAction, useCallback } from 'react'
import {
  Breadcrumb,
  ButtonGhostIconLeft,
  Flex,
} from '@npco/zeller-design-system'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { translate } from 'utils/translations'
import { GetEntityForAccountsLayoutQueryResponse } from 'layouts/AccountsLayout/hooks/graphql/getEntityForAccountsLayout.generated'
import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { TransferFlowButton } from 'components/TransferFlowButton'

export interface AccountsLayoutPrimaryHeaderRowDesktopProps {
  entity?: GetEntityForAccountsLayoutQueryResponse['getEntity']
  isFiltersDisabled: boolean
  isFiltersInDefaultState: boolean
  isFiltersVisible?: boolean
  isLoadingEntity: boolean
  onAddAccount: () => void
  setIsFiltersVisible: Dispatch<SetStateAction<boolean>>
  shouldHaveFilters: boolean
}

export const AccountsLayoutPrimaryHeaderRowDesktop = ({
  entity,
  isFiltersDisabled,
  isFiltersInDefaultState,
  isFiltersVisible = false,
  isLoadingEntity,
  onAddAccount: handleAddAccount,
  setIsFiltersVisible,
  shouldHaveFilters,
}: AccountsLayoutPrimaryHeaderRowDesktopProps) => {
  const handleSetIsFiltersOpen = useCallback(
    () => setIsFiltersVisible((prev) => !prev),
    [setIsFiltersVisible]
  )

  return (
    <Flex
      flexDirection="column"
      data-testid="accounts-layout-primary-header-row-desktop"
      width="100%"
    >
      <Flex
        alignItems="center"
        height="48px"
        justifyContent="space-between"
        width="100%"
      >
        <Flex maxWidth={300}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Breadcrumb.Text>
                {translate('page.accounts.accounts')}
              </Breadcrumb.Text>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Flex>
        <Flex gridGap="0.5rem">
          {shouldHaveFilters && (
            <DashboardFiltersButton
              onClick={handleSetIsFiltersOpen}
              isActive={isFiltersVisible}
              disabled={isFiltersDisabled}
              showIndicator={!isFiltersInDefaultState}
            />
          )}
          {!isLoadingEntity && (
            <>
              <ButtonGhostIconLeft icon={Plus} onClick={handleAddAccount}>
                {translate('page.accounts.account')}
              </ButtonGhostIconLeft>
              <TransferFlowButton entity={entity} isDisabled={false} />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
