import { Accept } from 'react-dropzone'

export const ACCEPT_IMAGE_TYPES: Accept = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/heic': ['.heic'],
  'image/heif': ['.heif'],
  'image/webp': ['.webp'],
}

export const MAX_FILE_SIZE_KB = 5 * 1024 * 1024
