import { useMemo, useState } from 'react'
import { pick } from 'ramda'

import { ChartDataPoint, ChartPoint } from '../Charts.types'

type ChartRef = {
  props?: {
    points?: { x: number; y: number; payload: ChartDataPoint }[]
  }
}

export const useChartPoints = () => {
  const [points, setPoints] = useState<ChartPoint[]>([])

  const onChartRef = useMemo(
    () => (ref: ChartRef) => {
      const newPoints =
        ref?.props?.points?.map((point) =>
          pick(['x', 'y', 'payload'], point)
        ) ?? []
      setPoints(newPoints)
    },
    [setPoints]
  )

  return {
    onChartRef,
    points,
  }
}
