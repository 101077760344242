import { gql } from '@apollo/client'

export const CreateItem = gql`
  mutation CreateInvoiceItem($entityUuid: ID!, $item: CreateItemInput!) {
    createItem(entityUuid: $entityUuid, input: $item) {
      id
      name
      description
      price
      taxes {
        enabled
        name
        percent
      }
    }
  }
`
