import { ReactComponent as SourceIcons } from 'assets/svg/source.svg'
import { TRANSACTION_SOURCES } from 'const/common'
import { getSelectedItemsInfo } from 'utils/picker'
import { PickerItemProps } from 'types/picker'
import { MultiDownshift } from 'components/MultiDownshift'
import { MultiDownshiftComponentName } from 'components/MultiDownshift/MultiDownshift.hooks'
import { AllItemsRow, BottomRow, StatusRow } from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { component } from 'translations'

import { Dropdown, OpenOptions, SourceBox } from './SourcePicker.styled'

export const SourcePicker = () => {
  const transactionSources = TRANSACTION_SOURCES

  return (
    <MultiDownshift
      itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
      componentName={MultiDownshiftComponentName.Source}
    >
      {({
        getToggleButtonProps,
        getRootProps,
        getItemProps,
        selectedItems,
        toggleSources,
        clearAllSelected,
        isOpen,
      }) => (
        <Dropdown
          {...getRootProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
        >
          <PickerTrigger
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            label={getSelectedItemsInfo(selectedItems, 'source')}
            placeholderIcon={<SourceIcons />}
            triggerContent={
              selectedItems?.length
                ? getSelectedItemsInfo(selectedItems, 'source')
                : undefined
            }
          />
          {isOpen && (
            <OpenOptions>
              <SourceBox>
                <AllItemsRow
                  label={component.sourcePicker.allSources}
                  checked={selectedItems.length === transactionSources.length}
                  handleOnChange={() => toggleSources(transactionSources)}
                />
                {transactionSources.map((type) => (
                  <StatusRow
                    key={type.name}
                    status={type}
                    getItemProps={getItemProps}
                    selectedItems={selectedItems}
                  />
                ))}
              </SourceBox>
              <BottomRow handleClearAll={clearAllSelected} />
            </OpenOptions>
          )}
        </Dropdown>
      )}
    </MultiDownshift>
  )
}
