import {
  COLOR,
  Flex,
  InputSelectComboboxItemRenderProps,
  InputSelectComboboxStandardItemWrapper,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as IconEdit } from 'assets/svg/pen.svg'
import { ReactComponent as IconClose } from 'assets/svg/toasts/close.svg'

import {
  StyledButtonIcon,
  StyledItemContainer,
} from '../../LabelSelectCombobox.styled'
import { LabelSelectComboboxItem as LabelSelectComboboxItemType } from '../../LabelSelectCombobox.types'

interface LabelSelectComboboxItemProps<ItemType> {
  itemHoverKey?: string | null
  renderProps: InputSelectComboboxItemRenderProps<ItemType>
  setIsSelectingItem: (bool: boolean) => void
  setItemHoverKey: (id: string | null) => void
  setSelectedDeleteItem: (item: ItemType) => void
  setSelectedEditItem: (item: ItemType) => void
}

export const LabelSelectComboboxItem = <
  ItemType extends LabelSelectComboboxItemType
>({
  itemHoverKey,
  renderProps,
  setIsSelectingItem,
  setItemHoverKey,
  setSelectedDeleteItem,
  setSelectedEditItem,
}: LabelSelectComboboxItemProps<ItemType>) => {
  const isHoveringKey = renderProps.id === itemHoverKey

  return (
    <InputSelectComboboxStandardItemWrapper
      {...renderProps}
      aria-selected={isHoveringKey}
      onMouseEnter={() => setItemHoverKey(renderProps.id)}
      onMouseLeave={() => setItemHoverKey(null)}
    >
      <StyledItemContainer data-testid={`label-item-${renderProps.item.id}`}>
        <Flex flexGrow={1} flexBasis="max-content" mr="1rem">
          {renderProps.item.label}
        </Flex>
        {renderProps.item.isEditable && isHoveringKey && (
          <>
            <Flex mr="1rem">
              <StyledButtonIcon
                dataTestId={`label-item-${renderProps.item.id}-edit-icon`}
                onClick={(event) => {
                  event.stopPropagation()
                  setSelectedEditItem(renderProps.item)
                  setIsSelectingItem(false)
                }}
              >
                <SvgIcon height="16" width="16" hoverColor={COLOR.BLUE_1000}>
                  <IconEdit />
                </SvgIcon>
              </StyledButtonIcon>
            </Flex>
            <Flex>
              <StyledButtonIcon
                dataTestId={`label-item-${renderProps.item.id}-delete-icon`}
                onClick={(event) => {
                  event.stopPropagation()
                  setSelectedDeleteItem(renderProps.item)
                  setIsSelectingItem(false)
                }}
              >
                <SvgIcon height="16" width="16" hoverColor={COLOR.BLUE_1000}>
                  <IconClose />
                </SvgIcon>
              </StyledButtonIcon>
            </Flex>
          </>
        )}
      </StyledItemContainer>
    </InputSelectComboboxStandardItemWrapper>
  )
}
