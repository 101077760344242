import { gql, useApolloClient, useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  GetXeroSiteSettings as GetXeroSiteSettingsResponse,
  GetXeroSiteSettings_getXeroSiteSettings as GetXeroSiteSettingsResponseData,
  GetXeroSiteSettingsVariables,
} from 'types/gql-types/GetXeroSiteSettings'

export const GetXeroSiteSettings = gql`
  query GetXeroSiteSettings($entityUuid: ID!) {
    getXeroSiteSettings(entityUuid: $entityUuid) {
      __typename
      id
      name
      receipt {
        email
        facebook
        instagram
        logo
        message
        name
        phone
        returnsMessage
        twitter
        website
      }
    }
  }
`

export const useGetXeroSiteSettings = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useQuery<
    GetXeroSiteSettingsResponse,
    GetXeroSiteSettingsVariables
  >(GetXeroSiteSettings, {
    variables: {
      entityUuid,
    },
  })

  const client = useApolloClient()

  const updateSiteSettingsCache = (
    updatedValues: GetXeroSiteSettingsResponseData
  ) => {
    client.cache.writeQuery<GetXeroSiteSettingsResponse>({
      query: GetXeroSiteSettings,
      variables: {
        entityUuid,
      },
      data: {
        getXeroSiteSettings: updatedValues,
      },
    })
  }

  return {
    siteSettings: data?.getXeroSiteSettings,
    updateSiteSettingsCache,
    isLoading: loading,
  }
}
