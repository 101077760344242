type UseSkeletonLoaderWidthProps = {
  rowIndex: number
  sampleWidthArray: Array<string> | Array<Array<string>>
}

export const useSkeletonLoaderWidth = ({
  rowIndex,
  sampleWidthArray,
}: UseSkeletonLoaderWidthProps) => {
  const reminder = (rowIndex + 1) % sampleWidthArray.length
  return sampleWidthArray[reminder]
}
