import styled from 'styled-components'

import { ZellerCard } from './ZellerCard/ZellerCard'

interface StyledZellerCardProps {
  isDisabled: boolean
}

export const StyledZellerCard = styled(ZellerCard)<StyledZellerCardProps>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  width: 48px;
  min-width: 48px;
`
