import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'

import { SelfieIDOption } from '../SelfieVerification.types'

export const useSelectedDocument = () => {
  const { search } = useLocation()

  const selectedDocument = useMemo(() => {
    const params = new URLSearchParams(search)

    const maybeDocument = params.get('document')

    if (
      !Object.values(SelfieIDOption).includes(maybeDocument as SelfieIDOption)
    ) {
      return null
    }

    return maybeDocument as SelfieIDOption
  }, [search])

  return { selectedDocument }
}
