import { BodySmall, ButtonFill } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const BodySmallNoMargin = styled(BodySmall)`
  margin: 0;
`

export const BorderedButtonFillWrapper = styled(ButtonFill)`
  border: ${({ theme }) => `1px solid ${theme.colors.WHITE}`};
`
