import { makeVar } from '@apollo/client'

import { DepositsAppState, ProfileSettingsAppState } from 'types/auth'

export const rvStepUpAuthDepositsObject = makeVar<DepositsAppState>(
  {} as DepositsAppState
)

export const rvStepUpAuthProfileSettingsObject =
  makeVar<ProfileSettingsAppState>({} as ProfileSettingsAppState)

export const rvIsE2eInProgress = makeVar(false)
