import { InvoiceItemUnit } from '@npco/mp-gql-types'
import { AccordionComplexProps } from '@npco/zeller-design-system'
import currency from 'currency.js'

import { PaymentTerms } from '../../Invoice.types'

export type InvoiceAccordionCommonProps = Pick<
  AccordionComplexProps,
  'isExpanded' | 'onChange'
>

export type InvoiceNameSelectedItem = {
  catalogItemUuid: string
  description: string
  id?: string
  label: string
  price: currency
  taxApplicable: boolean
  value: string
}

export enum InvoiceNameSelectedTab {
  SavedItems = 'SavedItems',
  RecentlyUsed = 'RecentlyUsed',
}

export type InvoicePaymentTermsSelectedItem = {
  isDisabled?: boolean
  value: PaymentTerms
  label: string
}

export type InvoiceUnitSelectedItem = {
  value: InvoiceItemUnit
  label: string
}
