import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { GetContactNameContactFragmentDoc } from '@npco/mp-utils-contacts-format'

export type ContactHeadingContactFragment = {
  __typename?: 'Contact'
  isSelf: boolean | null
  businessName: string | null
  contactType: Types.ContactType
  firstName: string | null
  lastName: string | null
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
}

export const ContactHeadingContactFragmentDoc = gql`
  fragment ContactHeadingContactFragment on Contact {
    ...GetContactNameContactFragment
    icon {
      images {
        size
        url
      }
      colour
      letter
    }
    isSelf
  }
  ${GetContactNameContactFragmentDoc}
` as unknown as TypedDocumentNode<ContactHeadingContactFragment, undefined>
