import { gql } from '@apollo/client'

export const CreateContact = gql`
  mutation CreateContact(
    $contact: ContactInput!
    $linkedContactUuid: ID
    $entityUuid: ID!
  ) {
    createContact(
      contact: $contact
      linkedContactUuid: $linkedContactUuid
      entityUuid: $entityUuid
    ) {
      abn
      acn
      additionalEmails {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      additionalPhones {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      address {
        country
        postcode
        state
        street
        suburb
      }
      businessName
      category
      contactType
      email {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      firstName
      id
      jobTitle
      lastName
      phone
      phoneV2 {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      website
      location
      locationAccuracy
      subcategory
      subcategoryUuid
      isSelf
    }
  }
`

export const CreateContacts = gql`
  mutation CreateContacts(
    $entityUuid: ID!
    $contact1: ContactInput!
    $contact2: ContactInput!
  ) {
    createContacts(
      entityUuid: $entityUuid
      contact1: $contact1
      contact2: $contact2
    ) {
      abn
      acn
      additionalEmails {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      additionalPhones {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      address {
        country
        postcode
        state
        street
        suburb
      }
      businessName
      category
      contactType
      email {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      firstName
      id
      jobTitle
      lastName
      phone
      phoneV2 {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      website
      location
      locationAccuracy
      subcategory
      subcategoryUuid
      isSelf
    }
  }
`

export const LinkContacts = gql`
  mutation LinkContacts(
    $entityUuid: ID!
    $contact1Uuid: ID!
    $contact2Uuid: ID!
  ) {
    linkContacts(
      entityUuid: $entityUuid
      contact1Uuid: $contact1Uuid
      contact2Uuid: $contact2Uuid
    )
  }
`

export const UnlinkContacts = gql`
  mutation UnlinkContacts(
    $entityUuid: ID!
    $contact1Uuid: ID!
    $contact2Uuid: ID!
  ) {
    unlinkContacts(
      entityUuid: $entityUuid
      contact1Uuid: $contact1Uuid
      contact2Uuid: $contact2Uuid
    )
  }
`

export const UpdateContact = gql`
  mutation UpdateContact(
    $entityUuid: ID!
    $contact: ContactUpdateInput!
    $id: ID!
  ) {
    updateContact(entityUuid: $entityUuid, contact: $contact, id: $id)
  }
`

export const DeleteContact = gql`
  mutation DeleteContact($entityUuid: ID!, $id: ID!) {
    deleteContact(entityUuid: $entityUuid, id: $id)
  }
`

export const RemoveContactImage = gql`
  mutation RemoveContactImage($entityUuid: ID!, $contactUuid: ID!) {
    removeContactImage(entityUuid: $entityUuid, contactUuid: $contactUuid)
  }
`

export const TagContact = gql`
  mutation TagContact(
    $entityUuid: ID!
    $contactUuid: ID!
    $tagNames: [String!]!
  ) {
    tagContact(
      entityUuid: $entityUuid
      contactUuid: $contactUuid
      tagNames: $tagNames
    )
  }
`

export const LinkSubcategoryToContact = gql`
  mutation LinkSubcategoryToContact(
    $entityUuid: ID!
    $contactUuid: ID!
    $subcategoryUuid: ID!
    $category: EntityCategories!
  ) {
    linkContactWithSubcategory(
      entityUuid: $entityUuid
      contactUuid: $contactUuid
      subcategoryUuid: $subcategoryUuid
      category: $category
    )
  }
`

export const UnlinkSubcategoryToContact = gql`
  mutation UnlinkSubcategoryToContact(
    $entityUuid: ID!
    $contactUuid: ID!
    $subcategoryUuid: ID!
  ) {
    unlinkContactWithSubcategory(
      entityUuid: $entityUuid
      contactUuid: $contactUuid
      subcategoryUuid: $subcategoryUuid
    )
  }
`
