import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Message,
  MessageSize,
  SuccessMountain,
} from '@npco/zeller-design-system'

import { outstandingExpensesEmptyTranslations } from './OutstandingExpensesEmpty.i18n'

export const OutstandingExpensesEmpty = () => {
  const t = useTranslations(outstandingExpensesEmptyTranslations)

  return (
    <Box textAlign="center" pt="40px" data-testid="outstanding-expenses-empty">
      <Message
        title={t('title')}
        description={t('description')}
        size={MessageSize.LARGE}
        align="center"
        image={<SuccessMountain size="large" />}
      />
    </Box>
  )
}
