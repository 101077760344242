import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ErrorMessageForm,
  InputSelectComboboxStandard,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { INVOICE_EMAIL_RECIPIENT_FIELD } from '../../../../../../../../Invoice.constants'
import { useEmailSelectCombobox } from '../../../../../../hooks/useEmailSelectCombobox'
import { translations } from '../InvoiceEmailRecipients.i18n'

export const InvoicePayerEmailAddress = () => {
  const t = useTranslations(translations)

  const [field, meta, helpers] = useField({
    name: INVOICE_EMAIL_RECIPIENT_FIELD,
  })

  const { filteredItems, renderComboboxItem } = useEmailSelectCombobox(
    field.value
  )

  const payerEmailError = meta.touched ? meta.error : ''

  const handleOnChange = useCallback(
    (changes) => helpers.setValue(changes.selectedItem?.value),
    [helpers]
  )

  const handleOnInputChange = useCallback(
    (changes) => {
      helpers.setValue(changes.inputValue)
    },
    [helpers]
  )

  const handleOnInputClear = useCallback(() => helpers.setValue(''), [helpers])

  return (
    <div>
      <InputSelectComboboxStandard
        hasError={Boolean(payerEmailError)}
        inputValue={field.value}
        items={filteredItems}
        label={t('payerEmail')}
        name={field.name}
        onBlur={field.onBlur}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        onInputClear={handleOnInputClear}
        selectedItem={undefined}
        renderItem={renderComboboxItem}
      />
      <ErrorMessageForm
        errorMessage={payerEmailError}
        hasError={Boolean(payerEmailError)}
      />
    </div>
  )
}
