import { useEffect } from 'react'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { excludeGraphQLFetch } from 'apollo-link-sentry'

import { IS_SENTRY_ENABLED, SENTRY_DSN, SENTRY_ENVIRONMENT } from 'const/envs'

import { ignoreErrors } from './useSentry.config'

export const useSentry = () => {
  useEffect(() => {
    try {
      if (IS_SENTRY_ENABLED) {
        Sentry.init({
          allowUrls: ['myzeller.com', 'myzeller.dev'],
          attachStacktrace: true,
          beforeBreadcrumb: excludeGraphQLFetch,
          dsn: SENTRY_DSN,
          environment: SENTRY_ENVIRONMENT ?? 'unknown',
          // List errors that are confirmed not affected UX and fixes are in progress
          ignoreErrors,
          integrations: [new BrowserTracing()],

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 0.3,
        })
      }
    } catch {
      // No error reporting if sentry is not configured
    }
  }, [])
}
