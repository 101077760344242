import { useCallback, useMemo } from 'react'
import { useDisableNavigation } from '@npco/ui-drawer-navigation'
import { useModalState } from 'design-system/Components/Modal'

import { useUpdateDebitCardTransaction } from 'hooks/banking'
import { useAddEntityTag } from 'hooks/entityTags/useAddEntityTag'
import { useDeleteEntityTag } from 'hooks/entityTags/useDeleteEntityTag'
import { useEntityTags } from 'hooks/entityTags/useEntityTags'
import { PartialTransactionUpdateType } from 'hooks/transactions'
import { hasArrayContentChanged } from 'utils/arrayHelper'
import { translate } from 'utils/translations'
import {
  MAXIMUM_TAGS_PER_TRANSACTION,
  validateAddNewTag,
} from 'components/EditTags'
import { EditTags } from 'components/EditTags/EditTags'

import { useTransactionTags } from './hooks/useTransactionTags'
import {
  showDeleteTagsError,
  showDeleteTagsSuccess,
  showEditTagsError,
  showEditTagsSuccess,
} from './utils/displayToasts'

interface Props {
  initialTags: string[]
  transactionUuid: string
  updateTransaction: (update: PartialTransactionUpdateType) => void
  buttonLabel: string
}

export const EditTransactionTags = ({
  transactionUuid,
  updateTransaction,
  initialTags,
  buttonLabel,
}: Props) => {
  const { entityTags } = useEntityTags()
  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()

  const { isModalOpen, openModal, closeModal } = useModalState()

  useDisableNavigation({ shouldDisableNavigation: isModalOpen })

  const { addEntityTag, isAddingEntityTag } = useAddEntityTag()

  const { deleteEntityTag, isDeletingEntityTag } = useDeleteEntityTag()

  const { saveTags, isSavingTags } = useTransactionTags({
    transactionUuid,
  })

  const tagOptions = useMemo(
    () => entityTags?.map((tag) => ({ label: tag, value: tag })) || [],
    [entityTags]
  )

  const handleAddNewTag = useCallback(
    async (tag: string) => {
      try {
        const response = await addEntityTag(tag)
        return Boolean(response.data?.addEntityTag)
      } catch (error) {
        return false
      }
    },
    [addEntityTag]
  )

  const handleSaveTags = useCallback(
    async (tags: string[]) => {
      if (!hasArrayContentChanged(initialTags, tags)) {
        return true
      }
      try {
        const response = await saveTags(tags)
        if (!response.data?.updateDebitCardTransactionAnnotations) {
          showEditTagsError()
          return false
        }

        updateTransaction({ tags })
        updateDebitCardTransaction(transactionUuid, { tags })
        showEditTagsSuccess()

        return true
      } catch (error) {
        showEditTagsError()
        return false
      }
    },
    [
      saveTags,
      updateTransaction,
      initialTags,
      updateDebitCardTransaction,
      transactionUuid,
    ]
  )

  const handleDeleteTag = async (tag: string) => {
    try {
      const response = await deleteEntityTag(tag)

      if (!response.data?.removeEntityTag) {
        showDeleteTagsError()
        return false
      }

      showDeleteTagsSuccess()

      return true
    } catch (error) {
      showDeleteTagsError()
      return false
    }
  }

  return (
    <EditTags
      buttonLabel={buttonLabel}
      initialTags={initialTags}
      inputLabel={translate('page.transactionDetails.tags.label')}
      isAddingEntityTag={isAddingEntityTag}
      isDeletingEntityTag={isDeletingEntityTag}
      isSavingTags={isSavingTags}
      itemsInSearch={tagOptions}
      onSaveEdit={handleSaveTags}
      onAddNewTag={handleAddNewTag}
      onDeleteEntityTag={handleDeleteTag}
      placeholder={translate('page.transactionDetails.tags.modalPlaceholder')}
      title={translate('page.transactionDetails.tags.modalTitle')}
      validate={validateAddNewTag(MAXIMUM_TAGS_PER_TRANSACTION)}
      onOpenModal={openModal}
      onCloseModal={closeModal}
    />
  )
}
