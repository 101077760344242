import { useCallback } from 'react'
import { isEqual } from 'lodash-es'

import { ALL_VALUE, MultiSelectItemBasic } from '../MultiSelect.types'

interface Args<ItemType> {
  items: ItemType[]
  selectedItems: ItemType[]
  onChange: (items: ItemType[]) => void
}

export function useMultiSelectItemsHandlers<
  ItemType extends MultiSelectItemBasic
>({ items, selectedItems, onChange }: Args<ItemType>) {
  const markAllAsSelected = useCallback(() => {
    onChange?.(items)
  }, [onChange, items])

  const clearAllSelected = useCallback(() => {
    onChange([])
  }, [onChange])

  const toggleAllSelected = useCallback(() => {
    if (selectedItems.length === items.length) {
      clearAllSelected()
    } else {
      markAllAsSelected()
    }
  }, [clearAllSelected, markAllAsSelected, selectedItems, items])

  const addSelectedItem = useCallback(
    (item: ItemType) => {
      onChange([...selectedItems, item])
    },
    [onChange, selectedItems]
  )

  const removeSelectedItem = useCallback(
    (item: ItemType) => {
      onChange(
        selectedItems.filter(
          (selectedItem) =>
            selectedItem.value !== item.value &&
            selectedItem.value !== ALL_VALUE
        )
      )
    },
    [onChange, selectedItems]
  )

  const changeSelectedItems = useCallback(
    (selectedItem: ItemType | null) => {
      if (!selectedItem) {
        return
      }

      if (selectedItem.value === ALL_VALUE) {
        toggleAllSelected()
        return
      }

      // Use deep comparison rather than object reference comparison
      if (selectedItems.some((item) => isEqual(item, selectedItem))) {
        removeSelectedItem(selectedItem)
        return
      }

      addSelectedItem(selectedItem)
    },
    [addSelectedItem, toggleAllSelected, removeSelectedItem, selectedItems]
  )

  return {
    changeSelectedItems,
    clearAllSelected,
  }
}
