import { COLOR, Flex, Tutorial } from '@npco/zeller-design-system'

interface BulletPointListProps {
  points: string[] | React.ReactNode[]
  iconColor?: string
  gap?: number
}
export const BulletPointList = ({
  points,
  iconColor = COLOR.GREEN_1000,
  gap = 4,
}: BulletPointListProps) => {
  return (
    <Flex flexDirection="column" gap={`${gap}px`} lineHeight="24px">
      {points.map((point, index) => {
        const key = `point-${index}`
        return (
          <Tutorial.BulletPoint key={key} text={point} iconColour={iconColor} />
        )
      })}
    </Flex>
  )
}
