import { PdfTemplateType } from '@npco/mp-gql-types'

import { GetZellerInvoiceSettingsQueryResponse } from '../graphql/getZellerInvoiceSettings.generated'

export type GetZellerInvoiceCustomisationResponse =
  GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']['invoice']['customisation']

export const isPdfTemplateType = (value: string): value is PdfTemplateType => {
  return Object.values(PdfTemplateType).includes(value as PdfTemplateType)
}

export const INVOICE_CUSTOMISATION_FORM_FIELDS = {
  selectedTemplateType: 'selectedTemplateType',
  logos: 'logos',
  accentColours: 'accentColours',
  selectedColor: 'selectedColor',
  selectedLogo: 'selectedLogo',
  defaultMessage: 'defaultMessage',
  termsAndConditions: 'termsAndConditions',
} as const

export type InvoiceSettingsCustomisationFormFields = {
  [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedTemplateType]: PdfTemplateType
  [INVOICE_CUSTOMISATION_FORM_FIELDS.logos]: string[]
  [INVOICE_CUSTOMISATION_FORM_FIELDS.accentColours]: string[]
  [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedColor]: string
  [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedLogo]: string
  [INVOICE_CUSTOMISATION_FORM_FIELDS.defaultMessage]: string
  [INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions]: string
}
