import { useEffect, useMemo, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { Contacts, SelectStateChangeProps } from '@npco/zeller-design-system'
import { ContactComboBoxItem } from '@npco/zeller-design-system/dist/FeatureComponents/Contacts/ContactCombobox/ContactCombobox.types'
import { getContactsInputComboboxItems } from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import { useContacts } from 'features/Contacts/hooks/useContacts/useContacts'
import { initialValues } from 'features/Invoicing/components/Invoices/Invoice/components/InvoiceCreateForm/InvoiceCreateForm.utils'
import { INVOICE_PAYER_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'

import {
  getCustomerFieldError,
  getCustomerFormValues,
  getCustomerSelectedItem,
  isCustomerFieldsDisabled,
} from '../InvoiceCustomerAccordion.utils'

type GetContact = ContactCoreFieldsFragment

export const translations = {
  businessSubLabel: translate('page.contacts.form.business'),
  validationErrorMessages: {
    CONTACT_REQUIRED: '',
    CONTACT_SELECTION_REQUIRED: translate(
      'page.invoice.formSections.customer.payerRequired'
    ),
  },
}

export const usePayerContactCombobox = () => {
  const {
    errors,
    initialValues: initialFormValues,
    setValues,
    values,
  } = useFormikContext<InvoiceFormFields>()

  const isDisabled = isCustomerFieldsDisabled(values.status)

  const [field] = useField({
    name: INVOICE_PAYER_FIELD,
  })

  const { writeToCache } = useContactCache()

  const [contactTypeToCreate, setContactTypeToCreate] =
    useState<ContactType | null>(null)

  const [validationError, setValidationError] = useState('')

  const { filters, name$ } = Contacts.useContactsFilters()

  const {
    contacts,
    loading: isLoading,
    hasMore,
    loadMore,
  } = useContacts({
    filters,
    limit: 50,
    isNetworkOnly: true,
    skip: isDisabled,
  })

  const clearFieldsOnCustomerChange = (
    selectedItem: ContactComboBoxItem | null
  ) => {
    const payerContact = selectedItem
      ? {
          contactName: selectedItem.value,
          contactUuid: selectedItem.id,
          contactType: selectedItem.subLabel
            ? ContactType.BUSINESS
            : ContactType.PERSON,
        }
      : initialValues.customer.payerContact

    setValues({
      ...values,
      customer: {
        attentionToContact: initialValues.customer.attentionToContact,
        payerContact,
      },
      delivery: {
        email: {
          ...values.delivery.email,
          bcc: [],
          cc: [],
          recipient: '',
        },
        sms: {
          ...values.delivery.sms,
          recipient: '',
        },
      },
    })
  }

  const handleOnInputClear = () => {
    clearFieldsOnCustomerChange(null)
  }

  const handleOnChange = (
    changes: SelectStateChangeProps<ContactComboBoxItem<void>>
  ) => {
    if (!changes.selectedItem?.id) {
      return
    }

    clearFieldsOnCustomerChange(changes.selectedItem)
  }

  const handleOnInputChange = (value: string) => {
    if (value) {
      return
    }
    handleOnInputClear()
  }

  const {
    handleChange,
    handleClose,
    handleInputChange,
    handleInputClear,
    inputValue,
    selectedItem,
    setSelectedItem,
  } = Contacts.useContactCombobox({
    isOptional: true,
    name$,
    onChange: handleOnChange,
    onInputClear: handleOnInputClear,
    onInputChange: handleOnInputChange,
    onValidationError: setValidationError,
    validationErrorMessages: translations.validationErrorMessages,
  })

  const { payerContact: initialPayerContact } = initialFormValues.customer

  useEffect(() => {
    setSelectedItem(getCustomerSelectedItem(values.customer.payerContact))

    setValidationError('')
    // Initialise set selected item on mount or when
    // initialPayerContact is re-initialised in Edit mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPayerContact])

  const payerItems = useMemo(
    () => getContactsInputComboboxItems(contacts),
    [contacts]
  )

  const handleCreateContact = (
    contact: CreateContact | CreateContactsContact,
    subContact?: GetContact | null
  ) => {
    const newContact = getCustomerFormValues(contact)
    setValues((prev) => ({
      ...prev,
      customer: {
        ...prev.customer,
        payerContact: newContact,
        ...(subContact?.id && {
          attentionToContact: getCustomerFormValues(subContact),
        }),
      },
    }))

    setSelectedItem(getCustomerSelectedItem(newContact))

    writeToCache(contact, subContact)

    setValidationError('')
  }

  return {
    contactTypeToCreate,
    error:
      getCustomerFieldError('payerContact', errors?.customer) ??
      validationError,
    handleChange,
    handleClose,
    handleCreateContact,
    handleInputChange,
    handleInputClear,
    handleOnblur: field.onBlur,
    hasMore,
    inputValue,
    isDisabled,
    isLoading,
    loadMore,
    payerItems,
    selectedItem,
    setContactTypeToCreate,
  }
}
