import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type GetEndOfCurrentWindowVelocityControlTypeFragment = {
  __typename?: 'VelocityControlType'
  resetsAt: any
  velocityWindow: Types.VelocityWindowEnum
}

export const GetEndOfCurrentWindowVelocityControlTypeFragmentDoc = gql`
  fragment GetEndOfCurrentWindowVelocityControlTypeFragment on VelocityControlType {
    resetsAt
    velocityWindow
  }
` as unknown as TypedDocumentNode<
  GetEndOfCurrentWindowVelocityControlTypeFragment,
  undefined
>
