import { useReactiveVar } from '@apollo/client'
import { rvSiteTypeList } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useQuerySitesWithTypes } from 'hooks/useQuerySitesWithTypes'
import { SiteWithType } from 'types/site'

export const useGetSitesWithTypes = () => {
  const sites: SiteWithType[] = useReactiveVar(rvSiteTypeList)
  const hasLoadedSites = sites.length !== 0

  const { isLoading } = useQuerySitesWithTypes({
    shouldSkipQuery: hasLoadedSites,
  })

  return { sites, isLoading }
}
