import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { translate } from 'utils/translations'
import { FileWithId } from 'components/UploadFileModal/hooks/useFileList'
import { UploadInProgress } from 'components/UploadFileModal/UploadInProgress/UploadInProgress'

import { useUploadToS3 } from './hooks/useUploadToS3'

interface Props {
  filesToUpload: FileWithId[]
  transactionUuid: string
  isOpen: boolean
  goToNextStep: (
    failedFiles: FileWithId[],
    successfulFiles: FileWithId[]
  ) => void
  onAbort: (failedFiles: FileWithId[]) => void
}

export const UploadingStep = ({
  filesToUpload,
  transactionUuid,
  isOpen,
  goToNextStep,
  onAbort,
}: Props) => {
  const { numberOfFilesThatFinished, abortUpload } = useUploadToS3({
    filesToUpload,
    transactionUuid,
    onComplete: goToNextStep,
    onAbort,
  })

  return (
    <ModalBasic
      title={translate('page.transactionDetails.images.modal.title')}
      isOpen={isOpen}
      onCancel={abortUpload}
    >
      <UploadInProgress
        totalNumberOfFiles={filesToUpload.length}
        numberOfFilesThatFinished={numberOfFilesThatFinished}
      />
    </ModalBasic>
  )
}
