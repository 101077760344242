import { useBlockedActionState } from '../../blocked-action-routing'
import { BlockedActionModalContent } from './BlockedActionModalContent/BlockedActionModalContent'

export const BlockedActionModal = () => {
  const { state } = useBlockedActionState()

  if (state === null) {
    return null
  }

  return <BlockedActionModalContent />
}
