import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'

import { AccountUpdateFragment } from './graphql/AccountUpdateFragment.generated'

export const useCacheAccount = () => {
  const { cache } = useApolloClient()

  const cacheAccountUpdate = useCallback(
    (accountUpdate: AccountUpdateFragment) => {
      cache.modify({
        id: cache.identify({
          id: accountUpdate.id,
          __typename: 'DebitCardAccountV2',
        }),
        fields: {
          name() {
            return accountUpdate.name
          },
          icon() {
            return accountUpdate.icon
          },
          balance() {
            return accountUpdate.balance
          },
          status() {
            return accountUpdate.status
          },
        },
      })
    },
    [cache]
  )

  return { cacheAccountUpdate }
}
