import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  InputAdaptiveField,
  InputAdaptiveTextArea,
} from '@npco/zeller-design-system'
import { Field } from 'formik'

import { InputAdaptiveCurrencyField } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField'
import { ItemCategoriesField } from 'components/ItemCategories'

import {
  ITEM_CATEGORIES_FIELD,
  ITEM_DESCRIPTION_FIELD,
  ITEM_DESCRIPTION_MAX_CHARACTERS,
  ITEM_NAME_FIELD,
  ITEM_PRICE_FIELD,
  ITEM_SKU_FIELD,
} from '../ItemBaseModalForm.constants'
import { AddNewItemCheckbox } from './AddNewItemCheckbox/AddNewItemCheckbox'
import { translations } from './ItemFields.i18n'
import * as styled from './ItemFields.styled'
import { ItemTaxApplicable } from './ItemTaxApplicable/ItemTaxApplicable'

interface ItemFieldsProps {
  showAddNewItemCheckbox?: boolean
  showDescription?: boolean
  skuDuplicateError?: string
}

export const ItemFields = ({
  showDescription,
  showAddNewItemCheckbox,
  skuDuplicateError,
}: ItemFieldsProps) => {
  const t = useTranslations(translations)
  return (
    <Flex
      data-testid="items-item-fields"
      flexDirection="column"
      flexGrow={1}
      gridGap="20px"
      pt="12px"
    >
      <Flex flexDirection="column">
        <InputAdaptiveField
          aria-label={t('nameAriaLabel')}
          data-testid="items-item-fields-name"
          name={ITEM_NAME_FIELD}
          label={t('name')}
        />
      </Flex>
      {showDescription && (
        <Flex flexDirection="column">
          <Field
            aria-label={t('descriptionAriaLabel')}
            component={InputAdaptiveTextArea}
            id="description"
            label={t('description')}
            marginBottom="0"
            maxLength={ITEM_DESCRIPTION_MAX_CHARACTERS}
            name={ITEM_DESCRIPTION_FIELD}
            rows={4}
          />
        </Flex>
      )}
      <Flex flexDirection="column">
        <InputAdaptiveField
          aria-label={t('skuAriaLabel')}
          data-testid="items-item-fields-sku"
          name={ITEM_SKU_FIELD}
          hasError={Boolean(skuDuplicateError)}
          label={t('sku')}
        />
        {skuDuplicateError && (
          <styled.ErrorMessage>{skuDuplicateError}</styled.ErrorMessage>
        )}
      </Flex>
      <Flex flexDirection="column">
        <InputAdaptiveCurrencyField
          ariaLabel={t('priceAriaLabel')}
          dataTestId="items-item-field-price"
          isAmountAlignLeft
          name={ITEM_PRICE_FIELD}
        />
      </Flex>
      <ItemTaxApplicable />
      <ItemCategoriesField fieldName={ITEM_CATEGORIES_FIELD} />
      {showAddNewItemCheckbox && <AddNewItemCheckbox />}
    </Flex>
  )
}
