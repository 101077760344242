import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { SavingsAccountLearnMoreState } from '../../SavingsAccountLearnMore.types'

export const useGoToSavingsAccountLearnMore = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToSavingsAccountLearnMore = useCallback(
    (state: SavingsAccountLearnMoreState | null) => {
      navigate(location.pathname, {
        state: {
          SavingsAccountLearnMoreModal: state,
        },
      })
    },
    [location.pathname, navigate]
  )

  return { goToSavingsAccountLearnMore }
}
