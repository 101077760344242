import { gql } from '@apollo/client'

import { InvoiceListCoreFields } from './invoiceListCoreFieldsFragment'

export const GetInvoices = gql`
  ${InvoiceListCoreFields}
  query GetInvoices($entityUuid: ID!, $input: GetInvoicesInput!) {
    getInvoices(entityUuid: $entityUuid, input: $input) {
      invoices {
        ...InvoiceListCoreFields
      }
      nextToken
    }
  }
`
