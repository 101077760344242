import { translate } from 'utils/translations'

export enum FilterState {
  FilterAndSort,
  SortBy,
  FilterByStatus,
  FilterByDueDate,
  FilterByAmount,
  FilterBySearch,
}

export const FilterAndSortOptions: Array<{
  value: FilterState
  label: string
  header: string
}> = [
  {
    value: FilterState.SortBy,
    label: translate('page.invoiceTable.mobile.filterLabels.sortBy'),
    header: translate('page.invoiceTable.mobile.filterHeaders.sortBy'),
  },
  {
    value: FilterState.FilterByStatus,
    label: translate('page.invoiceTable.mobile.filterLabels.status'),
    header: translate('page.invoiceTable.mobile.filterHeaders.status'),
  },
  {
    value: FilterState.FilterByDueDate,
    label: translate('page.invoiceTable.mobile.filterLabels.nextDue'),
    header: translate('page.invoiceTable.mobile.filterHeaders.nextDue'),
  },
  {
    value: FilterState.FilterByAmount,
    label: translate('page.invoiceTable.mobile.filterLabels.paidAmount'),
    header: translate('page.invoiceTable.mobile.filterHeaders.paidAmount'),
  },
  {
    value: FilterState.FilterBySearch,
    label: translate('page.invoiceTable.mobile.filterLabels.search'),
    header: translate('page.invoiceTable.mobile.filterHeaders.search'),
  },
]
