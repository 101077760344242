import { Flex } from '@npco/zeller-design-system'

import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'

import { StyledLabel, StyledValue } from './DrawerItem.styled'

export const DrawerItemSkeleton = () => {
  return (
    <Flex
      justifyContent="space-between"
      pb="1.25rem"
      data-testid="drawer-item-skeleton"
    >
      <StyledLabel>
        <SkeletonLoaderBasic width={118} height={8} isRounded />
      </StyledLabel>
      <StyledValue>
        <SkeletonLoaderBasic width={118} height={8} isRounded />
      </StyledValue>
    </Flex>
  )
}
