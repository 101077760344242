import { SkeletonBodyDefault as SkeletonBodyBase } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ListItem } from './ListItem'

const SkeletonBody = styled(SkeletonBodyBase)`
  margin: 0;
`

export type ListItemSkeletonProps = Record<string, never>

export const ListItemSkeleton = () => (
  <ListItem
    left={<SkeletonBody width={100} />}
    right={<SkeletonBody width={60} />}
  />
)
