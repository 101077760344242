import { mapSourceFilterToApi } from 'utils/mapFiltersInputToApiFilter'

import { useSourcePicker } from '../SourcePicker/useSourcePicker'

export const useSourceFilter = () => {
  const { selectedSources } = useSourcePicker()
  if (selectedSources.length === 0) {
    return { filter: undefined }
  }
  return { filter: { sourceFilter: mapSourceFilterToApi(selectedSources) } }
}
