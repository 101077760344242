import { useEffect, useState } from 'react'
import {
  getStoryblokApi,
  ISbStoriesParams,
  ISbStoryData,
  registerStoryblokBridge,
  StoryblokBridgeConfigV2,
} from '@storyblok/react'

// The useStoryblok hook provided by @storyblok/react does not handle fetch errors
// leading to numerous unhandled errors reported in Sentry.
// The following custom hook mimics the functionality of useStoryblok,
// but with enhanced error handling to catch fetch errors.
export const useStoryblok = (
  slug: string,
  apiOptions: ISbStoriesParams = {},
  bridgeOptions: StoryblokBridgeConfigV2 = {}
) => {
  const [story, setStory] = useState<ISbStoryData | null>(null)
  const storyblokApiInstance = getStoryblokApi()
  const isBridgeEnable =
    typeof window !== 'undefined' &&
    typeof window.storyblokRegisterEvent !== 'undefined'

  const apiOptionsString = JSON.stringify(apiOptions)
  const bridgeOptionsString = JSON.stringify(bridgeOptions)
  const bridgeOptionsForBridge = {
    ...bridgeOptions,
    resolveRelations:
      bridgeOptions.resolveRelations ?? apiOptions.resolve_relations,
    resolveLinks: bridgeOptions.resolveLinks ?? apiOptions.resolve_links,
  }

  useEffect(() => {
    if (!storyblokApiInstance) {
      console.error(
        "You can't use useStoryblok if you're not loading apiPlugin."
      )
      return
    }
    async function initStory() {
      try {
        const { data } = await storyblokApiInstance.get(
          `cdn/stories/${slug}`,
          apiOptions
        )
        setStory(data.story)
        // this is for live editing in storyblok
        if (isBridgeEnable && data?.story?.id) {
          registerStoryblokBridge(
            data.story.id,
            (story) => setStory(story),
            bridgeOptionsForBridge
          )
        }
      } catch (e) {
        // No need to handle this error as it occurs when the requested resource in Storyblok is deleted.
        // Simply catch the error to prevent Sentry from logging it.
      }
    }
    initStory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiOptionsString,
    bridgeOptionsString,
    isBridgeEnable,
    slug,
    storyblokApiInstance,
  ])

  if (!storyblokApiInstance) {
    return null
  }

  return story
}
