import { useMemo } from 'react'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { conditionalClassName as cn } from 'utils/conditionalClassName'
import { translate } from 'utils/translations'

import {
  StyledActiveFilterIndicator,
  StyledButtonGhostIconOnly,
  StyledWrapper,
} from './DashboardFiltersButton.styled'

interface Props {
  dataTestId?: string
  onClick?: () => void
  isActive: boolean
  disabled: boolean
  icon?: React.FC
  showIndicator?: boolean
}

export const DashboardFiltersButton = ({
  dataTestId = 'filters-toggle-button',
  disabled,
  icon: Icon = SearchIcon,
  isActive,
  onClick,
  showIndicator,
}: Props) => {
  const isMobile = useIsMobileResolution()

  const IconComponent = useMemo(() => {
    if (isMobile) {
      return () => (
        <SvgIcon
          width="16"
          height="16"
          color={disabled ? COLOR.GREY_250 : undefined}
        >
          <Icon />
        </SvgIcon>
      )
    }

    return Icon
  }, [Icon, disabled, isMobile])

  return (
    <StyledWrapper>
      {showIndicator && (
        <StyledActiveFilterIndicator data-testid="filters-dot-indicator" />
      )}
      <StyledButtonGhostIconOnly
        className={cn({
          isActive,
        })}
        dataTestId={dataTestId}
        disabled={disabled}
        icon={IconComponent}
        onClick={onClick}
        aria-label={translate('component.dashboardFiltersButton.label')}
      />
    </StyledWrapper>
  )
}
