import { useMemo, useState } from 'react'
import {
  DebitCardTransactionStatusV2,
  EntityCategories,
} from '@npco/mp-gql-types'
import { equals } from 'ramda'

import { ContactListItem } from 'layouts/AccountLayout/filters/AccountFilterContact/AccountFilterContact.utils'
import {
  FilterableTransactionType,
  transactionTypeItems,
} from 'layouts/AccountLayout/filters/AccountFilterTransactionType/AccountFilterTransactionType.constants'
import { FilterType } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList.constants'

import { FilterConfig } from './useAccountFiltersValues.types'
import { getTransactionTypeFromInitialConfig } from './useAccountFiltersValues.utils'

export interface CategoryWithSubcategory {
  category: EntityCategories
  subcategory: string | null
}

export enum ReceiptIncluded {
  INCLUDED = 'INCLUDED',
  NOT_INCLUDED = 'NOT_INCLUDED',
}

export interface DateRange {
  from: Date
  to: Date
}

export const DEFAULT_STATUS = Object.keys(DebitCardTransactionStatusV2) as
  | DebitCardTransactionStatusV2[]
  | null

export const DEFAULT_TRANSACTION_TYPES = Object.keys(
  FilterableTransactionType
) as FilterableTransactionType[] | null

export const DEFAULT_RECEIPT: ReceiptIncluded[] = [
  ReceiptIncluded.INCLUDED,
  ReceiptIncluded.NOT_INCLUDED,
]

interface AmountType {
  min: number
  max: number
}

export const DEFAULT_AMOUNT = null as AmountType | null

export const DEFAULT_DATE = null
export const DEFAULT_DESCRIPTION = ''
export const DEFAULT_TAGS: string[] = []
export const DEFAULT_CONTACT = null
export const DEFAULT_ACCOUNTING_CATEGORY = null
export const DEFAULT_CATEGORY_WITH_SUBCATEGORY = null

export const DEFAULT_FILTERS_VALUES = {
  date: DEFAULT_DATE as DateRange | null,
  status: DEFAULT_STATUS,
  transactionType: DEFAULT_TRANSACTION_TYPES,
  receipt: DEFAULT_RECEIPT,
  amount: DEFAULT_AMOUNT,
  description: DEFAULT_DESCRIPTION,
  tags: DEFAULT_TAGS,
  contact: DEFAULT_CONTACT as ContactListItem | null,
  accountingCategory: DEFAULT_ACCOUNTING_CATEGORY as EntityCategories | null,
  category: DEFAULT_CATEGORY_WITH_SUBCATEGORY as CategoryWithSubcategory | null,
}

export const DEFAULT_CONFIG: FilterConfig = {
  transactionType: {
    items: transactionTypeItems,
  },
}

interface UseAccountFiltersValuesProps {
  initialConfig?: Partial<FilterConfig>
}

export const useAccountFiltersValues = ({
  initialConfig,
}: UseAccountFiltersValuesProps = {}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const config = useMemo(() => ({ ...DEFAULT_CONFIG, ...initialConfig }), [])
  const defaultTransactionTypes =
    getTransactionTypeFromInitialConfig(initialConfig) ||
    DEFAULT_FILTERS_VALUES.transactionType

  const [date, setDate] = useState(DEFAULT_FILTERS_VALUES.date)
  const [status, setStatus] = useState(DEFAULT_FILTERS_VALUES.status)
  const [transactionType, setTransactionType] = useState(
    defaultTransactionTypes
  )
  const [receipt, setReceipt] = useState(DEFAULT_FILTERS_VALUES.receipt)
  const [amount, setAmount] = useState(DEFAULT_FILTERS_VALUES.amount)
  const [description, setDescription] = useState(
    DEFAULT_FILTERS_VALUES.description
  )

  const [tags, setTags] = useState(DEFAULT_FILTERS_VALUES.tags)
  const [contact, setContact] = useState(DEFAULT_FILTERS_VALUES.contact)

  const [category, setCategory] = useState(DEFAULT_FILTERS_VALUES.category)
  const [accountingCategory, setAccountingCategory] = useState(
    DEFAULT_FILTERS_VALUES.accountingCategory
  )

  const [visibleFilterCategories, setVisibleFilterCategories] = useState<
    FilterType[]
  >([])

  const resetDate = () => setDate(DEFAULT_FILTERS_VALUES.date)
  const resetStatus = () => setStatus(DEFAULT_FILTERS_VALUES.status)
  const resetTransactionType = () => setTransactionType(defaultTransactionTypes)
  const resetReceipt = () => setReceipt(DEFAULT_FILTERS_VALUES.receipt)
  const resetAmount = () => setAmount(DEFAULT_FILTERS_VALUES.amount)
  const resetDescription = () =>
    setDescription(DEFAULT_FILTERS_VALUES.description)
  const resetTags = () => setTags(DEFAULT_FILTERS_VALUES.tags)
  const resetContact = () => setContact(DEFAULT_FILTERS_VALUES.contact)
  const resetCategory = () => setCategory(DEFAULT_FILTERS_VALUES.category)
  const resetAccountingCategory = () =>
    setAccountingCategory(DEFAULT_FILTERS_VALUES.accountingCategory)
  const resetVisibleFilterCategories = () => setVisibleFilterCategories([])

  const resetAllFilters = () => {
    resetStatus()
    resetTransactionType()
    resetReceipt()
    resetAmount()
    resetDescription()
    resetTags()
    resetContact()
    resetAccountingCategory()
    resetCategory()
    resetDate()
    resetVisibleFilterCategories()
  }

  const areFiltersInDefaultState = useMemo(
    () =>
      equals({
        ...DEFAULT_FILTERS_VALUES,
        transactionType: defaultTransactionTypes,
      })({
        date,
        status,
        transactionType,
        receipt,
        amount,
        description,
        tags,
        contact,
        accountingCategory,
        category,
      }),
    [
      date,
      status,
      transactionType,
      receipt,
      amount,
      description,
      tags,
      contact,
      accountingCategory,
      category,
      defaultTransactionTypes,
    ]
  )

  return {
    areFiltersInDefaultState,
    resetAllFilters,
    date,
    setDate,
    resetDate,
    status,
    setStatus,
    resetStatus,
    transactionType,
    setTransactionType,
    resetTransactionType,
    receipt,
    setReceipt,
    resetReceipt,
    amount,
    setAmount,
    resetAmount,
    description,
    setDescription,
    resetDescription,
    tags,
    setTags,
    resetTags,
    contact,
    setContact,
    resetContact,
    category,
    setCategory,
    resetCategory,
    accountingCategory,
    setAccountingCategory,
    resetAccountingCategory,
    visibleFilterCategories,
    setVisibleFilterCategories,
    config,
  }
}

export type AccountFiltersValues = ReturnType<typeof useAccountFiltersValues>
