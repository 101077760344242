import { useMemo } from 'react'
import { Step } from 'react-joyride'
import { useTranslations } from '@npco/utils-translations'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { corporateCardSettingsWalkthroughTranslations } from '../../CorporateCardSettingsWalkthrough.i18n'

export const useGetCorporateCardSettingsWalkthroughSteps = (): Step[] => {
  const t = useTranslations(corporateCardSettingsWalkthroughTranslations)

  const isMobile = useIsMobileResolution()

  return useMemo(
    () => [
      {
        content: t('editOutstandingExpensesContent'),
        disableBeacon: true,
        placement: 'bottom-end',
        target: isMobile
          ? '.card-settings-button-mobile'
          : '.card-settings-button',
        title: t('editOutstandingExpensesTitle'),
      },
    ],
    [t, isMobile]
  )
}
