import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  threeDSStatus: '3DS Status',
  threeDSStatusTooltip:
    '3D Secure (3DS) is a security measure for online payments. Visit our Zeller Support Centre for more information.',
  passed: 'Passed',
  failed: 'Failed',
  refund: 'Refund',
  posLiteDetails: 'POS Lite Details',
  viewTransaction: 'View transaction',
})
