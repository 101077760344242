import { useCallback } from 'react'

import { StatementPeriod } from 'types/dates'
import { useFetchSimStatements } from 'pages/Settings/Sim/SimStatements/hooks/useFetchSimStatements'
import { StatementsBaseButton } from 'components/Statements/StatementsButtons/StatementsBaseButton/StatementsBaseButton'

interface Props {
  statementPeriod: StatementPeriod
}

export const SimActionButton = ({ statementPeriod }: Props) => {
  const { exportFile, isLoadingStatement, hasLoaded } = useFetchSimStatements()

  const generateFile = useCallback(() => {
    exportFile(statementPeriod)
  }, [statementPeriod, exportFile])

  return (
    <StatementsBaseButton
      isLoadingData={isLoadingStatement}
      hasLoaded={hasLoaded}
      generateFile={generateFile}
    />
  )
}
