import { useCallback } from 'react'
import { AnnouncementModal, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ConnectionSuccessfulIllustration } from 'assets/svg/connection-successful.svg'
import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import {
  ReportLostAndReplaceCardErrorState,
  ReportLostAndReplaceCardStage,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { StyledDescription } from '../../GlobalModals.styled'
import { useReportLostAndReplaceCard } from '../hooks/useReportLostAndReplaceCard'
import { useReportLostAndReplaceCardState } from '../hooks/useReportLostAndReplaceCardState'
import {
  getReportLostAndReplaceCardErrorPromptButtons,
  getReportLostAndReplaceCardErrorPromptDescription,
  getReportLostAndReplaceCardErrorPromptHeading,
  getReportLostAndReplaceCardErrorPromptSubDescription,
} from './ReportLostAndReplaceCardErrorPrompt.utils'

export const ReportLostAndReplaceCardErrorPrompt = () => {
  const isMobile = useIsMobileResolution()
  const { state } =
    useReportLostAndReplaceCardState<ReportLostAndReplaceCardErrorState>()
  const { goToAddCardStage } = useGoToAddCardStage()

  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()

  const closeReportLostAndReplaceCardModal = useCallback(
    () => goToReportLostAndReplaceCardStage(undefined),
    [goToReportLostAndReplaceCardStage]
  )

  const openAddCardModal = useCallback(() => {
    goToAddCardStage({
      stage: AddCardStage.LandingPageStage,
    })
  }, [goToAddCardStage])

  const { reportLostAndReplacePhysicalCard, reportLostAndReplaceVirtualCard } =
    useReportLostAndReplaceCard({
      velocityControl: state?.velocityControl ?? null,
      entityAddressTimezone: state?.entityAddressTimezone,
    })

  if (state === null) {
    return null
  }

  const buttonActions = getReportLostAndReplaceCardErrorPromptButtons({
    state,
    closeAction: closeReportLostAndReplaceCardModal,
    addCardAction: openAddCardModal,
    retryAction: () => {
      if (
        state.stage ===
        ReportLostAndReplaceCardStage.ReportLostPhysicalCardError
      ) {
        reportLostAndReplacePhysicalCard({
          cardId: state.cardId,
          cardColor: state.cardColor,
          address: state.address,
          isAdmin: state.isAdmin,
          isRetry: true,
        })
      }

      if (
        state.stage === ReportLostAndReplaceCardStage.ReportLostVirtualCardError
      ) {
        reportLostAndReplaceVirtualCard({
          cardId: state.cardId,
          cardColor: state.cardColor,
          isAdmin: state.isAdmin,
          isCardOwner: state.isCardOwner,
          cardOwnerFirstName: state.cardOwnerFirstName,
          isRetry: true,
        })
      }
    },
  })

  return (
    <AnnouncementModal
      isOpen={
        state.stage ===
          ReportLostAndReplaceCardStage.ReportLostPhysicalCardError ||
        state.stage ===
          ReportLostAndReplaceCardStage.ReportLostVirtualCardError ||
        state.stage === ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin ||
        state.stage === ReportLostAndReplaceCardStage.ReplaceCardErrorManager
      }
      heading={getReportLostAndReplaceCardErrorPromptHeading(state)}
      description={
        <>
          <StyledDescription mb={16}>
            {getReportLostAndReplaceCardErrorPromptDescription(state)}
          </StyledDescription>
          <StyledDescription>
            {getReportLostAndReplaceCardErrorPromptSubDescription(state)}
          </StyledDescription>
        </>
      }
      Illustration={
        <SvgIcon
          width={isMobile ? '140' : '180'}
          height={isMobile ? '140' : '180'}
        >
          <ConnectionSuccessfulIllustration />
        </SvgIcon>
      }
      {...buttonActions}
      onCancel={closeReportLostAndReplaceCardModal}
    />
  )
}
