import { GetSite_getSite_screensaver_logos as Logo } from 'types/gql-types/GetSite'

import { TerminalModel } from '../TerminalPreview/TerminalPreview.types'

export type LogosUploadInfo = {
  logoUuid: string
  formData: FormData
  url: string
}

export const SCREEN_SAVER_FORM_FIELDS = {
  logos: 'logos',
  customColours: 'customColours',
  selectedColour: 'selectedColour',
  selectedLogo: 'selectedLogo',
  selectedLogoUrl: 'selectedLogoUrl',
  logosToUpload: 'logosToUpload',
  selectedTerminalModel: 'selectedTerminalModel',
  selectedTerminalUrl: 'selectedTerminalUrl',
} as const

export type SiteAppearanceFormValues = {
  [SCREEN_SAVER_FORM_FIELDS.logos]: Logo[]
  [SCREEN_SAVER_FORM_FIELDS.customColours]: string[]
  [SCREEN_SAVER_FORM_FIELDS.selectedColour]: string
  [SCREEN_SAVER_FORM_FIELDS.selectedLogo]: string
  [SCREEN_SAVER_FORM_FIELDS.selectedLogoUrl]: string
  [SCREEN_SAVER_FORM_FIELDS.logosToUpload]: LogosUploadInfo[]
  [SCREEN_SAVER_FORM_FIELDS.selectedTerminalModel]: TerminalModel
  [SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl]: string
}
