import { useMFAState } from '../MFAContext/hooks/useMFAState/useMFAState'
import { RedirectToMFAModal } from './RedirectToMFAModal/RedirectToMFAModal'

export const RedirectToMFA = () => {
  const { isRedirecting } = useMFAState()

  if (!isRedirecting) {
    return null
  }

  return <RedirectToMFAModal />
}
