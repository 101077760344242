import { COLOR } from '@npco/zeller-design-system'
import { isNil } from 'lodash-es'
import {
  Area as RechartsArea,
  AreaChart as RechartsAreaChart,
  AreaProps,
  CartesianGrid,
  ReferenceLine,
  ReferenceLineProps,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts'
import { Margin } from 'recharts/types/util/types'

import { ChartData } from '../Charts.types'
import { useChartLoadingAnimationTracking } from '../hooks/useChartLoadingAnimationTracking'
import { useChartPoints } from '../hooks/useChartPoints'
import { useTooltipOnMouseEvent } from '../hooks/useTooltipOnMouseEvent'
import { CustomYAxisChartTick } from '../Shared/CustomYAxisChartTick/CustomYAxisChartTick'
import { RenderContent, Tooltip } from '../Shared/Tooltip/Tooltip'

interface AreaChartProps {
  areaProps?: AreaProps
  children?: React.ReactNode
  data: ChartData
  disabled?: boolean
  height: number | string
  isLoading?: boolean
  margin?: Margin
  referenceLineProps?: ReferenceLineProps
  tooltipContent: RenderContent
  width: number | string
  xAxisProps?: XAxisProps
  xAxisTickFormatter?: (value: number) => string
  yAxisProps?: YAxisProps
  yAxisTickFormatter?: (value: number) => string
}
export const AreaChart = ({
  areaProps,
  children,
  data,
  disabled = false,
  height,
  isLoading = false,
  margin,
  referenceLineProps,
  tooltipContent,
  width,
  xAxisProps,
  xAxisTickFormatter,
  yAxisProps,
  yAxisTickFormatter,
}: AreaChartProps) => {
  const { onChartRef, points } = useChartPoints()
  const { onChartMouseEvent, activeItem } = useTooltipOnMouseEvent(points)
  const { shouldFillChange, onAnimationStart, onAnimationEnd } =
    useChartLoadingAnimationTracking({
      initialShouldFillChange: !isLoading,
      fillChangeDelay: 500,
    })

  const tooltipPositionY = activeItem ? activeItem.y + 12 : undefined

  const disableTooltip = isLoading || disabled
  const isPointNotActive = isNil(activeItem?.payload.value)

  return (
    <div data-testid={isLoading ? 'area-chart-loading' : 'area-chart'}>
      <ResponsiveContainer width={width} height={height}>
        <RechartsAreaChart
          data={data}
          margin={margin}
          onMouseMove={!disableTooltip ? onChartMouseEvent : undefined}
          onMouseLeave={!disableTooltip ? onChartMouseEvent : undefined}
        >
          <defs>
            <linearGradient id="colorArea" x1="0" y1="0" x2="0" y2="1">
              <stop offset="19.2%" stopColor={COLOR.BLUE_120} stopOpacity={1} />
              <stop offset="100%" stopColor={COLOR.BLUE_50} stopOpacity={0.5} />
            </linearGradient>
            <linearGradient id="colorAreaLoading" x1="0" y1="0" x2="0" y2="1">
              <stop offset="19.2%" stopColor={COLOR.GREY_30} stopOpacity={1} />
              <stop offset="100%" stopColor={COLOR.GREY_20} stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} stroke={COLOR.GREY_60} />
          <XAxis
            axisLine={false}
            dataKey="name"
            interval="preserveStartEnd"
            tick={{
              fill: COLOR.BLACK_900,
              fontSize: 14,
            }}
            tickFormatter={xAxisTickFormatter}
            tickLine={false}
            tickSize={10}
            {...xAxisProps}
          />
          <YAxis
            axisLine={false}
            dataKey="value"
            domain={[0, 'auto']}
            interval="preserveStartEnd"
            tick={CustomYAxisChartTick}
            tickFormatter={yAxisTickFormatter}
            tickLine={false}
            tickSize={12}
            {...yAxisProps}
          />
          <RechartsTooltip
            content={<Tooltip data={data} renderContent={tooltipContent} />}
            cursor={false}
            isAnimationActive={false}
            offset={12}
            position={{ y: tooltipPositionY }}
            wrapperStyle={{
              outline: 'none',
              display: disableTooltip || isPointNotActive ? 'none' : 'block',
            }}
          />

          <ReferenceLine
            segment={
              disableTooltip || isPointNotActive
                ? undefined
                : [
                    {
                      x: activeItem?.payload?.name,
                      y: 0,
                    },
                    {
                      x: activeItem?.payload?.name,
                      y: activeItem?.payload?.value,
                    },
                  ]
            }
            stroke={COLOR.BLUE_400}
            strokeDasharray="3 3"
            strokeWidth={2}
            {...referenceLineProps}
          />
          <RechartsArea
            activeDot={
              !disableTooltip && {
                stroke: COLOR.BLUE_1000,
                strokeWidth: 2,
                r: 4,
                fill: COLOR.WHITE,
              }
            }
            dataKey="value"
            dot={false}
            fill={
              shouldFillChange ? 'url(#colorArea)' : 'url(#colorAreaLoading)'
            }
            fillOpacity={1}
            onAnimationEnd={onAnimationEnd}
            onAnimationStart={onAnimationStart}
            stroke={shouldFillChange ? COLOR.BLUE_1000 : COLOR.GREY_150}
            strokeWidth={2}
            strokeLinejoin="round"
            type="linear"
            {...areaProps}
            ref={disableTooltip ? null : (onChartRef as any)}
          />
          {children}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </div>
  )
}
