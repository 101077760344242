import {
  BodyDefaultTypography,
  DataMediumTypography,
  Flex,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledWrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 0.5rem;
`

export const StyledDetailsLabel = styled.span`
  color: ${({ theme }) => theme.colors.BLACK};
  ${InterZellerMediumFont};
  ${BodyDefaultTypography}
`

export const StyledDetailsValue = styled.div`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.BLACK};
`
