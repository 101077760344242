import { MouseEventHandler } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { ProductLaunchModalCMS } from 'features/StartupModals/CMSProductLaunchModal/ProductLaunchModalCMS'
import { useStoryblok } from 'features/StoryblokContent/hooks/useStoryblok'
import { StoryblokPLMProps } from 'features/StoryblokContent/Storyblok.types'

import { useQueryParams } from 'hooks/useQueryParams'

export interface ProductLaunchModalContentProps {
  slug?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const ProductLaunchModalContent = ({
  slug,
  ...props
}: ProductLaunchModalContentProps) => {
  const params = useQueryParams()
  const contentKey = params.get('modal-name') ?? slug
  const slugPrefix =
    'dashboard-merchant-portal/dashboard-product-launch-modals/'
  const slugComplete = slugPrefix + contentKey

  // This should be uplifted with backend flag fetching for production
  const story = useStoryblok(slugComplete, {
    version: params.get('modal-name') ? 'draft' : 'published', // draft for preview, published for prod
    resolve_links: 'url',
    cv: 1,
  })

  if (!story?.content) {
    return null
  }

  const storyContent = story.content

  return (
    <main {...storyblokEditable(storyContent)}>
      <ProductLaunchModalCMS
        productLaunchModalContent={storyContent as StoryblokPLMProps}
        slug={slug}
        {...props}
      />
    </main>
  )
}
