import {
  AnchorBasic,
  BodyDefaultTypography,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Label = styled.span`
  ${BodyDefaultTypography}
  ${InterZellerMediumFont}
  color: ${({ theme }) => theme.colors.BLACK_900};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`

export const Anchor = styled(AnchorBasic)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: underline;

  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.BLACK_900};
  }
`
