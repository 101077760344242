import { DataMedium, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const ContactWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 -0.5rem;
  padding: 0 0.5rem;

  @media (min-width: ${BREAKPOINT.XS}px) {
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
  }

  @media (min-width: ${BREAKPOINT.MD}px) {
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
  }

  @media (min-width: ${BREAKPOINT.LG}px) {
    margin: 0 -2.75rem;
    padding: 0 2.75rem;
  }

  @media (min-width: ${BREAKPOINT.XL}px) {
    margin: 0 -4.25rem;
    padding: 0 4.25rem;
  }
`

export const ContactDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.625rem;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    max-width: 31.5rem;
  }
`

export const Section = styled.div`
  margin-top: 2.5rem;
`

export const ContactDetailSection = styled(Section)`
  margin-top: 0;
`

export const SectionHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
`

export const Header = styled(H3)`
  margin: 0;
`
export const ActionWrapper = styled.div`
  margin-left: auto;
`
export const NoDataAddedContent = styled(DataMedium)`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`
