import { useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import { validateEmail } from '@npco/component-mp-common'
import { SaveAndSendInvoiceInput } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  DecisionModal,
  Flex,
  InputAdaptiveField,
  showErrorToast,
  showSuccessToast,
  SvgIcon,
} from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { validateMobileNumber } from 'utils/formValidation'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { translationsShared } from 'translations'

import { useInvoiceAnalytics } from '../../../../../../hooks/useInvoiceAnalytics'
import { useSaveAndSendInvoice } from '../../../../../../hooks/useSaveAndSendInvoice'
import { useInvoiceDecisionModal } from '../../../hooks/useInvoiceDecisionModal'
import { translations } from './ResendFailedSendInvoice.i18n'
import * as styled from './ResendFailedSendInvoice.styled'

const INITIAL_VALUES = { email: '', phone: '' }

type ResendFailedSendInvoice = {
  payerEmailToUpdate?: string
  payerMobileToUpdate?: string
}

export const ResendFailedSendInvoice = ({
  payerEmailToUpdate,
  payerMobileToUpdate,
}: ResendFailedSendInvoice) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { closeModal, details } = useInvoiceDecisionModal()

  const { customer, email, sms, id, referenceNumber } = details

  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const { saveAndSendInvoice, isSavingAndSendingInvoice } =
    useSaveAndSendInvoice()

  const handleSaveAndSendInvoice = async (values: typeof INITIAL_VALUES) => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_SEND_INVOICE)

    const saveAndSendInvoiceInput: SaveAndSendInvoiceInput = {
      referenceNumber,
    }

    if (values.email) {
      saveAndSendInvoiceInput.customer = {
        attentionContactStatus: customer?.attentionContact?.status,
        attentionContactUuid: customer?.attentionContact?.contactUuid,
        payerContactStatus: customer?.payerContact?.status,
        payerContactUuid: customer?.payerContact?.contactUuid,
        payerEmail: values.email,
      }
      saveAndSendInvoiceInput.email = {
        ...email,
        recipients: {
          ...email?.recipients,
          recipient: values.email,
        },
      }
    }
    if (values.phone) {
      saveAndSendInvoiceInput.sms = {
        ...sms,
        payerContactPhoneNumber: values.phone,
      }
    }

    try {
      const response = await saveAndSendInvoice(saveAndSendInvoiceInput)

      if (!response.data?.saveAndSendInvoice) {
        showErrorToast(t('error', { referenceNumber }))
        return
      }

      showSuccessToast(t('success', { referenceNumber }))
    } catch (err) {
      if (err instanceof ApolloError) {
        const hasInvalidEmail = /Invalid email/.test(err.message)

        if (hasInvalidEmail) {
          showErrorToast(t('errorInvalidEmail', { referenceNumber }))
          return
        }
      }

      showErrorToast(t('error', { referenceNumber }))
    } finally {
      closeModal()
    }
  }

  const validateNewEmail =
    (originalEmail?: string | null) => (email?: string) => {
      const isValidEmailError = validateEmail(email)

      const isNotNewEmailError =
        originalEmail === email ? t('isNotNewEmailError') : undefined

      return isValidEmailError || isNotNewEmailError
    }

  const validateNewMobile =
    (originalMobileNumber?: string | null) => (mobileNumber?: string) => {
      const isValidMobileNumberError = validateMobileNumber(mobileNumber)

      const isNotNewMobileError =
        originalMobileNumber === mobileNumber
          ? t('isNotNewMobileNumberError')
          : undefined

      return isValidMobileNumberError || isNotNewMobileError
    }

  const [title, message] = useMemo(() => {
    if (payerEmailToUpdate && payerMobileToUpdate) {
      return [t('resendInvoice'), t('warningEmailAndSMS')]
    }

    return payerEmailToUpdate
      ? [t('resendEmail'), t('warningEmail')]
      : [t('resendSMS'), t('warningSMS')]
  }, [payerEmailToUpdate, payerMobileToUpdate, t])

  return (
    <div data-testid="resend-invoice-modal">
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSaveAndSendInvoice}
      >
        {({ handleSubmit }) => (
          <DecisionModal
            isLoadingPrimary={isSavingAndSendingInvoice}
            isOpen
            onCancel={closeModal}
            onClickPrimary={handleSubmit}
            onClickSecondary={closeModal}
            primaryButtonLabel={tShared('send')}
            secondaryButtonLabel={tShared('cancel')}
            title={title}
          >
            <styled.Warning>{message}</styled.Warning>
            <Flex gridGap="40px" flexDirection="column">
              {payerEmailToUpdate && (
                <div>
                  <Flex gridGap="8px" mb="24px">
                    <SvgIcon color={COLOR.RED_1000} height="16" width="16">
                      <CloseIcon />
                    </SvgIcon>
                    <styled.ExistingEmail>
                      {payerEmailToUpdate}
                    </styled.ExistingEmail>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <InputAdaptiveField
                      name="email"
                      placeholder={t('emailPlaceholder')}
                      validate={validateNewEmail(payerEmailToUpdate)}
                    />
                  </Flex>
                </div>
              )}
              {payerMobileToUpdate && (
                <div>
                  <Flex gridGap="8px" mb="24px">
                    <SvgIcon color={COLOR.RED_1000} height="16" width="16">
                      <CloseIcon />
                    </SvgIcon>
                    <styled.ExistingEmail>
                      {payerMobileToUpdate}
                    </styled.ExistingEmail>
                  </Flex>
                  <Flex flexDirection="column" width="100%">
                    <InputAdaptiveField
                      name="phone"
                      placeholder={t('mobileNumberPlaceholder')}
                      validate={validateNewMobile(payerMobileToUpdate)}
                    />
                  </Flex>
                </div>
              )}
            </Flex>
          </DecisionModal>
        )}
      </Formik>
    </div>
  )
}
