import { translate } from 'utils/translations'

export enum MergeTag {
  BusinessName = 'businessName',
  Customer = 'customer',
  InvoiceNumber = 'invoiceNumber',
  PersonName = 'personName',
  Schedule = 'schedule',
  TotalPrice = 'totalPrice',
  ViewAndPay = 'viewAndPayLink',
}

export type EntityTagItem = {
  apiString: string
  key: string
  regex: RegExp
  text: string
  tooltip: string
  hasValue?: boolean
}

export const ENTITY_NAME_REGEX = /{{entityName}}/g

export const availableEntityTagsMap: Record<MergeTag, EntityTagItem> = {
  invoiceNumber: {
    apiString: '{{referenceNumber}}',
    hasValue: false,
    key: 'INVOICE_NO',
    regex: /{{referenceNumber}}/g,
    text: translate(
      'page.invoice.formSections.email.mergeTagInvoiceNumberValue'
    ),
    tooltip: translate(
      'page.invoice.formSections.email.mergeTagInvoiceNumberTooltip'
    ),
  },
  schedule: {
    apiString: '{{dueDate}}',
    hasValue: false,
    key: 'SCHEDULE',
    regex: /{{dueDate}}/g,
    text: translate('page.invoice.formSections.email.mergeTagDueDateLabel'),
    tooltip: translate(
      'page.invoice.formSections.email.mergeTagUnavailableTooltip'
    ),
  },
  totalPrice: {
    apiString: '{{totalPrice}}',
    hasValue: false,
    key: 'TOTAL_PRICE',
    regex: /{{totalPrice}}/g,
    text: translate('page.invoice.formSections.email.mergeTagAmountLabel'),
    tooltip: translate(
      'page.invoice.formSections.email.mergeTagUnavailableTooltip'
    ),
  },
  viewAndPayLink: {
    apiString: '{{paymentLink}}',
    key: 'VIEW_ANY_PAY',
    regex: /{{paymentLink}}/g,
    text: translate('page.invoice.formSections.email.mergeTagPaymentLinkLabel'),
    tooltip: '',
  },
  businessName: {
    apiString: '{{businessName}}',
    hasValue: false,
    key: 'BUSINESS_NAME',
    regex: /{{businessName}}/g,
    text: translate(
      'page.invoice.formSections.email.mergeTagBusinessNameLabel'
    ),
    tooltip: translate(
      'page.invoice.formSections.email.mergeTagUnavailableTooltip'
    ),
  },
  personName: {
    apiString: '{{personName}}',
    hasValue: false,
    key: 'PERSON_NAME',
    regex: /{{personName}}/g,
    text: translate('page.invoice.formSections.email.mergeTagPersonNameLabel'),
    tooltip: translate(
      'page.invoice.formSections.email.mergeTagUnavailableTooltip'
    ),
  },
  customer: {
    apiString: '{{customer}}',
    hasValue: false,
    key: 'CUSTOMER',
    regex: /{{customer}}/g,
    text: translate('page.invoice.formSections.email.mergeTagCustomerLabel'),
    tooltip: translate(
      'page.invoice.formSections.email.mergeTagUnavailableTooltip'
    ),
  },
}
