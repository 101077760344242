import { ReactNode } from 'react'

import { ThirdPartyBankAccount } from 'types/accounts'
import { page } from 'translations'

import { AccountDetailBody } from './AccountDetailBody/AccountDetailBody'
import { StyledAccountDetailsDrawer } from './AccountDetailDrawer.styled'

interface AccountDetailDrawerProps {
  account: ThirdPartyBankAccount
  onClose: () => void
  renderBottomAction: (account: ThirdPartyBankAccount) => ReactNode
  renderHeaderAction: (account: ThirdPartyBankAccount) => ReactNode
  getIcon: (bankAccount: ThirdPartyBankAccount) => ReactNode
}

export const ThirdPartyAccountDetailDrawer = ({
  onClose,
  account,
  renderBottomAction,
  getIcon,
  renderHeaderAction,
}: AccountDetailDrawerProps) => {
  const accountBsbLabel = page.settings.depositAccountDetail.bsb
  const accountNumberLabel = page.settings.depositAccountDetail.accountNumber

  const accountProperties = [
    { label: accountBsbLabel, value: account.accountBsb },
    { label: accountNumberLabel, value: account.accountNumber },
  ]

  return (
    <StyledAccountDetailsDrawer
      isOpen
      drawerTitle={account.accountName || ''}
      onClose={onClose}
      ariaHideApp={false}
      icon={getIcon(account)}
      renderRightHeader={() => renderHeaderAction(account)}
      overlayClassName="animated-drawer-overlay"
    >
      <AccountDetailBody<ThirdPartyBankAccount>
        renderBottomAction={renderBottomAction}
        accountProperties={accountProperties}
        account={account}
      />
    </StyledAccountDetailsDrawer>
  )
}
