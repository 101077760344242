import { gql } from '@apollo/client'
import { Box, Flex, PageTemplate } from '@npco/zeller-design-system'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'

import { useHeaderButtonsVisibility } from '../context/hooks/useHeaderButtonsVisibility/useHeaderButtonsVisibility'
import { CardsTopPanelEntityFragment } from './CardsTopPanel.generated'
import { ActivateCardButton } from './components/ActivateCardButton/ActivateCardButton'
import { AddCardButton } from './components/AddCardButton/AddCardButton'
import { CorporateSettingButton } from './components/CorporateSettingButton/CorporateSettingButton'

interface CardsTopPanelProps {
  isLoadingEntity: boolean
  entity?: CardsTopPanelEntityFragment
}

export const CardsTopPanel = ({
  isLoadingEntity,
  entity,
}: CardsTopPanelProps) => {
  const { isHeaderButtonsVisible } = useHeaderButtonsVisibility()
  const {
    settings: isSettingsButtonVisible,
    addCard: isAddCardButtonVisible,
    activateCard: isActivateCardButtonVisible,
  } = isHeaderButtonsVisible

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="48px"
      >
        <Box maxWidth="300px">
          <Breadcrumbs />
        </Box>

        <Flex flexGrow={1} justifyContent="end" gap="8px">
          {isSettingsButtonVisible && <CorporateSettingButton />}
          {isAddCardButtonVisible && !isLoadingEntity && (
            <AddCardButton entity={entity} />
          )}
          {isActivateCardButtonVisible && <ActivateCardButton />}
        </Flex>
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}

CardsTopPanel.fragments = {
  Entity: gql`
    fragment CardsTopPanelEntityFragment on Entity {
      ...AddCardButtonEntityFragment
    }

    ${AddCardButton.fragments.Entity}
  `,
}
