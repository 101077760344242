import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  GetSetupFlowDebitCardAccounts,
  GetSetupFlowDebitCardAccountsQueryResponse,
  GetSetupFlowDebitCardAccountsQueryVariables,
} from './graphql/getAccounts.generated'

export const useSetupFlowAccountsQuery = () => {
  const entityUuid = useSelectedEntityUuid()

  const {
    loading: isLoadingAccounts,
    data,
    refetch,
    networkStatus,
  } = useQuery<
    GetSetupFlowDebitCardAccountsQueryResponse,
    GetSetupFlowDebitCardAccountsQueryVariables
  >(GetSetupFlowDebitCardAccounts, {
    notifyOnNetworkStatusChange: true,
    variables: { entityUuid },
  })

  const accounts = useMemo(() => {
    return data?.getDebitCardAccountsV2.accounts
  }, [data?.getDebitCardAccountsV2?.accounts])

  return {
    isLoadingAccounts:
      isLoadingAccounts || networkStatus === NetworkStatus.refetch,
    accounts,
    refetchAccounts: refetch,
  }
}
