import { useCallback, useEffect, useMemo, useState } from 'react'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { useTransactionsWithUpdates } from 'hooks/useTransactionsList/useTransactionsWithUpdates'
import { filterNulls } from 'utils/common'
import { TransactionInList } from 'types/transactions'
import { useTransactionUpdates } from 'services/transactionUpdates/hooks/useTransactionUpdates'
import { WithPeriod } from 'pages/Dashboard/Dashboard.types'
import {
  groupByPeriod,
  mapToTotals,
  siteUuidIn,
  sumTotalsByPeriod,
} from 'pages/Dashboard/utils/transactionUpdates.utils'

interface UseUpdateTotalsProps<T extends WithPeriod> {
  selectedSitesUuid?: string[]
  mapToTotal: (transaction: TransactionInList) => T | null
  sumTotals: ([period, totals]: [string, T[]]) => T
}

export const useUpdateTotals = <T extends WithPeriod>({
  selectedSitesUuid,
  mapToTotal,
  sumTotals,
}: UseUpdateTotalsProps<T>) => {
  const [isDone, setIsDone] = useState(false)
  const [updateTotals, setUpdateTotals] = useState<T[]>([])
  const { transactionUpdates$ } = useTransactionUpdates()

  const { setTransactions, transactions$, updateObserver } =
    useTransactionsWithUpdates()

  const buildTotalsFromTransactions = useCallback(
    (transactions: TransactionInList[]) => {
      return pipe(
        mapToTotals(mapToTotal),
        filterNulls,
        groupByPeriod,
        sumTotalsByPeriod(sumTotals)
      )(transactions)
    },
    [mapToTotal, sumTotals]
  )

  const updatedTotals$ = useMemo(
    () => transactions$.pipe(map(buildTotalsFromTransactions)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buildTotalsFromTransactions]
  )

  useEffect(() => {
    const subscription = transactionUpdates$
      .pipe(filter(siteUuidIn(selectedSitesUuid)))
      .subscribe(updateObserver)

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSitesUuid, updateObserver])

  const resetUpdateTotals = useCallback(() => {
    setTransactions([])
  }, [setTransactions])

  useEffect(() => {
    const subscription = updatedTotals$.subscribe((...args) => {
      if (!isDone) {
        setIsDone(true)
        setUpdateTotals(...args)
      }
    })

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { updateTotals, resetUpdateTotals, updatedTotals$ }
}
