import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { page } from 'translations'

import { StyledViewReportLink } from './ViewReportLink.styled'

export const ViewReportLink = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <StyledViewReportLink
      onClick={() =>
        navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.REPORTS.path)
      }
    >
      {page.dashboard.payments.sales.reportingButtonLabel}
    </StyledViewReportLink>
  )
}
