import { useTranslations } from '@npco/utils-translations'
import {
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
  InlineLoaderVariant,
  InlineSpinner,
} from '@npco/zeller-design-system'

import { timezoneMessageTranslations } from './TimezoneMessage.i18n'
import * as styled from './TimezoneMessage.styled'
import { getTimezoneName } from './TimezoneMessage.utils'

interface TimezoneMessageProps {
  timezone?: string
  isLoadingTimezone: boolean
}
export const TimezoneMessage = ({
  timezone,
  isLoadingTimezone,
}: TimezoneMessageProps) => {
  const t = useTranslations(timezoneMessageTranslations)

  if (isLoadingTimezone) {
    return (
      <styled.CenteredSpinnerWrapper>
        <InlineSpinner loaderVariant={InlineLoaderVariant.Grey} />
      </styled.CenteredSpinnerWrapper>
    )
  }

  return (
    <InfoBox priority={INFOBOX_PRIORITY.DEFAULT} size={INFOBOX_SIZE.DEFAULT}>
      <InfoBox.Message>
        {!timezone
          ? t('timezoneInfoFallback')
          : t('timezoneInfo', {
              timezone: getTimezoneName(timezone),
            })}
      </InfoBox.Message>
    </InfoBox>
  )
}
