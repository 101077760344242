import { useTranslations } from '@npco/utils-translations'
import { isEmpty } from 'lodash-es'

import { nicknameFieldTranslations } from './Nickname.i18n'

export const useNicknameValidate = () => {
  const t = useTranslations(nicknameFieldTranslations)

  return (value: string): string | undefined => {
    if (isEmpty(value)) {
      return t('errorRequired')
    }

    return undefined
  }
}
