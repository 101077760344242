import { useEffect, useMemo, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import {
  NEW_ITEM_VALUE,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { UseComboboxStateChange } from 'downshift'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { translate } from 'utils/translations'

import { InputSelectComboBoxItem } from '../../Contacts.types'
import {
  rvSelectedContact,
  rvSelectedSubContact,
} from '../../rv-deprecated/contacts'
import { useContacts } from '../useContacts/useContacts'

interface UseComboBoxSelectProps {
  contactType: ContactType
  debounceDuration?: number
  isOptional: boolean
  setIsAddingNew: (value: boolean, inputValue?: string | null) => void
  setSelectedItem: (item: InputSelectComboBoxItem | null) => void
  setValidationError: (validationError: string) => void
}

export const useComboBoxSelect = ({
  contactType,
  debounceDuration = 300,
  isOptional,
  setIsAddingNew,
  setSelectedItem,
  setValidationError,
}: UseComboBoxSelectProps) => {
  const [inputValue, setInputValue] = useState('')

  const name$ = useMemo(() => new Subject<string>(), [])
  const [filters, setFilters] = useState({ name: '', tags: [] })

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      name$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((nextName) =>
          setFilters((prev) => ({ ...prev, name: nextName }))
        )
    )

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { contacts, loading, loadMore, hasMore } = useContacts({
    contactType,
    filters,
    limit: 25,
  })

  const subContactIds = rvSelectedContact()?.contacts?.map((item) => item?.id)

  const filterItems = (item: InputSelectComboBoxItem) =>
    !subContactIds?.includes(item.id)

  const onChange = (
    changes: SelectStateChangeProps<InputSelectComboBoxItem>
  ) => {
    const { inputValue: value, selectedItem } = changes

    if (selectedItem?.value === NEW_ITEM_VALUE) {
      setIsAddingNew(true, value)
      return
    }

    rvSelectedSubContact(
      contacts.find((element) => element?.id === selectedItem?.id) ?? null
    )

    setSelectedItem(selectedItem)
  }

  const onClose = (
    changes: UseComboboxStateChange<InputSelectComboBoxItem>
  ) => {
    // NOTE: when a user has an item, changes the input value and navigates away
    // from the menu causing the menu to close reset the input value to that of
    // the selected item
    if (changes.selectedItem) {
      if (changes.selectedItem.value !== changes.inputValue) {
        setInputValue(changes.selectedItem.value)
      }
    }

    // NOTE: reset validation error item is selected and input value
    if (changes.selectedItem && changes.inputValue) {
      setValidationError('')
    }

    // NOTE: reset validation error if no item is selected, no input value and
    // is marked as an optional field
    if (!changes.selectedItem && !changes.inputValue && isOptional) {
      setValidationError('')
    }

    // NOTE: if no selected item and input value validate as selection required
    if (!changes.selectedItem && changes.inputValue) {
      setValidationError(
        translate('page.contacts.form.subContactSelectionValidation')
      )
    }

    // NOTE: if no selected item and no input value validate as required
    if (!changes.selectedItem && !changes.inputValue && !isOptional) {
      setValidationError(
        translate(
          contactType === ContactType.BUSINESS
            ? 'page.contacts.form.subContactBusinessRequiredValidation'
            : 'page.contacts.form.subContactPersonRequiredValidation'
        )
      )
    }

    setIsAddingNew(false, null)
  }

  const onInputChange = (
    changes: SelectStateInputChangeProps<InputSelectComboBoxItem>
  ) => {
    const value = changes.inputValue

    if (value === '') {
      rvSelectedSubContact(null)
      setSelectedItem(null)
    }

    setInputValue(value)

    // NOTE: only fetch when no selected item or when current selected item
    // value does not match current input value
    if (!changes.selectedItem || changes.selectedItem.value !== value) {
      name$.next(value)
    }
  }

  const onInputClear = () => {
    rvSelectedSubContact(null)
    setInputValue('')
    setSelectedItem(null)

    name$.next('')
  }

  return {
    contacts,
    filterItems,
    hasMore,
    inputValue,
    loading,
    loadMore,
    onChange,
    onClose,
    onInputChange,
    onInputClear,
  }
}
