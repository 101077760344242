import { ShowMoreToggle } from './components/ShowMoreToggle'
import { TogglableContent } from './components/TogglableContent'
import { ShowMoreProvider } from './context/ShowMoreProvider'

interface ShowMoreProps {
  shouldShowInitially?: boolean
  children: React.ReactNode | React.ReactNode[]
}

export const ShowMore = ({ shouldShowInitially, children }: ShowMoreProps) => {
  return (
    <ShowMoreProvider initialValue={shouldShowInitially}>
      {children}
    </ShowMoreProvider>
  )
}

ShowMore.Toggle = ShowMoreToggle
ShowMore.TogglableContent = TogglableContent
