import { LocalStorageSource } from '@npco/utils-feature-flags-local-storage'

import { type FeatureFlags, FeatureFlagsSchema } from './FeatureFlagsSchema'

export const FeatureFlagsLocalSource = LocalStorageSource({
  storageKey: 'dev.FeatureFlags.values',
})

export const readFeatureFlagsLocalValues = ():
  | Partial<FeatureFlags>
  | undefined => {
  const valuesUnknown = FeatureFlagsLocalSource.readValues()
  const valuesResult = FeatureFlagsSchema.partial().safeParse(valuesUnknown)
  if (!valuesResult.success) {
    return undefined
  }
  const values = valuesResult.data
  return values
}

export const writeFeatureFlagsLocalValues = (
  values: Partial<FeatureFlags>
): void => {
  FeatureFlagsLocalSource.writeValues(values)
}

export const clearFeatureFlagsLocalValues = (): void => {
  FeatureFlagsLocalSource.clearValues()
}
