import { InvoiceStatus, RevisionStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { setIn, useFormikContext } from 'formik'

import {
  INVOICE_ATTENTION_TO_STATUS,
  INVOICE_PAYER_STATUS,
} from '../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../Invoice.types'
import { translations } from './InvoiceCustomerContactInfobox.i18n'

export const InvoiceCustomerContactInfobox = () => {
  const {
    values: {
      customer: { payerContact, attentionToContact },
      status: invoiceStatus,
    },
    setValues,
  } = useFormikContext<InvoiceFormFields>()
  const t = useTranslations(translations)

  const payerContactStatus = payerContact?.status
  const attentionToContactStatus = attentionToContact?.status

  const isAnyContactUpdated =
    payerContactStatus === RevisionStatus.UPDATED ||
    attentionToContactStatus === RevisionStatus.UPDATED

  const showContactUpdatedInfoBox =
    invoiceStatus === InvoiceStatus.DRAFT && isAnyContactUpdated

  const handleUpdatedContactDismiss = () => {
    setValues((values) => {
      let updated = { ...values }

      if (payerContactStatus === RevisionStatus.UPDATED) {
        updated = setIn(values, INVOICE_PAYER_STATUS, RevisionStatus.NO_CHANGE)
      }
      if (attentionToContactStatus === RevisionStatus.UPDATED) {
        updated = setIn(
          updated,
          INVOICE_ATTENTION_TO_STATUS,
          RevisionStatus.NO_CHANGE
        )
      }
      return updated
    })
  }

  return (
    <>
      {showContactUpdatedInfoBox && (
        <InfoBox
          priority={INFOBOX_PRIORITY.MEDIUM}
          variant={INFOBOX_VARIANT.NEGATIVE}
        >
          <Flex
            flexDirection={{ _: 'column', SM: 'row' }}
            gridGap="16px"
            justifyContent="space-between"
            width="100%"
          >
            <InfoBox.Message>{t('contactUpdated')}</InfoBox.Message>
            <InfoBox.ButtonGroup>
              <InfoBox.CallToAction onClick={handleUpdatedContactDismiss}>
                {t('dismiss')}
              </InfoBox.CallToAction>
            </InfoBox.ButtonGroup>
          </Flex>
        </InfoBox>
      )}
    </>
  )
}
