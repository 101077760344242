import { useCallback, useMemo, useState } from 'react'

import { ProgressBarContext, ProgressSettings } from './ProgressBar.context'

interface ProgressBarProviderProps {
  initialCurrentStep: number
  initialTotalSteps: number
  children: React.ReactNode
}

export const ProgressBarProvider = ({
  initialCurrentStep,
  initialTotalSteps,
  children,
}: ProgressBarProviderProps) => {
  const [currentStep, setCurrentStep] = useState(initialCurrentStep)
  const [totalSteps, setTotalSteps] = useState(initialTotalSteps)
  const [shouldShow, setShouldShow] = useState(true)

  const hideProgressBar = useCallback(() => {
    setShouldShow(false)
  }, [])

  const setProgress = useCallback(
    ({
      currentStep: newCurrentStep,
      totalSteps: newTotalSteps,
    }: ProgressSettings) => {
      if (newCurrentStep || newTotalSteps) {
        setShouldShow(true)
      }

      if (newCurrentStep) {
        setCurrentStep(newCurrentStep)
      }

      if (newTotalSteps) {
        setTotalSteps(newTotalSteps)
      }
    },
    []
  )

  const providerValue = useMemo(
    () => ({
      currentStep,
      totalSteps,
      setProgress,
      shouldShow,
      hideProgressBar,
    }),
    [currentStep, hideProgressBar, setProgress, shouldShow, totalSteps]
  )

  return (
    <ProgressBarContext.Provider value={providerValue}>
      {children}
    </ProgressBarContext.Provider>
  )
}
