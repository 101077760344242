import { translate } from 'utils/translations'

import {
  StyledBoldText,
  StyledContainer,
  StyledInnerContainer,
  StyledQuotesWrapper,
  StyledSearchWrapper,
  StyledText,
} from './NotFoundPlaceholder.styled'

interface Props {
  searchInput: string
}

export const NotFoundPlaceholder = ({ searchInput }: Props) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <StyledBoldText as="div">
          {translate(
            'page.accounts.transactionsList.filters.notFoundPlaceholder.line1',
            {
              searchInput: (
                <StyledQuotesWrapper>
                  &apos;
                  <StyledSearchWrapper>{searchInput}</StyledSearchWrapper>
                  &apos;
                </StyledQuotesWrapper>
              ),
            }
          )}
        </StyledBoldText>
        <StyledText>
          {translate(
            'page.accounts.transactionsList.filters.notFoundPlaceholder.line2'
          )}
        </StyledText>
      </StyledInnerContainer>
    </StyledContainer>
  )
}
