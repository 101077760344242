import { useMemo, useState } from 'react'

import {
  TransferContext,
  TransferStateType,
} from 'pages/Transfer/Transfer.context'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'

interface TransferProviderProps {
  children?: React.ReactNode
  transferState: TransferStateType
  transferValues?: TransferFieldTypes
}

export const TransferProvider = ({
  transferState,
  transferValues: initialTransferValues,
  children,
}: TransferProviderProps) => {
  const [transferValues, setTransferValues] = useState<TransferFieldTypes>(
    initialTransferValues ?? { from: null, to: null, reference: '', amount: '' }
  )

  const providerValue = useMemo(
    () => ({
      transferValues,
      transferState,
      updateTransferValues: setTransferValues,
    }),
    [transferState, transferValues]
  )

  return (
    <TransferContext.Provider value={providerValue}>
      {children}
    </TransferContext.Provider>
  )
}
