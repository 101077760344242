import { useMemo, useRef } from 'react'

import { DrawerNavigationContext } from './DrawerNavigation.context'
import { useKeyboardNavigation } from './hooks/useKeyboardNavigation/useKeyboardNavigation'

interface DrawerNavigationProviderProps {
  onPrevious: (() => void) | undefined
  onNext: (() => void) | undefined
  children?: React.ReactNode
}

export const DrawerNavigationProvider = ({
  onPrevious,
  onNext,
  children,
}: DrawerNavigationProviderProps) => {
  const drawerContentContainerRef = useRef<HTMLDivElement>(null)
  const drawerRef = useRef<HTMLButtonElement>(null)

  const {
    changeMode,
    innerSelectPrevious,
    innerSelectNext,
    isNavigationDisabled,
    setIsNavigationDisabled,
  } = useKeyboardNavigation({
    drawerContentContainerRef,
    drawerRef,
    onPrevious,
    onNext,
  })

  const providerValue = useMemo(
    () => ({
      changeMode,
      innerSelectPrevious,
      innerSelectNext,
      drawerRef,
      drawerContentContainerRef,
      onPrevious,
      onNext,
      isNavigationDisabled,
      setIsNavigationDisabled,
    }),
    [
      changeMode,
      innerSelectNext,
      innerSelectPrevious,
      isNavigationDisabled,
      onNext,
      onPrevious,
      setIsNavigationDisabled,
    ]
  )

  return (
    <DrawerNavigationContext.Provider value={providerValue}>
      {children}
    </DrawerNavigationContext.Provider>
  )
}
