import { RefObject, useState } from 'react'
import {
  InputSelectComboboxBasic,
  InputSelectComboboxInputRenderProps,
  InputSelectComboboxItemRenderProps,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { ModalWithButtons } from 'design-system/Components/Modal'

import { LoaderSimple } from 'components/LoaderSimple'
import { component, shared } from 'translations'

import { LabelSelectComboboxEditInput } from './components/LabelSelectComboboxEditInput/LabelSelectComboboxEditInput'
import { LabelSelectComboboxFooter } from './components/LabelSelectComboboxFooter/LabelSelectComboboxFooter'
import { LabelSelectComboboxInput } from './components/LabelSelectComboboxInput/LabelSelectComboboxInput'
import { LabelSelectComboboxItem } from './components/LabelSelectComboboxItem/LabelSelectComboboxItem'
import { LabelSelectComboboxNewItem } from './components/LabelSelectComboboxNewItem/LabelSelectComboboxNewItem'
import { LabelSelectNoItemsPlaceholder } from './components/LabelSelectComboboxNoItemsPlaceholder/LabelSelectComboboxNoItemsPlaceholder'
import { StyledLabelButton } from './LabelSelectCombobox.styled'
import { LabelSelectComboboxItem as LabelSelectComboboxItemType } from './LabelSelectCombobox.types'
import {
  getAddNewItemContent,
  getSortedItems,
} from './LabelSelectCombobox.utils'

export interface LabelSelectComboboxProps<ItemType> {
  isLoading: boolean
  items: ItemType[]
  onAddItem: (label: string, onComplete: () => void) => void
  onDeleteItem: (item: ItemType, onComplete: () => void) => void
  onEditItem: (nextItem: ItemType, onComplete: () => void) => void
  popperWrapperRef?: RefObject<HTMLDivElement> | null
  selectedItem: ItemType | null
  setSelectedItem: (item: ItemType | null) => void
}

export const LabelSelectCombobox = <
  ItemType extends LabelSelectComboboxItemType
>({
  isLoading,
  items,
  onAddItem,
  onDeleteItem,
  onEditItem,
  popperWrapperRef,
  selectedItem,
  setSelectedItem,
}: LabelSelectComboboxProps<ItemType>) => {
  const [inputValue, setInputValue] = useState<string>('')

  const [isSelectingItem, setIsSelectingItem] = useState<boolean>(false)
  const [selectedEditItem, setSelectedEditItem] = useState<ItemType | null>(
    null
  )
  const [selectedDeleteItem, setSelectedDeleteItem] = useState<ItemType | null>(
    null
  )
  const [itemHoverKey, setItemHoverKey] = useState<string | null>(null)

  const addNewItemContent = getAddNewItemContent<ItemType>({
    inputValue,
    items,
  })

  const sortedItems = getSortedItems({ items })

  const filterItems = (item: ItemType) =>
    item.label.toUpperCase().includes(String(inputValue?.trim().toUpperCase()))

  const handleOnDeleteConfirm = () => {
    if (selectedDeleteItem) {
      onDeleteItem(selectedDeleteItem, () => {
        setInputValue('')
        setSelectedDeleteItem(null)
      })
    }
  }

  const onChange = (changes: SelectStateChangeProps<ItemType>) => {
    setSelectedItem(changes.selectedItem)
    setIsSelectingItem(false)
  }

  const onInputChange = (changes: SelectStateInputChangeProps<ItemType>) => {
    if (changes.inputValue === '') {
      setSelectedItem(null)
    }

    setInputValue(changes.inputValue)
  }

  const renderFooter = () => (
    <LabelSelectComboboxFooter<ItemType>
      inputValue={inputValue}
      setInputValue={setInputValue}
      setSelectedItem={setSelectedItem}
    />
  )

  const renderInput = (renderProps: InputSelectComboboxInputRenderProps) => (
    <LabelSelectComboboxInput renderProps={renderProps} />
  )

  const renderItem = (
    renderProps: InputSelectComboboxItemRenderProps<ItemType>
  ) => (
    <LabelSelectComboboxItem<ItemType>
      itemHoverKey={itemHoverKey}
      key={renderProps.item.id}
      renderProps={renderProps}
      setIsSelectingItem={setIsSelectingItem}
      setItemHoverKey={setItemHoverKey}
      setSelectedDeleteItem={setSelectedDeleteItem}
      setSelectedEditItem={setSelectedEditItem}
    />
  )

  const renderNewItem = addNewItemContent
    ? (renderProps: InputSelectComboboxItemRenderProps<ItemType>) => (
        <LabelSelectComboboxNewItem
          addNewItemContent={addNewItemContent}
          inputValue={inputValue}
          key={renderProps.key}
          onAddItem={onAddItem}
          setIsSelectingItem={setIsSelectingItem}
          renderProps={renderProps}
        />
      )
    : undefined

  const renderNoItemsPlaceholder = () => (
    <LabelSelectNoItemsPlaceholder hasItems={Boolean(addNewItemContent)} />
  )

  if (isLoading) {
    return <LoaderSimple />
  }

  if (selectedEditItem) {
    return (
      <LabelSelectComboboxEditInput
        onEditItem={onEditItem}
        setInputValue={setInputValue}
        selectedEditItem={selectedEditItem}
        setSelectedEditItem={setSelectedEditItem}
      />
    )
  }

  if (!isSelectingItem || selectedDeleteItem) {
    return (
      <>
        <StyledLabelButton onClick={() => setIsSelectingItem(true)}>
          {selectedItem?.label || component.labelSelectCombobox.addLabel}
        </StyledLabelButton>
        {selectedDeleteItem && (
          <ModalWithButtons
            hasCancelButton
            isOpen
            onCancel={() => setSelectedDeleteItem(null)}
            onConfirm={handleOnDeleteConfirm}
            primaryButtonLabel={shared.delete}
            secondaryButtonLabel={shared.cancel}
            title={component.labelSelectCombobox.deleteModalTitle}
          >
            {component.labelSelectCombobox.deleteModalDescription}
          </ModalWithButtons>
        )}
      </>
    )
  }

  return (
    <InputSelectComboboxBasic<ItemType>
      filterItems={filterItems}
      inputValue={inputValue}
      items={sortedItems}
      onChange={onChange}
      onClose={() => setIsSelectingItem(false)}
      onInputChange={onInputChange}
      popperPlacement="bottom-end"
      popperWidth="312px"
      popperWrapperRef={popperWrapperRef}
      renderFooter={renderFooter}
      renderInput={renderInput}
      renderItem={renderItem}
      renderNewItem={renderNewItem}
      renderNoItemsPlaceholder={renderNoItemsPlaceholder}
      selectedItem={selectedItem}
    />
  )
}
