import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  'abn.abbreviation': 'ABN',
  'abn.full': 'Australian Business Number',
  'abnAndAcn.abbreviation': 'ABN/ACN',
  'abnAndAcn.full': 'Australian Business Number / Australian Company Number',
  'acn.abbreviation': 'ACN',
  'acn.full': 'Australian Company Number',
  abn: 'ABN',
  abnAndAcn: 'ABN/ACN',
  accounts: 'Accounts',
  acn: 'ACN',
  addBusiness: 'Add Business',
  addPerson: 'Add Person',
  additionalEmail: 'Additional Email',
  address: 'Address',
  business: 'Business',
  businessEmail: 'Business Email',
  businessName: 'Business Name',
  businessNameRequired: 'Business name is required.',
  invalidBusinessNameLength: 'Business Name has a limit of 256 characters.',
  businesses: 'Businesses',
  category: 'Category',
  combinedLabel: 'Phone, Email, Website, ABN/ACN',
  contacts: 'Contacts',
  country: 'Country',
  createInvoice: 'Create Invoice',
  email: 'Email',
  emptyInvoicesDescription: 'Would you like to create one now?',
  emptyInvoicesTitle: '{name} hasn’t yet received an invoice',
  emptyInvoicesTitleNoName: 'This contact hasn’t yet received an invoice',
  errorInvoicesDescription:
    'Something went wrong when loading Invoices.\n\nPlease make sure you’re connected to the internet and try again.',
  errorInvoicesTitle: 'Unable to load Invoices',
  existingBusiness: '{name} already exists.',
  existingPerson: '{name} with matching contact details already exists.',
  extendedFormFieldsLabel: 'Add details',
  firstName: 'First Name',
  firstNameRequired: 'First name is required.',
  invalidFirstNameLength: 'First Name has a limit of 20 characters.',
  general: 'General',
  invoices: 'Invoices',
  jobTitle: 'Job Title',
  lastName: 'Last Name',
  lastNameRequired: 'Last name is required.',
  invalidLastNameLength: 'Last Name has a limit of 40 characters.',
  mobile: 'Mobile',
  noSearchResultsDescription: 'There are no Invoices that meet your criteria',
  noSearchResultsTitle: 'No Invoices found',
  payments: 'Payments',
  people: 'People',
  person: 'Person',
  phone: 'Phone',
  postcode: 'Postcode',
  state: 'State',
  street: 'Address',
  suburb: 'Suburb',
  website: 'Website',
})
