import { useMemo, useState } from 'react'

import { CashflowReportingTransactionsContext } from './CashflowReportingTransactions.context'
import {
  ContactOrCategory,
  IncomeOrExpense,
} from './CashflowReportingTransactions.types'

export interface CashflowReportingTransactionsProviderProps {
  initialContactOrCategorySelection?: ContactOrCategory
  initialIncomeOrExpenseSelection?: IncomeOrExpense
  children?: React.ReactNode
}

export const CashflowReportingTransactionsProvider = ({
  initialContactOrCategorySelection = 'category',
  initialIncomeOrExpenseSelection = 'expense',
  children,
}: CashflowReportingTransactionsProviderProps) => {
  const [contactOrCategorySelection, setContactOrCategorySelection] =
    useState<ContactOrCategory>(initialContactOrCategorySelection)
  const [incomeOrExpenseSelection, setIncomeOrExpenseSelection] =
    useState<IncomeOrExpense>(initialIncomeOrExpenseSelection)

  const providerValue = useMemo(
    () => ({
      contactOrCategorySelection,
      incomeOrExpenseSelection,
      setContactOrCategorySelection,
      setIncomeOrExpenseSelection,
    }),
    [contactOrCategorySelection, incomeOrExpenseSelection]
  )

  return (
    <CashflowReportingTransactionsContext.Provider value={providerValue}>
      {children}
    </CashflowReportingTransactionsContext.Provider>
  )
}
