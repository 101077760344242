import { Fragment } from 'react'
import {
  Box,
  ButtonLink,
  ErrorMessageForm,
  Flex,
} from '@npco/zeller-design-system'
import { VALID_NUMBER_INTEGER_STRING_REGEX } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { FieldArray, useFormikContext } from 'formik'

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { translate } from 'utils/translations'

import { ToggleLabel } from '../SettingsGeneral/SettingsGeneralForm/SettingsGeneralForm.styled'
import {
  NumberField,
  ReminderEmptyState,
  RemoveRowButton,
} from './ReminderFields.styled'
import {
  InvoiceSettingsRemindersFormFields,
  REMINDER_AFTER_FIELD,
  REMINDER_BEFORE_FIELD,
} from './SettingsReminders.types'

interface ReminderFieldsTranslations {
  emptyState: string
  heading: string
  rowLabel: string
}

interface ReminderFieldsProps {
  getNewRowValue: () => string | number
  name: typeof REMINDER_BEFORE_FIELD | typeof REMINDER_AFTER_FIELD
  translations: ReminderFieldsTranslations
}

export const ReminderFields = ({
  getNewRowValue,
  name,
  translations,
}: ReminderFieldsProps) => {
  const { values, setFieldValue, errors } =
    useFormikContext<InvoiceSettingsRemindersFormFields>()

  const error = errors[name]

  const makeHandleOnChange =
    (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value

      const isValidNumber =
        VALID_NUMBER_INTEGER_STRING_REGEX.test(newValue) && newValue !== '0'
      const isValueEmpty = newValue === ''

      if (!isValidNumber && !isValueEmpty) {
        event.preventDefault()
        return
      }

      setFieldValue(fieldName, isValueEmpty ? newValue : Number(newValue))
    }

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <Box mb="24px">
          <Flex flex={1} alignItems="center" justifyContent="space-between">
            <ToggleLabel htmlFor={name}>{translations.heading}</ToggleLabel>
            <ButtonLink
              onClick={() => push(getNewRowValue())}
              data-testid={`add-row-${name}`}
            >
              {translate('shared.add')}
            </ButtonLink>
          </Flex>

          {values[name].length === 0 && (
            <ReminderEmptyState>{translations.emptyState}</ReminderEmptyState>
          )}
          {values[name].map((value, index) => {
            const fieldName = `${name}.${index}`
            const errorMessage = error?.[index]
            return (
              <Fragment key={fieldName}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mt="16px"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <NumberField
                      data-testid={`number-field-${fieldName}`}
                      $hasError={Boolean(errorMessage)}
                      id={fieldName}
                      inputMode="numeric"
                      maxLength={2}
                      name={fieldName}
                      onChange={makeHandleOnChange(fieldName)}
                      type="text"
                    />
                    {translations.rowLabel}
                  </Flex>
                  <RemoveRowButton
                    data-testid={`remove-row-${name}-${index}`}
                    height="16"
                    type="button"
                    onClick={() => remove(index)}
                    width="16"
                  >
                    <CloseIcon />
                  </RemoveRowButton>
                </Flex>
                <ErrorMessageForm
                  hasError={Boolean(errorMessage)}
                  errorMessage={errorMessage}
                />
              </Fragment>
            )
          })}
        </Box>
      )}
    />
  )
}
