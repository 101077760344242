import { Box } from '@npco/zeller-design-system'

import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'

import { getInitialDateTimeState } from './DatePicker.util'
import { useDatePickerState } from './hooks/useDatePickerState'
import { UncontrolledDatePicker } from './UncontrolledDatePicker'

interface DatePickerProps {
  isDateTime?: boolean
  widgetKey?: TIME_FILTER_ENABLED_COMPONENTS
}

export const DatePicker = ({ isDateTime, widgetKey }: DatePickerProps) => {
  const {
    data,
    enteredTo,
    selectedPeriod,
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
    onPeriodChange,
    outsideClickWrapperRef,
    hasCustomDateApplied,
    fromTime,
    toTime,
    onFromTimeChange,
    onToTimeChange,
    onFromInputBlur,
    onToInputBlur,
  } = useDatePickerState({
    initialState: getInitialDateTimeState(widgetKey),
    widgetKey,
  })

  return (
    <Box ref={outsideClickWrapperRef} width="100%">
      <UncontrolledDatePicker
        data={data}
        enteredTo={enteredTo}
        selectedPeriod={selectedPeriod}
        handleResetClick={handleResetClick}
        handleDayClick={handleDayClick}
        handleDayMouseEnter={handleDayMouseEnter}
        onPeriodChange={onPeriodChange}
        hasCustomDateApplied={hasCustomDateApplied}
        fromTime={fromTime}
        toTime={toTime}
        onFromTimeChange={onFromTimeChange}
        onToTimeChange={onToTimeChange}
        onPropsFromInputBlur={onFromInputBlur}
        onPropsToInputBlur={onToInputBlur}
        isDateTime={isDateTime}
      />
    </Box>
  )
}
