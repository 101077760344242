import { Box, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledSiteTitle = styled(H3)`
  margin: 1.25rem 0;
  width: 100%;
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 31.5rem;
  }
`

export const StyledSiteContainer = styled(Box)`
  width: 100%;
  overflow-x: hidden;
`
