import { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectSelectedItem } from 'design-system/Components/Select'

import {
  CategoryListItem,
  getCategoriesItems,
} from 'layouts/AccountLayout/filters/AccountFilterCategory/AccountFilterCategory.utils'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { CategoryWithSubcategories } from 'layouts/AccountLayout/hooks/useGetCategoriesWithSubcategories/useGetCategoriesWithSubcategories'
import { page } from 'translations'

import { MobileFilterCategorySelect } from '../MobileFilterCategorySelect/MobileFilterCategorySelect'

const filterName = page.accounts.transactionsList.filters.category

interface Props {
  categories: CategoryWithSubcategories[]
}

export const AccountMobileFilterCategory = ({ categories }: Props) => {
  const { category, setCategory, resetCategory } = useAccountFilters()
  const [searchInput, setSearchInput] = useState('')
  const [localCategory, setLocalCategory] = useState(category)
  const categoryItems = useMemo(
    () => getCategoriesItems(categories),
    [categories]
  )
  const items = categoryItems.filter(({ label }) =>
    label.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
  )

  const selectedItem = categoryItems.find(
    ({ category: categoryValue, subcategory }) =>
      categoryValue === localCategory?.category &&
      subcategory === localCategory?.subcategory
  )

  const resetLocalCategory = useCallback(() => {
    setLocalCategory(DEFAULT_FILTERS_VALUES.category)
  }, [])

  const onChange = (selectedCategory: SelectSelectedItem<CategoryListItem>) => {
    const { category: newCategory, subcategory: newSubcategory } =
      selectedCategory as CategoryListItem

    setLocalCategory({
      category: newCategory,
      subcategory: newSubcategory,
    })
  }

  const onClose = () => {
    setSearchInput('')
    setLocalCategory(category)
  }

  const onReset = () => {
    resetCategory()
    resetLocalCategory()
  }

  const onApply = (onInnerClose: () => void) => {
    setCategory(localCategory)
    onInnerClose()
  }

  useEffect(() => {
    setLocalCategory(category)
  }, [category])

  return (
    <MobileFilterCategorySelect
      items={items}
      selectedItem={selectedItem}
      filterName={filterName}
      onClose={onClose}
      onChange={onChange}
      onReset={onReset}
      onApply={onApply}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
    />
  )
}
