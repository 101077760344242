import { ApolloCache } from '@apollo/client'
import {
  DebitCardAccountTransactionStatusEnum,
  DebitCardTransactionStatusV2,
} from '@npco/mp-gql-types'
import { isEmpty } from 'lodash-es'

import { DebitCardTransaction } from '../CardSingle.types'
import { OutstandingSettingsType } from '../hooks/useOutstandingFilterSettings'

const checkIsOutstanding = ({
  settings,
  transaction,
}: {
  settings: OutstandingSettingsType
  transaction: DebitCardTransaction
}) => {
  const isApproved =
    transaction.status === DebitCardAccountTransactionStatusEnum.APPROVED ||
    transaction.status === DebitCardTransactionStatusV2.APPROVED

  return (
    isApproved &&
    ((settings.isReceiptOutstandingFilterSettingsOn &&
      isEmpty(transaction.attachments)) ||
      (settings.isNoteOutstandingFilterSettingsOn &&
        isEmpty(transaction.note)) ||
      (settings.isCategoryOutstandingFilterSettingsOn &&
        isEmpty(transaction.category)))
  )
}

interface UpdateOutstandingTransactionsCountInDebitCardProps {
  prevTransaction: DebitCardTransaction
  nextTransaction: DebitCardTransaction
  settings: OutstandingSettingsType
  cache: ApolloCache<object>
}

export const updateOutstandingTransactionsCount = ({
  prevTransaction,
  nextTransaction,
  settings,
  cache,
}: UpdateOutstandingTransactionsCountInDebitCardProps) => {
  const isPrevTransactionOutstanding = checkIsOutstanding({
    settings,
    transaction: prevTransaction,
  })

  const isNextTransactionOutstanding = checkIsOutstanding({
    settings,
    transaction: nextTransaction,
  })

  if (isPrevTransactionOutstanding === isNextTransactionOutstanding) {
    return
  }

  cache.modify({
    id: cache.identify({
      id: nextTransaction.debitCardId,
      __typename: 'DebitCardV2',
    }),
    fields: {
      outstandingTransactions(oldOutstandingTransactions: number | null) {
        const currentOutstandingCount = oldOutstandingTransactions ?? 0

        if (isNextTransactionOutstanding) {
          return currentOutstandingCount + 1
        }

        return Math.max(currentOutstandingCount - 1, 0)
      },
    },
  })
}

export const updateOutstandingCountCallback =
  (cache: ApolloCache<object>, settings: OutstandingSettingsType) =>
  (
    prevTransaction: DebitCardTransaction,
    nextTransaction: DebitCardTransaction
  ) => {
    updateOutstandingTransactionsCount({
      prevTransaction,
      nextTransaction,
      settings,
      cache,
    })
  }
