import { z } from 'zod'

export enum CompleteCardStage {
  CompleteCardSetupStage = 'CompleteCardSetupStage',
  CompleteCardDeliveryStage = 'CompleteCardDeliveryStage',
  CompleteCardInviteStage = 'CompleteCardInviteStage',
  CompleteCardSetupReplacementStage = 'CompleteCardSetupReplacementStage',
  CompleteCardReplacementDeliveryStage = 'CompleteCardReplacementDeliveryStage',
}

export type CompleteCardStateConfigType = {
  hasOpenTransition?: boolean
  pathname?: string
}

const CompleteCardSetupStateSchema = z.object({
  stage: z.literal(CompleteCardStage.CompleteCardSetupStage),
  cardId: z.string(),
})

export type CompleteCardSetupState = z.TypeOf<
  typeof CompleteCardSetupStateSchema
>

const CompleteCardDeliveryStateSchema = z.object({
  stage: z.literal(CompleteCardStage.CompleteCardDeliveryStage),
  cardholderName: z.string(),
})

export type CompleteCardDeliveryState = z.TypeOf<
  typeof CompleteCardDeliveryStateSchema
>

const CompleteCardReplacementDeliveryStateSchema = z.object({
  stage: z.literal(CompleteCardStage.CompleteCardReplacementDeliveryStage),
})

export type CompleteCardReplacementDeliveryState = z.TypeOf<
  typeof CompleteCardReplacementDeliveryStateSchema
>

const CompleteCardInviteStateSchema = z.object({
  stage: z.literal(CompleteCardStage.CompleteCardInviteStage),
  cardholderFirstName: z.string(),
})

export type CompleteCardInviteState = z.TypeOf<
  typeof CompleteCardInviteStateSchema
>

const CompleteCardReplacementSetupStateSchema = z.object({
  stage: z.literal(CompleteCardStage.CompleteCardSetupReplacementStage),
  cardholderFirstName: z.string(),
  cardId: z.string(),
})

export type CompleteCardReplacementSetupState = z.TypeOf<
  typeof CompleteCardReplacementSetupStateSchema
>

const CompleteCardStateSchema = z.union([
  CompleteCardSetupStateSchema,
  CompleteCardDeliveryStateSchema,
  CompleteCardInviteStateSchema,
  CompleteCardReplacementSetupStateSchema,
  CompleteCardReplacementDeliveryStateSchema,
])

export type CompleteCardState = z.TypeOf<typeof CompleteCardStateSchema>

export const CompleteCardLocationStateSchema = z.object({
  CompleteCardModal: CompleteCardStateSchema,
})
