import { useMemo } from 'react'
import { gql } from '@apollo/client'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import { useAccounts } from 'hooks/banking/useAccounts'

import {
  UseTransactionDetailsInfoDebitCardAccountTransactionFragment,
  UseTransactionDetailsInfoDebitCardTransactionV2Fragment,
} from './useTransactionDetailsInfo.generated'
import {
  adjustmentTransactionTypes,
  adjustmentTransactionTypesV3,
  bpayTransactionTypes,
  bpayTransactionTypesV3,
  cardTransactionTypes,
  cardTransactionTypesV3,
  externalIncomingTransactionTypes,
  externalIncomingTransactionTypesV3,
  externalOutgoingTransactionTypes,
  externalOutgoingTransactionTypesV3,
  getRecipientDetails,
  internalTransferTransactionTypes,
  internalTransferTransactionTypesV3,
  transferOutReversalTransactionTypes,
  transferOutReversalTransactionTypesV3,
} from './useTransactionDetailsInfo.utils'

export const useTransactionDetailsInfo = (
  transaction:
    | UseTransactionDetailsInfoDebitCardTransactionV2Fragment
    | UseTransactionDetailsInfoDebitCardAccountTransactionFragment
) => {
  const { accountById } = useAccounts()
  const isDebitCardTransactionV2Type =
    transaction.__typename === 'DebitCardTransactionV2'

  const isExternalOutgoingTransfer = isDebitCardTransactionV2Type
    ? externalOutgoingTransactionTypes.includes(transaction.type)
    : externalOutgoingTransactionTypesV3.includes(transaction.type)

  const isExternalIncomingTransaction = isDebitCardTransactionV2Type
    ? externalIncomingTransactionTypes.includes(transaction.type)
    : externalIncomingTransactionTypesV3.includes(transaction.type)

  const isCardTransaction = isDebitCardTransactionV2Type
    ? cardTransactionTypes.includes(transaction.type)
    : cardTransactionTypesV3.includes(transaction.type)

  const isInternalTransfer = isDebitCardTransactionV2Type
    ? internalTransferTransactionTypes.includes(transaction.type)
    : internalTransferTransactionTypesV3.includes(transaction.type)

  const isDepositType = isDebitCardTransactionV2Type
    ? transaction.type === DebitCardTransactionTypeV2.DEPOSIT
    : transaction.type === DebitCardAccountTransactionTypeEnum.DEPOSIT

  const isAdjustmentTransaction = isDebitCardTransactionV2Type
    ? adjustmentTransactionTypes.includes(transaction.type)
    : adjustmentTransactionTypesV3.includes(transaction.type)

  const isBpayTransaction = isDebitCardTransactionV2Type
    ? bpayTransactionTypes.includes(transaction.type)
    : bpayTransactionTypesV3.includes(transaction.type)

  const isBpayOutTransaction = isDebitCardTransactionV2Type
    ? transaction.type === DebitCardTransactionTypeV2.BPAY_OUT
    : transaction.type === DebitCardAccountTransactionTypeEnum.BPAY_OUT

  const isInterestTransaction = isDebitCardTransactionV2Type
    ? transaction.type === DebitCardTransactionTypeV2.INTEREST
    : false

  const isTransferOutReversalTransaction = isDebitCardTransactionV2Type
    ? transferOutReversalTransactionTypes.includes(transaction.type)
    : transferOutReversalTransactionTypesV3.includes(transaction.type)

  const isTransferInReversalTransaction = isDebitCardTransactionV2Type
    ? transaction.type === DebitCardTransactionTypeV2.NPP_IN_RETURN
    : transaction.type === DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN

  const isDirectDebitTransaction = isDebitCardTransactionV2Type
    ? transaction.type === DebitCardTransactionTypeV2.DDA_OUT
    : false

  const account = useMemo(() => {
    return accountById(transaction.debitCardAccountUuid)
  }, [transaction.debitCardAccountUuid, accountById])

  const recipientDetails = getRecipientDetails(transaction)

  return {
    isExternalOutgoingTransfer,
    isCardTransaction,
    isInternalTransfer,
    isDepositType,
    account,
    recipientDetails,
    isExternalIncomingTransaction,
    isAdjustmentTransaction,
    isBpayTransaction,
    isBpayOutTransaction,
    isInterestTransaction,
    isTransferOutReversalTransaction,
    isTransferInReversalTransaction,
    isDirectDebitTransaction,
  }
}

useTransactionDetailsInfo.fragments = {
  DebitCardTransactionV2: gql`
    fragment UseTransactionDetailsInfoDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      type
      debitCardAccountUuid
      ...GetRecipientDetailsDebitCardTransactionV2Fragment
    }

    ${getRecipientDetails.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment UseTransactionDetailsInfoDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      type
      debitCardAccountUuid
      ...GetRecipientDetailsDebitCardAccountTransactionFragment
    }

    ${getRecipientDetails.fragments.DebitCardAccountTransaction}
  `,
}
