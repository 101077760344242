import { TableData } from '@npco/ui-table'

import { CardAccountCell } from '../components/CardAccountCell/CardAccountCell'
import { CorporateCardBalanceCell } from '../components/CardBalanceCell/CorporateCardBalanceCell'
import { CardNameIconProgressCell } from '../components/CardNameIconCell/CardNameIconProgressCell'
import { CardOwnerCell } from '../components/CardOwnerCell/CardOwnerCell'
import { CardSpendProgressCell } from '../components/CardSpendProgressCell/CardSpendProgressCell'
import { CardTransactionsMissingDetailsCountCell } from '../components/CardTransactionsMissingDetailsCountCell/CardTransactionsMissingDetailsCountCell'
import { CorporateCardsTableDebitCardV2Fragment } from './CorporateCardsTable.generated'

export const columnsConfig = [
  {
    accessorId: 'cardNameIconProgressCell',
    cellComponent: CardNameIconProgressCell<
      TableData<CorporateCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 14, md: 7, xxl: 5.5 },
    cellSkeletonWidth: [
      { _: 116, MD: 174 },
      { _: 76, MD: 134 },
      { _: 116, MD: 156 },
    ],
  },
  {
    accessorId: 'cardOwner',
    cellComponent: CardOwnerCell<
      TableData<CorporateCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 0, md: 5, xxl: 4.5 },
    cellSkeletonWidth: [{ MD: 120 }, { MD: 144 }, { MD: 88 }],
  },
  {
    accessorId: 'debitCardAccount',
    cellComponent: CardAccountCell<
      TableData<CorporateCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 0, xxl: 4.5 },
    cellSkeletonWidth: [{ MD: 165 }, { MD: 80 }, { MD: 122 }],
  },
  {
    accessorId: 'cardTransactionsMissingDetailsCountCell',
    cellComponent: CardTransactionsMissingDetailsCountCell<
      TableData<CorporateCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 4, md: 3, xxl: 2 },
    cellSkeletonWidth: { _: 36, MD: 20 },
  },
  {
    accessorId: 'cardSpendProgressCell',
    cellComponent: CardSpendProgressCell<
      TableData<CorporateCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 0, md: 6, xxl: 5 },
    cellSkeletonWidth: [
      { MD: 188, XXL: 225 },
      { MD: 116, XXL: 140 },
      { MD: 156, XXL: 187 },
    ],
  },
  {
    accessorId: 'CardBalanceCell',
    cellComponent: CorporateCardBalanceCell<
      TableData<CorporateCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 6, md: 3, xxl: 2.5 },
    cellSkeletonWidth: [
      { _: 52, MD: 68 },
      { _: 68, MD: 84 },
      { _: 36, MD: 52 },
    ],
  },
]
