import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { BREAKPOINT } from 'styles/breakpoints'

import {
  MultiSelectBasicProps,
  MultiSelectItemBasic,
} from '../MultiSelect.types'
import { MultiSelectBasicDesktop } from './MultiSelectBasicDesktop/MultiSelectBasicDesktop'
import { MultiSelectBasicMobile } from './MultiSelectBasicMobile/MultiSelectBasicMobile'

export const MultiSelectBasic = <ItemType extends MultiSelectItemBasic>({
  items,
  selectedItems,
  onChange,
  renderTopSection,
  renderBottomSection,
  allItemsLabel,
  renderItem,
  renderTrigger,
  renderLabel,
  selectSize,
  selectStyle,
  onClose,
  shouldAutoFocusControl,
  isMobileResolution: isMobileResolutionProp,
  mobileOptionsListHeight,
  ...rest
}: MultiSelectBasicProps<ItemType>) => {
  const isMobileResolutionSmall = useIsMobileResolution(BREAKPOINT.SM)
  const isMobileResolution = isMobileResolutionProp ?? isMobileResolutionSmall

  return isMobileResolution ? (
    <MultiSelectBasicMobile<ItemType>
      selectSize={selectSize}
      selectStyle={selectStyle}
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      renderTopSection={renderTopSection}
      renderBottomSection={renderBottomSection}
      allItemsLabel={allItemsLabel}
      renderItem={renderItem}
      renderTrigger={renderTrigger}
      renderLabel={renderLabel}
      onClose={onClose}
      mobileOptionsListHeight={mobileOptionsListHeight}
      {...rest}
    />
  ) : (
    <MultiSelectBasicDesktop<ItemType>
      selectSize={selectSize}
      selectStyle={selectStyle}
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      renderTopSection={renderTopSection}
      renderBottomSection={renderBottomSection}
      allItemsLabel={allItemsLabel}
      renderItem={renderItem}
      renderTrigger={renderTrigger}
      renderLabel={renderLabel}
      onClose={onClose}
      shouldAutoFocusControl={shouldAutoFocusControl}
      {...rest}
    />
  )
}
