import { ButtonGhost } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const AddAccountButton = styled(ButtonGhost)`
  height: 48px;
  margin: 0;
  width: 48px;
`

export const MobileBalanceWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  align-items: flex-start;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`
