import { SimBillingStatus } from '@npco/mp-gql-types'

import { SimIcon } from '../../../components/SimIcon/SimIcon'
import * as styled from './SimImage.styled'

type SimImageProps = {
  billingStatus?: SimBillingStatus
}

export const SimImage = ({ billingStatus }: SimImageProps) => {
  return (
    <styled.SimImageContainer>
      <SimIcon billingStatus={billingStatus} width="80px" height="48px" />
    </styled.SimImageContainer>
  )
}
