import { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { renameAndDownloadFile } from '@npco/utils-file'
import { COLOR, DownloadIcon, Flex, Heading } from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { ReactComponent as CloseIcon } from 'assets/svg/close-grey.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { FileWithId } from 'components/File'

import { useInvoiceSettingsAnalytics } from '../../../../Settings/hooks/useInvoiceSettingsAnalytics'
import {
  InvoicePreviewBody,
  InvoicePreviewButton,
  InvoicePreviewContent,
  InvoicePreviewHeader,
  InvoicePreviewIconButton,
  InvoicePreviewWrapper,
} from './InvoicePreview.styled'
import { InvoicePreviewPdf } from './InvoicePreviewPdf/InvoicePreviewPdf'

export const translations = {
  heading: translate('page.invoice.previewModal.heading'),
  download: translate('page.invoice.previewModal.download'),
  close: translate('page.invoice.previewModal.close'),
  infoMessage: translate('page.invoice.previewModal.infoMessage'),
  getFileName: (referenceNumber: string) =>
    translate('page.invoice.previewModal.downloadFileName', {
      referenceNumber,
    }),
  draftFileName: translate('page.invoice.previewModal.downloadFileNameDraft'),
}

interface InvoicePreviewPdfProps {
  disableDownload?: boolean
  invoiceId?: string
  onClose: () => void
  pdfFile: FileWithId
  referenceNumber: string
}

const InvoiceDownloadIcon = () => <DownloadIcon />

export const InvoicePreview = ({
  disableDownload,
  invoiceId,
  onClose,
  pdfFile,
  referenceNumber,
}: InvoicePreviewPdfProps) => {
  const isMobile = useIsMobileResolution()
  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, invoiceId)
  const { trackCustomisationSettingsPdfPreviewView } =
    useInvoiceSettingsAnalytics()
  const { pathname } = useLocation()

  const handleDownload = async () => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_DOWNLOAD_PDF)
    await renameAndDownloadFile({
      link: pdfFile.id,
      filename: referenceNumber
        ? translations.getFileName(referenceNumber)
        : translations.draftFileName,
      format: 'PDF',
    })
  }

  useEffect(() => {
    if (pathname.includes('customisation')) {
      trackCustomisationSettingsPdfPreviewView()
    } else {
      trackInvoiceAction(AnalyticsEventNames.INVOICE_PREVIEWED_PDF)
    }
    // On mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InvoicePreviewWrapper
      bodyOpenClassName="modal-basic-open"
      overlayClassName="modal-basic-overlay"
      shouldCloseOnEsc
      testId={`preview-modal-${pdfFile.file.name}`}
      isOpen
      onRequestClose={onClose}
    >
      <InvoicePreviewHeader>
        <Heading.H3>{translations.heading}</Heading.H3>
        {isMobile && (
          <Flex gridGap="16px">
            {!disableDownload && (
              <InvoicePreviewIconButton
                data-testid="invoice-preview-download-icon"
                icon={InvoiceDownloadIcon}
                iconEnabledColor={COLOR.WHITE}
                onClick={handleDownload}
              />
            )}
            <InvoicePreviewIconButton
              data-testid="invoice-preview-close-icon"
              icon={CloseIcon}
              iconEnabledColor={COLOR.WHITE}
              onClick={onClose}
            />
          </Flex>
        )}
        {!isMobile && (
          <Flex gridGap="24px">
            {!disableDownload && (
              <InvoicePreviewButton
                data-testid="invoice-preview-download"
                icon={InvoiceDownloadIcon}
                iconEnabledColor={COLOR.WHITE}
                onClick={handleDownload}
              >
                {translations.download}
              </InvoicePreviewButton>
            )}
            <InvoicePreviewButton
              data-testid="invoice-preview-close"
              icon={CloseIcon}
              iconEnabledColor={COLOR.WHITE}
              onClick={onClose}
            >
              {translations.close}
            </InvoicePreviewButton>
          </Flex>
        )}
      </InvoicePreviewHeader>
      <InvoicePreviewBody>
        <InvoicePreviewContent>
          <InvoicePreviewPdf pdfFile={pdfFile} />
        </InvoicePreviewContent>
      </InvoicePreviewBody>
    </InvoicePreviewWrapper>
  )
}
