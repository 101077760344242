import { useMemo } from 'react'
import {
  // eslint-disable-next-line zeller/ban-raw-apollo-use-subscription
  useSubscription as useApolloSubscription,
} from '@apollo/client'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

export const useSubscription: typeof useApolloSubscription = (...args) => {
  const { isSubscriptionsSetup } = useZellerAuthenticationContext()

  const [subscription, options] = args

  const mergedOptions = useMemo(
    () => ({
      ...options,
      skip: isSubscriptionsSetup ? options?.skip : true,
      onError: (error: Error) =>
        ErrorLogger.report('[Core] Subscription error', error),
    }),
    [isSubscriptionsSetup, options]
  )

  return useApolloSubscription(subscription, mergedOptions)
}
