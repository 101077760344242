import { SiteType } from '@npco/mp-gql-types'

import { GetSitesForPricing_getSites_sites as SiteData } from 'types/gql-types/GetSitesForPricing'
import { GetXeroSurchargeSettings_getXeroSiteSettings_surchargesTaxes_feesSurchargeXinv as XeroInvoiceFees } from 'types/gql-types/GetXeroSurchargeSettings'
import { GetZellerSurchargeSettings_getZellerInvoiceSettings_surchargesTaxes_feesSurchargeZinv as ZellerInvoiceFees } from 'types/gql-types/GetZellerSurchargeSettings'
import { InfiniteList } from 'components/Lists/InfiniteList/InfiniteList'

import { XeroFeeContainer } from '../InvoiceFeesAndPricing/XeroFeeContainer'
import { ZellerFeeContainer } from '../InvoiceFeesAndPricing/ZellerFeeContainer'
import { PaymentTable } from '../PaymentTable/PaymentTable'
import {
  StyledSiteContainer,
  StyledSiteTableListContainer,
  StyledSiteTitle,
} from './AllSitesFeesAndPricingTableList.styled'

interface AllSitesFeesAndPricingTableListProps {
  allSites: SiteData[]
  xeroFees?: XeroInvoiceFees
  zellerFees?: ZellerInvoiceFees
}

export const AllSitesFeesAndPricingTableList = ({
  allSites,
  xeroFees,
  zellerFees,
}: AllSitesFeesAndPricingTableListProps) => {
  if (allSites.length > 0) {
    const allSitesWithCnp: SiteData[] = [
      { ...allSites[0], type: SiteType.CNP_XERO_INVOICE },
      { ...allSites[0], type: SiteType.CNP_ZELLER_INVOICE },
      ...allSites,
    ]
    return (
      <StyledSiteTableListContainer>
        <InfiniteList<SiteData>
          data={allSitesWithCnp}
          dataLength={allSitesWithCnp.length}
          renderItem={(site) => {
            if (site.type === SiteType.CNP_XERO_INVOICE) {
              return (
                <XeroFeeContainer
                  key="xero-fee-container"
                  xeroFees={xeroFees}
                />
              )
            }
            if (site.type === SiteType.CNP_ZELLER_INVOICE) {
              return (
                <ZellerFeeContainer
                  key="zeller-fee-container"
                  zellerFees={zellerFees}
                />
              )
            }
            return (
              <StyledSiteContainer key={site.id}>
                <StyledSiteTitle>{site.name}</StyledSiteTitle>
                <PaymentTable
                  cardPresentPayments={site.surchargesTaxes?.feePercent}
                  motoPayments={site.surchargesTaxes?.feePercentMoto}
                />
              </StyledSiteContainer>
            )
          }}
        />
      </StyledSiteTableListContainer>
    )
  }

  return null
}
