import {
  rvDetailedAccount,
  rvDetailedAccountType,
  rvThirdPartyDepositBank,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/depositAccount'

import { Account } from 'types/settings'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import { SessionStorageHandlerSlice } from 'services/sessionStorage/SessionStorageHandlerSlice'

export const DepositsStorageHandler = () => {
  return (
    <>
      <SessionStorageHandlerSlice
        storageKey={SESSION_STORAGE_KEYS.DEPOSITS_SELECTED_EXTERNAL_BANK}
        reactiveVarHandler={rvThirdPartyDepositBank}
        defaultValue={null}
      />
      <SessionStorageHandlerSlice
        storageKey={SESSION_STORAGE_KEYS.DEPOSITS_CLICKED_ACCOUNT}
        reactiveVarHandler={rvDetailedAccount}
        defaultValue={null}
      />
      <SessionStorageHandlerSlice
        storageKey={SESSION_STORAGE_KEYS.DEPOSITS_CLICKED_ACCOUNT_TYPE}
        reactiveVarHandler={rvDetailedAccountType}
        defaultValue={Account.ZELLER}
      />
    </>
  )
}
