import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const AccountDetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 8px;

  position: relative;
  margin: 24px 0 24px 6px;

  padding: 16px;

  box-shadow: 0px 8px 16px 0px rgba(34, 34, 38, 0.08),
    0px 1px 6px 0px rgba(34, 34, 38, 0.15);

  width: 97%;

  background-color: ${({ theme }) => theme.colors.WHITE};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    position: absolute;
    right: 33px;
    top: 33px;
    margin: 0;
    width: 346px;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    right: auto;
    left: 598px;
    top: 412px;
    align-self: auto;
    padding: 16px 24px;
    gap: 12px;
  }
`
