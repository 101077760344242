import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type AccountTotalBalanceQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type AccountTotalBalanceQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename?: 'DebitCardAccountV2'
      id: string
      balance: { __typename?: 'Money'; value: string } | null
    } | null>
  }
}

export const AccountTotalBalanceQuery = gql`
  query AccountTotalBalanceQuery($entityUuid: ID!) {
    getDebitCardAccountsV2(limit: 50, entityUuid: $entityUuid) {
      accounts {
        id
        balance {
          value
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  AccountTotalBalanceQueryResponse,
  AccountTotalBalanceQueryVariables
>

/**
 * __useAccountTotalBalanceQuery__
 *
 * To run a query within a React component, call `useAccountTotalBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTotalBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTotalBalanceQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useAccountTotalBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountTotalBalanceQueryResponse,
    AccountTotalBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AccountTotalBalanceQueryResponse,
    AccountTotalBalanceQueryVariables
  >(AccountTotalBalanceQuery, options)
}
export function useAccountTotalBalanceQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTotalBalanceQueryResponse,
    AccountTotalBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountTotalBalanceQueryResponse,
    AccountTotalBalanceQueryVariables
  >(AccountTotalBalanceQuery, options)
}
export type AccountTotalBalanceQueryHookResult = ReturnType<
  typeof useAccountTotalBalanceQuery
>
export type AccountTotalBalanceQueryLazyQueryHookResult = ReturnType<
  typeof useAccountTotalBalanceQueryLazyQuery
>
export type AccountTotalBalanceQueryQueryResult = Apollo.QueryResult<
  AccountTotalBalanceQueryResponse,
  AccountTotalBalanceQueryVariables
>
