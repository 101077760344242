import { BodyDefault, ButtonGhost } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledModalWrapper = styled.div`
  max-width: 28rem;
`
export const StyledDeleteButtonContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`

export const StyledDeleteButton = styled(ButtonGhost)`
  width: 100%;
`

export const StyledDeleteModalContent = styled.div`
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodyDefault}
`
