import { Box, Flex } from '@npco/zeller-design-system'

import {
  StyledSelectItem,
  StyledSkeleton,
} from './AccountFilterContactLoadingState.styled'

interface Props {
  isMobile?: boolean
}

export const AccountFilterContactLoadingState = ({
  isMobile = false,
}: Props) => {
  if (isMobile) {
    return (
      <Flex height="100%" flexDirection="column" width="100%">
        <StyledSelectItem hasSelectedIndicator={false}>
          <StyledSkeleton width="16.25rem" />
        </StyledSelectItem>
        <StyledSelectItem hasSelectedIndicator={false}>
          <StyledSkeleton width="16.25rem" />
        </StyledSelectItem>
        <StyledSelectItem hasSelectedIndicator={false}>
          <StyledSkeleton width="16.25rem" />
        </StyledSelectItem>
        <StyledSelectItem hasSelectedIndicator={false}>
          <StyledSkeleton width="16.25rem" />
        </StyledSelectItem>
      </Flex>
    )
  }

  return (
    <Box height="10.5rem" overflow="hidden">
      <StyledSelectItem hasSelectedIndicator={false}>
        <StyledSkeleton width="16.25rem" />
      </StyledSelectItem>
      <StyledSelectItem hasSelectedIndicator={false}>
        <StyledSkeleton width="16.25rem" />
      </StyledSelectItem>
      <StyledSelectItem hasSelectedIndicator={false}>
        <StyledSkeleton width="16.25rem" />
      </StyledSelectItem>
      <StyledSelectItem hasSelectedIndicator={false}>
        <StyledSkeleton width="16.25rem" />
      </StyledSelectItem>
    </Box>
  )
}
