import { useCallback, useRef } from 'react'
import { DebitCardTransactionStatusV2 } from '@npco/mp-gql-types'
import { Dayjs } from 'dayjs'

import dayjs from 'utils/dayjs'
import { DebitCardTransactionAmountUpdate_onDebitCardTransactionUpdate as AmountUpdate } from 'types/gql-types/DebitCardTransactionAmountUpdate'

interface UseTrackNewTransactionsProps {
  after: string | number | Date | Dayjs
}

export const useTrackNewTransactions = ({
  after,
}: UseTrackNewTransactionsProps) => {
  const newTransactions = useRef<AmountUpdate[]>([])

  const getIsNew = useCallback(
    (subscriptionUpdate: AmountUpdate) => {
      const result =
        dayjs(subscriptionUpdate.timestamp).isAfter(after) &&
        !newTransactions.current.find(
          (transaction) =>
            transaction.id === subscriptionUpdate.id &&
            transaction.status === DebitCardTransactionStatusV2.APPROVED
        )

      return result
    },
    [after]
  )

  const registerTransaction = useCallback(
    (subscriptionUpdate: AmountUpdate) => {
      newTransactions.current.push(subscriptionUpdate)
    },
    []
  )

  return { getIsNew, registerTransaction }
}
