import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import {
  AvatarUser,
  AvatarUserSize,
  Box,
  ButtonLink,
  COLOR,
  Divider,
  Ellipsize,
  Flex,
  TopBar,
  Typography,
  USER_AVATAR_COLORS,
} from '@npco/zeller-design-system'

import { useLogout } from 'hooks/useLogout'

import { topBarUserMenuTranslations } from './TopBarUserMenu.i18n'
import { getUserDisplayName } from './TopBarUserMenu.utils'

const deps = {
  useLogout,
}

export const TopBarUserMenu = () => {
  const { userData } = useLoggedInUser()

  const { logout } = deps.useLogout()

  const userFullName = getUserDisplayName(userData)
  const t = useTranslations(topBarUserMenuTranslations)

  return (
    <TopBar.Menu
      icon={
        <AvatarUser
          name={userFullName}
          size={AvatarUserSize.XXSmall}
          bgColor={USER_AVATAR_COLORS.DARK_BLUE}
          color={COLOR.WHITE}
        />
      }
    >
      <Flex marginBottom="16px" flexDirection="column">
        <Box>
          <Typography variant="heading-md">
            <Ellipsize>{userFullName}</Ellipsize>
          </Typography>
        </Box>
        {userData?.email && (
          <Box>
            <Ellipsize title={userData.email}>{userData.email}</Ellipsize>
          </Box>
        )}
      </Flex>
      <Divider margin="0px" />
      <Box marginTop="16px">
        <ButtonLink onClick={logout}>{t('logout')}</ButtonLink>
      </Box>
    </TopBar.Menu>
  )
}

TopBarUserMenu.deps = deps
