import { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { getColorFromResponse } from '@npco/utils-colors'
import { AvatarBasic, COLOR } from '@npco/zeller-design-system'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import { useAccounts } from 'hooks/banking/useAccounts'
import { sortAccounts } from 'utils/banking/sortAccounts/sortAccounts'
import { translate } from 'utils/translations'
import { DebitCardAccount } from 'types/accounts'
import { DisabledAccountInfo } from 'components/DepositAccountDetailDrawer/DisabledAccountInfo/DisabledAccountInfo'
import { ZellerAccountDetailDrawer } from 'components/DepositAccountDetailDrawer/ZellerAccountDetailDrawer'
import { AccountTileList } from 'components/Tile/AccountTileList/AccountTileList'
import { AccountTileRightControls } from 'components/Tile/AccountTileList/AccountTileRightControls'

import { SimBillingZellerAccountAction } from '../SimBillingAccountDetailAction/SimBillingZellerAccountAction'

export const SimBillingZellerBankTile = () => {
  const [openedAccountId, setOpenedAccountId] = useState<string>()
  const simBillingAccount = useReactiveVar(rvSimBillingAccount)
  const { accounts, accountById } = useAccounts()

  const debitAccounts = useMemo(
    () =>
      accounts.filter(
        (account) => account.type === DebitCardAccountType.ZLR_DEBIT
      ),
    [accounts]
  )

  const openedAccount = useMemo(
    () => accountById(openedAccountId),
    [accountById, openedAccountId]
  )

  const getIsDisabled = (account: DebitCardAccount) =>
    account.status === DebitCardAccountStatus.SUSPENDED

  const getIsActive = (account: DebitCardAccount) =>
    account.id === simBillingAccount?.id

  const renderSuspendedAccountAction = (account: DebitCardAccount) => {
    const isSelectedAccount = getIsActive(account)
    const messageString = isSelectedAccount
      ? 'page.settings.sim.billingAccount.suspended.messageSelectedAccount'
      : 'page.settings.sim.billingAccount.suspended.message'
    const descriptionString = isSelectedAccount
      ? 'page.settings.sim.billingAccount.suspended.descriptionSelectedAccount'
      : 'page.settings.sim.billingAccount.suspended.description'

    return (
      <DisabledAccountInfo
        isSelectedAccount={isSelectedAccount}
        message={translate(messageString)}
        description={translate(descriptionString)}
      />
    )
  }

  const getBankIcon = (account: DebitCardAccount) => {
    return (
      <AvatarBasic
        bgColor={
          account.status === DebitCardAccountStatus.SUSPENDED
            ? COLOR.GREY_150
            : getColorFromResponse(account.icon.colour)
        }
        text={account.icon.letter}
      />
    )
  }

  const renderAccountAction = (account: DebitCardAccount) => (
    <SimBillingZellerAccountAction
      account={account}
      isSelectedAccount={getIsActive(account)}
    />
  )

  return (
    <>
      <ZellerAccountDetailDrawer
        isOpen={Boolean(openedAccountId)}
        account={openedAccount}
        renderBottomAction={(account) =>
          getIsDisabled(account)
            ? renderSuspendedAccountAction(account)
            : renderAccountAction(account)
        }
        getIcon={(account) => getBankIcon(account)}
        onClose={() => setOpenedAccountId(undefined)}
      />
      <AccountTileList<DebitCardAccount>
        onSelect={(account) => {
          setOpenedAccountId(account.id)
        }}
        getIsActive={getIsActive}
        bankAccounts={sortAccounts(debitAccounts).filter(
          (account: DebitCardAccount) =>
            account.status !== DebitCardAccountStatus.CLOSED
        )}
        renderRightControl={({ isActive, isDisabled }) => (
          <AccountTileRightControls
            isActive={isActive}
            isDisabled={isDisabled}
            disabledMessage={translate(
              'page.settings.sim.billingAccount.suspended.title'
            )}
          />
        )}
        getIsDisabled={getIsDisabled}
        getTileTitle={(account: DebitCardAccount) => account.name}
        getTileIcon={getBankIcon}
      />
    </>
  )
}
