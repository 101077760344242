import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'You have no active SIM Cards',
  description:
    'Use Zeller SIM Card to accept payments on-the-go, or when Wi-Fi is unavailable. Active Zeller SIM Cards will be listed here.',
  cardHeading: 'Enjoy reliable connectivity with Zeller SIM Card',
  point1: 'Accept payments during Wi-Fi outages',
  point2: 'Unlimited monthly data',
  point3: 'First month of data included free',
})
