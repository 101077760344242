import { COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { CardTileColour } from './CardColourTile.types'

const getBackgroundColour = (color: CardTileColour) => {
  switch (color) {
    case CardTileColour.WHITE:
      return `linear-gradient(133.59deg, #FFFFFF 2.66%, #F5F5F5 88.52%)`
    case CardTileColour.BLACK:
      return `linear-gradient(133.04deg, #000000 2.69%, #8C959C 97.38%)`
    case CardTileColour.DARKBLUE:
      return '#0055A6'
    case CardTileColour.YELLOW:
      return ' #FFB703'
    case CardTileColour.GREEN:
      return '#00C072'
    case CardTileColour.LIGHTBLUE:
      return '#76D0EA'
    default:
      return COLOR.GREY_30
  }
}

interface ColorTileProps {
  color: CardTileColour
}

export const StyledColourTile = styled.div<ColorTileProps>`
  width: 100%;
  height: 100%;
  background: ${(props) => getBackgroundColour(props.color)};
`
