import { createTranslations } from '@npco/utils-translations'

export const imagePreviewModalTranslations = createTranslations({
  indexText: '{currentIndex} of {total}',
  delete: 'Delete',
  download: 'Download',
  close: 'Close',
  title: 'Delete Receipt',
  description: 'Are you sure you want to delete {fileName}?',
  cancel: 'Cancel',
  confirm: 'Confirm',
})
