import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  body: 'Logo image files can be up to 5MB in size, and must be in PNG, JPG/JPEG, WebP or HEIC/HEIF format.',
  title: 'Upload Logo',
  uploadLabel: 'Drag and drop or',
  uploaderSubLabel: 'select an image from your device.',
  successNotification: 'Logo uploaded successfully.',
  errorNotification: 'Logo could not be uploaded. Please try again.',
})
