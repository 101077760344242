import React from 'react'
import { SelectStandard } from 'design-system/Components/Select'

import { translate } from 'utils/translations'
import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { SelectMobileFilterFooter } from 'components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'

export interface MobileFilter {
  key: string
  renderFilter: () => React.ReactElement
}

interface Props {
  disabled: boolean
  filters: MobileFilter[]
  onReset?: () => void
  showIndicator?: boolean
}

export const FiltersListMobile = ({
  disabled,
  filters,
  onReset,
  showIndicator,
}: Props) => {
  const items = filters.map(({ key, renderFilter }) => ({
    label: key,
    value: key,
    renderItem: renderFilter,
  }))

  return (
    <div data-testid="filters-list-mobile">
      <SelectStandard
        onChange={() => undefined}
        selectedItem={null}
        mobileLabel={translate(
          'page.accounts.transactionsList.filters.filterMobileMainListHeader'
        )}
        items={items}
        renderItem={({ item: { renderItem } }) => renderItem()}
        renderTrigger={(renderProps) => (
          <DashboardFiltersButton
            {...renderProps}
            disabled={disabled}
            isActive={false}
            showIndicator={showIndicator}
          />
        )}
        renderBottomSection={() => (
          <SelectMobileFilterFooter onReset={onReset} />
        )}
      />
    </div>
  )
}
