import { useEffect, useState } from 'react'
import {
  Flex,
  PopperMobileTitleGroup,
  StyledPopperModalMobile,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { defaultAmountDisplay } from 'layouts/AccountLayout/filters/AccountFilterAmount/AccountFilterAmount.utils'
import {
  SLIDER_RANGE,
  useFilterAmountPopper,
} from 'layouts/AccountLayout/filters/AccountFilterAmount/AccountFilterAmountContent/hooks/useFilterAmountPopper/useFilterAmountPopper'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { InputManualRange } from 'components/Filters/NewFilters/RangePicker/InputManualRange/InputManualRange'
import { RangePickerValue } from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import { page } from 'translations'

import { SelectMobileFilterFooter } from '../../../../components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'
import { StyledRange } from './AccountMobileFilterAmount.styled'
import { AccountMobileFilterAmountTrigger } from './AccountMobileFilterAmountTrigger/AccountMobileFilterAmountTrigger'

const filterName = page.accounts.transactionsList.filters.amount
const SLIDER_STEP = 10

export const AccountMobileFilterAmount = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { amount, setAmount, resetAmount } = useAccountFilters()
  const [localAmount, setLocalAmount] = useState(amount)

  const {
    onSliderChange,
    onAfterChange,
    minInput,
    maxInput,
    onReset: onValuesReset,
    sliderValue,
  } = useFilterAmountPopper({
    isOpen,
    amount: localAmount || defaultAmountDisplay,
    setAmount: setLocalAmount,
    resetAmount,
  })

  const onApply = () => {
    setIsOpen(false)
    setAmount(localAmount)
  }

  const onCancel = () => {
    setIsOpen(false)
    setLocalAmount(amount)
  }

  useEffect(() => {
    setLocalAmount(amount)
  }, [amount])

  return (
    <>
      <AccountMobileFilterAmountTrigger
        openPopper={() => setIsOpen(true)}
        amount={amount}
      />
      <StyledPopperModalMobile
        isOpen={isOpen}
        overlayClassName="modal-basic-overlay"
      >
        <Flex flexDirection="column" height="100%">
          <PopperMobileTitleGroup onClick={onCancel}>
            {translate(
              'page.accounts.transactionsList.filters.filterMobileHeader',
              {
                filterName,
              }
            )}
          </PopperMobileTitleGroup>
          <Flex p="2rem 1rem 1rem" flex="1" flexDirection="column">
            <div>
              <StyledRange
                min={SLIDER_RANGE.min}
                max={SLIDER_RANGE.max}
                step={SLIDER_STEP}
                value={[sliderValue.min, sliderValue.max]}
                onChange={(newValue) =>
                  onSliderChange(newValue as RangePickerValue)
                }
                onAfterChange={onAfterChange}
              />
              <Flex justifyContent="space-between">
                <InputManualRange
                  value={minInput.inputValue}
                  onChange={minInput.onChange}
                  onBlur={minInput.onBlur}
                />
                <InputManualRange
                  value={maxInput.inputValue}
                  onChange={maxInput.onChange}
                  onBlur={maxInput.onBlur}
                />
              </Flex>
            </div>
          </Flex>
          <SelectMobileFilterFooter onReset={onValuesReset} onApply={onApply} />
        </Flex>
      </StyledPopperModalMobile>
    </>
  )
}
