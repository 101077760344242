import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTabsContainer = styled.div`
  width: 100%;

  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;

  h2.drawer-title {
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: -3rem;
    margin-right: -3rem;
  }

  @media screen and (min-width: ${BREAKPOINT.XL}px) {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    margin-left: -4.5rem;
    margin-right: -4.5rem;
  }
`
