import { useState } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  Divider,
  Flex,
  Heading,
  HeadingStyles,
  showErrorToast,
  showSuccessToast,
  SkeletonH5,
  ToggleForm,
  useModalState,
} from '@npco/zeller-design-system'
import { Field, Formik, FormikHelpers } from 'formik'

import { getNonNullString } from 'utils/string'
import { GetDevicePosSettings_getDeviceSettings_posSettings as PosSettingsType } from 'types/gql-types/GetDevicePosSettings'
import { ConfirmationModal } from 'pages/Devices/ConfirmationModal/ConfirmationModal'
import { useUpdateDevicesMutation } from 'pages/Devices/graphql/UpdateDevices.generated'
import { DetailsField } from 'components/DetailsField/DetailsField'
import { translationsShared } from 'translations'

import { useUpdatePosSettings } from '../PosSettings.hooks'
import { translations } from './PayAtTableDetails.i18'
import * as styled from './PayAtTableDetails.styled'

interface PayAtTableDetailsProps {
  posDetails: PosSettingsType
  isLoading: boolean
  deviceId: string
  siteId: string
}

export interface PayAtTableFormValues {
  billSummaryEnabled: boolean
}

export const PayAtTableDetails = ({
  posDetails,
  isLoading,
  deviceId,
  siteId,
}: PayAtTableDetailsProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { isModalOpen, openModal, closeModal } = useModalState()
  const [applyToAllDevices, setApplyToAllDevices] = useState(false)
  const [updateDevicesMutation] = useUpdateDevicesMutation()
  const { updateDevicePosSettings } = useUpdatePosSettings()
  const flags = useFeatureFlags()
  const entityUuid = useSelectedEntityUuid()

  const handleUpdateAllDevices = async (
    values: PayAtTableFormValues,
    formikHelpers: FormikHelpers<PayAtTableFormValues>
  ) => {
    try {
      const res = await updateDevicesMutation({
        variables: {
          entityUuid,
          siteUuid: siteId,
          input: {
            posSettings: {
              hlSettings: {
                billingSummary: values.billSummaryEnabled,
              },
            },
          },
        },
        update: (cache, { data }) => {
          if (!data?.updateDevices) {
            return
          }
          cache.modify({
            id: cache.identify({
              __typename: 'DeviceSettings',
              id: deviceId,
            }),
            fields: {
              posSettings: () => {
                return {
                  ...posDetails,
                  hlSettings: {
                    billingSummary: values.billSummaryEnabled,
                  },
                }
              },
            },
          })
        },
      })

      if (!res?.data?.updateDevices) {
        formikHelpers.resetForm()
        showErrorToast(t('errorToast'))
        return
      }

      showSuccessToast(t('updateAllSuccessToast'))
      formikHelpers.resetForm({ values })
    } catch (err) {
      showErrorToast(t('errorToast'))
    }
  }

  const handleUpdateSingleDevice = async (
    values: PayAtTableFormValues,
    formikHelpers: FormikHelpers<PayAtTableFormValues>
  ) => {
    try {
      const res = await updateDevicePosSettings({
        variables: {
          entityUuid,
          deviceSettings: {
            id: deviceId,
            posSettings: {
              hlSettings: {
                billingSummary: values.billSummaryEnabled,
              },
            },
          },
        },
        update: (cache, { data }) => {
          if (!data?.updateDeviceSettings) {
            return
          }
          cache.modify({
            id: cache.identify({
              __typename: 'DeviceSettings',
              id: deviceId,
            }),
            fields: {
              posSettings: () => {
                return {
                  ...posDetails,
                  hlSettings: {
                    billingSummary: values.billSummaryEnabled,
                  },
                }
              },
            },
          })
        },
      })

      if (!res?.data?.updateDeviceSettings) {
        formikHelpers.resetForm()
        showErrorToast(t('errorToast'))
        return
      }
      showSuccessToast(t('updateSuccessToast'))
      formikHelpers.resetForm({ values })
    } catch (err) {
      showErrorToast(t('errorToast'))
    }
  }

  const handleSubmit = async (
    values: PayAtTableFormValues,
    formikHelpers: FormikHelpers<PayAtTableFormValues>
  ) => {
    if (applyToAllDevices) {
      await handleUpdateAllDevices(values, formikHelpers)
    } else {
      await handleUpdateSingleDevice(values, formikHelpers)
    }
  }

  return (
    <>
      <Flex mb="16px">
        <Heading.H3>{t('payAtTableDetails')}</Heading.H3>
      </Flex>
      <DetailsField
        isLoading={isLoading}
        label={t('venueName')}
        value={getNonNullString(posDetails.posVenue?.name, '-')}
      />
      <DetailsField
        isLoading={isLoading}
        label={t('locations')}
        value={
          <Flex flexDirection="column" gap="8px">
            {posDetails.posVenue?.locations?.map((location) => (
              <Box key={location.id} textAlign="right">
                {location.name}
              </Box>
            ))}
            {posDetails.posVenue?.locations?.length === 0 && '-'}
          </Flex>
        }
      />
      <Divider />

      <Formik
        initialValues={{
          billSummaryEnabled: Boolean(posDetails.hlSettings?.billingSummary),
        }}
        onSubmit={async (values, formikHelpers) => {
          await handleSubmit(values, formikHelpers)
        }}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting }) => (
          <styled.Form>
            {flags.BillSummary && (
              <>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="16px"
                  data-testid="billSummary"
                >
                  <Heading.H4 withStyles={HeadingStyles.H5}>
                    {t('billSummary')}
                  </Heading.H4>
                  {isLoading ? (
                    <SkeletonH5 width="118px" margin="0" />
                  ) : (
                    <Field
                      component={ToggleForm}
                      name="billSummaryEnabled"
                      value={values.billSummaryEnabled}
                    />
                  )}
                </Flex>
                <styled.Description>{t('billSummaryDesc')} </styled.Description>
                <styled.ButtonContainer>
                  <ButtonFill
                    data-testid="submit-button"
                    onClick={() => openModal()}
                    isLoading={isSubmitting}
                    fullWidth
                    size={BUTTON_SIZE.LARGE}
                  >
                    {tShared('save')}
                  </ButtonFill>
                  <ButtonGhost
                    data-testid="reset-button"
                    onClick={() => resetForm()}
                    disabled={isSubmitting}
                    fullWidth
                    size={BUTTON_SIZE.LARGE}
                  >
                    {tShared('cancel')}
                  </ButtonGhost>
                </styled.ButtonContainer>
                <ConfirmationModal
                  isOpen={isModalOpen}
                  onCancel={closeModal}
                  onClickPrimary={() => {
                    setApplyToAllDevices(true)
                    submitForm()
                    closeModal()
                  }}
                  onClickSecondary={() => {
                    setApplyToAllDevices(false)
                    submitForm()
                    closeModal()
                  }}
                />
              </>
            )}
          </styled.Form>
        )}
      </Formik>
    </>
  )
}
