import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  CategoryIcon,
  COLOR,
  NumberBadge,
  NumberBadgeVariant,
  PillBasic,
  PillSize,
  TooltipBasic,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useGoToEditDebitCardTransactionCategory } from 'features/EditDebitCardTransactionCategory/edit-debit-card-transaction-category-routing'

import { getCategoryDisplay } from 'components/EditZellerCategories/Fields/utils/categories.utils'

import {
  CategoryCellDebitCardAccountTransactionFragment,
  CategoryCellDebitCardTransactionV2Fragment,
} from './CategoryCell.generated'
import { categoryCellTranslations } from './CategoryCell.i18n'
import { CellWrapper, IconButton, PillButton } from './CategoryCell.styled'

interface CategoryCellProps {
  transaction:
    | CategoryCellDebitCardTransactionV2Fragment
    | CategoryCellDebitCardAccountTransactionFragment
  isEmptyCategoryHighlighted?: boolean
}

export const CategoryCell = ({
  transaction,
  isEmptyCategoryHighlighted,
}: CategoryCellProps) => {
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  const transactionCategory =
    transaction.category ?? transaction.contact?.category ?? null
  const hasCategory = !!transactionCategory

  const category = getCategoryDisplay(transactionCategory)
  const emptyCategoryIconColor = isEmptyCategoryHighlighted
    ? COLOR.RED_400
    : COLOR.GREY_150

  const t = useTranslations(categoryCellTranslations)

  const { goToEditDebitCardTransactionCategory } =
    useGoToEditDebitCardTransactionCategory()

  const handleCategoryClick = useCallback<
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  >(
    (event) => {
      event?.stopPropagation()
      goToEditDebitCardTransactionCategory({
        stage: 'edit',
        debitCardTransactionUuid: transaction.id,
        contactUuid: transaction.contact?.id,
        category: transactionCategory,
        subcategoryDetails: transaction.subcategoryDetails,
      })
    },
    [
      goToEditDebitCardTransactionCategory,
      transaction.contact?.id,
      transaction.id,
      transaction.subcategoryDetails,
      transactionCategory,
    ]
  )

  return (
    <CellWrapper>
      <TooltipBasic
        renderTrigger={({ ref, handlers }) => (
          <span
            ref={ref}
            {...handlers}
            style={{ display: 'block', width: 'fit-content' }}
          >
            {isMobileOrTablet ? (
              <IconButton
                aria-label={t('category')}
                type="button"
                onClick={handleCategoryClick}
              >
                <CategoryIcon
                  width="12"
                  height="12"
                  color={
                    hasCategory ? COLOR.GREEN_1600 : emptyCategoryIconColor
                  }
                />
              </IconButton>
            ) : (
              <>
                {!hasCategory && isEmptyCategoryHighlighted ? (
                  <PillButton onClick={handleCategoryClick}>
                    <NumberBadge
                      text={category.text}
                      variant={NumberBadgeVariant.SoftAlert}
                    />
                  </PillButton>
                ) : (
                  <PillBasic
                    text={category.text}
                    icon={category.icon}
                    size={PillSize.Small}
                    isDisabledStyle={!hasCategory}
                    onClick={handleCategoryClick}
                  />
                )}
              </>
            )}
          </span>
        )}
        placement="top"
        showArrow={false}
      >
        {!hasCategory ? t('addCategory') : t('editCategory')}
      </TooltipBasic>
    </CellWrapper>
  )
}

CategoryCell.fragments = {
  DebitCardTransactionV2: gql`
    fragment CategoryCellDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
      category
      subcategoryDetails {
        id
        name
        predefined
      }
      contact {
        id
        category
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment CategoryCellDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
      category
      subcategoryDetails {
        id
        name
        predefined
      }
      contact {
        id
        category
      }
    }
  `,
}
