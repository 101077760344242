import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ArrowDecreaseIcon,
  ArrowIncreaseIcon,
  COLOR,
} from '@npco/zeller-design-system'

import { formatChange } from '../../utils/formatChange/formatChange'
import { translations } from './PieChartCenterContent.i18n'
import * as styled from './PieChartCenterContent.styled'

interface PieChartTextProps {
  size: 'small' | 'large'
  title: string
  total: string
  change?: number | null
}

export const PieChartCenterContent = ({
  size,
  title,
  total,
  change,
}: PieChartTextProps) => {
  const t = useTranslations(translations)
  const changeOptions = useMemo(() => {
    if (!change || change === 0) {
      return null
    }

    const isIncreaseChange = change > 0
    const color = isIncreaseChange ? COLOR.GREEN_1600 : COLOR.RED_1000
    const dimension = size === 'small' ? '8' : '12'
    const Icon = isIncreaseChange ? ArrowIncreaseIcon : ArrowDecreaseIcon

    return {
      icon: <Icon color={color} width={dimension} height={dimension} />,
      value: formatChange(Math.abs(change)),
      color,
    }
  }, [change, size])

  return (
    <foreignObject
      width="100%"
      height="100%"
      data-testid="pie-chart-center-content"
    >
      <styled.ContentWrapper size={size}>
        <styled.Title size={size}>{title}</styled.Title>
        <styled.Total size={size}>{total}</styled.Total>
        {changeOptions && (
          <>
            <styled.ChangeWrapper size={size} color={changeOptions.color}>
              {changeOptions.icon}
              <styled.ChangeText size={size}>
                {changeOptions.value}
              </styled.ChangeText>
              %
            </styled.ChangeWrapper>
            <styled.ChangeDescription color={changeOptions.color}>
              {t('changeDescription')}
            </styled.ChangeDescription>
          </>
        )}
      </styled.ContentWrapper>
    </foreignObject>
  )
}
