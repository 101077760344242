import { SimBillingStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  SkeletonBasic,
  Typography,
} from '@npco/zeller-design-system'

import { SimBillingDisplayStatus } from '../../../Sim.type'
import {
  getSimBillingDisplayStatusMessage,
  mapSimBillingStatusToDisplayStatus,
} from '../../../Sim.utils'
import { translations } from '../../SimDrawer.i18n'

type DrawerTitleProps = {
  isLoading: boolean
  billingStatus?: SimBillingStatus
}

export const DrawerTitle = ({ isLoading, billingStatus }: DrawerTitleProps) => {
  const t = useTranslations(translations)
  const simBillingDisplayStatus = mapSimBillingStatusToDisplayStatus(
    billingStatus ?? SimBillingStatus.NULL
  )
  const statusInfo = getSimBillingDisplayStatusMessage(simBillingDisplayStatus)

  return (
    <Flex gap="12px" alignItems="flex-end">
      <Typography variant="heading-2xl">{t('title')}</Typography>
      {isLoading ? (
        <Box paddingBottom="6px">
          <SkeletonBasic width="65px" height="12px" isRounded />
        </Box>
      ) : (
        <Typography
          variant="body-base"
          color={
            simBillingDisplayStatus === SimBillingDisplayStatus.ACTIVE
              ? COLOR.GREEN_1600
              : COLOR.RED_1000
          }
        >
          {statusInfo}
        </Typography>
      )}
    </Flex>
  )
}
