import { useMemo } from 'react'
import { NetworkStatus } from '@apollo/client'
import dayjs from 'dayjs'

import {
  GetCashflowCategoriesNetAmountsQueryVariables,
  useGetCashflowCategoriesNetAmountsQuery,
} from './graphql/getCashflowCategoriesNetAmounts.generated'

interface UseCashflowCategoriesNetAmountsProps {
  skip?: boolean
  variables: GetCashflowCategoriesNetAmountsQueryVariables
}

export const useCashflowCategoriesNetAmounts = ({
  skip = false,
  variables,
}: UseCashflowCategoriesNetAmountsProps) => {
  const { loading, data, error, refetch, networkStatus } =
    useGetCashflowCategoriesNetAmountsQuery({
      skip,
      variables,
      fetchPolicy:
        dayjs(variables.date).month() < dayjs().month()
          ? 'cache-first'
          : 'cache-and-network',
    })

  const categoryNetAmounts = useMemo(() => {
    return data?.getCashFlowCategoriesNetAmounts.categoryNetAmounts ?? []
  }, [data?.getCashFlowCategoriesNetAmounts.categoryNetAmounts])

  return {
    error,
    isLoading:
      (loading || networkStatus === NetworkStatus.refetch) &&
      categoryNetAmounts.length === 0,
    categoryNetAmounts,
    refetch,
  }
}
