import { useCallback, useMemo, useState } from 'react'

import { page } from 'translations'

export const STATUS_ALL = 'ALL' as const

interface AllListItem {
  label: string
  value: typeof STATUS_ALL
}

type DataListItem<DataType> = { label: string; value: DataType }
type ListItem<DataType> = DataListItem<DataType> | AllListItem

interface Arguments<DataType> {
  data: DataType[]
  setData: (newData: DataType[]) => void
  dataItems: readonly DataListItem<DataType>[]
}

export const useAccountMultiselectWithAllElement = <DataType>({
  data,
  setData,
  dataItems,
}: Arguments<DataType>) => {
  const [noElementsSelected, setNoElementsSelected] = useState(false)

  const listItems = useMemo(
    () => [
      {
        label: page.accounts.transactionsList.filters.allItems,
        value: STATUS_ALL,
      },
      ...dataItems,
    ],
    [dataItems]
  )

  const areAllItemsSelected = useMemo(
    () => data.length === dataItems.length,
    [data, dataItems]
  )
  const selectedItems = useMemo(
    () =>
      listItems.filter(({ value }) => {
        if (noElementsSelected) {
          return false
        }

        if (value === STATUS_ALL) {
          return areAllItemsSelected
        }

        return data.includes(value)
      }),
    [areAllItemsSelected, data, listItems, noElementsSelected]
  )

  const onChange = useCallback(
    (newSelectedItems: ListItem<DataType>[]) => {
      const hasAllSelected = newSelectedItems.some(
        ({ value }) => value === STATUS_ALL
      )
      const hasNoneSelected = newSelectedItems.length === 0
      setNoElementsSelected(hasNoneSelected)

      if (hasAllSelected || hasNoneSelected) {
        setData(dataItems.map(({ value }) => value))
        return
      }

      const newSelectedItemsWithoutAll = newSelectedItems.filter(
        (listItem): listItem is DataListItem<DataType> =>
          listItem.value !== STATUS_ALL
      )

      setData(newSelectedItemsWithoutAll.map(({ value }) => value))
    },
    [dataItems, setData]
  )

  const onClose = useCallback(() => {
    if (selectedItems.length === 0) {
      setNoElementsSelected(false)
    }
  }, [selectedItems.length])

  return {
    listItems,
    selectedItems,
    onChange,
    onClose,
    areAllItemsSelected,
    noElementsSelected,
    setNoElementsSelected,
  }
}
