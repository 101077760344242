import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledUserInvitationPendingContainer = styled.div`
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin-bottom: 2.5rem;
  }
`
