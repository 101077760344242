import {
  BillStatus,
  DebitCardAccountStatus,
  DebitCardTransactionStatusV2,
  DepositStatus,
  TransactionStatus,
} from '@npco/mp-gql-types'

// Some statuses are visual only based on boolean flag. That's why we introdue extension of transaction statuses
export enum TransactionStatusLocal {
  REFUNDED = 'REFUNDED',
  CHARGEBACK = 'CHARGEBACK',
  PARTIAL_REFUND = 'PARTIAL REFUND',
}
export type TransactionStatusExtended =
  | TransactionStatus
  | TransactionStatusLocal

export type TransactionStatusVariants = {
  [key in TransactionStatusExtended]: BadgeProps
}

export type DebitCardAccountStatusVariants = {
  [key in DebitCardAccountStatus]: BadgeProps
}

export type DebitCardTransactionStatusVariants = {
  [key in DebitCardTransactionStatusV2]: BadgeProps
}

export type DepositStatusVariants = {
  [key in DepositStatus]: BadgeProps
}

export type SimBillStatusVariants = {
  [key in BillStatus]: BadgeProps
}

interface BadgeProps {
  icon?: React.ReactNode
  text: string
  color: string
}
