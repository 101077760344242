import { gql } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'

import { AddressMap } from 'components/AddressMap'

import { SubcategoryItem } from '../SubcategoryItem'
import { getContactOrMerchantLocationData } from '../utils/contacts.utils'
import { BusinessContactDetails } from './BusinessContactDetails'
import {
  ContactContentContactFragment,
  ContactContentMerchantDetailsFragment,
} from './ContactContent.generated'
import { GoToContacts } from './GoToContacts/GoToContacts'
import { CategoryItem } from './Items/CategoryItem'
import { PersonContactDetails } from './PersonContactDetails'

interface ContactContentProps {
  merchant?: ContactContentMerchantDetailsFragment | null
  contact: ContactContentContactFragment | null
  onClose?: () => void
}

export const ContactContent = ({
  merchant,
  contact,
  onClose,
}: ContactContentProps) => {
  const { location, locationAccuracy } = getContactOrMerchantLocationData({
    contact,
    merchant,
  })

  const isPersonContact = contact?.contactType === ContactType.PERSON
  const category = contact?.category || null
  const subcategoryId = contact?.subcategoryUuid || null

  return (
    <>
      <AddressMap
        location={location || null}
        locationAccuracy={locationAccuracy}
      />
      {isPersonContact ? (
        <PersonContactDetails merchant={merchant} contact={contact} />
      ) : (
        <BusinessContactDetails merchant={merchant} contact={contact} />
      )}
      <CategoryItem category={category} />
      <SubcategoryItem category={category} subcategoryId={subcategoryId} />

      {contact && (
        <Box mt="2.375rem">
          <GoToContacts contact={contact} onNavigate={onClose} />
        </Box>
      )}
    </>
  )
}

ContactContent.fragments = {
  MerchantDetails: gql`
    fragment ContactContentMerchantDetailsFragment on MerchantDetails {
      ...GetContactOrMerchantLocationDataMerchantDetailsFragment
      ...PersonContactDetailsMerchantDetailsFragment
      ...BusinessContactDetailsMerchantDetailsFragment
    }

    ${getContactOrMerchantLocationData.fragments.MerchantDetails}
    ${PersonContactDetails.fragments.MerchantDetails}
    ${BusinessContactDetails.fragments.MerchantDetails}
  `,
  Contact: gql`
    fragment ContactContentContactFragment on Contact {
      ...GetContactOrMerchantLocationDataContactFragment
      contactType
      category
      subcategoryUuid
      ...PersonContactDetailsContactFragment
      ...BusinessContactDetailsContactFragment
      ...GoToContactsContactFragment
    }

    ${getContactOrMerchantLocationData.fragments.Contact}
    ${PersonContactDetails.fragments.Contact}
    ${BusinessContactDetails.fragments.Contact}
    ${GoToContacts.fragments.Contact}
  `,
}
