import { useCallback } from 'react'
import { Navigate, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'

import {
  SelfieVerification,
  useSelectedDocument,
} from 'components/SelfieVerification'

import { translations } from './BiometricsOnfidoPageTemplate.i18n'

export const BiometricsOnfidoPageTemplateDeps = {
  SelfieVerification,
}

type Props = {
  onSubmitRoute: string
  onNoSelectedDocumentRoute: string
  onChooseDifferentIDRoute: string
}

export const BiometricsOnfidoPageTemplate = ({
  onSubmitRoute,
  onNoSelectedDocumentRoute,
  onChooseDifferentIDRoute,
}: Props) => {
  const { SelfieVerification } = BiometricsOnfidoPageTemplateDeps
  const t = useTranslations(translations)

  const navigate = useNavigate()

  const onNext = useCallback(
    () => navigate(onSubmitRoute),
    [navigate, onSubmitRoute]
  )

  const navigateToChooseADifferentID = useCallback(
    () =>
      navigate(onChooseDifferentIDRoute, { state: { animation: 'backward' } }),
    [navigate, onChooseDifferentIDRoute]
  )

  const { selectedDocument } = useSelectedDocument()
  if (!selectedDocument) {
    return <Navigate to={onNoSelectedDocumentRoute} replace />
  }

  return (
    <SelfieVerification
      selectedDocument={selectedDocument}
      onNext={onNext}
      navigateToChooseADifferentID={navigateToChooseADifferentID}
      mobileCompleteMessage={t('mobileCompleteMessage')}
      mobileCompleteButtonText={t('mobileCompleteButtonText')}
    />
  )
}
