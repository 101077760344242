import { Box } from '@npco/zeller-design-system'
import { useWatchOnboardingSaveData } from 'features/OnboardingApp/shared/useWatchOnboardingSaveData'
import { Field, FieldProps, useFormikContext } from 'formik'

import { SettledSumValue } from 'types/onboarding'
import { Radiobutton, RadiobuttonLabel } from 'components/Input/Radiobutton'
import { page } from 'translations'

export interface FormValues {
  settledSum?: SettledSumValue
}

export const SettledSumFieldsGroup = () => {
  const { values } = useFormikContext<FormValues>()
  useWatchOnboardingSaveData({
    helperFields: { settledSum: values.settledSum },
  })

  return (
    <Box mb="2.5rem">
      <Field name="settledSum" type="radio" value={SettledSumValue.LessThan10k}>
        {({ field }: FieldProps<FormValues>) => (
          <Box mb="0.3rem">
            <RadiobuttonLabel isChecked={field.checked}>
              <Radiobutton {...field} />
              {page.onboarding.settledSum.lessThan10k}
            </RadiobuttonLabel>
          </Box>
        )}
      </Field>
      <Field
        type="radio"
        name="settledSum"
        value={SettledSumValue.MoreOrEqual10k}
      >
        {({ field }: FieldProps<FormValues>) => (
          <RadiobuttonLabel isChecked={field.checked}>
            <Radiobutton {...field} />
            {page.onboarding.settledSum.moreOrEqual10k}
          </RadiobuttonLabel>
        )}
      </Field>
    </Box>
  )
}
