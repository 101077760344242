import { useEffect, useMemo, useRef } from 'react'
import { ContactType, LabelType } from '@npco/mp-gql-types'
import { InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { LabelControl } from 'components/LabelControl'
import { page } from 'translations'

import { ContactFormData } from '../../Contacts.types'
import {
  getContactFormValues,
  validateContactEmail,
  validateEmailDuplication,
} from '../../Contacts.utils'

const getContactValuesMap = (fieldIndex: number) => ({
  [ContactType.BUSINESS]: {
    labelType: LabelType.BUSINESS,
    emailName: `business.additionalEmails.[${fieldIndex}].email`,
    labelName: `business.additionalEmails.[${fieldIndex}].label`,
  },
  [ContactType.PERSON]: {
    labelType: LabelType.PERSON,
    emailName: `person.additionalEmails.[${fieldIndex}].email`,
    labelName: `person.additionalEmails.[${fieldIndex}].label`,
  },
})

interface AdditionalEmailInputFieldProps {
  contactType: ContactType
  fieldIndex?: number
}

export const AdditionalEmailInputField = ({
  contactType,
  fieldIndex = 0,
}: AdditionalEmailInputFieldProps) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null)

  const { setFieldTouched, setFieldValue, values } =
    useFormikContext<ContactFormData>()

  const contactValues = getContactFormValues(contactType, values)

  const { additionalEmails, email } = contactValues

  const { labelType, emailName, labelName } =
    getContactValuesMap(fieldIndex)[contactType]

  const labelValue = contactValues.additionalEmails[fieldIndex]?.label
  const labelItem = labelValue
    ? {
        id: labelValue.id,
        isEditable: labelValue.isEditable,
        label: labelValue.labelText,
        value: labelValue.labelText,
      }
    : null

  const memoizedAdditionalEmails = useMemo(() => {
    return additionalEmails
      .filter((_, index) => index !== fieldIndex)
      .concat(email)
  }, [additionalEmails, email, fieldIndex])

  const currentValue = additionalEmails[fieldIndex]?.email

  useEffect(() => {
    const isDuplicateWithAnyEmail = validateEmailDuplication(
      currentValue,
      memoizedAdditionalEmails
    )

    if (!isDuplicateWithAnyEmail) {
      return
    }

    setFieldTouched(emailName)
  }, [
    memoizedAdditionalEmails,
    email,
    fieldIndex,
    emailName,
    setFieldTouched,
    currentValue,
  ])

  return (
    <InputAdaptiveField
      name={emailName}
      // NOTE: additional email label is 'Email' rather than 'Additional
      // email'
      label={page.contacts.fields.email}
      renderRightControls={() => (
        <LabelControl
          labelItem={labelItem}
          labelType={labelType}
          popperWrapperRef={wrapperRef}
          setLabel={(nextLabel) => setFieldValue(labelName, nextLabel)}
        />
      )}
      wrapperRef={wrapperRef}
      validate={(value) =>
        validateContactEmail(value, memoizedAdditionalEmails)
      }
    />
  )
}
