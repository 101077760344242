import { Flex } from '@npco/zeller-design-system'

import { ConfirmButton } from 'components/Modal/ModalElements/ModalElements'
import { StyledChangeButton } from 'components/ModalUploadLogo/ModalUploadLogo.styled'

interface ModalUploadFooterProps {
  isPrimaryButtonDisabled: boolean
  primaryButtonLabel: React.ReactNode
  secondaryButtonLabel: React.ReactNode
  primaryButtonOnClick: () => void
  secondaryButtonOnClick: () => void
}

export const ModalUploadFooter = ({
  secondaryButtonLabel,
  primaryButtonLabel,
  isPrimaryButtonDisabled,
  primaryButtonOnClick,
  secondaryButtonOnClick,
}: ModalUploadFooterProps) => {
  return (
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      mb="0.25rem"
      mt="1.5rem"
    >
      <StyledChangeButton onClick={secondaryButtonOnClick}>
        {secondaryButtonLabel}
      </StyledChangeButton>
      <ConfirmButton
        disabled={isPrimaryButtonDisabled}
        onClick={primaryButtonOnClick}
      >
        {primaryButtonLabel}
      </ConfirmButton>
    </Flex>
  )
}
