import { useCallback, useEffect, useState } from 'react'
import { SelectSize, SelectStandard } from '@npco/zeller-design-system'
import { SelectSelectedItem } from 'design-system/Components/Select'
import { useField } from 'formik'

import { translate } from 'utils/translations'

import { ITEM_TAX_APPLICABLE_FIELD } from '../../ItemBaseModalForm.constants'
import {
  ItemTaxApplicableItems,
  ItemTaxSelectedItem,
} from '../../ItemBaseModalForm.types'

export const translations = {
  label: translate('page.item.formSections.itemTaxLabel'),
  noTax: translate('page.item.formSections.itemNoTax'),
  taxApplicable: translate('page.item.formSections.itemTaxApplicable'),
}

const taxItems = [
  {
    value: ItemTaxApplicableItems.NoTax,
    label: translations.noTax,
  },
  {
    value: ItemTaxApplicableItems.TaxApplicable,
    label: translations.taxApplicable,
  },
]

export const ItemTaxApplicable = () => {
  const [field, , helpers] = useField(ITEM_TAX_APPLICABLE_FIELD)

  const [selectedItem, setSelectedItem] = useState<ItemTaxSelectedItem | null>(
    null
  )

  useEffect(() => {
    if (!field.value) {
      setSelectedItem(null)
      return
    }

    const nextSelectedItem = taxItems.find((item) => item.value === field.value)

    setSelectedItem(nextSelectedItem ?? null)
  }, [field.value])

  const handleChange = useCallback(
    (item: SelectSelectedItem<ItemTaxSelectedItem>) => {
      helpers.setValue(item?.value)
    },
    [helpers]
  )

  return (
    <SelectStandard
      items={taxItems}
      label={translations.label}
      mobileLabel={translations.label}
      onChange={handleChange}
      selectedItem={selectedItem}
      shouldAutoFocusControl={false}
      size={SelectSize.Small}
    />
  )
}
