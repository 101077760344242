import { useCallback } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { EntityInput, OnboardingStatus } from '@npco/mp-gql-types'
import { CustomerType as LoggedInUserData } from '@npco/mp-utils-logged-in-user'
import {
  rvEntityDetails,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { rvPathBeforeLoggingIn } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { DEFAULT_AFTER_LOGIN_ROUTE, ROOT } from 'const/routes'
import { getIsEntityOnboarded } from 'utils/onboardingStatus'

import { useUpdateEntityMutation } from './graphql/updateEntity.generated'
import { useTrackOnboardingErrors } from './useTrackOnboardingErrors'

export const useOnboardingEntityStatus = (
  onboardingStatusForError?: OnboardingStatus
) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { trackOnboardingErrors, setTrackOnboardingErrorsDataPayload } =
    useTrackOnboardingErrors()
  const shortEntityId = useSelectedShortEntityUuid()
  const [saveLastEntityStatus, { loading }] = useUpdateEntityMutation({
    onError: (err) => {
      trackOnboardingErrors(err, onboardingStatusForError)
    },
  })

  const saveLastOnboardingEntityStatus = useCallback(
    (
      onboardingStatus: OnboardingStatus,
      entityId?: string,
      additionalPayload: Omit<EntityInput, 'id'> = {}
    ) => {
      saveLastEntityStatus({
        variables: {
          entity: {
            id: entityId ?? rvEntityDetails().id,
            onboardingStatus,
            ...additionalPayload,
          },
        },
      })
      rvEntityDetails({
        ...rvEntityDetails(),
        onboardingStatus,
      })

      setTrackOnboardingErrorsDataPayload({ onboardingStatus })
    },
    [saveLastEntityStatus, setTrackOnboardingErrorsDataPayload]
  )

  const navigateUserAccordingToOnboardingStatus = useCallback(
    (
      entityData: EntityInput | undefined,
      loggedInUserData?: LoggedInUserData
    ) => {
      const onboardingState =
        entityData?.onboardingStatus ??
        rvEntityDetails().onboardingStatus ??
        OnboardingStatus.NONE

      switch (onboardingState) {
        case OnboardingStatus.NONE:
        case OnboardingStatus.ONBOARDED:
        case OnboardingStatus.RC_ONBOARDED: {
          if (!loggedInUserData?.phoneVerified) {
            navigate(ROOT.REGISTER_PHONE.path)
            return
          }

          if (getIsEntityOnboarded(entityData)) {
            const existingPathIsInPortal = Boolean(
              matchPath(`${ROOT.ORGS.ORG().path}/*`, location.pathname)
            )

            const logInRoute = rvPathBeforeLoggingIn()
              ? rvPathBeforeLoggingIn() + location.search
              : DEFAULT_AFTER_LOGIN_ROUTE(shortEntityId)

            if (!existingPathIsInPortal) {
              navigate(logInRoute, { state: location.state })
              return
            }
            return
          }

          navigate(ROOT.ONBOARDING.WELCOME_BACK.path)
          return
        }

        case OnboardingStatus.REVIEW:
        case OnboardingStatus.RC_REVIEW:
          navigate(ROOT.ONBOARDING.BUSINESS_REVIEW.path)
          return
        case OnboardingStatus.RC_ABANDONED:
          navigate(ROOT.LOGIN_ERROR_ENTITY_ABANDONED.path)
          return
        case OnboardingStatus.RC_REJECTED:
          navigate(ROOT.LOGIN_ERROR_ONBOARDING_STATUS.path)
          return
        case OnboardingStatus.RC_DEPLATFORMED:
          navigate(ROOT.LOGIN_ERROR_ENTITY_DISABLED.path)
          return
        default:
          navigate(ROOT.ONBOARDING.WELCOME_BACK.path)
      }
    },
    [
      navigate,
      location.pathname,
      location.search,
      location.state,
      shortEntityId,
    ]
  )

  return {
    navigateUserAccordingToOnboardingStatus,
    saveLastOnboardingEntityStatus,
    isSavingLastOnboardingEntityStatus: loading,
  }
}
