import { memo } from 'react'
import { ListCardButton, SkeletonBasic } from '@npco/zeller-design-system'

import { LoadingAvatar, LoadingContainer } from './VenueListLoading.styled'

const venues = [
  { id: 1, width: '208px' },
  { id: 2, width: '95px' },
  { id: 3, width: '144px' },
  { id: 4, width: '208px' },
  { id: 5, width: '95px' },
  { id: 6, width: '144px' },
  { id: 7, width: '208px' },
  { id: 8, width: '95px' },
  { id: 9, width: '144px' },
]

export const VenueListLoading = memo(() => {
  return (
    <>
      {venues.map((venue) => (
        <LoadingContainer key={venue.id}>
          <ListCardButton
            readonly
            image={LoadingAvatar}
            title={
              <SkeletonBasic height="12px" width={venue.width} isRounded />
            }
          />
        </LoadingContainer>
      ))}
    </>
  )
})
