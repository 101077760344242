import { BaseYAxisChartTick } from '../BaseYAxisChartTick/BaseYAxisChartTick'

interface Props extends React.SVGProps<SVGTextElement> {
  payload: {
    value: number
  }
  tickFormatter: (value: number) => string
  isZero?: boolean
}

export const CustomYAxisChartTick = ({
  payload,
  tickFormatter,
  fontSize = '14px',
  isZero = false,
  ...rest
}: Props) => (
  <BaseYAxisChartTick
    {...rest}
    payload={payload}
    tickFormatter={tickFormatter}
    fontSize={fontSize}
    isZero={isZero}
    x={0}
    textAnchor="start"
    dy="0.355em"
  />
)
