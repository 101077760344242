import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from '../../../../const/routes'
import { InternalTransferState } from '../../Transfer.types'

export const useGoToInternalTransfer = () => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()

  const createState = useCallback(
    (state: InternalTransferState | null) => ({
      Transfer: state,
    }),
    []
  )

  const goToInternalTransfer = useCallback(
    (state: InternalTransferState | null) => {
      history.push(
        ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.TRANSFER.path,
        createState(state)
      )
    },
    [history, shortEntityId, createState]
  )

  return { goToInternalTransfer, createState }
}
