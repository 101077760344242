import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ShopIcon } from 'assets/svg/shop-icon.svg'
import { EXTERNAL_SHOP } from 'const/externalLinks'
import { translate } from 'utils/translations'

import { SidebarItemExternal } from '../components/Item/ItemExternal'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const ShopItem = () => {
  const { isFolded } = useSidebarLocalCache()

  return (
    <SidebarItemExternal
      icon={
        <SvgIcon>
          <ShopIcon />
        </SvgIcon>
      }
      isFolded={isFolded}
      href={EXTERNAL_SHOP}
      title={translate('component.sidebar.items.shop')}
    >
      {translate('component.sidebar.items.shop')}
    </SidebarItemExternal>
  )
}
