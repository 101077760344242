import { useMemo } from 'react'
import { ApolloError, gql } from '@apollo/client'
import { DateRange } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox } from '@npco/zeller-design-system'

import { AccountBalanceAreaChart } from './AccountBalanceAreaChart'
import { accountBalanceChartTranslations } from './AccountBalanceChart.i18n'
import {
  getAccountBalanceForChart,
  getAccountBalancePlaceholderForChart,
} from './AccountBalanceChart.utils'
import { AccountBalanceChartError } from './AccountBalanceChartError'
import { AccountBalanceChartHeader } from './AccountBalanceChartHeader'
import { AccountBalanceEmptyMessage } from './AccountBalanceEmptyMessage'

type AccountBalanceChartViewProps = {
  accountBalances: any[]
  error: ApolloError | undefined
  isLoadingWithoutCache: boolean
  refetch: () => void
  dateRange: DateRange
  isTimeZoneUndefined: boolean
}

export const AccountBalanceChartView = ({
  accountBalances,
  error,
  isLoadingWithoutCache,
  refetch,
  dateRange,
  isTimeZoneUndefined,
}: AccountBalanceChartViewProps) => {
  const t = useTranslations(accountBalanceChartTranslations)

  const isNoData = useMemo(
    () => accountBalances.every((balance) => balance.balance.value === '0'),
    [accountBalances]
  )

  if (error && !isLoadingWithoutCache) {
    return <AccountBalanceChartError retry={refetch} />
  }

  return (
    <>
      {!isLoadingWithoutCache && isNoData && <AccountBalanceEmptyMessage />}
      <Box pb="16px">
        <AccountBalanceChartHeader />
      </Box>
      <AccountBalanceAreaChart
        balances={getAccountBalanceForChart(accountBalances, dateRange)}
        placeholderBalances={getAccountBalancePlaceholderForChart(dateRange)}
        isLoading={isLoadingWithoutCache}
      />
      {isTimeZoneUndefined && (
        <Box pt="32px">
          <InfoBox>
            <InfoBox.Message>{t('timeZoneWarning')}</InfoBox.Message>
          </InfoBox>
        </Box>
      )}
    </>
  )
}

AccountBalanceChartView.fragments = {
  DebitCardAccountBalance: gql`
    fragment AccountBalanceChartViewDebitCardAccountBalanceFragment on DebitCardAccountBalance {
      ...AccountBalanceAreaChartDebitCardAccountBalanceFragment
    }

    ${AccountBalanceAreaChart.fragments.DebitCardAccountBalance}
  `,
}
