import { SourceFilter } from '@npco/mp-gql-types'
import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  sourcesLabel: 'Source',
  DASHBOARD: 'Dashboard',
  POINT_OF_SALE: 'Point of Sale',
  INVOICE: 'Invoice',
  TERMINAL: 'Zeller Terminal',
  ZELLER_APP: 'Zeller App',
} satisfies Record<Exclude<SourceFilter, 'ZELLER_ADMIN'> | 'sourcesLabel', string>)
