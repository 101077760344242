import { CASHFLOW_REPORTING } from '@npco/mp-feature-cashflow-reporting'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'

import { ROOT } from 'const/routes'

import { translations } from '../Sidebar.i18n'
import { SubItem } from '../Sidebar.types'

export const useReportsSubitems = (): SubItem[] => {
  const t = useTranslations(translations)
  const shortEntityId = useSelectedShortEntityUuid()

  return [
    {
      subitemTitle: t('sales'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).REPORTS.SALES.path,
    },
    {
      subitemTitle: t('cashflow'),
      subitemPath: CASHFLOW_REPORTING(shortEntityId).path,
    },
  ]
}
