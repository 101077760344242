import { Flex, Tabs } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { BalanceRoutes } from './BalanceRoutes'
import { useDashboardTabs } from './hooks/useDashboardTabs'

export const DashboardTabs = () => {
  const isMobile = useIsMobileResolution()
  const { tabs, currentTab } = useDashboardTabs()

  return (
    <Flex justifyContent="space-between">
      <Tabs currentTab={currentTab}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.value} {...tab} />
        ))}
      </Tabs>

      {!isMobile && (
        <Flex mr={{ SM: '0.5rem', MD: '1.5rem', LG: '2.5rem' }}>
          <BalanceRoutes />
        </Flex>
      )}
    </Flex>
  )
}
