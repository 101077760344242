import { AvatarBasic } from '@npco/zeller-design-system'

import { ReactComponent as BPayLogo } from 'assets/svg/logos/Bpay.svg'

export const BpayAvatar = () => (
  <AvatarBasic
    text=""
    svgBackground="#183168"
    userDefinedContent={<BPayLogo />}
  />
)
