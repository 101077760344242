import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const useStateIfMounted: typeof useState = (
  ...useStateArgs: Parameters<typeof useState>
): ReturnType<typeof useState> => {
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const [state, setState] = useState(...useStateArgs)
  const setStateIfMounted: typeof setState = useCallback((...setStateArgs) => {
    if (mounted.current) {
      setState(...setStateArgs)
    }
  }, [])

  const stateTuple = useMemo(
    () => [state, setStateIfMounted] as ReturnType<typeof useState>,
    [state, setStateIfMounted]
  )
  return stateTuple
}
