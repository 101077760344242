import {
  BUTTON_SIZE,
  ButtonGhost,
  ButtonGhostType,
} from '@npco/zeller-design-system'
import { omit } from 'ramda'

import { shared } from 'translations'

interface ButtonBackProps extends ButtonGhostType {
  label?: string
}
export const ButtonBack = ({ label, ...buttonProps }: ButtonBackProps) => {
  return (
    <ButtonGhost
      size={BUTTON_SIZE.MEDIUM}
      type="button"
      data-testid="back-button"
      {...omit(['size'], buttonProps)}
      fullWidth
    >
      {label ?? shared.cancel}
    </ButtonGhost>
  )
}
