import { KycStatus } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'

// Add RC_VERIFIED when backend schema is ready
export const VERIFIED_STATUSES = [KycStatus.VERIFIED]

export const IN_REVIEW_STATUSES = [KycStatus.IN_REVIEW, KycStatus.RC_ABANDONED]

export const UNVERIFIED_STATUSES = [
  KycStatus.REQUIRED,
  KycStatus.RC_REJECTED,
  KycStatus.NOT_REQUIRED,
  null,
]

export const REVIEW_ALLOWED_ROUTES = [
  ROOT.ORGS.ORG().KYC.REVIEW_DOCUMENT_UPLOAD.path,
  ROOT.ORGS.ORG().KYC.DOCUMENT_UPLOAD.path,
  ROOT.ORGS.ORG().KYC.FINALISE.path,
  ROOT.ORGS.ORG().KYC.UPLOAD_SUCCESS.path,
]
