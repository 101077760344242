import {
  Flex,
  SkeletonAvatar,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

type SidebarItemSkeletonProps = {
  isFolded: boolean
}

export const SidebarItemSkeleton = ({ isFolded }: SidebarItemSkeletonProps) => {
  return (
    <Flex
      alignItems="center"
      marginLeft="10px"
      gap="16px"
      height="40px"
      marginBottom="8px"
      data-testid="sidebar-item-skeleton"
    >
      <SkeletonAvatar width="20px" height="20px" />
      {!isFolded && (
        <SkeletonTypographyBase type={TypographyTypes.H5} width="140px" />
      )}
    </Flex>
  )
}
