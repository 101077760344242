import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button } from 'components/Buttons/Button'

export const StyledButtonSpecial = styled(Button)`
  background-color: ${({ theme }) => theme.colors.GREY_30};
  border-color: ${({ theme }) => theme.colors.GREY_30};
  color: ${({ theme }) => theme.colors.BLUE_1000};

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    display: none;
  }

  &:hover,
  &:active,
  &:disabled,
  &:focus-within {
    background-color: ${({ theme }) => theme.colors.GREY_60};
    border-color: ${({ theme }) => theme.colors.GREY_60};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.BLUE_1000};
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.GREY_250};
  }
`
