import currency from 'currency.js'
import { getAllContactEmails } from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { uniq } from 'ramda'

type GetContact = ContactCoreFieldsFragment

type ContactEmailFields = Pick<GetContact, 'email' | 'additionalEmails'>

export const getAllCustomerEmails = (
  payer: ContactEmailFields | null,
  attentionTo: ContactEmailFields | null = null
) => {
  if (!payer) {
    return []
  }

  const payerEmails = getAllContactEmails(payer)
  const attentionToEmails = getAllContactEmails(attentionTo)

  return uniq(
    payerEmails
      .concat(attentionToEmails)
      .sort(({ email: email1 }, { email: email2 }) => {
        if (email1 < email2) {
          return -1
        }
        if (email1 > email2) {
          return 1
        }

        return 0
      })
      .map(({ email, label }) => ({
        id: email,
        label: email,
        subLabel: label,
        value: email,
      }))
  )
}

export const getCurrencyAmountFormatted = (value: string) =>
  currency(currency(value, { fromCents: true, precision: 4 })).format()

export const fadeInHeightAnimationVariants = {
  intro: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.3 },
  },
  enter: {
    opacity: 1,
    height: 'auto',
    transition: {
      duration: 0.3,
      opacity: {
        delay: 0.1,
      },
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.3, height: { delay: 0.1 } },
  },
}
