import { useState } from 'react'
import { Location, useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { rvSelectedTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'

import { Transaction, TransactionInList } from 'types/transactions'

type MaybeTransactionState = {
  transaction?: Transaction
}

const getTransactionFromLocationState = (location: Location) => {
  const transaction = (location.state as MaybeTransactionState)?.transaction

  return transaction ?? null
}

export const useSelectedTransaction = () => {
  const location = useLocation()
  const [areDetailsOpen, setAreDetailsOpen] = useState(() =>
    Boolean(getTransactionFromLocationState(location))
  )

  const selectedTransaction = useReactiveVar(rvSelectedTransaction)

  const onOpen = (transaction: TransactionInList) => {
    setAreDetailsOpen(true)
    rvSelectedTransaction({ ...transaction, subscriptionUpdatedAt: '' })
  }

  const onClose = () => {
    setAreDetailsOpen(false)
    rvSelectedTransaction(null)
  }

  return {
    areDetailsOpen,
    selectedTransaction,
    onOpen,
    onClose,
  }
}
