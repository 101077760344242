import { EntityCategories } from '@npco/mp-gql-types'

import { translate } from 'utils/translations'

export const getEntityCategory = (entityCategory: string | null) => {
  switch (entityCategory) {
    case EntityCategories.ADVERTISING:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.advertising'
      )
    case EntityCategories.BANK_FEES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.bankFees'
      )
    case EntityCategories.CLEANING:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.cleaning'
      )
    case EntityCategories.COMMISSION:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.commission'
      )
    case EntityCategories.COMPUTER_EQUIPMENT:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.computerEquipment'
      )
    case EntityCategories.CONSULTING_ACCOUNTING:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.consultingAccounting'
      )
    case EntityCategories.COST_OF_GOODS_SOLD:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.costOfGoodsSold'
      )
    case EntityCategories.ENTERTAINMENT:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.entertainment'
      )
    case EntityCategories.FREIGHT_COURIER:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.freightCourier'
      )
    case EntityCategories.GENERAL_EXPENSES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.generalExpenses'
      )
    case EntityCategories.INCOME_TAX_EXPENSE:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.incomeTaxExpense'
      )
    case EntityCategories.INSURANCE:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.insurance'
      )
    case EntityCategories.INTEREST_EXPENSE:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.interestExpense'
      )
    case EntityCategories.LEGAL_EXPENSES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.legalExpenses'
      )
    case EntityCategories.LIGHT_POWER_HEATING:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.lightPowerHeating'
      )
    case EntityCategories.MOTOR_VEHICLE_EXPENSES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.motorVehicleExpenses'
      )
    case EntityCategories.OFFICE_EQUIPMENT:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.officeEquipment'
      )
    case EntityCategories.OFFICE_EXPENSES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.officeExpenses'
      )
    case EntityCategories.PRINTING_STATIONERY:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.printingStationery'
      )
    case EntityCategories.PURCHASES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.purchases'
      )
    case EntityCategories.RENT:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.rent'
      )
    case EntityCategories.SUBSCRIPTIONS:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.subscriptions'
      )
    case EntityCategories.SUPERANNUATION:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.superannuation'
      )
    case EntityCategories.TELEPHONE_INTERNET:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.telephoneInternet'
      )
    case EntityCategories.TRAVEL_INTERNATIONAL:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.travelInternational'
      )
    case EntityCategories.TRAVEL_NATIONAL:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.travelNational'
      )
    case EntityCategories.WAGES_SALARIES:
      return translate(
        'page.transactionDetails.accounting.accountingCategory.wagesSalaries'
      )
    default:
      return ''
  }
}
