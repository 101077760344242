import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'

import { DebitCardUpdate_onDebitCardUpdate as DebitCardUpdateType } from 'types/gql-types/DebitCardUpdate'

export const useCacheCard = () => {
  const { cache } = useApolloClient()

  const cacheCardUpdate = useCallback(
    (cardUpdate: DebitCardUpdateType) => {
      cache.modify({
        id: cache.identify({
          id: cardUpdate.id,
          __typename: 'DebitCardV2',
        }),
        fields: {
          name() {
            return cardUpdate.name
          },
          status() {
            return cardUpdate.status
          },
        },
      })
    },
    [cache]
  )

  return { cacheCardUpdate }
}
