import { TableData } from '@npco/ui-table'

import { SimTableFragment } from './SimTable.generated'
import { SimDeviceCell } from './TableCells/SimDeviceCell'
import { SimIconCell } from './TableCells/SimIconCell'
import { SimIdCell } from './TableCells/SimIdCell'
import { SimInfoCell } from './TableCells/SimInfoCell'
import { SimSiteCell } from './TableCells/SimSiteCell'
import { SimStatusCell } from './TableCells/SimStatusCell'

export const columnsConfig = [
  {
    accessorId: 'id',
    cellComponent: SimIdCell<TableData<SimTableFragment>>,
    cellSize: { _: 0, xs: 5 },
  },
  {
    accessorId: 'siteName',
    cellComponent: SimSiteCell<TableData<SimTableFragment>>,
    cellSize: { _: 0, xs: 8 },
  },
  {
    accessorId: 'deviceName',
    cellComponent: SimDeviceCell<TableData<SimTableFragment>>,
    cellSize: { _: 0, xs: 8 },
  },
  {
    accessorId: 'simIcon',
    cellComponent: SimIconCell<TableData<SimTableFragment>>,
    cellSize: { _: 2, xs: 0 },
  },
  {
    accessorId: 'info',
    cellComponent: SimInfoCell<TableData<SimTableFragment>>,
    cellSize: { _: 14, xs: 0 },
  },
  {
    accessorId: 'status',
    cellComponent: SimStatusCell<TableData<SimTableFragment>>,
    cellSize: { _: 8, xs: 3 },
  },
]
