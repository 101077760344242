import styled, { css } from 'styled-components'

export const StyledImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  background-color: ${({ theme }) => theme.colors.GREY_30};

  ${css`
    animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
  `}
`
