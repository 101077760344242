import { useTranslations } from '@npco/utils-translations'

import {
  StyledContactHeaderButton,
  StyledContactHeaderContainer,
} from '../../../../ContactDropdown/ContactHeader.styled'
import { bpayTargetInputTranslations } from '../../../BPayTargetInput.i18n'

type AddBusinessButtonProps = {
  closeMenu: () => void
  onAddBusiness: () => void
}

export const AddBusinessButton = ({
  closeMenu,
  onAddBusiness,
}: AddBusinessButtonProps) => {
  const t = useTranslations(bpayTargetInputTranslations)

  return (
    <StyledContactHeaderContainer>
      <StyledContactHeaderButton
        onClick={() => {
          closeMenu()
          onAddBusiness()
        }}
      >
        {t('addBusinessButton')}
      </StyledContactHeaderButton>
    </StyledContactHeaderContainer>
  )
}
