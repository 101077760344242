import { useTranslations } from '@npco/utils-translations'

import { formatAccountNumber } from 'utils/common'

import {
  AvatarWithTextRow,
  DefaultRow,
  SectionHeading,
  TransferModal,
} from '../../../../TransferModal'
import { contactTransferModalTranslations } from '../../ContactTransferModal.i18n'
import { FromAccount } from '../../ContactTransferModal.types'

type FromSectionProps = {
  fromAccount: FromAccount
}

export const FromSection = ({ fromAccount }: FromSectionProps) => {
  const t = useTranslations(contactTransferModalTranslations)

  const fromAccountIcon = fromAccount.icon
    ? { ...fromAccount.icon, images: null, animation: null }
    : null

  return (
    <TransferModal.SectionWrapper aria-labelledby="from-section-header-id">
      <SectionHeading id="from-section-header-id">{t('from')}</SectionHeading>
      <AvatarWithTextRow
        avatar={{ icon: fromAccountIcon }}
        text={fromAccount.name}
      />
      <DefaultRow
        label={t('accountNumber')}
        value={formatAccountNumber(fromAccount.accountNumber)}
      />
    </TransferModal.SectionWrapper>
  )
}
