import { useMemo } from 'react'

import { useTransactionUpdateSubscription } from './hooks/useTransactionUpdateSubscription'
import { TransactionUpdateContext } from './TransactionUpdateContext'

interface Props {
  children?: React.ReactNode
}

export const TransactionUpdateSubscriptionProvider = ({ children }: Props) => {
  const { transactionUpdates$ } = useTransactionUpdateSubscription()

  // Do not re render with each new update
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ transactionUpdates$ }), [])

  return (
    <TransactionUpdateContext.Provider value={contextValue}>
      {children}
    </TransactionUpdateContext.Provider>
  )
}
