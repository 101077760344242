import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { DebitCardTransactionRowWrapper } from '../DebitCardTransactionRowWrapper/DebitCardTransactionRowWrapper'

export const Container = styled(DebitCardTransactionRowWrapper)`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 2fr 4fr;
  grid-template-rows: auto;
  grid-template-areas: 'company-avatar company-name category icons amount';
  grid-gap: 0.75rem;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: flex;
    justify-content: space-between;
    grid-template-columns: auto 1fr;
    padding: 1rem;
  }
`
