import { gql } from '@apollo/client'

export const GetTransactionsLocalState = gql`
  query GetTransactionsLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      transactions {
        filters {
          amount
          dates
          statuses
          types
          sources
          search
          terminals
          siteUuid
        }
        isFiltersVisible
      }
    }
  }
`
