import { BodySmall, Box, COLOR } from '@npco/zeller-design-system'

import { useVelocityControlLastModified } from './hooks/useVelocityControlLastModified'

export const LastModified = () => {
  const { lastModifiedText } = useVelocityControlLastModified()

  return (
    <Box minHeight="48px" color={COLOR.GREY_550}>
      {lastModifiedText && (
        <BodySmall data-testid="last-modified-text">
          {lastModifiedText}
        </BodySmall>
      )}
    </Box>
  )
}
