import { Box } from '@npco/zeller-design-system'

import { AccountStatementSkeleton } from './AccountStatementSkeleton'

export const AccountStatementsListSkeleton = () => {
  return (
    <div data-testid="account-statements-skeleton">
      <Box pb="4px">
        <AccountStatementSkeleton />
      </Box>
      <Box pb="4px">
        <AccountStatementSkeleton />
      </Box>
      <Box pb="4px">
        <AccountStatementSkeleton />
      </Box>
      <Box pb="4px">
        <AccountStatementSkeleton />
      </Box>
    </div>
  )
}
