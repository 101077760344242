import { DrawerNavigationProvider } from '@npco/ui-drawer-navigation'

import { PartialTransactionUpdateType } from 'hooks/transactions'
import { translate } from 'utils/translations'
import { DrawerHeader } from 'components/DrawerWithNavigation/DrawerNavigation/DrawerHeader/DrawerHeader'

import { useTransactionDrawerLayout } from './hooks/useTransactionDrawerLayout'
import { StyledDrawerWithNavigation } from './TransactionDrawer.styled'
import { DebitCardTransaction } from './TransactionDrawer.types'
import { TransactionDrawerContent } from './TransactionDrawerContent'

interface TransactionDrawerProps {
  transaction: DebitCardTransaction | null
  updateTransaction: (update: PartialTransactionUpdateType) => void
  isOpen: boolean
  onClose: () => void
  selectNextTransaction?: () => void
  selectPreviousTransaction?: () => void
}

export const TransactionDrawer = ({
  transaction,
  updateTransaction,
  isOpen,
  onClose,
  selectNextTransaction,
  selectPreviousTransaction,
}: TransactionDrawerProps) => {
  const { selectedTab, tabs } = useTransactionDrawerLayout({
    transaction,
    hasContact: Boolean(transaction?.contact),
  })

  return (
    <DrawerNavigationProvider
      onPrevious={selectPreviousTransaction}
      onNext={selectNextTransaction}
    >
      <StyledDrawerWithNavigation
        overlayClassName="animated-drawer-overlay"
        closeTimeoutMS={300}
        isOpen={isOpen}
        title={translate('page.accounts.transactionDetails.transactionHeader')}
        onClose={onClose}
        headerContent={
          <DrawerHeader
            animationKey={transaction?.id ?? ''}
            tabs={tabs}
            selectedTab={selectedTab}
            tagsGroupName="transactionDrawerTabs"
          />
        }
      >
        <TransactionDrawerContent
          selectedTab={selectedTab}
          transaction={transaction}
          updateTransaction={updateTransaction}
          onClose={onClose}
        />
      </StyledDrawerWithNavigation>
    </DrawerNavigationProvider>
  )
}
