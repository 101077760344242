import { BUTTON_SIZE } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { translate } from 'utils/translations'
import { FileListItemSelect } from 'components/File/File.types'

import { UploadDocumentsForm } from './Form/Form'
import { Values } from './Form/Form.types'
import { StyledButtonFilled } from './UploadDocuments.styled'
import { UploadDocuments as UploadDocumentsType } from './UploadDocuments.types'

interface UploadDocumentsProps extends UploadDocumentsType {
  onSubmit: (values: Values) => void
  persitedSubject: string
  optionItems?: FileListItemSelect['optionItems']
}

export const UploadDocuments = ({
  addFilesToQueue,
  failedFileNames,
  fileQueueItems,
  onSubmit,
  optionItems,
  persitedSubject,
  removeFileFromQueue,
  succeededFileQueueItems,
}: UploadDocumentsProps) => {
  return (
    <Formik<Values>
      onSubmit={(values) => onSubmit(values)}
      initialValues={{ subject: persitedSubject, fileItem: null }}
    >
      {({ handleSubmit, isSubmitting, submitForm, values, setFieldValue }) => (
        <>
          <UploadDocumentsForm
            addFilesToQueue={addFilesToQueue}
            failedFileNames={failedFileNames}
            fileQueueItems={fileQueueItems}
            onHandleSubmit={handleSubmit}
            optionItems={optionItems}
            removeFileFromQueue={(fileId: string) => {
              removeFileFromQueue(fileId)

              if (optionItems) {
                setFieldValue(`fileItem.${fileId}`, undefined, false)
              }
            }}
            succeededFileQueueItems={succeededFileQueueItems}
          />
          <StyledButtonFilled
            size={BUTTON_SIZE.LARGE}
            disabled={
              isSubmitting || fileQueueItems.length === 0 || !values.subject
            }
            onClick={submitForm}
          >
            {translate('shared.send')}
          </StyledButtonFilled>
        </>
      )}
    </Formik>
  )
}
