import {
  BodyLarge,
  BodySmall,
  Flex,
  H1,
  H2,
  H3,
  SvgIcon,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { PlaceholderAlignment, PlaceholderSize } from './Placeholders.types'

interface WithVariant {
  variant?: PlaceholderSize
}

const getIconBoxStyleByVariant = (variant: PlaceholderSize | undefined) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
      return css`
        width: 5rem;
        height: 5rem;
      `
    case PlaceholderSize.Medium:
    case PlaceholderSize.Small:
      return css`
        width: 3.5rem;
        height: 3.5rem;
      `
    default:
      return css`
        width: 5rem;
        height: 5rem;
      `
  }
}

export const StyledIconBox = styled(Flex)`
  ${(props: WithVariant) => getIconBoxStyleByVariant(props.variant)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
`

interface StyledTextContainerProps {
  alignment?: PlaceholderAlignment
}

export const StyledTextContainer = styled.div<StyledTextContainerProps>`
  margin: 2rem 0 2rem;
  text-align: ${({ alignment = PlaceholderAlignment.Center }) => alignment};
`

const TitleStyle = css`
  margin: 0 0 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledExtraLargeTitle = styled(H1)`
  ${TitleStyle}
`

export const StyledLargeTitle = styled(H2)`
  ${TitleStyle}
`

export const StyledMediumTitle = styled(H3)`
  ${TitleStyle}
`

const DescriptionStyle = css`
  color: ${({ theme }) => theme.colors.BLACK_900};
  max-width: 570px;
  margin: 0;
`

export const StyledExtraLargeDescription = styled(BodyLarge)`
  ${DescriptionStyle}
`

export const StyledMediumDescription = styled(BodySmall)`
  ${DescriptionStyle}
`

export const StyledWrapper = styled(Flex)`
  overflow: auto;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
`

const getSvgIconStyleByVariant = (variant: PlaceholderSize | undefined) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
      return css`
        width: 5rem;
        height: 5rem;
      `
    case PlaceholderSize.Medium:
    case PlaceholderSize.Small:
      return css`
        width: 3.5rem;
        height: 3.5rem;
      `
    default:
      return css`
        width: 5rem;
        height: 5rem;
      `
  }
}

export const StyledSvgIcon = styled(SvgIcon)`
  ${(props: WithVariant) => getSvgIconStyleByVariant(props.variant)};
`
