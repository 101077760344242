import { makeVar } from '@apollo/client'

import { tagPickerInitialValue } from 'const/common'
import { PickerItems } from 'types/picker'

import { ContactCoreFieldsFragment } from '../graphql/ContactCoreFields.generated'

type Contact = ContactCoreFieldsFragment

export const rvContacts = makeVar<Record<string, Contact | null> | null>(null)

export const rvAddedContacts = makeVar<string[]>([])

export const rvSelectedContact = makeVar<Contact | null>(null)

export const rvSelectedSubContact = makeVar<Contact | null>(null)

export const rvContactProfilePictureMap = makeVar<
  Record<string, string | null>
>({})

export const rvSelectedContactToTransfer = makeVar<Contact | null>(null)

export const rvSelectedTags = makeVar<PickerItems>(tagPickerInitialValue)

export const rvIsContactPaymentFiltersDisabled = makeVar<boolean>(false)
