import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type UpdateCachedCardFragment = {
  __typename?: 'DebitCardV2'
  name: string
  status: Types.DebitCardStatus
  velocityControl: {
    __typename?: 'VelocityControlType'
    velocityWindow: Types.VelocityWindowEnum
    resetsAt: any
    isPendingSubscriptionUpdate: boolean | null
    amountLimit: { __typename?: 'Money'; value: string }
    maxTransactionValue: { __typename?: 'Money'; value: string }
    totalSpentAmount: { __typename?: 'Money'; value: string }
    modifiedBy: {
      __typename?: 'VelocityControlModifiedByType'
      actingCustomerUuid: string
      updatedAt: any
    } | null
    availableAmount: { __typename?: 'Money'; value: string }
  } | null
}

export const UpdateCachedCardFragmentDoc = gql`
  fragment UpdateCachedCardFragment on DebitCardV2 {
    name
    status
    velocityControl {
      amountLimit {
        value
      }
      maxTransactionValue {
        value
      }
      totalSpentAmount {
        value
      }
      velocityWindow
      modifiedBy {
        actingCustomerUuid
        updatedAt
      }
      availableAmount {
        value
      }
      resetsAt
      isPendingSubscriptionUpdate @client
    }
  }
` as unknown as TypedDocumentNode<UpdateCachedCardFragment, undefined>
