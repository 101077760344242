import { useCallback } from 'react'
import { Box, ErrorMessageForm } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { type CreateItemCategoryMutationResponse } from './graphql/createItemCategory.generated'
import { ItemCategories } from './ItemCategories'

interface ItemCategoriesFieldProps {
  fieldName: string
}

export const ItemCategoriesField = ({
  fieldName,
}: ItemCategoriesFieldProps) => {
  const [field, meta, helpers] = useField(fieldName)

  const handleOnCreateSuccess = useCallback(
    async ({
      name,
      id,
    }: CreateItemCategoryMutationResponse['createItemCategory']) => {
      helpers.setValue([...field.value, { label: name, value: id }])
    },
    [field.value, helpers]
  )

  const hasError = Boolean(meta.touched) && Boolean(meta.error)

  return (
    <Box>
      <ItemCategories
        hasError={hasError}
        onChange={helpers.setValue}
        onClose={() => helpers.setTouched(true)}
        onCreateSuccess={handleOnCreateSuccess}
        selectedItems={field.value}
      />
      {hasError && (
        <ErrorMessageForm hasError={hasError} errorMessage={meta.error} />
      )}
    </Box>
  )
}
