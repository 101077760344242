import { useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useLocationState } from '@npco/utils-routing'
import { IdentityPhoneVerify } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/onboarding'
import { rvProfileSettings } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import {
  IdentityPhoneVerify as IdentityPhoneVerifyResponse,
  IdentityPhoneVerifyVariables,
} from 'types/gql-types/IdentityPhoneVerify'

import { ValidateCodeLocationStateSchema } from './ValidateCode.types'

export interface CodeProps {
  [key: string]: number | undefined | string
}

const initialCode: CodeProps = {
  code: '',
}

interface Args {
  onSubmitCallback: () => void
}

export const useValidateCode = ({ onSubmitCallback }: Args) => {
  const locationState = useLocationState(ValidateCodeLocationStateSchema)
  const [hasError, toggleHasError] = useState(false)

  const phone = locationState?.phone || ''
  const profileSettingsData = useReactiveVar(rvProfileSettings)

  const [identityPhoneVerify, { loading }] = useMutation<
    IdentityPhoneVerifyResponse,
    IdentityPhoneVerifyVariables
  >(IdentityPhoneVerify, {
    errorPolicy: 'all',
    onCompleted: (responseData) => {
      const codeVerified = responseData?.identityPhoneVerify?.codeVerified

      if (!codeVerified) {
        toggleHasError(true)
        return
      }

      rvProfileSettings({ ...profileSettingsData, phone })
      onSubmitCallback()
    },
    onError: () => toggleHasError(true),
  })

  const handleSubmit = (values: CodeProps) => {
    toggleHasError(false)
    identityPhoneVerify({
      variables: {
        code: `${values.code}`,
      },
    })
  }

  return { loading, handleSubmit, hasError, initialCode }
}
