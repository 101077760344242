import { z } from 'zod'

/**
 * @description
 * The most common Feature Flag value with default `false` value.
 */
const boolean = z.boolean().default(false)
const number = z.number().default(0)

/**
 * @description
 * This schema enumerates all local and remote feature flags for this project.
 * Any unknown flags from either Remote (LaunchDarkly) or Local (LocalStorage)
 * are ignored and are not accessible via the `useFeatureFlags` hook.
 *
 * Any flag can be remotely controlled if it is registered with the same name
 * in the Launch Darkly console.
 *
 * All flag value schemas _must_ have a `.default(...)` value!
 *
 * All existing remote flags can viewed in the Feature Flags page of the
 * "Zeller" project, or by visiting this link:
 * https://app.launchdarkly.com/default/dev/features.
 */
export const FeatureFlagsSchema = z.object({
  AccountsBalanceChartOnTransactions: boolean,
  BannerInOverviewPageOnly: boolean,
  BillSummary: boolean,
  CashFlowReporting: boolean,
  CombinedOnboardingFormABTesting: boolean,
  CombinedOnboardingFormPhase1: boolean,
  CombinedOnboardingFormPhase2: boolean,
  CorpCardsExistingEntities: boolean,
  DevTools: boolean,
  DarkMode: boolean,
  NoExternalTransferLimit: boolean,
  NotificationInvoicePaid: boolean,
  NotificationSitePinChanged: boolean,
  NotificationVirtualTerminalEmailLinkSendFailed: boolean,
  NotificationVirtualTerminalLinkPaymentReceived: boolean,
  NotificationVirtualTerminalSmsLinkSendFailed: boolean,
  OnboardingQuestionnaire: boolean,
  OnboardingShop: boolean,
  OnboardingShopT2: boolean,
  OnboardingSkipOnfido: boolean,
  OraclePos: boolean,
  POSAddItemsToCategory: boolean,
  POSEnableModifiersOnInvoices: boolean,
  POSItemReports: boolean,
  PrintReceiptSettings: boolean,
  ProhibitedMcc: boolean,
  ReportsFilterUplift: boolean,
  SiteReceiptPrintQRCode: boolean,
  SweepingTracking: boolean,
  T2Uplift: boolean,
  TfnEncryption: boolean,
  TransactionsFilterUplift: boolean,
  TransferAbusiveContentCheck: boolean,
  TransferRemittances: boolean,
  ZellerPos: boolean,
  PortalInactivityTimer: number,
  OnboardingInactivityTimer: number,
  CountDownModalTimer: number,
  MultiEntity: boolean,
  MultiEntityCache: boolean,
  MultiEntityLinkExisting: boolean,
  MultiEntityOnboarding: boolean,
  IDVEncryptionOnboarding: boolean,
  Expenses: boolean,
})

export type FeatureFlags = z.infer<typeof FeatureFlagsSchema>

export type FeatureFlagsAny = Record<string, unknown>
