import { InvoiceTaxFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { itemsTaxInclusiveSchema } from 'features/Invoicing/components/Invoices/Invoice/schemas/items'
import { yupToFormErrors } from 'formik'
import { object } from 'yup'

export const validateForm = (values: InvoiceTaxFormFields) => {
  return object({
    itemsTaxInclusive: itemsTaxInclusiveSchema,
  })
    .validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(yupToFormErrors)
}
