import styled from 'styled-components'

export const StyledSkeletonRowDesktop = styled.div`
  padding: 1.125rem 1.5rem;
`

export const StyledSkeletonItemsWrapper = styled.div<{ $height: string }>`
  height: ${({ $height }) => $height};
  overflow: hidden;
`
