import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

export const DebitCardUserAvatarSkeleton = () => {
  return (
    <Flex
      alignItems="center"
      p="6px 16px 8px 9px"
      border="3px solid transparent"
      width={{ _: 1, SM: 1 / 2 }}
    >
      <Box minWidth="24x" mr="16px">
        <SkeletonBasic height={24} width={24} isRounded />
      </Box>
      <Box width={1} maxWidth={139}>
        <SkeletonBasic height={12} isRounded />
      </Box>
    </Flex>
  )
}
