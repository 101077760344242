import { Flex } from '@npco/zeller-design-system'

import { Label } from 'pages/Settings/Connections/XeroBankFeeds/Components/AccountRow.styled'

import { Checkbox } from '../../../components/Form.styled'

interface BrandingThemeRowProps {
  id: string
  name: string
  checked?: boolean
  onChange: (e: React.ChangeEvent<any>) => void
}

export const BrandingThemeRow = ({
  id,
  name,
  onChange,
  checked,
}: BrandingThemeRowProps) => {
  return (
    <Flex mb="16px">
      <Checkbox
        name="xeroThemeIds"
        value={id}
        checked={checked}
        onChange={onChange}
      />
      <Flex ml="12px">
        <Label>{name}</Label>
      </Flex>
    </Flex>
  )
}
