import { useCallback, useMemo } from 'react'

import { mapToDebitAccounts } from 'utils/accounts'

import { useAccountsQuery } from './useAccountsQuery'

export const useAccounts = () => {
  const {
    isLoadingAccounts,
    isRefetchingAccounts,
    error,
    accounts: accountsData,
    refetchAccounts,
  } = useAccountsQuery()

  const accounts = useMemo(
    () => mapToDebitAccounts(accountsData || []),
    [accountsData]
  )

  const accountById = useCallback(
    (id: string | undefined | null) =>
      id ? accounts.find((account) => account.id === id) : undefined,
    [accounts]
  )

  return {
    error,
    accounts,
    accountById,
    refetchAccounts,
    isLoadingAccounts,
    isRefetchingAccounts,
  }
}
