import { gql } from '@apollo/client'
import dayjs from 'dayjs'

import { Month } from '../hooks/useSelectedDateRange/SelectedDateRange.type'
import { MapToChartDataCashFlowPeriodicTotalAmountFragment } from './IncomeAndExpenseChart.utils.generated'

export const mapToChartData = (
  incomeAndExpenses: MapToChartDataCashFlowPeriodicTotalAmountFragment[],
  timezone?: string
) => {
  return [...incomeAndExpenses].reverse().map((incomeAndExpense) => {
    const income = parseInt(
      incomeAndExpense.transactionDirectionTotalAmounts.income.value,
      10
    )
    const expense = parseInt(
      incomeAndExpense.transactionDirectionTotalAmounts.expense.value,
      10
    )
    const rangeStart = dayjs(incomeAndExpense.range.start)
    return {
      name: rangeStart.format('MMM').toUpperCase(),
      income,
      expense,
      dateStart: incomeAndExpense.range.start,
      year: rangeStart.format('YYYY'),
      value: Math.max(income, expense),
      isCurrentMonth: rangeStart.isSame(dayjs().tz(timezone), 'month'),
    }
  })
}

mapToChartData.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment MapToChartDataCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      range {
        start
      }
      transactionDirectionTotalAmounts {
        expense {
          value
        }
        income {
          value
        }
      }
    }
  `,
}

export const getMonthFromDate = (date: string): Month =>
  dayjs(date).format('MMMM') as Month
