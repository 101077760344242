import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetCustomerDetails } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'

import {
  GetCustomerDetails as GetCustomerDetailsResponse,
  GetCustomerDetailsVariables,
} from 'types/gql-types/GetCustomerDetails'

export const useGetCustomerDetails = (id?: string) => {
  const customerUuid = id ?? ''
  const entityUuid = useSelectedEntityUuid()
  const { loading: isLoading, data } = useQuery<
    GetCustomerDetailsResponse,
    GetCustomerDetailsVariables
  >(GetCustomerDetails, {
    variables: {
      entityUuid,
      customerUuid,
    },
    skip: customerUuid === '',
    onError: (error) => {
      showApiErrorToast(error)
    },
    fetchPolicy: 'cache-and-network',
  })

  return {
    isLoading,
    data,
  }
}
