import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { GetDebitCardTransactionsNewQueryResponse } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/getDebitCardTransactions.generated'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

export enum TransactionDrawerTabs {
  DETAILS = 'Details',
  CONTACT = 'Contact',
}

type DebitCardTransactionV3 = NonNullable<
  GetDebitCardTransactionsNewQueryResponse['getDebitCardTransactions']['transactions'][0]
>

export type DebitCardTransaction =
  | DebitCardTransactionV2
  | DebitCardTransactionV3

export type Contact = ContactCoreFieldsFragment
