import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GenerateShopTokenMutationVariables = Types.Exact<{
  input: Types.ShopTokenInput
}>

export type GenerateShopTokenMutationResponse = {
  __typename?: 'Mutation'
  generateShopToken: string
}

export const GenerateShopToken = gql`
  mutation GenerateShopToken($input: ShopTokenInput!) {
    generateShopToken(input: $input)
  }
` as unknown as TypedDocumentNode<
  GenerateShopTokenMutationResponse,
  GenerateShopTokenMutationVariables
>
export type GenerateShopTokenMutationFn = Apollo.MutationFunction<
  GenerateShopTokenMutationResponse,
  GenerateShopTokenMutationVariables
>

/**
 * __useGenerateShopTokenMutation__
 *
 * To run a mutation, you first call `useGenerateShopTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateShopTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateShopTokenMutation, { data, loading, error }] = useGenerateShopTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateShopTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateShopTokenMutationResponse,
    GenerateShopTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateShopTokenMutationResponse,
    GenerateShopTokenMutationVariables
  >(GenerateShopToken, options)
}
export type GenerateShopTokenMutationHookResult = ReturnType<
  typeof useGenerateShopTokenMutation
>
export type GenerateShopTokenMutationResult =
  Apollo.MutationResult<GenerateShopTokenMutationResponse>
export type GenerateShopTokenMutationOptions = Apollo.BaseMutationOptions<
  GenerateShopTokenMutationResponse,
  GenerateShopTokenMutationVariables
>
