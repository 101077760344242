import { useTranslations } from '@npco/utils-translations'
import { Flex, SkeletonBasic } from '@npco/zeller-design-system'

import { getCustomerDisplayName } from 'utils/customers'

import { CopyButtonWithTooltip } from '../../../../../../../../components/CopyButtonWithTooltip'
import { DebitCardType } from '../../../../../../../../hooks/useSetupFlowCardsQuery/graphql/getCards.type'
import { setupFlowDebitCardSensitiveInformationTranslations } from '../../SensitiveCardInformation.i18n'
import { CardHolderText } from '../../SensitiveCardInformation.styled'
import { SensitiveInfoLabel } from '../SensitiveContentMobile.styled'

interface CardOwnerInformationProps {
  isLoading: boolean
  cardOwner: DebitCardType['customer']
}

export const CardOwnerInformation = ({
  isLoading,
  cardOwner,
}: CardOwnerInformationProps) => {
  const t = useTranslations(setupFlowDebitCardSensitiveInformationTranslations)

  if (!cardOwner) {
    return null
  }

  const ownerName = getCustomerDisplayName(cardOwner)

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <SensitiveInfoLabel>{t('ownerLabel')}</SensitiveInfoLabel>
      {isLoading ? (
        <SkeletonBasic width={200} height={8} isRounded />
      ) : (
        <Flex gridGap="8px">
          <CardHolderText>{ownerName}</CardHolderText>
          <CopyButtonWithTooltip valueToCopy={ownerName} />
        </Flex>
      )}
    </Flex>
  )
}
