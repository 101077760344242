import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'

export const useSetPinSuccessParams = () => {
  const { search: queryParams } = useLocation()

  const isReplacement = useMemo(
    () => new URLSearchParams(queryParams).get('isReplacement') === 'true',
    [queryParams]
  )

  return {
    isReplacement,
  }
}
