import { useTranslations } from '@npco/utils-translations'

import { LeavingRoutePromptGuard } from '../LeavingRoutePromptGuard/LeavingRoutePromptGuard'
import { translations } from './OutstandingExpensesLeavingRoutePromptGuard.i18n'

export const OutstandingExpensesLeavingRoutePromptGuard = () => {
  const t = useTranslations(translations)

  return (
    <LeavingRoutePromptGuard
      dataTestId="outstanding-expenses-leaving-route-prompt-guard"
      message={t('message')}
      title={t('title')}
    />
  )
}
