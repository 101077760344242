import {
  DebitCardFormat,
  DebitCardProductType,
  VelocityControlCreateInput,
} from '@npco/mp-gql-types'

import {
  CardFormatOption,
  CardTypeOption,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { assertUnreachable } from 'utils/assertUnreachable'
import { getVelocityControlNewResetsAtApiFormat } from 'utils/banking/getVelocityControlNewResetsAt/getVelocityControlNewResetsAt'

import { VelocityControlInput } from './useRequestNewCard.types'

const DEFAULT_VELOCITY_CONTROL_RESET_TIMEZONE = 'Australia/Sydney'

const getApiMoneyInput = (value: string) => ({
  currency: 'AUD',
  value: Math.round(Number(value) * 100).toString(),
})

export const getCardFormatVariable = (cardFormatOption: CardFormatOption) => {
  if (cardFormatOption === CardFormatOption.Virtual) {
    return DebitCardFormat.VIRTUAL
  }

  if (cardFormatOption === CardFormatOption.Physical) {
    return DebitCardFormat.PHYSICAL
  }

  return assertUnreachable(cardFormatOption)
}

export const getCardTypeVariable = (cardTypeOption: CardTypeOption) => {
  return cardTypeOption === CardTypeOption.DebitCard
    ? DebitCardProductType.DEBIT
    : DebitCardProductType.EXPENSE
}

interface GetVelocityControlVariableProps {
  type: CardTypeOption
  velocityControl?: VelocityControlInput
}

export const getVelocityControlVariable = ({
  type,
  velocityControl,
}: GetVelocityControlVariableProps) => {
  if (type === CardTypeOption.DebitCard) {
    return undefined
  }

  if (!velocityControl) {
    throw Error('velocityControl is required for requesting Expense Card')
  }

  return {
    ...velocityControl,
    amountLimit: getApiMoneyInput(velocityControl.amountLimit),
    maxTransactionValue: getApiMoneyInput(velocityControl.maxTransactionValue),
  }
}

export const getVelocityControlFromInput = ({
  velocityControlInput,
  entityAddressTimezone = DEFAULT_VELOCITY_CONTROL_RESET_TIMEZONE,
}: {
  velocityControlInput: VelocityControlCreateInput
  entityAddressTimezone?: string
}) => {
  const amountLimit = {
    value: velocityControlInput.amountLimit.value,
  }

  return {
    resetsAt: getVelocityControlNewResetsAtApiFormat({
      velocityWindow: velocityControlInput.velocityWindow,
      entityAddressTimezone,
    }),
    velocityWindow: velocityControlInput.velocityWindow,
    availableAmount: amountLimit,
    amountLimit,
    totalSpentAmount: { value: '0' },
  }
}
