import { type ReactNode } from 'react'
import { matchPath, useLocation } from 'react-router-dom-v5-compat'
import {
  Breadcrumb,
  Flex,
  PageTemplate,
  Tabs,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { page } from 'translations'

import { useProfilePageLayout } from './ProfilePageLayout.hooks'

const HIDE_TABS_ROUTES = [
  ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.CHANGE_NUMBER.path,
  ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.VALIDATE_CODE.path,
]

type ProfilePageLayoutProps = {
  children?: ReactNode
}

export const ProfilePageLayout = ({ children }: ProfilePageLayoutProps) => {
  const { tabs, currentTab } = useProfilePageLayout()
  const { pathname } = useLocation()

  const shouldShowTabs = !HIDE_TABS_ROUTES.some((route) =>
    Boolean(matchPath(route, pathname))
  )

  const isInProfilePath = Boolean(
    matchPath(ROOT.ORGS.ORG().SETTINGS.PROFILE.path, pathname)
  )

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            {isInProfilePath ? (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>
                    {page.title.profileSettings}
                  </Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <Breadcrumbs />
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        shouldShowTabs && (
          <PageTemplate.HeaderSecondaryRow>
            <Tabs currentTab={currentTab}>
              {tabs.map((tab) => (
                <Tabs.Item key={tab.value} {...tab} />
              ))}
            </Tabs>
          </PageTemplate.HeaderSecondaryRow>
        )
      }
    >
      <PageTemplate.Section>{children}</PageTemplate.Section>
    </PageTemplate>
  )
}
