import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'

import { FieldValuesProps, SetFieldProps } from 'types/common'

import { useSignUp, useSignUpErrorMessages } from '../../SignUp.hooks'

export const useSignUpDesktop = () => {
  const [searchParams] = useSearchParams()
  const [isEmailSet, setIsEmailSet] = useState(
    Boolean(searchParams.get('email'))
  )
  const [areFieldsMounted, setAreFieldsMounted] = useState(false)
  const {
    emailErrorMessage,
    passwordErrorMessage,
    setEmailErrorMessage,
    setPasswordErrorMessage,
    setSignUpErrors,
  } = useSignUpErrorMessages()
  const passwordRef = useRef<HTMLInputElement>(null)

  const { handleSignUp } = useSignUp()

  const handleSubmit = useCallback(
    (values: FieldValuesProps, { setSubmitting }: SetFieldProps) => {
      if (!isEmailSet) {
        setIsEmailSet(true)
        setSubmitting(false)
        return
      }

      handleSignUp({
        values,
        onError: (err) => {
          setSignUpErrors(err)
          setSubmitting(false)
        },
      })
    },
    [setSignUpErrors, handleSignUp, isEmailSet]
  )

  useEffect(() => {
    if (areFieldsMounted) {
      passwordRef.current?.focus()
    }
  }, [areFieldsMounted])

  return {
    handleSubmit,
    emailErrorMessage,
    passwordErrorMessage,
    isEmailSet,
    setEmailErrorMessage,
    setAreFieldsMounted,
    passwordRef,
    setPasswordErrorMessage,
  }
}
