import { RefundPinType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { RadioGroupFormik, RadioListCard } from '@npco/zeller-design-system'

import { translations } from './Refunds.i18n'

export const RefundPinTypeOptions = () => {
  const t = useTranslations(translations)
  return (
    <RadioGroupFormik name="refundPinType" isVerticalDisplay gap="8px">
      <RadioListCard
        id="site-pin"
        title={t('sitePinOption')}
        value={RefundPinType.SITE_PIN}
      />
      <RadioListCard
        id="refund-pin"
        title={t('refundPinOption')}
        value={RefundPinType.REFUND_PIN}
      />
    </RadioGroupFormik>
  )
}
