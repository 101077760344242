import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const StyledPageContent = styled(Flex)`
  flex: 1 1 auto;
  width: 100%;
  flex-flow: column;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 31.5rem;
    flex: 0 0 auto;
    padding: 0;
  }
`
