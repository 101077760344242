import { useCallback, useMemo } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CardTypeOption,
  CreateSelectCardTypeState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

import { SelectCardTypeFormValues } from '../SelectCardTypeForm.types'

export const useSelectCardTypeForm = () => {
  const { state } = useAddCardState<CreateSelectCardTypeState>()
  const { goToAddCardStage } = useGoToAddCardStage()

  const handleSubmit = useCallback(
    ({ cardType }: SelectCardTypeFormValues) => {
      if (cardType === CardTypeOption.DebitCard) {
        goToAddCardStage({
          stage: AddCardStage.CreateSelectCardholderStage,
          cardType: CardTypeOption.DebitCard,
        })
      }

      if (cardType === CardTypeOption.ExpenseCard) {
        // TICKET: https://npco-dev.atlassian.net/browse/BANK-231
        // May need to update these enums to use GraphQL enums when available
        goToAddCardStage({
          stage: AddCardStage.CreateSelectCardholderStage,
          cardType: CardTypeOption.ExpenseCard,
        })
      }
    },
    [goToAddCardStage]
  )

  const handleBack = useCallback(() => {
    goToAddCardStage({ stage: AddCardStage.LandingPageStage })
  }, [goToAddCardStage])

  const initialCardType: CardTypeOption | '' = useMemo(() => {
    return state?.cardType ?? ''
  }, [state?.cardType])

  return {
    handleSubmit,
    handleBack,
    initialCardType,
  }
}
