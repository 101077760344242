import { useCallback } from 'react'
import {
  Divider,
  Flex,
  RadioGroupBasic,
  RadioListCard,
} from '@npco/zeller-design-system'
import { Form, useFormikContext } from 'formik'

import { currencyFormatter } from 'utils/common'
import { translate } from 'utils/translations'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import { DetailsField } from 'components/DetailsField/DetailsField'
import { InputAdaptiveCurrencyField } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField'

import { getCardDetails } from '../../TransactionDetails.utils'
import {
  RefundTransactionFormValues,
  RefundType,
} from '../RefundTransactionModal.types'

interface RefundTransactionFormProps {
  transaction: Transaction
}
export const RefundTransactionForm = ({
  transaction,
}: RefundTransactionFormProps) => {
  const { setValues, values } = useFormikContext<RefundTransactionFormValues>()
  const { amount, refundedAmount } = transaction
  const refundBalance = amount - Number(refundedAmount)

  const handleToggleChange = useCallback(
    (value: string) => {
      if (
        value === RefundType.FullRefund ||
        value === RefundType.PartialRefund
      ) {
        setValues({ ...values, refundType: value })
      }
    },
    [setValues, values]
  )

  return (
    <Form>
      <RadioGroupBasic
        gap="8px"
        isVerticalDisplay
        name="role"
        onChange={handleToggleChange}
        value={values.refundType}
      >
        <RadioListCard
          id="full-refund"
          name={RefundType.FullRefund}
          title={translate(
            'page.refundTransactionModal.formSections.option.fullRefund'
          )}
          value={RefundType.FullRefund}
        />
        <RadioListCard
          id="discount-config-amount-type"
          name={RefundType.PartialRefund}
          title={translate(
            'page.refundTransactionModal.formSections.option.partialRefund'
          )}
          value={RefundType.PartialRefund}
        />
      </RadioGroupBasic>

      <Divider />
      <Flex
        width="100%"
        mb={values.refundType === RefundType.PartialRefund ? '16px' : '0px'}
        flexDirection="column"
      >
        <DetailsField
          label={translate('page.refundTransactionModal.method')}
          value={getCardDetails({
            scheme: transaction.scheme,
            maskedPan: transaction.maskedPan,
          })}
        />
        <DetailsField
          label={translate('page.refundTransactionModal.refundableBalance')}
          value={currencyFormatter(refundBalance)}
        />
      </Flex>
      {values.refundType === RefundType.PartialRefund && (
        <InputAdaptiveCurrencyField
          ariaLabel={translate('page.refundTransactionModal.refundAmountLabel')}
          dataTestId="refund-amount"
          label={translate('page.refundTransactionModal.refundAmountLabel')}
          name="amount"
          style={{}}
        />
      )}
    </Form>
  )
}
