import { useCallback, useMemo } from 'react'

import * as styled from './SegmentedInput.styled'

type Props = {
  name: string
  value: string | undefined
  onValue: (value: string | undefined) => void
  length: number
  invalid?: boolean
  onComplete?: (value: string) => void
  className?: string
  rootProps?: React.HTMLAttributes<HTMLElement> & Record<string, unknown>
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> &
    Record<string, unknown>
  segmentProps?: React.InputHTMLAttributes<HTMLInputElement> &
    Record<string, unknown>
}

export const SegmentedInput = ({
  name,
  value,
  onValue,
  length,
  invalid = false,
  onComplete,
  className,
  rootProps,
  inputProps,
  segmentProps,
}: Props) => {
  const characters: number[] = useMemo(
    () => new Array(length).fill(undefined).map((_, index) => index),
    [length]
  )

  const onChange = useCallback<
    NonNullable<React.InputHTMLAttributes<HTMLInputElement>['onChange']>
  >(
    (e) => {
      const nextValue = e.target.value.substring(0, length)
      onValue(nextValue)
      if (nextValue.length === length) {
        onComplete?.(nextValue)
      }
    },
    [length, onValue, onComplete]
  )

  return (
    <styled.Container className={className} {...rootProps}>
      <styled.Input
        data-testid="segmented-input"
        name={name}
        value={value || ''}
        onChange={onChange}
        minLength={length}
        maxLength={length}
        aria-invalid={invalid}
        {...inputProps}
      />
      {characters.map((position) => (
        <styled.Segment
          data-testid={`segmented-input-${position}`}
          key={position}
          name={`${name}-${position}`}
          value={value?.charAt(position) || ''}
          disabled
          aria-hidden
          data-active={(value?.length ?? 0) === position}
          data-complete={value?.length === length}
          {...segmentProps}
        />
      ))}
    </styled.Container>
  )
}

SegmentedInput.PIN = ({
  inputProps,
  ...props
}: Partial<Pick<Props, 'length'>> & Omit<Props, 'length'>) => (
  <SegmentedInput
    length={4}
    {...props}
    inputProps={{
      inputMode: 'numeric',
      autoComplete: 'off',
      ...inputProps,
    }}
  />
)
