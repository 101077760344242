import { ACCORDION_FORM_STATE } from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { FormikErrors, FormikTouched } from 'formik'

import { translate } from 'utils/translations'

interface GetAccordionDetailsTextProps {
  touched: FormikTouched<InvoiceFormFields>
  values: InvoiceFormFields
}

export const getAccordionDetailsText = ({
  touched,
  values,
}: GetAccordionDetailsTextProps) => {
  const isAllRequiredFieldsTouched = Boolean(touched.title?.header)
  const hasAllRequiredFieldsValues = Boolean(values.title.header)

  if (isAllRequiredFieldsTouched && hasAllRequiredFieldsValues) {
    return values.title.header
  }

  return translate('page.invoice.formSections.title.details')
}

interface GetAccordionFormStateProps {
  errors: FormikErrors<InvoiceFormFields>
  touched: FormikTouched<InvoiceFormFields>
  values: InvoiceFormFields
}

export const getAccordionFormState = ({
  errors,
  touched,
  values,
}: GetAccordionFormStateProps) => {
  const hasError = Boolean(errors.title)

  const isAllRequiredFieldsTouched = Boolean(touched.title?.header)
  const hasAllRequiredFieldsValues = Boolean(values.title.header.trim())

  if (isAllRequiredFieldsTouched && hasAllRequiredFieldsValues && !hasError) {
    return ACCORDION_FORM_STATE.COMPLETE
  }

  if (hasError) {
    return ACCORDION_FORM_STATE.ATTENTION
  }

  const hasAnyValues =
    Boolean(values.title.header) || Boolean(values.title.message)
  const hasAnyTouched = Boolean(touched.title)

  if (hasAnyValues && hasAnyTouched) {
    return ACCORDION_FORM_STATE.PARTIAL_COMPLETE
  }

  return ACCORDION_FORM_STATE.EMPTY
}
