import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountTransactionsListDebitCardAccountV2FragmentDoc } from '../../../AccountTransactionsList/AccountTransactionsList.generated'

const defaultOptions = {} as const
export type AccountTransactionsQueryVariables = Types.Exact<{
  debitCardAccountUuid: Types.Scalars['ID']['input']
  entityUuid: Types.Scalars['ID']['input']
}>

export type AccountTransactionsQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountV2: {
    __typename?: 'DebitCardAccountV2'
    id: string
    type: Types.DebitCardAccountType
    status: Types.DebitCardAccountStatus
  } | null
}

export const AccountTransactionsQuery = gql`
  query AccountTransactionsQuery($debitCardAccountUuid: ID!, $entityUuid: ID!) {
    getDebitCardAccountV2(
      debitCardAccountUuid: $debitCardAccountUuid
      entityUuid: $entityUuid
    ) {
      id
      ...AccountTransactionsListDebitCardAccountV2Fragment
    }
  }
  ${AccountTransactionsListDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  AccountTransactionsQueryResponse,
  AccountTransactionsQueryVariables
>

/**
 * __useAccountTransactionsQuery__
 *
 * To run a query within a React component, call `useAccountTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTransactionsQuery({
 *   variables: {
 *      debitCardAccountUuid: // value for 'debitCardAccountUuid'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useAccountTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountTransactionsQueryResponse,
    AccountTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AccountTransactionsQueryResponse,
    AccountTransactionsQueryVariables
  >(AccountTransactionsQuery, options)
}
export function useAccountTransactionsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountTransactionsQueryResponse,
    AccountTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountTransactionsQueryResponse,
    AccountTransactionsQueryVariables
  >(AccountTransactionsQuery, options)
}
export type AccountTransactionsQueryHookResult = ReturnType<
  typeof useAccountTransactionsQuery
>
export type AccountTransactionsQueryLazyQueryHookResult = ReturnType<
  typeof useAccountTransactionsQueryLazyQuery
>
export type AccountTransactionsQueryQueryResult = Apollo.QueryResult<
  AccountTransactionsQueryResponse,
  AccountTransactionsQueryVariables
>
