import { BodySmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledTooltipWrapper = styled.div`
  position: relative;
  left: 0px;
  top: 0;
  transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0.5rem);
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  z-index: 5;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin-top: 0;
    padding-bottom: 0;
    position: absolute;
    width: 21rem;
    /* √2 * 15 / 2 = half of the diagonal of the arrow's square */
    /* -4 = additional left offset of the arrow */
    transform: translateX(${(Math.SQRT2 * 15) / 2 - 4}px) translateY(0);
  }
`

export const StyledTooltipContent = styled.div`
  ${BodySmallTypography};
  position: relative;
  background: ${({ theme }) => theme.colors.BLACK_900};
  color: ${({ theme }) => theme.colors.WHITE};
  padding: 1rem;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 1rem;
    top: auto;
    border-radius: 3px;
    transform: rotate(45deg) translateX(50%);
    background: ${({ theme }) => theme.colors.BLACK_900};
    width: 15px;
    height: 15px;
    margin-right: 0;
    margin-bottom: -4px;

    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      right: 100%;
      bottom: auto;
      left: auto;
      top: 1rem;
      margin-right: -4px;
      transform: rotate(45deg) translateX(50%);
    }
  }
`

export const StyledTooltipParentWrapper = styled.div`
  position: relative;
`
const animationCurve = 'cubic-bezier(.17,.67,.54,1.31)'
interface StyledTooltipConditionProps {
  isValid: boolean
  shouldShowError: boolean
}
export const StyledTooltipCondition = styled.li.attrs(
  ({ isValid }: StyledTooltipConditionProps) => ({
    className: isValid && 'active',
  })
)<StyledTooltipConditionProps>`
  position: relative;
  transition: color 0.3s ${animationCurve};

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  ${({ shouldShowError, theme }) =>
    shouldShowError &&
    `
    color: ${theme.colors.RED_1000};
  `}

  &::marker {
    font-size: 0;
  }

  &:before {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.WHITE};
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translate(-50%, -50%);

    transition: 0.3s ${animationCurve};
    transition-property: height, width, background;

    ${({ shouldShowError, theme }) =>
      shouldShowError &&
      `
      background: ${theme.colors.RED_1000};
    `}
  }

  svg {
    width: 0.5rem;
    height: 0.5rem;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: all 0.3s ${animationCurve};
    transition-property: opacity, transform;
    opacity: 0;
  }

  &.active {
    color: ${({ theme }) => theme.colors.GREEN_1000};

    &:before {
      width: 1rem;
      height: 1rem;
      background: ${({ theme }) => theme.colors.GREEN_1000};
    }

    svg {
      opacity: 1;
      transform: translateY(-50%) translateX(-50%) scale(1);
    }
  }
`

export const StyledUl = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0;
  padding-left: 1.5rem;
`
