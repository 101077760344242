import { ButtonGhost } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const PeriodButton = styled(ButtonGhost)<{ $isSelected?: boolean }>`
  ${({ theme, $isSelected = false }) =>
    $isSelected &&
    css`
      color: ${theme.colors.WHITE};
      background-color: ${theme.colors.BLUE_1000};

      &:hover {
        background-color: ${theme.colors.BLUE_700};
      }
    `}
`
