import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'

export const useRedirectToCardList = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  return {
    redirectToCardList: () =>
      navigate(ROOT.ORGS.ORG(shortEntityId).CARDS.path, { replace: true }),
  }
}
