import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  DebitCardColour,
  DebitCardFormat,
  DebitCardProductType,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'

import { UNEXPECTED_ERROR } from 'types/errors'

import {
  RequestNewSetupFlowDebitCardMutation,
  RequestNewSetupFlowDebitCardMutationResponse,
  RequestNewSetupFlowDebitCardMutationVariables,
} from './graphql/requestNewCard.generated'
import { cacheNewCard } from './useSetupFlowCardCreate.utils'

interface RequestSetupFlowDebitCardProps {
  accountUuid: string
  cardholderUuid: string
}

export const useSetupFlowCardCreate = () => {
  const entityUuid = useSelectedEntityUuid()

  const [requestSetupFlowDebitCardMutation, { loading }] = useMutation<
    RequestNewSetupFlowDebitCardMutationResponse,
    RequestNewSetupFlowDebitCardMutationVariables
  >(RequestNewSetupFlowDebitCardMutation, {
    update: (cache, result) => {
      const response = result.data?.requestNewDebitCard
      if (!response?.card) {
        return
      }
      cacheNewCard({ cache, newCard: response.card, entityUuid })
    },
  })

  const requestSetupFlowDebitCard = useCallback(
    async ({ accountUuid, cardholderUuid }: RequestSetupFlowDebitCardProps) => {
      try {
        const { data } = await requestSetupFlowDebitCardMutation({
          variables: {
            input: {
              accountUuid,
              cardholderUuid,
              colour: DebitCardColour.WHITE,
              format: DebitCardFormat.VIRTUAL,
              productType: DebitCardProductType.DEBIT,
              accessibleProfile: false,
            },
            entityUuid,
          },
        })

        if (!data?.requestNewDebitCard.card) {
          return UNEXPECTED_ERROR
        }

        return data?.requestNewDebitCard.card
      } catch (err) {
        ErrorLogger.report('Setup flow requesting new debit card error:', err)
        return UNEXPECTED_ERROR
      }
    },
    [requestSetupFlowDebitCardMutation, entityUuid]
  )

  return { requestSetupFlowDebitCard, isLoading: loading }
}
