import { useTranslations } from '@npco/utils-translations'
import { Divider, Flex } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'

import { SummaryRow } from '../../../components/SummaryRow/SummaryRow'
import { SummarySubRow } from '../../../components/SummarySubRow/SummarySubRow'
import { translations } from '../Reports.i18n'
import { ReportsSectionHeading } from '../ReportsSectionHeading/ReportsSectionHeading'
import * as styled from './ReportsTable.styled'

interface Props {
  collected: number
  surcharge: number
  tip: number
  fees: number
  tax: { [key in string]: number }
  refunds: number
  subtotal: number
}

export const ReportsTable = ({
  fees,
  collected,
  surcharge,
  tip,
  tax,
  refunds,
  subtotal,
}: Props) => {
  const t = useTranslations(translations)

  const sales = collected - (tip + surcharge)
  const total = subtotal - fees
  return (
    <styled.Wrapper>
      <ReportsSectionHeading>{t('heading')}</ReportsSectionHeading>
      <Flex flexDirection="column" gap="12px">
        <SummaryRow label={t('sales')} value={currencyFormatter(sales)} />
        <SummarySubRow
          label={t('tip')}
          value={currencyFormatter(tip)}
          amountTestId="tip-amount"
        />
        <SummarySubRow
          label={t('surcharge')}
          value={currencyFormatter(surcharge)}
          amountTestId="surcharge-amount"
        />
        <Divider margin="0" />
        <SummaryRow
          label={t('totalProcessed')}
          value={currencyFormatter(collected)}
          amountTestId="collected-amount"
        />
        <SummarySubRow
          label={t('refunds')}
          value={currencyFormatter(-refunds)}
          amountTestId="refunds-amount"
        />
        <Divider margin="0" />
        <SummaryRow
          label={t('subtotal')}
          value={currencyFormatter(subtotal)}
          amountTestId="subtotal-amount"
        />
        <SummarySubRow label={t('fees')} value={currencyFormatter(-fees)} />
        <Divider margin="0" />
        <SummaryRow label={t('total')} value={currencyFormatter(total)} />
        <SummarySubRow
          label={t('gst')}
          value={currencyFormatter(tax?.GST ?? 0)}
          headerTestId="tax-header"
          amountTestId="tax-amount"
        />
      </Flex>
    </styled.Wrapper>
  )
}
