import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type CategoryCellCashFlowCategoryNetAmountFragment = {
  __typename?: 'CashFlowCategoryNetAmount'
  category: Types.EntityCategories | null
  subcategoryCount: number | null
}

export const CategoryCellCashFlowCategoryNetAmountFragmentDoc = gql`
  fragment CategoryCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
    category
    subcategoryCount
  }
` as unknown as TypedDocumentNode<
  CategoryCellCashFlowCategoryNetAmountFragment,
  undefined
>
