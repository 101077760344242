import { SimBillingStatementExportFormat } from '@npco/mp-gql-types'

import { useExportSimBillsModal } from 'pages/Settings/Sim/BillingHistory/ExportSimBillsModal/hooks/useExportSimBillsModal'
import { ExportFileModalContent } from 'components/ExportFileModalContent/ExportFileModalContent'

interface Props {
  closeModal: () => void
  filename: string
  format: SimBillingStatementExportFormat
}

export const ExportSimBillsModal = ({
  closeModal,
  filename,
  format,
}: Props) => {
  const { exportFile, isLoading, hasError, hasLoaded } = useExportSimBillsModal(
    { closeModal, filename, format }
  )

  return (
    <ExportFileModalContent
      closeModal={closeModal}
      rerenderContent={exportFile}
      isLoading={isLoading}
      hasError={hasError}
      hasLoaded={hasLoaded}
    />
  )
}
