import {
  Flex,
  SkeletonAvatar,
  SkeletonBasic,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

const Skeleton = ({ width }: { width: string }) => (
  <Flex height="64px" alignItems="center" width="100%">
    <Flex width="512px" pl="12px" pr="16px">
      <Flex flex="1" alignItems="center">
        <Flex mr="12px" alignItems="center">
          <SkeletonAvatar width={20} height={20} />
        </Flex>
        <SkeletonTypographyBase
          width={width}
          type={TypographyTypes.BodyDefault}
        />
      </Flex>
      <Flex width="70px" alignItems="center">
        <SkeletonBasic width="70px" height="20px" isRounded />
      </Flex>
    </Flex>
  </Flex>
)

const SKELETON_WIDTH = ['185px', '210px', '165px', '190px', '170px']

export const LoadingState = () => {
  return (
    <>
      {SKELETON_WIDTH.map((width) => (
        <Skeleton key={width} width={width} />
      ))}
    </>
  )
}
