import { useMemo } from 'react'
import { ContactMethod, Source } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'

import { DetailsField } from 'components/DetailsField/DetailsField'

import { isVirtualTerminalOrPayByLinkTransaction } from '../../TransactionDetails.utils'
import { translations } from './EcommerceType.i18n'
import { useGetEcommerceTypeQuery } from './graphql/getEcommerceType.generated'

type EcommerceTypeProps = {
  source?: Source
  reference: string
}

export const EcommerceType = ({ source, reference }: EcommerceTypeProps) => {
  const t = useTranslations(translations)
  const entityUuid = useSelectedEntityUuid()

  const { data, loading, error } = useGetEcommerceTypeQuery({
    variables: {
      id: reference,
      entityUuid,
    },
    skip: source !== Source.PAY_BY_LINK || !reference,
  })

  const contactType = data?.getVirtualTerminalRecord.contactType
  const ecommerceType = useMemo(() => {
    if (source === Source.VIRTUAL_TERMINAL) {
      return t('manualCardEntry')
    }
    switch (contactType) {
      case ContactMethod.EMAIL:
        return t('emailLink')
      case ContactMethod.SMS:
        return t('smsLink')
      case ContactMethod.LINK:
        return t('copyLink')
      default:
        return '-'
    }
  }, [contactType, source, t])

  if (!isVirtualTerminalOrPayByLinkTransaction(source) || Boolean(error)) {
    return null
  }

  return (
    <DetailsField label={t('type')} value={ecommerceType} isLoading={loading} />
  )
}
