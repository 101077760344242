import { useCallback } from 'react'
import { Formik, FormikErrors } from 'formik'

import { translate } from 'utils/translations'

import { XeroConnectionAgreementForm } from './XeroConnectionAgreementForm'

interface Values {
  agreed: boolean
}

interface XeroConnectionAgreementProps {
  onSubmit: () => void
}

const initialValues: Values = { agreed: false }

export const XeroConnectionAgreement = ({
  onSubmit,
}: XeroConnectionAgreementProps) => {
  const validateAgreement = useCallback((values) => {
    const errors: FormikErrors<Values> = {}
    if (!values.agreed) {
      errors.agreed = translate(
        'page.settings.connections.xeroBankFeeds.errors.consentToPrivacyPolicy'
      )
    }
    return errors
  }, [])

  return (
    <Formik<Values>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateAgreement}
    >
      {({ errors, handleSubmit, handleChange, handleBlur }) => (
        <XeroConnectionAgreementForm
          errors={errors}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      )}
    </Formik>
  )
}
