import { createTranslations } from '@npco/utils-translations'

export const productDetailsTTranslation = createTranslations({
  t2Point1: 'Built-in free point-of-sale',
  t2Point2: '600+ point-of-sale integrations',
  t2Point3: 'Tap and insert payments',
  t2Point4: 'QR code, email and SMS receipts',
  t2Point5: '6.5” HD display',
  t2Point6: 'Wi-Fi, SIM card and ethernet',
  t2Point7: 'All-day battery',
  t1Point1: '600+ point-of-sale integrations',
  t1Point2: 'Tap, insert and swipe payments',
  t1Point3: 'Print, email and SMS receipts',
  t1Point4: '5.5” display',
  t1Point5: 'Wi-Fi, SIM card and ethernet',
  t1Point6: 'All-day battery',
  back: 'Back',
})
