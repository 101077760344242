import { useDebitCardTransactions } from 'pages/DebitCardTransactions'
import { DebitCardTransactions } from 'pages/DebitCardTransactions/DebitCardTransactions'

import { LatestTransactionsHeader } from './LatestTransactionsHeader'

const LATEST_TRANSACTIONS_LIMIT = 10

export const LatestTransactions = () => {
  const {
    groupedDebitCardTransactions,
    hasNoInitialResults,
    loading,
    error,
    refetch,
    onLoadMore,
    handleSelectedTransactionUpdate,
  } = useDebitCardTransactions({
    filters: {},
    areFiltersInDefaultState: true,
    limit: LATEST_TRANSACTIONS_LIMIT,
  })

  return (
    <>
      <LatestTransactionsHeader />
      <DebitCardTransactions
        groupedDebitCardTransactions={groupedDebitCardTransactions}
        hasNoInitialResults={hasNoInitialResults}
        loading={loading}
        error={error}
        refetch={refetch}
        hasMore={false}
        onLoadMore={onLoadMore}
        handleSelectedTransactionUpdate={handleSelectedTransactionUpdate}
        listHeight="100%"
      />
    </>
  )
}
