import * as styled from './DetailField.styled'

interface DetailFieldProps {
  label: React.ReactNode
  sublabel?: string
  value: React.ReactNode
  abbreviationTitle?: string
  dataTestId?: string
}

export const DetailField = ({
  label,
  sublabel,
  value,
  abbreviationTitle,
  dataTestId = 'contact-detail-field',
}: DetailFieldProps) => {
  return (
    <styled.DetailWrapper data-testid={dataTestId}>
      <styled.DetailLabel>
        {abbreviationTitle ? (
          <styled.Abbreviation title={abbreviationTitle}>
            {label}
          </styled.Abbreviation>
        ) : (
          label
        )}
        {sublabel && (
          <styled.SubLabel>
            <styled.Dot>{'\u00B7'}</styled.Dot>
            {sublabel}
          </styled.SubLabel>
        )}
      </styled.DetailLabel>
      <styled.DetailValue>{value}</styled.DetailValue>
    </styled.DetailWrapper>
  )
}
