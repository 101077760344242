import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { uniqBy } from 'lodash-es'

import { GetCardholderList } from '../graphql/customers'
import { GetCardholderListQueryResponse } from '../graphql/customers.generated'
import { sortCardholders } from '../utils/cardholders.utils'

type Customer = NonNullable<GetCardholderListQueryResponse['getCustomers'][0]>

export const useCardholders = () => {
  const { userData } = useLoggedInUser()
  const entityUuid = useSelectedEntityUuid()

  const { data, loading, error, refetch, networkStatus } =
    useQuery<GetCardholderListQueryResponse>(GetCardholderList, {
      notifyOnNetworkStatusChange: true,
      variables: { entityUuid },
    })

  const cardholders = useMemo(() => {
    const filteredCustomers =
      data?.getCustomers
        ?.filter((customer): customer is Customer => Boolean(customer))
        .filter((customer) => customer.role !== null) ?? []

    const uniqueCustomers = uniqBy(filteredCustomers, (customer) => customer.id)

    return sortCardholders(uniqueCustomers, userData?.id || '')
  }, [data?.getCustomers, userData?.id])

  return {
    cardholders,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  }
}
