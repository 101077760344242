import { translate } from 'utils/translations'

import {
  StyledBlueText,
  StyledDragAndDropWrapper,
  StyledGreyText,
} from './DragAndDropText.styled'

export const DragAndDropText = () => {
  return (
    <StyledDragAndDropWrapper>
      <StyledGreyText>
        {translate('page.transactionDetails.images.modal.dragAndDrop1')}
      </StyledGreyText>
      <StyledBlueText>
        {translate('page.transactionDetails.images.modal.dragAndDrop2')}
      </StyledBlueText>
    </StyledDragAndDropWrapper>
  )
}
