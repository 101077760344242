import { useMemo } from 'react'
import { sortBy } from 'lodash-es'

import { useTopBarNavQuery } from './graphql/TopBarNavQuery.generated'

export const useTopBarNavData = () => {
  const { data, loading, error } = useTopBarNavQuery()

  const sortedEntityRelations = useMemo(() => {
    const entityRelations = data?.getCustomerEntityMapping.entityRelations ?? []
    const visibleEntityRelations = entityRelations.filter(
      (entityRelation) => entityRelation.isVisible
    )

    return sortBy(visibleEntityRelations, 'order')
  }, [data?.getCustomerEntityMapping.entityRelations])

  return {
    entityRelations: sortedEntityRelations,
    isLoading: loading,
    error,
  }
}
