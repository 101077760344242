import {
  CardIcon,
  COLOR,
  RadioButtonUnselectedIcon,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import {
  StyledRadioCard,
  StyledRadioCardIcon,
} from 'pages/GlobalModals/GlobalModals.styled'

import { LandingPageOption } from '../LandingPageForm.types'

interface LinkCardOptionProps {
  onChange: () => void
  isChecked: boolean
  name: string
}

export const LinkCardOption = ({
  onChange,
  isChecked,
  name,
}: LinkCardOptionProps) => {
  return (
    <StyledRadioCard
      id={LandingPageOption.LinkCard}
      value={LandingPageOption.LinkCard}
      title={translate('page.addCardModal.landingPage.linkCardOptionTitle')}
      desc={translate('page.addCardModal.landingPage.linkCardOptionDesc')}
      icon={() => (
        <StyledRadioCardIcon color={COLOR.BLUE_1000}>
          <CardIcon />
        </StyledRadioCardIcon>
      )}
      onChange={onChange}
      checked={isChecked}
      name={name}
      unselectedIcon={RadioButtonUnselectedIcon}
    />
  )
}
