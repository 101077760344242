interface ConditionProps {
  shouldShow: boolean
  children: React.ReactNode
}

export const Condition = ({ shouldShow, children }: ConditionProps) => {
  if (!shouldShow) {
    return null
  }

  return <>{children}</>
}
