import {
  INVOICE_DEFAULT_PRICE,
  INVOICE_ITEMS_DISCOUNT_PRICE_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'

import { translate } from 'utils/translations'
import { InputAdaptiveCurrencyField } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField'

export const translations = {
  ariaLabel: translate(
    'page.invoice.formSections.items.itemsDiscountPriceAriaLabel'
  ),
}

export const InvoiceItemDiscountPrice = () => {
  return (
    <InputAdaptiveCurrencyField
      ariaLabel={translations.ariaLabel}
      dataTestId="invoicing-invoice-item-discount-price"
      defaultAmount={INVOICE_DEFAULT_PRICE}
      name={INVOICE_ITEMS_DISCOUNT_PRICE_FIELD}
    />
  )
}
