import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useGoToBlockedAction } from 'features/BlockedAction/blocked-action-routing'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CardTypeOption,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { UseOnAddCardEntityFragment } from './useOnAddCard.generated'

export const useOnAddCard = (
  entity: UseOnAddCardEntityFragment | null,
  cardType?: CardTypeOption
) => {
  const { userData } = useLoggedInUser()
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToBlockedAction } = useGoToBlockedAction()

  const onAddCard = useCallback(() => {
    if (entity && !entity.canCreateCard) {
      goToBlockedAction({ blockedAction: 'createCard' })
      return
    }

    if (userData?.role !== CustomerRole.ADMIN) {
      goToAddCardStage({ stage: AddCardStage.LinkCardIdStage })
      return
    }

    if (cardType && cardType === CardTypeOption.ExpenseCard) {
      goToAddCardStage({
        stage: AddCardStage.CreateSelectCardTypeStage,
        cardType: CardTypeOption.ExpenseCard,
      })
      return
    }

    goToAddCardStage({
      stage: AddCardStage.LandingPageStage,
    })
  }, [userData?.role, entity, cardType, goToAddCardStage, goToBlockedAction])

  return { onAddCard }
}

useOnAddCard.fragments = {
  Entity: gql`
    fragment UseOnAddCardEntityFragment on Entity {
      id
      canCreateCard
    }
  `,
}
