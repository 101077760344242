import { useMemo } from 'react'

import { translate } from 'utils/translations'
import { DrawerLabel, DrawerRow } from 'components/DrawerItem'

import {
  StyledLeftColumn,
  StyledRightColumn,
} from '../../TransactionContact.styled'
import { OpeningHours } from '../../TransactionContact.types'
import { HoursBreakdown } from './HoursBreakdown'
import { StyledErrorText } from './HoursBreakdown.styled'

interface Props {
  openingHours?: OpeningHours | null
  hasError: boolean
}

export const OpeningHoursItem = ({ openingHours, hasError }: Props) => {
  const openingHoursDisplay = useMemo(() => {
    if (hasError) {
      return (
        <StyledErrorText>
          {translate('errorMessages.couldNotLoadData')}
        </StyledErrorText>
      )
    }

    return openingHours ? <HoursBreakdown openingHours={openingHours} /> : '-'
  }, [hasError, openingHours])

  return (
    <DrawerRow>
      <StyledLeftColumn>
        <DrawerLabel>
          {translate('page.transactionContact.openingHours')}
        </DrawerLabel>
      </StyledLeftColumn>
      <StyledRightColumn>{openingHoursDisplay}</StyledRightColumn>
    </DrawerRow>
  )
}
