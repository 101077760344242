import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { EntityCategories } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { AddSubcategory } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/subcategories'
import { GetSubcategories } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/subcategories'

import {
  AddSubcategory as AddSubcategoryResponse,
  AddSubcategoryVariables,
} from 'types/gql-types/AddSubcategory'
import {
  GetSubcategories as GetSubcategoriesResponse,
  GetSubcategoriesVariables,
} from 'types/gql-types/GetSubcategories'

interface Props {
  category: EntityCategories | undefined
  subcategory: string | null | undefined
  onSuccess?: (id: string) => void
  onFailure?: () => void
}

export const useAddSubcategory = ({
  category,
  subcategory,
  onSuccess,
  onFailure,
}: Props) => {
  const entityUuid = useSelectedEntityUuid()
  const [addSubcategoryMutation, { loading: isLoading, error }] = useMutation<
    AddSubcategoryResponse,
    AddSubcategoryVariables
  >(AddSubcategory, {
    onCompleted: (response) => {
      if (response.addEntitySubcategory) {
        onSuccess?.(response.addEntitySubcategory)
      } else {
        onFailure?.()
      }
    },
    onError: onFailure,
  })

  const addSubcategory = useCallback(() => {
    if (category && subcategory) {
      addSubcategoryMutation({
        variables: {
          entityUuid,
          input: {
            category,
            subcategory,
          },
        },
        update: (cache, result) => {
          if (result.data?.addEntitySubcategory) {
            const data = cache.readQuery<
              GetSubcategoriesResponse,
              GetSubcategoriesVariables
            >({
              query: GetSubcategories,
              variables: { category, entityUuid },
            })
            cache.writeQuery<
              GetSubcategoriesResponse,
              GetSubcategoriesVariables
            >({
              query: GetSubcategories,
              variables: { category, entityUuid },
              data: {
                getEntitySubcategories: [
                  {
                    name: subcategory,
                    predefined: false,
                    id: result.data.addEntitySubcategory,
                  },
                  ...(data?.getEntitySubcategories || []),
                ],
              },
            })
          }
        },
      })
    }
  }, [addSubcategoryMutation, category, subcategory, entityUuid])

  return { addSubcategory, isLoading, error }
}
