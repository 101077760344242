import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'

import { useInternalTransferModal } from 'pages/Transfer/Internal/internal-transfer-routing'
import {
  TransferFieldTypes,
  TransferZellerFieldsSchema,
} from 'pages/Transfer/Transfer.types'
import { translationsShared } from 'translations'

import { useTransferQuery } from '../useTransferQuery/useTransferQuery'

export const useOpenInternalTransferModal = () => {
  const tShared = useTranslations(translationsShared)
  const { openInternalTransferModal: openModal } = useInternalTransferModal()

  const openInternalTransferModal = useCallback(
    (
      values: TransferFieldTypes,
      accountById: ReturnType<typeof useTransferQuery>['accountById']
    ) => {
      const internalTransferValuesResult =
        TransferZellerFieldsSchema.safeParse(values)
      const internalTransferValues = internalTransferValuesResult.success
        ? internalTransferValuesResult.data
        : undefined

      const fromAccount = accountById(internalTransferValues?.from)
      const toAccount = accountById(internalTransferValues?.to)

      if (!internalTransferValuesResult.success || !fromAccount || !toAccount) {
        ErrorLogger.report(
          '[Banking] Failed to find transfer account when opening internal transfer modal'
        )
        showErrorToast(tShared('somethingWentWrong'))
        return
      }

      openModal({
        stage: 'summary',
        transferDetails: {
          from: {
            name: fromAccount.name,
            id: fromAccount.id,
            icon: fromAccount.icon,
            accountNumber: fromAccount.accountDetails?.account,
          },
          to: {
            name: toAccount.name,
            id: toAccount.id,
            icon: toAccount.icon,
            accountNumber: toAccount.accountDetails?.account,
          },
          amount: values.amount,
          reference: internalTransferValuesResult.data.reference,
        },
      })
    },
    [openModal, tShared]
  )

  return openInternalTransferModal
}
