import { Flex, SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const ZellerCardWrapper = styled(SvgIcon)`
  width: 280px;
  height: 176px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 334px;
    height: 210px;
  }
`

export const TilesWrapper = styled(Flex)`
  justify-content: center;
  gap: 12px;
`
