import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetEndOfCurrentWindowVelocityControlTypeFragmentDoc } from '../../utils/getEndOfCurrentWindow.generated'

export type UseSpentFundsCorporateCardDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    velocityWindow: Types.VelocityWindowEnum
    totalSpentAmount: { __typename?: 'Money'; value: string }
  } | null
}

export const UseSpentFundsCorporateCardDebitCardV2FragmentDoc = gql`
  fragment UseSpentFundsCorporateCardDebitCardV2Fragment on DebitCardV2 {
    velocityControl {
      resetsAt
      totalSpentAmount {
        value
      }
      ...GetEndOfCurrentWindowVelocityControlTypeFragment
    }
  }
  ${GetEndOfCurrentWindowVelocityControlTypeFragmentDoc}
` as unknown as TypedDocumentNode<
  UseSpentFundsCorporateCardDebitCardV2Fragment,
  undefined
>
