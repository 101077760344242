import { RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { LabelType } from '@npco/mp-gql-types'

import { AddLabelMutationResponse } from './graphql/addLabel.generated'
import { useAddLabel } from './hooks/useAddLabel/useAddLabel'
import { useDeleteLabel } from './hooks/useDeleteLabel/useDeleteLabel'
import { useGetLabels } from './hooks/useGetLabels/useGetLabels'
import { useUpdateLabel } from './hooks/useUpdateLabel/useUpdateLabel'
import { GetLabel, LabelSelectComboBoxItem } from './LabelControl.types'
import { rvBusinessLabels, rvPersonLabels } from './LabelControl.utils'
import { LabelSelectCombobox } from './LabelSelectCombobox/LabelSelectCombobox'

type AddLabel = AddLabelMutationResponse['addLabel']

export const getInitialSelectedItem = (
  labelItem: LabelSelectComboBoxItem | null,
  labelType: LabelType
): LabelSelectComboBoxItem | null => {
  if (!labelItem) {
    return null
  }

  const labelId = labelItem.id
  const isBusinessLabelType = labelType === LabelType.BUSINESS

  const rvLabels = isBusinessLabelType ? rvBusinessLabels : rvPersonLabels
  const matchingLabel = rvLabels().find(
    (label: GetLabel) => label.id === labelId
  )

  // NOTE: if we have no rvLabel this means we have no optimistic updates for
  // this label as of yet so return existing label
  if (!matchingLabel) {
    return labelItem
  }

  return {
    ...labelItem,
    label: matchingLabel.labelText,
    value: matchingLabel.labelText,
  }
}

interface LabelControlProps {
  labelItem: LabelSelectComboBoxItem | null
  labelType: LabelType
  popperWrapperRef?: RefObject<HTMLDivElement> | null
  setLabel: (label: AddLabel | null) => void
}

export const LabelControl = ({
  labelItem,
  labelType,
  popperWrapperRef,
  setLabel,
}: LabelControlProps) => {
  // NOTE: the initial selected label might have been updated so we
  // must check this against our rvLabel store for the optimistic item
  const initialSelectedItem = getInitialSelectedItem(labelItem, labelType)

  const [selectedItem, setSelectedItem] =
    useState<LabelSelectComboBoxItem | null>(initialSelectedItem)

  const { addLabel, isAddingLabel } = useAddLabel()
  const { deleteLabel, isDeletingLabel } = useDeleteLabel()
  const { isLoadingLabels, labels } = useGetLabels(labelType)
  const { updateLabel, isUpdatingLabel } = useUpdateLabel()

  const items: LabelSelectComboBoxItem[] = useMemo(
    () =>
      labels.map((label) => ({
        id: label.id,
        isEditable: label.isEditable,
        label: label.labelText,
        value: label.labelText,
      })),
    [labels]
  )

  // NOTE: upon loading labels reset selected item
  useEffect(() => {
    if (!isLoadingLabels) {
      const nextSelectedItem = getInitialSelectedItem(labelItem, labelType)
      setSelectedItem(nextSelectedItem)
    }
  }, [isLoadingLabels, labelItem, labelType])

  // NOTE: syncs optimistic rv label updates with current selected item
  useEffect(() => {
    if (selectedItem) {
      const selectedLabel = labels.find(
        (label: GetLabel) => label.id === selectedItem.id
      )

      // NOTE: if our selected item was deleted and no longer exists in rv
      // labels unset the selected item
      if (!selectedLabel) {
        setSelectedItem(null)
        return
      }

      // NOTE: if our rv label text was optimistically updated elsewhere sync
      // the selected item
      if (selectedItem.label !== selectedLabel.labelText) {
        setSelectedItem({
          ...selectedItem,
          label: selectedLabel.labelText,
        })
      }
    }
  }, [selectedItem, labels])

  const isLabelComboboxLoading =
    isAddingLabel || isDeletingLabel || isLoadingLabels || isUpdatingLabel

  const handleAddItem = useCallback(
    async (labelText, onComplete) => {
      const label = await addLabel(labelText, labelType)

      if (label) {
        setLabel(label)

        setSelectedItem({
          id: label.id,
          isEditable: label.isEditable,
          label: label.labelText,
          value: label.labelText,
        })
      }

      onComplete()
    },
    [addLabel, labelType, setLabel, setSelectedItem]
  )

  const handleEditItem = useCallback(
    async (nextItem, onComplete) => {
      const nextLabel = {
        id: nextItem.id,
        isEditable: nextItem.isEditable,
        labelText: nextItem.value,
        type: labelType,
      }

      const result = await updateLabel(nextLabel)

      if (result) {
        const isUpdatedItemSelectedItem = nextItem.id === selectedItem?.id

        if (isUpdatedItemSelectedItem) {
          setLabel(nextLabel)
          setSelectedItem(nextItem)
        }
      }

      onComplete()
    },
    [labelType, selectedItem, setLabel, setSelectedItem, updateLabel]
  )

  const handleDeleteItem = useCallback(
    async (item, onComplete) => {
      if (item) {
        const { id } = item

        const result = await deleteLabel(id, labelType)

        if (result) {
          const isDeletedItemSelectedItem = item.id === selectedItem?.id

          if (isDeletedItemSelectedItem) {
            setLabel(null)
            setSelectedItem(null)
          }
        }

        onComplete()
      }
    },
    [deleteLabel, labelType, selectedItem, setLabel, setSelectedItem]
  )

  const handleSetSelectedItem = useCallback(
    (item) => {
      if (item) {
        // NOTE: convert to contact properties
        setLabel({
          id: item.id,
          isEditable: item.isEditable,
          labelText: item.value,
          type: labelType,
        })
      } else {
        setLabel(item)
      }

      setSelectedItem(item)
    },
    [labelType, setLabel, setSelectedItem]
  )

  return (
    <LabelSelectCombobox<LabelSelectComboBoxItem>
      isLoading={isLabelComboboxLoading}
      items={items}
      onAddItem={handleAddItem}
      onDeleteItem={handleDeleteItem}
      onEditItem={handleEditItem}
      popperWrapperRef={popperWrapperRef}
      selectedItem={selectedItem}
      setSelectedItem={handleSetSelectedItem}
    />
  )
}
