import { EntityCategories } from '@npco/mp-gql-types'

import { ReactComponent as AdvertisingIcon } from 'assets/svg/TransactionCategories/advertising-sm.svg'
import { ReactComponent as BankFeesIcon } from 'assets/svg/TransactionCategories/bank-fees.svg'
import { ReactComponent as CleaningIcon } from 'assets/svg/TransactionCategories/cleaning.svg'
import { ReactComponent as CommissionIcon } from 'assets/svg/TransactionCategories/commission.svg'
import { ReactComponent as ComputerEquipmentIcon } from 'assets/svg/TransactionCategories/computer-equipment-sm.svg'
import { ReactComponent as ConsultingAccountingIcon } from 'assets/svg/TransactionCategories/consulting-accounting.svg'
import { ReactComponent as CostOfGoodsSoldIcon } from 'assets/svg/TransactionCategories/cost-of-goods-sold-sm.svg'
import { ReactComponent as EntertainmentIcon } from 'assets/svg/TransactionCategories/entertainment-sm.svg'
import { ReactComponent as FreightCourierIcon } from 'assets/svg/TransactionCategories/freight-courier.svg'
import { ReactComponent as GeneralExpensesIcon } from 'assets/svg/TransactionCategories/general-expenses.svg'
import { ReactComponent as IncomeTaxExpenseIcon } from 'assets/svg/TransactionCategories/income-tax-expense.svg'
import { ReactComponent as InsuranceIcon } from 'assets/svg/TransactionCategories/insurance.svg'
import { ReactComponent as InterestExpenseIcon } from 'assets/svg/TransactionCategories/interest.svg'
import { ReactComponent as LegalExpensesIcon } from 'assets/svg/TransactionCategories/legal-expenses.svg'
import { ReactComponent as LightPowerHeatingIcon } from 'assets/svg/TransactionCategories/light-power-heating.svg'
import { ReactComponent as MotorVehicleExpensesIcon } from 'assets/svg/TransactionCategories/motor-vehicle-expenses.svg'
import { ReactComponent as OfficeEquipmentIcon } from 'assets/svg/TransactionCategories/office-equipment.svg'
import { ReactComponent as OfficeExpensesIcon } from 'assets/svg/TransactionCategories/office-expenses.svg'
import { ReactComponent as PrintingStationeryIcon } from 'assets/svg/TransactionCategories/printing-stationery.svg'
import { ReactComponent as PurchasesIcon } from 'assets/svg/TransactionCategories/purchases.svg'
import { ReactComponent as RentIcon } from 'assets/svg/TransactionCategories/rent.svg'
import { ReactComponent as SubscriptionsIcon } from 'assets/svg/TransactionCategories/subscriptions-sm.svg'
import { ReactComponent as SuperannuationIcon } from 'assets/svg/TransactionCategories/superannuation.svg'
import { ReactComponent as TelephoneInternetIcon } from 'assets/svg/TransactionCategories/telephone-internet.svg'
import { ReactComponent as TravelInternationalIcon } from 'assets/svg/TransactionCategories/travel-international.svg'
import { ReactComponent as TravelNationalIcon } from 'assets/svg/TransactionCategories/travel-national.svg'
import { ReactComponent as WagesSalariesIcon } from 'assets/svg/TransactionCategories/wages-salaries.svg'
import { translate } from 'utils/translations'

interface CategoryDisplay {
  text: string
  icon?: React.ReactNode
}

type CategoryDisplays = {
  [key in EntityCategories]: CategoryDisplay
}

const uncategorisedDisplay: CategoryDisplay = {
  text: translate('component.editZellerCategories.uncategorised'),
  icon: undefined,
}

const categoryDisplays: CategoryDisplays = {
  [EntityCategories.ADVERTISING]: {
    text: translate('component.editZellerCategories.category.advertising'),
    icon: <AdvertisingIcon />,
  },
  [EntityCategories.BANK_FEES]: {
    text: translate('component.editZellerCategories.category.bankFees'),
    icon: <BankFeesIcon />,
  },
  [EntityCategories.CLEANING]: {
    text: translate('component.editZellerCategories.category.cleaning'),
    icon: <CleaningIcon />,
  },
  [EntityCategories.COMMISSION]: {
    text: translate('component.editZellerCategories.category.commission'),
    icon: <CommissionIcon />,
  },
  [EntityCategories.COMPUTER_EQUIPMENT]: {
    text: translate(
      'component.editZellerCategories.category.computerEquipment'
    ),
    icon: <ComputerEquipmentIcon />,
  },
  [EntityCategories.CONSULTING_ACCOUNTING]: {
    text: translate(
      'component.editZellerCategories.category.consultingAccounting'
    ),
    icon: <ConsultingAccountingIcon />,
  },
  [EntityCategories.COST_OF_GOODS_SOLD]: {
    text: translate('component.editZellerCategories.category.costOfGoodsSold'),
    icon: <CostOfGoodsSoldIcon />,
  },
  [EntityCategories.ENTERTAINMENT]: {
    text: translate('component.editZellerCategories.category.entertainment'),
    icon: <EntertainmentIcon />,
  },
  [EntityCategories.FREIGHT_COURIER]: {
    text: translate('component.editZellerCategories.category.freightCourier'),
    icon: <FreightCourierIcon />,
  },
  [EntityCategories.GENERAL_EXPENSES]: {
    text: translate('component.editZellerCategories.category.generalExpenses'),
    icon: <GeneralExpensesIcon />,
  },
  [EntityCategories.INCOME_TAX_EXPENSE]: {
    text: translate('component.editZellerCategories.category.incomeTaxExpense'),
    icon: <IncomeTaxExpenseIcon />,
  },
  [EntityCategories.INSURANCE]: {
    text: translate('component.editZellerCategories.category.insurance'),
    icon: <InsuranceIcon />,
  },
  [EntityCategories.INTEREST_EXPENSE]: {
    text: translate('component.editZellerCategories.category.interestExpense'),
    icon: <InterestExpenseIcon />,
  },
  [EntityCategories.LEGAL_EXPENSES]: {
    text: translate('component.editZellerCategories.category.legalExpenses'),
    icon: <LegalExpensesIcon />,
  },
  [EntityCategories.LIGHT_POWER_HEATING]: {
    text: translate(
      'component.editZellerCategories.category.lightPowerHeating'
    ),
    icon: <LightPowerHeatingIcon />,
  },
  [EntityCategories.MOTOR_VEHICLE_EXPENSES]: {
    text: translate(
      'component.editZellerCategories.category.motorVehicleExpenses'
    ),
    icon: <MotorVehicleExpensesIcon />,
  },
  [EntityCategories.OFFICE_EQUIPMENT]: {
    text: translate('component.editZellerCategories.category.officeEquipment'),
    icon: <OfficeEquipmentIcon />,
  },
  [EntityCategories.OFFICE_EXPENSES]: {
    text: translate('component.editZellerCategories.category.officeExpenses'),
    icon: <OfficeExpensesIcon />,
  },
  [EntityCategories.PRINTING_STATIONERY]: {
    text: translate(
      'component.editZellerCategories.category.printingStationery'
    ),
    icon: <PrintingStationeryIcon />,
  },
  [EntityCategories.PURCHASES]: {
    text: translate('component.editZellerCategories.category.purchases'),
    icon: <PurchasesIcon />,
  },
  [EntityCategories.RENT]: {
    text: translate('component.editZellerCategories.category.rent'),
    icon: <RentIcon />,
  },
  [EntityCategories.SUBSCRIPTIONS]: {
    text: translate('component.editZellerCategories.category.subscriptions'),
    icon: <SubscriptionsIcon />,
  },
  [EntityCategories.SUPERANNUATION]: {
    text: translate('component.editZellerCategories.category.superannuation'),
    icon: <SuperannuationIcon />,
  },
  [EntityCategories.TELEPHONE_INTERNET]: {
    text: translate(
      'component.editZellerCategories.category.telephoneInternet'
    ),
    icon: <TelephoneInternetIcon />,
  },
  [EntityCategories.TRAVEL_INTERNATIONAL]: {
    text: translate(
      'component.editZellerCategories.category.travelInternational'
    ),
    icon: <TravelInternationalIcon />,
  },
  [EntityCategories.TRAVEL_NATIONAL]: {
    text: translate('component.editZellerCategories.category.travelNational'),
    icon: <TravelNationalIcon />,
  },
  [EntityCategories.WAGES_SALARIES]: {
    text: translate('component.editZellerCategories.category.wagesSalaries'),
    icon: <WagesSalariesIcon />,
  },
}

export const getCategoryDisplay = (category: EntityCategories | null) => {
  if (!category) {
    return uncategorisedDisplay
  }

  return categoryDisplays[category]
}
