import { COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const PopperTrigger = styled.div`
  display: flex;
`

export const PopperAnchor = styled.div``

export const PopperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  background-color: ${({ theme }) => theme.colors.WHITE};

  border: 1px solid ${({ theme }) => `${theme.colors.GREY_150}`};
  border-radius: 4px;
  box-shadow: 2px 2px 6px 0px #0000000a, -2px 2px 6px 0px #0000000a;

  box-sizing: border-box;
  min-width: 220px;
  width: auto;
  max-width: 504px;
`

export const PopperHr = styled.div`
  border-top: 1px solid ${COLOR.GREY_90};
  margin-bottom: 8px;
`

export const PopperFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`
