import { Form, Formik, FormikValues } from 'formik'

import { translate } from 'utils/translations'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'

interface TransferAddModalProps<V> {
  title: string
  onSave: (values: V) => void
  onCancel: () => void
  initialValues: V
  isOpen: boolean
  isLoading: boolean
  children: React.ReactNode
}

export const TransferAddModal = <V extends FormikValues>({
  title,
  onSave,
  onCancel,
  isOpen,
  initialValues,
  isLoading,
  children,
}: TransferAddModalProps<V>) => {
  return (
    <Formik<V> initialValues={initialValues} onSubmit={onSave}>
      {({ submitForm }) => (
        <ModalFormScrollable
          title={title}
          onSave={submitForm}
          isOpen={isOpen}
          isLoading={isLoading}
          confirmLabel={translate('shared.save')}
          cancelLabel={translate('shared.cancel')}
          onCancel={onCancel}
        >
          <Form>{children}</Form>
        </ModalFormScrollable>
      )}
    </Formik>
  )
}
