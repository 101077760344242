import { gql } from '@apollo/client'
import { CloseAccountView } from 'features/CloseAccount/CloseAccountModal/CloseAccount/CloseAccountView/CloseAccountView'

export const CloseAccountQuery = gql`
  query CloseAccountQuery($debitCardAccountUuid: ID!, $entityUuid: ID!) {
    getDebitCardAccountV2(
      debitCardAccountUuid: $debitCardAccountUuid
      entityUuid: $entityUuid
    ) {
      __typename
      ...CloseAccountViewDebitCardAccountV2Fragment
    }
    getBillingAccount(entityUuid: $entityUuid) {
      __typename
      ...CloseAccountViewBillingAccountFragment
    }
    getEntity(entityUuid: $entityUuid) {
      __typename
      ...CloseAccountViewEntityFragment
    }
  }

  ${CloseAccountView.fragments.DebitCardAccountV2}
  ${CloseAccountView.fragments.BillingAccount}
  ${CloseAccountView.fragments.Entity}
`
