import { H5 } from '@npco/zeller-design-system'
import { StyledSelectAdaptiveTrigger } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerAdaptive.styled'
import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledMonthsWrapper } from 'components/Filters/NewFilters/DatePicker/DatePicker.styled'
import { StyledSelectedItemWrapper } from 'components/PickerElements/Elements.styled'
import { StyledDropdownWrapper } from 'components/Shared'

export const StyledWrapper = styled.div`
  padding: 1.25rem 1.5rem 1.5rem;
  border-radius: 15px;
  box-shadow: 0px -0.5px 2px rgba(34, 34, 38, 0.15),
    0px 1px 2px rgba(34, 34, 38, 0.15), 0px 4px 6px rgba(34, 34, 38, 0.08);
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 8.125rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex: 0;
  }
`

export const StyledRangeContainer = styled.div`
  flex: 1 0 50%;

  ${StyledDropdownWrapper} {
    flex-flow: column;
    align-items: stretch;
    justify-content: space-between;

    ${StyledSelectedItemWrapper}, ${StyledSelectAdaptiveTrigger} {
      max-height: 2.5rem;
      width: 100%;
      margin-top: 0.5rem;
    }

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      flex-flow: row;
      align-items: flex-start;
      justify-content: space-between;

      ${StyledSelectedItemWrapper} {
        width: 49%;
      }
    }
  }

  ${StyledMonthsWrapper} {
    margin: -27rem 1rem 0 -1.5rem;
    width: calc(100vw - 2rem);
    max-width: 616px;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      margin: -27rem 0 0;
    }
  }
`

export const StyledTitle = styled(H5)`
  margin: 0 0 0.5rem;
  flex: 0 0 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin: 0 0 1rem;
  }
`

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-left: 0.5rem;
`
