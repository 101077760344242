import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'You have no Contacts',
  description:
    'Manage business relationships by linking transactions, transfers and payments to Contacts. Once you add a Contact, it will be listed here.',
  cardHeading: 'Keep track of your business contacts',
  point1: 'Create transfers and invoices faster',
  point2: 'Link Contacts to transactions',
  point3: 'Track a Contact’s financial interactions',
})
