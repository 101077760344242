import { useMemo } from 'react'

import { getEndOfCurrentDay } from 'pages/Dashboard/utils/timeUtils'
import {
  getTotalsBetween,
  sumFees,
  sumPurchaseAmounts,
  sumRefunds,
  sumSurcharges,
  sumTax,
  sumTips,
  sumTotalSales,
} from 'pages/Dashboard/utils/transactionTotalsUtils'

import {
  PerformancePeriod,
  TransactionTotal,
} from '../DashboardPerformance.types'
import { getCurrentSalesFromDate } from '../DashboardPerformance.utils'

interface UseTransactionSummaryOptions {
  transactionTotals: TransactionTotal[]
  performancePeriod: PerformancePeriod
}

export const useTransactionSummary = ({
  transactionTotals,
  performancePeriod,
}: UseTransactionSummaryOptions) => {
  const from = useMemo(
    () => getCurrentSalesFromDate(performancePeriod),
    [performancePeriod]
  )
  const to = useMemo(() => getEndOfCurrentDay(), [])

  const totalsBeingSummarised = useMemo(
    () => getTotalsBetween(transactionTotals, from, to),
    [transactionTotals, from, to]
  )

  const collected = useMemo(
    () => sumPurchaseAmounts(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  const surcharges = useMemo(
    () => sumSurcharges(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  const tips = useMemo(
    () => sumTips(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  const tax = useMemo(
    () => sumTax(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  const refunds = useMemo(
    () => sumRefunds(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  const total = useMemo(
    () => sumTotalSales(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  const fees = useMemo(
    () => sumFees(totalsBeingSummarised),
    [totalsBeingSummarised]
  )

  return {
    collected,
    surcharges,
    tips,
    tax,
    refunds,
    total,
    fees,
  }
}
