import {
  BodyDefault,
  BodyDefaultTypography,
  DataSmall,
  DataSmallTypography,
  zIndexMap,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

interface RowProps {
  noPadding?: boolean
  withTopBorder?: boolean
  onClick?: () => void
  withCursor?: boolean
  bgColor?: string
}

interface CopyProps {
  maxWidth?: boolean
  withMarginLeft?: boolean
  withCursor?: boolean
}

interface SelectedItemProps {
  isOpen: boolean
  withMarginRight?: boolean
  tabindex?: number
  isHalfWidth?: boolean
}

const withCursor = `cursor: pointer;`

const noPadding = `padding: 0.75rem 0.75rem;`

export const StyledRow = styled.div<RowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.colors.WHITE};
  ${(props) => props.withCursor && withCursor}
  ${({ withTopBorder, theme }) =>
    withTopBorder && `border-top: 1px solid ${theme.colors.GREY_150};`}
  ${(props) => !props.noPadding && noPadding}
`

export const StyledChildrenRow = styled.div`
  ${DataSmallTypography}

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    justify-content: flex-start;
  }
`

export const StyledItem = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 1.125rem;
  ${BodyDefaultTypography}
`

export const StyledSubItem = styled(StyledItem)`
  padding: 0 0 0.75rem 3rem;
  width: auto;
`

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.625rem;
`

export const StyledAmount = styled(DataSmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0 0.25rem 0;
`
const maxWidth = `flex: 1;`
const withMarginLeft = `margin-left: 12px;`

export const StyledCopy = styled(BodyDefault)<CopyProps>`
  display: inline-block;
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  cursor: default;
  ${(props) => props.maxWidth && maxWidth}
  ${(props) => props.withMarginLeft && withMarginLeft}
  ${(props) => props.withCursor && withCursor}
`

export const StyledSelectedItemCopy = styled(StyledCopy)<{
  withNoWrap?: boolean
}>`
  color: ${({ theme }) => theme.colors.BLUE_1000};
  cursor: inherit;
  ${(props) => props.withNoWrap && 'white-space: nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: block;
    width: 4.75rem;
  }
`

const withMarginRight = `margin-right: 8px;`
const withActionStyle = css`
  border-color: ${({ theme }) => theme.colors.BLUE_400};

  svg path {
    fill: ${({ theme }) => theme.colors.BLUE_400};
  }
`

export const StyledSelectedItemWrapper = styled.div<SelectedItemProps>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.isHalfWidth ? 49 : 100)}%;
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  ${(props) => props.withMarginRight && withMarginRight}
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.BLUE_80 : theme.colors.WHITE};
  border-radius: 7px;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  cursor: pointer;
  padding: 0.75rem 1rem;
  height: 3rem;

  &:hover {
    ${withActionStyle}
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_30};
    border-color: ${({ theme }) => theme.colors.BLUE_400};
    color: ${({ theme }) => theme.colors.BLUE_400};
  }
`

const setWidth = (width: number) => `${width}px`

export const StyledItemsWrapper = styled.div.attrs({
  'data-testid': 'picker-options',
})<{ $width?: number }>`
  position: absolute;
  top: 3.5rem;
  width: ${(props) => (props.$width ? setWidth(props.$width) : '100%')};
  background-color: ${({ theme }) => theme.colors.WHITE};
  z-index: ${zIndexMap.popper};
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.MD -
    1}px) {
    margin: auto;
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    width: 100%;
    top: 0;
    margin: auto auto 5rem auto;
  }
`
