import {
  Box,
  COLOR,
  Flex,
  PlusIcon,
  TileSelector as DSTileSelector,
} from '@npco/zeller-design-system'

import { FileWithId } from 'components/File'
import { Image } from 'components/Images/Images.types'

import {
  StyledThumbnail,
  StyledTileSelectorWrapper,
} from './TileSelector.styled'

interface TileSelectorProps {
  images: FileWithId[]
  previewId: string | null
  setPreviewId: (id: string | null) => void
  onAdd: () => void
}

export const TileSelector = ({
  images,
  previewId,
  setPreviewId,
  onAdd,
}: TileSelectorProps) => {
  return (
    <Flex gap="16px" width="100%" justifyContent="center">
      <DSTileSelector
        content={
          <Box backgroundColor={COLOR.WHITE}>
            <PlusIcon />
          </Box>
        }
        onChange={onAdd}
        value="add"
      />
      <StyledTileSelectorWrapper>
        {images.map((image: Image) => (
          <DSTileSelector
            key={image.id}
            content={<StyledThumbnail image={image} height={72} width={72} />}
            onChange={setPreviewId}
            value={image.id}
            selected={previewId === image.id}
          />
        ))}
      </StyledTileSelectorWrapper>
    </Flex>
  )
}
