import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

interface FormButtonsProps {
  onCancel: () => void
  onSave: () => Promise<void>
  isDisabled?: boolean
}

export const FormButtons = ({
  onCancel,
  onSave,
  isDisabled,
}: FormButtonsProps) => {
  const { isSubmitting } = useFormikContext()

  return (
    <Box
      display="grid"
      gridGap="24px"
      gridTemplateColumns="156px 110px"
      pt="16px"
    >
      <ButtonFill
        isLoading={isSubmitting}
        disabled={isDisabled}
        onClick={onSave}
        size={BUTTON_SIZE.MEDIUM}
        type="submit"
      >
        {translate('shared.save')}
      </ButtonFill>
      <ButtonGhost onClick={onCancel} size={BUTTON_SIZE.MEDIUM}>
        {translate('shared.cancel')}
      </ButtonGhost>
    </Box>
  )
}
