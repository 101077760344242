import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type CardSingleManageDropdownDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  accessibleProfile: boolean | null
  name: string
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  format: Types.DebitCardFormat | null
  productType: Types.DebitCardProductType | null
  debitCardAccount: { __typename?: 'DebitCardAccountV2'; id: string }
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
    firstname: string | null
  } | null
  velocityControl: {
    __typename?: 'VelocityControlType'
    velocityWindow: Types.VelocityWindowEnum
    amountLimit: { __typename?: 'Money'; value: string }
    maxTransactionValue: { __typename?: 'Money'; value: string }
    modifiedBy: {
      __typename?: 'VelocityControlModifiedByType'
      actingCustomerUuid: string
      updatedAt: any
    } | null
  } | null
}

export const CardSingleManageDropdownDebitCardV2FragmentDoc = gql`
  fragment CardSingleManageDropdownDebitCardV2Fragment on DebitCardV2 {
    accessibleProfile
    name
    status
    colour
    format
    productType
    debitCardAccount {
      id
    }
    customer {
      id
      entityUuid
      firstname
    }
    velocityControl {
      amountLimit {
        value
      }
      maxTransactionValue {
        value
      }
      velocityWindow
      modifiedBy {
        actingCustomerUuid
        updatedAt
      }
    }
  }
` as unknown as TypedDocumentNode<
  CardSingleManageDropdownDebitCardV2Fragment,
  undefined
>
