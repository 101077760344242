import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { PortalKYCLayout } from 'pages/PortalKYC/components/PortalKYCLayout'

import { BiometricsPageTemplate } from './BiometricsPageTemplate'

export const BiometricsPageDeps = {
  BiometricsPageTemplate,
}

export const BiometricsPage = () => {
  const { BiometricsPageTemplate } = BiometricsPageDeps

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const ctaSaveAndClose = useMemo(
    () => ({
      onClick: () =>
        navigate(ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.SECURITY.path),
    }),
    [navigate, shortEntityId]
  )

  return (
    <PortalKYCLayout ctaSaveAndClose={ctaSaveAndClose}>
      <BiometricsPageTemplate
        onSubmitRoute={ROOT.ORGS.ORG(shortEntityId).BIOMETRICS.ONFIDO.path}
      />
    </PortalKYCLayout>
  )
}
