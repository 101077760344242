import { getCents, getWholePartFromCents } from 'utils/common'

import {
  StyledTotalBalanceDecimal,
  StyledTotalBalanceWhole,
} from './Balance.styled'

interface BalanceProps {
  totalBalance: number
}

export const Balance = ({ totalBalance }: BalanceProps) => {
  const decimalPart = getCents(totalBalance)
  const wholePart = getWholePartFromCents(totalBalance)

  return (
    <>
      <StyledTotalBalanceWhole data-testid="total-whole">
        {wholePart}
      </StyledTotalBalanceWhole>
      <StyledTotalBalanceDecimal data-testid="total-decimal">{`.${decimalPart}`}</StyledTotalBalanceDecimal>
    </>
  )
}
