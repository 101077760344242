import { useEffect } from 'react'
import { MutationFunction } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  CreateCustomer,
  CreateCustomerVariables,
} from 'types/gql-types/CreateCustomer'

import { submitCreateForm } from '../../utils'
import { useCreateAdminMFAState } from './MFAState/useCreateAdminMFAState'

export const useCreateAdminOnMFARedirect = (
  createCustomer: MutationFunction<CreateCustomer, CreateCustomerVariables>
) => {
  const entityUuid = useSelectedEntityUuid()
  const {
    createAdminState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  } = useCreateAdminMFAState()

  useEffect(() => {
    if (createAdminState && hasRedirectedBackToApp) {
      setHasRedirectedBackToApp(false)
      submitCreateForm({
        createCustomer,
        values: createAdminState.variables,
        isXeroPaymentServicesEnabled: true,
        entityUuid,
      })
    }
  }, [
    createAdminState,
    createCustomer,
    entityUuid,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  ])
}
