import { gql } from '@apollo/client'

export const UpdateInvoiceNotes = gql`
  mutation UpdateInvoiceNotes(
    $entityUuid: ID!
    $input: UpdatedInvoiceNotesInput!
  ) {
    updateInvoiceNotes(entityUuid: $entityUuid, input: $input) {
      __typename
      notes
      id
      referenceNumber
    }
  }
`
