import { gql } from '@apollo/client'

export const GetDebitCardAccounts = gql`
  query GetDebitCardAccounts($entityUuid: ID!) {
    getDebitCardAccountsV2(limit: 50, entityUuid: $entityUuid) {
      accounts {
        __typename
        type
        name
        id
        icon {
          colour
          letter
          images {
            size
            url
          }
        }
        balance {
          currency
          value
        }
        status
        cardsCount {
          total
          debit
          expense
        }
      }
    }
  }
`
