import { useNavigate } from 'react-router-dom-v5-compat'
import { PosMethod } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  COLOR,
  Divider,
  Flex,
  Typography,
  useModalState,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { formatDateTime } from 'utils/date'
import { getNonNullString } from 'utils/string'
import { translate } from 'utils/translations'
import { GetDevicePosSettings_getDeviceSettings_posSettings as PosSettingsType } from 'types/gql-types/GetDevicePosSettings'
import { LoadingSkeletonRow } from 'pages/Devices/LoadingSkeletonRow/LoadingSkeletonRow'

import { EditPosRegisterNameModal } from '../../EditPosRegisterNameModal/EditPosRegisterNameModal'
import { translations } from './LinklyDetails.i18n'
import { InlineButtonLink } from './LinklyDetails.styled'

interface PosLinklyDetailsProps {
  posDetails: PosSettingsType
  posLastTransaction: string | null
  isLoading: boolean
  isPosLastTransactionLoading: boolean
  deviceId: string
}

export const LinklyDetails = ({
  posDetails,
  isLoading,
  posLastTransaction,
  isPosLastTransactionLoading,
  deviceId,
}: PosLinklyDetailsProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const { posSoftwareName, posRegisterName, connectionMethod } = posDetails

  const PosConnectionMethod: Record<PosMethod, string> = {
    CLOUD: t('CLOUD'),
    CLOUD_ASYNC: t('CLOUD_ASYNC'),
    LAN: t('LAN'),
    POS_CONNECTOR: t('POS_CONNECTOR'),
  }

  return (
    <>
      <Flex justifyContent="space-between" mb="16px">
        <Typography component="h3" variant="heading-xl">
          {t('posSettingsTitle')}
        </Typography>
        <ButtonLink onClick={openModal}>{translate('shared.edit')}</ButtonLink>
      </Flex>
      <Flex flexDirection="column" gap="8px">
        <LoadingSkeletonRow
          data-testid="software-name"
          isLoading={isLoading}
          label={t('posSoftwareName')}
          value={getNonNullString(posSoftwareName, '-')}
        />
        <LoadingSkeletonRow
          data-testid="connection-method"
          isLoading={isLoading}
          label={t('connectionMethod')}
          value={connectionMethod ? PosConnectionMethod[connectionMethod] : '-'}
        />
        <LoadingSkeletonRow
          data-testid="register-name"
          isLoading={isLoading}
          label={t('posRegisterName')}
          value={getNonNullString(posRegisterName, '-')}
        />
        <LoadingSkeletonRow
          data-testid="last-pos-transcation"
          isLoading={isPosLastTransactionLoading}
          label={t('lastPosTransaction')}
          value={posLastTransaction ? formatDateTime(posLastTransaction) : '-'}
        />
      </Flex>
      <EditPosRegisterNameModal
        posDetails={posDetails}
        deviceId={deviceId}
        isOpen={isModalOpen}
        onCancel={closeModal}
      />
      <Divider />
      <Typography component="h3" variant="heading-xl" mb="16px">
        {t('terminalDisplayTitle')}
      </Typography>
      <Typography component="div" variant="body-sm" color={COLOR.GREY_550}>
        {t('terminalDisplayDescription', {
          link: (
            <InlineButtonLink
              onClick={() => {
                navigate(ROOT.ORGS.ORG(shortEntityId).SITES.path)
              }}
            >
              {t('sites')}
            </InlineButtonLink>
          ),
        })}
      </Typography>
    </>
  )
}
