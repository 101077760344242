import {
  BodyDefaultTypography,
  DataMediumTypography,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledHeaderCell = styled.th`
  padding: 12px 0;
  text-align: left;
  ${InterZellerMediumFont}
  ${DataMediumTypography}
`

export const StyledAmountCell = styled.td`
  ${BodyDefaultTypography}
  padding: 12px 0;
  text-align: right;
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.GREY_150};
  }
`

export const StyledHighlightedAmountCell = styled(StyledAmountCell)<{
  isNegative: boolean
}>`
  ${InterZellerMediumFont}
  ${DataMediumTypography}
  color: ${({ isNegative, theme }) =>
    isNegative ? theme.colors.RED_1000 : theme.colors.BLUE_1000};
`
