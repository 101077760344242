import { Property } from 'csstype'

interface Props {
  children: React.ReactNode
  x?: string | number
  y?: string | number
  fill?: Property.Fill
}

export const SmallText = ({ children, x, y, fill }: Props) => {
  return (
    <text
      x={x}
      y={y}
      dominantBaseline="middle"
      textAnchor="middle"
      style={{
        fontSize: '12px',
        lineHeight: 1.5,
        fill,
      }}
    >
      {children}
    </text>
  )
}
