import { ReactNode } from 'react'
import { ImageSize } from '@npco/mp-gql-types'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'
import { z } from 'zod'

import { TransferContactFields } from './ContactTransfer'

export type TransferDropdownType = string | null

export interface TransferFormTypes {
  from: TransferDropdownType
  to?: TransferDropdownType | object
  code1?: string
  code2?: string
  code3?: string
  code0?: string
  amount?: string
}

export interface TransferZellerFields extends TransferFormTypes {
  to: TransferDropdownType
  amount: string
  reference: string
}

export interface TransferExternalAddFields {
  accountName: string
  bsb: number
  accountNumber: number
  nickname?: string
}

export interface TransferExternalFields extends TransferFormTypes {
  amount: string
  reference: string
  recipientReference: string
  isSelf?: boolean
}

export type ContactToLinkBpayTo = {
  label: string
  subLabel: string | ReactNode
  value: string
  id: string
}

export type ContactOrBillerType = 'Biller' | 'Contact'

export type ContactOrBiller = {
  type: 'Biller' | 'Contact'
  name: string
  code: string
  icon: {
    colour: string | null
    image: string | null
    letter: string | null
    images:
      | {
          size: ImageSize
          url: string
        }[]
      | null
  } | null
  id?: string
  email?: {
    email: string | null
  }
  phone?: string | null
  phoneV2?: {
    phone: string | null
  }
  isSelf?: boolean | null
  billerContacts?: {
    id: string
    name: string
    nickname: string
    code: string
    reference?: string
  }[]
}

export interface TransferBpayFields extends TransferFormTypes {
  amount: string
  from: TransferDropdownType
  businessName?: string
  to?: ContactOrBiller
  nickname?: string
  crn?: string
  paymentInstrumentUuid?: {
    id: string
    type?: string
    label?: string
    subLabel1?: string | ReactNode
    subLabel2?: string
    value?: string
  }
  reference?: string | ContactToLinkBpayTo
  isSelf?: boolean
}

export type TransferFieldTypes =
  | TransferContactFields
  | TransferExternalFields
  | TransferZellerFields
  | TransferBpayFields

export type ExcludedAccountType = [TransferDropdownType, TransferDropdownType]

export type OptimisticDebitCardTransaction =
  | DebitCardTransactionV2
  | DebitCardTransactionV3

type InternalTransferRecipients = {
  from?: string
  to?: string
  amountInCents: undefined
  reference: undefined
}

type InternalTransferFull = {
  from: string
  to: string
  amountInCents?: number
  reference?: string
}

export type TransferType = 'internal'

export type InternalTransferState = {
  transferType: TransferType
} & (InternalTransferRecipients | InternalTransferFull)

export const TransferLocationSchema = z.object({
  Transfer: z.optional(
    z.nullable(
      z.object({
        from: z.optional(z.string()),
        to: z.optional(z.string()),
        amountInCents: z.optional(z.number()),
        reference: z.optional(z.string()),
        transferType: z.literal('internal'),
      })
    )
  ),
})

export const TransferZellerFieldsSchema = z.object({
  from: z.string(),
  to: z.string(),
  amount: z.string(),
  reference: z.string(),
})

export const ContactOrBillerSchema = z.object({
  type: z.union([z.literal('Biller'), z.literal('Contact')]),
  name: z.string(),
  code: z.optional(z.string()),
  id: z.optional(z.string()),
  billerContacts: z.optional(
    z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        nickname: z.string(),
        code: z.string(),
        reference: z.optional(z.string()),
      })
    )
  ),
})

export type BPayTransferResponseType = {
  id: string
  debitCardAccountUuid: string
  paymentInstrumentUuid: string
  billerName: string
  billerCode: string
  crn: string
  amount: { __typename?: 'Money'; currency: string; value: string }
}
