import { useMemo } from 'react'
import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { CategoricalChartState } from 'recharts/types/chart/types'

import { useSelectedDateRange } from '../hooks/useSelectedDateRange'
import { convertToAWSDate } from '../utils/convertToAWSDate/convertToAWSDate'
import { AccountBalanceChart } from './AccountBalanceChart/AccountBalanceChart'
import { getPlaceholderAccountBalanceData } from './AccountBalanceChart/AccountBalanceChart.utils'
import { IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment } from './IncomeAndExpenseChart.generated'
import { mapToChartData } from './IncomeAndExpenseChart.utils'

type IncomeAndExpenseChartProps = {
  isLoadingIncomeAndExpenses: boolean
  incomeAndExpenses: IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment[]
  goToPreviousMonth?: () => void
  goToNextMonth?: () => void
  handleChartBarClick?: (state: CategoricalChartState) => void
  isFetchingMore: boolean
  timezone?: string
}

export const IncomeAndExpenseChart = ({
  isLoadingIncomeAndExpenses,
  incomeAndExpenses,
  isFetchingMore,
  timezone,
  goToPreviousMonth,
  goToNextMonth,
  handleChartBarClick,
}: IncomeAndExpenseChartProps) => {
  const { selectedDateRange } = useSelectedDateRange()

  const accountBalanceChartData = useMemo(
    () => mapToChartData(incomeAndExpenses, timezone),
    [incomeAndExpenses, timezone]
  )

  const selectedBar = useMemo(() => {
    if (
      selectedDateRange === 'Last 12 Months' ||
      selectedDateRange.type === 'TTM'
    ) {
      return undefined
    }
    return dayjs(convertToAWSDate(selectedDateRange, timezone))
      .format('MMM')
      .toUpperCase()
  }, [selectedDateRange, timezone])

  return (
    <AccountBalanceChart
      isLoading={isLoadingIncomeAndExpenses}
      data={
        isLoadingIncomeAndExpenses || isFetchingMore
          ? getPlaceholderAccountBalanceData({ length: 12, isFetchingMore })
          : accountBalanceChartData
      }
      handleChartBarClick={handleChartBarClick}
      selectedBar={selectedBar}
      handleSelectPrevious={goToPreviousMonth}
      handleSelectNext={goToNextMonth}
      isFetchingMore={isFetchingMore}
    />
  )
}

IncomeAndExpenseChart.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...MapToChartDataCashFlowPeriodicTotalAmountFragment
    }

    ${mapToChartData.fragments.CashFlowPeriodicTotalAmount}
  `,
}
