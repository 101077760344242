import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardsSettingsOutstandingExpensesFragmentDoc } from '../CardsSettingsOutstandingExpenses.generated'

const defaultOptions = {} as const
export type GetOutstandingTransactionRequirementConfigQueryVariables =
  Types.Exact<{
    entityUuid: Types.Scalars['ID']['input']
  }>

export type GetOutstandingTransactionRequirementConfigQueryResponse = {
  __typename?: 'Query'
  getEntity: {
    __typename?: 'Entity'
    id: string
    outstandingTransactionRequirementConfig: {
      __typename?: 'OutstandingTransactionRequirementConfig'
      attachments: boolean
      category: boolean
      note: boolean
      accountingCategory: boolean
    } | null
  }
}

export const GetOutstandingTransactionRequirementConfig = gql`
  query GetOutstandingTransactionRequirementConfig($entityUuid: ID!) {
    getEntity(entityUuid: $entityUuid) {
      id
      ...CardsSettingsOutstandingExpensesFragment
    }
  }
  ${CardsSettingsOutstandingExpensesFragmentDoc}
` as unknown as TypedDocumentNode<
  GetOutstandingTransactionRequirementConfigQueryResponse,
  GetOutstandingTransactionRequirementConfigQueryVariables
>

/**
 * __useGetOutstandingTransactionRequirementConfigQuery__
 *
 * To run a query within a React component, call `useGetOutstandingTransactionRequirementConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingTransactionRequirementConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingTransactionRequirementConfigQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetOutstandingTransactionRequirementConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOutstandingTransactionRequirementConfigQueryResponse,
    GetOutstandingTransactionRequirementConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOutstandingTransactionRequirementConfigQueryResponse,
    GetOutstandingTransactionRequirementConfigQueryVariables
  >(GetOutstandingTransactionRequirementConfig, options)
}
export function useGetOutstandingTransactionRequirementConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutstandingTransactionRequirementConfigQueryResponse,
    GetOutstandingTransactionRequirementConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOutstandingTransactionRequirementConfigQueryResponse,
    GetOutstandingTransactionRequirementConfigQueryVariables
  >(GetOutstandingTransactionRequirementConfig, options)
}
export type GetOutstandingTransactionRequirementConfigQueryHookResult =
  ReturnType<typeof useGetOutstandingTransactionRequirementConfigQuery>
export type GetOutstandingTransactionRequirementConfigLazyQueryHookResult =
  ReturnType<typeof useGetOutstandingTransactionRequirementConfigLazyQuery>
export type GetOutstandingTransactionRequirementConfigQueryResult =
  Apollo.QueryResult<
    GetOutstandingTransactionRequirementConfigQueryResponse,
    GetOutstandingTransactionRequirementConfigQueryVariables
  >
