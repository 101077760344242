import { CatalogSalesSortColumnNames } from '@npco/mp-gql-types'

import { getDateRangeForPeriod } from 'components/Filters/PillFilters/DateRange/PeriodButtons.utils'

import type { LocalStateItemsReports } from './Items.types'

export const ItemsReportsDefaultFilters = {
  dates: getDateRangeForPeriod('thisMonth'),
}

export const ItemsReportsDefaultLocalState: LocalStateItemsReports = {
  shared: {
    filters: {
      ...ItemsReportsDefaultFilters,
    },
    isRowFiltersVisible: true,
    isMobileFiltersVisible: false,
  },
  items: {
    sort: {
      ascending: false,
      columnName: CatalogSalesSortColumnNames.REVENUE,
    },
  },
}
