import { useTranslations } from '@npco/utils-translations'
import { Box, InfoFilledIcon, TooltipButton } from '@npco/zeller-design-system'

import { TOOLTIP_ZINDEX } from '../../../../../const/zIndex'
import { taxFileNumberFieldTranslations } from './TaxFileNumberField.i18n'

export const TaxFileNumberTooltip = () => {
  const t = useTranslations(taxFileNumberFieldTranslations)

  return (
    <TooltipButton
      tooltipIcon={<InfoFilledIcon />}
      zIndex={parseInt(TOOLTIP_ZINDEX, 10)}
      placement="top"
      showArrow={false}
    >
      <Box maxWidth="226px" textAlign="center">
        {t('tooltip')}
      </Box>
    </TooltipButton>
  )
}
