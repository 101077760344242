import { useCallback, useMemo, useState } from 'react'

import { OutstandingFilterContext } from './OutstandingFilterContext'

interface OutstandingFilterContextProviderProps {
  initialFilterValue?: boolean
  children: React.ReactNode
}

export const OutstandingFilterContextProvider = ({
  initialFilterValue = false,
  children,
}: OutstandingFilterContextProviderProps) => {
  const [isFilterOn, setIsFilterOn] = useState(initialFilterValue)

  const toggleIsOutstandingFilter = useCallback(() => {
    setIsFilterOn((prev) => !prev)
  }, [])

  const providerValue = useMemo(
    () => ({
      isOutstandingFilterOn: isFilterOn,
      toggleIsOutstandingFilter,
    }),
    [isFilterOn, toggleIsOutstandingFilter]
  )

  return (
    <OutstandingFilterContext.Provider value={providerValue}>
      {children}
    </OutstandingFilterContext.Provider>
  )
}
