import { gql } from '@apollo/client'
import { ExtendedColumnDef } from '@npco/ui-table'
import { COLOR, TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useSkeletonLoaderWidth } from 'hooks/useSkeletonLoaderWidth'

import { SimBillingDisplayStatus } from '../../Sim.type'
import {
  mapSimBillingStatusToDisplayStatus,
  simListMessageMap,
} from '../../Sim.utils'
import { SimStatusCellFragment } from './SimStatusCell.generated'

export const SimStatusCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, SimStatusCellFragment>) => {
  const columnDefValue = column.columnDef as ExtendedColumnDef<T>
  const isLoading = table.options.meta?.isLoading
  const simBillingDisplayStatus = isLoading
    ? undefined
    : mapSimBillingStatusToDisplayStatus(
        getValue<SimStatusCellFragment>()?.billingStatus
      )

  const statusInfo = simBillingDisplayStatus
    ? simListMessageMap[simBillingDisplayStatus]
    : '-'

  const skeletonLoaderWidth = useSkeletonLoaderWidth({
    rowIndex: row.index,
    sampleWidthArray: ['64%', '64%', '77%'],
  })

  return (
    <TableCellText
      key={cell.id}
      cellSize={columnDefValue.cellSize}
      dataTestId={`sim-row-${row.index}-status`}
      color={
        simBillingDisplayStatus === SimBillingDisplayStatus.ACTIVE
          ? COLOR.GREEN_1600
          : COLOR.BLACK_900
      }
      justifyContent="flex-end"
      isLoading={isLoading}
      text={statusInfo}
      skeletonProps={{ width: skeletonLoaderWidth }}
    />
  )
}

SimStatusCell.fragments = {
  Sim: gql`
    fragment SimStatusCellFragment on Sim {
      billingStatus
    }
  `,
}
