import {
  AnimationWrapper,
  IllustrationWrapper,
} from './IllustrationAnimation.styled'

interface IllustrationAnimationProps {
  imagesSmall: string[]
  imagesMedium: string[]
  imagesLarge: string[]
  interval: number
}
export const IllustrationAnimation = ({
  imagesSmall,
  imagesMedium,
  imagesLarge,
  interval,
}: IllustrationAnimationProps) => {
  const totalDuration = interval * 3
  return (
    <IllustrationWrapper>
      <AnimationWrapper
        duration={totalDuration}
        mobileImagePath={imagesSmall[2]}
        tabletImagePath={imagesMedium[2]}
        desktopImagePath={imagesLarge[2]}
        isLastImageContainer
      />
      <AnimationWrapper
        animationDelay={-interval}
        duration={totalDuration}
        mobileImagePath={imagesSmall[1]}
        tabletImagePath={imagesMedium[1]}
        desktopImagePath={imagesLarge[1]}
      />
      <AnimationWrapper
        animationDelay={-interval * 2}
        duration={totalDuration}
        mobileImagePath={imagesSmall[0]}
        tabletImagePath={imagesMedium[0]}
        desktopImagePath={imagesLarge[0]}
      />
    </IllustrationWrapper>
  )
}
