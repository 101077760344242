import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { createNewAccountIcon } from 'components/AccountFields/PersonaliseAccountFields/AccountAvatarField/AccountAvatarField.utils'
import { PersonaliseAccountValues } from 'components/AccountFields/PersonaliseAccountFields/PersonaliseAccountFields.types'

import * as EditAccountMutation from '../../graphql/editAccount.generated'

type EditAccountMutationProps = PersonaliseAccountValues & {
  id: string
}

export const useEditAccountMutation = (): [
  (values: EditAccountMutationProps) => Promise<undefined | { error: unknown }>,
  { loading: boolean }
] => {
  const entityUuid = useSelectedEntityUuid()
  const [$execute, { loading }] = useMutation<
    EditAccountMutation.EditAccountMutationOptions,
    EditAccountMutation.EditAccountMutationVariables
  >(EditAccountMutation.EditAccount)

  return [
    useCallback(
      async ({ id, name, accountColour }) => {
        const icon = createNewAccountIcon({ name, accountColour })

        try {
          await $execute({
            variables: {
              entityUuid,
              values: {
                id,
                name,
                icon: {
                  colour: icon.colour,
                  letter: icon.letter,
                },
              },
            },
          })
          return undefined
        } catch (error) {
          return { error }
        }
      },

      [entityUuid, $execute]
    ),
    { loading },
  ]
}
