import { useReactiveVar } from '@apollo/client'
import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'
import { rvUpdatedEmailNotConfirmed } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { getDataValue } from 'utils/settings'
import { translate } from 'utils/translations'
import { CustomerDetailsForm } from 'types/customers'
import { ProfileSettingsData } from 'types/profile'
import { Tooltip } from 'components/Tooltip'

import {
  StyledDetailsLabel,
  StyledDetailsValue,
  StyledEmailValue,
  StyledWrapper,
} from './UserDetailsList.styled'

interface Props {
  data: CustomerDetailsForm | ProfileSettingsData
  isPhoneShown?: boolean
}

export const UserDetailsList = ({ data, isPhoneShown }: Props) => {
  const updatedEmail = useReactiveVar(rvUpdatedEmailNotConfirmed)

  return (
    <div data-testid="user-details-list">
      <StyledWrapper>
        <StyledDetailsLabel>
          {translate('page.settings.users.firstName')}
        </StyledDetailsLabel>
        <StyledDetailsValue>
          {getDataValue(data, 'firstname')}
        </StyledDetailsValue>
      </StyledWrapper>
      <StyledWrapper>
        <StyledDetailsLabel>
          {translate('page.settings.users.middleName')}
        </StyledDetailsLabel>
        <StyledDetailsValue>
          {getDataValue(data, 'middlename')}
        </StyledDetailsValue>
      </StyledWrapper>
      <StyledWrapper>
        <StyledDetailsLabel>
          {translate('page.settings.users.lastName')}
        </StyledDetailsLabel>
        <StyledDetailsValue>
          {getDataValue(data, 'lastname')}
        </StyledDetailsValue>
      </StyledWrapper>
      {isPhoneShown && (
        <StyledWrapper>
          <StyledDetailsLabel>
            {translate('page.settings.users.mobilePhone')}
          </StyledDetailsLabel>
          <StyledDetailsValue>{getDataValue(data, 'phone')}</StyledDetailsValue>
        </StyledWrapper>
      )}
      <StyledWrapper>
        <StyledDetailsLabel>
          {translate('page.settings.users.email')}
        </StyledDetailsLabel>
        <Flex justifyContent="right">
          <StyledEmailValue>
            {updatedEmail || getDataValue(data, 'email')}
          </StyledEmailValue>

          {updatedEmail && (
            <Flex ml="0.5rem">
              <Tooltip
                placement="bottom"
                tooltipIcon={
                  <SvgIcon width="16" height="16" color={COLOR.GREY_250}>
                    <InfoIcon data-testid="info-icon" />
                  </SvgIcon>
                }
              >
                {translate(
                  'page.settings.users.changeEmail.confirmationTooltip'
                )}
              </Tooltip>
            </Flex>
          )}
        </Flex>
      </StyledWrapper>
    </div>
  )
}
