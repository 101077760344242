import { DebitCardProductType } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'

export const getCardsListRoute = (
  shortEntityId: string,
  productType?: DebitCardProductType | null
) =>
  productType === DebitCardProductType.EXPENSE
    ? ROOT.ORGS.ORG(shortEntityId).CARDS.CORPORATE.path
    : ROOT.ORGS.ORG(shortEntityId).CARDS.DEBIT.path
