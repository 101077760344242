import { useTranslations } from '@npco/utils-translations'
import { COLOR, Typography } from '@npco/zeller-design-system'

import { translations } from '../SignalStrength.i18n'

type WifiSignalStrengthProps = {
  strength?: string
}

export const WifiSignalStrength = ({ strength }: WifiSignalStrengthProps) => {
  const t = useTranslations(translations)
  if (!strength) {
    return <Typography color={COLOR.RED_1000}>{t('unavailable')}</Typography>
  }

  const strengthInt = parseInt(strength, 10)
  if (strengthInt <= -80) {
    return <Typography color={COLOR.RED_1000}>{t('veryWeak')}</Typography>
  }
  if (strengthInt <= -70) {
    return <Typography color={COLOR.RED_1000}>{t('weak')}</Typography>
  }

  if (strengthInt <= -67) {
    return <Typography color={COLOR.BLACK_900}>{t('good')}</Typography>
  }
  if (strengthInt <= -31) {
    return <Typography color={COLOR.GREEN_1600}>{t('veryGood')}</Typography>
  }
  return <Typography color={COLOR.GREEN_1600}>{t('excellent')}</Typography>
}
