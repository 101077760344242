import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardStatus/useCardStatus.generated'
import { CardStatusDebitCardV2FragmentDoc } from '../CardStatus/CardStatus.generated'

export type CardStatusCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
}

export const CardStatusCellDebitCardV2FragmentDoc = gql`
  fragment CardStatusCellDebitCardV2Fragment on DebitCardV2 {
    ...UseCardStatusDebitCardV2Fragment
    ...CardStatusDebitCardV2Fragment
  }
  ${UseCardStatusDebitCardV2FragmentDoc}
  ${CardStatusDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<CardStatusCellDebitCardV2Fragment, undefined>
