import { makeVar } from '@apollo/client'
import { DocumentType } from '@npco/mp-gql-types'

import { KYCData } from 'types/kyc'

export const rvKYCData = makeVar<KYCData>({
  attempts: {
    [DocumentType.DRIVING_LICENCE]: 0,
    [DocumentType.MEDICARE_CARD]: 0,
    [DocumentType.PASSPORT]: 0,
  },
})
