import { Box } from '@npco/zeller-design-system'

import { useSubscribeToAccounts } from 'hooks/banking'
import { useGetEntityDailyLimit } from 'hooks/useGetEntityDailyLimit'

import { AccountDetailsViewError } from './AccountDetailsViewError'
import { AccountDetailsViewLoading } from './AccountDetailsViewLoading'
import { AccountDetailsViewValue } from './AccountDetailsViewValue'
import { useAccountDetailsViewQuery } from './useAccountDetailsViewQuery'

const Container = ({
  loading,
  children,
}: {
  loading: boolean
  children?: React.ReactNode
}) => (
  <Box maxWidth="504px" mt={{ _: '-14px', MD: '2px' }} aria-busy={loading}>
    {children}
  </Box>
)

export type AccountDetailsViewProps = Record<string, never>

export const AccountDetails = () => {
  const {
    account,
    loading: accountLoading,
    error: accountError,
  } = useAccountDetailsViewQuery()

  const {
    dailyMaximumLimitFormatted,
    loading: limitLoading,
    error: limitError,
  } = useGetEntityDailyLimit()

  useSubscribeToAccounts({
    skip: accountLoading || Boolean(accountError),
  })

  const loading = accountLoading || limitLoading

  if (loading) {
    return (
      <Container loading={loading}>
        <AccountDetailsViewLoading />
      </Container>
    )
  }

  if (accountError || !account) {
    return (
      <Container loading={loading}>
        <AccountDetailsViewError />
      </Container>
    )
  }

  return (
    <Container loading={loading}>
      <AccountDetailsViewValue
        account={account}
        dailyMaximumLimit={dailyMaximumLimitFormatted}
        dailyMaximumLimitError={Boolean(limitError)}
      />
    </Container>
  )
}
