import { EntityCategories } from '@npco/mp-gql-types'
import { SelectSelectedItem } from 'design-system/Components/Select'

import { getEntityCategory } from 'utils/banking/getEntityCategories/getEntityCategory'
import { translate } from 'utils/translations'
import { CategoryWithSubcategory } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { CategoryWithSubcategories } from 'layouts/AccountLayout/hooks/useGetCategoriesWithSubcategories/useGetCategoriesWithSubcategories'

const SLASH_REGEX = /\s{0,10}\/\s{0,10}/g
export interface CategoryListItem {
  label: string
  value: string
  category: EntityCategories
  subcategory: string | null
}

const replaceSlashWithAmpersand = (stringToModify: string) =>
  stringToModify.replace(
    SLASH_REGEX,
    translate('component.accountFilterCategory.slashReplacement')
  )

export const getCategoriesItems = (
  categories: CategoryWithSubcategories[]
): CategoryListItem[] =>
  categories.flatMap(({ category, subcategories }) => [
    {
      value: category,
      label: `${getEntityCategory(category)} / ${translate(
        'page.accounts.transactionsList.filters.allItems'
      )}`,
      category,
      subcategory: null,
    },
    ...subcategories.map(({ name: subcategory, id: subcategoryId }) => ({
      value: `${category}-${subcategoryId}`,
      label: `${getEntityCategory(category)} / ${replaceSlashWithAmpersand(
        subcategory
      )}`,
      category,
      subcategory: subcategoryId,
    })),
  ])

interface Args {
  selectedCategory: SelectSelectedItem<CategoryListItem>
  setCategory: React.Dispatch<
    React.SetStateAction<CategoryWithSubcategory | null>
  >
}

export const handleCategoryChange = ({
  selectedCategory,
  setCategory,
}: Args) => {
  if (!selectedCategory) {
    return
  }

  const { category: newCategory, subcategory: newSubcategory } =
    selectedCategory

  setCategory({
    category: newCategory,
    subcategory: newSubcategory,
  })
}
