import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { getColorFromResponse } from '@npco/utils-colors'
import { ErrorLogger } from '@npco/utils-error-logger'

import { useGoToEditAccountStage } from 'hooks/useGoToEditAccountStage/useGoToEditAccountStage'
import { EditAccountStage } from 'hooks/useGoToEditAccountStage/useGoToEditAccountStage.types'
import { translate } from 'utils/translations'
import { AVATAR_BADGE_SIZE } from 'types/common'
import { getDefaultNewAccountColor } from 'components/AccountFields/PersonaliseAccountFields/AccountAvatarField/AccountAvatarField.utils'
import { ListItemHeader } from 'components/List'

import { AccountDetailsHeaderDebitCardAccountV2Fragment } from './AccountDetailsHeader.generated'

type AccountDetailsHeaderProps = {
  account: AccountDetailsHeaderDebitCardAccountV2Fragment
}

export const AccountDetailsHeader = ({
  account,
}: AccountDetailsHeaderProps) => {
  const { goToEditAccountStage } = useGoToEditAccountStage()

  const accountAvatarColour =
    getColorFromResponse(account.icon?.colour) ?? getDefaultNewAccountColor()

  const openEditModal = useCallback(() => {
    if (account.type === DebitCardAccountType.EML_PREPAID) {
      ErrorLogger.reportError(
        '[Banking] - Attempting to personalise EML account',
        undefined
      )
      return
    }

    goToEditAccountStage({
      stage: EditAccountStage.EditAccount,
      accountId: account.id,
      name: account.name,
      accountColour: accountAvatarColour,
      accountType: account.type,
    })
  }, [
    account.name,
    account.id,
    account.type,
    accountAvatarColour,
    goToEditAccountStage,
  ])

  return (
    <ListItemHeader
      title={account.name}
      icon={
        <IconAvatar
          icon={account.icon}
          defaultLetter={account.name}
          badgeSize={AVATAR_BADGE_SIZE.DEFAULT}
        />
      }
      actionEditText={translate('page.settings.accountDetails.actionEdit')}
      actionEditOnClick={openEditModal}
    />
  )
}

AccountDetailsHeader.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountDetailsHeaderDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      name
      type
      icon {
        ...IconAvatarIconFragment
      }
    }

    ${IconAvatar.fragments.Icon}
  `,
}
