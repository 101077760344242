import { BodyDefault, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

export const StyledFieldContainer = styled.div`
  display: flex;
`

export const StyledDetailName = styled(H5)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledDetailValue = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  text-align: right;
  word-break: break-word;
  margin-left: auto;
`
