import { createTranslations } from '@npco/utils-translations'

export const cardEmptyViewAccountTranslations = createTranslations({
  title: 'Add Cards to your Zeller Account.',
  description:
    'Create virtual or physical cards to manage business spending, or track team expenses.',
  item1: 'Use Zeller Debit Card for business purchases and expenses.',
  item2: 'Assign Zeller Corporate Cards to teams or projects.',
  item3: 'Attach notes, receipts, or invoices to transactions.',
  ctaDescription:
    'Add your first card and link to your nominated Transaction Account to get started. ',
  imageAlt: 'Two Zeller-branded debit cards floating: one white, one black',
})
