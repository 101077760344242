import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  DebitCardAccountTransactionStatusEnum,
  DebitCardTransactionStatusV2,
  EntityCategories,
} from '@npco/mp-gql-types'
import {
  DebitCardTransactionsV2Fragment,
  DebitCardTransactionsV2FragmentDoc,
} from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { rvUpdatedDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  DebitCardTransactionFragment as DebitCardTransactionV3,
  DebitCardTransactionFragmentDoc as DebitCardTransactionV3FragmentDoc,
} from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

import { getCurrentTime } from 'pages/Dashboard/utils/timeUtils'

import { updateOutstandingTransactionsCount } from '../../utils/updateOutstandingTransactionsCount'
import { useOutstandingFilterSettings } from '../useOutstandingFilterSettings'
import { useReadUpdatedOutstandingTransactionsLocalCache } from './useReadUpdatedOutstandingTransactionsLocalCache'
import { LocalStateOutstandingTransaction } from './useUpdatedOutstandingTransactions.types'
import { filterOutdatedTransactions } from './useUpdatedOutstandingTransactions.utils'
import { useWriteUpdatedOutstandingTransactionsLocalCache } from './useWriteUpdatedOutstandingTransactionsLocalCache'

type SubcategoryDetails = DebitCardTransactionsV2Fragment['subcategoryDetails']

interface UpdateOptions {
  note?: string | null
  attachments?: string[] | null
  category?: EntityCategories | null
  subcategory?: string | null
  subcategoryDetails?: SubcategoryDetails | null
}

export const useUpdatedOutstandingTransactions = (
  debitCardTransactionUuid: string
) => {
  const { cache } = useApolloClient()
  const { outstandingSettings, isOutstandingFilterSettingsAllInactive } =
    useOutstandingFilterSettings()
  const { updatedOutstandingTransactions } =
    useReadUpdatedOutstandingTransactionsLocalCache()
  const { updateCache } = useWriteUpdatedOutstandingTransactionsLocalCache()

  const updatedDebitCardTransactions = rvUpdatedDebitCardTransactions()

  const cachedTransactionV2 =
    cache.readFragment<DebitCardTransactionsV2Fragment>({
      fragment: DebitCardTransactionsV2FragmentDoc,
      id: cache.identify({
        id: debitCardTransactionUuid,
        __typename: 'DebitCardTransactionV2',
      }),
    })

  const cachedTransactionV3 = cache.readFragment<DebitCardTransactionV3>({
    fragment: DebitCardTransactionV3FragmentDoc,
    id: cache.identify({
      id: debitCardTransactionUuid,
      __typename: 'DebitCardAccountTransaction',
    }),
  })

  const existingOptimisticTransaction = updatedDebitCardTransactions.find(
    (optimisticTransaction) =>
      optimisticTransaction.id === debitCardTransactionUuid
  )

  const cacheCurrentUpdatedOutstandingTransaction = useCallback(
    ({ update }: { update: UpdateOptions }) => {
      if (isOutstandingFilterSettingsAllInactive) {
        return
      }

      const transaction =
        existingOptimisticTransaction ??
        cachedTransactionV2 ??
        cachedTransactionV3

      if (
        !transaction ||
        (transaction.status !== DebitCardTransactionStatusV2.APPROVED &&
          transaction.status !== DebitCardAccountTransactionStatusEnum.APPROVED)
      ) {
        return
      }

      const newTransaction: LocalStateOutstandingTransaction = {
        transaction: { ...transaction, ...update },
        timestamp: getCurrentTime().valueOf(),
      }

      updateOutstandingTransactionsCount({
        prevTransaction: transaction,
        nextTransaction: newTransaction.transaction,
        settings: outstandingSettings,
        cache,
      })

      const updatedOutstandingTransactionsWithoutCurrent =
        updatedOutstandingTransactions.filter(
          (updatedOutstandingTransaction) =>
            updatedOutstandingTransaction.transaction.id !==
            debitCardTransactionUuid
        )

      updateCache(
        filterOutdatedTransactions([
          ...updatedOutstandingTransactionsWithoutCurrent,
          newTransaction,
        ])
      )
    },
    [
      cache,
      updateCache,
      cachedTransactionV2,
      cachedTransactionV3,
      existingOptimisticTransaction,
      updatedOutstandingTransactions,
      debitCardTransactionUuid,
      isOutstandingFilterSettingsAllInactive,
      outstandingSettings,
    ]
  )

  return {
    cacheCurrentUpdatedOutstandingTransaction,
  }
}
