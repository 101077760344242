import { useEffect, useMemo } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  SelectHeadingTrigger,
  showErrorToast,
  SkeletonBasic,
} from '@npco/zeller-design-system'

import { useCashflowReportingAccountsListQuery } from '../../hooks/useCashflowReportingAccountsListQuery/useCashflowReportingAccountsListQuery'
import { useSelectedAccountId } from '../../hooks/useSelectedAccount/useSelectedAccountId'
import { useSetSelectedAccount } from '../../hooks/useSelectedAccount/useSetSelectedAccount'
import { accountSelectTranslations } from './AccountSelect.i18n'
import * as styled from './AccountSelect.styled'
import { getById } from './getById'
import { PopperItems } from './PopperItems/PopperItems'

export const AccountSelect = () => {
  const { accounts, isLoadingAccounts, error, refetch } =
    useCashflowReportingAccountsListQuery()

  const selectedAccountId = useSelectedAccountId()
  const { setSelectedAccount, resetSelectedAccount } = useSetSelectedAccount()

  const t = useTranslations(accountSelectTranslations)

  const selectedAccount = useMemo(() => {
    if (selectedAccountId === 'all accounts') {
      return 'all accounts'
    }

    return getById({
      list: accounts,
      isloading: isLoadingAccounts,
      id: selectedAccountId.accountId,
    })
  }, [accounts, isLoadingAccounts, selectedAccountId])

  useEffect(() => {
    if (selectedAccount === null) {
      showErrorToast(t('accountNotFound'))
      resetSelectedAccount()
    }
  }, [
    isLoadingAccounts,
    resetSelectedAccount,
    selectedAccount,
    selectedAccountId,
    t,
  ])

  if (!error && !accounts && !isLoadingAccounts) {
    // This could be a possible scenario where all accounts are closed or user has no account.
    // Leave it as it is for now until Faz create an Figma for it.
    return <div>Create a new account...</div>
  }

  if (selectedAccount === null || selectedAccount === 'loading') {
    return <SkeletonBasic width="200px" height="12px" isRounded />
  }

  return (
    <styled.StyledPopper
      popperWidth="355px"
      renderTrigger={({ isOpen, setIsOpen }) => (
        <SelectHeadingTrigger
          maxWidth="100%"
          shouldEllipsize
          id={
            selectedAccountId === 'all accounts'
              ? selectedAccountId
              : selectedAccountId.accountId
          }
          selectedItem={
            selectedAccount === 'all accounts'
              ? undefined
              : {
                  value: selectedAccount.id,
                  label: selectedAccount.name,
                }
          }
          onReset={resetSelectedAccount}
          isOpen={isOpen}
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
          placeholder={t('allAccounts')}
        />
      )}
    >
      {({ setIsOpen }) => (
        <Box py="16px" px="8px" maxHeight="408px" overflowY="scroll">
          <PopperItems
            accounts={accounts}
            setSelectedAccount={setSelectedAccount}
            setIsOpen={setIsOpen}
            isLoading={isLoadingAccounts}
            refetch={refetch}
            error={error}
            selectedAccountId={
              selectedAccountId === 'all accounts'
                ? undefined
                : selectedAccountId.accountId
            }
          />
        </Box>
      )}
    </styled.StyledPopper>
  )
}

AccountSelect.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountSelectDebitCardAccountV2Fragment on DebitCardAccountV2 {
      ...PopperItemsDebitCardAccountV2Fragment
    }

    ${PopperItems.fragments.DebitCardAccountV2}
  `,
}
