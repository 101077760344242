import { Flex, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SyncingHeading = styled(H3)`
  text-align: center;
`

export const SyncingMessage = styled.p`
  font-size: 14px;
  text-align: center;
`

export const SyncingContainer = styled(Flex)`
  width: 100%;
`
