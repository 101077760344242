import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { LinkSubcategoryToContact } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/contacts'

import {
  LinkSubcategoryToContact as LinkSubcategoryToContactResponse,
  LinkSubcategoryToContactVariables,
} from 'types/gql-types/LinkSubcategoryToContact'

import { CategoryOptions } from './categories.types'

const MISSING_CATEGORY_VALUES =
  'Categories cannot be updated with null category, subcategoryId or contact id'

interface Props {
  contactUuid: string | undefined
  onSuccess?: (options: CategoryOptions) => void
  onFailure?: () => void
}

export const useUpdateContactCategories = ({
  contactUuid,
  onSuccess,
  onFailure,
}: Props) => {
  const entityUuid = useSelectedEntityUuid()
  const [linkSubcategoryToContact, { loading: isLinkingSubcategoryToContact }] =
    useMutation<
      LinkSubcategoryToContactResponse,
      LinkSubcategoryToContactVariables
    >(LinkSubcategoryToContact, { onError: onFailure })

  const updateContactCategories = useCallback(
    async ({ category, subcategoryName, subcategoryId }: CategoryOptions) => {
      if (contactUuid && category && subcategoryName && subcategoryId) {
        const result = await linkSubcategoryToContact({
          variables: {
            entityUuid,
            category,
            subcategoryUuid: subcategoryId,
            contactUuid,
          },
        })

        if (result.data?.linkContactWithSubcategory) {
          onSuccess?.({ category, subcategoryId, subcategoryName })
        } else if (!result.errors) {
          onFailure?.()
        }

        return result
      }

      throw Error(MISSING_CATEGORY_VALUES)
    },
    [contactUuid, linkSubcategoryToContact, entityUuid, onSuccess, onFailure]
  )

  return { updateContactCategories, isLinkingSubcategoryToContact }
}
