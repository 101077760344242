import styled from 'styled-components'

import { Button } from '../Button/Button'

export const StyledButtonBlack = styled(Button)`
  background-color: ${({ theme }) => theme.colors.BLACK_900};
  color: ${({ theme }) => theme.colors.WHITE};
  border-color: ${({ theme }) => theme.colors.BLACK_900};

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_700};
    border-color: ${({ theme }) => theme.colors.GREY_700};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_550};
    border-color: ${({ theme }) => theme.colors.GREY_550};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.GREY_150};
    border-color: ${({ theme }) => theme.colors.GREY_150};
  }
`
