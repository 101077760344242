import { useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { Box, useModalState } from '@npco/zeller-design-system'
import { Form, Formik, useFormikContext } from 'formik'
import isEqual from 'lodash/isEqual'

import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { page, shared } from 'translations'

import { BusinessFields } from '../../Businesses/Business.fields'
import { LinkedInvoicesWarningModal } from '../../Contact/ContactSections/LinkedInvoicesWarningModal/LinkedInvoicesWarningModal'
import * as styled from '../../Contacts.styled'
import { ContactFormData, InputSelectComboBoxItem } from '../../Contacts.types'
import { getContactInputComboboxItem } from '../../Contacts.utils'
import { useContactCache } from '../../hooks/useContactCache/useContactCache'
import { PersonFields } from '../../People/Person.fields'
import { SubContactComboBox } from '../SubContactComboBox/SubContactComboBox'

interface AddSubContactProps {
  isBusinessContactType?: boolean
  existingBusinessContactName?: string | null
  existingPersonContactName?: string | null
}

export const AddSubContact = ({
  isBusinessContactType = false,
  existingBusinessContactName,
  existingPersonContactName,
}: AddSubContactProps) => {
  const {
    initialValues: { linkedContactUuid: initialLinkedContactUuid },
    setFieldValue,
    values,
  } = useFormikContext<ContactFormData>()
  const { readFromCache } = useContactCache()
  const initialSelectedSubContact = readFromCache(initialLinkedContactUuid)

  const [isAddingNewSubContact, setIsAddingNewSubContact] = useState(false)

  const [selectedSubContact, setSelectedSubContact] = useState<
    InputSelectComboBoxItem | null | undefined
  >(
    initialSelectedSubContact
      ? getContactInputComboboxItem(initialSelectedSubContact)
      : null
  )

  const header = isBusinessContactType
    ? page.contacts.form.personalDetails
    : page.contacts.form.businessDetails

  return (
    <>
      <Box mt="2.5rem" mb="1rem">
        <styled.Header>{header}</styled.Header>
      </Box>
      {isAddingNewSubContact &&
        (isBusinessContactType ? (
          <PersonFields existingContactName={existingPersonContactName} />
        ) : (
          <BusinessFields existingContactName={existingBusinessContactName} />
        ))}
      <SubContactComboBox
        isAddingNewSubContact={isAddingNewSubContact}
        isBusinessContactType={isBusinessContactType}
        selectedSubContact={selectedSubContact}
        setFieldValue={setFieldValue}
        setIsAddingNewSubContact={setIsAddingNewSubContact}
        setSelectedSubContact={setSelectedSubContact}
        values={values}
      />
    </>
  )
}

const hasNameOrEmailsChanged = (
  values: ContactFormData,
  initialValues: ContactFormData
) => {
  const hasBusinessNameChanged =
    values.business.businessName !== initialValues.business.businessName
  const hasPersonNameChanged =
    values.person.firstName !== initialValues.person.firstName ||
    values.person.lastName !== initialValues.person.lastName
  const hasEmailChanged =
    values.business.email !== initialValues.business.email ||
    values.person.email !== initialValues.person.email
  const hasAdditionalEmailsChanged =
    !isEqual(
      values.business.additionalEmails,
      initialValues.business.additionalEmails
    ) ||
    !isEqual(
      values.person.additionalEmails,
      initialValues.person.additionalEmails
    )

  return (
    hasBusinessNameChanged ||
    hasPersonNameChanged ||
    hasEmailChanged ||
    hasAdditionalEmailsChanged
  )
}

interface ContactFormProps {
  closeModal: () => void
  contactType: ContactType
  handleSubmit: (values: ContactFormData) => Promise<void>
  initialValues: ContactFormData
  isLoading: boolean
  isModalOpen: boolean
  title: string
  setExistingBusinessContactName: (value: string | null | undefined) => void
  setExistingPersonContactName: (value: string | null | undefined) => void
  existingBusinessContactName?: string | null
  existingPersonContactName?: string | null
  renderBottomSection?: (props: { values: ContactFormData }) => void
  renderTopSection?: () => void
  linkedInvoicesLength?: number
}

export const ContactForm = ({
  closeModal,
  contactType,
  handleSubmit,
  initialValues,
  isLoading,
  isModalOpen,
  title,
  existingBusinessContactName,
  existingPersonContactName,
  renderBottomSection,
  renderTopSection,
  setExistingBusinessContactName,
  setExistingPersonContactName,
  linkedInvoicesLength = 0,
}: ContactFormProps) => {
  const isBusinessContactType = contactType === ContactType.BUSINESS

  const {
    isModalOpen: isLinkedInvoicesWarningModalOpen,
    openModal: openLinkedInvoicesWarningModal,
    closeModal: closeLinkedInvoicesWarningModal,
  } = useModalState()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        try {
          await handleSubmit(values)

          // NOTE: clear all state on submit and then close
          resetForm()
          closeModal()
        } catch (error) {
          ErrorLogger.report('[Payment] Contact form submit', error)
        }
      }}
    >
      {({ submitForm, resetForm, values }) => (
        <Form>
          <ModalFormScrollable
            cancelLabel={shared.cancel}
            confirmLabel={shared.save}
            isConfirmButtonDisabled={isLoading}
            isLoading={isLoading}
            isOpen={isModalOpen}
            onCancel={() => {
              // NOTE: clear all state on cancel and then close
              resetForm()
              setExistingBusinessContactName(null)
              setExistingPersonContactName(null)
              closeModal()
            }}
            onSave={() => {
              if (
                linkedInvoicesLength > 0 &&
                hasNameOrEmailsChanged(values, initialValues)
              ) {
                openLinkedInvoicesWarningModal()
                return
              }

              submitForm()
            }}
            shouldReturnFocusAfterClose={false}
            title={title}
          >
            {renderTopSection?.()}

            {isBusinessContactType ? (
              <BusinessFields
                existingContactName={existingBusinessContactName}
              />
            ) : (
              <PersonFields existingContactName={existingPersonContactName} />
            )}

            {renderBottomSection?.({ values })}
          </ModalFormScrollable>
          {linkedInvoicesLength > 0 && (
            <LinkedInvoicesWarningModal
              closeModal={closeLinkedInvoicesWarningModal}
              isOpen={isLinkedInvoicesWarningModalOpen}
              linkedInvoicesLength={linkedInvoicesLength}
              onConfirm={() => {
                closeLinkedInvoicesWarningModal()
                submitForm()
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}
