import { Box, COLOR } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface PanInformationProps {
  isLoading: boolean
}

export const PanInformation = ({ isLoading }: PanInformationProps) => {
  return (
    <>
      {!isLoading && (
        <Box position="absolute" color={COLOR.GREY_250} left="16px" top="102px">
          {translate('component.cardSummary.sensitiveCardInformation.panLabel')}
        </Box>
      )}
      <Box id="mq-card-pan" position="absolute" top="102px" left="163px" />
    </>
  )
}
