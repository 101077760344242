import { useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  rvPersistedFiles,
  rvUploadedFiles,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/debitCardTransactions'
import { useUpdatedOutstandingTransactions } from 'features/Cards/CardSingle/hooks/useUpdatedOutstandingTransactions'

import {
  useUpdateDebitCardTransaction,
  useUpdateDebitCardTransactionRv,
} from 'hooks/banking'
import { useUpdateDebitCardTransactionAttachmentDownloadUrls } from 'hooks/useUploadedImages/useUpdateDebitCardTransactionAttachmentDownloadUrls'
import { FileWithId } from 'components/File'

interface UseDeleteImagesProps {
  transactionUuid: string
}

export const useDeleteImages = ({ transactionUuid }: UseDeleteImagesProps) => {
  const rvUploadedImages = useReactiveVar(rvUploadedFiles)
  const rvPersistedImages = useReactiveVar(rvPersistedFiles)

  const uploadedImagesByTransactionUuid = useMemo(
    () => rvUploadedImages[transactionUuid] ?? [],
    [transactionUuid, rvUploadedImages]
  )
  const persistedImagesByTransactionUuid = useMemo(
    () => rvPersistedImages[transactionUuid] ?? [],
    [transactionUuid, rvPersistedImages]
  )

  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()
  const { updateTransactionRv } = useUpdateDebitCardTransactionRv()
  const { cacheCurrentUpdatedOutstandingTransaction } =
    useUpdatedOutstandingTransactions(transactionUuid)
  const { removeAttachmentDownloadUrlByDocumentId } =
    useUpdateDebitCardTransactionAttachmentDownloadUrls()

  const filterById = (image: FileWithId, imageToRemove: FileWithId) =>
    image.id !== imageToRemove.id

  const deleteImage = useCallback(
    (imageToRemove: FileWithId) => {
      const uploadedImagesAfterDelete = uploadedImagesByTransactionUuid.filter(
        (image: FileWithId) => filterById(image, imageToRemove)
      )
      const persistedImagesAfterDelete =
        persistedImagesByTransactionUuid.filter((image: FileWithId) =>
          filterById(image, imageToRemove)
        )

      const attachmentsAfterDelete = [
        ...uploadedImagesAfterDelete.map((image) => image.id),
        ...persistedImagesAfterDelete.map((image) => image.id),
      ]

      rvUploadedFiles({
        ...rvUploadedImages,
        [transactionUuid]: uploadedImagesAfterDelete,
      })
      rvPersistedFiles({
        ...rvPersistedImages,
        [transactionUuid]: persistedImagesAfterDelete,
      })
      cacheCurrentUpdatedOutstandingTransaction({
        update: { attachments: attachmentsAfterDelete },
      })
      updateDebitCardTransaction(transactionUuid, {
        attachments: attachmentsAfterDelete,
      })
      updateTransactionRv({
        transactionUuid,
        update: { attachments: attachmentsAfterDelete },
      })
      removeAttachmentDownloadUrlByDocumentId(transactionUuid, imageToRemove.id)
    },
    [
      cacheCurrentUpdatedOutstandingTransaction,
      persistedImagesByTransactionUuid,
      removeAttachmentDownloadUrlByDocumentId,
      rvPersistedImages,
      rvUploadedImages,
      transactionUuid,
      updateDebitCardTransaction,
      updateTransactionRv,
      uploadedImagesByTransactionUuid,
    ]
  )

  return {
    deleteImage,
  }
}
