import { type Dispatch, type SetStateAction } from 'react'
import {
  ContactsFilters,
  InputSelectComboBoxItem,
} from 'features/Contacts/Contacts.types'
import { Subject } from 'rxjs'

import { useUnlinkedBsbPaymentInstruments } from 'hooks/paymentInstruments/useUnlinkedBsbPaymentInstruments'
import { TransferDropdownFrom } from 'pages/Transfer/FromDropdown/TransferDropdownFrom'
import { TransferDropdownTo } from 'pages/Transfer/FromDropdown/TransferDropdownTo'

interface TransferRecipientsProps {
  selectedContact: InputSelectComboBoxItem | null
  setSelectedContact: Dispatch<SetStateAction<InputSelectComboBoxItem | null>>
  contactValidationError: string | undefined
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
  contactFilters: ContactsFilters
  name$: Subject<string>
}

export const TransferRecipients = ({
  selectedContact,
  setSelectedContact,
  contactValidationError,
  setContactValidationError,
  contactFilters,
  name$,
}: TransferRecipientsProps) => {
  useUnlinkedBsbPaymentInstruments({})

  return (
    <>
      <TransferDropdownFrom />
      <TransferDropdownTo
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        contactValidationError={contactValidationError}
        setContactValidationError={setContactValidationError}
        contactFilters={contactFilters}
        name$={name$}
      />
    </>
  )
}
