import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as QRCode } from 'assets/svg/setup-flow/download-QR-code.svg'

import { DownloadMessage } from '../DownloadMessage/DownloadMessage'

export const DownloadLinkDesktop = () => {
  return (
    <Flex
      alignItems="center"
      backgroundColor={COLOR.BLUE_60}
      border={`1px solid ${COLOR.BLUE_100}`}
      borderRadius="8px"
      data-testid="download-link-desktop"
      gap="16px"
      marginTop="20px"
      p="6px 24px 6px 12px"
      width="455px"
    >
      <SvgIcon>
        <QRCode />
      </SvgIcon>
      <DownloadMessage />
    </Flex>
  )
}
