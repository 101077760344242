import { InfoBox } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { SupportMobile } from './SupportMobile'

export const CannotUpdateDetailsMessage = () => {
  return (
    <InfoBox>
      <InfoBox.Message>
        {
          translate('page.settings.profileSettings.cannotUpdate', {
            phone: <SupportMobile />,
          }) as string
        }
      </InfoBox.Message>
    </InfoBox>
  )
}
