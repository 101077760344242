import { forwardRef } from 'react'

import { CorporateCardTransactionRow } from 'pages/DebitCardTransactions/CorporateCardTransactionRow/CorporateCardTransactionRow'
import { TransactionListRowBaseProps } from 'pages/DebitCardTransactions/DebitCardTransactionsList/DebitCardTransactionsList.types'

import { OutstandingSettingsType } from '../hooks/useOutstandingFilterSettings'

interface GetForwardCorporateCardTransactionRowProps {
  outstandingSettings: OutstandingSettingsType
  isFilterOn: boolean
}

export const getForwardCorporateCardTransactionRow = ({
  outstandingSettings,
  isFilterOn,
}: GetForwardCorporateCardTransactionRowProps) => {
  return forwardRef<HTMLLIElement, TransactionListRowBaseProps>(
    (props, ref) => {
      const {
        isNoteOutstandingFilterSettingsOn,
        isCategoryOutstandingFilterSettingsOn,
        isReceiptOutstandingFilterSettingsOn,
      } = outstandingSettings
      return (
        <CorporateCardTransactionRow
          {...props}
          ref={ref}
          isCategoryIconHighlighted={
            isReceiptOutstandingFilterSettingsOn && isFilterOn
          }
          isNoteIconHighlighted={
            isNoteOutstandingFilterSettingsOn && isFilterOn
          }
          isReceiptIconHighlighted={
            isCategoryOutstandingFilterSettingsOn && isFilterOn
          }
        />
      )
    }
  )
}
