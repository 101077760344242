import { HeaderContext } from '@tanstack/react-table'

import { translate } from 'utils/translations'
import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { InvoiceHeaderCell } from '../InvoiceHeaderCell/InvoiceHeaderCell'
import { AllInvoiceTableColumn } from '../InvoiceTable.types'
import { TableColumnPopperWidth } from '../InvoiceTable.utils'
import { ContactFilters } from '../InvoiceTableFilters/ContactFilters/ContactFilters'

export const InvoiceContactHeaderCell = ({
  header,
  table,
}: HeaderContext<Invoice | null, unknown>) => {
  return (
    <InvoiceHeaderCell
      disablePopper={false}
      header={header}
      label={translate('component.invoiceTable.contact')}
      popperWidth={TableColumnPopperWidth[AllInvoiceTableColumn.Contact]}
      table={table}
    >
      {({ setIsOpen }) => <ContactFilters setIsOpen={setIsOpen} />}
    </InvoiceHeaderCell>
  )
}
