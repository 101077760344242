import {
  ItemAside,
  ItemAsideContainer,
  ItemContent,
  ItemContentContainer,
  ListItemRow,
  StyledItemText,
} from './ListItem.styled'

export interface ListItemProps {
  left?: React.ReactNode
  right?: React.ReactNode
  aside?: React.ReactNode
  isAsideHoverable?: boolean
  isRightEllipsisable?: boolean
  isLeftEllipsisable?: boolean
  className?: string
}

export const ListItem = ({
  left,
  right,
  aside,
  isAsideHoverable = false,
  isRightEllipsisable = false,
  isLeftEllipsisable = false,
  className,
}: ListItemProps) => {
  const wrapContent = (content: React.ReactNode) => {
    if (typeof content === 'string') {
      return <StyledItemText>{content}</StyledItemText>
    }
    return content
  }

  return (
    <ListItemRow>
      <ItemContent className={className}>
        <ItemContentContainer isEllipsisable={isLeftEllipsisable}>
          {wrapContent(left)}
        </ItemContentContainer>
        <ItemContentContainer isEllipsisable={isRightEllipsisable}>
          {wrapContent(right)}
        </ItemContentContainer>
      </ItemContent>
      {!!aside && (
        <ItemAsideContainer>
          <ItemAside isHoverable={isAsideHoverable}>
            {wrapContent(aside)}
          </ItemAside>
        </ItemAsideContainer>
      )}
    </ListItemRow>
  )
}
