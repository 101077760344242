import { mock } from '@npco/utils-apollo-testing-link'

import { GetCustomer } from '../hooks/useCustomerQuery/graphql/getCustomer.generated'
import { getMockLoggedInUser } from './loggedInUser.mock'

export const CUSTOMER_ID = 'customer-id'
export const ENTITY_UUID = 'entity-uuid'

export const GetCustomerMocks = mock(GetCustomer)

export const GetCustomerSuccessMock = GetCustomerMocks.mockVariables({
  entityUuid: ENTITY_UUID,
  customerUuid: CUSTOMER_ID,
}).mockData({
  getCustomer: getMockLoggedInUser({ phoneVerified: false }),
})

export const GetCustomerGraphQLErrorMock = GetCustomerMocks.mockVariables({
  entityUuid: ENTITY_UUID,
  customerUuid: CUSTOMER_ID,
}).mockGraphQLErrors()

export const GetCustomerNetworkErrorMock = GetCustomerMocks.mockVariables({
  entityUuid: ENTITY_UUID,
  customerUuid: CUSTOMER_ID,
}).mockNetworkError()
