import { gql } from '@apollo/client'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import {
  GetCanUnlinkContactDebitCardAccountTransactionFragment,
  GetCanUnlinkContactDebitCardTransactionV2Fragment,
  GetIsAllowedContactUnlinkingDebitCardAccountTransactionFragment,
  GetIsAllowedContactUnlinkingDebitCardTransactionV2Fragment,
} from './TransactionHeader.utils.generated'

interface GetIsTransferInArguments {
  transaction:
    | GetIsAllowedContactUnlinkingDebitCardTransactionV2Fragment
    | GetIsAllowedContactUnlinkingDebitCardAccountTransactionFragment
}

export const getIsAllowedContactUnlinking = ({
  transaction,
}: GetIsTransferInArguments) => {
  if (transaction.__typename === 'DebitCardAccountTransaction') {
    return [
      DebitCardAccountTransactionTypeEnum.DE_IN,
      DebitCardAccountTransactionTypeEnum.NPP_IN,
    ].includes(transaction.type)
  }
  return [
    DebitCardTransactionTypeV2.DE_IN,
    DebitCardTransactionTypeV2.NPP_IN,
    DebitCardTransactionTypeV2.DDA_OUT,
  ].includes(transaction.type)
}

getIsAllowedContactUnlinking.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetIsAllowedContactUnlinkingDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      type
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetIsAllowedContactUnlinkingDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      type
    }
  `,
}

interface GetCanUnlinkContactArguments {
  transaction:
    | GetCanUnlinkContactDebitCardTransactionV2Fragment
    | GetCanUnlinkContactDebitCardAccountTransactionFragment
}

export const getCanUnlinkContact = ({
  transaction,
}: GetCanUnlinkContactArguments) =>
  getIsAllowedContactUnlinking({ transaction }) && !!transaction.contact?.id

getCanUnlinkContact.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetCanUnlinkContactDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetIsAllowedContactUnlinkingDebitCardTransactionV2Fragment

      contact {
        id
      }
    }

    ${getIsAllowedContactUnlinking.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetCanUnlinkContactDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetIsAllowedContactUnlinkingDebitCardAccountTransactionFragment

      contact {
        id
      }
    }

    ${getIsAllowedContactUnlinking.fragments.DebitCardAccountTransaction}
  `,
}
