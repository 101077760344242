import { KeyboardEvent, useCallback } from 'react'
import { gql } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { useAccountNavigation } from '@npco/mp-utils-accounts-routing'
import { BodySmall, Box, COLOR, Flex } from '@npco/zeller-design-system'

import { ReactComponent as CardIcon } from 'assets/svg/card.svg'
import { KEY_CODE } from 'const/keyboard'
import { AVATAR_BADGE_SIZE } from 'types/common'
import { AccountStatus, shouldDisplayStatus } from 'components/AccountStatus'

import { AccountAmountItem } from './AccountAmountItem'
import { AccountNameAndInterestCell } from './AccountNameAndInterestCell/AccountNameAndInterestCell'
import { IconCounterCell } from './IconCounterCell'
import { ListItemAccountsDebitCardAccountV2Fragment } from './ListItemAccounts.generated'
import {
  StyledAccountGrow,
  StyledAccountRow,
  StyledDetailsContainer,
} from './ListItemAccounts.styled'
import { cardToolTip } from './ListItemAccounts.utils'

export interface Props {
  account: ListItemAccountsDebitCardAccountV2Fragment
}

export const ListItemAccounts = ({ account }: Props) => {
  const { navigateToAccountTransactions } = useAccountNavigation()

  const handleEnterKey = useCallback(
    (event: KeyboardEvent<HTMLLIElement>) => {
      if (event.key === KEY_CODE.ENTER) {
        navigateToAccountTransactions({
          accountId: account.id,
        })
      }
    },
    [navigateToAccountTransactions, account.id]
  )

  const isSavingsAccount = account.type === DebitCardAccountType.SAVINGS

  const isClosed = account.status === DebitCardAccountStatus.CLOSED

  return (
    <StyledAccountRow
      onClick={() =>
        navigateToAccountTransactions({
          accountId: account.id,
        })
      }
      onKeyUp={handleEnterKey}
      data-testid="account-row"
      isClosed={isClosed}
      tabIndex={0}
    >
      <Flex flexShrink={1} mr="0.75rem">
        <IconAvatar
          icon={account.icon}
          allowAnimation
          badgeSize={AVATAR_BADGE_SIZE.SMALL}
          disabled={account.status === DebitCardAccountStatus.CLOSED}
          withCursorPointer
        />
      </Flex>
      <AccountNameAndInterestCell account={account} />
      {shouldDisplayStatus(account) && (
        <Box ml={{ _: '16px', MD: '32px', LG: '80px' }}>
          <BodySmall>
            <AccountStatus account={account} />
          </BodySmall>
        </Box>
      )}
      <StyledAccountGrow />
      {!isSavingsAccount && (
        <IconCounterCell
          count={account?.cardsCount?.total}
          icon={<CardIcon width="12" height="12" color={COLOR.BLACK_900} />}
          tooltips={cardToolTip(account)}
        />
      )}
      <StyledDetailsContainer>
        <AccountAmountItem account={account} />
      </StyledDetailsContainer>
    </StyledAccountRow>
  )
}

ListItemAccounts.fragments = {
  DebitCardAccountV2: gql`
    fragment ListItemAccountsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      type
      name
      id
      icon {
        colour
        letter
        image
        images {
          size
          url
        }
        animation
      }
      status
      cardsCount {
        total
      }
      ...AccountAmountItemDebitCardAccountV2Fragment
      ...CardTooltipDebitCardAccountV2Fragment
      ...AccountNameAndInterestCellDebitCardAccountV2Fragment
    }

    ${AccountAmountItem.fragments.DebitCardAccountV2}
    ${cardToolTip.fragments.DebitCardAccountV2}
    ${AccountNameAndInterestCell.fragments.DebitCardAccountV2}
  `,
}
