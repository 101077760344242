import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { InvoiceAction, InvoiceActivityStatus } from '@npco/mp-gql-types'
import { UseTranslationsFn } from '@npco/utils-translations'

import { formatToday } from 'utils/date'
import dayjs from 'utils/dayjs'
import { InvoiceActivityFragment } from 'types/gql-types/InvoiceActivityFragment'

import { getCurrencyAmountFormatted } from '../../../Invoice/components/InvoiceFormAccordions/InvoiceFormAccordions.utils'
import { translations } from './InvoiceDrawerActivity.i18n'

const DATE_DISPLAY = 'DD MMM'

export const getTimelineBodyContent = (
  activity: InvoiceActivityFragment,
  t: UseTranslationsFn<typeof translations>
): string => {
  const {
    balance,
    contactName,
    completedTime,
    cnpTxnRefNum: referenceNumber,
    dueDate,
    status,
    type,
  } = activity

  const contactNameSafe = contactName || ''
  const formattedBalance = getCurrencyAmountFormatted(balance ?? '0')
  const formattedDueDate = dayjs(dueDate).format(DATE_FULL_DISPLAY_FORMAT)

  switch (type) {
    case InvoiceAction.CANCELLED:
    case InvoiceAction.MANUAL_PAYMENT: {
      return t('amountBalanceContent', { amount: formattedBalance })
    }
    case InvoiceAction.CNP_PAYMENT: {
      if (!completedTime) {
        return ''
      }

      const referenceNumberSafe = referenceNumber || ''

      return t('paymentRefContent', {
        referenceNumber: referenceNumberSafe,
        amount: formattedBalance,
      })
    }
    case InvoiceAction.SEND_INVOICE:
    case InvoiceAction.SEND_SMS_INVOICE:
    case InvoiceAction.SEND_EMAIL_INVOICE: {
      if (status === InvoiceActivityStatus.FAILED) {
        return t('reviewRecipients')
      }

      return t('sendInvoiceContent', {
        contactName: contactNameSafe,
        dueDate: formattedDueDate,
      })
    }
    case InvoiceAction.SEND_SCHEDULED_INVOICE:
    case InvoiceAction.RE_SEND_INVOICE: {
      return t('sendInvoiceContent', {
        contactName: contactNameSafe,
        dueDate: formattedDueDate,
      })
    }
    case InvoiceAction.SEND_MANUAL_REMINDER:
    case InvoiceAction.SEND_AUTO_REMINDER: {
      return t('contactContent', { contactName: contactNameSafe })
    }

    default: {
      return ''
    }
  }
}

const getCompleteHeading = ({
  isComplete,
  completeHeading,
  fallbackHeading,
}: {
  isComplete: boolean
  completeHeading: string
  fallbackHeading: string
}) => {
  if (isComplete) {
    return completeHeading
  }

  return fallbackHeading
}

export const getTimelineHeader = (
  activity: InvoiceActivityFragment,
  t: UseTranslationsFn<typeof translations>
): string => {
  const {
    balance,
    completedTime,
    paidAmount,
    reminderIndex,
    status,
    title,
    type,
  } = activity

  const isComplete = Boolean(completedTime)
  const formattedBalance = getCurrencyAmountFormatted(balance ?? '0')
  const formattedPaidAmount = getCurrencyAmountFormatted(paidAmount ?? '0')

  switch (type) {
    case InvoiceAction.CANCELLED: {
      return t('cancelInvoiceHeader')
    }
    case InvoiceAction.CREATE: {
      return t('createInvoiceHeader')
    }
    case InvoiceAction.CNP_PAYMENT: {
      return getCompleteHeading({
        isComplete,
        completeHeading: t('cnpPaymentReceivedHeader', {
          amount: formattedPaidAmount,
        }),
        fallbackHeading: t('cnpPaymentDueHeader', { amount: formattedBalance }),
      })
    }
    case InvoiceAction.MANUAL_PAYMENT: {
      return t('manualPaymentHeader', { amount: formattedPaidAmount })
    }
    case InvoiceAction.PAYMENT_DUE: {
      return t('paymentDueHeader', { amount: formattedBalance })
    }
    case InvoiceAction.SEND_INVOICE: {
      if (status === InvoiceActivityStatus.FAILED) {
        return t('failedInvoiceSend')
      }

      return getCompleteHeading({
        isComplete,
        completeHeading: t('sentViaEmailAndSmsHeader'),
        fallbackHeading: t('sendInvoiceSendHeader'),
      })
    }
    case InvoiceAction.RE_SEND_INVOICE: {
      return getCompleteHeading({
        isComplete,
        completeHeading: t('sendInvoiceSentHeader'),
        fallbackHeading: t('sendInvoiceSendHeader'),
      })
    }
    case InvoiceAction.SEND_AUTO_REMINDER: {
      const reminderIndexSafe = reminderIndex || ''

      return getCompleteHeading({
        isComplete,
        completeHeading: t('autoReminderSentHeader', {
          reminderIndex: reminderIndexSafe,
        }),
        fallbackHeading: t('autoReminderSendHeader', {
          reminderIndex: reminderIndexSafe,
        }),
      })
    }
    case InvoiceAction.SEND_MANUAL_REMINDER: {
      return t('manualReminderHeader')
    }
    case InvoiceAction.UPDATE: {
      return t('updateInvoiceHeader')
    }
    case InvoiceAction.SEND_SMS_INVOICE: {
      if (status === InvoiceActivityStatus.FAILED) {
        return t('sentViaSmsFailedHeader')
      }

      return t('sentViaSmsHeader')
    }
    case InvoiceAction.SEND_EMAIL_INVOICE: {
      if (status === InvoiceActivityStatus.FAILED) {
        return t('sentViaEmailFailedHeader')
      }

      return t('sentViaEmailHeader')
    }

    default: {
      return title
    }
  }
}

export const getDateDisplay = (dateTime: string) => {
  return formatToday(dateTime, (value) => dayjs(value).format(DATE_DISPLAY))
}
