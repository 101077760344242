/**
 * @description
 * Launch Darkly is used for remote feature flagging configuration.
 *
 * This value expects the `Client-side ID` of the target environment from
 * Launch Darkly. It is _not_ the `SDK Key` or `Mobile Key`, i.e. the expected
 * value does _not_ start with `"sdk-*"` or `"mob-*"`.
 *
 * This value can be found at Account Settings > Projects > "Zeller" >
 * Environments, or by visiting this link:
 * https://app.launchdarkly.com/settings/projects/default/environments.
 */
export const LAUNCH_DARKLY_ENV_KEY = import.meta.env
  .REACT_APP_LAUNCH_DARKLY_ENV_KEY
