import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { CreateSavingsAccountModalWrapper } from '../../CreateSavingsAccountModalWrapper/CreateSavingsAccountModalWrapper'
import { CreateSavingsAccountPrompt } from './CreateSavingsAccountPrompt/CreateSavingsAccountPrompt'
import { TaxFileNumberField } from './TaxFileNumberField/TaxFileNumberField'
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions'

type CreateSavingsAccountProps = {
  isActivatingAccount: boolean
}

export const CreateSavingsAccount = ({
  isActivatingAccount,
}: CreateSavingsAccountProps) => {
  const { submitForm } = useFormikContext()

  return (
    <CreateSavingsAccountModalWrapper
      onClickPrimary={submitForm}
      isLoadingPrimary={isActivatingAccount}
    >
      <Box pb="24px">
        <CreateSavingsAccountPrompt />
      </Box>
      <TaxFileNumberField />
      <Box pt="24px">
        <TermsAndConditions />
      </Box>
    </CreateSavingsAccountModalWrapper>
  )
}
