import { useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { COLOR } from '@npco/zeller-design-system'
import { XAxis } from 'recharts'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { TooltipContent } from 'pages/Reports/ReportsCharts/TotalChart/TooltipContent/TooltipContent'
import { AreaChart } from 'components/Charts/AreaChart/AreaChart'
import { ChartData } from 'components/Charts/Charts.types'
import {
  formatDayTicks,
  formatDollarValueTicks,
  formatMonthTicks,
  getYAxisWidth,
} from 'components/Charts/Shared/AxisTickFormatter.utils'
import { BaseLoadingXAxisChartTick } from 'components/Charts/Shared/BaseLoadingXAxisChartTick/BaseLoadingXAxisChartTick'
import { BaseLoadingYAxisChartTick } from 'components/Charts/Shared/BaseLoadingYAxisChartTick/BaseLoadingYAxisChartTick'
import {
  getXAxisDayTicks,
  getXAxisMonthTicks,
} from 'components/Charts/Shared/XAxisTick.utils'
import { getDefaultYAxisValueTicks } from 'components/Charts/Shared/YAxisTick.utils'

import { getMonthAxisDomain } from '../AccountBalanceChart.utils'

interface AccountBalanceAreaChartProps {
  balances: ChartData
  placeholderBalances: ChartData
  isLoading: boolean
}

export const AccountBalanceAreaChart = ({
  balances,
  placeholderBalances,
  isLoading,
}: AccountBalanceAreaChartProps) => {
  const [shouldShowAnimation] = useState(isLoading)

  const isMobile = useIsMobileResolution()

  const xAxisTickInterval = useMemo(() => (isMobile ? 14 : 7), [isMobile])

  const xAxisLoadingTickFunction = (props: any) => {
    return <BaseLoadingXAxisChartTick {...props} height={12} width={24} />
  }

  const yAxisLoadingTickFunction = (props: any) => {
    return <BaseLoadingYAxisChartTick {...props} width={24} height={8} />
  }

  const hasNoResults = balances.every(({ value }) => !value)
  const balanceChartData = hasNoResults ? [] : balances

  return (
    <AreaChart
      data={isLoading ? placeholderBalances : balanceChartData}
      margin={{
        top: 8,
        bottom: isLoading ? 30 : 0,
        right: 8,
      }}
      height={224}
      width="100%"
      tooltipContent={TooltipContent(false)}
      xAxisProps={{
        type: 'number',
        domain: ['dataMin', 'dataMax'],
        tick: !isLoading
          ? { fill: COLOR.BLACK_900, fontSize: 14 }
          : xAxisLoadingTickFunction,
        tickFormatter: formatDayTicks,
        ticks: getXAxisDayTicks(
          isLoading ? placeholderBalances : balances,
          xAxisTickInterval
        ),
        tickMargin: 10,
      }}
      yAxisProps={{
        type: 'number',
        hide: isMobile,
        tickCount: 5,
        width: getYAxisWidth(isLoading ? placeholderBalances : balances),
        tick: !isLoading
          ? { fill: COLOR.BLACK_900, fontSize: 14 }
          : yAxisLoadingTickFunction,
        ticks:
          hasNoResults || isLoading ? getDefaultYAxisValueTicks() : undefined,
        tickFormatter: formatDollarValueTicks,
      }}
      areaProps={{
        dataKey: 'value',
        isAnimationActive: !isLoading && shouldShowAnimation,
        animationDuration: 800,
        animationEasing: 'ease-out',
      }}
      disabled={hasNoResults}
      isLoading={isLoading}
    >
      {!isLoading && (
        <XAxis
          hide={isLoading}
          type="number"
          xAxisId={1}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          domain={getMonthAxisDomain(balances, placeholderBalances)}
          interval="preserveStartEnd"
          tickFormatter={formatMonthTicks}
          tick={{ fill: COLOR.GREY_400, fontSize: 12 }}
          ticks={getXAxisMonthTicks(
            balances.length ? balances : placeholderBalances,
            xAxisTickInterval
          )}
        />
      )}
    </AreaChart>
  )
}

AccountBalanceAreaChart.fragments = {
  DebitCardAccountBalance: gql`
    fragment AccountBalanceAreaChartDebitCardAccountBalanceFragment on DebitCardAccountBalance {
      __typename
      date
      balance {
        value
      }
    }
  `,
}
