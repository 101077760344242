import styled from 'styled-components'

export const AccountRowContainer = styled.label`
  margin: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 12px 0px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_30};
  }
`

export const LabelContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 16px;
`

export const Label = styled.p`
  display: table-cell;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SubLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
  font-size: 14px;
`
