import { Link } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { NoResultsForSearch } from 'pages/GlobalModals/AddCardModal/pages/shared/NoResultsForSearch/NoResultsForSearch'

interface CardholderNoResultsPageProps {
  searchText: string
}

export const CardholderNoResultsPage = ({
  searchText,
}: CardholderNoResultsPageProps) => {
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <NoResultsForSearch
      title={translate(
        'page.addCardModal.selectCardholder.cardholderNoResultsPage.title',
        { searchText }
      )}
      subtitle={translate(
        'page.addCardModal.selectCardholder.cardholderNoResultsPage.subtitle1'
      )}
      subtitle2={translate(
        'page.addCardModal.selectCardholder.cardholderNoResultsPage.subtitle2',
        {
          link: (
            <Link to={ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.path}>
              {translate(
                'page.addCardModal.selectCardholder.cardholderNoResultsPage.linkText'
              )}
            </Link>
          ),
        }
      )}
      marginTop="72px"
    />
  )
}
