import { useTranslations } from '@npco/utils-translations'

import { FeatureList } from '../../../../components/FeatureList/FeatureList'
import { useSetupFlowContext } from '../../../../contexts/SetupFlowContext'
import { ScrollableContent, Title } from '../../../SetupFlowModal.styled'
import { setupFlowAccountsStageTranslations } from '../FeatureAccountsStage.i18n'
import { AccountDetails } from './AccountDetails'
import { AccountDetailsError } from './AccountDetailsError'
import { AccountStageCheckboxField } from './AccountStageCheckboxField'

export const MainContent = () => {
  const t = useTranslations(setupFlowAccountsStageTranslations)
  const {
    isLoadingAccounts,
    setupFlowAccount,
    refetchAccounts,
    setupFlowCard,
  } = useSetupFlowContext()

  const transactionAccountPoints = [t('point1'), t('point2'), t('point3')]
  const savingsAccountPoints = [t('point4'), t('point5'), t('point6')]

  return (
    <ScrollableContent data-testid="setup-flow-feature-accounts-stage">
      <Title>{t('title')}</Title>
      <FeatureList
        title={t('transactionAccount')}
        points={transactionAccountPoints}
      />
      <FeatureList title={t('savingAccount')} points={savingsAccountPoints} />
      {setupFlowAccount ? (
        <>
          <AccountDetails account={setupFlowAccount} />
          {!setupFlowCard && <AccountStageCheckboxField />}
        </>
      ) : (
        <AccountDetailsError
          retry={() => refetchAccounts()}
          isLoading={isLoadingAccounts}
        />
      )}
    </ScrollableContent>
  )
}
