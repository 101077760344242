import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE } from '@npco/zeller-design-system'

import { DateRangeValue } from '../DateRange.types'
import { translations } from './PeriodButtons.i18n'
import * as styled from './PeriodButtons.styled'
import { getDateRangeForPeriod, Period } from './PeriodButtons.utils'

type PeriodButtonsProps = {
  handleSetPeriod: (period: Required<DateRangeValue>) => void
  defaultPeriod?: Period
}

const ORDERED_PERIODS = [
  'lastMonth',
  'lastWeek',
  'thisMonth',
  'thisWeek',
] satisfies Period[]

export const PeriodButtons = ({
  handleSetPeriod,
  defaultPeriod,
}: PeriodButtonsProps) => {
  const t = useTranslations(translations)

  return (
    <>
      {ORDERED_PERIODS.map((labelKey) => (
        <styled.PeriodButton
          size={BUTTON_SIZE.XSMALL}
          key={labelKey}
          onClick={() => {
            handleSetPeriod(getDateRangeForPeriod(labelKey))
          }}
          $isSelected={defaultPeriod === labelKey}
        >
          {t(labelKey)}
        </styled.PeriodButton>
      ))}
    </>
  )
}
