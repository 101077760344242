import { type ReactNode } from 'react'
import { PageTemplate } from '@npco/zeller-design-system'

import { CardsSettingsBreadcrumbs } from './components/CardsSettingsBreadcrumbs/CardsSettingsBreadcrumbs'
import { CardsSettingsTabs } from './components/CardsSettingsTabs/CardsSettingsTabs'

interface CardsSettingsLayoutProps {
  children?: ReactNode
}

export const CardsSettingsLayout = ({ children }: CardsSettingsLayoutProps) => {
  return (
    <PageTemplate
      HeaderPrimaryRow={<CardsSettingsBreadcrumbs />}
      HeaderSecondaryRow={<CardsSettingsTabs />}
    >
      {children}
    </PageTemplate>
  )
}
