import React, { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ErrorMessageForm,
  Flex,
  INPUT_SIZE,
  InputAdaptive,
  LoaderSimple,
  SelectSize,
  SelectStyle,
  SvgIcon,
} from '@npco/zeller-design-system'
import {
  MultiSelectAdaptive,
  MultiSelectItemBasic,
} from 'design-system/Components/MultiSelect'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'

import { type CreateItemCategoryMutationResponse } from './graphql/createItemCategory.generated'
import { useItemCategories } from './hooks/useItemCategories'
import { translations } from './ItemCategories.i18n'
import { getTriggerContent } from './ItemCategories.utils'
import { ItemCategoriesCreateNew } from './ItemCategoriesCreateNew'

interface ItemCategoriesProps {
  hasError?: boolean
  selectedItems: MultiSelectItemBasic[]
  onChange: (nextItems: MultiSelectItemBasic[]) => void
  onClose?: () => void
  onCreateSuccess?: (
    newCategory: CreateItemCategoryMutationResponse['createItemCategory']
  ) => void
}

export const ItemCategories = ({
  hasError,
  selectedItems,
  onClose,
  onChange: handleOnChange,
  onCreateSuccess,
}: ItemCategoriesProps) => {
  const t = useTranslations(translations)

  const {
    errorMessage,
    filteredItems,
    getItemCategories,
    handleCreateCategory,
    isLoading,
    search,
    setSearch,
  } = useItemCategories({
    onCreateSuccess,
  })

  useEffect(() => {
    getItemCategories()
  }, [getItemCategories])

  const renderNoItemsPlaceholder = () => {
    const searchValue = search.trim()

    if (isLoading || !searchValue.length) {
      return null
    }

    return (
      <Box pt="8px">
        <ItemCategoriesCreateNew
          onClick={handleCreateCategory}
          search={searchValue}
        />
      </Box>
    )
  }

  const renderAdditionalControls = () => (
    <Box p={{ _: '8px', MD: filteredItems.length ? '0 0 8px 0' : 0 }}>
      <InputAdaptive
        aria-label={t('itemCategorySearchPlaceholder')}
        name="items-item-search"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(event.target.value)
        }
        placeholder={t('itemCategorySearchPlaceholder')}
        renderLeftControls={() => (
          <Box mr="1rem">
            <SvgIcon width="16" height="16">
              <SearchIcon />
            </SvgIcon>
          </Box>
        )}
        renderRightControls={() =>
          isLoading && (
            <Flex ml="16px">
              <LoaderSimple size="24" />
            </Flex>
          )
        }
        size={INPUT_SIZE.MEDIUM}
        value={search}
      />
      <ErrorMessageForm hasError={!!errorMessage} errorMessage={errorMessage} />
    </Box>
  )

  const triggerContent = getTriggerContent(selectedItems)

  return (
    <MultiSelectAdaptive
      hasError={hasError}
      items={filteredItems}
      label={t('itemCategoryLabel')}
      mobileTitle={t('itemCategoryTitle')}
      onChange={handleOnChange}
      onClose={onClose}
      renderNoItemsPlaceholder={renderNoItemsPlaceholder}
      renderAdditionalControls={renderAdditionalControls}
      selectedItems={selectedItems}
      selectSize={SelectSize.Small}
      selectStyle={SelectStyle.Standard}
      triggerContent={triggerContent}
    />
  )
}
