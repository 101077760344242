import { BodySmall, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledCardName = styled(BodySmall)`
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const StyledMaskedPan = styled(BodySmall)`
  white-space: nowrap;
`

export const StyledCardItemWrapper = styled(Flex)`
  grid-gap: 8px;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
`
