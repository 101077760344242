import { useCallback, useState } from 'react'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { COLOR, TabItemProps } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { useDrawerTabEvents } from 'services/Analytics/useDrawerTabEvents'

export enum InvoiceDrawerTabs {
  Details = 'Details',
  Activity = 'Activity',
  Contact = 'Contact',
}

const DEFAULT_TAB = InvoiceDrawerTabs.Details

type UseInvoiceDrawerTabsProps = {
  status?: InvoiceStatus
}

export const useInvoiceDrawerTabs = ({ status }: UseInvoiceDrawerTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB)

  const { onDrawerTabSwitch } = useDrawerTabEvents({
    drawerName: 'InvoiceTable',
  })

  const handleTabSwitch = useCallback(
    (tabName) => {
      setSelectedTab(tabName)
      onDrawerTabSwitch(tabName)
    },
    [onDrawerTabSwitch]
  )

  const resetTabs = () => {
    if (selectedTab !== DEFAULT_TAB) {
      setSelectedTab(DEFAULT_TAB)
    }
  }

  const tabs: TabItemProps[] = [
    {
      id: 'invoice-drawer-tabs-details',
      children: translate('component.invoiceDrawer.tabs.details'),
      indicator: {
        color: COLOR.RED_1000,
        isActive: status === InvoiceStatus.SEND_FAILED,
      },
      value: InvoiceDrawerTabs.Details,
      onClick: () => handleTabSwitch(InvoiceDrawerTabs.Details),
    },
    {
      id: 'invoice-drawer-tabs-activity',
      children: translate('component.invoiceDrawer.tabs.activity'),
      value: InvoiceDrawerTabs.Activity,
      onClick: () => handleTabSwitch(InvoiceDrawerTabs.Activity),
    },
    {
      id: 'invoice-drawer-tabs-contact',
      children: translate('component.invoiceDrawer.tabs.contact'),
      value: InvoiceDrawerTabs.Contact,
      onClick: () => handleTabSwitch(InvoiceDrawerTabs.Contact),
    },
  ]

  return {
    resetTabs,
    selectedTab,
    tabs,
  }
}
