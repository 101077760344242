import { useCorporateCardWalkthroughStage } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough-routing/hooks/useCorporateCardWalkthroughStage/useCorporateCardWalkthroughStage'

import { CorporateCardWalkthroughModal } from './components/CorporateCardWalkthroughModal/CorporateCardWalkthroughModal'

export const CorporateCardWalkthrough = ({
  firstCardId,
}: {
  firstCardId?: string
}) => {
  const { stage } = useCorporateCardWalkthroughStage()

  if (stage === null) {
    return null
  }

  return <CorporateCardWalkthroughModal firstCardId={firstCardId} />
}
