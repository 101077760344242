import { z } from 'zod'

export type SentryUser = {
  id?: string
  customerUuid?: string
  entityUuid?: string
}

const TokenUserDataSchema = z.object({
  'https://identity.myzeller.com/user_metadata': z.object({
    customerUuid: z.string(),
    entityUuid: z.string(),
  }),
})

export function getSentryUserFromAuthTokenClaims(
  authTokenClaims: Record<string, unknown> | undefined
): SentryUser | undefined {
  const tokenUserDataResult = TokenUserDataSchema.safeParse(authTokenClaims)

  const tokenUserData = tokenUserDataResult.success
    ? tokenUserDataResult.data
    : undefined

  const { 'https://identity.myzeller.com/user_metadata': userMetadata } =
    tokenUserData ?? {}

  if (userMetadata?.customerUuid && userMetadata?.entityUuid) {
    return {
      id: userMetadata.customerUuid,
      entityUuid: userMetadata.entityUuid,
    }
  }

  return undefined
}
