import { ButtonLink, COLOR, Flex, Typography } from '@npco/zeller-design-system'
import { ModalWithButtons, useModalState } from 'design-system/Components/Modal'

import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'
import { page, shared } from 'translations'

import { GetSimQueryResponse } from '../SimDrawer/graphql/getSim.generated'

interface Props {
  cancelSim: () => void
  isLoading: boolean
  selectedSim?: GetSimQueryResponse['getSim']
}

export const ActiveStatusAction = ({
  cancelSim,
  isLoading,
  selectedSim,
}: Props) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <Flex justifyContent="space-between" pt="8px" data-testid="sim-card-active">
      <ButtonLink onClick={openModal} color={COLOR.RED_1000}>
        {page.settings.sim.details.billingStatusAction.active}
      </ButtonLink>
      <ModalWithButtons
        isOpen={isModalOpen}
        isPrimaryButtonLoading={isLoading}
        onCancel={closeModal}
        title={page.settings.sim.details.billingStatusModal.title}
        primaryButtonLabel={shared.confirm}
        secondaryButtonLabel={shared.cancel}
        onConfirm={cancelSim}
        hasCancelButton
      >
        <Typography variant="body-base">
          {translate('page.settings.sim.details.billingStatusModal.message', {
            rechargeDate: dayjs(selectedSim?.nextBillingDate).format(
              'DD/MM/YYYY'
            ),
          })}
        </Typography>
      </ModalWithButtons>
    </Flex>
  )
}
