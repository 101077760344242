import { BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDescription = styled(BodySmall)`
  margin: 0 0 24px;
`

export const StyledSubDescription = styled(BodySmall)`
  margin: 0;
`
