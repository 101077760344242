import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import {
  GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragmentDoc,
  IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragmentDoc,
  IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragmentDoc,
} from '@npco/mp-feature-cashflow-reporting'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type TotalIncomeAndExpenseQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  date: Types.Scalars['AWSDate']['input']
  accountUuid: Types.InputMaybe<Types.Scalars['ID']['input']>
  timeZone: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type TotalIncomeAndExpenseQueryResponse = {
  __typename?: 'Query'
  getCashFlowTotalAmounts: {
    __typename?: 'CashFlowTotalAmountsAmounts'
    cashFlowPeriodicTotalAmounts: Array<{
      __typename?: 'cashFlowPeriodicTotalAmount'
      transactionDirectionTotalAmounts: {
        __typename?: 'TransactionDirectionTotalAmounts'
        noOfIncome: number
        noOfExpense: number
        income: { __typename?: 'Money'; value: string }
        expense: { __typename?: 'Money'; value: string }
      }
      range: { __typename?: 'CashFlowReportRange'; start: any }
    }> | null
  }
}

export const TotalIncomeAndExpenseQuery = gql`
  query TotalIncomeAndExpenseQuery(
    $entityUuid: ID!
    $date: AWSDate!
    $accountUuid: ID
    $timeZone: String
  ) {
    getCashFlowTotalAmounts(
      entityUuid: $entityUuid
      date: $date
      accountUuid: $accountUuid
      timeZone: $timeZone
    ) {
      cashFlowPeriodicTotalAmounts {
        ...IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment
        ...IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment
        ...GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment
      }
    }
  }
  ${IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragmentDoc}
  ${IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragmentDoc}
  ${GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  TotalIncomeAndExpenseQueryResponse,
  TotalIncomeAndExpenseQueryVariables
>

/**
 * __useTotalIncomeAndExpenseQuery__
 *
 * To run a query within a React component, call `useTotalIncomeAndExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalIncomeAndExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalIncomeAndExpenseQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      date: // value for 'date'
 *      accountUuid: // value for 'accountUuid'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useTotalIncomeAndExpenseQuery(
  baseOptions: Apollo.QueryHookOptions<
    TotalIncomeAndExpenseQueryResponse,
    TotalIncomeAndExpenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TotalIncomeAndExpenseQueryResponse,
    TotalIncomeAndExpenseQueryVariables
  >(TotalIncomeAndExpenseQuery, options)
}
export function useTotalIncomeAndExpenseQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalIncomeAndExpenseQueryResponse,
    TotalIncomeAndExpenseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TotalIncomeAndExpenseQueryResponse,
    TotalIncomeAndExpenseQueryVariables
  >(TotalIncomeAndExpenseQuery, options)
}
export type TotalIncomeAndExpenseQueryHookResult = ReturnType<
  typeof useTotalIncomeAndExpenseQuery
>
export type TotalIncomeAndExpenseQueryLazyQueryHookResult = ReturnType<
  typeof useTotalIncomeAndExpenseQueryLazyQuery
>
export type TotalIncomeAndExpenseQueryQueryResult = Apollo.QueryResult<
  TotalIncomeAndExpenseQueryResponse,
  TotalIncomeAndExpenseQueryVariables
>
