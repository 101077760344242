import { Box } from '@npco/zeller-design-system'

import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'

import {
  StyledImageDisplayWrapper,
  StyledThumbnail,
  StyledThumbnailRow,
} from '../Images.styled'
import { Image } from '../Images.types'

interface Props {
  images: Image[]
  placeholder: string
  isLoadingImages?: boolean
  isDownloadingFiles?: boolean
  onClickThumbnail?: (id: string) => void
  thumbnailSize?: number
}

export const ImagesDisplay = ({
  images,
  placeholder,
  isDownloadingFiles,
  isLoadingImages,
  onClickThumbnail,
  thumbnailSize = 72,
}: Props) => {
  if (isLoadingImages) {
    return (
      <Box mb="3rem" height="0.75rem">
        <SkeletonLoaderBasic width="12rem" isRounded showOnLoadAnimation />
      </Box>
    )
  }

  if (images.length === 0 && !isDownloadingFiles) {
    return <StyledImageDisplayWrapper>{placeholder}</StyledImageDisplayWrapper>
  }

  return (
    <StyledImageDisplayWrapper>
      <StyledThumbnailRow>
        {images.map((image: Image) => (
          <StyledThumbnail
            isLoading={isDownloadingFiles}
            key={image.id}
            image={image}
            width={thumbnailSize}
            height={thumbnailSize}
            onClick={onClickThumbnail}
            showOnLoadAnimation
          />
        ))}
      </StyledThumbnailRow>
    </StyledImageDisplayWrapper>
  )
}
