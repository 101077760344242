import styled, { css } from 'styled-components'

export const CurrencySymbolWrapper = styled.div`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin-right: 8px;
`

// Work around for placeholder overlapping with left controls
export const Wrapper = styled.div<{ $isLeftAlign: boolean }>`
  ${({ $isLeftAlign }) =>
    $isLeftAlign &&
    css`
      .inputAdaptiveFloatingLabel,
      .inputAdaptivePlaceholder {
        margin-left: 16px;
      }
    `}
`
