import { createTranslations } from '@npco/utils-translations'

export const noTransactionsBannerTranslations = createTranslations({
  title: 'Income and expense reporting, simplified.',
  description:
    'Get an overview of your business spending and income sources by using Zeller Accounts and Cards.',
  item1:
    'Settle your earnings into a Zeller Transaction Account to get a full picture of your revenue streams',
  item2: 'Get better insights in your team spending by contacts and categories',
  item3: 'Identify trends or changes in cash flow over the past 12 months',
  item4:
    'Make better data-driven decisions with a full picture of your business financial health',
  ctaDescription:
    'Start transacting with Zeller Transaction Accounts and Cards to get your cash flow report today.',
  goToAccounts: 'Go to Accounts',
  alt: 'Income and expenses reporting',
})
