import { PillBasic, PillSize } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface SubcategoryItemProps {
  label: string
  text: string
  icon?: React.ReactNode
  onEdit?: () => void
  dataTestId?: string
}

export const CategoryItem = ({
  label,
  text,
  icon,
  onEdit,
  dataTestId,
}: SubcategoryItemProps) => {
  return (
    <DrawerItem
      label={label}
      value={
        <PillBasic
          size={PillSize.Small}
          text={text}
          icon={icon}
          onEdit={onEdit}
          editButtonText={translate('shared.edit')}
          closeIconAlt={translate('shared.close')}
          dataTestId={dataTestId}
        />
      }
    />
  )
}
