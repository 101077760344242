import { ReactElement } from 'react'

import { StyledGroup, StyledGroupList } from './Group.styled'

interface Props {
  elements: (ReactElement | null)[]
  header?: ReactElement | null
  isLastGroup: boolean
}

export const Group = ({ header, elements, isLastGroup }: Props) => {
  return (
    <StyledGroup data-testid="list-group">
      {header}
      <StyledGroupList isLastGroup={isLastGroup}>{elements}</StyledGroupList>
    </StyledGroup>
  )
}
