import { Box, Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { FileWithId } from 'components/UploadFileModal/hooks/useFileList'
import { UploadFileModal } from 'components/UploadFileModal/UploadFileModal'

import { StyledDescriptionWrapper } from './FailedUploadStep.styled'

interface Props {
  isOpen: boolean
  failedFiles: FileWithId[]
  onRetry: () => void
  onCancel: () => void
}

export const FailedUploadStep = ({
  isOpen,
  failedFiles,
  onRetry,
  onCancel,
}: Props) => {
  return (
    <UploadFileModal
      title={translate('component.failedUploadWarning.title', {
        failedUploads: failedFiles.length,
      })}
      isOpen={isOpen}
      onSave={onRetry}
      onCancel={onCancel}
      primaryButtonLabel={translate('shared.tryAgain')}
    >
      <Flex flexDirection="column" alignItems="stretch" mb="1rem">
        <StyledDescriptionWrapper>
          {translate('component.failedUploadWarning.description')}
        </StyledDescriptionWrapper>
        {failedFiles.map((fileWithId) => (
          <Box
            mb="0.25rem"
            key={fileWithId.file.name}
          >{`'${fileWithId.file.name}'`}</Box>
        ))}
      </Flex>
    </UploadFileModal>
  )
}
