import { useTranslations } from '@npco/utils-translations'
import {
  PillBasic,
  PillSize,
  Typography,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import {
  useSelectedDateRange,
  useSetSelectedDateRange,
} from '../../hooks/useSelectedDateRange'
import { convertToAWSDate } from '../../utils/convertToAWSDate/convertToAWSDate'
import { dayjs } from '../../utils/dayjs/dayjs'
import { monthSelectTranslations } from './MonthSelect.i18n'

type MonthSelectProps = {
  entityTimezone: string | undefined
}
export const MonthSelect = ({ entityTimezone }: MonthSelectProps) => {
  const isMobile = useIsMobileResolution()
  const { selectedDateRange } = useSelectedDateRange()
  const { resetSelectedDateRange } = useSetSelectedDateRange()

  const t = useTranslations(monthSelectTranslations)

  const currentMonth = dayjs().tz(entityTimezone).format('MMMM')
  const currentYear = dayjs().tz(entityTimezone).year()

  if (selectedDateRange === 'Last 12 Months') {
    return (
      <Typography variant="heading-md" component="span">
        {t('defaultMonth')}
      </Typography>
    )
  }
  const format = isMobile ? 'MMM YYYY' : 'MMMM YYYY'

  const end = dayjs(convertToAWSDate(selectedDateRange, entityTimezone)).tz(
    entityTimezone
  )

  if (selectedDateRange.type === 'TTM') {
    if (
      selectedDateRange.month === currentMonth &&
      currentYear === selectedDateRange.year
    ) {
      return (
        <Typography variant="heading-md" component="span">
          {t('defaultMonth')}
        </Typography>
      )
    }
    const start = end.subtract(11, 'months')

    return (
      <PillBasic
        text={`${start.format(format)} - ${end.format(format)}`}
        onClose={resetSelectedDateRange}
        size={PillSize.Small}
      />
    )
  }

  return (
    <PillBasic
      text={`${end.format(format)}`}
      onClose={resetSelectedDateRange}
      size={PillSize.Small}
    />
  )
}
