import type { ReactNode } from 'react'
import { useCallback } from 'react'

import { interpolate } from './interpolate'
import type {
  Translations,
  TranslationsEnvironment,
} from './TranslationsEnvironment'

export type CreateTranslationsEnvironmentOptions<
  LOCALE extends string = string
> = {
  locales: ReadonlyArray<LOCALE>
  useLocale: () => LOCALE | undefined
}

export const createTranslationsEnvironment = <LOCALE extends string>({
  locales,
  useLocale,
}: CreateTranslationsEnvironmentOptions<LOCALE>): TranslationsEnvironment<LOCALE> => {
  type ENVIRONMENT = TranslationsEnvironment<LOCALE>

  const createTranslations: ENVIRONMENT['createTranslations'] = (
    defaultMap,
    localeMaps
  ) =>
    ({
      DEFAULT: defaultMap,
      ...localeMaps?.(
        // @ts-expect-error This is okay. The "return" types are fake and only
        // used to check matching variables definitions defaultMap vs localeMap.
        (localeMap) => localeMap
      ),
    } as Translations<LOCALE, typeof defaultMap>)

  const useTranslations: ENVIRONMENT['useTranslations'] = (translations) => {
    const locale = useLocale()
    return useCallback(
      (
        key: string,
        variables?: Record<string, string | ReactNode>
      ): string | ReactNode => {
        const defaultMap = translations.DEFAULT
        const localeMap = locale && translations[locale]
        const value = localeMap?.[key] ?? defaultMap[key] ?? ''
        const valueInterpolated = interpolate(
          value,
          // @ts-expect-error Tuple/args error is hard to resolve.
          variables
        )
        return valueInterpolated
      },
      [locale, translations]
    ) as ReturnType<ENVIRONMENT['useTranslations']>
  }

  return {
    locales,
    createTranslations,
    useTranslations,
  }
}
