import { useCallback, useRef } from 'react'
import { InvoiceDiscountConfig, InvoiceItemUnit } from '@npco/mp-gql-types'
import {
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_DEFAULT_UNIT_QUANTITY,
  INVOICE_ITEMS_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { FormikHelpers } from 'formik'

import { translate } from 'utils/translations'
import { CreateInvoiceItem_createItem as CreateInvoiceItem } from 'types/gql-types/CreateInvoiceItem'
import { ItemTaxApplicableItems } from 'components/ItemBaseModalForm'

import { getTaxRate } from '../../InvoiceItemsAccordion.utils'
import { SaveItemFormFields } from '../InvoiceItemCreateModal/InvoiceItemCreateModal.types'
import { useUpdateInvoiceItem } from './useUpdateInvoiceItem'

export const translations = {
  updatedItemErrorNotification: translate(
    'page.invoice.formSections.items.updateItemErrorNotification'
  ),
  updatedItemSuccessNotification: translate(
    'page.invoice.formSections.items.updateItemSuccessNotification'
  ),
}

export interface UseInvoiceItemsProps {
  setFieldValue: FormikHelpers<InvoiceFormFields>['setFieldValue']
  values: InvoiceFormFields
}

export const useInvoiceItems = ({
  setFieldValue,
  values,
}: UseInvoiceItemsProps) => {
  const isTaxInclusive = values.itemsTaxInclusive

  const {
    closeModal: closeSaveItemModal,
    isModalOpen: isSaveItemModalOpen,
    openModal: openSaveItemModal,
  } = useModalState()

  const {
    closeModal: closeItemDiscountModal,
    isModalOpen: isItemDiscountModalOpen,
    openModal: openItemDiscountModal,
  } = useModalState()

  const {
    closeModal: closeUpdateItemModal,
    isModalOpen: isUpdateItemModalOpen,
    openModal: openUpdateItemModal,
  } = useModalState()

  const { updateInvoiceItem: updateItem, isUpdatingItem } =
    useUpdateInvoiceItem()

  const addItemDiscountIndexRef = useRef<number | undefined>()
  const initialItemsValuesRef = useRef<InvoiceItem[]>(values.items)
  const saveItemInitialValuesRef = useRef<SaveItemFormFields | undefined>()
  const updateItemIndexRef = useRef<number | undefined>()

  const handleOnAddItemDiscount = useCallback(
    (index: number) => () => {
      addItemDiscountIndexRef.current = index
      openItemDiscountModal()
    },
    [openItemDiscountModal]
  )

  const handleOnCloseItemDiscount = useCallback(() => {
    addItemDiscountIndexRef.current = undefined
    closeItemDiscountModal()
  }, [closeItemDiscountModal])

  const handleOnCloseSaveItem = useCallback(() => {
    saveItemInitialValuesRef.current = undefined
    closeSaveItemModal()
  }, [closeSaveItemModal])

  const handleOnCancelUpdateItem = useCallback(() => {
    updateItemIndexRef.current = undefined
    closeUpdateItemModal()
  }, [closeUpdateItemModal])

  const handleOnSaveItem = useCallback(
    (index: number) => (item: InvoiceItem) => {
      const taxRate = getTaxRate(item.taxApplicable, isTaxInclusive)
      const price = item.price.multiply(taxRate)

      saveItemInitialValuesRef.current = {
        categories: [],
        description: item.description,
        index,
        name: item.name,
        isTaxInclusive,
        taxApplicable: item.taxApplicable
          ? ItemTaxApplicableItems.TaxApplicable
          : ItemTaxApplicableItems.NoTax,
        sku: '',
        // NOTE: invoice total has centi cents precision so wrap in new
        // currency object to get nearest cent precision (formatted/displayed total)
        price: currency(price).toString(),
      }
      openSaveItemModal()
    },
    [isTaxInclusive, openSaveItemModal]
  )

  const handleOnSaveCatalogItem = useCallback(
    (index: number, item: CreateInvoiceItem) => {
      const isTaxApplicable = item.taxes?.some((tax) => tax.enabled) || false

      const value: InvoiceItem = {
        catalogItemUuid: item.id,
        description: item.description ?? '',
        discount: {
          config: InvoiceDiscountConfig.AMOUNT,
          percentage: INVOICE_DEFAULT_PERCENTAGE,
          price: INVOICE_DEFAULT_PRICE,
        },
        key: index + 1,
        hasCalculation: false,
        initialDescription: item.description ?? '',
        initialName: item.name,
        // NOTE: Must wrap in another instance of currency as an issue when
        // performing subsequent calculations on an instance using the `fromCents`
        // option https://github.com/scurker/currency.js/issues/425
        initialPrice: currency(
          currency(item.price, { fromCents: true, precision: 4 }),
          { precision: 4 }
        ),
        initialQuantity: INVOICE_DEFAULT_UNIT_QUANTITY,
        name: item.name,
        // NOTE: Must wrap in another instance of currency as an issue when
        // performing subsequent calculations on an instance using the `fromCents`
        // option https://github.com/scurker/currency.js/issues/425
        price: currency(
          currency(item.price, { fromCents: true, precision: 4 }),
          { precision: 4 }
        ),
        quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
        taxApplicable: isTaxApplicable,
        unit: InvoiceItemUnit.QUANTITY,
      }

      setFieldValue(`${INVOICE_ITEMS_FIELD}.${index}`, value)

      handleOnCloseSaveItem()
    },
    [handleOnCloseSaveItem, setFieldValue]
  )

  const handleOnUpdateItem = useCallback(
    (index: number) =>
      async (item: InvoiceItem, skipPriceCheck = false) => {
        try {
          const hasPriceChanged =
            item.initialPrice.intValue !== item.price.intValue

          // NOTE: if price has changed confirm update with user
          if (hasPriceChanged && !skipPriceCheck) {
            updateItemIndexRef.current = index
            openUpdateItemModal()

            return
          }

          await updateItem({
            description: item.description,
            id: item.catalogItemUuid,
            name: item.name,
            price: item.price.intValue,
          })

          closeUpdateItemModal()

          // NOTE: reset initial catalog properties to reset changed state
          setFieldValue(`${INVOICE_ITEMS_FIELD}.${index}`, {
            ...item,
            initialDescription: item.description,
            initialName: item.name,
            initialPrice: item.price,
          })

          showSuccessToast(translations.updatedItemSuccessNotification)
        } catch (error) {
          showErrorToast(translations.updatedItemErrorNotification)
        }
      },
    [closeUpdateItemModal, openUpdateItemModal, setFieldValue, updateItem]
  )

  return {
    addItemDiscountIndexRef,
    handleOnAddItemDiscount,
    handleOnCloseItemDiscount,
    handleOnCloseSaveItem,
    handleOnCancelUpdateItem,
    handleOnSaveCatalogItem,
    handleOnSaveItem,
    handleOnUpdateItem,
    initialItemsValuesRef,
    isItemDiscountModalOpen,
    isSaveItemModalOpen,
    isUpdateItemModalOpen,
    isUpdatingItem,
    openItemDiscountModal,
    openUpdateItemModal,
    saveItemInitialValuesRef,
    updateItemIndexRef,
  }
}
