import { ReactComponent as AccountsIcon } from 'assets/svg/Sidebar/accounts.svg'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { useAccountMultiselectWithAllElement } from 'layouts/AccountLayout/hooks/useAccountMultiselectWithAllElement/useAccountMultiselectWithAllElement'
import { page } from 'translations'

import { MultiSelectFilter } from '../MultiSelectFilter/MultiSelectFilter'

export const AccountFilterTransactionType = () => {
  const {
    transactionType: transactionTypesExcludingAll,
    setTransactionType,
    resetTransactionType,
    config,
  } = useAccountFilters()

  const {
    listItems,
    selectedItems,
    onChange: updateSelectedTypes,
    onClose,
    areAllItemsSelected,
  } = useAccountMultiselectWithAllElement({
    data: transactionTypesExcludingAll || [],
    setData: setTransactionType,
    dataItems: config.transactionType.items,
  })

  return (
    <MultiSelectFilter
      listItems={listItems}
      selectedItems={selectedItems}
      values={transactionTypesExcludingAll || []}
      onChange={updateSelectedTypes}
      onClose={onClose}
      onReset={resetTransactionType}
      filterName={page.accounts.transactionsList.filters.transactionType}
      maxHeight="432px"
      areAllItemsSelected={areAllItemsSelected}
      icon={<AccountsIcon />}
    />
  )
}
