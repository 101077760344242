import { INVOICE_SCHEDULE_SEND_DATE_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { useField } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import dayjs from 'utils/dayjs'

export const useScheduleSend = () => {
  const isMobileResolution = useIsMobileResolution()

  const [field, meta, helpers] = useField(INVOICE_SCHEDULE_SEND_DATE_FIELD)

  return {
    disabledDatePickerDays: {
      before: dayjs().add(1, 'day').toDate(),
    },
    fieldError: meta.touched && meta.error ? meta.error : '',
    fieldName: field.name,
    fieldValue: field.value,
    handleBlur: field.onBlur,
    handleDateChange: helpers.setValue,
    isMobileResolution,
  }
}
