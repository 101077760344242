import { SkeletonSvgBase } from '@npco/zeller-design-system'

const CENTER_X = 256 / 2

const loaders = [
  {
    id: 'performance-chart-salesThisMonth',
    y: 98,
    width: 146,
    height: 20,
  },
  {
    id: 'performance-chart-comparison',
    y: 137,
    width: 124,
    height: 8,
  },
  {
    id: 'performance-chart-lastMonth',
    y: 155,
    width: 84,
    height: 8,
  },
]

export const LoadingCentreText = () => (
  <g data-testid="loading-text-centre">
    {loaders.map(({ id, y, width, height }) => (
      <SkeletonSvgBase
        key={id}
        x={CENTER_X - width / 2}
        y={y}
        width={width}
        height={height}
      />
    ))}
  </g>
)
