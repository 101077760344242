import {
  Box,
  DATAPOINT_TEXT_SIZE,
  DatapointCurrency,
  DatapointText,
  Flex,
  TOOLTIP_ZINDEX,
  TooltipButton,
} from '@npco/zeller-design-system'

import { getDatapointValues } from 'utils/datapoint'
import { formatDate, formatDayMonth } from 'utils/date'
import { translate } from 'utils/translations'
import { GetContactTransactionTotals_getContactTransactionTotals as ContactTransactionTotals } from 'types/gql-types/GetContactTransactionTotals'

import { TransactionTotalsSkeleton } from '../TransactionTotalsSkeleton/TransactionTotalsSkeleton'

export const defaultValues = {
  amountText: '$0.00',
  integerText: '0',
  decimalText: '00',
}

interface TransactionTotalsProps {
  isLoading?: boolean
  transactionData: ContactTransactionTotals | null
}

export const TransactionTotals = ({
  isLoading,
  transactionData,
}: TransactionTotalsProps) => {
  if (isLoading) {
    return <TransactionTotalsSkeleton />
  }
  if (!transactionData) {
    return null
  }
  const { count, latestTimestamp, totalAmount } = transactionData

  const latestDateWithMonth = formatDayMonth(latestTimestamp)
  const latestDateTooltipText = formatDate(latestTimestamp)

  const hasTransactions = count > 0

  const averageValues = hasTransactions
    ? getDatapointValues(totalAmount / count)
    : defaultValues

  const totalValues = hasTransactions
    ? getDatapointValues(totalAmount)
    : defaultValues

  const isAverageAmountRounded = averageValues.decimalText === null
  const isTotalAmountRounded = totalValues.decimalText === null

  return (
    <Flex
      data-testid="contact-transaction-total"
      justifyContent="space-between"
      mb="3rem"
    >
      <Flex flex="1 1 0" justifyContent="flex-start" textAlign="left">
        {hasTransactions ? (
          <TooltipButton
            dataTestId="transaction-total"
            placement="top"
            tooltipIcon={
              <DatapointCurrency
                decimalText={totalValues.decimalText}
                integerText={totalValues.integerText}
                size={DATAPOINT_TEXT_SIZE.SMALL}
                title={translate(
                  'page.transactions.contact.transactionTotal.totalSpendTitle'
                )}
              />
            }
            zIndex={Number(TOOLTIP_ZINDEX)}
          >
            <Flex flexDirection="column" alignItems="center">
              {isTotalAmountRounded && <Box>{totalValues.amountText}</Box>}
              <Box>{`${count} transactions`}</Box>
            </Flex>
          </TooltipButton>
        ) : (
          <DatapointCurrency
            decimalText={totalValues.decimalText}
            integerText={totalValues.integerText}
            size={DATAPOINT_TEXT_SIZE.SMALL}
            title={translate(
              'page.transactions.contact.transactionTotal.totalSpendTitle'
            )}
          />
        )}
      </Flex>
      <Flex flex="1 1 0" justifyContent="center" textAlign="left">
        {hasTransactions && isAverageAmountRounded ? (
          <TooltipButton
            dataTestId="average-total"
            placement="top"
            tooltipIcon={
              <DatapointCurrency
                decimalText={averageValues.decimalText}
                integerText={averageValues.integerText}
                size={DATAPOINT_TEXT_SIZE.SMALL}
                title={translate(
                  'page.transactions.contact.transactionTotal.avgSpendTitle'
                )}
              />
            }
            zIndex={Number(TOOLTIP_ZINDEX)}
          >
            {averageValues.amountText}
          </TooltipButton>
        ) : (
          <DatapointCurrency
            decimalText={averageValues.decimalText}
            integerText={averageValues.integerText}
            size={DATAPOINT_TEXT_SIZE.SMALL}
            title={translate(
              'page.transactions.contact.transactionTotal.avgSpendTitle'
            )}
          />
        )}
      </Flex>
      <Flex flex="1 1 0" justifyContent="flex-end" textAlign="left">
        {hasTransactions ? (
          <TooltipButton
            dataTestId="last-visit"
            placement="top"
            tooltipIcon={
              <DatapointText
                size={DATAPOINT_TEXT_SIZE.SMALL}
                text={latestDateWithMonth}
                title={translate(
                  'page.transactions.contact.transactionTotal.lastVisitedTitle'
                )}
              />
            }
            zIndex={Number(TOOLTIP_ZINDEX)}
          >
            {latestDateTooltipText}
          </TooltipButton>
        ) : (
          <DatapointText
            size={DATAPOINT_TEXT_SIZE.SMALL}
            text="-"
            title={translate(
              'page.transactions.contact.transactionTotal.lastVisitedTitle'
            )}
          />
        )}
      </Flex>
    </Flex>
  )
}
