import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

export const useActivateCardButton = () => {
  const { goToAddCardStage } = useGoToAddCardStage()

  const goToActivateCard = useCallback(() => {
    goToAddCardStage({ stage: AddCardStage.LinkCardIdStage })
  }, [goToAddCardStage])

  return { goToActivateCard }
}
