import { createTranslations } from '@npco/utils-translations'

export const setupFlowInvoicesStageTranslations = createTranslations({
  badge: 'Invoices',
  title: 'Send and get invoices paid faster.',
  description:
    'Zeller Invoices is a free, powerful way to get your invoices paid, with no monthly subscription fees.',
  point1: 'Send unlimited free invoices.',
  point2: 'Fast, secure online payment acceptance.',
  point3: 'Customisable invoices templates.',
  point4: 'Track payment status and automate reminders.',
})
