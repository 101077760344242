import { gql } from '@apollo/client'
import { ContactType, ImageSize } from '@npco/mp-gql-types'
import { COLOR, getColorWithName } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { getIconImageUrlBySize } from 'utils/iconHelper'
import { translate } from 'utils/translations'

import { ContactFormData } from '../../Contacts.types'
import { initialValues } from '../../Contacts.utils'
import { rvContactProfilePictureMap } from '../../rv-deprecated/contacts'
import { GetContactProfilePictureUrlContactFragment } from './ContactGeneral.util.generated'

type Contact = ContactCoreFieldsFragment
type PaymentInstrument = NonNullable<Contact['paymentInstruments']>[0]

export const getAddress = (contact: Contact) => {
  if (
    !contact.address ||
    Object.values(contact?.address).every((value) => !value)
  ) {
    return '-'
  }

  const { street, suburb, state, postcode, country } = contact.address

  return [street, suburb, `${state ?? ''} ${postcode ?? ''}`.trim(), country]
    .filter(Boolean)
    .join(', ')
}

export const getContactProfilePictureUrl = (
  contact: GetContactProfilePictureUrlContactFragment | null
): string => {
  if (!contact?.id) {
    return ''
  }

  const contactProfilePictureMap = rvContactProfilePictureMap()

  // NOTE: the optimistic profile value can be a string or null, in cases where
  // this is null we've removed the contacts profile photo and want this changed
  // to be persisted
  const optimisticProfilePhoto = contactProfilePictureMap[contact.id]

  if (optimisticProfilePhoto === null) {
    return ''
  }

  if (optimisticProfilePhoto) {
    return optimisticProfilePhoto
  }

  return (
    getIconImageUrlBySize(contact?.icon, ImageSize.Thumbnail) ??
    getIconImageUrlBySize(contact?.icon, ImageSize.Native) ??
    ''
  )
}

getContactProfilePictureUrl.fragments = {
  Contact: gql`
    fragment GetContactProfilePictureUrlContactFragment on Contact {
      id
      icon {
        ...GetIconImageUrlBySizeIconFragment
        colour
        letter
      }
    }

    ${getIconImageUrlBySize.fragments.Icon}
  `,
}

const getAddressFromContact = (contact: Contact) => {
  return {
    country: contact.address?.country || '',
    postcode: contact.address?.postcode || '',
    state: contact.address?.state || '',
    street: contact.address?.street || '',
    suburb: contact.address?.suburb || '',
  }
}

export const getAdditionalEmailsFromContact = (contact: Contact) => {
  return (contact.additionalEmails || []).filter((additionalEmail) =>
    Boolean(additionalEmail.email)
  )
}

export const getAdditionalPhonesFromContact = (contact: Contact) => {
  return (contact.additionalPhones || []).filter((additional) =>
    Boolean(additional.phone)
  )
}

const getContactFormBusinessInitialValues = (contact: Contact) => ({
  ...initialValues,
  business: {
    abn: contact.abn || '',
    acn: contact.acn || '',
    additionalEmails: getAdditionalEmailsFromContact(contact),
    additionalPhones: getAdditionalPhonesFromContact(contact),
    email: {
      email: contact.email?.email || '',
      label: contact.email?.label || null,
    },
    businessName: contact.businessName || '',
    phone: '',
    phoneV2: {
      phone: contact.phoneV2?.phone || '',
      label: contact.phoneV2?.label || null,
    },
    website: contact.website || '',
    isSelf: contact.isSelf,
    ...getAddressFromContact(contact),
  },
})

const getContactFormPersonInitialValues = (contact: Contact) => ({
  ...initialValues,
  person: {
    additionalEmails: getAdditionalEmailsFromContact(contact),
    additionalPhones: getAdditionalPhonesFromContact(contact),
    email: {
      email: contact.email?.email || '',
      label: contact.email?.label || null,
    },
    firstName: contact.firstName || '',
    jobTitle: contact.jobTitle || '',
    lastName: contact.lastName || '',
    phone: contact.phone || '',
    phoneV2: {
      phone: contact.phoneV2?.phone || '',
      label: contact.phoneV2?.label || null,
    },
    isSelf: contact.isSelf,
    ...getAddressFromContact(contact),
  },
})

export const getContactFormInitialValues = (
  contact: Contact | null
): ContactFormData => {
  if (!contact) {
    return initialValues
  }

  if (contact.contactType === ContactType.BUSINESS) {
    return getContactFormBusinessInitialValues(contact)
  }

  return getContactFormPersonInitialValues(contact)
}

export const getContactAvatarBadgeOptions = (contact: Contact | null) => {
  if (!contact) {
    return {
      text: translate('shared.zeller'),
    }
  }

  const bgColor = getColorWithName(contact.icon?.colour)
  const profilePictureUrl = getContactProfilePictureUrl(contact)

  const userDefinedContent = profilePictureUrl ? (
    <img src={profilePictureUrl} alt="" />
  ) : undefined

  return {
    bgColor: !bgColor || bgColor === COLOR.GREY_250 ? COLOR.GREY_90 : bgColor,
    text: contact.icon?.letter || translate('shared.zeller'),
    userDefinedContent,
  }
}

export const byBankAccounts = (paymentInstrument: PaymentInstrument) =>
  !paymentInstrument.details || !('billerCode' in paymentInstrument.details)

export const byBpayAccounts = (paymentInstrument: PaymentInstrument) =>
  paymentInstrument.details && 'billerCode' in paymentInstrument.details
