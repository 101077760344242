import Modal from 'react-modal'
import {
  BUTTON_SIZE,
  ButtonGhostIconOnly,
  ButtonGhostIconRight,
  COLOR,
  Flex,
} from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

const PREVIEW_HEADER_HEIGHT = '80px'

export const TerminalPreviewModalWrapper = styled(Modal)`
  background: ${({ theme: { colors } }) => rgba(colors.BLACK_900, 0.75)};
  box-sizing: border-box;
  left: 50%;
  height: 100%;
  width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const TerminalPreviewHeader = styled(Flex)`
  color: ${({ theme }) => theme.colors.WHITE};
  height: ${PREVIEW_HEADER_HEIGHT};
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

const lightboxButton = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;

  &:hover {
    background: ${({ theme: { colors } }) => rgba(colors.WHITE, 0.05)};
  }

  &:focus-visible {
    outline: 3px solid ${({ theme: { colors } }) => rgba(colors.WHITE, 0.1)};
  }
`

export const TerminalPreviewBody = styled(Flex)`
  height: calc(100% - ${PREVIEW_HEADER_HEIGHT});
`

export const TerminalPreviewIconButton = styled(ButtonGhostIconOnly).attrs({
  size: BUTTON_SIZE.XSMALL,
  iconEnabledColor: COLOR.WHITE,
})`
  ${lightboxButton}
`

export const TerminalPreviewButton = styled(ButtonGhostIconRight).attrs({
  size: BUTTON_SIZE.XSMALL,
  iconEnabledColor: COLOR.WHITE,
})<{ $full?: boolean }>`
  ${lightboxButton}
  ${({ $full }) =>
    $full &&
    css`
      width: 100%;
      height: 100%;

      &&:hover {
        background: transparent;
        background-color: transparent;
      }
    `}
`
