import { COLOR, SvgIcon, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as PercentageIcon } from 'assets/svg/percentage.svg'
import { SettingsPayments } from 'types/settings'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import { validateTip } from './Tips.form'

interface Props {
  label: string
  name: string
}

export const TipField = ({ label, name }: Props) => {
  const { values } = useFormikContext<SettingsPayments>()
  return (
    <InputAdaptiveFieldWrapper
      size={TEXT_INPUT_SIZE.SMALL}
      label={label}
      name={name}
      type="number"
      validate={validateTip}
      disabled={!values.tipEnabled}
      min={0}
      renderRightControls={() => (
        <SvgIcon
          width="16"
          height="16"
          withMarginRight={false}
          color={COLOR.BLUE_1000}
        >
          <PercentageIcon />
        </SvgIcon>
      )}
    />
  )
}
