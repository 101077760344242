import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { SimDeviceCellFragmentDoc } from './TableCells/SimDeviceCell.generated'
import { SimIdCellFragmentDoc } from './TableCells/SimIdCell.generated'
import { SimSiteCellFragmentDoc } from './TableCells/SimSiteCell.generated'
import { SimStatusCellFragmentDoc } from './TableCells/SimStatusCell.generated'

export type SimTableFragment = {
  __typename?: 'Sim'
  id: string
  billingStatus: Types.SimBillingStatus
  deviceName: string | null
  siteName: string | null
}

export const SimTableFragmentDoc = gql`
  fragment SimTableFragment on Sim {
    ...SimIdCellFragment
    ...SimDeviceCellFragment
    ...SimSiteCellFragment
    ...SimStatusCellFragment
  }
  ${SimIdCellFragmentDoc}
  ${SimDeviceCellFragmentDoc}
  ${SimSiteCellFragmentDoc}
  ${SimStatusCellFragmentDoc}
` as unknown as TypedDocumentNode<SimTableFragment, undefined>
