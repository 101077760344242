/*
    ACN Validator
    Checks for ACN Validity
    Based on ACN format documentation:
    https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/
*/

export const getIsAcnValid = (value: string): boolean => {
  const checkValuePosition = 8
  const validAcnLength = 9
  const weights = [8, 7, 6, 5, 4, 3, 2, 1]
  const acn = value.replace(/\D/g, '')

  if (acn.length !== validAcnLength) {
    return false
  }

  const acnValues = acn
    .split('')
    .map((acnDigit: string) => parseInt(acnDigit, 10))

  const checkSum = weights.reduce(
    (accumulator: number, weight: number, position: number) =>
      accumulator + weight * acnValues[position],
    0
  )

  const check = (10 - (checkSum % 10)) % 10

  return acnValues[checkValuePosition] === check
}
