import { createTranslations } from '@npco/utils-translations'

import { Period } from './PeriodButtons.utils'

export const translations = createTranslations({
  thisWeek: 'This week',
  lastWeek: 'Last week',
  thisMonth: 'This month',
  lastMonth: 'Last month',
  lastThirtyDays: 'Last 30 days',
} satisfies Record<Period, string>)
