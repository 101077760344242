import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  moto: 'MOTO',
  motoDescription: 'Manually enter card details on your Zeller Terminal.',
  processingFee: 'Processing Fee',
  defaultMoto: 'Default to MOTO',
  requirePIN: 'Require site PIN',
  requirePINdescription:
    'You will be asked to enter your site PIN every time you process a MOTO payment.',
  motoSurcharge: 'MOTO surcharge',
  totalFees: 'Surcharge my total fees',
  percentage: 'Surcharge a percentage',
  description1:
    'Under Australian law, a card surcharge amount is limited to the direct costs incurred by the merchant.',
  description2: "Zeller's maximum surcharge is {value}%",
  turnMotoOn: 'Turn MOTO on',
  turnMotoOnDescription:
    'The processing fee for MOTO payments is higher than for payments where the card is physically presented. MOTO payments carry an increased risk of fraud, which may result in disputes and chargebacks to your business. You should only accept MOTO payments where the transaction value is within your tolerance for loss.',
  accept: 'Accept',
  cardSurcharge: 'Card Surcharge',
})
