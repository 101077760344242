import { BodyDefault, Box, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import SetupFlowCardsCardImageFront from 'assets/images/setup-flow/setup-flow-cards-debit-card-front.png'

import { DebitCardImageStyles } from '../../DebitCardDetails.styled'

export const SensitiveContentBack = styled(Flex)<{
  $isVisible: boolean
}>`
  flex-direction: column;
  width: 318px;
  box-shadow: 1.04px 1.04px 6.27px 2.09px rgba(0, 0, 0, 0.12);
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  gap: 8px;
  position: absolute;
  left: -48px;
  top: 18px;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: visibility 0.3s ease-out, opacity 0.25s ease-out;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    left: -26px;
    top: 32px;
  }
`

export const SensitiveContentFront = styled(Box)<{
  $isVisible: boolean
}>`
  background: url(${SetupFlowCardsCardImageFront}) center 0 / cover;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.25s ease-out;
  ${DebitCardImageStyles}
`

export const SensitiveInfoLabel = styled(BodyDefault)`
  margin: 0;
  font-weight: 500;
`
