import { Box, Flex, PageTemplate, Tabs } from '@npco/zeller-design-system'

import { AccountTotalBalance } from '../AccountTotalBalance/AccountTotalBalance'
import * as styled from './AccountsLayoutSecondaryHeaderRow.styled'
import { useTabs } from './hooks/useTabs'

export const AccountsLayoutSecondaryHeaderRow = () => {
  const { tabs, currentTab } = useTabs()

  return (
    <PageTemplate.HeaderSecondaryRow>
      <Box width="100%">
        <Flex
          data-testid="accounts-layout-tabs"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Tabs currentTab={currentTab}>
            {tabs.map((tab) => (
              <Tabs.Item key={tab.value} {...tab} />
            ))}
          </Tabs>

          <styled.DesktopBalanceWrapper alignItems="flex-start">
            <AccountTotalBalance />
          </styled.DesktopBalanceWrapper>
        </Flex>
      </Box>
    </PageTemplate.HeaderSecondaryRow>
  )
}
