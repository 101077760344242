export const getActiveColorList = (
  activeColorCount: number,
  fullColorList: string[]
) => {
  if (activeColorCount >= fullColorList.length) {
    return fullColorList
  }

  const interval = fullColorList.length / activeColorCount
  return Array.from({ length: activeColorCount }).map((_, index) => {
    return fullColorList[Math.floor(index * interval)]
  })
}
