import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  BadgeSize,
  BREAKPOINT,
  ComingSoonBadge,
  HeadingStyles,
  NewBadge,
  NumberBadge,
  NumberBadgeVariant,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translations } from 'translations/shared.i18n'

import * as styled from './SubItemElement.styled'

interface SubItemElementProps {
  excludeSubPaths?: string[]
  isComingSoon?: boolean
  isNew?: boolean
  isDisabled?: boolean
  numberBadgeCount?: number
  title: string
  path: string
  isFolded: boolean
}

export const SubItemElement = ({
  excludeSubPaths,
  isComingSoon,
  isNew,
  isDisabled,
  numberBadgeCount,
  isFolded,
  path,
  title,
}: SubItemElementProps) => {
  const location = useLocation()
  const tShared = useTranslations(translations)
  const isLgBreakpoint = useIsMobileResolution(BREAKPOINT.LG)
  const isMdBreakpoint = useIsMobileResolution(BREAKPOINT.MD)

  const showDot = isLgBreakpoint && !isMdBreakpoint
  const className = useMemo(() => {
    if (excludeSubPaths?.some((path) => location.pathname.includes(path))) {
      return 'unselected'
    }
    return ''
  }, [location.pathname, excludeSubPaths])

  return (
    <styled.Subitem isDisabled={isDisabled}>
      <styled.ItemLink
        to={path}
        data-testid="subitem-link"
        className={className}
      >
        <styled.TitleNoMargin
          withStyles={HeadingStyles.H5}
          $isFolded={isFolded}
        >
          {title}
        </styled.TitleNoMargin>
        {isDisabled && !isFolded && (
          <styled.Description>{tShared('comingSoon')}</styled.Description>
        )}
        {isComingSoon && (
          <ComingSoonBadge
            showDot={showDot}
            size={BadgeSize.Small}
            text={tShared('comingSoon')}
          />
        )}
        {isNew && (
          <NewBadge
            showDot={showDot}
            size={BadgeSize.Small}
            text={tShared('new')}
          />
        )}
        {Boolean(numberBadgeCount) && (
          <NumberBadge
            size={BadgeSize.Small}
            text={`${numberBadgeCount}`}
            variant={NumberBadgeVariant.Alert}
          />
        )}
      </styled.ItemLink>
    </styled.Subitem>
  )
}
