import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SetSpendControlForm } from './SetSpendControlForm/SetSpendControlForm'

export const SetCardSpendControlStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.CreateSpendControlsStage}
    >
      <SetSpendControlForm />
    </Condition>
  )
}
