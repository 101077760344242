import { Dispatch } from 'react'
import {
  DrawerNavigationProvider,
  DrawerWithNavigation,
} from '@npco/ui-drawer-navigation'
import {
  AvatarBasic,
  COLOR,
  Flex,
  Typography,
} from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'
import { getNonNullString } from 'utils/string'
import { GetDeposit_getDeposit as Deposit } from 'types/gql-types/GetDeposit'
import { DepositDetails } from 'components/DepositDetails'
import { SwitchAnimationContainer } from 'components/DrawerWithNavigation/DrawerNavigation/SwitchAnimationContainer/SwitchAnimationContainer'

interface DepositDrawerProps {
  selectedDeposit: Deposit
  setSelectedDeposit: Dispatch<React.SetStateAction<Deposit | null>>
  isModalOpen: boolean
  closeModal: () => void
  onSelectPreviousDeposit?: () => void
  onSelectNextDeposit?: () => void
}

export const DepositDrawer = ({
  selectedDeposit,
  setSelectedDeposit,
  isModalOpen,
  closeModal,
  onSelectPreviousDeposit,
  onSelectNextDeposit,
}: DepositDrawerProps) => {
  return (
    <DrawerNavigationProvider
      onPrevious={onSelectPreviousDeposit}
      onNext={onSelectNextDeposit}
    >
      <DrawerWithNavigation
        title={
          <AvatarBasic
            bgColor={COLOR.BLUE_80}
            color={COLOR.BLUE_1000}
            text={selectedDeposit?.siteName ?? ''}
          />
        }
        headerContent={
          <Flex justifyContent="space-between">
            <Typography
              data-testid="drawerTitle"
              component="h2"
              variant="heading-2xl"
            >
              {getNonNullString(selectedDeposit?.siteName)}
            </Typography>
            <Typography component="h2" variant="heading-2xl">
              {currencyFormatter(selectedDeposit.amountSettled)}
            </Typography>
          </Flex>
        }
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <SwitchAnimationContainer animationKey={selectedDeposit?.id}>
          <DepositDetails
            selectedDeposit={selectedDeposit}
            setSelectedDeposit={setSelectedDeposit}
          />
        </SwitchAnimationContainer>
      </DrawerWithNavigation>
    </DrawerNavigationProvider>
  )
}
