import { ActivateDebitCardErrorEnum } from '@npco/mp-gql-types'

import { ActivateDebitCardMutationResponse } from './graphql/ActivateDebitCard.generated'

type ActivateDebitCardResult =
  ActivateDebitCardMutationResponse['activateDebitCard']
type ApiError = Extract<
  ActivateDebitCardMutationResponse['activateDebitCard'],
  { __typename: 'ActivateDebitCardError' }
>

type DebitCardV2 = Extract<
  ActivateDebitCardMutationResponse['activateDebitCard'],
  { __typename: 'DebitCardV2' }
>

export const SUPPORTED_ERRORS = Object.values(ActivateDebitCardErrorEnum)

export const getIsApiError = (
  result: ActivateDebitCardResult
): result is ApiError => Boolean((result as ApiError).error)

export const getIsDebitCardV2 = (
  maybeDebitCardV2:
    | DebitCardV2
    | ActivateDebitCardErrorEnum
    | ActivateDebitCardResult
): maybeDebitCardV2 is DebitCardV2 =>
  Boolean((maybeDebitCardV2 as DebitCardV2).id)
