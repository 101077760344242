import {
  BodyDefaultTypography,
  Flex,
  Subtitle,
} from '@npco/zeller-design-system'
import {
  StyledContent,
  StyledSelectSubLabelsWrapper,
} from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerAdaptive.styled'
import styled from 'styled-components'

import { StyledBankIcon } from 'components/Tile/ThirdPartyAccountTile/ThirdPartyAccountTile.styled'

export const StyledListItemWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: 7px;
  padding: 0.5rem 0.5rem;

  :hover {
    background: ${({ theme }) => theme.colors.BLUE_80};
  }

  ${StyledSelectSubLabelsWrapper} {
    line-height: 1.5rem;
  }

  ${StyledBankIcon} {
    margin-right: 1.25rem;
  }

  ${StyledContent} {
    white-space: break-spaces;
    word-break: break-word;
  }
`

export const StyledStatus = styled(Subtitle)`
  ${BodyDefaultTypography}
  line-height: 1.5rem;
  margin-top: 2px;
`
