import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  label: 'Set Custom Station ID',
  locationTooltip:
    'This is your Sysnet Terminal Identifier. Allows transaction to be sent to the correct location for reporting & printing.',
  venueTooltip:
    'This is your Sysnet Terminal Identifier. Allows transaction to be sent to the correct venue for reporting & printing.',
  venueStationIdToggle: 'venueStationIdToggle',
  locationStationIdToggle: 'locationStationIdToggle',
})
