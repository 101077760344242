import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  Flex,
  SkeletonBasic,
  Typography,
  useModalState,
} from '@npco/zeller-design-system'

import { getNonNullString } from 'utils/string'
import { DetailsField } from 'components/DetailsField/DetailsField'

import { translations } from './OraclePosDetails.i18n'
import { PairModal } from './PairModal/PairModal'
import { UnpairModal } from './UnpairModal/UnpairModal'

type OraclePosDetailsProps = {
  deviceName: string
  deviceUuid: string
  isLoading: boolean
  workstationId?: string
}
export const OraclePosDetails = ({
  deviceName,
  deviceUuid,
  isLoading,
  workstationId,
}: OraclePosDetailsProps) => {
  const t = useTranslations(translations)
  const {
    isModalOpen: isUnpairModalOpen,
    closeModal: closeUnpairModal,
    openModal: openUnpairModal,
  } = useModalState()
  const {
    isModalOpen: isPairModalOpen,
    closeModal: closePairModal,
    openModal: openPairModal,
  } = useModalState()

  const handleClick = useCallback(() => {
    if (workstationId) {
      openUnpairModal()
    } else {
      openPairModal()
    }
  }, [openPairModal, openUnpairModal, workstationId])

  return (
    <>
      <Flex flexDirection="column" gap="16px" width="100%">
        <Flex justifyContent="space-between" alignItems="center">
          <Typography component="h3" variant="heading-xl">
            {t('title')}
          </Typography>
          {isLoading ? (
            <SkeletonBasic width="64px" height="12px" borderRadius="999px" />
          ) : (
            <ButtonLink onClick={handleClick}>
              {workstationId ? t('unpair') : t('pair')}
            </ButtonLink>
          )}
        </Flex>

        <DetailsField
          value={getNonNullString(workstationId, '-')}
          label={t('workstationId')}
          isLoading={isLoading}
        />
      </Flex>
      <PairModal
        deviceName={deviceName}
        deviceUuid={deviceUuid}
        isOpen={isPairModalOpen}
        onCancel={closePairModal}
      />
      <UnpairModal
        deviceName={deviceName}
        deviceUuid={deviceUuid}
        isOpen={isUnpairModalOpen}
        onCancel={closeUnpairModal}
      />
    </>
  )
}
