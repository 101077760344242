import { createTranslations } from '@npco/utils-translations'

export const cardHolderInfoBoxTranslations = createTranslations({
  verificationText: 'Cardholders must be verified existing Users.',
  creationText:
    'New Cardholders can be created in {usersText} under {settingsText}.',
  usersText: 'Users',
  settingsText: 'Settings',
  buttonLabel: 'Go to Users',
})
