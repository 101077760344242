import { rvLastSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { datePickerInitialState } from 'const/common'
import dayjs from 'utils/dayjs'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'

export const getThreeMonthPeriod = () => {
  return {
    from: dayjs().subtract(3, 'month').startOf('day').toDate(),
    to: dayjs().endOf('day').toDate(),
    enteredTo: dayjs().startOf('day').toDate(),
  }
}

export const getInitialDateTimeState = (
  key?: TIME_FILTER_ENABLED_COMPONENTS
) => {
  if (!key) {
    return datePickerInitialState
  }

  const storedDate = rvLastSelectedDates()[key]
  if (!storedDate?.from || !storedDate?.to) {
    return datePickerInitialState
  }

  return {
    from: dayjs(storedDate.from).toDate(),
    to: dayjs(storedDate.to).toDate(),
    enteredTo: dayjs(storedDate.from).toDate(),
  }
}
