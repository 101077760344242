import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useReactiveVar } from '@apollo/client'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Box, Flex, SvgIcon } from '@npco/zeller-design-system'
import { rvOnboardingMetadata } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'
import { DEFAULT_AFTER_LOGIN_ROUTE, ROOT } from 'const/routes'
import { BUTTON_SIZE } from 'types/common'
import { Button } from 'components/Buttons/Button'
import { page, shared } from 'translations'

import {
  StyledDescription,
  StyledLogoWrapper,
  StyledTitle,
  StyledWrapper,
} from './NotFound.styled'

export const NotFound = () => {
  const history = useHistory()

  const onboardingMetadata = useReactiveVar(rvOnboardingMetadata)

  const { token } = useZellerAuthenticationContext()

  const shortEntityId = useSelectedShortEntityUuid()

  const onClick = useCallback(() => {
    if (onboardingMetadata.isInProgress && onboardingMetadata.lastRoute) {
      history.push(onboardingMetadata.lastRoute)
    } else {
      const route = token ? DEFAULT_AFTER_LOGIN_ROUTE(shortEntityId) : ROOT.path

      history.push(route)
    }
  }, [
    history,
    onboardingMetadata.isInProgress,
    onboardingMetadata.lastRoute,
    shortEntityId,
    token,
  ])

  return (
    <StyledWrapper data-testid="not-found">
      <StyledLogoWrapper>
        <SvgIcon width="69" height="24">
          <LogoIcon data-testid="logo-zeller" />
        </SvgIcon>
      </StyledLogoWrapper>

      <Box width={['95%', '95%', '35.5rem']}>
        <StyledTitle>{page.notFound.title}</StyledTitle>
        <StyledDescription>{page.notFound.description}</StyledDescription>
        <Flex justifyContent="center">
          <Button size={BUTTON_SIZE.LARGE} type="button" onClick={onClick}>
            {onboardingMetadata.isInProgress
              ? shared.goBackToOnboarding
              : shared.goHome}
          </Button>
        </Flex>
      </Box>
    </StyledWrapper>
  )
}
