import { Box, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledListWrapper as InfiniteListWrapper } from 'components/Lists/InfiniteList/InfiniteList.styled'

interface GroupProps {
  isLastElement: boolean
}

export const Group = styled.div.attrs<GroupProps>(({ isLastElement }) => ({
  className: isLastElement ? 'isLastElement' : undefined,
}))<GroupProps>`
  padding: 0 0 24px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 512px;
  }

  &:not(:first-child) {
    padding-top: 32px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};
  }
`

export const LoaderWrapper = styled.div`
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 504px;
  }
`

export const Header = styled(Heading.H3)`
  text-align: left;
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0;
  word-break: break-all;
`

export const ListWrapper = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  height: 0;

  ${InfiniteListWrapper} {
    margin: 0 -8px;
    padding: 0 8px;

    @media screen and (min-width: ${BREAKPOINT.SM}px) {
      margin: 0 -40px;
      padding: 0 40px;
    }

    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      margin: 0 -32px;
      padding: 0 32px;
    }

    @media screen and (min-width: ${BREAKPOINT.LG}px) {
      margin: 0 -44px;
      padding: 0 44px;
    }

    @media screen and (min-width: ${BREAKPOINT.XL}px) {
      margin: 0 -68px;
      padding: 0 68px;
    }
  }
`
