import { useMemo, useState } from 'react'
import { TabItemProps } from '@npco/zeller-design-system'

import { useDrawerTabEvents } from 'services/Analytics/useDrawerTabEvents'
import { page } from 'translations'

export const TRANSACTION_TAB_TYPE = {
  DETAILS: 'details',
  CONTACT: 'contact',
}

const tabTypes = [TRANSACTION_TAB_TYPE.DETAILS, TRANSACTION_TAB_TYPE.CONTACT]

type Tabs = (typeof tabTypes)[number]

const tabNames: Record<Tabs, string> = {
  details: page.transactions.tabs.details,
  contact: page.transactions.tabs.contact,
}

interface UseTransactionsDrawerTabsProps {
  showContactTab: boolean
}

export const useTransactionsDrawerTabs = ({
  showContactTab,
}: UseTransactionsDrawerTabsProps) => {
  const { onDrawerTabSwitch } = useDrawerTabEvents({
    drawerName: 'Transaction',
  })
  const [selectedTab, setSelectedTab] = useState(TRANSACTION_TAB_TYPE.DETAILS)

  const tabs: TabItemProps[] = useMemo(() => {
    const createTabs = (tabIds: string[]) =>
      tabIds.map((tabId: string) => ({
        id: `transaction-tabs-${tabId}`,
        children: tabNames[tabId],
        value: tabId,
        onClick: () => {
          setSelectedTab(tabId)
          onDrawerTabSwitch(tabNames[tabId])
        },
      }))

    return createTabs(
      showContactTab ? tabTypes : [TRANSACTION_TAB_TYPE.DETAILS]
    )
  }, [onDrawerTabSwitch, showContactTab])

  return {
    selectedTab,
    setSelectedTab,
    tabs,
  }
}
