import { EntityCategories } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { useSubcategoryDisplay } from 'hooks/subcategories/useSubcategoryDisplay/useSubcategoryDisplay'
import { getCategoryDisplay } from 'components/EditZellerCategories/Fields/utils/categories.utils'

import { CategoryItemDisplay } from './CategoryItem/CategoryItemDisplay'
import { SubcategoryItemDisplay } from './CategoryItem/SubcategoryItemDisplay'
import { EditCategoryPills } from './EditCategoryPills'

interface CategoriesProps {
  category: EntityCategories | null
  subcategoryId: string | null
  contactUuid?: string
  canEditCategories?: boolean
}

export const CategoryPills = ({
  category,
  subcategoryId,
  contactUuid,
  canEditCategories = true,
}: CategoriesProps) => {
  const categoryDisplay = getCategoryDisplay(category)

  const { subcategory, isLoading, error } = useSubcategoryDisplay({
    category,
    subcategoryId,
  })

  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <Box mt="24px">
      <CategoryItemDisplay
        text={categoryDisplay?.text}
        icon={categoryDisplay?.icon}
        onEdit={openModal}
        canEditCategories={canEditCategories}
      />

      <SubcategoryItemDisplay
        isLoadingSubcategory={isLoading}
        hasError={Boolean(error)}
        subcategoryName={subcategory?.name}
        onEdit={openModal}
        canEditCategories={canEditCategories}
      />

      <EditCategoryPills
        isModalOpen={isModalOpen}
        category={category}
        subcategoryDetails={subcategory || null}
        closeModal={closeModal}
        contactUuid={contactUuid}
      />
    </Box>
  )
}
