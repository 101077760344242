import {
  EntityOnboardingDetailsInput,
  EntityType,
  OnboardingFlowType,
} from '@npco/mp-gql-types'

export type IndividualOwnerValues = {
  type: string
  firstName: string
  middleName: string
  lastName: string
  dob: string
  street: string
  suburb: string
  state: string
  postcode: string | null
  country: string
}

export type CompanyOwnerValues = {
  type: string
  companyTrustName: string
  abn: string
}

export type TrustOwnerValues = {
  type: string
  companyTrustName: string
  abn: string
}

export type IndividualPartnerValues = IndividualOwnerValues & {
  beneficialOwner?: boolean
}

export type InitialOwnerValues = {
  type: string
}

export type OwnerFieldsValues =
  | IndividualOwnerValues
  | CompanyOwnerValues
  | TrustOwnerValues
  | InitialOwnerValues
  | IndividualPartnerValues

export enum SKIP_REASON {
  TERMINAL_IN_PLACE = 'TERMINAL_IN_PLACE',
  TERMINAL_IN_PROGRESS = 'TERMINAL_IN_PROGRESS',
  TERMINAL_TO_BUY_LATER = 'TERMINAL_TO_BUY_LATER',
}

export type SkippedPurchaseData = {
  cardNumber?: string
  skipReason?: SKIP_REASON
  pin?: string
  confirmPin?: string
  accountId?: string
}

export type LinkCardData = {
  cardNumber: string
  accountId: string
  sessionId: string
  publicKeyCertificate: string
  otp: string
  phone: string
}

export interface OnboardingFormValues {
  abnOrAcn: string | undefined
}

export enum ACCOUNT_TYPE {
  CARD_PAYMENTS = 'CARD_PAYMENTS',
  TRANSACTIONS_ONLY = 'TRANSACTIONS_ONLY',
}

export enum PRODUCT_COLOR {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
}

export enum SettledSumValue {
  LessThan10k = 'LessThan10k',
  MoreOrEqual10k = 'MoreOrEqual10k',
}

export type NewCardData = {
  firstName?: string
  lastName?: string
  mobilePhone?: string
  email?: string
  address?: string
  state?: string
  postCode?: string
  city?: string
  isSameAsBusinessPlace?: boolean
  cardColor: PRODUCT_COLOR
}

export type TrusteeType = EntityType.COMPANY | EntityType.INDIVIDUAL

interface HelperFields {
  isNotRegulated?: boolean
  hasNoPlaceOfBusiness?: boolean
  hasNoTradingName?: boolean
  settledSum?: SettledSumValue
  trustee?: TrusteeType
}

export interface MembersFilters {
  director?: boolean
  beneficialOwner?: boolean
  settlor?: boolean
  trustee?: boolean
  beneficiary?: boolean
  partner?: boolean
  chair?: boolean
  secretary?: boolean
  treasurer?: boolean
}

export interface OnboardingMetadata {
  flowFlags?: string
  type?: OnboardingFlowType
  lastCheckpoint?: string
  lastRoute?: string
  lastSavedRoute?: string
  isAfterFullsearch?: boolean
  isFullSearchError?: boolean
  helperFields?: HelperFields
  membersFilters?: MembersFilters
  uploadedFileNames?: string[]
  isInProgress?: boolean
  governmentRole?: string
}

export interface OnboardingInitialSearchData {
  businessIdentifier?: string | null
  name?: string | null
  acn?: string | null
  abn?: string | null
  type?: EntityType | null
  found?: boolean | null
}

export type OnboardingDataSummary = Partial<EntityOnboardingDetailsInput>
