import { useQuery } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetEntityDailyLimitVariables } from 'types/gql-types/GetEntityDailyLimit'

import * as GetEntityDailyLimit from './GetEntityDailyLimit'

export const useGetEntityDailyLimit = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error } = useQuery<
    GetEntityDailyLimit.QueryData,
    GetEntityDailyLimitVariables
  >(GetEntityDailyLimit.query, { variables: { entityUuid } })

  // money value comes back with a string of number, including cent. e.g. '10000' = $100.00
  // use currencyFormatter
  const dailyMaximumLimit = parseInt(
    data?.getEntityDailyLimit.maximumLimit.value || '0',
    10
  )

  const dailyMaximumLimitFormatted = currencyFormatter(dailyMaximumLimit)

  return { dailyMaximumLimit, dailyMaximumLimitFormatted, loading, error }
}
