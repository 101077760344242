import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { rvSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'

import { removeWhiteSpaces } from 'utils/common'
import dayjs from 'utils/dayjs'
import { RangeModifierExt } from 'types/picker'

import { getCompleteTimeInput } from './useTimePickerState.util'

export const DEFAULT_FROM_TIME = '12:00 AM'
export const DEFAULT_TO_TIME = '11:59 PM'

export const DEFAULT_DISPLAY_FORMAT = 'hh:mm A'
export const DEFAULT_INPUT_FORMAT = 'hh:mmA'
export const ALLOWED_LOWERCASE_INPUT_FORMAT = 'hh:mma'
export const ALLOWED_24HR_INPUT_FORMAT = 'HH:mm'

interface TimePickerStateProps {
  setSelectedRange: Dispatch<SetStateAction<RangeModifierExt>>
}

export const useTimePickerState = ({
  setSelectedRange,
}: TimePickerStateProps) => {
  const [fromTime, setFromTime] = useState('')
  const [toTime, setToTime] = useState('')

  const [lastValidFromTime, setLastValidFromTime] = useState(DEFAULT_FROM_TIME)
  const [lastValidToTime, setLastValidToTime] = useState(DEFAULT_TO_TIME)

  const setDefaultTime = useCallback(() => {
    if (!fromTime) {
      setFromTime(DEFAULT_FROM_TIME)
    }

    if (!toTime) {
      setToTime(DEFAULT_TO_TIME)
    }
  }, [fromTime, toTime])

  const setTimes = useCallback((fromDate: Date, toDate: Date) => {
    const fromTimeInput = dayjs(fromDate).format(DEFAULT_DISPLAY_FORMAT)
    const toTimeInput = dayjs(toDate).format(DEFAULT_DISPLAY_FORMAT)

    setFromTime(fromTimeInput)
    setToTime(toTimeInput)
    setLastValidFromTime(fromTimeInput)
    setLastValidToTime(toTimeInput)
  }, [])

  const getValidTime = (time: string) => {
    const formatTimeInput = (input: string) => {
      const inputFormatted = removeWhiteSpaces(input)

      if (
        dayjs(inputFormatted, ALLOWED_LOWERCASE_INPUT_FORMAT, true).isValid()
      ) {
        return dayjs(inputFormatted, ALLOWED_LOWERCASE_INPUT_FORMAT).format(
          DEFAULT_DISPLAY_FORMAT
        )
      }

      if (dayjs(inputFormatted, ALLOWED_24HR_INPUT_FORMAT, true).isValid()) {
        return dayjs(inputFormatted, ALLOWED_24HR_INPUT_FORMAT).format(
          DEFAULT_DISPLAY_FORMAT
        )
      }

      if (dayjs(inputFormatted, DEFAULT_INPUT_FORMAT, true).isValid()) {
        return dayjs(inputFormatted, DEFAULT_INPUT_FORMAT).format(
          DEFAULT_DISPLAY_FORMAT
        )
      }

      return false
    }

    const formattedTime = formatTimeInput(time)
    return formattedTime || formatTimeInput(getCompleteTimeInput(time))
  }

  const isFromTimeEntryInvalidForCurrentToTime = (time: string) => {
    const selectedDates = rvSelectedDates()
    const newFromDate = dayjs(selectedDates.from)
      .hour(dayjs(time, DEFAULT_DISPLAY_FORMAT).hour())
      .minute(dayjs(time, DEFAULT_DISPLAY_FORMAT).minute())
      .toDate()
    return selectedDates.from && dayjs(newFromDate).isAfter(selectedDates.to)
  }

  const isToTimeEntryInvalidForCurrentFromTime = (time: string) => {
    const selectedDates = rvSelectedDates()
    const newToDate = dayjs(selectedDates.to)
      .hour(dayjs(time, DEFAULT_DISPLAY_FORMAT).hour())
      .minute(dayjs(time, DEFAULT_DISPLAY_FORMAT).minute())
      .toDate()
    return selectedDates.from && dayjs(selectedDates.from).isAfter(newToDate)
  }

  const onFromInputBlur = () => {
    const validFromTime = getValidTime(fromTime)

    if (
      !validFromTime ||
      (validFromTime && isFromTimeEntryInvalidForCurrentToTime(validFromTime))
    ) {
      setFromTime(fromTime ? lastValidFromTime : '')
      return
    }

    setLastValidFromTime(validFromTime)
    setSelectedRange((prev) => ({
      ...prev,
      from: dayjs(prev.from)
        .hour(dayjs(validFromTime, DEFAULT_DISPLAY_FORMAT).hour())
        .minute(dayjs(validFromTime, DEFAULT_DISPLAY_FORMAT).minute())
        .toDate(),
    }))
    rvDeferredFilterPayload({ ...rvDeferredFilterPayload(), Time: true })
  }

  const onToInputBlur = () => {
    const validToTime = getValidTime(toTime)

    if (
      !validToTime ||
      (validToTime && isToTimeEntryInvalidForCurrentFromTime(validToTime))
    ) {
      setToTime(toTime ? lastValidToTime : '')
      return
    }

    setLastValidToTime(validToTime)
    setSelectedRange((prev) => ({
      ...prev,
      to: dayjs(prev.to)
        .hour(dayjs(validToTime, DEFAULT_DISPLAY_FORMAT).hour())
        .minute(dayjs(validToTime, DEFAULT_DISPLAY_FORMAT).minute())
        .toDate(),
    }))
    rvDeferredFilterPayload({ ...rvDeferredFilterPayload(), Time: true })
  }

  return {
    fromTime,
    toTime,
    lastValidFromTime,
    lastValidToTime,
    getValidTime,
    onFromTimeChange: setFromTime,
    onToTimeChange: setToTime,
    onFromInputBlur,
    onToInputBlur,
    setDefaultTime,
    setTimes,
  }
}
