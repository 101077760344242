import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  BREAKPOINT,
  ButtonFillIconLeft,
  COLOR,
  PlusIcon,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { AddUserButtonMobile } from './AddUserButton.styled'

export const AddUserButton = () => {
  const navigate = useNavigate()
  const isTabletResolution = useIsMobileResolution(BREAKPOINT.MD)
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <>
      {isTabletResolution ? (
        <AddUserButtonMobile
          dataTestId="cards-add-button"
          onClick={() =>
            navigate(
              ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.USER_CREATE_TYPE.path
            )
          }
        >
          <PlusIcon color={COLOR.WHITE} />
        </AddUserButtonMobile>
      ) : (
        <ButtonFillIconLeft
          dataTestId="add-user-button"
          icon={Plus}
          iconEnabledColor={COLOR.WHITE}
          onClick={() =>
            navigate(
              ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.USER_CREATE_TYPE.path
            )
          }
        >
          {translate('page.settings.users.user')}
        </ButtonFillIconLeft>
      )}
    </>
  )
}
