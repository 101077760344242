import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { getContactName } from 'features/Contacts'

import { formatAccountNumber, formatBsb } from 'utils/common'

import {
  AvatarWithTextRow,
  DefaultRow,
  SectionHeading,
  TransferModal,
} from '../../../../TransferModal'
import { ToContact, ToPaymentInstrument } from '../../../ContactTransfer.types'
import { contactTransferModalTranslations } from '../../ContactTransferModal.i18n'

type ToSectionProps = {
  toContact: ToContact
  toPaymentInstrument: ToPaymentInstrument
  fallbackToDe?: boolean
}

export const ToSection = ({
  toContact,
  toPaymentInstrument,
  fallbackToDe = false,
}: ToSectionProps) => {
  const t = useTranslations(contactTransferModalTranslations)

  const toAccountIcon = toContact.icon
    ? { ...toContact.icon, image: null, animation: null }
    : null

  return (
    <TransferModal.SectionWrapper aria-labelledby="to-section-header-id">
      <SectionHeading id="to-section-header-id">{t('to')}</SectionHeading>
      <AvatarWithTextRow
        avatar={{
          icon: toAccountIcon,
          defaultLetter: getContactName(toContact) ?? '-',
        }}
        text={getContactName(toContact) ?? '-'}
      />
      <DefaultRow
        label={t('accountName')}
        value={toPaymentInstrument.bankAccountDetails?.name ?? '-'}
      />
      <DefaultRow
        label={t('bsb')}
        value={
          toPaymentInstrument.bankAccountDetails?.bsb
            ? formatBsb(toPaymentInstrument.bankAccountDetails.bsb)
            : '-'
        }
      />
      <DefaultRow
        label={t('accountNumber')}
        value={
          toPaymentInstrument.bankAccountDetails?.account
            ? formatAccountNumber(
                toPaymentInstrument.bankAccountDetails.account
              )
            : '-'
        }
      />
      {fallbackToDe && (
        <Box pt="8px">
          <InfoBox
            priority={INFOBOX_PRIORITY.MEDIUM}
            variant={INFOBOX_VARIANT.WARNING}
            size={INFOBOX_SIZE.SMALL}
            dataTestId="de-fallback-message"
          >
            <InfoBox.Header>{t('deFallbackMessageHeader')}</InfoBox.Header>
            <InfoBox.Message>{t('deFallbackMessage')}</InfoBox.Message>
          </InfoBox>
        </Box>
      )}
    </TransferModal.SectionWrapper>
  )
}
