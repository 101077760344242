import { DepositExportFormat } from '@npco/mp-gql-types'

import { useExportDepositsModal } from 'pages/Deposits/ExportDepositsModal/hooks/useExportDepositsModal'
import { ExportFileModalContent } from 'components/ExportFileModalContent/ExportFileModalContent'

interface Props {
  closeModal: () => void
  filename: string
  format: DepositExportFormat
}

export const ExportDepositsModal = ({
  closeModal,
  filename,
  format,
}: Props) => {
  const { exportFile, isLoading, hasError, hasLoaded } = useExportDepositsModal(
    { closeModal, filename, format }
  )

  return (
    <ExportFileModalContent
      closeModal={closeModal}
      rerenderContent={exportFile}
      isLoading={isLoading}
      hasError={hasError}
      hasLoaded={hasLoaded}
    />
  )
}
