import { COLOR } from '@npco/zeller-design-system'
import { MultiSelectItemBasic } from 'design-system/Components/MultiSelect'
import { sample } from 'lodash-es'

import { translate } from 'utils/translations'

export const CATEGORY_NAME_MAX_CHARACTERS = 256

export const ITEM_CATEGORY_COLOURS = [
  COLOR.POS_DARK_BLUE,
  COLOR.POS_MID_BLUE,
  COLOR.POS_LIGHT_BLUE,
  COLOR.POS_DARK_GREEN,
  COLOR.POS_MID_GREEN,
  COLOR.POS_LIGHT_GREEN,
  COLOR.POS_DARK_ORANGE,
  COLOR.POS_MID_ORANGE,
  COLOR.POS_LIGHT_ORANGE,
]

export const getTriggerContent = (items: MultiSelectItemBasic[]) => {
  if (items.length === 0) {
    return null
  }

  if (items.length === 1) {
    const { label } = items[0]
    return label
  }

  return `${items.length} ${translate('shared.categories')}`
}

export const getRandomItemColour = () => {
  // NOTE: sample can return null/undefined so providing fallback
  return sample(ITEM_CATEGORY_COLOURS) ?? ITEM_CATEGORY_COLOURS[0]
}
