import { GetTransaction_getTransaction_refundTransactions as RefundTransaction } from 'types/gql-types/GetTransaction'

export const sortRefundTransactionsByTime = (
  refundTransactions: RefundTransaction[]
) => {
  refundTransactions.sort(
    (current: RefundTransaction, next: RefundTransaction) => {
      return Date.parse(current.timestamp) - Date.parse(next.timestamp)
    }
  )
}
