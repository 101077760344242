import { useMemo } from 'react'

import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

import { Address } from '../../TransactionContact.types'
import { formatAddress } from '../../utils/address.utils'

interface Props {
  address: Address | null | undefined
}

export const AddressItem = ({ address }: Props) => {
  const formattedAddress = useMemo(
    () => (address ? formatAddress(address) : ''),
    [address]
  )

  return (
    <DrawerItem
      label={translate('page.transactionContact.address')}
      value={formattedAddress || '-'}
    />
  )
}
