import React, { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ErrorMessageForm,
  Flex,
  INPUT_SIZE,
  InputAdaptive,
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { usePairOraclePos } from 'hooks/oraclePos/usePairOraclePos'
import { useValidation } from 'hooks/oraclePos/useValidation'
import { useInputHandlers } from 'hooks/useInputHandlers'
import { OverrideOraclePosPairModal } from 'components/OverrideOraclePosPairModal/OverrideOraclePosPairModal'

import { translations } from './PairModal.i18n'

type PairModalProps = {
  deviceName: string
  deviceUuid: string
  isOpen: boolean
  onCancel: () => void
}

export const PairModal = ({
  deviceName,
  deviceUuid,
  isOpen,
  onCancel,
}: PairModalProps) => {
  const t = useTranslations(translations)
  const [newWorkstationId, setNewWorkstationId] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const { handleIntegerInputKeydown } = useInputHandlers()
  const { validateWorkstationId } = useValidation()

  const clearAndCloseModal = useCallback(() => {
    setNewWorkstationId('')
    setError(undefined)
    onCancel()
  }, [onCancel])

  const { pairOraclePos, isPairingOraclePos } = usePairOraclePos({
    deviceName,
    deviceUuid,
    onError: () => showErrorToast(t('errorToast')),
    onSuccess: () => {
      showSuccessToast(t('successToast'))
      clearAndCloseModal()
    },
    workstationId: newWorkstationId,
  })

  const onPair = useCallback(async () => {
    const validationResult = validateWorkstationId(newWorkstationId)
    if (validationResult) {
      setError(validationResult)
      return
    }

    await pairOraclePos()
  }, [newWorkstationId, pairOraclePos, validateWorkstationId])

  return (
    <>
      <ModalForm
        title={t('title')}
        onClickPrimary={onPair}
        primaryButtonLabel={t('primaryButtonLabel')}
        secondaryButtonLabel={t('secondaryButtonLabel')}
        isOpen={isOpen}
        onCancel={clearAndCloseModal}
        isLoading={isPairingOraclePos}
      >
        <Flex flexDirection="column" marginTop="6px">
          <InputAdaptive
            name="workstationId"
            size={INPUT_SIZE.MEDIUM}
            onKeyDown={handleIntegerInputKeydown}
            label={t('workstationIdLabel')}
            value={newWorkstationId}
            onChange={(e) => {
              setError(validateWorkstationId(e.target.value))
              setNewWorkstationId(e.target.value)
            }}
            hasError={!!error}
          />
          <ErrorMessageForm errorMessage={error} hasError={!!error} />
        </Flex>
      </ModalForm>
      <OverrideOraclePosPairModal
        onSuccess={() => {
          clearAndCloseModal()
        }}
      />
    </>
  )
}
