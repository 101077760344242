import React from 'react'
import { AnchorBasic, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as MailIcon } from 'assets/svg/envelope.svg'
import { translate } from 'utils/translations'
import { shared } from 'translations'

import { EmailInfoContainer } from '../../Help.styled'

export const EmailInfo = () => {
  return (
    <EmailInfoContainer>
      <SvgIcon width="16" height="16">
        <MailIcon />
      </SvgIcon>
      <AnchorBasic
        href={`mailto:${shared.contactSupport.supportEmail}`}
        className="support-email"
      >
        {translate('shared.contactSupport.supportEmail')}
      </AnchorBasic>
    </EmailInfoContainer>
  )
}
