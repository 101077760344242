import {
  DebitCardTransactionStatusV2,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import { DebitCardTransactionAmountUpdate_onDebitCardTransactionUpdate as AmountUpdate } from 'types/gql-types/DebitCardTransactionAmountUpdate'

const OUTGOING_EXTERNAL_TRANSFERS = [
  DebitCardTransactionTypeV2.ATM_OUT,
  DebitCardTransactionTypeV2.BPAY_OUT,
  DebitCardTransactionTypeV2.DE_OUT,
  DebitCardTransactionTypeV2.NPP_OUT,
  DebitCardTransactionTypeV2.PURCHASE,
  DebitCardTransactionTypeV2.PURCHASE_CNP,
  DebitCardTransactionTypeV2.WITHDRAWAL,
]

const INCOMING_EXTERNAL_TRANSFERS = [
  DebitCardTransactionTypeV2.ATM_IN,
  DebitCardTransactionTypeV2.BPAY_IN,
  DebitCardTransactionTypeV2.DE_IN,
  DebitCardTransactionTypeV2.NPP_IN,
  DebitCardTransactionTypeV2.REFUND,
  DebitCardTransactionTypeV2.REFUND_CNP,
]

export const getIsOutgoing = (subscriptionUpdate: AmountUpdate) =>
  OUTGOING_EXTERNAL_TRANSFERS.includes(subscriptionUpdate.type)

export const getIsIncoming = (subscriptionUpdate: AmountUpdate) =>
  INCOMING_EXTERNAL_TRANSFERS.includes(subscriptionUpdate.type)

export const getIsApproved = (subscriptionUpdate: AmountUpdate) =>
  subscriptionUpdate.status === DebitCardTransactionStatusV2.APPROVED
