import { Box, SkeletonH5 } from '@npco/zeller-design-system'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { useCustomerItemPermissions } from 'hooks/useGetCustomerItemPermissions/useCustomerItemPermissions'
import { getDevicesText } from 'utils/devices/devices.utils'
import { getNonNullString } from 'utils/string'
import { translate } from 'utils/translations'
import { GetCustomerBasicInfo_getCustomer as Customer } from 'types/gql-types/GetCustomerBasicInfo'
import { Card } from 'components/Cards/Card'
import { DetailsField } from 'components/DetailsField/DetailsField'
import { UserInvitationPendingNotification } from 'components/UserInvitationPendingNotification/UserInvitationPendingNotification'
import { page } from 'translations'

import { AssignedCardsSection } from './AssignedCardsSection/AssignedCardsSection'
import { DetailsEmailValue } from './DetailsEmailValue/DetailsEmailValue'
import { useUserDetails } from './hooks/useUserDetails'
import { PermissionSection } from './PermissionSection/PermissionSection'
import {
  StyledDescription,
  StyledDetailsLabel,
  StyledDetailsTitle,
  StyledWrapper,
} from './UserDetails.styled'
import { XeroPaymentServicesSection } from './XeroPaymentServicesSection/XeroPaymentServicesSection'

interface UserDetailsProps {
  selectedUser: Customer
}

const { firstName, lastName, mobilePhone, email } = page.settings.users

export const UserDetails = ({ selectedUser }: UserDetailsProps) => {
  const {
    idVerificationStatus,
    isAdmin,
    isInvitationPending,
    userSites,
    userDetails,
    isLoadingUserDetails,
  } = useUserDetails(selectedUser)
  const isInvoicingEnabled = useIsInvoicingEnabled()
  const {
    loading: isLoadingLoggedInUserPermissions,
    allowDiscountManagement,
    allowItemManagement,
  } = useCustomerItemPermissions()

  return (
    <Box data-testid="user-details">
      {isInvitationPending && (
        <Box mb="24px">
          <UserInvitationPendingNotification userId={selectedUser.id} />
        </Box>
      )}
      <Box pb="16px">
        <StyledDetailsTitle>
          {page.settings.users.personalInformation}
        </StyledDetailsTitle>
      </Box>
      <DetailsField label={firstName} value={selectedUser.firstname} />
      <DetailsField label={lastName} value={selectedUser.lastname} />
      <DetailsField
        label={mobilePhone}
        value={getNonNullString(userDetails?.phone, '-')}
        isLoading={isLoadingUserDetails}
      />
      <StyledWrapper>
        <StyledDetailsLabel>{email}</StyledDetailsLabel>
        {isLoadingUserDetails ? (
          <SkeletonH5 width="118px" margin="0" />
        ) : (
          <DetailsEmailValue email={getNonNullString(userDetails?.email)} />
        )}
      </StyledWrapper>
      <DetailsField
        label={translate('page.settings.users.idVerificationStatus')}
        value={idVerificationStatus}
        isLoading={isLoadingUserDetails}
      />
      {isInvoicingEnabled && (
        <PermissionSection
          isEnabled={isAdmin || !!userDetails?.permissions?.allowZellerInvoices}
          title={translate('page.settings.users.invoices.drawerTitle')}
          description={
            isAdmin
              ? translate('page.settings.users.invoices.descriptionAdmin')
              : translate('page.settings.users.invoices.descriptionManager')
          }
          isLoading={isLoadingUserDetails}
        />
      )}
      {allowItemManagement && (
        <PermissionSection
          isEnabled={isAdmin || !!userDetails?.permissions?.allowItemManagement}
          title={translate('page.settings.users.itemManagement.title')}
          description={
            isAdmin
              ? translate('page.settings.users.itemManagement.descriptionAdmin')
              : translate(
                  'page.settings.users.itemManagement.descriptionManager'
                )
          }
          isLoading={isLoadingUserDetails || isLoadingLoggedInUserPermissions}
        />
      )}
      {allowDiscountManagement && (
        <PermissionSection
          isEnabled={
            isAdmin || !!userDetails?.permissions?.allowDiscountManagement
          }
          title={translate('page.settings.users.discount.title')}
          description={
            isAdmin
              ? translate('page.settings.users.discount.descriptionAdmin')
              : translate('page.settings.users.discount.descriptionManager')
          }
          isLoading={isLoadingUserDetails || isLoadingLoggedInUserPermissions}
        />
      )}
      {isAdmin && <XeroPaymentServicesSection />}
      <Box py="16px">
        <StyledDetailsTitle>
          {page.settings.users.assignedSites.title}
        </StyledDetailsTitle>
      </Box>
      <StyledDescription>
        {isAdmin
          ? page.settings.users.assignedSites.description
          : page.settings.users.assignSites.description}
      </StyledDescription>
      <Box pt="24px">
        {userSites?.map((site) => (
          <Card
            key={site?.name}
            text={site?.name}
            textSmall={getDevicesText(site?.devices?.length)}
            isClickable={false}
            isSmall
          />
        ))}
      </Box>
      <AssignedCardsSection
        selectedUser={selectedUser}
        isAdmin={isAdmin}
        isInvitationPending={!!isInvitationPending}
        idVerificationStatus={idVerificationStatus}
        isLoadingUserDetails={isLoadingUserDetails}
      />
    </Box>
  )
}
