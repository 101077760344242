import { RefObject } from 'react'
import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  Observable,
  Operation,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ServerParseError } from '@apollo/client/link/http'
import { ErrorLogger } from '@npco/utils-error-logger'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '@npco/utils-session-storage'
import { v4 as randomUUID } from 'uuid'

import { ErrorType, ServerError } from 'types/errors'
import { GetSubscriptionUrl_getSubscriptionUrl as SubscriptionUrlType } from 'types/gql-types/GetSubscriptionUrl'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'

export const ZELLER_SESSION_ID = 'zeller-session-id'

export const setHeaders = (
  headers: Record<string, any>,
  token: string | null
) => ({
  headers: {
    ...headers,
    authorization: token,
  },
  region: 'ap-southeast-2',
  auth: {
    type: 'OPENID_CONNECT',
    jwtToken: async () => token,
  },
})

export const getZellerSessionId = () => {
  const zellerSessionId = getSessionStorageItem(
    SESSION_STORAGE_KEYS.ZELLER_SESSION_ID
  )

  if (!zellerSessionId) {
    const newZellerSessionId = randomUUID()

    setSessionStorageItem(
      SESSION_STORAGE_KEYS.ZELLER_SESSION_ID,
      newZellerSessionId
    )
    return newZellerSessionId
  }

  return zellerSessionId
}

export const isReportingErrorType = (type: string | undefined) =>
  type &&
  (type === ErrorType.INVALID_REQUEST || type === ErrorType.SERVER_ERROR)

export const isUnauthorized = (
  error: ServerError[],
  networkError: any
): boolean =>
  Boolean(
    (error && error[0].errorType === ErrorType.UNAUTHORIZED_EXCEPTION) ||
      (networkError && networkError?.statusCode === 401) ||
      (networkError?.errors &&
        networkError?.errors[0]?.message.includes('UnauthorizedException'))
  )

export const handleNetworkError = (
  networkError: Error | ServerError | ServerParseError,
  operationName: string
) => {
  if (!('statusCode' in networkError)) {
    return
  }

  ErrorLogger.report('[Core] Network Error', {
    statusCode: networkError.statusCode,
    errorMsg: networkError.message,
    operationName,
  })
}

export const shouldSetupClientWithSubscription = (
  subscriptionUrl: SubscriptionUrlType | null,
  isInitialClientSetup: boolean,
  clientRef: RefObject<ApolloClient<any> | null>
) => Boolean(subscriptionUrl && isInitialClientSetup && clientRef.current)

export const setZellerSessionIdCustomHeader = new ApolloLink(
  (operation, forward) => {
    const zellerSessionId = getZellerSessionId()

    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...{ [ZELLER_SESSION_ID]: zellerSessionId },
        },
      }
    })
    return forward(operation)
  }
)

export const getAuthLink = (token: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      return setHeaders(headers, token)
    })

    return forward(operation)
  })

export const getErrorLink = (
  getNewToken: () => Promise<void>,
  logout: () => void,
  token: string,
  setIsSubscriptionSetup: (bool: boolean) => void
) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    const { operationName } = operation
    const graphQLServerErrors = graphQLErrors as ServerError[]
    const isUnauthorizedError = isUnauthorized(
      graphQLServerErrors,
      networkError
    )

    if (
      !isUnauthorizedError &&
      graphQLServerErrors &&
      graphQLServerErrors.length > 0
    ) {
      graphQLServerErrors.forEach((graphQLError) => {
        if (
          graphQLError.message ===
          `Variable 'entityUuid' has coerced Null value for NonNull type 'ID!'`
        ) {
          ErrorLogger.report(
            `[Core] GraphQLNullEntityUuidError: ${operationName}`,
            {
              message: graphQLError.message,
              operationName,
            }
          )
          return
        }
        if (isReportingErrorType(graphQLError.errorType)) {
          ErrorLogger.report(`[Core] GraphQLServerError: ${operationName}`, {
            errorInfo: graphQLError.errorInfo,
            errorType: graphQLError.errorType,
            operationName,
          })
        }
      })
    }

    if (!isUnauthorizedError && networkError) {
      handleNetworkError(networkError, operationName)
    }

    if (isUnauthorizedError) {
      // terminate any open websocket as the token in it will be expired -> this triggers the skip flag
      setIsSubscriptionSetup(false)

      // links operate on observables
      return new Observable<FetchResult>((observer) => {
        // get new token via fetch to auth0
        getNewToken()
          .then(() => {
            // remap authLink to use new token in headers
            operation.setContext(({ headers = {} }) =>
              setHeaders(headers, token)
            )
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            }

            // Retry last failed request - has to be remapped to follow the Observable<FetchResult> requirement
            forward(operation).subscribe(subscriber)
          })
          .catch((error: any) => {
            // No refresh or client token available, we force user to login
            observer.error(error)
            logout()
          })
      })
    }

    return undefined
  })

const omitTypename = (key: string, value: any) =>
  key === '__typename' ? undefined : value

export const removeTypenameLink = new ApolloLink((operation, forward) => {
  const newOperation: Operation = operation

  if (newOperation.variables) {
    newOperation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }

  return forward(newOperation)
})
