import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment = {
  __typename?: 'CashFlowSubcategoryNetAmount'
  category: Types.EntityCategories | null
  noOfTransaction: number
  total: { __typename?: 'Money'; value: string; currency: string }
  average: { __typename?: 'Money'; value: string; currency: string }
  subcategoryDetails: {
    __typename?: 'Subcategory'
    id: string
    name: string
    predefined: boolean
  } | null
}

export const groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragmentDoc =
  gql`
    fragment groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      category
      noOfTransaction
      total {
        value
        currency
      }
      average {
        value
        currency
      }
      subcategoryDetails {
        id
        name
        predefined
      }
    }
  ` as unknown as TypedDocumentNode<
    groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment,
    undefined
  >
