import { Flex } from '@npco/zeller-design-system'

import { CardholderRadioOptionSkeleton } from './skeletons/CardholderRadioOptionSkeleton'

export const CardholderLoadingPage = () => {
  return (
    <Flex flexDirection="column" overflow="hidden">
      <CardholderRadioOptionSkeleton
        titleLength="153px"
        subtitleLength="345px"
      />
      <CardholderRadioOptionSkeleton
        titleLength="205px"
        subtitleLength="111px"
      />
      <CardholderRadioOptionSkeleton
        titleLength="130px"
        subtitleLength="196px"
      />
      <CardholderRadioOptionSkeleton
        titleLength="153px"
        subtitleLength="345px"
      />
    </Flex>
  )
}
