import { Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { ShowMore } from 'components/ShowMore/ShowMore'

export const ShowMoreButton = () => {
  return (
    <Flex justifyContent="center" mt="24px">
      <ShowMore.Toggle
        showText={translate('component.showMore.showDetails')}
        hideText={translate('component.showMore.hideDetails')}
      />
    </Flex>
  )
}
