import { createTranslations } from '@npco/utils-translations'

export const creditCardPaymentFormTranslation = createTranslations({
  formTitle: 'Credit or Debit Card',
  nameOnCardLabel: 'Name on Card',
  creditCardNumberPlaceholder: '0000 0000 0000 0000',
  expirationDateLabel: 'Expiry',
  expirationDatePlaceholder: 'MM/YY',
  cvvLabel: 'CVV',
  payNowButtonLabel: 'Pay Now',

  cardNameRequired: 'Name on Card is required.',
  cardNumberRequired: 'Card Number is required.',
  expiryRequired: 'Expiry is required.',
  cvvRequired: 'CVV is required.',
  invalidCardName: 'Please enter a valid name.',
  invalidCreditCardNumber: 'Please enter a valid credit card number.',
  invalidCardExpiryDate: 'Please enter a valid expiry date.',
  invalidCVV: 'Please enter a valid CVV.',
})
