import { Box, InputAdaptiveFieldWrapper } from '@npco/zeller-design-system'
import { Form, useFormikContext } from 'formik'

import {
  combineValidators,
  validateMaxLength,
  validateRequired,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import {
  FileListItemSelect,
  FileListSelectItem,
} from 'components/File/File.types'
import { handleOnboardingOnDropReject } from 'components/File/File.utils'
import { FileFailedError } from 'components/File/FileFailedError/FileFailedError'
import { FileUploaderGroup } from 'components/File/FileUploaderGroup/FileUploaderGroup'
import { Values } from 'components/UploadDocuments/Form/Form.types'

import { UploadDocuments } from '../UploadDocuments.types'
import { ConsentToViewField } from './ConsentToViewField/ConsentToViewField'

export interface UploadDocumentsFormProps extends UploadDocuments {
  onHandleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  optionItems?: FileListItemSelect['optionItems']
  defaultOption?: FileListSelectItem
  hasSubject?: boolean
}

const MAX_FILES_QUANTITY = 5

export const UploadDocumentsForm = ({
  addFilesToQueue,
  failedFileNames,
  fileQueueItems,
  onHandleSubmit,
  optionItems,
  defaultOption,
  removeFileFromQueue,
  succeededFileQueueItems,
  hasSubject = true,
}: UploadDocumentsFormProps) => {
  const { setFieldValue } = useFormikContext<Values>()

  return (
    <Form onSubmit={onHandleSubmit}>
      <div data-testid="uploader-group">
        <Box mb="2.5rem">
          {hasSubject && (
            <InputAdaptiveFieldWrapper
              label={translate('shared.subject')}
              name="subject"
              validate={combineValidators(
                validateRequired,
                validateMaxLength(300)
              )}
            />
          )}
        </Box>

        <FileFailedError failedFileNames={failedFileNames} />
        <FileUploaderGroup
          maxFiles={MAX_FILES_QUANTITY}
          succeededFileQueueItems={succeededFileQueueItems}
          fileQueueItems={fileQueueItems}
          addFilesToQueue={addFilesToQueue}
          removeFileFromQueue={(fileId: string) => {
            removeFileFromQueue(fileId)

            if (optionItems) {
              setFieldValue(`fileItem.${fileId}`, undefined, false)
            }
          }}
          onDropRejected={(errors) =>
            handleOnboardingOnDropReject(errors, MAX_FILES_QUANTITY)
          }
          optionItems={optionItems}
          defaultOption={defaultOption}
        />
        <ConsentToViewField />
      </div>
    </Form>
  )
}
