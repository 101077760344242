import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { sumBy } from 'lodash-es'

import { useGetTotalCashFlowSubcategoryNetAmountFragment } from './useGetTotal.generated'

export const useGetTotal = (
  categoriesNetAmounts: useGetTotalCashFlowSubcategoryNetAmountFragment[]
) => {
  const total = useMemo(() => {
    return sumBy(categoriesNetAmounts, (category) =>
      parseInt(category.total.value, 10)
    )
  }, [categoriesNetAmounts])

  return total
}

useGetTotal.fragment = {
  CashFlowSubcategoryNetAmount: gql`
    fragment useGetTotalCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      total {
        value
      }
    }
  `,
}
