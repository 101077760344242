import { translate } from 'utils/translations'

import { ErrorPage } from '../ErrorPage/ErrorPage'
import { PlaceholderAlignment, PlaceholderSize } from '../Placeholders.types'
import { TryAgainButton } from './TryAgainButton'

interface Props {
  retry?: () => void
  className?: string
  variant?: PlaceholderSize
  alignment?: PlaceholderAlignment
  ['data-testid']?: string
}

export const GenericErrorPage = ({
  retry,
  className,
  variant = PlaceholderSize.Medium,
  alignment = PlaceholderAlignment.Center,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <ErrorPage
      data-testid={dataTestId}
      title={translate('component.genericError.title')}
      description={[
        translate('component.genericError.description1'),
        '',
        translate('component.genericError.description2'),
      ]}
      variant={variant}
      alignment={alignment}
      className={className}
    >
      {retry && <TryAgainButton retry={retry} />}
    </ErrorPage>
  )
}
