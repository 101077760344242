import { BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledTooltipContainer = styled.div`
  padding: 0.5rem;
  max-width: 360px;

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    max-width: 100%;
  }
`

export const StyledTooltipText = styled(BodySmall)`
  margin: 0;
  margin-bottom: 0.5rem;
`

export const StyledTooltipList = styled.ul`
  margin: 0;
  padding-left: 1rem;
`

export const StyledTooltipListItem = styled.li`
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const StyledLabelText = styled.span<{ isDisabled?: boolean }>`
  white-space: nowrap;
  ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.GREY_400}`};
`
