import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledWrapper = styled.div`
  min-height: 0;
  width: 100%;
  box-sizing: border-box;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT.MD}px) {
    height: 100%;
    width: 22.875rem;
    border: none;
    padding-top: 0;
    padding-left: 1.5rem;
  }

  @media (min-width: ${BREAKPOINT.LG}px) {
    width: 20.5rem;
    padding-left: 1.5rem;
  }
`

export const StyledNoTransactionsWrapper = styled.div`
  padding-top: 1rem;
`
