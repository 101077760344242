import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { translations } from './DateRangeLimitInfoBox.i18n'

export const DateRangeLimitInfoBox = () => {
  const t = useTranslations(translations)

  return (
    <Box margin="0 32px 32px 0">
      <InfoBox
        variant={INFOBOX_VARIANT.POSITIVE}
        priority={INFOBOX_PRIORITY.MEDIUM}
        size={INFOBOX_SIZE.DEFAULT}
      >
        <InfoBox.Message>{t('rangeLimit')}</InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
