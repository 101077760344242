import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ensureIsArray } from 'utils/common'

import { PlaceholderPage } from '../PlaceholderPage/PlaceholderPage'
import { PlaceholderSize } from '../Placeholders.types'
import {
  getDescriptionBasedOnVariant,
  getTitleBasedOnVariant,
} from '../Placeholders.utils'
import { StyledIconBox, StyledTextContainer } from '../SharedElements.styled'

interface Props {
  title?: string
  description: string | string[]
  icon: React.ReactNode
  children?: React.ReactNode
  variant?: PlaceholderSize
}

export const NoInitialResults = ({
  title,
  description,
  icon,
  children,
  variant = PlaceholderSize.Large,
}: Props) => {
  const StyledTitleVariant = getTitleBasedOnVariant(variant)
  const StyledDescriptionVariant = getDescriptionBasedOnVariant(variant)

  const stackedDescriptions = ensureIsArray(description)

  return (
    <PlaceholderPage>
      <StyledIconBox
        alignItems="center"
        justifyContent="center"
        variant={variant}
      >
        <SvgIcon width="22" height="22" color={COLOR.BLUE_1000}>
          {icon}
        </SvgIcon>
      </StyledIconBox>
      <StyledTextContainer>
        {title && <StyledTitleVariant>{title}</StyledTitleVariant>}
        {stackedDescriptions.map((desc) => (
          <StyledDescriptionVariant key={desc}>{desc}</StyledDescriptionVariant>
        ))}
      </StyledTextContainer>
      {children}
    </PlaceholderPage>
  )
}
