import { useCallback, useMemo } from 'react'
import { NetworkStatus, WatchQueryFetchPolicy } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { isNotNull } from 'utils/common'

import { useGetDebitCardAccountsForTransferQuery } from '../graphql/getDebitCardAccountsQuery.generated'

interface UseTransferQueryProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useTransferQuery = ({
  fetchPolicy = 'cache-first',
}: UseTransferQueryProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const {
    loading: isLoadingAccounts,
    error,
    data,
    networkStatus,
    refetch: refetchAccounts,
  } = useGetDebitCardAccountsForTransferQuery({
    fetchPolicy,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: { entityUuid },
    onError: (err) => {
      showApiErrorToast(err)
    },
  })

  const debitCardAccounts = useMemo(() => {
    return (
      data?.getDebitCardAccountsV2.accounts
        .filter(isNotNull)
        .filter((account) => account.type === DebitCardAccountType.ZLR_DEBIT)
        .filter(
          (account) => account.status === DebitCardAccountStatus.ACTIVE
        ) ?? []
    )
  }, [data?.getDebitCardAccountsV2.accounts])

  const savingsAccounts = useMemo(() => {
    return (
      data?.getSavingsAccounts
        .filter(isNotNull)
        ?.filter(
          (account) => account.status === DebitCardAccountStatus.ACTIVE
        ) ?? []
    )
  }, [data?.getSavingsAccounts])

  const allAccounts = useMemo(() => {
    return [...savingsAccounts, ...debitCardAccounts]
  }, [debitCardAccounts, savingsAccounts])

  const accountById = useCallback(
    (id: string | undefined | null) =>
      id ? allAccounts.find((account) => account?.id === id) : undefined,
    [allAccounts]
  )

  return {
    isLoadingAccounts,
    isRefetchingAccounts: networkStatus === NetworkStatus.refetch,
    error,
    allAccounts,
    debitCardAccounts,
    savingsAccounts,
    entity: data?.getEntity,
    refetchAccounts,
    accountById,
  }
}
