import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  updateAllSuccessToast: 'Settings updated for all devices',
  updateSuccessToast: 'Settings updated for this device',
  errorToast: 'Could not update settings, please try again.',
  deivceHibernate: 'Device Hibernate',
  deviceHibernateDescription:
    'Select how long you want your device to wait before device automatically turns off',
  neverHibernate: 'Never when plugged in',
  screenBrightness: 'Screen Brightness',
  screenBrightnessDescription: 'Adjust the brightness of your Terminal screen',
  sleepMode: 'Sleep Mode',
  sleepModeDescription:
    'Select how long you want your device to wait before going to sleep when you’re not using it',
  darkMode: 'Dark Mode',
})
