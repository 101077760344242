import { useMemo } from 'react'

import { useModalCloseTransition } from 'pages/GlobalModals/hooks/useModalCloseTransition'

import { ModalCloseTransitionContext } from './ModalCloseTransitionContext'

interface ModalTransitionContextProviderProps {
  children: React.ReactNode
}

export const ModalCloseTransitionContextProvider = ({
  children,
}: ModalTransitionContextProviderProps) => {
  const {
    isModalOpen,
    hasModalCloseTransition,
    executeTransitionFinishedCallback,
    closeWithoutTransition,
    closeWithTransition,
  } = useModalCloseTransition()

  const providerValue = useMemo(
    () => ({
      isModalOpen,
      hasModalCloseTransition,
      executeTransitionFinishedCallback,
      closeWithoutTransition,
      closeWithTransition,
    }),
    [
      closeWithTransition,
      closeWithoutTransition,
      executeTransitionFinishedCallback,
      hasModalCloseTransition,
      isModalOpen,
    ]
  )

  return (
    <ModalCloseTransitionContext.Provider value={providerValue}>
      {children}
    </ModalCloseTransitionContext.Provider>
  )
}
