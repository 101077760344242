import { Box, Flex } from '@npco/zeller-design-system'

import { ReactComponent as CloseIcon } from 'assets/svg/close-red.svg'
import { Button } from 'components/Buttons/Button'
import { page, shared } from 'translations'

import {
  StyledDescription,
  StyledFailedIcon,
  StyledTitle,
} from './BlockDeleteAccountModal.styled'

export const BlockDeleteAccountModal = ({
  closeModal,
  description,
}: {
  closeModal: () => void
  description: string
}) => {
  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <StyledFailedIcon>
        <CloseIcon />
      </StyledFailedIcon>

      <StyledTitle>{page.settings.deposits.deleteAccount.title}</StyledTitle>

      <Box mb="2rem">
        <StyledDescription> {description}</StyledDescription>
      </Box>

      <Box width="13.5rem">
        <Button type="button" onClick={closeModal} fullWidth>
          {shared.ok}
        </Button>
      </Box>
    </Flex>
  )
}
