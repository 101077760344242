import { Box, BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ImageContainer = styled(Box)<{
  mobileImagePath: string
  tabletImagePath: string
  desktopImagePath: string
}>`
  --modal-width-mobile: calc((100vw - 32px));
  --modal-width-tablet: calc((100vw - 48px));
  --modal-width-desktop-medium: 720px;

  --image-small-ratio: 1.56;
  --image-medium-ratio: 1.7866;

  background: url(${({ mobileImagePath }) => mobileImagePath}) center 0 / cover;
  height: calc(var(--modal-width-mobile) / var(--image-small-ratio));

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    background: url(${({ tabletImagePath }) => tabletImagePath}) center 0 /
      cover;
    height: calc(var(--modal-width-tablet) / var(--image-medium-ratio));
  }

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    height: calc(var(--modal-width-desktop-medium) / var(--image-medium-ratio));
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    background: url(${({ desktopImagePath }) => desktopImagePath}) center 0 /
      cover;
    min-height: 636px;
    height: auto;
    width: 410px;
  }
`
