import { useTranslations } from '@npco/utils-translations'
import { Box, Heading } from '@npco/zeller-design-system'

import { BusinessContactField } from '../../BusinessContact/BusinessContact'
import { CrnField } from '../../CRN'
import { Nickname } from '../../Nickname'
import { BpayAmountField } from '../BpayAmountField/BpayAmountField'
import { bpayTransferFieldsTranslations } from '../BpayTransferFields.i18n'

type BillerCodeFieldsProps = {
  contactValidationError: string | undefined
  setContactValidationError: (newError: string | undefined) => void
}

export const BillerCodeFields = ({
  contactValidationError,
  setContactValidationError,
}: BillerCodeFieldsProps) => {
  const bpayTranslations = useTranslations(bpayTransferFieldsTranslations)

  return (
    <>
      <CrnField />
      <Nickname />
      <BpayAmountField />
      <Box>
        <Box pb="24px">
          <Heading.H4>{bpayTranslations('linkContact')}</Heading.H4>
        </Box>
        <BusinessContactField
          contactValidationError={contactValidationError}
          setContactValidationError={setContactValidationError}
          showPersonalDetailsInput={false}
        />
      </Box>
    </>
  )
}
