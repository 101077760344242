import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UserIconAvatarIconFragmentDoc } from '../../../../IconAvatar/UserIconAvatar/UserIconAvatar.generated'

export type UserWithAvatarCustomerFragment = {
  __typename?: 'Customer'
  id: string
  entityUuid: string | null
  firstname: string | null
  lastname: string | null
  icon: {
    __typename?: 'Icon'
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export const UserWithAvatarCustomerFragmentDoc = gql`
  fragment UserWithAvatarCustomerFragment on Customer {
    id
    entityUuid
    firstname
    lastname
    icon {
      ...UserIconAvatarIconFragment
    }
  }
  ${UserIconAvatarIconFragmentDoc}
` as unknown as TypedDocumentNode<UserWithAvatarCustomerFragment, undefined>
