import { Flex } from '@npco/zeller-design-system'

import { StyledFooterButton } from './SidebarFooterButton.styled'

interface SidebarFooterButtonProps {
  onClick: () => void
  isDisabled?: boolean
  children?: React.ReactNode
}

export const SidebarFooterButton = ({
  onClick,
  isDisabled,
  children,
}: SidebarFooterButtonProps) => {
  return (
    <Flex justifyContent="center">
      <StyledFooterButton
        disabled={isDisabled}
        onClick={onClick}
        dataTestId="sidebar-button"
      >
        {children}
      </StyledFooterButton>
    </Flex>
  )
}
