import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  MagnifyingGlassNoResults,
  Message,
  MessageSize,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { incomeAndExpenseErrorTranslations } from './IncomeAndExpenseError.i18n'
import * as styled from './IncomeAndExpenseError.styled'

type IncomeAndExpenseErrorProps = {
  handleTryAgain: () => void
  title: string
  description: string
}

export const IncomeAndExpenseError = ({
  handleTryAgain,
  title,
  description,
}: IncomeAndExpenseErrorProps) => {
  const t = useTranslations(incomeAndExpenseErrorTranslations)
  const isMobile = useIsMobileResolution()

  return (
    <styled.Panel>
      <Box display={{ _: 'none', XS: 'flex' }}>
        <MagnifyingGlassNoResults size="large" />
      </Box>
      <Message
        title={<styled.Title>{title}</styled.Title>}
        description={isMobile ? undefined : description}
        primaryButton={{
          label: t('actionButton'),
          onClick: () => handleTryAgain(),
        }}
        size={MessageSize.LARGE}
        align={isMobile ? 'center' : 'left'}
      />
    </styled.Panel>
  )
}
