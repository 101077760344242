import * as Types from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useHasActiveAccountOrTransactionsQuery } from './graphql/HasActiveAccountOrTransactionsQuery.generated'

export const useHasActiveAccountOrTransactions = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error } = useHasActiveAccountOrTransactionsQuery({
    variables: { entityUuid },
  })

  const hasActiveAccounts =
    data?.getDebitCardAccountsV2?.accounts?.some(
      (account) =>
        account?.status === Types.DebitCardAccountStatus.ACTIVE ||
        account?.status === Types.DebitCardAccountStatus.SUSPENDED
    ) ?? false

  const hasTransactions =
    !!data?.getDebitCardTransactionsV2?.transactions?.length

  const hasNoTransactions = !loading && !error && !hasTransactions
  const hasNoActiveAccounts =
    !loading && !error && !hasActiveAccounts && !hasNoTransactions

  return {
    hasActiveAccounts: loading || error || hasActiveAccounts,
    hasNoActiveAccounts,
    hasNoTransactions,
    hasTransactions: loading || error || hasTransactions,
  }
}
