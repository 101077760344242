import { NetworkStatus, useQuery } from '@apollo/client'
import { AccountStatementType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { AccountStatementsQuery } from './graphql/AccountStatementsQuery'
import {
  AccountStatementsQueryResponse,
  AccountStatementsQueryVariables,
} from './graphql/AccountStatementsQuery.generated'

interface UseAccountStatementsQueryProps {
  debitCardAccountUuid: string
  statementType: AccountStatementType
}

export const useAccountStatementsQuery = ({
  debitCardAccountUuid,
  statementType,
}: UseAccountStatementsQueryProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error, refetch, networkStatus } = useQuery<
    AccountStatementsQueryResponse,
    AccountStatementsQueryVariables
  >(AccountStatementsQuery, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    variables: {
      input: {
        debitCardAccountUuid,
        statementType,
      },
      entityUuid,
    },
  })

  return {
    accountStatements: data?.getBankingAccountStatements || null,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  }
}
