import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ContactCoreFieldsDoc } from './ContactCoreFields.generated'

const defaultOptions = {} as const
export type GetContactQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetContactQueryResponse = {
  __typename?: 'Query'
  getContact: {
    __typename: 'Contact'
    abn: string | null
    acn: string | null
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    id: string
    jobTitle: string | null
    lastName: string | null
    notes: string | null
    isSelf: boolean | null
    phone: string | null
    tags: Array<string | null> | null
    website: string | null
    location: string | null
    locationAccuracy: number | null
    category: Types.EntityCategories | null
    subcategory: string | null
    subcategoryUuid: string | null
    additionalEmails: Array<{
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    additionalPhones: Array<{
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    address: {
      __typename?: 'ContactAddress'
      country: string | null
      postcode: string | null
      state: string | null
      street: string | null
      suburb: string | null
    } | null
    contacts: Array<{
      __typename: 'Contact'
      abn: string | null
      acn: string | null
      businessName: string | null
      contactType: Types.ContactType
      firstName: string | null
      id: string
      jobTitle: string | null
      lastName: string | null
      phone: string | null
      website: string | null
      location: string | null
      locationAccuracy: number | null
      category: Types.EntityCategories | null
      subcategory: string | null
      subcategoryUuid: string | null
      additionalEmails: Array<{
        __typename?: 'ContactEmail'
        email: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      }> | null
      additionalPhones: Array<{
        __typename?: 'ContactPhone'
        phone: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      }> | null
      address: {
        __typename?: 'ContactAddress'
        country: string | null
        postcode: string | null
        state: string | null
        street: string | null
        suburb: string | null
      } | null
      email: {
        __typename?: 'ContactEmail'
        email: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      } | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
      phoneV2: {
        __typename?: 'ContactPhone'
        phone: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      } | null
    } | null> | null
    email: {
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    paymentInstruments: Array<{
      __typename?: 'PaymentInstrument'
      id: string
      status: Types.PaymentInstrumentStatus
      bankAccountDetails: {
        __typename?: 'BankAccountDetails'
        account: string | null
        bsb: string | null
        name: string | null
      } | null
      details:
        | { __typename?: 'BankAccountDetails' }
        | { __typename?: 'BpayDetails' }
        | { __typename?: 'NppDetails' }
        | {
            __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
            billerName: string
            billerCode: string
            nickname: string | null
          }
        | {
            __typename: 'PaymentInstrumentBpayStaticCrnDetails'
            billerName: string
            billerCode: string
            nickname: string | null
            crn: string
          }
    }> | null
    phoneV2: {
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
  }
}

export const GetContact = gql`
  query GetContact($id: ID!, $entityUuid: ID!) {
    getContact(id: $id, entityUuid: $entityUuid) {
      ...ContactCoreFields
    }
  }
  ${ContactCoreFieldsDoc}
` as unknown as TypedDocumentNode<
  GetContactQueryResponse,
  GetContactQueryVariables
>

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactQueryResponse,
    GetContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContactQueryResponse, GetContactQueryVariables>(
    GetContact,
    options
  )
}
export function useGetContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactQueryResponse,
    GetContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContactQueryResponse, GetContactQueryVariables>(
    GetContact,
    options
  )
}
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>
export type GetContactLazyQueryHookResult = ReturnType<
  typeof useGetContactLazyQuery
>
export type GetContactQueryResult = Apollo.QueryResult<
  GetContactQueryResponse,
  GetContactQueryVariables
>
