import { gql } from '@apollo/client'

import { CardSize } from 'types/cards'

import { CardAvatarDebitCardV2Fragment } from './CardAvatar.generated'
import { StyledZellerCard } from './CardAvatar.styled'

interface CardAvatarProps {
  card: CardAvatarDebitCardV2Fragment
  isDisabled: boolean
}

export const CardAvatar = ({ card, isDisabled }: CardAvatarProps) => (
  <StyledZellerCard
    cardColor={card.colour}
    size={CardSize.SMALL}
    maskedPan={card.maskedPan}
    isNumberWithDots={false}
    isDisabled={isDisabled}
  />
)

CardAvatar.fragments = {
  DebitCardV2: gql`
    fragment CardAvatarDebitCardV2Fragment on DebitCardV2 {
      colour
      maskedPan
    }
  `,
}
