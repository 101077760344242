import styled from 'styled-components'

import { DashboardTitle } from 'layouts/DashboardLayout'
import { StyledContainer as StyledDashboardLayoutContainer } from 'layouts/DashboardLayout/DashboardLayout.styled'

export const StyledTitle = styled(DashboardTitle)`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
`

export const StyledContainer = styled(StyledDashboardLayoutContainer)`
  overflow: hidden;
`
