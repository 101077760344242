import { GetTransaction_getTransaction as ApiTransaction } from 'types/gql-types/GetTransaction'
import { GetTransactions_getTransactions_transactions as ApiTransactionInList } from 'types/gql-types/GetTransactions'

import { TransactionUpdate_onTransactionUpdate as ApiTransactionUpdate } from './gql-types/TransactionUpdate'

export interface TransactionInList extends ApiTransactionInList {
  subscriptionUpdatedAt?: string
}

export type Transaction = ApiTransaction

export interface TransactionUpdateData extends ApiTransactionUpdate {
  subscriptionUpdatedAt?: string
}

export interface TransactionExportMockData {
  wifiEnabled: boolean
  wifiSsid: string
  cellularEnabled: boolean
  cellularNetwork: string
  serial: string
  appVersion: string
  terminalConfig: string
  firmwareVersion: string
}

export enum TransactionFilterStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  REFUNDED = 'REFUNDED',
}
