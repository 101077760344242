import { FileListItem } from '../FileListItem/FileListItem'
import * as styled from './FileList.styled'
import { FileWithId } from './FileList.types'

interface Props {
  files: FileWithId[]
  removeFile: (id: string) => void
}

export const FileList = ({ files, removeFile }: Props) => {
  return (
    <styled.Wrapper>
      {files.map((fileWithId) => (
        <FileListItem
          key={fileWithId.id}
          id={fileWithId.id}
          title={fileWithId.file.name}
          onDelete={removeFile}
        />
      ))}
    </styled.Wrapper>
  )
}
