import { useCallback } from 'react'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'

import { getCurrencyAmountFormatted } from '../../../../Invoice/components/InvoiceFormAccordions/InvoiceFormAccordions.utils'
import { useInvoiceDecisionModal } from '../hooks/useInvoiceDecisionModal'
import { useMarkInvoiceAsPaid } from '../hooks/useMarkInvoiceAsPaid'
import { InvoiceDecisionModalTypes } from '../InvoiceDrawerDetails.utils'

export const translations = {
  confirm: translate('shared.confirm'),
  cancel: translate('shared.cancel'),
  title: translate('component.invoiceDrawer.decisionModals.markAsPaid.title'),
  body: (referenceNumber: string, remainingBalance: string) =>
    translate('component.invoiceDrawer.decisionModals.markAsPaid.body', {
      referenceNumber,
      remainingBalance,
    }),
  successToast: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.markAsPaid.success', {
      referenceNumber,
    }),
  errorToast: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.markAsPaid.error', {
      referenceNumber,
    }),
}

export const MarkAsPaidModal = () => {
  const { activeModal, closeModal, details } = useInvoiceDecisionModal()
  const { markInvoiceAsPaid, isMarkingAsPaid } = useMarkInvoiceAsPaid()

  const { dueAmount, id, referenceNumber } = details
  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleMarkAsPaid = useCallback(async () => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_MARK_AS_PAID)
    try {
      const response = await markInvoiceAsPaid(referenceNumber)

      if (!response.data?.markInvoiceAsPaid) {
        showErrorToast(translations.errorToast(referenceNumber))
        return
      }
      showSuccessToast(translations.successToast(referenceNumber))
    } catch {
      showErrorToast(translations.errorToast(referenceNumber))
    } finally {
      closeModal()
    }
  }, [closeModal, referenceNumber, markInvoiceAsPaid, trackInvoiceAction])

  return (
    <DecisionModal
      isOpen={activeModal === InvoiceDecisionModalTypes.MarkAsPaid}
      isLoadingPrimary={isMarkingAsPaid}
      onCancel={closeModal}
      onClickPrimary={handleMarkAsPaid}
      onClickSecondary={closeModal}
      primaryButtonLabel={translations.confirm}
      secondaryButtonLabel={translations.cancel}
      title={translations.title}
    >
      {translations.body(
        referenceNumber,
        getCurrencyAmountFormatted(dueAmount || '0')
      )}
    </DecisionModal>
  )
}
