import { useEffect } from 'react'
import { rvOnboardingIsProcessing } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { LoaderOnboarding } from 'components/LoaderOnboarding'
import { LogoCorner } from 'components/LogoCorner'
import { StyledOnboardingPageContainer } from 'components/OnboardingPageContainer'

import { StyledTopBar } from './LogoFlowContent.styled'

interface LogoFlowContentProps {
  children?: React.ReactNode
  isVerticallyCentered?: boolean
  loaderText?: React.ReactNode
  isLoading?: boolean
  isLoaderContainer?: boolean
}

export const LogoFlowContent = ({
  children,
  isVerticallyCentered = true,
  loaderText,
  isLoading,
  isLoaderContainer,
}: LogoFlowContentProps) => {
  useEffect(() => {
    const container = document.querySelector('#container')

    if (container) {
      container.scrollIntoView()
    }
  }, [])

  useEffect(() => {
    rvOnboardingIsProcessing(isLoading)
  }, [isLoading])

  return isLoading ? (
    <LoaderOnboarding loaderText={loaderText} />
  ) : (
    <StyledOnboardingPageContainer
      isVerticallyCentered={isVerticallyCentered}
      isLoaderContainer={isLoaderContainer}
    >
      <StyledTopBar>
        <LogoCorner />
      </StyledTopBar>

      {children}
    </StyledOnboardingPageContainer>
  )
}
