import { ReactNode } from 'react'

import {
  StyledDetailsContainer,
  StyledDetailsLabel1,
  StyledDetailsLabel2,
} from './HorizontalCardDetails.styled'

interface Props {
  label1?: ReactNode
  label2?: ReactNode
}

export const HorizontalCardDetails = ({ label1, label2 }: Props) => {
  return (
    <StyledDetailsContainer>
      {label1 && <StyledDetailsLabel1>{label1}</StyledDetailsLabel1>}
      {label2 && (
        <StyledDetailsLabel2 isLabel1Visible={!!label1}>
          {label2}
        </StyledDetailsLabel2>
      )}
    </StyledDetailsContainer>
  )
}
