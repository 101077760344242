import {
  sourcePickerInitialValue,
  statusPickerInitialValue,
  terminalPickerInitialValue,
  typePickerInitialValue,
} from 'const/common'

import { LocalStateTransactions } from './TransactionsFilters.types'

export const TransactionsDefaultLocalState: LocalStateTransactions = {
  filters: {
    amount: { min: undefined, max: undefined },
    dates: { start: undefined, end: undefined },
    search: '',
    sources: sourcePickerInitialValue,
    statuses: statusPickerInitialValue,
    terminals: terminalPickerInitialValue,
    types: typePickerInitialValue,
    siteUuid: '',
  },
  isFiltersVisible: false,
}
