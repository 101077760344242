import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToReportLostCardCompleteStage } from 'hooks/useGoToReportLostCardCompleteStage/useGoToReportLostCardCompleteStage'

export const useReportLostCardCompleteModal = () => {
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToReportLostCardCompleteStage } =
    useGoToReportLostCardCompleteStage()

  const openAddCardModal = useCallback(() => {
    goToAddCardStage({
      stage: AddCardStage.LandingPageStage,
    })
  }, [goToAddCardStage])

  const closeReportLostCardCompleteModal = useCallback(() => {
    goToReportLostCardCompleteStage(undefined)
  }, [goToReportLostCardCompleteStage])

  return { openAddCardModal, closeReportLostCardCompleteModal }
}
