import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { AssignDeviceToSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'

import { getNonNullString } from 'utils/string'
import {
  AssignDeviceToSite as AssignDeviceToSiteResponse,
  AssignDeviceToSiteVariables,
} from 'types/gql-types/AssignDeviceToSite'

import { translations } from '../Device.i18n'
import { DeviceSite } from '../Devices.types'

export type LastReassignedDeviceType = { siteId: string; deviceId: string }

export const useDeviceAssignment = () => {
  const entityUuid = useSelectedEntityUuid()
  const [sites, setSites] = useState<DeviceSite[]>([])
  const t = useTranslations(translations)
  const [lastReassignedDevice, setLastReassignedDevice] =
    useState<LastReassignedDeviceType | null>(null)

  const [assignDevice, { loading: isLoadingMutation }] = useMutation<
    AssignDeviceToSiteResponse,
    AssignDeviceToSiteVariables
  >(AssignDeviceToSite, {
    onCompleted(data) {
      if (lastReassignedDevice && data.assignDeviceToSite) {
        const { siteId: newSiteId, deviceId } = lastReassignedDevice
        const previousDeviceSiteIndex = sites.findIndex((site) =>
          site.devices.find(({ id }) => id === deviceId)
        )
        const previousDevice = sites[previousDeviceSiteIndex].devices.find(
          ({ id }) => id === deviceId
        )
        const newDeviceSiteIndex = sites.findIndex(({ id }) => id === newSiteId)

        const newSites = sites.map((site, siteIndex) => {
          if (siteIndex === previousDeviceSiteIndex) {
            return {
              ...site,
              devices: site.devices.filter(({ id }) => id !== deviceId),
            }
          }

          if (siteIndex === newDeviceSiteIndex && previousDevice) {
            return {
              ...site,
              devices: [
                ...site.devices,
                {
                  ...previousDevice,
                  siteName: site.name,
                  siteId: site.id,
                },
              ],
            }
          }

          return site
        })
        showSuccessToast(
          t('reassigned', {
            deviceName: getNonNullString(previousDevice?.name),
          })
        )
        setSites(newSites)
      } else {
        showApiErrorToast()
      }
    },
    onError: showApiErrorToast,
  })

  const handleReassign = useCallback(
    ({ siteId, deviceId }: LastReassignedDeviceType) => {
      setLastReassignedDevice({ siteId, deviceId })

      assignDevice({
        variables: {
          entityUuid,
          deviceUuid: deviceId,
          siteUuid: siteId,
        },
      })
    },
    [assignDevice, entityUuid]
  )

  return {
    sites,
    setSites,
    handleReassign,
    isLoadingMutation,
  }
}
