import { KycStatus } from '@npco/mp-gql-types'

const IDV_COMPLETED_STATUSES = [KycStatus.IN_REVIEW, KycStatus.VERIFIED]

interface WithKycStatus {
  kycStatus: KycStatus | null
}

export const getHasCompletedIDV = (customer: WithKycStatus) =>
  customer.kycStatus && IDV_COMPLETED_STATUSES.includes(customer.kycStatus)
