import { ChangeEventHandler, InputHTMLAttributes } from 'react'
import { CheckboxIndeterminateIcon as CheckboxMixedIcon } from '@npco/zeller-design-system'
import { FieldProps } from 'formik'

import { ReactComponent as CheckboxTickIcon } from 'assets/svg/tick.svg'
import { conditionalClassName as cn } from 'utils/conditionalClassName'

import * as styled from './Checkbox.styled'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  ariaLabel?: string
  ariaLabelledby?: string
  dataTestId?: string
  disabled?: boolean
  indeterminate?: boolean
  label?: React.ReactNode
  name: string
  onBlur?: any
  onChange?: ChangeEventHandler<HTMLInputElement>
  children?: React.ReactNode
  alignItems?: string
  justifyContent?: string
  flexDirection?: string
  checkboxMargin?: string
}

export const Checkbox = ({
  ariaLabel,
  ariaLabelledby,
  checked = false,
  dataTestId = 'checkbox-default',
  disabled = false,
  indeterminate = false,
  label,
  name,
  onBlur,
  onChange = () => null,
  onClick,
  type = 'checkbox',
  value,
  children,
  alignItems = 'flex-start',
  justifyContent,
  flexDirection,
  checkboxMargin,
}: CheckboxProps) => (
  <styled.Container
    data-testid="checkbox"
    className={cn({
      disabled,
    })}
    $alignItems={alignItems}
    $justifyContent={justifyContent}
    $flexDirection={flexDirection}
  >
    <styled.DefaultCheckbox
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      aria-checked={indeterminate ? 'mixed' : checked}
      checked={checked}
      data-testid={dataTestId}
      disabled={disabled}
      id={name}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      type={type}
      value={value}
    />
    {indeterminate && (
      <styled.IndeterminateCheckboxWrapper>
        <CheckboxMixedIcon aria-hidden />
      </styled.IndeterminateCheckboxWrapper>
    )}
    {!indeterminate && (
      <styled.Checkbox $margin={checkboxMargin}>
        <CheckboxTickIcon aria-hidden />
      </styled.Checkbox>
    )}
    {label && <styled.Text>{label}</styled.Text>}
    {children}
  </styled.Container>
)

type FormCheckboxProps = CheckboxProps & FieldProps

export const FormCheckbox = ({
  ariaLabel,
  ariaLabelledby,
  dataTestId,
  disabled,
  field,
  label,
  onBlur,
  onChange,
  type,
  children,
  alignItems,
  justifyContent,
  flexDirection,
  checkboxMargin,
}: FormCheckboxProps) => (
  <Checkbox
    {...field}
    ariaLabel={ariaLabel}
    ariaLabelledby={ariaLabelledby}
    dataTestId={dataTestId}
    disabled={disabled}
    id={field.name}
    label={label}
    onBlur={onBlur ?? field.onBlur}
    onChange={onChange ?? field.onChange}
    type={type}
    alignItems={alignItems}
    justifyContent={justifyContent}
    flexDirection={flexDirection}
    checkboxMargin={checkboxMargin}
  >
    {children}
  </Checkbox>
)
