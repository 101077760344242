import { useEffect } from 'react'
import { useMultiEntityStore } from '@npco/mp-utils-multi-entity-store'

import { useUpdateVisibleTabs } from './hooks/useUpdateVisibleTabs/useUpdateVisibleTabs'

const MULTI_ENTITY_STORE_DEBOUNCE_KEY = 'multi-entity-store'
const MULTI_ENTITY_STORE_DEBOUNCE_DEBOUNCE_IN_MS = 5000

export const PushMultiEntityStoreToBackend = () => {
  const { subscribe } = useMultiEntityStore()
  const { updateVisibleTabs } = useUpdateVisibleTabs({
    context: {
      debounceKey: MULTI_ENTITY_STORE_DEBOUNCE_KEY,
      debounceTimeout: MULTI_ENTITY_STORE_DEBOUNCE_DEBOUNCE_IN_MS,
    },
  })

  useEffect(() => {
    const unsubscribe = subscribe((visibleTabs) => {
      updateVisibleTabs(visibleTabs)
    })

    return unsubscribe
  }, [subscribe, updateVisibleTabs])

  return null
}
