import { BillingAccountType } from '@npco/mp-gql-types'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import { translate } from 'utils/translations'
import { DebitCardAccount } from 'types/accounts'

import { useSimBillingAccountSelection } from '../hooks/useFilterSimBillingAccount'

interface UseSelectSimBillingZellerAccountProps {
  onSuccess?: (account: DebitCardAccount) => void
}

export const useSelectSimBillingZellerAccount = ({
  onSuccess,
}: UseSelectSimBillingZellerAccountProps) => {
  const { isLoading, selectSimBillingAccount } =
    useSimBillingAccountSelection<DebitCardAccount>()

  const changeToZellerAccount = async (currentAccount: DebitCardAccount) => {
    try {
      await selectSimBillingAccount(currentAccount, BillingAccountType.PREPAID)
      rvSimBillingAccount(currentAccount)
      showSuccessToast(
        translate(
          'page.settings.sim.billingAccount.changingSimBillingAccountSuccessToast',
          { accountName: currentAccount.name }
        )
      )
      onSuccess?.(currentAccount)
    } catch (err) {
      showApiErrorToast()
    }
  }

  return {
    changeToZellerAccount,
    isLoading,
  }
}
