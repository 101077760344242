import { gql, type TypedDocumentNode } from '@apollo/client'

export type GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    income: { __typename?: 'Money'; value: string }
    expense: { __typename?: 'Money'; value: string }
  }
}

export const GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragmentDoc =
  gql`
    fragment GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      transactionDirectionTotalAmounts {
        income {
          value
        }
        expense {
          value
        }
      }
    }
  ` as unknown as TypedDocumentNode<
    GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment,
    undefined
  >
