import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { SiteIcon } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'
import { translations } from '../Sidebar.i18n'

export const SitesItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translations)

  return (
    <SidebarItem
      icon={<SiteIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.path}
      className="nav-item-site"
    >
      {t('sites')}
    </SidebarItem>
  )
}
