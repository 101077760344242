import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import {
  AvatarUserSize,
  TableCellAvatarUserText,
  TableCellSize,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { CardOwnerCellDebitCardV2Fragment } from './CardOwnerCell.generated'

export const CardOwnerCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, CardOwnerCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isMobile = useIsMobileResolution()
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CardOwnerCellDebitCardV2Fragment>()

  return (
    <TableCellAvatarUserText
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={isLoading}
      justifyContent="flex-end"
      key={cell.id}
      skeletonProps={{ width: skeletonLoaderWidth }}
      size={TableCellSize.MEDIUM}
      cellTextSize={isMobile ? 'Small' : 'Medium'}
      text={data?.owner}
      cellSize={cellSize}
      avatarProps={{ name: data?.owner || '', size: AvatarUserSize.XXSmall }}
    />
  )
}

CardOwnerCell.fragments = {
  DebitCardV2: gql`
    fragment CardOwnerCellDebitCardV2Fragment on DebitCardV2 {
      owner
    }
  `,
}
