import { useMemo } from 'react'
import { Box } from '@npco/zeller-design-system'
import {
  MultiSelectItemBasic,
  MultiSelectTriggerRenderProps,
} from 'design-system/Components/MultiSelect'
import {
  SelectSearchInput,
  SelectSelectedItem,
  SelectSize,
  SelectStandard,
} from 'design-system/Components/Select'

import { translate } from 'utils/translations'
import { NotFoundPlaceholder } from 'layouts/AccountLayout/filters/NotFoundPlaceholder/NotFoundPlaceholder'
import { SelectMobileFilterFooter } from 'components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'

import { FilterMobileSelectTrigger } from '../FilterMobileSelectTrigger/FilterMobileSelectTrigger'

interface Props<T extends MultiSelectItemBasic> {
  items: T[]
  selectedItem: T | undefined
  filterName: string
  onClose: () => void
  onChange: (newItem: SelectSelectedItem<T>) => void
  onReset: (onInnerClose: () => void) => void
  onApply: (onInnerClose: () => void) => void
  searchInput: string
  setSearchInput: (value: string) => void
}

export const MobileFilterCategorySelect = <T extends MultiSelectItemBasic>({
  items,
  selectedItem,
  filterName,
  onClose,
  onChange,
  onReset,
  onApply,
  searchInput,
  setSearchInput,
}: Props<T>) => {
  const renderTrigger = useMemo(() => {
    return ({ ref, ...renderTriggerProps }: MultiSelectTriggerRenderProps) => (
      <FilterMobileSelectTrigger
        filterName={filterName}
        selectedItems={selectedItem ? [selectedItem] : []}
        values={selectedItem ? [selectedItem.value] : []}
        innerRef={ref}
        {...renderTriggerProps}
      />
    )
  }, [filterName, selectedItem])

  return (
    <SelectStandard
      items={items}
      selectedItem={selectedItem}
      onChange={onChange}
      mobileLabel={translate(
        'page.accounts.transactionsList.filters.filterMobileHeader',
        {
          filterName,
        }
      )}
      onClose={onClose}
      size={SelectSize.Small}
      hasSelectedIndicator
      shouldCloseOnSelect={false}
      renderAdditionalControls={() => (
        <Box p="0.5rem">
          <SelectSearchInput
            value={searchInput}
            setValue={setSearchInput}
            aria-label={translate('shared.typeToSearch')}
            placeholder={translate('shared.typeToSearch')}
          />
        </Box>
      )}
      renderTrigger={renderTrigger}
      renderNoItemsPlaceholder={() => (
        <NotFoundPlaceholder searchInput={searchInput} />
      )}
      renderBottomSection={({ onClose: onInnerClose }) => (
        <SelectMobileFilterFooter
          onReset={() => onReset(onInnerClose)}
          onApply={() => onApply(onInnerClose)}
        />
      )}
    />
  )
}
