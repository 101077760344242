import { Accept, useDropzone } from 'react-dropzone'
import { showErrorToast } from '@npco/zeller-design-system'

import { getDropzoneErrorMessage } from 'utils/getDropzoneErrorMessage'
import {
  StyledGreyText,
  StyledModalWrapper,
} from 'components/ModalUploadLogo/ModalUploadLogo.styled'

import { SpinnerWrapped } from '../Spinner'
import { ModalUploadCropImage } from './ModalUploadCropImage/ModalUploadCropImage'
import { ModalUploadDropImage } from './ModalUploadDropImage/ModalUploadDropImage'
import { ModalUploadFooter } from './ModalUploadFooter/ModalUploadFooter'
import {
  ACCEPT_IMAGE_TYPES,
  MAX_FILE_SIZE_KB,
} from './ModalUploadImage.constants'
import { ModalUploadPreviewImage } from './ModalUploadPreviewImage/ModalUploadPreviewImage'

interface ModalUploadImageProps {
  description: React.ReactNode
  imageUrl: string
  isCroppingImageMode?: boolean
  isPreviewLoading?: boolean
  isPreviewError?: boolean
  isProcessing: boolean
  isSaveDisabled: boolean
  onCrop?: (nextImage: string) => void
  onCropFile?: (file: File) => void
  onDrop: (acceptedFiles: File[]) => FileReader
  onSecondaryButtonClick?: () => void
  primaryButtonLabel: React.ReactNode
  secondaryButtonLabel: React.ReactNode
  uploadFile: () => void
  acceptImageTypes?: Accept
  fileType?: string
}

export const ModalUploadImage = ({
  description,
  imageUrl,
  isPreviewError = false,
  isPreviewLoading = false,
  isCroppingImageMode = false,
  isProcessing,
  isSaveDisabled,
  onCrop,
  onCropFile,
  onDrop,
  onSecondaryButtonClick,
  primaryButtonLabel,
  secondaryButtonLabel,
  uploadFile,
  acceptImageTypes = ACCEPT_IMAGE_TYPES,
  fileType,
}: ModalUploadImageProps) => {
  const { getRootProps, open, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptImageTypes,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_KB,
    onDropRejected: (error) => {
      showErrorToast(getDropzoneErrorMessage(error))
    },
  })

  const handleSecondaryButtonClick = onSecondaryButtonClick ?? open

  if (isProcessing) {
    return (
      <StyledModalWrapper data-testid="upload-logo-modal">
        <SpinnerWrapped variant="centre" py="80px" />
      </StyledModalWrapper>
    )
  }

  return (
    <StyledModalWrapper data-testid="upload-logo-modal">
      <input data-testid="upload-image-input" {...getInputProps()} />

      {/* Loading State */}
      {isPreviewLoading && (
        <ModalUploadPreviewImage
          previewUrl={imageUrl}
          isPreviewLoading={isPreviewLoading}
          isPreviewError={isPreviewError}
        />
      )}

      {/* Drop Image State */}
      {!imageUrl && !isPreviewLoading && (
        <>
          <ModalUploadDropImage
            rootProps={getRootProps({ className: 'dropzone' })}
            isDragActive={isDragActive}
          />
          <StyledGreyText>{description}</StyledGreyText>
        </>
      )}

      {/* Preview Image State */}
      {imageUrl &&
        !isPreviewLoading &&
        (isCroppingImageMode && (onCrop || onCropFile) ? (
          <ModalUploadCropImage
            onCrop={onCrop}
            onCropFile={onCropFile}
            imageUrl={imageUrl}
            fileType={fileType}
          />
        ) : (
          <ModalUploadPreviewImage
            previewUrl={imageUrl}
            isPreviewLoading={false}
            isPreviewError={isPreviewError}
          />
        ))}

      {imageUrl && (
        <ModalUploadFooter
          isPrimaryButtonDisabled={isSaveDisabled}
          primaryButtonLabel={primaryButtonLabel}
          secondaryButtonLabel={secondaryButtonLabel}
          primaryButtonOnClick={() => {
            uploadFile()
          }}
          secondaryButtonOnClick={handleSecondaryButtonClick}
        />
      )}
    </StyledModalWrapper>
  )
}
