import { useCallback } from 'react'

import { AddBpayDetailsFormValues } from 'pages/Transfer/BPay/AddBpayDetailsModal/AddBpayDetailsModal.types'
import { TransferBpayFields } from 'pages/Transfer/Transfer.types'

interface UseBpayDetailsFieldProps {
  transferFormValues: TransferBpayFields | undefined
  setFieldValue: (
    field: string,
    value?: string | object,
    shouldValidate?: boolean
  ) => void
}

export const useBpayDetailsField = ({
  transferFormValues,
  setFieldValue,
}: UseBpayDetailsFieldProps) => {
  const handleAddBpayModelDone = useCallback(
    (values: AddBpayDetailsFormValues) => {
      const newContactOrBiller = transferFormValues?.to ?? {
        billerContacts: [],
      }

      newContactOrBiller.billerContacts = [
        {
          id: 'NEW_BILLER',
          name: values.billerName,
          nickname: values.nicknameNew,
          code: values.billerCode,
          reference: '',
        },
      ]

      setFieldValue('crn', values.crnNew)
      setFieldValue('nickname', values.nicknameNew)
      setFieldValue('contactOrBiller', newContactOrBiller)
      setFieldValue('paymentInstrumentUuid', { id: 'NEW_BILLER' })
      setFieldValue('amount', undefined, false)
    },
    [setFieldValue, transferFormValues]
  )

  return {
    handleAddBpayModelDone,
  }
}
