import { useTranslations } from '@npco/utils-translations'

import { FeatureList } from '../../../../components/FeatureList/FeatureList'
import { ScrollableContent, Title } from '../../../SetupFlowModal.styled'
import { setupFlowCardsStageTranslations } from '../FeatureCardsStage.i18n'
import { DebitCardDetails } from './DebitCardDetails'

export const MainContent = () => {
  const t = useTranslations(setupFlowCardsStageTranslations)

  return (
    <ScrollableContent data-testid="setup-flow-feature-cards-stage">
      <Title>{t('title')}</Title>
      <FeatureList
        title={t('debitCard')}
        points={[t('point1'), t('point2'), t('point3')]}
      />
      <FeatureList
        title={t('corporateCard')}
        points={[t('point4'), t('point5'), t('point6')]}
      />
      <DebitCardDetails />
    </ScrollableContent>
  )
}
