import { Outlet } from 'react-router-dom-v5-compat'
import { PageTemplate } from '@npco/zeller-design-system'

import { TransferPrimaryHeaderRow } from './TransferPrimaryHeaderRow/TransferPrimaryHeaderRow'

export const TransfersLayout = () => (
  <PageTemplate HeaderPrimaryRow={<TransferPrimaryHeaderRow />}>
    <Outlet />
  </PageTemplate>
)
