import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 24px;
  width: 100%;
  flex-flow: column;
  margin-bottom: 40px;
`

export const Tile = styled.label<{ $isActive: boolean }>`
  display: flex;
  gap: 16px;
  box-sizing: border-box;
  padding: 24px;
  padding-left: 32px;
  border: 1px solid
    ${({ $isActive, theme }) =>
      $isActive ? theme.colors.BLUE_1000 : theme.colors.GREY_250};
  border-radius: 8px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.BLUE_80 : theme.colors.WHITE};
  cursor: pointer;

  transition: background-color 0.2s, border-color 0.1s;
`

export const TileDescription = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`
