import styled from 'styled-components'

export const EllipsisableText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export interface ListItemEllipsisableTextProps {
  text: string
}

export const ListItemEllipsisableText = ({
  text,
}: ListItemEllipsisableTextProps) => <EllipsisableText>{text}</EllipsisableText>
