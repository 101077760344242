import {
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
} from 'react'
import {
  Box,
  INPUT_SIZE,
  InputAdaptive,
  InputSelectComboboxInputRenderProps,
  InputSelectComboboxStandardIconsWrapper,
} from '@npco/zeller-design-system'
import { useMergeRefs } from 'use-callback-ref'

import { LoaderSimple } from 'components/LoaderSimple'

interface EditTagsModalInputProps {
  hasError: boolean
  inputLabel: ReactNode
  isAddingEntityTag?: boolean
  onKeyPress: KeyboardEventHandler<HTMLInputElement>
  onBlur: FocusEventHandler<HTMLInputElement>
  onFocus: FocusEventHandler<HTMLInputElement>
  renderProps: InputSelectComboboxInputRenderProps
}

export const EditTagsModalInput = forwardRef<
  HTMLInputElement,
  EditTagsModalInputProps
>(
  (
    {
      onKeyPress,
      hasError,
      inputLabel,
      isAddingEntityTag,
      onBlur,
      onFocus,
      renderProps: { ref, ...props },
    },
    inputRef
  ) => {
    return (
      <>
        <InputAdaptive
          {...props}
          name="tag-input"
          ref={useMergeRefs([inputRef, ref])}
          hasError={hasError}
          label={inputLabel}
          data-testid="combobox-input"
          size={INPUT_SIZE.SMALL}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <InputSelectComboboxStandardIconsWrapper>
          {isAddingEntityTag && (
            <Box pt="0.25rem" mr="0.25rem">
              <LoaderSimple size="16" />
            </Box>
          )}
        </InputSelectComboboxStandardIconsWrapper>
      </>
    )
  }
)
