import { generatePath, Link, useParams } from 'react-router-dom-v5-compat'

import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg'
import { StyledIconWrapper } from 'layouts/DashboardLayout/DashboardLayout.styled'
import { BreadcrumbPath } from 'components/Breadcrumbs/Breadcrumbs.types'

interface BreadcrumbItemProps {
  route: BreadcrumbPath
  isLastElement: boolean
}

const Separator = () => (
  <StyledIconWrapper>
    <ArrowRight data-testid="arrow-right" />
  </StyledIconWrapper>
)

export const BreadcrumbItem = ({
  route,
  isLastElement,
}: BreadcrumbItemProps) => {
  const params = useParams()
  const SeparatorComp = isLastElement ? null : <Separator />

  if (route.path && !isLastElement) {
    return (
      <>
        <Link
          data-testid="main-route-link"
          to={generatePath(route.path, params)}
        >
          {route.name}
        </Link>
        {SeparatorComp}
      </>
    )
  }
  return (
    <>
      {route.name}
      {SeparatorComp}
    </>
  )
}
