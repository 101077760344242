import { boolean, object, string } from 'yup'

import { getIsMobileNumberValid } from 'utils/formValidation'
import { translate } from 'utils/translations'

export const smsOptionalSchema = object({
  isEnabled: boolean(),
  recipient: string().test(
    'isValidAustralianPhoneNumber',
    translate('errorMessages.invalidAustralianMobileNumber'),
    (value = '') => (!value ? true : getIsMobileNumberValid(value))
  ),
})

export const smsRequiredSchema = object({
  isEnabled: boolean(),
  recipient: string()
    .required(translate('errorMessages.invalidAustralianMobileNumber'))
    .test(
      'isValidAustralianPhoneNumber',
      translate('errorMessages.invalidAustralianMobileNumber'),
      (value = '') => getIsMobileNumberValid(value)
    ),
})
