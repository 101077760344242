import { useCallback } from 'react'
import { ButtonGhost, Divider } from '@npco/zeller-design-system'
import { AllInvoiceTableColumn } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import { StatusFiltersList } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/StatusFilters/StatusFiltersList'

import { shared } from 'translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import * as styled from './StatusFilters.styled'

export interface StatusFiltersProps {
  setIsOpen: (state: boolean) => void
}

const columnName = AllInvoiceTableColumn.Status

export const StatusFilters = () => {
  const {
    selectedStatuses,
    setSelectedStatuses,
    setStatusFilter,
    sortingObject,
  } = useInvoicesContext()

  const handleClear = useCallback(() => {
    setSelectedStatuses([])
    setStatusFilter(null)
  }, [setSelectedStatuses, setStatusFilter])

  const isClearDisabled =
    !selectedStatuses.length && sortingObject?.columnName !== columnName

  return (
    <styled.StatusFiltersWrapper data-testid="status-filters">
      <StatusFiltersList
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        setStatusFilter={setStatusFilter}
      />
      <Divider margin="20px 0" />
      <styled.BottomRow>
        <ButtonGhost onClick={handleClear} disabled={isClearDisabled}>
          {shared.clear}
        </ButtonGhost>
      </styled.BottomRow>
    </styled.StatusFiltersWrapper>
  )
}
