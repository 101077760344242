import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { page } from 'translations'

import { SubItem } from '../Sidebar.types'

export const usePaymentsSubItems = (): SubItem[] => {
  const flags = useFeatureFlags()
  const shortEntityId = useSelectedShortEntityUuid()

  return [
    {
      subitemTitle: page.title.transactions,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).TRANSACTIONS.path,
    },
    {
      subitemTitle: page.title.deposits,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).PAYMENTS.DEPOSITS.path,
    },
    {
      subitemTitle: page.title.reports,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).PAYMENTS.REPORTS.path,
      isVisible: !flags.CashFlowReporting,
    },
    {
      subitemTitle: page.title.devices,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).PAYMENTS.DEVICES.path,
    },
    {
      subitemTitle: page.title.sim,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.path,
    },
  ]
}
