import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  ConnectionUnsuccessful,
  Flex,
  useModalState,
} from '@npco/zeller-design-system'

import { useXeroBankFeedsError } from 'hooks/useXeroErrors/useXeroBankFeedsError'
import { useXeroPaymentServicesError } from 'hooks/useXeroErrors/useXeroPaymentServicesError'
import { ROOT } from 'const/routes'

import { Description } from './Components/Description'
import { XeroErrorModalTranslations } from './XeroErrorModal.i18n'

export const XeroErrorModal = () => {
  const t = useTranslations(XeroErrorModalTranslations)
  const { isModalOpen, closeModal } = useModalState(true)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const { hasAuthErrorInPaymentService, hasErrorInPaymentService } =
    useXeroPaymentServicesError()
  const { hasAuthErrorInBankFeeds, hasErrorInBankFeeds } =
    useXeroBankFeedsError()
  const hasGenericErrorInPaymentService =
    hasErrorInPaymentService && !hasAuthErrorInPaymentService
  const hasGenericErrorInBankFeeds =
    hasErrorInBankFeeds && !hasAuthErrorInBankFeeds

  const getHeading = () => {
    if (!hasGenericErrorInPaymentService && !hasGenericErrorInBankFeeds) {
      return t('authenticationExpired')
    }

    if (hasErrorInBankFeeds && hasErrorInPaymentService) {
      return t('connectionErrors')
    }

    return t('connectionError')
  }

  if (!isModalOpen) {
    return null
  }

  return (
    <AnnouncementModal
      isOpen={isModalOpen}
      // Intentionally not closing modal on onCancel so that user has to take action
      onCancel={() => null}
      Illustration={<ConnectionUnsuccessful size="large" />}
      heading={getHeading()}
      description={
        <Flex flexDirection="column">
          <Description
            hasErrorInBankFeeds={hasErrorInBankFeeds}
            hasAuthErrorInBankFeeds={hasAuthErrorInBankFeeds}
            hasErrorInPaymentService={hasErrorInPaymentService}
            hasAuthErrorInPaymentService={hasAuthErrorInPaymentService}
          />
        </Flex>
      }
      primaryButtonLabel={t('primaryButtonLabel')}
      onClickPrimary={() => {
        closeModal()
        navigate(ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.path)
      }}
      secondaryButtonLabel={t('secondaryButtonLabel')}
      onClickSecondary={closeModal}
    />
  )
}
