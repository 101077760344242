import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetDebitCardsList } from './graphql/getDebitCardsList'
import {
  GetDebitCardsListQueryResponse,
  GetDebitCardsListQueryVariables,
} from './graphql/getDebitCardsList.generated'
import { useToggleHeaderButtons } from './useToggleHeaderButtons'

type DebitCard = NonNullable<
  GetDebitCardsListQueryResponse['getDebitCardsV2']['cards'][number]
>

const getFilteredDebitCards = (cards: (DebitCard | null)[]) => {
  return cards.filter((card): card is DebitCard => !!card)
}

export const useQueryDebitCards = () => {
  const entityUuid = useSelectedEntityUuid()
  const {
    loading,
    data,
    error,
    networkStatus,
    refetch: refetchCards,
  } = useQuery<GetDebitCardsListQueryResponse, GetDebitCardsListQueryVariables>(
    GetDebitCardsList,
    {
      variables: {
        entityUuid,
        filter: {
          productType: {
            eq: DebitCardProductType.DEBIT,
          },
        },
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    }
  )

  const cards = useMemo(
    () => getFilteredDebitCards(data?.getDebitCardsV2?.cards ?? []),
    [data]
  )

  useToggleHeaderButtons({ cards })

  const entity = useMemo(() => data?.getEntity ?? null, [data?.getEntity])

  return {
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    cards,
    entity,
    error,
    refetchCards,
  }
}
