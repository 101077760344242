import { StyledRange as Slider, StyledThumb, StyledTrack } from './Range.styled'

type Value = number | number[] | null | undefined

interface RangeProps {
  className?: string
  margin?: string
  max: number
  min: number
  onAfterChange?: (value: number | readonly number[], index: number) => void
  onChange: ((value: Value) => void) | undefined
  step: number
  thumbBackgroundColor?: string
  value: number | number[] | undefined
}

export const Range: React.FC<RangeProps> = ({
  className,
  margin,
  max,
  min,
  onAfterChange,
  onChange,
  step,
  thumbBackgroundColor,
  value,
}) => (
  <Slider
    $margin={margin}
    defaultValue={[min, max]}
    ariaLabel={['Lower thumb', 'Upper thumb']}
    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
    renderThumb={(props) => (
      // react-slider has typings issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <StyledThumb {...props} $backgroundColor={thumbBackgroundColor} />
    )}
    renderTrack={(props, state) => (
      // react-slider has typings issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <StyledTrack {...props} index={state.index} />
    )}
    pearling
    minDistance={10}
    min={min}
    max={max}
    step={step}
    // react-slider has typings issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange={onChange}
    onAfterChange={onAfterChange}
    value={value}
    className={className}
  />
)
