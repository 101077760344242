import { useCallback } from 'react'
import { AccountStatementType } from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { UNEXPECTED_ERROR } from 'types/errors'

import { accountStatementListTranslations } from '../AccountStatementList.i18n'
import { StatementCard } from '../StatementCard'
import { useCreateInterimBankingAccountStatement } from './hooks/useCreateInterimBankingAccountStatement'
import { useInterimTitle } from './hooks/useInterimTitle/useInterimTitle'
import { interimStatementListItemTranslations } from './InterimStatementListItem.i18n'

interface InterimStatementListItemProps {
  statementType: AccountStatementType
  accountId: string
}

export const InterimStatementListItem = ({
  statementType,
  accountId,
}: InterimStatementListItemProps) => {
  const t = useTranslations(interimStatementListItemTranslations)
  const tList = useTranslations(accountStatementListTranslations)

  const { title } = useInterimTitle({ statementType })

  const {
    createInterimBankingAccountStatement,
    isLoading: isInterimStatementLoading,
  } = useCreateInterimBankingAccountStatement({ type: statementType })

  const downloadInterimStatement = useCallback(async () => {
    const result = await createInterimBankingAccountStatement({
      debitCardAccountUuid: accountId,
    })

    if (result === UNEXPECTED_ERROR) {
      showErrorToast(tList('downloadFailure'))
      return
    }

    try {
      await renameAndDownloadFile({
        link: result.interimAccountStatement.downloadLink,
        filename: tList('fileName', { date: title }),
        format: 'PDF',
      })

      showSuccessToast(tList('downloadSuccess'))
    } catch (err) {
      showErrorToast(tList('downloadFailure'))
    }
  }, [accountId, createInterimBankingAccountStatement, tList, title])

  return (
    <StatementCard
      title={t('interimTitle', { title })}
      isLoading={isInterimStatementLoading}
      onClick={downloadInterimStatement}
    />
  )
}
