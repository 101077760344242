import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetContactInvoicesQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  input: Types.GetInvoicesInput
}>

export type GetContactInvoicesQueryResponse = {
  __typename?: 'Query'
  getInvoices: {
    __typename?: 'InvoiceConnection'
    nextToken: string | null
    invoices: Array<{
      __typename: 'Invoice'
      createdTime: number | null
      dueAmount: string | null
      dueDate: any | null
      id: string
      paidAmount: string | null
      paidTime: number | null
      payerContactName: string | null
      referenceNumber: string
      sentTime: number | null
      startDate: any | null
      status: Types.InvoiceStatus
      title: string | null
      totalAmount: string | null
    }> | null
  }
}

export const GetContactInvoices = gql`
  query GetContactInvoices($entityUuid: ID!, $input: GetInvoicesInput!) {
    getInvoices(entityUuid: $entityUuid, input: $input) {
      invoices {
        __typename
        createdTime
        dueAmount
        dueDate
        id
        paidAmount
        paidTime
        payerContactName
        referenceNumber
        sentTime
        startDate
        status
        title
        totalAmount
      }
      nextToken
    }
  }
` as unknown as TypedDocumentNode<
  GetContactInvoicesQueryResponse,
  GetContactInvoicesQueryVariables
>

/**
 * __useGetContactInvoicesQuery__
 *
 * To run a query within a React component, call `useGetContactInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactInvoicesQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContactInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContactInvoicesQueryResponse,
    GetContactInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContactInvoicesQueryResponse,
    GetContactInvoicesQueryVariables
  >(GetContactInvoices, options)
}
export function useGetContactInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactInvoicesQueryResponse,
    GetContactInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContactInvoicesQueryResponse,
    GetContactInvoicesQueryVariables
  >(GetContactInvoices, options)
}
export type GetContactInvoicesQueryHookResult = ReturnType<
  typeof useGetContactInvoicesQuery
>
export type GetContactInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetContactInvoicesLazyQuery
>
export type GetContactInvoicesQueryResult = Apollo.QueryResult<
  GetContactInvoicesQueryResponse,
  GetContactInvoicesQueryVariables
>
