import { ApolloError, gql } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { Animation, fadeInAnimationVariants } from '@npco/zeller-design-system'

import { DebitCardSummarySkeleton } from 'components/DebitCardSummary/DebitCardSummarySkeleton/DebitCardSummarySkeleton'
import { ZellerCardSummary } from 'components/DebitCardSummary/ZellerCardSummary'

import { CannotPayByCardWarningMessage } from './CannotPayByCardWarningMessage'
import { ExpenseCardBudgetMessageDisplay } from './ExpenseCardBudgetMessageDisplay'
import {
  ZellerCardSummaryDisplayDebitCardV2Fragment,
  ZellerCardSummaryDisplayGetEntityFragment,
} from './ZellerCardSummaryDisplay.generated'
import { ZellerCardSummaryError } from './ZellerCardSummaryError/ZellerCardSummaryError'
import { ZellerCardSummaryStatusInfo } from './ZellerCardSummaryStatusInfo/ZellerCardSummaryStatusInfo'

interface ZellerCardSummaryDisplayProps {
  zellerCard: ZellerCardSummaryDisplayDebitCardV2Fragment | null
  entity: ZellerCardSummaryDisplayGetEntityFragment | null
  isLoading: boolean
  error: ApolloError | undefined
  retry: () => void
  isLoadingStatus?: boolean
  timezone?: string
}

export const ZellerCardSummaryDisplay = ({
  zellerCard,
  entity,
  isLoading,
  error,
  retry,
  isLoadingStatus,
  timezone,
}: ZellerCardSummaryDisplayProps) => {
  if (isLoading) {
    return <DebitCardSummarySkeleton />
  }

  if (error) {
    return <ZellerCardSummaryError retry={retry} />
  }

  if (!zellerCard) {
    return null
  }

  const isProductTypeExpense =
    zellerCard?.productType === DebitCardProductType.EXPENSE

  const cannotPayByCard = !entity?.canPayByCard

  return (
    <Animation shouldShow variants={fadeInAnimationVariants}>
      <ZellerCardSummary
        card={zellerCard}
        timezone={timezone}
        isLoadingStatus={isLoadingStatus}
      />
      {isProductTypeExpense && (
        <ExpenseCardBudgetMessageDisplay
          card={zellerCard}
          timezone={timezone}
        />
      )}
      {cannotPayByCard && <CannotPayByCardWarningMessage entity={entity} />}
      <ZellerCardSummaryStatusInfo status={zellerCard.status} />
    </Animation>
  )
}

ZellerCardSummaryDisplay.fragments = {
  DebitCardV2: gql`
    fragment ZellerCardSummaryDisplayDebitCardV2Fragment on DebitCardV2 {
      ...ZellerCardSummaryDebitCardV2Fragment
      ...ExpenseCardBudgetMessageDisplayDebitCardV2Fragment
    }

    ${ZellerCardSummary.fragments.DebitCardV2}
    ${ExpenseCardBudgetMessageDisplay.fragments.DebitCardV2}
  `,
  Entity: gql`
    fragment ZellerCardSummaryDisplayGetEntity on Entity {
      ...CannotPayByCardWarningMessageEntityFragment
    }
    ${CannotPayByCardWarningMessage.fragments.Entity}
  `,
}
