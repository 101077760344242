import {
  Box,
  BoxProps,
  ProgressBar as ProgressBarComponent,
} from '@npco/zeller-design-system'

import { useProgressBar } from './hooks/useProgressBar'

interface ProgressBarProps extends BoxProps {
  dataTestId?: string
}

export const ProgressBar = ({ dataTestId, ...boxProps }: ProgressBarProps) => {
  const { currentStep, totalSteps, shouldShow } = useProgressBar()

  if (!shouldShow) {
    return null
  }

  return (
    <Box {...boxProps}>
      <ProgressBarComponent
        dataTestId={dataTestId}
        completedAmount={currentStep}
        totalAmount={totalSteps}
      />
    </Box>
  )
}
