import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'

import { MultiSelectItemBasic } from '../MultiSelect.types'

interface Args<ItemType> {
  changeSelectedItems: (selectedItem: ItemType) => void
  items: ItemType[]
  onClose: () => void
}

export function useMultiSelectReducer<ItemType extends MultiSelectItemBasic>({
  changeSelectedItems,
  items,
  onClose,
}: Args<ItemType>) {
  const stateReducer = (
    state: DownshiftState<ItemType>,
    changes: StateChangeOptions<ItemType>
  ) => {
    if (
      changes.type === Downshift.stateChangeTypes.clickButton &&
      !changes.isOpen
    ) {
      onClose()
      return changes
    }
    if (
      changes.type === Downshift.stateChangeTypes.clickItem ||
      changes.type === Downshift.stateChangeTypes.blurInput ||
      (changes.type === Downshift.stateChangeTypes.blurButton && state.isOpen)
    ) {
      return {
        ...changes,
        isOpen: true,
      }
    }

    if (changes.type === Downshift.stateChangeTypes.keyDownSpaceButton) {
      if (state?.highlightedIndex !== null && state?.highlightedIndex > -1) {
        changeSelectedItems(items[state.highlightedIndex])
      }
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true,
      }
    }

    if (changes.type === Downshift.stateChangeTypes.keyDownEnter) {
      if (!changes.isOpen) {
        onClose()
      }

      return {
        isOpen: changes.isOpen,
        highlightedIndex: null,
      }
    }

    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
      onClose()
      return {
        ...changes,
        highlightedIndex: null,
      }
    }

    return changes
  }

  return {
    stateReducer,
  }
}
