import { Dispatch, SetStateAction, useState } from 'react'
import { Box, InfoBox } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { page } from 'translations'

import { getSubContactPersonNames } from '../../Contact/ContactSections/SubContacts/AddSubContact/AddSubContact.utils'
import {
  DEFAULT_BUSINESS_NAME,
  DEFAULT_PERSON_NAME,
} from '../../Contacts.constants'
import { ContactFormData, InputSelectComboBoxItem } from '../../Contacts.types'
import { BusinessComboBoxSelect } from '../BusinessComboBoxSelect'
import { PersonComboBoxSelect } from '../PersonComboBoxSelect'

interface SubContactComboBoxProps {
  isBusinessContactType: boolean
  isAddingNewSubContact: boolean
  setIsAddingNewSubContact: Dispatch<SetStateAction<boolean>>
  values: ContactFormData
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  selectedSubContact?: InputSelectComboBoxItem | null
  setSelectedSubContact: Dispatch<
    SetStateAction<InputSelectComboBoxItem | null | undefined>
  >
}

export const SubContactComboBox = ({
  isBusinessContactType,
  isAddingNewSubContact,
  setIsAddingNewSubContact,
  values,
  setFieldValue,
  selectedSubContact,
  setSelectedSubContact,
}: SubContactComboBoxProps) => {
  const [validationError, setValidationError] = useState('')

  const businessName =
    values.business.businessName?.trim() || DEFAULT_BUSINESS_NAME

  const personName =
    values.person.firstName || values.person.lastName
      ? `${values.person.firstName} ${values.person.lastName}`.trim()
      : DEFAULT_PERSON_NAME

  if (!isAddingNewSubContact && isBusinessContactType) {
    return (
      <>
        <PersonComboBoxSelect
          isAddingNew={isAddingNewSubContact}
          isOptional
          placeholder={page.contacts.form.searchPersonName}
          setIsAddingNew={(value, inputValue) => {
            const { firstName, lastName } = getSubContactPersonNames(
              inputValue ?? ''
            )

            setFieldValue('linkedContactUuid', '')
            setFieldValue('person.firstName', firstName)
            setFieldValue('person.lastName', lastName)
            setIsAddingNewSubContact(value)
          }}
          selectedItem={selectedSubContact}
          setSelectedItem={(item) => {
            setFieldValue('linkedContactUuid', item?.id || '')
            setSelectedSubContact(item)
          }}
          setValidationError={setValidationError}
          validationError={validationError}
        />
        {selectedSubContact && (
          <Box mt="1.5rem">
            <InfoBox>
              <InfoBox.Message>
                {translate('page.contacts.infoMessage.linkedTo', {
                  contact1: selectedSubContact.label,
                  contact2: businessName,
                })}
              </InfoBox.Message>
            </InfoBox>
          </Box>
        )}
      </>
    )
  }

  if (!isAddingNewSubContact && !isBusinessContactType) {
    return (
      <>
        <BusinessComboBoxSelect
          isAddingNew={isAddingNewSubContact}
          isOptional
          placeholder={page.contacts.form.searchBusinessName}
          setIsAddingNew={(value, inputValue) => {
            setFieldValue('linkedContactUuid', '')
            setFieldValue('business.businessName', inputValue ?? '')
            setIsAddingNewSubContact(value)
          }}
          selectedItem={selectedSubContact}
          setSelectedItem={(item) => {
            setFieldValue('linkedContactUuid', item?.id || '')
            setSelectedSubContact(item)
          }}
          setValidationError={setValidationError}
          validationError={validationError}
        />
        {selectedSubContact && (
          <Box mt="1.5rem">
            <InfoBox>
              <InfoBox.Message>
                {translate('page.contacts.infoMessage.linkedTo', {
                  contact1: personName,
                  contact2: selectedSubContact.label,
                })}
              </InfoBox.Message>
            </InfoBox>
          </Box>
        )}
      </>
    )
  }

  return null
}
