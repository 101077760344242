import { DebitCardAccountType } from '@npco/mp-gql-types'
import {
  BREAKPOINT,
  PageTemplate,
  Tabs,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { Balance } from '../../TransactionAccountsLayout/Balance/Balance'
import { DesktopBalanceSkeleton } from '../../TransactionAccountsLayout/skeletons/DesktopBalanceSkeleton'
import { useAccountLayoutTabs } from '../hooks/useAccountLayoutTabs'

export const AccountLayoutSecondaryHeaderRowDeps = {
  useIsMobileResolution,
  useAccountLayoutTabs,
}

export type AccountLayoutSecondaryHeaderRowProps = {
  accountId: string
  accountBalance?: number
  accountType?: DebitCardAccountType
  isLoadingAccount: boolean
}

export const AccountLayoutSecondaryHeaderRow = ({
  accountId,
  accountBalance,
  accountType,
  isLoadingAccount,
}: AccountLayoutSecondaryHeaderRowProps) => {
  const { useIsMobileResolution, useAccountLayoutTabs } =
    AccountLayoutSecondaryHeaderRowDeps

  const isTablet = useIsMobileResolution(BREAKPOINT.MD)

  const { tabs, currentTab } = useAccountLayoutTabs({
    accountId,
    accountType,
    isLoadingAccount,
  })

  if (isTablet) {
    return (
      <PageTemplate.HeaderSecondaryRow>
        <Tabs currentTab={currentTab}>
          {tabs.map((tab) => (
            <Tabs.Item key={tab.value} {...tab} />
          ))}
        </Tabs>
      </PageTemplate.HeaderSecondaryRow>
    )
  }

  return (
    <PageTemplate.HeaderSecondaryRow>
      <Tabs currentTab={currentTab}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.value} {...tab} />
        ))}
      </Tabs>
      {isLoadingAccount ? (
        <DesktopBalanceSkeleton />
      ) : (
        accountBalance !== undefined && (
          <Balance totalBalance={accountBalance} />
        )
      )}
    </PageTemplate.HeaderSecondaryRow>
  )
}
