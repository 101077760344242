import { useMemo, useState } from 'react'

import { AppState } from '../MFA.types'
import { MFAContext } from './MFAContext'

interface MFAProviderProps {
  children: React.ReactNode
  initialIsRedirecting?: boolean
  initialAppState?: AppState
  initialHasRedirectedBackToApp?: boolean
}

export const MFAProvider = ({
  children,
  initialIsRedirecting = false,
  initialAppState = undefined,
  initialHasRedirectedBackToApp = false,
}: MFAProviderProps) => {
  const [isRedirecting, setIsRedirecting] = useState(initialIsRedirecting)
  const [appState, setAppState] = useState<AppState | undefined>(
    initialAppState
  )
  const [hasRedirectedBackToApp, setHasRedirectedBackToApp] = useState(
    initialHasRedirectedBackToApp
  )
  const [onCancelAction, setOnCancelAction] = useState<(() => void) | null>(
    null
  )

  const value = useMemo(
    () => ({
      isRedirecting,
      setIsRedirecting,
      appState,
      setAppState,
      hasRedirectedBackToApp,
      setHasRedirectedBackToApp,
      onCancel: onCancelAction,
      setOnCancel: setOnCancelAction,
    }),
    [
      isRedirecting,
      appState,
      hasRedirectedBackToApp,
      onCancelAction,
      setOnCancelAction,
    ]
  )

  return <MFAContext.Provider value={value}>{children}</MFAContext.Provider>
}
