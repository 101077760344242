import { InterZellerMediumFont } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledTotalBalanceWhole = styled.p`
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.2;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    font-size: 2.5rem;
  }
`

export const StyledTotalBalanceDecimal = styled.p`
  font-size: 1rem;
  margin: 0.0625rem 0 0 0.25rem;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    ${InterZellerMediumFont}
    font-size: 1.375rem;
    margin: 0;
    margin-left: 0.25rem;
    margin-top: 0.3125rem;
  }
`
