import {
  SelectStyle,
  StyledSelectPopperMobileTitle,
} from '@npco/zeller-design-system'
import {
  MultiSelectBasic,
  MultiSelectBasicProps,
  MultiSelectHeader,
  MultiSelectItemBasic,
  MultiSelectTriggerRenderProps,
} from 'design-system/Components/MultiSelect'
import { MultiSelectItem } from 'design-system/Components/MultiSelect/MultiSelectItem/MultiSelectItem'
import { SelectSize } from 'design-system/Components/Select'

import { translate } from 'utils/translations'

import { SelectMobileFilterFooter } from '../../../../components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'
import { FilterMobileSelectTrigger } from '../FilterMobileSelectTrigger/FilterMobileSelectTrigger'

interface Props<ItemType extends MultiSelectItemBasic, TValue extends string> {
  items: ItemType[]
  selectedItems: ItemType[]
  areAllItemsSelected: boolean
  values: TValue[]
  onChange: (selectedItems: ItemType[]) => void
  onApply: (onInnerClose: () => void) => void
  onReset: (onInnerClose: () => void) => void
  onCancel: (onInnerClose: () => void) => void
  filterName: string
  renderAdditionalControls?: MultiSelectBasicProps<ItemType>['renderAdditionalControls']
  renderNoItemsPlaceholder?: MultiSelectBasicProps<ItemType>['renderNoItemsPlaceholder']
}

export const MultiSelectMobileFilter = <
  ItemType extends MultiSelectItemBasic,
  TValue extends string
>({
  items,
  selectedItems,
  areAllItemsSelected,
  values,
  onChange,
  onApply,
  onReset,
  onCancel,
  filterName,
  renderNoItemsPlaceholder,
  renderAdditionalControls,
}: Props<ItemType, TValue>) => {
  const renderTrigger = ({ ref, ...props }: MultiSelectTriggerRenderProps) => {
    return (
      <FilterMobileSelectTrigger
        areAllItemsSelected={areAllItemsSelected}
        filterName={filterName}
        selectedItems={selectedItems}
        values={values}
        innerRef={ref}
        {...props}
      />
    )
  }

  return (
    <MultiSelectBasic<ItemType>
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      selectSize={SelectSize.Small}
      selectStyle={SelectStyle.Standard}
      renderTrigger={renderTrigger}
      renderItem={(renderProps) => <MultiSelectItem {...renderProps} />}
      renderTopSection={({ onClose: onInnerClose, ...renderProps }) => (
        <MultiSelectHeader
          onClose={() => onCancel(onInnerClose)}
          {...renderProps}
        >
          <StyledSelectPopperMobileTitle>
            {translate(
              'page.accounts.transactionsList.filters.filterMobileHeader',
              {
                filterName,
              }
            )}
          </StyledSelectPopperMobileTitle>
        </MultiSelectHeader>
      )}
      renderAdditionalControls={renderAdditionalControls}
      renderNoItemsPlaceholder={renderNoItemsPlaceholder}
      renderBottomSection={({ onClose: onInnerClose }) => (
        <SelectMobileFilterFooter
          onReset={() => onReset(onInnerClose)}
          onApply={() => onApply(onInnerClose)}
        />
      )}
    />
  )
}
