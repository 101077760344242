import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  ButtonGhostIconOnly,
  Popper,
  PopperProps,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ReactComponent as Caret } from 'assets/svg/arrow-down.svg'
import { ReactComponent as DotsVerticalIcon } from 'assets/svg/dots-vertical.svg'

import { translations } from './ManageDropdown.i18n'
import * as styled from './ManageDropdown.styled'

type ManageDropdownProps = {
  children: PopperProps['children']
}

export const ManageDropdown = ({ children }: ManageDropdownProps) => {
  const t = useTranslations(translations)
  const isMobile = useIsMobileResolution(BREAKPOINT.XS)

  return (
    <Popper
      role="menu"
      placement="bottom-end"
      popperWidth="193px"
      renderTrigger={({ isOpen, toggleOpen }) =>
        isMobile ? (
          <ButtonGhostIconOnly
            icon={DotsVerticalIcon}
            onClick={toggleOpen}
            id="manage-button"
            dataTestId="manage-button-mobile"
          />
        ) : (
          <styled.ManageButton
            aria-expanded={isOpen}
            icon={Caret}
            onClick={toggleOpen}
            $isExpanded={isOpen}
            id="manage-button"
          >
            {t('manage')}
          </styled.ManageButton>
        )
      }
    >
      {(popperProps) =>
        typeof children === 'function' ? children(popperProps) : children
      }
    </Popper>
  )
}
