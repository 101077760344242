import { Formik } from 'formik'

import { EditNotesFormValues } from './EditNotesModal.types'
import { NotesModalForm } from './NotesModalForm/NotesModalForm'

interface EditNotesModalProps {
  characterLimit?: number
  handleSubmit: (notes: string) => void
  isLoading?: boolean
  isOpen: boolean
  notes: string | null
  onCancel: () => void
  placeholder: string
  title: string
}

export const EditNotesModal = ({
  characterLimit = 1000,
  handleSubmit,
  isLoading,
  isOpen,
  notes,
  onCancel,
  placeholder,
  title,
}: EditNotesModalProps) => {
  if (!isOpen) {
    return null
  }

  return (
    <Formik<EditNotesFormValues>
      initialValues={{
        notes: notes || '',
      }}
      onSubmit={({ notes: notesValue }) => handleSubmit(notesValue)}
    >
      <NotesModalForm
        isLoading={isLoading}
        isOpen={isOpen}
        limit={characterLimit}
        onCancel={onCancel}
        placeholder={placeholder}
        title={title}
      />
    </Formik>
  )
}
