import { Avatar, useAvatarColors } from '@npco/ui-avatar'
import { COLOR, Label, RadioPickerFormik } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface AccountAvatarFieldProps {
  accountName?: string
}

const NUMBER_TO_LETTER_CONVERSION = 65

export const AccountAvatarField = ({
  accountName,
}: AccountAvatarFieldProps) => {
  const colors = useAvatarColors()

  return (
    <>
      <Label>{translate('page.createAccountModal.avatarStyle')}</Label>
      <RadioPickerFormik name="accountColour" color={COLOR.BLUE_1000}>
        {colors.map((color, i) => (
          <RadioPickerFormik.Option
            key={color}
            id={color}
            value={color}
            dataTestId={`color-${color}`}
          >
            <Avatar
              color={color}
              text={
                accountName ||
                String.fromCharCode(
                  i + NUMBER_TO_LETTER_CONVERSION
                ).toUpperCase()
              }
              withCursorPointer
            />
          </RadioPickerFormik.Option>
        ))}
      </RadioPickerFormik>
    </>
  )
}
