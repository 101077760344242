import { useCallback, useMemo } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'
import { isEqual } from 'lodash-es'

import { PredefinedAddressPageFragment } from 'types/gql-types/PredefinedAddressPageFragment'

import {
  AddressFormValues,
  AddressOption,
  PredefinedAddressFormValues,
  PredefinedAddressValues,
} from '../../DeliveryAddress.types'

interface UsePredefinedAddressFormProps {
  addressOptions?: PredefinedAddressPageFragment
  address?: AddressFormValues
  onSubmit: (address: PredefinedAddressValues) => void
}

export const usePredefinedAddressForm = ({
  addressOptions,
  address,
  onSubmit,
}: UsePredefinedAddressFormProps) => {
  const handleSubmit = useCallback(
    ({ address: addressOption }: PredefinedAddressFormValues) => {
      if (
        !addressOptions?.businessAddress &&
        !addressOptions?.registeredAddress
      ) {
        ErrorLogger.report(
          '[Core] DeliveryAddressForm submitting form with null value',
          addressOptions
        )
        showErrorToast()
        return
      }

      if (
        addressOption === AddressOption.BusinessAddress &&
        addressOptions.businessAddress
      ) {
        onSubmit(addressOptions.businessAddress)
      }

      if (
        addressOption === AddressOption.RegisteredAddress &&
        addressOptions.registeredAddress
      ) {
        onSubmit(addressOptions.registeredAddress)
      }
    },
    [addressOptions, onSubmit]
  )

  const initialAddressOption = useMemo<AddressOption | ''>(() => {
    if (address && isEqual(addressOptions?.businessAddress, address)) {
      return AddressOption.BusinessAddress
    }

    if (address && isEqual(addressOptions?.registeredAddress, address)) {
      return AddressOption.RegisteredAddress
    }

    return ''
  }, [
    addressOptions?.businessAddress,
    addressOptions?.registeredAddress,
    address,
  ])

  return {
    handleSubmit,
    initialAddressOption,
  }
}
