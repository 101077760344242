import { FormikHelpers } from 'formik'

import { ItemBaseModalForm } from '../ItemBaseModalForm'
import { ItemFormFields } from '../ItemBaseModalForm.types'

export interface ItemsCreateModalFormProps<T> {
  initialValues: T
  isCreatingItem: boolean
  onClose: () => void
  onSubmit: (values: T, helpers: FormikHelpers<T>) => Promise<void>
  title: string
  skuDuplicateError?: string
}

export const ItemCreateModalForm = <T extends ItemFormFields>({
  isCreatingItem: isLoading,
  ...props
}: ItemsCreateModalFormProps<T>) => {
  return (
    <ItemBaseModalForm
      showDescription
      showAddNewItemCheckbox
      isLoading={isLoading}
      {...props}
    />
  )
}
