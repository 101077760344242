import { useCallback, useEffect, useState } from 'react'
import {
  Flex,
  PopperMobileTitleGroup,
  StyledPopperModalMobile,
} from '@npco/zeller-design-system'
import { SelectSearchInput } from 'design-system/Components/Select'

import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'

import { SelectMobileFilterFooter } from '../../../../components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'
import { AccountMobileFilterDescriptionTrigger } from './AccountMobileFilterDescriptionTrigger/AccountMobileFilterDescriptionTrigger'

export const AccountMobileFilterDescription = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { description, setDescription, resetDescription } = useAccountFilters()
  const [inputValue, setInputValue] = useState('')

  const resetInputValue = useCallback(() => {
    setInputValue(DEFAULT_FILTERS_VALUES.description)
  }, [])

  const onChange = useCallback((value: string) => {
    setInputValue(value)
  }, [])

  const onApply = () => {
    setIsOpen(false)
    setDescription(inputValue)
  }

  const onReset = () => {
    resetDescription()
    resetInputValue()
  }

  const onCancel = () => {
    setIsOpen(false)
    setInputValue(description)
  }

  useEffect(() => {
    setInputValue(description)
  }, [description])

  return (
    <>
      <AccountMobileFilterDescriptionTrigger
        openPopper={() => setIsOpen(true)}
        description={inputValue}
      />
      <StyledPopperModalMobile
        isOpen={isOpen}
        overlayClassName="modal-basic-overlay"
      >
        <Flex flexDirection="column" height="100%">
          <PopperMobileTitleGroup onClick={onCancel}>
            {translate(
              'page.accounts.transactionsList.filters.filterMobileHeader',
              {
                filterName: translate(
                  'page.accounts.transactionsList.filters.description'
                ),
              }
            )}
          </PopperMobileTitleGroup>
          <Flex p="1rem 0.5rem" flex="1" flexDirection="column">
            <SelectSearchInput
              value={inputValue}
              setValue={onChange}
              aria-label={translate(
                'page.accounts.transactionsList.filters.descriptionPlaceholder'
              )}
              placeholder={translate(
                'page.accounts.transactionsList.filters.descriptionPlaceholder'
              )}
            />
          </Flex>
          <SelectMobileFilterFooter onReset={onReset} onApply={onApply} />
        </Flex>
      </StyledPopperModalMobile>
    </>
  )
}
