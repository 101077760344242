import * as styled from './IncomeAndExpenseLegendItem.styled'

type IncomeAndExpenseLegendItemProps = {
  legend: {
    name: string
    color: string
  }
}

export const IncomeAndExpenseLegendItem = ({
  legend,
}: IncomeAndExpenseLegendItemProps) => {
  return (
    <styled.LegendItem as="li" key={`legend-item-${legend.name}`}>
      <styled.LegendItemIcon color={legend.color} />
      <styled.LegendItemText>{legend.name}</styled.LegendItemText>
    </styled.LegendItem>
  )
}
