import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Invoice Customisation',
  description:
    'Show customers your unique brand style with professionalism and impact. ',
  comingSoonPill: 'Coming Soon',
  benefits1: 'Choose a Template',
  benefits2: 'Accent with your colour',
  benefits3: 'Use your own logo',
  benefits4: 'Write a welcome note',
})
