import { KycStatus } from '@npco/mp-gql-types'

import { GetCustomers_getCustomers as Customer } from 'types/gql-types/GetCustomers'
import { page } from 'translations'

const kycRequired: (string | null)[] = [
  KycStatus.REQUIRED,
  KycStatus.NOT_REQUIRED,
  KycStatus.RC_REJECTED,
  KycStatus.RC_ABANDONED,
]

interface OnboardingStatus {
  title: string
  description: string
}
export const getOnboardingStatus = (
  user: Customer
): OnboardingStatus | null => {
  if (user.isInvitationPending) {
    return {
      title: page.settings.users.onboardingStatus.pending.title,
      description: page.settings.users.onboardingStatus.pending.description,
    }
  }
  if (user.kycStatus === KycStatus.IN_REVIEW) {
    return {
      title: page.settings.users.onboardingStatus.inReview.title,
      description: page.settings.users.onboardingStatus.inReview.description,
    }
  }
  if (kycRequired.includes(user.kycStatus)) {
    return {
      title: page.settings.users.onboardingStatus.unverified.title,
      description: page.settings.users.onboardingStatus.unverified.description,
    }
  }

  return null
}
