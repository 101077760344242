import { array, boolean, number, object, TestContext } from 'yup'

import { translate } from 'utils/translations'

const isUniqueValueInArray = (
  value: number | undefined,
  context: TestContext
) => {
  const hasMatchingValue = context.parent.some(
    (parentValue: number | undefined, index: number) => {
      // NOTE: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/49518
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line
      if (context.options.index === index || value === undefined) {
        return false
      }

      return parentValue === value
    }
  )

  return !hasMatchingValue
}

export const remindersSchema = object().shape({
  reminderOnDue: boolean(),
  remindersDaysBeforeDue: array().of(
    number().test(
      'Unique',
      translate('page.invoicesSettings.reminders.duplicateError'),
      isUniqueValueInArray
    )
  ),
  remindersDaysAfterDue: array().of(
    number().test(
      'Unique',
      translate('page.invoicesSettings.reminders.duplicateError'),
      isUniqueValueInArray
    )
  ),
})
