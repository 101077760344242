import { ButtonLink, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ListItem } from '../ListItem'

export const HeaderTitle = styled(Heading.H1)`
  margin: 0;
  margin-left: 14px;
  font-size: 1.375em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const HeaderEditButton = styled(ButtonLink)`
  font-size: 1.2em;
  margin-bottom: 14px;
`

export interface ListItemHeaderProps {
  title: string
  icon: React.ReactNode
  actionEditText: string
  actionEditOnClick: () => void
}

export const ListItemHeader = styled(
  ({
    title,
    icon,
    actionEditText,
    actionEditOnClick,
    ...props
  }: ListItemHeaderProps) => (
    <ListItem
      isLeftEllipsisable
      left={
        <div {...props}>
          {icon}
          <HeaderTitle>{title}</HeaderTitle>
        </div>
      }
      right={
        <HeaderEditButton onClick={actionEditOnClick}>
          {actionEditText}
        </HeaderEditButton>
      }
    />
  )
)`
  margin-bottom: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
