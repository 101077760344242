import { useMemo } from 'react'
import { gql } from '@apollo/client'
import dayjs from 'dayjs'

import { DateRange } from '../../../hooks/useSelectedDateRange'
import { convertToAWSDate } from '../../../utils/convertToAWSDate/convertToAWSDate'
import { UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment } from './useFilterByDateRange.generated'

type FilterByDateRangeOptions = {
  dateRange: DateRange
  timezone: string | undefined
}

export const useFilterByDateRange = <
  T extends UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment
>(
  totals: T[],
  { dateRange, timezone }: FilterByDateRangeOptions
) => {
  const date = useMemo(() => {
    return convertToAWSDate(dateRange, timezone)
  }, [dateRange, timezone])

  return useMemo(() => {
    if (dateRange === 'Last 12 Months' || dateRange.type === 'TTM') {
      return totals
    }

    return totals.filter((total) => {
      return dayjs(total.range.start).month() === dayjs(date).month()
    })
  }, [date, dateRange, totals])
}

useFilterByDateRange.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      range {
        start
      }
    }
  `,
}
