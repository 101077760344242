import {
  Flex,
  Heading,
  HeadingStyles,
  StyledPillWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledLabel = styled(Heading.H4).attrs({
  withStyles: HeadingStyles.H5,
})`
  margin: 0;
  padding-right: 1rem;
`

export const StyledValue = styled.div`
  text-align: right;
  ${({ theme }) => theme.typography['body-base']};
  word-break: break-word;

  ${StyledPillWrapper} {
    ${({ theme }) => theme.typography['body-base']};
  }
`

export const StyledItemWrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 0.625rem;
`
