import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'

import {
  rvSelectedContact,
  rvSelectedSubContact,
} from '../../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../../rv-deprecated/contacts.utils'

type Contact = ContactCoreFieldsFragment

export const updateRvSelectedContactWithSubContact = () => {
  const currentSubContacts = rvSelectedContact()?.contacts
  const newSubContact = rvSelectedSubContact()

  if (!newSubContact) {
    return
  }

  const newSubContacts =
    currentSubContacts && newSubContact
      ? currentSubContacts.concat(newSubContact)
      : [newSubContact]
  const currentContact = rvSelectedContact()

  const updatedContactWithSubContacts = {
    ...currentContact,
    contacts: newSubContacts,
  } as Contact
  rvSelectedContact(updatedContactWithSubContacts)

  // NOTE: Add selected sub contact to current contact
  updateRvContactsOnContactUpdate(updatedContactWithSubContacts.id, {
    contacts: newSubContacts,
  })

  // NOTE: add current contact as a sub contact of the selected sub contact
  updateRvContactsOnContactUpdate(newSubContact.id, {
    contacts: newSubContact.contacts
      ? newSubContact.contacts.concat(updatedContactWithSubContacts)
      : [updatedContactWithSubContacts],
  })
}

export const getSubContactFormTitle = (isSubContactTypeBusiness: boolean) => {
  return isSubContactTypeBusiness
    ? translate('page.contacts.form.title.business')
    : translate('page.contacts.form.title.person')
}

export const displaySubContactErrorToast = (
  contactName: string | undefined | null
) => {
  showErrorToast(
    translate('page.contacts.notifications.addFailMessage', {
      contactName,
    })
  )
}

export const displaySubContactSuccessToast = (
  contactName: string | undefined | null
) => {
  showSuccessToast(
    translate('page.contacts.notifications.addSuccessMessage', {
      contactName,
    })
  )
}

export const getSubContactPersonNames = (fullStringName: string) => {
  const nameSplit = fullStringName.trim().split(' ')

  if (nameSplit.length === 1) {
    return { firstName: fullStringName, lastName: '' }
  }

  return {
    firstName: nameSplit.slice(0, nameSplit.length - 1).join(' '),
    lastName: nameSplit[nameSplit.length - 1],
  }
}
