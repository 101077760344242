import { Box, Flex, SvgIcon } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { ReactComponent as IconEdit } from 'assets/svg/pen.svg'
import { ReactComponent as IconClose } from 'assets/svg/toasts/close.svg'
import { TemporaryCustomer } from 'types/customers'
import { BasicModal } from 'components/BasicModal'
import {
  CardHorizontal,
  CardLetterBadge,
} from 'components/Cards/CardHorizontal'
import { shared } from 'translations'

import {
  StyledListCustomersButton,
  StyledListCustomersItemWrapper,
} from './ListCustomers.styled'

export interface Props {
  children: React.ReactNode
  customer: TemporaryCustomer
  removeModalTitle: string
  removeModalWarning: string
  onEdit?: () => void
  onRemove?: () => void
  letterToDisplay?: string
}

export const ListCustomersItem = ({
  customer,
  onEdit,
  onRemove,
  removeModalTitle,
  removeModalWarning,
  children,
  letterToDisplay,
  ...rest
}: Props) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <StyledListCustomersItemWrapper {...rest}>
      <CardHorizontal>
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="center">
            <Box mr="16px">
              <CardLetterBadge
                data-testid={`letter-badge-${customer.temporaryId}`}
              >
                {letterToDisplay}
              </CardLetterBadge>
            </Box>

            <div>{children}</div>
          </Flex>

          <Flex>
            {onEdit && (
              <Flex height="40px" alignItems="center" mr="8px">
                <StyledListCustomersButton
                  data-testid={`customers-edit-button-${customer.temporaryId}`}
                  onClick={onEdit}
                >
                  <SvgIcon height="16" width="16">
                    <IconEdit />
                  </SvgIcon>
                </StyledListCustomersButton>
              </Flex>
            )}

            {onRemove && (
              <>
                <Flex height="40px" alignItems="center" mr="8px">
                  <StyledListCustomersButton
                    data-testid={`customers-remove-button-${customer.temporaryId}`}
                    onClick={openModal}
                  >
                    <SvgIcon height="16" width="16">
                      <IconClose />
                    </SvgIcon>
                  </StyledListCustomersButton>
                </Flex>

                <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
                  <BasicModal
                    modalTitle={removeModalTitle}
                    modalDescription={removeModalWarning}
                    onPrimaryButtonClick={() => {
                      onRemove()
                      closeModal()
                    }}
                    onSecondaryButtonClick={closeModal}
                    primaryButtonLabel={shared.remove}
                  />
                </ModalBasic>
              </>
            )}
          </Flex>
        </Flex>
      </CardHorizontal>
    </StyledListCustomersItemWrapper>
  )
}
