import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'

import { CardEmptyViewAccount } from '../components/CardEmptyViewAccount/CardEmptyViewAccount'
import { CardEmptyViewMessage } from '../components/CardEmptyViewMessage/CardEmptyViewMessage'
import { cardEmptyAccountTranslations } from './AccountCardsEmpty.i18n'

interface AccountCardsEmptyProps {
  onAddCard: () => void
}

export const AccountCardsEmpty = ({ onAddCard }: AccountCardsEmptyProps) => {
  const t = useTranslations(cardEmptyAccountTranslations)
  const { userRole } = useLoggedInUser()

  if (userRole === CustomerRole.ADMIN) {
    return <CardEmptyViewAccount onAddCard={onAddCard} />
  }

  return (
    <CardEmptyViewMessage title={t('title')} description={t('description')} />
  )
}
