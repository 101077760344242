import ReactSlider from 'react-slider'
import styled from 'styled-components'

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 24px;
  box-sizing: border-box;
`

export const Thumb = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  border-radius: 50%;
  cursor: grab;
  outline: none;
  box-sizing: border-box;
`

interface TrackProps {
  index?: number
}

export const Track = styled.div<TrackProps>`
  top: 10px;
  height: 4px;
  background: ${({ theme, index }) =>
    index === 1 ? theme.colors.GREY_60 : theme.colors.BLUE_1000};
`

interface MarkProps {
  isActive: boolean
  positionLeft: number
}

export const Mark = styled.span<MarkProps>`
  position: absolute;
  width: 2px;
  height: 2px;
  top: 11px;
  left: ${({ positionLeft }) => `${positionLeft + 11}px`};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.WHITE : theme.colors.GREY_150};
  border-radius: 50%;
  cursor: pointer;
`
