import { AccountBalanceListError } from './AccountBalanceListError'
import { AccountBalanceListHeader } from './AccountBalanceListHeader'
import { AccountBalanceListView } from './AccountBalanceListView'
import { useAccountBalanceListQuery } from './hooks/useAccountBalanceListQuery'

export const AccountBalanceList = () => {
  const {
    isLoading,
    debitCardAccounts,
    savingsAccounts,
    entity,
    error,
    refetch,
  } = useAccountBalanceListQuery()

  if (error && !isLoading) {
    return <AccountBalanceListError retry={refetch} />
  }

  return (
    <>
      <AccountBalanceListHeader entity={entity} />
      <AccountBalanceListView
        isLoading={isLoading}
        accounts={[...savingsAccounts, ...debitCardAccounts]}
        entity={entity}
      />
    </>
  )
}
