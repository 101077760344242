import { useMemo } from 'react'
import { QueryHookOptions, useReactiveVar } from '@apollo/client'
import { rvOptimisticDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useSelectedTransactionUpdate } from 'hooks/transactions'
import { useDebitCardTransactionsWithUpdates } from 'hooks/useDebitCardTransactionsWithUpdates/useDebitCardTransactionsWithUpdates'
import { groupDebitCardsTransactions } from 'utils/groupDebitCardsTransactions'
import {
  filterByCardId,
  filterByContactUuid,
  filterDuplicates,
  getIsWithinTimeRange,
} from 'pages/DebitCardTransactions/hooks/useDebitCardAccountTransactions/utils/useDebitCardAccountTransactions.utils'

import { OutstandingSettingsType } from '../useOutstandingFilterSettings'
import {
  GetDebitCardTransactionsNewQueryResponse,
  GetDebitCardTransactionsNewQueryVariables,
} from './graphql/getDebitCardTransactions.generated'
import { DebitCardTransaction } from './useDebitCardTransactions.types'
import { useDebitCardTransactionsQuery } from './useDebitCardTransactionsQuery'
import { useSubscribeDebitCardTransaction } from './useSubscribeDebitCardTransaction'

type HookOptions = QueryHookOptions<
  GetDebitCardTransactionsNewQueryResponse,
  GetDebitCardTransactionsNewQueryVariables
>

interface UseOptimisticDebitCardTransactionsProps extends HookOptions {
  groupBy?: (x: (DebitCardTransaction | null)[]) => DebitCardTransaction[][]
  areFiltersInDefaultState?: boolean
  variables: GetDebitCardTransactionsNewQueryVariables
  skip?: boolean
  outstandingSettings: OutstandingSettingsType
  isLoadingOutstandingFilterSettings: boolean
}

export const useOptimisticDebitCardTransactions = ({
  groupBy = groupDebitCardsTransactions,
  areFiltersInDefaultState = true,
  variables,
  skip,
  outstandingSettings,
  isLoadingOutstandingFilterSettings,
  ...queryProps
}: UseOptimisticDebitCardTransactionsProps) => {
  const optimisticDebitCardTransactions = useReactiveVar(
    rvOptimisticDebitCardTransactions
  )
  const { limit, filter, debitCardId } = variables

  const selectedContactID = filter?.contactUuid?.eq ?? undefined
  const {
    transactions,
    loading,
    fetchMoreTransactions,
    hasMore,
    error,
    refetchTransactions,
  } = useDebitCardTransactionsQuery({
    variables,
    skip,
    ...queryProps,
  })

  const { transactionsWithUpdates } = useDebitCardTransactionsWithUpdates({
    transactions,
    contactUuid: selectedContactID,
    debitCardId,
  })

  const transactionsWithOptimistic = useMemo(
    () =>
      skip
        ? []
        : [
            ...optimisticDebitCardTransactions
              .filter(filterByCardId(debitCardId))
              .filter(filterByContactUuid(selectedContactID))
              .filter(filterDuplicates(transactionsWithUpdates))
              .filter(getIsWithinTimeRange(transactionsWithUpdates, limit)),
            ...transactionsWithUpdates,
          ],
    [
      optimisticDebitCardTransactions,
      transactionsWithUpdates,
      selectedContactID,
      limit,
      debitCardId,
      skip,
    ]
  )

  const groupedTransactions = useMemo(
    () => groupBy(transactionsWithOptimistic),
    [transactionsWithOptimistic, groupBy]
  )

  const { partialUpdateSelectedTransaction } = useSelectedTransactionUpdate({
    cachedTransactions: transactions,
  })

  useSubscribeDebitCardTransaction({
    debitCardId,
    transactions,
    skip:
      skip || !areFiltersInDefaultState || isLoadingOutstandingFilterSettings,
    outstandingSettings,
  })

  return {
    groupedTransactions,
    fetchMoreTransactions,
    loading,
    hasMore,
    error,
    refetchTransactions,
    handleSelectedTransactionUpdate: partialUpdateSelectedTransaction,
  }
}
