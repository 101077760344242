import { useCallback } from 'react'
import { parsePriceToCents } from '@npco/component-mp-common'

import { UNEXPECTED_ERROR } from 'types/errors'

import { useSubmitBpayTransfer } from './useSubmitBpayTransfer'

export interface SendBpayTransfer {
  amount: string
  debitCardAccountUuid: string
  paymentInstrumentUuid: string
  type?: 'static' | 'dynamic'
  crn?: string
}

export const useSendBpayTransfer = () => {
  const { submitBpayTransfer, isSubmitBpayLoading } = useSubmitBpayTransfer(1)

  const handleSubmit = useCallback(
    async ({
      amount,
      debitCardAccountUuid,
      paymentInstrumentUuid,
      type,
      crn,
    }: SendBpayTransfer) => {
      try {
        const amountInCents = parsePriceToCents(amount).toString()

        const submitBpayTransferResults = await submitBpayTransfer({
          isDynamicPayment: type === 'dynamic',
          amountInCents,
          debitCardAccountUuid,
          paymentInstrumentUuid,
          crn: crn ?? 'crn',
        })

        return submitBpayTransferResults
      } catch {
        return UNEXPECTED_ERROR
      }
    },
    [submitBpayTransfer]
  )
  return { handleSubmit, loading: isSubmitBpayLoading }
}
