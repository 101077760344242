import { Condition } from 'components/Condition/Condition'

import { useCreateExternalAccountContext } from '../../contexts/useCreateExternalAccountContext'
import { CreateExternalAccountStage } from '../../CreateExternalAccount.types'
import { ExternalAccountFormStage } from './ExternalAccountFormStage/ExternalAccountFormStage'
import { ExternalAccountMessageStage } from './ExternalAccountMessageStage/ExternalAccountMessageStage'
import { ExternalAccountReviewStage } from './ExternalAccountReviewStage/ExternalAccountReviewStage'

export const CreateExternalAccountStages = () => {
  const { state } = useCreateExternalAccountContext()

  return (
    <>
      <Condition
        shouldShow={
          state?.stage ===
          CreateExternalAccountStage.ExternalAccountMessageStage
        }
      >
        <ExternalAccountMessageStage />
      </Condition>
      <Condition
        shouldShow={
          state?.stage === CreateExternalAccountStage.ExternalAccountFormStage
        }
      >
        <ExternalAccountFormStage />
      </Condition>
      <Condition
        shouldShow={
          state?.stage === CreateExternalAccountStage.ExternalAccountReviewStage
        }
      >
        <ExternalAccountReviewStage />
      </Condition>
    </>
  )
}
