import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { useGoToBlockedAction } from 'features/BlockedAction/blocked-action-routing'

import { UseGoToCreateAccountStageEntityFragment } from 'types/gql-types/UseGoToCreateAccountStageEntityFragment'

import { CreateAccountStage } from './useGoToCreateAccountStage.types'

export const useGoToCreateAccountStage = (
  entity?: UseGoToCreateAccountStageEntityFragment | null
) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { goToBlockedAction } = useGoToBlockedAction()
  const { pathname } = location

  const isAccountCreationBlocked = entity && entity?.canCreateAccount === false

  const goToCreateAccountStage = useCallback(
    (stage: CreateAccountStage | undefined) => {
      if (isAccountCreationBlocked) {
        goToBlockedAction({ blockedAction: 'createAccount' })
        return
      }

      navigate(pathname, { state: { CreateAccountModal: { stage } } })
    },
    [navigate, pathname, goToBlockedAction, isAccountCreationBlocked]
  )

  const navigateTo = useCallback(
    (stage: CreateAccountStage | undefined) => {
      if (isAccountCreationBlocked) {
        navigate(pathname, {
          state: { BlockedActionModal: { blockedAction: 'createAccount' } },
        })
        return
      }

      navigate(pathname, { state: { CreateAccountModal: { stage } } })
    },
    [isAccountCreationBlocked, navigate, pathname]
  )

  return { goToCreateAccountStage, navigateTo }
}

useGoToCreateAccountStage.fragments = {
  Entity: gql`
    fragment UseGoToCreateAccountStageEntityFragment on Entity {
      canCreateAccount
    }
  `,
}
