import { useCallback, useEffect, useMemo, useState } from 'react'

import { locationTypeInMetersMap } from 'utils/google'

export const COUNTRY_ACCURACY_RADIUS = 2500000
export const COUNTRY_ACCURACY_FALLBACK_RADIUS = 4500000

export const getLatLongFromLocation = (location: string | null) => {
  if (location?.includes(',')) {
    const latlong = location.split(',')
    return {
      lat: Number(latlong[0]),
      lng: Number(latlong[1]),
    }
  }

  return null
}

interface UseLocationAccuracyProps {
  location: string | null
  locationAccuracy: number | null | undefined
  skip: boolean
}

export const useLocationAccuracy = ({
  skip,
  location,
  locationAccuracy,
}: UseLocationAccuracyProps) => {
  const [centerLocationOfCountry, setCenterLocationOfCountry] = useState<
    google.maps.LatLngLiteral | undefined
  >()

  const [isCountryQueryFailed, setIsCountryQueryFailed] = useState(false)

  const isLocationOnlyAccurateToCountry = Boolean(
    locationAccuracy &&
      locationAccuracy >= locationTypeInMetersMap.GEOMETRIC_CENTER
  )

  const mapCircleRadius = useMemo(() => {
    if (isLocationOnlyAccurateToCountry && centerLocationOfCountry) {
      return COUNTRY_ACCURACY_RADIUS
    }

    if (isLocationOnlyAccurateToCountry && isCountryQueryFailed) {
      return COUNTRY_ACCURACY_FALLBACK_RADIUS
    }

    return locationAccuracy === locationTypeInMetersMap.ROOFTOP
      ? null
      : locationAccuracy
  }, [
    locationAccuracy,
    isCountryQueryFailed,
    centerLocationOfCountry,
    isLocationOnlyAccurateToCountry,
  ])

  const getCenterLocationOfCountry = useCallback((countryName: string) => {
    new google.maps.Geocoder()
      .geocode({ address: countryName }, (results, status) => {
        const isGeocoderStatusOk =
          status === google.maps.GeocoderStatus.OK && results?.length

        if (isGeocoderStatusOk) {
          setCenterLocationOfCountry({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          })
        } else {
          setIsCountryQueryFailed(true)
        }
      })
      .catch(() => setIsCountryQueryFailed(true))
  }, [])

  useEffect(() => {
    if (skip || !locationAccuracy || centerLocationOfCountry) {
      return
    }

    /**
     * when location accuracy is >= 10,000 (can be considered as cannot get the GPS signal)),
     * hide marker and display a large circle that can cover whole country.
     * given a location (lat, lng) -> find the country name and find center location of the country (show fallback if failed)
     */
    if (locationAccuracy >= locationTypeInMetersMap.GEOMETRIC_CENTER) {
      new google.maps.Geocoder()
        .geocode(
          { location: getLatLongFromLocation(location) },
          (results, status) => {
            const isGeocoderStatusOk =
              status === google.maps.GeocoderStatus.OK && results?.length

            if (isGeocoderStatusOk) {
              const countryResult = results.find(
                (result) => result.types[0] === 'country'
              )

              if (!countryResult) {
                setIsCountryQueryFailed(true)
                return
              }

              const countryName = countryResult.address_components[0].long_name
              getCenterLocationOfCountry(countryName)
            } else {
              setIsCountryQueryFailed(true)
            }
          }
        )
        .catch(() => setIsCountryQueryFailed(true))
    }
  }, [
    skip,
    location,
    locationAccuracy,
    centerLocationOfCountry,
    getCenterLocationOfCountry,
  ])

  return {
    isMapMarkVisible: !isLocationOnlyAccurateToCountry,
    mapLocation: centerLocationOfCountry ?? getLatLongFromLocation(location),
    mapCircleRadius,
  }
}
