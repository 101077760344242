import { useCallback, useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { isNotNull } from 'utils/common'

import { AccountBalanceListQuery } from './graphql/AccountBalanceListQuery'
import {
  AccountBalanceListQueryResponse,
  AccountBalanceListQueryVariables,
} from './graphql/AccountBalanceListQuery.generated'

export const useAccountBalanceListQuery = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error, refetch, networkStatus } = useQuery<
    AccountBalanceListQueryResponse,
    AccountBalanceListQueryVariables
  >(AccountBalanceListQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { entityUuid },
  })

  const refetchWithoutArgs = useCallback(() => {
    refetch()
  }, [refetch])

  const debitCardAccounts = useMemo(() => {
    return (
      data?.getDebitCardAccountsV2?.accounts
        .filter(isNotNull)
        .filter((account) => account.status !== DebitCardAccountStatus.CLOSED)
        .filter((account) => account.type === DebitCardAccountType.ZLR_DEBIT) ??
      []
    )
  }, [data])

  const savingsAccounts = useMemo(() => {
    return (
      data?.getSavingsAccounts
        .filter(isNotNull)
        .filter(
          (account) => account.status !== DebitCardAccountStatus.CLOSED
        ) ?? []
    )
  }, [data])

  const entity = useMemo(() => {
    return data?.getEntity
  }, [data?.getEntity])

  return {
    isLoading:
      (loading &&
        debitCardAccounts.length === 0 &&
        savingsAccounts.length === 0) ||
      networkStatus === NetworkStatus.refetch,
    debitCardAccounts,
    savingsAccounts,
    entity,
    error,
    refetch: refetchWithoutArgs,
  }
}
