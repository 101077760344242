import {
  Box,
  INPUT_SIZE,
  InputAdaptiveFieldWithPrefix,
  TextInputPrefix,
} from '@npco/zeller-design-system'

import { validateSocialMedia } from 'utils/formValidation'
import { FormLine } from 'components/FormLine'
import { page } from 'translations'

export interface Values {
  facebook: string
  instagram: string
  customerDiscovery: string
  goodsServicesProvided: string
  twitter: string
  website: string
}

interface Props {
  shouldValidate?: boolean
  rowMargin?: string
}

export const AboutBusinessSocialFields = ({
  shouldValidate,
  rowMargin = '40px',
}: Props) => {
  return (
    <>
      <FormLine>
        <Box mb={rowMargin}>
          <InputAdaptiveFieldWithPrefix
            size={INPUT_SIZE.SMALL}
            label={page.settings.receipt.website}
            name="website"
            hasNoSpaceAfterPrefix
          />
        </Box>
      </FormLine>
      <FormLine>
        <Box mb={rowMargin}>
          <InputAdaptiveFieldWithPrefix
            label={page.settings.receipt.instagram}
            name="instagram"
            renderPrefix={() => (
              <TextInputPrefix>
                {page.settings.receipt.instagramPrefix}
              </TextInputPrefix>
            )}
            size={INPUT_SIZE.SMALL}
            validate={shouldValidate ? validateSocialMedia : undefined}
            hasNoSpaceAfterPrefix
          />
        </Box>
      </FormLine>
      <FormLine>
        <Box mb={rowMargin}>
          <InputAdaptiveFieldWithPrefix
            label={page.settings.receipt.facebook}
            name="facebook"
            renderPrefix={() => (
              <TextInputPrefix>
                {page.settings.receipt.facebookPrefix}
              </TextInputPrefix>
            )}
            size={INPUT_SIZE.SMALL}
            validate={shouldValidate ? validateSocialMedia : undefined}
            hasNoSpaceAfterPrefix
          />
        </Box>
      </FormLine>
      <FormLine>
        <Box mb={rowMargin}>
          <InputAdaptiveFieldWithPrefix
            label={page.settings.receipt.twitter}
            name="twitter"
            renderPrefix={() => (
              <TextInputPrefix>
                {page.settings.receipt.twitterPrefix}
              </TextInputPrefix>
            )}
            size={INPUT_SIZE.SMALL}
            validate={shouldValidate ? validateSocialMedia : undefined}
            hasNoSpaceAfterPrefix
          />
        </Box>
      </FormLine>
    </>
  )
}
