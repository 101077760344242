import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { Avatar } from '@npco/ui-avatar'
import { getColorFromResponse } from '@npco/utils-colors'
import { AVATAR_BADGE_SIZE, RadioGroupFormik } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'
import { StyledFormBodyWithOffset } from 'pages/GlobalModals/GlobalModals.styled'

import { TrackScroll } from '../TrackScroll/TrackScroll'
import { AccountNoResultsPage } from './AccountNoResultsPage'
import { SelectAccountRadioGroupDebitCardAccountV2Fragment } from './SelectAccountRadioGroup.generated'
import { AccountRow } from './SelectAccountRadioGroup.styles'

export interface SelectAccountRadioGroupProps {
  accountInfo: SelectAccountRadioGroupDebitCardAccountV2Fragment[]
  searchInput: string
  hasResultsForSearch: boolean
}

const createAccountAvatar =
  (account: SelectAccountRadioGroupDebitCardAccountV2Fragment) => () =>
    (
      <Avatar
        text={account.icon?.letter ?? account.name}
        color={getColorFromResponse(account.icon?.colour)}
        badgeSize={AVATAR_BADGE_SIZE.SMALL}
      />
    )

export const SelectAccountRadioGroup = ({
  accountInfo,
  searchInput,
  hasResultsForSearch,
}: SelectAccountRadioGroupProps) => {
  const accounts = useMemo(
    () =>
      accountInfo.map((account) => {
        const accountBalance = account.balance?.value
        const formattedBalance =
          accountBalance && currencyFormatter(parseInt(accountBalance, 10))

        return (
          <AccountRow
            key={account.id}
            id={account.id}
            value={account.id}
            title={account.name}
            avatar={createAccountAvatar(account)}
            subtitle={formattedBalance}
          />
        )
      }),
    [accountInfo]
  )

  return (
    <StyledFormBodyWithOffset data-testid="select-account-radio" pt="3px">
      {!hasResultsForSearch ? (
        <AccountNoResultsPage searchInput={searchInput} />
      ) : (
        <RadioGroupFormik name="accountId" isVerticalDisplay>
          {accounts}
        </RadioGroupFormik>
      )}
      <TrackScroll searchInput={searchInput} />
    </StyledFormBodyWithOffset>
  )
}

SelectAccountRadioGroup.fragments = {
  DebitCardAccountV2: gql`
    fragment SelectAccountRadioGroupDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      name
      icon {
        colour
        letter
      }
      balance {
        value
      }
    }
  `,
}
