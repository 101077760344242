import {
  BodySmall,
  Box,
  BoxProps,
  ButtonGhost,
  Flex,
  FlexProps,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { ConfirmButton } from 'components/Modal/ModalElements/ModalElements'

export const StyledModalWrapper = styled(Box)<BoxProps>`
  position: relative;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    max-width: 28rem;
  }
`

export const StyledLogoPlaceholder = styled(Flex)<FlexProps>`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  border-radius: 0.25rem;
  height: 100%;
  cursor: pointer;
`

export const StyledText = styled(BodySmall)`
  margin: 0;
`

export const StyledGreyText = styled(StyledText)`
  color: ${({ theme }) => theme.colors.GREY_400};
`

export const StyledBlueText = styled(StyledText)`
  color: ${({ theme }) => theme.colors.BLUE_1000};
`

export const StyledChangeButton = styled(ButtonGhost)`
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-right: 0.25rem;
  }
`

export const StyledConfirmButton = styled(ConfirmButton)`
  && {
    margin-left: 0;
  }
`
