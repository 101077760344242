import { BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Action = styled(BodySmall)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLUE_1000};
`

export const VenueContainer = styled.div`
  margin: 0 -12px;
`
