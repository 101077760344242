import { useInfiniteLoader } from 'hooks/useInfiniteLoader/useInfiniteLoader'

import { useInvoicesContext } from '../../InvoicesContext/InvoicesContext'
import { DEFAULT_GET_LIMIT } from '../InvoiceTable.utils'

export const useInvoiceTableInfiniteLoader = () => {
  const { filterInput, isLoading, nextToken, loadMore, sortingObject } =
    useInvoicesContext()

  return useInfiniteLoader({
    isLoading,
    onLoadMore: async () => {
      await loadMore?.({
        variables: {
          input: {
            limit: DEFAULT_GET_LIMIT,
            filter: filterInput,
            nextToken,
            sort: sortingObject,
          },
        },
      })
    },
    shouldLoadMore: !!nextToken,
  })
}
