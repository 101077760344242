import { BodyDefault, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Label = styled(Heading.H4)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  white-space: nowrap;
`

export const DetailValue = styled(BodyDefault)`
  text-align: right;
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0 0 0 48px;
  word-break: break-all;
`
