import { Status } from '@npco/zeller-design-system'

import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'

import { getTransactionBadgeVariant } from './TransactionStatusBadge.utils'

interface TransactionStatusBadgeProps {
  transaction: Transaction
  hasText?: boolean
}

export const TransactionStatusBadge = ({
  transaction,
  hasText = true,
}: TransactionStatusBadgeProps) => {
  const { text, color } = getTransactionBadgeVariant(transaction)

  return <Status color={color} text={hasText ? text : undefined} />
}
