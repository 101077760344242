import { translate } from 'utils/translations'
import { DEFAULT_AMOUNT } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { formatAmountTriggerLabel } from 'layouts/AccountLayout/utils/formatAmount'
import { FiltersListMobileListItem } from 'components/FiltersList/FiltersListMobileListItem'

import { StyledSelectedValueWrapper } from '../../FilterMobileSelectTrigger/FilterMobileSelectTrigger.styled'

interface Props {
  openPopper: () => void
  amount: { min: number; max: number } | null
}

export const AccountMobileFilterAmountTrigger = ({
  openPopper,
  amount,
}: Props) => {
  const areFiltersDefault = amount === DEFAULT_AMOUNT

  if (!areFiltersDefault && amount) {
    const label = (
      <span data-testid="account-mobile-filter-amount-trigger-value">
        {translate('page.accounts.transactionsList.filters.amount')}{' '}
        <StyledSelectedValueWrapper>
          ({formatAmountTriggerLabel(amount.min, amount.max, true)})
        </StyledSelectedValueWrapper>
      </span>
    )
    return <FiltersListMobileListItem label={label} onClick={openPopper} />
  }

  return (
    <FiltersListMobileListItem
      label={translate('page.accounts.transactionsList.filters.amount')}
      onClick={openPopper}
    />
  )
}
