import { Flex } from '@npco/zeller-design-system'

import {
  Description,
  NotificationWrapper,
  Title,
} from 'components/TransactionDetails/Notification/Notification.styled'

export const Notification = ({ children }: { children: string[] }) => (
  <NotificationWrapper>
    <Flex alignItems="start" data-testid="notification" flexDirection="column">
      {children[1].length !== 0 && (
        <Title data-testid="heading"> {children[0]}</Title>
      )}
      <Description data-testid="text">
        {children[1].length !== 0 ? children[1] : children[0]}
      </Description>
    </Flex>
  </NotificationWrapper>
)
