import { useCallback, useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { preloadImage } from 'hooks/useImagePreloader/useImagePreloader'

import { linkCardImageTranslations } from './LinkCardImage.i18n'
import * as styled from './LinkCardImage.styled'

interface LinkCardImageProps {
  dataTestId: string
  loadingImagePath: string
  cardImagePath: string
}
export const LinkCardImage = ({
  dataTestId,
  loadingImagePath,
  cardImagePath,
}: LinkCardImageProps) => {
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const t = useTranslations(linkCardImageTranslations)
  const loadImage = useCallback(async () => {
    try {
      setIsLoadingImage(true)
      await preloadImage(cardImagePath)
    } finally {
      setIsLoadingImage(false)
    }
  }, [cardImagePath])

  useEffect(() => {
    loadImage()
  }, [cardImagePath, loadImage])

  return (
    <styled.Image
      data-testid={dataTestId}
      src={isLoadingImage ? loadingImagePath : cardImagePath}
      alt={t('cardDisplayAlt')}
    />
  )
}
