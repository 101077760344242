import {
  Animation,
  Box,
  fadeInAnimationVariants,
  Flex,
  InfoBox,
} from '@npco/zeller-design-system'

import ZellerBackCard from 'assets/images/zeller-card-back.png'
import { LinkCardError } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'

interface LinkCardErrorInfoMessageProps {
  linkCardError: LinkCardError | undefined
}

const LinkCardErrorInfoMessage = ({
  linkCardError,
}: LinkCardErrorInfoMessageProps) => {
  return (
    <Animation
      shouldShow={linkCardError === LinkCardError.CardNumberLengthError}
      variants={fadeInAnimationVariants}
    >
      <Box mt="24px">
        <InfoBox>
          <Flex flexDirection={['column', 'row']}>
            <Box mb={['16px', 0]} mr="24px">
              <InfoBox.Header>
                {translate('page.addCardModal.linkCard.cardIdErrorHeading')}
              </InfoBox.Header>
              <InfoBox.Message>
                {translate('page.addCardModal.linkCard.cardIdErrorMessage')}
              </InfoBox.Message>
            </Box>
            <Flex justifyContent="center">
              <img
                data-testid="zeller-back-card-image"
                width="241px"
                src={ZellerBackCard}
                alt={translate(
                  'page.addCardModal.linkCard.cardIDErrorImageAlt'
                )}
              />
            </Flex>
          </Flex>
        </InfoBox>
      </Box>
    </Animation>
  )
}

export default LinkCardErrorInfoMessage
