import { TableHeaderCellIcon } from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { translate } from 'utils/translations'
import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { AllInvoiceTableColumn } from '../InvoiceTable.types'
import { TableColumnPopperWidth } from '../InvoiceTable.utils'

export const InvoiceTitleHeaderCell = ({
  header,
}: HeaderContext<Invoice | null, unknown>) => {
  // NOTE: https://github.com/TanStack/table/discussions/3192
  const size = header.column.getSize()
  const width = size !== 150 ? size : 'auto'

  return (
    <TableHeaderCellIcon
      disablePopper
      forwardedProps={{ style: { padding: 0, width } }}
      key={header.id}
      label={translate('component.invoiceTable.title')}
      popperWidth={TableColumnPopperWidth[AllInvoiceTableColumn.Title]}
    />
  )
}
