import { page } from 'translations'

export const getDevicesText = (numberOfDevices?: number | null) => {
  if (!numberOfDevices) {
    return `0 ${page.settings.sites.devices}`
  }
  if (numberOfDevices === 1) {
    return `${numberOfDevices} ${page.settings.sites.device}`
  }

  return `${numberOfDevices} ${page.settings.sites.devices}`
}
