import { BodySmall, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledDocsInfoWrapper = styled(Flex)<{ isMobile: boolean }>`
  ${(props) => props.isMobile && 'display: none'};

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    display: ${(props) => (props.isMobile ? 'flex' : 'none')};
  }
`

export const StyledInfo = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0 5px;
`
