import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledWrapper } from 'components/Buttons/ActionButtonsGroup/ActionButtonsGroup.styled'

export const StyledButtonsGroupRowWrapper = styled(StyledWrapper)`
  display: flex;
  flex-direction: row;

  > * {
    margin: 0 1rem 0 0;

    &:last-child {
      margin: 0;
      flex: 1;
    }
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    > * {
      &:last-child {
        flex-grow: 0;
      }
    }
  }
`
