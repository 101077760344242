import { ReactNode } from 'react'
import { gql } from '@apollo/client'
import {
  BoxProps,
  CellSize,
  Flex,
  SkeletonBasic,
  TableCellBasic,
  TableCellSize,
  TableCellSkeleton,
} from '@npco/zeller-design-system'

import { useCardStatus } from 'hooks/useCardStatus'
import { ZellerCardThumbnail } from 'components/ZellerCard'

import { CardNameIconCellBaseDebitCardV2Fragment } from './CardNameIconCellBase.generated'
import * as styled from './CardNameIconCellBase.styled'

interface CardNameIconCellBaseProps {
  card?: CardNameIconCellBaseDebitCardV2Fragment
  content: ReactNode
  isLoading?: boolean
  cellId: string
  skeletonPropWidth: BoxProps['width']
  cellSize?: CellSize
  className?: string
}
export const CardNameIconCellBase = ({
  card,
  content,
  isLoading,
  cellId,
  skeletonPropWidth,
  cellSize,
  className,
}: CardNameIconCellBaseProps) => {
  const { isClosed } = useCardStatus(card)

  return (
    <TableCellBasic
      className={className}
      dataTestId={cellId}
      forwardedProps={{ style: { padding: 0 } }}
      isLoading={isLoading}
      justifyContent="flex-start"
      key={cellId}
      size={TableCellSize.MEDIUM}
      cellTextSize="Small"
      cellSize={cellSize ?? { _: '50%', md: '33%' }}
    >
      <styled.CellContainer>
        {isLoading ? (
          <Flex flexDirection="row" gap="16px" alignItems="center">
            <SkeletonBasic width="48px" height="32px" borderRadius="3px" />
            <TableCellSkeleton
              width={skeletonPropWidth}
              size={TableCellSize.MEDIUM}
              cellTextSize="Small"
            />
          </Flex>
        ) : (
          <>
            {card && <ZellerCardThumbnail card={card} isDisabled={isClosed} />}
            {content}
          </>
        )}
      </styled.CellContainer>
    </TableCellBasic>
  )
}

CardNameIconCellBase.fragments = {
  DebitCardV2: gql`
    fragment CardNameIconCellBaseDebitCardV2Fragment on DebitCardV2 {
      ...ZellerCardThumbnailDebitCardV2Fragment
      ...UseCardStatusDebitCardV2Fragment
    }

    ${ZellerCardThumbnail.fragments.DebitCardV2}
    ${useCardStatus.fragments.DebitCardV2}
  `,
}
