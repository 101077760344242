import { useCallback, useState } from 'react'
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  TooltipRenderProps,
} from 'react-joyride'
import { useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  CardAdd,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useGoToCorporateCardWalkthroughStage } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough-routing/hooks/useGoToCorporateCardWalkthroughStage/useGoToCorporateCardWalkthroughStage'

import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'
import { ROOT } from 'const/routes'
import { DEFAULT_JOYRIDE_STYLE_OPTIONS } from 'components/Onboarding/Onboarding'

import { useCorporateCardWalkthroughStage } from '../../../../corporate-card-walkthrough-routing/hooks/useCorporateCardWalkthroughStage/useCorporateCardWalkthroughStage'
import { corporateCardWalkthroughTranslations } from '../../CorporateCardWalkthrough.i18n'
import { useGetCorporateCardWalkthroughSteps } from '../../hooks/useGetCorporateCardWalkthroughSteps/useGetCorporateCardWalkthroughSteps'
import { CorporateCardWalkthroughToolTips } from '../CorporateCardWalkthroughToolTips/CorporateCardWalkthroughToolTips'
import { ModalHeading } from './components/ModalHeading/ModalHeading'
import {
  getStageFromStepIndex,
  shouldRunJoyride,
} from './CorporateCardWalkthroughModal.utils'

export const CorporateCardWalkthroughModal = ({
  firstCardId,
}: {
  firstCardId?: string
}) => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const [stepIndex, setStepIndex] = useState(0)
  const { stage } = useCorporateCardWalkthroughStage()
  const { goToCorporateCardWalkthroughStage } =
    useGoToCorporateCardWalkthroughStage()

  const { updateProductTourStatus } = useCustomerProductTourStatus()
  const { userRole } = useLoggedInUser()
  const isAdmin = userRole === CustomerRole.ADMIN

  const handleCancelOnboarding = useCallback(() => {
    goToCorporateCardWalkthroughStage(undefined)
    updateProductTourStatus({ showCorporateCardsWalkthrough: false })
  }, [goToCorporateCardWalkthroughStage, updateProductTourStatus])

  const t = useTranslations(corporateCardWalkthroughTranslations)

  const steps = useGetCorporateCardWalkthroughSteps()
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? 'medium' : 'large'
  const totalStepNumber = steps.length

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, type } = data
      const shouldHandleNextOrBack =
        type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND

      if (shouldHandleNextOrBack) {
        const newIndex = index + (action === ACTIONS.PREV ? -1 : 1)
        setStepIndex(newIndex)
        if (newIndex === totalStepNumber) {
          goToCorporateCardWalkthroughStage(undefined)
          updateProductTourStatus({ showCorporateCardsWalkthrough: false })
          if (firstCardId && action === ACTIONS.CLOSE) {
            navigate(ROOT.ORGS.ORG(shortEntityId).CARDS.CARD(firstCardId).path)
          }
        } else {
          goToCorporateCardWalkthroughStage(
            getStageFromStepIndex(newIndex, isAdmin)
          )
        }
      }

      const shouldHandleRestart = action === ACTIONS.PREV
      if (shouldHandleRestart) {
        setStepIndex(0)
        goToCorporateCardWalkthroughStage(
          getStageFromStepIndex(stepIndex, isAdmin)
        )
      }
    },
    [
      totalStepNumber,
      goToCorporateCardWalkthroughStage,
      updateProductTourStatus,
      firstCardId,
      navigate,
      shortEntityId,
      isAdmin,
      stepIndex,
    ]
  )

  const CorporateCardWalkthroughToolTipsWithCustomProps = useCallback(
    (props: TooltipRenderProps) => {
      return (
        <CorporateCardWalkthroughToolTips
          {...props}
          totalSteps={totalStepNumber}
          isAdmin={isAdmin}
        />
      )
    },
    [isAdmin, totalStepNumber]
  )

  return (
    <>
      <AnnouncementModal
        Illustration={<CardAdd size={illustrationSize} />}
        heading={<ModalHeading title={t('modalTitle')} />}
        description={t('modalBody')}
        primaryButtonLabel={t('modalPrimaryButton')}
        onClickPrimary={() =>
          goToCorporateCardWalkthroughStage('outstandingExpenses')
        }
        secondaryButtonLabel={t('modalSecondaryButton')}
        onClickSecondary={handleCancelOnboarding}
        onCancel={handleCancelOnboarding}
        isOpen={stage === 'entryModal'}
      />
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        disableScrolling
        run={shouldRunJoyride(stage)}
        stepIndex={stepIndex}
        steps={steps}
        spotlightPadding={0}
        styles={DEFAULT_JOYRIDE_STYLE_OPTIONS}
        tooltipComponent={CorporateCardWalkthroughToolTipsWithCustomProps}
      />
    </>
  )
}
