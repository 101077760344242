import { InputAdaptiveField } from '@npco/zeller-design-system'

import { validateCardNumberLength } from 'utils/formValidation'
import { translate } from 'utils/translations'

export const CardIdField = () => {
  return (
    <InputAdaptiveField
      name="cardId"
      validate={validateCardNumberLength}
      label={translate('page.addCardModal.linkCard.cardIdLabel')}
    />
  )
}
