import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { getContactName } from '@npco/mp-utils-contacts-format'
import { COLOR } from '@npco/zeller-design-system'
import { sumBy } from 'lodash-es'

import { getActiveColorList } from '../../../utils/getActiveColorList'
import { HoveredContactNetAmount } from '../../TransactionsSummaryContact.types'
import { UseGetContactPieChartDataCashFlowContactNetAmountFragment } from './useGetContactPieChartData.generated'

export const CHART_ACTIVE_COLORS = [
  COLOR.BLUE_80,
  COLOR.BLUE_120,
  '#C5DCEC',
  '#A7C9E2',
  '#71A8D0',
  COLOR.BLUE_700,
  COLOR.BLUE_1000,
  '#0055A6',
  '#004075',
  '#002442',
]

interface UseGetContactPieChartDataProps {
  netAmounts: UseGetContactPieChartDataCashFlowContactNetAmountFragment[]
  hoveredContactNetAmount: HoveredContactNetAmount
}

export const useGetContactPieChartData = ({
  netAmounts,
  hoveredContactNetAmount,
}: UseGetContactPieChartDataProps) => {
  const totalAmount = useMemo(
    () => sumBy(netAmounts, (netAmount) => parseInt(netAmount.total.value, 10)),
    [netAmounts]
  )

  const activeNetAmount = useMemo(() => {
    if (hoveredContactNetAmount === undefined) {
      return undefined
    }

    return netAmounts.find(
      (netAmount) =>
        netAmount.contact?.id === hoveredContactNetAmount.contact?.id
    )
  }, [hoveredContactNetAmount, netAmounts])

  const pieChartData = useMemo(() => {
    const colorList = getActiveColorList(netAmounts.length, CHART_ACTIVE_COLORS)
    const percentageTotalAmount = sumBy(netAmounts, (netAmount) =>
      Math.abs(parseInt(netAmount.total.value, 10))
    )

    return netAmounts.map((netAmount, index) => {
      return {
        fill:
          !activeNetAmount ||
          activeNetAmount.contact?.id === netAmount.contact?.id
            ? colorList[index % colorList.length]
            : COLOR.GREY_90,
        name: netAmount.contact ? getContactName(netAmount.contact) ?? '' : '',
        percentage:
          (Math.abs(parseInt(netAmount.total.value, 10)) /
            percentageTotalAmount) *
          100,
      }
    })
  }, [netAmounts, activeNetAmount])

  return { pieChartData, totalAmount, activeNetAmount }
}

useGetContactPieChartData.fragments = {
  CashFlowContactNetAmount: gql`
    fragment UseGetContactPieChartDataCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      contact {
        __typename
        id
        ...GetContactNameContactFragment
      }
      total {
        value
      }
    }

    ${getContactName.fragments.Contact}
  `,
}
