import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseInterestRateDisplayDebitCardAccountV2FragmentDoc } from './hooks/useInterestRateDisplay.generated'

export type SavingsAccountDetailsDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  status: Types.DebitCardAccountStatus
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    bonusRateEndsAt: any | null
    tfnProvided: boolean | null
    baseInterestRate: string | null
    bonusInterestRate: string | null
  } | null
  savingsAccountProduct: {
    __typename?: 'SavingsAccountProduct'
    id: string | null
    interestThreshold: { __typename?: 'Money'; value: string } | null
  } | null
}

export const SavingsAccountDetailsDebitCardAccountV2FragmentDoc = gql`
  fragment SavingsAccountDetailsDebitCardAccountV2Fragment on DebitCardAccountV2 {
    status
    savingsAccountDetails {
      bonusRateEndsAt
      tfnProvided
    }
    savingsAccountProduct {
      id
      interestThreshold {
        value
      }
    }
    ...UseInterestRateDisplayDebitCardAccountV2Fragment
  }
  ${UseInterestRateDisplayDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  SavingsAccountDetailsDebitCardAccountV2Fragment,
  undefined
>
