import {
  RadioOutlineCard,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import * as styled from './FieldOption.styled'

interface FieldOptionProps {
  id: string
  title: string
  icon: () => JSX.Element
  value: string
  isComingSoon?: boolean
}

export const FieldOption = ({
  id,
  value,
  title,
  icon,
  isComingSoon = false,
}: FieldOptionProps) => {
  const isMobile = useIsMobileResolution()
  const [field, , helpers] = useField({ name: value })

  return (
    <RadioOutlineCard
      checked={field.value}
      id={id}
      title={
        <styled.TitleWrapper as="span" maxWidth={{ XS: '100px' }}>
          {title}
        </styled.TitleWrapper>
      }
      value={value}
      name={value}
      icon={icon}
      details={false}
      stackDirection={isMobile ? 'horizontal' : 'vertical'}
      disabled={isComingSoon}
      type="checkbox"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(event.target.checked)
      }}
      marginBottom="0"
    />
  )
}
