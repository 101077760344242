import { gql } from '@apollo/client'
import { SelectSearchInput } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'
import { GetDebitCardAccountsForCardCreationQueryResponse } from 'pages/GlobalModals/AddCardModal/graphql/accounts.generated'
import { StyledFormBodyWithOffset } from 'pages/GlobalModals/GlobalModals.styled'

import { ErrorContent } from '../ErrorContent/ErrorContent'
import { NoAccountsForSelectAccountForm } from '../NoAccountsForSelectAccountForm/NoAccountsForSelectAccountForm'
import { SelectAccountRadioGroup } from '../SelectAccountRadioGroup/SelectAccountRadioGroup'
import { SelectAccountSkeleton } from '../SelectAccountSkeleton/SelectAccountSkeleton'
import { useSearchAccounts } from './hooks/useSearchAccounts'
import { SelectSearchInputWrapper } from './SelectAccountRadioGroupWithSearch.styled'

type AccountInfo = NonNullable<
  GetDebitCardAccountsForCardCreationQueryResponse['getDebitCardAccountsV2']['accounts'][0]
>

export interface SelectAccountRadioGroupProps {
  accountInfo: AccountInfo[]
  loading: boolean
  hasError: boolean
  refetch: () => void
}

export const SelectAccountRadioGroupWithSearch = ({
  accountInfo,
  loading,
  hasError,
  refetch,
}: SelectAccountRadioGroupProps) => {
  const isMobile = useIsMobileResolution()
  const { searchInput, setSearchInput, filteredAccounts } =
    useSearchAccounts(accountInfo)

  const hasNoAccounts =
    !loading && !hasError && accountInfo && !accountInfo.length
  const hasResultsForSearch = Boolean(filteredAccounts.length)

  if (hasError) {
    return (
      <ErrorContent
        title={translate('page.addCardModal.selectAccount.errorTitle')}
        subtitle={translate('page.addCardModal.selectAccount.errorSubtitle')}
        refetch={refetch}
      />
    )
  }

  if (hasNoAccounts) {
    return <NoAccountsForSelectAccountForm />
  }

  return (
    <>
      <StyledFormBodyWithOffset canScroll={false}>
        <SelectSearchInputWrapper>
          <SelectSearchInput
            value={searchInput}
            setValue={setSearchInput}
            hasBgColor={isMobile}
            isClearable
            placeholder={translate(
              'page.addCardModal.selectAccount.searchPlaceholder'
            )}
          />
        </SelectSearchInputWrapper>
      </StyledFormBodyWithOffset>
      {loading ? (
        <SelectAccountSkeleton />
      ) : (
        <SelectAccountRadioGroup
          accountInfo={filteredAccounts}
          searchInput={searchInput}
          hasResultsForSearch={hasResultsForSearch}
        />
      )}
    </>
  )
}

SelectAccountRadioGroupWithSearch.fragments = {
  DebitCardAccountV2: gql`
    fragment SelectAccountRadioGroupWithSearchDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status

      ...SelectAccountRadioGroupDebitCardAccountV2Fragment
    }

    ${SelectAccountRadioGroup.fragments.DebitCardAccountV2}
  `,
}
