import { Box } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
`

export const StyledNavbar = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 2;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    background: ${({ theme }) => theme.colors.WHITE};
    box-sizing: border-box;
  }
`

export const StyledContentWrapper = styled.main`
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 512px;
  width: 100%;
  margin: auto;

  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.MD -
    1}px) {
    padding: 0 24px;
    max-width: 560px;
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    padding: 6rem 1rem 1rem;
    box-sizing: border-box;
    min-height: 100%;
    max-width: unset;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
  }
`

export const StyledAsideBox = styled(Box)<{
  as: string
  backgroundUrl?: string
}>`
  ${({ backgroundUrl }) =>
    backgroundUrl && `background: url(${backgroundUrl}) 0 0 / cover`};
  background-color: ${({ theme }) => theme.colors.GREY_150};
  width: 33%;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    display: none;
  }
`

export const StyledAuthLayoutSignUpMarketingCampaignContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 1rem 1rem;
  box-sizing: border-box;
  max-width: unset;
  width: 100%;
  overflow: auto;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    max-width: 512px;
    margin: auto;
  }
  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    max-width: 560px;
    margin: auto;
  }
`
