import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const SaveAndSendInvoice = gql`
  ${InvoiceCoreFields}
  mutation SaveAndSendInvoice(
    $entityUuid: ID!
    $invoice: SaveAndSendInvoiceInput!
  ) {
    saveAndSendInvoice(entityUuid: $entityUuid, input: $invoice) {
      ...InvoiceCoreFields
    }
  }
`
