import { type LocalStateOraclePos } from './oraclePos.types'

export const OracleDefaultLocalState: LocalStateOraclePos = {
  isOverridePairingModalOpen: false,
  newDeviceUuid: '',
  newDeviceName: '',
  currentDeviceUuid: '',
  currentDeviceName: '',
  workstationId: '',
}
