import {
  DURATION,
  EASING_FUNCTION,
  Flex,
  getTransition,
} from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import invoiceImage from 'assets/images/invoice-image.png'
import { ImagePreview } from 'components/ImagePreview/ImagePreview'

const boxShadowCommon = css`
  box-shadow: rgba(223, 225, 230, 0.35) 0px 6px 18px 3px,
    rgba(0, 0, 0, 0.12) 0px 0px 1px 0px;
`

export const InvoicePreviewSpinnerWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  ${boxShadowCommon};
`

export const InvoicePreviewFallback = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  text-align: center;
  :before {
    content: '';
    background: url(${invoiceImage}) 0 0 / cover;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    ${boxShadowCommon};
  }
`

export const InvoicePdfActionsWrapper = styled(Flex)`
  display: flex;
  gap: 24px;
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: ${({ theme: { colors } }) => rgba(colors.BLACK_900, 0.75)};
  border-radius: 3px;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity ${getTransition(DURATION.FAST, EASING_FUNCTION.LINEAR)};
  z-index: 1;

  &:hover {
    opacity: 1;
  }
`

export const InvoicePdf = styled(ImagePreview)<{ $isLoading?: boolean }>`
  margin: 0 auto;

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  .react-pdf__Page {
    ${boxShadowCommon}
  }

  transition: opacity ${getTransition(DURATION.FAST, EASING_FUNCTION.LINEAR)};
`

export const LoaderWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`
