import { Fragment, useCallback, useMemo } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { flexRender, Row } from '@tanstack/react-table'

interface TableRowProps<T> {
  hoveredRowIndex?: number
  isTableLoading: boolean
  onMouseEnter: (row: Row<T>, rowIndex: number) => void
  onMouseLeave: (row: Row<T>) => void
  onRowClick?: (row: Row<T>) => void
  row: Row<T>
  rowIndex: number
  isDraggable: boolean
}

export const TableRow = <T,>({
  row,
  rowIndex,
  isTableLoading,
  hoveredRowIndex,
  onMouseEnter,
  onMouseLeave,
  onRowClick: handleRowClick,
  isDraggable,
}: TableRowProps<T>) => {
  const loadingHandler = useCallback(
    (loading: boolean, cbFn: () => void) => (loading ? undefined : cbFn),
    []
  )

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: row.id,
    disabled: !isDraggable,
  })

  const draggableProps = useMemo(() => {
    if (!listeners) {
      return {}
    }

    return {
      ref: setNodeRef,
      style: {
        cursor: isDragging ? 'grabbing' : 'grab',
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
      },
      ...attributes,
      ...listeners,
    }
  }, [attributes, isDragging, listeners, setNodeRef, transform, transition])

  return (
    <tr
      className="table-row"
      data-testid={`row-${rowIndex}`}
      onClick={loadingHandler(isTableLoading, () => handleRowClick?.(row))}
      onMouseEnter={loadingHandler(isTableLoading, () =>
        onMouseEnter(row, rowIndex)
      )}
      onMouseLeave={loadingHandler(isTableLoading, () => onMouseLeave(row))}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...draggableProps}
    >
      {row.getVisibleCells().map((cell) => {
        const tableCellProps = {
          hoveredRowIndex,
        }
        return (
          <Fragment key={cell.id}>
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              ...tableCellProps,
            })}
          </Fragment>
        )
      })}
    </tr>
  )
}
