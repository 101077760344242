import { useCallback, useEffect, useState } from 'react'

import { statusItems } from 'layouts/AccountLayout/filters/AccountFilterStatus/AccountFilterStatus.constants'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { useAccountMultiselectWithAllElement } from 'layouts/AccountLayout/hooks/useAccountMultiselectWithAllElement/useAccountMultiselectWithAllElement'
import { page } from 'translations'

import { useMobileFilterButtonActions } from '../hooks/useMobileFilterButtonActions/useMobileFilterButtonActions'
import { MultiSelectMobileFilter } from '../MultiSelectMobileFilter/MultiSelectMobileFilter'

export const AccountMobileFilterStatus = () => {
  const { status, setStatus, resetStatus } = useAccountFilters()
  const [localStatus, setLocalStatus] = useState(status)

  const resetLocalData = useCallback(() => {
    setLocalStatus(DEFAULT_FILTERS_VALUES.status)
  }, [])

  const {
    listItems,
    selectedItems,
    onChange,
    onClose,
    areAllItemsSelected,
    setNoElementsSelected,
  } = useAccountMultiselectWithAllElement({
    data: localStatus || [],
    setData: setLocalStatus,
    dataItems: statusItems,
  })

  const { onApply, onReset, onCancel } = useMobileFilterButtonActions({
    onClose,
    globalData: status,
    setGlobalData: setStatus,
    resetGlobalData: resetStatus,
    localData: localStatus,
    setLocalData: setLocalStatus,
    resetLocalData,
    setNoElementsSelected,
  })

  useEffect(() => {
    setLocalStatus(status)
  }, [status])

  return (
    <MultiSelectMobileFilter
      items={listItems}
      values={status || []}
      selectedItems={selectedItems}
      onChange={onChange}
      onApply={onApply}
      onReset={onReset}
      onCancel={onCancel}
      areAllItemsSelected={areAllItemsSelected}
      filterName={page.accounts.transactionsList.filters.status}
    />
  )
}
