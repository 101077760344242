import { useTranslations } from '@npco/utils-translations'

import { ContactZellerSupport } from 'components/ContactZellerSupport'

import { ErrorDisplay } from '../../ErrorDisplay'
import { translations } from './YourCardsListError.i18n'

interface YourCardsListErrorProps {
  retry?: () => void
}

export const YourCardsListError = ({ retry }: YourCardsListErrorProps) => {
  const t = useTranslations(translations)

  return (
    <ErrorDisplay
      title={t('title')}
      description={t('description', {
        contactZellerSupport: <ContactZellerSupport />,
      })}
      retry={retry}
    />
  )
}
