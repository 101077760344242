import { BodyDefault, ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Statement = styled(BodyDefault)`
  margin: 0;
`
export const RemoveConnectionButton = styled(ButtonLink)`
  font-size: 1.125rem;
  line-height: 1.55;
`
