import {
  BREAKPOINT,
  ButtonLink,
  DataSmallTypography,
  Flex,
  Heading,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceDiscountErrorText = styled(Flex)`
  ${DataSmallTypography}
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.RED_1000};
  padding-top: 4px;
  text-align: right;
  width: 309px;
`

export const InvoiceDiscountSubtotalText = styled.div`
  ${DataSmallTypography}
  ${InterZellerMediumFont}
  height: 20px;
  text-align: right;
`

export const InvoiceDiscountSubtotalValue = styled(InvoiceDiscountSubtotalText)`
  min-width: 70px;
`

export const InvoiceDiscountTotalText = styled(Heading.H4)<{
  $hasError: boolean
}>`
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.colors.RED_1000 : 'inherit'};
  padding-top: 2px;
  text-align: right;
`

export const InvoiceDiscountTotalValue = styled(Heading.H4)<{
  $hasError: boolean
}>`
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.colors.RED_1000 : 'inherit'};
  text-align: right;
  min-width: 70px;
  padding-top: 2px;
`

export const InvoiceDiscountResetDiscount = styled(ButtonLink)`
  justify-content: flex-start;
  height: auto;
  padding: 0;
  order: 2;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    align-self: flex-end;
    order: unset;
  }
`
