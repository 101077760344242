import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type SubContactCoreFieldsFragment = {
  __typename: 'Contact'
  abn: string | null
  acn: string | null
  businessName: string | null
  contactType: Types.ContactType
  firstName: string | null
  id: string
  jobTitle: string | null
  lastName: string | null
  phone: string | null
  website: string | null
  location: string | null
  locationAccuracy: number | null
  category: Types.EntityCategories | null
  subcategory: string | null
  subcategoryUuid: string | null
  additionalEmails: Array<{
    __typename?: 'ContactEmail'
    email: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  }> | null
  additionalPhones: Array<{
    __typename?: 'ContactPhone'
    phone: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  }> | null
  address: {
    __typename?: 'ContactAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street: string | null
    suburb: string | null
  } | null
  email: {
    __typename?: 'ContactEmail'
    email: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  } | null
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  phoneV2: {
    __typename?: 'ContactPhone'
    phone: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  } | null
}

export const SubContactCoreFieldsDoc = gql`
  fragment SubContactCoreFields on Contact {
    __typename
    abn
    acn
    additionalEmails {
      email
      label {
        id
        isEditable
        labelText
        type
      }
    }
    additionalPhones {
      phone
      label {
        id
        isEditable
        labelText
        type
      }
    }
    address {
      country
      postcode
      state
      street
      suburb
    }
    businessName
    contactType
    email {
      email
      label {
        id
        isEditable
        labelText
        type
      }
    }
    firstName
    icon {
      colour
      images {
        size
        url
      }
      letter
    }
    id
    jobTitle
    lastName
    phone
    phoneV2 {
      phone
      label {
        id
        isEditable
        labelText
        type
      }
    }
    website
    location
    locationAccuracy
    category
    subcategory
    subcategoryUuid
  }
` as unknown as TypedDocumentNode<SubContactCoreFieldsFragment, undefined>
