import { ChevronLeftIcon } from '@npco/zeller-design-system'

import { StyledNavButton } from './ChartXAxisTick.styled'

type LeftNavButtonProps = {
  handleSelectPrevious?: () => void
}

export const LeftNavButton = ({ handleSelectPrevious }: LeftNavButtonProps) => {
  return (
    <StyledNavButton
      data-testid="previous-nav-button"
      aria-label="previous-nav-button"
      disabled={!handleSelectPrevious}
      onClick={handleSelectPrevious}
      type="button"
    >
      <ChevronLeftIcon width="12" height="12" />
    </StyledNavButton>
  )
}
