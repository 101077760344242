import {
  Box,
  MagnifyingGlassNoResults,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { page } from 'translations'

export const NoPayments = () => {
  return (
    <Box textAlign="center">
      <MessageWithIcon
        icon={<MagnifyingGlassNoResults size="medium" />}
        iconBackgroundColor={null}
        title={page.dashboard.transactions.payments.noResults.title}
        description={page.dashboard.transactions.payments.noResults.description}
      />
    </Box>
  )
}
