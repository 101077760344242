import { matchPath, useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { rvHasSeenPushAppModal } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'

import { useIsMobileDevice } from './useIsMobileDevice'

export const usePushAppModal = () => {
  const { isMobileDevice } = useIsMobileDevice()
  const location = useLocation()
  const hasSeen = useReactiveVar(rvHasSeenPushAppModal)
  const isCorrectPath = Boolean(
    matchPath(ROOT.ORGS.ORG().OVERVIEW.PAYMENTS.path, location.pathname)
  )

  return { shouldShowPushAppModal: isMobileDevice && isCorrectPath && !hasSeen }
}
