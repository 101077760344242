import {
  InvoiceAction,
  InvoiceActivityStatus,
  InvoiceStatus,
} from '@npco/mp-gql-types'

import { InvoiceDetailsFragment_activities as Activity } from 'types/gql-types/InvoiceDetailsFragment'

export enum InvoiceDecisionModalTypes {
  CancelInvoice,
  DeleteInvoice,
  MarkAsPaid,
  RecordPayment,
  ResendInvoice,
  SendInvoice,
  SendNow,
  SendReminder,
  SendSuccess,
}

export const getAllLatestSendInvoiceActivities = (
  activities: Activity[] | null
) => {
  return (
    Array.from(activities ?? [])
      // NOTE: reverse to make it easier to find the first instance of
      // completed and failed send activities
      .reverse()
      .filter(({ type }) =>
        [
          InvoiceAction.SEND_EMAIL_INVOICE,
          InvoiceAction.SEND_INVOICE,
          InvoiceAction.SEND_SMS_INVOICE,
        ].includes(type)
      )
  )
}

export const getLatestSendInvoiceActivity = (activities: Activity[] | null) => {
  const activity = getAllLatestSendInvoiceActivities(activities).filter(
    (activity) => activity.status === InvoiceActivityStatus.COMPLETED
  )[0]

  return activity
}

export const getInvoiceActionOnPartFailedSend = (
  invoiceStatus: InvoiceStatus,
  activities: Activity[] | null
) => {
  if (
    [
      InvoiceStatus.CANCELLED,
      InvoiceStatus.DELETED,
      InvoiceStatus.DRAFT,
      InvoiceStatus.ERROR,
      InvoiceStatus.PAID,
      InvoiceStatus.SCHEDULED,
    ].includes(invoiceStatus)
  ) {
    return null
  }

  const allSendInvoiceActivities = getAllLatestSendInvoiceActivities(activities)

  if (
    !allSendInvoiceActivities.length ||
    // NOTE: ignore if the last send activity is completed
    allSendInvoiceActivities[0].status === InvoiceActivityStatus.COMPLETED
  ) {
    return null
  }

  // NOTE: find the first instance of send failed activity in a reversed list
  const failedActivity = allSendInvoiceActivities.find(
    (activity) => activity.status === InvoiceActivityStatus.FAILED
  )

  return failedActivity
}
