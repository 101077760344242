import { IllustrationProps } from '@npco/zeller-design-system'

export const ZellerSavingsAccount = ({
  size,
  ariaLabel = '',
}: Omit<IllustrationProps, 'variant'>) => {
  const dimension = size === 'large' ? '180px' : '140px'

  return (
    <svg
      width={dimension}
      height={dimension}
      data-testid="zeller-savings-account-illustration"
      viewBox="0 0 180 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{ariaLabel}</title>
      <path
        d="M45.225 96.9351C45.225 96.9751 45.23 96.9951 45.245 97.0401C45.175 97.0601 45.1 97.0951 45.03 97.1201L45.225 96.9351Z"
        fill="#FFC4A6"
      />
      <path
        d="M95.7527 120.233L48.3577 94.9927C42.5127 91.8827 40.2977 84.6127 43.4077 78.7677L68.6577 31.3577C71.7677 25.5127 79.0377 23.2977 84.8827 26.4077L132.278 51.6477C138.123 54.7577 140.353 62.0327 137.243 67.8777L111.993 115.288C108.878 121.138 101.598 123.348 95.7527 120.233Z"
        fill="#77BB66"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.28 51.6464L117 43.5L131.381 78.886L137.245 67.8756C140.355 62.0309 138.125 54.7563 132.28 51.6464Z"
        fill="#337654"
      />
      <path
        d="M100.811 61.0741L79.4236 101.237L85.7607 104.611L107.148 64.4487L100.811 61.0741Z"
        fill="white"
      />
      <path
        d="M94.4253 45.4271L73.038 85.5898L79.375 88.9644L100.762 48.8017L94.4253 45.4271Z"
        fill="white"
      />
      <path
        d="M118.115 132.23C117.435 128.08 116.675 123.905 114.515 120.305C113.575 118.73 112.695 117.25 111.895 115.885C108.04 109.43 105.485 105.355 102.455 103.855C97.3201 101.315 93.9651 100.425 89.1901 98.1851C88.6651 97.9451 88.1301 97.6951 87.5851 97.4251C86.0551 96.6701 83.7801 96.1651 82.9001 97.4951C82.8251 97.5701 82.7751 97.6601 82.7351 97.7701C82.4701 100.06 83.8751 102.455 85.8251 104.505C87.5701 106.335 89.7751 107.87 91.6301 108.765C93.1401 109.49 96.7801 113.075 96.6201 114.85C89.3501 114.805 82.3051 112.41 75.8751 108.99C74.7551 108.395 73.6551 107.77 72.5851 107.12C65.2701 102.71 58.6951 97.1601 51.8451 92.0551C50.4451 91.0201 48.7251 89.9351 47.0651 90.4651C46.3201 90.7201 45.7351 91.2701 45.3501 91.9601C44.8801 92.8001 44.7101 93.8401 44.8701 94.8051C44.9801 95.4951 45.0751 96.2151 45.2301 96.9301C45.2301 96.9701 45.2351 96.9901 45.2501 97.0351C45.1801 97.0551 45.1051 97.0901 45.0351 97.1151C43.0851 97.9701 41.4301 99.7901 41.4801 101.88C41.5251 103.13 41.8701 104.635 42.3801 106.05C40.2701 110.725 44.2451 115.445 47.3801 119.585C55.3751 130.08 64.1601 138.605 76.2651 143.865C81.7501 146.25 87.5551 148.46 91.6701 152.815L91.9051 152.805L91.9201 152.815C91.8201 167.27 90.7101 170.105 89.7551 179.735C89.7551 179.735 101.255 179.235 107.255 178.245C113.26 177.25 124.75 173.245 124.75 173.245C121.605 148.75 121.76 154.745 118.115 132.23Z"
        fill="#FFC4A6"
      />
    </svg>
  )
}
function getIllustrationDimensions(size: string): { width: any; height: any } {
  throw new Error('Function not implemented.')
}
