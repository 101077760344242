import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AverageCellCashFlowContactNetAmountFragmentDoc } from '../../TableCells/TableRowCells/AverageCell/AverageCell.generated'
import { ContactCellCashFlowContactNetAmountFragmentDoc } from '../../TableCells/TableRowCells/ContactCell/ContactCell.generated'
import { QuantityCellCashFlowContactNetAmountFragmentDoc } from '../../TableCells/TableRowCells/QuantityCell/QuantityCell.generated'
import { TotalCellCashFlowContactNetAmountFragmentDoc } from '../../TableCells/TableRowCells/TotalCell/TotalCell.generated'

export type ContactsTableCashFlowContactNetAmountFragment = {
  __typename: 'CashFlowContactNetAmount'
  noOfTransaction: number
  change: number | null
  average: { __typename?: 'Money'; currency: string; value: string }
  total: { __typename?: 'Money'; currency: string; value: string }
  contact: {
    __typename: 'Contact'
    id: string
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    lastName: string | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
}

export const ContactsTableCashFlowContactNetAmountFragmentDoc = gql`
  fragment ContactsTableCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    __typename
    ...QuantityCellCashFlowContactNetAmountFragment
    ...AverageCellCashFlowContactNetAmountFragment
    ...TotalCellCashFlowContactNetAmountFragment
    ...ContactCellCashFlowContactNetAmountFragment
  }
  ${QuantityCellCashFlowContactNetAmountFragmentDoc}
  ${AverageCellCashFlowContactNetAmountFragmentDoc}
  ${TotalCellCashFlowContactNetAmountFragmentDoc}
  ${ContactCellCashFlowContactNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  ContactsTableCashFlowContactNetAmountFragment,
  undefined
>
