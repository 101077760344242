import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  DragAndDropTabs,
  PlusIcon,
  TabItemType,
  TopBar,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { topBarNavTranslations } from 'components/TopBarNav/TopBarNav.i18n'

import { TopBarUserMenu } from '../TopBarUserMenu/TopBarUserMenu'

interface DesktopTopBarNavProps {
  hasLeftSection?: boolean
  entities: TabItemType[]
  activeEntityId: TabItemType['id'] | null
  reorderEntities: (newEntities: TabItemType[]) => void
  setSelectedEntityId: (id: TabItemType['id']) => void
  closeEntitySession: (id: TabItemType['id']) => void
  goToManageBusinessesPage: () => void
  isManageBusinessesPage: boolean
}
export const DesktopTopBarNav = ({
  hasLeftSection,
  entities,
  activeEntityId,
  reorderEntities,
  setSelectedEntityId,
  closeEntitySession,
  goToManageBusinessesPage,
  isManageBusinessesPage,
}: DesktopTopBarNavProps) => {
  const isMobileResolution = useIsMobileResolution()

  const t = useTranslations(topBarNavTranslations)

  if (isMobileResolution) {
    return null
  }

  return (
    <TopBar>
      {hasLeftSection && (
        <TopBar.LeftSection isFolded={false} hasBorderRight={false} />
      )}
      <TopBar.CentreSection>
        <DragAndDropTabs
          tabs={entities}
          activeTabId={activeEntityId ?? ''}
          onTabsChange={reorderEntities}
          handleSelectTab={setSelectedEntityId}
          handleTabClose={closeEntitySession}
        />
      </TopBar.CentreSection>
      <TopBar.RightSection>
        <TopBar.TopBarButton
          component="button"
          activeClassName={TopBar.TopBarButton.activeClassName}
          onClick={goToManageBusinessesPage}
          aria-label={t('goToManageBusinessesPage')}
          isActive={isManageBusinessesPage}
        >
          <PlusIcon height="12" width="12" color={COLOR.BLACK_900} />
        </TopBar.TopBarButton>
        <TopBarUserMenu />
      </TopBar.RightSection>
    </TopBar>
  )
}
