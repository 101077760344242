import { KYCData } from 'types/kyc'
import { OnboardingDataSummary } from 'types/onboarding'

export const convertOnboardingDataToKyc = (
  prevKycData: KYCData,
  onboardingData: OnboardingDataSummary
) => {
  const { kycInitialData } = onboardingData
  return {
    ...prevKycData,
    yourInformation: {
      ...prevKycData.yourInformation,
      firstName:
        kycInitialData?.personalData?.firstName ??
        prevKycData.yourInformation?.firstName ??
        '',
      middleName:
        kycInitialData?.personalData?.middleName ??
        prevKycData.yourInformation?.middleName ??
        '',
      lastName:
        kycInitialData?.personalData?.lastName ??
        prevKycData.yourInformation?.lastName ??
        '',
      dob:
        kycInitialData?.personalData?.dob ??
        prevKycData.yourInformation?.dob ??
        '',
      street:
        kycInitialData?.personalData?.street ??
        prevKycData.yourInformation?.street ??
        '',
      suburb:
        kycInitialData?.personalData?.suburb ??
        prevKycData.yourInformation?.suburb ??
        '',
      state:
        kycInitialData?.personalData?.state ??
        prevKycData.yourInformation?.state ??
        '',
      postcode:
        kycInitialData?.personalData?.postcode ??
        prevKycData.yourInformation?.postcode ??
        '',
    },
  }
}
