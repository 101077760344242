import { BREAKPOINT, RadioOutlineCardBrand } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

export const TransparentButton = styled.button.attrs({ type: 'button' })`
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  text-align: left;
`

export const StyledRadioOutlineCardBrand = styled(RadioOutlineCardBrand)`
  border: none;
  margin: 0;
  box-sizing: border-box;
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    padding: 24px 16px;
  }
`

export const StyledInfoIcon = styled(InfoIcon)`
  path {
    fill: ${({ theme }) => theme.colors.RED_1000};
  }
`
