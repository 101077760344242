import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Animation,
  Box,
  ErrorMessageForm,
  Flex,
  InputSelectComboboxStandard,
  slideDownAnimationVariants,
  ToggleForm,
} from '@npco/zeller-design-system'
import { Field, useField, useFormikContext } from 'formik'

import {
  INVOICE_SMS_ENABLED_FIELD,
  INVOICE_SMS_RECIPIENT_FIELD,
} from '../../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../../Invoice.types'
import { usePhoneSelectCombobox } from '../../../../hooks/usePhoneSelectCombobox'
import {
  DeliveryTitle,
  DeliveryTitleWrapper,
  SmsDescriptionWrapper,
} from '../../InvoiceDeliveryAccordion.styled'
import { InvoiceSelectCustomerInfobox } from '../InvoiceSelectCustomerInfobox/InvoiceSelectCustomerInfobox'
import { translations } from './InvoiceDeliverySMS.i18n'

type InvoiceDeliverySMSProps = {
  onEditCustomerClick: () => void
}

export const InvoiceDeliverySMS = ({
  onEditCustomerClick,
}: InvoiceDeliverySMSProps) => {
  const t = useTranslations(translations)

  const {
    values: {
      customer: { payerContact },
      delivery: { sms: smsValues },
    },
  } = useFormikContext<InvoiceFormFields>()
  const [field, meta, helpers] = useField({
    name: INVOICE_SMS_RECIPIENT_FIELD,
  })

  const { filteredItems, renderComboboxItem } = usePhoneSelectCombobox(
    field.value,
    [smsValues.recipient]
  )

  const payerSMSError = meta.touched ? meta.error : ''

  const handleOnInputClear = useCallback(() => helpers.setValue(''), [helpers])

  const handleOnInputChange = useCallback(
    (changes) => {
      helpers.setValue(changes.inputValue)
    },
    [helpers]
  )

  const handleOnChange = useCallback(
    (changes) => helpers.setValue(changes.selectedItem?.value),
    [helpers]
  )

  return (
    <div>
      <Flex
        gridGap="12px"
        alignItems={{ _: 'flex-start', XS: 'center' }}
        flexDirection={{ _: 'column', XS: 'row' }}
      >
        <DeliveryTitleWrapper>
          <Field
            component={ToggleForm}
            name={INVOICE_SMS_ENABLED_FIELD}
            value={smsValues.isEnabled}
          />
          <DeliveryTitle>{t('title')}</DeliveryTitle>
        </DeliveryTitleWrapper>
        {smsValues.isEnabled ? (
          <InvoiceSelectCustomerInfobox
            onEditCustomerClick={onEditCustomerClick}
          />
        ) : (
          <SmsDescriptionWrapper $isSendBySmsEnabled>
            {t('details')}
          </SmsDescriptionWrapper>
        )}
      </Flex>
      <Animation
        disableInitialAnimation
        shouldShow={Boolean(smsValues.isEnabled && payerContact)}
        variants={slideDownAnimationVariants}
      >
        <Box
          display="grid"
          pt="24px"
          gridGap="20px"
          gridTemplateColumns={{
            _: 'none',
            XS: 'repeat(2, 1fr)',
            LG: 'repeat(3, 1fr)',
          }}
        >
          <div>
            <InputSelectComboboxStandard
              hasError={Boolean(payerSMSError)}
              inputValue={field.value}
              items={filteredItems}
              label={t('label')}
              name={field.name}
              onBlur={field.onBlur}
              onChange={handleOnChange}
              onInputChange={handleOnInputChange}
              onInputClear={handleOnInputClear}
              selectedItem={undefined}
              renderItem={renderComboboxItem}
            />
            <ErrorMessageForm
              errorMessage={payerSMSError}
              hasError={Boolean(payerSMSError)}
            />
          </div>
        </Box>
      </Animation>
    </div>
  )
}
