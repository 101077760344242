import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type HasActiveSavingsAccountQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type HasActiveSavingsAccountQueryResponse = {
  __typename?: 'Query'
  getSavingsAccounts: Array<{
    __typename?: 'DebitCardAccountV2'
    id: string
    status: Types.DebitCardAccountStatus
  } | null>
}

export const HasActiveSavingsAccountQuery = gql`
  query HasActiveSavingsAccountQuery($entityUuid: ID!) {
    getSavingsAccounts(entityUuid: $entityUuid) {
      id
      status
    }
  }
` as unknown as TypedDocumentNode<
  HasActiveSavingsAccountQueryResponse,
  HasActiveSavingsAccountQueryVariables
>

/**
 * __useHasActiveSavingsAccountQuery__
 *
 * To run a query within a React component, call `useHasActiveSavingsAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasActiveSavingsAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasActiveSavingsAccountQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useHasActiveSavingsAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasActiveSavingsAccountQueryResponse,
    HasActiveSavingsAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HasActiveSavingsAccountQueryResponse,
    HasActiveSavingsAccountQueryVariables
  >(HasActiveSavingsAccountQuery, options)
}
export function useHasActiveSavingsAccountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasActiveSavingsAccountQueryResponse,
    HasActiveSavingsAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HasActiveSavingsAccountQueryResponse,
    HasActiveSavingsAccountQueryVariables
  >(HasActiveSavingsAccountQuery, options)
}
export type HasActiveSavingsAccountQueryHookResult = ReturnType<
  typeof useHasActiveSavingsAccountQuery
>
export type HasActiveSavingsAccountQueryLazyQueryHookResult = ReturnType<
  typeof useHasActiveSavingsAccountQueryLazyQuery
>
export type HasActiveSavingsAccountQueryQueryResult = Apollo.QueryResult<
  HasActiveSavingsAccountQueryResponse,
  HasActiveSavingsAccountQueryVariables
>
