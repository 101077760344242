import { SplitPaymentType, TransactionStatus } from '@npco/mp-gql-types'

import { Transaction } from 'types/transactions'

export const getRemainingAmount = (transaction: Transaction) => {
  const transactionSaleAmount =
    transaction.status === TransactionStatus.APPROVED
      ? transaction.saleAmount
      : 0
  const targetAmount = transaction?.splitPayment?.targetAmount || 0

  const relatedTransactionsSaleAmount =
    transaction?.splitPayment?.transactions.reduce(
      (accumulator, transaction) => {
        if (transaction.status === TransactionStatus.APPROVED)
          return accumulator + transaction.saleAmount
        return accumulator
      },
      0
    ) || 0

  return targetAmount - (transactionSaleAmount + relatedTransactionsSaleAmount)
}

type SplitPaymentTypeDisplayValueMapProps = {
  [key in SplitPaymentType]: string
}

const splitPaymentTypeDisplayValueMap: SplitPaymentTypeDisplayValueMapProps = {
  [SplitPaymentType.AMOUNT]: 'Amount',
  [SplitPaymentType.ITEMS]: 'Items',
  [SplitPaymentType.PORTION]: 'People',
}

type GetSplitPaymentTypeDisplayValueProps = {
  portions: number | null | undefined
  type: SplitPaymentType | null | undefined
}

export const getSplitPaymentTypeDisplayValue = ({
  portions,
  type,
}: GetSplitPaymentTypeDisplayValueProps) => {
  if (!type) {
    return '-'
  }
  if (type === SplitPaymentType.PORTION) {
    return `${portions} ${
      splitPaymentTypeDisplayValueMap[SplitPaymentType.PORTION]
    }`
  }
  return splitPaymentTypeDisplayValueMap[type]
}
