import { ReviewCardStage } from './ReviewCardStage/ReviewCardStage'
import { SelectAccountStage } from './SelectAccountStage/SelectAccountStage'
import { SelectCardDesignStage } from './SelectCardDesignStage/SelectCardDesignStage'
import { SelectCardFormatStage } from './SelectCardFormatStage/SelectCardFormatStage'
import { SelectCardholderStage } from './SelectCardholderStage/SelectCardholderStage'
import { SelectCardTypeStage } from './SelectCardTypeStage/SelectCardTypeStage'
import { SelectDeliveryAddressStage } from './SelectDeliveryAddressStage/SelectDeliveryAddressStage'
import { SetCardSettingsStage } from './SetCardSettingsStage/SetCardSettingsStage'
import { SetCardSpendControlStage } from './SetCardSpendControlStage/SetCardSpendControlStage'

interface CreateCardStagesProps {
  setIsCloseButtonHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateCardStages = ({
  setIsCloseButtonHidden,
}: CreateCardStagesProps) => {
  return (
    <>
      <SelectCardTypeStage />
      <SelectCardFormatStage />
      <SelectCardholderStage />
      <SelectAccountStage />
      <SetCardSettingsStage />
      <SetCardSpendControlStage />
      <SelectCardDesignStage />
      <SelectDeliveryAddressStage />
      <ReviewCardStage setIsCloseButtonHidden={setIsCloseButtonHidden} />
    </>
  )
}
