import { Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DetailsListItem } from 'components/DetailsListItem'

interface Props {
  account?: string | null
  bsbNumber?: string | null
  recipientAccountName?: string | null
  recipientAccountNumber?: string | null
  referencePayee?: string | null
  reference?: string | null
}
export const ExternalRecipientDetails = ({
  account,
  bsbNumber,
  recipientAccountName,
  recipientAccountNumber,
  referencePayee,
  reference,
}: Props) => {
  const splittedBsbNumber =
    bsbNumber && `${bsbNumber.slice(0, 3)} ${bsbNumber.slice(3)}`

  return (
    <Box mt="24px">
      <DetailsListItem
        label={translate('page.transactionDetails.account')}
        value={account || '-'}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.reference')}
        value={reference || '-'}
        isCapitalised={false}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.recipient.accountName')}
        value={recipientAccountName || '-'}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.recipient.bsb')}
        value={splittedBsbNumber || '-'}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.recipient.accountNumber')}
        value={recipientAccountNumber || '-'}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.recipient.description')}
        value={referencePayee || '-'}
        isCapitalised={false}
      />
    </Box>
  )
}
