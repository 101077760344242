import { ONBOARDING_SHOP } from '@npco/mp-feature-onboarding-shop'

import { ROOT } from '../routes'
import { OnboardingFlowConfigItem } from './onboarding-config.types'
import { SHARED_KYC_FLOW_CONFIG_ITEMS } from './onboarding-config-kyc'

const ONBOARDING_GOVERNMENT_CONFIG_COMMON: OnboardingFlowConfigItem[] = [
  {
    path: ROOT.ONBOARDING.GOVERNMENT.YOUR_ROLE.path,
    isCheckpoint: false,
  },
  ...SHARED_KYC_FLOW_CONFIG_ITEMS,
  {
    path: ROOT.ONBOARDING.GOVERNMENT.ABOUT_BUSINESS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.GOVERNMENT.BUSINESS_REGULATIONS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.PRE_ONBOARDED_QUESTIONNAIRE.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.COMPLETE.path,
    isCheckpoint: true,
  },
  { path: ONBOARDING_SHOP.DEALS.path, isCheckpoint: false },
  { path: ONBOARDING_SHOP.DEALS.CHECKOUT.path, isCheckpoint: false },
]

export const ONBOARDING_GOVERNMENT_FLOW_CONFIG_ITEMS: OnboardingFlowConfigItem[] =
  [
    {
      path: ROOT.ONBOARDING.GOVERNMENT.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.GOVERNMENT.PLACE_OF_BUSINESS.path,
      isCheckpoint: true,
    },
    {
      path: ROOT.ONBOARDING.GOVERNMENT.REVENUE.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.GOVERNMENT.BUSINESS_TYPE.path,
      isCheckpoint: false,
    },
    ...ONBOARDING_GOVERNMENT_CONFIG_COMMON,
  ]

export const ONBOARDING_GOVERNMENT_FLOW_CONFIG_ITEMS_PHASE_2: OnboardingFlowConfigItem[] =
  [
    {
      path: ROOT.ONBOARDING.YOUR_BUSINESS_INFORMATION.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.GOVERNMENT.PLACE_OF_BUSINESS.path,
      isCheckpoint: true,
    },
    ...ONBOARDING_GOVERNMENT_CONFIG_COMMON,
  ]
