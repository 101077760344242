import { CorporateCardWalkthroughStage } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough-routing'

export const getOutstandingTransactionsCount = (
  stage: CorporateCardWalkthroughStage | null,
  index: number,
  outstandingTransactions: number
) => {
  const isFirstRow = index === 0
  const showBadgeDuringCorporateCardWalkthrough =
    stage === 'outstandingExpenses' && isFirstRow

  if (showBadgeDuringCorporateCardWalkthrough) {
    return 1
  }
  return outstandingTransactions
}
