import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CellWrapper = styled.div`
  grid-area: category;
  flex-grow: 1;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: block;
    padding-left: 130px;
    width: 232px;
  }
`

export const PillButton = styled.button`
  background: none;
  border: none;
  text-decoration: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`

export const IconButton = styled.button<{ $color?: string }>`
  background: none;
  border: none;
  text-decoration: none;
  outline: none;
  padding: 0;
  font: inherit;
  margin-right: 6px;
  cursor: pointer;

  div path {
    ${({ $color }) => $color && `fill: ${$color}`};
  }

  &:hover,
  &:focus-visible {
    svg path {
      fill: ${({ theme }) => theme.colors.GREY_550};
    }
  }
`
