import { Flex } from '@npco/zeller-design-system'

import { StyledSimBillFiltersWrapper } from 'pages/Settings/Sim/BillingHistory/SimBillFilters/SimBillFilters.styled'
import { DatePicker } from 'components/Filters/NewFilters/DatePicker'

export const SimBillFilters = () => {
  return (
    <StyledSimBillFiltersWrapper>
      <Flex
        flexDirection="column"
        width="100%"
        mr={['1rem', 0]}
        mb={['1rem', 0]}
      >
        <DatePicker />
      </Flex>
    </StyledSimBillFiltersWrapper>
  )
}
