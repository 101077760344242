import { useEffect, useRef } from 'react'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { getAllContactEmails, useContactCache } from 'features/Contacts'
import { useFormikContext } from 'formik'

import {
  INVOICE_EMAIL_MESSAGE_FIELD,
  INVOICE_EMAIL_RECIPIENT_FIELD,
  INVOICE_EMAIL_SUBJECT_FIELD,
} from '../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../Invoice.types'

interface UseInvoiceEmailSectionProps {
  isExpanded?: boolean
}

export const useInvoiceEmailSection = ({
  isExpanded,
}: UseInvoiceEmailSectionProps) => {
  const { readFromCache } = useContactCache()

  const {
    setFieldValue,
    setStatus,
    setFieldTouched,
    status,
    touched,
    values: {
      customer: { attentionToContact, payerContact },
      delivery: { email: emailValues },
      status: invoiceStatus,
    },
  } = useFormikContext<InvoiceFormFields>()

  const initialEmailRecipientValueRef = useRef('')

  const isEmailEnabled = isExpanded && emailValues.isEnabled
  const isRecipientTouched = !!touched.delivery?.email?.recipient

  const payerFromCache = readFromCache(payerContact?.contactUuid)
  const attentionToFromCache = readFromCache(
    attentionToContact?.contactUuid,
    true
  )

  const payerEmails = getAllContactEmails(payerFromCache)
  const attentionToEmails = getAllContactEmails(attentionToFromCache)

  const initialEmailRecipientValue =
    payerEmails.length === 1 && !attentionToEmails.length
      ? payerEmails[0].email
      : ''

  const shouldSetInitialValue =
    invoiceStatus === InvoiceStatus.DRAFT &&
    isEmailEnabled &&
    // NOTE: If payer contact was changed, set initial value if applicable
    (!initialEmailRecipientValueRef.current ||
      initialEmailRecipientValue !== initialEmailRecipientValueRef.current)

  useEffect(() => {
    if (!isEmailEnabled || status.emailInitialised) {
      return
    }

    setStatus({ ...status, emailInitialised: true })

    setFieldTouched(INVOICE_EMAIL_MESSAGE_FIELD, true, false)
    setFieldTouched(INVOICE_EMAIL_SUBJECT_FIELD, true, false)
  }, [isEmailEnabled, setStatus, setFieldTouched, status])

  useEffect(() => {
    // NOTE: Reset ref when payer is updated to set initial value
    // again when pay via accordion is expanded
    initialEmailRecipientValueRef.current = ''
  }, [payerContact?.contactUuid])

  useEffect(() => {
    if (!shouldSetInitialValue) {
      return
    }

    if (initialEmailRecipientValue) {
      setFieldValue(INVOICE_EMAIL_RECIPIENT_FIELD, initialEmailRecipientValue)
      initialEmailRecipientValueRef.current = initialEmailRecipientValue

      if (!isRecipientTouched) {
        setFieldTouched(INVOICE_EMAIL_RECIPIENT_FIELD, true, false)
      }
    }
  }, [
    initialEmailRecipientValue,
    shouldSetInitialValue,
    setFieldValue,
    isRecipientTouched,
    setFieldTouched,
  ])
}
