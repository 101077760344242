import { useTranslations } from '@npco/utils-translations'
import { HeaderContext } from '@tanstack/react-table'

import { headerCellsTranslations } from './HeaderCells.i18n'
import { HeaderCellText } from './HeaderCellText'

export const AverageHeaderCell = <T,>({
  header,
  column,
}: HeaderContext<T, string>) => {
  const t = useTranslations(headerCellsTranslations)
  return (
    <HeaderCellText header={header} column={column} justifyContent="end">
      {t('average')}
    </HeaderCellText>
  )
}
