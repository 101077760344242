import { useReactiveVar } from '@apollo/client'
import {
  rvSelectedStatuses,
  rvSelectedTypes,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import Downshift, { ControllerStateAndHelpers } from 'downshift'

import { TRANSACTION_REFUND_TYPE, TRANSACTION_STATUSES } from 'const/common'
import { PickerItemProps, PickerItems } from 'types/picker'

import {
  AreAllSiteDevicesSelectedProps,
  SiteProps,
  ToggleAllDevicesProps,
  ToggleAllSiteProps,
} from '../SiteDevicePicker/SiteDevicePicker.types'
import {
  MultiDownshiftComponentName,
  useMultidownShiftResults,
  useMultiDownshiftState,
} from './MultiDownshift.hooks'

interface Custom extends ControllerStateAndHelpers<PickerItemProps> {
  clearAllSelected: () => void
  toggleAllDevices: ToggleAllSiteProps
  toggleSiteDevices: ToggleAllDevicesProps
  areAllSiteDevicesSelected: AreAllSiteDevicesSelectedProps
  selectedItems: PickerItems
  toggleStatuses: (statuses: PickerItems) => void
  toggleTypes: (types: PickerItems) => void
  toggleSources: (sources: PickerItems) => void
}

interface MultiDownshiftProps {
  itemToString?: (item: PickerItemProps | null) => string
  children: (arg: Custom) => JSX.Element
  componentName?: MultiDownshiftComponentName
}

export const MultiDownshift: React.FC<MultiDownshiftProps> = ({
  componentName,
  children,
  ...props
}) => {
  const selectedTypes = useReactiveVar(rvSelectedTypes)
  const selectedStatuses = useReactiveVar(rvSelectedStatuses)

  const { stateReducer } = useMultiDownshiftState()
  const { setResult, selectedItems } = useMultidownShiftResults(componentName)
  const addSelectedItem = (item: PickerItemProps): PickerItems =>
    setResult([...selectedItems, item])
  const removeItem = (item: PickerItemProps): PickerItems =>
    setResult(selectedItems.filter((i: PickerItemProps) => i.id !== item.id))
  const handleSelection = (selectedItem: PickerItemProps | null): void => {
    if (!selectedItem) {
      return
    }

    if (selectedItems.includes(selectedItem)) {
      removeItem(selectedItem)

      return
    }

    addSelectedItem(selectedItem)

    if (selectedItem === TRANSACTION_REFUND_TYPE) {
      rvSelectedStatuses(
        selectedStatuses.filter(
          (item: PickerItemProps) => item !== TRANSACTION_STATUSES[2]
        )
      )
    }
  }
  const clearAllSelected = (): PickerItems => setResult([])
  const toggleAllDevices = (sites: SiteProps[]) => {
    const allDevices = sites.reduce(
      (acc: PickerItems, site: SiteProps) => [...acc, ...site.devices],
      []
    )

    if (selectedItems.length === allDevices.length) {
      clearAllSelected()

      return
    }

    setResult(allDevices)
  }
  const toggleStatuses = (statuses: PickerItems) => {
    if (selectedItems.length === statuses.length) {
      clearAllSelected()

      return
    }

    if (
      selectedTypes.includes(TRANSACTION_REFUND_TYPE) &&
      selectedItems.length === statuses.length - 1
    ) {
      clearAllSelected()
      return
    }

    if (selectedTypes.includes(TRANSACTION_REFUND_TYPE)) {
      setResult(statuses.slice(0, 2))
      return
    }

    setResult(statuses)
  }

  const toggleSources = (sources: PickerItems) => {
    if (selectedItems.length === sources.length) {
      clearAllSelected()

      return
    }

    setResult(sources)
  }

  const toggleTypes = (types: PickerItems) => {
    if (selectedItems.length === types.length) {
      clearAllSelected()

      return
    }
    setResult(types)
    rvSelectedStatuses(
      selectedStatuses.filter(
        (item: PickerItemProps) => item !== TRANSACTION_STATUSES[2]
      )
    )
  }

  const toggleSiteDevices = (devices: PickerItems): void => {
    const alreadyCheckedDevices = devices.filter((item) =>
      selectedItems.includes(item)
    )

    if (alreadyCheckedDevices.length < devices.length) {
      setResult([...selectedItems, ...devices])

      return
    }

    setResult(
      selectedItems.filter((item: PickerItemProps) => !devices.includes(item))
    )
  }

  const areAllSiteDevicesSelected = (devices: PickerItems): boolean => {
    const filteredItems = devices.filter((device) =>
      selectedItems.some(({ id }) => id === device.id)
    )

    return filteredItems.length === devices.length
  }
  const getStateAndHelpers = (
    downshift: ControllerStateAndHelpers<PickerItemProps>
  ): Custom => ({
    clearAllSelected,
    toggleAllDevices,
    toggleSiteDevices,
    areAllSiteDevicesSelected,
    toggleStatuses,
    toggleTypes,
    toggleSources,
    selectedItems,
    ...downshift,
  })

  return (
    <Downshift
      {...props}
      onSelect={handleSelection}
      selectedItem={undefined}
      stateReducer={stateReducer}
    >
      {(downshift) => children(getStateAndHelpers(downshift))}
    </Downshift>
  )
}
