import { useCallback, useEffect, useState } from 'react'

import { rvOnboardingTutorialImages } from './reactiveVariables'

export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)

    const rejectSrc = () => reject(src)

    img.onerror = rejectSrc
    img.onabort = rejectSrc
    img.src = src

    rvOnboardingTutorialImages({
      ...rvOnboardingTutorialImages(),
      [src]: img,
    })
  })
}

export const useImagePreloader = (imageList: string[], skipLoading = false) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false)

  const loadImages = useCallback(async () => {
    const imagesPromiseList: Promise<any>[] = []
    imageList.forEach((image: string) => {
      imagesPromiseList.push(preloadImage(image))
    })

    await Promise.all(imagesPromiseList)

    setImagesPreloaded(true)
  }, [imageList])

  useEffect(() => {
    if (!imagesPreloaded && !skipLoading) {
      loadImages()
    }
  }, [imagesPreloaded, loadImages, skipLoading])

  return { imagesPreloaded }
}
