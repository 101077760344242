import { gql } from '@apollo/client'

export const GetOraclePosLocalState = gql`
  query GetOraclePosLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      oraclePos {
        isOverridePairingModalOpen
        currentDeviceUuid
        currentDeviceName
        newDeviceUuid
        newDeviceName
        workstationId
      }
    }
  }
`
