import { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box, BUTTON_SIZE, ButtonFill, Flex } from '@npco/zeller-design-system'
import { ContactDetailsPlaceholder } from 'features/Contacts/Contact/ContactSections/ContactDetails/ContactDetailsPlaceholder'
import { InputSelectComboBoxItem } from 'features/Contacts/Contacts.types'
import { Formik } from 'formik'

import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { ContactUuidSelectField } from 'components/ContactUuidSelectField/ContactUuidSelectField'
import { TransactionContactErrorMessage } from 'components/TransactionContactErrorMessage'
import { TransactionContactLoading } from 'components/TransactionContactLoading'

import { translations } from './TransactionContactUnlinkedTransaction.i18l'
import {
  StyledContactDescription,
  StyledContactHeader,
} from './TransactionContactUnlinkedTransaction.styled'

interface TransactionContactUnlinkedTransactionProps {
  cardholderOrSenderUuid: string
  description?: string
  hasLinkContactError: boolean
  isLoading: boolean
  onCreateContactAndLink: (
    transferorUuid: string,
    contact: CreateContact
  ) => void
  onLinkContact: ({
    cardholderOrSenderUuid,
    contactUuid,
  }: {
    cardholderOrSenderUuid: string
    contactUuid: string
  }) => Promise<void>
}

const CONTACT_UUID = 'contactUuid'

export const TransactionContactUnlinkedTransaction = ({
  cardholderOrSenderUuid,
  description,
  hasLinkContactError,
  isLoading,
  onCreateContactAndLink,
  onLinkContact: handleLinkContact,
}: TransactionContactUnlinkedTransactionProps) => {
  const t = useTranslations(translations)

  const [selectedItem, setSelectedItem] =
    useState<InputSelectComboBoxItem | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [validationError, setValidationError] = useState<string>()
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const handleCreateContactAndLink = useCallback(
    (contact: CreateContact) => {
      onCreateContactAndLink(cardholderOrSenderUuid, contact)
    },
    [cardholderOrSenderUuid, onCreateContactAndLink]
  )

  const handleSubmit = useCallback(
    (values) => {
      // NOTE: if we already have a validation error persist
      if (validationError) {
        return
      }

      if (!values.contactUuid) {
        setValidationError(t('contactRequiredValidation'))

        return
      }

      handleLinkContact(values)
      if (!hasLinkContactError) {
        trackAnalyticsEvent(AnalyticsEventNames.CONTACT_LINKED_CARDHOLDER, {
          Location: locationName.current,
        })
      }
    },
    [
      trackAnalyticsEvent,
      hasLinkContactError,
      handleLinkContact,
      locationName,
      validationError,
      t,
    ]
  )

  return (
    <Formik
      initialValues={{
        cardholderOrSenderUuid,
        contactUuid: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, submitForm }) => {
        if (isLoading) {
          return (
            <TransactionContactLoading
              title={t('loadingTitle')}
              description={t('loadingDescription')}
            />
          )
        }

        if (hasLinkContactError) {
          return <TransactionContactErrorMessage retry={submitForm} />
        }

        const handleSetContactUuid = (contactUuid: string) =>
          setFieldValue(CONTACT_UUID, contactUuid)

        return (
          <Flex
            data-testid="unlinked-contact-transaction"
            flexDirection="column"
            width="100%"
          >
            <StyledContactHeader>{t('title')}</StyledContactHeader>
            <StyledContactDescription>
              {description || t('description')}
            </StyledContactDescription>
            <Box mb="1.5rem">
              <ContactUuidSelectField
                label={t('contactSelectLabel')}
                requiredValidationMessage={t('contactRequiredValidation')}
                unselectedValidationMesage={t('contactUnselectedValidation')}
                onCreate={handleCreateContactAndLink}
                setContactUuid={handleSetContactUuid}
                setValidationError={setValidationError}
                validationError={validationError}
                inputValue={inputValue}
                setInputValue={setInputValue}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </Box>
            <Flex flexDirection="column" mb="3rem" width="7.5rem">
              <ButtonFill onClick={submitForm} size={BUTTON_SIZE.MEDIUM}>
                {t('linkButton')}
              </ButtonFill>
            </Flex>
            <ContactDetailsPlaceholder />
          </Flex>
        )
      }}
    </Formik>
  )
}
