import { useCallback, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { Dayjs } from 'dayjs'

import dayjs from 'utils/dayjs'

import {
  getEndOfCurrentWindow,
  getIsCurrentTimeAfterOrEqualToResetTime,
} from '../../utils/getEndOfCurrentWindow'
import { UseAvailableFundsCorporateCardDebitCardV2Fragment } from './useAvailableFundsCorporateCard.generated'

interface UseAvailableFundsCorporateCardProps {
  card: UseAvailableFundsCorporateCardDebitCardV2Fragment
  timezone?: string
}

export const useAvailableFundsCorporateCard = ({
  card,
  timezone,
}: UseAvailableFundsCorporateCardProps) => {
  const getAvailableFunds = useCallback(
    (now: Dayjs) => {
      if (card.velocityControl === null) {
        return 0
      }

      if (
        getIsCurrentTimeAfterOrEqualToResetTime(
          card.velocityControl.resetsAt,
          now
        )
      ) {
        return parseInt(card.velocityControl.amountLimit.value, 10)
      }

      return parseInt(card.velocityControl.availableAmount.value, 10)
    },
    [card.velocityControl]
  )

  const [availableFunds, setAvailableFunds] = useState(() =>
    getAvailableFunds(dayjs())
  )

  useEffect(() => {
    if (card.velocityControl) {
      const now = dayjs()

      const timeUntilWindowChanges =
        getEndOfCurrentWindow({
          velocityControl: card.velocityControl,
          now,
          timezone,
        }).valueOf() - now.valueOf()

      setAvailableFunds(getAvailableFunds(now))

      const timeout = setTimeout(() => {
        const futureTime = dayjs()
        setAvailableFunds(getAvailableFunds(futureTime))
      }, timeUntilWindowChanges)

      return () => clearTimeout(timeout)
    }

    return undefined
  }, [card.velocityControl, getAvailableFunds, timezone])

  return availableFunds
}

useAvailableFundsCorporateCard.fragments = {
  DebitCardV2: gql`
    fragment UseAvailableFundsCorporateCardDebitCardV2Fragment on DebitCardV2 {
      velocityControl {
        resetsAt
        amountLimit {
          value
        }
        availableAmount {
          value
        }
        ...GetEndOfCurrentWindowVelocityControlTypeFragment
      }
    }

    ${getEndOfCurrentWindow.fragments.VelocityControlType}
  `,
}
