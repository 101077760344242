import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ACCOUNT } from '../../routes/routes'

interface AccountNavigationProps {
  accountId: string
}

export const useAccountNavigation = () => {
  const navigate = useNavigate()
  const { userData } = useLoggedInUser()
  const shortEntityId = useSelectedShortEntityUuid()

  const getAccountTransactionsPath = useCallback(
    (accountId: string) => {
      return ACCOUNT(shortEntityId, accountId).TRANSACTIONS.path
    },
    [shortEntityId]
  )

  const isAdmin = userData?.role === CustomerRole.ADMIN

  const navigateToAccountTransactions = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(getAccountTransactionsPath(accountId))
    },
    [navigate, getAccountTransactionsPath]
  )

  const navigateToAccountDetails = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(shortEntityId, accountId).DETAILS.path)
    },
    [navigate, shortEntityId]
  )

  const navigateToAccountEdit = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(shortEntityId, accountId).EDIT.path)
    },
    [navigate, shortEntityId]
  )

  const navigateToAccountClose = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(shortEntityId, accountId).CLOSE.path)
    },
    [navigate, shortEntityId]
  )

  const navigateToAccountCards = useCallback(
    ({ accountId }: AccountNavigationProps) => {
      navigate(ACCOUNT(shortEntityId, accountId).CARDS.path)
    },
    [navigate, shortEntityId]
  )

  return {
    navigateToAccountTransactions,
    navigateToAccountDetails,
    navigateToAccountCards,
    navigateToAccountEdit,
    navigateToAccountClose,
    getAccountTransactionsPath,
    canNavigate: isAdmin,
  }
}
