import { currencyFormatter } from './common'

export interface DatapointValues {
  amountText: string | null
  decimalText: string | null
  integerText: string
}

export interface DatapointIntegerType {
  intValue: number
  hasRemainder: boolean
}

export const getFormattedCurrency = (value: number) => {
  const formattedValue = currencyFormatter(value)

  // NOTE: remove $ sign from front and split as integer and decimal part
  return formattedValue.slice(1, formattedValue.length).split('.')
}

export const getIntegerDatapoint = (value: number): DatapointIntegerType => {
  const intValue = Math.floor(value)
  const decValue = value - intValue
  const hasRemainder = decValue > 0

  return {
    intValue,
    hasRemainder,
  }
}

const THOUSAND_DENOMINATOR = 1000
const MILLION_DENOMINATOR = 1000000

export const getDatapointValues = (amountInCents: number): DatapointValues => {
  const formattedAmountText = currencyFormatter(amountInCents)
  const normalizedAmount = Math.abs(amountInCents) / 100

  // NOTE: If amount is less than 9999  display as it is, otherwise should have K(thousands) or M(millions) as suffix
  if (normalizedAmount <= 9999) {
    const displayText = getFormattedCurrency(amountInCents)

    return {
      amountText: formattedAmountText,
      integerText: displayText[0],
      decimalText: `${displayText[1]}`,
    }
  }

  if (normalizedAmount > 9999 && normalizedAmount <= 999999) {
    const amount = normalizedAmount / THOUSAND_DENOMINATOR
    const { intValue, hasRemainder } = getIntegerDatapoint(amount)
    const suffix = hasRemainder ? 'K +' : 'K'

    return {
      amountText: formattedAmountText,
      integerText: `${intValue}${suffix}`,
      decimalText: null,
    }
  }

  if (normalizedAmount > 999999) {
    const amount = normalizedAmount / MILLION_DENOMINATOR
    const { intValue, hasRemainder } = getIntegerDatapoint(amount)
    const suffix = hasRemainder ? 'M +' : 'M'

    return {
      amountText: formattedAmountText,
      integerText: `${intValue}${suffix}`,
      decimalText: null,
    }
  }

  return {
    amountText: '$0.00',
    integerText: '0',
    decimalText: '00',
  }
}
