import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type CardAvatarDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  colour: Types.DebitCardColour
  maskedPan: string
}

export const CardAvatarDebitCardV2FragmentDoc = gql`
  fragment CardAvatarDebitCardV2Fragment on DebitCardV2 {
    colour
    maskedPan
  }
` as unknown as TypedDocumentNode<CardAvatarDebitCardV2Fragment, undefined>
