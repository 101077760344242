import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import {
  combineValidators,
  validateAddressString,
  validatePostcode,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { Address } from 'forms/formSections/Address/Address'
import {
  validateAddressLineWithPoBox,
  validatePostcodeWithPoBox,
} from 'forms/formViews/OfficeAddress/OfficeAddress.validators'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { ToggleFormField } from 'components/ToggleFormField'

import { PDF_INCLUDE_ADDRESS_FIELD } from '../SettingsGeneral.constants'
import { InvoiceSettingsGeneralFormFields } from '../SettingsGeneral.types'
import { SettingDescription } from './SettingsGeneralForm.styled'

export const AddressToggleFormField = () => {
  const { values } = useFormikContext<InvoiceSettingsGeneralFormFields>()

  return (
    <ToggleFormField
      childrenMarginTop="16px"
      label={translate(
        'page.invoicesSettings.general.settings.toggleLabel.pdfIncludesAddress'
      )}
      name={PDF_INCLUDE_ADDRESS_FIELD}
      value={values[PDF_INCLUDE_ADDRESS_FIELD]}
      marginBottom="24px"
    >
      <SettingDescription>
        {translate(
          'page.invoicesSettings.general.settings.description.address'
        )}
      </SettingDescription>
      <Box mb="20px">
        <InputAdaptiveFieldWrapper
          label={translate('form.site.address.label')}
          name="street"
          type="text"
          validate={combineValidators(
            validateAddressString,
            validateAddressLineWithPoBox
          )}
          maxLength={40}
        />
      </Box>
      <Address
        mb={0}
        shouldValidate
        postcodeValidator={combineValidators(
          validatePostcode,
          validatePostcodeWithPoBox
        )}
      />
    </ToggleFormField>
  )
}
