import { useContext } from 'react'
import { Subject } from 'rxjs'

import { IS_DEVELOPMENT } from 'const/envs'
import { TransactionUpdateData } from 'types/transactions'

import { TransactionUpdateContext } from '../context/TransactionUpdateContext'

export const useTransactionUpdates = () => {
  const { transactionUpdates$ } = useContext(TransactionUpdateContext)

  if (transactionUpdates$ === undefined && IS_DEVELOPMENT) {
    throw new Error(
      'useTransactionUpdates must be used within TransactionUpdateContext - wrap components using <TransactionUpdates />.'
    )
  }

  return {
    transactionUpdates$: transactionUpdates$ as Subject<TransactionUpdateData>,
  }
}
