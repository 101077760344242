import { gql } from '@apollo/client'
import { Box, BREAKPOINT, Flex } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { DebitCardTransactionFilters } from 'components/DebitCardTransactionFilters/DebitCardTransactionFilters'
import { DefaultFilterOptions } from 'components/FiltersList/FiltersList'
import { TransferFlowButton } from 'components/TransferFlowButton'

import { TransactionAccountsActionsEntityFragment } from '../../types/gql-types/TransactionAccountsActionsEntityFragment'

interface TransactionAccountsActionsProps {
  accountId?: string
  defaultFilters: DefaultFilterOptions
  entity?: TransactionAccountsActionsEntityFragment
  isFiltersDisabled: boolean
  isFiltersVisible: boolean
  isTransferDisabled: boolean
  shouldHideFilterButton?: boolean
  toggleFilters: () => void
}

export const TransactionAccountsActions = ({
  accountId,
  defaultFilters,
  entity,
  isFiltersDisabled,
  isFiltersVisible,
  isTransferDisabled,
  shouldHideFilterButton,
  toggleFilters,
}: TransactionAccountsActionsProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.MD)
  const { areFiltersInDefaultState } = useAccountFilters()

  if (isMobile) {
    return (
      <Flex>
        {!shouldHideFilterButton && (
          <Box mr="0.625rem">
            <DebitCardTransactionFilters
              defaultFilters={defaultFilters}
              isFilterButtonDisabled={isFiltersDisabled}
            />
          </Box>
        )}
        <TransferFlowButton
          entity={entity}
          initialFromAccountId={accountId}
          isDisabled={isTransferDisabled}
        />
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" data-testid="accounts-top-panel-desktop">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        {!shouldHideFilterButton && (
          <Box mr="0.5rem">
            <DashboardFiltersButton
              disabled={isFiltersDisabled}
              isActive={isFiltersVisible}
              onClick={toggleFilters}
              showIndicator={!areFiltersInDefaultState}
            />
          </Box>
        )}
        <TransferFlowButton
          entity={entity}
          initialFromAccountId={accountId}
          isDisabled={isTransferDisabled}
        />
      </Flex>
    </Flex>
  )
}

TransactionAccountsActions.fragments = {
  Entity: gql`
    fragment TransactionAccountsActionsEntityFragment on Entity {
      ...TransferFlowButtonEntityFragment
    }

    ${TransferFlowButton.fragments.Entity}
  `,
}
