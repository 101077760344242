import { useEffect, useState } from 'react'

import { BREAKPOINT } from 'styles/breakpoints'

export const useIsMobileResolution = (
  widthBreakpoint: number = BREAKPOINT.XS
) => {
  const [isMobileResolution, setIsMobileResolution] = useState(
    window.innerWidth < widthBreakpoint
  )

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobileResolution(window.innerWidth < widthBreakpoint)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [widthBreakpoint])

  return isMobileResolution
}
