import { Flex } from '@npco/zeller-design-system'

import { useEztaTermsOfService } from 'hooks/useEztaTermsOfService/useEztaTermsOfService'
import { ThirdPartyAccountType } from 'types/accounts'
import {
  StyledAccountSettingDescription,
  StyledAccountSettingPageTitle,
  StyledAccountsTitle,
} from 'components/AccountSetting/AccountSetting.styled'
import { AddNewExternalAccountModal } from 'components/AddThirdPartyAccount/AddNewExternalAccoutModal'
import { page } from 'translations'

import { SimBillingExternalBankTile } from './SimBillingExternalBankTile/SimBillingExternalBankTile'
import { SimBillingZellerBankTile } from './SimBillingZellerBankTile/SimBillingZellerBankTile'

export const SimBillingAccount = () => {
  const { hasRejectedTermsOfService } = useEztaTermsOfService()

  return (
    <>
      <StyledAccountSettingPageTitle>
        {page.settings.sim.billingAccount.heading}
      </StyledAccountSettingPageTitle>
      <StyledAccountSettingDescription>
        {page.settings.sim.billingAccount.description}
      </StyledAccountSettingDescription>
      {!hasRejectedTermsOfService && (
        <>
          <Flex mt="3rem" mb="1rem">
            <StyledAccountsTitle>
              {page.settings.sim.billingAccount.zellerAccountTitle}
            </StyledAccountsTitle>
          </Flex>
          <SimBillingZellerBankTile />
        </>
      )}

      <Flex mt="2.5rem" mb="1rem">
        <StyledAccountsTitle>
          {page.settings.sim.billingAccount.externalAccountTitle}
        </StyledAccountsTitle>
      </Flex>
      <SimBillingExternalBankTile />
      <AddNewExternalAccountModal
        accountType={ThirdPartyAccountType.SimBilling}
      />
    </>
  )
}
