import { useCallback } from 'react'
import {
  InvoiceDiscountFormFields,
  InvoiceFormFields,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

export interface UseInvoiceDiscountModalHandlersProps {
  closeModal: () => void
}

export const useInvoiceDiscount = ({
  closeModal,
}: UseInvoiceDiscountModalHandlersProps) => {
  const { values, setValues } = useFormikContext<InvoiceFormFields>()

  const initialValues = values.discount

  const handleSubmit = useCallback(
    (formValues: InvoiceDiscountFormFields) => {
      const discount = {
        ...values.discount,
        ...formValues,
      }

      setValues({ ...values, discount })
      closeModal()
    },
    [closeModal, setValues, values]
  )

  return {
    handleSubmit,
    initialValues,
    invoiceValues: values,
  }
}
