import styled from 'styled-components'

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.GREY_30};
  border-radius: 8px;
  gap: 16px;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: row;
    padding: 24px 40px;
    gap: 40px;
  }
`
