import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ImagePreview } from 'components/ImagePreview/ImagePreview'

export const StyledThumbnail = styled(ImagePreview)`
  border-radius: 4px;
  cursor: 'pointer';
`

export const StyledTileSelectorWrapper = styled(Flex)`
  gap: 16px;
  display: none;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: flex;
  }
`
