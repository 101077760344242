import { Spinner } from '@npco/zeller-design-system'

import checkmark from 'assets/animations/checkmark/checkmark.json'
import crossmark from 'assets/animations/crossmark/crossmark.json'
import { ExportFileExportMessage as ErrorMessage } from 'components/ExportFileErrorMessage/ExportFileErrorMessage'
import { CancelButton } from 'components/Modal/ModalElements/ModalElements'
import { Status } from 'components/Status/Status'
import { page, shared } from 'translations'

import {
  StyledButtonWrapper,
  StyledDescription,
  StyledLoaderWrapper,
} from './ExportFileModalView.styled'

interface ExportFileModalViewProps {
  isLoading: boolean
  hasError: boolean
  closeModal: () => void
  rerenderContent: () => void
}

export const ExportFileModalView = ({
  isLoading,
  hasError,
  closeModal,
  rerenderContent,
}: ExportFileModalViewProps) => {
  if (isLoading) {
    return (
      <>
        <StyledLoaderWrapper>
          <Spinner dataTestId="loader" />
        </StyledLoaderWrapper>
        <StyledDescription>{page.exportFile.generatingFile}</StyledDescription>
        <StyledButtonWrapper>
          <CancelButton onClick={closeModal}>{shared.cancel}</CancelButton>
        </StyledButtonWrapper>
      </>
    )
  }

  if (hasError) {
    return (
      <>
        <StyledLoaderWrapper>
          <Status dataTestId="export-error-status" icon={crossmark} />
        </StyledLoaderWrapper>
        <ErrorMessage
          closeModal={closeModal}
          rerenderContent={rerenderContent}
        />
      </>
    )
  }

  return (
    <StyledLoaderWrapper>
      <Status dataTestId="export-success-status" icon={checkmark} />
    </StyledLoaderWrapper>
  )
}
