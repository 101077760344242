import { BodyDefault, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const VenueLabel = styled(Heading.H4)`
  margin-bottom: 16px;
`

export const Description = styled(BodyDefault)`
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 40px;
`
