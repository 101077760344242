import { useCallback } from 'react'
import { useGoToCreateSavingsAccount } from '@npco/mp-utils-create-savings-account-routing'
import { useTranslations } from '@npco/utils-translations'
import {
  AccountActivation,
  AnnouncementModal,
  AnnouncementModalBodyContent,
  AnnouncementModalBodyDescription,
  Flex,
  Heading,
  ProgressIndicator,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useGoToSavingsAccountLearnMore } from 'features/SavingsAccountLearnMore/savings-account-learn-more-routing'

import { translations } from 'translations/shared.i18n'

import { useGoToBlockedAction } from '../../../../BlockedAction/blocked-action-routing'
import { TOTAL_STEPS } from '../SavingsAccountLearnMoreModal.utils'
import { readyToGoStageTranslations } from './ReadyToGoStage.i18n'

type ReadyToGoStageProps = {
  interestRate: string
  canCreateAccount: boolean | null
}

export const ReadyToGoStage = ({
  interestRate,
  canCreateAccount,
}: ReadyToGoStageProps) => {
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? 'medium' : 'large'

  const t = useTranslations(readyToGoStageTranslations)
  const tShared = useTranslations(translations)

  const { goToSavingsAccountLearnMore } = useGoToSavingsAccountLearnMore()
  const { goToCreateSavingsAccount } = useGoToCreateSavingsAccount()
  const { goToBlockedAction } = useGoToBlockedAction()

  const goToActivateStage = useCallback(() => {
    const isCreateAccountBlocked = canCreateAccount === false

    if (isCreateAccountBlocked) {
      goToBlockedAction({ blockedAction: 'createAccount' })
      return
    }

    goToCreateSavingsAccount({ stage: 'tfn' })
  }, [canCreateAccount, goToBlockedAction, goToCreateSavingsAccount])

  const goBackToSavingsStage = useCallback(() => {
    goToSavingsAccountLearnMore({
      stage: 'savings',
      interestRate,
      canCreateAccount,
    })
  }, [canCreateAccount, goToSavingsAccountLearnMore, interestRate])

  const closeModal = useCallback(() => {
    goToSavingsAccountLearnMore(null)
  }, [goToSavingsAccountLearnMore])

  return (
    <AnnouncementModal
      isOpen
      height={isMobile ? 'auto' : '528px'}
      onClickPrimary={goToActivateStage}
      primaryButtonLabel={t('activate')}
      onClickSecondary={goBackToSavingsStage}
      secondaryButtonLabel={tShared('back')}
      onCancel={closeModal}
    >
      <AccountActivation size={illustrationSize} />
      <AnnouncementModalBodyContent>
        <Heading.H2 dataTestId="announcement-modal-heading">
          {t('title')}
        </Heading.H2>
        <AnnouncementModalBodyDescription data-testid="announcement-modal-description">
          {t('description')}
        </AnnouncementModalBodyDescription>
      </AnnouncementModalBodyContent>
      <Flex
        justifyContent="center"
        width="100%"
        mt="auto"
        pt={{ _: '16px', XS: '8px' }}
      >
        <ProgressIndicator currentStep={4} totalStepCount={TOTAL_STEPS} />
      </Flex>
    </AnnouncementModal>
  )
}
