import { TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

export const InvoiceContactRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const isLoading = !row?.original || table.options.meta?.isLoading

  let width

  if (row.index % 3 === 0) {
    width = '85%'
  } else if (row.index % 2 === 0) {
    width = '95%'
  } else {
    width = '71%'
  }

  // NOTE: value returned as "' '" when empty, trim and fallback if needed
  const value = isLoading ? undefined : (getValue<string>() || '').trim() || '-'

  return (
    <TableCellText
      dataTestId={`invoice-table-row-${row.index}-contact`}
      forwardedProps={{ style: { padding: 0 } }}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width }}
      text={value}
    />
  )
}
