import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetRemainingTimeVelocityControlTypeFragmentDoc } from './VelocityWindowSummary.utils.generated'

export type VelocityWindowSummaryVelocityControlTypeFragment = {
  __typename?: 'VelocityControlType'
  resetsAt: any
  velocityWindow: Types.VelocityWindowEnum
  amountLimit: { __typename?: 'Money'; value: string }
}

export const VelocityWindowSummaryVelocityControlTypeFragmentDoc = gql`
  fragment VelocityWindowSummaryVelocityControlTypeFragment on VelocityControlType {
    amountLimit {
      value
    }
    ...GetRemainingTimeVelocityControlTypeFragment
  }
  ${GetRemainingTimeVelocityControlTypeFragmentDoc}
` as unknown as TypedDocumentNode<
  VelocityWindowSummaryVelocityControlTypeFragment,
  undefined
>
