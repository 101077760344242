import dayjs from 'utils/dayjs'
import { groupByKey } from 'utils/groupByKey'
import {
  getStartOfCurrentDay,
  getStartOfDayOneMonthAgoPlusDay,
} from 'pages/Dashboard/utils/timeUtils'
import { sumTotalSales } from 'pages/Dashboard/utils/transactionTotalsUtils'
import { ChartData } from 'components/Charts/Charts.types'
import {
  getIsFirst,
  getIsFirstTickOfMonth,
  getIsTickIndex,
} from 'components/Charts/Shared/XAxisTick.utils'

import { WithPeriod, WithTotalAmount } from '../../../Dashboard.types'

const getDatesToBeCharted = () => {
  const dates = []
  const startDate = getStartOfDayOneMonthAgoPlusDay()
  const currentDay = getStartOfCurrentDay()

  let date = dayjs(startDate)
  while (date.isBefore(currentDay)) {
    dates.push(String(+date))
    date = date.add(1, 'day')
  }

  dates.push(String(+dayjs(currentDay)))

  return dates
}

const getDay = (transactiontotal: WithPeriod) =>
  String(+dayjs(transactiontotal.period).startOf('day'))

export const convertToDayTotalSalesPairs = (
  salesTotals: (WithPeriod & WithTotalAmount)[]
) => {
  const transactionTotalsGroupedByDay = groupByKey<
    WithPeriod & WithTotalAmount
  >(getDay)(salesTotals)

  const datesToBeCharted = getDatesToBeCharted()

  return datesToBeCharted.map((day) => ({
    name: Number.parseInt(day, 10),
    value: sumTotalSales(transactionTotalsGroupedByDay[day] || []),
  }))
}

export const getDayTicks = (chartData: ChartData) => [
  ...chartData.filter((_val, i) => i % 5 === 0).map((data) => data.name),
]

const getIsFirstTickOrFirstTickOfMonth =
  (isMobile = false) =>
  (milliseconds: number, index: number) =>
    getIsFirst(index) ||
    (getIsTickIndex(index, 5) &&
      getIsFirstTickOfMonth(milliseconds, isMobile ? 10 : 5))

export const getMonthTicks = (chartData: ChartData, isMobile = false) => [
  ...chartData
    .map((point) => point.name)
    .filter(getIsFirstTickOrFirstTickOfMonth(isMobile)),
]
