import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  payAtTableDetails: 'Pay at Table Details',
  venueName: 'Venue Name',
  locations: 'Locations',
  billSummary: 'Itemised Bill Summary View',
  billSummaryDesc:
    'Turn this on if you want to see an itemised digital bill when viewing and paying tables on this terminal.',
  updateAllSuccessToast: 'Settings updated for all devices',
  updateSuccessToast: 'Settings updated for this device',
  errorToast: 'Could not update settings, please try again.',
})
