import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'

import { transformCreateContactToGetContact } from '../Contacts.utils'
import { ContactCoreFieldsFragment } from '../graphql/ContactCoreFields.generated'
import { rvAddedContacts, rvContacts, rvSelectedContact } from './contacts'

export const isContactDeletedFromRv = (id: string | null) => {
  if (!id) {
    return false
  }

  return rvContacts()?.[id] === null
}

export const saveContactsToRv = (
  contact: CreateContact,
  subContact: ContactCoreFieldsFragment | null,
  isExistingSubContact: boolean
) => {
  // NOTE: when contacts are added we must handle three scenarios, only
  // contact is created, a contact is created and linked to an existing contact,
  // both a contact and subcontact are created at the same time.
  // Do not add existing sub contacts into the list
  const newAddedContactIds =
    !isExistingSubContact && subContact?.id
      ? [contact.id, subContact.id]
      : [contact.id]

  rvAddedContacts([...rvAddedContacts(), ...newAddedContactIds])

  // NOTE: the create contact type is different to the get contact type so we
  // must add some default properties to match the schema
  const mappedContact = transformCreateContactToGetContact(contact)

  const contactRv = {
    [contact.id]: {
      ...mappedContact,
      contacts: subContact ? [subContact] : null,
    },
  }

  const subContactRv = subContact
    ? {
        [subContact.id]: {
          ...subContact,
          // NOTE: Add created contact to existing sub contacts
          contacts: subContact.contacts?.concat(mappedContact) || [
            mappedContact,
          ],
        },
      }
    : {}

  rvContacts({ ...rvContacts(), ...contactRv, ...subContactRv })
}

export const updateRvContactsOnContactUpdate = (
  contactId: string,
  fieldsToUpdate: Partial<ContactCoreFieldsFragment>
) => {
  const contacts = rvContacts()

  if (!contacts?.[contactId]) {
    return
  }

  rvContacts({
    ...contacts,
    [contactId]: {
      ...contacts[contactId],
      ...fieldsToUpdate,
    } as ContactCoreFieldsFragment,
  })
}

export const updateRvSelectedContactOnContactUpdate = (
  fieldsToUpdate: Partial<ContactCoreFieldsFragment>
) => {
  const contact = rvSelectedContact()

  if (!contact) {
    return
  }

  rvSelectedContact({
    ...contact,
    ...fieldsToUpdate,
  })
}
