import {
  Box,
  Heading,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Formik, FormikHelpers } from 'formik'

import { translate } from 'utils/translations'
import { ReceiptDetails } from 'forms/formViews/Receipt/ReceiptDetails'
import { SettingsLeavingRoutePromptGuard } from 'components/RouteLeavingPromptGuard'
import { SpinnerWrapped } from 'components/Spinner'

import { FormButtons } from '../FormButtons'
import { useGetZellerInvoiceSettings } from '../hooks/useGetZellerInvoiceSettings'
import { useUpdateZellerInvoiceSettings } from '../hooks/useUpdateZellerInvoiceSettings'
import { InvoiceSettingsReceiptsFormFields } from './SettingsReceipts.types'

export const SettingsReceipts = () => {
  const { invoiceSettings, updateInvoiceSettingsCache, isLoading } =
    useGetZellerInvoiceSettings()

  const { isUpdatingZellerInvoiceSettings, updateZellerInvoiceSettings } =
    useUpdateZellerInvoiceSettings()

  if (!invoiceSettings || isLoading) {
    return <SpinnerWrapped variant="top" />
  }

  const handleOnSubmit = async (
    values: InvoiceSettingsReceiptsFormFields,
    formikHelpers: FormikHelpers<InvoiceSettingsReceiptsFormFields>
  ) => {
    try {
      const result = await updateZellerInvoiceSettings({ receipt: values })

      if (result) {
        updateInvoiceSettingsCache({
          ...invoiceSettings,
          receipt: {
            __typename: 'ReceiptSettings',
            logo: invoiceSettings?.receipt?.logo ?? '',
            ...values,
          },
        })

        formikHelpers.resetForm({ values })

        showSuccessToast(translate('page.invoicesSettings.successToast'))
      } else {
        showErrorToast(translate('page.invoicesSettings.errorToast'))
      }
    } catch (err) {
      showErrorToast(translate('page.invoicesSettings.errorToast'))
    }
  }

  return (
    <Formik<InvoiceSettingsReceiptsFormFields>
      onSubmit={handleOnSubmit}
      initialValues={{
        name: invoiceSettings?.receipt?.name ?? '',
        phone: invoiceSettings?.receipt?.phone ?? '',
        twitter: invoiceSettings?.receipt?.twitter ?? '',
        facebook: invoiceSettings?.receipt?.facebook ?? '',
        instagram: invoiceSettings?.receipt?.instagram ?? '',
        website: invoiceSettings?.receipt?.website ?? '',
        email: invoiceSettings?.receipt?.email ?? '',
        message: invoiceSettings?.receipt?.message ?? '',
        returnsMessage: invoiceSettings?.receipt?.returnsMessage ?? '',
      }}
    >
      {({ submitForm, resetForm, values }) => (
        <>
          <SettingsLeavingRoutePromptGuard />
          {isUpdatingZellerInvoiceSettings ? (
            <SpinnerWrapped variant="top" />
          ) : (
            <>
              <Box mb="16px">
                <Heading.H3>
                  {translate('page.invoicesSettings.receipts.title')}
                </Heading.H3>
              </Box>
              <ReceiptDetails
                messageValue={values.message}
                returnsMessageValue={values.returnsMessage}
                showBusinessNameSubtitle
              />
              <FormButtons onSave={submitForm} onCancel={resetForm} />
            </>
          )}
        </>
      )}
    </Formik>
  )
}
