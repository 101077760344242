import { BodySmall, BodySmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledOpeningHourWrapper = styled.div`
  display: block;
  text-align: right;
  margin-bottom: 0.25rem;
  ${BodySmallTypography}
`

export const StyledOpeningDay = styled.span`
  margin-right: 0.625rem;
  text-transform: capitalize;

  &::after {
    content: ':';
  }
`

export const StyledAlwaysOpen = styled.span`
  margin-bottom: 0.25rem;
  ${BodySmallTypography}
`

export const StyledOpeningHours = styled.span`
  display: inline-block;
  min-width: 5.625rem;
  font-variant-numeric: tabular-nums;
`

export const StyledErrorText = styled(BodySmall)`
  ${({ theme }) => theme.colors.RED_1000};
  margin: 0;
`
