import { BodySmallTypography, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledText = styled.span`
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledBoldText = styled(H3)`
  word-break: break-word;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledInnerContainer = styled.div`
  width: 100%;
`

export const StyledQuotesWrapper = styled.div`
  display: inline-flex;
  white-space: nowrap;
  max-width: 100%;
`

export const StyledSearchWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledContainer = styled.div`
  display: flex;
  min-height: 9rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.5rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`
