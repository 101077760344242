import { Box, Flex } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import {
  StyledFormBodyWithOffset,
  StyledFormFooterWithOffset,
  StyledFormWithOffset,
} from 'pages/GlobalModals/GlobalModals.styled'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { CardHolderInfoBox } from '../../../shared/CardHolderInfoBox/CardHolderInfoBox'
import { SelectCardTypeFields } from './fields/SelectCardTypeFields'
import { useSelectCardTypeForm } from './hooks/useSelectCardTypeForm'
import { SelectCardTypeFormValues } from './SelectCardTypeForm.types'

interface SelectCardTypeFormProps {
  dataTestId?: string
}

export const SelectCardTypeForm = ({ dataTestId }: SelectCardTypeFormProps) => {
  const { handleSubmit, handleBack, initialCardType } = useSelectCardTypeForm()
  useMultiStageModalProgressBar({
    newCurrentStep: 2,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })

  return (
    <Formik<SelectCardTypeFormValues>
      validateOnMount
      initialValues={{ cardType: initialCardType }}
      onSubmit={handleSubmit}
    >
      <StyledFormWithOffset data-testid={dataTestId}>
        <StyledFormBodyWithOffset>
          <SelectCardTypeFields />
          <Flex flex={{ _: 0, MD: 1 }} alignItems="center">
            <Box width="100%">
              <CardHolderInfoBox />
            </Box>
          </Flex>
        </StyledFormBodyWithOffset>
        <StyledFormFooterWithOffset onCancel={handleBack} disableOnInvalid />
      </StyledFormWithOffset>
    </Formik>
  )
}
