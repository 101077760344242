import styled from 'styled-components'

export const StyledPartsWrapper = styled.div`
  white-space: normal;
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
`
