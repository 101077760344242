import { useMemo } from 'react'
import { gql } from '@apollo/client'
import {
  getContactAvatarOptions,
  getContactName,
} from '@npco/mp-utils-contacts-format'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  QuestionMarkIcon,
  TableCellAvatarText,
  TableCellSize,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { ContactCellCashFlowContactNetAmountFragment } from './ContactCell.generated'
import { translations } from './ContactCell.i18n'

export const ContactCell = <T,>({
  cell,
  table,
  getValue,
  row,
  column,
}: CellContext<T, ContactCellCashFlowContactNetAmountFragment>) => {
  const t = useTranslations(translations)
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<ContactCellCashFlowContactNetAmountFragment>()

  const contactName = useMemo(() => {
    const contact = data?.contact
    if (!contact) {
      return t('unassigned')
    }

    return getContactName(contact)
  }, [data, t])

  const contactAvatarOptions = useMemo(() => {
    const contact = data?.contact
    if (!contact) {
      return {
        text: '',
        bgColor: COLOR.GREY_90,
        svgBackground: COLOR.GREY_90,
        contentHeight: '12px',
        contentWidth: '12px',
        userDefinedContent: <QuestionMarkIcon color={COLOR.GREY_550} />,
      }
    }

    return getContactAvatarOptions(contact)
  }, [data?.contact])

  return (
    <TableCellAvatarText
      key={cell.id}
      dataTestId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      avatarProps={contactAvatarOptions}
      text={contactName}
      size={TableCellSize.SMALL}
      skeletonProps={{ width: skeletonLoaderWidth }}
    />
  )
}

ContactCell.fragments = {
  CashFlowContactNetAmount: gql`
    fragment ContactCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      contact {
        __typename
        id
        ...GetContactAvatarOptionsContactFragment
        ...GetContactNameContactFragment
      }
    }

    ${getContactAvatarOptions.fragments.Contact}
    ${getContactName.fragments.Contact}
  `,
}
