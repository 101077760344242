import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountAmountItemDebitCardAccountV2FragmentDoc } from './AccountAmountItem.generated'
import { AccountNameAndInterestCellDebitCardAccountV2FragmentDoc } from './AccountNameAndInterestCell/AccountNameAndInterestCell.generated'
import { CardTooltipDebitCardAccountV2FragmentDoc } from './ListItemAccounts.utils.generated'

export type ListItemAccountsDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  type: Types.DebitCardAccountType
  name: string
  id: string
  status: Types.DebitCardAccountStatus
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    image: string | null
    animation: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  cardsCount: {
    __typename?: 'DebitCardAccountV2CardsCount'
    total: number
    debit: number
    expense: number | null
  }
  balance: { __typename?: 'Money'; value: string } | null
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    effectiveInterestRate: string | null
  } | null
}

export const ListItemAccountsDebitCardAccountV2FragmentDoc = gql`
  fragment ListItemAccountsDebitCardAccountV2Fragment on DebitCardAccountV2 {
    type
    name
    id
    icon {
      colour
      letter
      image
      images {
        size
        url
      }
      animation
    }
    status
    cardsCount {
      total
    }
    ...AccountAmountItemDebitCardAccountV2Fragment
    ...CardTooltipDebitCardAccountV2Fragment
    ...AccountNameAndInterestCellDebitCardAccountV2Fragment
  }
  ${AccountAmountItemDebitCardAccountV2FragmentDoc}
  ${CardTooltipDebitCardAccountV2FragmentDoc}
  ${AccountNameAndInterestCellDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ListItemAccountsDebitCardAccountV2Fragment,
  undefined
>
