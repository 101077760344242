import { Placement } from '@popperjs/core'
import { useSelect } from 'downshift'

import { usePopperConfig } from '../../../../shared/hooks/usePopperConfig'
import { SelectItemBasic, SelectSelectedItem } from '../../Select.types'

interface Props<ItemType> {
  items: ItemType[]
  onChange: (selectedItem: SelectSelectedItem<ItemType>) => void
  onOpen?: (selectedItem: SelectSelectedItem<ItemType>) => void
  onClose?: (selectedItem: SelectSelectedItem<ItemType>) => void
  setHasBeenOpen: React.Dispatch<React.SetStateAction<boolean>>
  placement?: Placement
  shouldCloseOnSelect: boolean
  popperWidth?: string
}

export function useSelectBasic<ItemType extends SelectItemBasic>({
  items,
  onChange,
  onOpen,
  onClose,
  setHasBeenOpen,
  placement,
  shouldCloseOnSelect,
  popperWidth,
}: Props<ItemType>) {
  const {
    menuAttributes,
    wrapperElementRef,
    menuElementRef,
    update: updatePopper,
  } = usePopperConfig(placement, popperWidth)

  const selectHandlers = useSelect<SelectSelectedItem<ItemType>>({
    items,
    defaultIsOpen: !shouldCloseOnSelect,
    onIsOpenChange: (changes) => {
      if (changes.isOpen) {
        setHasBeenOpen(true)
        onOpen?.(changes.selectedItem)
      } else {
        onClose?.(changes.selectedItem)
      }

      updatePopper?.()
    },
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem?.isDisabled) {
        onChange(changes.selectedItem)
      }
    },
  })

  return {
    menuAttributes,
    wrapperElementRef,
    menuElementRef,
    ...selectHandlers,
  }
}
