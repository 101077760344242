import { Source } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'

import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'
import {
  useGetPosDetails,
  useGetPosLastTransaction,
} from 'pages/Devices/PosDetails/PosDetails.hooks'
import { StyledGenericErrorPage } from 'pages/Devices/PosDetails/PosDetails.styled'
import { PosSettings } from 'pages/Devices/PosDetails/PosSettings/PosSettings'

interface Props {
  device: DeviceWithSiteInfo
  posSource?: Source | null
}

export const PosDetails = ({ device, posSource }: Props) => {
  const {
    error: isPosDetailsError,
    isLoading: isPosDetailsLoading,
    getPosDetails,
    posDetails,
  } = useGetPosDetails(device.id)

  const {
    isLoading: isLastTransactionLoading,
    getPosLastTransaction,
    lastTransaction: posLastTransaction,
  } = useGetPosLastTransaction(device.id)

  const isLoading = isPosDetailsLoading
  const isPosLastTransactionLoading = isLastTransactionLoading
  const isError = Boolean(isPosDetailsError)

  return (
    <Flex flex={1} width="100%">
      {isError ? (
        <StyledGenericErrorPage
          data-testid="pos-settings-error"
          retry={() => {
            getPosDetails()
            getPosLastTransaction()
          }}
        />
      ) : (
        <PosSettings
          posLastTransaction={posLastTransaction}
          posDetails={posDetails}
          isLoading={isLoading}
          posSource={posSource}
          isPosLastTransactionLoading={isPosLastTransactionLoading}
          deviceId={device.id}
          deviceName={device.name ?? ''}
          siteId={device.siteId}
        />
      )}
    </Flex>
  )
}
