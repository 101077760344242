import { ReactComponent as MobileIcon } from 'assets/svg/payment-source/mobile.svg'
import { ReactComponent as TerminalIcon } from 'assets/svg/payment-source/terminal.svg'
import { ReactComponent as T2Icon } from 'assets/svg/t2.svg'
import { DeviceModel } from 'types/devices'

export const getIsMobileDevice = (deviceModel: string | null | undefined) => {
  return deviceModel === DeviceModel.ANDROID || deviceModel === DeviceModel.IOS
}

export const getDeviceModelIcon = (deviceModel: DeviceModel) => {
  switch (deviceModel) {
    case DeviceModel.ANDROID:
    case DeviceModel.IOS:
      return <MobileIcon />
    case DeviceModel.NEW9220:
      return <TerminalIcon />
    case DeviceModel.T2:
      return <T2Icon />
    default:
      return <TerminalIcon />
  }
}
