import {
  DownloadIcon,
  ListCardButton,
  LoaderVariant,
  StyledSpinner,
} from '@npco/zeller-design-system'

import { AccountStatementAvatar } from './AccountStatementAvatar'

interface StatementCardProps {
  title: React.ReactNode
  onClick: () => void
  isLoading: boolean
  readonly?: boolean
}

export const StatementCard = ({
  title,
  onClick,
  isLoading,
  readonly,
}: StatementCardProps) => {
  return (
    <ListCardButton
      image={AccountStatementAvatar}
      title={title}
      onClick={onClick}
      readonly={readonly}
    >
      {isLoading ? (
        <StyledSpinner loaderVariant={LoaderVariant.Dark} />
      ) : (
        <DownloadIcon />
      )}
    </ListCardButton>
  )
}
