import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { InvoiceCompanyLogoUpload } from 'components/InvoiceCompanyLogoUpload/InvoiceCompanyLogoUpload'

import { ReceiptFormFields } from '../Receipt/Receipt.types'
import { CompanyLogoLayout } from './CompanyLogoLayout'

export const ReceiptCompanyLogo = () => {
  const { setValues, values } = useFormikContext<ReceiptFormFields>()

  const { logo: imageUrl } = values

  const handleOnImageAdded = useCallback(
    (url: string, file: File) => {
      setValues((values) => ({
        ...values,
        logo: url,
        logoFile: file,
      }))
    },
    [setValues]
  )

  const handleOnImageRemoved = useCallback(() => {
    setValues((values) => ({
      ...values,
      logo: undefined,
      logoFile: undefined,
    }))
  }, [setValues])

  return (
    <InvoiceCompanyLogoUpload
      CompanyLogoLayout={CompanyLogoLayout}
      imageUrl={imageUrl}
      onImageAdded={handleOnImageAdded}
      onImageRemoved={handleOnImageRemoved}
    />
  )
}
