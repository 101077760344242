import { Dispatch, SetStateAction } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import {
  Animation,
  fadeInHeightAnimationVariants,
  slideDownAnimationVariants,
  slideSideAnimationVariants,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'
import {
  ContactsFilters,
  InputSelectComboBoxItem,
} from 'features/Contacts/Contacts.types'
import { useCreatePaymentInstrumentMFAState } from 'features/MFA'
import { Subject } from 'rxjs'

import { AccountSelectInput } from 'pages/Transfer/ContactDropdown/AccountSelectInput'
import { ContactSelectField } from 'pages/Transfer/ContactDropdown/ContactSelectField'
import {
  TransferZellerDropdown,
  TransferZellerDropdownProps,
} from 'pages/Transfer/FromDropdown/TransferZellerDropdown'
import { useIsSingleAccountCase } from 'pages/Transfer/hooks/useIsSingleAccountCase/useIsSingleAccountCase'
import { SingleZellerAccountInfo } from 'pages/Transfer/states/Receipients/SIngleZellerAccountInfo/SingleZellerAccountInfo'
import { StyledLabelWithTabs } from 'pages/Transfer/states/Receipients/TransferRecipients.styled'
import { TransferStateCondition as Condition } from 'pages/Transfer/states/TransferState/TransferStateCondition'
import {
  TransferBpayState,
  TransferContactState,
  TransferGeneralState,
  TransferZellerState,
} from 'pages/Transfer/Transfer.stateMachine'
import {
  StyledDropdownToWrapper as DropdownWrapper,
  StyledLabel as Label,
} from 'pages/Transfer/Transfer.styled'
import { TransferTypeTabs } from 'pages/Transfer/TransferTypeTabs/TransferTypeTabs'
import { MarkAsSelf } from 'components/MarkAsSelf/MarkAsSelf'
import { page } from 'translations'

import { validateAccountTo } from '../Transfer.validators'
import { BpayToFields } from './BpayToFields/BpayToFields'

interface Props {
  validateAccounts: TransferZellerDropdownProps['validateAccounts']
}

const TransferZellerToDropdown = ({ validateAccounts }: Props) => {
  return (
    <TransferZellerDropdown name="to" validateAccounts={validateAccounts} />
  )
}

interface TransferDropdownToProps {
  selectedContact: InputSelectComboBoxItem | null
  setSelectedContact: Dispatch<SetStateAction<InputSelectComboBoxItem | null>>
  contactValidationError: string | undefined
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
  contactFilters: ContactsFilters
  name$: Subject<string>
}

export const TransferDropdownTo = ({
  selectedContact,
  setSelectedContact,
  contactValidationError,
  setContactValidationError,
  contactFilters,
  name$,
}: TransferDropdownToProps) => {
  const isSingleAccountCase = useIsSingleAccountCase()
  const { hasRedirectedBackToApp, createPaymentInstrumentState } =
    useCreatePaymentInstrumentMFAState()
  const flags = useFeatureFlags()

  const {
    isModalOpen: isAccountModalOpen,
    openModal: openAccountModal,
    closeModal: closeAccountModal,
  } = useModalState({
    isOpenByDefault: Boolean(
      hasRedirectedBackToApp && createPaymentInstrumentState
    ),
  })

  if (isSingleAccountCase) {
    return (
      <DropdownWrapper>
        <StyledLabelWithTabs>
          <Label htmlFor="to">{page.transfer.to}</Label>
          <TransferTypeTabs
            setSelectedContact={setSelectedContact}
            setContactValidationError={setContactValidationError}
          />
        </StyledLabelWithTabs>
        <SingleZellerAccountInfo />
      </DropdownWrapper>
    )
  }

  return (
    <DropdownWrapper data-testid="transfer-dropdown-to">
      <StyledLabelWithTabs>
        <Label htmlFor="to">{page.transfer.to}</Label>
        <Animation variants={slideDownAnimationVariants} shouldShow>
          <TransferTypeTabs
            setSelectedContact={setSelectedContact}
            setContactValidationError={setContactValidationError}
          />
        </Animation>
      </StyledLabelWithTabs>

      <Condition
        allowedStates={[TransferGeneralState.ZellerTransfer]}
        animationVariants={fadeInHeightAnimationVariants}
      >
        <TransferZellerToDropdown validateAccounts={validateAccountTo} />
      </Condition>

      <Condition
        allowedStates={[TransferGeneralState.ContactTransfer]}
        animationVariants={fadeInHeightAnimationVariants}
      >
        <ContactSelectField
          selectedItem={selectedContact}
          setSelectedItem={setSelectedContact}
          contactValidationError={contactValidationError}
          setContactValidationError={setContactValidationError}
          filters={contactFilters}
          name$={name$}
          onCreate={openAccountModal}
        />
      </Condition>
      {flags.SweepingTracking &&
        !!selectedContact &&
        typeof selectedContact.isSelf !== 'boolean' && (
          <Condition
            parent={TransferGeneralState.ContactTransfer}
            allowedStates={[
              TransferContactState.NoAccount,
              TransferZellerState.TransferDetails,
            ]}
            animationVariants={fadeInHeightAnimationVariants}
          >
            <MarkAsSelf
              name="isSelf"
              contactType={selectedContact.contactType}
            />
          </Condition>
        )}
      <Condition
        parent={TransferGeneralState.ContactTransfer}
        allowedStates={[
          TransferContactState.NoAccount,
          TransferContactState.TransferDetails,
        ]}
        animationVariants={slideSideAnimationVariants}
      >
        <AccountSelectInput
          isModalOpen={isAccountModalOpen}
          openModal={openAccountModal}
          closeModal={closeAccountModal}
        />
      </Condition>
      <Condition
        parent={TransferGeneralState.BpayTransfer}
        allowedStates={[TransferBpayState.TransferDetails]}
        animationVariants={fadeInHeightAnimationVariants}
      >
        <BpayToFields />
      </Condition>
    </DropdownWrapper>
  )
}
