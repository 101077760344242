import { createContext } from 'react'

export interface ProgressSettings {
  currentStep?: number
  totalSteps?: number
}

interface ProgressBarContextType {
  currentStep: number
  totalSteps: number
  shouldShow: boolean
  setProgress: (newProgress: ProgressSettings) => void
  hideProgressBar: () => void
}

export const ProgressBarContext = createContext<ProgressBarContextType | null>(
  null
)
