import { useEffect } from 'react'
import {
  BodyDefault,
  ButtonFill,
  ButtonGhost,
  Flex,
  H3,
  Spinner,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { AccountSelect } from './Components/AccountSelect'
import { useXeroAccounts } from './hooks/useXeroAccounts'
import { Values } from './OrganisationAccountForm.types'

export const StepTwo = () => {
  const { setFieldValue, isSubmitting } = useFormikContext<Values>()
  const { loading, data } = useXeroAccounts()
  const { accounts, selectedAccountIds } = data

  useEffect(() => {
    setFieldValue('accounts', selectedAccountIds)
    // including setFieldValue in deps causes infinte render loop
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedAccountIds])

  if (loading) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <H3>
        {translate(
          'page.settings.connections.xeroBankFeeds.accountSelect.title'
        )}
      </H3>
      <BodyDefault>
        {translate(
          'page.settings.connections.xeroBankFeeds.accountSelect.description'
        )}
      </BodyDefault>
      <br />
      <AccountSelect accounts={accounts} />

      <br />
      <br />
      <ButtonFill fullWidth type="submit" disabled={isSubmitting}>
        {translate('page.settings.connections.xeroBankFeeds.cta.save')}
      </ButtonFill>
      <br />
      <ButtonGhost fullWidth onClick={() => setFieldValue('step', 1)}>
        {translate('page.settings.connections.xeroBankFeeds.cta.cancel')}
      </ButtonGhost>
    </>
  )
}
