import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  managerTitle: 'No Sites Available',
  managerDescription:
    'You are not assigned to any Sites yet. Contact your administrator to be assigned to a Site.',
  adminTitle: 'You have no Sites',
  adminDescription:
    'Once you add a Site, it will be listed here. Sites can also be created via Zeller Terminal.',
  cardHeading: 'Organise your business with Sites',
  point1: 'Group Payment Devices together',
  point2: 'Apply consistent settings to all Devices',
  point3: 'Build reports for different locations',
})
