import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  ErrorMessageForm,
  PillSize,
  PillYellow,
  SelectHeaderRenderProps,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'
import { UseComboboxStateChange } from 'downshift'
import { ContactSelectCombobox } from 'features/Contacts/components/ContactSelectCombobox/ContactSelectCombobox'
import { ContactSimpleForm } from 'features/Contacts/components/ContactSimpleForm'
import {
  ContactsFilters,
  InputSelectComboBoxItem,
} from 'features/Contacts/Contacts.types'
import { getContactName } from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { useContacts } from 'features/Contacts/hooks/useContacts/useContacts'
import { rvSelectedContactToTransfer } from 'features/Contacts/rv-deprecated/contacts'
import {
  useCreatePaymentInstrumentMFAState,
  useTransferMFAState,
} from 'features/MFA'
import { useFormikContext } from 'formik'
import { uniqBy } from 'lodash-es'
import { Subject } from 'rxjs'

import { translate } from 'utils/translations'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'
import { TO } from 'pages/Transfer/ContactDropdown/AccountSelectInput'
import { ContactHeader } from 'pages/Transfer/ContactDropdown/ContactHeader'
import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferEvent } from 'pages/Transfer/Transfer.stateMachine'
import { page, translationsShared } from 'translations'

import {
  TransferContactFields,
  useUpdateToContact,
  useUpdateToPaymentInstrument,
} from '../ContactTransfer'
import { useResetFields } from '../hooks/useResetFields'
import { transferFieldsTranslations } from '../TransferFields/TransferFields.i18n'

export const CONTACT = 'contact'

const getContactItem = (
  contact: ContactCoreFieldsFragment | CreateContact | CreateContactsContact
) => {
  const label = getContactName(contact) || ''

  return {
    id: contact.id,
    label,
    subLabel:
      contact?.contactType === ContactType.BUSINESS
        ? page.contacts.form.business
        : '',
    value: label,
    contactType: contact.contactType,
    isSelf: contact.isSelf,
  }
}
export interface ContactSelectFieldProps {
  selectedItem: InputSelectComboBoxItem | null
  setSelectedItem: Dispatch<SetStateAction<InputSelectComboBoxItem | null>>
  onCreate: () => void
  contactValidationError: string | undefined
  setContactValidationError: (newError: string | undefined) => void
  filters: ContactsFilters
  name$: Subject<string>
  contactType?: ContactType
  shouldUpdateToFieldWithPaymentInstrument?: boolean
  shouldClearAllFields?: boolean
  showPersonalDetailsInput?: boolean
}

export const ContactSelectField = ({
  selectedItem,
  setSelectedItem,
  onCreate,
  contactValidationError,
  setContactValidationError,
  filters,
  name$,
  contactType,
  shouldUpdateToFieldWithPaymentInstrument = true,
  shouldClearAllFields = true,
  showPersonalDetailsInput = true,
}: ContactSelectFieldProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(transferFieldsTranslations)

  const { setFieldValue, values } = useFormikContext<TransferContactFields>()

  const { updateToContact } = useUpdateToContact()
  const { updateToPaymentInstrument } = useUpdateToPaymentInstrument()

  const { resetFields } = useResetFields()
  const {
    transferState: [, send],
  } = useTransferState()
  const [addContactType, setAddContactType] = useState<ContactType | null>(null)
  const { isModalOpen, openModal, closeModal } = useModalState()

  const {
    contacts: contactsFromBackend,
    loading: isLoading,
    hasMore,
    loadMore,
  } = useContacts({
    contactType,
    filters,
    limit: 25,
    isNetworkOnly: true,
  })

  const {
    createPaymentInstrumentState,
    hasRedirectedBackToApp: hasCreatePaymentInstrumentRedirectedBackToApp,
  } = useCreatePaymentInstrumentMFAState()
  const {
    transferState,
    hasRedirectedBackToApp: hasTransferRedirectedBackToApp,
  } = useTransferMFAState()

  const contacts = useMemo(() => {
    if (
      hasCreatePaymentInstrumentRedirectedBackToApp ||
      hasTransferRedirectedBackToApp
    ) {
      return uniqBy(
        [
          ...contactsFromBackend,
          createPaymentInstrumentState?.contact,
          transferState?.contact,
        ].filter(Boolean),
        (contact) => contact?.id
      )
    }

    return contactsFromBackend
  }, [
    hasCreatePaymentInstrumentRedirectedBackToApp,
    hasTransferRedirectedBackToApp,
    createPaymentInstrumentState,
    contactsFromBackend,
    transferState,
  ])

  const items: InputSelectComboBoxItem[] = useMemo(() => {
    return (
      contacts
        .filter(
          (item): item is ContactCoreFieldsFragment =>
            Boolean(item) && Boolean(item?.id)
        )
        .map((item: ContactCoreFieldsFragment) => {
          const label = getContactName(item) || ''
          const businessLabel =
            item?.contactType === ContactType.BUSINESS
              ? translate('page.contacts.form.business')
              : ''

          return {
            label,
            subLabel: item.isSelf ? (
              <PillYellow text={tShared('you')} size={PillSize.Small} />
            ) : (
              businessLabel
            ),
            value: label,
            id: item.id,
            contactType: item.contactType,
            isSelf: item.isSelf,
          }
        }) || []
    )
  }, [contacts, tShared])

  const selectContact = useCallback(
    (newSelectedItem: InputSelectComboBoxItem | null) => {
      const selectedItemId = newSelectedItem?.id
      if (selectedItemId) {
        const selectedContact = contacts.find(
          (contact) => contact?.id === selectedItemId
        )
        const linkedPaymentInstruments = (
          selectedContact?.paymentInstruments || []
        ).filter((item) => Boolean(item) && Boolean(item?.id))

        const isSingleLinkedPaymentInstrument =
          linkedPaymentInstruments.length === 1

        setFieldValue(CONTACT, newSelectedItem?.value)
        updateToContact(selectedContact ?? null)
        rvSelectedContactToTransfer(selectedContact)

        if (
          isSingleLinkedPaymentInstrument &&
          shouldUpdateToFieldWithPaymentInstrument
        ) {
          setFieldValue(TO, linkedPaymentInstruments[0].id)
          updateToPaymentInstrument(linkedPaymentInstruments[0])
          send(TransferEvent.AddContact)
        } else {
          send(TransferEvent.SelectContactWithMultipleOrNoAccount)
        }
      }

      setSelectedItem(newSelectedItem)
    },
    [
      contacts,
      send,
      setFieldValue,
      setSelectedItem,
      shouldUpdateToFieldWithPaymentInstrument,
      updateToContact,
      updateToPaymentInstrument,
    ]
  )

  const onChange = useCallback(
    (changes: SelectStateChangeProps<InputSelectComboBoxItem>) => {
      selectContact(changes.selectedItem)
    },
    [selectContact]
  )

  useEffect(() => {
    if (
      hasCreatePaymentInstrumentRedirectedBackToApp &&
      createPaymentInstrumentState
    ) {
      selectContact(
        items.find(
          (contact) =>
            contact.id === createPaymentInstrumentState.variables.contactId
        ) || null
      )
    }

    if (hasTransferRedirectedBackToApp && transferState) {
      selectContact(
        items.find((contact) => contact.id === transferState.contact?.id) ||
          null
      )
    }
  }, [
    transferState,
    hasTransferRedirectedBackToApp,
    createPaymentInstrumentState,
    hasCreatePaymentInstrumentRedirectedBackToApp,
    items,
    setSelectedItem,
    selectContact,
  ])

  // NOTE: clear contact validation error message when either of the create contact modals is
  // opened, the error message is set when the select dropdown menu closes
  useEffect(() => {
    if (isModalOpen) {
      setContactValidationError(undefined)
    }
  }, [isModalOpen, setContactValidationError])

  const onAddNewContact = () => {
    setSelectedItem(null)
    updateToContact(null)
    setFieldValue(CONTACT, '')
    send(TransferEvent.RemoveContact)
  }

  const onClose = (
    changes: UseComboboxStateChange<InputSelectComboBoxItem>
  ) => {
    // NOTE: when a user has an item, changes the input value and navigates away
    // from the menu causing the menu to close reset the input value to that of
    // the selected item
    if (changes.selectedItem) {
      if (changes.selectedItem.value !== changes.inputValue) {
        setFieldValue(CONTACT, changes.selectedItem.value)
      }
      setContactValidationError(undefined)
    } else {
      setContactValidationError(t('contactRequired'))
    }
  }

  const handleClearingContactField = () => {
    setSelectedItem(null)
    updateToContact(null)
    name$.next('')
    if (shouldClearAllFields) {
      resetFields()
    }

    send(TransferEvent.RemoveContact)
  }

  const onInputChange = (
    changes: SelectStateInputChangeProps<InputSelectComboBoxItem>
  ) => {
    const value = changes.inputValue

    if (value === '') {
      handleClearingContactField()
    }

    setFieldValue(CONTACT, value)

    // NOTE: only fetch when no selected item or when current selected item
    // value does not match current input value
    if (!changes.selectedItem || changes.selectedItem.value !== value) {
      name$.next(value)
    }
  }

  const onInputClear = () => {
    setFieldValue(CONTACT, '')
    handleClearingContactField()

    name$.next('')
  }

  const handleOnContactCreate = (
    contact: CreateContact | CreateContactsContact
  ) => {
    setFieldValue(CONTACT, contact.id)

    const nextSelectedItem = getContactItem(contact)
    setSelectedItem(nextSelectedItem)
    updateToContact({ ...contact, icon: null })

    rvSelectedContactToTransfer(contact as ContactCoreFieldsFragment)
    send(TransferEvent.SelectContactWithMultipleOrNoAccount)
    setContactValidationError(undefined)

    // onCreate opens the account modal when a new contact is created successfully
    onCreate()
  }

  return (
    <>
      <ContactSelectCombobox
        hasError={Boolean(contactValidationError)}
        contactType={contactType}
        hasMore={hasMore}
        loadMore={loadMore}
        inputValue={values.contact}
        isLoading={isLoading}
        items={items}
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onInputClear={onInputClear}
        renderHeader={({ closeMenu }: SelectHeaderRenderProps) => (
          <ContactHeader
            closeMenu={closeMenu}
            onAddNewContact={onAddNewContact}
            setAddContactType={setAddContactType}
            openContactFormModal={openModal}
            contactType={contactType}
          />
        )}
        selectedItem={selectedItem}
      />
      <ContactSimpleForm
        closeModal={closeModal}
        contactType={addContactType}
        isModalOpen={isModalOpen}
        onCreate={handleOnContactCreate}
        showPersonalDetailsInput={showPersonalDetailsInput}
      />
      <ErrorMessageForm
        hasError={Boolean(contactValidationError)}
        errorMessage={contactValidationError}
      />
    </>
  )
}
