import { createTranslations } from '@npco/utils-translations'

export const corporateCardWalkthroughTranslations = createTranslations({
  modalTitle: 'Your Zeller Corporate Card is ready to go!',
  modalBody: 'Discover what features this card has in store for you.',
  modalSecondaryButton: 'Skip Tour',
  modalPrimaryButton: "Let's Go!",
  outstandingExpensesTitle: 'See your team’s outstanding expenses',
  outstandingExpensesTitleManager: 'See your outstanding expenses',
  outstandingExpensesContent:
    'Streamline tax preparation by knowing how many purchases need categorisation, receipts or notes added.',
  outstandingExpensesContentManager:
    'See how many purchases need categorisation, receipts or notes added. Make a purchase with your card to see this in action.',
  remainingBalanceTitle: 'Remaining Balance',
  remainingBalanceContent:
    'View your team’s remaining balances for the current period at a glance. Position your mouse over the bar to see an exact figure.',
  remainingBalanceContentManager:
    'View your remaining balance for the current period at a glance. Position your mouse over the bar to see an exact figure.',
  editOutstandingExpensesTitle: 'Manage expense definitions',
  editOutstandingExpensesContent:
    'Define expense transaction requirements for employees by determining whether receipts, notes and categories are required.',
  readyTitle: "You're ready!",
  readyContent:
    'As an Admin you can lock, close, and manage the spend controls for any card.',
  readyContentManager:
    'You can now view this card’s details to lock, close, or edit the nickname.',
  secondaryTooltipButtonTitle: 'View Card',
})
