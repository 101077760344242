import { Box } from '@npco/zeller-design-system'
import {
  ContactFormData,
  InputSelectComboBoxItem,
} from 'features/Contacts/Contacts.types'

import { BusinessNameField } from '../../Businesses/BusinessSimple.fields'
import * as styled from '../../Contacts.styled'
import { PersonFirstLastNameField } from '../../People/PersonSimple.fields'
import { SubContactComboBox } from '../SubContactComboBox/SubContactComboBox'

interface ContactSimpleFormPersonalDetailsProps {
  modalSubHeading: string
  isBusinessContactType: boolean
  existingBusinessContactName: string | null | undefined
  existingPersonContactName: string | null | undefined
  isAddingNewSubContact: boolean
  setIsAddingNewSubContact: React.Dispatch<React.SetStateAction<boolean>>
  selectedSubContact: InputSelectComboBoxItem | null | undefined
  setSelectedSubContact: React.Dispatch<
    React.SetStateAction<InputSelectComboBoxItem | null | undefined>
  >
  values: ContactFormData
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
}
export const ContactSimpleFormPersonalDetails = ({
  modalSubHeading,
  isBusinessContactType,
  existingBusinessContactName,
  existingPersonContactName,
  isAddingNewSubContact,
  setIsAddingNewSubContact,
  selectedSubContact,
  setSelectedSubContact,
  setFieldValue,
  values,
}: ContactSimpleFormPersonalDetailsProps) => {
  return (
    <>
      <Box mt="2.5rem" mb="1rem">
        <styled.Header>{modalSubHeading}</styled.Header>
      </Box>

      {isAddingNewSubContact && !isBusinessContactType && (
        <BusinessNameField existingContactName={existingBusinessContactName} />
      )}

      {isAddingNewSubContact && isBusinessContactType && (
        <PersonFirstLastNameField
          existingContactName={existingPersonContactName}
        />
      )}

      <SubContactComboBox
        isAddingNewSubContact={isAddingNewSubContact}
        isBusinessContactType={isBusinessContactType}
        selectedSubContact={selectedSubContact}
        setFieldValue={setFieldValue}
        setIsAddingNewSubContact={setIsAddingNewSubContact}
        setSelectedSubContact={setSelectedSubContact}
        values={values}
      />
    </>
  )
}
