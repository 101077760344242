import { BodySmall, H6 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDisabledAccountInfo = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.GREY_30};
  border-radius: 7px;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`

export const StyledInfoMessage = styled(H6)`
  margin: 0;
`
export const StyledInfoDetails = styled(BodySmall)`
  margin: 0;
`
