import { rvNewSites } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { SiteCache } from 'types/site'

export const storageManageNewSite = (newSite: SiteCache) => {
  const sitesInCache = rvNewSites()
  const isNewSiteInCache = sitesInCache.some((site) => site.id === newSite.id)
  return rvNewSites(
    isNewSiteInCache ? sitesInCache : [newSite, ...sitesInCache]
  )
}

export const getStorageDeduplicatedSites = (
  sitesFromMemory: SiteCache[] | undefined,
  sitesFromApi: SiteData[]
): SiteData[] => {
  if (sitesFromMemory) {
    const newSitesNotInDatabase: SiteCache[] = sitesFromMemory.reduce(
      (cacheSites, siteFromMemory) => {
        return sitesFromApi.some((site) => site.id === siteFromMemory.id)
          ? cacheSites
          : [siteFromMemory, ...cacheSites]
      },
      [] as SiteCache[]
    )
    rvNewSites(newSitesNotInDatabase)
    return [...newSitesNotInDatabase, ...sitesFromApi]
  }
  return sitesFromApi
}

export const getNewlyCreatedSiteFromStorage = (
  id: string
): SiteCache | undefined => {
  const sitesInSessionStorage = rvNewSites()
  return sitesInSessionStorage.find((site) => site.id === id)
}
