export const getFlowFlagsCombinedForm = (
  flowFlags?: string | null
): boolean | undefined => {
  if (!flowFlags) {
    return undefined
  }

  try {
    const flag = JSON.parse(flowFlags)?.combinedForm
    if (typeof flag === 'boolean') {
      return flag
    }
    return undefined
  } catch {
    return undefined
  }
}
