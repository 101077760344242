import { useCallback, useState } from 'react'
import { gql } from '@apollo/client'
import { BREAKPOINT, Flex, PageTemplate } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { DebitCardTransactionFilters } from 'components/DebitCardTransactionFilters/DebitCardTransactionFilters'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'

import { CardSingleBreadcrumbs } from '../CardSingleBreadcrumbs/CardSingleBreadcrumbs'
import { CardSingleManageDropdown } from '../CardSingleManageDropdown/CardSingleManageDropdown'
import { CardSingleLayoutProps } from './CardSingleLayout.types'
import { useCardSingleBreadcrumbsQuery } from './hooks/useCardSingleBreadcumbsQuery'

export const CardSingleLayout = ({
  children,
  card,
  areFiltersApplied,
  areFiltersDisabled,
  unlockCard,
  timezone,
}: CardSingleLayoutProps) => {
  const isBelowBreakpointMD = useIsMobileResolution(BREAKPOINT.MD)
  const [areFiltersOpen, setAreFiltersOpen] = useState(false)

  const toggleFilters = useCallback(
    () => setAreFiltersOpen((prev) => !prev),
    []
  )

  const {
    card: breadcrumbsCard,
    isLoadingBreadcrumbsData,
    error: breadcrumbsError,
  } = useCardSingleBreadcrumbsQuery()

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex
            alignItems="center"
            flex={1}
            gap="8px"
            width={1}
            data-testid="card-single-layout"
          >
            <Flex flex={1} style={{ minWidth: '0' }}>
              <CardSingleBreadcrumbs
                error={breadcrumbsError}
                isLoading={isLoadingBreadcrumbsData}
                debitCard={breadcrumbsCard}
              />
            </Flex>
            {isBelowBreakpointMD ? (
              <DebitCardTransactionFilters
                isFilterButtonDisabled={areFiltersDisabled}
                defaultFilters={{
                  selectedAccountID: undefined,
                  selectedDebitCardId: card?.id,
                }}
              />
            ) : (
              <DashboardFiltersButton
                showIndicator={areFiltersApplied}
                onClick={toggleFilters}
                isActive={areFiltersOpen}
                disabled={areFiltersDisabled}
              />
            )}
            {card?.id && (
              <CardSingleManageDropdown
                card={card}
                unlockCard={unlockCard}
                entityAddressTimezone={timezone}
              />
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        isBelowBreakpointMD ? undefined : (
          <FiltersHeaderRow isOpen={areFiltersOpen}>
            <DebitCardTransactionFilters
              isFilterButtonDisabled={false}
              defaultFilters={{
                selectedAccountID: undefined,
                selectedDebitCardId: card?.id,
              }}
            />
          </FiltersHeaderRow>
        )
      }
    >
      {children}
    </PageTemplate>
  )
}

CardSingleLayout.fragments = {
  DebitCardV2: gql`
    fragment CardSingleLayoutDebitCardV2Fragment on DebitCardV2 {
      id
      ...CardSingleManageDropdownDebitCardV2Fragment
    }

    ${CardSingleManageDropdown.fragments.DebitCardV2}
  `,
}
