import {
  BillerContact,
  ContactOrBiller,
} from '../../../bpay-transfer-routing/BPayTransfer.types'

type BillerArgs = {
  to: ContactOrBiller | undefined
  maybeBillerDetail: BillerContact | undefined
}

export const getBillerName = ({ to, maybeBillerDetail }: BillerArgs) => {
  return to?.type === 'Biller'
    ? to?.name ?? '-'
    : maybeBillerDetail?.name ?? '-'
}
