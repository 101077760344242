import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  emailOnlyConfirmation:
    'Are you sure you want to resend {referenceNumber}? The Customer will receive the Invoice email again.',
  error: '{referenceNumber} could not be sent. Please try again.',
  resendInvoice: 'Resend Invoice',
  smsAndEmailConfirmation:
    'Are you sure you want to resend {referenceNumber}? The Customer will receive the Invoice email and SMS again.',
  smsOnlyConfirmation:
    'Are you sure you want to resend {referenceNumber}? The Customer will receive the Invoice SMS again.',
  success: '{referenceNumber} successfully sent.',
})
