import {
  AnnouncementModal,
  ProgressIndicator,
} from '@npco/zeller-design-system'

import BuildBusiness from 'assets/svg/accounts-upgrade-flow/build-your-business.svg'
import { SharedProgressIndicatorWrapper } from 'pages/GlobalModals/UpgradeAccountsCompleteModal/UpgradeAccountCompleteModal.styled'

import { useUpgradeAccountsCompleteModal } from '../../../hooks/useUpgradeAccountsCompleteModal'
import { ImageContainer } from '../../../shared/ImageContainer/ImageContainer'
import { ModalContent } from '../../../shared/ModalContent/ModalContent'
import {
  backButtonLabel,
  nextButtonLabel,
  TOTAL_STEP_COUNT,
} from '../../../UpgradeAccountsCompleteModal.utils'
import {
  description,
  title,
} from './UpgradeAccountsCompleteBuildBusinessStageContent.utils'

export const UpgradeAccountsCompleteBuildBusinessStageContent = () => {
  const { closeModal, goCompleteDebitCardsRewardsStage, goCompleteStartStage } =
    useUpgradeAccountsCompleteModal()

  return (
    <AnnouncementModal
      isOpen
      onClickPrimary={goCompleteDebitCardsRewardsStage}
      onClickSecondary={goCompleteStartStage}
      primaryButtonLabel={nextButtonLabel}
      secondaryButtonLabel={backButtonLabel}
      onCancel={closeModal}
      className="upgrade-accounts-complete"
    >
      <ModalContent
        Illustration={<ImageContainer imagePath={BuildBusiness} />}
        description={description}
        heading={title}
      >
        <SharedProgressIndicatorWrapper mt={{ _: 46, XS: 20 }}>
          <ProgressIndicator
            currentStep={2}
            totalStepCount={TOTAL_STEP_COUNT}
          />
        </SharedProgressIndicatorWrapper>
      </ModalContent>
    </AnnouncementModal>
  )
}
