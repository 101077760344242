import { gql } from '@apollo/client'

export const InvoiceListCoreFields = gql`
  fragment InvoiceListCoreFields on Invoice {
    __typename
    createdTime
    dueAmount
    dueDate
    id
    paidAmount
    paidTime
    payerContactName
    referenceNumber
    sentTime
    startDate
    status
    title
    totalAmount
  }
`
