import { useRef } from 'react'
import { ApolloError, useReactiveVar } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'
import { rvFinishedLoading } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useSelectedTransaction } from 'hooks/useSelectedTransaction/useSelectedTransaction'
import { formatDate, formatToday } from 'utils/date'
import dayjs from 'utils/dayjs'
import { getIsTimedOutError } from 'utils/errors'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import { ListGroupTransactionItem } from 'components/ListGroupTransactionItem/ListGroupTransactionItem'
import { renderGroup } from 'components/Lists/GroupedList/Group/Group.utils'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListGroupTitle } from 'components/Lists/ListGroupTitle/ListGroupTitle'
import { StyledListWrapper } from 'components/Lists/ListWrapper/ListWrapper.styled'
import { LoaderList } from 'components/LoaderList'
import { NoResults } from 'components/Placeholders/NoResults'
import { useNextPreviousTransaction } from 'components/TransactionsDrawer/hooks/useNextPreviousTransaction/useNextPreviousTransaction'
import { TransactionsDrawer } from 'components/TransactionsDrawer/TransactionsDrawer'
import { TransactionTimeoutError } from 'components/TransactionTimeoutError/TransactionTimeoutError'
import { component } from 'translations'

interface TransactionsListProps {
  groupedTransactions: Transaction[][]
  onLoadMore: () => void
  loading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  transactionsError: ApolloError | undefined
}

export const TransactionsList = ({
  groupedTransactions,
  onLoadMore,
  loading,
  isLoadingMore,
  hasMore,
  transactionsError,
}: TransactionsListProps) => {
  const isMappingDone = useReactiveVar(rvFinishedLoading)
  const transactionsRefs = useRef<Record<string, HTMLDivElement | null>>({})

  const { areDetailsOpen, selectedTransaction, onOpen, onClose } =
    useSelectedTransaction()

  const { onSelectNextTransaction, onSelectPreviousTransaction } =
    useNextPreviousTransaction({
      groupedTransactions,
      selectedTransaction,
      transactionsRefs,
    })

  const renderItem = (listItemsData: Transaction) => (
    <ListGroupTransactionItem
      ref={(ref) => {
        transactionsRefs.current[listItemsData.id] = ref
      }}
      key={listItemsData.id}
      data={listItemsData}
      onRecordSelect={onOpen}
    />
  )

  if (!isLoadingMore && (loading || !isMappingDone)) {
    return (
      <Flex flex="1" pt="40px">
        <LoaderList />
      </Flex>
    )
  }

  if (getIsTimedOutError(transactionsError?.graphQLErrors)) {
    return <TransactionTimeoutError />
  }

  const isEmpty = groupedTransactions.length === 0
  if (isEmpty && isMappingDone) {
    return (
      <NoResults
        title={component.noResults.titleTransactions}
        description={component.noResults.descTransactions}
      />
    )
  }

  return (
    <>
      <StyledListWrapper>
        <GroupedList
          data={groupedTransactions}
          onScrollEnd={onLoadMore}
          renderItem={renderItem}
          renderGroup={renderGroup({
            getGroupKey: ([firstElement]) =>
              dayjs(firstElement?.timestamp).startOf('day'),
            renderHeader: ([firstElement]) => (
              <ListGroupTitle data-testid="list-group-header">
                {formatToday(firstElement?.timestamp, formatDate)}
              </ListGroupTitle>
            ),
          })}
          hasMore={hasMore}
        />
      </StyledListWrapper>

      <TransactionsDrawer
        areDetailsOpen={areDetailsOpen}
        onClose={onClose}
        selectedTransaction={selectedTransaction}
        selectNextTransaction={onSelectNextTransaction}
        selectPreviousTransaction={onSelectPreviousTransaction}
      />
    </>
  )
}
