import { ReactiveVar, useReactiveVar } from '@apollo/client'
import {
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'
import { rvSelectedTags } from 'features/Contacts/rv-deprecated/contacts'

import { PickerItemProps, PickerItems } from 'types/picker'

export const useMultiDownshiftState = () => {
  const stateReducer = (
    _state: DownshiftState<PickerItemProps>,
    changes: StateChangeOptions<PickerItemProps>
  ) => {
    if (changes.type === Downshift.stateChangeTypes.clickItem) {
      return {
        ...changes,
        isOpen: true,
      }
    }
    if (changes.type === Downshift.stateChangeTypes.blurInput) {
      return {
        ...changes,
        isOpen: true,
      }
    }

    return changes
  }

  return {
    stateReducer,
  }
}

interface UseMultidownShiftResultsReturnValue {
  selectedItems: PickerItems
  setResult: ReactiveVar<PickerItems>
}

export enum MultiDownshiftComponentName {
  Status,
  Source,
  Type,
  Terminal,
  Tag,
}

export const useMultidownShiftResults = (
  componentName?: MultiDownshiftComponentName
): UseMultidownShiftResultsReturnValue => {
  const statuses = {
    selectedItems: useReactiveVar(rvSelectedStatuses),
    setResult: rvSelectedStatuses,
  }

  const sources = {
    selectedItems: useReactiveVar(rvSelectedSources),
    setResult: rvSelectedSources,
  }

  const terminals = {
    selectedItems: useReactiveVar(rvSelectedTerminals),
    setResult: rvSelectedTerminals,
  }

  const types = {
    selectedItems: useReactiveVar(rvSelectedTypes),
    setResult: rvSelectedTypes,
  }

  const tags = {
    selectedItems: useReactiveVar(rvSelectedTags),
    setResult: rvSelectedTags,
  }

  switch (componentName) {
    case MultiDownshiftComponentName.Status:
      return statuses
    case MultiDownshiftComponentName.Source:
      return sources
    case MultiDownshiftComponentName.Type:
      return types
    case MultiDownshiftComponentName.Tag:
      return tags
    case MultiDownshiftComponentName.Terminal:
    default:
      return terminals
  }
}
