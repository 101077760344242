import {
  ApolloError,
  DocumentNode,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  GetInvoice as GetInvoiceResponse,
  GetInvoiceVariables,
} from 'types/gql-types/GetInvoice'

import { GetInvoice } from '../graphql/getInvoice'

interface UseGetInvoiceProps<T> {
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
  onCompleted?: (data: T) => void
  onError?: (error: ApolloError) => void
  query?: DocumentNode
  referenceNumber: string
}

export const useGetInvoice = <T = GetInvoiceResponse>({
  fetchPolicy,
  nextFetchPolicy,
  onCompleted,
  onError,
  referenceNumber,
  query = GetInvoice,
}: UseGetInvoiceProps<T>) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, error, loading } = useQuery<T, GetInvoiceVariables>(query, {
    fetchPolicy,
    nextFetchPolicy,
    onCompleted,
    onError,
    skip: !referenceNumber,
    variables: { entityUuid, referenceNumber },
  })

  return {
    error,
    invoiceData: data,
    isLoading: loading,
  }
}
