import { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { BREAKPOINT } from 'styles/breakpoints'

import { useSidebarLocalCache } from './hooks/useSidebarLocalCache'

export const useFoldOnMobile = () => {
  const { setIsFolded } = useSidebarLocalCache()
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)
  const location = useLocation()

  useEffect(() => {
    if (isMobileOrTablet) setIsFolded(true)
  }, [isMobileOrTablet, location.pathname, setIsFolded])
}
