import { TransactionDetails } from '../../../bpay-transfer-routing/BPayTransfer.types'

export const getBillerCode = (transferDetails: TransactionDetails) => {
  const billerDetail = transferDetails?.to?.billerContacts?.find(
    (item) => item.id === transferDetails?.paymentInstrumentUuid?.id
  )

  if (transferDetails?.to?.code && transferDetails?.to?.code !== '') {
    return transferDetails?.to?.code
  }
  if (
    typeof transferDetails?.paymentInstrumentUuid?.subLabel1 === 'string' &&
    transferDetails?.paymentInstrumentUuid?.subLabel1 !== ''
  ) {
    return transferDetails?.paymentInstrumentUuid?.subLabel1
  }
  return billerDetail?.code ?? '-'
}
