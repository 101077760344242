import { createTranslations } from '@npco/utils-translations'

export const setupFlowTapToPayStageTranslations = createTranslations({
  title: 'Tap to Pay with Zeller App.',
  description:
    'Accept contactless payments on your smartphone — no additional sign-up, app, or hardware needed.',
  point1: 'Accept contactless payments instantly.',
  point2: 'Add items from Zeller POS Lite for fast checkout',
  point3: 'Email and SMS receipts, tipping and surcharging.',
  point4: 'Available on selected iPhone and Android smartphones.',
})
