import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const LegendWrapper = styled(Flex)`
  ${({ theme }) => theme.typography['body-sm']};
  list-style-type: none;
  padding: 0;
  margin: 0 -32px 0 0;
  flex-grow: 1;
  justify-content: end;
  flex-wrap: wrap;
  width: 170px;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: auto;
  }
`

export const LegendItemsWrapper = styled(Flex)`
  justify-content: end;
  flex-wrap: wrap;
`
