import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as TransactionsIcon } from 'assets/svg/Sidebar/transactions.svg'
import { useGoToTransfer } from 'hooks/useGoToTransfer/useGoToTransfer'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { useGoToBlockedAction } from '../../features/BlockedAction/blocked-action-routing'
import { TransferFlowButtonEntityFragment } from '../../types/gql-types/TransferFlowButtonEntityFragment'
import { StyledTransferButton } from './TransferFlowButton.styled'

interface Props {
  isDisabled?: boolean
  initialFromAccountId?: string
  entity?: TransferFlowButtonEntityFragment
}

export const TransferFlowButton = ({
  isDisabled,
  initialFromAccountId,
  entity,
}: Props) => {
  const isMobile = useIsMobileResolution()
  const { goToTransfer } = useGoToTransfer()
  const { goToBlockedAction } = useGoToBlockedAction()

  const handleTransferNavigation = useCallback(() => {
    if (entity && !entity.canTransferOut) {
      goToBlockedAction({ blockedAction: 'transferOut' })
      return
    }
    goToTransfer({ transferFromAccountId: initialFromAccountId })
  }, [entity, goToBlockedAction, goToTransfer, initialFromAccountId])

  return (
    <StyledTransferButton
      disabled={isDisabled}
      onClick={handleTransferNavigation}
    >
      {isMobile ? (
        <SvgIcon color={COLOR.WHITE}>
          <TransactionsIcon />
        </SvgIcon>
      ) : (
        translate('page.accounts.transfer')
      )}
    </StyledTransferButton>
  )
}

TransferFlowButton.fragments = {
  Entity: gql`
    fragment TransferFlowButtonEntityFragment on Entity {
      id
      canTransferOut
    }
  `,
}
