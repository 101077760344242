import {
  BodyDefault,
  BodyDefaultTypography,
  Box,
  DataSmall,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { LetterBadge } from 'components/LetterBadge'

/**
 * @deprecated
 */
export const StyledTextLarge = styled.span<{
  hasOnCardClick?: boolean
}>`
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
  cursor: ${(props) => (props.hasOnCardClick ? 'pointer' : 'default')};
  word-break: break-all;
`

/**
 * @deprecated
 */
export const StyledTextMedium = styled.span<{
  hasOnCardClick?: boolean
}>`
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
  cursor: ${(props) => (props.hasOnCardClick ? 'pointer' : 'default')};
  word-break: break-all;
`

/**
 * @deprecated
 */
export const StyledTextSmall = styled(DataSmall)<{
  color?: string
  hasOnCardClick?: boolean
}>`
  margin: 0;
  color: ${({ color, theme }) => color || theme.colors.GREY_550};
  cursor: ${(props) => (props.hasOnCardClick ? 'pointer' : 'default')};
`

/**
 * @deprecated
 */
export const StyledLabel = styled.label`
  display: none;
  color: ${({ theme }) => theme.colors.BLUE_120};
  cursor: pointer;
  padding: 10px 20px;
`

/**
 * @deprecated
 */
export const StyledCardLetterBadge = styled(({ className, ...rest }) => (
  <LetterBadge className={className} {...rest} />
))`
  transition: background-color 0.3s ease-out;
`

/**
 * @deprecated
 */
export const StyledNameCard = styled.article<{
  isClickable?: boolean
  hasOnCardClick?: boolean
}>`
  width: auto;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  ${(props) => props.hasOnCardClick && 'cursor: pointer;'};
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${({ isClickable, theme }) =>
      isClickable ? theme.colors.BLUE_80 : 'transparent'};

    &:hover ${StyledLabel} {
      display: block;
    }

    &:hover ${StyledCardLetterBadge} {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`

/**
 * @deprecated
 */
export const StyledIconWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(226, 232, 240, 0.5);
  border-radius: 4px;
  cursor: pointer;
`

/**
 * @deprecated
 */
export const StyledTextLargeClickable = styled(BodyDefault)`
  margin: 0;
  padding: 20px 20px 20px 16px;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  cursor: pointer;
`

/**
 * @deprecated
 */
export const StyledClickableWrapper = styled(Box)`
  cursor: pointer;
  padding: 12px 0 12px 12px;
`

/**
 * @deprecated
 */
export const StyledCardAddWrapper = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  font: inherit;
`

/**
 * @deprecated
 */
export const StyledMobileBadge = styled.span`
  display: none;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: flex;
    color: ${({ theme }) => theme.colors.GREEN_1000};
    font-size: 12px;
  }
`

/**
 * @deprecated
 */
export const StyledBadgeWrapper = styled(Box)`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: none;
  }
`

/**
 * @deprecated
 */
export const StyledCloseIconWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(226, 232, 240, 0.5);
  border-radius: 7px;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: block;
  }
`

/**
 * @deprecated
 */
export const StyledActionLabelWrapper = styled.span`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: none;
  }
`
