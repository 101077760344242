import type { ItemContactOrBiller } from './useBPayTargetItems'

export const getComboboxItemId = (item: ItemContactOrBiller): string => {
  if (item.type === 'Contact') {
    return `contact:${item.id}`
  }
  if (item.type === 'Biller') {
    return `biller:${item.code}`
  }
  throw new TypeError()
}
