import { useState } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'

import { LogosUploadInfo } from '../SiteAppearanceForm/SiteAppearanceForm.types'

const DEFAULT_TIMEOUT = 900000 // 90 seconds

export const useUploadLogosToS3 = () => {
  const [isUploading, setIsUploading] = useState(false)

  const upload = async (logosUploadInfo: LogosUploadInfo[]) => {
    setIsUploading(true)
    await Promise.all(
      logosUploadInfo.map(async (logoUploadInfo) => {
        try {
          const controller = new AbortController()
          const { signal } = controller
          const id = setTimeout(() => {
            controller.abort()
          }, DEFAULT_TIMEOUT)
          const response = await fetch(logoUploadInfo.url, {
            signal,
            method: 'POST',
            body: logoUploadInfo.formData,
          })
          if (!response.ok) {
            throw Error(`Failed to upload to bucket ${logoUploadInfo.url}`)
          }
          clearTimeout(id)
        } catch (error) {
          ErrorLogger.report('[Core] Upload to bucket', error)
        }
      })
    )

    setIsUploading(false)
  }

  return {
    upload,
    isUploading,
  }
}
