import { Box, COLOR, SvgIcon } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { ReactComponent as MobileIcon } from 'assets/svg/mobile.svg'
import { validateMobileNumber } from 'utils/formValidation'
import { IdentityPhoneRegisterVariables } from 'types/gql-types/IdentityPhoneRegister'
import { StyledFormWrapper } from 'pages/Login/Login.styled'
import { InputFieldWithoutLabelWrapper } from 'components/InputAdaptiveManagers/InputFieldWithoutLabelWrapper'
import { page } from 'translations'

import { ButtonNext } from '../Buttons/ButtonNext'
import { StyledButtonsGroupRowWrapper } from '../Buttons/ButtonsGroupRowWrapper'

interface Props {
  handleSubmit: (values: IdentityPhoneRegisterVariables) => void
  submitButtonText: string
  backButton?: React.ReactNode
}

export const RegisterPhone = ({
  handleSubmit,
  submitButtonText,
  backButton,
}: Props) => {
  return (
    <Formik
      initialValues={{
        phone: '',
      }}
      onSubmit={handleSubmit}
      component={({ isSubmitting, dirty }) => (
        <StyledFormWrapper>
          <Box mb="2.5rem">
            <InputFieldWithoutLabelWrapper
              icon={MobileIcon}
              name="phone"
              type="tel"
              placeholder={page.registerPhone.inputPlaceholder}
              validate={validateMobileNumber}
              renderLeftControls={({ hasError }) => (
                <SvgIcon
                  width="16"
                  height="16"
                  withMarginRight
                  color={hasError ? COLOR.RED_1000 : COLOR.BLUE_1000}
                >
                  <MobileIcon />
                </SvgIcon>
              )}
              autoFocus
            />
          </Box>
          <StyledButtonsGroupRowWrapper>
            <ButtonNext disabled={isSubmitting || !dirty} type="submit">
              {submitButtonText}
            </ButtonNext>
            {backButton}
          </StyledButtonsGroupRowWrapper>
        </StyledFormWrapper>
      )}
    />
  )
}
