import { useCallback, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { Dayjs } from 'dayjs'

import dayjs from 'utils/dayjs'

import {
  getEndOfCurrentWindow,
  getIsCurrentTimeAfterOrEqualToResetTime,
} from '../../utils/getEndOfCurrentWindow'
import { UseSpentFundsCorporateCardDebitCardV2Fragment } from './useSpentFundsCorporateCard.generated'

interface UseSpentFundsCorporateCardProps {
  card?: UseSpentFundsCorporateCardDebitCardV2Fragment
  timezone?: string
}

export const useSpentFundsCorporateCard = ({
  card,
  timezone,
}: UseSpentFundsCorporateCardProps) => {
  const getSpentFunds = useCallback(
    (now: Dayjs) => {
      if (!card?.velocityControl) {
        return 0
      }

      if (
        getIsCurrentTimeAfterOrEqualToResetTime(
          card.velocityControl.resetsAt,
          now
        )
      ) {
        return 0
      }

      return parseInt(card.velocityControl.totalSpentAmount.value, 10)
    },
    [card]
  )

  const [spentFunds, setSpentFunds] = useState(() => getSpentFunds(dayjs()))

  useEffect(() => {
    if (card?.velocityControl) {
      const now = dayjs()

      const timeUntilWindowChanges =
        getEndOfCurrentWindow({
          velocityControl: card?.velocityControl,
          now,
          timezone,
        }).valueOf() - now.valueOf()

      setSpentFunds(getSpentFunds(now))

      const timeout = setTimeout(() => {
        const futureTime = dayjs()
        setSpentFunds(getSpentFunds(futureTime))
      }, timeUntilWindowChanges)

      return () => clearTimeout(timeout)
    }

    return undefined
  }, [card?.velocityControl, getSpentFunds, timezone])

  return spentFunds
}

useSpentFundsCorporateCard.fragments = {
  DebitCardV2: gql`
    fragment UseSpentFundsCorporateCardDebitCardV2Fragment on DebitCardV2 {
      velocityControl {
        resetsAt
        totalSpentAmount {
          value
        }
        ...GetEndOfCurrentWindowVelocityControlTypeFragment
      }
    }

    ${getEndOfCurrentWindow.fragments.VelocityControlType}
  `,
}
