import { shared } from 'translations'

import { StyledGhostButton } from './BottomRow.styled'
import { StyledChildrenRow, StyledRow } from './Elements.styled'

interface BottomRowProps {
  handleClearAll: () => void
  handleSetInput?: (input: string) => void
  isClearButtonDisabled?: boolean
}

export const BottomRow: React.FC<BottomRowProps> = ({
  children,
  handleClearAll,
  handleSetInput,
  isClearButtonDisabled,
}) => (
  <StyledRow withTopBorder>
    <StyledChildrenRow>{children}</StyledChildrenRow>
    <StyledGhostButton
      data-testid="clear-selection"
      onClick={() => {
        handleClearAll()
        if (handleSetInput) {
          handleSetInput('')
        }
      }}
      disabled={isClearButtonDisabled}
    >
      {shared.clear}
    </StyledGhostButton>
  </StyledRow>
)
