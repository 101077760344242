import { useContext, useEffect } from 'react'

import {
  CardsListLayoutContext,
  HeaderButtonsVisibilityType,
} from '../../CardsListLayoutContext'

export const useToggleHeaderButtonsVisibility = ({
  settings,
  addCard,
  activateCard,
}: HeaderButtonsVisibilityType) => {
  const context = useContext(CardsListLayoutContext)

  if (!context) {
    throw new Error(
      'useHeaderButtonsVisibilitySetter must be used within CardsListLayoutContext'
    )
  }

  const { setIsHeaderButtonsVisible } = context

  useEffect(() => {
    setIsHeaderButtonsVisible({
      settings,
      addCard,
      activateCard,
    })

    return () => {
      setIsHeaderButtonsVisible({
        settings: false,
        addCard: false,
        activateCard: false,
      })
    }
  }, [settings, addCard, activateCard, setIsHeaderButtonsVisible])
}
