import { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  rvSalesErrorTracker,
  rvSitePerformanceErrorTracker,
  rvTransactionsErrorTracker,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/dashboard'

export const useDashboardErrors = () => {
  const salesRetry = useReactiveVar(rvSalesErrorTracker)
  const sitePerformanceRetry = useReactiveVar(rvSitePerformanceErrorTracker)
  const transactionsRetry = useReactiveVar(rvTransactionsErrorTracker)

  const [shouldDisplayError, setShouldDisplayError] = useState(false)

  useEffect(() => {
    if (
      salesRetry !== null &&
      sitePerformanceRetry !== null &&
      transactionsRetry !== null
    ) {
      setShouldDisplayError(true)
    }
  }, [salesRetry, sitePerformanceRetry, transactionsRetry])

  const retry = useMemo(() => {
    if (!shouldDisplayError) {
      return null
    }

    return () => {
      setShouldDisplayError(false)
      salesRetry?.()
      sitePerformanceRetry?.()
      transactionsRetry?.()
    }
  }, [salesRetry, shouldDisplayError, sitePerformanceRetry, transactionsRetry])

  return { shouldDisplayError, retry }
}
