import {
  BodyDefaultTypography,
  Heading,
  HeadingStyles,
  StyledStatusText,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { DebitCardTransactionStatusBadge } from 'components/StatusBadge/DebitCardTransactionStatusBadge/DebitCardTransactionStatusBadge'

export const StyledListElement = styled.li`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.625rem;

  &:last-child {
    padding-bottom: 0;
  }
`

export const StyledListKey = styled(Heading.H4).attrs({
  withStyles: HeadingStyles.H5,
})`
  margin: 0;
  padding-right: 1rem;
`

export const StyledListValue = styled.span<{
  isCapitalised?: boolean
  enableEllipsis?: boolean
}>`
  flex-grow: 1;
  text-align: right;
  padding-left: 1rem;
  text-transform: ${({ isCapitalised }) =>
    isCapitalised ? 'capitalize' : 'none'};
  ${BodyDefaultTypography};
  ${({ enableEllipsis = true }) =>
    enableEllipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

export const StyledStatus = styled(DebitCardTransactionStatusBadge)`
  flex-grow: 1;
  text-align: right;
  padding-left: 1rem;
  justify-content: end;

  ${StyledStatusText} {
    ${BodyDefaultTypography};
  }
`
