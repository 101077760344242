import { ChangeEvent, useState } from 'react'
import { Checkbox, DrawerPaddedContainer } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import {
  CheckAll,
  CheckAllContainer,
  CheckAllLabel,
  ErrorMessage,
  ShowAll,
} from '../../components/Form.styled'
import { Values } from '../OrganisationAccountForm.types'
import { AccountRow } from './AccountRow'
import { XeroAccountWithSelected } from './AccountSelect.types'

interface AccountSelectProps {
  accounts: XeroAccountWithSelected[]
}

const DISPLAY_LIMIT = 5

export const AccountSelect = ({ accounts }: AccountSelectProps) => {
  const { handleChange, setFieldValue, values, errors, touched } =
    useFormikContext<Values>()
  const [shouldShowAll, setShouldShowAll] = useState(false)

  const selectedAccounts = shouldShowAll
    ? accounts
    : accounts.slice(0, DISPLAY_LIMIT)

  const selectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      const allIds = accounts.map((account) => account?.id)
      setFieldValue('accounts', allIds)
      return
    }
    setFieldValue('accounts', [])
  }

  return (
    <>
      <DrawerPaddedContainer>
        <CheckAllContainer alignItems="center">
          <CheckAll>
            <CheckAllLabel>
              {translate(
                'page.settings.connections.xeroBankFeeds.accountSelect.connectAll'
              )}
            </CheckAllLabel>
            <Checkbox
              name="checkall"
              type="checkbox"
              onChange={selectAll}
              component="div"
            />
          </CheckAll>
        </CheckAllContainer>
      </DrawerPaddedContainer>
      {selectedAccounts.map((account) => (
        <AccountRow
          id={account?.id}
          key={account?.id}
          label={account?.name}
          checked={values.accounts?.includes(account?.id)}
          onChange={handleChange}
          icon={account?.icon}
        />
      ))}
      {accounts.length > DISPLAY_LIMIT &&
        selectedAccounts.length !== accounts.length && (
          <DrawerPaddedContainer>
            <ShowAll onClick={() => setShouldShowAll(!shouldShowAll)}>
              {translate(
                'page.settings.connections.xeroBankFeeds.accountSelect.showAllAccounts',
                { total: accounts.length }
              )}
            </ShowAll>
          </DrawerPaddedContainer>
        )}
      {errors?.accounts && touched?.accounts && (
        <ErrorMessage>{errors.accounts}</ErrorMessage>
      )}
    </>
  )
}
