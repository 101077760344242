import { CustomerRole } from '@npco/mp-gql-types'

import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { useAssignSites } from 'components/AssignSites/AssignSites.hooks'

import { useGetEntitySites } from './useGetEntitySites/useGetEntitySites'

export const useGetUserSites = ({
  initialRole,
  isAdmin,
  isEditForm,
}: {
  initialRole: CustomerRole
  isAdmin: boolean
  isEditForm: boolean
}) => {
  const { entitySites } = useGetEntitySites()
  const { userSites, setUserSites } = useAssignSites({
    initialRole,
    isAdmin,
    isEditForm,
    entitySites,
  })

  const removeSite = (siteId: string) => {
    const assignedUserSites = userSites.filter(
      (site: SiteData) => site.id !== siteId
    )
    setUserSites(assignedUserSites)
  }

  const addSites = (addedSites: SiteData[]) => {
    const newSites = [...userSites, ...addedSites]
    setUserSites(newSites)
  }

  return {
    userSites,
    removeSite,
    addSites,
  }
}
