import { useYourCardsListQuery } from './hooks/useYourCardsListQuery'
import { YourCardsListError } from './YourCardsListError'
import { YourCardsListHeader } from './YourCardsListHeader'
import { YourCardsListView } from './YourCardsListView'

export const YourCardsList = () => {
  const { isLoading, cards, entity, error, refetch } = useYourCardsListQuery()

  if (error && cards.length === 0 && !isLoading) {
    return <YourCardsListError retry={refetch} />
  }

  return (
    <>
      <YourCardsListHeader />
      <YourCardsListView cards={cards} entity={entity} isLoading={isLoading} />
    </>
  )
}
