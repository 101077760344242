import { ButtonLink, Flex } from '@npco/zeller-design-system'

import { DescriptionText } from './AddressToggle.styled'

interface AddressToggleProps {
  toggleDescription: string
  buttonLabel: string
  onClick: () => void
}

export const AddressToggle = ({
  toggleDescription,
  buttonLabel,
  onClick,
}: AddressToggleProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mt="12px">
      <DescriptionText>{toggleDescription}</DescriptionText>
      <ButtonLink onClick={onClick}>{buttonLabel}</ButtonLink>
    </Flex>
  )
}
