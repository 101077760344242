import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { blockedActionModalContentTranslations } from 'features/BlockedAction/blocked-action-modal/BlockedActionModal/BlockedActionModalContent/BlockedActionModalContent.i18n'
import {
  BlockedAction,
  useGoToBlockedAction,
} from 'features/BlockedAction/blocked-action-routing'

import { ROOT } from 'const/routes'

export const useBlockedActionModalContent = () => {
  const t = useTranslations(blockedActionModalContentTranslations)
  const navigate = useNavigate()
  const { goToBlockedAction } = useGoToBlockedAction()
  const shortEntityId = useSelectedShortEntityUuid()

  const handleGoToContactZellerSupport = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).HELP.path, {
      state: undefined,
    })
  }, [navigate, shortEntityId])

  const handleCloseModal = useCallback(() => {
    goToBlockedAction(null)
  }, [goToBlockedAction])

  const getBlockedActionMessage = useCallback(
    (blockedAction?: BlockedAction) => {
      switch (blockedAction) {
        case 'createAccount':
          return t('blockedActionCopyCreateAccount')
        case 'createCard':
          return t('blockedActionCopyCreateCard')
        case 'transferOut':
          return t('blockedActionCopyTransferOut')
        default:
          return undefined
      }
    },
    [t]
  )

  return {
    handleGoToContactZellerSupport,
    handleCloseModal,
    getBlockedActionMessage,
  }
}
