import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledLabel = styled.label<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${BodyDefaultTypography};

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    padding-bottom: 2rem;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.GREY_400};
      cursor: default;
    `};
`
