import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useEditThirdPartyAccountDetail } from 'hooks/useEditThirdPartyAccountDetail'
import { BankAccount, ThirdPartyBankAccount } from 'types/accounts'
import { page } from 'translations'

interface Values {
  accountName: string
  colorPicked?: string
}

interface Parameters {
  onSubmit: (account: any) => void
}

export const useSubmitEditAccountDetail = ({ onSubmit }: Parameters) => {
  const onSuccess = (account: BankAccount) => {
    showSuccessToast(page.accounts.editedSuccess)
    onSubmit(account)
  }
  const onFailure = () => showErrorToast()

  const { loading: isSubmittingThirdPartyAccount, editAccountName } =
    useEditThirdPartyAccountDetail({
      onSuccess: (account) => onSuccess(account as ThirdPartyBankAccount),
      onFailure,
    })

  const editAccountDetail = (value: Values, accountId: string) => {
    return editAccountName(value.accountName, accountId)
  }

  return {
    submitting: isSubmittingThirdPartyAccount,
    editAccountDetail,
  }
}
