import { useEffect, useMemo } from 'react'
import { ReactiveVar } from '@apollo/client'
import { SiteType } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'
import {
  SelectCompactField,
  SelectCompactItemWithIcon,
  SelectItemBasic,
  SelectSize,
} from 'design-system/Components/Select'
import { SelectTriggerAdaptive } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerAdaptive'
import { useFormikContext } from 'formik'

import { ReactComponent as MobileSiteIcon } from 'assets/svg/car.svg'
import { ReactComponent as SiteIcon } from 'assets/svg/union.svg'
import { StatementsFormValues } from 'types/utils'
import { useGetSitesWithTypes } from 'components/Statements/hooks/useGetSitesWithTypes'
import { ALL_SITES } from 'components/Statements/Statements.utils'
import { page } from 'translations'

interface Option extends SelectItemBasic {
  icon: React.ReactNode
}

interface Props {
  reactiveVariable: ReactiveVar<string>
}

export const StatementsSitesField = ({ reactiveVariable }: Props) => {
  const { values } = useFormikContext<StatementsFormValues>()

  const { sites } = useGetSitesWithTypes()

  useEffect(() => {
    reactiveVariable(values.sites)
  }, [reactiveVariable, values])

  const items = useMemo(() => {
    return [
      {
        label: page.settings.statements.allSites,
        value: ALL_SITES,
        icon: <SiteIcon />,
      },
      ...sites.map((site) => ({
        value: site.id,
        label: site.name,
        icon: site.type === SiteType.FIXED ? <SiteIcon /> : <MobileSiteIcon />,
      })),
    ]
  }, [sites])

  return (
    <Box mb="2.5rem" mt="5px">
      <SelectCompactField<Option>
        name="sites"
        mobileLabel={page.settings.statements.selectSite}
        items={items}
        renderTrigger={(renderProps) => (
          <SelectTriggerAdaptive
            {...renderProps}
            label={page.settings.statements.selectLabel}
            placeholder={page.settings.statements.allSites}
            size={SelectSize.Small}
          />
        )}
        renderItem={(renderProps) => (
          <SelectCompactItemWithIcon {...renderProps} />
        )}
      />
    </Box>
  )
}
