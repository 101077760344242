import { useCallback } from 'react'
import { Box, ButtonLink, Flex } from '@npco/zeller-design-system'
import { ModalWithButtons, useModalState } from 'design-system/Components/Modal'
import { ContactDetails } from 'features/Contacts/Contact/ContactSections/ContactDetails/ContactDetails'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { useGetContactTransactionTotals } from 'hooks/useGetContactTransactionTotals/useGetContactTransactionTotals'
import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { SpinnerWrapped } from 'components/Spinner'
import { UnlinkingContactSpinner } from 'components/UnlinkingContactSpinner/UnlinkingContactSpinner'

import { TransactionTotals } from './TransactionTotals/TransactionTotals'
import { ViewContactSection } from './ViewContactSection/ViewContactSection'

interface LinkedTransactionProps {
  contact: ContactCoreFieldsFragment | null
  contactUuid: string
  cardholderUuid: string
  getContact: (contactUuid: string) => Promise<void>
  hasGetContactError: boolean
  isLoading: boolean
  onUnlinkContact: ({
    cardholderUuid,
    contactUuid,
  }: {
    cardholderUuid: string
    contactUuid: string
  }) => void
  hasUnlinkContactError: boolean
  isUnlinkingContact: boolean
}

export const LinkedTransaction = ({
  contact,
  contactUuid,
  cardholderUuid,
  getContact,
  hasGetContactError,
  isLoading,
  onUnlinkContact: handleUnlinkContact,
  hasUnlinkContactError,
  isUnlinkingContact,
}: LinkedTransactionProps) => {
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const { isContactTransactionTotalsLoading, transactionTotals } =
    useGetContactTransactionTotals({ contactUuid, cardholderUuid })

  const {
    isModalOpen: isUnlinkModalOpen,
    openModal: openUnlinkModal,
    closeModal: closeUnlinkModal,
  } = useModalState()

  const renderHeaderAction = useCallback(
    () => (
      <ButtonLink onClick={openUnlinkModal}>
        {translate('page.transactions.contact.unlink')}
      </ButtonLink>
    ),
    [openUnlinkModal]
  )

  const unlinkContact = async () => {
    handleUnlinkContact({ cardholderUuid, contactUuid })
    if (!hasUnlinkContactError) {
      trackAnalyticsEvent(AnalyticsEventNames.CONTACT_UNLINKED_CARDHOLDER, {
        Location: locationName.current,
      })
    }
    closeUnlinkModal()
  }

  if (isLoading) {
    return <SpinnerWrapped variant="top" />
  }

  if (isUnlinkingContact) {
    return <UnlinkingContactSpinner />
  }

  if (hasGetContactError) {
    return (
      <GenericErrorPage
        data-testid="get-contact-error"
        retry={() => getContact(contactUuid)}
      />
    )
  }

  if (hasUnlinkContactError) {
    return (
      <GenericErrorPage
        data-testid="unlink-contact-error"
        retry={() => handleUnlinkContact({ cardholderUuid, contactUuid })}
      />
    )
  }

  // NOTE: theoretically this should never happen here but adding a check to
  // satisfy the type constraint and to ensure we have an appropriate fallback
  if (!contact) {
    return (
      <GenericErrorPage
        data-testid="missing-contact-error"
        retry={() => getContact(contactUuid)}
      />
    )
  }

  return (
    <Flex
      data-testid="linked-contact-transaction"
      flexDirection="column"
      width="100%"
    >
      <TransactionTotals
        isLoading={isContactTransactionTotalsLoading}
        transactionData={transactionTotals}
      />

      <Box mb="40px">
        <ContactDetails
          canEditCategories={false}
          contact={contact}
          renderHeaderAction={renderHeaderAction}
        />
      </Box>

      <ViewContactSection contact={contact} />

      <ModalWithButtons
        hasCancelButton
        isOpen={isUnlinkModalOpen}
        onCancel={closeUnlinkModal}
        onConfirm={unlinkContact}
        primaryButtonLabel={translate('shared.continue')}
        secondaryButtonLabel={translate('shared.cancel')}
        title={translate('page.transactions.contact.unlinkModalTitle')}
      >
        {translate('page.transactions.contact.unlinkModalDescription')}
      </ModalWithButtons>
    </Flex>
  )
}
