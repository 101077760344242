import { Box, Heading, HeadingStyles } from '@npco/zeller-design-system'

import { NoResultsDescription } from './NoResultsForSearch.styled'

interface NoResultsForSearchProps {
  title: string
  subtitle: string
  subtitle2?: React.ReactNode
  marginTop?: string
}
export const NoResultsForSearch = ({
  title,
  subtitle,
  subtitle2,
  marginTop = '96px',
}: NoResultsForSearchProps) => (
  <Box textAlign="center" marginTop={marginTop}>
    <Heading.H2 withStyles={HeadingStyles.H3}>{title}</Heading.H2>
    <Box mt="16px" textAlign="center">
      <NoResultsDescription>{subtitle}</NoResultsDescription>
      {subtitle2 && <NoResultsDescription>{subtitle2}</NoResultsDescription>}
    </Box>
  </Box>
)
