import { useCallback, useEffect, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { DotLottie, DotLottieReact } from '@lottiefiles/dotlottie-react'
import { getImageUrl } from '@npco/mp-utils-icon'
import { getColorFromResponse } from '@npco/utils-colors'

import { MerchantAvatar } from '../components/MerchantAvatar/MerchantAvatar'
import { UseIconAvatarIconFragment } from './useIconAvatar.generated'

interface UseIconAvatarProps {
  icon: UseIconAvatarIconFragment | null
  defaultLetter: string
  defaultBgColor?: string
  defaultUrl?: string
  allowAnimation?: boolean
}

export const useIconAvatar = ({
  icon,
  defaultLetter,
  defaultBgColor,
  defaultUrl,
  allowAnimation,
}: UseIconAvatarProps) => {
  const animationUrl = icon?.animation
  const avatarUrl = getImageUrl(icon)

  const [dotLottie, setDotLottie] = useState<DotLottie | null>(null)
  const [isAnimationError, setIsAnimationError] = useState(false)

  useEffect(() => {
    if (!dotLottie) {
      return undefined
    }

    const onLoadError = () => {
      setIsAnimationError(true)
    }

    dotLottie.addEventListener('loadError', onLoadError)

    return () => {
      dotLottie.removeEventListener('loadError', onLoadError)
    }
  }, [dotLottie])

  const [isAvatarError, setIsAvatarError] = useState(false)

  const handleUserDefinedContentError = useCallback(() => {
    setIsAvatarError(true)
  }, [])

  const animationContent = useMemo(() => {
    if (!allowAnimation || !animationUrl || isAnimationError) {
      return null
    }

    return (
      <DotLottieReact
        src={animationUrl}
        loop
        autoplay
        dotLottieRefCallback={setDotLottie}
      />
    )
  }, [allowAnimation, animationUrl, isAnimationError])

  const imageContent = useMemo(() => {
    if (!(avatarUrl || defaultUrl) || isAvatarError) {
      return null
    }

    return (
      <MerchantAvatar
        merchantAvatarUrl={avatarUrl ?? defaultUrl}
        onError={handleUserDefinedContentError}
      />
    )
  }, [avatarUrl, defaultUrl, handleUserDefinedContentError, isAvatarError])

  const avatarProps = useMemo(
    () => ({
      userDefinedContent: animationContent ?? imageContent ?? undefined,
      text: icon?.letter ? icon.letter : defaultLetter,
      bgColor: getColorFromResponse(icon?.colour ?? defaultBgColor),
    }),
    [
      animationContent,
      imageContent,
      icon?.letter,
      icon?.colour,
      defaultLetter,
      defaultBgColor,
    ]
  )

  return avatarProps
}

useIconAvatar.fragments = {
  Icon: gql`
    fragment UseIconAvatarIconFragment on Icon {
      colour
      letter
      animation
      ...GetImageUrlIconFragment
    }

    ${getImageUrl.fragments.Icon}
  `,
}
