import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from 'const/envs'
import { sendToDataLayer } from 'utils/gtmHelper'

export const useGtm = () => {
  useEffect(() => {
    const isDatadogUserAgent = /DatadogSynthetics/.test(
      window.navigator.userAgent
    )

    if (isDatadogUserAgent || !GTM_ID) {
      return
    }

    // Disabling typescript to force default values in window before TagManager do it's job
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.IdentifyInvoked = window.IdentifyInvoked || false
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer = window.dataLayer || []

    const tagManagerArgs = {
      auth: GTM_AUTH,
      gtmId: GTM_ID,
      preview: GTM_PREVIEW,
    }

    TagManager.initialize(tagManagerArgs)

    sendToDataLayer({ event: 'Initialised' })
  }, [])
}
