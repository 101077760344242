import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type RequestNewSetupFlowDebitCardMutationVariables = Types.Exact<{
  input: Types.RequestNewDebitCardInput
  entityUuid: Types.Scalars['ID']['input']
}>

export type RequestNewSetupFlowDebitCardMutationResponse = {
  __typename?: 'Mutation'
  requestNewDebitCard: {
    __typename?: 'RequestNewDebitCardResult'
    card: {
      __typename?: 'DebitCardV2'
      id: string
      customer: {
        __typename?: 'Customer'
        id: string
        entityUuid: string | null
        firstname: string | null
        lastname: string | null
      } | null
    } | null
  }
}

export const RequestNewSetupFlowDebitCardMutation = gql`
  mutation RequestNewSetupFlowDebitCardMutation(
    $input: RequestNewDebitCardInput!
    $entityUuid: ID!
  ) {
    requestNewDebitCard(input: $input, entityUuid: $entityUuid) {
      card {
        id
        customer {
          id
          entityUuid
          firstname
          lastname
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  RequestNewSetupFlowDebitCardMutationResponse,
  RequestNewSetupFlowDebitCardMutationVariables
>
export type RequestNewSetupFlowDebitCardMutationMutationFn =
  Apollo.MutationFunction<
    RequestNewSetupFlowDebitCardMutationResponse,
    RequestNewSetupFlowDebitCardMutationVariables
  >

/**
 * __useRequestNewSetupFlowDebitCardMutation__
 *
 * To run a mutation, you first call `useRequestNewSetupFlowDebitCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestNewSetupFlowDebitCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestNewSetupFlowDebitCardMutation, { data, loading, error }] = useRequestNewSetupFlowDebitCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useRequestNewSetupFlowDebitCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestNewSetupFlowDebitCardMutationResponse,
    RequestNewSetupFlowDebitCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestNewSetupFlowDebitCardMutationResponse,
    RequestNewSetupFlowDebitCardMutationVariables
  >(RequestNewSetupFlowDebitCardMutation, options)
}
export type RequestNewSetupFlowDebitCardMutationHookResult = ReturnType<
  typeof useRequestNewSetupFlowDebitCardMutation
>
export type RequestNewSetupFlowDebitCardMutationMutationResult =
  Apollo.MutationResult<RequestNewSetupFlowDebitCardMutationResponse>
export type RequestNewSetupFlowDebitCardMutationMutationOptions =
  Apollo.BaseMutationOptions<
    RequestNewSetupFlowDebitCardMutationResponse,
    RequestNewSetupFlowDebitCardMutationVariables
  >
