import { z } from 'zod'

export const CORPORATE_CARD_JOYRIDE_STAGE = [
  'outstandingExpenses',
  'remainingBalance',
  'editOutstandingExpenses',
  'youAreReady',
]

export const CORPORATE_CARD_WALKTHROUGH_STAGE = [
  'entryModal',
  ...CORPORATE_CARD_JOYRIDE_STAGE,
]

export type CorporateCardWalkthroughStage =
  | 'entryModal'
  | 'outstandingExpenses'
  | 'remainingBalance'
  | 'editOutstandingExpenses'
  | 'youAreReady'

export const CorporateCardWalkthroughLocationSchema = z.object({
  CorporateCardWalkthroughModal: z.optional(
    z.nullable(
      z.object({
        stage: z.union([
          z.literal('entryModal'),
          z.literal('outstandingExpenses'),
          z.literal('remainingBalance'),
          z.literal('editOutstandingExpenses'),
          z.literal('youAreReady'),
        ]),
      })
    )
  ),
})
