import { Outlet } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { PageTemplate } from '@npco/zeller-design-system'

import { ExpensesHeaderPrimaryRow } from './ExpensesHeaderPrimaryRow/ExpensesHeaderPrimaryRow'
import { ExpensesHeaderSecondaryRow } from './ExpensesHeaderSecondaryRow/ExpensesHeaderSecondaryRow'

export const ExpensesLayout = () => {
  const { userRole } = useLoggedInUser()
  const isAdmin = userRole === CustomerRole.ADMIN
  return (
    <PageTemplate
      HeaderPrimaryRow={<ExpensesHeaderPrimaryRow />}
      HeaderSecondaryRow={isAdmin && <ExpensesHeaderSecondaryRow />}
    >
      <Outlet />
    </PageTemplate>
  )
}
