import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
`

export const StyledIconWrapper = styled.button`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 7px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_30};
    text-decoration: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.GREY_250};
    text-decoration: none;
  }
`

export const StyledIconWrapperWithBackground = styled(StyledIconWrapper)`
  background-color: ${({ theme }) => theme.colors.BLUE_1000};

  &:hover {
    background-color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

export const StyledLoaderWrapper = styled.div`
  display: block;
  animation: ${animation} 1s linear infinite;
`
