import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { GetDebitCardAccounts_getDebitCardAccountsV2_accounts_icon_images as Image } from 'types/gql-types/GetDebitCardAccounts'

export interface ZellerAccountNameId {
  id: string
  name: string
}

export interface DebitCard {
  id: string
  name: string
  maskedPan: string
}

export interface DebitCardIcon {
  colour: string
  letter: string
  images: Image[] | null
}

export interface DebitCardAccount {
  id: string
  name: string
  balance: {
    currency?: string
    value: number
  }
  icon: DebitCardIcon
  status: DebitCardAccountStatus
  cardsCount: {
    total: number
    debit: number
    expense: number
  }
}

export interface ThirdPartyAccount {
  accountBsb: string
  accountName: string
  accountNumber: string
}

export interface ThirdPartyBankAccount {
  id: string
  accountBsb: string | null
  accountName: string | null
  accountNumber: string | null
  name: string | null
}

export type BankAccount = ThirdPartyBankAccount | DebitCardAccount | null

export enum ThirdPartyAccountType {
  SimBilling = 'SimBilling',
  Deposit = 'Deposit',
}
