import styled from 'styled-components'

import { ReactComponent as ChevronDownIcon } from 'assets/svg/arrow-down.svg'

export const Row = styled.div`
  display: flex;
  align-items: center;

  gap: 0 8px;
  padding: 12px 16px;

  height: 24px;
`

export const LabelName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 4px;
`

export const ulGroups = styled.ul`
  /* reset */
  margin: 0;
  padding: 0;
  list-style-type: none;
`

export const liGroup = styled.li`
  display: flex;
  flex-direction: column;
`

export const ulGroupItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  padding-top: 0;
  margin-left: 32px;

  /* reset */
  list-style-type: none;
`

export const liGroupItem = styled.li``

export const GroupItemLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const GroupExpander = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-width: 0;
  background-color: transparent;
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:focus-visible {
    outline: ${({ theme }) => theme.colors.BLUE_1000} auto 1px;
  }
`

export const GroupExpanderChevron = styled(ChevronDownIcon)<{ $open: boolean }>`
  transform: scaleY(${({ $open }) => ($open ? '-1' : '1')});
  transition: transform 0.15s ease-in-out;
`
