import { gql } from '@apollo/client'

export const ExportDebitCardTransactions = gql`
  subscription ExportDebitCardTransactions(
    $entityUuid: ID!
    $customerUuid: ID!
    $filter: String!
    $format: DebitCardTransactionsExportFormat!
  ) {
    exportDebitCardTransactions(
      entityUuid: $entityUuid
      customerUuid: $customerUuid
      filter: $filter
      format: $format
    ) {
      downloadLink
      expire
      error
    }
  }
`
