import { useTranslations } from '@npco/utils-translations'
import { HeaderContext } from '@tanstack/react-table'

import { HeaderCellText } from '../../../../../TransactionsSummary/TableCells/TableHeaderCells/HeaderCellText'
import { headerCellTranslations } from './HeaderCells.i18n'

export const SubcategoryHeaderCell = <T,>({
  header,
  column,
}: HeaderContext<T, string>) => {
  const t = useTranslations(headerCellTranslations)
  return (
    <HeaderCellText header={header} column={column}>
      {t('subcategory')}
    </HeaderCellText>
  )
}
