import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import {
  GetContactAvatarOptionsContactFragmentDoc,
  GetContactNameContactFragmentDoc,
} from '@npco/mp-utils-contacts-format'

export type ContactCellCashFlowContactNetAmountFragment = {
  __typename?: 'CashFlowContactNetAmount'
  contact: {
    __typename: 'Contact'
    id: string
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    lastName: string | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
}

export const ContactCellCashFlowContactNetAmountFragmentDoc = gql`
  fragment ContactCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    contact {
      __typename
      id
      ...GetContactAvatarOptionsContactFragment
      ...GetContactNameContactFragment
    }
  }
  ${GetContactAvatarOptionsContactFragmentDoc}
  ${GetContactNameContactFragmentDoc}
` as unknown as TypedDocumentNode<
  ContactCellCashFlowContactNetAmountFragment,
  undefined
>
