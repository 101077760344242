import { ConfigType } from 'dayjs'

import dayjs from 'utils/dayjs'

// NOTE: this is used to disable the input label/placeholder animation
// when the input value isn't truthy (i.e. empty string)
export const NON_BREAKING_SPACE = '\u00A0'

type TimeFormat = `${number}:${number} ${'am' | 'pm'}`

const isStringTimeFormat = (time: string): time is TimeFormat => {
  return /^([1-9]|1[0-2]):([0-5]\d) (am|pm)$/.test(time)
}

export const formatTimeForInput = (
  timeString: ConfigType
): TimeFormat | typeof NON_BREAKING_SPACE => {
  // NOTE: dayjs doesn't support local time with lowercase am/pm,
  // so can't use the 'LT' token
  if (timeString) {
    const time = dayjs(timeString).format('h:mm a')
    if (isStringTimeFormat(time)) {
      return time
    }
  }

  return NON_BREAKING_SPACE
}

export const formatDateForInput = (dateString: ConfigType): string => {
  return dateString ? dayjs(dateString).format('l') : NON_BREAKING_SPACE
}

const acceptedTimeFormats = [
  // NOTE: the order of this array matters -
  // 24 hour should have least priority to avoid
  // conflicts with meridian time formats
  'h:mm a',
  'h:mm A',
  'h:mma',
  'h:mmA',
  'ha',
  'hA',
  'HH:mm',
]

const acceptedDateTimeFormats = acceptedTimeFormats.map(
  (timeFormat) => `YYYY-MM-DD ${timeFormat}`
)

const formatTimeInputTo24Hours = (
  time: string
): { hours: number; minutes: number } | null => {
  const tempDateTime24Hour = dayjs(
    `2000-01-01 ${time}`,
    acceptedDateTimeFormats
  ).toDate()

  if (!dayjs(tempDateTime24Hour).isValid()) {
    return null
  }
  const [hours, minutes] = dayjs(tempDateTime24Hour)
    .format('HH:mm')
    .split(':')
    .map(Number)

  return { hours, minutes }
}

export const replaceDateTimeWithTime = (
  date: ConfigType,
  time: string
): Date | null => {
  const formattedTime = formatTimeInputTo24Hours(time)
  if (!formattedTime) {
    return null
  }

  const { hours, minutes } = formattedTime
  const newDate = dayjs(date).hour(hours).minute(minutes).second(0).toDate()
  return newDate
}

export const getDateFromInputValue = (value: string): Date | null => {
  const date = dayjs(value, 'l').toDate()
  return !dayjs(date).isValid() ? null : date
}

export const setSeconds = ({
  date,
  key,
}: {
  date: Date | undefined
  key: 'start' | 'end'
}) => {
  if (!date) {
    return undefined
  }

  return key === 'start'
    ? dayjs(date).second(0).millisecond(0).toDate()
    : dayjs(date).second(59).millisecond(999).toDate()
}
