import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  BREAKPOINT,
  NewBadge,
  ZellerAccountIcon,
} from '@npco/zeller-design-system'
import { BadgeSize } from '@npco/zeller-design-system/dist/Components/Badge/Badge.types'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { component } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'
import { BadgeContainer } from '../Sidebar.styled'

interface AccountsItemProps {
  isBadgeVisible?: boolean
}

export const AccountsItem = ({ isBadgeVisible }: AccountsItemProps) => {
  const { isFolded } = useSidebarLocalCache()
  const shortEntityId = useSelectedShortEntityUuid()

  const isLgBreakpoint = useIsMobileResolution(BREAKPOINT.LG)
  const isMdBreakpoint = useIsMobileResolution(BREAKPOINT.MD)

  const showDot = isLgBreakpoint && !isMdBreakpoint

  const badge = (
    <BadgeContainer data-testid="accounts-item-new-badge">
      <NewBadge
        showDot={showDot}
        size={BadgeSize.Small}
        text={translate('shared.new')}
      />
    </BadgeContainer>
  )

  return (
    <SidebarItem
      badge={isBadgeVisible && badge}
      icon={<ZellerAccountIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.path}
      className="nav-item-accounts"
    >
      {component.sidebar.items.accounts}
    </SidebarItem>
  )
}
