import { useEffect, useMemo } from 'react'
import { DebitCardTransaction } from 'features/Cards/CardSingle/CardSingle.types'
import { useCleanOutdatedOutstandingTransactions } from 'features/Cards/CardSingle/hooks/useUpdatedOutstandingTransactions'
import { differenceBy, flatten, groupBy } from 'lodash-es'

import { groupDebitCardsTransactions } from 'utils/groupDebitCardsTransactions'

import { OutstandingSettingsType } from '../useOutstandingFilterSettings'
import {
  filterApprovedStatus,
  filterByOutstanding,
} from './useFilteredOutstandingTransactions.utils'

interface UpdatedOutstandingTransactions<T> {
  timestamp: number
  transaction: T
}

interface UseFilteredOutstandingTransactionsProps<T> {
  groupedTransactions: T[][]
  updatedOutstandingTransactions: UpdatedOutstandingTransactions<T>[]
  isFilterOn: boolean
  group?: (x: (T | null)[]) => T[][]
  outstandingSettings: OutstandingSettingsType
}

export const useFilteredOutstandingTransactions = <
  T extends Pick<
    DebitCardTransaction,
    'id' | 'attachments' | 'note' | 'category' | 'status' | 'timestamp'
  >
>({
  groupedTransactions,
  isFilterOn,
  updatedOutstandingTransactions,
  group = groupDebitCardsTransactions,
  outstandingSettings,
}: UseFilteredOutstandingTransactionsProps<T>) => {
  const { cleanOutdatedOutstandingTransactions } =
    useCleanOutdatedOutstandingTransactions()

  useEffect(() => {
    cleanOutdatedOutstandingTransactions()
    // clean outdated outstanding transactions when filter change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterOn])

  const filteredOutstandingTransactions = useMemo(() => {
    const validOutstandingTransactions = updatedOutstandingTransactions.map(
      (transaction) => transaction.transaction
    )

    const idToOutstandingTransactions = groupBy(
      validOutstandingTransactions,
      'id'
    )
    const flattenedTransactions = flatten(groupedTransactions).map(
      (transaction) => {
        const updatedTransactions = idToOutstandingTransactions[transaction.id]
        if (updatedTransactions?.[0]) {
          return {
            ...transaction,
            attachments: updatedTransactions[0].attachments,
            note: updatedTransactions[0].note,
            category: updatedTransactions[0].category,
          }
        }
        return transaction
      }
    )

    if (!isFilterOn) {
      return group(flattenedTransactions)
    }

    return group(
      [
        ...differenceBy(
          validOutstandingTransactions,
          flattenedTransactions,
          'id'
        ),
        ...flattenedTransactions,
      ]
        .filter(filterApprovedStatus)
        .filter(filterByOutstanding(outstandingSettings))
    )
  }, [
    isFilterOn,
    outstandingSettings,
    groupedTransactions,
    updatedOutstandingTransactions,
    group,
  ])

  return {
    filteredOutstandingTransactions,
  }
}
