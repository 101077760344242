import { makeVar } from '@apollo/client'

import {
  datePickerInitialState,
  lastStoredTimeFilterInitialState,
  rangePickerInitialValue,
  searchPhraseInitialValue,
  sourcePickerInitialValue,
  statusPickerInitialValue,
  terminalPickerInitialValue,
  typePickerInitialValue,
} from 'const/common'
import { LastStoredTimeMap, PickerItems } from 'types/picker'

export const rvSearchPhrase = makeVar(searchPhraseInitialValue)

export const rvSelectedTerminals = makeVar(
  terminalPickerInitialValue as PickerItems
)

export const rvSelectedStatuses = makeVar(
  statusPickerInitialValue as PickerItems
)

export const rvSelectedSources = makeVar(
  sourcePickerInitialValue as PickerItems
)

export const rvSelectedTypes = makeVar(typePickerInitialValue as PickerItems)

export const rvLastSelectedDates = makeVar<LastStoredTimeMap>(
  lastStoredTimeFilterInitialState
) // This can be removed once all filters have time picker
export const rvSelectedDates = makeVar(datePickerInitialState)

export const rvIsDateRangeAppropriate = makeVar(true)

export const rvSelectedRates = makeVar(rangePickerInitialValue)

export const rvToggleFilters = makeVar(false)

export const rvSelectedDepositsSite = makeVar('')

export const rvDepositsPdfFont = makeVar<string | null>(null)
