import { Box } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const DepositDetailsContainer = styled.div`
  width: calc(100vw - 32px);
  height: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  padding-bottom: 96px;
`

export const TooltipContent = styled(Box)`
  max-width: 368px;
  text-align: center;
`
