import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useEntitySavingsAccountProductQuery } from './graphql/EntitySavingsAccountProductQuery.generated'

export const useEntitySavingsAccountProduct = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useEntitySavingsAccountProductQuery({
    variables: { entityUuid },
  })

  return {
    entity: data?.getEntity,
    isLoadingEntity: loading,
  }
}
