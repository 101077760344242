import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'
import { InvoiceDiscountFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { InvoiceDiscountPercentage } from './InvoiceDiscountPercentage/InvoiceDiscountPercentage'
import { InvoiceDiscountPrice } from './InvoiceDiscountPrice/InvoiceDiscountPrice'

export const InvoiceDiscountValue = () => {
  const { values } = useFormikContext<InvoiceDiscountFormFields>()

  return (
    <Flex flexDirection="column" pt="24px">
      {values.config === InvoiceDiscountConfig.PERCENTAGE ? (
        <InvoiceDiscountPercentage />
      ) : (
        <InvoiceDiscountPrice />
      )}
    </Flex>
  )
}
