import { useCallback } from 'react'
import {
  Box,
  Flex,
  InputAdaptiveTextArea,
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'
import { Field, Formik } from 'formik'
import { object, string } from 'yup'

import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { InputAdaptiveCurrencyField } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField'

import { getCurrencyAmountFormatted } from '../../../../Invoice/components/InvoiceFormAccordions/InvoiceFormAccordions.utils'
import { useInvoiceDecisionModal } from '../hooks/useInvoiceDecisionModal'
import { useRecordPayment } from '../hooks/useRecordPayment'
import { InvoiceDecisionModalTypes } from '../InvoiceDrawerDetails.utils'
import { DueAmount, InvoiceBalance } from './RecordPaymentModal.styled'

export const translations = {
  save: translate('shared.save'),
  cancel: translate('shared.cancel'),
  title: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.title'
  ),
  invoiceBalance: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.invoiceBalance'
  ),
  amount: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.amount'
  ),
  amountAriaLabel: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.amountAriaLabel'
  ),
  amountMinError: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.amountMinError'
  ),
  amountMaxError: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.amountMaxError'
  ),
  notes: translate(
    'component.invoiceDrawer.decisionModals.recordPayment.notes'
  ),
  successToast: (amount: string, referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.recordPayment.success', {
      amount,
      referenceNumber,
    }),
  errorToast: (amount: string) =>
    translate('component.invoiceDrawer.decisionModals.recordPayment.error', {
      amount,
    }),
}

export const recordPaymentSchema = (maxAmount: string) => {
  // NOTE: max amount is a centi cents string value
  const maxAmountValue = currency(maxAmount, {
    fromCents: true,
    precision: 4,
  }).value

  return object({
    amount: string()
      .test('maxValue', translations.amountMaxError, (value) => {
        return (
          !value || maxAmountValue >= currency(value, { precision: 4 }).value
        )
      })
      .test('minValue', translations.amountMinError, (value) => {
        return !value || currency(value, { precision: 4 }).value > 0
      }),
    notes: string().nullable(),
  })
}

interface RecordPaymentFormFields {
  notes: string | null
  amount: string
}

export const RecordPaymentModal = () => {
  const {
    activeModal,
    closeModal,
    details: { id, referenceNumber, dueAmount },
  } = useInvoiceDecisionModal()
  const { recordPayment, isRecordingPayment } = useRecordPayment()
  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleRecordPayment = useCallback(
    async (values: RecordPaymentFormFields, { resetForm }) => {
      trackInvoiceAction(AnalyticsEventNames.INVOICE_RECORD_PAYMENT)
      try {
        const response = await recordPayment({
          amount: currency(values.amount, { precision: 4 }).intValue.toString(),
          notes: values.notes,
          referenceNumber,
        })

        if (!response.data?.recordPayment) {
          showErrorToast(translations.errorToast(values.amount))
          return
        }
        showSuccessToast(
          translations.successToast(values.amount, referenceNumber)
        )
      } catch {
        showErrorToast(translations.errorToast(values.amount))
      } finally {
        resetForm()
        closeModal()
      }
    },
    [closeModal, recordPayment, referenceNumber, trackInvoiceAction]
  )

  return (
    <Formik<RecordPaymentFormFields>
      initialValues={{ amount: '', notes: '' }}
      onSubmit={handleRecordPayment}
      validationSchema={recordPaymentSchema(dueAmount ?? '0')}
    >
      {({ handleSubmit, resetForm, validateForm }) => (
        <ModalForm
          isLoading={isRecordingPayment}
          isOpen={activeModal === InvoiceDecisionModalTypes.RecordPayment}
          onCancel={() => {
            closeModal()
            resetForm()
          }}
          onClickPrimary={() => {
            validateForm()
            handleSubmit()
          }}
          primaryButtonLabel={translations.save}
          secondaryButtonLabel={translations.cancel}
          title={translations.title}
        >
          <Flex flexDirection="column">
            <Flex justifyContent="space-between" mb="24px">
              <InvoiceBalance>{translations.invoiceBalance}</InvoiceBalance>
              <DueAmount>
                {getCurrencyAmountFormatted(dueAmount ?? '0')}
              </DueAmount>
            </Flex>
            <Box mb="24px">
              <InputAdaptiveCurrencyField
                ariaLabel={translations.amountAriaLabel}
                autoFocus
                isAmountAlignLeft
                label={translations.amount}
                name="amount"
                placeholder="0.00"
              />
            </Box>
            <Field
              component={InputAdaptiveTextArea}
              id="notes"
              label={translations.notes}
              marginBottom="0"
              maxLength={500}
              name="notes"
              rows={5}
            />
          </Flex>
        </ModalForm>
      )}
    </Formik>
  )
}
