import { useCreateSavingsAccountState } from '@npco/mp-utils-create-savings-account-routing'

import { CreateSavingsAccountView } from './CreateSavingsAccountView/CreateSavingsAccountView'

export const CreateSavingsAccountModal = () => {
  const { state } = useCreateSavingsAccountState()

  if (state === null) {
    return null
  }

  return <CreateSavingsAccountView />
}
