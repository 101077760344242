import { SectionHeader } from 'pages/Dashboard/SectionHeader'
import { page } from 'translations'

import { TransactionTotal } from '../../Dashboard.types'
import { getHasNoSales } from '../DashboardPerformance/DashboardPerformance.utils'
import { StyledOverviewContainer } from './DashboardSales.styled'
import { NoSales } from './NoSales'
import { SalesChart } from './SalesChart/SalesChart'
import { ViewReportLink } from './SalesChart/ViewReportLink'
import { SalesOverview } from './SalesOverview/SalesOverview'

interface Props {
  salesTotals: TransactionTotal[]
  isLoading: boolean
}

export const SalesContent = ({ salesTotals, isLoading }: Props) => {
  const hasSales = !getHasNoSales(salesTotals)

  return (
    <>
      <SectionHeader heading={page.dashboard.payments.sales.salesTitle}>
        <ViewReportLink />
      </SectionHeader>
      <div>
        <StyledOverviewContainer>
          {hasSales || isLoading ? (
            <SalesOverview salesTotals={salesTotals} isLoading={isLoading} />
          ) : (
            <NoSales />
          )}
        </StyledOverviewContainer>
      </div>
      <SalesChart salesTotals={salesTotals} isLoading={isLoading} />
    </>
  )
}
