import { ReactNode, useEffect, useMemo } from 'react'
import {
  createHttpLink,
  from,
  useApolloClient,
  useLazyQuery,
} from '@apollo/client'
import { getSessionStorageItem } from '@npco/utils-session-storage'
import DebounceLink from 'apollo-link-debounce'
import { SentryLink } from 'apollo-link-sentry'
import { GetSubscriptionUrl } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/global'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { UrlInfo } from 'aws-appsync-subscription-link/lib/types'

import { GetSubscriptionUrl as GetSubscriptionUrlResponse } from 'types/gql-types/GetSubscriptionUrl'
import { AuthLoadingPage } from 'pages/AuthLoadingPage/AuthLoadingPage'
import { SpinnerWrapped } from 'components/Spinner'

import {
  getAuthLink,
  getErrorLink,
  removeTypenameLink,
  setZellerSessionIdCustomHeader,
} from './AuthorizedApolloProvider.utils'
import { getLazyLoadableTestingLink } from './utils/getLazyLoadableTestingLink'

const DEFAULT_DEBOUNCE_TIMEOUT = 200

const USE_APOLLO_TESTING_LINK_SESSION_STORAGE_KEY = 'use-test-utils-in-cypress'

interface ApolloMiddlewareProps {
  apiUri?: string
  children: ReactNode
  isTest: boolean
}

// NOTE: This component ensures that the correct middleware (apollo links) is applied
// e.g handle requests, refresh tokens, error handling and setting up subscriptions
export const ApolloMiddleware = ({
  apiUri,
  children,
  isTest,
}: ApolloMiddlewareProps) => {
  const client = useApolloClient()

  const {
    getNewToken,
    isClientSetup,
    logout,
    scope,
    setIsClientSetup,
    setIsSubscriptionsSetup,
    token,
  } = useZellerAuthenticationContext()

  const [getSubscriptionUrl] =
    useLazyQuery<GetSubscriptionUrlResponse>(GetSubscriptionUrl)

  useEffect(() => {
    if (scope) {
      getNewToken()
    }
  }, [getNewToken, scope])

  const shouldUseApolloTestingLink: boolean | undefined = getSessionStorageItem(
    USE_APOLLO_TESTING_LINK_SESSION_STORAGE_KEY
  )
  const lazyLoadableTestingLink = useMemo(
    () =>
      getLazyLoadableTestingLink(window.Cypress, shouldUseApolloTestingLink),
    [shouldUseApolloTestingLink]
  )

  // NOTE: this use effect handles the apollo link middleware and will rerun on
  // each token update
  useEffect(() => {
    async function configureMiddleware(nextToken?: string) {
      if (!nextToken || !apiUri) {
        return
      }

      const authLink = getAuthLink(nextToken)

      const errorLink = getErrorLink(
        getNewToken,
        logout,
        nextToken,
        setIsSubscriptionsSetup
      )

      const httpLink = createHttpLink({
        uri: apiUri,
      })

      const sentryLink = new SentryLink({
        attachBreadcrumbs: {
          // NOTE: these are the defaults but explicitly defining
          // in case these are changed in the future to prevent
          // any PII leak
          includeCache: false,
          includeContext: false,
          includeError: false,
          includeFetchResult: false,
          includeQuery: false,
          includeVariables: false,
        },
      })

      // NOTE: standard apollo middleware to make requests without subscriptions
      const links = from([
        setZellerSessionIdCustomHeader,
        removeTypenameLink,
        errorLink,
        sentryLink,
        authLink,
        // NOTE: Requests are debounced together if they share the same
        // debounceKey. Requests without a debounce key are passed to the next
        // link unchanged.
        new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
        ...lazyLoadableTestingLink,
      ])

      // NOTE: the mocked apollo provider mocks will be overwritten if we set
      // the set link below so only apply when not the test environment
      if (!isTest || !shouldUseApolloTestingLink) {
        // NOTE: we must set this apollo middleware before requesting for
        // the subscription url below otherwise we'll get a 401 unauthorized error
        client.setLink(links.concat(httpLink))
      }
      // NOTE: attempt to concat subscription link and log error if failure
      const response = await getSubscriptionUrl()

      if (response?.data?.getSubscriptionUrl) {
        const { apiUrl, region } = response.data.getSubscriptionUrl

        const subscriptionLinkConfig: UrlInfo = {
          url: `https://${apiUrl}/graphql`,
          region: region || 'ap-southeast-2',
          auth: {
            type: 'OPENID_CONNECT',
            jwtToken: async () => nextToken,
          },
        }

        const subscriptionLink = from([
          createAuthLink({
            url: apiUri,
            region: 'ap-southeast-2',
            auth: {
              type: 'OPENID_CONNECT',
              jwtToken: async () => nextToken,
            },
          }),
          // NOTE: http link is passed here so we don't need to concat the
          // above httpLink below, this will overwrite the links set above
          // and will communicate with our api as per normal
          createSubscriptionHandshakeLink(subscriptionLinkConfig, httpLink),
        ])

        // NOTE: the mocked apollo provider mocks will be overwritten if we set
        // the set link below so only apply when not the test environment
        if (!isTest) {
          client.setLink(links.concat(subscriptionLink))
        }

        setIsSubscriptionsSetup(true)
      }

      setIsClientSetup(true)
    }

    configureMiddleware(token)
  }, [
    apiUri,
    client,
    getNewToken,
    getSubscriptionUrl,
    isTest,
    logout,
    setIsClientSetup,
    setIsSubscriptionsSetup,
    token,
    lazyLoadableTestingLink,
    shouldUseApolloTestingLink,
  ])

  const isAfterRedirect: boolean | undefined = getSessionStorageItem('redirect')

  if (isAfterRedirect && !isClientSetup) {
    return <AuthLoadingPage />
  }

  if (!isAfterRedirect && !isClientSetup) {
    return <SpinnerWrapped variant="centre" backgroundColor="white" />
  }

  return <>{children}</>
}
