import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Box, ButtonLink } from '@npco/zeller-design-system'

import { PERMISSIONS } from 'const/permissions'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { withPermissionGuard } from 'services/withPermissionGuard/withPermissionGuard'

interface UserEditButtonProps {
  isUsers?: boolean
}

export const UserEditButton = withPermissionGuard<UserEditButtonProps>(
  PERMISSIONS.SITE.USERS.EDIT
)(({ isUsers = false }) => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const nextRoute = useMemo(() => {
    if (isUsers) {
      return ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.USER_EDIT.path
    }

    return ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.USERS.EDIT.path
  }, [isUsers, shortEntityId])
  return (
    <Box marginLeft="auto">
      <ButtonLink onClick={() => navigate(nextRoute)}>
        {translate('page.settings.users.edit')}
      </ButtonLink>
    </Box>
  )
})
