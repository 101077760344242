import { ButtonGhostIconOnly } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledButtonGhostIconOnly = styled(ButtonGhostIconOnly)`
  &.isActive {
    &,
    &:hover,
    &:focus {
      background: ${({ theme }) => theme.colors.BLUE_80};
    }
  }
`

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledActiveFilterIndicator = styled.div`
  background: ${({ theme }) => theme.colors.RED_1000};
  border: 2px solid ${({ theme }) => theme.colors.WHITE};
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 10;
  right: 10px;
  top: 6px;
`
