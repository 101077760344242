import React, { useCallback, useEffect, useState } from 'react'

import { KEY_CODE } from './keyboard'

export interface UseKeyboardNavigationProps {
  onPrevious?: () => void
  onNext?: () => void
  drawerContentContainerRef: React.RefObject<HTMLDivElement | null>
  drawerRef?: React.MutableRefObject<HTMLDivElement | HTMLButtonElement | null>
}

export const useKeyboardNavigation = ({
  onPrevious,
  onNext,
  drawerContentContainerRef,
  drawerRef,
}: UseKeyboardNavigationProps) => {
  const [changeMode, setChangeMode] = useState<'next' | 'previous'>('next')
  const [isNavigationDisabled, setIsNavigationDisabled] = useState(false)

  const innerSelectPrevious = useCallback(() => {
    setChangeMode('previous')
    onPrevious?.()
  }, [onPrevious])

  const innerSelectNext = useCallback(() => {
    setChangeMode('next')
    onNext?.()
  }, [onNext])

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const { tagName, isContentEditable } = e.target as HTMLElement

      if (
        tagName === 'INPUT' ||
        tagName === 'SELECT' ||
        tagName === 'TEXTAREA' ||
        isContentEditable
      ) {
        return
      }

      if (e.key === KEY_CODE.ARROW_DOWN || e.key === KEY_CODE.ARROW_UP) {
        const drawerModalContent = document.getElementById('drawer-basic')

        if (drawerModalContent?.contains(document.activeElement)) {
          drawerContentContainerRef.current?.focus()
          return
        }
      }

      if (e.key === KEY_CODE.ARROW_LEFT) {
        e.preventDefault()
        drawerContentContainerRef.current?.scrollTo(0, 0)
        innerSelectPrevious?.()
        drawerRef?.current?.focus()
        return
      }

      if (e.key === KEY_CODE.ARROW_RIGHT) {
        e.preventDefault()
        drawerContentContainerRef.current?.scrollTo(0, 0)
        innerSelectNext?.()
        drawerRef?.current?.focus()
      }
    }

    if (!isNavigationDisabled) {
      document.addEventListener('keydown', listener)
    }

    return () => document.removeEventListener('keydown', listener)
  }, [
    drawerContentContainerRef,
    drawerRef,
    innerSelectNext,
    innerSelectPrevious,
    isNavigationDisabled,
  ])

  return {
    drawerContentContainerRef,
    changeMode,
    innerSelectPrevious,
    innerSelectNext,
    isNavigationDisabled,
    setIsNavigationDisabled,
  }
}
