export interface GooglePlacesDataItem {
  description: string
  ['place_id']: string
}

export enum GOOGLE_PLACES_DETAILS_ITEM_TYPE {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  LOCALITY = 'locality',
  STATE = 'administrative_area_level_1',
  POSTCODE = 'postal_code',
  COUNTRY = 'country',
  UNIT = 'subpremise',
}

export interface GooglePlacesDetailsAddressComponentItem {
  ['long_name']: string
  ['short_name']: string
  types: GOOGLE_PLACES_DETAILS_ITEM_TYPE[]
}

export interface GooglePlacesDetailsDataItem {
  ['address_components']: GooglePlacesDetailsAddressComponentItem[]
}

export interface GooglePlacesGeometryLocation {
  lat: () => number
  lng: () => number
}

export interface GoogleGeocoderGeometry {
  location: GooglePlacesGeometryLocation
}

export interface GoogleGeocodeDataItem {
  address_components: GooglePlacesDetailsAddressComponentItem[]
  geometry: GoogleGeocoderGeometry
  place_id: string
  types: GOOGLE_PLACES_DETAILS_ITEM_TYPE[]
}

export interface GoogleParsedAddressData {
  unit?: string
  street: string
  streetNumber: string
  state: string
  postcode: string
  suburb: string
  country: string
}
