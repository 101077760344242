import { ApolloCache } from '@apollo/client'
import { Contact } from '@npco/mp-gql-types'
import { UpdateOption, updateWithOption } from 'features/utils-cache'

export type ContactUpdate = {
  id: string
  isSelf?: UpdateOption<Contact['isSelf']>
}

type UpdateCachedContactArguments = {
  cache: ApolloCache<any>
  contactUpdate: ContactUpdate
}

export const updateCachedContact = ({
  cache,
  contactUpdate,
}: UpdateCachedContactArguments) => {
  cache.modify({
    id: cache.identify({ __typename: 'Contact', id: contactUpdate.id }),
    fields: {
      isSelf(oldIsSelf: Contact['isSelf']) {
        return updateWithOption(contactUpdate.isSelf, oldIsSelf)
      },
    },
  })
}
