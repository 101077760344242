import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { getSessionStorageItem } from '@npco/utils-session-storage'

import { ROOT } from 'const/routes'

import { LAST_DASHBOARD_TAB_KEY } from '../../utils/sessionStorageKeys'

export const useSavedDashboardRoute = () => {
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    getSessionStorageItem<string>(LAST_DASHBOARD_TAB_KEY) ||
    ROOT.ORGS.ORG(shortEntityId).OVERVIEW.PAYMENTS.path
  )
}
