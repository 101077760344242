import { useCallback, useState } from 'react'
import { GetInvoicesSortInput } from '@npco/mp-gql-types'
import {
  ButtonFill,
  ButtonGhost,
  PopperItemOneLine,
  PopperItemOneLineSize,
} from '@npco/zeller-design-system'
import { AllInvoiceTableColumn } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import {
  isAscendingSelected,
  isDescendingSelected,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { Filters } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileFilters/SortByFilters.styled'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'
import { translate } from 'utils/translations'
import { page } from 'translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { FilterState } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFilters.utils'
import { BottomWrapper } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer.styled'

interface SortByFiltersProps {
  setFilterState: (values: FilterState) => void
}

export const SortByFilters = ({ setFilterState }: SortByFiltersProps) => {
  const { setSortingObject, sortingObject } = useInvoicesContext()

  const [nextSortingObject, setNextSortingObject] =
    useState<GetInvoicesSortInput | null>(sortingObject)

  const handleOnClick = useCallback(
    (columnName, ascending) => () => {
      setNextSortingObject({ columnName, ascending })
    },
    [setNextSortingObject]
  )

  const handleApply = useCallback(() => {
    setSortingObject(nextSortingObject)
    setFilterState(FilterState.FilterAndSort)
  }, [nextSortingObject, setFilterState, setSortingObject])

  const handleClear = useCallback(() => {
    setNextSortingObject(null)
  }, [setNextSortingObject])

  return (
    <>
      <Filters data-testid="sort-by-filters">
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.ID
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.ID} />}
          label={page.invoiceTable.sortBy.idDescending}
          onClick={handleOnClick(AllInvoiceTableColumn.ID, false)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.ID
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.ID} />}
          label={page.invoiceTable.sortBy.idAscending}
          onClick={handleOnClick(AllInvoiceTableColumn.ID, true)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.Contact
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.Contact} />}
          label={page.invoiceTable.sortBy.contactAscending}
          onClick={handleOnClick(AllInvoiceTableColumn.Contact, true)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.Contact
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.Contact} />}
          label={page.invoiceTable.sortBy.contactDescending}
          onClick={handleOnClick(AllInvoiceTableColumn.Contact, false)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.Status
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.Status} />}
          label={page.invoiceTable.sortBy.statusAscending}
          onClick={handleOnClick(AllInvoiceTableColumn.Status, true)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.Status
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.Status} />}
          label={page.invoiceTable.sortBy.statusDescending}
          onClick={handleOnClick(AllInvoiceTableColumn.Status, false)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.DatePaid
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.DatePaid} />}
          label={page.invoiceTable.sortBy.dateDescending}
          onClick={handleOnClick(AllInvoiceTableColumn.DatePaid, false)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.DatePaid
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.DatePaid} />}
          label={page.invoiceTable.sortBy.dateAscending}
          onClick={handleOnClick(AllInvoiceTableColumn.DatePaid, true)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.AmountPaid
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.AmountPaid} />}
          label={page.invoiceTable.sortBy.amountDescending}
          onClick={handleOnClick(AllInvoiceTableColumn.AmountPaid, false)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            nextSortingObject,
            AllInvoiceTableColumn.AmountPaid
          )}
          icon={() => <Tick title={AllInvoiceTableColumn.AmountPaid} />}
          label={page.invoiceTable.sortBy.amountAscending}
          onClick={handleOnClick(AllInvoiceTableColumn.AmountPaid, true)}
          size={PopperItemOneLineSize.Small}
        />
      </Filters>
      <BottomWrapper>
        <ButtonGhost onClick={handleClear} dataTestId="clear-filters-btn">
          {translate('shared.clear')}
        </ButtonGhost>
        <ButtonFill dataTestId="apply-filters-btn" onClick={handleApply}>
          {translate('shared.apply')}
        </ButtonFill>
      </BottomWrapper>
    </>
  )
}
