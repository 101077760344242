import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvAddedContacts } from 'features/Contacts/rv-deprecated/contacts'
import { equals } from 'ramda'

import { ContactsFilters } from '../Contacts.types'
import { useContacts } from '../hooks/useContacts/useContacts'

export const defaultFilters: ContactsFilters = {
  name: '',
  tags: [],
}

export type ContactsContextType = {
  filters: ContactsFilters
  hasContact: boolean
  isDefaultFilters: boolean
  isFiltersVisible: boolean
  setFilters: Dispatch<React.SetStateAction<ContactsFilters>>
  setIsFiltersVisible: Dispatch<React.SetStateAction<boolean>>
}

export const ContactsContext = createContext<ContactsContextType | undefined>(
  undefined
)

ContactsContext.displayName = 'Contacts Context'

interface ContactsProviderProps {
  children: ReactNode | ReactNode[]
}

export const ContactsProvider = ({ children }: ContactsProviderProps) => {
  const addedContacts = useReactiveVar(rvAddedContacts)
  const [filters, setFilters] = useState<ContactsFilters>(defaultFilters)
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const { contacts } = useContacts({
    filters: defaultFilters,
    limit: 1,
  })
  const [hasContact, setHasContact] = useState<boolean>(false)

  const isDefaultFilters = useMemo(
    () => equals(defaultFilters)(filters),
    [filters]
  )

  useEffect(() => {
    if (contacts.length) {
      setHasContact(true)
    }
  }, [contacts.length])

  useEffect(() => {
    if (addedContacts.length) {
      setHasContact(true)
    }
  }, [addedContacts.length])

  const value = useMemo(
    () =>
      ({
        filters,
        hasContact,
        isDefaultFilters,
        isFiltersVisible,
        setFilters,
        setIsFiltersVisible,
      } satisfies ContactsContextType),
    [
      filters,
      hasContact,
      isDefaultFilters,
      isFiltersVisible,
      setFilters,
      setIsFiltersVisible,
    ]
  )

  return (
    <ContactsContext.Provider value={value}>
      {children}
    </ContactsContext.Provider>
  )
}

export const useContactsContext = () => {
  const context = useContext(ContactsContext)

  if (!context) {
    throw new Error('useContactsContext must be used within ContactsContext')
  }

  return context
}
