import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  description: 'Description (Optional)',
  descriptionAriaLabel: 'item description',
  name: 'Name',
  nameAriaLabel: 'item name',
  priceAriaLabel: 'item price',
  sku: 'SKU (Optional)',
  skuAriaLabel: 'item sku',
})
