import { useContext, useMemo } from 'react'

import { getIsValidContactTransferAppState } from '../../../utils/utils'
import { MFAContext } from '../../MFAContext'

export const useContactTransferMFAState = () => {
  const context = useContext(MFAContext)

  if (context === null) {
    throw new Error('useTransferMFAState can only be used within MFAContext')
  }

  const { appState, hasRedirectedBackToApp, setHasRedirectedBackToApp } =
    context

  const transferState = useMemo(() => {
    if (!appState || !getIsValidContactTransferAppState(appState)) {
      return null
    }

    return appState
  }, [appState])

  return { transferState, hasRedirectedBackToApp, setHasRedirectedBackToApp }
}
