import {
  Flex,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { DetailsLabel, Separator } from '../../ZellerCardSummary.styled'

export const DebitCardDetailsSkeletonMobile = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      data-testid="debit-card-detail-skeleton-mobile"
      pt="24px"
    >
      <DetailsLabel>
        {translate('component.cardSummary.debitCardDetails.availableFunds')}
      </DetailsLabel>
      <SkeletonTypographyBase
        margin="8px 0 0"
        type={TypographyTypes.H3}
        width={206}
      />
      <SkeletonTypographyBase
        margin="26px auto 0"
        type={TypographyTypes.H4}
        width={73}
      />
      <Separator />
    </Flex>
  )
}
