import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'
import { useGoToCreateSavingsAccount } from '@npco/mp-utils-create-savings-account-routing'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { AccountsTransactionsMetadataProvider } from 'features/AccountsTransactions'

import { useRefreshAccounts } from 'hooks/banking'
import { ROOT } from 'const/routes'
import { Transfer } from 'pages/Transfer/Transfer'
import { AccountFilterValuesWrapper } from 'layouts/AccountLayout/AccountFilterValuesWrapper/AccountFilterValuesWrapper'
import { AccountLayout } from 'layouts/AccountLayout/AccountLayout'
import { AccountsLayout } from 'layouts/AccountsLayout/AccountsLayout'

import { TransfersLayout } from '../../../pages/Transfer/TransferLayout/TransferLayout'
import { Accounts } from '../Accounts/Accounts'
import { AccountsTransactions } from '../AccountsTransactions/AccountsTransactions'
import { TransferToSavings } from '../TransferToSavings/TransferToSavings'
import { AccountRoutes } from './AccountRoutes/AccountRoutes'
import { PrivateAccountsRoute } from './PrivateAccountsRoute/PrivateAccountsRoute'

export const AccountsRoutes = () => {
  useRefreshAccounts()

  const { createState: createSavingsAccountState } =
    useGoToCreateSavingsAccount()

  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Routes>
      <Route element={<PrivateAccountsRoute />}>
        <Route
          element={
            <AccountsTransactionsMetadataProvider>
              <AccountFilterValuesWrapper>
                <AccountsLayout />
              </AccountFilterValuesWrapper>
            </AccountsTransactionsMetadataProvider>
          }
        >
          <Route index element={<Accounts />} />

          <Route
            path="activate-savings"
            element={
              <Navigate
                to={ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.path}
                state={createSavingsAccountState({ stage: 'tfn' })}
                replace
              />
            }
          />

          <Route
            path={ROOT.ORGS.ORG().ACCOUNTS.TRANSACTIONS.relative}
            element={<AccountsTransactions />}
          />
        </Route>

        <Route
          path={`${ROOT.ORGS.ORG().ACCOUNTS.TRANSFER.relative}/*`}
          element={<TransfersLayout />}
        >
          <Route index element={<Transfer />} />
          <Route
            path={ROOT.ORGS.ORG().ACCOUNTS.TRANSFER.SAVE.relative}
            element={<TransferToSavings />}
          />
        </Route>

        <Route
          element={
            <AccountsTransactionsMetadataProvider>
              <AccountFilterValuesWrapper>
                <AccountLayout />
              </AccountFilterValuesWrapper>
            </AccountsTransactionsMetadataProvider>
          }
        >
          <Route path={`${ACCOUNT().relative}/*`} element={<AccountRoutes />} />
        </Route>
      </Route>
    </Routes>
  )
}
