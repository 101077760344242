import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useUpdateDebitCardTransaction } from 'hooks/banking'

import { DebitCardTransaction } from '../../TransactionDrawer.types'
import { UnlinkContactWithDebitCardAccountTransactionSender } from '../graphql/unlinkContactWithDebitCardAccountTransactionSender'
import {
  UnlinkContactWithDebitCardAccountTransactionSenderMutationResponse,
  UnlinkContactWithDebitCardAccountTransactionSenderMutationVariables,
} from '../graphql/unlinkContactWithDebitCardAccountTransactionSender.generated'
import { translations } from './useTransactionDrawerContent.i18n'

export const useTransactionDrawerContent = (
  transactionUuid: string | undefined,
  updateTransaction: (update: Partial<DebitCardTransaction>) => void
) => {
  const entityUuid = useSelectedEntityUuid()

  const t = useTranslations(translations)
  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()

  const [unlinkContactMutation, { loading, error }] = useMutation<
    UnlinkContactWithDebitCardAccountTransactionSenderMutationResponse,
    UnlinkContactWithDebitCardAccountTransactionSenderMutationVariables
  >(UnlinkContactWithDebitCardAccountTransactionSender)
  const unlinkContact = (contactUuid?: string, senderUuid?: string | null) => {
    if (!contactUuid || !senderUuid) {
      showErrorToast()

      ErrorLogger.report(
        '[Banking] Unlinking contact requires both contactUuid and senderUuid'
      )

      return
    }

    unlinkContactMutation({
      variables: {
        contactUuid,
        senderUuid,
        entityUuid,
      },
      update: () => {
        // Update cache
        if (transactionUuid) {
          updateDebitCardTransaction(transactionUuid, { contact: null })
        }

        // Update reactive variables
        updateTransaction({ contact: null })

        showSuccessToast(t('successMessage'))
      },
    })
  }
  return {
    unlinkContact,
    isUnlinkingContact: loading,
    hasUnlinkingContactError: Boolean(error),
  }
}
