import { type ComponentProps } from 'react'
import { AsideLayout } from '@npco/ui-aside-layout'

import { translate } from 'utils/translations'
import { SidebarFooterButton } from 'components/SidebarFooterButton/SidebarFooterButton'
import { TerminalsImageSidebar } from 'components/TerminalsImageSidebar/TerminalsImageSidebar'

import { StyledPortalKYCWrapper } from './PortalKYCContainer.styled'
import { PortalKYCContent } from './PortalKYCLayout/PortalKYCContent'

// Would be ideal to inline *LayoutContent into *Layout directly.
export type KYCLayoutContentSharedProps = Pick<
  ComponentProps<typeof PortalKYCContent>,
  'isLoading' | 'isLoaderContainer' | 'isVerticallyCentered'
>

export const PortalKYCLayout = ({
  children,
  ctaSaveAndClose,
  isLoading,
  isLoaderContainer,
  isVerticallyCentered,
}: {
  children?: React.ReactNode
  ctaSaveAndClose?: { onClick: () => void; isDisabled?: boolean }
} & KYCLayoutContentSharedProps): JSX.Element => {
  return (
    <AsideLayout
      isSideBarOpen={false}
      isRightSide
      hasAside
      aside={
        <TerminalsImageSidebar data-testid="onboarding-sidebar">
          {ctaSaveAndClose && (
            <SidebarFooterButton
              onClick={ctaSaveAndClose.onClick}
              isDisabled={ctaSaveAndClose.isDisabled}
            >
              {translate('shared.saveAndClose')}
            </SidebarFooterButton>
          )}
        </TerminalsImageSidebar>
      }
    >
      <StyledPortalKYCWrapper>
        <PortalKYCContent
          ctaSaveAndClose={ctaSaveAndClose}
          isLoading={isLoading}
          isLoaderContainer={isLoaderContainer}
          isVerticallyCentered={isVerticallyCentered}
        >
          {children}
        </PortalKYCContent>
      </StyledPortalKYCWrapper>
    </AsideLayout>
  )
}
