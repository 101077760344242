import { useCallback, useMemo } from 'react'
import { VelocityWindowEnum } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box, ButtonLink, Flex } from '@npco/zeller-design-system'

import { VelocityControl } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { currencyFormatter } from 'utils/common'
import { translationsShared } from 'translations'

import { Item } from '../Item'
import { Title } from '../ReviewCard.styled'
import { spendManagementControlReviewTranslations } from './SpendManagementControlReview.i18n'

interface SpendManagementControlReviewProps {
  velocityControl: VelocityControl
  isLoading: boolean
  onEdit: () => void
}

export const SpendManagementControlReview = ({
  onEdit,
  velocityControl,
  isLoading,
}: SpendManagementControlReviewProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(spendManagementControlReviewTranslations)

  const getVelocityWindowDisplay = useCallback(
    (velocityWindow: VelocityWindowEnum) => {
      if (velocityWindow === VelocityWindowEnum.DAY) {
        return tShared('daily')
      }
      if (velocityWindow === VelocityWindowEnum.WEEK) {
        return tShared('weekly')
      }
      if (velocityWindow === VelocityWindowEnum.FORTNIGHT) {
        return tShared('fortnightly')
      }
      if (velocityWindow === VelocityWindowEnum.MONTH) {
        return tShared('monthly')
      }
      return tShared('quarterly')
    },
    [tShared]
  )

  const velocityControlsDisplay = useMemo(
    () => ({
      amountLimit: currencyFormatter(Number(velocityControl.amountLimit) * 100),
      maxTransactionValue: currencyFormatter(
        Number(velocityControl.maxTransactionValue) * 100
      ),
      velocityWindow: getVelocityWindowDisplay(velocityControl.velocityWindow),
    }),
    [velocityControl, getVelocityWindowDisplay]
  )

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Title>{t('title')}</Title>
        <ButtonLink onClick={onEdit} disabled={isLoading}>
          {tShared('edit')}
        </ButtonLink>
      </Flex>
      <Item
        label={t('spendLimitLabel')}
        value={velocityControlsDisplay.amountLimit}
      />
      <Item
        label={t('spendLimitFrequencyLabel')}
        value={velocityControlsDisplay.velocityWindow}
      />
      <Item
        label={t('maxTransactionLimitLabel')}
        value={velocityControlsDisplay.maxTransactionValue}
      />
    </Box>
  )
}
