import { createContext, useContext } from 'react'

import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'

export type SitesContextType = {
  sites: SiteData[]
  isLoading: boolean
  isLoadingMore: boolean
  onLoadMore: () => void
  hasMore: boolean
}
export const SitesContext = createContext<SitesContextType | undefined>(
  undefined
)

export const useSitesContext = () => {
  const context = useContext(SitesContext)

  if (context === undefined) {
    throw new Error('useSitesContext must be used within a SitesProvider')
  }
  return context
}
