import { BodyDefaultTypography } from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled from 'styled-components'

interface ContainerProps {
  isSmall: boolean
}

export const StyledContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${(props) => (props.isSmall ? '8px 10px' : '12px 16px')};
  background-color: ${({ theme }) => theme.colors.GREY_60};
  border: 1px solid ${({ theme }) => theme.colors.GREY_60};
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  border-radius: 4px;
  height: 48px;

  input {
    width: 100%;
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.GREY_150};
  }
`

export const StyledInput = styled.input<{ isSmall: boolean }>`
  margin-left: ${(props) => (props.isSmall ? '6' : '14')}px;
  padding: 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.GREY_60};
  color: ${({ theme }) => theme.colors.BLACK};
  caret-color: ${({ theme }) => theme.colors.BLUE_1000};
  flex: 1;
  outline: none;
  font-family: inherit;
  ${BodyDefaultTypography}

  &::placeholder {
    color: ${({ theme }) => theme.colors.GREY_250};
    opacity: 1;
  }

  &:disabled {
    background-color: ${({ theme }) => rgba(theme.colors.GREY_60, 0.75)};
    color: ${({ theme }) => rgba(theme.colors.GREY_250, 0.75)};
    -webkit-text-fill-color: ${({ theme }) =>
      rgba(theme.colors.GREY_250, 0.75)};
  }
`
