import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowInvoicesLargeOne from 'assets/images/setup-flow/setup-flow-invoices-large-one.png'
import SetupFlowInvoicesLargeThree from 'assets/images/setup-flow/setup-flow-invoices-large-three.png'
import SetupFlowInvoicesLargeTwo from 'assets/images/setup-flow/setup-flow-invoices-large-two.png'
import SetupFlowInvoicesMediumOne from 'assets/images/setup-flow/setup-flow-invoices-medium-one.png'
import SetupFlowInvoicesMediumThree from 'assets/images/setup-flow/setup-flow-invoices-medium-three.png'
import SetupFlowInvoicesMediumTwo from 'assets/images/setup-flow/setup-flow-invoices-medium-two.png'
import SetupFlowInvoicesSmallOne from 'assets/images/setup-flow/setup-flow-invoices-small-one.png'
import SetupFlowInvoicesSmallThree from 'assets/images/setup-flow/setup-flow-invoices-small-three.png'
import SetupFlowInvoicesSmallTwo from 'assets/images/setup-flow/setup-flow-invoices-small-two.png'
import { translationsShared } from 'translations'

import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { setupFlowInvoicesStageTranslations } from './FeatureInvoicesStage.i18n'
import { IllustrationAnimation } from './IllustrationAnimation/IllustrationAnimation'
import { MainContent } from './MainContent/MainContent'

export const FeatureInvoicesStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(setupFlowInvoicesStageTranslations)
  const { goToStage } = useSetupFlowModal()

  return (
    <Tutorial.TutorialLayout
      badgeName={t('badge')}
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() =>
              goToStage('FeatureVirtualTerminalStage')
            }
            onSecondaryButtonClick={() => goToStage('FeatureCardsStage')}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <IllustrationAnimation
          imagesSmall={[
            SetupFlowInvoicesSmallOne,
            SetupFlowInvoicesSmallTwo,
            SetupFlowInvoicesSmallThree,
          ]}
          imagesMedium={[
            SetupFlowInvoicesMediumOne,
            SetupFlowInvoicesMediumTwo,
            SetupFlowInvoicesMediumThree,
          ]}
          imagesLarge={[
            SetupFlowInvoicesLargeOne,
            SetupFlowInvoicesLargeTwo,
            SetupFlowInvoicesLargeThree,
          ]}
          interval={1500}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
