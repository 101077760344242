export type FeatureFlagsSource = {
  key: string
  init: (context: FeatureFlagsSourceContext) => void
  dispose?: (context: FeatureFlagsSourceContext) => void
}

export type FeatureFlagsSourceContext = {
  onValues: (values: unknown) => void
}

type Disposer = () => void

export const FeatureFlagsManager = <
  TSources extends (FeatureFlagsSource | undefined)[]
>(
  sourcesConfig: TSources,
  {
    onValues,
    onSourceValues,
  }: {
    onValues?: (values: object) => void
    /**
     * @description
     * Optionally return `false` if values should be considered "invalid" and
     * should be discarded, so that they are not included in the merged
     * `onValues` values.
     *
     * @example
     * ```ts
     * onSourceValues: (key, values) => {
     *   const isValid = validate(values)
     *   if (!isValid) {
     *     reportError(new Error("Source values invalid."))
     *     return false
     *   }
     *   return true
     * },
     * ```
     */
    onSourceValues?: (
      key: NonNullable<TSources[number]>['key'],
      values: object | undefined
    ) => boolean | void
  } = {}
): Disposer => {
  const sources = sourcesConfig.filter(
    (source) => source
  ) as FeatureFlagsSource[]
  const sourcesValuesByKey = new Map<string, object | undefined>()

  const onValuesCallback = () => {
    const values = sources.reduce((acc, source) => {
      const sourceValues = sourcesValuesByKey.get(source.key)
      Object.assign(acc, sourceValues)
      return acc
    }, {})

    onValues?.(values)
  }

  const createSourceContext = (
    source: FeatureFlagsSource
  ): FeatureFlagsSourceContext => ({
    onValues: (valuesUnknown) => {
      const sourceValues =
        valuesUnknown && typeof valuesUnknown === 'object'
          ? valuesUnknown
          : undefined

      const valid = onSourceValues?.(source.key, sourceValues)
      sourcesValuesByKey.set(source.key, valid === false ? {} : sourceValues)

      const isReady = sourcesValuesByKey.size === sources.length
      if (isReady) {
        onValuesCallback()
      }
    },
  })

  const sourceDisposers: Disposer[] = []
  const disposer = () =>
    sourceDisposers.forEach((sourceDisposer) => sourceDisposer())

  sources.forEach((source) => {
    const sourceContext = createSourceContext(source)
    source.init(sourceContext)
    const sourceDisposer = () => source.dispose?.(sourceContext)
    sourceDisposers.push(sourceDisposer)
  })

  return disposer
}
