import { gql } from '@apollo/client'

export const CreatePaymentInstrument = gql`
  mutation CreatePaymentInstrument(
    $entityUuid: ID!
    $payload: CreatePaymentInstrumentInput!
  ) {
    createPaymentInstrument(entityUuid: $entityUuid, payload: $payload)
  }
`

export const UpdatePaymentInstrument = gql`
  mutation UpdatePaymentInstrument(
    $entityUuid: ID!
    $id: ID!
    $type: PaymentInstrumentType!
    $payload: UpdatePaymentInstrumentInput!
  ) {
    updatePaymentInstrument(
      entityUuid: $entityUuid
      id: $id
      type: $type
      payload: $payload
    )
  }
`

export const LinkPaymentInstrument = gql`
  mutation LinkPaymentInstrument(
    $entityUuid: ID!
    $id: ID!
    $type: PaymentInstrumentType!
    $contactUuid: ID!
  ) {
    linkPaymentInstrumentWithContact(
      entityUuid: $entityUuid
      id: $id
      type: $type
      contactUuid: $contactUuid
    )
  }
`

export const UnlinkPaymentInstrument = gql`
  mutation UnlinkPaymentInstrument(
    $entityUuid: ID!
    $id: ID!
    $type: PaymentInstrumentType!
    $contactUuid: ID!
  ) {
    unlinkPaymentInstrumentFromContact(
      entityUuid: $entityUuid
      id: $id
      type: $type
      contactUuid: $contactUuid
    )
  }
`
