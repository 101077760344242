import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { parseApiMoney } from 'utils/parseMoney'

import {
  GetSpendControlLimitAccountBalance,
  GetSpendControlLimitAccountBalanceQueryResponse,
  GetSpendControlLimitAccountBalanceQueryVariables,
} from './graphql/getSpendControlLimitAccountBalance.generated'

interface UseAccountBalanceProps {
  id?: string
}

export const useAccountBalance = ({ id }: UseAccountBalanceProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data } = useQuery<
    GetSpendControlLimitAccountBalanceQueryResponse,
    GetSpendControlLimitAccountBalanceQueryVariables
  >(GetSpendControlLimitAccountBalance, {
    skip: !id,
    variables: { entityUuid, id: id ?? '' },
  })

  return {
    balance: data?.account?.balance?.value
      ? parseApiMoney(data.account.balance.value)
      : null,
  }
}
