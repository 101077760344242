import { ApolloError, makeVar } from '@apollo/client'
import { CompleteEmailChangeStatus } from '@npco/mp-gql-types'

import { CustomerDetailsForm } from 'types/customers'
import { GetCustomers_getCustomers as Customer } from 'types/gql-types/GetCustomers'
import { ProfileSettingsData } from 'types/profile'

export const rvProfileSettings = makeVar({
  id: '',
  email: null,
  firstname: null,
  middlename: null,
  lastname: null,
  address: null,
} as ProfileSettingsData)

export const rvUpdatedEmailNotConfirmed = makeVar<string>('')

export const rvEmailChangeStatus = makeVar<CompleteEmailChangeStatus | null>(
  null
)

export const rvProfileFetchError = makeVar<ApolloError | null>(null)

export const rvNewUserDetails = makeVar<CustomerDetailsForm>(
  {} as CustomerDetailsForm
)

export const rvCustomers = makeVar<(Customer | null)[]>([])
