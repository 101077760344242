import { createTranslations } from '@npco/utils-translations'

export const noAccountBannerTranslations = createTranslations({
  cashflowImageAlt: 'Cashflow Reporting Image',
  title: "You don't have any active Zeller Accounts or Cards.",
  description:
    'Get an overview of your business spending and income sources by using our Zeller Accounts and Cards.',
  ctaPrimaryText: 'Create Account',
  ctaDescription:
    'Create a new Zeller Transaction Account and start transacting today.',
  point1:
    'Settle your earnings into a Zeller Transaction Account to get a full picture of your revenue streams',
  point2:
    'Get better insights in your team spending by contacts and categories',
  point3: 'Identify trends or changes in cash flow over the past 12 months',
  point4:
    'Make better data-driven decisions with a full picture of your business financial health',
})
