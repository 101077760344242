import { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import { BodyDefault, BUTTON_SIZE } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import {
  StyledContactDetails,
  StyledUnlinkButton,
  StyledUnlinkContent,
  StyledUnlinkHeading,
} from './CardHolderDetailsOptOut.styled'
import { useOptOutReceipts } from './hooks/useOptOutReceipts'

interface CardHolderDetailsProps {
  setIsOptOutSuccessful: Dispatch<SetStateAction<boolean>>
}

export const NO_RECORD = 'no-record'
export const CardHolderDetails = ({
  setIsOptOutSuccessful,
}: CardHolderDetailsProps) => {
  const { search } = useLocation()
  const { optOutReceipts, isOptingOutReceipts } = useOptOutReceipts({
    setIsOptOutSuccessful,
  })

  const pan = new URLSearchParams(search).get('pan')
  const cardNumber = pan && pan !== NO_RECORD ? pan?.slice(-4) : ''
  const email = new URLSearchParams(search).get('email') || ''
  const phone = new URLSearchParams(search).get('phone') || ''

  return (
    <>
      <StyledContactDetails>
        <BodyDefault>
          {cardNumber && (
            <span data-testid="opt-out-card-details">
              {translate(
                'page.optOutCardHolderDetails.contactDetails.heading',
                { cardNumber }
              )}
            </span>
          )}
          {email && (
            <span>
              {cardNumber && <br />}
              {translate('page.optOutCardHolderDetails.contactDetails.email', {
                email,
              })}
            </span>
          )}
          {phone && (
            <span>
              {(cardNumber || email) && <br />}
              {translate('page.optOutCardHolderDetails.contactDetails.phone', {
                phone,
              })}
            </span>
          )}
        </BodyDefault>
      </StyledContactDetails>

      <StyledUnlinkHeading>
        {translate('page.optOutCardHolderDetails.confirmDetails.heading')}
      </StyledUnlinkHeading>

      <StyledUnlinkContent>
        {translate('page.optOutCardHolderDetails.confirmDetails.text')}
      </StyledUnlinkContent>

      <StyledUnlinkButton
        dataTestId="opt-out-unlink-button"
        isLoading={isOptingOutReceipts}
        onClick={optOutReceipts}
        size={BUTTON_SIZE.MEDIUM}
      >
        {translate('page.optOutCardHolderDetails.confirmDetails.buttonLabel')}
      </StyledUnlinkButton>
    </>
  )
}
