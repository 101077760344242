import { EditAccountModalForm } from './EditAccountModalForm'
import { useEditAccountState } from './hooks/useEditAccountState'

export const EditAccountModal = () => {
  const { state } = useEditAccountState()

  if (state === null) {
    return null
  }

  return <EditAccountModalForm />
}
