import {
  Box,
  ButtonFill,
  ButtonGhost,
  Flex,
  Heading,
  ModalBasic,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { MultiStageModal } from 'components/MultiStageModal'

import { ErrorDescription } from './DeliveryAddressErrorPage.styled'

interface DeliveryAddressErrorPageProps {
  refetch: () => void
  onCancel?: () => void
  goToCustomAddress?: () => void
}

export const DeliveryAddressErrorPage = ({
  refetch,
  onCancel,
  goToCustomAddress,
}: DeliveryAddressErrorPageProps) => {
  return (
    <>
      <ModalBasic.Body>
        <Flex alignItems="center" height="100%">
          <Flex
            flexDirection="column"
            alignItems="center"
            pt="24px"
            width="100%"
          >
            <Heading.H3>
              {translate('page.deliveryAddressForm.errorTitle')}
            </Heading.H3>
            <Box pt="16px" pb="32px" textAlign="center">
              <ErrorDescription>
                {translate('page.deliveryAddressForm.errorDescription1')}
              </ErrorDescription>
              <ErrorDescription>
                {translate('page.deliveryAddressForm.errorDescription2')}
              </ErrorDescription>
            </Box>
            <Flex>
              {goToCustomAddress && (
                <Box pr="8px">
                  <ButtonGhost onClick={goToCustomAddress}>
                    {translate('page.deliveryAddressForm.errorEnterManually')}
                  </ButtonGhost>
                </Box>
              )}
              <Box>
                <ButtonFill onClick={refetch}>
                  {translate('page.deliveryAddressForm.errorRetryLabel')}
                </ButtonFill>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </ModalBasic.Body>
      {onCancel && (
        <MultiStageModal.FooterButtons
          shouldShowBackButton
          onCancel={onCancel}
          isConfirmDisabled
        />
      )}
    </>
  )
}
