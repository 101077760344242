import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'
import { CreateAccountLocationSchema } from 'features/CreateAccount/hooks/useGoToCreateAccountStage/useGoToCreateAccountStage.types'

export const useCreateAccountStage = () => {
  const locationState = useLocationState(CreateAccountLocationSchema)

  const stage = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.CreateAccountModal.stage
  }, [locationState])

  return { stage }
}
