import { gql, type TypedDocumentNode } from '@apollo/client'

export type OutstandingExpensesFormFragment = {
  __typename?: 'Entity'
  id: string
  outstandingTransactionRequirementConfig: {
    __typename?: 'OutstandingTransactionRequirementConfig'
    attachments: boolean
    category: boolean
    note: boolean
    accountingCategory: boolean
  } | null
}

export const OutstandingExpensesFormFragmentDoc = gql`
  fragment OutstandingExpensesFormFragment on Entity {
    id
    outstandingTransactionRequirementConfig {
      attachments
      category
      note
      accountingCategory
    }
  }
` as unknown as TypedDocumentNode<OutstandingExpensesFormFragment, undefined>
