import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BREAKPOINT,
  EducationPanel,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import PreviewImage from './assets/income-category-preview.png'
import MobilePreviewImage from './assets/income-category-preview-mobile.png'
import TabletPreviewImage from './assets/income-category-preview-tablet.png'
import { translations } from './WelcomeIncomeBanner.i18n'

export const WelcomeIncomeBanner = () => {
  const t = useTranslations(translations)
  const isTablet = useIsMobileResolution(BREAKPOINT.MD)
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)

  const imageSrc = useMemo(() => {
    if (isMobile) {
      return MobilePreviewImage
    }

    return isTablet ? TabletPreviewImage : PreviewImage
  }, [isMobile, isTablet])

  return (
    <Box mt={{ _: '26px', XS: '34px' }}>
      <EducationPanel
        image={<img src={imageSrc} alt={t('alt')} />}
        tag={t('comingSoonPill')}
        title={t('title')}
        description={t('description')}
        items={[t('benefits1'), t('benefits2'), t('benefits3')]}
        asideBreakpoint={BREAKPOINT.MD}
      />
    </Box>
  )
}
