import { ReactComponent as ChartIcon } from 'assets/svg/chart.svg'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { useAccountMultiselectWithAllElement } from 'layouts/AccountLayout/hooks/useAccountMultiselectWithAllElement/useAccountMultiselectWithAllElement'
import { page } from 'translations'

import { MultiSelectFilter } from '../MultiSelectFilter/MultiSelectFilter'
import { statusItems } from './AccountFilterStatus.constants'

export const AccountFilterStatus = () => {
  const { status, setStatus, resetStatus } = useAccountFilters()

  const { listItems, selectedItems, onChange, onClose, areAllItemsSelected } =
    useAccountMultiselectWithAllElement({
      data: status || [],
      setData: setStatus,
      dataItems: statusItems,
    })

  return (
    <MultiSelectFilter
      listItems={listItems}
      selectedItems={selectedItems}
      values={status || []}
      onChange={onChange}
      onClose={onClose}
      onReset={resetStatus}
      filterName={page.accounts.transactionsList.filters.status}
      maxHeight="19rem"
      icon={<ChartIcon />}
      areAllItemsSelected={areAllItemsSelected}
    />
  )
}
