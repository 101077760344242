import { useEffect } from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { CorporateCardWalkthrough } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough'
import { useGoToCorporateCardWalkthroughStage } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough-routing'

import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'
import { CardTypeOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useOnAddCard } from 'hooks/useOnAddCard/useOnAddCard'
import { useSubscribeToCardUpdate } from 'hooks/useSubscribeToCardUpdate/useSubscribeToCardUpdate'
import { CardsView, CardsViewContainer } from 'components/CardsView'
import { ActiveCards } from 'components/CardsView/ActiveCards'
import { ClosedCards } from 'components/CardsView/ClosedCards'
import { CorporateCardsEmpty } from 'components/CardsView/components/CardsEmpty/CorporateCardsEmpty'
import { CorporateCardsTable } from 'components/CardsView/components/CardsTable/CorporateCardsTable/CorporateCardsTable'
import { CorporateCardsTableDebitCardV2Fragment } from 'components/CardsView/components/CardsTable/CorporateCardsTable/CorporateCardsTable.generated'
import { useFilterCardStatus } from 'components/CardsView/hooks/useFilterCardStatus'
import { PendingCards } from 'components/CardsView/PendingCards'

import { CardsErrorPage } from '../CardsErrorPage'
import { useCorporateCardWalkthroughRouterState } from './hooks/useCorporateCardWalkthroughRouterState/useCorporateCardWalkthroughRouterState'
import { useQueryCorporateCards } from './hooks/useQueryCorporateCards/useQueryCorporateCards'

export const AllCardsCorporateList = () => {
  const { cards, entity, isLoading, error, refetchCards } =
    useQueryCorporateCards()
  const filteredCards =
    useFilterCardStatus<CorporateCardsTableDebitCardV2Fragment>(cards)
  const { onAddCard } = useOnAddCard(entity, CardTypeOption.ExpenseCard)
  const { userData } = useLoggedInUser()
  const { goToCorporateCardWalkthroughStage } =
    useGoToCorporateCardWalkthroughStage()

  const { updateProductTourStatus, showCorporateCardsWalkthrough } =
    useCustomerProductTourStatus()

  const { isRouterStateReadyForWalkthrough } =
    useCorporateCardWalkthroughRouterState()

  const cardListLength = cards?.length
  const hasCards = cardListLength > 0
  const hasActiveCards = filteredCards.active.length > 0
  const hasOwnedPendingCards = filteredCards.pending.some(
    (card: CorporateCardsTableDebitCardV2Fragment) =>
      card.customer?.id === userData?.id
  )

  const firstCardId = filteredCards.active?.[0]?.id

  const hasClosedCards = filteredCards.closed.length > 0

  const shouldTriggerWalkthrough =
    !isLoading &&
    showCorporateCardsWalkthrough &&
    hasActiveCards &&
    isRouterStateReadyForWalkthrough

  useSubscribeToCardUpdate(shouldTriggerWalkthrough)

  useEffect(() => {
    if (shouldTriggerWalkthrough) {
      goToCorporateCardWalkthroughStage('entryModal')
      updateProductTourStatus({ showCorporateCardsSettingsWalkthrough: false })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTriggerWalkthrough])

  if (error && cards.length === 0 && !isLoading) {
    return <CardsErrorPage retry={() => refetchCards()} pt="24px" />
  }

  return (
    <CardsViewContainer data-testid="corporate-cards-view">
      {!isLoading && <CorporateCardWalkthrough firstCardId={firstCardId} />}
      <CardsView
        hasCards={hasCards}
        isLoading={isLoading}
        emptyView={<CorporateCardsEmpty onAddCard={onAddCard} />}
      >
        <ActiveCards
          onAddCard={onAddCard}
          hasActiveCards={Boolean(hasActiveCards)}
          hasOwnedPendingCards={hasOwnedPendingCards}
          hasClosedCards={hasClosedCards}
          isLoading={isLoading}
        >
          <CorporateCardsTable
            cards={filteredCards.active}
            isLoading={isLoading}
          />
        </ActiveCards>
        <PendingCards numberOfPendingCards={filteredCards.pending.length}>
          <CorporateCardsTable
            cards={filteredCards.pending}
            isLoading={isLoading}
            isReadonly
          />
        </PendingCards>
        <ClosedCards numberOfClosedCards={filteredCards.closed.length}>
          <CorporateCardsTable
            cards={filteredCards.closed}
            isLoading={isLoading}
          />
        </ClosedCards>
      </CardsView>
    </CardsViewContainer>
  )
}
