import dayjs from 'utils/dayjs'

export const getTranslationString = () => {
  const hour = dayjs().hour()

  if (hour >= 5 && hour < 12) {
    return 'page.dashboard.headerMorning'
  }

  if (hour >= 21 || hour <= 5) {
    return 'page.dashboard.headerEvening'
  }

  return 'page.dashboard.headerAfternoon'
}
