import {
  IncomeAndExpenseChart,
  IncomeAndExpenseError,
  IncomeAndExpenseTopSection,
  InverseIncomeOrExpenseMessage,
  useChartDateRange,
  useSelectedAccountId,
  useSelectedDateRange,
  useSelectMonth,
} from '@npco/mp-feature-cashflow-reporting'
import { useTranslations } from '@npco/utils-translations'
import dayjs from 'dayjs'

import { useTotalIncomeAndExpenseData } from './hooks/useTotalIncomeAndExpense'
import { totalIncomeAndExpenseTranslations } from './TotalIncomeAndExpense.i18n'
import * as styled from './TotalIncomeAndExpense.styled'

type TotalIncomeAndExpenseProps = {
  entityTimezone: string | undefined
  isLoadingTimezone: boolean
}

export const TotalIncomeAndExpense = ({
  entityTimezone,
  isLoadingTimezone,
}: TotalIncomeAndExpenseProps) => {
  const t = useTranslations(totalIncomeAndExpenseTranslations)
  const selectedAccount = useSelectedAccountId()
  const { selectedDateRange } = useSelectedDateRange()
  const { selectMonth } = useSelectMonth()

  const {
    chartDate,
    goToPreviousMonth,
    goToNextMonth,
    hasInitialisedTimezone,
  } = useChartDateRange({
    entityTimezone,
    isLoadingTimezone,
  })

  const {
    incomeAndExpenses,
    isLoadingIncomeAndExpenses,
    error,
    refetch,
    isRefetching,
    isFetchingMore,
    hasInverseExpenseOrIncome,
  } = useTotalIncomeAndExpenseData({
    accountUuid:
      selectedAccount === 'all accounts'
        ? undefined
        : selectedAccount.accountId,
    date: chartDate,
    timeZone: entityTimezone,
    skip: !hasInitialisedTimezone,
  })

  const isLoading =
    (isLoadingIncomeAndExpenses && incomeAndExpenses.length === 0) ||
    isRefetching ||
    isLoadingTimezone ||
    !hasInitialisedTimezone

  const shouldDisableNextButton = !dayjs(chartDate)
    .endOf('month')
    .isBefore(dayjs().tz(entityTimezone))

  return (
    <styled.IncomeAndExpenseWrapper
      aria-label="Account balance over time"
      as="section"
      pb={{ _: '32px', SM: '40px' }}
    >
      <IncomeAndExpenseTopSection
        incomeAndExpenses={incomeAndExpenses}
        isLoadingIncomeAndExpenses={isLoading}
        selectedDateRange={selectedDateRange}
        timezone={entityTimezone}
        isFetchingMore={isFetchingMore}
      />
      {error ? (
        <IncomeAndExpenseError
          title={t('errorTitle')}
          description={t('errorDescription')}
          handleTryAgain={refetch}
        />
      ) : (
        <>
          <IncomeAndExpenseChart
            isLoadingIncomeAndExpenses={isLoading}
            incomeAndExpenses={incomeAndExpenses}
            goToPreviousMonth={goToPreviousMonth}
            goToNextMonth={shouldDisableNextButton ? undefined : goToNextMonth}
            handleChartBarClick={selectMonth}
            isFetchingMore={isFetchingMore}
            timezone={entityTimezone}
          />
          {!isLoading && hasInverseExpenseOrIncome && (
            <InverseIncomeOrExpenseMessage />
          )}
        </>
      )}
    </styled.IncomeAndExpenseWrapper>
  )
}
