import { useTranslations } from '@npco/utils-translations'

import { SectionHeader } from '../../../SectionHeader'
import { translations } from './AccountBalanceChartHeader.i18n'

export const AccountBalanceChartHeader = () => {
  const t = useTranslations(translations)

  return <SectionHeader heading={t('heading')} />
}
