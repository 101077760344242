import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionErrorType, ConnectionStatus } from '@npco/mp-gql-types'
import {
  Box,
  ButtonLink,
  Flex,
  TooltipBasic,
  Typography,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { Connection } from '../Connections.types'
import {
  StyledInfoIcon,
  StyledRadioOutlineCardBrand,
  TransparentButton,
} from './ConnectionList.styled'

interface ConnectionListProps {
  title: string
  connections: Connection[]
}

export const getConnetionCardDetails = (connectionStatus: ConnectionStatus) => {
  const isConnected = [
    ConnectionStatus.CONNECTED,
    ConnectionStatus.CONNECTED_WITH_ERROR,
  ].includes(connectionStatus)

  if (!isConnected) {
    return null
  }

  return (
    <Flex alignItems="flex-start">
      <ButtonLink style={{ backgroundColor: 'transparent' }}>
        {translate('page.settings.connections.manage')}
      </ButtonLink>
    </Flex>
  )
}

export const ConnectionList = ({ title, connections }: ConnectionListProps) => {
  const navigate = useNavigate()

  if (connections.length === 0) {
    return null
  }

  return (
    <>
      <Box pb="16px">
        <Typography component="h3" variant="heading-xl">
          {title}
        </Typography>
      </Box>
      <Flex flexDirection="column" gap="4px" maxWidth="504px">
        {connections.map((connection) => (
          <TransparentButton
            key={connection.title}
            onClick={() =>
              navigate(
                [
                  ConnectionStatus.CONNECTED,
                  ConnectionStatus.CONNECTED_WITH_ERROR,
                ].includes(connection.status as ConnectionStatus)
                  ? connection.routeManage
                  : connection.routeInitalise
              )
            }
          >
            <StyledRadioOutlineCardBrand
              id={connection.title}
              value={connection.title}
              icon={connection.icon}
              details={getConnetionCardDetails(
                connection.status as ConnectionStatus
              )}
              title={
                <Box>
                  {connection.title}
                  {connection.status ===
                    ConnectionStatus.CONNECTED_WITH_ERROR && (
                    <TooltipBasic
                      placement="top"
                      renderTrigger={({ handlers }) => (
                        <Box
                          height="24px"
                          display="inline-block"
                          verticalAlign="bottom"
                          pl="8px"
                          {...handlers}
                        >
                          <Flex height="100%">
                            <StyledInfoIcon
                              data-testid="error-icon"
                              width={16}
                            />
                          </Flex>
                        </Box>
                      )}
                    >
                      {connection.errorType ===
                      ConnectionErrorType.TOKEN_EXPIRED
                        ? translate('page.settings.connections.error.authError')
                        : translate(
                            'page.settings.connections.error.genericError'
                          )}
                    </TooltipBasic>
                  )}
                </Box>
              }
              desc={connection.subTitle}
            />
          </TransparentButton>
        ))}
      </Flex>
    </>
  )
}
