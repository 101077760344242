import { gql } from '@apollo/client'

export const AddEntityTagMutation = gql`
  mutation AddEntityTag($tag: String!, $entityUuid: ID!) {
    addEntityTag(tag: $tag, entityUuid: $entityUuid)
  }
`

export const DeleteEntityTagMutation = gql`
  mutation DeleteEntityTag($tag: String!, $entityUuid: ID!) {
    removeEntityTag(tag: $tag, entityUuid: $entityUuid)
  }
`
