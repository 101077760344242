import { useMemo } from 'react'
import { gql } from '@apollo/client'
import {
  BuildingsIcon,
  COLOR,
  RadioButtonUnselectedIcon,
} from '@npco/zeller-design-system'

import { formatEntityAddress } from 'utils/address'
import { translate } from 'utils/translations'
import { RegisteredAddressOptionFragment } from 'types/gql-types/RegisteredAddressOptionFragment'
import { RadioCardSkeleton } from 'pages/GlobalModals/components/RadioCardSkeleton'
import {
  StyledRadioCard,
  StyledRadioCardIcon,
} from 'pages/GlobalModals/GlobalModals.styled'

import { AddressOption } from '../../../DeliveryAddress.types'

const getRadioCardIcon = () => (
  <StyledRadioCardIcon color={COLOR.BLUE_1000}>
    <BuildingsIcon />
  </StyledRadioCardIcon>
)

interface RegisteredAddressOptionProps {
  onChange: () => void
  isChecked: boolean
  name: string
  registeredAddress?: RegisteredAddressOptionFragment
  isLoading?: boolean
}

export const RegisteredAddressOption = ({
  onChange,
  isChecked,
  name,
  registeredAddress,
  isLoading,
}: RegisteredAddressOptionProps) => {
  const desc = useMemo(() => {
    if (registeredAddress?.registeredAddress) {
      return formatEntityAddress(registeredAddress.registeredAddress)
    }

    return ''
  }, [registeredAddress])

  if (isLoading) {
    return <RadioCardSkeleton />
  }

  return (
    <StyledRadioCard
      id={AddressOption.RegisteredAddress}
      value={AddressOption.RegisteredAddress}
      title={translate('page.deliveryAddressForm.registeredAddressOptionTitle')}
      desc={desc}
      icon={getRadioCardIcon}
      onChange={onChange}
      checked={isChecked}
      name={name}
      unselectedIcon={RadioButtonUnselectedIcon}
    />
  )
}

RegisteredAddressOption.fragments = {
  Entity: gql`
    fragment RegisteredAddressOptionFragment on Entity {
      registeredAddress {
        country
        postcode
        state
        street1
        street2
        suburb
      }
    }
  `,
}
