import { Flex } from 'reflexbox'
import styled from 'styled-components/macro'

import { StyledLoaderText } from 'components/LoaderWithText/LoaderWithText.styled'

export const StyledLoaderWrapper = styled(Flex)`
  height: 100%;
  justify-content: center;
  align-items: center;
  ${StyledLoaderText} {
    color: ${({ theme }) => theme.colors.GREY_250};
  }
`
