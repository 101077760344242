import styled from 'styled-components'

export const SearchWrapper = styled.div<{
  $isOpen: boolean
  $isMobile?: boolean
  $openWidth: string
}>`
  display: flex;
  align-items: center;
  position: relative;
  width: ${({ $isOpen, $isMobile, $openWidth }) =>
    !$isOpen || $isMobile ? '48px' : $openWidth};

  transition: width 0.4s ease-in-out;
`

export const SearchIconWrapper = styled.div<{ $isOpen: boolean }>`
  z-index: 1;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'none' : 'auto')};
  background-color: transparent;
  position: relative;
  opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
  width: ${({ $isOpen }) => ($isOpen ? '0px' : '48px')};

  transition: opacity 0.4s ease-in-out, width 0.4s ease-in-out;
`
export const SearchInputWrapper = styled.div<{ $isOpen: boolean }>`
  z-index: 0;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
  width: ${({ $isOpen }) => ($isOpen ? '100%' : '0')};
  position: relative;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(-48px)'};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};

  transition: opacity ${({ $isOpen }) => ($isOpen ? '0.32s 0.08s' : '0.2s')}
      ease-in-out,
    transform ${({ $isOpen }) => ($isOpen ? '0s' : '0.2s 0.2s')} ease-in-out,
    width ${({ $isOpen }) => ($isOpen ? '0s' : '0.2s 0.2s')} ease-in-out;
`
