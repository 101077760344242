import { gql, type TypedDocumentNode } from '@apollo/client'

export type CardholderInformationCustomerFragment = {
  __typename?: 'Customer'
  id: string
  entityUuid: string | null
  firstname: string | null
  lastname: string | null
}

export const CardholderInformationCustomerFragmentDoc = gql`
  fragment CardholderInformationCustomerFragment on Customer {
    id
    entityUuid
    firstname
    lastname
  }
` as unknown as TypedDocumentNode<
  CardholderInformationCustomerFragment,
  undefined
>
