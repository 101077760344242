import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { OutstandingFilterSettingsFragmentDoc } from './outstandingFilterSettingsFragment.generated'

const defaultOptions = {} as const
export type GetOutstandingFilterSettingsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetOutstandingFilterSettingsQueryResponse = {
  __typename?: 'Query'
  getEntity: {
    __typename?: 'Entity'
    id: string
    outstandingTransactionRequirementConfig: {
      __typename?: 'OutstandingTransactionRequirementConfig'
      attachments: boolean
      category: boolean
      note: boolean
      accountingCategory: boolean
    } | null
  }
}

export const GetOutstandingFilterSettings = gql`
  query GetOutstandingFilterSettings($entityUuid: ID!) {
    getEntity(entityUuid: $entityUuid) {
      ...OutstandingFilterSettingsFragment
    }
  }
  ${OutstandingFilterSettingsFragmentDoc}
` as unknown as TypedDocumentNode<
  GetOutstandingFilterSettingsQueryResponse,
  GetOutstandingFilterSettingsQueryVariables
>

/**
 * __useGetOutstandingFilterSettingsQuery__
 *
 * To run a query within a React component, call `useGetOutstandingFilterSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingFilterSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingFilterSettingsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetOutstandingFilterSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOutstandingFilterSettingsQueryResponse,
    GetOutstandingFilterSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOutstandingFilterSettingsQueryResponse,
    GetOutstandingFilterSettingsQueryVariables
  >(GetOutstandingFilterSettings, options)
}
export function useGetOutstandingFilterSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutstandingFilterSettingsQueryResponse,
    GetOutstandingFilterSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOutstandingFilterSettingsQueryResponse,
    GetOutstandingFilterSettingsQueryVariables
  >(GetOutstandingFilterSettings, options)
}
export type GetOutstandingFilterSettingsQueryHookResult = ReturnType<
  typeof useGetOutstandingFilterSettingsQuery
>
export type GetOutstandingFilterSettingsLazyQueryHookResult = ReturnType<
  typeof useGetOutstandingFilterSettingsLazyQuery
>
export type GetOutstandingFilterSettingsQueryResult = Apollo.QueryResult<
  GetOutstandingFilterSettingsQueryResponse,
  GetOutstandingFilterSettingsQueryVariables
>
