import { translate } from 'utils/translations'
import { DetailsListItem } from 'components/DetailsListItem'

interface Props {
  accountName?: string | null
}
export const AccountDetailsListItem = ({ accountName }: Props) => {
  return (
    <>
      {accountName && (
        <DetailsListItem
          label={translate('page.transactionDetails.account')}
          value={accountName}
        />
      )}
    </>
  )
}
