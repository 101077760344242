import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BUTTON_SIZE,
  ButtonGhostIconOnly,
  COLOR,
  Flex,
} from '@npco/zeller-design-system'

import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg'

import { translations } from './InvoicePdfPagination.i18n'

interface InvoicePdfPaginationProps {
  className?: string
  pageNumber: number
  totalPages: number
  onPreviousPageClick: (pageNumber: number) => void
  onNextPageClick: (pageNumber: number) => void
}

export const InvoicePdfPagination = ({
  className,
  onNextPageClick,
  onPreviousPageClick,
  pageNumber,
  totalPages,
}: InvoicePdfPaginationProps) => {
  const t = useTranslations(translations)

  const handleOnPreviousPageClick = useCallback(
    (number: number) => () => onPreviousPageClick(number),
    [onPreviousPageClick]
  )

  const handleOnNextPageClick = useCallback(
    (number: number) => () => onNextPageClick(number),
    [onNextPageClick]
  )

  return (
    <Flex
      alignItems="center"
      className={className}
      data-testid="pagination"
      justifyContent="space-between"
    >
      <ButtonGhostIconOnly
        dataTestId="pagination-previous-page"
        iconEnabledColor={COLOR.GREY_550}
        icon={ChevronLeft}
        disabled={pageNumber === 1}
        onClick={handleOnPreviousPageClick(pageNumber - 1)}
        size={BUTTON_SIZE.XSMALL}
      />
      {t('pagination', { pageNumber, totalPages })}
      <ButtonGhostIconOnly
        dataTestId="pagination-next-page"
        iconEnabledColor={COLOR.GREY_550}
        icon={ChevronRight}
        disabled={pageNumber === totalPages}
        onClick={handleOnNextPageClick(pageNumber + 1)}
        size={BUTTON_SIZE.XSMALL}
      />
    </Flex>
  )
}
