import { useTranslations } from '@npco/utils-translations'
import { Box, Message, MessageSize } from '@npco/zeller-design-system'

import { ReactComponent as CardsIcon } from 'assets/svg/graphics/cards.svg'

import { cardEmptyViewMessageTranslations } from './CardEmptyViewMessage.i18n'

interface CardEmptyViewMessageProps {
  title?: string
  description?: string
}

export const CardEmptyViewMessage = ({
  title,
  description,
}: CardEmptyViewMessageProps) => {
  const t = useTranslations(cardEmptyViewMessageTranslations)

  return (
    <Box textAlign="center" data-testid="card-empty-view-message">
      <Message
        title={title ?? t('title')}
        description={description ?? t('description')}
        size={MessageSize.LARGE}
        align="center"
        image={<CardsIcon width={180} />}
      />
    </Box>
  )
}
