import { memo } from 'react'
import { Box } from '@npco/zeller-design-system'

import {
  validateFirstName,
  validateLastName,
  validateMiddleName,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

export interface NameSectionProps {
  fieldNames?: string[]
  isRequired?: boolean
  areNameFieldsDisabled?: boolean
}

export const ProfilePersonalInformation = memo(
  ({
    isRequired,
    fieldNames = ['firstName', 'secondName', 'lastName'],
    areNameFieldsDisabled = false,
  }: NameSectionProps) => {
    return (
      <>
        <FormLine dataTestId="name-form-section">
          <Box mb="2.5rem">
            <InputAdaptiveFieldWrapper
              label={translate('form.shared.firstName')}
              name={fieldNames[0]}
              type="text"
              validate={isRequired ? validateFirstName : undefined}
              maxLength={20}
              disabled={areNameFieldsDisabled}
              isRequired={isRequired}
            />
          </Box>

          <Box mb="2.5rem">
            <InputAdaptiveFieldWrapper
              label={translate('form.shared.middleName')}
              name={fieldNames[1]}
              type="text"
              maxLength={20}
              validate={validateMiddleName}
              disabled={areNameFieldsDisabled}
            />
          </Box>
        </FormLine>

        <FormLine dataTestId="last-name-form-section">
          <Box mb="2.5rem">
            <InputAdaptiveFieldWrapper
              label={translate('form.shared.lastName')}
              name={fieldNames[2]}
              type="text"
              validate={isRequired ? validateLastName : undefined}
              maxLength={40}
              disabled={areNameFieldsDisabled}
              isRequired={isRequired}
            />
          </Box>
        </FormLine>
      </>
    )
  }
)
