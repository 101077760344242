import { gql, type TypedDocumentNode } from '@apollo/client'

export type UseMultiEntityTabsCustomerEntityRelationFragment = {
  __typename?: 'CustomerEntityRelation'
  entityUuid: string
  isVisible: boolean
  order: number | null
  entity: { __typename?: 'Entity'; id: string; name: string | null }
}

export const UseMultiEntityTabsCustomerEntityRelationFragmentDoc = gql`
  fragment UseMultiEntityTabsCustomerEntityRelationFragment on CustomerEntityRelation {
    entityUuid
    isVisible
    order
    entity {
      id
      name
    }
  }
` as unknown as TypedDocumentNode<
  UseMultiEntityTabsCustomerEntityRelationFragment,
  undefined
>
