import { useMemo } from 'react'

import { useOutstandingFilterContext } from '../../context/useOutstandingFilterContext'

export const useGetOutstandingFilter = () => {
  const { isOutstandingFilterOn } = useOutstandingFilterContext()

  const isFilterOn = useMemo(() => {
    return isOutstandingFilterOn
  }, [isOutstandingFilterOn])

  return {
    isFilterOn,
  }
}
