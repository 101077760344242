import { useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ColourPicker, ModalForm } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translationsShared } from 'translations'

import { translations } from './ColourPickerModal.i18n'

const DEFAULT_COLOUR = '#000000'

interface ColourPickerModalProps {
  initialColour?: string
  onSave: (colour: string) => void
  isOpen: boolean
  onClose: () => void
  title: string
  mobileTitle: string
}

export const ColourPickerModal = ({
  initialColour,
  onSave,
  isOpen,
  onClose,
  title,
  mobileTitle,
}: ColourPickerModalProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)

  const [selectedColour, setSelectedColour] = useState(
    initialColour ?? DEFAULT_COLOUR
  )

  const [hasError, setHasError] = useState(false)
  const isMobile = useIsMobileResolution()

  const handleSetColour = (colour: string) => {
    setSelectedColour(colour)
    setHasError(false)
  }

  const handleSave = () => {
    if (!hasError) {
      onSave(selectedColour)
      onClose()
    }
  }

  return (
    <ModalForm
      canScroll={false}
      showOverflow
      title={isMobile ? mobileTitle : title}
      onClickPrimary={handleSave}
      isOpen={isOpen}
      onCancel={onClose}
      primaryButtonLabel={tShared('save')}
      secondaryButtonLabel={tShared('cancel')}
    >
      <ColourPicker
        value={selectedColour}
        onValue={handleSetColour}
        errorMessage={t('errorMessage')}
        onError={() => setHasError(true)}
      />
    </ModalForm>
  )
}
