import { Box, Flex } from '@npco/zeller-design-system'
import { indexOf } from 'ramda'

import { SpinnerWrapped } from 'components/Spinner'

import * as styled from './AuthLoadingPage.styled'

interface Props {
  children?: React.ReactNode[]
}

export const AuthLoadingPage = ({ children }: Props) => (
  <Box display="relative" height="100%">
    <styled.ZellerLogo />
    <Flex
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="relative">
        <SpinnerWrapped variant="centre" backgroundColor="white" />
        <styled.TextWrapper>
          {children?.map((element) => (
            <styled.Text key={`loading-copy-${indexOf(element, children)}`}>
              {element}
            </styled.Text>
          ))}
        </styled.TextWrapper>
      </Box>
    </Flex>
  </Box>
)
