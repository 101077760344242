import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ProductContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const ProductDisplayCard = styled(Flex)`
  width: 100%;
  padding: 24px 24px 56px 24px;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.GREY_30};
  border-radius: 16px;
  font-family: 'Inter Zeller';
  font-style: normal;
  font-weight: normal;
  position: relative;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 566px;
    padding: 24px;
  }
`
export const ProductDisplayTitle = styled.div`
  font-weight: 500;
  text-align: center;
  font-size: 24px;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    font-size: 32px;
  }
`

export const BulletPointsContainer = styled.div`
  position: static;
  margin: -32px auto 5px;
  z-index: 1;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    position: absolute;
    top: 96px;
    margin: 0;
    right: -170px;
  }
`
