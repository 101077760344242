import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'

import { useCustomerEntityMappingLazyQuery } from './graphql/CustomerEntityMapping.generated'

export const useCustomerEntityMappingDataLazy = () => {
  const [getCustomerEntityMappingApollo, { loading }] =
    useCustomerEntityMappingLazyQuery({
      errorPolicy: 'all',
      onError: (e) => {
        ErrorLogger.reportError(
          '[MultiEntity] Error initialising entity mapping',
          e
        )
      },
    })

  const getCustomerEntityMapping = useCallback(async () => {
    try {
      const result = await getCustomerEntityMappingApollo()

      return {
        defaultEntityUuid:
          result.data?.getCustomerEntityMapping?.defaultEntityUuid,
        entityRelations:
          result.data?.getCustomerEntityMapping?.entityRelations ?? [],
      }
    } catch (err) {
      return null
    }
  }, [getCustomerEntityMappingApollo])

  return { getCustomerEntityMapping, isLoading: loading }
}
