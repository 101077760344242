import { Box } from '@npco/zeller-design-system'

import { validateMobileNumber } from 'utils/formValidation'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { page } from 'translations'

interface Props {
  isDisabled?: boolean
  phone?: string
}

export const PhoneField = ({ phone, isDisabled }: Props) => (
  <Box mb="2.5rem">
    <InputAdaptiveFieldWrapper
      disabled={isDisabled}
      label={page.settings.users.mobilePhone}
      name="phone"
      type="tel"
      value={phone}
      validate={(value) => validateMobileNumber(value, false)}
    />
  </Box>
)
