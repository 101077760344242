import { PartialTransactionUpdateType } from 'hooks/transactions'

import { Contact } from '../../TransactionDrawer.types'
import { useTransactionDetailsQuery } from './hooks/useTransactionDetailsQuery'
import { TransactionDetailsError } from './TransactionDetailsError'
import { TransactionDetailsSkeleton } from './TransactionDetailsSkeleton'
import { TransactionDetailsView } from './TransactionDetailsView'

interface Props {
  debitCardTransactionUuid: string
  updateTransaction: (
    update: PartialTransactionUpdateType,
    contactUpdate?: Partial<Contact>
  ) => void
}

export const TransactionDetails = ({
  debitCardTransactionUuid,
  updateTransaction,
}: Props) => {
  const { transaction, isLoading, error, refetch } = useTransactionDetailsQuery(
    {
      debitCardTransactionUuid,
    }
  )

  if (isLoading) {
    return <TransactionDetailsSkeleton />
  }

  if (error || transaction === undefined) {
    return <TransactionDetailsError retry={refetch} />
  }

  return (
    <TransactionDetailsView
      transaction={transaction}
      updateTransaction={updateTransaction}
    />
  )
}
