import { useTranslations } from '@npco/utils-translations'
import { INFOBOX_VARIANT } from '@npco/zeller-design-system'

import { ExpenseCardBudgetMessage } from '../components/ExpenseCardBudgetMessage'
import { expenseCardLowAvailableBalanceTranslations } from './ExpenseCardLowAvailableBalanceWarningMessage.i18n'

interface ExpenseCardLowAvailableBalanceWarningMessageProps {
  isAdmin: boolean
  onEdit: () => void
}

export const ExpenseCardLowAvailableBalanceWarningMessage = ({
  isAdmin,
  onEdit,
}: ExpenseCardLowAvailableBalanceWarningMessageProps) => {
  const t = useTranslations(expenseCardLowAvailableBalanceTranslations)
  return (
    <ExpenseCardBudgetMessage
      dataTestId="expend-card-low-available-balance-warning-message"
      variant={INFOBOX_VARIANT.WARNING}
      isAdmin={isAdmin}
      messageAdmin={t('messageAdmin')}
      messageNonAdmin={t('message')}
      buttonMessage={t('editButton')}
      onClick={onEdit}
    />
  )
}
