import { translate } from 'utils/translations'
import { getRoundedPercentage } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/DashboardPerformance.utils'
import { getCurrentDay } from 'pages/Dashboard/utils/timeUtils'
import { page } from 'translations'

export const getTodayComparisonText = (
  todaySales: number,
  lastWeekSales: number
) => {
  const day = getCurrentDay()

  if (lastWeekSales <= 0) {
    return translate(
      'page.dashboard.payments.sitePerformance.chart.noSalesLastWeek',
      { day }
    )
  }

  return translate(
    'page.dashboard.payments.sitePerformance.chart.percentageOfSales',
    { percentage: getRoundedPercentage(todaySales, lastWeekSales), day }
  )
}

export const getThisMonthComparisonText = () => {
  return page.dashboard.payments.sitePerformance.chart.thisTimeLastMonth
}
