import { BodyLarge, H1 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledContainer = styled.div<{
  $maxWidth: string
  $justifyContent?: string
  margin?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: inherit;
  max-width: ${(props) => props.$maxWidth};
  height: 100%;
  margin: ${({ margin }) => margin};
  margin-bottom: 2.5rem;
  position: relative;

  @media screen and (max-width: ${BREAKPOINT.XL - 1}px) {
    max-width: 512px;
  }

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    max-width: 560px;
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  ${({ $justifyContent }) =>
    $justifyContent && `justify-content: ${$justifyContent}`};
`

export const StyledOnboardingDescription = styled(BodyLarge)`
  margin: 0 0 48px 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  white-space: pre-wrap;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin-bottom: 32px;
  }
`

export const StyledTitle = styled(H1)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin-bottom: 24px;
    max-width: 312px;
    line-height: 44px;
  }
`
