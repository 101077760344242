import { useCallback } from 'react'

import { useParseEntityIdParam } from '../useParseParam/useParseParam'

export const useConvertToUuid = () => {
  const { parseEntityIdParam } = useParseEntityIdParam()

  const convertToUuid = useCallback(
    (maybeShortUuid: string | undefined) => {
      return parseEntityIdParam(maybeShortUuid).entityId ?? ''
    },
    [parseEntityIdParam]
  )

  return { convertToUuid }
}
