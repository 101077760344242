import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  closeBody: "Close if you're not ready to send your Invoice just yet.",
  closeTitle: 'Come Back Later',
  finish: 'Finish',
  finishBody:
    "Now that you're all caught up, start here by adding a Customer for the Invoice.",
  finishTitle: "You're Ready",
  hideTips: 'Hide Tips',
  modalBody:
    'Completing each section in order makes creating your Invoice a focused and simple process.',
  modalPrimaryButton: "Let's Go!",
  modalSecondaryButton: 'Skip Tour',
  modalTitle: 'Create Your First Invoice',
  previewBody: 'Use this action to see the Invoice PDF and your Payment Page.',
  previewTitle: 'Preview Invoice',
  saveBody: 'Pick up where you left off whenever you return to this Invoice.',
  saveTitle: 'Save Your Progress',
  sendBody:
    "When you've completed your Invoice don't forget to Send it so that you can get paid.",
  sendTitle: 'Send Your Invoice',
  settingsBody:
    'Manage your Invoice preferences such as; adding a custom logo, managing payment reminders, customising receipts and assigning Users.',
  settingsTitle: 'Settings for Invoices',
  startAgain: 'Start Again',
})
