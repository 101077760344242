import { ApolloCache } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'

interface CacheAccountStatusProps {
  cache: ApolloCache<object>
  status: DebitCardAccountStatus
  accountId: string
}
export const cacheAccountStatus = ({
  cache,
  accountId,
  status,
}: CacheAccountStatusProps) => {
  cache.modify({
    id: cache.identify({ id: accountId, __typename: 'DebitCardAccountV2' }),
    fields: {
      status() {
        return status
      },
    },
  })
}
