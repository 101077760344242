import { currencyFormatter } from '@npco/component-mp-common'
import { SkeletonBasic } from '@npco/zeller-design-system'

import { StyledSummaryTitle, StyledSummaryValue } from './SummarySection.styled'

interface SummarySectionProps {
  title: React.ReactNode
  amountInCents: number
  isLoading: boolean
  children?: React.ReactNode
}

export const SummarySection = ({
  title,
  amountInCents,
  isLoading,
  children,
}: SummarySectionProps) => {
  return (
    <>
      <StyledSummaryTitle>{title}</StyledSummaryTitle>
      <StyledSummaryValue>
        {isLoading ? (
          <SkeletonBasic width="108px" height="20px" isRounded />
        ) : (
          currencyFormatter(amountInCents)
        )}
        {children}
      </StyledSummaryValue>
    </>
  )
}
