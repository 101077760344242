import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'

import { UseCardBalanceDebitCardV2Fragment } from './useCardBalance.generated'

export const useCardBalance = (card?: UseCardBalanceDebitCardV2Fragment) => {
  return useMemo(() => {
    const cardAccountBalance = card?.debitCardAccount.balance
    const balanceValue = cardAccountBalance
      ? parseInt(cardAccountBalance.value, 10)
      : 0
    return currencyFormatter(balanceValue)
  }, [card?.debitCardAccount])
}

useCardBalance.fragments = {
  DebitCardV2: gql`
    fragment UseCardBalanceDebitCardV2Fragment on DebitCardV2 {
      debitCardAccount {
        __typename
        id
        balance {
          value
        }
      }
    }
  `,
}
