import { slideSideAnimationVariants } from '@npco/zeller-design-system'

import { ContactTransferFields } from 'pages/Transfer/states/ContactTransferFields/ContactTransferFields'
import { TransferStateCondition as Condition } from 'pages/Transfer/states/TransferState/TransferStateCondition'
import {
  TransferContactState,
  TransferGeneralState,
} from 'pages/Transfer/Transfer.stateMachine'

export const TransferContactFields = () => {
  return (
    <Condition
      parent={TransferGeneralState.ContactTransfer}
      allowedStates={[TransferContactState.TransferDetails]}
      animationVariants={slideSideAnimationVariants}
    >
      <ContactTransferFields />
    </Condition>
  )
}
