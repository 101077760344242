import { BodySmall, DotBasic } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDotDivider = styled(DotBasic)`
  margin-left: 8px;
  margin-right: 8px;
`

export const StyledAccountDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`

export const StyledBankIcon = styled.img`
  width: 48px;
  height: 48px;
  border: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.GREY_150};
  border-radius: 7px;
`
