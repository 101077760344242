import { gql, TypedDocumentNode } from '@apollo/client'

import { AssignedCardsList } from '../AssignedCardsList'
import { GetAssignedCardsQueryResponse } from './GetAssignedCards.generated'

export const GetAssignedCards = gql`
  query GetAssignedCards($entityUuid: ID!) {
    getDebitCardsV2(entityUuid: $entityUuid, limit: 50) {
      cards {
        __typename
        ...AssignedCardsListDebitCardV2Fragment
        customerUuid
      }
    }
  }

  ${AssignedCardsList.fragments.DebitCardV2}
` as TypedDocumentNode<GetAssignedCardsQueryResponse, never>
