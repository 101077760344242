import { useCallback, useMemo, useState } from 'react'
import { Box } from '@npco/zeller-design-system'
import { MultiSelectTriggerRenderProps } from 'design-system/Components/MultiSelect'
import {
  SelectSearchInput,
  SelectSelectedItem,
  SelectSize,
  SelectStandard,
} from 'design-system/Components/Select'

import { useContactsSearch } from 'hooks/useContactsSearch/useContactsSearch'
import { translate } from 'utils/translations'
import { ContactListItem } from 'layouts/AccountLayout/filters/AccountFilterContact/AccountFilterContact.utils'
import { AccountFilterContactLoadingState } from 'layouts/AccountLayout/filters/AccountFilterContact/AccountFilterContactLoadingState/AccountFilterContactLoadingState'
import { AccountFilterContactPlaceholder } from 'layouts/AccountLayout/filters/AccountFilterContact/AccountFilterContactPlaceholder/AccountFilterContactPlaceholder'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { page } from 'translations'

import { SelectMobileFilterFooter } from '../../../../components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'
import { FilterMobileSelectTrigger } from '../FilterMobileSelectTrigger/FilterMobileSelectTrigger'

const filterName = page.accounts.transactionsList.filters.contact

export const AccountMobileFilterContact = () => {
  const { contact, setContact, resetContact } = useAccountFilters()
  const [localContact, setLocalContact] = useState(contact)
  const resetLocalData = useCallback(() => {
    setLocalContact(DEFAULT_FILTERS_VALUES.contact)
  }, [])

  const {
    contactItems,
    resetSearch,
    searchInput,
    updateSearchValue,
    isLoading,
  } = useContactsSearch()

  const renderTrigger = useMemo(() => {
    return ({ ref, ...renderTriggerProps }: MultiSelectTriggerRenderProps) => (
      <FilterMobileSelectTrigger
        filterName={filterName}
        selectedItems={contact ? [contact] : []}
        values={contact ? [contact.value] : []}
        innerRef={ref}
        {...renderTriggerProps}
      />
    )
  }, [contact])

  const onChange = (selectedContact: SelectSelectedItem<ContactListItem>) => {
    setLocalContact(selectedContact as ContactListItem)
  }

  const onReset = () => {
    resetContact()
    resetLocalData()
  }

  const onApply = (onInnerClose: () => void) => {
    setContact(localContact)
    onInnerClose()
  }

  return (
    <SelectStandard
      items={contactItems}
      selectedItem={localContact}
      onChange={onChange}
      mobileLabel={translate(
        'page.accounts.transactionsList.filters.filterMobileHeader',
        {
          filterName,
        }
      )}
      onClose={resetSearch}
      size={SelectSize.Small}
      renderAdditionalControls={() => (
        <Box p="0.5rem">
          <SelectSearchInput
            value={searchInput}
            setValue={updateSearchValue}
            aria-label={translate('shared.typeToSearch')}
            placeholder={translate('shared.typeToSearch')}
          />
        </Box>
      )}
      shouldCloseOnSelect={false}
      hasSelectedIndicator
      isLoading={isLoading}
      renderLoadingPlaceholder={() => (
        <AccountFilterContactLoadingState isMobile />
      )}
      renderNoItemsPlaceholder={() => (
        <AccountFilterContactPlaceholder searchInput={searchInput} />
      )}
      renderTrigger={renderTrigger}
      renderBottomSection={({ onClose: onInnerClose }) => (
        <SelectMobileFilterFooter
          onReset={onReset}
          onApply={() => onApply(onInnerClose)}
        />
      )}
    />
  )
}
