import { createContext } from 'react'

export interface AccountsTransactionsMetadataContextType {
  areFiltersInDefaultState: boolean
  isActionsVisible: boolean
  isEmpty: boolean
  isFiltersDisabled: boolean
  isFiltersVisible: boolean
  isLoading: boolean
  setAreFiltersInDefaultState: (areFiltersInDefaultState: boolean) => void
  setIsEmpty: (isEmpty: boolean) => void
  setIsFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsActionsVisible: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading: (isLoading: boolean) => void
}

export const AccountsTransactionsMetadataContext =
  createContext<AccountsTransactionsMetadataContextType | null>(null)
