import {
  BodyDefaultTypography,
  BodySmallTypography,
  BodyXSmall,
  BREAKPOINT,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const IconCounter = styled.span`
  ${BodySmallTypography};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyDefaultTypography};
  }
`

export const IconCounterToolTip = styled(BodyXSmall)`
  margin: 0;
`
