import { AccordionComplex, Flex } from '@npco/zeller-design-system'
import { INVOICE_ITEMS_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { FieldArray } from 'formik'

import { translate } from 'utils/translations'

import { InvoiceAccordionCommonProps } from '../../InvoiceFormAccordions.types'
import {
  ACCORDION_CONTENT_PADDING_LEFT_MOBILE,
  InvoiceAccordionContentWrapper,
} from '../InvoiceAccordionContentWrapper/InvoiceAccordionContentWrapper'
import { useInvoiceItemsAccordionState } from './hooks/useInvoiceItemsAccordionState'
import { InvoiceItems } from './InvoiceItems/InvoiceItems'
import { InvoiceItemsAddLineItem } from './InvoiceItemsAddLineItem/InvoiceItemsAddLineItem'
import { InvoiceItemsTotals } from './InvoiceItemsTotals/InvoiceItemsTotals'

export const translations = {
  itemsFormDetails: translate('page.invoice.formSections.items.details'),
  itemsFormTitle: translate('page.invoice.formSections.items.title'),
}

export const InvoiceItemsAccordion = ({
  isExpanded,
  onChange,
}: InvoiceAccordionCommonProps) => {
  const { accordionFormState, accordionDetailsText } =
    useInvoiceItemsAccordionState()

  return (
    <AccordionComplex
      dataTestId="invoice-items"
      details={accordionDetailsText}
      formItemState={accordionFormState}
      isExpanded={isExpanded}
      onChange={onChange}
      title={translations.itemsFormTitle}
    >
      <FieldArray
        name={INVOICE_ITEMS_FIELD}
        render={(helpers) => (
          <Flex flexDirection="column">
            <InvoiceAccordionContentWrapper
              gridGap="0px"
              paddingLeft={{ _: ACCORDION_CONTENT_PADDING_LEFT_MOBILE, MD: 0 }}
              paddingRight={0}
            >
              <InvoiceItems onRemoveLineItem={helpers.remove} />
            </InvoiceAccordionContentWrapper>
            <InvoiceAccordionContentWrapper paddingRight={0}>
              <Flex paddingTop={['40px', '16px']}>
                <Flex flexGrow={1}>
                  <InvoiceItemsAddLineItem onAddLineItem={helpers.push} />
                </Flex>
                <InvoiceItemsTotals />
              </Flex>
            </InvoiceAccordionContentWrapper>
          </Flex>
        )}
      />
    </AccordionComplex>
  )
}
