import {
  AccordionButtonBase,
  AccordionContentWrapper,
  Divider,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CustomizedAccordionWrapper = styled.div`
  ${AccordionButtonBase} {
    padding: 16px 0px;
  }
  ${AccordionContentWrapper} {
    padding: 4px 0px 16px;
  }
`

export const CustomizedDivder = styled(Divider)`
  margin-bottom: 0px;
`
