import { gql } from '@apollo/client'

import { GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment } from './getHasInverseExpenseOrIncome.generated'

export const getHasInverseExpenseOrIncome = (
  incomeAndExpenses: GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment[]
) => {
  return incomeAndExpenses.some(
    (
      incomeAndExpense: GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment
    ) => {
      const isIncomeNegative =
        parseInt(
          incomeAndExpense.transactionDirectionTotalAmounts.income.value,
          10
        ) < 0
      const isExpenseNegative =
        parseInt(
          incomeAndExpense.transactionDirectionTotalAmounts.expense.value,
          10
        ) < 0
      return isIncomeNegative || isExpenseNegative
    }
  )
}

getHasInverseExpenseOrIncome.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment GetHasInverseExpenseOrIncomeCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      transactionDirectionTotalAmounts {
        income {
          value
        }
        expense {
          value
        }
      }
    }
  `,
}
