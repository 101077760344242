import { useEffect, useRef } from 'react'
import { Form } from 'formik'

import { ReceiptProps } from 'types/settings'
import { OptionalReceiptElements } from 'forms/formViews/Receipt/OptionalReceiptElements/OptionalReceiptElements'
import {
  StyledDivider,
  StyledEndingDivider,
  StyledTitle,
} from 'forms/formViews/Receipt/Receipt.styled'
import { CompanyLogoUpload } from 'components/CompanyLogoUpload/CompanyLogoUpload'
import { page } from 'translations'

import { ReceiptDetails } from './ReceiptDetails'

interface ReceiptFormProps {
  values: ReceiptProps
  validateForm: (values: ReceiptProps) => void
  logo: string
  handleRemoveLogo: () => void
  setTemporaryLogo: React.Dispatch<React.SetStateAction<string>>
}

export const Receipt = ({
  values,
  logo,
  handleRemoveLogo,
  validateForm,
  setTemporaryLogo,
}: ReceiptFormProps) => {
  const valuesRef = useRef(values)

  useEffect(() => {
    valuesRef.current = values
  }, [values])

  useEffect(() => {
    validateForm(valuesRef.current)
  }, [validateForm])

  return (
    <Form>
      <StyledTitle>{page.settings.receipt.customiseReceipts}</StyledTitle>
      <CompanyLogoUpload
        logo={logo}
        removeLogo={handleRemoveLogo}
        setTemporaryLogo={setTemporaryLogo}
      />
      <ReceiptDetails
        messageValue={values.message}
        returnsMessageValue={values.returnsMessage}
      />
      <StyledDivider />
      <OptionalReceiptElements />
      <StyledEndingDivider />
    </Form>
  )
}
