import { useMemo, useState } from 'react'

import { assertUnreachable } from 'utils/assertUnreachable'
import { FileWithId } from 'components/File'

import {
  UploadReceiptModalState,
  UploadReceiptStage,
} from './useUploadReceiptsModalState.type'

export const useUploadReceiptsModalState = (): UploadReceiptModalState => {
  const [stage, setStage] = useState(UploadReceiptStage.DragAndDrop)
  const [filesToUpload, setFilesToUpload] = useState<FileWithId[]>([])

  const [failedFiles, setFailedFiles] = useState<FileWithId[]>([])

  const failedFilesIds = useMemo(
    () => failedFiles.map(({ id }) => id),
    [failedFiles]
  )
  const uploadedFiles = useMemo(
    () => filesToUpload.filter(({ id }) => !failedFilesIds.includes(id)),
    [failedFilesIds, filesToUpload]
  )

  switch (stage) {
    case UploadReceiptStage.DragAndDrop: {
      const goToNextStep = (files: FileWithId[]) => {
        setFilesToUpload(files)
        setStage(UploadReceiptStage.Uploading)
      }

      return {
        stage,
        goToNextStep,
      }
    }

    case UploadReceiptStage.Uploading: {
      const goToNextStep = (files: FileWithId[]) => {
        setFailedFiles(files)
        setStage(UploadReceiptStage.FailedUpload)
      }

      return {
        stage,
        filesToUpload,
        uploadedFiles,
        goToNextStep,
      }
    }

    case UploadReceiptStage.FailedUpload: {
      const onRetry = () => {
        setStage(UploadReceiptStage.Reuploading)
      }

      return {
        stage,
        uploadedFiles,
        failedFiles,
        onRetry,
      }
    }

    case UploadReceiptStage.Reuploading: {
      const goToNextStep = (newFailedFiles: FileWithId[]) => {
        setFailedFiles(newFailedFiles)
        setStage(UploadReceiptStage.FailedUpload)
      }

      return {
        stage,
        failedFiles,
        uploadedFiles,
        goToNextStep,
      }
    }

    // no default
  }

  return assertUnreachable(stage)
}
