import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetEndOfCurrentWindowVelocityControlTypeFragmentDoc } from '../../../../../features/Cards/CardSingle/utils/getEndOfCurrentWindow.generated'

export type GetRemainingTimeVelocityControlTypeFragment = {
  __typename?: 'VelocityControlType'
  resetsAt: any
  velocityWindow: Types.VelocityWindowEnum
}

export const GetRemainingTimeVelocityControlTypeFragmentDoc = gql`
  fragment GetRemainingTimeVelocityControlTypeFragment on VelocityControlType {
    ...GetEndOfCurrentWindowVelocityControlTypeFragment
  }
  ${GetEndOfCurrentWindowVelocityControlTypeFragmentDoc}
` as unknown as TypedDocumentNode<
  GetRemainingTimeVelocityControlTypeFragment,
  undefined
>
