import { useMemo } from 'react'

import { useHighEfficiencyImagePreview } from 'hooks/useHighEfficiencyImagePreview/useHighEfficiencyImagePreview'
import { useTagImagePreview } from 'hooks/useTagImagePreview/useTagImagePreview'
import { NullableFileWithId } from 'components/File'

const HIGH_EFFICIENCY_IMAGE_MIME_TYPES = ['image/heic', 'image/heif']
const TAG_IMAGE_MIME_TYPES = ['image/tiff', 'image/tif']

export const usePreviewUrl = (image: NullableFileWithId) => {
  const isHighEfficiencyImage = Boolean(
    image.file && HIGH_EFFICIENCY_IMAGE_MIME_TYPES.includes(image.file.type)
  )

  const isTagImage = Boolean(
    image.file && TAG_IMAGE_MIME_TYPES.includes(image.file.type)
  )

  const {
    imageUrl: highEfficiencyImageUrl,
    isLoading: isLoadingHighEfficiencyImage,
    error: highEfficiencyImageError,
  } = useHighEfficiencyImagePreview({
    image,
    skip: !isHighEfficiencyImage,
  })

  const {
    imageUrl: tagImageUrl,
    isLoading: isLoadingTagImage,
    error: tagImageError,
  } = useTagImagePreview({
    image,
    skip: !isTagImage,
  })

  const previewUrl = useMemo(() => {
    if (isHighEfficiencyImage) {
      return highEfficiencyImageUrl
    }

    if (isTagImage) {
      return tagImageUrl
    }

    if (!image.file) {
      return ''
    }

    return URL.createObjectURL(image.file)
    // Use id instead of comparing entire file object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    image.id,
    isHighEfficiencyImage,
    highEfficiencyImageUrl,
    isTagImage,
    tagImageUrl,
  ])

  const previewError = useMemo(() => {
    if (isHighEfficiencyImage) {
      return highEfficiencyImageError
    }

    if (isTagImage) {
      return tagImageError
    }

    return null
  }, [
    isHighEfficiencyImage,
    highEfficiencyImageError,
    isTagImage,
    tagImageError,
  ])

  return {
    previewUrl,
    isLoading:
      (isHighEfficiencyImage && isLoadingHighEfficiencyImage) ||
      (isTagImage && isLoadingTagImage),
    error: previewError,
  }
}
