import {
  CardFormatOption,
  CreateSetCardSettingsState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

export const getInitialIsAccessibleCard = (
  state: CreateSetCardSettingsState | null
) => {
  if (state?.cardFormat === CardFormatOption.Physical) {
    return !(state?.usePin ?? true)
  }

  return false
}
