const PORTAL_ACCOUNT_ID = 'accountId'
const PORTAL_ENTITY_ID = 'entityId'

export const ACCOUNT = (
  entityId = `:${PORTAL_ENTITY_ID}`,
  accountId = `:${PORTAL_ACCOUNT_ID}`
) => ({
  path: `/orgs/${entityId}/accounts/${accountId}`,
  relative: accountId,
  customPageName: 'Account',
  TRANSACTIONS: {
    path: `/orgs/${entityId}/accounts/${accountId}/transactions`,
    relative: `transactions`,
    customPageName: 'AccountTransactions',
  },
  DETAILS: {
    path: `/orgs/${entityId}/accounts/${accountId}/details`,
    relative: 'details',
    customPageName: 'AccountDetails',
  },
  CARDS: {
    path: `/orgs/${entityId}/accounts/${accountId}/cards`,
    relative: 'cards',
    customPageName: 'AccountCards',
  },
  EDIT: {
    path: `/orgs/${entityId}/accounts/${accountId}/edit`,
    relative: 'edit',
    customPageName: 'AccountEdit',
  },
  CLOSE: {
    path: `/orgs/${entityId}/accounts/${accountId}/close`,
    relative: 'close',
    customPageName: 'AccountClose',
  },
})
