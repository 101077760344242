import { useCallback, useMemo } from 'react'
import { INVOICE_TAX_INCLUSIVE_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceTaxFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField } from 'formik'
import { isEqual } from 'lodash-es'

interface UseInvoiceTaxProps {
  closeModal: () => void
}

export const useInvoiceTax = ({ closeModal }: UseInvoiceTaxProps) => {
  const [field, , helpers] = useField<boolean>(INVOICE_TAX_INCLUSIVE_FIELD)

  const initialValues: InvoiceTaxFormFields = useMemo(
    () => ({
      itemsTaxInclusive: field.value,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleSubmit = useCallback(
    (formValues: InvoiceTaxFormFields) => {
      if (isEqual(initialValues, formValues)) {
        closeModal()
        return
      }

      helpers.setValue(formValues.itemsTaxInclusive)

      closeModal()
    },
    [closeModal, initialValues, helpers]
  )

  return {
    initialValues,
    handleSubmit,
  }
}
