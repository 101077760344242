import { descend, pipe, sort, uniqBy } from 'ramda'

import dayjs from 'utils/dayjs'

export interface WithTimestamp {
  id: string
  timestamp: string
}

const getUpdatesInSameTimePeriod = <T extends WithTimestamp>(
  updates: T[],
  originalTransactions: T[]
) => {
  if (originalTransactions.length === 0) {
    return updates
  }

  const earliestTimestamp =
    originalTransactions[originalTransactions.length - 1].timestamp

  return updates.filter((transactionUpdate) => {
    const updateTimestamp = dayjs(transactionUpdate.timestamp)
    return (
      updateTimestamp.isAfter(earliestTimestamp) ||
      updateTimestamp.isSame(earliestTimestamp)
    )
  })
}

export const mergeUpdates = <T extends WithTimestamp>(
  updates: T[],
  originalTransactions: T[]
) => {
  const updatesInTimeWindow = getUpdatesInSameTimePeriod<T>(
    updates,
    originalTransactions
  )

  return pipe(
    uniqBy((transaction: T) => transaction.id),
    sort(descend((t) => t.timestamp))
  )([...updatesInTimeWindow, ...originalTransactions])
}
