import { useCallback, useEffect } from 'react'
import { Accept } from 'react-dropzone'
import { DragAndDrop } from '@npco/zeller-design-system'

import { usePrevious } from 'hooks/usePrevious'
import { getDragAndDropErrorMessage } from 'utils/getDropzoneErrorMessage'
import { translate } from 'utils/translations'
import { DragAndDropText } from 'components/DragAndDropText'
import { useFileDrop } from 'components/ModalUploadImage/hooks/useFileDrop'

import { Logo, LogoContainer } from './InvoiceCompanyLogoUpload.styled'

const ALLOWED_FILE_TYPES: Accept = {
  'image/*': ['.jpeg', '.jpg', '.tiff', '.png', '.heic', '.heif', '.webp'],
}
const FIVE_MB = 5 * 1024 * 1024

export interface CompanyLogoLayoutProps {
  children: React.ReactNode
  onRemove?: () => void
}

interface InvoiceCompanyLogoUploadProps {
  CompanyLogoLayout: React.ComponentType<CompanyLogoLayoutProps>
  imageUrl?: string
  onImageAdded: (url: string, file: File) => void
  onImageRemoved: () => void
}

export const InvoiceCompanyLogoUpload = ({
  CompanyLogoLayout,
  imageUrl = '',
  onImageAdded: handleOnImageAdded,
  onImageRemoved: handleOnImageRemoved,
}: InvoiceCompanyLogoUploadProps) => {
  const { onDrop, previewUrl, file, reset } = useFileDrop(imageUrl)

  const previousPreviewUrl = usePrevious(previewUrl)

  const handleOnFileRemove = useCallback(() => {
    handleOnImageRemoved()
    // NOTE: as preview url is memoized we must reset for it to recompute and
    // fallback to the empty state
    reset()
  }, [handleOnImageRemoved, reset])

  useEffect(() => {
    if (previousPreviewUrl !== previewUrl && previewUrl && file) {
      handleOnImageAdded(previewUrl, file)
    }
  }, [file, handleOnImageAdded, previewUrl, previousPreviewUrl])

  if (previewUrl) {
    return (
      <CompanyLogoLayout onRemove={handleOnFileRemove}>
        <LogoContainer>
          <Logo
            alt={translate('page.invoicesSettings.general.logoAlt')}
            src={previewUrl}
          />
        </LogoContainer>
      </CompanyLogoLayout>
    )
  }

  return (
    <CompanyLogoLayout>
      <DragAndDrop
        accept={ALLOWED_FILE_TYPES}
        dragAndDropContent={<DragAndDropText />}
        getErrorMessage={getDragAndDropErrorMessage}
        maxFiles={1}
        maxSize={FIVE_MB}
        onDrop={onDrop}
        width="100%"
      />
    </CompanyLogoLayout>
  )
}
