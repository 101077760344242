import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useContactHeadingQuery as useContactHeadingQueryApollo } from './graphql/ContactHeadingQuery.generated'

type UseContactHeadingQueryProps = {
  contactUuid: string
}

export const useContactHeadingQuery = ({
  contactUuid,
}: UseContactHeadingQueryProps) => {
  const entityUuid = useSelectedEntityUuid()

  const { data, loading, error, refetch } = useContactHeadingQueryApollo({
    variables: { entityUuid, contactUuid },
    fetchPolicy: 'cache-only',
  })

  return {
    contact: data?.getContact ?? null,
    isLoadingContact: loading,
    error,
    refetch,
  }
}
