import styled from 'styled-components'

export const ListItem = styled.li`
  display: flex;
  gap: 8px 8px;
`

export const ListItemIcon = styled.div`
  svg circle {
    fill: ${({ theme }) => theme.colors.POS_DARK_GREEN};
  }
`

export const ListItemContent = styled.div`
  text-align: start;
`
