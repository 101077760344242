import { useTranslations } from '@npco/utils-translations'

import { ListItem, ListItemSectionTitle } from 'components/List'

import { TransferDailyMaximumLimitLabel } from './components/TransferDailyMaximumLimitLabel'
import { maximumLimitListItemsTranslations } from './MaximumLimitListItems.i18n'

interface MaximumLimitListItemsProps {
  accountMaximumTransferLimit: string
  dailyMaximumLimit: string | undefined
  dailyMaximumLimitError: boolean
}

export const MaximumLimitListItems = ({
  accountMaximumTransferLimit,
  dailyMaximumLimit,
  dailyMaximumLimitError,
}: MaximumLimitListItemsProps) => {
  const t = useTranslations(maximumLimitListItemsTranslations)

  return (
    <>
      <ListItemSectionTitle title={t('sectionTitle')} />
      <ListItem
        left={t('maximumTransferLimit')}
        right={accountMaximumTransferLimit}
      />
      {!dailyMaximumLimitError && (
        <ListItem
          left={
            <TransferDailyMaximumLimitLabel
              dailyMaximumLimit={t('dailyMaximumLimit')}
              dailyMaximumLimitInfo={t('dailyMaximumLimitInfo')}
            />
          }
          right={dailyMaximumLimit}
        />
      )}
    </>
  )
}
