import { useCallback } from 'react'

import { useCreateExternalAccountContext } from '../../../../contexts/useCreateExternalAccountContext'
import {
  CreateExternalAccountStage,
  ExternalAccountFormState,
} from '../../../../CreateExternalAccount.types'
import { ExternalAccountFields } from '../../../CreateExternalAccountModal.types'

export const useExternalAccountFormStage = () => {
  const { state, goToCreateExternalAccountState } =
    useCreateExternalAccountContext<ExternalAccountFormState>()

  const goToCreateExternalAccountReviewStage = useCallback(
    (values: ExternalAccountFields) => {
      goToCreateExternalAccountState({
        stage: CreateExternalAccountStage.ExternalAccountReviewStage,
        ...values,
      })
    },
    [goToCreateExternalAccountState]
  )

  const goBackToCreateExternalAccountMessageStage = useCallback(() => {
    goToCreateExternalAccountState({
      ...(state || {}),
      stage: CreateExternalAccountStage.ExternalAccountMessageStage,
    })
  }, [state, goToCreateExternalAccountState])

  return {
    goToCreateExternalAccountReviewStage,
    goBackToCreateExternalAccountMessageStage,
    initialExternalAccountFields: {
      shouldUpdateDepositAccount: state?.shouldUpdateDepositAccount ?? false,
      accountName: state?.accountName ?? '',
      accountBsb: state?.accountBsb ?? '',
      accountNumber: state?.accountNumber ?? '',
    },
  }
}
