import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@npco/zeller-design-system'
import { SelectSearchInput } from 'design-system/Components/Select'

import { translate } from 'utils/translations'
import {
  mapTagToSelectItem,
  TagListItem,
} from 'layouts/AccountLayout/filters/AccountFilterTags/AccountFilterTags.utils'
import { NoTagsPlaceholder } from 'layouts/AccountLayout/filters/AccountFilterTags/NoTagsPlaceholder/NoTagsPlaceholder'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { page } from 'translations'

import { MultiSelectMobileFilter } from '../MultiSelectMobileFilter/MultiSelectMobileFilter'

interface Props {
  availableTags: string[]
}

export const AccountMobileFilterTags = ({ availableTags }: Props) => {
  const { tags, setTags, resetTags } = useAccountFilters()
  const [localTags, setLocalTags] = useState(tags)

  const resetLocalTags = useCallback(() => {
    setLocalTags(DEFAULT_FILTERS_VALUES.tags)
  }, [])

  const [searchInput, setSearchInput] = useState('')
  const tagItems = useMemo(
    () =>
      availableTags
        .filter((tag) =>
          tag.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
        )
        .map(mapTagToSelectItem),
    [availableTags, searchInput]
  )

  const onClose = () => {
    setSearchInput('')
  }

  const selectedItems = tagItems.filter(({ value }) =>
    localTags.includes(value)
  )
  const onChange = (newElements: TagListItem[]) => {
    setLocalTags((previouslySelectedTags) => {
      const previouslySelectedTagsOutsideSearch = previouslySelectedTags.filter(
        (localTag) =>
          !tagItems.map((tagItem) => tagItem.value).includes(localTag)
      )

      return [
        ...previouslySelectedTagsOutsideSearch,
        ...newElements.map(({ value }) => value),
      ]
    })
  }
  const onApply = (onInnerClose: () => void) => {
    onClose()
    setTags(localTags)
    onInnerClose()
  }
  const onReset = () => {
    onClose()
    resetTags()
    resetLocalTags()
  }
  const onCancel = (onInnerClose: () => void) => {
    onClose()
    onInnerClose()
    setLocalTags(tags)
  }

  const areAllItemsSelected = availableTags.length === localTags.length

  useEffect(() => {
    setLocalTags(tags)
  }, [tags])

  return (
    <MultiSelectMobileFilter
      items={tagItems}
      values={localTags}
      selectedItems={selectedItems}
      onChange={onChange}
      onApply={onApply}
      onReset={onReset}
      onCancel={onCancel}
      areAllItemsSelected={areAllItemsSelected}
      renderAdditionalControls={() =>
        availableTags.length > 0 && (
          <Box p="0.5rem">
            <SelectSearchInput
              value={searchInput}
              setValue={setSearchInput}
              aria-label={translate('shared.typeToSearch')}
              placeholder={translate('shared.typeToSearch')}
            />
          </Box>
        )
      }
      filterName={page.accounts.transactionsList.filters.tags}
      renderNoItemsPlaceholder={() => (
        <NoTagsPlaceholder searchInput={searchInput} />
      )}
    />
  )
}
