import { UpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'
import { Condition } from 'components/Condition/Condition'

import { useUpgradeAccountsCompleteState } from '../../hooks/useUpgradeAccountsCompleteState'
import { UpgradeAccountsXeroIntegrationStageContent } from './UpgradeAccountsCompleteXeroIntegrationStageContet/UpgradeAccountsCompleteXeroIntegrationStageContent'

export const UpgradeAccountsCompleteXeroIntegrationStage = () => {
  const { state } = useUpgradeAccountsCompleteState()
  return (
    <Condition
      shouldShow={
        state?.stage ===
        UpgradeAccountsCompleteStage.UpgradeAccountsXeroIntegrationStage
      }
    >
      <UpgradeAccountsXeroIntegrationStageContent />
    </Condition>
  )
}
