import { BodyLarge, Flex, H2 } from '@npco/zeller-design-system'
import { Form } from 'formik'
import styled from 'styled-components'

import { Button as Base } from 'components/Buttons/Button'

export const StyledSuccessCopy = styled(BodyLarge)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin-bottom: 32px;
  text-align: center;
  white-space: pre-wrap;
`

export const StyledIconBox = styled(Flex)`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
`

export const StyledContainer = styled.div`
  width: 100%;
`

export const StyledButton = styled(Base)`
  width: 214px;
`

export const StyledReceiptForm = styled(Form)`
  -webkit-tap-highlight-color: transparent;
`

export const StyledHeader = styled(H2)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0 0 32px;
`
