import { DebitCardColour } from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'

export const getCardSummaryBackground = (
  cardColour: DebitCardColour,
  isClosed?: boolean
) => {
  if (isClosed) {
    return COLOR.GREY_30
  }

  switch (cardColour) {
    case DebitCardColour.DARKBLUE:
      return '#F5FAFF'
    case DebitCardColour.LIGHTBLUE:
      return '#F5FBFE'
    case DebitCardColour.YELLOW:
      return '#FFF8F0'
    case DebitCardColour.GREEN:
      return '#F3FBF4'
    case DebitCardColour.BLACK:
    case DebitCardColour.WHITE:
    default:
      return COLOR.GREY_30
  }
}
