import { gql } from '@apollo/client'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { formatWeekdayDateTime } from 'utils/date'
import { DetailsListItem } from 'components/DetailsListItem'

import { StyledDetailsListItemsDivider } from '../../TransactionDetailsInfo.styled'
import {
  translateTransactionDetailsTypesV2,
  translateTransactionDetailsTypesV3,
} from '../../TransactionDetailsInfo.utils'
import {
  BasicDetailsListItemsDebitCardAccountTransactionFragment,
  BasicDetailsListItemsDebitCardTransactionV2Fragment,
} from './BasicDetailsListItems.generated'
import { basicDetailsListItemsTranslations } from './BasicDetailsListItems.i18n'
import { StyledDetailsListItem } from './BasicDetailsListItems.styled'

interface BasicDetailsListItemsProps {
  transaction:
    | BasicDetailsListItemsDebitCardTransactionV2Fragment
    | BasicDetailsListItemsDebitCardAccountTransactionFragment
}

export const BasicDetailsListItems = ({
  transaction,
}: BasicDetailsListItemsProps) => {
  const { timestamp, status, type, reference } = transaction

  const translatedDebitCardTransactionType =
    transaction.__typename === 'DebitCardTransactionV2'
      ? translateTransactionDetailsTypesV2(transaction.type)
      : translateTransactionDetailsTypesV3(transaction.type)

  const t = useTranslations(basicDetailsListItemsTranslations)

  const isBpayTransferOut =
    transaction.__typename === 'DebitCardTransactionV2'
      ? type === DebitCardTransactionTypeV2.BPAY_OUT
      : type === DebitCardAccountTransactionTypeEnum.BPAY_OUT

  const isDepositTransaction =
    transaction.__typename === 'DebitCardAccountTransaction'
      ? transaction.type === DebitCardAccountTransactionTypeEnum.DEPOSIT
      : transaction.type === DebitCardTransactionTypeV2.DEPOSIT

  return (
    <>
      <Box mb="24px">
        <StyledDetailsListItem label={t('status')} status={status} />
        <DetailsListItem
          label={t('date')}
          value={formatWeekdayDateTime(timestamp)}
        />
        <DetailsListItem
          label={t('transactionType')}
          value={translatedDebitCardTransactionType}
        />
        {isBpayTransferOut && reference && (
          <DetailsListItem
            label={t('transactionReferenceNumber')}
            value={reference}
          />
        )}
        {isDepositTransaction && (
          <DetailsListItem
            label="Site Name"
            value={transaction.deposit?.siteName}
          />
        )}
      </Box>
      <StyledDetailsListItemsDivider />
    </>
  )
}

BasicDetailsListItems.fragments = {
  DebitCardTransactionV2: gql`
    fragment BasicDetailsListItemsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      timestamp
      status
      type
      reference
      deposit {
        id
        siteName
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment BasicDetailsListItemsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      timestamp
      status
      type
      reference
      deposit {
        id
        siteName
      }
    }
  `,
}
