import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  SendInvoice as SendInvoiceResponse,
  SendInvoiceVariables,
} from 'types/gql-types/SendInvoice'

import { SendInvoice } from '../graphql/sendInvoice'

export const useSendInvoice = () => {
  const entityUuid = useSelectedEntityUuid()
  const [sendInvoice, { loading: isSendingInvoice }] = useMutation<
    SendInvoiceResponse,
    SendInvoiceVariables
  >(SendInvoice)

  return {
    sendInvoice: (referenceNumber: string) =>
      sendInvoice({
        variables: {
          entityUuid,
          referenceNumber,
        },
      }),

    isSendingInvoice,
  }
}
