import { COLOR } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { getCurrentDay } from 'pages/Dashboard/utils/timeUtils'
import { page } from 'translations'

interface TodaySitePerformanceData {
  todaySales: number
  totalSalesOneWeekAgo: number
}

interface ThisMonthSitePerformanceData {
  totalSalesLastMonth: number
  salesThisMonth: number
}

interface PreviousMonthPerformanceData {
  totalSalesLastMonth: number
  salesThisTimeLastMonth: number
}

interface LastWeekPerformanceData {
  salesThisTimeOneWeekAgo: number
  totalSalesOneWeekAgo: number
}

const getPercentageCappedAt100 = (numerator: number, denominator: number) => {
  if (denominator <= 0) {
    return 100
  }
  return Math.min((numerator / denominator) * 100, 100)
}

const getCurrentSalesPercentage = (
  currentSales: number,
  previousSales: number
) => {
  if (currentSales > 0) {
    return getPercentageCappedAt100(currentSales, previousSales)
  }
  return 0
}

const getCurrentSalesColor = (currentSales: number, previousSales: number) => {
  if (currentSales > previousSales && previousSales > 0) {
    return COLOR.GREEN_1000
  }
  return COLOR.BLUE_1000
}

export const getOuterPiePlaceholderData = (percentage = 100) => [
  { name: '', fill: COLOR.GREY_60, percentage },
]

export const getInnerPiePlaceholderData = (percentage = 100) => [
  { name: '', fill: COLOR.GREY_150, percentage },
]

export const getTotalLastWeekChartData = () => {
  const day = getCurrentDay()

  return [
    {
      name: translate(
        'page.dashboard.payments.sitePerformance.chart.today.oneWeekAgoTotal',
        { day }
      ),
      percentage: 100,
      fill: COLOR.GREY_60,
    },
  ]
}

export const getThisTimeLastWeekPerformanceChartData = ({
  salesThisTimeOneWeekAgo,
  totalSalesOneWeekAgo,
}: LastWeekPerformanceData) => {
  const percentageOfOneWeekAgoSales =
    salesThisTimeOneWeekAgo <= 0
      ? 0
      : getPercentageCappedAt100(salesThisTimeOneWeekAgo, totalSalesOneWeekAgo)

  const day = getCurrentDay()

  return [
    {
      name: translate(
        'page.dashboard.payments.sitePerformance.chart.today.salesThisDayLastWeek',
        { day }
      ),
      percentage: percentageOfOneWeekAgoSales,
      fill: COLOR.GREY_250,
    },
    {
      name: translate(
        'page.dashboard.payments.sitePerformance.chart.today.oneWeekAgoTotal',
        { day }
      ),
      percentage: 100 - percentageOfOneWeekAgoSales,
      fill: 'transparent',
    },
  ]
}

export const getTodayPerformanceChartData = ({
  todaySales,
  totalSalesOneWeekAgo,
}: TodaySitePerformanceData) => {
  const todaySalesPercentage = getCurrentSalesPercentage(
    todaySales,
    totalSalesOneWeekAgo
  )

  return [
    {
      name: page.dashboard.payments.sitePerformance.chart.today.todaySales,
      percentage: todaySalesPercentage,
      fill: getCurrentSalesColor(todaySales, totalSalesOneWeekAgo),
    },
    {
      name: page.dashboard.payments.sitePerformance.chart.today.todaySales,
      percentage: 100 - todaySalesPercentage,
      fill: 'transparent',
    },
  ]
}

export const getTotalLastMonthPerformanceChartData = () => {
  return [
    {
      name: page.dashboard.payments.sitePerformance.chart.thisMonth
        .thisTimeLastMonth,
      percentage: 100,
      fill: COLOR.GREY_60,
    },
  ]
}

export const getThisTimeLastMonthPerformanceChartData = ({
  salesThisTimeLastMonth,
  totalSalesLastMonth,
}: PreviousMonthPerformanceData) => {
  const percentageOfTotalSalesThisTimeLastMonth =
    salesThisTimeLastMonth <= 0
      ? 0
      : getPercentageCappedAt100(salesThisTimeLastMonth, totalSalesLastMonth)
  return [
    {
      name: page.dashboard.payments.sitePerformance.chart.thisMonth
        .thisTimeLastMonth,
      percentage: percentageOfTotalSalesThisTimeLastMonth,
      fill: COLOR.GREY_250,
    },
    {
      name: page.dashboard.payments.sitePerformance.chart.thisMonth
        .lastMonthTotal,
      percentage: 100 - percentageOfTotalSalesThisTimeLastMonth,
      fill: 'transparent',
    },
  ]
}

export const getThisMonthPerformanceChartData = ({
  totalSalesLastMonth,
  salesThisMonth,
}: ThisMonthSitePerformanceData) => {
  const percentageOfPreviousMonthSales = getCurrentSalesPercentage(
    salesThisMonth,
    totalSalesLastMonth
  )
  return [
    {
      name: page.dashboard.payments.sitePerformance.chart.thisMonth
        .totalThisMonth,
      percentage: percentageOfPreviousMonthSales,
      fill: getCurrentSalesColor(salesThisMonth, totalSalesLastMonth),
    },
    {
      name: page.dashboard.payments.sitePerformance.chart.thisMonth
        .totalThisMonth,
      percentage: 100 - percentageOfPreviousMonthSales,
      fill: 'transparent',
    },
  ]
}
