import { gql } from '@apollo/client'
import { IconAvatar, IconAvatarProps } from '@npco/mp-ui-icon-avatar'
import {
  Checkbox,
  DrawerPaddedContainer,
  Flex,
} from '@npco/zeller-design-system'

import { AVATAR_BADGE_SIZE } from 'types/common'

import {
  AccountRowContainer,
  Label,
  LabelContainer,
  SubLabel,
} from './AccountRow.styled'

interface AccountRowProps extends Pick<IconAvatarProps, 'icon'> {
  id: string
  label?: string
  sublabel?: string
  checked?: boolean
  onChange: (e: React.ChangeEvent<any>) => void
}

export const AccountRow = ({
  id,
  label,
  sublabel,
  onChange,
  checked = false,
  icon,
}: AccountRowProps) => {
  return (
    <AccountRowContainer>
      <DrawerPaddedContainer>
        <Flex>
          <IconAvatar badgeSize={AVATAR_BADGE_SIZE.SMALL} icon={icon} />
          <Flex alignItems="center" justifyContent="space-between" flexGrow={1}>
            <LabelContainer>
              <Label>{label}</Label>
              {sublabel && <SubLabel>{sublabel}</SubLabel>}
            </LabelContainer>
            <Checkbox
              name="accounts"
              value={id}
              checked={checked}
              onChange={onChange}
              component="div"
            />
          </Flex>
        </Flex>
      </DrawerPaddedContainer>
    </AccountRowContainer>
  )
}

AccountRow.fragments = {
  AccountRowDebitCardAccountConnectionV2Fragment: gql`
    fragment AccountRowDebitCardAccountConnectionV2Fragment on DebitCardAccountConnectionV2 {
      accounts {
        icon {
          ...IconAvatarIconFragment
        }
      }
    }
    ${IconAvatar.fragments.Icon}
  `,
}
