import { useContext } from 'react'

import { InvoiceDecisionModalContext } from '../InvoiceDecisionModalProvider'

export const useInvoiceDecisionModal = () => {
  const context = useContext(InvoiceDecisionModalContext)

  if (context === null) {
    throw new Error(
      'useInvoiceDecisionModal must be used within InvoiceDecisionModalContext'
    )
  }

  return context
}
