import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseAvailableFundsCorporateCardDebitCardV2FragmentDoc } from '../../../../features/Cards/CardSingle/hooks/useAvailableFundsCorporateCard/useAvailableFundsCorporateCard.generated'
import { UseSpentFundsCorporateCardDebitCardV2FragmentDoc } from '../../../../features/Cards/CardSingle/hooks/useSpentFundsCorporateCard/useSpentFundsCorporateCard.generated'
import {
  UserAndAccountDetailsCustomerFragmentDoc,
  UserAndAccountDetailsDebitCardAccountV2FragmentDoc,
} from '../../../CardSummary/components/UserAndAccountDetails/UserAndAccountDetails.generated'
import { VelocityWindowSummaryVelocityControlTypeFragmentDoc } from './VelocityWindowSummary/VelocityWindowSummary.generated'

export type CorporateCardDetailsDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
  velocityControl: {
    __typename?: 'VelocityControlType'
    isPendingSubscriptionUpdate: boolean | null
    resetsAt: any
    velocityWindow: Types.VelocityWindowEnum
    amountLimit: { __typename?: 'Money'; value: string }
    availableAmount: { __typename?: 'Money'; value: string }
    totalSpentAmount: { __typename?: 'Money'; value: string }
  } | null
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
    firstname: string | null
    lastname: string | null
    icon: {
      __typename?: 'Icon'
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
    } | null
  }
}

export const CorporateCardDetailsDebitCardV2FragmentDoc = gql`
  fragment CorporateCardDetailsDebitCardV2Fragment on DebitCardV2 {
    status
    ...UseAvailableFundsCorporateCardDebitCardV2Fragment
    ...UseSpentFundsCorporateCardDebitCardV2Fragment
    velocityControl {
      amountLimit {
        value
      }
      isPendingSubscriptionUpdate @client
      ...VelocityWindowSummaryVelocityControlTypeFragment
    }
    customer {
      id
      entityUuid
      ...UserAndAccountDetailsCustomerFragment
    }
    debitCardAccount {
      ...UserAndAccountDetailsDebitCardAccountV2Fragment
    }
  }
  ${UseAvailableFundsCorporateCardDebitCardV2FragmentDoc}
  ${UseSpentFundsCorporateCardDebitCardV2FragmentDoc}
  ${VelocityWindowSummaryVelocityControlTypeFragmentDoc}
  ${UserAndAccountDetailsCustomerFragmentDoc}
  ${UserAndAccountDetailsDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CorporateCardDetailsDebitCardV2Fragment,
  undefined
>
