import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ExistingEmail = styled.p`
  ${BodyDefaultTypography}
  color: ${({ theme }) => theme.colors.RED_1000};
  margin: 0;
  word-break: break-all;
`

export const Warning = styled.p`
  ${BodyDefaultTypography}
  margin: 0 0 24px;
`
