import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  accordionTitle: 'Send via',
  accordionDetails:
    'Method to send your invoice, including recipients and message.',
  sendMeCopy: 'Send me a copy ',

  smsTitle: 'SMS',
  smsDetails:
    'Send your Invoice with payment link via mobile text message (SMS)',
})
