import { createTranslations } from '@npco/utils-translations'

import { TransactionsFiltersInput } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters.types'

export const translations = createTranslations({
  statusLabel: 'Status',
  approved: 'Approved',
  declined: 'Declined',
  refunded: 'Refunded',
} satisfies Record<'statusLabel' & TransactionsFiltersInput['statuses'], string>)
