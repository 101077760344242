import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled(Flex)`
  display: inline-flex;
  background: ${({ theme }) => theme.colors.GREEN_120};
  border-radius: 6px;
  font-family: 'Inter Zeller';
  font-style: normal;
  font-weight: normal;
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 15%);
  align-items: center;
  justify-content: center;
  min-width: 253px;
  min-height: 168px;
`

export const List = styled.ul`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 24px;
`
