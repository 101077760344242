import { BodySmall, H6 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const NotificationWrapper = styled.div`
  margin-bottom: 24px;
  padding: 12px 24px 12px 16px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.RED_80};
`

export const Description = styled(BodySmall)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const Title = styled(H6)`
  margin: 0;
  padding: 0 0 4px 0;
`
