import { useMemo, useState } from 'react'

import {
  CardsListLayoutContext,
  HeaderButtonsVisibilityType,
} from './CardsListLayoutContext'

interface CardsListLayoutProviderProps {
  isHeaderButtonsVisibleInitial?: HeaderButtonsVisibilityType
  children?: React.ReactNode
}

export const CardsListLayoutProvider = ({
  isHeaderButtonsVisibleInitial = {
    settings: false,
    addCard: false,
    activateCard: false,
  },
  children,
}: CardsListLayoutProviderProps) => {
  const [isHeaderButtonsVisible, setIsHeaderButtonsVisible] =
    useState<HeaderButtonsVisibilityType>(isHeaderButtonsVisibleInitial)

  const providerValue = useMemo(
    () => ({
      isHeaderButtonsVisible,
      setIsHeaderButtonsVisible,
    }),
    [isHeaderButtonsVisible]
  )

  return (
    <CardsListLayoutContext.Provider value={providerValue}>
      {children}
    </CardsListLayoutContext.Provider>
  )
}
