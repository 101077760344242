import { BREAKPOINT, Divider } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CheckoutLayout = styled.div`
  width: 100%;
  padding: 0 40px;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    display: grid;
    grid-template-columns: 340px auto 340px;
  }
`

export const CheckoutLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    grid-column: 2;
  }
`

export const CheckoutLayoutAside = styled.aside`
  width: 340px;
  margin: 0 auto;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    grid-column: 3;
    margin: 88px auto 0;
  }
`

export const CheckoutLayoutDivider = styled(Divider)`
  margin: 8px 0;
`
