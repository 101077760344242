import { gql } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'

import { formatDollars } from 'utils/common'
import { translate } from 'utils/translations'
import {
  List,
  ListActions,
  ListActionsButtonDestructive,
  ListItem,
  ListItemEllipsisableText,
} from 'components/List'
import { AccountStatusBadge } from 'components/StatusBadge/AccountStatusBadge/AccountStatusBadge'

import { AccountDetailsHeader } from './AccountDetailsHeader/AccountDetailsHeader'
import { AccountDetailsStatements } from './AccountDetailsStatements'
import { AccountDetailsViewValueDebitCardAccountV2Fragment } from './AccountDetailsViewValue.generated'
import { MaximumLimitListItems } from './components/MaximumLimit/MaximumLimitListItems'
import { useGoToCloseAccount } from './hooks/useGoToCloseAccount'
import { SavingsAccountDetails } from './SavingsAccountDetails/SavingsAccountDetails'
import { TransferInDetails } from './TransferInDetails/TransferInDetails'

type AccountDetailsViewValueProps = {
  account: AccountDetailsViewValueDebitCardAccountV2Fragment
  dailyMaximumLimit: string
  dailyMaximumLimitError: boolean
}

export const AccountDetailsViewValue = ({
  account,
  dailyMaximumLimit,
  dailyMaximumLimitError,
}: AccountDetailsViewValueProps) => {
  const { goToCloseAccount } = useGoToCloseAccount()

  const accountId = account.id
  const accountNickname = account.name

  const accountMaximumTransferLimit = formatDollars(100_000)

  const isSavingsAccount = account.type === DebitCardAccountType.SAVINGS
  const isDebitAccount = account.type === DebitCardAccountType.ZLR_DEBIT
  const isAccountClosed = account.status === DebitCardAccountStatus.CLOSED

  return (
    <>
      <List>
        {!isAccountClosed && <AccountDetailsHeader account={account} />}
        <ListItem
          isRightEllipsisable
          left={translate('page.settings.accountDetails.accountNickname')}
          right={<ListItemEllipsisableText text={accountNickname} />}
        />
        <ListItem
          left={translate('page.settings.accountDetails.accountStatus')}
          right={<AccountStatusBadge status={account.status} />}
        />
        {isSavingsAccount && <SavingsAccountDetails account={account} />}
        {isDebitAccount && (
          <>
            <TransferInDetails account={account} />
            <MaximumLimitListItems
              accountMaximumTransferLimit={accountMaximumTransferLimit}
              dailyMaximumLimit={dailyMaximumLimit}
              dailyMaximumLimitError={dailyMaximumLimitError}
            />
          </>
        )}

        <AccountDetailsStatements />
      </List>

      {!isAccountClosed && isDebitAccount && (
        <ListActions>
          <ListActionsButtonDestructive
            onClick={() => goToCloseAccount(accountId)}
          >
            {translate('page.settings.accountDetails.actionCloseAccount')}
          </ListActionsButtonDestructive>
        </ListActions>
      )}
    </>
  )
}

AccountDetailsViewValue.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountDetailsViewValueDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      name
      type
      status
      ...AccountDetailsHeaderDebitCardAccountV2Fragment
      ...SavingsAccountDetailsDebitCardAccountV2Fragment
      ...TransferInDetailsDebitCardAccountV2Fragment
    }

    ${AccountDetailsHeader.fragments.DebitCardAccountV2}
    ${SavingsAccountDetails.fragments.DebitCardAccountV2}
    ${TransferInDetails.fragments.DebitCardAccountV2}
  `,
}
