import { Box, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as EyeIcon } from 'assets/svg/eye.svg'

import { MaskedPanText } from './ViewDetails.styled'

interface MaskedPanContentProps {
  maskedPan: string
}

export const MaskedPanContent = ({ maskedPan }: MaskedPanContentProps) => {
  return (
    <>
      <SvgIcon>
        <EyeIcon />
      </SvgIcon>
      <Box pl="13px">
        <MaskedPanText>••• {maskedPan}</MaskedPanText>
      </Box>
    </>
  )
}
