import { AppState } from '@auth0/auth0-react'

import { AddThirdPartyBankAccountVariables } from 'types/gql-types/AddThirdPartyBankAccount'
import { RequestEmailChangeVariables } from 'types/gql-types/RequestEmailChange'
import { SelectDepositAccountVariables } from 'types/gql-types/SelectDepositAccount'

import { IdentityPhoneRegisterVariables } from './gql-types/IdentityPhoneRegister'

export enum AuthScope {
  OFFLINE = 'offline_access',
  SENSITIVE = 'offline_access access:sensitive',
}

export enum DepositsApiCallTypeEnum {
  SELECT = 'SelectDepositAccount',
  ADD = 'AddThirdPartyBankAccount',
}

export enum ProfileSettingsApiCallTypeEnum {
  RESEND_EMAIL = 'RequestEmailChange',
  CHANGE_PHONE = 'ChangePhoneNumber',
}

export type SelectDepositAccountAppState = {
  shouldCallApiAgain: boolean
  scope: AuthScope
  name: string
  apiCallType: DepositsApiCallTypeEnum.SELECT
  variables: SelectDepositAccountVariables
  isNewAccount?: boolean
}

export type AddDepositAccountAppState = {
  shouldCallApiAgain: boolean
  scope: AuthScope
  name: string
  apiCallType: DepositsApiCallTypeEnum.ADD
  variables: AddThirdPartyBankAccountVariables
  isNewAccount?: boolean
}

export type ResendEmailAppState = {
  shouldCallApiAgain: boolean
  scope: AuthScope
  name: string
  apiCallType: ProfileSettingsApiCallTypeEnum.RESEND_EMAIL
  variables: RequestEmailChangeVariables
}

export type ChangePhoneAppState = {
  shouldCallApiAgain: boolean
  scope: AuthScope
  name: string
  apiCallType: ProfileSettingsApiCallTypeEnum.CHANGE_PHONE
  variables: IdentityPhoneRegisterVariables
}

export type ProfileSettingsAppState = ResendEmailAppState | ChangePhoneAppState

export type DepositsAppState =
  | SelectDepositAccountAppState
  | AddDepositAccountAppState

export type RedirectAppState = AppState &
  (ProfileSettingsAppState | DepositsAppState)

export const isDepositsAppState = (
  state: RedirectAppState
): state is DepositsAppState => {
  return Object.values(DepositsApiCallTypeEnum).includes(
    state.apiCallType as DepositsApiCallTypeEnum
  )
}

export const isProfileSettingsAppState = (
  state: RedirectAppState
): state is ProfileSettingsAppState => {
  return Object.values(ProfileSettingsApiCallTypeEnum).includes(
    state.apiCallType as ProfileSettingsApiCallTypeEnum
  )
}
