import { FocusEventHandler, useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { NicknameInfoToolTip } from 'pages/Transfer/TransferFields/fields/Nickname/NicknameInfoToolTip'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import * as styled from '../field.styled'
import { nicknameFieldTranslations } from './Nickname.i18n'
import { useNicknameValidate } from './useNicknameValidate'

interface NicknameProps {
  fieldName?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const Nickname = ({ fieldName = 'nickname', onBlur }: NicknameProps) => {
  const t = useTranslations(nicknameFieldTranslations)
  const [, { error, touched }] = useField({
    name: fieldName,
    validate: useNicknameValidate(),
  })

  const renderToolTip = useCallback(() => {
    return <NicknameInfoToolTip label={t('toolTipLabel')} />
  }, [t])

  return (
    <styled.FieldWrapper>
      <InputAdaptiveFieldWrapper
        hasError={touched && !!error}
        renderRightControls={renderToolTip}
        name={fieldName}
        label={t('label')}
        size={TEXT_INPUT_SIZE.LARGE}
        onBlur={onBlur}
      />
    </styled.FieldWrapper>
  )
}
