import { useMemo } from 'react'
import { IconAvatar, IconAvatarProps } from '@npco/mp-ui-icon-avatar'
import { FlexProps } from '@npco/zeller-design-system'
import { v4 } from 'uuid'

import { AVATAR_BADGE_SIZE } from 'types/common'

import { AvatarWithTextRowWrapper, Text } from './AvatarWithTextRow.styled'

export interface AvatarWithTextRowProps {
  avatar: IconAvatarProps
  text: string
}

export const AvatarWithTextRow = ({
  avatar,
  avatar: { badgeSize = AVATAR_BADGE_SIZE.XSMALL },
  text,
  ...props
}: AvatarWithTextRowProps & FlexProps) => {
  const headerId = useMemo(() => v4(), [])

  return (
    <AvatarWithTextRowWrapper {...props} aria-labelledby={headerId}>
      <IconAvatar {...avatar} badgeSize={badgeSize} />
      <Text id={headerId}>{text}</Text>
    </AvatarWithTextRowWrapper>
  )
}

AvatarWithTextRow.Avatar = IconAvatar
AvatarWithTextRow.Text = Text
