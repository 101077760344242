import { useContext } from 'react'

import { ShowMoreContext } from './ShowMoreContext'

export const useShowMore = () => {
  const context = useContext(ShowMoreContext)

  if (context === null) {
    throw new Error('useShowMore must be used within ShowMoreContext')
  }

  return context
}
