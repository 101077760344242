import { useMemo } from 'react'
import { FlexProps } from '@npco/zeller-design-system'
import { v4 } from 'uuid'

import { Label, SummaryRow, Value } from './DefaultRow.styled'

export interface DefaultRowProps {
  label: string
  value: string
}

export const DefaultRow = ({
  label,
  value,
  ...props
}: DefaultRowProps & FlexProps) => {
  const headerId = useMemo(() => v4(), [])

  return (
    <SummaryRow {...props} aria-labelledby={headerId}>
      <Label id={headerId}>{label}</Label>
      <Value>{value}</Value>
    </SummaryRow>
  )
}

DefaultRow.Row = SummaryRow
DefaultRow.Label = Label
DefaultRow.Value = Value
