import { Box } from '@npco/zeller-design-system'

import { DrawerItem } from 'components/DrawerItem'
import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'

import { StyledMapSection } from '../TransactionContact.styled'

export const ContactContentSkeleton = () => {
  return (
    <div data-testid="contact-content-skeleton">
      <StyledMapSection>
        <Box
          height="180px"
          onContextMenu={(e) => {
            // NOTE: Disable right click for maps
            e.preventDefault()
          }}
        >
          <SkeletonLoaderBasic />
        </Box>
      </StyledMapSection>

      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
    </div>
  )
}
