import { AnnouncementModal, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CardInHandIllustration } from 'assets/svg/card-in-hand.svg'
import {
  CompleteCardInviteState,
  CompleteCardStage,
} from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'
import { useModalHasOpenTransition } from 'pages/GlobalModals/hooks/useModalHasOpenTransition'

import { useCompleteCardModal } from '../hooks/useCompleteCardModal'
import { useCompleteCardState } from '../hooks/useCompleteCardState'

export const CompleteCardInviteModal = () => {
  const isMobile = useIsMobileResolution()
  const { state } = useCompleteCardState<CompleteCardInviteState>()

  const { hasOpenTransition } = useModalHasOpenTransition()

  const { openAddCardModal, closeCompleteCardModal } = useCompleteCardModal()

  if (state === null) {
    return null
  }

  return (
    <AnnouncementModal
      isOpen={state.stage === CompleteCardStage.CompleteCardInviteStage}
      shouldContentFadeIn={hasOpenTransition}
      heading={translate('page.completeCardModal.completeCardInvite.heading', {
        cardholderFirstName: state.cardholderFirstName,
      })}
      description={translate(
        'page.completeCardModal.completeCardInvite.description',
        {
          cardholderFirstName: state.cardholderFirstName,
        }
      )}
      Illustration={
        <SvgIcon
          width={isMobile ? '140' : '180'}
          height={isMobile ? '140' : '180'}
        >
          <CardInHandIllustration />
        </SvgIcon>
      }
      primaryButtonLabel={translate('page.completeCardModal.doneButtonLabel')}
      secondaryButtonLabel={translate(
        'page.completeCardModal.addAnotherCardButtonLabel'
      )}
      onCancel={closeCompleteCardModal}
      onClickSecondary={openAddCardModal}
      onClickPrimary={closeCompleteCardModal}
    />
  )
}
