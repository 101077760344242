import { ModalWithButtons } from 'design-system/Components/Modal'
import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledAccountNumberAndBsbContainer = styled.div`
  display: block;
  @media only screen and (min-width: ${BREAKPOINT.MD}px) {
    display: flex;
  }
`

export const StyledAddAccountModal = styled(ModalWithButtons)`
  min-height: 22rem;
`
