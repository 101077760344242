import { useGetZellerInvoiceSettings } from '../../../../../../../Settings/hooks/useGetZellerInvoiceSettings'
import { availableEntityTagsMap } from '../InvoiceDeliveryAccordion.constants'

const { customer, invoiceNumber, totalPrice, schedule } = availableEntityTagsMap

const NEW_LINE_REGEX = /\r?\n|\r/g

export const DEFAULT_EMAIL_MESSAGE_MARKUP = `
<p>Hi ${customer.apiString}</p>
<p></p>
<p>Please find attached invoice ${invoiceNumber.apiString} for ${totalPrice.apiString} which is due on ${schedule.apiString}</p>
<p></p>
<p>Click the link below to view and pay your invoice.</p>
<p></p>
<p>If you have any questions, please let us know.</p>
<p></p>
<p>Thanks again</p>
<p></p>
<p>{{entityName}}</p>
`.replace(NEW_LINE_REGEX, '')

export const DEFAULT_EMAIL_SUBJECT_MARKUP = `Invoice ${invoiceNumber.apiString} from {{entityName}}`

export const ENTITY_NAME_REGEX = /{{entityName}}/g

export const useDefaultMarkupValue = () => {
  const { invoiceSettings } = useGetZellerInvoiceSettings()

  const entityName = invoiceSettings?.receipt?.name || ''

  const defaultSubject = DEFAULT_EMAIL_SUBJECT_MARKUP.replace(
    ENTITY_NAME_REGEX,
    entityName
  ).trim()

  const defaultMessage = DEFAULT_EMAIL_MESSAGE_MARKUP.replace(
    ENTITY_NAME_REGEX,
    entityName
  ).trim()

  return {
    defaultMessage,
    defaultSubject,
  }
}
