import { SidebarDefaultLocalState } from '../Sidebar/Sidebar.constants'
import { LocalStateSidebar } from '../Sidebar/Sidebar.types'

export type LocalStateApplication = {
  sidebar: LocalStateSidebar
}

export type CacheLocalStateApplication = {
  local: {
    application: LocalStateApplication
  }
}

export const ApplicationDefaultLocalState: LocalStateApplication = {
  sidebar: SidebarDefaultLocalState,
}
