import { Box } from '@npco/zeller-design-system'
import styled, { css, keyframes } from 'styled-components'

import { ImageContainer } from 'components/ImageContainer/ImageContainer'

const animation = keyframes`
    0% {opacity: 0;}
    20% {opacity: 1;}
    33% {opacity: 1;}
    53% {opacity: 0;}
    100% {opacity: 0;}
`

export const IllustrationWrapper = styled(Box)`
  min-width: 0;
  position: relative;
`

export const AnimationWrapper = styled(ImageContainer)<{
  animationDelay?: number
  duration: number
  isLastImageContainer?: boolean
}>`
  ${({ isLastImageContainer }) =>
    !isLastImageContainer &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `}

  animation-name: ${animation};
  animation-iteration-count: infinite;
  animation-duration: ${({ duration }) => `${duration}ms`};

  ${({ animationDelay }) =>
    animationDelay &&
    css`
      animation-delay: ${animationDelay}ms;
    `}
`
