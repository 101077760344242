import { createContext, type ReactNode, useContext, useMemo } from 'react'

export type FeatureFlagsValuesAny = Record<string, unknown>

export type FeatureFlagsEnvironment<TValues extends FeatureFlagsValuesAny> = {
  FeatureFlagsProvider: (props: {
    values?: Partial<TValues>
    children?: ReactNode
  }) => JSX.Element
  useFeatureFlags: () => TValues
}

export const createFeatureFlagsEnvironment = <
  TValues extends FeatureFlagsValuesAny
>(
  defaultValues: TValues
): FeatureFlagsEnvironment<TValues> => {
  const FeatureFlagsContext = createContext<TValues>(defaultValues)

  const FeatureFlagsProvider: FeatureFlagsEnvironment<TValues>['FeatureFlagsProvider'] =
    ({ values, children }) => {
      const mergedValues = useMemo(
        () => ({ ...defaultValues, ...values }),
        [values]
      )
      return (
        <FeatureFlagsContext.Provider value={mergedValues}>
          {children}
        </FeatureFlagsContext.Provider>
      )
    }

  const useFeatureFlags: FeatureFlagsEnvironment<TValues>['useFeatureFlags'] =
    () => useContext(FeatureFlagsContext)

  return {
    FeatureFlagsProvider,
    useFeatureFlags,
  }
}
