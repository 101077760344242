import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetBillersByCodeQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  code: Types.Scalars['String']['input']
}>

export type GetBillersByCodeQueryResponse = {
  __typename?: 'Query'
  searchBpayBillers: {
    __typename?: 'BpayBillerSearchResponse'
    bpayBillers: Array<{
      __typename?: 'BpayBiller'
      billerName: string
      billerCode: string
    }>
  }
}

export const GetBillersByCode = gql`
  query GetBillersByCode($entityUuid: ID!, $code: String!) {
    searchBpayBillers(entityUuid: $entityUuid, billerCode: $code) {
      bpayBillers {
        billerName
        billerCode
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetBillersByCodeQueryResponse,
  GetBillersByCodeQueryVariables
>

/**
 * __useGetBillersByCodeQuery__
 *
 * To run a query within a React component, call `useGetBillersByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillersByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillersByCodeQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetBillersByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillersByCodeQueryResponse,
    GetBillersByCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBillersByCodeQueryResponse,
    GetBillersByCodeQueryVariables
  >(GetBillersByCode, options)
}
export function useGetBillersByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillersByCodeQueryResponse,
    GetBillersByCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBillersByCodeQueryResponse,
    GetBillersByCodeQueryVariables
  >(GetBillersByCode, options)
}
export type GetBillersByCodeQueryHookResult = ReturnType<
  typeof useGetBillersByCodeQuery
>
export type GetBillersByCodeLazyQueryHookResult = ReturnType<
  typeof useGetBillersByCodeLazyQuery
>
export type GetBillersByCodeQueryResult = Apollo.QueryResult<
  GetBillersByCodeQueryResponse,
  GetBillersByCodeQueryVariables
>
