import { Transaction, TransactionInList } from 'types/transactions'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { TransactionContactUnlinkedTransaction } from 'components/TransactionContactUnlinkedTransaction'

import { LinkedTransaction } from './components/LinkedTransaction/LinkedTransaction'
import { useTransactionContact } from './hooks/useTransactionContact'

interface TransactionContactProps {
  onLink?: () => void
  onUnlink?: (unlinkedCardHolderUuid: string) => void
  transaction: TransactionInList
}

export const TransactionContact = ({
  onLink,
  onUnlink,
  transaction,
}: TransactionContactProps) => {
  const contactUuid = (transaction as Transaction)?.contact?.id ?? null
  const cardholderUuid = (transaction as Transaction).cardholderUuid ?? null

  const {
    contact,
    getContact,
    handleLinkContact,
    handleOnContactCreateAndLink,
    handleUnlinkContact,
    hasGetContactError,
    hasLinkContactError,
    hasUnlinkContactError,
    isLinkingContactWithCardholder,
    isLoadingContact,
    isUnlinkingContactWithCardholder,
    linkedContactUuid,
  } = useTransactionContact({
    cardholderUuid,
    contactUuid,
    onLink,
    onUnlink,
  })

  // NOTE: technically shouldn't happen on production but there are cases on
  // other environments where this can occur so adding fallback
  if (cardholderUuid) {
    if (!linkedContactUuid) {
      return (
        <TransactionContactUnlinkedTransaction
          cardholderOrSenderUuid={cardholderUuid}
          isLoading={isLinkingContactWithCardholder}
          hasLinkContactError={hasLinkContactError}
          onCreateContactAndLink={handleOnContactCreateAndLink}
          onLinkContact={handleLinkContact}
        />
      )
    }

    return (
      <LinkedTransaction
        cardholderUuid={cardholderUuid}
        contact={contact}
        contactUuid={linkedContactUuid}
        getContact={getContact}
        hasGetContactError={hasGetContactError}
        hasUnlinkContactError={hasUnlinkContactError}
        isLoading={isLoadingContact}
        onUnlinkContact={handleUnlinkContact}
        isUnlinkingContact={isUnlinkingContactWithCardholder}
      />
    )
  }

  return <GenericErrorPage data-testid="missing-cardholder-uuid-error" />
}
