import { ActiveConnection } from '@npco/mp-gql-types'

import { GetDeviceInformationQueryResponse } from '../../graphql/getDeviceInformation.generated'
import { CellularDetails } from './CellularDetails'
import { EthernetDetails } from './EthernetDetails'
import { WifiDetails } from './WifiDetails'

type ActiveNetworkDetailsProps = {
  isLoading: boolean
  networkDetails?: GetDeviceInformationQueryResponse['getDeviceInformation']['network']
}
export const ActiveNetworkDetails = ({
  isLoading,
  networkDetails,
}: ActiveNetworkDetailsProps) => {
  switch (networkDetails?.activeConnection) {
    case ActiveConnection.CELLULAR:
      return (
        <CellularDetails
          isLoading={isLoading}
          networkDetails={networkDetails}
        />
      )
    case ActiveConnection.ETHERNET:
      return (
        <EthernetDetails
          isLoading={isLoading}
          networkDetails={networkDetails}
        />
      )
    default:
      return (
        <WifiDetails isLoading={isLoading} networkDetails={networkDetails} />
      )
  }
}
