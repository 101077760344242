import { ApolloCache, gql } from '@apollo/client'

import { TestUpdateContactFragment as TestUpdateContactFragmentType } from 'types/gql-types/TestUpdateContactFragment'

export const TestUpdateContactFragment = gql`
  fragment TestUpdateContactFragment on Contact {
    id
    isSelf
  }
`

const initialContact: TestUpdateContactFragmentType & {
  __typename: 'Contact'
} = { __typename: 'Contact', id: 'placeholder', isSelf: null }

type CreateContactCacheRecordOptions = {
  id: string
  overwritingData?: Partial<typeof initialContact>
}

export const createContactCacheRecord = (
  cache: ApolloCache<any>,
  { id, overwritingData }: CreateContactCacheRecordOptions
) => ({
  [cache.identify({ id, __typename: 'Contact' }) as string]: {
    ...initialContact,
    ...overwritingData,
    id,
  },
})

type GetCachedRecordOptions = { id: string }

export const getCachedContactRecord = (
  cache: ApolloCache<any>,
  { id }: GetCachedRecordOptions
) => {
  return cache.readFragment<TestUpdateContactFragmentType>({
    fragment: TestUpdateContactFragment,
    id: cache.identify({ __typename: 'Contact', id }),
  })
}
