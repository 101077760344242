import {
  Box,
  Flex,
  SkeletonAvatar,
  SkeletonBasic,
} from '@npco/zeller-design-system'

export const AccountSkeleton = () => {
  return (
    <Flex alignItems="center" data-testid="account-skeleton">
      <Box width="40px" mr="16px">
        <SkeletonAvatar height={40} width={40} />
      </Box>
      <Box width={1} maxWidth={200}>
        <SkeletonBasic height={10} isRounded />
      </Box>
    </Flex>
  )
}
