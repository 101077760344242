import { Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DetailsListItem } from 'components/DetailsListItem'

interface Props {
  accountName?: string | null
  reference?: string | null
}
export const InternalTransferDetails = ({ accountName, reference }: Props) => {
  return (
    <Box mt="24px">
      <DetailsListItem
        label={translate('page.transactionDetails.account')}
        value={accountName}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.reference')}
        value={reference || '-'}
        isCapitalised={false}
      />
    </Box>
  )
}
