import { FileQueueItem } from '../File.types'
import { FileItem } from '../FileItem/FileItem'

interface FileListProps {
  fileQueueItems: FileQueueItem[]
  removeFileFromQueue: (fileId: string) => void
  uploadedItems: FileQueueItem[]
}

export const FileListGroup = ({
  fileQueueItems,
  removeFileFromQueue,
  uploadedItems,
}: FileListProps) => {
  return (
    <>
      {uploadedItems.map((fileQueueItem) => {
        return (
          <FileItem
            key={fileQueueItem.id}
            id={fileQueueItem.id}
            title={fileQueueItem.file.name}
            onDelete={removeFileFromQueue}
            isUploaded
          />
        )
      })}

      {fileQueueItems.map((fileQueueItem) => {
        return (
          <FileItem
            key={fileQueueItem.id}
            id={fileQueueItem.id}
            title={fileQueueItem.file.name}
            onDelete={removeFileFromQueue}
          />
        )
      })}
    </>
  )
}
