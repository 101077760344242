import { Condition } from '../../../../../components/Condition/Condition'
import { useInternalTransferState } from '../../internal-transfer-routing'
import { CompleteStage } from './CompleteStage/CompleteStage'
import { SummaryStage } from './SummaryStage/SummaryStage'

export const InternalTransferModal = () => {
  const { state } = useInternalTransferState()

  if (state === null) {
    return null
  }

  return (
    <>
      <Condition shouldShow={state.stage === 'summary'}>
        <SummaryStage transferDetails={state.transferDetails} />
      </Condition>
      <Condition shouldShow={state.stage === 'complete'}>
        <CompleteStage
          transferDetails={state.transferDetails}
          transactionTimestamp={state.transactionTimestamp}
        />
      </Condition>
    </>
  )
}
