import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SideImage = styled.img`
  width: 100%;
  padding-top: 16px;
  object-position: center;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    height: 100%;
    position: absolute;
    object-fit: contain;
    padding-top: unset;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    object-fit: cover;
  }

  @media screen and (min-width: ${BREAKPOINT.XL}px) {
    object-fit: contain;
  }
`
