import { gql } from '@apollo/client'

export const LinkContactWithCardholder = gql`
  mutation LinkContactWithCardholder(
    $entityUuid: ID!
    $cardholderUuid: ID!
    $contactUuid: ID!
  ) {
    linkContactWithCardholder(
      entityUuid: $entityUuid
      cardholderUuid: $cardholderUuid
      contactUuid: $contactUuid
    )
  }
`

export const SendReceiptMutation = gql`
  mutation SendReceipt($input: SendReceiptInput!) {
    sendReceipt(input: $input)
  }
`

export const UpdateTransactionNotesMutation = gql`
  mutation UpdateTransactionNotes(
    $entityUuid: ID!
    $notes: String!
    $transactionUuid: ID!
  ) {
    updateTransactionNotes(
      entityUuid: $entityUuid
      notes: $notes
      transactionUuid: $transactionUuid
    )
  }
`

export const DeleteTransactionNotesMutation = gql`
  mutation DeleteTransactionNotes($entityUuid: ID!, $transactionUuid: ID!) {
    deleteTransactionNotes(
      entityUuid: $entityUuid
      transactionUuid: $transactionUuid
    )
  }
`

export const UnlinkContactWithCardholder = gql`
  mutation UnlinkContactWithCardholder(
    $entityUuid: ID!
    $cardholderUuid: ID!
    $contactUuid: ID!
  ) {
    unlinkContactWithCardholder(
      entityUuid: $entityUuid
      cardholderUuid: $cardholderUuid
      contactUuid: $contactUuid
    )
  }
`
export const RefundTransaction = gql`
  mutation RefundTransaction(
    $entityUuid: ID!
    $input: RefundTransactionInput!
  ) {
    refundTransaction(entityUuid: $entityUuid, input: $input) {
      approvalCode
      id
      responseCode
      rrn
    }
  }
`
