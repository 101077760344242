import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { ONBOARDING_SHOP_ROUTES } from '@npco/mp-feature-onboarding-shop'
import {
  rvOnboardingMetadata,
  rvPathBeforeLoggingIn,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ONBOARDING_ROUTES } from 'const/routes'

export const PrivateRoutesGuard = () => {
  const onboardingMetadata = useReactiveVar(rvOnboardingMetadata)
  const location = useLocation()

  useEffect(() => {
    rvPathBeforeLoggingIn(location.pathname)
  }, [location.pathname])

  if (
    onboardingMetadata.isInProgress &&
    onboardingMetadata.lastRoute &&
    !Object.values(ONBOARDING_ROUTES).includes(location.pathname) &&
    !Object.values(ONBOARDING_SHOP_ROUTES).includes(location.pathname)
  ) {
    return <Navigate to={onboardingMetadata.lastRoute} replace />
  }

  return <Outlet />
}
