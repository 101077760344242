import { useCallback } from 'react'
import shortUUID from 'short-uuid'

import { UUID_REGEXP } from '../../constants'

export const useConvertUuidToShortUuid = () => {
  const translator = shortUUID()

  const convertUuidToShortUuid = useCallback(
    (uuid: string) => {
      if (!UUID_REGEXP.test(uuid)) {
        return ''
      }

      return translator.fromUUID(uuid)
    },
    [translator]
  )

  return convertUuidToShortUuid
}
