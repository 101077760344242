import { useCallback, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { Box, Flex } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { ButtonExportTransactions } from 'components/Buttons/ButtonExportTransactions/ButtonExportTransactions'
import { Filters } from 'components/Filters'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'

import { useTransactionsContext } from '../../TransactionsContext/TransactionsContext'
import { translations } from './TransactionsSecondaryHeaderRow.i18n'
import * as styled from './TransactionsSecondaryHeaderRow.styled'

const deps = {
  Filters,
  useLocation,
  useTransactionsContext,
  useTranslations,
}
export { deps as TransactionsSecondaryHeaderRowDeps }

export const TransactionsSecondaryHeaderRow = () => {
  const { isFiltersVisible, isDateRangeAppropriate, openStatementModal } =
    deps.useTransactionsContext()

  const [isWarningVisible, setIsWarningVisible] = useState(false)

  const { pathname } = deps.useLocation()

  const t = deps.useTranslations(translations)

  useEffect(() => {
    if (isDateRangeAppropriate) {
      setIsWarningVisible(false)
    }
  }, [isDateRangeAppropriate])

  const handleOnExportTransactions = useCallback(() => {
    if (!isDateRangeAppropriate) {
      setIsWarningVisible(true)
    } else {
      openStatementModal()
    }
  }, [isDateRangeAppropriate, openStatementModal, setIsWarningVisible])

  const isOnSplitPaymentPath = matchPath(
    ROOT.ORGS.ORG().TRANSACTIONS.SPLITPAYMENT.SPLIT_PAYMENT_ID().path,
    pathname
  )

  const widgetKey = isOnSplitPaymentPath
    ? TIME_FILTER_ENABLED_COMPONENTS.TRANSACTIONS_SPLIT_PAYMENT
    : TIME_FILTER_ENABLED_COMPONENTS.TRANSACTIONS

  return (
    <FiltersHeaderRow isOpen={isFiltersVisible}>
      <Flex flexDirection="column">
        {isWarningVisible && (
          <Box mb="1rem">
            <styled.StyledWarningNotification>
              {t('transactionsWarning')}
            </styled.StyledWarningNotification>
          </Box>
        )}

        <deps.Filters isDateTime widgetKey={widgetKey} />

        <Box mt="16px">
          <ButtonExportTransactions
            label={t('exportTransactions')}
            onClick={handleOnExportTransactions}
          />
        </Box>
      </Flex>
    </FiltersHeaderRow>
  )
}
