import { ApolloError, gql } from '@apollo/client'
import { KycStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Divider,
  Heading,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { AssignedCardsList } from 'components/AssignedCardsList'

import { AssignedCardsDescription } from './AssignedCardsDescription'
import { AssignedCardsSectionDebitCardV2Fragment } from './AssignedCardsSection.generated'
import { translations } from './AssignedCardsSection.i18n'

export type AssignedCardsSectionProps = {
  isAdmin: boolean
  isEditForm: boolean
  kycStatus?: KycStatus
  isCardsLoading: boolean
  cards: AssignedCardsSectionDebitCardV2Fragment[]
  cardError?: ApolloError
  cardRefetch: () => void
}
export const AssignedCardsSection = ({
  isAdmin,
  isEditForm,
  kycStatus,
  isCardsLoading,
  cards,
  cardError: error,
  cardRefetch: refetch,
}: AssignedCardsSectionProps) => {
  const t = useTranslations(translations)
  const hasNoCards = !isCardsLoading && cards.length === 0
  const isVerified = kycStatus === KycStatus.VERIFIED
  const shouldShowInfobox = !isVerified && !isAdmin

  return (
    <>
      <Divider />
      <Heading.H3 mb="16px">{t('title')}</Heading.H3>
      {shouldShowInfobox && (
        <InfoBox
          variant={INFOBOX_VARIANT.NEGATIVE}
          size={INFOBOX_SIZE.DEFAULT}
          priority={INFOBOX_PRIORITY.MEDIUM}
        >
          <InfoBox.Message>{t('managerInfoText')}</InfoBox.Message>
        </InfoBox>
      )}
      <AssignedCardsDescription
        isAdmin={isAdmin}
        isEditForm={isEditForm}
        isVerified={isVerified}
        hasNoCards={hasNoCards}
      />
      {isEditForm && isVerified && (
        <AssignedCardsList
          cards={cards}
          isLoading={isCardsLoading}
          refetch={refetch}
          error={error}
        />
      )}
    </>
  )
}

AssignedCardsSection.fragments = {
  DebitCardV2: gql`
    fragment AssignedCardsSectionDebitCardV2Fragment on DebitCardV2 {
      ...AssignedCardsListDebitCardV2Fragment
    }
    ${AssignedCardsList.fragments.DebitCardV2}
  `,
}
