import { BREADCRUMB } from 'components/Breadcrumbs/breadcrumbParts'
import {
  BreadcrumbPath,
  BreadcrumbVariableType,
} from 'components/Breadcrumbs/Breadcrumbs.types'

const getBreadcrumbsParts = (breadcrumb: BreadcrumbPath): BreadcrumbPath[] => {
  if (breadcrumb.parent) {
    return [breadcrumb, ...getBreadcrumbsParts(BREADCRUMB[breadcrumb.parent])]
  }
  return [breadcrumb]
}

export const getAllBreadcrumbPartsForPath = (
  isCurrentPathMatched: (route: string) => boolean
): BreadcrumbPath[] => {
  const startPoint = Object.values(BREADCRUMB).find((part) => {
    if (part.applyToPaths) {
      return part.applyToPaths.some((path) => isCurrentPathMatched(path))
    }
    return isCurrentPathMatched(part.path || '')
  })
  if (!startPoint) {
    return []
  }

  return getBreadcrumbsParts(startPoint).reverse()
}

export const fillRoutesWithVariables = (
  routes: BreadcrumbPath[],
  variables: BreadcrumbVariableType
) => {
  const variableNames = Object.keys(variables)
  return routes.map((route) => {
    if (variableNames.includes(route.name)) {
      return { ...route, name: variables[route.name] ?? '' }
    }
    return route
  })
}
