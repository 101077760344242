import styled, { css } from 'styled-components/macro'

export const StyledAccountsList = styled.section`
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 3rem;
  grid-gap: 0.25rem;
`

export const StyledAccountContent = styled.div`
  flex: 1;
  text-align: left;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledRadioButton = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  background: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.GREY_60 : theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};

  &::before {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.BLUE_1000};
    z-index: 1;
    transition: 0.3s cubic-bezier(0.27, 1.55, 0.4, 1.17);
    transition-property: opacity, transform;
    opacity: 0;
    transform: scale(0);
  }
`

export const StyledAccountName = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.BLACK};
  margin: 0;
`

export const StyledAdditionalAccountName = styled.span`
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledAccountBalance = styled.p`
  margin: 0;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.GREY_550};
`

interface AccountType {
  isSelected: boolean
}

export const StyledAccount = styled.div.attrs(
  ({ isSelected }: AccountType) => ({
    className: isSelected && 'selected',
  })
)`
  display: flex;
  align-items: stretch;
  width: 100%;
  position: relative;
  padding: 0.5rem;
  background: transparent;
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.WHITE};
  transition: background 0.4s ease-out;

  &:hover,
  &:focus {
    outline: 0;
    background: ${({ theme }) => theme.colors.BLUE_80};
  }

  &.selected {
    background: ${({ theme }) => theme.colors.BLUE_80};

    ${StyledRadioButton} {
      border-color: ${({ theme }) => theme.colors.BLUE_1000};

      &::before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;

      ${StyledAccountName}, ${StyledAccountBalance} {
        color: ${({ theme }) => theme.colors.GREY_250};
      }
    `}
`

export const StyledAccountInSelect = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  position: relative;
  background: transparent;
  border-radius: 7px;
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 0;
`

export const StyledTooltipContent = styled.div`
  max-width: 11.5rem;
  text-align: center;
`
