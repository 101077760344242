import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ContactsChartCashFlowContactNetAmountFragmentDoc } from '../../../ContactsChart/ContactsChart.generated'
import { ContactsTableCashFlowContactNetAmountFragmentDoc } from '../../../ContactsTable/ContactsTable.generated'

const defaultOptions = {} as const
export type GetCashFlowContactsNetAmountsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  accountUuid: Types.InputMaybe<Types.Scalars['String']['input']>
  date: Types.Scalars['AWSDate']['input']
  reportType: Types.CashFlowReportType
  timeZone: Types.InputMaybe<Types.Scalars['String']['input']>
  transactionDirection: Types.TransactionDirection
}>

export type GetCashFlowContactsNetAmountsQueryResponse = {
  __typename?: 'Query'
  getCashFlowContactsNetAmounts: {
    __typename?: 'CashFlowContactsNetAmounts'
    contactNetAmounts: Array<{
      __typename: 'CashFlowContactNetAmount'
      noOfTransaction: number
      change: number | null
      average: { __typename?: 'Money'; currency: string; value: string }
      total: { __typename?: 'Money'; currency: string; value: string }
      contact: {
        __typename: 'Contact'
        id: string
        businessName: string | null
        contactType: Types.ContactType
        firstName: string | null
        lastName: string | null
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          image: string | null
          images: Array<{
            __typename?: 'Image'
            url: string
            size: Types.ImageSize
          }> | null
        } | null
      } | null
    }> | null
  }
}

export const GetCashFlowContactsNetAmounts = gql`
  query GetCashFlowContactsNetAmounts(
    $entityUuid: ID!
    $accountUuid: String
    $date: AWSDate!
    $reportType: CashFlowReportType!
    $timeZone: String
    $transactionDirection: TransactionDirection!
  ) {
    getCashFlowContactsNetAmounts(
      entityUuid: $entityUuid
      accountUuid: $accountUuid
      date: $date
      reportType: $reportType
      timeZone: $timeZone
      transactionDirection: $transactionDirection
    ) {
      contactNetAmounts {
        ...ContactsTableCashFlowContactNetAmountFragment
        ...ContactsChartCashFlowContactNetAmountFragment
      }
    }
  }
  ${ContactsTableCashFlowContactNetAmountFragmentDoc}
  ${ContactsChartCashFlowContactNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  GetCashFlowContactsNetAmountsQueryResponse,
  GetCashFlowContactsNetAmountsQueryVariables
>

/**
 * __useGetCashFlowContactsNetAmountsQuery__
 *
 * To run a query within a React component, call `useGetCashFlowContactsNetAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashFlowContactsNetAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashFlowContactsNetAmountsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      accountUuid: // value for 'accountUuid'
 *      date: // value for 'date'
 *      reportType: // value for 'reportType'
 *      timeZone: // value for 'timeZone'
 *      transactionDirection: // value for 'transactionDirection'
 *   },
 * });
 */
export function useGetCashFlowContactsNetAmountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCashFlowContactsNetAmountsQueryResponse,
    GetCashFlowContactsNetAmountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCashFlowContactsNetAmountsQueryResponse,
    GetCashFlowContactsNetAmountsQueryVariables
  >(GetCashFlowContactsNetAmounts, options)
}
export function useGetCashFlowContactsNetAmountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCashFlowContactsNetAmountsQueryResponse,
    GetCashFlowContactsNetAmountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCashFlowContactsNetAmountsQueryResponse,
    GetCashFlowContactsNetAmountsQueryVariables
  >(GetCashFlowContactsNetAmounts, options)
}
export type GetCashFlowContactsNetAmountsQueryHookResult = ReturnType<
  typeof useGetCashFlowContactsNetAmountsQuery
>
export type GetCashFlowContactsNetAmountsLazyQueryHookResult = ReturnType<
  typeof useGetCashFlowContactsNetAmountsLazyQuery
>
export type GetCashFlowContactsNetAmountsQueryResult = Apollo.QueryResult<
  GetCashFlowContactsNetAmountsQueryResponse,
  GetCashFlowContactsNetAmountsQueryVariables
>
