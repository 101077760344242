import { useMutation } from '@apollo/client'
import { RequestEmailChange } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/profileSettings'

import {
  RequestEmailChange as RequestEmailChangePayload,
  RequestEmailChangeVariables,
} from 'types/gql-types/RequestEmailChange'

export const useRequestEmailChange = () => {
  const [requestEmailChange, { loading: isRequestingEmailChange }] =
    useMutation<RequestEmailChangePayload, RequestEmailChangeVariables>(
      RequestEmailChange
    )

  return {
    requestEmailChange: (newEmail: string) =>
      requestEmailChange({
        variables: {
          input: { newEmail },
        },
      }),
    isRequestingEmailChange,
  }
}
