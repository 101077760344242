import { useCallback } from 'react'
import {
  Box,
  ButtonFill,
  ButtonGhost,
  Flex,
  Heading,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { Description } from '../XeroPaymentServicesConnection.styled'
import { BusinessDetailForm } from './forms/BusinessDetailForm'
import { XeroPaymentServicesConnetionFormValues } from './XeroPaymentServicesConnectionSetup.types'

export const StepThree = () => {
  const { setFieldValue } =
    useFormikContext<XeroPaymentServicesConnetionFormValues>()

  const handleCancel = useCallback(() => {
    setFieldValue('step', 1)
  }, [setFieldValue])

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex width="100%" flexDirection="column">
        <Box mb="24px">
          <Heading.H3>
            {translate(
              'page.settings.connections.xeroPaymentServices.setBusinessDetails.title'
            )}
          </Heading.H3>
        </Box>
        <Box mb="40px">
          <Description>
            {translate(
              'page.settings.connections.xeroPaymentServices.setBusinessDetails.description'
            )}
          </Description>
        </Box>

        <BusinessDetailForm />
      </Flex>

      <Flex justifyContent="space-between">
        <ButtonFill fullWidth type="submit">
          {translate('page.settings.connections.xeroBankFeeds.cta.save')}
        </ButtonFill>
        <ButtonGhost fullWidth onClick={handleCancel}>
          {translate('page.settings.connections.xeroBankFeeds.cta.cancel')}
        </ButtonGhost>
      </Flex>
    </Flex>
  )
}
