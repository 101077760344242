import { gql } from '@apollo/client'
import { DebitCardFormat, DebitCardProductType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { cardDisplayTranslations } from '../../CardDisplay.i18n'
import { UseCardFormatDisplayDebitCardV2Fragment } from './useCardFormatDisplay.generated'

type UseCardFormatDisplayProps = {
  card: UseCardFormatDisplayDebitCardV2Fragment
}

export const useCardFormatDisplay = ({ card }: UseCardFormatDisplayProps) => {
  const t = useTranslations(cardDisplayTranslations)

  const productTypeDisplay =
    card.productType === DebitCardProductType.EXPENSE
      ? t('corporate')
      : t('debit')

  const cardFormatDisplay =
    (card.format || DebitCardFormat.PHYSICAL) === DebitCardFormat.PHYSICAL
      ? t('physical')
      : t('virtual')

  return { productTypeDisplay, cardFormatDisplay }
}

useCardFormatDisplay.fragments = {
  DebitCardV2: gql`
    fragment UseCardFormatDisplayDebitCardV2Fragment on DebitCardV2 {
      format
      productType
    }
  `,
}
