import { BodyDefaultTypography, BodySmall } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { ReactComponent as Padlock } from 'assets/svg/padlock.svg'
import { CardSize } from 'types/cards'

const largeCardNumber = css`
  bottom: 10.38251366%;
  left: 7.24137931%;
  ${BodyDefaultTypography}
`

const smallCardNumber = css`
  bottom: 2px;
  left: 3px;
  font-size: 0.625rem;
  line-height: 1.2;
  font-weight: 600;
`

const CardNumberSizes = {
  [CardSize.SMALL]: smallCardNumber,
  [CardSize.LARGE]: largeCardNumber,
}

export const StyledZellerCard = styled.div<{ isLocked: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  transition: opacity 0.3s ease-out;
  opacity: ${({ isLocked }) => (isLocked ? 0.5 : 1)};
`

type CardNumberProps = {
  color: string
  cardSize: string
}

export const StyledCardNumber = styled.div<CardNumberProps>`
  position: absolute;
  margin: 0;
  color: ${({ color }) => color};
  ${({ cardSize }) => CardNumberSizes[cardSize as CardSize]}
`

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const StyledPadlock = styled(Padlock)`
  height: 0.75rem;

  path {
    fill: ${({ theme }) => theme.colors.WHITE};
  }
`

export const StyledLockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background: ${({ theme }) => theme.colors.BLACK_900};
  border-radius: 999rem;

  &.${CardSize.LARGE} {
    width: 2.5rem;
    height: 2.5rem;

    ${StyledPadlock} {
      height: 1rem;
    }
  }
`

export const StyledTooltipTitle = styled(BodySmall)`
  margin: 0.25rem 0;
`
