import { DATE_MMM_FORMAT } from 'const/date'
import dayjs from 'utils/dayjs'

export const getFormattedDateRange = ({
  from,
  to,
  label,
  isSelectionMoreThenTwoMonths,
}: {
  from?: Date
  to?: Date
  label: number
  isSelectionMoreThenTwoMonths: boolean
}): string => {
  const dayjsLabel = dayjs(label)

  if (!isSelectionMoreThenTwoMonths) {
    return dayjsLabel.format(DATE_MMM_FORMAT)
  }

  const dayjsLabelStart = dayjsLabel.startOf('month')
  const dayjsLabelEnd = dayjsLabel.endOf('month')

  if (!from || !to) {
    return `${dayjsLabelStart.format(DATE_MMM_FORMAT)} - ${dayjsLabelEnd.format(
      DATE_MMM_FORMAT
    )}`
  }

  const dayjsFrom = dayjs(from)
  const dayjsTo = dayjs(to)

  const isStartMonth = dayjsFrom.isSame(dayjsLabel, 'month')
  const isEndMonth = dayjsTo.isSame(dayjsLabel, 'month')

  const startDate = isStartMonth ? dayjsFrom : dayjsLabelStart
  const endDate = isEndMonth ? dayjsTo : dayjsLabelEnd

  return `${startDate.format(DATE_MMM_FORMAT)} - ${endDate.format(
    DATE_MMM_FORMAT
  )}`
}
