import { useCallback, useMemo, useState } from 'react'
import { rvSelectedTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'

import { TransactionInList } from 'types/transactions'

import {
  filterNonNullElements,
  filterUnique,
  sortDescendingByTimestamp,
  sortDescendingByUpdate,
} from './useTransactionsList.utils'

export const useTransactionsWithUpdates = () => {
  const transactionList$ = useMemo(() => new Subject<TransactionInList[]>(), [])
  const [, setAllTransactions] = useState<TransactionInList[]>([])
  const transactions$ = useMemo(
    () =>
      transactionList$.pipe(
        map(filterNonNullElements),
        map(sortDescendingByUpdate),
        map(filterUnique),
        map(sortDescendingByTimestamp)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const setTransactions = useCallback(
    (transactions: (TransactionInList | null)[]) => {
      setAllTransactions(() => {
        transactionList$.next([
          ...(transactions as unknown as TransactionInList[]),
        ])
        return [...(transactions as unknown as TransactionInList[])]
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const addTransactionsPage = useCallback(
    (transactions: (TransactionInList | null)[]) => {
      const transactionsWithoutNullElements = transactions.filter(
        Boolean
      ) as TransactionInList[]

      setAllTransactions((prev) => {
        transactionList$.next([...prev, ...transactionsWithoutNullElements])
        return [...prev, ...transactionsWithoutNullElements]
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const addNewTransaction = useCallback(
    (transaction: TransactionInList) => {
      if (rvSelectedTransaction()?.id === transaction.id) {
        rvSelectedTransaction({
          ...(rvSelectedTransaction() as TransactionInList),
          subscriptionUpdatedAt: new Date().toISOString(),
        })
      }
      setAllTransactions((prev) => {
        transactionList$.next([
          {
            ...transaction,
            subscriptionUpdatedAt: new Date().toISOString(),
          },
          ...prev,
        ])

        return [
          {
            ...transaction,
            subscriptionUpdatedAt: new Date().toISOString(),
          },
          ...prev,
        ]
      })
    },

    // Do not change callback each time list changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    setTransactions,
    addTransactionsPage,
    transactions$,
    updateObserver: addNewTransaction,
  }
}
