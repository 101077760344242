import { useCallback, useMemo } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CardFormatOption,
  CardTypeOption,
  CreateSelectCardFormatState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

import { SelectCardFormatFormValues } from '../SelectCardFormatForm.types'

export const useSelectCardFormatForm = () => {
  const { goToAddCardStage } = useGoToAddCardStage()
  const { state } = useAddCardState<CreateSelectCardFormatState>()

  const goToSetCardSettingsStage = useCallback(
    ({ cardFormat }: SelectCardFormatFormValues) => {
      if (!state || !cardFormat) {
        const submitActionErrorMessage = translate(
          'page.addCardModal.selectCardFormat.submitActionErrorMessage'
        )
        ErrorLogger.report('[Banking] set card settings', {
          submitActionErrorMessage,
        })
        showErrorToast()
        return
      }

      goToAddCardStage({
        stage: AddCardStage.CreateSetCardSettingsStage,
        cardFormat,
        cardType: state.cardType,
        cardholder: state.cardholder,
        account: state.account,
      })
    },
    [goToAddCardStage, state]
  )

  const goBackToSelectAccountStage = useCallback(() => {
    if (!state) {
      const backActionErrorMessage = translate(
        'page.addCardModal.selectCardFormat.backActionErrorMessage'
      )
      ErrorLogger.report(`[Banking] ${backActionErrorMessage}`)
      showErrorToast()
      return
    }

    goToAddCardStage({
      stage: AddCardStage.CreateSelectAccountStage,
      cardType: state.cardType,
      cardholder: state.cardholder,
    })
  }, [goToAddCardStage, state])

  const initialCardFormat: CardFormatOption | '' = useMemo(() => {
    if (state?.cardType === CardTypeOption.ExpenseCard) {
      return CardFormatOption.Virtual
    }
    return state?.cardFormat || ''
  }, [state?.cardFormat, state?.cardType])

  return {
    goToSetCardSettingsStage,
    goBackToSelectAccountStage,
    initialCardFormat,
  }
}
