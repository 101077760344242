import { ContactType } from '@npco/mp-gql-types'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { ascend, compose, omit, prop, sortWith, toLower } from 'ramda'

import { GetContactsWithSortName } from '../../Contacts.types'
import { rvContacts } from '../../rv-deprecated/contacts'
import { isContactDeletedFromRv } from '../../rv-deprecated/contacts.utils'

type GetContact = ContactCoreFieldsFragment

const filterAndMapSubContacts = (contact: GetContact) =>
  contact.contacts
    ?.filter(
      (
        subContact
      ): subContact is NonNullable<NonNullable<GetContact['contacts']>[0]> => {
        return !!subContact && !isContactDeletedFromRv(subContact.id)
      }
    )
    .map((subContact) => {
      // NOTE: Instead of updating every single contact with the new sub contact details,
      // just return the updated contact from RV
      return rvContacts()?.[subContact.id] ?? subContact
    }) ?? null

export const getMappedRvFromContacts = (contacts: (GetContact | null)[]) => {
  const reactiveContacts = rvContacts()

  return contacts.reduce<Record<string, GetContact | null>>((acc, curr) => {
    if (!curr) {
      return acc
    }
    // if ID does not exist yet in RV
    if (reactiveContacts?.[curr.id] === undefined) {
      acc[curr.id] = curr
    } else {
      acc[curr.id] = reactiveContacts?.[curr.id]
    }

    return acc
  }, {})
}

export const injectSortName = (contact: GetContact) => ({
  ...contact,
  sortName:
    contact.contactType === ContactType.BUSINESS
      ? contact.businessName ?? ''
      : `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`.trim(),
})

export const getMappedContactsFromRv = (
  contacts: (GetContact | null)[]
): GetContactsWithSortName[] => {
  const reactiveContacts = rvContacts()

  return contacts
    .filter(
      (contact): contact is GetContactsWithSortName =>
        !!contact && !isContactDeletedFromRv(contact.id)
    )
    .map((contact) => {
      const reactiveContact = reactiveContacts?.[contact.id]

      if (!reactiveContact) {
        return injectSortName({
          ...contact,
          contacts: filterAndMapSubContacts(contact),
        })
      }

      return injectSortName({
        ...reactiveContact,
        contacts: filterAndMapSubContacts(reactiveContact),
      })
    })
}

export const removeSortNameProp = (
  items: GetContactsWithSortName[]
): GetContact[] => {
  return items.map((contactObjWithSortName) =>
    omit(['sortName'], contactObjWithSortName)
  )
}

const toLowerSortName = (a: string) => (a ? toLower(a) : '')

export const contactsNameSort = sortWith([
  ascend<GetContactsWithSortName>(compose(toLowerSortName, prop('sortName'))),
])

export const splitContactsByIsSelf = (
  contacts: GetContactsWithSortName[]
): GetContactsWithSortName[][] => {
  return contacts.reduce(
    (acc: GetContactsWithSortName[][], current: GetContactsWithSortName) =>
      current.isSelf
        ? [[...acc[0], current], [...acc[1]]]
        : [[...acc[0]], [...acc[1], current]],
    [[], []]
  )
}
