import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const BillerName = styled(BodyDefault)`
  color: #6d7274;
`

export const FieldWrapper = styled.div`
  margin: 0 0 24px 0;
`
