import { gql } from '@apollo/client/core'

export const DebitCardUpdate = gql`
  subscription DebitCardUpdate(
    $entityUuid: ID!
    $debitCardAccountUuid: ID
    $customerUuid: ID
  ) {
    onDebitCardUpdate(
      entityUuid: $entityUuid
      debitCardAccountUuid: $debitCardAccountUuid
      customerUuid: $customerUuid
    ) {
      colour
      debitCardAccountUuid
      id
      maskedPan
      name
      owner
      customerUuid
      status
      productType
    }
  }
`
