import { useEffect } from 'react'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { AuthLoadingPage } from 'pages/AuthLoadingPage/AuthLoadingPage'
import { page } from 'translations'

export const Logout = () => {
  const { logout: auth0Logout } = useZellerAuthenticationContext()

  useEffect(() => {
    auth0Logout()
  }, [auth0Logout])

  return <AuthLoadingPage>{[page.logout.dashboard]}</AuthLoadingPage>
}
