import { useCallback } from 'react'
import { Box } from '@npco/zeller-design-system'
import { MultiSelectTriggerRenderProps } from 'design-system/Components/MultiSelect'
import {
  SelectSearchInput,
  SelectSelectedItem,
  SelectSize,
  SelectStandard,
} from 'design-system/Components/Select'

import { ReactComponent as ContactIcon } from 'assets/svg/contact.svg'
import { useContactsSearch } from 'hooks/useContactsSearch/useContactsSearch'
import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { page } from 'translations'

import { FilterSelectTrigger } from '../FilterSelectTrigger/FilterSelectTrigger'
import { ContactListItem } from './AccountFilterContact.utils'
import { AccountFilterContactLoadingState } from './AccountFilterContactLoadingState/AccountFilterContactLoadingState'
import { AccountFilterContactPlaceholder } from './AccountFilterContactPlaceholder/AccountFilterContactPlaceholder'

export const AccountFilterContact = () => {
  const { contact, setContact, resetContact } = useAccountFilters()

  const renderTrigger = useCallback(
    ({ ref, ...renderTriggerProps }: MultiSelectTriggerRenderProps) => (
      <FilterSelectTrigger
        selectedItems={contact ? [contact] : []}
        values={contact ? [contact.value] : []}
        onReset={resetContact}
        filterName={page.accounts.transactionsList.filters.contact}
        icon={<ContactIcon />}
        innerRef={ref}
        {...renderTriggerProps}
      />
    ),
    [contact, resetContact]
  )

  const onChange = (selectedContact: SelectSelectedItem<ContactListItem>) => {
    setContact(selectedContact as ContactListItem)
  }

  const {
    contactItems,
    resetSearch,
    searchInput,
    updateSearchValue,
    isLoading,
  } = useContactsSearch()

  return (
    <SelectStandard
      shouldAutoFocusControl
      items={contactItems}
      selectedItem={contact}
      onChange={onChange}
      maxHeight="10.5rem"
      popperWidth="26rem"
      onClose={resetSearch}
      size={SelectSize.Small}
      renderAdditionalControls={() => (
        <Box p="0.5rem">
          <SelectSearchInput
            value={searchInput}
            setValue={updateSearchValue}
            aria-label={translate('shared.typeToSearch')}
            placeholder={translate('shared.typeToSearch')}
          />
        </Box>
      )}
      isLoading={isLoading}
      renderLoadingPlaceholder={() => <AccountFilterContactLoadingState />}
      renderNoItemsPlaceholder={() => (
        <AccountFilterContactPlaceholder searchInput={searchInput} />
      )}
      renderTrigger={renderTrigger}
    />
  )
}
