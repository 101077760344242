import { COLOR } from '@npco/zeller-design-system'

import { StyledLetterBadge } from './LetterBadge.styled'

interface Props {
  isLarge?: boolean
  bgColor?: string
  color?: string
  text: string | null
  withCursorPointer?: boolean
  className?: string
}

/**
 *
 * NOTE: `LetterBadge` is deprecated - use `AvatarBasic` from the Design
 * System instead.
 */
export const LetterBadge: React.FC<Props> = ({
  isLarge,
  bgColor = COLOR.BLUE_80,
  color = COLOR.BLUE_1000,
  text,
  withCursorPointer,
  className,
}) => {
  const firstLetter = text?.[0]

  return (
    <StyledLetterBadge
      alignItems="center"
      data-testid="letter-badge"
      isLarge={isLarge}
      bgColor={bgColor}
      color={color}
      justifyContent="center"
      withCursorPointer={withCursorPointer}
      className={className}
    >
      {firstLetter}
    </StyledLetterBadge>
  )
}
