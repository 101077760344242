import {
  AnnouncementModal,
  ProgressIndicator,
} from '@npco/zeller-design-system'

import SuccessHiFive from 'assets/svg/accounts-upgrade-flow/success-hi-five.svg'

import { useUpgradeAccountsCompleteModal } from '../../../hooks/useUpgradeAccountsCompleteModal'
import { ImageContainer } from '../../../shared/ImageContainer/ImageContainer'
import { ModalContent } from '../../../shared/ModalContent/ModalContent'
import { TOTAL_STEP_COUNT } from '../../../UpgradeAccountsCompleteModal.utils'
import { ProgressIndicatorWrapper } from './UpgradeAccountsCompleteStartStageContent.styled'
import {
  description,
  primaryButtonLabel,
  title,
} from './UpgradeAccountsCompleteStartStageContent.utils'

export const UpgradeAccountsCompleteStartStageContent = () => {
  const { closeModal, goCompleteBuildBusinessStage } =
    useUpgradeAccountsCompleteModal()
  return (
    <AnnouncementModal
      isOpen
      onClickPrimary={goCompleteBuildBusinessStage}
      primaryButtonLabel={primaryButtonLabel}
      onCancel={closeModal}
    >
      <ModalContent
        Illustration={<ImageContainer imagePath={SuccessHiFive} />}
        description={description}
        heading={title}
      >
        <ProgressIndicatorWrapper justifyContent="center" width="100%">
          <ProgressIndicator
            currentStep={1}
            totalStepCount={TOTAL_STEP_COUNT}
          />
        </ProgressIndicatorWrapper>
      </ModalContent>
    </AnnouncementModal>
  )
}
