import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledGroup = styled.div`
  display: contents;
`
interface StyledListGroupProps {
  isLastGroup: boolean
}

export const StyledGroupList = styled.ul<StyledListGroupProps>`
  list-style: none;
  margin-bottom: ${({ isLastGroup }) => (isLastGroup ? '0' : '24px')};
  margin-top: 0;
  padding: 0;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-bottom: ${({ isLastGroup }) => (isLastGroup ? '0' : '40px')};
  }
`
