import { MutableRefObject } from 'react'

import { translate } from 'utils/translations'

import { GetZellerInvoiceSettingsQueryResponse } from '../../../../Settings/graphql/getZellerInvoiceSettings.generated'
import { InvoiceFormFields } from '../../Invoice.types'
import { InvoiceFormAccordions } from '../InvoiceFormAccordions/InvoiceFormAccordions'
import { INVOICE_ACCORDION_ID } from '../InvoiceFormAccordions/InvoiceFormAccordions.constants'
import { InvoiceSkeletonLoader } from '../InvoiceSkeletonLoader/InvoiceSkeletonLoader'
import { InvoiceSettingsInfoBox } from './components/InvoiceSettingsInfoBox/InvoiceSettingsInfoBox'
import { useInvoiceCreateFormContent } from './hooks/useInvoiceCreateFormContent'

export const translations = {
  saveValidationError: translate('page.invoice.saveValidationFailed'),
  sendValidationError: translate('page.invoice.sendValidationFailed'),
}

interface InvoiceCreateFormContentProps {
  defaultInitialValuesRef: MutableRefObject<InvoiceFormFields>
  invoiceSettings?: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']
  isInvoiceScheduled: boolean
  isSendingInvoiceRef: MutableRefObject<boolean>
  shouldResetInitialExpandedItem: boolean
}

export const InvoiceCreateFormContent = ({
  defaultInitialValuesRef,
  invoiceSettings,
  isInvoiceScheduled,
  isSendingInvoiceRef,
  shouldResetInitialExpandedItem,
}: InvoiceCreateFormContentProps) => {
  const { isLoading, invoiceData } = useInvoiceCreateFormContent({
    defaultInitialValuesRef,
    invoiceSettings,
    isInvoiceScheduled,
    isSendingInvoiceRef,
  })

  if (isLoading) {
    return <InvoiceSkeletonLoader dataTestId="invoice-create-loader" />
  }

  return (
    <>
      <InvoiceSettingsInfoBox />
      <InvoiceFormAccordions
        initialExpandedItem={
          invoiceData?.getInvoice
            ? INVOICE_ACCORDION_ID.SCHEDULE
            : INVOICE_ACCORDION_ID.CUSTOMER
        }
        shouldResetInitialExpandedItem={shouldResetInitialExpandedItem}
      />
    </>
  )
}
