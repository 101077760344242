import {
  BodySmall,
  BodySmallTypography,
  ErrorMessageForm,
} from '@npco/zeller-design-system'
import { Form } from 'formik'
import styled from 'styled-components'

export const StyledSubtext = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0.25rem 0;
`

export const StyledErrorMessage = styled(ErrorMessageForm)`
  ${BodySmallTypography}
  margin-top: 0.5rem;
`

export const StyledForm = styled(Form)`
  padding-top: 0.75rem;
`
