import { gql } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { useIsMobileResolution } from '@npco/zeller-design-system'

import { formatAccountNumber, formatBsb } from 'utils/common'
import { translate } from 'utils/translations'
import { ListItem, ListItemSectionTitle } from 'components/List'

import { CopyButton } from '../CopyButton/CopyButton'
import { TransferInDetailsDebitCardAccountV2Fragment } from './TransferInDetails.generated'

type TransferInDetailsProps = {
  account: TransferInDetailsDebitCardAccountV2Fragment
}

export const TransferInDetails = ({ account }: TransferInDetailsProps) => {
  const isAccountClosed = account.status === DebitCardAccountStatus.CLOSED
  const isMobile = useIsMobileResolution()
  const isCopyEnabled = !isAccountClosed && !isMobile

  return (
    <>
      <ListItemSectionTitle
        title={translate(
          'page.settings.accountDetails.sectionTransferInDetails'
        )}
      />
      <ListItem
        left={translate('page.settings.accountDetails.accountName')}
        right={!isAccountClosed ? account.accountDetails?.name ?? '-' : '-'}
        isAsideHoverable
        aside={
          isCopyEnabled && (
            <CopyButton
              value={account.accountDetails?.name}
              title={translate(
                'page.settings.accountDetails.actionCopyAccountName'
              )}
              buttonTestId="button-account-name"
              paddingLeft="16px"
            />
          )
        }
      />
      <ListItem
        left={translate('page.settings.accountDetails.accountBsb')}
        right={
          account.accountDetails?.bsb && !isAccountClosed
            ? formatBsb(account.accountDetails.bsb)
            : '-'
        }
        isAsideHoverable
        aside={
          isCopyEnabled && (
            <CopyButton
              value={account.accountDetails?.bsb}
              title={translate(
                'page.settings.accountDetails.actionCopyAccountBsb'
              )}
              buttonTestId="button-account-bsb"
              paddingLeft="16px"
            />
          )
        }
      />
      <ListItem
        left={translate('page.settings.accountDetails.accountNumber')}
        right={
          account.accountDetails?.account && !isAccountClosed
            ? formatAccountNumber(account.accountDetails.account)
            : '-'
        }
        isAsideHoverable
        aside={
          isCopyEnabled && (
            <CopyButton
              value={account.accountDetails?.account}
              title={translate(
                'page.settings.accountDetails.actionCopyAccountNumber'
              )}
              buttonTestId="button-account-number"
              paddingLeft="16px"
            />
          )
        }
      />
    </>
  )
}

TransferInDetails.fragments = {
  DebitCardAccountV2: gql`
    fragment TransferInDetailsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status
      accountDetails {
        account
        bsb
        name
      }
    }
  `,
}
