import { BREAKPOINT, ButtonGhost } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { SpinnerWrapped } from 'components/Spinner'

// NOTE: matches PAGE_HALF_WIDTH from design-system
const FORM_WIDTH = 504

export const AbsoluteSpinner = styled(SpinnerWrapped)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: ${FORM_WIDTH}px;
    left: auto;
    transform: none;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: ${FORM_WIDTH}px;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    width: ${FORM_WIDTH}px;
    flex: none;
  }
`

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const InvoicePreviewWrapper = styled.div`
  display: none;
  width: 100%;
  padding-top: 12px;
  padding-left: 76px;
  max-width: 443px;
  position: sticky;
  top: 0;
  align-self: flex-start;
`

export const PreviewButton = styled(ButtonGhost)`
  height: 30px;
`
