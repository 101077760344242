import { pipe } from 'ramda'

import {
  GetOverviewTransactionTotals_getTransactionTotalsBigInt as ApiTransactionTotal,
  GetOverviewTransactionTotals_getTransactionTotalsBigInt_taxAmounts as ApiTaxAmount,
} from 'types/gql-types/GetOverviewTransactionTotals'
import { TransactionInList } from 'types/transactions'
import {
  getHourlyPeriod,
  groupByPeriod,
  mapTransactionToTotal,
  sumTotalsByPeriod,
} from 'pages/Dashboard/utils/transactionUpdates.utils'

import { TaxAmount, TransactionTotal } from '../../DashboardPerformance.types'

const convertApiTaxAmounts = (apiTaxAmounts: ApiTaxAmount[]) =>
  apiTaxAmounts.map((taxAmount) => ({
    name: taxAmount.name,
    amount: parseInt(taxAmount.amount, 10),
  }))

const createTransactionTotal = (transaction: TransactionInList) => {
  const refund = transaction.refundedAmount ?? 0

  return {
    period: getHourlyPeriod(transaction),
    refundAmount: refund,
    surchargeAmount: transaction.surchargeAmount,
    purchaseAmount: transaction.amount,
    totalAmount: transaction.amount - refund,
    tipAmount: transaction.tipAmount,
    taxAmounts: transaction.taxAmounts ?? [],
    feeAmount: transaction.feeAmount ?? 0,
  }
}

const updateExistingTaxAmount = (
  existingTaxAmounts: TaxAmount[],
  indexToUpdate: number,
  taxAmountToAdd: TaxAmount
) =>
  existingTaxAmounts.map((existingTaxAmount, index) => {
    if (index === indexToUpdate) {
      return {
        name: existingTaxAmount.name,
        amount: existingTaxAmount.amount + taxAmountToAdd.amount,
      }
    }
    return existingTaxAmount
  })

const mergeTaxAmount = (
  existingTaxAmounts: TaxAmount[],
  taxAmount: TaxAmount
) => {
  const indexToUpdate = existingTaxAmounts.findIndex(
    (existing) => existing.name === taxAmount.name
  )

  if (indexToUpdate === -1) {
    return [...existingTaxAmounts, taxAmount]
  }
  return updateExistingTaxAmount(existingTaxAmounts, indexToUpdate, taxAmount)
}

const mergeTaxAmounts = (
  existingTaxAmounts: TaxAmount[],
  taxAmountsToMerge: TaxAmount[]
) => taxAmountsToMerge.reduce(mergeTaxAmount, existingTaxAmounts)

export const mapToTransactionTotal = mapTransactionToTotal(
  createTransactionTotal
)

export const sumTransactionTotals = ([period, totals]: [
  string,
  TransactionTotal[]
]) =>
  totals.reduce((summedTotal, totalToAdd) => ({
    period,
    refundAmount: summedTotal.refundAmount + totalToAdd.refundAmount,
    surchargeAmount: summedTotal.surchargeAmount + totalToAdd.surchargeAmount,
    totalAmount: summedTotal.totalAmount + totalToAdd.totalAmount,
    tipAmount: summedTotal.tipAmount + totalToAdd.tipAmount,
    purchaseAmount: summedTotal.purchaseAmount + totalToAdd.purchaseAmount,
    taxAmounts: mergeTaxAmounts(
      summedTotal.taxAmounts ?? [],
      totalToAdd.taxAmounts ?? []
    ),
    feeAmount: summedTotal.feeAmount + totalToAdd.feeAmount,
  }))

export const mergePerformanceData = (
  totalsA: TransactionTotal[],
  totalsB: TransactionTotal[]
) => {
  return pipe(
    groupByPeriod as (
      s: TransactionTotal[]
    ) => Record<string, TransactionTotal[]>,
    sumTotalsByPeriod(sumTransactionTotals)
  )([...totalsA, ...totalsB])
}

export const convertFromApiTotals = (apiTotals: ApiTransactionTotal[]) =>
  apiTotals.map((apiTotal) => {
    return {
      period: apiTotal.period,
      refundAmount: parseInt(apiTotal.refundAmount, 10),
      surchargeAmount: parseInt(apiTotal.surchargeAmount, 10),
      totalAmount: parseInt(apiTotal.totalAmount, 10),
      tipAmount: parseInt(apiTotal.tipAmount, 10),
      purchaseAmount: parseInt(apiTotal.purchaseAmount, 10),
      taxAmounts: convertApiTaxAmounts(apiTotal.taxAmounts ?? []),
      feeAmount: parseInt(apiTotal.feeAmount, 10),
    }
  })
