import { gql } from '@apollo/client'

export const XeroPaymentServices = gql`
  query XeroPaymentServices(
    $connection: ConnectionType = XERO_PAYMENT_SERVICES
    $entityUuid: ID!
  ) {
    xeroPaymentServices: getConnectionState(
      connectionType: $connection
      entityUuid: $entityUuid
    ) {
      id
      status
      errorType
    }
  }
`
