import {
  BodyDefault,
  Box,
  Heading,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Formik, FormikHelpers } from 'formik'

import { translate } from 'utils/translations'
import { SettingsLeavingRoutePromptGuard } from 'components/RouteLeavingPromptGuard'
import { SpinnerWrapped } from 'components/Spinner'

import { useGetZellerInvoiceSettings } from '../hooks/useGetZellerInvoiceSettings'
import { useInvoiceSettingsAnalytics } from '../hooks/useInvoiceSettingsAnalytics'
import { useUpdateZellerInvoiceSettings } from '../hooks/useUpdateZellerInvoiceSettings'
import { RemindersForm } from './RemindersForm'
import { remindersSchema } from './SettingsReminders.schemas'
import {
  InvoiceSettingsRemindersFormFields,
  REMINDER_AFTER_FIELD,
  REMINDER_BEFORE_FIELD,
} from './SettingsReminders.types'

const sortArray = (array: number[], ascending = false) => {
  if (ascending) {
    return array.sort((a: number, b: number) => a - b)
  }

  return array.sort((a: number, b: number) => b - a)
}

export const SettingsReminders = () => {
  const { trackRemindersSettingsUpdated } = useInvoiceSettingsAnalytics()
  const { invoiceSettings, updateInvoiceSettingsCache, isLoading } =
    useGetZellerInvoiceSettings()

  const { updateZellerInvoiceSettings, isUpdatingZellerInvoiceSettings } =
    useUpdateZellerInvoiceSettings()

  if (isLoading || !invoiceSettings) {
    return <SpinnerWrapped variant="top" />
  }

  const initialValues = {
    reminderOnDue: invoiceSettings.invoice.reminderOnDue,
    remindersDaysBeforeDue: invoiceSettings.invoice.remindersDaysBeforeDue,
    remindersDaysAfterDue: invoiceSettings.invoice.remindersDaysAfterDue,
  }

  const handleOnSubmit = async (
    values: InvoiceSettingsRemindersFormFields,
    formikHelpers: FormikHelpers<InvoiceSettingsRemindersFormFields>
  ) => {
    const invoice = {
      ...values,
      remindersDaysAfterDue: sortArray(
        values[REMINDER_AFTER_FIELD].filter(Boolean),
        true
      ),
      remindersDaysBeforeDue: sortArray(
        values[REMINDER_BEFORE_FIELD].filter(Boolean)
      ),
    }

    try {
      const result = await updateZellerInvoiceSettings({ invoice })

      if (result) {
        trackRemindersSettingsUpdated(initialValues, values)

        updateInvoiceSettingsCache({
          ...invoiceSettings,
          invoice: {
            ...invoiceSettings.invoice,
            ...invoice,
          },
        })

        formikHelpers.resetForm({ values: invoice })
        showSuccessToast(translate('page.invoicesSettings.successToast'))
      } else {
        showErrorToast(translate('page.invoicesSettings.errorToast'))
      }
    } catch (err) {
      showErrorToast(translate('page.invoicesSettings.errorToast'))
    }
  }

  return (
    <Formik<InvoiceSettingsRemindersFormFields>
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validationSchema={remindersSchema}
    >
      <>
        <SettingsLeavingRoutePromptGuard />
        {isUpdatingZellerInvoiceSettings ? (
          <SpinnerWrapped variant="top" />
        ) : (
          <>
            <Heading.H3>
              {translate('page.invoicesSettings.reminders.title')}
            </Heading.H3>
            <Box mb="24px">
              <BodyDefault>
                {translate('page.invoicesSettings.reminders.description')}
              </BodyDefault>
            </Box>
            <RemindersForm />
          </>
        )}
      </>
    </Formik>
  )
}
