import { ComponentProps } from 'react'
import { COLOR, PadlockIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
  gap: 0 8px;
`
export const Padlock = (props: ComponentProps<typeof PadlockIcon>) => (
  <PadlockIcon color={COLOR.YELLOW_1000} width="12" height="12" {...props} />
)
