import { Flex } from '@npco/zeller-design-system'

import { DebitCardType } from '../../../../../../../hooks/useSetupFlowCardsQuery/graphql/getCards.type'
import { CardCVCInformation } from './components/CardCVCInformation'
import { CardExpiryInformation } from './components/CardExpiryInformation'
import { CardOwnerInformation } from './components/CardOwnerInformation'
import { CardPanInformation } from './components/CardPanInformation'
import {
  SensitiveContentBack,
  SensitiveContentFront,
} from './SensitiveContentMobile.styled'

interface SensitiveContentMobileProps {
  isFlipped: boolean
  debitCard?: DebitCardType
  isRevealed: boolean
  copySuccessMap: Record<string, boolean>
}

export const SensitiveContentMobile = ({
  isFlipped,
  debitCard,
  isRevealed,
  copySuccessMap,
}: SensitiveContentMobileProps) => {
  return (
    <>
      <SensitiveContentFront $isVisible={!isFlipped} />
      {debitCard && (
        <SensitiveContentBack $isVisible={isFlipped}>
          <CardOwnerInformation
            isLoading={!isRevealed}
            cardOwner={debitCard.customer}
          />

          <CardPanInformation
            isLoading={!isRevealed}
            copySuccessMap={copySuccessMap}
          />
          <Flex gridGap="70px">
            <CardExpiryInformation
              isLoading={!isRevealed}
              copySuccessMap={copySuccessMap}
            />
            <CardCVCInformation
              isLoading={!isRevealed}
              copySuccessMap={copySuccessMap}
            />
          </Flex>
        </SensitiveContentBack>
      )}
    </>
  )
}
