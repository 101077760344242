import styled, { css } from 'styled-components'

import { SelectSize } from '../Select.types'

export const StyledSelectIcon = styled.div<{
  isOpen?: boolean
}>`
  transform-origin: center center;
  transition: 250ms ease-in-out;
  transition-property: transform;
  ${({ isOpen }) => isOpen && 'transform: rotateX(180deg)'};

  path {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

interface StyledSelectTriggerBasicProps {
  isOpen: boolean
  hasError?: boolean
}

export const StyledSelectTrigger = styled.button.attrs({
  type: 'button',
})<StyledSelectTriggerBasicProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  border-radius: 7px;
  cursor: pointer;
  transition: border-color 0.3s ease-out, background-color 0.3s ease;
  text-align: left;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.BLUE_400};

    ${StyledSelectIcon} svg path {
      fill: ${({ theme }) => theme.colors.BLUE_400};
    }
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      &:focus {
        background-color: ${({ theme }) => theme.colors.GREY_30};
      }
    `}

  &:disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.colors.GREY_250};
    color: ${({ theme }) => theme.colors.GREY_250};

    ${StyledSelectIcon} svg path {
      fill: ${({ theme }) => theme.colors.GREY_250};
    }
  }

  ${({ isOpen, theme }) =>
    isOpen && `background-color: ${theme.colors.BLUE_80}`};
  ${({ hasError, theme }) =>
    hasError && `border-color: ${theme.colors.RED_1000}`};

  &.${SelectSize.Small} {
    height: 3rem;
    padding: 0 0.625rem 0 0.75rem;
  }

  &.${SelectSize.Medium} {
    height: 3.5rem;
    padding: 0 1.125rem 0 0.75rem;
  }

  &.${SelectSize.Large} {
    height: 4rem;
    padding: 0 1.125rem 0 0.75rem;
  }

  &.${SelectSize.ExtraLarge} {
    height: 4.75rem;
    padding: 0 1rem;
  }
`

export const StyledSelectTriggerContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
