import { useMemo } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'

import { useSubcategories } from '../useSubcategories'

interface UseSubcategoryDisplayProps {
  category: EntityCategories | null
  subcategoryId: string | null | undefined
}

export const useSubcategoryDisplay = ({
  category,
  subcategoryId,
}: UseSubcategoryDisplayProps) => {
  const { subcategories, isLoading, error } = useSubcategories({ category })

  const subcategory = useMemo(
    () =>
      subcategories.find(
        (subcategoryDetails) => subcategoryDetails.id === subcategoryId
      ) ||
      subcategories.find(
        (subcategoryDetails) => subcategoryDetails.name === 'Other'
      ),
    [subcategories, subcategoryId]
  )

  return { subcategory, isLoading, error }
}
