import { detect } from 'detect-browser'

export const MINIMUM_FIREFOX_VERSION_SUPPORTING_CUSTOM_SCROLLBARS = 64

export const useCustomScrollbarIsAllowed = () => {
  const details = detect()

  if (!details) {
    return true
  }

  if (details.name === 'firefox') {
    const versionParts = details.version.split('.')
    const majorVersion = versionParts[0]
    const version = Number.parseInt(majorVersion, 10)

    return version < MINIMUM_FIREFOX_VERSION_SUPPORTING_CUSTOM_SCROLLBARS
  }

  if (details.os === 'iOS' || details.os === 'Android OS') {
    return false
  }

  return true
}
