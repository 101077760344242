import {
  Heading,
  HeadingStyles,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Label = styled(Heading.H4).attrs({
  withStyles: HeadingStyles.H5,
})``

export const Value = styled.div`
  ${InterZellerMediumFont};
  color: ${({ theme }) => theme.colors.BLACK_900};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
