import { BodyDefault, Flex, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Description = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const Subtitle = styled(Heading.H4)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`
export const Row = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`
