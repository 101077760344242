import { ErrorLogger } from '@npco/utils-error-logger'

import {
  DailyHours,
  DailyOpenCloseHours,
  OpeningHours,
  OpeningHoursDisplay,
  OpeningHoursForDay,
  OpeningHoursStatus,
} from '../TransactionContact.types'

const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

const SEVEN_DAYS = 7

export const getHoursText = (times: DailyOpenCloseHours[] | null) => {
  if (!times) {
    return null
  }
  const mergedHours = times.map((element) => `${element.open}-${element.close}`)

  return mergedHours.join()
}

export const buildOpeningHoursForDay = (
  dayAndHoursObject: OpeningHoursForDay
) => {
  return {
    days: [dayAndHoursObject.day],
    hours: dayAndHoursObject.hours,
    status: dayAndHoursObject.status,
  }
}

export const buildOpeningHoursForDayWithNullishHours = (
  dayAndHoursObject: OpeningHoursForDay
) => {
  return {
    days: [dayAndHoursObject.day],
    hours: null,
    status: dayAndHoursObject.status,
  }
}

export const filterAndSortOpeningHours = (
  deserializedOpeningHours: OpeningHours
) => {
  // clone hours to be able to remove boolean value
  const tempHours = { ...deserializedOpeningHours }
  delete tempHours.is_always_open

  // Gather object values and keys
  const rawOpeningHoursValues = Object.values(deserializedOpeningHours)
  const receivedWeekDays = Object.keys(tempHours)

  return rawOpeningHoursValues // filter out `is_always_open` boolean variable
    .filter((dayAndHours) => typeof dayAndHours !== 'boolean')
    .map((dayAndHoursObject: DailyHours, index) => {
      return {
        day: receivedWeekDays[index],
        status: dayAndHoursObject?.status ? dayAndHoursObject.status : null,
        hours: getHoursText(dayAndHoursObject?.times || null),
      } as OpeningHoursForDay
    })
    .sort((a, b) => {
      return weekDays.indexOf(a.day) - weekDays.indexOf(b.day)
    })
}

export const buildMergedDaysAndAddMissingDays = (
  filteredDaysWithOpeningHours: OpeningHoursForDay[]
) => {
  const mergedDays: OpeningHoursDisplay[] = []
  // add missing days for later display
  weekDays.forEach((day, index) => {
    if (day !== filteredDaysWithOpeningHours[index].day) {
      filteredDaysWithOpeningHours.splice(index, 0, {
        day: weekDays[index],
        status: null,
        hours: null,
      })
    }
  })

  filteredDaysWithOpeningHours.forEach((dayAndHoursObject) => {
    if (
      dayAndHoursObject?.status?.toUpperCase() === OpeningHoursStatus.Closed
    ) {
      mergedDays.push(
        buildOpeningHoursForDayWithNullishHours(dayAndHoursObject)
      )
    } else {
      mergedDays.push(buildOpeningHoursForDay(dayAndHoursObject))
    }
  })

  return mergedDays
}

export const modifyMergedDaysWithNewDay = (
  mergedDays: OpeningHoursDisplay[],
  dayWithOpeningHours: OpeningHoursForDay
) => {
  const areLastHoursAreTheSameAsCurrent = Boolean(
    mergedDays[mergedDays.length - 1].hours === dayWithOpeningHours.hours
  )
  if (areLastHoursAreTheSameAsCurrent) {
    mergedDays[mergedDays.length - 1].days.push(dayWithOpeningHours.day)
  } else {
    mergedDays.push(buildOpeningHoursForDay(dayWithOpeningHours))
  }
}

export const buildMergedDays = (
  filteredDaysWithOpeningHours: OpeningHoursForDay[]
) => {
  const mergedDays: OpeningHoursDisplay[] = []

  filteredDaysWithOpeningHours.forEach((dayWithOpeningHours, index) => {
    if (
      dayWithOpeningHours?.status?.toUpperCase() === OpeningHoursStatus.Closed
    ) {
      mergedDays.push(
        buildOpeningHoursForDayWithNullishHours(dayWithOpeningHours)
      )
    } else if (index !== 0) {
      modifyMergedDaysWithNewDay(mergedDays, dayWithOpeningHours)
    } else {
      mergedDays.push(buildOpeningHoursForDay(dayWithOpeningHours))
    }
  })

  return mergedDays
}

export const getOpeningHoursParser = (hours: OpeningHours | null) => {
  if (!hours) {
    return []
  }
  const filteredAndSortedDays = filterAndSortOpeningHours(hours)
  const areSomeDaysMissing = filteredAndSortedDays.length < SEVEN_DAYS

  const mergedDays = areSomeDaysMissing
    ? buildMergedDaysAndAddMissingDays(filteredAndSortedDays)
    : buildMergedDays(filteredAndSortedDays)

  mergedDays.forEach((dayAndHoursObject, index) => {
    if (dayAndHoursObject.days.length === 1) {
      mergedDays[index].days = [`${dayAndHoursObject.days[0].slice(0, 3)}`]
    } else {
      mergedDays[index].days = [
        `${dayAndHoursObject.days[0].slice(0, 3)} - ${dayAndHoursObject.days[
          dayAndHoursObject.days.length - 1
        ].slice(0, 3)}`,
      ]
    }
  })

  return mergedDays
}

export const getDeserializedOpeningHours = (openingHours?: string | null) => {
  if (!openingHours) {
    return {
      openingHours: null,
      hasError: false,
    }
  }

  try {
    return {
      openingHours: JSON.parse(openingHours) as OpeningHours,
      hasError: false,
    }
  } catch (err) {
    ErrorLogger.report('[Banking] Get Opening hours', err)
  }

  return { openingHours: null, hasError: true }
}

const getStatusIsAlwaysOpen = (openingHours: OpeningHours) => {
  return (
    openingHours.monday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours &&
    openingHours.tuesday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours &&
    openingHours.wednesday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours &&
    openingHours.thursday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours &&
    openingHours.friday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours &&
    openingHours.saturday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours &&
    openingHours.sunday?.status?.toUpperCase() ===
      OpeningHoursStatus.TwentyFourHours
  )
}

export const getIsAlwaysOpen = (openingHours: OpeningHours | null) => {
  return (
    openingHours &&
    (openingHours?.is_always_open || getStatusIsAlwaysOpen(openingHours))
  )
}
