import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import {
  Box,
  INPUT_SIZE,
  InputSelectComboboxInputRenderProps,
  InputSelectComboboxItemBasic,
  NEW_ITEM_VALUE,
  Pill,
  PillList,
} from '@npco/zeller-design-system'
import { ModalWithButtons } from 'design-system/Components/Modal'
import { UseComboboxStateChange } from 'downshift'

import { translate } from 'utils/translations'
import { CustomValidator } from 'types/common'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { component, shared } from 'translations'

import {
  getNonCaseSensitiveMatch,
  TAGS_SUGGESTIONS_HIDDEN_TESTID,
  TAGS_SUGGESTIONS_VISIBLE_TESTID,
  validateTagValue,
} from './EditTags.utils'
import { EditTagsInputSelectCombobox } from './EditTagsInputSelectCombobox'
import { StyledEditTagsModalWrapper } from './EditTagsModal.styled'
import { EditTagsModalInput } from './EditTagsModalInput'

interface EditTagsModalProps {
  inputLabel?: ReactNode
  isAddingEntityTag?: boolean
  isDeletingEntityTag?: boolean
  isOpen: boolean
  isSavingTags?: boolean
  itemsInSearch: InputSelectComboboxItemBasic[]
  onAddTag: (value: string, isNewTag: boolean) => Promise<boolean>
  onDeleteEntityTag: (tag: string) => Promise<boolean>
  onCancel: () => void
  onSave: () => void
  onTagClose: (tag: Pill) => void
  placeholder?: string
  tags: string[]
  title?: ReactNode
  validate: CustomValidator<string, string[]>
}

export const EditTagsModal = ({
  inputLabel,
  isAddingEntityTag,
  isDeletingEntityTag,
  isOpen,
  isSavingTags,
  itemsInSearch,
  onAddTag,
  onCancel,
  onDeleteEntityTag,
  onSave,
  onTagClose,
  placeholder,
  tags,
  title,
  validate,
}: EditTagsModalProps) => {
  const [tagToDelete, setTagToDelete] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [shouldHideSuggestions, setShouldHideSuggestions] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const isAddNewTagError = useRef(false)

  const handleAddTag = useCallback(
    async (tag: string, isNewTag: boolean) => {
      const error = validate(inputValue, tags)

      if (error) {
        isAddNewTagError.current = true
        setErrorMessage(error)
        inputRef.current?.blur()
        return
      }

      const success = await onAddTag(tag, isNewTag)

      if (!success) {
        return
      }

      setErrorMessage('')
      setInputValue('')

      inputRef.current?.focus()
      isAddNewTagError.current = false
    },
    [onAddTag, inputValue, tags, validate]
  )

  const onInputChange = useCallback(
    (changes: UseComboboxStateChange<InputSelectComboboxItemBasic>) => {
      if (isAddNewTagError.current) {
        return
      }

      const value = changes.inputValue || ''

      setInputValue(value)

      const error = validateTagValue(value) || ''

      setErrorMessage(error)

      setShouldHideSuggestions(Boolean(error))
    },
    []
  )

  const onInputClear = useCallback(() => setInputValue(''), [])

  const onSelection = useCallback(
    async (changes: UseComboboxStateChange<InputSelectComboboxItemBasic>) => {
      const isNewTag = changes?.selectedItem?.value === NEW_ITEM_VALUE

      await handleAddTag(
        isNewTag ? inputValue : (changes?.selectedItem?.label as string),
        isNewTag
      )
    },
    [handleAddTag, inputValue]
  )

  const handleKeyPress = useCallback(
    async (e: { key: string }) => {
      if (e.key !== 'Enter' || !inputValue) {
        return
      }

      const nonCaseSensitiveMatch = itemsInSearch.find(
        getNonCaseSensitiveMatch(inputValue)
      )

      if (nonCaseSensitiveMatch) {
        await handleAddTag(nonCaseSensitiveMatch.label, false)
        return
      }

      await handleAddTag(inputValue.trim(), true)
    },
    [handleAddTag, inputValue, itemsInSearch]
  )

  const itemsWithoutSelectedTags = useMemo(
    () =>
      itemsInSearch.filter(({ label }) => !tags.some((tag) => label === tag)),
    [itemsInSearch, tags]
  )

  const shouldRenderNewItem = Boolean(
    inputValue && !itemsInSearch.some(getNonCaseSensitiveMatch(inputValue))
  )

  const hasError = Boolean(errorMessage)

  const isEmptySelection =
    !shouldRenderNewItem && !itemsWithoutSelectedTags.length

  const hideSuggestions =
    shouldHideSuggestions ||
    tags.some(getNonCaseSensitiveMatch(inputValue)) ||
    isEmptySelection

  const renderComboboxInput = (
    renderProps: InputSelectComboboxInputRenderProps
  ) => (
    <EditTagsModalInput
      hasError={hasError}
      inputLabel={inputLabel}
      isAddingEntityTag={isAddingEntityTag}
      onBlur={renderProps.onBlur}
      onFocus={(e) => {
        isAddNewTagError.current = false
        renderProps.onFocus(e)
      }}
      onKeyPress={handleKeyPress}
      renderProps={renderProps}
      ref={inputRef}
    />
  )

  return (
    <ModalFormScrollable
      isOpen={isOpen}
      onCancel={onCancel}
      onSave={onSave}
      title={title}
      confirmLabel={translate('shared.save')}
      cancelLabel={translate('shared.cancel')}
      isLoading={isSavingTags}
    >
      <StyledEditTagsModalWrapper
        $size={INPUT_SIZE.SMALL}
        data-testid={
          hideSuggestions
            ? TAGS_SUGGESTIONS_HIDDEN_TESTID
            : TAGS_SUGGESTIONS_VISIBLE_TESTID
        }
      >
        {Boolean(tags.length) && (
          <Box paddingBottom="1rem">
            <PillList
              maxWidth="28rem"
              pills={tags}
              onPillClose={onTagClose}
              editButtonText={translate('shared.edit')}
              closeIconAlt={translate('shared.close')}
            />
          </Box>
        )}
        <EditTagsInputSelectCombobox
          inputValue={inputValue}
          renderInput={renderComboboxInput}
          items={itemsWithoutSelectedTags}
          onDeleteItemClick={setTagToDelete}
          onInputChange={onInputChange}
          onInputClear={onInputClear}
          onChange={onSelection}
          hasError={hasError}
          errorMessage={errorMessage}
          helperText={placeholder}
          shouldRenderNewItem={shouldRenderNewItem}
          validate={validate}
        />
      </StyledEditTagsModalWrapper>
      {Boolean(tagToDelete) && (
        <ModalWithButtons
          hasCancelButton
          isPrimaryButtonLoading={isDeletingEntityTag}
          title={component.tags.deleteTag}
          primaryButtonLabel={shared.delete}
          secondaryButtonLabel={shared.cancel}
          onConfirm={async () => {
            await onDeleteEntityTag?.(tagToDelete)
            setTagToDelete('')
          }}
          isOpen
          onCancel={() => setTagToDelete('')}
        >
          <Box mb="0.75rem">{component.tags.deleteTagDescription}</Box>
        </ModalWithButtons>
      )}
    </ModalFormScrollable>
  )
}
