import { useCallback, useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonGhost,
  ButtonGhostIconOnly,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useCorporateCardWalkthroughRouterState } from 'features/Cards/AllCardsCorporate/hooks/useCorporateCardWalkthroughRouterState/useCorporateCardWalkthroughRouterState'
import { CorporateCardSettingsWalkthrough } from 'features/CorporateCardSettingsWalkthrough/corporate-card-settings-walkthrough'
import { useGoToCorporateCardSettingsWalkthroughState } from 'features/CorporateCardSettingsWalkthrough/corporate-card-settings-walkthrough-routing'

import { ReactComponent as CogIcon } from 'assets/svg/cogwheel.svg'
import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'

import { useNavigateToCardSettings } from '../../../../../hooks/useNavigateToCardSettings/useNavigateToCardSettings'
import { corporateSettingButtonTranslations } from './CorporateSettingButton.i18n'

export const CorporateSettingButton = () => {
  const t = useTranslations(corporateSettingButtonTranslations)
  const { navigateToCardSettings } = useNavigateToCardSettings()
  const isMobile = useIsMobileResolution()

  const { goToCorporateCardSettingsWalkthroughState } =
    useGoToCorporateCardSettingsWalkthroughState()

  const { isRouterStateReadyForWalkthrough } =
    useCorporateCardWalkthroughRouterState()

  const {
    showCorporateCardsWalkthrough,
    showCorporateCardsSettingsWalkthrough,
  } = useCustomerProductTourStatus()

  const handleNavigationToCardSettings = useCallback(() => {
    navigateToCardSettings()
  }, [navigateToCardSettings])

  const shouldTriggerSettingsWalkthrough =
    !showCorporateCardsWalkthrough &&
    showCorporateCardsSettingsWalkthrough &&
    isRouterStateReadyForWalkthrough

  useEffect(() => {
    if (shouldTriggerSettingsWalkthrough) {
      goToCorporateCardSettingsWalkthroughState({
        stage: 'editOutstandingExpenses',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTriggerSettingsWalkthrough])

  return (
    <>
      {isMobile ? (
        <ButtonGhostIconOnly
          className="card-settings-button-mobile"
          aria-label={t('settings')}
          icon={CogIcon}
          onClick={handleNavigationToCardSettings}
        />
      ) : (
        <ButtonGhost
          onClick={handleNavigationToCardSettings}
          className="card-settings-button"
        >
          {t('settings')}
        </ButtonGhost>
      )}

      <CorporateCardSettingsWalkthrough />
    </>
  )
}
