import { createTranslations } from '@npco/utils-translations'

export const setupFlowVirtualTerminalStageTranslations = createTranslations({
  title: 'Accept payments from any web browser.',
  description:
    'Process a payment from your web browser, or send customers a secure payment link.',
  point1: 'Accept payments online — no terminal needed.',
  point2: 'Apply a surcharge to pass on transaction fees.',
  point3: 'Assign transactions to contacts.',
  point4: 'Send payment links via SMS, email, call centres or social media.',
})
