import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useGetCustomerMarketingQuery } from './graphql/getCustomerMarketing.generated'

export const useGetCustomerMarketing = () => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data } = useGetCustomerMarketingQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entityUuid,
    },
  })

  return {
    isLoading: loading,
    bannerSlug: data?.getCustomerMarketing?.banner,
    modalSlug: data?.getCustomerMarketing?.modal,
  }
}
