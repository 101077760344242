import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetSimBills } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sim'
import {
  rvSelectedDates,
  rvSelectedRates,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { whereEq } from 'ramda'

import { resetFilters } from 'utils/common'
import { mapFiltersInputToSimStatementsApiFilter } from 'utils/mapFiltersInputToApiFilter'
import {
  GetSimBills as GetSimBillsType,
  GetSimBills_getSimBills_nextToken as NextToken,
  GetSimBillsVariables,
} from 'types/gql-types/GetSimBills'
import { SimBillType } from 'types/sim'

const defaultFiltersState = {
  timestamp: {
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  },
}

export const useSimBillsFilters = (isLocalDate = true) => {
  const dates = useReactiveVar(rvSelectedDates)

  const filters = useMemo(
    () =>
      mapFiltersInputToSimStatementsApiFilter(
        {
          dates,
        },
        isLocalDate
      ),
    [dates, isLocalDate]
  )

  return {
    filters,
    defaultFiltersState,
    areFiltersInDefaultState: whereEq(defaultFiltersState)({
      ...defaultFiltersState,
      ...filters,
    }),
  }
}

const SIM_BILLS_PER_PAGE = 30
export const useSimBillsFetch = () => {
  const entityUuid = useSelectedEntityUuid()
  const { filters, areFiltersInDefaultState } = useSimBillsFilters()

  const [nextToken, setNextToken] = useState<NextToken | null>(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasErrorOccurred, setHasErrorOccurred] = useState(false)

  const [getSimBills, { data, loading, fetchMore }] = useLazyQuery<
    GetSimBillsType,
    GetSimBillsVariables
  >(GetSimBills, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      entityUuid,
      limit: SIM_BILLS_PER_PAGE,
      filter: filters,
    },
    onError: () => {
      setHasErrorOccurred(true)
      showApiErrorToast()
    },
    onCompleted: (response) => {
      setHasLoaded(true)
      setNextToken(response.getSimBills.nextToken)
    },
  })

  const simBills = useMemo(
    () => (data?.getSimBills?.bills as SimBillType[]) ?? [],
    [data]
  )

  useEffect(() => {
    resetFilters()
    rvSelectedRates([0, 10000000])
    getSimBills()
  }, [getSimBills])

  const fetchMoreSimBills = () => {
    if (fetchMore && nextToken) {
      setIsLoadingMore(true)
      fetchMore({
        variables: {
          limit: SIM_BILLS_PER_PAGE,
          filter: filters,
          nextToken,
        },
      }).then(() => setIsLoadingMore(false))
    }
  }

  return {
    isInitiallyLoading: loading && !isLoadingMore,
    areFiltersInDefaultState,
    simBills,
    hasLoaded,
    hasErrorOccurred,
    fetchMoreSimBills,
    hasReachedEnd: nextToken === null,
  }
}
