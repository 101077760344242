import { createTranslations } from '@npco/utils-translations'

export const accountSelectTranslations = createTranslations({
  accountNotFound: 'Account report not found. Please try again.',
  allAccounts: 'All Accounts',
  failToLoadHeader: 'Accounts failed to load',
  failToLoadDescription:
    'Couldn’t fetch your accounts. Make sure you’re connected to the internet and try again.',
  failToLoadActionButton: 'Try Again',
  search: 'Search',
  searchAccount: 'Search Account',
})
