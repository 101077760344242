import { gql } from '@apollo/client'

export const UpdateInvoiceItem = gql`
  mutation UpdateInvoiceItem($entityUuid: ID!, $item: UpdateItemInput!) {
    updateItem(entityUuid: $entityUuid, input: $item) {
      id
      name
      description
      price
      taxes {
        enabled
        name
        percent
      }
    }
  }
`
