import { InputSelectComboBoxItem } from 'features/Contacts/Contacts.types'

import { GetBillersByCodeQueryResponse } from 'pages/Transfer/BPay/BPayTargetInput/useBPayTargetItems/GetBillersByCode.generated'

export type BillerSelectOption = InputSelectComboBoxItem & {
  icon: 'biller'
}

const mapSelectOptionItems = (
  billerData:
    | GetBillersByCodeQueryResponse['searchBpayBillers']['bpayBillers']
    | undefined
): BillerSelectOption[] | undefined => {
  return billerData?.map((biller) => {
    return {
      id: biller?.billerCode,
      value: biller?.billerCode,
      label: biller?.billerCode,
      subLabel: biller?.billerName,
      icon: 'biller',
    }
  })
}

export const getSelectOptionItems = (
  billerData:
    | GetBillersByCodeQueryResponse['searchBpayBillers']['bpayBillers']
    | undefined,
  searchString: string
): BillerSelectOption[] => {
  if (searchString.length >= 4) {
    return (
      mapSelectOptionItems(billerData)
        ?.filter((item) => item.value.startsWith(searchString))
        ?.slice(0, 10) ?? []
    )
  }
  return []
}
