import { useContext } from 'react'

import { CardsListLayoutContext } from '../../CardsListLayoutContext'

export const useHeaderButtonsVisibility = () => {
  const context = useContext(CardsListLayoutContext)

  if (!context) {
    throw new Error(
      'useHeaderButtonsVisibility must be used within CardsListLayoutContext'
    )
  }

  const { isHeaderButtonsVisible } = context

  return {
    isHeaderButtonsVisible,
  }
}
