import { gql } from '@apollo/client'

import { TransactionDetailsView } from '../../../TransactionDetailsView'

export const TransactionDetailsQuery = gql`
  query TransactionDetailsQuery(
    $debitCardTransactionUuid: ID!
    $entityUuid: ID!
  ) {
    getDebitCardTransactionV2(
      debitCardTransactionUuid: $debitCardTransactionUuid
      entityUuid: $entityUuid
    ) {
      __typename
      id
      ...TransactionDetailsViewDebitCardTransactionV2Fragment
    }
  }

  ${TransactionDetailsView.fragments.DebitCardTransactionV2}
`
