import { useTranslations } from '@npco/utils-translations'
import {
  AccountCreated,
  AnchorBasic,
  BodyDefault,
  Box,
  Flex,
  Heading,
} from '@npco/zeller-design-system'

import { translations } from './VenueListNotConnected.i18n'
import * as styled from './VenueListNotConnected.styled'

export const VenueListNotConnected = () => {
  const t = useTranslations(translations)

  return (
    <Flex flexDirection="column">
      <Box mb="32px">
        <AccountCreated size="large" />
      </Box>
      <styled.Message>
        <Heading.H3>{t('title')}</Heading.H3>
        <BodyDefault>
          {t('description', {
            linkSupport: (
              <AnchorBasic
                href={t('linkSupportHref')}
                target="_blank"
                rel="noreferrer"
              >
                {t('linkSupport')}
              </AnchorBasic>
            ),
          })}
        </BodyDefault>
      </styled.Message>
    </Flex>
  )
}
