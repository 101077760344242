import { gql } from '@apollo/client'
import { Ellipsize } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'

import { AccountAmountItemDebitCardAccountV2Fragment } from './AccountAmountItem.generated'

interface Props {
  account: AccountAmountItemDebitCardAccountV2Fragment
}

export const AccountAmountItem = ({ account }: Props) => (
  <Ellipsize data-testid="amount-item">
    {currencyFormatter(parseInt(account?.balance?.value ?? '0', 10))}
  </Ellipsize>
)

AccountAmountItem.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountAmountItemDebitCardAccountV2Fragment on DebitCardAccountV2 {
      balance {
        value
      }
    }
  `,
}
