import {
  AnnouncementModal,
  ModalBasic,
  ScrollWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SuccessAnnouncementModal = styled(AnnouncementModal)`
  ${ModalBasic.SecondaryButton} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const FormWrapper = styled.form`
  height: 100%;

  // Workaround required as InvoiceOnboarding is adding a overflow: initial;
  // which is preventing scrolling
  ${ScrollWrapper} {
    overflow: scroll !important;
  }
`
