import { SelectSize, SelectStandard } from '@npco/zeller-design-system'
import { PaymentTerms } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'

import { translate } from 'utils/translations'

import { InvoicePaymentTermsSelectedItem } from '../../../InvoiceFormAccordions.types'
import { useInvoicePaymentTerms } from '../hooks/useInvoicePaymentTerms'

const SELECT_POPPER_MAX_HEIGHT = '352px'

export const translations = {
  invoicePaymentTermsFieldLabel: translate(
    'page.invoice.formSections.schedule.paymentTermsLabel'
  ),
  paymentTermsOptionImmediate: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionImmediate'
  ),
  paymentTermsOptionDays7: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionDays7'
  ),
  paymentTermsOptionDays14: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionDays14'
  ),
  paymentTermsOptionDays30: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionDays30'
  ),
  paymentTermsOptionDays60: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionDays60'
  ),
  paymentTermsOptionDays90: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionDays90'
  ),
  paymentTermsOptionCustom: translate(
    'page.invoice.formSections.schedule.paymentTermsOptionCustom'
  ),
}

const paymentTermsItems: InvoicePaymentTermsSelectedItem[] = Object.entries(
  PaymentTerms
).map(([key, paymentTerm]) => {
  return {
    value: paymentTerm,
    label:
      translations[`paymentTermsOption${key}` as keyof typeof translations],
  }
})

export const InvoicePaymentTerms = () => {
  const { handleChange, selectedItem, updatedPaymentTermItems } =
    useInvoicePaymentTerms(paymentTermsItems)

  return (
    <SelectStandard
      maxHeight={SELECT_POPPER_MAX_HEIGHT}
      mobileLabel={translations.invoicePaymentTermsFieldLabel}
      items={updatedPaymentTermItems}
      label={translations.invoicePaymentTermsFieldLabel}
      onChange={handleChange}
      selectedItem={selectedItem}
      size={SelectSize.Small}
    />
  )
}
