import { useLayoutEffect, useRef } from 'react'
import { Box, Flex } from '@npco/zeller-design-system'
import lottie from 'lottie-web/build/player/lottie_light'

interface StatusProps {
  size?: string
  icon: any
  className?: string
  dataTestId?: string
}

export const Status = ({ size, icon, className, dataTestId }: StatusProps) => {
  const boxRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const animationItem = lottie.loadAnimation({
      container: boxRef.current as Element,
      animationData: icon,
      loop: false,
    })

    return () => {
      animationItem.destroy()
    }
  }, [icon])

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className={className}
      data-testid={dataTestId}
    >
      <Box
        width={size || '5rem'}
        height={size || '5rem'}
        ref={boxRef}
        role="status"
      />
    </Flex>
  )
}
