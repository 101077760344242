import { ReactNode } from 'react'
import {
  BoxProps,
  CellSize,
  TableCellByline,
  TableCellSize,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

interface CardBalanceCellBaseProps {
  content: ReactNode
  byline?: ReactNode
  isLoading?: boolean
  cellId: string
  skeletonPropWidth: BoxProps['width']
  cellSize?: CellSize
}
export const CardBalanceCellBase = ({
  content,
  byline,
  isLoading,
  cellId,
  skeletonPropWidth,
  cellSize,
}: CardBalanceCellBaseProps) => {
  const isMobile = useIsMobileResolution()
  return (
    <TableCellByline
      dataTestId={cellId}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={isLoading}
      justifyContent="flex-end"
      key={cellId}
      skeletonProps={{ width: skeletonPropWidth }}
      label={content}
      byline={byline}
      size={TableCellSize.SMALL}
      cellTextSize={isMobile ? 'Small' : 'Medium'}
      cellSize={cellSize}
    />
  )
}
