import { useEffect } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { LoadScriptProps, useJsApiLoader } from '@react-google-maps/api'

import { GOOGLE_API_KEY } from 'const/envs'

const libraries: LoadScriptProps['libraries'] = ['places']

interface UseInitGoogleScriptLoadProps {
  onLoad?: () => void
}

export const useInitGoogleScriptLoad = ({
  onLoad,
}: UseInitGoogleScriptLoadProps = {}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-api-script',
    googleMapsApiKey: `${GOOGLE_API_KEY}`,
    libraries,
  })

  useEffect(() => {
    if (!loadError) {
      return
    }

    ErrorLogger.report(
      '[Core] Error when loading google maps script',
      loadError
    )
  }, [loadError])

  useEffect(() => {
    if (!isLoaded || !onLoad) {
      return
    }

    onLoad()
  }, [isLoaded, onLoad])

  return { isLoaded, loadError }
}
