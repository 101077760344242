import styled, { css } from 'styled-components'

export const DotWrapper = styled.div`
  position: relative;
  width: 0;
  height: 0;
`

export const NotificationsDot = styled.div<{
  $isFolded: boolean
  $isMobile?: boolean
  $newDot?: boolean
}>`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: ${({ theme, $newDot }) =>
    $newDot ? theme.colors.BRIGHT_ORANGE : theme.colors.RED_1000};
  position: absolute;
  bottom: 6px;
  right: -6px;
  visibility: visible;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      bottom: 4px;
      right: -4px;
      outline: 2px solid ${({ theme }) => theme.colors.WHITE};
    `}

  ${({ $isFolded }) =>
    !$isFolded &&
    css`
      visibility: hidden;
      opacity: 0;
      width: 0;
    `}

  animation-name: pulse;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      transform: scale(0.9);
      opacity: 0;
    }
    80% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`
