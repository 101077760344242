import { gql } from '@apollo/client'
import { AccountStatementType } from '@npco/mp-gql-types'

import { UNEXPECTED_ERROR } from 'types/errors'

import { AccountStatementsError } from '../AccountStatementsError'
import { AccountStatementsList } from '../AccountStatementsList'
import { AccountStatementsWarning } from '../AccountStatementsList/AccountStatementsWarning'
import { AccountStatementsListSkeleton } from '../AccountStatementsListSkeleton'
import { AccountStatementsViewBankingAccountStatementFragment } from './AccountStatementsView.generated'

interface AccountStatementsViewProps {
  isLoading: boolean
  hasError: boolean
  accountStatements: AccountStatementsViewBankingAccountStatementFragment[]
  statementType: AccountStatementType
  refetchStatementDownloadLink: (
    startDate: any
  ) => Promise<{ downloadLink: string } | typeof UNEXPECTED_ERROR>
  accountId: string
  onRetry: () => void
}

export const AccountStatementsView = ({
  isLoading,
  hasError,
  accountStatements,
  statementType,
  refetchStatementDownloadLink,
  accountId,
  onRetry,
}: AccountStatementsViewProps) => {
  if (isLoading && !accountStatements.length) {
    return (
      <>
        <AccountStatementsWarning />
        <AccountStatementsListSkeleton />
      </>
    )
  }

  if (hasError) {
    return <AccountStatementsError onRetry={onRetry} />
  }

  return (
    <AccountStatementsList
      accountStatements={accountStatements}
      statementType={statementType}
      refetchStatementDownloadLink={refetchStatementDownloadLink}
      accountId={accountId}
    />
  )
}

AccountStatementsView.fragments = {
  BankingAccountStatement: gql`
    fragment AccountStatementsViewBankingAccountStatementFragment on BankingAccountStatement {
      ...AccountStatementsListBankingAccountStatementFragment
    }

    ${AccountStatementsList.fragments.BankingAccountStatement}
  `,
}
