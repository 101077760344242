import { Flex } from '@npco/zeller-design-system'

interface Props {
  text: React.ReactNode
  icon: React.ReactNode
}

export const SelectCompactItemContentWithIcon = ({ text, icon }: Props) => {
  return (
    <Flex alignItems="center">
      <Flex alignItems="center" mr="1rem">
        {icon}
      </Flex>
      {text}
    </Flex>
  )
}
