import { gql, type TypedDocumentNode } from '@apollo/client'

export type UseCardBalanceDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    balance: { __typename?: 'Money'; value: string } | null
  }
}

export const UseCardBalanceDebitCardV2FragmentDoc = gql`
  fragment UseCardBalanceDebitCardV2Fragment on DebitCardV2 {
    debitCardAccount {
      __typename
      id
      balance {
        value
      }
    }
  }
` as unknown as TypedDocumentNode<UseCardBalanceDebitCardV2Fragment, undefined>
