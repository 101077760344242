import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetCorporateCardsList } from './graphql/getCorporateCardsList'
import {
  GetCorporateCardsListQueryResponse,
  GetCorporateCardsListQueryVariables,
} from './graphql/getCorporateCardsList.generated'
import { useToggleHeaderButtons } from './useToggleHeaderButtons'

type CorporateCard = NonNullable<
  GetCorporateCardsListQueryResponse['getDebitCardsV2']['cards'][number]
>

const getFilteredCorporateCards = (cards: (CorporateCard | null)[]) => {
  return cards.filter((card): card is CorporateCard => !!card)
}

export const useQueryCorporateCards = () => {
  const entityUuid = useSelectedEntityUuid()
  const {
    loading,
    data,
    error,
    networkStatus,
    refetch: refetchCards,
  } = useQuery<
    GetCorporateCardsListQueryResponse,
    GetCorporateCardsListQueryVariables
  >(GetCorporateCardsList, {
    variables: {
      entityUuid,
      filter: {
        productType: {
          eq: DebitCardProductType.EXPENSE,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  })

  const cards = useMemo(
    () => getFilteredCorporateCards(data?.getDebitCardsV2?.cards ?? []),
    [data]
  )

  useToggleHeaderButtons({ cards })

  const entity = useMemo(() => data?.getEntity ?? null, [data?.getEntity])

  return {
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    cards,
    entity,
    error,
    refetchCards,
  }
}
