import 'tippy.js/dist/tippy.css'

import Tippy from '@tippyjs/react'

import { CardSize } from 'types/cards'
import { shared } from 'translations'

import {
  StyledLockContainer,
  StyledPadlock,
  StyledTooltipTitle,
} from '../ZellerCard.styled'

interface Props {
  size: CardSize
  title?: string
}

export const LockTooltip = ({ size, title }: Props) => {
  return (
    <StyledLockContainer className={size} data-testid="lock-container">
      <Tippy
        placement="top"
        offset={[0, 26]}
        content={
          <StyledTooltipTitle>
            {title || shared.cardStatus.locked}
          </StyledTooltipTitle>
        }
      >
        <StyledPadlock />
      </Tippy>
    </StyledLockContainer>
  )
}
