import { useCallback } from 'react'
import {
  AmountColumnEnum,
  DateColumnEnum,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import {
  AmountLabel,
  DateLabel,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import {
  BottomDrawerContent,
  BottomDrawerOption,
  BottomDrawerWrapper,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileBottomDrawer/InvoiceTableMobileBottomDrawer.styled'

export interface InvoiceTableMobileBottomDrawerProps {
  isOpen: boolean
  type: 'amount' | 'date'
  onSelectedDate: (arg: DateColumnEnum) => void
  onSelectedAmount: (arg: AmountColumnEnum) => void
}

export const InvoiceTableMobileBottomDrawer = ({
  isOpen,
  onSelectedDate,
  onSelectedAmount,
  type,
}: InvoiceTableMobileBottomDrawerProps) => {
  const handleOnDateClick = useCallback(
    (key: DateColumnEnum) => () => {
      onSelectedDate(key)
    },
    [onSelectedDate]
  )

  const handleOnAmountClick = useCallback(
    (key: AmountColumnEnum) => () => {
      onSelectedAmount(key)
    },
    [onSelectedAmount]
  )

  const dateContent = () => {
    return Object.keys(DateLabel).map((key) => (
      <BottomDrawerOption
        key={key}
        onClick={handleOnDateClick(key as DateColumnEnum)}
      >
        {DateLabel[key as DateColumnEnum]}
      </BottomDrawerOption>
    ))
  }

  const amountContent = () => {
    return Object.keys(AmountLabel).map((key) => (
      <BottomDrawerOption
        key={key}
        onClick={handleOnAmountClick(key as AmountColumnEnum)}
      >
        {AmountLabel[key as AmountColumnEnum]}
      </BottomDrawerOption>
    ))
  }

  return (
    <BottomDrawerWrapper
      isOpen={isOpen}
      overlayClassName="animated-drawer-overlay"
      testId="invoices-mobile-bottom-drawer"
    >
      <BottomDrawerContent>
        {type === 'date' && dateContent()}
        {type === 'amount' && amountContent()}
      </BottomDrawerContent>
    </BottomDrawerWrapper>
  )
}
