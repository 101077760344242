import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledDropdownWrapper } from 'components/Shared'

export const StyledSearchWrapper = styled.div`
  padding: 0 16px 6px;
`

export const StyledList = styled.ul`
  max-height: 19rem;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  box-sizing: border-box;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    max-height: 15rem;
  }
`

export const StyledDropdown = styled(StyledDropdownWrapper)`
  width: 100%;
`
