import { Flex } from '@npco/zeller-design-system'

import { EmailInfo } from './components/EmailInfo'
import { MobilePhoneInfo } from './components/MobilePhoneInfo'
import { SMSInfo } from './components/SMSInfo'

export const ContactSupport = () => {
  return (
    <Flex flexDirection="column" gap="16px">
      <MobilePhoneInfo />
      <EmailInfo />
      <SMSInfo />
    </Flex>
  )
}
