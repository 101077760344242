import { useCallback, useMemo } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CardFormatOption,
  CardTypeOption,
  CreateSelectCardDesignState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

import {
  DEBIT_CARD_COLOURS,
  EXPENSE_CARD_COLOURS,
} from '../SelectCardDesignForm.constants'
import { SelectCardDesignFormValues } from '../SelectCardDesignForm.types'

export const useSelectCardDesignForm = () => {
  const { state } = useAddCardState<CreateSelectCardDesignState>()
  const { goToAddCardStage } = useGoToAddCardStage()

  const checkStateIsDefined = useCallback(
    (
      stateToCheck: CreateSelectCardDesignState | null
    ): stateToCheck is CreateSelectCardDesignState => {
      if (stateToCheck === null) {
        const submitActionErrorMessage = translate(
          'page.addCardModal.selectCardDesign.submitActionErrorMessage'
        )
        ErrorLogger.report(
          '[Banking] Select Card design',
          submitActionErrorMessage
        )
        showErrorToast()
        return false
      }

      return true
    },
    []
  )

  const goBackToPreviousStage = useCallback(() => {
    if (!checkStateIsDefined(state)) {
      return
    }

    if (state.cardType === CardTypeOption.ExpenseCard) {
      goToAddCardStage({
        ...state,
        stage: AddCardStage.CreateSpendControlsStage,
        cardType: state.cardType,
      })
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSetCardSettingsStage,
    })
  }, [goToAddCardStage, state, checkStateIsDefined])

  const goToReviewCardStage = useCallback(
    (
      { colour }: SelectCardDesignFormValues,
      selectCardDesignState: CreateSelectCardDesignState
    ) => {
      goToAddCardStage({
        ...selectCardDesignState,
        stage: AddCardStage.CreateReviewCardStage,
        colour,
      })
    },
    [goToAddCardStage]
  )

  const goToSelectDeliveryAddressStage = useCallback(
    (
      { colour }: SelectCardDesignFormValues,
      selectCardDesignState: CreateSelectCardDesignState
    ) => {
      goToAddCardStage({
        ...selectCardDesignState,
        stage: AddCardStage.CreateSelectDeliveryAddressStage,
        colour,
      })
    },
    [goToAddCardStage]
  )

  const submitCardDesign = useCallback(
    (values) => {
      if (!checkStateIsDefined(state)) {
        return
      }

      if (state.cardFormat === CardFormatOption.Virtual) {
        goToReviewCardStage(values, state)
      } else {
        goToSelectDeliveryAddressStage(values, state)
      }
    },
    [
      state,
      checkStateIsDefined,
      goToReviewCardStage,
      goToSelectDeliveryAddressStage,
    ]
  )

  const initialColour = useMemo(() => {
    if (state?.cardType === CardTypeOption.DebitCard) {
      return state?.colour && DEBIT_CARD_COLOURS.includes(state.colour)
        ? state.colour
        : DEBIT_CARD_COLOURS[0]
    }

    return state?.colour && EXPENSE_CARD_COLOURS.includes(state.colour)
      ? state.colour
      : EXPENSE_CARD_COLOURS[0]
  }, [state?.cardType, state?.colour])

  const colourTileConfig = useMemo(() => {
    if (state?.cardType === CardTypeOption.DebitCard) {
      return {
        isHoverable: false,
        colours: DEBIT_CARD_COLOURS,
      }
    }

    return {
      isHoverable: true,
      colours: EXPENSE_CARD_COLOURS,
    }
  }, [state?.cardType])

  return {
    goBackToPreviousStage,
    submitCardDesign,
    initialColour,
    colourTileConfig,
  }
}
