import { SelectStyle } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const StyledTopSectionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};
`

export const StyledOptionsList = styled.ul<{
  menuStyle?: SelectStyle
  isEmpty: boolean
  $height?: string
}>`
  align-self: stretch;
  list-style: none;
  outline: none;
  margin: 0;
  box-sizing: border-box;
  overflow: scroll;
  height: ${({ $height }) => $height ?? '100%'};

  padding: ${({ menuStyle }) => {
    switch (menuStyle) {
      case SelectStyle.Standard:
        return '0.5rem'
      default:
        return '0'
    }
  }};

  &::-webkit-scrollbar {
    height: 11px;
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid #0000;
    background-clip: padding-box;
    border-radius: 999px;
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`
