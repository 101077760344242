import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  headingAppearance: 'Appearance',
  headingMessaging: 'Messaging',
  messagingLabel: 'Default Message',
  messagingPlaceholder: 'Thank you for your business.',
  messagingHelperText:
    'This is the default message that will appear on your invoices. It can be customised when you are creating an invoice.',
  termsAndConditionsLabel: 'Terms & Conditions',
  formSaveErrorNotification:
    'Unable to save settings. Please review and try again.',
  termsAndConditionsError:
    'Maximum input exceeded. Please shorten the Terms & Conditions.',
  optionLogo: 'Logo',
  uploadInProgressErrorNotification:
    'Upload in progress. Please try again in a few seconds.',
  optionAccentColour: 'Accent Colour',
  templateClassic: 'Classic',
  templateStrong: 'Strong',
  templateMinimal: 'Minimal',
  preview: 'Preview',
  previewErrorNotification: 'Unable to load preview. Please try again.',
  attentionToFirstName: 'Customer',
  attentionToLastName: 'Name',
  customerBusinessName: 'Customer Business Name',
  businessName: 'Your Business Name',
  businessPhone: '0410 235 789',
  businessEmail: 'info@yourbusiness.com.au',
  businessNumber: 'ABN 12 123 45 6789',
  businessWebsite: 'yourwebsite.com.au',
  referenceNumber: 'ZLR123',
  invoiceTitle: 'Invoice Title',
  itemName: 'Item / Service',
  itemDescription: 'This is where your description will go (Optional)',
  successNotification: 'Settings updated successfully.',
  errorNotification: 'Settings could not be updated. Please try again.',
})
