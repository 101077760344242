import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { isEqual, omit, pick } from 'lodash-es'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { InvoiceSettingsCustomisationFormFields } from '../SettingsCustomisation.types'
import { isValidLogo } from '../SettingsCustomisation.utils'
import { UseSettingsCustomisationReturnType } from './useSettingsCustomisation'
import { useUpdateSettingsCustomisation } from './useUpdateSettingsCustomisation'

const DEBOUNCE_DURATION = 800

// NOTE: changing these values shouldn't update the preview
const NON_DEBOUNCED_FIELDS = ['accentColours', 'logos'] as const

const omitNonDebouncedFields = (
  values?: InvoiceSettingsCustomisationFormFields
) => omit(values, NON_DEBOUNCED_FIELDS)
const pickNonDebouncedFields = (
  values?: InvoiceSettingsCustomisationFormFields
) => pick(values, NON_DEBOUNCED_FIELDS)

// NOTE: trim string values to prevent unnecessary updates
const trimStringValues = <
  T extends InvoiceSettingsCustomisationFormFields | DebouncedPreviewValues
>(
  values: T
): T => ({
  ...values,
  defaultMessage: values?.defaultMessage.trim(),
  termsAndConditions: values?.termsAndConditions.trim(),
})
const isTrimmedValuesEqual = (
  values1: DebouncedPreviewValues,
  values2: DebouncedPreviewValues
) => isEqual(trimStringValues(values1), trimStringValues(values2))

type DebouncedPreviewValues = Omit<
  InvoiceSettingsCustomisationFormFields,
  (typeof NON_DEBOUNCED_FIELDS)[number]
>

type UseSettingsCustomisationFormDebounceProps = {
  debounceDuration?: number
  onError: () => void
  onInvalidFormSave: () => void
  onInvalidLogoSave: () => void
  setPreviewValues: UseSettingsCustomisationReturnType['setPreviewValues']
  setWillLoadPreview: (willLoad: boolean) => void
}

export const useSettingsCustomisationFormDebounce = ({
  debounceDuration = DEBOUNCE_DURATION,
  onError,
  onInvalidFormSave,
  onInvalidLogoSave,
  setPreviewValues,
  setWillLoadPreview,
}: UseSettingsCustomisationFormDebounceProps) => {
  const [values$] = useState(new Subject<DebouncedPreviewValues>())
  const {
    values,
    submitForm: formikSubmitForm,
    resetForm: formikResetForm,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    isValid: formikIsValid,
  } = useFormikContext<InvoiceSettingsCustomisationFormFields>()

  const { updateSettingsCustomisation } = useUpdateSettingsCustomisation({
    onError,
  })

  const initialValuesRef = useRef(values)
  const previousValuesRef = useRef(values)
  const isValidRef = useRef(formikIsValid)
  const hasUploadedLogoRef = useRef(false)
  const isLoadingLogo = useMemo(
    () => Boolean(values.logos.find((logo) => !isValidLogo(logo))),
    [values.logos]
  )

  useEffect(() => {
    if (
      isValidRef.current &&
      !isTrimmedValuesEqual(values, previousValuesRef.current) &&
      isEqual(
        pickNonDebouncedFields(values),
        pickNonDebouncedFields(previousValuesRef.current)
      )
    ) {
      // NOTE: the values changed and wasn't a non-debounced field, so a preview will be loaded
      setWillLoadPreview(true)
    }

    if (
      !hasUploadedLogoRef.current &&
      values.logos.some((logo) => !isValidLogo(logo))
    ) {
      hasUploadedLogoRef.current = true
    }

    values$.next(omitNonDebouncedFields(values))
    previousValuesRef.current = values
    isValidRef.current = formikIsValid
  }, [values$, values, setWillLoadPreview, formikIsValid])

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      values$
        .pipe(
          debounceTime(debounceDuration),
          distinctUntilChanged(isTrimmedValuesEqual)
        )
        .subscribe((nextValue) => {
          if (!isValidRef.current) {
            return
          }
          setPreviewValues((prevValues) => {
            const values = {
              logos: [],
              accentColours: [],
              ...prevValues,
              ...nextValue,
            }
            if (isEqual(values, prevValues)) {
              return prevValues
            }
            return values
          })
        })
    )

    subscription.add(
      values$.pipe(debounceTime(debounceDuration)).subscribe(() => {
        // NOTE: this separate subscription ensures willLoadPreview is set to false
        // even if the values aren't distinct
        setWillLoadPreview(false)
      })
    )

    setWillLoadPreview(false)
    return () => subscription.unsubscribe()
  }, [debounceDuration, setPreviewValues, setWillLoadPreview, values$])

  const resetForm = useCallback(() => {
    if (isLoadingLogo) {
      onInvalidLogoSave()
      return
    }

    formikResetForm({
      values: initialValuesRef.current,
    })

    if (hasUploadedLogoRef.current) {
      updateSettingsCustomisation(initialValuesRef.current)
      hasUploadedLogoRef.current = false
    }
  }, [
    formikResetForm,
    isLoadingLogo,
    onInvalidLogoSave,
    updateSettingsCustomisation,
  ])

  const submitForm = useCallback(async () => {
    if (isLoadingLogo) {
      onInvalidLogoSave()
      return
    }

    if (!isValidRef.current) {
      onInvalidFormSave()
      return
    }

    initialValuesRef.current = values
    await formikSubmitForm()
  }, [
    isLoadingLogo,
    values,
    formikSubmitForm,
    onInvalidLogoSave,
    onInvalidFormSave,
  ])

  return {
    resetForm,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    submitForm,
    values,
  }
}
