import { Flex } from '@npco/zeller-design-system'

import { component } from 'translations'

import {
  StyledNoItemsH3,
  StyledNoItemsSpan,
} from '../../LabelSelectCombobox.styled'

interface LabelSelectComboboxNoItemsPlaceholderProps {
  hasItems: boolean
}

export const LabelSelectNoItemsPlaceholder = ({
  hasItems,
}: LabelSelectComboboxNoItemsPlaceholderProps) => {
  if (hasItems) {
    return null
  }

  return (
    <Flex flexDirection="column" alignItems="center" padding="1.5rem">
      <StyledNoItemsH3>
        {component.labelSelectCombobox.noItemsHeader}
      </StyledNoItemsH3>
      <StyledNoItemsSpan>
        {component.labelSelectCombobox.noItemsDescription}
      </StyledNoItemsSpan>
    </Flex>
  )
}
