import { useMemo } from 'react'
import { gql } from '@apollo/client'
import {
  COLOR,
  RadioButtonUnselectedIcon,
  ShopIcon,
} from '@npco/zeller-design-system'

import { formatEntityAddress } from 'utils/address'
import { translate } from 'utils/translations'
import { BusinessAddressOptionFragment } from 'types/gql-types/BusinessAddressOptionFragment'
import { RadioCardSkeleton } from 'pages/GlobalModals/components/RadioCardSkeleton'
import {
  StyledRadioCard,
  StyledRadioCardIcon,
} from 'pages/GlobalModals/GlobalModals.styled'

import { AddressOption } from '../../../DeliveryAddress.types'

const getRadioCardIcon = () => (
  <StyledRadioCardIcon color={COLOR.BLUE_1000}>
    <ShopIcon />
  </StyledRadioCardIcon>
)

interface BusinessAddressOptionProps {
  onChange: () => void
  isChecked: boolean
  name: string
  businessAddress?: BusinessAddressOptionFragment
  isLoading?: boolean
}

export const BusinessAddressOption = ({
  onChange,
  isChecked,
  name,
  businessAddress,
  isLoading,
}: BusinessAddressOptionProps) => {
  const desc = useMemo(() => {
    if (businessAddress?.businessAddress) {
      return formatEntityAddress(businessAddress.businessAddress)
    }

    return ''
  }, [businessAddress])

  if (isLoading) {
    return <RadioCardSkeleton />
  }

  return (
    <StyledRadioCard
      id={AddressOption.BusinessAddress}
      value={AddressOption.BusinessAddress}
      title={translate('page.deliveryAddressForm.businessAddressOptionTitle')}
      desc={desc}
      icon={getRadioCardIcon}
      onChange={onChange}
      checked={isChecked}
      name={name}
      unselectedIcon={RadioButtonUnselectedIcon}
    />
  )
}

BusinessAddressOption.fragments = {
  Entity: gql`
    fragment BusinessAddressOptionFragment on Entity {
      businessAddress {
        country
        postcode
        state
        street1
        street2
        suburb
      }
    }
  `,
}
