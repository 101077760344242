import { useState } from 'react'
import { Formik } from 'formik'

import { BankAccountFieldsProps } from 'forms/formViews/BankAccountFields/BankAccountFields.types'

import { AddThirdPartyAccountConfirm } from './AddThirdPartyAccountConfirm/AddThirdPartyAccountConfirm'
import { AddThirdPartyAccountForm } from './AddThirdPartyAccountForm/AddThirdPartyAccountForm'

interface NewAccountModalsProps {
  onSubmit: (values: BankAccountFieldsProps) => void
  onCancel: () => void
  isLoadingAddThirdPartyAccount: boolean
}

export const AddAccountAndConfirmModals = ({
  onSubmit,
  onCancel,
  isLoadingAddThirdPartyAccount,
}: NewAccountModalsProps) => {
  const [showConfirm, setShowConfirm] = useState(false)
  return (
    <Formik<BankAccountFieldsProps>
      initialValues={{}}
      onSubmit={(account) => onSubmit(account)}
      validateOnChange
      component={({ isValid, submitForm, resetForm, values, touched }) => {
        if (showConfirm) {
          return (
            <AddThirdPartyAccountConfirm
              values={values}
              onSubmitNewAccount={async () => {
                await submitForm()
                resetForm()
              }}
              isLoadingAddThirdPartyAccount={isLoadingAddThirdPartyAccount}
              onBack={() => {
                setShowConfirm(false)
              }}
              isOpen
            />
          )
        }
        return (
          <AddThirdPartyAccountForm
            isOpen={!showConfirm}
            onContinue={() => {
              setShowConfirm(true)
            }}
            onCancel={() => {
              onCancel()
              resetForm()
            }}
            touched={touched}
            isValid={isValid}
          />
        )
      }}
    />
  )
}
