import { memo } from 'react'

import { StyledLoaderList } from './LoaderList.styled'
import { LoadingRow } from './LoaderRow'

interface LoadingListProps {
  length?: number
  showMobileStyle?: boolean
}

const getPreConfiguredRows = (showMobileStyle = false) => [
  <LoadingRow
    key="loading-row-1"
    colOneWidth={180}
    colTwoWidth={80}
    colThreeWidth={70}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-2"
    colOneWidth={200}
    colTwoWidth={100}
    colThreeWidth={90}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-3"
    colOneWidth={190}
    colTwoWidth={90}
    colThreeWidth={80}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-4"
    colOneWidth={170}
    colTwoWidth={70}
    colThreeWidth={60}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-5"
    colOneWidth={160}
    colTwoWidth={60}
    colThreeWidth={50}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-6"
    colOneWidth={190}
    colTwoWidth={90}
    colThreeWidth={80}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-7"
    colOneWidth={200}
    colTwoWidth={100}
    colThreeWidth={90}
    showMobileStyle={showMobileStyle}
  />,
  <LoadingRow
    key="loading-row-8"
    colOneWidth={180}
    colTwoWidth={80}
    colThreeWidth={70}
    showMobileStyle={showMobileStyle}
  />,
]

export const LoaderList = memo(
  ({ length = 8, showMobileStyle = false }: LoadingListProps) => {
    const rows = getPreConfiguredRows(showMobileStyle).slice(0, length)
    return <StyledLoaderList data-testid="loader-list">{rows}</StyledLoaderList>
  }
)
