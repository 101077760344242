import { z } from 'zod'

type SavingsAccountLearnMoreStage =
  | 'intro'
  | 'interest-earning'
  | 'savings'
  | 'ready-to-go'

export type SavingsAccountLearnMoreState = {
  stage: SavingsAccountLearnMoreStage
  interestRate: string
  canCreateAccount: boolean | null
}

export const SavingsAccountLearnMoreLocationSchema = z.object({
  SavingsAccountLearnMoreModal: z.object({
    stage: z.union([
      z.literal('intro'),
      z.literal('interest-earning'),
      z.literal('savings'),
      z.literal('ready-to-go'),
    ]),
    interestRate: z.string(),
    canCreateAccount: z.union([z.boolean(), z.null()]),
  }),
})
