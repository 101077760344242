import { StyledTableEffect } from '@npco/ui-table'
import { Box, Ellipsize, Flex, H4 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const ContactItemContainer = styled.div`
  align-items: center;
  border-radius: none;
  cursor: pointer;
  display: flex;
  padding: 0.75rem 0.375rem;

  ${StyledTableEffect}
`

export const FormExtensionContainer = styled.div`
  ${Box} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Header = styled(H4)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0;
`

export const RowContainer = styled(Flex)<{ $mb?: string | number }>`
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ $mb }) => $mb ?? '24px'};

  > ${Flex} {
    flex: 1 0;
    margin-bottom: 1.5rem;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      margin-bottom: 0;
      margin-right: 1.25rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
  }
`

export const ContactsContentWrapper = styled(Flex)`
  flex-grow: 1;
  padding-left: 0.75rem;
  min-width: 0;
`

export const ContactsListItemWrapper = styled(Ellipsize)`
  padding: 0 0.8rem 0 0;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding: 0;
    width: 22.5rem;
  }
`
