import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { OutstandingExpensesToggleDebitCardV2FragmentDoc } from '../OutstandingExpensesToggle.generated'

const defaultOptions = {} as const
export type CardOutstandingQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  cardId: Types.Scalars['ID']['input']
}>

export type CardOutstandingQueryResponse = {
  __typename?: 'Query'
  getDebitCardV2: {
    __typename: 'DebitCardV2'
    id: string
    outstandingTransactions: number | null
  }
}

export const CardOutstandingQuery = gql`
  query CardOutstandingQuery($entityUuid: ID!, $cardId: ID!) {
    getDebitCardV2(entityUuid: $entityUuid, cardId: $cardId) {
      __typename
      ...OutstandingExpensesToggleDebitCardV2Fragment
    }
  }
  ${OutstandingExpensesToggleDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardOutstandingQueryResponse,
  CardOutstandingQueryVariables
>

/**
 * __useCardOutstandingQuery__
 *
 * To run a query within a React component, call `useCardOutstandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardOutstandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardOutstandingQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useCardOutstandingQuery(
  baseOptions: Apollo.QueryHookOptions<
    CardOutstandingQueryResponse,
    CardOutstandingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CardOutstandingQueryResponse,
    CardOutstandingQueryVariables
  >(CardOutstandingQuery, options)
}
export function useCardOutstandingQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CardOutstandingQueryResponse,
    CardOutstandingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CardOutstandingQueryResponse,
    CardOutstandingQueryVariables
  >(CardOutstandingQuery, options)
}
export type CardOutstandingQueryHookResult = ReturnType<
  typeof useCardOutstandingQuery
>
export type CardOutstandingQueryLazyQueryHookResult = ReturnType<
  typeof useCardOutstandingQueryLazyQuery
>
export type CardOutstandingQueryQueryResult = Apollo.QueryResult<
  CardOutstandingQueryResponse,
  CardOutstandingQueryVariables
>
