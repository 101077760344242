interface WithId {
  id: string
}

export const getById = <T extends WithId>({
  list,
  isloading,
  id,
}: {
  list?: T[]
  isloading: boolean
  id: string
}) => {
  if (isloading) {
    return 'loading'
  }

  return list?.find((item) => item.id === id) ?? null
}
