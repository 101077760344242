import { FeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { COLOR } from '@npco/zeller-design-system'

import T1ShellBlack from 'assets/images/t1-shell-black.png'
import T2ShellBlack from 'assets/images/t2-shell-black.png'
import { GetSite_getSite_screensaver_logos as Logo } from 'types/gql-types/GetSite'
import { SiteCache } from 'types/site'

import { SiteAppearanceFormValues } from './SiteAppearanceForm/SiteAppearanceForm.types'
import { TerminalModel } from './TerminalPreview/TerminalPreview.types'

export const getScreensaverInitialValues = (
  site: SiteCache | null,
  flags: FeatureFlags
) => {
  if (!site?.screensaver) {
    return {
      logos: [] as Logo[],
      customColours: [] as string[],
      selectedColour: COLOR.BLACK,
      selectedLogo: '',
      selectedLogoUrl: '',
      logosToUpload: [] as SiteAppearanceFormValues['logosToUpload'],
      selectedTerminalModel: flags.T2Uplift
        ? TerminalModel.T2
        : TerminalModel.T1,
      selectedTerminalUrl: flags.T2Uplift ? T2ShellBlack : T1ShellBlack,
    }
  }
  return {
    logos: site.screensaver.logos,
    customColours: site.screensaver.customColours,
    selectedColour: site.screensaver.primaryColour,
    selectedLogo: site.screensaver.primaryLogoUuid,
    selectedLogoUrl: site.screensaver.primaryLogoUrl,
    logosToUpload: [] as SiteAppearanceFormValues['logosToUpload'],
    selectedTerminalModel: flags.T2Uplift ? TerminalModel.T2 : TerminalModel.T1,
    selectedTerminalUrl: flags.T2Uplift ? T2ShellBlack : T1ShellBlack,
  }
}

// https://en.wikipedia.org/wiki/YIQ
// caculate Y
export const getInensity = (hexColor: string) => {
  const r = parseInt(hexColor.substring(1, 3), 16)
  const g = parseInt(hexColor.substring(3, 5), 16)
  const b = parseInt(hexColor.substring(5, 7), 16)
  return (r * 299 + g * 587 + b * 114) / 1000
}

export const getLogoColor = (hexcolor: string) => {
  return getInensity(hexcolor) >= 128 ? COLOR.BLACK : COLOR.WHITE
}

export const getStatusBarColor = (hexcolor: string) => {
  return getInensity(hexcolor) >= 128 ? COLOR.GREY_550 : COLOR.WHITE
}
