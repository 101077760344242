import { useCallback, useEffect, useState } from 'react'
import { ReactiveVar, useReactiveVar } from '@apollo/client'

interface WithId {
  id: string
}

interface UseCacheResultProps<T, V> {
  resourceWithId: T
  rvResource: ReactiveVar<Record<string, V>>
  skip?: boolean
  fetchNewValue?: () => Promise<V>
  onSuccess?: () => void
  onError?: (error: Error) => void
}

export const useCacheValue = <T extends WithId, V>({
  skip = true,
  resourceWithId,
  rvResource,
  fetchNewValue,
  onSuccess,
  onError,
}: UseCacheResultProps<T, V>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const cacheData = useReactiveVar(rvResource)

  const updateCache = useCallback(
    (value: V) => {
      rvResource({
        ...rvResource(),
        [resourceWithId.id]: value,
      })
    },
    [resourceWithId.id, rvResource]
  )

  const getNewValueAndUpdateCache = useCallback(async () => {
    if (!fetchNewValue) {
      return
    }

    try {
      setIsLoading(true)
      const value: V = await fetchNewValue()
      updateCache(value)
      onSuccess?.()
    } catch (e) {
      const err = e as Error
      setError(err)
      onError?.(err)
    } finally {
      setIsLoading(false)
    }
  }, [fetchNewValue, onError, onSuccess, updateCache])

  useEffect(() => {
    if (skip || cacheData[resourceWithId.id]) {
      return
    }

    getNewValueAndUpdateCache()

    // Use id instead of comparing entire resourceWithId object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceWithId.id])

  return {
    isLoading,
    error,
    cacheValue: cacheData[resourceWithId.id],
  }
}
