import { DebitCardTransaction } from '../../TransactionDrawer.types'

export const getNextTransaction = (
  currentTransaction: DebitCardTransaction | null,
  transactionsList: DebitCardTransaction[]
): DebitCardTransaction | null => {
  if (currentTransaction === null) {
    return null
  }

  const currentTransactionIndex = transactionsList.findIndex(
    ({ id }) => id === currentTransaction.id
  )

  return transactionsList[currentTransactionIndex + 1] ?? null
}

export const getPreviousTransaction = (
  currentTransaction: DebitCardTransaction | null,
  transactionsList: DebitCardTransaction[]
): DebitCardTransaction | null => {
  if (currentTransaction === null) {
    return null
  }

  const currentTransactionIndex = transactionsList.findIndex(
    ({ id }) => id === currentTransaction.id
  )

  return transactionsList[currentTransactionIndex - 1] ?? null
}
