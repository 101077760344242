import { COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ColourSquare = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
`
export const AddValue = styled.div`
  border: 2px dashed ${COLOR.GREY_150};
  box-sizing: border-box;
`

export const NoValue = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLOR.GREY_30};
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 12px;

  @media (hover: none) {
    gap: 16px;
  }
`
