import { useMemo } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useGetOutstandingFilterSettingsQuery } from './graphql/getOutstandingFilterSettings.generated'

export const useOutstandingFilterSettings = () => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data } = useGetOutstandingFilterSettingsQuery({
    variables: { entityUuid },
  })

  const outstandingFilterSettings = useMemo(
    () => data?.getEntity.outstandingTransactionRequirementConfig,
    [data]
  )

  const outstandingSettings = useMemo(
    () => ({
      isReceiptOutstandingFilterSettingsOn:
        outstandingFilterSettings?.attachments ?? false,
      isNoteOutstandingFilterSettingsOn:
        outstandingFilterSettings?.note ?? false,
      isCategoryOutstandingFilterSettingsOn:
        outstandingFilterSettings?.category ?? false,
    }),
    [outstandingFilterSettings]
  )

  return {
    isLoadingOutstandingFilterSettings: loading,
    outstandingSettings,
    isOutstandingFilterSettingsAllInactive: Object.values(
      outstandingSettings
    ).every((value) => !value),
  }
}
