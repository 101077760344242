import { useCallback } from 'react'
import { useDisableNavigation } from '@npco/ui-drawer-navigation'
import { Box, Flex, showErrorToast } from '@npco/zeller-design-system'
import {
  useEditDebitCardTransactionImagesState,
  useGoToEditDebitCardTransactionImages,
} from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-routing'

import { useFetchImage } from 'hooks/banking/useImagePreview/useFetchImage'
import { translate } from 'utils/translations'
import { MAX_FILES } from 'pages/DebitCardTransactions/DebitCardTransactions.utils'

import { AddLink } from './AddLink'
import { StyledImagesTitle } from './Images.styled'
import { ImagesDisplay } from './ImagesDisplay/ImagesDisplay'

interface Props {
  title: React.ReactNode
  placeholder: string
  className?: string
  onClickThumbnail?: (id: string) => void
  thumbnailSize?: number
  transactionUuid: string
}

export const Images = ({
  className,
  onClickThumbnail,
  placeholder,
  thumbnailSize,
  title,
  transactionUuid,
}: Props) => {
  const { goToEditDebitCardTransactionImages } =
    useGoToEditDebitCardTransactionImages()
  const { state } = useEditDebitCardTransactionImagesState()
  const isAddReceiptModalOpen = state !== null

  useDisableNavigation({ shouldDisableNavigation: isAddReceiptModalOpen })

  const { isLoadingImages, isDownloadingFiles, transactionCombinedImages } =
    useFetchImage({ transactionUuid, fetchPolicy: 'cache-first' })

  const allowedFilesLength = MAX_FILES - transactionCombinedImages.length
  const onAdd = useCallback(() => {
    if (allowedFilesLength === 0) {
      showErrorToast(
        translate('page.transactionDetails.images.uploadLimitWarning')
      )
      return
    }

    goToEditDebitCardTransactionImages({
      stage: 'edit',
      debitCardTransactionUuid: transactionUuid,
      maxFiles: MAX_FILES,
    })
  }, [allowedFilesLength, transactionUuid, goToEditDebitCardTransactionImages])

  return (
    <Box className={className} mb="2.5rem">
      <Flex justifyContent="space-between" alignItems="center">
        <StyledImagesTitle>{title}</StyledImagesTitle>
        <AddLink
          onClick={onAdd}
          isDisabled={isLoadingImages || isDownloadingFiles}
          dataTestId="add-image"
        />
      </Flex>
      <ImagesDisplay
        images={transactionCombinedImages}
        placeholder={placeholder}
        onClickThumbnail={onClickThumbnail}
        thumbnailSize={thumbnailSize}
        isLoadingImages={isLoadingImages}
        isDownloadingFiles={isDownloadingFiles}
      />
    </Box>
  )
}
