import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  BUTTON_SIZE,
  ButtonFillIconLeft,
  COLOR,
  PlusIcon,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translationsShared } from 'translations'

import { useGoToBlockedAction } from '../../../../../../BlockedAction/blocked-action-routing'
import { AddCardButtonEntityFragment } from './AddCardButton.generated'
import { AddCardButtonMobile } from './AddCardButton.styled'

interface AddCardButtonProps {
  entity?: AddCardButtonEntityFragment
}

export const AddCardButton = ({ entity }: AddCardButtonProps) => {
  const tShared = useTranslations(translationsShared)
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToBlockedAction } = useGoToBlockedAction()

  const isTabletResolution = useIsMobileResolution(BREAKPOINT.MD)

  const handleAddCard = useCallback(() => {
    if (entity && !entity.canCreateCard) {
      goToBlockedAction({ blockedAction: 'createCard' })
      return
    }
    goToAddCardStage({ stage: AddCardStage.LandingPageStage })
  }, [entity, goToAddCardStage, goToBlockedAction])
  return (
    <>
      {isTabletResolution ? (
        <AddCardButtonMobile
          dataTestId="cards-add-button"
          onClick={handleAddCard}
        >
          <PlusIcon color={COLOR.WHITE} />
        </AddCardButtonMobile>
      ) : (
        <ButtonFillIconLeft
          onClick={handleAddCard}
          icon={Plus}
          iconEnabledColor={COLOR.WHITE}
          size={BUTTON_SIZE.MEDIUM}
        >
          {tShared('card')}
        </ButtonFillIconLeft>
      )}
    </>
  )
}

AddCardButton.fragments = {
  Entity: gql`
    fragment AddCardButtonEntityFragment on Entity {
      id
      canCreateCard
    }
  `,
}
