import { BodySmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'total' 'day-of-week' 'time-of-day';
  grid-gap: 6rem 2.5rem;

  @media (min-width: ${BREAKPOINT.SM}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'total total' 'day-of-week time-of-day';
  }
`

type AreaType = 'total' | 'day-of-week' | 'time-of-day'

export const StyledChartWrapper = styled.div<{ area: AreaType }>`
  grid-area: ${({ area }) => area};
  min-width: 0;
`

export const StyledHeader = styled.div`
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.GREY_250};
  white-space: nowrap;
  margin-bottom: 0.25rem;
  text-align: center;
`
