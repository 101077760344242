import { useCallback, useEffect, useMemo, useRef } from 'react'
import { AUSTRALIA_COUNTRY_CODE } from '@npco/component-mp-common'
import { ContactType, LabelType } from '@npco/mp-gql-types'
import { InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'
import {
  type CountryCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/max'

import { toAlpha2CodeCountry } from 'utils/countries'
import { validatePhoneNumberForContact } from 'utils/formValidation'
import { LabelControl } from 'components/LabelControl'

import type { ContactFormData } from '../../Contacts.types'
import {
  getContactFormValues,
  validatePhoneNumberDuplication,
} from '../../Contacts.utils'

const getContactValuesMap = (fieldIndex: number) => ({
  [ContactType.BUSINESS]: {
    labelType: LabelType.BUSINESS,
    phoneName: `business.additionalPhones.[${fieldIndex}].phone`,
    labelName: `business.additionalPhones.[${fieldIndex}].label`,
  },
  [ContactType.PERSON]: {
    labelType: LabelType.PERSON,
    phoneName: `person.additionalPhones.[${fieldIndex}].phone`,
    labelName: `person.additionalPhones.[${fieldIndex}].label`,
  },
})

export type AdditionalPhoneInputFieldProps = {
  contactType: ContactType
  fieldIndex?: number
  placeholder: string
}

export const AdditionalPhoneInputField = ({
  contactType,
  placeholder,
  fieldIndex = 0,
}: AdditionalPhoneInputFieldProps) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null)
  const { setFieldTouched, values, setFieldValue } =
    useFormikContext<ContactFormData>()

  const contactValues = getContactFormValues(contactType, values)
  const { additionalPhones, phoneV2, country } = contactValues
  const { labelType, labelName, phoneName } =
    getContactValuesMap(fieldIndex)[contactType]
  const labelValue = contactValues.additionalPhones[fieldIndex]?.label
  const defaultCountry = toAlpha2CodeCountry(
    country || AUSTRALIA_COUNTRY_CODE
  ) as CountryCode

  useEffect(() => {
    if (country) {
      // NOTE: force validation on the phone field on country change
      setFieldTouched('phone', true, true)
    }
  }, [country, setFieldTouched])

  const memoizedPhoneNumbers = useMemo(() => {
    const allPhoneNumbers = additionalPhones.filter(
      (_, index) => index !== fieldIndex
    )

    if (phoneV2?.phone) allPhoneNumbers.push(phoneV2)

    return allPhoneNumbers
  }, [additionalPhones, phoneV2, fieldIndex])

  const handleBlur = useCallback(() => {
    const phoneNumber = parsePhoneNumberFromString(
      String(additionalPhones[fieldIndex]?.phone ?? ''),
      {
        defaultCountry,
        extract: false,
      }
    )

    if (phoneNumber?.isValid()) {
      setFieldValue(phoneName, phoneNumber.formatInternational())
    }
  }, [defaultCountry, phoneName, additionalPhones, fieldIndex, setFieldValue])

  const renderRightControls = useCallback(() => {
    const labelItem = labelValue
      ? {
          id: labelValue.id,
          isEditable: labelValue.isEditable,
          label: labelValue.labelText,
          value: labelValue.labelText,
        }
      : null

    return (
      <LabelControl
        labelItem={labelItem}
        labelType={labelType}
        popperWrapperRef={wrapperRef}
        setLabel={(nextLabel) => setFieldValue(labelName, nextLabel)}
      />
    )
  }, [labelName, labelType, labelValue, setFieldValue, wrapperRef])

  return (
    <InputAdaptiveField
      autoComplete="tel"
      label={placeholder}
      name={phoneName}
      renderRightControls={renderRightControls}
      type="tel"
      validate={(value) => {
        return (
          validatePhoneNumberForContact(value, false) ||
          validatePhoneNumberDuplication(country)(value, memoizedPhoneNumbers)
        )
      }}
      onBlur={handleBlur}
      wrapperRef={wrapperRef}
    />
  )
}
