import {
  BodyDefaultTypography,
  BodySmallTypography,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

import { HorizontalCard } from '../../../../../components/HorizontalCard/HorizontalCard'

const FocusedStyles = css`
  &:focus-visible {
    border-radius: 4px;
    outline-offset: -3px;
    outline: solid 3px ${({ theme }) => theme.colors.BLUE_120};
    + li {
      border-top: 1px solid transparent;
    }
    border-bottom: 1px solid transparent;
  }
`

export const StyledHorizontalCardInAccountsList = styled(HorizontalCard)`
  height: 6rem;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    height: 6.5rem;
  }
`

export const StyledAccountLabel = styled.div<{
  $maxWidth?: string
}>`
  max-width: ${({ $maxWidth }) => $maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${BodySmallTypography};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyDefaultTypography};
  }
`

export const StyledAccountGrow = styled.div`
  flex-grow: 1;
`

export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin: auto;
  width: 100px;
  ${BodySmallTypography};
  gap: 0.25rem;

  @media (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: column;
    justify-content: right;
    text-align: right;
    ${BodyDefaultTypography};
    width: 200px;
  }
`

interface StyledAccountRowProps {
  isClosed?: boolean
}

export const StyledAccountRow = styled.li<StyledAccountRowProps>`
  display: flex;
  width: 100%;
  height: 4rem;
  padding: 0.75rem 0.375rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_90};
  background-color: ${({ theme }) => theme.colors.WHITE};
  transition: background-color 0.1s ease-in-out;
  color: ${({ isClosed, theme }) =>
    isClosed ? theme.colors.GREY_550 : 'initial'};

  ${FocusedStyles}

  &:hover {
    background: ${({ theme }) => theme.colors.GREY_30};
    border-radius: 4px;
    box-shadow: none;
    border-top: 1px solid transparent;
  }
  &:hover + li {
    border-top: 1px solid transparent;
  }
  &:first-child {
    border-top: 1px solid transparent;
  }
`
