import { page } from 'translations'

import { StyledTryAgainButton } from './TryAgainButton.styled'

interface Props {
  retry: () => void
}

export const TryAgainButton = ({ retry }: Props) => {
  return (
    <StyledTryAgainButton onClick={() => retry()}>
      {page.dashboard.error.tryAgainLabel}
    </StyledTryAgainButton>
  )
}
