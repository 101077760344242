import dayjs from 'utils/dayjs'

const HOURS_FORMAT = 'h:mma'

export const getFormattedDateRange = (hour: number) => {
  const dateStart = dayjs()
    .hour(hour - 1)
    .minute(0)
  const dateEnd = dayjs().hour(hour).minute(0)

  return `${dateStart.format(HOURS_FORMAT)}-${dateEnd.format(HOURS_FORMAT)}`
}
