import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  logoTitle: 'Company Logo',
  logoDescription:
    'This logo will be displayed on your Payment Page, Receipts and Invoice PDFs.',
  logoDescriptionFeatureFlagged:
    'This logo will be displayed on your Payment Page and Receipts. To upload a custom logo for Invoice PDFs, go to {link}.',
  customisationLink: 'Customisation',
  logoAlt: 'Preview of Company Logo',
  uploadDescription:
    'Upload an image from your computer (PNG, JPG/JPEG, WebP or HEIC/HEIF up to 5MB).',
})
