import { FetchResult } from '@apollo/client'
import {
  RequestEmailChangeStatus,
  UpdateCustomerInput,
} from '@npco/mp-gql-types'
import { CustomerType } from '@npco/mp-utils-logged-in-user'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import {
  rvProfileSettings,
  rvUpdatedEmailNotConfirmed,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import { translate } from 'utils/translations'
import { ErrorType, ServerError } from 'types/errors'
import { RequestEmailChange } from 'types/gql-types/RequestEmailChange'
import { UpdateCustomer } from 'types/gql-types/UpdateCustomer'
import {
  ProfileSettingFormValuesWithEmail,
  ProfileSettingsData,
} from 'types/profile'

interface UpdateCustomerAndRequestEmailChangeProps {
  initialEmailValue: string
  values: ProfileSettingFormValuesWithEmail
  customerUuid: string
  customerDetails: ProfileSettingsData
  updateCustomer: (
    customer: UpdateCustomerInput,
    update: () => void
  ) => Promise<FetchResult<UpdateCustomer>>
  requestEmailChange: (
    newEmail: string
  ) => Promise<FetchResult<RequestEmailChange>>
  setUserData: (newUserData: Partial<CustomerType>) => void
  redirect: () => void
  openModal: () => void
}

const checkMFAError = (
  error: ServerError | null,
  redirect: () => void,
  openModal: () => void
) => {
  if (error?.errorType === ErrorType.MFA_SENSITIVE_ACCESS_EXPIRED) {
    openModal()
    return
  }

  redirect()
  showErrorToast(translate('page.settings.users.changeEmail.couldNotSave'))
}

type RequestEmailChangeResponse = RequestEmailChange | null | undefined

const hasEmailChangeFailed = (
  emailChangeResult: RequestEmailChangeResponse
) => {
  return (
    emailChangeResult?.requestEmailChange?.status ===
    RequestEmailChangeStatus.FAILED
  )
}

const hasEmailChangeRequestAlreadyBeenSent = (
  emailChangeResult: RequestEmailChangeResponse
) => {
  return emailChangeResult?.requestEmailChange?.message?.includes('[400]')
}

interface RequestEmailChangeArguments {
  initialEmailValue: string
  values: ProfileSettingFormValuesWithEmail
  requestEmailChange: (
    newEmail: string
  ) => Promise<FetchResult<RequestEmailChange>>
  redirect: () => void
  openModal: () => void
}

const getEmailErrorMessage = (
  emailChangeResult: RequestEmailChange | null | undefined
) => {
  if (hasEmailChangeFailed(emailChangeResult)) {
    if (hasEmailChangeRequestAlreadyBeenSent(emailChangeResult)) {
      return translate('page.changeEmail.waitError')
    }
    return translate('page.changeEmail.existsError')
  }

  return null
}

const getGraphQLError = (error: unknown) => {
  if (
    error &&
    typeof error === 'object' &&
    'graphQLErrors' in error &&
    Array.isArray(error.graphQLErrors) &&
    typeof error.graphQLErrors[0] === 'object' &&
    'errorType' in error.graphQLErrors[0]
  ) {
    return error.graphQLErrors[0] as ServerError
  }

  return null
}

const handleEmailChangeSuccess = (email: string) => {
  rvUpdatedEmailNotConfirmed(email)
  showSuccessToast(
    translate('page.settings.users.changeEmail.confirmationSent', {
      email,
    })
  )
}

export const updateEmail = async ({
  initialEmailValue,
  values,
  requestEmailChange,
  redirect,
  openModal,
}: RequestEmailChangeArguments) => {
  const { email } = values
  const hasEmailChanged = initialEmailValue !== email

  if (email && hasEmailChanged) {
    try {
      const result = await requestEmailChange(email.trim())

      const errorMessage = getEmailErrorMessage(result.data)

      if (errorMessage) {
        showErrorToast(errorMessage)
        return
      }

      handleEmailChangeSuccess(email)
    } catch (err) {
      const maybeGraphQLError = getGraphQLError(err)

      checkMFAError(maybeGraphQLError, redirect, openModal)

      return
    }
  }

  redirect()
}

export const updateCustomerAndRequestEmailChange = ({
  initialEmailValue,
  values,
  customerUuid,
  customerDetails,
  updateCustomer,
  requestEmailChange,
  setUserData,
  redirect,
  openModal,
}: UpdateCustomerAndRequestEmailChangeProps) => {
  const formattedValues = {
    firstname: values.firstname,
    middlename: values.middlename,
    lastname: values.lastname,
    address: {
      street: values.street,
      suburb: values.suburb,
      state: values.state,
      postcode: values.postcode,
      country: values.country,
    },
  }

  const customer = {
    ...formattedValues,
    id: customerUuid,
  }

  const { email } = values
  const hasEmailChanged = initialEmailValue !== email

  const updateProfileSettings = () => {
    rvProfileSettings({
      ...customerDetails,
      ...formattedValues,
    })
  }

  const redirectAndShowErrorToast = () => {
    redirect()
    showErrorToast(translate('page.settings.users.changeEmail.couldNotSave'))
  }

  if (email && hasEmailChanged) {
    return Promise.all([
      updateCustomer(customer, updateProfileSettings),
      requestEmailChange(email.trim()),
    ])
      .then((result) => {
        const errorMessage = getEmailErrorMessage(result[1]?.data)

        if (errorMessage) {
          showErrorToast(errorMessage)
          return
        }

        if (result[0]?.data?.updateCustomer) {
          setUserData(formattedValues)
          redirect()
          handleEmailChangeSuccess(email)
        }
      })
      .catch(({ graphQLErrors }) => {
        checkMFAError(graphQLErrors[0], redirect, openModal)
      })
  }

  return updateCustomer(customer, updateProfileSettings)
    .then((res) => {
      setUserData(formattedValues)
      redirect()
      if (res.data?.updateCustomer) {
        showSuccessToast(
          translate('page.settings.users.changeEmail.savedSuccessfully')
        )
      }
    })
    .catch(() => {
      redirectAndShowErrorToast()
    })
}

export const requestEmailChangeHandler = (
  requestEmailChange: (
    newEmail: string
  ) => Promise<FetchResult<RequestEmailChange>>
) => {
  const email = rvUpdatedEmailNotConfirmed()
  requestEmailChange(email)
    .then((result) => {
      if (
        result?.data?.requestEmailChange?.status ===
        RequestEmailChangeStatus.FAILED
      ) {
        showErrorToast(translate('page.changeEmail.waitError'))
        return
      }
      showSuccessToast(
        translate('page.settings.users.changeEmail.confirmationSent', {
          email,
        })
      )
    })
    .catch(() => {
      showErrorToast(translate('page.settings.users.changeEmail.couldNotSave'))
    })
}
