import { gql } from '@apollo/client'
import { ModalBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { CloseAccountPromptDebitCardAccountV2Fragment as DebitCardAccountV2 } from 'types/gql-types/CloseAccountPromptDebitCardAccountV2Fragment'

import { StyledBoldText, StyledDescription } from './CloseAccountPrompt.styled'
import { CloseAccountPromptLinkedCards } from './CloseAccountPromptLinkedCards/CloseAccountPromptLinkedCards'
import { useCloseAccountPrompt } from './hooks/useCloseAccountPrompt'

interface CloseAccountPromptProps {
  account: DebitCardAccountV2
}

export const CloseAccountPrompt = ({ account }: CloseAccountPromptProps) => {
  const { handleSubmit, closeAccountModal, isClosingAccount } =
    useCloseAccountPrompt(
      account.id,
      account.cards?.map((card) => card.id)
    )

  return (
    <ModalBasic
      isOpen
      title={translate('page.closeAccountModal.closeAccountPrompt.heading')}
      onCancel={closeAccountModal}
    >
      <ModalBasic.Body>
        <StyledDescription>
          {translate('page.closeAccountModal.closeAccountPrompt.description', {
            boldText: (
              <StyledBoldText>
                {translate(
                  'page.closeAccountModal.closeAccountPrompt.boldText'
                )}
              </StyledBoldText>
            ),
          })}
        </StyledDescription>
        <CloseAccountPromptLinkedCards cards={account.cards || []} />
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={closeAccountModal}>
          {translate('shared.cancel')}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          isLoading={isClosingAccount}
          onClick={handleSubmit}
        >
          {translate(
            'page.closeAccountModal.closeAccountPrompt.primaryButtonLabel'
          )}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}

CloseAccountPrompt.fragments = {
  DebitCardAccountV2: gql`
    fragment CloseAccountPromptDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      ...CloseAccountPromptLinkedCardsDebitCardAccountV2Fragment
    }

    ${CloseAccountPromptLinkedCards.fragments.DebitCardAccountV2}
  `,
}
