import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { translate } from 'utils/translations'
import { GetXeroSurchargeSettings_getXeroSiteSettings_surchargesTaxes_feesSurchargeXinv as XeroInvoiceFees } from 'types/gql-types/GetXeroSurchargeSettings'

import { InvoiceFeesInfo } from '../InvoiceFeesInfo/InvoiceFeesInfo'
import {
  StyledSiteContainer,
  StyledSiteTitle,
} from './InvoiceFeesAndPricingContainer.styled'

export interface XeroFeeContainerProps {
  xeroFees?: XeroInvoiceFees
}

export const XeroFeeContainer = ({ xeroFees }: XeroFeeContainerProps) => {
  const { userData } = useLoggedInUser()
  const isEntityEnabledXero = !!userData?.entityUuid

  if (!isEntityEnabledXero || !xeroFees) {
    return null
  }

  return (
    <StyledSiteContainer>
      <StyledSiteTitle>
        {translate(
          'page.settings.feesAndPricing.table.xeroInvoiceTransactionCharges'
        )}
      </StyledSiteTitle>
      <InvoiceFeesInfo fees={xeroFees} />
    </StyledSiteContainer>
  )
}
