import { Box } from '@npco/zeller-design-system'
import { useField, useFormikContext } from 'formik'

import { ReactComponent as TickIcon } from 'assets/svg/tick.svg'
import {
  validateHasNumber,
  validateHasSpecialChar,
  validateHasUppercase,
  validatePasswordMinLength,
} from 'utils/formValidation'
import { SignupFields } from 'pages/SignUp/SignUp.types'
import { page } from 'translations'

import {
  StyledTooltipCondition as Condition,
  StyledTooltipContent as Content,
  StyledTooltipWrapper as Wrapper,
  StyledUl,
} from './SignupTooltipContents.styled'

// we need to pass fake error message so it's casted as true in the validator
const PLACEHOLDER_ERROR = 'error'

export const SignupTooltipContents = () => {
  const {
    values: { password },
  } = useFormikContext<SignupFields>()

  const [, fieldMeta] = useField('password')

  const shouldShowError = Boolean(fieldMeta.touched)

  return (
    <Wrapper data-testid="signup-tooltip">
      <Content>
        <Box my="0" as="p">
          {page.signUp.password.info}
        </Box>
        <StyledUl>
          <Condition
            data-testid="has-eight-chars"
            isValid={!validatePasswordMinLength(PLACEHOLDER_ERROR)(password)}
            shouldShowError={shouldShowError}
          >
            <TickIcon />
            {page.signUp.password.eightChars}
          </Condition>
          <Condition
            data-testid="has-uppercase"
            isValid={!validateHasUppercase(PLACEHOLDER_ERROR)(password)}
            shouldShowError={shouldShowError}
          >
            <TickIcon />
            {page.signUp.password.oneUppercase}
          </Condition>
          <Condition
            data-testid="has-number"
            isValid={!validateHasNumber(PLACEHOLDER_ERROR)(password)}
            shouldShowError={shouldShowError}
          >
            <TickIcon />
            {page.signUp.password.hasNumber}
          </Condition>
          <Condition
            data-testid="has-special-char"
            isValid={!validateHasSpecialChar(PLACEHOLDER_ERROR)(password)}
            shouldShowError={shouldShowError}
          >
            <TickIcon />
            {page.signUp.password.specialChar}
          </Condition>
        </StyledUl>
      </Content>
    </Wrapper>
  )
}
