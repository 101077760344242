import { useCallback } from 'react'
import {
  NavigateOptions,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat'

import { BPayTransferModalState } from '../../BPayTransfer.types'

type OpenBPayModalOptions = Omit<NavigateOptions, 'state'>

export const useBPayTransferModal = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const openBPayTransferModal = useCallback(
    (state: BPayTransferModalState, options?: OpenBPayModalOptions) => {
      navigate(location.pathname, {
        state: { BPayTransferModal: state },
        ...options,
      })
    },
    [navigate, location.pathname]
  )

  const closeBPayTransferModal = useCallback(() => {
    navigate(location.pathname, {
      state: { BPayTransferModal: null },
    })
  }, [navigate, location.pathname])

  return { openBPayTransferModal, closeBPayTransferModal }
}
