import { ConnectionType } from '@npco/mp-gql-types'
import { Flex, Spinner } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import {
  SyncingContainer,
  SyncingHeading,
  SyncingMessage,
} from './Syncing.styled'

interface SyncingProps {
  connectionType: ConnectionType
}

export const Syncing = ({ connectionType }: SyncingProps) => (
  <SyncingContainer
    flexDirection="column"
    justifyContent="center"
    alignContent="center"
    width="100%"
    height="100%"
  >
    <Flex width="100%" justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
    <SyncingHeading>
      {connectionType === ConnectionType.XERO_BANKFEEDS
        ? translate('page.settings.connections.xeroBankFeeds.syncing.title')
        : translate(
            'page.settings.connections.xeroPaymentServices.syncing.title'
          )}
    </SyncingHeading>
    <SyncingMessage>
      {translate(
        'page.settings.connections.xeroBankFeeds.syncing.body.lineOne'
      )}
      <br />
      {translate(
        'page.settings.connections.xeroBankFeeds.syncing.body.lineTwo'
      )}
    </SyncingMessage>
  </SyncingContainer>
)
