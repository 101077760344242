import { pipe } from 'lodash/fp'
import { isFinite, toNumber } from 'lodash-es'

/**
 * This addresses multiplication issue on javascript when manipulating amounts
 * Ex: 134.2*100 = 13419.999998, not 13420
 * Therefore Math.floor(parsedValue * (10 ** decimalPlaces)) / (10 ** decimalPlaces)).toFixed(decimalPlaces) does not work
 * Make sure all the existing test cases work if you are attempting to change this function
 *
 * @param value
 * @param decimalPlaces
 */
export const getInputAmount = (
  value: number,
  decimalPlaces: number
): string => {
  const valueArray = value.toString().split('.')

  if (valueArray.length < 2) {
    return parseInt(valueArray[0], 10).toFixed(decimalPlaces)
  }

  if (valueArray[1].length <= decimalPlaces) {
    return value.toFixed(decimalPlaces)
  }

  return `${valueArray[0]}.${valueArray[1].slice(0, decimalPlaces)}`
}

export const getBasePoints = (percentageValue: number | null | undefined) => {
  if (percentageValue === undefined) {
    return undefined
  }

  if (percentageValue === null) {
    return null
  }

  return Number(getInputAmount(percentageValue * 100, 2))
}

export const getPercentageValue = (
  basePointsValue: number | null | undefined
) => {
  if (basePointsValue === undefined) {
    return undefined
  }

  if (basePointsValue === null) {
    return null
  }

  return Number(getInputAmount(basePointsValue / 100, 2))
}

/**
 * isNumeric
 * takes a string parses to an integer then checks its Finite which ensures its not NaN or Infinity
 */
export const isNumeric: (input: string) => boolean = pipe(toNumber, isFinite)
