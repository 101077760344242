import { BREAKPOINT } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const StyledTableEffect = css`
  max-height: 4rem;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_90};
  border-bottom: 1px solid transparent;
  :first-child {
    border-top: 1px solid transparent;
  }
  :last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_90};
    :hover {
      border-bottom: 1px solid transparent;
    }
  }

  :hover {
    + li {
      border-top: 1px solid transparent;
    }
    /* should migrate rows to LI element */
    + div {
      border-top: 1px solid transparent;
    }
    background-color: ${({ theme }) => theme.colors.GREY_30};
    border-radius: 4px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
`

export const TableRowHoveredOrActiveStyles = css`
  background-color: ${({ theme }) => theme.colors.GREY_30};
  cursor: pointer;
  td {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  td:first-child {
    border-radius: 4px;
  }
  td:last-child {
    border-radius: 4px;
  }
`

// NOTE: the styled instances below are to be used with the tanstack table
// instances
export const Table = styled.table<{
  hasLastTrBorderBottom: boolean
  hasBorderBottom: boolean
  isRowClickable: boolean
}>`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  tr:first-child {
    td {
      border-top: 1px solid transparent;
    }
  }

  tr:last-child {
    td {
      border-bottom: ${({ theme, hasLastTrBorderBottom }) =>
        `1px solid ${
          hasLastTrBorderBottom ? theme.colors.GREY_90 : 'transparent'
        }`};
    }
  }

  tbody {
    tr {
      td {
        border-top: ${({ theme, hasBorderBottom }) =>
          `1px solid ${
            hasBorderBottom ? theme.colors.GREY_90 : 'transparent'
          }`};
      }

      &:active {
        ${({ isRowClickable }) =>
          isRowClickable && TableRowHoveredOrActiveStyles}
      }

      @media screen and (min-width: ${BREAKPOINT.XS}px) {
        &:hover {
          ${({ isRowClickable }) =>
            isRowClickable && TableRowHoveredOrActiveStyles}
        }
      }
    }
  }
`

export const THead = styled.thead<{
  headBottomSpace?: string
}>`
  ${({ headBottomSpace }) =>
    headBottomSpace &&
    css`
      &::after {
        content: '';
        height: ${headBottomSpace};
        display: block;
      }
    `};
`

export const TableRowHeader = styled.tr`
  position: sticky;
  top: 0;
  z-index: 1;
`
