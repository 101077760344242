import { useCallback, useEffect, useRef } from 'react'
import { AUSTRALIA_COUNTRY_CODE } from '@npco/component-mp-common'
import { ContactType, LabelType } from '@npco/mp-gql-types'
import { InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'
import {
  type CountryCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js/max'

import { toAlpha2CodeCountry } from 'utils/countries'
import {
  makeOptional,
  validateMobileNumberForContact,
  validatePhoneNumberForContact,
} from 'utils/formValidation'
import type { CustomValidator } from 'types/common'
import { LabelControl } from 'components/LabelControl'

import { ContactFormData } from '../../Contacts.types'
import {
  getContactFormValues,
  isCountryNilOrAustralia,
} from '../../Contacts.utils'

interface PhoneInputFieldProps {
  existingContactName?: string | null
  placeholder: string
  isOptional?: boolean
  contactType: ContactType
}

const CONTACT_MAPPING = {
  [ContactType.BUSINESS]: {
    labelType: LabelType.BUSINESS,
    phoneName: 'business.phoneV2.phone',
    labelName: 'business.phoneV2.label',
  },
  [ContactType.PERSON]: {
    labelType: LabelType.PERSON,
    phoneName: 'person.phoneV2.phone',
    labelName: 'person.phoneV2.label',
  },
}

export const PhoneInputField = ({
  existingContactName,
  placeholder,
  contactType,
  isOptional,
}: PhoneInputFieldProps) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null)

  const { setFieldTouched, values, setFieldValue } =
    useFormikContext<ContactFormData>()

  const contactValues = getContactFormValues(contactType, values)
  const { email, phoneV2, country } = contactValues
  const { labelType, phoneName, labelName } = CONTACT_MAPPING[contactType]

  const defaultCountry = toAlpha2CodeCountry(
    country || AUSTRALIA_COUNTRY_CODE
  ) as CountryCode

  const validation: CustomValidator<string | number | undefined, boolean> =
    contactType === ContactType.PERSON
      ? validateMobileNumberForContact
      : validatePhoneNumberForContact

  useEffect(() => {
    if (country) {
      // NOTE: force validation on the phone field on country change
      setFieldTouched('phone', true, true)
    }
  }, [country, setFieldTouched])

  const validate = isCountryNilOrAustralia(country)
    ? (value: string) =>
        makeOptional(validation, value, isOptional || Boolean(email.email))
    : undefined

  const handleBlur = useCallback(() => {
    const phoneNumber = parsePhoneNumberFromString(
      String(phoneV2?.phone ?? ''),
      {
        defaultCountry,
        extract: false,
      }
    )

    if (phoneNumber?.isValid()) {
      setFieldValue(phoneName, phoneNumber.formatInternational())
    }
  }, [defaultCountry, phoneName, phoneV2?.phone, setFieldValue])

  const renderRightControls = useCallback(() => {
    const labelValue = phoneV2?.label
    const labelItem = labelValue
      ? {
          id: labelValue.id,
          isEditable: labelValue.isEditable,
          label: labelValue.labelText,
          value: labelValue.labelText,
        }
      : null

    return (
      <LabelControl
        labelItem={labelItem}
        labelType={labelType}
        popperWrapperRef={wrapperRef}
        setLabel={(nextLabel) => setFieldValue(labelName, nextLabel)}
      />
    )
  }, [labelName, labelType, phoneV2?.label, setFieldValue, wrapperRef])

  return (
    <InputAdaptiveField
      autoComplete="tel"
      hasError={Boolean(existingContactName)}
      label={placeholder}
      name={phoneName}
      renderRightControls={renderRightControls}
      type="tel"
      validate={validate}
      wrapperRef={wrapperRef}
      onBlur={handleBlur}
    />
  )
}
