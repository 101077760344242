import {
  Box,
  SkeletonAvatar,
  SkeletonBodyDefault as SkeletonBodyBase,
  SkeletonH3 as SkeletonHeading,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { AVATAR_BADGE_SIZE } from 'types/common'

import { ListItem } from '../ListItem'
import { HeaderEditButton, HeaderTitle, ListItemHeader } from './ListItemHeader'

const HeaderAvatarSkeleton = () => (
  <SkeletonAvatar size={AVATAR_BADGE_SIZE.LARGE} />
)

const HeaderTitleSkeleton = HeaderTitle.withComponent((props) => (
  <SkeletonHeading width={120} {...props} />
))

const SkeletonBody = styled(SkeletonBodyBase)`
  margin: 0;
`

const HeaderEditButtonSkeleton = HeaderEditButton.withComponent((props) => (
  <SkeletonBody width={40} {...props} />
))

export type ListItemHeaderSkeletonProps = Record<string, never>

export const ListItemHeaderSkeleton = ListItemHeader.withComponent(
  (props: ListItemHeaderSkeletonProps) => (
    <ListItem
      left={
        <div {...props}>
          <HeaderAvatarSkeleton />
          <Box ml="14px">
            <HeaderTitleSkeleton />
          </Box>
        </div>
      }
      right={<HeaderEditButtonSkeleton />}
    />
  )
)
