import { forwardRef } from 'react'
import { SelectTriggerPill } from '@npco/zeller-design-system'

import { ReactComponent as DollarIcon } from 'assets/svg/dollar.svg'
import { SelectPill } from 'layouts/AccountLayout/SelectPill/SelectPill'
import { formatAmountTriggerLabel } from 'layouts/AccountLayout/utils/formatAmount'
import { page } from 'translations'

interface Props {
  onClear: () => void
  onToggleOpen: () => void
  isOpen: boolean
  amount: {
    min: number
    max: number
  }
  areFiltersDefault: boolean
}

export const AccountFilterAmountTrigger = forwardRef<HTMLButtonElement, Props>(
  ({ areFiltersDefault, onToggleOpen, onClear, amount, isOpen }, ref) => {
    if (!areFiltersDefault) {
      return (
        <SelectPill
          ref={ref}
          icon={<DollarIcon />}
          onClick={onToggleOpen}
          onClose={onClear}
        >
          {formatAmountTriggerLabel(amount.min, amount.max)}
        </SelectPill>
      )
    }

    return (
      <SelectTriggerPill
        ref={ref}
        hasChevron
        isOpen={isOpen}
        onClick={onToggleOpen}
      >
        {page.accounts.transactionsList.filters.amount}
      </SelectTriggerPill>
    )
  }
)
