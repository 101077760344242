import { useCallback, useState } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { showSuccessToast } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'
import { GetSubcategories_getEntitySubcategories as SubcategoryDetails } from 'types/gql-types/GetSubcategories'
import { NewSubcategory } from 'components/EditZellerCategories/EditZellerCategories.types'

import { useUpdateCategory } from '../../../hooks/useUpdateCategory/useUpdateCategory'
import { rvSelectedContact } from '../../../rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from '../../../rv-deprecated/contacts.utils'
import { ContactEntityCategoryValues } from './CategoryPills.types'
import { displayCategoryUpdateError } from './CategoryPills.utils'
import { EditContactCategories } from './EditContactCategories/EditContactCategories'

interface Props {
  isModalOpen: boolean
  category: EntityCategories | null
  subcategoryDetails: SubcategoryDetails | null
  closeModal: () => void
  contactUuid: string | undefined
}

type Contact = ContactCoreFieldsFragment

export const EditCategoryPills = ({
  isModalOpen,
  category,
  subcategoryDetails,
  closeModal,
  contactUuid,
}: Props) => {
  const [isSubCategoryEditedOrDeleted, setIsSubCategoryEditedOrDeleted] =
    useState(false)

  const displaySuccessAndCloseModal = useCallback(() => {
    showSuccessToast(translate('page.contacts.category.updateCategorySuccess'))
    closeModal()
  }, [closeModal])

  const { updateCategories, isLinkingSubcategoryToContact } = useUpdateCategory(
    {
      contactUuid,
      onSuccess: displaySuccessAndCloseModal,
      onFailure: displayCategoryUpdateError,
    }
  )

  const handleSaveCategory = useCallback(
    ({
      category: newCategory,
      subcategory,
      subcategoryId,
    }: ContactEntityCategoryValues) => {
      const contactCategoryIsUnchanged =
        newCategory === category &&
        subcategoryId === subcategoryDetails?.id &&
        !isSubCategoryEditedOrDeleted

      if (isSubCategoryEditedOrDeleted) {
        setIsSubCategoryEditedOrDeleted(false)
        displaySuccessAndCloseModal()
        closeModal()
        return
      }

      if (contactCategoryIsUnchanged) {
        closeModal()
        return
      }

      updateCategories({
        category: newCategory,
        subcategoryName: subcategory,
        subcategoryId,
      })
    },
    [
      category,
      closeModal,
      displaySuccessAndCloseModal,
      isSubCategoryEditedOrDeleted,
      setIsSubCategoryEditedOrDeleted,
      subcategoryDetails?.id,
      updateCategories,
    ]
  )

  const handleEditSubcategory = useCallback(
    ({
      category: newCategory,
      subcategory,
      subcategoryId,
    }: ContactEntityCategoryValues) => {
      if (subcategoryId === subcategoryDetails?.id) {
        setIsSubCategoryEditedOrDeleted(true)

        const contact = rvSelectedContact()
        rvSelectedContact({
          ...contact,
          category: newCategory,
          subcategory,
          subcategoryUuid: subcategoryId,
        } as Contact)

        if (contactUuid) {
          updateRvContactsOnContactUpdate(contactUuid, {
            category: newCategory,
            subcategory,
            subcategoryUuid: subcategoryId,
          })
        }

        // NOTE: edit of subcategory will automatically update the contact via backend
      }
    },
    [subcategoryDetails?.id, contactUuid, setIsSubCategoryEditedOrDeleted]
  )

  const handleDeleteSubcategory = useCallback(
    ({
      category: newCategory,
      newSubcategory,
    }: ContactEntityCategoryValues & NewSubcategory) => {
      setIsSubCategoryEditedOrDeleted(true)

      const contact = rvSelectedContact()

      rvSelectedContact({
        ...contact,
        category: newCategory,
        subcategory: newSubcategory?.name,
        subcategoryUuid: newSubcategory?.id,
      } as Contact)

      if (contactUuid) {
        updateRvContactsOnContactUpdate(contactUuid, {
          category: newCategory,
          subcategory: newSubcategory?.name,
          subcategoryUuid: newSubcategory?.id,
        })
      }
      // NOTE: delete of subcategory will automatically update the contact via backend
    },
    [setIsSubCategoryEditedOrDeleted, contactUuid]
  )

  return isModalOpen ? (
    <EditContactCategories
      isModalOpen={isModalOpen}
      category={category}
      subcategoryDetails={subcategoryDetails}
      onSaveCategory={handleSaveCategory}
      onCancel={() => {
        setIsSubCategoryEditedOrDeleted(false)
        closeModal()
      }}
      onEditContactSubcategory={handleEditSubcategory}
      onDeleteContactSubcategory={handleDeleteSubcategory}
      shouldShowApplyAll={false}
      isSavingCategories={isLinkingSubcategoryToContact}
    />
  ) : null
}
