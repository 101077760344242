import { ReactNode } from 'react'

import { conditionalClassName } from 'utils/conditionalClassName'

import {
  StyledContent,
  StyledHorizontalCardContainer,
  StyledHorizontalCardImageWrapper,
  StyledIndicatorItem,
} from './HorizontalCard.styled'
import { HorizontalCardDetails } from './HorizontalCardDetails/HorizontalCardDetails'
import { HorizontalCardLabels } from './HorizontalCardLabels/HorizontalCardLabels'

export interface Props {
  className?: string
  onClick?: () => void
  image?: ReactNode
  heading?: ReactNode
  descriptionLabel1?: ReactNode
  descriptionLabel2?: ReactNode
  detailsLabel1?: ReactNode
  detailsLabel2?: ReactNode
  isDraggable?: boolean
  ['data-testId']?: string
}

export const HorizontalCard = ({
  className,
  onClick,
  image,
  heading,
  descriptionLabel1,
  descriptionLabel2,
  detailsLabel1,
  detailsLabel2,
  isDraggable = false,
  'data-testId': testId,
}: Props) => {
  return (
    <StyledHorizontalCardContainer
      className={conditionalClassName(
        {
          isDraggable,
        },
        className
      )}
      data-testid={testId}
      onClick={onClick}
    >
      {isDraggable && <StyledIndicatorItem />}
      {image && (
        <StyledHorizontalCardImageWrapper>
          {image}
        </StyledHorizontalCardImageWrapper>
      )}
      <StyledContent>
        <HorizontalCardLabels
          heading={heading}
          label1={descriptionLabel1}
          label2={descriptionLabel2}
        />
        <HorizontalCardDetails label1={detailsLabel1} label2={detailsLabel2} />
      </StyledContent>
    </StyledHorizontalCardContainer>
  )
}
