import { Flex, Message, MessageSize, Spinner } from '@npco/zeller-design-system'

interface TransactionContactLoadingProps {
  title: string
  description: string
}
export const TransactionContactLoading = ({
  title,
  description,
}: TransactionContactLoadingProps) => {
  return (
    <Flex
      mt={{ _: '0', SM: '56px' }}
      justifyContent="center"
      textAlign="center"
      data-testid="transaction-contact-loading"
    >
      <Message
        description={description}
        image={<Spinner />}
        size={MessageSize.LARGE}
        title={title}
      />
    </Flex>
  )
}
