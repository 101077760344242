import { PillYellow } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Title = styled.h3`
  ${({ theme }) => theme.typography['heading-xl']}
  display: inline;
  vertical-align: middle;
  word-break: break-word;
`

export const Pill = styled(PillYellow)`
  display: inline;
  margin-left: 16px;
`
