import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { translations } from 'features/Expenses/ExpensesLayout/Expenses.i18n'

export const ExpensesHeaderPrimaryRow = () => {
  const t = useTranslations(translations)
  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex flexDirection="column" height="48px" justifyContent="center">
        <Breadcrumb>
          <Breadcrumb.Text>{t('breadcrumb')}</Breadcrumb.Text>
        </Breadcrumb>
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
