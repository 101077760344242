import {
  ButtonGhost,
  StyledButtonContent,
  StyledSelectStandardItem,
  StyledWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const MergeTagItem = styled(StyledSelectStandardItem)`
  padding: 10px 10px 10px 12px;
`

export const InsertButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 12px;
`

export const InsertButton = styled(ButtonGhost)<{ $isOpen: boolean }>`
  padding: 0;
  ${StyledButtonContent} {
    gap: 8px;

    transition: transform 250ms ease;
    transform: translateX(75%);

    ${({ $isOpen }) => $isOpen && 'transform: translateX(0);'}
  }
  ${StyledWrapper} {
    overflow: hidden;
  }
  background: transparent;

  &:hover {
    ${StyledButtonContent} {
      transform: translateX(0);
    }
  }

  ${({ $isOpen, theme }) =>
    $isOpen && `background-color: ${theme.colors.GREY_30};`}
`
