import { useCallback, useState } from 'react'
import { renameAndDownloadFile } from '@npco/utils-file'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'

import { UNEXPECTED_ERROR } from '../../const/errors'
import { useGetTransferRemittancePdf } from '../useGetTransferRemittancePdf/useGetTransferRemittancePdf'
import { downloadRemittanceTranslations } from './useDownloadRemittance.i18n'

const PDF = 'PDF'

type UseDownloadRemittanceProps = {
  dcaTransactionUuid: string
}

export const useDownloadRemittance = ({
  dcaTransactionUuid,
}: UseDownloadRemittanceProps) => {
  const t = useTranslations(downloadRemittanceTranslations)
  const [isDownloadingFile, setIsDownloadingFile] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const { getTransferRemittancePdf, isLoadingPdfRemittance } =
    useGetTransferRemittancePdf({
      dcaTransactionUuid,
    })

  const downloadPdfRemittance = useCallback(async () => {
    const result = await getTransferRemittancePdf()

    if (result === UNEXPECTED_ERROR) {
      showErrorToast(t('errorMessage'))
      return
    }

    setIsDownloadingFile(true)

    try {
      await renameAndDownloadFile({
        link: result,
        format: PDF,
        filename: t('pdfName', { id: dcaTransactionUuid }),
      })
      setIsDownloaded(true)
    } catch {
      showErrorToast(t('errorMessage'))
    } finally {
      setIsDownloadingFile(false)
    }
  }, [dcaTransactionUuid, getTransferRemittancePdf, t])

  return {
    downloadPdfRemittance,
    isLoadingPdfRemittance,
    isDownloadingFile,
    isDownloaded,
  }
}
