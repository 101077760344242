import { currencyFormatter } from '@npco/component-mp-common'
import { COLOR } from '@npco/zeller-design-system'
import { flatten, max, min } from 'lodash-es'

import { DataPoint } from './AccountBalanceChart.types'

export const getIncomeBarFillColor = ({
  isLoading,
  name,
  selectedBar,
  value,
}: {
  isLoading: boolean
  name: string
  selectedBar?: string
  value: number
}) => {
  if (isLoading) {
    return COLOR.GREY_90
  }

  if (selectedBar === undefined || selectedBar === name) {
    return value >= 0 ? COLOR.BLUE_1000 : COLOR.RED_1000
  }

  return value >= 0 ? COLOR.BLUE_120 : COLOR.RED_120
}

export const getExpenseBarFillColor = ({
  isLoading,
  name,
  selectedBar,
  value,
}: {
  isLoading: boolean
  name: string
  selectedBar?: string
  value: number
}) => {
  if (isLoading) {
    return COLOR.GREY_150
  }

  if (selectedBar === undefined || selectedBar === name) {
    return value >= 0 ? COLOR.BLUE_400 : COLOR.RED_400
  }

  return value >= 0 ? COLOR.BLUE_80 : COLOR.RED_80
}

export const getIncomeBarFillColorByValue = (value: number) => {
  return value >= 0 ? COLOR.BLUE_1000 : COLOR.RED_1000
}

export const getExpenseBarFillColorByValue = (value: number) => {
  return value >= 0 ? COLOR.BLUE_400 : COLOR.RED_400
}

export const getPlaceholderAccountBalanceData = ({
  length,
  isFetchingMore,
}: {
  length: number
  isFetchingMore?: boolean
}) => {
  const barHeight = [1.2, 2.1, 3.9, 3.9, 2.1, 1.2]
  return Array.from({ length }).map((_, index) => {
    const value = isFetchingMore ? 0 : barHeight[index % barHeight.length]
    return {
      name: index.toString(),
      income: value,
      expense: value,
      dateStart: '',
      year: '',
      value,
    }
  })
}

const formatDollar = ({
  prefix,
  roundedAmount,
  suffix,
}: {
  prefix: string
  roundedAmount: number
  suffix: string
}) => {
  const stringifiedAmount = roundedAmount.toFixed(2)
  if (stringifiedAmount.endsWith('00')) {
    return `${prefix}${roundedAmount.toFixed(0)}${suffix}`
  }

  return `${prefix}${stringifiedAmount}${suffix}`
}

export const formatDollarValueTicks = (value: number) => {
  const prefix = value < 0 ? '-$' : '$'
  const absValue = Math.abs(value)
  if (absValue < 100000) {
    return currencyFormatter(value).split('.')[0]
  }

  if (absValue < 100000000) {
    return formatDollar({
      prefix,
      roundedAmount: absValue / 100000,
      suffix: 'K',
    })
  }

  if (absValue < 100000000000) {
    return formatDollar({
      prefix,
      roundedAmount: absValue / 100000000,
      suffix: 'M',
    })
  }

  return formatDollar({
    prefix,
    roundedAmount: absValue / 100000000000,
    suffix: 'B',
  })
}

const getMinMaxValues = (chartData: DataPoint[]) => {
  const values = flatten(
    chartData.map(({ income, expense }) => [income, expense])
  )
  return { min: min([...values, 0]) ?? 0, max: max([...values, 0]) ?? 0 }
}

const roundUpToRange = (value: number): number => {
  const ranges = [
    { min: 0, max: 100, step: 100 },
    { min: 100, max: 1000, step: 100 },
    { min: 1000, max: 10000, step: 1000 },
    { min: 10000, max: 100000, step: 10000 },
    { min: 100000, max: 1000000, step: 100000 },
    { min: 1000000, max: 10000000, step: 1000000 },
    { min: 10000000, max: 100000000, step: 10000000 },
    { min: 100000000, max: 1000000000, step: 100000000 },
    { min: 1000000000, max: 10000000000, step: 1000000000 },
    { min: 10000000000, max: 100000000000, step: 10000000000 },
    { min: 100000000000, max: 1000000000000, step: 100000000000 },
  ]

  const range = ranges.find((r) => value >= r.min && value < r.max)
  const step = range ? range.step : ranges[ranges.length - 1].step
  return Math.ceil(value / step) * step
}

export const getYAxisValueTicks = (chartData: DataPoint[], tickCount = 5) => {
  const { min: minValue, max: maxValue } = getMinMaxValues(chartData)
  const interval = tickCount - 1
  let step = Math.abs(maxValue - minValue) / interval
  if (maxValue * minValue < 0) {
    step = Math.max(
      step,
      Math.max(Math.abs(maxValue), Math.abs(minValue)) / (interval - 1),
      Math.min(Math.abs(maxValue), Math.abs(minValue))
    )
  }

  let zeroIndex = 0
  const roundedStep = roundUpToRange(step)
  while (minValue + roundedStep * zeroIndex < 0) {
    zeroIndex += 1
  }

  const gap =
    zeroIndex === 0 || zeroIndex === interval
      ? roundedStep
      : roundUpToRange(
          Math.max(
            Math.abs(minValue) / zeroIndex,
            Math.abs(maxValue) / (interval - zeroIndex)
          )
        )

  return Array(tickCount)
    .fill(0)
    .map((_, index) => {
      return (
        0 +
        (index - zeroIndex + (maxValue > 0 && zeroIndex === interval ? 1 : 0)) *
          gap
      )
    })
}

export const getDefaultYAxisValueTicks = (interval: number) => {
  return Array(5)
    .fill(0)
    .map((_, index) => interval * index)
}

export const isOdd = (num: number) => num % 2 !== 0
