import { Formik, FormikHelpers } from 'formik'

import { GetDebitCardAccountsForCardCreationQueryResponse } from 'pages/GlobalModals/AddCardModal/graphql/accounts.generated'
import {
  StyledFormFooterWithOffset,
  StyledFormWithOffset,
} from 'pages/GlobalModals/GlobalModals.styled'

import { SelectAccountRadioGroupWithSearch } from '../SelectAccountRadioGroupWithSearch/SelectAccountRadioGroupWithSearch'
import { SelectAccountFormValues } from './SearchableAccountForm.types'
import { selectAccountFormValidationSchema } from './SearchableAccountForm.utils'

type AccountInfo = NonNullable<
  GetDebitCardAccountsForCardCreationQueryResponse['getDebitCardAccountsV2']['accounts'][0]
>

interface SearchableAccountFormProps {
  initialAccountId: string
  selectAccount: (
    values: SelectAccountFormValues,
    formikHelpers: FormikHelpers<SelectAccountFormValues>
  ) => void | Promise<any>
  onBack: () => void
  accounts: AccountInfo[]
  isAccountsLoading: boolean
  hasError: boolean
  refetch: () => void
  dataTestId?: string
  isConfirming?: boolean
}

export const SearchableAccountForm = ({
  initialAccountId,
  selectAccount,
  onBack,
  accounts,
  isAccountsLoading,
  hasError,
  refetch,
  dataTestId,
  isConfirming,
}: SearchableAccountFormProps) => {
  return (
    <Formik<SelectAccountFormValues>
      initialValues={{ accountId: initialAccountId }}
      onSubmit={selectAccount}
      validationSchema={selectAccountFormValidationSchema}
      validateOnMount
    >
      <StyledFormWithOffset data-testid={dataTestId}>
        <SelectAccountRadioGroupWithSearch
          accountInfo={accounts}
          loading={isAccountsLoading}
          hasError={hasError}
          refetch={refetch}
        />
        <StyledFormFooterWithOffset
          onCancel={onBack}
          disableOnInvalid
          isLoading={isConfirming}
        />
      </StyledFormWithOffset>
    </Formik>
  )
}
