import { TransactionExportFormat } from '@npco/mp-gql-types'
import { ExportTransactionsErrorMessage } from 'features/ExportTransactions/components/ExportTransactionsErrorMessage/ExportTransactionsErrorMessage'
import { useExportTransactionsModal } from 'features/ExportTransactions/hooks/useExportTransactionsModal'

import { ExportTransactionsModalContent } from 'pages/Dashboard/DashboardTransactions/PaymentTransactions/ExportTransactionsModal/ExportTransactionsModalContent'

interface Props {
  closeModal: () => void
  filename: string
  format: TransactionExportFormat
}

export const ExportTransactionsModal = ({
  closeModal,
  filename,
  format,
}: Props) => {
  const { renderContent, hasTimePassed, setHasTimePassed, rerenderContent } =
    useExportTransactionsModal()

  return (
    <>
      {renderContent && !hasTimePassed && (
        <ExportTransactionsModalContent
          filename={filename}
          rerenderContent={rerenderContent}
          closeModal={closeModal}
          setHasTimePassed={setHasTimePassed}
          format={format}
        />
      )}
      {hasTimePassed && (
        <ExportTransactionsErrorMessage
          hasTimePassed={hasTimePassed}
          closeModal={closeModal}
          rerenderContent={rerenderContent}
        />
      )}
    </>
  )
}
