import { z } from 'zod'

export enum UpgradeAccountsCompleteStage {
  UpgradeAccountsCompleteStartStage = 'UpgradeAccountsCompleteStartStage',
  UpgradeAccountsCompleteBuildBusinessStage = 'UpgradeAccountsCompleteBuildBusinessStage',
  UpgradeAccountsXeroIntegrationStage = 'UpgradeAccountsXeroIntegrationStage',
  UpgradeAccountsDebitCardsRewardsStage = 'UpgradeAccountsDebitCardsRewardsStage',
  UpgradeAccountsReadyToGoStage = 'UpgradeAccountsReadyToGoStage',
}

export type UpgradeAccountsCompleteStateConfigType = {
  pathname?: string
}

const UpgradeAccountsCompleteStateSchema = z.object({
  stage: z.nativeEnum(UpgradeAccountsCompleteStage),
})

export type UpgradeAccountsCompleteState = z.infer<
  typeof UpgradeAccountsCompleteStateSchema
>

export const UpgradeAccountsCompleteLocationStateSchema = z.object({
  UpgradeAccountsCompleteModal: UpgradeAccountsCompleteStateSchema,
})
