import { gql } from '@apollo/client'
import { IncomeAndExpenseLegend } from '@npco/mp-feature-cashflow-reporting'
import { useTranslations } from '@npco/utils-translations'
import { Typography } from '@npco/zeller-design-system'

import { AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment } from './AccountsIncomeAndExpenseTopSection.generated'
import { accountsIncomeAndExpenseTopSectionTranslations } from './AccountsIncomeAndExpenseTopSection.i18n'
import * as styled from './AccountsIncomeAndExpenseTopSection.styled'

type AccountsIncomeAndExpenseTopSectionProps = {
  incomeAndExpenses: AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment[]
  isFetchingMore: boolean
}

export const AccountsIncomeAndExpenseTopSection = ({
  incomeAndExpenses,
  isFetchingMore,
}: AccountsIncomeAndExpenseTopSectionProps) => {
  const t = useTranslations(accountsIncomeAndExpenseTopSectionTranslations)

  return (
    <styled.AccountsIncomeAndExpenseTopSectionWrapper>
      <Typography variant="heading-md" mb="4px">
        {t('header')}
      </Typography>
      {!isFetchingMore && (
        <IncomeAndExpenseLegend incomeAndExpenses={incomeAndExpenses} />
      )}
    </styled.AccountsIncomeAndExpenseTopSectionWrapper>
  )
}

AccountsIncomeAndExpenseTopSection.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment
    }

    ${IncomeAndExpenseLegend.fragments.CashFlowPeriodicTotalAmount}
  `,
}
