import { useTranslations } from '@npco/utils-translations'
import { DecisionModal } from '@npco/zeller-design-system'

import { translations } from './ConfirmationModal.i18'

interface ConfirmationModalProps {
  isOpen: boolean
  onCancel: () => void
  onClickSecondary: () => void
  onClickPrimary: () => void
}

export const ConfirmationModal = ({
  isOpen,
  onCancel,
  onClickSecondary,
  onClickPrimary,
}: ConfirmationModalProps) => {
  const t = useTranslations(translations)

  return (
    <DecisionModal
      isOpen={isOpen}
      onCancel={onCancel}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      title={t('title')}
      primaryButtonLabel={t('primaryButtonLabel')}
      secondaryButtonLabel={t('secondaryButtonLabel')}
    >
      {t('description')}
    </DecisionModal>
  )
}
