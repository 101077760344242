import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { useTokenUserMetadata } from 'auth/ZellerAuthenticationContext'

export const useEntityUuidOnboarding = () => {
  const { userData } = useLoggedInUser()
  const tokenMetadata = useTokenUserMetadata()
  const entityUuid =
    rvEntityDetails()?.id ?? userData?.entityUuid ?? tokenMetadata?.entityUuid

  return { entityUuid }
}
