import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { TransactionFilterStatus } from 'types/transactions'
import { Items, MultiSelect } from 'components/Filters/PillFilters/MultiSelect'

import { translations } from './Status.i18n'

type StatusProps = {
  selectedStatuses?: `${TransactionFilterStatus}`[]
  setSelectedStatuses: (statuses: `${TransactionFilterStatus}`[]) => void
  disabledStatuses?: `${TransactionFilterStatus}`[]
  hiddenStatuses?: `${TransactionFilterStatus}`[]
}

export const Status = ({
  selectedStatuses,
  setSelectedStatuses,
  disabledStatuses,
  hiddenStatuses,
}: StatusProps) => {
  const t = useTranslations(translations)

  const items = useMemo(() => {
    const items = [
      { id: '1', label: t('approved'), value: 'APPROVED' },
      { id: '2', label: t('declined'), value: 'DECLINED' },
      { id: '3', label: t('refunded'), value: 'REFUNDED' },
    ] satisfies Items<`${TransactionFilterStatus}`>
    return items.filter((item) => !hiddenStatuses?.includes(item.value))
  }, [hiddenStatuses, t])

  return (
    <MultiSelect
      onSelectItem={setSelectedStatuses}
      selectedItems={selectedStatuses}
      items={items}
      defaultLabel={t('statusLabel')}
      disabledItems={disabledStatuses}
    />
  )
}
