import { useTranslations } from '@npco/utils-translations'
import { NumberBadge, NumberBadgeVariant } from '@npco/zeller-design-system'

import { cardTransactionsMissingDetailsCountCellTranslations } from './CardTransactionsMissingDetailsCountCell.i18n'

interface CardTransactionsMissingDetailsCountCellContentProps {
  count: number
}
export const CardTransactionsMissingDetailsCountCellContent = ({
  count,
}: CardTransactionsMissingDetailsCountCellContentProps) => {
  const t = useTranslations(cardTransactionsMissingDetailsCountCellTranslations)

  return (
    <NumberBadge
      variant={NumberBadgeVariant.SoftAlert}
      text={`${count > 99 ? t('outstandingTransactionsCountMax') : count}`}
    />
  )
}
