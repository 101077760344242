import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'

import { useInvoiceAnalytics } from '../../../components/Invoices/hooks/useInvoiceAnalytics'
import { useInvoicesContext } from '../../../components/Invoices/InvoicesContext/InvoicesContext'
import { AllStatusesSelected } from '../../../components/Invoices/InvoiceTable/InvoiceTable.utils'

export const useInvoicingListHandlers = () => {
  const { trackInvoiceInitiated } = useInvoiceAnalytics()

  const history = useHistory()

  const shortEntityId = useSelectedShortEntityUuid()

  const {
    filterInput,
    setIsMobileFiltersOpen,
    setSearchFilter,
    sortingObject,
  } = useInvoicesContext()

  const handleClickFilters = () => {
    setIsMobileFiltersOpen(true)
  }

  const handleClickInvoice = () => {
    trackInvoiceInitiated()
    history.push(ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.NEW.path)
  }

  const handleClickSettings = () =>
    history.push(ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.SETTINGS.path)

  const handleClickRetry = () =>
    history.push(ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.path)

  const handleSearch = (nextSearch: string) => {
    setSearchFilter(nextSearch)
  }

  const isMobileFiltersActive = useMemo(() => {
    if (
      filterInput?.amountFilter ||
      filterInput?.dateFilter ||
      filterInput?.statusFilter ||
      filterInput?.textSearchFilter
    ) {
      return (
        filterInput?.statusFilter?.values?.length !== AllStatusesSelected.length
      )
    }
    return !!sortingObject?.columnName
  }, [
    filterInput?.amountFilter,
    filterInput?.dateFilter,
    filterInput?.statusFilter,
    filterInput?.textSearchFilter,
    sortingObject,
  ])

  const searchDefaultValue = filterInput?.textSearchFilter || ''

  return {
    handleClickFilters,
    handleClickInvoice,
    handleClickRetry,
    handleClickSettings,
    handleSearch,
    isMobileFiltersActive,
    searchDefaultValue,
  }
}
