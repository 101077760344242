import { useNavigate } from 'react-router-dom-v5-compat'
import { useMutation } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { CreateCustomerForSignUp } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'

import { ROOT } from 'const/routes'
import {
  CreateCustomerForSignUp as CreateCustomerForSignUpType,
  CreateCustomerForSignUpVariables,
} from 'types/gql-types/CreateCustomerForSignUp'

export const useCreateCustomer = () => {
  const navigate = useNavigate()

  const [createCustomer, { called: isCreateCustomerCalled }] = useMutation<
    CreateCustomerForSignUpType,
    CreateCustomerForSignUpVariables
  >(CreateCustomerForSignUp, {
    onError: (error) => {
      showApiErrorToast(error)
    },
    onCompleted: () => {
      navigate(ROOT.REGISTER_PHONE.path)
    },
  })

  return {
    createCustomer,
    isCreateCustomerCalled,
  }
}
