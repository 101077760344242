import { useTranslations } from '@npco/utils-translations'
import { COLOR, SvgIcon, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'

import { ReactComponent as PercentageIcon } from 'assets/svg/percentage.svg'
import { GetSite_getSite_surchargesTaxes as SurchargesTaxes } from 'types/gql-types/GetSite'
import {
  getMaxCardSurchargeValue,
  validateSurchargePercent,
} from 'pages/Settings/SitePayments/SitePayments.utils'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import { translations } from './Surcharge.i18n'

export interface Props {
  surchargesTaxes?: SurchargesTaxes | null
}

export const SurchargeInput = ({ surchargesTaxes }: Props) => {
  const isDisabledSurchargeInput =
    !surchargesTaxes?.feePercent && surchargesTaxes?.feePercent !== 0
  const t = useTranslations(translations)

  return (
    <InputAdaptiveFieldWrapper
      size={TEXT_INPUT_SIZE.SMALL}
      label={t('cardSurcharge')}
      name="surchargePercent"
      placeholder={t('cardSurcharge')}
      step=".01"
      type="number"
      validate={validateSurchargePercent(
        getMaxCardSurchargeValue(surchargesTaxes?.feePercent)
      )}
      disabled={isDisabledSurchargeInput}
      min={0}
      renderRightControls={({ hasError }) => (
        <SvgIcon
          width="16"
          height="16"
          withMarginRight={hasError}
          color={COLOR.BLUE_1000}
        >
          <PercentageIcon />
        </SvgIcon>
      )}
    />
  )
}
