import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ReactiveVar, useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { MultiEntityStore } from './MultiEntityStore'
import {
  GetEntityLocalStorageDataType,
  GetEntityReactiveVariableDataType,
  GetEntitySessionDataType,
  SavedEntitySession,
} from './MultiEntityStore.types'
import { MultiEntityStoreContext } from './MultiEntityStoreContext'

interface MultiEntityStoreProviderProps {
  children: React.ReactNode
  keyToReactiveVarMap: { [key: string]: ReactiveVar<any> }
  getEntityReactiveVariableData: GetEntityReactiveVariableDataType
  getEntityLocalStorageData: GetEntityLocalStorageDataType
  getEntitySessionData: GetEntitySessionDataType
  resetEntityReactiveVariables: () => void
  resetEntitySessionData: () => void
  initialEntitySessions: SavedEntitySession[]
  convertToShortUuid: (entityUuid: string) => string
}

export const MultiEntityStoreProvider = ({
  children,
  keyToReactiveVarMap,
  getEntityReactiveVariableData,
  getEntityLocalStorageData,
  getEntitySessionData,
  resetEntityReactiveVariables,
  resetEntitySessionData,
  initialEntitySessions,
  convertToShortUuid,
}: MultiEntityStoreProviderProps) => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const entityUuid = useSelectedEntityUuid()
  // replace it with a hook in the future

  const multiEntityStore = useMemo(
    () =>
      new MultiEntityStore({
        navigate,
        keyToReactiveVarMap,
        getEntityReactiveVariableData,
        getEntityLocalStorageData,
        getEntitySessionData,
        resetEntityReactiveVariables,
        resetEntitySessionData,
        initialEntitySessions,
        client,
        convertToShortUuid,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    multiEntityStore.setNavigate(navigate)
  }, [multiEntityStore, navigate])

  useEffect(() => {
    multiEntityStore.setCurrentEntityId(entityUuid)
  }, [entityUuid, multiEntityStore])

  const value = useMemo(
    () => ({
      changeCurrentEntitySession: multiEntityStore.changeCurrentEntitySession,
      removeEntitySession: multiEntityStore.removeEntitySession,
      addEntitySession: multiEntityStore.addEntitySession,
      reorderEntitySessions: multiEntityStore.reorderEntitySessions,
      subscribe: multiEntityStore.subscribe,
    }),
    [
      multiEntityStore.addEntitySession,
      multiEntityStore.changeCurrentEntitySession,
      multiEntityStore.removeEntitySession,
      multiEntityStore.reorderEntitySessions,
      multiEntityStore.subscribe,
    ]
  )

  return (
    <MultiEntityStoreContext.Provider value={value}>
      {children}
    </MultiEntityStoreContext.Provider>
  )
}
