import { useCallback, useEffect, useState } from 'react'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { page } from 'translations'

import { useReportsFetch } from './Reports.hooks'
import { ReportsContent } from './ReportsContent/ReportsContent'
import { ReportsFilters } from './ReportsFilters/ReportsFilters'

export const Reports = () => {
  const [areFiltersVisible, setAreFiltersVisible] = useState(false)
  const {
    isLoading,
    hasNoResults,
    hasNoInitialResults,
    totalSaleChartData,
    timeOfDayChartData,
    dayOfWeekChartData,
    totalDeclinedChartData,
    timeOfDayDeclinedChartData,
    dayOfWeekDeclinedChartData,
    isSelectionMoreThenTwoMonths,
    transactionsSum,
  } = useReportsFetch()

  useEffect(() => {
    setAreFiltersVisible(true)
  }, [])

  const handleToggleFilters = useCallback(
    () => setAreFiltersVisible((prev) => !prev),
    [setAreFiltersVisible]
  )

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex
            width="100%"
            height="48px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{page.title.reports}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
            <DashboardFiltersButton
              disabled={isLoading}
              isActive={areFiltersVisible}
              onClick={handleToggleFilters}
              showIndicator
            />
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        <PageTemplate.HeaderSecondaryRow>
          <ReportsFilters areFiltersVisible={areFiltersVisible} />
        </PageTemplate.HeaderSecondaryRow>
      }
    >
      <ReportsContent
        isLoading={isLoading}
        hasNoResults={hasNoResults}
        hasNoInitialResults={hasNoInitialResults}
        transactionsSum={transactionsSum}
        totalSaleChartData={totalSaleChartData}
        timeOfDayChartData={timeOfDayChartData}
        dayOfWeekChartData={dayOfWeekChartData}
        totalDeclinedChartData={totalDeclinedChartData}
        timeOfDayDeclinedChartData={timeOfDayDeclinedChartData}
        dayOfWeekDeclinedChartData={dayOfWeekDeclinedChartData}
        isSelectionMoreThenTwoMonths={isSelectionMoreThenTwoMonths}
      />
    </PageTemplate>
  )
}
