import { MessageSize, MessageWithIcon } from '@npco/zeller-design-system'

import * as styled from './EmptyStateMessageWithCard.styled'
import {
  IllustrationName,
  useGetIllustrationFromName,
} from './useGetIllustrationFromName'

type Props = {
  title: string
  description: React.ReactNode
  illustrationName: IllustrationName
  card: React.ReactNode
}
export const EmptyStateMessageWithCard = ({
  title,
  description,
  illustrationName,
  card,
}: Props) => {
  const image = useGetIllustrationFromName(illustrationName)

  return (
    <styled.Container>
      <MessageWithIcon
        size={MessageSize.LARGE}
        title={title}
        image={image}
        description={description}
      />
      {card}
    </styled.Container>
  )
}
