import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled(Flex)`
  background: #f5fff8;
  border-radius: 12px;
  font-family: 'Inter Zeller';
  font-style: normal;
  font-weight: normal;
  align-items: center;
  border: 1px solid #e3fdeb;
  width: 100%;
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: 680px;
    height: 48px;
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  width: 100%;
  justify-content: space-between;
  gap: 8px;
  margin: 0;
  padding: 24px;
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    padding: 16px 24px 16px 24px;
  }
`
