import { useEffect, useMemo, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import {
  AllInvoiceTableColumn,
  AmountColumnEnum,
  DateColumnEnum,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { InvoiceAmountHeaderCell } from '../InvoiceAmountHeaderCell/InvoiceAmountHeaderCell'
import { InvoiceAmountRowCell } from '../InvoiceAmountRowCell/InvoiceAmountRowCell'
import { InvoiceContactHeaderCell } from '../InvoiceContactHeaderCell/InvoiceContactHeaderCell'
import { InvoiceContactRowCell } from '../InvoiceContactRowCell/InvoiceContactRowCell'
import { InvoiceDateHeaderCell } from '../InvoiceDateHeaderCell/InvoiceDateHeaderCell'
import { InvoiceDateRowCell } from '../InvoiceDateRowCell/InvoiceDateRowCell'
import { InvoiceIdHeaderCell } from '../InvoiceIdHeaderCell/InvoiceIdHeaderCell'
import { InvoiceIdRowCell } from '../InvoiceIdRowCell/InvoiceIdRowCell'
import { InvoiceStatusHeaderCell } from '../InvoiceStatusHeaderCell/InvoiceStatusHeaderCell'
import { InvoiceStatusRowCell } from '../InvoiceStatusRowCell/InvoiceStatusRowCell'
import { TableColumnMaxWidth } from '../InvoiceTable.utils'
import { InvoiceMobileHeaderCell } from '../InvoiceTableMobile/InvoiceMobileHeaderCell/InvoiceMobileHeaderCell'
import { InvoiceMobileRowCell } from '../InvoiceTableMobile/InvoiceMobileRowCell/InvoiceMobileRowCell'
import { InvoiceTitleHeaderCell } from '../InvoiceTitleHeaderCell/InvoiceTitleHeaderCell'
import { InvoiceTitleRowCell } from '../InvoiceTitleRowCell/InvoiceTitleRowCell'

type GetColumnVisibilityProps = {
  isBelowLGBreakpoint: boolean
  isMobileResolution: boolean
  selectedAmountColumn: AmountColumnEnum
  selectedDateColumn: DateColumnEnum
}

const getColumnVisibility = ({
  isBelowLGBreakpoint,
  isMobileResolution,
  selectedAmountColumn,
  selectedDateColumn,
}: GetColumnVisibilityProps) => ({
  [AllInvoiceTableColumn.Title]: !isBelowLGBreakpoint && !isMobileResolution,
  [AllInvoiceTableColumn.NextDue]:
    !isMobileResolution && selectedDateColumn === AllInvoiceTableColumn.NextDue,
  [AllInvoiceTableColumn.Created]:
    !isMobileResolution && selectedDateColumn === AllInvoiceTableColumn.Created,
  [AllInvoiceTableColumn.Issued]:
    !isMobileResolution && selectedDateColumn === AllInvoiceTableColumn.Issued,
  [AllInvoiceTableColumn.Sent]:
    !isMobileResolution && selectedDateColumn === AllInvoiceTableColumn.Sent,
  [AllInvoiceTableColumn.DatePaid]:
    !isMobileResolution &&
    selectedDateColumn === AllInvoiceTableColumn.DatePaid,
  [AllInvoiceTableColumn.AmountPaid]:
    !isMobileResolution &&
    selectedAmountColumn === AllInvoiceTableColumn.AmountPaid,
  [AllInvoiceTableColumn.Total]:
    !isMobileResolution && selectedAmountColumn === AllInvoiceTableColumn.Total,
  [AllInvoiceTableColumn.Outstanding]:
    !isMobileResolution &&
    selectedAmountColumn === AllInvoiceTableColumn.Outstanding,
  [AllInvoiceTableColumn.Mobile]: isMobileResolution,
  [AllInvoiceTableColumn.ID]: !isMobileResolution,
  [AllInvoiceTableColumn.Contact]: !isMobileResolution,
  [AllInvoiceTableColumn.Status]: !isMobileResolution,
})

interface UseInvoiceTableColumnsProps {
  isBelowLGBreakpoint: boolean
  isMobileResolution: boolean
  selectedAmountColumn: AmountColumnEnum
  selectedDateColumn: DateColumnEnum
}

export const useInvoiceTableColumns = ({
  isBelowLGBreakpoint,
  isMobileResolution,
  selectedAmountColumn,
  selectedDateColumn,
}: UseInvoiceTableColumnsProps) => {
  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >(
    getColumnVisibility({
      isBelowLGBreakpoint,
      isMobileResolution,
      selectedAmountColumn,
      selectedDateColumn,
    })
  )

  const columns = useMemo<ColumnDef<Invoice | null>[]>(
    () => [
      {
        id: AllInvoiceTableColumn.ID,
        accessorFn: (props) => props?.referenceNumber,
        cell: InvoiceIdRowCell,
        header: InvoiceIdHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.ID],
      },
      {
        id: AllInvoiceTableColumn.Contact,
        accessorFn: (props) => props?.payerContactName,
        cell: InvoiceContactRowCell,
        header: InvoiceContactHeaderCell,
        size: !columnVisibility?.title
          ? undefined
          : TableColumnMaxWidth[AllInvoiceTableColumn.Contact],
      },
      {
        id: AllInvoiceTableColumn.Title,
        accessorFn: (props) => props?.title,
        cell: InvoiceTitleRowCell,
        header: InvoiceTitleHeaderCell,
        size: undefined,
      },
      {
        id: AllInvoiceTableColumn.Status,
        accessorFn: (props) => props?.status,
        cell: InvoiceStatusRowCell,
        header: InvoiceStatusHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.Status],
      },
      {
        id: AllInvoiceTableColumn.NextDue,
        accessorFn: (props) => props?.dueDate,
        cell: InvoiceDateRowCell,
        header: InvoiceDateHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.NextDue],
      },
      {
        id: AllInvoiceTableColumn.Created,
        accessorFn: (props) => props?.createdTime,
        cell: InvoiceDateRowCell,
        header: InvoiceDateHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.Created],
      },
      {
        id: AllInvoiceTableColumn.Issued,
        accessorFn: (props) => props?.startDate,
        cell: InvoiceDateRowCell,
        header: InvoiceDateHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.Issued],
      },
      {
        id: AllInvoiceTableColumn.Sent,
        accessorFn: (props) => props?.sentTime,
        cell: InvoiceDateRowCell,
        header: InvoiceDateHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.Sent],
      },
      {
        id: AllInvoiceTableColumn.DatePaid,
        accessorFn: (props) => props?.paidTime,
        cell: InvoiceDateRowCell,
        header: InvoiceDateHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.DatePaid],
      },
      {
        id: AllInvoiceTableColumn.AmountPaid,
        accessorFn: (props) => props?.paidAmount,
        cell: InvoiceAmountRowCell,
        header: InvoiceAmountHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.AmountPaid],
      },
      {
        id: AllInvoiceTableColumn.Total,
        accessorFn: (props) => props?.totalAmount,
        cell: InvoiceAmountRowCell,
        header: InvoiceAmountHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.Total],
      },
      {
        id: AllInvoiceTableColumn.Outstanding,
        accessorFn: (props) => props?.dueAmount,
        cell: InvoiceAmountRowCell,
        header: InvoiceAmountHeaderCell,
        size: TableColumnMaxWidth[AllInvoiceTableColumn.Outstanding],
      },
      {
        id: AllInvoiceTableColumn.Mobile,
        accessorFn: (props) => props,
        cell: InvoiceMobileRowCell,
        header: InvoiceMobileHeaderCell,
        size: undefined,
      },
    ],
    [columnVisibility]
  )

  useEffect(() => {
    const nextColumnVisibility = getColumnVisibility({
      isBelowLGBreakpoint,
      isMobileResolution,
      selectedAmountColumn,
      selectedDateColumn,
    })

    setColumnVisibility(nextColumnVisibility)
  }, [
    isMobileResolution,
    isBelowLGBreakpoint,
    selectedDateColumn,
    selectedAmountColumn,
  ])

  return { columns, columnVisibility }
}
