import ReactCrop from 'react-image-crop'
import styled from 'styled-components'

export const StyledReactCrop = styled(ReactCrop)`
  &.imageCropper .ReactCrop__crop-selection {
    border: none;
    border-image: none;
  }

  &.imageCropper .ReactCrop__drag-handle::after {
    background-color: ${({ theme }) => theme.colors.WHITE};
    border: none;
    border-radius: 50%;
  }
`
