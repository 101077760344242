import { useCallback, useEffect, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'

import {
  GeneratePinToken as GeneratePinTokenResponse,
  GeneratePinTokenVariables,
} from 'types/gql-types/GeneratePinToken'

import { useSetPinState } from '../../hooks/useSetPinState'
import { GeneratePinToken } from '../graphql/GeneratePinToken'

export const useSetPinTokens = () => {
  const { userData } = useLoggedInUser()
  const { state } = useSetPinState()
  const entityUuid = useSelectedEntityUuid()

  const [generatePinToken, { data, loading, error, called }] = useMutation<
    GeneratePinTokenResponse,
    GeneratePinTokenVariables
  >(GeneratePinToken, {
    variables: { entityUuid, debitCardId: state?.cardId || '' },
  })

  const generateToken = useCallback(async () => {
    try {
      await generatePinToken()
    } catch (err) {
      ErrorLogger.report('[Core] Error generating pin token', err)
    }
  }, [generatePinToken])

  useEffect(() => {
    generateToken()
  }, [generateToken])

  const oneTimeToken = useMemo(
    () => data?.generatePinToken,
    [data?.generatePinToken]
  )

  return {
    oneTimeToken,
    userToken: userData?.id,
    cardToken: state?.cardId,
    isLoading: loading || !called,
    hasError: Boolean(error) || !state?.cardId,
    retry: generateToken,
  }
}
