import { createContext } from 'react'

import { CreateExternalAccountState } from '../CreateExternalAccount.types'

export interface CreateExternalAccountContextType {
  state: CreateExternalAccountState | undefined
  goToCreateExternalAccountState: (
    newState: CreateExternalAccountState | undefined
  ) => void
}

export const CreateExternalAccountContext = createContext<
  CreateExternalAccountContextType | undefined
>(undefined)

CreateExternalAccountContext.displayName = 'CreateExternalAccountContext'
