import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseMultiEntityTabsCustomerEntityRelationFragmentDoc } from '../../useMultiEntityTabs/useMultiEntityTabs.generated'

const defaultOptions = {} as const
export type TopBarNavQueryVariables = Types.Exact<{ [key: string]: never }>

export type TopBarNavQueryResponse = {
  __typename?: 'Query'
  getCustomerEntityMapping: {
    __typename?: 'CustomerEntityMapping'
    entityRelations: Array<{
      __typename?: 'CustomerEntityRelation'
      entityUuid: string
      isVisible: boolean
      order: number | null
      entity: { __typename?: 'Entity'; id: string; name: string | null }
    }> | null
  }
}

export const TopBarNavQuery = gql`
  query TopBarNavQuery {
    getCustomerEntityMapping {
      entityRelations {
        ...UseMultiEntityTabsCustomerEntityRelationFragment
      }
    }
  }
  ${UseMultiEntityTabsCustomerEntityRelationFragmentDoc}
` as unknown as TypedDocumentNode<
  TopBarNavQueryResponse,
  TopBarNavQueryVariables
>

/**
 * __useTopBarNavQuery__
 *
 * To run a query within a React component, call `useTopBarNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBarNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBarNavQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopBarNavQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TopBarNavQueryResponse,
    TopBarNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TopBarNavQueryResponse, TopBarNavQueryVariables>(
    TopBarNavQuery,
    options
  )
}
export function useTopBarNavQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopBarNavQueryResponse,
    TopBarNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TopBarNavQueryResponse, TopBarNavQueryVariables>(
    TopBarNavQuery,
    options
  )
}
export type TopBarNavQueryHookResult = ReturnType<typeof useTopBarNavQuery>
export type TopBarNavQueryLazyQueryHookResult = ReturnType<
  typeof useTopBarNavQueryLazyQuery
>
export type TopBarNavQueryQueryResult = Apollo.QueryResult<
  TopBarNavQueryResponse,
  TopBarNavQueryVariables
>
