import {
  Box,
  Flex,
  MagnifyingGlassNoResults,
  Message,
} from '@npco/zeller-design-system'
import { useTheme } from 'styled-components'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

interface ZellerCardSummaryErrorProps {
  retry: () => void
}

export const ZellerCardSummaryError = ({
  retry,
}: ZellerCardSummaryErrorProps) => {
  const { colors } = useTheme()
  const isMobile = useIsMobileResolution()
  const align = isMobile ? 'center' : 'left'

  return (
    <Flex
      backgroundColor={colors.GREY_30}
      justifyContent="center"
      borderRadius="8px"
      py={['7px', '38px']}
    >
      <Flex flexDirection={['column', 'row']}>
        <Box data-testid="debit-card-error-illustration" pr={['0', '48px']}>
          <MagnifyingGlassNoResults size={isMobile ? 'medium' : 'large'} />
        </Box>
        <Box>
          <Message
            title={translate('page.cardSingle.cardSummaryError.title')}
            description={
              <Box textAlign={align}>
                <>{translate('page.cardSingle.cardSummaryError.subtitle1')}</>
                {isMobile ? ' ' : <br />}
                <>{translate('page.cardSingle.cardSummaryError.subtitle2')}</>
              </Box>
            }
            align={align}
            primaryButton={{
              label: translate('page.cardSingle.cardSummaryError.buttonLabel'),
              onClick: retry,
            }}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
