import { ReactNode } from 'react'

import { Container } from './CardsView.styled'

interface CardsViewProps {
  hasCards: boolean
  isLoading?: boolean
  children: ReactNode
  emptyView: ReactNode
}

export const CardsView = ({
  hasCards,
  isLoading,
  children,
  emptyView,
}: CardsViewProps) => {
  if (!hasCards && !isLoading) {
    return <>{emptyView}</>
  }

  return <Container gap="40px">{children}</Container>
}
