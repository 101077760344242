import { GetSite_getSite_devices as ApiDevice } from 'types/gql-types/GetSite'

export type Device = ApiDevice

export enum DeviceModel {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  NEW9220 = 'NEW9220',
  T2 = 'T2',
}
