import {
  NotesBasic,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { rvTransactionsNotes } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'
import { EditNotesModal } from 'components/EditNotesModal/EditNotesModal'
import { page } from 'translations'

import {
  useDeleteTransactionNotes,
  useUpdateTransactionNotes,
} from './TransactionNotes.hooks'

const showTransactionNoteErrorToast = () =>
  showErrorToast(translate('page.transactions.notes.errorToast'))

export const TransactionNotes = ({
  notes = '',
  transactionUuid,
}: {
  notes: string | null | undefined
  transactionUuid: string
}) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { deleteTransactionNotes, isDeleting } = useDeleteTransactionNotes()
  const { updateTransactionNotes, isUpdating } = useUpdateTransactionNotes()

  const transactionNotes = rvTransactionsNotes()
  const currentNotes =
    transactionUuid in transactionNotes
      ? transactionNotes[transactionUuid]
      : notes

  const handleSubmit = async (updatedNotes: string) => {
    try {
      const isDirty = currentNotes !== updatedNotes

      if (updatedNotes === '' && isDirty) {
        const response = await deleteTransactionNotes(transactionUuid)

        if (!response.data?.deleteTransactionNotes) {
          showTransactionNoteErrorToast()
          return
        }

        showSuccessToast(
          translate('page.transactions.notes.removeSuccessToast')
        )
      } else {
        if (isDirty) {
          const response = await updateTransactionNotes(
            transactionUuid,
            updatedNotes
          )

          if (!response.data?.updateTransactionNotes) {
            showTransactionNoteErrorToast()
            return
          }
        }

        showSuccessToast(
          translate('page.transactions.notes.updateSuccessToast')
        )
      }

      rvTransactionsNotes({
        ...transactionNotes,
        [transactionUuid]: updatedNotes,
      })

      closeModal()
    } catch (error) {
      showTransactionNoteErrorToast()
    }
  }

  return (
    <>
      <NotesBasic
        title={page.transactions.notes.title}
        note={currentNotes}
        onEdit={openModal}
        placeholder={page.transactions.notes.emptyState}
        addLabel={translate('shared.add')}
        editLabel={translate('shared.edit')}
      />
      <EditNotesModal
        handleSubmit={handleSubmit}
        isLoading={isDeleting || isUpdating}
        isOpen={isModalOpen}
        notes={currentNotes}
        onCancel={closeModal}
        placeholder={page.transactions.notes.placeholder}
        title={page.transactions.notes.title}
      />
    </>
  )
}
