import { ROOT } from '../routes'
import { type OnboardingFlowConfigItem } from './onboarding-config.types'

export const SHARED_KYC_FLOW_CONFIG_ITEMS: OnboardingFlowConfigItem[] = [
  {
    path: ROOT.ONBOARDING.KYC.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.KYC.YOUR_INFORMATION.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.MEDICARE.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.PASSPORT.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.DRIVING_LICENCE.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.RESIDENTIAL_ADDRESS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.VERIFICATION_FAILED.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.NO_IDENTIFICATION.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.KYC.TIME_FOR_A_SELFIE.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.KYC.SELFIE_VERIFICATION.path,
    isCheckpoint: false,
  },
]
