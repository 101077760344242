import { useTranslations } from '@npco/utils-translations'
import { COLOR, MenubarsIcon, SvgIcon } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { useSidebarLocalCache } from '../../hooks/useSidebarLocalCache'
import * as styled from './SidebarHeader.styled'

export const SidebarHeader = () => {
  const { isFolded, toggleFold } = useSidebarLocalCache()
  const t = useTranslations(translationsShared)

  return (
    <styled.Header>
      <styled.FoldButton
        aria-controls="menu-sidebar"
        aria-expanded={!isFolded}
        aria-label={isFolded ? t('maximiseMenu') : t('minimiseMenu')}
        onClick={toggleFold}
      >
        <SvgIcon
          color={isFolded ? COLOR.BLUE_1000 : COLOR.GREY_250}
          height="20"
          width="20"
          ariaHidden
        >
          <MenubarsIcon />
        </SvgIcon>
      </styled.FoldButton>
      <styled.SidebarLogo $isFolded={isFolded} />
    </styled.Header>
  )
}
