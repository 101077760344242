import {
  BodyDefaultTypography,
  BodyXSmall,
  Flex,
  FlexProps,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTextAreaContainer = styled(Flex)<FlexProps>`
  position: relative;
`

export const StyledTextAreaBody = styled.textarea<{ shouldShowError: boolean }>`
  height: 105px;
  width: 100%;
  margin-right: -8px;
  padding: 14px 16px;
  background-color: ${({ theme }) => theme.colors.GREY_60};
  border: 1px solid ${({ theme }) => theme.colors.GREY_60};
  border-radius: 3px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.BLACK};
  caret-color: ${({ theme }) => theme.colors.BLUE_1000};
  resize: none;
  outline: none;
  transition: border-color 0.3s ease-out;
  font-family: inherit;
  ${BodyDefaultTypography}

  &:focus {
    border-color: ${({ shouldShowError, theme }) =>
      shouldShowError ? theme.colors.RED_1000 : theme.colors.BLUE_1000};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.GREY_150};
  }

  ${({ shouldShowError, theme }) =>
    shouldShowError && `border-color: ${theme.colors.RED_1000};`}
`

export const StyledErrorMessage = styled(BodyXSmall)`
  position: absolute;
  bottom: -36px;
  left: 0;
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.RED_1000};
`
