import {
  BodyDefaultTypography,
  BodySmallTypography,
  Flex,
  H5,
  H6,
} from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledButton } from 'components/Buttons/Button/Button.styled'

export const StyledSimModalWrapper = styled(Flex)`
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
  width: 100%;
  height: 100%;
  max-width: 28rem;
  overflow-y: auto;
  overflow-x: hidden;
`

export const StyledGeneralFirst = styled.p`
  margin: 0 0 1.5rem;
`

export const StyledGeneralSecond = styled.p`
  margin: 0 0 1rem;
`

export const StyledGeneralList = styled.ul`
  padding-left: 1.5rem;
`

export const StyledButtonWrapper = styled(Flex)`
  margin-top: 1.5rem;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-items: stretch;

  ${StyledButton} {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    ${StyledButton} {
      margin-bottom: 0;
      flex: 0 0 48%;
    }
  }
`

export const StyledDivider = styled.div`
  width: 100%;
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};
`

export const StyledSummaryTableItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.GREY_150};
  border-bottom: none;
  padding: 1rem;
  margin: 0;
  ${BodySmallTypography};

  &:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  &:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};
  }
`
export const StyledH6 = styled(H6)`
  margin: 0 0 0.25rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledH5 = styled(H5)`
  margin: 1rem 0 0.5rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledSummaryText = styled.p`
  ${BodyDefaultTypography};
  margin: 0 0 10px;
  color: ${({ theme }) => theme.colors.GREY_550};

  span {
    color: ${({ theme }) => theme.colors.BLACK_900};
  }
`

export const StyledPhone = styled.span`
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledMainList = styled.ol`
  list-style: none;
  ${H5};
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0 0 1rem;
  padding-left: 2.5rem;
  counter-reset: main-counter;
`
export const StyledListMainItem = styled.li`
  margin: 0 0 1rem;
  position: relative;

  &::before {
    position: absolute;
    counter-increment: main-counter;
    content: counter(main-counter);
    margin-left: -2.5rem;
  }
`

export const StyledSubList = styled.ol`
  list-style: none;
  ${H5};
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0 0 1rem;
  padding-left: 0;
  counter-reset: sub-counter;
`

export const StyledListSubItem = styled.li`
  margin: 0 0 0.5rem;
  list-style: none;
  position: relative;

  &::before {
    position: absolute;
    counter-increment: sub-counter;
    content: counter(main-counter) '.' counter(sub-counter);
    margin-left: -2.5rem;
  }
`

export const StyledNestedList = styled.ol`
  list-style: none;
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
  counter-reset: nested-counter;
`

export const StyledListNestedItem = styled.li`
  margin: 0 0 10px;
  position: relative;

  &::before {
    position: absolute;
    counter-increment: nested-counter;
    content: '(' counter(nested-counter, lower-alpha) ')';
    margin-left: -2.5rem;
  }
`

export const StyledRomanList = styled.ol`
  list-style: none;
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
  counter-reset: roman-counter;
`

export const StyledListRomanItem = styled.li`
  margin: 0 0 10px;
  position: relative;

  &::before {
    position: absolute;
    counter-increment: roman-counter;
    content: '(' counter(roman-counter, lower-roman) ')';
    margin-left: -2.5rem;
  }
`

export const StyledGeneralText = styled.p`
  margin: 0 0 10px;
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodyDefaultTypography};
`
