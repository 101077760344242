import {
  defaultDataIdFromObject,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client'
import {
  mergeEntities,
  mergeLocalState,
  mergeWithPagination,
  paginationReadUtil,
  readLocalState,
} from 'apps/component-merchant-portal/src/graphql/cache.helpers'

export const config: InMemoryCacheConfig = {
  dataIdFromObject: (responseObject) => {
    // This is to handle caching for queries that does not return an id
    // https://www.apollographql.com/docs/react/caching/cache-configuration#customizing-identifier-generation-globally
    switch (responseObject.__typename) {
      case 'ItemSettings':
        return `ItemSettings:${responseObject.entityUuid}`
      case 'CatalogSettings':
        return `CatalogSettings:${responseObject.entityUuid}`
      case 'Customer':
        return `Customer:${responseObject.id}-${responseObject.entityUuid}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Query: {
      fields: {
        getPaymentInstruments: {
          keyArgs: ['type', 'contactUuid'],
          read: paginationReadUtil('paymentInstruments'),
          merge: mergeEntities,
        },
        getPaymentInstrumentsWithoutContact: {
          keyArgs: ['type'],
          read: paginationReadUtil('paymentInstrumentsWithoutContact'),
          merge: mergeEntities,
        },
        getCatalogItems: {
          keyArgs: ['entityUuid', 'input', ['sort', 'filter', 'limit']],
          merge: mergeWithPagination({
            entityKey: 'items',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getCatalogItemsWithOneCategory: {
          keyArgs: ['entityUuid', 'input', ['limit']],
          merge: mergeWithPagination({
            entityKey: 'items',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getCatalogItemsToReassignReportingCategory: {
          keyArgs: ['entityUuid', 'input', ['limit']],
          merge: mergeWithPagination({
            entityKey: 'items',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getCatalogCategories: {
          keyArgs: ['entityUuid', 'input', ['sort', 'filter', 'limit']],
          merge: mergeWithPagination({
            entityKey: 'categories',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getContacts: {
          keyArgs: ['contactType', 'filter', 'limit'],
          read: paginationReadUtil('contacts'),
          merge: mergeEntities,
        },
        getDebitCardsV2: {
          keyArgs: ['entityUuid', 'filter', 'limit'],
          read: paginationReadUtil('cards'),
          merge: mergeEntities,
        },
        getDeposits: {
          keyArgs: ['entityUuid', 'filter'],
          read: paginationReadUtil('deposits'),
          merge: mergeEntities,
        },
        getThirdPartyBankAccounts: {
          keyArgs: ['entityUuid', 'accounts'],
          read: paginationReadUtil('accounts'),
          merge: mergeEntities,
        },
        getTransactions: {
          keyArgs: ['entityUuid', 'filter', 'limit'],
          read: paginationReadUtil('transactions'),
          merge: mergeEntities,
        },
        getSimBills: {
          keyArgs: ['entityUuid', 'filter', 'limit'],
          read: paginationReadUtil('bills'),
          merge: mergeEntities,
        },
        getDebitCardTransactionsV2: {
          keyArgs: ['filter', 'limit', 'entityUuid'],
          read: paginationReadUtil('transactions'),
          merge: mergeEntities,
        },
        getDebitCardTransactions: {
          keyArgs: [
            'entityUuid',
            'filter',
            'limit',
            'isOutstanding',
            'debitCardId',
          ],
          merge: mergeWithPagination({
            entityKey: 'transactions',
            nextTokenPath: ['nextToken'],
          }),
        },
        getSites: {
          keyArgs: ['entityUuid', 'filter', 'limit'],
          read: paginationReadUtil('sites'),
          merge: mergeEntities,
        },
        getItems: {
          keyArgs: ['filter', 'limit'],
          merge: mergeWithPagination({
            entityKey: 'items',
            nextTokenPath: ['nextToken'],
          }),
        },
        getItemCategories: {
          keyArgs: ['filter'],
          merge: mergeWithPagination({
            entityKey: 'categories',
            nextTokenPath: ['nextToken'],
          }),
        },
        getVirtualTerminalRecords: {
          keyArgs: ['entityUuid', ['limit']],
          merge: mergeWithPagination({
            entityKey: 'ecommerces',
            nextTokenPath: ['nextToken'],
          }),
        },
        getOrders: {
          keyArgs: ['entityUuid', 'input', ['filter', 'limit']],
          merge: mergeWithPagination({
            entityKey: 'orders',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getSims: {
          keyArgs: ['entityUuid', 'limit'],
          merge: mergeWithPagination({
            entityKey: 'sims',
            nextTokenPath: ['nextToken'],
          }),
        },
        getInvoices: {
          keyArgs: ['entityUuid', 'input', ['sort', 'filter', 'limit']],
          merge: mergeWithPagination({
            entityKey: 'invoices',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getNotifications: {
          keyArgs: ['input', ['sort', 'filter', 'limit']],
          merge: mergeWithPagination({
            entityKey: 'notifications',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getCatalogAttributeSets: {
          keyArgs: false,
          merge: mergeWithPagination({
            entityKey: 'attributeSets',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getCatalogItemSales: {
          keyArgs: ['input', ['filter', 'sort']],
          merge: mergeWithPagination({
            entityKey: 'items',
            nextTokenPath: ['input', 'nextToken'],
          }),
        },
        getNotificationSettings: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
        getDebitCardAccountV2: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'DebitCardAccountV2',
              id: args?.debitCardAccountUuid,
            })
          },
        },
        getDebitCardTransactionV2: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'DebitCardTransactionV2',
              id: args?.debitCardTransactionUuid,
            })
          },
        },
        getThirdPartyBankAccount: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ThirdPartyBankAccount',
              id: args?.thirdPartyBankAccountUuid,
            })
          },
        },
        getDebitCardV2: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'DebitCardV2',
              id: args?.cardId,
            })
          },
        },
        getPaymentInstrument: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'PaymentInstrument',
              id: args?.id,
            })
          },
        },
        getCustomer: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Customer',
              id: args?.customerUuid,
              entityUuid: args?.entityUuid,
            })
          },
        },
        getContact: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Contact',
              id: args?.id,
            })
          },
        },
        // NOTE: local only cache query state below
        local: {
          keyArgs: ['entityUuid'],
          read: readLocalState,
          merge: mergeLocalState,
        },
      },
    },
    CustomerEntityRelation: {
      keyFields: ['entityUuid'],
    },
    DebitCardAccountV2: {
      fields: {
        icon: {
          merge: true,
        },
        balance: {
          merge: true,
        },
        savingsAccountDetails: {
          merge: true,
        },
      },
    },
    DebitCardTransactionV2: {
      fields: {
        payerDetails: {
          merge: true,
        },
        payeeDetails: {
          merge: true,
        },
      },
    },
    Contact: {
      fields: {
        icon: {
          merge: true,
        },
      },
    },
    VelocityControlType: {
      fields: {
        isPendingSubscriptionUpdate: {
          read(cachedField = false) {
            return cachedField
          },
        },
      },
    },
  },
}

export const cache = new InMemoryCache(config)
