import { useCallback, useState } from 'react'
import { clearSessionStorage } from '@npco/utils-session-storage'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  DecisionModal,
  Ellipsize,
  Flex,
} from '@npco/zeller-design-system'
import { rvIsUserSavingOnboarding } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useSaveOnboarding } from 'features/OnboardingApp/shared/useSaveOnboarding'

import { useLogout } from 'hooks/useLogout'
import { MY_ZELLER_URL } from 'const/externalLinks'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import { SpinnerWrapped } from 'components/Spinner'

import { saveAndCloseModalTranslations } from './SaveAndCloseModal.i18n'

interface Props {
  toggleButton: React.ReactElement | null
  isModalOpen: boolean
  closeModal: () => void
}

export const SaveAndCloseModal = ({
  toggleButton,
  isModalOpen,
  closeModal,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { saveOnboarding } = useSaveOnboarding()
  const t = useTranslations(saveAndCloseModalTranslations)

  const { logout } = useLogout()

  const onSubmit = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    await saveOnboarding()

    logout()
  }, [saveOnboarding, logout, isLoading])

  const handleRedirectionLinkClick = useCallback(async () => {
    setIsLoading(true)
    await saveOnboarding()
    clearSessionStorage<SESSION_STORAGE_KEYS>(
      SESSION_STORAGE_KEYS.PREVIOUS_PATH
    )
    window.location.replace(MY_ZELLER_URL)
  }, [saveOnboarding])

  return (
    <>
      <Flex justifyContent="center">{toggleButton}</Flex>
      <DecisionModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        isPrimaryButtonDisabled={isLoading}
        isSecondaryButtonDisabled={isLoading}
        onClickPrimary={() => onSubmit().then(closeModal)}
        onClickSecondary={() => {
          rvIsUserSavingOnboarding(false)
          closeModal()
        }}
        primaryButtonLabel={t('primaryButtonLabel')}
        secondaryButtonLabel={t('secondaryButtonLabel')}
        title={t('title')}
      >
        {isLoading ? (
          <Ellipsize>
            <SpinnerWrapped justifyContent="center" />
          </Ellipsize>
        ) : (
          t('description', {
            link: (
              <ButtonLink isInline onClick={handleRedirectionLinkClick}>
                {t('linkLabel')}
              </ButtonLink>
            ),
          })
        )}
      </DecisionModal>
    </>
  )
}
