import { useCallback, useEffect, useRef, useState } from 'react'
import {
  ButtonAdditionalAction,
  INPUT_SIZE,
  InputAdaptiveField,
  ModalBasic,
  useModalState,
} from '@npco/zeller-design-system'
import { INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { useField } from 'formik'

import { ReactComponent as ExpandIcon } from 'assets/svg/expand.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import {
  DescriptionCharacterLength,
  DescriptionFooter,
  DescriptionTextArea,
} from './InvoiceItemDescription.styled'

export const translations = {
  cancel: translate('shared.cancel'),
  label: translate('page.invoice.formSections.items.descriptionLabel'),
  placeholder: translate('shared.startTyping'),
  save: translate('shared.save'),
  title: translate('page.invoice.formSections.items.descriptionTitle'),
}

export interface InvoiceItemDescriptionProps {
  lineIndex: number
  name: string
}

export const InvoiceItemDescription = ({
  lineIndex,
  name,
}: InvoiceItemDescriptionProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const [field, , helpers] = useField(name)
  const isMobileViewport = useIsMobileResolution()
  const { isModalOpen, openModal, closeModal } = useModalState()
  const [characterLength, setCharacterLength] = useState<number>()

  const trimmedFieldValue = String(field.value || '').substring(
    0,
    INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS
  )

  const expandButtonAriaLabel = translate(
    'page.invoice.formSections.items.lineItemAriaLabel',
    {
      index: lineIndex,
      field: 'show description modal',
    }
  )

  const inputAriaLabel = translate(
    'page.invoice.formSections.items.lineItemAriaLabel',
    {
      index: lineIndex,
      field: 'description',
    }
  )

  const textAreaAriaLabel = translate(
    'page.invoice.formSections.items.lineItemAriaLabel',
    {
      index: lineIndex,
      field: 'description text area',
    }
  )

  useEffect(() => {
    setCharacterLength(trimmedFieldValue.length || 0)
  }, [trimmedFieldValue])

  const handleChange = useCallback(
    (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
      setCharacterLength(event.currentTarget.value.length)
    },
    []
  )

  const handleSave = useCallback(() => {
    if (textAreaRef.current) {
      const { value } = textAreaRef.current
      helpers.setValue(value)
    }

    closeModal()
  }, [closeModal, helpers])

  const renderRightControls = useCallback(
    () => (
      <ButtonAdditionalAction
        aria-label={expandButtonAriaLabel}
        data-testid={`invoicing-invoice-line-item-description-${lineIndex}-expand-icon`}
        onClick={openModal}
        padding="0"
      >
        <ExpandIcon />
      </ButtonAdditionalAction>
    ),
    [expandButtonAriaLabel, lineIndex, openModal]
  )

  return (
    <>
      <InputAdaptiveField
        aria-label={inputAriaLabel}
        data-testid={`invoicing-invoice-line-item-description-${lineIndex}`}
        name={name}
        label={translations.label}
        renderRightControls={renderRightControls}
        size={INPUT_SIZE.SMALL}
      />
      {/* TICKET: Update Modal to NotesModal https://npco-dev.atlassian.net/browse/ZD-11655 */}
      <ModalBasic
        testId={`invoicing-invoice-line-item-description-modal-${lineIndex}`}
        isFullScreen={isMobileViewport}
        isOpen={isModalOpen}
        onCancel={closeModal}
      >
        <ModalBasic.HeaderForm
          onClickPrimary={handleSave}
          onClickSecondary={closeModal}
          primaryButtonLabel={translations.save}
          secondaryButtonLabel={translations.cancel}
          title={translations.title}
        />
        <ModalBasic.Body>
          <DescriptionTextArea
            aria-label={textAreaAriaLabel}
            data-testid={`invoicing-invoice-line-item-description-textarea-${lineIndex}`}
            defaultValue={field.value}
            maxLength={INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS}
            onChange={handleChange}
            placeholder={translations.placeholder}
            ref={textAreaRef}
          />
        </ModalBasic.Body>
        <DescriptionFooter>
          <DescriptionCharacterLength
            data-testid={`invoicing-invoice-line-item-description-character-count-${lineIndex}`}
          >{`${characterLength}/${INVOICE_ITEM_DESCRIPTION_MAX_CHARACTERS}`}</DescriptionCharacterLength>
        </DescriptionFooter>
      </ModalBasic>
    </>
  )
}
