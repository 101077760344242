import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Box, Heading } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { badgeLabel } from 'utils/badge'
import { translate } from 'utils/translations'
import { Card } from 'components/Cards/Card'
import { SpinnerWrapped } from 'components/Spinner'

import { useGetXeroSiteCustomers } from '../hooks/useGetXeroSiteCustomers'

interface UsersProps {
  children: React.ReactNode
}

export const Users = ({ children }: UsersProps) => {
  const navigate = useNavigate()
  const { isLoadingSiteCustomers, siteCustomers, siteUuid } =
    useGetXeroSiteCustomers()
  const shortEntityId = useSelectedShortEntityUuid()

  const onUserDetailsOpen = (userId: string) => {
    navigate(
      ROOT.ORGS.ORG(
        shortEntityId
      ).SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS.USER(userId)
        .path
    )
  }

  if (isLoadingSiteCustomers || !siteUuid) {
    return <SpinnerWrapped variant="centre" />
  }

  return (
    <div data-testid="xero-payment-services-users-container">
      <Box mb="16px">
        <Heading.H3>
          {translate('page.settings.users.assignedUsers')}
        </Heading.H3>
      </Box>
      {siteCustomers?.map((user) => (
        <Card
          key={user.id}
          type={user.role as string}
          hasBadge={false}
          text={`${user.firstname} ${user.lastname}`}
          textSmall={user?.role && badgeLabel[user.role]}
          onCardClick={() => onUserDetailsOpen(user.id)}
          isSmall
        />
      ))}
      {children}
    </div>
  )
}
