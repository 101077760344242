import { TransactionType } from '@npco/mp-gql-types'
import { pipe } from 'ramda'

import { GetTransactionTotals_getTransactionTotalsBigInt as ApiTransactionTotal } from 'types/gql-types/GetTransactionTotals'
import { TransactionInList } from 'types/transactions'
import { TransactionTotal } from 'pages/Dashboard/Dashboard.types'
import {
  getHourlyPeriod,
  groupByPeriod,
  mapTransactionToTotal,
  sumTotalsByPeriod,
} from 'pages/Dashboard/utils/transactionUpdates.utils'

const createSalesTotal = (transaction: TransactionInList) => {
  const refund = transaction.refundedAmount || 0

  return {
    period: getHourlyPeriod(transaction),
    totalAmount: transaction.amount - refund,
    countPurchases: transaction.type === TransactionType.PURCHASE ? 1 : 0,
  }
}

export const mapToSalesTransactionTotal =
  mapTransactionToTotal(createSalesTotal)

export const sumSalesTransactionTotals = ([period, totals]: [
  string,
  TransactionTotal[]
]) =>
  totals.reduce((summedTotal, totalToAdd) => ({
    period,
    totalAmount: summedTotal.totalAmount + totalToAdd.totalAmount,
    countPurchases: summedTotal.countPurchases + totalToAdd.countPurchases,
  }))

export const mergeSalesTransactionTotals = (
  totalsA: TransactionTotal[],
  totalsB: TransactionTotal[]
) => {
  return pipe(
    groupByPeriod as (
      s: TransactionTotal[]
    ) => Record<string, TransactionTotal[]>,
    sumTotalsByPeriod(sumSalesTransactionTotals)
  )([...totalsA, ...totalsB])
}

export const convertFromApiTotals = (apiTotals: ApiTransactionTotal[]) =>
  apiTotals.map((apiTotal) => ({
    period: apiTotal.period,
    totalAmount: parseInt(apiTotal.totalAmount, 10),
    countPurchases: parseInt(apiTotal.countPurchases, 10),
  }))
