/*
 * in banking application you are not allowed to use float/double because of
 * wrong precision. THat's why we are removing the ,. separators so we can
 * operate on int
 *
 * always returns 2 friction digits (or 00)
 * ex. 12.34 --> 1234
 * 12.3 --> 1230
 * 12.345 --> 1234
 * */

const MIN_VALUE = 0
const MAX_VALUE = 10000000000000000000
export const parsePriceToCents = (value: string): number => {
  const [integerPart, decimalPart = ''] = value.split('.')
  const [firstDecimalPlace = '0', secondDecimalPlace = '0'] = decimalPart

  // cover integer edge cases
  const valueAsNum = parseInt(value, 10)
  if (/e/.exec(integerPart) || valueAsNum >= MAX_VALUE) {
    if (/-/.exec(integerPart)) {
      return MIN_VALUE
    }
    return MAX_VALUE
  }

  return parseInt(integerPart + firstDecimalPlace + secondDecimalPlace, 10)
}
