import { ReactElement, ReactNode } from 'react'
import { SelectSize } from 'design-system/Components/Select'
import { SelectTriggerAdaptive } from 'design-system/Components/Select/SelectTriggerAdaptive'
import { PropGetters } from 'downshift'

interface PickerTriggerProps {
  label?: ReactNode
  triggerContent?: string
  isOpen: boolean
  placeholderIcon?: ReactElement
  size?: SelectSize
  getToggleButtonProps: PropGetters<any>['getToggleButtonProps']
  hasTriggerArrow?: boolean
}

export const PickerTrigger = ({
  getToggleButtonProps,
  hasTriggerArrow = true,
  label,
  isOpen,
  placeholderIcon,
  size = SelectSize.Small,
  triggerContent,
}: PickerTriggerProps) => {
  return (
    <SelectTriggerAdaptive
      data-testid="picker"
      size={size}
      isOpen={isOpen}
      placeholderIcon={placeholderIcon}
      label={label}
      selectedItem={
        triggerContent
          ? {
              label: triggerContent,
              value: '',
            }
          : null
      }
      {...(!hasTriggerArrow && { renderRightControls: null })}
      {...getToggleButtonProps()}
    />
  )
}
