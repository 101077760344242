import { ErrorLogger } from '@npco/utils-error-logger'

export const createFileFromUrl = async (
  url: string,
  fileName: string,
  contentType: string
) => {
  try {
    const response = await fetch(url)

    if (!response.ok) {
      return null
    }

    const data = await response.blob()
    const metadata = {
      type: contentType,
    }
    return new File([data], fileName, metadata)
  } catch (err) {
    ErrorLogger.report('[Core] create file', err)
    return null
  }
}
