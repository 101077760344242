import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { EditAccountState } from './useGoToEditAccountStage.types'

export const useGoToEditAccountStage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const goToEditAccountStage = useCallback(
    (state: EditAccountState | undefined) => {
      navigate(location.pathname, {
        state: { EditAccountModal: state },
      })
    },
    [navigate, location]
  )

  return { goToEditAccountStage }
}
