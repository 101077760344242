import { useTranslations } from '@npco/utils-translations'
import { COLOR, Flex, Tutorial } from '@npco/zeller-design-system'

import { setupFlowTapToPayStageTranslations } from '../TapToPayStage.i18n'

export const PointsSection = () => {
  const t = useTranslations(setupFlowTapToPayStageTranslations)

  return (
    <Flex flexDirection="column" gap="4px" lineHeight="24px">
      <Tutorial.BulletPoint iconColour={COLOR.GREEN_1000} text={t('point1')} />
      <Tutorial.BulletPoint iconColour={COLOR.GREEN_1000} text={t('point2')} />
      <Tutorial.BulletPoint iconColour={COLOR.GREEN_1000} text={t('point3')} />
      <Tutorial.BulletPoint iconColour={COLOR.GREEN_1000} text={t('point4')} />
    </Flex>
  )
}
