import { useCallback } from 'react'
import { BannerContent } from 'features/StoryblokContent/BannerContent'

import { useOverviewBannerVisibility } from './hooks/useOverviewBannerVisibility/useOverviewBannerVisibility'

interface OverviewBannerProps {
  bannerSlug?: string | null
}

export const OverviewBanner = ({ bannerSlug }: OverviewBannerProps) => {
  const { isBannerHidden, setIsOverviewBannerHidden } =
    useOverviewBannerVisibility()

  const hideOverviewBanner = useCallback(() => {
    setIsOverviewBannerHidden(true)
  }, [setIsOverviewBannerHidden])

  if (isBannerHidden) {
    return null
  }

  return (
    <BannerContent
      hideOverviewBanner={hideOverviewBanner}
      slug={bannerSlug ?? ''}
    />
  )
}
