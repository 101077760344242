import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardNameIconCellBaseDebitCardV2FragmentDoc } from './components/CardNameIconCellBase/CardNameIconCellBase.generated'

export type CardNameIconCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  name: string
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  maskedPan: string
}

export const CardNameIconCellDebitCardV2FragmentDoc = gql`
  fragment CardNameIconCellDebitCardV2Fragment on DebitCardV2 {
    name
    ...CardNameIconCellBaseDebitCardV2Fragment
  }
  ${CardNameIconCellBaseDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardNameIconCellDebitCardV2Fragment,
  undefined
>
