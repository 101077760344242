import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type SaveEntityOnboardingErrorsMutationVariables = Types.Exact<{
  input:
    | Array<Types.EntityOnboardingErrorsInput>
    | Types.EntityOnboardingErrorsInput
  entityUuid: Types.Scalars['ID']['input']
}>

export type SaveEntityOnboardingErrorsMutationResponse = {
  __typename?: 'Mutation'
  saveEntityOnboardingErrors: boolean
}

export const SaveEntityOnboardingErrors = gql`
  mutation SaveEntityOnboardingErrors(
    $input: [EntityOnboardingErrorsInput!]!
    $entityUuid: ID!
  ) {
    saveEntityOnboardingErrors(input: $input, entityUuid: $entityUuid)
  }
` as unknown as TypedDocumentNode<
  SaveEntityOnboardingErrorsMutationResponse,
  SaveEntityOnboardingErrorsMutationVariables
>
export type SaveEntityOnboardingErrorsMutationFn = Apollo.MutationFunction<
  SaveEntityOnboardingErrorsMutationResponse,
  SaveEntityOnboardingErrorsMutationVariables
>

/**
 * __useSaveEntityOnboardingErrorsMutation__
 *
 * To run a mutation, you first call `useSaveEntityOnboardingErrorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEntityOnboardingErrorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEntityOnboardingErrorsMutation, { data, loading, error }] = useSaveEntityOnboardingErrorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useSaveEntityOnboardingErrorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEntityOnboardingErrorsMutationResponse,
    SaveEntityOnboardingErrorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveEntityOnboardingErrorsMutationResponse,
    SaveEntityOnboardingErrorsMutationVariables
  >(SaveEntityOnboardingErrors, options)
}
export type SaveEntityOnboardingErrorsMutationHookResult = ReturnType<
  typeof useSaveEntityOnboardingErrorsMutation
>
export type SaveEntityOnboardingErrorsMutationResult =
  Apollo.MutationResult<SaveEntityOnboardingErrorsMutationResponse>
export type SaveEntityOnboardingErrorsMutationOptions =
  Apollo.BaseMutationOptions<
    SaveEntityOnboardingErrorsMutationResponse,
    SaveEntityOnboardingErrorsMutationVariables
  >
