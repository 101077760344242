import { useMemo, useState } from 'react'

import { ShowMoreContext } from './ShowMoreContext'

interface ShowMoreProviderProps {
  initialValue?: boolean
  children: React.ReactNode
}

export const ShowMoreProvider = ({
  initialValue = false,
  children,
}: ShowMoreProviderProps) => {
  const [isShowingMore, setIsShowingMore] = useState(initialValue)
  const providerValue = useMemo(
    () => ({ isShowingMore, setIsShowingMore }),
    [isShowingMore]
  )

  return (
    <ShowMoreContext.Provider value={providerValue}>
      {children}
    </ShowMoreContext.Provider>
  )
}
