import { COLOR } from '@npco/zeller-design-system'

import { page } from 'translations'

import { StyledDivider, StyledTooltipLayout } from '../PerformanceChart.styled'
import { PerformanceTooltipSection } from './PerformanceTooltipSection'

interface Props {
  lastMonthTotal: number
  thisTimeLastMonthTotal: number
}

export const ThisMonthTooltipContent = ({
  lastMonthTotal,
  thisTimeLastMonthTotal,
}: Props) => {
  return (
    <StyledTooltipLayout>
      <PerformanceTooltipSection
        label={
          page.dashboard.payments.sitePerformance.chart.thisMonth.lastMonthTotal
        }
        total={lastMonthTotal}
        color={COLOR.GREY_60}
      />
      <StyledDivider />
      <PerformanceTooltipSection
        label={
          page.dashboard.payments.sitePerformance.chart.thisMonth
            .thisTimeLastMonth
        }
        total={thisTimeLastMonthTotal}
        color={COLOR.GREY_250}
      />
    </StyledTooltipLayout>
  )
}
