import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { PersonIcon } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { component } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const ContactsItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <SidebarItem
      icon={<PersonIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).CONTACTS.path}
      className="nav-item-contacts"
    >
      {component.sidebar.items.contacts}
    </SidebarItem>
  )
}
