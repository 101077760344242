import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetRecentlyUsedLineItems as GetRecentlyUsedLineItemsResponse } from 'types/gql-types/GetRecentlyUsedLineItems'

import { GetRecentlyUsedLineItems } from '../graphql/getRecentlyUsedLineItems'

export const useGetRecentlyUsedItems = (
  hookOptions?: LazyQueryHookOptions<GetRecentlyUsedLineItemsResponse>
) => {
  const entityUuid = useSelectedEntityUuid()
  const [getRecentlyUsedLineItems, { data, loading: isLoading }] =
    useLazyQuery<GetRecentlyUsedLineItemsResponse>(GetRecentlyUsedLineItems, {
      variables: {
        entityUuid,
      },
      ...hookOptions,
    })

  const items = data?.getRecentlyUsedLineItems

  return {
    getRecentlyUsedLineItems,
    isLoading,
    items,
  }
}
