import { useQuery } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { IsSupportedLocation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/signup'

import { IsSupportedLocation as IsSupportedLocationType } from 'types/gql-types/IsSupportedLocation'
import { ApolloClientWithAPIKey } from 'services/graphql'

interface UseCheckGeoLocationProps {
  onNotSupported: () => void
}

export const useCheckGeoLocation = ({
  onNotSupported,
}: UseCheckGeoLocationProps) => {
  const { loading: isLoading } = useQuery<IsSupportedLocationType>(
    IsSupportedLocation,
    {
      client: ApolloClientWithAPIKey,
      fetchPolicy: 'network-only',
      onError: (error) => {
        showApiErrorToast(error)
      },
      onCompleted: (result) => {
        if (!result.isSupportedLocation) {
          onNotSupported()
        }
      },
    }
  )

  return {
    isLoading,
  }
}
