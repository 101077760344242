import {
  BADGE_SIZE,
  BodyDefaultTypography,
  BodyLargeTypography,
  BodySmallTypography,
  SelectStyle,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { AVATAR_BADGE_SIZE } from 'types/common'

import { SelectSize } from '../Select.types'

const TRANSITION_PROPERTIES = '0.1s ease-in-out'

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
`

export const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0.75rem;
  width: 100%;
  white-space: nowrap;
  min-width: 0;
`

export const StyledLabel = styled.div`
  text-align: left;
  transition: color ${TRANSITION_PROPERTIES};
  color: ${({ theme }) => theme.colors.BLACK};

  &.oneLine {
    ${BodyLargeTypography};
  }

  &.twoLines {
    ${BodyDefaultTypography};
  }

  &.${SelectSize.Small} {
    &.oneLine {
      ${BodyDefaultTypography};
    }

    &.twoLines {
      ${BodySmallTypography};
    }
  }
`

export const StyledSubLabels = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.GREY_550};
  text-align: left;
  transition: color ${TRANSITION_PROPERTIES};
  ${BodySmallTypography};
`

export const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: opacity ${TRANSITION_PROPERTIES};
  margin-right: 0.25rem;

  &.isDisabled {
    opacity: 0.5;
  }
`

interface StyledIconWrapperProps {
  badgeSize?: AVATAR_BADGE_SIZE
}

export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.badgeSize ? BADGE_SIZE[props.badgeSize] : 'auto')};
  height: ${(props) =>
    props.badgeSize ? BADGE_SIZE[props.badgeSize] : 'auto'};
`

export const StyledSelectSubLabelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.GREY_550};
  text-align: left;
  transition: color ${TRANSITION_PROPERTIES};
  ${BodySmallTypography};
`

export const StyledSelectSubLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.isDisabled {
    color: ${({ theme }) => theme.colors.GREY_250};
  }
`

export const StyledDividerSubLabel = styled.div`
  padding: 0 0.5rem;

  &::before {
    content: '•';
  }
`

export const StyledSelectLabel = styled.div<{ color?: string }>`
  text-align: left;
  transition: color ${TRANSITION_PROPERTIES};
  color: ${({ color, theme }) => color || theme.colors.BLACK_900};
  overflow: hidden;
  text-overflow: ellipsis;

  &.oneLine {
    ${BodyLargeTypography};
  }

  &.twoLines {
    ${BodyDefaultTypography};
  }

  &.${SelectSize.Small} {
    &.oneLine {
      ${BodyDefaultTypography};
    }

    &.twoLines {
      ${BodySmallTypography};
    }
  }

  &.${SelectStyle.Compact}, &.placeholder {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  &.isDisabled {
    color: ${({ theme }) => theme.colors.GREY_250};
  }
`

export const StyledEditWrapper = styled.button`
  margin-right: 0.5rem;
  cursor: pointer;
  background: none;
  border: 1px solid transparent;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.BLUE_400};
    border-radius: 4px;
  }
`
