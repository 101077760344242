import { useMemo } from 'react'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { isNotNull } from 'utils/common'

import { useGetSavingsAccountsQuery } from './graphql/GetSavingsAccountsQuery.generated'

interface UseGetSavingsAccountsProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetSavingsAccounts = ({
  fetchPolicy = 'cache-first',
}: UseGetSavingsAccountsProps = {}) => {
  const { userRole } = useLoggedInUser()

  const {
    loading: isLoadingSavingsAccounts,
    error: savingsAccountsError,
    data,
  } = useGetSavingsAccountsQuery({
    fetchPolicy,
    errorPolicy: 'all',
    skip: userRole !== CustomerRole.ADMIN,
  })

  const savingsAccounts = useMemo(() => {
    return data?.getSavingsAccounts.filter(isNotNull) ?? []
  }, [data?.getSavingsAccounts])

  return {
    isLoadingSavingsAccounts,
    savingsAccountsError,
    savingsAccounts,
  }
}
