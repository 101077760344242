import { RESOURCE_ALREADY_EXISTS_ERROR, UNEXPECTED_ERROR } from 'types/errors'
import {
  ContactOrBiller,
  ContactToLinkBpayTo,
  TransferBpayFields,
} from 'pages/Transfer/Transfer.types'

const commonExistingBpayCheck = (values: TransferBpayFields) =>
  values.to?.type === 'Contact' &&
  values.from &&
  values.paymentInstrumentUuid?.id &&
  values.paymentInstrumentUuid.id !== 'NEW_BILLER'

export const isStaticExistingPayment = (values: TransferBpayFields) =>
  commonExistingBpayCheck(values) &&
  values.paymentInstrumentUuid?.type === 'static'

export const isDynamicExistingPayment = (values: TransferBpayFields) =>
  commonExistingBpayCheck(values) &&
  values.paymentInstrumentUuid?.type === 'dynamic'

export type NewPaymentValues = TransferBpayFields & {
  nickname: string
  reference: ContactToLinkBpayTo
  from: string
  to: ContactOrBiller
}

export const isNewPayment = (
  values: TransferBpayFields
): values is NewPaymentValues =>
  Boolean(
    typeof values.from === 'string' &&
      values.nickname &&
      values.reference &&
      values.to
  )

export const getCRNForNewPayment = (values: TransferBpayFields): string =>
  values?.crn ?? values?.to?.code ?? ''

export const isNewPaymentError = (
  createAndSendResponse:
    | 'unexpected error'
    | 'resource already exists'
    | 'mfa error'
    | object
): createAndSendResponse is 'unexpected error' | 'resource already exists' =>
  createAndSendResponse === UNEXPECTED_ERROR ||
  createAndSendResponse === RESOURCE_ALREADY_EXISTS_ERROR

const getBillerInfo = (values: TransferBpayFields) =>
  values?.to?.billerContacts ?? []

export const getBillerCodeForNewPayment = (values: TransferBpayFields) => {
  const billerInfo = getBillerInfo(values)
  return (billerInfo[0]?.code || values.to?.code) ?? ''
}

export const getBillerNameForNewPayment = (values: TransferBpayFields) => {
  const billerInfo = getBillerInfo(values)
  return (billerInfo[0]?.name || values.to?.code) ?? ''
}

export const getContactUuidForNewPayment = (values: TransferBpayFields) =>
  values.to?.id ?? ''
