import { useCallback } from 'react'
import { Box } from '@npco/zeller-design-system'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'

import { TransactionContactErrorMessage } from 'components/TransactionContactErrorMessage'
import { UnlinkingContactSpinner } from 'components/UnlinkingContactSpinner/UnlinkingContactSpinner'

import { ContactContent } from './ContactContent'
import { ContactContentError } from './ContactContentError'
import { ContactContentSkeleton } from './ContactContentSkeleton'
import { useTransactionContactQuery } from './hooks/useTransactionContactQuery'

const CONTACT_DEBOUNCE_IN_MS = 500

interface Props {
  debitCardTransactionUuid: string
  contactUuid: string | null | undefined
  onClose?: () => void
  isUnlinkingContact: boolean
  hasUnlinkingContactError: boolean
  unlinkContact: () => void
}

export const TransactionContact = ({
  debitCardTransactionUuid,
  contactUuid,
  onClose,
  isUnlinkingContact,
  hasUnlinkingContactError,
  unlinkContact,
}: Props) => {
  const {
    data: contact,
    loading: isLoadingContact,
    error: contactError,
    refetch: refetchContact,
  } = useGetContact({
    id: contactUuid,
    queryOptions: {
      context: {
        debounceKey: 'debitCardTransactionContact',
        debounceTimeout: CONTACT_DEBOUNCE_IN_MS,
      },
    },
  })

  const {
    isLoading: isLoadingTransaction,
    error: transactionError,
    transaction,
    refetchTransaction,
  } = useTransactionContactQuery({ debitCardTransactionUuid })

  const refetchMerchantAndContact = useCallback(() => {
    if (contactUuid && contactError) {
      refetchContact(contactUuid)
    }

    if (transactionError) {
      refetchTransaction()
    }
  }, [
    contactUuid,
    refetchContact,
    refetchTransaction,
    contactError,
    transactionError,
  ])

  if (isLoadingContact || isLoadingTransaction) {
    return <ContactContentSkeleton />
  }

  if (isUnlinkingContact) {
    return <UnlinkingContactSpinner />
  }

  if (contactError || transactionError || !transaction) {
    return <ContactContentError retry={refetchMerchantAndContact} />
  }

  if (hasUnlinkingContactError) {
    return <TransactionContactErrorMessage retry={unlinkContact} />
  }

  return (
    <Box pb="32px">
      <ContactContent
        merchant={transaction.merchant}
        contact={contact}
        onClose={onClose}
      />
    </Box>
  )
}
