import { gql } from '@apollo/client'
import { AccountStatementType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { UNEXPECTED_ERROR } from 'types/errors'
import { ShowMore } from 'components/ShowMore'
import { translations } from 'translations/shared.i18n'

import { AccountStatementListItem } from './AccountStatementListItem'
import { AccountStatementsListBankingAccountStatementFragment } from './AccountStatementsList.generated'
import { AccountStatementsWarning } from './AccountStatementsWarning'
import { InterimStatementListItem } from './InterimStatementListItem'

interface AccountStatementsListProps {
  accountStatements: AccountStatementsListBankingAccountStatementFragment[]
  statementType: AccountStatementType
  refetchStatementDownloadLink: (
    startDate: any
  ) => Promise<{ downloadLink: string } | typeof UNEXPECTED_ERROR>
  accountId: string
}

export const AccountStatementsList = ({
  accountStatements,
  statementType,
  refetchStatementDownloadLink,
  accountId,
}: AccountStatementsListProps) => {
  const initialAccountStatements = accountStatements.slice(0, 3)
  const togglableAccountStatements = accountStatements.slice(3)

  const t = useTranslations(translations)

  return (
    <ShowMore>
      <AccountStatementsWarning />
      <Box pb="4px">
        <InterimStatementListItem
          statementType={statementType}
          accountId={accountId}
        />
      </Box>
      {initialAccountStatements.map((accountStatement) => (
        <Box pb="4px" key={accountStatement.startDate}>
          <AccountStatementListItem
            accountStatement={accountStatement}
            statementType={statementType}
            refetchDownloadLink={() =>
              refetchStatementDownloadLink(accountStatement.startDate)
            }
          />
        </Box>
      ))}
      <ShowMore.TogglableContent>
        {togglableAccountStatements.map((accountStatement) => (
          <Box pb="4px" key={accountStatement.startDate}>
            <AccountStatementListItem
              accountStatement={accountStatement}
              statementType={statementType}
              refetchDownloadLink={() =>
                refetchStatementDownloadLink(accountStatement.downloadLink)
              }
            />
          </Box>
        ))}
      </ShowMore.TogglableContent>
      {!!togglableAccountStatements.length && (
        <Box pt="8px">
          <ShowMore.Toggle showText={t('showAll')} hideText={t('showLess')} />
        </Box>
      )}
    </ShowMore>
  )
}

AccountStatementsList.fragments = {
  BankingAccountStatement: gql`
    fragment AccountStatementsListBankingAccountStatementFragment on BankingAccountStatement {
      ...AccountStatementListItemBankingAccountStatementFragment
    }

    ${AccountStatementListItem.fragments.BankingAccountStatement}
  `,
}
