import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type GetIconImageUrlBySizeIconFragment = {
  __typename?: 'Icon'
  images: Array<{
    __typename?: 'Image'
    url: string
    size: Types.ImageSize
  }> | null
}

export const GetIconImageUrlBySizeIconFragmentDoc = gql`
  fragment GetIconImageUrlBySizeIconFragment on Icon {
    images {
      url
      size
    }
  }
` as unknown as TypedDocumentNode<GetIconImageUrlBySizeIconFragment, undefined>
