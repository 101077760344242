import { Ref } from 'react'
import {
  MultiSelectItemBasic,
  MultiSelectTriggerRenderProps,
} from 'design-system/Components/MultiSelect'

import { FiltersListMobileListItem } from 'components/FiltersList/FiltersListMobileListItem'

import { getMobileContent } from './FilterMobileSelectTrigger.utils'

interface Props<ItemType extends MultiSelectItemBasic, ValueType>
  extends Omit<MultiSelectTriggerRenderProps, 'ref'> {
  areAllItemsSelected?: boolean
  filterName: string
  selectedItems: ItemType[]
  values: ValueType[]
  innerRef?: Ref<HTMLButtonElement>
}

export const FilterMobileSelectTrigger = <
  ItemType extends MultiSelectItemBasic,
  ValueType extends string
>({
  filterName,
  areAllItemsSelected = false,
  selectedItems,
  values,
  innerRef,
  ...renderTriggerProps
}: Props<ItemType, ValueType>) => {
  const content = getMobileContent(
    areAllItemsSelected,
    filterName,
    selectedItems,
    values
  )

  return (
    <FiltersListMobileListItem
      label={content}
      // Casting to any as FiltersListMobileListItem expects ref to be for li element but downshift passes ref to a button
      ref={innerRef as any}
      {...renderTriggerProps}
    />
  )
}
