import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  SomethingWentWrongDominoes,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useBlockedActionModalContent } from 'features/BlockedAction/blocked-action-modal/BlockedActionModal/BlockedActionModalContent/hooks/useBlockedActionModalContent'
import { useBlockedActionState } from 'features/BlockedAction/blocked-action-routing'

import { SUPPORT_PHONE } from 'const/externalLinks'
import { translationsShared } from 'translations'

import { blockedActionModalContentTranslations } from './BlockedActionModalContent.i18n'

export const BlockedActionModalContent = () => {
  const { state } = useBlockedActionState()
  const {
    handleGoToContactZellerSupport,
    handleCloseModal,
    getBlockedActionMessage,
  } = useBlockedActionModalContent()
  const t = useTranslations(blockedActionModalContentTranslations)
  const tShared = useTranslations(translationsShared)
  const blockedActionCopy = getBlockedActionMessage(state?.blockedAction)
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? 'medium' : 'large'

  const modalClasses = state?.hasBlurAndFade
    ? { className: 'animated-content', overlayClassName: 'animated-overlay' }
    : {}

  return (
    <AnnouncementModal
      Illustration={<SomethingWentWrongDominoes size={illustrationSize} />}
      heading={t('titleContactZellerSupport')}
      description={t('description', {
        blockedActionCopy,
        supportPhone: SUPPORT_PHONE,
      })}
      primaryButtonLabel={tShared('close')}
      secondaryButtonLabel={tShared('contactUs')}
      onClickPrimary={handleCloseModal}
      onClickSecondary={handleGoToContactZellerSupport}
      onCancel={handleCloseModal}
      isOpen={state?.blockedAction !== null}
      {...modalClasses}
    />
  )
}
