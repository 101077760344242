import { Flex, Heading, ModalBasic, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CardWithShadowIllustration } from 'assets/svg/card-with-shadow.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { MultiStageModal } from 'components/MultiStageModal'

import { StyledBodyDefault } from './NotificationContent.styled'

interface NotificationContentProps {
  title: string
  content1: string
  content2?: string
  nextButtonLabel?: string
  backButtonLabel?: string
  handleAction: () => void
  handleDismiss: () => void
}
export const NotificationContent = ({
  title,
  content1,
  content2,
  nextButtonLabel,
  backButtonLabel,
  handleAction,
  handleDismiss,
}: NotificationContentProps) => {
  const isMobile = useIsMobileResolution()
  return (
    <>
      <ModalBasic.Body>
        <Flex
          flexDirection="column"
          alignItems={['center']}
          textAlign="center"
          paddingX="24px"
        >
          <SvgIcon
            width={isMobile ? '140' : '180'}
            height={isMobile ? '140' : '180'}
          >
            <CardWithShadowIllustration />
          </SvgIcon>

          <Heading.H2>{title}</Heading.H2>
          <Flex flexDirection="column" mt="16px" gridGap="16px">
            <StyledBodyDefault>{content1}</StyledBodyDefault>
            {content2 && <StyledBodyDefault>{content2}</StyledBodyDefault>}
          </Flex>
        </Flex>
      </ModalBasic.Body>
      <MultiStageModal.FooterButtons
        onCancel={handleDismiss}
        onConfirm={handleAction}
        nextButtonLabel={nextButtonLabel}
        backButtonLabel={backButtonLabel}
      />
    </>
  )
}
