import { CreateItemCategoryInput } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useCreateItemCategoryMutation } from '../graphql/createItemCategory.generated'
import { GetItemCategories } from '../graphql/getItemCategories.generated'

export const useCreateItemCategory = () => {
  const entityUuid = useSelectedEntityUuid()

  const [createItemCategory, { loading: isCreatingItemCategory }] =
    useCreateItemCategoryMutation({
      update: (cache, { data: createItemMutationResponse }) => {
        if (!createItemMutationResponse?.createItemCategory) {
          return
        }
        cache.updateQuery({ query: GetItemCategories }, (data) => ({
          getItemCategories: {
            ...data?.getItemCategories,
            categories: [
              ...(data?.getItemCategories.categories ?? []),
              createItemMutationResponse.createItemCategory,
            ],
            nextToken: data?.getItemCategories.nextToken ?? null,
          },
        }))
      },
    })

  return {
    createItemCategory: (category: CreateItemCategoryInput) =>
      createItemCategory({ variables: { category, entityUuid } }),
    isCreatingItemCategory,
  }
}
