import { ProductLaunchModalContent } from 'features/StartupModals/CMSProductLaunchModal/ProductLaunchModalContent'

import { useQueryParams } from 'hooks/useQueryParams'

import { useStartupModalsData } from './hooks/useStartupModalsData/useStartupModalsData'
import { useStartupModalsSession } from './hooks/useStartupModalsSession/useStartupModalsSession'
import { StartupModalSwitch } from './StartupModalSwitch'

interface StartupModalsProps {
  isLoadingCMS: boolean
  modalSlug?: string | null
}
export const StartupModals = ({
  isLoadingCMS,
  modalSlug,
}: StartupModalsProps) => {
  const { xeroBankFeed, xeroPaymentServices, isLoading } =
    useStartupModalsData()
  const params = useQueryParams()
  const contentKey = params.get('modal-name')

  const { startupModal, shouldShow } = useStartupModalsSession({
    isLoading,
    xeroBankFeed,
    xeroPaymentServices,
  })

  if (contentKey) {
    return <ProductLaunchModalContent slug={contentKey} />
  }

  return !isLoadingCMS ? (
    <StartupModalSwitch
      startupModal={startupModal}
      shouldShow={shouldShow}
      slug={modalSlug}
    />
  ) : null
}
