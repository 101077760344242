import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ListAccountsDebitCardAccountV2FragmentDoc } from '../../../ListAccounts/ListAccounts.generated'

const defaultOptions = {} as const
export type AccountsListQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type AccountsListQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename?: 'DebitCardAccountV2'
      id: string
      type: Types.DebitCardAccountType
      name: string
      status: Types.DebitCardAccountStatus
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        image: string | null
        animation: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
      cardsCount: {
        __typename?: 'DebitCardAccountV2CardsCount'
        total: number
        debit: number
        expense: number | null
      }
      balance: { __typename?: 'Money'; value: string } | null
      savingsAccountDetails: {
        __typename?: 'SavingsAccountDetails'
        effectiveInterestRate: string | null
      } | null
    } | null>
  }
  getSavingsAccounts: Array<{
    __typename?: 'DebitCardAccountV2'
    id: string
    type: Types.DebitCardAccountType
    name: string
    status: Types.DebitCardAccountStatus
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      image: string | null
      animation: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    cardsCount: {
      __typename?: 'DebitCardAccountV2CardsCount'
      total: number
      debit: number
      expense: number | null
    }
    balance: { __typename?: 'Money'; value: string } | null
    savingsAccountDetails: {
      __typename?: 'SavingsAccountDetails'
      effectiveInterestRate: string | null
    } | null
  } | null>
}

export const AccountsListQuery = gql`
  query AccountsListQuery($entityUuid: ID!) {
    getDebitCardAccountsV2(limit: 50, entityUuid: $entityUuid) {
      accounts {
        id
        ...ListAccountsDebitCardAccountV2Fragment
      }
    }
    getSavingsAccounts(entityUuid: $entityUuid) {
      id
      ...ListAccountsDebitCardAccountV2Fragment
    }
  }
  ${ListAccountsDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  AccountsListQueryResponse,
  AccountsListQueryVariables
>

/**
 * __useAccountsListQuery__
 *
 * To run a query within a React component, call `useAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsListQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useAccountsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountsListQueryResponse,
    AccountsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AccountsListQueryResponse, AccountsListQueryVariables>(
    AccountsListQuery,
    options
  )
}
export function useAccountsListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountsListQueryResponse,
    AccountsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountsListQueryResponse,
    AccountsListQueryVariables
  >(AccountsListQuery, options)
}
export type AccountsListQueryHookResult = ReturnType<
  typeof useAccountsListQuery
>
export type AccountsListQueryLazyQueryHookResult = ReturnType<
  typeof useAccountsListQueryLazyQuery
>
export type AccountsListQueryQueryResult = Apollo.QueryResult<
  AccountsListQueryResponse,
  AccountsListQueryVariables
>
