import { z } from 'zod'

export const setupFlowStage = [
  'SetupFlowStartStage',
  'DownloadAppStage',
  'TapToPayStage',
  'FeatureOverviewStage',
  'FeaturePaymentsStage',
  'FeatureAccountsStage',
  'FeatureCardsStage',
  'FeatureInvoicesStage',
  'FeatureVirtualTerminalStage',
  'FeaturePosLiteStage',
  'FeatureItemsStage',
  'FeatureContactsStage',
] as const

export type SetupFlowStage = (typeof setupFlowStage)[number]

const SetupFlowStateSchema = z.object({
  stage: z.enum(setupFlowStage),
})

export type SetupFlowState = z.infer<typeof SetupFlowStateSchema>

export const SetupFlowLocationStateSchema = z.object({
  SetupFlowModal: SetupFlowStateSchema,
})
