import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface Props {
  mobile: string | null | undefined
}

export const MobileItem = ({ mobile }: Props) => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.mobile')}
      value={mobile || '-'}
    />
  )
}
