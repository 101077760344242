import { Flex, ModalBasic } from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { useInvoiceDiscount } from './hooks/useInvoiceDiscount'
import { InvoiceDiscountConfig } from './InvoiceDiscountConfig/InvoiceDiscountConfig'
import { validateForm } from './InvoiceDiscountModal.utils'
import { InvoiceDiscountTotals } from './InvoiceDiscountTotals/InvoiceDiscountTotals'
import { InvoiceDiscountValue } from './InvoiceDiscountValue/InvoiceDiscountValue'

export const translations = {
  cancel: translate('shared.cancel'),
  headerTitle: translate(
    'page.invoice.formSections.items.invoiceDiscountModalHeaderTitle'
  ),
  save: translate('shared.save'),
}

export interface InvoiceDiscountModalProps {
  closeModal: () => void
}

export const InvoiceDiscountModal = ({
  closeModal,
}: InvoiceDiscountModalProps) => {
  const isMobileViewport = useIsMobileResolution()

  const { handleSubmit, initialValues, invoiceValues } = useInvoiceDiscount({
    closeModal,
  })

  return (
    <ModalBasic
      isFullScreen={isMobileViewport}
      isOpen
      onCancel={closeModal}
      testId="invoicing-invoice-discount-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validate={validateForm}
      >
        {({
          handleSubmit: handleFormikSubmit,
          submitForm: handleFormikSubmitForm,
        }) => (
          <Form
            data-testid="invoicing-invoice-discount-form"
            onSubmit={handleFormikSubmit}
          >
            <ModalBasic.HeaderForm
              onClickPrimary={handleFormikSubmitForm}
              onClickSecondary={closeModal}
              primaryButtonLabel={translations.save}
              secondaryButtonLabel={translations.cancel}
              title={translations.headerTitle}
            />
            <ModalBasic.Body canScroll={false}>
              <Flex
                flexDirection="column"
                paddingBottom="24px"
                paddingTop="12px"
              >
                <InvoiceDiscountConfig />
                <InvoiceDiscountValue />
              </Flex>
              <InvoiceDiscountTotals invoiceValues={invoiceValues} />
            </ModalBasic.Body>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  )
}
