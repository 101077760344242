import { useEffect, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, showApiErrorToast } from '@npco/zeller-design-system'
import { IdentityPhoneRegister } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/onboarding'
import parseMobile from 'libphonenumber-js/mobile'

import {
  IdentityPhoneRegister as IdentityPhoneRegisterType,
  IdentityPhoneRegisterVariables,
} from 'types/gql-types/IdentityPhoneRegister'
import {
  ConfirmButton,
  ModalDescription,
  ModalHeader,
} from 'components/Modal/ModalElements/ModalElements'

import { SpinnerWrapped } from '../Spinner'
import { resendCodeModalTranslations } from './ResendCodeModal.i18n'

interface ModalProps {
  phone: string
  closeModal: () => void
}

export const ResendCodeModal: React.FC<ModalProps> = ({
  phone,
  closeModal,
}) => {
  const t = useTranslations(resendCodeModalTranslations)

  const [identityPhoneRegister, { loading, data, error }] = useMutation<
    IdentityPhoneRegisterType,
    IdentityPhoneRegisterVariables
  >(IdentityPhoneRegister, {
    errorPolicy: 'all',
    onError: (err) => {
      showApiErrorToast(err)
    },
  })

  const modalContent = useMemo(() => {
    if (error?.message === 'Rate limit exceeded') {
      return {
        title: t('titleTooManyAttempts'),
        description: t('tooManyAttempts'),
      }
    }
    if (error || data?.identityPhoneRegister === null) {
      return { title: t('titleFailure'), description: t('copyFailure') }
    }
    return { title: t('titleSuccess'), description: t('copySuccess') }
  }, [error, data?.identityPhoneRegister, t])

  useEffect(() => {
    if (!phone) {
      return
    }
    const resendNumber = parseMobile(phone, 'AU')

    identityPhoneRegister({
      variables: {
        phone: resendNumber ? (resendNumber.number as string) : '',
      },
    })
  }, [phone, identityPhoneRegister])

  if (loading) {
    return (
      <SpinnerWrapped
        justifyContent="center"
        py="60px"
        width={['100%', '448px']}
      />
    )
  }

  return (
    <Box data-testid="resend-code-modal" width={['100%', '448px']}>
      <ModalHeader>{modalContent.title}</ModalHeader>
      <ModalDescription>{modalContent.description}</ModalDescription>
      <ConfirmButton onClick={closeModal}>{t('buttonLabel')}</ConfirmButton>
    </Box>
  )
}
