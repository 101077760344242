import { StyledTabs } from '../DashboardPerformance.styled'
import { PerformancePeriod } from '../DashboardPerformance.types'
import { usePerformanceTabs } from './hooks/usePerformanceTabs'

interface Props {
  initialTab: PerformancePeriod
  onChange: (performancePeriod: PerformancePeriod) => void
}

export const PerformanceTabs = ({ initialTab, onChange }: Props) => {
  const { tabs, currentTab } = usePerformanceTabs({ initialTab, onChange })

  return (
    <div>
      <StyledTabs currentTab={currentTab}>
        {tabs.map((tab) => (
          <StyledTabs.Item key={tab.value} {...tab} />
        ))}
      </StyledTabs>
    </div>
  )
}
