import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox, INFOBOX_VARIANT } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { useDepositAccount } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'
import { Condition } from 'components/Condition/Condition'

import { ExternalAccountFields } from '../../../../CreateExternalAccountModal.types'
import { translations } from '../../ExternalAccountFormStage.i18n'
import { WarningHighlightName } from '../../ExternalAccountFormStage.styled'

export const UpdateDepositAccountWarning = () => {
  const t = useTranslations(translations)
  const { depositAccount } = useDepositAccount()
  const { values } = useFormikContext<ExternalAccountFields>()

  if (!depositAccount) {
    return null
  }

  return (
    <Condition shouldShow={values.shouldUpdateDepositAccount}>
      <Box marginTop="auto">
        <InfoBox
          variant={INFOBOX_VARIANT.NEGATIVE}
          dataTestId="set-settlement-account-warning"
        >
          <InfoBox.Message>
            {t('warning', {
              settlementAccount: (
                <WarningHighlightName>
                  {depositAccount.name}
                </WarningHighlightName>
              ),
            })}
          </InfoBox.Message>
        </InfoBox>
      </Box>
    </Condition>
  )
}
