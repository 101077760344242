import { FocusEventHandler, useCallback, useRef, useState } from 'react'
import DayPicker, { RangeModifier } from 'react-day-picker'
import { Box, Flex } from '@npco/zeller-design-system'
import { Dayjs } from 'dayjs'

import { ReactComponent as Calendar } from 'assets/svg/calendar.svg'
import { formatDateFullAndTime, formatDateMonthYear } from 'utils/date'
import dayjs from 'utils/dayjs'
import { PickerItemProps, RangeModifierExt } from 'types/picker'
import {
  StyledDatePickerWrapper,
  StyledMonthsWrapper,
} from 'components/Filters/NewFilters/DatePicker/DatePicker.styled'
import { PeriodButtons } from 'components/Filters/NewFilters/DatePicker/DatePickerPeriodButtons'
import { MultiDownshift } from 'components/MultiDownshift'
import { BottomRow, TopRow } from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { StyledDropdownWrapper } from 'components/Shared'
import { component } from 'translations'

import { TimeEntries } from './TimeEntries/TimeEntries'

interface UncontrolledDatePickerProps {
  height?: string
  data: RangeModifierExt
  enteredTo: Date | undefined
  selectedPeriod: string
  handleResetClick: () => void
  handleDayClick: (day: Date) => void
  handleDayMouseEnter: (day: Date) => void
  onPeriodChange: (periodData: RangeModifierExt, periodName: string) => void
  hasCustomDateApplied: boolean
  fromTime?: string
  toTime?: string
  onFromTimeChange?: (newFromTime: string) => void
  onToTimeChange?: (newToTime: string) => void
  onPropsFromInputBlur?: FocusEventHandler<HTMLInputElement>
  onPropsToInputBlur?: FocusEventHandler<HTMLInputElement>
  isDateTime?: boolean
}

export const UncontrolledDatePicker = ({
  data,
  enteredTo,
  selectedPeriod,
  handleResetClick,
  handleDayClick,
  handleDayMouseEnter,
  onPeriodChange,
  hasCustomDateApplied,
  fromTime,
  toTime,
  onFromTimeChange,
  onToTimeChange,
  onPropsFromInputBlur,
  onPropsToInputBlur,
  isDateTime = false,
}: UncontrolledDatePickerProps) => {
  const { from, to } = data

  const [currentlyVisibleFirstMonth, setCurrentlyVisibleFirstMonth] = useState<
    Dayjs | undefined
  >(dayjs(from).startOf('month'))
  const ref = useRef<DayPicker>(null)

  const modifiers = {
    start: from,
    end: enteredTo,
    endNotSelected: enteredTo ? undefined : from,
  }

  const getTriggerContent = (date: Date | string) => {
    return isDateTime ? formatDateFullAndTime(date) : formatDateMonthYear(date)
  }

  const setPeriod = (periodData: RangeModifierExt, periodName: string) => {
    const periodDataFromDayjs = dayjs(periodData.from).startOf('month')
    const isFromMonthVisible =
      currentlyVisibleFirstMonth?.isSame(periodDataFromDayjs) ||
      currentlyVisibleFirstMonth?.add(1, 'month').isSame(periodDataFromDayjs)

    if (periodData.from && !isFromMonthVisible) {
      ref.current?.showMonth(periodData.from)
    }

    onPeriodChange(periodData, periodName)
  }

  const onMonthChange = useCallback((month: Date) => {
    setCurrentlyVisibleFirstMonth(dayjs(month).startOf('month'))
  }, [])

  return (
    <MultiDownshift
      itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
    >
      {({ isOpen, closeMenu, getToggleButtonProps, getRootProps }) => (
        <StyledDropdownWrapper
          width="100%"
          {...getRootProps()}
          data-testid="date-picker"
        >
          <Flex width="100%" flexDirection={['column', 'row']}>
            <Box mb={['12px', 0]} mr={{ _: '12px', SM: '16px' }} width="100%">
              <PickerTrigger
                isOpen={isOpen}
                getToggleButtonProps={getToggleButtonProps}
                placeholderIcon={<Calendar />}
                triggerContent={from ? getTriggerContent(from) : undefined}
                hasTriggerArrow={false}
                label={
                  from ? getTriggerContent(from) : component.datePicker.from
                }
              />
            </Box>
            <Box width="100%">
              <PickerTrigger
                isOpen={isOpen}
                getToggleButtonProps={getToggleButtonProps}
                placeholderIcon={<Calendar />}
                triggerContent={to ? getTriggerContent(to) : undefined}
                hasTriggerArrow={false}
                label={to ? getTriggerContent(to) : component.datePicker.to}
              />
            </Box>
          </Flex>
          {isOpen && (
            <StyledMonthsWrapper $width={616}>
              <TopRow
                copy={component.datePicker.selectDates}
                handleCloseMenu={closeMenu}
              />
              <StyledDatePickerWrapper isDateTime={isDateTime}>
                <DayPicker
                  ref={ref}
                  numberOfMonths={2}
                  selectedDays={[
                    from,
                    {
                      from: from as unknown as RangeModifier['from'], // insufficient library typing
                      to: enteredTo as unknown as RangeModifier['to'], // hack to set it properly
                    },
                  ]}
                  modifiers={modifiers}
                  onDayClick={handleDayClick}
                  onDayMouseEnter={handleDayMouseEnter}
                  onDayMouseLeave={() => null}
                  onMonthChange={onMonthChange}
                  disabledDays={{ after: new Date() }}
                  toMonth={new Date()}
                  month={dayjs(from).toDate()}
                  data-testid="day-picker"
                />
              </StyledDatePickerWrapper>
              {isDateTime && (
                <TimeEntries
                  fromTime={fromTime || ''}
                  onFromTimeChange={onFromTimeChange}
                  onToTimeChange={onToTimeChange}
                  toTime={toTime || ''}
                  onPropsFromInputBlur={onPropsFromInputBlur}
                  onPropsToInputBlur={onPropsToInputBlur}
                />
              )}
              <BottomRow
                handleClearAll={handleResetClick}
                isClearButtonDisabled={!hasCustomDateApplied}
              >
                <PeriodButtons
                  handleSetPeriod={setPeriod}
                  selectedPeriod={selectedPeriod}
                />
              </BottomRow>
            </StyledMonthsWrapper>
          )}
        </StyledDropdownWrapper>
      )}
    </MultiDownshift>
  )
}
