import { gql, type TypedDocumentNode } from '@apollo/client'

import type * as GetSitesGQL from 'types/gql-types/GetSites'

export const GetSites = gql`
  query GetSites(
    $entityUuid: ID!
    $limit: Int!
    $nextToken: SiteNextTokenInput
  ) {
    getSites(entityUuid: $entityUuid, limit: $limit, nextToken: $nextToken) {
      sites {
        id
        name
        devices {
          id
          name
          model
          serial
        }
      }
      nextToken {
        type
        siteNameSortKey
      }
    }
  }
` as TypedDocumentNode<GetSitesGQL.GetSites, GetSitesGQL.GetSitesVariables>

export const GetSitesForPricing = gql`
  query GetSitesForPricing(
    $entityUuid: ID!
    $limit: Int!
    $nextToken: SiteNextTokenInput
  ) {
    getSites(entityUuid: $entityUuid, limit: $limit, nextToken: $nextToken) {
      sites {
        surchargesTaxes {
          feePercent
          feePercentMoto
          feeFixedCnp
          feePercentCnp
        }
        id
        name
        type
      }
      nextToken {
        type
        siteNameSortKey
      }
    }
  }
`

export const GetSite = gql`
  query GetSite($entityUuid: ID!, $siteUuid: ID!) {
    getSite(entityUuid: $entityUuid, siteUuid: $siteUuid) {
      id
      name
      type
      address {
        street
        suburb
        state
        postcode
      }
      customers {
        id
        entityUuid
        firstname
        middlename
        lastname
        role
      }
      screensaver {
        customColours
        logos {
          logoUuid
          url
        }
        primaryColour
        primaryLogoUrl
        primaryLogoUuid
      }
      devices {
        id
        name
        model
        serial
      }
      pin
      surchargesTaxes {
        surchargeEnabled
        surchargeFullFees
        surchargePercent
        feePercent
        gstEnabled
        gstPercent
        taxes {
          name
          percent
        }
        surchargeEnabledMoto
        surchargeFullFeesMoto
        surchargePercentMoto
        feePercentMoto
        feesSurchargeCpoc {
          feeFixed
          feePercent
          surchargeEnabled
          surchargeFullFees
          surchargePercent
        }
        feesSurchargeVt {
          feeFixed
          feePercent
          surchargeEnabled
          surchargeFullFees
          surchargePercent
        }
        feesSurchargePbl {
          feeFixed
          feePercent
          surchargeEnabled
          surchargeFullFees
          surchargePercent
        }
      }
      moto {
        enabled
        defaultEntryMethod
        requiresPin
      }
      tipping {
        enabled
        tipPercent1
        tipPercent2
        tipPercent3
        customTipAllowed
      }
      schemes {
        name
      }
      schemesMoto {
        name
      }
      refundRequiresPin
      refundPin
      refundPinType
      receipt {
        merchantCopy
        printLogo
        printSocials
        name
        address1
        address2
        number
        phone
        printDeclinedTransaction
        email
        message
        returnsMessage
        website
        instagram
        facebook
        twitter
        logo
        qrCode
      }
      features {
        restrictReportAccessEnabled
        splitPaymentEnabled
      }
      vtEnabled
    }
  }
`

export const GetSiteWithUsers = gql`
  query GetSiteWithUsers($entityUuid: ID!, $siteUuid: ID!) {
    getSite(entityUuid: $entityUuid, siteUuid: $siteUuid) {
      id
      name
      address {
        street
        suburb
        state
        postcode
      }
      pin
      customers {
        id
        entityUuid
        firstname
        middlename
        lastname
        role
        sites {
          name
          devices {
            name
          }
        }
      }
    }
  }
`

export const GetReceiptSettingsLogoUploadUrl = gql`
  query GetReceiptSettingsLogoUploadUrl(
    $entityUuid: ID!
    $siteUuid: ID!
    $fileName: String
  ) {
    getReceiptSettingsLogoUploadUrl(
      entityUuid: $entityUuid
      siteUuid: $siteUuid
      fileName: $fileName
    )
  }
`

export const GetSitesWithTypes = gql`
  query GetSitesWithTypes($entityUuid: ID!, $limit: Int!) {
    getSites(entityUuid: $entityUuid, limit: $limit) {
      sites {
        id
        name
        type
      }
      nextToken {
        type
        siteNameSortKey
      }
    }
  }
`
