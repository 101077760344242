import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  splitPaymentDetails: 'Split Payment Details',
  splitType: 'Split Type',
  initialKeyAmount: 'Initial Keyed Amount',
  remaining: 'Remaining',
  viewRelatedTransactions: 'View All Related Transactions',
  date: 'Date',
})
