import { useCallback, useMemo, useRef } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useModalState } from '@npco/zeller-design-system'
import { rvSelectedAccountTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { PartialTransactionUpdateType } from 'hooks/transactions'
import { formatDate, formatToday } from 'utils/date'
import dayjs from 'utils/dayjs'
import { useDrawerTabEvents } from 'services/Analytics/useDrawerTabEvents'
import { renderGroup } from 'components/Lists/GroupedList/Group/Group.utils'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListGroupTitle } from 'components/Lists/ListGroupTitle/ListGroupTitle'

import {
  TransactionDrawer,
  TransactionDrawerTabs,
  useNextPreviousDebitCardTransaction,
} from '../TransactionDrawer'
import { DebitCardTransactionListWrapper } from './DebitCardTransactionsList.styled'
import {
  DebitCardTransaction,
  TransactionListRowBaseProps,
} from './DebitCardTransactionsList.types'
import { ListGroupItemDebitCardTransactions } from './ListGroupItemDebitCardTransactions/ListGroupItemDebitCardTransactions'

interface DebitCardTransactionListProps {
  groupedTransactions: DebitCardTransaction[][]
  fetchMore?: () => void
  hasMore: boolean
  handleSelectedTransactionUpdate: (
    update: PartialTransactionUpdateType
  ) => void
  listHeight?: string
  Row?: React.ForwardRefExoticComponent<
    TransactionListRowBaseProps & React.RefAttributes<HTMLLIElement>
  >
  showMobileStyle?: boolean
}

export const DebitCardTransactionsList = ({
  groupedTransactions,
  fetchMore,
  hasMore,
  handleSelectedTransactionUpdate,
  listHeight,
  Row = ListGroupItemDebitCardTransactions,
  showMobileStyle = false,
}: DebitCardTransactionListProps) => {
  const selectedTransaction = useReactiveVar(rvSelectedAccountTransaction)
  const transactionsRefs = useRef<Record<string, HTMLLIElement | null>>({})
  const initialTime = useMemo(() => dayjs(), [])
  const { onDrawerOpen, onDrawerClose } = useDrawerTabEvents({
    drawerName: 'Transaction',
  })

  const { onSelectNextTransaction, onSelectPreviousTransaction } =
    useNextPreviousDebitCardTransaction({
      groupedTransactions,
      selectedTransaction,
      transactionsRefs,
    })

  const { isModalOpen, openModal, closeModal } = useModalState()

  const showDetails = useCallback(
    (transaction: DebitCardTransaction) => {
      rvSelectedAccountTransaction(transaction)
      openModal()
      onDrawerOpen(TransactionDrawerTabs.DETAILS)
    },
    [onDrawerOpen, openModal]
  )

  const hideDetails = useCallback(() => {
    rvSelectedAccountTransaction(null)
    onDrawerClose()
    closeModal()
  }, [onDrawerClose, closeModal])

  const renderItem = useCallback(
    (listItemsData: DebitCardTransaction) => (
      <Row
        ref={(ref) => {
          transactionsRefs.current[listItemsData.id] = ref
        }}
        key={listItemsData.id}
        transaction={listItemsData}
        showDetails={showDetails}
        isNew={dayjs(listItemsData.timestamp).isAfter(initialTime)}
        showMobileStyle={showMobileStyle}
      />
    ),
    [showDetails, initialTime, Row, showMobileStyle]
  )

  return (
    <>
      <DebitCardTransactionListWrapper $listHeight={listHeight}>
        <GroupedList
          data={groupedTransactions}
          onScrollEnd={fetchMore}
          renderItem={renderItem}
          hasMore={hasMore}
          renderGroup={renderGroup({
            getGroupKey: ([firstElement]) => firstElement?.timestamp,
            renderHeader: ([firstElement]) => (
              <ListGroupTitle data-testid="list-group-header">
                {formatToday(firstElement?.timestamp, formatDate)}
              </ListGroupTitle>
            ),
          })}
        />
      </DebitCardTransactionListWrapper>

      <TransactionDrawer
        transaction={selectedTransaction}
        updateTransaction={handleSelectedTransactionUpdate}
        isOpen={isModalOpen}
        onClose={hideDetails}
        selectNextTransaction={onSelectNextTransaction}
        selectPreviousTransaction={onSelectPreviousTransaction}
      />
    </>
  )
}
