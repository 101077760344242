import { Subcategory } from './subcategories.types'

export const mapSubcategoriesToItems = (
  subcategories: Subcategory[],
  editSubcategory: (subcategory: Subcategory) => void
) => {
  return subcategories.map((subcategory) => ({
    label: subcategory.name,
    value: subcategory.name,
    id: subcategory.id,
    onEdit:
      subcategory.predefined || !subcategory.id
        ? undefined
        : () => editSubcategory(subcategory),
  }))
}
