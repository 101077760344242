import {
  CORPORATE_CARD_JOYRIDE_STAGE,
  type CorporateCardWalkthroughStage,
} from 'features/CorporateCardWalkthrough/corporate-card-walkthrough-routing'

export const getStageFromStepIndex = (
  stepIndex: number,
  isAdmin: boolean
): CorporateCardWalkthroughStage | undefined => {
  switch (stepIndex) {
    case 0:
      return 'outstandingExpenses'
    case 1:
      return 'remainingBalance'
    case 2:
      return isAdmin ? 'editOutstandingExpenses' : 'youAreReady'
    case 3:
      return isAdmin ? 'youAreReady' : undefined
    default:
      return undefined
  }
}

export const shouldRunJoyride = (stage: CorporateCardWalkthroughStage | null) =>
  CORPORATE_CARD_JOYRIDE_STAGE.includes(stage ?? '')
