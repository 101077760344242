import { ErrorLogger } from '@npco/utils-error-logger'

import { NullableFileWithId } from 'components/File'

import { useCacheValue } from '../useCacheValue/useCacheValue'
import { rvTagImageUrls } from './reactiveVariables'
import { tagImageConverter } from './useTagImagePreview.utils'

export const useTagImagePreview = ({
  image,
  skip,
}: {
  image: NullableFileWithId
  skip: boolean
}) => {
  const { cacheValue, isLoading, error } = useCacheValue<
    NullableFileWithId,
    string
  >({
    resourceWithId: image,
    rvResource: rvTagImageUrls,
    skip,
    ...(image.file
      ? {
          fetchNewValue: () => tagImageConverter(image.file as File),
          onError: (err) =>
            ErrorLogger.report('[Core] Tag Image conversion error', err),
        }
      : {}),
  })

  return {
    isLoading,
    imageUrl: cacheValue,
    error,
  }
}
