import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  text-align: center;
  gap: 16px;
  margin-top: 40px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    align-items: center;
    gap: 40px;
    margin-top: 64px;
  }
`
