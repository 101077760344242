import { Formik, FormikErrors } from 'formik'

import { RemittanceData } from './TransferRemittancesModal.types'
import { TransferRemittancesModalForm } from './TransferRemittancesModalForm/TransferRemittancesModalForm'

type TransferRemittancesModalProps = {
  isOpen: boolean
  onCancel: () => void
  title: string
  description: string
  children: React.ReactNode
  sendRemittance: (values: RemittanceData) => void
  isLoading: boolean
  isPrimaryButtonDisabled: (
    values: RemittanceData,
    errors: FormikErrors<RemittanceData>
  ) => boolean
  contactPhone?: string
  contactEmail?: string
}

export const TransferRemittancesModal = ({
  isOpen,
  onCancel,
  title,
  description,
  children,
  sendRemittance,
  isLoading,
  isPrimaryButtonDisabled,
  contactPhone,
  contactEmail,
}: TransferRemittancesModalProps) => {
  return (
    <Formik<RemittanceData>
      initialValues={{
        sms: contactPhone ?? '',
        email: contactEmail ?? '',
        smsCheckbox: false,
        emailCheckbox: false,
      }}
      onSubmit={sendRemittance}
    >
      <TransferRemittancesModalForm
        isOpen={isOpen}
        onCancel={onCancel}
        title={title}
        description={description}
        isLoading={isLoading}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      >
        {children}
      </TransferRemittancesModalForm>
    </Formik>
  )
}
