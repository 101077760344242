import { useTranslations } from '@npco/utils-translations'
import { PageTemplate } from '@npco/zeller-design-system'

import { EmptyStateMessageWithCard } from 'components/EmptyState/EmptyStateMessageWithCard'
import { EmptyStateCard } from 'components/EmptyStateCard/EmptyStateCard'

import { HeaderPrimaryRow } from '../components/HeaderPrimaryRow/HeaderPrimaryRow'
import { PurchaseSimButton } from '../components/PurchaseSimButton/PurchaseSimButton'
import { translations } from './SimEmptyPage.i18n'

export const SimEmptyPage = () => {
  const t = useTranslations(translations)

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <HeaderPrimaryRow>
          <PurchaseSimButton isFilled />
        </HeaderPrimaryRow>
      }
    >
      <EmptyStateMessageWithCard
        title={t('title')}
        description={t('description')}
        illustrationName="SimCard"
        card={
          <EmptyStateCard
            heading={t('cardHeading')}
            valuePoints={[t('point1'), t('point2'), t('point3')]}
          />
        }
      />
    </PageTemplate>
  )
}
