import { gql } from '@apollo/client'

export const AddThirdPartyBankAccount = gql`
  mutation AddThirdPartyBankAccount(
    $entityUuid: ID!
    $input: CreateThirdPartyBankAccountInput!
  ) {
    addThirdPartyBankAccount(entityUuid: $entityUuid, input: $input) {
      accountBsb
      accountName
      accountNumber
      id
      name
    }
  }
`

export const SelectDepositAccount = gql`
  mutation SelectDepositAccount(
    $entityUuid: ID!
    $id: ID!
    $remitToCard: Boolean!
  ) {
    selectDepositAccount(
      entityUuid: $entityUuid
      id: $id
      remitToCard: $remitToCard
    )
  }
`

export const UpdateThirdPartyBankAccount = gql`
  mutation UpdateThirdPartyBankAccount(
    $entityUuid: ID!
    $input: UpdateThirdPartyBankAccountInput!
  ) {
    updateThirdPartyBankAccount(entityUuid: $entityUuid, input: $input)
  }
`

export const RemoveThirdPartyBankAccount = gql`
  mutation RemoveThirdPartyBankAccount(
    $entityUuid: ID!
    $thirdPartyBankAccountUuid: ID!
  ) {
    removeThirdPartyBankAccount(
      entityUuid: $entityUuid
      thirdPartyBankAccountUuid: $thirdPartyBankAccountUuid
    )
  }
`
