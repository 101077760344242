import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { EditDebitCardTransactionNotesState } from '../../EditDebitCardTransactionNotes.types'

export const useGoToEditDebitCardTransactionNotes = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToEditDebitCardTransactionNotes = useCallback(
    (state: EditDebitCardTransactionNotesState | null) => {
      navigate(location.pathname, {
        state: {
          EditDebitCardTransactionNotesModal: state,
        },
      })
    },
    [navigate, location.pathname]
  )

  return { goToEditDebitCardTransactionNotes }
}
