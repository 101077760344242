import { useEffect, useRef } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { usePrevious } from 'hooks/usePrevious'

import { InvoiceFormFields } from '../Invoice.types'
import { translations } from './useSubmitFormValidation.i18n'

interface UseSubmitFormValidation {
  isSendingInvoice: boolean
  isInvoiceScheduled?: boolean
  shouldShowToastOnError?: boolean
  onSubmitError?: () => void
  onSubmitSuccess?: () => void
}

export const useSubmitFormValidation = (props: UseSubmitFormValidation) => {
  const t = useTranslations(translations)
  const propsRef = useRef(props)
  const { isSubmitting, isValid } = useFormikContext<InvoiceFormFields>()

  useEffect(() => {
    propsRef.current = props
  })

  const isPreviouslySubmitting = usePrevious(isSubmitting)

  useEffect(() => {
    const {
      isSendingInvoice,
      isInvoiceScheduled,
      onSubmitError,
      onSubmitSuccess,
      shouldShowToastOnError = true,
    } = propsRef.current

    if (isPreviouslySubmitting && !isSubmitting) {
      if (isValid) {
        onSubmitSuccess?.()
        return
      }

      if (shouldShowToastOnError) {
        switch (true) {
          case isSendingInvoice && isInvoiceScheduled:
            showErrorToast(t('scheduleValidationError'))
            break
          case isSendingInvoice && !isInvoiceScheduled:
            showErrorToast(t('sendValidationError'))
            break
          default:
            showErrorToast(t('saveValidationError'))
        }
      }

      onSubmitError?.()
    }
  }, [isPreviouslySubmitting, isValid, isSubmitting, t])
}
