import { PaymentInstrumentStatus } from '@npco/mp-gql-types'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { rvUnlinkedPaymentInstruments } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/paymentInstruments'
import { rvSelectedContactToTransfer } from 'features/Contacts/rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from 'features/Contacts/rv-deprecated/contacts.utils'

import { BankAccountDetails } from 'hooks/paymentInstruments/PaymentInstruments.types'
import { translate } from 'utils/translations'

export const isPaymentInstrumentExisting = (
  bsb: string,
  accountNumber: string
): string => {
  const unlinkedPaymentInstruments = rvUnlinkedPaymentInstruments()
  const relevantPaymentInstrument = unlinkedPaymentInstruments?.find(
    (item) => item.details.bsb === bsb && item.details.account === accountNumber
  )

  return relevantPaymentInstrument?.id ?? ''
}

export const showAddAccountToast = (isSuccess: boolean) => {
  return isSuccess
    ? showSuccessToast(translate('page.transfer.addAccountSuccessMessage'))
    : showErrorToast(translate('page.transfer.addAccountFailMessage'))
}

export const updateSelectedContactWithPaymentInstrument = (
  paymentInstrumentId: string,
  bankAccountDetails: BankAccountDetails
) => {
  const selectedContact = rvSelectedContactToTransfer()
  if (selectedContact?.id) {
    const paymentInstrumentsLinkedToContact =
      selectedContact?.paymentInstruments || []
    const newPaymentInstruments = [
      ...paymentInstrumentsLinkedToContact,
      {
        id: paymentInstrumentId,
        bankAccountDetails,
        details: {},
        status: PaymentInstrumentStatus.ACTIVE,
      },
    ]
    const updatedSelectedContact = {
      ...selectedContact,
      paymentInstruments: newPaymentInstruments,
    }
    rvSelectedContactToTransfer(updatedSelectedContact)
    updateRvContactsOnContactUpdate(selectedContact.id, {
      paymentInstruments: newPaymentInstruments,
    })
  }
}

export const removePaymentInstrumentFromUnlinkedList = (
  paymentInstrumentId: string
) => {
  const currentUnlinkedList = rvUnlinkedPaymentInstruments()
  const newUnlinkedList = currentUnlinkedList.filter(
    (item) => item.id !== paymentInstrumentId
  )
  rvUnlinkedPaymentInstruments(newUnlinkedList)
}
