import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowCardsLarge from 'assets/images/setup-flow/setup-flow-cards-large.png'
import SetupFlowCardsMedium from 'assets/images/setup-flow/setup-flow-cards-medium.png'
import SetupFlowCardsSmall from 'assets/images/setup-flow/setup-flow-cards-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { setupFlowCardsStageTranslations } from './FeatureCardsStage.i18n'
import { MainContent } from './MainContent/MainContent'

export const FeatureCardsStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(setupFlowCardsStageTranslations)
  const { goToStage } = useSetupFlowModal()

  return (
    <Tutorial.TutorialLayout
      badgeName={t('badge')}
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage('FeatureInvoicesStage')}
            onSecondaryButtonClick={() => goToStage('FeatureAccountsStage')}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowCardsSmall}
          imageMedium={SetupFlowCardsMedium}
          imageLarge={SetupFlowCardsLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
