import { useTranslations } from '@npco/utils-translations'
import { Box, Message, MessageSize } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

interface ErrorDisplayProps {
  title: React.ReactNode
  description: React.ReactNode
  retry?: () => void
}

export const ErrorDisplay = ({
  title,
  description,
  retry,
}: ErrorDisplayProps) => {
  const tShared = useTranslations(translationsShared)

  return (
    <Message
      title={title}
      description={<Box textAlign="center">{description}</Box>}
      primaryButton={{ label: tShared('tryAgain'), onClick: retry }}
      size={MessageSize.MEDIUM}
      align="center"
    />
  )
}
