import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  discount: 'Discount',
  discountAriaLabel: 'add invoice discount',
  invoiceGst: 'Invoice GST',
  invoiceGstDescription:
    'Select if GST is included in the price of items that are GST Applicable.',
  invoiceGstWarningTitle: 'Totals discrepancy due to tax calculation change',
  invoiceGstWarningDescription:
    'The tax calculation method has changed. As a result, the sum of the item prices may not exactly match the displayed subtotal or total. This is due to the higher precision price used when the item was originally created.',
  invoiceGstWarningSupportMessage:
    'If you have any questions or require further clarification, please contact our {link}.',
  itemTotal: 'Item Total',
  subtotal: 'Subtotal',
  resetCalculate: 'Reset Calculate',
  taxAriaLabel: 'include invoice goods and services tax',
  taxExcludedShort: 'GST Excl.',
  taxIncludedShort: 'GST Incl.',
  total: 'Total',
})
