import { ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDeleteButton = styled(ButtonLink)`
  color: ${({ theme }) => theme.colors.RED_1000};

  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.RED_1000};
  }
`
