import { BodyDefault, BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ImageSpecification = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 16px 0 0;
`

export const SettingDescription = styled(BodyDefault)`
  margin: 0 0 24px;
`
