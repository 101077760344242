import { Flex, MessageSize, MessageWithIcon } from '@npco/zeller-design-system'

import {
  type IllustrationName,
  useGetIllustrationFromName,
} from './useGetIllustrationFromName'

type NoTransactionsInfoProps = {
  title: string
  description: React.ReactNode
  illustrationName: IllustrationName
}

export const EmptyStateMessageOnly = ({
  title,
  description,
  illustrationName,
}: NoTransactionsInfoProps) => {
  const image = useGetIllustrationFromName(illustrationName)

  return (
    <Flex justifyContent="center" marginTop={{ _: '40px', XS: '80px' }}>
      <MessageWithIcon
        size={MessageSize.LARGE}
        image={image}
        title={title}
        description={description}
      />
    </Flex>
  )
}
