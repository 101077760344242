import dayjs from 'utils/dayjs'
import { quarterDisplayDate } from 'components/Statements/Statements.utils'

enum QuarterNumberEnum {
  'III' = 1,
  'IV' = 2,
  'I' = 3,
  'II' = 4,
}

export const useGetUIData = () => {
  const previousQuarterName = `Quarter ${
    QuarterNumberEnum[dayjs().subtract(1, 'quarter').quarter()]
  }`
  const previousQuarterDate = quarterDisplayDate(
    dayjs().subtract(1, 'quarter').quarter()
  )
  const currentQuarterName = `Quarter ${QuarterNumberEnum[dayjs().quarter()]}`
  const currentQuarterDate = quarterDisplayDate(dayjs().quarter())
  const currentMonthDate = dayjs().format('MMMM YYYY')
  const previousMonthDate = dayjs().subtract(1, 'month').format('MMMM YYYY')

  return {
    previousQuarterName,
    previousQuarterDate,
    currentQuarterName,
    currentQuarterDate,
    currentMonthDate,
    previousMonthDate,
  }
}
