import {
  InputSelectComboboxStandardItemWrapper,
  StyledAvatarBasic,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledAccountSelectItem = styled(
  InputSelectComboboxStandardItemWrapper
)`
  &[aria-selected='true'] {
    ${StyledAvatarBasic} {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`

export const StyledContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  ${StyledAvatarBasic} {
    transition: background-color 0.3s ease-out;
  }

  &:hover,
  &:focus {
    ${StyledAvatarBasic} {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`
