import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'

export const useNavigateToCardSettings = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const navigateToCardSettings = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).CARDS.SETTINGS.path)
  }, [navigate, shortEntityId])

  const navigateToCardSettingsOutstandingExpenses = useCallback(() => {
    navigate(
      ROOT.ORGS.ORG(shortEntityId).CARDS.SETTINGS.OUTSTANDING_EXPENSES.path
    )
  }, [navigate, shortEntityId])

  return { navigateToCardSettings, navigateToCardSettingsOutstandingExpenses }
}
