import { useContext } from 'react'

import { MFAContext } from '../../MFAContext'

export const useMFAState = () => {
  const context = useContext(MFAContext)

  if (context === null) {
    throw new Error('useMFAState can only be used within MFAContext')
  }

  const { isRedirecting, appState, onCancel } = context

  return { isRedirecting, appState, onCancel }
}
