import { TooltipRenderProps } from 'react-joyride'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'
import { corporateCardWalkthroughTranslations } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough/CorporateCardWalkthrough/CorporateCardWalkthrough.i18n'

import * as styled from 'components/Onboarding/OnboardingTooltip.styled'
import { translationsShared } from 'translations/index'

import { ToolTipGraphics } from '../ToolTipGraphics/ToolTipGraphics'

export interface CorporateCardWalkthroughToolTipsProps
  extends TooltipRenderProps {
  totalSteps: number
  isAdmin: boolean
}

export const CorporateCardWalkthroughToolTips = ({
  index,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
  totalSteps,
  isAdmin,
}: CorporateCardWalkthroughToolTipsProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(corporateCardWalkthroughTranslations)

  const isLastStep = index === totalSteps - 1
  return (
    <styled.TooltipContainer
      {...tooltipProps}
      maxWidth="200px"
      data-testid="corporate-card-walkthrough-tooltip"
    >
      <ToolTipGraphics index={index} isAdmin={isAdmin} />
      {step.title && <styled.TooltipTitle>{step.title}</styled.TooltipTitle>}
      <styled.TooltipBody>{step.content}</styled.TooltipBody>
      {isLastStep ? (
        <Flex justifyContent="space-between">
          <styled.SecondaryTooltipButton
            {...closeProps}
            title={t('secondaryTooltipButtonTitle')}
            aria-label={t('secondaryTooltipButtonTitle')}
          >
            {t('secondaryTooltipButtonTitle')}
          </styled.SecondaryTooltipButton>
          <styled.PrimaryTooltipButton
            {...primaryProps}
            title={tShared('gotIt')}
            aria-label={tShared('gotIt')}
          >
            {tShared('gotIt')}
          </styled.PrimaryTooltipButton>
        </Flex>
      ) : (
        <Flex justifyContent="flex-end">
          <styled.PrimaryTooltipButton
            {...primaryProps}
            title={tShared('next')}
            aria-label={tShared('next')}
          >
            {tShared('next')}
          </styled.PrimaryTooltipButton>
        </Flex>
      )}
    </styled.TooltipContainer>
  )
}
