import { gql, type TypedDocumentNode } from '@apollo/client'

export type OutstandingExpensesToggleDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  outstandingTransactions: number | null
}

export const OutstandingExpensesToggleDebitCardV2FragmentDoc = gql`
  fragment OutstandingExpensesToggleDebitCardV2Fragment on DebitCardV2 {
    id
    outstandingTransactions
  }
` as unknown as TypedDocumentNode<
  OutstandingExpensesToggleDebitCardV2Fragment,
  undefined
>
