import { BodyDefault, Heading, HeadingStyles } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledSummaryTitle = styled(BodyDefault)`
  margin: 0;
  margin-bottom: 0.5rem;
`

export const StyledSummaryValue = styled(Heading.H2).attrs({
  withStyles: HeadingStyles.H3,
})`
  margin: 0 1rem 0 0;
  animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
`
