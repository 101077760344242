import { useMutation } from '@apollo/client'
import { UpdateCustomer } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'

import {
  UpdateCustomer as UpdateCustomerResponse,
  UpdateCustomerVariables,
} from 'types/gql-types/UpdateCustomer'

export const useUpdateCustomer = () => {
  const [updateCustomer, { loading: isUpdatingCustomer }] = useMutation<
    UpdateCustomerResponse,
    UpdateCustomerVariables
  >(UpdateCustomer)

  return {
    updateCustomer,
    isUpdatingCustomer,
  }
}
