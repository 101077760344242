import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  getInputAdaptiveIconColor,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as ClockIcon } from 'assets/svg/clock.svg'

import { clockTranslations } from './Clock.i18n'

interface ClockProps {
  defaultColor?: string
}

export const Clock = ({ defaultColor = COLOR.BLUE_1000 }: ClockProps) => {
  const t = useTranslations(clockTranslations)

  return (
    <SvgIcon
      height="16"
      width="16"
      color={getInputAdaptiveIconColor({
        hasError: false,
        disabled: false,
        defaultColor,
      })}
      ariaLabel={t('clock')}
    >
      <ClockIcon />
    </SvgIcon>
  )
}
