import { Box, Flex, Spinner } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface UploadDocumentLoaderProps {
  currentFileIndex: number
  totalFilesAmount: number
}
export const UploadDocumentLoader = ({
  currentFileIndex,
  totalFilesAmount,
}: UploadDocumentLoaderProps) => {
  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Box mb="1.5rem">
        <Spinner dataTestId="loader" />
      </Box>

      <Flex alignItems="center" flexDirection="column">
        <div>
          {translate('form.shared.uploadDocuments.uploadingProgress', {
            currentFileIndex,
            totalFilesAmount,
          })}
        </div>
        <div>{translate('component.uploadInProgress.description2')}</div>
      </Flex>
    </Flex>
  )
}
