import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, TooltipBasic, zIndexMap } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { CopyIconButton } from './CopyIconButton/CopyIconButton'

interface CopyIconButtonProps {
  id?: string
  valueToCopy?: string
  copySuccessMap?: Record<string, boolean>
}

export const CopyButtonWithTooltip = ({
  id,
  valueToCopy,
  copySuccessMap,
}: CopyIconButtonProps) => {
  const tShared = useTranslations(translationsShared)
  const renderTrigger = useCallback(
    ({ setIsTooltipVisible, ref, isTooltipVisible }) => (
      <Flex ref={ref}>
        <CopyIconButton
          id={id}
          isTooltipVisible={isTooltipVisible}
          copySuccessMap={copySuccessMap}
          valueToCopy={valueToCopy}
          setIsTooltipVisible={setIsTooltipVisible}
        />
      </Flex>
    ),
    [id, copySuccessMap, valueToCopy]
  )

  return (
    <TooltipBasic
      closeOnOutsideClick={false}
      isInteractive
      placement="top"
      renderTrigger={renderTrigger}
      showArrow={false}
      zIndex={Number(zIndexMap.tooltip)}
    >
      <Box>{tShared('copied')}</Box>
    </TooltipBasic>
  )
}
