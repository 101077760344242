import { useEffect, useRef } from 'react'

import { SelectItemBasic, SelectItemRenderProps } from '../../Select.types'

interface Props<ItemType> {
  isSelected: boolean
  item: ItemType
  idBase: string
  onSelectItem: (item: ItemType) => void
  renderItem: (
    renderProps: SelectItemRenderProps<ItemType>
  ) => React.ReactElement
  hasSelectedIndicator?: boolean
}

export const SelectBasicMobileItem = <ItemType extends SelectItemBasic>({
  isSelected,
  renderItem,
  item,
  onSelectItem,
  idBase,
  hasSelectedIndicator,
}: Props<ItemType>) => {
  const ref = useRef<HTMLLIElement>(null)
  const id = `${idBase}-${item.value}`

  useEffect(() => {
    if (isSelected) {
      ref.current?.scrollIntoView()
    }
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {renderItem({
        // eslint-disable-next-line no-useless-computed-key
        ['aria-selected']: isSelected,
        id,
        key: id,
        onClick: () => onSelectItem(item),
        role: 'option',
        item,
        ref,
        hasSelectedIndicator,
      })}
    </>
  )
}
