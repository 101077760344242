import type {
  ContactType,
  InvoiceDiscountConfig,
  InvoiceItemUnit,
  InvoiceStatus,
  PartialInvoiceItemInput,
  RevisionStatus,
} from '@npco/mp-gql-types'
import type currency from 'currency.js'

import type { InvoiceSettingsCustomisationFormFields } from '../../Settings/SettingsCustomisation/SettingsCustomisation.types'

export enum PaymentTerms {
  Immediate = '0',
  Days7 = '7',
  Days14 = '14',
  Days30 = '30',
  Days60 = '60',
  Days90 = '90',
  Custom = 'Custom',
}

export type InvoiceItem = {
  catalogItemUuid: string
  description: string
  discount: {
    config: InvoiceDiscountConfig
    percentage: string
    price: string
  }
  hasCalculation: boolean
  initialDescription: string
  initialName: string
  initialPrice: currency
  initialQuantity: string
  id?: string
  key: number
  name: string
  price: currency
  quantity: string
  taxApplicable: boolean
  unit: InvoiceItemUnit
}

export type InvoiceItemCalculateFormFields = {
  hasCalculation: boolean
  price: currency
  quantity: string
  unit: InvoiceItemUnit
}

export type InvoiceDiscountFormFields = {
  config: InvoiceDiscountConfig
  percentage: string
  price: string
}

export type InvoiceTaxFormFields = {
  itemsTaxInclusive: boolean
}

export type InvoiceContactValues = {
  contactUuid: string
  contactName: string
  contactType: ContactType
  status?: RevisionStatus
}

export type InvoiceFormFields = {
  customer: {
    payerContact: InvoiceContactValues | null
    attentionToContact: InvoiceContactValues | null
  }
  discount: {
    config: InvoiceDiscountConfig
    percentage: string
    price: string
  }
  discountsEnabled: boolean
  title: {
    header: string
    message: string
  }
  id?: string
  items: InvoiceItem[]
  itemsApplyTax: boolean
  itemsTaxInclusive: boolean
  schedule: {
    invoiceDate: string
    paymentTerms: PaymentTerms | null
    dueDate: string
    sendEnabled: boolean
    sendDate: string
  }
  delivery: {
    email: {
      isEnabled: boolean
      recipient: string
      cc: string[]
      bcc: string[]
      subject: string | null
      message: string | null
      sendMeCopy: boolean
    }
    sms: {
      isEnabled: boolean
      recipient: string
    }
  }
  maximumLimit: currency
  minimumLimit: currency
  requiredEmailUpdateBeforeSend: string[]
  referenceNumber: string
  status: InvoiceStatus
}

export interface InvoiceCustomisationFormFieldsAndPreviewDefaults
  extends InvoiceFormFields {
  customisation?: InvoiceSettingsCustomisationFormFields
  receiptLogo?: string
  defaults?: {
    addressStreet: string
    addressCity: string
    addressState: string
    addressPostcode: string
    attentionToContactFirstName: string
    attentionToContactLastName: string
    businessEmail: string
    businessName: string
    businessNumber: string
    businessPhone: string
    businessWebsite: string
    customerBusinessName: string
    items: PartialInvoiceItemInput[]
    itemsTaxInclusive: boolean
    referenceNumber: string
    title: string
    dueDate: string
  }
}

// NOTE: refactor invoice form fields to be driven from the validation schema
// so that the total price properties are included by default
export type InvoiceFormFieldsWithTotalErrors = InvoiceFormFields & {
  itemsExceedsMaxAmount?: string
  itemsExceedsMinAmount?: string
}
