import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CategoriesChartCashFlowCategoryNetAmountFragmentDoc } from '../../../CategoriesChart/CategoriesChart.generated'
import { CategoriesTableCashFlowCategoryNetAmountFragmentDoc } from '../../../CategoriesTable/CategoriesTable.generated'

const defaultOptions = {} as const
export type GetCashflowCategoriesNetAmountsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  accountUuid: Types.InputMaybe<Types.Scalars['String']['input']>
  date: Types.Scalars['AWSDate']['input']
  reportType: Types.CashFlowReportType
  timeZone: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type GetCashflowCategoriesNetAmountsQueryResponse = {
  __typename?: 'Query'
  getCashFlowCategoriesNetAmounts: {
    __typename?: 'CashFlowCategoriesNetAmounts'
    categoryNetAmounts: Array<{
      __typename: 'CashFlowCategoryNetAmount'
      noOfTransaction: number
      change: number | null
      category: Types.EntityCategories | null
      subcategoryCount: number | null
      average: { __typename?: 'Money'; currency: string; value: string }
      total: { __typename?: 'Money'; currency: string; value: string }
    }> | null
  }
}

export const GetCashflowCategoriesNetAmounts = gql`
  query GetCashflowCategoriesNetAmounts(
    $entityUuid: ID!
    $accountUuid: String
    $date: AWSDate!
    $reportType: CashFlowReportType!
    $timeZone: String
  ) {
    getCashFlowCategoriesNetAmounts(
      entityUuid: $entityUuid
      accountUuid: $accountUuid
      date: $date
      reportType: $reportType
      timeZone: $timeZone
    ) {
      categoryNetAmounts {
        ...CategoriesTableCashFlowCategoryNetAmountFragment
        ...CategoriesChartCashFlowCategoryNetAmountFragment
      }
    }
  }
  ${CategoriesTableCashFlowCategoryNetAmountFragmentDoc}
  ${CategoriesChartCashFlowCategoryNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  GetCashflowCategoriesNetAmountsQueryResponse,
  GetCashflowCategoriesNetAmountsQueryVariables
>

/**
 * __useGetCashflowCategoriesNetAmountsQuery__
 *
 * To run a query within a React component, call `useGetCashflowCategoriesNetAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashflowCategoriesNetAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashflowCategoriesNetAmountsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      accountUuid: // value for 'accountUuid'
 *      date: // value for 'date'
 *      reportType: // value for 'reportType'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetCashflowCategoriesNetAmountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCashflowCategoriesNetAmountsQueryResponse,
    GetCashflowCategoriesNetAmountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCashflowCategoriesNetAmountsQueryResponse,
    GetCashflowCategoriesNetAmountsQueryVariables
  >(GetCashflowCategoriesNetAmounts, options)
}
export function useGetCashflowCategoriesNetAmountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCashflowCategoriesNetAmountsQueryResponse,
    GetCashflowCategoriesNetAmountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCashflowCategoriesNetAmountsQueryResponse,
    GetCashflowCategoriesNetAmountsQueryVariables
  >(GetCashflowCategoriesNetAmounts, options)
}
export type GetCashflowCategoriesNetAmountsQueryHookResult = ReturnType<
  typeof useGetCashflowCategoriesNetAmountsQuery
>
export type GetCashflowCategoriesNetAmountsLazyQueryHookResult = ReturnType<
  typeof useGetCashflowCategoriesNetAmountsLazyQuery
>
export type GetCashflowCategoriesNetAmountsQueryResult = Apollo.QueryResult<
  GetCashflowCategoriesNetAmountsQueryResponse,
  GetCashflowCategoriesNetAmountsQueryVariables
>
