import { gql } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { AccountStatusDebitCardAccountV2Fragment } from './AccountStatus.generated'
import {
  StyledAccountStatus,
  StyledAccountStatusLabel,
  StyledIconAccountClosed,
  StyledIconAccountSuspended,
} from './AccountStatus.styled'
import { getAccountStatusLabel } from './AccountStatus.utils'

interface AccountStatusProps {
  account: AccountStatusDebitCardAccountV2Fragment
}

export const AccountStatus = ({ account }: AccountStatusProps) => {
  const isClosed = account.status === DebitCardAccountStatus.CLOSED

  return (
    <StyledAccountStatus>
      {isClosed ? <StyledIconAccountClosed /> : <StyledIconAccountSuspended />}
      <StyledAccountStatusLabel>
        {getAccountStatusLabel(account)}
      </StyledAccountStatusLabel>
    </StyledAccountStatus>
  )
}

AccountStatus.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountStatusDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status
      ...GetAccountStatusLabelDebitCardAccountV2Fragment
    }

    ${getAccountStatusLabel.fragments.DebitCardAccountV2}
  `,
}
