import { UseTranslationsFn } from '@npco/utils-translations'

import { XeroErrorModalTranslations } from '../XeroErrorModal.i18n'
import { XeroServiceName } from '../XeroErrorModal.styled'

export const getBankFeeds = (
  t: UseTranslationsFn<typeof XeroErrorModalTranslations>
) => <XeroServiceName>{t('bankFeedsIntegration')}</XeroServiceName>

export const getPaymentServices = (
  t: UseTranslationsFn<typeof XeroErrorModalTranslations>
) => <XeroServiceName>{t('zellerPaymentService')}</XeroServiceName>

export const getXeroServices = (
  t: UseTranslationsFn<typeof XeroErrorModalTranslations>
) => (
  <>
    {t('services', {
      service1: getBankFeeds(t),
      service2: getPaymentServices(t),
    })}
  </>
)

export const getServicesToDisplay = (
  t: UseTranslationsFn<typeof XeroErrorModalTranslations>,
  shouldDisplayBankFeeds: boolean,
  shouldDisplayPaymentServices: boolean
) => {
  if (shouldDisplayBankFeeds && shouldDisplayPaymentServices) {
    return getXeroServices(t)
  }
  if (shouldDisplayBankFeeds) {
    return getBankFeeds(t)
  }
  if (shouldDisplayPaymentServices) {
    return getPaymentServices(t)
  }
  return undefined
}

export const getGenericErrorDescription = (
  t: UseTranslationsFn<typeof XeroErrorModalTranslations>,
  servicesWithGenericError?: React.ReactNode
) => (
  <span>
    {t('genericErrorDescription', {
      services: servicesWithGenericError,
    })}
  </span>
)

export const getAuthErrorDescription = (
  t: UseTranslationsFn<typeof XeroErrorModalTranslations>,
  servicesWithAuthError?: React.ReactNode
) => (
  <span>
    {t('authErrorDescription', {
      services: servicesWithAuthError,
    })}
  </span>
)
