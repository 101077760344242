import { useTranslations } from '@npco/utils-translations'
import { Typography } from '@npco/zeller-design-system'

import { createSavingsAccountPromptTranslations } from './CreateSavingsAccountPrompt.i18n'

export const CreateSavingsAccountPrompt = () => {
  const t = useTranslations(createSavingsAccountPromptTranslations)

  return (
    <Typography component="p" variant="body-base">
      {t('prompt')}
    </Typography>
  )
}
