import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceItemsWrapper = styled(Flex)`
  flex-direction: column;
  row-gap: 40px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.MD}) {
    row-gap: 16px;
  }
`

export const InvoiceItemWrapper = styled(Flex)`
  flex-direction: column;
  row-gap: 16px;
  flex-wrap: wrap;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.MD}) {
    padding-left: 42px;
    position: relative;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.LG}) {
    flex-wrap: unset;
  }
`

export const InvoiceRowWrapper = styled(Flex)`
  row-gap: 16px;
  flex-wrap: wrap;
`

export const InvoiceItemNameWrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  order: 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.LG}) {
    flex: 0 0 256px;
    padding-right: 16px;
  }
`

export const InvoiceItemDescriptionAndPriceWrapper = styled(Flex)`
  flex-wrap: wrap;
  flex: 1 0 100%;
  order: 3;
  row-gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.LG}) {
    flex: 1;
    flex-wrap: unset;
    order: 2;
  }
`

export const InvoiceItemDiscountWrapper = styled(Flex)`
  align-items: flex-start;
  flex-basis: 184px;
  flex-direction: column;
  flex: 1;
  grid-gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
`

export const InvoiceItemMoreOptionsWrapper = styled(Flex)`
  // NOTE: ensures poppers always appears in the correct position consistently
  height: 48px;
  order: 2;
  padding: 16px 6px 0 2px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.LG}) {
    order: 3;
  }
`

export const InvoiceItemDescriptionWrapper = styled(Flex)`
  flex: 1 0 100%;
  flex-direction: column;
  padding-right: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex: auto;
    padding-right: unset;
  }
`

export const InvoiceItemCalculatorWrapper = styled(Flex)`
  padding-left: 0px;
  padding-right: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding-left: 16px;
  }
`

export const InvoiceItemPriceWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 184px;
  padding-right: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-grow: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.LG}) {
    flex-basis: 168px;
    padding-right: 0;
  }
`
