import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { ConnectionType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { COLOR, showApiErrorToast } from '@npco/zeller-design-system'

import {
  GetXeroOrganisations as GetXeroOrganisationsResponse,
  GetXeroOrganisationsVariables,
} from 'types/gql-types/GetXeroOrganisations'

import { GetXeroOrganisations } from '../graphql/GetXeroOrganisations'

export const useXeroOrganisations = (connectionType: ConnectionType) => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data, error, refetch } = useQuery<
    GetXeroOrganisationsResponse,
    GetXeroOrganisationsVariables
  >(GetXeroOrganisations, {
    variables: { entityUuid, connectionType },
    onError: (err) => {
      showApiErrorToast(err)
    },
  })

  const organisationsResult = useMemo(() => {
    if (!data?.organisations) {
      return []
    }

    return data?.organisations.map((org) => ({
      value: org.id ?? '',
      label: org.name ?? '',
      avatar: {
        bgColor: COLOR.WHITE,
        color: COLOR.BLUE_1000,
        letter: org.name?.charAt(0) ?? '#',
      },
    }))
  }, [data?.organisations])

  return {
    isLoading: loading,
    organisations: organisationsResult,
    error,
    refetch,
  }
}
