import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { QUnitType } from 'dayjs'
import { descend, identity, pipe, sort } from 'ramda'

import dayjs from 'utils/dayjs'

interface WithTimestamp {
  timestamp: DebitCardTransactionsV2Fragment['timestamp']
}

const getGroupingKey = <T extends WithTimestamp>(
  item: T,
  groupInterval: QUnitType
) => {
  const { timestamp } = item
  return +dayjs(timestamp).utc().local().startOf(groupInterval)
}

const filterNonNullElements = <T extends WithTimestamp>(
  items: (T | null)[]
): T[] => items.filter(Boolean) as T[]

const makeGroupTransactionsByKey =
  (groupInterval: QUnitType) =>
  <T extends WithTimestamp>(transactions: T[]) =>
    transactions.reduce<Record<string, T[]>>(
      (transactionGroups, transaction) => {
        const key = String(getGroupingKey(transaction, groupInterval))
        const currentItemTransactionGroup = transactionGroups[key] ?? []

        return {
          ...transactionGroups,
          [key]: [...currentItemTransactionGroup, transaction],
        }
      },
      {}
    )

const sortTransactionGroupsByKeys = <T extends WithTimestamp>(
  transactionGroups: Record<string, T[]>
) => {
  const sortedKeys = sort(descend(identity), Object.keys(transactionGroups))

  return sortedKeys.map((key) => transactionGroups[key])
}

export const groupDebitCardsTransactions = pipe(
  filterNonNullElements,
  makeGroupTransactionsByKey('day'),
  sortTransactionGroupsByKeys
)

export const groupDebitCardsTransactionsByYear = pipe(
  filterNonNullElements,
  makeGroupTransactionsByKey('year'),
  sortTransactionGroupsByKeys
)
