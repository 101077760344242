import { Box } from '@npco/zeller-design-system'

import { PartialTransactionUpdateType } from 'hooks/transactions'
import { SwitchAnimationContainer } from 'components/DrawerWithNavigation/DrawerNavigation/SwitchAnimationContainer/SwitchAnimationContainer'

import {
  DebitCardTransaction,
  TransactionDrawerTabs,
} from '../TransactionDrawer.types'
import { useTransactionDrawerContent } from './hooks/useTransactionDrawerContent'
import { TransactionContactDetails } from './TransactionContactDetails'
import { TransactionDetails } from './TransactionDetails'
import { TransactionHeader } from './TransactionHeader'

interface TransactionDrawerContentProps {
  selectedTab: TransactionDrawerTabs
  transaction: DebitCardTransaction | null
  updateTransaction: (update: PartialTransactionUpdateType) => void
  onClose: () => void
}

export const TransactionDrawerContent = ({
  selectedTab,
  transaction,
  updateTransaction,
  onClose,
}: TransactionDrawerContentProps) => {
  const { unlinkContact, isUnlinkingContact, hasUnlinkingContactError } =
    useTransactionDrawerContent(transaction?.id, updateTransaction)

  if (!transaction) {
    return null
  }

  const isOnContactTab = selectedTab === TransactionDrawerTabs.CONTACT
  const contactUuid = transaction?.contact?.id
  const senderUuid = transaction?.payerDetails?.senderUuid

  return (
    <Box width="100%">
      <TransactionHeader
        transaction={transaction}
        isOnContactTab={isOnContactTab}
        unlinkContact={() => unlinkContact(contactUuid, senderUuid)}
        isUnlinkingContact={isUnlinkingContact}
        hasUnlinkingContactError={hasUnlinkingContactError}
      />

      {selectedTab === TransactionDrawerTabs.DETAILS && (
        <SwitchAnimationContainer animationKey={transaction.id}>
          <TransactionDetails
            debitCardTransactionUuid={transaction.id}
            updateTransaction={updateTransaction}
          />
        </SwitchAnimationContainer>
      )}
      {isOnContactTab && (
        <SwitchAnimationContainer animationKey={transaction.id}>
          <TransactionContactDetails
            transaction={transaction}
            onClose={onClose}
            isUnlinkingContact={isUnlinkingContact}
            hasUnlinkingContactError={hasUnlinkingContactError}
            unlinkContact={() => unlinkContact(contactUuid, senderUuid)}
            updateTransaction={updateTransaction}
          />
        </SwitchAnimationContainer>
      )}
    </Box>
  )
}
