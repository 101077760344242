import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledWrapper = styled(Flex)`
  min-height: 100%;
  height: 100%;
  padding-bottom: 20px;
  max-width: 95%;
  margin: 0 auto;
  overflow: auto;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding-bottom: 0;
    max-width: 100%;
    margin: auto;
  }
`

export const StyledLogoWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: block;
  }
`
