import {
  useIncomeOrExpenseSelection,
  useSelectedAccountId,
  useSelectedDateRange,
} from '@npco/mp-feature-cashflow-reporting'
import {
  DebitCardTransactionStatusV2,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'

import {
  DebitCardTransactions,
  useDebitCardTransactions,
} from 'pages/DebitCardTransactions'

import { getTimestampFilterFromSelectedDateRange } from './ContactTransactions.utils'
import { ViewAllTransactionsLink } from './ViewAllTransactionsLink/ViewAllTransactionsLink'

type ContactTransactionsProps = {
  contactUuid: string
  timezone: string | undefined
}

export const INCOME_TRANSACTION_TYPES = [
  DebitCardTransactionTypeV2.DE_IN,
  DebitCardTransactionTypeV2.NPP_IN,
  DebitCardTransactionTypeV2.BPAY_IN,
  DebitCardTransactionTypeV2.NPP_IN_RETURN,
  DebitCardTransactionTypeV2.INTEREST,
  DebitCardTransactionTypeV2.DEPOSIT,
  DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT,
  DebitCardTransactionTypeV2.TRANSFER_IN,
  DebitCardTransactionTypeV2.ACQUIRING_REFUND,
  DebitCardTransactionTypeV2.ACQUIRING_REFUND_RETURN,
]

export const EXPENSE_TRANSACTION_TYPES = [
  DebitCardTransactionTypeV2.PURCHASE,
  DebitCardTransactionTypeV2.PURCHASE_CNP,
  DebitCardTransactionTypeV2.REFUND,
  DebitCardTransactionTypeV2.REFUND_CNP,
  DebitCardTransactionTypeV2.DE_OUT,
  DebitCardTransactionTypeV2.NPP_OUT,
  DebitCardTransactionTypeV2.BPAY_OUT,
  DebitCardTransactionTypeV2.DDA_OUT,
  DebitCardTransactionTypeV2.NPP_OUT_RETURN,
  DebitCardTransactionTypeV2.DE_OUT_RETURN,
  DebitCardTransactionTypeV2.WITHDRAWAL,
  DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL,
  DebitCardTransactionTypeV2.TRANSFER_OUT,
]

export const ContactTransactions = ({
  contactUuid,
  timezone,
}: ContactTransactionsProps) => {
  const { selectedDateRange } = useSelectedDateRange()
  const selectedAccountId = useSelectedAccountId()
  const incomeOrExpense = useIncomeOrExpenseSelection()

  const { startDate, endDate } = getTimestampFilterFromSelectedDateRange(
    selectedDateRange,
    timezone
  )

  const {
    groupedDebitCardTransactions,
    hasNoInitialResults,
    loading,
    error,
    refetch,
    handleSelectedTransactionUpdate,
  } = useDebitCardTransactions({
    areFiltersInDefaultState: true,
    filters: {
      contactUuid: { eq: contactUuid },
      timestamp: { between: [startDate, endDate] },
      type: {
        in:
          incomeOrExpense === 'expense'
            ? EXPENSE_TRANSACTION_TYPES
            : INCOME_TRANSACTION_TYPES,
      },
      status: { in: [DebitCardTransactionStatusV2.APPROVED] },
      debitCardAccountUuid:
        selectedAccountId === 'all accounts'
          ? undefined
          : { eq: selectedAccountId.accountId },
    },
    limit: 10,
  })

  return (
    <>
      <DebitCardTransactions
        groupedDebitCardTransactions={groupedDebitCardTransactions}
        hasNoInitialResults={hasNoInitialResults}
        loading={loading}
        error={error}
        refetch={refetch}
        handleSelectedTransactionUpdate={handleSelectedTransactionUpdate}
        listHeight="100%"
        showMobileStyle
      />
      <Flex mt="24px" p="20px" width="100%" justifyContent="center">
        <ViewAllTransactionsLink />
      </Flex>
    </>
  )
}
