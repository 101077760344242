import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Income Categories',
  description:
    'Enhanced reporting for your revenue streams by tracking incoming transactions by income category.',
  comingSoonPill: 'Coming Soon',
  benefits1: 'Get a comprehensive breakdown of your income sources',
  benefits2: 'Simplify income reporting for accounting purposes',
  benefits3: 'Review all interest accrued from your Zeller Savings Account',
  alt: 'Income Category Coming Soon Preview',
})
