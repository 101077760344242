import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CompleteIcon } from 'assets/svg/complete.svg'
import {
  StyledButton,
  StyledHeader,
  StyledIconBox,
  StyledSuccessCopy,
} from 'components/SendReceiptModal/SendReceiptModal.styled'
import { component } from 'translations'

interface SendReceiptModalSuccessProps {
  value: string
  closeModal: () => void
}

export const SendReceiptModalSuccess = ({
  value,
  closeModal,
}: SendReceiptModalSuccessProps) => (
  <Flex
    mt={2}
    flexDirection="column"
    alignItems="center"
    data-testid="modal-success"
  >
    <StyledIconBox alignItems="center" justifyContent="center" mb="32px">
      <SvgIcon width="22" height="22" color={COLOR.BLUE_1000}>
        <CompleteIcon />
      </SvgIcon>
    </StyledIconBox>
    <StyledHeader>{component.modal.sendReceipt.sentHeader}</StyledHeader>
    <StyledSuccessCopy>{`${component.modal.sendReceipt.sentCopy}\n${value}`}</StyledSuccessCopy>
    <StyledButton fullWidth onClick={closeModal}>
      {component.modal.sendReceipt.done}
    </StyledButton>
  </Flex>
)
