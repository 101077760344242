import { ReactNode } from 'react'
import { Box, Flex } from '@npco/zeller-design-system'

import { BILL_STATUS } from 'const/sim'
import { currencyFormatter } from 'utils/common'
import { formatWeekdayDate } from 'utils/date'
import { SimBillType } from 'types/sim'
import {
  DEFAULT_DATA_ITEM,
  getAmountLabelBackgroundColor,
  getAmountLabelTextColor,
} from 'pages/Settings/Sim/BillingHistory/BillingHistory.utils'
import { SpinnerWrapped } from 'components/Spinner'
import SimBillStatusBadge from 'components/StatusBadge/SimBillStatusBadge/SimBillStatusBadge'
import { page } from 'translations'

import { Header } from './Header/Header'
import {
  StyledAmountBadge,
  StyledAmountContainer,
  StyledAmountLabel,
  StyledAmountValue,
  StyledDetailsLabel,
  StyledDetailsTitle,
  StyledDetailsValue,
  StyledSimCardDrawer,
} from './SimBillDrawer.styled'

interface Props {
  data: SimBillType | null
  isOpen: boolean
  onClose: () => void
}

const DetailsField = ({
  label,
  value,
}: {
  label: ReactNode
  value: ReactNode
}) => {
  return (
    <Flex justifyContent="space-between" mb="0.5rem">
      <StyledDetailsLabel>{label}</StyledDetailsLabel>
      <StyledDetailsValue>{value}</StyledDetailsValue>
    </Flex>
  )
}

export const SimBillDrawer = ({ data, isOpen, onClose }: Props) => {
  return (
    <StyledSimCardDrawer
      isOpen={isOpen}
      onClose={onClose}
      title=" "
      overlayClassName="animated-drawer-overlay"
      headerContent={data && <Header simId={data.simId} />}
    >
      {!data ? (
        <SpinnerWrapped variant="top" />
      ) : (
        <Box width="100%">
          <StyledDetailsTitle>
            {page.settings.sim.billingHistory.details.title}
          </StyledDetailsTitle>

          <DetailsField
            label={page.settings.sim.billingHistory.details.accountName}
            value={data.billingAccountName}
          />

          <DetailsField
            label={page.settings.sim.billingHistory.details.date}
            value={formatWeekdayDate(data.timestamp)}
          />

          <DetailsField
            label={page.settings.sim.billingHistory.details.siteName}
            value={data.siteName ?? DEFAULT_DATA_ITEM}
          />

          <DetailsField
            label={page.settings.sim.billingHistory.details.status}
            value={<SimBillStatusBadge status={BILL_STATUS[data.status]} />}
          />

          <StyledDetailsTitle>
            {page.settings.sim.billingHistory.details.amount}
          </StyledDetailsTitle>

          <DetailsField
            label={page.settings.sim.billingHistory.details.amount}
            value={currencyFormatter(data.amount)}
          />

          <DetailsField
            label={page.settings.sim.billingHistory.details.taxAmount}
            value={currencyFormatter(data.taxAmount ?? 0)}
          />
          <StyledAmountContainer>
            <StyledAmountBadge
              $backgroundColor={getAmountLabelBackgroundColor(data.status)}
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                margin="0 5px"
              >
                <StyledAmountLabel
                  $color={getAmountLabelTextColor(data.status)}
                >
                  {page.settings.sim.billingHistory.details.total}
                </StyledAmountLabel>
                <StyledAmountValue
                  $color={getAmountLabelTextColor(data.status)}
                >
                  {currencyFormatter(data.amount)}
                </StyledAmountValue>
              </Flex>
            </StyledAmountBadge>
          </StyledAmountContainer>
        </Box>
      )}
    </StyledSimCardDrawer>
  )
}
