import { gql } from '@apollo/client'

export const UpdateXeroSiteSettings = gql`
  mutation UpdateXeroSiteSettings(
    $entityUuid: ID!
    $input: UpdateXeroSiteSettingsInput!
  ) {
    updateXeroSiteSettings(entityUuid: $entityUuid, input: $input)
  }
`
