import { useEffect, useState } from 'react'
import debounce from 'debounce'

import * as Element from 'utils/dom.Element'

export type Rect = {
  x: number
  y: number
  width: number
  height: number
}

export const getElementRect = (
  selector: string | undefined
): Rect | undefined => {
  if (!selector) {
    return undefined
  }

  const element = window.document.querySelector(selector)
  if (!element) {
    return undefined
  }

  if (!Element.checkVisibility.call(element)) {
    return undefined
  }

  return Element.getBoundingClientRect.call(element)
}

export const DEFAULT_RESIZE_DEBOUNCE_MILLISECONDS = 100

export type UseElementRectOptions = {
  resizeDebounceMilliseconds?: number
}

/**
 * Returns a `Rect{x, y, width, height}` dimensions of a DOM Element referenced
 * by `selector` using `document.querySelector` with `getBoundingClientRect`.
 *
 * This `Rect` is recalculated if the window is resized via a debounced
 * callback. The debounce delay can be configured via the optional `options`
 * parameter.
 *
 * If the `selector` given cannot find the target element returns `undefined`.
 */
export const useElementRect = (
  selector: string | undefined,
  {
    resizeDebounceMilliseconds = DEFAULT_RESIZE_DEBOUNCE_MILLISECONDS,
  }: UseElementRectOptions = {}
): Rect | undefined => {
  const [elementRect, setElementRect] = useState<Rect>()

  useEffect(() => {
    const callback = () => setElementRect(getElementRect(selector))
    callback()
    const onResize = debounce(callback, resizeDebounceMilliseconds)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [selector, resizeDebounceMilliseconds])

  return elementRect
}
