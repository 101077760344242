import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import { ChartLineIcon } from '@npco/zeller-design-system'

import { ItemToggle } from '../components/ItemToggle/ItemToggle'
import { useReportsSubitems } from '../hooks/useReportsSubitems'
import { translations } from '../Sidebar.i18n'

export const ReportsItem = () => {
  const reportsSubitems = useReportsSubitems()
  const t = useTranslations(translations)

  const flags = useFeatureFlags()
  const isCashflowEnabled = flags.CashFlowReporting

  if (!isCashflowEnabled) {
    return null
  }

  return (
    <ItemToggle
      icon={<ChartLineIcon />}
      subItems={reportsSubitems}
      name="Reports"
      className="nav-item-reports"
    >
      {t('reports')}
    </ItemToggle>
  )
}
