import { useCallback, useState } from 'react'
import { TableData } from '@npco/ui-table'
import { Flex, PageTemplate, useModalState } from '@npco/zeller-design-system'
import { ErrorBoundary } from '@sentry/react'
import type { Row } from '@tanstack/react-table'

import { useInfiniteLoader } from 'hooks/useInfiniteLoader/useInfiniteLoader'
import { ErrorPage } from 'components/ErrorPage/ErrorPage'
import { ListLoader } from 'components/Lists'

import { HeaderPrimaryRow } from './components/HeaderPrimaryRow/HeaderPrimaryRow'
import { ManageSim } from './components/ManageSim/ManageSim'
import { PurchaseSimButton } from './components/PurchaseSimButton/PurchaseSimButton'
import { useGetSims } from './hooks/useGetSims'
import { SimDrawer } from './SimDrawer/SimDrawer'
import { SimEmptyPage } from './SimEmptyPage/SimEmptyPage'
import { SimErrorPage } from './SimErrorPage/SimErrorPage'
import { SimTable } from './SimTable/SimTable'
import { SimTableFragment } from './SimTable/SimTable.generated'

export const Sim = () => {
  const [selectedSim, setSelectedSim] = useState<SimTableFragment | null>(null)
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { isLoading, error, loadMore, nextToken, sims } = useGetSims()
  const { isLoadingMore, observerContainer } = useInfiniteLoader({
    isLoading,
    onLoadMore: loadMore,
    shouldLoadMore: !!nextToken,
  })

  const onRowClick = useCallback(
    (row: Row<TableData<SimTableFragment>>) => {
      setSelectedSim(row.original.data)
      openModal()
    },
    [openModal]
  )

  if (error && sims.length === 0) {
    return <SimErrorPage />
  }

  if (!isLoading && sims.length === 0) {
    return <SimEmptyPage />
  }

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <HeaderPrimaryRow>
          <Flex gap="10px">
            <PurchaseSimButton />
            <ManageSim />
          </Flex>
        </HeaderPrimaryRow>
      }
    >
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('team', 'payments')
          scope.setTag('feature', 'sim')
        }}
        fallback={<ErrorPage />}
      >
        <SimTable isLoading={isLoading} onRowClick={onRowClick} sims={sims} />
        <div ref={observerContainer} />
        {isLoadingMore && <ListLoader />}
        <SimDrawer
          isOpen={isModalOpen}
          closeModal={closeModal}
          selectedSim={selectedSim ?? undefined}
        />
      </ErrorBoundary>
    </PageTemplate>
  )
}
