import 'utils/yup'

import {
  InvoiceItem,
  InvoiceItemCalculateFormFields,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { itemsCalculateSchema } from 'features/Invoicing/components/Invoices/Invoice/schemas/items'
import { yupToFormErrors } from 'formik'

export const getInitialValues = ({
  item,
}: {
  item: InvoiceItem
}): InvoiceItemCalculateFormFields => ({
  hasCalculation: item.hasCalculation,
  price: item.price,
  quantity: item.quantity,
  unit: item.unit,
})

export const validateForm = (values: InvoiceItemCalculateFormFields) => {
  return itemsCalculateSchema
    .validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(yupToFormErrors)
}
