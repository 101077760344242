import React from 'react'
import {
  Flex,
  Heading,
  HeadingStyles,
  TickIcon,
} from '@npco/zeller-design-system'

interface ItemProps {
  description: string
}
export const Item = ({ description }: ItemProps) => {
  return (
    <Flex width="100%" gridGap="8px" alignItems="flex-start">
      <Flex
        width="22px"
        height="22px"
        alignItems="center"
        justifyContent="center"
      >
        <TickIcon width="12" height="12" />
      </Flex>
      <Heading.H4 withStyles={HeadingStyles.H6}>{description}</Heading.H4>
    </Flex>
  )
}
