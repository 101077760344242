import { useTranslations } from '@npco/utils-translations'
import { BoatSinking, Message, MessageSize } from '@npco/zeller-design-system'

import { errorStateTranslations } from './ErrorState.i18n'

interface ErrorStateProps {
  reload: () => void
}

export const ErrorState = ({ reload }: ErrorStateProps) => {
  const t = useTranslations(errorStateTranslations)

  return (
    <Message
      title={t('title')}
      description={t('description')}
      primaryButton={{
        label: t('reload'),
        onClick: () => reload(),
      }}
      size={MessageSize.MEDIUM}
      align="center"
      image={<BoatSinking size="large" />}
    />
  )
}
