import { useCallback, useEffect, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Divider } from '@npco/zeller-design-system'
import { useBPayTransferMFAState, useMFAState } from 'features/MFA'

import { formatAccountNumber } from 'utils/common'
import { BPAY_TRANSFER_MODAL_HEIGHT } from 'pages/Transfer/Transfer.utils'
import { AmountRow } from 'pages/Transfer/TransferModal/components/AmountRow/AmountRow'
import { AvatarWithTextRow } from 'pages/Transfer/TransferModal/components/AvatarWithTextRow/AvatarWithTextRow'
import { DefaultRow } from 'pages/Transfer/TransferModal/components/DefaultRow/DefaultRow'
import { SectionHeading } from 'pages/Transfer/TransferModal/components/SectionHeading/SectionHeading'
import { TransferModal } from 'pages/Transfer/TransferModal/TransferModal'

import { useBPayTransferModal } from '../../../bpay-transfer-routing'
import {
  ContactToLinkBpayToSchema,
  TransactionDetails,
} from '../../../bpay-transfer-routing/BPayTransfer.types'
import { useSubmitBpayTransfer } from '../../hooks/useSubmitBPayTransfer/useSubmitBPayTransfer'
import { formatDetailsWithSpace } from '../../utils/formatBPayDetails'
import { getBillerCode } from '../../utils/getBillerCode/getBillerCode'
import { summaryStageTranslations } from './SummaryStage.i18n'
import { getBillerName } from './SummaryStage.utils'

type SummaryStageProps = {
  transferDetails: TransactionDetails
}

export const SummaryStage = ({ transferDetails }: SummaryStageProps) => {
  const { from, to, amount, nickname, crn, paymentInstrumentUuid, reference } =
    transferDetails
  const t = useTranslations(summaryStageTranslations)
  const { closeBPayTransferModal } = useBPayTransferModal()
  const { handleSubmit, isDisabled, isLoading } =
    useSubmitBpayTransfer(transferDetails)
  const {
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
    bpayTransferState,
  } = useBPayTransferMFAState()

  useEffect(() => {
    if (hasRedirectedBackToApp && bpayTransferState) {
      setHasRedirectedBackToApp(false)
      handleSubmit()
    }
  }, [
    handleSubmit,
    bpayTransferState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  ])

  const billerCodeValue = getBillerCode(transferDetails)

  const billerDetail = to?.billerContacts?.find(
    (item) => item.id === paymentInstrumentUuid?.id
  )
  const bPayDetails = [
    {
      label: t('billerName'),
      value: getBillerName({ to, maybeBillerDetail: billerDetail }),
    },
    {
      label: t('bPayNickname'),
      value: nickname ?? billerDetail?.nickname ?? '-',
    },
    {
      label: t('billerCode'),
      value: formatDetailsWithSpace(billerCodeValue) ?? '-',
    },
    {
      label: t('crn'),
      value: crn ?? billerDetail?.reference ?? '-',
    },
  ]

  const handleEdit = useCallback(() => {
    closeBPayTransferModal()
  }, [closeBPayTransferModal])

  const { isRedirecting } = useMFAState()

  const maybeReferenceContact = useMemo(() => {
    const maybeContact = ContactToLinkBpayToSchema.safeParse(reference)

    return maybeContact.success ? maybeContact.data : null
  }, [reference])

  if (isRedirecting) {
    return null
  }

  const toIcon = to?.icon ? { ...to.icon, animation: null } : null

  return (
    <TransferModal.DecisionModal
      isOpen
      secondaryButtonLabel={t('edit')}
      primaryButtonLabel={t('payNow')}
      onCancel={handleEdit}
      onClickSecondary={handleEdit}
      onClickPrimary={handleSubmit}
      isLoadingPrimary={isLoading}
      isPrimaryButtonDisabled={isLoading || isDisabled}
      isSecondaryButtonDisabled={isLoading}
      className="animated-content"
      overlayClassName="animated-overlay"
      bodyClassName="transfer-modal-body"
      footerClassName="transfer-modal-footer"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentStyle={{
        height: BPAY_TRANSFER_MODAL_HEIGHT,
      }}
    >
      <TransferModal.ModalWrapper>
        <TransferModal.SectionWrapper
          gap="16px"
          aria-label="Sender account details"
        >
          <SectionHeading>{t('from')}</SectionHeading>
          <AvatarWithTextRow
            avatar={{
              icon: from.icon ? { ...from.icon, animation: null } : null,
              defaultLetter: from.icon?.letter ?? from.name.charAt(0),
            }}
            text={from.name}
            mb="16px"
          />
          <DefaultRow
            label={t('accountNumber')}
            value={formatAccountNumber(from.accountNumber)}
            mb="8px"
            aria-label="Sender Account Number"
          />
        </TransferModal.SectionWrapper>
        <Divider margin="18px 0" />
        <TransferModal.SectionWrapper
          gap="16px"
          aria-label="Recipient account details"
        >
          <SectionHeading>{t('to')}</SectionHeading>
          <AvatarWithTextRow
            avatar={{
              icon:
                to?.type === 'Contact'
                  ? toIcon
                  : {
                      colour: null,
                      letter: maybeReferenceContact?.label ?? '',
                      image: null,
                      images: null,
                      animation: null,
                    },
              defaultLetter:
                to?.type === 'Contact'
                  ? to?.icon?.letter ?? to?.name?.charAt(0)
                  : maybeReferenceContact?.label ?? '',
            }}
            text={
              to?.type === 'Contact'
                ? to?.name ?? '-'
                : maybeReferenceContact?.label ?? '-'
            }
            mb="18px"
          />
          {bPayDetails.map((item) => (
            <DefaultRow
              key={item.label}
              label={item.label}
              value={item.value}
              aria-label={item.label}
            />
          ))}
        </TransferModal.SectionWrapper>
        <Divider margin="18px 0" />
        <TransferModal.SectionWrapper aria-label="Transfer details">
          <AmountRow label={t('amount')} amountInCents={+amount * 100} />
        </TransferModal.SectionWrapper>
      </TransferModal.ModalWrapper>
    </TransferModal.DecisionModal>
  )
}
