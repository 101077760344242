import {
  BodyDefaultTypography,
  BodySmallTypography,
  Heading,
} from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

export const TooltipContainer = styled.div<{ maxWidth?: string }>`
  background: ${({ theme }) => theme.colors.DARK_BLUE};
  border-radius: 8px;
  padding: 20px;
  max-width: ${({ maxWidth = '240px' }) => maxWidth};
`

export const TooltipTitle = styled(Heading.H3)`
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: 16px;
`

export const TooltipBody = styled.div`
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: 24px;
`

const coreButtonStyles = css`
  border: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  outline: none;

  :active,
  :focus-visible {
    outline: 4px solid ${({ theme }) => theme.colors.BLUE_120};
  }
`

export const PrimaryTooltipButton = styled.button<{ fullWidth?: boolean }>`
  ${coreButtonStyles}
  ${BodyDefaultTypography}
  background: ${({ theme }) => rgba(theme.colors.WHITE, 0.1)};
  padding: 8px 24px;

  :hover {
    background: ${({ theme }) => rgba(theme.colors.WHITE, 0.3)};
  }

  :active {
    background: ${({ theme }) => rgba(theme.colors.WHITE, 0.1)};
    color: ${({ theme }) => theme.colors.GREY_250};
    outline: none;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export const SecondaryTooltipButton = styled.button`
  ${coreButtonStyles}
  ${BodySmallTypography};
  background: none;
  padding: 8px 0;
  text-decoration-line: underline;

  :active {
    color: ${({ theme }) => theme.colors.GREY_250};
    outline: none;
  }
`
