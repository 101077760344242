import { useSavingsAccountLearnMoreState } from 'features/SavingsAccountLearnMore/savings-account-learn-more-routing'

import { Condition } from 'components/Condition/Condition'

import { InterestEarningStage } from './InterestEarningStage/InterestEarningStage'
import { IntroStage } from './IntroStage/IntroStage'
import { ReadyToGoStage } from './ReadyToGoStage/ReadyToGoStage'
import { SavingsStage } from './SavingsStage/SavingsStage'

export const SavingsAccountLearnMoreModal = () => {
  const { state } = useSavingsAccountLearnMoreState()

  if (state === null) {
    return null
  }

  return (
    <>
      <Condition shouldShow={state.stage === 'intro'}>
        <IntroStage
          interestRate={state.interestRate}
          canCreateAccount={state.canCreateAccount}
        />
      </Condition>
      <Condition shouldShow={state.stage === 'interest-earning'}>
        <InterestEarningStage
          interestRate={state.interestRate}
          canCreateAccount={state.canCreateAccount}
        />
      </Condition>
      <Condition shouldShow={state.stage === 'savings'}>
        <SavingsStage
          interestRate={state.interestRate}
          canCreateAccount={state.canCreateAccount}
        />
      </Condition>
      <Condition shouldShow={state.stage === 'ready-to-go'}>
        <ReadyToGoStage
          interestRate={state.interestRate}
          canCreateAccount={state.canCreateAccount}
        />
      </Condition>
    </>
  )
}
