import { useCallback } from 'react'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { BasicModal } from 'components/BasicModal'
import { component, shared } from 'translations'

import { FileListItem } from '../FileListItem/FileListItem'

interface FileItemProps {
  className?: string
  id: string
  isUploaded?: boolean
  onDelete: (id: string) => void
  title: string
}

export const FileItem = ({
  className,
  id,
  isUploaded,
  onDelete,
  title,
}: FileItemProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const handleDelete = useCallback(() => {
    onDelete(id)
  }, [id, onDelete])

  return (
    <FileListItem
      id={id}
      title={title}
      onDelete={openModal}
      isUploaded={isUploaded}
      className={className}
    >
      <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
        <BasicModal
          modalTitle={component.modal.removeUploadedDocument.title}
          modalDescription={component.modal.removeUploadedDocument.description}
          onPrimaryButtonClick={handleDelete}
          onSecondaryButtonClick={closeModal}
          primaryButtonLabel={shared.remove}
        />
      </ModalBasic>
    </FileListItem>
  )
}
