import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE, CardAdded } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { useGoToBlockedAction } from '../../../../../../features/BlockedAction/blocked-action-routing'
import { CallToAction } from '../../../CallToAction'
import { NoCardsCallToActionEntityFragment } from './NoCardsCallToAction.generated'
import { translations } from './NoCardsCallToAction.i18n'

interface NoCardsCallToActionProps {
  entity: NoCardsCallToActionEntityFragment | null
}

export const NoCardsCallToAction = ({ entity }: NoCardsCallToActionProps) => {
  const t = useTranslations(translations)

  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToBlockedAction } = useGoToBlockedAction()

  const openCreateCardModal = useCallback(() => {
    if (entity && !entity.canCreateCard) {
      goToBlockedAction({ blockedAction: 'createCard' })
      return
    }

    goToAddCardStage({ stage: AddCardStage.LandingPageStage })
  }, [entity, goToAddCardStage, goToBlockedAction])

  return (
    <CallToAction
      description={t('description')}
      icon={<CardAdded size="medium" />}
      dataTestId="no-cards-call-to-action"
    >
      <CallToAction.Button
        size={BUTTON_SIZE.XSMALL}
        onClick={openCreateCardModal}
      >
        {t('addCard')}
      </CallToAction.Button>
    </CallToAction>
  )
}

NoCardsCallToAction.fragments = {
  Entity: gql`
    fragment NoCardsCallToActionEntityFragment on Entity {
      id
      canCreateCard
    }
  `,
}
