import { TransactionFilterInput } from '@npco/mp-gql-types'

import { filterObjectEmptyFields } from 'utils/mapFiltersInputToApiFilter'
import {
  mapDateFiltersToApi,
  mapSourceFilterToApi,
  mapStatusFilterToApi,
  mapTerminalsFiltersToApi,
  mapTypeFilterToApi,
} from 'components/Filters/PillFilters/shared/filterApi.utils'

import type { ReportsFiltersInput } from './ReportsNew.types'

export const mapFiltersInputToOverviewApiFilter = ({
  dates,
  statuses,
  terminals,
  types,
  sources,
}: ReportsFiltersInput): TransactionFilterInput =>
  filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates),
    deviceUuid: mapTerminalsFiltersToApi(terminals),
    type: mapTypeFilterToApi(types),
    sourceFilter: mapSourceFilterToApi(sources),
    status: mapStatusFilterToApi(statuses),
  })
