import { translate } from 'utils/translations'
import { CategoryItem } from 'components/CategoryDisplay/CategoryItem'

interface CategoryItemProps {
  text: string
  icon?: React.ReactNode
  onEdit?: () => void
  canEditCategories?: boolean
}

export const CategoryItemDisplay = ({
  text,
  icon,
  onEdit,
  canEditCategories = true,
}: CategoryItemProps) => {
  const shouldEdit = canEditCategories ? onEdit : undefined

  return (
    <CategoryItem
      label={translate('page.contact.sections.generalDetails.category')}
      text={text}
      icon={icon}
      onEdit={shouldEdit}
    />
  )
}
