import { Box, Flex, SkeletonAvatar } from '@npco/zeller-design-system'

import { SectionHeaderSkeleton } from 'components/SectionHeaderSkeleton'

export const ImagesSkeleton = () => {
  return (
    <Box>
      <SectionHeaderSkeleton pb="20px" />
      <Flex gridGap="16px">
        <SkeletonAvatar />
        <SkeletonAvatar />
        <SkeletonAvatar />
      </Flex>
    </Box>
  )
}
