import { useCallback, useMemo } from 'react'
import { currencyFormatter } from '@npco/component-mp-common'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import {
  useEditSpendControlsState,
  useGoToEditSpendControls,
} from 'features/EditSpendControls/edit-spend-controls-routing'

import { useGetEntityAddressTimezone } from 'hooks/useGetEntityAddressTimezone'
import { UNEXPECTED_ERROR } from 'types/errors'
import { SpendControlTypes } from 'pages/GlobalModals/components/SpendControlFields/SpendControlFields.types'

import { editSpendControlsTranslations } from '../../EditSpendControlsModal.i18n'
import { useEditSpendControls } from '../useEditSpendControls/useEditSpendControls'

export const useEditSpendControlsModal = () => {
  const { goToEditSpendControls } = useGoToEditSpendControls()
  const { editSpendControls, isLoading } = useEditSpendControls()
  const { state } = useEditSpendControlsState()
  const t = useTranslations(editSpendControlsTranslations)
  const {
    timezone: entityAddressTimezone,
    isLoading: isLoadingEntityAddressTimezone,
  } = useGetEntityAddressTimezone({ skip: !state })

  const cardUuid = state?.cardUuid

  const initialValues = useMemo<SpendControlTypes>(
    () => ({
      amountLimit: state?.amountLimit
        ? currencyFormatter(parseInt(state.amountLimit, 10))
            .slice(1)
            .replaceAll(',', '')
        : '',
      maxTransactionValue: state?.maxTransactionValue
        ? currencyFormatter(parseInt(state.maxTransactionValue, 10))
            .slice(1)
            .replaceAll(',', '')
        : '',
      velocityWindow: state?.velocityWindow,
    }),
    [state]
  )

  const closeModal = useCallback(() => {
    goToEditSpendControls(null)
  }, [goToEditSpendControls])

  const submitForm = useCallback(
    async (values: SpendControlTypes) => {
      if (!values.velocityWindow || !cardUuid) {
        ErrorLogger.reportError(
          '[Banking - Edit Spend Controls] Invalid values or card id editing spend controls',
          { values, cardUuid }
        )
        showErrorToast()
        return
      }

      if (values === initialValues) {
        closeModal()
        return
      }

      const result = await editSpendControls({
        cardUuid,
        velocityControl: {
          amountLimit: {
            currency: 'AUD',
            value: Math.round(Number(values.amountLimit) * 100).toString(),
          },
          maxTransactionValue: {
            currency: 'AUD',
            value: Math.round(
              Number(values.maxTransactionValue) * 100
            ).toString(),
          },
          velocityWindow: values.velocityWindow,
        },
        entityAddressTimezone,
        isUpdatingVelocityWindow:
          values.velocityWindow &&
          values.velocityWindow !== initialValues.velocityWindow,
      })

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('errorEditing'))
        return
      }

      showSuccessToast(t('successEditing'))
      closeModal()
    },
    [
      cardUuid,
      entityAddressTimezone,
      editSpendControls,
      closeModal,
      initialValues,
      t,
    ]
  )

  return {
    closeModal,
    submitForm,
    isEditingSpendControls: isLoading,
    initialValues,
    isLoadingEntityAddressTimezone,
    entityAddressTimezone,
  }
}
