import type { ComboboxProps } from '../useComboboxProps'
import * as styled from './ComboboxItem.styled'

export const ComboboxItem: ComboboxProps['renderItem'] = ({
  id,
  key,
  item,
  ref,
  style,
  onClick,
  role,
}) => (
  <styled.Item
    id={id}
    key={key}
    style={style}
    ref={ref}
    onClick={onClick}
    role={role}
    aria-label={`${item.label} ${item.subLabel}`}
  >
    <styled.ItemContent>
      <styled.ItemContentLabel>{item.label}</styled.ItemContentLabel>
      <styled.ItemContentSublabel>{item.subLabel}</styled.ItemContentSublabel>
    </styled.ItemContent>
    <styled.ItemIconBox>
      {item.icon === 'contact' && <styled.ItemIconBoxPersonIcon />}
      {item.icon === 'biller' && <styled.ItemIconBoxBPayIcon />}
    </styled.ItemIconBox>
  </styled.Item>
)
