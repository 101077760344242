import { COLOR } from '@npco/zeller-design-system'

interface Props extends React.SVGProps<SVGTextElement> {
  payload: {
    value: number
  }
  tickFormatter: (value: number) => string
  verticalAnchor?: string
  visibleTicksCount?: number
  isZero?: boolean
}

export const BaseYAxisChartTick = ({
  dy,
  fontSize = '14px',
  isZero = false,
  payload,
  textAnchor,
  tickFormatter,
  x,
  ...rest
}: Props) => {
  // Explicitly remove (and don't use) recharts props to avoid React warnings
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { verticalAnchor, visibleTicksCount, ...svgProps } = rest

  return (
    <text {...svgProps} x={x} textAnchor={textAnchor} fill={COLOR.BLACK_900}>
      <tspan x={x} dy={dy} fontSize={fontSize}>
        {isZero ? '$0' : tickFormatter(payload.value)}
      </tspan>
    </text>
  )
}
