import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'

import {
  CardTypeOption,
  CreateReviewCardState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { getCustomerDisplayName } from 'utils/customers'

import { cardDetailsReviewTranslations } from '../CardDetailsReview.i18n'
import { useGetCardNickname } from './useGetCardNickname'

interface UseCardDetailsReviewDisplayProps {
  state: CreateReviewCardState
}

export const useCardDetailsReviewDisplayValue = ({
  state,
}: UseCardDetailsReviewDisplayProps) => {
  const t = useTranslations(cardDetailsReviewTranslations)
  const { getCardNickname } = useGetCardNickname()

  const cardTypeDisplayValue = useMemo(() => {
    const cardTypeValue =
      state.cardType === CardTypeOption.DebitCard
        ? t('debitCard')
        : t('corporateCard')

    return t('cardType', {
      cardFormat: state.cardFormat,
      cardType: state.usePin ? cardTypeValue : t('debitCardAccessible'),
    })
  }, [t, state.cardFormat, state.usePin, state.cardType])

  return {
    cardTypeDisplayValue,
    linkedAccountDisplayValue: state.account.name,
    cardNicknameDisplayValue: getCardNickname({
      nickname: state.nickname,
      firstName: state.cardholder.firstName,
      cardType: state.cardType,
    }),
    cardholderDisplayValue: getCustomerDisplayName({
      firstname: state.cardholder.firstName,
      lastname: state.cardholder.lastName,
    }),
  }
}
