import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'

import { StyledSkeletonRowDesktop } from '../SelectLoadingPlaceholder.styled'

export const SelectSkeletonRowDesktop = () => {
  return (
    <StyledSkeletonRowDesktop data-testid="skeleton-dropdown-row">
      <SkeletonLoaderBasic height="0.75rem" width="16.25rem" isRounded />
    </StyledSkeletonRowDesktop>
  )
}
