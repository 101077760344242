import { gql } from '@apollo/client'
import { Table, TableData, useTable } from '@npco/ui-table'
import { BREAKPOINT, useIsMobileResolution } from '@npco/zeller-design-system'

import {
  AverageCell,
  ContactCell,
  QuantityCell,
  TotalCell,
} from '../../TableCells/TableRowCells'
import { ContactsTableCashFlowContactNetAmountFragment as ContactNetAmount } from './ContactsTable.generated'
import { columnsConfig } from './ContactsTableConfig'

interface ContactsTableProps {
  netAmounts: ContactNetAmount[]
  isLoading?: boolean
  transactionDirection: 'income' | 'expense'
  setContactUuid: React.Dispatch<React.SetStateAction<string | null>>
  onNetAmountHovered: (netAmount: ContactNetAmount) => void
  onNetAmountUnhovered: (netAmount: ContactNetAmount) => void
}

export const ContactsTable = ({
  netAmounts,
  isLoading = false,
  transactionDirection,
  setContactUuid,
  onNetAmountHovered,
  onNetAmountUnhovered,
}: ContactsTableProps) => {
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  const { table } = useTable<ContactNetAmount>({
    columnsConfig,
    isLoading,
    listData: netAmounts,
    meta: {
      transactionDirection,
    },
  })

  return (
    <Table<TableData<ContactNetAmount>>
      hideHeader={false}
      table={table}
      hasBorderBottom={false}
      hasLastTrBorderBottom={false}
      headBottomSpace={isMobileOrTablet ? '16px' : '40px'}
      onRowClick={(row) =>
        setContactUuid(row.original.data.contact?.id ?? null)
      }
      onRowMouseEnter={(row) => onNetAmountHovered(row.original.data)}
      onRowMouseLeave={(row) => onNetAmountUnhovered(row.original.data)}
    />
  )
}

ContactsTable.fragments = {
  CashFlowContactNetAmount: gql`
    fragment ContactsTableCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      __typename
      ...QuantityCellCashFlowContactNetAmountFragment
      ...AverageCellCashFlowContactNetAmountFragment
      ...TotalCellCashFlowContactNetAmountFragment
      ...ContactCellCashFlowContactNetAmountFragment
    }

    ${QuantityCell.fragments.CashFlowContactNetAmount}
    ${AverageCell.fragments.CashFlowContactNetAmount}
    ${TotalCell.fragments.CashFlowContactNetAmount}
    ${ContactCell.fragments.CashFlowContactNetAmount}
  `,
}
