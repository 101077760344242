import { BodySmall, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const UploadCopy = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0 0 16px 0;
`

export const EmptyStateCopy = styled(UploadCopy)`
  white-space: nowrap;
`

export const LogoImage = styled.img`
  height: auto;
  max-width: 100%;
  max-height: 128px;
`

export const LogoContainer = styled(Flex)<{
  hasLogo?: boolean
  isDragActive?: boolean
}>`
  border-width: 1px;
  border-style: ${({ hasLogo }) => (hasLogo ? 'solid' : 'dashed')};
  border-color: ${({ isDragActive, theme }) =>
    isDragActive ? theme.colors.BLUE_1000 : theme.colors.GREY_250};
  border-radius: 4px;
  background: ${({ hasLogo, theme }) =>
    hasLogo ? theme.colors.GREY_30 : theme.colors.WHITE};
  height: 144px;
  padding: 8px;
  outline: none;
  transition: border 0.3s ease-out;
`
