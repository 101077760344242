import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE, ZellerAccount } from '@npco/zeller-design-system'
import {
  CreateAccountStage,
  useGoToCreateAccountStage,
} from 'features/CreateAccount'

import { useGoToBlockedAction } from '../../../../../../features/BlockedAction/blocked-action-routing'
import { CallToAction } from '../../../CallToAction'
import { NoAccountsCallToActionEntityFragment } from './NoAccountsCallToAction.generated'
import { translations } from './NoAccountsCallToAction.i18n'

interface NoAccountsCallToActionProps {
  entity?: NoAccountsCallToActionEntityFragment
}

export const NoAccountsCallToAction = ({
  entity,
}: NoAccountsCallToActionProps) => {
  const t = useTranslations(translations)

  const { goToCreateAccountStage } = useGoToCreateAccountStage()
  const { goToBlockedAction } = useGoToBlockedAction()

  const openCreateAccountModal = useCallback(() => {
    const isCreateAccountBlocked = entity?.canCreateAccount === false

    if (isCreateAccountBlocked) {
      goToBlockedAction({ blockedAction: 'createAccount' })
      return
    }

    goToCreateAccountStage(CreateAccountStage.AccountNameStage)
  }, [entity?.canCreateAccount, goToBlockedAction, goToCreateAccountStage])

  return (
    <CallToAction
      description={t('description')}
      icon={<ZellerAccount size="medium" variant="light" />}
      dataTestId="no-accounts-call-to-action"
    >
      <CallToAction.Button
        onClick={openCreateAccountModal}
        size={BUTTON_SIZE.XSMALL}
      >
        {t('addAccount')}
      </CallToAction.Button>
    </CallToAction>
  )
}

NoAccountsCallToAction.fragments = {
  Entity: gql`
    fragment NoAccountsCallToActionEntityFragment on Entity {
      canCreateAccount
    }
  `,
}
