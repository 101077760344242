import { DataSmall as Base } from '@npco/zeller-design-system'
import { StyledSelectAdaptiveTrigger } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerAdaptive.styled'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledDropdownWrapper = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: ${(props) => props.width};
  box-sizing: border-box;

  ${StyledSelectAdaptiveTrigger} {
    overflow: hidden;
  }

  @media screen and (max-width: ${BREAKPOINT.SM - 1}px) {
    min-width: 10.5rem;
    width: 100%;
    gap: 12px;
  }
`

export const StyledDataSmall = styled(Base)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`

export const StyledCardWrapper = styled.div`
  background: ${({ theme }) => theme.colors.GREY_60};
  padding: 4.625rem 4rem;
  box-sizing: border-box;
  width: 100%;
  border-radius: 7px;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: none;
  }
`

export const StyledMobileCardWrapper = styled.div`
  display: none;
  border-radius: 7px;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: block;
    padding: 1.125rem 43px;
    background: ${({ theme }) => theme.colors.GREY_60};
  }
`
