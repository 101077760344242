import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'

export const useSelectedAccountId = () => {
  const { search } = useLocation()

  const selectedAccountId = useMemo(() => {
    const params = new URLSearchParams(search)
    const accountId = params.get('accountId')

    return accountId ? { accountId } : 'all accounts'
  }, [search])

  return selectedAccountId
}
