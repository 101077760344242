import { Navigate } from 'react-router-dom-v5-compat'
import { useAccountNavigation } from '@npco/mp-utils-accounts-routing'

import { CreateSavingsAccountForm } from './CreateSavingsAccountForm/CreateSavingsAccountForm'
import { CreateSavingsAccountLoader } from './CreateSavingsAccountLoader/CreateSavingsAccountLoader'
import { CreateSavingsAccountModalWrapper } from './CreateSavingsAccountModalWrapper/CreateSavingsAccountModalWrapper'
import { useCreateSavingsAccountViewData } from './hooks/useCreateSavingsAccountViewData/useCreateSavingsAccountViewData'

export const CreateSavingsAccountView = () => {
  const { isLoadingCreateSavingsAccountViewData, activeSavingsAccountId } =
    useCreateSavingsAccountViewData()
  const { getAccountTransactionsPath } = useAccountNavigation()

  if (isLoadingCreateSavingsAccountViewData) {
    return (
      <CreateSavingsAccountModalWrapper>
        <CreateSavingsAccountLoader />
      </CreateSavingsAccountModalWrapper>
    )
  }

  if (activeSavingsAccountId) {
    return (
      <Navigate
        to={getAccountTransactionsPath(activeSavingsAccountId)}
        replace
      />
    )
  }

  return <CreateSavingsAccountForm />
}
