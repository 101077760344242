import { useMemo } from 'react'
import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { INPUT_SIZE, InputAdaptive } from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  INVOICE_ITEMS_DISCOUNT_FIELD,
  INVOICE_ITEMS_PRICE_FIELD,
  INVOICE_ITEMS_QUANTITY_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { itemsSchemaErrors } from 'features/Invoicing/components/Invoices/Invoice/schemas/items'
import { useField, useFormikContext } from 'formik'

import { convertLocaleStringToNumber } from 'utils/localeString'
import { translate } from 'utils/translations'
import { CurrencySymbolWrapper } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField.styled'

import { getDiscount } from '../../hooks/useInvoiceItemsCalculations'
import { getTaxRate } from '../../InvoiceItemsAccordion.utils'
import {
  InvoiceItemDiscountInputWrapper,
  InvoiceItemDiscountText,
} from './InvoiceItemDiscount.styled'

const renderLeftControls = () => (
  <CurrencySymbolWrapper>$</CurrencySymbolWrapper>
)

export const translations = {
  ariaLabel: (lineIndex: number) =>
    translate('page.invoice.formSections.items.lineItemAriaLabel', {
      index: lineIndex,
      field: 'discount',
    }),
  itemDiscount: translate('page.invoice.formSections.items.itemDiscount'),
}

export interface InvoiceItemDiscountProps {
  isTaxApplicable: boolean
  lineIndex: number
  name: string
  onAddItemDiscount: () => void
}

export const InvoiceItemDiscount = ({
  isTaxApplicable,
  lineIndex,
  name,
  onAddItemDiscount: handleOnAddItemDiscount,
}: InvoiceItemDiscountProps) => {
  const { values } = useFormikContext<InvoiceFormFields>()

  const [discount] = useField<InvoiceItem['discount']>(
    `${name}.${INVOICE_ITEMS_DISCOUNT_FIELD}`
  )

  const [price, priceMeta] = useField<InvoiceItem['price']>(
    `${name}.${INVOICE_ITEMS_PRICE_FIELD}`
  )

  const [quantity] = useField<InvoiceItem['quantity']>(
    `${name}.${INVOICE_ITEMS_QUANTITY_FIELD}`
  )

  const isTaxInclusive = values.itemsTaxInclusive

  const value = useMemo(() => {
    const total = price.value
      .multiply(getTaxRate(isTaxApplicable, isTaxInclusive))
      .multiply(convertLocaleStringToNumber(quantity.value))

    const discountCurrency = getDiscount({
      discountConfig: discount.value.config,
      discountPercentage: discount.value.percentage,
      discountPrice: discount.value.price,
      total,
    })

    // NOTE: pattern only outputs the number value
    return currency(discountCurrency, { pattern: '#' }).format()
  }, [discount, isTaxApplicable, isTaxInclusive, price, quantity])

  const isPriceAboveDiscountAmount =
    priceMeta.error === itemsSchemaErrors.itemsPriceBelowDiscountError

  const showPercentageDiscount =
    discount.value.config === InvoiceDiscountConfig.PERCENTAGE

  const percentageDiscount = discount.value.percentage

  return (
    <>
      <InvoiceItemDiscountText>
        {translations.itemDiscount}{' '}
        {showPercentageDiscount && `(${percentageDiscount}%)`}
      </InvoiceItemDiscountText>
      <InvoiceItemDiscountInputWrapper>
        <InputAdaptive
          aria-label={translations.ariaLabel(lineIndex)}
          data-testid={`invoicing-invoice-line-item-discount-${lineIndex}`}
          hasError={isPriceAboveDiscountAmount}
          name={`invoicing-invoice-line-item-discount-${lineIndex}`}
          onClick={handleOnAddItemDiscount}
          renderLeftControls={renderLeftControls}
          readOnly
          size={INPUT_SIZE.SMALL}
          style={{ caretColor: 'transparent', textAlign: 'right' }}
          value={value}
        />
      </InvoiceItemDiscountInputWrapper>
    </>
  )
}
