import { useCallback } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useModalState } from 'design-system/Components/Modal'
import type { ContactSimpleFormProps } from 'features/Contacts/components/ContactSimpleForm'

import type { ItemContactOrBiller } from './useBPayTargetItems'

export type FormProps = Required<ContactSimpleFormProps>

export type Options = { onValueChange: (item: ItemContactOrBiller) => void }

export const useFormProps = ({ onValueChange }: Options) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const onCreate: FormProps['onCreate'] = useCallback(
    (contact) => {
      if (
        contact.contactType === ContactType.BUSINESS &&
        contact.businessName
      ) {
        const item: ItemContactOrBiller = {
          type: 'Contact',
          id: contact.id,
          name: contact.businessName,
          icon: null,
          isSelf: contact.isSelf,
          email: {
            email: contact?.email?.email ?? '',
          },
          phone: contact.phone,
          phoneV2: {
            phone: contact?.phoneV2?.phone ?? '',
          },
        }
        onValueChange(item)
        closeModal()
      }
    },
    [onValueChange, closeModal]
  )

  return {
    isModalOpen,
    onCreate,
    openModal,
    closeModal,
  }
}
