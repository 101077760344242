import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  ErrorMessageForm,
  InputAdaptiveProps,
  SvgIcon,
  Typography,
} from '@npco/zeller-design-system'
import { debounceTime, Subject, Subscription } from 'rxjs'

import { ReactComponent as DollarSign } from 'assets/svg/dollar.svg'
import { useInputHandlers } from 'hooks/useInputHandlers'
import {
  convertLocaleStringToNumber,
  convertNumberToLocaleString,
} from 'utils/localeString'
import { translationsShared } from 'translations'

import { translations } from './Amount.i18n'
import * as styled from './Amount.styled'
import { AmountValue } from './Amount.types'
import { getAmountLabel } from './Amount.utils'
import { FilterPopper } from './shared/FilterPopper'

type AmountProps = {
  debounceDuration?: number
  selectedAmount?: AmountValue
  setSelectedAmount: (amount: AmountValue) => void
}

const DollarSignIcon = (hasError: boolean) => (
  <SvgIcon
    width="16"
    height="16"
    withMarginRight
    color={hasError ? COLOR.RED_1000 : COLOR.BLUE_1000}
  >
    <DollarSign />
  </SvgIcon>
)

export const Amount = ({
  debounceDuration = 600,
  selectedAmount,
  setSelectedAmount,
}: AmountProps) => {
  const errorKey$ = useMemo(() => new Subject<'min' | 'max' | undefined>(), [])

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { handleCurrencyMax9DigitInputKeydown } = useInputHandlers()
  const minInputRef = useRef<HTMLInputElement>(null)
  const maxInputRef = useRef<HTMLInputElement>(null)

  const [minValue, setMinValue] = useState(
    selectedAmount?.min ? convertNumberToLocaleString(selectedAmount.min) : ''
  )
  const [maxValue, setMaxValue] = useState(
    selectedAmount?.max ? convertNumberToLocaleString(selectedAmount.max) : ''
  )
  const [errorKey, setErrorKey] = useState<'min' | 'max' | undefined>()

  const doesMinHaveError = useCallback(
    ({ min }: { min?: number }) => {
      if (
        min !== undefined &&
        maxValue &&
        min > convertLocaleStringToNumber(maxValue)
      ) {
        return true
      }
      return false
    },
    [maxValue]
  )

  const doesMaxHaveError = useCallback(
    ({ max }: { max?: number }) => {
      if (
        max !== undefined &&
        minValue &&
        max < convertLocaleStringToNumber(minValue)
      ) {
        return true
      }
      return false
    },
    [minValue]
  )

  const handleMinFocus: InputAdaptiveProps['onFocus'] = useCallback((e) => {
    const replacedCommaString = e.target.value.replace(/,/g, '')
    setMinValue(replacedCommaString)
  }, [])

  const handleMaxFocus: InputAdaptiveProps['onFocus'] = useCallback((e) => {
    const replacedCommaString = e.target.value.replace(/,/g, '')
    setMaxValue(replacedCommaString)
  }, [])

  const handleMinInputBlur: InputAdaptiveProps['onBlur'] = useCallback(
    (e) => {
      const min = e.target.value
        ? convertLocaleStringToNumber(e.target.value)
        : undefined

      setMinValue(min !== undefined ? convertNumberToLocaleString(min) : '')

      if (!errorKey && !doesMinHaveError({ min })) {
        setSelectedAmount({
          min,
          max: maxValue ? convertLocaleStringToNumber(maxValue) : undefined,
        })
      }
    },
    [doesMinHaveError, errorKey, maxValue, setSelectedAmount]
  )

  const handleMaxInputBlur: InputAdaptiveProps['onBlur'] = useCallback(
    (e) => {
      const max = e.target.value
        ? convertLocaleStringToNumber(e.target.value)
        : undefined

      setMaxValue(max !== undefined ? convertNumberToLocaleString(max) : '')

      if (!errorKey && !doesMaxHaveError({ max })) {
        setSelectedAmount({
          min: minValue ? convertLocaleStringToNumber(minValue) : undefined,
          max,
        })
      }
    },
    [doesMaxHaveError, errorKey, minValue, setSelectedAmount]
  )

  const active =
    selectedAmount?.min !== undefined || selectedAmount?.max !== undefined

  const activeLabel =
    selectedAmount && getAmountLabel(selectedAmount, tShared('currencySymbol'))

  const label = active ? activeLabel : t('amountLabel')

  const handleClear = useMemo(
    () =>
      active
        ? () => {
            setSelectedAmount({ min: undefined, max: undefined })
            setErrorKey(undefined)
            setMinValue('')
            setMaxValue('')
          }
        : undefined,
    [active, setSelectedAmount]
  )

  const handleMinChange: InputAdaptiveProps['onChange'] = useCallback(
    (e) => {
      setMinValue(e.target.value)
      const min = Number(e.target.value)
      if (doesMinHaveError({ min })) {
        errorKey$.next('min')
      } else {
        // NOTE: we immediately reset the error, but need to debounce
        // as well in case of race condition
        setErrorKey(undefined)
        errorKey$.next(undefined)
      }
    },
    [doesMinHaveError, errorKey$]
  )

  const handleMaxChange: InputAdaptiveProps['onChange'] = useCallback(
    (e) => {
      setMaxValue(e.target.value)
      const max = e.target.value ? Number(e.target.value) : undefined
      if (max !== undefined && doesMaxHaveError({ max })) {
        errorKey$.next('max')
      } else {
        // NOTE: we immediately reset the error, but need to debounce
        // as well in case of race condition
        setErrorKey(undefined)
        errorKey$.next(undefined)
      }
    },
    [doesMaxHaveError, errorKey$]
  )

  const handleMinKeyDown: InputAdaptiveProps['onKeyDown'] = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        maxInputRef.current?.focus()
      } else {
        handleCurrencyMax9DigitInputKeydown(e)
      }
    },
    [handleCurrencyMax9DigitInputKeydown]
  )

  const handleMaxKeyDown: InputAdaptiveProps['onKeyDown'] = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.currentTarget.blur()
      } else {
        handleCurrencyMax9DigitInputKeydown(e)
      }
    },
    [handleCurrencyMax9DigitInputKeydown]
  )

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      errorKey$.pipe(debounceTime(debounceDuration)).subscribe((nextValue) => {
        setErrorKey(nextValue)
      })
    )

    return () => subscription.unsubscribe()
  }, [debounceDuration, errorKey$])

  const minHasError = errorKey === 'min'
  const maxHasError = errorKey === 'max'
  const errorMessage = errorKey && t(`${errorKey}Error`)

  return (
    <FilterPopper label={label} active={active} onClear={handleClear}>
      {() => (
        <styled.ResponsiveWrapper>
          <styled.InputsWrapper>
            <styled.AmountInput
              autoFocus
              aria-errormessage="amountErrorMessage"
              aria-invalid={minHasError}
              aria-label={tShared('min')}
              hasError={minHasError}
              name={tShared('min')}
              onFocus={handleMinFocus}
              onBlur={handleMinInputBlur}
              onChange={handleMinChange}
              onKeyDown={handleMinKeyDown}
              ref={minInputRef}
              value={minValue}
              placeholder={tShared('min')}
              renderLeftControls={({ hasError }) => DollarSignIcon(!!hasError)}
            />
            <styled.Delimiter>
              <Typography color={COLOR.GREY_250} variant="body-base">
                –
              </Typography>
            </styled.Delimiter>
            <styled.AmountInput
              aria-errormessage="amountErrorMessage"
              aria-invalid={maxHasError}
              aria-label={tShared('max')}
              hasError={maxHasError}
              name={tShared('max')}
              onFocus={handleMaxFocus}
              onBlur={handleMaxInputBlur}
              onChange={handleMaxChange}
              onKeyDown={handleMaxKeyDown}
              ref={maxInputRef}
              value={maxValue}
              placeholder={tShared('max')}
              renderLeftControls={({ hasError }) => DollarSignIcon(!!hasError)}
            />
          </styled.InputsWrapper>
          {errorKey && (
            <ErrorMessageForm
              aria-live="assertive"
              // NOTE: The id should be set to the same value as the aria-errormessage,
              // but this component doesn't accept an id so using key until it does
              key="amountErrorMessage"
              hasError={!!errorKey}
              errorMessage={errorMessage}
            />
          )}
        </styled.ResponsiveWrapper>
      )}
    </FilterPopper>
  )
}
