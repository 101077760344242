import { createContext } from 'react'
import { Subject } from 'rxjs'

import { TransactionUpdateData } from 'types/transactions'

export interface TransactionUpdateContextType {
  transactionUpdates$: Subject<TransactionUpdateData> | null
}

export const TransactionUpdateContext =
  createContext<TransactionUpdateContextType>({
    transactionUpdates$: null,
  })
