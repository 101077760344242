import React from 'react'
import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'
import { MultiSelectTriggerRenderProps } from 'design-system/Components/MultiSelect'
import {
  SelectComponentsContent,
  SelectSize,
  StyledSelectStandardItem,
} from 'design-system/Components/Select'

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg'

export interface MobileFilter {
  label: React.ReactNode
  key: string
  renderFilter: (renderTrigger: () => React.ReactElement) => React.ReactElement
}

interface Props extends Partial<MultiSelectTriggerRenderProps> {
  label: React.ReactNode
}

export const FiltersListMobileListItem = React.forwardRef<HTMLLIElement, Props>(
  ({ label, ...rest }, ref) => {
    const content: React.ReactNode = (
      <Flex width="100%" justifyContent="space-between">
        {label}
        <Flex p="0.375rem" alignItems="center" justifyContent="center">
          <SvgIcon height="16" width="16" color={COLOR.BLUE_1000}>
            <ChevronRight />
          </SvgIcon>
        </Flex>
      </Flex>
    )

    return (
      <StyledSelectStandardItem
        data-testid="select-standard-item"
        className={SelectSize.Small}
        {...rest}
        ref={ref}
        hasSelectedIndicator
      >
        {/* Casting to string as SelectItem doesn't support anything apart from string */}
        <SelectComponentsContent hasValue label={content as string} />
      </StyledSelectStandardItem>
    )
  }
)
