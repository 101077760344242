import { UpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'
import { Condition } from 'components/Condition/Condition'

import { useUpgradeAccountsCompleteState } from '../../hooks/useUpgradeAccountsCompleteState'
import { UpgradeAccountsCompleteBuildBusinessStageContent } from './content/UpgradeAccountsCompleteBuildBusinessStageContent'

export const UpgradeAccountsCompleteBuildBusinessStage = () => {
  const { state } = useUpgradeAccountsCompleteState()
  return (
    <Condition
      shouldShow={
        state?.stage ===
        UpgradeAccountsCompleteStage.UpgradeAccountsCompleteBuildBusinessStage
      }
    >
      <UpgradeAccountsCompleteBuildBusinessStageContent />
    </Condition>
  )
}
