import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const DashboardSection = styled.div<{ hasBorder?: boolean }>`
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: ${BREAKPOINT.XL}px) {
    ${({ hasBorder }) =>
      hasBorder &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_60};
        padding-bottom: 2rem;
      `}
  }
`

export const StyledOverviewContainer = styled.div`
  margin: 0;
  margin-bottom: 3rem;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-bottom: 3rem;
  }
`
