import { useEffect } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { CustomerRole, KycStatus } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { REVIEW_ALLOWED_ROUTES } from 'const/kyc'
import { ADMIN_KYC_ROUTES, ROOT } from 'const/routes'

export const useRedirectToKyc = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const shortEntityId = useSelectedShortEntityUuid()

  const { pathname } = location
  const { userData } = useLoggedInUser()

  useEffect(() => {
    const isInReviewAllowedRoutes = REVIEW_ALLOWED_ROUTES.some((routeKey) =>
      Boolean(matchPath(`${routeKey}/*`, pathname))
    )
    if (
      !isInReviewAllowedRoutes &&
      userData?.kycStatus === KycStatus.IN_REVIEW &&
      !userData?.registeringIndividual &&
      userData?.role === CustomerRole.ADMIN
    ) {
      navigate(ROOT.ORGS.ORG(shortEntityId).KYC.REVIEW_DOCUMENT_UPLOAD.path)
      return
    }

    if (
      userData?.kycStatus === KycStatus.RC_REJECTED &&
      !userData?.registeringIndividual
    ) {
      navigate(ROOT.ORGS.ORG(shortEntityId).KYC.REJECTED.path)
      return
    }

    if (
      !Object.values(ADMIN_KYC_ROUTES(shortEntityId)).includes(pathname) &&
      !userData?.registeringIndividual &&
      userData?.phoneVerified &&
      userData?.kycStatus === KycStatus.REQUIRED
    ) {
      navigate(ROOT.ORGS.ORG(shortEntityId).KYC.EXISTING_USER.path)
    }
  }, [
    pathname,
    navigate,
    userData?.kycStatus,
    userData?.registeringIndividual,
    userData?.phoneVerified,
    userData?.role,
    shortEntityId,
  ])
}
