import { FeeRateSettings } from '@npco/mp-gql-types'

import { getPercentageValue } from 'utils/numbers'
import { GetSitesForPricing_getSites_sites as SiteData } from 'types/gql-types/GetSitesForPricing'

const feesAndPricingEqual = (site: SiteData, siteToCompare: SiteData) => {
  return (
    site.surchargesTaxes?.feePercent ===
      siteToCompare?.surchargesTaxes?.feePercent &&
    site.surchargesTaxes?.feePercentMoto ===
      siteToCompare?.surchargesTaxes?.feePercentMoto
  )
}

export const getSitesWithFeesAndPricing = (
  sites: SiteData[],
  entityFees: FeeRateSettings
): SiteData[] => {
  return sites.map((site) => {
    return {
      ...site,
      surchargesTaxes: {
        ...site.surchargesTaxes,
        feePercent: site.surchargesTaxes?.feePercent || entityFees.feePercent,
        feePercentMoto:
          site.surchargesTaxes?.feePercentMoto || entityFees.feePercentMoto,
      },
    } as SiteData
  })
}

export const getSitesWithUniquePayments = (sites: SiteData[]) => {
  return sites
    .filter(
      (site) =>
        site.surchargesTaxes?.feePercent || site.surchargesTaxes?.feePercentMoto
    )
    .reduce<SiteData[]>((prev, current) => {
      return prev.findIndex((site) => feesAndPricingEqual(site, current)) === -1
        ? [...prev, current]
        : prev
    }, [])
}

export const getSitesWithUniqueId = (
  sitesFromMemory: SiteData[],
  sitesFromApi: (SiteData | null)[]
) => {
  return sitesFromApi.reduce(
    (prev, siteFromApi) => {
      return siteFromApi &&
        prev.findIndex((site) => site.id === siteFromApi?.id) === -1
        ? [...prev, siteFromApi]
        : prev
    },
    [...sitesFromMemory]
  )
}

export const getPaymentValue = (payment: number | null | undefined) => {
  if (payment === null || payment === undefined) {
    return ''
  }
  return `${getPercentageValue(payment)}%`
}

type FeeType = number | null | undefined
export const getTotalFee = ({
  feeFixed,
  feePercent,
}: {
  feeFixed: FeeType
  feePercent: FeeType
}): string => {
  const feePercentFormatted = getPaymentValue(feePercent)
  const feeFixedFormatted = feeFixed && `${feeFixed}c`
  return [feePercentFormatted, feeFixedFormatted].filter(Boolean).join(' + ')
}
