import { useMemo } from 'react'
import { ActiveConnection } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'

import { LoadingSkeletonRow } from 'pages/Devices/LoadingSkeletonRow/LoadingSkeletonRow'

import { GetDeviceInformationQueryResponse } from '../../graphql/getDeviceInformation.generated'
import { translations } from '../NetworkDetails.i18n'

type InactiveNetworkDetailsProps = {
  isLoading: boolean
  networkDetails?: GetDeviceInformationQueryResponse['getDeviceInformation']['network']
}
export const InactiveNetworkDetails = ({
  isLoading,
  networkDetails,
}: InactiveNetworkDetailsProps) => {
  const t = useTranslations(translations)
  const inactiveNetworkLabels = useMemo(() => {
    switch (networkDetails?.activeConnection) {
      case ActiveConnection.CELLULAR:
        return [t('wifi'), t('ethernet')]
      case ActiveConnection.ETHERNET:
        return [t('wifi'), t('sim')]
      default:
        return [t('sim'), t('ethernet')]
    }
  }, [networkDetails?.activeConnection, t])

  return (
    <Flex flexDirection="column" gap="8px">
      {inactiveNetworkLabels.map((label) => (
        <LoadingSkeletonRow
          key={label}
          data-testid={label}
          isLoading={isLoading}
          label={label}
          value={t('notConnected')}
        />
      ))}
    </Flex>
  )
}
