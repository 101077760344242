import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button } from 'components/Buttons/Button'

export const StyledActionsContainer = styled.div`
  width: 100%;
  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.MD -
    1}px) {
    display: inline-flex;
  }
`

export const StyledAccountPrimaryAction = styled(Button)`
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.MD -
    1}px) {
    margin-top: 1rem;
  }
`
