import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Flex, ListCardButton } from '@npco/zeller-design-system'

import { translations } from './Venue.i18n'
import { Action, VenueContainer } from './Venue.styled'
import { VenueAvatar } from './VenueAvatar'

interface AccountRowProps {
  id: string
  label: string
  mode?: 'EDIT' | 'ASSIGN'
  onClick: (id: string) => void
}

export const Venue = ({
  id,
  label,
  mode = 'ASSIGN',
  onClick,
}: AccountRowProps) => {
  const t = useTranslations(translations)

  const handleClick = useCallback(() => onClick(id), [id, onClick])

  return (
    <VenueContainer>
      <ListCardButton image={VenueAvatar} title={label} onClick={handleClick}>
        <Flex height="44px" alignItems="center">
          <Action>{mode === 'ASSIGN' ? t('assign') : t('edit')}</Action>
        </Flex>
      </ListCardButton>
    </VenueContainer>
  )
}
