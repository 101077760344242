import { gql } from '@apollo/client'

export const CompleteConncetionOAuthFlow = gql`
  mutation CompleteConncetionOAuthFlow(
    $entityUuid: ID!
    $url: String!
    $connectionType: ConnectionType!
  ) {
    completeConnectionOAuthFlow(
      entityUuid: $entityUuid
      connectionType: $connectionType
      url: $url
    ) {
      id
      status
      errorType
      connectionError
    }
  }
`
