import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { getCustomerDisplayName } from 'utils/customers'

import { DebitCardType } from '../../../../../../../hooks/useSetupFlowCardsQuery/graphql/getCards.type'
import { setupFlowDebitCardSensitiveInformationTranslations } from '../SensitiveCardInformation.i18n'
import { CardHolderText } from '../SensitiveCardInformation.styled'
import {
  DebitCardFlipContainer,
  SensitiveContentBack,
  SensitiveContentDesktopContainer,
  SensitiveContentFront,
} from './SensitiveContentDesktopAndTablet.styled'
import { SensitiveContentDesktopAndTabletLoading } from './SensitiveContentDesktopAndTabletLoading'

interface SensitiveContentDesktopAndTabletProps {
  isFlipped: boolean
  debitCard?: DebitCardType
  isRevealed: boolean
}

export const SensitiveContentDesktopAndTablet = ({
  isFlipped,
  debitCard,
  isRevealed,
}: SensitiveContentDesktopAndTabletProps) => {
  const t = useTranslations(setupFlowDebitCardSensitiveInformationTranslations)

  return (
    <DebitCardFlipContainer $isFlipped={isFlipped}>
      <SensitiveContentFront />
      {debitCard && (
        <SensitiveContentBack $isVisible={isFlipped}>
          <SensitiveContentDesktopContainer>
            {!isRevealed && <SensitiveContentDesktopAndTabletLoading />}
            <Box id="mq-card-pan" position="absolute" top="-8px" />
            {isRevealed && (
              <Box position="absolute" top="10px" fontWeight="700">
                {t('expiryLabel')}
              </Box>
            )}
            <Box id="mq-card-exp" position="absolute" top="4px" left="30px" />
            {isRevealed && (
              <Box position="absolute" top="10px" fontWeight="700" left="62px">
                {t('cvcLabel')}
              </Box>
            )}
            <Box id="mq-card-cvv" position="absolute" top="4px" left="84px" />
            {isRevealed && debitCard.customer && (
              <CardHolderText position="absolute" top="22px" width="auto">
                {getCustomerDisplayName(debitCard.customer)}
              </CardHolderText>
            )}
          </SensitiveContentDesktopContainer>
        </SensitiveContentBack>
      )}
    </DebitCardFlipContainer>
  )
}
