import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type StatusOverlayDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
}

export const StatusOverlayDebitCardV2FragmentDoc = gql`
  fragment StatusOverlayDebitCardV2Fragment on DebitCardV2 {
    status
  }
` as unknown as TypedDocumentNode<StatusOverlayDebitCardV2Fragment, undefined>
