import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { InvoiceCompanyLogoUpload } from 'components/InvoiceCompanyLogoUpload/InvoiceCompanyLogoUpload'

import { CompanyLogoLayout } from './CompanyLogoLayout/CompanyLogoLayout'
import { LOGO, LOGO_FILE } from './SettingsGeneral.constants'
import { InvoiceSettingsGeneralFormFields } from './SettingsGeneral.types'

export const SettingsGeneralCompanyLogo = () => {
  const { setValues, values } =
    useFormikContext<InvoiceSettingsGeneralFormFields>()

  const imageUrl = values[LOGO]

  const handleOnImageAdded = useCallback(
    (url: string, file: File) => {
      setValues({
        ...values,
        [LOGO]: url,
        [LOGO_FILE]: file,
      })
    },
    [setValues, values]
  )

  const handleOnImageRemoved = useCallback(() => {
    setValues({
      ...values,
      [LOGO]: undefined,
      [LOGO_FILE]: undefined,
    })
  }, [setValues, values])

  return (
    <InvoiceCompanyLogoUpload
      CompanyLogoLayout={CompanyLogoLayout}
      imageUrl={imageUrl}
      onImageAdded={handleOnImageAdded}
      onImageRemoved={handleOnImageRemoved}
    />
  )
}
