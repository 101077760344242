import styled from 'styled-components'

export const SummaryRow = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`

export const Label = styled.h3`
  ${({ theme }) => theme.typography['heading-md']};
  margin: 0;
`

export const Value = styled.span`
  ${({ theme }) => theme.typography['body-base']};
  text-align: right;
  word-break: break-word;
`
