import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import { useExtendedColumnConfig } from '@npco/ui-table'
import {
  Ellipsize,
  TableCellBasic,
  TableCellSize,
  TableCellSkeleton,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import {
  AverageCellCashFlowCategoryNetAmountFragment,
  AverageCellCashFlowContactNetAmountFragment,
  AverageCellCashFlowSubcategoryNetAmountFragment,
} from './AverageCell.generated'

type AverageCellFragment =
  | AverageCellCashFlowCategoryNetAmountFragment
  | AverageCellCashFlowContactNetAmountFragment
  | AverageCellCashFlowSubcategoryNetAmountFragment

export const AverageCell = <T,>({
  cell,
  table,
  row,
  getValue,
  column,
}: CellContext<T, AverageCellFragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<AverageCellFragment>()

  const average = useMemo(() => {
    const isExpense = table.options.meta?.transactionDirection === 'expense'
    const value = parseInt(data?.average.value ?? '0', 10)
    return isExpense ? -value : value
  }, [data?.average.value, table.options.meta])

  return (
    <TableCellBasic
      key={cell.id}
      dataTestId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      justifyContent="end"
    >
      {isLoading && (
        <TableCellSkeleton
          width={skeletonLoaderWidth}
          size={TableCellSize.SMALL}
        />
      )}

      {!isLoading && <Ellipsize>{currencyFormatter(average)}</Ellipsize>}
    </TableCellBasic>
  )
}

AverageCell.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment AverageCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      average {
        currency
        value
      }
    }
  `,

  CashFlowContactNetAmount: gql`
    fragment AverageCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      average {
        currency
        value
      }
    }
  `,

  CashFlowSubcategoryNetAmount: gql`
    fragment AverageCellCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      average {
        currency
        value
      }
    }
  `,
}
