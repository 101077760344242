import { useTranslations } from '@npco/utils-translations'
import { Tabs } from '@npco/zeller-design-system'

import { contactDrawerTabsTranslations } from './ContactDrawerTabs.i18n'
import { ContactDrawerTab } from './hooks/useContactDrawerTabs/useContactDrawerTabs.types'

type ContactDrawerTabsProps = {
  currentTab: ContactDrawerTab
  setCurrentTab: (tab: ContactDrawerTab) => void
}

export const ContactDrawerTabs = ({
  currentTab,
  setCurrentTab,
}: ContactDrawerTabsProps) => {
  const t = useTranslations(contactDrawerTabsTranslations)

  return (
    <Tabs currentTab={currentTab}>
      <Tabs.Item value="Details" onClick={() => setCurrentTab('Details')}>
        {t('details')}
      </Tabs.Item>
      <Tabs.Item
        value="Transactions"
        onClick={() => setCurrentTab('Transactions')}
      >
        {t('transactions')}
      </Tabs.Item>
    </Tabs>
  )
}
