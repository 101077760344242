import { useMutation } from '@apollo/client'
import { RemoveSiteLogo } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'

import { RemoveSiteLogoVariables } from 'types/gql-types/RemoveSiteLogo'

export const useRemoveSiteLogo = () => {
  const [removeSiteLogo] = useMutation<
    { removeSiteLogo: boolean },
    RemoveSiteLogoVariables
  >(RemoveSiteLogo)

  return { removeSiteLogo }
}
