import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../hooks/useCardStatus/useCardStatus.generated'

export type CardTransactionsMissingDetailsCountCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  outstandingTransactions: number | null
  status: Types.DebitCardStatus
}

export const CardTransactionsMissingDetailsCountCellDebitCardV2FragmentDoc =
  gql`
    fragment CardTransactionsMissingDetailsCountCellDebitCardV2Fragment on DebitCardV2 {
      outstandingTransactions
      ...UseCardStatusDebitCardV2Fragment
    }
    ${UseCardStatusDebitCardV2FragmentDoc}
  ` as unknown as TypedDocumentNode<
    CardTransactionsMissingDetailsCountCellDebitCardV2Fragment,
    undefined
  >
