import { gql } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  NumberBadge,
  NumberBadgeVariant,
  TooltipButton,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { StyledAccountLabel } from '../ListItemAccounts.styled'
import { AccountNameAndInterestCellDebitCardAccountV2Fragment } from './AccountNameAndInterestCell.generated'
import { accountNameAndInterestCellTranslations } from './AccountNameAndInterestCell.i18n'
import * as styled from './AccountNameAndInterestCell.styled'

type AccountNameAndInterestCellProps = {
  account: AccountNameAndInterestCellDebitCardAccountV2Fragment
}

export const AccountNameAndInterestCell = ({
  account,
}: AccountNameAndInterestCellProps) => {
  const isSavingsAccount = account.type === DebitCardAccountType.SAVINGS

  const isMobile = useIsMobileResolution()
  const t = useTranslations(accountNameAndInterestCellTranslations)

  const pillText = t('interest', {
    interest: account.savingsAccountDetails?.effectiveInterestRate ?? '',
  })

  const isNotClosed = account.status !== DebitCardAccountStatus.CLOSED
  const shouldShowPill =
    isSavingsAccount &&
    isNotClosed &&
    !!account.savingsAccountDetails?.effectiveInterestRate &&
    !isMobile

  return (
    <StyledAccountLabel $maxWidth="390px">
      <Flex>
        <StyledAccountLabel $maxWidth="250px">
          {account?.name}
        </StyledAccountLabel>

        {shouldShowPill && (
          <styled.InterestPillWrapper>
            <TooltipButton
              tooltipIcon={
                <NumberBadge
                  text={pillText}
                  variant={NumberBadgeVariant.Default}
                />
              }
              placement="top"
              showArrow={false}
            >
              <Box maxWidth="188px" textAlign="center">
                {t('tooltip')}
              </Box>
            </TooltipButton>
          </styled.InterestPillWrapper>
        )}
      </Flex>
    </StyledAccountLabel>
  )
}

AccountNameAndInterestCell.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountNameAndInterestCellDebitCardAccountV2Fragment on DebitCardAccountV2 {
      name
      type
      savingsAccountDetails {
        effectiveInterestRate
      }
      status
    }
  `,
}
