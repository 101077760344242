import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
} from '@npco/zeller-design-system'

import { accountStatementWarningTranslations } from './AccountStatementsWarning.i18n'

export const AccountStatementsWarning = () => {
  const t = useTranslations(accountStatementWarningTranslations)

  return (
    <Box mb="4px">
      <InfoBox priority={INFOBOX_PRIORITY.MEDIUM} size={INFOBOX_SIZE.SMALL}>
        <InfoBox.Message>{t('message')}</InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
