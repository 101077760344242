import { gql } from '@apollo/client'

export const ConfigureXeroPaymentServices = gql`
  mutation ConfigureXeroPaymentServices(
    $entityUuid: ID!
    $config: XeroPaymentServicesConfigInput!
  ) {
    configureXeroPaymentServices(entityUuid: $entityUuid, config: $config) {
      connection {
        connectionError
        errorType
        id
        status
      }
      enabledThemes {
        xeroThemeId
      }
    }
  }
`
