import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { PositionWrapper } from '../MainContent.styled'

export const AccountDetailsErrorCard = styled(PositionWrapper)`
  background-color: ${({ theme }) => theme.colors.GREY_20};
  align-items: center;
  padding: 10px 0 18px 0;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    width: unset;
  }
`
