import australiaStates from 'const/australia-states.json'
import countryCodes from 'const/country-codes.json'

export const convertAusStateCodeToName = (stateCode?: string | null) => {
  const isAlreadyAusStateName = australiaStates.find(
    (state) => state.name === stateCode
  )

  if (isAlreadyAusStateName) {
    return stateCode
  }

  return stateCode
    ? australiaStates.find((state) => state.value === stateCode)?.name
    : undefined
}

export const convertAusStateNameToCode = (stateName?: string | null) => {
  const isAlreadyAusStateCode = australiaStates.find(
    (state) => state.value === stateName
  )

  if (isAlreadyAusStateCode) {
    return stateName
  }

  return stateName
    ? australiaStates.find((state) => state.name === stateName)?.value
    : undefined
}

export const convertCountryCodeToName = (countryCode?: string | null) => {
  const defaultValue = '-'
  const isAlreadyCountryName = countryCodes.find(
    (country) => country.name === countryCode
  )

  if (isAlreadyCountryName) {
    return countryCode
  }

  if (countryCode) {
    return (
      countryCodes.find((country) => country.alpha3Code === countryCode)
        ?.name || defaultValue
    )
  }

  return defaultValue
}

export const covertToHiddenPhoneNumber = (phone: string) => {
  return `${phone.substring(0, 3)}••••${phone.substring(9)}`
}

export function parseNullableValue<T>(value: T | null | undefined) {
  return value ?? undefined
}
