import styled, { keyframes } from 'styled-components'

const loadingAnimation = keyframes`
  from {
    transform: scaleY(0)
  }
  to {
    transform: scaleY(1)
  }
`

export const barCellClassName = 'bar-cell'
export const barCellAnimationDelay = 100

/*
HACK: As recharts doesn't allow wrapping any of its components in other components
      we cannot create a styled <Cell /> component, hence we have to use css
      nesting.
*/

export const StyledChartWrapper = styled.div`
  &.isLoading .${barCellClassName} {
    transform: scaleY(0);
    animation: ${loadingAnimation} 500ms forwards;
  }
`
