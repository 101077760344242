import { useCallback } from 'react'
import { InvoiceDiscountConfig as InvoiceDiscountConfigType } from '@npco/mp-gql-types'
import { RadioGroupBasic, RadioListCard } from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_ITEMS_DISCOUNT_CONFIG_FIELD,
  INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD,
  INVOICE_ITEMS_DISCOUNT_PRICE_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceDiscountFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

export const translations = {
  amount: translate(
    'page.invoice.formSections.items.itemsDiscountConfigAmount'
  ),
  percentage: translate(
    'page.invoice.formSections.items.itemsDiscountConfigPercentage'
  ),
}

export const InvoiceItemDiscountConfig = () => {
  const { errors, values, setFieldValue } =
    useFormikContext<InvoiceDiscountFormFields>()

  const handleOnChange = useCallback(
    (config: string) => {
      setFieldValue(INVOICE_ITEMS_DISCOUNT_CONFIG_FIELD, config)

      // NOTE: reset previous config type value if error to prevent user not
      // being able to submit the form
      if (config === InvoiceDiscountConfigType.PERCENTAGE && errors.price) {
        setFieldValue(INVOICE_ITEMS_DISCOUNT_PRICE_FIELD, INVOICE_DEFAULT_PRICE)
      }

      if (config === InvoiceDiscountConfigType.AMOUNT && errors.percentage) {
        setFieldValue(
          INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD,
          INVOICE_DEFAULT_PERCENTAGE
        )
      }
    },
    [errors, setFieldValue]
  )

  return (
    <RadioGroupBasic
      gap="8px"
      isVerticalDisplay
      value={values.config}
      name="type"
      onChange={handleOnChange}
    >
      <RadioListCard
        checked={values.config === InvoiceDiscountConfigType.PERCENTAGE}
        id="discount-item-config-percentage-type"
        name="type"
        title={translations.percentage}
        value={InvoiceDiscountConfigType.PERCENTAGE}
      />
      <RadioListCard
        checked={values.config === InvoiceDiscountConfigType.AMOUNT}
        id="discount-item-config-amount-type"
        name="type"
        title={translations.amount}
        value={InvoiceDiscountConfigType.AMOUNT}
      />
    </RadioGroupBasic>
  )
}
