import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'

import { savingsAccountDetailsTranslations } from '../SavingsAccountDetails.i18n'
import { UseInterestRateDisplayDebitCardAccountV2Fragment } from './useInterestRateDisplay.generated'

type UseInterestRateDisplayProps = {
  account: UseInterestRateDisplayDebitCardAccountV2Fragment
}

export const useInterestRateDisplay = ({
  account,
}: UseInterestRateDisplayProps) => {
  const t = useTranslations(savingsAccountDetailsTranslations)

  const hasBonusInterestRate =
    account.savingsAccountDetails?.bonusInterestRate &&
    Number(account.savingsAccountDetails.bonusInterestRate) !== 0

  const interestRateBaseText = t('interestRateBase', {
    base: account.savingsAccountDetails?.baseInterestRate ?? '0.00',
  })

  const interestRateBonusText = t('interestRateBonus', {
    bonus: account.savingsAccountDetails?.bonusInterestRate ?? '0.00',
  })

  return {
    hasBonusInterestRate,
    interestRateBaseText,
    interestRateBonusText,
  }
}

useInterestRateDisplay.fragments = {
  DebitCardAccountV2: gql`
    fragment UseInterestRateDisplayDebitCardAccountV2Fragment on DebitCardAccountV2 {
      savingsAccountDetails {
        baseInterestRate
        bonusInterestRate
      }
    }
  `,
}
