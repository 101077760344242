import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  Message,
} from '@npco/zeller-design-system'

import { transferErrorPopperTranslations } from './TransferErrorPopper.i18n'

interface TransferErrorPopperProps {
  isRefetchingAccounts: boolean
  refetchAccounts: () => void
}

export const TransferErrorPopper = ({
  isRefetchingAccounts,
  refetchAccounts,
}: TransferErrorPopperProps) => {
  const t = useTranslations(transferErrorPopperTranslations)

  const handleRefetchAccounts = useCallback(() => {
    refetchAccounts()
  }, [refetchAccounts])

  return (
    <Message
      title={t('title')}
      description={t('description')}
      primaryButton={
        <Box
          pr={['0', '8px']}
          pb={['8px', '0']}
          width={{ _: '100%', XS: 'auto' }}
        >
          <ButtonFill
            onClick={handleRefetchAccounts}
            size={BUTTON_SIZE.MEDIUM}
            isLoading={isRefetchingAccounts}
          >
            {t('actionButton')}
          </ButtonFill>
        </Box>
      }
    />
  )
}
