import { useLocationState } from '@npco/utils-routing'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { page } from 'translations'

import { ResendCodeModal } from '../ResendCodeModal'
import { StyledResendButton } from './ValidateCode.styled'
import { ValidateCodeLocationStateSchema } from './ValidateCode.types'

export const ResendCodeModalWrapper = () => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const locationState = useLocationState(ValidateCodeLocationStateSchema)
  const phone = locationState?.phone || ''

  return (
    <>
      <StyledResendButton onClick={openModal}>
        {page.validateCode.resendIt}
      </StyledResendButton>

      <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
        <ResendCodeModal closeModal={closeModal} phone={phone} />
      </ModalBasic>
    </>
  )
}
