import styled from 'styled-components'

export const ScrollArea = styled.div`
  overflow-y: scroll;
  width: 330px;
  margin-top: 0.5rem;
  max-height: 288px;
`

export const LabelSiteName = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 4px;
`
