import { gql } from '@apollo/client'
import { DebitCardColour } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'

import { ReactComponent as BlackDebitCard } from 'assets/svg/cards/debit-card-black-xsmall.svg'
import { ReactComponent as WhiteDebitCard } from 'assets/svg/cards/debit-card-white-xsmall.svg'
import { CloseAccountPromptCardItemDebitCardV2Fragment as DebitCardAccountV2Card } from 'types/gql-types/CloseAccountPromptCardItemDebitCardV2Fragment'

import {
  StyledCardItemWrapper,
  StyledCardName,
  StyledMaskedPan,
} from './CloseAccountPromptCardItem.styled'

interface CloseAccountPromptCardItemProps {
  card: DebitCardAccountV2Card
}

export const CloseAccountPromptCardItem = ({
  card,
}: CloseAccountPromptCardItemProps) => {
  return (
    <Flex
      as="li"
      data-testid="card-row"
      gridGap="12px"
      padding="8px 6px"
      justifyContent="space-between"
    >
      <StyledCardItemWrapper>
        <Flex width={48}>
          {card?.colour === DebitCardColour.BLACK ? (
            <BlackDebitCard />
          ) : (
            <WhiteDebitCard />
          )}
        </Flex>

        <StyledCardName>{card.name}</StyledCardName>
      </StyledCardItemWrapper>

      <StyledMaskedPan>••• {card.maskedPan}</StyledMaskedPan>
    </Flex>
  )
}

CloseAccountPromptCardItem.fragments = {
  DebitCardV2: gql`
    fragment CloseAccountPromptCardItemDebitCardV2Fragment on DebitCardV2 {
      maskedPan
      name
      colour
    }
  `,
}
