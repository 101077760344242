import React from 'react'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ErrorIcon } from 'assets/svg/error-state.svg'
import {
  StyledErrorStateWrapper as Wrapper,
  StyledIconBox,
} from 'pages/Settings/Sim/BillingHistory/ErrorState/ErrorState.styled'
import {
  StyledLargeTitle,
  StyledMediumDescription,
  StyledTextContainer,
} from 'components/Placeholders/SharedElements.styled'
import { page } from 'translations'

export const ErrorState = () => {
  return (
    <Wrapper>
      <StyledIconBox alignItems="center" justifyContent="center">
        <SvgIcon width="22px" height="22px" color={COLOR.RED_1000}>
          <ErrorIcon />
        </SvgIcon>
      </StyledIconBox>
      <StyledTextContainer>
        <StyledLargeTitle>
          {page.settings.sim.billingHistory.errorState.title}
        </StyledLargeTitle>
        <StyledMediumDescription>
          {page.settings.sim.billingHistory.errorState.description1}
        </StyledMediumDescription>
        <StyledMediumDescription>
          {page.settings.sim.billingHistory.errorState.description2}
        </StyledMediumDescription>
      </StyledTextContainer>
    </Wrapper>
  )
}
