import {
  ONBOARDING_FLOW,
  ONBOARDING_FLOW_COMBINED_FORM_PHASE_1,
  ONBOARDING_FLOW_COMBINED_FORM_PHASE_2,
} from 'const/onboarding'

export const getCheckpointFlowConfig = (
  isCombinedForm: boolean,
  isPhase1: boolean,
  isPhase2: boolean
) => {
  if (!isCombinedForm) {
    return ONBOARDING_FLOW
  }
  if (isCombinedForm && isPhase2) {
    return ONBOARDING_FLOW_COMBINED_FORM_PHASE_2
  }
  if (isCombinedForm && isPhase1) {
    return ONBOARDING_FLOW_COMBINED_FORM_PHASE_1
  }
  return ONBOARDING_FLOW
}
