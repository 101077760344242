import { ContactType } from '@npco/mp-gql-types'

import { GetAccountTransactionFiltersContacts_getContacts_contacts as Contact } from 'types/gql-types/GetAccountTransactionFiltersContacts'

export interface ContactListItem {
  label: string
  value: string
}

export const mapContactToSelectItem = (contact: Contact): ContactListItem => {
  if (contact.contactType === ContactType.BUSINESS) {
    return {
      label: contact.businessName ?? '',
      value: contact.id,
    }
  }

  return {
    label: `${contact.firstName} ${contact.lastName}`,
    value: contact.id,
  }
}
