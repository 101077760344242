import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { DebitCardAccount } from 'types/accounts'

export const sortAccounts = (accounts: DebitCardAccount[]) => {
  const activeAccounts = accounts.filter(
    (account) => account.status === DebitCardAccountStatus.ACTIVE
  )
  const suspendedAccounts = accounts.filter(
    (account) => account.status === DebitCardAccountStatus.SUSPENDED
  )

  const closedAccounts = accounts.filter(
    (account) => account.status === DebitCardAccountStatus.CLOSED
  )

  return [...activeAccounts, ...suspendedAccounts, ...closedAccounts]
}
