import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import {
  BillFilterInput,
  SimBillingStatementExportFormat,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { renameAndDownloadFile } from '@npco/utils-file'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { ExportSimBillingStatement } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sim'
import { rvSelectedRates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSelectedSimSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sim'

import dayjs from 'utils/dayjs'
import { mapFiltersInputToSimStatementsApiFilter } from 'utils/mapFiltersInputToApiFilter'
import { DatesRange, StatementPeriod } from 'types/dates'
import {
  ExportSimBillingStatement as ExportSimBillingStatementType,
  ExportSimBillingStatementVariables,
} from 'types/gql-types/ExportSimBillingStatement'
import {
  ALL_SITES,
  getStatementDate,
} from 'components/Statements/Statements.utils'
import { page } from 'translations'

const DATE_FORMAT = 'DD/MM/YYYY'
const isLocalDate = true

export const useFetchSimStatements = () => {
  const entityUuid = useSelectedEntityUuid()
  const selectedSite = useReactiveVar(rvSelectedSimSite)
  const rates = useReactiveVar(rvSelectedRates)
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [datesRange, setDatesRange] = useState<DatesRange>({
    from: undefined,
    to: undefined,
  })

  const siteUuid = useMemo(
    () => (selectedSite === ALL_SITES ? undefined : selectedSite),
    [selectedSite]
  )

  const fileName = useMemo(() => {
    return `${page.settings.statements.simStatement} ${dayjs(
      datesRange.from
    ).format(DATE_FORMAT)} - ${dayjs(datesRange.to).format(DATE_FORMAT)}`
  }, [datesRange])

  const [filters, setFilters] = useState<BillFilterInput>({})

  const updateFilters = (dates: DatesRange) =>
    setFilters(
      mapFiltersInputToSimStatementsApiFilter(
        { dates, rates, siteUuid },
        isLocalDate
      )
    )

  const [getSingleSimStatementData] = useLazyQuery<
    ExportSimBillingStatementType,
    ExportSimBillingStatementVariables
  >(ExportSimBillingStatement, {
    variables: {
      entityUuid,
      filter: filters,
      format: SimBillingStatementExportFormat.PDF,
    },
    onError: (err) => {
      showApiErrorToast(err)
      setIsLoading(false)
    },
    onCompleted: (data) => {
      if (!data?.exportSimBillingStatement?.downloadLink) {
        showApiErrorToast()
        setIsLoading(false)
        return
      }

      renameAndDownloadFile({
        link: data?.exportSimBillingStatement.downloadLink,
        filename: fileName,
        format: SimBillingStatementExportFormat.PDF,
      })
        .then(() => {
          setIsLoading(false)
          setHasLoaded(true)
        })
        .catch(() => {
          setIsLoading(false)
          showApiErrorToast()
        })
    },
  })

  useEffect(() => {
    setHasLoaded(false)
  }, [selectedSite])

  const exportFile = (statementPeriod: StatementPeriod) => {
    setHasLoaded(false)

    const dates = getStatementDate(statementPeriod)
    updateFilters(dates)
    setDatesRange(dates)
    setIsLoading(true)
    getSingleSimStatementData()
  }

  return {
    exportFile,
    isLoadingStatement: isLoading,
    hasLoaded,
  }
}
