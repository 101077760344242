import { ButtonFill, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { StyledTitle } from 'components/Breadcrumbs/Breadcrumbs.styled'

export const Header = styled(Flex)`
  ${StyledTitle} {
    white-space: inherit;
  }
`

export const Button = styled(ButtonFill)`
  height: 48px;
  width: 48px;

  div {
    display: flex;
  }
`
