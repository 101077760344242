import { useMemo } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  TableMeta,
  TableState,
  useReactTable,
} from '@tanstack/react-table'

import { ColumnConfig, TableData } from '../Table.types'

const LOADING_ROW_COUNT_DEFAULT = 8

export interface UseTableProps<T> {
  columnsConfig: ColumnConfig<T>[]
  isLoading: boolean
  listData: T[]
  loadingRowCount?: number
  state?: Partial<TableState>
  meta?: TableMeta<TableData<T>>
}

/**
 * Generic hook to construct table by using react-table.
 * See the usage in DebitCardsTable and AccountCardsTable.
 *
 * @param {ColumnConfig<T>[]} columnsConfig - columns config, including the cell components.
 * @param {boolean} isLoading - loading state.
 * @param {T[]} listData - data that passed to the table.
 * @param {Partial<TableState>} state - table state.
 * @param {number} loadingRowCount - loading row count. Default to 8
 * @returns table: Table<TableData<T>>, which will be used to construct a table.
 */

export const useTable = <T>({
  columnsConfig,
  isLoading,
  listData,
  state,
  loadingRowCount = LOADING_ROW_COUNT_DEFAULT,
  meta = {},
}: UseTableProps<T>) => {
  const columnHelper = createColumnHelper<TableData<T>>()

  const columns = useMemo(() => {
    return columnsConfig.map((config) => ({
      ...columnHelper.accessor('data', {
        id: config.accessorId,
        cell: config.cellComponent,
        header: config.headerComponent,
      }),
      cellSize: config.cellSize,
      cellSkeletonWidth: config.cellSkeletonWidth,
    }))
  }, [columnHelper, columnsConfig])

  const tableData: TableData<T>[] =
    !isLoading && listData
      ? listData.map((item) => {
          return {
            data: item,
          }
        })
      : Array(loadingRowCount)

  const table = useReactTable<TableData<T>>({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    meta: { ...meta, isLoading },
    state,
  })

  return {
    table,
  }
}
