import { createContext } from 'react'

import {
  ContactOrCategory,
  IncomeOrExpense,
} from './CashflowReportingTransactions.types'

type CashflowReportingTransactionsContextType = {
  contactOrCategorySelection: ContactOrCategory
  incomeOrExpenseSelection: IncomeOrExpense
  setContactOrCategorySelection: (newSelection: ContactOrCategory) => void
  setIncomeOrExpenseSelection: (newSelection: IncomeOrExpense) => void
}

export const CashflowReportingTransactionsContext =
  createContext<CashflowReportingTransactionsContextType | null>(null)
