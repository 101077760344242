import { Box, InfoBox, InputAdaptiveField } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { validateNickname } from 'forms/formViews/BankAccountFields/BankAccountFields.utils'

import { StyledHeader } from '../SetCardSettingsAccessibilityOption/SetCardSettingsAccessibilityOption.styled'

export const SetCardSettingsNickname = () => {
  return (
    <Box>
      <Box mb="24px">
        <StyledHeader>
          {translate('page.addCardModal.setCardSettings.nicknameTitle')}
        </StyledHeader>
      </Box>
      <InfoBox>
        <InfoBox.Message>
          {translate('page.addCardModal.setCardSettings.nicknameDescription')}
        </InfoBox.Message>
      </InfoBox>
      <Box mt="24px">
        <InputAdaptiveField
          label={translate('page.addCardModal.setCardSettings.nicknameLabel')}
          name="nickname"
          validate={validateNickname}
        />
      </Box>
    </Box>
  )
}
