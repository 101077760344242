import { Typography } from '@npco/zeller-design-system'
import type * as CSS from 'csstype'
import styled from 'styled-components'

export const StyledTooltipText = styled(Typography).attrs({
  variant: 'body-sm',
})<{
  maxWidth?: CSS.Property.MaxWidth
  textAlign?: CSS.Property.TextAlign
}>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  color: ${({ theme }) => theme.colors.WHITE};
  margin: 0;
`

export const StyledTooltipButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: none;
  text-align: left;
`

export const StyledPopperArrow = styled.div<{ contentLeftPosition?: string }>`
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;

  &::after {
    background-color: ${({ theme }) => theme.colors.BLACK_900};
    content: '';
    height: 0.75rem;
    ${({ contentLeftPosition }) =>
      contentLeftPosition && `left: ${contentLeftPosition}`};
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 0.75rem;
  }
`

export const StyledPopperContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.BLACK_900};
  border-radius: 4px;
  padding: 0.5rem;
  z-index: 10001;
  width: max-content;

  &[data-popper-placement^='top'] > ${StyledPopperArrow} {
    bottom: -3px;
  }

  &[data-popper-placement^='top-end'] > ${StyledPopperArrow} {
    left: -8px !important;
  }

  &[data-popper-placement^='right'] > ${StyledPopperArrow} {
    left: -3px;
  }

  &[data-popper-placement^='bottom'] > ${StyledPopperArrow} {
    top: -3px;
  }

  &[data-popper-placement^='left'] > ${StyledPopperArrow} {
    right: -3px;
  }
`
