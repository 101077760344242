import styled from 'styled-components'

export const ActiveIndicator = styled.div`
  background: ${({ theme }) => theme.colors.RED_1000};
  border: 2px solid ${({ theme }) => theme.colors.WHITE};
  border-radius: 50%;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 10;
  right: 10px;
  top: 6px;
`
