import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { AccessDenied } from './AccessDenied/AccessDenied'
import { reportsAccessDeniedTranslations } from './ReportsAccessDenied.i18n'

export const ReportsAccessDenied = () => {
  const t = useTranslations(reportsAccessDeniedTranslations)

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            height="48px"
            width="100%"
          >
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{t('pageTitle')}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <AccessDenied />
    </PageTemplate>
  )
}
