import { gql, type TypedDocumentNode } from '@apollo/client'

export type LearnMoreButtonEntityFragment = {
  __typename?: 'Entity'
  canCreateAccount: boolean | null
  savingsAccountProduct: {
    __typename?: 'EntitySavingsAccountProduct'
    effectiveInterestRate: string | null
  } | null
}

export const LearnMoreButtonEntityFragmentDoc = gql`
  fragment LearnMoreButtonEntityFragment on Entity {
    savingsAccountProduct {
      effectiveInterestRate
    }
    canCreateAccount
  }
` as unknown as TypedDocumentNode<LearnMoreButtonEntityFragment, undefined>
