import { PropertyStringPath } from 'property-string-path'

import translations from './en-au.json'
import termsAndConditions from './terms-and-conditions.en-au.json'

// NOTE: webpack 5 demands that default exports are used from JSON files so
// export top level properties for easier migration
// https://webpack.js.org/blog/2020-10-10-webpack-5-release/#json-modules

export {
  translations as translationsShared,
  type TShared,
  type TSharedKey,
} from './shared.i18n'

const {
  component,
  errorMessages,
  form,
  notifications,
  page,
  pdf,
  shared,
  successMessages,
  transactionsErrors,
} = translations

const { general, mobileDataServices, standardForm, summary } =
  termsAndConditions

export {
  component,
  errorMessages,
  form,
  general,
  mobileDataServices,
  notifications,
  page,
  pdf,
  shared,
  standardForm,
  successMessages,
  summary,
  transactionsErrors,
}

export type TranslatePath = PropertyStringPath<typeof translations>

export default translations
