import {
  StyledCancelButtonStyled,
  StyledConfirmButtonStyled,
  StyledDescription,
  StyledHeader,
} from './ModalElements.styled'
import { ModalButtonProps, ModalElementProps } from './ModalElements.types'

export const ModalHeader: React.FC<ModalElementProps> = ({ children }) => (
  <StyledHeader>{children}</StyledHeader>
)

export const ModalDescription: React.FC<ModalElementProps> = ({ children }) => (
  <StyledDescription>{children}</StyledDescription>
)

export const CancelButton: React.FC<ModalButtonProps> = ({
  children,
  onClick,
  disabled,
  ...props
}) => (
  <StyledCancelButtonStyled
    {...props}
    fullWidth
    onClick={onClick}
    disabled={disabled}
    data-testid="cancel-button"
  >
    {children}
  </StyledCancelButtonStyled>
)

export const ConfirmButton: React.FC<ModalButtonProps> = ({
  children,
  disabled,
  onClick,
  isLoading = false,
  ...props
}) => (
  <StyledConfirmButtonStyled
    data-testid="modal-confirm-btn"
    {...props}
    fullWidth
    disabled={disabled}
    onClick={onClick}
    isLoading={isLoading}
  >
    {children}
  </StyledConfirmButtonStyled>
)
