import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    justify-content: flex-start;
  }
`

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${({ theme }) => theme.colors.GREY_30};
  border-radius: 8px;
  gap: 40px 0;
  text-align: center;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    padding: 48px;
    gap: 0 40px;
    text-align: left;
  }
`

export const PanelImage = styled.img`
  max-height: 200px;
`

export const SideImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

export const SideImage = styled.img`
  width: 100%;
  object-position: center;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    height: 100%;
    position: absolute;
    object-fit: contain;
  }
`
