import { forwardRef, KeyboardEvent, useCallback } from 'react'
import { gql } from '@apollo/client'
import { useModalState } from '@npco/zeller-design-system'

import { DocumentNode } from 'graphql'
import { KEY_CODE } from 'const/keyboard'
import { TransactionListRowBaseProps } from 'pages/DebitCardTransactions/DebitCardTransactionsList/DebitCardTransactionsList.types'
import { ImagePreviewModal } from 'components/ImagePreviewModal/ImagePreviewModal'

import { AmountCell } from '../AmountCell/AmountCell'
import { BadgeAndNameCell } from '../BadgeAndNameCell/BadgeAndNameCell'
import { CategoryCell } from '../CategoryCell/CategoryCell'
import { ReceiptAndNoteCell } from '../ReceiptAndNoteCell/ReceiptAndNoteCell'
import * as styled from './CorporateCardTransactionRow.styled'

type CorporateCardTransactionRowProps = TransactionListRowBaseProps & {
  isCategoryIconHighlighted: boolean
  isNoteIconHighlighted: boolean
  isReceiptIconHighlighted: boolean
}

export const CorporateCardTransactionRow = forwardRef<
  HTMLLIElement,
  CorporateCardTransactionRowProps
>(
  (
    {
      transaction,
      showDetails,
      isNew,
      isCategoryIconHighlighted,
      isNoteIconHighlighted,
      isReceiptIconHighlighted,
    },
    ref
  ) => {
    const handleEnterKey = useCallback(
      (event: KeyboardEvent<HTMLLIElement>) => {
        if (event.key === KEY_CODE.ENTER) {
          showDetails(transaction)
        }
      },
      [transaction, showDetails]
    )
    const {
      isModalOpen: isImagePreviewOpen,
      closeModal: closeImagePreview,
      openModal: openImagePreview,
    } = useModalState()

    return (
      <>
        <styled.Container
          data-testid="corporate-card-transaction-row"
          onClick={() => showDetails(transaction)}
          onKeyUp={handleEnterKey}
          tabIndex={0}
          $isNew={isNew}
          ref={ref}
        >
          <BadgeAndNameCell
            transaction={transaction}
            hideAvatarOnMobile={false}
          />
          <CategoryCell
            transaction={transaction}
            isEmptyCategoryHighlighted={isCategoryIconHighlighted}
          />
          <ReceiptAndNoteCell
            transaction={transaction}
            isEmptyReceiptHighlighted={isReceiptIconHighlighted}
            isEmptyNoteHighlighted={isNoteIconHighlighted}
            openImagePreview={openImagePreview}
          />
          <AmountCell transaction={transaction} />
        </styled.Container>
        {isImagePreviewOpen && (
          <ImagePreviewModal
            transactionUuid={transaction.id}
            initialImageId="first-image"
            onClose={closeImagePreview}
          />
        )}
      </>
    )
  }
) as React.ForwardRefExoticComponent<
  CorporateCardTransactionRowProps & React.RefAttributes<HTMLLIElement>
> & {
  fragments:
    | { DebitCardTransactionV2: DocumentNode }
    | { DebitCardAccountTransaction: DocumentNode }
}

CorporateCardTransactionRow.fragments = {
  DebitCardTransactionV2: gql`
    fragment CorporateCardTransactionRowDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...BadgeAndNameCellDebitCardTransactionV2Fragment
      ...CategoryCellDebitCardTransactionV2Fragment
      ...ReceiptAndNoteCellDebitCardTransactionV2Fragment
      ...AmountCellDebitCardTransactionV2Fragment
    }

    ${BadgeAndNameCell.fragments.DebitCardTransactionV2}
    ${AmountCell.fragments.DebitCardTransactionV2}
    ${CategoryCell.fragments.DebitCardTransactionV2}
    ${ReceiptAndNoteCell.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment CorporateCardTransactionRowDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...BadgeAndNameCellDebitCardAccountTransactionFragment
      ...CategoryCellDebitCardAccountTransactionFragment
      ...ReceiptAndNoteCellDebitCardAccountTransactionFragment
      ...AmountCellDebitCardAccountTransactionFragment
    }

    ${BadgeAndNameCell.fragments.DebitCardAccountTransaction}
    ${AmountCell.fragments.DebitCardAccountTransaction}
    ${CategoryCell.fragments.DebitCardAccountTransaction}
    ${ReceiptAndNoteCell.fragments.DebitCardAccountTransaction}
  `,
}
