import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Info } from 'assets/svg/info.svg'
import { ReactComponent as Lock } from 'assets/svg/padlock.svg'

import {
  StyledDisabledAccountInfo,
  StyledInfoDetails,
  StyledInfoMessage,
} from './DisabledAccountInfo.styled'

interface DisabledAccountInfoProps {
  isSelectedAccount: boolean
  message: string
  description: string
}

export const DisabledAccountInfo = ({
  isSelectedAccount,
  message,
  description,
}: DisabledAccountInfoProps) => {
  const infoIcon = (
    <SvgIcon height="16" width="16" color={COLOR.RED_1000}>
      <Info />
    </SvgIcon>
  )

  const lockIcon = (
    <SvgIcon height="16" width="16">
      <Lock />
    </SvgIcon>
  )
  return (
    <StyledDisabledAccountInfo data-testid="disabledAccountInfo">
      <Flex>
        <Box mr="1rem" mt="2px">
          {isSelectedAccount ? infoIcon : lockIcon}
        </Box>
        <Flex flexDirection="column">
          <StyledInfoMessage>{message}</StyledInfoMessage>
          <StyledInfoDetails>{description}</StyledInfoDetails>
        </Flex>
      </Flex>
    </StyledDisabledAccountInfo>
  )
}
