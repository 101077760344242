import { useMemo } from 'react'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useHasActiveSavingsAccountQuery as useHasActiveSavingsAccountQueryApollo } from './graphql/HasActiveSavingsAccountQuery.generated'

export const useHasActiveSavingsAccountQuery = () => {
  const entityUuid = useSelectedEntityUuid()

  const { data, loading } = useHasActiveSavingsAccountQueryApollo({
    variables: { entityUuid },
  })

  const hasActiveSavingsAccount = useMemo(
    () =>
      data?.getSavingsAccounts.some(
        (account) => account?.status === DebitCardAccountStatus.ACTIVE
      ) ?? true,
    [data]
  )

  return {
    hasActiveSavingsAccount,
    isLoadingHasActiveSavingsAccount: loading,
  }
}
