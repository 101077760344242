import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { object, string } from 'yup'

import { billerCodeSearchInputTranslations } from 'pages/Transfer/BPay/AddBpayDetailsModal/components/BillerCodeSearchInput/BillerCodeSearchInput.i18n'

export const useAddBpayDetailsFormValidationSchema = () => {
  const t = useTranslations(billerCodeSearchInputTranslations)
  return useMemo(
    () =>
      object({
        billerCode: string().required(t('errorRequired')),
        billerName: string().required(t('errorRequired')),
      }),
    [t]
  )
}
