import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { GetImageUrlIconFragmentDoc } from '@npco/mp-utils-icon'

export type UseIconAvatarIconFragment = {
  __typename?: 'Icon'
  colour: string | null
  letter: string | null
  animation: string | null
  image: string | null
  images: Array<{
    __typename?: 'Image'
    url: string
    size: Types.ImageSize
  }> | null
}

export const UseIconAvatarIconFragmentDoc = gql`
  fragment UseIconAvatarIconFragment on Icon {
    colour
    letter
    animation
    ...GetImageUrlIconFragment
  }
  ${GetImageUrlIconFragmentDoc}
` as unknown as TypedDocumentNode<UseIconAvatarIconFragment, undefined>
