import { Flex } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { Document, Page } from 'components/PDF'

const convertToPx = (pixels: number) => `${pixels}px`

export const StyledPdf = styled(Document)<{ width: number; height: number }>`
  width: ${({ width }) => convertToPx(width)};
  height: ${({ height }) => convertToPx(height)};
`

export const StyledPage = styled(Page)`
  text-align: -webkit-center;
  height: inherit;
  width: inherit;
`

export const StyledFlex = styled(Flex)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.GREY_60};
`

export const StyledImage = styled.img<{
  width: number
  height: number
  objectFit: 'cover' | 'contain' | 'scale-down'
  $showAppearAnimation?: boolean
}>`
  width: ${({ width }) => convertToPx(width)};
  height: ${({ height }) => convertToPx(height)};
  object-fit: ${({ objectFit }) => objectFit};

  ${({ $showAppearAnimation }) =>
    $showAppearAnimation &&
    css`
      animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
    `}
`
