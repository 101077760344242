import { AnchorBasic, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SidebarTitle = styled.h1`
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  letter-spacing: -0.02em;
  font-weight: 600;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    font-size: 32px;
  }
`

export const SidebarSubtitle = styled.h2`
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  text-align: left;
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    text-align: center;
  }
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    text-align: left;
  }
`

export const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none;

  background-color: ${({ theme }) => theme.colors.GREY_60};

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    overflow-y: hidden;
    width: 528px;
    height: 100vh;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    width: 530px;
  }

  @media screen and (min-width: ${BREAKPOINT.XXL}px) {
    width: 580px;
  }
`
export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 32px 40px 32px;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin: 104px 76px 24px 76px;
  }

  @media screen and (min-width: ${BREAKPOINT.XL}px) {
    margin: 160px 76px 24px 76px;
  }
`

export const Underline = styled(AnchorBasic)`
  text-decoration: underline;
`

export const ImageWrapper = styled(Flex)`
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 24px 0 24px 0;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin: auto 0 0 0;
  }
  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    min-height: 400px;
  }

  @media screen and (min-width: ${BREAKPOINT.XL}px) {
    width: 528px;
  }

  @media screen and (min-width: ${BREAKPOINT.XXL}px) {
    width: 100%;
  }
`
