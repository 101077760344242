import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { PopperItemsDebitCardAccountV2FragmentDoc } from './PopperItems/PopperItems.generated'

export type AccountSelectDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  id: string
  name: string
  type: Types.DebitCardAccountType
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    animation: string | null
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export const AccountSelectDebitCardAccountV2FragmentDoc = gql`
  fragment AccountSelectDebitCardAccountV2Fragment on DebitCardAccountV2 {
    ...PopperItemsDebitCardAccountV2Fragment
  }
  ${PopperItemsDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  AccountSelectDebitCardAccountV2Fragment,
  undefined
>
