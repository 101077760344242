import { useCallback } from 'react'
import TextTruncate from 'react-text-truncate'
import { ListCardButton } from '@npco/zeller-design-system'

import { getNonNullString } from 'utils/string'
import { DeviceModel } from 'types/devices'
import { GetSite_getSite_devices as Device } from 'types/gql-types/GetSite'
import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'

import { DeviceAvatar } from './DeviceAvatar'
import * as styled from './DeviceCard.styled'

interface Props {
  onClick?: () => void
  device: Device | DeviceWithSiteInfo
}

export const DeviceCard = ({ onClick, device }: Props) => {
  const getDeviceAvatar = useCallback(() => {
    return <DeviceAvatar deviceModel={device.model as DeviceModel} />
  }, [device.model])

  return (
    <styled.Container onClick={onClick} data-testid="terminal-card">
      <ListCardButton
        title={<TextTruncate line={2} text={getNonNullString(device.name)} />}
        image={getDeviceAvatar}
      />
    </styled.Container>
  )
}
