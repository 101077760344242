import { Box, COLOR, Flex } from '@npco/zeller-design-system'

interface SkeletonProp {
  previewWidth: number
  previewHeight: number
}

export const Skeleton = ({ previewWidth, previewHeight }: SkeletonProp) => {
  return (
    <Flex
      width={previewWidth}
      height={previewHeight}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        height="500px"
        width="375px"
        backgroundColor={COLOR.GREY_800}
        data-testid="image-preview-skeleton"
      />
    </Flex>
  )
}
