import {
  rvStepUpAuthDepositsObject,
  rvStepUpAuthProfileSettingsObject,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/auth'

import {
  AuthScope,
  DepositsApiCallTypeEnum,
  DepositsAppState,
  ProfileSettingsApiCallTypeEnum,
  ProfileSettingsAppState,
} from 'types/auth'
import { AddThirdPartyBankAccountVariables } from 'types/gql-types/AddThirdPartyBankAccount'
import { RequestEmailChangeVariables } from 'types/gql-types/RequestEmailChange'
import { SelectDepositAccountVariables } from 'types/gql-types/SelectDepositAccount'

import { IdentityPhoneRegisterVariables } from '../types/gql-types/IdentityPhoneRegister'

export const useAppStateMapper = () => {
  const mapDepositStateToAuthObject = (
    apiCallType: DepositsApiCallTypeEnum,
    variables:
      | AddThirdPartyBankAccountVariables
      | SelectDepositAccountVariables,
    name: string,
    isNewAccount?: boolean
  ): DepositsAppState => {
    return {
      shouldCallApiAgain: true,
      scope: AuthScope.SENSITIVE,
      name,
      apiCallType,
      variables,
      isNewAccount,
    } as DepositsAppState
  }

  const mapAuthObjectToDepositState = (data: DepositsAppState) => {
    // should differentiate what kind of AppState it is for future use cases. for now it just returns RV for deposits
    rvStepUpAuthDepositsObject(data)
  }

  const mapProfileSettingsStateToAuthObject = (
    apiCallType: ProfileSettingsApiCallTypeEnum,
    variables: RequestEmailChangeVariables | IdentityPhoneRegisterVariables,
    name: string
  ): ProfileSettingsAppState => {
    return {
      shouldCallApiAgain: true,
      scope: AuthScope.SENSITIVE,
      apiCallType,
      variables,
      name,
    } as ProfileSettingsAppState
  }

  const mapAuthObjectToProfileSettingsState = (
    data: ProfileSettingsAppState
  ) => {
    // should differentiate what kind of AppState it is for future use cases. for now it just returns RV for change email
    rvStepUpAuthProfileSettingsObject(data)
  }
  return {
    mapDepositStateToAuthObject,
    mapAuthObjectToDepositState,
    mapProfileSettingsStateToAuthObject,
    mapAuthObjectToProfileSettingsState,
  }
}
