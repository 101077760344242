import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { MY_ZELLER_URL } from 'const/externalLinks'
import { ROOT } from 'const/routes'
import { AuthLayout } from 'layouts'
import { LogoCornerLinkExternal } from 'components/LogoCornerLinkExternal/LogoCornerLinkExternal'
import { shared } from 'translations'

import { useQueryParams } from '../../hooks/useQueryParams'
import {
  useUtmSourceAndMedium,
  UTM_MEDIUM,
  UTM_SOURCE,
} from '../../services/Analytics/useUtmSourceAndMedium'
import { useCheckGeoLocation } from './hooks/useCheckGeoLocation'
import { useUpdateSardineConfig } from './hooks/useUpdateSardineConfig'
import {
  StyledSignUpDesktopWrapper,
  StyledSignUpMobileWrapper,
} from './SignUp.styled'
import { SignUpDesktop } from './SignUpDesktop/SignUpDesktop'
import { SignUpMobile } from './SignUpMobile/SignUpMobile'

export const SignUp: React.FC = () => {
  const navigate = useNavigate()
  const params = useQueryParams()

  const { isLoading } = useCheckGeoLocation({
    onNotSupported: () => navigate(ROOT.JOIN_WAITLIST.path),
  })

  const { setUtmSourceAndMedium } = useUtmSourceAndMedium()

  useEffect(() => {
    setUtmSourceAndMedium(params.get(UTM_SOURCE), params.get(UTM_MEDIUM))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useUpdateSardineConfig()
  return (
    <AuthLayout
      isLoading={isLoading}
      logo={
        <LogoCornerLinkExternal
          href={MY_ZELLER_URL}
          aria-label={shared.goToMyZeller}
        />
      }
    >
      <StyledSignUpDesktopWrapper>
        <SignUpDesktop />
      </StyledSignUpDesktopWrapper>
      <StyledSignUpMobileWrapper>
        <SignUpMobile />
      </StyledSignUpMobileWrapper>
    </AuthLayout>
  )
}
