import { useCallback } from 'react'
import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { COLOR, Flex, TickIcon } from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceItem } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField } from 'formik'

import { translate } from 'utils/translations'

import {
  getTaxRate,
  TAX_EXCLUSIVE_RATE,
  TAX_INCLUSIVE_RATE,
} from '../../InvoiceItemsAccordion.utils'
import {
  InvoiceItemPopperItem,
  InvoiceItemPopperRemoveItem,
} from './InvoiceItemPopper.styled'

export const translations = {
  addItemDiscount: translate('page.invoice.formSections.items.addItemDiscount'),
  addItemDiscountAriaLabel: translate(
    'page.invoice.formSections.items.addItemDiscountAriaLabel'
  ),
  removeLineItem: translate('page.invoice.formSections.items.removeLineItem'),
  removeLineItemAriaLabel: translate(
    'page.invoice.formSections.items.removeLineItemAriaLabel'
  ),
  saveItem: translate('page.invoice.formSections.items.saveItem'),
  saveItemAriaLabel: translate(
    'page.invoice.formSections.items.saveItemAriaLabel'
  ),
  taxApplicable: translate('page.invoice.formSections.items.taxApplicable'),
  taxApplicableAriaLabel: translate(
    'page.invoice.formSections.items.taxApplicableAriaLabel'
  ),
  updateItem: translate('page.invoice.formSections.items.updateItem'),
  updateItemAriaLabel: translate(
    'page.invoice.formSections.items.updateItemAriaLabel'
  ),
}

export interface InvoiceItemPopperProps {
  index: number
  isCatalogItem: boolean
  isCatalogItemModified: boolean
  isDiscountsEnabled: boolean
  isTaxInclusive: boolean
  itemsCount: number
  name: string
  isOpen: boolean
  onAddItemDiscount: (item: InvoiceItem) => void
  onRemoveLineItem: (index: number) => void
  onSaveItem: (item: InvoiceItem) => void
  onUpdateItem: (item: InvoiceItem) => void
  setIsOpen: (isOpen: boolean) => void
}

export const InvoiceItemPopper = ({
  index,
  isCatalogItem,
  isCatalogItemModified,
  isDiscountsEnabled,
  isOpen,
  isTaxInclusive,
  itemsCount,
  name,
  onAddItemDiscount: handleOnAddItemDiscount,
  onRemoveLineItem: handleOnRemoveLineItem,
  onSaveItem: handleOnSaveItem,
  onUpdateItem: handleOnUpdateItem,
  setIsOpen,
}: InvoiceItemPopperProps) => {
  const [field, , helpers] = useField(name)

  const isDiscountApplied =
    field.value.discount.config === InvoiceDiscountConfig.PERCENTAGE
      ? field.value.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
      : field.value.discount.price !== INVOICE_DEFAULT_PRICE

  const isTaxApplicable = Boolean(field.value.taxApplicable)
  const isNameDefined = field.value.name

  const handleOnTaxApplicable = useCallback(() => {
    const newTaxApplicableValue = !field.value.taxApplicable
    const itemTaxRate =
      !newTaxApplicableValue && isTaxInclusive
        ? TAX_INCLUSIVE_RATE
        : TAX_EXCLUSIVE_RATE
    const taxRate = getTaxRate(newTaxApplicableValue, isTaxInclusive)

    helpers.setValue({
      ...field.value,
      price: newTaxApplicableValue
        ? currency(field.value.price, { precision: 4 }).divide(taxRate)
        : // Revert back to previous base price if tax applicable is toggled off
          currency(field.value.price, { precision: 4 }).multiply(itemTaxRate),
      taxApplicable: newTaxApplicableValue,
    })
  }, [field.value, helpers, isTaxInclusive])

  if (!isOpen) {
    return null
  }

  const isLastItem = itemsCount === 1

  // NOTE: until inline saving/creating items is supported, hardcode to false
  // see InvoiceItems.tsx:138
  const showSaveItem = isCatalogItem // !isCatalogItem
  const showUpdateItem = isCatalogItem

  return (
    <Flex data-testid="invoicing-invoice-item-popper" flexDirection="column">
      {isDiscountsEnabled && (
        <InvoiceItemPopperItem
          aria-label={translations.addItemDiscountAriaLabel}
          data-testid="invoicing-invoice-item-popper-discount"
          onClick={() => {
            handleOnAddItemDiscount(field.value)
            setIsOpen(false)
          }}
        >
          <Flex alignItems="center" paddingRight="12px">
            <TickIcon
              color={isDiscountApplied ? COLOR.BLACK : COLOR.GREY_150}
            />
          </Flex>
          <Flex>{translations.addItemDiscount}</Flex>
        </InvoiceItemPopperItem>
      )}
      <InvoiceItemPopperItem
        aria-label={translations.taxApplicableAriaLabel}
        data-testid="invoicing-invoice-item-popper-tax-applicable"
        onClick={handleOnTaxApplicable}
      >
        <Flex alignItems="center" pr="12px">
          <TickIcon
            color={isTaxApplicable ? COLOR.BLACK_900 : COLOR.GREY_150}
          />
        </Flex>
        <Flex>{translations.taxApplicable}</Flex>
      </InvoiceItemPopperItem>
      {showSaveItem && (
        <InvoiceItemPopperItem
          $isDisabled={!isNameDefined}
          aria-label={translations.saveItemAriaLabel}
          aria-disabled={isNameDefined}
          data-testid="invoicing-invoice-item-popper-save-item"
          disabled={!isNameDefined}
          onClick={() => {
            handleOnSaveItem(field.value)
            setIsOpen(false)
          }}
        >
          {translations.saveItem}
        </InvoiceItemPopperItem>
      )}
      {showUpdateItem && (
        <InvoiceItemPopperItem
          $isDisabled={!isCatalogItemModified}
          aria-disabled={!isCatalogItemModified}
          aria-label={translations.updateItemAriaLabel}
          data-testid="invoicing-invoice-item-popper-update-item"
          disabled={!isCatalogItemModified}
          onClick={() => {
            handleOnUpdateItem(field.value)
            setIsOpen(false)
          }}
        >
          {translations.updateItem}
        </InvoiceItemPopperItem>
      )}
      <InvoiceItemPopperRemoveItem
        $isDisabled={isLastItem}
        aria-label={translations.removeLineItemAriaLabel}
        aria-disabled={isLastItem}
        data-testid="invoicing-invoice-item-popper-remove-line-item"
        disabled={isLastItem}
        onClick={() => handleOnRemoveLineItem(index)}
      >
        {translations.removeLineItem}
      </InvoiceItemPopperRemoveItem>
    </Flex>
  )
}
