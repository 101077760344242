import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  createInvoice: 'Create Invoice',
  createQuote: 'Create Quote',
  createQuoteOrInvoice: 'Create Quote or Invoice',
  invoice: 'Invoice',
  openSettings: 'Open invoice settings',
  quote: 'Quote',
  searchPlaceholder: 'ID, Contact or Title',
  settings: 'Settings',
  toggleFilters: 'Toggle invoice filters',
})
