import { useMemo } from 'react'
import { rvTransactionsErrorTracker } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/dashboard'

import {
  TRANSACTIONS_LIMIT,
  useTransactionsList,
} from 'hooks/useTransactionsList/useTransactionsList'
import { DashboardErrorPage } from 'pages/Dashboard/Fallbacks'
import { useSourceFilter } from 'pages/Dashboard/hooks/useSourceFilter'

import { useSourcePicker } from '../../../SourcePicker/useSourcePicker'
import { StyledNoTransactionsWrapper } from '../../DashboardTransactions.styled'
import { DashboardTransactionsListGroup } from '../../DashboardTransactionsListGroup/DashboardTransactionsListGroup'
import { TransactionsListSkeletonLoading } from '../../TransactionsListSkeletonLoading/TransactionsListSkeletonLoading'
import { PaymentTransactionsListElement } from '../PaymentTransactionsListElement/PaymentTransactionsListElement'
import { NoPayments } from './NoPayments'

export const LATEST_TRANSACTIONS_LIMIT = 10

export const PaymentTransactionsList = () => {
  const { selectedSources } = useSourcePicker()
  const { filter } = useSourceFilter()
  const areFiltersSelected = false
  const {
    transactionList,
    isLoading,
    refetching: isRefetching,
    transactionsError,
    refetch,
  } = useTransactionsList({
    filterObject: filter,
    areFiltersSelected,
    limit: LATEST_TRANSACTIONS_LIMIT,
  })

  const isNoOptionSelected = !selectedSources.length
  const shouldDisplayNoPayments =
    transactionList.length === 0 || isNoOptionSelected

  const limitedTransactionsList = useMemo(
    () => transactionList.slice(0, TRANSACTIONS_LIMIT),
    [transactionList]
  )

  if (isLoading || isRefetching) {
    return <TransactionsListSkeletonLoading />
  }

  if (transactionsError && transactionList.length === 0) {
    return (
      <StyledNoTransactionsWrapper>
        <DashboardErrorPage
          retry={refetch}
          rvRetry={rvTransactionsErrorTracker}
        />
      </StyledNoTransactionsWrapper>
    )
  }

  if (shouldDisplayNoPayments) {
    return (
      <StyledNoTransactionsWrapper>
        <NoPayments />
      </StyledNoTransactionsWrapper>
    )
  }

  return (
    <div>
      {limitedTransactionsList.map((transactionsList) => (
        <DashboardTransactionsListGroup
          timestamp={transactionsList[0].timestamp}
          key={transactionsList[0].timestamp}
        >
          {transactionsList.map((transaction) => (
            <PaymentTransactionsListElement
              key={transaction.id}
              transaction={transaction}
            />
          ))}
        </DashboardTransactionsListGroup>
      ))}
    </div>
  )
}
