import {
  BodyDefaultTypography,
  BodySmallTypography,
  BREAKPOINT,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ReactComponent as LocationIcon } from 'assets/svg/location.svg'
import { LetterBadge } from 'components/LetterBadge'

export const StyledLetterBadge = styled(LetterBadge)`
  flex-shrink: 0;
  transition: background-color 0.3s ease-out;
  border-radius: 50%;
  text-transform: uppercase;
  margin-right: 12px;
`

export const StyledPendingInvite = styled.span`
  display: none;
  color: ${({ theme }) => theme.colors.GREY_470};
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: inline-block;
  }
`

export const StyledLabelDivider = styled.span`
  display: inline-block;
  padding: 0 12px;
  color: ${({ theme }) => theme.colors.GREY_470};
`

export const StyledRole = styled.span`
  text-align: right;
  width: 10.25rem;
  text-transform: capitalize;
`

export const StyledSpacer = styled.div`
  flex-grow: 1;
`

export const StyledListItemContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  ${BodySmallTypography};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyDefaultTypography};
  }
`

export const StyledSummary = styled.div`
  display: none;
  justify-content: end;
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: flex;
  }
`

export const StyledInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.313rem;
  width: 35px;
`

export const StyledLocationCount = styled.span`
  flex-grow: 1;
`

export const StyledLocationIcon = styled(LocationIcon)`
  margin: 0 0.3rem 0 0;
  width: 10px;
  height: 12px;
  path {
    fill: ${({ theme }) => theme.colors.BLACK_900};
  }
`
