import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { DebitCardTransactionStatusV2 } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { rvIsDateRangeAppropriate } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

import { filtersListTranslations } from '../FiltersList.i18n'

export const useValidateExportFilterConditions = () => {
  const t = useTranslations(filtersListTranslations)

  const { areFiltersInDefaultState, ...accountFilters } = useAccountFilters()

  const { status, date } = accountFilters
  const isDateRangeAppropriate =
    useReactiveVar(rvIsDateRangeAppropriate) && Boolean(date)
  const isCorrectStatus =
    status === null || status.includes(DebitCardTransactionStatusV2.APPROVED)
  const isMeetingExportFilterConditions =
    isDateRangeAppropriate && isCorrectStatus
  const shouldDisableExport =
    areFiltersInDefaultState || !isMeetingExportFilterConditions

  const getTooltipMessage = useMemo(() => {
    // isDateRangeAppropriate is set to true when filters are in default state.
    if (shouldDisableExport && isDateRangeAppropriate && !isCorrectStatus) {
      return t('statusErrorMessage')
    }

    if (shouldDisableExport || !isDateRangeAppropriate) {
      return t('dateRangeErrorMessage')
    }

    return null
  }, [shouldDisableExport, isDateRangeAppropriate, isCorrectStatus, t])

  return {
    shouldDisableExport,
    tooltipMessage: getTooltipMessage,
  }
}
