import { useContext } from 'react'

import { SelectedDateRangeContext } from './SelectedDateRange.context'

export const useSelectedDateRange = () => {
  const context = useContext(SelectedDateRangeContext)

  if (!context) {
    throw new Error(
      'useSelectedDateRange must be used within SelectedDateRangeContext'
    )
  }

  return {
    selectedDateRange: context.selectedDateRange,
  }
}
