import { z } from 'zod'

export enum ReportLostCardCompleteStage {
  ReportLostCardComplete = 'ReportLostCardComplete',
}

const ReportLostCardCompleteStateSchema = z.object({
  stage: z.literal(ReportLostCardCompleteStage.ReportLostCardComplete),
  isAdmin: z.boolean(),
})

export type ReportLostCardCompleteState = z.infer<
  typeof ReportLostCardCompleteStateSchema
>

export const ReportLostCardCompleteLocationStateSchema = z.object({
  ReportLostCardCompleteModal: ReportLostCardCompleteStateSchema,
})
