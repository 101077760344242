import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { EditDebitCardTransactionCategoryState } from '../../EditDebitCardTransactionCategory.types'

export const useGoToEditDebitCardTransactionCategory = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToEditDebitCardTransactionCategory = useCallback(
    (state: EditDebitCardTransactionCategoryState | null) => {
      navigate(location.pathname, {
        state: {
          EditDebitCardTransactionCategoryModal: state,
        },
      })
    },
    [navigate, location.pathname]
  )

  return { goToEditDebitCardTransactionCategory }
}
