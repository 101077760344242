import { useMemo } from 'react'
import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  Flex,
  InfoFilledIcon,
  TooltipBasic,
  useModalState,
  zIndexMap,
} from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFieldsWithTotalErrors } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'
import { Box } from 'reflexbox'

import { translations } from '../../../../../Invoice.i18n'
import { useInvoiceItemsCalculations } from '../hooks/useInvoiceItemsCalculations'
import { InvoiceDiscountModal } from './InvoiceDiscountModal/InvoiceDiscountModal'
import {
  InvoiceAmountErrorText,
  InvoiceSubtotalButton,
  InvoiceSubtotalText,
  InvoiceSubtotalValue,
  InvoiceTotalsWrapper,
  InvoiceTotalText,
  InvoiceTotalValue,
} from './InvoiceItemsTotals.styled'
import { InvoiceTaxModal } from './InvoiceTaxModal/InvoiceTaxModal'

export const InvoiceItemsTotals = () => {
  const t = useTranslations(translations)

  const {
    closeModal: closeDiscountModal,
    isModalOpen: isDiscountModalOpen,
    openModal: openDiscountModal,
  } = useModalState()

  const {
    closeModal: closeTaxModal,
    isModalOpen: isTaxModalOpen,
    openModal: openTaxModal,
  } = useModalState()

  const { errors, initialValues, touched, values } =
    useFormikContext<InvoiceFormFieldsWithTotalErrors>()

  const {
    discountFormatted,
    subTotalFormatted,
    taxesFormatted,
    totalFormatted,
  } = useInvoiceItemsCalculations({ values })

  const isDiscountsEnabled = values.discountsEnabled
  const isItemsTaxInclusive = values.itemsTaxInclusive

  const isAnyLineItemTouched = useMemo(
    () =>
      Boolean(
        touched?.items?.some(
          (item) => item?.description || item?.name || item?.price
        )
      ),
    [touched.items]
  )

  const hasSwitchedTaxSetting = useMemo(
    () => initialValues.itemsTaxInclusive !== values.itemsTaxInclusive,
    [initialValues.itemsTaxInclusive, values.itemsTaxInclusive]
  )

  const isInvoiceDiscountApplied =
    values.discount.config === InvoiceDiscountConfig.PERCENTAGE
      ? values.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
      : values.discount.price !== INVOICE_DEFAULT_PRICE

  // NOTE: itemsExceedsMaxAmount and isItemsExceedingMinAmount is an adhoc
  // validation error reported on the errors schema.
  const showItemsExceedingMaxAmount = Boolean(
    (isAnyLineItemTouched || isInvoiceDiscountApplied) &&
      errors.itemsExceedsMaxAmount
  )

  const showItemsExceedingMinAmount = Boolean(
    (isAnyLineItemTouched || isInvoiceDiscountApplied) &&
      errors.itemsExceedsMinAmount
  )

  const isPercentageDiscount =
    values.discount.config === InvoiceDiscountConfig.PERCENTAGE

  const showPercentageDiscount =
    isPercentageDiscount &&
    values.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE

  return (
    <Flex alignItems="flex-end" flexDirection="column" paddingRight="24px">
      <InvoiceTotalsWrapper data-testid="invoicing-invoice-totals-wrapper">
        <Flex alignItems="flex-end" flexDirection="column" gridGap="8px">
          <InvoiceSubtotalText>{t('subtotal')}</InvoiceSubtotalText>
          {isDiscountsEnabled && (
            <InvoiceSubtotalButton
              aria-label={t('discountAriaLabel')}
              data-testid="invoicing-invoice-discount"
              onClick={openDiscountModal}
            >
              <InvoiceSubtotalText>
                {t('discount')}{' '}
                {showPercentageDiscount && `(${values.discount.percentage}%)`}
              </InvoiceSubtotalText>
            </InvoiceSubtotalButton>
          )}
          <InvoiceSubtotalButton
            aria-label={t('taxAriaLabel')}
            data-testid="invoicing-invoice-tax"
            onClick={openTaxModal}
          >
            <InvoiceSubtotalText>
              {isItemsTaxInclusive
                ? t('taxIncludedShort')
                : t('taxExcludedShort')}
            </InvoiceSubtotalText>
          </InvoiceSubtotalButton>
          <Flex alignItems="center" flexDirection="row" gap="8px">
            {hasSwitchedTaxSetting && (
              <TooltipBasic
                placement="top"
                renderTrigger={({ handlers, ref }) => (
                  <span ref={ref} {...handlers}>
                    <InfoFilledIcon color={COLOR.YELLOW_1000} />
                  </span>
                )}
                showArrow={false}
                zIndex={Number(zIndexMap.tooltip)}
              >
                <Box p="8px" maxWidth="321px" textAlign="center">
                  {t('invoiceGstWarningDescription')}
                </Box>
              </TooltipBasic>
            )}
            <InvoiceTotalText
              $hasError={
                showItemsExceedingMaxAmount || showItemsExceedingMinAmount
              }
            >
              {t('total')}
            </InvoiceTotalText>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gridGap="8px">
          <InvoiceSubtotalValue data-testid="invoicing-invoice-sub-total-value">
            {subTotalFormatted}
          </InvoiceSubtotalValue>
          {isDiscountsEnabled && (
            <InvoiceSubtotalValue data-testid="invoicing-invoice-discount-value">
              {discountFormatted}
            </InvoiceSubtotalValue>
          )}
          <InvoiceSubtotalValue data-testid="invoicing-invoice-tax-value">
            {taxesFormatted}
          </InvoiceSubtotalValue>
          <Flex flexDirection="row" alignItems="center" gap="8px">
            <InvoiceTotalValue
              $hasError={
                showItemsExceedingMaxAmount || showItemsExceedingMinAmount
              }
              dataTestId="invoicing-invoice-total-value"
            >
              {totalFormatted}
            </InvoiceTotalValue>
          </Flex>
        </Flex>
      </InvoiceTotalsWrapper>
      {showItemsExceedingMaxAmount && (
        <InvoiceAmountErrorText data-testid="invoicing-invoice-exceeded-max-amount-error">
          {errors.itemsExceedsMaxAmount}
        </InvoiceAmountErrorText>
      )}
      {showItemsExceedingMinAmount && (
        <InvoiceAmountErrorText data-testid="invoicing-invoice-exceeded-min-amount-error">
          {errors.itemsExceedsMinAmount}
        </InvoiceAmountErrorText>
      )}
      {isDiscountModalOpen && (
        <InvoiceDiscountModal closeModal={closeDiscountModal} />
      )}
      {isTaxModalOpen && <InvoiceTaxModal closeModal={closeTaxModal} />}
    </Flex>
  )
}
