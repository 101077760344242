import {
  StyledAvatarBasic,
  StyledLoaderPill,
  StyledLoaderPillContainer,
  StyledLoaderRow,
} from './LoaderRow.styled'

interface LoadingRowProps {
  colOneWidth: number
  colTwoWidth: number
  colThreeWidth: number
  showMobileStyle?: boolean
}

export const LoadingRow = ({
  colOneWidth,
  colTwoWidth,
  colThreeWidth,
  showMobileStyle = false,
}: LoadingRowProps) => (
  <StyledLoaderRow>
    <StyledAvatarBasic />
    <StyledLoaderPillContainer showMobileStyle={showMobileStyle}>
      <StyledLoaderPill
        width={`${colOneWidth}px`}
        showMobileStyle={showMobileStyle}
      />
    </StyledLoaderPillContainer>
    <StyledLoaderPillContainer small showMobileStyle={showMobileStyle}>
      <StyledLoaderPill
        width={`${colTwoWidth}px`}
        showMobileStyle={showMobileStyle}
      />
    </StyledLoaderPillContainer>
    <StyledLoaderPillContainer
      small
      align="right"
      showMobileStyle={showMobileStyle}
    >
      <StyledLoaderPill
        width={`${colThreeWidth}px`}
        showMobileStyle={showMobileStyle}
      />
    </StyledLoaderPillContainer>
  </StyledLoaderRow>
)
