import React from 'react'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ChartLineIcon } from 'assets/svg/chart-line.svg'
import { REPORTS_TRANSACTION_STATUSES } from 'const/common'
import { getSelectedItemsInfo } from 'utils/picker'
import { PickerItemProps } from 'types/picker'
import {
  StyledDropdown,
  StyledOpenOptions,
  StyledStatusesBox,
} from 'components/Filters/NewFilters/StatusPicker/StatusPicker.styled'
import { MultiDownshift } from 'components/MultiDownshift'
import { MultiDownshiftComponentName } from 'components/MultiDownshift/MultiDownshift.hooks'
import {
  AllItemsRow,
  BottomRow,
  StatusRow,
  TopRow,
} from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { StyledDataSmall } from 'components/Shared'
import { component } from 'translations'

export const ReportStatusPicker: React.FC = () => (
  <MultiDownshift
    itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
    componentName={MultiDownshiftComponentName.Status}
  >
    {({
      getRootProps,
      getItemProps,
      getToggleButtonProps,
      closeMenu,
      selectedItems,
      toggleStatuses,
      isOpen,
      clearAllSelected,
    }) => (
      <StyledDropdown
        {...getRootProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
      >
        <PickerTrigger
          getToggleButtonProps={getToggleButtonProps}
          label={getSelectedItemsInfo(selectedItems, 'status')}
          isOpen={isOpen}
          placeholderIcon={
            <SvgIcon color={COLOR.BLUE_1000}>
              <ChartLineIcon />
            </SvgIcon>
          }
          triggerContent={
            selectedItems?.length
              ? getSelectedItemsInfo(selectedItems, 'status')
              : undefined
          }
        />
        {isOpen && (
          <StyledOpenOptions>
            <TopRow
              handleCloseMenu={closeMenu}
              copy={component.statusPicker.selectStatus}
            />
            <StyledStatusesBox>
              {REPORTS_TRANSACTION_STATUSES.length > 0 && (
                <AllItemsRow
                  label={component.statusPicker.allStatuses}
                  checked={
                    selectedItems.length === REPORTS_TRANSACTION_STATUSES.length
                  }
                  handleOnChange={() =>
                    toggleStatuses(REPORTS_TRANSACTION_STATUSES)
                  }
                />
              )}
              {REPORTS_TRANSACTION_STATUSES.map((status) => (
                <StatusRow
                  key={status.name}
                  status={status}
                  getItemProps={getItemProps}
                  selectedItems={selectedItems}
                />
              ))}
            </StyledStatusesBox>
            <BottomRow handleClearAll={clearAllSelected}>
              <StyledDataSmall data-testid="selected-items">
                {getSelectedItemsInfo(selectedItems, 'status')}
              </StyledDataSmall>
            </BottomRow>
          </StyledOpenOptions>
        )}
      </StyledDropdown>
    )}
  </MultiDownshift>
)
