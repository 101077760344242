import {
  TableBodyCellContentChildrenWrapper,
  TableCellIcon,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InfoIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 26px;
`

export const SimIconWrapper = styled(TableCellIcon)`
  ${TableBodyCellContentChildrenWrapper} {
    overflow: visible;
  }
`
