import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'
import { StyledDropdownWrapper } from 'components/Shared'

export const StyledDropdown = styled(StyledDropdownWrapper)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.LG -
    1}px) {
    width: 100%;
  }
`
export const StyledItemsBox = styled(StyledItemsWrapper)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
  }
`

export const StyledTypesBox = styled.ul`
  margin: 0;
  padding: 0;
  max-height: 17.5rem;
  overflow: auto;
`

export const StyledOpenOptions = styled(StyledItemsWrapper)`
  transform: translateY(-112px);

  @media (min-width: ${BREAKPOINT.XS}px) {
    transform: none;
  }
`
