import { useCallback } from 'react'
import { TableHeaderCellIcon } from '@npco/zeller-design-system'
import { Header, Table } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'
import { SortIndicator } from 'components/Sort'

interface InvoiceHeaderCellProps {
  children?:
    | React.ReactNode
    | ((props: {
        isOpen: boolean
        setIsOpen: (state: boolean) => void
        setShouldCloseOnOutsideClick: (state: boolean) => void
      }) => React.ReactNode)
  disablePopper?: boolean
  header: Header<Invoice | null, unknown>
  isFiltering?: boolean
  justifyContent?: string
  label: string
  popperWidth: string
  table: Table<Invoice | null>
}

export const InvoiceHeaderCell = ({
  children,
  disablePopper = false,
  isFiltering,
  justifyContent,
  label,
  popperWidth,
  header,
  table,
}: InvoiceHeaderCellProps) => {
  const { sortingObject: sorting } = table.options.meta || {}
  const isSorting = Boolean(sorting?.columnName === header.column.id)
  const isSortingAscending = Boolean(sorting?.ascending)
  const size = header.column.getSize()

  const renderIcon = useCallback(
    () => (
      <SortIndicator
        data-testid={`${header.id}-${isSortingAscending}`}
        $ascending={isSortingAscending}
      />
    ),
    [header.id, isSortingAscending]
  )

  // NOTE: https://github.com/TanStack/table/discussions/3192
  // The column default size is 150, if we pass no size property on the column
  // definition it falls back to this value. Rather than have a fixed width we
  // actually want these undefined size columns to take up the remaining
  // available room so override to have `auto` value.
  const width = size !== 150 ? size : 'auto'

  return (
    <TableHeaderCellIcon
      disablePopper={disablePopper}
      forwardedProps={{ style: { padding: 0, width } }}
      icon={renderIcon}
      isFiltering={isSorting || isFiltering}
      isSorting={isSorting}
      justifyContent={justifyContent}
      key={header.id}
      label={label}
      popperPlacement="bottom-start"
      popperWidth={popperWidth}
    >
      {children}
    </TableHeaderCellIcon>
  )
}
