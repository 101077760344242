import { gql } from '@apollo/client'
import {
  BREAKPOINT,
  Flex,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { PieChart } from '../../../PieChart/PieChart'
import { HoveredContactNetAmount } from '../TransactionsSummaryContact.types'
import { ContactPieChartText } from './ContactPieChartText'
import { ContactsChartCashFlowContactNetAmountFragment } from './ContactsChart.generated'
import { useGetContactPieChartData } from './hooks/useGetContactPieChartData'

interface ContactsChartProps {
  netAmounts: ContactsChartCashFlowContactNetAmountFragment[]
  isLoading?: boolean
  transactionDirection: 'income' | 'expense'
  hoveredContactNetAmount: HoveredContactNetAmount
}

export const ContactsChart = ({
  netAmounts,
  isLoading = false,
  transactionDirection,
  hoveredContactNetAmount,
}: ContactsChartProps) => {
  const isBelowBreakpointMD = useIsMobileResolution(BREAKPOINT.MD)
  const isBelowBreakpointLG = useIsMobileResolution(BREAKPOINT.LG)

  const { pieChartData, totalAmount, activeNetAmount } =
    useGetContactPieChartData({
      netAmounts,
      hoveredContactNetAmount,
    })

  const size = isBelowBreakpointLG && !isBelowBreakpointMD ? 'small' : 'large'
  return (
    <Flex
      py="30px"
      px={{ LG: '36px' }}
      mt="24px"
      justifyContent="center"
      data-testid="contact-chart"
    >
      {isLoading ? (
        <PieChart.Loading size={size} />
      ) : (
        <PieChart
          size={size}
          data={pieChartData}
          center={
            <ContactPieChartText
              size={size}
              activeNetAmount={activeNetAmount}
              totalAmount={totalAmount}
              transactionDirection={transactionDirection}
            />
          }
        />
      )}
    </Flex>
  )
}

ContactsChart.fragments = {
  CashFlowContactNetAmount: gql`
    fragment ContactsChartCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      ...UseGetContactPieChartDataCashFlowContactNetAmountFragment
    }

    ${useGetContactPieChartData.fragments.CashFlowContactNetAmount}
  `,
}
