import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  Heading,
  MagnifyingGlassNoResults,
  Message,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translationsShared } from 'translations'

import { translations } from './AccountBalanceChartError.i18n'
import { StyledDescription } from './AccountBalanceChartError.styled'

interface AccountBalanceChartErrorProps {
  retry?: () => void
}

export const AccountBalanceChartError = ({
  retry,
}: AccountBalanceChartErrorProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)

  const isMobile = useIsMobileResolution()
  const align = isMobile ? 'center' : 'left'

  return (
    <Flex
      backgroundColor={COLOR.GREY_30}
      borderRadius="8px"
      p={['8px 16px', '24px 40px']}
      mb="12px"
      data-testid="account-balance-chart-error"
    >
      <Flex flexDirection={['column', 'row']}>
        <Box
          data-testid="account-balance-error-illustration"
          pr={['0', '40px']}
          margin="auto"
        >
          <MagnifyingGlassNoResults size={isMobile ? 'medium' : 'large'} />
        </Box>
        <Box>
          <Message
            title={
              <Box textAlign={align}>
                <Heading.H2>{t('heading')}</Heading.H2>
              </Box>
            }
            description={
              <Box textAlign={align}>
                <StyledDescription>{t('description')}</StyledDescription>
              </Box>
            }
            align={align}
            primaryButton={{
              label: tShared('tryAgain'),
              onClick: retry,
            }}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
