import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  InfoFilledIcon,
  TOOLTIP_ZINDEX,
  TooltipButton,
} from '@npco/zeller-design-system'

import { nicknameFieldTranslations } from 'pages/Transfer/TransferFields/fields/Nickname/Nickname.i18n'

export const NicknameInfoToolTip = ({ label }: { label: string }) => {
  const t = useTranslations(nicknameFieldTranslations)
  return (
    <TooltipButton
      label={label}
      tooltipIcon={<InfoFilledIcon color={COLOR.GREY_550} />}
      placement="top"
      zIndex={Number(TOOLTIP_ZINDEX)}
    >
      <Box maxWidth="300px" textAlign="center">
        {t('toolTipText')}
      </Box>
    </TooltipButton>
  )
}
