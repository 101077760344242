import { BodySmallTypography } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const StyledFileUploaderWrapper = styled.div<{
  isDragActive?: boolean
}>`
  box-sizing: border-box;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ isDragActive, theme }) =>
    isDragActive ? theme.colors.BLUE_1000 : theme.colors.GREY_250};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  height: 8.5rem;
  padding: 0.5rem;
  outline: none;
  transition: border 0.3s ease-out;
  cursor: pointer;
`

export const StyledDragDescription = styled.p`
  ${BodySmallTypography};
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
`

export const StyledBrowseDescription = styled.p`
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
`

export const StyledContent = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  width: 100%;
  height: 100%;
  transition: background-color 0.3s ease-out;
  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background-color: ${theme.colors.GREY_30};

      ${StyledDragDescription}, ${StyledBrowseDescription} {
        color: ${theme.colors.GREY_250};
      }
    `};
`

export const StyledCounter = styled.div`
  ${BodySmallTypography};
  padding: 0.625rem 0;
`
