import {
  PredefinedAddressPageFragment_businessAddress as BusinessAddressValues,
  PredefinedAddressPageFragment_registeredAddress as RegisteredAddressValues,
} from 'types/gql-types/PredefinedAddressPageFragment'

export interface CustomAddressFormValues {
  street1: string | null
  street2: string | null
  suburb: string | null
  state: string | null
  postcode: string | null
}

export interface CustomAddressValues extends CustomAddressFormValues {
  country: string | null
}

export enum AddressOption {
  RegisteredAddress = 'RegisteredAddress',
  BusinessAddress = 'BusinessAddress',
}

export enum AddressPage {
  PredefinedAddress = 'PredefinedAddress',
  CustomAddress = 'CustomAddress',
}

export interface PredefinedAddressFormValues {
  address: AddressOption | ''
}

export type PredefinedAddressValues =
  | BusinessAddressValues
  | RegisteredAddressValues

export type AddressFormValues =
  | CustomAddressFormValues
  | PredefinedAddressValues
