import styled from 'styled-components'

import { Notification, StyledText } from 'components/Notification'

export const StyledWarningNotification = styled(Notification)`
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.RED_80};

  ${StyledText} {
    font-size: 14px;
  }

  div {
    align-self: center;
  }
`
