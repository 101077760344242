import { InvoiceStatus as InvoiceStatusType } from '@npco/mp-gql-types'
import {
  BodyDefaultTypography,
  DrawerBasic as ImportedDrawerBasic,
  Flex,
} from '@npco/zeller-design-system'
import { StatusColor } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import styled from 'styled-components'

export const DrawerBasic = styled(ImportedDrawerBasic)`
  .close-container {
    align-self: stretch;
    flex: 1;
  }
`

export const InvoiceStatusLabel = styled(Flex)<{ $status?: InvoiceStatusType }>`
  ${BodyDefaultTypography}
  ${({ $status }) => $status && `color: ${StatusColor[$status]}`};

  align-self: flex-end;
  flex: 1;
  line-height: 1;
  margin-bottom: 6px;
  margin-left: 12px;
  position: relative;
`
