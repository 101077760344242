import { useTranslations } from '@npco/utils-translations'
import { CategoryIcon, PenIcon, ReceiptIcon } from '@npco/zeller-design-system'

import { FieldOption } from './FieldOption/FieldOption'
import { outstandingExpensesFieldsTranslations } from './OutstandingExpensesFields.i18n'
import * as styled from './OutstandingExpensesFields.styled'

const CategoryIconElement = () => (
  <styled.CardIconWrapper>
    <CategoryIcon />
  </styled.CardIconWrapper>
)

const ReceiptIconElement = () => (
  <styled.CardIconWrapper>
    <ReceiptIcon />
  </styled.CardIconWrapper>
)

const NoteIconElement = () => (
  <styled.CardIconWrapper>
    <PenIcon />
  </styled.CardIconWrapper>
)

export const OutstandingExpensesFields = () => {
  const t = useTranslations(outstandingExpensesFieldsTranslations)

  return (
    <styled.FieldsWrapper
      gap="8px"
      flexDirection={{ _: 'column', XS: 'row' }}
      height={{ _: '232px', XS: '154px' }}
    >
      <FieldOption
        id="category-option"
        title={t('categoryOptionTitle')}
        value="isCategoryRequired"
        icon={CategoryIconElement}
      />
      <FieldOption
        id="receipt-option"
        title={t('receiptOptionTitle')}
        value="isReceiptRequired"
        icon={ReceiptIconElement}
      />
      <FieldOption
        id="note-option"
        title={t('noteOptionTitle')}
        value="isNoteRequired"
        icon={NoteIconElement}
      />
    </styled.FieldsWrapper>
  )
}
