import { useCallback } from 'react'
import { Box } from '@npco/zeller-design-system'
import { GoogleMap, Marker } from '@react-google-maps/api'

import markerUrl from 'assets/svg/marker.svg'
import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'
import { StaticMapError } from 'components/StaticMap/StaticMapError/StaticMapError'

import { useInitGoogleScriptLoad } from '../../hooks/useInitGoogleScriptLoad/useInitGoogleScriptLoad'
import { Location } from './StaticMap.types'
import { buildStyles, navigateToGoogleMap } from './StaticMap.utils'

interface Props {
  children?: React.ReactNode
  location: Location
  isDisabled?: boolean
  onGoogleMapLoad?: (map: google.maps.Map) => void
  shouldHideIcons?: boolean
  shouldHideMarker?: boolean
  width?: string
  height?: string
  'data-testid'?: string
  zoom?: number
}

export const DEFAULT_STREET_LEVEL_ZOOM = 16

export const StaticMap = ({
  children,
  location,
  isDisabled = false,
  shouldHideIcons = false,
  shouldHideMarker = false,
  width = '100%',
  height = '100%',
  'data-testid': dataTestId,
  zoom = DEFAULT_STREET_LEVEL_ZOOM,
  onGoogleMapLoad,
}: Props) => {
  const { isLoaded, loadError } = useInitGoogleScriptLoad()

  const openMapInNewWindow = useCallback(
    () => navigateToGoogleMap({ lng: location.lng, lat: location.lat }),
    [location.lng, location.lat]
  )

  const mapContainerStyle = {
    width,
    height,
    cursor: isDisabled ? undefined : 'pointer',
  }

  const handleMapClick = isDisabled ? undefined : openMapInNewWindow

  const styles = buildStyles({ shouldHideIcons, isDisabled })

  return (
    <>
      <Box
        height="10rem"
        onContextMenu={(e) => {
          // NOTE: Disable right click for maps
          e.preventDefault()
        }}
      >
        {isLoaded ? (
          <GoogleMap
            clickableIcons={false}
            center={location}
            data-testid={dataTestId || 'static-map'}
            mapContainerStyle={mapContainerStyle}
            options={{
              keyboardShortcuts: false,
              styles,
              draggable: false,
              disableDefaultUI: true,
            }}
            onClick={handleMapClick}
            onLoad={onGoogleMapLoad}
            zoom={zoom}
          >
            {!isDisabled && !shouldHideMarker && (
              <Marker
                clickable={false}
                position={location}
                data-testid={`${dataTestId || 'static-map'}-marker`}
                icon={markerUrl}
              />
            )}
            {!isDisabled && children}
          </GoogleMap>
        ) : (
          <SkeletonLoaderBasic />
        )}
      </Box>

      {loadError && <StaticMapError />}
    </>
  )
}
