import { ApolloError } from '@apollo/client'

import { TransactionsSummaryEmpty } from '../../TransactionsSummaryEmpty'
import { TransactionsSummaryError } from '../../TransactionsSummaryError'
import { TransactionsSummaryNotSupport } from '../../TransactionsSummaryNotSupport'
import { WelcomeIncomeBanner } from '../WelcomeIncomeBanner'

interface CategoriesBannerProps {
  isOnIncomeTab: boolean
  isLoading: boolean
  isAccountSaving: boolean
  isNetAmountsEmpty: boolean
  error?: ApolloError
  onRetry: () => void
}

export const CategoriesBanner: React.FC<CategoriesBannerProps> = ({
  isOnIncomeTab,
  isLoading,
  isAccountSaving,
  isNetAmountsEmpty,
  error,
  onRetry,
}) => {
  if (isOnIncomeTab) {
    return <WelcomeIncomeBanner />
  }
  if (isAccountSaving) {
    return <TransactionsSummaryNotSupport />
  }

  if (!isLoading && isNetAmountsEmpty && !error) {
    return <TransactionsSummaryEmpty />
  }

  if (!isLoading && isNetAmountsEmpty && error) {
    return <TransactionsSummaryError onRetry={onRetry} />
  }

  return null
}
