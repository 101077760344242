import {
  InputSelectComboboxStandardItemWrapper,
  Subtitle,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledInputSelectComboboxItem = styled(
  InputSelectComboboxStandardItemWrapper
)`
  display: flex;
  justify-content: space-between;
`

export const StyledBankDetails = styled(Subtitle)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
`

export const StyledAccountName = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
