import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Flex, InfoBox } from '@npco/zeller-design-system'

import { useGoToUsersList } from 'hooks/useGoToUsersList/useGoToUsersList'

import { cardHolderInfoBoxTranslations } from './CardHolderInfoBox.i18n'
import { StyledBoldText } from './CardHolderInfoBox.styled'

export const CardHolderInfoBox = () => {
  const t = useTranslations(cardHolderInfoBoxTranslations)
  const { goToUsersList } = useGoToUsersList()

  const navigateToUsers = useCallback(() => {
    goToUsersList()
  }, [goToUsersList])

  return (
    <InfoBox>
      <Flex
        flexDirection={{ _: 'column', SM: 'row' }}
        gap="16px"
        justifyContent="space-between"
        width="100%"
      >
        <InfoBox.Message>
          {t('verificationText')}
          <br />
          {t('creationText', {
            usersText: <StyledBoldText>{t('usersText')}</StyledBoldText>,
            settingsText: <StyledBoldText>{t('settingsText')}</StyledBoldText>,
          })}
        </InfoBox.Message>
        <InfoBox.ButtonGroup>
          <InfoBox.CallToAction onClick={navigateToUsers}>
            {t('buttonLabel')}
          </InfoBox.CallToAction>
        </InfoBox.ButtonGroup>
      </Flex>
    </InfoBox>
  )
}
