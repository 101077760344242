import { useMutation } from '@apollo/client'
import { RefundTransaction } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/transactions'

import {
  RefundTransaction as RefundTransactionResponse,
  RefundTransactionVariables,
} from 'types/gql-types/RefundTransaction'

export const useRefundTransaction = () => {
  const [refundTransaction, { loading }] = useMutation<
    RefundTransactionResponse,
    RefundTransactionVariables
  >(RefundTransaction, {
    errorPolicy: 'all',
  })

  const handleRefundTransaction = (variables: RefundTransactionVariables) => {
    return refundTransaction({
      variables,
    })
  }

  return {
    isRefunding: loading,
    refundTransaction: handleRefundTransaction,
  }
}
