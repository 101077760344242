import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { translations } from './ContactUuidSelectHeader.i18n'
import {
  StyledContactHeaderButton,
  StyledContactHeaderContainer,
} from './ContactUuidSelectHeader.styled'

interface ContactUuidSelectHeaderProps {
  closeMenu: () => void
  onCreateContact: (contactType: ContactType) => void
}

export const ContactUuidSelectHeader = ({
  closeMenu,
  onCreateContact: handleCreateContact,
}: ContactUuidSelectHeaderProps) => {
  const t = useTranslations(translations)

  const handleClick = (contactType: ContactType) => {
    handleCreateContact(contactType)
    closeMenu()
  }

  return (
    <StyledContactHeaderContainer>
      <StyledContactHeaderButton
        dataTestId="create-person-contact"
        onClick={() => handleClick(ContactType.PERSON)}
      >
        {t('createPersonButton')}
      </StyledContactHeaderButton>
      <StyledContactHeaderButton
        dataTestId="create-business-contact"
        onClick={() => handleClick(ContactType.BUSINESS)}
      >
        {t('createBusinessButton')}
      </StyledContactHeaderButton>
    </StyledContactHeaderContainer>
  )
}
