import { ContactType } from '@npco/mp-gql-types'
import isEqual from 'lodash/isEqual'
import { array, bool, boolean, object, string, TestContext } from 'yup'

import { VALID_EMAIL_REGEX } from 'utils/formValidation'
import { translate } from 'utils/translations'

import { defaultInvoiceLineItem } from '../components/InvoiceCreateForm/InvoiceCreateForm.utils'
import {
  availableEntityTagsMap,
  MergeTag,
} from '../components/InvoiceFormAccordions/components/InvoiceDeliveryAccordion/InvoiceDeliveryAccordion.constants'
import {
  INVOICE_EMAIL_MESSAGE_MAX_CHARACTERS,
  INVOICE_EMAIL_SUBJECT_MAX_CHARACTERS,
} from '../Invoice.constants'
import { InvoiceFormFields } from '../Invoice.types'

export const emailSchemaErrors = {
  invalidEmailError: translate(
    'page.invoice.formSections.email.invalidEmailError'
  ),
  invalidFailedEmailError: translate(
    'page.invoice.formSections.email.invalidFailedEmailError'
  ),
  invalidFailedPayerEmailError: translate(
    'page.invoice.formSections.email.invalidFailedPayerEmailError'
  ),
  mergeTagUnavailableError: translate(
    'page.invoice.formSections.email.mergeTagUnavailableError'
  ),
  subjectRequiredError: translate(
    'page.invoice.formSections.email.subjectRequiredError'
  ),
  subjectMaxLengthError: translate(
    'page.invoice.formSections.email.subjectMaxLengthError',
    { maxLength: INVOICE_EMAIL_SUBJECT_MAX_CHARACTERS }
  ),
  messageRequiredError: translate(
    'page.invoice.formSections.email.messageRequiredError'
  ),
  messageMaxLengthError: translate(
    'page.invoice.formSections.email.messageMaxLengthError',
    { maxLength: INVOICE_EMAIL_MESSAGE_MAX_CHARACTERS }
  ),
}

export function validateAllMergeTagsHaveValues(
  emailMessage: string | null | undefined,
  context: TestContext<{
    attentionToContactType?: ContactType
    initialValues?: InvoiceFormFields
    payerContactType?: ContactType
    values?: InvoiceFormFields
  }>
) {
  return Object.keys(availableEntityTagsMap).every((key) => {
    const entityKey = key as MergeTag
    if (
      !context.options.context?.values ||
      !emailMessage?.includes(availableEntityTagsMap[entityKey].apiString)
    ) {
      return true
    }

    const {
      attentionToContactType,
      payerContactType,
      values: { customer, items, schedule },
    } = context.options.context

    if (entityKey === MergeTag.Customer) {
      return (
        customer.payerContact?.contactUuid ||
        customer.attentionToContact?.contactUuid
      )
    }
    if (entityKey === MergeTag.PersonName) {
      return (
        attentionToContactType === ContactType.PERSON ||
        payerContactType === ContactType.PERSON
      )
    }
    if (entityKey === MergeTag.BusinessName) {
      return (
        attentionToContactType === ContactType.BUSINESS ||
        payerContactType === ContactType.BUSINESS
      )
    }
    if (entityKey === MergeTag.Schedule) {
      return schedule.dueDate
    }
    if (entityKey === MergeTag.TotalPrice) {
      const isDefaultItem =
        items.length === 1 && isEqual(items[0], defaultInvoiceLineItem)

      return items.length > 0 && !isDefaultItem
    }
    return true
  })
}

export const validateIsNotRequiredEmailUpdateBeforeSend = (
  email: string | undefined,
  {
    options,
  }: TestContext<{
    attentionToContactType?: ContactType
    initialValues?: InvoiceFormFields
    payerContactType?: ContactType
    values?: InvoiceFormFields
  }>
) => {
  const initialRequiredEmailUpdateBeforeSend =
    options?.context?.initialValues?.requiredEmailUpdateBeforeSend ?? []

  if (!initialRequiredEmailUpdateBeforeSend.length || !email) {
    return true
  }

  const hasInvalidEmail = initialRequiredEmailUpdateBeforeSend.includes(email)

  return !hasInvalidEmail
}

export const validateAnyRequiredEmailUpdateBeforeSend = (
  emails: (string | undefined)[] | undefined,
  {
    options,
  }: TestContext<{
    attentionToContactType?: ContactType
    initialValues?: InvoiceFormFields
    payerContactType?: ContactType
    values?: InvoiceFormFields
  }>
) => {
  const initialRequiredEmailUpdateBeforeSend =
    options?.context?.initialValues?.requiredEmailUpdateBeforeSend ?? []

  if (!initialRequiredEmailUpdateBeforeSend.length || !emails?.length) {
    return true
  }

  const hasAnyInvalidEmail = (emails ?? []).some(
    (email) => email && initialRequiredEmailUpdateBeforeSend.includes(email)
  )

  return !hasAnyInvalidEmail
}

export const emailOptionalSchema = object({
  cc: array()
    .of(
      string()
        .trim()
        .email(emailSchemaErrors.invalidEmailError)
        .matches(VALID_EMAIL_REGEX, {
          excludeEmptyString: true,
          message: emailSchemaErrors.invalidEmailError,
        })
    )
    .test(
      'isAnyRequiredEmailUpdateBeforeSend',
      emailSchemaErrors.invalidFailedEmailError,
      validateAnyRequiredEmailUpdateBeforeSend
    ),
  bcc: array()
    .of(
      string()
        .trim()
        .email(emailSchemaErrors.invalidEmailError)
        .matches(VALID_EMAIL_REGEX, {
          excludeEmptyString: true,
          message: emailSchemaErrors.invalidEmailError,
        })
    )
    .test(
      'isAnyRequiredEmailUpdateBeforeSend',
      emailSchemaErrors.invalidFailedEmailError,
      validateAnyRequiredEmailUpdateBeforeSend
    ),
  isEnabled: bool(),
  subject: string()
    .nullable()
    .max(250, emailSchemaErrors.subjectMaxLengthError),
  message: string()
    .nullable()
    .max(1000, emailSchemaErrors.messageMaxLengthError),
  recipient: string()
    .email(emailSchemaErrors.invalidEmailError)
    .test(
      'isNotRequiredEmailUpdateBeforeSend',
      emailSchemaErrors.invalidFailedPayerEmailError,
      validateIsNotRequiredEmailUpdateBeforeSend
    )
    .matches(VALID_EMAIL_REGEX, {
      excludeEmptyString: true,
      message: emailSchemaErrors.invalidEmailError,
    }),
  sendMeCopy: boolean(),
})

export const emailRequiredSchema = object({
  isEnabled: boolean(),
  subject: string()
    .nullable(false)
    .typeError(emailSchemaErrors.subjectRequiredError)
    .test(
      'isAllMergeTagsHaveValues',
      emailSchemaErrors.mergeTagUnavailableError,
      validateAllMergeTagsHaveValues
    )
    .required(emailSchemaErrors.subjectRequiredError),
  message: string()
    .nullable(false)
    .typeError(emailSchemaErrors.messageRequiredError)
    .test(
      'isAllMergeTagsHaveValues',
      emailSchemaErrors.mergeTagUnavailableError,
      validateAllMergeTagsHaveValues
    )
    .required(emailSchemaErrors.messageRequiredError),
  recipient: string().trim().required(emailSchemaErrors.invalidEmailError),
})
