import { useCallback } from 'react'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { useGoToUsersList } from 'hooks/useGoToUsersList/useGoToUsersList'

interface UseUserNavigationProps {
  userId: string
}

export const useUserNavigation = ({ userId }: UseUserNavigationProps) => {
  const { userData } = useLoggedInUser()

  const isAdmin = userData?.role === CustomerRole.ADMIN
  const isLoggedInUser = userData?.id === userId

  const { goToUsersList } = useGoToUsersList()

  const navigateToUser = useCallback(() => {
    goToUsersList({ selectedUserId: userId })
  }, [goToUsersList, userId])

  return { navigateToUser, canNavigate: isAdmin && !isLoggedInUser }
}
