import { useCallback, useMemo } from 'react'
import { ReceiptNotificationType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { FormikErrors } from 'formik'

import { useSendEmailRemittance } from '../../hooks/useSendEmailRemittance/useSendEmailRemittance'
import { transferRemittancesButtonsTranslations } from '../../TransferRemittancesButtons/TransferRemittancesButtons.i18n'
import { TransferRemittancesModal } from '../TransferRemittancesModal'
import { RemittanceData } from '../TransferRemittancesModal.types'
import { EmailRemittanceFields } from './EmailRemittanceFields/EmailRemittanceFields'

type EmailRemittanceModalProps = {
  isOpen: boolean
  closeModal: () => void
  currentUserEmail: string
  transactionID: string
  contactEmail?: string
}

export const EmailRemittanceModal = ({
  isOpen,
  closeModal,
  currentUserEmail,
  transactionID,
  contactEmail,
}: EmailRemittanceModalProps) => {
  const t = useTranslations(transferRemittancesButtonsTranslations)
  const { sendEmailRemittance, isSending } = useSendEmailRemittance()

  const checkIfCurrentUserDetails = useCallback(
    (value: string) => {
      return value === currentUserEmail
    },
    [currentUserEmail]
  )

  const sendNotification = useCallback(
    async (values: RemittanceData) => {
      const isSenderNotified =
        !checkIfCurrentUserDetails(values.email) && values.emailCheckbox

      await sendEmailRemittance(
        {
          dcaTransactionUuid: transactionID,
          isSenderNotified,
          type: ReceiptNotificationType.EMAIL,
          recipientEmail: values.email,
        },
        closeModal
      )
    },
    [checkIfCurrentUserDetails, closeModal, sendEmailRemittance, transactionID]
  )

  const isPrimaryButtonDisabled = useMemo(
    () => (values: RemittanceData, errors: FormikErrors<RemittanceData>) =>
      !values.email || Boolean(errors.email),
    []
  )

  return (
    <TransferRemittancesModal
      isOpen={isOpen}
      onCancel={closeModal}
      title={t('emailRemittance')}
      description={t('emailRemittanceDescription')}
      sendRemittance={sendNotification}
      isLoading={isSending}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      contactEmail={contactEmail}
    >
      <EmailRemittanceFields
        checkBoxDescription={t('emailRemittanceCheckBox')}
        placeholder={t('email')}
        currentUserEmail={currentUserEmail}
      />
    </TransferRemittancesModal>
  )
}
