import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'

import { SetupFlowState } from './useGoToSetupFlowStage.types'

export const useGoToSetupFlowStage = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const goToSetupFlowStage = useCallback(
    (state: SetupFlowState | undefined) => {
      navigate(ROOT.ORGS.ORG(shortEntityId).OVERVIEW.PAYMENTS.path, {
        state: {
          SetupFlowModal: state,
        },
      })
    },
    [navigate, shortEntityId]
  )

  return { goToSetupFlowStage }
}
