import { BillStatus } from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'
import { descend, identity, map, pipe, sort } from 'ramda'

import dayjs from 'utils/dayjs'
import { groupByKey } from 'utils/groupByKey'
import { SimBillType } from 'types/sim'

interface Props {
  link: string
  filename: string
}

export const DEFAULT_DATA_ITEM = '-'

const getGroupingKey = (item: SimBillType) => {
  const { timestamp } = item
  return String(+dayjs(timestamp).utc().local().startOf('day'))
}

const filterNonNullElements = (items: (SimBillType | null)[]): SimBillType[] =>
  items.filter(Boolean) as SimBillType[]

const sortSimBillsGroupsByKeys = (
  transactionGroups: Record<string, SimBillType[]>
) => {
  const sortedKeys = sort(descend(identity), Object.keys(transactionGroups))

  return sortedKeys.map((key) => transactionGroups[key])
}

const mapSimBills = (item: SimBillType): SimBillType => ({
  simId: item.simId,
  amount: item.amount,
  billingAccountName: item.billingAccountName,
  billingAccountUuid: item.billingAccountUuid,
  siteName: item.siteName,
  siteUuid: item.siteUuid,
  status: item.status,
  taxAmount: item.taxAmount,
  timestamp: item.timestamp,
})

export const groupSimBills = pipe(
  filterNonNullElements,
  map(mapSimBills),
  groupByKey(getGroupingKey),
  sortSimBillsGroupsByKeys
)

export const downloadData = async (link: string) => {
  const response = await fetch(link)
  const blob = await response.blob()
  return window.URL.createObjectURL(blob)
}

export const createAnchor = (url: string, filename: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename}.pdf`
  return a
}

export const downloadFile = (a: HTMLAnchorElement) => {
  a.click()
  a.remove()
}

export const renameAndDownloadPdfFile = async ({ link, filename }: Props) => {
  const url = await downloadData(link)
  const a = createAnchor(url, filename)
  downloadFile(a)
}

export const getAmountLabelBackgroundColor = (status: string) => {
  return status === BillStatus.CHARGE_FAILED ? COLOR.RED_80 : COLOR.BLUE_80
}

export const getAmountLabelTextColor = (status: string) => {
  return status === BillStatus.CHARGE_FAILED ? COLOR.BLACK : COLOR.BLUE_1000
}
