import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'

export const DropdownWrapper = styled(StyledItemsWrapper)`
  margin-top: 64px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-top: 8px;
  }
`
