import { gql } from '@apollo/client'
import {
  ContactType,
  DebitCardAccountTransactionStatusEnum,
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionStatusV2,
  DebitCardTransactionTypeV2,
  ImageSize,
} from '@npco/mp-gql-types'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { COLOR } from '@npco/zeller-design-system'
import { getContactProfilePictureUrl } from 'features/Contacts/Contact/ContactGeneral/ContactGeneral.util'
import { isNil } from 'lodash-es'
import { complement, either, isEmpty } from 'ramda'

import { ReactComponent as ZellerIconLarge } from 'assets/svg/logos/zeller-icon-large.svg'
import { ReactComponent as ZellerIconSmall } from 'assets/svg/logos/zeller-icon-small.svg'
import { getIconImageUrlBySize } from 'utils/iconHelper'
import { translate } from 'utils/translations'
import { page, translationsShared } from 'translations'

import { AVATAR_BADGE_SIZE } from '../../types/common'
import {
  GetAccountBadgeBankAccountDetailsFragment,
  GetAccountBadgeDebitCardAccountV2Fragment,
  GetBadgeOptionsDebitCardAccountTransactionFragment,
  GetBadgeOptionsDebitCardTransactionV2Fragment,
  GetContactIsSelfDebitCardAccountTransactionFragment,
  GetContactIsSelfDebitCardTransactionV2Fragment,
  GetContactNameDebitCardAccountTransactionFragment,
  GetContactNameDebitCardTransactionV2Fragment,
  GetContactOrBillerNameBadgeDebitCardAccountTransactionFragment,
  GetContactOrBillerNameBadgeDebitCardTransactionV2Fragment,
  GetContactOrMerchantBadgeDebitCardAccountTransactionFragment,
  GetContactOrMerchantBadgeDebitCardTransactionV2Fragment,
  GetContactOrPayeeAccountNameDebitCardAccountTransactionFragment,
  GetContactOrPayeeAccountNameDebitCardTransactionV2Fragment,
  GetContactOrPayeeBadgeDebitCardAccountTransactionFragment,
  GetContactOrPayeeBadgeDebitCardTransactionV2Fragment,
  GetContactOrPayerAccountBadgeDebitCardAccountTransactionFragment,
  GetContactOrPayerAccountBadgeDebitCardTransactionV2Fragment,
  GetDefaultDebitCardAccountTransactionFragment,
  GetDefaultDebitCardTransactionV2Fragment,
  GetIsOutgoingTransactionDebitCardAccountTransactionFragment,
  GetIsOutgoingTransactionDebitCardTransactionV2Fragment,
  GetIsZellerTerminalTransactionDebitCardAccountTransactionFragment,
  GetIsZellerTerminalTransactionDebitCardTransactionV2Fragment,
  GetMerchantAvatarUrlDebitCardAccountTransactionFragment,
  GetMerchantAvatarUrlDebitCardTransactionV2Fragment,
  GetMerchantOrContactNameDebitCardAccountTransactionFragment,
  GetMerchantOrContactNameDebitCardTransactionV2Fragment,
  GetMerchantOrContactOrBillerNameDebitCardAccountTransactionFragment,
  GetMerchantOrContactOrBillerNameDebitCardTransactionV2Fragment,
  GetMerchantOrContactOrPayeeAccountNameDebitCardAccountTransactionFragment,
  GetMerchantOrContactOrPayeeAccountNameDebitCardTransactionV2Fragment,
  GetMerchantOrContactOrPayerAccountNameDebitCardAccountTransactionFragment,
  GetMerchantOrContactOrPayerAccountNameDebitCardTransactionV2Fragment,
  GetPayeeAccountBadgeDebitCardAccountTransactionFragment,
  GetPayeeAccountBadgeDebitCardTransactionV2Fragment,
  GetPayeeAccountNameDebitCardAccountTransactionFragment,
  GetPayeeAccountNameDebitCardTransactionV2Fragment,
  GetPayerAccountBadgeDebitCardAccountTransactionFragment,
  GetPayerAccountBadgeDebitCardTransactionV2Fragment,
  GetPayerAccountNameDebitCardAccountTransactionFragment,
  GetPayerAccountNameDebitCardTransactionV2Fragment,
  GetTitleDebitCardAccountTransactionFragment,
  GetTitleDebitCardTransactionV2Fragment,
  GetTransactionAmountColorByStatusDebitCardAccountTransactionFragment,
  GetTransactionAmountColorByStatusDebitCardTransactionV2Fragment,
} from './DebitCardTransactions.utils.generated'

type TransactionTypeMap<T, V> = {
  [key in DebitCardTransactionTypeV2]: (
    transaction: T,
    avatarSize?: AVATAR_BADGE_SIZE
  ) => V
}

type TransactionTypeMapV3<T, V> = {
  [key in DebitCardAccountTransactionTypeEnum]: (
    transaction: T,
    avatarSize?: AVATAR_BADGE_SIZE
  ) => V
}

const FALLBACK_NAME = translate('shared.zeller')
const getEmptyString = () => ''

const getContactName = (
  transaction:
    | GetContactNameDebitCardTransactionV2Fragment
    | GetContactNameDebitCardAccountTransactionFragment
) => {
  if (transaction.contact) {
    return transaction.contact.contactType === ContactType.BUSINESS
      ? transaction.contact.businessName
      : `${transaction.contact.firstName} ${transaction.contact.lastName}`
  }

  return null
}

getContactName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      contact {
        contactType
        businessName
        firstName
        lastName
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      contact {
        contactType
        businessName
        firstName
        lastName
      }
    }
  `,
}

export const getContactIsSelf = (
  transaction:
    | GetContactIsSelfDebitCardTransactionV2Fragment
    | GetContactIsSelfDebitCardAccountTransactionFragment
) => transaction.contact?.isSelf

getContactIsSelf.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactIsSelfDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      contact {
        isSelf
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactIsSelfDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      contact {
        isSelf
      }
    }
  `,
}

export const getMerchantOrContactName = (
  transaction:
    | GetMerchantOrContactNameDebitCardTransactionV2Fragment
    | GetMerchantOrContactNameDebitCardAccountTransactionFragment
) => transaction.merchant?.name || getContactName(transaction) || ''

getMerchantOrContactName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetMerchantOrContactNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      merchant {
        name
      }
      ...GetContactNameDebitCardTransactionV2Fragment
    }

    ${getContactName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetMerchantOrContactNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      merchant {
        name
      }
      ...GetContactNameDebitCardAccountTransactionFragment
    }

    ${getContactName.fragments.DebitCardAccountTransaction}
  `,
}

export const getMerchantAvatarUrl = (
  transaction:
    | GetMerchantAvatarUrlDebitCardTransactionV2Fragment
    | GetMerchantAvatarUrlDebitCardAccountTransactionFragment
) => getIconImageUrlBySize(transaction.merchant?.icon, ImageSize.Native)

getMerchantAvatarUrl.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetMerchantAvatarUrlDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      merchant {
        icon {
          ...GetIconImageUrlBySizeIconFragment
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetMerchantAvatarUrlDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      merchant {
        icon {
          ...GetIconImageUrlBySizeIconFragment
        }
      }
    }
  `,
}

export const getPayeeAccountName = (
  transaction:
    | GetPayeeAccountNameDebitCardTransactionV2Fragment
    | GetPayeeAccountNameDebitCardAccountTransactionFragment
) => transaction.payeeDetails?.accountDetails?.name || ''

getPayeeAccountName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayeeAccountNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payeeDetails {
        accountDetails {
          name
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayeeAccountNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payeeDetails {
        accountDetails {
          name
        }
      }
    }
  `,
}

export const getPayerAccountName = (
  transaction:
    | GetPayerAccountNameDebitCardTransactionV2Fragment
    | GetPayerAccountNameDebitCardAccountTransactionFragment
) => transaction.payerDetails?.accountDetails?.name || ''

getPayerAccountName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayerAccountNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payerDetails {
        accountDetails {
          name
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayerAccountNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payerDetails {
        accountDetails {
          name
        }
      }
    }
  `,
}

export const getMerchantOrContactOrPayerAccountName = (
  transaction:
    | GetMerchantOrContactOrPayerAccountNameDebitCardTransactionV2Fragment
    | GetMerchantOrContactOrPayerAccountNameDebitCardAccountTransactionFragment
) => {
  const merchantName = transaction.merchant?.name
  const contactName = getContactName(transaction)
  const payeeAccountName = getPayerAccountName(transaction)

  if (merchantName) {
    return merchantName
  }

  if (contactName) {
    return contactName
  }

  return payeeAccountName ?? ''
}

getMerchantOrContactOrPayerAccountName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetMerchantOrContactOrPayerAccountNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetPayerAccountNameDebitCardTransactionV2Fragment
      ...GetContactNameDebitCardTransactionV2Fragment
      merchant {
        name
      }
    }
    ${getContactName.fragments.DebitCardTransactionV2}
    ${getPayerAccountName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetMerchantOrContactOrPayerAccountNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetPayerAccountNameDebitCardAccountTransactionFragment
      ...GetContactNameDebitCardAccountTransactionFragment
      merchant {
        name
      }
    }
    ${getContactName.fragments.DebitCardAccountTransaction}
    ${getPayerAccountName.fragments.DebitCardAccountTransaction}
  `,
}

export const getMerchantOrContactOrPayeeAccountName = (
  transaction:
    | GetMerchantOrContactOrPayeeAccountNameDebitCardTransactionV2Fragment
    | GetMerchantOrContactOrPayeeAccountNameDebitCardAccountTransactionFragment
) => {
  const merchantName = transaction.merchant?.name
  const contactName = getContactName(transaction)
  const payeeAccountName = getPayeeAccountName(transaction)

  if (merchantName) {
    return merchantName
  }

  if (contactName) {
    return contactName
  }

  return payeeAccountName ?? ''
}

getMerchantOrContactOrPayeeAccountName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetMerchantOrContactOrPayeeAccountNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetContactNameDebitCardTransactionV2Fragment
      ...GetPayeeAccountNameDebitCardTransactionV2Fragment
      merchant {
        name
      }
    }

    ${getContactName.fragments.DebitCardTransactionV2}
    ${getPayeeAccountName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetMerchantOrContactOrPayeeAccountNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetContactNameDebitCardAccountTransactionFragment
      ...GetPayeeAccountNameDebitCardAccountTransactionFragment
      merchant {
        name
      }
    }

    ${getContactName.fragments.DebitCardAccountTransaction}
    ${getPayeeAccountName.fragments.DebitCardAccountTransaction}
  `,
}

export const getMerchantOrContactOrBillerName = (
  transaction:
    | GetMerchantOrContactOrBillerNameDebitCardTransactionV2Fragment
    | GetMerchantOrContactOrBillerNameDebitCardAccountTransactionFragment
) => {
  const merchantName = transaction.merchant?.name
  const contactName = getContactName(transaction)
  const billerName = transaction.payeeDetails?.bpayDetails?.billerName

  if (merchantName) {
    return merchantName
  }

  if (contactName) {
    return contactName
  }

  return billerName ?? ''
}

getMerchantOrContactOrBillerName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetMerchantOrContactOrBillerNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetContactNameDebitCardTransactionV2Fragment
      merchant {
        name
      }
      payeeDetails {
        bpayDetails {
          billerName
        }
      }
    }
    ${getContactName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetMerchantOrContactOrBillerNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetContactNameDebitCardAccountTransactionFragment
      merchant {
        name
      }
      payeeDetails {
        bpayDetails {
          billerName
        }
      }
    }
    ${getContactName.fragments.DebitCardAccountTransaction}
  `,
}

export const getContactOrPayeeAccountName = (
  transaction:
    | GetContactOrPayeeAccountNameDebitCardTransactionV2Fragment
    | GetContactOrPayeeAccountNameDebitCardAccountTransactionFragment
) => {
  const contactName = getContactName(transaction)
  const payeeAccountName = getPayeeAccountName(transaction)

  if (contactName) {
    return contactName
  }

  return payeeAccountName ?? ''
}

getContactOrPayeeAccountName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactOrPayeeAccountNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetContactNameDebitCardTransactionV2Fragment
      ...GetPayeeAccountNameDebitCardTransactionV2Fragment
    }

    ${getContactName.fragments.DebitCardTransactionV2}
    ${getPayeeAccountName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactOrPayeeAccountNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetContactNameDebitCardAccountTransactionFragment
      ...GetPayeeAccountNameDebitCardAccountTransactionFragment
    }

    ${getContactName.fragments.DebitCardAccountTransaction}
    ${getPayeeAccountName.fragments.DebitCardAccountTransaction}
  `,
}

export const getIsZellerTerminalTransaction = (
  transaction:
    | GetIsZellerTerminalTransactionDebitCardTransactionV2Fragment
    | GetIsZellerTerminalTransactionDebitCardAccountTransactionFragment
) => {
  if (transaction.__typename === 'DebitCardAccountTransaction') {
    return [
      DebitCardAccountTransactionTypeEnum.DEPOSIT,
      DebitCardAccountTransactionTypeEnum.WITHDRAWAL,
      DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT,
      DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL,
    ].includes(transaction.type)
  }
  return [
    DebitCardTransactionTypeV2.DEPOSIT,
    DebitCardTransactionTypeV2.WITHDRAWAL,
    DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT,
    DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL,
  ].includes(transaction.type)
}

getIsZellerTerminalTransaction.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetIsZellerTerminalTransactionDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      type
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetIsZellerTerminalTransactionDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      type
    }
  `,
}

const TRANSACTION_TYPE_TO_TITLE: TransactionTypeMap<
  GetTitleDebitCardTransactionV2Fragment,
  string
> = {
  [DebitCardTransactionTypeV2.ATM_IN]: getEmptyString,
  [DebitCardTransactionTypeV2.ATM_OUT]: getEmptyString,
  [DebitCardTransactionTypeV2.BPAY_IN]: getEmptyString,
  [DebitCardTransactionTypeV2.BPAY_OUT]: getMerchantOrContactOrBillerName,
  [DebitCardTransactionTypeV2.DEPOSIT]: getPayerAccountName,
  [DebitCardTransactionTypeV2.DE_IN]: getMerchantOrContactOrPayerAccountName,
  [DebitCardTransactionTypeV2.DE_OUT]: getMerchantOrContactOrPayeeAccountName,
  [DebitCardTransactionTypeV2.DE_OUT_RETURN]:
    getMerchantOrContactOrPayeeAccountName,
  [DebitCardTransactionTypeV2.NPP_IN]: getMerchantOrContactOrPayerAccountName,
  [DebitCardTransactionTypeV2.NPP_IN_RETURN]:
    getMerchantOrContactOrPayerAccountName,
  [DebitCardTransactionTypeV2.NPP_OUT]: getMerchantOrContactOrPayeeAccountName,
  [DebitCardTransactionTypeV2.NPP_OUT_RETURN]:
    getMerchantOrContactOrPayeeAccountName,
  [DebitCardTransactionTypeV2.PURCHASE]: getMerchantOrContactName,
  [DebitCardTransactionTypeV2.PURCHASE_CNP]: getMerchantOrContactName,
  [DebitCardTransactionTypeV2.REFUND]: getMerchantOrContactName,
  [DebitCardTransactionTypeV2.REFUND_CNP]: getMerchantOrContactName,
  [DebitCardTransactionTypeV2.TRANSFER_IN]: getPayerAccountName,
  [DebitCardTransactionTypeV2.TRANSFER_OUT]: getPayeeAccountName,
  [DebitCardTransactionTypeV2.WITHDRAWAL]: getPayeeAccountName,
  [DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT]: getEmptyString,
  [DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL]: getEmptyString,
  [DebitCardTransactionTypeV2.INTEREST]: getPayerAccountName,
  [DebitCardTransactionTypeV2.DDA_OUT]: getContactOrPayeeAccountName,
  [DebitCardTransactionTypeV2.ACQUIRING_REFUND]: getEmptyString,
  [DebitCardTransactionTypeV2.ACQUIRING_REFUND_RETURN]: getEmptyString,
}

const TRANSACTION_TYPE_TO_TITLE_V3: TransactionTypeMapV3<
  GetTitleDebitCardAccountTransactionFragment,
  string
> = {
  [DebitCardAccountTransactionTypeEnum.ATM_IN]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.ATM_OUT]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.BPAY_IN]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.BPAY_OUT]:
    getMerchantOrContactOrBillerName,
  [DebitCardAccountTransactionTypeEnum.DEPOSIT]: getPayerAccountName,
  [DebitCardAccountTransactionTypeEnum.DE_IN]:
    getMerchantOrContactOrPayerAccountName,
  [DebitCardAccountTransactionTypeEnum.DE_OUT]:
    getMerchantOrContactOrPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.DE_OUT_RETURN]:
    getMerchantOrContactOrPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.NPP_IN]:
    getMerchantOrContactOrPayerAccountName,
  [DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN]:
    getMerchantOrContactOrPayerAccountName,
  [DebitCardAccountTransactionTypeEnum.NPP_OUT]:
    getMerchantOrContactOrPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.NPP_OUT_RETURN]:
    getMerchantOrContactOrPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.PURCHASE]: getMerchantOrContactName,
  [DebitCardAccountTransactionTypeEnum.PURCHASE_CNP]: getMerchantOrContactName,
  [DebitCardAccountTransactionTypeEnum.REFUND]: getMerchantOrContactName,
  [DebitCardAccountTransactionTypeEnum.REFUND_CNP]: getMerchantOrContactName,
  [DebitCardAccountTransactionTypeEnum.TRANSFER_IN]: getPayerAccountName,
  [DebitCardAccountTransactionTypeEnum.TRANSFER_OUT]: getPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.WITHDRAWAL]: getPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.DDA_OUT]: getContactOrPayeeAccountName,
  [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND_RETURN]: getEmptyString,
}

export const getTitle = (
  transaction:
    | GetTitleDebitCardTransactionV2Fragment
    | GetTitleDebitCardAccountTransactionFragment
) => {
  if (getIsZellerTerminalTransaction(transaction)) {
    return page.transactionDetails.zellerTerminalTransactionTitle
  }

  if (transaction.__typename === 'DebitCardAccountTransaction') {
    return TRANSACTION_TYPE_TO_TITLE_V3[transaction.type](transaction)
  }

  return TRANSACTION_TYPE_TO_TITLE[transaction.type](transaction)
}

getTitle.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetTitleDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      ...GetIsZellerTerminalTransactionDebitCardTransactionV2Fragment
      ...GetPayerAccountNameDebitCardTransactionV2Fragment
      ...GetPayeeAccountNameDebitCardTransactionV2Fragment
      ...GetMerchantOrContactNameDebitCardTransactionV2Fragment
      ...GetMerchantOrContactOrPayeeAccountNameDebitCardTransactionV2Fragment
      ...GetMerchantOrContactOrBillerNameDebitCardTransactionV2Fragment
    }

    ${getIsZellerTerminalTransaction.fragments.DebitCardTransactionV2}
    ${getPayerAccountName.fragments.DebitCardTransactionV2}
    ${getPayeeAccountName.fragments.DebitCardTransactionV2}
    ${getMerchantOrContactName.fragments.DebitCardTransactionV2}
    ${getMerchantOrContactOrPayeeAccountName.fragments.DebitCardTransactionV2}
    ${getMerchantOrContactOrBillerName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetTitleDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      ...GetIsZellerTerminalTransactionDebitCardAccountTransactionFragment
      ...GetPayerAccountNameDebitCardAccountTransactionFragment
      ...GetPayeeAccountNameDebitCardAccountTransactionFragment
      ...GetMerchantOrContactNameDebitCardAccountTransactionFragment
      ...GetMerchantOrContactOrPayeeAccountNameDebitCardAccountTransactionFragment
      ...GetMerchantOrContactOrBillerNameDebitCardAccountTransactionFragment
    }

    ${getIsZellerTerminalTransaction.fragments.DebitCardAccountTransaction}
    ${getPayerAccountName.fragments.DebitCardAccountTransaction}
    ${getPayeeAccountName.fragments.DebitCardAccountTransaction}
    ${getMerchantOrContactName.fragments.DebitCardAccountTransaction}
    ${getMerchantOrContactOrPayeeAccountName.fragments
      .DebitCardAccountTransaction}
    ${getMerchantOrContactOrBillerName.fragments.DebitCardAccountTransaction}
  `,
}

const getDefault = (
  transaction:
    | GetDefaultDebitCardTransactionV2Fragment
    | GetDefaultDebitCardAccountTransactionFragment,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => (
  <IconAvatar
    icon={null}
    badgeSize={avatarSize}
    defaultLetter={getTitle(transaction)}
  />
)

getDefault.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetDefaultDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetTitleDebitCardTransactionV2Fragment
    }

    ${getTitle.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetDefaultDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetTitleDebitCardAccountTransactionFragment
    }

    ${getTitle.fragments.DebitCardAccountTransaction}
  `,
}

const getZellerBadge = (
  transaction:
    | GetContactOrMerchantBadgeDebitCardTransactionV2Fragment
    | GetContactOrMerchantBadgeDebitCardAccountTransactionFragment,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => {
  if (avatarSize === AVATAR_BADGE_SIZE.SMALL) {
    return <ZellerIconSmall data-testid="zeller-logo-avatar" />
  }
  return <ZellerIconLarge data-testid="zeller-logo-avatar" />
}

const getContactOrMerchantBadge = (
  transaction:
    | GetContactOrMerchantBadgeDebitCardTransactionV2Fragment
    | GetContactOrMerchantBadgeDebitCardAccountTransactionFragment,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => {
  const contactAvatarUrl = getContactProfilePictureUrl(transaction.contact)
  const contactColor = transaction.contact?.icon?.colour

  const merchantAvatarUrl = getMerchantAvatarUrl(transaction)
  const merchantColor = transaction.merchant?.icon?.colour

  const avatarUrl = contactAvatarUrl || merchantAvatarUrl
  const avatarColor = contactColor ?? merchantColor ?? undefined
  const avatarLetter =
    transaction.contact?.icon?.letter ?? transaction.merchant?.icon?.letter

  return (
    <IconAvatar
      icon={null}
      badgeSize={avatarSize}
      defaultLetter={avatarLetter ?? (getTitle(transaction) || FALLBACK_NAME)}
      defaultBgColor={avatarUrl ? 'WHITE' : avatarColor}
      defaultUrl={avatarUrl ?? undefined}
    />
  )
}

getContactOrMerchantBadge.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactOrMerchantBadgeDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      merchant {
        icon {
          colour
          letter
        }
      }
      ...GetTitleDebitCardTransactionV2Fragment
      ...GetMerchantAvatarUrlDebitCardTransactionV2Fragment
    }

    ${getContactProfilePictureUrl.fragments.Contact}
    ${getTitle.fragments.DebitCardTransactionV2}
    ${getMerchantAvatarUrl.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactOrMerchantBadgeDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      merchant {
        icon {
          colour
          letter
        }
      }
      ...GetTitleDebitCardAccountTransactionFragment
      ...GetMerchantAvatarUrlDebitCardAccountTransactionFragment
    }

    ${getContactProfilePictureUrl.fragments.Contact}
    ${getTitle.fragments.DebitCardAccountTransaction}
    ${getMerchantAvatarUrl.fragments.DebitCardAccountTransaction}
  `,
}

const getContactOrPayeeBadge = (
  transaction:
    | GetContactOrPayeeBadgeDebitCardTransactionV2Fragment
    | GetContactOrPayeeBadgeDebitCardAccountTransactionFragment,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => {
  const contactAvatarUrl = getContactProfilePictureUrl(transaction.contact)
  const contactColor = transaction.contact?.icon?.colour

  const avatarUrl = contactAvatarUrl ?? undefined
  const avatarColor = contactColor ?? undefined
  const avatarLetter =
    transaction.contact?.icon?.letter ??
    getContactName(transaction) ??
    transaction.payeeDetails?.accountDetails?.name

  return (
    <IconAvatar
      icon={null}
      badgeSize={avatarSize}
      defaultLetter={avatarLetter ?? (getTitle(transaction) || FALLBACK_NAME)}
      defaultBgColor={avatarUrl ? 'WHITE' : avatarColor}
      defaultUrl={avatarUrl}
    />
  )
}

getContactOrPayeeBadge.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactOrPayeeBadgeDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      payeeDetails {
        accountDetails {
          name
        }
      }
      ...GetContactNameDebitCardTransactionV2Fragment
      ...GetTitleDebitCardTransactionV2Fragment
    }

    ${getContactProfilePictureUrl.fragments.Contact}
    ${getContactName.fragments.DebitCardTransactionV2}
    ${getTitle.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactOrPayeeBadgeDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      payeeDetails {
        accountDetails {
          name
        }
      }
      ...GetContactNameDebitCardAccountTransactionFragment
      ...GetTitleDebitCardAccountTransactionFragment
    }

    ${getContactProfilePictureUrl.fragments.Contact}
    ${getContactName.fragments.DebitCardAccountTransaction}
    ${getTitle.fragments.DebitCardAccountTransaction}
  `,
}

export const getContactOrBillerNameBadge = (
  transaction:
    | GetContactOrBillerNameBadgeDebitCardTransactionV2Fragment
    | GetContactOrBillerNameBadgeDebitCardAccountTransactionFragment,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => {
  const contactAvatarUrl = getContactProfilePictureUrl(transaction.contact)
  const contactColor = transaction.contact?.icon?.colour

  const avatarUrl = contactAvatarUrl
  const avatarColor = contactColor ?? undefined
  const avatarLetter =
    transaction.contact?.icon?.letter ??
    getContactName(transaction) ??
    transaction.payeeDetails?.bpayDetails?.billerName

  return (
    <IconAvatar
      icon={null}
      badgeSize={avatarSize}
      defaultLetter={avatarLetter ?? FALLBACK_NAME}
      defaultBgColor={avatarUrl ? 'WHITE' : avatarColor}
      defaultUrl={avatarUrl}
    />
  )
}

getContactOrBillerNameBadge.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactOrBillerNameBadgeDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetContactNameDebitCardTransactionV2Fragment
      ...GetTitleDebitCardTransactionV2Fragment
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      payeeDetails {
        bpayDetails {
          billerName
        }
      }
    }

    ${getContactName.fragments.DebitCardTransactionV2}
    ${getTitle.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactOrBillerNameBadgeDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetContactNameDebitCardAccountTransactionFragment
      ...GetTitleDebitCardAccountTransactionFragment
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      payeeDetails {
        bpayDetails {
          billerName
        }
      }
    }

    ${getContactName.fragments.DebitCardAccountTransaction}
    ${getTitle.fragments.DebitCardAccountTransaction}
  `,
}

const getAccountBadge = (
  accountDetails?: GetAccountBadgeBankAccountDetailsFragment | null,
  debitCardAccount?: GetAccountBadgeDebitCardAccountV2Fragment | null,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => {
  return (
    <IconAvatar
      icon={debitCardAccount?.icon ?? null}
      defaultLetter={
        debitCardAccount?.icon?.letter ?? accountDetails?.name ?? ''
      }
      badgeSize={avatarSize}
    />
  )
}

getAccountBadge.fragments = {
  BankAccountDetails: gql`
    fragment GetAccountBadgeBankAccountDetailsFragment on BankAccountDetails {
      name
    }
  `,
  DebitCardAccountV2: gql`
    fragment GetAccountBadgeDebitCardAccountV2Fragment on DebitCardAccountV2 {
      icon {
        ...IconAvatarIconFragment
      }
    }

    ${IconAvatar.fragments.Icon}
  `,
}

const getPayeeAccountBadge = (
  transaction:
    | GetPayeeAccountBadgeDebitCardTransactionV2Fragment
    | GetPayeeAccountBadgeDebitCardAccountTransactionFragment,
  avatarSize?: AVATAR_BADGE_SIZE
) => {
  return getAccountBadge(
    transaction.payeeDetails?.accountDetails,
    transaction.payeeDetails?.debitCardAccount,
    avatarSize
  )
}

getPayeeAccountBadge.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayeeAccountBadgeDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payeeDetails {
        accountDetails {
          ...GetAccountBadgeBankAccountDetailsFragment
        }
        debitCardAccount {
          ...GetAccountBadgeDebitCardAccountV2Fragment
        }
      }
    }

    ${getAccountBadge.fragments.BankAccountDetails}
    ${getAccountBadge.fragments.DebitCardAccountV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayeeAccountBadgeDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payeeDetails {
        accountDetails {
          ...GetAccountBadgeBankAccountDetailsFragment
        }
        debitCardAccount {
          ...GetAccountBadgeDebitCardAccountV2Fragment
        }
      }
    }

    ${getAccountBadge.fragments.BankAccountDetails}
    ${getAccountBadge.fragments.DebitCardAccountV2}
  `,
}

const getPayerAccountBadge = (
  transaction:
    | GetPayerAccountBadgeDebitCardTransactionV2Fragment
    | GetPayerAccountBadgeDebitCardAccountTransactionFragment,
  avatarSize?: AVATAR_BADGE_SIZE
) => {
  return getAccountBadge(
    transaction.payerDetails?.accountDetails,
    transaction.payerDetails?.debitCardAccount,
    avatarSize
  )
}

getPayerAccountBadge.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayerAccountBadgeDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payerDetails {
        accountDetails {
          ...GetAccountBadgeBankAccountDetailsFragment
        }
        debitCardAccount {
          ...GetAccountBadgeDebitCardAccountV2Fragment
        }
      }
    }

    ${getAccountBadge.fragments.BankAccountDetails}
    ${getAccountBadge.fragments.DebitCardAccountV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayerAccountBadgeDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payerDetails {
        accountDetails {
          ...GetAccountBadgeBankAccountDetailsFragment
        }
        debitCardAccount {
          ...GetAccountBadgeDebitCardAccountV2Fragment
        }
      }
    }

    ${getAccountBadge.fragments.BankAccountDetails}
    ${getAccountBadge.fragments.DebitCardAccountV2}
  `,
}

export const getContactOrPayerAccountBadge = (
  transaction:
    | GetContactOrPayerAccountBadgeDebitCardTransactionV2Fragment
    | GetContactOrPayerAccountBadgeDebitCardAccountTransactionFragment,
  avatarSize: AVATAR_BADGE_SIZE = AVATAR_BADGE_SIZE.SMALL
) => {
  if (!transaction.contact) {
    return (
      <IconAvatar
        icon={null}
        badgeSize={avatarSize}
        defaultLetter={translationsShared.DEFAULT.questionMark}
      />
    )
  }

  const contactAvatarUrl = getContactProfilePictureUrl(transaction.contact)
  const contactColor = transaction.contact?.icon?.colour
  const payerBadgeColor =
    transaction.payerDetails?.debitCardAccount?.icon?.colour

  const avatarUrl = contactAvatarUrl || null
  const avatarColor = contactColor ?? payerBadgeColor ?? undefined
  const avatarLetter =
    transaction.contact?.icon?.letter ??
    getContactName(transaction) ??
    transaction.payerDetails?.debitCardAccount?.icon?.letter ??
    transaction.payerDetails?.accountDetails?.name

  return (
    <IconAvatar
      icon={null}
      badgeSize={avatarSize}
      defaultLetter={avatarLetter ?? (getTitle(transaction) || FALLBACK_NAME)}
      defaultBgColor={avatarUrl ? 'WHITE' : avatarColor}
      defaultUrl={avatarUrl ?? undefined}
    />
  )
}

getContactOrPayerAccountBadge.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetContactOrPayerAccountBadgeDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetTitleDebitCardTransactionV2Fragment
      ...GetContactNameDebitCardTransactionV2Fragment
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      payerDetails {
        accountDetails {
          name
        }
        debitCardAccount {
          icon {
            colour
            letter
          }
        }
      }
    }
    ${getContactProfilePictureUrl.fragments.Contact}
    ${getTitle.fragments.DebitCardTransactionV2}
    ${getContactName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetContactOrPayerAccountBadgeDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetTitleDebitCardAccountTransactionFragment
      ...GetContactNameDebitCardAccountTransactionFragment
      contact {
        ...GetContactProfilePictureUrlContactFragment
        icon {
          colour
          letter
        }
      }
      payerDetails {
        accountDetails {
          name
        }
        debitCardAccount {
          icon {
            colour
            letter
          }
        }
      }
    }
    ${getContactProfilePictureUrl.fragments.Contact}
    ${getTitle.fragments.DebitCardAccountTransaction}
    ${getContactName.fragments.DebitCardAccountTransaction}
  `,
}

const TRANSACTION_TYPE_TO_BADGE: TransactionTypeMap<
  GetBadgeOptionsDebitCardTransactionV2Fragment,
  JSX.Element
> = {
  [DebitCardTransactionTypeV2.ATM_IN]: getDefault,
  [DebitCardTransactionTypeV2.ATM_OUT]: getDefault,
  [DebitCardTransactionTypeV2.BPAY_IN]: getDefault,
  [DebitCardTransactionTypeV2.BPAY_OUT]: getContactOrBillerNameBadge,
  [DebitCardTransactionTypeV2.DEPOSIT]: getZellerBadge,
  [DebitCardTransactionTypeV2.DE_IN]: getContactOrPayerAccountBadge,
  [DebitCardTransactionTypeV2.DE_OUT]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.DE_OUT_RETURN]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.NPP_IN]: getContactOrPayerAccountBadge,
  [DebitCardTransactionTypeV2.NPP_IN_RETURN]: getContactOrPayerAccountBadge,
  [DebitCardTransactionTypeV2.NPP_OUT]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.NPP_OUT_RETURN]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.PURCHASE]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.PURCHASE_CNP]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.REFUND]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.REFUND_CNP]: getContactOrMerchantBadge,
  [DebitCardTransactionTypeV2.TRANSFER_IN]: getPayerAccountBadge,
  [DebitCardTransactionTypeV2.TRANSFER_OUT]: getPayeeAccountBadge,
  [DebitCardTransactionTypeV2.WITHDRAWAL]: getZellerBadge,
  [DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT]: getZellerBadge,
  [DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL]: getZellerBadge,
  [DebitCardTransactionTypeV2.INTEREST]: getZellerBadge,
  [DebitCardTransactionTypeV2.DDA_OUT]: getContactOrPayeeBadge,
  [DebitCardTransactionTypeV2.ACQUIRING_REFUND]: getDefault,
  [DebitCardTransactionTypeV2.ACQUIRING_REFUND_RETURN]: getDefault,
}

const TRANSACTION_TYPE_TO_BADGE_V3: TransactionTypeMapV3<
  GetBadgeOptionsDebitCardAccountTransactionFragment,
  JSX.Element
> = {
  [DebitCardAccountTransactionTypeEnum.ATM_IN]: getDefault,
  [DebitCardAccountTransactionTypeEnum.ATM_OUT]: getDefault,
  [DebitCardAccountTransactionTypeEnum.BPAY_IN]: getDefault,
  [DebitCardAccountTransactionTypeEnum.BPAY_OUT]: getContactOrBillerNameBadge,
  [DebitCardAccountTransactionTypeEnum.DEPOSIT]: getZellerBadge,
  [DebitCardAccountTransactionTypeEnum.DE_IN]: getContactOrPayerAccountBadge,
  [DebitCardAccountTransactionTypeEnum.DE_OUT]: getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.DE_OUT_RETURN]:
    getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.NPP_IN]: getContactOrPayerAccountBadge,
  [DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN]:
    getContactOrPayerAccountBadge,
  [DebitCardAccountTransactionTypeEnum.NPP_OUT]: getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.NPP_OUT_RETURN]:
    getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.PURCHASE]: getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.PURCHASE_CNP]: getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.REFUND]: getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.REFUND_CNP]: getContactOrMerchantBadge,
  [DebitCardAccountTransactionTypeEnum.TRANSFER_IN]: getPayerAccountBadge,
  [DebitCardAccountTransactionTypeEnum.TRANSFER_OUT]: getPayeeAccountBadge,
  [DebitCardAccountTransactionTypeEnum.WITHDRAWAL]: getZellerBadge,
  [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT]: getZellerBadge,
  [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL]: getZellerBadge,
  [DebitCardAccountTransactionTypeEnum.DDA_OUT]: getContactOrPayeeBadge,
  [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND]: getDefault,
  [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND_RETURN]: getDefault,
}

export const getBadgeOptions = (
  transaction:
    | GetBadgeOptionsDebitCardTransactionV2Fragment
    | GetBadgeOptionsDebitCardAccountTransactionFragment,
  avatarSize?: AVATAR_BADGE_SIZE
) => {
  if (transaction.__typename === 'DebitCardAccountTransaction') {
    return TRANSACTION_TYPE_TO_BADGE_V3[transaction.type](
      transaction,
      avatarSize
    )
  }
  return TRANSACTION_TYPE_TO_BADGE[transaction.type](transaction, avatarSize)
}

getBadgeOptions.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetBadgeOptionsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      type
      ...GetDefaultDebitCardTransactionV2Fragment
      ...GetPayerAccountBadgeDebitCardTransactionV2Fragment
      ...GetPayeeAccountBadgeDebitCardTransactionV2Fragment
      ...GetContactOrMerchantBadgeDebitCardTransactionV2Fragment
    }

    ${getDefault.fragments.DebitCardTransactionV2}
    ${getPayerAccountBadge.fragments.DebitCardTransactionV2}
    ${getPayeeAccountBadge.fragments.DebitCardTransactionV2}
    ${getContactOrMerchantBadge.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetBadgeOptionsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      type
      ...GetDefaultDebitCardAccountTransactionFragment
      ...GetPayerAccountBadgeDebitCardAccountTransactionFragment
      ...GetPayeeAccountBadgeDebitCardAccountTransactionFragment
      ...GetContactOrMerchantBadgeDebitCardAccountTransactionFragment
    }

    ${getDefault.fragments.DebitCardAccountTransaction}
    ${getPayerAccountBadge.fragments.DebitCardAccountTransaction}
    ${getPayeeAccountBadge.fragments.DebitCardAccountTransaction}
    ${getContactOrMerchantBadge.fragments.DebitCardAccountTransaction}
  `,
}

const OUTGOING_TRANSACTIONS = [
  DebitCardTransactionTypeV2.DE_OUT,
  DebitCardTransactionTypeV2.PURCHASE,
  DebitCardTransactionTypeV2.PURCHASE_CNP,
  DebitCardTransactionTypeV2.TRANSFER_OUT,
  DebitCardTransactionTypeV2.WITHDRAWAL,
  DebitCardTransactionTypeV2.NPP_OUT,
  DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL,
  DebitCardTransactionTypeV2.BPAY_OUT,
  DebitCardTransactionTypeV2.NPP_IN_RETURN,
  DebitCardTransactionTypeV2.DDA_OUT,
]

const OUTGOING_TRANSACTIONS_V3 = [
  DebitCardAccountTransactionTypeEnum.DE_OUT,
  DebitCardAccountTransactionTypeEnum.PURCHASE,
  DebitCardAccountTransactionTypeEnum.PURCHASE_CNP,
  DebitCardAccountTransactionTypeEnum.TRANSFER_OUT,
  DebitCardAccountTransactionTypeEnum.WITHDRAWAL,
  DebitCardAccountTransactionTypeEnum.NPP_OUT,
  DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL,
  DebitCardAccountTransactionTypeEnum.BPAY_OUT,
  DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN,
  DebitCardAccountTransactionTypeEnum.DDA_OUT,
]

export const getIsOutgoingTransaction = (
  transaction:
    | GetIsOutgoingTransactionDebitCardTransactionV2Fragment
    | GetIsOutgoingTransactionDebitCardAccountTransactionFragment
) => {
  if (transaction.__typename === 'DebitCardAccountTransaction') {
    return OUTGOING_TRANSACTIONS_V3.includes(transaction.type)
  }
  if (transaction.__typename === 'DebitCardTransactionV2') {
    return OUTGOING_TRANSACTIONS.includes(transaction.type)
  }
  return false
}

getIsOutgoingTransaction.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetIsOutgoingTransactionDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      type
    }
  `,
  DebitCardAccountTransaction: gql`
    fragment GetIsOutgoingTransactionDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      type
    }
  `,
}

export const hasValue = complement(either(isEmpty, isNil))

const UNSUCCESSFUL_TRANSACTION = [
  DebitCardTransactionStatusV2.CANCELLED,
  DebitCardTransactionStatusV2.DECLINED,
]

const UNSUCCESSFUL_TRANSACTION_V3 = [
  DebitCardAccountTransactionStatusEnum.CANCELLED,
  DebitCardAccountTransactionStatusEnum.DECLINED,
]

export const MAX_FILES = 10

export const getTransactionAmountColorByStatus = (
  transaction:
    | GetTransactionAmountColorByStatusDebitCardTransactionV2Fragment
    | GetTransactionAmountColorByStatusDebitCardAccountTransactionFragment
) => {
  if (
    transaction.__typename === 'DebitCardTransactionV2' &&
    UNSUCCESSFUL_TRANSACTION.includes(transaction.status)
  ) {
    return COLOR.RED_1000
  }

  if (
    transaction.__typename === 'DebitCardAccountTransaction' &&
    UNSUCCESSFUL_TRANSACTION_V3.includes(transaction.status)
  ) {
    return COLOR.RED_1000
  }

  if (!getIsOutgoingTransaction(transaction)) {
    return COLOR.GREEN_1600
  }

  return COLOR.BLACK
}

getTransactionAmountColorByStatus.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetTransactionAmountColorByStatusDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      status
      ...GetIsOutgoingTransactionDebitCardTransactionV2Fragment
    }

    ${getIsOutgoingTransaction.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetTransactionAmountColorByStatusDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      status
      ...GetIsOutgoingTransactionDebitCardAccountTransactionFragment
    }

    ${getIsOutgoingTransaction.fragments.DebitCardAccountTransaction}
  `,
}
