import { createTranslations } from '@npco/utils-translations'

export const contactTransferModalTranslations = createTranslations({
  primaryButtonLabel: 'Pay Now',
  from: 'From',
  accountNumber: 'Account Number',
  to: 'To',
  accountName: 'Account Name',
  bsb: 'BSB',
  description: 'Description',
  reference: 'Reference',
  transferDetails: 'Transfer Details',
  transferErrorMessage: 'Your transfer was unsuccessful. Please try again.',
  maxDailyLimit:
    "This amount exceeds your Daily Maximum Limit. Please contact Zeller Support if you'd like to raise your limit.",
  deFallbackMessageHeader: 'Unable to process an instant funds transfer',
  deFallbackMessage:
    'Your recipient’s account details may be incorrect, or their bank may be experiencing issues receiving real-time fund transfers. By processing this transfer, your funds may take 1-2 business days to arrive. Alternatively, please attempt the transfer again later.',
  recipientDetails: 'Recipient Details',
})
