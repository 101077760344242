import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  noActiveCardsTitle: 'You have no active Cards',
  noActiveCardsImageAlt: 'Upright hand holding three splayed out debit cards',
  noActiveCardsDescriptionManager:
    'If you require a card, request an Admin to create one for you.',
  noActiveCardsDescriptionAdmin:
    'Create a virtual Card and spend instantly, or order a physical Card and spend in-store.',
  noActiveCardsOnDeliveryTitle: 'Your card is on its way!',
  noActiveCardsOnDeliveryDescription:
    'Once you receive your card, you can activate it below.',
  noActiveCardsOnDeliveryImageAlt: 'Delivery truck on the road',
})
