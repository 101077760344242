import { H6 } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'

import {
  StyledDot,
  StyledTooltipHeader,
  StyledTooltipSection,
} from '../PerformanceChart.styled'

interface Props {
  label: React.ReactNode
  total: number
  color: string
}

export const PerformanceTooltipSection = ({ label, total, color }: Props) => {
  return (
    <StyledTooltipSection>
      <StyledDot color={color} />
      <div>
        <StyledTooltipHeader>{label}</StyledTooltipHeader>
        <H6 as="div">{currencyFormatter(total)}</H6>
      </div>
    </StyledTooltipSection>
  )
}
