import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { UpdateCardNameMutation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/card'

import { cacheDebitCardName } from 'utils/banking/cacheDebitCardName/cacheDebitCardName'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  UpdateCardName as UpdateCardNameResponse,
  UpdateCardNameVariables,
} from 'types/gql-types/UpdateCardName'

export const useUpdateCardName = () => {
  const entityUuid = useSelectedEntityUuid()
  const [updateCardNameMutation, { loading }] = useMutation<
    UpdateCardNameResponse,
    UpdateCardNameVariables
  >(UpdateCardNameMutation, {
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  const updateCardName = useCallback(
    async ({ name, cardId }: { name: string; cardId: string }) => {
      try {
        const updateResult = await updateCardNameMutation({
          variables: {
            entityUuid,
            input: {
              cardId: {
                cardUuid: cardId,
              },
              name,
            },
          },
          update: (cache, result) => {
            if (result.data?.updateDebitCard) {
              cacheDebitCardName({
                cache,
                cardId,
                name,
              })
            }
          },
        })

        if (!updateResult.data?.updateDebitCard) {
          throw new Error(translate('page.cardActionErrorMessage.editName'))
        }

        return updateResult.data.updateDebitCard
      } catch (err) {
        ErrorLogger.report(
          `[Banking] ${translate('page.cardActionErrorMessage.editName')}`,
          err
        )
        return UNEXPECTED_ERROR
      }
    },
    [entityUuid, updateCardNameMutation]
  )

  return { updateCardName, isUpdatingCardName: loading }
}
