import { useCallback, useEffect, useState } from 'react'
import { InvoiceItemUnit } from '@npco/mp-gql-types'
import { SelectSize, SelectStandard } from '@npco/zeller-design-system'
import { SelectSelectedItem } from 'design-system/Components/Select'
import {
  INVOICE_ITEMS_CALCULATE_QUANTITY_FIELD,
  INVOICE_ITEMS_CALCULATE_UNIT_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceItemCalculateFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'

import { convertNumberToLocaleString } from 'utils/localeString'
import { translate } from 'utils/translations'

import { InvoiceUnitSelectedItem } from '../../../../../../InvoiceFormAccordions.types'

export const translations = {
  label: 'Unit',
  unitOptionDAY: translate(
    'page.invoice.formSections.items.itemsCalculateUnitOptionDAY'
  ),
  unitOptionHOUR: translate(
    'page.invoice.formSections.items.itemsCalculateUnitOptionHOUR'
  ),
  unitOptionQUANTITY: translate(
    'page.invoice.formSections.items.itemsCalculateUnitOptionQUANTITY'
  ),
}

const unitItems: InvoiceUnitSelectedItem[] = Object.entries(
  InvoiceItemUnit
).map(([key, unit]) => {
  return {
    value: unit,
    label: translations[`unitOption${key}` as keyof typeof translations],
  }
})

export const InvoiceItemCalculateUnit = () => {
  const [field, , helpers] = useField(INVOICE_ITEMS_CALCULATE_UNIT_FIELD)
  const { values, setFieldValue } =
    useFormikContext<InvoiceItemCalculateFormFields>()

  const [selectedItem, setSelectedItem] =
    useState<InvoiceUnitSelectedItem | null>(null)

  useEffect(() => {
    if (!field.value) {
      setSelectedItem(null)
      return
    }

    const nextSelectedItem = unitItems.find(
      (item) => item.value === field.value
    )

    setSelectedItem(nextSelectedItem ?? null)
  }, [field.value])

  const handleChange = useCallback(
    (item: SelectSelectedItem<InvoiceUnitSelectedItem>) => {
      if (!item) {
        return
      }

      const isQuantityUnit = item.value === InvoiceItemUnit.QUANTITY
      const number = Number(values.quantity)
      const value = isQuantityUnit ? Math.floor(number) : number

      const numberString = convertNumberToLocaleString(value, {
        useGrouping: false,
        minimumFractionDigits: isQuantityUnit ? 0 : 2,
      })

      setFieldValue(INVOICE_ITEMS_CALCULATE_QUANTITY_FIELD, numberString)

      helpers.setValue(item.value)
    },
    [helpers, setFieldValue, values.quantity]
  )

  return (
    <SelectStandard
      items={unitItems}
      label={translations.label}
      mobileLabel={translations.label}
      onChange={handleChange}
      selectedItem={selectedItem}
      size={SelectSize.Small}
    />
  )
}
