import { useEffect, useMemo, useState } from 'react'
import { BankAccountDetailsInput } from '@npco/mp-gql-types'
import { useCreatePaymentInstrumentMFAState } from 'features/MFA'

import { BankAccountDetails } from 'hooks/paymentInstruments/PaymentInstruments.types'
import { useCreateBsbPaymentInstrument } from 'hooks/paymentInstruments/useCreateBsbPaymentInstrument'
import {
  MFA_ERROR,
  RESOURCE_ALREADY_EXISTS_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'

export type CreatePaymentInstrumentError =
  | typeof UNEXPECTED_ERROR
  | typeof MFA_ERROR
  | typeof RESOURCE_ALREADY_EXISTS_ERROR

interface UseCreatePaymentInstrumentOnMFARedirectOptions {
  onSuccess: (id: string, details: BankAccountDetails) => void
  onError: (
    err: CreatePaymentInstrumentError,
    details: BankAccountDetails
  ) => void
}

export const useCreatePaymentInstrumentOnMFARedirect = ({
  onSuccess,
  onError,
}: UseCreatePaymentInstrumentOnMFARedirectOptions) => {
  const {
    createPaymentInstrumentState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  } = useCreatePaymentInstrumentMFAState()

  const { createPaymentInstrument } = useCreateBsbPaymentInstrument()

  const [isCreatingPaymentInstrument, setIsCreatingPaymentInstrument] =
    useState(false)

  const bankAccountDetails = useMemo(() => {
    if (!createPaymentInstrumentState) {
      return undefined
    }

    const { bsb, name, account } = createPaymentInstrumentState.variables

    return { bsb, name, account }
  }, [createPaymentInstrumentState])

  useEffect(() => {
    if (createPaymentInstrumentState && hasRedirectedBackToApp) {
      const createAccount = async (
        variables: {
          contactId: string
        } & BankAccountDetailsInput
      ) => {
        const { contactId, ...details } = variables

        const result = await createPaymentInstrument(details, contactId)

        setIsCreatingPaymentInstrument(false)

        if (
          result === UNEXPECTED_ERROR ||
          result === MFA_ERROR ||
          result === RESOURCE_ALREADY_EXISTS_ERROR
        ) {
          onError(result, details)
          return
        }

        onSuccess(result.id, details)
      }

      setHasRedirectedBackToApp(false)
      setIsCreatingPaymentInstrument(true)

      createAccount(createPaymentInstrumentState.variables)
    }
  }, [
    createPaymentInstrumentState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
    createPaymentInstrument,
    onSuccess,
    onError,
  ])

  return {
    isCreatingPaymentInstrument,
    bankAccountDetails,
    hasRedirectedBackToApp,
  }
}
