import { ContactType } from '@npco/mp-gql-types'
import { Contacts } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const translations = {
  createBusinessButtonLabel: translate(
    'page.invoice.formSections.customer.createBusiness'
  ),
  createPersonButtonLabel: translate(
    'page.invoice.formSections.customer.createPerson'
  ),
}

interface InvoiceContactComboboxHeaderProps {
  closeMenu: () => void
  onSelection: (contactType: ContactType) => void
}

export const InvoiceContactComboboxHeader = ({
  closeMenu,
  onSelection,
}: InvoiceContactComboboxHeaderProps) => {
  return (
    <Contacts.ContactComboboxHeader
      closeMenu={closeMenu}
      createBusinessButtonLabel={translations.createBusinessButtonLabel}
      createPersonButtonLabel={translations.createPersonButtonLabel}
      flexDirection={{ _: 'column', XS: 'row' }}
      onCreatePerson={() => onSelection(ContactType.PERSON)}
      onCreateBusiness={() => onSelection(ContactType.BUSINESS)}
    />
  )
}
