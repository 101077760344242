import { useTranslations } from '@npco/utils-translations'
import { MiniTabs } from '@npco/zeller-design-system'

import { Condition } from 'components/Condition/Condition'
import { ListItemSectionTitle } from 'components/List'
import { AccountStatementContent } from 'components/List/ListItemContent/ListItemContent'

import { accountStatementsTranslations } from './AccountDetailsStatements.i18n'
import { AccountStatementType } from './AccountDetailsStatements.types'
import { useAccountStatementTabs } from './hooks/useAccountStatementTabs'
import { MonthlyStatements } from './MonthlyStatements'
import { QuarterlyStatements } from './QuarterlyStatements'

export const AccountDetailsStatements = () => {
  const { tabs, currentTab } = useAccountStatementTabs()
  const t = useTranslations(accountStatementsTranslations)

  return (
    <>
      <ListItemSectionTitle
        title={t('sectionTitle')}
        right={
          <MiniTabs currentTab={currentTab}>
            {tabs.map((tab) => (
              <MiniTabs.Item key={tab.value} {...tab} />
            ))}
          </MiniTabs>
        }
      />
      <Condition shouldShow={currentTab === AccountStatementType.MONTHLY}>
        <AccountStatementContent>
          <MonthlyStatements />
        </AccountStatementContent>
      </Condition>
      <Condition shouldShow={currentTab === AccountStatementType.QUARTERLY}>
        <AccountStatementContent>
          <QuarterlyStatements />
        </AccountStatementContent>
      </Condition>
    </>
  )
}
