import { useCallback } from 'react'
import {
  PopperItemOneLine,
  PopperItemOneLineSize,
} from '@npco/zeller-design-system'
import { AllInvoiceTableColumn } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import {
  isAscendingSelected,
  isDescendingSelected,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { ContactFiltersWrapper } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/ContactFilters/ContactFilters.styled'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'
import { page } from 'translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'

export interface ContactFiltersProps {
  setIsOpen: (state: boolean) => void
}

const columnName = AllInvoiceTableColumn.Contact

export const ContactFilters = ({ setIsOpen }: ContactFiltersProps) => {
  const { sortingObject, setSortingObject } = useInvoicesContext()

  const handleClick = useCallback(
    (ascending: boolean) => () => {
      if (sortingObject?.ascending === ascending) {
        setSortingObject(null)
      } else {
        setSortingObject({ columnName, ascending })
      }
      setIsOpen(false)
    },
    [setSortingObject, setIsOpen, sortingObject]
  )

  return (
    <ContactFiltersWrapper data-testid="contact-filters">
      <PopperItemOneLine
        shouldDisplayIcon={isAscendingSelected(sortingObject, columnName)}
        icon={Tick}
        label={page.invoiceTable.sorting.contactAscending}
        onClick={handleClick(true)}
        size={PopperItemOneLineSize.Small}
      />
      <PopperItemOneLine
        shouldDisplayIcon={isDescendingSelected(sortingObject, columnName)}
        icon={Tick}
        label={page.invoiceTable.sorting.contactDescending}
        onClick={handleClick(false)}
        size={PopperItemOneLineSize.Small}
      />
    </ContactFiltersWrapper>
  )
}
