import { Box, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import SetupFlowCardsCardImageBack from 'assets/images/setup-flow/setup-flow-cards-debit-card-back.png'
import SetupFlowCardsCardImageFront from 'assets/images/setup-flow/setup-flow-cards-debit-card-front.png'

import { DebitCardImageStyles } from '../../DebitCardDetails.styled'

export const SensitiveContentDesktopContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: absolute;
  left: 16px;
  top: 54px;
  font-size: 9px;
  white-space: nowrap;
`

export const DebitCardFlipContainer = styled(Box)<{
  $isFlipped: boolean
}>`
  height: 100%;
  width: 100%;
  position: absolute;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform: ${({ $isFlipped }) => ($isFlipped ? 'rotateY(180deg)' : 'unset')};
`

export const SensitiveContentFront = styled(Box)`
  backface-visibility: hidden;
  background: url(${SetupFlowCardsCardImageFront}) center 0 / cover;
  ${DebitCardImageStyles}
`

export const SensitiveContentBack = styled(Box)<{
  $isVisible: boolean
}>`
  background: url(${SetupFlowCardsCardImageBack}) center 0 / cover;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: visibility 0.5s;
  ${DebitCardImageStyles}
`
