import { Flex } from '@npco/zeller-design-system'

import {
  CardFormatOption,
  CreateSetCardSettingsState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SetCardSettingsAccessibilityOption } from './SetCardSettingsAccessibilityOption/SetCardSettingsAccessibilityOption'
import { SetCardSettingsNickname } from './SetCardSettingsNickname/SetCardSettingsNickname'

export const SetCardSettingsFields = () => {
  const { state } = useAddCardState<CreateSetCardSettingsState>()

  return (
    <Flex flexDirection="column" mt="12px">
      <SetCardSettingsNickname />
      <Condition shouldShow={state?.cardFormat === CardFormatOption.Physical}>
        <SetCardSettingsAccessibilityOption />
      </Condition>
    </Flex>
  )
}
