import { gql } from '@apollo/client'
import { Box } from '@npco/zeller-design-system'

import { ExpandableCardTable } from 'components/CardsView/components/CardsTable/ExpandableCardTable'
import { ShowMore } from 'components/ShowMore'

import { NoCardsCallToAction } from './NoCardsCallToAction'
import {
  YourCardsListViewDebitCardV2Fragment,
  YourCardsListViewEntityFragment,
} from './YourCardsListView.generated'

interface YourCardsListViewProps {
  cards: YourCardsListViewDebitCardV2Fragment[]
  entity: YourCardsListViewEntityFragment | null
  isLoading: boolean
}

export const YourCardsListView = ({
  cards,
  entity,
  isLoading,
}: YourCardsListViewProps) => {
  return (
    <Box position="relative" width="100%" data-testid="your-cards-view">
      {!isLoading && cards.length === 0 && (
        <NoCardsCallToAction entity={entity} />
      )}

      <ShowMore>
        <ExpandableCardTable
          isLoading={isLoading || cards.length === 0}
          cards={cards}
        />
      </ShowMore>
    </Box>
  )
}

YourCardsListView.fragments = {
  DebitCardV2: gql`
    fragment YourCardsListViewDebitCardV2Fragment on DebitCardV2 {
      ...ExpandableCardTableDebitCardV2Fragment
    }

    ${ExpandableCardTable.fragments.DebitCardV2}
  `,
  Entity: gql`
    fragment YourCardsListViewEntityFragment on Entity {
      ...NoCardsCallToActionEntityFragment
    }

    ${NoCardsCallToAction.fragments.Entity}
  `,
}
