import { useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'

import { FileWithId } from 'components/File'

import {
  fetchUploadUrls,
  promisifiedFetchUploadFileToS3Bucket,
} from './useUploadToS3.utils'

interface Arguments {
  filesToUpload: FileWithId[]
  transactionUuid: string
  onComplete: (failedFiles: FileWithId[], successfulFiles: FileWithId[]) => void
  onAbort: (failedFiles: FileWithId[]) => void
}

export const useUploadToS3 = ({
  filesToUpload,
  transactionUuid,
  onComplete,
  onAbort,
}: Arguments) => {
  const entityUuid = useSelectedEntityUuid()
  const client = useApolloClient()
  const [numberOfFilesThatFinished, setNumberOfFilesThatFinished] = useState(0)
  const abortControllerRef = useRef<AbortController>()

  const attachCounterOnResolved = <T>(promise: Promise<T>) => {
    promise
      .then(() => setNumberOfFilesThatFinished((n) => n + 1))
      .catch(() => setNumberOfFilesThatFinished((n) => n + 1))
  }

  const upload = async () => {
    try {
      const filesWithUploadUrls = await fetchUploadUrls({
        entityUuid,
        client,
        filesToUpload,
        transactionUuid,
      })

      const abortController = new AbortController()
      abortControllerRef.current = abortController
      const { signal } = abortController

      const uploadPromises = filesWithUploadUrls.map(({ file, uploadUrl }) =>
        promisifiedFetchUploadFileToS3Bucket({
          file,
          s3BucketUrl: uploadUrl,
          signal,
        })
      )

      uploadPromises.forEach(attachCounterOnResolved)

      const results = await Promise.allSettled(uploadPromises)

      const successfulFiles = results.reduce<FileWithId[]>(
        (acc, { status }, i) => {
          if (status !== 'fulfilled') {
            return acc
          }

          return [...acc, filesWithUploadUrls[i]]
        },
        []
      )

      const failedFiles = results.reduce<FileWithId[]>((acc, { status }, i) => {
        if (status === 'fulfilled') {
          return acc
        }

        return [...acc, filesWithUploadUrls[i]]
      }, [])

      if (signal.aborted) {
        onAbort(failedFiles)
        return
      }

      onComplete(failedFiles, successfulFiles)
    } catch (error) {
      onComplete(filesToUpload, [])
      ErrorLogger.report('[Core] upload to bucket', error)
    }
  }

  const abortUpload = () => {
    abortControllerRef.current?.abort()
  }

  useEffect(() => {
    upload()

    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    numberOfFilesThatFinished,
    abortUpload,
  }
}
