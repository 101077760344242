import { useReactiveVar } from '@apollo/client'
import { TransactionType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex, InfoFilledIcon, TooltipBasic } from '@npco/zeller-design-system'
import { rvSelectedTypes } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { amountFormatter, getCents, getWholePartFromCents } from 'utils/common'

import { translations } from '../Reports.i18n'
import {
  StyledAmount,
  StyledContainer,
  StyledDecimal,
  StyledIncomeWrapper,
  StyledRefundsWrapper,
  StyledSalesWrapper,
  StyledTitle,
} from './ReportsHighlights.styled'

interface Props {
  total: number
  salesCount: number
  refundsCount: number
  shouldShowCounts?: boolean
}

export const ReportsHighlights = ({
  total,
  salesCount,
  refundsCount,
  shouldShowCounts = true,
}: Props) => {
  const t = useTranslations(translations)

  const selectedTypes = useReactiveVar(rvSelectedTypes)
  const wholePart = getWholePartFromCents(total)
  const decimalPart = getCents(total)

  const getTotalText = () => {
    if (selectedTypes.length === 1) {
      return selectedTypes[0].value === TransactionType.PURCHASE
        ? t('totalFromSales')
        : t('totalFromRefunds')
    }

    return t('total')
  }

  return (
    <StyledContainer>
      <StyledIncomeWrapper>
        <StyledAmount data-testid="highlights-total">
          {wholePart}
          <StyledDecimal>.{decimalPart}</StyledDecimal>
        </StyledAmount>
        <StyledTitle>{getTotalText()}</StyledTitle>
      </StyledIncomeWrapper>
      {shouldShowCounts && (
        <>
          <StyledSalesWrapper>
            <StyledAmount data-testid="highlights-sales">
              {amountFormatter(salesCount)}
            </StyledAmount>
            <StyledTitle>{t('sales')}</StyledTitle>
          </StyledSalesWrapper>
          <StyledRefundsWrapper>
            <StyledAmount data-testid="highlights-refunds">
              {amountFormatter(refundsCount)}
            </StyledAmount>
            <StyledTitle>
              {t('refunds')}
              <TooltipBasic
                showArrow={false}
                renderTrigger={({ handlers }) => (
                  <Flex
                    data-testid="refunds-info-icon"
                    marginLeft="8px"
                    {...handlers}
                  >
                    <InfoFilledIcon />
                  </Flex>
                )}
              >
                {t('refundsTooltip')}
              </TooltipBasic>
            </StyledTitle>
          </StyledRefundsWrapper>
        </>
      )}
    </StyledContainer>
  )
}
