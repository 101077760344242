import { useCallback } from 'react'
import { ContactType, InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { isEqual } from 'lodash-es'

import { DATE_FULL_DISPLAY_FORMAT } from 'const/date'
import dayjs from 'utils/dayjs'
import {
  AnalyticsEventNames,
  InvoiceActionEventMapsForPayload,
} from 'services/Analytics/events'
import { InvoiceAnalyticsProperties } from 'services/Analytics/events/invoice'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { defaultInvoiceLineItem } from '../Invoice/components/InvoiceCreateForm/InvoiceCreateForm.utils'
import { useDefaultMarkupValue } from '../Invoice/components/InvoiceFormAccordions/components/InvoiceDeliveryAccordion/hooks/useDefaultMarkupValue'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
} from '../Invoice/Invoice.constants'
import { InvoiceFormFields } from '../Invoice/Invoice.types'

interface GetInvoiceFormFieldsAnalyticsPayloadProps {
  defaultMessage: string
  defaultSubject: string
  invoice: InvoiceFormFields
}

const dayjsFromFullDisplayFormat = (date: string): dayjs.Dayjs =>
  dayjs(date, DATE_FULL_DISPLAY_FORMAT)

const getCustomerContactType = (customer: InvoiceFormFields['customer']) => {
  if (customer?.payerContact?.contactType === ContactType.BUSINESS) {
    return 'Business'
  }

  if (customer?.payerContact?.contactType === ContactType.PERSON) {
    return 'Person'
  }

  return undefined
}

const getDirtyItemsLength = (items?: InvoiceFormFields['items']) => {
  if (!items) {
    return 0
  }

  // when a new item is added and not edited, it will still have a new id and key
  // so will not match defaultInvoiceLineItem
  const defaultItemWithoutKeyId = {
    ...defaultInvoiceLineItem,
    id: undefined,
    key: undefined,
  }
  return items.filter((item) => {
    const itemWithoutKeyId = { ...item, id: undefined, key: undefined }
    return !isEqual(itemWithoutKeyId, defaultItemWithoutKeyId)
  }).length
}

export const getInvoiceFormFieldsAnalyticsPayload = ({
  defaultMessage,
  defaultSubject,
  invoice,
}: GetInvoiceFormFieldsAnalyticsPayloadProps): InvoiceAnalyticsProperties => ({
  AttentionTo: Boolean(invoice.customer.attentionToContact?.contactUuid),
  Customer: getCustomerContactType(invoice.customer),
  GST: invoice.itemsTaxInclusive ? 'Inclusive' : 'Exclusive',
  EmailCustomisation:
    (!!invoice.delivery.email.message || !!invoice.delivery.email.subject) &&
    (invoice.delivery.email.message !== defaultMessage ||
      invoice.delivery.email.subject !== defaultSubject),
  EmailEnabled: invoice.delivery.email.isEnabled,
  InvoiceDiscount: Boolean(
    invoice.discount.config === InvoiceDiscountConfig.PERCENTAGE
      ? invoice.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
      : invoice.discount.price !== INVOICE_DEFAULT_PRICE
  ),
  ItemDiscount: Boolean(
    invoice.items?.some((item) =>
      Boolean(
        item.discount.config === InvoiceDiscountConfig.PERCENTAGE
          ? item.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
          : item.discount.price !== INVOICE_DEFAULT_PRICE
      )
    )
  ),
  Items: getDirtyItemsLength(invoice.items),
  Message: Boolean(invoice.title.message),
  PaymentTerms: dayjsFromFullDisplayFormat(invoice.schedule.dueDate).diff(
    dayjsFromFullDisplayFormat(invoice.schedule.invoiceDate),
    'days'
  ),
  SavedItems: Boolean(
    invoice.items?.some((item) => Boolean(item.catalogItemUuid))
  ),
  ScheduleSend: invoice.schedule.sendEnabled,
  ...(invoice.schedule.sendEnabled && {
    ScheduleDays: dayjsFromFullDisplayFormat(invoice.schedule.sendDate).diff(
      dayjs().startOf('day'),
      'days'
    ),
  }),
  SMSEnabled: invoice.delivery.sms.isEnabled,
  ...(invoice.referenceNumber && {
    ReferenceNumber: invoice.referenceNumber,
  }),
  ...(invoice.id && { ID: invoice.id }),
})

export const useInvoiceAnalytics = (referenceNumber?: string, id?: string) => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()
  const { defaultMessage, defaultSubject } = useDefaultMarkupValue()

  const trackDiscardedInvoice = (invoice: InvoiceFormFields) => {
    const payload = getInvoiceFormFieldsAnalyticsPayload({
      defaultMessage,
      defaultSubject,
      invoice,
    })

    trackAnalyticsEvent(AnalyticsEventNames.INVOICE_DISCARDED, payload)
  }

  const trackSavedInvoice = (invoice: InvoiceFormFields) => {
    const payload = getInvoiceFormFieldsAnalyticsPayload({
      defaultMessage,
      defaultSubject,
      invoice,
    })

    trackAnalyticsEvent(AnalyticsEventNames.INVOICE_SAVED, payload)
  }

  const trackSentInvoice = (invoice: InvoiceFormFields) => {
    const payload = getInvoiceFormFieldsAnalyticsPayload({
      defaultMessage,
      defaultSubject,
      invoice,
    })

    trackAnalyticsEvent(AnalyticsEventNames.INVOICE_SENT, payload)
  }

  const trackInvoiceAction = useCallback(
    (eventName: keyof InvoiceActionEventMapsForPayload) => {
      trackAnalyticsEvent(eventName, {
        ID: id,
        ReferenceNumber: referenceNumber,
      })
    },
    [id, referenceNumber, trackAnalyticsEvent]
  )

  const trackInvoiceInitiated = useCallback(
    () => trackAnalyticsEvent(AnalyticsEventNames.INVOICE_INITIATED),
    [trackAnalyticsEvent]
  )

  return {
    trackDiscardedInvoice,
    trackInvoiceAction,
    trackInvoiceInitiated,
    trackSavedInvoice,
    trackSentInvoice,
  }
}
