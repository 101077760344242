import { PropsWithChildren } from 'react'
import { motion, MotionProps } from 'framer-motion'

export const FadeUpAnimation = ({
  children,
  ...props
}: PropsWithChildren<MotionProps>) => {
  return (
    <motion.div
      {...props}
      initial={{
        opacity: 0,
        y: 24,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{ duration: 0.35, delay: 0.2, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  )
}
