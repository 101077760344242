import { BodySmall, PillYellow } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

const CommonTextWrapperCss = css`
  margin: 0;
`

export const Header = styled.div`
  display: flex;
  padding-bottom: 24px;
  gap: 16px;
`

export const CardAcceptorName = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  ${CommonTextWrapperCss}
`

export const Title = styled.h3`
  ${CommonTextWrapperCss}
  ${({ theme }) => theme.typography['heading-xl']}
  display: inline;
  vertical-align: middle;
  word-break: break-word;
`

export const Pill = styled(PillYellow)`
  display: inline;
  margin-left: 16px;
`

export const Amount = styled.span<{
  color: string
}>`
  ${({ theme }) => theme.typography['heading-xl']}
  color: ${({ color }) => color};
`
