import { ErrorTracker } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/dashboard'

import {
  PlaceholderAlignment,
  PlaceholderSize,
} from 'components/Placeholders/Placeholders.types'

import { StyledGlobalErrorPage } from './Fallbacks.styled'

interface Props {
  retry: ErrorTracker
}

export const GlobalErrorPage = ({ retry }: Props) => {
  return (
    <StyledGlobalErrorPage
      data-testid="dashboard-global-error-page"
      retry={retry || undefined}
      variant={PlaceholderSize.ExtraLarge}
      alignment={PlaceholderAlignment.Left}
    />
  )
}
