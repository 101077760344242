import { Balance } from 'layouts/TransactionAccountsLayout/Balance/Balance'

import { useAccountTotalBalanceQuery } from './hooks/useAccountTotalBalanceQuery/useAccountTotalBalanceQuery'

export const AccountTotalBalanceDeps = {
  useAccountTotalBalanceQuery,
}

export const AccountTotalBalance = () => {
  const { useAccountTotalBalanceQuery } = AccountTotalBalanceDeps

  const { totalBalance } = useAccountTotalBalanceQuery()

  return <Balance totalBalance={totalBalance} />
}
