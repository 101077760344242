import { useMemo } from 'react'
import { VelocityWindowEnum } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { translationsShared } from 'translations'

import { spendControlFrequencyTranslations } from '../SpendControlFrequency.i18n'

export const useSpendControlFrequencyOptions = () => {
  const t = useTranslations(spendControlFrequencyTranslations)
  const tShared = useTranslations(translationsShared)
  return useMemo(
    () => [
      {
        label: tShared('daily'),
        subLabel1: t('resetDailyDescription'),
        value: VelocityWindowEnum.DAY,
      },
      {
        label: tShared('weekly'),
        subLabel1: t('resetWeeklyDescription'),
        value: VelocityWindowEnum.WEEK,
      },
      {
        label: tShared('fortnightly'),
        subLabel1: t('resetFortnightlyDescription'),
        value: VelocityWindowEnum.FORTNIGHT,
      },
      {
        label: tShared('monthly'),
        subLabel1: t('resetMonthlyDescription'),
        value: VelocityWindowEnum.MONTH,
      },
      {
        label: tShared('quarterly'),
        subLabel1: t('resetQuarterlyDescription'),
        value: VelocityWindowEnum.QUARTER,
      },
    ],
    [t, tShared]
  )
}
