import React, { useCallback, useMemo, useState } from 'react'
import { Box, PillInput } from '@npco/zeller-design-system'
import { debounce } from 'debounce'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { page } from 'translations'

export const INPUT_VALUE_CHANGE_DEBOUNCE = 500

export const AccountFilterDescription = () => {
  const { setDescription, resetDescription } = useAccountFilters()
  const [inputValue, setInputValue] = useState('')

  const updateGlobalDescription = useCallback(
    (value: string) => setDescription(value),
    [setDescription]
  )
  const debouncedUpdateGlobalDescription = useMemo(
    () => debounce(updateGlobalDescription, INPUT_VALUE_CHANGE_DEBOUNCE),
    [updateGlobalDescription]
  )

  const onChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      debouncedUpdateGlobalDescription(value)
      setInputValue(value)
    },
    [debouncedUpdateGlobalDescription]
  )

  const onClear = useCallback(() => {
    setInputValue('')
    resetDescription()
    debouncedUpdateGlobalDescription.clear()
  }, [debouncedUpdateGlobalDescription, resetDescription])

  return (
    <Box width="15rem">
      <PillInput
        type="text"
        name="description"
        id="description"
        icon={<SearchIcon />}
        placeholder={
          page.accounts.transactionsList.filters.descriptionPlaceholder
        }
        value={inputValue}
        onChange={onChange}
        onClear={onClear}
        aria-label={
          page.accounts.transactionsList.filters.descriptionPlaceholder
        }
      />
    </Box>
  )
}
