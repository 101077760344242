import { createTranslations } from '@npco/utils-translations'

export const validationTranslations = createTranslations({
  spendControlLimitRequiredError: 'Spend Limit is required.',
  spendControlLimitMinError: 'Spend Limit must be greater than $1.00.',
  spendControlFrequencyRequiredError: 'Spend Control Frequency is required.',
  spendControlMaxTransactionLimitRequiredError:
    'Maximum Transaction Limit is required.',
  spendControlMaxTransactionLimitMinError:
    'Maximum Transaction Limit must be greater than $1.00.',
  spendControlMaxTransactionLimitMaxError:
    'Maximum Transaction Limit must not exceed $25,000.00.',
})
