/**
 * TO DO: Co-locating for now, but will need to remove all rv variables
 * and use apollo caching instead
 */
import { makeVar } from '@apollo/client'

import { GetLabel } from './LabelControl.types'

export const rvBusinessLabels = makeVar<GetLabel[]>([])
export const rvBusinessLabelsDeleted = makeVar<string[]>([])

export const rvPersonLabels = makeVar<GetLabel[]>([])
export const rvPersonLabelsDeleted = makeVar<string[]>([])
