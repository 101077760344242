import { Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { NotFoundPlaceholder } from '../../NotFoundPlaceholder/NotFoundPlaceholder'
import { StyledText } from './AccountFilterContactPlaceholder.styled'

interface Props {
  searchInput: string
}

export const AccountFilterContactPlaceholder = ({ searchInput }: Props) => {
  if (searchInput) {
    return <NotFoundPlaceholder searchInput={searchInput} />
  }

  return (
    <Flex
      height="9rem"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <StyledText>
        {translate(
          'page.accounts.transactionsList.filters.contactPlaceholder.initial',
          { newLineMarker: <br /> }
        )}
      </StyledText>
    </Flex>
  )
}
