import { useTranslations } from '@npco/utils-translations'

import { introDescriptionTranslations } from './IntroDescription.i18n'
import { TermsApply } from './TermsApply'

type IntroDescriptionProps = {
  interestRate: string
}

export const IntroDescription = ({ interestRate }: IntroDescriptionProps) => {
  const t = useTranslations(introDescriptionTranslations)

  return (
    <>
      {t('description', {
        interestRate,
        termsText: <TermsApply />,
      })}
    </>
  )
}
