import { z } from 'zod'

const CloseAccountStateSchema = z.object({
  accountId: z.string(),
})

export const CloseAccountLocationSchema = z.object({
  CloseAccountModal: CloseAccountStateSchema,
})

export type CloseAccountState = z.infer<typeof CloseAccountStateSchema>
