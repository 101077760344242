export enum CreateExternalAccountStage {
  ExternalAccountMessageStage = 'ExternalAccountMessageStage',
  ExternalAccountFormStage = 'ExternalAccountFormStage',
  ExternalAccountReviewStage = 'ExternalAccountReviewStage',
}

export interface ExternalAccountMessageState {
  stage: CreateExternalAccountStage.ExternalAccountMessageStage
  accountName?: string
  accountBsb?: string
  accountNumber?: string
  shouldUpdateDepositAccount?: boolean
}

export interface ExternalAccountFormState {
  stage: CreateExternalAccountStage.ExternalAccountFormStage
  accountName?: string
  accountBsb?: string
  accountNumber?: string
  shouldUpdateDepositAccount?: boolean
}

export interface ExternalAccountReviewState {
  stage: CreateExternalAccountStage.ExternalAccountReviewStage
  accountName: string
  accountBsb: string
  accountNumber: string
  shouldUpdateDepositAccount: boolean
}

export type CreateExternalAccountState =
  | ExternalAccountMessageState
  | ExternalAccountFormState
  | ExternalAccountReviewState
