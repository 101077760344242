import { useCallback } from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { UNEXPECTED_ERROR } from 'types/errors'

import { useSetupFlowContext } from '../../../contexts/SetupFlowContext'
import { useSetupFlowCardCreate } from '../../../hooks/useSetupFlowCardCreate/useSetupFlowCardCreate'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { setupFlowAccountsStageTranslations } from './FeatureAccountsStage.i18n'
import { SetupFlowAccountsStageFormFields } from './FeatureAccountsStageContent.types'
import { FeatureAccountsStageContentForm } from './FeatureAccountsStageContentForm'

export const FeatureAccountsStageContent = () => {
  const t = useTranslations(setupFlowAccountsStageTranslations)
  const { goToStage } = useSetupFlowModal()
  const { userData } = useLoggedInUser()
  const { setupFlowAccount, isCreateCardAllowedInitialState } =
    useSetupFlowContext()
  const { requestSetupFlowDebitCard, isLoading } = useSetupFlowCardCreate()

  const handleNext = useCallback(
    async ({ isCreateCardAllowed }: SetupFlowAccountsStageFormFields) => {
      if (isLoading) {
        return
      }

      if (!setupFlowAccount || !userData || !isCreateCardAllowed) {
        goToStage('FeatureCardsStage')
        return
      }

      const result = await requestSetupFlowDebitCard({
        accountUuid: setupFlowAccount.id,
        cardholderUuid: userData.id,
      })

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('errorMessage'))
      }

      goToStage('FeatureCardsStage')
    },
    [
      requestSetupFlowDebitCard,
      setupFlowAccount,
      userData,
      isLoading,
      goToStage,
      t,
    ]
  )

  return (
    <Formik<SetupFlowAccountsStageFormFields>
      initialValues={{
        isCreateCardAllowed: isCreateCardAllowedInitialState,
      }}
      onSubmit={handleNext}
    >
      <FeatureAccountsStageContentForm isLoading={isLoading} />
    </Formik>
  )
}
