import { currencyFormatter } from '@npco/component-mp-common'

import { AvatarWithTextRow } from '../AvatarWithTextRow/AvatarWithTextRow'
import { DefaultRow } from '../DefaultRow/DefaultRow'

interface AmountRowProps {
  label: string
  amountInCents: number
}

export const AmountRow = ({ label, amountInCents }: AmountRowProps) => {
  return (
    <DefaultRow.Row aria-label="Amount">
      <DefaultRow.Label>{label}</DefaultRow.Label>
      <AvatarWithTextRow.Text as="p">
        {currencyFormatter(+amountInCents)}
      </AvatarWithTextRow.Text>
    </DefaultRow.Row>
  )
}
