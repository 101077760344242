import { useEffect } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { DecisionModal, useModalState } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { useGetInvoice } from '../../../components/Invoices/hooks/useGetInvoice'
import { translations } from './InvoiceEditSettingsModal.i18n'

const ALLOWED_NON_DRAFT_INVOICES_FOR_EDIT = [
  InvoiceStatus.PART_PAID,
  InvoiceStatus.SENT,
  InvoiceStatus.OVERDUE,
]

export const InvoiceEditSettingsModal = () => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const { invoiceRefNumber } = useParams()
  const { invoiceData } = useGetInvoice({
    referenceNumber: invoiceRefNumber ?? '',
  })

  const shouldShowEditSettingsModal =
    invoiceData?.getInvoice.status !== undefined &&
    ALLOWED_NON_DRAFT_INVOICES_FOR_EDIT.includes(invoiceData.getInvoice.status)

  const { closeModal, isModalOpen, setIsModalOpen } = useModalState(false)

  useEffect(() => {
    setIsModalOpen(shouldShowEditSettingsModal)
  }, [setIsModalOpen, shouldShowEditSettingsModal])

  return (
    <DecisionModal
      isOpen={isModalOpen}
      onClickPrimary={closeModal}
      title={t('title')}
      onCancel={closeModal}
      primaryButtonLabel={tShared('dismiss')}
      hasCloseButton
    >
      {t('description')}
    </DecisionModal>
  )
}
