import { gql } from '@apollo/client'

export const CardSingleSubscription = gql`
  subscription CardSingleSubscription($entityUuid: ID!) {
    onDebitCardUpdate(entityUuid: $entityUuid) {
      id
      colour
      name
      status
      velocityControl {
        amountLimit {
          value
        }
        availableAmount {
          value
        }
        maxTransactionValue {
          value
        }
        totalSpentAmount {
          value
        }
        modifiedBy {
          actingCustomerUuid
          updatedAt
        }
        resetsAt
        velocityWindow
      }
      outstandingTransactions
    }
  }
`
