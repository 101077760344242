import {
  StyledButtonWrapper,
  StyledCancelButton,
  StyledConfirmationButton,
} from '../Modal.styled'
import { ModalWithButtonsProps } from '../Modal.types'
import { ModalBasic } from '../ModalBasic/ModalBasic'

export const ModalWithButtons = ({
  primaryButtonLabel,
  secondaryButtonLabel,
  hasCancelButton = false,
  children,
  onCancel,
  onConfirm,
  isOpen,
  isPrimaryButtonDisabled = false,
  isPrimaryButtonLoading = false,
  ...props
}: ModalWithButtonsProps) => {
  return (
    <ModalBasic isOpen={isOpen} onCancel={onCancel} {...props}>
      <div>
        {children}
        <StyledButtonWrapper>
          {hasCancelButton && (
            <StyledCancelButton onClick={onCancel}>
              {secondaryButtonLabel}
            </StyledCancelButton>
          )}
          <StyledConfirmationButton
            onClick={onConfirm}
            hasCancelButton={hasCancelButton}
            disabled={isPrimaryButtonDisabled}
            isLoading={isPrimaryButtonLoading}
          >
            {primaryButtonLabel}
          </StyledConfirmationButton>
        </StyledButtonWrapper>
      </div>
    </ModalBasic>
  )
}
