import { z } from 'zod'

import { ProductFragment } from '../graphql/bigcommerce-graphql/GetProducts.generated'

const ImageDataFragmentSchema = z.object({
  __typename: z.literal('Image').optional(),
  altText: z.string(),
  url320wide: z.string(),
})

const MultipleChoicesSchema = z.object({
  optionEntityId: z.number(),
  optionValueEntityId: z.number(),
})

const SelectedOptionsSchema = z
  .object({
    multipleChoices: z.array(MultipleChoicesSchema).optional().nullable(),
  })
  .optional()
  .nullable()

const ImageItemsSchema = z.array(
  z.object({
    url: z.string(),
    tooltip: z.string(),
  })
)

export const ProductDisplayDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  displayName: z.string(),
  category: z.string(),
  description: z.string(),
  primaryImage: ImageDataFragmentSchema,
  price: z.number(),
  slashedPrice: z.number().optional(),
  accessoryImages: ImageItemsSchema,
  selectedOptions: SelectedOptionsSchema,
})

export type ProductDisplayData = z.infer<typeof ProductDisplayDataSchema>

export type MultipleChoices = z.infer<typeof MultipleChoicesSchema>

export type ImageItems = z.infer<typeof ImageItemsSchema>

export type PrimaryImage = z.infer<typeof ImageDataFragmentSchema>

export type ProductColor = 'White' | 'Black' | 'Graphite'

export type ProductNode = {
  node: ProductFragment
}

export type ProductList = {
  products: ProductNode[]
}

export type SelectedProductColor = {
  [key: string]: ProductColor
}

export type SelectedProductQuantities = {
  [key: string]: number
}

export type ColorsByCategory = {
  [key: string]: ProductColor[]
}
