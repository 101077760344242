import { Box, Flex, SkeletonH3 } from '@npco/zeller-design-system'

import { SkeletonBodySmall } from 'components/SkeletonLoader/SkeletonLoaderTypography'

import * as styled from './FeesAndPricingLoadingSkeleton.styled'

const SkeletonPaymentTable = () => {
  return (
    <styled.TableSkeletonWrapper data-testid="feesAndPricingLoadingSkeleton">
      <styled.PaymentTable>
        <styled.PaymentTableCell className="leftCell topCell">
          <SkeletonBodySmall margin={0} width="200px" />
        </styled.PaymentTableCell>
        <styled.PaymentTableCell className="topCell rightCell">
          <SkeletonBodySmall margin={0} width="32px" />
        </styled.PaymentTableCell>
        <styled.PaymentTableCell className="leftCell">
          <SkeletonBodySmall margin={0} width="200px" />
        </styled.PaymentTableCell>
        <styled.PaymentTableCell className="rightCell">
          <SkeletonBodySmall margin={0} width="32px" />
        </styled.PaymentTableCell>
      </styled.PaymentTable>
    </styled.TableSkeletonWrapper>
  )
}

export const FeesAndPricingLoadingSkeleton = () => {
  return (
    <Flex flexDirection="column">
      <Box mt="8px">
        <SkeletonH3 width="201px" />
      </Box>
      <styled.DescriptionSkeleton />
      <styled.DescriptionNextLineSkeleton />
      <SkeletonPaymentTable />
      <styled.ZellerPricingLinkSkeleton />
    </Flex>
  )
}
