import { SyntheticEvent, useState } from 'react'

interface Arguments {
  defaultValue: number
  onChange: (value: number) => void
  sliderValue: number
}

export const useInputValue = ({
  defaultValue,
  onChange: onExternalChange,
  sliderValue,
}: Arguments) => {
  const [inputValue, setInputValue] = useState(String(defaultValue))

  const onChange = (event: SyntheticEvent) => {
    const {
      target: { value },
    } = event as unknown as React.ChangeEvent<HTMLInputElement>

    setInputValue(value)
    const parsedValue = Number.parseInt(value, 10)

    if (Number.isNaN(parsedValue)) {
      onExternalChange(0)
      return
    }

    onExternalChange(parsedValue)
  }

  const onBlur = () => {
    setInputValue(String(sliderValue))
  }

  return {
    inputValue,
    setInputValue,
    onChange,
    onBlur,
  }
}
