import { DebitCardAccountType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { isNotNull } from 'utils/common'

import { useAccountsListQuery as useAccountsListQueryGenerated } from './graphql/AccountsListQuery.generated'

export const useAccountsListQuery = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error } = useAccountsListQueryGenerated({
    errorPolicy: 'all',
    onError: (err) => {
      showApiErrorToast(err)
    },
    variables: { entityUuid },
  })

  return {
    debitCardAccounts:
      data?.getDebitCardAccountsV2.accounts
        .filter(isNotNull)
        .filter((account) => account.type === DebitCardAccountType.ZLR_DEBIT) ??
      [],
    savingsAccounts: data?.getSavingsAccounts.filter(isNotNull) ?? [],
    isLoadingAccounts: loading,
    error,
  }
}
