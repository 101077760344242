import { gql, type TypedDocumentNode } from '@apollo/client'

export type CardAccountCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    icon: { __typename?: 'Icon'; colour: string | null } | null
  }
}

export const CardAccountCellDebitCardV2FragmentDoc = gql`
  fragment CardAccountCellDebitCardV2Fragment on DebitCardV2 {
    debitCardAccount {
      __typename
      id
      name
      icon {
        colour
      }
    }
  }
` as unknown as TypedDocumentNode<CardAccountCellDebitCardV2Fragment, undefined>
