import {
  sourcePickerInitialValue,
  statusPickerInitialValue,
  terminalPickerInitialValue,
  typePickerInitialValue,
} from 'const/common'

import { LocalStateReports } from './ReportsNew.types'

export const ReportsDefaultLocalState: LocalStateReports = {
  filters: {
    dates: { start: undefined, end: undefined },
    statuses: statusPickerInitialValue,
    terminals: terminalPickerInitialValue,
    types: typePickerInitialValue,
    sources: sourcePickerInitialValue,
  },
  isFiltersVisible: true,
}
