import { useTranslations } from '@npco/utils-translations'
import { InfoBox } from '@npco/zeller-design-system'

import { contactInfoBoxTranslations } from './ContactInfoBox.i18n'

export const ContactInfoBox = ({ contactName }: { contactName: string }) => {
  const t = useTranslations(contactInfoBoxTranslations)
  return (
    <InfoBox>
      <InfoBox.Message>{t('text', { contactName })}</InfoBox.Message>
    </InfoBox>
  )
}
