import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowStartLarge from 'assets/images/setup-flow/setup-flow-start-large.png'
import SetupFlowStartMedium from 'assets/images/setup-flow/setup-flow-start-medium.png'
import SetupFlowStartSmall from 'assets/images/setup-flow/setup-flow-start-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionMainContent } from '../../SetupFlowModal.styled'
import { MainContent } from './MainContent/MainContent'

export const SetupFlowStartStageContent = () => {
  const tShared = useTranslations(translationsShared)

  const { goToStage } = useSetupFlowModal()
  return (
    <Tutorial.TutorialLayout
      Footer={
        <Tutorial.TutorialFooter
          onPrimaryButtonClick={() => goToStage('DownloadAppStage')}
          primaryButtonLabel={tShared('next')}
        />
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowStartSmall}
          imageMedium={SetupFlowStartMedium}
          imageLarge={SetupFlowStartLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
