import OutstandingExpenses from 'assets/animations/corporate-card-walkthrough/outstanding-expenses-animation.json'
import { AnimationBase } from 'components/AnimationBase/AnimationBase'

import { AnimationContainer } from '../ToolTipGraphics.styled'

export const OutstandingExpensesAnimation = () => {
  return (
    <AnimationContainer data-testid="outstanding-expenses-animation">
      <AnimationBase
        animationData={OutstandingExpenses}
        width="200px"
        height="65px"
      />
    </AnimationContainer>
  )
}
