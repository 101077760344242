import { useCallback, useMemo } from 'react'
import { InvoiceItemUnit } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  Heading,
  InfoFilledIcon,
  TooltipBasic,
  zIndexMap,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import { INVOICE_DEFAULT_UNIT_QUANTITY } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceItemCalculateFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'
import { isEmpty, isEqual } from 'lodash-es'

import { convertLocaleStringToNumber } from 'utils/localeString'

import { translations } from '../../../../../../../../Invoice.i18n'
import { getTaxRate } from '../../../../InvoiceItemsAccordion.utils'
import {
  InvoiceTotalWrapper,
  ItemResetCalculate,
  ItemTotalValue,
} from './InvoiceItemsCalculateTotals.styled'

export interface InvoiceItemsCalculateTotalsProps {
  isTaxApplicable: boolean
  isTaxInclusive: boolean
  hasSwitchedTaxSetting: boolean
}

export const InvoiceItemsCalculateTotals = ({
  isTaxApplicable,
  isTaxInclusive,
  hasSwitchedTaxSetting,
}: InvoiceItemsCalculateTotalsProps) => {
  const t = useTranslations(translations)

  const { errors, setValues, values } =
    useFormikContext<InvoiceItemCalculateFormFields>()

  const hasModifiedCalculateFields = useMemo(() => {
    return !isEqual(
      {
        quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
        unit: InvoiceItemUnit.QUANTITY,
      },
      {
        quantity: values.quantity,
        unit: values.unit,
      }
    )
  }, [values])

  const handleReset = useCallback(() => {
    setValues({
      ...values,
      quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
      unit: InvoiceItemUnit.QUANTITY,
    })
  }, [setValues, values])

  const hasValidationError = !isEmpty(errors)

  const rate = getTaxRate(isTaxApplicable, isTaxInclusive)

  const total = hasValidationError
    ? 0
    : values.price
        .multiply(rate)
        .multiply(convertLocaleStringToNumber(values.quantity))

  const totalFormatted = currency(total).format()

  return (
    <Flex flexDirection="column">
      <InvoiceTotalWrapper>
        <ItemResetCalculate
          dataTestId="invoicing-invoice-line-item-calculate-reset"
          disabled={!hasModifiedCalculateFields}
          onClick={handleReset}
        >
          {t('resetCalculate')}
        </ItemResetCalculate>
        <Flex alignItems="flex-end" flexDirection="column" flex={1}>
          <Flex gridGap="8px" alignItems="center">
            {hasSwitchedTaxSetting && (
              <TooltipBasic
                placement="top"
                renderTrigger={({ handlers, ref }) => (
                  <span ref={ref} {...handlers}>
                    <InfoFilledIcon color={COLOR.YELLOW_1000} />
                  </span>
                )}
                showArrow={false}
                zIndex={Number(zIndexMap.tooltip)}
              >
                <Box p="8px" textAlign="center" maxWidth="353px">
                  {t('invoiceGstWarningDescription')}
                </Box>
              </TooltipBasic>
            )}
            <Heading.H4>{`${t('itemTotal')}:`}</Heading.H4>
            <ItemTotalValue dataTestId="invoicing-invoice-line-item-calculate-total-value">
              {totalFormatted}
            </ItemTotalValue>
          </Flex>
        </Flex>
      </InvoiceTotalWrapper>
    </Flex>
  )
}
