import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { errorMessages, page } from 'translations'

const DEFAULT_TIMEOUT = 10000 // 10 seconds

interface FetchUploadLogoToS3BucketArgs {
  s3BucketUrl: string
  file?: File
  onSuccess?: () => void
  onError?: () => void
  disableSuccessToast?: boolean
  disableErrorToast?: boolean
}

export const fetchUploadLogoToS3Bucket = async ({
  disableSuccessToast,
  disableErrorToast,
  s3BucketUrl,
  file,
  onSuccess,
  onError,
}: FetchUploadLogoToS3BucketArgs) => {
  try {
    const controller = new AbortController()
    const { signal } = controller

    const id = setTimeout(() => {
      controller.abort()
    }, DEFAULT_TIMEOUT)
    const response = await fetch(s3BucketUrl, {
      signal,
      method: 'PUT',
      body: file,
    })
    clearTimeout(id)

    if (response.ok) {
      if (!disableSuccessToast)
        showSuccessToast(page.settings.receipt.logoUploaded)
      onSuccess?.()
    } else {
      if (!disableErrorToast) showErrorToast(errorMessages.somethingWentWrong)
      onError?.()
    }
  } catch (err) {
    ErrorLogger.report('[Core] Upload logo', err)
    onError?.()
    if (!disableErrorToast) showErrorToast(errorMessages.somethingWentWrong)
  }
}
