import { COLOR } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'

import {
  getHasExceededPositiveSalesPreviousPeriod,
  getRoundedPercentage,
} from '../../../DashboardPerformance.utils'
import { StyledSvgAnimation } from './CentreText.styled'
import { getThisMonthComparisonText } from './CentreText.utils'
import { BoldText } from './SvgText/BoldText'
import { DefaultText } from './SvgText/DefaultText'
import { SmallText } from './SvgText/SmallText'

interface Props {
  salesThisMonth: number
  salesThisTimeLastMonth: number
  totalSalesLastMonth: number
}

export const ThisMonthCentreText = ({
  salesThisMonth,
  salesThisTimeLastMonth,
  totalSalesLastMonth,
}: Props) => {
  const hasExceededPreviousPeriod = getHasExceededPositiveSalesPreviousPeriod(
    salesThisMonth,
    totalSalesLastMonth
  )

  const transitionKey = `${salesThisMonth}${totalSalesLastMonth}`

  return (
    <StyledSvgAnimation key={transitionKey}>
      {hasExceededPreviousPeriod && (
        <BoldText x="50%" y="30%" fill={COLOR.GREEN_1000}>
          {`+${
            getRoundedPercentage(salesThisMonth, totalSalesLastMonth) - 100
          }%`}
        </BoldText>
      )}
      <BoldText
        x="50%"
        y="45%"
        fill={hasExceededPreviousPeriod ? COLOR.GREEN_1000 : COLOR.BLUE_1000}
      >
        {currencyFormatter(salesThisMonth)}
      </BoldText>
      <SmallText x="50%" y="55%" fill={COLOR.GREY_400}>
        {getThisMonthComparisonText()}
      </SmallText>
      <DefaultText x="50%" y="65%" fill={COLOR.GREY_400}>
        {currencyFormatter(salesThisTimeLastMonth)}
      </DefaultText>
    </StyledSvgAnimation>
  )
}
