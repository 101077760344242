import { Box, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ChartTooltipItem = styled(Flex)`
  ${({ theme }) => theme.typography['body-sm']};
  width: 100%;
  gap: 8px;
  align-items: center;
  justify-content: start;
`

export const ChartTooltipItemText = styled(Box)`
  color: ${({ theme }) => theme.colors.WHITE};
`

export const ChartTooltipItemIcon = styled.span<{
  backgroundColor: string
}>`
  width: 10px;
  height: 10px;
  border-radius: 3px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
