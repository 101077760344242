import { useTranslations } from '@npco/utils-translations'
import { Message, SomethingWentWrongDominoes } from '@npco/zeller-design-system'

import { categoryDrawerTranslations } from '../../CategoryDrawer.i18n'

export const SUPPORT_PHONE_NUMBER = '1800 935 537'

export const CategoryDrawerError = () => {
  const t = useTranslations(categoryDrawerTranslations)

  return (
    <Message
      title={t('subcategoriesDataErrorTitle')}
      description={t('subcategoriesDataErrorDescription', {
        supportPhoneNumber: SUPPORT_PHONE_NUMBER,
      })}
      image={<SomethingWentWrongDominoes size="large" />}
    />
  )
}
