import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { rvSelectedUser } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'

import { UsersListState } from './useGoToUsersList.types'

export const useGoToUsersList = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const goToUsersList = useCallback(
    ({ selectedUserId }: UsersListState = { selectedUserId: null }) => {
      rvSelectedUser(selectedUserId || '')
      navigate(ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.path, {
        state: {
          UsersList: { selectedUserId },
        },
      })
    },
    [navigate, shortEntityId]
  )

  return { goToUsersList }
}
