import { MouseEventHandler } from 'react'
import { ButtonLink } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>
  isDisabled?: boolean
  dataTestId?: string
}

export const AddLink = ({ onClick, isDisabled, dataTestId }: Props) => {
  return (
    <ButtonLink onClick={onClick} disabled={isDisabled} dataTestId={dataTestId}>
      {translate('shared.add')}
    </ButtonLink>
  )
}
