import { translate } from 'utils/translations'

export const TOTAL_STEP_COUNT = 5

export const nextButtonLabel = translate(
  'page.upgradeAccountsCompleteModal.shared.primaryButtonLabel'
)

export const backButtonLabel = translate(
  'page.upgradeAccountsCompleteModal.shared.secondaryButtonLabel'
)
