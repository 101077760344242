import { useCallback, useEffect, useState } from 'react'

import { convertToAWSDate } from '../../utils/convertToAWSDate/convertToAWSDate'
import { dayjs } from '../../utils/dayjs/dayjs'
import {
  useSelectedDateRange,
  useSetSelectedDateRange,
} from '../useSelectedDateRange'
import { Month } from '../useSelectedDateRange/SelectedDateRange.type'

type UseChartDateRangeProps = {
  entityTimezone: string | undefined
  isLoadingTimezone: boolean
}

export const useChartDateRange = ({
  entityTimezone,
  isLoadingTimezone,
}: UseChartDateRangeProps) => {
  const { selectedDateRange } = useSelectedDateRange()
  const { updateSelectedDateRange } = useSetSelectedDateRange()

  const [hasInitialisedTimezone, setHasInitialisedTimezone] = useState(false)
  const [chartDate, setChartDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD')
  )

  useEffect(() => {
    if (selectedDateRange === 'Last 12 Months') {
      setChartDate(
        dayjs().tz(entityTimezone).endOf('month').format('YYYY-MM-DD')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange])

  useEffect(() => {
    if (!isLoadingTimezone) {
      if (entityTimezone) {
        setChartDate(
          dayjs().tz(entityTimezone).endOf('month').format('YYYY-MM-DD')
        )
      }
      setHasInitialisedTimezone(true)
    }
  }, [entityTimezone, isLoadingTimezone])

  const goToPreviousMonth = useCallback(() => {
    const newChartDate = dayjs(chartDate).subtract(1, 'month').endOf('month')

    setChartDate(newChartDate.format('YYYY-MM-DD'))

    if (
      selectedDateRange === 'Last 12 Months' ||
      selectedDateRange.type === 'TTM' ||
      newChartDate.isBefore(convertToAWSDate(selectedDateRange, entityTimezone))
    ) {
      updateSelectedDateRange({
        type: 'TTM',
        year: newChartDate.year(),
        month: newChartDate.format('MMMM') as Month,
      })
    }
  }, [chartDate, selectedDateRange, updateSelectedDateRange, entityTimezone])

  const goToNextMonth = useCallback(() => {
    const newChartDate = dayjs(chartDate).add(1, 'month').endOf('month')

    setChartDate(newChartDate.format('YYYY-MM-DD'))

    if (
      selectedDateRange === 'Last 12 Months' ||
      selectedDateRange.type === 'TTM' ||
      newChartDate
        .subtract(12, 'months')
        .isAfter(convertToAWSDate(selectedDateRange, entityTimezone))
    ) {
      updateSelectedDateRange({
        type: 'TTM',
        year: newChartDate.year(),
        month: newChartDate.format('MMMM') as Month,
      })
    }
  }, [chartDate, selectedDateRange, updateSelectedDateRange, entityTimezone])

  return { chartDate, goToPreviousMonth, goToNextMonth, hasInitialisedTimezone }
}
