import { createTranslations } from '@npco/utils-translations'

export const setupFlowContactsStageTranslations = createTranslations({
  badge: 'Contacts',
  title: 'Build a list of your customer and supplier information.',
  description:
    'Manage business relationships with a free, built-in CRM on Zeller Dashboard or App.',
  point1: 'See payments, transfers, and invoices by contact.',
  point2: 'Track spend by contact over time.',
  point3: 'Build customer and supplier profiles.',
  point4: 'Add notes on contact preferences.',
  animationDataFilenameSmall: 'setup-flow-contacts-small',
  animationDataFilenameMedium: 'setup-flow-contacts-medium',
  animationDataFilenameLarge: 'setup-flow-contacts-large',
})
