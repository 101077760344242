import { Box, ProgressBar, SkeletonBasic } from '@npco/zeller-design-system'

interface CorporateCardProgressBarProps {
  isCardClosed: boolean
  isMobile: boolean
  availableInCents: number
  amountLimitInCents: number
  isLoading?: boolean
}
export const CorporateCardProgressBar = ({
  isCardClosed,
  isMobile,
  availableInCents,
  amountLimitInCents,
  isLoading = false,
}: CorporateCardProgressBarProps) => (
  <Box pt="16px" pb={isCardClosed && !isMobile ? '46px' : 'auto'}>
    {isLoading ? (
      <SkeletonBasic height="8px" isRounded />
    ) : (
      <ProgressBar
        completedAmount={isCardClosed ? 1 : availableInCents}
        totalAmount={isCardClosed ? 1 : amountLimitInCents}
        size="large"
        animationDirection="rtl"
        variant={isCardClosed ? 'disabled' : 'neutral'}
      />
    )}
  </Box>
)
