import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE, ButtonGhost } from '@npco/zeller-design-system'
import { InputSelectComboBoxItem } from 'features/Contacts/Contacts.types'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { useTransferState } from 'pages/Transfer/Transfer.context'
import {
  TransferEvent,
  TransferGeneralState,
} from 'pages/Transfer/Transfer.stateMachine'

import { useResetFields } from '../hooks/useResetFields'
import { transferTypeTabsTranslations } from './TransferTypeTabs.i18n'
import { StyledTab, StyledTabContainer } from './TransferTypeTabs.styled'

export interface TransferTypeTabsProps {
  setSelectedContact: Dispatch<SetStateAction<InputSelectComboBoxItem | null>>
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
}

export const TransferTypeTabs = ({
  setSelectedContact,
  setContactValidationError,
}: TransferTypeTabsProps) => {
  const t = useTranslations(transferTypeTabsTranslations)

  const {
    transferState: [state, send],
  } = useTransferState()
  const { resetFields } = useResetFields()
  const isMobile = useIsMobileResolution()

  const isStateZeller = state.matches(TransferGeneralState.ZellerTransfer)
  const isStateContact = state.matches(TransferGeneralState.ContactTransfer)
  const isStateBpay = state.matches(TransferGeneralState.BpayTransfer)

  const handleTabSwitch = useCallback(
    (event: TransferEvent) => {
      send(event)
      setSelectedContact(null)
      setContactValidationError(undefined)
      resetFields()
    },
    [send, setSelectedContact, setContactValidationError, resetFields]
  )

  return (
    <StyledTabContainer>
      <StyledTab
        active={isStateContact}
        onClick={() =>
          !isStateContact && handleTabSwitch(TransferEvent.ToContactTransfer)
        }
        role="tab"
      >
        <ButtonGhost size={BUTTON_SIZE.XSMALL}>
          {isMobile ? t('contactMobile') : t('contact')}
        </ButtonGhost>
      </StyledTab>
      <StyledTab
        active={isStateBpay}
        onClick={() =>
          !isStateBpay && handleTabSwitch(TransferEvent.ToBpayTransfer)
        }
        role="tab"
      >
        <ButtonGhost size={BUTTON_SIZE.XSMALL}>{t('bpay')}</ButtonGhost>
      </StyledTab>
      <StyledTab
        active={isStateZeller}
        onClick={() =>
          !isStateZeller && handleTabSwitch(TransferEvent.ToZellerTransfer)
        }
        role="tab"
      >
        <ButtonGhost size={BUTTON_SIZE.XSMALL}>
          {t('internalTransfer')}
        </ButtonGhost>
      </StyledTab>
    </StyledTabContainer>
  )
}
