import { gql } from '@apollo/client'

export const GetDebitCardTransactionAttachmentUploadUrls = gql`
  query GetDebitCardTransactionAttachmentUploadUrls(
    $entityUuid: ID!
    $fileNames: [String]!
    $transactionUuid: ID!
  ) {
    getDebitCardTxnAttachmentUploadUrls(
      entityUuid: $entityUuid
      fileNames: $fileNames
      transactionUuid: $transactionUuid
    ) {
      fileName
      uploadUrl
      documentUuid
    }
  }
`

export const GetDebitCardTransactionSummary = gql`
  query GetDebitCardTransactionSummary(
    $entityUuid: ID!
    $transactionUuid: ID!
    $timeZone: String
  ) {
    getDebitCardTransactionSummary(
      entityUuid: $entityUuid
      debitCardTransactionUuid: $transactionUuid
      timeZone: $timeZone
    ) {
      downloadLink
      expire
    }
  }
`

export const GetAccountTransactionFiltersContacts = gql`
  query GetAccountTransactionFiltersContacts(
    $entityUuid: ID!
    $limit: Int!
    $filter: ContactFilterInput
    $nextToken: String
  ) {
    getContacts(
      entityUuid: $entityUuid
      limit: $limit
      filter: $filter
      nextToken: $nextToken
    ) {
      contacts {
        id
        businessName
        firstName
        lastName
        contactType
      }
      nextToken
    }
  }
`

export const GetDebitCardTransactionAttachmentDownloadUrls = gql`
  query GetDebitCardTransactionAttachmentDownloadUrls(
    $transactionUuid: ID!
    $entityUuid: ID!
  ) {
    getDebitCardTxnAttachmentDownloadUrls(
      transactionUuid: $transactionUuid
      entityUuid: $entityUuid
    ) {
      downloadUrl
      fileName
      contentType
      documentUuid
    }
  }
`
