import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { InvoiceIcon } from '@npco/zeller-design-system'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const InvoicesItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const isInvoicingEnabled = useIsInvoicingEnabled()
  const shortEntityId = useSelectedShortEntityUuid()

  if (!isInvoicingEnabled) {
    return null
  }

  return (
    <SidebarItem
      icon={<InvoiceIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).INVOICING.path}
      className="nav-item-invoices"
    >
      {translate('component.sidebar.items.invoices')}
    </SidebarItem>
  )
}
