import { detect } from 'detect-browser'

import {
  ZELLER_APP_LINK_ANDROID,
  ZELLER_APP_LINK_IOS,
} from 'const/externalLinks'

export const getAppLink = () => {
  const browser = detect()
  const isIOS = browser?.os === 'iOS'
  const isAndroid = browser?.os === 'Android OS'

  if (isIOS) {
    return ZELLER_APP_LINK_IOS
  }

  if (isAndroid) {
    return ZELLER_APP_LINK_ANDROID
  }

  return ''
}
