import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { useCompleteCardState } from 'pages/GlobalModals/CompleteCardModal/hooks/useCompleteCardState'
import { useSetPinState } from 'pages/GlobalModals/SetPinModal/hooks/useSetPinState'

export const useCorporateCardWalkthroughRouterState = () => {
  const { state: setPinState } = useSetPinState()
  const { state: completeCardState } = useCompleteCardState()
  const { state: addCardState } = useAddCardState()

  const isSetPinModalOpen = setPinState !== null
  const isCompleteCardModalOpen = completeCardState !== null
  const isAddCardModalOpen = addCardState !== null
  const isRouterStateReadyForWalkthrough =
    !isSetPinModalOpen && !isCompleteCardModalOpen && !isAddCardModalOpen

  return {
    isRouterStateReadyForWalkthrough,
  }
}
