import { BUTTON_SIZE } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { ModalFormProps } from '../Modal.types'
import { ModalBasic } from '../ModalBasic/ModalBasic'
import {
  StyledButton,
  StyledButtonsGroup,
  StyledHeader,
  StyledModalHeaderTitle,
} from './ModalForm.styled'

export const ModalForm = ({
  cancelLabel,
  children,
  confirmLabel,
  isConfirmButtonDisabled = false,
  isLoading,
  isOpen,
  onCancel,
  onSave,
  title,
  ...props
}: ModalFormProps) => {
  const isMobileResolution = useIsMobileResolution()

  const onEllipsisTitle = title as string

  return (
    <ModalBasic
      hasCloseButton={false}
      isFullScreen={isMobileResolution}
      isOpen={isOpen}
      onCancel={onCancel}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      {...props}
    >
      <StyledHeader>
        <div title={onEllipsisTitle}>
          <StyledModalHeaderTitle>{title}</StyledModalHeaderTitle>
        </div>
        <StyledButtonsGroup>
          <StyledButton
            dataTestId="modal-form-cancel-button"
            disabled={isLoading}
            onClick={onCancel}
            size={BUTTON_SIZE.XSMALL}
          >
            {cancelLabel}
          </StyledButton>
          <StyledButton
            dataTestId="modal-form-save-button"
            disabled={isConfirmButtonDisabled}
            isConfirmationButton
            isLoading={isLoading}
            onClick={onSave}
            size={BUTTON_SIZE.XSMALL}
          >
            {confirmLabel}
          </StyledButton>
        </StyledButtonsGroup>
      </StyledHeader>
      {children}
    </ModalBasic>
  )
}
