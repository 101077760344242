import { InfoBoxWrapper } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Wrapper = styled.div`
  ${InfoBoxWrapper} {
    /* 
    NOTE: no need to update design system handle this padding
    as this is a special use case (Designer feedback)
    */
    padding: 0;
  }
`
