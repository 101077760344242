import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Title = styled.h3`
  ${({ theme }) => theme.typography['heading-2xl']}
  margin: 0;
  text-align: center;
  padding: 24px 0;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    ${({ theme }) => theme.typography['heading-3xl']}
    padding: 0 0 24px 24px;
    text-align: left;
  }
`
