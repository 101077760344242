import {
  DebitCardAccountTransactionStatusEnum,
  DebitCardTransactionStatusV2,
} from '@npco/mp-gql-types'
import { COLOR, Status } from '@npco/zeller-design-system'

import { ReactComponent as DeclinedIcon } from 'assets/svg/decline.svg'
import { ReactComponent as PendingIcon } from 'assets/svg/pending.svg'
import { ReactComponent as ApprovedIcon } from 'assets/svg/tick.svg'
import { DebitCardTransactionStatusVariants } from 'components/StatusBadge/StatusBadge.types'
import { component } from 'translations'

const variants: DebitCardTransactionStatusVariants = {
  [DebitCardTransactionStatusV2.APPROVED]: {
    icon: <ApprovedIcon />,
    text: component.transaction.statusBadge.approved,
    color: COLOR.GREEN_1600,
  },
  [DebitCardTransactionStatusV2.PROCESSING]: {
    icon: <PendingIcon />,
    text: component.transaction.statusBadge.pending,
    color: COLOR.GREY_550,
  },
  [DebitCardTransactionStatusV2.DECLINED]: {
    icon: <DeclinedIcon />,
    text: component.transaction.statusBadge.declined,
    color: COLOR.RED_1000,
  },
  [DebitCardTransactionStatusV2.CANCELLED]: {
    icon: <DeclinedIcon />,
    text: component.transaction.statusBadge.canceled,
    color: COLOR.RED_1000,
  },
}

interface StatusBadgeProps {
  status: DebitCardTransactionStatusV2 | DebitCardAccountTransactionStatusEnum
  isWithoutIcon?: boolean
  isWithoutText?: boolean
  className?: string
}

const StatusBadge = ({
  status,
  isWithoutIcon,
  isWithoutText,
  className,
}: StatusBadgeProps) => {
  if (!variants[status]) {
    return null
  }

  const { icon, text, color } = variants[status]

  return (
    <Status
      color={color}
      icon={isWithoutIcon ? undefined : icon}
      text={isWithoutText ? undefined : text}
      className={className}
    />
  )
}

export const DebitCardTransactionStatusBadge = ({
  status,
  isWithoutIcon = false,
  isWithoutText = false,
  className,
}: StatusBadgeProps) => {
  return (
    <StatusBadge
      status={status}
      isWithoutIcon={isWithoutIcon}
      isWithoutText={isWithoutText}
      className={className}
    />
  )
}
