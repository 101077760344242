import { gql } from '@apollo/client'
import { Dayjs } from 'dayjs'
import { getEndOfCurrentWindow } from 'features/Cards/CardSingle/utils/getEndOfCurrentWindow'

import dayjs from 'utils/dayjs'

import { GetRemainingTimeVelocityControlTypeFragment } from './VelocityWindowSummary.utils.generated'

const getDaysOrHoursBetween = (firstTime: Dayjs, secondTime: Dayjs) => {
  const diffInDays = Math.abs(firstTime.diff(secondTime, 'days'))

  if (diffInDays > 1) {
    return `${diffInDays} Days`
  }

  const diffInHours = Math.abs(firstTime.diff(secondTime, 'hours'))
  const remainingHours = diffInHours - 24

  if (diffInDays > 0 && remainingHours > 0) {
    return remainingHours > 1
      ? `1 Day, ${remainingHours} Hours`
      : '1 Day, 1 Hour'
  }

  if (diffInDays > 0 && remainingHours === 0) {
    return '1 Day'
  }

  if (diffInHours > 1) {
    return `${diffInHours} Hours`
  }

  if (diffInHours === 1) {
    return `1 Hour`
  }

  return 'Less than 1 Hour'
}

interface GetRemainingTimeArguments {
  velocityControl: GetRemainingTimeVelocityControlTypeFragment
  timezone?: string
}

export const getRemainingTime = ({
  velocityControl,
  timezone,
}: GetRemainingTimeArguments) => {
  const now = dayjs()

  const endOfCurrentWindow = getEndOfCurrentWindow({
    velocityControl,
    now,
    timezone,
  })

  return {
    remainingTime: `${getDaysOrHoursBetween(dayjs(), endOfCurrentWindow)} Left`,
    endOfCurrentWindow,
  }
}

getRemainingTime.fragments = {
  VelocityControlType: gql`
    fragment GetRemainingTimeVelocityControlTypeFragment on VelocityControlType {
      ...GetEndOfCurrentWindowVelocityControlTypeFragment
    }

    ${getEndOfCurrentWindow.fragments.VelocityControlType}
  `,
}
