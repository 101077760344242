import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { CardEmptyViewDebit } from '../components/CardEmptyViewDebit/CardEmptyViewDebit'
import { CardEmptyViewMessage } from '../components/CardEmptyViewMessage/CardEmptyViewMessage'

interface DebitCardsEmptyProps {
  onAddCard: () => void
}

export const DebitCardsEmpty = ({ onAddCard }: DebitCardsEmptyProps) => {
  const { userRole } = useLoggedInUser()

  if (userRole === CustomerRole.ADMIN) {
    return <CardEmptyViewDebit onAddCard={onAddCard} />
  }

  return <CardEmptyViewMessage />
}
