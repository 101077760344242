import { EntityCategories } from '@npco/mp-gql-types'
import { z } from 'zod'

const EditDebitCardTransactionCategorySchema = z.literal('edit')

export type EditDebitCardTransactionCategoryStage = z.infer<
  typeof EditDebitCardTransactionCategorySchema
>

export const EditDebitCardTransactionCategoryStateSchema = z.object({
  stage: EditDebitCardTransactionCategorySchema,
  debitCardTransactionUuid: z.string(),
  contactUuid: z.union([z.string(), z.undefined()]),
  category: z.nativeEnum(EntityCategories).nullable(),
  subcategoryDetails: z
    .object({
      id: z.string(),
      name: z.string(),
      predefined: z.boolean(),
    })
    .nullable(),
})

export type EditDebitCardTransactionCategoryState = z.infer<
  typeof EditDebitCardTransactionCategoryStateSchema
>

export const EditDebitCardTransactionCategoryLocationStateSchema = z.object({
  EditDebitCardTransactionCategoryModal:
    EditDebitCardTransactionCategoryStateSchema,
})
