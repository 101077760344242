import { gql } from '@apollo/client'
import { ExtendedColumnDef } from '@npco/ui-table'
import { useTranslations } from '@npco/utils-translations'
import { TableCellByline } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useSkeletonLoaderWidth } from 'hooks/useSkeletonLoaderWidth'

import { translations } from '../SimTable.i18n'
import { SimDeviceCell } from './SimDeviceCell'
import { SimInfoCellFragment } from './SimInfoCell.generated'
import { SimSiteCell } from './SimSiteCell'

export const SimInfoCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, SimInfoCellFragment>) => {
  const t = useTranslations(translations)
  const columnDefValue = column.columnDef as ExtendedColumnDef<T>
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<SimInfoCellFragment>()
  const byline = data?.deviceName ?? '-'
  const value = data?.siteName ?? t('unassigned')

  const skeletonLoaderWidth = useSkeletonLoaderWidth({
    rowIndex: row.index,
    sampleWidthArray: ['73%', '73%', '50%'],
  })

  return (
    <TableCellByline
      byline={byline}
      dataTestId={`sim-mobile-row-${row.index}-info`}
      cellSize={columnDefValue.cellSize}
      justifyContent="flex-start"
      hasDivider={false}
      isLoading={isLoading}
      key={cell.id}
      label={value}
      skeletonProps={{
        width: skeletonLoaderWidth,
      }}
    />
  )
}

SimInfoCell.fragments = {
  Sim: gql`
    fragment SimInfoCellFragment on Sim {
      ...SimDeviceCellFragment
      ...SimSiteCellFragment
    }
    ${SimDeviceCell.fragments.Sim}
    ${SimSiteCell.fragments.Sim}
  `,
}
