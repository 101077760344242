import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import {
  BadgeSize,
  NumberBadge,
  NumberBadgeVariant,
  TableCellBasic,
  TableCellSize,
  TableCellSkeleton,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import {
  QuantityCellCashFlowCategoryNetAmountFragment,
  QuantityCellCashFlowContactNetAmountFragment,
} from './QuantityCell.generated'

type QuantityCellFragment =
  | QuantityCellCashFlowContactNetAmountFragment
  | QuantityCellCashFlowCategoryNetAmountFragment

export const QuantityCell = <T,>({
  cell,
  table,
  getValue,
  row,
  column,
}: CellContext<T, QuantityCellFragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<QuantityCellFragment>()

  return (
    <TableCellBasic
      key={cell.id}
      dataTestId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      justifyContent="center"
    >
      {isLoading && (
        <TableCellSkeleton
          width={skeletonLoaderWidth}
          size={TableCellSize.SMALL}
        />
      )}

      {!isLoading && (
        <NumberBadge
          size={BadgeSize.Large}
          text={data?.noOfTransaction.toString() ?? '0'}
          variant={NumberBadgeVariant.Default}
        />
      )}
    </TableCellBasic>
  )
}

QuantityCell.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment QuantityCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      noOfTransaction
    }
  `,

  CashFlowContactNetAmount: gql`
    fragment QuantityCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      noOfTransaction
    }
  `,
}
