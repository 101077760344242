import { useMemo } from 'react'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { getInvoiceTotals } from '../../InvoiceItemsAccordion/hooks/useInvoiceItemsCalculations'
import {
  availableEntityTagsMap,
  MergeTag,
} from '../InvoiceDeliveryAccordion.constants'
import { getUpdatedEntityTag } from './useEmailMergeTags.utils'

const clonedAvailableEntityTagsMap = { ...availableEntityTagsMap }

export const useEmailMergeTags = () => {
  const { values } = useFormikContext<InvoiceFormFields>()
  const { totalFormatted } = getInvoiceTotals({ values })

  const { items } = values

  const hasOneItem = items.length === 1
  const nameIsDefault = items[0]?.name === ''
  const descriptionIsDefault = items[0]?.name === ''
  const priceIsDefault = items[0]?.price?.intValue === 0

  const hasOnlyDefaultItem =
    hasOneItem && nameIsDefault && descriptionIsDefault && priceIsDefault

  const totalPrice = hasOnlyDefaultItem ? '' : totalFormatted

  const {
    customer: { attentionToContact, payerContact },
    referenceNumber,
    schedule: { dueDate },
  } = values

  const updatedEntityMergeTagsMap = useMemo(() => {
    return Object.keys(availableEntityTagsMap).reduce<
      typeof availableEntityTagsMap
    >((acc, key) => {
      const entityKey = key as MergeTag

      const isContactEntityMergeTag =
        entityKey === MergeTag.Customer ||
        entityKey === MergeTag.PersonName ||
        entityKey === MergeTag.BusinessName
      const isAnyContactExist = Boolean(payerContact || attentionToContact)

      if (isContactEntityMergeTag && !isAnyContactExist) {
        acc[entityKey] = { ...availableEntityTagsMap[entityKey] }
        return acc
      }

      acc[entityKey] = getUpdatedEntityTag({
        attentionToContact,
        dueDate,
        entityKey,
        payerContact,
        referenceNumber,
        totalPrice,
      })

      return acc
    }, clonedAvailableEntityTagsMap)
  }, [attentionToContact, payerContact, referenceNumber, dueDate, totalPrice])

  return {
    mergeTagsMap: updatedEntityMergeTagsMap,
  }
}
