import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { isNil } from 'ramda'

import { useGetUnreadNotificationCount } from 'hooks/useGetUnreadNotificationCount/useGetUnreadNotificationCount'

export const UseNotificationsCountSidebarDeps = {
  useLoggedInUser,
  useGetUnreadNotificationCount,
}

export const useNotificationsCountSidebar = () => {
  const { useLoggedInUser, useGetUnreadNotificationCount } =
    UseNotificationsCountSidebarDeps

  const { userData } = useLoggedInUser()

  const customerUuid = userData?.id ?? ''
  const skip = !customerUuid

  const { count } = useGetUnreadNotificationCount({
    customerUuid,
    skip,
  })

  const shouldShowCount = !isNil(count) && count > 0

  return {
    count,
    shouldShowCount,
    skip,
  }
}
