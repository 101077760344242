import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { PreviewInvoiceInput } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  GetInvoicePreview as GetInvoicePreviewResponse,
  GetInvoicePreviewVariables,
} from 'types/gql-types/GetInvoicePreview'

import { GetInvoicePreview } from '../graphql/getInvoicePreview'

export const useGetInvoicePdf = () => {
  const entityUuid = useSelectedEntityUuid()
  const { refetch: getInvoicePreview } = useQuery<
    GetInvoicePreviewResponse,
    GetInvoicePreviewVariables
  >(GetInvoicePreview, {
    skip: true,
  })

  return {
    getDownloadLink: useCallback(
      async (variables: string | PreviewInvoiceInput) => {
        if (typeof variables === 'string') {
          const response = await getInvoicePreview({
            entityUuid,
            invoice: {
              referenceNumber: variables,
            },
          })

          return response.data.getInvoicePreview
        }

        const response = await getInvoicePreview({
          entityUuid,
          invoice: variables,
        })
        return response.data.getInvoicePreview
      },
      [entityUuid, getInvoicePreview]
    ),
  }
}
