import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountRowDebitCardAccountConnectionV2FragmentDoc } from '../Components/AccountRow.generated'

const defaultOptions = {} as const
export type XeroAccountsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type XeroAccountsQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename?: 'DebitCardAccountV2'
      id: string
      name: string
      status: Types.DebitCardAccountStatus
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        animation: string | null
        image: string | null
        images: Array<{
          __typename?: 'Image'
          url: string
          size: Types.ImageSize
        }> | null
      } | null
    } | null>
  }
  getXeroBankfeedsConfiguration: {
    __typename?: 'XeroBankfeedsConfiguration'
    organisationName: string | null
    bankAccounts: Array<{
      __typename?: 'EnabledAccountDetails'
      id: string
      accountConnectionStatus: Types.AccountConnectionStatus
    }>
  }
  getConnectionState: {
    __typename?: 'Connection'
    id: string | null
    status: Types.ConnectionStatus | null
    errorType: Types.ConnectionErrorType | null
  }
}

export const XeroAccountsQuery = gql`
  query XeroAccountsQuery($entityUuid: ID!) {
    getDebitCardAccountsV2(entityUuid: $entityUuid, limit: 50) {
      accounts {
        id
        name
        status
      }
      ...AccountRowDebitCardAccountConnectionV2Fragment
    }
    getXeroBankfeedsConfiguration(entityUuid: $entityUuid) {
      bankAccounts {
        id
        accountConnectionStatus
      }
      organisationName
    }
    getConnectionState(
      entityUuid: $entityUuid
      connectionType: XERO_BANKFEEDS
    ) {
      id
      status
      errorType
    }
  }
  ${AccountRowDebitCardAccountConnectionV2FragmentDoc}
` as unknown as TypedDocumentNode<
  XeroAccountsQueryResponse,
  XeroAccountsQueryVariables
>

/**
 * __useXeroAccountsQuery__
 *
 * To run a query within a React component, call `useXeroAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useXeroAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useXeroAccountsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useXeroAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    XeroAccountsQueryResponse,
    XeroAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<XeroAccountsQueryResponse, XeroAccountsQueryVariables>(
    XeroAccountsQuery,
    options
  )
}
export function useXeroAccountsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    XeroAccountsQueryResponse,
    XeroAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    XeroAccountsQueryResponse,
    XeroAccountsQueryVariables
  >(XeroAccountsQuery, options)
}
export type XeroAccountsQueryHookResult = ReturnType<
  typeof useXeroAccountsQuery
>
export type XeroAccountsQueryLazyQueryHookResult = ReturnType<
  typeof useXeroAccountsQueryLazyQuery
>
export type XeroAccountsQueryQueryResult = Apollo.QueryResult<
  XeroAccountsQueryResponse,
  XeroAccountsQueryVariables
>
