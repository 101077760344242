import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  DebitCardAddressInput,
  DebitCardColour,
  DebitCardProductType,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { v4 } from 'uuid'

import { VelocityControl } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { cacheDebitCardStatus } from 'utils/banking/cacheDebitCardStatus/cacheDebitCardStatus'
import { cacheNewCorporateCard } from 'utils/banking/cacheNewCorporateCard/cacheNewCorporateCard'
import { cacheNewDebitCard } from 'utils/banking/cacheNewDebitCard/cacheNewDebitCard'
import { UNEXPECTED_ERROR } from 'types/errors'
import { getVelocityControlFromInput } from 'pages/GlobalModals/AddCardModal/pages/CreateCardStages/ReviewCardStage/ReviewCard/hooks/useRequestNewCard/useRequestNewCard.utils'

import { ReportLostDebitCard } from './graphql/reportLostDebitCard'
import {
  ReportLostDebitCardMutationResponse,
  ReportLostDebitCardMutationVariables,
} from './graphql/reportLostDebitCard.generated'
import {
  REPLACE_CARD_ERROR,
  REPORT_LOST_CARD_ERROR,
} from './useReportLostCard.utils'

type UseReportLostCardProps = {
  velocityControl: VelocityControl | null
  entityAddressTimezone?: string
}

export const useReportLostCard = ({
  velocityControl,
  entityAddressTimezone,
}: UseReportLostCardProps) => {
  const entityUuid = useSelectedEntityUuid()
  const [reportLostCardMutation, { loading }] = useMutation<
    ReportLostDebitCardMutationResponse,
    ReportLostDebitCardMutationVariables
  >(ReportLostDebitCard, {
    update: (cache, result) => {
      const response = result.data?.reportLostDebitCard
      if (!response?.lostCard) {
        return
      }
      cacheDebitCardStatus({
        cache,
        cardId: response.lostCard.id,
        status: response.lostCard.status,
      })
      if (response.replacementCard) {
        if (
          response.replacementCard.productType === DebitCardProductType.EXPENSE
        ) {
          cacheNewCorporateCard({
            entityUuid,
            cache,
            newCard: {
              ...response.replacementCard,
              velocityControl: velocityControl
                ? {
                    ...getVelocityControlFromInput({
                      velocityControlInput: {
                        idempotencyKey: v4(),
                        ...velocityControl,
                      },
                      entityAddressTimezone,
                    }),
                    __typename: 'VelocityControlType',
                  }
                : null,
            },
          })
          return
        }

        cacheNewDebitCard({
          cache,
          newCard: response.replacementCard,
          entityUuid,
        })
      }
    },
  })
  const reportLostCard = useCallback(
    async ({
      cardId,
      colour,
      address,
    }: {
      cardId: string
      colour?: DebitCardColour
      address?: DebitCardAddressInput
    }) => {
      try {
        const reportLostCardResult = await reportLostCardMutation({
          variables: {
            entityUuid,
            input: {
              cardId: { cardUuid: cardId },
              replacementRequired: true,
              colour,
              address,
            },
          },
        })

        const mutationResultData =
          reportLostCardResult.data?.reportLostDebitCard

        if (!mutationResultData?.lostCard) {
          return REPORT_LOST_CARD_ERROR
        }

        if (!mutationResultData.replacementCard) {
          return REPLACE_CARD_ERROR
        }

        return mutationResultData.replacementCard.id
      } catch (err) {
        ErrorLogger.report('Error reporting lost card', err)
        return UNEXPECTED_ERROR
      }
    },
    [entityUuid, reportLostCardMutation]
  )

  return { reportLostCard, isReportingLostCard: loading }
}
