import { useCallback } from 'react'
import { Flex, ToggleForm } from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { FormButtons } from '../FormButtons'
import { ToggleLabel } from '../SettingsGeneral/SettingsGeneralForm/SettingsGeneralForm.styled'
import { ReminderFields } from './ReminderFields'
import {
  InvoiceSettingsRemindersFormFields,
  REMINDER_AFTER_FIELD,
  REMINDER_BEFORE_FIELD,
  REMINDER_ON_FIELD,
} from './SettingsReminders.types'

export const MAX_REMINDER_VALUE = 99

export const RemindersForm = () => {
  const { values, submitForm, resetForm } =
    useFormikContext<InvoiceSettingsRemindersFormFields>()

  const beforeDateValues = values[REMINDER_BEFORE_FIELD]
  const afterDateValues = values[REMINDER_AFTER_FIELD]

  const handleNewBeforeRow = useCallback(() => {
    if (beforeDateValues.length === 0) {
      return 7
    }

    const lastIndexValue = beforeDateValues[beforeDateValues.length - 1]

    if (lastIndexValue === 1 || !lastIndexValue) {
      return ''
    }

    return lastIndexValue - 1
  }, [beforeDateValues])

  const handleNewAfterRow = useCallback(() => {
    if (afterDateValues.length === 0) {
      return 1
    }

    const lastIndexValue = afterDateValues[afterDateValues.length - 1]

    if (!lastIndexValue || lastIndexValue === MAX_REMINDER_VALUE) {
      return ''
    }

    return Math.floor(lastIndexValue) + 1
  }, [afterDateValues])

  return (
    <>
      <Flex mb="24px" alignItems="center" justifyContent="space-between">
        <ToggleLabel htmlFor={REMINDER_ON_FIELD}>
          {translate('page.invoicesSettings.reminders.labels.onDueDate')}
        </ToggleLabel>
        <Field
          component={ToggleForm}
          name={REMINDER_ON_FIELD}
          value={values[REMINDER_ON_FIELD]}
        />
      </Flex>
      <ReminderFields
        getNewRowValue={handleNewBeforeRow}
        name={REMINDER_BEFORE_FIELD}
        translations={{
          emptyState: translate(
            'page.invoicesSettings.reminders.beforeDueDateEmptyState'
          ),
          heading: translate(
            'page.invoicesSettings.reminders.labels.beforeDueDate'
          ),
          rowLabel: translate(
            'page.invoicesSettings.reminders.labels.beforeDueDateRow'
          ),
        }}
      />
      <ReminderFields
        getNewRowValue={handleNewAfterRow}
        name={REMINDER_AFTER_FIELD}
        translations={{
          emptyState: translate(
            'page.invoicesSettings.reminders.afterDueDateEmptyState'
          ),
          heading: translate(
            'page.invoicesSettings.reminders.labels.afterDueDate'
          ),
          rowLabel: translate(
            'page.invoicesSettings.reminders.labels.afterDueDateRow'
          ),
        }}
      />
      <FormButtons onSave={submitForm} onCancel={resetForm} />
    </>
  )
}
