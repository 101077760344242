import { useMemo } from 'react'
import { useGoToEditDebitCardTransactionImages } from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-routing'

import { useTransactionImages } from 'hooks/useTransactionImages'
import { useUploadedImages } from 'hooks/useUploadedImages/useUploadedImages'

import { UploadReceiptsModal } from './UploadReceiptsModal/UploadReceiptsModal'

type UploadReceiptsProps = {
  transactionUuid: string
  maxFiles: number
}

export const UploadReceipts = ({
  transactionUuid,
  maxFiles,
}: UploadReceiptsProps) => {
  const { goToEditDebitCardTransactionImages } =
    useGoToEditDebitCardTransactionImages()

  const { images, isLoadingImages, isDownloadingFiles } = useTransactionImages({
    transactionUuid,
    fetchPolicy: 'cache-first',
  })

  const { transactionCombinedImages, addImages } = useUploadedImages({
    transactionUuid,
    fetchedImages: images,
  })

  const transactionCombinedImagesIds = useMemo(
    () => transactionCombinedImages.map(({ id }) => id),
    [transactionCombinedImages]
  )

  const allowedFilesLength = maxFiles - transactionCombinedImages.length
  const areFilesLoading = isLoadingImages || isDownloadingFiles

  return (
    <UploadReceiptsModal
      isOpen
      maxFiles={maxFiles}
      remainingFiles={allowedFilesLength}
      isLoadingImages={areFilesLoading}
      transactionCombinedImagesIds={transactionCombinedImagesIds}
      onCancel={() => goToEditDebitCardTransactionImages(null)}
      addUploadedImages={addImages}
      transactionUuid={transactionUuid}
    />
  )
}
