import { useMemo, useState } from 'react'
import { isNil } from 'lodash-es'
import { CategoricalChartState } from 'recharts/types/chart/types'

export const useTooltipOnMouseEvent = <T,>(items: T[]) => {
  const [activeState, setActiveState] = useState<CategoricalChartState>({})

  const onChartMouseEvent = useMemo(
    () => (nextState: CategoricalChartState) => {
      setActiveState(nextState)
    },
    [setActiveState]
  )

  const activeItem = useMemo(() => {
    return isNil(activeState.activeTooltipIndex)
      ? undefined
      : items?.[activeState.activeTooltipIndex]
  }, [activeState.activeTooltipIndex, items])

  return {
    onChartMouseEvent,
    activeItem,
    activeItemCoordinateX: activeState?.chartX,
    isTooltipActive: activeState?.isTooltipActive,
  }
}
