import {
  MessageSize,
  MessageWithIcon,
  TickIcon,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { AuthLayout } from 'layouts'

import { StyledAgreement } from './CardHolderDetailsOptOut.styled'

export const OptOutSuccessPage = () => {
  return (
    <AuthLayout isLogInPage>
      <MessageWithIcon
        title={translate('page.optOutCardHolderDetails.successPage.heading')}
        description={translate(
          'page.optOutCardHolderDetails.successPage.description'
        )}
        align="left"
        size={MessageSize.XLARGE}
        margin={0}
        icon={<TickIcon width="24" height="24" />}
      />
      <StyledAgreement>
        {translate('page.optOutCardHolderDetails.agreement')}
      </StyledAgreement>
    </AuthLayout>
  )
}
