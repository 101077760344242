import { BUTTON_SIZE, COLOR, useModalState } from '@npco/zeller-design-system'
import { InvoiceItem } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField } from 'formik'

// NOTE: move calculator svg icon to design system
import { ReactComponent as Calculator } from 'assets/svg/calculator.svg'
import { translate } from 'utils/translations'

import { InvoiceItemCalculateModal } from './InvoiceItemCalculateModal/InvoiceItemCalculateModal'
import { CalculatorIcon } from './InvoiceItemCalculator.styled'

export interface InvoiceItemCalculatorProps {
  index: number
  lineIndex: number
  name: string
}

export const InvoiceItemCalculator = ({
  index,
  lineIndex,
  name,
}: InvoiceItemCalculatorProps) => {
  const { closeModal, isModalOpen, openModal } = useModalState()
  const [item] = useField<InvoiceItem>(name)

  const ariaLabel = translate(
    'page.invoice.formSections.items.lineItemAriaLabel',
    {
      index: lineIndex,
      field: 'calculator',
    }
  )

  const { hasCalculation } = item.value

  const dataTestId = hasCalculation
    ? `invoicing-invoice-line-item-calculator-${index}-active`
    : `invoicing-invoice-line-item-calculator-${index}-inactive`

  return (
    <>
      <CalculatorIcon
        aria-label={ariaLabel}
        data-testid={dataTestId}
        icon={Calculator}
        iconEnabledColor={hasCalculation ? COLOR.BLUE_1000 : COLOR.GREY_550}
        onClick={openModal}
        size={BUTTON_SIZE.MEDIUM}
      />
      {isModalOpen && (
        <InvoiceItemCalculateModal
          closeModal={closeModal}
          name={name}
          isTaxApplicable={item.value.taxApplicable}
        />
      )}
    </>
  )
}
