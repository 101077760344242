import { ApolloCache } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'

interface CacheDebitCardStatusProps {
  cache: ApolloCache<object>
  status: DebitCardStatus
  cardId: string
}
export const cacheDebitCardStatus = ({
  cache,
  cardId,
  status,
}: CacheDebitCardStatusProps) => {
  cache.modify({
    id: cache.identify({ id: cardId, __typename: 'DebitCardV2' }),
    fields: {
      status() {
        return status
      },
    },
  })
}
