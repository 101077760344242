import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { isEqual } from 'lodash-es'

import { ROOT } from 'const/routes'
import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { GetTransactionsLocalState } from 'components/Filters/PillFilters/TransactionsFilters/getTransactionsLocalState'
import { TransactionsDefaultLocalState } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters.constants'
import { CacheLocalStateTransactions } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters.types'
import { useTransactionsLocalCache } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsLocalCache'

import { CacheLocalStateEntityScopedVariable } from '../../../../../graphql/cache.types'
import { useTransactionsContext } from '../../TransactionsContext/TransactionsContext'
import { translations } from '../TransactionsPrimaryHeaderRow/TransactionsPrimaryHeaderRow.i18n'

const deps = {
  useTransactionsContext,
  useTranslations,
  useTransactionsLocalCache,
}
export { deps as NewTransactionsPrimaryHeaderRowDeps }

export const NewTransactionsPrimaryHeaderRow = () => {
  const entityUuid = useSelectedEntityUuid()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = deps.useTranslations(translations)

  const { splitPaymentId } = deps.useTransactionsContext()
  const { data: cacheData } = useQuery<
    CacheLocalStateTransactions<'transactions'>,
    CacheLocalStateEntityScopedVariable
  >(GetTransactionsLocalState, { variables: { entityUuid } })
  const isFiltersSelected = !isEqual(
    cacheData?.local.transactions.filters,
    TransactionsDefaultLocalState.filters
  )
  const { setIsFiltersVisible } = deps.useTransactionsLocalCache('transactions')

  const handleToggleFilters = useCallback(() => {
    return setIsFiltersVisible(!cacheData?.local.transactions.isFiltersVisible)
  }, [cacheData?.local.transactions.isFiltersVisible, setIsFiltersVisible])

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex flex={1} height="48px">
        <Flex
          flex={1}
          alignItems="center"
          gap="10px"
          justifyContent="space-between"
        >
          <Breadcrumb>
            {splitPaymentId ? (
              <>
                <Breadcrumb.Item>
                  <Breadcrumb.Link
                    to={ROOT.ORGS.ORG(shortEntityId).TRANSACTIONS.path}
                  >
                    {t('transactions')}
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item isLastChild>
                  <Breadcrumb.Text> {t('relatedTransactions')}</Breadcrumb.Text>
                </Breadcrumb.Item>
              </>
            ) : (
              <Breadcrumb.Item>
                <Breadcrumb.Text>{t('transactions')}</Breadcrumb.Text>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
          {!splitPaymentId && (
            <DashboardFiltersButton
              disabled={false}
              isActive={!!cacheData?.local.transactions.isFiltersVisible}
              onClick={handleToggleFilters}
              showIndicator={isFiltersSelected}
            />
          )}
        </Flex>
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
