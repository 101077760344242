import { Outlet } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { PageTemplate } from '@npco/zeller-design-system'

import { AccountsLayoutPrimaryHeaderRow } from './AccountsLayoutPrimaryHeaderRow/AccountsLayoutPrimaryHeaderRow'
import { AccountsLayoutSecondaryHeaderRow } from './AccountsLayoutSecondaryHeaderRow/AccountsLayoutSecondaryHeaderRow'
import { AccountsLayoutTertiaryHeaderRow } from './AccountsLayoutTertiaryHeaderRow/AccountsLayoutTertiaryHeaderRow'
import { useAccountsLayout } from './hooks/useAccountsLayout/useAccountsLayout'

export const AccountsLayoutDeps = {
  useAccountsLayout,
}

export const AccountsLayout = () => {
  const flags = useFeatureFlags()
  const { useAccountsLayout } = AccountsLayoutDeps

  const {
    entity,
    isAllTransactionsRoute,
    isFiltersDisabled,
    isFiltersInDefaultState,
    isFiltersVisible,
    isLoadingEntity,
    setIsFiltersVisible,
  } = useAccountsLayout()

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <AccountsLayoutPrimaryHeaderRow
          entity={entity}
          isLoadingEntity={isLoadingEntity}
          isAllTransactionsRoute={isAllTransactionsRoute}
          isFiltersVisible={isFiltersVisible}
          isFiltersInDefaultState={isFiltersInDefaultState}
          isFiltersDisabled={isFiltersDisabled}
          setIsFiltersVisible={setIsFiltersVisible}
        />
      }
      HeaderSecondaryRow={<AccountsLayoutSecondaryHeaderRow />}
      HeaderTertiaryRow={
        !flags.AccountsBalanceChartOnTransactions && (
          <AccountsLayoutTertiaryHeaderRow
            isAllTransactionsRoute={isAllTransactionsRoute}
            isFiltersVisible={isFiltersVisible}
          />
        )
      }
    >
      <Outlet />
    </PageTemplate>
  )
}
