import { AccountStatementType } from '@npco/mp-gql-types'

import { AccountStatementsView } from '../AccountStatementsView'
import { useAccountStatements } from '../hooks'

export const QuarterlyStatements = () => {
  const {
    accountStatements,
    isLoading,
    hasError,
    refetchStatementDownloadLink,
    accountId,
    refetch,
  } = useAccountStatements({ statementType: AccountStatementType.QUARTERLY })

  return (
    <AccountStatementsView
      accountStatements={accountStatements}
      isLoading={isLoading}
      hasError={hasError}
      onRetry={refetch}
      statementType={AccountStatementType.QUARTERLY}
      refetchStatementDownloadLink={refetchStatementDownloadLink}
      accountId={accountId}
    />
  )
}
