import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { DEFAULT_ENTITY, PORTAL_ROUTE, ROOT } from 'const/routes'

export const useRedirectToDefaultOrg = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const flags = useFeatureFlags()
  const shortEntityId = useSelectedShortEntityUuid()

  const { pathname, state, search } = location

  useEffect(() => {
    if (pathname.startsWith(PORTAL_ROUTE)) {
      navigate(
        {
          pathname: pathname.replace(
            PORTAL_ROUTE,
            ROOT.ORGS.ORG(shortEntityId).path
          ),
          search,
        },
        { replace: true, state }
      )
    }
  }, [navigate, pathname, search, shortEntityId, state])

  useEffect(() => {
    if (!flags.MultiEntity) {
      return
    }

    if (
      pathname.startsWith(ROOT.ORGS.ORG(DEFAULT_ENTITY).path) &&
      shortEntityId
    ) {
      navigate(
        {
          pathname: pathname.replace(
            ROOT.ORGS.ORG(DEFAULT_ENTITY).path,
            ROOT.ORGS.ORG(shortEntityId).path
          ),
          search,
        },
        { replace: true, state }
      )
    }
  }, [flags.MultiEntity, navigate, pathname, search, shortEntityId, state])
}
