import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ButtonFill,
  ButtonGhost,
  Flex,
  InputWithoutLabel,
  SearchIcon,
} from '@npco/zeller-design-system'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { translate } from 'utils/translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { FilterState } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFilters.utils'
import { BottomWrapper } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer.styled'

interface SearchFilterProps {
  debounceDuration?: number
  setFilterState: (value: FilterState) => void
}

export const SearchFilter = ({
  debounceDuration = 100,
  setFilterState,
}: SearchFilterProps) => {
  const { filterInput, setSearchFilter } = useInvoicesContext()

  const [nextSearchFilter, setNextSearchFilter] = useState(
    filterInput?.textSearchFilter ?? ''
  )

  const [search$] = useState(new Subject<string>())
  const ref = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      search$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((nextSearch) => setNextSearchFilter(nextSearch.trim()))
    )

    return () => subscription.unsubscribe()
  }, [debounceDuration, search$, setNextSearchFilter])

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      search$.next(event.target.value),
    [search$]
  )

  const handleApply = useCallback(() => {
    setSearchFilter(nextSearchFilter)
    setFilterState(FilterState.FilterAndSort)
  }, [nextSearchFilter, setFilterState, setSearchFilter])

  const handleClear = useCallback(() => {
    if (ref.current) {
      ref.current.value = ''
    }
    setNextSearchFilter('')
  }, [setNextSearchFilter])

  return (
    <Flex flexDirection="column" flexGrow={1} data-testid="search-filter">
      <Flex flexDirection="column" flexGrow={1}>
        <InputWithoutLabel
          autoFocus
          data-testid="search-filter-input"
          defaultValue={filterInput?.textSearchFilter || ''}
          name="search-filter-input"
          icon={<SearchIcon />}
          onChange={handleOnChange}
          placeholder={translate(
            'page.invoiceTable.mobile.filterLabels.search'
          )}
          ref={ref}
        />
      </Flex>
      <BottomWrapper>
        <ButtonGhost onClick={handleClear} dataTestId="clear-filters-btn">
          {translate('shared.clear')}
        </ButtonGhost>
        <ButtonFill dataTestId="apply-filters-btn" onClick={handleApply}>
          {translate('shared.apply')}
        </ButtonFill>
      </BottomWrapper>
    </Flex>
  )
}
