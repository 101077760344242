import { ButtonGhostIconRight } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ManageButton = styled(ButtonGhostIconRight)<{
  $isExpanded: boolean
}>`
  ${({ $isExpanded, theme }) =>
    $isExpanded && `background-color: ${theme.colors.BLUE_80};`}

  svg {
    transform-origin: center center;
    transition: 250ms ease-in-out;
    transition-property: transform;
    ${({ $isExpanded }) => $isExpanded && 'transform: rotateZ(-180deg)'};
  }
`
