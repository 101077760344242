import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Flex,
  PopperMobileTitleGroup,
  StyledPopperModalMobile,
} from '@npco/zeller-design-system'
import { includes } from 'ramda'
import { v4 as randomUUID } from 'uuid'

import { useMultiSelectItemsHandlers } from '../../hooks/useMultiSelectItemsHandlers'
import {
  ALL_VALUE,
  MultiSelectBasicProps,
  MultiSelectItemBasic,
} from '../../MultiSelect.types'
import {
  StyledOptionsList,
  StyledTopSectionWrapper,
} from './MultiSelectBasicMobile.styled'

export const MultiSelectBasicMobile = <ItemType extends MultiSelectItemBasic>({
  mobileLabel,
  items,
  selectedItems,
  onChange,
  renderTopSection,
  renderBottomSection,
  renderAdditionalControls,
  renderNoItemsPlaceholder,
  allItemsLabel,
  renderItem,
  renderTrigger,
  renderLabel,
  selectStyle,
  selectSize,
  onClose,
  isDisabled,
  hasError,
  onScrollAtBottom,
  mobileOptionsListHeight,
}: MultiSelectBasicProps<ItemType>) => {
  const id = useMemo(() => randomUUID(), [])
  const [isOpen, setIsOpen] = useState(false)
  const { changeSelectedItems, clearAllSelected } = useMultiSelectItemsHandlers(
    {
      onChange,
      items,
      selectedItems,
    }
  )

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    onClose?.(selectedItems)
    setIsOpen(false)
  }

  const shouldDisplayNoItemsPlaceholder = items.length === 0

  const scrollWrapperRef = useRef<HTMLUListElement>(null)
  const [bottomRef, setBottomRef] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!isOpen) {
      return undefined
    }

    const root = scrollWrapperRef.current
    const target = bottomRef
    if (!(root && target)) {
      return undefined
    }

    // Skip if not using the new API and the IntersectionObserver isn't available.
    // This stops tests failing if a IntersectionObserver mock is missing but
    // the onScrollAtBottom prop isn't used.
    if (!onScrollAtBottom && !window.IntersectionObserver) {
      return undefined
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries
        if (entry?.isIntersecting) {
          onScrollAtBottom?.()
        }
      },
      { root }
    )
    observer.observe(target)
    return () => {
      observer.disconnect()
    }
  }, [bottomRef, scrollWrapperRef, isOpen, onScrollAtBottom])

  return (
    <div data-testid="multiselect-mobile">
      {renderLabel?.({
        htmlFor: id,
        id: `label-${id}`,
      })}
      {renderTrigger({
        id,
        onClick: open,
        isOpen,
        selectStyle,
        size: selectSize,
        disabled: isDisabled,
        hasError,
      })}

      <StyledPopperModalMobile
        isOpen={isOpen}
        overlayClassName="modal-basic-overlay"
      >
        <Flex flexDirection="column" style={{ height: '100%' }}>
          {mobileLabel && (
            <PopperMobileTitleGroup onClick={close}>
              {mobileLabel}
            </PopperMobileTitleGroup>
          )}
          {renderTopSection && (
            <StyledTopSectionWrapper>
              {renderTopSection({
                isOpen,
                onClose: close,
              })}
            </StyledTopSectionWrapper>
          )}
          {renderAdditionalControls?.({ closeMenu: close })}

          <StyledOptionsList
            ref={scrollWrapperRef}
            isEmpty={false}
            $height={mobileOptionsListHeight}
          >
            {allItemsLabel &&
              renderItem?.({
                label: allItemsLabel,
                isChecked: selectedItems.length === items.length,
                id: ALL_VALUE,
                onClick: () =>
                  changeSelectedItems({
                    value: ALL_VALUE,
                    label: allItemsLabel,
                  } as ItemType),
                key: ALL_VALUE,
                role: 'option',
                item: { value: ALL_VALUE, label: allItemsLabel } as ItemType,
              })}
            {shouldDisplayNoItemsPlaceholder &&
              renderNoItemsPlaceholder?.({ closeMenu: close })}
            {!shouldDisplayNoItemsPlaceholder &&
              items.map((item) =>
                renderItem?.({
                  key: item.value,
                  label: item.label,
                  // NOTE: using ramda to ensure non primitive equality
                  isChecked: includes(item, selectedItems),
                  id: item.value,
                  onClick: () => changeSelectedItems(item),
                  item,
                  role: 'option',
                })
              )}
            <div
              ref={(ref) => {
                // Need to set ref via state to trigger useEffect via. re-render.
                setBottomRef(ref)
              }}
              style={{
                width: '100%',
                background: 'transparent',
                height: 1,
              }}
            />
          </StyledOptionsList>
          {renderBottomSection?.({
            isOpen,
            onClick: clearAllSelected,
            onClose: close,
          })}
        </Flex>
      </StyledPopperModalMobile>
    </div>
  )
}
