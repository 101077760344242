import { Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DetailsListItem } from 'components/DetailsListItem'

import { AccountDetailsListItem } from '../AccountDetailsListItem'

interface Props {
  cardAccountName?: string | null
  debitCardName?: string | null
  debitCardMaskedPan?: string | null
}

export const CardDetailsListItems = ({
  cardAccountName,
  debitCardName,
  debitCardMaskedPan,
}: Props) => {
  return (
    <Box mt="24px">
      <AccountDetailsListItem accountName={cardAccountName} />

      <DetailsListItem
        label={translate('page.transactionDetails.cardName')}
        value={debitCardName}
      />
      {debitCardMaskedPan && (
        <DetailsListItem
          label={translate('page.transactionDetails.cardNumber')}
          value={`•••• ${debitCardMaskedPan}`}
        />
      )}
    </Box>
  )
}
