import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { page } from 'translations'

import { SubItem } from '../Sidebar.types'

interface UseSettingsSubItemsProps {
  connectionErrorsCount?: number
}
export const useSettingsSubItems = ({
  connectionErrorsCount,
}: UseSettingsSubItemsProps): SubItem[] => {
  const shortEntityId = useSelectedShortEntityUuid()

  return [
    {
      subitemTitle: page.title.users,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.path,
    },
    {
      subitemTitle: page.title.profileSettings,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.path,
    },
    {
      subitemTitle: page.title.feesAndPricing,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).SETTINGS.FEES_AND_PRICING.path,
    },
    {
      subitemTitle: page.title.connections,
      subitemPath: ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.path,
      numberBadgeCount: connectionErrorsCount,
    },
  ]
}
