import { useCallback, useEffect, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import {
  ErrorMessageForm,
  INPUT_SIZE,
  InputSelectComboboxStandard,
} from '@npco/zeller-design-system'
import { ContactSimpleForm } from 'features/Contacts/components/ContactSimpleForm'

import { BILLER_OR_CONTACT_ROW_ITEM_HEIGHT } from './BpayTargetInput.utils'
import {
  type ItemContactOrBiller,
  useBPayTargetItemsDebounced,
} from './BPayTargetInput/useBPayTargetItems'
import { useComboboxProps } from './BPayTargetInput/useComboboxProps'
import { useFormProps } from './BPayTargetInput/useFormProps'

export type { ItemContactOrBiller as BPayTargetInputItem }

interface BPayTargetInputProps {
  value: ItemContactOrBiller | undefined
  formError?: string
  onValueChange: (value: ItemContactOrBiller | undefined) => void
}

const GET_ITEMS_BY_QUERY_DEBOUNCE_DELAY_MS = 800

export const BPayTargetInput = ({
  value,
  formError,
  onValueChange: onValueChangeProp,
}: BPayTargetInputProps): JSX.Element => {
  const [searchString, setSearchString] = useState<string>('')
  const [queryState, { getItemsByQuery }] = useBPayTargetItemsDebounced({
    debounceDelayMs: GET_ITEMS_BY_QUERY_DEBOUNCE_DELAY_MS,
  })

  useEffect(() => {
    if (!value && searchString.length >= 4) {
      getItemsByQuery(searchString)
    }
  }, [searchString, value, getItemsByQuery])

  const onValueChange: typeof onValueChangeProp = useCallback(
    (value) => {
      setSearchString('')
      onValueChangeProp(value)
    },
    [setSearchString, onValueChangeProp]
  )

  const { isModalOpen, onCreate, openModal, closeModal } = useFormProps({
    onValueChange,
  })

  const {
    label,
    placeholder,
    items,
    isLoading,
    selectedItem,
    onChange,
    inputValue,
    onInputChange,
    onInputClear,
    hasError,
    renderHeader,
    renderItem,
  } = useComboboxProps({
    value,
    onValueChange,
    searchString,
    onQueryChange: setSearchString,
    searchResults: queryState,
    onAddBusiness: openModal,
  })

  return (
    <>
      <InputSelectComboboxStandard
        label={label}
        placeholder={placeholder}
        items={items}
        isLoading={isLoading}
        selectedItem={selectedItem}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onInputClear={onInputClear}
        inputSize={INPUT_SIZE.MEDIUM}
        hasMore={false}
        hasError={hasError || !!formError}
        renderHeader={renderHeader}
        renderItem={renderItem}
        rowItemHeight={BILLER_OR_CONTACT_ROW_ITEM_HEIGHT}
      />
      <ErrorMessageForm hasError={!!formError} errorMessage={formError} />
      {isModalOpen && (
        <ContactSimpleForm
          isModalOpen
          contactType={ContactType.BUSINESS}
          onCreate={onCreate}
          closeModal={closeModal}
          showPersonalDetailsInput={false}
        />
      )}
    </>
  )
}
