import { ImageSize } from '@npco/mp-gql-types'
import { z } from 'zod'

const AccountSchema = z.object({
  name: z.string(),
  id: z.string(),
  icon: z
    .object({
      colour: z.string().nullable(),
      image: z.string().nullable(),
      letter: z.string().nullable(),
      images: z
        .array(
          z.object({
            size: z.nativeEnum(ImageSize),
            url: z.string(),
          })
        )
        .nullable(),
      animation: z.string().nullable(),
    })
    .nullable(),
  accountNumber: z.string().nullable().optional(),
})

export const TransactionDetailsSchema = z.object({
  from: AccountSchema,
  to: AccountSchema,
  amount: z.string(),
  reference: z.string(),
})

export const InternalTransferSchema = z.nullable(
  z.object({
    stage: z.union([z.literal('summary'), z.literal('complete')]),
    transferDetails: TransactionDetailsSchema,
    transactionTimestamp: z.string().optional(),
  })
)

export const InternalTransferLocationSchema = z.object({
  InternalTransferModal: InternalTransferSchema,
})

export type InternalTransferState = z.TypeOf<typeof InternalTransferSchema>

export type TransactionDetails = z.TypeOf<typeof TransactionDetailsSchema>
