import { useCallback, useMemo, useState } from 'react'
import { sortAscendingByProperty } from '@npco/component-mp-common'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'
import { MultiSelectItemBasic } from 'design-system/Components/MultiSelect'

import { CreateItemCategoryMutationResponse } from '../graphql/createItemCategory.generated'
import { useGetItemCategoriesLazyQuery } from '../graphql/getItemCategories.generated'
import { translations } from '../ItemCategories.i18n'
import {
  CATEGORY_NAME_MAX_CHARACTERS,
  getRandomItemColour,
} from '../ItemCategories.utils'
import { useCreateItemCategory } from './useCreateItemCategory'

export interface UseItemCategoriesProps {
  onCreateSuccess?: (
    newCategory: CreateItemCategoryMutationResponse['createItemCategory']
  ) => void
}

export const useItemCategories = ({
  onCreateSuccess,
}: UseItemCategoriesProps) => {
  const t = useTranslations(translations)
  const entityUuid = useSelectedEntityUuid()
  const { createItemCategory, isCreatingItemCategory } = useCreateItemCategory()
  const [errorMessage, setErrorMessage] = useState('')
  const [search, setSearch] = useState('')

  const [getItemCategories, { data, loading: isLoadingItemCategories }] =
    useGetItemCategoriesLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: { limit: 100, entityUuid },
    })

  const categories = data?.getItemCategories.categories

  const items = useMemo(
    () =>
      (categories || []).map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [categories]
  )

  const filteredItems = useMemo(
    () =>
      sortAscendingByProperty<MultiSelectItemBasic>('label')(
        items.filter((item) =>
          item.label.toUpperCase().includes(search.toUpperCase())
        )
      ),
    [items, search]
  )

  const handleCreateCategory = useCallback(async () => {
    const colour = getRandomItemColour()

    const payload = {
      description: null,
      color: colour,
      name: search.trim(),
      sites: null,
    }

    if (payload.name.length > CATEGORY_NAME_MAX_CHARACTERS) {
      setErrorMessage(
        t('categoryNameMaxLengthError', {
          length: `${CATEGORY_NAME_MAX_CHARACTERS}`,
        })
      )
      return
    }

    try {
      setErrorMessage('')
      const result = await createItemCategory(payload)

      if (result.data?.createItemCategory) {
        onCreateSuccess?.(result.data.createItemCategory)
        setSearch('')
      } else {
        showErrorToast(t('itemCategoryErrorNotification'))
      }
    } catch (error) {
      showErrorToast(t('itemCategoryErrorNotification'))
    }
  }, [createItemCategory, onCreateSuccess, search, t])

  return {
    errorMessage,
    filteredItems,
    getItemCategories,
    handleCreateCategory,
    isLoading: isCreatingItemCategory || isLoadingItemCategories,
    items,
    search,
    setSearch,
  }
}
