import { ApolloCache } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import {
  GetAccountDebitCardsVerbose,
  GetAccountDebitCardsVerboseQueryResponse,
  GetAccountDebitCardsVerboseQueryVariables,
} from 'features/Accounts/AccountCards/hooks/useAccountCards/graphql/GetAccountDebitCards.generated'

type DebitCardV2 = NonNullable<
  NonNullable<
    GetAccountDebitCardsVerboseQueryResponse['getDebitCardAccountV2']
  >['cards']
>[0]

export const cacheAccountDebitCardList =
  (debitCardAccountUuid: string, entityUuid: string) =>
  ({
    cache,
    mapToNewList,
  }: {
    cache: ApolloCache<any>
    mapToNewList: (oldCards: (DebitCardV2 | null)[]) => (DebitCardV2 | null)[]
  }) => {
    const cardList = cache.readQuery<
      GetAccountDebitCardsVerboseQueryResponse,
      GetAccountDebitCardsVerboseQueryVariables
    >({
      variables: {
        debitCardAccountUuid,
        entityUuid,
      },
      query: GetAccountDebitCardsVerbose,
    })

    if (cardList?.getDebitCardAccountV2?.cards !== undefined) {
      const getIsNotNull = (
        maybeCard: DebitCardV2 | null
      ): maybeCard is DebitCardV2 => Boolean(maybeCard)

      cache.writeQuery<
        GetAccountDebitCardsVerboseQueryResponse,
        GetAccountDebitCardsVerboseQueryVariables
      >({
        query: GetAccountDebitCardsVerbose,
        variables: {
          debitCardAccountUuid,
          entityUuid,
        },
        data: {
          getDebitCardAccountV2: {
            id: debitCardAccountUuid,
            type: DebitCardAccountType.ZLR_DEBIT,
            cards: mapToNewList(
              cardList.getDebitCardAccountV2.cards || []
            ).filter(getIsNotNull),
            status: cardList.getDebitCardAccountV2.status,
            __typename: 'DebitCardAccountV2',
          },
          getEntity: cardList.getEntity,
        },
      })
    }
  }
