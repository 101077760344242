import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SelectCardholderForm } from './SelectCardholderForm/SelectCardholderForm'

export const SelectCardholderStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.CreateSelectCardholderStage}
    >
      <SelectCardholderForm />
    </Condition>
  )
}
