import { BodyLarge, H2 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledFailedIcon = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1rem;
  background: ${({ theme }) => theme.colors.RED_80};
  line-height: 1;
  margin-bottom: 1rem;
`

export const StyledTitle = styled(H2)`
  margin: 0 0 1rem 0;
`

export const StyledDescription = styled(BodyLarge)`
  text-align: center;
  padding: 0;
  margin: 0;
  max-width: 26.5rem;
`
