import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  FlexProps,
  MagnifyingGlassNoResults,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { cardsErrorPageTranslations } from './CardsErrorPage.i18n'

interface CardsErrorPageProps {
  retry: () => void
  description?: string
  pt?: FlexProps['pt']
}

export const CardsErrorPage = ({
  retry,
  description,
  pt,
}: CardsErrorPageProps) => {
  const isMobile = useIsMobileResolution()
  const t = useTranslations(cardsErrorPageTranslations)

  return (
    <Flex justifyContent="center" pt={pt || ['20px', '40px']}>
      <Box maxWidth="600px" textAlign="center">
        <MessageWithIcon
          icon={
            <MagnifyingGlassNoResults size={isMobile ? 'medium' : 'large'} />
          }
          iconBackgroundColor={null}
          title={t('title')}
          description={description ?? t('description')}
          primaryButton={{
            label: t('buttonLabel'),
            onClick: retry,
          }}
        />
      </Box>
    </Flex>
  )
}
