import {
  BodyDefaultTypography,
  BodySmallTypography,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 2.5rem;

  @media (min-width: ${BREAKPOINT.SM}px) {
    display: flex;
    margin: 0 0 2.5rem;

    && > *:not(:last-child) {
      margin-right: 5rem;
    }
  }
`

export const StyledIncomeWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  @media (min-width: ${BREAKPOINT.SM}px) {
    margin-bottom: 0;
    width: auto;
  }
`

export const StyledTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.GREY_250};
  ${BodySmallTypography}
`

export const StyledAmount = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.BLACK};
  margin: 0;
  ${BodyDefaultTypography}
  font-size: 2.5rem;
  line-height: 3rem;
`

export const StyledDecimal = styled.span`
  font-size: 1.375rem;
  line-height: 2.125rem;
  margin-left: 0.25rem;
`

export const StyledRefundsWrapper = styled.div`
  ${StyledAmount} {
    font-size: 1.5rem;
    line-height: 1.4;

    @media (min-width: ${BREAKPOINT.SM}px) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
`

export const StyledSalesWrapper = styled(StyledRefundsWrapper)`
  margin-right: 2.5rem;
`
