import {
  Box,
  InlineLoaderVariant,
  InlineSpinner,
  InlineSpinnerSize,
} from '@npco/zeller-design-system'

export const ChartInlineSpinner = () => {
  return (
    <Box position="absolute" left="calc(50% - 12px)" top="calc(50% - 12px)">
      <InlineSpinner
        loaderVariant={InlineLoaderVariant.Grey}
        size={InlineSpinnerSize.Large}
      />
    </Box>
  )
}
