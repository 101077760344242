import { matchPath, useLocation, useParams } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { getContactName } from 'features/Contacts/Contacts.utils'
import { rvSelectedContact } from 'features/Contacts/rv-deprecated/contacts'

import { ROUTE_PARAM_NAME } from 'const/routes'
import {
  BreadcrumbsVariable,
  BreadcrumbVariableType,
} from 'components/Breadcrumbs/Breadcrumbs.types'
import {
  fillRoutesWithVariables,
  getAllBreadcrumbPartsForPath,
} from 'components/Breadcrumbs/Breadcrumbs.utils'

export const useBreadcrumbsVariables = (): BreadcrumbVariableType => {
  const siteName = useReactiveVar(rvSiteDetails)
  const selectedContact = useReactiveVar(rvSelectedContact)
  const { invoiceRefNumber = '' } = useParams<{
    [ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER]: string
  }>()

  return {
    [BreadcrumbsVariable.siteName]: siteName ? siteName.name : null,
    [BreadcrumbsVariable.contactName]: getContactName(selectedContact),
    [BreadcrumbsVariable.invoiceRefNumber]: invoiceRefNumber,
  }
}

export const isCurrentPathExactMatched =
  (locationPathName: string) => (route: string) => {
    return Boolean(matchPath(route, locationPathName))
  }

export const useBreadcrumbParts = () => {
  const variables = useBreadcrumbsVariables()
  const location = useLocation()

  const routes = getAllBreadcrumbPartsForPath(
    isCurrentPathExactMatched(location.pathname)
  )
  return fillRoutesWithVariables(routes, variables).slice(-2)
}
