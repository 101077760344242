import { useEffect } from 'react'

import { KEY_CODE } from 'const/keyboard'

export const useHitEnter = (performAction: () => void, isDisabled = false) => {
  useEffect(() => {
    const handlePerformAction = (event: KeyboardEvent) => {
      if (event.key === KEY_CODE.ENTER) {
        performAction()
      }
    }

    if (!isDisabled) {
      window.addEventListener('keydown', handlePerformAction)
    }

    return () => {
      window.removeEventListener('keydown', handlePerformAction)
    }
  }, [performAction, isDisabled])
}
