import { gql } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'

import { AccountBalanceCard } from './AccountBalanceCard'
import { AccountBalancesDebitCardAccountV2Fragment } from './AccountBalances.generated'

interface AccountBalancesProps {
  accounts: AccountBalancesDebitCardAccountV2Fragment[]
}

export const AccountBalances = ({ accounts }: AccountBalancesProps) => {
  return (
    <Flex data-testid="account-balances" flexDirection="column" gap="4px">
      {accounts.map((account) => (
        <AccountBalanceCard key={account.id} account={account} />
      ))}
    </Flex>
  )
}

AccountBalances.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountBalancesDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      ...AccountBalanceCardDebitCardAccountV2Fragment
    }

    ${AccountBalanceCard.fragments.DebitCardAccountV2}
  `,
}
