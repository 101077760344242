import { gql } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'

import { GetContactNameContactFragment } from './getContactName.generated'

export const getContactName = ({
  businessName,
  contactType,
  firstName,
  lastName,
}: GetContactNameContactFragment): string | null => {
  if (contactType === ContactType.BUSINESS) {
    return businessName
  }

  return `${firstName} ${lastName}`
}

getContactName.fragments = {
  Contact: gql`
    fragment GetContactNameContactFragment on Contact {
      businessName
      contactType
      firstName
      lastName
    }
  `,
}
