import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { either, filter, propEq } from 'ramda'

export const filterClosedAccounts: <
  T extends { status: DebitCardAccountStatus }
>(
  p: T[]
) => T[] = filter(propEq('status', DebitCardAccountStatus.CLOSED))

export const filterActiveAccounts: <
  T extends { status: DebitCardAccountStatus }
>(
  p: T[]
) => T[] = filter(
  either(
    propEq('status', DebitCardAccountStatus.ACTIVE),
    propEq('status', DebitCardAccountStatus.SUSPENDED)
  )
)
