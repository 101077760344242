export const loaderWidthMap = {
  mobile: [
    [108, 141],
    [108, 77],
    [108, 133],
    [108, 141],
    [108, 77],
  ],
  tablet: [
    [158, 388],
    [158, 324],
    [158, 380],
    [158, 388],
    [158, 324],
  ],
  desktop: [
    [180, 460],
    [148, 396],
    [132, 452],
    [108, 460],
    [148, 396],
  ],
}

export const getLoaderWidthMap = (
  isMobile: boolean,
  isMobileOrTablet: boolean
) => {
  if (isMobile) {
    return loaderWidthMap.mobile
  }
  if (isMobileOrTablet) {
    return loaderWidthMap.tablet
  }

  return loaderWidthMap.desktop
}
