import { Box, Flex } from '@npco/zeller-design-system'
import { AccountsTransactionsMetadataProvider } from 'features/AccountsTransactions'

import { useSubscribeToAccounts, useSubscribeToCards } from 'hooks/banking'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { useRememberDashboardTab } from '../RememberDashboardTab/hooks/useRememberDashboardTab/useRememberDashboardTab'
import { AccountBalanceChart } from './AccountBalanceChart/AccountBalanceChart'
import { AccountBalanceList } from './AccountBalanceList'
import { AvailableBalanceSummary } from './AvailableBalanceSummary'
import { StyledDashboardFinanceWrapper } from './DashboardFinance.styled'
import { LatestTransactions } from './LatestTransactions'
import { YourCardsList } from './YourCardsList'

export const DashboardFinance = () => {
  useRememberDashboardTab()

  useSubscribeToAccounts()
  useSubscribeToCards()

  const isMobile = useIsMobileResolution()

  return (
    <StyledDashboardFinanceWrapper>
      <Box>
        {isMobile && (
          <Box pb="64px">
            <AvailableBalanceSummary />
          </Box>
        )}
        <Box pb={{ _: '40px', SM: '64px' }}>
          <AccountBalanceChart />
        </Box>
        <Flex width="100%" flexDirection={{ _: 'column', SM: 'row' }}>
          <Box
            width={{ _: '100%', SM: '50%' }}
            pr={{ _: 0, SM: '40px' }}
            pb="64px"
          >
            <AccountBalanceList />
          </Box>
          <Box
            width={{ _: '100%', SM: '50%' }}
            pl={{ _: 0, SM: '40px' }}
            pb="64px"
          >
            <YourCardsList />
          </Box>
        </Flex>
        <Box>
          <AccountsTransactionsMetadataProvider>
            <LatestTransactions />
          </AccountsTransactionsMetadataProvider>
        </Box>
      </Box>
    </StyledDashboardFinanceWrapper>
  )
}
