import { Box, Flex, InputAdaptiveField } from '@npco/zeller-design-system'

import {
  validateAddressString,
  validateOptionalAddressString,
  validatePostcode,
  validateRequired,
  validateSuburb,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { State } from 'forms/formSections/State'

export const CustomAddressFields = () => {
  return (
    <Box pt="12px">
      <Box pb="24px">
        <InputAdaptiveField
          name="street1"
          label={translate('shared.address.label')}
          validate={validateAddressString}
          isRequired
        />
      </Box>
      <Box pb="24px">
        <InputAdaptiveField
          name="street2"
          label={translate('page.deliveryAddressForm.street2')}
          validate={validateOptionalAddressString}
        />
      </Box>
      <Flex pb="24px" flexDirection={['column', 'row']}>
        <Box width={['100%', '60%']} pr={['0px', '20px']} pb={['24px', '0px']}>
          <InputAdaptiveField
            name="suburb"
            label={translate('form.shared.suburb.label')}
            validate={validateSuburb}
            isRequired
          />
        </Box>
        <Flex width={['100%', '40%']}>
          <Box pr="20px">
            <State validator={validateRequired} isRequired />
          </Box>
          <Box>
            <InputAdaptiveField
              name="postcode"
              label={translate('form.shared.postcode.label')}
              validate={validatePostcode}
              isRequired
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}
