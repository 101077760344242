import { useRef } from 'react'
import { Box, InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { formatToInternationalPhoneNumber } from '../../../utils/formatMobileNumber/formatMobileNumber'
import { useFormValidation } from '../../../utils/useFormValidation/useFormValidation'
import { StyledCheckbox } from '../../EmailRemittanceModal/EmailRemittanceFields/EmailRemittanceFields.styled'
import { RemittanceData } from '../../TransferRemittancesModal.types'

type SMSRemittanceFieldsProps = {
  placeholder: string
  checkBoxDescription: string
  currentUserMobileNumber: string
}

export const SMSRemittanceFields = ({
  placeholder,
  checkBoxDescription,
  currentUserMobileNumber,
}: SMSRemittanceFieldsProps) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null)

  const { touched, errors, values, setFieldValue } =
    useFormikContext<RemittanceData>()

  const { validateSMS } = useFormValidation()

  const formattedInternationalMobileNumber = formatToInternationalPhoneNumber(
    currentUserMobileNumber
  )
  const formattedInputNumber = values.sms?.replace(/\s/g, '')

  const isCheckboxDisabled =
    formatToInternationalPhoneNumber(formattedInputNumber) ===
    formattedInternationalMobileNumber

  return (
    <>
      <Box>
        <InputAdaptiveField
          hasError={Boolean(touched.sms && errors.sms)}
          aria-labelledby={placeholder}
          autoComplete="tel"
          type="tel"
          label={placeholder}
          wrapperRef={wrapperRef}
          validate={(value) => validateSMS(value)}
          onChange={() => {
            setFieldValue('sms', wrapperRef.current?.value)
          }}
          name="sms"
          isRequired
        />
      </Box>
      {/* Hiding the checkbox for the first release */}
      <Box hidden>
        <StyledCheckbox
          name="smsCheckbox"
          checked={isCheckboxDisabled ? false : values.smsCheckbox}
          onChange={() => {
            setFieldValue('smsCheckbox', !values.smsCheckbox)
          }}
          disabled={isCheckboxDisabled}
        >
          {checkBoxDescription}
        </StyledCheckbox>
      </Box>
    </>
  )
}
