import { useEditDebitCardTransactionNotesState } from 'features/EditDebitCardTransactionNotes/edit-debit-card-transaction-notes-routing'

import { EditDebitCardTransactionNotes } from './EditDebitCardTransactionNotes/EditDebitCardTransactionNotes'

export const EditDebitCardTransactionNotesModal = () => {
  const { state } = useEditDebitCardTransactionNotesState()

  if (state === null) {
    return null
  }

  return (
    <EditDebitCardTransactionNotes
      debitCardTransactionUuid={state.debitCardTransactionUuid}
      note={state.note}
    />
  )
}
