import styled from 'styled-components'

export const LogoContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 144px;
  justify-content: center;
  padding: 8px;
  width: 100%;
`

export const Logo = styled.img`
  height: auto;
  max-width: 100%;
  max-height: 128px;
`
