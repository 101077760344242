import {
  Box,
  COLOR,
  Flex,
  InfoFilledIcon,
  TooltipBasic,
} from '@npco/zeller-design-system'

interface TransferDailyMaximumLimitLabelProps {
  dailyMaximumLimit: string
  dailyMaximumLimitInfo: string
}
export const TransferDailyMaximumLimitLabel = ({
  dailyMaximumLimit,
  dailyMaximumLimitInfo,
}: TransferDailyMaximumLimitLabelProps) => (
  <Flex>
    <span>{dailyMaximumLimit}</span>
    <TooltipBasic
      showArrow={false}
      placement="top"
      renderTrigger={({ handlers }) => (
        <Flex ml="4px" {...handlers}>
          <InfoFilledIcon color={COLOR.GREY_550} width="16px" />
        </Flex>
      )}
    >
      <Box maxWidth="223px" textAlign="center">
        {dailyMaximumLimitInfo}
      </Box>
    </TooltipBasic>
  </Flex>
)
