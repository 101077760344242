import { gql } from '@apollo/client'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import {
  AVATAR_BADGE_SIZE,
  BadgeSize,
  ComingSoonBadge,
  Flex,
} from '@npco/zeller-design-system'

import { ActivateButton } from './ActivateButton/ActivateButton'
import { LearnMoreButton } from './LearnMoreButton/LearnMoreButton'
import { SavingAccountHeaderEntityFragment } from './SavingsAccountHeader.generated'
import { savingsAccountHeaderTranslations } from './SavingsAccountHeader.i18n'
import * as styled from './SavingsAccountHeader.styled'

type SavingAccountHeaderProps = {
  entity: SavingAccountHeaderEntityFragment
}

export const SavingsAccountHeader = ({ entity }: SavingAccountHeaderProps) => {
  const t = useTranslations(savingsAccountHeaderTranslations)

  if (!entity.savingsAccountProduct?.effectiveInterestRate) {
    ErrorLogger.reportError(
      '[Banking] - Savings Account Product interest rate NULL on Entity',
      undefined
    )
    return null
  }

  return (
    <styled.StyledSavingAccountHeader>
      <Flex alignItems="center">
        <IconAvatar
          icon={{
            letter: '',
            colour: 'BLUE_1000',
            image: '/savings-account-green.svg',
            images: null,
            animation: '/savings-account-green.lottie',
          }}
          allowAnimation
          badgeSize={AVATAR_BADGE_SIZE.SMALL}
        />
        <styled.StyledTitleWrapper ml="12px" mr="10px">
          {t('savingsAccount')}
        </styled.StyledTitleWrapper>
        <ComingSoonBadge
          size={BadgeSize.Small}
          text={t('rate', {
            effectiveInterestRate:
              entity.savingsAccountProduct.effectiveInterestRate,
          })}
        />
      </Flex>
      <Flex
        alignItems="center"
        flexGrow="1"
        gap="8px"
        justifyContent={{ _: 'space-between', SM: 'end' }}
      >
        <LearnMoreButton entity={entity} />
        <ActivateButton entity={entity} />
      </Flex>
    </styled.StyledSavingAccountHeader>
  )
}

SavingsAccountHeader.fragments = {
  Entity: gql`
    fragment SavingAccountHeaderEntityFragment on Entity {
      ...ActivateButtonEntityFragment
      ...LearnMoreButtonEntityFragment
    }

    ${ActivateButton.fragments.Entity}
    ${LearnMoreButton.fragments.Entity}
  `,
}
