import { PaymentInstrumentStatus } from '@npco/mp-gql-types'
import { Flex, SvgIcon } from '@npco/zeller-design-system'
import { SelectTriggerContent } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerContent'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { ReactComponent as DefaultBankIcon } from 'assets/svg/Bank/default.svg'
import { getBankByBsB } from 'utils/bankAccountHelper'
import { translate } from 'utils/translations'
import { ThirdPartyBankIcon } from 'components/Tile/ThirdPartyAccountTile/ThirdPartyAccountTile'

import { statusLabelMapping } from './BankAccounts.utils'
import {
  StyledListItemWrapper,
  StyledStatus,
} from './BankAccountsListItem.styled'

export const ITEM_DATA_TESTID = 'contact-bank-account-item'

type BankAccountDetails = NonNullable<
  ContactCoreFieldsFragment['paymentInstruments']
>[0]['bankAccountDetails']

interface BankAccountsListItemProps {
  bankAccountDetails: BankAccountDetails | null
  id: string
  onItemClick: (itemToUpdate: string) => void
  status: PaymentInstrumentStatus
}

export const BankAccountsListItem = ({
  bankAccountDetails,
  id,
  onItemClick,
  status,
}: BankAccountsListItemProps) => {
  if (!bankAccountDetails) {
    return null
  }

  const { account, bsb, name } = bankAccountDetails

  return (
    <StyledListItemWrapper
      justifyContent="space-between"
      data-testid={ITEM_DATA_TESTID}
      onClick={() => onItemClick(id)}
    >
      <Flex alignItems="center">
        {bsb && getBankByBsB(bsb) ? (
          <ThirdPartyBankIcon
            account={{
              accountBsb: bsb,
              accountName: name,
              accountNumber: account,
              id,
              name,
            }}
          />
        ) : (
          <Flex mr="1.25rem" data-testid={`${ITEM_DATA_TESTID}-default-icon`}>
            <SvgIcon width="48" height="48">
              <DefaultBankIcon />
            </SvgIcon>
          </Flex>
        )}

        <SelectTriggerContent
          label={name}
          subLabel1={`${translate('shared.bsb')} ${bsb}`}
          subLabel2={`${translate('shared.accountShorthand')} ${account}`}
        />
      </Flex>
      {status !== PaymentInstrumentStatus.ACTIVE && (
        <StyledStatus>{statusLabelMapping[status]}</StyledStatus>
      )}
    </StyledListItemWrapper>
  )
}
