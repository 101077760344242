import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'

interface TransactionDetailsErrorProps {
  retry: () => void
}

export const TransactionDetailsError = ({
  retry,
}: TransactionDetailsErrorProps) => {
  return <GenericErrorPage retry={retry} />
}
