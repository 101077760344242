import { ModalBasic } from 'design-system/Components/Modal'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledHeader } from 'components/Modal/ModalElements/ModalElements.styled'

export const StyledModal = styled(ModalBasic)`
  display: flex;
  flex-direction: column;

  padding-left: 0.25rem;
  padding-right: 0.25rem;

  ${StyledHeader} {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }
`
export const StyledContentContainer = styled.div<{
  $hasScrollableContentShadow: boolean
}>`
  box-shadow: ${({ $hasScrollableContentShadow }) =>
    $hasScrollableContentShadow
      ? '0px 6px 12px -8px rgba(0, 0, 0, 0.15)'
      : 'none'};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
`
