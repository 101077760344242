import { type ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { Checkbox } from 'components/Input/Checkbox'

import type { GroupSelectProps } from '../GroupSelect'
import * as styled from '../GroupSelect.styled'
import { translations } from './GroupSelectOk.i18n'

export const GroupSelectOk = ({
  groups: groupsProp,
  value: valueProp,
  onValueChange,
  actionSelectAll,
}: GroupSelectProps.Items.Ok &
  Pick<GroupSelectProps, 'value' | 'onValueChange'>): ReactElement => {
  const t = useTranslations(translations)

  const {
    value,
    items,
    groups,
    isAllSelected,
  }: {
    value: Set<string>
    items: Map<string, { item: GroupSelectProps.Item; value: boolean }>
    groups: Map<
      string,
      { group: GroupSelectProps.Group; value: boolean | 'partial' }
    >
    isAllSelected: boolean
  } = useMemo(() => {
    const value = new Set(valueProp)
    const items = new Map<
      string,
      { item: GroupSelectProps.Item; value: boolean }
    >()
    const groups = new Map<
      string,
      { group: GroupSelectProps.Group; value: boolean | 'partial' }
    >()

    groupsProp.forEach((group) => {
      let selectedCount = 0
      group.items.forEach((item) => {
        const itemValue = value.has(item.key)
        items.set(item.key, { item, value: itemValue })
        if (itemValue) {
          selectedCount += 1
        }
      })
      const groupValue =
        selectedCount > 0 && selectedCount < group.items.length
          ? 'partial'
          : selectedCount === group.items.length
      groups.set(group.key, { group, value: groupValue })
    })

    const isAllSelected = value.size > 0 && value.size === items.size

    return {
      value,
      items,
      groups,
      isAllSelected,
    }
  }, [valueProp, groupsProp])

  const onToggleAll = useCallback((): void => {
    if (!onValueChange) {
      return
    }

    if (isAllSelected) {
      onValueChange([])
    } else {
      onValueChange(Array.from(items.keys()))
    }
  }, [isAllSelected, onValueChange, items])

  const onToggleGroup = useCallback(
    (groupKey: string): void => {
      if (!onValueChange) {
        return
      }

      const match = groups.get(groupKey)
      if (!match) {
        return
      }

      const isGroupAllSelected = match.value === true
      if (isGroupAllSelected) {
        match.group.items.forEach((item) => value.delete(item.key))
      } else {
        match.group.items.forEach((item) => value.add(item.key))
      }

      onValueChange(Array.from(value.values()))
    },
    [value, onValueChange, groups]
  )

  const onToggleGroupItem = useCallback(
    (itemKey: string): void => {
      if (!onValueChange) {
        return
      }

      const match = items.get(itemKey)
      if (!match) {
        return
      }

      const isSelected = match.value
      if (isSelected) {
        value.delete(itemKey)
      } else {
        value.add(itemKey)
      }

      onValueChange(Array.from(value.values()))
    },
    [value, onValueChange, items]
  )

  const [expanded, setExpanded] = useState<Set<string>>(new Set())
  const onToggleGroupExpander = useCallback((groupKey: string) => {
    setExpanded((expanded) => {
      const isExpanded = expanded.has(groupKey)
      const nextExpanded = new Set(expanded)
      if (isExpanded) {
        nextExpanded.delete(groupKey)
      } else {
        nextExpanded.add(groupKey)
      }
      return nextExpanded
    })
  }, [])
  return (
    <>
      {!!actionSelectAll && (
        <styled.Row>
          <Checkbox
            type="checkbox"
            name="all"
            label={actionSelectAll.label}
            checked={isAllSelected}
            onChange={onToggleAll}
          />
        </styled.Row>
      )}
      <styled.ulGroups>
        {groupsProp.map((group) => {
          const match = groups.get(group.key)
          const value = match?.value
          return (
            <styled.liGroup key={group.key}>
              <styled.Row>
                <Checkbox
                  type="checkbox"
                  name={`group-${group.key}`}
                  label={<styled.LabelName>{group.label}</styled.LabelName>}
                  checked={value === true}
                  indeterminate={value === 'partial'}
                  onChange={() => onToggleGroup(group.key)}
                  alignItems="center"
                />
                <styled.GroupExpander
                  aria-label={t('groupExpand', { label: group.label })}
                  onClick={() => onToggleGroupExpander(group.key)}
                >
                  <styled.GroupExpanderChevron
                    width={16}
                    $open={expanded.has(group.key)}
                  />
                </styled.GroupExpander>
              </styled.Row>
              {expanded.has(group.key) && (
                <styled.ulGroupItems>
                  {group.items.map((item) => {
                    const match = items.get(item.key)
                    const value = match?.value
                    return (
                      <styled.liGroupItem key={item.key}>
                        <Checkbox
                          type="checkbox"
                          name={`item-${item.key}`}
                          label={
                            <styled.GroupItemLabel>
                              <styled.LabelName>{item.label}</styled.LabelName>
                              {item.icon}
                            </styled.GroupItemLabel>
                          }
                          checked={value === true}
                          onChange={() => onToggleGroupItem(item.key)}
                          alignItems="center"
                        />
                      </styled.liGroupItem>
                    )
                  })}
                </styled.ulGroupItems>
              )}
            </styled.liGroup>
          )
        })}
      </styled.ulGroups>
    </>
  )
}
