import { useCallback, useState } from 'react'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '@npco/utils-session-storage'

import { SESSION_STORAGE_KEYS } from '../../../../../../services/sessionStorage/keys'

export const useOverviewBannerVisibility = () => {
  const [isBannerHidden, setIsBannerHidden] = useState(
    getSessionStorageItem(SESSION_STORAGE_KEYS.HIDE_OVERVIEW_BANNER)
  )

  const setIsOverviewBannerHidden = useCallback((hideBanner: boolean) => {
    setSessionStorageItem(SESSION_STORAGE_KEYS.HIDE_OVERVIEW_BANNER, hideBanner)
    setIsBannerHidden(
      getSessionStorageItem(SESSION_STORAGE_KEYS.HIDE_OVERVIEW_BANNER)
    )
  }, [])

  return {
    isBannerHidden,
    setIsOverviewBannerHidden,
  }
}
