import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  general: 'General',
  network: 'Network',
  andriodDevice: 'Android Device',
  iosDevice: 'iOS Device',
  zellerT1: 'Zeller Terminal 1',
  zellerT2: 'Zeller Terminal 2',
  deviceName: 'Device Name',
  deviceType: 'Device Type',
  deviceOwner: 'Device Owner',
  siteAssigned: 'Site Assigned To',
  lastTransaction: 'Last Transaction',
  deviceId: 'Device ID',
  appVersion: 'App Version',
  imageNumber: 'Image Number',
  serial: 'Serial',
  caid: 'CAID',
  catid: 'CATID',
})
