import PhoneImage from 'assets/images/setup-flow/setup-flow-tap-to-pay-phone.png'

import * as styled from './AnimatedPhone.styled'

export const AnimatedPhone = () => {
  return (
    <styled.Container>
      <styled.PhoneContent>
        <styled.PhoneCase src={PhoneImage} />
      </styled.PhoneContent>
    </styled.Container>
  )
}
