import { ReactNode, useMemo, useState } from 'react'

import { MerchantPortalSessionTimeoutModal } from '../MerchantPortalSessionTimeoutModal/MerchantPortalSessionTimeoutModal'
import { TimeoutContext } from './SessionTimeoutContext'

type SessionTimeoutProviderProps = {
  children: ReactNode
}

const SessionTimeoutProvider = ({ children }: SessionTimeoutProviderProps) => {
  const [timeoutContext, setTimeoutContext] = useState<
    'default' | 'onboarding'
  >('default')
  const value = useMemo(
    () => ({ timeoutContext, setTimeoutContext }),
    [timeoutContext, setTimeoutContext]
  )

  return (
    <TimeoutContext.Provider value={value}>
      <MerchantPortalSessionTimeoutModal />
      {children}
    </TimeoutContext.Provider>
  )
}

export { SessionTimeoutProvider }
