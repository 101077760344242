import { useMutation } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'

import {
  RunConfigureXeroBankfeeds,
  RunConfigureXeroBankfeedsVariables,
} from 'types/gql-types/RunConfigureXeroBankfeeds'

import { RunConfigureXeroBankfeeds as gql } from './ConfigureXeroBankfeeds.graphql'

export const useConfigureXeroBankfeeds = () => {
  const [configureXeroBankfeeds, { loading, error }] = useMutation<
    RunConfigureXeroBankfeeds,
    RunConfigureXeroBankfeedsVariables
  >(gql, {
    onError: (err) => {
      showApiErrorToast(err)
    },
    notifyOnNetworkStatusChange: true,
  })

  return {
    isLoading: loading,
    error,
    configureXeroBankfeeds,
  }
}
