import { useCallback } from 'react'
import { DebitCardColour } from '@npco/mp-gql-types'

import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import {
  Address,
  ReportLostAndReplaceCardStage,
  VelocityControl,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  REPLACE_CARD_ERROR,
  REPORT_LOST_CARD_ERROR,
} from 'pages/GlobalModals/ReportLostAndReplaceCardModal/hooks/useReportLostCard/useReportLostCard.utils'

import { useReportLostAndReplaceCardModal } from './useReportLostAndReplaceCardModal'
import { useReportLostCard } from './useReportLostCard'

type ReportLostAndReplacePhysicalCardType = {
  cardId: string
  cardColor: DebitCardColour
  address: Address
  isAdmin: boolean
  isRetry?: boolean
}

type ReportLostAndReplaceVirtualCardType = {
  cardId: string
  cardColor: DebitCardColour
  isAdmin: boolean
  isCardOwner: boolean
  cardOwnerFirstName: string | null | undefined
  isRetry?: boolean
}

type UseReportLostAndReplaceCardProps = {
  velocityControl: VelocityControl | null
  entityAddressTimezone?: string
}

export const useReportLostAndReplaceCard = ({
  velocityControl,
  entityAddressTimezone,
}: UseReportLostAndReplaceCardProps) => {
  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()
  const { reportLostCard, isReportingLostCard } = useReportLostCard({
    velocityControl,
    entityAddressTimezone,
  })

  const {
    closeReportLostAndReplaceCardModal,
    openVirtualCardCompleteModal,
    openPhysicalCardCompleteModal,
    openSetPinModal,
    openReplaceCardErrorModal,
  } = useReportLostAndReplaceCardModal({
    velocityControl,
    entityAddressTimezone,
  })

  const reportLostAndReplacePhysicalCard = useCallback(
    async ({
      cardId,
      cardColor,
      address,
      isAdmin,
      isRetry = false,
    }: ReportLostAndReplacePhysicalCardType) => {
      const result = await reportLostCard({
        cardId,
        colour: cardColor,
        address,
      })

      if (result === REPORT_LOST_CARD_ERROR || result === UNEXPECTED_ERROR) {
        if (!isRetry) {
          goToReportLostAndReplaceCardStage({
            stage: ReportLostAndReplaceCardStage.ReportLostPhysicalCardError,
            cardId,
            cardColor,
            address,
            isAdmin,
            velocityControl,
            entityAddressTimezone,
          })
        }
        return
      }

      if (result === REPLACE_CARD_ERROR) {
        openReplaceCardErrorModal(isAdmin)
        return
      }

      openPhysicalCardCompleteModal()
    },
    [
      openPhysicalCardCompleteModal,
      openReplaceCardErrorModal,
      goToReportLostAndReplaceCardStage,
      reportLostCard,
      entityAddressTimezone,
      velocityControl,
    ]
  )

  const reportLostAndReplaceVirtualCard = useCallback(
    async ({
      cardId,
      cardColor,
      isAdmin,
      isCardOwner,
      cardOwnerFirstName,
      isRetry = false,
    }: ReportLostAndReplaceVirtualCardType) => {
      const result = await reportLostCard({
        cardId,
        colour: cardColor,
      })

      if (result === REPORT_LOST_CARD_ERROR || result === UNEXPECTED_ERROR) {
        if (!isRetry) {
          goToReportLostAndReplaceCardStage({
            stage: ReportLostAndReplaceCardStage.ReportLostVirtualCardError,
            cardId,
            cardColor,
            isAdmin,
            isCardOwner,
            cardOwnerFirstName,
            velocityControl,
            entityAddressTimezone,
          })
        }

        return
      }

      if (result === REPLACE_CARD_ERROR) {
        openReplaceCardErrorModal(isAdmin)
        return
      }

      if (isCardOwner) {
        openSetPinModal(result)
        return
      }

      if (cardOwnerFirstName) {
        openVirtualCardCompleteModal(result, cardOwnerFirstName)
      } else {
        closeReportLostAndReplaceCardModal()
      }
    },
    [
      openReplaceCardErrorModal,
      openSetPinModal,
      openVirtualCardCompleteModal,
      goToReportLostAndReplaceCardStage,
      reportLostCard,
      closeReportLostAndReplaceCardModal,
      entityAddressTimezone,
      velocityControl,
    ]
  )

  return {
    isReportingLostAndReplaceCard: isReportingLostCard,
    reportLostAndReplacePhysicalCard,
    reportLostAndReplaceVirtualCard,
  }
}
