import { COLOR, NFCIcon } from '@npco/zeller-design-system'

import { CardFormatOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'
import {
  StyledRadioCard,
  StyledRadioCardIcon,
} from 'pages/GlobalModals/GlobalModals.styled'

interface VirtualCardRadioOptionProps {
  onChange?: () => void
  isChecked: boolean
  name: string
}

export const VirtualCardRadioOption = ({
  onChange,
  isChecked,
  name,
}: VirtualCardRadioOptionProps) => {
  return (
    <StyledRadioCard
      icon={() => (
        <StyledRadioCardIcon color={COLOR.BLUE_1000}>
          <NFCIcon />
        </StyledRadioCardIcon>
      )}
      id={CardFormatOption.Virtual}
      value={CardFormatOption.Virtual}
      title={translate(
        'page.addCardModal.selectCardFormat.virtualCardOptionTitle'
      )}
      desc={translate(
        'page.addCardModal.selectCardFormat.virtualCardOptionDesc'
      )}
      onChange={onChange}
      checked={isChecked}
      name={name}
    />
  )
}
