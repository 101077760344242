import { useEffect, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  getInputAdaptiveIconColor,
  INPUT_SIZE,
  InputWithoutLabel,
  MagnifyingGlassNoResults,
  Message,
  SearchIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as SiteIcon } from 'assets/svg/site.svg'
import { useGetSites } from 'hooks/useGetSites'
import { translate } from 'utils/translations'
import { PickerItemProps } from 'types/picker'
import { MultiDownshift } from 'components/MultiDownshift'
import { MultiDownshiftComponentName } from 'components/MultiDownshift/MultiDownshift.hooks'
import { AllItemsRow, BottomRow, SiteRow } from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { SpinnerWrapped } from 'components/Spinner'

import { translations } from './SiteDevicePicker.i18n'
import * as styled from './SiteDevicePicker.styled'
import {
  getSelectedSitesInfo,
  getSitePropsFromSites,
} from './SiteDevicePicker.utils'

export const SiteDevicePicker = () => {
  const [input, setInput] = useState('')
  const t = useTranslations(translations)

  const {
    sites,
    isLoading: sitesLoading,
    hasMore,
    isLoadingMore,
    onLoadMore,
  } = useGetSites()

  useEffect(() => {
    if (hasMore && !isLoadingMore) {
      onLoadMore()
    }
  }, [hasMore, isLoadingMore, onLoadMore])

  const handleSearch = (e: React.SyntheticEvent): void => {
    const target = e.target as HTMLInputElement
    setInput(target.value)
  }

  const source = useMemo(
    () => getSitePropsFromSites(sites, input),
    [input, sites]
  )

  const allDevicesAmount = useMemo(
    () => source.reduce((acc, curr) => acc + curr.devices.length, 0),
    [source]
  )

  return (
    <MultiDownshift
      itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
      componentName={MultiDownshiftComponentName.Terminal}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getRootProps,
        getItemProps,
        selectedItems,
        toggleAllDevices,
        toggleSiteDevices,
        clearAllSelected,
        areAllSiteDevicesSelected,
        isOpen,
      }) => (
        <styled.Dropdown
          {...getRootProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
        >
          <PickerTrigger
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            label={translate('component.siteDevicePicker.selectSiteOrDevice')}
            placeholderIcon={<SiteIcon />}
            triggerContent={
              selectedItems?.length
                ? getSelectedSitesInfo(source, selectedItems)
                : undefined
            }
          />
          {isOpen && (
            <styled.OpenOptions>
              <styled.SearchWrapper>
                <InputWithoutLabel
                  name="search-input"
                  icon={
                    <SearchIcon
                      color={getInputAdaptiveIconColor({
                        disabled: sites.length === 0,
                        hasError: false,
                      })}
                    />
                  }
                  {...getInputProps({
                    placeholder: translate(
                      'component.siteDevicePicker.inputPlaceholder'
                    ),
                    value: input,
                    onChange: (e: React.SyntheticEvent) => handleSearch(e),
                  })}
                  size={INPUT_SIZE.SMALL}
                  ref={undefined}
                  disabled={sites.length === 0}
                />
              </styled.SearchWrapper>
              <styled.List>
                {sitesLoading || isLoadingMore ? (
                  <SpinnerWrapped justifyContent="center" my="20px" />
                ) : (
                  <>
                    {source.length > 0 && (
                      <AllItemsRow
                        label={translate(
                          'component.siteDevicePicker.allTerminals'
                        )}
                        checked={selectedItems.length === allDevicesAmount}
                        handleOnChange={() => toggleAllDevices(source)}
                      />
                    )}
                    {source.map((site) => (
                      <SiteRow
                        key={site.id}
                        site={site}
                        getItemProps={getItemProps}
                        selectedItems={selectedItems}
                        toggleSiteDevices={toggleSiteDevices}
                        areAllSiteDevicesSelected={areAllSiteDevicesSelected}
                      />
                    ))}
                    {source.length === 0 && !input && (
                      <Message
                        margin="24px"
                        description={t('noSitesDescription')}
                        title={t('noSites')}
                      />
                    )}
                    {source.length === 0 && input && (
                      <Message
                        image={<MagnifyingGlassNoResults size="medium" />}
                        title={t('noResult')}
                        description={t('noResultDescription')}
                      />
                    )}
                  </>
                )}
              </styled.List>
              <BottomRow
                handleClearAll={clearAllSelected}
                handleSetInput={setInput}
              />
            </styled.OpenOptions>
          )}
        </styled.Dropdown>
      )}
    </MultiDownshift>
  )
}
