import { TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'
import currency from 'currency.js'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

export const InvoiceAmountRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const isLoading = !row?.original || table.options.meta?.isLoading

  const text = isLoading
    ? undefined
    : currency(
        currency(getValue<string>(), { fromCents: true, precision: 4 })
      ).format()

  return (
    <TableCellText
      dataTestId={`invoice-table-row-${row.index}-amount`}
      forwardedProps={{ style: { padding: 0 } }}
      isLoading={isLoading}
      justifyContent="flex-end"
      key={cell.id}
      skeletonProps={{ width: '100%' }}
      text={text}
    />
  )
}
