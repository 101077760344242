import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button as BaseButton } from 'components/Buttons/Button'

export const StyledTransferButton = styled(BaseButton)`
  height: 3rem;
  margin: 0;
  width: 3rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
  }
`
