import { useTranslations } from '@npco/utils-translations'
import { COLOR, Flex, Typography } from '@npco/zeller-design-system'
import { Field } from 'formik'

import { FormCheckbox } from 'components/Input/Checkbox'

import { setupFlowAccountsStageTranslations } from '../../FeatureAccountsStage.i18n'

export const AccountStageCheckboxField = () => {
  const t = useTranslations(setupFlowAccountsStageTranslations)

  return (
    <Flex
      backgroundColor={COLOR.BLUE_60}
      border={`1px solid ${COLOR.BLUE_100}`}
      borderRadius="8px"
      marginTop="16px"
      p="13px 24px 13px 12px"
      width={{ _: '100%', XS: '454px' }}
    >
      <Field
        component={FormCheckbox}
        name="isCreateCardAllowed"
        type="checkbox"
        alignItems="center"
        checkboxMargin="0 12px 0 0"
      >
        <Typography color={COLOR.BLUE_1000}>{t('checkboxMessage')}</Typography>
      </Field>
    </Flex>
  )
}
