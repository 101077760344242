import { useCallback } from 'react'
import {
  Box,
  Flex,
  InfoBox,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'
import { DebitCardAccount } from 'types/accounts'
import {
  StyledAccountPrimaryAction,
  StyledActionsContainer,
} from 'components/DepositAccountDetailDrawer/AccountDetailAction/AccountDetailAction.styled'
import { ModalConfirm } from 'components/Modal/ModalConfirm/ModalConfirm'

import { useSelectSimBillingZellerAccount } from '../SimBillingZellerBankTile/SimBillingZellerBankTile.hook'

interface SimBillingZellerBankActionProps {
  account: DebitCardAccount
  isSelectedAccount: boolean
}

export const SimBillingZellerAccountAction = ({
  account,
  isSelectedAccount,
}: SimBillingZellerBankActionProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const { isLoading, changeToZellerAccount } = useSelectSimBillingZellerAccount(
    { onSuccess: () => closeModal }
  )

  const onConfirm = useCallback(() => {
    changeToZellerAccount(account)
  }, [account, changeToZellerAccount])

  if (isSelectedAccount) {
    return (
      <Flex mt="28px">
        <Box width="100%">
          <InfoBox variant={INFOBOX_VARIANT.POSITIVE} size={INFOBOX_SIZE.SMALL}>
            <InfoBox.Message>
              {translate('page.settings.sim.billingAccount.selectedAccount')}
            </InfoBox.Message>
          </InfoBox>
        </Box>
      </Flex>
    )
  }

  return (
    <>
      <ModalConfirm
        isOpen={isModalOpen}
        onCancel={() => closeModal()}
        onConfirm={onConfirm}
        title={translate('page.settings.sim.billingAccount.modal.title')}
        description={translate(
          'page.settings.sim.billingAccount.modal.description',
          { accountType: 'Zeller', accountName: account?.name }
        )}
        isLoading={isLoading}
      />
      <StyledActionsContainer>
        <StyledAccountPrimaryAction
          dataTestId="setAccount"
          onClick={() => openModal()}
        >
          {translate('page.settings.sim.billingAccount.setAccount')}
        </StyledAccountPrimaryAction>
      </StyledActionsContainer>
    </>
  )
}
