import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

export const useRefreshAccounts = () => {
  const { cache } = useApolloClient()

  useEffect(() => {
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'getDebitCardAccountsV2' })
  }, [cache])
}
