import { Box, SkeletonBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { DetailsLabel } from '../../ZellerCardSummary.styled'

export const AvailableFundsSkeleton = () => {
  return (
    <>
      <DetailsLabel>
        {translate('component.cardSummary.debitCardDetails.availableFunds')}
      </DetailsLabel>
      <Box width={1} maxWidth={153} mt="10px">
        <SkeletonBasic height={20} isRounded />
      </Box>
    </>
  )
}
