import { useCallback, useState } from 'react'
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Step,
  TooltipRenderProps,
} from 'react-joyride'
import {
  AnnouncementModal,
  COLOR,
  TOOLTIP_ZINDEX,
} from '@npco/zeller-design-system'

export const DEFAULT_OVERLAY_STYLE: React.CSSProperties = {
  zIndex: 10000,
  position: 'absolute',
  background: 'rgba(0,0,0,0.4)',
  transition: 'all 0.3s',
} as const

export const DEFAULT_JOYRIDE_STYLE_OPTIONS = {
  options: {
    arrowColor: COLOR.DARK_BLUE,
    overlayColor: `${DEFAULT_OVERLAY_STYLE.background}`,
    zIndex: Number(TOOLTIP_ZINDEX),
  },
}

enum OnboardingStage {
  Hidden,
  WelcomeModal,
  OnboardingWizard,
}

type OnboardingProps = {
  illustrationComponent: JSX.Element
  heading: string
  description: string
  primaryButtonLabel: string
  secondaryButtonLabel: string
  steps: Step[]
  tooltipComponent: React.ElementType<TooltipRenderProps>
}

export const Onboarding = ({
  illustrationComponent,
  heading,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  steps,
  tooltipComponent,
}: OnboardingProps) => {
  const [onboardingStage, setOnboardingStage] = useState<OnboardingStage>(
    OnboardingStage.WelcomeModal
  )
  const [stepIndex, setStepIndex] = useState(0)

  const handleCancelOnboarding = () =>
    setOnboardingStage(OnboardingStage.Hidden)

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { action, index, type } = data

      // Handle next
      if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
      }

      // Handle close
      if (action === ACTIONS.CLOSE) {
        setOnboardingStage(OnboardingStage.Hidden)
      }

      // Handle restart, rewiring the back button to be restart
      if (action === ACTIONS.PREV) {
        setStepIndex(0)
      }
    },
    [setOnboardingStage, setStepIndex]
  )

  const handleSetOnboardingStageToWizard = () =>
    setOnboardingStage(OnboardingStage.OnboardingWizard)

  return (
    <>
      <AnnouncementModal
        Illustration={illustrationComponent}
        heading={heading}
        description={description}
        primaryButtonLabel={primaryButtonLabel}
        onClickPrimary={handleSetOnboardingStageToWizard}
        secondaryButtonLabel={secondaryButtonLabel}
        onClickSecondary={handleCancelOnboarding}
        onCancel={handleCancelOnboarding}
        isOpen={onboardingStage === OnboardingStage.WelcomeModal}
      />
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={onboardingStage === OnboardingStage.OnboardingWizard}
        stepIndex={stepIndex}
        steps={steps}
        spotlightPadding={0}
        styles={DEFAULT_JOYRIDE_STYLE_OPTIONS}
        tooltipComponent={tooltipComponent}
      />
    </>
  )
}
