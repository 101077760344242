import { ButtonGhost, H2 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledButton = styled(ButtonGhost)<{
  isConfirmationButton?: boolean
}>`
  color: ${({ isConfirmationButton, theme }) =>
    isConfirmationButton ? theme.colors.BLUE_1000 : theme.colors.GREY_550};
`

export const StyledButtonsGroup = styled.div`
  display: flex;
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin-bottom: 0.75rem;
`

export const StyledModalHeaderTitle = styled(H2)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 1rem 0 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  overflow: hidden;
  text-overflow: ellipsis;
`
