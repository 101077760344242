import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useLocationState } from '@npco/utils-routing'

import {
  AddCardLocationStateSchema,
  AddCardState,
} from './useGoToAddCardStage.types'

export const useGoToAddCardStage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = useLocationState(AddCardLocationStateSchema)

  const goToAddCardStage = useCallback(
    (state: AddCardState | undefined, path?: string) => {
      navigate(path ?? location.pathname, {
        state: {
          AddCardModal: state
            ? { ...(locationState?.AddCardModal ?? {}), ...state }
            : undefined,
        },
      })
    },
    [location.pathname, locationState?.AddCardModal, navigate]
  )

  return { goToAddCardStage }
}
