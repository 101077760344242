import {
  combineValidators,
  getHasOnlyDigits,
  getIsValidInputString,
  validateMaxLength,
  validateMaxLengthAndCharsBecs,
  validateRequiredCustomMsg,
} from 'utils/formValidation'
import { CustomValidator } from 'types/common'
import { errorMessages } from 'translations'

const MAX_LENGTH_ACCOUNT_NAME = 32
const MAX_LENGTH_NICKNAME = 256

const getIsBankAccountNumberValid = (value: string) =>
  /^[\da-zA-Z]{5,9}$/.test(value)

export const validateBankAccountNumber: CustomValidator<string | undefined> = (
  value
) => {
  if (!value || !getIsValidInputString(value)) {
    return errorMessages.bankAccountNumberRequired
  }

  if (!getIsBankAccountNumberValid(value)) {
    return errorMessages.accountNumberLength
  }

  return undefined
}

export const validateEztaAccountName = combineValidators(
  validateRequiredCustomMsg(errorMessages.accountNameRequired),
  validateMaxLength(
    MAX_LENGTH_NICKNAME,
    errorMessages.invalidEztaAccountNameLength
  )
)

export const validateNickname = validateMaxLength(
  MAX_LENGTH_NICKNAME,
  errorMessages.invalidNicknameLength
)

export const validateAccountName = combineValidators(
  validateRequiredCustomMsg(errorMessages.accountNameRequired),
  validateMaxLengthAndCharsBecs({
    maxLength: MAX_LENGTH_ACCOUNT_NAME,
    errorMsgLengthAndSpecialChars:
      errorMessages.invalidAccountNameLengthAndCharacters,
    errorMsgSpecialChars: errorMessages.invalidAccountNameCharacters,
  }),
  validateMaxLength(
    MAX_LENGTH_ACCOUNT_NAME,
    errorMessages.invalidAccountNameLength
  )
)

export const VALID_BSB_LENGTH = 6
export const validateBSBNumber: CustomValidator<string | undefined> = (
  value
) => {
  const valueLength = value?.length ?? 0

  if (!value) {
    return errorMessages.bsbNumberRequired
  }

  if (valueLength !== VALID_BSB_LENGTH) {
    return errorMessages.bsbLength
  }

  if (!getHasOnlyDigits(value.toString())) {
    return errorMessages.invalidBsbNumber
  }

  return undefined
}
