import { gql } from '@apollo/client'
import { Box } from '@npco/zeller-design-system'

import { PartialTransactionUpdateType } from 'hooks/transactions'

import { Contact } from '../../../TransactionDrawer.types'
import {
  getHasDownloadableSummary,
  getHasDownloadableSummaryV3,
} from '../TransactionDetails.utils'
import { DownloadSummary } from './DownloadSummary'
import { Images } from './Images'
import { Tags } from './Tags'
import { TransactionDetailsInfo } from './TransactionDetailsInfo'
import {
  TransactionDetailsViewDebitCardAccountTransactionFragment,
  TransactionDetailsViewDebitCardTransactionV2Fragment,
} from './TransactionDetailsView.generated'
import { TransactionNotes } from './TransactionNotes'

interface TransactionDetailsViewProps {
  transaction:
    | TransactionDetailsViewDebitCardTransactionV2Fragment
    | TransactionDetailsViewDebitCardAccountTransactionFragment
  updateTransaction: (
    update: PartialTransactionUpdateType,
    contactUpdate?: Partial<Contact>
  ) => void
}

export const TransactionDetailsView = ({
  transaction,
  updateTransaction,
}: TransactionDetailsViewProps) => {
  const hasSummary =
    transaction.__typename === 'DebitCardTransactionV2'
      ? getHasDownloadableSummary(transaction.type)
      : getHasDownloadableSummaryV3(transaction.type)

  return (
    <Box pb="32px" data-testid="transaction-details-view">
      <TransactionDetailsInfo transaction={transaction} />
      <Images transaction={transaction} />
      <TransactionNotes transaction={transaction} />
      <Tags transaction={transaction} updateTransaction={updateTransaction} />
      {hasSummary && <DownloadSummary transaction={transaction} />}
    </Box>
  )
}

TransactionDetailsView.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransactionDetailsViewDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      type
      ...TransactionDetailsInfoDebitCardTransactionV2Fragment
      ...ImagesDebitCardTransactionV2Fragment
      ...TransactionNotesDebitCardTransactionV2Fragment
      ...TagsDebitCardTransactionV2Fragment
      ...DownloadSummaryDebitCardTransactionV2Fragment
    }

    ${TransactionDetailsInfo.fragments.DebitCardTransactionV2}
    ${Images.fragments.DebitCardTransactionV2}
    ${TransactionNotes.fragments.DebitCardTransactionV2}
    ${Tags.fragments.DebitCardTransactionV2}
    ${DownloadSummary.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment TransactionDetailsViewDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      type
      ...TransactionDetailsInfoDebitCardAccountTransactionFragment
      ...ImagesDebitCardAccountTransactionFragment
      ...TransactionNotesDebitCardAccountTransactionFragment
      ...TagsDebitCardAccountTransactionFragment
      ...DownloadSummaryDebitCardAccountTransactionFragment
    }

    ${TransactionDetailsInfo.fragments.DebitCardAccountTransaction}
    ${Images.fragments.DebitCardAccountTransaction}
    ${TransactionNotes.fragments.DebitCardAccountTransaction}
    ${Tags.fragments.DebitCardAccountTransaction}
    ${DownloadSummary.fragments.DebitCardAccountTransaction}
  `,
}
