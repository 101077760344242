import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { COLOR } from '@npco/zeller-design-system'

import { IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment } from './IncomeAndExpenseLegend.generated'
import { translations } from './IncomeAndExpenseLegend.i18n'
import * as styled from './IncomeAndExpenseLegend.styled'
import {
  checkIsNegativeValue,
  checkIsPositiveValue,
} from './IncomeAndExpenseLegend.utils'
import { IncomeAndExpenseLegendItem } from './IncomeAndExpenseLegendItem'

type IncomeAndExpenseLegendProps = {
  incomeAndExpenses: IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment[]
}

export const IncomeAndExpenseLegend = ({
  incomeAndExpenses,
}: IncomeAndExpenseLegendProps) => {
  const t = useTranslations(translations)

  const isPositiveIncomeExist = incomeAndExpenses.some(
    ({ transactionDirectionTotalAmounts }) =>
      checkIsPositiveValue(transactionDirectionTotalAmounts.income.value)
  )

  const isNegativeIncomeExist = incomeAndExpenses.some(
    ({ transactionDirectionTotalAmounts }) =>
      checkIsNegativeValue(transactionDirectionTotalAmounts.income.value)
  )

  const isPositiveExpenseExist = incomeAndExpenses.some(
    ({ transactionDirectionTotalAmounts }) =>
      checkIsPositiveValue(transactionDirectionTotalAmounts.expense.value)
  )

  const isNegativeExpenseExist = incomeAndExpenses.some(
    ({ transactionDirectionTotalAmounts }) =>
      checkIsNegativeValue(transactionDirectionTotalAmounts.expense.value)
  )

  return (
    <styled.LegendWrapper as="ul">
      <styled.LegendItemsWrapper>
        {isPositiveIncomeExist && (
          <IncomeAndExpenseLegendItem
            legend={{ name: t('income'), color: COLOR.BLUE_1000 }}
          />
        )}
        {isPositiveExpenseExist && (
          <IncomeAndExpenseLegendItem
            legend={{ name: t('expense'), color: COLOR.BLUE_400 }}
          />
        )}
      </styled.LegendItemsWrapper>
      {isNegativeIncomeExist && (
        <IncomeAndExpenseLegendItem
          legend={{ name: t('negativeIncome'), color: COLOR.RED_1000 }}
        />
      )}
      {isNegativeExpenseExist && (
        <IncomeAndExpenseLegendItem
          legend={{ name: t('negativeExpense'), color: COLOR.RED_400 }}
        />
      )}
    </styled.LegendWrapper>
  )
}

IncomeAndExpenseLegend.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      transactionDirectionTotalAmounts {
        income {
          value
        }
        expense {
          value
        }
      }
    }
  `,
}
