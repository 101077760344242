import { BodySmall, Box, Heading } from '@npco/zeller-design-system'

import { useWindowResolution } from 'hooks/useWindowResolution/useWindowResolution'
import { translate } from 'utils/translations'
import { SpinnerWrapped } from 'components/Spinner'

const MOBILE_PADDING_OFFSET = 144

export const LoadingSetPin = () => {
  const { width } = useWindowResolution()

  return (
    <Box
      position="absolute"
      top="45px"
      left={[`${width / 2 - MOBILE_PADDING_OFFSET}px`, '100px']}
    >
      <SpinnerWrapped justifyContent="center" />
      <Box pt="32px" textAlign="center">
        <Heading.H3>
          {translate('page.setPinModal.loadingPin.title')}
        </Heading.H3>
        <BodySmall>
          {translate('page.setPinModal.loadingPin.subtitle')}
        </BodySmall>
      </Box>
    </Box>
  )
}
