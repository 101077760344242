import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT } from '@npco/zeller-design-system'
import { DownloadLinkDesktop } from 'features/StartupModals/SetupFlow/components/DownloadLink/DownloadLinkDesktop'
import { DownloadLinkMobile } from 'features/StartupModals/SetupFlow/components/DownloadLink/DownloadLinkMobile'
import { DownloadLinkTablet } from 'features/StartupModals/SetupFlow/components/DownloadLink/DownloadLinkTablet'
import { PointsSection } from 'features/StartupModals/SetupFlow/SetupFlowModal/pages/TapToPayStage/TapToPayStage/PointsSection'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import {
  ScrollableContent,
  Subtitle,
  Title,
} from '../../../SetupFlowModal.styled'
import { setupFlowTapToPayStageTranslations } from '../TapToPayStage.i18n'

export const MainContent = () => {
  const t = useTranslations(setupFlowTapToPayStageTranslations)

  const isMobileResolution = useIsMobileResolution(BREAKPOINT.XS)
  const isTabletResolution = useIsMobileResolution(BREAKPOINT.LG)

  return (
    <ScrollableContent data-testid="setup-flow-tap-to-app-stage">
      <Title>{t('title')}</Title>
      {isMobileResolution && <DownloadLinkMobile />}
      <Subtitle>{t('description')}</Subtitle>
      {isTabletResolution && !isMobileResolution && <DownloadLinkTablet />}
      <PointsSection />
      {!isTabletResolution && <DownloadLinkDesktop />}
    </ScrollableContent>
  )
}
