import { useEffect, useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetAccountTransactionFiltersContacts } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/debitCardTransactions'

import {
  GetAccountTransactionFiltersContacts as GetAccountTransactionFiltersContactsType,
  GetAccountTransactionFiltersContacts_getContacts_contacts as Contact,
  GetAccountTransactionFiltersContactsVariables,
} from 'types/gql-types/GetAccountTransactionFiltersContacts'

interface Arguments {
  searchInput: string
}

export const CONTACTS_LIMIT = 50

export const useAccountFilterContactQuery = ({ searchInput }: Arguments) => {
  const entityUuid = useSelectedEntityUuid()

  const variables: GetAccountTransactionFiltersContactsVariables = useMemo(
    () => ({
      entityUuid,
      limit: CONTACTS_LIMIT,
      filter: {
        name: { contains: searchInput },
      },
    }),
    [entityUuid, searchInput]
  )

  const { data, fetchMore, networkStatus } = useQuery<
    GetAccountTransactionFiltersContactsType,
    GetAccountTransactionFiltersContactsVariables
  >(GetAccountTransactionFiltersContacts, {
    skip: searchInput === '',
    variables,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (data?.getContacts.nextToken) {
      fetchMore({
        variables: {
          ...variables,
          nextToken: data?.getContacts.nextToken,
        },
      })
    }
  }, [data?.getContacts.nextToken, fetchMore, variables])

  const contacts = (data?.getContacts.contacts ?? []).filter(
    (contact): contact is Contact => contact !== null
  )

  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  return { isLoading, contacts }
}
