import { ReactNode, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { StyledContentCollapserWrapper } from 'components/ContentCollapser/ContentCollapser.styled'

interface ContentEnvelopeProps {
  isActive: boolean
  children: ReactNode
  withOverflowHidden?: boolean
  shouldForceRerender?: boolean
  id?: string
}

export const ContentCollapser = ({
  children,
  isActive,
  withOverflowHidden = false,
  shouldForceRerender = false,
  id,
}: ContentEnvelopeProps) => {
  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 0,
  })

  // You can notice the situation that big content is not fully "uncollapsed".
  // We have to force rerender for such issues (rc-collapse package does similar thing)
  const [, forceRerender] = useState(false)

  return (
    <StyledContentCollapserWrapper
      isActive={isActive}
      height={height}
      data-testid="content-collapser"
      id={id}
      withOverflowHidden={withOverflowHidden}
      onTransitionEnd={() => {
        if (shouldForceRerender) {
          forceRerender(true)
        }
      }}
    >
      <div ref={ref}>{children}</div>
    </StyledContentCollapserWrapper>
  )
}
