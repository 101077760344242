import { createTranslations } from '@npco/utils-translations'

export const transferRemittancesButtonsTranslations = createTranslations({
  sms: 'SMS',
  email: 'Email',
  smsRemittance: 'SMS Remittance',
  emailRemittance: 'Email Remittance',
  smsRemittanceDescription:
    'Send a SMS to notify someone that your payment has been made.',
  emailRemittanceDescription:
    'Send an email to notify someone that a payment has been made. ',

  smsRemittanceCheckBox: 'Also send to my mobile number',
  emailRemittanceCheckBox: 'Also send to my email address',
  mobileNumber: 'Mobile Number',
})
