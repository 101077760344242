import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  Divider,
  Flex,
  Typography,
  useModalState,
} from '@npco/zeller-design-system'

import { formatDateTime } from 'utils/date'
import { getIsMobileDevice } from 'utils/device'
import { getNonNullString } from 'utils/string'
import { translate } from 'utils/translations'
import { DeviceModel } from 'types/devices'
import { DeviceDetails } from 'pages/Devices/DeviceDetails/DeviceDetails.types'
import { StyledTerminalSettingsContainer } from 'pages/Devices/DeviceDetails/DeviceSettings/DeviceSettings.styled'
import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'
import { LoadingSkeletonRow } from 'pages/Devices/LoadingSkeletonRow/LoadingSkeletonRow'

import { EditDeviceNameModal } from '../EditDeviceNameModal/EditDeviceNameModal'
import { translations } from './DeviceSettings.i18n'
import { NetworkDetails } from './NetworkDetails/NetworkDetails'

interface Props {
  selectedDevice: DeviceWithSiteInfo
  deviceDetails: DeviceDetails
  lastTransactionDetails: string | null
  isLoading: boolean
  isTerminalLastTransactionLoading: boolean
  setSelectedDevice: React.Dispatch<
    React.SetStateAction<DeviceWithSiteInfo | null>
  >
  isSiteDevicesPage?: boolean
}

export const DeviceSettings = ({
  selectedDevice,
  setSelectedDevice,
  deviceDetails,
  lastTransactionDetails,
  isLoading,
  isTerminalLastTransactionLoading,
  isSiteDevicesPage = false,
}: Props) => {
  const t = useTranslations(translations)
  const {
    serial,
    appVersion,
    firmwareVersion: imageNumber,
    caid,
    catid,
    deviceUser,
  } = deviceDetails

  const { isModalOpen, openModal, closeModal } = useModalState()
  const details = { appVersion, imageNumber, serial, caid, catid }
  const isMobile = getIsMobileDevice(selectedDevice.model)

  const getDeviceType = (model: DeviceModel) => {
    switch (model) {
      case DeviceModel.ANDROID:
        return t('andriodDevice')
      case DeviceModel.IOS:
        return t('iosDevice')
      case DeviceModel.T2:
        return t('zellerT2')
      case DeviceModel.NEW9220:
      default:
        return t('zellerT1')
    }
  }

  return (
    <StyledTerminalSettingsContainer>
      <Flex justifyContent="space-between" mb="16px">
        <Typography component="h3" variant="heading-xl">
          {t('general')}
        </Typography>
        <ButtonLink onClick={openModal}>{translate('shared.edit')}</ButtonLink>
      </Flex>
      <Flex flexDirection="column" gap="8px">
        <LoadingSkeletonRow
          data-testid="terminal-name"
          isLoading={false}
          label={t('deviceName')}
          value={getNonNullString(selectedDevice.name)}
        />
        <LoadingSkeletonRow
          data-testid="device-type"
          isLoading={false}
          label={t('deviceType')}
          value={getDeviceType(selectedDevice.model as DeviceModel)}
        />
        <LoadingSkeletonRow
          data-testid="site-name"
          isLoading={false}
          label={t('siteAssigned')}
          value={getNonNullString(selectedDevice.siteName, '-')}
        />
        <LoadingSkeletonRow
          data-testid="deivce-owner"
          isLoading={false}
          label={t('deviceOwner')}
          value={getNonNullString(deviceUser, '-')}
        />
        <LoadingSkeletonRow
          data-testid="last-transaction"
          isLoading={isTerminalLastTransactionLoading}
          label={t('lastTransaction')}
          value={
            lastTransactionDetails
              ? formatDateTime(lastTransactionDetails)
              : '-'
          }
        />
      </Flex>
      <Divider />
      {isMobile && (
        <LoadingSkeletonRow
          data-testid="device-id"
          isLoading={isLoading}
          label={t('deviceId')}
          value={getNonNullString(selectedDevice.serial, '-')}
        />
      )}
      {!isMobile && (
        <>
          <Typography component="h3" variant="heading-xl" mb="16px">
            {t('network')}
          </Typography>
          <NetworkDetails deviceUuid={selectedDevice.id} />

          <Divider />
          <Flex flexDirection="column" gap="8px">
            <LoadingSkeletonRow
              label={t('appVersion')}
              value={getNonNullString(details.appVersion, '-')}
              isLoading={isLoading}
            />
            <LoadingSkeletonRow
              label={t('imageNumber')}
              value={getNonNullString(details.imageNumber, '-')}
              isLoading={isLoading}
            />
            <LoadingSkeletonRow
              label={t('serial')}
              value={getNonNullString(details.serial, '-')}
              isLoading={isLoading}
            />
            <LoadingSkeletonRow
              label={t('caid')}
              value={getNonNullString(details.caid, '-')}
              isLoading={isLoading}
            />
            <LoadingSkeletonRow
              label={t('catid')}
              value={getNonNullString(details.catid, '-')}
              isLoading={isLoading}
            />
          </Flex>
        </>
      )}

      <Divider />
      {isModalOpen && (
        <EditDeviceNameModal
          isSiteDevicesPage={isSiteDevicesPage}
          setSelectedDevice={setSelectedDevice}
          selectedDevice={selectedDevice}
          isOpen={isModalOpen}
          onCancel={closeModal}
        />
      )}
    </StyledTerminalSettingsContainer>
  )
}
