import { Box, BREAKPOINT, COLOR, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ProductContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const ProductDisplayT2Card = styled(Flex)`
  width: 100%;
  padding: 24px 24px 56px 24px;
  gap: 8px;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  background: ${COLOR.GREY_30};
  border-radius: 16px;
  font-family: 'Inter Zeller';
  font-style: normal;
  font-weight: normal;
  position: relative;
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: 320px;
    padding: 24px;
  }
`
export const CustomNewBadge = styled(Box)`
  color: ${COLOR.GREEN_1600};
  background-color: ${COLOR.BRIGHT_GREEN};
  border-radius: 88px;
  padding: 4px 12px 4px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
`
