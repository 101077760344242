import { useMemo } from 'react'
import { DebitCardColour } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { COLOR } from '@npco/zeller-design-system'

import CARD_BLACK_PATH from 'assets/svg/cards/card-black.svg'
import CARD_DARK_BLUE_PATH from 'assets/svg/cards/card-dark-blue.svg'
import CARD_GREEN_PATH from 'assets/svg/cards/card-green.svg'
import CARD_LIGHT_BLUE_PATH from 'assets/svg/cards/card-light-blue.svg'
import CARD_WHITE_PATH from 'assets/svg/cards/card-white.svg'
import CARD_YELLOW_PATH from 'assets/svg/cards/card-yellow.svg'

import { cardAltTextTranslations } from './useZellerCardAttributesMap.i18n'

export const useZellerCardAttributesMap = () => {
  const t = useTranslations(cardAltTextTranslations)

  return useMemo(
    () => ({
      [DebitCardColour.WHITE]: {
        src: CARD_WHITE_PATH,
        altText: t('altTextWhiteCard'),
        fontColor: COLOR.GREY_700,
      },
      [DebitCardColour.BLACK]: {
        src: CARD_BLACK_PATH,
        altText: t('altTextBlackCard'),
        fontColor: COLOR.GREY_90,
      },
      [DebitCardColour.DARKBLUE]: {
        src: CARD_DARK_BLUE_PATH,
        altText: t('altTextDarkblueCard'),
        fontColor: COLOR.GREY_90,
      },
      [DebitCardColour.LIGHTBLUE]: {
        src: CARD_LIGHT_BLUE_PATH,
        altText: t('altTextLightblueCard'),
        fontColor: COLOR.GREY_700,
      },
      [DebitCardColour.GREEN]: {
        src: CARD_GREEN_PATH,
        altText: t('altTextGreenCard'),
        fontColor: COLOR.WHITE,
      },
      [DebitCardColour.YELLOW]: {
        src: CARD_YELLOW_PATH,
        altText: t('altTextYellowCard'),
        fontColor: COLOR.GREY_700,
      },
    }),
    [t]
  )
}
