import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'The new Zeller App Available now',
  description1: 'The best way to manage your Zeller Account.',
  description2: 'All Zeller products in the one App.',
  description3: 'Track real-time business performance.',
  description4: 'Instant payment & settlement reporting.',
  description5: 'Manage accounts and issues cards.',
  takeMeToZellerApp: 'Take me to Zeller App',
  continue: 'Continue to mobile site',
  modalName: 'PushAppModal',
})
