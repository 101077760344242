import { Box, Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
  ModalHeader,
} from 'components/Modal/ModalElements/ModalElements'

interface Props {
  children?: React.ReactNode
  dataTestId?: string
  modalDescription?: React.ReactNode
  modalTitle?: string
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  isPrimaryButtonDisabled?: boolean
  isSecondaryButtonDisabled?: boolean
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
  isLoading?: boolean
}

export const BasicModal: React.FC<Props> = ({
  children,
  dataTestId,
  modalDescription,
  modalTitle,
  primaryButtonLabel,
  secondaryButtonLabel,
  isPrimaryButtonDisabled,
  isSecondaryButtonDisabled,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  isLoading,
}) => (
  <Box data-testid={dataTestId || 'basic-modal'}>
    <ModalHeader>{modalTitle}</ModalHeader>
    {modalDescription && (
      <ModalDescription>{modalDescription}</ModalDescription>
    )}
    {children}
    <Flex justifyContent="space-between">
      <CancelButton
        disabled={isSecondaryButtonDisabled}
        onClick={onSecondaryButtonClick}
      >
        {secondaryButtonLabel || translate('shared.cancel')}
      </CancelButton>
      <ConfirmButton
        disabled={isPrimaryButtonDisabled}
        onClick={onPrimaryButtonClick}
        isLoading={isLoading}
      >
        {primaryButtonLabel || translate('shared.submit')}
      </ConfirmButton>
    </Flex>
  </Box>
)
