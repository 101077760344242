import { useTranslations } from '@npco/utils-translations'

import { translations } from './useValidation.i18n'

export const useValidation = () => {
  const t = useTranslations(translations)

  const validateWorkstationId = (workstationId: string) => {
    if (workstationId?.length === 0) {
      return t('requiredError')
    }
    if (workstationId?.length > 50) {
      return t('maxLengthExceededError')
    }
    return undefined
  }

  return { validateWorkstationId }
}
