import { gql } from '@apollo/client'

import { AbnItem } from './AbnItem'
import { EmailItem } from './EmailItem'
import {
  MerchantSecondaryDetailsItemsContactFragment,
  MerchantSecondaryDetailsItemsMerchantDetailsFragment,
} from './MerchantSecondaryDetailsItems.generated'
import {
  getAbn,
  getEmail,
  getWebsite,
} from './MerchantSecondaryDetailsItems.utils'
import { SecondaryDetailsPlaceholder } from './SecondaryDetailsPlaceholder'
import { WebsiteItem } from './WebsiteItem'

interface Props {
  merchant?: MerchantSecondaryDetailsItemsMerchantDetailsFragment | null
  contact: MerchantSecondaryDetailsItemsContactFragment | null
}

export const MerchantSecondaryDetailsItems = ({ merchant, contact }: Props) => {
  const email = getEmail(merchant, contact)
  const website = getWebsite(merchant, contact)
  const abn = getAbn(merchant, contact)

  const merchantHasSecondaryDetailsPopulated = email || website || abn

  return merchantHasSecondaryDetailsPopulated ? (
    <>
      <EmailItem email={email} />
      <WebsiteItem url={website} />
      <AbnItem abn={abn} />
    </>
  ) : (
    <SecondaryDetailsPlaceholder />
  )
}

MerchantSecondaryDetailsItems.fragments = {
  MerchantDetails: gql`
    fragment MerchantSecondaryDetailsItemsMerchantDetailsFragment on MerchantDetails {
      ...GetEmailMerchantDetailsFragment
      ...GetWebsiteMerchantDetailsFragment
      ...GetAbnMerchantDetailsFragment
    }

    ${getEmail.fragments.MerchantDetails}
    ${getWebsite.fragments.MerchantDetails}
    ${getAbn.fragments.MerchantDetails}
  `,
  Contact: gql`
    fragment MerchantSecondaryDetailsItemsContactFragment on Contact {
      ...GetEmailContactFragment
      ...GetWebsiteContactFragment
      ...GetAbnContactFragment
    }

    ${getEmail.fragments.Contact}
    ${getWebsite.fragments.Contact}
    ${getAbn.fragments.Contact}
  `,
}
