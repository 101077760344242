import { gql } from '@apollo/client'
import { PaymentInstrumentType } from '@npco/mp-gql-types'

import { GetPaymentInstrumentTypePaymentInstrumentFragment } from 'types/gql-types/GetPaymentInstrumentTypePaymentInstrumentFragment'

const detailsTypeToPaymentInstrumentType: Record<
  GetPaymentInstrumentTypePaymentInstrumentFragment['details']['__typename'],
  PaymentInstrumentType
> = {
  PaymentInstrumentBpayDynamicCrnDetails:
    PaymentInstrumentType.BPAY_DYNAMIC_CRN,
  PaymentInstrumentBpayStaticCrnDetails: PaymentInstrumentType.BPAY_STATIC_CRN,
  BankAccountDetails: PaymentInstrumentType.BSB,
  NppDetails: PaymentInstrumentType.NPP,
  BpayDetails: PaymentInstrumentType.BPAY,
}

export const getPaymentInstrumentType = (
  paymentInstrument: GetPaymentInstrumentTypePaymentInstrumentFragment
) => {
  return detailsTypeToPaymentInstrumentType[
    paymentInstrument.details.__typename
  ]
}

getPaymentInstrumentType.fragments = {
  PaymentInstrument: gql`
    fragment GetPaymentInstrumentTypePaymentInstrumentFragment on PaymentInstrument {
      details {
        __typename
      }
    }
  `,
}
