import { Box, Flex } from '@npco/zeller-design-system'

import { AVATAR_BADGE_SIZE } from 'types/common'
import { SkeletonLoaderAvatar } from 'components/SkeletonLoader/SkeletonLoaderAvatar'
import {
  SkeletonBodySmall,
  SkeletonH6,
} from 'components/SkeletonLoader/SkeletonLoaderTypography'

const ListElement = () => (
  <Flex alignItems="center" justifyContent="space-between" my="0.5rem">
    <Flex alignItems="center">
      <Box mr="0.75rem">
        <SkeletonLoaderAvatar size={AVATAR_BADGE_SIZE.SMALL} />
      </Box>
      <Box mr="0.75rem">
        <SkeletonBodySmall width={140} margin="0" />
      </Box>
    </Flex>
    <SkeletonBodySmall width={55} margin="0" />
  </Flex>
)

export const TransactionsListSkeletonLoading = () => {
  return (
    <div>
      <SkeletonH6 width={90} margin="0 0 0.5rem 0" />
      <Box mb="1.5rem">
        <ListElement />
        <ListElement />
        <ListElement />
        <ListElement />
        <ListElement />
      </Box>
      <SkeletonH6 width={90} margin="0 0 0.5rem 0" />
      <Box mb="1.5rem">
        <ListElement />
        <ListElement />
        <ListElement />
        <ListElement />
        <ListElement />
      </Box>
      <SkeletonH6 width={90} margin="0 0 0.5rem 0" />
      <ListElement />
    </div>
  )
}
