import { type ReactNode } from 'react'

import * as styled from './CheckoutLayout.styled'

export type CheckoutLayoutProps = {
  children: ReactNode
}

export const CheckoutLayout = ({ children }: CheckoutLayoutProps) => {
  return <styled.CheckoutLayout>{children}</styled.CheckoutLayout>
}

CheckoutLayout.Main = styled.CheckoutLayoutMain
CheckoutLayout.Aside = styled.CheckoutLayoutAside
CheckoutLayout.Divider = styled.CheckoutLayoutDivider
