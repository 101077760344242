import { ApolloQueryResult } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'

import { HlPosConfiguration as HlPosPairConfigResponse } from 'types/gql-types/HlPosConfiguration'

import { Venue as VenueListItem } from './Venue'
import { translations } from './VenueList.i18n'
import { Description, VenueLabel } from './VenueList.styled'
import { VenueListError } from './VenueListError'
import { VenueListLoading } from './VenueListLoading'
import { VenueListNotConnected } from './VenueListNotConnected'

export type VenueListProps = {
  state: VenueListProps.State
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace VenueListProps {
  export type State =
    | { is: 'NotConnected' }
    | { is: 'Loading' }
    | {
        is: 'Error'
        onRetry?: () => Promise<ApolloQueryResult<HlPosPairConfigResponse>>
      }
    | {
        is: 'Ready'
        venues?: Venue[]
        onVenue?: (venueId: string) => void
      }

  export type Venue = {
    id: string
    name: string
    hasAssignments?: boolean
  }
}

export const VenueList = ({ state }: VenueListProps) => {
  const t = useTranslations(translations)

  if (state.is === 'Error') {
    return <VenueListError onRetry={state.onRetry} />
  }

  return (
    <Flex flexDirection="column">
      <Description>{t('description')}</Description>
      {state.is === 'NotConnected' ? (
        <VenueListNotConnected />
      ) : (
        <>
          <VenueLabel>{t('label')}</VenueLabel>
          {state.is === 'Loading' ? (
            <VenueListLoading />
          ) : (
            <Flex gridGap="4px" flexDirection="column">
              {state.venues?.map((venue) => (
                <VenueListItem
                  key={venue.id}
                  id={venue.id}
                  label={venue.name}
                  onClick={() => state.onVenue?.(venue.id)}
                  mode={venue.hasAssignments ? 'EDIT' : 'ASSIGN'}
                />
              ))}
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}
