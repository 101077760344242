import { Box, SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CardOverlay = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 13px;
  background-color: ${({ theme }) => theme.colors.BLACK_900};
  opacity: 0.25;
`

export const TransitionWrapper = styled.div<{
  isVisible: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`
export const SvgIconWithBoxShadow = styled(SvgIcon)`
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  height: 210px;
  width: 333px;
  margin: 1px;
`
