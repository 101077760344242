import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { ExpensesLayout } from 'features/Expenses/ExpensesLayout/ExpensesLayout'
import { SummaryPage } from 'features/Expenses/SummaryPage/SummaryPage'
import { TransactionsPage } from 'features/Expenses/TransactionsPage/TransactionsPage'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

export const ExpensesRoutes = () => {
  const { userRole } = useLoggedInUser()
  const isAdmin = userRole === CustomerRole.ADMIN
  return (
    <Routes>
      <Route element={<ExpensesLayout />}>
        <Route
          index
          element={
            <Navigate
              to={
                isAdmin
                  ? ROOT.ORGS.ORG().EXPENSES.SUMMARY.relative
                  : ROOT.ORGS.ORG().EXPENSES.TRANSACTIONS.relative
              }
            />
          }
        />
        <Route
          element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
        >
          <Route
            path={ROOT.ORGS.ORG().EXPENSES.SUMMARY.relative}
            element={<SummaryPage />}
          />
        </Route>
        <Route
          path={ROOT.ORGS.ORG().EXPENSES.TRANSACTIONS.relative}
          element={<TransactionsPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
