import { ReactNode } from 'react'

import {
  StyledClickableWrapper,
  StyledWrapper,
} from './DashboardTransactionsListItemWrapper.styled'

interface Props {
  onClick?: () => void
  className?: string
  children: ReactNode
  isNew?: boolean
  dataTestId?: string
}

export const DashboardTransactionsListItemWrapper = ({
  children,
  className,
  onClick,
  isNew,
  dataTestId,
}: Props) => {
  if (onClick) {
    return (
      <StyledClickableWrapper
        onClick={onClick}
        className={className}
        role="button"
        $isNew={isNew}
        data-testid={dataTestId}
      >
        {children}
      </StyledClickableWrapper>
    )
  }

  return <StyledWrapper>{children}</StyledWrapper>
}
