import { CustomerType } from '@npco/mp-utils-logged-in-user'

import { IN_REVIEW_STATUSES, UNVERIFIED_STATUSES } from 'const/kyc'
import { getIsVerified } from 'utils/kyc/kycStatus'
import { translate } from 'utils/translations'

export enum VerificationStatus {
  Unverified,
  Verified,
  InReview,
}

const UNVERIFIED = {
  status: VerificationStatus.Unverified,
  display: translate('page.settings.users.idVerification.status.unverified'),
}

const VERIFIED = {
  status: VerificationStatus.Verified,
  display: translate('page.settings.users.idVerification.status.verified'),
}

const IN_REVIEW = {
  status: VerificationStatus.InReview,
  display: translate('page.settings.users.idVerification.status.inReview'),
}

export const getVerificationStatus = (status: CustomerType['kycStatus']) => {
  if (!status) {
    return UNVERIFIED
  }

  if (getIsVerified(status)) {
    return VERIFIED
  }

  if (IN_REVIEW_STATUSES.includes(status)) {
    return IN_REVIEW
  }

  return UNVERIFIED
}

export const getVerificationLinkVisibility = (
  status: CustomerType['kycStatus']
) => {
  if (!status) {
    return true
  }

  return UNVERIFIED_STATUSES.includes(status)
}
