import { useEffect } from 'react'
import { Source } from '@npco/mp-gql-types'
import {
  BUTTON_SIZE as DESIGN_BUTTON_SIZE,
  ButtonGhost,
  Flex,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import {
  useGetDeviceDetails,
  useGetDeviceLastTransaction,
} from 'pages/Devices/DeviceDetails/DeviceDetails.hooks'
import { DeviceSettings } from 'pages/Devices/DeviceDetails/DeviceSettings/DeviceSettings'
import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'

import * as styled from './DeviceDetails.styled'

interface DeviceDetailsProps {
  selectedDevice: DeviceWithSiteInfo
  openTransferModal: (terminal: DeviceWithSiteInfo) => void
  setSelectedDevice: React.Dispatch<
    React.SetStateAction<DeviceWithSiteInfo | null>
  >
  setPosSource: React.Dispatch<React.SetStateAction<Source | null | undefined>>
  areDetailsOpen: boolean
  isSiteDevicesPage?: boolean
}

export const DeviceDetails = ({
  selectedDevice,
  openTransferModal,
  setSelectedDevice,
  setPosSource,
  areDetailsOpen,
  isSiteDevicesPage = false,
}: DeviceDetailsProps) => {
  const {
    error: isDeviceDetailsError,
    isLoading: isDeviceDetailsLoading,
    getDeviceDetails,
    deviceDetails,
  } = useGetDeviceDetails(selectedDevice.id)

  const {
    isLoading: isLastTranscationLoading,
    getDeviceLastTransaction,
    lastTransaction: lastTransactionDetails,
  } = useGetDeviceLastTransaction(selectedDevice.id)

  const isPosDataAvailable =
    deviceDetails.active === Source.LINKLY ||
    deviceDetails.active === Source.HL_POS

  useEffect(() => {
    if (areDetailsOpen && isPosDataAvailable) {
      setPosSource(deviceDetails.active)
    }

    if (!areDetailsOpen) {
      setPosSource(null)
    }
  }, [areDetailsOpen, deviceDetails.active, isPosDataAvailable, setPosSource])

  const isLoading = isDeviceDetailsLoading
  const isTerminalLastTransactionLoading = isLastTranscationLoading
  const isError = Boolean(isDeviceDetailsError)

  if (isError) {
    return (
      <Flex flexDirection="column" flex={1}>
        <GenericErrorPage
          data-testid="terminal-details-error"
          retry={() => {
            getDeviceDetails()
            getDeviceLastTransaction()
          }}
        />
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" flex={1} justifyContent="space-between">
      <DeviceSettings
        isSiteDevicesPage={isSiteDevicesPage}
        lastTransactionDetails={lastTransactionDetails}
        setSelectedDevice={setSelectedDevice}
        deviceDetails={deviceDetails}
        selectedDevice={selectedDevice}
        isLoading={isLoading}
        isTerminalLastTransactionLoading={isTerminalLastTransactionLoading}
      />
      <styled.ButtonContainer>
        <ButtonGhost
          data-testid="transfer-button"
          fullWidth
          onClick={() => {
            openTransferModal(selectedDevice)
          }}
          disabled={isLoading}
          size={DESIGN_BUTTON_SIZE.LARGE}
        >
          {translate('page.terminals.details.reassignButton')}
        </ButtonGhost>
      </styled.ButtonContainer>
    </Flex>
  )
}
