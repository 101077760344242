import { useCallback } from 'react'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useLockCard } from 'hooks/banking'
import { useGoToLockCardStage } from 'hooks/useGoToLockCardStage/useGoToLockCardStage'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'
import { useLockCardState } from 'pages/GlobalModals/LockCardModal/hooks/useLockCardState'

export const useLockCardForm = () => {
  const { state } = useLockCardState()
  const { goToLockCardStage } = useGoToLockCardStage()
  const { lockCard, isLockingCard } = useLockCard(state?.cardId)

  const closeLockCardModal = useCallback(
    () => goToLockCardStage(undefined),
    [goToLockCardStage]
  )

  const handleSubmit = useCallback(async () => {
    const handleError = () => {
      showErrorToast()
    }

    const cardId = state?.cardId

    if (!cardId) {
      handleError()
      return
    }

    const result = await lockCard()

    if (result === UNEXPECTED_ERROR) {
      handleError()
      return
    }

    showSuccessToast(translate('page.cardActionSuccessMessage.lock'))
    closeLockCardModal()
  }, [closeLockCardModal, lockCard, state?.cardId])

  return { handleSubmit, closeLockCardModal, isLockingCard }
}
