import React, { PropsWithChildren } from 'react'
import {
  BodyDefault,
  BodySmall,
  BREAKPOINT,
  Flex,
  Heading,
} from '@npco/zeller-design-system'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ScrollableContent = styled(Flex)`
  flex-direction: column;
  height: 100%;
  grid-gap: 16px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    grid-gap: 24px;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    overflow-y: visible;
  }
`

export const ScrollableContentNarrow = styled(ScrollableContent)`
  padding-bottom: 40px;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    grid-gap: 20px;
    padding-bottom: 0;
  }
`

export const Title = styled(Heading.H1)`
  font-size: 20px;
  line-height: 28px;
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    max-width: 454px;
  }
`

export const Subtitle = styled(BodyDefault)`
  margin: 0;
  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    max-width: 454px;
  }
`

export const SubtitleHighlighted = styled(Subtitle)`
  font-weight: 500;
`

export const Message = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const MotionFooter = ({ children }: PropsWithChildren<unknown>) => (
  <motion.div
    initial={{
      position: 'relative',
      bottom: '24px',
      opacity: 0,
      width: '100%',
    }}
    animate={{
      position: 'relative',
      top: '0',
      opacity: 1,
      width: '100%',
    }}
    transition={{ duration: 0.35, ease: 'easeOut', delay: 0.5 }}
  >
    {children}
  </motion.div>
)

export const MotionMainContent = ({ children }: PropsWithChildren<unknown>) => (
  <motion.div
    layout
    initial={{
      height: '100%',
      opacity: 0,
    }}
    animate={{
      height: '100%',
      opacity: 1,
    }}
    transition={{ duration: 0.3, ease: 'easeIn', delay: 0.3 }}
  >
    {children}
  </motion.div>
)

export const MotionStage = ({ children }: PropsWithChildren<unknown>) => (
  <motion.div
    initial={{
      height: '100%',
      opacity: 0.1,
      filter: 'blur(1px)',
    }}
    animate={{
      height: '100%',
      opacity: 1,
      filter: 'blur(0)',
    }}
    exit={{
      height: '100%',
      opacity: 0.1,
      filter: 'blur(1px)',
    }}
    transition={{ duration: 0.2, ease: 'easeOut' }}
  >
    {children}
  </motion.div>
)
