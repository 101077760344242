import { SyntheticEvent, useMemo, useRef, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { usePopper } from 'react-popper'
import { Options } from '@popperjs/core'
import type * as CSS from 'csstype'

import * as styled from './Tooltip.styled'

interface Props {
  children: React.ReactNode
  className?: string
  contentLeftPosition?: string
  isDisabled?: boolean
  label?: string
  maxWidth?: CSS.Property.MaxWidth
  offset?: [number, number]
  placement?: Options['placement']
  strategy?: Options['strategy']
  tooltipIcon: React.ReactNode
  textAlign?: CSS.Property.TextAlign
}

export const Tooltip = ({
  children,
  className,
  contentLeftPosition,
  isDisabled,
  label,
  maxWidth,
  offset = [0, 13],
  placement = 'top',
  strategy = 'absolute',
  textAlign,
  tooltipIcon,
}: Props) => {
  const isHoverSupported = !window.matchMedia('(hover: none)')?.matches

  const [showPopper, setShowPopper] = useState(false)
  const buttonRef = useRef(null)
  const popperRef = useRef(null)
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null)

  useOnclickOutside(() => setShowPopper(false), { refs: [buttonRef] })

  const buttonProps = useMemo(() => {
    if (isDisabled) {
      return {}
    }

    return isHoverSupported
      ? {
          onMouseEnter: () => setShowPopper(true),
          onMouseLeave: () => setShowPopper(false),
          onFocus: () => setShowPopper(true),
          onBlur: () => setShowPopper(false),
        }
      : {
          onClick: (event: SyntheticEvent) => {
            event.stopPropagation()
            setShowPopper((prevShowPopper) => !prevShowPopper)
          },
        }
  }, [isDisabled, isHoverSupported])

  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      placement,
      strategy,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 16,
          },
        },
      ],
    }
  )

  return (
    <styled.StyledTooltipButton
      type="button"
      ref={buttonRef}
      disabled={isDisabled}
      {...buttonProps}
      className={className}
      aria-label={label}
    >
      {tooltipIcon}
      {showPopper && (
        <styled.StyledPopperContainer
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <styled.StyledTooltipText textAlign={textAlign} maxWidth={maxWidth}>
            {children}
          </styled.StyledTooltipText>
          <styled.StyledPopperArrow
            contentLeftPosition={contentLeftPosition}
            ref={setArrowRef}
            style={styles.arrow}
          />
        </styled.StyledPopperContainer>
      )}
    </styled.StyledTooltipButton>
  )
}
