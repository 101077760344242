import {
  BREAKPOINT,
  ButtonLink,
  Flex,
  Heading,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceTotalWrapper = styled(Flex)`
  align-items: flex-end;
  flex-direction: column;
  grid-gap: 8px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
  }
`

export const ItemTotalValue = styled(Heading.H4)`
  min-width: 70px;
  text-align: right;
`

export const ItemResetCalculate = styled(ButtonLink)`
  justify-content: flex-start;
  flex-grow: 1;
  height: auto;
  padding: 0;
  order: 2;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    align-self: flex-start;
    order: unset;
    flex-grow: initial;
  }
`
