import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { StyledDescription as Description } from 'components/Modal/ModalElements/ModalElements.styled'

export const StyledButtonWrapper = styled(Flex)`
  width: 12.5rem;
  margin-top: 1.5rem;
`

export const StyledButtonsWrapper = styled(Flex)`
  width: 100%;
  margin-top: 1.5rem;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 6.5rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    height: auto;
  }
`

export const StyledLoaderWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`

export const StyledDescription = styled(Description)`
  text-align: center;
`
