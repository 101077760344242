import { useCallback } from 'react'
import {
  ProductTourStatus,
  useLoggedInUser,
} from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { isEmpty } from 'lodash-es'

import { useUpdateCustomer } from 'hooks/useUpdateCustomer/useUpdateCustomer'

export type ProductTourStatuses = { [K in keyof ProductTourStatus]: boolean }

export const useCustomerProductTourStatus = () => {
  const entityUuid = useSelectedEntityUuid()
  const { userData, setUserData } = useLoggedInUser()
  const { updateCustomer } = useUpdateCustomer()

  const productTourStatus = userData?.productTourStatus

  const productTourStatuses: ProductTourStatuses = {
    showAdminMerchantPortalWelcome:
      productTourStatus?.showAdminMerchantPortalWelcome !== false,
    showCorporateCardsWalkthrough:
      productTourStatus?.showCorporateCardsWalkthrough !== false,
    showCorporateCardsSettingsWalkthrough:
      productTourStatus?.showCorporateCardsSettingsWalkthrough !== false,
    showInvoiceInstructions:
      productTourStatus?.showInvoiceInstructions !== false,
    showInvoiceSendViaInfo: productTourStatus?.showInvoiceSendViaInfo !== false,
    showInvoicesCustomisationWelcome:
      productTourStatus?.showInvoicesCustomisationWelcome !== false,
    showInvoicesScheduleSendWelcome:
      productTourStatus?.showInvoicesScheduleSendWelcome !== false,
    showInvoicesSendBySmsWelcome:
      productTourStatus?.showInvoicesSendBySmsWelcome !== false,
    showInvoicesWelcome: productTourStatus?.showInvoicesWelcome !== false,
    showInvoicingCustomisationSettingsWelcome:
      productTourStatus?.showInvoicingCustomisationSettingsWelcome !== false,
    showItemInstructions: productTourStatus?.showItemInstructions !== false,
    showTapToPayInstructions:
      productTourStatus?.showTapToPayInstructions !== false,
    showNotificationsWelcome:
      productTourStatus?.showNotificationsWelcome !== false,
    showOnboardingShop: !!productTourStatus?.showOnboardingShop,
    showCatalogueItemsWelcome:
      productTourStatus?.showCatalogueItemsWelcome !== false,
  }

  const updateProductTourStatus = useCallback(
    async (productTourStatus: Partial<ProductTourStatus>) => {
      if (!userData?.productTourStatus) {
        return false
      }

      // Remove unnecessary updates
      const productTourStatusToUpdate = Object.keys(productTourStatus).reduce<
        Partial<ProductTourStatus>
      >((acc, curr) => {
        const key = curr as keyof ProductTourStatus

        // Ignore if existing value is the same as the new value
        if (userData.productTourStatus?.[key] === productTourStatus[key]) {
          return acc
        }
        acc[key] = productTourStatus[key]
        return acc
      }, {})

      if (isEmpty(productTourStatusToUpdate)) {
        return false
      }

      // NOTE: intentionally and optimistically update product tour status
      // regardless of graphql request response above
      setUserData({
        productTourStatus: {
          ...userData.productTourStatus,
          ...productTourStatusToUpdate,
        },
      })

      const result = await updateCustomer({
        variables: {
          entityUuid,
          customer: {
            id: userData.id,
            ...productTourStatusToUpdate,
          },
        },
      })

      return result.data?.updateCustomer
    },
    [
      entityUuid,
      setUserData,
      updateCustomer,
      userData?.id,
      userData?.productTourStatus,
    ]
  )

  return {
    ...productTourStatuses,
    updateProductTourStatus,
  }
}
