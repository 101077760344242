import { Formik } from 'formik'

import { SelfieIDOption } from '../SelfieVerification.types'
import { SelfieIDOptionFields } from './SelfieIDOptionFields'
import { TimeForASelfieValues } from './TimeForASelfie.types'
import { TimeForASelfieFormWrapper } from './TimeForASelfieFormWrapper'

type TimeForASelfieProps = {
  initiallySelectedOption?: SelfieIDOption
  onSubmit: (values: TimeForASelfieValues) => void
  onCancel?: () => void
  descriptionCta?: string
}

export const TimeForASelfie = ({
  initiallySelectedOption,
  onSubmit,
  onCancel,
  descriptionCta,
}: TimeForASelfieProps) => {
  return (
    <Formik<TimeForASelfieValues>
      initialValues={{ idType: initiallySelectedOption ?? '' }}
      onSubmit={onSubmit}
    >
      <TimeForASelfieFormWrapper
        onCancel={onCancel}
        descriptionCta={descriptionCta}
      >
        <SelfieIDOptionFields />
      </TimeForASelfieFormWrapper>
    </Formik>
  )
}
