import { component } from 'translations'

import {
  StyledClearLabelButton,
  StyledFooterContainer,
} from '../../LabelSelectCombobox.styled'

interface LabelSelectComboboxFooterProps<ItemType> {
  inputValue: string
  setInputValue: (value: string) => void
  setSelectedItem: (item: ItemType | null) => void
}

export const LabelSelectComboboxFooter = <ItemType,>({
  inputValue,
  setInputValue,
  setSelectedItem,
}: LabelSelectComboboxFooterProps<ItemType>) => {
  return (
    <StyledFooterContainer>
      <StyledClearLabelButton
        dataTestId="clear-label"
        disabled={Boolean(!inputValue)}
        onClick={() => {
          setInputValue('')
          setSelectedItem(null)
        }}
      >
        {component.labelSelectCombobox.clearLabel}
      </StyledClearLabelButton>
    </StyledFooterContainer>
  )
}
