import { useState } from 'react'
import { ApolloError } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'
import { ErrorType, ServerError } from 'types/errors'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { ContactType as AnalyticsContactType } from 'services/Analytics/events/contact'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { ContactFormData } from '../../Contacts.types'
import {
  getBusinessCreateContactPayload,
  getBusinessUpdateContactPayload,
  getPersonCreateContactPayload,
  getPersonUpdateContactPayload,
  getUpdatedContactFromFormValues,
  transformCreateContactToGetContact,
} from '../../Contacts.utils'
import { rvContacts } from '../../rv-deprecated/contacts'
import { saveContactsToRv } from '../../rv-deprecated/contacts.utils'
import { useCreateContacts } from '../useCreateContacts/useCreateContacts'
import { useUpdateContact } from '../useUpdateContact/useUpdateContact'

type GetContact = ContactCoreFieldsFragment

interface UseContactFormHelpersProps {
  onCreate?: (
    contact: CreateContact | CreateContactsContact,
    subContact?: GetContact | null
  ) => void
  onEdit?: (updatedContact: GetContact) => void
  contactType: ContactType | null
  isEditAction?: boolean
  contact?: GetContact
}

export const useContactFormHelpers = ({
  onCreate,
  contactType,
  contact,
  onEdit,
}: UseContactFormHelpersProps) => {
  const {
    createContact,
    createContacts,
    isCreatingContact,
    isCreatingContacts,
  } = useCreateContacts()

  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const { isUpdatingContact, updateContact } = useUpdateContact()

  const isLoading = isCreatingContact || isCreatingContacts || isUpdatingContact

  const [existingBusinessContactName, setExistingBusinessContactName] =
    useState<string | null | undefined>(null)

  const [existingPersonContactName, setExistingPersonContactName] = useState<
    string | null | undefined
  >(null)

  const handleSubmitPersonContact = async (values: ContactFormData) => {
    const personPayload = await getPersonCreateContactPayload(values)

    const { linkedContactUuid } = values

    let newContact: CreateContact | CreateContactsContact | null
    let subContact: GetContact | null | undefined = null
    let isCreatingMultipleContacts = false
    let isLinkingToContact = false

    if (linkedContactUuid) {
      // NOTE: create new person and linking existing business
      const response = await createContact(personPayload, linkedContactUuid)
      newContact = response.data?.createContact ?? null
      subContact = rvContacts()?.[linkedContactUuid]
      isLinkingToContact = true
    } else if (values.business.businessName) {
      // NOTE: create new person and new business
      const businessPayload = await getBusinessCreateContactPayload(values)
      const response = await createContacts(personPayload, businessPayload)
      newContact = response.data?.createContacts?.[0] ?? null
      subContact = response.data?.createContacts?.[1]
        ? transformCreateContactToGetContact(response.data.createContacts[1])
        : null
      isCreatingMultipleContacts = true
    } else {
      // NOTE: create person only
      const response = await createContact(personPayload)
      newContact = response.data?.createContact ?? null
    }

    if (!newContact) {
      return
    }

    saveContactsToRv(newContact, subContact ?? null, !!linkedContactUuid)

    onCreate?.(newContact, subContact)
    trackAnalyticsEvent(AnalyticsEventNames.CONTACT_CREATED, {
      Type: AnalyticsContactType.Person,
      Multi: isCreatingMultipleContacts,
      Linked: isLinkingToContact,
      Location: locationName.current,
    })
  }

  const handleSubmitBusinessContact = async (values: ContactFormData) => {
    const businessPayload = await getBusinessCreateContactPayload(values)

    const { linkedContactUuid } = values

    let newContact: CreateContact | CreateContactsContact | null
    let subContact: GetContact | null | undefined = null
    let isCreatingMultipleContacts = false
    let isLinkingToContact = false

    if (linkedContactUuid) {
      // NOTE: create new business and linking existing person
      const response = await createContact(businessPayload, linkedContactUuid)
      newContact = response.data?.createContact ?? null
      subContact = rvContacts()?.[linkedContactUuid]
      isLinkingToContact = true
    } else if (values.person.firstName) {
      // NOTE: create new person and new business
      const personPayload = await getPersonCreateContactPayload(values)
      const response = await createContacts(businessPayload, personPayload)
      newContact = response.data?.createContacts?.[0] ?? null
      subContact = response.data?.createContacts?.[1]
        ? transformCreateContactToGetContact(response.data.createContacts[1])
        : undefined
      isCreatingMultipleContacts = true
    } else {
      // NOTE: create business only
      const response = await createContact(businessPayload)
      newContact = response.data?.createContact ?? null
    }

    if (!newContact) {
      return
    }

    saveContactsToRv(newContact, subContact ?? null, !!linkedContactUuid)

    onCreate?.(newContact, subContact)
    trackAnalyticsEvent(AnalyticsEventNames.CONTACT_CREATED, {
      Type: AnalyticsContactType.Business,
      Linked: isLinkingToContact,
      Multi: isCreatingMultipleContacts,
      Location: locationName.current,
    })
  }

  const handleCreateContact = async (values: ContactFormData) => {
    const isBusinessContactType = contactType === ContactType.BUSINESS

    if (isBusinessContactType) {
      await handleSubmitBusinessContact(values)
    } else {
      await handleSubmitPersonContact(values)
    }
  }

  const handleEditContact = async (formValues: ContactFormData) => {
    const payload =
      contactType === ContactType.BUSINESS
        ? await getBusinessUpdateContactPayload(formValues)
        : await getPersonUpdateContactPayload(formValues)

    if (contact) {
      await updateContact(payload, contact.id)

      if (onEdit) {
        const updatedContact = getUpdatedContactFromFormValues(
          contact.contactType === ContactType.BUSINESS,
          contact,
          formValues,
          payload
        )

        onEdit(updatedContact)
      }
    }
  }

  const handleSubmit = async (values: ContactFormData) => {
    const isBusinessContactType = contactType === ContactType.BUSINESS
    const businessContactName = `${values.business.businessName}`.trim()
    const personContactName =
      `${values.person.firstName} ${values.person.lastName}`.trim()

    const isEditingContact = !!contact

    const [successMessage, failMessage] = !isEditingContact
      ? ([
          'page.contacts.notifications.addSuccessMessage',
          'page.contacts.notifications.addFailMessage',
        ] as const)
      : ([
          'page.contacts.notifications.editSuccessMessage',
          'page.contacts.notifications.editFailMessage',
        ] as const)

    const notificationContactName = isBusinessContactType
      ? businessContactName
      : personContactName

    try {
      setExistingBusinessContactName(null)
      setExistingPersonContactName(null)

      const handleSubmitFn = isEditingContact
        ? handleEditContact
        : handleCreateContact

      await handleSubmitFn(values)

      showSuccessToast(
        translate(successMessage, {
          contactName: notificationContactName,
        })
      )
    } catch (error) {
      if (error instanceof ApolloError) {
        const graphQLErrors = error?.graphQLErrors || []

        const isExistingBusinessResource = graphQLErrors.some(
          ({ errorType, message }: ServerError) =>
            errorType === ErrorType.RESOURCE_ALREADY_EXISTS &&
            message.includes('businessName')
        )

        if (isExistingBusinessResource) {
          setExistingBusinessContactName(businessContactName)
        }

        const isExistingPersonResource = graphQLErrors.some(
          ({ errorType, message }: ServerError) =>
            errorType === ErrorType.RESOURCE_ALREADY_EXISTS &&
            message.includes('firstName')
        )

        if (isExistingPersonResource) {
          setExistingPersonContactName(personContactName)
        }
      }

      showErrorToast(
        translate(failMessage, {
          contactName: notificationContactName,
        })
      )
      throw error
    }
  }

  return {
    isLoading,
    handleSubmit,
    existingBusinessContactName,
    existingPersonContactName,
    setExistingBusinessContactName,
    setExistingPersonContactName,
  }
}
