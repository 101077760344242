import { gql } from '@apollo/client'

export const XeroBankfeed = gql`
  query XeroBankfeed(
    $connection: ConnectionType = XERO_BANKFEEDS
    $entityUuid: ID!
  ) {
    xeroBankFeed: getConnectionState(
      connectionType: $connection
      entityUuid: $entityUuid
    ) {
      id
      status
      errorType
    }
  }
`
