import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'
import { StyledDropdownWrapper } from 'components/Shared'

export const Dropdown = styled(StyledDropdownWrapper)`
  width: 100%;
`
export const ItemsBox = styled(StyledItemsWrapper)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
  }
`

export const SourceBox = styled.ul`
  margin: 0;
  padding: 0;
  max-height: 342px;
  overflow: auto;
`

export const OpenOptions = styled(StyledItemsWrapper)`
  transform: translateY(56px);

  @media (min-width: ${BREAKPOINT.XS}px) {
    transform: none;
  }
`
