import { ModalBasic } from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

export const translations = {
  cancel: translate('shared.cancel'),
  confirm: translate('shared.confirm'),
  content: ({ current, previous }: { current: string; previous: string }) =>
    translate('page.invoice.formSections.items.itemsUpdateItemModalContent', {
      current,
      previous,
    }),
  title: translate(
    'page.invoice.formSections.items.itemsUpdateItemModalHeaderTitle'
  ),
}

export interface InvoiceItemUpdateModalProps {
  index: number
  isLoading: boolean
  onCancel: () => void
  onConfirm: (item: InvoiceItem, skipPriceCheck: boolean) => void
}

export const InvoiceItemUpdateModal = ({
  index,
  isLoading,
  onCancel: handleOnCancel,
  onConfirm: handleOnConfirm,
}: InvoiceItemUpdateModalProps) => {
  const { values } = useFormikContext<InvoiceFormFields>()

  const isTaxInclusive = values.itemsTaxInclusive
  const item = values.items[index]

  const taxRate = isTaxInclusive ? 1.1 : 1

  const previousPriceFormatted = currency(
    item.initialPrice.multiply(taxRate).multiply(item.initialQuantity)
  ).format()

  const currentPriceFormatted = currency(
    item.price.multiply(taxRate).multiply(item.initialQuantity)
  ).format()

  // NOTE: this modal is only shown if a unit price discrepancy originally, when
  // we confirm we override so define true here
  const skipPriceCheck = true

  return (
    <ModalBasic isOpen onCancel={handleOnCancel} title={translations.title}>
      <ModalBasic.Body>
        {translations.content({
          previous: previousPriceFormatted,
          current: currentPriceFormatted,
        })}
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton
          disabled={isLoading}
          onClick={handleOnCancel}
        >
          {translations.cancel}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          isLoading={isLoading}
          onClick={() => handleOnConfirm(item, skipPriceCheck)}
        >
          {translations.confirm}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}
