import tick from 'assets/animations/tick/tick.json'

import { StyledAnimatedButton, StyledStatus } from './DownloadSummary.styled'

interface Props {
  isLoading: boolean
  isCompleted: boolean
  onClick: () => void
  children: React.ReactNode
}

export const AnimatedButton = ({
  isLoading,
  isCompleted,
  onClick,
  children,
}: Props) => {
  return (
    <StyledAnimatedButton
      onClick={onClick}
      isCompleted={isCompleted}
      isLoading={isLoading}
      disabled={isCompleted}
    >
      {isCompleted ? (
        <StyledStatus dataTestId="success-tick" size="1.5rem" icon={tick} />
      ) : (
        children
      )}
    </StyledAnimatedButton>
  )
}
