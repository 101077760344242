import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  Flex,
  Popper,
  PopperItemNew,
  Typography,
} from '@npco/zeller-design-system'

import { ContactOrCategory } from '../../context/CashflowReportingTransactions.types'
import { useCashflowReportingTransactionsContext } from '../../hooks/useCashflowReportingTransactionsContext'
import { categoryOrContactDropdownTranslations } from './CategoryOrContactDropdown.i18n'
import {
  ChevronDownIcon,
  SelectionButton,
} from './CategoryOrContactDropdown.styled'

export const CategoryOrContactDropdown = () => {
  const t = useTranslations(categoryOrContactDropdownTranslations)
  const { contactOrCategorySelection, setContactOrCategorySelection } =
    useCashflowReportingTransactionsContext()

  const handlePopperItemClick = useCallback(
    (selection: ContactOrCategory, setIsOpen: (isOpen: boolean) => void) => {
      setContactOrCategorySelection(selection)
      setIsOpen(false)
    },
    [setContactOrCategorySelection]
  )

  return (
    <Flex gap="8px">
      <Typography variant="heading-xl">{t('title')}</Typography>
      <Popper
        popperWidth="220px"
        placement="bottom-end"
        renderTrigger={({ setIsOpen }) => (
          <SelectionButton
            icon={ChevronDownIcon}
            size={BUTTON_SIZE.XSMALL}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          >
            {t(contactOrCategorySelection)}
          </SelectionButton>
        )}
      >
        {({ setIsOpen }) => (
          <Box p="8px" maxHeight="150px" overflowY="scroll">
            <PopperItemNew
              title={t('category')}
              size="small"
              isSelected={contactOrCategorySelection === 'category'}
              onClick={() => handlePopperItemClick('category', setIsOpen)}
              aria-label={t('category')}
            />
            <PopperItemNew
              title={t('contact')}
              size="small"
              isSelected={contactOrCategorySelection === 'contact'}
              onClick={() => handlePopperItemClick('contact', setIsOpen)}
              aria-label={t('contact')}
            />
          </Box>
        )}
      </Popper>
    </Flex>
  )
}
