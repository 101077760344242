import { useCallback } from 'react'
import { useMFAState } from 'features/MFA'

import { MFA_ERROR, UNEXPECTED_ERROR } from 'types/errors'
import { mapReferenceToProperties } from 'pages/Transfer/BPay/bpay-transfer-modal/BPayModal.utils'
import { getBillerCode } from 'pages/Transfer/BPay/bpay-transfer-modal/utils/getBillerCode/getBillerCode'
import {
  BPayTransferResponseType,
  TransferBpayFields,
} from 'pages/Transfer/Transfer.types'
import {
  getBillerCodeForNewPayment,
  getBillerNameForNewPayment,
  getContactUuidForNewPayment,
  getCRNForNewPayment,
  isDynamicExistingPayment,
  isNewPayment,
  isNewPaymentError,
  isStaticExistingPayment,
  NewPaymentValues,
} from 'pages/Transfer/TransferFields/TransferSubmitButton/TransferBpaySubmitButton.utils'

import { useCreateAndSendBpayTransfer } from './useCreateAndSendBpayTransfer'
import { useSendBpayTransfer } from './useSendBpayTransfer'

export const useCommonSubmitBPayTransfer = ({
  values,
  onSuccess,
  onError,
  onMFA,
}: {
  values: TransferBpayFields
  onSuccess: (result: BPayTransferResponseType) => void
  onError: () => void
  onMFA?: () => void
}) => {
  const { handleSubmit, loading } = useSendBpayTransfer()
  const createAndSend = useCreateAndSendBpayTransfer()
  const { isRedirecting: isRedirectingToMfa } = useMFAState()
  const sendStaticPayment = useCallback(async () => {
    const sendResponse = await handleSubmit({
      amount: values.amount,
      // typescript cannot infer from isStaticExistingPayment
      debitCardAccountUuid: values.from as string,
      paymentInstrumentUuid: values.paymentInstrumentUuid?.id as string,
      type: 'static',
    })

    if (sendResponse === UNEXPECTED_ERROR) {
      onError()
      return
    }

    onSuccess(sendResponse)
  }, [handleSubmit, onError, onSuccess, values])

  const res = mapReferenceToProperties(values?.reference)
  const createPaymentInstrumentAndSendUsingReferenceAsContact = useCallback(
    async (values: NewPaymentValues) => {
      const createAndSendResponse = await createAndSend.handleSubmit({
        amount: values.amount,
        debitCardAccountUuid: values.from,
        nickname: values.nickname,
        billerCode: getBillerCode({
          ...values,
          from: {
            id: values.from,
            name: '',
            accountNumber: '',
            icon: null,
          },
        }),
        billerName: getBillerNameForNewPayment(values),
        crn: getCRNForNewPayment(values),
        contactUuid:
          typeof res === 'string' ? res : res.id || (values.to.id ?? ''),
      })

      if (isNewPaymentError(createAndSendResponse)) {
        onError()
        return
      }

      if (createAndSendResponse === MFA_ERROR) {
        onMFA?.()
        return
      }

      onSuccess(createAndSendResponse)
    },
    [createAndSend, onError, onSuccess, onMFA, res]
  )
  const sendDynamicPayment = useCallback(async () => {
    const sendResponse = await handleSubmit({
      amount: values.amount,
      // typescript cannot infer because isDynamicExistingPayment is extracted as function
      debitCardAccountUuid: values.from as string,
      paymentInstrumentUuid: values.paymentInstrumentUuid?.id as string,
      type: 'dynamic',
      crn: values.crn,
    })

    if (sendResponse === UNEXPECTED_ERROR) {
      onError()
      return
    }

    onSuccess(sendResponse)
  }, [handleSubmit, onError, onSuccess, values])

  const handleClick = useCallback(async () => {
    if (isStaticExistingPayment(values)) {
      sendStaticPayment()
      return
    }

    if (isDynamicExistingPayment(values)) {
      sendDynamicPayment()
      return
    }

    if (isNewPayment(values)) {
      createPaymentInstrumentAndSendUsingReferenceAsContact(values)
      return
    }

    if (values.from && values.nickname && values.to) {
      const createAndSendResponse = await createAndSend.handleSubmit({
        amount: values.amount,
        debitCardAccountUuid: values.from,
        nickname: values.nickname,
        billerCode: getBillerCodeForNewPayment(values),
        billerName: getBillerNameForNewPayment(values),
        crn: getCRNForNewPayment(values),
        contactUuid: getContactUuidForNewPayment(values),
      })
      if (createAndSendResponse === UNEXPECTED_ERROR) {
        onError()
        return
      }

      if (createAndSendResponse === MFA_ERROR) {
        onMFA?.()
        return
      }
      if (typeof createAndSendResponse !== 'string') {
        onSuccess(createAndSendResponse)
      }
    }
  }, [
    values,
    sendStaticPayment,
    sendDynamicPayment,
    createPaymentInstrumentAndSendUsingReferenceAsContact,
    createAndSend,
    onSuccess,
    onError,
    onMFA,
  ])

  return {
    createPaymentInstrumentAndSendUsingReferenceAsContact,
    sendDynamicPayment,
    sendStaticPayment,
    handleClick,
    isDisabled: isRedirectingToMfa,
    isLoading: loading || createAndSend.loading,
  }
}
