import { useTranslations } from '@npco/utils-translations'
import { Box, ButtonLink, Flex } from '@npco/zeller-design-system'

import { Address } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { combineStreets } from 'utils/address'
import { translationsShared } from 'translations'

import { Item } from '../Item'
import { Title } from '../ReviewCard.styled'
import { deliveryAddressReviewTranslations } from './DeliveryAddressReview.i18n'

interface DeliveryAddressReviewProps {
  address: Address
  isLoading: boolean
  onEdit: () => void
}

export const DeliveryAddressReview = ({
  address,
  isLoading,
  onEdit,
}: DeliveryAddressReviewProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(deliveryAddressReviewTranslations)
  return (
    <Box>
      <Flex justifyContent="space-between">
        <Title>{t('addressTitle')}</Title>
        <ButtonLink onClick={onEdit} disabled={isLoading}>
          {tShared('edit')}
        </ButtonLink>
      </Flex>
      <Item
        label={t('streetLabel')}
        value={combineStreets(address.street1, address.street2)}
      />
      <Item label={t('suburbLabel')} value={address.suburb} />
      <Item label={t('stateLabel')} value={address.state} />
      <Item label={t('postcodeLabel')} value={address.postcode} />
    </Box>
  )
}
