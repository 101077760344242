import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'

import { StyledNavbar } from './NavbarLogoMobile.styled'

interface NavbarLogoMobileProps {
  dataTestId?: string
  children?: React.ReactNode
}

export const NavbarLogoMobile = ({
  dataTestId,
  children,
}: NavbarLogoMobileProps) => {
  return (
    <StyledNavbar data-testid={dataTestId}>
      {children}
      <SvgIcon dataTestId="navbar-logo" width="69" height="24">
        <LogoIcon data-testid="logo-zeller" />
      </SvgIcon>
    </StyledNavbar>
  )
}
