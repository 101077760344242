import { useTranslations } from '@npco/utils-translations'
import {
  Anchor,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
  ModalBasic,
  Typography,
} from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ZELLER_SUPPORT_URL } from 'const/externalLinks'
import { translationsShared } from 'translations/index'

import { translations } from '../../../../../../Invoice.i18n'
import { useInvoiceTax } from './hooks/useInvoiceTax'
import { validateForm } from './InvoiceTaxModal.utils'
import { InvoiceTaxOptions } from './InvoiceTaxOptions'

export interface InvoiceTaxModalProps {
  closeModal: () => void
}

export const InvoiceTaxModal = ({ closeModal }: InvoiceTaxModalProps) => {
  const isMobileViewport = useIsMobileResolution()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const { initialValues, handleSubmit } = useInvoiceTax({
    closeModal,
  })

  return (
    <ModalBasic
      isFullScreen={isMobileViewport}
      isOpen
      onCancel={closeModal}
      testId="invoicing-invoice-tax-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validate={(values) => validateForm(values)}
      >
        {({
          handleSubmit: handleFormikSubmit,
          values: { itemsTaxInclusive },
          submitForm: handleFormikSubmitForm,
        }) => (
          <Form
            data-testid="invoicing-invoice-tax-form"
            onSubmit={handleFormikSubmit}
          >
            <ModalBasic.HeaderForm
              onClickPrimary={handleFormikSubmitForm}
              onClickSecondary={closeModal}
              primaryButtonLabel={tShared('save')}
              secondaryButtonLabel={tShared('cancel')}
              title={t('invoiceGst')}
            />
            <ModalBasic.Body canScroll={false}>
              <Flex flexDirection="column">
                <Flex paddingBottom="24px" paddingTop="12px">
                  {t('invoiceGstDescription')}
                </Flex>
                <Flex flexDirection="column" gap="24px">
                  <InvoiceTaxOptions />
                  {initialValues.itemsTaxInclusive !== itemsTaxInclusive && (
                    <InfoBox
                      priority={INFOBOX_PRIORITY.DEFAULT}
                      variant={INFOBOX_VARIANT.NEGATIVE}
                    >
                      <InfoBox.Message>
                        <Flex gap="24px" flexDirection="column">
                          <Typography>{t('invoiceGstWarningTitle')}</Typography>
                          <Typography>
                            {t('invoiceGstWarningDescription')}
                          </Typography>
                          <Typography>
                            {t('invoiceGstWarningSupportMessage', {
                              link: (
                                <Anchor
                                  href={ZELLER_SUPPORT_URL}
                                  target="_blank"
                                >
                                  Zeller Support
                                </Anchor>
                              ),
                            })}
                          </Typography>
                        </Flex>
                      </InfoBox.Message>
                    </InfoBox>
                  )}
                </Flex>
              </Flex>
            </ModalBasic.Body>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  )
}
