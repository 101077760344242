import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardStatusCellDebitCardV2FragmentDoc } from './components/CardStatusCell/CardStatusCell.generated'

export type DebitCardAccountBalanceCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  status: Types.DebitCardStatus
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
  } | null
}

export const DebitCardAccountBalanceCellDebitCardV2FragmentDoc = gql`
  fragment DebitCardAccountBalanceCellDebitCardV2Fragment on DebitCardV2 {
    id
    customer {
      id
      entityUuid
    }
    ...CardStatusCellDebitCardV2Fragment
  }
  ${CardStatusCellDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  DebitCardAccountBalanceCellDebitCardV2Fragment,
  undefined
>
