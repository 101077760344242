import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useGoToCreateSavingsAccount } from '@npco/mp-utils-create-savings-account-routing'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  BUTTON_SIZE,
  ButtonFill,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { useGoToBlockedAction } from '../../../../BlockedAction/blocked-action-routing'
import { savingsAccountHeaderTranslations } from '../SavingsAccountHeader.i18n'
import { ActivateButtonEntityFragment } from './ActivateButton.generated'

type ActivateButtonProps = {
  entity: ActivateButtonEntityFragment
}

export const ActivateButton = ({ entity }: ActivateButtonProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const { goToCreateSavingsAccount } = useGoToCreateSavingsAccount()
  const { goToBlockedAction } = useGoToBlockedAction()

  const t = useTranslations(savingsAccountHeaderTranslations)

  const openActivateSavingsAccount = useCallback(() => {
    const isCreateAccountBlocked = entity?.canCreateAccount === false
    if (isCreateAccountBlocked) {
      goToBlockedAction({ blockedAction: 'createAccount' })
      return
    }

    goToCreateSavingsAccount({ stage: 'tfn' })
  }, [entity?.canCreateAccount, goToBlockedAction, goToCreateSavingsAccount])

  return (
    <ButtonFill
      size={isMobile ? BUTTON_SIZE.XSMALL : BUTTON_SIZE.SMALL}
      onClick={openActivateSavingsAccount}
    >
      {t('activate')}
    </ButtonFill>
  )
}

ActivateButton.fragments = {
  Entity: gql`
    fragment ActivateButtonEntityFragment on Entity {
      canCreateAccount
    }
  `,
}
