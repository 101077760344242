import { object } from 'yup'

import { translate } from 'utils/translations'

import { emailOptionalSchema, emailRequiredSchema } from './email'
import { smsOptionalSchema, smsRequiredSchema } from './sms'

export const deliverySchemaErrors = {
  invalidDeliveryMethod: translate(
    'page.invoice.formSections.email.invalidDeliveryMethod'
  ),
}

export const deliveryOptionalSchema = object({
  email: emailOptionalSchema.when('$values', (values, schema) => {
    return values.delivery.email.isEnabled ? schema : object()
  }),
  sms: smsOptionalSchema.when('$values', (values, schema) => {
    return values.delivery.sms.isEnabled ? schema : object()
  }),
})

export const deliveryRequiredSchema = object({
  email: emailRequiredSchema.when('$values', (values, schema) => {
    return values.delivery.email.isEnabled ? schema : object()
  }),
  sms: smsRequiredSchema.when('$values', (values, schema) => {
    return values.delivery.sms.isEnabled ? schema : object()
  }),
}).test(
  'isDeliverySelected',
  deliverySchemaErrors.invalidDeliveryMethod,
  (delivery) => Boolean(delivery.email.isEnabled || delivery.sms.isEnabled)
)
