import { useEffect } from 'react'
import { InvoiceStatus, RevisionStatus } from '@npco/mp-gql-types'
import { setIn, useFormikContext } from 'formik'

import {
  INVOICE_ATTENTION_TO_STATUS,
  INVOICE_PAYER_STATUS,
} from '../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../Invoice.types'

export const useCustomerContactStatus = (isExpanded?: boolean) => {
  const {
    values: {
      customer: { attentionToContact, payerContact },
      status,
    },
    setValues,
  } = useFormikContext<InvoiceFormFields>()

  const isPayerUpdated =
    payerContact?.status !== undefined &&
    payerContact.status !== RevisionStatus.NO_CHANGE
  const isAttentionToUpdated =
    attentionToContact?.status !== undefined &&
    attentionToContact.status !== RevisionStatus.NO_CHANGE

  const isAnyContactStatusUpdated =
    status === InvoiceStatus.DRAFT && (isPayerUpdated || isAttentionToUpdated)

  useEffect(() => {
    if (isExpanded) {
      return
    }
    // Collapsing customer accordion acts as a
    // dismiss all for all contact update infoBoxes
    if (isAnyContactStatusUpdated) {
      setValues((values) => {
        let updated = setIn(
          values,
          INVOICE_PAYER_STATUS,
          RevisionStatus.NO_CHANGE
        )

        updated = setIn(
          updated,
          INVOICE_ATTENTION_TO_STATUS,
          RevisionStatus.NO_CHANGE
        )
        return updated
      })
    }
  }, [isAnyContactStatusUpdated, isExpanded, setValues])
}
