import { useHistory } from 'react-router-dom'
import { Box, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CompleteIcon } from 'assets/svg/confirmation.svg'
import { ROOT } from 'const/routes'
import { BUTTON_SIZE } from 'types/common'
import { StyledCopy, StyledHeader } from 'pages/Login/Login.styled'
import { AuthLayout } from 'layouts'
import { Button } from 'components/Buttons/Button'
import { page } from 'translations'

import { StyledIconBox } from './EmailVerified.styled'

const { emailVerified } = page

export const EmailVerified: React.FC = () => {
  const history = useHistory()

  return (
    <AuthLayout hasNavBar={false}>
      <StyledIconBox mb="32px" alignItems="center" justifyContent="center">
        <SvgIcon width="16" height="16">
          <CompleteIcon />
        </SvgIcon>
      </StyledIconBox>
      <Box>
        <StyledHeader data-testid="email-verified-page">
          {emailVerified.headline}
        </StyledHeader>
        <StyledCopy>{emailVerified.copy}</StyledCopy>
      </Box>
      <Flex
        width={['100%', '200px']}
        flex="1"
        flexDirection="column"
        justifyContent={['flex-end', 'flex-start']}
      >
        <Button
          fullWidth
          size={BUTTON_SIZE.LARGE}
          onClick={() => history.push(ROOT.path)}
        >
          {emailVerified.buttonLabel}
        </Button>
      </Flex>
    </AuthLayout>
  )
}
