import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT, COLOR, Flex, Tutorial } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { DownloadLinkDesktop } from '../../../../components/DownloadLink/DownloadLinkDesktop'
import { DownloadLinkMobile } from '../../../../components/DownloadLink/DownloadLinkMobile'
import { DownloadLinkTablet } from '../../../../components/DownloadLink/DownloadLinkTablet'
import {
  ScrollableContent,
  Subtitle,
  Title,
} from '../../../SetupFlowModal.styled'
import { setupFlowDownloadStageTranslations } from '../DownloadAppStage.i18n'

export const MainContent = () => {
  const t = useTranslations(setupFlowDownloadStageTranslations)
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.XS)
  const isTabletResolution = useIsMobileResolution(BREAKPOINT.LG)

  return (
    <ScrollableContent data-testid="setup-flow-download-app-stage">
      <Title>{t('title')}</Title>
      {isMobileResolution && <DownloadLinkMobile />}
      <Subtitle>{t('description')}</Subtitle>
      {isTabletResolution && !isMobileResolution && <DownloadLinkTablet />}
      <Flex flexDirection="column" gridGap="4px" lineHeight="24px">
        <Tutorial.BulletPoint
          text={t('point1')}
          iconColour={COLOR.GREEN_1000}
        />
        <Tutorial.BulletPoint
          text={t('point2')}
          iconColour={COLOR.GREEN_1000}
        />
        <Tutorial.BulletPoint
          text={t('point3')}
          iconColour={COLOR.GREEN_1000}
        />
        <Tutorial.BulletPoint
          text={t('point4')}
          iconColour={COLOR.GREEN_1000}
        />
      </Flex>
      {!isTabletResolution && <DownloadLinkDesktop />}
    </ScrollableContent>
  )
}
