import { AnnouncementModal } from '@npco/zeller-design-system'

import { ReactComponent as CardWithShadowIllustration } from 'assets/svg/card-with-shadow.svg'
import { translate } from 'utils/translations'

import { useReportLostCardCompleteModal } from './hooks/useReportLostCardCompleteModal'
import { useReportLostCardCompleteState } from './hooks/useReportLostCardCompleteState'
import {
  StyledDescription,
  StyledSubDescription,
} from './ReportLostCardCompleteModal.styled'

export const ReportLostCardCompleteModal = () => {
  const { state } = useReportLostCardCompleteState()
  const { openAddCardModal, closeReportLostCardCompleteModal } =
    useReportLostCardCompleteModal()

  if (state === null) {
    return null
  }

  return (
    <AnnouncementModal
      isOpen
      description={
        <>
          <StyledDescription>
            {translate('page.reportLostCardCompleteModal.description')}
          </StyledDescription>
          {!state?.isAdmin && (
            <StyledSubDescription>
              {translate('page.reportLostCardCompleteModal.subDescription')}
            </StyledSubDescription>
          )}
        </>
      }
      heading={translate('page.reportLostCardCompleteModal.title')}
      Illustration={<CardWithShadowIllustration />}
      onCancel={closeReportLostCardCompleteModal}
      secondaryButtonLabel={translate('shared.close')}
      onClickSecondary={closeReportLostCardCompleteModal}
      {...(state?.isAdmin && {
        onClickPrimary: openAddCardModal,
        primaryButtonLabel: translate(
          'page.reportLostCardCompleteModal.createNewCardButtonLabel'
        ),
      })}
    />
  )
}
