import { gql } from '@apollo/client'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'
import { isNil } from 'lodash-es'
import { complement, either, isEmpty } from 'ramda'

import { translate } from 'utils/translations'

import {
  GetBpayNicknameDebitCardAccountTransactionFragment,
  GetBpayNicknameDebitCardTransactionV2Fragment,
  GetDebitCardNameDebitCardAccountTransactionFragment,
  GetDebitCardNameDebitCardTransactionV2Fragment,
  GetDescriptionDebitCardAccountTransactionFragment,
  GetDescriptionDebitCardTransactionV2Fragment,
  GetPayerDescriptionDebitCardAccountTransactionFragment,
  GetPayerDescriptionDebitCardTransactionV2Fragment,
  GetPayerReferenceDebitCardAccountTransactionFragment,
  GetPayerReferenceDebitCardTransactionV2Fragment,
  GetPayerReferenceWithFallbackDebitCardAccountTransactionFragment,
  GetPayerReferenceWithFallbackDebitCardTransactionV2Fragment,
  GetSiteNameDebitCardAccountTransactionFragment,
  GetSiteNameDebitCardTransactionV2Fragment,
} from './ListGroupItemDebitCardTransactions.utils.generated'

type TransactionTypeMap<T, V> = {
  [key in DebitCardTransactionTypeV2]: (transaction: T) => V
}

type TransactionTypeMapV3<T, V> = {
  [key in DebitCardAccountTransactionTypeEnum]: (transaction: T) => V
}

const getEmptyString = () => ''

export const getDebitCardName = (
  transaction:
    | GetDebitCardNameDebitCardTransactionV2Fragment
    | GetDebitCardNameDebitCardAccountTransactionFragment
) => transaction.debitCardName ?? ''

getDebitCardName.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetDebitCardNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      debitCardName
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetDebitCardNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      debitCardName
    }
  `,
}

export const getSiteName = (
  transaction:
    | GetSiteNameDebitCardAccountTransactionFragment
    | GetSiteNameDebitCardTransactionV2Fragment
) => transaction.deposit?.siteName ?? ''

getSiteName.fragments = {
  DebitCardAccountTransaction: gql`
    fragment GetSiteNameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      deposit {
        siteName
        id
      }
    }
  `,
  DebitCardTransactionV2: gql`
    fragment GetSiteNameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      deposit {
        siteName
        id
      }
    }
  `,
}

export const getPayerReference = (
  transaction:
    | GetPayerReferenceDebitCardTransactionV2Fragment
    | GetPayerReferenceDebitCardAccountTransactionFragment
) => transaction.reference ?? ''

getPayerReference.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayerReferenceDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      reference
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayerReferenceDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      reference
    }
  `,
}

export const getPayerReferenceWithFallback = (
  transaction:
    | GetPayerReferenceWithFallbackDebitCardTransactionV2Fragment
    | GetPayerReferenceWithFallbackDebitCardAccountTransactionFragment
) =>
  transaction.reference ?? translate('page.transactionDetails.emptyDescription')

getPayerReferenceWithFallback.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayerReferenceWithFallbackDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      reference
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayerReferenceWithFallbackDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      reference
    }
  `,
}

export const getPayerDescription = (
  transaction:
    | GetPayerDescriptionDebitCardTransactionV2Fragment
    | GetPayerDescriptionDebitCardAccountTransactionFragment
) =>
  transaction.description ??
  translate('page.transactionDetails.emptyDescription')

getPayerDescription.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetPayerDescriptionDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      description
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetPayerDescriptionDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      description
    }
  `,
}

export const getNickname = (
  transaction:
    | GetBpayNicknameDebitCardTransactionV2Fragment
    | GetBpayNicknameDebitCardAccountTransactionFragment
) => {
  return transaction?.payeeDetails?.bpayDetails?.nickname ?? ''
}
getNickname.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetBpayNicknameDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payeeDetails {
        bpayDetails {
          nickname
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetBpayNicknameDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payeeDetails {
        bpayDetails {
          nickname
        }
      }
    }
  `,
}

const getInterestEarned = () =>
  translate('page.transactionDetails.transactionTypes.interest')

const getDirectDebit = () =>
  translate('page.transactionDetails.transactionTypes.directDebit')

const TRANSACTION_TYPE_TO_DESC: TransactionTypeMap<
  GetDescriptionDebitCardTransactionV2Fragment,
  string
> = {
  [DebitCardTransactionTypeV2.ATM_IN]: getEmptyString,
  [DebitCardTransactionTypeV2.ATM_OUT]: getEmptyString,
  [DebitCardTransactionTypeV2.BPAY_IN]: getEmptyString,
  [DebitCardTransactionTypeV2.BPAY_OUT]: getNickname,
  [DebitCardTransactionTypeV2.DEPOSIT]: getSiteName,
  [DebitCardTransactionTypeV2.DE_IN]: getPayerDescription,
  [DebitCardTransactionTypeV2.DE_OUT]: getPayerReferenceWithFallback,
  [DebitCardTransactionTypeV2.DE_OUT_RETURN]: getPayerReference,
  [DebitCardTransactionTypeV2.NPP_IN]: getPayerDescription,
  [DebitCardTransactionTypeV2.NPP_IN_RETURN]: getPayerDescription,
  [DebitCardTransactionTypeV2.NPP_OUT]: getPayerReferenceWithFallback,
  [DebitCardTransactionTypeV2.NPP_OUT_RETURN]: getPayerReference,
  [DebitCardTransactionTypeV2.PURCHASE]: getDebitCardName,
  [DebitCardTransactionTypeV2.PURCHASE_CNP]: getDebitCardName,
  [DebitCardTransactionTypeV2.REFUND]: getDebitCardName,
  [DebitCardTransactionTypeV2.REFUND_CNP]: getDebitCardName,
  [DebitCardTransactionTypeV2.TRANSFER_IN]: getPayerReference,
  [DebitCardTransactionTypeV2.TRANSFER_OUT]: getPayerReference,
  [DebitCardTransactionTypeV2.WITHDRAWAL]: getPayerReference,
  [DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT]: getPayerDescription,
  [DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL]: getPayerDescription,
  [DebitCardTransactionTypeV2.INTEREST]: getInterestEarned,
  [DebitCardTransactionTypeV2.DDA_OUT]: getDirectDebit,
  [DebitCardTransactionTypeV2.ACQUIRING_REFUND]: getEmptyString,
  [DebitCardTransactionTypeV2.ACQUIRING_REFUND_RETURN]: getEmptyString,
}

const TRANSACTION_TYPE_TO_DESC_V3: TransactionTypeMapV3<
  GetDescriptionDebitCardAccountTransactionFragment,
  string
> = {
  [DebitCardAccountTransactionTypeEnum.ATM_IN]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.ATM_OUT]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.BPAY_IN]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.BPAY_OUT]: getNickname,
  [DebitCardAccountTransactionTypeEnum.DEPOSIT]: getSiteName,
  [DebitCardAccountTransactionTypeEnum.DE_IN]: getPayerDescription,
  [DebitCardAccountTransactionTypeEnum.DE_OUT]: getPayerReferenceWithFallback,
  [DebitCardAccountTransactionTypeEnum.DE_OUT_RETURN]: getPayerReference,
  [DebitCardAccountTransactionTypeEnum.NPP_IN]: getPayerDescription,
  [DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN]: getPayerDescription,
  [DebitCardAccountTransactionTypeEnum.NPP_OUT]: getPayerReferenceWithFallback,
  [DebitCardAccountTransactionTypeEnum.NPP_OUT_RETURN]: getPayerReference,
  [DebitCardAccountTransactionTypeEnum.PURCHASE]: getDebitCardName,
  [DebitCardAccountTransactionTypeEnum.PURCHASE_CNP]: getDebitCardName,
  [DebitCardAccountTransactionTypeEnum.REFUND]: getDebitCardName,
  [DebitCardAccountTransactionTypeEnum.REFUND_CNP]: getDebitCardName,
  [DebitCardAccountTransactionTypeEnum.TRANSFER_IN]: getPayerReference,
  [DebitCardAccountTransactionTypeEnum.TRANSFER_OUT]: getPayerReference,
  [DebitCardAccountTransactionTypeEnum.WITHDRAWAL]: getPayerReference,
  [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT]: getPayerDescription,
  [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL]:
    getPayerDescription,
  [DebitCardAccountTransactionTypeEnum.DDA_OUT]: getDirectDebit,
  [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND]: getEmptyString,
  [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND_RETURN]: getEmptyString,
}

export const getDescription = (
  transaction:
    | GetDescriptionDebitCardTransactionV2Fragment
    | GetDescriptionDebitCardAccountTransactionFragment
) => {
  if (transaction.__typename === 'DebitCardAccountTransaction') {
    return TRANSACTION_TYPE_TO_DESC_V3[transaction.type](transaction)
  }
  return TRANSACTION_TYPE_TO_DESC[transaction.type](transaction)
}

getDescription.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetDescriptionDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      type
      ...GetPayerReferenceDebitCardTransactionV2Fragment
      ...GetPayerDescriptionDebitCardTransactionV2Fragment
      ...GetPayerReferenceWithFallbackDebitCardTransactionV2Fragment
      ...GetDebitCardNameDebitCardTransactionV2Fragment
      ...GetBpayNicknameDebitCardTransactionV2Fragment
      ...GetSiteNameDebitCardTransactionV2Fragment
    }

    ${getPayerReference.fragments.DebitCardTransactionV2}
    ${getPayerDescription.fragments.DebitCardTransactionV2}
    ${getPayerReferenceWithFallback.fragments.DebitCardTransactionV2}
    ${getDebitCardName.fragments.DebitCardTransactionV2}
    ${getSiteName.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment GetDescriptionDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      type
      ...GetPayerReferenceDebitCardAccountTransactionFragment
      ...GetPayerDescriptionDebitCardAccountTransactionFragment
      ...GetPayerReferenceWithFallbackDebitCardAccountTransactionFragment
      ...GetDebitCardNameDebitCardAccountTransactionFragment
      ...GetBpayNicknameDebitCardAccountTransactionFragment
      ...GetSiteNameDebitCardAccountTransactionFragment
    }

    ${getPayerReference.fragments.DebitCardAccountTransaction}
    ${getPayerDescription.fragments.DebitCardAccountTransaction}
    ${getPayerReferenceWithFallback.fragments.DebitCardAccountTransaction}
    ${getDebitCardName.fragments.DebitCardAccountTransaction}
    ${getSiteName.fragments.DebitCardAccountTransaction}
  `,
}

export const hasValue = complement(either(isEmpty, isNil))
