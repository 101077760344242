import { TableData } from '@npco/ui-table'

import { YourCardListBalanceCell } from '../components/CardBalanceCell/YourCardListBalanceCell'
import { CardNameIconCell } from '../components/CardNameIconCell/CardNameIconCell'
import { YourCardListTableDebitCardV2Fragment } from './YourCardListTable.generated'

export const columnsConfig = [
  {
    accessorId: 'CardNameIconCell',
    cellComponent: CardNameIconCell<
      TableData<YourCardListTableDebitCardV2Fragment>
    >,
    cellSize: { _: 15 },
    cellSkeletonWidth: [
      { _: 130, MD: 100 },
      { _: 76, MD: 164 },
      { _: 150, MD: 112 },
    ],
  },
  {
    accessorId: 'YourCardListBalanceCell',
    cellComponent: YourCardListBalanceCell<
      TableData<YourCardListTableDebitCardV2Fragment>
    >,
    cellSize: { _: 9 },
    cellSkeletonWidth: [
      { _: 72, MD: 72 },
      { _: 96, MD: 72 },
      { _: 64, MD: 72 },
    ],
  },
]
