import { Accordion, BREAKPOINT, Flex } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { getLoaderWidthMap } from './InvoiceSkeletonLoader.utils'

interface InvoiceSkeletonLoaderProps {
  dataTestId: string
}

export const InvoiceSkeletonLoader = ({
  dataTestId,
}: InvoiceSkeletonLoaderProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.XS)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)
  const [loader1, loader2, loader3, loader4, loader5] = getLoaderWidthMap(
    isMobile,
    isMobileOrTablet
  )

  return (
    <Flex data-testid={dataTestId} flexDirection="column" gridGap="8px">
      <Accordion.Loader titleWidth={loader1[0]} detailsWidth={loader1[1]} />
      <Accordion.Loader titleWidth={loader2[0]} detailsWidth={loader2[1]} />
      <Accordion.Loader titleWidth={loader3[0]} detailsWidth={loader3[1]} />
      <Accordion.Loader titleWidth={loader4[0]} detailsWidth={loader4[1]} />
      <Accordion.Loader titleWidth={loader5[0]} detailsWidth={loader5[1]} />
    </Flex>
  )
}
