import { createTranslations } from '@npco/utils-translations'

export const errorModalTranslations = createTranslations({
  errorTitle: 'Something went wrong',
  errorDesc:
    'We were unable to process your payment.\nIf this problem persists, please contact Zeller Support on 1800 935 537.',
  tryAgain: 'Try Again',
  signOut: 'Sign Out',
  goToDashboardButtonLabel: 'Go to Dashboard',
})
