import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  amountBalanceContent: 'Balance: {amount}',
  autoReminderSendHeader: 'Send Reminder ({reminderIndex})',
  autoReminderSentHeader: 'Reminder Sent ({reminderIndex})',
  cancelInvoiceHeader: 'Cancelled',
  cnpPaymentDueHeader: '{amount} Payment Due',
  cnpPaymentReceivedHeader: '{amount} Received',
  contactContent: 'To: {contactName}',
  createInvoiceHeader: 'Created',
  draftInfobox:
    'Automated events in this Activity timeline will only trigger if the Invoice has been sent.',
  failedInvoiceSend: 'Failed to Send Invoice',
  manualPaymentHeader: '{amount} Manual Payment Recorded',
  manualReminderHeader: 'Manual Reminder Sent',
  paymentDueHeader: '{amount} Payment Due',
  paymentRefContent: 'Ref #{referenceNumber} · Balance {amount}',
  placeholderButton: 'Edit Invoice',
  placeholderDescription:
    'Add more information to this Invoice to see activity.',
  placeholderTitle: 'No Activity Yet',
  reviewRecipients: 'Review recipients contact details and resend',
  sendInvoiceContent: 'To: {contactName} · Due: {dueDate}',
  sendInvoiceSendHeader: 'Send',
  sendInvoiceSentHeader: 'Sent',
  sentViaEmailAndSmsHeader: 'Sent via Email & SMS',
  sentViaEmailHeader: 'Sent via Email',
  sentViaEmailFailedHeader: 'Email Delivery Failed',
  sentViaSmsHeader: 'Sent via SMS',
  sentViaSmsFailedHeader: 'SMS Delivery Failed',
  updateInvoiceHeader: 'Invoice Updated',
})
