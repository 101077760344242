import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Close } from 'assets/svg/close.svg'

import { StyledCopy, StyledRow } from './Elements.styled'

interface TopRowProps {
  copy: string
  handleCloseMenu: () => void
}

export const TopRow: React.FC<TopRowProps> = ({ copy, handleCloseMenu }) => (
  <StyledRow>
    <StyledCopy>{copy}</StyledCopy>
    <SvgIcon
      width="12"
      height="12"
      hoverColor={COLOR.GREY_550}
      withCursor
      dataTestId="picker-close"
    >
      <Close onClick={handleCloseMenu} />
    </SvgIcon>
  </StyledRow>
)
