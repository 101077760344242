import { useMutation } from '@apollo/client'
import { CreateItemInput } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  CreateInvoiceItem as CreateInvoiceItemResponse,
  CreateInvoiceItemVariables,
} from 'types/gql-types/CreateInvoiceItem'

import { CreateItem } from './graphql/createItem'

export const useCreateItem = () => {
  const entityUuid = useSelectedEntityUuid()
  const [createItem, { loading: isCreatingItem }] = useMutation<
    CreateInvoiceItemResponse,
    CreateInvoiceItemVariables
  >(CreateItem)

  return {
    createItem: (item: CreateItemInput) =>
      createItem({ variables: { entityUuid, item } }),
    isCreatingItem,
  }
}
