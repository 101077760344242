import { object, string } from 'yup'

import { translate } from 'utils/translations'

export const customerSchemaErrors = {
  customerPayerRequiredError: translate(
    'page.invoice.formSections.customer.payerRequired'
  ),
}

export const customerOptionalSchema = object({
  payerContact: object({
    contactUuid: string(),
  }).nullable(),
  attentionToContact: object({
    contactUuid: string(),
  }).nullable(),
})

export const customerRequiredSchema = object({
  payerContact: object({
    contactUuid: string().required(
      customerSchemaErrors.customerPayerRequiredError
    ),
  }).typeError(customerSchemaErrors.customerPayerRequiredError),
})
