import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  EducationPanel,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import DEBIT_CARDS_EMPTY_SRC from 'assets/images/debit-cards-empty.png'
import DEBIT_CARDS_EMPTY_SMALL_SRC from 'assets/images/debit-cards-empty-small.png'
import { translationsShared } from 'translations'

import { SideImage, SideImageContainer } from '../../../../CardsView.styled'
import { cardEmptyViewDebitTranslations } from './CardEmptyViewDebit.i18n'

export interface CardEmptyViewProps {
  onAddCard: () => void
}

export const CardEmptyViewDebit = ({ onAddCard }: CardEmptyViewProps) => {
  const t = useTranslations(cardEmptyViewDebitTranslations)
  const tShared = useTranslations(translationsShared)
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const imageSrc = isMobile
    ? DEBIT_CARDS_EMPTY_SMALL_SRC
    : DEBIT_CARDS_EMPTY_SRC

  return (
    <EducationPanel
      data-testid="card-empty-view-debit"
      image={
        <SideImageContainer>
          <SideImage src={imageSrc} alt={t('imageAlt')} />
        </SideImageContainer>
      }
      title={t('title')}
      description={t('description')}
      items={[t('item1'), t('item2'), t('item3')]}
      ctaDescription={t('ctaDescription')}
      ctaPrimary={{
        text: tShared('addCard'),
        onClick: onAddCard,
      }}
    />
  )
}
