import { GraphQLFormattedError } from 'graphql'

export enum ErrorType {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  CUSTOMER_EMAIL_ALREADY_EXISTS = 'CUSTOMER_EMAIL_ALREADY_EXISTS',
  CUSTOMER_IDENTITY_ALREADY_EXISTS = 'CUSTOMER_IDENTITY_ALREADY_EXISTS',
  DUPLICATE_ITEM_SKU = 'DUPLICATE_SKU',
  DUPLICATE_MODIFIER_NAME = 'DUPLICATE_MODIFIER_NAME',
  DUPLICATE_MODIFIER_SET_NAME = 'DUPLICATE_MODIFIER_SET_NAME',
  GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT',
  INVALID_REQUEST = 'INVALID_REQUEST',
  MFA_REQUIRED = 'MFA_REQUIRED',
  MFA_SENSITIVE_ACCESS_EXPIRED = 'MFA_SENSITIVE_ACCESS_EXPIRED',
  MODIFIER_SET_LIMIT_EXCEEDED = 'MODIFIER_SET_LIMIT_EXCEEDED',
  NOT_FOUND = 'NOT_FOUND',
  RESOURCE_ALREADY_EXISTS = 'RESOURCE_ALREADY_EXISTS',
  SERVER_ERROR = 'SERVER_ERROR',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  UNAUTHORIZED_EXCEPTION = 'UnauthorizedException',
}

export enum ErrorInfoType {
  ACCOUNT_DISABLED = 'ACCOUNT_DISABLED',
  ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
  DUPLICATE_MODIFIER_NAME = 'DUPLICATE_MODIFIER_NAME',
  ZELLER_DECLINE = 'ZELLER_DECLINE',
}

type ErrorInfo = {
  duplicateModifiers?: string[]
  errorDescription?: string
  type: ErrorInfoType
}

export type ServerError = {
  errorType?: ErrorType | string
  errorInfo?: ErrorInfo
} & GraphQLFormattedError

export const UNEXPECTED_ERROR = 'unexpected error'
export const MFA_ERROR = 'mfa error'
export const RESOURCE_ALREADY_EXISTS_ERROR = 'resource already exists'
export const OVERDRAWN_LIMIT_EXCEEDED_ERROR =
  'Refund Rejected due to overdrawn limit'
export const DAILY_TRANSFER_LIMIT_EXCEEDED = 'daily transfer limit exceeded'
export const DUPLICATE_NAME_ERROR = 'duplicate name error'
export const ZOD_SCHEMA_PARSE_ERROR = 'ZOD_SCHEMA_PARSE_ERROR'
