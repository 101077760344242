import * as styled from '../TransactionHeader.styled'
import { Title } from './Title'

type DrawerHeaderProps = {
  children: React.ReactNode
}

export const DrawerHeading = ({ children }: DrawerHeaderProps) => {
  return <styled.Header>{children}</styled.Header>
}

DrawerHeading.Title = Title
