import {
  StyledItemWrapper,
  StyledLabel,
  StyledValue,
} from './DrawerItem.styled'
import { DrawerItemSkeleton } from './DrawerItemSkeleton'

interface Props {
  label: React.ReactNode
  value: React.ReactNode
}

export const DrawerItem = ({ label, value }: Props) => {
  return (
    <StyledItemWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>{value}</StyledValue>
    </StyledItemWrapper>
  )
}

DrawerItem.Skeleton = DrawerItemSkeleton
