import { gql } from '@apollo/client'

export const GetRecentlyUsedLineItems = gql`
  query GetRecentlyUsedLineItems($entityUuid: ID!) {
    getRecentlyUsedLineItems(entityUuid: $entityUuid) {
      __typename
      catalogItem {
        id
      }
      description
      id
      name
      price
      taxes {
        enabled
      }
    }
  }
`
