import { gql, type TypedDocumentNode } from '@apollo/client'

export type CannotPayByCardWarningMessageEntityFragment = {
  __typename?: 'Entity'
  id: string
  canPayByCard: boolean | null
}

export const CannotPayByCardWarningMessageEntityFragmentDoc = gql`
  fragment CannotPayByCardWarningMessageEntityFragment on Entity {
    id
    canPayByCard
  }
` as unknown as TypedDocumentNode<
  CannotPayByCardWarningMessageEntityFragment,
  undefined
>
