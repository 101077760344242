import { ApolloCache, ApolloClient } from '@apollo/client'
import { GetCorporateCardsListQueryResponse } from 'features/Cards/AllCardsCorporate/hooks/useQueryCorporateCards/graphql/getCorporateCardsList.generated'

import { cacheCorporateCardList } from '../cacheCorporateCardList/cacheCorporateCardList'

type CorporateCard = NonNullable<
  GetCorporateCardsListQueryResponse['getDebitCardsV2']['cards'][number]
>

interface CacheNewCorporateCardProps {
  entityUuid: string
  cache: ApolloCache<any>
  newCard: CorporateCard
  client?: ApolloClient<any>
}

export const cacheNewCorporateCard = ({
  entityUuid,
  cache,
  newCard,
  client,
}: CacheNewCorporateCardProps) => {
  const updateCardsList = (oldCards: (CorporateCard | null)[]) => {
    const isCardAlreadyCached = oldCards.some(
      (existingCard) => existingCard?.id === newCard.id
    )

    if (isCardAlreadyCached) {
      return oldCards.map((existingCard) => {
        if (existingCard?.id === newCard.id) {
          return newCard
        }

        return existingCard
      })
    }

    return [newCard, ...oldCards]
  }

  cacheCorporateCardList({
    cache,
    entityUuid,
    mapToNewList: updateCardsList,
    client,
  })
}
