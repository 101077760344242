import { AUSTRALIA_COUNTRY_CODE } from '@npco/component-mp-common'
import { Box, Heading } from '@npco/zeller-design-system'

import {
  combineValidators,
  validateAddressString,
  validatePostcode,
  validateRequired,
  validateSuburb,
} from 'utils/formValidation'
import { ProfileSettingFormValues } from 'types/profile'
import { Address } from 'forms/formSections/Address'
import { Country } from 'forms/formSections/Country'
import {
  validateAddressLineWithPoBox,
  validatePostcodeWithPoBox,
} from 'forms/formViews/OfficeAddress/OfficeAddress.validators'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { form, page, shared } from 'translations'

export interface Props {
  values?: Partial<ProfileSettingFormValues> | null
  isDisabled?: boolean
}

export const ProfilePersonalAddress = ({
  values,
  isDisabled = false,
}: Props) => {
  const isAustralianAddress =
    values?.country === AUSTRALIA_COUNTRY_CODE ||
    values?.country === 'Australia'

  return (
    <>
      <Box mb="2rem">
        <Heading.H3>{page.settings.users.personalAddress}</Heading.H3>
      </Box>
      <Box mb="2.5rem" data-testid="address-country">
        <Country
          validator={validateRequired}
          label={form.shared.country}
          isDisabled={isDisabled}
        />
      </Box>
      <FormLine>
        <Box mb="2.5rem">
          <InputAdaptiveFieldWrapper
            label={shared.address.label}
            name="street"
            placeholder={shared.address.placeholder}
            type="text"
            validate={combineValidators(
              validateAddressString,
              validateAddressLineWithPoBox
            )}
            maxLength={40}
            disabled={isDisabled}
          />
        </Box>
      </FormLine>
      <Address
        suburbValidator={
          isAustralianAddress ? validateSuburb : validateRequired
        }
        stateValidator={validateRequired}
        postcodeValidator={
          isAustralianAddress
            ? combineValidators(validatePostcode, validatePostcodeWithPoBox)
            : validateRequired
        }
        isStateText={!isAustralianAddress}
        shouldValidate
        isFormDisabled={isDisabled}
      />
    </>
  )
}
