import dayjs from 'utils/dayjs'
import { shared } from 'translations'

export const getEndOfCurrentDay = () => dayjs().endOf('day').toDate()

export const getStartOfMonth = () => dayjs().startOf('month').toDate()

export const getStartOfCurrentDay = () => dayjs().startOf('day').toDate()

export const getStartOfPreviousMonth = () =>
  dayjs().subtract(1, 'month').startOf('month').toDate()

export const getStartOfDayOneWeekAgo = () =>
  dayjs().subtract(1, 'week').startOf('day').toDate()

export const getEndOfDayOneWeekAgo = () =>
  dayjs().subtract(1, 'week').endOf('day').toDate()

export const getEndOfDayOneMonthAgo = () =>
  dayjs().subtract(1, 'month').endOf('day').toDate()

export const getThisTimeOneWeekAgo = () => dayjs().subtract(1, 'week').toDate()

export const getEndOfPreviousMonth = () =>
  dayjs().subtract(1, 'month').endOf('month').toDate()

export const getCurrentMonth = () =>
  new Date().toLocaleString('en-AU', { month: 'long' })

export const getCurrentDay = () => shared.daysOfWeek[new Date().getDay()]

export const getStartOfDayOneMonthAgoPlusDay = () =>
  dayjs().subtract(1, 'month').startOf('day').add(1, 'day').toDate()

export const getCurrentTime = () => dayjs()
