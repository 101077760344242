import { Source } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'

import { GetDevicePosSettings_getDeviceSettings_posSettings as PosSettingsType } from 'types/gql-types/GetDevicePosSettings'

import { LinklyDetails } from './LinklyDetails/LinklyDetails'
import { OraclePosDetails } from './OraclePosDetails/OraclePosDetails'
import { PayAtTableDetails } from './PayAtTableDetails/PayAtTableDetails'

interface PosSettingsProps {
  posDetails: PosSettingsType
  posLastTransaction: string | null
  isLoading: boolean
  isPosLastTransactionLoading: boolean
  deviceId: string
  deviceName: string
  siteId: string
  posSource?: Source | null
}

export const PosSettings = ({
  posDetails,
  posLastTransaction,
  isLoading,
  isPosLastTransactionLoading,
  deviceId,
  deviceName,
  siteId,
  posSource,
}: PosSettingsProps) => {
  return (
    <Flex flexDirection="column" flex={1} width="100%">
      {posSource === Source.LINKLY && (
        <LinklyDetails
          posDetails={posDetails}
          isLoading={isLoading}
          posLastTransaction={posLastTransaction}
          isPosLastTransactionLoading={isPosLastTransactionLoading}
          deviceId={deviceId}
        />
      )}
      {posSource === Source.HL_POS && (
        <PayAtTableDetails
          posDetails={posDetails}
          isLoading={isLoading}
          deviceId={deviceId}
          siteId={siteId}
        />
      )}
      {posSource === Source.ORACLE_POS && (
        <OraclePosDetails
          deviceUuid={deviceId}
          deviceName={deviceName}
          workstationId={posDetails.oracleSettings?.workstationId}
          isLoading={isLoading}
        />
      )}
    </Flex>
  )
}
