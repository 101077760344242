import { InvoiceDiscountFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { discountSchema } from 'features/Invoicing/components/Invoices/Invoice/schemas/discount'
import { yupToFormErrors } from 'formik'

export const validateForm = (values: InvoiceDiscountFormFields) => {
  return discountSchema
    .validate(values, { abortEarly: false })
    .then(() => ({}))
    .catch(yupToFormErrors)
}
