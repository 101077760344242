import { useEffect, useState } from 'react'
import { Box, ButtonLink, Flex } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { validateConfirmEmail, validateEmail } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { ProfileSettingFormValuesWithEmail } from 'types/profile'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { shared } from 'translations'

export const ProfilePersonalEmail = () => {
  const { values, validateForm } =
    useFormikContext<ProfileSettingFormValuesWithEmail>()
  const [isConfirmFieldDisplayed, setIsConfirmFieldDisplayed] =
    useState<boolean>(false)

  useEffect(() => {
    validateForm()
  }, [validateForm, values])

  return (
    <>
      <FormLine>
        <Box mb="2.5rem">
          {!isConfirmFieldDisplayed && (
            <Flex mb="0.5rem" flexDirection="row-reverse">
              <ButtonLink
                onClick={() => {
                  setIsConfirmFieldDisplayed(true)
                }}
              >
                {translate('shared.change')}
              </ButtonLink>
            </Flex>
          )}
          <InputAdaptiveFieldWrapper
            label={translate('page.settings.users.email')}
            name={shared.email}
            type="text"
            validate={(value) => validateEmail(value.trim())}
            maxLength={50}
            disabled={!isConfirmFieldDisplayed}
            data-testid="profile-personal-email"
            isRequired
          />
        </Box>
      </FormLine>
      {isConfirmFieldDisplayed && (
        <FormLine>
          <Box mb="2.5rem">
            <InputAdaptiveFieldWrapper
              label={translate('page.settings.users.confirmEmail')}
              name="emailConfirm"
              type="text"
              validate={(value) =>
                validateConfirmEmail(value.trim(), values.email?.trim())
              }
              maxLength={50}
              isRequired
            />
          </Box>
        </FormLine>
      )}
    </>
  )
}
