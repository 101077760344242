import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'
import { translate } from 'utils/translations'

import { PopupModal } from './components/PopupModal'
import { useSetPinState } from './hooks/useSetPinState'
import { SetPinStage } from './SetPinStage/SetPinStage'

export const SetPinModal = () => {
  const { state } = useSetPinState()
  const { goToSetPinStage } = useGoToSetPinStage()

  if (state === null) {
    return null
  }

  return (
    <PopupModal
      title={translate('page.setPinModal.modalTitle')}
      isOpen
      onCancel={() => goToSetPinStage(undefined)}
      dataTestId="set-pin-modal"
    >
      <SetPinStage />
    </PopupModal>
  )
}
