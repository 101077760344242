import { ReactNode } from 'react'

type ReferenceObject = {
  label: string
  subLabel?: string | ReactNode
  value: string
  id: string
}

type Reference = ReferenceObject | string

export function isReferenceObject(
  reference?: Reference
): reference is ReferenceObject {
  return typeof reference !== 'string'
}

export function mapReferenceToProperties(reference?: Reference) {
  if (reference === undefined) {
    return { label: '', subLabel: '', value: '', id: '' }
  }

  if (isReferenceObject(reference)) {
    const { label, value, id } = reference
    return {
      label,
      value,
      id,
      subLabel:
        typeof reference.subLabel === 'object' ? '' : reference.subLabel,
    }
  }

  if (isReferenceObject(reference)) {
    return reference
  }

  return { id: reference, label: '', subLabel: '', value: '' }
}
