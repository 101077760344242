import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

interface OnboardingPageContainerProps {
  isLoaderContainer?: boolean
  isVerticallyCentered?: boolean
}

export const StyledOnboardingPageContainer = styled.main<OnboardingPageContainerProps>`
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  position: relative;
  align-items: ${({ isVerticallyCentered }) =>
    isVerticallyCentered ? 'center' : 'flex-start'};

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    padding: 0 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    padding: 0 1rem;
    align-items: ${({ isLoaderContainer }) =>
      isLoaderContainer ? 'center' : 'flex-start'};
  }
`
