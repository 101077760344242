import {
  BodySmallTypography,
  StyledSkeletonCSS,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceMobileItem = styled.td`
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 6px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.GREY_90};
`

export const RowWrapper = styled.div`
  ${BodySmallTypography};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`

export const Element = styled.span<{
  alignRight?: boolean
  color?: string
}>`
  ${BodySmallTypography};
  display: block;
  margin: ${({ alignRight }) => (alignRight ? '0 0 0 16px' : '0')};
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
  color: ${({ color }) => color || 'inherit'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: ${({ alignRight }) => (alignRight ? 'fit-content' : '0')};
`

export const Dot = styled(Element)`
  margin: 0 5px;

  &:before {
    content: '·';
  }
`

export const RefNumberElement = styled.span`
  flex: 1 0 50px;
`

export const LoadingSkeleton = styled.span`
  ${StyledSkeletonCSS}
  border-radius: 8px;
  display: inline-block;
  height: 8px;
  width: 100%;
`
