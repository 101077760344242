import { ZELLER_SUPPORT_URL } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { AnchorBasic } from '@npco/zeller-design-system'

import { translations } from './ContactZellerSupport.i18n'

export const ContactZellerSupport = () => {
  const t = useTranslations(translations)

  return (
    <AnchorBasic href={ZELLER_SUPPORT_URL}>
      {t('contactZellerSupport')}
    </AnchorBasic>
  )
}
