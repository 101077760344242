import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountBalanceChartViewDebitCardAccountBalanceFragmentDoc } from '../../../AccountBalanceChartView.generated'

const defaultOptions = {} as const
export type AccountBalanceChartQueryVariables = Types.Exact<{
  range: Types.DateRange
  timeZone: Types.Scalars['String']['input']
  entityUuid: Types.Scalars['ID']['input']
}>

export type AccountBalanceChartQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountBalances: Array<{
    __typename: 'DebitCardAccountBalance'
    date: any
    balance: { __typename?: 'Money'; value: string }
  }> | null
}

export const AccountBalanceChartQuery = gql`
  query AccountBalanceChartQuery(
    $range: DateRange!
    $timeZone: String!
    $entityUuid: ID!
  ) {
    getDebitCardAccountBalances(
      range: $range
      timeZone: $timeZone
      entityUuid: $entityUuid
    ) {
      ...AccountBalanceChartViewDebitCardAccountBalanceFragment
    }
  }
  ${AccountBalanceChartViewDebitCardAccountBalanceFragmentDoc}
` as unknown as TypedDocumentNode<
  AccountBalanceChartQueryResponse,
  AccountBalanceChartQueryVariables
>

/**
 * __useAccountBalanceChartQuery__
 *
 * To run a query within a React component, call `useAccountBalanceChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBalanceChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBalanceChartQuery({
 *   variables: {
 *      range: // value for 'range'
 *      timeZone: // value for 'timeZone'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useAccountBalanceChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountBalanceChartQueryResponse,
    AccountBalanceChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AccountBalanceChartQueryResponse,
    AccountBalanceChartQueryVariables
  >(AccountBalanceChartQuery, options)
}
export function useAccountBalanceChartQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountBalanceChartQueryResponse,
    AccountBalanceChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountBalanceChartQueryResponse,
    AccountBalanceChartQueryVariables
  >(AccountBalanceChartQuery, options)
}
export type AccountBalanceChartQueryHookResult = ReturnType<
  typeof useAccountBalanceChartQuery
>
export type AccountBalanceChartQueryLazyQueryHookResult = ReturnType<
  typeof useAccountBalanceChartQueryLazyQuery
>
export type AccountBalanceChartQueryQueryResult = Apollo.QueryResult<
  AccountBalanceChartQueryResponse,
  AccountBalanceChartQueryVariables
>
