import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'
import { Notification, StyledText } from 'components/Notification'

export const StyledSimBillFiltersWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: flex-start;
  padding-bottom: 2rem;

  @media (min-width: ${BREAKPOINT.XS}px) {
    flex-flow: row;
  }
`
export const StyledWarningNotification = styled(Notification)`
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.RED_80};

  ${StyledText} {
    font-size: 14px;
  }

  div {
    align-self: center;
  }
`
