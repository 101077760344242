import { AnchorBasic, ButtonFill, Flex } from '@npco/zeller-design-system'
import { FormikErrors, FormikHandlers } from 'formik'

import { translate } from 'utils/translations'

import {
  Checkbox,
  CheckboxContainer,
  CheckboxMessage,
  ErrorMessage,
} from './Form.styled'
import { XeroConnectionAgreementFormWrapper } from './XeroConnectionAgreementForm.styled'

interface Values {
  agreed: boolean
}
interface XeroConnectionAgreementFormProps {
  handleSubmit: FormikHandlers['handleSubmit']
  handleChange: FormikHandlers['handleChange']
  handleBlur: FormikHandlers['handleBlur']
  errors: FormikErrors<Values>
}

const inputId = 'have-read'

export const XeroConnectionAgreementForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
}: XeroConnectionAgreementFormProps) => {
  return (
    <XeroConnectionAgreementFormWrapper onSubmit={handleSubmit}>
      <Flex flexDirection="column">
        <CheckboxContainer htmlFor={inputId}>
          <Checkbox
            name="agreed"
            id={inputId}
            type="checkbox"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <CheckboxMessage>
            {translate(
              'page.settings.connections.xeroBankFeeds.initialiseConnection.consentCheckbox.lineOne'
            )}{' '}
            <AnchorBasic href="https://www.myzeller.com/terms-xero">
              {translate(
                'page.settings.connections.xeroBankFeeds.initialiseConnection.consentCheckbox.lineTwo'
              )}
            </AnchorBasic>
          </CheckboxMessage>
        </CheckboxContainer>
        {errors.agreed && <ErrorMessage>{errors.agreed}</ErrorMessage>}
      </Flex>

      <ButtonFill fullWidth type="submit">
        {translate('page.settings.connections.xeroBankFeeds.cta.connect')}
      </ButtonFill>
    </XeroConnectionAgreementFormWrapper>
  )
}
