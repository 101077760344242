import { validateMaxLengthAndCharsBecs } from '@npco/component-mp-common'

import {
  combineValidators,
  validateMaxLength,
  validateRequired,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { errorMessages } from 'translations'

export const validateBECSReferenceField = (maxLength: number) =>
  combineValidators(
    validateRequired,
    validateMaxLengthAndCharsBecs({
      maxLength,
      errorMsgSpecialChars: errorMessages.invalidCharsBecs,
    }),
    validateMaxLength(
      maxLength,
      translate('errorMessages.referenceLength', {
        referenceLength: maxLength,
      })
    )
  )
