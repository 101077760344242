import { useReportLostAndReplaceCardState } from './hooks/useReportLostAndReplaceCardState'
import { ReportLostAndReplaceCardAddressPrompt } from './ReportLostAndReplaceCardAddressPrompt/ReportLostAndReplaceCardAddressPrompt'
import { ReportLostAndReplaceCardErrorPrompt } from './ReportLostAndReplaceCardErrorPrompt/ReportLostAndReplaceCardErrorPrompt'
import { ReportLostAndReplaceCardPrompt } from './ReportLostAndReplaceCardPrompt/ReportLostAndReplaceCardPrompt'

export const ReportLostAndReplaceCardModal = () => {
  const { state } = useReportLostAndReplaceCardState()

  if (state === null) {
    return null
  }

  return (
    <>
      <ReportLostAndReplaceCardPrompt />
      <ReportLostAndReplaceCardAddressPrompt />
      <ReportLostAndReplaceCardErrorPrompt />
    </>
  )
}
