import { useEffect } from 'react'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { rvSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSelectedDepositsSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactionsDeposits'
import { Formik } from 'formik'

import { datePickerInitialState } from 'const/common'
import { ROOT } from 'const/routes'
import { StatementPeriod } from 'types/dates'
import { StatementsFormValues } from 'types/utils'
import { DepositsCustomRange } from 'pages/Deposits/DepositsStatements/DepositsCustomRange/DepositsCustomRange'
import { useGetFont } from 'pages/Deposits/DepositsStatements/hooks/useGetFont'
import { DepositsActionButton } from 'pages/Deposits/DepositsStatements/StatementsButtons/DepositsActionButton'
import { SpinnerWrapped } from 'components/Spinner'
import { useGetSitesWithTypes } from 'components/Statements/hooks/useGetSitesWithTypes'
import { useGetUIData } from 'components/Statements/hooks/useGetUIData'
import { Statement } from 'components/Statements/Statement/Statement'
import {
  StyledStatementsHeader,
  StyledStatementsWrapper,
} from 'components/Statements/Statements.styled'
import { ALL_SITES } from 'components/Statements/Statements.utils'
import { StatementsSitesField } from 'components/Statements/StatementsSitesField/StatementSitesField'
import { page, shared } from 'translations'

export const DepositsStatements = () => {
  const initialValues = {
    sites: ALL_SITES,
  }

  const shortEntityId = useSelectedShortEntityUuid()

  const { isLoading } = useGetSitesWithTypes()

  const {
    previousQuarterName,
    previousQuarterDate,
    currentQuarterName,
    currentQuarterDate,
    currentMonthDate,
    previousMonthDate,
  } = useGetUIData()

  useGetFont()

  useEffect(() => {
    rvSelectedDates(datePickerInitialState)
  }, [])

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Link
                  to={ROOT.ORGS.ORG(shortEntityId).PAYMENTS.DEPOSITS.path}
                >
                  {page.title.deposits}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{page.title.statements}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>
        {isLoading && (
          <StyledStatementsWrapper>
            <SpinnerWrapped variant="centre" />
          </StyledStatementsWrapper>
        )}
        {!isLoading && (
          <Formik<StatementsFormValues>
            initialValues={initialValues}
            onSubmit={() => undefined}
          >
            {() => (
              <>
                <StatementsSitesField
                  reactiveVariable={rvSelectedDepositsSite}
                />
                <StyledStatementsHeader>
                  {page.settings.statements.downloadStatement}
                </StyledStatementsHeader>
                <Statement
                  title={previousQuarterName}
                  description={previousQuarterDate}
                  actionButton={
                    <DepositsActionButton
                      statementPeriod={StatementPeriod.PreviousQuarter}
                    />
                  }
                />
                <Statement
                  title={currentQuarterName}
                  description={currentQuarterDate}
                  actionButton={
                    <DepositsActionButton
                      statementPeriod={StatementPeriod.CurrentQuarter}
                    />
                  }
                />
                <Statement
                  title={shared.currentMonth}
                  description={currentMonthDate}
                  actionButton={
                    <DepositsActionButton
                      statementPeriod={StatementPeriod.CurrentMonth}
                    />
                  }
                />
                <Statement
                  title={shared.previousMonth}
                  description={previousMonthDate}
                  actionButton={
                    <DepositsActionButton
                      statementPeriod={StatementPeriod.PreviousMonth}
                    />
                  }
                />
                <DepositsCustomRange />
              </>
            )}
          </Formik>
        )}
      </PageTemplate.Section>
    </PageTemplate>
  )
}
