import { Flex, Message } from '@npco/zeller-design-system'

import { ReactComponent as LockAndKey } from 'assets/svg/lock-and-key.svg'
import { translate } from 'utils/translations'

export const AccountsAccessDenied = () => {
  return (
    <Flex
      role="status"
      height="100%"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      <Message
        image={<LockAndKey />}
        title={translate(
          'page.accounts.errorState.permissionNoAccessAsManager.title'
        )}
        description={translate(
          'page.accounts.errorState.permissionNoAccessAsManager.description'
        )}
      />
    </Flex>
  )
}
