import { useAccountFiltersList } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'
import { FiltersList } from 'components/FiltersList/FiltersList'

const defaultFilters = { selectedAccountID: undefined }

export const AccountsLayoutTertiaryHeaderRowDeps = {
  useAccountFiltersList,
}

export type AccountsLayoutTertiaryHeaderRowProps = {
  isAllTransactionsRoute: boolean
  isFiltersVisible: boolean
}

export const AccountsLayoutTertiaryHeaderRow = ({
  isAllTransactionsRoute,
  isFiltersVisible,
}: AccountsLayoutTertiaryHeaderRowProps) => {
  const { useAccountFiltersList } = AccountsLayoutTertiaryHeaderRowDeps

  const { selectItems, visibleFilters, onSelect } = useAccountFiltersList({
    defaultFilters,
    skip: !isFiltersVisible,
  })

  return (
    <FiltersHeaderRow isOpen={isFiltersVisible && isAllTransactionsRoute}>
      <FiltersList
        defaultFilters={defaultFilters}
        moreSelectItems={selectItems}
        onSelect={onSelect}
        visibleFilters={visibleFilters}
      />
    </FiltersHeaderRow>
  )
}
