import { gql } from '@apollo/client'

export const CreateSite = gql`
  mutation CreateSite($entityUuid: ID!, $input: CreateSiteInput!) {
    createSite(entityUuid: $entityUuid, input: $input) {
      id
    }
  }
`

export const UpdateSite = gql`
  mutation UpdateSite($entityUuid: ID!, $input: UpdateSiteInput!) {
    updateSite(entityUuid: $entityUuid, input: $input)
  }
`

export const AssignDeviceToSite = gql`
  mutation AssignDeviceToSite(
    $entityUuid: ID!
    $deviceUuid: ID!
    $siteUuid: ID!
  ) {
    assignDeviceToSite(
      entityUuid: $entityUuid
      deviceUuid: $deviceUuid
      siteUuid: $siteUuid
    )
  }
`

export const RemoveSiteLogo = gql`
  mutation RemoveSiteLogo($entityUuid: ID!, $siteUuid: ID!) {
    removeSiteLogo(entityUuid: $entityUuid, siteUuid: $siteUuid)
  }
`

export const UpdateDeviceSettings = gql`
  mutation UpdateDeviceSettings(
    $entityUuid: ID!
    $deviceSettings: UpdateDeviceSettingsInput!
  ) {
    updateDeviceSettings(
      entityUuid: $entityUuid
      deviceSettings: $deviceSettings
    )
  }
`
