import { createTranslations } from '@npco/utils-translations'

export const userRemoveModalTranslation = createTranslations({
  title: 'Remove {name}?',
  description:
    'This action will permanently remove {name} from your organisation, preventing them from accessing any Sites, and closing any associated cards immediately.',
  emptySiteAndCardDescription:
    'Are you sure you would like to remove {name} from your organisation? This cannot be undone. ',
  associatedSites: 'Associated Sites',
  associatedCards: 'Associated Cards',
  cancelButton: 'Cancel',
  removeButton: 'Remove User',
  showAll: 'Show All',
  showLess: 'Show Less',
})
