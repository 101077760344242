import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Delete {contactType}',
  confirmationMessage:
    'Are you sure you want to delete this {contactType} from your Contacts? This action cannot be undone.',
  confirmationMessageLinkedInvoices:
    'This {contactType} will be removed from {draftNumber} draft invoices, are you sure you want to delete?',
  confirmationMessageLinkedInvoicesSingular:
    'This {contactType} will be removed from 1 draft invoice, are you sure you want to delete?',
})
