import { BodySmallTypography, Box } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { SkeletonBodyDefault } from 'components/SkeletonLoader/SkeletonLoaderTypography'

export const DescriptionSkeleton = styled(SkeletonBodyDefault)`
  display: block;
  width: 100%;
  margin-bottom: 0;
  margin-top: 20px;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 422px;
  }
`
export const DescriptionNextLineSkeleton = styled(SkeletonBodyDefault)`
  width: 100%;
  display: block;
  margin: 0;
  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.MD -
    1}px) {
    display: none;
  }
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 292px;
  }
`

export const TableSkeletonWrapper = styled.div`
  display: block;
  margin-top: 20px;
  padding: 0;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 422px;
  }
`

export const ZellerPricingLinkSkeleton = styled(SkeletonBodyDefault)`
  display: block;
  margin-top: 20px;
  width: 212px;
`

export const PaymentTable = styled.div`
  display: grid;
  grid-template-columns: auto 64px;
  border: solid 1px ${({ theme }) => theme.colors.GREY_250};
  border-radius: 10px;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 422px;
  }
  .leftCell {
    border-right: solid 1px ${({ theme }) => theme.colors.GREY_250};
  }
  .topCell {
    border-bottom: solid 1px ${({ theme }) => theme.colors.GREY_250};
  }
  .rightCell {
    text-align: center;
  }
`

export const PaymentTableCell = styled(Box)`
  padding: 14px 16px;
  height: 48px;
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodySmallTypography}
`
