import { ReactElement, ReactNode, useCallback, useMemo } from 'react'

import { InfiniteList } from 'components/Lists/InfiniteList/InfiniteList'

export interface ListProps<T> {
  data: T[][]
  onScrollEnd?: () => void
  renderItem: (data: T) => ReactElement
  renderGroup: (
    items: T[],
    elements: ReactElement[],
    index: number,
    data: T[][]
  ) => ReactElement
  loader?: ReactElement
  header?: ReactElement | null
  footer?: ReactElement | null
  hasMore?: boolean
  className?: string
  numberOfElements?: number
  children?: ReactNode
}

export const GroupedList = <T,>({
  data,
  onScrollEnd,
  renderItem,
  renderGroup,
  loader,
  header,
  footer,
  hasMore = false,
  className,
  numberOfElements: propsNumberOfElements,
  children,
}: ListProps<T>) => {
  const numberOfElements = useMemo(
    () =>
      propsNumberOfElements ??
      data.reduce((acc, elements) => acc + elements.length, 0),
    [data, propsNumberOfElements]
  )

  const renderGroupItem = useCallback(
    (items: T[], index: number) => {
      return renderGroup(
        items,
        items.map((listItemsData) => renderItem(listItemsData)),
        index,
        data
      )
    },
    [renderGroup, renderItem, data]
  )

  return (
    <InfiniteList
      data={data}
      dataLength={numberOfElements}
      onScrollEnd={onScrollEnd}
      hasMore={hasMore}
      className={className}
      renderItem={renderGroupItem}
      loader={loader}
      header={header}
      footer={footer}
    >
      {children}
    </InfiniteList>
  )
}
