import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, Flex } from '@npco/zeller-design-system'

import { ShowMore } from 'components/ShowMore'

import { AccountBalances } from './AccountBalances'
import { ExpandableAccountBalancesDebitCardAccountV2Fragment } from './ExpandableAccountBalances.generated'
import { translations } from './ExpandableAccountBalances.i18n'

interface ExpandableAccountBalancesProps {
  accounts: ExpandableAccountBalancesDebitCardAccountV2Fragment[]
}

const INITIAL_NUMBER_OF_ACCOUNTS = 3

export const ExpandableAccountBalances = ({
  accounts,
}: ExpandableAccountBalancesProps) => {
  const t = useTranslations(translations)

  const numberOfOverflowAccounts = accounts.length - INITIAL_NUMBER_OF_ACCOUNTS

  const canExpandAccounts = numberOfOverflowAccounts > 0

  return (
    <ShowMore>
      <Flex
        gridGap="4px"
        flexDirection="column"
        marginBottom={{ _: '4px', SM: '16px' }}
        data-testid="account-balance-content"
      >
        <AccountBalances
          accounts={accounts.slice(0, INITIAL_NUMBER_OF_ACCOUNTS)}
        />
        <ShowMore.TogglableContent>
          <AccountBalances
            accounts={accounts.slice(INITIAL_NUMBER_OF_ACCOUNTS)}
          />
        </ShowMore.TogglableContent>
      </Flex>

      {canExpandAccounts && (
        <Box ml="12px">
          <ShowMore.Toggle
            showText={t('showMore', {
              numberOfAccounts: numberOfOverflowAccounts,
            })}
            hideText={t('showLess')}
            dataTestId="show-more-accounts-button"
          />
        </Box>
      )}
    </ShowMore>
  )
}

ExpandableAccountBalances.fragments = {
  DebitCardAccountV2: gql`
    fragment ExpandableAccountBalancesDebitCardAccountV2Fragment on DebitCardAccountV2 {
      ...AccountBalancesDebitCardAccountV2Fragment
    }

    ${AccountBalances.fragments.DebitCardAccountV2}
  `,
}
