import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const UpdateInvoice = gql`
  ${InvoiceCoreFields}
  mutation UpdateInvoice($entityUuid: ID!, $input: UpdateInvoiceInput!) {
    updateInvoice(entityUuid: $entityUuid, input: $input) {
      ...InvoiceCoreFields
    }
  }
`
