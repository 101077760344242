import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetCustomisationInvoicePreviewQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  invoice: Types.CustomisationPreviewInvoiceInput
}>

export type GetCustomisationInvoicePreviewQueryResponse = {
  __typename?: 'Query'
  getCustomisationInvoicePreview: {
    __typename?: 'InvoicePdfDownload'
    downloadLink: string
    expire: any
  }
}

export const GetCustomisationInvoicePreview = gql`
  query GetCustomisationInvoicePreview(
    $entityUuid: ID!
    $invoice: CustomisationPreviewInvoiceInput!
  ) {
    getCustomisationInvoicePreview(entityUuid: $entityUuid, input: $invoice) {
      downloadLink
      expire
    }
  }
` as unknown as TypedDocumentNode<
  GetCustomisationInvoicePreviewQueryResponse,
  GetCustomisationInvoicePreviewQueryVariables
>

/**
 * __useGetCustomisationInvoicePreviewQuery__
 *
 * To run a query within a React component, call `useGetCustomisationInvoicePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomisationInvoicePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomisationInvoicePreviewQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      invoice: // value for 'invoice'
 *   },
 * });
 */
export function useGetCustomisationInvoicePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomisationInvoicePreviewQueryResponse,
    GetCustomisationInvoicePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCustomisationInvoicePreviewQueryResponse,
    GetCustomisationInvoicePreviewQueryVariables
  >(GetCustomisationInvoicePreview, options)
}
export function useGetCustomisationInvoicePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomisationInvoicePreviewQueryResponse,
    GetCustomisationInvoicePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomisationInvoicePreviewQueryResponse,
    GetCustomisationInvoicePreviewQueryVariables
  >(GetCustomisationInvoicePreview, options)
}
export type GetCustomisationInvoicePreviewQueryHookResult = ReturnType<
  typeof useGetCustomisationInvoicePreviewQuery
>
export type GetCustomisationInvoicePreviewLazyQueryHookResult = ReturnType<
  typeof useGetCustomisationInvoicePreviewLazyQuery
>
export type GetCustomisationInvoicePreviewQueryResult = Apollo.QueryResult<
  GetCustomisationInvoicePreviewQueryResponse,
  GetCustomisationInvoicePreviewQueryVariables
>
