import { useCallback } from 'react'
import { AUSTRALIA_COUNTRY_CODE } from '@npco/component-mp-common'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  Address,
  CreateSelectDeliveryAddressState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

export const useSelectDeliveryAddressForm = () => {
  const { state } = useAddCardState<CreateSelectDeliveryAddressState>()
  const { goToAddCardStage } = useGoToAddCardStage()

  const submitAddressPage = useCallback(
    (address: Address) => {
      if (state === null) {
        ErrorLogger.report(
          '[Banking] CreateSelectDeliveryAddressStage submitting form with null state'
        )
        showErrorToast()
        return
      }

      goToAddCardStage({
        stage: AddCardStage.CreateReviewCardStage,
        cardType: state.cardType,
        cardholder: state.cardholder,
        account: state.account,
        cardFormat: state.cardFormat,
        nickname: state.nickname,
        usePin: state.usePin,
        colour: state.colour,
        address,
      })
    },
    [goToAddCardStage, state]
  )

  const goBackToCardDesignStage = useCallback(() => {
    if (state === null) {
      ErrorLogger.report(
        '[Banking] CreateSelectDeliveryAddressStage going back to card design stage with null state'
      )
      showErrorToast()
      return
    }

    goToAddCardStage({
      stage: AddCardStage.CreateSelectCardDesignStage,
      cardType: state.cardType,
      cardholder: state.cardholder,
      account: state.account,
      colour: state.colour,
      cardFormat: state.cardFormat,
      nickname: state.nickname,
      usePin: state.usePin,
    })
  }, [goToAddCardStage, state])

  const submitCustomAddressPage = useCallback(
    (address: Omit<Address, 'country'>) => {
      submitAddressPage({
        ...address,
        country: AUSTRALIA_COUNTRY_CODE,
      })
    },
    [submitAddressPage]
  )

  return {
    submitCustomAddressPage,
    submitPredefinedAddressPage: submitAddressPage,
    goBackToCardDesignStage,
    initialAddress: state?.address,
  }
}
