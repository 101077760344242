import { useMutation } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { DeleteCustomer } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'
import { rvSelectedUser } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { updateCachedCard } from 'features/utils-cards-cache'

import {
  DeleteCustomer as DeleteCustomerResponse,
  DeleteCustomerVariables,
} from 'types/gql-types/DeleteCustomer'
import { page } from 'translations'

import { useCustomerUtils } from '../useCustomerUtils'

export const useDeleteCustomer = ({
  redirect,
  cardIds = [],
}: {
  redirect: () => void
  cardIds?: string[]
}) => {
  const entityUuid = useSelectedEntityUuid()
  const { deleteCustomerFromStorage } = useCustomerUtils()
  const [deleteCustomer, { loading: loadingDeleteCustomer }] = useMutation<
    DeleteCustomerResponse,
    DeleteCustomerVariables
  >(DeleteCustomer, {
    variables: {
      entityUuid,
      customerUuid: rvSelectedUser(),
    },
    onCompleted: () => {
      deleteCustomerFromStorage(rvSelectedUser())
      redirect()
      showSuccessToast(page.settings.users.successDeleteUser)
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
    update: (cache, { data }) => {
      if (data?.deleteCustomer) {
        cardIds.forEach((cardId) =>
          updateCachedCard({
            cache,
            cardUpdate: {
              id: cardId,
              status: DebitCardStatus.CLOSED,
            },
          })
        )
      }
    },
  })

  return {
    deleteCustomer,
    loadingDeleteCustomer,
  }
}
