import { useTranslations } from '@npco/utils-translations'
import parseNumber from 'libphonenumber-js/max'

import { useFormValidationTranslations } from './useFormValidation.i18n'

export type CustomValidator<T, U = undefined> = (
  value: T,
  secondValue?: U
) => string | undefined

export const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z-]{2,63}$/g

const getIsEmailValid = (value: string): boolean =>
  new RegExp(VALID_EMAIL_REGEX).test(value)

export const AUSTRALIA_COUNTRY_CODE = 'AU'

export const useFormValidation = () => {
  const t = useTranslations(useFormValidationTranslations)

  const validateSMS = (value: string): string | undefined => {
    const getIsMobileNumberValid = (): boolean => {
      const phoneNumber = parseNumber(String(value), {
        defaultCountry: AUSTRALIA_COUNTRY_CODE,
        extract: false,
      })

      if (phoneNumber) {
        const isMobile = phoneNumber.getType() === 'MOBILE'
        const isValid = phoneNumber.isValid()
        return isMobile && isValid
      }

      return false
    }

    if (!value) {
      return t('mobileNumberRequired')
    }

    const isMobileNumberValid = getIsMobileNumberValid()

    if (!isMobileNumberValid) {
      return t('invalidMobileNumber')
    }

    return undefined
  }

  const validateEmail = (value: string): string | undefined => {
    if (!value) {
      return t('emailRequired')
    }

    if (!getIsEmailValid(value)) {
      return t('invalidEmail')
    }

    return undefined
  }

  return { validateSMS, validateEmail }
}
