export const ignoreErrors = [
  'Subscription error',
  'subscriptionFailedCallback',
  'ResizeObserver loop',
  'TypeError: Failed to fetch', // Google trackers throwing errors time to time.
  'ApolloError',
  // Any backend error (including BE not available) is being handled with the global errorLink that's in place.
  // these apollo errors are confirmed to occure due to user actions, such as rapid navigation, network errors
  'TypeError: Importing a module script failed', // Content blockers and some userscripts can block dyanmic import
  'TypeError: Load failed', // Content blockers and some userscripts can block dyanmic import
  'TypeError: error loading dynamically imported module', // Content blockers and some userscripts can block dyanmic import
  'TypeError: cancelled', // Segment analytics throwing errors due to network issues or adblockers
  "Cannot read properties of null (reading 'error')", // aws realtime-subscription-handshake-link.ts is not handling errors properly
  "null is not an object (evaluating 'qs.error')", // aws realtime-subscription-handshake-link.ts is not handling errors properly
  'https://a-us.storyblok.com/', // Storyblock cdn not giving error on quick navigation away from overview page
  'TypeError: rl is undefined', // Firefox specific issue. Tested and confirmed users are not affected by this
  'AbortError: The user aborted a request',
]
