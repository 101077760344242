import { COLOR, Flex, TickIcon } from '@npco/zeller-design-system'

import { StyledLabel } from './BenefitItem.styled'

interface BenefitItemProps {
  title: string
}
export const BenefitItem = ({ title }: BenefitItemProps) => {
  return (
    <Flex gridGap="14px">
      <TickIcon width="14" height="14" color={COLOR.GREEN_1600} />
      <StyledLabel>{title}</StyledLabel>
    </Flex>
  )
}
