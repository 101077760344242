import { SkeletonAvatar } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const LoadingAvatar = styled(SkeletonAvatar)`
  width: 40px;
  height: 40px;
`

export const LoadingContainer = styled.div`
  margin: 0 -12px;
`
