import {
  PerformancePeriod,
  TransactionTotal,
} from '../../DashboardPerformance.types'
import { ThisMonthPerformanceChart } from './ThisMonthPerformanceChart'
import { TodayPerformanceChart } from './TodayPerformanceChart'

interface Props {
  performancePeriod: PerformancePeriod
  performanceData: TransactionTotal[]
  disabled: boolean
  isLoading: boolean
}

export const PerformanceChart = ({
  performancePeriod,
  performanceData,
  disabled,
  isLoading,
}: Props) => {
  if (performancePeriod === PerformancePeriod.THIS_MONTH) {
    return (
      <ThisMonthPerformanceChart
        salesTotals={performanceData}
        disabled={disabled}
        isLoading={isLoading}
      />
    )
  }

  return (
    <TodayPerformanceChart
      salesTotals={performanceData}
      disabled={disabled}
      isLoading={isLoading}
    />
  )
}
