import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { GetInvoicesSortInput, InvoiceStatus } from '@npco/mp-gql-types'
import { theme } from '@npco/zeller-design-system'
import {
  AllInvoiceTableColumn,
  AmountColumnEnum,
  DateColumnEnum,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'

import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'
import { RangePickerValue } from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import { page } from 'translations'

export const DEFAULT_GET_LIMIT = 50

export const InvoiceTableDefaultAmount: RangePickerValue = [0, 50000]
export const AllStatusesSelected: InvoiceStatus[] = [
  InvoiceStatus.SEND_FAILED,
  InvoiceStatus.CANCELLED,
  InvoiceStatus.DRAFT,
  InvoiceStatus.OVERDUE,
  InvoiceStatus.PAID,
  InvoiceStatus.PART_PAID,
  InvoiceStatus.SCHEDULED,
  InvoiceStatus.SENT,
]

export const StatusColor: { [key in InvoiceStatus]?: string } = {
  [InvoiceStatus.ACTIVE]: theme.colors.GREY_550,
  [InvoiceStatus.CANCELLED]: theme.colors.GREY_550,
  [InvoiceStatus.DELETED]: theme.colors.RED_1000,
  [InvoiceStatus.DRAFT]: theme.colors.GREY_550,
  [InvoiceStatus.ENDED]: theme.colors.RED_1000,
  [InvoiceStatus.OVERDUE]: theme.colors.RED_1000,
  [InvoiceStatus.PAID]: theme.colors.GREEN_1600,
  [InvoiceStatus.PART_PAID]: theme.colors.BLACK_900,
  [InvoiceStatus.SCHEDULED]: theme.colors.BLACK_900,
  [InvoiceStatus.SEND_FAILED]: theme.colors.RED_1000,
  [InvoiceStatus.SENT]: theme.colors.BLACK_900,
}

export const DateLabel: { [key in DateColumnEnum]: string } = {
  [DateColumnEnum.NextDue]: page.invoiceTable.dateColumn.nextDue,
  [DateColumnEnum.Created]: page.invoiceTable.dateColumn.created,
  [DateColumnEnum.Issued]: page.invoiceTable.dateColumn.issued,
  [DateColumnEnum.Sent]: page.invoiceTable.dateColumn.sent,
  [DateColumnEnum.DatePaid]: page.invoiceTable.dateColumn.paid,
}

export const AmountLabel: { [key in AmountColumnEnum]: string } = {
  [AmountColumnEnum.AmountPaid]: page.invoiceTable.amountColumn.paid,
  [AmountColumnEnum.Outstanding]: page.invoiceTable.amountColumn.outstanding,
  [AmountColumnEnum.Total]: page.invoiceTable.amountColumn.total,
}

export const StatusLabel: { [key in InvoiceStatus]?: string } = {
  [InvoiceStatus.ACTIVE]: page.invoiceTable.status.ACTIVE,
  [InvoiceStatus.CANCELLED]: page.invoiceTable.status.CANCELLED,
  [InvoiceStatus.DELETED]: page.invoiceTable.status.DELETED,
  [InvoiceStatus.DRAFT]: page.invoiceTable.status.DRAFT,
  [InvoiceStatus.ENDED]: page.invoiceTable.status.ENDED,
  [InvoiceStatus.OVERDUE]: page.invoiceTable.status.OVERDUE,
  [InvoiceStatus.PAID]: page.invoiceTable.status.PAID,
  [InvoiceStatus.PART_PAID]: page.invoiceTable.status.PART_PAID,
  [InvoiceStatus.SCHEDULED]: page.invoiceTable.status.SCHEDULED,
  [InvoiceStatus.SEND_FAILED]: page.invoiceTable.status.SEND_FAILED,
  [InvoiceStatus.SENT]: page.invoiceTable.status.SENT,
}

export const PartialFiltersStatusLabel: { [key in InvoiceStatus]?: string } = {
  [InvoiceStatus.CANCELLED]: page.invoiceTable.status.CANCELLED,
  [InvoiceStatus.DRAFT]: page.invoiceTable.status.DRAFT,
  [InvoiceStatus.SEND_FAILED]: page.invoiceTable.status.SEND_FAILED,
  [InvoiceStatus.OVERDUE]: page.invoiceTable.status.OVERDUE,
  [InvoiceStatus.PAID]: page.invoiceTable.status.PAID,
  [InvoiceStatus.PART_PAID]: page.invoiceTable.status.PART_PAID,
  [InvoiceStatus.SCHEDULED]: page.invoiceTable.status.SCHEDULED,
  [InvoiceStatus.SENT]: page.invoiceTable.status.SENT,
}

export const TableColumnMaxWidth = {
  [AllInvoiceTableColumn.Contact]: 220,
  [AllInvoiceTableColumn.Title]: 320,
  [AllInvoiceTableColumn.ID]: 108,
  [AllInvoiceTableColumn.Status]: 120,
  [AllInvoiceTableColumn.DatePaid]: 120,
  [AllInvoiceTableColumn.Created]: 120,
  [AllInvoiceTableColumn.Issued]: 120,
  [AllInvoiceTableColumn.Sent]: 120,
  [AllInvoiceTableColumn.NextDue]: 120,
  [AllInvoiceTableColumn.AmountPaid]: 120,
  [AllInvoiceTableColumn.Outstanding]: 120,
  [AllInvoiceTableColumn.Total]: 120,
}

export const TableColumnPopperWidth = {
  [AllInvoiceTableColumn.Contact]: '160px',
  [AllInvoiceTableColumn.Title]: '160px',
  [AllInvoiceTableColumn.ID]: '208px',
  [AllInvoiceTableColumn.Status]: '235px',
  [AllInvoiceTableColumn.DatePaid]: '352px',
  [AllInvoiceTableColumn.Created]: '352px',
  [AllInvoiceTableColumn.Issued]: '352px',
  [AllInvoiceTableColumn.Sent]: '352px',
  [AllInvoiceTableColumn.NextDue]: '352px',
  [AllInvoiceTableColumn.AmountPaid]: '320px',
  [AllInvoiceTableColumn.Outstanding]: '320px',
  [AllInvoiceTableColumn.Total]: '320px',
  [AllInvoiceTableColumn.Default]: '160px',
}

export const TableHeaders: Array<{
  column: string
  label: string
  maxWidth: number | undefined
}> = [
  {
    column: AllInvoiceTableColumn.ID,
    label: translate('component.invoiceTable.id'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.ID],
  },
  {
    column: AllInvoiceTableColumn.Contact,
    label: translate('component.invoiceTable.contact'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Contact],
  },
  {
    column: AllInvoiceTableColumn.Title,
    label: translate('component.invoiceTable.title'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Title],
  },
  {
    column: AllInvoiceTableColumn.Status,
    label: translate('component.invoiceTable.status'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Status],
  },
  {
    column: AllInvoiceTableColumn.NextDue,
    label: translate('page.invoiceTable.dateColumn.nextDue'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.NextDue],
  },
  {
    column: AllInvoiceTableColumn.NextDue,
    label: translate('page.invoiceTable.dateColumn.issued'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Issued],
  },
  {
    column: AllInvoiceTableColumn.NextDue,
    label: translate('page.invoiceTable.dateColumn.created'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Created],
  },
  {
    column: AllInvoiceTableColumn.NextDue,
    label: translate('page.invoiceTable.dateColumn.sent'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Sent],
  },
  {
    column: AllInvoiceTableColumn.NextDue,
    label: translate('page.invoiceTable.dateColumn.paid'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.DatePaid],
  },
  {
    column: AllInvoiceTableColumn.AmountPaid,
    label: translate('page.invoiceTable.amountColumn.paid'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.AmountPaid],
  },
  {
    column: AllInvoiceTableColumn.Outstanding,
    label: translate('page.invoiceTable.amountColumn.outstanding'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Outstanding],
  },
  {
    column: AllInvoiceTableColumn.Total,
    label: translate('page.invoiceTable.amountColumn.total'),
    maxWidth: TableColumnMaxWidth[AllInvoiceTableColumn.Total],
  },
]

export const isAscendingSelected = (
  sortingObject: GetInvoicesSortInput | null,
  columnName: string
): boolean => {
  return Boolean(
    sortingObject?.columnName === columnName && sortingObject?.ascending
  )
}

export const isDescendingSelected = (
  sortingObject: GetInvoicesSortInput | null,
  columnName: string
): boolean => {
  return Boolean(
    sortingObject?.columnName === columnName && !sortingObject?.ascending
  )
}

export const getFormattedDate = (value?: string | number) => {
  if (!value) {
    return '-'
  }
  return typeof value === 'number'
    ? dayjs.unix(value).format(DATE_FULL_DISPLAY_FORMAT)
    : dayjs(new Date(value)).format(DATE_FULL_DISPLAY_FORMAT)
}
