import { useCallback } from 'react'
import {
  BUTTON_SIZE,
  ButtonGhostIconOnly,
  ErrorMessageForm,
  HelperText,
  InputSelectComboboxItemBasic,
  InputSelectComboboxStandard,
  InputSelectComboboxStandardItemAddNew,
  InputSelectComboboxStandardItemWrapper,
  SelectInputComboboxBasicProps,
} from '@npco/zeller-design-system'

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'
import { CustomValidator } from 'types/common'
import { component } from 'translations'

import { StyledIconWrapper, StyledItemContainer } from './EditTagsModal.styled'

interface EditTagsInputSelectComboboxProps
  extends Pick<
    SelectInputComboboxBasicProps<InputSelectComboboxItemBasic>,
    'renderInput' | 'onInputChange' | 'onChange'
  > {
  errorMessage?: string
  hasError: boolean
  helperText?: string
  inputValue: string
  items: InputSelectComboboxItemBasic[]
  onDeleteItemClick: (tag: string) => void
  onInputClear: () => void
  shouldRenderNewItem: boolean
  validate: CustomValidator<string, string[]>
}

export const EditTagsInputSelectCombobox = ({
  errorMessage,
  hasError,
  helperText,
  inputValue,
  items,
  onChange,
  onDeleteItemClick,
  onInputChange,
  onInputClear,
  renderInput,
  shouldRenderNewItem,
  validate,
}: EditTagsInputSelectComboboxProps) => {
  const getFilterItems = useCallback(
    ({ label }: InputSelectComboboxItemBasic) => {
      return label.toUpperCase().includes(inputValue.trim().toUpperCase())
    },
    [inputValue]
  )
  return (
    <>
      <InputSelectComboboxStandard
        inputValue={inputValue}
        renderNewItem={
          shouldRenderNewItem
            ? ({ onClick, closeMenu, ...renderProps }) => (
                <InputSelectComboboxStandardItemAddNew
                  {...renderProps}
                  onClick={(e) => {
                    onClick(e)
                    if (validate(inputValue)) {
                      closeMenu()
                    }
                  }}
                >
                  {`${component.labelSelectCombobox.create} "${inputValue}"`}
                </InputSelectComboboxStandardItemAddNew>
              )
            : undefined
        }
        renderInput={renderInput}
        renderItem={({ closeMenu, ...renderProps }) => (
          <StyledItemContainer
            data-testid="match-tag-item"
            key={renderProps.key}
          >
            <InputSelectComboboxStandardItemWrapper {...renderProps}>
              {renderProps.item.label}
              <StyledIconWrapper>
                <ButtonGhostIconOnly
                  icon={CloseIcon}
                  size={BUTTON_SIZE.SMALL}
                  onClick={(e) => {
                    e.stopPropagation()
                    closeMenu()
                    onDeleteItemClick(renderProps.item.label)
                  }}
                />
              </StyledIconWrapper>
            </InputSelectComboboxStandardItemWrapper>
          </StyledItemContainer>
        )}
        filterItems={getFilterItems}
        items={items}
        onInputChange={onInputChange}
        onInputClear={onInputClear}
        // No need to track selected items since we clear input text on selection
        selectedItem={null}
        shouldCloseOnSelection={false}
        onChange={onChange}
      />
      <HelperText hasError={hasError}>{helperText}</HelperText>
      <ErrorMessageForm hasError={hasError} errorMessage={errorMessage} />
    </>
  )
}
