import { Ellipsize, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const TotalWrapper = styled(Ellipsize)`
  width: 100%;
  text-align: right;
`

export const ChangeWrapper = styled(Flex)<{
  color: string
}>`
  ${({ theme }) => theme.typography['heading-sm']}
  color: ${({ color }) => color};
  max-width: calc(100% - 10px);
`

export const ChangeText = styled(Ellipsize)`
  margin-left: 2px;
`
