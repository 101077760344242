import { EntityCategories } from '@npco/mp-gql-types'
import { CategoryIcons } from '@npco/zeller-design-system'

export const getCategoryIcon = (
  category: EntityCategories | null | undefined
) => {
  switch (category) {
    case EntityCategories.ADVERTISING:
      return CategoryIcons.AdvertisingIcon
    case EntityCategories.BANK_FEES:
      return CategoryIcons.BankFeesIcon
    case EntityCategories.CLEANING:
      return CategoryIcons.CleaningIcon
    case EntityCategories.COMMISSION:
      return CategoryIcons.CommissionIcon
    case EntityCategories.COMPUTER_EQUIPMENT:
      return CategoryIcons.ComputerEquipmentIcon
    case EntityCategories.CONSULTING_ACCOUNTING:
      return CategoryIcons.ConsultingAccountingIcon
    case EntityCategories.COST_OF_GOODS_SOLD:
      return CategoryIcons.CostOfGoodsSoldIcon
    case EntityCategories.ENTERTAINMENT:
      return CategoryIcons.EntertainmentIcon
    case EntityCategories.FREIGHT_COURIER:
      return CategoryIcons.FreightCourierIcon
    case EntityCategories.GENERAL_EXPENSES:
      return CategoryIcons.GeneralExpensesIcon
    case EntityCategories.INCOME_TAX_EXPENSE:
      return CategoryIcons.IncomeTaxExpenseIcon
    case EntityCategories.INSURANCE:
      return CategoryIcons.InsuranceIcon
    case EntityCategories.INTEREST_EXPENSE:
      return CategoryIcons.InterestExpenseIcon
    case EntityCategories.LEGAL_EXPENSES:
      return CategoryIcons.LegalExpensesIcon
    case EntityCategories.LIGHT_POWER_HEATING:
      return CategoryIcons.LightPowerHeatingIcon
    case EntityCategories.MOTOR_VEHICLE_EXPENSES:
      return CategoryIcons.MotorVehicleExpensesIcon
    case EntityCategories.OFFICE_EQUIPMENT:
      return CategoryIcons.OfficeEquipmentIcon
    case EntityCategories.OFFICE_EXPENSES:
      return CategoryIcons.OfficeExpensesIcon
    case EntityCategories.PRINTING_STATIONERY:
      return CategoryIcons.PrintingStationeryIcon
    case EntityCategories.PURCHASES:
      return CategoryIcons.PurchasesIcon
    case EntityCategories.RENT:
      return CategoryIcons.RentIcon
    case EntityCategories.SUBSCRIPTIONS:
      return CategoryIcons.SubscriptionsIcon
    case EntityCategories.SUPERANNUATION:
      return CategoryIcons.SuperannuationIcon
    case EntityCategories.TELEPHONE_INTERNET:
      return CategoryIcons.TelephoneInternetIcon
    case EntityCategories.TRAVEL_INTERNATIONAL:
      return CategoryIcons.TravelInternationalIcon
    case EntityCategories.TRAVEL_NATIONAL:
      return CategoryIcons.TravelNationalIcon
    case EntityCategories.WAGES_SALARIES:
      return CategoryIcons.WagesSalariesIcon
    default:
      return undefined
  }
}
