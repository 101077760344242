import { Popper } from '@npco/zeller-design-system'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_AMOUNT } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'

import { defaultAmountDisplay } from './AccountFilterAmount.utils'
import { AccountFilterAmountContent } from './AccountFilterAmountContent/AccountFilterAmountContent'
import { AccountFilterAmountTrigger } from './AccountFilterAmountTrigger/AccountFilterAmountTrigger'

export const AccountFilterAmount = () => {
  const { amount, resetAmount } = useAccountFilters()

  const areFiltersDefault = amount === DEFAULT_AMOUNT

  return (
    <Popper
      popperWidth="20.625rem"
      renderTrigger={({ isOpen, setIsOpen }) => {
        const onClear = () => {
          setIsOpen(false)
          resetAmount()
        }

        const onToggleOpen = () => setIsOpen((wasOpen) => !wasOpen)

        return (
          <AccountFilterAmountTrigger
            areFiltersDefault={areFiltersDefault}
            onToggleOpen={onToggleOpen}
            onClear={onClear}
            amount={amount || defaultAmountDisplay}
            isOpen={isOpen}
          />
        )
      }}
    >
      {({ isOpen }) => <AccountFilterAmountContent isOpen={isOpen} />}
    </Popper>
  )
}
