import { useCallback } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { updateCachedContact } from 'features/utils-contacts-cache'

import { useUpdateContactIsSelfMutation } from './graphql/updateContactIsSelf.generated'

type UpdateContactProps = {
  id: string
  isSelf: boolean
  updateRvContact: (id: string, isSelf: boolean) => void
}

export const useUpdateContact = () => {
  const entityUuid = useSelectedEntityUuid()
  const [updateContactMutation] = useUpdateContactIsSelfMutation()
  const updateContact = useCallback(
    async ({ id, isSelf, updateRvContact }: UpdateContactProps) => {
      try {
        const result = await updateContactMutation({
          variables: {
            entityUuid,
            id,
            isSelf,
          },
          update: (cache, result) => {
            if (result.data?.updateContact) {
              updateCachedContact({
                cache,
                contactUpdate: { id, isSelf },
              })
            }
          },
        })

        if (!result.data?.updateContact) {
          ErrorLogger.report(
            '[Banking] Mutation response for UpdateContactIsSelf failed with undefined or false updateContact'
          )
          return
        }

        updateRvContact(id, isSelf)
      } catch (error) {
        ErrorLogger.report('[Banking] Error on update contact:', error)
      }
    },
    [entityUuid, updateContactMutation]
  )

  return updateContact
}
