import { BodyDefault, Flex, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledTags = styled.div`
  margin-bottom: 1rem;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin-bottom: 2.5rem;
  }
`

export const StyledTagsHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`

export const StyledTagsTitle = styled(H3)`
  margin: 0;
`

export const StyledTagsPlaceholder = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`
