import { useCallback, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { DashboardFiltersButton } from 'layouts/DashboardLayout'
import { DateRangeValue } from 'components/Filters/PillFilters/DateRange.types'
import { getDateRangeForPeriod } from 'components/Filters/PillFilters/DateRange/PeriodButtons.utils'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'
import { page } from 'translations'

import { CacheLocalStateEntityScopedVariable } from '../../../graphql/cache.types'
import { GetReportsLocalState } from './getReportsLocalState'
import { ReportsContentNew } from './ReportsContentNew'
import { ReportsFiltersNew } from './ReportsFiltersNew'
import { CacheLocalStateReports } from './ReportsNew.types'
import { useFetchReportsContent } from './useFetchReportsContent'
import { useReportsLocalCache } from './useReportsLocalCache'

const DEFAULT_RANGE = getDateRangeForPeriod('lastThirtyDays')

type ReportsNewProps = {
  defaultRange?: Required<DateRangeValue>
}

export const ReportsNew = ({
  defaultRange = DEFAULT_RANGE,
}: ReportsNewProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data: cacheData } = useQuery<
    CacheLocalStateReports,
    CacheLocalStateEntityScopedVariable
  >(GetReportsLocalState, { variables: { entityUuid } })
  const isFiltersVisible = !!cacheData?.local.reports.isFiltersVisible
  const { setIsFiltersVisible, setSelectedDates } = useReportsLocalCache()
  const reportsContentProps = useFetchReportsContent({
    defaultRange,
  })

  useEffect(() => {
    setSelectedDates(
      cacheData?.local.reports.filters.dates?.end
        ? cacheData.local.reports.filters.dates
        : defaultRange
    )
    // NOTE: on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggleFilters = useCallback(
    () => setIsFiltersVisible(!cacheData?.local.reports.isFiltersVisible),
    [cacheData?.local.reports.isFiltersVisible, setIsFiltersVisible]
  )

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <Flex
          width="100%"
          height="48px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Breadcrumb>
            <Breadcrumb.Item>
              <Breadcrumb.Text>{page.title.reports}</Breadcrumb.Text>
            </Breadcrumb.Item>
          </Breadcrumb>
          <DashboardFiltersButton
            disabled={reportsContentProps.isLoading}
            isActive={isFiltersVisible}
            onClick={handleToggleFilters}
            showIndicator
          />
        </Flex>
      }
      HeaderSecondaryRow={
        <FiltersHeaderRow isOpen={isFiltersVisible}>
          <ReportsFiltersNew defaultRange={defaultRange} />
        </FiltersHeaderRow>
      }
    >
      <ReportsContentNew {...reportsContentProps} />
    </PageTemplate>
  )
}
