import { createTranslations } from '@npco/utils-translations'

export const cardSingleManageDropdownTranslations = createTranslations({
  editNickname: 'Edit Card Nickname',
  resetPin: 'Reset PIN',
  spendControls: 'Spend Controls',
  lockCard: 'Lock Card',
  unlockCard: 'Unlock Card',
  closeCard: 'Close Card',
  reportLost: 'Report Lost or Stolen',
})
