import { useMutation } from '@apollo/client'
import { showApiErrorToast, showErrorToast } from '@npco/zeller-design-system'
import { SendReceiptMutation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/transactions'

import {
  SendReceipt as SendReceiptType,
  SendReceiptVariables,
} from 'types/gql-types/SendReceipt'
import { errorMessages } from 'translations'

export const useSendReceipt = (onComplete: () => void) => {
  const [sendReceipt, { loading: isSending }] = useMutation<
    SendReceiptType,
    SendReceiptVariables
  >(SendReceiptMutation, {
    onError: (error) => {
      showApiErrorToast(error)
    },
    onCompleted: (data) => {
      if (data.sendReceipt) {
        onComplete()
      } else {
        showErrorToast(errorMessages.failedSendReceipt)
      }
    },
    fetchPolicy: 'no-cache',
  })

  return {
    sendReceipt,
    isSending,
  }
}
