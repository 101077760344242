import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, InputAdaptiveField } from '@npco/zeller-design-system'
import { Field } from 'formik'

import {
  validateAccountName,
  validateBankAccountNumber,
  validateBSBNumber,
} from 'forms/formViews/BankAccountFields/BankAccountFields.utils'
import { FormCheckbox } from 'components/Input/Checkbox'

import { translations as translationsModal } from '../../../../CreateExternalAccountModal.i18n'
import { translations } from '../../ExternalAccountFormStage.i18n'

export const ExternalAccountFormFields = () => {
  const tModal = useTranslations(translationsModal)
  const t = useTranslations(translations)

  return (
    <Flex py="12px" flexDirection="column" gridGap={{ _: '24px' }}>
      <Box>
        <InputAdaptiveField
          name="accountName"
          type="text"
          label={tModal('nameField')}
          aria-label={tModal('nameField')}
          validate={validateAccountName}
        />
      </Box>
      <Flex
        gridGap={{ _: '24px', SM: '20px' }}
        flexDirection={{ _: 'column', SM: 'row' }}
      >
        <Box width={1}>
          <InputAdaptiveField
            name="accountBsb"
            label={tModal('bsbField')}
            aria-label={tModal('bsbField')}
            validate={validateBSBNumber}
          />
        </Box>
        <Box width={1}>
          <InputAdaptiveField
            name="accountNumber"
            label={tModal('accountNumberField')}
            aria-label={tModal('accountNumberField')}
            validate={validateBankAccountNumber}
          />
        </Box>
      </Flex>
      <Field
        component={FormCheckbox}
        type="checkbox"
        label={t('settlementAccountText')}
        name="shouldUpdateDepositAccount"
      />
    </Flex>
  )
}
