import { useReactiveVar } from '@apollo/client'
import { rvProfileSettings } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import { BiometricVerificationSection } from './IndexPage/BiometricVerificationSection'
import { Container } from './IndexPage/components'
import { IDVerificationSection } from './IndexPage/IDVerificationSection'
import { PasswordSection } from './IndexPage/PasswordSection'
import { PhoneSection } from './IndexPage/PhoneSection'

export const IndexPage = () => {
  const customerDetails = useReactiveVar(rvProfileSettings)

  return (
    <Container>
      <PasswordSection />
      <PhoneSection phone={customerDetails.phone} />
      <IDVerificationSection status={customerDetails.kycStatus} />
      <BiometricVerificationSection />
    </Container>
  )
}
