import {
  BodyDefaultTypography,
  DURATION,
  EASING_FUNCTION,
  getTransition,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

const StyledBaseButton = styled.button`
  font: inherit;
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  color: currentColor;

  &:not(:disabled) {
    cursor: pointer;
  }
`

export const StyledButtonContainer = styled.div`
  font: inherit;
  ${BodyDefaultTypography};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  background: ${({ theme }) => theme.colors.BLUE_80};
  border-radius: 9999px;
  outline: 2px solid transparent;
  transition: outline ${getTransition(DURATION.FAST, EASING_FUNCTION.IN_OUT)};

  path {
    transition: fill ${getTransition(DURATION.FAST, EASING_FUNCTION.IN_OUT)};
    fill: ${({ theme }) => theme.colors.BLUE_1000};
  }

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.BLUE_120};
  }
`

export const StyledContent = styled(StyledBaseButton)`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  gap: 0.375rem;
  transition: ${getTransition(DURATION.FAST, EASING_FUNCTION.IN_OUT)};
  transition-property: color;
  outline: none;

  &:hover {
    color: ${({ theme }) => theme.colors.BLUE_400};

    path {
      fill: ${({ theme }) => theme.colors.BLUE_400};
    }
  }
`

export const StyledCloseContainer = styled(StyledBaseButton)`
  padding: 0.25rem 0.625rem 0.25rem 0.25rem;
  opacity: 0.75;

  &:hover,
  ${StyledContent}:hover + & {
    path {
      fill: ${({ theme }) => theme.colors.BLUE_400};
    }
  }
`

export const StyledIconCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
`
