import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type ActivateSavingsAccountEncryptedMutationVariables = Types.Exact<{
  input: Types.CreateSavingsAccountV3Input
  entityUuid: Types.Scalars['ID']['input']
}>

export type ActivateSavingsAccountEncryptedMutationResponse = {
  __typename?: 'Mutation'
  createSavingsAccountV3: {
    __typename?: 'SavingsAccountV2'
    id: string
    name: string
    status: Types.SavingsAccountStatus
    balance: { __typename?: 'Money'; currency: string; value: string } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      image: string | null
      animation: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    savingsAccountDetails: {
      __typename?: 'SavingsAccountDetails'
      effectiveInterestRate: string | null
    } | null
    accountDetails: {
      __typename?: 'BankAccountDetails'
      account: string | null
    } | null
  }
}

export const ActivateSavingsAccountEncrypted = gql`
  mutation ActivateSavingsAccountEncrypted(
    $input: CreateSavingsAccountV3Input!
    $entityUuid: ID!
  ) {
    createSavingsAccountV3(input: $input, entityUuid: $entityUuid) {
      id
      name
      status
      balance {
        currency
        value
      }
      icon {
        colour
        letter
        image
        images {
          size
          url
        }
        animation
      }
      savingsAccountDetails {
        effectiveInterestRate
      }
      accountDetails {
        account
      }
    }
  }
` as unknown as TypedDocumentNode<
  ActivateSavingsAccountEncryptedMutationResponse,
  ActivateSavingsAccountEncryptedMutationVariables
>
export type ActivateSavingsAccountEncryptedMutationFn = Apollo.MutationFunction<
  ActivateSavingsAccountEncryptedMutationResponse,
  ActivateSavingsAccountEncryptedMutationVariables
>

/**
 * __useActivateSavingsAccountEncryptedMutation__
 *
 * To run a mutation, you first call `useActivateSavingsAccountEncryptedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSavingsAccountEncryptedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSavingsAccountEncryptedMutation, { data, loading, error }] = useActivateSavingsAccountEncryptedMutation({
 *   variables: {
 *      input: // value for 'input'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useActivateSavingsAccountEncryptedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateSavingsAccountEncryptedMutationResponse,
    ActivateSavingsAccountEncryptedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateSavingsAccountEncryptedMutationResponse,
    ActivateSavingsAccountEncryptedMutationVariables
  >(ActivateSavingsAccountEncrypted, options)
}
export type ActivateSavingsAccountEncryptedMutationHookResult = ReturnType<
  typeof useActivateSavingsAccountEncryptedMutation
>
export type ActivateSavingsAccountEncryptedMutationResult =
  Apollo.MutationResult<ActivateSavingsAccountEncryptedMutationResponse>
export type ActivateSavingsAccountEncryptedMutationOptions =
  Apollo.BaseMutationOptions<
    ActivateSavingsAccountEncryptedMutationResponse,
    ActivateSavingsAccountEncryptedMutationVariables
  >
