import { DocumentNode } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import {
  type ResultOf,
  type TypedDocumentNode,
  type VariablesOf,
} from '@graphql-typed-document-node/core'

import { IMockBuilder, MockBuilder } from './MockBuilder'

export const mock = <
  V,
  R,
  TQuery extends DocumentNode = TypedDocumentNode<R, V>
>(
  query: TQuery
): IMockBuilder<VariablesOf<TQuery>, ResultOf<TQuery>> => {
  const operationName = getOperationName(query)

  if (!operationName) {
    throw new Error('Attempting to mock a query without an operation name')
  }

  return new MockBuilder<VariablesOf<TQuery>, ResultOf<TQuery>>(operationName)
}
