import { useMemo, useState } from 'react'
import { ApolloError, gql } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box, SelectSearchInput } from '@npco/zeller-design-system'

import { accountSelectTranslations } from '../AccountSelect.i18n'
import { PopperItemsDebitCardAccountV2Fragment as PopperItemsFragmentType } from './PopperItems.generated'
import { PopperItemsContent } from './PopperItemsContent/PopperItemsContent'

interface PopperItemsProps {
  accounts?: PopperItemsFragmentType[]
  setSelectedAccount: ({
    id,
    type,
  }: {
    id: string
    type: DebitCardAccountType
  }) => void
  setIsOpen: (value: boolean) => void
  isLoading: boolean
  refetch: () => void
  error: ApolloError | undefined
  selectedAccountId?: string
}

export const PopperItems = ({
  accounts,
  setSelectedAccount,
  setIsOpen,
  isLoading,
  refetch,
  error,
  selectedAccountId,
}: PopperItemsProps) => {
  const t = useTranslations(accountSelectTranslations)

  const [searchInput, setSearchInput] = useState('')

  const filteredAccounts = useMemo(() => {
    if (!searchInput) return accounts

    return accounts?.filter((account) =>
      account.name.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [accounts, searchInput])

  return (
    <>
      <Box px="8px" pb="8px">
        <SelectSearchInput
          value={searchInput}
          setValue={setSearchInput}
          aria-label={t('search')}
          placeholder={t('searchAccount')}
        />
      </Box>
      <PopperItemsContent
        accounts={accounts}
        error={error}
        isLoading={isLoading}
        refetch={refetch}
        filteredAccounts={filteredAccounts}
        setSelectedAccount={setSelectedAccount}
        setIsOpen={setIsOpen}
        selectedAccountId={selectedAccountId}
      />
    </>
  )
}

PopperItems.fragments = {
  DebitCardAccountV2: gql`
    fragment PopperItemsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      ...PopperItemsContentDebitCardAccountV2Fragment
    }

    ${PopperItemsContent.fragments.DebitCardAccountV2}
  `,
}
