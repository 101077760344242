import { rvIsUserSavingOnboarding } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'

import { SaveAndCloseModal } from '../SaveAndCloseModal/SaveAndCloseModal'
import { useSaveAndCloseGroup } from './hooks/useSaveAndCloseGroup'

interface RenderProps {
  onClick: () => void
  isDisabled: boolean
}

export const SaveAndCloseGroup = ({
  children,
}: WithChildrenRenderProp<RenderProps>) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const { isOnboardingProcessing, isSaveAndCloseAllowed } =
    useSaveAndCloseGroup()

  return isSaveAndCloseAllowed ? (
    <SaveAndCloseModal
      toggleButton={children({
        isDisabled: isOnboardingProcessing,
        onClick: () => {
          rvIsUserSavingOnboarding(true)
          openModal()
        },
      })}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    />
  ) : null
}
