import { lazy, Suspense } from 'react'
import Modal from 'react-modal'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { useDayjs } from 'hooks/useDayjs'
import { usePageTitle } from 'hooks/usePageTitle'
import { IS_DEVELOPMENT } from 'const/envs'
import { AppStorageHandler } from 'services/sessionStorage/handlers/AppStorageHandler'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'

import { AppRoutes } from './components/AppRoutes/AppRoutes'

Modal.setAppElement('#root')

const LazyDevTools = lazy(() => import('features/DevTools/DevTools'))

export const App: React.FC = () => {
  usePageTitle()
  useDayjs()

  const flags = useFeatureFlags()
  const isDevToolsEnabled = IS_DEVELOPMENT || flags.DevTools

  return (
    <>
      <AppStorageHandler />
      {!!isDevToolsEnabled && (
        <Suspense fallback={null}>
          <LazyDevTools />
        </Suspense>
      )}
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </>
  )
}
