import { useEffect, useRef, useState } from 'react'

interface UseInfiniteLoaderProps {
  isLoading: boolean
  onLoadMore: () => Promise<void>
  shouldLoadMore: boolean
}

export const useInfiniteLoader = <T extends Element = HTMLDivElement>({
  isLoading,
  onLoadMore,
  shouldLoadMore,
}: UseInfiniteLoaderProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const observerContainer = useRef<T>(null)

  const executeLoadMore = () => {
    if (!shouldLoadMore) {
      return
    }
    setIsLoadingMore(true)

    onLoadMore?.()
      .catch(() => undefined)
      .finally(() => setIsLoadingMore(false))
  }

  const executeLoadMoreRef = useRef<typeof executeLoadMore>(executeLoadMore)

  useEffect(() => {
    executeLoadMoreRef.current = executeLoadMore
  })

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        executeLoadMoreRef.current?.()
      }
    })

    if (isLoading) {
      observer.disconnect()
      return () => undefined
    }

    if (observerContainer.current) {
      observer.observe(observerContainer.current)
    }

    return () => observer.disconnect()
  }, [isLoading])

  return { isLoadingMore, observerContainer }
}
