import { useCallback, useMemo } from 'react'

import { FileWithId } from 'components/File'

interface UseImagePreviewNavigationProps {
  selectedImageId: string | null
  setPreviewId: (id: string) => void
  images: FileWithId[]
}

export const useImagePreviewNavigation = ({
  selectedImageId,
  setPreviewId,
  images,
}: UseImagePreviewNavigationProps) => {
  const imagesIdsArray = useMemo(() => {
    return images.map((image) => image.id)
  }, [images])

  const currentIndex = useMemo(() => {
    if (selectedImageId) {
      return imagesIdsArray.indexOf(selectedImageId)
    }

    return 0
  }, [imagesIdsArray, selectedImageId])

  const onNext = useCallback(() => {
    const isLastImage = currentIndex === imagesIdsArray.length - 1

    if (isLastImage) {
      const firstImageId = imagesIdsArray[0]
      setPreviewId(firstImageId)

      return
    }

    const nextImageId = imagesIdsArray[currentIndex + 1]

    setPreviewId(nextImageId)
  }, [currentIndex, imagesIdsArray, setPreviewId])

  const onPrevious = useCallback(() => {
    const isFirstImage = currentIndex === 0

    if (isFirstImage) {
      const lastImageId = imagesIdsArray[imagesIdsArray.length - 1]
      setPreviewId(lastImageId)

      return
    }

    const previousImageId = imagesIdsArray[currentIndex - 1]
    setPreviewId(previousImageId)
  }, [currentIndex, imagesIdsArray, setPreviewId])

  const imageConfig = useMemo(() => {
    return {
      currentIndex,
      total: imagesIdsArray.length,
    }
  }, [currentIndex, imagesIdsArray])

  return {
    onNext,
    onPrevious,
    imageConfig,
  }
}
