import {
  CardMedia,
  TransactionStatus,
  TransactionType,
} from '@npco/mp-gql-types'

import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'

export const checkIfMotoTransaction = (transaction: Transaction) =>
  transaction.cardMedia === CardMedia.MANUAL &&
  transaction.type !== TransactionType.REFUND

export const checkIfRefundableTransaction = (transaction: Transaction) =>
  transaction.type === TransactionType.PURCHASE &&
  transaction.status === TransactionStatus.APPROVED &&
  transaction.amount !== transaction.refundedAmount
