import React from 'react'
import { Flex, SvgIcon } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as BatteryIcon } from 'assets/svg/StatusBar/battery.svg'
import { ReactComponent as WifiSignalIcon } from 'assets/svg/StatusBar/wifi-signal.svg'

import { getStatusBarColor } from '../../SiteAppearance.utils'
import { SiteAppearanceFormValues } from '../../SiteAppearanceForm/SiteAppearanceForm.types'
import * as styled from './StatusBar.styled'

export const StatusBar = () => {
  const { values } = useFormikContext<SiteAppearanceFormValues>()

  return (
    <Flex
      position="absolute"
      top="4px"
      width="180px"
      height="12px"
      backgroundColor={values.selectedColour}
      justifyContent="space-between"
      p="2px 12px"
    >
      <styled.TimeStatusWrapper
        color={getStatusBarColor(values.selectedColour)}
      >
        10:08
      </styled.TimeStatusWrapper>
      <Flex gap="4px">
        <SvgIcon
          width="8px"
          height="8px"
          color={getStatusBarColor(values.selectedColour)}
        >
          <WifiSignalIcon />
        </SvgIcon>
        <SvgIcon
          width="5px"
          height="8px"
          color={getStatusBarColor(values.selectedColour)}
        >
          <BatteryIcon />
        </SvgIcon>
      </Flex>
    </Flex>
  )
}
