import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { v4 as randomUUID } from 'uuid'

type EmailLabel = NonNullable<
  ContactCoreFieldsFragment['additionalEmails']
>[0]['label']
type PhoneLabel = NonNullable<
  ContactCoreFieldsFragment['additionalPhones']
>[0]['label']

type UUID = `${string}-${string}-${string}-${string}-${string}`

type MergeReturn = {
  key: UUID
  label: {
    label: string
    sublabel?: string
  }
  value: string
}

type MergeOptions = {
  defaultLabel: string
  emptyState?: string
}

type PhoneOrEmailItem = {
  email?: string
  phone?: string
  label: EmailLabel | PhoneLabel | null
}

const mergeEmailOrPhone = (
  first: PhoneOrEmailItem | null,
  additional: PhoneOrEmailItem[] | null,
  { defaultLabel, emptyState = '-' }: MergeOptions
): MergeReturn[] => {
  const merged = [first]
    .concat(additional)
    .filter((item) => {
      return Boolean(item?.email || item?.phone)
    })
    .map((item): MergeReturn => {
      return {
        value: item?.email || item?.phone || emptyState,
        label: {
          label: defaultLabel,
          sublabel: item?.label?.labelText,
        },
        key: randomUUID() as UUID,
      }
    })

  if (merged.length === 0) {
    merged.push({
      value: emptyState,
      label: {
        label: defaultLabel,
      },
      key: randomUUID() as UUID,
    })
  }

  return merged
}

export const mergeEmails = mergeEmailOrPhone
export const mergePhones = mergeEmailOrPhone
