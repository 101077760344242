import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Replace Pairing',
  currentPairingInfo:
    'This Oracle Simphony workstation is currently paired to {pairedDeviceName}.',
  newPairingConfirmation:
    'Would you like to pair it with {newDeviceName}? This will remove the current pairing.',
  secondaryButton: 'Cancel',
  primaryButton: 'Replace',
  successToast: 'Changes saved',
  errorToast: 'Changes could not be saved, please try again.',
})
