import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Pair Workstation',
  workstationIdLabel: 'Workstation ID',
  primaryButtonLabel: 'Save',
  secondaryButtonLabel: 'Cancel',
  successToast: 'Terminal paired to workstation',
  errorToast: 'Changes could not be saved, please try again.',
})
