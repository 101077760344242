import { ApolloError, gql } from '@apollo/client'
import { ModalBasic } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { PredefinedAddressPageFragment } from 'types/gql-types/PredefinedAddressPageFragment'
import {
  StyledFormBodyWithOffset,
  StyledFormFooterWithOffset,
  StyledFormWithOffset,
} from 'pages/GlobalModals/GlobalModals.styled'

import {
  AddressFormValues,
  PredefinedAddressFormValues,
  PredefinedAddressValues,
} from '../DeliveryAddress.types'
import { AddressRadioFields } from './AddressRadioFields/fields/AddressRadioFields'
import { DeliveryAddressErrorPage } from './DeliveryAddressErrorPage/DeliveryAddressErrorPage'
import { GoToCustomAddressPage } from './GoToCustomAddressPage/GoToCustomAddressPage'
import { usePredefinedAddressForm } from './hooks/usePredefinedAddressForm'

interface PredefinedAddressPageProps {
  dataTestId?: string
  submitButtonLabel?: string
  toggleDescription?: string
  addressOptions?: PredefinedAddressPageFragment
  isLoading?: boolean
  error?: ApolloError
  retry: () => void
  isSubmitting?: boolean
  initialAddress?: AddressFormValues
  onSubmit: (address: PredefinedAddressValues) => void
  onCancel?: () => void
  goToCustomAddressPage: () => void
}

export const PredefinedAddressPage = ({
  dataTestId = 'predefined-address-page',
  isLoading,
  error,
  retry,
  addressOptions,
  toggleDescription,
  submitButtonLabel,
  isSubmitting,
  initialAddress,
  goToCustomAddressPage,
  onCancel,
  onSubmit,
}: PredefinedAddressPageProps) => {
  const { initialAddressOption, handleSubmit } = usePredefinedAddressForm({
    address: initialAddress,
    addressOptions,
    onSubmit,
  })

  if (error && !isLoading) {
    return (
      <DeliveryAddressErrorPage
        refetch={retry}
        goToCustomAddress={goToCustomAddressPage}
        onCancel={onCancel}
      />
    )
  }

  return (
    <Formik<PredefinedAddressFormValues>
      initialValues={{ address: initialAddressOption }}
      onSubmit={handleSubmit}
      validateOnMount
    >
      <>
        <ModalBasic.Body canScroll={false}>
          <GoToCustomAddressPage
            toggleDescription={toggleDescription}
            onClick={goToCustomAddressPage}
          />
        </ModalBasic.Body>
        <StyledFormWithOffset data-testid={dataTestId}>
          <StyledFormBodyWithOffset>
            <AddressRadioFields
              addressOptions={addressOptions}
              isLoading={isLoading}
            />
          </StyledFormBodyWithOffset>
          <StyledFormFooterWithOffset
            shouldShowBackButton={Boolean(onCancel)}
            onCancel={onCancel}
            nextButtonLabel={submitButtonLabel}
            isLoading={isSubmitting}
            disableOnInvalid
          />
        </StyledFormWithOffset>
      </>
    </Formik>
  )
}

PredefinedAddressPage.fragments = {
  Entity: gql`
    fragment PredefinedAddressPageFragment on Entity {
      ...AddressRadioFieldsFragment
    }
    ${AddressRadioFields.fragments.Entity}
  `,
}
