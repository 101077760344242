import { DashboardErrorPage } from 'pages/Dashboard/Fallbacks/DashboardErrorPage'
import { useSourceFilter } from 'pages/Dashboard/hooks/useSourceFilter'
import { SourcePicker } from 'pages/Dashboard/SourcePicker/SourcePicker'

import { PerformancePeriod } from '../DashboardPerformance.types'
import { usePerformanceData } from '../hooks/usePerformanceData'
import { PerformanceChart } from './PerformanceChart/PerformanceChart'
import {
  StyledChartWrapper,
  StyledContentWrapper,
  StyledPerformanceContentWrapper,
  StyledSitePerformanceBreakdownWrapper,
  StyledSiteSelectWrapper,
  StyledSummaryWrapper,
} from './PerformanceContent.styled'
import { PerformanceLoadingSummary } from './PerformanceLoadingSummary'
import { PerformanceSummary } from './PerformanceSummary'

interface Props {
  performancePeriod: PerformancePeriod
}

export const PerformanceContent = ({ performancePeriod }: Props) => {
  const { filter } = useSourceFilter()
  const {
    performanceData,
    isLoadingPerformanceData,
    hasNoPerformanceData,
    hasError,
    refetch,
  } = usePerformanceData({ filter })

  return (
    <StyledContentWrapper data-testid="spc">
      <StyledPerformanceContentWrapper>
        <StyledSiteSelectWrapper>
          <SourcePicker />
        </StyledSiteSelectWrapper>
        <StyledSummaryWrapper>
          {!isLoadingPerformanceData && !hasError && (
            <PerformanceSummary
              performancePeriod={performancePeriod}
              performanceData={performanceData}
            />
          )}
          {isLoadingPerformanceData && <PerformanceLoadingSummary />}
        </StyledSummaryWrapper>

        <StyledChartWrapper>
          {!hasError && (
            <PerformanceChart
              isLoading={isLoadingPerformanceData}
              disabled={hasNoPerformanceData}
              performancePeriod={performancePeriod}
              performanceData={performanceData}
            />
          )}
        </StyledChartWrapper>
      </StyledPerformanceContentWrapper>
      {hasError && (
        <StyledSitePerformanceBreakdownWrapper justifyContent="center">
          <DashboardErrorPage retry={refetch} />
        </StyledSitePerformanceBreakdownWrapper>
      )}
    </StyledContentWrapper>
  )
}
