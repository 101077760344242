import { TabItemProps, Tabs } from '@npco/zeller-design-system'

import { SwitchAnimationContainer } from '../SwitchAnimationContainer/SwitchAnimationContainer'
import { StyledTabsContainer } from './DrawerHeader.styled'

type DrawerHeaderProps = {
  animationKey: string
  tabs: TabItemProps[]
  tagsGroupName: string
  selectedTab: string
}

export const DrawerHeader = ({
  animationKey,
  tabs,
  tagsGroupName,
  selectedTab,
}: DrawerHeaderProps) => {
  return (
    <StyledTabsContainer>
      <SwitchAnimationContainer animationKey={animationKey}>
        <Tabs currentTab={selectedTab} dataTestId={tagsGroupName}>
          {tabs.map((tab) => (
            <Tabs.Item key={tab.value} {...tab} />
          ))}
        </Tabs>
      </SwitchAnimationContainer>
    </StyledTabsContainer>
  )
}
