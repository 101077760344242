import { gql } from '@apollo/client'

export const GetZellerSurchargeSettings = gql`
  query GetZellerSurchargeSettings($entityUuid: ID!) {
    getZellerInvoiceSettings(entityUuid: $entityUuid) {
      __typename
      id
      name
      surchargesTaxes {
        feesSurchargeZinv {
          feeFixed
          feeFixedIntl
          feePercent
          feePercentIntl
        }
      }
    }
  }
`
