import { createTranslations } from '@npco/utils-translations'

export const XeroErrorModalTranslations = createTranslations({
  connectionError: 'Xero Connection Error',
  connectionErrors: 'Xero Connection Errors',
  authenticationExpired: 'Xero Authentication Expired',
  authErrorDescription: 'Authentication for the {services} has expired.',
  genericErrorDescription: 'There has been an error with the {services}.',
  bankFeedsIntegration: 'Bank Feeds Integration',
  zellerPaymentService: 'Zeller Payment Service',
  services: '{service1} and {service2}',
  primaryButtonLabel: 'Go to Connections',
  secondaryButtonLabel: 'Ignore',
})
