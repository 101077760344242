import { gql, type TypedDocumentNode } from '@apollo/client'

export type GetEmailMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  email: string | null
}

export type GetEmailContactFragment = {
  __typename?: 'Contact'
  email: { __typename?: 'ContactEmail'; email: string } | null
}

export type GetWebsiteMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  url: string | null
}

export type GetWebsiteContactFragment = {
  __typename?: 'Contact'
  website: string | null
}

export type GetAbnMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  abn: string | null
}

export type GetAbnContactFragment = {
  __typename?: 'Contact'
  abn: string | null
}

export const GetEmailMerchantDetailsFragmentDoc = gql`
  fragment GetEmailMerchantDetailsFragment on MerchantDetails {
    email
  }
` as unknown as TypedDocumentNode<GetEmailMerchantDetailsFragment, undefined>
export const GetEmailContactFragmentDoc = gql`
  fragment GetEmailContactFragment on Contact {
    email {
      email
    }
  }
`
export const GetWebsiteMerchantDetailsFragmentDoc = gql`
  fragment GetWebsiteMerchantDetailsFragment on MerchantDetails {
    url
  }
`
export const GetWebsiteContactFragmentDoc = gql`
  fragment GetWebsiteContactFragment on Contact {
    website
  }
`
export const GetAbnMerchantDetailsFragmentDoc = gql`
  fragment GetAbnMerchantDetailsFragment on MerchantDetails {
    abn
  }
`
export const GetAbnContactFragmentDoc = gql`
  fragment GetAbnContactFragment on Contact {
    abn
  }
`
