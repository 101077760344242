import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledItemText = styled(BodyDefault)`
  margin: 0;
`
export const ItemAside = styled.div<{
  isHoverable?: boolean
}>`
  visibility: ${({ isHoverable }) => (isHoverable ? 'hidden' : 'visible')};
`

export const ItemAsideContainer = styled.div`
  &:hover {
    ${ItemAside} {
      visibility: visible;
    }
  }
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  gap: 12px;

  &:hover + ${ItemAsideContainer} {
    ${ItemAside} {
      visibility: visible;
    }
  }
`

export const ItemContentContainer = styled.div<{
  isEllipsisable?: boolean
}>`
  min-width: ${({ isEllipsisable }) => (isEllipsisable ? '0' : 'auto')};
`

export const ListItemRow = styled.div`
  display: grid;
  align-items: center;
  position: relative;
`
