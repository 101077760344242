import { useCallback } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { translations } from './useGetCategoryName.i18n'

export const useGetCategoryName = () => {
  const t = useTranslations(translations)

  return useCallback(
    (category: EntityCategories | null | 'other') => {
      if (!category) {
        return t('uncategorised')
      }

      switch (category) {
        case 'other':
          return t('other')
        case EntityCategories.ADVERTISING:
          return t('advertising')
        case EntityCategories.BANK_FEES:
          return t('bankFees')
        case EntityCategories.CLEANING:
          return t('cleaning')
        case EntityCategories.COMMISSION:
          return t('commission')
        case EntityCategories.COMPUTER_EQUIPMENT:
          return t('computerEquipment')
        case EntityCategories.CONSULTING_ACCOUNTING:
          return t('consultingAccounting')
        case EntityCategories.COST_OF_GOODS_SOLD:
          return t('costOfGoodsSold')
        case EntityCategories.ENTERTAINMENT:
          return t('entertainment')
        case EntityCategories.FREIGHT_COURIER:
          return t('freightCourier')
        case EntityCategories.GENERAL_EXPENSES:
          return t('generalExpenses')
        case EntityCategories.INCOME_TAX_EXPENSE:
          return t('incomeTaxExpense')
        case EntityCategories.INSURANCE:
          return t('insurance')
        case EntityCategories.INTEREST_EXPENSE:
          return t('interestExpense')
        case EntityCategories.LEGAL_EXPENSES:
          return t('legalExpenses')
        case EntityCategories.LIGHT_POWER_HEATING:
          return t('lightPowerHeating')
        case EntityCategories.MOTOR_VEHICLE_EXPENSES:
          return t('motorVehicleExpenses')
        case EntityCategories.OFFICE_EQUIPMENT:
          return t('officeEquipment')
        case EntityCategories.OFFICE_EXPENSES:
          return t('officeExpenses')
        case EntityCategories.PRINTING_STATIONERY:
          return t('printingStationery')
        case EntityCategories.PURCHASES:
          return t('purchases')
        case EntityCategories.RENT:
          return t('rent')
        case EntityCategories.SUBSCRIPTIONS:
          return t('subscriptions')
        case EntityCategories.SUPERANNUATION:
          return t('superannuation')
        case EntityCategories.TELEPHONE_INTERNET:
          return t('telephoneInternet')
        case EntityCategories.TRAVEL_INTERNATIONAL:
          return t('travelInternational')
        case EntityCategories.TRAVEL_NATIONAL:
          return t('travelNational')
        case EntityCategories.WAGES_SALARIES:
          return t('wageSalaries')
        default:
          return String(category)
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
      }
    },
    [t]
  )
}
