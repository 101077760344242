import dayjs from 'utils/dayjs'

export const getFormatTemplate = (minute: number) => {
  if (minute === 0) {
    return 'ha'
  }
  return 'h:mma'
}

export const getSettlementTimeString = (
  tradingSlot: string | null | undefined
) => {
  const currentDate = dayjs().format('YYYY-MM-DD')
  if (tradingSlot === '12-to-12') {
    return `${currentDate}T00:00:00`
  }
  if (tradingSlot === '4-to-4') {
    return `${currentDate}T04:00:00`
  }
  return ''
}

export const getSettlementCutOffTime = (
  tradingSlot: string | null | undefined
) => {
  const settlementTimeString = getSettlementTimeString(tradingSlot)
  const sourceTimeZone = 'Australia/Melbourne'
  const customerTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (!settlementTimeString) {
    return '-'
  }
  const convertedTime = dayjs(settlementTimeString)
    .tz(sourceTimeZone)
    .tz(customerTimeZone)

  return convertedTime.format(getFormatTemplate(convertedTime.minute()))
}
