import { useCallback, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  DownloadIcon,
  Lightbox,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ReactComponent as CloseIcon } from 'assets/svg/close-grey.svg'
import { useFetchImage } from 'hooks/banking/useImagePreview/useFetchImage'
import { useImagePreviewAdd } from 'hooks/banking/useImagePreview/useImagePreviewAdd'
import { useImagePreviewNavigation } from 'hooks/banking/useImagePreview/useImagePreviewNavigation'
import { useWindowResolution } from 'hooks/useWindowResolution/useWindowResolution'
import { ImagePreview } from 'components/ImagePreview/ImagePreview'

import { Delete } from './Delete/Delete'
import { imagePreviewModalTranslations } from './ImagePreviewModal.i18n'
import { LightboxSelector } from './LightboxSelector/LightboxSelector'
import { Skeleton } from './Skeleton/Skeleton'

const BUTTON_HEADER_HEIGHT = 80
const TILE_SELECTOR_HEIGHT = 48
const MOBILE_IMAGE_CONTROLS_HEIGHT = 32

const HORIZONTAL_PADDING_DESKTOP_AND_TABLET = 80
const HORIZONTAL_PADDING_MOBILE = 32

const MARGIN_BETWEEN_TILE_SELECTOR_AND_IMAGE_CONTROL = 72
const MARGIN_BETWEEN_IMAAGE_CONTROL_AND_BOTTOM = 12

const IMAGE_CONTROL_VERTICAL_MARGIN =
  MARGIN_BETWEEN_TILE_SELECTOR_AND_IMAGE_CONTROL +
  MARGIN_BETWEEN_IMAAGE_CONTROL_AND_BOTTOM

interface ImagePreviewModalProps {
  transactionUuid: string
  initialImageId: string
  onClose: () => void
}

export const ImagePreviewModal = ({
  transactionUuid,
  initialImageId,
  onClose,
}: ImagePreviewModalProps) => {
  const { isLoadingImages, isDownloadingFiles, transactionCombinedImages } =
    useFetchImage({ transactionUuid })

  const [previewId, setPreviewId] = useState<string | null>(initialImageId)

  const selectedImageId = useMemo(() => {
    if (isLoadingImages || isDownloadingFiles) {
      return null
    }

    if (previewId === 'first-image') {
      return transactionCombinedImages[0]?.id ?? null
    }

    return previewId
  }, [
    isLoadingImages,
    isDownloadingFiles,
    transactionCombinedImages,
    previewId,
  ])

  const { onPrevious, onNext, imageConfig } = useImagePreviewNavigation({
    selectedImageId,
    setPreviewId,
    images: transactionCombinedImages,
  })

  const selectedImage = useMemo(() => {
    if (selectedImageId === null) {
      return null
    }

    return transactionCombinedImages.find(
      (image) => image.id === selectedImageId
    )
  }, [selectedImageId, transactionCombinedImages])
  const { handleOnAdd } = useImagePreviewAdd({
    transactionUuid,
    transactionCombinedImages,
  })

  const isSingleImage = useMemo(
    () => transactionCombinedImages.length === 1,
    [transactionCombinedImages]
  )
  const onSuccessfulDelete = useCallback(() => {
    if (isSingleImage) {
      onClose()
      return
    }

    onNext()
  }, [onClose, onNext, isSingleImage])

  const fileUrl = useMemo(
    () => (selectedImage ? URL.createObjectURL(selectedImage.file) : ''),
    [selectedImage]
  )

  const t = useTranslations(imagePreviewModalTranslations)
  const { currentIndex, total } = imageConfig
  const indexText = t('indexText', {
    currentIndex: currentIndex + 1,
    total,
  })

  const isMobile = useIsMobileResolution()
  const { width, height } = useWindowResolution()
  const previewHeight =
    height -
    BUTTON_HEADER_HEIGHT -
    TILE_SELECTOR_HEIGHT -
    (!isMobile ? 0 : MOBILE_IMAGE_CONTROLS_HEIGHT) -
    (!isMobile
      ? MARGIN_BETWEEN_TILE_SELECTOR_AND_IMAGE_CONTROL
      : IMAGE_CONTROL_VERTICAL_MARGIN)
  const previewWidth =
    width -
    (!isMobile
      ? HORIZONTAL_PADDING_DESKTOP_AND_TABLET
      : HORIZONTAL_PADDING_MOBILE)

  return (
    <Lightbox
      isOpen
      ariaLabelledby="receipt-lightbox"
      shouldCloseOnEsc
      closeModal={onClose}
    >
      <Lightbox.Header
        heading={selectedImage?.file.name ?? ''}
        index={indexText}
        headingId="receipt-lightbox"
        isLoading={!selectedImage}
      >
        <Lightbox.Actions>
          {selectedImage && (
            <>
              <Delete
                transactionUuid={transactionUuid}
                selectedImage={selectedImage}
                onSuccessfulDelete={onSuccessfulDelete}
              />
              <Lightbox.Download
                href={fileUrl}
                download={selectedImage.file.name}
                icon={
                  <DownloadIcon
                    color={COLOR.WHITE}
                    hoverColor={COLOR.WHITE}
                    height="16"
                    width="16"
                  />
                }
              >
                {!isMobile && t('download')}
              </Lightbox.Download>
            </>
          )}
          <Lightbox.Button onClick={onClose} icon={CloseIcon}>
            {!isMobile && t('close')}
          </Lightbox.Button>
        </Lightbox.Actions>
      </Lightbox.Header>
      <Lightbox.Content>
        <LightboxSelector
          areImageControlsEnabled={!isSingleImage}
          onPrevious={onPrevious}
          onNext={onNext}
          previewId={selectedImageId}
          setPreviewId={setPreviewId}
          onAdd={handleOnAdd}
          images={transactionCombinedImages}
          indexText={indexText}
          isLoading={!selectedImage}
        >
          {selectedImage ? (
            <ImagePreview
              image={selectedImage}
              width={previewWidth}
              height={previewHeight}
              objectFit="scale-down"
            />
          ) : (
            <Skeleton
              previewWidth={previewWidth}
              previewHeight={previewHeight}
            />
          )}
        </LightboxSelector>
      </Lightbox.Content>
    </Lightbox>
  )
}
