import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { Box, ToggleForm } from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { ReceiptProps } from 'types/settings'
import {
  StyledLabelText,
  StyledTitle,
  StyledToggleContainer,
} from 'forms/formViews/Receipt/Receipt.styled'
import { page } from 'translations'

export const OptionalReceiptElements = () => {
  const { values } = useFormikContext<ReceiptProps>()
  const flags = useFeatureFlags()

  return (
    <Box data-testid="optional-elements">
      <StyledTitle>{page.settings.receipt.receiptPrinting}</StyledTitle>
      <StyledToggleContainer>
        <StyledLabelText>
          {page.settings.receipt.alwaysPrintStoreCopy}
        </StyledLabelText>
        <Field
          component={ToggleForm}
          name="merchantCopy"
          value={values.merchantCopy}
        />
      </StyledToggleContainer>
      {flags.PrintReceiptSettings && (
        <StyledToggleContainer>
          <StyledLabelText>
            {page.settings.receipt.printDeclinedTransaction}
          </StyledLabelText>
          <Field
            component={ToggleForm}
            name="printDeclinedTransaction"
            value={values.printDeclinedTransaction}
          />
        </StyledToggleContainer>
      )}
      {flags.SiteReceiptPrintQRCode && (
        <StyledToggleContainer>
          <StyledLabelText>{page.settings.receipt.printQrCode}</StyledLabelText>
          <Field component={ToggleForm} name="qrCode" value={values.qrCode} />
        </StyledToggleContainer>
      )}
      <StyledToggleContainer>
        <StyledLabelText>
          {page.settings.receipt.printCompanyLogo}
        </StyledLabelText>
        <Field
          component={ToggleForm}
          name="printLogo"
          value={values.printLogo}
        />
      </StyledToggleContainer>
      <StyledToggleContainer>
        <StyledLabelText>
          {page.settings.receipt.printSocialMediaLinks}
        </StyledLabelText>
        <Field
          component={ToggleForm}
          name="printSocials"
          value={values.printSocials}
        />
      </StyledToggleContainer>
    </Box>
  )
}
