import { createTranslations } from '@npco/utils-translations'

export const receiptAndNoteCellTranslations = createTranslations({
  receipts: 'Receipts',
  notes: 'Notes',
  addReceipt: 'Add Receipt',
  viewReceipt: 'View Receipt',
  addNote: 'Add Note',
  editNote: 'Edit Note',
})
