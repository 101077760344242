import {
  BodySmall,
  Box,
  Flex,
  Heading,
  HeadingStyles,
} from '@npco/zeller-design-system'

import { page } from 'translations'

import { NotFoundPlaceholder } from '../../NotFoundPlaceholder/NotFoundPlaceholder'

interface Props {
  searchInput: string
}

export const NoTagsPlaceholder = ({ searchInput }: Props) => {
  if (searchInput) {
    return <NotFoundPlaceholder searchInput={searchInput} />
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
      minHeight="9.5rem"
      height="100%"
      p="1.5rem"
    >
      <Box mb="0.5rem">
        <Heading.H4 withStyles={HeadingStyles.H5}>
          {page.accounts.transactionsList.filters.noTagsPlaceholder.line1}
        </Heading.H4>
      </Box>
      <Box as={BodySmall} m="0">
        {page.accounts.transactionsList.filters.noTagsPlaceholder.line2}
      </Box>
    </Flex>
  )
}
