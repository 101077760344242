import { useCallback } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { showSuccessToast } from '@npco/zeller-design-system'
import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { useUpdatedOutstandingTransactions } from 'features/Cards/CardSingle/hooks/useUpdatedOutstandingTransactions'
import { updateRvContactsOnContactUpdate } from 'features/Contacts/rv-deprecated/contacts.utils'

import {
  useUpdateDebitCardTransaction,
  useUpdateDebitCardTransactionRv,
} from 'hooks/banking'
import { translate } from 'utils/translations'

import {
  createSubcategoryUpdate,
  displayCategoryEditError,
  SubcategoryUpdateVariables,
} from './CategoryPills.utils'
import { EditTransactionZellerCategories } from './EditZellerTransactionCategories/EditTransactionZellerCategories'
import { TransactionEntityCategoryValues } from './EditZellerTransactionCategories/EditTransactionZellerCategories.types'
import { useUpdateCategories } from './hooks/useUpdateCategories/useUpdateCategories'
import { CategoryOptions } from './hooks/useUpdateCategories/useUpdateCategories.types'

type SubcategoryDetails = DebitCardTransactionsV2Fragment['subcategoryDetails']

export interface EditTransactionCategoryPillsProps {
  isModalOpen: boolean
  category: EntityCategories | null
  subcategoryDetails: SubcategoryDetails | null
  updateTransaction: (update: {
    category: EntityCategories | null
    subcategory: string | null | undefined
    subcategoryDetails: SubcategoryDetails | null
  }) => void
  closeModal: () => void
  debitCardTransactionUuid: string
  contactUuid: string | undefined
}

export const EditTransactionCategoryPills = ({
  isModalOpen,
  category,
  subcategoryDetails,
  updateTransaction,
  closeModal,
  debitCardTransactionUuid,
  contactUuid,
}: EditTransactionCategoryPillsProps) => {
  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()
  const { updateTransactionRv } = useUpdateDebitCardTransactionRv()
  const { cacheCurrentUpdatedOutstandingTransaction } =
    useUpdatedOutstandingTransactions(debitCardTransactionUuid)
  const updateTransactionCategories = useCallback(
    (variables: SubcategoryUpdateVariables) => {
      const transactionUpdate = createSubcategoryUpdate(variables)
      cacheCurrentUpdatedOutstandingTransaction({
        update: transactionUpdate,
      })
      updateTransaction(transactionUpdate)
      updateDebitCardTransaction(debitCardTransactionUuid, transactionUpdate)
      updateTransactionRv({
        transactionUuid: debitCardTransactionUuid,
        update: transactionUpdate,
      })
    },
    [
      updateTransaction,
      updateDebitCardTransaction,
      cacheCurrentUpdatedOutstandingTransaction,
      debitCardTransactionUuid,
      updateTransactionRv,
    ]
  )

  const updateContactCategories = useCallback(
    ({
      category: newCategory,
      subcategoryName: newSubcategoryName,
      subcategoryId: newSubcategoryId,
    }: CategoryOptions) => {
      if (contactUuid) {
        updateRvContactsOnContactUpdate(contactUuid, {
          category: newCategory,
          subcategory: newSubcategoryName,
          subcategoryUuid: newSubcategoryId,
        })
      }
    },
    [contactUuid]
  )

  const displaySuccessAndCloseModal = useCallback(() => {
    showSuccessToast(
      translate(
        'page.transactionDetails.zellerCategories.updateCategorySuccess'
      )
    )
    closeModal()
  }, [closeModal])

  const { updateCategories, isLoading } = useUpdateCategories({
    debitCardTransactionUuid,
    contactUuid,
    onUpdateTransactionCategories: updateTransactionCategories,
    onUpdateContactCategories: updateContactCategories,
    onSuccess: displaySuccessAndCloseModal,
    onFailure: displayCategoryEditError,
  })

  const handleSave = useCallback(
    ({
      category: newCategory,
      subcategory,
      subcategoryId,
      applyAll,
    }: TransactionEntityCategoryValues) => {
      const transactionCategoryIsUnchanged =
        newCategory === category &&
        subcategoryId === subcategoryDetails?.id &&
        !applyAll

      if (transactionCategoryIsUnchanged) {
        closeModal()
        return
      }

      updateCategories({
        category: newCategory,
        subcategory,
        subcategoryId,
        applyAll,
      })
    },
    [category, closeModal, subcategoryDetails?.id, updateCategories]
  )

  const handleEditSubcategory = useCallback(
    ({
      category: newCategory,
      subcategory,
      subcategoryId,
    }: TransactionEntityCategoryValues) => {
      if (subcategoryId === subcategoryDetails?.id) {
        updateTransactionCategories({
          category: newCategory,
          subcategoryName: subcategory,
          subcategoryId,
          predefined: false,
        })
      }
    },
    [subcategoryDetails?.id, updateTransactionCategories]
  )

  return isModalOpen ? (
    <EditTransactionZellerCategories
      isOpen={isModalOpen}
      category={category}
      subcategoryDetails={subcategoryDetails}
      onSave={handleSave}
      onCancel={closeModal}
      onEditSubcategory={handleEditSubcategory}
      shouldShowApplyAll={Boolean(contactUuid)}
      isSavingCategories={isLoading}
    />
  ) : null
}
