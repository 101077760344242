import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  EditCardNameLocationStateSchema,
  EditCardNameStage,
} from 'hooks/useGoToEditCardNameStage/useGoToEditCardNameStage.types'

export const useEditCardNameState = () => {
  const locationState = useLocationState(EditCardNameLocationStateSchema)

  const state = useMemo(() => {
    if (
      !locationState?.EditCardNameModal?.stage ||
      locationState.EditCardNameModal.stage !== EditCardNameStage.EditCardName
    ) {
      return null
    }

    return locationState.EditCardNameModal
  }, [locationState?.EditCardNameModal])

  return { state }
}
