import { useEffect, useState } from 'react'

import { FileQueueItem } from 'components/File/File.types'

interface Args {
  succeededFileQueueItems: FileQueueItem[]
  fileQueueItems: FileQueueItem[]
  resetFileQueueItems: () => void
  resetSuccededFileQueueItems: () => void
}

export const useUploadDocument = ({
  succeededFileQueueItems,
  fileQueueItems,
  resetFileQueueItems,
  resetSuccededFileQueueItems,
}: Args) => {
  const [isLoading, setIsLoading] = useState(false)
  const [persitedSubject, setPersistedSubject] = useState('')
  const [failedFileNames, setFailedFailedNames] = useState<string[]>([])

  useEffect(() => {
    const succeededAndFailedFileLength =
      succeededFileQueueItems.length + failedFileNames.length

    if (succeededAndFailedFileLength === fileQueueItems.length) {
      setIsLoading(false)
      resetFileQueueItems()

      if (failedFileNames.length === 0) {
        setPersistedSubject('')
        resetSuccededFileQueueItems()
      }
    }
  }, [
    succeededFileQueueItems.length,
    fileQueueItems.length,
    failedFileNames.length,
    resetFileQueueItems,
    resetSuccededFileQueueItems,
  ])

  return {
    setFailedFailedNames,
    setPersistedSubject,
    setIsLoading,
    isLoading,
    failedFileNames,
    persitedSubject,
  }
}
