import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { Flex } from '@npco/zeller-design-system'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { InvoiceEmptyState } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceEmptyState/InvoiceEmptyState'
import { InvoiceErrorState } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceErrorState/InvoiceErrorState'
import { NoInvoicesFound } from 'features/Invoicing/components/Invoices/InvoiceTable/NoInvoicesFound/NoInvoicesFound'
import { isEqual } from 'lodash-es'
import { useTheme } from 'styled-components'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { usePrevious } from 'hooks/usePrevious'
import { ROOT } from 'const/routes'
import { GetInvoices_getInvoices_invoices as InvoiceType } from 'types/gql-types/GetInvoices'
import { BREAKPOINT } from 'styles/breakpoints'
import { ListLoader } from 'components/Lists'

import { useInvoicesContext } from '../InvoicesContext/InvoicesContext'
import { useInvoiceTableColumns } from './hooks/useInvoiceTableColumns'
import { useInvoiceTableInfiniteLoader } from './hooks/useInvoiceTableInfiniteLoader'
import {
  InvoiceTableContainer,
  InvoiceTableRowContainer,
} from './InvoiceTable.styled'

const initialInvoices = Array(50).fill(null)

export const InvoiceTable = () => {
  const entityUuid = useSelectedEntityUuid()
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const theme = useTheme()

  const isMobileResolution = useIsMobileResolution()
  const isBelowLGBreakpoint = useIsMobileResolution(BREAKPOINT.LG)

  const {
    filterInput,
    getInvoices,
    hasError,
    hasNoResults,
    invoices,
    isDefaultFilters,
    isLoading,
    refetchInvoices,
    selectedAmountColumn,
    selectedDateColumn,
    selectedStatuses,
    sortingObject,
  } = useInvoicesContext()

  const prevSortingObject = usePrevious(sortingObject)
  const prevFilterInput = usePrevious(filterInput)

  const { columns, columnVisibility } = useInvoiceTableColumns({
    isBelowLGBreakpoint,
    isMobileResolution,
    selectedAmountColumn,
    selectedDateColumn,
  })

  const { isLoadingMore, observerContainer } = useInvoiceTableInfiniteLoader()

  useEffect(() => {
    getInvoices({
      variables: {
        entityUuid,
        input: {
          filter: filterInput,
          limit: 50,
          sort: sortingObject,
        },
      },
    })
    // component did mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: only to fetch filter/sort changes immediately on desktop
  useEffect(() => {
    if (isMobileResolution) {
      return
    }

    const hasSortingObjectChanged = !isEqual(prevSortingObject, sortingObject)
    const hasFilterInputChanged = !isEqual(prevFilterInput, filterInput)

    if (prevSortingObject === undefined && prevFilterInput === undefined) {
      return
    }

    if (!hasSortingObjectChanged && !hasFilterInputChanged) {
      return
    }

    refetchInvoices()
  }, [
    filterInput,
    isMobileResolution,
    prevFilterInput,
    prevSortingObject,
    refetchInvoices,
    sortingObject,
  ])

  const table = useReactTable<InvoiceType | null>({
    columns,
    data: invoices ?? initialInvoices,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      filterInput,
      isLoading,
      selectedAmountColumn,
      selectedDateColumn,
      selectedStatuses,
      sortingObject,
      theme,
    },
    state: { columnVisibility },
  })

  const handleTableRowClick = (row: InvoiceType | null) => () => {
    if (row) {
      navigate(
        ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.INVOICE(
          row.referenceNumber
        ).path
      )
    }
  }

  const tableContent = () => {
    if (invoices?.length === 0) {
      return null
    }

    return table.getRowModel().rows.map((row) => (
      <InvoiceTableRowContainer
        data-testid={`invoice-table-row-${row.index}`}
        hasDivider
        key={row.id}
        onClick={handleTableRowClick(row.original)}
      >
        {row.getVisibleCells().map((cell) => {
          return flexRender(cell.column.columnDef.cell, cell.getContext())
        })}
      </InvoiceTableRowContainer>
    ))
  }

  if (hasError) {
    return <InvoiceErrorState onRetry={refetchInvoices} />
  }

  if (isDefaultFilters && hasNoResults && !isLoading) {
    return <InvoiceEmptyState />
  }

  return (
    <Flex
      data-testid="invoicing-invoices-container"
      flexDirection="column"
      flexGrow={1}
    >
      <InvoiceTableContainer>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <InvoiceTableRowContainer
              data-testid="invoice-table-header-row"
              key={headerGroup.id}
              isSticky
            >
              {headerGroup.headers.map((header) =>
                flexRender(header.column.columnDef.header, header.getContext())
              )}
            </InvoiceTableRowContainer>
          ))}
        </thead>
        <tbody>{tableContent()}</tbody>
      </InvoiceTableContainer>
      <div ref={observerContainer} />
      {!isLoading && hasNoResults && <NoInvoicesFound />}
      {isLoadingMore && <ListLoader />}
    </Flex>
  )
}
