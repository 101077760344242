import { BodySmall, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledGreyText = styled(BodySmall)`
  align-self: center;
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`

export const StyledBlueText = styled(BodySmall)`
  align-self: center;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  margin: 0;
`

export const StyledDragAndDropWrapper = styled(Flex)`
  align-content: center;
  justify-content: center;
  flex-direction: column;
`
