import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BodyDefault } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { AuthLayout } from 'layouts'

import { CardHolderDetails } from './CardHolderDetails'
import {
  StyledAgreement,
  StyledContactDetails,
  StyledHeading,
  StyledUnlinkContent,
} from './CardHolderDetailsOptOut.styled'
import { OptOutSuccessPage } from './OptOutSuccessPage'

export const CardHolderDetailsOptOut = () => {
  const { search } = useLocation()
  const [isOptOutSuccessful, setIsOptOutSuccessful] = useState(false)

  const hasEmail = new URLSearchParams(search).has('email')
  const hasPhone = new URLSearchParams(search).has('phone')
  const isContactStored = hasEmail || hasPhone

  if (isOptOutSuccessful) {
    return <OptOutSuccessPage />
  }

  return (
    <AuthLayout isLogInPage>
      <StyledHeading>
        {translate('page.optOutCardHolderDetails.title')}
      </StyledHeading>

      {isContactStored ? (
        <CardHolderDetails setIsOptOutSuccessful={setIsOptOutSuccessful} />
      ) : (
        <>
          <StyledContactDetails>
            <BodyDefault>
              {translate(
                'page.optOutCardHolderDetails.contactDetails.noDetailsStored'
              )}
            </BodyDefault>
          </StyledContactDetails>

          <StyledUnlinkContent>
            {translate('page.optOutCardHolderDetails.confirmDetails.text')}
          </StyledUnlinkContent>
        </>
      )}

      <StyledAgreement>
        {translate('page.optOutCardHolderDetails.agreement')}
      </StyledAgreement>
    </AuthLayout>
  )
}
