import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardAvatarDebitCardV2FragmentDoc } from './CardAvatar/CardAvatar.generated'

export type ZellerCardThumbnailDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  colour: Types.DebitCardColour
  maskedPan: string
}

export const ZellerCardThumbnailDebitCardV2FragmentDoc = gql`
  fragment ZellerCardThumbnailDebitCardV2Fragment on DebitCardV2 {
    ...CardAvatarDebitCardV2Fragment
  }
  ${CardAvatarDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ZellerCardThumbnailDebitCardV2Fragment,
  undefined
>
