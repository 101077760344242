import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import {
  CompleteCardState,
  CompleteCardStateConfigType,
} from './useGoToCompleteCardStage.types'

export const useGoToCompleteCardStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToCompleteCardStage = useCallback(
    (
      state: CompleteCardState | undefined,
      config?: CompleteCardStateConfigType
    ) => {
      navigate(config?.pathname || location.pathname, {
        state: {
          CompleteCardModal: state,
          ModalHasOpenTransition: config?.hasOpenTransition,
        },
      })
    },
    [navigate, location]
  )

  return { goToCompleteCardStage }
}
