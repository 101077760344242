import { Box, Message, MessageSize } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

interface CardTransactionsErrorProps {
  retry: () => void
}

export const CardTransactionsError = ({
  retry,
}: CardTransactionsErrorProps) => {
  const isMobile = useIsMobileResolution()

  return (
    <Box pt={['0', '56px']}>
      <Message
        size={isMobile ? MessageSize.MEDIUM : MessageSize.LARGE}
        title={translate('page.cardSingle.transactionsError.heading')}
        description={
          <>
            <Box textAlign="center">
              {translate('page.cardSingle.transactionsError.subtitle1')}
            </Box>
            <Box textAlign="center">
              {translate('page.cardSingle.transactionsError.subtitle2')}
            </Box>
          </>
        }
        primaryButton={{
          label: translate('page.cardSingle.transactionsError.buttonLabel'),
          onClick: retry,
        }}
        align="center"
      />
    </Box>
  )
}
