import { gql, type TypedDocumentNode } from '@apollo/client'

export type SimDeviceCellFragment = {
  __typename?: 'Sim'
  deviceName: string | null
}

export const SimDeviceCellFragmentDoc = gql`
  fragment SimDeviceCellFragment on Sim {
    deviceName
  }
` as unknown as TypedDocumentNode<SimDeviceCellFragment, undefined>
