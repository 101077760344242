import { TransactionStatus, TransactionType } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { groupBy } from 'ramda'

import dayjs from 'utils/dayjs'
import { TransactionInList, TransactionUpdateData } from 'types/transactions'

import { WithPeriod } from '../Dashboard.types'
import { getCurrentTime } from './timeUtils'

const getIsPurchase = (transaction: TransactionInList) =>
  transaction.type === TransactionType.PURCHASE

const getIsFinalised = (transaction: TransactionInList) =>
  transaction.status === TransactionStatus.APPROVED

const getDoesImpactTotals = (transaction: TransactionInList) =>
  getIsPurchase(transaction) && getIsFinalised(transaction)

export const siteUuidIn =
  (selectedSitesUuid: string[] | undefined) =>
  (transaction: TransactionUpdateData) =>
    selectedSitesUuid ? selectedSitesUuid.includes(transaction.siteUuid) : true

export const mapToTotals =
  <T>(mapToTotal: (transactionUpdate: TransactionInList) => T) =>
  (transactions: TransactionInList[]) =>
    transactions.map(mapToTotal)

export const sumTotalsByPeriod =
  <T>(sumTotals: ([period, totals]: [string, T[]]) => T) =>
  (totalsGroupedByPeriod: Record<string, T[]>) =>
    Object.entries(totalsGroupedByPeriod).map(sumTotals)

export const groupByPeriod = <T extends WithPeriod>(toGroup: T[]) =>
  groupBy((total: T) => total.period as string)(toGroup)

export const getHourlyPeriod = (transaction: TransactionInList) => {
  try {
    return dayjs(transaction.timestamp).startOf('hour').toISOString()
  } catch (err) {
    ErrorLogger.report('[Core] Parse transaction timestamp', err)
    return getCurrentTime().startOf('hour').toISOString()
  }
}

export const mapTransactionToTotal =
  <T>(map: (transaction: TransactionInList) => T) =>
  (transaction: TransactionInList) => {
    if (getDoesImpactTotals(transaction)) {
      return map(transaction)
    }

    return null
  }
