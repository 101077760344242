import { amountFormatter, getWholePartFromCents } from 'utils/common'

const wrapWithParenthesis = (text: string) => `(${text})`

export const formatAmountTriggerLabel = (
  min: number,
  max: number,
  includeDollarSign = false
) => {
  const minStringFormatted = includeDollarSign
    ? getWholePartFromCents(min * 100)
    : amountFormatter(min)
  const maxStringFormatted =
    includeDollarSign && max < 0
      ? getWholePartFromCents(max * 100)
      : amountFormatter(max)

  const minWithParenthesis =
    min < 0 ? wrapWithParenthesis(minStringFormatted) : minStringFormatted
  const maxWithParenthesis =
    max < 0 ? wrapWithParenthesis(maxStringFormatted) : maxStringFormatted

  return `${minWithParenthesis}–${maxWithParenthesis}`
}
