import 'dayjs/locale/en-au'

import {
  DEFAULT_LOCALE,
  useLocaleContext,
  useTranslations,
  UseTranslationsFn,
} from '@npco/utils-translations'

import dayjs from 'utils/dayjs'

import { relativeTimeTranslations } from '../../translations/shared.i18n'

export const daysToWeeks = (
  day: number,
  t: UseTranslationsFn<typeof relativeTimeTranslations>
) => {
  if (day > 6) {
    return `${Math.round(day / 7)}${t('w')}`
  }
  return `${day}${t('dd')}`
}

export const useDayjs = () => {
  const { locale } = useLocaleContext()
  const t = useTranslations(relativeTimeTranslations)

  dayjs.updateLocale(locale ?? DEFAULT_LOCALE, {
    relativeTime: {
      future: t('future'),
      past: t('past'),
      s: t('s'),
      m: t('m'),
      mm: t('mm'),
      h: t('h'),
      hh: t('hh'),
      d: t('d'),
      // NOTE: dayjs doesn't support weeks, so we use additional token processing
      // for the `dd` token to convert days to weeks when days > 6.
      dd: (number: number) => daysToWeeks(number, t),
      M: t('M'),
      MM: t('MM'),
      y: t('y'),
      yy: t('yy'),
    },
  })

  dayjs.locale(locale ?? DEFAULT_LOCALE)
}
