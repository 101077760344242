import { ContactHeading } from '../ContactHeading/ContactHeading'
import { ContactDisplay } from './ContactDisplay/ContactDisplay'
import { useContactDetailsQuery } from './hooks/useContactDetailsQuery/useContactDetailsQuery'
import { useContactHeadingQuery } from './hooks/useContactHeadingQuery/useContactHeadingQuery'

type ContactDetailsProps = {
  contactUuid: string
}

export const ContactDetails = ({ contactUuid }: ContactDetailsProps) => {
  const {
    contact: contentData,
    isLoadingContact: isLoadingContent,
    error: contentError,
    refetch: refetchContent,
  } = useContactDetailsQuery({
    contactUuid,
  })
  const { contact: headingData } = useContactHeadingQuery({
    contactUuid,
  })

  return (
    <>
      {!contentError && headingData && <ContactHeading contact={headingData} />}
      <ContactDisplay
        contact={contentData}
        isLoading={isLoadingContent}
        error={contentError}
        refetch={refetchContent}
      />
    </>
  )
}
