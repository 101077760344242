import { useCallback, useContext, useEffect } from 'react'
import { useAuthContext } from 'auth/AuthContext'
import { useAppStateMapper } from 'auth/useAppStateMapper'
import { getIsValidAppState } from 'features/MFA'
import { MFAContext } from 'features/MFA/MFAContext/MFAContext'

import {
  AuthScope,
  isDepositsAppState,
  isProfileSettingsAppState,
  RedirectAppState,
} from 'types/auth'

export const useAuth0Provider = () => {
  const mfaContext = useContext(MFAContext)
  const { updateScope, scope, setRedirectTo } = useAuthContext()

  const { mapAuthObjectToDepositState, mapAuthObjectToProfileSettingsState } =
    useAppStateMapper()

  // scope from redirect
  const onRedirectCallback = useCallback(
    (data: RedirectAppState) => {
      if (data?.scope) {
        updateScope(data.scope)
      }

      if (data && isDepositsAppState(data)) {
        mapAuthObjectToDepositState(data)
      }
      if (data && isProfileSettingsAppState(data)) {
        mapAuthObjectToProfileSettingsState(data)
      }

      if (data && getIsValidAppState(data)) {
        mfaContext?.setAppState(data)
        mfaContext?.setHasRedirectedBackToApp(true)
      }

      if (data?.returnTo) {
        setRedirectTo(data?.returnTo)
      }
    },
    [
      mapAuthObjectToDepositState,
      mapAuthObjectToProfileSettingsState,
      setRedirectTo,
      updateScope,
      mfaContext,
    ]
  )

  useEffect(() => {
    // scenario when user interrupted the redirect flow and goes back to app, isAfterRedirect will be true, have to "reset" app state
    const timeout = setTimeout(() => {
      if (scope === undefined) {
        updateScope(AuthScope.OFFLINE)
      }
    }, 2000)

    return () => clearTimeout(timeout)
  })

  return {
    onRedirectCallback,
  }
}
