import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import {
  InvoiceFormFields,
  PaymentTerms,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'

import dayjs from 'utils/dayjs'

import {
  getDueDateByInvoiceDateAndPaymentTerms,
  isPastDate,
  isValidDate,
} from '../InvoiceScheduleAccordion.utils'

export const getNewScheduleFieldValues =
  (newInvoiceDate: string, paymentTerms: PaymentTerms | null) =>
  (prevValues: InvoiceFormFields) => {
    if (
      !isValidDate(newInvoiceDate) ||
      !paymentTerms ||
      paymentTerms === PaymentTerms.Custom
    ) {
      return {
        ...prevValues,
        schedule: {
          ...prevValues.schedule,
          invoiceDate: newInvoiceDate,
        },
      }
    }

    const isPastInvoiceDate = isPastDate(newInvoiceDate)

    const newDueDate = isPastInvoiceDate
      ? dayjs().format(DATE_FULL_DISPLAY_FORMAT)
      : getDueDateByInvoiceDateAndPaymentTerms(newInvoiceDate, paymentTerms)

    const newPaymentTerms = isPastInvoiceDate
      ? PaymentTerms.Custom
      : prevValues.schedule.paymentTerms

    return {
      ...prevValues,
      schedule: {
        ...prevValues.schedule,
        dueDate: newDueDate,
        invoiceDate: newInvoiceDate,
        paymentTerms: newPaymentTerms,
      },
    }
  }
