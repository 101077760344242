import { useParams } from 'react-router-dom-v5-compat'

import { ROUTE_PARAM_NAME } from 'const/routes'

export const ERROR_MESSAGE =
  'useAccountId should be only used when URL has an AccountId'

export const useAccountId = () => {
  const { accountId } = useParams<ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID>()

  if (!accountId) {
    throw new Error(ERROR_MESSAGE)
  }

  return { accountId }
}
