import {
  Day,
  POPPER_ZINDEX,
  TooltipBasic,
  TriggerProps,
} from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

import { useDateFilterDatepicker } from '../../../hooks/useDateFilterDatepicker/useDateFilterDatepicker'
import { DatePickerRangeForFilter } from '../../DatePickerRangeForFilter'
import { StyledAccountFilterDateContent } from './AccountFilterDateContent.styled'

interface Props {
  isOpen: boolean
  focusTrigger: () => void
}

export const AccountFilterDateContent = ({ isOpen, focusTrigger }: Props) => {
  const { date, setDate, resetDate } = useAccountFilters()
  const {
    data,
    enteredTo,
    selectedPeriod,
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleClearKeyDown,
    onPeriodChange,
    disabledDays,
  } = useDateFilterDatepicker({
    focusTrigger,
    isOpen,
    date,
    setDate,
    resetDate,
  })

  return (
    <StyledAccountFilterDateContent>
      <DatePickerRangeForFilter
        data={data}
        enteredTo={enteredTo}
        selectedPeriod={selectedPeriod}
        handleResetClick={handleResetClick}
        handleDayClick={handleDayClick}
        handleDayMouseEnter={handleDayMouseEnter}
        handleDayMouseLeave={handleDayMouseLeave}
        onPeriodChange={onPeriodChange}
        handleClearKeyDown={handleClearKeyDown}
        disabledDays={disabledDays}
        renderDay={(renderDate, modifiers) => {
          if (modifiers.disabled && disabledDays) {
            return (
              <TooltipBasic
                renderTrigger={({ handlers }) => (
                  <Day {...(handlers as TriggerProps<HTMLDivElement>)}>
                    {dayjs(renderDate).format('D')}
                  </Day>
                )}
                showArrow={false}
                placement="top"
                zIndex={Number.parseInt(POPPER_ZINDEX, 10)}
              >
                {translate('component.datePicker.rangeLimit')}
              </TooltipBasic>
            )
          }

          return <Day>{dayjs(renderDate).format('D')}</Day>
        }}
      />
    </StyledAccountFilterDateContent>
  )
}
