import { H2 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDashboardLayoutContent = styled.div`
  height: 100%;
`

export const StyledTitle = styled(H2).attrs({
  'data-testid': 'dashboard-layout-title',
})`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK};
`

export const StyledContainer = styled.span`
  & > a {
    color: ${({ theme }) => theme.colors.BLUE_1000};
    text-decoration: initial;
  }
`

export const StyledIconWrapper = styled.span`
  padding: 0 10px;
`

export const StyledMaxWidthContainer = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  background: ${({ theme }) => theme.colors.WHITE};
`

export const StyledFullWidthBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  background: #f0f0f0;
`
