import { useEffect } from 'react'
import { useFetchSetupData } from 'auth/useFetchSetupData'

export const usePortalRoutesSetup = () => {
  const {
    fetchSetupData,
    isFetchSetupDataCalled,
    fetchSetupDataError,
    isFetchSetupDataLoading,
  } = useFetchSetupData()

  useEffect(() => {
    fetchSetupData().catch(() => undefined)
  }, [fetchSetupData])

  return {
    fetchSetupData,
    fetchSetupDataError,
    isFetchSetupDataLoading: isFetchSetupDataLoading || !isFetchSetupDataCalled,
  }
}
