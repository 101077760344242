import { createTranslations } from '@npco/utils-translations'

export const loginErrorTranslation = createTranslations({
  title: 'Something went wrong',
  disabledAccountTitle: 'This account is disabled',
  inactiveAccountTitle: 'This account is inactive',
  subTitle: "We couldn't load the page due to an unexpected error.",
  description1:
    'If the problem persists, please contact the Zeller Support Team on 1800 935 537 (7 days a week).',
  description2:
    'Please contact a member of our team on 1800 935 537 for further information.',
})
