import { CustomerAddressInput } from '@npco/mp-gql-types'
import { useWatchOnboardingSaveData } from 'features/OnboardingApp/shared/useWatchOnboardingSaveData'
import { useFormikContext } from 'formik'

import { parseToSaveFullDate } from 'utils/date'
import { Roles } from 'types/customers'
import { AddCustomerForm } from 'forms/formViews/AddCustomerForm/AddCustomerForm'

export type TrustAddCustomerFormValues = CustomerAddressInput & {
  isBeneficialOwner?: boolean
  firstName?: string
  middleName?: string
  lastName?: string
  dob?: string
}

export const TrustAddCustomerForm = () => {
  const { values } = useFormikContext<TrustAddCustomerFormValues>()
  const { isBeneficialOwner, ...restValues } = values

  useWatchOnboardingSaveData({
    initialCustomerData: {
      roles: isBeneficialOwner ? [Roles.beneficialOwner] : [],
      ...restValues,
      dob: parseToSaveFullDate(restValues.dob),
    },
  })

  return <AddCustomerForm />
}
