import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  rvIsUserSavingOnboarding,
  rvKYCData,
  rvOnboardingDataSummary,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { OnboardingDataSummary } from 'types/onboarding'

import { convertOnboardingDataToKyc } from './useWatchOnboardingSaveData.utils'

export const useWatchOnboardingSaveData = (data: OnboardingDataSummary) => {
  const isUserSavingOnboarding = useReactiveVar(rvIsUserSavingOnboarding)

  useEffect(() => {
    if (isUserSavingOnboarding) {
      rvOnboardingDataSummary({
        ...rvOnboardingDataSummary(),
        ...data,
      })

      rvKYCData(convertOnboardingDataToKyc(rvKYCData(), data))
      rvIsUserSavingOnboarding(false)
    }
  }, [isUserSavingOnboarding, data])
}
