import styled, { css } from 'styled-components'

export const InvoiceTableContainer = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`

export const InvoiceTableRowContainer = styled.tr<{
  hasDivider?: boolean
  isRowSelected?: boolean
  isSticky?: boolean
}>`
  cursor: pointer;
  box-shadow: ${({ hasDivider, theme }) =>
    hasDivider && `inset 0px -1px 0px ${theme.colors.GREY_90}`};

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
    `}

  &.selected {
    background-color: ${({ theme }) => theme.colors.BLUE_50};
    box-shadow: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.GREY_30};
    }
  }

  &:hover {
    ${({ hasDivider }) =>
      hasDivider &&
      css`
        background-color: ${({ theme }) => theme.colors.GREY_30};
        box-shadow: none;
      `}
  }
`
