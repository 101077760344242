import { useEffect } from 'react'
import { DocumentUploadedType } from '@npco/mp-gql-types'
import { useFormikContext } from 'formik'

import { Values } from '../../Form.types'

export const useConsentToViewField = () => {
  const { errors, values, setFieldValue } = useFormikContext<Values>()
  const hasIDDocumentType =
    values?.fileItem &&
    Object.values(values.fileItem).find(
      (item) => item?.value === DocumentUploadedType.ID_DOCUMENT
    )

  useEffect(() => {
    if (hasIDDocumentType) {
      return
    }

    setFieldValue('consentToViewDocument', undefined)
  }, [hasIDDocumentType, setFieldValue])

  return {
    error: errors.consentToViewDocument,
    hasIDDocumentType,
  }
}
