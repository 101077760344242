import React, { ReactNode } from 'react'

import { ZellerAuthentication } from './auth/ZellerAuthentication'

interface RenderRootProps {
  children: ReactNode
}

export const RenderRoot = ({ children }: RenderRootProps) => {
  const urlParams = new URLSearchParams(window.location.search)

  const mockedAccessToken =
    urlParams.get('mockedAccessToken') ||
    window.localStorage.getItem('MOCKED_ACCESS_TOKEN') ||
    ''

  const mockedCustomerUuid =
    urlParams.get('mockedCustomerUuid') ||
    window.localStorage.getItem('MOCKED_CUSTOMER_UUID') ||
    ''

  if (mockedAccessToken && mockedCustomerUuid) {
    const ZellerAuthenticationMocked = React.lazy(
      () => import('mockData/ZellerAuthenticationMocked')
    )

    return (
      <React.Suspense fallback={<></>}>
        <ZellerAuthenticationMocked>{children}</ZellerAuthenticationMocked>
      </React.Suspense>
    )
  }

  return <ZellerAuthentication>{children}</ZellerAuthentication>
}
