import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import {
  ErrorMessageForm,
  InputSelectComboboxItemRenderProps,
  InputSelectComboboxStandard,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { UseComboboxStateChange } from 'downshift'
import { useFormikContext } from 'formik'

import {
  BsbPaymentInstrument,
  useBsbPaymentInstruments,
} from 'hooks/paymentInstruments/useBsbPaymentInstruments'
import { translate } from 'utils/translations'

import { InputComboBoxBankDetailsItem } from './BankAccounts.types'
import { createBankAccountItem } from './BankAccounts.utils'
import {
  StyledAccountName,
  StyledBankDetails,
  StyledInputSelectComboboxItem,
} from './BankAccountsModal.styled'

export interface BankAccountsInputSelectComboboxProps {
  contactId: string
  selectedItem: InputComboBoxBankDetailsItem | null
  setSelectedItem: Dispatch<SetStateAction<InputComboBoxBankDetailsItem | null>>
  setSelectedPaymentInstrument: Dispatch<
    SetStateAction<BsbPaymentInstrument | null>
  >
  setValidationError: (validationError?: string) => void
  validationError?: string
}

export const BankAccountsInputSelectCombobox = ({
  contactId,
  selectedItem,
  setSelectedItem,
  setSelectedPaymentInstrument,
  validationError,
  setValidationError,
}: BankAccountsInputSelectComboboxProps) => {
  const { resetForm, setFieldValue } = useFormikContext()

  const { paymentInstruments, loading: isLoadingPaymentInstruments } =
    useBsbPaymentInstruments()

  const [inputValue, setInputValue] = useState('')

  const items: InputComboBoxBankDetailsItem[] = paymentInstruments
    .filter((item) => item.contactUuid !== contactId)
    .map((item) => {
      const { bsb, account, name } = item.details

      return createBankAccountItem({ bsb, account, name, id: item.id })
    })

  const getFilterItems = useCallback(
    ({ label, data: details }: InputComboBoxBankDetailsItem) => {
      const inputFormatted = inputValue.trim().toUpperCase()

      return Boolean(
        label.toUpperCase().includes(inputFormatted) ||
          details?.account?.includes(inputFormatted) ||
          details?.bsb?.includes(inputFormatted)
      )
    },
    [inputValue]
  )

  const onChange = (
    changes: SelectStateChangeProps<InputComboBoxBankDetailsItem>
  ) => {
    setSelectedItem(changes.selectedItem)

    const paymentInstrument = paymentInstruments.find(
      (payment) => payment.id === changes.selectedItem?.id
    )

    if (paymentInstrument) {
      setSelectedPaymentInstrument(paymentInstrument)
    }

    setFieldValue('bsb', paymentInstrument?.details?.bsb || '')
    setFieldValue('accountNumber', paymentInstrument?.details?.account || '')
    setFieldValue('accountName', paymentInstrument?.details?.name || '')
    setFieldValue('status', paymentInstrument?.status || '')
  }

  const onClose = (
    changes: UseComboboxStateChange<InputComboBoxBankDetailsItem>
  ) => {
    // NOTE: when a user has an item, changes the input value and navigates away
    // from the menu causing the menu to close reset the input value to that of
    // the selected item
    if (changes.selectedItem) {
      if (changes.selectedItem.value !== changes.inputValue) {
        setInputValue(changes.selectedItem.label)
      }
    }

    if (changes.selectedItem && changes.inputValue) {
      setValidationError(undefined)
    }

    // NOTE: if no selected item and input value validate as selection required
    if (!changes.selectedItem && changes.inputValue) {
      setValidationError(
        translate(
          'page.contact.sections.bankAccounts.accountNameSelectionValidation'
        )
      )
    }

    // NOTE: if no selected item and no input value validate as required
    if (!changes.selectedItem && !changes.inputValue) {
      setValidationError(
        translate(
          'page.contact.sections.bankAccounts.accountNameRequiredValidation'
        )
      )
    }
  }

  const onInputChange = (
    changes: SelectStateInputChangeProps<InputComboBoxBankDetailsItem>
  ) => {
    if (changes.inputValue === '') {
      resetForm()
      setSelectedPaymentInstrument(null)
      setSelectedItem(null)
    }

    setInputValue(changes.inputValue)
  }

  const onInputClear = () => {
    setInputValue('')
    setSelectedPaymentInstrument(null)
    setSelectedItem(null)
  }

  const renderItem = (
    renderProps: InputSelectComboboxItemRenderProps<InputComboBoxBankDetailsItem>
  ) => {
    const renderPropWithCorrectKey = {
      ...renderProps,
      key: renderProps.item.id,
    }
    return (
      <StyledInputSelectComboboxItem {...renderPropWithCorrectKey}>
        <StyledAccountName>{renderProps.item.label}</StyledAccountName>
        <StyledBankDetails>{renderProps.item.subLabel}</StyledBankDetails>
      </StyledInputSelectComboboxItem>
    )
  }

  return (
    <>
      <InputSelectComboboxStandard<InputComboBoxBankDetailsItem>
        addNewItemContent={translate('page.contacts.form.createNew')}
        filterItems={getFilterItems}
        hasError={Boolean(validationError)}
        inputValue={inputValue}
        isLoading={isLoadingPaymentInstruments}
        items={items}
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onInputClear={onInputClear}
        placeholder={translate(
          'page.contact.sections.bankAccounts.inputPlaceholder'
        )}
        renderItem={renderItem}
        selectedItem={selectedItem}
      />
      <ErrorMessageForm
        hasError={Boolean(validationError)}
        errorMessage={validationError}
      />
    </>
  )
}
