import { ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledViewReportLink = styled(ButtonLink)`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`
