import { forwardRef } from 'react'
import { SelectTriggerPill } from '@npco/zeller-design-system'

import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg'
import { formatWeekdayDate } from 'utils/date'
import {
  DateRange,
  DEFAULT_DATE,
} from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { SelectPill } from 'layouts/AccountLayout/SelectPill/SelectPill'
import { page } from 'translations'

interface Props {
  onClear: () => void
  onToggleOpen: () => void
  date: DateRange | null
  isOpen: boolean
}

export const AccountFilterDateTrigger = forwardRef<HTMLButtonElement, Props>(
  ({ onClear, onToggleOpen, date, isOpen }, ref) => {
    if (date !== DEFAULT_DATE) {
      return (
        <SelectPill
          ref={ref}
          icon={<CalendarIcon />}
          onClick={onToggleOpen}
          onClose={onClear}
        >
          {formatWeekdayDate(date.from.toString())}-
          {formatWeekdayDate(date.to.toString())}
        </SelectPill>
      )
    }

    return (
      <SelectTriggerPill
        ref={ref}
        hasChevron
        isOpen={isOpen}
        onClick={onToggleOpen}
      >
        {page.accounts.transactionsList.filters.date}
      </SelectTriggerPill>
    )
  }
)
