import { translate } from 'utils/translations'
import { GetXeroSurchargeSettings_getXeroSiteSettings_surchargesTaxes_feesSurchargeXinv as XeroInvoiceFees } from 'types/gql-types/GetXeroSurchargeSettings'
import { GetZellerSurchargeSettings_getZellerInvoiceSettings_surchargesTaxes_feesSurchargeZinv as ZellerInvoiceFees } from 'types/gql-types/GetZellerSurchargeSettings'

import * as styled from '../FeesAndPricing.styled'
import { getTotalFee } from '../FeesAndPricing.utils'

interface InvoiceFeesInfoProps {
  fees: XeroInvoiceFees | ZellerInvoiceFees
}

export const InvoiceFeesInfo = ({ fees }: InvoiceFeesInfoProps) => {
  const { feeFixed, feeFixedIntl, feePercent, feePercentIntl } = fees
  const localCardsFee = getTotalFee({
    feeFixed,
    feePercent,
  })

  const internationalCardsFee = getTotalFee({
    feeFixed: feeFixedIntl,
    feePercent: feePercentIntl,
  })

  return (
    <styled.Table data-testid="invoiceTable">
      <styled.TableCell className="leftCell topCell">
        {translate('page.settings.feesAndPricing.table.onlinePaymentsLocal')}
      </styled.TableCell>
      <styled.TableCell className="topCell rightCell">
        {localCardsFee || '-'}
      </styled.TableCell>
      <styled.TableCell className="leftCell">
        {translate(
          'page.settings.feesAndPricing.table.onlinePaymentsInternational'
        )}
      </styled.TableCell>
      <styled.TableCell className="rightCell">
        {internationalCardsFee || '-'}
      </styled.TableCell>
    </styled.Table>
  )
}
