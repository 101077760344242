import {
  ButtonLink,
  DataSmallTypography,
  Flex,
  Heading,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceAmountErrorText = styled.div`
  ${DataSmallTypography}
  color: ${({ theme }) => theme.colors.RED_1000};
  padding-top: 4px;
  text-align: right;
`

export const InvoiceSubtotalButton = styled(ButtonLink)`
  height: 20px;
`

export const InvoiceSubtotalText = styled.div`
  ${DataSmallTypography}
  ${InterZellerMediumFont}
  height: 20px;
`

export const InvoiceSubtotalValue = styled.div`
  ${DataSmallTypography}
  ${InterZellerMediumFont}

  height: 20px;
  text-align: right;
  min-width: 70px;
`

export const InvoiceTotalsWrapper = styled(Flex)`
  flex-direction: row;
  grid-gap: 12px;
`

export const InvoiceTotalText = styled(Heading.H4)<{ $hasError: boolean }>`
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.colors.RED_1000 : 'inherit'};
  padding-top: 2px;
`

export const InvoiceTotalValue = styled(Heading.H4)<{ $hasError: boolean }>`
  color: ${({ $hasError, theme }) =>
    $hasError ? theme.colors.RED_1000 : 'inherit'};
  text-align: right;
  min-width: 70px;
  padding-top: 2px;
  padding-left: 8px;
`
