import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { RemoveThirdPartyBankAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/deposits'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  RemoveThirdPartyBankAccount as RemoveThirdPartyBankAccountResponse,
  RemoveThirdPartyBankAccountVariables,
} from 'types/gql-types/RemoveThirdPartyBankAccount'
import { page } from 'translations'

export const useDeleteCustomer = (
  redirect: () => void,
  selectedAccountId: string
) => {
  const entityUuid = useSelectedEntityUuid()
  const [deleteExternalAccount, { loading: loadingDeleteExternalAccount }] =
    useMutation<
      RemoveThirdPartyBankAccountResponse,
      RemoveThirdPartyBankAccountVariables
    >(RemoveThirdPartyBankAccount, {
      variables: {
        entityUuid,
        thirdPartyBankAccountUuid: selectedAccountId,
      },
      onCompleted: (result) => {
        if (result.removeThirdPartyBankAccount) {
          rvThirdPartyAccounts(
            rvThirdPartyAccounts().filter(
              (account) => account.id !== selectedAccountId
            )
          )
          redirect()
          showSuccessToast(page.settings.deposits.deleteAccount.deleteSuccess)
        } else {
          showApiErrorToast()
        }
      },
      onError: (error) => {
        showApiErrorToast(error)
      },
    })

  return {
    deleteExternalAccount,
    loadingDeleteExternalAccount,
  }
}
