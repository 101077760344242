import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  XeroPaymentServices as XeroPaymentServicesResponse,
  XeroPaymentServicesVariables,
} from 'types/gql-types/XeroPaymentServices'

import { XeroPaymentServices } from './XeroPaymentServices.graphql'

export const useXeroPaymentServices = () => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data, error } = useQuery<
    XeroPaymentServicesResponse,
    XeroPaymentServicesVariables
  >(XeroPaymentServices, { variables: { entityUuid } })

  return {
    isLoading: loading,
    xeroPaymentServices: data?.xeroPaymentServices,
    error,
  }
}
