import { gql, type TypedDocumentNode } from '@apollo/client'

export type QuantityCellCashFlowCategoryNetAmountFragment = {
  __typename?: 'CashFlowCategoryNetAmount'
  noOfTransaction: number
}

export type QuantityCellCashFlowContactNetAmountFragment = {
  __typename?: 'CashFlowContactNetAmount'
  noOfTransaction: number
}

export const QuantityCellCashFlowCategoryNetAmountFragmentDoc = gql`
  fragment QuantityCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
    noOfTransaction
  }
` as unknown as TypedDocumentNode<
  QuantityCellCashFlowCategoryNetAmountFragment,
  undefined
>
export const QuantityCellCashFlowContactNetAmountFragmentDoc = gql`
  fragment QuantityCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    noOfTransaction
  }
`
