import { useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import {
  GetScreensaverLogoUploadDetails,
  GetScreensaverLogoUploadDetailsQueryResponse,
  GetScreensaverLogoUploadDetailsQueryVariables,
} from '../graphql/getScreensaverLogoUploadDetails.generated'

export const useGetScreensaverLogoUploadDetails = () => {
  const tShared = useTranslations(translationsShared)

  const [getScreensaverLogoUploadDetails, { data, loading, error }] =
    useLazyQuery<
      GetScreensaverLogoUploadDetailsQueryResponse,
      GetScreensaverLogoUploadDetailsQueryVariables
    >(GetScreensaverLogoUploadDetails, {
      onError: () => {
        showErrorToast(tShared('somethingWentWrong'))
      },
      fetchPolicy: 'network-only',
    })

  const screensaverLogoUploadDetails = useMemo(
    () => data?.getScreensaverLogoUploadDetails,
    [data?.getScreensaverLogoUploadDetails]
  )

  return {
    data,
    screensaverLogoUploadDetails,
    getScreensaverLogoUploadDetails,
    hasError: Boolean(error),
    isLoading: loading,
  }
}
