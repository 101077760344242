import { useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { SaveNote } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/debitCardTransactions'

import {
  SaveNote as SaveNoteResponse,
  SaveNoteVariables,
} from 'types/gql-types/SaveNote'

interface Props {
  transactionUuid: string
  onSuccess: (note: string) => void
  onError: (error?: ApolloError) => void
}

export const useTransactionNotes = ({
  transactionUuid,
  onSuccess,
  onError,
}: Props) => {
  const entityUuid = useSelectedEntityUuid()
  const [saveNote, { loading }] = useMutation<
    SaveNoteResponse,
    SaveNoteVariables
  >(SaveNote, {
    onError,
  })

  const handleSaveNote = useCallback(
    async (newNote: string) => {
      const result = await saveNote({
        variables: {
          debitCardTransactionUuid: transactionUuid,
          note: newNote,
          entityUuid,
        },
      })

      if (
        !result.errors &&
        result.data?.updateDebitCardTransactionAnnotations
      ) {
        onSuccess(newNote)
      } else if (!result.errors) {
        onError()
      }
    },
    [onSuccess, saveNote, transactionUuid, onError, entityUuid]
  )
  return { saveNote: handleSaveNote, isLoading: loading }
}
