import { Flex } from '@npco/zeller-design-system'

import { GetXeroSurchargeSettings_getXeroSiteSettings_surchargesTaxes_feesSurchargeXinv as XeroInvoiceFees } from 'types/gql-types/GetXeroSurchargeSettings'
import { GetZellerSurchargeSettings_getZellerInvoiceSettings_surchargesTaxes_feesSurchargeZinv as ZellerInvoiceFees } from 'types/gql-types/GetZellerSurchargeSettings'

import { XeroFeeContainer } from './XeroFeeContainer'
import { ZellerFeeContainer } from './ZellerFeeContainer'

export interface InvoiceFeesAndPricingContainerProps {
  xeroFees?: XeroInvoiceFees
  zellerFees?: ZellerInvoiceFees
}

export const InvoiceFeesAndPricingContainer = ({
  xeroFees,
  zellerFees,
}: InvoiceFeesAndPricingContainerProps) => {
  return (
    <Flex flexDirection="column">
      <XeroFeeContainer xeroFees={xeroFees} />
      <ZellerFeeContainer zellerFees={zellerFees} />
    </Flex>
  )
}
