import { BodySmallTypography, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledTooltipWrapper as TooltipWrapper } from 'components/Charts/Shared/Tooltip/Tooltip.styled'

export const StyledTooltipWrapper = styled(TooltipWrapper)`
  padding: 0;
`

export const StyledTooltipHeader = styled.div`
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.GREY_250};
  white-space: nowrap;
  margin-bottom: 0.25rem;
  text-align: center;
`

export const StyledTooltipSection = styled(Flex)`
  padding: 0.5rem;
`

interface DotProps {
  color: string
}

export const StyledDot = styled.span`
  margin: 0.25rem 0.5rem 0rem 0rem;
  height: 12px;
  width: 12px;
  background-color: ${({ color }: DotProps) => color};
  border-radius: 50%;
  display: inline-block;
`

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_400};
  border-left: 1px solid transparent;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    border-bottom: 1px solid transparent;
    border-left: 1px solid ${({ theme }) => theme.colors.GREY_400};
  }
`

export const StyledTooltipLayout = styled(Flex)`
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
  }
`
