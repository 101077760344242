import {
  Box,
  COLOR,
  Flex,
  Lightbox,
  Typography,
} from '@npco/zeller-design-system'

import { ReactComponent as ChevronLeftIcon } from 'assets/svg/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg'

interface MobileImageControlProps {
  areImageControlsEnabled: boolean
  onPrevious: () => void
  onNext: () => void
  indexText: React.ReactNode
}

export const MobileImageControl = ({
  areImageControlsEnabled,
  onPrevious,
  onNext,
  indexText,
}: MobileImageControlProps) => {
  return (
    <Box
      width="100%"
      marginTop="20px"
      marginBottom="32px"
      display={{ _: 'flex', XS: 'none' }}
      justifyContent="center"
    >
      {areImageControlsEnabled && (
        <Flex flex="1" justifyContent="flex-start">
          <Lightbox.Button onClick={onPrevious} icon={ChevronLeftIcon} />
        </Flex>
      )}
      <Typography variant="heading-sm" color={COLOR.WHITE}>
        {indexText}
      </Typography>
      {areImageControlsEnabled && (
        <Flex flex="1" justifyContent="flex-end">
          <Lightbox.Button onClick={onNext} icon={ChevronRightIcon} />
        </Flex>
      )}
    </Box>
  )
}
