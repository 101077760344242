import { Navigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { ROOT } from 'const/routes'

export const CardsActivateRoutes = () => {
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Navigate
      to={ROOT.ORGS.ORG(shortEntityId).CARDS.DEBIT.path}
      state={{
        AddCardModal: {
          stage: AddCardStage.LinkCardIdStage,
        },
      }}
      replace
    />
  )
}
