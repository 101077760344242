import { Box } from '@npco/zeller-design-system'
import { OnboardingLayout } from 'features/OnboardingApp/OnboardingLayout'
import { OnboardingPageLayout } from 'features/OnboardingApp/OnboardingPageLayout'
import { Formik } from 'formik'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { useRegisterPhone } from 'components/RegisterPhone/useRegisterPhone'

import { PhoneInputField } from './PhoneInputField/PhoneInputField'

export const RegisterPhone = () => {
  const { handleSubmit, loading, error } = useRegisterPhone({
    url: ROOT.VALIDATE_CODE.path,
  })

  return (
    <OnboardingLayout data-testid="register-phone">
      <Formik
        initialValues={{
          phone: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm }) => {
          return (
            <OnboardingPageLayout
              isLoading={loading}
              title={translate('page.registerPhone.headline')}
              description={translate('page.registerPhone.copy')}
              nextButtonLabel={translate('shared.submit')}
              confirmDisabled={isSubmitting || !!error}
              onConfirmClick={submitForm}
              isBackButtonHidden
            >
              <Box mb="2.5rem">
                <PhoneInputField error={error} />
              </Box>
            </OnboardingPageLayout>
          )
        }}
      </Formik>
    </OnboardingLayout>
  )
}
