import type { RefObject } from 'react'
import { useCallback, useEffect, useState } from 'react'

export const useIsResourceElementLoaded = ({
  elementRef,
}: {
  elementRef: RefObject<HTMLIFrameElement | HTMLImageElement>
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const setElementLoaded = useCallback(() => {
    setIsLoaded(true)
  }, [setIsLoaded])

  useEffect(() => {
    const elementCurrent = elementRef?.current
    // load fired when resources fetched
    elementCurrent?.addEventListener('load', setElementLoaded)
    return () => {
      elementCurrent?.removeEventListener('load', setElementLoaded)
    }
  }, [elementRef, setElementLoaded])

  return { isLoaded }
}
