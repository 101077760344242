import { translate } from 'utils/translations'
import { DEFAULT_DESCRIPTION } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { FiltersListMobileListItem } from 'components/FiltersList/FiltersListMobileListItem'

import { StyledSelectedValueWrapper } from '../../FilterMobileSelectTrigger/FilterMobileSelectTrigger.styled'

interface Props {
  openPopper: () => void
  description: string
}

export const AccountMobileFilterDescriptionTrigger = ({
  openPopper,
  description,
}: Props) => {
  const areFiltersDefault = description === DEFAULT_DESCRIPTION

  if (!areFiltersDefault) {
    const label = (
      <span>
        {translate('page.accounts.transactionsList.filters.description')}{' '}
        <StyledSelectedValueWrapper data-testid="account-mobile-filter-description-trigger-value">
          ({description})
        </StyledSelectedValueWrapper>
      </span>
    )

    return <FiltersListMobileListItem label={label} onClick={openPopper} />
  }

  return (
    <FiltersListMobileListItem
      label={translate('page.accounts.transactionsList.filters.description')}
      onClick={openPopper}
    />
  )
}
