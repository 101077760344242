import {
  BodySmall,
  Box,
  ButtonFill,
  Flex,
  Heading,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface PinTokenErrorProps {
  retry: () => void
}

export const PinTokenError = ({ retry }: PinTokenErrorProps) => {
  return (
    <Flex
      width="100%"
      height="280px"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
    >
      <Box textAlign="center">
        <Heading.H3>{translate('errorMessages.smthWentWrong')}</Heading.H3>
        <BodySmall>{translate('page.setPinModal.error.subtitle')}</BodySmall>
        <Flex justifyContent="center">
          <ButtonFill onClick={retry}>
            {translate('shared.tryAgain')}
          </ButtonFill>
        </Flex>
      </Box>
    </Flex>
  )
}
