import { SelectLoadingPlaceholderRenderProps } from '..'
import { SelectLoadingPlaceholderDesktop } from './SelectLoadingPlaceholderDesktop/SelectLoadingPlaceholderDesktop'
import { SelectLoadingPlaceholderMobile } from './SelectLoadingPlaceholderMobile/SelectLoadingPlaceholderMobile'

export const SelectLoadingPlaceholder = ({
  isMobileVariant,
  maxHeight,
}: SelectLoadingPlaceholderRenderProps) => {
  if (isMobileVariant) {
    return <SelectLoadingPlaceholderMobile />
  }

  return <SelectLoadingPlaceholderDesktop maxHeight={maxHeight} />
}
