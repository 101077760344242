import { translate } from 'utils/translations'

const SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9 ]/
const MAX_SUBCATEGORY_LENGTH = 50

export const validateSubcategory = (subcategory: string) => {
  if (SPECIAL_CHAR_REGEX.test(subcategory)) {
    return translate(
      'component.editZellerCategories.subcategorySpecialCharacters'
    )
  }
  if (!subcategory) {
    return translate('component.editZellerCategories.emptySubcategory')
  }

  if (subcategory.length > MAX_SUBCATEGORY_LENGTH) {
    return translate('component.editZellerCategories.maxLengthExceeded', {
      limit: MAX_SUBCATEGORY_LENGTH,
    })
  }

  return undefined
}
