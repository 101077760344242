import { useEffect, useMemo, useState } from 'react'
import { equals } from 'ramda'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { PickerItemProps } from 'types/picker'

import { ContactsFilters } from '../../Contacts.types'

export const defaultFilters: ContactsFilters = {
  name: '',
  tags: [],
}

interface UseContactsFiltersProps {
  debounceDuration?: number
}

// NOTE: this hook is used across multiple features, to improve maintainability
// moving forward we should look to decouple
export const useContactsFilters = (props?: UseContactsFiltersProps) => {
  const debounceDuration = props?.debounceDuration || 500

  const [filters, setFilters] = useState<ContactsFilters>(defaultFilters)

  const [name$] = useState(new Subject<string>())
  const [tags$] = useState(new Subject<PickerItemProps[]>())

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      name$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((nextName) =>
          setFilters((prev) => ({ ...prev, name: nextName }))
        )
    )

    subscription.add(
      tags$.subscribe((nextTags) =>
        setFilters((prev) => ({ ...prev, tags: nextTags }))
      )
    )

    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const areFiltersInDefaultState = useMemo(
    () => equals(defaultFilters)(filters),
    [filters]
  )

  return {
    areFiltersInDefaultState,
    filters,
    name$,
    setFilters,
    tags$,
  }
}
