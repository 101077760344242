import { CustomerRole } from '@npco/mp-gql-types'

export const PERMISSIONS = {
  SITE: {
    ADD_SITE: [CustomerRole.ADMIN],
    USERS: {
      ADD: [CustomerRole.ADMIN],
      EDIT: [CustomerRole.ADMIN],
    },
  },
  CARDS: {
    ADD_CARD: [CustomerRole.ADMIN],
    LINK_CARD: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  },
}
