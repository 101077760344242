export enum StatementPeriod {
  CurrentMonth = 'current_month',
  PreviousMonth = 'previous_month',
  CurrentQuarter = 'current_quarter',
  PreviousQuarter = 'past_quarter',
  CustomRange = 'custom_range',
}

export interface DatesRange {
  from: Date | undefined
  to: Date | undefined
}
