import { useCallback } from 'react'
import dayjs from 'dayjs'
import { CategoricalChartState } from 'recharts/types/chart/types'

import { getMonthFromDate } from '../../IncomeAndExpenseChart/IncomeAndExpenseChart.utils'
import { useSetSelectedDateRange } from '../useSelectedDateRange'

export const useSelectMonth = () => {
  const { updateSelectedDateRange } = useSetSelectedDateRange()

  const selectMonth = useCallback(
    (state: CategoricalChartState) => {
      const selectPayload = state.activePayload?.[0]?.payload
      const monthToSelect = selectPayload?.dateStart
      const income = selectPayload?.income
      const expense = selectPayload?.expense

      if (!monthToSelect || (!income && !expense)) {
        return
      }

      updateSelectedDateRange({
        type: 'MONTH',
        month: getMonthFromDate(monthToSelect),
        year: dayjs(monthToSelect).year(),
      })
    },
    [updateSelectedDateRange]
  )

  return { selectMonth }
}
