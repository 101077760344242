import {
  Box,
  ButtonGhostIconLeft,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { translate } from 'utils/translations'
import { ThirdPartyAccount, ThirdPartyAccountType } from 'types/accounts'
import { BankAccountFieldsProps } from 'forms/formViews/BankAccountFields/BankAccountFields.types'

import { AddAccountAndConfirmModals } from './AddAccountAndConfirmModals'
import { useAddThirdPartyAccount } from './AddThirdPartyAccount.hook'

interface Props {
  accountType: ThirdPartyAccountType
}

export const AddNewExternalAccountModal = ({ accountType }: Props) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const { addThirdPartyAccount, isLoadingAddThirdPartyAccount } =
    useAddThirdPartyAccount()

  const onSubmit = async (values: BankAccountFieldsProps) => {
    try {
      const response = await addThirdPartyAccount(
        values as ThirdPartyAccount,
        accountType === ThirdPartyAccountType.Deposit
      )
      if (!response) {
        showErrorToast()
        return
      }

      showSuccessToast(
        translate('page.settings.deposits.creatingAccountSuccess')
      )
      closeModal()
    } catch (err) {
      showErrorToast()
    }
  }

  return (
    <>
      {isModalOpen && (
        <AddAccountAndConfirmModals
          onSubmit={onSubmit}
          onCancel={closeModal}
          isLoadingAddThirdPartyAccount={isLoadingAddThirdPartyAccount}
        />
      )}
      <Box mb="4rem" ml="1.2rem" mt="1.75rem">
        <ButtonGhostIconLeft icon={Plus} onClick={openModal}>
          {translate('page.settings.deposits.addExternalAccount')}
        </ButtonGhostIconLeft>
      </Box>
    </>
  )
}
