import { useState } from 'react'
import {
  DebitCardTransactionsExportFormat,
  DepositExportFormat,
  SimBillingStatementExportFormat,
  TransactionExportFormat,
} from '@npco/mp-gql-types'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { StatementsModalContent } from 'components/StatementsModalContent/StatementsModalContent'

interface Props<
  T extends
    | DepositExportFormat
    | TransactionExportFormat
    | DebitCardTransactionsExportFormat
    | SimBillingStatementExportFormat
> {
  closeModal: () => void
  title: string
  modalContent: (fileFormat: T) => JSX.Element
  type: Record<string, string>
  info?: string
  defaultFormat?: T
}
export const StatementsModal = <
  T extends
    | DepositExportFormat
    | TransactionExportFormat
    | DebitCardTransactionsExportFormat
    | SimBillingStatementExportFormat
>({
  closeModal,
  title,
  modalContent,
  type,
  info,
  defaultFormat = DepositExportFormat.PDF as T,
}: Props<T>) => {
  const [isTypeSelected, setIsTypeSelected] = useState(false)
  const [selectedFormat, setSelectedFormat] = useState<T>(defaultFormat)

  const onConfirm = () => {
    setIsTypeSelected(true)
  }

  return (
    <ModalBasic isOpen onCancel={closeModal}>
      {isTypeSelected ? (
        modalContent(selectedFormat)
      ) : (
        <StatementsModalContent
          title={title}
          selectedFormat={selectedFormat}
          setSelectedFormat={setSelectedFormat}
          closeModal={closeModal}
          onConfirm={onConfirm}
          type={type}
          info={info}
        />
      )}
    </ModalBasic>
  )
}
