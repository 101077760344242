import { ModalSize } from '@npco/zeller-design-system'

import { MultiStageModal } from 'components/MultiStageModal'

import { useCreateExternalAccountContext } from '../contexts/useCreateExternalAccountContext'
import { CreateExternalAccountStages } from './AddExternalAccountStages/CreateExternalAccountStages'
import {
  getStageTitle,
  TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES,
} from './CreateExternalAccountModal.utils'

export const CreateExternalAccountModal = () => {
  const { state, goToCreateExternalAccountState } =
    useCreateExternalAccountContext()

  if (state === null) {
    return null
  }

  return (
    <MultiStageModal
      title={getStageTitle(state.stage)}
      isOpen
      onCancel={() => goToCreateExternalAccountState(undefined)}
      hasCloseButton
      size={ModalSize.MEDIUM}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      testId="create-external-account-modal"
    >
      <MultiStageModal.ProgressBarProvider
        initialCurrentStep={0}
        initialTotalSteps={TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES}
      >
        <MultiStageModal.ProgressBar
          pr={['24px', '32px']}
          mt="20px"
          dataTestId="progress-bar"
        />
        <CreateExternalAccountStages />
      </MultiStageModal.ProgressBarProvider>
    </MultiStageModal>
  )
}
