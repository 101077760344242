import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { SimBillingStatementExportFormat } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { renameAndDownloadFile } from '@npco/utils-file'
import { ExportSimBillingStatement } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sim'

import {
  ExportSimBillingStatement as ExportSimBillingStatementType,
  ExportSimBillingStatementVariables,
} from 'types/gql-types/ExportSimBillingStatement'
import { useSimBillsFilters } from 'pages/Settings/Sim/BillingHistory/BillingHistory.hooks'

export const useExportSimBills = (
  filename: string,
  format: SimBillingStatementExportFormat
) => {
  const entityUuid = useSelectedEntityUuid()
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const { filters } = useSimBillsFilters()

  const [getFile] = useLazyQuery<
    ExportSimBillingStatementType,
    ExportSimBillingStatementVariables
  >(ExportSimBillingStatement, {
    variables: {
      entityUuid,
      filter: filters,
      format,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setIsLoading(false)
      setHasError(true)
    },
    onCompleted: (data) => {
      if (!data?.exportSimBillingStatement?.downloadLink) {
        setIsLoading(false)
        setHasError(true)
        return
      }

      renameAndDownloadFile({
        link: data?.exportSimBillingStatement.downloadLink,
        filename,
        format,
      }).then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    },
  })

  const exportFile = () => {
    setHasError(false)
    setIsLoading(true)
    getFile()
  }

  return {
    exportFile,
    isLoading,
    hasError,
    hasLoaded,
  }
}
