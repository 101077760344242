import { useMemo } from 'react'

import { TransactionTotal } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/DashboardPerformance.types'
import {
  getEndOfCurrentDay,
  getEndOfDayOneMonthAgo,
  getEndOfPreviousMonth,
  getStartOfMonth,
  getStartOfPreviousMonth,
} from 'pages/Dashboard/utils/timeUtils'

import { useGetTotalSalesBetween } from './useGetTotalSalesBetween'

interface UseTodaySitePerformanceOptions {
  salesTotals: TransactionTotal[]
}

export const useMonthlyPerformance = ({
  salesTotals,
}: UseTodaySitePerformanceOptions) => {
  const getTotalSalesBetween = useGetTotalSalesBetween(salesTotals)

  const startOfLastMonth = useMemo(() => getStartOfPreviousMonth(), [])
  const endOfLastMonth = useMemo(() => getEndOfPreviousMonth(), [])

  const totalSalesLastMonth = useMemo(
    () => getTotalSalesBetween(startOfLastMonth, endOfLastMonth),
    [getTotalSalesBetween, startOfLastMonth, endOfLastMonth]
  )

  const thisTimeLastMonth = useMemo(() => getEndOfDayOneMonthAgo(), [])

  const salesThisTimeLastMonth = useMemo(
    () => getTotalSalesBetween(startOfLastMonth, thisTimeLastMonth),
    [getTotalSalesBetween, startOfLastMonth, thisTimeLastMonth]
  )

  const startOfCurrentMonth = useMemo(() => getStartOfMonth(), [])
  const endOfCurrentDay = getEndOfCurrentDay()

  const salesThisMonth = useMemo(
    () => getTotalSalesBetween(startOfCurrentMonth, endOfCurrentDay),
    [getTotalSalesBetween, startOfCurrentMonth, endOfCurrentDay]
  )

  return {
    totalSalesLastMonth,
    salesThisTimeLastMonth,
    salesThisMonth,
  }
}
