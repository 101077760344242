import { createTranslations } from '@npco/utils-translations'

export const savingsAccountDetailsTranslations = createTranslations({
  typeLabel: 'Type',
  typeValue: 'Savings',
  interestRateLabel: 'Interest Rate (Variable)',
  interestRateBase: '{base}% p.a.',
  plusSign: '+',
  interestRateBonus: '{bonus}% p.a. bonus',
  bonusRateEndsLabel: 'Bonus Rate Ends',
  interestThresholdLabel: 'Interest Payable Up To',
  tfnLabel: 'TFN Status',
  tfnSupplied: 'Supplied',
  termsApply: 'Terms apply',
})
