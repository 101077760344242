import { BodyDefaultTypography, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTerminalSettingsContainer = styled.div`
  div: first-of-type {
    margin-top: 0;
  }
`

export const StyledTerminalText = styled.span`
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledTerminalLabel = styled(H5)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin-top: 0;
  margin-bottom: 0;
`
