import { translate } from 'utils/translations'

export const title = translate(
  'page.upgradeAccountsCompleteModal.xeroIntegration.title'
)

export const description = translate(
  'page.upgradeAccountsCompleteModal.xeroIntegration.description'
)

export const messageLinkText = translate(
  'page.upgradeAccountsCompleteModal.xeroIntegration.messageLinkText'
)

export const getMessage = (link: JSX.Element) =>
  translate('page.upgradeAccountsCompleteModal.xeroIntegration.message', {
    link,
  })
