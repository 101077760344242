import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useQueryDebitCards } from 'features/Cards/AllCardsDebit/hooks/useQueryDebitCards/useQueryDebitCards'
import { CardsErrorPage } from 'features/Cards/CardsErrorPage'

import { useOnAddCard } from 'hooks/useOnAddCard/useOnAddCard'
import { useSubscribeToCardUpdate } from 'hooks/useSubscribeToCardUpdate/useSubscribeToCardUpdate'
import { CardsView, CardsViewContainer } from 'components/CardsView'
import { ActiveCards } from 'components/CardsView/ActiveCards'
import { ClosedCards } from 'components/CardsView/ClosedCards'
import { DebitCardsEmpty } from 'components/CardsView/components/CardsEmpty/DebitCardsEmpty'
import { DebitCardsTable } from 'components/CardsView/components/CardsTable/DebitCardsTable/DebitCardsTable'
import { DebitCardsTableDebitCardV2Fragment } from 'components/CardsView/components/CardsTable/DebitCardsTable/DebitCardsTable.generated'
import { useFilterCardStatus } from 'components/CardsView/hooks/useFilterCardStatus'
import { PendingCards } from 'components/CardsView/PendingCards'

export const AllCardsDebit = () => {
  const { cards, entity, isLoading, error, refetchCards } = useQueryDebitCards()

  const filteredCards =
    useFilterCardStatus<DebitCardsTableDebitCardV2Fragment>(cards)
  const { onAddCard } = useOnAddCard(entity)
  const { userData } = useLoggedInUser()

  useSubscribeToCardUpdate()

  const cardListLength = cards?.length

  const hasCards = cardListLength > 0
  const hasActiveCards = filteredCards.active.length > 0
  const hasOwnedPendingCards = filteredCards.pending.some(
    (card: DebitCardsTableDebitCardV2Fragment) =>
      card.customer?.id === userData?.id
  )
  const hasClosedCards = filteredCards.closed.length > 0

  if (error && cards.length === 0 && !isLoading) {
    return <CardsErrorPage retry={() => refetchCards()} pt="24px" />
  }

  return (
    <CardsViewContainer data-testid="debit-cards-view">
      <CardsView
        hasCards={hasCards}
        isLoading={isLoading}
        emptyView={<DebitCardsEmpty onAddCard={onAddCard} />}
      >
        <ActiveCards
          onAddCard={onAddCard}
          hasActiveCards={hasActiveCards}
          hasOwnedPendingCards={hasOwnedPendingCards}
          hasClosedCards={hasClosedCards}
          isLoading={isLoading}
        >
          <DebitCardsTable cards={filteredCards.active} isLoading={isLoading} />
        </ActiveCards>
        <PendingCards numberOfPendingCards={filteredCards.pending.length}>
          <DebitCardsTable
            cards={filteredCards.pending}
            isLoading={isLoading}
            isReadonly
          />
        </PendingCards>
        <ClosedCards numberOfClosedCards={filteredCards.closed.length}>
          <DebitCardsTable cards={filteredCards.closed} isLoading={isLoading} />
        </ClosedCards>
      </CardsView>
    </CardsViewContainer>
  )
}
