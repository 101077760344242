import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { StyledModal } from './UploadFileModal.styled'

interface Props {
  title: React.ReactNode
  isOpen: boolean
  onSave: () => void
  onCancel: () => void
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  isPrimaryButtonDisabled?: boolean
  children: React.ReactNode
}

export const UploadFileModal = ({
  title,
  isOpen,
  primaryButtonLabel = translate('shared.save'),
  secondaryButtonLabel = translate('shared.cancel'),
  onSave,
  onCancel,
  children,
  isPrimaryButtonDisabled,
}: Props) => {
  const isMobile = useIsMobileResolution()

  return (
    <StyledModal
      title={title}
      primaryButtonLabel={primaryButtonLabel}
      secondaryButtonLabel={secondaryButtonLabel}
      isOpen={isOpen}
      onConfirm={onSave}
      onCancel={onCancel}
      hasCancelButton
      hasCloseButton
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      isFullScreen={isMobile}
    >
      {children}
    </StyledModal>
  )
}
