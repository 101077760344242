import { SyntheticEvent, useState } from 'react'
import { rvSelectedRates as updateSelectedRates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import debounce from 'debounce'

import { ReactComponent as Dollar } from 'assets/svg/dollar.svg'
import { rangePickerInitialValue } from 'const/common'
import { amountFormatter } from 'utils/common'
import { InputManualRange } from 'components/Filters/NewFilters/RangePicker/InputManualRange/InputManualRange'
import {
  StyledRangeItemsWrapper,
  StyledTicksWrapper,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.styled'
import {
  RangePickerValue,
  RangeSliderValue,
  RangeType,
  RangeValue,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import {
  adjustRangeValuesForEdgeConditions,
  getNewRangeFromEvent,
  printRangeSummary,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.utils'
import { Range } from 'components/Input/Range'
import { MultiDownshift } from 'components/MultiDownshift'
import { BottomRow, TopRow } from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { StyledDataSmall, StyledDropdownWrapper } from 'components/Shared'
import { component } from 'translations'

const SLIDER_VALUE_CHANGE_DEBOUNCE = 500
const SLIDER_STEP = 10
const DEFAULT_INITIAL_RANGE = rangePickerInitialValue

const changeSelectedRatesDebounced = debounce(
  updateSelectedRates,
  SLIDER_VALUE_CHANGE_DEBOUNCE
)

export const RangePicker = ({
  initialRange = DEFAULT_INITIAL_RANGE,
}: {
  initialRange?: [number, number]
}) => {
  const [[inputMin, inputMax], setInputRange] =
    useState<[RangeValue, RangeValue]>(initialRange)
  const [[sliderMin, sliderMax], setSliderRange] =
    useState<RangePickerValue>(initialRange)

  const handleOnInputChange = (event: SyntheticEvent, type: RangeType) => {
    const newRangeFromInput = getNewRangeFromEvent(
      [inputMin, inputMax],
      event,
      type
    )
    setInputRange(newRangeFromInput)

    if (newRangeFromInput.includes('-')) {
      return
    }

    const changedRange = adjustRangeValuesForEdgeConditions(
      newRangeFromInput as [RangeValue, RangeValue],
      type,
      [initialRange[0], initialRange[1]]
    )

    updateSelectedRates(changedRange)
    setSliderRange(changedRange)
  }
  const clearRange = () => {
    updateSelectedRates(initialRange)
    setInputRange(initialRange)
    setSliderRange(initialRange)
  }

  return (
    <MultiDownshift>
      {({ getToggleButtonProps, getRootProps, closeMenu, isOpen }) => (
        <StyledDropdownWrapper
          width="100%"
          {...getRootProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
          data-testid="range-picker"
        >
          <PickerTrigger
            isOpen={isOpen}
            getToggleButtonProps={getToggleButtonProps}
            label={amountFormatter(sliderMin)}
            placeholderIcon={<Dollar />}
            hasTriggerArrow={false}
            triggerContent={
              initialRange[0] === sliderMin
                ? undefined
                : amountFormatter(sliderMin)
            }
          />
          <PickerTrigger
            isOpen={isOpen}
            getToggleButtonProps={getToggleButtonProps}
            label={amountFormatter(sliderMax)}
            placeholderIcon={<Dollar />}
            hasTriggerArrow={false}
            triggerContent={
              initialRange[1] === sliderMax
                ? undefined
                : amountFormatter(sliderMax)
            }
          />
          {isOpen && (
            <StyledRangeItemsWrapper $width={344}>
              <TopRow
                copy={component.rangePicker.selectAmountRange}
                handleCloseMenu={closeMenu}
              />
              <Range
                min={initialRange[0]}
                max={initialRange[1]}
                step={SLIDER_STEP}
                value={[sliderMin, sliderMax]}
                onChange={(newValue: RangeSliderValue) => {
                  const range = newValue as RangePickerValue
                  changeSelectedRatesDebounced(range)
                  setSliderRange(range)
                  setInputRange(range)
                }}
              />
              <StyledTicksWrapper>
                <InputManualRange
                  placeholder={inputMin.toString()}
                  value={inputMin.toString()}
                  onChange={(e: SyntheticEvent) =>
                    handleOnInputChange(e, 'min')
                  }
                  onBlur={() => setInputRange([sliderMin, sliderMax])}
                />
                <InputManualRange
                  placeholder={inputMax.toString()}
                  value={inputMax.toString()}
                  onChange={(e: SyntheticEvent) =>
                    handleOnInputChange(e, 'max')
                  }
                  onBlur={() => setInputRange([sliderMin, sliderMax])}
                />
              </StyledTicksWrapper>

              <BottomRow handleClearAll={clearRange}>
                <StyledDataSmall>
                  {printRangeSummary(sliderMin, sliderMax)}
                </StyledDataSmall>
              </BottomRow>
            </StyledRangeItemsWrapper>
          )}
        </StyledDropdownWrapper>
      )}
    </MultiDownshift>
  )
}
