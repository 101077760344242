import { useCallback, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvStepUpAuthDepositsObject } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/auth'

import { useAccounts } from 'hooks/banking/useAccounts'
import { useGetThirdPartyAccounts } from 'hooks/useGetThirdPartyAccounts'
import { ThirdPartyBankAccount } from 'types/accounts'
import { DepositsApiCallTypeEnum, DepositsAppState } from 'types/auth'
import { useSelectDepositExternalBankListItem } from 'pages/Settings/DepositsSettings/DepositExternalAccountsList/DepositExternalAccountsList.hook'
import { useSelectZellerAccountListItem } from 'pages/Settings/DepositsSettings/DepositZellerAccountsList/DepositZellerAccountsList.hook'
import { useAddThirdPartyAccount } from 'components/AddThirdPartyAccount/AddThirdPartyAccount.hook'

export const useDepositsQueryRetry = () => {
  const stepUpAuthObject = useReactiveVar(rvStepUpAuthDepositsObject)
  const { accountById } = useAccounts()

  const { thirdPartyAccounts } = useGetThirdPartyAccounts()
  const { changeToExternalAccount, isLoading: isLoadingExternalAccountChange } =
    useSelectDepositExternalBankListItem()

  const { isLoading: isLoadingZellerAccountChange, changeToZellerAccount } =
    useSelectZellerAccountListItem()

  const { addThirdPartyAccount, isLoadingAddThirdPartyAccount } =
    useAddThirdPartyAccount()

  const [isLoading, setIsLoading] = useState(false)

  const retryApiCall = useCallback(() => {
    setIsLoading(true)

    if (!stepUpAuthObject) {
      setIsLoading(false)
      return
    }

    // check if the retry should follow select flow
    if (stepUpAuthObject.apiCallType === DepositsApiCallTypeEnum.SELECT) {
      const { id, remitToCard } = stepUpAuthObject.variables
      const zellerAccount = accountById(id)

      // remitToCard true for zeller accounts
      if (remitToCard && !isLoadingZellerAccountChange && zellerAccount) {
        changeToZellerAccount(zellerAccount).then(() => {
          rvStepUpAuthDepositsObject({} as DepositsAppState)
          setIsLoading(false)
        })
        return
      }

      // remitToCard false for external accounts
      if (
        !remitToCard &&
        thirdPartyAccounts &&
        !isLoadingExternalAccountChange
      ) {
        changeToExternalAccount(
          thirdPartyAccounts.find(
            ({ id: accountId }) => accountId === id
          ) as ThirdPartyBankAccount,
          stepUpAuthObject.isNewAccount
        ).then(() => {
          rvStepUpAuthDepositsObject({} as DepositsAppState)
          setIsLoading(false)
        })
        return
      }

      setIsLoading(false)
      return
    }

    // check if the retry should follow add flow
    if (
      stepUpAuthObject.apiCallType === DepositsApiCallTypeEnum.ADD &&
      !isLoadingAddThirdPartyAccount
    ) {
      addThirdPartyAccount(stepUpAuthObject.variables.input, true).then(() => {
        rvStepUpAuthDepositsObject({} as DepositsAppState)
        setIsLoading(false)
      })
    }
  }, [
    accountById,
    addThirdPartyAccount,
    changeToExternalAccount,
    changeToZellerAccount,
    isLoadingAddThirdPartyAccount,
    isLoadingExternalAccountChange,
    isLoadingZellerAccountChange,
    stepUpAuthObject,
    thirdPartyAccounts,
  ])

  return {
    retryApiCall,
    isLoading,
  }
}
