import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type GetContactNameContactFragment = {
  __typename?: 'Contact'
  businessName: string | null
  contactType: Types.ContactType
  firstName: string | null
  lastName: string | null
}

export const GetContactNameContactFragmentDoc = gql`
  fragment GetContactNameContactFragment on Contact {
    businessName
    contactType
    firstName
    lastName
  }
` as unknown as TypedDocumentNode<GetContactNameContactFragment, undefined>
