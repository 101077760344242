import { useMemo } from 'react'

import { useManageBusinessesQuery } from './graphql/getCustomerEntityMapping.generated'

export const useManageBusinessesQueryData = () => {
  const { data, loading, error, refetch, networkStatus } =
    useManageBusinessesQuery({
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    })

  const entityRelations = useMemo(() => {
    return data?.getCustomerEntityMapping.entityRelations?.filter(Boolean) ?? []
  }, [data])

  return {
    entityRelations,
    isLoadingEntityRelations: loading,
    entityRelationsQueryError: error,
    refetchEntityRelations: refetch,
    entityRelationsQueryNetworkStatus: networkStatus,
  }
}
