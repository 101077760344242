import { gql, type TypedDocumentNode } from '@apollo/client'

export type OutstandingFilterSettingsFragment = {
  __typename?: 'Entity'
  id: string
  outstandingTransactionRequirementConfig: {
    __typename?: 'OutstandingTransactionRequirementConfig'
    attachments: boolean
    category: boolean
    note: boolean
    accountingCategory: boolean
  } | null
}

export const OutstandingFilterSettingsFragmentDoc = gql`
  fragment OutstandingFilterSettingsFragment on Entity {
    id
    outstandingTransactionRequirementConfig {
      attachments
      category
      note
      accountingCategory
    }
  }
` as unknown as TypedDocumentNode<OutstandingFilterSettingsFragment, undefined>
