import { useMemo } from 'react'
import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client'
import {
  rvEntityDetails,
  useSelectedEntityUuid,
} from '@npco/mp-utils-selected-entity'

import { ReactComponent as DefaultBankIcon } from 'assets/svg/close.svg'
import { getBankByBsB } from 'utils/bankAccountHelper'
import {
  GetSettlementAccountInfo as GetSettlementAccountInfoResponse,
  GetSettlementAccountInfoVariables,
} from 'types/gql-types/GetSettlementAccountInfo'

import { GetSettlementAccountInfo } from '../graphql/GetSettlementAccountInfo'
import { StyledSvgIcon } from '../XeroPaymentServicesConnection.styled'

export const getThirdPartyBankIcon = (bsb: string | null | undefined) => {
  const bank = bsb ? getBankByBsB(bsb) : null
  return <StyledSvgIcon>{bank?.icon || <DefaultBankIcon />}</StyledSvgIcon>
}

export const useGetSettlementAccountInfo = () => {
  const entityDetails = useReactiveVar(rvEntityDetails)
  const entityUuid = useSelectedEntityUuid()
  const { data, error, loading, networkStatus } = useQuery<
    GetSettlementAccountInfoResponse,
    GetSettlementAccountInfoVariables
  >(GetSettlementAccountInfo, {
    variables: {
      entityUuid,
      debitCardAccountUuid: entityDetails.debitCardAccountUuid ?? '',
      thirdPartyBankAccountUuid: entityDetails.depositAccountUuid ?? '',
      remitToCard: !!entityDetails.remitToCard,
    },
    notifyOnNetworkStatusChange: true,
  })

  const accountName = useMemo(
    () =>
      entityDetails.remitToCard
        ? data?.zellerAccount?.name
        : data?.thirdPartyBankAccount?.name,
    [
      data?.thirdPartyBankAccount?.name,
      data?.zellerAccount?.name,
      entityDetails.remitToCard,
    ]
  )

  const accountColor = useMemo(
    () =>
      entityDetails.remitToCard ? data?.zellerAccount?.icon?.colour : undefined,
    [data?.zellerAccount?.icon?.colour, entityDetails.remitToCard]
  )

  const accountIcon = useMemo(
    () =>
      !entityDetails.remitToCard
        ? getThirdPartyBankIcon(data?.thirdPartyBankAccount?.accountBsb)
        : undefined,
    [data?.thirdPartyBankAccount?.accountBsb, entityDetails.remitToCard]
  )

  const hasError = Boolean(error)
  const isLoading = loading || networkStatus === NetworkStatus.refetch

  return { accountName, accountColor, accountIcon, isLoading, hasError }
}
