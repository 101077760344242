import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { ButtonLink, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'
import { ROOT } from 'const/routes'

export const LogoIconLink = ({
  ariaLabel,
  className = '',
  height = '24',
  width = '69',
}: {
  height?: string
  width?: string
  ariaLabel?: string
  className?: string
}) => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <ButtonLink
      aria-label={ariaLabel}
      className={className}
      data-testid="header-logo-link"
      onClick={() => navigate(ROOT.ORGS.ORG(shortEntityId).path)}
    >
      <SvgIcon width={width} height={height}>
        <LogoIcon data-testid="logo" />
      </SvgIcon>
    </ButtonLink>
  )
}
