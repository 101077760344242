import { useCallback, useState } from 'react'
import { ModalBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface DisconnectModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  children: React.ReactNode
}

export const DisconnectModal = ({
  isOpen,
  onCancel,
  onConfirm,
  children,
}: DisconnectModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmit = useCallback(() => {
    setIsSubmitting(true)
    onConfirm()
  }, [onConfirm, setIsSubmitting])
  return (
    <ModalBasic
      isOpen={isOpen}
      onCancel={onCancel}
      title={translate(
        'page.settings.connections.removeConnectionDialogue.title'
      )}
    >
      <ModalBasic.Body>{children}</ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={onCancel}>
          {translate(
            'page.settings.connections.removeConnectionDialogue.cta.cancel'
          )}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {translate(
            'page.settings.connections.removeConnectionDialogue.cta.confirm'
          )}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}
