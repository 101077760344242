import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showErrorToast } from '@npco/zeller-design-system'
import { LinkContactWithCardholder } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/transactions'

import {
  LinkContactWithCardholder as LinkContactWithCardholderResponse,
  LinkContactWithCardholderVariables,
} from 'types/gql-types/LinkContactWithCardholder'
import { errorMessages } from 'translations'

interface UseLinkContactWithCardholderProps {
  suppressErrorToast?: boolean
}

export const useLinkContactWithCardholder = (
  props: UseLinkContactWithCardholderProps = {}
) => {
  const entityUuid = useSelectedEntityUuid()
  const { suppressErrorToast = false } = props

  const [
    linkContactWithCardholder,
    { error, loading: isLinkingContactWithCardholder },
  ] = useMutation<
    LinkContactWithCardholderResponse,
    LinkContactWithCardholderVariables
  >(LinkContactWithCardholder, {
    onError: () => {
      if (!suppressErrorToast) {
        showErrorToast(errorMessages.linkCardholderToContactError)
      }
    },
  })

  const linkContact = async (
    cardholderUuid: string,
    contactUuid: string
  ): Promise<boolean> => {
    const response = await linkContactWithCardholder({
      variables: { entityUuid, cardholderUuid, contactUuid },
    })

    const hasErrors = Boolean(response.errors)
    const result = Boolean(response.data?.linkContactWithCardholder)

    if (!result && !hasErrors && !suppressErrorToast) {
      showErrorToast(errorMessages.linkCardholderToContactError)
    }

    return result
  }

  return {
    error,
    isLinkingContactWithCardholder,
    linkContactWithCardholder: linkContact,
  }
}
