import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { UpdateCategory } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/debitCardTransactions'

import {
  UpdateCategory as UpdateCategoryResponse,
  UpdateCategoryVariables,
} from 'types/gql-types/UpdateCategory'

import { CategoryOptions } from '../../utils/categories/categories.types'

interface Props {
  debitCardTransactionUuid: string
  onSuccess?: (options: CategoryOptions) => void
  onFailure?: () => void
}

export const useUpdateDebitCardTransactionCategories = ({
  debitCardTransactionUuid,
  onSuccess,
  onFailure,
}: Props) => {
  const entityUuid = useSelectedEntityUuid()
  const [
    updateCategoriesMutation,
    { loading: isUpdatingTransactionCategories, error },
  ] = useMutation<UpdateCategoryResponse, UpdateCategoryVariables>(
    UpdateCategory,
    { onError: onFailure }
  )

  const updateTransactionCategories = useCallback(
    async ({ category, subcategoryName, subcategoryId }: CategoryOptions) => {
      const result = await updateCategoriesMutation({
        variables: {
          entityUuid,
          category,
          subcategory: subcategoryId,
          debitCardTransactionUuid,
        },
      })

      if (result.data?.updateDebitCardTransactionAnnotations) {
        onSuccess?.({ category, subcategoryName, subcategoryId })
      } else if (!result.errors) {
        onFailure?.()
      }

      return result
    },
    [
      updateCategoriesMutation,
      entityUuid,
      debitCardTransactionUuid,
      onSuccess,
      onFailure,
    ]
  )

  return { updateTransactionCategories, isUpdatingTransactionCategories, error }
}
