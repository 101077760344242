import { StyledTableEffect } from '@npco/ui-table'
import {
  BodyDefault,
  BodyDefaultTypography,
  Title,
} from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { DebitCardTransactionStatusBadge } from 'components/StatusBadge/DebitCardTransactionStatusBadge/DebitCardTransactionStatusBadge'

export const Container = styled.li<{ $isNew?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_90};
  column-gap: 0.625rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4rem 3rem 1fr 3rem 1.25fr minmax(6.25rem, 7.5rem) minmax(
      4.5rem,
      5rem
    );
  padding: 0.75rem 0;

  ${({ theme, $isNew }) =>
    $isNew &&
    css`
      animation: ${theme.animation.appearAnimation} 1s linear;
      border-left: 2px solid ${theme.colors.BLUE_1000};
    `}

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding: 0.75rem 0.5rem;
    grid-template-columns: 5rem 5rem 1fr 5rem 1fr minmax(6.25rem, 8.75rem) minmax(
        4.5rem,
        6rem
      );
  }

  ${StyledTableEffect}

  ${Title} {
    text-align: left;
  }
`

export const MobileContainer = styled.li<{ $isNew?: boolean }>`
  box-shadow: inset 0 -1px 0 ${({ theme }) => rgba(theme.colors.GREY_150, 0.75)};
  padding: 0.5rem 0;

  ${({ theme, $isNew }) =>
    $isNew &&
    css`
      animation: ${theme.animation.appearAnimation} 1s linear;
      border-left: 2px solid ${theme.colors.BLUE_1000};
    `}
`

export const DescriptionText = styled(BodyDefault)`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.GREY_550};

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    color: ${({ theme }) => theme.colors.BLACK};
  }
`

export const StyledDebitCardTransactionStatusBadge = styled(
  DebitCardTransactionStatusBadge
)`
  cursor: inherit;
`

export const TransactionAmount = styled.p<{
  $isNegativeAmount?: boolean
}>`
  ${BodyDefaultTypography};
  color: ${({ theme, $isNegativeAmount }) =>
    $isNegativeAmount ? theme.colors.RED_1000 : theme.colors.GREEN_1600};
  margin: 0;
  text-align: right;
`
