import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { getCents } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  COLOR,
  DATAPOINT_TEXT_SIZE,
  DatapointCurrency,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { sumBy } from 'lodash-es'

import { getDollarAmountForDatapoint } from '../IncomeAndExpenseDatapoints.utils'
import { ExpenseDatapointCashFlowPeriodicTotalAmountFragment } from './ExpenseDatapoint.generated'
import { expenseDatapointTranslations } from './ExpenseDatapoint.i18n'

type ExpenseDatapointProps = {
  expenseTotals: ExpenseDatapointCashFlowPeriodicTotalAmountFragment[]
  isLoading: boolean
}

export const ExpenseDatapoint = ({
  expenseTotals,
  isLoading,
}: ExpenseDatapointProps) => {
  const t = useTranslations(expenseDatapointTranslations)
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)

  const totalOutgoingTransactions = useMemo(
    () =>
      sumBy(
        expenseTotals,
        (total) => total.transactionDirectionTotalAmounts.noOfExpense
      ),
    [expenseTotals]
  )

  const expenseAmountInCents = useMemo(
    () =>
      sumBy(expenseTotals, (total) =>
        parseInt(total.transactionDirectionTotalAmounts.expense.value, 10)
      ),
    [expenseTotals]
  )

  const dollarAmount = getDollarAmountForDatapoint(expenseAmountInCents)
  const centAmount = getCents(expenseAmountInCents)

  return (
    <DatapointCurrency
      integerText={dollarAmount}
      decimalText={centAmount}
      badgeText={isMobile ? undefined : totalOutgoingTransactions.toString()}
      isNegative={expenseAmountInCents >= 0}
      size={isMobile ? DATAPOINT_TEXT_SIZE.SMALL : DATAPOINT_TEXT_SIZE.MEDIUM}
      title={t('title')}
      badgeBackground={COLOR.GREY_30}
      isLoading={isLoading}
      shouldEllipsize
    />
  )
}

ExpenseDatapoint.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment ExpenseDatapointCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      transactionDirectionTotalAmounts {
        noOfExpense
        expense {
          value
        }
      }
    }
  `,
}
