import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledLeftColumn = styled.div`
  @media (min-width: ${BREAKPOINT.SM}px) {
    flex: 1;
    position: sticky;
    margin-bottom: 3.5rem;
    min-width: 0;
    margin-right: 2.5rem;
  }

  @media (min-width: ${BREAKPOINT.LG}px) {
    margin-right: 4.75rem;
  }
`
