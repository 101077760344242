import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Box } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { SourcePicker } from '../../SourcePicker/SourcePicker'
import { useSourcePicker } from '../../SourcePicker/useSourcePicker'
import { StyledWrapper } from './PaymentTransactions.styled'
import { PaymentTransactionsHeader } from './PaymentTransactionsHeader'
import { PaymentTransactionsList } from './PaymentTransactionsList/PaymentTransactionsList'

export const PaymentTransactions = () => {
  const { selectedSources } = useSourcePicker()

  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()

  const onSeeAllClick = useCallback(() => {
    history.push(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.TRANSACTIONS.path, {
      sources: selectedSources,
    })
  }, [history, selectedSources, shortEntityId])

  return (
    <>
      <PaymentTransactionsHeader onClickViewAll={onSeeAllClick} />
      <StyledWrapper data-testid="payments-transactions">
        <Box mb="1rem">
          <SourcePicker />
        </Box>
        <PaymentTransactionsList />
      </StyledWrapper>
    </>
  )
}
