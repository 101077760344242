import { DebitCardColour, DebitCardStatus } from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'

export const getTextColour = ({
  cardColour,
  isCardRevealed,
}: {
  cardColour: DebitCardColour
  isCardRevealed: boolean
}) => {
  if (isCardRevealed) {
    return COLOR.WHITE
  }

  switch (cardColour) {
    case DebitCardColour.BLACK:
      return COLOR.GREY_250
    case DebitCardColour.WHITE:
      return COLOR.GREY_700
    case DebitCardColour.DARKBLUE:
      return COLOR.WHITE
    case DebitCardColour.LIGHTBLUE:
      return COLOR.BLACK
    case DebitCardColour.GREEN:
      return COLOR.WHITE
    case DebitCardColour.YELLOW:
      return COLOR.BLACK
    default:
      return COLOR.WHITE
  }
}

const OVERLAY_STATUSES = [
  DebitCardStatus.CLOSED,
  DebitCardStatus.LOCKED,
  DebitCardStatus.LOST,
  DebitCardStatus.SUSPENDED,
]

export const getIsCardRestricted = (status: DebitCardStatus) => {
  return OVERLAY_STATUSES.includes(status)
}

export const getIsCardClosed = (status: DebitCardStatus) =>
  status === DebitCardStatus.CLOSED
