import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  GetSetupFlowDebitCards,
  GetSetupFlowDebitCardsQueryResponse,
  GetSetupFlowDebitCardsQueryVariables,
} from './graphql/getCards.generated'

export const useSetupFlowCardsQuery = () => {
  const entityUuid = useSelectedEntityUuid()

  const { loading, data } = useQuery<
    GetSetupFlowDebitCardsQueryResponse,
    GetSetupFlowDebitCardsQueryVariables
  >(GetSetupFlowDebitCards, { variables: { entityUuid } })

  const cards = useMemo(() => {
    return data?.getDebitCardsV2.cards
  }, [data?.getDebitCardsV2?.cards])

  return {
    isLoadingCards: loading,
    cards,
  }
}
