import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
} from '@npco/zeller-design-system'

import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'
import { translationsShared } from 'translations'

import { translations } from './InvoiceSettingsInfobox.i18n'

export const InvoiceSettingsInfoBox = () => {
  const { showInvoicingCustomisationSettingsWelcome, updateProductTourStatus } =
    useCustomerProductTourStatus()

  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)

  const handleDismiss = useCallback(
    () =>
      updateProductTourStatus({
        showInvoicingCustomisationSettingsWelcome: false,
      }),

    [updateProductTourStatus]
  )

  if (!showInvoicingCustomisationSettingsWelcome) {
    return null
  }

  return (
    <Box mb="40px">
      <InfoBox
        dataTestId="invoice-settings-infobox"
        priority={INFOBOX_PRIORITY.MEDIUM}
      >
        <Flex
          flexDirection={{ _: 'column', SM: 'row' }}
          gridGap="16px"
          justifyContent="space-between"
          width="100%"
        >
          <InfoBox.Message>
            {t('message', { settings: <strong>{t('settings')}</strong> })}
          </InfoBox.Message>
          <InfoBox.ButtonGroup>
            <InfoBox.CallToAction onClick={handleDismiss}>
              {tShared('dismiss')}
            </InfoBox.CallToAction>
          </InfoBox.ButtonGroup>
        </Flex>
      </InfoBox>
    </Box>
  )
}
