import { ReactComponent as ImageIcon } from 'assets/svg/image.svg'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { useAccountMultiselectWithAllElement } from 'layouts/AccountLayout/hooks/useAccountMultiselectWithAllElement/useAccountMultiselectWithAllElement'
import { page } from 'translations'

import { MultiSelectFilter } from '../MultiSelectFilter/MultiSelectFilter'
import { receiptItems } from './AccountFilterReceipt.constants'

export const AccountFilterReceipt = () => {
  const { receipt, setReceipt, resetReceipt } = useAccountFilters()

  const { listItems, selectedItems, onChange, onClose, areAllItemsSelected } =
    useAccountMultiselectWithAllElement({
      data: receipt,
      setData: setReceipt,
      dataItems: receiptItems,
    })

  return (
    <MultiSelectFilter
      listItems={listItems}
      selectedItems={selectedItems}
      values={receipt}
      onChange={onChange}
      onClose={onClose}
      onReset={resetReceipt}
      filterName={page.accounts.transactionsList.filters.receipt}
      maxHeight="19rem"
      areAllItemsSelected={areAllItemsSelected}
      icon={<ImageIcon />}
    />
  )
}
