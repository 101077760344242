import { BodyDefault, ButtonAdditionalAction } from '@npco/zeller-design-system'
import { Field } from 'formik'
import styled from 'styled-components'

export const NumberField = styled(Field)`
  background: ${({ theme }) => theme.colors.GREY_30};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.GREY_550};
  display: flex;
  height: 56px;
  margin-right: 20px;
  outline: ${({ $hasError, theme }) =>
    $hasError && `1px solid ${theme.colors.RED_1000}`};
  text-align: center;
  width: 72px;
`

export const RemoveRowButton = styled(ButtonAdditionalAction)`
  path {
    fill: ${({ theme }) => theme.colors.GREY_250};
  }
`

export const ReminderEmptyState = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.GREY_550};
`
