import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type DebitCardTransactionFragment = {
  __typename: 'DebitCardAccountTransaction'
  attachments: Array<string> | null
  cardAcceptorName: string | null
  debitCardName: string | null
  id: string
  debitCardAccountUuid: string
  debitCardId: string | null
  debitCardMaskedPan: string | null
  description: string | null
  category: Types.EntityCategories | null
  subcategory: string | null
  status: Types.DebitCardAccountTransactionStatusEnum
  timestamp: any
  reference: string | null
  referencePayee: string | null
  type: Types.DebitCardAccountTransactionTypeEnum
  note: string | null
  tags: Array<string> | null
  amount: { __typename?: 'Money'; value: string }
  deposit: { __typename?: 'Deposit'; siteName: string; id: string } | null
  subcategoryDetails: {
    __typename?: 'Subcategory'
    id: string
    name: string
    predefined: boolean
  } | null
  contact: {
    __typename?: 'Contact'
    businessName: string | null
    category: Types.EntityCategories | null
    contactType: Types.ContactType
    firstName: string | null
    id: string
    lastName: string | null
    isSelf: boolean | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
  } | null
  merchant: {
    __typename: 'MerchantDetails'
    id: string
    name: string | null
    hours: string | null
    phone: string | null
    email: string | null
    url: string | null
    abn: string | null
    location: string | null
    locationAccuracy: number | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    address: {
      __typename?: 'MerchantAddress'
      country: string | null
      postcode: string | null
      state: string | null
      street1: string | null
      street2: string | null
      suburb: string | null
    } | null
  } | null
  payerDetails: {
    __typename?: 'DebitCardTransactionCounterparty'
    debitCardAccountUuid: string | null
    paymentInstrumentUuid: string | null
    senderUuid: string | null
    accountDetails: {
      __typename?: 'BankAccountDetails'
      bsb: string | null
      account: string | null
      name: string | null
    } | null
    debitCardAccount: {
      __typename?: 'DebitCardAccountV2'
      id: string
      name: string
      type: Types.DebitCardAccountType
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        image: string | null
        animation: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
    } | null
  } | null
  payeeDetails: {
    __typename?: 'DebitCardTransactionCounterparty'
    debitCardAccountUuid: string | null
    paymentInstrumentUuid: string | null
    accountDetails: {
      __typename?: 'BankAccountDetails'
      bsb: string | null
      account: string | null
      name: string | null
    } | null
    bpayDetails: {
      __typename?: 'BpayDetails'
      billerName: string
      billerCode: string
      crn: string
      nickname: string
    } | null
    debitCardAccount: {
      __typename?: 'DebitCardAccountV2'
      id: string
      name: string
      type: Types.DebitCardAccountType
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        image: string | null
        animation: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
    } | null
  } | null
}

export const DebitCardTransactionFragmentDoc = gql`
  fragment DebitCardTransactionFragment on DebitCardAccountTransaction {
    __typename
    attachments
    cardAcceptorName
    amount {
      value
    }
    deposit {
      siteName
      id
    }
    debitCardName
    id
    debitCardAccountUuid
    debitCardId
    debitCardMaskedPan
    description
    category
    subcategory
    subcategoryDetails {
      id
      name
      predefined
    }
    status
    timestamp
    reference
    referencePayee
    type
    note
    tags
    contact {
      businessName
      category
      contactType
      firstName
      id
      lastName
      isSelf
      icon {
        colour
        images {
          size
          url
        }
        letter
      }
    }
    merchant {
      __typename
      id
      name
      icon {
        images {
          size
          url
        }
        colour
        letter
      }
      address {
        country
        postcode
        state
        street1
        street2
        suburb
      }
      hours
      phone
      email
      url
      abn
      location
      locationAccuracy
    }
    payerDetails {
      accountDetails {
        bsb
        account
        name
      }
      debitCardAccount {
        id
        name
        icon {
          colour
          letter
          image
          images {
            size
            url
          }
          animation
        }
        type
      }
      debitCardAccountUuid
      paymentInstrumentUuid
      senderUuid
    }
    payeeDetails {
      accountDetails {
        bsb
        account
        name
      }
      bpayDetails {
        billerName
        billerCode
        crn
        nickname
      }
      debitCardAccount {
        id
        name
        icon {
          colour
          letter
          image
          images {
            size
            url
          }
          animation
        }
        type
      }
      debitCardAccountUuid
      paymentInstrumentUuid
    }
  }
` as unknown as TypedDocumentNode<DebitCardTransactionFragment, undefined>
