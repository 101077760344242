import { ReactNode } from 'react'

import { DetailsField } from 'components/DetailsField/DetailsField'

import * as styled from './DetailsField.styled'

interface DetailsFieldWithButtonLinkProps {
  redirectTo?: string
  label?: string
  value: ReactNode
  onClick?: () => void
  isExternalUrl?: boolean
  isLoading?: boolean
}

export const DetailsFieldWithButtonLink = ({
  isExternalUrl,
  label,
  onClick,
  redirectTo,
  value,
  isLoading,
}: DetailsFieldWithButtonLinkProps) => {
  const target = isExternalUrl ? '_blank' : undefined
  const to = isExternalUrl ? redirectTo ?? '' : { pathname: redirectTo }

  const formattedValue = redirectTo ? (
    <styled.RedirectButtonLink target={target} to={to}>
      {value}
    </styled.RedirectButtonLink>
  ) : (
    <styled.StyledButtonLink onClick={onClick}>{value}</styled.StyledButtonLink>
  )

  return (
    <DetailsField value={formattedValue} label={label} isLoading={isLoading} />
  )
}

interface ShortIdLinkProps {
  label?: string
  value: ReactNode
  onClick?: () => void
  isLoading?: boolean
}

export const ShortIdLink = ({
  label,
  onClick,
  value,
  isLoading,
}: ShortIdLinkProps) => {
  const formattedValue = value ? (
    <styled.StyledButtonLink onClick={onClick}>{value}</styled.StyledButtonLink>
  ) : (
    <styled.DetailsValue>-</styled.DetailsValue>
  )

  return (
    <DetailsField value={formattedValue} label={label} isLoading={isLoading} />
  )
}
