import * as styled from './TransferConfirmationHeader.styled'
import { TransferDetailsHidden } from './TransferDetailsHidden'
import { TransferDetailsRevealed } from './TransferDetailsRevealed'

type TransferConfirmationHeaderProps = {
  paddingTop: string
  paddingBottom: string
  modalMinHeight: number
  behindModalRef: React.RefObject<HTMLDivElement>
  isShowingDetails: boolean
  toggleShowDetails: () => void
  isTransferRemittancesFlagOn: boolean
  isAnimationComplete: boolean
  setIsAnimationComplete: (isAnimationComplete: boolean) => void
}

export const TransferConfirmationHeader = ({
  paddingTop,
  paddingBottom,
  modalMinHeight,
  behindModalRef,
  isShowingDetails,
  toggleShowDetails,
  isTransferRemittancesFlagOn,
  isAnimationComplete,
  setIsAnimationComplete,
}: TransferConfirmationHeaderProps) => {
  return (
    <styled.HeaderWrapper
      $isExpanded={!isShowingDetails}
      $paddingTop={isTransferRemittancesFlagOn ? '0' : paddingTop}
    >
      {isShowingDetails ? (
        <TransferDetailsRevealed
          isExpanded={!isShowingDetails}
          paddingBottom={isTransferRemittancesFlagOn ? paddingBottom : '0'}
          toggleShowDetails={toggleShowDetails}
          isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
        />
      ) : (
        <TransferDetailsHidden
          isExpanded={!isShowingDetails}
          paddingBottom={isTransferRemittancesFlagOn ? paddingBottom : '0'}
          modalMinHeight={modalMinHeight}
          behindModalRef={behindModalRef}
          isAnimationComplete={isAnimationComplete}
          setIsAnimationComplete={setIsAnimationComplete}
          toggleShowDetails={toggleShowDetails}
          isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
        />
      )}
    </styled.HeaderWrapper>
  )
}
