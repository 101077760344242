import { useContext } from 'react'

import { CashflowReportingTransactionsContext } from '../../context/CashflowReportingTransactions.context'

export const useCashflowReportingTransactionsContext = () => {
  const cashflowReportingTransactionsContext = useContext(
    CashflowReportingTransactionsContext
  )

  if (cashflowReportingTransactionsContext === null) {
    throw new Error(
      'useCashflowReportingTransactionsContext must be used within CashflowReportingTransactionsContext'
    )
  }

  return cashflowReportingTransactionsContext
}
