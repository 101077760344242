import { useHistory, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { showSuccessToast } from '@npco/zeller-design-system'
import { rvIsUserSigningUp } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useEntityUuidOnboarding } from 'features/OnboardingApp/hooks/useEntityUuidOnboarding/useEntityUuidOnboarding'

import { ROOT } from 'const/routes'
import { getIsEntityOnboarded } from 'utils/onboardingStatus'
import { translate } from 'utils/translations'

export const useOnboardingValidateCode = () => {
  const history = useHistory()
  const client = useApolloClient()
  const { state } = useLocation<{ phone: string | undefined }>()
  const phone = state?.phone || ''
  const { setUserData, userData } = useLoggedInUser()
  const shortEntityId = useSelectedShortEntityUuid()
  const { entityUuid } = useEntityUuidOnboarding()

  const showSuccessValidation = () => {
    showSuccessToast(translate('page.validateCode.successValidation'))
  }

  const onSubmitCallback = () => {
    setUserData({
      phone,
      phoneVerified: true,
    })

    client.cache.modify({
      id: client.cache.identify({
        __typename: 'Customer',
        id: userData?.id,
        entityUuid,
      }),
      fields: {
        phone() {
          return phone
        },
        phoneVerified() {
          return true
        },
      },
    })

    if (getIsEntityOnboarded()) {
      showSuccessValidation()
      history.push(ROOT.ORGS.ORG(shortEntityId).KYC.NEW_USER.path)
      return
    }
    // if not onboarded, means it's a new user
    rvIsUserSigningUp(true)
    history.push(ROOT.ACCOUNT_CREATED.path)
  }

  return {
    onSubmitCallback,
    phone,
  }
}
