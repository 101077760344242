import { ErrorLogger } from '@npco/utils-error-logger'

import { useCustomerEntityMappingQuery as useCustomerEntityMappingQueryApollo } from './graphql/CustomerEntityMapping.generated'

type UseCustomerEntityMappingProps = {
  skip: boolean
}

export const useCustomerEntityMappingData = ({
  skip,
}: UseCustomerEntityMappingProps) => {
  const { data, loading, error } = useCustomerEntityMappingQueryApollo({
    skip,
    errorPolicy: 'all',
    onError: (e) => {
      ErrorLogger.reportError(
        '[MultiEntity] Error initialising entity mapping',
        e
      )
    },
  })

  const defaultEntityUuid = data?.getCustomerEntityMapping?.defaultEntityUuid
  const entityRelations = data?.getCustomerEntityMapping?.entityRelations ?? []

  return { defaultEntityUuid, entityRelations, isLoading: loading, error }
}
