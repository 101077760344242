import {
  BodyDefaultTypography,
  BodyLargeTypography,
  BodySmallTypography,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: auto;
  ${BodySmallTypography};
  gap: 0.25rem;

  @media (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: column;
    justify-content: right;
    text-align: right;
  }
`

export const StyledDetailsLabel1 = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.GREY_550};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyDefaultTypography}
  }
`

export const StyledDetailsLabel2 = styled.span<{ isLabel1Visible: boolean }>`
  display: block;
  color: ${({ theme }) => theme.colors.BLACK_900};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyLargeTypography}
    ${InterZellerMediumFont};
  }
`
