import { ROOT } from 'const/routes'
import { getIsOnPage } from 'utils/common'

export const getAreTabsVisible = (pathname: string) => {
  const isUserOnUsersPage = [
    ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.EDIT.path,
    ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_TYPE.path,
    ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_DETAILS.path,
  ].some((route) => getIsOnPage(route, pathname))

  return (
    getIsOnPage(`${ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.path}/*`, pathname) &&
    !isUserOnUsersPage
  )
}
