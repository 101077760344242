import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledButton = styled.button`
  ${BodyDefaultTypography};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  transition: background-color 0.3s ease-out;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
  }
`

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`
