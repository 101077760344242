import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const showEditTagsSuccess = () => {
  showSuccessToast(translate('page.transactionDetails.tags.editSuccess'))
}

export const showEditTagsError = () => {
  showErrorToast(translate('page.transactionDetails.tags.editFailure'))
}

export const showDeleteTagsSuccess = () => {
  showSuccessToast(translate('component.tags.tagDeleteSuccessMessage'))
}

export const showDeleteTagsError = () => {
  showErrorToast(translate('component.tags.tagDeleteFailMessage'))
}
