import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { AddressToggle } from '../../AddressToggle/AddressToggle'
import { CustomAddressFormValues } from '../../DeliveryAddress.types'

interface GoToPredefinedAddressPageProps {
  toggleDescription?: string
  onClick: (values: CustomAddressFormValues) => void
}
export const GoToPredefinedAddressPage = ({
  toggleDescription = translate('page.deliveryAddressForm.description'),
  onClick,
}: GoToPredefinedAddressPageProps) => {
  const { values } = useFormikContext<CustomAddressFormValues>()

  const handleOnClick = useCallback(() => onClick(values), [onClick, values])

  return (
    <AddressToggle
      toggleDescription={toggleDescription}
      buttonLabel={translate('shared.cancel')}
      onClick={handleOnClick}
    />
  )
}
