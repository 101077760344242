import { useMemo } from 'react'
import { VelocityWindowEnum } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { number, object, string } from 'yup'

import {
  SPEND_CONTROL_LIMIT_MIN_VALUE,
  SPEND_CONTROL_MAX_TRANSACTION_LIMIT_MAX_VALUE,
  SPEND_CONTROL_MAX_TRANSACTION_LIMIT_MIN_VALUE,
} from '../../SpendControlFields.constants'
import { validationTranslations } from './useValidation.i18n'

export const useValidation = () => {
  const t = useTranslations(validationTranslations)

  return useMemo(
    () =>
      object({
        amountLimit: number()
          .required(t('spendControlLimitRequiredError'))
          .min(SPEND_CONTROL_LIMIT_MIN_VALUE, t('spendControlLimitMinError')),
        velocityWindow: string()
          .oneOf([
            VelocityWindowEnum.DAY,
            VelocityWindowEnum.WEEK,
            VelocityWindowEnum.FORTNIGHT,
            VelocityWindowEnum.MONTH,
            VelocityWindowEnum.QUARTER,
          ])
          .required(t('spendControlFrequencyRequiredError')),
        maxTransactionValue: number()
          .required(t('spendControlMaxTransactionLimitRequiredError'))
          .min(
            SPEND_CONTROL_MAX_TRANSACTION_LIMIT_MIN_VALUE,
            t('spendControlMaxTransactionLimitMinError')
          )
          .max(
            SPEND_CONTROL_MAX_TRANSACTION_LIMIT_MAX_VALUE,
            t('spendControlMaxTransactionLimitMaxError')
          ),
      }),
    [t]
  )
}
