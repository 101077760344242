import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom-v5-compat'
import { rvOnboardingMetadata } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  GetIsRouteBeforeLastCheckpointArgs,
  useIsRouteBeforeLastCheckpoint,
} from 'features/OnboardingApp/shared/OnboardingCheckpointedRoutes/useIsRouteBeforeLastCheckPoint'
import { type Action, type Location } from 'history'
import { z } from 'zod'

import { translate } from 'utils/translations'
import { OnboardingMetadata } from 'types/onboarding'

import backgroundUrl from './background.png?url'
import {
  StyledCopyrightText,
  StyledTerminalsImageAside,
  StyledTerminalsImageFooter,
} from './TerminalsImageSidebar.styled'

const AnimationStateSchema = z.object({
  animation: z.enum(['forward', 'backward', 'none']),
})

type Animation = z.infer<typeof AnimationStateSchema>['animation']

const getAnimationFromLocation = (
  location: Pick<Location, 'state'>
): Animation | undefined => {
  const animationStateResult = AnimationStateSchema.safeParse(location.state)
  if (animationStateResult.success) {
    return animationStateResult.data.animation
  }
  return undefined
}

const getAnimation = ({
  action,
  location,
  flowType,
  previousPathname,
  previousAnimation,
  getIsRouteBeforeLastCheckpoint,
}: {
  action: Action
  location: Pick<Location, 'pathname' | 'state'>
  flowType: OnboardingMetadata['type']
  previousPathname?: Location['pathname']
  previousAnimation?: Animation
  getIsRouteBeforeLastCheckpoint: ({
    flowType,
    lastCheckpointPath,
    componentPath,
  }: GetIsRouteBeforeLastCheckpointArgs) => boolean
}) => {
  if (action === 'POP' && previousAnimation) {
    if (previousAnimation === 'forward') {
      return 'backward'
    }
    if (previousAnimation === 'backward') {
      return 'forward'
    }
    return previousAnimation
  }

  const animationFromLocation = getAnimationFromLocation(location)
  if (animationFromLocation) {
    return animationFromLocation
  }

  const isRouteBeforeLastCheckpoint = getIsRouteBeforeLastCheckpoint({
    flowType,
    componentPath: location.pathname,
    lastCheckpointPath: previousPathname,
  })
  if (isRouteBeforeLastCheckpoint) {
    return 'backward'
  }

  return 'forward'
}

interface TerminalsImageSidebarProps {
  dataTestId?: string
  children?: React.ReactNode
}

export const TerminalsImageSidebar = ({
  dataTestId,
  children,
}: TerminalsImageSidebarProps) => {
  const location = useLocation()
  const navigationType = useNavigationType()
  const [slideIndex, setSlideIndex] = useState(0)
  const previousPathname = useRef<Location['pathname']>(location.pathname)
  const previousAnimation = useRef<Animation | undefined>(
    getAnimationFromLocation(location)
  )
  const { getIsRouteBeforeLastCheckpoint } = useIsRouteBeforeLastCheckpoint()
  useEffect(() => {
    if (location.pathname === previousPathname.current) {
      return
    }

    const { type: flowType } = rvOnboardingMetadata()
    const animation = getAnimation({
      action: navigationType,
      location,
      flowType,
      previousPathname: previousPathname.current,
      previousAnimation: previousAnimation.current,
      getIsRouteBeforeLastCheckpoint,
    })

    if (animation === 'forward') {
      setSlideIndex((index) => index + 1)
    } else if (animation === 'backward') {
      setSlideIndex((index) => index - 1)
    }

    previousPathname.current = location.pathname
    previousAnimation.current = animation
  }, [getIsRouteBeforeLastCheckpoint, location, navigationType])

  const style = useMemo(
    () => ({
      '--slide-index': slideIndex,
      backgroundImage: `url(${backgroundUrl})`,
    }),
    [slideIndex]
  )

  return (
    <StyledTerminalsImageAside data-testid={dataTestId} style={style}>
      <StyledTerminalsImageFooter>
        {children}
        <StyledCopyrightText>
          {translate('shared.copyright.text1', {
            year: new Date().getFullYear(),
          })}
          <br />
          {translate('shared.copyright.text2')}
        </StyledCopyrightText>
      </StyledTerminalsImageFooter>
    </StyledTerminalsImageAside>
  )
}
