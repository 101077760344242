import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { ErrorLogger } from '@npco/utils-error-logger'

import {
  GenerateDebitCardPanToken,
  GenerateDebitCardPanTokenMutationResponse,
  GenerateDebitCardPanTokenMutationVariables,
} from './graphql/generateDebitCardPanToken.generated'

export const useDebitCardPanToken = () => {
  const [generatePanTokenMutation, { data, loading, error }] = useMutation<
    GenerateDebitCardPanTokenMutationResponse,
    GenerateDebitCardPanTokenMutationVariables
  >(GenerateDebitCardPanToken)

  const generatePanToken = useCallback(
    async (variables: GenerateDebitCardPanTokenMutationVariables) => {
      try {
        await generatePanTokenMutation({
          variables,
        })
      } catch (err) {
        ErrorLogger.report('[Banking] Error generating pan token:', err)
      }
    },
    [generatePanTokenMutation]
  )

  const clientAccessToken = loading ? undefined : data?.generatePanToken

  return {
    clientAccessToken,
    isLoading: loading,
    generateTokenError: error,
    generatePanToken,
  }
}
