import { useMemo } from 'react'
import { Box, Flex, Lightbox } from '@npco/zeller-design-system'

import { ReactComponent as ChevronLeftIcon } from 'assets/svg/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg'
import { FileWithId } from 'components/File'

import { MobileImageControl } from './MobileImageControl'
import { TileSelector } from './TileSelector'

interface LightboxSelectorProps {
  children: React.ReactNode
  areImageControlsEnabled: boolean
  onPrevious: () => void
  onNext: () => void
  previewId: string | null
  setPreviewId: (id: string | null) => void
  onAdd: () => void
  images: FileWithId[]
  indexText: React.ReactNode
  isLoading?: boolean
}

export const LightboxSelector = ({
  children,
  areImageControlsEnabled,
  onPrevious,
  onNext,
  previewId,
  setPreviewId,
  onAdd,
  images,
  indexText,
  isLoading,
}: LightboxSelectorProps) => {
  const shouldRenderImageControls = useMemo(() => {
    return !isLoading && areImageControlsEnabled
  }, [isLoading, areImageControlsEnabled])

  return (
    <Box>
      <Flex
        width="100%"
        marginBottom="30px"
        marginX={{ _: '16px', SM: '32px' }}
      >
        {shouldRenderImageControls && (
          <Box
            display={{ _: 'none', XS: 'flex' }}
            data-testid="lightbox-content-image-control-desktop-left"
          >
            <Lightbox.Button onClick={onPrevious} icon={ChevronLeftIcon} />
          </Box>
        )}
        {children}
        {shouldRenderImageControls && (
          <Box
            display={{ _: 'none', XS: 'flex' }}
            data-testid="lightbox-content-image-control-desktop-right"
          >
            <Lightbox.Button onClick={onNext} icon={ChevronRightIcon} />
          </Box>
        )}
      </Flex>
      {!isLoading && (
        <TileSelector
          images={images}
          previewId={previewId}
          setPreviewId={setPreviewId}
          onAdd={onAdd}
        />
      )}
      {!isLoading && (
        <MobileImageControl
          areImageControlsEnabled={areImageControlsEnabled}
          onPrevious={onPrevious}
          onNext={onNext}
          indexText={indexText}
        />
      )}
    </Box>
  )
}
