import { gql } from '@apollo/client'
import { Box, COLOR } from '@npco/zeller-design-system'

import { StatusContent } from './StatusContent'
import { StatusOverlayDebitCardV2Fragment } from './StatusOverlay.generated'

interface StatusOverlayProps {
  card: StatusOverlayDebitCardV2Fragment
  isLoadingStatus?: boolean
}

export const StatusOverlay = ({
  card,
  isLoadingStatus = false,
}: StatusOverlayProps) => {
  return (
    <Box
      borderRadius="50px"
      backgroundColor={COLOR.WHITE}
      position="absolute"
      zIndex={10}
      p={isLoadingStatus ? '12px 16px' : '14px 24px'}
    >
      <StatusContent status={card.status} isLoadingStatus={isLoadingStatus} />
    </Box>
  )
}

StatusOverlay.fragments = {
  DebitCardV2: gql`
    fragment StatusOverlayDebitCardV2Fragment on DebitCardV2 {
      status
    }
  `,
}
