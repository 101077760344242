import { BodyDefault, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTitle = styled(H3)`
  margin: 0 0 1rem;
`

export const StyledDescription = styled(BodyDefault)`
  margin: 0 0 1.5rem;
`
