import { useReactiveVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { rvNewUserType } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Formik } from 'formik'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { useDisplayXeroPaymentServiceConnectionInfo } from 'hooks/xeroPaymentServices/useDisplayXeroPaymentServiceConnectionInfo'
import { CustomerDetailsForm } from 'types/customers'
import { SpinnerWrapped } from 'components/Spinner'

import { useSettingsUser } from './User.hooks'
import { StyledWrapper } from './User.styled'
import { UserProps } from './User.types'
import { UserForm } from './UserForm/UserForm'
import { UserFormCustomerFragment } from './UserForm/UserForm.generated'

const { ADMIN } = CustomerRole

export const User = ({ isEditForm, isUsers = false }: UserProps) => {
  const isXeroPaymentServicesEnabled =
    useDisplayXeroPaymentServiceConnectionInfo()
  const isInvoicingEnabled = useIsInvoicingEnabled()

  const role = useReactiveVar(rvNewUserType) as CustomerRole
  const currentUserData = useReactiveVar(rvCurrentUserData)

  const {
    customerUuid,
    customerDetails,
    isCustomerDetailsLoading,
    onSubmitEditForm,
    onSubmitCreateForm,
    isPendingAssignSites,
    isInvitationPending,
    isLoading,
    initialValues,
    deleteCustomer,
    cards,
    isCardsLoading,
    cardError,
    cardRefetch,
    loadingDeleteCustomer,
    redirect,
  } = useSettingsUser({
    isEditForm,
    isUsers,
    role,
    isXeroPaymentServicesEnabled,
  })

  const isAdmin = currentUserData?.role === ADMIN

  const isEmailDisabled = !isEditForm
    ? false
    : isEditForm && (!isInvitationPending || !isAdmin)

  const handleOnSubmit = isEditForm ? onSubmitEditForm : onSubmitCreateForm

  return (
    <>
      {isPendingAssignSites || isLoading ? (
        <StyledWrapper isEditMode={isEditForm}>
          <SpinnerWrapped variant="top" />
        </StyledWrapper>
      ) : (
        <Formik<CustomerDetailsForm>
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleOnSubmit}
        >
          <UserForm
            customerUuid={customerUuid}
            loadingDeleteCustomer={loadingDeleteCustomer}
            customerDetails={
              customerDetails?.getCustomer as UserFormCustomerFragment
            }
            isCustomerDetailsLoading={isCustomerDetailsLoading}
            deleteCustomer={deleteCustomer}
            isEditForm={!!isEditForm}
            isEmailDisabled={isEmailDisabled}
            isInvitationPending={!!isInvitationPending}
            isInvoicesEnabled={isInvoicingEnabled}
            isXeroPaymentServicesEnabled={isXeroPaymentServicesEnabled}
            redirect={redirect}
            associatedCards={cards}
            isCardsLoading={isCardsLoading}
            cardError={cardError}
            cardRefetch={cardRefetch}
            isAdminRole={customerDetails?.getCustomer.role === ADMIN}
          />
        </Formik>
      )}
    </>
  )
}
