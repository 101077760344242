import { Box } from '@npco/zeller-design-system'

import { AvailableFunds } from '../AvailableFunds/AvailableFunds'

interface AvailableFundsDisplayProps {
  availableFundsForDisplay: number | null
  isMobile: boolean
  isLoading?: boolean
}
export const AvailableFundsDisplay = ({
  availableFundsForDisplay,
  isMobile,
  isLoading = false,
}: AvailableFundsDisplayProps) => (
  <Box mt="14px">
    <AvailableFunds
      availableFunds={availableFundsForDisplay}
      align={isMobile ? 'center' : 'start'}
      isLoading={isLoading}
    />
  </Box>
)
