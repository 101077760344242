import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
  Typography,
} from '@npco/zeller-design-system'

import { useUnpairOraclePos } from 'hooks/oraclePos/useUnpairOraclePos'

import { translations } from './UnpairModal.i18n'

type UnpairModalProps = {
  deviceName: string
  deviceUuid: string
  isOpen: boolean
  onCancel: () => void
}
export const UnpairModal = ({
  deviceName,
  deviceUuid,
  isOpen,
  onCancel,
}: UnpairModalProps) => {
  const t = useTranslations(translations)
  const { unpairOraclePos, isUnpairingOraclePos } = useUnpairOraclePos({
    deviceUuid,
    onError: () => showErrorToast(t('unpairErrorToast')),
    onSuccess: () => {
      showSuccessToast(t('unpairSuccessToast'))
      onCancel()
    },
  })
  return (
    <DecisionModal
      isOpen={isOpen}
      onCancel={onCancel}
      title={t('title')}
      primaryButtonLabel={t('primaryButtonLabel')}
      secondaryButtonLabel={t('secondaryButtonLabel')}
      onClickPrimary={unpairOraclePos}
      onClickSecondary={onCancel}
      isLoadingPrimary={isUnpairingOraclePos}
    >
      {t('description', {
        deviceName: (
          <Typography variant="heading-md" component="span">
            {deviceName}
          </Typography>
        ),
      })}
    </DecisionModal>
  )
}
