import { Form, Formik } from 'formik'

import { OutstandingExpensesLeavingRoutePromptGuard } from 'components/RouteLeavingPromptGuard'

import { OutstandingExpensesButtons } from '../OutstandingExpensesButtons'
import { OutstandingExpensesFields } from './components/OutstandingExpensesFields'
import { useOutstandingExpensesForm } from './hooks/useOutstandingExpensesForm'
import { OutstandingExpensesFormFragment } from './OutstandingExpensesForm.generated'
import { OutstandingExpensesRequirementConfigValue } from './OutstandingExpensesForm.types'

interface OutstandingExpensesFormProps {
  entity: OutstandingExpensesFormFragment
}
export const OutstandingExpensesForm = ({
  entity,
}: OutstandingExpensesFormProps) => {
  const { handleSubmitForm, loading } = useOutstandingExpensesForm(entity)
  const requirementConfig = entity.outstandingTransactionRequirementConfig

  return (
    <Formik<OutstandingExpensesRequirementConfigValue>
      initialValues={{
        isNoteRequired: requirementConfig?.note ?? false,
        isReceiptRequired: requirementConfig?.attachments ?? false,
        isCategoryRequired: requirementConfig?.category ?? false,
      }}
      onSubmit={handleSubmitForm}
    >
      <Form>
        <OutstandingExpensesLeavingRoutePromptGuard />
        <OutstandingExpensesFields />
        <OutstandingExpensesButtons isSubmitLoading={loading} />
      </Form>
    </Formik>
  )
}
