import { makeVar } from '@apollo/client'
import { InitialCustomerDataInput } from '@npco/mp-gql-types'
import { AbnOrAcnSet } from 'features/OnboardingApp/OnboardingRoutes/CompanySearch.types'

import { InitialMemberData, TemporaryCustomer } from 'types/customers'
import {
  OnboardingDataSummary,
  OnboardingInitialSearchData,
  OnboardingMetadata,
  SkippedPurchaseData,
} from 'types/onboarding'

export const rvOnboardingMetadata = makeVar({} as OnboardingMetadata)

export const rvOnboardingInitialSearchData = makeVar<
  Partial<OnboardingInitialSearchData>
>({})

export const rvIsUserSavingOnboarding = makeVar(false)

export const rvIsGovernmentCustomerUpdated = makeVar(false)

export const rvOnboardingDataSummary = makeVar<OnboardingDataSummary>({})

export const rvOnboardingInitialCustomerData =
  makeVar<InitialCustomerDataInput>({})

export const rvOnboardingIsProcessing = makeVar(false)

export const rvOnboardingIsWarningVisible = makeVar(false)

export const rvCustomersTempData = makeVar([] as TemporaryCustomer[])

export const rvOnboardingAreCustomersTempDataPrepared = makeVar(false)

export const rvCustomersInitialMembersData = makeVar([] as InitialMemberData[])

export const rvCustomersCurrentCustomerTempId = makeVar<string | undefined>(
  undefined
)

export const rvOnboardingSkippedPurchaseData = makeVar(
  {} as SkippedPurchaseData
)

export const rvInactiveAbnOrAcns = makeVar<AbnOrAcnSet>({})

export const rvUnsupportedAbnOrAcns = makeVar<AbnOrAcnSet>({})

export const rvThirdPartyCompanySearchError = makeVar<boolean>(false)
