import { useMemo, useState } from 'react'
import { validateRequired } from '@npco/component-mp-common'
import { Box, Contacts } from '@npco/zeller-design-system'
import { getContactsInputComboboxItems } from 'features/Contacts/Contacts.utils'
import { useContacts } from 'features/Contacts/hooks/useContacts/useContacts'
import { useField, useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { InvoiceContactComboboxHeader } from '../../../../../../InvoiceContactComboboxHeader/InvoiceContactComboboxHeader'
import {
  CreateNewContactEvent,
  InvoiceLinkEmailToContactFormFields,
} from '../../InvoiceEmailRecipients.types'
import { INVOICE_LINK_CONTACT_FIELD } from '../InvoiceLinkEmailToContactModal.constants'

export const translations = {
  contactLabel: translate(
    'page.invoice.formSections.email.saveEmailToContactComboboxLabel'
  ),
  contactPlaceholder: translate(
    'page.invoice.formSections.email.saveEmailToContactComboboxPlaceholder'
  ),
  validationErrorMessages: {
    CONTACT_REQUIRED: translate(
      'page.invoice.formSections.email.saveEmailToContactComboboxRequired'
    ),
    CONTACT_SELECTION_REQUIRED: translate(
      'page.invoice.formSections.customer.payerRequired'
    ),
  },
}

interface InvoiceLinkEmailToContactSelectProps {
  onCreateNewContact: CreateNewContactEvent
}

export const InvoiceLinkEmailToContactSelect = ({
  onCreateNewContact: handleCreateNewContact,
}: InvoiceLinkEmailToContactSelectProps) => {
  const {
    values: { email },
  } = useFormikContext<InvoiceLinkEmailToContactFormFields>()
  const [validationError, setValidationError] = useState('')
  const { filters, name$ } = Contacts.useContactsFilters()
  const [, meta, helpers] = useField({
    name: INVOICE_LINK_CONTACT_FIELD,
    validate: validateRequired,
  })

  const fieldValidationError =
    meta.touched && meta.error
      ? translations.validationErrorMessages.CONTACT_REQUIRED
      : ''

  const {
    handleChange,
    handleClose,
    handleInputChange,
    handleInputClear,
    inputValue: contactInputValue,
    selectedItem,
  } = Contacts.useContactCombobox({
    name$,
    onChange: (changes) => {
      helpers.setValue(changes.selectedItem?.id)
    },
    onInputClear: () => {
      helpers.setValue('')
    },
    onValidationError: setValidationError,
    validationErrorMessages: translations.validationErrorMessages,
  })

  const {
    contacts,
    loading: isLoading,
    hasMore,
    loadMore,
  } = useContacts({
    filters,
    limit: 50,
    isNetworkOnly: true,
  })

  const contactItems = useMemo(
    () => getContactsInputComboboxItems(contacts),
    [contacts]
  )

  return (
    <Box>
      <Contacts.ContactCombobox
        hasMore={hasMore}
        inputLabel={translations.contactLabel}
        inputValue={contactInputValue}
        isLoading={isLoading}
        items={contactItems}
        loadMore={loadMore}
        name={INVOICE_LINK_CONTACT_FIELD}
        onChange={handleChange}
        onClose={handleClose}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        renderHeader={({ closeMenu }) => (
          <InvoiceContactComboboxHeader
            closeMenu={closeMenu}
            onSelection={(contactType) =>
              handleCreateNewContact(contactType, contactInputValue, email)
            }
          />
        )}
        selectedItem={selectedItem}
        error={validationError || fieldValidationError}
      />
    </Box>
  )
}
