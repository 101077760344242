import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { GetCustomerBasicInfo_getCustomer as Customer } from 'types/gql-types/GetCustomerBasicInfo'
import {
  AssignedCardsList,
  useGetActiveAssignedCards,
} from 'components/AssignedCardsList'

import { StyledDescription, StyledDetailsTitle } from '../UserDetails.styled'
import { translations } from './AssignedCardsSection.i18n'

export type AssignedCardsSectionProps = {
  idVerificationStatus: string
  isInvitationPending: boolean
  isAdmin: boolean
  isLoadingUserDetails: boolean
  selectedUser: Customer
}
export const AssignedCardsSection = ({
  idVerificationStatus,
  isAdmin,
  isInvitationPending,
  isLoadingUserDetails,
  selectedUser,
}: AssignedCardsSectionProps) => {
  const t = useTranslations(translations)
  const { isCardsLoading, cards, error, refetch } = useGetActiveAssignedCards({
    selectedUserId: selectedUser.id,
  })
  const description = isAdmin ? t('adminDescription') : t('managerDescription')

  const isUserVerified = idVerificationStatus === 'Verified'
  const hasInfobox = !isLoadingUserDetails && !isUserVerified

  return (
    <Flex flexDirection="column" gap="16px">
      <StyledDetailsTitle>{t('title')}</StyledDetailsTitle>
      {hasInfobox && (
        <InfoBox
          variant={INFOBOX_VARIANT.NEGATIVE}
          priority={INFOBOX_PRIORITY.MEDIUM}
          size={INFOBOX_SIZE.DEFAULT}
        >
          <InfoBox.Message>
            {t('unverifiedInfo', { firstName: selectedUser.firstname })}
            {!isInvitationPending && (
              <>
                {t('idvInfoPrefix')}
                <span style={{ fontWeight: 500 }}>{t('idvLocation')}</span>
              </>
            )}
          </InfoBox.Message>
        </InfoBox>
      )}
      {isUserVerified && <StyledDescription>{description}</StyledDescription>}
      <AssignedCardsList
        cards={cards}
        isLoading={isCardsLoading || isLoadingUserDetails}
        refetch={refetch}
        error={error}
      />
    </Flex>
  )
}
