import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SelectCardTypeForm } from './SelectCardTypeForm/SelectCardTypeForm'

export const SelectCardTypeStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.CreateSelectCardTypeStage}
    >
      <SelectCardTypeForm dataTestId="select-card-type-stage" />
    </Condition>
  )
}
