import { Dispatch, SetStateAction, useState } from 'react'
import { useMutation } from '@apollo/client'
import { showSuccessToast } from '@npco/zeller-design-system'
import { UpdateCustomer } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'
import {
  rvNewUserType,
  rvSelectedUser,
  rvUpdatedUserDetails,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { ApiCallType, useRedirectToMFA } from 'features/MFA'

import { AuthScope } from 'types/auth'
import { CustomerDetailsForm } from 'types/customers'
import {
  UpdateCustomer as UpdateCustomerResponse,
  UpdateCustomerVariables,
} from 'types/gql-types/UpdateCustomer'
import { page } from 'translations'

import { useCustomerUtils } from '../useCustomerUtils'
import {
  getHasCurrentSite,
  handleCustomerError,
  initialValuesCreate,
  updateCustomerInStorage,
} from '../utils'

export const useUpdateCustomer = (
  initialValues: CustomerDetailsForm,
  redirect: () => void,
  setIsPendingAssignSites: Dispatch<SetStateAction<boolean>>,
  isUsers: boolean
) => {
  const { updateCachedCustomer, deleteCustomerFromStorage } = useCustomerUtils()
  const { redirectToMFA } = useRedirectToMFA()
  const [updateCustomerInput, setUpdateCustomerInput] =
    useState<CustomerDetailsForm>(initialValuesCreate)
  const [initialValuesMFA, setInitialValuesMFA] =
    useState<CustomerDetailsForm>(initialValuesCreate)
  const [updateCustomer, { loading: loadingUpdateCustomer }] = useMutation<
    UpdateCustomerResponse,
    UpdateCustomerVariables
  >(UpdateCustomer, {
    onCompleted: async () => {
      setIsPendingAssignSites(true)
      const updatedUserDetails = rvUpdatedUserDetails()
      const userAssignedSites = initialValues.sites

      if (isUsers) {
        updateCachedCustomer(userAssignedSites)
      }

      updateCustomerInStorage(updatedUserDetails)

      if (
        !isUsers &&
        userAssignedSites &&
        !getHasCurrentSite(userAssignedSites)
      ) {
        deleteCustomerFromStorage(updatedUserDetails.id as string)
      }

      setIsPendingAssignSites(false)
      rvNewUserType(null)
      redirect()

      const hasEmailChanged = initialValues.email !== updatedUserDetails.email

      const successMessage = hasEmailChanged
        ? page.settings.users.successUpdateUserWithEmail
        : page.settings.users.successUpdateUser

      showSuccessToast(successMessage)
    },
    onError: (err) => {
      handleCustomerError(err, redirectToMFA, {
        apiCallType: ApiCallType.UpdateAdmin,
        scope: AuthScope.SENSITIVE,
        variables: { id: rvSelectedUser(), ...updateCustomerInput },
        initialValues: initialValuesMFA,
      })
    },
  })

  const submitUpdatedValuesForMFA = (inputValues: CustomerDetailsForm) => {
    setUpdateCustomerInput(inputValues)
  }

  const submitInitialValuesForMFA = (values = initialValuesCreate) => {
    setInitialValuesMFA(values)
  }

  return {
    updateCustomer,
    loadingUpdateCustomer,
    setUpdateCustomerInput,
    submitUpdatedValuesForMFA,
    submitInitialValuesForMFA,
  }
}
