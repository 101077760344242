import { SyntheticEvent, useEffect, useState } from 'react'
import { GetInvoicesAmountFilterInput } from '@npco/mp-gql-types'
import { rvSelectedRates as updateSelectedRates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { InvoiceTableDefaultAmount } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import {
  RangePickerValue,
  RangeType,
  RangeValue,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import {
  adjustRangeValuesForEdgeConditions,
  getNewRangeFromEvent,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.utils'

import { AmountColumnEnum } from '../../InvoiceTable.types'

interface UseAmountFiltersProps {
  debounceDuration?: number
  selectedAmount?: RangePickerValue
  selectedAmountColumn: AmountColumnEnum
  setAmountFilter: (input: GetInvoicesAmountFilterInput | null) => void
}

export const useAmountFilters = ({
  debounceDuration = 500,
  selectedAmount = InvoiceTableDefaultAmount,
  selectedAmountColumn,
  setAmountFilter,
}: UseAmountFiltersProps) => {
  const [[inputMin, inputMax], setInputRange] =
    useState<[RangeValue, RangeValue]>(selectedAmount)

  const [[sliderMin, sliderMax], setSliderRange] =
    useState<RangePickerValue>(selectedAmount)

  const [inputChange$] = useState(new Subject<RangePickerValue>())
  const [afterChange$] = useState(new Subject<RangePickerValue>())

  useEffect(() => {
    const afterSubscription = new Subscription()
    const inputSubscription = new Subscription()

    afterSubscription.add(
      afterChange$
        .pipe(distinctUntilChanged())
        .subscribe((changedRange: RangePickerValue) =>
          setAmountFilter({
            columnName: selectedAmountColumn,
            to: changedRange[1].toString(),
            from: changedRange[0].toString(),
          })
        )
    )

    inputSubscription.add(
      inputChange$
        .pipe(debounceTime(debounceDuration), distinctUntilChanged())
        .subscribe((changedRange: RangePickerValue) =>
          setAmountFilter({
            columnName: selectedAmountColumn,
            to: changedRange[1].toString(),
            from: changedRange[0].toString(),
          })
        )
    )

    return () => {
      afterSubscription.unsubscribe()
      inputSubscription.unsubscribe()
    }
  }, [
    afterChange$,
    debounceDuration,
    inputChange$,
    selectedAmountColumn,
    setAmountFilter,
  ])

  const onInputChange = (event: SyntheticEvent, type: RangeType) => {
    const newRangeFromInput = getNewRangeFromEvent(
      [inputMin, inputMax],
      event,
      type
    )

    setInputRange(newRangeFromInput)

    const changedRange = adjustRangeValuesForEdgeConditions(
      newRangeFromInput as [RangeValue, RangeValue],
      type,
      [InvoiceTableDefaultAmount[0], InvoiceTableDefaultAmount[1]]
    )

    updateSelectedRates(changedRange)
    setSliderRange(changedRange)

    inputChange$.next(changedRange)
  }

  const onSliderChange = (range: RangePickerValue) => {
    setSliderRange(range)
    setInputRange(range)
  }

  const onAfterChange = (newValue: number | readonly number[]) => {
    const changedRange = newValue as RangePickerValue

    updateSelectedRates(changedRange)
    setInputRange(changedRange)

    afterChange$.next(changedRange)
  }

  const handleResetClick = () => {
    setSliderRange(InvoiceTableDefaultAmount)
    setInputRange(InvoiceTableDefaultAmount)
  }

  return {
    handleResetClick,
    inputMax,
    inputMin,
    onAfterChange,
    onInputChange,
    onSliderChange,
    setInputRange,
    setSliderRange,
    sliderMax,
    sliderMin,
  }
}
