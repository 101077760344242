import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { ZELLER_SUPPORT_URL } from 'const/externalLinks'

import { translations } from './Warning.i18n'
import * as styled from './Warning.styled'

export const Warning = () => {
  const t = useTranslations(translations)

  return (
    <Flex marginTop="16px">
      <InfoBox
        priority={INFOBOX_PRIORITY.MEDIUM}
        variant={INFOBOX_VARIANT.NEGATIVE}
        size={INFOBOX_SIZE.SMALL}
      >
        <InfoBox.Message>{t('warning')}</InfoBox.Message>
        <br />
        <styled.Link href={ZELLER_SUPPORT_URL}>
          {ZELLER_SUPPORT_URL}
        </styled.Link>
      </InfoBox>
    </Flex>
  )
}
