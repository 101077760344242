import { Auth0Error } from 'auth0-js'

import { getErrorMessage } from 'utils/authErrors'
import { errorMessages } from 'translations'

import { EmailInUseError } from './EmailInUseError/EmailInUseError'
import {
  InvalidPasswordError,
  InvalidPasswordErrorMap,
  SignUpError,
  SignUpErrorMap,
} from './SignUp.types'

const SIGNUP_ERROR_MSG_MAP: SignUpErrorMap = {
  [SignUpError.INVALID_SIGNUP]: <EmailInUseError />,
  [SignUpError.USERNAME_EXISTS]: <EmailInUseError />,
  [SignUpError.USER_EXISTS]: <EmailInUseError />,
  [SignUpError.UNAUTHORISED]: errorMessages.tryAgainLater,
  default: errorMessages.tryAgainLater,
}

const INVALID_PASSWORD_ERROR_MSG_MAP: InvalidPasswordErrorMap = {
  [InvalidPasswordError.PASSWORD_NO_USER_INFO_ERROR]:
    errorMessages.passwordUserInfo,
  [InvalidPasswordError.PASSWORD_DICTIONARY_ERROR]:
    errorMessages.passwordTooCommon,
  [InvalidPasswordError.PASSWORD_STRENGTH_ERROR]: errorMessages.invalidPassword,
}

const getInvalidPasswordErrorMessage = (err: Auth0Error) => {
  const defaultErrorMessage = (err.description ||
    errorMessages.invalidPassword) as React.ReactNode
  return getErrorMessage(
    {
      ...INVALID_PASSWORD_ERROR_MSG_MAP,
      default: defaultErrorMessage,
    },
    err.name
  )
}

export const getSignUpErrorMessage = (err: Auth0Error) => {
  if (err.code === SignUpError.EXTENSIBILITY_ERROR) {
    return {
      emailError:
        err.original?.response?.body.friendly_message ||
        errorMessages.emailNotSupportedDomain,
    }
  }
  if (err.code === SignUpError.INVALID_PASSWORD) {
    return { passwordError: getInvalidPasswordErrorMessage(err) }
  }
  return { emailError: getErrorMessage(SIGNUP_ERROR_MSG_MAP, err.code) }
}
