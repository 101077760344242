import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import { useExtendedColumnConfig } from '@npco/ui-table'
import {
  ArrowDecreaseIcon,
  ArrowIncreaseIcon,
  COLOR,
  Flex,
  TableCellBasic,
  TableCellSize,
  TableCellSkeleton,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { formatChange } from '../../../../utils/formatChange/formatChange'
import {
  TotalCellCashFlowCategoryNetAmountFragment,
  TotalCellCashFlowContactNetAmountFragment,
  TotalCellCashFlowSubcategoryNetAmountFragment,
} from './TotalCell.generated'
import * as styled from './TotalCell.styled'

type TotalCellFragment =
  | TotalCellCashFlowCategoryNetAmountFragment
  | TotalCellCashFlowContactNetAmountFragment
  | TotalCellCashFlowSubcategoryNetAmountFragment

export const TotalCell = <T,>({
  cell,
  table,
  row,
  getValue,
  column,
}: CellContext<T, TotalCellFragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<TotalCellFragment>()

  const change = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (data?.__typename === 'CashFlowSubcategoryNetAmount' || !data?.change) {
      return null
    }

    const isIncreaseChange = data.change > 0
    const color = isIncreaseChange ? COLOR.GREEN_1600 : COLOR.RED_1000
    const Icon = isIncreaseChange ? ArrowIncreaseIcon : ArrowDecreaseIcon
    return {
      icon: <Icon color={color} width="8" height="8" />,
      value: formatChange(Math.abs(data.change)),
      color,
    }
  }, [data])

  const total = useMemo(() => {
    const isExpense = table.options.meta?.transactionDirection === 'expense'
    const value = parseInt(data?.total.value ?? '0', 10)
    return isExpense ? -value : value
  }, [data?.total.value, table.options.meta?.transactionDirection])

  return (
    <TableCellBasic
      key={cell.id}
      dataTestId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      justifyContent="end"
    >
      {isLoading && (
        <TableCellSkeleton
          width={skeletonLoaderWidth}
          size={TableCellSize.SMALL}
        />
      )}

      {!isLoading && (
        <Flex flexDirection="column" alignItems="end" width="100%">
          <styled.TotalWrapper>{currencyFormatter(total)}</styled.TotalWrapper>
          {change && (
            <Flex width="100%" justifyContent="end">
              {change.icon}
              <styled.ChangeWrapper color={change.color}>
                <styled.ChangeText>{change.value}</styled.ChangeText>%
              </styled.ChangeWrapper>
            </Flex>
          )}
        </Flex>
      )}
    </TableCellBasic>
  )
}

TotalCell.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment TotalCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      __typename
      change
      total {
        currency
        value
      }
    }
  `,

  CashFlowContactNetAmount: gql`
    fragment TotalCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
      __typename
      change
      total {
        currency
        value
      }
    }
  `,

  CashFlowSubcategoryNetAmount: gql`
    fragment TotalCellCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      __typename
      total {
        currency
        value
      }
    }
  `,
}
