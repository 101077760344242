import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox, INFOBOX_VARIANT } from '@npco/zeller-design-system'

import { noBillersFoundInfoTranslations } from './NoBillersFoundInfo.i18n'

export const NoBillersFoundInfo = () => {
  const t = useTranslations(noBillersFoundInfoTranslations)
  return (
    <Box margin="12px 18px 12px 16px">
      <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
        <InfoBox.Message>{t('info')}</InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
