import { Box, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled from 'styled-components'

export const StyledSavingAccountHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.BLUE_50};
  color: ${({ theme }) => theme.colors.BLACK};
  box-shadow: 0px 3px 10px 0px ${({ theme }) => rgba(theme.colors.BLACK, 0.07)};
  align-items: left;
  border-radius: 8px;
  z-index: 1;
  padding: 12px 6px;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    flex-direction: row;
    align-items: center;
  }
`

export const StyledTitleWrapper = styled(Box)`
  ${({ theme }) => theme.typography['body-sm']};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    ${({ theme }) => theme.typography['body-base']};
  }
`
