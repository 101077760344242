import { ReactNode, useCallback } from 'react'
import {
  ButtonLink,
  InputSelectComboboxItemBasic,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { CustomValidator } from 'types/common'

import { EditTagsModal } from './EditTagsModal'
import { useTagListState } from './hooks/useTagListState'

export const TAGS_DATA_TESTID = 'contact-tags-section'

interface Props {
  buttonDataTestId?: string
  buttonLabel: string
  initialTags: string[]
  inputLabel?: ReactNode
  isAddingEntityTag?: boolean
  isDeletingEntityTag?: boolean
  isSavingTags?: boolean
  itemsInSearch: InputSelectComboboxItemBasic[]
  onAddNewTag: (tag: string) => Promise<boolean>
  onCancelEdit?: (currentTags: string[]) => void
  onDeleteEntityTag: (tag: string) => Promise<boolean>
  onSaveEdit: (currentTags: string[]) => Promise<boolean>
  placeholder?: string
  title?: ReactNode
  validate: CustomValidator<string, string[]>
  onOpenModal?: () => void
  onCloseModal?: () => void
}

export const EditTags = ({
  buttonDataTestId = 'tags-action-button',
  buttonLabel,
  initialTags,
  inputLabel,
  isAddingEntityTag = false,
  isDeletingEntityTag = false,
  isSavingTags = false,
  itemsInSearch,
  onAddNewTag,
  onCancelEdit,
  onDeleteEntityTag,
  onSaveEdit,
  title,
  validate,
  placeholder,
  onOpenModal,
  onCloseModal,
}: Props) => {
  const {
    isModalOpen,
    openModal: openModalInternal,
    closeModal: closeModalInternal,
  } = useModalState()

  const openModal = useCallback(() => {
    openModalInternal()
    onOpenModal?.()
  }, [openModalInternal, onOpenModal])

  const closeModal = useCallback(() => {
    closeModalInternal()
    onCloseModal?.()
  }, [closeModalInternal, onCloseModal])

  const { tags, addTag, removeTag, setTags } = useTagListState(initialTags)

  const handleCancel = useCallback(() => {
    setTags(initialTags)
    onCancelEdit?.(tags)
    closeModal()
  }, [closeModal, initialTags, onCancelEdit, setTags, tags])

  const handleSaveTags = useCallback(async () => {
    const success = await onSaveEdit(tags)

    if (!success) {
      return
    }

    closeModal()
  }, [closeModal, onSaveEdit, tags])

  const handleAddTag = useCallback(
    async (tag: string, isNewTag: boolean) => {
      if (isNewTag) {
        const success = await onAddNewTag(tag)
        if (!success) {
          return false
        }
      }
      addTag(tag)

      return true
    },
    [addTag, onAddNewTag]
  )

  return (
    <>
      {isModalOpen && (
        <EditTagsModal
          itemsInSearch={itemsInSearch}
          isOpen={isModalOpen}
          onCancel={handleCancel}
          onSave={handleSaveTags}
          title={title}
          inputLabel={inputLabel}
          isAddingEntityTag={isAddingEntityTag}
          isDeletingEntityTag={isDeletingEntityTag}
          isSavingTags={isSavingTags}
          onTagClose={removeTag}
          onAddTag={handleAddTag}
          onDeleteEntityTag={onDeleteEntityTag}
          tags={tags}
          validate={validate}
          placeholder={placeholder}
        />
      )}
      <ButtonLink dataTestId={buttonDataTestId} onClick={openModal}>
        {buttonLabel}
      </ButtonLink>
    </>
  )
}
