import { ModalBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { useLockCardForm } from './hooks/useLockCardForm'
import { LockCardDescription } from './LockCardForm.styled'

interface LockCardFormProps {
  dataTestId?: string
}

export const LockCardForm = ({ dataTestId }: LockCardFormProps) => {
  const { handleSubmit, closeLockCardModal, isLockingCard } = useLockCardForm()

  return (
    <>
      <ModalBasic.Body data-testid={dataTestId} mb="12px">
        <LockCardDescription>
          {translate('page.lockCardModal.description')}
        </LockCardDescription>
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={closeLockCardModal}>
          {translate('shared.cancel')}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          onClick={handleSubmit}
          isLoading={isLockingCard}
        >
          {translate('page.lockCardModal.buttonLabel')}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </>
  )
}
