import { useCallback } from 'react'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ImageBroken } from 'assets/svg/image-broken.svg'
import { usePreviewUrl } from 'hooks/usePreviewUrl/usePreviewUrl'
import { KEY_CODE } from 'const/keyboard'

import { SkeletonLoaderAvatar } from '../SkeletonLoader/SkeletonLoaderAvatar'
import { SpinnerWrapped } from '../Spinner'
import {
  StyledFlex,
  StyledImage,
  StyledPage,
  StyledPdf,
} from './ImagePreview.styled'
import { ImagePreviewProps } from './ImagePreview.types'

export const ImagePreview = ({
  className,
  height,
  image,
  isLoading,
  objectFit = 'cover',
  onClick,
  onPdfLoadSuccess,
  onPdfRenderPageSuccess,
  pageNumber = 1,
  renderLoader,
  renderPdfAnnotation,
  showOnLoadAnimation,
  width,
}: ImagePreviewProps) => {
  const isPdf = image.file.type === 'application/pdf'
  const {
    isLoading: isPreviewLoading,
    error: previewError,
    previewUrl,
  } = usePreviewUrl(image)

  const handleClick = useCallback(() => {
    onClick?.(image.id)
  }, [image.id, onClick])

  if (isLoading || isPreviewLoading) {
    return (
      <SkeletonLoaderAvatar
        width={width}
        height={height}
        showOnLoadAnimation={showOnLoadAnimation}
      />
    )
  }

  if (previewError) {
    return (
      <StyledFlex justifyContent="center" width={width} height={height}>
        <SvgIcon
          width="40"
          height="40"
          color={COLOR.GREY_470}
          dataTestId={`error-${image.file.name}`}
        >
          <ImageBroken />
        </SvgIcon>
      </StyledFlex>
    )
  }

  const handleOnKeyDown = ({ key }: { key: string }) => {
    if (key === KEY_CODE.ENTER) {
      handleClick()
    }
  }

  if (isPdf) {
    return (
      <StyledPdf
        data-testid={`preview-${image.file.name}`}
        file={previewUrl}
        width={width}
        height={height}
        onLoadSuccess={onPdfLoadSuccess}
        loading={
          renderLoader ?? (
            <SkeletonLoaderAvatar
              width={width}
              height={height}
              showOnLoadAnimation={showOnLoadAnimation}
            />
          )
        }
        className={className}
      >
        {/* Have to apply tabIndex and onKeyDown on a parent div,
            as Page do not accept them as props */}
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        <div tabIndex={0} onKeyDown={handleOnKeyDown}>
          {/* eslint-enable */}
          <StyledPage
            renderAnnotationLayer={renderPdfAnnotation}
            pageNumber={pageNumber}
            height={height}
            onClick={handleClick}
            onRenderSuccess={onPdfRenderPageSuccess}
            loading={renderLoader ?? <SpinnerWrapped pt="8rem" />}
            width={width}
          />
        </div>
      </StyledPdf>
    )
  }

  return (
    <StyledImage
      data-testid={`preview-${image.file.name}`}
      src={previewUrl}
      alt={image.file.name}
      width={width}
      height={height}
      className={className}
      onClick={handleClick}
      objectFit={objectFit}
      tabIndex={0}
      onKeyDown={handleOnKeyDown}
      $showAppearAnimation={showOnLoadAnimation}
    />
  )
}
