import { createContext, useContext } from 'react'

export type TimeoutContextType = {
  timeoutContext: 'default' | 'onboarding'
  setTimeoutContext: (context: 'default' | 'onboarding') => void
}

export const TimeoutContext = createContext<TimeoutContextType | null>(null)
export const useTimeoutContext = () => {
  const context = useContext(TimeoutContext)
  if (!context) {
    throw new Error(
      'useTimeoutContext must be used within a SessionTimeoutProvider'
    )
  }
  return context
}
