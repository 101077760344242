import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  COLOR,
  Funds,
  Message,
  MessageSize,
  showApiErrorToast,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { useAccountId } from '../../../../hooks/banking'
import { useGetTransferAccountsLazy } from '../../../../pages/Transfer/hooks/useGetTransferAccountsLazy/useGetTransferAccountsLazy'
import { useGoToInternalTransfer } from '../../../../pages/Transfer/hooks/useGoToInternalTransfer/useGoToInternalTransfer'
import { UNEXPECTED_ERROR } from '../../../../types/errors'
import { isNotNull } from '../../../../utils/common'
import { useGoToBlockedAction } from '../../../BlockedAction/blocked-action-routing'
import { savingsAccountEmptyTransactionsTranslations } from './SavingsAccountEmptyTransactions.i18n'
import { getAccountWithHighestFunds } from './SavingsAccountEmptyTransactions.utils'

export const SavingsAccountEmptyTransactions = () => {
  const t = useTranslations(savingsAccountEmptyTransactionsTranslations)
  const { accountId: toAccountId } = useAccountId()
  const { getAccounts, isLoadingAccounts } = useGetTransferAccountsLazy()
  const { goToInternalTransfer } = useGoToInternalTransfer()
  const { goToBlockedAction } = useGoToBlockedAction()
  const isMobile = useIsMobileResolution()

  const fetchAccountsAndGoToTransfer = async () => {
    const result = await getAccounts()
    if (result === UNEXPECTED_ERROR) {
      showApiErrorToast()
      return
    }
    const { accounts, entity } = result

    const debitCardAccounts = accounts.accounts.filter(isNotNull)
    const highestFundsAccountId = getAccountWithHighestFunds(debitCardAccounts)

    const isTransferOutBlocked = entity.canTransferOut === false

    if (isTransferOutBlocked) {
      goToBlockedAction({ blockedAction: 'transferOut' })
      return
    }

    goToInternalTransfer({
      from: highestFundsAccountId ?? '',
      to: toAccountId,
      reference: t('savingsText'),
      transferType: 'internal',
    })
  }

  return (
    <Message
      image={<Funds size="large" variant="outlined-green" ariaLabel="funds" />}
      title={t('title', { lineBreak: <br /> })}
      description={t('description')}
      primaryButton={
        <Box
          pr={['0', '8px']}
          pb={['8px', '0']}
          width={{ _: '100%', XS: 'auto' }}
        >
          <ButtonFill
            onClick={fetchAccountsAndGoToTransfer}
            size={BUTTON_SIZE.LARGE}
            isLoading={isLoadingAccounts}
            fullWidth={isMobile}
          >
            {t('actionButton')}
          </ButtonFill>
        </Box>
      }
      margin={0}
      padding="24px"
      size={MessageSize.LARGE}
      backgroundColor={COLOR.GREY_30}
      align="center"
    />
  )
}
