import {
  BoxProps,
  Flex,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

export const SectionHeaderSkeleton = (
  props: Omit<BoxProps, 'justifyContent'>
) => {
  return (
    <Flex justifyContent="space-between" {...props}>
      <SkeletonTypographyBase
        type={TypographyTypes.H3}
        width="200px"
        margin={0}
      />
      <SkeletonTypographyBase
        type={TypographyTypes.BodyDefault}
        width="48px"
        margin={0}
      />
    </Flex>
  )
}
