import { SourcePickerProvider } from '../SourcePicker/SourcePickerProvider'
import { StyledWrapper } from './DashboardTransactions.styled'
import { PaymentTransactions } from './PaymentTransactions'

export const DashboardTransactions = () => {
  return (
    <StyledWrapper>
      <SourcePickerProvider>
        <PaymentTransactions />
      </SourcePickerProvider>
    </StyledWrapper>
  )
}
