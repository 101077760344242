import { useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { BusinessSimpleFields } from 'features/Contacts/Businesses/BusinessSimple.fields'
import { ContactSimpleFormPersonalDetails } from 'features/Contacts/components/ContactSimpleFormPersonalDetails/ContactSimpleFormPersonalDetails'
import { PersonSimpleFields } from 'features/Contacts/People/PersonSimple.fields'
import { Formik } from 'formik'

import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { shared } from 'translations'

import { InputSelectComboBoxItem } from '../Contacts.types'
import { initialValues } from '../Contacts.utils'
import { useContactFormHelpers } from '../hooks/useContactFormHelpers/useContactFormHelpers'
import { contactSimpleFormTranslations } from './ContactSimpleForm.i18n'

export interface ContactSimpleFormProps {
  onCreate: (contact: CreateContact | CreateContactsContact) => void
  closeModal: () => void
  isModalOpen: boolean
  contactType: ContactType | null
  showPersonalDetailsInput?: boolean
}

export const ContactSimpleForm = ({
  onCreate,
  closeModal,
  isModalOpen,
  contactType,
  showPersonalDetailsInput = true,
}: ContactSimpleFormProps) => {
  const {
    isLoading,
    handleSubmit,
    existingBusinessContactName,
    existingPersonContactName,
    setExistingBusinessContactName,
    setExistingPersonContactName,
  } = useContactFormHelpers({
    onCreate,
    contactType,
  })

  const [isAddingNewSubContact, setIsAddingNewSubContact] = useState(false)

  const [selectedSubContact, setSelectedSubContact] = useState<
    InputSelectComboBoxItem | null | undefined
  >(null)

  const t = useTranslations(contactSimpleFormTranslations)

  const isBusinessContactType = contactType === ContactType.BUSINESS

  const modalTitle = isBusinessContactType
    ? t('titleBusiness')
    : t('titlePerson')

  const modalSubHeading = isBusinessContactType
    ? t('subheadingPersonalDetails')
    : t('subheadingBusinessDetails')

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        try {
          await handleSubmit(values)

          // NOTE: clear all state on submit and then close
          resetForm()
          closeModal()
        } catch (error) {
          ErrorLogger.report('[Payment] Handle contact submit', error)
        }
      }}
    >
      {({ setFieldValue, submitForm, resetForm, values }) => {
        return (
          // NOTE: If we move this out into a child component with useFormikContext,
          // it will break ContactSelectField.spec.ts
          // This may be because the test is mocking useFormikContext.
          <ModalFormScrollable
            cancelLabel={shared.cancel}
            confirmLabel={shared.next}
            isConfirmButtonDisabled={isLoading}
            isLoading={isLoading}
            isOpen={isModalOpen}
            onCancel={() => {
              // NOTE: clear all state on cancel and then close
              resetForm()
              setIsAddingNewSubContact(false)
              setExistingBusinessContactName(null)
              setExistingPersonContactName(null)
              setSelectedSubContact(null)
              closeModal()
            }}
            onSave={submitForm}
            title={modalTitle}
          >
            {isBusinessContactType ? (
              <BusinessSimpleFields
                existingContactName={existingBusinessContactName}
              />
            ) : (
              <PersonSimpleFields
                existingContactName={existingPersonContactName}
              />
            )}
            {showPersonalDetailsInput && (
              <ContactSimpleFormPersonalDetails
                modalSubHeading={modalSubHeading}
                existingBusinessContactName={existingBusinessContactName}
                existingPersonContactName={existingPersonContactName}
                isAddingNewSubContact={isAddingNewSubContact}
                isBusinessContactType={isBusinessContactType}
                selectedSubContact={selectedSubContact}
                setFieldValue={setFieldValue}
                setIsAddingNewSubContact={setIsAddingNewSubContact}
                setSelectedSubContact={setSelectedSubContact}
                values={values}
              />
            )}
          </ModalFormScrollable>
        )
      }}
    </Formik>
  )
}
