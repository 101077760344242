import { gql } from '@apollo/client'

import { CardSingleBreadcrumbs } from '../../../CardSingleBreadcrumbs/CardSingleBreadcrumbs'

export const CardSingleBreadcrumbsQuery = gql`
  query CardSingleBreadcrumbsQuery($entityUuid: ID!, $cardId: ID!) {
    getDebitCardV2(entityUuid: $entityUuid, cardId: $cardId) {
      id
      ...CardSingleBreadcrumbsDebitCardV2Fragment
    }
  }

  ${CardSingleBreadcrumbs.fragments.DebitCardV2}
`
