import { createTranslations } from '@npco/utils-translations'

export const headerCellsTranslations = createTranslations({
  average: 'Average',
  total: 'Total',
  category: 'Category',
  quantity: 'Quantity',
  qty: 'Qty',
  contact: 'Contacts',
})
