import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { DetailsListItem } from 'components/DetailsListItem'

import { transactionDetailsInfoTranslations } from '../../TransactionDetailsInfo.i18n'
import {
  TransferInReversalDetailsDebitCardAccountTransactionFragment,
  TransferInReversalDetailsDebitCardTransactionV2Fragment,
} from './TransferInReversalDetails.generated'

export const TransferInReversalDetails = ({
  payeeDetails,
  description,
}:
  | TransferInReversalDetailsDebitCardTransactionV2Fragment
  | TransferInReversalDetailsDebitCardAccountTransactionFragment) => {
  const t = useTranslations(transactionDetailsInfoTranslations)

  return (
    <Box mt="24px">
      <DetailsListItem
        label={t('account')}
        value={payeeDetails?.accountDetails?.name ?? '-'}
      />
      <DetailsListItem
        label={t('description')}
        value={description ?? '-'}
        isCapitalised={false}
      />
    </Box>
  )
}

TransferInReversalDetails.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransferInReversalDetailsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      description
      payeeDetails {
        accountDetails {
          name
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment TransferInReversalDetailsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      description
      payeeDetails {
        accountDetails {
          name
        }
      }
    }
  `,
}
