import { useCallback, useEffect, useRef } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import { useFormikContext } from 'formik'

import { useCheckForAbusiveDescriptionResult } from 'pages/Transfer/hooks/useCheckForAbusiveDescriptionResult/useCheckForAbusiveDescriptionResult'
import { externalTransferTranslations } from 'pages/Transfer/Transfer.i18n'
import { TransferExternalFields } from 'pages/Transfer/Transfer.types'

export const useAbusiveContentCheck = () => {
  const flags = useFeatureFlags()
  const isAbusiveContentCheckEnabled = flags.TransferAbusiveContentCheck
  const { checkForAbusiveDescription, isCheckingAbusiveMessage } =
    useCheckForAbusiveDescriptionResult()

  const { setFieldError, errors, setStatus, status } =
    useFormikContext<TransferExternalFields>()

  const t = useTranslations(externalTransferTranslations)

  const abuseErrorRef = useRef<string | undefined>()

  const validateAbusiveContent = useCallback(
    async (value: string) => {
      if (!isAbusiveContentCheckEnabled) {
        return undefined
      }
      if (value) {
        try {
          const isAbusive = await checkForAbusiveDescription({
            description: value,
          })

          if (isAbusive) {
            return t('abusiveDescriptionError')
          }
        } catch (e) {
          return undefined
        }
      }
      return undefined
    },
    [checkForAbusiveDescription, isAbusiveContentCheckEnabled, t]
  )

  const handleBlur = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (errors.recipientReference) {
        return
      }
      const abuseError = await validateAbusiveContent(e.target.value)
      if (abuseError) {
        setFieldError('recipientReference', abuseError)
        abuseErrorRef.current = abuseError
      }
    },
    [errors.recipientReference, setFieldError, validateAbusiveContent]
  )

  const handleChange = () => {
    abuseErrorRef.current = undefined
  }

  useEffect(() => {
    setStatus({ ...status, isCheckingAbusiveMessage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingAbusiveMessage])

  return {
    handleBlur,
    handleChange,
    abuseErrorRef,
  }
}
