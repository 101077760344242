import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetBpayRestrictedBillersQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetBpayRestrictedBillersQueryResponse = {
  __typename?: 'Query'
  getBpayRestrictedBillers: Array<{
    __typename?: 'BpayBiller'
    billerCode: string
    billerName: string
  }>
}

export const GetBpayRestrictedBillers = gql`
  query GetBpayRestrictedBillers {
    getBpayRestrictedBillers {
      billerCode
      billerName
    }
  }
` as unknown as TypedDocumentNode<
  GetBpayRestrictedBillersQueryResponse,
  GetBpayRestrictedBillersQueryVariables
>

/**
 * __useGetBpayRestrictedBillersQuery__
 *
 * To run a query within a React component, call `useGetBpayRestrictedBillersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBpayRestrictedBillersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBpayRestrictedBillersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBpayRestrictedBillersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBpayRestrictedBillersQueryResponse,
    GetBpayRestrictedBillersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBpayRestrictedBillersQueryResponse,
    GetBpayRestrictedBillersQueryVariables
  >(GetBpayRestrictedBillers, options)
}
export function useGetBpayRestrictedBillersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBpayRestrictedBillersQueryResponse,
    GetBpayRestrictedBillersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBpayRestrictedBillersQueryResponse,
    GetBpayRestrictedBillersQueryVariables
  >(GetBpayRestrictedBillers, options)
}
export type GetBpayRestrictedBillersQueryHookResult = ReturnType<
  typeof useGetBpayRestrictedBillersQuery
>
export type GetBpayRestrictedBillersLazyQueryHookResult = ReturnType<
  typeof useGetBpayRestrictedBillersLazyQuery
>
export type GetBpayRestrictedBillersQueryResult = Apollo.QueryResult<
  GetBpayRestrictedBillersQueryResponse,
  GetBpayRestrictedBillersQueryVariables
>
