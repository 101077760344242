import type { FeatureFlagsSource } from '@npco/utils-feature-flags'

type Options = { storageKey?: string }

export type { Options as LocalStorageSourceOptions }

export type LocalStorageHelpers = {
  readValues: () => unknown
  writeValues: (value: unknown) => void
  clearValues: () => void
}

export const LocalStorageSource = ({
  storageKey = 'feature-flags',
}: Options = {}): FeatureFlagsSource & LocalStorageHelpers => {
  const readValues: LocalStorageHelpers['readValues'] = () =>
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    parseOrUndefined(localStorage.getItem(storageKey) ?? '')
  const writeValues: LocalStorageHelpers['writeValues'] = (values) =>
    localStorage.setItem(storageKey, JSON.stringify(values))
  const clearValues: LocalStorageHelpers['clearValues'] = () =>
    localStorage.removeItem(storageKey)

  const source: FeatureFlagsSource = {
    key: 'LocalStorage',
    init: ({ onValues }) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const values = parseOrUndefined(localStorage.getItem(storageKey) ?? '')
      onValues(values)
    },
  }

  return Object.assign(source, { readValues, writeValues, clearValues })
}

function parseOrUndefined(value: string): unknown {
  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}
