import { gql } from '@apollo/client'

import {
  GetAbnContactFragment,
  GetAbnMerchantDetailsFragment,
  GetEmailContactFragment,
  GetEmailMerchantDetailsFragment,
  GetWebsiteContactFragment,
  GetWebsiteMerchantDetailsFragment,
} from './MerchantSecondaryDetailsItems.utils.generated'

export const getEmail = (
  merchant: GetEmailMerchantDetailsFragment | null | undefined,
  contact: GetEmailContactFragment | null
) => merchant?.email || contact?.email?.email

getEmail.fragments = {
  MerchantDetails: gql`
    fragment GetEmailMerchantDetailsFragment on MerchantDetails {
      email
    }
  `,
  Contact: gql`
    fragment GetEmailContactFragment on Contact {
      email {
        email
      }
    }
  `,
}

export const getWebsite = (
  merchant: GetWebsiteMerchantDetailsFragment | null | undefined,
  contact: GetWebsiteContactFragment | null
) => merchant?.url || contact?.website

getWebsite.fragments = {
  MerchantDetails: gql`
    fragment GetWebsiteMerchantDetailsFragment on MerchantDetails {
      url
    }
  `,
  Contact: gql`
    fragment GetWebsiteContactFragment on Contact {
      website
    }
  `,
}

export const getAbn = (
  merchant: GetAbnMerchantDetailsFragment | null | undefined,
  contact: GetAbnContactFragment | null
) => merchant?.abn || contact?.abn

getAbn.fragments = {
  MerchantDetails: gql`
    fragment GetAbnMerchantDetailsFragment on MerchantDetails {
      abn
    }
  `,
  Contact: gql`
    fragment GetAbnContactFragment on Contact {
      abn
    }
  `,
}
