import { useCallback } from 'react'
import { SelectStyle } from '@npco/zeller-design-system'
import {
  MultiSelectBasic,
  MultiSelectBasicProps,
  MultiSelectItemBasic,
  MultiSelectTriggerRenderProps,
} from 'design-system/Components/MultiSelect'
import { MultiSelectItem } from 'design-system/Components/MultiSelect/MultiSelectItem/MultiSelectItem'
import { SelectSize } from 'design-system/Components/Select'

import { FilterSelectTrigger } from '../FilterSelectTrigger/FilterSelectTrigger'

interface Props<ItemType extends MultiSelectItemBasic, ValueType> {
  listItems: ItemType[]
  selectedItems: ItemType[]
  values: ValueType[]
  onChange: (newSelectedItems: ItemType[]) => void
  onClose?: () => void
  onReset: () => void
  filterName: string
  maxHeight: string
  icon?: React.ReactNode
  areAllItemsSelected: boolean
  shouldShowSelectedCountOnAllSelected?: boolean
  renderTopSection?: MultiSelectBasicProps<ItemType>['renderTopSection']
  renderNoItemsPlaceholder?: MultiSelectBasicProps<ItemType>['renderNoItemsPlaceholder']
  popperWidth?: MultiSelectBasicProps<ItemType>['popperWidth']
  shouldAutoFocusControl?: boolean
}

export const MultiSelectFilter = <
  ItemType extends MultiSelectItemBasic,
  ValueType extends string
>({
  listItems,
  selectedItems,
  values,
  onChange,
  onClose,
  onReset,
  filterName,
  maxHeight,
  icon,
  areAllItemsSelected,
  shouldShowSelectedCountOnAllSelected,
  popperWidth = '18rem',
  shouldAutoFocusControl,
  ...rest
}: Props<ItemType, ValueType>) => {
  const renderTrigger = useCallback(
    ({ ref, ...renderTriggerProps }: MultiSelectTriggerRenderProps) => (
      <FilterSelectTrigger
        selectedItems={selectedItems}
        values={values}
        onReset={onReset}
        filterName={filterName}
        icon={icon}
        areAllItemsSelected={areAllItemsSelected}
        innerRef={ref}
        shouldShowSelectedCountOnAllSelected={
          shouldShowSelectedCountOnAllSelected
        }
        {...renderTriggerProps}
      />
    ),
    [
      areAllItemsSelected,
      filterName,
      icon,
      onReset,
      selectedItems,
      shouldShowSelectedCountOnAllSelected,
      values,
    ]
  )

  return (
    <MultiSelectBasic<ItemType>
      popperWidth={popperWidth}
      maxHeight={maxHeight}
      items={listItems}
      selectedItems={selectedItems}
      onClose={onClose}
      onChange={onChange}
      selectSize={SelectSize.Small}
      selectStyle={SelectStyle.Standard}
      renderTrigger={renderTrigger}
      renderItem={(renderProps) => <MultiSelectItem {...renderProps} />}
      shouldAutoFocusControl={shouldAutoFocusControl}
      {...rest}
    />
  )
}
