import { ContactType } from '@npco/mp-gql-types'
import {
  Box,
  FormExtensionRenderContentProps,
} from '@npco/zeller-design-system'

import { page } from 'translations'

import { AdditionalEmailInputField } from '../components/AdditionalEmailInputField/AdditionalEmailInputField'
import { AdditionalPhoneInputField } from '../components/AdditionalPhoneInputField/AdditionalPhoneInputField'
import { AddressInputFields } from '../components/AddressInputFields'
import { EmailInputField } from '../components/EmailInputField'
import { PhoneInputField } from '../components/PhoneInputField/PhoneInputField'
import { ContactFormData } from '../Contacts.types'
import { FieldProps } from '../People/PersonShared.fields'

export const FIELD_EMAIL_BUSINESS: FieldProps = {
  itemId: 'email',
  itemName: page.contacts.fields.email,
  renderContent: () => {
    return (
      <Box mb="1.5rem" key="email">
        <EmailInputField contactType={ContactType.BUSINESS} />
      </Box>
    )
  },
  shouldDisplayContentOnMount: () => true,
}

export const FIELD_ADDITIONAL_EMAIL_BUSINESS: FieldProps = {
  canBeAddedMultipleTimes: true,
  dependsOn: FIELD_EMAIL_BUSINESS.itemId,
  initialDisplayedElementCount: (values: ContactFormData) =>
    values.business.additionalEmails.length,
  itemId: 'additional-email',
  itemName: page.contacts.fields.email,
  renderContent: ({
    contentIndex,
    ...props
  }: FormExtensionRenderContentProps) => {
    return (
      <Box mb="24px" {...props}>
        <AdditionalEmailInputField
          contactType={ContactType.BUSINESS}
          fieldIndex={contentIndex}
        />
      </Box>
    )
  },
  shouldDisplayContentOnMount: (values: ContactFormData) =>
    values.business.additionalEmails.length > 0,
}

export const FIELD_PHONE_BUSINESS: FieldProps = {
  itemId: 'phone',
  itemName: page.contacts.fields.phone,
  renderContent: () => {
    return (
      <Box mb="1.5rem" key="phone">
        <PhoneInputField
          contactType={ContactType.BUSINESS}
          placeholder={page.contacts.fields.phone}
        />
      </Box>
    )
  },
  shouldDisplayContentOnMount: () => true,
}

export const FIELD_ADDITIONAL_PHONE_BUSINESS: FieldProps = {
  canBeAddedMultipleTimes: true,
  dependsOn: FIELD_PHONE_BUSINESS.itemId,
  initialDisplayedElementCount: (values: ContactFormData) => {
    return values.business.additionalPhones.length
  },
  itemId: 'additional-phone',
  itemName: page.contacts.fields.phone,
  renderContent: ({
    contentIndex,
    ...props
  }: FormExtensionRenderContentProps) => {
    return (
      <Box mb="1.5rem" {...props}>
        <AdditionalPhoneInputField
          contactType={ContactType.BUSINESS}
          fieldIndex={contentIndex}
          placeholder={page.contacts.fields.phone}
        />
      </Box>
    )
  },
  shouldDisplayContentOnMount: (values: ContactFormData) => {
    return values.business.additionalPhones.length > 0
  },
}

export const FIELD_STREET_BUSINESS: FieldProps = {
  itemId: 'address',
  itemName: page.contacts.fields.street,
  renderContent: ({ key }: FormExtensionRenderContentProps) => (
    <AddressInputFields key={key} contactType={ContactType.BUSINESS} />
  ),
  shouldDisplayContentOnMount: (values: ContactFormData) =>
    Boolean(values.business.country) ||
    Boolean(values.business.postcode) ||
    Boolean(values.business.state) ||
    Boolean(values.business.street) ||
    Boolean(values.business.suburb),
}
