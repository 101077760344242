import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { IconAvatarIconFragmentDoc } from '@npco/mp-ui-icon-avatar'

export type AccountRowDebitCardAccountConnectionV2Fragment = {
  __typename?: 'DebitCardAccountConnectionV2'
  accounts: Array<{
    __typename?: 'DebitCardAccountV2'
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      animation: string | null
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null>
}

export const AccountRowDebitCardAccountConnectionV2FragmentDoc = gql`
  fragment AccountRowDebitCardAccountConnectionV2Fragment on DebitCardAccountConnectionV2 {
    accounts {
      icon {
        ...IconAvatarIconFragment
      }
    }
  }
  ${IconAvatarIconFragmentDoc}
` as unknown as TypedDocumentNode<
  AccountRowDebitCardAccountConnectionV2Fragment,
  undefined
>
