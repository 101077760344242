import { type ReactNode } from 'react'

import * as styled from '../../Sidebar.styled'
import { ItemLabel } from './ItemLabel'

export interface ItemExternalProps {
  children: ReactNode
  isFolded: boolean
  href: string
  icon: ReactNode
  title?: string
  badge?: ReactNode
}

export const SidebarItemExternal = ({
  badge,
  children,
  icon,
  isFolded,
  href,
  title,
}: ItemExternalProps) => (
  <styled.ItemLink
    data-testid="item-link-external"
    as="a"
    href={href}
    title={title}
    target="_blank"
    $isFolded={isFolded}
  >
    <ItemLabel icon={icon} badge={badge} isFolded={isFolded}>
      {children}
    </ItemLabel>
  </styled.ItemLink>
)
