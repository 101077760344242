import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledSignUpDesktopWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: block;
  }
`

export const StyledSignUpMobileWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: none;
  }
`
