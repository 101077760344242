import {
  Box,
  INPUT_SIZE,
  StyledActiveFloatingInputLabel,
  StyledFloatingInputLabel,
  StyledGhostButtonIconOnly,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledEditTagsModalWrapper = styled(Box)<{ $size?: INPUT_SIZE }>`
  [aria-expanded='true'] {
    ${StyledFloatingInputLabel} {
      ${StyledActiveFloatingInputLabel}
    }
  }
`

export const StyledInputErrorIcon = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 1rem;
`

export const StyledIconWrapper = styled.div`
  display: none;
`

export const StyledItemContainer = styled.div`
&:hover {
  ${StyledIconWrapper} {
    display: block;
    position: absolute;
    right: 0.75rem;
    top: 0.25rem;
    ${StyledGhostButtonIconOnly} {
      background: transparent;
    }
  }
`
