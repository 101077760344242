import { LocalStateVirtualTerminal } from './VirtualTerminal.types'

export const CNP_MINIMUM = '100'
export const CNP_MAXIMUM = '5000000'
export const MOTO_MINIMUM = '100'
export const MOTO_MAXIMUM = '2500000'
export const LIST_ITEM_HEIGHT = 56
export const SITES_QUERY_LIMIT = 50
export const VTRECORDS_QUERY_LIMIT = 50

// This is the container width at which the layout shifts from vertical to horizontal
// This caters for the sidebar being hidden on mobile and smaller screens
export const CONTAINER_BREAKPOINT = 768

export const VirtualTerminalDefaultLocalState: LocalStateVirtualTerminal = {
  lastSelectedSite: null,
}
