// This was adopted from https://github.com/zendesk/laika -> createLazyLoadableLaikaLink.ts

import { createLazyLoadableLink } from './createLazyLoadableLink'

export const createLazyLoadableTestingLink = (
  testingUtilPropertyName?: string
) =>
  createLazyLoadableLink(
    import('./createGlobalTestingLink').then(
      ({ createGlobalTestingUtilsLink }) =>
        createGlobalTestingUtilsLink(testingUtilPropertyName)
    )
  )
