import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  posSettingsTitle: 'POS Details',
  terminalDisplayTitle: 'Terminal Display',
  terminalDisplayDescription:
    'Screensavers are applied to your Zeller Terminal at a Site level. To get started, go to {link} in your Zeller Dashboard, select a Site, then select “Appearance”.',
  posSoftwareName: 'POS System Name',
  connectionMethod: 'POS Connection Type',
  posRegisterName: 'POS Register Name',
  lastPosTransaction: 'Last POS Transaction',
  LAN: 'On Premise',
  CLOUD: 'Cloud',
  CLOUD_ASYNC: 'Cloud Async',
  POS_CONNECTOR: 'POS Connector',
  sites: 'Sites',
})
