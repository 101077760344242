import { DebitCardAccountType } from '@npco/mp-gql-types'
import { z } from 'zod'

export const EditAccountStageSchema = z.enum(['EditAccount'])
export const EditAccountStateSchema = z.object({
  stage: EditAccountStageSchema,
  accountId: z.string(),
  name: z.string(),
  accountColour: z.string(),
  accountType: z.union([
    z.literal(DebitCardAccountType.ZLR_DEBIT),
    z.literal(DebitCardAccountType.SAVINGS),
  ]),
})

export const EditAccountLocationStateSchema = z.object({
  EditAccountModal: EditAccountStateSchema,
})

export const EditAccountStage = EditAccountStageSchema.Values
export type EditAccountState = z.TypeOf<typeof EditAccountStateSchema>
