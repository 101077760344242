import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateUtils, DayModifiers } from 'react-day-picker'
import { RangeModifierExt, showErrorToast } from '@npco/zeller-design-system'

import { KEY_CODE } from 'const/keyboard'
import dayjs from 'utils/dayjs'
import { DateRange } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { component } from 'translations'

const initialState = { from: undefined, to: undefined }

type LocalDateRange = Partial<DateRange>

interface Arguments {
  focusTrigger?: () => void
  isOpen: boolean
  date: DateRange | null
  setDate: (newDate: DateRange) => void
  resetDate: () => void
}

export const isSelectingFirstDay = (
  fromDate: Date | null | undefined,
  toDate: Date | null | undefined,
  selectedDay: Date
) => {
  const isBeforeFirstDay =
    fromDate && DateUtils.isDayBefore(selectedDay, fromDate)
  const isRangeSelected = fromDate && toDate

  return !fromDate || isBeforeFirstDay || isRangeSelected
}

export const useDateFilterDatepicker = ({
  date,
  setDate,
  resetDate,
  focusTrigger,
  isOpen,
}: Arguments) => {
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [enteredTo, setEnteredTo] = useState<Date>()
  const [localDate, setLocalDate] = useState<LocalDateRange>(
    date ?? initialState
  )

  const { from, to } = localDate

  const disabledDays = useMemo(() => {
    if (from && !to && dayjs(from).isBefore(dayjs().subtract(365, 'days'))) {
      return { after: dayjs(from).add(365, 'days').subtract(1, 'day').toDate() }
    }

    return undefined
  }, [from, to])

  useEffect(() => {
    if (isOpen) {
      setLocalDate(date ?? initialState)
      setEnteredTo(date?.to ?? initialState.to)
      setSelectedPeriod('')
      return
    }

    const isSelectingInterrupted = localDate.from && !localDate.to

    if (!isSelectingInterrupted) {
      return
    }

    setLocalDate(date ?? initialState)
    showErrorToast(component.datePicker.lackOfEndDate)

    // We want to trigger this only when the popper opens/closes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    setLocalDate(date ?? initialState)
    setEnteredTo(date?.to ?? initialState.to)
  }, [date])

  const handleResetClick = useCallback(() => {
    resetDate()
    setEnteredTo(undefined)
    setSelectedPeriod('')
  }, [resetDate])

  const handleDayClick = (day: Date) => {
    const today = new Date()
    today.setHours(12, 0, 0, 0)

    if (day > today) {
      return
    }

    if (from && to && day >= from && day <= to) {
      handleResetClick()
    }

    if (
      (isSelectingFirstDay(from, to, day) && !(from && to && day >= from)) ||
      (from && to && (day > to || day < from))
    ) {
      setLocalDate({
        from: dayjs(day).toDate(),
        to: undefined,
      })
      setEnteredTo(undefined)
      return
    }

    const rangeObject = {
      from: from as Date,
      to:
        disabledDays && dayjs(day).isAfter(disabledDays.after)
          ? dayjs(disabledDays.after).toDate()
          : dayjs(day).toDate(),
    }

    setLocalDate(rangeObject)
    setDate(rangeObject)
    setEnteredTo(day)
  }

  const handleDayMouseEnter = (day: Date, modifiers?: DayModifiers) => {
    if (!isSelectingFirstDay(from, to, day) && !modifiers?.disabled) {
      setEnteredTo(day)
    }
  }

  const handleDayMouseLeave = () => {
    if (!to) {
      setEnteredTo(undefined)
    }
  }

  const onPeriodChange = (periodData: RangeModifierExt, periodName: string) => {
    if (periodData.from && periodData.to) {
      setDate({ from: periodData.from, to: periodData.to })
    }

    setSelectedPeriod(periodName)
  }

  const handleClearKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KEY_CODE.TAB) {
      e.preventDefault()
      focusTrigger?.()
    }
  }

  const data: RangeModifierExt = {
    from: localDate.from,
    to: localDate.to,
    enteredTo,
  }

  return {
    data,
    enteredTo,
    selectedPeriod,
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleClearKeyDown,
    onPeriodChange,
    disabledDays,
  }
}
