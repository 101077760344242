import { useMemo, useState } from 'react'
import debounce from 'debounce'

export const useIsScrollActive = () => {
  const [isScrollActive, setScrollActive] = useState(false)

  const handleOnScroll = useMemo(
    () =>
      debounce((event: React.UIEvent<HTMLElement>) => {
        const target = event.target as HTMLElement
        setScrollActive(target.scrollTop > 0)
      }, 10),
    []
  )
  return {
    isScrollActive,
    handleOnScroll,
  }
}
