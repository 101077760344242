import { ErrorLogger } from '@npco/utils-error-logger'
import { isEmpty } from 'lodash-es'
import { GeoArgs, getGeocode, getLatLng } from 'use-places-autocomplete'

import {
  GOOGLE_PLACES_DETAILS_ITEM_TYPE,
  GoogleParsedAddressData,
  GooglePlacesDetailsDataItem,
} from 'types/google'

import { toAlpha3CodeCountry } from './countries'

// NOTE: Mapping is based on https://npco-dev.atlassian.net/browse/ZD-7280
export type GoogleLocationTypes =
  | 'ROOFTOP'
  | 'RANGE_INTERPOLATED'
  | 'GEOMETRIC_CENTER'
  | 'APPROXIMATE'

export const locationTypeInMetersMap: Record<GoogleLocationTypes, number> = {
  ROOFTOP: 10,
  RANGE_INTERPOLATED: 100,
  GEOMETRIC_CENTER: 10000,
  APPROXIMATE: 100000,
}

export const parseGoogleAddressData = (
  data: GooglePlacesDetailsDataItem
): GoogleParsedAddressData => {
  if (isEmpty(data) || isEmpty(data.address_components)) {
    return {
      street: '',
      streetNumber: '',
      state: '',
      postcode: '',
      suburb: '',
      country: '',
    }
  }
  const { address_components: addressComponents } = data

  const findItem = (type: GOOGLE_PLACES_DETAILS_ITEM_TYPE) =>
    addressComponents.find((item) => item.types.includes(type))

  return {
    unit: findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.UNIT)?.long_name,
    street: findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.ROUTE)?.long_name || '',
    streetNumber:
      findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.STREET_NUMBER)?.long_name || '',
    state: findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.STATE)?.short_name || '',
    postcode:
      findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.POSTCODE)?.long_name || '',
    suburb: findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.LOCALITY)?.long_name || '',
    country:
      toAlpha3CodeCountry(
        findItem(GOOGLE_PLACES_DETAILS_ITEM_TYPE.COUNTRY)?.short_name
      ) || '',
  }
}

type GetLocationDataFromGoogleResponse = {
  locationString: string
  locationType: GoogleLocationTypes
  locationTypeInMeters: number
} | null

export const getLocationDataFromGoogle = async (
  data: GeoArgs | null
): Promise<GetLocationDataFromGoogleResponse> => {
  try {
    if (!data) {
      return null
    }

    const geoCode = await getGeocode(data)

    if (geoCode?.[0]) {
      const latLng = await getLatLng(geoCode[0])

      const { lat, lng } = latLng

      return {
        locationString: `${lat},${lng}`,
        locationType: geoCode[0].geometry.location_type,
        locationTypeInMeters:
          locationTypeInMetersMap[geoCode[0].geometry.location_type],
      }
    }
    return null
  } catch (error) {
    ErrorLogger.report('[Core] get geocode', {
      ...(error as Error),
      ...(data as GeoArgs),
    })
    return null
  }
}
