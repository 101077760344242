import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

export const RadioCardSkeleton = () => {
  const isMobile = useIsMobileResolution()

  return (
    <Flex alignItems="center" p="28px 24px" data-testid="radio-card-skeleton">
      <Box>
        <SkeletonBasic width="40px" height="40px" borderRadius="4px" />
      </Box>
      <Box pl="16px">
        <Box pb="8px">
          <SkeletonBasic
            width={isMobile ? '100px' : '153px'}
            height="12px"
            borderRadius="1000px"
          />
        </Box>
        <Box>
          <SkeletonBasic
            width={isMobile ? '230px' : '345px'}
            height="8px"
            borderRadius="1000px"
          />
        </Box>
      </Box>
    </Flex>
  )
}
