import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { TransferBpayFields } from '../../../Transfer.types'
import { CrnField } from '../CRN'
import { FieldCondition } from '../FieldCondition'
import { BillerCodeFields } from './BillerCodeFields/BillerCodeFields'
import { BpayAmountField } from './BpayAmountField/BpayAmountField'

type BpayTransferFieldsProps = {
  contactValidationError: string | undefined
  setContactValidationError: (newError: string | undefined) => void
}

export const BpayTransferFields = ({
  contactValidationError,
  setContactValidationError,
}: BpayTransferFieldsProps) => {
  const { values } = useFormikContext<TransferBpayFields>()

  const isBillerCode = values.to?.type === 'Biller'
  const isContact = values.to?.type === 'Contact'
  const isDynamicCrn = values.paymentInstrumentUuid?.type === 'dynamic'

  const selectedPaymentInstrument = values.to?.billerContacts?.find(
    (biller) => biller.id === values.paymentInstrumentUuid?.id
  )
  return (
    <Box mt="24px">
      <FieldCondition when={isBillerCode}>
        <BillerCodeFields
          contactValidationError={contactValidationError}
          setContactValidationError={setContactValidationError}
        />
      </FieldCondition>
      <FieldCondition
        when={
          isContact &&
          (isDynamicCrn || selectedPaymentInstrument?.id === 'NEW_BILLER')
        }
      >
        <CrnField />
      </FieldCondition>
      <FieldCondition when={isContact && !!selectedPaymentInstrument}>
        <BpayAmountField />
      </FieldCondition>
    </Box>
  )
}
