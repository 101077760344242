import { useCallback } from 'react'
import {
  InitSecureSessionRequest,
  TfnEncryptor,
} from '@npco/end-to-end-encryption'
import { mockedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'

import { UNEXPECTED_ERROR } from '../../../../../const/errors'
import { useInitiateSavingsAccountSecureSessionMutation } from './graphql/InitiateSavingsAccountSecureSession.generated'

type EncryptTfnArgs = { tfn: string }

export const useEncryptTfn = () => {
  const [initiateSavingsAccountSecureSession, { loading }] =
    useInitiateSavingsAccountSecureSessionMutation()

  const initiateSession = useCallback(
    async (initSecureSessionRequest: InitSecureSessionRequest) => {
      const result = await initiateSavingsAccountSecureSession({
        variables: {
          input: initSecureSessionRequest,
          entityUuid: mockedEntityUuid,
        },
      })

      if (
        !result.data?.initiateSavingsAccountSecureSession.secureSessionId ||
        !result.data?.initiateSavingsAccountSecureSession.serverPublicKey
      ) {
        ErrorLogger.reportError('Failed to initiate secure session', result, {
          request: initSecureSessionRequest,
          result,
        })
        throw new Error()
      }

      return result.data.initiateSavingsAccountSecureSession
    },
    [initiateSavingsAccountSecureSession]
  )

  const encryptTfn = useCallback(
    async ({ tfn }: EncryptTfnArgs) => {
      try {
        const encryptor = new TfnEncryptor(initiateSession)
        return await encryptor.encryptTfn(tfn.replace(/\s/g, ''))
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [initiateSession]
  )

  return { isInitiatingSession: loading, encryptTfn }
}
