import { type ReactNode, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useLocationState } from '@npco/utils-routing'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sites'

import { ROOT } from 'const/routes'
import { SiteNavigation } from 'components/App/components/PortalRoutes/PaymentsRoutes/SitesRoutes/SiteRoutes/SiteNavigation/SiteNavigation'
import { getAreTabsVisible } from 'components/App/components/PortalRoutes/PaymentsRoutes/SitesRoutes/SiteRoutes/SiteNavigation/SiteNavigation.utils'
import { SpinnerWrapped } from 'components/Spinner'
import { page } from 'translations'

import { SitesLocationStateSchema } from '../Sites/Sites.types'
import { updateSiteDetails, useGetSite } from './hooks/useGetSite'

export const SiteDetailsFetcher = ({ children }: { children: ReactNode }) => {
  const currentSiteId = useReactiveVar(rvSelectedSite)
  const siteDetails = useReactiveVar(rvSiteDetails)
  const { pathname } = useLocation()
  const shortEntityId = useSelectedShortEntityUuid()

  // NOTE: temporary solution to get site name while reactive variables are loading
  const { name } =
    useLocationState(SitesLocationStateSchema) ?? siteDetails ?? {}

  useEffect(() => {
    // reset site details when initially hitting this page
    // this prevents Page Title from showing previously loaded site details
    if (
      Boolean(siteDetails?.id) &&
      Boolean(currentSiteId) &&
      currentSiteId !== siteDetails?.id
    ) {
      rvSiteDetails(null)
    }
  }, [currentSiteId, siteDetails?.id])
  const { siteData, isLoading } = useGetSite(currentSiteId)

  useEffect(() => {
    if (siteData) {
      updateSiteDetails(siteData)
    }
  }, [siteData])

  const areTabsVisible = getAreTabsVisible(pathname)

  const content = isLoading ? <SpinnerWrapped variant="centre" /> : children

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            {areTabsVisible ? (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Link
                    to={ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.path}
                  >
                    {page.title.sites}
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>{name}</Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            ) : (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Link
                    to={
                      ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.USERS
                        .path
                    }
                  >
                    {name}
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>
                    {matchPath(
                      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_TYPE
                        .path,
                      pathname
                    )
                      ? page.title.createNewUser
                      : page.title.editUser}
                  </Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={areTabsVisible && <SiteNavigation />}
    >
      {!matchPath(
        ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.APPEARANCE.path,
        pathname
      ) ? (
        <PageTemplate.Section>{content}</PageTemplate.Section>
      ) : (
        content
      )}
    </PageTemplate>
  )
}
