import { ReactNode } from 'react'
import { AccordionSimple } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export interface PendingCardsProps {
  numberOfPendingCards: number
  children: ReactNode
}

export const PendingCards = ({
  numberOfPendingCards,
  children,
}: PendingCardsProps) => {
  if (numberOfPendingCards === 0) {
    return null
  }

  return (
    <AccordionSimple
      title={translate('page.cards.status.pending', {
        count: numberOfPendingCards,
      })}
      removeContentPadding
    >
      {children}
    </AccordionSimple>
  )
}
