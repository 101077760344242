import { ReactNode } from 'react'
import { Box, Flex, StyledErrorBox } from '@npco/zeller-design-system'
import { Field, FieldProps, Formik } from 'formik'

import { StyledFormWrapper } from 'pages/Login/Login.styled'
import { SegmentedInput } from 'components/SegmentedInput'
import { errorMessages } from 'translations'

import { ButtonNext } from '../Buttons/ButtonNext'
import { StyledButtonsGroupRowWrapper } from '../Buttons/ButtonsGroupRowWrapper'
import { CodeProps } from './useValidateCode'
import { StyledCopyWrapper } from './ValidateCode.styled'

interface Props {
  handleSubmit: (values: CodeProps) => void
  hasError: boolean
  initialValues: CodeProps
  backButton?: ReactNode
  description: ReactNode
  codeLabel: string
  subtitle?: ReactNode
  submitButtonText?: string
}

export const ValidateCode = ({
  handleSubmit,
  hasError,
  initialValues,
  backButton,
  submitButtonText,
  subtitle,
  description,
  codeLabel,
}: Props) => {
  return (
    <>
      <StyledCopyWrapper>{description}</StyledCopyWrapper>
      {subtitle}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        component={({ isSubmitting, dirty, isValid }) => (
          <StyledFormWrapper>
            <Box pb="2rem">
              <Flex>
                <Field name="code">
                  {({ field, form }: FieldProps<string | undefined>) => (
                    <SegmentedInput.PIN
                      name="code"
                      value={field.value}
                      onValue={(value) => form.setFieldValue(field.name, value)}
                      invalid={hasError}
                      inputProps={{
                        autoFocus: true,
                        'aria-label': codeLabel,
                      }}
                    />
                  )}
                </Field>
              </Flex>
              {hasError && (
                <StyledErrorBox data-testid="error-field">
                  {errorMessages.invalidVerificationCode}
                </StyledErrorBox>
              )}
            </Box>
            <StyledButtonsGroupRowWrapper>
              <ButtonNext
                disabled={isSubmitting || !dirty || !isValid}
                type="submit"
              >
                {submitButtonText}
              </ButtonNext>
              {backButton}
            </StyledButtonsGroupRowWrapper>
          </StyledFormWrapper>
        )}
      />
    </>
  )
}
