import { useTranslations } from '@npco/utils-translations'
import { useIsMobileResolution } from '@npco/zeller-design-system'
import { HeaderContext } from '@tanstack/react-table'

import { headerCellsTranslations } from './HeaderCells.i18n'
import { HeaderCellText } from './HeaderCellText'

export const QuantityHeaderCell = <T,>({
  header,
  column,
}: HeaderContext<T, string>) => {
  const t = useTranslations(headerCellsTranslations)
  const isMobile = useIsMobileResolution()

  return (
    <HeaderCellText header={header} column={column} justifyContent="center">
      {isMobile ? t('qty') : t('quantity')}
    </HeaderCellText>
  )
}
