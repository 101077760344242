import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import T1ShellBlack from 'assets/images/t1-shell-black.png'
import T1ShellWhite from 'assets/images/t1-shell-white.png'
import T2ShellBlack from 'assets/images/t2-shell-black.png'
import T2ShellGrey from 'assets/images/t2-shell-grey.png'
import T2ShellWhite from 'assets/images/t2-shell-white.png'

import {
  SCREEN_SAVER_FORM_FIELDS,
  SiteAppearanceFormValues,
} from '../SiteAppearanceForm/SiteAppearanceForm.types'
import { TerminalModel } from '../TerminalPreview/TerminalPreview.types'

export const useSelectTerminal = () => {
  const { values, setFieldValue } = useFormikContext<SiteAppearanceFormValues>()

  const handleT2TileClick = useCallback(() => {
    if (values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl] === T1ShellBlack) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T2ShellBlack)
    } else if (
      values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl] === T1ShellWhite
    ) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T2ShellWhite)
    }
    setFieldValue(
      SCREEN_SAVER_FORM_FIELDS.selectedTerminalModel,
      TerminalModel.T2
    )
  }, [setFieldValue, values])

  const handleT1TileClick = useCallback(() => {
    if (values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl] === T2ShellGrey) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T1ShellBlack)
    } else if (
      values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl] === T2ShellBlack
    ) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T1ShellBlack)
    } else if (
      values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl] === T2ShellWhite
    ) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T1ShellWhite)
    }
    setFieldValue(
      SCREEN_SAVER_FORM_FIELDS.selectedTerminalModel,
      TerminalModel.T1
    )
  }, [setFieldValue, values])

  const handleBlackTileClick = useCallback(() => {
    if (
      values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalModel] ===
      TerminalModel.T1
    ) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T1ShellBlack)
    } else {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T2ShellBlack)
    }
  }, [setFieldValue, values])

  const handleWhiteTileClick = useCallback(() => {
    if (
      values[SCREEN_SAVER_FORM_FIELDS.selectedTerminalModel] ===
      TerminalModel.T1
    ) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T1ShellWhite)
    } else {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T2ShellWhite)
    }
  }, [setFieldValue, values])

  const handleGreyTileClick = useCallback(() => {
    setFieldValue(
      SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl,
      TerminalModel.T2
    )
    setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedTerminalUrl, T2ShellGrey)
  }, [setFieldValue])

  return {
    values,
    handleT2TileClick,
    handleT1TileClick,
    handleBlackTileClick,
    handleWhiteTileClick,
    handleGreyTileClick,
  }
}
