import { ModalBasic } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { StyledForm } from 'pages/GlobalModals/GlobalModals.styled'
import { MultiStageModal } from 'components/MultiStageModal'

import { CustomAddressFormValues } from '../DeliveryAddress.types'
import { CustomAddressFields } from './CustomAddressFields/fields/CustomAddressFields'
import { EMPTY_CUSTOM_ADDRESS_VALUES } from './CustomAddressPage.constant'
import { GoToPredefinedAddressPage } from './GoToPredefinedAddressPage/GoToPredefinedAddressPage'

interface CustomAddressPageProps {
  dataTestId?: string
  submitButtonLabel?: string
  toggleDescription?: string
  isSubmitting?: boolean
  initialAddress?: CustomAddressFormValues
  onSubmit: (address: CustomAddressFormValues) => void
  onCancel?: () => void
  goToPredefinedAddressPage: (values: CustomAddressFormValues) => void
}

export const CustomAddressPage = ({
  dataTestId = 'custom-address-page',
  submitButtonLabel,
  toggleDescription,
  isSubmitting,
  initialAddress = EMPTY_CUSTOM_ADDRESS_VALUES,
  onSubmit,
  onCancel,
  goToPredefinedAddressPage,
}: CustomAddressPageProps) => {
  return (
    <Formik<CustomAddressFormValues>
      initialValues={initialAddress}
      onSubmit={onSubmit}
    >
      <>
        <ModalBasic.Body canScroll={false}>
          <GoToPredefinedAddressPage
            toggleDescription={toggleDescription}
            onClick={goToPredefinedAddressPage}
          />
        </ModalBasic.Body>
        <StyledForm data-testid={dataTestId}>
          <ModalBasic.Body>
            <CustomAddressFields />
          </ModalBasic.Body>
          <MultiStageModal.FormButtons
            onCancel={onCancel}
            shouldShowBackButton={Boolean(onCancel)}
            nextButtonLabel={submitButtonLabel}
            isLoading={isSubmitting}
          />
        </StyledForm>
      </>
    </Formik>
  )
}
