const ALLOWED_HOSTNAMES = [
  'dashboard.myzeller.dev',
  'arosha.myzeller.dev',
  'ryan.myzeller.dev',
  'lukas.myzeller.dev',
  'bernard.myzeller.dev',
  'krys.myzeller.dev',
  'james.myzeller.dev',
  'michal.myzeller.dev',
  'bartek.myzeller.dev',
  'jakub.myzeller.dev',
  'kacper.myzeller.dev',
  'localhost',
]

export const isAllowedHostname = (hostname: string) =>
  ALLOWED_HOSTNAMES.includes(hostname)
