import { gql } from '@apollo/client/core'

export const DepositUpdate = gql`
  subscription DepositUpdate($entityUuid: ID!) {
    onDepositUpdate(entityUuid: $entityUuid) {
      id
      siteName
      timestamp
      skippedTimestamp
      status
      reference
      shortId
      processedAmount
      processedFeeAmount
      refundsAmount
      withHeld
      rolledOverProcessedAmount
      rolledOverFeeAmount
      amountSettled
      accountName
      tradingSlot
      siteUuid
    }
  }
`
