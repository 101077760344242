import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferEvent } from 'pages/Transfer/Transfer.stateMachine'

export const useTransferActionEvents = () => {
  const {
    transferState: [, send],
  } = useTransferState()
  const sendBackEvent = () => send(TransferEvent.Back)
  const sendContinueEvent = () => send(TransferEvent.Continue)

  return { sendContinueEvent, sendBackEvent }
}
