import { useEffect } from 'react'
import { gql } from '@apollo/client'
import { Heading } from '@npco/zeller-design-system'

import { SITES } from 'const/common'
import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { Description } from 'layouts'
import { AddEntityModal } from 'components/AddEntityModal'
import { BasicModal } from 'components/BasicModal'
import { Card, CardAdd } from 'components/Cards/Card'
import { page } from 'translations'

import { AssignSitesSiteFragment } from './AssignSites.generated'

const { assignSites, assignedSites, unassign, unassignModal } =
  page.settings.users
const { devices } = page.settings.sites

interface Props {
  isAdmin?: boolean
  entitySites: AssignSitesSiteFragment[]
  userSites: SiteData[]
  addSites: (addedSites: SiteData[]) => void
  removeSite: (siteId: string) => void
  setFieldValue: (
    field: 'sites',
    value: SiteData[] | AssignSitesSiteFragment[],
    shouldValidate?: boolean | undefined
  ) => void
}

export const AssignSites = ({
  isAdmin = false,
  entitySites,
  userSites,
  addSites,
  removeSite,
  setFieldValue,
}: Props) => {
  useEffect(() => {
    if (!isAdmin) {
      setFieldValue('sites', userSites)
    }
  }, [isAdmin, userSites, setFieldValue, entitySites])

  const entities = entitySites.filter(
    (site: AssignSitesSiteFragment) =>
      !userSites.some((userSite) => userSite.id === site.id)
  )

  return (
    <div data-testid="assign-sites">
      <Heading.H3 mb="24px">{assignedSites.title}</Heading.H3>
      <Description>
        {isAdmin ? assignedSites.description : assignSites.description}
      </Description>
      {!isAdmin && (
        <CardAdd
          label={assignSites.title}
          modalComponent={(closeModal) => (
            <AddEntityModal
              closeModal={closeModal}
              entities={entities}
              type={SITES}
              onSaveCallback={addSites}
            />
          )}
        />
      )}
      {userSites?.map((site) => (
        <Card
          hasCloseIcon
          isClickable={!isAdmin}
          key={site.name}
          text={site.name}
          textSmall={`${site?.devices?.length ?? 0} ${devices}`}
          modalComponent={(closeModal) => (
            <BasicModal
              modalDescription={unassignModal.description}
              modalTitle={unassignModal.title}
              primaryButtonLabel={unassign}
              onPrimaryButtonClick={() => removeSite(site.id)}
              onSecondaryButtonClick={closeModal}
            />
          )}
        />
      ))}
    </div>
  )
}

AssignSites.fragments = {
  Site: gql`
    fragment AssignSitesSiteFragment on Site {
      id
      name
      devices {
        id
        name
        model
        serial
      }
    }
  `,
}
