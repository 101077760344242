import { gql } from '@apollo/client'

export const SendInternalTransfer = gql`
  mutation SendInternalTransfer($input: FundsTransferInput!, $entityUuid: ID!) {
    transferFundsDebitCardAccount(input: $input, entityUuid: $entityUuid) {
      id
      status
      transferInUuid
    }
  }
`
