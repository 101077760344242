import { useCallback, useRef } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { InputBasic } from '@npco/zeller-design-system'

import { KEY_CODE } from 'const/keyboard'

import { StyledInputWrapper } from '../../LabelSelectCombobox.styled'
import { LabelSelectComboboxItem } from '../../LabelSelectCombobox.types'

interface LabelSelectComboboxEditInputProps<ItemType> {
  onEditItem: (item: ItemType, onComplete: () => void) => void
  selectedEditItem: ItemType
  setInputValue: (value: string) => void
  setSelectedEditItem: (item: ItemType | null) => void
}

export const LabelSelectComboboxEditInput = <
  ItemType extends LabelSelectComboboxItem
>({
  onEditItem,
  selectedEditItem,
  setInputValue,
  setSelectedEditItem,
}: LabelSelectComboboxEditInputProps<ItemType>) => {
  const editInputRef = useRef<HTMLInputElement>(null)

  useOnclickOutside(
    () => {
      const value = editInputRef?.current?.value

      if (selectedEditItem && value) {
        const trimmedValue = value.trim()

        // NOTE: if value hasn't changed exit edit mode
        if (selectedEditItem.value === trimmedValue) {
          setSelectedEditItem(null)
          return
        }

        const nextItem = {
          ...selectedEditItem,
          label: trimmedValue,
          value: trimmedValue,
        }

        onEditItem(nextItem, () => setSelectedEditItem(null))
      }
    },
    { refs: [editInputRef] }
  )

  const handleKeyDown = useCallback(
    (event) => {
      // NOTE: prevent escape from closing modal if used
      event.stopPropagation()

      const trimmedValue = String(event.currentTarget.value).trim()

      if (event.key === KEY_CODE.ESCAPE) {
        setSelectedEditItem(null)
        return
      }

      if (event.key === KEY_CODE.ENTER) {
        const nextItem = {
          ...selectedEditItem,
          label: trimmedValue,
          value: trimmedValue,
        }

        // NOTE: if value hasn't changed exit edit mode
        if (selectedEditItem.value === trimmedValue) {
          setSelectedEditItem(null)
          return
        }

        onEditItem(nextItem, () => setSelectedEditItem(null))
      }
    },
    [onEditItem, selectedEditItem, setSelectedEditItem]
  )

  return (
    <StyledInputWrapper>
      <InputBasic
        autoFocus
        data-testid="edit-input"
        defaultValue={selectedEditItem.value}
        onKeyDown={handleKeyDown}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(event.currentTarget.value)
        }
        maxLength={20}
        placeholder={selectedEditItem.value}
        ref={editInputRef}
      />
    </StyledInputWrapper>
  )
}
