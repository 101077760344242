import { useCallback, useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Divider, showErrorToast } from '@npco/zeller-design-system'
import { ApiCallType, useMFAState, useRedirectToMFA } from 'features/MFA'
import { useContactTransferMFAState } from 'features/MFA/MFAContext/hooks/useContactTransferMFAState/useContactTransferMFAState'

import { FALLBACK_TO_DE } from 'hooks/banking'
import { AuthScope } from 'types/auth'
import {
  DAILY_TRANSFER_LIMIT_EXCEEDED,
  MFA_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'
import { translations } from 'translations/shared.i18n'

import { TransferModal } from '../../../TransferModal'
import { contactTransferModalTranslations } from '../ContactTransferModal.i18n'
import { TransferContactValues } from '../ContactTransferModal.types'
import { useGoToContactTransferModal } from '../hooks/useGoToContactTransferModal/useGoToContactTransferModal'
import { DetailsSection } from './DetailsSection/DetailsSection'
import { FromSection } from './FromSection/FromSection'
import { useSubmitContactTransfer } from './hooks/useSubmitContactTransfer/useSubmitContactTransfer'
import { ToSection } from './ToSection/ToSection'

type SendContactTransferArgs = {
  values: TransferContactValues
  forceDeOut: boolean
}

type ContactTransferSummaryProps = {
  values: TransferContactValues
}

export const ContactTransferSummary = ({
  values,
}: ContactTransferSummaryProps) => {
  const { closeContactTransferModal, openContactTransferModal } =
    useGoToContactTransferModal()
  const { submitContactTransfer, isSendingContactTransfer } =
    useSubmitContactTransfer()

  const [fallbackToDe, setFallbackToDe] = useState(false)

  const t = useTranslations(contactTransferModalTranslations)
  const tShared = useTranslations(translations)

  const { isRedirecting } = useMFAState()
  const { redirectToMFA } = useRedirectToMFA()
  const { hasRedirectedBackToApp, setHasRedirectedBackToApp, transferState } =
    useContactTransferMFAState()

  const sendContactTransfer = useCallback(
    async ({ values, forceDeOut }: SendContactTransferArgs) => {
      const result = await submitContactTransfer({
        values,
        forceDeOut,
      })

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('transferErrorMessage'))
        return
      }

      if (result === FALLBACK_TO_DE) {
        setFallbackToDe(true)
        return
      }

      if (result === MFA_ERROR) {
        redirectToMFA({
          apiCallType: ApiCallType.ContactTransfer,
          values,
          forceDeOut,
          scope: AuthScope.SENSITIVE,
        })
        return
      }

      if (result === DAILY_TRANSFER_LIMIT_EXCEEDED) {
        showErrorToast(t('maxDailyLimit'))
        return
      }

      openContactTransferModal(
        {
          stage: 'success',
          values,
          timestamp: new Date().toISOString(),
          transactionID: result.id,
        },
        { replace: true }
      )
    },
    [openContactTransferModal, redirectToMFA, submitContactTransfer, t]
  )

  useEffect(() => {
    if (hasRedirectedBackToApp && transferState) {
      setHasRedirectedBackToApp(false)
      sendContactTransfer({
        values: transferState.values,
        forceDeOut: transferState.forceDeOut,
      })
    }
  }, [
    hasRedirectedBackToApp,
    transferState,
    setHasRedirectedBackToApp,
    sendContactTransfer,
  ])

  const submitTransfer = useCallback(async () => {
    sendContactTransfer({ values, forceDeOut: fallbackToDe })
  }, [sendContactTransfer, values, fallbackToDe])

  if (isRedirecting) {
    return null
  }

  return (
    <TransferModal.DecisionModal
      isOpen
      primaryButtonLabel={t('primaryButtonLabel')}
      onClickPrimary={submitTransfer}
      isLoadingPrimary={isSendingContactTransfer}
      secondaryButtonLabel={tShared('edit')}
      onClickSecondary={closeContactTransferModal}
      isSecondaryButtonDisabled={isSendingContactTransfer}
      onCancel={closeContactTransferModal}
      className="animated-content"
      overlayClassName="animated-overlay"
      bodyClassName="transfer-modal-body"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <TransferModal.ModalWrapper>
        <FromSection fromAccount={values.fromAccount} />
        <Divider margin="18px 0" />
        <ToSection
          toContact={values.toContact}
          toPaymentInstrument={values.toPaymentInstrument}
          fallbackToDe={fallbackToDe}
        />
        <Divider margin="18px 0" />
        <DetailsSection
          amount={values.amount}
          recipientReference={values.recipientReference}
          reference={values.reference}
          mb="28px"
        />
      </TransferModal.ModalWrapper>
    </TransferModal.DecisionModal>
  )
}
