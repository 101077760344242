import { Box } from '@npco/zeller-design-system'

import { validateEmail } from 'utils/formValidation'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { page } from 'translations'

interface Props {
  isDisabled?: boolean
}

export const EmailField = ({ isDisabled }: Props) => (
  <Box mb="2.5rem">
    <InputAdaptiveFieldWrapper
      label={page.settings.users.email}
      name="email"
      type="text"
      validate={validateEmail}
      disabled={isDisabled}
      isRequired
    />
  </Box>
)
