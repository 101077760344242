import { RadioGroupBasic, RadioListCard } from '@npco/zeller-design-system'
import { INVOICE_TAX_INCLUSIVE_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceTaxFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

export const translations = {
  taxExcluded: translate('page.invoice.formSections.items.taxExcluded'),
  taxIncluded: translate('page.invoice.formSections.items.taxIncluded'),
}

export const InvoiceTaxOptions = () => {
  const { setFieldValue, values } = useFormikContext<InvoiceTaxFormFields>()

  return (
    <RadioGroupBasic
      gap="8px"
      isVerticalDisplay
      name={INVOICE_TAX_INCLUSIVE_FIELD}
      onChange={(value) =>
        setFieldValue(INVOICE_TAX_INCLUSIVE_FIELD, value === 'true')
      }
      value={values.itemsTaxInclusive.toString()}
    >
      <RadioListCard
        id="gst-included"
        name={INVOICE_TAX_INCLUSIVE_FIELD}
        title={translations.taxIncluded}
        value="true"
      />
      <RadioListCard
        id="gst-excluded"
        name={INVOICE_TAX_INCLUSIVE_FIELD}
        title={translations.taxExcluded}
        value="false"
      />
    </RadioGroupBasic>
  )
}
