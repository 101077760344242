import { useExtendedColumnConfig } from '@npco/ui-table'
import { COLOR, TableHeaderCellBasic } from '@npco/zeller-design-system'
import { Column, Header } from '@tanstack/react-table'

interface HeaderCellTextProps<T> {
  children: React.ReactNode
  header: Header<T, string>
  justifyContent?: string
  column: Column<T>
  overrideStyles?: React.CSSProperties
}

export const HeaderCellText = <T,>({
  children,
  header,
  justifyContent = 'flex-start',
  column,
  overrideStyles,
}: HeaderCellTextProps<T>) => {
  const { cellSize } = useExtendedColumnConfig<T>({ column })
  return (
    <TableHeaderCellBasic
      key={header.id}
      dataTestId={`${header.id}-head-cell`}
      forwardedProps={{
        style: {
          backgroundColor: COLOR.WHITE,
          overflow: 'hidden',
          ...overrideStyles,
        },
      }}
      justifyContent={justifyContent}
      cellSize={cellSize}
      padding="2px 0"
    >
      {children}
    </TableHeaderCellBasic>
  )
}
