/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */

import type { ReactNode } from 'react'
import { Fragment } from 'react'

export type InferVariables<S extends string> =
  S extends `${string}{${infer K}}${infer Z}` ? K | InferVariables<Z> : never

export function interpolate<VALUE extends string>(
  ...args: InferVariables<VALUE> extends never
    ? [value: VALUE]
    : [value: VALUE, variables: Record<InferVariables<VALUE>, string>]
): VALUE extends `${string}\n${string}` ? ReactNode : string
export function interpolate<VALUE extends string>(
  ...args: InferVariables<VALUE> extends never
    ? [value: VALUE]
    : [value: VALUE, variables: Record<InferVariables<VALUE>, ReactNode>]
): ReactNode
export function interpolate(
  value: string,
  variables?: Record<string, string | ReactNode>
): string | ReactNode {
  const context: Context = {
    nodes: [],
    hasReactNodes: false,
    currentToken: '',
    currentTokenIsVariable: false,
  }

  for (const char of value) {
    if (char === '{') {
      handleOpenVariableToken(context)
      continue
    } else if (char === '}') {
      handleCloseVariableToken(context, variables)
      continue
    } else if (char === '\n') {
      handleNewLineToken(context)
      continue
    }

    context.currentToken += char
  }

  if (context.currentToken) {
    context.nodes.push(context.currentToken)
  }

  if (context.hasReactNodes) {
    return context.nodes.map((node, index) => {
      const key = `${index}:${node}`
      return <Fragment key={key}>{node}</Fragment>
    })
  }

  return context.nodes.join('')
}

type Context = {
  nodes: ReactNode[]
  hasReactNodes: boolean
  currentToken: string
  currentTokenIsVariable: boolean
}

function handleOpenVariableToken(context: Context): void {
  if (!context.currentTokenIsVariable) {
    context.currentTokenIsVariable = true

    if (context.currentToken) {
      context.nodes.push(context.currentToken)
      context.currentToken = ''
    }
  }
}

function handleCloseVariableToken(
  context: Context,
  variables: Record<string, ReactNode> | undefined
): void {
  if (context.currentTokenIsVariable) {
    context.currentTokenIsVariable = false

    const key = context.currentToken
    context.currentToken = ''

    const variable = variables?.[key] ?? ''
    if (typeof variable !== 'string' && typeof variable !== 'number') {
      context.hasReactNodes = true
    }

    context.nodes.push(variable)
  }
}

function handleNewLineToken(context: Context): void {
  if (context.currentToken) {
    context.nodes.push(context.currentToken)
    context.currentToken = ''
  }

  context.nodes.push(<br />)
  context.hasReactNodes = true
}
