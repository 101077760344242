import { useCallback, useMemo } from 'react'
import { AccountStatementType } from '@npco/mp-gql-types'

import { useAccountId } from 'hooks/banking'
import { UNEXPECTED_ERROR } from 'types/errors'

import { AccountStatementsViewBankingAccountStatementFragment } from '../../AccountStatementsView/AccountStatementsView.generated'
import { useAccountStatementsQuery } from '../useAccountStatementsQuery'

interface UseAccountStatementsProps {
  statementType: AccountStatementType
}

export const useAccountStatements = ({
  statementType,
}: UseAccountStatementsProps) => {
  const { accountId } = useAccountId()

  const {
    accountStatements: preDefinedAccountStatements,
    isLoading: areStatementsLoading,
    error: statementsError,
    refetch,
  } = useAccountStatementsQuery({
    debitCardAccountUuid: accountId,
    statementType,
  })

  const accountStatements = useMemo(() => {
    return [...(preDefinedAccountStatements || [])].filter(
      (
        statement
      ): statement is AccountStatementsViewBankingAccountStatementFragment =>
        !!statement
    )
  }, [preDefinedAccountStatements])

  const refetchStatementDownloadLink = useCallback(
    async (startDate: any) => {
      try {
        const result = await refetch()

        const newDownloadLink = result.data.getBankingAccountStatements.find(
          (statement) => statement.startDate === startDate
        )?.downloadLink

        if (!newDownloadLink) {
          return UNEXPECTED_ERROR
        }

        return { downloadLink: newDownloadLink }
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [refetch]
  )

  const handleRefetch = useCallback(() => {
    refetch()
  }, [refetch])

  return {
    accountStatements,
    isLoading: areStatementsLoading,
    hasError: !!statementsError,
    refetchStatementDownloadLink,
    accountId,
    refetch: handleRefetch,
  }
}
