import { useMemo, useState } from 'react'
import { COLOR, zIndexMap } from '@npco/zeller-design-system'
import {
  Bar,
  BarChart as RechartsBarChart,
  BarProps,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts'
import { Margin } from 'recharts/types/util/types'

import { conditionalClassName as cn } from 'utils/conditionalClassName'

import { ChartData } from '../Charts.types'
import { useChartBars } from '../hooks/useChartBars'
import { useTooltipOnMouseEvent } from '../hooks/useTooltipOnMouseEvent'
import { CustomYAxisChartTick } from '../Shared/CustomYAxisChartTick/CustomYAxisChartTick'
import { RenderContent, Tooltip } from '../Shared/Tooltip/Tooltip'
import {
  barCellAnimationDelay,
  barCellClassName,
  StyledChartWrapper,
} from './BarChart.styled'
import {
  getBarColor,
  getTransformOriginY,
  onTooltipRef as onTooltipRefUnMemoed,
} from './BarChart.utils'

export type AxisTickFormatter<TValue extends number | string = number> = (
  value: TValue
) => string

export type BarChartProps<TXaxisName extends number | string = number> = {
  data: ChartData<TXaxisName>
  width: number | string
  height: number | string
  TooltipContent: RenderContent
  xAxisProps?: XAxisProps
  yAxisTickFormatter?: AxisTickFormatter
  xAxisTickFormatter?: AxisTickFormatter<TXaxisName>
  yAxisProps?: YAxisProps
  disabled?: boolean
  isLoading?: boolean
  barProps?: Partial<BarProps>
  gridColor?: string
  children?: React.ReactNode
  margin?: Margin
  dataTestId?: string
} & (
  | {
      yAxisTickFormatter: AxisTickFormatter
      xAxisTickFormatter: AxisTickFormatter<TXaxisName>
    }
  | {
      xAxisProps: Required<Pick<XAxisProps, 'tick'>> & XAxisProps
      yAxisProps: Required<Pick<YAxisProps, 'tick'>> & YAxisProps
    }
)

export const BarChart = <T extends number | string = number>({
  data,
  width,
  height,
  margin,
  disabled: isDisabledFromOutside = false,
  xAxisProps,
  yAxisProps,
  xAxisTickFormatter,
  yAxisTickFormatter,
  TooltipContent,
  barProps,
  gridColor = COLOR.GREY_60,
  children,
  isLoading = false,
  dataTestId,
}: BarChartProps<T>) => {
  const [tooltipWidth, setTooltipWidth] = useState(0)
  const { onChartRef, bars } = useChartBars()
  const { onChartMouseEvent, activeItem } = useTooltipOnMouseEvent(bars)

  const tooltipPositionY = activeItem ? activeItem.y + 16 : undefined

  const onTooltipRef = useMemo(
    () => onTooltipRefUnMemoed(tooltipWidth, setTooltipWidth),
    [tooltipWidth]
  )

  const isDisabled = isLoading || isDisabledFromOutside

  return (
    <StyledChartWrapper
      data-testid={dataTestId}
      className={cn({
        isLoading,
      })}
      aria-live="polite"
      aria-busy={isLoading}
    >
      <ResponsiveContainer width={width} height={height}>
        <RechartsBarChart
          data={data}
          onMouseMove={!isDisabled ? onChartMouseEvent : undefined}
          margin={margin}
        >
          <CartesianGrid vertical={false} stroke={gridColor} />
          <XAxis
            xAxisId={0}
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tickFormatter={xAxisTickFormatter}
            tickSize={12}
            tick={{ fill: COLOR.BLACK, fontSize: 14 }}
            {...xAxisProps}
          />
          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
            tickFormatter={yAxisTickFormatter}
            tick={CustomYAxisChartTick}
            tickSize={12}
            domain={[0, 'auto']}
            {...yAxisProps}
          />
          <RechartsTooltip
            cursor={false}
            isAnimationActive={false}
            wrapperStyle={{
              outline: 'none',
              display: isDisabled ? 'none' : 'block',
              zIndex: zIndexMap.tooltip,
            }}
            position={{ y: tooltipPositionY }}
            content={
              <Tooltip
                data={data}
                renderContent={TooltipContent}
                ref={isDisabled ? null : onTooltipRef}
              />
            }
          />
          <Bar
            dataKey="value"
            fill={getBarColor(isLoading || isDisabled)}
            ref={onChartRef as any}
            barSize={32}
            isAnimationActive={!isLoading}
            {...barProps}
          >
            {data.filter(Boolean).map(({ name }, i) => {
              const currentBar = bars[i]
              const transformOriginY = getTransformOriginY(
                currentBar?.y,
                currentBar?.height
              )
              const currentBarDelay = i * barCellAnimationDelay

              return (
                <Cell
                  className={
                    barProps?.isAnimationActive ? barCellClassName : undefined
                  }
                  key={name}
                  style={{
                    transformOrigin: `0 ${transformOriginY}px`,
                    animationDelay: `${currentBarDelay}ms`,
                  }}
                />
              )
            })}
          </Bar>
          {children}
        </RechartsBarChart>
      </ResponsiveContainer>
    </StyledChartWrapper>
  )
}
