import { lazy, Suspense, useMemo } from 'react'

import { isAllowedHostname } from 'utils/devTools'
import { NotFound } from 'pages/NotFound'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'

export const Demos = () => {
  const LazyDemoList = useMemo(
    () =>
      lazy(() =>
        import('./DemoList').then(({ DemoList }) => ({ default: DemoList }))
      ),
    []
  )

  if (!isAllowedHostname(window.location.hostname)) {
    return <NotFound />
  }

  return (
    <ErrorBoundary>
      <Suspense fallback="Loading...">
        <LazyDemoList />
      </Suspense>
    </ErrorBoundary>
  )
}
