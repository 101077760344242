import { COLOR } from '@npco/zeller-design-system'

const COLOR_HEX_STRING_REGEXP = /^#[0-9A-F]{1,2}[0-9A-F]{1,2}[0-9A-F]{1,2}$/
const COLOR_RGB_STRING_REGEXP = /^([\d\s]+),([\d\s]+),([\d\s]+)?$/

export const parseColorHexString = (
  value: string | undefined | null
): string | undefined => {
  if (!value) {
    return undefined
  }
  const color = value.toUpperCase()
  if (color in COLOR) {
    return COLOR[color as keyof typeof COLOR]
  }
  if (COLOR_RGB_STRING_REGEXP.test(color)) {
    return `#${color
      .replace(/\s/g, '')
      .split(',')
      .map((x) => parseInt(x, 10).toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase()}`
  }
  if (COLOR_HEX_STRING_REGEXP.test(color)) {
    return color
  }
  return undefined
}
