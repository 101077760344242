import { getCents, getWholePartFromCents } from '@npco/component-mp-common'
import {
  DATAPOINT_TEXT_SIZE,
  DatapointAlignment,
  DatapointCurrency,
} from '@npco/zeller-design-system'

interface SpentFundsProps {
  spentFundsInCents: number
  align?: DatapointAlignment
  isLoading?: boolean
}

export const SpentFunds = ({
  spentFundsInCents,
  align,
  isLoading = false,
}: SpentFundsProps) => {
  const dollarAmount = getWholePartFromCents(spentFundsInCents).slice(1)
  const centAmount = getCents(spentFundsInCents)

  return (
    <DatapointCurrency
      size={DATAPOINT_TEXT_SIZE.MEDIUM}
      title="Spent"
      integerText={dollarAmount}
      decimalText={centAmount}
      align={align}
      isLoading={isLoading}
      skeletonWidth="78px"
    />
  )
}
