import { FormikHelpers } from 'formik'

import { useGetZellerInvoiceSettings } from '../../hooks/useGetZellerInvoiceSettings'
import { useUpdateZellerInvoiceSettings } from '../../hooks/useUpdateZellerInvoiceSettings'
import { InvoiceSettingsCustomisationFormFields } from '../SettingsCustomisation.types'
import { isValidLogo } from '../SettingsCustomisation.utils'

export const useUpdateSettingsCustomisation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError: () => void
}) => {
  const { updateZellerInvoiceSettings, isUpdatingZellerInvoiceSettings } =
    useUpdateZellerInvoiceSettings()
  const {
    invoiceSettings,
    isLoading: isLoadingSettings,
    updateInvoiceSettingsCache,
    refetch,
  } = useGetZellerInvoiceSettings({ onError })

  const updateSettingsCustomisation = async (
    values: InvoiceSettingsCustomisationFormFields,
    helpers?: Partial<FormikHelpers<InvoiceSettingsCustomisationFormFields>>
  ) => {
    const valuesWithoutDataUrlsLogos: typeof values = {
      ...values,
      logos: values.logos?.filter(isValidLogo),
    }

    try {
      const result = await updateZellerInvoiceSettings({
        invoice: {
          customisation: valuesWithoutDataUrlsLogos,
        },
      })

      if (!result) {
        onError?.()
        return
      }

      // NOTE: invoice settings should always be defined but wrapping as the
      // value can be null
      if (invoiceSettings) {
        updateInvoiceSettingsCache({
          ...invoiceSettings,
          invoice: {
            ...invoiceSettings.invoice,
            customisation: {
              ...valuesWithoutDataUrlsLogos,
            },
          },
        })
      }

      onSuccess?.()
      helpers?.resetForm?.({ values })
    } catch (e) {
      onError()
    }
  }

  return {
    invoiceSettings,
    isLoadingSettings,
    isUpdatingZellerInvoiceSettings,
    refetch,
    updateInvoiceSettingsCache,
    updateSettingsCustomisation,
  }
}
