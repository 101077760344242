import { ReactNode } from 'react'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { SpinnerWrapped } from 'components/Spinner'
import { page } from 'translations'

import { useFilterSimBillingAccount } from '../hooks/useFilterSimBillingAccount'

export const FetchSimBillingAccount = ({
  children,
}: {
  children: ReactNode
}) => {
  const { isLoading } = useFilterSimBillingAccount()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" width="100%">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Link
                  to={ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.path}
                >
                  {page.title.sim}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Breadcrumb.Text>
                  {page.settings.sim.billingAccount.title}
                </Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>
        {isLoading ? <SpinnerWrapped variant="centre" /> : children}
      </PageTemplate.Section>
    </PageTemplate>
  )
}
