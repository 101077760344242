import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  EditAccountLocationStateSchema,
  EditAccountStageSchema,
} from 'hooks/useGoToEditAccountStage/useGoToEditAccountStage.types'

export const useEditAccountState = () => {
  const locationState = useLocationState(EditAccountLocationStateSchema)
  const state = useMemo(() => {
    if (
      !EditAccountStageSchema.safeParse(locationState?.EditAccountModal.stage)
        .success
    ) {
      return null
    }

    return locationState?.EditAccountModal
  }, [locationState?.EditAccountModal])

  return { state }
}
