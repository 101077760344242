import { gql } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'

import { AccountBalanceListSkeleton } from './AccountBalanceListSkeleton'
import {
  AccountBalanceListViewDebitCardAccountV2Fragment,
  AccountBalanceListViewEntityFragment,
} from './AccountBalanceListView.generated'
import { ExpandableAccountBalances } from './ExpandableAccountBalances'
import { NoAccountsCallToAction } from './NoAccountsCallToAction'

interface AccountBalanceListViewProps {
  isLoading: boolean
  accounts: AccountBalanceListViewDebitCardAccountV2Fragment[]
  entity?: AccountBalanceListViewEntityFragment
}

export const AccountBalanceListView = ({
  isLoading,
  accounts,
  entity,
}: AccountBalanceListViewProps) => {
  if (isLoading || accounts.length === 0) {
    return (
      <Flex justifyContent="center" position="relative">
        <AccountBalanceListSkeleton isLoading={isLoading} />
        {!isLoading && accounts.length === 0 && (
          <NoAccountsCallToAction entity={entity} />
        )}
      </Flex>
    )
  }

  return <ExpandableAccountBalances accounts={accounts} />
}

AccountBalanceListView.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountBalanceListViewDebitCardAccountV2Fragment on DebitCardAccountV2 {
      ...ExpandableAccountBalancesDebitCardAccountV2Fragment
    }

    ${ExpandableAccountBalances.fragments.DebitCardAccountV2}
  `,
  Entity: gql`
    fragment AccountBalanceListViewEntityFragment on Entity {
      ...NoAccountsCallToActionEntityFragment
    }

    ${NoAccountsCallToAction.fragments.Entity}
  `,
}
