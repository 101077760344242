import { useTranslations } from '@npco/utils-translations'
import { Box, Flex } from '@npco/zeller-design-system'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'
import { ModalBasic } from 'design-system/Components/Modal'
import { ModalVerificationRequired } from 'features/MFA'

import { useEztaTermsOfService } from 'hooks/useEztaTermsOfService/useEztaTermsOfService'
import { useDepositsSettingsContext } from 'pages/Deposits/DepositsSettingsContext/DepositsSettingsContext'
import {
  StyledAccountSettingDescription,
  StyledAccountSettingPageTitle,
  StyledAccountsTitle,
} from 'components/AccountSetting/AccountSetting.styled'
import { page } from 'translations'

import {
  CreateExternalAccount,
  CreateExternalAccountContextProvider,
} from './CreateExternalAccount'
import { DepositExternalAccountsList } from './DepositExternalAccountsList/DepositExternalAccountsList'
import { translations } from './DepositSettings.i18n'
import { DepositZellerAccountsList } from './DepositZellerAccountsList/DepositZellerAccountsList'

export const DepositSettings = () => {
  const t = useTranslations(translations)
  const { isStepUpAuthRequired, depositsAuth0AppState, resetOnCancel } =
    useDepositsSettingsContext()

  const { hasRejectedTermsOfService } = useEztaTermsOfService()

  const { stepUpAuth } = useZellerAuthenticationContext()

  const onModalClose = () => {
    resetOnCancel()
  }

  const onRedirect = () => {
    stepUpAuth(depositsAuth0AppState)
  }
  if (isStepUpAuthRequired) {
    return (
      <ModalBasic isOpen onCancel={onModalClose}>
        <ModalVerificationRequired
          onCancel={onModalClose}
          onRedirect={onRedirect}
        />
      </ModalBasic>
    )
  }

  return (
    <>
      <Box>
        <StyledAccountSettingPageTitle>
          {page.settings.deposits.title}
        </StyledAccountSettingPageTitle>
        <StyledAccountSettingDescription>
          {!hasRejectedTermsOfService
            ? page.settings.deposits.description
            : t('description3PAOnly')}
        </StyledAccountSettingDescription>
      </Box>
      {!hasRejectedTermsOfService && (
        <>
          <Flex mb="1rem" mt="3rem">
            <StyledAccountsTitle>
              {page.settings.deposits.zellerAccountTitle}
            </StyledAccountsTitle>
          </Flex>
          <DepositZellerAccountsList />
        </>
      )}
      <Flex mt="2.5rem" mb="1rem">
        <StyledAccountsTitle>
          {page.settings.deposits.externalAccountTitle}
        </StyledAccountsTitle>
      </Flex>
      <DepositExternalAccountsList />
      <CreateExternalAccountContextProvider>
        <CreateExternalAccount />
      </CreateExternalAccountContextProvider>
    </>
  )
}
