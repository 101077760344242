import { ReactElement } from 'react'

import { Group } from './Group'

type GetGroupKeyType<T> = (data: T[] | [], index: number) => any
type RenderHeaderType<T> = (data: T[], index: number) => ReactElement | null

interface RenderGroupArgument<T> {
  getGroupKey: GetGroupKeyType<T>
  renderHeader: RenderHeaderType<T>
}

export function renderGroup<T>({
  getGroupKey,
  renderHeader,
}: RenderGroupArgument<T>) {
  return (items: T[], elements: ReactElement[], index: number, data: T[][]) => {
    const key = getGroupKey(items, index)
    const header = renderHeader(items, index)
    const { length: dataLength } = data

    return (
      <Group
        key={key}
        elements={elements}
        header={header}
        isLastGroup={index === dataLength - 1}
      />
    )
  }
}
