import { ONBOARDING_SHOP } from '@npco/mp-feature-onboarding-shop'

import { ROOT } from '../routes'
import { OnboardingFlowConfigItem } from './onboarding-config.types'
import { SHARED_KYC_FLOW_CONFIG_ITEMS } from './onboarding-config-kyc'

const ONBOARDING_INDIVIDUAL_WITH_ABN_CONFIG_COMMON: OnboardingFlowConfigItem[] =
  [
    ...SHARED_KYC_FLOW_CONFIG_ITEMS,
    {
      path: ROOT.ONBOARDING.INDIVIDUAL.ABOUT_BUSINESS.path,
      isCheckpoint: true,
    },
    {
      path: ROOT.ONBOARDING.INDIVIDUAL.BUSINESS_REGULATIONS.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.INDIVIDUAL.UPLOAD_BANK_STATEMENTS.path,
      isCheckpoint: true,
    },
    {
      path: ROOT.ONBOARDING.PRE_ONBOARDED_QUESTIONNAIRE.path,
      isCheckpoint: true,
    },
    {
      path: ROOT.ONBOARDING.COMPLETE.path,
      isCheckpoint: true,
    },
    { path: ONBOARDING_SHOP.DEALS.path, isCheckpoint: false },
    { path: ONBOARDING_SHOP.DEALS.CHECKOUT.path, isCheckpoint: false },
  ]

export const ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_ITEMS: OnboardingFlowConfigItem[] =
  [
    {
      path: ROOT.ONBOARDING.COMPANY_INFORMATION.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.TRY_AGAIN.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.INDIVIDUAL.TRADING_NAME.path,
      isCheckpoint: true,
    },
    {
      path: ROOT.ONBOARDING.INDIVIDUAL.REVENUE.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.INDIVIDUAL.BUSINESS_TYPE.path,
      isCheckpoint: false,
    },
    ...ONBOARDING_INDIVIDUAL_WITH_ABN_CONFIG_COMMON,
  ]

export const ONBOARDING_INDIVIDUAL_WITH_ABN_FLOW_CONFIG_ITEMS_PHASE_2: OnboardingFlowConfigItem[] =
  [
    {
      path: ROOT.ONBOARDING.YOUR_BUSINESS_INFORMATION.path,
      isCheckpoint: false,
    },
    ...ONBOARDING_INDIVIDUAL_WITH_ABN_CONFIG_COMMON,
  ]
