import { useCallback, useRef, useState } from 'react'
import { TransferRemittancesButtons } from '@npco/component-mp-feature-transfer-remittances'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { DecisionModalProps } from '@npco/zeller-design-system'

import { AnimationAnchor } from '../TransferCompleteAnimation/TransferCompleteAnimation.styled'
import {
  SectionWrapper,
  StyledDecisionModal,
} from '../TransferModal/TransferModal.styled'
import { TransferConfirmationHeader } from './TransferConfirmationHeader/TransferConfirmationHeader'

export interface TransferConfirmationModalProps {
  decisionModalProps: DecisionModalProps
  paddingTop: string
  paddingBottom: string
  modalMinHeight: number
  children: React.ReactNode
  showRemittancesButtons?: boolean
  transactionID: string
  contactEmail?: string | null
  contactPhone?: string | null
}

export const TransferConfirmationModal = ({
  decisionModalProps,
  paddingTop,
  paddingBottom,
  modalMinHeight,
  children,
  showRemittancesButtons = true,
  transactionID,
  contactEmail,
  contactPhone,
}: TransferConfirmationModalProps) => {
  const [isShowingDetails, setIsShowingDetails] = useState(false)
  const behindModalRef = useRef<HTMLDivElement>(null)
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)

  const flag = useFeatureFlags()
  const isTransferRemittancesFlagOn = flag.TransferRemittances

  const toggleShowDetails = useCallback(() => {
    setIsShowingDetails((prev) => !prev)
  }, [])

  const userData = rvCurrentUserData()

  return (
    <>
      <StyledDecisionModal
        isOpen={decisionModalProps.isOpen}
        onCancel={decisionModalProps.onCancel}
        className="animated-content"
        overlayClassName="animated-overlay"
        bodyClassName="transfer-modal-body"
        contentStyle={{
          minHeight: modalMinHeight,
        }}
        $overflow={isShowingDetails ? undefined : 'visible'}
      >
        <AnimationAnchor
          $modalMinHeight={modalMinHeight}
          ref={behindModalRef}
        />
      </StyledDecisionModal>
      <StyledDecisionModal
        {...decisionModalProps}
        className="animated-content"
        bodyClassName="transfer-modal-body"
        contentStyle={{
          minHeight: modalMinHeight,
        }}
        $overflow={isShowingDetails ? undefined : 'visible'}
        aria={{
          labelledby: 'transfer-confirmation-heading',
        }}
      >
        <>
          <TransferConfirmationHeader
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            modalMinHeight={modalMinHeight}
            behindModalRef={behindModalRef}
            isShowingDetails={isShowingDetails}
            toggleShowDetails={toggleShowDetails}
            isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
            isAnimationComplete={isAnimationComplete}
            setIsAnimationComplete={setIsAnimationComplete}
          />
          {isShowingDetails && children}
          {isTransferRemittancesFlagOn &&
            isAnimationComplete &&
            transactionID !== '' &&
            showRemittancesButtons && (
              <TransferRemittancesButtons
                currentUserMobileNumber={userData?.phone ?? ''}
                currentUserEmail={userData?.email ?? ''}
                isShowingDetails={isShowingDetails}
                transactionID={transactionID}
                contactEmail={contactEmail ?? ''}
                contactPhone={contactPhone ?? ''}
              />
            )}
        </>
      </StyledDecisionModal>
    </>
  )
}

TransferConfirmationModal.DecisionModal = StyledDecisionModal
TransferConfirmationModal.SectionWrapper = SectionWrapper
