import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  Flex,
  Heading,
  NotesDisplay,
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'

import { InvoiceDrawerNotesFragment } from 'types/gql-types/InvoiceDrawerNotesFragment'
import { EditNotesModal } from 'components/EditNotesModal/EditNotesModal'
import { translationsShared } from 'translations'

import { useUpdateInvoiceNotes } from './hooks/useUpdateInvoiceNotes'
import { translations } from './InvoiceDrawerNotes.i18n'

export const EDIT_BUTTON_TESTID = 'contact-edit-notes'

export const InvoiceDrawerNotes = ({
  notes: initialNote,
  referenceNumber,
}: InvoiceDrawerNotesFragment) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const { isLoading, updateInvoiceNotes } = useUpdateInvoiceNotes()

  const handleSubmit = async (notes: string) => {
    try {
      await updateInvoiceNotes({ referenceNumber, notes })
      showSuccessToast(t('successNotification'))
      closeModal()
    } catch (error) {
      showErrorToast(t('errorNotification'))
    }
    return undefined
  }

  return (
    <Flex flexDirection="column" gridGap="16px">
      <Flex justifyContent="space-between">
        <Heading.H3>{t('heading')}</Heading.H3>
        <ButtonLink dataTestId={EDIT_BUTTON_TESTID} onClick={openModal}>
          {tShared('edit')}
        </ButtonLink>
      </Flex>
      <NotesDisplay note={initialNote} placeholder={t('noData')} />

      <EditNotesModal
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        isOpen={isModalOpen}
        notes={initialNote}
        onCancel={closeModal}
        placeholder={t('placeholder')}
        title={t('heading')}
      />
    </Flex>
  )
}

InvoiceDrawerNotes.fragments = {
  InvoiceDrawerNotes: gql`
    fragment InvoiceDrawerNotesFragment on Invoice {
      notes
      referenceNumber
    }
  `,
}
