import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useMutation, useReactiveVar } from '@apollo/client'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import {
  Flex,
  MagnifyingGlassNoResults,
  Message,
  showApiErrorToast,
} from '@npco/zeller-design-system'
import { AssignDeviceToSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'
import { sortAscendingByName } from 'utils/common'
import { translate } from 'utils/translations'
import {
  AssignDeviceToSite as AssignDeviceToSiteResponse,
  AssignDeviceToSiteVariables,
} from 'types/gql-types/AssignDeviceToSite'
import { GetSite_getSite_devices as Device } from 'types/gql-types/GetSite'
import { SiteCache } from 'types/site'
import { DeviceDrawer } from 'pages/Devices/DeviceDrawer/DeviceDrawer'
import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'
import { DeviceCard } from 'components/DeviceCard/DeviceCard'
import { SpinnerWrapped } from 'components/Spinner'

export const SiteDevices = () => {
  const shortEntityId = useSelectedShortEntityUuid()
  const entityUuid = useSelectedEntityUuid()
  const [selectedDevice, setSelectedDevice] =
    useState<DeviceWithSiteInfo | null>(null)
  const siteDetails = useReactiveVar(rvSiteDetails) as SiteCache
  const [areDetailsOpen, setAreDetailsOpen] = useState(false)

  const devices = useMemo(() => {
    const devicesArray = siteDetails?.devices ?? []
    return sortAscendingByName<Device>(devicesArray)
  }, [siteDetails])

  const [lastReassignedDeviceId, setLastReassignedDeviceId] =
    useState<string>('')

  const [assignDevice, { loading: isLoadingMutation }] = useMutation<
    AssignDeviceToSiteResponse,
    AssignDeviceToSiteVariables
  >(AssignDeviceToSite, {
    onCompleted(data) {
      if (data.assignDeviceToSite) {
        const filteredDevices = devices.filter(
          (device) => device.id !== lastReassignedDeviceId
        )
        rvSiteDetails({
          ...(rvSiteDetails() as SiteCache),
          devices: filteredDevices,
        })
      } else {
        showApiErrorToast()
      }
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })
  const navigate = useNavigate()
  const handleReassign = useCallback(
    ({ siteId, deviceId }: { siteId: string; deviceId: string }) => {
      setLastReassignedDeviceId(deviceId)
      assignDevice({
        variables: {
          entityUuid,
          deviceUuid: deviceId,
          siteUuid: siteId,
        },
      })
    },
    [assignDevice, entityUuid]
  )

  const handleOnDeviceCardClick = useCallback(
    (terminal) => () => {
      setSelectedDevice({
        ...terminal,
        siteId: siteDetails.id,
        siteName: siteDetails.name,
      })
      setAreDetailsOpen(true)
    },
    [siteDetails, setAreDetailsOpen]
  )

  return (
    <Flex flexDirection="column" pt="2px" pb="32px">
      {isLoadingMutation && <SpinnerWrapped variant="centre" />}
      {devices.length === 0 && (
        <Message
          image={<MagnifyingGlassNoResults size="medium" />}
          title={translate('page.settings.sites.site.devices.emptyDevices')}
          description={translate(
            'page.settings.sites.site.devices.emptyDevicesDescription'
          )}
          primaryButton={{
            label: translate('page.settings.sites.site.devices.goToDevices'),
            onClick: () =>
              navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.DEVICES.path),
          }}
        />
      )}
      {!isLoadingMutation &&
        devices &&
        devices.map((device) => (
          <DeviceCard
            key={device.id}
            device={device}
            onClick={handleOnDeviceCardClick(device)}
          />
        ))}

      <DeviceDrawer
        isSiteDevicesPage
        selectedDevice={selectedDevice}
        areDetailsOpen={areDetailsOpen}
        setAreDetailsOpen={setAreDetailsOpen}
        setSelectedDevice={setSelectedDevice}
        handleReassign={handleReassign}
      />
    </Flex>
  )
}
