import { gql, type TypedDocumentNode } from '@apollo/client'

import { ActivateButtonEntityFragmentDoc } from './ActivateButton/ActivateButton.generated'
import { LearnMoreButtonEntityFragmentDoc } from './LearnMoreButton/LearnMoreButton.generated'

export type SavingAccountHeaderEntityFragment = {
  __typename?: 'Entity'
  canCreateAccount: boolean | null
  savingsAccountProduct: {
    __typename?: 'EntitySavingsAccountProduct'
    effectiveInterestRate: string | null
  } | null
}

export const SavingAccountHeaderEntityFragmentDoc = gql`
  fragment SavingAccountHeaderEntityFragment on Entity {
    ...ActivateButtonEntityFragment
    ...LearnMoreButtonEntityFragment
  }
  ${ActivateButtonEntityFragmentDoc}
  ${LearnMoreButtonEntityFragmentDoc}
` as unknown as TypedDocumentNode<SavingAccountHeaderEntityFragment, undefined>
