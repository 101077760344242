import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetZellerInvoiceSettingsUpdateSubscriptionVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  siteUuid: Types.Scalars['ID']['input']
}>

export type GetZellerInvoiceSettingsUpdateSubscriptionResponse = {
  __typename?: 'Subscription'
  onZellerInvoiceSettingsUpdate: {
    __typename?: 'UpdatedZellerInvoiceSettings'
    customisation: {
      __typename?: 'InvoicePdfCustomisation'
      logos: Array<string | null> | null
    } | null
  } | null
}

export const GetZellerInvoiceSettingsUpdateSubscription = gql`
  subscription GetZellerInvoiceSettingsUpdateSubscription(
    $entityUuid: ID!
    $siteUuid: ID!
  ) {
    onZellerInvoiceSettingsUpdate(
      entityUuid: $entityUuid
      siteUuid: $siteUuid
    ) {
      customisation {
        logos
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetZellerInvoiceSettingsUpdateSubscriptionResponse,
  GetZellerInvoiceSettingsUpdateSubscriptionVariables
>

/**
 * __useGetZellerInvoiceSettingsUpdateSubscription__
 *
 * To run a query within a React component, call `useGetZellerInvoiceSettingsUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetZellerInvoiceSettingsUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZellerInvoiceSettingsUpdateSubscription({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      siteUuid: // value for 'siteUuid'
 *   },
 * });
 */
export function useGetZellerInvoiceSettingsUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetZellerInvoiceSettingsUpdateSubscriptionResponse,
    GetZellerInvoiceSettingsUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    GetZellerInvoiceSettingsUpdateSubscriptionResponse,
    GetZellerInvoiceSettingsUpdateSubscriptionVariables
  >(GetZellerInvoiceSettingsUpdateSubscription, options)
}
export type GetZellerInvoiceSettingsUpdateSubscriptionHookResult = ReturnType<
  typeof useGetZellerInvoiceSettingsUpdateSubscription
>
export type GetZellerInvoiceSettingsUpdateSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<GetZellerInvoiceSettingsUpdateSubscriptionResponse>
