import { gql } from '@apollo/client'

import { getContactOrMerchantLocationData } from '../utils/contacts.utils'
import { getDeserializedOpeningHours } from '../utils/openingHours.utils'
import {
  BusinessContactDetailsContactFragment,
  BusinessContactDetailsMerchantDetailsFragment,
} from './BusinessContactDetails.generated'
import { AddressItem } from './Items/AddressItem'
import { MerchantSecondaryDetailsItems } from './Items/MerchantSecondaryDetailsItems/MerchantSecondaryDetailsItems'
import { OpeningHoursItem } from './Items/OpeningHoursItem'
import { PhoneItem } from './Items/PhoneItem'

interface BusinessContactDetailsProps {
  merchant?: BusinessContactDetailsMerchantDetailsFragment | null
  contact: BusinessContactDetailsContactFragment | null
}

export const BusinessContactDetails = ({
  merchant,
  contact,
}: BusinessContactDetailsProps) => {
  const { openingHours, hasError: hasOpeningHoursError } =
    getDeserializedOpeningHours(merchant?.hours)

  const { address } = getContactOrMerchantLocationData({
    contact,
    merchant,
  })

  return (
    <>
      <AddressItem address={address} />
      {merchant && (
        <OpeningHoursItem
          openingHours={openingHours}
          hasError={hasOpeningHoursError}
        />
      )}
      <PhoneItem phone={merchant?.phone || contact?.phoneV2?.phone} />
      <MerchantSecondaryDetailsItems merchant={merchant} contact={contact} />
    </>
  )
}

BusinessContactDetails.fragments = {
  MerchantDetails: gql`
    fragment BusinessContactDetailsMerchantDetailsFragment on MerchantDetails {
      hours
      ...GetContactOrMerchantLocationDataMerchantDetailsFragment
      phone
      ...MerchantSecondaryDetailsItemsMerchantDetailsFragment
    }

    ${getContactOrMerchantLocationData.fragments.MerchantDetails}
    ${MerchantSecondaryDetailsItems.fragments.MerchantDetails}
  `,
  Contact: gql`
    fragment BusinessContactDetailsContactFragment on Contact {
      ...GetContactOrMerchantLocationDataContactFragment
      phone
      phoneV2 {
        phone
      }
      ...MerchantSecondaryDetailsItemsContactFragment
    }

    ${getContactOrMerchantLocationData.fragments.Contact}
    ${MerchantSecondaryDetailsItems.fragments.Contact}
  `,
}
