import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountDetailsHeaderDebitCardAccountV2FragmentDoc } from './AccountDetailsHeader/AccountDetailsHeader.generated'
import { SavingsAccountDetailsDebitCardAccountV2FragmentDoc } from './SavingsAccountDetails/SavingsAccountDetails.generated'
import { TransferInDetailsDebitCardAccountV2FragmentDoc } from './TransferInDetails/TransferInDetails.generated'

export type AccountDetailsViewValueDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  id: string
  name: string
  type: Types.DebitCardAccountType
  status: Types.DebitCardAccountStatus
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    animation: string | null
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    bonusRateEndsAt: any | null
    tfnProvided: boolean | null
    baseInterestRate: string | null
    bonusInterestRate: string | null
  } | null
  savingsAccountProduct: {
    __typename?: 'SavingsAccountProduct'
    id: string | null
    interestThreshold: { __typename?: 'Money'; value: string } | null
  } | null
  accountDetails: {
    __typename?: 'BankAccountDetails'
    account: string | null
    bsb: string | null
    name: string | null
  } | null
}

export const AccountDetailsViewValueDebitCardAccountV2FragmentDoc = gql`
  fragment AccountDetailsViewValueDebitCardAccountV2Fragment on DebitCardAccountV2 {
    id
    name
    type
    status
    ...AccountDetailsHeaderDebitCardAccountV2Fragment
    ...SavingsAccountDetailsDebitCardAccountV2Fragment
    ...TransferInDetailsDebitCardAccountV2Fragment
  }
  ${AccountDetailsHeaderDebitCardAccountV2FragmentDoc}
  ${SavingsAccountDetailsDebitCardAccountV2FragmentDoc}
  ${TransferInDetailsDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  AccountDetailsViewValueDebitCardAccountV2Fragment,
  undefined
>
