import { useCallback, useEffect } from 'react'
import { ConnectionType } from '@npco/mp-gql-types'
import {
  Box,
  ButtonFill,
  Flex,
  Heading,
  SelectStandard,
  Spinner,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'
import { head } from 'ramda'

import { translate } from 'utils/translations'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'

import { ErrorMessage } from '../../components/Form.styled'
import { useXeroOrganisations } from '../../hooks/useXeroOrganisations'
import { Description } from '../XeroPaymentServicesConnection.styled'
import { XeroPaymentServicesConnetionFormValues } from './XeroPaymentServicesConnectionSetup.types'

export const StepOne = () => {
  const { values, errors, setFieldValue, setFieldError } =
    useFormikContext<XeroPaymentServicesConnetionFormValues>()
  const { isLoading, organisations, error, refetch } = useXeroOrganisations(
    ConnectionType.XERO_PAYMENT_SERVICES
  )

  const handleNext = useCallback(() => {
    if (values.organisation === '') {
      setFieldError(
        'organisation',
        translate(
          'page.settings.connections.xeroBankFeeds.errors.noOrganisationSelected'
        )
      )
      return
    }
    setFieldValue('step', 2)
  }, [setFieldValue, setFieldError, values])

  const handleOnChange = useCallback(
    (item) => {
      setFieldValue('organisation', item?.value)
    },
    [setFieldValue]
  )

  useEffect(() => {
    if (organisations.length === 1) {
      const firstOrg = head(organisations)
      setFieldValue('organisation', firstOrg?.value)
    }
    // including setFieldValue in deps causes infinte render loop
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [organisations])

  if (isLoading) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    )
  }

  if (error) {
    return (
      <GenericErrorPage
        retry={refetch}
        data-testid="fetch-organization-error"
      />
    )
  }

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex width="100%" flexDirection="column">
        <Box mb="24px">
          <Heading.H3>
            {translate(
              'page.settings.connections.xeroBankFeeds.organisationSelect.title'
            )}
          </Heading.H3>
        </Box>
        <Box mb="40px">
          <Description>
            {translate(
              'page.settings.connections.xeroBankFeeds.organisationSelect.description'
            )}
          </Description>
        </Box>
        <SelectStandard
          placeholder="Organisation"
          items={organisations}
          selectedItem={organisations.find(
            (o) => o.value === values.organisation
          )}
          onChange={handleOnChange}
        />
        {errors?.organisation && (
          <ErrorMessage>{errors.organisation}</ErrorMessage>
        )}
      </Flex>
      <ButtonFill fullWidth onClick={handleNext}>
        {translate('page.settings.connections.xeroBankFeeds.cta.next')}
      </ButtonFill>
    </Flex>
  )
}
