import {
  DebitCardTransactionsExportFormat,
  DepositExportFormat,
  SimBillingStatementExportFormat,
  TransactionExportFormat,
} from '@npco/mp-gql-types'
import {
  Box,
  InfoBox,
  RadioGroupBasic,
  RadioListCard,
} from '@npco/zeller-design-system'

import { StyledButtonsWrapper } from 'components/ExportFileModalContent/ExportFileModalView/ExportFileModalView.styled'
import {
  CancelButton,
  ConfirmButton,
} from 'components/Modal/ModalElements/ModalElements'
import {
  StyledDescription,
  StyledHeader,
} from 'components/Modal/ModalElements/ModalElements.styled'
import { page, shared } from 'translations'

interface Props<
  T extends
    | DepositExportFormat
    | TransactionExportFormat
    | DebitCardTransactionsExportFormat
    | SimBillingStatementExportFormat
> {
  title: string
  selectedFormat: T
  setSelectedFormat: React.Dispatch<React.SetStateAction<T>>
  closeModal: () => void
  onConfirm: () => void
  type: Record<string, string>
  info?: string
}

export const StatementsModalContent = <
  T extends
    | DepositExportFormat
    | TransactionExportFormat
    | DebitCardTransactionsExportFormat
    | SimBillingStatementExportFormat
>({
  title,
  selectedFormat,
  setSelectedFormat,
  closeModal,
  onConfirm,
  type,
  info,
}: Props<T>) => {
  const tileNames = Object.keys(type).map((key) => key as T)

  const sortedTileNames = [
    ...tileNames.filter((name) => name === type.PDF),
    ...tileNames.filter((name) => name !== type.PDF),
  ]

  const radioOptions = sortedTileNames.map((name) => (
    <RadioListCard
      key={name}
      id={name}
      title={page.exportFile.formatName[name]}
      value={name}
    />
  ))

  return (
    <>
      <StyledHeader>{title}</StyledHeader>
      <StyledDescription>{page.exportFile.chooseFormat}</StyledDescription>
      <RadioGroupBasic
        gap="8px"
        isVerticalDisplay
        name="export-format"
        // NOTE: update on change callback to pass T type
        onChange={(selectedFormat) => setSelectedFormat(selectedFormat as T)}
        value={selectedFormat}
      >
        {radioOptions}
      </RadioGroupBasic>
      {info && (
        <Box mt="8px">
          <InfoBox>
            <InfoBox.Message>{info}</InfoBox.Message>
          </InfoBox>
        </Box>
      )}

      <StyledButtonsWrapper>
        <CancelButton onClick={closeModal}>{shared.cancel}</CancelButton>
        <ConfirmButton onClick={onConfirm} disabled={!selectedFormat}>
          {shared.download}
        </ConfirmButton>
      </StyledButtonsWrapper>
    </>
  )
}
