import { forwardRef } from 'react'

import { ChartData } from '../../Charts.types'
import { StyledTooltipWrapper } from './Tooltip.styled'

export type RenderContent = ({
  label,
  value,
}: {
  label: number
  value: number
}) => React.ReactNode

interface Props {
  data: ChartData<number | string>
  renderContent: RenderContent
  label?: number
  className?: string
}

export const Tooltip = forwardRef<HTMLDivElement, Props>(
  ({ data, label, renderContent, className }, ref) => {
    const index = data.findIndex(({ name }) => name === label)

    const value = data[index]?.value

    return (
      <StyledTooltipWrapper className={className} ref={ref}>
        {renderContent({ label: label ?? 0, value: value ?? 0 })}
      </StyledTooltipWrapper>
    )
  }
)
