import crossmark from 'assets/animations/crossmark/crossmark.json'
import { ExportFileExportMessage as ErrorMessage } from 'components/ExportFileErrorMessage/ExportFileErrorMessage'
import { StyledModalWrapper } from 'components/ExportFileModalContent/ExportFileModalContent.styled'
import { StyledLoaderWrapper } from 'components/ExportFileModalContent/ExportFileModalView/ExportFileModalView.styled'
import { Status } from 'components/Status/Status'

interface ExportTransactionsErrorMessageProps {
  hasTimePassed: boolean
  closeModal: () => void
  rerenderContent: () => void
}

export const ExportTransactionsErrorMessage = ({
  hasTimePassed,
  closeModal,
  rerenderContent,
}: ExportTransactionsErrorMessageProps) => (
  <StyledModalWrapper isExpanded={hasTimePassed}>
    <StyledLoaderWrapper>
      <Status icon={crossmark} />
    </StyledLoaderWrapper>
    <ErrorMessage closeModal={closeModal} rerenderContent={rerenderContent} />
  </StyledModalWrapper>
)
