import {
  BodyLargeTypography,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StationIdLabel = styled.div`
  ${BodyLargeTypography};
  ${InterZellerMediumFont};
`
