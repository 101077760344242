import { gql, type TypedDocumentNode } from '@apollo/client'

import { MapToChartDataCashFlowPeriodicTotalAmountFragmentDoc } from './IncomeAndExpenseChart.utils.generated'

export type IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  range: { __typename?: 'CashFlowReportRange'; start: any }
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    expense: { __typename?: 'Money'; value: string }
    income: { __typename?: 'Money'; value: string }
  }
}

export const IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragmentDoc = gql`
  fragment IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
    ...MapToChartDataCashFlowPeriodicTotalAmountFragment
  }
  ${MapToChartDataCashFlowPeriodicTotalAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  IncomeAndExpenseChartCashFlowPeriodicTotalAmountFragment,
  undefined
>
