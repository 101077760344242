import { gql } from '@apollo/client'

export const DebitCardTransactionAmountUpdate = gql`
  subscription DebitCardTransactionAmountUpdate($entityUuid: ID!) {
    onDebitCardTransactionUpdate(entityUuid: $entityUuid) {
      id
      amount {
        value
      }
      status
      timestamp
      type
    }
  }
`
