import { SvgIcon } from '@npco/zeller-design-system'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

import { StyledWrapper } from './AccountTransactionTypeIcon.styled'
import {
  getTransactionIconsV2,
  getTransactionIconsV3,
} from './AccountTransactionTypeIcon.utils'

interface AccountTransactionTypeIconProps {
  transaction:
    | Pick<DebitCardTransactionV2, '__typename' | 'type'>
    | Pick<DebitCardTransactionV3, '__typename' | 'type'>
  color?: string
}

export const AccountTransactionTypeIcon = ({
  transaction,
  color,
}: AccountTransactionTypeIconProps) => {
  const transactionTypeIcon =
    transaction.__typename === 'DebitCardTransactionV2'
      ? getTransactionIconsV2({ type: transaction.type })
      : getTransactionIconsV3({ type: transaction.type })

  return (
    <StyledWrapper>
      <SvgIcon color={color}>{transactionTypeIcon}</SvgIcon>
    </StyledWrapper>
  )
}
