import { createContext, useContext } from 'react'
import { IdToken, LogoutOptions } from '@auth0/auth0-react'

import { AuthScope } from 'types/auth'

export type ZellerAuthenticationContextType = {
  fetchTokenClaims: () => Promise<void>
  getNewToken: any
  idTokenClaims: IdToken | undefined
  isAuthenticated: boolean
  isClientSetup: boolean
  isSubscriptionsSetup: boolean
  logout: (options?: LogoutOptions) => void
  redirectTo: string | undefined
  scope: string | undefined
  setIsClientSetup: (arg: boolean) => void
  setIsSubscriptionsSetup: (arg: boolean) => void
  setShouldRedirectAfterLogin: (arg: boolean) => void
  shouldRedirectAfterLogin: boolean
  stepUpAuth: (state?: any) => void
  token: string | undefined
  updateScope: (arg: AuthScope) => void
  updateToken: (arg: string) => void
  loginWithInactiveTimeout: (redirect: string) => void
}

export const ZellerAuthenticationContext = createContext<
  ZellerAuthenticationContextType | undefined
>(undefined)

ZellerAuthenticationContext.displayName = 'ZellerAuthenticationContext'

export const useZellerAuthenticationContext = () => {
  const context = useContext(ZellerAuthenticationContext)

  if (!context) {
    throw new Error(
      'useZellerAuthentication must be used within ZellerAuthenticationContext'
    )
  }

  return context
}

export type TokenUserMetadata = {
  customerUuid: string | undefined
  entityUuid: string | undefined
}

export const useTokenUserMetadata = (): TokenUserMetadata | undefined => {
  const { idTokenClaims } = useZellerAuthenticationContext()
  const metadata =
    idTokenClaims?.['https://identity.myzeller.com/user_metadata']
  if (!metadata) {
    return undefined
  }

  const { customerUuid, entityUuid } = metadata
  return {
    customerUuid: typeof customerUuid === 'string' ? customerUuid : undefined,
    entityUuid: typeof entityUuid === 'string' ? entityUuid : undefined,
  }
}
