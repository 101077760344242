import SetupFlowAccountsLarge from 'assets/images/setup-flow/setup-flow-accounts-large.png'
import SetupFlowAccountsMedium from 'assets/images/setup-flow/setup-flow-accounts-medium.png'
import SetupFlowAccountsSmall from 'assets/images/setup-flow/setup-flow-accounts-small.png'
import SetupFlowAppDownloadLarge from 'assets/images/setup-flow/setup-flow-app-download-large.png'
import SetupFlowAppDownloadMedium from 'assets/images/setup-flow/setup-flow-app-download-medium.png'
import SetupFlowAppDownloadSmall from 'assets/images/setup-flow/setup-flow-app-download-small.png'
import SetupFlowDebitCardBack from 'assets/images/setup-flow/setup-flow-cards-debit-card-back.png'
import SetupFlowDebitCardFront from 'assets/images/setup-flow/setup-flow-cards-debit-card-front.png'
import SetupFlowCardsLarge from 'assets/images/setup-flow/setup-flow-cards-large.png'
import SetupFlowCardsMedium from 'assets/images/setup-flow/setup-flow-cards-medium.png'
import SetupFlowCardsSmall from 'assets/images/setup-flow/setup-flow-cards-small.png'
import SetupFlowContactsLarge from 'assets/images/setup-flow/setup-flow-contacts-large.png'
import SetupFlowContactsMedium from 'assets/images/setup-flow/setup-flow-contacts-medium.png'
import SetupFlowContactsSmall from 'assets/images/setup-flow/setup-flow-contacts-small.png'
import SetupFlowInvoicesLargeOne from 'assets/images/setup-flow/setup-flow-invoices-large-one.png'
import SetupFlowInvoicesLargeThree from 'assets/images/setup-flow/setup-flow-invoices-large-three.png'
import SetupFlowInvoicesLargeTwo from 'assets/images/setup-flow/setup-flow-invoices-large-two.png'
import SetupFlowInvoicesMediumOne from 'assets/images/setup-flow/setup-flow-invoices-medium-one.png'
import SetupFlowInvoicesMediumThree from 'assets/images/setup-flow/setup-flow-invoices-medium-three.png'
import SetupFlowInvoicesMediumTwo from 'assets/images/setup-flow/setup-flow-invoices-medium-two.png'
import SetupFlowInvoicesSmallOne from 'assets/images/setup-flow/setup-flow-invoices-small-one.png'
import SetupFlowInvoiceSmallThree from 'assets/images/setup-flow/setup-flow-invoices-small-three.png'
import SetupFlowInvoicesSmallTwo from 'assets/images/setup-flow/setup-flow-invoices-small-two.png'
import SetupFlowOverviewLarge from 'assets/images/setup-flow/setup-flow-overview-large.png'
import SetupFlowOverviewMedium from 'assets/images/setup-flow/setup-flow-overview-medium.png'
import SetupFlowOverviewSmall from 'assets/images/setup-flow/setup-flow-overview-small.png'
import SetupFlowPaymentsLarge from 'assets/images/setup-flow/setup-flow-payments-large.png'
import SetupFlowPaymentsMedium from 'assets/images/setup-flow/setup-flow-payments-medium.png'
import SetupFlowPaymentsSmall from 'assets/images/setup-flow/setup-flow-payments-small.png'
import SetupFlowStartLarge from 'assets/images/setup-flow/setup-flow-start-large.png'
import SetupFlowStartMedium from 'assets/images/setup-flow/setup-flow-start-medium.png'
import SetupFlowStartSmall from 'assets/images/setup-flow/setup-flow-start-small.png'
import SetupFlowTapToPayAnimated from 'assets/images/setup-flow/setup-flow-tap-to-pay.webp'
import SetupFlowTapToPayPhone from 'assets/images/setup-flow/setup-flow-tap-to-pay-phone.png'
import SetupFlowHighlightUrl from 'assets/svg/highlight.svg'

interface GetPreLoadImagesProps {
  isMobile: boolean
  isTablet: boolean
}

export const getPreLoadImages = ({
  isMobile,
  isTablet,
}: GetPreLoadImagesProps) => {
  const commonImages: string[] = [
    SetupFlowDebitCardFront,
    SetupFlowDebitCardBack,
    SetupFlowHighlightUrl,
    SetupFlowTapToPayAnimated,
    SetupFlowTapToPayPhone,
  ]

  if (isMobile) {
    return [
      ...commonImages,
      SetupFlowStartSmall,
      SetupFlowAppDownloadSmall,
      SetupFlowContactsSmall,
      SetupFlowPaymentsSmall,
      SetupFlowOverviewSmall,
      SetupFlowAccountsSmall,
      SetupFlowCardsSmall,
      SetupFlowInvoicesSmallOne,
      SetupFlowInvoicesSmallTwo,
      SetupFlowInvoiceSmallThree,
    ]
  }

  if (isTablet) {
    return [
      ...commonImages,
      SetupFlowStartMedium,
      SetupFlowContactsMedium,
      SetupFlowPaymentsMedium,
      SetupFlowOverviewMedium,
      SetupFlowAccountsMedium,
      SetupFlowCardsMedium,
      SetupFlowInvoicesMediumOne,
      SetupFlowInvoicesMediumTwo,
      SetupFlowInvoicesMediumThree,
      SetupFlowAppDownloadMedium,
    ]
  }

  return [
    ...commonImages,
    SetupFlowStartLarge,
    SetupFlowAppDownloadLarge,
    SetupFlowContactsLarge,
    SetupFlowPaymentsLarge,
    SetupFlowOverviewLarge,
    SetupFlowAccountsLarge,
    SetupFlowCardsLarge,
    SetupFlowInvoicesLargeOne,
    SetupFlowInvoicesLargeTwo,
    SetupFlowInvoicesLargeThree,
    SetupFlowAppDownloadLarge,
  ]
}
