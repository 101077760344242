import {
  BodySmallTypography,
  ButtonGhost,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const HeaderWrapper = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.WHITE};
`

export const HeaderActiveItem = styled.span`
  color: ${({ theme }) => theme.colors.BLUE_1000};
  margin-left: 4px;
`

export const MobileHeaderItem = styled(ButtonGhost)`
  ${BodySmallTypography};
  padding: 6px;
  color: ${({ theme }) => theme.colors.GREY_550};
`
