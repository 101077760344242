import { Box, InfoBox, InputAdaptiveField } from '@npco/zeller-design-system'

import { EditCardNameStage } from 'hooks/useGoToEditCardNameStage/useGoToEditCardNameStage.types'
import { combineValidators, validateRequired } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { validateNickname } from 'forms/formViews/BankAccountFields/BankAccountFields.utils'
import { Condition } from 'components/Condition/Condition'

import { useEditCardNameState } from '../hooks/useEditCardNameState'

export const EditCardNameForm = () => {
  const { state } = useEditCardNameState()

  return (
    <Condition shouldShow={state?.stage === EditCardNameStage.EditCardName}>
      <Box>
        <InfoBox>
          <InfoBox.Message>
            {translate('page.editCardNameModal.nicknameDescription')}
          </InfoBox.Message>
        </InfoBox>
        <Box mt="24px">
          <InputAdaptiveField
            label={translate('page.editCardNameModal.nicknameLabel')}
            name="nickname"
            validate={combineValidators(validateNickname, validateRequired)}
          />
        </Box>
      </Box>
    </Condition>
  )
}
