import {
  BodyDefaultTypography,
  BodyLargeTypography,
} from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled from 'styled-components'

import { ButtonPadding, ButtonProps } from 'types/button'
import { BUTTON_SIZE } from 'types/common'
import { StyledLoaderWrapper } from 'components/LoaderSimple/LoaderSimple.styled'

const buttonPadding: ButtonPadding = {
  [BUTTON_SIZE.LARGE]: '13px 24px',
  [BUTTON_SIZE.DEFAULT]: '11px 22px',
  [BUTTON_SIZE.SMALL]: '7px 24px',
  [BUTTON_SIZE.XSMALL]: '3px 16px',
}

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-weight: normal;
  box-sizing: border-box;
  padding: ${(props) => buttonPadding[props.size || BUTTON_SIZE.DEFAULT]};
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  border-radius: 7px;
  outline: none;
  background-color: ${({ isSecondary, theme }) =>
    isSecondary ? theme.colors.WHITE : theme.colors.BLUE_1000};
  color: ${({ isSecondary, theme }) =>
    isSecondary ? theme.colors.BLUE_1000 : theme.colors.WHITE};
  transition: all 0.3s ease-out;
  cursor: pointer;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  ${(props) => props.size === BUTTON_SIZE.LARGE && 'min-height: 54px;'}
  ${(props) =>
    props.size === BUTTON_SIZE.LARGE
      ? BodyLargeTypography
      : BodyDefaultTypography}

  &:hover {
    ${({ isSecondary, theme }) =>
      !isSecondary && `background-color: ${theme.colors.BLUE_700})`};
    ${({ isSecondary, theme }) =>
      isSecondary && `border-color: ${theme.colors.BLUE_400})`};
  }

  &:focus-visible {
    background-color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.GREY_30 : theme.colors.BLUE_400};
    color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.BLUE_1000 : theme.colors.WHITE};
    border-color: ${({ theme }) => theme.colors.BLUE_400};
  }

  &:active {
    background-color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.GREY_30 : theme.colors.BLUE_700};
  }

  &:disabled {
    background-color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.WHITE : theme.colors.GREY_150};
    border-color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.GREY_250 : theme.colors.GREY_150};
    color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.colors.GREY_250 : rgba(theme.colors.GREY_400, 0.75)};
    cursor: not-allowed;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.GREY_250};
      }
    }
  }

  ${StyledLoaderWrapper} {
    position: relative;
    margin-left: -1rem;
    transition: all 0.2s ease-out;

    ${({ isLoading }) =>
      isLoading &&
      `
      left:2rem;
    `}
  }
`
