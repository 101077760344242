import { ApolloCache } from '@apollo/client'

import {
  GetSetupFlowDebitCards,
  GetSetupFlowDebitCardsQueryResponse,
  GetSetupFlowDebitCardsQueryVariables,
} from '../useSetupFlowCardsQuery/graphql/getCards.generated'
import { NewDebitCardType } from './graphql/requestNewCard.types'

interface CacheNewCardProps {
  cache: ApolloCache<any>
  newCard: NewDebitCardType
  entityUuid: string
}
export const cacheNewCard = ({
  cache,
  newCard,
  entityUuid,
}: CacheNewCardProps) => {
  const cachedCards =
    cache.readQuery<
      GetSetupFlowDebitCardsQueryResponse,
      GetSetupFlowDebitCardsQueryVariables
    >({
      query: GetSetupFlowDebitCards,
      variables: { entityUuid },
    })?.getDebitCardsV2.cards || []

  cache.writeQuery<
    GetSetupFlowDebitCardsQueryResponse,
    GetSetupFlowDebitCardsQueryVariables
  >({
    query: GetSetupFlowDebitCards,
    variables: { entityUuid },
    data: {
      getDebitCardsV2: {
        cards: [newCard, ...cachedCards],
      },
    },
  })
}
