import { Spinner } from '@npco/zeller-design-system'

import { ensureIsArray } from 'utils/common'

import {
  StyledLoadingSubtext,
  StyledLoadingWrapper,
  StyledSubtextWrapper,
} from './InProgressDisplay.styled'

interface Props {
  subtext: string | string[]
}

export const InProgressDisplay = ({ subtext }: Props) => {
  return (
    <StyledLoadingWrapper>
      <Spinner dataTestId="loader" />
      <StyledSubtextWrapper>
        {ensureIsArray(subtext).map((text) => (
          <StyledLoadingSubtext key={text}>{text}</StyledLoadingSubtext>
        ))}
      </StyledSubtextWrapper>
    </StyledLoadingWrapper>
  )
}
