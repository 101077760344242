import { DataMediumTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Description = styled.div`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  button {
    &:first-of-type {
      margin-right: 8px;
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`
