import { UpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'
import { Condition } from 'components/Condition/Condition'

import { useUpgradeAccountsCompleteState } from '../../hooks/useUpgradeAccountsCompleteState'
import { UpgradeAccountsCompleteDebitCardsRewardsStageContent } from './UpgradeAccountsCompleteDebitCardsRewardsStageContent/UpgradeAccountsCompleteDebitCardsRewardsStageContent'

export const UpgradeAccountsCompleteDebitCardsRewardsStage = () => {
  const { state } = useUpgradeAccountsCompleteState()
  return (
    <Condition
      shouldShow={
        state?.stage ===
        UpgradeAccountsCompleteStage.UpgradeAccountsDebitCardsRewardsStage
      }
    >
      <UpgradeAccountsCompleteDebitCardsRewardsStageContent />
    </Condition>
  )
}
