import { gql, type TypedDocumentNode } from '@apollo/client'

export type CardOwnerCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  owner: string | null
}

export const CardOwnerCellDebitCardV2FragmentDoc = gql`
  fragment CardOwnerCellDebitCardV2Fragment on DebitCardV2 {
    owner
  }
` as unknown as TypedDocumentNode<CardOwnerCellDebitCardV2Fragment, undefined>
