import { BodySmall, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

export const StyledStatement = styled.div`
  padding: 20px 24px;
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  box-shadow: 0px -0.5px 2px rgba(34, 34, 38, 0.15),
    0px 1px 2px rgba(34, 34, 38, 0.15), 0px 4px 6px rgba(34, 34, 38, 0.08);
  flex: 0 0 3rem;
`

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  flex: 1;
`

export const StyledTitle = styled(H5)`
  margin: 0 0 4px 0;
`

export const StyledDescription = styled(BodySmall)`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`
