import { COLOR, DownloadIcon, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as EllipseLoader } from 'assets/svg/ellipse-loader.svg'
import { ReactComponent as Tick } from 'assets/svg/tick.svg'
import {
  StyledIconWrapper,
  StyledIconWrapperWithBackground,
  StyledLoaderWrapper,
} from 'components/Statements/StatementsButtons/StatementsBaseButton/StatementsBaseButton.styled'

interface Props {
  isLoadingData: boolean
  hasLoaded: boolean
  generateFile: () => void
}

export const StatementsBaseButton = ({
  isLoadingData,
  hasLoaded,
  generateFile,
}: Props) => (
  <>
    {!hasLoaded && !isLoadingData && (
      <StyledIconWrapper onClick={generateFile} data-testid="btn-generate">
        <SvgIcon height="16" width="16">
          <DownloadIcon />
        </SvgIcon>
      </StyledIconWrapper>
    )}
    {isLoadingData && (
      <StyledIconWrapperWithBackground data-testid="btn-loading">
        <StyledLoaderWrapper>
          <SvgIcon
            height="16"
            width="16"
            color={COLOR.WHITE}
            backgroundColor={COLOR.BLUE_1000}
          >
            <EllipseLoader />
          </SvgIcon>
        </StyledLoaderWrapper>
      </StyledIconWrapperWithBackground>
    )}
    {hasLoaded && !isLoadingData && (
      <StyledIconWrapperWithBackground data-testid="btn-check">
        <SvgIcon
          height="16"
          width="16"
          color={COLOR.WHITE}
          backgroundColor={COLOR.BLUE_1000}
        >
          <Tick />
        </SvgIcon>
      </StyledIconWrapperWithBackground>
    )}
  </>
)
