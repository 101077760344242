import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useModalState } from 'design-system/Components/Modal'

import dayjs from 'utils/dayjs'
import { GetDeposits_getDeposits_deposits as Deposit } from 'types/gql-types/GetDeposits'
import { renderGroup } from 'components/Lists/GroupedList/Group/Group.utils'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListGroupTitle } from 'components/Lists/ListGroupTitle/ListGroupTitle'

import { useDrawerNavigation } from '../hooks/useDrawerNavigation'
import { DepositDrawer } from './DepositDrawer'
import { StyledDepositsListWrapper } from './DepositList.styled'
import { ListGroupDepositItem } from './ListGroupDepositItem'

interface DepositListProps {
  groupedDeposits: Deposit[][]
  fetchMoreDeposits: () => void
  hasReachedEnd: boolean
}

interface DespositId {
  depositUuid: string
}

export const DepositList = ({
  groupedDeposits,
  fetchMoreDeposits,
  hasReachedEnd,
}: DepositListProps) => {
  const [selectedDeposit, setSelectedDeposit] = useState<Deposit | null>(null)
  const { onSelectNextDeposit, onSelectPreviousDeposit } = useDrawerNavigation({
    groupedDeposits,
    selectedDeposit,
    setSelectedDeposit,
  })

  const history = useHistory<DespositId>()
  const { openModal, isModalOpen, closeModal } = useModalState()
  const openDrawer = useCallback(
    (deposit: Deposit) => {
      setSelectedDeposit(deposit)
      openModal()
    },
    [openModal]
  )

  const onCloseDrawer = useCallback(() => {
    closeModal()
  }, [closeModal])

  useEffect(() => {
    if (history.location?.state?.depositUuid) {
      openDrawer({ id: history.location.state.depositUuid } as Deposit)
    }
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderItem = (deposit: Deposit) => (
    <ListGroupDepositItem
      data={deposit}
      onRecordSelect={openDrawer}
      key={deposit.id}
    />
  )

  return (
    <>
      <StyledDepositsListWrapper>
        <GroupedList
          data={groupedDeposits}
          onScrollEnd={fetchMoreDeposits}
          renderItem={renderItem}
          renderGroup={renderGroup({
            getGroupKey: ([firstElement]) => {
              return dayjs(firstElement?.timestamp).startOf('year')
            },
            renderHeader: ([firstElement]) => (
              <ListGroupTitle data-testid="list-group-header">
                {dayjs(firstElement?.timestamp).year()}
              </ListGroupTitle>
            ),
          })}
          hasMore={!hasReachedEnd}
        />
      </StyledDepositsListWrapper>
      {selectedDeposit && (
        <DepositDrawer
          selectedDeposit={selectedDeposit}
          isModalOpen={isModalOpen}
          closeModal={onCloseDrawer}
          setSelectedDeposit={setSelectedDeposit}
          onSelectNextDeposit={onSelectNextDeposit}
          onSelectPreviousDeposit={onSelectPreviousDeposit}
        />
      )}
    </>
  )
}
