import { ButtonBasicProps } from '@npco/zeller-design-system'

import {
  BodySmallNoMargin,
  BorderedButtonFillWrapper,
} from './BorderedButtonFill.styled'

export const BorderedButtonFill = ({
  children,
  ...props
}: ButtonBasicProps) => {
  return (
    <BorderedButtonFillWrapper {...props}>
      <BodySmallNoMargin>{children}</BodySmallNoMargin>
    </BorderedButtonFillWrapper>
  )
}
