import { gql } from '@apollo/client'

export const GetInvoicePreview = gql`
  query GetInvoicePreview($entityUuid: ID!, $invoice: PreviewInvoiceInput!) {
    getInvoicePreview(entityUuid: $entityUuid, input: $invoice) {
      downloadLink
      expire
    }
  }
`
