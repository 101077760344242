import {
  CompleteCardReplacementSetupState,
  CompleteCardSetupState,
  CompleteCardStage,
} from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { translate } from 'utils/translations'

export const getCompleteCardSetupModalHeading = (
  state: CompleteCardSetupState | CompleteCardReplacementSetupState
): string => {
  if (state.stage === CompleteCardStage.CompleteCardSetupReplacementStage) {
    return translate(
      'page.completeCardModal.completeCardReplacementSetup.heading',
      {
        cardholderFirstName: state.cardholderFirstName,
      }
    )
  }
  return translate('page.completeCardModal.completeCardSetup.heading')
}

export const getCompleteCardSetupModalDescription = (
  state: CompleteCardSetupState | CompleteCardReplacementSetupState
): string => {
  if (state.stage === CompleteCardStage.CompleteCardSetupReplacementStage) {
    return translate(
      'page.completeCardModal.completeCardReplacementSetup.description',
      {
        cardholderFirstName: state.cardholderFirstName,
      }
    )
  }
  return translate('page.completeCardModal.completeCardSetup.description')
}
