import { ButtonProps } from 'types/button'
import { BUTTON_SIZE } from 'types/common'
import { LoaderSimple } from 'components/LoaderSimple'

import { StyledButton } from './Button.styled'

export const Button = ({
  children,
  dataTestId,
  type = 'button',
  forwardRef,
  size = BUTTON_SIZE.DEFAULT,
  isLoading = false,
  disabled,
  ...props
}: ButtonProps) => (
  <StyledButton
    data-testid={dataTestId ?? 'button'}
    type={type}
    ref={forwardRef}
    size={size}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    {...props}
  >
    {children}
    {isLoading && <LoaderSimple isVisible={isLoading} />}
  </StyledButton>
)
