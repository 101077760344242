import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useAccountId } from 'hooks/banking'

import {
  GetAccountDebitCardsVerbose as CardsQuery,
  GetAccountDebitCardsVerboseQueryResponse,
  GetAccountDebitCardsVerboseQueryVariables,
} from './graphql/GetAccountDebitCards.generated'

export const useAccountCards = () => {
  const { accountId } = useAccountId()
  const entityUuid = useSelectedEntityUuid()

  const { data, loading } = useQuery<
    GetAccountDebitCardsVerboseQueryResponse,
    GetAccountDebitCardsVerboseQueryVariables
  >(CardsQuery, {
    variables: {
      debitCardAccountUuid: accountId,
      entityUuid,
    },
    errorPolicy: 'all',
  })

  const account = useMemo(() => {
    return data?.getDebitCardAccountV2 || null
  }, [data?.getDebitCardAccountV2])

  const entity = useMemo(() => {
    return data?.getEntity ?? null
  }, [data?.getEntity])

  return {
    account,
    entity,
    isLoading: loading,
  }
}
