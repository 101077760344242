import type { FeatureFlagsSource } from '@npco/utils-feature-flags'
import * as LaunchDarklySDK from 'launchdarkly-js-client-sdk'

type Options = {
  envKey: string
  context: LaunchDarklySDK.LDContext
}

export type { Options as LaunchDarklySourceOptions }

export const LaunchDarklySource = ({
  envKey,
  context,
}: Options): FeatureFlagsSource => {
  let selfClient: LaunchDarklySDK.LDClient | undefined
  return {
    key: 'LaunchDarkly',
    init: ({ onValues }) => {
      const client = LaunchDarklySDK.initialize(envKey, context)
      selfClient = client
      // `ready` fires on both `success` and `failure`.
      client.on('ready', () => {
        const values = client.allFlags()
        onValues(values)
      })
      // `change` fires when any flag changes.
      client.on('change', () => {
        const values = client.allFlags()
        onValues(values)
      })
    },
    dispose: () => {
      if (!selfClient) {
        return
      }
      selfClient.close()
      selfClient = undefined
    },
  }
}
