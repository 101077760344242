import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { AnimatedPhone } from './TapToPayStage/AnimatedPhone'
import { MainContent } from './TapToPayStage/MainContent'

export const TapToPayStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const { goToStage } = useSetupFlowModal()

  return (
    <Tutorial.TutorialLayout
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage('FeaturePaymentsStage')}
            onSecondaryButtonClick={() => goToStage('DownloadAppStage')}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={<AnimatedPhone />}
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
