import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { Section, SectionLink, SectionTitle } from './components'

type PhoneSectionProps = {
  phone: string | null
}

export const PhoneSection = ({ phone }: PhoneSectionProps): JSX.Element => {
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Section>
      <SectionTitle>
        {translate('page.settings.profileSettings.phoneNumber')}
      </SectionTitle>
      <div>{phone}</div>
      <div>
        <SectionLink
          to={
            ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.SECURITY.CHANGE_NUMBER
              .path
          }
        >
          {translate('page.settings.profileSettings.changeNumber')}
        </SectionLink>
      </div>
    </Section>
  )
}
