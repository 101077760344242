import styled from 'styled-components'

export const BlackTile = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(137deg, #000 0%, #8c959c 100%);
`

export const WhiteTile = styled.div`
  background: linear-gradient(137deg, #fff 0%, #f5f5f5 100%);
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.GREY_90};
  border-radius: 8px;
  border-style: solid;
  box-sizing: border-box;
`

export const GreyTile = styled.div`
  background: linear-gradient(137deg, #6d7274 0%, #b0b7bd 100%);
`

export const LogoWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.GREY_30};
  border-color: ${({ theme }) => theme.colors.GREY_90};
  justify-content: center;
  align-items: center;
  img {
    max-width: 40px;
    max-height: 40px;
  }
`
