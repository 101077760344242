import { GetSites_getSites_sites as ApiSite } from 'types/gql-types/GetSites'

export const mapSiteInfoToDevices = (sites: ApiSite[]) => {
  return sites.map((site) => {
    const devices = site?.devices ?? []
    return {
      ...site,
      devices: devices.map((device) => ({
        id: device?.id,
        name: device?.name,
        model: device?.model,
        serial: device?.serial,
        siteId: site.id,
        siteName: site.name,
      })),
    }
  })
}
