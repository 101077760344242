import { gql } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { translate } from 'utils/translations'

import {
  GetAccountStatusLabelDebitCardAccountV2Fragment,
  ShouldDisplayStatusDebitCardAccountV2Fragment,
} from './AccountStatus.utils.generated'

const accountStatusTranslation = {
  [DebitCardAccountStatus.SUSPENDED]: translate('shared.cardStatus.suspended'),
  [DebitCardAccountStatus.CLOSED]: translate('shared.cardStatus.closed'),
  [DebitCardAccountStatus.ACTIVE]: translate('shared.cardStatus.active'),
}

export const getAccountStatusLabel = (
  account: GetAccountStatusLabelDebitCardAccountV2Fragment
) => accountStatusTranslation[account.status]

getAccountStatusLabel.fragments = {
  DebitCardAccountV2: gql`
    fragment GetAccountStatusLabelDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status
    }
  `,
}

export const shouldDisplayStatus = (
  account: ShouldDisplayStatusDebitCardAccountV2Fragment
) =>
  account.status === DebitCardAccountStatus.CLOSED ||
  account.status === DebitCardAccountStatus.SUSPENDED

shouldDisplayStatus.fragments = {
  DebitCardAccountV2: gql`
    fragment ShouldDisplayStatusDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status
    }
  `,
}
