import { useCallback, useEffect, useRef } from 'react'
import { Prompt } from 'react-router-dom'
import { ModalBasic, Title, useModalState } from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { useSubmitFormValidation } from '../../hooks/useSubmitFormValidation'
import { InvoiceFormFields } from '../../Invoice.types'

export const translations = {
  title: translate('page.invoice.closePromptModal.unsavedChangesTitle'),
  message: translate('page.invoice.closePromptModal.unsavedChangesMessage'),
  discardChanges: translate('page.invoice.closePromptModal.discardChanges'),
  save: translate('page.invoice.closePromptModal.save'),
}

interface InvoiceFormClosePromptProps {
  isLoading: boolean
  onDiscardChanges: () => void
  onSave: () => void
  onRedirectPrompt: (redirectUrl: string) => void
  shouldClosePrompt?: boolean
}

export const InvoiceFormClosePrompt = ({
  isLoading,
  onDiscardChanges,
  onSave,
  onRedirectPrompt,
  shouldClosePrompt,
}: InvoiceFormClosePromptProps) => {
  const { dirty, values } = useFormikContext<InvoiceFormFields>()
  const { trackDiscardedInvoice } = useInvoiceAnalytics()
  const { closeModal, isModalOpen, openModal } = useModalState()

  const isConfirmedNavigation = useRef(false)

  useSubmitFormValidation({
    isSendingInvoice: false,
    onSubmitError: () => {
      isConfirmedNavigation.current = false
      onRedirectPrompt('')
      closeModal()
    },
    shouldShowToastOnError: false,
  })

  useEffect(() => {
    if (shouldClosePrompt) {
      closeModal()
    }
  }, [closeModal, shouldClosePrompt])

  const handleOnClick = useCallback(() => {
    isConfirmedNavigation.current = true
    onSave()
  }, [onSave])

  return (
    <>
      <Prompt
        when={dirty}
        message={(location) => {
          if (isConfirmedNavigation.current) {
            return true
          }

          onRedirectPrompt(location.pathname)
          openModal()

          return false
        }}
      />
      <ModalBasic
        hasCloseButton
        shouldCloseOnOverlayClick={!isLoading}
        isOpen={isModalOpen}
        title={translations.title}
        onCancel={() => {
          closeModal()
          onRedirectPrompt('')
        }}
      >
        <ModalBasic.Body marginBottom={12}>
          <Title>{translations.message}</Title>
        </ModalBasic.Body>
        <ModalBasic.Footer>
          <ModalBasic.SecondaryButton
            onClick={() => {
              isConfirmedNavigation.current = true
              trackDiscardedInvoice(values)
              onDiscardChanges()
            }}
          >
            {translations.discardChanges}
          </ModalBasic.SecondaryButton>
          <ModalBasic.PrimaryButton
            isLoading={isLoading}
            onClick={handleOnClick}
          >
            {translations.save}
          </ModalBasic.PrimaryButton>
        </ModalBasic.Footer>
      </ModalBasic>
    </>
  )
}
