import { useTranslations } from '@npco/utils-translations'

import { EmptyStateMessageWithCard } from 'components/EmptyState/EmptyStateMessageWithCard'
import { EmptyStateCard } from 'components/EmptyStateCard/EmptyStateCard'

import { translations } from './DevicesEmptyState.i18n'

export const DevicesEmptyState = () => {
  const t = useTranslations(translations)

  return (
    <EmptyStateMessageWithCard
      title={t('title')}
      description={t('description')}
      illustrationName="TapToPayIphone"
      card={
        <EmptyStateCard
          heading={t('cardHeading')}
          valuePoints={[t('point1'), t('point2'), t('point3')]}
        />
      }
    />
  )
}
