import { useTranslations } from '@npco/utils-translations'

import { Description } from 'layouts'

import { translations } from './AssignedCardsSection.i18n'

type Props = {
  isAdmin: boolean
  isEditForm: boolean
  isVerified: boolean
  hasNoCards: boolean
}

export const AssignedCardsDescription = ({
  isAdmin,
  isEditForm,
  isVerified,
  hasNoCards,
}: Props) => {
  const t = useTranslations(translations)

  if (isAdmin) {
    return <Description>{t('adminDescription')}</Description>
  }

  if (isEditForm && !isAdmin && isVerified) {
    if (hasNoCards) {
      return <Description>{t('managerNoCardsDescription')}</Description>
    }
    return <Description>{t('managerDescription')}</Description>
  }

  return null
}
