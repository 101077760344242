import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SimImageContainer = styled(Flex)`
  align-items: center;
  border-radius: 8px;
  height: 200px;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.GREY_30};

  img {
    transform: rotate(-15deg);
  }
`
