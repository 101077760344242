import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { LockCardState } from './useGoToLockCardStage.types'

export const useGoToLockCardStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToLockCardStage = useCallback(
    (state: LockCardState | undefined) => {
      navigate(location.pathname, { state: { LockCardModal: state } })
    },
    [navigate, location]
  )

  return { goToLockCardStage }
}
