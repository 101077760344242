import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  ReportLostAndReplaceCardLocationStateSchema,
  ReportLostAndReplaceCardStage,
  ReportLostAndReplaceCardState,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'

const REPORT_LOST_AND_REPLACE_CARD_STAGES = Object.values(
  ReportLostAndReplaceCardStage
)

export const useReportLostAndReplaceCardState = <
  T extends ReportLostAndReplaceCardState
>() => {
  const locationState = useLocationState(
    ReportLostAndReplaceCardLocationStateSchema
  )

  const state = useMemo(() => {
    if (
      !locationState?.ReportLostAndReplaceCardModal?.stage ||
      !REPORT_LOST_AND_REPLACE_CARD_STAGES.includes(
        locationState.ReportLostAndReplaceCardModal.stage
      )
    ) {
      return null
    }

    return locationState.ReportLostAndReplaceCardModal as T
  }, [locationState?.ReportLostAndReplaceCardModal])

  return { state }
}
