import { gql } from '@apollo/client'
import { getImageUrl } from '@npco/mp-utils-icon'
import { AvatarUser, AvatarUserSize } from '@npco/zeller-design-system'

import { UserIconAvatarIconFragment } from './UserIconAvatar.generated'

interface UserIconAvatarProps {
  icon: UserIconAvatarIconFragment | null
  firstName: string
  size: AvatarUserSize
}

export const UserIconAvatar = ({
  icon,
  firstName,
  size,
}: UserIconAvatarProps) => {
  const src = getImageUrl(icon)

  return <AvatarUser name={firstName} src={src || undefined} size={size} />
}

UserIconAvatar.fragments = {
  Icon: gql`
    fragment UserIconAvatarIconFragment on Icon {
      ...GetImageUrlIconFragment
    }

    ${getImageUrl.fragments.Icon}
  `,
}
