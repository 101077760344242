import { useEffect } from 'react'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { rvSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSelectedSimSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/sim'
import { Formik } from 'formik'

import { datePickerInitialState } from 'const/common'
import { ROOT } from 'const/routes'
import { StatementPeriod } from 'types/dates'
import { StatementsFormValues } from 'types/utils'
import { SimCustomRange } from 'pages/Settings/Sim/SimStatements/SimCustomRange/SimCustomRange'
import { SimActionButton } from 'pages/Settings/Sim/SimStatements/StatementsButtons/SimActionButton'
import { SpinnerWrapped } from 'components/Spinner'
import { useGetSitesWithTypes } from 'components/Statements/hooks/useGetSitesWithTypes'
import { useGetUIData } from 'components/Statements/hooks/useGetUIData'
import { Statement } from 'components/Statements/Statement/Statement'
import {
  StyledSitesHeader,
  StyledStatementsHeader,
} from 'components/Statements/Statements.styled'
import { ALL_SITES } from 'components/Statements/Statements.utils'
import { StatementsSitesField } from 'components/Statements/StatementsSitesField/StatementSitesField'
import { page, shared } from 'translations'

export const SimStatements = () => {
  const initialValues = {
    sites: ALL_SITES,
  }

  const shortEntityId = useSelectedShortEntityUuid()

  const { isLoading } = useGetSitesWithTypes()

  const {
    previousQuarterName,
    previousQuarterDate,
    currentQuarterName,
    currentQuarterDate,
    currentMonthDate,
    previousMonthDate,
  } = useGetUIData()

  useEffect(() => {
    rvSelectedDates(datePickerInitialState)
  }, [])

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" width="100%" justifyContent="space-between">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Link
                  to={
                    ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.BILLING_HISTORY
                      .path
                  }
                >
                  {page.settings.sim.billingHistory.title}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Breadcrumb.Text>
                  {page.settings.sim.statements.title}
                </Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>
        {isLoading ? (
          <SpinnerWrapped variant="centre" />
        ) : (
          <Formik<StatementsFormValues>
            initialValues={initialValues}
            onSubmit={() => undefined}
          >
            <>
              <StyledSitesHeader>
                {page.settings.statements.selectSite}
              </StyledSitesHeader>
              <StatementsSitesField reactiveVariable={rvSelectedSimSite} />
              <StyledStatementsHeader>
                {page.settings.statements.downloadStatement}
              </StyledStatementsHeader>
              <Statement
                title={previousQuarterName}
                description={previousQuarterDate}
                actionButton={
                  <SimActionButton
                    statementPeriod={StatementPeriod.PreviousQuarter}
                  />
                }
              />
              <Statement
                title={currentQuarterName}
                description={currentQuarterDate}
                actionButton={
                  <SimActionButton
                    statementPeriod={StatementPeriod.CurrentQuarter}
                  />
                }
              />
              <Statement
                title={shared.currentMonth}
                description={currentMonthDate}
                actionButton={
                  <SimActionButton
                    statementPeriod={StatementPeriod.CurrentMonth}
                  />
                }
              />
              <Statement
                title={shared.previousMonth}
                description={previousMonthDate}
                actionButton={
                  <SimActionButton
                    statementPeriod={StatementPeriod.PreviousMonth}
                  />
                }
              />
              <SimCustomRange />
            </>
          </Formik>
        )}
      </PageTemplate.Section>
    </PageTemplate>
  )
}
