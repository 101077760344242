import { ChangeEvent, useCallback, useEffect } from 'react'
import { Box, Flex } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { GetXeroBrandingThemes_getXeroBrandingThemes as XeroBrandingThemes } from 'types/gql-types/GetXeroBrandingThemes'

import {
  CheckAll,
  CheckAllLabel,
  Checkbox,
  ErrorMessage,
} from '../../../components/Form.styled'
import { XeroPaymentServicesConnetionFormValues } from '../../XeroPaymentServicesConnectionSetup/XeroPaymentServicesConnectionSetup.types'
import { BrandingThemeRow } from './BrandingThemeRow'

interface BrandingThemeSelectProps {
  brandingThemes: XeroBrandingThemes[]
}

export const BrandingThemeSelect = ({
  brandingThemes,
}: BrandingThemeSelectProps) => {
  const { handleChange, setFieldValue, values, errors, touched } =
    useFormikContext<XeroPaymentServicesConnetionFormValues>()

  const selectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        const allIds = brandingThemes.map((theme) => theme?.brandingThemeId)
        setFieldValue('xeroThemeIds', allIds)
        return
      }
      setFieldValue('xeroThemeIds', [])
    },
    [brandingThemes, setFieldValue]
  )

  useEffect(() => {
    const allIds = brandingThemes.map((theme) => theme?.brandingThemeId)
    setFieldValue('xeroThemeIds', allIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex flexDirection="column" mt="24px">
      <Flex mb="16px">
        <CheckAll>
          <Checkbox
            name="checkall"
            type="checkbox"
            onChange={selectAll}
            checked={values.xeroThemeIds.length === brandingThemes.length}
          />
          <Box ml="12px">
            <CheckAllLabel>
              {translate(
                'page.settings.connections.xeroPaymentServices.brandingThemeSelect.selectAll'
              )}
            </CheckAllLabel>
          </Box>
        </CheckAll>
      </Flex>
      {brandingThemes.map((theme) => (
        <BrandingThemeRow
          key={theme.brandingThemeId}
          id={theme.brandingThemeId as string}
          name={theme.name as string}
          checked={values.xeroThemeIds?.includes(
            theme?.brandingThemeId as string
          )}
          onChange={handleChange}
        />
      ))}
      {errors?.xeroThemeIds && touched?.xeroThemeIds && (
        <ErrorMessage>{errors.xeroThemeIds}</ErrorMessage>
      )}
    </Flex>
  )
}
