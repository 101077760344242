import styled, { css } from 'styled-components'

import { ReactComponent as ArrowDownFullSvg } from 'assets/svg/arrow-down-full.svg'

export const SortIndicator = styled(ArrowDownFullSvg)<{
  $ascending?: boolean | null
}>`
  ${({ $ascending }) =>
    $ascending &&
    css`
      transform: rotate(180deg);
    `}
`
