import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { gql } from '@apollo/client'
import { RevisionStatus } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { Box, ButtonFill, Heading } from '@npco/zeller-design-system'
import { ContactDetails } from 'features/Contacts/Contact/ContactSections/ContactDetails/ContactDetails'
import { ContactDetailsPlaceholder } from 'features/Contacts/Contact/ContactSections/ContactDetails/ContactDetailsPlaceholder'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'

import { useGetContactTransactionTotals } from 'hooks/useGetContactTransactionTotals/useGetContactTransactionTotals'
import { ROOT } from 'const/routes'
import { InvoiceContactFragment } from 'types/gql-types/InvoiceContactFragment'
import { TransactionTotals } from 'components/TransactionContact/components/LinkedTransaction/TransactionTotals/TransactionTotals'
import { ViewContactSection } from 'components/TransactionContact/components/LinkedTransaction/ViewContactSection/ViewContactSection'

import { translations } from './InvoiceDrawerContact.i18n'
import { LinkInvoiceBody } from './InvoiceDrawerContact.styled'
import { InvoiceDrawerContactDeleted } from './InvoiceDrawerContactDeleted'

export const InvoiceDrawerContact = ({
  customer,
  referenceNumber,
}: InvoiceContactFragment) => {
  const { push } = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translations)

  const contactUuid =
    customer?.payerContact?.status !== RevisionStatus.DELETED
      ? customer?.payerContact?.contactUuid
      : ''

  const { data: contact, loading } = useGetContact({
    id: contactUuid,
  })

  const { isContactTransactionTotalsLoading, transactionTotals } =
    useGetContactTransactionTotals({
      contactUuid: contactUuid ?? '',
    })

  const handleOnClick = useCallback(
    () =>
      push(
        ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.INVOICE(referenceNumber)
          .EDIT.path
      ),
    [push, referenceNumber, shortEntityId]
  )

  if (customer?.payerContact?.status === RevisionStatus.DELETED) {
    return <InvoiceDrawerContactDeleted customer={customer} />
  }

  if (!customer?.payerContact?.contactUuid) {
    return (
      <>
        <Box mb="40px">
          <Heading.H4>{t('linkInvoiceTitle')}</Heading.H4>
          <LinkInvoiceBody>{t('linkInvoiceBody')}</LinkInvoiceBody>
          <ButtonFill onClick={handleOnClick}>
            {t('linkInvoiceButton')}
          </ButtonFill>
        </Box>

        <ContactDetailsPlaceholder />
      </>
    )
  }

  return (
    <>
      <TransactionTotals
        isLoading={isContactTransactionTotalsLoading}
        transactionData={transactionTotals}
      />
      <Box mb="40px">
        <ContactDetails contact={contact} isLoading={loading} />
      </Box>
      <ViewContactSection contact={contact} isLoading={loading} />
    </>
  )
}

InvoiceDrawerContact.fragments = {
  InvoiceContact: gql`
    fragment InvoiceContactFragment on Invoice {
      referenceNumber
      customer {
        payerContact {
          contactUuid
          status
        }
      }
      ...InvoiceContactDeletedFragment
    }
    ${InvoiceDrawerContactDeleted.fragments.InvoiceContactDeleted}
  `,
}
