import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { AnyStyledComponent } from 'styled-components'

export const StyledListWrapper = styled.div`
  flex: 1;
  width: 100%;
  position: relative;

  &:not(.hasMore) {
    padding-bottom: 2.5rem;
  }
`

export const StyledInfiniteScroll = styled(
  // InfinityScroll typing issue
  InfiniteScroll as unknown as AnyStyledComponent
)`
  /* Important here is being used to overwrite the inline styling added by the InfiniteScroll component */
  overflow: unset !important;
`
