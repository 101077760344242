import { DebitCardColour } from '@npco/mp-gql-types'

import { ReactComponent as DarkBlueCorporateCardDefault } from 'assets/svg/cards/corporate-card-dark-blue-chip.svg'
import { ReactComponent as GreenCorporateCardDefault } from 'assets/svg/cards/corporate-card-green-chip.svg'
import { ReactComponent as LightBlueCorporateCardDefault } from 'assets/svg/cards/corporate-card-light-blue-chip.svg'
import { ReactComponent as YellowCorporateCardDefault } from 'assets/svg/cards/corporate-card-yellow-chip.svg'
import { ReactComponent as BlackDebitCardDefault } from 'assets/svg/cards/debit-card-black-chip.svg'
import { ReactComponent as WhiteDebitCardDefault } from 'assets/svg/cards/debit-card-white-chip.svg'

interface DefaultZellerCardProps {
  colour: DebitCardColour
}

export const DefaultZellerCard = ({ colour }: DefaultZellerCardProps) => {
  switch (colour) {
    case DebitCardColour.WHITE:
      return <WhiteDebitCardDefault data-testid="white-debit-card-default" />
    case DebitCardColour.DARKBLUE:
      return (
        <DarkBlueCorporateCardDefault data-testid="dark-blue-corporate-card-default" />
      )
    case DebitCardColour.LIGHTBLUE:
      return (
        <LightBlueCorporateCardDefault data-testid="light-blue-corporate-card-default" />
      )
    case DebitCardColour.GREEN:
      return (
        <GreenCorporateCardDefault data-testid="green-corporate-card-default" />
      )
    case DebitCardColour.YELLOW:
      return (
        <YellowCorporateCardDefault data-testid="yellow-corporate-card-default" />
      )
    case DebitCardColour.BLACK:
    default:
      return <BlackDebitCardDefault data-testid="black-debit-card-default" />
  }
}
