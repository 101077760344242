import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragmentDoc } from '../../../CategoryDrawer/CategoryDrawerContent/CategoryDrawerPieChart/CategoryDrawerPieChart.generated'

const defaultOptions = {} as const
export type CashflowReportingSubcategoriesNetAmountsQueryVariables =
  Types.Exact<{
    entityUuid: Types.Scalars['ID']['input']
    category: Types.EntityCategories
    date: Types.Scalars['AWSDate']['input']
    reportType: Types.CashFlowReportType
    accountUuid: Types.InputMaybe<Types.Scalars['String']['input']>
    timeZone: Types.InputMaybe<Types.Scalars['String']['input']>
  }>

export type CashflowReportingSubcategoriesNetAmountsQueryResponse = {
  __typename?: 'Query'
  getCashFlowSubcategoriesNetAmounts: {
    __typename?: 'CashFlowSubcategoriesNetAmounts'
    subcategoryNetAmounts: Array<{
      __typename: 'CashFlowSubcategoryNetAmount'
      category: Types.EntityCategories | null
      noOfTransaction: number
      subcategoryDetails: {
        __typename?: 'Subcategory'
        name: string
        id: string
        predefined: boolean
      } | null
      total: { __typename?: 'Money'; value: string; currency: string }
      average: { __typename?: 'Money'; value: string; currency: string }
    }> | null
  } | null
}

export const CashflowReportingSubcategoriesNetAmounts = gql`
  query CashflowReportingSubcategoriesNetAmounts(
    $entityUuid: ID!
    $category: EntityCategories!
    $date: AWSDate!
    $reportType: CashFlowReportType!
    $accountUuid: String
    $timeZone: String
  ) {
    getCashFlowSubcategoriesNetAmounts(
      entityUuid: $entityUuid
      category: $category
      date: $date
      reportType: $reportType
      accountUuid: $accountUuid
      timeZone: $timeZone
    ) {
      subcategoryNetAmounts {
        ...CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment
      }
    }
  }
  ${CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  CashflowReportingSubcategoriesNetAmountsQueryResponse,
  CashflowReportingSubcategoriesNetAmountsQueryVariables
>

/**
 * __useCashflowReportingSubcategoriesNetAmountsQuery__
 *
 * To run a query within a React component, call `useCashflowReportingSubcategoriesNetAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashflowReportingSubcategoriesNetAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashflowReportingSubcategoriesNetAmountsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      category: // value for 'category'
 *      date: // value for 'date'
 *      reportType: // value for 'reportType'
 *      accountUuid: // value for 'accountUuid'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useCashflowReportingSubcategoriesNetAmountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CashflowReportingSubcategoriesNetAmountsQueryResponse,
    CashflowReportingSubcategoriesNetAmountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CashflowReportingSubcategoriesNetAmountsQueryResponse,
    CashflowReportingSubcategoriesNetAmountsQueryVariables
  >(CashflowReportingSubcategoriesNetAmounts, options)
}
export function useCashflowReportingSubcategoriesNetAmountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CashflowReportingSubcategoriesNetAmountsQueryResponse,
    CashflowReportingSubcategoriesNetAmountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CashflowReportingSubcategoriesNetAmountsQueryResponse,
    CashflowReportingSubcategoriesNetAmountsQueryVariables
  >(CashflowReportingSubcategoriesNetAmounts, options)
}
export type CashflowReportingSubcategoriesNetAmountsQueryHookResult =
  ReturnType<typeof useCashflowReportingSubcategoriesNetAmountsQuery>
export type CashflowReportingSubcategoriesNetAmountsLazyQueryHookResult =
  ReturnType<typeof useCashflowReportingSubcategoriesNetAmountsLazyQuery>
export type CashflowReportingSubcategoriesNetAmountsQueryResult =
  Apollo.QueryResult<
    CashflowReportingSubcategoriesNetAmountsQueryResponse,
    CashflowReportingSubcategoriesNetAmountsQueryVariables
  >
