import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { Flex, showErrorToast } from '@npco/zeller-design-system'
import { useTransactionsContext } from 'features/Transactions/components/TransactionsContext/TransactionsContext'

import dayjs from 'utils/dayjs'
import { ButtonExportTransactions } from 'components/Buttons/ButtonExportTransactions/ButtonExportTransactions'
import { DateRangeValue } from 'components/Filters/PillFilters/DateRange.types'
import { GetTransactionsLocalState } from 'components/Filters/PillFilters/TransactionsFilters/getTransactionsLocalState'
import { TransactionsFilters } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters'
import { CacheLocalStateTransactions } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters.types'
import { FiltersHeaderRow } from 'components/FiltersHeaderRow'

import { CacheLocalStateEntityScopedVariable } from '../../../../../graphql/cache.types'
import { translations } from './NewTransactionsSecondaryHeaderRow.i18n'

const deps = {
  TransactionsFilters,
  useTransactionsContext,
  useTranslations,
}
export { deps as NewTransactionsSecondaryHeaderRowDeps }

const isValidDateRange = (range?: DateRangeValue | null) =>
  !!(
    range?.start &&
    range.end &&
    dayjs(range.end).diff(dayjs(range.start), 'month') < 12
  )

export const NewTransactionsSecondaryHeaderRow = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data: cacheData } = useQuery<
    CacheLocalStateTransactions<'transactions'>,
    CacheLocalStateEntityScopedVariable
  >(GetTransactionsLocalState, { variables: { entityUuid } })
  const range = cacheData?.local.transactions.filters.dates

  const { openStatementModal, splitPaymentId } = deps.useTransactionsContext()
  const isFiltersVisible = !!cacheData?.local.transactions.isFiltersVisible

  const t = deps.useTranslations(translations)

  const handleOnExportTransactions = useCallback(() => {
    if (!isValidDateRange(range)) {
      showErrorToast(t('transactionsWarning'))
    } else {
      openStatementModal()
    }
  }, [openStatementModal, range, t])

  if (splitPaymentId) {
    return null
  }

  return (
    <FiltersHeaderRow isOpen={isFiltersVisible}>
      <Flex flexDirection="column" width="100%" gap="16px">
        <Flex gap="24px" width="100%" flexWrap="wrap">
          <deps.TransactionsFilters cacheKey="transactions" />
          <ButtonExportTransactions
            label={t('exportTransactions')}
            onClick={handleOnExportTransactions}
          />
        </Flex>
      </Flex>
    </FiltersHeaderRow>
  )
}
