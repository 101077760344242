import { gql, type TypedDocumentNode } from '@apollo/client'

import { UseCardProgressDebitCardV2FragmentDoc } from '../../../../../../hooks/useCardProgress/useCardProgress.generated'

export type CardSpendProgressCellContentDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    availableAmount: { __typename?: 'Money'; value: string }
    amountLimit: { __typename?: 'Money'; value: string }
  } | null
}

export const CardSpendProgressCellContentDebitCardV2FragmentDoc = gql`
  fragment CardSpendProgressCellContentDebitCardV2Fragment on DebitCardV2 {
    ...UseCardProgressDebitCardV2Fragment
  }
  ${UseCardProgressDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardSpendProgressCellContentDebitCardV2Fragment,
  undefined
>
