import { useTranslations } from '@npco/utils-translations'

import { cardActivateButtonTranslations } from './CardActivateButton.i18n'
import * as styled from './CardActivateButton.styled'
import { useActivateCardButton } from './hooks/useActivateCardButton'

export const CardActivateButton = () => {
  const { goToActivateCard } = useActivateCardButton()
  const t = useTranslations(cardActivateButtonTranslations)

  return (
    <styled.Container>
      <styled.Action
        isInline
        dataTestId="active-button"
        onClick={(e) => {
          e.stopPropagation()
          goToActivateCard()
        }}
      >
        {t('activateButtonLabel')}
      </styled.Action>
    </styled.Container>
  )
}
