import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  RadioGroupFormik,
  RadioListCard,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { CardSurcharge, SettingsPayments } from 'types/settings'

import { getMaxCardSurchargeText } from '../../SitePayments.utils'
import { SurchargeInput } from './SurchargeInput.fields'
import { translations } from './VirtualTerminal.i18n'
import * as styled from './VirtualTerminal.styled'

export interface SurchargeOptionsProps {
  feePercent: number
  feeFixed: number
}

export const SurchargeOptions = ({
  feeFixed,
  feePercent,
}: SurchargeOptionsProps) => {
  const { values } = useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)

  return (
    <Flex flexDirection="column" gap="24px">
      <RadioGroupFormik name="vtSurchargeType" isVerticalDisplay gap="8px">
        <RadioListCard
          id="surcharge-type-total-fees"
          title={t('totalFees')}
          value={CardSurcharge.TotalFees}
        />
        <RadioListCard
          id="surcharge-type-percentage"
          title={t('percentage')}
          value={CardSurcharge.Percentage}
        />
      </RadioGroupFormik>

      {values.vtSurchargeType === CardSurcharge.Percentage && (
        <>
          <styled.Description>
            {t('surchargeDescription', {
              maxFeePercentText: getMaxCardSurchargeText(feePercent),
            })}
            &nbsp;
            {Boolean(feeFixed) && t('feeFixedText', { feeFixed })}
          </styled.Description>

          <div>
            <SurchargeInput feePercent={feePercent} />
          </div>
        </>
      )}
    </Flex>
  )
}
