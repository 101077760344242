import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Assigned Cards',
  managerInfoText:
    'Managers need to log in and complete ID Verification before they can be assigned to a Zeller Debit or Corporate Card.',
  adminDescription:
    'Admin role has access to all cards created for the organisation. Cards can also be assigned to this admin while creating a card.',
  managerNoCardsDescription:
    'This manager is not assigned to any cards. You can assign them while creating a card.',
  managerDescription: 'Managers must be assigned to cards to have access.',
})
