import { useMemo } from 'react'
import {
  BREAKPOINT,
  COLOR,
  Flex,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'

import {
  BAR_SIZE,
  NAV_PADDING,
  NAV_PADDING_MOBILE,
} from './AccountBalanceChart.constants'
import { DataPoint } from './AccountBalanceChart.types'
import {
  formatDollarValueTicks,
  getDefaultYAxisValueTicks,
  getExpenseBarFillColor,
  getIncomeBarFillColor,
  getYAxisValueTicks,
} from './AccountBalanceChart.utils'
import {
  xAxisLoadedTickFunction,
  xAxisLoadingTickFunction,
  yAxisLoadingTickFunction,
} from './AxisTickFunctions'
import { ChartBarActiveShape, ChartBarShape } from './ChartBarShape'
import { ChartInlineSpinner } from './ChartInlineSpinner'
import { ChartTooltip } from './ChartTooltip/ChartTooltip'
import { HighlightBackgroundShape } from './HighlightBackgroundShape'

type AccountBalanceChartProps = {
  isLoading: boolean
  isFetchingMore?: boolean
  data: DataPoint[]
  selectedBar?: string
  handleChartBarClick?: (state: CategoricalChartState) => void
  handleSelectPrevious?: () => void
  handleSelectNext?: () => void
}

export const AccountBalanceChart = ({
  isLoading,
  isFetchingMore = false,
  data,
  selectedBar,
  handleChartBarClick,
  handleSelectPrevious,
  handleSelectNext,
}: AccountBalanceChartProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const hasNoResults = data.every(({ value }) => !value)
  const navPadding = isMobile ? NAV_PADDING_MOBILE : NAV_PADDING
  const isBarClickable = Boolean(handleChartBarClick)

  const yAxisValueTicks = useMemo(() => {
    if (isLoading) {
      return getDefaultYAxisValueTicks(1)
    }
    if (hasNoResults) {
      return getDefaultYAxisValueTicks(500000)
    }
    return getYAxisValueTicks(data)
  }, [data, hasNoResults, isLoading])

  return (
    <Flex
      position="relative"
      style={isFetchingMore ? { pointerEvents: 'none' } : undefined}
    >
      {isFetchingMore && <ChartInlineSpinner />}
      <ResponsiveContainer height={isMobile ? 200 : 240}>
        <BarChart
          margin={{ top: 10, bottom: 14 }}
          data={data}
          onClick={handleChartBarClick}
        >
          {!isMobile && (
            <CartesianGrid vertical={false} stroke={COLOR.GREY_60} />
          )}
          {!isLoading &&
            data.map(({ name, income, expense }) => (
              <ReferenceArea
                key={name}
                x1={name}
                x2={name}
                radius={8}
                fill={selectedBar === name ? COLOR.GREY_30 : COLOR.WHITE}
                opacity={selectedBar === name ? 1 : 0}
                aria-label={
                  selectedBar === name
                    ? `selected-bar-${name}`
                    : `bar-reference-${name}`
                }
                style={{
                  cursor: income === 0 && expense === 0 ? 'default' : 'pointer',
                }}
                shape={<HighlightBackgroundShape />}
              />
            ))}

          {!isLoading && (
            <Tooltip
              isAnimationActive={false}
              wrapperStyle={{
                outline: 'none',
              }}
              position={{ y: -60 }}
              offset={-30}
              content={<ChartTooltip />}
              cursor={
                <HighlightBackgroundShape
                  fill={COLOR.GREY_30}
                  radius={8}
                  opacity={1}
                  style={!isBarClickable ? { cursor: 'default' } : undefined}
                />
              }
            />
          )}
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            height={36}
            padding={{ left: navPadding, right: navPadding }}
            interval={0}
            tick={
              isLoading || isFetchingMore
                ? xAxisLoadingTickFunction
                : xAxisLoadedTickFunction(
                    data,
                    isMobile,
                    handleSelectPrevious,
                    handleSelectNext
                  )
            }
          />
          {!isMobile && (
            <YAxis
              type="number"
              dataKey="value"
              interval="preserveStartEnd"
              tickLine={false}
              axisLine={false}
              tickSize={12}
              tickCount={5}
              tick={
                isLoading || isFetchingMore
                  ? yAxisLoadingTickFunction
                  : { fill: COLOR.BLACK_900, fontSize: 12 }
              }
              ticks={yAxisValueTicks}
              tickFormatter={formatDollarValueTicks}
            />
          )}
          {!isFetchingMore && (
            <Bar
              dataKey="income"
              barSize={BAR_SIZE}
              fill={COLOR.BLUE_1000}
              radius={[4, 4, 0, 0]}
              activeBar={isLoading ? undefined : ChartBarActiveShape}
              shape={isLoading ? undefined : ChartBarShape}
            >
              {data.map(({ name, income }) => {
                return (
                  <Cell
                    key={name}
                    cursor={
                      isLoading || !isBarClickable ? 'default' : 'pointer'
                    }
                    fill={getIncomeBarFillColor({
                      isLoading,
                      name,
                      selectedBar,
                      value: income,
                    })}
                    aria-label={`income-bar-${name}`}
                  />
                )
              })}
            </Bar>
          )}
          {!isFetchingMore && (
            <Bar
              dataKey="expense"
              barSize={BAR_SIZE}
              fill={COLOR.BLUE_400}
              radius={[4, 4, 0, 0]}
              activeBar={isLoading ? undefined : ChartBarActiveShape}
              shape={isLoading ? undefined : ChartBarShape}
            >
              {data.map(({ name, expense }) => {
                return (
                  <Cell
                    key={name}
                    cursor={
                      isLoading || !isBarClickable ? 'default' : 'pointer'
                    }
                    fill={getExpenseBarFillColor({
                      isLoading,
                      name,
                      selectedBar,
                      value: expense,
                    })}
                    aria-label={`expense-bar-${name}`}
                  />
                )
              })}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  )
}
