import { rvSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import dayjs from 'utils/dayjs'
import { DatesRange, StatementPeriod } from 'types/dates'
import { shared } from 'translations'

export const ALL_SITES = 'all sites'

export const quarterDisplayDate = (quarterNumber: number) => {
  const currentQuarter = dayjs().quarter()
  const year =
    currentQuarter === 1 && quarterNumber === 4
      ? dayjs().subtract(1, 'year').format('YYYY')
      : dayjs().format('YYYY')

  switch (quarterNumber) {
    case 1:
      return `${shared.months[1]} - ${shared.months[3]} ${year}`
    case 2:
      return `${shared.months[4]} - ${shared.months[6]} ${year}`
    case 3:
      return `${shared.months[7]} - ${shared.months[9]} ${year}`
    case 4:
      return `${shared.months[10]} - ${shared.months[12]} ${year}`
    default:
      return ''
  }
}

export const getStatementDate = (
  statementPeriod: StatementPeriod
): DatesRange => {
  switch (statementPeriod) {
    case StatementPeriod.CurrentMonth:
      return {
        from: dayjs().startOf('month').toDate(),
        to: dayjs().endOf('month').toDate(),
      }
    case StatementPeriod.PreviousMonth:
      return {
        from: dayjs().startOf('month').subtract(1, 'month').toDate(),
        to: dayjs().endOf('month').subtract(1, 'month').toDate(),
      }
    case StatementPeriod.CurrentQuarter:
      return {
        from: dayjs().startOf('quarter').toDate(),
        to: dayjs().endOf('quarter').toDate(),
      }
    case StatementPeriod.PreviousQuarter:
      return {
        from: dayjs().startOf('quarter').subtract(1, 'quarter').toDate(),
        to: dayjs().endOf('quarter').subtract(1, 'quarter').toDate(),
      }
    case StatementPeriod.CustomRange:
    default:
      return rvSelectedDates()
  }
}
