import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import * as Types from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { PieChart } from '../../../PieChart/PieChart'
import { PieChartCenterContent } from '../../../PieChart/PieChartCenterContent/PieChartCenterContent'
import { useGetCategoryName } from '../../../TransactionsSummary/hooks/useGetCategoryName'
import { categoryDrawerContentTranslations } from '../CategoryDrawerContent.i18n'
import { HoveredSubcategoryNetAmount } from '../CategoryDrawerContent.type'
import { useGetPieChartData } from '../utils/useGetPieChartData'
import { useGetTotal } from '../utils/useGetTotal'
import { CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment } from './CategoryDrawerPieChart.generated'

interface CategoryDrawerPieChartProps {
  subCategoriesNetAmounts: CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment[]
  size?: 'small' | 'large'
  isLoading?: boolean
  transactionDirection: 'income' | 'expense'
  hoveredSubcategoryNetAmount: HoveredSubcategoryNetAmount
  selectedCategory: Types.EntityCategories
}

export const CategoryDrawerPieChart = ({
  subCategoriesNetAmounts,
  size = 'large',
  isLoading = false,
  transactionDirection,
  hoveredSubcategoryNetAmount,
  selectedCategory,
}: CategoryDrawerPieChartProps) => {
  const t = useTranslations(categoryDrawerContentTranslations)

  const subCategoriesNetAmountsTotal = useGetTotal(subCategoriesNetAmounts)
  const { subcategoryNetAmount, pieChartData } = useGetPieChartData(
    subCategoriesNetAmounts,
    hoveredSubcategoryNetAmount
  )

  const getCategoryName = useGetCategoryName()
  const pieChartCenterText = useMemo(() => {
    if (subcategoryNetAmount === undefined) {
      return getCategoryName(selectedCategory)
    }

    return subcategoryNetAmount?.subcategoryDetails?.name ?? t('uncategorised')
  }, [subcategoryNetAmount, t, getCategoryName, selectedCategory])

  const pieChartTotal = useMemo(() => {
    const value = subcategoryNetAmount?.total.value
      ? parseInt(subcategoryNetAmount.total.value, 10)
      : subCategoriesNetAmountsTotal

    return transactionDirection === 'expense' ? -value : value
  }, [
    subCategoriesNetAmountsTotal,
    transactionDirection,
    subcategoryNetAmount?.total.value,
  ])

  if (isLoading) return <PieChart.Loading size={size} />

  return (
    <PieChart
      size={size}
      data={pieChartData}
      center={
        <PieChartCenterContent
          size={size}
          title={pieChartCenterText}
          total={currencyFormatter(pieChartTotal)}
        />
      }
    />
  )
}

CategoryDrawerPieChart.fragments = {
  CashFlowSubcategoryNetAmount: gql`
    fragment CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      __typename
      subcategoryDetails {
        name
      }
      total {
        value
      }

      ...useGetTotalCashFlowSubcategoryNetAmountFragment
      ...useGetPieChartDataCashFlowSubcategoryNetAmountFragment
    }

    ${useGetTotal.fragment.CashFlowSubcategoryNetAmount}
    ${useGetPieChartData.fragment.CashFlowSubcategoryNetAmount}
  `,
}
