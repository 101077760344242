import { useMemo, useState } from 'react'
import { SelectSearchInput } from 'design-system/Components/Select'

import { ReactComponent as TagIcon } from 'assets/svg/tag.svg'
import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { page } from 'translations'

import { MultiSelectFilter } from '../MultiSelectFilter/MultiSelectFilter'
import { mapTagToSelectItem, TagListItem } from './AccountFilterTags.utils'
import { NoTagsPlaceholder } from './NoTagsPlaceholder/NoTagsPlaceholder'

interface Props {
  availableTags: string[]
}

export const AccountFilterTags = ({ availableTags }: Props) => {
  const [searchInput, setSearchInput] = useState('')
  const { tags, setTags, resetTags } = useAccountFilters()
  const allTagItems = useMemo(
    () => availableTags.map(mapTagToSelectItem),
    [availableTags]
  )
  const filteredTagItems = useMemo(
    () =>
      allTagItems.filter(({ value }) =>
        value.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
      ),
    [allTagItems, searchInput]
  )

  const selectedItems = allTagItems.filter(({ value }) => tags.includes(value))

  const onClose = () => {
    setSearchInput('')
  }

  const onChange = (newElements: TagListItem[]) => {
    setTags(newElements.map(({ value }) => value))
  }

  const areAllItemsSelected = availableTags.length === tags.length

  return (
    <MultiSelectFilter
      shouldAutoFocusControl
      listItems={filteredTagItems}
      selectedItems={selectedItems}
      values={tags}
      onChange={onChange}
      onClose={onClose}
      onReset={resetTags}
      filterName={page.accounts.transactionsList.filters.tags}
      maxHeight="19rem"
      popperWidth="22.5rem"
      icon={<TagIcon />}
      areAllItemsSelected={areAllItemsSelected}
      renderTopSection={() =>
        availableTags.length > 0 && (
          <SelectSearchInput
            autoFocus
            value={searchInput}
            setValue={setSearchInput}
            aria-label={translate('shared.typeToSearch')}
            placeholder={translate('shared.typeToSearch')}
          />
        )
      }
      renderNoItemsPlaceholder={() => (
        <NoTagsPlaceholder searchInput={searchInput} />
      )}
      shouldShowSelectedCountOnAllSelected
    />
  )
}
