import { CustomerRole, DebitCardStatus } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { useToggleHeaderButtonsVisibility } from '../../../components/CardsListLayout/context/hooks/useToggleHeaderButtonsVisibility'
import { GetCorporateCardsListQueryResponse } from './graphql/getCorporateCardsList.generated'

type CorporateCard = NonNullable<
  GetCorporateCardsListQueryResponse['getDebitCardsV2']['cards'][number]
>

export const useToggleHeaderButtons = ({
  cards,
}: {
  cards: CorporateCard[]
}) => {
  const { userData } = useLoggedInUser()

  const isAdmin = userData?.role === CustomerRole.ADMIN
  const hasActiveCards = cards.some(
    (card) => card.status === DebitCardStatus.ACTIVE
  )

  const hasOwnedPendingCards = cards.some(
    (card) =>
      card.customer?.id === userData?.id &&
      card.status === DebitCardStatus.INACTIVE
  )

  useToggleHeaderButtonsVisibility({
    settings: isAdmin && (hasActiveCards ?? hasOwnedPendingCards),
    addCard: isAdmin && (hasActiveCards ?? hasOwnedPendingCards),
    activateCard: !isAdmin && hasActiveCards,
  })
}
