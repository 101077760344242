import { BREAKPOINT, ButtonBasic, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CounterButton = styled(ButtonBasic)`
  padding: 0 8px;
  line-height: 1.5;
  width: 36px;
  border: none;
  border-radius: 0;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    line-height: 0;
    padding: 16px;
    height: 56px;
    width: 52px;
  }
`
export const CounterContainer = styled(Flex)`
  border: none;
  border-radius: 7px;
  overflow: hidden;
`

export const CounterInput = styled.input`
  width: 32px;
  min-height: 54px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 16px;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 48px;
  }
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
