import { Box } from '@npco/zeller-design-system'

import { DrawerItem } from 'components/DrawerItem'
import { ImagesSkeleton } from 'components/Images/ImagesSkeleton'

import { TagsSkeleton } from '../TransactionDetailsView/Tags/TagsSkeleton'
import { StyledDetailsListItemsDivider } from '../TransactionDetailsView/TransactionDetailsInfo/TransactionDetailsInfo.styled'
import { TransactionNotesSkeleton } from '../TransactionDetailsView/TransactionNotes/TransactionNotesSkeleton'

export const TransactionDetailsSkeleton = () => {
  return (
    <Box pb="32px" data-testid="transaction-details-skeleton">
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <DrawerItem.Skeleton />
      <StyledDetailsListItemsDivider />
      <Box mt="24px">
        <DrawerItem.Skeleton />
        <DrawerItem.Skeleton />
        <DrawerItem.Skeleton />
        <DrawerItem.Skeleton />
        <DrawerItem.Skeleton />
      </Box>
      <Box mt="24px">
        <DrawerItem.Skeleton />
        <DrawerItem.Skeleton />
      </Box>
      <Box mt="24px">
        <ImagesSkeleton />
      </Box>
      <Box mt="40px">
        <TransactionNotesSkeleton />
      </Box>
      <Box mt="40px">
        <TagsSkeleton />
      </Box>
    </Box>
  )
}
