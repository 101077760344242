import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type UpdateEntityMutationVariables = Types.Exact<{
  entity: Types.EntityInput
}>

export type UpdateEntityMutationResponse = {
  __typename?: 'Mutation'
  updateEntity: boolean
}

export const UpdateEntity = gql`
  mutation UpdateEntity($entity: EntityInput!) {
    updateEntity(entity: $entity)
  }
` as unknown as TypedDocumentNode<
  UpdateEntityMutationResponse,
  UpdateEntityMutationVariables
>
export type UpdateEntityMutationFn = Apollo.MutationFunction<
  UpdateEntityMutationResponse,
  UpdateEntityMutationVariables
>

/**
 * __useUpdateEntityMutation__
 *
 * To run a mutation, you first call `useUpdateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityMutation, { data, loading, error }] = useUpdateEntityMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useUpdateEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEntityMutationResponse,
    UpdateEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateEntityMutationResponse,
    UpdateEntityMutationVariables
  >(UpdateEntity, options)
}
export type UpdateEntityMutationHookResult = ReturnType<
  typeof useUpdateEntityMutation
>
export type UpdateEntityMutationResult =
  Apollo.MutationResult<UpdateEntityMutationResponse>
export type UpdateEntityMutationOptions = Apollo.BaseMutationOptions<
  UpdateEntityMutationResponse,
  UpdateEntityMutationVariables
>
