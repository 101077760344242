import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type contactFieldsFragment = {
  __typename?: 'Contact'
  id: string
  businessName: string | null
  isSelf: boolean | null
  phone: string | null
  icon: {
    __typename?: 'Icon'
    colour: string | null
    image: string | null
    letter: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  paymentInstruments: Array<{
    __typename?: 'PaymentInstrument'
    id: string
    details:
      | { __typename: 'BankAccountDetails' }
      | { __typename: 'BpayDetails' }
      | { __typename: 'NppDetails' }
      | {
          __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
          nickname: string | null
          billerName: string
          billerCode: string
        }
      | {
          __typename: 'PaymentInstrumentBpayStaticCrnDetails'
          nickname: string | null
          billerName: string
          billerCode: string
          crn: string
        }
  }> | null
  email: { __typename?: 'ContactEmail'; email: string } | null
  phoneV2: { __typename?: 'ContactPhone'; phone: string } | null
}

export const contactFieldsDoc = gql`
  fragment contactFields on Contact {
    id
    businessName
    icon {
      colour
      image
      letter
      images {
        size
        url
      }
    }
    isSelf
    paymentInstruments {
      id
      details {
        __typename
        ... on PaymentInstrumentBpayStaticCrnDetails {
          nickname
          billerName
          billerCode
          crn
        }
        ... on PaymentInstrumentBpayDynamicCrnDetails {
          nickname
          billerName
          billerCode
        }
      }
    }
    email {
      email
    }
    phone
    phoneV2 {
      phone
    }
  }
` as unknown as TypedDocumentNode<contactFieldsFragment, undefined>
