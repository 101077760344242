import { createTranslations } from '@npco/utils-translations'

export const blockedActionModalContentTranslations = createTranslations({
  titleContactZellerSupport: 'Contact Zeller Support',
  description:
    'Your ability to {blockedActionCopy} has been temporarily disabled. Please contact Zeller Support on {supportPhone} for more information.',
  blockedActionCopyCreateAccount: 'create new Zeller Transaction Accounts',
  blockedActionCopyCreateCard: 'create new Zeller Debit Cards',
  blockedActionCopyTransferOut: 'transfer funds from your Zeller Account',
})
