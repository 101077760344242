import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { CorporateCardSettingsWalkthroughState } from '../../CorporateCardSettingsWalkthrough.types'

export const useGoToCorporateCardSettingsWalkthroughState = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToCorporateCardSettingsWalkthroughState = useCallback(
    (state: CorporateCardSettingsWalkthroughState | undefined) => {
      navigate(location.pathname, {
        state: {
          CorporateCardSettingsWalkthroughModal: state,
        },
      })
    },
    [navigate, location.pathname]
  )

  return { goToCorporateCardSettingsWalkthroughState }
}
