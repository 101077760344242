import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { ACCORDION_FORM_STATE } from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceFormFields,
  InvoiceFormFieldsWithTotalErrors,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { FormikErrors, FormikTouched, useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { useInvoiceItemsCalculations } from './useInvoiceItemsCalculations'

const isAnyItemErrored = (
  errors: FormikErrors<InvoiceFormFieldsWithTotalErrors>
) => Boolean(errors?.items?.length)

const isAnyItemTouched = (touched: FormikTouched<InvoiceFormFields>) =>
  Boolean(
    touched?.items?.some(
      (item) => item?.description || item?.name || item?.price
    )
  )

const isAllItemsValid = (values: InvoiceFormFields) =>
  values?.items?.every(
    (item) => Boolean(item.name.trim()) && Boolean(item.price)
  )

const isInvoiceDiscountApplied = (values: InvoiceFormFields) =>
  values.discount.config === InvoiceDiscountConfig.PERCENTAGE
    ? values.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
    : values.discount.price !== INVOICE_DEFAULT_PRICE

const isAnyTotalError = (
  errors: FormikErrors<InvoiceFormFieldsWithTotalErrors>
) => Boolean(errors.itemsExceedsMaxAmount || errors.itemsExceedsMinAmount)

interface GetAccordionDetailsTextProps {
  touched: FormikTouched<InvoiceFormFields>
  total: string
  values: InvoiceFormFields
}

export const getAccordionDetailsText = ({
  touched,
  total,
  values,
}: GetAccordionDetailsTextProps) => {
  const isItemsTouched = isAnyItemTouched(touched)
  const hasInvoiceDiscount = isInvoiceDiscountApplied(values)

  if (isItemsTouched || hasInvoiceDiscount) {
    const itemsCount = values.items.length
    const itemsText = itemsCount === 1 ? 'Item' : 'Items'

    return translate('page.invoice.formSections.items.detailsItems', {
      itemsSummary: `${itemsCount} ${itemsText}`,
      itemsTotal: total,
    })
  }

  return translate('page.invoice.formSections.items.details')
}

interface GetAccordionFormStateProps {
  errors: FormikErrors<InvoiceFormFieldsWithTotalErrors>
  touched: FormikTouched<InvoiceFormFields>
  values: InvoiceFormFields
}

export const getAccordionFormState = ({
  errors,
  touched,
  values,
}: GetAccordionFormStateProps) => {
  const isDiscountApplied = isInvoiceDiscountApplied(values)
  const isItemsValid = isAllItemsValid(values)
  const isItemsTouched = isAnyItemTouched(touched)
  const isItemsError = isAnyItemErrored(errors)
  const isTotalError = isAnyTotalError(errors)

  const isFormStateComplete = isItemsValid && !isItemsError && !isTotalError

  const isFormStateAttention =
    (isItemsTouched && (isItemsError || isTotalError)) ||
    (isTotalError && isDiscountApplied)

  const isFormStatePartialComplete = isItemsTouched || isDiscountApplied

  if (isFormStateComplete) {
    return ACCORDION_FORM_STATE.COMPLETE
  }
  if (isFormStateAttention) {
    return ACCORDION_FORM_STATE.ATTENTION
  }
  if (isFormStatePartialComplete) {
    return ACCORDION_FORM_STATE.PARTIAL_COMPLETE
  }

  return ACCORDION_FORM_STATE.EMPTY
}

export const useInvoiceItemsAccordionState = () => {
  const { errors, touched, values } = useFormikContext<InvoiceFormFields>()

  const { totalFormatted } = useInvoiceItemsCalculations({ values })

  const accordionFormState = getAccordionFormState({
    errors,
    touched,
    values,
  })

  const accordionDetailsText = getAccordionDetailsText({
    total: totalFormatted,
    touched,
    values,
  })

  return {
    accordionFormState,
    accordionDetailsText,
  }
}
