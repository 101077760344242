type GetKeyFunction<T> = (item: T) => string

export const groupByKey =
  <T>(getKey: GetKeyFunction<T>) =>
  (items: T[]) =>
    items.reduce<Record<string, T[]>>((groups, item) => {
      const key = getKey(item)
      const currentItemGroup = groups[key] ?? []

      return {
        ...groups,
        [key]: [...currentItemGroup, item],
      }
    }, {})
