import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Table, TableData, useTable } from '@npco/ui-table'
import { Row } from '@tanstack/react-table'

import { ROOT } from 'const/routes'
import { CardAccountCell } from 'components/CardsView/components/CardsTable/components/CardAccountCell/CardAccountCell'
import { CorporateCardBalanceCell } from 'components/CardsView/components/CardsTable/components/CardBalanceCell/CorporateCardBalanceCell'
import { CardNameIconProgressCell } from 'components/CardsView/components/CardsTable/components/CardNameIconCell/CardNameIconProgressCell'
import { CardOwnerCell } from 'components/CardsView/components/CardsTable/components/CardOwnerCell/CardOwnerCell'
import { CardSpendProgressCell } from 'components/CardsView/components/CardsTable/components/CardSpendProgressCell/CardSpendProgressCell'
import { CardTransactionsMissingDetailsCountCell } from 'components/CardsView/components/CardsTable/components/CardTransactionsMissingDetailsCountCell/CardTransactionsMissingDetailsCountCell'

import { CorporateCardsTableDebitCardV2Fragment } from './CorporateCardsTable.generated'
import { columnsConfig } from './TableConfig'

interface CardsTableProps {
  cards: CorporateCardsTableDebitCardV2Fragment[]
  isLoading?: boolean
  isReadonly?: boolean
}

export const CorporateCardsTable = ({
  cards,
  isLoading = false,
  isReadonly,
}: CardsTableProps) => {
  const { table } = useTable<CorporateCardsTableDebitCardV2Fragment>({
    columnsConfig,
    isLoading,
    listData: cards,
  })
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const handleRowClick = useCallback(
    (row: Row<TableData<CorporateCardsTableDebitCardV2Fragment>> | null) => {
      const cardId = row?.original.data?.id

      if (cardId) {
        navigate(ROOT.ORGS.ORG(shortEntityId).CARDS.CARD(cardId).path)
      }
    },
    [navigate, shortEntityId]
  )

  return (
    <Table<TableData<CorporateCardsTableDebitCardV2Fragment>>
      hideHeader
      onRowClick={!isReadonly ? handleRowClick : undefined}
      table={table}
    />
  )
}

CorporateCardsTable.fragments = {
  DebitCardV2: gql`
    fragment CorporateCardsTableDebitCardV2Fragment on DebitCardV2 {
      __typename
      ...CardNameIconProgressCellDebitCardV2Fragment
      ...CardOwnerCellDebitCardV2Fragment
      ...CardAccountCellDebitCardV2Fragment
      ...CardTransactionsMissingDetailsCountCellDebitCardV2Fragment
      ...CardSpendProgressCellDebitCardV2Fragment
      ...CorporateCardBalanceCellDebitCardV2Fragment
    }
    ${CardNameIconProgressCell.fragments.DebitCardV2}
    ${CardOwnerCell.fragments.DebitCardV2}
    ${CardAccountCell.fragments.DebitCardV2}
    ${CardTransactionsMissingDetailsCountCell.fragments.DebitCardV2}
    ${CardSpendProgressCell.fragments.DebitCardV2}
    ${CorporateCardBalanceCell.fragments.DebitCardV2}
  `,
}
