import { useCallback } from 'react'
import {
  rvContacts,
  rvSelectedContactToTransfer,
} from 'features/Contacts/rv-deprecated/contacts'
import { updateRvContactsOnContactUpdate } from 'features/Contacts/rv-deprecated/contacts.utils'

import { ExtendedTransferContactFieldsSchema } from 'pages/Transfer/ContactTransfer'
import {
  ContactOrBillerSchema,
  TransferFieldTypes,
} from 'pages/Transfer/Transfer.types'

import { useUpdateContact } from './useUpdateContact'

export const useUpdateContactIsSelf = () => {
  const updateContact = useUpdateContact()

  const updateRvContactInContactTransfer = useCallback(
    (contactId: string, isSelf: boolean) => {
      const selectedContact = rvSelectedContactToTransfer()

      if (selectedContact) {
        rvSelectedContactToTransfer({ ...selectedContact, isSelf })
        updateRvContactsOnContactUpdate(contactId, { isSelf })
      }
    },
    []
  )

  const updateRvContactInBpayTransfer = useCallback(
    (contactId: string, isSelf: boolean) => {
      const selectedContact = rvContacts()?.[contactId]

      if (selectedContact) {
        updateRvContactsOnContactUpdate(contactId, { isSelf })
      }
    },
    []
  )

  const updateContactInContactTransfer = useCallback(
    (values: TransferFieldTypes) => {
      const toValues = ExtendedTransferContactFieldsSchema.safeParse(values)

      if (
        !toValues.success ||
        !toValues.data.toContact?.id ||
        typeof toValues.data.isSelf !== 'boolean'
      ) {
        return
      }

      updateContact({
        id: toValues.data.toContact.id,
        isSelf: toValues.data.isSelf,
        updateRvContact: updateRvContactInContactTransfer,
      })
    },
    [updateContact, updateRvContactInContactTransfer]
  )

  const updateContactInBpayTransfer = useCallback(
    (values: TransferFieldTypes) => {
      const toValues = ContactOrBillerSchema.safeParse(values.to)

      if (
        !toValues.success ||
        toValues.data.type !== 'Contact' ||
        !toValues.data.id ||
        !('isSelf' in values) ||
        typeof values.isSelf !== 'boolean'
      ) {
        return
      }

      updateContact({
        id: toValues.data.id,
        isSelf: values.isSelf,
        updateRvContact: updateRvContactInBpayTransfer,
      })
    },
    [updateContact, updateRvContactInBpayTransfer]
  )

  return { updateContactInContactTransfer, updateContactInBpayTransfer }
}
