import { PopperWrapper } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledMultiSelectPopper = styled(PopperWrapper)`
  border-radius: 4px;
  /* The underlying PopperWrapper unnecessarily adds {pointer-events: none;}
   * based on some logic but this creates a headache with react-testing-libary
   * where we are unable to interact with a {pointer-events: none;} element even
   * if it's visible. It's too hard to figure out how to reliably waitFor it to
   * not be applied so this property overrides the {pointer-events: none;} to
   * stop the userEvent.click unit test error with no impact to normal
   * interaction. */
  pointer-events: auto;
`
