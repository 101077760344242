import { useTranslations } from '@npco/utils-translations'
import {
  BodyLarge,
  Flex,
  Heading,
  MagnifyingGlassNoResults,
} from '@npco/zeller-design-system'

import { sharedBankingTranslations } from 'translations/sharedBanking.i18n'

import { noDebitCardTransactionsTranslations } from './NoDebitCardTransactions.i18n'

export const NoDebitCardTransactions = () => {
  const tSharedBanking = useTranslations(sharedBankingTranslations)
  const t = useTranslations(noDebitCardTransactionsTranslations)
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      padding="24px"
      mt={{ _: '24px', SM: '40px' }}
      data-testid="no-initial-results"
    >
      <Flex>
        <MagnifyingGlassNoResults size="large" />
      </Flex>
      <Flex
        flexDirection="column"
        textAlign="center"
        maxWidth="552px"
        mt="32px"
      >
        <Heading.H2>{tSharedBanking('noTransactions')}</Heading.H2>
        <BodyLarge>{t('noDebitCardTransactionsDescription')}</BodyLarge>
      </Flex>
    </Flex>
  )
}
