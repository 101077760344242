import { BodySmall, H6Style } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceBalance = styled.div`
  ${H6Style}
`

export const DueAmount = styled(BodySmall)`
  margin: 0;
`
