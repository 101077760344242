import { BodySmall, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  text-align: center;
  margin: 1.5rem 0 1rem 0;
`

export const StyledDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  text-align: center;
  word-break: break-word;
  max-width: 95%;
  margin: 0 auto 1rem auto;
`
