import { removeWhiteSpaces } from 'utils/common'

export const ANTE_MERIDIEM = 'AM'
export const POST_MERIDIEM = 'PM'
export const COMPLETE_NUMERIC_TIME_LENGTH = 4
export const MINUTE_NUMERIC_TIME_LENGTH = 2
export const MERIDIEM_LENGTH = 2

const addColonToTimeString = (time: string) => {
  const timeFormatted =
    time.length < COMPLETE_NUMERIC_TIME_LENGTH ? `0${time}` : time
  const colonPosition = timeFormatted.length - MINUTE_NUMERIC_TIME_LENGTH
  return `${timeFormatted.substring(
    0,
    colonPosition
  )}:${timeFormatted.substring(colonPosition)}`
}

const formatTimeString = (time: string) => {
  if (time.length === 1) {
    return `0${time}:00`
  }
  if (time.length === 2) {
    return `${time}:00`
  }

  return addColonToTimeString(time)
}

const handleMeridian = (time: string) => {
  const numericTime = time.slice(0, time.length - MERIDIEM_LENGTH)

  if (time.includes(ANTE_MERIDIEM)) {
    return [formatTimeString(numericTime), ANTE_MERIDIEM].join('')
  }

  if (time.includes(POST_MERIDIEM)) {
    return [formatTimeString(numericTime), POST_MERIDIEM].join('')
  }

  return formatTimeString(time)
}

export const getCompleteTimeInput = (incompleteTimeString: string) => {
  const timeStringFormatted =
    removeWhiteSpaces(incompleteTimeString).toUpperCase()

  if (!timeStringFormatted.includes(':')) {
    return handleMeridian(timeStringFormatted)
  }

  return `0${timeStringFormatted}`
}
