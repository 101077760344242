import { submitOnlyDirtyFields } from '@npco/component-mp-common'
import { FormikHelpers } from 'formik'
import { isNil } from 'lodash-es'
import { reject } from 'ramda'

import { AnalyticsEventNames } from 'services/Analytics/events'
import {
  InvoiceCustomisationAnalyticsProperties,
  InvoiceGeneralSettingsProperties,
  InvoiceReminderSettingsProperties,
} from 'services/Analytics/events/invoice'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { InvoiceSettingsCustomisationFormFields } from '../SettingsCustomisation/SettingsCustomisation.types'
import { ADDRESS_FIELDS } from '../SettingsGeneral/SettingsGeneral.constants'
import { InvoiceSettingsGeneralFormFields } from '../SettingsGeneral/SettingsGeneral.types'
import { InvoiceSettingsRemindersFormFields } from '../SettingsReminders/SettingsReminders.types'

const getCustomisationFieldValueUpdated = <T>(initialValue?: T, value?: T) =>
  initialValue !== value ? 'Updated' : undefined

const getFieldValue = <T>(initialValue?: T, value?: T) => {
  if (value === initialValue || (!value && !initialValue)) {
    return undefined
  }

  if (!initialValue && value) {
    return 'Added'
  }

  if (initialValue && !value) {
    return 'Removed'
  }

  return 'Updated'
}

const getReminderDueDateValue = (initialValue?: number[], value?: number[]) => {
  if (value === undefined) {
    return undefined
  }

  const initialValueCount = initialValue?.length || 0

  if (initialValueCount < value.length) {
    return 'Added'
  }

  if (initialValueCount > value.length) {
    return 'Removed'
  }

  return value.every((val, index) => val === initialValue?.[index])
    ? undefined
    : 'Updated'
}

const getAddressFieldValue = (
  initialValues: InvoiceSettingsGeneralFormFields,
  values: InvoiceSettingsGeneralFormFields
) => {
  const fieldValues = ADDRESS_FIELDS.map((field) =>
    getFieldValue(initialValues[field], values[field])
  )

  return fieldValues.includes('Updated') ? 'Updated' : undefined
}

export const useInvoiceSettingsAnalytics = () => {
  const { trackAnalyticsEvent, logAnalyticsPage } = useAnalyticsLogger()

  const trackCustomisationSettingsPdfPreviewView = () => {
    logAnalyticsPage({
      AnalyticsEventName:
        AnalyticsEventNames.INVOICE_CUSTOMISATION_SETTINGS_PDF_PREVIEW,
    })
  }

  const trackCustomisationSettingsLogosUpdated = (
    action: 'Added' | 'Removed'
  ) => {
    trackAnalyticsEvent(
      AnalyticsEventNames.INVOICE_CUSTOMISATION_LOGOS_UPDATED,
      { Logos: action }
    )
  }

  const trackCustomisationSettingsAccentColourUpdated = (
    action: 'Added' | 'Removed'
  ) => {
    trackAnalyticsEvent(
      AnalyticsEventNames.INVOICE_CUSTOMISATION_ACCENT_COLOUR_UPDATED,
      { AccentColour: action }
    )
  }

  const trackCustomisationSettingsUpdated = (
    initialValues: InvoiceSettingsCustomisationFormFields,
    values: InvoiceSettingsCustomisationFormFields
  ) => {
    const trackEvent = () => {
      const payload: InvoiceCustomisationAnalyticsProperties = {
        Template: values.selectedTemplateType,
        TemplateAction: getCustomisationFieldValueUpdated(
          values.selectedTemplateType,
          initialValues.selectedTemplateType
        ),
        Logo: getCustomisationFieldValueUpdated(
          initialValues.selectedLogo,
          values.selectedLogo
        ),
        Colour: getCustomisationFieldValueUpdated(
          initialValues.selectedColor,
          values.selectedColor
        ),
        Messaging: getCustomisationFieldValueUpdated(
          initialValues.defaultMessage,
          values.defaultMessage
        ),
        TermsAndConditions: getCustomisationFieldValueUpdated(
          initialValues.termsAndConditions,
          values.termsAndConditions
        ),
      }

      trackAnalyticsEvent(
        AnalyticsEventNames.INVOICE_CUSTOMISATION_SETTINGS_UPDATED,
        reject(isNil)(payload)
      )
    }

    const getOnlyDirtyFields = submitOnlyDirtyFields(initialValues, trackEvent)
    getOnlyDirtyFields(
      values,
      {} as FormikHelpers<InvoiceSettingsCustomisationFormFields>
    )
  }

  const trackGeneralSettingsUpdated = (
    initialValues: InvoiceSettingsGeneralFormFields,
    values: InvoiceSettingsGeneralFormFields
  ) => {
    const trackEvent = (updatedValues: InvoiceSettingsGeneralFormFields) => {
      const payload: InvoiceGeneralSettingsProperties = {
        AddressInPDF: updatedValues.pdfIncludesAddress,
        AddressInformation: getAddressFieldValue(initialValues, updatedValues),
        BCCCopy: updatedValues.sendBccCopy,
        Discounts: updatedValues.discountsEnabled,
        // we need the current value of logo to track even if it's not dirty
        // since it depends on the current value of the logo field
        Logo: getFieldValue(initialValues.logo, values.logo),
        DefaultSendByEmail: updatedValues.defaultSendByEmail,
        DefaultSendBySMS: updatedValues.defaultSendBySMS,
      }

      trackAnalyticsEvent(
        AnalyticsEventNames.INVOICE_GENERAL_SETTINGS_UPDATED,
        reject(isNil)(payload)
      )
    }
    const getOnlyDirtyFields = submitOnlyDirtyFields(initialValues, trackEvent)
    getOnlyDirtyFields(
      values,
      {} as FormikHelpers<InvoiceSettingsGeneralFormFields>
    )
  }

  const trackRemindersSettingsUpdated = (
    initialValues: InvoiceSettingsRemindersFormFields,
    values: InvoiceSettingsRemindersFormFields
  ) => {
    const trackEvent = (updatedValues: InvoiceSettingsRemindersFormFields) => {
      const payload: InvoiceReminderSettingsProperties = {
        OnDueDate: updatedValues.reminderOnDue,
        AfterDueDate: getReminderDueDateValue(
          initialValues.remindersDaysAfterDue,
          updatedValues.remindersDaysAfterDue
        ),
        BeforeDueDate: getReminderDueDateValue(
          initialValues.remindersDaysBeforeDue,
          updatedValues.remindersDaysBeforeDue
        ),
      }

      trackAnalyticsEvent(
        AnalyticsEventNames.INVOICE_REMINDER_SETTINGS_UPDATED,
        reject(isNil)(payload)
      )
    }
    const getOnlyDirtyFields = submitOnlyDirtyFields(initialValues, trackEvent)
    getOnlyDirtyFields(
      values,
      {} as FormikHelpers<InvoiceSettingsRemindersFormFields>
    )
  }

  return {
    trackCustomisationSettingsAccentColourUpdated,
    trackCustomisationSettingsLogosUpdated,
    trackCustomisationSettingsUpdated,
    trackCustomisationSettingsPdfPreviewView,
    trackGeneralSettingsUpdated,
    trackRemindersSettingsUpdated,
  }
}
