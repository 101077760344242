import { keys } from 'ramda'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { GetConnections as GetConnectionsResponse } from 'types/gql-types/GetConnections'

import { HLIcon } from './components/HLIcon'
import { OracleIcon } from './components/OracleIcon'
import { XeroIcon } from './components/XeroIcon'
import { Connection } from './Connections.types'

type ConnectionConfig = Omit<Connection, 'id' | 'status' | 'errorType'>
type ConnectionsConfig = {
  [key: string]: ConnectionConfig
}

export const xeroPaymentServicesConfigTitle = translate(
  'page.settings.connections.xeroPaymentServices.card.title'
)

const xeroBankFeedsConfig = (shortEntityId: string): ConnectionConfig => ({
  title: translate('page.settings.connections.xeroBankFeeds.card.title'),
  subTitle: translate('page.settings.connections.xeroBankFeeds.card.subTitle'),
  routeInitalise:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.XERO_BANK_FEEDS.path,
  routeManage:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_MANAGE
      .path,
  icon: XeroIcon,
})

const xeroPaymentServicesConfig = (
  shortEntityId: string
): ConnectionConfig => ({
  title: xeroPaymentServicesConfigTitle,
  subTitle: translate(
    'page.settings.connections.xeroPaymentServices.card.subTitle'
  ),
  routeInitalise:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES
      .path,
  routeManage:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS
      .XERO_PAYMENT_SERVICES_MANAGE.path,
  icon: XeroIcon,
})

const payAtTableConfig = (shortEntityId: string): ConnectionConfig => ({
  title: translate('page.settings.connections.payAtTable.title'),
  subTitle: translate('page.settings.connections.payAtTable.description'),
  routeInitalise:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.HL_POS.MANAGE.path,
  routeManage:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.HL_POS.MANAGE.path,
  icon: HLIcon,
})

const oraclePosConfig = (shortEntityId: string): ConnectionConfig => ({
  title: translate('page.settings.connections.oraclePost.title'),
  subTitle: translate('page.settings.connections.oraclePost.subTitle'),
  routeInitalise:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.ORACLE_POS.path,
  routeManage:
    ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.ORACLE_POS.MANAGE.path,
  icon: OracleIcon,
})

export const getConnectionsConfigs = (
  shortEntityId: string
): ConnectionsConfig => ({
  xeroBankFeeds: xeroBankFeedsConfig(shortEntityId),
  xeroPaymentServices: xeroPaymentServicesConfig(shortEntityId),
  payAtTable: payAtTableConfig(shortEntityId),
  oraclePos: oraclePosConfig(shortEntityId),
})

export const buildConnections = (
  shortEntityId: string,
  payload?: GetConnectionsResponse
): Connection[] => {
  const hasConnectionConfig = (type: string) =>
    !!getConnectionsConfigs(shortEntityId)[type]

  if (!payload) {
    return []
  }

  return keys(payload).reduce<Connection[]>((result, key) => {
    if (!hasConnectionConfig(key)) {
      return result
    }

    const connection: Connection = {
      ...payload[key],
      ...getConnectionsConfigs(shortEntityId)[key],
    }
    return [...result, connection]
  }, [])
}
