import { gql, type TypedDocumentNode } from '@apollo/client'

import { ExpenseDatapointCashFlowPeriodicTotalAmountFragmentDoc } from './ExpenseDatapoint/ExpenseDatapoint.generated'
import { UseFilterByDateRangeCashFlowPeriodicTotalAmountFragmentDoc } from './hooks/useFilterByDateRange/useFilterByDateRange.generated'
import { IncomeDatapointCashFlowPeriodicTotalAmountFragmentDoc } from './IncomeDatapoint/IncomeDatapoint.generated'

export type IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    noOfIncome: number
    noOfExpense: number
    income: { __typename?: 'Money'; value: string }
    expense: { __typename?: 'Money'; value: string }
  }
  range: { __typename?: 'CashFlowReportRange'; start: any }
}

export const IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragmentDoc =
  gql`
    fragment IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...IncomeDatapointCashFlowPeriodicTotalAmountFragment
      ...ExpenseDatapointCashFlowPeriodicTotalAmountFragment
      ...UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment
    }
    ${IncomeDatapointCashFlowPeriodicTotalAmountFragmentDoc}
    ${ExpenseDatapointCashFlowPeriodicTotalAmountFragmentDoc}
    ${UseFilterByDateRangeCashFlowPeriodicTotalAmountFragmentDoc}
  ` as unknown as TypedDocumentNode<
    IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment,
    undefined
  >
