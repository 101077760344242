import { DetailField } from './DetailField'

type ListProps = {
  label: {
    label: string
    sublabel?: string
  }
  value: React.ReactNode
  abbreviationTitle?: string
  dataTestId?: string
  key: string
}

export const DetailFields = ({
  list,
  type,
}: {
  list: ListProps[]
  type: string
}) => {
  return (
    <>
      {list.map(({ key, label: { label, sublabel }, value }, idx) => (
        <DetailField
          dataTestId={`contact-${type}-field[${idx}]`}
          key={key}
          label={label}
          sublabel={sublabel}
          value={value}
        />
      ))}
    </>
  )
}
