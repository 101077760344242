import { gql, type TypedDocumentNode } from '@apollo/client'

import {
  GetContactOrMerchantLocationDataContactFragmentDoc,
  GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc,
} from '../utils/contacts.utils.generated'
import {
  MerchantSecondaryDetailsItemsContactFragmentDoc,
  MerchantSecondaryDetailsItemsMerchantDetailsFragmentDoc,
} from './Items/MerchantSecondaryDetailsItems/MerchantSecondaryDetailsItems.generated'

export type BusinessContactDetailsMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  hours: string | null
  phone: string | null
  location: string | null
  locationAccuracy: number | null
  email: string | null
  url: string | null
  abn: string | null
  address: {
    __typename?: 'MerchantAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street1: string | null
    street2: string | null
    suburb: string | null
  } | null
}

export type BusinessContactDetailsContactFragment = {
  __typename?: 'Contact'
  phone: string | null
  location: string | null
  locationAccuracy: number | null
  website: string | null
  abn: string | null
  phoneV2: { __typename?: 'ContactPhone'; phone: string } | null
  address: {
    __typename?: 'ContactAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street: string | null
    suburb: string | null
  } | null
  email: { __typename?: 'ContactEmail'; email: string } | null
}

export const BusinessContactDetailsMerchantDetailsFragmentDoc = gql`
  fragment BusinessContactDetailsMerchantDetailsFragment on MerchantDetails {
    hours
    ...GetContactOrMerchantLocationDataMerchantDetailsFragment
    phone
    ...MerchantSecondaryDetailsItemsMerchantDetailsFragment
  }
  ${GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc}
  ${MerchantSecondaryDetailsItemsMerchantDetailsFragmentDoc}
` as unknown as TypedDocumentNode<
  BusinessContactDetailsMerchantDetailsFragment,
  undefined
>
export const BusinessContactDetailsContactFragmentDoc = gql`
  fragment BusinessContactDetailsContactFragment on Contact {
    ...GetContactOrMerchantLocationDataContactFragment
    phone
    phoneV2 {
      phone
    }
    ...MerchantSecondaryDetailsItemsContactFragment
  }
  ${GetContactOrMerchantLocationDataContactFragmentDoc}
  ${MerchantSecondaryDetailsItemsContactFragmentDoc}
`
