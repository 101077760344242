import { useCorporateCardSettingsWalkthroughState } from 'features/CorporateCardSettingsWalkthrough/corporate-card-settings-walkthrough-routing/hooks/useCorporateCardSettingsWalkthroughState/useCorporateCardSettingsWalkthroughState'

import { CorporateCardSettingsWalkthroughModal } from './components/CorporateCardSettingsWalkthroughModal/CorporateCardSettingsWalkthroughModal'

export const CorporateCardSettingsWalkthrough = () => {
  const { state } = useCorporateCardSettingsWalkthroughState()

  if (state === null) {
    return null
  }

  return <CorporateCardSettingsWalkthroughModal />
}
