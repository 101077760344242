import { useCallback, useMemo, useState } from 'react'

import { translate } from 'utils/translations'
import { EditCategoriesModal } from 'components/EditCategoriesModal/EditCategoriesModal'

import {
  EditState,
  EntityCategoryValues,
  NewSubcategory,
} from './EditZellerCategories.types'
import { ZellerCategoryDropdownFields } from './ZellerCategoryDropdownFields'

interface Props<V extends EntityCategoryValues> {
  isOpen: boolean
  initialValues: V
  onSave: (values: V) => void
  onCancel: () => void
  onEditSubcategory?: (values: V) => void
  onDeleteSubcategory?: (values: V & NewSubcategory) => void
  isSavingCategories?: boolean
  children?: React.ReactNode
}

export const EditZellerCategories = <V extends EntityCategoryValues>({
  isOpen,
  initialValues,
  onSave,
  onCancel,
  onEditSubcategory,
  onDeleteSubcategory,
  isSavingCategories = false,

  children,
}: Props<V>) => {
  const [isEditingSubcategory, setIsEditingSubcategory] = useState(false)

  const onEditStateChange = useCallback((newEditState: EditState) => {
    setIsEditingSubcategory(newEditState !== EditState.Select)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialCategory = useMemo(() => initialValues.category, [])

  const initialSubcategory = useMemo(
    () => initialValues.subcategory || null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <EditCategoriesModal
      title={translate('component.editZellerCategories.title')}
      onSave={onSave}
      onCancel={onCancel}
      initialValues={initialValues}
      isOpen={isOpen}
      isSavingCategories={isSavingCategories}
      isSaveDisabled={isEditingSubcategory}
    >
      <ZellerCategoryDropdownFields
        initialCategory={initialCategory}
        initialSubcategory={initialSubcategory}
        onEditSubcategoryStateChange={onEditStateChange}
        onEditSubcategory={onEditSubcategory}
        onDeleteSubcategory={onDeleteSubcategory}
      />
      {children}
    </EditCategoriesModal>
  )
}
