import { useCallback, useEffect, useState } from 'react'
import { ApolloError, useApolloClient } from '@apollo/client'
import { EntityCategories } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetSubcategories } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/subcategories'
import { sortBy } from 'ramda'

import {
  GetSubcategories as GetSubcategoriesType,
  GetSubcategories_getEntitySubcategories as Subcategory,
  GetSubcategoriesVariables,
} from 'types/gql-types/GetSubcategories'

export interface CategoryWithSubcategories {
  category: EntityCategories
  subcategories: Subcategory[]
}

type CategorySubcategoriesMap = {
  [key in EntityCategories]?: CategoryWithSubcategories
}

interface UseGetCategoriesWithSubcategoriesProps {
  skip?: boolean
}

export const useGetCategoriesWithSubcategories = ({
  skip = false,
}: UseGetCategoriesWithSubcategoriesProps = {}) => {
  const client = useApolloClient()
  const entityUuid = useSelectedEntityUuid()

  const [categoryToSubcategoriesMap, setCategoryToSubcategoriesMap] =
    useState<CategorySubcategoriesMap>({})
  const [error, setError] = useState<ApolloError | null>(null)

  const [subcategories, setSubcategories] = useState<
    CategoryWithSubcategories[]
  >([])

  const fetchSubcategories = useCallback(async () => {
    const categories = Object.values(EntityCategories)

    categories.forEach((category) =>
      client
        .watchQuery<GetSubcategoriesType, GetSubcategoriesVariables>({
          query: GetSubcategories,
          variables: {
            category,
            entityUuid,
          },
        })
        .subscribe(
          (result) => {
            setCategoryToSubcategoriesMap((prev) => ({
              ...prev,
              [category]: {
                category,
                subcategories: result.data.getEntitySubcategories.filter(
                  (subcategory): subcategory is NonNullable<Subcategory> =>
                    subcategory !== null
                ),
              },
            }))
          },
          (subscriptionError) => {
            setError(subscriptionError)
          }
        )
    )
  }, [client, entityUuid])

  useEffect(() => {
    setSubcategories(
      sortBy<CategoryWithSubcategories>((element) => element.category)(
        Object.values(categoryToSubcategoriesMap)
      )
    )
  }, [categoryToSubcategoriesMap])

  useEffect(() => {
    if (error) {
      showApiErrorToast(error)
    }
  }, [error])

  useEffect(() => {
    if (!skip) {
      fetchSubcategories()
    }
  }, [fetchSubcategories, skip])

  return subcategories
}
