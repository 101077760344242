import { Spinner } from '@npco/zeller-design-system'

import { LoaderWithText } from 'components/LoaderWithText'
import { LogoCorner } from 'components/LogoCorner'
import { StyledOnboardingPageContainer } from 'components/OnboardingPageContainer'

interface Props {
  loaderText?: React.ReactNode
}

export const LoaderOnboarding = ({ loaderText }: Props) => {
  return (
    <StyledOnboardingPageContainer isLoaderContainer isVerticallyCentered>
      <LogoCorner />
      {loaderText ? (
        <LoaderWithText loaderText={loaderText} />
      ) : (
        <Spinner dataTestId="loader" />
      )}
    </StyledOnboardingPageContainer>
  )
}
