import { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import {
  rvCurrentUserSites,
  rvSiteDetails,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'

interface Arguments {
  initialRole: CustomerRole
  entitySites: SiteData[]
  isAdmin: boolean
  isEditForm: boolean
}
export const useAssignSites = ({
  initialRole,
  isAdmin,
  isEditForm,
  entitySites,
}: Arguments) => {
  const [userSites, setUserSites] = useState<SiteData[]>([])
  const sites = useReactiveVar(rvCurrentUserSites)
  const siteDetails: SiteData | null = useReactiveVar(rvSiteDetails)

  useEffect(() => {
    if (isAdmin) {
      setUserSites(entitySites)
    } else if (
      isEditForm &&
      sites &&
      sites.length > 0 &&
      initialRole !== CustomerRole.ADMIN
    ) {
      setUserSites(sites)
    } else if (
      initialRole === CustomerRole.ADMIN &&
      !isAdmin &&
      siteDetails?.id
    ) {
      setUserSites(sites.length > 0 ? sites : [siteDetails])
    } else if (initialRole === CustomerRole.ADMIN && !isAdmin) {
      setUserSites(sites)
    } else if (siteDetails?.id) {
      setUserSites([siteDetails])
    } else {
      setUserSites([])
    }

    return () => setUserSites([])
  }, [initialRole, sites, isAdmin, isEditForm, entitySites, siteDetails])

  return {
    userSites,
    setUserSites,
  }
}
