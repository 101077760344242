import { CustomValidator } from 'types/common'
import { errorMessages } from 'translations'

const getIsPosRegisterNameValid = (value: string) => /^.{1,40}$/.test(value)

export const validatePosRegisterName: CustomValidator<string> = (value) => {
  const valueTrimmed = value?.trim()

  if (valueTrimmed && !getIsPosRegisterNameValid(valueTrimmed)) {
    return errorMessages.posRegisterNameMaxLength
  }

  return undefined
}
