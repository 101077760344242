import React from 'react'
import { SelectStyle } from '@npco/zeller-design-system'
import { Placement } from '@popperjs/core'

export enum SelectSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

export interface SelectItemBasic {
  label: string
  value: string | number
  subLabel1?: React.ReactNode
  subLabel2?: React.ReactNode
  icon?: React.ReactNode
  image?: React.ReactNode
  avatar?: {
    letter: string
    bgColor?: string
    bgActiveColor?: string
    color?: string
  }
  isDisabled?: boolean
  onEdit?: () => void
  color?: string
}

export type SelectSelectedItem<ItemType> = ItemType | null | undefined

export interface SelectItemRenderProps<ItemType> {
  ['aria-selected']?: boolean
  id: string
  key: string
  onClick: React.MouseEventHandler<HTMLLIElement>
  ref?: React.RefObject<HTMLLIElement>
  role: 'option'
  item: ItemType
  hasSelectedIndicator?: boolean
}

export interface SelectTriggerBasicProps {
  ['aria-expanded']?: boolean
  ['aria-haspopup']?: 'listbox'
  ['aria-labelledby']?: string
  ['data-testid']?: string
  id: string
  onClick: () => void
  onFocus?: (isOpen: boolean) => void
  onBlur?: () => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  ref?: React.RefCallback<HTMLButtonElement | null>
  isOpen: boolean
  disabled?: boolean
  hasError?: boolean
  children?: React.ReactNode
  className?: string
  size?: SelectSize
  selectStyle?: SelectStyle
  hasValue?: boolean
  isRequired?: boolean
}

export interface SelectTriggerAdaptiveBasicProps
  extends SelectTriggerBasicProps {
  placeholder?: React.ReactNode
  label?: React.ReactNode
  renderLeftControls?: () => React.ReactNode
  renderContent?: () => React.ReactNode
  renderRightControls?: () => React.ReactNode
}

export interface SelectTriggerProps extends SelectTriggerBasicProps {
  placeholder?: string
  placeholderIcon?: React.ReactElement
  selectedItem?: SelectItemBasic | null
  selectStyle?: SelectStyle
}

export interface SelectTriggerAdaptiveProps
  extends Omit<SelectTriggerAdaptiveBasicProps, 'renderContent'> {
  placeholder?: string
  placeholderIcon?: React.ReactElement
  selectedItem?: SelectItemBasic | null
  selectStyle?: SelectStyle
  renderContent?: () => React.ReactNode
}

export interface SelectLabelRenderProps {
  id: string
  htmlFor: string
}

export interface SelectTriggerRenderProps<ItemType>
  extends SelectTriggerBasicProps {
  selectedItem: SelectSelectedItem<ItemType>
}

export interface SelectAdditionalControlsRenderProps<ItemType> {
  closeMenu: () => void
  selectItem?: (item: SelectSelectedItem<ItemType>) => void
  shouldAutoFocusControl?: boolean
}
export interface SelectNoItemsPlaceholderRenderProps {
  closeMenu: () => void
}
export interface SelectBottomSectionRenderProps {
  isOpen: boolean
  onClose: () => void
}

export interface SelectLoadingPlaceholderRenderProps {
  isMobileVariant: boolean
  maxHeight?: string
}

export interface SelectBasicSharedProps<ItemType> {
  items: ItemType[]
  selectedItem: SelectSelectedItem<ItemType>
  hasSelectedIndicator?: boolean
  onChange: (selectedItem: SelectSelectedItem<ItemType>) => void
  renderTrigger: (
    renderProps: SelectTriggerRenderProps<ItemType>
  ) => React.ReactElement
  renderItem: (
    renderProps: SelectItemRenderProps<ItemType>
  ) => React.ReactElement
  renderLabel?: (
    renderProps: SelectLabelRenderProps
  ) => React.ReactElement | null
  onOpen?: (selectedItem: SelectSelectedItem<ItemType>) => void
  onClose?: (selectedItem: SelectSelectedItem<ItemType>) => void
  onFocus?: (isOpen: boolean) => void
  onBlur?: () => void
  isDisabled?: boolean
  filterItems?: (item: ItemType) => boolean
  menuStyle?: SelectStyle
  renderAdditionalControls?: (
    renderProps: SelectAdditionalControlsRenderProps<ItemType>
  ) => React.ReactNode
  renderNoItemsPlaceholder?: (
    renderProps: SelectNoItemsPlaceholderRenderProps
  ) => React.ReactNode
  renderBottomSection?: (
    renderProps: SelectBottomSectionRenderProps
  ) => React.ReactNode
  isLoading?: boolean
  renderLoadingPlaceholder?: (
    renderProps: SelectLoadingPlaceholderRenderProps
  ) => React.ReactNode
  hasError?: boolean
  shouldCloseOnSelect?: boolean
  shouldAutoFocusControl?: boolean
  isRequired?: boolean
}

export interface SelectBasicMobileProps<ItemType>
  extends SelectBasicSharedProps<ItemType> {
  mobileLabel?: React.ReactNode
}

export interface SelectBaseDesktopProps<ItemType>
  extends SelectBasicSharedProps<ItemType> {
  maxHeight?: string
  popperWidth?: string
  isSearchableByKeypress?: boolean
  placement?: Placement
  size?: SelectSize
  isResettable?: boolean
  isClearable?: boolean
}

export type SelectInputBasicProps<ItemType> = SelectBasicMobileProps<ItemType> &
  SelectBaseDesktopProps<ItemType>
