import {
  BodyDefault,
  ButtonFill,
  ButtonGhost,
  H2,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledHeader = styled(H2)`
  margin: 0 0 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_900};

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin-top: 0;
  }
`

export const StyledDescription = styled(BodyDefault)`
  margin: 0 0 1.5rem 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledCancelButtonStyled = styled(ButtonGhost)`
  margin-right: 0.25rem;
  width: 100%;
`

export const StyledConfirmButtonStyled = styled(ButtonFill)`
  margin-left: 0.25rem;
  width: 100%;
`

export const StyledCloseIconBox = styled.button`
  position: fixed;
  right: 1.5rem;
  z-index: 1000;
  border: none;
  background-color: transparent;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    top: 2rem;
  }
`
