import { useContext } from 'react'

import { TransactionUpdateContext } from './context/TransactionUpdateContext'
import { TransactionUpdateSubscriptionProvider } from './context/TransactionUpdateSubscriptionProvider'

interface Props {
  children?: React.ReactNode
}

export const TransactionUpdates = ({ children }: Props) => {
  const existingContext = useContext(TransactionUpdateContext)

  const { transactionUpdates$ } = existingContext

  if (transactionUpdates$) {
    return <>{children}</>
  }

  return (
    <TransactionUpdateSubscriptionProvider>
      {children}
    </TransactionUpdateSubscriptionProvider>
  )
}
