import { gql } from '@apollo/client'
import {
  AccountsFundsRemaining,
  AnnouncementModal,
} from '@npco/zeller-design-system'
import { useCloseAccountModal } from 'features/CloseAccount/hooks/useCloseAccountModal/useCloseAccountModal'

import { useGoToTransfer } from 'hooks/useGoToTransfer/useGoToTransfer'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { currencyFormatter } from 'utils/common'
import { translate } from 'utils/translations'
import { CloseAccountTransferFundsPromptDebitCardAccountV2Fragment } from 'types/gql-types/CloseAccountTransferFundsPromptDebitCardAccountV2Fragment'

interface CloseAccountTransferFundsPromptProps {
  account: CloseAccountTransferFundsPromptDebitCardAccountV2Fragment
}

export const CloseAccountTransferFundsPrompt = ({
  account,
}: CloseAccountTransferFundsPromptProps) => {
  const isMobile = useIsMobileResolution()
  const { goToTransfer } = useGoToTransfer()
  const { closeModal } = useCloseAccountModal()

  return (
    <AnnouncementModal
      Illustration={
        <AccountsFundsRemaining size={isMobile ? 'medium' : 'large'} />
      }
      heading={translate(
        'page.closeAccountModal.closeAccountTransferFundsPrompt.heading'
      )}
      description={translate(
        'page.closeAccountModal.closeAccountTransferFundsPrompt.description',
        {
          balance: currencyFormatter(
            parseInt(account.balance?.value || '0', 10)
          ),
        }
      )}
      primaryButtonLabel={translate(
        'page.closeAccountModal.closeAccountTransferFundsPrompt.primaryButtonLabel'
      )}
      onClickPrimary={() => goToTransfer({ transferFromAccountId: account.id })}
      secondaryButtonLabel={translate('shared.cancel')}
      onClickSecondary={closeModal}
      isOpen
      onCancel={closeModal}
    />
  )
}

CloseAccountTransferFundsPrompt.fragments = {
  DebitCardAccountV2: gql`
    fragment CloseAccountTransferFundsPromptDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      balance {
        value
      }
    }
  `,
}
