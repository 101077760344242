import { useCallback } from 'react'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  LinkSelectAccountState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'
import { UNEXPECTED_ERROR } from 'types/errors'
import { getIsDebitCardV2 } from 'pages/GlobalModals/AddCardModal/hooks/useActivateCard/useActivateCard.utils'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { SelectAccountFormValues } from 'pages/GlobalModals/AddCardModal/pages/shared/SearchableAccountForm/SearchableAccountForm.types'

import { useActivateCard } from '../../../../../hooks/useActivateCard'

export const useSelectAccountForm = () => {
  const { state } = useAddCardState<LinkSelectAccountState>()
  const { goToAddCardStage } = useGoToAddCardStage()
  const { activateDebitCard, isActivatingCard } = useActivateCard()
  const { goToSetPinStage } = useGoToSetPinStage()

  const goToLinkCardIdStage = useCallback(() => {
    goToAddCardStage({ stage: AddCardStage.LinkCardIdStage })
  }, [goToAddCardStage])

  const selectAccount = useCallback(
    async ({ accountId }: SelectAccountFormValues) => {
      if (!state?.cardId) {
        showErrorToast()
        return
      }

      const activatedCard = await activateDebitCard({
        debitCardAccountUuid: accountId,
        reference: state?.cardId,
      })

      if (
        activatedCard === UNEXPECTED_ERROR ||
        !getIsDebitCardV2(activatedCard)
      ) {
        showErrorToast()
        return
      }

      goToSetPinStage({
        stage: 'SetPin',
        cardId: activatedCard.id,
      })
    },
    [activateDebitCard, goToSetPinStage, state?.cardId]
  )

  return {
    initialAccountId: state?.accountId || '',
    goToLinkCardIdStage,
    selectAccount,
    isActivatingCard,
  }
}
