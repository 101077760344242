import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Magnifier } from 'assets/svg/search.svg'

import {
  StyledExtraLargeDescription,
  StyledIconBox,
  StyledLargeTitle,
  StyledTextContainer,
} from '../SharedElements.styled'

interface NoResultsProps {
  title: string
  description: string
}

export const NoResults = ({ title, description }: NoResultsProps) => {
  return (
    <Flex
      alignItems={['flex-start', 'center']}
      justifyContent="center"
      height="100%"
      marginBottom={['1rem', '12.5rem', '9rem']}
    >
      <Flex flexDirection="column" alignItems="center">
        <StyledIconBox alignItems="center" justifyContent="center">
          <SvgIcon width="22" height="22" color={COLOR.BLUE_1000}>
            <Magnifier />
          </SvgIcon>
        </StyledIconBox>
        <StyledTextContainer>
          <StyledLargeTitle>{title}</StyledLargeTitle>
          <StyledExtraLargeDescription>
            {description}
          </StyledExtraLargeDescription>
        </StyledTextContainer>
      </Flex>
    </Flex>
  )
}
