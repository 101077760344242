import { useCallback, useContext } from 'react'

import { AppState } from '../../../MFA.types'
import { MFAContext } from '../../MFAContext'

export const useRedirectToMFA = () => {
  const context = useContext(MFAContext)

  if (context === null) {
    throw new Error('useRedirectToMFA can only be used within MFAContext')
  }

  const { setIsRedirecting, setAppState, setOnCancel, onCancel } = context

  const cancelRedirect = useCallback(() => {
    onCancel?.()
    setIsRedirecting(false)
    setAppState(undefined)
  }, [setIsRedirecting, setAppState, onCancel])

  const redirectToMFA = useCallback(
    (appState: AppState, newOnCancel?: () => void) => {
      // We need to callback to the callback, as otherwise the return of the callback
      // is used as the new state, and we get type errors down the line
      setOnCancel(newOnCancel ? () => newOnCancel : null)
      setIsRedirecting(true)
      setAppState(appState)
    },
    [setIsRedirecting, setAppState, setOnCancel]
  )

  return { cancelRedirect, redirectToMFA }
}
