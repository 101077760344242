import { NavLink } from 'react-router-dom-v5-compat'
import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { commonLinkStyles, Title } from '../../Sidebar.styled'

export const ItemLink = styled(NavLink)`
  ${commonLinkStyles};
  gap: 8px;
  justify-content: flex-start;
  padding: 12px 18px;

  &.active:not(.unselected) {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding: 0;
  }
`

export const Subitem = styled.li<{ isDisabled?: boolean }>`
  list-style-type: none;
  ${ItemLink} {
    padding: 12px 0 12px 10px;
  }
  & > * {
    margin-bottom: 2px;
  }
  &:last-child {
    padding-bottom: 6px;
  }

  ${({ isDisabled }) => isDisabled && 'pointer-events: none'};
`

export const TitleNoMargin = styled(Title)`
  margin-left: 0;
`

export const Description = styled.div`
  font-size: 12px;
  padding-top: 2px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.GREY_550};
  opacity: 0;
  animation: ${({ theme }) => theme.animation.appearAnimation} 0.3s forwards
    ease-out;
  white-space: nowrap;
`
