import { useHistory } from 'react-router-dom'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  BUTTON_SIZE,
  ButtonGhost,
  MessageSize,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as InvoiceNoActivityImage } from 'assets/svg/Something went wrong - dominoes.svg'
import { ROOT } from 'const/routes'

import { InvoiceDrawerActivityPlaceholderWrapper } from './InvoiceDrawerActivity.styled'
import { translations } from './InvoiceDrawerActivityPlaceholder.i18n'

interface InvoiceDrawerActivityPlaceholderProps {
  referenceNumber: string
}

export const InvoiceDrawerActivityPlaceholder = ({
  referenceNumber,
}: InvoiceDrawerActivityPlaceholderProps) => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translations)

  return (
    <InvoiceDrawerActivityPlaceholderWrapper>
      <MessageWithIcon
        align="center"
        description={t('description')}
        image={<InvoiceNoActivityImage />}
        primaryButton={
          <ButtonGhost
            size={BUTTON_SIZE.MEDIUM}
            onClick={() =>
              history.push(
                ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.INVOICE(
                  referenceNumber
                ).EDIT.path
              )
            }
          >
            {t('editInvoice')}
          </ButtonGhost>
        }
        size={MessageSize.MEDIUM}
        title={t('title')}
      />
    </InvoiceDrawerActivityPlaceholderWrapper>
  )
}
