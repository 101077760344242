import { useCallback, useMemo, useState } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'

import {
  type CategoriesNetAmount,
  getGroupedNetAmounts,
} from '../../utils/getGroupedNetAmounts'

interface UseCategoryNavigationProps {
  categoryNetAmounts: CategoriesNetAmount[]
  isOtherCategoriesExpanded: boolean
  expandOtherCategories: () => void
}

export const useCategoryNavigation = ({
  categoryNetAmounts,
  isOtherCategoriesExpanded,
  expandOtherCategories,
}: UseCategoryNavigationProps) => {
  const groupedNetAmounts = getGroupedNetAmounts(categoryNetAmounts)
  const [selectedCategory, setSelectedCategory] = useState<
    EntityCategories | null | undefined
  >(undefined)

  const currentCategoryIndex = useMemo(() => {
    if (selectedCategory === undefined) {
      return undefined
    }

    return categoryNetAmounts.findIndex(
      (categoryNetAmount) => categoryNetAmount.category === selectedCategory
    )
  }, [categoryNetAmounts, selectedCategory])

  const prevCategory = useMemo(() => {
    if (currentCategoryIndex === undefined || currentCategoryIndex === 0) {
      return undefined
    }

    return categoryNetAmounts[currentCategoryIndex - 1]?.category
  }, [currentCategoryIndex, categoryNetAmounts])

  const nextCategory = useMemo(() => {
    if (
      currentCategoryIndex === undefined ||
      currentCategoryIndex === categoryNetAmounts.length - 1
    ) {
      return undefined
    }

    return categoryNetAmounts[currentCategoryIndex + 1]?.category
  }, [currentCategoryIndex, categoryNetAmounts])

  const isNextOtherCategory = useMemo(() => {
    if (currentCategoryIndex === undefined) {
      return false
    }

    return groupedNetAmounts[currentCategoryIndex + 1]?.category === 'other'
  }, [currentCategoryIndex, groupedNetAmounts])

  const selectPrevCategory = useCallback(() => {
    if (prevCategory === undefined) {
      return
    }
    setSelectedCategory(prevCategory)
  }, [prevCategory])

  const selectNextCategory = useCallback(() => {
    if (nextCategory === undefined) {
      return
    }

    if (!isOtherCategoriesExpanded && isNextOtherCategory) {
      expandOtherCategories()
    }
    setSelectedCategory(nextCategory)
  }, [
    nextCategory,
    expandOtherCategories,
    isOtherCategoriesExpanded,
    isNextOtherCategory,
  ])

  return {
    selectNextCategory:
      nextCategory === undefined ? undefined : selectNextCategory,
    selectPrevCategory:
      prevCategory === undefined ? undefined : selectPrevCategory,
    selectedCategory,
    setSelectedCategory,
  }
}
