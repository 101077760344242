import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToCompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage'
import { CompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import {
  ReportLostAndReplaceCardStage,
  VelocityControl,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'

type UseReportLostAndReplaceCardModalProps = {
  velocityControl: VelocityControl | null
  entityAddressTimezone?: string
}

export const useReportLostAndReplaceCardModal = ({
  velocityControl,
  entityAddressTimezone,
}: UseReportLostAndReplaceCardModalProps) => {
  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToCompleteCardStage } = useGoToCompleteCardStage()
  const { goToSetPinStage } = useGoToSetPinStage()

  const openAddCardModal = useCallback(() => {
    goToAddCardStage({
      stage: AddCardStage.LandingPageStage,
    })
  }, [goToAddCardStage])

  const closeReportLostAndReplaceCardModal = useCallback(
    () => goToReportLostAndReplaceCardStage(undefined),
    [goToReportLostAndReplaceCardStage]
  )

  const openVirtualCardCompleteModal = useCallback(
    (cardId: string, cardholderFirstName: string) => {
      goToCompleteCardStage({
        stage: CompleteCardStage.CompleteCardSetupReplacementStage,
        cardId,
        cardholderFirstName,
      })
    },
    [goToCompleteCardStage]
  )

  const openPhysicalCardCompleteModal = useCallback(() => {
    goToCompleteCardStage({
      stage: CompleteCardStage.CompleteCardReplacementDeliveryStage,
    })
  }, [goToCompleteCardStage])

  const openSetPinModal = useCallback(
    (cardId: string) => {
      goToSetPinStage({
        stage: 'SetPin',
        cardId,
        isReplacement: true,
      })
    },
    [goToSetPinStage]
  )

  const openReplaceCardErrorAdminModal = useCallback(() => {
    goToReportLostAndReplaceCardStage({
      stage: ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin,
      velocityControl,
      entityAddressTimezone,
    })
  }, [
    goToReportLostAndReplaceCardStage,
    velocityControl,
    entityAddressTimezone,
  ])

  const openReplaceCardErrorManagerModal = useCallback(() => {
    goToReportLostAndReplaceCardStage({
      stage: ReportLostAndReplaceCardStage.ReplaceCardErrorManager,
      velocityControl,
      entityAddressTimezone,
    })
  }, [
    goToReportLostAndReplaceCardStage,
    velocityControl,
    entityAddressTimezone,
  ])

  const openReplaceCardErrorModal = useCallback(
    (isAdmin: boolean) =>
      isAdmin
        ? openReplaceCardErrorAdminModal()
        : openReplaceCardErrorManagerModal(),
    [openReplaceCardErrorAdminModal, openReplaceCardErrorManagerModal]
  )

  return {
    openAddCardModal,
    openSetPinModal,
    openPhysicalCardCompleteModal,
    openVirtualCardCompleteModal,
    openReplaceCardErrorModal,
    closeReportLostAndReplaceCardModal,
  }
}
