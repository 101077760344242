import { useTranslations } from '@npco/utils-translations'
import {
  BodyDefault,
  Box,
  ButtonFill,
  Flex,
  Heading,
  SomethingWentWrong,
} from '@npco/zeller-design-system'

import { translations } from './VenueListError.i18n'
import { ErrorMessage } from './VenueListError.styled'

interface VenueListErrorProps {
  onRetry?: () => void
}

export const VenueListError = ({ onRetry }: VenueListErrorProps) => {
  const t = useTranslations(translations)
  return (
    <Flex flexDirection="column">
      <Box mb="32px">
        <SomethingWentWrong size="large" />
      </Box>
      <ErrorMessage>
        <Heading.H3>{t('title')}</Heading.H3>
        <BodyDefault>{t('description')}</BodyDefault>
        <Flex justifyContent="center">
          <ButtonFill onClick={onRetry}>{t('retry')}</ButtonFill>
        </Flex>
      </ErrorMessage>
    </Flex>
  )
}
