import { useMemo } from 'react'
import { currencyFormatter } from '@npco/component-mp-common'
import { getContactName } from '@npco/mp-utils-contacts-format'
import { useTranslations } from '@npco/utils-translations'

import { PieChartCenterContent } from '../../../PieChart/PieChartCenterContent/PieChartCenterContent'
import { translations } from './ContactPieChartText.i18n'
import { UseGetContactPieChartDataCashFlowContactNetAmountFragment as ContactNetAmount } from './hooks/useGetContactPieChartData.generated'

interface ContactPieChartTextProps {
  activeNetAmount: ContactNetAmount | undefined
  totalAmount: number
  size: 'small' | 'large'
  transactionDirection: 'income' | 'expense'
}

export const ContactPieChartText = ({
  activeNetAmount,
  totalAmount,
  size,
  transactionDirection,
}: ContactPieChartTextProps) => {
  const t = useTranslations(translations)

  const total = useMemo(() => {
    const value =
      activeNetAmount === undefined
        ? totalAmount
        : parseInt(activeNetAmount.total.value, 10)

    return transactionDirection === 'expense' ? -value : value
  }, [activeNetAmount, totalAmount, transactionDirection])

  const title = useMemo(() => {
    if (activeNetAmount === undefined) {
      return t('top10Contacts')
    }

    if (!activeNetAmount.contact) {
      return t('unassigned')
    }

    return getContactName(activeNetAmount.contact) ?? ''
  }, [activeNetAmount, t])

  return (
    <PieChartCenterContent
      size={size}
      title={title}
      total={currencyFormatter(total)}
    />
  )
}
