import { ApolloCache, FetchResult } from '@apollo/client'

import { ConfigureXeroPaymentServices } from 'types/gql-types/ConfigureXeroPaymentServices'
import {
  GetConnections as GetConnectionsType,
  GetConnectionsVariables,
} from 'types/gql-types/GetConnections'

import { GetConnections } from '../graphql/GetConnections'

interface WriteConnectionStatus {
  cache: ApolloCache<object>
  connectionId: string
  status: string
}

export const writeConnectionStatusToCache = ({
  cache,
  connectionId,
  status,
}: WriteConnectionStatus) => {
  cache.modify({
    id: cache.identify({ id: connectionId, __typename: 'Connection' }),
    fields: {
      status() {
        return status
      },
    },
  })
}

export const getUpdateOnSuccess =
  (variables: GetConnectionsVariables) =>
  (
    cache: ApolloCache<any>,
    result: FetchResult<ConfigureXeroPaymentServices>
  ) => {
    if (result?.data?.configureXeroPaymentServices?.connection) {
      const connections = cache.readQuery<GetConnectionsType>({
        query: GetConnections,
        variables,
      })
      cache.writeQuery<GetConnectionsType>({
        query: GetConnections,
        variables,
        data: {
          ...(connections as GetConnectionsType),
          xeroPaymentServices: {
            id: result?.data?.configureXeroPaymentServices?.connection.id,
            status:
              result?.data?.configureXeroPaymentServices?.connection.status,
            errorType:
              result?.data?.configureXeroPaymentServices?.connection.errorType,
            __typename: 'Connection',
          },
        },
      })
    }
  }
