import { ContactType } from '@npco/mp-gql-types'
import { useContactsFilters } from 'features/Contacts/hooks/useContactsFilters/useContactsFilters'
import { useField } from 'formik'

import { ContactSelectField } from 'pages/Transfer/ContactDropdown/ContactSelectField'
import { useBusinessContactValidate } from 'pages/Transfer/TransferFields/fields/BusinessContact/useBusinessContactValidate'

import * as styled from '../field.styled'

type BusinessContactFieldProps = {
  contactValidationError: string | undefined
  setContactValidationError: (newError: string | undefined) => void
  showPersonalDetailsInput?: boolean
}

export const BusinessContactField = ({
  contactValidationError,
  setContactValidationError,
  showPersonalDetailsInput,
}: BusinessContactFieldProps) => {
  const { filters, name$ } = useContactsFilters()
  const [{ value }, , { setValue }] = useField({
    name: 'reference',
    validate: useBusinessContactValidate(),
  })

  return (
    <styled.FieldWrapper>
      <ContactSelectField
        contactType={ContactType.BUSINESS}
        selectedItem={value}
        setSelectedItem={setValue}
        contactValidationError={contactValidationError}
        setContactValidationError={setContactValidationError}
        filters={filters}
        name$={name$}
        onCreate={() => undefined}
        shouldUpdateToFieldWithPaymentInstrument={false}
        shouldClearAllFields={false}
        showPersonalDetailsInput={showPersonalDetailsInput}
      />
    </styled.FieldWrapper>
  )
}
