import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

// NOTE: the negative margin-top is needed to handle the gap property which is
// applied within the wrapping page template components. We need to keep this
// div in the dom in order to animate smoothly otherwise it stutters.
export const Container = styled.div<{ $expanded: boolean }>`
  margin-top: ${({ $expanded }) => ($expanded ? '0px' : '-8px')};
  transition: margin-top 0.2s;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-top: ${({ $expanded }) => ($expanded ? '0px' : '-24px')};
  }
`
