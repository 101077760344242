import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import {
  UpgradeAccountsCompleteState,
  UpgradeAccountsCompleteStateConfigType,
} from './useGoToUpgradeAccountsCompleteStage.types'

export const useGoToUpgradeAccountsCompleteStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToUpgradeAccountsCompleteStage = useCallback(
    (
      state: UpgradeAccountsCompleteState | undefined,
      config?: UpgradeAccountsCompleteStateConfigType
    ) => {
      navigate(config?.pathname || location.pathname, {
        state: {
          UpgradeAccountsCompleteModal: state,
        },
      })
    },
    [navigate, location]
  )

  return { goToUpgradeAccountsCompleteStage }
}
