import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetEntityForAccountsLayoutQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetEntityForAccountsLayoutQueryResponse = {
  __typename?: 'Query'
  getEntity: {
    __typename?: 'Entity'
    id: string
    canTransferOut: boolean | null
    canCreateAccount: boolean | null
  }
}

export const GetEntityForAccountsLayout = gql`
  query GetEntityForAccountsLayout($entityUuid: ID!) {
    getEntity(entityUuid: $entityUuid) {
      id
      canTransferOut
      canCreateAccount
    }
  }
` as unknown as TypedDocumentNode<
  GetEntityForAccountsLayoutQueryResponse,
  GetEntityForAccountsLayoutQueryVariables
>

/**
 * __useGetEntityForAccountsLayoutQuery__
 *
 * To run a query within a React component, call `useGetEntityForAccountsLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityForAccountsLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityForAccountsLayoutQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetEntityForAccountsLayoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntityForAccountsLayoutQueryResponse,
    GetEntityForAccountsLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetEntityForAccountsLayoutQueryResponse,
    GetEntityForAccountsLayoutQueryVariables
  >(GetEntityForAccountsLayout, options)
}
export function useGetEntityForAccountsLayoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntityForAccountsLayoutQueryResponse,
    GetEntityForAccountsLayoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetEntityForAccountsLayoutQueryResponse,
    GetEntityForAccountsLayoutQueryVariables
  >(GetEntityForAccountsLayout, options)
}
export type GetEntityForAccountsLayoutQueryHookResult = ReturnType<
  typeof useGetEntityForAccountsLayoutQuery
>
export type GetEntityForAccountsLayoutLazyQueryHookResult = ReturnType<
  typeof useGetEntityForAccountsLayoutLazyQuery
>
export type GetEntityForAccountsLayoutQueryResult = Apollo.QueryResult<
  GetEntityForAccountsLayoutQueryResponse,
  GetEntityForAccountsLayoutQueryVariables
>
