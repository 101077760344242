import { Flex, HeadingStyles } from '@npco/zeller-design-system'

import * as styled from 'pages/Devices/LoadingSkeletonRow/LoadingSkeletonRow.styled'
import { SkeletonH5 } from 'components/SkeletonLoader/SkeletonLoaderTypography'

interface Props {
  label: string
  value: string
  isLoading: boolean
}

export const LoadingSkeletonRow = ({
  label,
  value,
  isLoading,
  ...props
}: Props) => {
  return (
    <Flex width="100%" justifyContent="space-between" {...props}>
      {isLoading ? (
        <>
          <SkeletonH5 width="118px" margin="0" />
          <SkeletonH5 width="118px" margin="0" />
        </>
      ) : (
        <>
          <styled.Label withStyles={HeadingStyles.H5}>{label}</styled.Label>
          <styled.DetailValue>{value}</styled.DetailValue>
        </>
      )}
    </Flex>
  )
}
