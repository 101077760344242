export const DEFAULT_SEND_BY_SMS_FIELD = 'defaultSendBySMS'
export const DEFAULT_SEND_BY_EMAIL_FIELD = 'defaultSendByEmail'
export const PDF_INCLUDE_ADDRESS_FIELD = 'pdfIncludesAddress'
export const BCC_COPY_FIELD = 'sendBccCopy'
export const BCC_EMAIL_FIELD = 'bccEmail'
export const SURCHARGE_ENABLED_FIELD = 'surchargeEnabledCnp'
export const SURCHARGE_TYPE_FIELD = 'surchargeType' // To be omitted for api
export const SURCHARGE_PERCENT_FIELD = 'surchargePercentCnp'
export const DISCOUNT_FIELD = 'discountsEnabled'
export const CPOC_SURCHARGE_TYPE_FIELD = 'cpocSurchargeType'
export const ADDRESS_STREET_FIELD = 'street'
export const ADDRESS_SUBURB_FIELD = 'suburb'
export const ADDRESS_STATE_FIELD = 'state'
export const ADDRESS_POSTCODE_FIELD = 'postcode'
export const ADDRESS_FIELDS = [
  ADDRESS_STREET_FIELD,
  ADDRESS_SUBURB_FIELD,
  ADDRESS_STATE_FIELD,
  ADDRESS_POSTCODE_FIELD,
] as const

export const LOGO = 'logo'
export const LOGO_FILE = 'logoFile'
