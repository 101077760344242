import { createTranslations } from '@npco/utils-translations'

export const setupFlowDebitCardDetailsTranslations = createTranslations({
  showMessageDesktop:
    'Click to see your first Debit Card details and instantly add it to your mobile wallet.',
  hideMessageDesktop: 'Click to hide your Debit Card details.',
  showMessageTablet:
    'Tap now to see your first Debit Card details and instantly add it to your mobile wallet.',
  hideMessageTablet: 'Tap to hide your Debit Card details.',
  showMessageMobile: 'Show Card Details',
  hideMessageMobile: 'Hide Card Details',
  errorMessage: 'Card details failed to load',
})
