import { ErrorMessageForm, Flex, INPUT_SIZE } from '@npco/zeller-design-system'
import { Editor, EditorProps } from 'draft-js'
import { useField } from 'formik'

import { translate } from 'utils/translations'

import { INVOICE_EMAIL_SUBJECT_FIELD } from '../../../../../../../Invoice.constants'
import { useDefaultMarkupValue } from '../../../hooks/useDefaultMarkupValue'
import { useEmailRichTextEditor } from '../../../hooks/useEmailRichTextEditor'
import {
  Label,
  RichTextEditorWrapper,
} from '../../../InvoiceDeliveryAccordion.styled'
import { InvoiceEmailMergeTags } from '../../InvoiceEmailMergeTags/InvoiceEmailMergeTags'

// Disables new line in subject textfield
// Special case in subject field as it is a 1 line field
const handleEditorReturn: EditorProps['handleReturn'] = () => 'handled'

export const InvoiceEmailSubject = () => {
  const { defaultSubject } = useDefaultMarkupValue()
  const [field, meta, helpers] = useField(INVOICE_EMAIL_SUBJECT_FIELD)

  const initialMarkup = field.value ?? defaultSubject
  // This tells the editor to create a new editorState from
  // the default subject markup (on first load or on reset template)
  const isUsingDefaultTemplate = field.value === null

  const hasFieldError = Boolean(meta.error)

  const {
    availableMergeTags,
    handleEditorBlur,
    handleEditorFocus,
    handleKeyPress,
    handleEditorChange,
    isFocused,
    sendMergeTagToEditor,
    textEditorState,
  } = useEmailRichTextEditor({
    fieldName: INVOICE_EMAIL_SUBJECT_FIELD,
    hasFieldError,
    initialMarkup,
    isDefaultTemplate: isUsingDefaultTemplate,
    onBlur: () => helpers.setTouched(true),
    onChange: (value: string) =>
      // Prevent validate when reset
      helpers.setValue(value, !isUsingDefaultTemplate),
  })

  const isFloatingLabel = Boolean(isFocused || field.value)

  return (
    <Flex flexDirection="column" data-testid="invoice-email-subject-field">
      <RichTextEditorWrapper active={isFocused} hasError={hasFieldError}>
        <Label isFloatingLabel={isFloatingLabel} $size={INPUT_SIZE.MEDIUM}>
          {translate('page.invoice.formSections.email.subjectLabel')}
        </Label>
        <Editor
          editorState={textEditorState}
          handleReturn={handleEditorReturn}
          onBlur={handleEditorBlur}
          onFocus={handleEditorFocus}
          onChange={handleEditorChange}
          keyBindingFn={handleKeyPress}
        />

        <InvoiceEmailMergeTags
          onSelectTag={(mergeTag) =>
            sendMergeTagToEditor(availableMergeTags[mergeTag])
          }
        />
      </RichTextEditorWrapper>
      <ErrorMessageForm hasError={hasFieldError} errorMessage={meta.error} />
    </Flex>
  )
}
