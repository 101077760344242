import { DatePicker } from 'components/Filters/NewFilters/DatePicker'
import {
  StyledIconContainer,
  StyledRangeContainer,
  StyledTitle,
  StyledWrapper,
} from 'components/Statements/StatementsButtons/CustomRange/CustomRange.styled'
import { page } from 'translations'

interface Props {
  children: React.ReactNode
}

export const CustomRange = ({ children }: Props) => {
  return (
    <StyledWrapper>
      <StyledTitle>{page.settings.statements.customRange}</StyledTitle>
      <StyledRangeContainer>
        <DatePicker />
      </StyledRangeContainer>
      <StyledIconContainer>{children}</StyledIconContainer>
    </StyledWrapper>
  )
}
