import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  CrossmarkRoundEdgeIcon,
  MenubarsIcon,
  SvgIcon,
} from '@npco/zeller-design-system'

import { useGetUnreadNotificationCountQuery } from 'hooks/useGetUnreadNotificationCount/graphql/getUnreadNotificationCount.generated'
import { useSidebarLocalCache } from 'components/Sidebar/hooks/useSidebarLocalCache'
import { translationsShared } from 'translations/index'

import * as notificationStyled from '../../Elements/NotificationsItem.styled'
import * as styled from './MobileNavHeader.styled'

export const SidebarMenuButtonDeps = {
  useGetUnreadNotificationCountQuery,
}

type SidebarMenuButtonProps = {
  isDisabled?: boolean
}

export const SidebarMenuButton = ({ isDisabled }: SidebarMenuButtonProps) => {
  const { useGetUnreadNotificationCountQuery } = SidebarMenuButtonDeps
  const entityUuid = useSelectedEntityUuid()
  const t = useTranslations(translationsShared)

  const { data } = useGetUnreadNotificationCountQuery({
    variables: { entityUuid },
  })
  const { toggleFold, isFolded } = useSidebarLocalCache()

  const isDotVisible = Number(data?.getUnreadNotificationCount) > 0

  return (
    <styled.MenuIconWrapper
      aria-controls="menu-sidebar"
      aria-label={t('toggleMenu')}
      aria-expanded={!isFolded}
      data-testid="menu-icon"
      onClick={toggleFold}
      disabled={isDisabled}
    >
      <SvgIcon
        width="20"
        height="20"
        color={isDisabled ? COLOR.GREY_150 : COLOR.BLUE_1000}
      >
        {isFolded ? (
          <>
            <MenubarsIcon />
            <notificationStyled.DotWrapper>
              <notificationStyled.NotificationsDot
                $isMobile
                $isFolded={isFolded}
                $newDot={!isDotVisible}
                data-testid="notifications-dot"
              />
            </notificationStyled.DotWrapper>
          </>
        ) : (
          <CrossmarkRoundEdgeIcon />
        )}
      </SvgIcon>
    </styled.MenuIconWrapper>
  )
}
