import { useTranslations } from '@npco/utils-translations'
import { AnchorBasic, Typography } from '@npco/zeller-design-system'

import {
  FINANCIAL_SERVICE_GUIDE_URL,
  PRODUCT_DISCLOSURE_STATEMENT_URL,
  PROMOTION_TERMS_CONDITIONS_URL,
} from '../../../../../const/externalLinks'
import { termsAndConditionsTranslations } from './TermsAndConditions.i18n'

export const TermsAndConditions = () => {
  const t = useTranslations(termsAndConditionsTranslations)

  return (
    <Typography component="p" variant="body-xs">
      {t('allConditions', {
        termsOfService: (
          <AnchorBasic href={PROMOTION_TERMS_CONDITIONS_URL}>
            {t('termsOfService')}
          </AnchorBasic>
        ),
        financialServicesGuide: (
          <AnchorBasic href={FINANCIAL_SERVICE_GUIDE_URL}>
            {t('financialServicesGuide')}
          </AnchorBasic>
        ),
        productDisclosureStatement: (
          <AnchorBasic href={PRODUCT_DISCLOSURE_STATEMENT_URL}>
            {t('productDisclosureStatement')}
          </AnchorBasic>
        ),
      })}
    </Typography>
  )
}
