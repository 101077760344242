const TIMEZONE_MAP: Record<string, string> = {
  'Australia/Broken_Hill': 'Broken Hill',
  'Australia/Lord_Howe': 'Lord Howe Island',
  'Pacific/Norfolk': 'Norfolk Island',
  'Australia/Eucla': 'Eucla',
  'Indian/Christmas': 'Christmas Island',
  'Indian/Cocos': 'Cocos (Keeling) Island',
  'Australia/Darwin': 'Darwin',
  'Australia/Sydney': 'Sydney',
  'Australia/Canberra': 'Canberra',
  'Australia/Melbourne': 'Melbourne',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Perth': 'Perth',
  'Australia/Hobart': 'Hobart',
}

const DEFAULT_TIMEZONE = 'Sydney'

export const getTimezoneName = (timezone?: string): string => {
  if (!timezone) {
    return DEFAULT_TIMEZONE
  }
  return TIMEZONE_MAP[timezone] ?? DEFAULT_TIMEZONE
}
