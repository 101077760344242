import { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetDeviceDetails } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/devices'
import { GetTransactionsTimestamp } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/transactions'

import {
  GetDeviceDetails as GetDeviceDetailsType,
  GetDeviceDetailsVariables,
} from 'types/gql-types/GetDeviceDetails'
import {
  GetTransactionsTimestamp as GetTransactionsTimestampType,
  GetTransactionsTimestampVariables,
} from 'types/gql-types/GetTransactionsTimestamp'

import { DeviceDetails } from './DeviceDetails.types'

export const useGetDeviceDetails = (deviceUuid: string) => {
  const entityUuid = useSelectedEntityUuid()
  const [getDeviceDetails, { loading, error, data }] = useLazyQuery<
    GetDeviceDetailsType,
    GetDeviceDetailsVariables
  >(GetDeviceDetails, {
    fetchPolicy: 'network-only',
    variables: {
      entityUuid,
      deviceUuid,
    },
  })

  useEffect(() => {
    if (deviceUuid) {
      getDeviceDetails()
    }
  }, [getDeviceDetails, deviceUuid])

  const deviceDetails: DeviceDetails = useMemo(() => {
    const deviceSettings = data?.getDeviceSettings

    return {
      serial: deviceSettings?.serial ?? '',
      appVersion: deviceSettings?.appVersion ?? '',
      firmwareVersion: deviceSettings?.firmwareVersion ?? '',
      caid: deviceSettings?.terminalConfig
        ? JSON.parse(deviceSettings.terminalConfig).caid
        : '',
      catid: deviceSettings?.terminalConfig
        ? JSON.parse(deviceSettings.terminalConfig).catid
        : '',
      active: deviceSettings?.posSettings?.active,
      deviceUser: deviceSettings?.deviceUser ?? '',
    }
  }, [data])

  return {
    error,
    isLoading: loading,
    getDeviceDetails,
    deviceDetails,
  }
}

export const useGetDeviceLastTransaction = (deviceUuid: string) => {
  const entityUuid = useSelectedEntityUuid()
  const [getDeviceLastTransaction, { loading, error, data }] = useLazyQuery<
    GetTransactionsTimestampType,
    GetTransactionsTimestampVariables
  >(GetTransactionsTimestamp, {
    fetchPolicy: 'network-only',
    variables: {
      entityUuid,
      filter: {
        deviceUuid: {
          eq: deviceUuid,
        },
      },
      limit: 1,
    },
  })

  useEffect(() => {
    if (deviceUuid) {
      getDeviceLastTransaction()
    }
  }, [getDeviceLastTransaction, deviceUuid])

  const lastTransaction: string | null = useMemo(() => {
    const transactions = data?.getTransactions?.transactions

    return transactions?.length ? transactions[0]?.timestamp : null
  }, [data])

  return {
    error,
    isLoading: loading,
    getDeviceLastTransaction,
    lastTransaction,
  }
}
