import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetUpdatedOutstandingTransactionsLocalState } from './graphql/getUpdatedOutstandingTransactionsLocalState'
import { CacheLocalStateOutstandingTransactions } from './useUpdatedOutstandingTransactions.types'

export const useReadUpdatedOutstandingTransactionsLocalCache = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data } = useQuery<CacheLocalStateOutstandingTransactions>(
    GetUpdatedOutstandingTransactionsLocalState,
    { variables: { entityUuid } }
  )

  return {
    updatedOutstandingTransactions:
      data?.local.updatedOutstandingTransactions ?? [],
  }
}
