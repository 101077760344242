import {
  Box,
  Divider,
  Flex,
  Heading,
  HeadingStyles,
} from '@npco/zeller-design-system'

interface SectionHeaderProps {
  heading: React.ReactNode
  children?: React.ReactNode
}

export const SectionHeader = ({ heading, children }: SectionHeaderProps) => {
  return (
    <Box pb="16px" width="100%">
      <Flex pb="8px" alignItems="end" justifyContent="space-between">
        <Heading.H2 withStyles={HeadingStyles.H4}>{heading}</Heading.H2>
        {children}
      </Flex>
      <Divider margin={0} />
    </Box>
  )
}
