import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box, TableCellText, TooltipBasic } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { StatusColor, StatusLabel } from '../InvoiceTable.utils'
import { translations } from './InvoiceStatusRowCell.i18n'

export const InvoiceStatusRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const t = useTranslations(translations)

  const isLoading = !row?.original || table.options.meta?.isLoading

  const value = isLoading ? undefined : getValue<InvoiceStatus>()
  const color = value ? StatusColor[value] : undefined
  const label = value ? StatusLabel[value] : undefined

  const isSendFailedStatus = value === InvoiceStatus.SEND_FAILED

  const text = isSendFailedStatus ? (
    <TooltipBasic
      dataTestId="send-failed-tooltip"
      placement="top"
      renderTrigger={({ ref, handlers }) => (
        <span ref={ref} {...handlers}>
          {label}
        </span>
      )}
      showArrow={false}
    >
      <Box textAlign="center" width="131px">
        {t('tooltip')}
      </Box>
    </TooltipBasic>
  ) : (
    label
  )

  return (
    <TableCellText
      color={color}
      dataTestId={`invoice-table-row-${row.index}-status`}
      forwardedProps={{ style: { padding: 0 } }}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width: '100%' }}
      text={text}
    />
  )
}
