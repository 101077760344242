import { Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

import { AccountBalance } from './AccountBalance'

export const BalanceRoutes = () => {
  return (
    <Routes>
      <Route
        path={ROOT.ORGS.ORG().OVERVIEW.FINANCE.relative}
        element={<AccountBalance />}
      />
    </Routes>
  )
}
