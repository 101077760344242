import { useMemo } from 'react'
import { Step } from 'react-joyride'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { corporateCardWalkthroughTranslations } from '../../CorporateCardWalkthrough.i18n'

export const useGetCorporateCardWalkthroughSteps = (): Step[] => {
  const t = useTranslations(corporateCardWalkthroughTranslations)
  const { userRole } = useLoggedInUser()
  const isAdmin = userRole === CustomerRole.ADMIN

  const isMobile = useIsMobileResolution()
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  return useMemo(() => {
    const editOutstandingExpensesStep: Step = {
      content: t('editOutstandingExpensesContent'),
      disableBeacon: true,
      placement: 'bottom-end',
      target: isMobile
        ? '.card-settings-button-mobile'
        : '.card-settings-button',
      title: t('editOutstandingExpensesTitle'),
    }

    return [
      {
        content: isAdmin
          ? t('outstandingExpensesContent')
          : t('outstandingExpensesContentManager'),
        disableBeacon: true,
        placement: 'bottom-end',
        target: '.card-transactions-missing-details-count-cell',
        title: isAdmin
          ? t('outstandingExpensesTitle')
          : t('outstandingExpensesTitleManager'),
      },
      {
        content: isAdmin
          ? t('remainingBalanceContent')
          : t('remainingBalanceContentManager'),
        disableBeacon: true,
        placement: 'bottom-end',
        target: isMobileOrTablet
          ? '.card-name-icon-progress-cell'
          : '.card-spend-progress-cell',
        title: t('remainingBalanceTitle'),
      },
      ...(isAdmin ? [editOutstandingExpensesStep] : []),
      {
        content: isAdmin ? t('readyContent') : t('readyContentManager'),
        disableBeacon: true,
        placement: 'bottom-start',
        target: '.table-row',
        title: t('readyTitle'),
      },
    ]
  }, [t, isMobileOrTablet, isMobile, isAdmin])
}
