import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Add Business Name',
  cancel: 'Cancel',
  continue: 'Continue',
  businessName: 'Business Name',
  body: 'Enter your Business Name below to start using invoices. You can update this later in Invoice Settings.',
  subtitle:
    'Your Business Name will appear on the invoice PDF, emails, checkout page, receipts and your customer’s card statement.',
  success: 'Business Name added successfully.',
  error: 'Business Name could not be added. Please try again.',
})
