import { useMemo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'
import { translations } from 'features/Expenses/ExpensesLayout/Expenses.i18n'

import { ROOT } from 'const/routes'

export const useExpensesTabs = () => {
  const { pathname } = useLocation()
  const t = useTranslations(translations)
  const tabs: TabItemProps<typeof Link>[] = useMemo(
    () => [
      {
        children: t('summary'),
        value: ROOT.ORGS.ORG().EXPENSES.SUMMARY.path,
        to: ROOT.ORGS.ORG().EXPENSES.SUMMARY.relative,
        component: Link,
      },
      {
        children: t('transactions'),
        value: ROOT.ORGS.ORG().EXPENSES.TRANSACTIONS.path,
        to: ROOT.ORGS.ORG().EXPENSES.TRANSACTIONS.relative,
        component: Link,
      },
    ],
    [t]
  )

  return {
    tabs,
    currentTab: (value: string) => Boolean(matchPath(value, pathname)),
  }
}
