import { useTranslations } from '@npco/utils-translations'
import {
  BodyLarge,
  Box,
  ButtonGhost,
  Flex,
  Heading,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as SomethingWentWrong } from 'assets/svg/something-went-wrong-balance.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { useLogout } from 'hooks/useLogout'
import { translationsShared } from 'translations'

interface LoginErrorContentProps {
  title: string
  subTitle?: string
  description: string
}

export const LoginErrorContent = ({
  title,
  subTitle,
  description,
}: LoginErrorContentProps) => {
  const tShared = useTranslations(translationsShared)
  const { logout } = useLogout()
  const isMobile = useIsMobileResolution()

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        width={{ _: '100%', XS: '600px' }}
        flexDirection="column"
        alignItems="start"
        p="24px"
        gridGap="32px"
      >
        <SvgIcon width="180px" height="180px">
          <SomethingWentWrong />
        </SvgIcon>
        <Box>
          <Heading.H1 mb="16px">{title}</Heading.H1>
          {subTitle && (
            <Box as={BodyLarge} mb="32px">
              {subTitle}
            </Box>
          )}
          <Box as={BodyLarge}>{description}</Box>
        </Box>

        <ButtonGhost fullWidth={isMobile} onClick={logout}>
          {tShared('logout')}
        </ButtonGhost>
      </Flex>
    </Flex>
  )
}
