import { useCloseAccountState } from '../hooks/useCloseAccountState/useCloseAccountState'
import { CloseAccount } from './CloseAccount/CloseAccount'

export const CloseAccountModal = () => {
  const { state } = useCloseAccountState()

  if (state === null) {
    return null
  }

  return <CloseAccount accountId={state.accountId} />
}
