import { useMemo } from 'react'
import { NetworkStatus } from '@apollo/client'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonClear,
  ButtonLink,
  Flex,
  INPUT_SIZE,
  InputWithoutLabel,
  SearchIcon,
  Typography,
} from '@npco/zeller-design-system'

import { BusinessesList } from './BusinessesList/BusinessesList'
import { useManageBusinessesQueryWithSearch } from './hooks/useManageBusinessesQueryWithSearch'
import { manageBusinessesTranslations } from './ManageBusinesses.i18n'

export const ManageBusinesses = () => {
  const t = useTranslations(manageBusinessesTranslations)
  const {
    entityRelationsQueryError,
    isLoadingEntityRelations,
    entityRelationsQueryNetworkStatus,
    entityRelations,
    searchValue,
    refetchEntityRelations,
    onChangeSearchTerm,
    onClearSearchTerm,
    filteredEntityRelations,
  } = useManageBusinessesQueryWithSearch()

  const isLastVisibleEntity = useMemo(() => {
    if (
      isLoadingEntityRelations ||
      entityRelationsQueryNetworkStatus === NetworkStatus.refetch ||
      !entityRelations
    ) {
      return false
    }

    const visibleEntitiesCount =
      entityRelations.filter((entity) => entity.isVisible).length ?? 0

    return visibleEntitiesCount === 1
  }, [
    entityRelations,
    isLoadingEntityRelations,
    entityRelationsQueryNetworkStatus,
  ])

  const featureFlags = useFeatureFlags()

  return (
    <Box maxWidth="580px" width="100%" mx="auto" p="56px 16px">
      <Box maxWidth="512px" width="100%">
        <Flex justifyContent="space-between">
          <Typography variant="heading-xl">{t('header')}</Typography>
          {(featureFlags.MultiEntityLinkExisting ||
            featureFlags.MultiEntityOnboarding) && (
            <ButtonLink>{t('addBusiness')}</ButtonLink>
          )}
        </Flex>
        <Box mt="24px">
          <InputWithoutLabel
            aria-label={t('searchInput')}
            name={t('searchInput')}
            value={searchValue}
            onChange={onChangeSearchTerm}
            icon={<SearchIcon />}
            size={INPUT_SIZE.MEDIUM}
            placeholder={t('placeholder')}
            renderRightControls={() =>
              searchValue && <ButtonClear onClick={onClearSearchTerm} />
            }
          />
        </Box>
      </Box>
      <Box mt="24px" width="100%">
        <BusinessesList
          isLoading={
            isLoadingEntityRelations ||
            entityRelationsQueryNetworkStatus === NetworkStatus.refetch
          }
          entityRelations={entityRelations}
          filteredEntityRelations={filteredEntityRelations}
          hasError={entityRelationsQueryError}
          reload={refetchEntityRelations}
          isSingleVisibleTab={isLastVisibleEntity}
        />
      </Box>
    </Box>
  )
}
