import { DEFAULT_MAX_HEIGHT } from '../../Select.utils'
import { StyledSkeletonItemsWrapper } from '../SelectLoadingPlaceholder.styled'
import { SelectSkeletonRowDesktop } from './SelectSkeletonRowDesktop'

interface Props {
  maxHeight?: string
}

export const SelectLoadingPlaceholderDesktop = ({
  maxHeight = DEFAULT_MAX_HEIGHT,
}: Props) => {
  return (
    <StyledSkeletonItemsWrapper $height={maxHeight}>
      <SelectSkeletonRowDesktop />
      <SelectSkeletonRowDesktop />
      <SelectSkeletonRowDesktop />
      <SelectSkeletonRowDesktop />
    </StyledSkeletonItemsWrapper>
  )
}
