import { useState } from 'react'
import {
  COLOR,
  Flex,
  SvgIcon,
  TileSelector,
  useModalState,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'
import { ImagePreview } from 'components/ModalUploadImage/ImagePreview/ImagePreview'

import { LogoUploadModal } from '../LogoUploadModal/LogoUploadModal'
import { MAX_LOGO_COUNT } from '../SiteAppearanceForm/SiteAppearanceForm.constants'
import {
  SCREEN_SAVER_FORM_FIELDS,
  SiteAppearanceFormValues,
} from '../SiteAppearanceForm/SiteAppearanceForm.types'
import * as styled from './LogoList.styled'

export const LogoList = () => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const [brokenImages, setBrokenImages] = useState<string[]>([])
  const { values, setFieldValue } = useFormikContext<SiteAppearanceFormValues>()
  return (
    <Flex mb="16px" maxWidth="504px" gap="12px" flexWrap="wrap">
      <TileSelector
        name="default-logo"
        selected={values.selectedLogo === ''}
        content={
          <styled.ZellerLogoWrapper>
            <SvgIcon
              dataTestId="screen-saver-zeller-logo"
              width="40"
              height="40"
              color={COLOR.BLACK}
            >
              <LogoIcon data-testid="logo-zeller" />
            </SvgIcon>
          </styled.ZellerLogoWrapper>
        }
        onChange={() => {
          setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedLogo, '')
        }}
        value="default-logo"
      />
      {values.logos.map((logoInfo) => {
        const { logoUuid, url } = logoInfo
        const isBroken = brokenImages.includes(logoUuid)

        return (
          <TileSelector
            key={logoUuid}
            name={logoUuid}
            selected={values.selectedLogo === logoInfo.logoUuid}
            content={
              <styled.LogoWrapper>
                <ImagePreview
                  tabIndex={-1}
                  src={url}
                  isBroken={isBroken}
                  onError={() => {
                    setBrokenImages((prev) => [...prev, logoUuid])
                  }}
                />
              </styled.LogoWrapper>
            }
            onChange={() => {
              setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedLogo, logoUuid)
            }}
            onClear={() => {
              if (values.selectedLogo === logoInfo.logoUuid) {
                setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedLogo, '')
              }
              setFieldValue(
                SCREEN_SAVER_FORM_FIELDS.logos,
                values.logos.filter((logo) => {
                  return logo.logoUuid !== logoUuid
                })
              )
              setFieldValue(
                SCREEN_SAVER_FORM_FIELDS.logosToUpload,
                values.logosToUpload.filter((logo) => {
                  return logo.logoUuid !== logoUuid
                })
              )
            }}
            clearable
            value={`custom-logo-${logoUuid}`}
          />
        )
      })}
      {values.logos.length < MAX_LOGO_COUNT && (
        <TileSelector
          name="add-logo"
          content={
            <styled.AddValue>
              <SvgIcon width="16" height="16">
                <Plus />
              </SvgIcon>
            </styled.AddValue>
          }
          onChange={openModal}
          value="add-logo"
        />
      )}
      <LogoUploadModal isOpen={isModalOpen} onClose={closeModal} />
    </Flex>
  )
}
