import { z } from 'zod'

export type SetPinStage = 'SetPin'

export type SetPinStateConfigType = {
  pathname?: string
}

const SetPinStateSchema = z.object({
  stage: z.literal('SetPin'),
  cardId: z.string(),
  isReplacement: z.boolean().optional(),
})

export type SetPinState = z.infer<typeof SetPinStateSchema>

export const SetPinLocationStateSchema = z.object({
  SetPinModal: SetPinStateSchema,
})
