import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { useSelectField } from 'design-system/Components/Select'
import { getAccountValueLabel } from 'features/Accounts/Accounts/ListAccounts/ListItemAccounts/ListItemAccounts.utils'
import { useFormikContext } from 'formik'

import { combineValidators, validateRequired } from 'utils/formValidation'
import { CustomValidator } from 'types/common'

import { useTransferQuery } from '../hooks/useTransferQuery/useTransferQuery'
import { useTransferState } from '../Transfer.context'
import { TransferGeneralState } from '../Transfer.stateMachine'
import { TransferFieldTypes } from '../Transfer.types'
import { getAccountAvatar, getRightSideLabel } from '../Transfer.utils'
import {
  TransferSelectInput,
  TransferSelectInputItemType,
} from '../TransferSelectInput/TransferSelectInput'
import { transferZellerDropdownTranslations } from './TransferZellerDropdown.i18n'

export interface TransferZellerDropdownProps {
  name: string
  disabled?: boolean
  validateAccounts?: (
    accounts: ReturnType<typeof useTransferQuery>['allAccounts'],
    externalTransfer?: boolean
  ) => CustomValidator<string>
}

export const TransferZellerDropdown = ({
  name,
  disabled = false,
  validateAccounts,
}: TransferZellerDropdownProps) => {
  const {
    allAccounts: activeAccounts,
    error: getAccountsError,
    isRefetchingAccounts,
    refetchAccounts,
  } = useTransferQuery()

  const {
    transferState: [state],
  } = useTransferState()

  const t = useTranslations(transferZellerDropdownTranslations)

  const externalTransfer = useMemo(
    () =>
      state.matches(TransferGeneralState.ContactTransfer) ||
      state.matches(TransferGeneralState.BpayTransfer),
    [state]
  )

  const { values } = useFormikContext<TransferFieldTypes>()

  const prefilledOption = useMemo<TransferSelectInputItemType | null>(() => {
    const initialAccount = activeAccounts.find(
      (account) => account.id === values[name as keyof typeof values]
    )

    if (!initialAccount) {
      return null
    }

    return {
      label: initialAccount.name,
      value: initialAccount.id,
      status: initialAccount.status,
      subLabel1: getAccountValueLabel(initialAccount),
      avatar: getAccountAvatar(initialAccount),
    }

    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectOptions = useMemo<TransferSelectInputItemType[]>(() => {
    return activeAccounts.map((account) => ({
      label: account.name,
      value: account.id,
      status: account.status,
      subLabel1: getAccountValueLabel(account),
      rightSideLabel: getRightSideLabel(t('internalLabel'), account),
      avatar: getAccountAvatar(account),
    }))
  }, [activeAccounts, t])

  const filterItems = (account: TransferSelectInputItemType) => {
    return ![values.from, values.to].includes(account.value)
  }

  const { hasError, onChange, selectedItem, errorMessage, setTouched } =
    useSelectField<TransferSelectInputItemType>({
      name,
      validate: combineValidators(
        validateRequired,
        validateAccounts?.(activeAccounts, externalTransfer)
      ),
      items: selectOptions,
    })

  const chosenItem = selectedItem ?? prefilledOption

  const isErrorAvailable = useMemo(() => {
    if (name === 'from' && externalTransfer && chosenItem && errorMessage) {
      return true
    }
    return hasError
  }, [chosenItem, errorMessage, externalTransfer, hasError, name])

  return (
    <TransferSelectInput
      isDisabled={disabled}
      selectOptions={selectOptions}
      onChange={onChange}
      setTouched={setTouched}
      chosenItem={chosenItem}
      hasError={isErrorAvailable}
      isRefetchingAccounts={isRefetchingAccounts}
      errorMessage={errorMessage}
      getAccountsError={getAccountsError}
      refetchAccounts={refetchAccounts}
      filterItems={filterItems}
    />
  )
}
