import { useCallback, useEffect, useRef, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import {
  ErrorMessageForm,
  INPUT_SIZE,
  InputAdaptive,
} from '@npco/zeller-design-system'
import { FieldConfig, useField } from 'formik'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

import poweredByGoogle from 'assets/images/powered_by_google.png'
import { useInitGoogleScriptLoad } from 'hooks/useInitGoogleScriptLoad/useInitGoogleScriptLoad'
import { KEY_CODE } from 'const/keyboard'
import { parseGoogleAddressData } from 'utils/google'
import {
  GoogleParsedAddressData,
  GooglePlacesDataItem,
  GooglePlacesDetailsDataItem,
} from 'types/google'
import { shared } from 'translations'

import {
  StyledButton,
  StyledImageWrapper,
  StyledSuggestionsList,
  StyledSuggestionsListItem,
  StyledSuggestionsListWrapper,
  StyledWrapper,
} from './AddressAutocompleteInput.styled'

const { poweredByGoogle: poweredByGoogleText } = shared

interface Props {
  setFullAddressValues: (
    googleParsedAddressData: GoogleParsedAddressData
  ) => void
  country: string | null
  name: string
  validate?: FieldConfig['validate']
  label?: string
  placeholder?: string
  disabled?: boolean
  size?: INPUT_SIZE
  maxLength?: number
}

export const AddressAutocompleteInput = ({
  setFullAddressValues,
  country,
  name,
  validate,
  label,
  placeholder,
  disabled,
  size = INPUT_SIZE.SMALL,
  maxLength = undefined,
}: Props) => {
  const [field, meta, handlers] = useField({ name, validate })
  const { error, touched } = meta
  const hasError = Boolean(touched && error)

  const {
    init,
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      types: ['address'],
      componentRestrictions: { country: country ?? '' },
    },
    debounce: 300,
  })

  useInitGoogleScriptLoad({ onLoad: init })

  const ref = useOnclickOutside(clearSuggestions)
  const suggestionRefs = useRef<HTMLButtonElement[] | null[]>([])
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState<
    number | null
  >(null)

  const googlePlacesData = data as GooglePlacesDataItem[]

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value, Boolean(e.target.value))
      handlers.setValue(e.target.value)
      if (!e.target.value) {
        clearSuggestions()
      }
    },
    [handlers, setValue, clearSuggestions]
  )

  const onSelectSuggestion = useCallback(
    async (dataItem: GooglePlacesDataItem) => {
      const parameter = {
        placeId: dataItem.place_id,
        fields: ['address_components'],
      }

      const response = (await getDetails(
        parameter
      )) as GooglePlacesDetailsDataItem

      const addressData = parseGoogleAddressData(response)

      if (setFullAddressValues) {
        setFullAddressValues(addressData)
      }

      setValue(addressData.street || '', false)
      setFocusedSuggestionIndex(null)
      clearSuggestions()
    },
    [setValue, clearSuggestions, setFullAddressValues]
  )

  const shouldRenderList = status === 'OK' && googlePlacesData.length > 0

  const focusDown = useCallback(
    (e: KeyboardEvent) => {
      const isLastItem =
        focusedSuggestionIndex === suggestionRefs.current.length - 1
      const nextIndex =
        focusedSuggestionIndex === null || isLastItem
          ? 0
          : focusedSuggestionIndex + 1

      const nextEl = suggestionRefs.current[nextIndex]

      if (shouldRenderList && nextEl && e.key === KEY_CODE.ARROW_DOWN) {
        e.preventDefault()
        nextEl.focus()
        setFocusedSuggestionIndex(nextIndex)
      }
    },
    [shouldRenderList, focusedSuggestionIndex]
  )

  const focusUp = useCallback(
    (e: KeyboardEvent) => {
      const isFirstItem = focusedSuggestionIndex === 0

      const previousIndex =
        focusedSuggestionIndex === null || isFirstItem
          ? suggestionRefs.current.length - 1
          : focusedSuggestionIndex - 1

      const previousEl = suggestionRefs.current[previousIndex]

      if (shouldRenderList && previousEl && e.key === KEY_CODE.ARROW_UP) {
        e.preventDefault()
        previousEl.focus()
        setFocusedSuggestionIndex(previousIndex)
      }
    },
    [focusedSuggestionIndex, shouldRenderList]
  )

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === KEY_CODE.ARROW_UP) {
        focusUp(e)
      }

      if (e.key === KEY_CODE.ARROW_DOWN) {
        focusDown(e)
      }

      if (e.key === KEY_CODE.TAB) {
        clearSuggestions()
      }
    },
    [clearSuggestions, focusDown, focusUp]
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <StyledWrapper ref={ref}>
      <InputAdaptive
        label={label}
        placeholder={placeholder}
        name={name}
        value={field.value}
        disabled={disabled}
        onChange={onChange}
        size={size}
        maxLength={maxLength}
        onBlur={(event) => {
          field.onBlur(event)
          handlers.setTouched(true)
        }}
        hasError={hasError}
      />
      <ErrorMessageForm hasError={Boolean(hasError)} errorMessage={error} />

      {shouldRenderList && (
        <StyledSuggestionsListWrapper data-testid="place-suggestions">
          <StyledSuggestionsList>
            {googlePlacesData.map((dataItem, index) => (
              <StyledButton
                ref={(element) => {
                  suggestionRefs.current[index] = element
                }}
                disabled={!ready}
                onClick={() => onSelectSuggestion(dataItem)}
                key={dataItem.place_id}
                data-testid={`suggestion-${dataItem.place_id}`}
                type="button"
              >
                <StyledSuggestionsListItem>
                  {dataItem.description}
                </StyledSuggestionsListItem>
              </StyledButton>
            ))}

            <StyledImageWrapper>
              <img src={poweredByGoogle} alt={poweredByGoogleText} />
            </StyledImageWrapper>
          </StyledSuggestionsList>
        </StyledSuggestionsListWrapper>
      )}
    </StyledWrapper>
  )
}
