import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  AvatarBasic as AvatarBase,
  AvatarProps as AvatarBaseProps,
  COLOR,
} from '@npco/zeller-design-system'

import { avatarTranslations } from './Avatar.i18n'
import { parseColorHexString } from './utils/parseColorHexString'

export interface AvatarProps
  extends Omit<AvatarBaseProps, 'text' | 'color' | 'bgColor'> {
  /**
   * Used as an initial/character.
   */
  text?: string | null
  /**
   * The base/background color of the avatar. If color matches a Preset `textColor` will
   * be automatically set to match.
   */
  color?: string | null
  /**
   * The alt/foreground color of the avatar. Will use `text` color of a Preset if `color`
   * matches a Preset key. If defined, will override the Preset's `text` color.
   */
  textColor?: string | null
  /**
   * If `true` (default `false`), will use a faded/greyed-out base/background color. If
   * defined, will ignore `color`, `textColor` and the Preset's `text` color.
   */
  disabled?: boolean
}

export const Avatar = ({
  text,
  color: colorRaw,
  textColor: textColorRaw,
  disabled,
  ...props
}: AvatarProps) => {
  const t = useTranslations(avatarTranslations)

  const color = useMemo(() => parseColorHexString(colorRaw), [colorRaw])
  const textColor = useMemo(
    () => parseColorHexString(textColorRaw),
    [textColorRaw]
  )

  const textResolved = text ?? t('zeller')
  const colorResolved = useMemo(() => {
    if (disabled) {
      return COLOR.GREY_550
    }
    return textColor
  }, [disabled, textColor])

  const bgColorResolved = useMemo(() => {
    if (disabled) {
      return COLOR.GREY_60
    }
    return color
  }, [disabled, color])

  return (
    <AvatarBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      text={textResolved}
      color={colorResolved}
      bgColor={bgColorResolved}
    />
  )
}
