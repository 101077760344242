import { DebitCardAccountType } from '@npco/mp-gql-types'
import { createMachine } from 'xstate'

import { TransferType } from './Transfer.types'

export enum TransferEvent {
  AddContact = 'AddContact',
  RemoveContact = 'RemoveContact',
  SelectContactWithMultipleOrNoAccount = 'SelectContactWithMultipleOrNoAccount',
  AddAccount = 'AddAccount',
  RecipientInput = 'RecipientInput',
  Continue = 'Continue',
  Back = 'Back',
  DeFallbackRetry = 'DeFallbackRetry',
  ToZellerTransfer = 'ToZellerTransfer',
  ToContactTransfer = 'ToContactTransfer',
  ToBpayTransfer = 'ToBpayTransfer',
  Error = 'Error',
  Reset = 'Reset',
}

export enum TransferGeneralState {
  ZellerTransfer = 'ZellerTransfer',
  ContactTransfer = 'ContactTransfer',
  BpayTransfer = 'BpayTransfer',
}

export enum TransferZellerState {
  NoRecipients = 'NoRecipients',
  TransferDetails = 'TransferDetails',
  RedirectToAccountList = 'RedirectToAccountList',
}

export enum TransferContactState {
  NoContact = 'NoContact',
  NoAccount = 'NoAccount',
  TransferDetails = 'TransferDetails',
  RedirectToAccountList = 'RedirectToAccountList',
}

export enum TransferBpayState {
  TransferDetails = 'TransferDetails',
  RedirectToAccountList = 'RedirectToAccountList',
}

const zellerState = {
  initial: TransferZellerState.NoRecipients,
  states: {
    [TransferZellerState.NoRecipients]: {
      on: {
        [TransferEvent.Back]: TransferZellerState.RedirectToAccountList,
        [TransferEvent.RecipientInput]: TransferZellerState.TransferDetails,
      },
    },
    [TransferZellerState.TransferDetails]: {
      on: {
        [TransferEvent.Back]: TransferZellerState.RedirectToAccountList,
      },
    },
    [TransferZellerState.RedirectToAccountList]: {
      type: 'final',
    } as { type: 'final' },
  },
}

export const contactTransferState = {
  initial: TransferContactState.NoContact,
  states: {
    [TransferContactState.NoContact]: {
      on: {
        [TransferEvent.AddContact]: TransferContactState.TransferDetails,
        [TransferEvent.SelectContactWithMultipleOrNoAccount]:
          TransferContactState.NoAccount,
        [TransferEvent.Back]: TransferContactState.RedirectToAccountList,
        [TransferEvent.Reset]: TransferContactState.NoContact,
      },
    },
    [TransferContactState.NoAccount]: {
      on: {
        [TransferEvent.AddAccount]: TransferContactState.TransferDetails,
        [TransferEvent.RemoveContact]: TransferContactState.NoContact,
        [TransferEvent.AddContact]: TransferContactState.TransferDetails,
        [TransferEvent.Back]: TransferContactState.RedirectToAccountList,
        [TransferEvent.Reset]: TransferContactState.NoContact,
      },
    },
    [TransferContactState.TransferDetails]: {
      on: {
        [TransferEvent.SelectContactWithMultipleOrNoAccount]:
          TransferContactState.NoAccount,
        [TransferEvent.RemoveContact]: TransferContactState.NoContact,
        [TransferEvent.Back]: TransferContactState.RedirectToAccountList,
        [TransferEvent.Reset]: TransferContactState.NoContact,
      },
    },
    [TransferContactState.RedirectToAccountList]: {
      type: 'final',
    } as { type: 'final' },
  },
}

export const bpayTransferState = {
  initial: TransferBpayState.TransferDetails,
  states: {
    [TransferBpayState.TransferDetails]: {
      on: {
        [TransferEvent.Back]: TransferBpayState.RedirectToAccountList,
      },
    },
    [TransferBpayState.RedirectToAccountList]: {
      type: 'final',
    } as { type: 'final' },
  },
}

type CreateTransferMachineArgs = {
  type?: TransferType
  initialAccountType?: DebitCardAccountType
}

export const createTransferMachine = ({
  type,
  initialAccountType,
}: CreateTransferMachineArgs) => {
  return createMachine({
    id: 'transferFlow',
    initial:
      type === 'internal' || initialAccountType === DebitCardAccountType.SAVINGS
        ? TransferGeneralState.ZellerTransfer
        : TransferGeneralState.ContactTransfer,
    states: {
      [TransferGeneralState.ZellerTransfer]: zellerState,
      [TransferGeneralState.ContactTransfer]: contactTransferState,
      [TransferGeneralState.BpayTransfer]: bpayTransferState,
    },
    on: {
      [TransferEvent.ToZellerTransfer]: [
        {
          target: TransferGeneralState.ZellerTransfer,
        },
      ],
      [TransferEvent.ToContactTransfer]: [
        {
          target: TransferGeneralState.ContactTransfer,
        },
      ],
      [TransferEvent.ToBpayTransfer]: [
        {
          target: TransferGeneralState.BpayTransfer,
        },
      ],
    },
  })
}
