import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    gap: 20px;
  }
`

export const StyledFiltersSelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 21.53rem;
  }
`
