import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  InfoFilledIcon,
  ToggleForm,
  TOOLTIP_ZINDEX,
  TooltipBasic,
  TooltipSize,
} from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { type EditVenueFormType } from './EditVenue.types'
import { translations } from './StationIdToggle.i18n'
import * as styled from './StationIdToggle.styled'

type Props = {
  category: 'venue' | 'location'
}
export const StationIdToggle = ({ category }: Props) => {
  const t = useTranslations(translations)
  const tooltip =
    category === 'venue' ? t('venueTooltip') : t('locationTooltip')
  const { values } = useFormikContext<EditVenueFormType>()
  const name =
    category === 'venue' ? 'venueStationIdToggle' : 'locationStationIdToggle'
  const value =
    category === 'venue'
      ? values.venueStationIdToggle
      : values.locationStationIdToggle

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      alignSelf="stretch"
    >
      <Flex flexDirection="row" alignItems="center" gap="8px">
        <styled.StationIdLabel>{t('label')}</styled.StationIdLabel>
        <TooltipBasic
          placement="top"
          renderTrigger={({ handlers, ref }) => (
            <span ref={ref} {...handlers}>
              <InfoFilledIcon color={COLOR.GREY_550} data-testid="info-icon" />
            </span>
          )}
          showArrow={false}
          size={TooltipSize.Small}
          zIndex={Number.parseInt(TOOLTIP_ZINDEX, 10)}
        >
          <Box maxWidth="408px" textAlign="center">
            {tooltip}
          </Box>
        </TooltipBasic>
      </Flex>

      <Field component={ToggleForm} name={name} value={value} />
    </Flex>
  )
}
