import { Checkbox } from 'components/Input/Checkbox'

import { StyledRow } from './Elements.styled'

interface AllItemsRowProps {
  label: string
  checked: boolean
  handleOnChange: () => void
  indeterminate?: boolean
}

export const AllItemsRow: React.FC<AllItemsRowProps> = ({
  label,
  checked,
  handleOnChange,
  indeterminate = false,
}) => (
  <StyledRow as="li" data-testid="all-items-row">
    <Checkbox
      type="checkbox"
      name="all"
      label={label}
      checked={checked}
      indeterminate={indeterminate}
      onChange={handleOnChange}
    />
  </StyledRow>
)
