import { ErrorMessageForm } from '@npco/zeller-design-system'
import { Field } from 'formik'
import { Box } from 'reflexbox'

import { validateRequired } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { FormCheckbox } from 'components/Input/Checkbox'

import { useConsentToViewField } from './hooks/useConsentToViewField'

export const ConsentToViewField = () => {
  const { error, hasIDDocumentType } = useConsentToViewField()

  if (!hasIDDocumentType) {
    return null
  }

  return (
    <Box mb="40px">
      <Field
        component={FormCheckbox}
        label={translate('form.shared.uploadDocuments.consentToViewDocument')}
        name="consentToViewDocument"
        type="checkbox"
        validate={validateRequired}
      />
      <ErrorMessageForm hasError={!!error} errorMessage={error} />
    </Box>
  )
}
