import { useCallback, useMemo } from 'react'
import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import { VelocityWindowEnum } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  SkeletonBasic,
  TooltipButton,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { VelocityWindowSummaryVelocityControlTypeFragment } from './VelocityWindowSummary.generated'
import { velocityWindowSummaryTranslations } from './VelocityWindowSummary.i18n'
import * as styled from './VelocityWindowSummary.styled'
import { getRemainingTime } from './VelocityWindowSummary.utils'

interface VelocityWindowSummaryProps {
  velocityControl: VelocityWindowSummaryVelocityControlTypeFragment
  timezone?: string
  isLoading?: boolean
}

export const VelocityWindowSummary = ({
  velocityControl,
  timezone,
  isLoading = false,
}: VelocityWindowSummaryProps) => {
  const tShared = useTranslations(translationsShared)

  const { remainingTime, endOfCurrentWindow } = getRemainingTime({
    velocityControl,
    timezone,
  })

  const limit = currencyFormatter(
    parseInt(velocityControl.amountLimit.value, 10)
  )

  const t = useTranslations(velocityWindowSummaryTranslations)

  const getVelocityWindowDisplay = useCallback(
    (velocityWindow: VelocityWindowEnum) => {
      if (velocityWindow === VelocityWindowEnum.DAY) {
        return tShared('daily')
      }
      if (velocityWindow === VelocityWindowEnum.WEEK) {
        return tShared('weekly')
      }
      if (velocityWindow === VelocityWindowEnum.FORTNIGHT) {
        return tShared('fortnightly')
      }
      if (velocityWindow === VelocityWindowEnum.MONTH) {
        return tShared('monthly')
      }
      return tShared('quarterly')
    },
    [tShared]
  )

  const hasMinutes = useMemo(
    () => endOfCurrentWindow.local().minute() > 0,
    [endOfCurrentWindow]
  )

  return (
    <Box m="14px 0 6px 0">
      {isLoading ? (
        <Flex justifyContent={{ _: 'center', SM: 'end' }}>
          <SkeletonBasic width="184px" height="8px" isRounded />
        </Flex>
      ) : (
        <TooltipButton
          tooltipIcon={
            <styled.SummaryText>
              {t('summary', {
                endOfWindow: endOfCurrentWindow
                  .local()
                  .format(
                    hasMinutes ? 'h:mma, D MMMM YYYY' : 'ha, D MMMM YYYY'
                  ),
                limit,
              })}
            </styled.SummaryText>
          }
          placement="top"
          showArrow={false}
        >
          <Box maxWidth="230px" textAlign="center" fontWeight={500}>
            {t('endOfWindow', {
              remainingTimeText: remainingTime,
              window: getVelocityWindowDisplay(velocityControl.velocityWindow),
            })}
          </Box>
        </TooltipButton>
      )}
    </Box>
  )
}

VelocityWindowSummary.fragments = {
  VelocityControlType: gql`
    fragment VelocityWindowSummaryVelocityControlTypeFragment on VelocityControlType {
      amountLimit {
        value
      }
      ...GetRemainingTimeVelocityControlTypeFragment
    }

    ${getRemainingTime.fragments.VelocityControlType}
  `,
}
