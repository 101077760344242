import { number } from 'yup'

export const getIsValidNumber = (
  value: unknown,
  originalValue: unknown,
  formattedString: string
) => {
  const isValidNumber = Number.isFinite(value)

  // NOTE: handle falsey string values and non finite numbers, by returning
  // originalValue a type error will be thrown
  if (!formattedString || !isValidNumber) {
    return originalValue
  }
  return value
}

// NOTE: this schema is used for string inputs but we use the number schema and
// transform the value as appropriate
export const stringToNumberSchema = number()
  // NOTE: yup attempts to automatically cast any value to a number
  // given this schema so the current value will most likely alway be
  // NaN. We transform to a number and only pass the originalValue when
  // we need to raise a type error if value can't be transformed.
  .transform(function stringToNumberTransform(currentValue, originalValue) {
    if (this.isType(currentValue)) {
      return currentValue
    }

    const replacedCommaString = originalValue.replace(/,/g, '')

    // NOTE: number string values can be formatted to locale string so can
    // include commas e.g 45,657.00
    const numberValue = Number(replacedCommaString)

    return getIsValidNumber(numberValue, originalValue, replacedCommaString)
  })
