import { ConnectionErrorType, ConnectionStatus } from '@npco/mp-gql-types'

import { useXeroBankfeed } from './useXeroBankfeed'

export const useXeroBankFeedsError = () => {
  const { xeroBankFeed, isLoading } = useXeroBankfeed()
  const hasErrorInBankFeeds =
    xeroBankFeed?.status === ConnectionStatus.CONNECTED_WITH_ERROR
  const isTokenExpired =
    xeroBankFeed?.errorType === ConnectionErrorType.TOKEN_EXPIRED

  const hasAuthErrorInBankFeeds = hasErrorInBankFeeds && isTokenExpired

  return {
    isXeroBankFeedLoading: isLoading,
    hasErrorInBankFeeds,
    hasAuthErrorInBankFeeds,
  }
}
