import { useCallback, useMemo, useState } from 'react'

import { CreateExternalAccountState } from '../CreateExternalAccount.types'
import { CreateExternalAccountContext } from './CreateExternalAccountContext'

interface CreateExternalAccountContextProviderProps {
  children: React.ReactNode
  initialState?: CreateExternalAccountState
}

export const CreateExternalAccountContextProvider = ({
  children,
  initialState,
}: CreateExternalAccountContextProviderProps) => {
  const [modalState, setModalState] = useState<
    CreateExternalAccountState | undefined
  >(initialState)

  const goToCreateExternalAccountState = useCallback(
    (state: CreateExternalAccountState | undefined) => {
      setModalState(state)
    },
    [setModalState]
  )

  const providerValue = useMemo(
    () => ({
      state: modalState,
      goToCreateExternalAccountState,
    }),
    [modalState, goToCreateExternalAccountState]
  )

  return (
    <CreateExternalAccountContext.Provider value={providerValue}>
      {children}
    </CreateExternalAccountContext.Provider>
  )
}
