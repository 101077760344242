import React from 'react'
import { CustomerAddressInput } from '@npco/mp-gql-types'

import { convertCountryCodeToName } from 'utils/converters'
import { getDataValue } from 'utils/settings'
import { component } from 'translations'

import {
  StyledDetailsLabel,
  StyledDetailsValue,
  StyledWrapper,
} from './AddressDetails.styled'

export interface AddressProps {
  data: CustomerAddressInput
}

interface EntryProps {
  label: string
  value?: string | null
}

export const DataRow = React.memo(({ label, value }: EntryProps) => (
  <StyledWrapper>
    <StyledDetailsLabel>{label}</StyledDetailsLabel>
    <StyledDetailsValue>{value}</StyledDetailsValue>
  </StyledWrapper>
))

export const AddressDetails = ({ data }: AddressProps) => {
  return (
    <div data-testid="user-details-list">
      <DataRow
        label={component.AddressDetails.country}
        value={convertCountryCodeToName(data?.country)}
      />
      <DataRow
        label={component.AddressDetails.address}
        value={getDataValue(data, 'street')}
      />
      <DataRow
        label={component.AddressDetails.suburb}
        value={getDataValue(data, 'suburb')}
      />
      <DataRow
        label={component.AddressDetails.state}
        value={getDataValue(data, 'state')}
      />
      <DataRow
        label={component.AddressDetails.postcode}
        value={getDataValue(data, 'postcode')}
      />
    </div>
  )
}
