import { gql } from '@apollo/client'

export const InvoiceCoreFields = gql`
  fragment InvoiceCoreFields on Invoice {
    __typename
    customer {
      attentionContact {
        businessName
        contactType
        contactUuid
        firstName
        lastName
        status
      }
      payerContact {
        businessName
        contactType
        contactUuid
        firstName
        lastName
        status
      }
      payerEmail
    }
    discount {
      config
      value
    }
    dueDate
    email {
      body
      enabled
      recipients {
        bcc
        cc
        sendMeCopy
      }
      subject
    }
    sms {
      enabled
      payerContactPhoneNumber
    }
    referenceNumber
    id
    items {
      catalogItem {
        id
        lastRevisionTime
        status
      }
      description
      discount {
        config
        value
      }
      id
      name
      orderIndex
      price
      quantity
      taxes {
        enabled
        name
        percent
      }
      unit
    }
    itemsApplyTax
    itemsTaxInclusive
    message
    paidAmount
    payerContactName
    requiredEmailUpdateBeforeSend
    siteSettings {
      invoice {
        discountsEnabled
      }
    }
    startDate
    sendSchedule {
      enabled
      sendDate
    }
    status
    title
  }
`
