import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  Heading,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { Formik, FormikHelpers } from 'formik'

import { useCompanyLogoS3Bucket } from 'hooks/useCompanyLogoS3Bucket'
import { useRemoveSiteLogo } from 'hooks/useRemoveSiteLogo'
import { translate } from 'utils/translations'
import { SettingsLeavingRoutePromptGuard } from 'components/RouteLeavingPromptGuard'
import { SpinnerWrapped } from 'components/Spinner'

import { useGetZellerInvoiceSettings } from '../hooks/useGetZellerInvoiceSettings'
import { useInvoiceSettingsAnalytics } from '../hooks/useInvoiceSettingsAnalytics'
import { useUpdateZellerInvoiceSettings } from '../hooks/useUpdateZellerInvoiceSettings'
import { LOGO, LOGO_FILE } from './SettingsGeneral.constants'
import { InvoiceSettingsGeneralFormFields } from './SettingsGeneral.types'
import {
  convertGeneralFormFieldsToGeneralSettingsInput,
  convertInvoiceSettingsToFormFields,
} from './SettingsGeneral.utils'
import { SettingsGeneralCompanyLogo } from './SettingsGeneralCompanyLogo'
import { generalSettingsSchema } from './SettingsGeneralForm/SettingsGeneral.schemas'
import { SettingsGeneralForm } from './SettingsGeneralForm/SettingsGeneralForm'

export const SettingsGeneral = () => {
  const entityUuid = useSelectedEntityUuid()
  const { invoiceSettings, updateInvoiceSettingsCache, isLoading } =
    useGetZellerInvoiceSettings()

  const { updateZellerInvoiceSettings, isUpdatingZellerInvoiceSettings } =
    useUpdateZellerInvoiceSettings()

  const { trackGeneralSettingsUpdated } = useInvoiceSettingsAnalytics()

  const { uploadCompanyLogoToS3Bucket } = useCompanyLogoS3Bucket({
    siteUuid: invoiceSettings?.id,
  })

  const { removeSiteLogo } = useRemoveSiteLogo()

  if (isLoading || !invoiceSettings) {
    return <SpinnerWrapped variant="top" />
  }

  const handleUploadLogo = async (values: InvoiceSettingsGeneralFormFields) => {
    const { [LOGO]: logoUrl, [LOGO_FILE]: logoFile } = values

    const hasChangedLogo = logoUrl !== invoiceSettings?.receipt?.logo
    const hasRemovedLogo = Boolean(invoiceSettings?.receipt?.logo && !logoUrl)

    if (!hasChangedLogo) {
      return
    }

    if (hasRemovedLogo) {
      await removeSiteLogo({
        variables: { entityUuid, siteUuid: invoiceSettings.id },
      })
      return
    }

    if (logoFile) {
      await uploadCompanyLogoToS3Bucket(logoFile)
    }
  }

  const handleOnSubmit =
    (initialValues: InvoiceSettingsGeneralFormFields) =>
    async (
      values: InvoiceSettingsGeneralFormFields,
      formikHelpers: FormikHelpers<InvoiceSettingsGeneralFormFields>
    ) => {
      const formattedValues =
        convertGeneralFormFieldsToGeneralSettingsInput(values)

      try {
        await handleUploadLogo(values)

        const result = await updateZellerInvoiceSettings(formattedValues)

        if (result) {
          trackGeneralSettingsUpdated(initialValues, values)

          updateInvoiceSettingsCache({
            ...invoiceSettings,
            address: { ...invoiceSettings.address, ...formattedValues.address },
            invoice: {
              ...invoiceSettings.invoice,
              ...formattedValues.invoice,
            },
            surchargesTaxes: invoiceSettings.surchargesTaxes
              ? {
                  ...invoiceSettings.surchargesTaxes,
                  ...formattedValues.surchargesTaxes,
                }
              : invoiceSettings.surchargesTaxes,
            receipt: invoiceSettings.receipt
              ? { ...invoiceSettings.receipt, logo: values[LOGO] || '' }
              : invoiceSettings.receipt,
          })

          formikHelpers.resetForm({ values })
          showSuccessToast(translate('page.invoicesSettings.successToast'))
        } else {
          showErrorToast(translate('page.invoicesSettings.errorToast'))
        }
      } catch (err) {
        showErrorToast(translate('page.invoicesSettings.errorToast'))
      }
    }

  const initialValues = convertInvoiceSettingsToFormFields(invoiceSettings)

  return (
    <Formik<InvoiceSettingsGeneralFormFields>
      initialValues={initialValues}
      onSubmit={handleOnSubmit(initialValues)}
      validationSchema={generalSettingsSchema}
    >
      <>
        <SettingsLeavingRoutePromptGuard />
        {isUpdatingZellerInvoiceSettings ? (
          <SpinnerWrapped variant="top" />
        ) : (
          <>
            <SettingsGeneralCompanyLogo />
            <Heading.H3 mt="40px" mb="24px">
              {translate(
                'page.invoicesSettings.general.settings.invoicesTitle'
              )}
            </Heading.H3>
            <SettingsGeneralForm />
          </>
        )}
      </>
    </Formik>
  )
}
