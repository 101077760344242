import {
  ReportLostAndReplaceCardErrorState,
  ReportLostAndReplaceCardStage,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { translate } from 'utils/translations'

export const getReportLostAndReplaceCardErrorPromptHeading = (
  state: ReportLostAndReplaceCardErrorState
): string => {
  switch (state.stage) {
    case ReportLostAndReplaceCardStage.ReportLostPhysicalCardError:
    case ReportLostAndReplaceCardStage.ReportLostVirtualCardError:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.reportLostCardError.heading'
      )

    case ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.ReplaceCardErrorAdmin.heading'
      )

    case ReportLostAndReplaceCardStage.ReplaceCardErrorManager:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.ReplaceCardErrorManager.heading'
      )

    default:
      return ''
  }
}

export const getReportLostAndReplaceCardErrorPromptDescription = (
  state: ReportLostAndReplaceCardErrorState
): string => {
  switch (state.stage) {
    case ReportLostAndReplaceCardStage.ReportLostPhysicalCardError:
    case ReportLostAndReplaceCardStage.ReportLostVirtualCardError:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.reportLostCardError.description'
      )

    case ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.ReplaceCardErrorAdmin.description'
      )

    case ReportLostAndReplaceCardStage.ReplaceCardErrorManager:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.ReplaceCardErrorManager.description'
      )

    default:
      return ''
  }
}

export const getReportLostAndReplaceCardErrorPromptSubDescription = (
  state: ReportLostAndReplaceCardErrorState
): string => {
  switch (state.stage) {
    case ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.ReplaceCardErrorAdmin.subDescription'
      )

    case ReportLostAndReplaceCardStage.ReplaceCardErrorManager:
      return translate(
        'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.ReplaceCardErrorManager.subDescription'
      )

    default:
      return ''
  }
}

export const getReportLostAndReplaceCardErrorPromptButtons = ({
  state,
  retryAction,
  closeAction,
  addCardAction,
}: {
  state: ReportLostAndReplaceCardErrorState | null
  retryAction: () => void
  closeAction: () => void
  addCardAction: () => void
}) => {
  switch (state?.stage) {
    case ReportLostAndReplaceCardStage.ReportLostPhysicalCardError:
    case ReportLostAndReplaceCardStage.ReportLostVirtualCardError:
      return {
        onClickPrimary: retryAction,
        primaryButtonLabel: translate('shared.tryAgain'),
      }

    case ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin:
      return {
        onClickPrimary: addCardAction,
        primaryButtonLabel: translate(
          'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardErrorPrompt.createCardButtonLabel'
        ),
        secondaryButtonLabel: translate('shared.close'),
        onClickSecondary: closeAction,
      }

    default:
      return {
        secondaryButtonLabel: translate('shared.dismiss'),
        onClickSecondary: closeAction,
      }
  }
}
