import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'
import { useHitEnter } from 'hooks/useHitEnter'
import { useLogout } from 'hooks/useLogout'
import { BUTTON_SIZE } from 'types/common'
import { Button } from 'components/Buttons/Button'
import { UnexpectedErrorMessage } from 'components/UnexpectedErrorMessage/UnexpectedErrorMessage'
import { shared } from 'translations'

import { StyledLogoWrapper } from './UnexpectedError.styled'

interface Props {
  onLogout?: () => void
}

export const UnexpectedError = ({ onLogout: onExternalLogout }: Props) => {
  const { logout } = useLogout()

  useHitEnter(logout)

  const onLogout = () => {
    logout()
    onExternalLogout?.()
  }

  return (
    <>
      <StyledLogoWrapper>
        <SvgIcon width="69" height="24">
          <LogoIcon data-testid="logo-zeller" />
        </SvgIcon>
      </StyledLogoWrapper>

      <UnexpectedErrorMessage>
        <Button onClick={onLogout} size={BUTTON_SIZE.LARGE} type="button">
          {shared.signOut}
        </Button>
      </UnexpectedErrorMessage>
    </>
  )
}
