import { useContext, useMemo } from 'react'

import { getIsValidCreatePaymentInstrumentAppState } from '../../../utils/utils'
import { MFAContext } from '../../MFAContext'

export const useCreatePaymentInstrumentMFAState = () => {
  const context = useContext(MFAContext)

  if (context === null) {
    throw new Error(
      'useCreatePaymentInstrumentMFAState can only be used within MFAContext'
    )
  }

  const { appState, hasRedirectedBackToApp, setHasRedirectedBackToApp } =
    context

  const createPaymentInstrumentState = useMemo(() => {
    if (!appState || !getIsValidCreatePaymentInstrumentAppState(appState)) {
      return null
    }

    return appState
  }, [appState])

  return {
    createPaymentInstrumentState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  }
}
