import { WatchQueryFetchPolicy } from '@apollo/client'

import { useTransactionImages } from 'hooks/useTransactionImages'
import { useUploadedImages } from 'hooks/useUploadedImages/useUploadedImages'

interface UseImageFetchProps {
  transactionUuid: string
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useFetchImage = ({
  transactionUuid,
  fetchPolicy = 'cache-first',
}: UseImageFetchProps) => {
  const { isLoadingImages, isDownloadingFiles, images } = useTransactionImages({
    transactionUuid,
    fetchPolicy,
  })

  const { transactionCombinedImages } = useUploadedImages({
    transactionUuid,
    fetchedImages: images,
  })

  return {
    isLoadingImages,
    isDownloadingFiles,
    transactionCombinedImages,
  }
}
