import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useTransactionsList } from 'hooks/useTransactionsList/useTransactionsList'
import { useTransactionsFilters } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsFilters'
import { useTransactionsLocalCache } from 'components/Filters/PillFilters/TransactionsFilters/useTransactionsLocalCache'

export type ContactContextType = {
  isAccountsFiltersVisible: boolean
  isPaymentsFiltersVisible: boolean
  isPaymentsFilterButtonVisible: boolean
  isPaymentsFilterButtonDisabled: boolean
  setIsAccountsFiltersVisible: Dispatch<React.SetStateAction<boolean>>
  togglePaymentsFiltersVisible: () => void
  setIsPaymentsFilterButtonVisible: Dispatch<React.SetStateAction<boolean>>
}

export const ContactContext = createContext<ContactContextType | undefined>(
  undefined
)

ContactContext.displayName = 'Contact Context'

interface ContactProviderProps {
  children: ReactNode | ReactNode[]
}

export const ContactProvider = ({ children }: ContactProviderProps) => {
  const [isAccountsFiltersVisible, setIsAccountsFiltersVisible] =
    useState(false)

  const { setIsFiltersVisible } = useTransactionsLocalCache(
    'contactTransactions'
  )

  const { filters, isFiltersSelected, isFiltersVisible } =
    useTransactionsFilters({
      key: 'contactTransactions',
    })

  const togglePaymentsFiltersVisible = useCallback(() => {
    setIsFiltersVisible(!isFiltersVisible)
  }, [isFiltersVisible, setIsFiltersVisible])
  const [isPaymentsFilterButtonVisible, setIsPaymentsFilterButtonVisible] =
    useState(false)

  const { isLoading: isPaymentsFilterButtonDisabled } = useTransactionsList({
    areFiltersSelected: isFiltersSelected,
    filterObject: filters,
  })
  const value = useMemo(
    () =>
      ({
        isPaymentsFiltersVisible: isFiltersVisible,
        isAccountsFiltersVisible,
        isPaymentsFilterButtonDisabled,
        isPaymentsFilterButtonVisible,
        setIsAccountsFiltersVisible,
        togglePaymentsFiltersVisible,
        setIsPaymentsFilterButtonVisible,
      } satisfies ContactContextType),
    [
      isFiltersVisible,
      isAccountsFiltersVisible,
      isPaymentsFilterButtonDisabled,
      isPaymentsFilterButtonVisible,
      togglePaymentsFiltersVisible,
      setIsAccountsFiltersVisible,
      setIsPaymentsFilterButtonVisible,
    ]
  )

  return (
    <ContactContext.Provider value={value}>{children}</ContactContext.Provider>
  )
}

export const useContactContext = () => {
  const context = useContext(ContactContext)

  if (!context) {
    throw new Error('useContactContext must be used within ContactContext')
  }

  return context
}
