import {
  BodyDefaultTypography,
  BodyXSmallTypography,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const LabelName = styled.span`
  ${BodyDefaultTypography};
  font-weight: 500;
`

export const LabelCategory = styled.span`
  ${BodyXSmallTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
`
