import { Flex } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { CardFormatOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { validateRequired } from 'utils/formValidation'

import { PhysicalCardRadioOption } from './PhysicalCardRadioOption'
import { VirtualCardRadioOption } from './VirtualCardRadioOption'

export const SelectCardFormatFields = () => {
  const [field, , handlers] = useField({
    name: 'cardFormat',
    validate: validateRequired,
  })

  return (
    <Flex flexDirection="column" mt="12px">
      <PhysicalCardRadioOption
        onChange={() => handlers.setValue(CardFormatOption.Physical)}
        isChecked={field.value === CardFormatOption.Physical}
        name="cardFormat"
      />
      <VirtualCardRadioOption
        onChange={() => handlers.setValue(CardFormatOption.Virtual)}
        isChecked={field.value === CardFormatOption.Virtual}
        name="cardFormat"
      />
    </Flex>
  )
}
