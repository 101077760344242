import styled from 'styled-components'

import { LogoCorner as Logo } from 'components/LogoCorner'
import { StyledLogoCornerWrapper } from 'components/LogoCorner/LogoCorner.styled'

export const ZellerLogo = styled(Logo)`
  ${StyledLogoCornerWrapper} {
    top: 32px;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.GREY_250};
  margin: 0;
`

export const TextWrapper = styled.div`
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  min-height: 56px;
  padding-top: 40px;
`
