import { gql } from '@apollo/client'
import { useDisableNavigation } from '@npco/ui-drawer-navigation'
import { NotesBasic } from '@npco/zeller-design-system'
import {
  useEditDebitCardTransactionNotesState,
  useGoToEditDebitCardTransactionNotes,
} from 'features/EditDebitCardTransactionNotes/edit-debit-card-transaction-notes-routing'

import { translate } from 'utils/translations'

import {
  TransactionNotesDebitCardAccountTransactionFragment,
  TransactionNotesDebitCardTransactionV2Fragment,
} from './TransactionNotes.generated'

interface Props {
  transaction:
    | TransactionNotesDebitCardTransactionV2Fragment
    | TransactionNotesDebitCardAccountTransactionFragment
}

export const TransactionNotes = ({ transaction }: Props) => {
  const { note, id } = transaction
  const { state } = useEditDebitCardTransactionNotesState()

  const { goToEditDebitCardTransactionNotes } =
    useGoToEditDebitCardTransactionNotes()

  useDisableNavigation({ shouldDisableNavigation: state !== null })

  return (
    <NotesBasic
      title={translate('page.transactionNotes.title')}
      note={note}
      onEdit={() =>
        goToEditDebitCardTransactionNotes({
          stage: 'edit',
          debitCardTransactionUuid: id,
          note: note ?? '',
        })
      }
      placeholder={translate('page.transactionNotes.placeholder')}
      addLabel={translate('shared.add')}
      editLabel={translate('shared.edit')}
      marginBottom="40px"
    />
  )
}

TransactionNotes.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransactionNotesDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
      note
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment TransactionNotesDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
      note
    }
  `,
}
