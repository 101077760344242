import { StyledModalWrapper } from 'components/ExportFileModalContent/ExportFileModalContent.styled'

import { ExportFileModalView } from './ExportFileModalView/ExportFileModalView'

interface Props {
  closeModal: () => void
  rerenderContent: () => void
  isLoading: boolean
  hasError: boolean
  hasLoaded: boolean
}

export const ExportFileModalContent = ({
  closeModal,
  rerenderContent,
  isLoading,
  hasError,
  hasLoaded,
}: Props) => {
  return (
    <StyledModalWrapper
      isShrinked={!isLoading && !hasError && hasLoaded}
      isExpanded={!isLoading && hasError}
    >
      <ExportFileModalView
        isLoading={isLoading}
        hasError={hasError}
        closeModal={closeModal}
        rerenderContent={rerenderContent}
      />
    </StyledModalWrapper>
  )
}
