import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ErrorMessageForm,
  InfoBox,
  InputSelectComboboxItemRenderProps,
  InputSelectComboboxStandard,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { BankAccountDetails } from 'hooks/paymentInstruments/PaymentInstruments.types'
import { translate } from 'utils/translations'
import {
  StyledAccountSelectItem,
  StyledContentWrapper,
} from 'pages/Transfer/AddModal/AddBankAccountModal/AddBankAccountModal.styled'
import { AccountFieldItem } from 'pages/Transfer/AddModal/fields/AccountField.types'
import { useAccountComboBox } from 'pages/Transfer/AddModal/hooks/useAccountComboBox'
import { AccountSelectItem } from 'pages/Transfer/ContactDropdown/AccountSelectItem'
import { contactTransferTranslations } from 'pages/Transfer/Transfer.i18n'
import {
  validateBankAccountNumber,
  validateBSBNumber,
} from 'forms/formViews/BankAccountFields/BankAccountFields.utils'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

const ROW_ITEM_HEIGHT_IN_PX = 64

const renderItemHandler = (
  renderProps: InputSelectComboboxItemRenderProps<AccountFieldItem>
) => (
  <StyledAccountSelectItem {...renderProps}>
    <StyledContentWrapper>
      <AccountSelectItem
        label={renderProps.item.label}
        subLabel1={renderProps.item.bsb}
        subLabel2={renderProps.item.account}
        value={renderProps.item.value}
      />
    </StyledContentWrapper>
  </StyledAccountSelectItem>
)
export interface AccountFieldsProps {
  isLinkedPaymentInstrument: boolean
  setIsLinkedPaymentInstrument: Dispatch<SetStateAction<boolean>>
  selectedContactName: string
  initialValues: BankAccountDetails
}

export const AccountFields = ({
  isLinkedPaymentInstrument,
  setIsLinkedPaymentInstrument,
  selectedContactName,
  initialValues,
}: AccountFieldsProps) => {
  const t = useTranslations(contactTransferTranslations)
  const {
    error,
    inputValue,
    items,
    name,
    onBlur,
    onChange,
    onClose,
    onInputChange,
    onInputClear,
    selectedItem,
    touched,
  } = useAccountComboBox()

  const { values, setFieldValue, resetForm } =
    useFormikContext<BankAccountDetails>()

  useEffect(() => {
    if (selectedItem) {
      resetForm()
      setFieldValue('bsb', selectedItem.bsb)
      setFieldValue('account', selectedItem.account)
      setFieldValue('name', selectedItem.label)
    } else {
      setFieldValue('bsb', initialValues.bsb)
      setFieldValue('account', initialValues.account)
    }
  }, [
    selectedItem,
    resetForm,
    setFieldValue,
    initialValues.bsb,
    initialValues.account,
  ])

  useEffect(() => {
    setIsLinkedPaymentInstrument(false)
  }, [values.bsb, values.account, setIsLinkedPaymentInstrument])

  const getFilterItems = useCallback(
    ({ label }: AccountFieldItem) => {
      return label.toUpperCase().includes(inputValue.trim().toUpperCase())
    },
    [inputValue]
  )

  return (
    <>
      <FormLine dataTestId="account-name-form-section">
        <Box mb="1.5rem">
          <InputSelectComboboxStandard
            inputValue={inputValue}
            filterItems={getFilterItems}
            // We don't fetch items here
            isLoading={false}
            shouldAutoFocus
            hasError={Boolean(touched && error)}
            label={t('accountNameLabel')}
            renderItem={renderItemHandler}
            rowItemHeight={ROW_ITEM_HEIGHT_IN_PX}
            items={items}
            name={name}
            onBlur={onBlur}
            onInputChange={onInputChange}
            onInputClear={onInputClear}
            selectedItem={selectedItem}
            onChange={onChange}
            onClose={onClose}
          />
          {touched && (
            <ErrorMessageForm hasError={Boolean(error)} errorMessage={error} />
          )}
        </Box>
      </FormLine>
      <FormLine
        dataTestId="account-info-form-section"
        mxContainer="-0.625rem"
        pxField="0.625rem"
      >
        <Box mb="1.5rem">
          <InputAdaptiveFieldWrapper
            name="bsb"
            maxLength={6}
            hasError={Boolean(isLinkedPaymentInstrument)}
            label={t('accountBsbLabel')}
            validate={validateBSBNumber}
          />
        </Box>

        <Box mb="1.5rem">
          <InputAdaptiveFieldWrapper
            name="account"
            hasError={Boolean(isLinkedPaymentInstrument)}
            label={t('accountNumberLabel')}
            validate={validateBankAccountNumber}
          />
        </Box>
      </FormLine>
      <ErrorMessageForm
        hasError={isLinkedPaymentInstrument}
        errorMessage={translate('errorMessages.accountAlreadyLinked')}
      />
      {!isLinkedPaymentInstrument && (
        <InfoBox>
          <InfoBox.Message>
            {translate('page.transfer.linkToContact', {
              contact: selectedContactName,
            })}
          </InfoBox.Message>
        </InfoBox>
      )}
    </>
  )
}
