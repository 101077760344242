import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { rvOnboardingMetadata } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { getFlowFlagsCombinedForm } from 'features/OnboardingApp/shared/utils/getFlowFlagsCombinedForm'

export const useCombinedOnboardingFormPhase2 = () => {
  const flags = useFeatureFlags()
  const flowFlagsCombinedForm = getFlowFlagsCombinedForm(
    rvOnboardingMetadata()?.flowFlags
  )
  const isUserInCombinedFlowForm =
    flowFlagsCombinedForm ?? flags.CombinedOnboardingFormABTesting
  const shouldShowCombinedFormForPhase2 =
    flags.CombinedOnboardingFormPhase2 && isUserInCombinedFlowForm
  const shouldSaveFlowFlags =
    flowFlagsCombinedForm === undefined && flags.CombinedOnboardingFormPhase2

  return { shouldShowCombinedFormForPhase2, shouldSaveFlowFlags }
}
