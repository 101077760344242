import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  ErrorMessageForm,
  SelectHeaderRenderProps,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'
import { UseComboboxStateChange } from 'downshift'
import { BusinessForm } from 'features/Contacts/Businesses/Business.form'
import { ContactSelectCombobox } from 'features/Contacts/components/ContactSelectCombobox/ContactSelectCombobox'
import { getSubContactPersonNames } from 'features/Contacts/Contact/ContactSections/SubContacts/AddSubContact/AddSubContact.utils'
import {
  ContactFormData,
  InputSelectComboBoxItem,
} from 'features/Contacts/Contacts.types'
import { getContactName, initialValues } from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { useContacts } from 'features/Contacts/hooks/useContacts/useContacts'
import { useContactsFilters } from 'features/Contacts/hooks/useContactsFilters/useContactsFilters'
import { PersonForm } from 'features/Contacts/People/Person.form'

import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'

import { translations } from './ContactUuidSelectField.i18n'
import { ContactUuidSelectHeader } from './ContactUuidSelectHeader/ContactUuidSelectHeader'

const getContactItem = (
  contact: ContactCoreFieldsFragment | CreateContact | CreateContactsContact
) => {
  const label = getContactName(contact) || ''

  return {
    id: contact.id,
    label,
    subLabel:
      contact?.contactType === ContactType.BUSINESS
        ? translations.DEFAULT.formBusinessLabel
        : '',
    value: label,
  }
}

export interface ContactUuidSelectFieldProps {
  label: string
  requiredValidationMessage: string
  unselectedValidationMesage: string
  onCreate: (contact: CreateContact) => void
  setContactUuid: (contactUuid: string) => void
  setValidationError: (validationError?: string) => void
  validationError?: string
  selectedItem: InputSelectComboBoxItem | null
  setSelectedItem: Dispatch<SetStateAction<InputSelectComboBoxItem | null>>
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
}

export const ContactUuidSelectField = ({
  label,
  requiredValidationMessage,
  unselectedValidationMesage,
  onCreate: handleOnCreate,
  setContactUuid,
  setValidationError,
  validationError,
  selectedItem,
  setSelectedItem,
  inputValue,
  setInputValue,
}: ContactUuidSelectFieldProps) => {
  const t = useTranslations(translations)

  const [formInitialValues, setFormInitialValues] =
    useState<ContactFormData>(initialValues)

  const { filters, name$ } = useContactsFilters()

  const {
    contacts,
    loading: isLoading,
    hasMore,
    loadMore,
  } = useContacts({
    filters,
    limit: 25,
    isNetworkOnly: true,
  })

  const {
    isModalOpen: isAddPersonModalOpen,
    openModal: openAddPersonModal,
    closeModal: closeAddPersonModal,
  } = useModalState()

  const {
    isModalOpen: isAddBusinessModalOpen,
    openModal: openAddBusinessModal,
    closeModal: closeAddBusinessModal,
  } = useModalState()

  // NOTE: clear validation message when either of the create contact modals is
  // opened, the error message is set when the select dropdown menu closes
  useEffect(() => {
    if (isAddBusinessModalOpen || isAddPersonModalOpen) {
      setValidationError(undefined)
    }
  }, [isAddBusinessModalOpen, isAddPersonModalOpen, setValidationError])

  const items: InputSelectComboBoxItem[] = useMemo(() => {
    return contacts
      .filter(
        (contact): contact is ContactCoreFieldsFragment =>
          Boolean(contact) && Boolean(contact?.id)
      )
      .map(getContactItem)
  }, [contacts])

  const handleInputClear = () => {
    setContactUuid('')
    setInputValue('')
    setSelectedItem(null)

    name$.next('')
  }

  const handleCreateContact = (contactType: ContactType) => {
    handleInputClear()

    if (contactType === ContactType.BUSINESS) {
      setFormInitialValues((formValues) => ({
        ...formValues,
        business: {
          ...formValues.business,
          businessName: inputValue,
        },
      }))

      openAddBusinessModal()

      return
    }

    const { firstName, lastName } = getSubContactPersonNames(inputValue)

    setFormInitialValues((formValues) => ({
      ...formValues,
      person: {
        ...formValues.person,
        firstName,
        lastName,
      },
    }))

    openAddPersonModal()
  }

  const handleOnContactCreate = (
    contact: CreateContact | CreateContactsContact
  ) => {
    setContactUuid(contact.id)

    const nextSelectedItem = getContactItem(contact)
    setSelectedItem(nextSelectedItem)

    handleOnCreate(contact)
  }

  const handleChange = (
    changes: SelectStateChangeProps<InputSelectComboBoxItem>
  ) => {
    if (changes.selectedItem?.id) {
      setContactUuid(changes.selectedItem.id)
    }
    setSelectedItem(changes.selectedItem)
  }

  const handleClose = (
    changes: UseComboboxStateChange<InputSelectComboBoxItem>
  ) => {
    // NOTE: when a user has an item, changes the input value and navigates away
    // from the menu causing the menu to close reset the input value to that of
    // the selected item
    if (
      changes.selectedItem &&
      changes.selectedItem.value !== changes.inputValue
    ) {
      setInputValue(changes.selectedItem.value)
    }

    if (changes.selectedItem && changes.inputValue) {
      setValidationError(undefined)
    }

    // NOTE: if no selected item and input value validate as selection required
    if (!changes.selectedItem && changes.inputValue) {
      setValidationError(unselectedValidationMesage)
    }

    // NOTE: if no selected item and no input value validate as required
    if (!changes.selectedItem && !changes.inputValue) {
      setValidationError(requiredValidationMessage)
    }
  }

  const handleInputChange = (
    changes: SelectStateInputChangeProps<InputSelectComboBoxItem>
  ) => {
    const value = changes.inputValue

    if (value === '') {
      setContactUuid('')
      setSelectedItem(null)
    }

    setInputValue(value)

    // NOTE: only fetch when no selected item or when current selected item
    // value does not match current input value
    if (!changes.selectedItem || changes.selectedItem.value !== value) {
      name$.next(value)
    }
  }

  return (
    <>
      <ContactSelectCombobox
        hasError={Boolean(validationError)}
        hasMore={hasMore}
        inputValue={inputValue}
        label={label}
        loadMore={loadMore}
        isLoading={isLoading}
        items={items}
        onChange={handleChange}
        onClose={handleClose}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        renderHeader={({ closeMenu }: SelectHeaderRenderProps) => (
          <ContactUuidSelectHeader
            closeMenu={closeMenu}
            onCreateContact={handleCreateContact}
          />
        )}
        selectedItem={selectedItem}
      />
      <ErrorMessageForm
        hasError={Boolean(validationError)}
        errorMessage={validationError}
      />
      {isAddBusinessModalOpen && (
        <BusinessForm
          closeModal={closeAddBusinessModal}
          initialValues={formInitialValues}
          isModalOpen
          onCreate={handleOnContactCreate}
          title={t('formBusinessTitle')}
        />
      )}
      {isAddPersonModalOpen && (
        <PersonForm
          closeModal={closeAddPersonModal}
          initialValues={formInitialValues}
          isModalOpen
          onCreate={handleOnContactCreate}
          title={t('formPersonTitle')}
        />
      )}
    </>
  )
}
