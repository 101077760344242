import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { EditSpendControlsState } from '../../EditSpendControls.types'

export const useGoToEditSpendControls = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToEditSpendControls = useCallback(
    (state: EditSpendControlsState | null) => {
      navigate(location.pathname, { state: { EditSpendControlsModal: state } })
    },
    [navigate, location.pathname]
  )

  return { goToEditSpendControls }
}
