import { ReactNode } from 'react'

import {
  StyledDescriptionLabel,
  StyledHeader,
  StyledLabelsContainer,
  StyledLabelsContent,
  StyledLabelsDivider,
} from './HorizontalCardLabels.styled'

export interface Props {
  heading?: ReactNode
  label1?: ReactNode
  label2?: ReactNode
}

export const HorizontalCardLabels = ({ heading, label1, label2 }: Props) => {
  return (
    <StyledLabelsContainer>
      <StyledHeader>{heading}</StyledHeader>
      <StyledLabelsContent>
        {label1 && <StyledDescriptionLabel>{label1}</StyledDescriptionLabel>}
        {label1 && label2 && <StyledLabelsDivider />}
        {label2 && <div>{label2}</div>}
      </StyledLabelsContent>
    </StyledLabelsContainer>
  )
}
