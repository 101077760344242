import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetLabelsQueryVariables = Types.Exact<{
  type: Types.InputMaybe<Types.LabelType>
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetLabelsQueryResponse = {
  __typename?: 'Query'
  getLabels: Array<{
    __typename?: 'Label'
    isEditable: boolean
    id: string
    labelText: string
    type: Types.LabelType
  } | null>
}

export const GetLabels = gql`
  query GetLabels($type: LabelType, $entityUuid: ID!) {
    getLabels(type: $type, entityUuid: $entityUuid) {
      isEditable
      id
      labelText
      type
    }
  }
` as unknown as TypedDocumentNode<
  GetLabelsQueryResponse,
  GetLabelsQueryVariables
>

/**
 * __useGetLabelsQuery__
 *
 * To run a query within a React component, call `useGetLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabelsQueryResponse,
    GetLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLabelsQueryResponse, GetLabelsQueryVariables>(
    GetLabels,
    options
  )
}
export function useGetLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabelsQueryResponse,
    GetLabelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLabelsQueryResponse, GetLabelsQueryVariables>(
    GetLabels,
    options
  )
}
export type GetLabelsQueryHookResult = ReturnType<typeof useGetLabelsQuery>
export type GetLabelsLazyQueryHookResult = ReturnType<
  typeof useGetLabelsLazyQuery
>
export type GetLabelsQueryResult = Apollo.QueryResult<
  GetLabelsQueryResponse,
  GetLabelsQueryVariables
>
