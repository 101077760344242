import { gql } from '@apollo/client'

export const UpdateZellerInvoiceSettings = gql`
  mutation UpdateZellerInvoiceSettings(
    $entityUuid: ID!
    $settings: UpdateZellerInvoiceSettingsInput!
  ) {
    updateZellerInvoiceSettings(entityUuid: $entityUuid, input: $settings)
  }
`
