import { object, string } from 'yup'

import { translate } from 'utils/translations'

import { INVOICE_TITLE_HEADER_MAX_CHARACTERS } from '../Invoice.constants'

export const titleSchemaErrors = {
  titleHeaderRequiredError: translate(
    'page.invoice.formSections.title.titleHeaderRequired'
  ),
  titleHeaderMaxLengthError: translate(
    'page.invoice.formSections.title.titleHeaderMaxLengthError',
    { titleLength: INVOICE_TITLE_HEADER_MAX_CHARACTERS }
  ),
}

export const titleOptionalSchema = object({
  header: string()
    .trim()
    .max(
      INVOICE_TITLE_HEADER_MAX_CHARACTERS,
      titleSchemaErrors.titleHeaderMaxLengthError
    ),
  message: string(),
})

export const titleRequiredSchema = object({
  header: string().trim().required(titleSchemaErrors.titleHeaderRequiredError),
})
