import type { Venue } from '../HlPosManage.types'
import type { PairingsAssignments } from './getPairingsAssignments'

export type Options = {
  siteId: string
  venueId: string
  pairingsAssignments: PairingsAssignments
  venuesById: VenuesById
  i18n: {
    venueAssignment: (variables: { venueName: string }) => string
    locationAssignment: (variables: {
      venueName: string
      locationName: string
    }) => string
  }
}

export type VenuesById = Record<string, Venue>

export const getSiteAssignmentLabel = ({
  siteId,
  venueId,
  pairingsAssignments,
  venuesById,
  i18n,
}: Options): string | undefined => {
  const sitePairingAssignment = pairingsAssignments.bySiteId[siteId]
  const isUnassigned = !sitePairingAssignment
  if (isUnassigned) {
    return undefined
  }

  const isAssignedToThisVenue = sitePairingAssignment.venueId === venueId
  if (isAssignedToThisVenue) {
    return undefined
  }

  const siteVenueId = sitePairingAssignment.venueId
  const siteVenue = venuesById[siteVenueId]
  const siteVenueName = siteVenue?.name ?? siteVenueId

  const siteLocationId = sitePairingAssignment.locationId
  const isAssignedToLocation = !!siteLocationId
  if (isAssignedToLocation) {
    const siteLocation = siteVenue
      ? siteVenue.locations?.find(({ id }) => id === siteLocationId)
      : undefined
    const siteLocationName = siteLocation?.name ?? siteLocationId
    return i18n.locationAssignment({
      venueName: siteVenueName,
      locationName: siteLocationName,
    })
  }

  return i18n.venueAssignment({ venueName: siteVenueName })
}
