import { useMemo } from 'react'

import { TransactionTotal } from '../../../DashboardPerformance.types'
import {
  getInnerPiePlaceholderData,
  getOuterPiePlaceholderData,
  getThisTimeLastWeekPerformanceChartData,
  getTodayPerformanceChartData,
  getTotalLastWeekChartData,
} from '../PerformanceChart.utils'
import { useTodayPerformance } from './useTodayPerformance'

interface Arguments {
  salesTotals: TransactionTotal[]
  disabled: boolean
  isLoading: boolean
}

export const useTodayPerformanceChartData = ({
  salesTotals,
  disabled,
  isLoading = true,
}: Arguments) => {
  const { salesThisTimeOneWeekAgo, totalSalesOneWeekAgo, todaySales } =
    useTodayPerformance({
      salesTotals,
    })

  const totalLastWeekData = useMemo(() => {
    if (isLoading) {
      return getOuterPiePlaceholderData(100)
    }

    if (disabled) {
      return getOuterPiePlaceholderData()
    }

    return getTotalLastWeekChartData()
  }, [disabled, isLoading])

  const thisTimeLastWeekData = useMemo(() => {
    if (isLoading) {
      return getOuterPiePlaceholderData(0)
    }

    if (disabled) {
      return getOuterPiePlaceholderData()
    }

    return getThisTimeLastWeekPerformanceChartData({
      salesThisTimeOneWeekAgo,
      totalSalesOneWeekAgo,
    })
  }, [isLoading, disabled, salesThisTimeOneWeekAgo, totalSalesOneWeekAgo])

  const todayData = useMemo(() => {
    if (isLoading) {
      return getOuterPiePlaceholderData(0)
    }

    if (disabled) {
      return getInnerPiePlaceholderData()
    }
    return getTodayPerformanceChartData({
      todaySales,
      totalSalesOneWeekAgo,
    })
  }, [isLoading, disabled, todaySales, totalSalesOneWeekAgo])

  return {
    totalLastWeekData,
    thisTimeLastWeekData,
    todayData,
    todaySales,
    totalSalesOneWeekAgo,
    salesThisTimeOneWeekAgo,
  }
}
