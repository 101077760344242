import { useMemo } from 'react'

import { mapAccountTransactionFiltersToApiFilters } from 'utils/banking/mapAccountTransactionsFiltersToApiFilters/mapAccountTransactionsFiltersToApiFilters'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

export const useCardSingleFilters = () => {
  const { areFiltersInDefaultState, ...rawFilters } = useAccountFilters()
  const filters = useMemo(
    () =>
      mapAccountTransactionFiltersToApiFilters({
        selectedAccountID: undefined,
        ...rawFilters,
      }),
    [rawFilters]
  )

  return { filters, areFiltersInDefaultState }
}
