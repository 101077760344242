import { BREAKPOINT, Ellipsize } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledPeopleDetails = styled.div`
  min-width: 0;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    align-items: center;
    flex-direction: row;
  }
`

export const StyledPeopleName = styled(Ellipsize)`
  flex-grow: 1;
  padding: 0 0.8rem 0 0;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    flex-grow: 0;
    width: 12.5rem;
  }
`

export const StyledBusinessListItemWrapper = styled(Ellipsize)`
  color: ${({ theme }) => theme.colors.GREY_550};
  padding: 0 0.8rem 0 0;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding: 0;
    width: 18rem;
  }
  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    padding: 0;
    width: 27rem;
  }
  @media screen and (min-width: ${BREAKPOINT.XL}px) {
    padding: 0;
    width: 33rem;
  }
`
