import {
  BodyDefaultTypography,
  ButtonGhost,
  DrawerBasic,
  Flex,
  StyledContentContainer,
  StyledDrawerBasic,
  StyledHeaderContainer,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const BottomDrawerWrapper = styled(DrawerBasic)`
  ${StyledDrawerBasic} {
    right: 0;
    bottom: 0;
    max-height: 75vh;
    height: auto;
    border-radius: 16px 16px 0 0;
    padding: 0;
    margin: 0;

    &.ReactModal__Content {
      transform: translateY(100%);
      transition: transform 0.3s cubic-bezier(0.5, 0.5, 0.25, 1);
    }

    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateY(100%);
      transition: transform 0.3s cubic-bezier(0.5, 0.5, 0.25, 1);
    }
  }

  ${StyledHeaderContainer} {
    height: 0;
    display: none;
  }

  ${StyledContentContainer} {
    padding: 0;
    margin: 0;
  }
`

export const BottomDrawerContent = styled(Flex)`
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
`

export const BottomDrawerOption = styled(ButtonGhost)`
  ${BodyDefaultTypography};
  text-align: center;
  outline: none;
  color: ${({ theme }) => theme.colors.BLACK_900};
  min-height: 76px;
  width: 100%;
  margin: 0;
  border-radius: unset;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_90};
  background: inherit;

  &:last-of-type {
    border-bottom: none;
  }
`
