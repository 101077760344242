import React, { useMemo } from 'react'
import { DebitCardTransactionStatusV2 } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  DownloadIcon,
  Flex,
  showErrorToast,
  useModalState,
} from '@npco/zeller-design-system'
import { MultiSelectTriggerRenderProps } from 'design-system/Components/MultiSelect'
import { SelectSize } from 'design-system/Components/Select'

import { mapAccountTransactionFiltersToApiFilters } from 'utils/banking/mapAccountTransactionsFiltersToApiFilters/mapAccountTransactionsFiltersToApiFilters'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

import { ExportDebitCardTransactionsModal } from './ExportDebitCardTransactionsModal'
import { DefaultFilterOptions } from './FiltersList'
import { filtersListMobileExportTransactionsItemTranslations } from './FiltersListMobileExportTransactionsItem.i18n'
import * as styled from './FiltersListMobileExportTransactionsItem.styled'
import { useValidateExportFilterConditions } from './hooks/useValidateExportFilterConditions'

type Props = Partial<MultiSelectTriggerRenderProps> & {
  defaultFilters: DefaultFilterOptions
}

export const FiltersListMobileExportTransactionsItem = React.forwardRef<
  HTMLLIElement,
  Props
>(({ defaultFilters, ...forwardedSelectItemProps }, ref) => {
  const { ...accountFilters } = useAccountFilters()
  const { shouldDisableExport, tooltipMessage } =
    useValidateExportFilterConditions()

  const t = useTranslations(filtersListMobileExportTransactionsItemTranslations)

  const filters = useMemo(
    () =>
      mapAccountTransactionFiltersToApiFilters({
        ...accountFilters,
        ...defaultFilters,
        // We only allow users to export Approved status.
        // We decided to handle this in the frontend.
        status: [DebitCardTransactionStatusV2.APPROVED],
      }),
    [accountFilters, defaultFilters]
  )

  const { isModalOpen, openModal, closeModal } = useModalState()

  const handleOpenModal = () => {
    if (shouldDisableExport) {
      showErrorToast(tooltipMessage)
    } else {
      openModal()
    }
  }

  return (
    <>
      <styled.SelectStandardItem
        data-testid="select-standard-item"
        $disabled={shouldDisableExport}
        className={SelectSize.Small}
        {...forwardedSelectItemProps}
        ref={ref}
        onClick={handleOpenModal}
        hasSelectedIndicator
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          role="button"
        >
          <styled.ExportLabel $disabled={shouldDisableExport}>
            {t('label')}
          </styled.ExportLabel>
          <styled.IconWrapper
            $disabled={shouldDisableExport}
            p="6px"
            alignItems="center"
            justifyContent="center"
          >
            <DownloadIcon />
          </styled.IconWrapper>
        </Flex>
      </styled.SelectStandardItem>
      <ExportDebitCardTransactionsModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        filters={filters}
      />
    </>
  )
})
