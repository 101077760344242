import { gql } from '@apollo/client'

export const AddSubcategory = gql`
  mutation AddSubcategory($entityUuid: ID!, $input: EntitySubcategoryInput!) {
    addEntitySubcategory(entityUuid: $entityUuid, input: $input)
  }
`

export const EditSubcategory = gql`
  mutation EditSubcategory(
    $entityUuid: ID!
    $subcategoryUuid: ID!
    $subcategory: String!
  ) {
    updateEntitySubcategory(
      entityUuid: $entityUuid
      subcategoryUuid: $subcategoryUuid
      subcategory: $subcategory
    )
  }
`

export const DeleteSubcategory = gql`
  mutation DeleteSubcategory($entityUuid: ID!, $subcategoryUuid: ID!) {
    removeEntitySubcategory(
      entityUuid: $entityUuid
      subcategoryUuid: $subcategoryUuid
    )
  }
`
