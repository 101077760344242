import { gql } from '@apollo/client'

import { InvoiceDrawerActivity } from '../InvoiceDrawerActivity/InvoiceDrawerActivity'
import { InvoiceDrawerContact } from '../InvoiceDrawerContact/InvoiceDrawerContact'
import { InvoiceDrawerDetails } from '../InvoiceDrawerDetails/InvoiceDrawerDetails'

export const GetInvoiceDrawer = gql`
  query GetInvoiceDrawer($entityUuid: ID!, $referenceNumber: String!) {
    getInvoice(entityUuid: $entityUuid, referenceNumber: $referenceNumber) {
      __typename
      id
      activities {
        ...InvoiceActivityFragment
      }
      ...InvoiceDetailsFragment
      ...InvoiceContactFragment
    }
  }

  ${InvoiceDrawerActivity.fragments.InvoiceActivity}
  ${InvoiceDrawerContact.fragments.InvoiceContact}
  ${InvoiceDrawerDetails.fragments.InvoiceDetails}
`
