import { AnchorBasic } from '@npco/zeller-design-system'

import { TERMS_CONDITIONS_URL } from 'const/externalLinks'
import { mobileDataServices } from 'translations'

import {
  StyledGeneralText,
  StyledListMainItem,
  StyledListNestedItem,
  StyledListRomanItem,
  StyledMainList,
  StyledNestedList,
  StyledRomanList,
} from './ActivateSimCardModal.styled'

export const MobileDataServices = () => {
  return (
    <StyledMainList>
      <StyledListMainItem>
        {mobileDataServices.list.first.header}
      </StyledListMainItem>
      <StyledNestedList>
        <StyledListNestedItem>
          {mobileDataServices.list.first.items.first}
        </StyledListNestedItem>
        <StyledListNestedItem>
          {mobileDataServices.list.first.items.second}&nbsp;
          <AnchorBasic href={TERMS_CONDITIONS_URL}>
            {mobileDataServices.list.first.items.link}
          </AnchorBasic>
        </StyledListNestedItem>
        <StyledListNestedItem>
          {mobileDataServices.list.first.items.third}
        </StyledListNestedItem>
        <StyledListNestedItem>
          {mobileDataServices.list.first.items.fourth}
        </StyledListNestedItem>
        <StyledRomanList>
          <StyledListRomanItem>
            {mobileDataServices.list.first.items.romanOne}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.first.items.romanTwo}
          </StyledListRomanItem>
        </StyledRomanList>
      </StyledNestedList>

      <StyledListMainItem>
        {mobileDataServices.list.second.header}
      </StyledListMainItem>
      <StyledNestedList>
        <StyledListNestedItem>
          {mobileDataServices.list.second.items.first}
        </StyledListNestedItem>
        <StyledListNestedItem>
          {mobileDataServices.list.second.items.second}
        </StyledListNestedItem>
        <StyledRomanList>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanOne}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanTwo}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanThree}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanFour}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanFive}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanSix}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanSeven}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.second.items.romanEight}
          </StyledListRomanItem>
        </StyledRomanList>
      </StyledNestedList>

      <StyledListMainItem>
        {mobileDataServices.list.third.header}
      </StyledListMainItem>
      <StyledNestedList>
        <StyledListNestedItem>
          {mobileDataServices.list.third.items.first}
        </StyledListNestedItem>
        <StyledListNestedItem>
          {mobileDataServices.list.third.items.second}
        </StyledListNestedItem>
        <StyledRomanList>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanOne}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanTwo}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanThree}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanFour}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanFive}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanSix}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanSeven}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanEight}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanNine}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.third.items.romanTen}
          </StyledListRomanItem>
        </StyledRomanList>
      </StyledNestedList>

      <StyledListMainItem>
        {mobileDataServices.list.fourth.header}
      </StyledListMainItem>
      <StyledGeneralText>
        {mobileDataServices.list.fourth.items.general}
      </StyledGeneralText>

      <StyledListMainItem>
        {mobileDataServices.list.fifth.header}
      </StyledListMainItem>
      <StyledNestedList>
        <StyledListNestedItem>
          {mobileDataServices.list.fifth.items.first}
        </StyledListNestedItem>
        <StyledRomanList>
          <StyledListRomanItem>
            {mobileDataServices.list.fifth.items.romanOne}
          </StyledListRomanItem>
          <StyledListRomanItem>
            {mobileDataServices.list.fifth.items.romanTwo}
          </StyledListRomanItem>
        </StyledRomanList>
        <StyledGeneralText>
          {mobileDataServices.list.fifth.items.infoOne}
        </StyledGeneralText>
        <StyledListNestedItem>
          {mobileDataServices.list.fifth.items.second}
        </StyledListNestedItem>
      </StyledNestedList>
    </StyledMainList>
  )
}
