import { useCallback, useState } from 'react'

import { usePreviewUrl } from 'hooks/usePreviewUrl/usePreviewUrl'

import { getUniqueId } from '../ModalUploadImage.utils'

export const useFileDrop = (imageUrl?: string) => {
  // NOTE: when this existing image is passed this could be an image data
  // url (local) or an s3 url (external)
  const [previewCacheId, setPreviewCacheId] = useState(getUniqueId())
  const [file, setFile] = useState<File>()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const [acceptedFile] = acceptedFiles

    if (acceptedFile) {
      setFile(acceptedFile)
      setPreviewCacheId(getUniqueId())
    }

    return new FileReader()
  }, [])

  const reset = useCallback(() => {
    setFile(undefined)
    setPreviewCacheId(getUniqueId())
  }, [])

  const {
    isLoading: isPreviewLoading,
    error: previewError,
    previewUrl,
  } = usePreviewUrl({ file, id: previewCacheId })

  return {
    file,
    fileType: file?.type,
    isPreviewError: Boolean(previewError),
    isPreviewLoading,
    onDrop,
    previewUrl: previewUrl || imageUrl || '',
    reset,
  }
}
