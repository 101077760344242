const IS_NON_NUMERIC_REGEX = /\D/g

const getIsNonNumeric = (text: string) => {
  const matches = text.match(IS_NON_NUMERIC_REGEX)

  return matches !== null
}

const weightsOfTfnNineDigits = [1, 4, 3, 7, 5, 8, 6, 9, 10]
const weightsOfTfnEightDigits = [10, 7, 8, 4, 6, 3, 5, 1]

// https://clearwater.com.au/code/tfn
const getIsValidTFN = (maybeTfn: string | undefined) => {
  if (!maybeTfn) {
    return false
  }

  const trimmedTfn = maybeTfn.replace(/\s/g, '')
  const isNonNumeric = getIsNonNumeric(trimmedTfn)

  if (isNonNumeric || (trimmedTfn.length !== 9 && trimmedTfn.length !== 8)) {
    return false
  }

  const weightsOfTfnDigits =
    trimmedTfn.length === 9 ? weightsOfTfnNineDigits : weightsOfTfnEightDigits

  let sum = 0
  weightsOfTfnDigits.forEach((weight, index) => {
    const tfnDigit = parseInt(trimmedTfn[index], 10)
    sum += weight * tfnDigit
  })
  return sum % 11 === 0
}

export const validateTaxFileNumber = (value: string | undefined) => {
  if (!getIsValidTFN(value)) {
    return 'Unable to validate TFN. Please check your digits and try again.'
  }

  return undefined
}
