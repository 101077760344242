import { Flex, SkeletonH5 } from '@npco/zeller-design-system'

import { LoaderList } from 'components/LoaderList'

type DebitCardTransactionListSkeletonProps = {
  showMobileStyle?: boolean
}

export const DebitCardTransactionListSkeleton = ({
  showMobileStyle = false,
}: DebitCardTransactionListSkeletonProps) => {
  return (
    <>
      <Flex pb="12px">
        <SkeletonH5 width="7.5rem" />
      </Flex>
      <LoaderList showMobileStyle={showMobileStyle} />
    </>
  )
}
