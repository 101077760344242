import { gql } from '@apollo/client'

export const UnlinkContactWithDebitCardAccountTransactionSender = gql`
  mutation UnlinkContactWithDebitCardAccountTransactionSender(
    $contactUuid: ID!
    $senderUuid: ID!
    $entityUuid: ID!
  ) {
    unlinkContactWithDebitCardAccountTransactionSender(
      contactUuid: $contactUuid
      senderUuid: $senderUuid
      entityUuid: $entityUuid
    )
  }
`
