import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import { Box, COLOR, Typography } from '@npco/zeller-design-system'

import { selectCardTypeFormTranslations } from '../SelectCardTypeForm.i18n'

export const ExpenseCardDescription = () => {
  const t = useTranslations(selectCardTypeFormTranslations)
  const flags = useFeatureFlags()

  return (
    <Box mr="20px">
      {t('expenseCardOptionDesc')}
      {!flags.CorpCardsExistingEntities && (
        <>
          <br />
          <Typography variant="heading-sm" color={COLOR.BLACK} component="div">
            {t('expenseCardOptionDescFreeTrailEnds')}
          </Typography>
        </>
      )}
    </Box>
  )
}
