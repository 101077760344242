import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'
import isMobile from 'ismobilejs'

import SetupFlowAppDownloadLarge from 'assets/images/setup-flow/setup-flow-app-download-large.png'
import SetupFlowAppDownloadMedium from 'assets/images/setup-flow/setup-flow-app-download-medium.png'
import SetupFlowAppDownloadSmall from 'assets/images/setup-flow/setup-flow-app-download-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { MainContent } from './MainContent/MainContent'

const deps = {
  isMobile,
}
export { deps as SetupFlowModalPagesDownloadAppStageContentDeps }

export const DownloadAppStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const { goToStage } = useSetupFlowModal()

  const nextStage = deps.isMobile(window.navigator).tablet
    ? 'FeaturePaymentsStage'
    : 'TapToPayStage'

  return (
    <Tutorial.TutorialLayout
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage(nextStage)}
            onSecondaryButtonClick={() => goToStage('SetupFlowStartStage')}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowAppDownloadSmall}
          imageMedium={SetupFlowAppDownloadMedium}
          imageLarge={SetupFlowAppDownloadLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
