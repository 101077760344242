import { translate } from 'utils/translations'

export const thisWeekLabel = translate(
  'component.datePicker.buttonLabels.thisWeek'
)
export const lastWeekLabel = translate(
  'component.datePicker.buttonLabels.lastWeek'
)
export const thisMonthLabel = translate(
  'component.datePicker.buttonLabels.thisMonth'
)
export const lastMonthLabel = translate(
  'component.datePicker.buttonLabels.lastMonth'
)
