import { ApolloError, makeVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'

import { GetCustomerDetails_getCustomer as CustomerDetails } from 'types/gql-types/GetCustomerDetails'
import { SiteCache, SiteTypeOperation, SiteWithType } from 'types/site'

export const rvSiteDetails = makeVar<SiteCache | null>(null)

export const rvSiteFetchError = makeVar<ApolloError | null>(null)

export const rvSelectedSite = makeVar('')

export const rvSelectedUser = makeVar('')

export const rvNewSites = makeVar([] as SiteCache[])

export const rvNewUserType = makeVar<CustomerRole | null>(null)

export const rvCustomerSiteOperation = makeVar<SiteTypeOperation | null>(null)

export const rvCurrentUserSites = makeVar<SiteCache[]>([])

export const rvUpdatedUserDetails = makeVar<Partial<CustomerDetails>>({})

export const rvSiteTypeList = makeVar<SiteWithType[]>([])
