import { PillList } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { StyledTagsPlaceholder } from './Tags.styled'

interface Props {
  tags: string[]
  placeholder: React.ReactNode
}

export const TagsDisplay = ({ tags, placeholder }: Props) => {
  if (tags.length === 0) {
    return <StyledTagsPlaceholder>{placeholder}</StyledTagsPlaceholder>
  }

  return (
    <PillList
      pills={tags}
      maxWidth="26.75rem"
      editButtonText={translate('shared.edit')}
      closeIconAlt={translate('shared.close')}
    />
  )
}
