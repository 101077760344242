import { Box } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { ReactComponent as Car } from 'assets/svg/car.svg'
import { ReactComponent as Passport } from 'assets/svg/passport.svg'
import { IDOptionButton } from 'components/IDOptionButton'
import { SelfieIDOption } from 'components/SelfieVerification/SelfieVerification.types'

export const SelfieIDOptionFields = () => {
  const [field, , handlers] = useField<SelfieIDOption | ''>({
    name: 'idType',
  })

  return (
    <Box pt="4px" pb="40px">
      <IDOptionButton
        isSelected={field.value === SelfieIDOption.DriversLicence}
        isDisabled={false}
        onClick={() => handlers.setValue(SelfieIDOption.DriversLicence)}
        text="Drivers licence"
        description={null}
        icon={<Car />}
      />
      <IDOptionButton
        isSelected={field.value === SelfieIDOption.Passport}
        isDisabled={false}
        onClick={() => handlers.setValue(SelfieIDOption.Passport)}
        text="Passport"
        description={null}
        icon={<Passport />}
      />
    </Box>
  )
}
