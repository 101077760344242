import { useTranslations } from '@npco/utils-translations'

import { ReactComponent as CardsIcon } from 'assets/svg/graphics/cards.svg'
import { PlaceHolder } from 'components/Placeholders/NoInitialResults/PlaceHolder'

import { translations } from './AccountCards.EmptyClosed.i10n'

export const EmptyClosed = () => {
  const translate = useTranslations(translations)
  return (
    <PlaceHolder
      icon={<CardsIcon width={180} />}
      title={translate('title')}
      description={translate('description')}
    />
  )
}
