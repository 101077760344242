import {
  Box,
  ButtonGhost,
  INPUT_SIZE,
  StyledFloatingInputLabel,
  StyledInputAdaptiveBasic,
  StyledInputAdaptiveBasicWrapper,
  StyledInputPlaceholder,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { InvoiceInputLabel } from '../../../../InvoiceDeliveryAccordion.styled'

export const InvoiceEmailInputFieldWrapper = styled(Box)<{
  isFloatingLabel: boolean
  $hasValue: boolean
  $size: INPUT_SIZE
}>`
  width: 100%;

  ${StyledInputAdaptiveBasicWrapper} {
    flex-wrap: wrap;
    gap: 8px;

    ${StyledInputAdaptiveBasic} {
      flex: 1;
    }
  }

  ${StyledFloatingInputLabel} {
    ${InvoiceInputLabel}
  }

  ${StyledInputPlaceholder} {
    ${({ $hasValue }) => $hasValue && { display: 'none' }};
  }
`

export const LinkEmailButton = styled(ButtonGhost)`
  justify-content: flex-start;
`
