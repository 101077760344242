import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: auto;
`
