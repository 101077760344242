import { ModalBasic } from '@npco/zeller-design-system'
import { Field, Formik } from 'formik'

import { translate } from 'utils/translations'
import { StyledForm } from 'pages/GlobalModals/GlobalModals.styled'
import { getDefaultNewAccountColor } from 'components/AccountFields/PersonaliseAccountFields/AccountAvatarField/AccountAvatarField.utils'
import { FormCheckbox } from 'components/Input/Checkbox'
import { MultiStageModal } from 'components/MultiStageModal'

import { CreateAccountValues } from '../../AccountNameStage.types'
import { CreateAccountFields } from './fields/CreateAccountFields'
import { useCreateAccountForm } from './hooks/useCreateAccountForm'

interface CreateAccountFormProps {
  dataTestId?: string
}

export const CreateAccountForm = ({ dataTestId }: CreateAccountFormProps) => {
  const { handleSubmit, isLoading, onCancel } = useCreateAccountForm()

  return (
    <Formik<CreateAccountValues>
      initialValues={{
        name: '',
        isTermsAndConditionsChecked: false,
        accountColour: getDefaultNewAccountColor(),
      }}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <StyledForm data-testid={dataTestId}>
          <ModalBasic.Body hasDropShadow={false}>
            <CreateAccountFields />
          </ModalBasic.Body>
          <ModalBasic.Body canScroll={false} marginBottom={12}>
            <Field
              component={FormCheckbox}
              type="checkbox"
              label={translate(
                'page.createAccountModal.termsAndConditionsAgree'
              )}
              name="isTermsAndConditionsChecked"
              validate={(value: boolean) => !value}
            />
          </ModalBasic.Body>

          <MultiStageModal.FormButtons
            onCancel={onCancel}
            backButtonLabel={translate('shared.cancel')}
            nextButtonLabel={translate('page.createAccountModal.modalTitle')}
            isLoading={isLoading}
            nextButtonDisabled={!values.isTermsAndConditionsChecked}
          />
        </StyledForm>
      )}
    </Formik>
  )
}
