import { AreaChart } from 'components/Charts/AreaChart/AreaChart'
import { ChartData } from 'components/Charts/Charts.types'

import { yAxisTickFormatter } from '../ReportsChart.utils'
import { xAxisTickFormatter } from './TimeOfDayChart.utils'
import { TooltipContent } from './TooltipContent/TooltipContent'

interface Props {
  data: ChartData
  hasNoInitialResults: boolean
}

export const TimeOfDayChart = ({ data, hasNoInitialResults }: Props) => {
  return (
    <AreaChart
      data={data}
      disabled={hasNoInitialResults}
      height={230}
      tooltipContent={TooltipContent}
      width="100%"
      xAxisProps={{
        tickMargin: 4,
        interval: 3,
      }}
      xAxisTickFormatter={xAxisTickFormatter}
      yAxisTickFormatter={yAxisTickFormatter}
    />
  )
}
