import gql from 'graphql-tag'

export const GetOverview = gql`
  query GetOverview($entityUuid: ID!, $filter: TransactionFilterInput!) {
    getTransactionTotalsBigInt(
      entityUuid: $entityUuid
      filter: $filter
      totalsType: HOURLY
    ) {
      period
      totalAmount
      countPurchases
      countRefunds
      purchaseAmount
      surchargeAmount
      tipAmount
      taxAmounts {
        name
        amount
      }
      feeAmount
      refundAmount
      declinedAmount
    }
  }
`
