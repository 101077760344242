import { CustomValidator } from 'types/common'
import { errorMessages } from 'translations'

export const validateAddressLineWithPoBox: CustomValidator<string> = (
  value
) => {
  if (!value) {
    return undefined
  }

  const hasPoBox =
    /^(pob(ox)?|postofficebox)$/gi.test(value.replace(/[^A-z]/gi, '')) ||
    /\b\w*pobox\w*\b/gi.test(value.replace(/[^A-z]/gi, ''))

  if (hasPoBox) {
    return errorMessages.poBoxIncludedAddressLine
  }

  return undefined
}

const PO_BOX_POSTCODE_BOUNDARIES = [
  [1000, 1999],
  [200, 299],
  [8000, 8999],
  [9000, 9999],
  [5800, 5999],
  [6800, 6999],
  [7800, 7999],
  [900, 999],
]

export const validatePostcodeWithPoBox: CustomValidator<string | number> = (
  value
) => {
  const parsedValue = Number(value)

  if (Number.isNaN(parsedValue)) {
    return errorMessages.invalidPostcode
  }

  const isInvalid = PO_BOX_POSTCODE_BOUNDARIES.some(
    (boundries) => parsedValue >= boundries[0] && parsedValue <= boundries[1]
  )

  if (isInvalid) {
    return errorMessages.poBoxIncludedPostcode
  }

  return undefined
}
