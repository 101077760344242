import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { Condition } from 'components/Condition/Condition'

import { useAddCardState } from '../../hooks/useAddCardState'
import { LandingPageForm } from './components/LandingPageForm/LandingPageForm'

export const LandingPageStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition shouldShow={state?.stage === AddCardStage.LandingPageStage}>
      <LandingPageForm dataTestId="landing-page-stage" />
    </Condition>
  )
}
