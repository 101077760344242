import { TransferZellerDropdown } from 'pages/Transfer/FromDropdown/TransferZellerDropdown'
import {
  StyledDropdownWrapper as DropdownWrapper,
  StyledLabelFrom as Label,
} from 'pages/Transfer/Transfer.styled'
import { page } from 'translations'

import { validateAccountFrom } from '../Transfer.validators'

export const TransferDropdownFrom = () => {
  return (
    <DropdownWrapper data-testid="transfer-dropdown-from">
      <Label htmlFor="from">{page.transfer.from}</Label>
      <TransferZellerDropdown
        name="from"
        validateAccounts={validateAccountFrom}
      />
    </DropdownWrapper>
  )
}
