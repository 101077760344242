import { useCallback } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useLocationState } from '@npco/utils-routing'
import { z } from 'zod'

import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'
import { ROOT } from 'const/routes'

const IsFirstOnboardingSchema = z.object({
  isFirstOnboarding: z.boolean().optional(),
})

export const useProductTour = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const state = useLocationState(IsFirstOnboardingSchema)
  const { updateProductTourStatus } = useCustomerProductTourStatus()
  const { userData } = useLoggedInUser()

  const isFirstOnboarding = state?.isFirstOnboarding

  const turnOffWelcomeModal = useCallback(async () => {
    const isSuccessful = await updateProductTourStatus({
      showAdminMerchantPortalWelcome: false,
    })

    if (isSuccessful && isFirstOnboarding) {
      navigate(pathname, {
        state: {
          isFirstOnboarding: false,
        },
      })
    }
  }, [updateProductTourStatus, isFirstOnboarding, navigate, pathname])

  const isAdminKycPath = Boolean(
    matchPath(`${ROOT.ORGS.ORG().KYC.path}/*`, pathname)
  )

  const shouldShowAdminWelcome = Boolean(
    !isAdminKycPath &&
      (isFirstOnboarding ||
        userData?.productTourStatus?.showAdminMerchantPortalWelcome)
  )

  return {
    shouldShowAdminWelcome,
    turnOffWelcomeModal,
  }
}
