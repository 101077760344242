import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { useEditSpendControlsState } from 'features/EditSpendControls/edit-spend-controls-routing'

import { getCustomerDisplayName } from 'utils/customers'
import dayjs from 'utils/dayjs'
import {
  CustomerNameQuery as CustomerNameQueryResponse,
  CustomerNameQueryVariables,
} from 'types/gql-types/CustomerNameQuery'

import { lastModifiedTranslations } from '../../LastModified.i18n'
import { CustomerNameQuery } from './graphql/CustomerNameQuery'

export const useVelocityControlLastModified = () => {
  const entityUuid = useSelectedEntityUuid()
  const { state } = useEditSpendControlsState()
  const t = useTranslations(lastModifiedTranslations)

  const customerUuid = state?.modifiedBy?.actingCustomerUuid || ''
  const updatedAt = state?.modifiedBy?.updatedAt

  const { data } = useQuery<
    CustomerNameQueryResponse,
    CustomerNameQueryVariables
  >(CustomerNameQuery, {
    variables: { entityUuid, customerUuid },
    skip: !customerUuid,
  })

  const lastModifiedText = useMemo(() => {
    if (!data?.getCustomer || !updatedAt) {
      return null
    }

    const updatedAtDate = dayjs(updatedAt).local()
    const { firstname, lastname } = data.getCustomer

    return t('lastModifiedSummary', {
      dayMonthAndYear: updatedAtDate.format('D MMMM YYYY'),
      time: updatedAtDate.format('h:mmA'),
      customerName: getCustomerDisplayName({ firstname, lastname }),
    })
  }, [data, updatedAt, t])

  return { lastModifiedText }
}
