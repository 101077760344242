import { ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
  gap: 0 8px;
`
export const Action = styled(ButtonLink)`
  ${({ theme }) => theme.typography['body-sm']}
`
