import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type UseCardStatusDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
}

export const UseCardStatusDebitCardV2FragmentDoc = gql`
  fragment UseCardStatusDebitCardV2Fragment on DebitCardV2 {
    status
  }
` as unknown as TypedDocumentNode<UseCardStatusDebitCardV2Fragment, undefined>
