import { SectionHeadingTitle } from './SectionHeading.styled'

export interface SectionHeadingProps {
  children: React.ReactNode
  id?: string
}

export const SectionHeading = ({ children, id }: SectionHeadingProps) => {
  return <SectionHeadingTitle id={id}>{children}</SectionHeadingTitle>
}
