import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGetEntityAddressTimezone } from 'hooks/useGetEntityAddressTimezone'
import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CreateSpendControlsState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { useAddCardState } from '../../../../../hooks/useAddCardState'
import { setCardSpendControlStageTranslations } from '../../SetCardSpendControlStage.i18n'
import { SetSpendControlFormValues } from '../SetSpendControlForm.types'

export const useSetSpendControlForm = () => {
  const { goToAddCardStage } = useGoToAddCardStage()
  const { state } = useAddCardState<CreateSpendControlsState>()
  const t = useTranslations(setCardSpendControlStageTranslations)
  const {
    timezone: entityAddressTimezone,
    isLoading: isLoadingEntityAddressTimezone,
  } = useGetEntityAddressTimezone({ skip: !state })

  const submitSpendControlStage = useCallback(
    ({
      amountLimit,
      maxTransactionValue,
      velocityWindow,
    }: SetSpendControlFormValues) => {
      if (!state) {
        ErrorLogger.report('[Banking] set card spend control stage', {
          submitActionErrorMessage: t('submitActionErrorMessage'),
        })
        showErrorToast()
        return
      }

      if (!velocityWindow) {
        ErrorLogger.report('[Banking] set card spend control stage', {
          submitActionErrorMessage: t(
            'submitActionVelocityWindowMissingErrorMessage'
          ),
        })
        showErrorToast()
        return
      }

      goToAddCardStage({
        ...state,
        stage: AddCardStage.CreateSelectCardDesignStage,
        velocityControl: {
          amountLimit,
          maxTransactionValue,
          velocityWindow,
        },
        entityAddressTimezone,
      })
    },
    [goToAddCardStage, state, t, entityAddressTimezone]
  )

  const goBackToSetCardSettingsStage = useCallback(() => {
    if (!state) {
      ErrorLogger.report('[Banking] set card spend control stage', {
        submitActionErrorMessage: t('backActionErrorMessage'),
      })
      showErrorToast()
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSetCardSettingsStage,
    })
  }, [goToAddCardStage, state, t])

  return {
    goBackToSetCardSettingsStage,
    submitSpendControlStage,
    initialValues: {
      amountLimit: state?.velocityControl?.amountLimit ?? '',
      maxTransactionValue: state?.velocityControl?.maxTransactionValue ?? '',
      velocityWindow: state?.velocityControl?.velocityWindow,
    },
    accountId: state?.account?.id,
    isLoadingEntityAddressTimezone,
    entityAddressTimezone,
  }
}
