import { TooltipButton } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { StyledChip } from './SalesOverview.styled'

interface Props {
  volume: number
}

export const SummaryVolume = ({ volume }: Props) => {
  const tooltipText = translate(
    'page.dashboard.payments.sales.transactionsTooltip',
    {
      transactions: volume.toLocaleString(),
    }
  )

  return (
    <TooltipButton
      tooltipIcon={<StyledChip label={volume} />}
      label={tooltipText}
      placement="bottom"
    >
      {tooltipText}
    </TooltipButton>
  )
}
