import { gql } from '@apollo/client'

export const GetXeroOrganisations = gql`
  query GetXeroOrganisations(
    $entityUuid: ID!
    $connectionType: ConnectionType = XERO_BANKFEEDS
  ) {
    organisations: getXeroOrganisations(
      entityUuid: $entityUuid
      connectionType: $connectionType
    ) {
      id
      name
    }
  }
`
