import { useMemo } from 'react'
import { SkeletonSvgBase } from '@npco/zeller-design-system'

type Props = React.SVGProps<SVGImageElement> & {
  height: number
  width: number
}

export const BaseLoadingYAxisChartTick = ({
  x,
  y,
  width,
  height,
  ...rest
}: Props) => {
  const rectX = useMemo(() => {
    const parsedX = typeof x === 'number' ? x : Number.parseInt(x ?? '0', 10)

    return parsedX - width
  }, [width, x])

  const rectY = useMemo(() => {
    const parsedY = typeof y === 'number' ? y : Number.parseInt(y ?? '0', 10)

    return parsedY - height / 2
  }, [height, y])

  return (
    <SkeletonSvgBase
      x={rectX}
      y={rectY}
      width={width}
      height={height}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  )
}
