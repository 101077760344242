import { useAccountFilterTags } from 'layouts/AccountLayout/hooks/useAccountFilterTags/useAccountFilterTags'
import { useGetCategoriesWithSubcategories } from 'layouts/AccountLayout/hooks/useGetCategoriesWithSubcategories/useGetCategoriesWithSubcategories'
import { DefaultFilterOptions } from 'components/FiltersList/FiltersList'
import { useFiltersList } from 'components/FiltersList/useFiltersList/useFiltersList'

import {
  alwaysVisibleFilters,
  filters,
  mobileFiltersWithExport,
} from './useAccountFiltersList.constants'
import {
  mapFilterToPillComponent,
  mapFilterToSelectElement,
  mapMobileFilter,
} from './useAccountFiltersList.utils'

interface UseAccountFiltersListProps {
  defaultFilters: DefaultFilterOptions
  skip?: boolean
  hideContactsFilter?: boolean
}

export const useAccountFiltersList = ({
  defaultFilters,
  skip = false,
  hideContactsFilter = false,
}: UseAccountFiltersListProps) => {
  const availableTags = useAccountFilterTags({ skip })
  const categories = useGetCategoriesWithSubcategories({ skip })

  const { visibleFilters, hiddenFilters, onSelect } = useFiltersList({
    filters,
    alwaysVisibleFilters,
  })

  const filteredVisibleFilters = hideContactsFilter
    ? visibleFilters.filter((filter) => filter !== 'Contact')
    : visibleFilters

  const selectItems = hiddenFilters.map(mapFilterToSelectElement)
  const mappedVisibleFilters = filteredVisibleFilters.map(
    mapFilterToPillComponent(availableTags, categories)
  )

  const mobileFilters = mobileFiltersWithExport.map(
    mapMobileFilter(availableTags, categories, defaultFilters)
  )

  return {
    selectItems,
    visibleFilters: mappedVisibleFilters,
    onSelect,
    mobileFilters,
  }
}
