import { SimBillingStatus } from '@npco/mp-gql-types'

export enum SimBillingDisplayStatus {
  ACTIVE = 'ACTIVE',
  OVERDUE = 'OVERDUE',
  CANCEL_PENDING = 'CANCEL_PENDING',
  CANCELLED = 'CANCELLED',
}

export type SimBillingStatusMap = {
  [key in SimBillingStatus]: SimBillingDisplayStatus | null
}

export type SimBillingStatusMessageMap = {
  [key in SimBillingDisplayStatus]: string
}

export type SimBillingStatusColorMap = {
  [key in SimBillingDisplayStatus]?: string
}
