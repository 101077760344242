import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { rvUpdatedDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

export type DebitCardTransaction =
  | DebitCardTransactionV2
  | DebitCardTransactionV3

export type PartialTransactionUpdateType = Partial<
  Omit<DebitCardTransaction, 'type' | 'status' | '__typename'>
>

export const useUpdateDebitCardTransactionRv = () => {
  const updateTransactionRv = ({
    transactionUuid,
    update,
  }: {
    transactionUuid: string
    update: PartialTransactionUpdateType
  }) => {
    const updatedDebitCardTransactions = rvUpdatedDebitCardTransactions()
    const transactionInRv = updatedDebitCardTransactions.find(
      (transaction) => transaction.id === transactionUuid
    )

    if (transactionInRv === undefined) {
      return
    }

    const newUpdatedDebitCardTransactions = updatedDebitCardTransactions.map(
      (transaction) => {
        if (transaction.id !== transactionUuid) {
          return transaction
        }

        return {
          ...transaction,
          ...update,
        }
      }
    )

    rvUpdatedDebitCardTransactions(newUpdatedDebitCardTransactions)
  }

  return { updateTransactionRv }
}
