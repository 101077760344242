import { InterZellerMediumFont } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ListItem, ListItemProps } from './ListItem'

const ListItemKey = styled.div`
  ${InterZellerMediumFont}
`

const ListItemValue = styled.div``

export const ListItemKeyValue = ({
  left,
  right,
  ...forwardListItemProps
}: ListItemProps) => (
  <ListItem
    left={<ListItemKey>{left}</ListItemKey>}
    right={<ListItemValue>{right}</ListItemValue>}
    {...forwardListItemProps}
  />
)
