import { createTranslations } from '@npco/utils-translations'

export const categoryDrawerTranslations = createTranslations({
  uncategorisedTitle: 'You have uncategorised transactions',
  uncategorisedDescription:
    'Go to your transactions and add a category, so we can help you populate them in the correct areas in this report.',
  uncategoriesButtonText: 'Go to Transactions',
  subcategoriesDataErrorTitle: 'Something went wrong',
  subcategoriesDataErrorDescription:
    "We couldn't load the page due to an unexpected error. If the problem persists, please contact our Support team on {supportPhoneNumber}.",
})
