import { formatWeekdayDate } from 'utils/date'
import { translate } from 'utils/translations'
import {
  DateRange,
  DEFAULT_DATE,
} from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { FiltersListMobileListItem } from 'components/FiltersList/FiltersListMobileListItem'

import { StyledSelectedValueWrapper } from '../../FilterMobileSelectTrigger/FilterMobileSelectTrigger.styled'

interface Props {
  openPopper: () => void
  date: DateRange | null
}

export const AccountMobileFilterDateTrigger = ({ openPopper, date }: Props) => {
  if (date !== DEFAULT_DATE) {
    const label = (
      <span data-testid="account-mobile-filter-date-trigger-value">
        {translate('page.accounts.transactionsList.filters.date')}{' '}
        <StyledSelectedValueWrapper>
          ({formatWeekdayDate(date.from.toString())}-
          {formatWeekdayDate(date.to.toString())})
        </StyledSelectedValueWrapper>
      </span>
    )
    return <FiltersListMobileListItem label={label} onClick={openPopper} />
  }

  return (
    <FiltersListMobileListItem
      label={translate('page.accounts.transactionsList.filters.date')}
      onClick={openPopper}
    />
  )
}
