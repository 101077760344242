import { useEffect, useRef } from 'react'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useContactCache } from 'features/Contacts'
import { useFormikContext } from 'formik'

import { INVOICE_SMS_RECIPIENT_FIELD } from '../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../Invoice.types'

interface UseInvoiceSMSSectionProps {
  isExpanded?: boolean
}

export const useInvoiceSMSSection = ({
  isExpanded,
}: UseInvoiceSMSSectionProps) => {
  const { readFromCache } = useContactCache()

  const {
    initialValues: {
      delivery: {
        sms: { recipient: initialRecipientValue },
      },
    },
    setFieldValue,
    setStatus,
    setFieldTouched,
    status,
    values: {
      customer: { attentionToContact, payerContact },
      delivery: { sms: smsValues },
      status: invoiceStatus,
    },
  } = useFormikContext<InvoiceFormFields>()

  const preselectRecipientValueRef = useRef('')

  useEffect(() => {
    // NOTE: Reset ref when payer is updated to set initial value
    // again when pay via accordion is expanded
    preselectRecipientValueRef.current = ''
  }, [payerContact?.contactUuid])

  useEffect(() => {
    if (!initialRecipientValue) {
      return
    }
    // NOTE: set preselect ref when an initial value was set
    // ex. Duplicating an invoice, or editing a draft invoice
    preselectRecipientValueRef.current = initialRecipientValue
  }, [initialRecipientValue])

  const isSMSEnabled = isExpanded && smsValues.isEnabled

  const payerFromCache = readFromCache(payerContact?.contactUuid)
  const attentionToFromCache = readFromCache(
    attentionToContact?.contactUuid,
    true
  )

  // NOTE: only preselect if payer has 1 phone number
  const preselectRecipientValue =
    payerFromCache?.phoneV2?.phone && !attentionToFromCache?.phoneV2?.phone
      ? payerFromCache.phoneV2?.phone
      : ''

  const shouldSetInitialValue =
    invoiceStatus === InvoiceStatus.DRAFT &&
    isSMSEnabled &&
    // NOTE: If payer contact was changed, set initial value if applicable
    !preselectRecipientValueRef.current &&
    Boolean(preselectRecipientValue)

  const shouldSetFieldTouched = Boolean(
    isSMSEnabled &&
      !status.smsInitialised &&
      shouldSetInitialValue &&
      preselectRecipientValue
  )

  useEffect(() => {
    if (!shouldSetFieldTouched) {
      return
    }

    setStatus({ ...status, smsInitialised: true })
    setFieldTouched(INVOICE_SMS_RECIPIENT_FIELD, true, false)
  }, [setFieldTouched, setStatus, shouldSetFieldTouched, status])

  useEffect(() => {
    if (!shouldSetInitialValue) {
      return
    }

    if (preselectRecipientValue) {
      setFieldValue(INVOICE_SMS_RECIPIENT_FIELD, preselectRecipientValue)
      preselectRecipientValueRef.current = preselectRecipientValue
    }
  }, [preselectRecipientValue, shouldSetInitialValue, setFieldValue])
}
