import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { currencyFormatter } from 'utils/common'

import {
  StyledSummaries,
  StyledSummaryTitle,
  StyledSummaryValue,
} from './SalesOverview.styled'
import { SummaryVolume } from './SummaryVolume'

interface Props {
  title: string
  value: number
  volume: number
}

export const SalesSummary = ({ title, value, volume }: Props) => {
  const isMobile = useIsMobileResolution()

  return (
    <div>
      <StyledSummaryTitle>{title}</StyledSummaryTitle>
      <StyledSummaries key={value} alignItems="center">
        <StyledSummaryValue>{currencyFormatter(value)}</StyledSummaryValue>
        {!isMobile && <SummaryVolume volume={volume} />}
      </StyledSummaries>
    </div>
  )
}
