import { gql } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { CellContext } from '@tanstack/react-table'

import { CardStatusCell } from 'components/CardsView/components/CardsTable/components/CardBalanceCell/components/CardStatusCell/CardStatusCell'

import { DebitCardAccountBalanceCellDebitCardV2Fragment } from './DebitCardAccountBalanceCell.generated'

export const DebitCardAccountBalanceCell = <T,>({
  cell,
  row,
  getValue,
  table,
  column,
}: CellContext<T, DebitCardAccountBalanceCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<DebitCardAccountBalanceCellDebitCardV2Fragment>()

  const cardUserId = data?.customer?.id
  const currentUserId = useLoggedInUser().userData?.id
  const isOwner = !!(
    cardUserId &&
    currentUserId &&
    cardUserId === currentUserId
  )

  return (
    <CardStatusCell
      card={data}
      cellId={cell.id}
      isLoading={isLoading}
      isActivateButtonEnabled={isOwner}
      skeletonPropWidth={skeletonLoaderWidth}
      cellSize={cellSize}
    />
  )
}

DebitCardAccountBalanceCell.fragments = {
  DebitCardV2: gql`
    fragment DebitCardAccountBalanceCellDebitCardV2Fragment on DebitCardV2 {
      id
      customer {
        id
        entityUuid
      }
      ...CardStatusCellDebitCardV2Fragment
    }
    ${CardStatusCell.fragments.DebitCardV2}
  `,
}
