export enum PlaceholderSize {
  Small,
  Medium,
  Large,
  ExtraLarge,
}

export enum PlaceholderAlignment {
  Left = 'left',
  Center = 'center',
}
