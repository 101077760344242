import { DropzoneOptions, FileRejection } from 'react-dropzone'
import { isEmpty } from 'lodash-es'

import { DropZoneErrorType } from 'types/files'
import { errorMessages, page } from 'translations'

import { pluralTranslate } from './translations'

export const dropzoneErrorMessageMap = {
  [DropZoneErrorType.FileInvalidType]: page.settings.receipt.errorTypeFile,
  [DropZoneErrorType.FileTooLarge]: page.settings.receipt.errorMaxFile,
  [DropZoneErrorType.FileToMany]: page.settings.receipt.errorQuantityFile,
}

export type DropzoneErrorMessageMap = Record<
  keyof typeof dropzoneErrorMessageMap,
  string
>

export const getDropzoneErrorMessage = (
  rejections: FileRejection[],
  customMessagesMap: Partial<DropzoneErrorMessageMap> = {}
) => {
  if (isEmpty(rejections) || isEmpty(rejections[0].errors)) {
    return errorMessages.smthWentWrong
  }

  const errorCode = rejections[0].errors[0].code
  const erroMessageMap: Record<string, string> = {
    ...dropzoneErrorMessageMap,
    ...customMessagesMap,
  }
  const errorMessage = erroMessageMap[errorCode]

  return errorMessage ?? errorMessages.smthWentWrong
}

export const getDragAndDropErrorMessage = (
  options: DropzoneOptions,
  fileRejections: FileRejection[]
) => {
  const { maxFiles } = options
  if (maxFiles === undefined) {
    return getDropzoneErrorMessage(fileRejections)
  }

  const customMap = {
    [DropZoneErrorType.FileToMany]: pluralTranslate(
      'form.shared.uploadDocuments.tooManyFilesErrorSingular',
      'form.shared.uploadDocuments.tooManyFilesError',
      maxFiles,
      { value: maxFiles }
    ),
  }
  return getDropzoneErrorMessage(fileRejections, customMap)
}
