import { TransactionInList } from 'types/transactions'

export const getNextTransaction = (
  currentTransaction: TransactionInList | null,
  transactionsList: TransactionInList[]
): TransactionInList | null => {
  if (currentTransaction === null) {
    return null
  }

  const currentTransactionIndex = transactionsList.findIndex(
    ({ id }) => id === currentTransaction.id
  )

  if (currentTransactionIndex === -1) {
    return null
  }

  return transactionsList[currentTransactionIndex + 1] ?? null
}

export const getPreviousTransaction = (
  currentTransaction: TransactionInList | null,
  transactionsList: TransactionInList[]
): TransactionInList | null => {
  if (currentTransaction === null) {
    return null
  }

  const currentTransactionIndex = transactionsList.findIndex(
    ({ id }) => id === currentTransaction.id
  )

  if (currentTransactionIndex === -1) {
    return null
  }

  return transactionsList[currentTransactionIndex - 1] ?? null
}
