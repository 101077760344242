import styled from 'styled-components'

export const ListItemContent = styled.div`
  grid-column: 1 / 2;
  min-width: 0;
`

export const AccountStatementContent = styled(ListItemContent)`
  min-height: 370px;
`
