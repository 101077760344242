import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledCopy = styled(BodyDefault)`
  margin: 0 0 32px;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledSitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`
