import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledContentWrapper = styled.div`
  overflow: auto;
  margin: 0 -4.25rem;
  padding: 0 4.25rem 1.5rem;

  @media screen and (max-width: ${BREAKPOINT.XL - 1}px) {
    margin: 0 -2.75rem;
    padding: 0 2.75rem 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINT.LG - 1}px) {
    margin: 0 -1.5rem;
    padding: 0 1.5rem 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    margin: 0 -2.5rem;
    padding: 0 2.5rem 1.5rem;
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin: 0 -0.5rem;
    padding: 0 0.5rem 1.5rem;
  }
`
