import { mock } from '@npco/utils-apollo-testing-link'

import { ONBOARDING_STORE_CHANNEL_ID } from '../../../envs'
import {
  GenerateShopToken,
  GenerateShopTokenMutationResponse,
  GenerateShopTokenMutationVariables,
} from '../graphql/GenerateShopToken.generated'

export const GENERATED_TOKEN = 'new-token'

export const GenerateShopTokenMocks = mock<
  GenerateShopTokenMutationVariables,
  GenerateShopTokenMutationResponse
>(GenerateShopToken)

export const getGenerateShopTokenSuccessMock = (
  expiresAt: number,
  origin = 'http://localhost'
) =>
  GenerateShopTokenMocks.mockVariables({
    input: {
      allowedCorsOrigins: [origin],
      channelId: ONBOARDING_STORE_CHANNEL_ID,
      ...{ expiresAt },
    },
  }).mockData({
    generateShopToken: 'new-token',
  })

export const getGenerateShopTokenSuccessMockWithoutVariable = () =>
  GenerateShopTokenMocks.mockData({
    generateShopToken: 'new-token',
  })

export const getGenerateShopTokenErrorMock = () =>
  GenerateShopTokenMocks.mockGraphQLErrors()
