import { ButtonGhost } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { LogoFlowContent } from 'layouts/LogoFlowContent/LogoFlowContent'
import { NavbarLogoMobile } from 'components/NavbarLogoMobile/NavbarLogoMobile'

import { StyledNavbarButtonContainer } from './PortalKYCContent.styled'

export const PortalKYCContent = ({
  children,
  ctaSaveAndClose,
  isLoaderContainer,
  isLoading,
  isVerticallyCentered,
}: {
  children?: React.ReactNode
  ctaSaveAndClose?: { onClick: () => void; isDisabled?: boolean }
  isLoaderContainer?: boolean
  isLoading?: boolean
  isVerticallyCentered?: boolean
}) => {
  return (
    <LogoFlowContent
      isLoaderContainer={isLoaderContainer}
      isLoading={isLoading}
      isVerticallyCentered={isVerticallyCentered}
    >
      <NavbarLogoMobile>
        {ctaSaveAndClose && (
          <StyledNavbarButtonContainer>
            <ButtonGhost
              onClick={ctaSaveAndClose.onClick}
              disabled={ctaSaveAndClose.isDisabled}
            >
              {translate('shared.cancel')}
            </ButtonGhost>
          </StyledNavbarButtonContainer>
        )}
      </NavbarLogoMobile>
      {children}
    </LogoFlowContent>
  )
}
