import { ButtonGhost, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BUTTON_SIZE } from 'types/common'
import { BREAKPOINT } from 'styles/breakpoints'
import { Button } from 'components/Buttons/Button'

export const StyledWrapper = styled(Flex)`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    width: 100%;
  }
`
export const StyledButtonsGroupWrapper = styled(StyledWrapper)`
  display: flex;

  > * {
    margin: 0 1rem 0 0;

    &:last-child {
      flex: 1;
      margin: 0;
    }
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    > * {
      &:last-child {
        flex: none;
      }
    }
  }
`

// add global alias so it's easier to find this comp
export const ActionButtons = StyledButtonsGroupWrapper

export const StyledNextButton = styled(Button).attrs(() => ({
  'data-testid': 'confirm-button',
  size: BUTTON_SIZE.LARGE,
}))`
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-width: 12.5rem;
    width: 0;
  }
`

export const StyledCloseButton = styled(ButtonGhost)`
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-width: 12.5rem;
    width: 0;
  }
`
