import { ModalBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { CancelCardDescription } from './CancelCardForm.styled'
import { useCancelCardForm } from './hooks/useCancelCardForm'

interface CancelCardFormProps {
  dataTestId?: string
}

export const CancelCardForm = ({ dataTestId }: CancelCardFormProps) => {
  const { handleSubmit, closeCancelCardModal, isCancellingCard } =
    useCancelCardForm()

  return (
    <>
      <ModalBasic.Body data-testid={dataTestId} mb="12px">
        <CancelCardDescription>
          {translate('page.cancelCardModal.description')}
        </CancelCardDescription>
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={closeCancelCardModal}>
          {translate('shared.cancel')}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          onClick={handleSubmit}
          isLoading={isCancellingCard}
        >
          {translate('page.cancelCardModal.buttonLabel')}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </>
  )
}
