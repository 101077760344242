import { DecisionModalProps, Divider } from '@npco/zeller-design-system'

import { AmountRow } from './components/AmountRow/AmountRow'
import {
  AvatarWithTextRow,
  AvatarWithTextRowProps,
} from './components/AvatarWithTextRow/AvatarWithTextRow'
import { DefaultRow } from './components/DefaultRow/DefaultRow'
import { SectionHeading } from './components/SectionHeading/SectionHeading'
import {
  ModalWrapper,
  SectionWrapper,
  StyledDecisionModal,
} from './TransferModal.styled'

export interface TransferModalProps {
  decisionModalProps: DecisionModalProps
  modalHeight?: string
  senderInfo: {
    subText: string
    avatar: AvatarWithTextRowProps['avatar']
    accountName: string
    accountNumber: {
      label: string
      value: string
    }
  }
  recipientInfo: {
    subText: string
    avatar: AvatarWithTextRowProps['avatar']
    accountName: string
    summaryRows?: {
      label: string
      value: string
    }[]
  }
  transferDetails: {
    amountFieldProps: {
      label: string
      amountInCents: number
    }
    summaryRows?: {
      label: string
      value: string
    }[]
  }
}

export const TransferModal = ({
  decisionModalProps,
  modalHeight,
  senderInfo,
  recipientInfo,
  transferDetails,
}: TransferModalProps) => {
  return (
    <StyledDecisionModal
      {...decisionModalProps}
      className="animated-content"
      overlayClassName="animated-overlay"
      bodyClassName="transfer-modal-body"
      footerClassName="transfer-modal-footer"
      // Override height of the modal
      contentStyle={{
        height: modalHeight,
      }}
    >
      <ModalWrapper>
        <SectionWrapper gap="16px" aria-label="Sender account details">
          <SectionHeading>{senderInfo.subText}</SectionHeading>
          <AvatarWithTextRow
            avatar={senderInfo.avatar}
            text={senderInfo.accountName}
            mb="16px"
          />
          <DefaultRow {...senderInfo.accountNumber} mb="8px" />
        </SectionWrapper>
        <Divider margin="18px 0" />
        <SectionWrapper gap="16px" aria-label="Recipient account details">
          <SectionHeading>{recipientInfo.subText}</SectionHeading>
          <AvatarWithTextRow
            avatar={recipientInfo.avatar}
            text={recipientInfo.accountName}
            mb="18px"
          />
          <SectionWrapper>
            {recipientInfo.summaryRows?.map((row) => (
              <DefaultRow {...row} key={row.label} />
            ))}
          </SectionWrapper>
        </SectionWrapper>
        <Divider margin="18px 0" />
        <SectionWrapper aria-label="Transfer details">
          <AmountRow {...transferDetails.amountFieldProps} />
          {transferDetails.summaryRows?.map((row) => (
            <DefaultRow {...row} key={row.label} />
          ))}
        </SectionWrapper>
      </ModalWrapper>
    </StyledDecisionModal>
  )
}

TransferModal.DecisionModal = StyledDecisionModal
TransferModal.ModalWrapper = ModalWrapper
TransferModal.SectionWrapper = SectionWrapper
