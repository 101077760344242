export const PIE_CHART_SIZES = {
  large: {
    width: 300,
    height: 300,
  },
  small: {
    width: 210,
    height: 210,
  },
}
