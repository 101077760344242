import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Message,
  MessageSize,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import CARDS_IN_HAND_SRC from 'assets/svg/cards-in-hand.svg'
import { translationsShared } from 'translations'

import { Panel, PanelImage } from '../../../CardsView.styled'
import { translations } from '../../CardsView.ActiveCards.i18n'

interface ActiveCardsEmptyMessageProps {
  onAddCard: () => void
}
export const ActiveCardsEmptyMessage = ({
  onAddCard,
}: ActiveCardsEmptyMessageProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { userRole } = useLoggedInUser()
  const isMobile = useIsMobileResolution()

  return (
    <Panel>
      <PanelImage src={CARDS_IN_HAND_SRC} alt={t('noActiveCardsImageAlt')} />
      <Box maxWidth={{ XS: '504px' }}>
        <Message
          title={t('noActiveCardsTitle')}
          description={
            userRole === CustomerRole.ADMIN
              ? t('noActiveCardsDescriptionAdmin')
              : t('noActiveCardsDescriptionManager')
          }
          primaryButton={
            userRole === CustomerRole.ADMIN
              ? {
                  label: tShared('addCard'),
                  onClick: onAddCard,
                }
              : undefined
          }
          size={MessageSize.LARGE}
          align={isMobile ? 'center' : 'left'}
        />
      </Box>
    </Panel>
  )
}
