import { useCallback } from 'react'

import { StatementPeriod } from 'types/dates'
import { useFetchDepositsStatements } from 'pages/Deposits/DepositsStatements/hooks/useFetchDeppositsStatements'
import { StatementsBaseButton } from 'components/Statements/StatementsButtons/StatementsBaseButton/StatementsBaseButton'

interface Props {
  statementPeriod: StatementPeriod
}

export const DepositsActionButton = ({ statementPeriod }: Props) => {
  const { exportDepositFile, isLoadingDeposits, hasLoaded } =
    useFetchDepositsStatements()

  const generateFile = useCallback(() => {
    exportDepositFile(statementPeriod)
  }, [statementPeriod, exportDepositFile])

  return (
    <StatementsBaseButton
      isLoadingData={isLoadingDeposits}
      hasLoaded={hasLoaded}
      generateFile={generateFile}
    />
  )
}
