import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { useBreadcrumbParts } from 'components/Breadcrumbs/Breadcrumbs.hooks'

const OVERRIDE_PAGE_TITLE = {
  [ROOT.ORGS.ORG().HELP.path]: 'Help',
  [ROOT.ORGS.ORG().REFERRAL.path]: 'Refer',
}

export const usePageTitle = () => {
  const { pathname } = useLocation()
  const breadcrumbParts = useBreadcrumbParts()

  const matchingRoute = Object.keys(OVERRIDE_PAGE_TITLE).find((routeKey) =>
    Boolean(matchPath(routeKey, pathname))
  )
  const overrideTitle = matchingRoute
    ? OVERRIDE_PAGE_TITLE?.[matchingRoute]
    : ''

  const pageTitle =
    overrideTitle || breadcrumbParts[breadcrumbParts.length - 1]?.name

  useEffect(() => {
    document.title = pageTitle
      ? `${pageTitle} | Zeller Dashboard | All Your Business Finances in One Place`
      : 'Zeller Dashboard | All Your Business Finances in One Place'
  }, [pageTitle])
}
