import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledLogoWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    top: 32px;
  }
`
