import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  Message,
  SomethingWentWrongQuestionMark,
} from '@npco/zeller-design-system'

import { categoryDrawerTranslations } from '../CategoryDrawer.i18n'

export const UncategorisedMessage = () => {
  const t = useTranslations(categoryDrawerTranslations)

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const TRANSACTION_PATH = useMemo(
    () => `/orgs/${shortEntityId}/accounts/transactions`,
    [shortEntityId]
  )

  return (
    <Message
      title={t('uncategorisedTitle')}
      description={t('uncategorisedDescription')}
      image={<SomethingWentWrongQuestionMark size="large" />}
      secondaryButton={{
        label: t('uncategoriesButtonText'),
        onClick: () => navigate(TRANSACTION_PATH),
      }}
    />
  )
}
