import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  LockCardLocationStateSchema,
  LockCardStage,
} from 'hooks/useGoToLockCardStage/useGoToLockCardStage.types'

export const useLockCardState = () => {
  const locationState = useLocationState(LockCardLocationStateSchema)

  const state = useMemo(() => {
    if (
      !locationState?.LockCardModal?.stage ||
      locationState.LockCardModal.stage !== LockCardStage.LockCard
    ) {
      return null
    }

    return locationState.LockCardModal
  }, [locationState?.LockCardModal])

  return { state }
}
