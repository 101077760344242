import { gql } from '@apollo/client'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Breadcrumb } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { CardSingleBreadcrumbsProps } from './CardSingleBreadcrumbs.types'
import { getCardsListRoute } from './CardSingleBreadcrumbs.utils'

export const CardSingleBreadcrumbs = ({
  debitCard,
  isLoading,
  error,
}: CardSingleBreadcrumbsProps) => {
  const isMobile = useIsMobileResolution()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Breadcrumb isLoading={isLoading}>
      {!isMobile && (
        <Breadcrumb.Item>
          <Breadcrumb.Link
            to={getCardsListRoute(shortEntityId, debitCard?.productType)}
          >
            {translate('page.cards.breadcrumb')}
          </Breadcrumb.Link>
        </Breadcrumb.Item>
      )}

      {!error && (
        <Breadcrumb.Item isCurrent>
          <Breadcrumb.Text>{debitCard?.name}</Breadcrumb.Text>
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  )
}

CardSingleBreadcrumbs.fragments = {
  DebitCardV2: gql`
    fragment CardSingleBreadcrumbsDebitCardV2Fragment on DebitCardV2 {
      name
      productType
    }
  `,
}
