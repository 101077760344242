import { gql, type TypedDocumentNode } from '@apollo/client'

export type UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  range: { __typename?: 'CashFlowReportRange'; start: any }
}

export const UseFilterByDateRangeCashFlowPeriodicTotalAmountFragmentDoc = gql`
  fragment UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
    range {
      start
    }
  }
` as unknown as TypedDocumentNode<
  UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment,
  undefined
>
