import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { Flex } from '@npco/zeller-design-system'
import { InvoiceDiscountFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { InvoiceItemDiscountPercentage } from './InvoiceItemDiscountPercentage/InvoiceItemDiscountPercentage'
import { InvoiceItemDiscountPrice } from './InvoiceItemDiscountPrice/InvoiceItemDiscountPrice'

export const InvoiceItemDiscountValue = () => {
  const { values } = useFormikContext<InvoiceDiscountFormFields>()

  return (
    <Flex flexDirection="column" pt="24px">
      {values.config === InvoiceDiscountConfig.PERCENTAGE ? (
        <InvoiceItemDiscountPercentage />
      ) : (
        <InvoiceItemDiscountPrice />
      )}
    </Flex>
  )
}
