import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type CheckForAbusiveDescriptionMutationVariables = Types.Exact<{
  description: Types.Scalars['String']['input']
  entityUuid: Types.Scalars['ID']['input']
}>

export type CheckForAbusiveDescriptionMutationResponse = {
  __typename?: 'Mutation'
  checkForAbusiveDescription: {
    __typename?: 'CheckForAbusiveDescriptionResult'
    isAbusive: boolean
  }
}

export const CheckForAbusiveDescription = gql`
  mutation CheckForAbusiveDescription($description: String!, $entityUuid: ID!) {
    checkForAbusiveDescription(
      description: $description
      entityUuid: $entityUuid
    ) {
      isAbusive
    }
  }
` as unknown as TypedDocumentNode<
  CheckForAbusiveDescriptionMutationResponse,
  CheckForAbusiveDescriptionMutationVariables
>
export type CheckForAbusiveDescriptionMutationFn = Apollo.MutationFunction<
  CheckForAbusiveDescriptionMutationResponse,
  CheckForAbusiveDescriptionMutationVariables
>

/**
 * __useCheckForAbusiveDescriptionMutation__
 *
 * To run a mutation, you first call `useCheckForAbusiveDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckForAbusiveDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkForAbusiveDescriptionMutation, { data, loading, error }] = useCheckForAbusiveDescriptionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useCheckForAbusiveDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckForAbusiveDescriptionMutationResponse,
    CheckForAbusiveDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CheckForAbusiveDescriptionMutationResponse,
    CheckForAbusiveDescriptionMutationVariables
  >(CheckForAbusiveDescription, options)
}
export type CheckForAbusiveDescriptionMutationHookResult = ReturnType<
  typeof useCheckForAbusiveDescriptionMutation
>
export type CheckForAbusiveDescriptionMutationResult =
  Apollo.MutationResult<CheckForAbusiveDescriptionMutationResponse>
export type CheckForAbusiveDescriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CheckForAbusiveDescriptionMutationResponse,
    CheckForAbusiveDescriptionMutationVariables
  >
