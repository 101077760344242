import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Send Invoice Reminder',
  body: 'The Customer for this Invoice will receive a reminder {channel}. Are you sure you want to continue?',
  success: 'Reminder for {referenceNumber} successfully sent.',
  error: 'Reminder for {referenceNumber} could not be sent. Please try again.',
  sms: 'SMS',
  email: 'email',
})
