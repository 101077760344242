import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

const checkboxWrapperCSS = css<{ $margin?: string }>`
  width: 1.25rem;
  // solution for firefox checkbox issue
  min-width: 1.25rem;
  height: 1.25rem;
  margin: ${({ $margin }) => $margin || '2px 12px 0 0'};
  border-collapse: separate;
  border-radius: 4px;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  box-sizing: border-box;
`
export const IndeterminateCheckboxWrapper = styled.div`
  ${checkboxWrapperCSS}
`
export const Checkbox = styled.div<{ $margin?: string }>`
  position: relative;
  display: inline-table;
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  background-color: ${({ theme }) => theme.colors.WHITE};
  ${checkboxWrapperCSS}

  &:hover {
    border-color: ${({ theme }) => theme.colors.GREY_400};
  }

  svg {
    height: 12px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 12px;
  }

  input[type='checkbox']:checked + & {
    border-color: ${({ theme }) => theme.colors.BLUE_1000};
    background-color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  input[disabled] + & {
    border-color: ${({ theme }) => theme.colors.GREY_250};
    background-color: ${({ theme }) => theme.colors.GREY_60};

    svg path {
      fill: ${({ theme }) => theme.colors.GREY_60};
    }
  }

  input[type='checkbox'][disabled]:checked + & {
    border-color: transparent;
    background-color: transparent;

    svg path {
      fill: ${({ theme }) => theme.colors.BLUE_1000};
    }
  }

  input[type='checkbox']:focus-visible + & {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.BLUE_120};
  }
`

export const DefaultCheckbox = styled.input.attrs({
  type: 'checkbox' as string,
})`
  position: absolute;
  padding: 0;
  border: 0;
  /* https://css-tricks.com/comparing-various-ways-to-hide-things-in-css/#method-5-the-visually-hidden-class */
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  height: 0;
  background-color: transparent;
`

export const Container = styled.label<{
  $alignItems: string
  $justifyContent?: string
  $flexDirection?: string
}>`
  display: flex;
  min-width: 0;
  flex: 1;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  flex-direction: ${({ $flexDirection }) => $flexDirection};

  &:not(.disabled) {
    cursor: pointer;
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.typography['body-base']}
  display: inline-block;
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK};
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    white-space: normal;
    text-overflow: unset;
  }
`

interface FieldHighlightWrapperProps {
  isHighlighted?: boolean
}

export const FieldHighlightWrapper = styled.div<FieldHighlightWrapperProps>`
  position: relative;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.BLUE_80 : 'transparent'};
`
