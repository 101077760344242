import { gql } from '@apollo/client'

export const SaveTags = gql`
  mutation SaveTags(
    $entityUuid: ID!
    $tags: [String!]
    $debitCardTransactionUuid: ID!
  ) {
    updateDebitCardTransactionAnnotations(
      entityUuid: $entityUuid
      input: {
        tags: $tags
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
    )
  }
`

export const UpdateCategory = gql`
  mutation UpdateCategory(
    $entityUuid: ID!
    $category: EntityCategories
    $subcategory: ID
    $debitCardTransactionUuid: ID!
  ) {
    updateDebitCardTransactionAnnotations(
      entityUuid: $entityUuid
      input: {
        category: $category
        subcategory: $subcategory
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
    )
  }
`

export const SaveNote = gql`
  mutation SaveNote(
    $note: String!
    $debitCardTransactionUuid: ID!
    $entityUuid: ID!
  ) {
    updateDebitCardTransactionAnnotations(
      input: {
        note: $note
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
      entityUuid: $entityUuid
    )
  }
`

export const DeleteAttachment = gql`
  mutation DeleteAttachment(
    $entityUuid: ID!
    $documentUuid: String!
    $transactionUuid: ID!
  ) {
    removeDebitCardTxnAttachment(
      entityUuid: $entityUuid
      documentUuid: $documentUuid
      transactionUuid: $transactionUuid
    )
  }
`
