import { AUSTRALIA_COUNTRY_CODE } from '@npco/component-mp-common'
import { Box, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { FormikHelpers, useFormikContext } from 'formik'

import {
  combineValidators,
  validateAddressString,
  validatePostcode,
  validateRequired,
  validateSuburb,
} from 'utils/formValidation'
import { IndividualOwnerValues } from 'types/onboarding'
import { ProfileSettingFormValues } from 'types/profile'
import { Address } from 'forms/formSections/Address'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { shared } from 'translations'

import { validateAddressLineWithPoBox } from '../OfficeAddress/OfficeAddress.validators'

export interface PersonalAddressProps {
  values?: IndividualOwnerValues | Partial<ProfileSettingFormValues> | null
  setValues:
    | FormikHelpers<ProfileSettingFormValues>['setValues']
    | FormikHelpers<IndividualOwnerValues>['setValues']
}

export const PersonalAddress = () => {
  const { values } = useFormikContext<IndividualOwnerValues>()

  const isAustralianAddress = values?.country === AUSTRALIA_COUNTRY_CODE

  return (
    <>
      <FormLine>
        <Box mb="2.5rem">
          <InputAdaptiveFieldWrapper
            label={shared.address.label}
            name="street"
            placeholder={shared.address.placeholder}
            type="text"
            validate={combineValidators(
              validateAddressString,
              validateAddressLineWithPoBox
            )}
            size={TEXT_INPUT_SIZE.SMALL}
          />
        </Box>
      </FormLine>
      <Address
        isStateText={!isAustralianAddress}
        postcodeValidator={
          isAustralianAddress ? validatePostcode : validateRequired
        }
        shouldValidate
        stateValidator={validateRequired}
        suburbValidator={
          isAustralianAddress ? validateSuburb : validateRequired
        }
      />
    </>
  )
}
