import { gql } from '@apollo/client'
import { Box } from '@npco/zeller-design-system'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'

import { translate } from 'utils/translations'
import { CategoryItemError } from 'components/CategoryDisplay/CategoryItemError'
import { DrawerItem } from 'components/DrawerItem'

import { CategoryPills } from '../Items/CategoryPills'
import {
  CategoryPillDisplayDebitCardAccountTransactionFragment,
  CategoryPillDisplayDebitCardTransactionV2Fragment,
} from './CategoryPillDisplay.generated'

const CONTACT_DEBOUNCE_IN_MS = 500

interface CategoryPillDisplayProps {
  transaction:
    | CategoryPillDisplayDebitCardTransactionV2Fragment
    | CategoryPillDisplayDebitCardAccountTransactionFragment
}

export const CategoryPillDisplay = ({
  transaction,
}: CategoryPillDisplayProps) => {
  const { category } = transaction

  const {
    data: contact,
    loading: isLoadingContact,
    error,
  } = useGetContact({
    id: transaction.contact?.id,
    queryOptions: {
      context: {
        debounceKey: 'debitCardTransactionDetails',
        debounceTimeout: CONTACT_DEBOUNCE_IN_MS,
      },
    },
  })

  if (isLoadingContact) {
    return (
      <Box mt="1.5rem">
        <DrawerItem.Skeleton />
        <DrawerItem.Skeleton />
      </Box>
    )
  }

  if (error) {
    return (
      <Box mt="1.5rem">
        <CategoryItemError
          label={translate('page.transactionDetails.category')}
        />
        <CategoryItemError
          label={translate('component.categoryDisplay.subcategoryLabel')}
        />
      </Box>
    )
  }

  return (
    <CategoryPills
      category={category || contact?.category || null}
      subcategoryId={
        transaction.subcategory || contact?.subcategoryUuid || null
      }
      debitCardTransactionUuid={transaction.id}
      contactUuid={transaction.contact?.id}
    />
  )
}

CategoryPillDisplay.fragments = {
  DebitCardTransactionV2: gql`
    fragment CategoryPillDisplayDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
      category
      subcategory
      contact {
        id
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment CategoryPillDisplayDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
      category
      subcategory
      contact {
        id
      }
    }
  `,
}
