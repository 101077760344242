import {
  BodyDefaultTypography,
  BREAKPOINT,
  Ellipsize,
  H5Style,
  inputContentHeight,
  StyledActiveFloatingInputLabel,
  StyledFloatingInputLabel,
  StyledInputBorderAndBackground,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { SCROLLBAR } from 'const/scrollbars'

const DEFAULT_MESSAGE_HEIGHT = '314px'

export const InvoiceInputLabel = css<{
  isFloatingLabel: boolean
}>`
  transform: translate(0) !important;
  top: 15px;

  ${({ isFloatingLabel }) =>
    isFloatingLabel && {
      top: -8,
    }}

  ${({ isFloatingLabel }) => isFloatingLabel && StyledActiveFloatingInputLabel}
`

export const Label = styled(StyledFloatingInputLabel).attrs({
  isVisibleFloatingInputLabel: false,
})<{
  isFloatingLabel: boolean
}>`
  left: 24px;

  ${InvoiceInputLabel}
`

export const RichTextEditorWrapper = styled.div<{
  active: boolean
  hasError: boolean
  $isMultiline?: boolean
}>`
  align-items: flex-start;
  background: ${({ theme: { colors } }) => colors.GREY_30};
  display: flex;
  position: relative;
  padding-right: 62px;

  ${({ $isMultiline }) =>
    $isMultiline &&
    `
      padding: 16px ${SCROLLBAR.SM.rightSpacing} 16px 20px;
    `}

  ${StyledInputBorderAndBackground}

  .DraftEditor-editorContainer, .public-DraftEditor-content {
    height: 100%;
  }

  .DraftEditor-root {
    border-radius: 3px;
    flex: 1;
    height: ${inputContentHeight.LARGE};
    overflow-x: hidden;
    padding: 12px 24px;

    &::-webkit-scrollbar {
      width: ${SCROLLBAR.SM.width};
    }

    ${({ $isMultiline }) =>
      $isMultiline &&
      `
      height: calc(${DEFAULT_MESSAGE_HEIGHT} - 48px);
      padding: 1px 62px 0 12px;
    `}
  }

  .emptyBlock {
    .public-DraftStyleDefault-block {
      display: block;
    }
  }

  .public-DraftStyleDefault-block {
    line-height: 24px;
    // 👇 This allows spans with whitespace to be rendered
    white-space: ${({ $isMultiline }) => ($isMultiline ? 'pre-wrap' : 'pre')};
  }
`

// Applied custom pill styling instead of using PillBasic
// There were css in PillBasic that's causing weird behaviour
// in cursor selection on keyboard navigation. Also had to use
// span instead of PillBasic div
export const MergeTagPill = styled.span<{
  $hasError: boolean
  $isFocused: boolean
}>`
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  border-radius: 16px;
  caret-color: transparent;
  padding: 2px 10px;
  user-select: none;

  ${BodyDefaultTypography}

  [data-text='true'] {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  ${({ $hasError, theme }) =>
    $hasError &&
    `
    border: 1px solid ${theme.colors.RED_700};
    z-index: 1;

    [data-text='true'] {
      color: ${theme.colors.RED_1000};
    }

  `}

  ${({ $isFocused, theme }) =>
    $isFocused &&
    `
   outline: 3px solid ${theme.colors.BLUE_120};
   z-index: 1;
  `}
`

export const DeliveryTitle = styled.h5`
  ${H5Style};
`

export const DeliveryTitleWrapper = styled.div`
  display: flex;
  gap: 12px;
  min-width: unset;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    min-width: 180px;
  }
`

export const SmsDescriptionWrapper = styled(Ellipsize)<{
  $isSendBySmsEnabled: boolean
}>`
  ${BodyDefaultTypography}
  white-space: unset;

  ${({ $isSendBySmsEnabled, theme: { colors } }) =>
    $isSendBySmsEnabled && `color: ${colors.GREY_400};`}

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    min-width: 180px;
    white-space: nowrap;
  }
`
