import { useMemo } from 'react'
import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import { isNotNull } from 'utils/common'

import {
  GetDebitCardTransactionsNewQueryResponse,
  GetDebitCardTransactionsNewQueryVariables,
  useGetDebitCardTransactionsNewQuery,
} from './graphql/getDebitCardTransactions.generated'

export const useDebitCardTransactionsQuery = ({
  onCompleted,
  onError,
  ...queryOptions
}: QueryHookOptions<
  GetDebitCardTransactionsNewQueryResponse,
  GetDebitCardTransactionsNewQueryVariables
>) => {
  const {
    data,
    fetchMore,
    refetch: refetchTransactions,
    error,
    networkStatus,
  } = useGetDebitCardTransactionsNewQuery({
    onCompleted,
    onError,
    ...queryOptions,
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first',
  })

  const transactions = useMemo(
    () =>
      (data?.getDebitCardTransactions?.transactions || []).filter(isNotNull),
    [data?.getDebitCardTransactions?.transactions]
  )

  const fetchMoreTransactions = async () => {
    const nextToken = data?.getDebitCardTransactions.nextToken

    if (!nextToken) {
      return
    }

    await fetchMore({
      variables: {
        ...queryOptions.variables,
        nextToken,
      },
    })
  }

  const hasMore = Boolean(data?.getDebitCardTransactions?.nextToken)

  return {
    transactions,
    fetchMoreTransactions,
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch,
    hasMore,
    refetchTransactions,
    error,
  }
}
