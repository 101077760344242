import { ButtonGhost } from '@npco/zeller-design-system'

import { SaveAndCloseGroup } from 'pages/Onboarding/SaveAndClose/SaveAndCloseGroup/SaveAndCloseGroup'
import { NavbarLogoMobile } from 'components/NavbarLogoMobile/NavbarLogoMobile'
import { shared } from 'translations'

import { StyledButtonContainer } from './NavbarOnboardingMobile.styled'

export const NavbarOnboardingMobile: React.FC<{
  hasButtonSave?: boolean
}> = ({ hasButtonSave = true }) => {
  return (
    <NavbarLogoMobile dataTestId="navbar-onboarding-mobile">
      {hasButtonSave && (
        <SaveAndCloseGroup>
          {({ isDisabled, onClick }) => (
            <StyledButtonContainer>
              <ButtonGhost disabled={isDisabled} onClick={onClick}>
                {shared.save}
              </ButtonGhost>
            </StyledButtonContainer>
          )}
        </SaveAndCloseGroup>
      )}
    </NavbarLogoMobile>
  )
}
