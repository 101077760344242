import { useSearchParams } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import {
  Animation,
  Box,
  fadeInHeightAnimationVariants,
} from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { EmailField } from 'pages/SignUp/fields/EmailField'
import { PasswordField } from 'pages/SignUp/fields/PasswordField'
import { TermsAndConditionsField } from 'pages/SignUp/fields/TermsAndConditionsField'
import { SignupFields } from 'pages/SignUp/SignUp.types'
import { SignupSubmitButton } from 'pages/SignUp/SignupSubmitButton/SignupSubmitButton'

import { useSignUpDesktop } from './hooks/useSignUpDesktop'
import { translations } from './SignUpDesktop.i18n'
import {
  StyledDescription,
  StyledFormWrapper,
  StyledHeader,
} from './SignUpDesktop.styled'
import { getIsFormDisabled } from './SignUpDesktop.utils'

export const SignUpDesktop = () => {
  const t = useTranslations(translations)

  const {
    handleSubmit,
    emailErrorMessage,
    passwordErrorMessage,
    isEmailSet,
    setEmailErrorMessage,
    setAreFieldsMounted,
    passwordRef,
    setPasswordErrorMessage,
  } = useSignUpDesktop()

  const [searchParams] = useSearchParams()

  return (
    <>
      <StyledHeader data-testid="signup-page-desktop">
        {t('createAccountHeading')}
      </StyledHeader>
      <StyledDescription>{t('createAccountSubHeading')}</StyledDescription>
      <Formik<SignupFields>
        initialValues={{
          email: searchParams.get('email') || '',
          password: '',
          termsAndConditions: false,
        }}
        onSubmit={handleSubmit}
      >
        {({ isValid, values, isSubmitting }) => {
          const hasErrors = Boolean(
            emailErrorMessage || passwordErrorMessage || !isValid
          )
          const isFormDisabled = getIsFormDisabled(
            values,
            isEmailSet,
            hasErrors
          )

          return (
            <StyledFormWrapper>
              <div>
                <EmailField
                  error={emailErrorMessage}
                  setError={setEmailErrorMessage}
                />
                <Animation
                  shouldShow={isEmailSet}
                  variants={fadeInHeightAnimationVariants}
                  onTransitionEnd={() => {
                    setAreFieldsMounted(true)
                  }}
                >
                  <PasswordField
                    error={passwordErrorMessage}
                    setError={setPasswordErrorMessage}
                    ref={passwordRef}
                  />
                  <Box pt={['1rem', '3.25rem']}>
                    <TermsAndConditionsField dataTestId="desktop-terms-and-conditions" />
                  </Box>
                </Animation>
              </div>
              <SignupSubmitButton isDisabled={isFormDisabled || isSubmitting} />
            </StyledFormWrapper>
          )
        }}
      </Formik>
    </>
  )
}
