import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

const curve = 'cubic-bezier(.57,.53,.51,.92)'

interface ContentTypes {
  isShrinked?: boolean
  isExpanded?: boolean
}

export const StyledModalWrapper = styled.div.attrs(
  ({ isShrinked, isExpanded }: ContentTypes) => ({
    className: `${isShrinked && 'shrinked'} ${isExpanded && 'expanded'} `,
  })
)<ContentTypes>`
  max-width: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 16.5rem;
  transition: height 0.1s ${curve};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    height: 15rem;
  }

  &.shrinked {
    height: 7.5rem;
  }

  &.expanded {
    height: 21.5rem;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      height: 16.75rem;
    }
  }
`
