import { translate } from 'utils/translations'

export enum FilterableTransactionType {
  MoneyIn = 'MoneyIn',
  MoneyOut = 'MoneyOut',
  InternalTransfers = 'InternalTransfers',
  ZellerDeposits = 'ZellerDeposits',
  OutgoingTransfer = 'OutgoingTransfer',
  CardPurchases = 'CardPurchases',
  BPAYPayment = 'BPAYPayment',
  Interest = 'Interest',
}
export interface DebitCardTransactionTypeFilterItem {
  label: string
  value: FilterableTransactionType
}

export const transactionTypeItems: readonly DebitCardTransactionTypeFilterItem[] =
  [
    {
      label: translate('component.transactionTypeFilter.labels.moneyIn'),
      value: FilterableTransactionType.MoneyIn,
    },
    {
      label: translate('component.transactionTypeFilter.labels.moneyOut'),
      value: FilterableTransactionType.MoneyOut,
    },
    {
      label: translate(
        'component.transactionTypeFilter.labels.internalTransfers'
      ),
      value: FilterableTransactionType.InternalTransfers,
    },
    {
      label: translate('component.transactionTypeFilter.labels.zellerDeposits'),
      value: FilterableTransactionType.ZellerDeposits,
    },
    {
      label: translate(
        'component.transactionTypeFilter.labels.outgoingTransfer'
      ),
      value: FilterableTransactionType.OutgoingTransfer,
    },
    {
      label: translate('component.transactionTypeFilter.labels.cardPurchases'),
      value: FilterableTransactionType.CardPurchases,
    },
    {
      label: translate('component.transactionTypeFilter.labels.bpayPayment'),
      value: FilterableTransactionType.BPAYPayment,
    },
    {
      label: translate('component.transactionTypeFilter.labels.interest'),
      value: FilterableTransactionType.Interest,
    },
  ]
