import { Flex } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { AvailableFundsSkeleton } from './AvailableFundsSkeleton'
import { DebitCardAccountAvatarSkeleton } from './DebitCardAccountAvatarSkeleton'
import { DebitCardDetailsSkeletonMobile } from './DebitCardDetailsSkeletonMobile'
import { DebitCardUserAvatarSkeleton } from './DebitCardUserAvatarSkeleton'

export const DebitCardDetailsSkeleton = () => {
  const isMobile = useIsMobileResolution()

  if (isMobile) {
    return <DebitCardDetailsSkeletonMobile />
  }

  return (
    <>
      <Flex
        height={{ _: 'auto' }}
        flexDirection={{ _: 'column', SM: 'row' }}
        justifyContent={{ _: 'start', SM: 'space-between' }}
        alignItems={{ _: 'center', XS: 'flex-start' }}
        pt={{ _: '24px', XS: '0' }}
        pl={{ _: '0', XS: '14px' }}
        data-testid="debit-card-detail-skeleton"
      >
        <Flex
          alignItems={['center', 'start']}
          flexDirection="column"
          width={1}
          mt={{ _: '0', XS: '14px', SM: '0' }}
          mb={{ _: '0', XS: '24px', SM: '0' }}
        >
          <AvailableFundsSkeleton />
        </Flex>
      </Flex>
      <Flex
        width={1}
        flexDirection={{ _: 'column', XS: 'row' }}
        justifyContent={{ _: 'auto', XS: 'space-between' }}
        alignItems={{ _: 'auto', XS: 'center' }}
        pb="2px"
      >
        <DebitCardUserAvatarSkeleton />
        <DebitCardAccountAvatarSkeleton />
      </Flex>
    </>
  )
}
