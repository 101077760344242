import { Flex } from '@npco/zeller-design-system'

import defaultPath from 'assets/svg/Bank/default.svg'
import { getBankByBsB } from 'utils/bankAccountHelper'
import { translate } from 'utils/translations'
import { ThirdPartyBankAccount } from 'types/accounts'

import {
  StyledAccountDescription,
  StyledBankIcon,
  StyledDotDivider,
} from './ThirdPartyAccountTile.styled'

interface Props {
  account: ThirdPartyBankAccount
}

interface IconProps {
  account:
    | (Partial<Omit<ThirdPartyBankAccount, 'accountBsb'>> &
        Pick<ThirdPartyBankAccount, 'accountBsb'>)
    | null
}

export const ThirdPartyAccountTileDescription = ({ account }: Props) => (
  <Flex alignItems="center">
    <StyledAccountDescription>
      {translate('component.tile.bsb', { accountBsb: account.accountBsb })}
    </StyledAccountDescription>
    <StyledDotDivider />
    <StyledAccountDescription>
      {translate('component.tile.accountNumber', {
        accountNumber: account.accountNumber,
      })}
    </StyledAccountDescription>
  </Flex>
)

export const ThirdPartyBankIcon = ({ account }: IconProps) => {
  const bsb = account?.accountBsb
  const bank = bsb ? getBankByBsB(bsb) : null

  return <StyledBankIcon src={bank?.iconPath || defaultPath} />
}
