import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  collected: 'Collected',
  dayOfWeekChartTooltip: 'Total sale on',
  dayOfWeekHeader: 'Day',
  dayOfWeekTooltip: 'Total amount grouped by days of the week',
  heading: 'Summary',
  noData: 'Data is unavailable for the current selection.',
  refunds: 'Refunds',
  refundsTooltip: 'Includes partial refunds',
  sales: 'Sales',
  surcharge: 'Surcharges',
  timeOfDayHeader: 'Time',
  timeOfDayTooltip: 'Total amount grouped by time of day',
  tip: 'Tips',
  fees: 'Fees',
  subtotal: 'Subtotal',
  total: 'Total',
  totalFromRefunds: 'Total from Refunds',
  totalFromSales: 'Total from Sales',
  totalSaleHeader: 'Amount',
  totalProcessed: 'Total Processed',
  gst: 'GST incl.',
})
