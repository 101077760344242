import { H4 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledImage = styled.img<{ isActive?: boolean }>`
  margin-top: -10px;
  height: 56px;
  width: 58px;
  opacity: ${({ isActive }) => (isActive === undefined || isActive ? 1 : 0.5)};
`

export const StyledDetailsTitle = styled(H4)`
  margin: 1.5rem 0 1rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledSimCardNumber = styled(StyledDetailsTitle)`
  margin: 1rem 0 0;
  color: ${({ theme }) => theme.colors.BLUE_1000};
`
