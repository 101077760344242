import { useCallback, useMemo, useState } from 'react'
import { v4 as randomUUID } from 'uuid'

import { FileQueueItem } from '../File.types'

export const useFilesQueue = () => {
  const [fileQueueItems, setFileQueueItems] = useState<FileQueueItem[]>([])
  const [processingFileQueueItems, setProcessingFileQueueItems] = useState<
    FileQueueItem[]
  >([])
  const [succeededFileQueueItems, setSuccededFileQueueItems] = useState<
    FileQueueItem[]
  >([])

  const addFilesToQueue = useCallback((files: File[]) => {
    setFileQueueItems((prevFileQueueItems) => [
      ...files.map((file) => ({
        id: randomUUID(),
        file,
      })),
      ...prevFileQueueItems,
    ])
  }, [])

  const removeFileFromQueue = useCallback(
    (fileId: string) =>
      setFileQueueItems((prevFileQueueItems) =>
        prevFileQueueItems.filter(
          (fileQueueItem) => fileQueueItem.id !== fileId
        )
      ),
    []
  )

  const resetFileQueueItems = useCallback(() => setFileQueueItems([]), [])

  const resetSuccededFileQueueItems = useCallback(
    () => setSuccededFileQueueItems([]),
    []
  )

  const initializeProcessingFileQueueItems = useCallback(
    () => setProcessingFileQueueItems(fileQueueItems),
    [fileQueueItems]
  )

  const addSuccededFileQueueItem = useCallback(
    (fileQueueItem: FileQueueItem) => {
      setSuccededFileQueueItems((prevSucceededFileQueueItems) => [
        ...prevSucceededFileQueueItems,
        fileQueueItem,
      ])
    },
    []
  )

  const failedFileNames = useMemo(
    () =>
      processingFileQueueItems
        .filter(
          (processingFileQueueItem) =>
            !succeededFileQueueItems.includes(processingFileQueueItem)
        )
        .map((fileQueueItem) => fileQueueItem.file.name),
    [processingFileQueueItems, succeededFileQueueItems]
  )

  return {
    addFilesToQueue,
    addSuccededFileQueueItem,
    failedFileNames,
    fileQueueItems,
    initializeProcessingFileQueueItems,
    removeFileFromQueue,
    resetFileQueueItems,
    resetSuccededFileQueueItems,
    succeededFileQueueItems,
  }
}
