import {
  Box,
  BUTTON_SIZE as DESIGN_BUTTON_SIZE,
  ButtonGhost,
} from '@npco/zeller-design-system'

import { BUTTON_SIZE } from 'types/common'
import { Button } from 'components/Buttons/Button'
import { shared } from 'translations'

import { StyledWrapper } from './ActionButtonsGroup.styled'

export interface Props {
  backButtonLabel?: string
  backDisabled?: boolean
  confirmDisabled?: boolean
  isBackButtonHidden?: boolean
  isNextButtonHidden?: boolean
  nextButtonLabel?: string
  onBackClick?: () => void
  onConfirmClick?: () => void
  nextButtonWidth?: string | string[]
  className?: string
}

export const ActionButtonsGroup: React.FC<Props> = ({
  isBackButtonHidden,
  isNextButtonHidden,
  backButtonLabel,
  onBackClick,
  backDisabled,
  onConfirmClick,
  confirmDisabled,
  nextButtonLabel,
  nextButtonWidth,
  className,
}) => {
  return (
    <StyledWrapper className={className}>
      {!isBackButtonHidden && (
        <Box mr="16px" className="backButton" width={['100%', '248px']}>
          <ButtonGhost
            data-testid="back-button-labeled"
            fullWidth
            size={DESIGN_BUTTON_SIZE.LARGE}
            onClick={onBackClick}
            disabled={backDisabled}
            type="button"
          >
            {backButtonLabel || shared.back}
          </ButtonGhost>
        </Box>
      )}
      {!isNextButtonHidden && (
        <Box
          className="confirmButton"
          width={nextButtonWidth || ['100%', '248px']}
        >
          <Button
            type={onConfirmClick ? 'button' : 'submit'}
            data-testid="confirm-button"
            fullWidth
            size={BUTTON_SIZE.LARGE}
            onClick={onConfirmClick}
            disabled={confirmDisabled}
          >
            {nextButtonLabel}
          </Button>
        </Box>
      )}
    </StyledWrapper>
  )
}
