import { BodyDefaultTypography, SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Description = styled.div`
  ${BodyDefaultTypography}
`

export const StyledSvgIcon = styled(SvgIcon)`
  width: 40px;
  height: 40px;
  border-radius: 7px;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.GREY_150};
  overflow: hidden;
`
