import { createContext } from 'react'

import { AppState } from '../MFA.types'

export interface MFAContextType {
  isRedirecting: boolean
  setIsRedirecting: (newIsRedirecting: boolean) => void
  appState: AppState | undefined
  setAppState: (newAppState: AppState | undefined) => void
  hasRedirectedBackToApp: boolean
  setHasRedirectedBackToApp: (hasRedirectedBackToApp: boolean) => void
  onCancel: (() => void) | null
  setOnCancel: (onCancelAction: (() => void) | null) => void
}

export const MFAContext = createContext<MFAContextType | null>(null)
