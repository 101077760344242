import { COLOR } from '@npco/zeller-design-system'

export const AVATAR_COLORS: ReadonlyArray<string> = [
  COLOR.GREY_90,
  COLOR.BLACK_900,
  COLOR.DARK_BLUE,
  COLOR.BLUE_400,
  COLOR.BLUE_700,
  COLOR.GREEN_1000,
  COLOR.GREEN_700,
  COLOR.YELLOW_1000,
  COLOR.RED_1000,
  COLOR.CORAL,
]

export const useAvatarColors = () => {
  return AVATAR_COLORS
}
