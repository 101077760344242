import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  rvSearchPhrase,
  rvSelectedDates,
  rvSelectedRates,
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useGetSites } from 'hooks/useGetSites'
import {
  checkIfFiltersNotSelected,
  checkIfFiltersNotStored,
} from 'utils/common'

export const useTransactionFilters = () => {
  const selectedDevices = useReactiveVar(rvSelectedTerminals)
  const { isLoading, hasMore, sites } = useGetSites()
  const totalDevicesCount = useMemo(() => {
    if (isLoading || hasMore) {
      return 0
    }

    return sites.reduce((acc, curr) => {
      if (!curr.devices) {
        return acc
      }

      return acc + curr.devices.length
    }, 0)
  }, [isLoading, hasMore, sites])

  const filters = {
    rates: useReactiveVar(rvSelectedRates),
    dates: useReactiveVar(rvSelectedDates),
    statuses: useReactiveVar(rvSelectedStatuses),
    sources: useReactiveVar(rvSelectedSources),
    terminals:
      selectedDevices.length === totalDevicesCount ? [] : selectedDevices,
    phrase: useReactiveVar(rvSearchPhrase),
    types: useReactiveVar(rvSelectedTypes),
  }

  const areFiltersInDefaultState =
    checkIfFiltersNotSelected(filters) || checkIfFiltersNotStored(filters)

  return { filters, areFiltersInDefaultState }
}
