import { StyledTableEffect } from '@npco/ui-table'
import { Title } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  padding: 1.25rem 0.375rem;
  ${StyledTableEffect}

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding: 1.25rem 1rem 1.25rem 0.375rem;
  }
`

export const StyledMobileTitle = styled(Title)`
  display: block;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`

export const StyledStandardItem = styled.div`
  display: block;
  flex: 1 0 3rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
    flex: 1 0 3rem;
  }
`

export const StyledMainItem = styled.div`
  display: block;
  align-items: center;
  flex: 4 0 10rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
    flex: 3 0 10rem;
  }
`

export const StyledHideableItem = styled.div`
  box-sizing: border-box;
  padding-left: 1.85rem;
  display: none;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
    flex: 2.5 0 8rem;
    overflow-x: hidden;
  }
`

export const StyledHideableStatusItem = styled.div`
  display: none;
  box-sizing: border-box;
  padding-left: 1.85rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
    flex: 2 0 7.5rem;
  }
`

export const StyledStatusBadgeMobile = styled.div`
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`

export const StyledItemText = styled(Title)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
