import { Box, Flex } from '@npco/zeller-design-system'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { InputManualRange } from 'components/Filters/NewFilters/RangePicker/InputManualRange/InputManualRange'
import { RangePickerValue } from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import { shared } from 'translations'

import { defaultAmountDisplay } from '../AccountFilterAmount.utils'
import {
  StyledClearButton,
  StyledFooter,
  StyledRange,
} from './AccountFilterAmountContent.styled'
import {
  SLIDER_RANGE,
  useFilterAmountPopper,
} from './hooks/useFilterAmountPopper/useFilterAmountPopper'

const SLIDER_STEP = 10

interface Props {
  isOpen: boolean
}

export const AccountFilterAmountContent = ({ isOpen }: Props) => {
  const { amount, setAmount, resetAmount } = useAccountFilters()

  const {
    onSliderChange,
    onAfterChange,
    minInput,
    maxInput,
    onReset,
    sliderValue,
  } = useFilterAmountPopper({
    isOpen,
    amount: amount || defaultAmountDisplay,
    setAmount,
    resetAmount,
  })

  return (
    <>
      <Box p="2rem 1rem 1rem">
        <StyledRange
          min={SLIDER_RANGE.min}
          max={SLIDER_RANGE.max}
          step={SLIDER_STEP}
          value={[sliderValue.min, sliderValue.max]}
          onChange={(newValue) => onSliderChange(newValue as RangePickerValue)}
          onAfterChange={onAfterChange}
        />
        <Flex justifyContent="space-between">
          <InputManualRange
            value={minInput.inputValue}
            onChange={minInput.onChange}
            onBlur={minInput.onBlur}
          />
          <InputManualRange
            value={maxInput.inputValue}
            onChange={maxInput.onChange}
            onBlur={maxInput.onBlur}
          />
        </Flex>
      </Box>
      <StyledFooter>
        <StyledClearButton type="button" onClick={onReset}>
          {shared.clear}
        </StyledClearButton>
      </StyledFooter>
    </>
  )
}
