import { COLOR, ListItem, TooltipBasic } from '@npco/zeller-design-system'

import { getCustomerDisplayName } from 'utils/customers'
import { GetCustomers_getCustomers as Customer } from 'types/gql-types/GetCustomers'
import { page } from 'translations'

import { OnboardingStatus } from './OnboardingStatus'
import {
  StyledInfo,
  StyledLetterBadge,
  StyledListItemContainer,
  StyledLocationCount,
  StyledLocationIcon,
  StyledRole,
  StyledSpacer,
  StyledSummary,
} from './UserListItem.styled'
import { getOnboardingStatus } from './UserListItem.util'

interface UserListItemProps {
  customer: Customer
  onClick: () => void
}

export const UserListItem = ({ customer, onClick }: UserListItemProps) => {
  const name = getCustomerDisplayName(customer)
  const { role } = customer
  const onboardingStatus = getOnboardingStatus(customer)

  return (
    <ListItem onClickOrEnter={onClick}>
      <StyledListItemContainer data-testid={`user-list-item-${customer.id}`}>
        <StyledLetterBadge
          bgColor={COLOR.GREY_90}
          color={COLOR.GREY_700}
          isLarge
          text={name}
          withCursorPointer
        />
        <span>{name}</span>
        <OnboardingStatus onboardingStatus={onboardingStatus} />
        <StyledSpacer />
        <StyledSummary>
          {!!customer.siteCount && customer.siteCount > 0 && (
            <TooltipBasic
              renderTrigger={({ ref, handlers }) => (
                <StyledInfo ref={ref} {...handlers}>
                  <StyledLocationIcon />
                  <StyledLocationCount>
                    {customer.siteCount}
                  </StyledLocationCount>
                </StyledInfo>
              )}
            >
              {page.settings.users.rowSummary.sites}
            </TooltipBasic>
          )}
        </StyledSummary>
        <StyledRole>{role?.toLocaleLowerCase()}</StyledRole>
      </StyledListItemContainer>
    </ListItem>
  )
}
