import { createTranslations } from '@npco/utils-translations'

export const spendControlLimitTranslations = createTranslations({
  header: 'Spend Control Limit',
  headerTooltip: 'Assign a budget to this card.',
  placeholder: 'Spend Limit',
  label: 'Spend Limit',
  ariaLabel: 'spend control limit',
  warning: 'Spend Limit exceeds current account balance of {balance}.',
})
