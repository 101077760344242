import { Navigate, useLocation } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'

export const AllCards = () => {
  const location = useLocation()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Navigate
      to={ROOT.ORGS.ORG(shortEntityId).CARDS.DEBIT.path}
      state={location.state}
      replace
    />
  )
}
