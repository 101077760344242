import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  AnnouncementModalBodyContent,
  AnnouncementModalBodyDescription,
  Flex,
  FundsGrow,
  Heading,
  ProgressIndicator,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useGoToSavingsAccountLearnMore } from 'features/SavingsAccountLearnMore/savings-account-learn-more-routing'

import { translations } from 'translations/shared.i18n'

import { TOTAL_STEPS } from '../SavingsAccountLearnMoreModal.utils'
import { interestEarningStageTranslations } from './InterestEarningStage.i18n'

type InterestEarningStageProps = {
  interestRate: string
  canCreateAccount: boolean | null
}

export const InterestEarningStage = ({
  interestRate,
  canCreateAccount,
}: InterestEarningStageProps) => {
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? 'medium' : 'large'

  const t = useTranslations(interestEarningStageTranslations)
  const tShared = useTranslations(translations)

  const { goToSavingsAccountLearnMore } = useGoToSavingsAccountLearnMore()

  const closeModal = useCallback(() => {
    goToSavingsAccountLearnMore(null)
  }, [goToSavingsAccountLearnMore])

  const goBackToIntroStage = useCallback(() => {
    goToSavingsAccountLearnMore({
      stage: 'intro',
      interestRate,
      canCreateAccount,
    })
  }, [canCreateAccount, goToSavingsAccountLearnMore, interestRate])

  const goToSavingsStage = useCallback(() => {
    goToSavingsAccountLearnMore({
      stage: 'savings',
      interestRate,
      canCreateAccount,
    })
  }, [canCreateAccount, goToSavingsAccountLearnMore, interestRate])

  return (
    <AnnouncementModal
      isOpen
      height={isMobile ? 'auto' : '528px'}
      onClickPrimary={goToSavingsStage}
      primaryButtonLabel={tShared('next')}
      onClickSecondary={goBackToIntroStage}
      secondaryButtonLabel={tShared('back')}
      onCancel={closeModal}
    >
      <FundsGrow size={illustrationSize} variant="green" />
      <AnnouncementModalBodyContent>
        <Heading.H2 dataTestId="announcement-modal-heading">
          {t('title')}
        </Heading.H2>
        <AnnouncementModalBodyDescription data-testid="announcement-modal-description">
          {t('description')}
        </AnnouncementModalBodyDescription>
      </AnnouncementModalBodyContent>
      <Flex
        justifyContent="center"
        width="100%"
        mt="auto"
        pt={{ _: '16px', XS: '8px' }}
      >
        <ProgressIndicator currentStep={2} totalStepCount={TOTAL_STEPS} />
      </Flex>
    </AnnouncementModal>
  )
}
