import { CreateExternalAccountStage } from '../CreateExternalAccount.types'
import { translations } from './CreateExternalAccountModal.i18n'

export const TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES = 20

export const getStageTitle = (stage: CreateExternalAccountStage) => {
  if (stage === CreateExternalAccountStage.ExternalAccountReviewStage) {
    return translations.DEFAULT.reviewAccountTitle
  }

  return translations.DEFAULT.addAccountTitle
}
