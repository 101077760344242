import { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  Ellipsize,
  Lightbox,
  useIsMobileResolution,
  useModalState,
} from '@npco/zeller-design-system'

import { ReactComponent as DeleteIcon } from 'assets/svg/trash.svg'
import { useDeleteImages } from 'hooks/banking/useImagePreview/useDeleteImages'
import { useDeleteTransactionAttachment } from 'hooks/banking/useImagePreview/useDeleteTransactionAttachment'
import { FileWithId } from 'components/File'

import { imagePreviewModalTranslations } from '../ImagePreviewModal.i18n'
import {
  displayDeleteImageErrorToast,
  displayDeleteImageSuccessToast,
} from './displayDeleteImageToast'

interface DeleteProps {
  transactionUuid: string
  selectedImage: FileWithId
  onSuccessfulDelete: () => void
}

export const Delete = ({
  transactionUuid,
  selectedImage,
  onSuccessfulDelete,
}: DeleteProps) => {
  const {
    isModalOpen: isDeleteConfirmationOpen,
    openModal: openDeleteConfirmation,
    closeModal: closeDeleteConfirmation,
  } = useModalState()

  const isMobile = useIsMobileResolution()

  const t = useTranslations(imagePreviewModalTranslations)

  const [isDeletingTransactionImage, setIsDeletingTransactionImage] =
    useState(false)
  const { deleteImage } = useDeleteImages({
    transactionUuid,
  })
  const { handleDeleteImage } = useDeleteTransactionAttachment({
    selectedImage,
    transactionUuid,

    deleteImage,
    onSuccessfulDelete,
  })

  const onDelete = useCallback(async () => {
    try {
      setIsDeletingTransactionImage(true)

      await handleDeleteImage()

      closeDeleteConfirmation()
      displayDeleteImageSuccessToast()
    } catch (error) {
      displayDeleteImageErrorToast()
    } finally {
      setIsDeletingTransactionImage(false)
    }
  }, [closeDeleteConfirmation, handleDeleteImage])

  return (
    <>
      <Lightbox.Button
        onClick={openDeleteConfirmation}
        icon={DeleteIcon}
        isLoading={isDeletingTransactionImage}
      >
        {!isMobile && t('delete')}
      </Lightbox.Button>
      <DecisionModal
        isOpen={isDeleteConfirmationOpen}
        onCancel={closeDeleteConfirmation}
        title={t('title')}
        primaryButtonLabel={t('confirm')}
        secondaryButtonLabel={t('cancel')}
        onClickPrimary={onDelete}
        onClickSecondary={closeDeleteConfirmation}
        isLoadingPrimary={isDeletingTransactionImage}
      >
        <Ellipsize>
          {t('description', { fileName: selectedImage.file.name })}
        </Ellipsize>
      </DecisionModal>
    </>
  )
}
