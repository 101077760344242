import { Theme } from '@npco/mp-gql-types'
import { SelectItemBasic } from '@npco/zeller-design-system'

export const SLEEP_TIME_ITEMS: SelectItemBasic[] = [
  { value: 60, label: '1 minute' },
  { value: 120, label: '2 minutes' },
  { value: 300, label: '5 minutes' },
  { value: 1800, label: '30 minutes' },
  { value: 3600, label: '1 hour' },
]

export const HIBERNATE_ITEMS: SelectItemBasic[] = [
  { value: 1800, label: '30 minutes' },
  { value: 7200, label: '2 hours' },
  { value: 14400, label: '4 hours' },
]

export const mapHibernateTimeToItem = (time: number | null) => {
  switch (time) {
    case 1800:
      return HIBERNATE_ITEMS[0]
    case 7200:
      return HIBERNATE_ITEMS[1]
    case 14400:
      return HIBERNATE_ITEMS[2]
    default:
      return HIBERNATE_ITEMS[0]
  }
}

export const mapSleepTimeToItem = (time: number | null) => {
  switch (time) {
    case 60:
      return SLEEP_TIME_ITEMS[0]
    case 120:
      return SLEEP_TIME_ITEMS[1]
    case 300:
      return SLEEP_TIME_ITEMS[2]
    case 1800:
      return SLEEP_TIME_ITEMS[3]
    case 3600:
      return SLEEP_TIME_ITEMS[4]
    default:
      return SLEEP_TIME_ITEMS[0]
  }
}

export const getTheme = (isDarkMode: boolean) =>
  isDarkMode ? Theme.DARK_THEME : Theme.LIGHT_THEME
