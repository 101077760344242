import { forwardRef } from 'react'

import { usePrevious } from 'hooks/usePrevious'

import { SelectSize, SelectTriggerAdaptiveBasicProps } from '../Select.types'
import {
  StyledSelectAdaptiveTrigger,
  StyledSelectPlaceholder,
  StyledSelectTriggerAdaptiveContent,
  StyledSelectTriggerFloatingLabel,
  StyledSelectTriggerMainWrapper,
} from './SelectTriggerAdaptive.styled'

export const SelectTriggerAdaptiveBasic = forwardRef<
  HTMLButtonElement,
  SelectTriggerAdaptiveBasicProps
>(
  (
    {
      children,
      className = '',
      hasError,
      isOpen,
      label,
      onBlur,
      onFocus,
      placeholder,
      renderContent,
      renderLeftControls,
      renderRightControls,
      size = SelectSize.Medium,
      ...rest
    },
    ref
  ) => {
    const isPreviousOpened = usePrevious(isOpen)

    return (
      <StyledSelectAdaptiveTrigger
        {...rest}
        onBlur={!isOpen ? onBlur : undefined}
        onFocus={() => onFocus?.(isOpen)}
        tabIndex={0}
        className={className}
        $size={size}
        isOpen={isOpen}
        isPreviousOpened={isPreviousOpened}
        hasError={hasError}
        hasLabel={!!label}
        ref={ref}
      >
        <StyledSelectTriggerMainWrapper>
          {label && (
            <StyledSelectTriggerFloatingLabel
              data-testid="select-floating-label"
              $size={size}
            >
              {label}
            </StyledSelectTriggerFloatingLabel>
          )}
          {renderLeftControls?.()}
          {Boolean(placeholder && !rest.hasValue) && (
            <StyledSelectPlaceholder $size={size}>
              {placeholder}
            </StyledSelectPlaceholder>
          )}
          <StyledSelectTriggerAdaptiveContent>
            {children || renderContent?.()}
          </StyledSelectTriggerAdaptiveContent>
        </StyledSelectTriggerMainWrapper>
        {renderRightControls?.()}
      </StyledSelectAdaptiveTrigger>
    )
  }
)
