import {
  formatEntityAddress,
  joinStreetWithSuburbStateAndPostcode,
} from 'utils/address'

import {
  Address,
  ContactAddress,
  MerchantAddress,
} from '../TransactionContact.types'

const getIsMerchantAddress = (address: Address): address is MerchantAddress =>
  Boolean(
    (address as MerchantAddress).street1 || (address as MerchantAddress).street2
  )

const getIsContactAddress = (address: Address): address is ContactAddress =>
  Boolean((address as ContactAddress).street)

const formatContactAddress = (contactAddress: ContactAddress) =>
  joinStreetWithSuburbStateAndPostcode(contactAddress)

export const formatAddress = (address: Address) => {
  if (getIsMerchantAddress(address)) {
    return formatEntityAddress(address)
  }

  return formatContactAddress(address)
}

const getHasNoStreetOrSuburb = (address: Address) => {
  return !(
    (getIsMerchantAddress(address) && address.street1 && address.suburb) ||
    (getIsContactAddress(address) && address.street && address.suburb)
  )
}

export const getIsInaccurate = (address: Address | null | undefined) =>
  address === null || address === undefined || getHasNoStreetOrSuburb(address)

const LATITUDE_LONGITUDE_REGEX = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/

export const getLatitudeLongitudeFromLocationString = (
  location: string | null | undefined
) => {
  if (location && LATITUDE_LONGITUDE_REGEX.test(location)) {
    const [lat, lng] = location.split(',')

    // parseFloat is suitable here, as it is not currency values - lng/lat
    return {
      // eslint-disable-next-line zeller/ban-parse-float
      lat: parseFloat(lat),
      // eslint-disable-next-line zeller/ban-parse-float
      lng: parseFloat(lng),
    }
  }

  return null
}
