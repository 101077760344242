import {
  IntFilterInput,
  SourceFilter,
  TransactionType,
} from '@npco/mp-gql-types'

import dayjs from 'utils/dayjs'
import { TransactionFilterStatus } from 'types/transactions'

import { AmountValue } from '../Amount.types'
import { DateRangeValue } from '../DateRange.types'

export const mapDateFiltersToApi = (dates: DateRangeValue | null) => {
  if (!dates || (!dates.start && !dates.end)) {
    return null
  }

  const dateLocalStart = dayjs(dates.start).utc().local().format()
  const dateLocalEnd = dayjs(dates.end).utc().local().format()

  return { between: [dateLocalStart, dateLocalEnd] }
}

export const mapTypeFilterToApi = (types: `${TransactionType}`[] | null) =>
  types?.length ? { in: types } : null

export const mapStatusFilterToApi = (
  statuses: `${TransactionFilterStatus}`[] | null
) => {
  if (!statuses?.length) {
    return null
  }
  return {
    in: statuses.map((status) => (status === 'REFUNDED' ? 'APPROVED' : status)),
  }
}

export const mapSourceFilterToApi = (source: `${SourceFilter}`[] | null) =>
  source?.length ? { in: source } : null

export const mapTerminalsFiltersToApi = (terminals: string[] | null) =>
  terminals?.length ? { in: terminals } : null

export const mapSiteFilterToApi = (siteUuid: string | null) =>
  siteUuid ? { eq: siteUuid } : null

export const mapRefundedStatusToApi = (
  statuses: `${TransactionFilterStatus}`[] | null
) => {
  if (statuses?.length === 1 && statuses.includes('REFUNDED')) {
    return { eq: true }
  }

  if (statuses?.includes('APPROVED') && !statuses.includes('REFUNDED')) {
    return { eq: false }
  }

  return null
}

export const mapAmountRangeFilterToApi = (
  amount: AmountValue | null
): IntFilterInput | null => {
  const min = amount?.min
  const max = amount?.max

  if (min !== undefined && max === undefined) {
    return {
      ge: min * 100,
    }
  }

  if (min === undefined && max !== undefined) {
    return {
      le: max * 100,
    }
  }

  if (min !== undefined && max !== undefined) {
    return {
      between: [min * 100, max * 100],
    }
  }

  return null
}
