import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type DeleteLabelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  entityUuid: Types.Scalars['ID']['input']
}>

export type DeleteLabelMutationResponse = {
  __typename?: 'Mutation'
  deleteLabel: boolean
}

export const DeleteLabel = gql`
  mutation DeleteLabel($id: ID!, $entityUuid: ID!) {
    deleteLabel(id: $id, entityUuid: $entityUuid)
  }
` as unknown as TypedDocumentNode<
  DeleteLabelMutationResponse,
  DeleteLabelMutationVariables
>
export type DeleteLabelMutationFn = Apollo.MutationFunction<
  DeleteLabelMutationResponse,
  DeleteLabelMutationVariables
>

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useDeleteLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLabelMutationResponse,
    DeleteLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteLabelMutationResponse,
    DeleteLabelMutationVariables
  >(DeleteLabel, options)
}
export type DeleteLabelMutationHookResult = ReturnType<
  typeof useDeleteLabelMutation
>
export type DeleteLabelMutationResult =
  Apollo.MutationResult<DeleteLabelMutationResponse>
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<
  DeleteLabelMutationResponse,
  DeleteLabelMutationVariables
>
