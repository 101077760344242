import { useTranslations } from '@npco/utils-translations'
import { InfoBox } from '@npco/zeller-design-system'

import { translations } from './InverseIncomeOrExpenseMessage.i18n'

export const InverseIncomeOrExpenseMessage = () => {
  const t = useTranslations(translations)

  return (
    <InfoBox>
      <InfoBox.Message>{t('message')}</InfoBox.Message>
    </InfoBox>
  )
}
