import { LockCardStage } from 'hooks/useGoToLockCardStage/useGoToLockCardStage.types'
import { Condition } from 'components/Condition/Condition'

import { useLockCardState } from '../hooks/useLockCardState'
import { LockCardForm } from './LockCardForm/LockCardForm'

export const LockCard = () => {
  const { state } = useLockCardState()

  return (
    <Condition shouldShow={state?.stage === LockCardStage.LockCard}>
      <LockCardForm dataTestId="lock-card-form" />
    </Condition>
  )
}
