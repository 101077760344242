import { ButtonFill, ButtonGhost } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { StyledFooter } from './SelectMobileFilterFooter.styled'

interface Props {
  onReset?: () => void
  onApply?: () => void
}

export const SelectMobileFilterFooter = ({ onReset, onApply }: Props) => {
  return (
    <StyledFooter isSingleColumn={!onApply}>
      <ButtonGhost onClick={onReset}>{translate('shared.clear')}</ButtonGhost>
      {onApply && (
        <ButtonFill onClick={onApply}>{translate('shared.apply')}</ButtonFill>
      )}
    </StyledFooter>
  )
}
