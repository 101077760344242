import { useCallback, useEffect, useRef, useState } from 'react'
import DayPicker from 'react-day-picker'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash-es'

import dayjs from 'utils/dayjs'

import { DateRangeValue } from '../DateRange.types'
import { DatePickerProps } from './DatePicker.types'
import { getTimeAdjustedDate } from './DateRange.utils'

export const useDateRange = ({
  defaultRange,
  format,
  onRangeChange,
  range,
}: DatePickerProps) => {
  const [currentlyVisibleFirstMonth, setCurrentlyVisibleFirstMonth] = useState<
    Dayjs | undefined
  >(dayjs(range?.end).startOf('month'))
  const [firstSelectedDay, setFirstSelectedDay] = useState<Date | undefined>(
    range?.start
  )
  const [previousRangeEnd, setPreviousRangeEnd] = useState<Date | undefined>()

  const [hoveredDay, setHoveredDay] = useState<Date>()
  const isDateTime = format === 'datetime'

  const ref = useRef<DayPicker>(null)

  const showMonth = useCallback(
    (month?: Date) => {
      const periodFirstMonth = dayjs(month).startOf('month')
      const isFirstMonthVisible =
        currentlyVisibleFirstMonth?.isSame(periodFirstMonth) ??
        currentlyVisibleFirstMonth?.add(1, 'month').isSame(periodFirstMonth)

      if (month && !isFirstMonthVisible) {
        ref.current?.showMonth(month)
      }
    },
    [currentlyVisibleFirstMonth]
  )

  const handleSetPeriod = useCallback(
    (periodRange: Required<DateRangeValue>) => {
      showMonth(periodRange.start)

      const periodRangeTimeAdjusted = {
        start: getTimeAdjustedDate({
          isDateTime,
          time: range?.start,
          date: periodRange.start,
        }),
        end: getTimeAdjustedDate({
          isDateTime,
          time: range?.end,
          date: periodRange.end,
        }),
      }

      onRangeChange(periodRangeTimeAdjusted)
    },
    [isDateTime, onRangeChange, range?.end, range?.start, showMonth]
  )

  const handleDayMouseEnter = (day: Date) => {
    if (range?.end && range.start) return
    setHoveredDay(day)
  }

  const handleDayMouseLeave = () => {
    setHoveredDay(undefined)
  }

  const onMonthChange = useCallback(
    (month: Date) => {
      setCurrentlyVisibleFirstMonth(dayjs(month).startOf('month'))
    },
    [setCurrentlyVisibleFirstMonth]
  )

  const handleDayClick = useCallback(
    (day: Date) => {
      const rangeEnd = range?.end ?? previousRangeEnd
      setPreviousRangeEnd(range?.end)

      if (dayjs(day).isAfter(dayjs().endOf('day'))) return

      const dateIfStart = getTimeAdjustedDate({
        isDateTime,
        time: range?.start,
        date: dayjs(day).startOf('day').toDate(),
      })

      const dateIfEnd = getTimeAdjustedDate({
        isDateTime,
        time: rangeEnd,
        date: dayjs(day).endOf('day').toDate(),
      })

      if (!firstSelectedDay) {
        setFirstSelectedDay(dateIfStart)
        onRangeChange({
          start: dateIfStart,
          end: undefined,
        })
        return
      }

      if (!range?.end) {
        if (dayjs(day).isBefore(firstSelectedDay)) {
          onRangeChange({
            start: dateIfStart,
            end: getTimeAdjustedDate({
              isDateTime,
              time: rangeEnd,
              date: dayjs(firstSelectedDay).endOf('day').toDate(),
            }),
          })
        } else {
          onRangeChange({
            start: firstSelectedDay,
            end: dateIfEnd,
          })
        }
        return
      }

      if (range.end) {
        setHoveredDay(undefined)
        setFirstSelectedDay(dateIfStart)
        onRangeChange({
          start: dateIfStart,
          end: undefined,
        })
      }
    },
    [firstSelectedDay, isDateTime, onRangeChange, previousRangeEnd, range]
  )

  const handleResetClick = useCallback(() => {
    showMonth(defaultRange?.start)
    onRangeChange(defaultRange)
    setFirstSelectedDay(undefined)
    setHoveredDay(undefined)
  }, [defaultRange, onRangeChange, showMonth])

  const resetDisabled = isEqual(
    range,
    defaultRange ?? { start: undefined, end: undefined }
  )

  useEffect(() => {
    // NOTE: When resetting the range from outside the component's internal
    // state, we still want to show the first month of the new range
    const rangeFirstMonth = dayjs(defaultRange?.start).startOf('month')
    if (
      isEqual(range, defaultRange) &&
      !dayjs(currentlyVisibleFirstMonth).isSame(rangeFirstMonth)
    ) {
      showMonth(range?.start)
    }
    // NOTE: Don't trigger this effect when currentlyVisibleFirstMonth changes,
    // only when the range or default range changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range?.start, defaultRange?.start, defaultRange, range])

  return {
    currentlyVisibleFirstMonth: currentlyVisibleFirstMonth?.toDate(),
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleResetClick,
    handleSetPeriod,
    onMonthChange,
    ref,
    resetDisabled,
    hoveredDay,
    setCurrentlyVisibleFirstMonth,
  }
}
