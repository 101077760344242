import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type PairOraclePosMutationVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  config: Types.OraclePosPairConfigInput
}>

export type PairOraclePosMutationResponse = {
  __typename?: 'Mutation'
  pairOraclePos: boolean
}

export const PairOraclePos = gql`
  mutation PairOraclePos($entityUuid: ID!, $config: OraclePosPairConfigInput!) {
    pairOraclePos(entityUuid: $entityUuid, config: $config)
  }
` as unknown as TypedDocumentNode<
  PairOraclePosMutationResponse,
  PairOraclePosMutationVariables
>
export type PairOraclePosMutationFn = Apollo.MutationFunction<
  PairOraclePosMutationResponse,
  PairOraclePosMutationVariables
>

/**
 * __usePairOraclePosMutation__
 *
 * To run a mutation, you first call `usePairOraclePosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePairOraclePosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pairOraclePosMutation, { data, loading, error }] = usePairOraclePosMutation({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      config: // value for 'config'
 *   },
 * });
 */
export function usePairOraclePosMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PairOraclePosMutationResponse,
    PairOraclePosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PairOraclePosMutationResponse,
    PairOraclePosMutationVariables
  >(PairOraclePos, options)
}
export type PairOraclePosMutationHookResult = ReturnType<
  typeof usePairOraclePosMutation
>
export type PairOraclePosMutationResult =
  Apollo.MutationResult<PairOraclePosMutationResponse>
export type PairOraclePosMutationOptions = Apollo.BaseMutationOptions<
  PairOraclePosMutationResponse,
  PairOraclePosMutationVariables
>
