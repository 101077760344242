import { Box, Flex, ModalBasic } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { TOTAL_LINK_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { StyledForm } from 'pages/GlobalModals/GlobalModals.styled'
import {
  MultiStageModal,
  useMultiStageModalProgressBar,
} from 'components/MultiStageModal'

import LinkCardErrorInfoMessage from '../LinkCardErrorInfoMessage/LinkCardErrorInfoMessage'
import { LinkCardDisplay } from './components/LinkCardDisplay/LinkCardDisplay'
import { LinkCardIdFields } from './components/LinkCardIdFields'
import { useCardStyleTabs } from './hooks/useCardStyleTabs'
import { useLinkCardIdForm } from './hooks/useLinkCardIdForm'
import { LinkCardIdFormValues } from './LinkCardIdForm.types'

interface LinkCardIdFormProps {
  dataTestId?: string
}

export const LinkCardIdForm = ({ dataTestId }: LinkCardIdFormProps) => {
  const {
    linkCard,
    goBackToLandingPage,
    isActivatingCard,
    linkCardError,
    isAdmin,
  } = useLinkCardIdForm()
  useMultiStageModalProgressBar({
    newCurrentStep: 2,
    newTotalSteps: TOTAL_LINK_CARD_STAGES,
  })

  const { currentTab, tabs } = useCardStyleTabs()

  return (
    <Formik<LinkCardIdFormValues>
      initialValues={{ cardId: '' }}
      onSubmit={linkCard}
    >
      <StyledForm data-testid={dataTestId}>
        <ModalBasic.Body>
          <Flex flexDirection={['column-reverse', 'row']}>
            <Box pr={['0px', '64px']} pt="24px" flexGrow={1}>
              <LinkCardIdFields
                linkCardError={linkCardError}
                currentTab={currentTab}
              />
            </Box>
            <LinkCardDisplay currentTab={currentTab} tabs={tabs} />
          </Flex>
          <LinkCardErrorInfoMessage linkCardError={linkCardError} />
        </ModalBasic.Body>
        <MultiStageModal.FormButtons
          onCancel={goBackToLandingPage}
          isLoading={isActivatingCard}
          shouldShowBackButton={isAdmin}
        />
      </StyledForm>
    </Formik>
  )
}
