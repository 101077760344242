import { useTranslations } from '@npco/utils-translations'
import { Flex, Heading } from '@npco/zeller-design-system'

import * as styled from '../FeesAndPricing.styled'
import { getTotalFee } from '../FeesAndPricing.utils'
import { translations } from './VirtualTerminalFeesAndPricing.i18n'

type VirtualTerminalFeesAndPricingProps = {
  feeFixedVt?: number | null
  feePercentVt?: number | null
}
export const VirtualTerminalFeesAndPricing = ({
  feeFixedVt,
  feePercentVt,
}: VirtualTerminalFeesAndPricingProps) => {
  const t = useTranslations(translations)
  const virtualTerminalFees = getTotalFee({
    feeFixed: feeFixedVt,
    feePercent: feePercentVt,
  })

  return (
    <Flex flexDirection="column" marginTop="20px">
      <Heading.H3>{t('title')}</Heading.H3>
      <styled.Description>{t('description')}</styled.Description>
      <styled.Table data-testid="virtualTerminalTable">
        <styled.TableCell className="leftCell">
          {t('cardNotPresentPayments')}
        </styled.TableCell>
        <styled.TableCell className="rightCell ">
          {virtualTerminalFees || '-'}
        </styled.TableCell>
      </styled.Table>
    </Flex>
  )
}
