/*
    ABN Validator
    Checks for ABN Validity
    Based on ABN format documentation:
    https://abr.business.gov.au/Help/AbnFormat
*/

export const getIsAbnValid = (value: string): boolean => {
  const validAbnLength = 11
  const checkNumberModulo = 89
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
  const abn = value.replace(/\D/g, '')

  if (abn.length !== validAbnLength) {
    return false
  }

  const checkSum = weights.reduce(
    (accumulator: number, weight: number, position: number) => {
      const abnDigit = parseInt(abn.charAt(position), 10)
      const substractor = position ? 0 : 1
      const checkDigit = abnDigit - substractor
      return accumulator + weight * checkDigit
    },
    0
  )

  return checkSum % checkNumberModulo === 0
}
