export interface ItemCategoryFormField {
  label: string
  value: string
}
export interface ItemFormFields {
  addNewOnSave?: boolean
  categories: ItemCategoryFormField[]
  description: string
  name: string
  price: string
  isTaxInclusive: boolean
  taxApplicable: ItemTaxApplicableItems
  sku: string
}

export type EditItemFormFields = Pick<
  ItemFormFields,
  'categories' | 'name' | 'price' | 'isTaxInclusive' | 'taxApplicable' | 'sku'
>

export enum ItemTaxApplicableItems {
  NoTax = 'NoTax',
  TaxApplicable = 'TaxApplicable',
}

export type ItemTaxSelectedItem = {
  value: ItemTaxApplicableItems
  label: string
}

export type ItemBasicFields = {
  taxes: Array<{ enabled: boolean }> | null
  price: number
  name: string
  sku: string | null
  categories: Array<{ name: string; id: string }> | null
}
