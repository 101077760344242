import { ContactTransferSuccess } from './ContactTransferSuccess/ContactTransferSuccess'
import { ContactTransferSummary } from './ContactTransferSummary/ContactTransferSummary'
import { useContactTransferModalState } from './hooks/useContactTransferModalState/useContactTransferModalState'

type ContactTransferModalProps = {
  onNewTransfer?: () => void
}

export const ContactTransferModal = ({
  onNewTransfer,
}: ContactTransferModalProps) => {
  const { state } = useContactTransferModalState()

  if (state === null) {
    return null
  }

  return (
    <>
      {state.stage === 'summary' && (
        <ContactTransferSummary values={state.values} />
      )}
      {state.stage === 'success' && (
        <ContactTransferSuccess
          values={state.values}
          transactionTimestamp={state.timestamp}
          onNewTransfer={onNewTransfer}
          transactionID={state.transactionID}
        />
      )}
    </>
  )
}
