import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { Condition } from 'components/Condition/Condition'

import { useAddCardState } from '../../../hooks/useAddCardState'
import { SelectAccountForm } from './SelectAccountForm/SelectAccountForm'

export const LinkSelectAccountStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.LinkSelectAccountStage}
    >
      <SelectAccountForm dataTestId="link-select-account-stage" />
    </Condition>
  )
}
