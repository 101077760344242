import React, { useMemo } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  FormExtensionBasic,
  FormExtensionRenderContentProps,
  InputAdaptiveField,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import {
  combineValidators,
  validateMaxLength,
  validateRequiredCustomMsg,
} from 'utils/formValidation'
import { MarkAsSelf } from 'components/MarkAsSelf/MarkAsSelf'
import { page } from 'translations'

import { EmailInputField } from '../components/EmailInputField'
import { PhoneInputField } from '../components/PhoneInputField/PhoneInputField'
import { translations } from '../Contacts.i18n'
import * as styled from '../Contacts.styled'
import { ContactFormData } from '../Contacts.types'
import { FieldProps } from '../People/PersonShared.fields'
import {
  FIELD_ADDITIONAL_EMAIL_BUSINESS,
  FIELD_ADDITIONAL_PHONE_BUSINESS,
  FIELD_STREET_BUSINESS,
} from './BusinessShared.fields'

const MAX_LENGTH_BUSINESS_NAME = 256

interface BusinessSimpleFieldsProps {
  existingContactName?: string | null
}

export const BusinessNameField = ({
  existingContactName,
}: BusinessSimpleFieldsProps) => {
  const t = useTranslations(translations)

  return (
    <Box mb="16px">
      <InputAdaptiveField
        hasError={Boolean(existingContactName)}
        label={t('businessName')}
        name="business.businessName"
        validate={combineValidators(
          validateRequiredCustomMsg(t('businessNameRequired')),
          validateMaxLength(
            MAX_LENGTH_BUSINESS_NAME,
            t('invalidBusinessNameLength')
          )
        )}
      />

      {existingContactName && (
        <Flex
          alignItems="center"
          color={COLOR.RED_1000}
          data-testid="existing-contact-message"
          flexWrap="wrap"
          mt="8px"
        >
          {t('existingBusiness', { name: existingContactName })}
        </Flex>
      )}

      <MarkAsSelf name="business.isSelf" contactType={ContactType.BUSINESS} />
    </Box>
  )
}

export const BusinessSimpleFields = React.memo(
  ({ existingContactName }: BusinessSimpleFieldsProps) => {
    const { values } = useFormikContext<ContactFormData>()
    const t = useTranslations(translations)

    const FIELD_EMAIL_BUSINESS = {
      itemId: 'email',
      itemName: t('email'),
      renderContent: ({ key }: FormExtensionRenderContentProps) => (
        <Box mb="24px" key={key}>
          <EmailInputField contactType={ContactType.BUSINESS} isOptional />
        </Box>
      ),
      shouldDisplayContentOnMount: (values: ContactFormData) =>
        Boolean(values.business.email?.email),
    }

    const FIELD_PHONE_BUSINESS = {
      itemId: 'phone',
      itemName: t('phone'),
      renderContent: ({ key }: FormExtensionRenderContentProps) => (
        <Box mb="24px" key={key}>
          <PhoneInputField
            contactType={ContactType.BUSINESS}
            isOptional
            placeholder={page.contacts.fields.phone}
          />
        </Box>
      ),
      shouldDisplayContentOnMount: (values: ContactFormData) =>
        Boolean(values.business.phone),
    }

    const BUSINESS_FIELDS = [
      FIELD_PHONE_BUSINESS,
      FIELD_ADDITIONAL_PHONE_BUSINESS,
      FIELD_STREET_BUSINESS,
      FIELD_EMAIL_BUSINESS,
      {
        ...FIELD_ADDITIONAL_EMAIL_BUSINESS,
        dependsOn: FIELD_EMAIL_BUSINESS.itemId,
      },
    ]

    const BUTTON_ORDER = [
      FIELD_STREET_BUSINESS.itemId,
      FIELD_EMAIL_BUSINESS.itemId,
      FIELD_ADDITIONAL_EMAIL_BUSINESS.itemId,
      FIELD_PHONE_BUSINESS.itemId,
      FIELD_ADDITIONAL_PHONE_BUSINESS.itemId,
    ]

    const extendedFormFields = useMemo(() => {
      return BUSINESS_FIELDS.map((field: FieldProps) => ({
        ...field,
        initialDisplayedElementCount:
          field.initialDisplayedElementCount?.(values),
        shouldDisplayContentOnMount: field.shouldDisplayContentOnMount(values),
      }))
      // Run on mount to get initial displayed items for form extension
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <BusinessNameField existingContactName={existingContactName} />
        <styled.FormExtensionContainer>
          <FormExtensionBasic
            buttonOrder={BUTTON_ORDER}
            labelText={t('extendedFormFieldsLabel')}
          >
            {extendedFormFields}
          </FormExtensionBasic>
        </styled.FormExtensionContainer>
      </>
    )
  }
)
