import { BillStatus } from '@npco/mp-gql-types'

type BillStatusProps = {
  [key in BillStatus]: BillStatus
}

export const BILL_STATUS: BillStatusProps = {
  [BillStatus.CHARGED]: BillStatus.CHARGED,
  [BillStatus.CHARGE_FAILED]: BillStatus.CHARGE_FAILED,
  [BillStatus.REFUNDED]: BillStatus.REFUNDED,
  [BillStatus.REFUND_FAILED]: BillStatus.REFUND_FAILED,
}
