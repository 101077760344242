import { Box } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { Checkbox } from 'components/Input/Checkbox'

interface Props {
  name: string
  label: string
}

export const CategoryCheckbox = ({ name, label }: Props) => {
  const [field, , handlers] = useField(name)

  return (
    <Box mt="1.5rem">
      <Checkbox
        name={name}
        label={label}
        onChange={() => handlers.setValue(!field.value)}
        value={field.value}
        checked={field.value}
      />
    </Box>
  )
}
