import { gql } from '@apollo/client'
import { Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { CloseAccountPromptLinkedCardsDebitCardAccountV2Fragment_cards as DebitCardAccountV2Card } from 'types/gql-types/CloseAccountPromptLinkedCardsDebitCardAccountV2Fragment'

import { CloseAccountPromptCardItem } from '../CloseAccountPromptCardItem/CloseAccountPromptCardItem'
import {
  StyledLinkedCardsDescription,
  StyledLinkedCardsTitle,
  StyledLinkedCardsWrapper,
} from './CloseAccountPromptLinkedCards.styled'

interface CloseAccountPromptLinkedCardsProps {
  cards: DebitCardAccountV2Card[]
}

export const CloseAccountPromptLinkedCards = ({
  cards,
}: CloseAccountPromptLinkedCardsProps) => {
  return (
    <Box>
      <StyledLinkedCardsTitle>
        {translate(
          'page.closeAccountModal.closeAccountPrompt.linkedCardsTitle',
          { cardCount: cards.length ? `(${cards.length})` : '' }
        )}
      </StyledLinkedCardsTitle>
      <StyledLinkedCardsDescription>
        {translate(
          'page.closeAccountModal.closeAccountPrompt.linkedCardsDescription'
        )}
      </StyledLinkedCardsDescription>
      <StyledLinkedCardsWrapper>
        {cards.map((card: DebitCardAccountV2Card) => (
          <CloseAccountPromptCardItem card={card} key={card.id} />
        ))}
      </StyledLinkedCardsWrapper>
    </Box>
  )
}

CloseAccountPromptLinkedCards.fragments = {
  DebitCardAccountV2: gql`
    fragment CloseAccountPromptLinkedCardsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      cards {
        __typename
        id
        ...CloseAccountPromptCardItemDebitCardV2Fragment
      }
    }
    ${CloseAccountPromptCardItem.fragments.DebitCardV2}
  `,
}
