import { useMemo } from 'react'

import { TransactionTotal } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/DashboardPerformance.types'

import {
  getInnerPiePlaceholderData,
  getOuterPiePlaceholderData,
  getThisMonthPerformanceChartData,
  getThisTimeLastMonthPerformanceChartData,
  getTotalLastMonthPerformanceChartData,
} from '../PerformanceChart.utils'
import { useMonthlyPerformance } from './useMonthlyPerformance'

interface Arguments {
  salesTotals: TransactionTotal[]
  disabled: boolean
  isLoading: boolean
}

export const useThisMonthPerformanceChartData = ({
  salesTotals,
  disabled,
  isLoading,
}: Arguments) => {
  const { totalSalesLastMonth, salesThisTimeLastMonth, salesThisMonth } =
    useMonthlyPerformance({ salesTotals })

  const totalLastMonthData = useMemo(() => {
    if (isLoading) {
      return getOuterPiePlaceholderData(100)
    }

    return getTotalLastMonthPerformanceChartData()
  }, [isLoading])

  const thisTimeLastMonthData = useMemo(() => {
    if (isLoading) {
      return getOuterPiePlaceholderData(0)
    }

    if (disabled) {
      return getOuterPiePlaceholderData()
    }

    return getThisTimeLastMonthPerformanceChartData({
      salesThisTimeLastMonth,
      totalSalesLastMonth,
    })
  }, [isLoading, disabled, salesThisTimeLastMonth, totalSalesLastMonth])

  const currentMonthData = useMemo(() => {
    if (isLoading) {
      return getInnerPiePlaceholderData(0)
    }

    if (disabled) {
      return getInnerPiePlaceholderData()
    }

    return getThisMonthPerformanceChartData({
      totalSalesLastMonth,
      salesThisMonth,
    })
  }, [isLoading, disabled, totalSalesLastMonth, salesThisMonth])

  return {
    totalSalesLastMonth,
    salesThisTimeLastMonth,
    salesThisMonth,
    totalLastMonthData,
    thisTimeLastMonthData,
    currentMonthData,
  }
}
