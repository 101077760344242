import { ApolloError } from '@apollo/client'
import { SiteType } from '@npco/mp-gql-types'

import { SITE_TYPE } from 'const/settings'
import { Site, SiteCache } from 'types/site'
import { form } from 'translations'

export const getSiteTypeValue = (siteDetails: SiteCache | null) => {
  if (!siteDetails) {
    return null
  }
  if (siteDetails.type === SITE_TYPE.FIXED) {
    return form.shared.fixedLocation
  }
  return form.shared.mobileLocation
}

export const hasCannotFindSiteError = (error: ApolloError) => {
  const subString = 'Site not found'
  // INFO: backend does not provide any error codes, so until it is implemented filtering by substring is a valid option
  return error.message.indexOf(subString) !== -1
}

const getSiteApiType = (values: Site): SiteType =>
  values.siteType === SITE_TYPE.MOBILE ? SiteType.MOBILE : SiteType.FIXED

export const composeUpdateData = (
  values: Site,
  siteDetails: SiteCache
): Partial<SiteCache> => {
  const type = getSiteApiType(values)
  if (type === SITE_TYPE.MOBILE) {
    return {
      name: values.name.trim(),
      pin: values.pin?.toString(),
      type,
      receipt: {
        ...siteDetails.receipt,
        name: values.businessName.trim(),
      },
    }
  }

  return {
    name: values.name.trim(),
    pin: values.pin?.toString(),
    type,
    address: {
      street: values.street.trim(),
      suburb: values.suburb.trim(),
      state: values.state,
      postcode: values.postcode,
    },
    receipt: {
      ...siteDetails.receipt,
      name: values.businessName.trim(),
    },
  }
}

export const getIsSiteFormDisabled = (values: Site) => {
  if (values.siteType === SITE_TYPE.MOBILE) {
    return Boolean(!values.name || !values.pin)
  }

  return Boolean(
    !values.name ||
      !values.pin ||
      !values.street ||
      !values.suburb ||
      !values.state ||
      !values.postcode
  )
}
