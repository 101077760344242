import { Flex, Heading, HeadingStyles } from '@npco/zeller-design-system'

import { StyledValue } from './ReviewItem.styled'

interface ReviewItemProps {
  label: string
  value: string
}
export const ReviewItem = ({ label, value }: ReviewItemProps) => {
  return (
    <Flex justifyContent="space-between" gridGap="8px">
      <Heading.H4 withStyles={HeadingStyles.H5}>{label}</Heading.H4>
      <StyledValue>{value}</StyledValue>
    </Flex>
  )
}
