import {
  InputAdaptiveFieldWrapper,
  TEXT_INPUT_SIZE,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { validateEztaAccountName } from 'forms/formViews/BankAccountFields/BankAccountFields.utils'

export const AccountNameField = () => {
  return (
    <InputAdaptiveFieldWrapper
      label={translate('page.createAccountModal.accountNameLabel')}
      id="name"
      name="name"
      type="text"
      size={TEXT_INPUT_SIZE.SMALL}
      validate={validateEztaAccountName}
    />
  )
}
