import {
  Animation,
  Box,
  BoxProps,
  COLOR,
  Flex,
  slideDownAnimationVariants,
  SvgIcon,
  ToggleForm,
  TooltipBasic,
} from '@npco/zeller-design-system'
import { Field } from 'formik'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import * as styled from './ToggleFormField.styled'

interface ToggleFormFieldProps {
  children?: React.ReactNode
  childrenMarginTop?: string
  label: string
  marginBottom?: BoxProps['marginBottom']
  name: string
  value: boolean
  tooltipMessage?: string
}

export const ToggleFormField = ({
  children,
  childrenMarginTop = '24px',
  label,
  marginBottom,
  name,
  value,
  tooltipMessage,
}: ToggleFormFieldProps) => {
  return (
    <Box mb={marginBottom}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <styled.ToggleLabel htmlFor={name}>{label}</styled.ToggleLabel>
          {tooltipMessage && (
            <TooltipBasic
              placement="top"
              renderTrigger={({ ref, handlers }) => {
                return (
                  <span {...handlers} ref={ref}>
                    <SvgIcon
                      dataTestId="info-icon"
                      width="16"
                      height="16"
                      color={COLOR.GREY_250}
                    >
                      <InfoIcon />
                    </SvgIcon>
                  </span>
                )
              }}
            >
              <styled.TooltipMessage>{tooltipMessage}</styled.TooltipMessage>
            </TooltipBasic>
          )}
        </Flex>
        <Field component={ToggleForm} name={name} value={value} />
      </Flex>
      {children && (
        <Animation shouldShow={value} variants={slideDownAnimationVariants}>
          <Box mt={childrenMarginTop}>{children}</Box>
        </Animation>
      )}
    </Box>
  )
}
