import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

import { CopyButtonWithTooltip } from '../../../../../../../../components/CopyButtonWithTooltip'
import { setupFlowDebitCardSensitiveInformationTranslations } from '../../SensitiveCardInformation.i18n'
import { SensitiveInfoLabel } from '../SensitiveContentMobile.styled'

interface CardPanInformationProps {
  isLoading: boolean
  copySuccessMap: Record<string, boolean>
}

export const CardPanInformation = ({
  isLoading,
  copySuccessMap,
}: CardPanInformationProps) => {
  const t = useTranslations(setupFlowDebitCardSensitiveInformationTranslations)

  return (
    <Flex
      justifyContent="space-between"
      position="relative"
      alignItems="center"
    >
      <SensitiveInfoLabel>{t('panLabel')}</SensitiveInfoLabel>
      <Flex flexGrow={1} justifyContent="flex-end">
        {isLoading && <SkeletonBasic width={200} height={8} isRounded />}
        <Box id="mq-card-pan" position="absolute" top="2px" left="104px" />

        <Box
          opacity={isLoading ? 0 : 1}
          position="absolute"
          top="4px"
          left="calc(100% - 16px)"
        >
          <CopyButtonWithTooltip
            id="mq-copy-card-pan"
            copySuccessMap={copySuccessMap}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
