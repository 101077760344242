import { Flex } from '@npco/zeller-design-system'

import { ReportStatusPicker } from 'pages/Reports/ReportsFilters/ReportStatusPicker/ReportStatusPicker'
import { ContentCollapser } from 'components/ContentCollapser/ContentCollapser'
import { useDatePickerState } from 'components/Filters/NewFilters/DatePicker/hooks/useDatePickerState'
import { UncontrolledDatePicker } from 'components/Filters/NewFilters/DatePicker/UncontrolledDatePicker'
import { SourcePicker } from 'components/Filters/NewFilters/SourcePicker/SourcePicker'
import { TypePicker } from 'components/Filters/NewFilters/TypePicker/TypePicker'
import { SiteDevicePicker } from 'components/SiteDevicePicker/SiteDevicePicker'

import { getInitialPeriod, getLastThirtyDays } from '../Reports.utils'

interface ReportsFiltersProps {
  areFiltersVisible: boolean
}

export const ReportsFilters = ({ areFiltersVisible }: ReportsFiltersProps) => {
  const {
    data,
    enteredTo,
    selectedPeriod,
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
    onPeriodChange,
    outsideClickWrapperRef,
    hasCustomDateApplied,
    fromTime,
    toTime,
    onFromTimeChange,
    onToTimeChange,
    onFromInputBlur,
    onToInputBlur,
  } = useDatePickerState({
    initialState: getInitialPeriod(),
    defaultState: getLastThirtyDays(),
  })

  return (
    <ContentCollapser isActive={areFiltersVisible}>
      <Flex flexDirection="row" flexWrap="wrap">
        <Flex
          ref={outsideClickWrapperRef}
          width={{ _: '100%', MD: '66.67%' }}
          pr={{ _: 0, SM: '1rem' }}
        >
          <UncontrolledDatePicker
            data={data}
            enteredTo={enteredTo}
            selectedPeriod={selectedPeriod}
            handleResetClick={handleResetClick}
            handleDayClick={handleDayClick}
            handleDayMouseEnter={handleDayMouseEnter}
            onPeriodChange={onPeriodChange}
            hasCustomDateApplied={hasCustomDateApplied}
            fromTime={fromTime}
            toTime={toTime}
            onFromTimeChange={onFromTimeChange}
            onToTimeChange={onToTimeChange}
            onPropsFromInputBlur={onFromInputBlur}
            onPropsToInputBlur={onToInputBlur}
            isDateTime
          />
        </Flex>
        <Flex
          width={{ _: '100%', SM: '50%', MD: '33.33%' }}
          mt={['0.5rem', '0.5rem', '1rem', 0]}
          pr={[0, 0, '1rem', 0]}
        >
          <SiteDevicePicker />
        </Flex>
        <Flex
          width={{ _: '100%', SM: '50%', MD: '33.33%' }}
          pr={[0, 0, '1rem']}
          mt={['0.5rem', '0.5rem', '1rem', '1rem']}
        >
          <TypePicker />
        </Flex>
        <Flex
          width={{ _: '100%', SM: '50%', MD: '33.33%' }}
          pr={[0, 0, '1rem']}
          mt={['0.5rem', '0.5rem', '1rem', '1rem']}
        >
          <ReportStatusPicker />
        </Flex>
        <Flex
          width={{ _: '100%', SM: '50%', MD: '33.33%' }}
          mt={['0.5rem', '0.5rem', '1rem', '1rem']}
          pr={[0, 0, '1rem', 0]}
          mb="1rem"
        >
          <SourcePicker />
        </Flex>
      </Flex>
    </ContentCollapser>
  )
}
