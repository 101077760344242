import { useHistory } from 'react-router'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'
import { getDevicesText } from 'utils/devices/devices.utils'
import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { Card } from 'components/Cards/Card'

export const SiteListItem = ({ site }: { site: SiteData }) => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()
  const { id, name, devices } = site
  return (
    <Card
      key={id}
      text={name}
      textSmall={getDevicesText(devices?.length)}
      isClickable
      onCardClick={() => {
        history.push(
          ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.GENERAL.path,
          {
            // NOTE: temporary solution to pass site name while reactive variables are loading
            name,
          }
        )
        rvSelectedSite(id)
      }}
    />
  )
}
