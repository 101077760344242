import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CardTypeOption,
  CreateSetCardSettingsState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

import { SetCardSettingsFormValues } from '../SetCardSettingsForm.types'
import { getInitialIsAccessibleCard } from './useSetCardSettingsForm.utils'

export const useSetCardSettingsForm = () => {
  const { goToAddCardStage } = useGoToAddCardStage()
  const { state } = useAddCardState<CreateSetCardSettingsState>()

  const submitCardSettingStage = useCallback(
    ({ nickname, isAccessibleCard }: SetCardSettingsFormValues) => {
      if (!state) {
        const submitActionErrorMessage = translate(
          'page.addCardModal.setCardSettings.submitActionErrorMessage'
        )
        ErrorLogger.report('[Banking] set card settings stage', {
          submitActionErrorMessage,
        })
        showErrorToast()
        return
      }

      const nicknameState = {
        nickname: nickname.trim(),
        usePin: !isAccessibleCard,
      }

      if (state.cardType === CardTypeOption.ExpenseCard) {
        goToAddCardStage({
          ...state,
          stage: AddCardStage.CreateSpendControlsStage,
          cardType: state.cardType,
          ...nicknameState,
        })
        return
      }

      goToAddCardStage({
        ...state,
        stage: AddCardStage.CreateSelectCardDesignStage,
        ...nicknameState,
      })
    },
    [goToAddCardStage, state]
  )

  const goBackToSelectCardFormatStage = useCallback(() => {
    if (!state) {
      const submitActionErrorMessage = translate(
        'page.addCardModal.setCardSettings.backActionErrorMessage'
      )
      ErrorLogger.report('[Banking] set card settings stage', {
        submitActionErrorMessage,
      })
      showErrorToast()
      return
    }

    goToAddCardStage({
      ...state,
      stage: AddCardStage.CreateSelectCardFormatStage,
    })
  }, [goToAddCardStage, state])

  return {
    submitCardSettingStage,
    goBackToSelectCardFormatStage,
    initialAccessibleCard: getInitialIsAccessibleCard(state),
    initialNickname: state?.nickname || '',
  }
}
