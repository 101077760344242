import { useCallback } from 'react'
import {
  INVOICE_ITEMS_FIELD,
  INVOICE_ITEMS_PRICE_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceDiscountFormFields,
  InvoiceFormFields,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

export interface UseInvoiceItemDiscountProps {
  index: number
  onClose: () => void
}

export const useInvoiceItemDiscount = ({
  onClose: handleOnClose,
  index,
}: UseInvoiceItemDiscountProps) => {
  const { setFieldTouched, setFieldValue, values } =
    useFormikContext<InvoiceFormFields>()

  const initialValues = values.items[index].discount
  const invoiceValues = values
  const itemValues = values.items[index]

  const handleSubmit = useCallback(
    (formValues: InvoiceDiscountFormFields) => {
      const discount = {
        ...initialValues,
        ...formValues,
      }

      setFieldValue(
        `${INVOICE_ITEMS_FIELD}.${index}`,
        { ...itemValues, discount },
        true
      )

      // NOTE: set price field touched in order to show error validation
      setFieldTouched(
        `${INVOICE_ITEMS_FIELD}.${index}.${INVOICE_ITEMS_PRICE_FIELD}`,
        true
      )

      handleOnClose()
    },
    [
      handleOnClose,
      index,
      initialValues,
      itemValues,
      setFieldTouched,
      setFieldValue,
    ]
  )

  return {
    handleSubmit,
    initialValues,
    invoiceValues,
    itemValues,
  }
}
