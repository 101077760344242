import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { EditDebitCardTransactionNotesLocationStateSchema } from '../../EditDebitCardTransactionNotes.types'

export const useEditDebitCardTransactionNotesState = () => {
  const locationState = useLocationState(
    EditDebitCardTransactionNotesLocationStateSchema
  )

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.EditDebitCardTransactionNotesModal
  }, [locationState])

  return { state }
}
