import { useCallback, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { COLOR, DownloadIcon, SvgIcon } from '@npco/zeller-design-system'
import { rvSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { StatementPeriod } from 'types/dates'
import { StatementsBaseButton } from 'components/Statements/StatementsButtons/StatementsBaseButton/StatementsBaseButton'
import { StyledIconWrapper } from 'components/Statements/StatementsButtons/StatementsBaseButton/StatementsBaseButton.styled'

interface Props {
  getStatement: any
  isLoadingData: boolean
  hasLoaded: boolean
}

export const CustomRangeButton = ({
  getStatement,
  isLoadingData,
  hasLoaded,
}: Props) => {
  const pickerDates = useReactiveVar(rvSelectedDates)
  const [isChanged, setIsChanged] = useState(false)
  const hasDateRangePicked = pickerDates.from && pickerDates.to

  const generateFile = useCallback(() => {
    if (!hasDateRangePicked) {
      return
    }
    getStatement(StatementPeriod.CustomRange)
    setIsChanged(false)
  }, [hasDateRangePicked, getStatement])

  useEffect(() => {
    setIsChanged(true)
  }, [pickerDates])

  if (isChanged) {
    return (
      <StyledIconWrapper
        onClick={generateFile}
        data-testid="btn-changed"
        disabled={!hasDateRangePicked}
      >
        <SvgIcon
          height="16"
          width="16"
          color={!hasDateRangePicked ? COLOR.GREY_250 : COLOR.BLUE_1000}
        >
          <DownloadIcon />
        </SvgIcon>
      </StyledIconWrapper>
    )
  }

  return (
    <StatementsBaseButton
      isLoadingData={isLoadingData}
      hasLoaded={hasLoaded}
      generateFile={generateFile}
    />
  )
}
