import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SelectDeliveryAddressStageForm } from './SelectDeliveryAddressStageForm/SelectDeliveryAddressStageForm'

export const SelectDeliveryAddressStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={
        state?.stage === AddCardStage.CreateSelectDeliveryAddressStage
      }
    >
      <SelectDeliveryAddressStageForm dataTestId="select-delivery-address-stage" />
    </Condition>
  )
}
