import { Navigate, Outlet, Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

import { General } from './General/General'
import { ManageXeroPaymentServicesLayout } from './ManageXeroPaymentServicesLayout'
import { Receipt } from './Receipt/Receipt'
import { Users } from './Users/Users'
import { XeroUserDrawer } from './XeroUserDrawer'

export const ManageXeroPaymentServicesRoutes = () => {
  return (
    <Routes>
      <Route element={<ManageXeroPaymentServicesLayout />}>
        <Route
          index
          element={
            <Navigate
              to={
                ROOT.ORGS.ORG().SETTINGS.CONNECTIONS
                  .XERO_PAYMENT_SERVICES_MANAGE.GENERAL.relative
              }
              replace
            />
          }
        />
        <Route
          path={
            ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE
              .GENERAL.relative
          }
          element={<General />}
        />
        <Route
          path={
            ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE
              .RECEIPT.relative
          }
          element={<Receipt />}
        />
        <Route
          path={
            ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE
              .USERS.relative
          }
          element={
            <Users>
              <Outlet />
            </Users>
          }
        >
          <Route
            path={
              ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS.USER()
                .relative
            }
            element={<XeroUserDrawer />}
          />
        </Route>
      </Route>
    </Routes>
  )
}
