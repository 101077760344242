import { Dispatch, SetStateAction } from 'react'
import { ContactType } from '@npco/mp-gql-types'

import {
  StyledContactHeaderButton,
  StyledContactHeaderContainer,
} from 'components/ContactUuidSelectField/ContactUuidSelectHeader/ContactUuidSelectHeader.styled'
import { page } from 'translations'

interface ContactHeaderProps {
  closeMenu: () => void
  onAddNewContact: () => void
  setAddContactType: Dispatch<SetStateAction<ContactType | null>>
  openContactFormModal: () => void
  contactType?: ContactType
}

export const ContactHeader = ({
  closeMenu,
  onAddNewContact,
  setAddContactType,
  openContactFormModal,
  contactType,
}: ContactHeaderProps) => {
  const showAddPerson = contactType !== ContactType.BUSINESS
  const showAddBusiness = contactType !== ContactType.PERSON
  const handleClick = (contactType: ContactType) => {
    setAddContactType(contactType)
    onAddNewContact()
    openContactFormModal()
    closeMenu()
  }

  return (
    <StyledContactHeaderContainer>
      {showAddPerson && (
        <StyledContactHeaderButton
          dataTestId="add-person-transfer"
          onClick={() => handleClick(ContactType.PERSON)}
        >
          {page.contacts.form.addPersonButton}
        </StyledContactHeaderButton>
      )}
      {showAddBusiness && (
        <StyledContactHeaderButton
          dataTestId="add-business-transfer"
          onClick={() => handleClick(ContactType.BUSINESS)}
        >
          {page.contacts.form.addBusinessButton}
        </StyledContactHeaderButton>
      )}
    </StyledContactHeaderContainer>
  )
}
