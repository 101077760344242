import { useEffect, useMemo, useState } from 'react'
import { TabItemProps } from '@npco/zeller-design-system'

import { isNotNull } from 'utils/common'

export const DEVICE_TAB_TYPE = {
  DETAILS: 'details',
  POS_SETTINGS: 'posSettings',
  SCREEN_SETTINGS: 'screenSettings',
}

export const usePaymentDeviceTabs = (
  areDetailsOpen: boolean,
  isMobile: boolean,
  isPosDataAvailable: boolean
) => {
  const [selectedTab, setSelectedTab] = useState(DEVICE_TAB_TYPE.DETAILS)

  useEffect(() => {
    // Reset the tabs to initial state when drawer is closed
    if (!areDetailsOpen) {
      setSelectedTab(DEVICE_TAB_TYPE.DETAILS)
    }
  }, [areDetailsOpen])

  const deviceDetailsTab = useMemo(() => {
    return {
      id: 'device-detail-tab',
      value: DEVICE_TAB_TYPE.DETAILS,
      children: 'Details',
      onClick: () => setSelectedTab(DEVICE_TAB_TYPE.DETAILS),
    }
  }, [])

  const posSettingsTab = useMemo(
    () =>
      !isMobile
        ? {
            id: 'device-pos-settings-tab',
            value: DEVICE_TAB_TYPE.POS_SETTINGS,
            children: 'POS Settings',
            onClick: () => setSelectedTab(DEVICE_TAB_TYPE.POS_SETTINGS),
            isDisabled: !isPosDataAvailable,
          }
        : null,
    [isPosDataAvailable, isMobile]
  )

  const screenSettingsTab = useMemo(
    () =>
      !isMobile
        ? {
            id: 'device-screen-settings-tab',
            value: DEVICE_TAB_TYPE.SCREEN_SETTINGS,
            children: 'Screen Settings',
            onClick: () => setSelectedTab(DEVICE_TAB_TYPE.SCREEN_SETTINGS),
          }
        : null,
    [isMobile]
  )

  const tabs: TabItemProps[] = useMemo(
    () =>
      [deviceDetailsTab, posSettingsTab, screenSettingsTab].filter(isNotNull),
    [deviceDetailsTab, posSettingsTab, screenSettingsTab]
  )

  return {
    selectedTab,
    tabs,
  }
}
