import { useCallback, useMemo } from 'react'
import shortUUID from 'short-uuid'

import { DEFAULT_ENTITY, UUID_REGEXP } from '../../constants'
import { rvEntityDetails } from '../../graphql/reactiveVariables'

export const useParseEntityIdParam = () => {
  const translator = useMemo(() => shortUUID(), [])

  const parseEntityIdParam = useCallback(
    (entityIdParam: string | undefined) => {
      if (entityIdParam === undefined || entityIdParam === '') {
        return { type: 'no entity id' as const, entityId: null }
      }

      if (entityIdParam === DEFAULT_ENTITY) {
        return {
          type: 'default entity' as const,
          entityId: rvEntityDetails().id,
        }
      }

      if (UUID_REGEXP.test(entityIdParam)) {
        return { type: 'uuid' as const, entityId: entityIdParam }
      }

      if (!translator.validate(entityIdParam)) {
        return { type: 'invalid uuid' as const, entityId: null }
      }

      return {
        type: 'uuid' as const,
        entityId: translator.toUUID(entityIdParam),
      }
    },
    [translator]
  )

  return { parseEntityIdParam }
}
