import { BodyDefault, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Title = styled(Heading.H3)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const Subtitle = styled(Heading.H4)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const Description = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const ModalWrapper = styled.div`
  box-sizing: border-box;
  max-width: 448px;
`
