import { createTranslations } from '@npco/utils-translations'

export const timeForASelfieDescriptionTranslations = createTranslations({
  description1:
    'We use a photo of your ID and a selfie to help verify who you are. We match these photos, solely for security purposes, to ensure someone is not using your details to open an account.',
  description2: 'Use your mobile to take a photo of:',
  listItem1: 'your ID',
  listItem2: 'your face',
  description3: 'Confirm your previously selected ID:',
})
