import { useCallback, useMemo } from 'react'
import { ReceiptNotificationType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { FormikErrors } from 'formik'

import { useSendSMSRemittance } from '../../hooks/useSendSMSRemittance/useSendSMSRemittance'
import { transferRemittancesButtonsTranslations } from '../../TransferRemittancesButtons/TransferRemittancesButtons.i18n'
import { formatToInternationalPhoneNumber } from '../../utils/formatMobileNumber/formatMobileNumber'
import { TransferRemittancesModal } from '../TransferRemittancesModal'
import { RemittanceData } from '../TransferRemittancesModal.types'
import { SMSRemittanceFields } from './SMSRemittanceFields/SMSRemittanceFields'

type SMSRemittanceModalProps = {
  isOpen: boolean
  closeModal: () => void
  currentUserMobileNumber: string
  transactionID: string
  contactPhone?: string
}

export const SMSRemittanceModal = ({
  isOpen,
  closeModal,
  currentUserMobileNumber,
  transactionID,
  contactPhone,
}: SMSRemittanceModalProps) => {
  const t = useTranslations(transferRemittancesButtonsTranslations)
  const { sendSMSRemittance, isSending } = useSendSMSRemittance()

  const checkIfCurrentUserDetails = useCallback(
    (value: string) => {
      const formattedInputNumber = value?.replace(/\s/g, '')

      const formattedInternationalMobileNumber =
        formatToInternationalPhoneNumber(formattedInputNumber)
      return formattedInternationalMobileNumber === currentUserMobileNumber
    },
    [currentUserMobileNumber]
  )

  const sendNotification = useCallback(
    async (values: RemittanceData) => {
      const formattedInputNumber = values.sms?.replace(/\s/g, '')
      const isSenderNotified =
        !checkIfCurrentUserDetails(values.sms) && values.smsCheckbox

      await sendSMSRemittance(
        {
          dcaTransactionUuid: transactionID,
          isSenderNotified,
          type: ReceiptNotificationType.SMS,
          recipientMobile:
            formatToInternationalPhoneNumber(formattedInputNumber),
        },
        closeModal
      )
    },
    [checkIfCurrentUserDetails, closeModal, sendSMSRemittance, transactionID]
  )

  const isPrimaryButtonDisabled = useMemo(
    () => (values: RemittanceData, errors: FormikErrors<RemittanceData>) => {
      return !values.sms || Boolean(errors.sms)
    },
    []
  )

  return (
    <TransferRemittancesModal
      isOpen={isOpen}
      onCancel={closeModal}
      title={t('smsRemittance')}
      description={t('smsRemittanceDescription')}
      sendRemittance={sendNotification}
      isLoading={isSending}
      isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      contactPhone={contactPhone}
    >
      <SMSRemittanceFields
        checkBoxDescription={t('smsRemittanceCheckBox')}
        placeholder={t('mobileNumber')}
        currentUserMobileNumber={currentUserMobileNumber}
      />
    </TransferRemittancesModal>
  )
}
