import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetTransferRemittancePdfQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  dcaTransactionUuid: Types.Scalars['String']['input']
}>

export type GetTransferRemittancePdfQueryResponse = {
  __typename?: 'Query'
  getTransferRemittancePdf: {
    __typename?: 'TransferRemittancePdfDownload'
    downloadLink: string | null
    error: Types.TransferRemittancePdfErrorEnum | null
    expire: any | null
  }
}

export const GetTransferRemittancePdf = gql`
  query GetTransferRemittancePdf(
    $entityUuid: ID!
    $dcaTransactionUuid: String!
  ) {
    getTransferRemittancePdf(
      entityUuid: $entityUuid
      dcaTransactionUuid: $dcaTransactionUuid
    ) {
      downloadLink
      error
      expire
    }
  }
` as unknown as TypedDocumentNode<
  GetTransferRemittancePdfQueryResponse,
  GetTransferRemittancePdfQueryVariables
>

/**
 * __useGetTransferRemittancePdfQuery__
 *
 * To run a query within a React component, call `useGetTransferRemittancePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferRemittancePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferRemittancePdfQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      dcaTransactionUuid: // value for 'dcaTransactionUuid'
 *   },
 * });
 */
export function useGetTransferRemittancePdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransferRemittancePdfQueryResponse,
    GetTransferRemittancePdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTransferRemittancePdfQueryResponse,
    GetTransferRemittancePdfQueryVariables
  >(GetTransferRemittancePdf, options)
}
export function useGetTransferRemittancePdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransferRemittancePdfQueryResponse,
    GetTransferRemittancePdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTransferRemittancePdfQueryResponse,
    GetTransferRemittancePdfQueryVariables
  >(GetTransferRemittancePdf, options)
}
export type GetTransferRemittancePdfQueryHookResult = ReturnType<
  typeof useGetTransferRemittancePdfQuery
>
export type GetTransferRemittancePdfLazyQueryHookResult = ReturnType<
  typeof useGetTransferRemittancePdfLazyQuery
>
export type GetTransferRemittancePdfQueryResult = Apollo.QueryResult<
  GetTransferRemittancePdfQueryResponse,
  GetTransferRemittancePdfQueryVariables
>
