import { TransactionExportFormat } from '@npco/mp-gql-types'

import { useExportTransactionsModalContent } from 'pages/Dashboard/DashboardTransactions/PaymentTransactions/ExportTransactionsModal/hooks/useExportTransactionsModalContent'
import { ExportFileModalContent } from 'components/ExportFileModalContent/ExportFileModalContent'

interface Props {
  filename: string
  rerenderContent: () => void
  closeModal: () => void
  setHasTimePassed: React.Dispatch<React.SetStateAction<boolean>>
  format: TransactionExportFormat
}

export const ExportTransactionsModalContent = ({
  filename,
  closeModal,
  rerenderContent,
  setHasTimePassed,
  format,
}: Props) => {
  const { isLoading, hasError, hasLoaded } = useExportTransactionsModalContent({
    filename,
    closeModal,
    setHasTimePassed,
    format,
  })

  return (
    <ExportFileModalContent
      closeModal={closeModal}
      rerenderContent={rerenderContent}
      isLoading={isLoading}
      hasError={hasError}
      hasLoaded={hasLoaded}
    />
  )
}
