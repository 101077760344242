import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseIconAvatarIconFragmentDoc } from './hooks/useIconAvatar.generated'

export type IconAvatarIconFragment = {
  __typename?: 'Icon'
  colour: string | null
  letter: string | null
  animation: string | null
  image: string | null
  images: Array<{
    __typename?: 'Image'
    url: string
    size: Types.ImageSize
  }> | null
}

export const IconAvatarIconFragmentDoc = gql`
  fragment IconAvatarIconFragment on Icon {
    ...UseIconAvatarIconFragment
  }
  ${UseIconAvatarIconFragmentDoc}
` as unknown as TypedDocumentNode<IconAvatarIconFragment, undefined>
