import { ButtonGhost, DataSmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_150};
  ${DataSmallTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledButton = styled(ButtonGhost)`
  margin-bottom: 0;
`
