import { z } from 'zod'

const CorporateCardSettingsWalkthroughStateSchema = z.nullable(
  z.object({
    stage: z.literal('editOutstandingExpenses'),
  })
)
export type CorporateCardSettingsWalkthroughState = z.infer<
  typeof CorporateCardSettingsWalkthroughStateSchema
>

export const CorporateCardSettingsWalkthroughLocationSchema = z.object({
  CorporateCardSettingsWalkthroughModal:
    CorporateCardSettingsWalkthroughStateSchema,
})
