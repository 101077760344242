import { useTranslations } from '@npco/utils-translations'
import { isEmpty } from 'lodash-es'

import { bpayDetailsFieldTranslations } from './BpayDetailsField.i18n'

export const useBpayDetailsValidate = () => {
  const t = useTranslations(bpayDetailsFieldTranslations)

  return (value: string): string | undefined => {
    if (isEmpty(value)) {
      return t('errorRequired')
    }

    return undefined
  }
}
