import { useMemo } from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useParseEntityIdParam } from '../useParseParam/useParseParam'

export const useEntityIdParam = () => {
  const { entityId } = useParams()
  const { parseEntityIdParam } = useParseEntityIdParam()

  return useMemo(
    () => parseEntityIdParam(entityId),
    [entityId, parseEntityIdParam]
  )
}
