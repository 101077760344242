import styled from 'styled-components'

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${({ theme }) => theme.colors.GREY_150};
  overflow: hidden;
  border-radius: 8px;
  width: 48px;
  height: 48px;
`
