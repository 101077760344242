import { useCallback } from 'react'
import {
  combineValidators,
  validateRequiredCustomMsg,
} from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'

import { ItemContactOrBiller } from 'pages/Transfer/BPay/BPayTargetInput/useBPayTargetItems'

import { bpayTransferFieldsTranslations } from '../../BpayTransferFields/BpayTransferFields.i18n'
import { contactOrBillerFieldTranslations } from '../ContactOrBillerField.i18n'

type UseValidateContactOrBillerFieldProps = {
  disabledBillers: { billerCode: string }[]
}

export const useValidateContactOrBillerField = ({
  disabledBillers,
}: UseValidateContactOrBillerFieldProps) => {
  const t = useTranslations(contactOrBillerFieldTranslations)
  const tBpay = useTranslations(bpayTransferFieldsTranslations)

  const validateBillerCodeNotDisabled = useCallback(
    (value: ItemContactOrBiller | undefined) => {
      if (
        value?.type === 'Biller' &&
        disabledBillers.some(({ billerCode }) => billerCode === value.code)
      ) {
        return tBpay('billerNotSupported')
      }

      return undefined
    },
    [disabledBillers, tBpay]
  )

  return combineValidators(
    validateRequiredCustomMsg(t('required')),
    validateBillerCodeNotDisabled
  )
}
