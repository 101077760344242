import { createTranslations } from '@npco/utils-translations'

export const signUpSidebarTranslations = createTranslations({
  title: 'More ways to get paid with Zeller.',
  subtitle: 'Accept payments with Zeller Tap to Pay with free integrated POS.',
  benefit1: 'Accept contactless payments with Tap to Pay.',
  benefit2: 'Zeller POS Lite included free.',
  benefit3: 'Free Transaction Accounts and Debit Cards.',
  benefit4: 'Send online invoices for free.',
  benefit5: '7-day support via phone and email.',
})
