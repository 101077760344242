import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import {
  Box,
  InputAdaptiveField,
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Formik } from 'formik'

import { useGetSites } from 'hooks/useGetSites'
import { combineValidators, validateUnique } from 'utils/formValidation'
import { getNonNullString } from 'utils/string'
import { translate } from 'utils/translations'
import { SiteCache } from 'types/site'
import { DeviceWithSiteInfo } from 'pages/Devices/Devices.types'

import { validateDeviceName } from './EditDeviceNameModal.utils'
import { useUpdateDeviceName } from './hooks/useUpdateDeviceName'

interface EditFormValues {
  deviceName: string
}

interface EditDeviceNameModalProps {
  selectedDevice: DeviceWithSiteInfo
  setSelectedDevice: React.Dispatch<
    React.SetStateAction<DeviceWithSiteInfo | null>
  >
  onCancel: () => void
  isOpen: boolean
  isSiteDevicesPage?: boolean
}

const SITES_QUERY_PAGE_SIZE = 100

export const EditDeviceNameModal = ({
  selectedDevice,
  setSelectedDevice,
  onCancel,
  isOpen,
  isSiteDevicesPage = false,
}: EditDeviceNameModalProps) => {
  const entityUuid = useSelectedEntityUuid()
  const fetchPolicy = isSiteDevicesPage ? 'cache-and-network' : 'cache-first'

  const { sites, hasMore, onLoadMore, isLoadingMore } = useGetSites({
    pageSize: SITES_QUERY_PAGE_SIZE,
    fetchPolicy,
  })

  const { updateDeviceName, isLoading: isUpdatingDeviceName } =
    useUpdateDeviceName()

  useEffect(() => {
    if (hasMore && !isLoadingMore) {
      onLoadMore()
    }
  }, [hasMore, isLoadingMore, onLoadMore])

  const deviceNames = useMemo(
    () =>
      !hasMore
        ? sites
            .map(
              (site) =>
                site.devices?.map(
                  (device) => device.name?.toLowerCase() || ''
                ) || ''
            )
            .flat()
        : [],
    [hasMore, sites]
  )

  const validateUniqueness = (inputValue: string, initialInputValue: string) =>
    combineValidators(
      validateDeviceName,
      validateUnique(
        inputValue.toLowerCase(),
        initialInputValue.toLowerCase(),
        deviceNames,
        translate('errorMessages.deviceNameUsed')
      )
    )

  const handleOnSubmit = useCallback(
    async (values: EditFormValues) => {
      const response = await updateDeviceName({
        variables: {
          entityUuid,
          deviceUuid: selectedDevice.id,
          name: values.deviceName.trim(),
        },
        update: (cache, result) => {
          if (result.data?.updateDeviceName && isSiteDevicesPage) {
            const oldSiteDetails = rvSiteDetails() as SiteCache
            const updatedDevices = oldSiteDetails.devices.map((device) => {
              if (device.id === selectedDevice.id) {
                return {
                  id: device.id,
                  name: values.deviceName.trim(),
                  model: device.model,
                  serial: device.serial,
                }
              }
              return device
            })
            rvSiteDetails({
              ...oldSiteDetails,
              devices: updatedDevices,
            })
            return
          }
          if (result.data?.updateDeviceName) {
            cache.modify({
              id: cache.identify({
                id: selectedDevice.id,
                __typename: 'Device',
              }),
              fields: {
                name() {
                  return values.deviceName.trim()
                },
              },
            })
          }
        },
      })
      if (!response.data?.updateDeviceName) {
        showErrorToast(translate('errorMessages.somethingWentWrong'))
        return
      }

      showSuccessToast(
        translate('component.editDeviceNameModal.updateSuccessToast')
      )

      setSelectedDevice({ ...selectedDevice, name: values.deviceName })
      onCancel()
    },
    [
      entityUuid,
      isSiteDevicesPage,
      onCancel,
      selectedDevice,
      setSelectedDevice,
      updateDeviceName,
    ]
  )
  return (
    <Formik<EditFormValues>
      enableReinitialize
      initialValues={{
        deviceName: getNonNullString(selectedDevice.name),
      }}
      onSubmit={async (values) => {
        try {
          await handleOnSubmit(values)
        } catch (error) {
          showErrorToast()
          ErrorLogger.report(
            '[Payment] Failed to edit name of a terminal',
            error
          )
        }
      }}
    >
      {({ submitForm, isValid, values, initialValues, dirty }) => (
        <ModalForm
          title={translate('component.editDeviceNameModal.title')}
          isOpen={isOpen}
          onCancel={onCancel}
          onClickPrimary={submitForm}
          secondaryButtonLabel={translate('shared.cancel')}
          primaryButtonLabel={translate('shared.save')}
          isPrimaryButtonDisabled={!isValid || hasMore || !dirty}
          isLoading={isUpdatingDeviceName}
        >
          <form>
            <Box mt="6px">
              <InputAdaptiveField
                name="deviceName"
                label={translate(
                  'component.editDeviceNameModal.deviceNameLabel'
                )}
                validate={validateUniqueness(
                  values.deviceName,
                  initialValues.deviceName
                )}
              />
            </Box>
          </form>
        </ModalForm>
      )}
    </Formik>
  )
}
