import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardStatus/useCardStatus.generated'
import { ZellerCardThumbnailDebitCardV2FragmentDoc } from '../../../../../../../ZellerCard/ZellerCardThumbnail.generated'

export type CardNameIconCellBaseDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  maskedPan: string
}

export const CardNameIconCellBaseDebitCardV2FragmentDoc = gql`
  fragment CardNameIconCellBaseDebitCardV2Fragment on DebitCardV2 {
    ...ZellerCardThumbnailDebitCardV2Fragment
    ...UseCardStatusDebitCardV2Fragment
  }
  ${ZellerCardThumbnailDebitCardV2FragmentDoc}
  ${UseCardStatusDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardNameIconCellBaseDebitCardV2Fragment,
  undefined
>
