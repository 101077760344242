export const PAN_CONFIG_DESKTOP = {
  id: 'mq-card-pan',
  size: '9px',
}

export const EXPIRY_CONFIG_DESKTOP = {
  id: 'mq-card-exp',
  size: '9px',
}

export const CVV_CONFIG_DESKTOP = {
  id: 'mq-card-cvv',
  size: '9px',
}

export const PAN_CONFIG = {
  id: 'mq-card-pan',
  size: '16px',
}

export const EXPIRY_CONFIG = {
  id: 'mq-card-exp',
  size: '16px',
}

export const CVV_CONFIG = {
  id: 'mq-card-cvv',
  size: '16px',
}

export const COPY_PAN_CONFIG = {
  id: 'mq-copy-card-pan',
}

export const COPY_EXPIRY_CONFIG = {
  id: 'mq-copy-card-exp',
}

export const COPY_CVV_CONFIG = {
  id: 'mq-copy-card-cvv',
}
