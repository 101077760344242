import { CardScheme, TransactionIssuer } from '@npco/mp-gql-types'

import { ReactComponent as AfterpayLogo } from 'assets/svg/payment-providers/afterpay.svg'
import { ReactComponent as AmexLogo } from 'assets/svg/payment-providers/amex.svg'
import { ReactComponent as CUPLogo } from 'assets/svg/payment-providers/cup.svg'
import { ReactComponent as DinersLogo } from 'assets/svg/payment-providers/diners.svg'
import { ReactComponent as EftposLogo } from 'assets/svg/payment-providers/eftpos.svg'
import { ReactComponent as JCBLogo } from 'assets/svg/payment-providers/jcb.svg'
import { ReactComponent as MasterCardLogo } from 'assets/svg/payment-providers/mastercard.svg'
import { ReactComponent as OtherLogo } from 'assets/svg/payment-providers/other.svg'
import { ReactComponent as VisaLogo } from 'assets/svg/payment-providers/visa.svg'
import { component } from 'translations'

type CardIconsProps = {
  [key in CardScheme]: JSX.Element | null
}

type CardSchemaDescriptionProps = {
  [key in CardScheme]: string | null
}

export const cardIcons: CardIconsProps = {
  [CardScheme.VISA]: <VisaLogo />,
  [CardScheme.MC]: <MasterCardLogo />,
  [CardScheme.AMEX]: <AmexLogo />,
  [CardScheme.JCB]: <JCBLogo />,
  [CardScheme.EFTPOS]: <EftposLogo />,
  [CardScheme.CUP]: <CUPLogo />,
  [CardScheme.DINERS]: <DinersLogo />,
  [CardScheme.OTHER]: <OtherLogo />,
}

export const cardSchemaDescription: CardSchemaDescriptionProps = {
  [CardScheme.VISA]: component.cardSchema.visa,
  [CardScheme.MC]: component.cardSchema.mc,
  [CardScheme.AMEX]: component.cardSchema.amex,
  [CardScheme.JCB]: component.cardSchema.jcb,
  [CardScheme.EFTPOS]: component.cardSchema.eftpos,
  [CardScheme.CUP]: component.cardSchema.cup,
  [CardScheme.DINERS]: component.cardSchema.diners,
  [CardScheme.OTHER]: component.cardSchema.other,
}

type TransactionIssuerProps = {
  [key in TransactionIssuer]: JSX.Element | null
}

type IssuerTitleProps = {
  [key in TransactionIssuer]: string | null
}

export const issuerIcons: TransactionIssuerProps = {
  [TransactionIssuer.AFTERPAY]: <AfterpayLogo />,
}

export const issuerTitles: IssuerTitleProps = {
  [TransactionIssuer.AFTERPAY]: component.issuer.afterpay,
}
