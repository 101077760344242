import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { ContactDetails } from 'features/Contacts/Contact/ContactSections/ContactDetails/ContactDetails'
import {
  getContactIconLetter,
  getDeletedContactInitialValues,
} from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import {
  InvoiceContactDeletedFragment,
  InvoiceContactDeletedFragment_customer_payerContact as PayerContact,
} from 'types/gql-types/InvoiceContactDeletedFragment'

import { translations } from './InvoiceDrawerContact.i18n'

type GetContact = ContactCoreFieldsFragment

const getContactFromPayerContact = ({
  businessName,
  contactType,
  firstName,
  lastName,
  payerEmail,
}: {
  payerEmail: string | null
} & PayerContact): GetContact => {
  const contact: GetContact = {
    ...getDeletedContactInitialValues(),
    email: { email: payerEmail ?? '', label: null },
    firstName,
    lastName,
    businessName,
    contactType,
  }

  return {
    ...contact,
    icon: {
      colour: null,
      images: null,
      letter: getContactIconLetter(contact),
    },
  }
}

export const InvoiceDrawerContactDeleted = ({
  customer,
}: InvoiceContactDeletedFragment) => {
  const t = useTranslations(translations)
  if (!customer?.payerContact) {
    return null
  }

  return (
    <>
      <Box mb="40px">
        <ContactDetails
          contact={getContactFromPayerContact({
            businessName: customer.payerContact.businessName,
            contactType: customer.payerContact.contactType,
            firstName: customer.payerContact.firstName,
            lastName: customer.payerContact.lastName,
            payerEmail: customer.payerEmail,
          })}
          useCategoryPills={false}
        />
      </Box>
      <InfoBox
        priority={INFOBOX_PRIORITY.MEDIUM}
        variant={INFOBOX_VARIANT.POSITIVE}
      >
        <InfoBox.Message>{t('deletedContactInfobox')}</InfoBox.Message>
      </InfoBox>
    </>
  )
}

InvoiceDrawerContactDeleted.fragments = {
  InvoiceContactDeleted: gql`
    fragment InvoiceContactDeletedFragment on Invoice {
      customer {
        payerContact {
          businessName
          contactType
          firstName
          lastName
        }
        payerEmail
      }
    }
  `,
}
