import { forwardRef } from 'react'
import {
  Animation,
  Box,
  fadeInAnimationVariants,
  Flex,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-dropdown.svg'
import { ReactComponent as ErrorIcon } from 'assets/svg/error.svg'
import { conditionalClassName as cn } from 'utils/conditionalClassName'

import { SelectSize, SelectTriggerBasicProps } from '../Select.types'
import {
  StyledSelectIcon,
  StyledSelectTrigger,
  StyledSelectTriggerContent,
} from './SelectTrigger.styled'

export const SelectTriggerBasic = forwardRef<
  HTMLButtonElement,
  SelectTriggerBasicProps
>(
  (
    {
      isOpen,
      children,
      size = SelectSize.Medium,
      className = '',
      hasError,
      onFocus,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledSelectTrigger
        {...rest}
        onFocus={() => onFocus?.(isOpen)}
        tabIndex={0}
        className={cn({
          [size]: true,
          [className]: Boolean(className),
        })}
        isOpen={isOpen}
        hasError={hasError}
        ref={ref}
      >
        <StyledSelectTriggerContent>{children}</StyledSelectTriggerContent>

        <Flex>
          <Animation
            shouldShow={Boolean(hasError)}
            variants={fadeInAnimationVariants}
          >
            <Box mr="0.5rem">
              <SvgIcon height="16" width="16">
                <ErrorIcon />
              </SvgIcon>
            </Box>
          </Animation>

          <StyledSelectIcon isOpen={isOpen}>
            <SvgIcon width="16" height="16">
              <ArrowDown />
            </SvgIcon>
          </StyledSelectIcon>
        </Flex>
      </StyledSelectTrigger>
    )
  }
)
