import { useTranslations } from '@npco/utils-translations'
import {
  Message,
  MessageSize,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import CARDS_IN_DELIVERY_SRC from 'assets/svg/card-on-delivery.svg'
import { translationsShared } from 'translations'

import { Panel, PanelImage } from '../../../CardsView.styled'
import { translations } from '../../CardsView.ActiveCards.i18n'

interface ActiveCardsEmptyOnDeliveryProps {
  onAddCard: () => void
}
export const ActiveCardsEmptyOnDelivery = ({
  onAddCard,
}: ActiveCardsEmptyOnDeliveryProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const isMobile = useIsMobileResolution()

  return (
    <Panel>
      <PanelImage
        src={CARDS_IN_DELIVERY_SRC}
        alt={t('noActiveCardsOnDeliveryImageAlt')}
      />
      <Message
        title={t('noActiveCardsOnDeliveryTitle')}
        description={t('noActiveCardsOnDeliveryDescription')}
        primaryButton={{
          label: tShared('activeCard'),
          onClick: onAddCard,
        }}
        size={MessageSize.LARGE}
        align={isMobile ? 'center' : 'left'}
      />
    </Panel>
  )
}
