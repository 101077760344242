import { useCallback } from 'react'
import Joyride, { CallBackProps, EVENTS } from 'react-joyride'
import {
  useCorporateCardSettingsWalkthroughState,
  useGoToCorporateCardSettingsWalkthroughState,
} from 'features/CorporateCardSettingsWalkthrough/corporate-card-settings-walkthrough-routing'

import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'
import { DEFAULT_JOYRIDE_STYLE_OPTIONS } from 'components/Onboarding/Onboarding'

import { useGetCorporateCardSettingsWalkthroughSteps } from '../../hooks/useGetCorporateCardSettingsWalkthroughSteps/useGetCorporateCardSettingsWalkthroughSteps'
import { CorporateCardSettingsWalkthroughToolTips } from '../CorporateCardSettingsWalkthroughToolTips/CorporateCardSettingsWalkthroughToolTips'

export const CorporateCardSettingsWalkthroughModal = () => {
  const steps = useGetCorporateCardSettingsWalkthroughSteps()
  const { state } = useCorporateCardSettingsWalkthroughState()
  const { goToCorporateCardSettingsWalkthroughState } =
    useGoToCorporateCardSettingsWalkthroughState()
  const { updateProductTourStatus } = useCustomerProductTourStatus()

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { type } = data
      const shouldHandleNextOrBack =
        type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND

      if (shouldHandleNextOrBack) {
        updateProductTourStatus({
          showCorporateCardsSettingsWalkthrough: false,
        })
        goToCorporateCardSettingsWalkthroughState(undefined)
      }
    },
    [goToCorporateCardSettingsWalkthroughState, updateProductTourStatus]
  )

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={!!state}
      hideCloseButton
      disableScrolling
      stepIndex={0}
      steps={steps}
      spotlightPadding={0}
      styles={DEFAULT_JOYRIDE_STYLE_OPTIONS}
      tooltipComponent={CorporateCardSettingsWalkthroughToolTips}
    />
  )
}
