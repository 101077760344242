import { CustomerRole } from '@npco/mp-gql-types'

import { BusinessRowCustomerEntityRelationFragment } from '../../BusinessesList/BusinessRow/BusinessRow.generated'

export const getCustomerEntityMappingMock: (BusinessRowCustomerEntityRelationFragment & {
  entityUuid: string
})[] = [
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.ADMIN,
    isVisible: true,
    entityUuid: '1',
    entity: {
      id: '1',
      name: 'Claremont Gelati',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.ADMIN,
    isVisible: false,
    entityUuid: '2',
    entity: {
      id: '2',
      name: 'Kings Park Wine Bar',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.MANAGER,
    isVisible: true,
    entityUuid: '3',
    entity: {
      id: '3',
      name: 'Prahran Dining Hall',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.ADMIN,
    isVisible: false,
    entityUuid: '4',
    entity: {
      id: '4',
      name: 'Bean & Bloom Cafe',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.MANAGER,
    isVisible: true,
    entityUuid: '5',
    entity: {
      id: '5',
      name: 'Sunrise Sip & Snack',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.MANAGER,
    isVisible: false,
    entityUuid: '6',
    entity: {
      id: '6',
      name: 'Dallgeta Cream & Confectionery',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.ADMIN,
    isVisible: true,
    entityUuid: '7',
    entity: {
      id: '7',
      name: 'Dark Roast Desires',
    },
  },
  {
    __typename: 'CustomerEntityRelation',
    role: CustomerRole.ADMIN,
    isVisible: false,
    entityUuid: '8',
    entity: {
      id: '8',
      name: 'Sinful Sips',
    },
  },
]
