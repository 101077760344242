import {
  ReceiptNotificationType,
  TransferRemittanceErrorEnum,
  TransferRemittanceNotificationInput,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { TransferRemittancesTranslations } from '../TransferRemittances.i18n'
import { useTransferRemittanceNotification } from '../useTransferRemittanceNotification/useTransferRemittanceNotification'

const createEmailInput = (
  input: TransferRemittanceNotificationInput
): TransferRemittanceNotificationInput => {
  return {
    dcaTransactionUuid: input.dcaTransactionUuid,
    isSenderNotified: input.isSenderNotified,
    type: ReceiptNotificationType.EMAIL,
    recipientEmail: input.recipientEmail,
  }
}

export const useSendEmailRemittance = () => {
  const { sendTransferRemittance, isSending } =
    useTransferRemittanceNotification()
  const t = useTranslations(TransferRemittancesTranslations)

  const sendEmailRemittance = async (
    input: TransferRemittanceNotificationInput,
    closeModal: () => void
  ) => {
    try {
      const result = await sendTransferRemittance(createEmailInput(input))
      if (
        result?.data?.transferRemittanceNotification.error ===
        TransferRemittanceErrorEnum.TOO_MANY_REQUESTS
      ) {
        showErrorToast(t('emailRemittanceLimitReached'))
        return
      }

      if (
        result.data?.transferRemittanceNotification.error ===
        TransferRemittanceErrorEnum.TRANSACTION_NOTIFICATION_PERIOD_EXPIRED
      ) {
        showErrorToast(t('notificationPeriodExpired'))
        return
      }

      if (
        result.data?.transferRemittanceNotification.error ===
        TransferRemittanceErrorEnum.RECIPIENT_EMAIL_NOT_FOUND
      ) {
        showErrorToast(t('emailNotFound'))
        return
      }

      closeModal()
      showSuccessToast(
        t('emailRemittanceSuccess', { email: input.recipientEmail })
      )
    } catch (error) {
      showErrorToast(t('somethingWentWrong'))
    }
  }
  return { sendEmailRemittance, isSending }
}
