import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type InitiateSavingsAccountSecureSessionMutationVariables = Types.Exact<{
  input: Types.InitSecureSessionRequestInput
  entityUuid: Types.Scalars['ID']['input']
}>

export type InitiateSavingsAccountSecureSessionMutationResponse = {
  __typename?: 'Mutation'
  initiateSavingsAccountSecureSession: {
    __typename?: 'InitiateSavingsAccountSecureSession'
    secureSessionId: string
    serverPublicKey: string
  }
}

export const InitiateSavingsAccountSecureSession = gql`
  mutation InitiateSavingsAccountSecureSession(
    $input: InitSecureSessionRequestInput!
    $entityUuid: ID!
  ) {
    initiateSavingsAccountSecureSession(
      input: $input
      entityUuid: $entityUuid
    ) {
      secureSessionId
      serverPublicKey
    }
  }
` as unknown as TypedDocumentNode<
  InitiateSavingsAccountSecureSessionMutationResponse,
  InitiateSavingsAccountSecureSessionMutationVariables
>
export type InitiateSavingsAccountSecureSessionMutationFn =
  Apollo.MutationFunction<
    InitiateSavingsAccountSecureSessionMutationResponse,
    InitiateSavingsAccountSecureSessionMutationVariables
  >

/**
 * __useInitiateSavingsAccountSecureSessionMutation__
 *
 * To run a mutation, you first call `useInitiateSavingsAccountSecureSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateSavingsAccountSecureSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateSavingsAccountSecureSessionMutation, { data, loading, error }] = useInitiateSavingsAccountSecureSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useInitiateSavingsAccountSecureSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateSavingsAccountSecureSessionMutationResponse,
    InitiateSavingsAccountSecureSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InitiateSavingsAccountSecureSessionMutationResponse,
    InitiateSavingsAccountSecureSessionMutationVariables
  >(InitiateSavingsAccountSecureSession, options)
}
export type InitiateSavingsAccountSecureSessionMutationHookResult = ReturnType<
  typeof useInitiateSavingsAccountSecureSessionMutation
>
export type InitiateSavingsAccountSecureSessionMutationResult =
  Apollo.MutationResult<InitiateSavingsAccountSecureSessionMutationResponse>
export type InitiateSavingsAccountSecureSessionMutationOptions =
  Apollo.BaseMutationOptions<
    InitiateSavingsAccountSecureSessionMutationResponse,
    InitiateSavingsAccountSecureSessionMutationVariables
  >
