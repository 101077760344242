import { useMemo, useState } from 'react'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { getColorFromResponse } from '@npco/utils-colors'
import { AvatarBasic, COLOR } from '@npco/zeller-design-system'
import { rvDetailedAccountType } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/depositAccount'

import { useAccounts } from 'hooks/banking/useAccounts'
import { sortAccounts } from 'utils/banking/sortAccounts/sortAccounts'
import { translate } from 'utils/translations'
import { DebitCardAccount } from 'types/accounts'
import { Account } from 'types/settings'
import { useDepositAccount } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'
import { DisabledAccountInfo } from 'components/DepositAccountDetailDrawer/DisabledAccountInfo/DisabledAccountInfo'
import { ZellerAccountDetailDrawer } from 'components/DepositAccountDetailDrawer/ZellerAccountDetailDrawer'
import { AccountTileList } from 'components/Tile/AccountTileList/AccountTileList'
import { AccountTileRightControls } from 'components/Tile/AccountTileList/AccountTileRightControls'

import { DepositZellerAccountAction } from '../DepositAccountDetailAction/DepositZellerAccountAction'

export const DepositZellerAccountsList = () => {
  const [openedAccountId, setOpenedAccountId] = useState<string>()
  const { depositAccount } = useDepositAccount()
  const { accounts, accountById } = useAccounts()

  const debitAccounts = useMemo(
    () =>
      accounts.filter(
        (account) => account.type === DebitCardAccountType.ZLR_DEBIT
      ),
    [accounts]
  )

  const openedAccount = useMemo(
    () => accountById(openedAccountId),
    [accountById, openedAccountId]
  )

  const getBankIcon = (account: DebitCardAccount) => (
    <AvatarBasic
      bgColor={
        account.status === DebitCardAccountStatus.SUSPENDED
          ? COLOR.GREY_150
          : getColorFromResponse(account.icon.colour)
      }
      text={account.icon.letter}
    />
  )

  const getIsActive = (account: DebitCardAccount) =>
    account.id === depositAccount?.id

  const renderSuspendedAccountAction = (account: DebitCardAccount) => {
    const isSelectedAccount = getIsActive(account)
    const messageString = isSelectedAccount
      ? 'page.settings.deposits.suspended.messageSelectedAccount'
      : 'page.settings.deposits.suspended.message'
    const descriptionString = isSelectedAccount
      ? 'page.settings.deposits.suspended.descriptionSelectedAccount'
      : 'page.settings.deposits.suspended.description'

    return (
      <DisabledAccountInfo
        isSelectedAccount={isSelectedAccount}
        message={translate(messageString)}
        description={translate(descriptionString)}
      />
    )
  }

  const renderAccountAction = (account: DebitCardAccount) => (
    <DepositZellerAccountAction
      account={account}
      isSelectedAccount={getIsActive(account)}
    />
  )

  const getIsDisabled = (account: DebitCardAccount) =>
    account.status === DebitCardAccountStatus.SUSPENDED

  return (
    <>
      <ZellerAccountDetailDrawer
        isOpen={Boolean(openedAccountId)}
        account={openedAccount}
        onClose={() => setOpenedAccountId(undefined)}
        getIcon={(account) => getBankIcon(account)}
        renderBottomAction={(account) =>
          getIsDisabled(account)
            ? renderSuspendedAccountAction(account)
            : renderAccountAction(account)
        }
      />
      <AccountTileList<DebitCardAccount>
        dataTestId="zeller-accounts-list"
        getIsActive={getIsActive}
        renderRightControl={({ isActive, isDisabled }) => (
          <AccountTileRightControls
            isActive={isActive}
            isDisabled={isDisabled}
            disabledMessage={translate(
              'page.settings.sim.billingAccount.suspended.title'
            )}
          />
        )}
        bankAccounts={sortAccounts(debitAccounts).filter(
          (account) => account.status !== DebitCardAccountStatus.CLOSED
        )}
        getTileTitle={(account: DebitCardAccount) => account.name}
        getTileIcon={getBankIcon}
        getIsDisabled={getIsDisabled}
        onSelect={(account) => {
          setOpenedAccountId(account.id)
          rvDetailedAccountType(Account.ZELLER)
        }}
      />
    </>
  )
}
