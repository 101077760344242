import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  TableCellBasic,
  TableCellSize,
  TableCellSkeleton,
  TooltipBasic,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useCardProgress } from 'hooks/useCardProgress'
import { useCardStatus } from 'hooks/useCardStatus'

import { useSpentFundsCorporateCard } from '../../../../../../features/Cards/CardSingle/hooks/useSpentFundsCorporateCard/useSpentFundsCorporateCard'
import { CardSpendProgressCellDebitCardV2Fragment } from './CardSpendProgressCell.generated'
import { cardSpendProgressCellTranslations } from './CardSpendProgressCell.i18n'
import { CardSpendProgressCellContent } from './CardSpendProgressCellContent'

export const CardSpendProgressCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, CardSpendProgressCellDebitCardV2Fragment>) => {
  const t = useTranslations(cardSpendProgressCellTranslations)
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CardSpendProgressCellDebitCardV2Fragment>()

  const { isClosed, isInactive } = useCardStatus(data)
  const { availableAmount, totalAmount } = useCardProgress(data)

  const remainingPercentage = useMemo(
    () =>
      totalAmount > 0
        ? Math.round((Math.max(availableAmount, 0) * 100) / totalAmount)
        : 0,
    [totalAmount, availableAmount]
  )

  const spendAmountInCents = useSpentFundsCorporateCard({ card: data })
  const spendAmount = useMemo(
    () => currencyFormatter(spendAmountInCents),
    [spendAmountInCents]
  )

  return (
    <TooltipBasic
      offset={[0, -20]}
      placement="top"
      renderTrigger={({ handlers }) => (
        <TableCellBasic
          className="card-spend-progress-cell"
          dataTestId={cell.id}
          forwardedProps={{ style: { padding: 0 } }}
          isLoading={isLoading}
          justifyContent="flex-start"
          key={cell.id}
          size={TableCellSize.MEDIUM}
          cellTextSize="Small"
          cellSize={cellSize}
          onMouseEnter={handlers.onMouseEnter}
          onMouseLeave={handlers.onMouseLeave}
        >
          {isLoading && (
            <TableCellSkeleton
              width={skeletonLoaderWidth}
              size={TableCellSize.SMALL}
            />
          )}

          {!isLoading && !isClosed && !isInactive && (
            <CardSpendProgressCellContent card={data} />
          )}
        </TableCellBasic>
      )}
      showArrow={false}
    >
      <Box textAlign="center" width="144px">
        <Box color={COLOR.GREY_250}>
          {t('tooltipRemaining', { percentage: remainingPercentage })}
        </Box>
        <Box>{t('tooltipSpend', { amount: spendAmount })}</Box>
      </Box>
    </TooltipBasic>
  )
}

CardSpendProgressCell.fragments = {
  DebitCardV2: gql`
    fragment CardSpendProgressCellDebitCardV2Fragment on DebitCardV2 {
      ...UseCardStatusDebitCardV2Fragment
      ...CardSpendProgressCellContentDebitCardV2Fragment
      ...UseSpentFundsCorporateCardDebitCardV2Fragment
      ...UseCardProgressDebitCardV2Fragment
    }

    ${useCardStatus.fragments.DebitCardV2}
    ${CardSpendProgressCellContent.fragments.DebitCardV2}
    ${useSpentFundsCorporateCard.fragments.DebitCardV2}
    ${useCardProgress.fragments.DebitCardV2}
  `,
}
