import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { TransferContactFields } from '../../ContactTransfer.types'

const TO_CONTACT = 'toContact'

export const useUpdateToContact = () => {
  const { setFieldValue } = useFormikContext<TransferContactFields>()

  const updateToContact = useCallback(
    (contact: TransferContactFields['toContact']) => {
      setFieldValue(TO_CONTACT, contact)
    },
    [setFieldValue]
  )

  return { updateToContact }
}
