import { BarChart } from 'components/Charts/BarChart/BarChart'
import { ChartData } from 'components/Charts/Charts.types'

import { yAxisTickFormatter } from '../ReportsChart.utils'
import { xAxisTickFormatter } from './DayOfWeekChart.utils'
import { TooltipContent } from './TooltipContent/TooltipContent'

interface Props {
  data: ChartData
  hasNoInitialResults: boolean
}

export const DayOfWeekChart = ({ data, hasNoInitialResults }: Props) => {
  return (
    <BarChart
      data={data}
      disabled={hasNoInitialResults}
      height={230}
      TooltipContent={TooltipContent}
      width="100%"
      xAxisTickFormatter={xAxisTickFormatter}
      yAxisTickFormatter={yAxisTickFormatter}
    />
  )
}
