import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowVirtualTerminalLarge from 'assets/images/setup-flow/setup-flow-virtual-terminal-large.png'
import SetupFlowVirtualTerminalMedium from 'assets/images/setup-flow/setup-flow-virtual-terminal-medium.png'
import SetupFlowVirtualTerminalSmall from 'assets/images/setup-flow/setup-flow-virtual-terminal-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { MainContent } from './MainContent/MainContent'

export const FeatureVirtualTerminalStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const { goToStage } = useSetupFlowModal()

  return (
    <Tutorial.TutorialLayout
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage('FeaturePosLiteStage')}
            onSecondaryButtonClick={() => goToStage('FeatureInvoicesStage')}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowVirtualTerminalSmall}
          imageMedium={SetupFlowVirtualTerminalMedium}
          imageLarge={SetupFlowVirtualTerminalLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
