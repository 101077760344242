import type { ItemContactOrBiller } from '../../useBPayTargetItems'
import type { useGetContactsByNameLazyQuery } from '../GetContactsByName.generated'
import type { getItemsByQuery } from '../getItemsByQuery'
import { getContactItemsFromResponse } from './shared/getContactItemsFromResponse'

type GetItemsByNameOptions = {
  entityUuid: string
  name: string
  getContactsByName: ReturnType<typeof useGetContactsByNameLazyQuery>[0]
}

export const getItemsByName = async ({
  entityUuid,
  name,
  getContactsByName,
}: GetItemsByNameOptions): Promise<ReturnType<typeof getItemsByQuery>> => {
  const contactsRequest = getContactsByName({
    variables: { entityUuid, name },
  })

  const contacts = await contactsRequest

  if (contacts.error || !contacts.data) {
    return { type: 'Error' }
  }

  const itemsContacts = getContactItemsFromResponse(
    contacts.data.getContacts.contacts
  )

  const items: ItemContactOrBiller[] = itemsContacts

  return { type: 'Ok', value: { items } }
}
