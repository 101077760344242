import {
  List,
  ListItemHeaderSkeleton,
  ListItemSectionTitleSkeleton,
  ListItemSkeleton,
} from 'components/List'

export type AccountDetailsViewLoadingProps = Record<string, never>

export const AccountDetailsViewLoading = () => (
  <List role="progressbar">
    <ListItemHeaderSkeleton />
    <ListItemSkeleton />
    <ListItemSkeleton />
    <ListItemSectionTitleSkeleton />
    <ListItemSkeleton />
    <ListItemSkeleton />
    <ListItemSkeleton />
    <ListItemSectionTitleSkeleton />
    <ListItemSkeleton />
  </List>
)
