import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { IdToken } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'

import { AuthScope } from 'types/auth'

import { getSentryUserFromAuthTokenClaims } from './getSentryUserFromAuthTokenClaims'

export type AuthContextType = {
  token: string | undefined
  scope: string | undefined
  idTokenClaims: IdToken | undefined
  updateIdTokenClaims: (arg: IdToken) => void
  updateToken: (arg: string) => void
  updateScope: (arg: AuthScope) => void
  redirectTo: string | undefined
  shouldRedirectAfterLogin: boolean
  setRedirectTo: (arg: string) => void
  setShouldRedirectAfterLogin: (arg: boolean) => void
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)
AuthContext.displayName = 'AuthorizationContext'

export const AuthProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  const [token, setToken] = useState<string | undefined>(undefined)
  const [scope, setScope] = useState<AuthScope | undefined>(undefined)
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | undefined>(
    undefined
  )

  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined)
  const [shouldRedirectAfterLogin, setShouldRedirectAfterLogin] =
    useState<boolean>(true)

  const value = useMemo(
    () => ({
      token,
      updateToken: setToken,
      scope,
      updateScope: setScope,
      idTokenClaims,
      updateIdTokenClaims: setIdTokenClaims,
      redirectTo,
      setRedirectTo,
      shouldRedirectAfterLogin,
      setShouldRedirectAfterLogin,
    }),
    [idTokenClaims, scope, token, redirectTo, shouldRedirectAfterLogin]
  )

  const sentryUser = useMemo(
    () => getSentryUserFromAuthTokenClaims(idTokenClaims),
    [idTokenClaims]
  )
  useEffect(() => {
    Sentry.setUser(sentryUser ?? null)
  }, [sentryUser])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuthContext must be used within AuthContext')
  }

  return context
}
