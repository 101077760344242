import { BodyDefault, H3, H4 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledContactDescription = styled(BodyDefault)`
  margin-top: 0;
  margin-bottom: 1.5rem;
`

export const StyledContactNamePlaceholder = styled(H3)`
  margin-top: 0;
  margin-bottom: 0rem;
`

export const StyledContactHeader = styled(H4)`
  margin-top: 0;
  margin-bottom: 1.5rem;
`
