import { SimBillingStatus } from '@npco/mp-gql-types'

import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'
import { page } from 'translations'

import {
  SimBillingDisplayStatus,
  SimBillingStatusMap,
  SimBillingStatusMessageMap,
} from './Sim.type'

const DATE_FORMAT = 'DD MMM YYYY'

export const getBillingDisplayInfo = (
  status: SimBillingStatus,
  nextBillingDate: string
): string => {
  switch (status) {
    case SimBillingStatus.TRIAL:
    case SimBillingStatus.SUBSCRIBED:
      return `${page.settings.sim.nextRecharge} ${dayjs(nextBillingDate).format(
        DATE_FORMAT
      )}`
    case SimBillingStatus.CANCEL_PENDING:
      return `${page.settings.sim.expires} ${dayjs(nextBillingDate).format(
        DATE_FORMAT
      )}`
    case SimBillingStatus.CANCELLED:
    case SimBillingStatus.CANCELLED_FAILED_PAYMENT:
      return `${page.settings.sim.expired} ${dayjs(nextBillingDate).format(
        DATE_FORMAT
      )}`
    case SimBillingStatus.NULL:
    case SimBillingStatus.OVERDUE:
    default:
      return ''
  }
}

export const getIsActiveSim = (status: SimBillingStatus) =>
  status === SimBillingStatus.TRIAL ||
  status === SimBillingStatus.CANCEL_PENDING ||
  status === SimBillingStatus.SUBSCRIBED

export const getPlanDisplayInfo = (plan?: number | null) => {
  if (!plan) {
    return '-'
  }
  return `$${plan / 100}/month`
}

const simBillingStatusMap: SimBillingStatusMap = {
  [SimBillingStatus.TRIAL]: SimBillingDisplayStatus.ACTIVE,
  [SimBillingStatus.SUBSCRIBED]: SimBillingDisplayStatus.ACTIVE,
  [SimBillingStatus.OVERDUE]: SimBillingDisplayStatus.OVERDUE,
  [SimBillingStatus.CANCEL_PENDING]: SimBillingDisplayStatus.CANCEL_PENDING,
  [SimBillingStatus.CANCELLED]: SimBillingDisplayStatus.CANCELLED,
  [SimBillingStatus.CANCELLED_FAILED_PAYMENT]:
    SimBillingDisplayStatus.CANCELLED,
  [SimBillingStatus.NULL]: null,
}

const simBillingMessageMap: SimBillingStatusMessageMap = {
  [SimBillingDisplayStatus.ACTIVE]: translate(
    'page.settings.sim.details.billingStatus.active'
  ),
  [SimBillingDisplayStatus.CANCELLED]: translate(
    'page.settings.sim.details.billingStatus.cancelled'
  ),
  [SimBillingDisplayStatus.CANCEL_PENDING]: translate(
    'page.settings.sim.details.billingStatus.cancel_pending'
  ),
  [SimBillingDisplayStatus.OVERDUE]: translate(
    'page.settings.sim.details.billingStatus.overdue'
  ),
}

export const simListMessageMap: SimBillingStatusMessageMap = {
  ...simBillingMessageMap,
  [SimBillingDisplayStatus.CANCEL_PENDING]: translate(
    'page.settings.sim.details.billingStatus.pending'
  ),
} as const

export const mapSimBillingStatusToDisplayStatus = (
  simBillingStatus: SimBillingStatus
): SimBillingDisplayStatus | null => {
  return simBillingStatusMap[simBillingStatus]
}

export const getSimBillingDisplayStatusMessage = (
  simBillingDisplayStatus: SimBillingDisplayStatus | null
): string => {
  return simBillingDisplayStatus
    ? simBillingMessageMap[simBillingDisplayStatus]
    : ''
}

export const DEFAULT_DATA_ITEM = '-'
