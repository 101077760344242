import { useEffect, useRef } from 'react'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useFormikContext } from 'formik'

import { useGetZellerInvoiceSettings } from '../../../../../../../Settings/hooks/useGetZellerInvoiceSettings'
import { INVOICE_TITLE_MESSAGE_FIELD } from '../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../Invoice.types'

interface UseInvoiceEmailSectionProps {
  isExpanded?: boolean
}

export const useInvoiceTitleSection = ({
  isExpanded,
}: UseInvoiceEmailSectionProps) => {
  const hasBeenExpanded = useRef(false)
  const { invoiceSettings } = useGetZellerInvoiceSettings()

  const defaultMessage = invoiceSettings?.invoice.customisation?.defaultMessage

  const {
    setFieldValue,
    setTouched,
    touched,
    values: {
      title: { message },
      status,
    },
  } = useFormikContext<InvoiceFormFields>()

  const hasBeenInitialised = hasBeenExpanded.current || Boolean(message)

  useEffect(() => {
    if (!isExpanded || hasBeenInitialised) {
      return
    }
    hasBeenExpanded.current = true

    setTouched(
      {
        ...touched,
        title: {
          ...touched.title,
          message: true,
        },
      },
      false
    )
  }, [hasBeenInitialised, isExpanded, setTouched, touched])

  const isMessageTouched = touched?.title?.message

  const shouldSetInitialValue =
    status === InvoiceStatus.DRAFT && isExpanded && !isMessageTouched

  useEffect(() => {
    if (!shouldSetInitialValue) {
      return
    }

    if (defaultMessage) {
      setFieldValue(INVOICE_TITLE_MESSAGE_FIELD, defaultMessage)
    }
  }, [defaultMessage, setFieldValue, shouldSetInitialValue])
}
