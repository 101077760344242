import { StyledAside, StyledContainer, StyledMain } from './AsideLayout.styled'

interface AsideLayoutProps {
  children: React.ReactNode
  aside: React.ReactNode
  hasAside?: boolean
  isRightSide?: boolean
  isSideBarOpen: boolean
  isFullWidth?: boolean
}

export const AsideLayout = ({
  aside,
  hasAside = true,
  children,
  isRightSide = false,
  isSideBarOpen,
  isFullWidth,
}: AsideLayoutProps) => {
  return (
    <StyledContainer isSideBarOpen={isSideBarOpen} isFullWidth={isFullWidth}>
      {hasAside && <StyledAside isRightSide={isRightSide}>{aside}</StyledAside>}
      <StyledMain isRightSide={isRightSide}>{children}</StyledMain>
    </StyledContainer>
  )
}
