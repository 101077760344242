import { useTranslations } from '@npco/utils-translations'
import { Box, Divider } from '@npco/zeller-design-system'
import { getContactName } from 'features/Contacts'

import { currencyFormatter, formatAccountNumber, formatBsb } from 'utils/common'
import { formatTimeDate } from 'pages/Transfer/Transfer.utils'
import { translations } from 'translations/shared.i18n'

import { DefaultRow, TransferModal } from '../../../../TransferModal'
import { contactTransferModalTranslations } from '../../ContactTransferModal.i18n'
import { TransferContactValues } from '../../ContactTransferModal.types'

type ContactTransferReceiptProps = {
  values: TransferContactValues
  transactionTimestamp: string
}

export const ContactTransferReceipt = ({
  values,
  transactionTimestamp,
}: ContactTransferReceiptProps) => {
  const t = useTranslations(contactTransferModalTranslations)
  const tShared = useTranslations(translations)

  return (
    <Box>
      <TransferModal.SectionWrapper aria-label={t('transferDetails')}>
        <DefaultRow
          label={tShared('amount')}
          value={currencyFormatter(+values.amount * 100)}
        />
        <DefaultRow label="Date" value={formatTimeDate(transactionTimestamp)} />
        <DefaultRow
          label={t('from')}
          value={`${values.fromAccount.name} · ${formatAccountNumber(
            values.fromAccount.accountNumber
          )}`}
        />
      </TransferModal.SectionWrapper>
      <Divider />
      <TransferModal.SectionWrapper aria-label={t('recipientDetails')}>
        <DefaultRow
          label={t('to')}
          value={getContactName(values.toContact) ?? '-'}
        />
        <DefaultRow
          label={t('bsb')}
          value={
            values.toPaymentInstrument.bankAccountDetails?.bsb
              ? formatBsb(values.toPaymentInstrument.bankAccountDetails.bsb)
              : '-'
          }
        />
        <DefaultRow
          label={t('accountNumber')}
          value={
            values.toPaymentInstrument.bankAccountDetails?.account
              ? formatAccountNumber(
                  values.toPaymentInstrument.bankAccountDetails.account
                )
              : '-'
          }
        />
      </TransferModal.SectionWrapper>
      <Divider />
      <TransferModal.SectionWrapper>
        <DefaultRow
          label={t('description')}
          value={values.recipientReference}
        />
        <DefaultRow label={t('reference')} value={values.reference} />
      </TransferModal.SectionWrapper>
    </Box>
  )
}
