import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Accordion } from '@npco/zeller-design-system'
import { FormikErrors, getIn, useFormikContext } from 'formik'

import { usePrevious } from 'hooks/usePrevious'

import { InvoiceFormFields } from '../../Invoice.types'
import { InvoiceCustomerAccordion } from './components/InvoiceCustomerAccordion/InvoiceCustomerAccordion'
import { InvoiceDeliveryAccordion } from './components/InvoiceDeliveryAccordion/InvoiceDeliveryAccordion'
import { InvoiceItemsAccordion } from './components/InvoiceItemsAccordion/InvoiceItemsAccordion'
import { InvoiceScheduleAccordion } from './components/InvoiceScheduleAccordion/InvoiceScheduleAccordion'
import { InvoiceTitleAccordion } from './components/InvoiceTitleAccordion/InvoiceTitleAccordion'
import { INVOICE_ACCORDION_ID } from './InvoiceFormAccordions.constants'

const getAccordionsWithError = (errors: FormikErrors<InvoiceFormFields>) => {
  const accordionWithErrors = []

  if (errors.customer) {
    accordionWithErrors.push(INVOICE_ACCORDION_ID.CUSTOMER)
  }
  if (errors.title) {
    accordionWithErrors.push(INVOICE_ACCORDION_ID.TITLE)
  }
  if (
    errors.items ||
    getIn(errors, 'itemsExceedsMaxAmount') ||
    getIn(errors, 'itemsExceedsMinAmount')
  ) {
    accordionWithErrors.push(INVOICE_ACCORDION_ID.ITEMS)
  }
  if (errors.schedule) {
    accordionWithErrors.push(INVOICE_ACCORDION_ID.SCHEDULE)
  }
  if (errors.delivery?.email) {
    accordionWithErrors.push(INVOICE_ACCORDION_ID.EMAIL)
  }

  return accordionWithErrors
}

export interface InvoiceFormAccordionsProps {
  initialExpandedItem?: INVOICE_ACCORDION_ID
  shouldResetInitialExpandedItem?: boolean
}

export const InvoiceFormAccordions = ({
  initialExpandedItem = INVOICE_ACCORDION_ID.CUSTOMER,
  shouldResetInitialExpandedItem,
}: InvoiceFormAccordionsProps) => {
  const [expandedItem, setExpandedItem] = useState<INVOICE_ACCORDION_ID | null>(
    initialExpandedItem
  )
  const expandedItemRef = useRef(expandedItem)

  const { errors, isSubmitting } = useFormikContext<InvoiceFormFields>()
  const isPreviouslySubmitting = usePrevious(isSubmitting)

  useEffect(() => {
    if (shouldResetInitialExpandedItem) {
      setExpandedItem(initialExpandedItem)
    }
  }, [initialExpandedItem, shouldResetInitialExpandedItem])

  useEffect(() => {
    expandedItemRef.current = expandedItem
  })

  useEffect(() => {
    if (!Object.keys(errors).length) {
      return
    }
    if (isPreviouslySubmitting && !isSubmitting) {
      const accordionsWithError = getAccordionsWithError(errors)
      if (
        expandedItemRef.current &&
        accordionsWithError.includes(expandedItemRef.current)
      ) {
        return
      }

      setExpandedItem(accordionsWithError[0])
    }
  }, [errors, isPreviouslySubmitting, isSubmitting])

  const handleChange =
    (itemName: INVOICE_ACCORDION_ID) =>
    async (_: SyntheticEvent, isExpanded: boolean) => {
      setExpandedItem(isExpanded ? itemName : null)
    }

  return (
    <Accordion.Wrapper>
      <InvoiceCustomerAccordion
        isExpanded={expandedItem === INVOICE_ACCORDION_ID.CUSTOMER}
        onChange={handleChange(INVOICE_ACCORDION_ID.CUSTOMER)}
      />
      <InvoiceTitleAccordion
        isExpanded={expandedItem === INVOICE_ACCORDION_ID.TITLE}
        onChange={handleChange(INVOICE_ACCORDION_ID.TITLE)}
      />
      <InvoiceItemsAccordion
        isExpanded={expandedItem === INVOICE_ACCORDION_ID.ITEMS}
        onChange={handleChange(INVOICE_ACCORDION_ID.ITEMS)}
      />
      <InvoiceScheduleAccordion
        isExpanded={expandedItem === INVOICE_ACCORDION_ID.SCHEDULE}
        onChange={handleChange(INVOICE_ACCORDION_ID.SCHEDULE)}
      />
      <InvoiceDeliveryAccordion
        isExpanded={expandedItem === INVOICE_ACCORDION_ID.EMAIL}
        onChange={handleChange(INVOICE_ACCORDION_ID.EMAIL)}
        onEditCustomerClick={() =>
          setExpandedItem(INVOICE_ACCORDION_ID.CUSTOMER)
        }
      />
    </Accordion.Wrapper>
  )
}
