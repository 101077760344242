import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Animation,
  Box,
  COLOR,
  DatePickerInputExact,
  INPUT_SIZE,
  SvgIcon,
  ToggleForm,
} from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { Field, useFormikContext } from 'formik'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { Tooltip } from 'components/Tooltip'

import { fadeInHeightAnimationVariants } from '../../../InvoiceFormAccordions.utils'
import { useScheduleSend } from '../hooks/useScheduleSend'
import { DATE_PICKER_POPPER_WIDTH } from '../InvoiceScheduleAccordion.constants'
import { translations } from './InvoiceScheduleSend.i18n'

const SendTimeInformationPopover = (text: string) => () => {
  return (
    <Tooltip
      maxWidth="120px"
      textAlign="center"
      tooltipIcon={
        <SvgIcon width="16" height="16" color={COLOR.GREY_250}>
          <InfoIcon />
        </SvgIcon>
      }
    >
      {text}
    </Tooltip>
  )
}

export const InvoiceScheduleSend = () => {
  const t = useTranslations(translations)

  const {
    values: { schedule, status },
  } = useFormikContext<InvoiceFormFields>()

  const {
    disabledDatePickerDays,
    fieldError,
    fieldName,
    fieldValue,
    handleBlur,
    handleDateChange,
    isMobileResolution,
  } = useScheduleSend()

  const isSentInvoice = status === InvoiceStatus.SENT
  const isOverdueInvoice = status === InvoiceStatus.OVERDUE

  return (
    <>
      <Field
        component={ToggleForm}
        name="schedule.sendEnabled"
        value={schedule.sendEnabled}
        label={t('sendDateToggleLabel')}
        isDisabled={isSentInvoice || isOverdueInvoice}
      />

      <Animation
        disableInitialAnimation
        shouldShow={Boolean(schedule.sendEnabled)}
        variants={fadeInHeightAnimationVariants}
      >
        <Box pt="24px">
          <DatePickerInputExact
            disabledDays={disabledDatePickerDays}
            error={fieldError}
            hasError={Boolean(fieldError)}
            inputSize={INPUT_SIZE.SMALL}
            isDisabled={isSentInvoice || isOverdueInvoice}
            label={t('sendDateInputLabel')}
            name={fieldName}
            onBlur={handleBlur}
            onChange={handleDateChange}
            popperWidth={
              isMobileResolution ? undefined : DATE_PICKER_POPPER_WIDTH
            }
            renderRightControls={SendTimeInformationPopover(
              t('sendDateTooltipText')
            )}
            value={fieldValue}
          />
        </Box>
      </Animation>
    </>
  )
}
