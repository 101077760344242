import { useHistory } from 'react-router'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { getSessionStorageItem } from '@npco/utils-session-storage'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'

const SELECTED_SITE_KEY = `selected-site-key`

export const useRedirectToUsers = (isUsers = false) => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()

  if (isUsers) {
    return () => history.push(ROOT.ORGS.ORG(shortEntityId).SETTINGS.USERS.path)
  }

  return () =>
    history.push(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.USERS.path)
}

export const getSelectedSite = (): string => {
  return getSessionStorageItem(SELECTED_SITE_KEY) || rvSelectedSite()
}

export function getDataValue<T, K extends keyof T>(
  data: T | null,
  key: K
): T[K] | string {
  const defaultValue = '-'
  if (data) {
    return data[key] || defaultValue
  }
  return defaultValue
}
