import { Flex, Label, useTextAreaOnChange } from '@npco/zeller-design-system'
import { FieldProps } from 'formik'

import {
  StyledErrorMessage,
  StyledTextAreaBody,
  StyledTextAreaContainer,
} from './TextArea.styled'

interface TextAreaProps {
  label: string
  hasError?: boolean
  shouldHideErrorMessage?: boolean
  maxLength?: number
}

export const TextArea: React.FC<
  FieldProps & HTMLTextAreaElement & TextAreaProps
> = ({
  id,
  field,
  label,
  rows,
  maxLength,
  hasError,
  form: { touched, errors },
  shouldHideErrorMessage,
  placeholder,
  className,
}) => {
  const shouldShowError =
    !!(touched[field.name] && errors[field.name]) || Boolean(hasError)

  const { onChange } = useTextAreaOnChange({ field, maxLength })

  return (
    <StyledTextAreaContainer
      flexDirection="column"
      mb="40px"
      data-testid="text-area"
      className={className}
    >
      {label && (
        <Flex justifyContent="space-between">
          <Label data-testid="text-area-label" htmlFor={field.name}>
            {label}
          </Label>
          {maxLength !== undefined && (
            <Label as="div" data-testid="text-area-counter">
              {`${field.value ? field.value.length : '0'}/${maxLength}`}
            </Label>
          )}
        </Flex>
      )}
      <StyledTextAreaBody
        data-testid={field.name}
        name={field.name}
        rows={rows}
        onChange={onChange}
        value={field.value}
        shouldShowError={shouldShowError}
        placeholder={placeholder}
        id={id}
      />
      {shouldShowError && !shouldHideErrorMessage && (
        <StyledErrorMessage data-testid="error-field">
          {errors[field.name]}
        </StyledErrorMessage>
      )}
    </StyledTextAreaContainer>
  )
}
