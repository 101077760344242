import React from 'react'
import { gql } from '@apollo/client'
import { AccordionSimple, Box, Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { EmptyAccounts } from '../EmptyAccounts/EmptyAccounts'
import {
  ListAccountsDebitCardAccountV2Fragment,
  ListAccountsEntityFragment,
} from './ListAccounts.generated'
import { StyledContainer } from './ListAccounts.styled'
import {
  filterActiveAccounts,
  filterClosedAccounts,
} from './ListAccounts.utils'
import { ListItemAccounts } from './ListItemAccounts/ListItemAccounts'

interface ListAccountsProps {
  accounts: ListAccountsDebitCardAccountV2Fragment[]
  entity?: ListAccountsEntityFragment
  handleOnScroll: (event: React.UIEvent<HTMLElement>) => void
  activeAccountHeader?: React.ReactNode
}

export const ListAccounts = ({
  accounts,
  entity,
  handleOnScroll,
  activeAccountHeader,
}: ListAccountsProps) => {
  const activeAccounts = filterActiveAccounts(accounts)
  const closedAccounts = filterClosedAccounts(accounts)

  const closedAccountsLabel = translate('page.accounts.closedAccounts', {
    numberOfClosedAccounts: closedAccounts.length,
  })

  return (
    <StyledContainer data-testid="list-accounts" onScroll={handleOnScroll}>
      <Flex flexDirection="column">
        {activeAccounts.length === 0 ? (
          <Box mb="24px">
            <EmptyAccounts entity={entity} />
          </Box>
        ) : (
          <>
            {activeAccountHeader}
            <Box width="100%">
              {activeAccounts.map((account) => (
                <ListItemAccounts key={account.id} account={account} />
              ))}
            </Box>
          </>
        )}
        {closedAccounts.length > 0 && (
          <Box width="100%" mt="1rem" mb="4rem">
            <AccordionSimple removeContentPadding title={closedAccountsLabel}>
              {closedAccounts.map((account) => (
                <ListItemAccounts key={account.id} account={account} />
              ))}
            </AccordionSimple>
          </Box>
        )}
      </Flex>
    </StyledContainer>
  )
}

ListAccounts.fragments = {
  DebitCardAccountV2: gql`
    fragment ListAccountsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      ...ListItemAccountsDebitCardAccountV2Fragment
    }

    ${ListItemAccounts.fragments.DebitCardAccountV2}
  `,
  Entity: gql`
    fragment ListAccountsEntityFragment on Entity {
      ...EmptyAccountsEntityFragment
    }

    ${EmptyAccounts.fragments.Entity}
  `,
}
