import { useReactiveVar } from '@apollo/client'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import {
  rvSelectedSite,
  rvSiteDetails,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Formik } from 'formik'
import { isEqual } from 'lodash-es'

import { SiteCache } from 'types/site'
import { SpinnerWrapped } from 'components/Spinner'

import { useUpdateScreensaver } from './hooks/useUpdateScreensaver'
import { useUploadLogosToS3 } from './hooks/useUploadLogosToS3'
import { getScreensaverInitialValues } from './SiteAppearance.utils'
import { SiteAppearanceForm } from './SiteAppearanceForm/SiteAppearanceForm'
import { SiteAppearanceFormValues } from './SiteAppearanceForm/SiteAppearanceForm.types'

export const SiteAppearance = () => {
  const siteDetails = useReactiveVar(rvSiteDetails)
  const siteUuid = useReactiveVar(rvSelectedSite)
  const { updateScreensaver, isUpdatingScreensaver } = useUpdateScreensaver()
  const { upload, isUploading } = useUploadLogosToS3()
  const flags = useFeatureFlags()

  const initialValues = getScreensaverInitialValues(siteDetails, flags)

  const handleSubmit = async (values: SiteAppearanceFormValues) => {
    if (isEqual(values, initialValues)) {
      return
    }
    await upload(values.logosToUpload)
    const result = await updateScreensaver({
      siteUuid,
      primaryColour: values.selectedColour,
      primaryLogoUuid: values.selectedLogo,
      logoUuids: values.logos.map((logo) => logo.logoUuid),
      customColours: values.customColours,
    })
    if (result.data?.updateScreensaver) {
      rvSiteDetails({
        ...(siteDetails as SiteCache),
        screensaver: {
          customColours: values.customColours,
          logos: values.logos,
          primaryColour: values.selectedColour,
          primaryLogoUrl: '',
          primaryLogoUuid: values.selectedLogo,
        },
      })
    }
  }

  if (isUploading || isUpdatingScreensaver) {
    return <SpinnerWrapped variant="centre" />
  }

  return (
    <Formik<SiteAppearanceFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <SiteAppearanceForm />
    </Formik>
  )
}
