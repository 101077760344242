import { BodyDefault, H5Style } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ToggleLabel = styled.label`
  ${H5Style}
  margin-right: 8px;
`

export const SettingDescription = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0 0 24px;
`

export const ProcessingFeeLabel = styled.div`
  ${H5Style}
`

export const ProcessingFee = styled(BodyDefault)`
  margin: 0;
`
