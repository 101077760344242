import { useMutation } from '@apollo/client'
import { showApiErrorToast } from '@npco/zeller-design-system'

import {
  CompleteConncetionOAuthFlow as CompleteConncetionOAuthFlowResponse,
  CompleteConncetionOAuthFlowVariables,
} from 'types/gql-types/CompleteConncetionOAuthFlow'

import { CompleteConncetionOAuthFlow } from '../graphql/CompleteConncetionOAuthFlow'

export const useCompleteConnectionOAuthFlow = () => {
  // NOTE: need to use completeConnectionOAuthFlowV2 mutation if MultiEntity FF is enabled
  const [completeConnectionOAuthFlow, { loading, error }] = useMutation<
    CompleteConncetionOAuthFlowResponse,
    CompleteConncetionOAuthFlowVariables
  >(CompleteConncetionOAuthFlow, {
    onError: (err) => {
      showApiErrorToast(err)
    },
    notifyOnNetworkStatusChange: true,
  })

  return {
    isLoading: loading,
    error,
    completeConnectionOAuthFlow,
  }
}
