import { useTranslations } from '@npco/utils-translations'
import {
  Animation,
  Flex,
  slideDownAnimationVariants,
} from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { FormCheckbox } from 'components/Input/Checkbox'

import { INVOICE_EMAIL_SEND_ME_COPY_FIELD } from '../../../../../../Invoice.constants'
import { InvoiceFormFields } from '../../../../../../Invoice.types'
import { translations } from '../../InvoiceDeliveryAccordion.i18n'
import { InvoiceEmailMessage } from './InvoiceEmailMessage/InvoiceEmailMessage'
import { InvoiceEmailRecipients } from './InvoiceEmailRecipients/InvoiceEmailRecipients'
import { InvoiceEmailSubject } from './InvoiceEmailSubject/InvoiceEmailSubject'

type InvoiceDeliveryEmailProps = {
  onEditCustomerClick: () => void
}

export const InvoiceDeliveryEmail = ({
  onEditCustomerClick,
}: InvoiceDeliveryEmailProps) => {
  const t = useTranslations(translations)

  const {
    values: {
      delivery: {
        email: { isEnabled },
      },
    },
  } = useFormikContext<InvoiceFormFields>()

  const shouldShowFields = Boolean(isEnabled)

  return (
    <>
      <InvoiceEmailRecipients onEditCustomerClick={onEditCustomerClick} />
      <Animation
        disableInitialAnimation
        shouldShow={shouldShowFields}
        variants={slideDownAnimationVariants}
      >
        <Flex flexDirection="column" gridGap="24px">
          <InvoiceEmailSubject />
          <InvoiceEmailMessage />

          <Field
            component={FormCheckbox}
            label={t('sendMeCopy')}
            name={INVOICE_EMAIL_SEND_ME_COPY_FIELD}
            type="checkbox"
          />
        </Flex>
      </Animation>
    </>
  )
}
