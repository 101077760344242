import { Flex } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { CardTypeOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { validateRequired } from 'utils/formValidation'

import { DebitCardRadioOption } from './DebitCardRadioOption'
import { ExpenseCardRadioOption } from './ExpenseCardRadioOption'

export const SelectCardTypeFields = () => {
  const [field, , handlers] = useField({
    name: 'cardType',
    validate: validateRequired,
  })

  return (
    <Flex flexDirection="column" mt="12px">
      <DebitCardRadioOption
        onChange={() => handlers.setValue(CardTypeOption.DebitCard)}
        isChecked={field.value === CardTypeOption.DebitCard}
        name="cardType"
      />
      <ExpenseCardRadioOption
        onChange={() => handlers.setValue(CardTypeOption.ExpenseCard)}
        isChecked={field.value === CardTypeOption.ExpenseCard}
        name="cardType"
      />
    </Flex>
  )
}
