import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { Flex, TileSelector } from '@npco/zeller-design-system'

import T1 from 'assets/images/t1.png'
import T1ShellBlack from 'assets/images/t1-shell-black.png'
import T1ShellWhite from 'assets/images/t1-shell-white.png'
import T2 from 'assets/images/t2.png'
import T2ShellBlack from 'assets/images/t2-shell-black.png'
import T2ShellGrey from 'assets/images/t2-shell-grey.png'
import T2ShellWhite from 'assets/images/t2-shell-white.png'
import { ImagePreview } from 'components/ModalUploadImage/ImagePreview/ImagePreview'

import { useSelectTerminal } from '../../hooks/useSelectTerminal'
import * as styled from '../TerminalPreview.styled'
import { TerminalModel } from '../TerminalPreview.types'

export const ControlPanel = () => {
  const flags = useFeatureFlags()
  const {
    values,
    handleT2TileClick,
    handleT1TileClick,
    handleBlackTileClick,
    handleWhiteTileClick,
    handleGreyTileClick,
  } = useSelectTerminal()

  return (
    <Flex flexDirection="column" gap="24px">
      <Flex
        width="168px"
        pl="16px"
        flexDirection="row"
        gap="12px"
        justifyContent="center"
      >
        {flags.T2Uplift && (
          <>
            <TileSelector
              name="t2-model"
              content={
                <styled.LogoWrapper>
                  <ImagePreview tabIndex={-1} src={T2} />
                </styled.LogoWrapper>
              }
              selected={values.selectedTerminalModel === TerminalModel.T2}
              onChange={handleT2TileClick}
              value="0"
            />
            <TileSelector
              name="t1-model"
              content={
                <styled.LogoWrapper>
                  <ImagePreview tabIndex={-1} src={T1} />
                </styled.LogoWrapper>
              }
              selected={values.selectedTerminalModel === TerminalModel.T1}
              onChange={handleT1TileClick}
              value="1"
            />
          </>
        )}
      </Flex>
      <Flex
        width="168px"
        flexDirection="row"
        pl="16px"
        gap="12px"
        justifyContent="center"
      >
        <TileSelector
          name="default-terminal-black"
          content={<styled.BlackTile />}
          selected={
            values.selectedTerminalModel === TerminalModel.T1
              ? values.selectedTerminalUrl === T1ShellBlack
              : values.selectedTerminalUrl === T2ShellBlack
          }
          onChange={handleBlackTileClick}
          value="2"
        />
        <TileSelector
          name="default-terminal-white"
          content={<styled.WhiteTile />}
          selected={
            values.selectedTerminalModel === TerminalModel.T1
              ? values.selectedTerminalUrl === T1ShellWhite
              : values.selectedTerminalUrl === T2ShellWhite
          }
          onChange={handleWhiteTileClick}
          value="3"
        />
        {values.selectedTerminalModel === TerminalModel.T2 && (
          <TileSelector
            name="default-terminal-grey"
            content={<styled.GreyTile />}
            selected={values.selectedTerminalUrl === T2ShellGrey}
            onChange={handleGreyTileClick}
            value="4"
          />
        )}
      </Flex>
    </Flex>
  )
}
