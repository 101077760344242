import { BodyDefault, zIndexMap } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledTerminalsImageAside = styled.aside`
  display: none;
  flex-direction: column;
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.GREY_60};
  background-position: calc(var(--slide-index, 0) * -300px) 0;
  background-size: cover;
  background-repeat: repeat-x;
  transition: background-position 800ms;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: flex;
    height: 100vh;
    z-index: 3;
    width: 22.5rem;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    width: 26rem;
  }
`

export const Animation = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${Number(zIndexMap.background)};
`

export const StyledTerminalsImageFooter = styled.footer`
  margin-top: auto;
  flex: 0 0 auto;
  padding: 1rem;
  text-align: center;
`

export const StyledCopyrightText = styled(BodyDefault)`
  text-align: center;
  margin: 1.5rem 0;
  color: ${({ theme }) => theme.colors.GREY_550};
  line-height: 1.43;
`
