import { useTranslations } from '@npco/utils-translations'
import { ButtonLink } from '@npco/zeller-design-system'

import { SectionHeader } from 'pages/Dashboard/SectionHeader'

import { translations } from './PaymentTransactionsHeader.i18n'

interface PaymentTransactionsHeaderProps {
  onClickViewAll: () => void
}

export const PaymentTransactionsHeader = ({
  onClickViewAll,
}: PaymentTransactionsHeaderProps) => {
  const t = useTranslations(translations)

  return (
    <SectionHeader heading={t('heading')}>
      <ButtonLink onClick={onClickViewAll}>{t('viewAll')}</ButtonLink>
    </SectionHeader>
  )
}
