import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledLogoCornerWrapper = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  z-index: 2;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    top: 2.5rem;
    left: 2.5rem;
  }
`
