import { useRef } from 'react'
import { Box, ModalBasic } from '@npco/zeller-design-system'

import { useIsResourceElementLoaded } from 'hooks/useIsResourceElementLoaded/useIsResourceElementLoaded'
import { translate } from 'utils/translations'
import { StyledAbsoluteContainer } from 'pages/GlobalModals/GlobalModals.styled'

import { SpinnerWrapped } from '../../../../../../components/Spinner'
import { LoadingSetPin } from '../LoadingSetPin/LoadingSetPin'

interface SetPinLoadedProps {
  src: string
}

export const SetPinLoaded = ({ src }: SetPinLoadedProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { isLoaded } = useIsResourceElementLoaded({
    elementRef: iframeRef,
  })

  return (
    <ModalBasic.Body height="280px" canScroll={false}>
      <Box position="relative" width="100%" height="270px">
        {isLoaded ? (
          <StyledAbsoluteContainer>
            <SpinnerWrapped variant="centre" flexGrow={1} />
          </StyledAbsoluteContainer>
        ) : (
          <LoadingSetPin />
        )}

        <StyledAbsoluteContainer>
          <iframe
            ref={iframeRef}
            data-testid="set-pin-frame"
            style={{ border: 'none' }}
            width="100%"
            height="100%"
            scrolling="no"
            title={translate('page.setPinModal.modalTitle')}
            src={src}
          />
        </StyledAbsoluteContainer>
      </Box>
    </ModalBasic.Body>
  )
}
