import {
  BodyDefaultTypography,
  BodySmallTypography,
  DataSmallTypography,
  StyledErrorBox,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledErrorMsg = styled(StyledErrorBox)`
  ${DataSmallTypography}
  margin: 0 8px 0 0;
`

export const StyledListCustomers = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const StyledListCustomersItemWrapper = styled.li`
  margin: 0 0 0.25rem 0;
  padding: 0;
`

export const StyledListCustomersMainText = styled.div`
  ${BodyDefaultTypography};
`

export const StyledListCustomersSubText = styled.div`
  display: flex;
  align-items: center;
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.GREY_550};

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledListCustomersSubTextExtension = styled.div<{
  hasDot?: boolean
}>`
  display: flex;
  align-items: center;
  ${BodySmallTypography};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  ${(props) =>
    props.hasDot &&
    css`
      &::before {
        content: '';
        display: block;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.GREY_550};
        margin-right: 8px;
      }
    `}
`

export const StyledListCustomersButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  color: 1px solid ${({ theme }) => theme.colors.GREY_250};
  border-radius: 7px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: border-color 0.3s ease-out;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.BLACK};
  }
`
