import { BodySmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

import { SelectSize } from '../Select.types'

export interface Props {
  $size: SelectSize
}

export const StyledSelectCompactItem = styled.li`
  padding: 0.875rem 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.BLACK};
  ${BodySmallTypography}
  transition: color 0.3s ease-out;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};
  list-style: none;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    &:last-of-type {
      border-bottom: none;
    }
  }

  &[aria-selected='true'],
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`
