import { ButtonClose } from '@npco/zeller-design-system'

import { MultiSelectTopSectionRenderProps } from '../MultiSelect.types'
import { StyledWrapper } from './MultiSelectHeader.styled'

interface Props extends MultiSelectTopSectionRenderProps {
  children: React.ReactNode
}

export const MultiSelectHeader = ({ onClose, isOpen, children }: Props) => {
  return (
    <StyledWrapper>
      {children}
      {isOpen && <ButtonClose onClick={onClose} />}
    </StyledWrapper>
  )
}
