import { BodyLargeTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled.div`
  /* creates new stacking context for full coverage of absolute Input */
  position: relative;
  /* inline stops Container div filling width of parent */
  display: inline-flex;
  column-gap: 0.5rem;
`

export const Input = styled.input`
  /* stretches to fully cover Container and sibling Character elements */
  position: absolute;
  /* higher than Character */
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  /* mobile long-press/paste doesn't work w/ opacity 0 */
  color: transparent;
  background-color: transparent;
  caret-color: transparent;
  border: none;
  outline: none;
  /* equal to height of Character rects, for full height tap area for paste */
  font-size: 4rem;
  /* hides selection rectangle */
  letter-spacing: -99999px;

  ${Container}:hover &:disabled {
    cursor: not-allowed;
  }

  /* workaround for hiding the user-agent's unoverridable ':autofill' styles
  * (which reveal this invisible input field) by using a _really_ long
  * transition-delay (~12 days) */
  transition-property: color, background-color;
  transition-duration: 0s;
  transition-delay: 999999s;
`

export const Segment = styled.input`
  /* relative with z-index to not occlude Input */
  position: relative;
  /* lower than Input */
  z-index: 1;
  /* [ios/safari] fix */
  padding: 0;
  width: 56px;
  height: 64px;
  ${BodyLargeTypography}
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.BLACK_900};
  background: ${({ theme }) => theme.colors.GREY_30};

  /* [ios/safari] fix */
  &:disabled {
    opacity: 1;
  }
  ${Container}:hover ${Input}:enabled~& {
    background: ${({ theme }) => theme.colors.GREY_60};
  }
  ${Container}:hover ${Input}:focus~& {
    background: ${({ theme }) => theme.colors.GREY_30};
  }
  ${Input}[aria-invalid='true']~& {
    border-color: ${({ theme }) => theme.colors.RED_1000};
  }
  ${Input}:focus~&[data-active='true'] {
    border-color: ${({ theme }) => theme.colors.BLUE_1000};
  }
  ${Input}:focus~&:last-child[data-complete='true'] {
    border-right: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  }
`
