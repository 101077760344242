import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { DebitCardTransactionsV2FragmentDoc } from './DebitCardTransactionsV2Fragment.generated'

const defaultOptions = {} as const
export type GetDebitCardTransactionsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input']
  filter: Types.InputMaybe<Types.DebitCardTransactionFilterInputV2>
  nextToken: Types.InputMaybe<Types.DebitCardTransactionV2NextTokenInput>
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetDebitCardTransactionsQueryResponse = {
  __typename?: 'Query'
  getDebitCardTransactionsV2: {
    __typename?: 'DebitCardTransactionConnectionV2'
    transactions: Array<{
      __typename: 'DebitCardTransactionV2'
      attachments: Array<string> | null
      cardAcceptorName: string | null
      debitCardName: string | null
      id: string
      debitCardAccountUuid: string
      debitCardId: string | null
      debitCardMaskedPan: string | null
      description: string | null
      category: Types.EntityCategories | null
      subcategory: string | null
      status: Types.DebitCardTransactionStatusV2
      timestamp: any
      reference: string | null
      referencePayee: string | null
      type: Types.DebitCardTransactionTypeV2
      note: string | null
      tags: Array<string> | null
      amount: { __typename?: 'Money'; value: string }
      deposit: { __typename?: 'Deposit'; siteName: string; id: string } | null
      subcategoryDetails: {
        __typename?: 'Subcategory'
        id: string
        name: string
        predefined: boolean
      } | null
      contact: {
        __typename?: 'Contact'
        businessName: string | null
        category: Types.EntityCategories | null
        contactType: Types.ContactType
        firstName: string | null
        id: string
        lastName: string | null
        isSelf: boolean | null
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
      } | null
      merchant: {
        __typename: 'MerchantDetails'
        id: string
        name: string | null
        hours: string | null
        phone: string | null
        email: string | null
        url: string | null
        abn: string | null
        location: string | null
        locationAccuracy: number | null
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
        address: {
          __typename?: 'MerchantAddress'
          country: string | null
          postcode: string | null
          state: string | null
          street1: string | null
          street2: string | null
          suburb: string | null
        } | null
      } | null
      payerDetails: {
        __typename?: 'DebitCardTransactionCounterparty'
        debitCardAccountUuid: string | null
        paymentInstrumentUuid: string | null
        senderUuid: string | null
        accountDetails: {
          __typename?: 'BankAccountDetails'
          bsb: string | null
          account: string | null
          name: string | null
        } | null
        debitCardAccount: {
          __typename?: 'DebitCardAccountV2'
          id: string
          name: string
          type: Types.DebitCardAccountType
          icon: {
            __typename?: 'Icon'
            colour: string | null
            letter: string | null
            image: string | null
            animation: string | null
            images: Array<{
              __typename?: 'Image'
              size: Types.ImageSize
              url: string
            }> | null
          } | null
        } | null
      } | null
      payeeDetails: {
        __typename?: 'DebitCardTransactionCounterparty'
        debitCardAccountUuid: string | null
        paymentInstrumentUuid: string | null
        accountDetails: {
          __typename?: 'BankAccountDetails'
          bsb: string | null
          account: string | null
          name: string | null
        } | null
        bpayDetails: {
          __typename?: 'BpayDetails'
          billerName: string
          billerCode: string
          crn: string
          nickname: string
        } | null
        debitCardAccount: {
          __typename?: 'DebitCardAccountV2'
          id: string
          name: string
          type: Types.DebitCardAccountType
          icon: {
            __typename?: 'Icon'
            colour: string | null
            letter: string | null
            image: string | null
            animation: string | null
            images: Array<{
              __typename?: 'Image'
              size: Types.ImageSize
              url: string
            }> | null
          } | null
        } | null
      } | null
    } | null>
    nextToken: {
      __typename?: 'DebitCardTransactionV2NextToken'
      id: string | null
      entityUuid: string | null
      type: string
    } | null
  }
}

export const GetDebitCardTransactions = gql`
  query GetDebitCardTransactions(
    $limit: Int!
    $filter: DebitCardTransactionFilterInputV2
    $nextToken: DebitCardTransactionV2NextTokenInput
    $entityUuid: ID!
  ) {
    getDebitCardTransactionsV2(
      limit: $limit
      filter: $filter
      nextToken: $nextToken
      entityUuid: $entityUuid
    ) {
      transactions {
        ...DebitCardTransactionsV2Fragment
      }
      nextToken {
        id
        entityUuid
        type
      }
    }
  }
  ${DebitCardTransactionsV2FragmentDoc}
` as unknown as TypedDocumentNode<
  GetDebitCardTransactionsQueryResponse,
  GetDebitCardTransactionsQueryVariables
>

/**
 * __useGetDebitCardTransactionsQuery__
 *
 * To run a query within a React component, call `useGetDebitCardTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebitCardTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebitCardTransactionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      nextToken: // value for 'nextToken'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetDebitCardTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDebitCardTransactionsQueryResponse,
    GetDebitCardTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDebitCardTransactionsQueryResponse,
    GetDebitCardTransactionsQueryVariables
  >(GetDebitCardTransactions, options)
}
export function useGetDebitCardTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDebitCardTransactionsQueryResponse,
    GetDebitCardTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDebitCardTransactionsQueryResponse,
    GetDebitCardTransactionsQueryVariables
  >(GetDebitCardTransactions, options)
}
export type GetDebitCardTransactionsQueryHookResult = ReturnType<
  typeof useGetDebitCardTransactionsQuery
>
export type GetDebitCardTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetDebitCardTransactionsLazyQuery
>
export type GetDebitCardTransactionsQueryResult = Apollo.QueryResult<
  GetDebitCardTransactionsQueryResponse,
  GetDebitCardTransactionsQueryVariables
>
