import { gql } from '@apollo/client'

export const GetPaymentInstruments = gql`
  query GetPaymentInstruments(
    $filter: PaymentInstrumentFilterInput
    $entityUuid: ID!
  ) {
    getPaymentInstruments(filter: $filter, entityUuid: $entityUuid) {
      id
      bankAccountDetails {
        account
        bsb
        name
      }
      contactUuid
      contact {
        id
        contactType
        firstName
        lastName
        businessName
      }
      details {
        ... on BankAccountDetails {
          bsb
          account
          name
        }
        ... on NppDetails {
          payId
        }
        ... on BpayDetails {
          billerCode
          crn
        }
      }
      status
    }
  }
`

export const GetPaymentInstrumentsWithoutContact = gql`
  query GetPaymentInstrumentsWithoutContact(
    $filter: PaymentInstrumentFilterInput
    $entityUuid: ID!
  ) {
    getPaymentInstrumentsWithoutContact(
      filter: $filter
      entityUuid: $entityUuid
    ) {
      id
      contactUuid
      contact {
        id
        contactType
        firstName
        lastName
        businessName
      }
      details {
        ... on BankAccountDetails {
          bsb
          account
          name
        }
        ... on NppDetails {
          payId
        }
        ... on BpayDetails {
          billerCode
          crn
        }
      }
      status
    }
  }
`
