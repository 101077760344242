import { FiltersHeaderRow } from 'components/FiltersHeaderRow'

import { DebitCardTransactionFilters } from '../../../components/DebitCardTransactionFilters/DebitCardTransactionFilters'

export const AccountLayoutTertiaryHeaderRowDeps = {
  DebitCardTransactionFilters,
}

export type AccountLayoutTertiaryHeaderRowProps = {
  accountId: string
  isFiltersVisible: boolean
  isTransactionsRoute: boolean
}

export const AccountLayoutTertiaryHeaderRow = ({
  accountId,
  isFiltersVisible,
  isTransactionsRoute,
}: AccountLayoutTertiaryHeaderRowProps) => {
  const { DebitCardTransactionFilters } = AccountLayoutTertiaryHeaderRowDeps

  return (
    <FiltersHeaderRow isOpen={isFiltersVisible && isTransactionsRoute}>
      <DebitCardTransactionFilters
        isFilterButtonDisabled={false}
        defaultFilters={{ selectedAccountID: accountId }}
      />
    </FiltersHeaderRow>
  )
}
