import { useRef } from 'react'
import { ModalFormProps } from 'design-system/Components/Modal'

import { useTrackScroll } from 'hooks/useTrackScroll/useTrackScroll'

import {
  StyledContentContainer,
  StyledModalForm,
} from './ModalFormScrollable.styled'

export const ModalFormScrollable = ({
  children,
  ...otherProps
}: ModalFormProps) => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const { isScrollable, hasScrolledToBottom, handleScroll } = useTrackScroll({
    scrollableRef,
  })

  const hasScrollableContentShadow = isScrollable && !hasScrolledToBottom

  return (
    <StyledModalForm bodyOpenClassName="modal-basic-open" {...otherProps}>
      <StyledContentContainer
        data-testid="modal-form-content-container"
        $hasScrollableContentShadow={hasScrollableContentShadow}
        onScroll={handleScroll}
        ref={scrollableRef}
      >
        {children}
      </StyledContentContainer>
    </StyledModalForm>
  )
}
