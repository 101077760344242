import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'
import { BREAKPOINT } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import {
  SetupFlowLocationStateSchema,
  SetupFlowStage,
} from '../../hooks/useSetupFlowStage/useGoToSetupFlowStage.types'
import { DownloadAppStageContent } from '../pages/DownloadAppStage/DownloadAppStageContent'
import { FeatureAccountsStageContent } from '../pages/FeatureAccountsStage/FeatureAccountsStageContent'
import { FeatureCardsStageContent } from '../pages/FeatureCardsStage/FeatureCardsStageContent'
import { FeatureContactsStageContent } from '../pages/FeatureContactsStage/FeatureContactsStageContent'
import { FeatureInvoicesStageContent } from '../pages/FeatureInvoicesStage/FeatureInvoicesStageContent'
import { FeatureItemsStageContent } from '../pages/FeatureItemsStage/FeatureItemsStageContent'
import { FeatureOverviewStageContent } from '../pages/FeatureOverviewStage/FeatureOverviewStageContent'
import { FeaturePaymentsStageContent } from '../pages/FeaturePaymentsStage/FeaturePaymentsStageContent'
import { FeaturePosLiteStageContent } from '../pages/FeaturePosLiteStage/FeaturePosLiteStageContent'
import { FeatureVirtualTerminalStageContent } from '../pages/FeatureVirtualTerminalStage/FeatureVirtualTerminalStageContent'
import { SetupFlowStartStageContent } from '../pages/SetupFlowStartStage/SetupFlowStartStageContent'
import { TapToPayStageContent } from '../pages/TapToPayStage/TapToPayStageContent'

const STAGE_MAP: Record<
  SetupFlowStage,
  {
    Component: () => React.ReactElement
    spotlightSelector?: string
  }
> = {
  SetupFlowStartStage: {
    Component: SetupFlowStartStageContent,
  },
  DownloadAppStage: {
    Component: DownloadAppStageContent,
  },
  TapToPayStage: {
    Component: TapToPayStageContent,
  },
  FeaturePaymentsStage: {
    Component: FeaturePaymentsStageContent,
    spotlightSelector: '.nav-item-payments > button',
  },
  FeatureAccountsStage: {
    Component: FeatureAccountsStageContent,
    spotlightSelector: '.nav-item-accounts',
  },
  FeatureCardsStage: {
    Component: FeatureCardsStageContent,
    spotlightSelector: '.nav-item-cards',
  },
  FeatureInvoicesStage: {
    Component: FeatureInvoicesStageContent,
    spotlightSelector: '.nav-item-invoices',
  },
  FeatureVirtualTerminalStage: {
    Component: FeatureVirtualTerminalStageContent,
    spotlightSelector: '.nav-item-virtual-terminal',
  },
  FeaturePosLiteStage: {
    Component: FeaturePosLiteStageContent,
    spotlightSelector: '.nav-item-pos-lite',
  },
  FeatureItemsStage: {
    Component: FeatureItemsStageContent,
    spotlightSelector: '.nav-item-items > button',
  },
  FeatureContactsStage: {
    Component: FeatureContactsStageContent,
    spotlightSelector: '.nav-item-contacts',
  },

  FeatureOverviewStage: {
    Component: FeatureOverviewStageContent,
    spotlightSelector: '.nav-item-overview',
  },
}

export const useSetupFlowModalState = () => {
  const locationState = useLocationState(SetupFlowLocationStateSchema)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.SetupFlowModal
  }, [locationState])

  const stage = useMemo(() => {
    if (!state) {
      return null
    }

    return state.stage
  }, [state])

  const { Component, spotlightSelector } = useMemo(() => {
    if (!stage || !STAGE_MAP[stage]) {
      return { Component: null, spotlightSelector: undefined }
    }
    return STAGE_MAP[stage]
  }, [stage])

  const visibleSpotlightSelector = isMobileOrTablet
    ? undefined
    : spotlightSelector
  const isSpotlightVisible = Boolean(visibleSpotlightSelector)

  return { stage, Component, visibleSpotlightSelector, isSpotlightVisible }
}
