import { showSuccessToast } from '@npco/zeller-design-system'

import { assertUnreachable } from 'utils/assertUnreachable'
import { pluralTranslate } from 'utils/translations'
import { FileWithId } from 'components/File'

import { useRemoveFiles } from './hooks/useRemoveFiles'
import { useUploadReceiptsModalState } from './hooks/useUploadReceiptsModalState'
import { UploadReceiptStage } from './hooks/useUploadReceiptsModalState.type'
import { DragAndDropStep } from './steps/DragAndDropStep/DragAndDropStep'
import { FailedUploadStep } from './steps/FailedUploadStep/FailedUploadStep'
import { UploadingStep } from './steps/UploadingStep/UploadingStep'
import { getFilesToUpload } from './UploadReceiptsModal.utils'

interface Props {
  isOpen: boolean
  onCancel: () => void
  maxFiles: number
  remainingFiles: number
  addUploadedImages: (images: FileWithId[]) => void
  transactionUuid: string
  transactionCombinedImagesIds: string[]
  isLoadingImages: boolean
}

export const UploadReceiptsModal = ({
  isOpen,
  onCancel,
  maxFiles,
  remainingFiles,
  addUploadedImages,
  transactionCombinedImagesIds,
  transactionUuid,
  isLoadingImages,
}: Props) => {
  const receiptState = useUploadReceiptsModalState()
  const { removeImages } = useRemoveFiles({
    transactionUuid,
  })

  const handleUpload = (
    failedFiles: FileWithId[],
    uploadedFiles: FileWithId[],
    goToNextStep: (failedFiles: FileWithId[]) => void
  ) => {
    addUploadedImages(uploadedFiles)
    const filesCount = uploadedFiles.length

    if (filesCount > 0) {
      showSuccessToast(
        pluralTranslate(
          'page.transactionDetails.images.modal.uploadSuccessSingular',
          'page.transactionDetails.images.modal.uploadSuccessPlural',
          filesCount,
          {
            numberOfImagesAdded: filesCount,
          }
        )
      )
    }

    if (failedFiles.length > 0) {
      goToNextStep(failedFiles)
    } else {
      onCancel()
    }
  }

  const uploadFiles =
    (goToNextStep: (failedFiles: FileWithId[]) => void) =>
    (failedFiles: FileWithId[], successfulFiles: FileWithId[]) =>
      handleUpload(failedFiles, successfulFiles, goToNextStep)

  switch (receiptState.stage) {
    case UploadReceiptStage.DragAndDrop: {
      const goToNextStep = (filesFormDragAndDrop: FileWithId[]) =>
        receiptState.goToNextStep(
          getFilesToUpload(filesFormDragAndDrop, transactionCombinedImagesIds)
        )

      return (
        <DragAndDropStep
          isOpen={isOpen}
          maxFiles={maxFiles}
          remainingFiles={remainingFiles}
          onCancel={onCancel}
          goToNextStep={goToNextStep}
          isLoadingImages={isLoadingImages}
        />
      )
    }

    case UploadReceiptStage.Uploading: {
      const onAbort = async (failedFiles: FileWithId[]) => {
        onCancel()
        const { failed } = await removeImages(failedFiles)

        if (failed.length > 0) {
          addUploadedImages(failed)
        }
      }

      const goToNextStep = uploadFiles(receiptState.goToNextStep)

      return (
        <UploadingStep
          isOpen={isOpen}
          filesToUpload={receiptState.filesToUpload}
          transactionUuid={transactionUuid}
          goToNextStep={goToNextStep}
          onAbort={onAbort}
        />
      )
    }

    case UploadReceiptStage.FailedUpload: {
      return (
        <FailedUploadStep
          isOpen={isOpen}
          failedFiles={receiptState.failedFiles}
          onRetry={receiptState.onRetry}
          onCancel={onCancel}
        />
      )
    }

    case UploadReceiptStage.Reuploading: {
      const goToNextStep = uploadFiles(receiptState.goToNextStep)

      return (
        <UploadingStep
          isOpen={isOpen}
          filesToUpload={receiptState.failedFiles}
          transactionUuid={transactionUuid}
          goToNextStep={goToNextStep}
          onAbort={receiptState.goToNextStep}
        />
      )
    }

    // no default
  }

  return assertUnreachable(receiptState)
}
