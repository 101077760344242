import { useState } from 'react'
import { BodyDefault, Box, InfoBox } from '@npco/zeller-design-system'
import { Field, useField } from 'formik'

import { translate } from 'utils/translations'
import { Condition } from 'components/Condition/Condition'
import { FormCheckbox } from 'components/Input/Checkbox'

import {
  StyledButtonLink,
  StyledHeader,
} from './SetCardSettingsAccessibilityOption.styled'

export const SetCardSettingsAccessibilityOption = () => {
  const [field] = useField({
    name: 'isAccessibleCard',
  })
  const [showAccessibilityOption, setShowAccessibilityOption] = useState(
    field.value
  )

  return (
    <Box>
      <Condition shouldShow={!showAccessibilityOption}>
        <StyledButtonLink onClick={() => setShowAccessibilityOption(true)}>
          {translate(
            'page.addCardModal.setCardSettings.accessibilityOptionLabel'
          )}
        </StyledButtonLink>
      </Condition>
      <Condition shouldShow={showAccessibilityOption}>
        <Box mt="40px" mb="24px">
          <StyledHeader>
            {translate(
              'page.addCardModal.setCardSettings.accessibilityOptionTitle'
            )}
          </StyledHeader>
        </Box>

        <BodyDefault>
          {translate(
            'page.addCardModal.setCardSettings.accessibilityOptionDescription'
          )}
        </BodyDefault>

        <Box pt="8px" mb="24px">
          <Field
            component={FormCheckbox}
            type="checkbox"
            label={translate(
              'page.addCardModal.setCardSettings.accessibilityOptionCheckboxLabel'
            )}
            name="isAccessibleCard"
          />
        </Box>

        <InfoBox dataTestId="info-box">
          <InfoBox.Message>
            {translate(
              'page.addCardModal.setCardSettings.accessibilityOptionWarning'
            )}
          </InfoBox.Message>
        </InfoBox>
      </Condition>
    </Box>
  )
}
