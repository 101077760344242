import { DebitCardStatus } from '@npco/mp-gql-types'
import { Box, InfoBox, INFOBOX_VARIANT } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface ZellerCardStatusInfoProps {
  status: DebitCardStatus
}

export const ZellerCardSummaryStatusInfo = ({
  status,
}: ZellerCardStatusInfoProps) => {
  if (status !== DebitCardStatus.SUSPENDED) {
    return null
  }

  return (
    <Box mt={{ _: '24PX', XS: '40px' }}>
      <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
        <InfoBox.Message>
          {translate('page.cardSingle.cardSummaryStatusInfo.suspended')}
        </InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
