import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { ReportLostCardCompleteState } from './useGoToReportLostCardCompleteStage.types'

export const useGoToReportLostCardCompleteStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToReportLostCardCompleteStage = useCallback(
    (state: ReportLostCardCompleteState | undefined) => {
      navigate(location.pathname, {
        state: {
          ReportLostCardCompleteModal: state,
        },
      })
    },
    [navigate, location]
  )

  return { goToReportLostCardCompleteStage }
}
