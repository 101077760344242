import {
  rvCheckoutAddress,
  rvECommerceCartItem,
} from '@npco/mp-feature-onboarding-shop'
import { DocumentType } from '@npco/mp-gql-types'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { EntityDetails, rvEntityDetails } from '@npco/mp-utils-selected-entity'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '@npco/utils-session-storage'
import {
  rvAddedContacts,
  rvContactProfilePictureMap,
  rvContacts,
  rvIsContactPaymentFiltersDisabled,
  rvSelectedContact,
  rvSelectedContactToTransfer,
  rvSelectedSubContact,
  rvSelectedTags,
} from 'features/Contacts/rv-deprecated/contacts'

import {
  datePickerInitialState,
  lastStoredTimeFilterInitialState,
  rangePickerInitialValue,
  searchPhraseInitialValue,
  sourcePickerInitialValue,
  statusPickerInitialValue,
  tagPickerInitialValue,
  terminalPickerInitialValue,
} from 'const/common'
import {
  CustomerDetailsForm,
  InitialMemberData,
  TemporaryCustomer,
} from 'types/customers'
import { OnboardingMetadata, SkippedPurchaseData } from 'types/onboarding'
import { ProfileSettingsData } from 'types/profile'
import { Account } from 'types/settings'
import { SiteCache } from 'types/site'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import { LAST_DASHBOARD_TAB_KEY } from 'pages/Dashboard/RememberDashboardTab/utils/sessionStorageKeys'

import {
  rvCurrentUserSites,
  rvCustomers,
  rvCustomersCurrentCustomerTempId,
  rvCustomersInitialMembersData,
  rvCustomerSiteOperation,
  rvCustomersTempData,
  rvDepositsPdfFont,
  rvEmailChangeStatus,
  rvFinishedLoading,
  rvHasSeenPushAppModal,
  rvInactiveAbnOrAcns,
  rvIsDateRangeAppropriate,
  rvIsGovernmentCustomerUpdated,
  rvIsUserSavingOnboarding,
  rvKYCData,
  rvLastSelectedDates,
  rvNewSites,
  rvNewUserDetails,
  rvNewUserType,
  rvOnboardingAreCustomersTempDataPrepared,
  rvOnboardingDataSummary,
  rvOnboardingInitialCustomerData,
  rvOnboardingInitialSearchData,
  rvOnboardingIsProcessing,
  rvOnboardingIsWarningVisible,
  rvOnboardingMetadata,
  rvOnboardingSkippedPurchaseData,
  rvOptimisticDebitCardTransactions,
  rvPathBeforeLoggingIn,
  rvProfileFetchError,
  rvProfileSettings,
  rvSearchPhrase,
  rvSelectedAccountTransaction,
  rvSelectedDates,
  rvSelectedDepositsSite,
  rvSelectedRates,
  rvSelectedSite,
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
  rvSelectedUser,
  rvSiteDetails,
  rvSiteFetchError,
  rvSiteTypeList,
  rvThirdPartyAccounts,
  rvThirdPartyCompanySearchError,
  rvToggleFilters,
  rvUnsupportedAbnOrAcns,
  rvUpdatedDebitCardTransactions,
  rvUpdatedEmailNotConfirmed,
  rvUpdatedUserDetails,
} from '../../graphql/reactiveVariables'
import { rvIsE2eInProgress } from '../../graphql/reactiveVariables/auth'
import {
  rvSalesErrorTracker,
  rvSitePerformanceErrorTracker,
  rvTransactionsErrorTracker,
} from '../../graphql/reactiveVariables/dashboard'
import {
  rvPersistedFiles,
  rvUploadedFiles,
} from '../../graphql/reactiveVariables/debitCardTransactions'
import {
  rvDetailedAccount,
  rvDetailedAccountType,
  rvThirdPartyDepositBank,
} from '../../graphql/reactiveVariables/depositAccount'
import { rvDeferredFilterPayload } from '../../graphql/reactiveVariables/filters'
import { rvForceMigrationPaymentInstrument } from '../../graphql/reactiveVariables/forceMigration'
import { rvUnlinkedPaymentInstruments } from '../../graphql/reactiveVariables/paymentInstruments'
import { rvSelectedSimSite } from '../../graphql/reactiveVariables/sim'
import {
  rvDetailedSimBillingAccount,
  rvDetailedSimBillingAccountType,
  rvSimBillingAccount,
} from '../../graphql/reactiveVariables/simBillingAccount'
import {
  rvSelectedTransaction,
  rvTransactionCardholderContactMap,
  rvTransactionsNotes,
} from '../../graphql/reactiveVariables/transactions'

// Import all reactive variables tied to an entity and need to be restored here
// Extract from https://npco-dev.atlassian.net/wiki/spaces/FRON/pages/2216001585/Multi+Entity#Reactive-Variables
export const keyToReactiveVarMap = {
  rvSelectedAccountTransaction,
  rvThirdPartyAccounts,
  rvOptimisticDebitCardTransactions,
  rvUpdatedDebitCardTransactions,
  rvSalesErrorTracker,
  rvSitePerformanceErrorTracker,
  rvTransactionsErrorTracker,
  rvPersistedFiles,
  rvUploadedFiles,
  rvDetailedAccount,
  rvDetailedAccountType,
  rvThirdPartyDepositBank,
  rvDeferredFilterPayload,
  rvForceMigrationPaymentInstrument,
  rvEntityDetails,
  rvFinishedLoading,
  rvOnboardingMetadata,
  rvOnboardingInitialSearchData,
  rvIsUserSavingOnboarding,
  rvIsGovernmentCustomerUpdated,
  rvOnboardingDataSummary,
  rvOnboardingInitialCustomerData,
  rvOnboardingIsProcessing,
  // Need to change this logic - user is allowed to navigate
  // away to another entity, but not allowed to leave the dashboard
  // as a whole or move away within the tab.
  rvOnboardingIsWarningVisible,
  rvCustomersTempData,
  rvOnboardingAreCustomersTempDataPrepared,
  rvCustomersInitialMembersData,
  rvCustomersCurrentCustomerTempId,
  rvOnboardingSkippedPurchaseData,
  rvThirdPartyCompanySearchError,
  rvUnlinkedPaymentInstruments,
  rvSelectedSimSite,
  rvSimBillingAccount,
  rvDetailedSimBillingAccount,
  rvDetailedSimBillingAccountType,
  rvSiteDetails,
  rvSiteFetchError,
  rvSelectedSite,
  rvSelectedUser,
  rvNewSites,
  rvNewUserType,
  rvCustomerSiteOperation,
  rvCurrentUserSites,
  rvUpdatedUserDetails,
  rvSiteTypeList,
  rvTransactionCardholderContactMap,
  rvTransactionsNotes,
  rvSelectedTransaction,
  rvSearchPhrase,
  rvSelectedTerminals,
  rvSelectedStatuses,
  rvSelectedSources,
  rvSelectedTypes,
  rvLastSelectedDates,
  rvSelectedDates,
  rvIsDateRangeAppropriate,
  rvSelectedRates,
  rvToggleFilters,
  rvSelectedDepositsSite,
  rvDepositsPdfFont,
  rvProfileSettings, // Partially Customer and partially Entity?
  rvUpdatedEmailNotConfirmed,
  rvEmailChangeStatus, // Customer?
  rvProfileFetchError, // Customer & Entity?
  rvNewUserDetails,
  rvCustomers,
  rvContacts,
  rvAddedContacts,
  rvSelectedContact,
  rvSelectedSubContact,
  rvContactProfilePictureMap,
  rvSelectedContactToTransfer,
  rvSelectedTags,
  rvIsContactPaymentFiltersDisabled,
  rvECommerceCartItem,
  rvCheckoutAddress,
  // rvs linked to session storage and not in the
  // https://npco-dev.atlassian.net/wiki/spaces/FRON/pages/2216001585/Multi+Entity#Reactive-Variables
  rvCurrentUserData,
  rvUnsupportedAbnOrAcns,
  rvInactiveAbnOrAcns,
  rvPathBeforeLoggingIn,
  rvIsE2eInProgress,
  rvKYCData,
  rvHasSeenPushAppModal,
}

export const getEntityReactiveVariableData = () => {
  // Use Object.entries to iterate over the keyToReactiveVarMap
  return Object.fromEntries(
    Object.entries(keyToReactiveVarMap).map(([key, func]) => [key, func()])
  )
}

// Extract from https://npco-dev.atlassian.net/wiki/spaces/FRON/pages/2216001585/Multi+Entity#Session-Data
// if it's linked to a reactive variable, it would be put in the keyToReactiveVarMap and resetEntityReactiveVariables
export const getEntitySessionData = () => {
  return {
    'last-dashboard-tab-key': getSessionStorageItem('last-dashboard-tab-key'),
    'hide-overview-banner': getSessionStorageItem('hide-overview-banner'),
    startupModal: getSessionStorageItem('startupModal'),
  }
}

export const getEntityLocalStorageData = () => {
  return {
    'dev.FeatureFlags.values': JSON.parse(
      window.localStorage.getItem('dev.FeatureFlags.values') ?? 'null'
    ),
  }
}

export const resetEntityReactiveVariables = () => {
  rvSelectedAccountTransaction(null)
  rvThirdPartyAccounts([])
  rvOptimisticDebitCardTransactions([])
  rvUpdatedDebitCardTransactions([])
  rvSalesErrorTracker(null)
  rvSitePerformanceErrorTracker(null)
  rvTransactionsErrorTracker(null)
  rvPersistedFiles({})
  rvUploadedFiles({})
  rvDetailedAccount(null)
  rvDetailedAccountType(Account.ZELLER)
  rvThirdPartyDepositBank(null)
  rvDeferredFilterPayload({})
  rvForceMigrationPaymentInstrument(undefined)
  rvEntityDetails({} as EntityDetails)
  rvFinishedLoading(false)
  rvOnboardingMetadata({} as OnboardingMetadata)
  rvOnboardingInitialSearchData({})
  rvIsUserSavingOnboarding(false)
  rvIsGovernmentCustomerUpdated(false)
  rvOnboardingDataSummary({})
  rvOnboardingInitialCustomerData({})
  rvOnboardingIsProcessing(false)
  rvOnboardingIsWarningVisible(false)
  rvCustomersTempData([] as TemporaryCustomer[])
  rvOnboardingAreCustomersTempDataPrepared(false)
  rvCustomersInitialMembersData([] as InitialMemberData[])
  rvCustomersCurrentCustomerTempId(undefined)
  rvOnboardingSkippedPurchaseData({} as SkippedPurchaseData)
  rvThirdPartyCompanySearchError(false)
  rvUnlinkedPaymentInstruments([])
  rvSelectedSimSite('')
  rvSimBillingAccount(null)
  rvDetailedSimBillingAccount(null)
  rvDetailedSimBillingAccountType(Account.ZELLER)
  rvSiteDetails(null)
  rvSiteFetchError(null)
  rvSelectedSite('')
  rvSelectedUser('')
  rvNewSites([] as SiteCache[])
  rvNewUserType(null)
  rvCustomerSiteOperation(null)
  rvCurrentUserSites([])
  rvUpdatedUserDetails({})
  rvSiteTypeList([])
  rvTransactionCardholderContactMap({})
  rvTransactionsNotes({})
  rvSelectedTransaction(null)
  rvSearchPhrase(searchPhraseInitialValue)
  rvSelectedTerminals(terminalPickerInitialValue)
  rvSelectedStatuses(statusPickerInitialValue)
  rvSelectedSources(sourcePickerInitialValue)
  rvSelectedTypes(sourcePickerInitialValue)
  rvLastSelectedDates(lastStoredTimeFilterInitialState)
  rvSelectedDates(datePickerInitialState)
  rvIsDateRangeAppropriate(true)
  rvSelectedRates(rangePickerInitialValue)
  rvToggleFilters(false)
  rvSelectedDepositsSite('')
  rvDepositsPdfFont(null)
  rvProfileSettings({
    id: '',
    email: null,
    firstname: null,
    middlename: null,
    lastname: null,
    address: null,
  } as ProfileSettingsData)
  rvUpdatedEmailNotConfirmed('')
  rvEmailChangeStatus(null)
  rvProfileFetchError(null)
  rvNewUserDetails({} as CustomerDetailsForm)
  rvCustomers([])
  rvContacts(null)
  rvAddedContacts([])
  rvSelectedContact(null)
  rvSelectedSubContact(null)
  rvContactProfilePictureMap({})
  rvSelectedContactToTransfer(null)
  rvSelectedTags(tagPickerInitialValue)
  rvIsContactPaymentFiltersDisabled(false)
  rvECommerceCartItem([])
  rvCheckoutAddress({})
  // rvs linked to session storage and not in the
  // https://npco-dev.atlassian.net/wiki/spaces/FRON/pages/2216001585/Multi+Entity#Reactive-Variables
  rvCurrentUserData(null)
  rvUnsupportedAbnOrAcns({})
  rvInactiveAbnOrAcns({})
  rvPathBeforeLoggingIn('')
  rvIsE2eInProgress(false)
  rvKYCData({
    attempts: {
      [DocumentType.DRIVING_LICENCE]: 0,
      [DocumentType.MEDICARE_CARD]: 0,
      [DocumentType.PASSPORT]: 0,
    },
  })
  rvHasSeenPushAppModal(false)
}

export const resetEntitySessionData = () => {
  setSessionStorageItem(LAST_DASHBOARD_TAB_KEY, undefined)
  setSessionStorageItem(SESSION_STORAGE_KEYS.HIDE_OVERVIEW_BANNER, undefined)
  setSessionStorageItem('startupModal', undefined)
}
