import { gql } from '@apollo/client/core'

export const TransactionUpdate = gql`
  subscription TransactionUpdate($entityUuid: ID!) {
    onTransactionUpdate(entityUuid: $entityUuid) {
      id
      deviceName
      deviceModel
      siteName
      siteUuid
      timestamp
      refunded
      refundedAmount
      reversed
      review
      amount
      scheme
      type
      cardMedia
      status
      maskedPan
      reference
      responseCode
      source
      sourceFilter
      issuer
      tipAmount
      taxAmounts {
        name
        amount
      }
      surchargeAmount
      feeAmount
    }
  }
`
export const ExportTransaction = gql`
  subscription ExportTransaction(
    $entityUuid: ID!
    $customerUuid: ID!
    $filter: String!
    $format: TransactionExportFormat!
  ) {
    exportTransaction(
      entityUuid: $entityUuid
      customerUuid: $customerUuid
      filter: $filter
      format: $format
    ) {
      downloadLink
      expire
      error
    }
  }
`
