import { translate } from 'utils/translations'

export enum FileUploaderErrorType {
  REQUIRED_SELECT_OPTION = 'REQUIRED_SELECT_OPTION',
}

const errorMapping: {
  [key in FileUploaderErrorType]: string
} = {
  [FileUploaderErrorType.REQUIRED_SELECT_OPTION]: translate(
    'form.documentType.documentTypeRequired'
  ),
}

export const getErrorMessageByType = (type: FileUploaderErrorType) =>
  errorMapping[type]
