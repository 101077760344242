import { useTranslations } from '@npco/utils-translations'
import { useField } from 'formik'

import { Checkbox } from '../../../Input/Checkbox'
import { ITEM_ADD_NEW_ITEM_FIELD } from '../../ItemBaseModalForm.constants'
import { translations } from './AddNewItemCheckbox.i18n'

export const AddNewItemCheckbox = () => {
  const [field, , handlers] = useField(ITEM_ADD_NEW_ITEM_FIELD)
  const t = useTranslations(translations)

  return (
    <Checkbox
      name={field.name}
      label={t('label')}
      onChange={() => handlers.setValue(!field.value)}
      value={field.value}
      checked={field.value}
    />
  )
}
