import { useEffect, useMemo } from 'react'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { useAccountsTransactionsMetadata } from 'features/AccountsTransactions'

import { useAccountId, useSubscribeToAccounts } from 'hooks/banking'
import { resetAccountTransactionsFilters } from 'utils/common'

import { AccountTransactionsList } from '../AccountTransactionsList/AccountTransactionsList'
import { useAccountTransactionsFilters } from './hooks/useAccountTransactionsFilters/useAccountTransactionsFilters'
import { useGetAccountType } from './hooks/useGetAccountType/useGetAccountType'
import { SavingsAccountEmptyTransactions } from './SavingsAccountEmptyTransactions/SavingsAccountEmptyTransactions'

export const AccountTransactions = () => {
  useSubscribeToAccounts()

  const { accountId } = useAccountId()
  const { setIsActionsVisible } = useAccountsTransactionsMetadata()
  const { filters, areFiltersInDefaultState } = useAccountTransactionsFilters()

  // NOTE: account transactions handles its own data requirements so does not
  // pull account state from the account layout context defined above
  const { account, isLoadingAccountType } = useGetAccountType({ accountId })

  const isSavingsAccount = account?.type === DebitCardAccountType.SAVINGS
  const isClosedAccount = account?.status !== DebitCardAccountStatus.CLOSED

  useEffect(() => {
    resetAccountTransactionsFilters()
  }, [])

  const emptyState = useMemo(() => {
    if (isClosedAccount && isSavingsAccount) {
      return <SavingsAccountEmptyTransactions />
    }
    return undefined
  }, [isClosedAccount, isSavingsAccount])

  return (
    <AccountTransactionsList
      accountTypeLoading={isLoadingAccountType}
      areFiltersInDefaultState={areFiltersInDefaultState}
      emptyState={emptyState}
      filters={filters}
      isSavingsAccount={isSavingsAccount}
      setIsActionsVisible={setIsActionsVisible}
    />
  )
}
