import { useCallback } from 'react'
import { useApolloClient, useReactiveVar } from '@apollo/client'
import { ErrorLogger } from '@npco/utils-error-logger'
import { rvOptimisticDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import {
  decreaseAccountBalance,
  increaseAccountBalance,
} from 'utils/banking/cacheAccountBalance/cacheAccountBalance'

import { createInternalOptimisticTransactions } from '../optimisticTransactions.utils'
import { useTransferQuery } from './useTransferQuery/useTransferQuery'

interface UpdateAfterZellerTransferArguments {
  id: string
  transferInUuid: string | null
  from: string | null
  to: string | null
  amountInCents: number
  reference: string
}

export const useUpdateAfterZellerTransfer = () => {
  const { accountById } = useTransferQuery()
  const optimisticTransactions = useReactiveVar(
    rvOptimisticDebitCardTransactions
  )
  const { cache } = useApolloClient()

  return useCallback(
    ({
      id,
      transferInUuid,
      from,
      to,
      amountInCents,
      reference,
    }: UpdateAfterZellerTransferArguments) => {
      const fromRecipient = accountById(from)
      const toRecipient = accountById(to)

      if (!(fromRecipient && toRecipient)) {
        ErrorLogger.report(
          `[Banking] Sent zeller transfer to an account that did not exist in the cache, from: ${from}, to: ${to}`
        )
      }

      if (fromRecipient?.id) {
        decreaseAccountBalance({
          cache,
          id: fromRecipient.id,
          valueInCents: amountInCents,
        })
      }

      if (toRecipient?.id) {
        increaseAccountBalance({
          cache,
          id: toRecipient.id,
          valueInCents: amountInCents,
        })
      }

      const now = new Date().toISOString()

      if (fromRecipient && toRecipient) {
        const updatedOptimisticTransactions = [
          ...createInternalOptimisticTransactions({
            transferOutUuid: id,
            transferInUuid,
            amountInCents,
            reference,
            fromRecipient,
            toRecipient,
            timestamp: now,
          }),
          ...optimisticTransactions,
        ]

        rvOptimisticDebitCardTransactions(updatedOptimisticTransactions)
      }
    },
    [optimisticTransactions, accountById, cache]
  )
}
