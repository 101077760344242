import { BREAKPOINT, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Description = styled(Heading.H2)`
  color: ${({ theme }) => theme.colors.BLUE_1000};

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    max-width: 438px;
  }
`
