import { useCallback, useState } from 'react'
import { useCustomerQuery } from '@npco/mp-utils-logged-in-user'
import {
  useEntityQuery,
  useSelectedEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'

import { useCustomerEntityMappingDataLazy } from '../useCustomerEntityMappingData/useCustomerEntityMappingDataLazy'

type UseSetupMultiEntityProps = {
  getCustomerData: ReturnType<typeof useCustomerQuery>['getCustomerData']
  getEntityData: ReturnType<typeof useEntityQuery>['getEntityData']
}

export const useSetupMultiEntity = ({
  getCustomerData,
  getEntityData,
}: UseSetupMultiEntityProps) => {
  const [isSettingUpActiveEntity, setIsSettingUpActiveEntity] = useState(false)

  const entityIdFromUrl = useSelectedEntityUuid()

  const { getCustomerEntityMapping } = useCustomerEntityMappingDataLazy()

  const getEntityIdFromActiveTabOrDefault = useCallback(async () => {
    const result = await getCustomerEntityMapping()

    return (
      result?.entityRelations.find((entityRelation) => entityRelation.isActive)
        ?.entityUuid ?? result?.defaultEntityUuid
    )
  }, [getCustomerEntityMapping])

  const setupMultiEntity = useCallback(
    async (customerUuid: string) => {
      try {
        setIsSettingUpActiveEntity(true)

        const entityIdToSetup =
          (entityIdFromUrl || (await getEntityIdFromActiveTabOrDefault())) ??
          undefined

        // If no customer entity mapping, use undefined entity id in getEntity, and wait for that response to pass entityUuid to getCustomer call.
        if (!entityIdToSetup) {
          const result = await getEntityData()

          const entityResultId = result.data?.getEntity?.id

          if (!entityResultId) {
            ErrorLogger.report(
              '[Setup] - No entity id available',
              undefined,
              undefined,
              'error'
            )
          }

          getCustomerData({
            variables: {
              customerUuid,
              entityUuid: entityResultId ?? '',
            },
          })
          return
        }

        getEntityData({ variables: { entityUuid: entityIdToSetup } })
        getCustomerData({
          variables: {
            customerUuid,
            entityUuid: entityIdToSetup,
          },
        })
      } finally {
        setIsSettingUpActiveEntity(false)
      }
    },
    [
      entityIdFromUrl,
      getEntityIdFromActiveTabOrDefault,
      getEntityData,
      getCustomerData,
    ]
  )

  return { setupMultiEntity, isSettingUpActiveEntity }
}
