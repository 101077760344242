import { createContext, useContext } from 'react'

import { GenerateDebitCardPanTokenMutationVariables } from 'hooks/useDebitCardPanToken/graphql/generateDebitCardPanToken.generated'

import { AccountType } from '../hooks/useSetupFlowAccountsQuery/graphql/getAccounts.type'
import { DebitCardType } from '../hooks/useSetupFlowCardsQuery/graphql/getCards.type'

export interface SetupFlowContextType {
  clientAccessToken: string | undefined
  exitClicked: boolean
  generatePanToken: (
    variables: GenerateDebitCardPanTokenMutationVariables
  ) => Promise<void>
  generateTokenError: any
  isLoaded: boolean
  isLoadingAccounts: boolean
  isLoadingCards: boolean
  isLoadingDebitCardPanToken: boolean
  refetchAccounts: () => void
  setExitClicked: (isClicked: boolean) => void
  setupFlowAccount: AccountType | undefined
  setupFlowCard: DebitCardType | undefined
  isCreateCardAllowedInitialState: boolean
  setIsCreateCardAllowedInitialState: (isAllowed: boolean) => void
}

export const SetupFlowContext = createContext<SetupFlowContextType | null>(null)

export const useSetupFlowContext = () => {
  const context = useContext(SetupFlowContext)

  if (!context) {
    throw new Error('useSetupFlowContext must be used within SetupFlowContext')
  }

  return context
}
