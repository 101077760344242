import { useContext } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SvgIcon,
} from '@npco/zeller-design-system'

import { DrawerNavigationContext } from '../DrawerNavigation.context'
import { navButtonsTranslations } from './NavButtons.i18n'
import { StyledNavButton } from './NavButtons.styled'

export const NavButtons = () => {
  const {
    innerSelectPrevious,
    innerSelectNext,
    changeMode,
    drawerRef,
    onPrevious,
    onNext,
  } = useContext(DrawerNavigationContext)

  const t = useTranslations(navButtonsTranslations)

  return (
    <>
      <StyledNavButton
        data-testid="previous-nav-button"
        aria-label={t('previousLabel')}
        disabled={!onPrevious}
        onClick={innerSelectPrevious}
        type="button"
        ref={
          changeMode === 'previous'
            ? (drawerRef as React.MutableRefObject<HTMLButtonElement>)
            : null
        }
      >
        <SvgIcon width="16" height="16">
          <ChevronLeftIcon />
        </SvgIcon>
      </StyledNavButton>
      <StyledNavButton
        data-testid="next-nav-button"
        aria-label={t('nextLabel')}
        disabled={!onNext}
        onClick={innerSelectNext}
        type="button"
        ref={
          changeMode === 'next'
            ? (drawerRef as React.MutableRefObject<HTMLButtonElement>)
            : null
        }
      >
        <SvgIcon width="16" height="16">
          <ChevronRightIcon />
        </SvgIcon>
      </StyledNavButton>
    </>
  )
}
