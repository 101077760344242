// TICKET: BANK-557
import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { ConnectionType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { Disconnect, DisconnectVariables } from 'types/gql-types/Disconnect'
import { errorMessages } from 'translations'

import type { MutationResult } from '../XeroBankFeeds/ManageXeroBankFeeds/shared/MutationResult'
import { updateOnRemove } from '../XeroBankFeeds/xeroBankFeeds.utils'

const { smthWentWrong } = errorMessages

export const MutationDisconnect = gql`
  mutation Disconnect($entityUuid: ID!, $connectionType: ConnectionType!) {
    disconnectConnection(
      entityUuid: $entityUuid
      connectionType: $connectionType
    ) {
      id
    }
  }
`

export const useMutationDisconnect = (
  connectionType: ConnectionType = ConnectionType.XERO_BANKFEEDS,
  errorMessage: string = smthWentWrong
): [(connectionType?: ConnectionType) => MutationResult<never>] => {
  const entityUuid = useSelectedEntityUuid()
  const [execute] = useMutation<Disconnect, DisconnectVariables>(
    MutationDisconnect,
    {
      onError: (error) => showApiErrorToast(error, errorMessage),
      variables: { entityUuid, connectionType },
    }
  )
  return [
    useCallback(async () => {
      const { errors } = await execute({
        update: updateOnRemove,
        variables: { entityUuid, connectionType },
      })
      return { data: undefined, error: errors }
    }, [connectionType, entityUuid, execute]),
  ]
}

useMutationDisconnect.gql = MutationDisconnect
useMutationDisconnect.gqlData = undefined as unknown as Disconnect
useMutationDisconnect.gqlVariables = undefined as unknown as DisconnectVariables
