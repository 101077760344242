import { useMemo } from 'react'
import { COLOR } from '@npco/zeller-design-system'
import { XAxis } from 'recharts'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { getHasNoSales } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/DashboardPerformance.utils'
import { getPlaceholderBarTransactions } from 'pages/Reports/Reports.utils'
import { TooltipContent } from 'pages/Reports/ReportsCharts/TotalChart/TooltipContent/TooltipContent'
import { BarChart } from 'components/Charts/BarChart/BarChart'
import {
  formatDayTicks,
  formatDollarValueTicks,
  formatMonthTicks,
} from 'components/Charts/Shared/AxisTickFormatter.utils'
import { BaseLoadingXAxisChartTick } from 'components/Charts/Shared/BaseLoadingXAxisChartTick/BaseLoadingXAxisChartTick'
import { BaseLoadingYAxisChartTick } from 'components/Charts/Shared/BaseLoadingYAxisChartTick/BaseLoadingYAxisChartTick'
import { BaseYAxisChartTick } from 'components/Charts/Shared/BaseYAxisChartTick/BaseYAxisChartTick'

import { TransactionTotal } from '../../../Dashboard.types'
import {
  convertToDayTotalSalesPairs,
  getDayTicks,
  getMonthTicks,
} from './SalesChart.utils'

interface Props {
  salesTotals: TransactionTotal[]
  isLoading: boolean
}

export const SalesChart = ({ salesTotals, isLoading }: Props) => {
  const hasNoSales = getHasNoSales(salesTotals)

  const salesChartData = useMemo(
    () =>
      hasNoSales || isLoading
        ? convertToDayTotalSalesPairs(getPlaceholderBarTransactions())
        : convertToDayTotalSalesPairs(salesTotals),
    [salesTotals, hasNoSales, isLoading]
  )

  const isMobile = useIsMobileResolution()

  const yAxisTickFunction = (props: any) => {
    if (isLoading) {
      const width = props.index === 0 ? 24 : 40

      return <BaseLoadingYAxisChartTick {...props} height={8} width={width} />
    }

    return (
      <BaseYAxisChartTick
        {...props}
        fill={COLOR.BLACK}
        fontSize="0.875rem"
        isZero={hasNoSales}
        dy="0.355em"
      />
    )
  }

  const xAxisLoadingTickFunction = (props: any) => {
    return <BaseLoadingXAxisChartTick {...props} height={12} width={24} />
  }

  const barProps = useMemo(() => {
    const fillObject =
      isLoading || hasNoSales
        ? {}
        : {
            fill: COLOR.BLUE_1000,
          }

    return {
      ...fillObject,
      barSize: isMobile ? 3 : 5,
      radius: 10,
    }
  }, [hasNoSales, isLoading, isMobile])

  return (
    <BarChart
      dataTestId={isLoading ? 'sales-chart-loading' : 'sales-chart'}
      margin={{ top: 12, left: 16, right: 16 }}
      TooltipContent={TooltipContent(false)}
      height={240}
      width="100%"
      disabled={hasNoSales}
      data={salesChartData}
      xAxisTickFormatter={formatDayTicks}
      yAxisTickFormatter={formatDollarValueTicks}
      xAxisProps={{
        type: 'number',
        minTickGap: 40,
        scale: 'utc',
        domain: ['dataMin', 'dataMax'],
        tick: !isLoading
          ? { fill: COLOR.BLACK, fontSize: 14 }
          : xAxisLoadingTickFunction,
        interval: 'preserveStartEnd',
        ticks: getDayTicks(salesChartData),
        tickMargin: 10,
      }}
      yAxisProps={{
        tick: yAxisTickFunction,
        tickMargin: 4,
      }}
      barProps={barProps}
      gridColor={COLOR.GREY_30}
      isLoading={isLoading}
    >
      <XAxis
        xAxisId={1}
        dataKey="name"
        axisLine={false}
        tickLine={false}
        tickFormatter={formatMonthTicks}
        tickSize={12}
        tick={{ fill: COLOR.GREY_400, fontSize: 12 }}
        ticks={!isLoading ? getMonthTicks(salesChartData, isMobile) : ['']}
        type="number"
        minTickGap={32}
        scale="utc"
        domain={['dataMin', 'dataMax']}
        interval="preserveStartEnd"
        tickMargin={0}
      />
    </BarChart>
  )
}
