import { EntityCategories } from '@npco/mp-gql-types'
import { showErrorToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const displayCategoryEditError = () => {
  showErrorToast(
    translate('page.transactionDetails.zellerCategories.updateCategoryFailure')
  )
}

type NullableString = string | null | undefined

export interface SubcategoryUpdateVariables {
  category: EntityCategories | null
  subcategoryName: NullableString
  subcategoryId: NullableString
  predefined?: boolean
}

export const createSubcategoryUpdate = ({
  category,
  subcategoryName,
  subcategoryId,
  predefined,
}: SubcategoryUpdateVariables) => {
  if (subcategoryName && subcategoryId) {
    return {
      category,
      subcategory: subcategoryId,
      subcategoryDetails: {
        id: subcategoryId,
        name: subcategoryName,
        predefined: predefined ?? true,
      },
    }
  }

  return {
    category,
    subcategory: subcategoryId,
    subcategoryDetails: null,
  }
}
