import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { PlaceholderSize } from 'components/Placeholders/Placeholders.types'

export const AccountDetailsViewError = () => {
  return (
    <div role="status">
      <GenericErrorPage variant={PlaceholderSize.Large} />
    </div>
  )
}
