import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { BillingReview } from './BillingReview/BillingReview'
import { FreeTrialReview } from './FreeTrialReview/FreeTrialReview'

export const CorporateCardBillingReview = () => {
  const flags = useFeatureFlags()

  return (
    <>
      {!flags.CorpCardsExistingEntities && <FreeTrialReview />}
      <BillingReview />
    </>
  )
}
