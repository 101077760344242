import { useCallback } from 'react'
import { parsePriceToCents } from '@npco/component-mp-common'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useCreatePaymentInstrument } from 'hooks/paymentInstruments/useCreatePaymentInstrument'
import {
  MFA_ERROR,
  RESOURCE_ALREADY_EXISTS_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'
import { useGetBpayBillerDetailQueryLazyQuery } from 'pages/Transfer/BPay/hooks/GetBpayBillerDetailQuery.generated'
import { useValidateBpayPaymentQueryLazyQuery } from 'pages/Transfer/BPay/hooks/ValidateBpayPaymentQuery.generated'
import { getCreatePaymentInstrumentInput } from 'pages/Transfer/hooks/useSendBpayTransfer/useCreateAndSendBpayTransfer.utils'

import { useSubmitBpayTransfer } from './useSubmitBpayTransfer'

interface CreateAndSendBpayTransfer {
  amount: string
  debitCardAccountUuid: string
  crn: string
  nickname: string
  billerCode: string
  billerName: string
  contactUuid: string
}

export const useCreateAndSendBpayTransfer = () => {
  const entityUuid = useSelectedEntityUuid()
  const [validateBpayPayment, { loading: loadingValidation }] =
    useValidateBpayPaymentQueryLazyQuery()
  const [getBpayBillerDetail, { loading: loadingDetail }] =
    useGetBpayBillerDetailQueryLazyQuery()
  const { createPaymentInstrument, loading: loadingCreatePaymentInstrument } =
    useCreatePaymentInstrument()

  const { submitBpayTransfer, isSubmitBpayLoading } = useSubmitBpayTransfer(5)

  const isLoading =
    loadingValidation ||
    loadingDetail ||
    isSubmitBpayLoading ||
    loadingCreatePaymentInstrument

  const handleSubmit = useCallback(
    async ({
      crn,
      amount,
      nickname,
      contactUuid,
      billerCode,
      billerName,
      debitCardAccountUuid,
    }: CreateAndSendBpayTransfer) => {
      try {
        const amountInCents = parsePriceToCents(amount).toString()
        const validatedBpayPayment = await validateBpayPayment({
          variables: {
            entityUuid,
            input: {
              billerCode,
              crn,
              amount: {
                currency: 'AUD',
                value: amountInCents,
              },
            },
          },
        })

        if (!validatedBpayPayment.data?.validateBpayPayment.isValid) {
          throw new Error('failed to validate payment')
        }

        const bpayBillerDetail = await getBpayBillerDetail({
          variables: { entityUuid, billerCode },
        })

        if (!bpayBillerDetail.data?.getBpayBillerDetail.crnLengths) {
          throw new Error('failed to getBpayBillerDetail')
        }

        const crnLengths = bpayBillerDetail.data?.getBpayBillerDetail.crnLengths
        const isDynamicPayment =
          bpayBillerDetail.data?.getBpayBillerDetail.icrnIndicator

        const createPaymentInstrumentInput = getCreatePaymentInstrumentInput({
          isDynamicPayment,
          contactUuid,
          billerCode,
          crn,
          crnLengths,
          nickname,
          billerName,
        })

        const paymentInstrumentResponse = await createPaymentInstrument(
          createPaymentInstrumentInput
        )

        if (paymentInstrumentResponse === MFA_ERROR) {
          return MFA_ERROR
        }

        if (
          paymentInstrumentResponse === UNEXPECTED_ERROR ||
          paymentInstrumentResponse === RESOURCE_ALREADY_EXISTS_ERROR
        ) {
          return paymentInstrumentResponse
        }

        const submitBpayTransferResults = await submitBpayTransfer({
          isDynamicPayment,
          amountInCents,
          debitCardAccountUuid,
          paymentInstrumentUuid: paymentInstrumentResponse.id,
          crn,
        })

        return submitBpayTransferResults
      } catch {
        return UNEXPECTED_ERROR
      }
    },
    [
      validateBpayPayment,
      entityUuid,
      getBpayBillerDetail,
      createPaymentInstrument,
      submitBpayTransfer,
    ]
  )
  return { handleSubmit, loading: isLoading }
}
