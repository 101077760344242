import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useCheckForAbusiveDescriptionMutation } from 'pages/Transfer/hooks/useCheckForAbusiveDescriptionResult/graphql/checkForAbusiveDescription.generated'

const MUTATION_DEBOUNCE_DELAY_MS = 1000

export const useCheckForAbusiveDescriptionResult = () => {
  const entityUuid = useSelectedEntityUuid()
  const [checkForAbusiveDescriptionMutation, { loading }] =
    useCheckForAbusiveDescriptionMutation({
      context: {
        debounceKey: 'searchBusinessIdentifier',
        debounceTimeout: MUTATION_DEBOUNCE_DELAY_MS,
      },
    })

  const checkForAbusiveDescription = async ({
    description,
  }: {
    description: string
  }) => {
    try {
      const { data } = await checkForAbusiveDescriptionMutation({
        variables: { description, entityUuid },
      })

      return data?.checkForAbusiveDescription?.isAbusive
    } catch (e) {
      return false
    }
  }

  return {
    isCheckingAbusiveMessage: loading,
    checkForAbusiveDescription,
  }
}
