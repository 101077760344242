import {
  BodyDefaultTypography,
  BodyLargeTypography,
  DrawerBasic,
  H2,
  H4,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

interface TextProps {
  $color: string
}

interface ContainerProps {
  $backgroundColor: string
}

export const StyledAmountContainer = styled.div`
  margin: 2.5rem 0 0;
`

export const StyledDetailsTitle = styled(H4)`
  margin: 1.5rem 0 1rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledDetailsLabel = styled.span`
  color: ${({ theme }) => theme.colors.BLACK_900};
  ${BodyDefaultTypography};
  ${InterZellerMediumFont};
`

export const StyledDetailsValue = styled.div`
  max-width: 60%;
  text-align: right;
  color: ${({ theme }) => theme.colors.BLACK_900};
  ${BodyDefaultTypography}
`

export const StyledAmountBadge = styled.div<ContainerProps>`
  width: auto;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.$backgroundColor};
`

export const StyledAmountLabel = styled.span<TextProps>`
  ${BodyLargeTypography}
  color: ${(props) => props.$color};
`

export const StyledAmountValue = styled(H2)<TextProps>`
  margin: 0;
  color: ${(props) => props.$color};
`

export const StyledSimCardDrawer = styled(DrawerBasic)`
  .drawer-header {
    padding-top: 14px;
  }

  .drawer-title-container {
    margin-bottom: 0;
  }

  .drawer-content {
    padding-top: 0;
  }
`
