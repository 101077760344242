import { SkeletonH3 as SkeletonHeading } from '@npco/zeller-design-system'

import { ListItem } from '../ListItem'
import { SectionTitle } from './ListItemSectionTitle'

const SectionTitleSkeleton = SectionTitle.withComponent((props) => (
  <SkeletonHeading width={180} {...props} />
))

export type ListItemSectionTitleSkeletonProps = Record<string, never>

export const ListItemSectionTitleSkeleton = () => (
  <ListItem left={<SectionTitleSkeleton />} />
)
