import styled from 'styled-components'

export const AddValue = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.GREY_30};
  box-sizing: border-box;
`

export const ZellerLogoWrapper = styled.div`
  width: 40px;
  background: ${({ theme }) => theme.colors.GREY_30};
  svg {
    background-color: ${({ theme }) => theme.colors.GREY_30};
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.GREY_30};
  justify-content: center;
  align-items: center;
  img {
    max-width: 40px;
    max-height: 40px;
  }
`
