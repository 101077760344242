import { useCallback } from 'react'
import { useDisableNavigation } from '@npco/ui-drawer-navigation'
import { showErrorToast } from '@npco/zeller-design-system'
import {
  useEditDebitCardTransactionImagesState,
  useGoToEditDebitCardTransactionImages,
} from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-routing'

import { translate } from 'utils/translations'
import { MAX_FILES } from 'pages/DebitCardTransactions/DebitCardTransactions.utils'
import { FileWithId } from 'components/File'

interface UseImagePreviewAddProps {
  transactionCombinedImages: FileWithId[]
  transactionUuid: string
}

export const useImagePreviewAdd = ({
  transactionCombinedImages,
  transactionUuid,
}: UseImagePreviewAddProps) => {
  const { goToEditDebitCardTransactionImages } =
    useGoToEditDebitCardTransactionImages()

  const { state } = useEditDebitCardTransactionImagesState()
  const isAddReceiptModalOpen = state !== null
  useDisableNavigation({ shouldDisableNavigation: isAddReceiptModalOpen })

  const allowedFilesLength = MAX_FILES - transactionCombinedImages.length
  const handleOnAdd = useCallback(() => {
    if (allowedFilesLength <= 0) {
      showErrorToast(
        translate('page.transactionDetails.images.uploadLimitWarning')
      )
      return
    }

    goToEditDebitCardTransactionImages({
      stage: 'edit',
      debitCardTransactionUuid: transactionUuid,
      maxFiles: MAX_FILES,
    })
  }, [allowedFilesLength, transactionUuid, goToEditDebitCardTransactionImages])

  return {
    handleOnAdd,
  }
}
