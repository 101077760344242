import { gql } from '@apollo/client'

export const GetConnections = gql`
  query GetConnections($entityUuid: ID!) {
    xeroBankFeeds: getConnectionState(
      entityUuid: $entityUuid
      connectionType: XERO_BANKFEEDS
    ) {
      id
      status
      errorType
      __typename
    }
    xeroPaymentServices: getConnectionState(
      entityUuid: $entityUuid
      connectionType: XERO_PAYMENT_SERVICES
    ) {
      id
      status
      errorType
      __typename
    }
    payAtTable: getConnectionState(
      entityUuid: $entityUuid
      connectionType: HL_POS
    ) {
      id
      status
      errorType
      __typename
    }
    oraclePos: getConnectionState(
      entityUuid: $entityUuid
      connectionType: ORACLE_POS
    ) {
      id
      status
      errorType
      __typename
    }
  }
`
