import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'
import { ConnectionsLists } from 'pages/Settings/Connections/components/ConnectionsLists'
import { HlPosManage } from 'pages/Settings/Connections/HlPos/HlPosManage'
import { OraclePosConnection } from 'pages/Settings/Connections/OraclePos/OraclePosConnection'
import { BankFeedsConnection } from 'pages/Settings/Connections/XeroBankFeeds/BankFeedsConnection'
import { ManageXeroBankFeeds } from 'pages/Settings/Connections/XeroBankFeeds/ManageXeroBankFeeds'
import { OrganisationAccountForm } from 'pages/Settings/Connections/XeroBankFeeds/OrganisationAccountForm'
import { ManageXeroPaymentServicesRoutes } from 'pages/Settings/Connections/XeroPaymentServices/ManageXeroPaymentServices/ManageXeroPaymentServicesRoutes'
import { XeroPaymentServicesConnection } from 'pages/Settings/Connections/XeroPaymentServices/XeroPaymentServicesConnection'
import { XeroPaymentServicesConnectionSetup } from 'pages/Settings/Connections/XeroPaymentServices/XeroPaymentServicesConnectionSetup/XeroPaymentServicesConnectionSetup'
import { LazyLoadComponent } from 'components/LazyLoadComponent/LazyLoadComponent'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

const OraclePosRoutes = lazy(
  () => import('pages/Settings/Connections/OraclePos/OraclePosRoutes')
)

export const SettingsConnectionsRoutes = () => {
  return (
    <Routes>
      <Route
        element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
      >
        <Route element={<ConnectionsLists />}>
          <Route index />
          <Route
            path={ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_BANK_FEEDS.relative}
            element={<BankFeedsConnection />}
          />
          <Route
            path={
              ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_SETUP
                .relative
            }
            element={<OrganisationAccountForm />}
          />
          <Route
            path={
              ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_MANAGE
                .relative
            }
            element={<ManageXeroBankFeeds />}
          />
          <Route
            path={
              ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES
                .relative
            }
            element={<XeroPaymentServicesConnection />}
          />
          <Route
            path={
              ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_SETUP
                .relative
            }
            element={<XeroPaymentServicesConnectionSetup />}
          />
          <Route
            path={`${ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.HL_POS.relative}/*`}
          >
            <Route
              path={ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.HL_POS.MANAGE.relative}
              element={<HlPosManage />}
            />
          </Route>
          <Route
            path={ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.ORACLE_POS.relative}
            element={<OraclePosConnection />}
          />
        </Route>

        <Route
          path={`${
            ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE
              .relative
          }/*`}
          element={<ManageXeroPaymentServicesRoutes />}
        />

        <Route
          path={`${ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.ORACLE_POS.relative}/*`}
        >
          <Route
            path={`${
              ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.ORACLE_POS.MANAGE.relative
            }/*`}
            element={
              <LazyLoadComponent>
                <OraclePosRoutes />
              </LazyLoadComponent>
            }
          />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
