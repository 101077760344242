import { type ReactElement } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { SkeletonAvatar, SkeletonBasic } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import * as styled from '../GroupSelect.styled'

const Rows = Array.from(Array(6).keys()).map((key) => (
  <styled.liGroup key={key} aria-hidden>
    <styled.Row>
      <div style={{ height: 18 }}>
        <SkeletonAvatar width={18} height={18} />
      </div>
      <SkeletonBasic width="100%" height={14} borderRadius="7px" />
    </styled.Row>
  </styled.liGroup>
))

export const GroupSelectLoading = (): ReactElement => {
  const tShared = useTranslations(translationsShared)
  return (
    <>
      <output aria-label={tShared('loading')} />
      {Rows}
    </>
  )
}
