import { useState } from 'react'
import {
  DebitCardTransactionFilterInputV2,
  DebitCardTransactionsExportFormat,
} from '@npco/mp-gql-types'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { renameAndDownloadFile } from '@npco/utils-file'

import { useSubscription } from 'hooks/useSubscription'
import {
  ExportDebitCardTransactions as ExportDebitCardTransactionsResponse,
  ExportDebitCardTransactionsVariables,
} from 'types/gql-types/ExportDebitCardTransactions'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { ExportDebitCardTransactions } from './graphql/ExportDebitCardTransaction'

export interface UseDebitCardTransactionsExportProps {
  filename: string
  format: DebitCardTransactionsExportFormat
  filters: DebitCardTransactionFilterInputV2
}

export const useDebitCardTransactionsExport = ({
  filename,
  format,
  filters,
}: UseDebitCardTransactionsExportProps) => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const data = rvCurrentUserData()

  const id = data?.id ?? ''
  const entityUuid = data?.entityUuid ?? ''

  const { loading, error } = useSubscription<
    ExportDebitCardTransactionsResponse,
    ExportDebitCardTransactionsVariables
  >(ExportDebitCardTransactions, {
    onSubscriptionData: (res) => {
      const response = res.subscriptionData.data?.exportDebitCardTransactions

      if (response?.downloadLink) {
        renameAndDownloadFile({
          link: response?.downloadLink,
          filename,
          format,
        }).catch(() => setHasError(true))

        trackAnalyticsEvent(
          AnalyticsEventNames.DEBIT_CARD_TRANSACTIONS_EXPORT,
          {
            Type: format,
          }
        )

        setHasError(false)
      } else if (response?.error) {
        setHasError(true)
      }

      setHasLoaded(true)
    },
    variables: {
      entityUuid,
      customerUuid: id,
      filter: JSON.stringify(filters),
      format,
    },
  })

  return {
    isLoading: loading,
    hasError: Boolean(error) || hasError,
    hasLoaded,
  }
}
