import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { DeleteEntityTagMutation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/entityTags'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import {
  DeleteEntityTag as DeleteEntityTagResponse,
  DeleteEntityTagVariables,
} from 'types/gql-types/DeleteEntityTag'
import {
  GetEntityTags as GetEntityTagsType,
  GetEntityTagsVariables,
} from 'types/gql-types/GetEntityTags'

export const useDeleteEntityTag = () => {
  const entityUuid = useSelectedEntityUuid()
  const [deleteEntityTag, { loading: isDeletingEntityTag }] = useMutation<
    DeleteEntityTagResponse,
    DeleteEntityTagVariables
  >(DeleteEntityTagMutation)

  return {
    isDeletingEntityTag,
    deleteEntityTag: async (tag: string) => {
      return deleteEntityTag({
        variables: { tag, entityUuid },
        update: (cache, result) => {
          if (result.data?.removeEntityTag) {
            const data = cache.readQuery<
              GetEntityTagsType,
              GetEntityTagsVariables
            >({
              query: GetEntityTags,
              variables: { entityUuid },
            })
            cache.writeQuery<GetEntityTagsType, GetEntityTagsVariables>({
              query: GetEntityTags,
              variables: { entityUuid },
              data: {
                getEntityTags:
                  data?.getEntityTags?.filter(
                    (cachedTag) => cachedTag !== tag
                  ) || [],
              },
            })
          }
        },
      })
    },
  }
}
