import { useCallback, useState } from 'react'
import { Entity } from '@npco/mp-gql-types'

import { UpdateRemoveBusinessModalProps } from './useRemoveBusinessModal.types'

export const useRemoveBusinessModal = () => {
  const [selectedBusiness, setSelectedBusiness] = useState<{
    businessId: Entity['id']
    businessName: Entity['name']
  } | null>(null)

  const openRemoveBusinessModal = useCallback(
    ({ businessId, businessName }: UpdateRemoveBusinessModalProps) => {
      setSelectedBusiness({ businessName, businessId })
    },
    []
  )

  const closeRemoveBusinessModal = useCallback(() => {
    setSelectedBusiness(null)
  }, [])

  const onConfirmRemoveBusiness = useCallback(() => {
    if (!selectedBusiness) {
      return
    }
    // once API is available, add check whether current user is the last user with the entity
    // Once API is available, add check whether business can be removed
    // Finally, add remove business API call
    closeRemoveBusinessModal()
  }, [selectedBusiness, closeRemoveBusinessModal])

  return {
    isRemoveBusinessModalOpen: !!selectedBusiness,
    selectedBusinessName: selectedBusiness?.businessName,
    onConfirmRemoveBusiness,
    openRemoveBusinessModal,
    closeRemoveBusinessModal,
  }
}
