import { ReactNode } from 'react'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useAuth0Provider } from 'auth/useAuth0Provider'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URI,
} from 'const/envs'
import { RedirectAppState } from 'types/auth'

interface Props {
  children: ReactNode
}

export const Auth0ProviderWrapper = ({ children }: Props) => {
  const { onRedirectCallback } = useAuth0Provider()

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN as string}
      clientId={AUTH0_CLIENT_ID as string}
      audience={AUTH0_AUDIENCE}
      redirectUri={AUTH0_REDIRECT_URI}
      onRedirectCallback={(data: AppState) =>
        onRedirectCallback(data as RedirectAppState)
      }
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}
