import { ReactNode } from 'react'
import {
  Animation,
  Box,
  COLOR,
  slideDownAnimationVariants,
  StyledErrorBox,
  SvgIcon,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as MailIcon } from 'assets/svg/mail.svg'
import { validateEmail } from 'utils/formValidation'
import { SignupFields } from 'pages/SignUp/SignUp.types'
import { InputFieldWithoutLabelWrapper } from 'components/InputAdaptiveManagers/InputFieldWithoutLabelWrapper'
import { page } from 'translations'

interface EmailFieldProps {
  error?: ReactNode
  setError: (newValue?: string) => void
  dataTestId?: string
}

export const EmailField = ({
  error,
  setError,
  dataTestId = 'email-field',
}: EmailFieldProps) => {
  const { handleChange, setFieldValue } = useFormikContext<SignupFields>()

  const hasError = error !== undefined
  return (
    <Box mb="0.5rem">
      <InputFieldWithoutLabelWrapper
        aria-label="email"
        icon={MailIcon}
        name="email"
        type="text"
        data-testid={dataTestId}
        placeholder={page.login.emailPlaceholder}
        validate={(value) => validateEmail(value.trim())}
        hasError={hasError}
        onBlur={(e) => {
          setFieldValue('email', e.target.value.trim())
        }}
        renderLeftControls={(renderProps) => (
          <SvgIcon
            width="16"
            height="16"
            withMarginRight
            color={
              hasError || renderProps.hasError
                ? COLOR.RED_1000
                : COLOR.BLUE_1000
            }
          >
            <MailIcon />
          </SvgIcon>
        )}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setError(undefined)
          handleChange(e)
        }}
      />
      <Animation shouldShow={hasError} variants={slideDownAnimationVariants}>
        <StyledErrorBox data-testid="custom-error-field">
          {error}
        </StyledErrorBox>
      </Animation>
    </Box>
  )
}
