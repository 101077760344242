import { Flex, SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const FieldsWrapper = styled(Flex)`
  & > * {
    box-sizing: border-box;
  }
`

export const CardIconWrapper = styled(SvgIcon)`
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  width: 40px;
  height: 40px;
  border-radius: 4px;
`
