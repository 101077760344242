import { useReactiveVar } from '@apollo/client'
import {
  rvSelectedDates,
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

export const useOverviewFilters = () => ({
  dates: useReactiveVar(rvSelectedDates),
  statuses: useReactiveVar(rvSelectedStatuses),
  terminals: useReactiveVar(rvSelectedTerminals),
  types: useReactiveVar(rvSelectedTypes),
  sources: useReactiveVar(rvSelectedSources),
})
