import { useMemo } from 'react'

import { TransactionTotal } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/DashboardPerformance.types'
import {
  getEndOfCurrentDay,
  getEndOfDayOneWeekAgo,
  getStartOfCurrentDay,
  getStartOfDayOneWeekAgo,
  getThisTimeOneWeekAgo,
} from 'pages/Dashboard/utils/timeUtils'

import { useGetTotalSalesBetween } from './useGetTotalSalesBetween'

interface UseTodaySitePerformanceOptions {
  salesTotals: TransactionTotal[]
}

export const useTodayPerformance = ({
  salesTotals,
}: UseTodaySitePerformanceOptions) => {
  const getTotalSalesBetween = useGetTotalSalesBetween(salesTotals)

  const startOfDayOneWeekAgo = useMemo(() => getStartOfDayOneWeekAgo(), [])
  const thisTimeOneWeekAgo = useMemo(() => getThisTimeOneWeekAgo(), [])

  const salesThisTimeOneWeekAgo = useMemo(
    () => getTotalSalesBetween(startOfDayOneWeekAgo, thisTimeOneWeekAgo),
    [getTotalSalesBetween, startOfDayOneWeekAgo, thisTimeOneWeekAgo]
  )

  const endOfDayOneWeekAgo = useMemo(() => getEndOfDayOneWeekAgo(), [])

  const totalSalesOneWeekAgo = useMemo(
    () => getTotalSalesBetween(startOfDayOneWeekAgo, endOfDayOneWeekAgo),
    [getTotalSalesBetween, startOfDayOneWeekAgo, endOfDayOneWeekAgo]
  )

  const startOfCurrentDay = useMemo(() => getStartOfCurrentDay(), [])
  const endOfCurrentDay = useMemo(() => getEndOfCurrentDay(), [])

  const todaySales = useMemo(
    () => getTotalSalesBetween(startOfCurrentDay, endOfCurrentDay),
    [getTotalSalesBetween, startOfCurrentDay, endOfCurrentDay]
  )

  return {
    salesThisTimeOneWeekAgo,
    totalSalesOneWeekAgo,
    todaySales,
  }
}
