import { useMemo } from 'react'
import { ErrorMessageForm } from '@npco/zeller-design-system'
import { SelectCompact, useSelectField } from 'design-system/Components/Select'

import australiaStates from 'const/australia-states.json'
import { CustomValidator } from 'types/common'
import { form } from 'translations'

import { StyledHiddenInput } from './State.styled'
import { useResetInvalidStateValue } from './useResetInvalidStateValue'

interface StateProps {
  customName?: string
  isDisabled?: boolean
  isRequired?: boolean
  placeholder?: string
  validator?: CustomValidator<string>
}

interface Item {
  label: string
  value: string
  fullName: string
}

export const State = ({
  customName,
  isDisabled,
  isRequired,
  placeholder,
  validator,
}: StateProps) => {
  const states = useMemo<Item[]>(
    () =>
      australiaStates.map(({ value, name }) => ({
        label: value,
        value,
        fullName: name,
      })),
    []
  )
  const fieldName = customName || 'state'
  const { selectedItem, hasError, onChange, errorMessage, setTouched } =
    useSelectField({
      name: fieldName,
      validate: validator,
      items: states,
    })

  const prefillStateFromBrowserAutoComplete = (value: string) => {
    if (!value) {
      return
    }

    const foundState = states.find((state) => {
      const upperCasedValue = value.toUpperCase()

      return (
        state.value.toUpperCase() === upperCasedValue ||
        state.fullName.toUpperCase() === upperCasedValue
      )
    })

    if (foundState) {
      onChange(foundState)
    }
  }

  useResetInvalidStateValue(fieldName)

  return (
    <>
      <StyledHiddenInput
        tabIndex={-1}
        data-testid="browser-prefill-input"
        aria-label={form.shared.state.label}
        onChange={(event) => {
          prefillStateFromBrowserAutoComplete(event.target.value)
        }}
      />
      <SelectCompact<Item>
        label={form.shared.state.label}
        isSearchableByKeypress
        mobileLabel={form.shared.state.label}
        placeholder={placeholder ?? form.shared.state.placeholder}
        maxHeight="13.875rem"
        onChange={onChange}
        items={states}
        isDisabled={isDisabled}
        hasError={hasError}
        selectedItem={selectedItem}
        onClose={setTouched}
        isRequired={isRequired}
      />
      {hasError && (
        <ErrorMessageForm hasError={hasError} errorMessage={errorMessage} />
      )}
    </>
  )
}
