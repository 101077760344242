import { rvSalesErrorTracker } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/dashboard'

import { DashboardErrorPage } from '../../Fallbacks'
import { DashboardSection } from './DashboardSales.styled'
import { useTransactionTotals } from './hooks/useTransactionTotals'
import { SalesContent } from './SalesContent'

export const DashboardSales = () => {
  const {
    transactionTotals,
    isLoadingTransactionTotals,
    error,
    refetchTransactionTotals,
  } = useTransactionTotals()

  if (error) {
    return (
      <DashboardSection>
        <DashboardErrorPage
          retry={refetchTransactionTotals}
          rvRetry={rvSalesErrorTracker}
        />
      </DashboardSection>
    )
  }

  return (
    <DashboardSection>
      <SalesContent
        salesTotals={transactionTotals}
        isLoading={isLoadingTransactionTotals}
      />
    </DashboardSection>
  )
}
