import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { ExtendedCellContext, useExtendedColumnConfig } from '@npco/ui-table'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  DotsHorizontalIcon,
  QuestionMarkIcon,
  TableCellAvatarText,
  TableCellSize,
} from '@npco/zeller-design-system'

import { getCategoryIcon } from '../../../../utils/getCategoryIcon/getCategoryIcon'
import { useGetCategoryName } from '../../../hooks/useGetCategoryName'
import { NetAmountWithOtherCategory } from '../../../TransactionsSummary.types'
import { CategoryCellCashFlowCategoryNetAmountFragment } from './CategoryCell.generated'
import { translations } from './CategoryCell.i18n'

export const CategoryCell = <T,>({
  hoveredRowIndex,
  cell,
  table,
  getValue,
  row,
  column,
}: ExtendedCellContext<
  T,
  NetAmountWithOtherCategory<CategoryCellCashFlowCategoryNetAmountFragment>
>) => {
  const getCategoryName = useGetCategoryName()
  const t = useTranslations(translations)
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<
        NetAmountWithOtherCategory<CategoryCellCashFlowCategoryNetAmountFragment>
      >()

  const descriptionText = useMemo(() => {
    if (data?.category === 'other') {
      return `${data.otherCategoryCount} ${
        data.otherCategoryCount > 1
          ? t('categoryPlural')
          : t('categorySingular')
      }`
    }

    const subcategoryCount = data?.subcategoryCount ?? 0
    if (subcategoryCount === 0) {
      return ''
    }

    return `${subcategoryCount} ${
      subcategoryCount > 1 ? t('subcategoryPlural') : t('subcategorySingular')
    }`
  }, [data, t])

  const categoryText = useMemo(
    () => (data ? getCategoryName(data.category) : ''),
    [data, getCategoryName]
  )

  const CategoryIcon = useMemo(() => {
    if (!data?.category) {
      return QuestionMarkIcon
    }
    if (data.category === 'other') {
      return DotsHorizontalIcon
    }
    return getCategoryIcon(data?.category)
  }, [data?.category])

  const avatarColor = useMemo(() => {
    return row.index === hoveredRowIndex
      ? {
          bgColor: COLOR.BLUE_700,
          iconColor: COLOR.WHITE,
        }
      : {
          bgColor: COLOR.GREY_90,
          iconColor: COLOR.GREY_550,
        }
  }, [hoveredRowIndex, row.index])

  return (
    <TableCellAvatarText
      key={cell.id}
      dataTestId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      avatarProps={{
        text: data?.category ?? '',
        bgColor: avatarColor.bgColor,
        svgBackground: avatarColor.bgColor,
        contentHeight: '12px',
        contentWidth: '12px',
        userDefinedContent: CategoryIcon && (
          <CategoryIcon color={avatarColor.iconColor} withNoTransition />
        ),
      }}
      byline={descriptionText}
      text={categoryText}
      size={TableCellSize.SMALL}
      skeletonProps={{ width: skeletonLoaderWidth }}
    />
  )
}

CategoryCell.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment CategoryCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      category
      subcategoryCount
    }
  `,
}
