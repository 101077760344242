import { makeVar } from '@apollo/client'

import { Transaction, TransactionInList } from 'types/transactions'

export interface TransactionCardholderContactMap {
  [cardholderUuid: string]: string | null
}

export const rvTransactionCardholderContactMap =
  makeVar<TransactionCardholderContactMap>({})

interface TransactionsNotes {
  [transactionId: string]: string | null
}

export const rvTransactionsNotes = makeVar<TransactionsNotes>({})
export const rvSelectedTransaction = makeVar<
  Transaction | TransactionInList | null
>(null)
