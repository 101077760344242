import { ButtonGhost } from '@npco/zeller-design-system'
import {
  CreateAccountStage,
  useGoToCreateAccountStage,
} from 'features/CreateAccount'

import { ReactComponent as IconTransaction } from 'assets/svg/no-transactions.svg'
import { page } from 'translations'

import { NoInitialResults } from '../NoInitialResults'
import { PlaceholderSize } from '../Placeholders.types'
import { getButtonSizeBasedOnVariant } from '../Placeholders.utils'

interface Props {
  variant?: PlaceholderSize
}

export const NoAccounts = ({ variant = PlaceholderSize.Large }: Props) => {
  const { goToCreateAccountStage } = useGoToCreateAccountStage()

  return (
    <NoInitialResults
      icon={<IconTransaction />}
      title={page.accounts.emptyState.title}
      description={page.accounts.emptyState.copy}
      variant={variant}
    >
      <ButtonGhost
        size={getButtonSizeBasedOnVariant(variant)}
        onClick={() =>
          goToCreateAccountStage(CreateAccountStage.AccountNameStage)
        }
      >
        {page.accounts.emptyState.cta}
      </ButtonGhost>
    </NoInitialResults>
  )
}
