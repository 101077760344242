import { Box, ButtonLink, Flex } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { translate } from 'utils/translations'
import { ModalUploadLogo } from 'components/ModalUploadLogo'

import { RemoveLogoModal } from './RemoveLogoModal'

interface Props {
  logo?: string
  handleRemoveLogo: () => void
  setTemporaryLogo: React.Dispatch<React.SetStateAction<string>>
}

export const LogoActions = ({
  logo,
  handleRemoveLogo,
  setTemporaryLogo,
}: Props) => {
  const {
    isModalOpen: isModalRemoveOpen,
    openModal: openModalRemove,
    closeModal: closeModalRemove,
  } = useModalState()
  const {
    isModalOpen: isModalUploadOpen,
    openModal: openModalUpload,
    closeModal: closeModalUpload,
  } = useModalState()

  return (
    <Flex mb="1rem">
      <Box pl={2}>
        <ButtonLink onClick={openModalRemove}>
          {translate('page.settings.receipt.remove')}
        </ButtonLink>
      </Box>
      <Box pl={[2, '1.5rem']}>
        <ButtonLink onClick={openModalUpload}>
          {translate('component.modal.uploadImage.uploadNewImage')}
        </ButtonLink>
      </Box>

      <RemoveLogoModal
        isModalOpen={isModalRemoveOpen}
        closeModal={closeModalRemove}
        handleRemoveLogo={handleRemoveLogo}
      />

      <ModalBasic
        title={translate('component.modal.uploadImage.uploadNewImage')}
        isOpen={isModalUploadOpen}
        onCancel={closeModalUpload}
        hasCloseButton
      >
        <ModalUploadLogo
          logo={logo}
          setTemporaryLogo={setTemporaryLogo}
          closeModal={closeModalUpload}
        />
      </ModalBasic>
    </Flex>
  )
}
