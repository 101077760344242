import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const OriginalPrice = styled.span`
  position: relative;
  font-size: 23px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.GREY_700};
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    font-size: 30px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: red;
    transform: rotate(-10deg);
    transform-origin: center center;
    border-radius: 2px;
  }
`
