import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { CogwheelIcon } from '@npco/zeller-design-system'

import { useXeroBankFeedsError } from 'hooks/useXeroErrors/useXeroBankFeedsError'
import { useXeroPaymentServicesError } from 'hooks/useXeroErrors/useXeroPaymentServicesError'
import { component } from 'translations'

import { ItemToggle } from '../components/ItemToggle/ItemToggle'
import { useSettingsSubItems } from '../hooks/useSettingsSubItems'

export const SettingsItem = () => {
  const { userRole } = useLoggedInUser()
  const isAdmin = userRole === CustomerRole.ADMIN
  const { hasErrorInBankFeeds } = useXeroBankFeedsError()
  const { hasErrorInPaymentService } = useXeroPaymentServicesError()
  const numberBadgeCount = isAdmin
    ? Number(hasErrorInBankFeeds) + Number(hasErrorInPaymentService)
    : undefined
  const settingsSubItems = useSettingsSubItems({
    connectionErrorsCount: numberBadgeCount,
  })

  return (
    <ItemToggle
      icon={<CogwheelIcon />}
      subItems={settingsSubItems}
      name="Settings"
      numberBadgeCount={numberBadgeCount}
    >
      {component.sidebar.items.settings}
    </ItemToggle>
  )
}
