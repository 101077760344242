import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { capitalise } from 'utils/common'

import { getCustomPageName } from './pages'
import { useAnalyticsContext } from './useAnalyticsContext'
import { useAnalyticsLogger } from './useAnalyticsLogger'

interface UseDrawerTabEventsProps {
  drawerName: string
}
export const useDrawerTabEvents = ({ drawerName }: UseDrawerTabEventsProps) => {
  const location = useLocation()
  const { locationName } = useAnalyticsContext()
  const { logAnalyticsPage } = useAnalyticsLogger()

  const onDrawerOpen = useCallback(
    (tabName: string) => {
      const expectedPrefix = getCustomPageName(location.pathname) ?? ''
      if (!locationName.current.startsWith(expectedPrefix)) {
        locationName.current = expectedPrefix
      }
      locationName.current = [
        locationName.current,
        capitalise(drawerName),
        capitalise(tabName),
      ].join('_')
      logAnalyticsPage({ name: locationName.current })
    },

    [drawerName, location.pathname, locationName, logAnalyticsPage]
  )

  const onDrawerClose = useCallback(() => {
    locationName.current = locationName.current
      .split('_')
      .slice(0, -2)
      .join('_')
    logAnalyticsPage({ name: locationName.current })
  }, [logAnalyticsPage, locationName])

  const onDrawerTabSwitch = useCallback(
    (tabName: string) => {
      const drawerWithoutTabName = locationName.current
        .split('_')
        .slice(0, -1)
        .join('_')
      locationName.current = `${drawerWithoutTabName}_${capitalise(tabName)}`
      logAnalyticsPage({ name: locationName.current })
    },
    [logAnalyticsPage, locationName]
  )

  return {
    onDrawerOpen,
    onDrawerClose,
    onDrawerTabSwitch,
  }
}
