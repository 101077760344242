import { createContext } from 'react'

import {
  AccountFiltersValues,
  DEFAULT_CONFIG,
  DEFAULT_FILTERS_VALUES,
  useAccountFiltersValues,
} from '../hooks/useAccountFiltersValues/useAccountFiltersValues'
import { FilterConfig } from '../hooks/useAccountFiltersValues/useAccountFiltersValues.types'

interface Props {
  initialConfig?: Partial<FilterConfig>
  children: React.ReactNode
}

export const DEFAULT_FILTER_CONTEXT = {
  ...DEFAULT_FILTERS_VALUES,
  areFiltersInDefaultState: true,
  resetAllFilters: () => undefined,
  setDate: () => undefined,
  resetDate: () => undefined,
  setStatus: () => undefined,
  resetStatus: () => undefined,
  setTransactionType: () => undefined,
  resetTransactionType: () => undefined,
  setReceipt: () => undefined,
  resetReceipt: () => undefined,
  setAmount: () => undefined,
  resetAmount: () => undefined,
  setDescription: () => undefined,
  resetDescription: () => undefined,
  setTags: () => undefined,
  resetTags: () => undefined,
  setContact: () => undefined,
  resetContact: () => undefined,
  setCategory: () => undefined,
  resetCategory: () => undefined,
  setAccountingCategory: () => undefined,
  resetAccountingCategory: () => undefined,
  visibleFilterCategories: [],
  setVisibleFilterCategories: () => undefined,
  config: DEFAULT_CONFIG,
}

export const AccountFilterContext = createContext<AccountFiltersValues>(
  DEFAULT_FILTER_CONTEXT
)

export const AccountFilterValuesWrapper = ({
  initialConfig,
  children,
}: Props) => {
  const context = useAccountFiltersValues({
    initialConfig,
  })

  return (
    <AccountFilterContext.Provider value={context}>
      {children}
    </AccountFilterContext.Provider>
  )
}
