import { useCallback } from 'react'
import { MultiSelectItem } from 'design-system/Components/MultiSelect/MultiSelectItem/MultiSelectItem'

import * as styled from './MultiSelect.styled'
import { FilterPopper } from './shared/FilterPopper'

export type Items<T extends string> = {
  label: string
  value: T
  id: string
}[]

export type PillFloatingMultiselectProps<T extends string> = {
  onSelectItem: (items: T[]) => void
  selectedItems?: T[]
  disabledItems?: T[]
  items: Items<T>
  defaultLabel: string
}

export const MultiSelect = <T extends string>({
  defaultLabel,
  disabledItems,
  items,
  onSelectItem,
  selectedItems,
}: PillFloatingMultiselectProps<T>) => {
  const handleOnClick = useCallback(
    ({ value, isChecked }: { value: T; isChecked: boolean }) => {
      if (isChecked && selectedItems) {
        return () =>
          onSelectItem(
            selectedItems?.filter((selectedItem) => selectedItem !== value)
          )
      }

      return () => onSelectItem([...(selectedItems ?? []), value])
    },
    [onSelectItem, selectedItems]
  )

  const handleReset = useCallback(() => {
    onSelectItem([])
  }, [onSelectItem])

  const pillLabel =
    (selectedItems?.length === 1 &&
      items.find((item) => item.value === selectedItems[0])?.label) ||
    defaultLabel

  return (
    <FilterPopper
      count={selectedItems?.length}
      active={!!selectedItems?.length}
      label={pillLabel}
      onClear={selectedItems?.length ? handleReset : undefined}
    >
      {() => (
        <styled.List>
          {items?.map(({ id, label, value }) => {
            const isChecked = !!selectedItems?.includes(value)
            return (
              <MultiSelectItem
                tabIndex={0}
                aria-label={label}
                aria-selected={isChecked}
                id={id}
                isChecked={isChecked}
                item={{ label, value }}
                key={value}
                label={label}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOnClick({ value, isChecked })()
                    e.stopPropagation()
                  }
                }}
                onClick={handleOnClick({ value, isChecked })}
                isDisabled={disabledItems?.includes(value)}
              />
            )
          })}
        </styled.List>
      )}
    </FilterPopper>
  )
}
