import { useState } from 'react'
import { gql } from '@apollo/client'
import { useDisableNavigation } from '@npco/ui-drawer-navigation'
import { useModalState } from '@npco/zeller-design-system'
import { useEditDebitCardTransactionImagesState } from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-routing'

import { translate } from 'utils/translations'
import { ImagePreviewModal } from 'components/ImagePreviewModal/ImagePreviewModal'
import { Images as ImagesDisplay } from 'components/Images/Images'

import {
  ImagesDebitCardAccountTransactionFragment,
  ImagesDebitCardTransactionV2Fragment,
} from './Images.generated'

interface ImagesProps {
  transaction:
    | ImagesDebitCardTransactionV2Fragment
    | ImagesDebitCardAccountTransactionFragment
}

export const Images = ({ transaction }: ImagesProps) => {
  const [id, setId] = useState('first-image')
  const {
    isModalOpen: isImagePreviewOpen,
    openModal: openImagePreview,
    closeModal: closeImagePreview,
  } = useModalState()

  const { state } = useEditDebitCardTransactionImagesState()

  const isAddReceiptModalOpen = state !== null

  useDisableNavigation({
    shouldDisableNavigation: isAddReceiptModalOpen || isImagePreviewOpen,
  })

  const handleClick = (id: string) => {
    openImagePreview()
    setId(id)
  }

  return (
    <>
      <ImagesDisplay
        title={translate('page.transactionDetails.images.title')}
        placeholder={translate('page.transactionDetails.images.placeholder')}
        onClickThumbnail={handleClick}
        transactionUuid={transaction.id}
      />
      {isImagePreviewOpen && (
        <ImagePreviewModal
          transactionUuid={transaction.id}
          initialImageId={id}
          onClose={closeImagePreview}
        />
      )}
    </>
  )
}

Images.fragments = {
  DebitCardTransactionV2: gql`
    fragment ImagesDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
    }
  `,
  DebitCardAccountTransaction: gql`
    fragment ImagesDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
    }
  `,
}
