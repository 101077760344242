import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { SubContactCoreFieldsDoc } from './SubContactCoreFields.generated'

export type ContactCoreFieldsFragment = {
  __typename: 'Contact'
  abn: string | null
  acn: string | null
  businessName: string | null
  contactType: Types.ContactType
  firstName: string | null
  id: string
  jobTitle: string | null
  lastName: string | null
  notes: string | null
  isSelf: boolean | null
  phone: string | null
  tags: Array<string | null> | null
  website: string | null
  location: string | null
  locationAccuracy: number | null
  category: Types.EntityCategories | null
  subcategory: string | null
  subcategoryUuid: string | null
  additionalEmails: Array<{
    __typename?: 'ContactEmail'
    email: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  }> | null
  additionalPhones: Array<{
    __typename?: 'ContactPhone'
    phone: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  }> | null
  address: {
    __typename?: 'ContactAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street: string | null
    suburb: string | null
  } | null
  contacts: Array<{
    __typename: 'Contact'
    abn: string | null
    acn: string | null
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    id: string
    jobTitle: string | null
    lastName: string | null
    phone: string | null
    website: string | null
    location: string | null
    locationAccuracy: number | null
    category: Types.EntityCategories | null
    subcategory: string | null
    subcategoryUuid: string | null
    additionalEmails: Array<{
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    additionalPhones: Array<{
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    address: {
      __typename?: 'ContactAddress'
      country: string | null
      postcode: string | null
      state: string | null
      street: string | null
      suburb: string | null
    } | null
    email: {
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    phoneV2: {
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
  } | null> | null
  email: {
    __typename?: 'ContactEmail'
    email: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  } | null
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  paymentInstruments: Array<{
    __typename?: 'PaymentInstrument'
    id: string
    status: Types.PaymentInstrumentStatus
    bankAccountDetails: {
      __typename?: 'BankAccountDetails'
      account: string | null
      bsb: string | null
      name: string | null
    } | null
    details:
      | { __typename?: 'BankAccountDetails' }
      | { __typename?: 'BpayDetails' }
      | { __typename?: 'NppDetails' }
      | {
          __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
          billerName: string
          billerCode: string
          nickname: string | null
        }
      | {
          __typename: 'PaymentInstrumentBpayStaticCrnDetails'
          billerName: string
          billerCode: string
          nickname: string | null
          crn: string
        }
  }> | null
  phoneV2: {
    __typename?: 'ContactPhone'
    phone: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  } | null
}

export const ContactCoreFieldsDoc = gql`
  fragment ContactCoreFields on Contact {
    __typename
    abn
    acn
    additionalEmails {
      email
      label {
        id
        isEditable
        labelText
        type
      }
    }
    additionalPhones {
      phone
      label {
        id
        isEditable
        labelText
        type
      }
    }
    address {
      country
      postcode
      state
      street
      suburb
    }
    businessName
    contacts {
      ...SubContactCoreFields
    }
    contactType
    email {
      email
      label {
        id
        isEditable
        labelText
        type
      }
    }
    firstName
    icon {
      colour
      images {
        size
        url
      }
      letter
    }
    id
    jobTitle
    lastName
    notes
    isSelf
    paymentInstruments {
      id
      bankAccountDetails {
        account
        bsb
        name
      }
      details {
        ... on PaymentInstrumentBpayStaticCrnDetails {
          __typename
          billerName
          billerCode
          nickname
          crn
        }
        ... on PaymentInstrumentBpayDynamicCrnDetails {
          __typename
          billerName
          billerCode
          nickname
        }
      }
      status
    }
    phone
    phoneV2 {
      phone
      label {
        id
        isEditable
        labelText
        type
      }
    }
    tags
    website
    location
    locationAccuracy
    category
    subcategory
    subcategoryUuid
  }
  ${SubContactCoreFieldsDoc}
` as unknown as TypedDocumentNode<ContactCoreFieldsFragment, undefined>
