import React, { ReactNode } from 'react'
import {
  DebitCardAccountTransactionStatusEnum,
  DebitCardTransactionStatusV2,
} from '@npco/mp-gql-types'

import {
  StyledListElement,
  StyledListKey,
  StyledListValue,
  StyledStatus,
} from './DetailsListItem.styled'

interface DetailsListItemProps {
  label?: ReactNode
  value?: ReactNode
  status?: DebitCardTransactionStatusV2 | DebitCardAccountTransactionStatusEnum
  isCapitalised?: boolean
}

export const DetailsListItem = ({
  label,
  value,
  status,
  isCapitalised = true,
}: DetailsListItemProps) => {
  return (
    <StyledListElement data-testid={label}>
      {label && <StyledListKey>{label}</StyledListKey>}
      {value && (
        <StyledListValue isCapitalised={isCapitalised} enableEllipsis={false}>
          {value}
        </StyledListValue>
      )}
      {status && <StyledStatus status={status} isWithoutIcon />}
    </StyledListElement>
  )
}
