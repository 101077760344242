import { Flex } from '@npco/zeller-design-system'

import { KEY_CODE } from 'const/keyboard'

import { RightControlProps } from './AccountTileList.type'
import { StyledItem, StyledItemName } from './DepositAccountListItem.styled'

const ACCESSIBLE_TAB_INX = 0

interface Props {
  name: string
  graphicIndicator?: React.ReactNode
  isActive: boolean
  isDisabled?: boolean
  onSelect: () => void
  description?: React.ReactNode
  renderRightControl?: (rightControlProps: RightControlProps) => React.ReactNode
}

export const DepositAccountListItem = ({
  name,
  isActive,
  onSelect,
  graphicIndicator,
  description,
  isDisabled,
  renderRightControl,
}: Props) => {
  const handleClick = () => {
    onSelect()
  }

  return (
    <StyledItem
      data-testid="account-item"
      aria-label={name}
      isActive={isActive}
      onClick={handleClick}
      onKeyDown={(event) => {
        if (event.key === KEY_CODE.SPACE) {
          handleClick()
        }
      }}
      role="button"
      tabIndex={ACCESSIBLE_TAB_INX}
    >
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <Flex alignItems="center" width="100%">
          <Flex mr="16px">{graphicIndicator}</Flex>
          <Flex flexDirection="column" flex={1} style={{ minWidth: '0' }}>
            <StyledItemName data-testid="deposit-account-name">
              {name}
            </StyledItemName>
            {description}
          </Flex>
          {renderRightControl?.({ isActive, isDisabled })}
        </Flex>
      </Flex>
    </StyledItem>
  )
}
