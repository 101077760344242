import { SelectTriggerAdaptive } from '../../../Select/SelectTriggerAdaptive'
import {
  MultiSelectBasicProps,
  MultiSelectItemBasic,
  MultiSelectItemRenderProps,
} from '../../MultiSelect.types'
import { MultiSelectBasic } from '../../MultiSelectBasic/MultiSelectBasic'
import {
  MultiSelectItem,
  MultiSelectItemProps,
} from '../../MultiSelectItem/MultiSelectItem'

interface MultiSelectAdaptiveProps<ItemType extends MultiSelectItemBasic>
  extends Omit<
    MultiSelectBasicProps<ItemType>,
    'renderItem' | 'renderTrigger'
  > {
  label?: string
  placeholder?: string
  placeholderIcon?: React.ReactElement
  triggerContent: string | null
  modifyItemProps?: (
    props: MultiSelectItemRenderProps<ItemType>
  ) => MultiSelectItemProps<ItemType>
}

const MultiSelectAdaptive = <ItemType extends MultiSelectItemBasic>({
  mobileLabel,
  label,
  placeholder,
  placeholderIcon,
  triggerContent,
  modifyItemProps,
  ...rest
}: MultiSelectAdaptiveProps<ItemType>) => {
  return (
    <MultiSelectBasic
      mobileLabel={mobileLabel}
      renderItem={(renderProps) => (
        <MultiSelectItem
          {...(modifyItemProps ? modifyItemProps(renderProps) : renderProps)}
        />
      )}
      renderTrigger={(renderProps) => (
        <SelectTriggerAdaptive
          {...renderProps}
          label={label}
          placeholder={placeholder}
          placeholderIcon={placeholderIcon}
          selectedItem={
            triggerContent ? { label: triggerContent, value: '' } : null
          }
        />
      )}
      {...rest}
    />
  )
}

export {
  MultiSelectAdaptive as MultiSelectAdaptiveDeprecated,
  type MultiSelectAdaptiveProps as MultiSelectAdaptiveDeprecatedProps,
}
