import { useCallback, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { ErrorLogger } from '@npco/utils-error-logger'
import { rvTransactionCardholderContactMap } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'
import { transformCreateContactToGetContact } from 'features/Contacts/Contacts.utils'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'

import { useLinkContactWithCardholder } from 'hooks/useLinkContactWithCardholder/useLinkContactWithCardholder'
import { usePrevious } from 'hooks/usePrevious'
import { useUnlinkContactWithCardholder } from 'hooks/useUnlinkContactWithCardholder/useUnlinkContactWithCardholder'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { getLinkedContactUuid } from 'components/TransactionContact/TransactionContact.utils'

interface UseTransactionContactProps {
  cardholderUuid: string | null
  contactUuid: string | null
  onLink?: () => void
  onUnlink?: (unlinkedCardHolderUuid: string) => void
}

export const useTransactionContact = ({
  cardholderUuid,
  contactUuid,
  onLink,
  onUnlink,
}: UseTransactionContactProps) => {
  const cardholderContactMap = useReactiveVar(rvTransactionCardholderContactMap)

  const linkedContactUuid = getLinkedContactUuid(
    cardholderContactMap,
    cardholderUuid,
    contactUuid
  )

  // NOTE: when a transaction is selected we attempt to prefetch further up the
  // stack, if the data is already cached we'll preset the contact state below
  // and won't fetch
  const {
    data: cachedContact,
    error,
    loading: isLoading,
    refetch,
  } = useGetContact({
    id: linkedContactUuid,
  })

  const [contact, setContact] = useState<ContactCoreFieldsFragment | null>(
    cachedContact
  )

  // NOTE: when linking or unlinking error the transaction sidebar will show
  // error so don't raise error toast
  const {
    error: linkContactError,
    isLinkingContactWithCardholder,
    linkContactWithCardholder,
  } = useLinkContactWithCardholder({ suppressErrorToast: true })

  const {
    error: unlinkContactError,
    isUnlinkingContactWithCardholder,
    unlinkContactWithCardholder,
  } = useUnlinkContactWithCardholder({ suppressErrorToast: true })

  const previousCachedContact = usePrevious<ContactCoreFieldsFragment | null>(
    cachedContact
  )

  // NOTE: should the prefetch request not occur or fail and we send a network
  // request to get the contact we must set the contact manually
  useEffect(() => {
    // NOTE: only set the contact if the previous contact was falsey
    if (!previousCachedContact && cachedContact) {
      setContact(cachedContact)
    }
  }, [cachedContact, previousCachedContact, setContact])

  const getContact = useCallback(
    async (uuid: string) => {
      try {
        const refetchedContact = await refetch(uuid)

        if (refetchedContact) {
          setContact(refetchedContact)
        }
      } catch (err) {
        ErrorLogger.report('[Payment] Re-Fetch contact', err)
      }
    },
    [refetch]
  )

  const handleLinkContact = useCallback(
    async ({
      cardholderOrSenderUuid: cardholderId,
      contactUuid: contactId,
    }: {
      cardholderOrSenderUuid: string
      contactUuid: string
    }) => {
      // NOTE: thrown error is handled by hook
      const result = await linkContactWithCardholder(cardholderId, contactId)

      if (result) {
        rvTransactionCardholderContactMap({
          ...rvTransactionCardholderContactMap(),
          [cardholderId]: contactId,
        })

        getContact(contactId)
        onLink?.()
      }
    },
    [getContact, linkContactWithCardholder, onLink]
  )

  const handleOnContactCreateAndLink = useCallback(
    async (cardholderId: string, newContact: CreateContact) => {
      // NOTE: thrown error is handled by hook
      const result = await linkContactWithCardholder(
        cardholderId,
        newContact.id
      )

      if (result) {
        rvTransactionCardholderContactMap({
          ...rvTransactionCardholderContactMap(),
          [cardholderId]: newContact.id,
        })

        const transformedContact =
          transformCreateContactToGetContact(newContact)

        setContact(transformedContact)
      }
    },
    [linkContactWithCardholder]
  )

  const handleUnlinkContact = useCallback(
    async ({
      cardholderUuid: cardholderId,
      contactUuid: contactId,
    }: {
      cardholderUuid: string
      contactUuid: string
    }) => {
      // NOTE: thrown error is handled by hook
      const result = await unlinkContactWithCardholder(cardholderId, contactId)

      if (result) {
        rvTransactionCardholderContactMap({
          ...rvTransactionCardholderContactMap(),
          [cardholderId]: null,
        })

        setContact(null)
        onUnlink?.(cardholderId)
      }
    },
    [onUnlink, unlinkContactWithCardholder]
  )

  return {
    contact,
    getContact,
    handleLinkContact,
    handleOnContactCreateAndLink,
    handleUnlinkContact,
    hasGetContactError: Boolean(error),
    hasLinkContactError: Boolean(linkContactError),
    hasUnlinkContactError: Boolean(unlinkContactError),
    isLinkingContactWithCardholder,
    isLoadingContact: isLoading,
    isUnlinkingContactWithCardholder,
    linkedContactUuid,
  }
}
