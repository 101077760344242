import { Flex } from '@npco/zeller-design-system'

import { PlaceholderAlignment } from '../Placeholders.types'
import { StyledWrapper } from '../SharedElements.styled'

interface Props {
  children?: React.ReactNode
  className?: string
  alignment?: PlaceholderAlignment
  ['data-testid']?: string
}

export const PlaceholderPage = ({
  children,
  className,
  alignment = PlaceholderAlignment.Center,
  'data-testid': dataTestId = 'no-initial-results',
}: Props) => {
  return (
    <StyledWrapper
      justifyContent={['space-between', 'center']}
      data-testid={dataTestId}
      className={className}
    >
      <Flex flexDirection="column" alignItems={alignment}>
        {children}
      </Flex>
    </StyledWrapper>
  )
}
