import { PdfTemplateType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  Heading,
  InputAdaptiveTextArea,
  RadioGroupFormik,
  RadioListCard,
  showErrorToast,
} from '@npco/zeller-design-system'
import { Field } from 'formik'

import { SettingsLeavingRoutePromptGuard } from 'components/RouteLeavingPromptGuard'

import { FormButtons } from '../../FormButtons'
import { UseSettingsCustomisationReturnType } from '../hooks/useSettingsCustomisation'
import { useSettingsCustomisationFormDebounce } from '../hooks/useSettingsCustomisationFormDebounce'
import { useSettingsCustomisationFormHandlers } from '../hooks/useSettingsCustomisationFormHandlers'
import { INVOICE_MESSAGING_MAX_LENGTH } from '../SettingsCustomisation.constants'
import { translations } from '../SettingsCustomisation.i18n'
import styles from '../SettingsCustomisation.module.css'
import * as styled from '../SettingsCustomisation.styled'
import { INVOICE_CUSTOMISATION_FORM_FIELDS } from '../SettingsCustomisation.types'
import { TileSelectorList } from '../TileSelectorList/TileSelectorList'

export const SettingsCustomisationForm = ({
  isLoadingPreview,
  openMobilePreview,
  setPreviewValues,
  setWillLoadPreview,
}: {
  isLoadingPreview: boolean
  openMobilePreview: () => void
  setPreviewValues: UseSettingsCustomisationReturnType['setPreviewValues']
  setWillLoadPreview: (willLoad: boolean) => void
}) => {
  const t = useTranslations(translations)

  const { resetForm, submitForm, values } =
    useSettingsCustomisationFormDebounce({
      setPreviewValues,
      setWillLoadPreview,
      onError: () => showErrorToast(t('errorNotification')),
      onInvalidLogoSave: () =>
        showErrorToast(t('uploadInProgressErrorNotification')),
      onInvalidFormSave: () => showErrorToast(t('formSaveErrorNotification')),
    })

  const {
    handleAddAccentColour,
    handleAddLogo,
    handleRemoveAccentColour,
    handleRemoveLogo,
    handleSelectAccentColour,
    handleSelectLogo,
    handleTermsAndConditionsLimit,
  } = useSettingsCustomisationFormHandlers({
    termsAndConditionsErrorMessage: t('termsAndConditionsError'),
  })

  return (
    <>
      <SettingsLeavingRoutePromptGuard
        resetForm={resetForm}
        submitForm={submitForm}
      />
      <styled.FormWrapper>
        <styled.FormSection>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            height="30px"
          >
            <Heading.H3>{t('headingAppearance')}</Heading.H3>
            <div className={styles.PreviewButton}>
              <styled.PreviewButton
                isLoading={isLoadingPreview}
                onClick={openMobilePreview}
              >
                {t('preview')}
              </styled.PreviewButton>
            </div>
          </Flex>
          <RadioGroupFormik
            gap="8px"
            isVerticalDisplay
            name={INVOICE_CUSTOMISATION_FORM_FIELDS.selectedTemplateType}
            radioElementMarginBottom="8px"
          >
            <RadioListCard
              title={t('templateMinimal')}
              id={PdfTemplateType.MINIMAL}
              value={PdfTemplateType.MINIMAL}
            />
            <RadioListCard
              title={t('templateStrong')}
              id={PdfTemplateType.STRONG}
              value={PdfTemplateType.STRONG}
            />
            <RadioListCard
              title={t('templateClassic')}
              id={PdfTemplateType.CLASSIC}
              value={PdfTemplateType.CLASSIC}
            />
          </RadioGroupFormik>
          <Flex flexDirection="column" gridGap="16px">
            <Heading.H4>{t('optionLogo')}</Heading.H4>
            <TileSelectorList
              variant="logo"
              initialValue={values.selectedLogo}
              values={values.logos}
              onSelectValue={handleSelectLogo}
              onAddValue={handleAddLogo}
              onRemoveValue={handleRemoveLogo}
            />
          </Flex>
          <Flex flexDirection="column" gridGap="16px">
            <Heading.H4>{t('optionAccentColour')}</Heading.H4>
            <TileSelectorList
              variant="colour"
              values={values.accentColours}
              initialValue={values.selectedColor}
              onSelectValue={handleSelectAccentColour}
              onAddValue={handleAddAccentColour}
              onRemoveValue={handleRemoveAccentColour}
            />
          </Flex>
        </styled.FormSection>
        <styled.FormSection>
          <Heading.H3>{t('headingMessaging')}</Heading.H3>
          <Field
            label={t('messagingLabel')}
            placeholder={t('messagingPlaceholder')}
            helperText={t('messagingHelperText')}
            id={INVOICE_CUSTOMISATION_FORM_FIELDS.defaultMessage}
            name={INVOICE_CUSTOMISATION_FORM_FIELDS.defaultMessage}
            maxLength={INVOICE_MESSAGING_MAX_LENGTH}
            component={InputAdaptiveTextArea}
            marginBottom="16px"
            rows={4}
          />
          <Field
            label={t('termsAndConditionsLabel')}
            id={INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions}
            name={INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions}
            component={InputAdaptiveTextArea}
            marginBottom="0px"
            rows={4}
            limitAreaPx={{
              width: 650,
              height: 100,
            }}
            onLimit={handleTermsAndConditionsLimit}
          />
          <FormButtons onCancel={resetForm} onSave={submitForm} />
        </styled.FormSection>
      </styled.FormWrapper>
    </>
  )
}
