import { VelocityWindowEnum } from '@npco/mp-gql-types'

import dayjs from 'utils/dayjs'

const DEFAULT_VELOCITY_CONTROL_RESET_TIMEZONE = 'Australia/Sydney'

export const getVelocityControlNewResetsAtApiFormat = ({
  entityAddressTimezone = DEFAULT_VELOCITY_CONTROL_RESET_TIMEZONE,
  velocityWindow,
}: {
  entityAddressTimezone?: string
  velocityWindow: VelocityWindowEnum
}) => {
  const now = dayjs().tz(entityAddressTimezone)
  if (velocityWindow === VelocityWindowEnum.DAY) {
    return now.add(1, 'day').startOf('day').utc().toISOString()
  }

  if (velocityWindow === VelocityWindowEnum.WEEK) {
    return now.add(1, 'week').startOf('week').utc().toISOString()
  }

  if (velocityWindow === VelocityWindowEnum.FORTNIGHT) {
    const isResetWeek = now.week() % 2 === 1
    return now
      .add(isResetWeek ? 2 : 1, 'week')
      .startOf('week')
      .utc()
      .toISOString()
  }

  if (velocityWindow === VelocityWindowEnum.MONTH) {
    return now.add(1, 'month').startOf('month').utc().toISOString()
  }

  if (velocityWindow === VelocityWindowEnum.QUARTER) {
    return now.add(1, 'quarter').startOf('quarter').utc().toISOString()
  }

  throw new Error(
    `[INVALID VELOCITY WINDOW] - Only DAY, WEEK, FORTNIGHT, MONTH, QUARTER velocity windows are supported, got ${velocityWindow}.`
  )
}
