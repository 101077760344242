import { BodyDefault, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CheckAll = styled.label`
  cursor: pointer;
  flex-grow: 1;
  display: flex;
`

export const CheckAllLabel = styled.span`
  flex-grow: 1;
`

export const Label = styled.p`
  margin: 0;
`

export const CheckAllContainer = styled(Flex)`
  margin: 0 0 18px 0;
`
export const CheckboxContainer = styled.label`
  display: flex;
`

export const CheckboxMessage = styled(BodyDefault)`
  flex-grow: 1;
  margin: 0 0 0 12px;
  cursor: pointer;
`

export const ShowAll = styled.span`
  cursor: pointer;
  display: block;
  padding: 8px;
  color: ${({ theme }) => theme.colors.BLUE_1000};
`

export const SelectLabel = styled.label`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.GREY_550};
  position: relative;
  bottom: -28px;
  left: 16px;
`

export const Select = styled.select`
  width: 100%;
  background: ${({ theme }) => theme.colors.GREY_30};
  padding: 20px;
  margin: 20px 0 10px;
  border-radius: 4px;
  border: 0;
  border-right: 10px solid ${({ theme }) => theme.colors.GREY_30};
`

export const ErrorMessage = styled(BodyDefault)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.RED_1000};
`

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  flex: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
`
