import UTIF from 'utif'

import { errorMessages } from 'translations'

const imageBufferToRgbaFormatUnit8Array = (
  buffer: ArrayBuffer,
  firstIDF: UTIF.IFD
) => {
  UTIF.decodeImage(buffer, firstIDF)
  return UTIF.toRGBA8(firstIDF)
}

const rgbaFormatUnit8ArrayToDataUrl = (
  rgbaFormatArray: Uint8Array,
  { width, height }: UTIF.IFD
) => {
  const canvasElement: HTMLCanvasElement = document.createElement('canvas')
  canvasElement.width = width
  canvasElement.height = height

  const canvasCtx = canvasElement.getContext('2d') as CanvasRenderingContext2D
  const imageData = canvasCtx.createImageData(width, height)

  imageData.data.set(rgbaFormatArray)
  canvasCtx.putImageData(imageData, 0, 0)
  return canvasElement.toDataURL('image/jpeg', 0.9)
}

export const tagImageConverter = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const bufferReader = new FileReader()
    bufferReader.onload = () => {
      const buffer = bufferReader.result
      if (buffer instanceof ArrayBuffer) {
        try {
          const firstIDF = UTIF.decode(buffer)[0]
          const rgbaFormatArray = imageBufferToRgbaFormatUnit8Array(
            buffer,
            firstIDF
          )
          resolve(rgbaFormatUnit8ArrayToDataUrl(rgbaFormatArray, firstIDF))
        } catch (error) {
          reject(new Error(errorMessages.errorConvertTagImageFile))
        }
      }
      reject(new Error(errorMessages.errorConvertTagImageFile))
    }
    bufferReader.onerror = reject
    bufferReader.readAsArrayBuffer(file)
  })
}
