import { createContext } from 'react'

import { PickerItemProps } from 'types/picker'

interface SourcePickerContextType {
  selectedSources: PickerItemProps[]
  setSelectedSources: React.Dispatch<React.SetStateAction<PickerItemProps[]>>
}

export const SourcePickerContext =
  createContext<SourcePickerContextType | null>(null)
