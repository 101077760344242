import { useContext } from 'react'
import { DrawerBasic, DrawerBasicProps } from '@npco/zeller-design-system'

import { DrawerNavigationContext } from './DrawerNavigation/DrawerNavigation.context'
import { NavButtons } from './DrawerNavigation/NavButtons/NavButtons'

export const DrawerWithNavigation = ({
  children,
  ...rest
}: Omit<DrawerBasicProps, 'beforeCloseContent' | 'contentContainerRef'>) => {
  const { drawerContentContainerRef } = useContext(DrawerNavigationContext)

  return (
    <DrawerBasic
      beforeCloseContent={<NavButtons />}
      contentContainerRef={drawerContentContainerRef || undefined}
      overlayClassName="animated-drawer-overlay"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </DrawerBasic>
  )
}
