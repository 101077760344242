import { FileWithId } from 'components/File'

const FILE_INDEX_REGEX = /\((\d+)\)$/

interface FileMetadata {
  fileNameWithoutIndex?: string
  extension?: string
  fileIndex: number
}

export const splitOnLast = (
  text: string,
  delimiter: string
): [string, string] | [string] => {
  const lastOccurrenceOfDelimiter = text.lastIndexOf(delimiter)

  if (lastOccurrenceOfDelimiter < 0) {
    return [text]
  }

  const firstPart = text.substring(0, lastOccurrenceOfDelimiter)
  const secondPart = text.substring(lastOccurrenceOfDelimiter + 1)

  if (!secondPart) {
    return [firstPart]
  }

  return [firstPart, secondPart]
}

export const getFileMetadata = (fullFileName: string): FileMetadata => {
  const [fileName, extension] = splitOnLast(fullFileName, '.')
  const indexMatch = new RegExp(FILE_INDEX_REGEX).exec(fileName)

  if (indexMatch === null) {
    return {
      fileNameWithoutIndex: fileName,
      extension,
      fileIndex: 0,
    }
  }

  const [, indexAsString] = indexMatch
  const fileIndex = Number.parseInt(indexAsString, 10)

  const fileNameWithoutIndex = fileName.replace(FILE_INDEX_REGEX, '')

  return {
    fileNameWithoutIndex,
    extension,
    fileIndex,
  }
}

export const getNewImageId = (
  currentImagesFileNames: string[],
  newImageCurrentName: string
) => {
  const currentFileNamesMetadata = currentImagesFileNames.map(getFileMetadata)
  const newImageMetadata = getFileMetadata(newImageCurrentName)

  const duplicates = currentFileNamesMetadata.filter(
    ({ extension, fileNameWithoutIndex }) =>
      fileNameWithoutIndex === newImageMetadata.fileNameWithoutIndex &&
      extension === newImageMetadata.extension
  )

  if (duplicates.length === 0) {
    return newImageCurrentName
  }

  const [{ fileIndex: highestDuplicateIndex }] = [...duplicates].sort(
    ({ fileIndex: fileIndexA }, { fileIndex: fileIndexB }) =>
      fileIndexB - fileIndexA
  )

  const nextIndex = highestDuplicateIndex + 1
  const extensionSuffix =
    newImageMetadata.extension !== undefined
      ? `.${newImageMetadata.extension}`
      : ''

  return `${newImageMetadata.fileNameWithoutIndex}(${nextIndex})${extensionSuffix}`
}

export const getFilesToUpload = (
  filesFromDragAndDrop: FileWithId[],
  transactionCombinedImagesIds: string[]
) =>
  filesFromDragAndDrop.reduce<FileWithId[]>((alreadyMappedFiles, { file }) => {
    const alreadyMappedFilesIds = alreadyMappedFiles.map(
      (prevImage) => prevImage.id
    )
    const id = getNewImageId(
      [...transactionCombinedImagesIds, ...alreadyMappedFilesIds],
      file.name
    )

    const currentFileWithId = {
      id,
      file,
    }

    return [...alreadyMappedFiles, currentFileWithId]
  }, [])
