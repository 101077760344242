import { useTranslations } from '@npco/utils-translations'

import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { stageTitleTranslations } from './useStageTitle.i18n'

export const useStageTitle = (stage?: AddCardStage) => {
  const t = useTranslations(stageTitleTranslations)

  if (!stage) {
    return ''
  }

  switch (stage) {
    case AddCardStage.LinkCardIdStage:
      return t('linkCardIdStageTitle')
    case AddCardStage.LinkEMLCardInfoStage:
      return t('linkEMLCardInfoStageTitle')
    case AddCardStage.LinkSelectAccountStage:
      return t('linkSelectAccountStageTitle')
    case AddCardStage.CreateSelectAccountStage:
      return t('createSelectAccountStageTitle')
    case AddCardStage.CreateSelectCardholderStage:
      return t('createSelectCardholderStageTitle')
    case AddCardStage.CreateSelectCardTypeStage:
      return t('createSelectCardTypeStageTitle')
    case AddCardStage.CreateSelectCardFormatStage:
      return t('createSelectCardFormatStageTitle')
    case AddCardStage.CreateSetCardSettingsStage:
      return t('createSetCardSettingsStageTitle')
    case AddCardStage.CreateSpendControlsStage:
      return t('createSpendControlsStageTitle')
    case AddCardStage.CreateSelectCardDesignStage:
      return t('createSelectCardDesignStageTitle')
    case AddCardStage.CreateSelectDeliveryAddressStage:
      return t('createSelectDeliveryAddressStageTitle')
    case AddCardStage.CreateReviewCardStage:
      return t('createReviewCardStageTitle')
    default:
      return t('defaultTitle')
  }
}
