import { lazy, Suspense, useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowOverviewLarge from 'assets/images/setup-flow/setup-flow-overview-large.png'
import SetupFlowOverviewMedium from 'assets/images/setup-flow/setup-flow-overview-medium.png'
import SetupFlowOverviewSmall from 'assets/images/setup-flow/setup-flow-overview-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowContext } from '../../../contexts/SetupFlowContext'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { setupFlowOverviewStageTranslations } from './FeatureOverviewStage.i18n'
import { MainContent } from './MainContent/MainContent'

const IllustrationAnimation = lazy(
  () =>
    import('../../../components/IllustrationAnimation/IllustrationAnimation')
)
export const FeatureOverviewStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(setupFlowOverviewStageTranslations)

  const { goToStage } = useSetupFlowModal()
  const { setExitClicked } = useSetupFlowContext()

  const handleExitClicked = useCallback(() => {
    setExitClicked(true)
  }, [setExitClicked])

  return (
    <Tutorial.TutorialLayout
      badgeName={t('badge')}
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={handleExitClicked}
            onSecondaryButtonClick={() => goToStage('FeatureContactsStage')}
            primaryButtonLabel={tShared('letsGo')}
          />
        </MotionFooter>
      }
      Illustration={
        <Suspense
          fallback={
            <Illustration
              imageSmall={SetupFlowOverviewSmall}
              imageMedium={SetupFlowOverviewMedium}
              imageLarge={SetupFlowOverviewLarge}
            />
          }
        >
          <IllustrationAnimation
            fallbackImageSmall={SetupFlowOverviewSmall}
            fallbackImageMedium={SetupFlowOverviewMedium}
            fallbackImageLarge={SetupFlowOverviewLarge}
            animationDataFilenameSmall={t('animationDataFilenameSmall')}
            animationDataFilenameMedium={t('animationDataFilenameMedium')}
            animationDataFilenameLarge={t('animationDataFilenameLarge')}
          />
        </Suspense>
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
