import {
  Box,
  ButtonLink,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { ModalWithButtons, useModalState } from 'design-system/Components/Modal'

import { useUnlinkBsbPaymentInstrument } from 'hooks/paymentInstruments/useUnlinkBsbPaymentInstrument'
import { translate } from 'utils/translations'
import { shared } from 'translations'

const showErrorMessage = (accountName: string) => {
  showErrorToast(
    translate(
      'page.contact.sections.bankAccounts.unlinkBankAccountModal.unlinkBankAccountFailure',
      { accountName }
    )
  )
}

interface UnlinkBankAccountProps {
  accountName: string
  contactId: string
  contactName: string
  onUnlinkSuccess: () => void
  paymentIntrumentId: string
}

export const UnlinkBankAccount = ({
  accountName,
  contactId,
  contactName,
  onUnlinkSuccess,
  paymentIntrumentId,
}: UnlinkBankAccountProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const { isUnlinkingPaymentInstrument, unlinkPaymentInstrument } =
    useUnlinkBsbPaymentInstrument()

  const handleUnlinkBankAccount = async () => {
    try {
      const unlinkResponse = await unlinkPaymentInstrument(
        contactId,
        paymentIntrumentId
      )

      if (!unlinkResponse.data?.unlinkPaymentInstrumentFromContact) {
        showErrorMessage(accountName)
        return
      }

      showSuccessToast(
        translate(
          'page.contact.sections.bankAccounts.unlinkBankAccountModal.unlinkBankAccountSuccess',
          { accountName }
        )
      )

      closeModal()
      onUnlinkSuccess()
    } catch (error) {
      showErrorMessage(accountName)
    }
  }

  return (
    <Box mt="24px" marginBottom="0.25rem">
      <ButtonLink onClick={() => openModal()}>
        {translate('page.contact.sections.bankAccounts.unlinkBankAccount')}
      </ButtonLink>
      <ModalWithButtons
        title={translate(
          'page.contact.sections.bankAccounts.unlinkBankAccountModal.title',
          { contactName }
        )}
        primaryButtonLabel={translate(
          'page.contact.sections.bankAccounts.unlinkBankAccountModal.unlinkButton'
        )}
        secondaryButtonLabel={shared.cancel}
        isPrimaryButtonLoading={isUnlinkingPaymentInstrument}
        isOpen={isModalOpen}
        onConfirm={handleUnlinkBankAccount}
        onCancel={closeModal}
        hasCancelButton
      >
        <Box mb="0.75rem">
          {translate(
            'page.contact.sections.bankAccounts.unlinkBankAccountModal.description'
          )}
        </Box>
      </ModalWithButtons>
    </Box>
  )
}
