import { useTranslations } from '@npco/utils-translations'
import { showSuccessToast } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { addBpayDetailsFormInitialValue } from 'pages/Transfer/BPay/AddBpayDetailsModal/AddBpayDetailsModal.utils'
import { bpayDetailsFieldTranslations } from 'pages/Transfer/TransferFields/fields/BpayDetails/BpayDetailsField.i18n'

import { AddBpayDetailsForm } from './AddBpayDetailsForm'
import { AddBpayDetailsFormValues } from './AddBpayDetailsModal.types'
import { useAddBpayDetailsFormValidationSchema } from './hooks/useAddBpayDetailsFormValidation'

export interface AddBpayDetailsModalProps {
  onDone: (value: AddBpayDetailsFormValues) => void
  closeModal: () => void
  isModalOpen: boolean
  contactName?: string
}

export const AddBpayDetailsModal = ({
  onDone,
  closeModal,
  isModalOpen,
  contactName,
}: AddBpayDetailsModalProps) => {
  const t = useTranslations(bpayDetailsFieldTranslations)
  const validationSchema = useAddBpayDetailsFormValidationSchema()
  return (
    <Formik<AddBpayDetailsFormValues>
      initialValues={addBpayDetailsFormInitialValue}
      validationSchema={validationSchema}
      onSubmit={(value) => {
        onDone(value)
        closeModal()
        showSuccessToast(t('successMessage'))
      }}
    >
      <AddBpayDetailsForm
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        contactName={contactName}
      />
    </Formik>
  )
}
