import {
  BodySmallTypography,
  ButtonBasic,
  ButtonGhost,
  H3,
  Input,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledButtonIcon = styled(ButtonBasic)`
  background-color: transparent;
  border: 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.GREY_250 : theme.colors.BLUE_1000};
  line-height: 0;
  padding: 0;
`

export const StyledClearLabelButton = styled(ButtonGhost)`
  padding: 8px;
`

export const StyledFooterContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_150};
  display: flex;
  justify-content: flex-end;
  margin-top: 0.25rem;
  padding: 1rem 1rem 0.75rem 1rem;
  text-align: right;
  width: auto;
`

export const StyledItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`

export const StyledLabelButton = styled(ButtonBasic)`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  flex-shrink: 0;
  font-size: 0.875rem;
  padding: 0;
`

export const StyledInputWrapper = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;

  ${Input} {
    ${BodySmallTypography};
    background-color: transparent;
    text-align: right;
  }
`

export const StyledNoItemsH3 = styled(H3)`
  margin: 0rem 0rem 1rem 0rem;
`

export const StyledNoItemsSpan = styled.span`
  ${BodySmallTypography}
  text-align: center;
`
