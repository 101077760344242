import { SetStateAction, useMemo, useState } from 'react'
import { Box } from '@npco/zeller-design-system'
import {
  createAddNewItem,
  SelectItemBasic,
  SelectNoItemsPlaceholderRenderProps,
  SelectSearchInput,
  SelectSelectedItem,
  SelectSize,
  SelectStandard,
} from 'design-system/Components/Select'
import { SelectLoadingPlaceholder } from 'design-system/Components/Select/SelectLoadingPlaceholder/SelectLoadingPlaceholder'
import { useField, useFormikContext } from 'formik'

import { getIsFoundBySearchFilter } from 'utils/common'
import { translate } from 'utils/translations'
import { CustomValidator } from 'types/common'
import { UpdateValuesFields } from 'components/EditZellerCategories/EditZellerCategories.types'

import {
  StyledErrorMessage,
  StyledSubtext,
} from '../EditCategoriesModal.styled'

interface Props<V, I extends SelectItemBasic> {
  name: string
  selectedItem?: I | null
  items: I[]
  label: string
  updateValues?: (fields: UpdateValuesFields<V, I>) => void
  onChange?: (item: SelectItemBasic) => void
  subtext?: React.ReactNode
  placeholder?: string
  isDisabled?: boolean
  renderNoItemsPlaceholder?: (
    renderProps: SelectNoItemsPlaceholderRenderProps
  ) => React.ReactNode
  validate?: CustomValidator<string>
  onChangeSearchInput?: (input: SetStateAction<string>) => void
  isLoading?: boolean
  isSearchDisabled?: boolean
  mobileLabel?: string
  handleAddNewItem?: () => void
  hasError?: boolean
}

export const CategoryDropdown = <
  V,
  I extends SelectItemBasic = SelectItemBasic
>({
  name,
  selectedItem,
  items: baseItems,
  label,
  updateValues = ({ handlers: formHandlers, newItem }) =>
    formHandlers.setValue(newItem?.value || ''),
  onChange,
  subtext,
  placeholder,
  isDisabled = false,
  renderNoItemsPlaceholder,
  validate,
  onChangeSearchInput,
  isLoading = false,
  isSearchDisabled = false,
  mobileLabel,
  handleAddNewItem,
  hasError,
}: Props<V, I>) => {
  const [field, meta, handlers] = useField({ name, validate })
  const formik = useFormikContext<V>()

  const [searchInput, setSearchInput] = useState('')

  const isItemMatchingSearch = useMemo(
    () => baseItems.some((baseItem) => baseItem.label === searchInput),
    [baseItems, searchInput]
  )

  const shouldDisplayAddNewItem = useMemo(
    () => handleAddNewItem && searchInput && !isItemMatchingSearch && !hasError,
    [handleAddNewItem, searchInput, isItemMatchingSearch, hasError]
  )

  const items = useMemo(
    () =>
      shouldDisplayAddNewItem
        ? [createAddNewItem(searchInput) as I, ...baseItems]
        : baseItems,
    [shouldDisplayAddNewItem, searchInput, baseItems]
  )

  const filterItems = (item: SelectItemBasic) => {
    return getIsFoundBySearchFilter(
      {
        label: item.label,
      },
      searchInput
    )
  }

  const handleChange = (newItem: SelectSelectedItem<I>) => {
    if (newItem?.value === searchInput && shouldDisplayAddNewItem) {
      handleAddNewItem?.()
      return
    }

    updateValues({ field, meta, handlers, newItem, formik })

    if (newItem && onChange) {
      onChange(newItem)
    }
  }

  const hasFormError = Boolean(meta.error && meta.touched)

  const handleSearchChange: React.Dispatch<SetStateAction<string>> = (
    input
  ) => {
    setSearchInput(input)
    onChangeSearchInput?.(input)
  }

  return (
    <>
      <SelectStandard<I>
        selectedItem={
          selectedItem === undefined
            ? items.find((item) => field.value === item.value)
            : selectedItem
        }
        items={items}
        onChange={handleChange}
        mobileLabel={mobileLabel ?? label}
        label={label}
        renderAdditionalControls={
          isSearchDisabled
            ? undefined
            : () => (
                <Box p={['0 0.5rem', '0.5rem']}>
                  <SelectSearchInput
                    value={searchInput}
                    setValue={handleSearchChange}
                    aria-label={translate('shared.search')}
                    maxLength={50}
                  />
                </Box>
              )
        }
        filterItems={filterItems}
        placeholder={placeholder}
        isDisabled={isDisabled}
        renderNoItemsPlaceholder={renderNoItemsPlaceholder}
        maxHeight={isSearchDisabled ? '14.5rem' : '10.35rem'}
        hasSelectedIndicator
        size={SelectSize.Small}
        isLoading={isLoading}
        renderLoadingPlaceholder={SelectLoadingPlaceholder}
        shouldAutoFocusControl={!isSearchDisabled}
        hasError={hasFormError}
      />
      {subtext && !hasFormError && <StyledSubtext>{subtext}</StyledSubtext>}
      <StyledErrorMessage hasError={hasFormError} errorMessage={meta.error} />
    </>
  )
}
