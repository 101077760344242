import { useCallback, useState } from 'react'
import {
  ErrorMessageForm,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { AddBpayDetailsFormValues } from 'pages/Transfer/BPay/AddBpayDetailsModal/AddBpayDetailsModal.types'
import { useBillerCodeSearchFieldValidate } from 'pages/Transfer/BPay/AddBpayDetailsModal/components/BillerCodeSearchField/hooks/useBillerCodeSearchFieldValidate'
import { BillerCodeSearchInput } from 'pages/Transfer/BPay/AddBpayDetailsModal/components/BillerCodeSearchInput/BillerCodeSearchInput'
import { type BillerSelectOption } from 'pages/Transfer/BPay/AddBpayDetailsModal/components/BillerCodeSearchInput/BillerCodeSearchInput.utils'
import { useDisabledBillerCodes } from 'pages/Transfer/BPay/hooks/useDisabledBillerCodes/useDisabledBillerCodes'
import * as styled from 'pages/Transfer/TransferFields/fields/field.styled'

import { defaultSelectedOption } from './BillerCodeSearchField.utils'

export const BillerCodedSearchField = () => {
  const [selectedItem, setSelectedItem] = useState<BillerSelectOption>(
    defaultSelectedOption
  )
  const { disabledBillers } = useDisabledBillerCodes()
  const [value, { error, touched }, { setValue, setTouched }] = useField<
    AddBpayDetailsFormValues['billerCode']
  >({
    name: 'billerCode',
    validate: useBillerCodeSearchFieldValidate(disabledBillers),
  })

  const [, , { setValue: setBillerName }] = useField<
    AddBpayDetailsFormValues['billerName']
  >({
    name: 'billerName',
  })

  const onChange = useCallback(
    (change: SelectStateInputChangeProps<BillerSelectOption>) => {
      const value = change.selectedItem
      setSelectedItem(value ?? defaultSelectedOption)
      setValue(value?.value ?? '')
      setBillerName((value?.subLabel as string) ?? '')
      setTouched(false)
    },
    [setValue, setBillerName, setTouched]
  )

  return (
    <styled.FieldWrapper>
      <BillerCodeSearchInput
        onChange={onChange}
        hasError={!!error && touched}
        selectedItem={selectedItem}
        onBlur={value.onBlur}
        onClose={() => setTouched(true)}
      />
      <ErrorMessageForm hasError={!!error && touched} errorMessage={error} />
    </styled.FieldWrapper>
  )
}
