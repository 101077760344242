import { useCallback } from 'react'

import { StartUpModal } from './useStartupModalsSession.types'

type UseSetStartupModalForSessionProps = {
  updateStartupModalAndReRender: (modal: StartUpModal) => void
  showPushAppModal: boolean
}

export const useSetStartupModalForSession = ({
  updateStartupModalAndReRender,
  showPushAppModal,
}: UseSetStartupModalForSessionProps) => {
  const setStartupModalForSession = useCallback(() => {
    if (showPushAppModal) {
      updateStartupModalAndReRender('pushApp')
    }
  }, [showPushAppModal, updateStartupModalAndReRender])

  return { setStartupModalForSession }
}
