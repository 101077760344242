import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  sendViaEmailLabel: 'Send via Email enabled by default',
  sendViaEmailTooltip:
    'When you create a new Invoice, the delivery method for email will be preselected.',
  sendViaSMSLabel: 'Send via SMS enabled by default',
  sendViaSMSTooltip:
    'When you create a new Invoice, the delivery method for SMS will be preselected.',
  bccEmailToggle: 'Always BCC a copy',
  bccEmailLabel: 'BCC Email',
  discountsTooltip:
    'This will give you the ability to configure invoice and item level discounts',
  discountsLabel: 'Discounts',
})
