import { ONBOARDING_SHOP } from '@npco/mp-feature-onboarding-shop'

import { ROOT } from '../routes'
import { OnboardingFlowConfigItem } from './onboarding-config.types'
import { SHARED_KYC_FLOW_CONFIG_ITEMS } from './onboarding-config-kyc'

const ONBOARDING_ASSOCIATION_CONFIG_COMMON: OnboardingFlowConfigItem[] = [
  {
    path: ROOT.ONBOARDING.ASSOCIATION.POSITION.path,
    isCheckpoint: false,
  },
  ...SHARED_KYC_FLOW_CONFIG_ITEMS,
  {
    path: ROOT.ONBOARDING.ASSOCIATION.CUSTOMERS.CHAIR.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.CUSTOMERS.SECRETARY.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.CUSTOMERS.TREASURER.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.ABOUT_BUSINESS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.BUSINESS_REGULATIONS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.UPLOAD_DOCUMENTS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.UPLOAD_DOCUMENTS_LATER.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.UPLOAD_BANK_STATEMENTS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.PRE_ONBOARDED_QUESTIONNAIRE.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.COMPLETE.path,
    isCheckpoint: true,
  },
  { path: ONBOARDING_SHOP.DEALS.path, isCheckpoint: false },
  { path: ONBOARDING_SHOP.DEALS.CHECKOUT.path, isCheckpoint: false },
]
export const ONBOARDING_ASSOCIATION_CONFIG_ITEMS: OnboardingFlowConfigItem[] = [
  {
    path: ROOT.ONBOARDING.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.COMPANY_INFORMATION.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRY_AGAIN.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.TRADING_NAME.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.REGISTERED_OFFICE_ADDRESS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.PLACE_OF_BUSINESS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.REVENUE.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.ASSOCIATION.BUSINESS_TYPE.path,
    isCheckpoint: false,
  },
  ...ONBOARDING_ASSOCIATION_CONFIG_COMMON,
]

export const ONBOARDING_ASSOCIATION_CONFIG_ITEMS_PHASE_2: OnboardingFlowConfigItem[] =
  [
    {
      path: ROOT.ONBOARDING.YOUR_BUSINESS_INFORMATION.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.ASSOCIATION.REGISTERED_OFFICE_ADDRESS.path,
      isCheckpoint: true,
    },
    {
      path: ROOT.ONBOARDING.ASSOCIATION.PLACE_OF_BUSINESS.path,
      isCheckpoint: false,
    },
    ...ONBOARDING_ASSOCIATION_CONFIG_COMMON,
  ]
