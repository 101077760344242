import { createTranslations } from '@npco/utils-translations'

export const completeStageTranslations = createTranslations({
  newTransfer: 'New Transfer',
  close: 'Close',
  amount: 'Amount',
  date: 'Date',
  from: 'From',
  to: 'To',
  billerNickname: 'Biller Nickname',
  billerCode: 'Biller Code',
  crn: 'CRN',
})
