import { Box, Flex } from '@npco/zeller-design-system'

import { useHitEnter } from 'hooks/useHitEnter'
import { BUTTON_SIZE } from 'types/common'
import { StyledHeader } from 'pages/Login/Login.styled'
import { AuthLayout } from 'layouts'
import { Button } from 'components/Buttons/Button'
import { page } from 'translations'

import { StyledCopy } from './AccountCreated.styled'
import { useStartOnboarding } from './hooks/useStartOnboarding'

export const AccountCreated = () => {
  const { startOnboarding } = useStartOnboarding()

  useHitEnter(startOnboarding)

  return (
    <AuthLayout hasNavBar={false} shouldShowMarketingBanner={false}>
      <Box>
        <StyledHeader data-testid="verify-phone-number-page">
          {page.accountCreated.headline}
        </StyledHeader>
        <StyledCopy>{page.accountCreated.copy}</StyledCopy>
      </Box>
      <Flex width={['100%', '200px']} flexDirection="column">
        <Button onClick={startOnboarding} fullWidth size={BUTTON_SIZE.LARGE}>
          {page.accountCreated.buttonLabel}
        </Button>
      </Flex>
    </AuthLayout>
  )
}
