import { useCallback } from 'react'
import {
  PopperItemOneLine,
  PopperItemOneLineSize,
} from '@npco/zeller-design-system'
import { AllInvoiceTableColumn } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import {
  isAscendingSelected,
  isDescendingSelected,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { IdFiltersWrapper } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/IdFilters/IdFilters.styled'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'
import { page } from 'translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'

export interface IdFiltersProps {
  setIsOpen: (state: boolean) => void
}

const columnName = AllInvoiceTableColumn.ID

export const IdFilters = ({ setIsOpen }: IdFiltersProps) => {
  const { sortingObject, setSortingObject } = useInvoicesContext()

  const handleClick = useCallback(
    (ascending: boolean) => () => {
      setIsOpen(false)
      if (
        sortingObject?.ascending === ascending &&
        sortingObject?.columnName === columnName
      ) {
        setSortingObject(null)
      } else {
        setSortingObject({ columnName, ascending })
      }
    },
    [setSortingObject, setIsOpen, sortingObject]
  )

  return (
    <IdFiltersWrapper>
      <PopperItemOneLine
        shouldDisplayIcon={isAscendingSelected(sortingObject, columnName)}
        icon={Tick}
        label={page.invoiceTable.sorting.idAscending}
        onClick={handleClick(true)}
        size={PopperItemOneLineSize.Small}
      />
      <PopperItemOneLine
        shouldDisplayIcon={isDescendingSelected(sortingObject, columnName)}
        icon={Tick}
        label={page.invoiceTable.sorting.idDescending}
        onClick={handleClick(false)}
        size={PopperItemOneLineSize.Small}
      />
    </IdFiltersWrapper>
  )
}
