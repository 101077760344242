import {
  ButtonFill,
  ButtonGhost,
  ButtonGhostIconOnly,
  Ellipsize,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const TitleEllipsis = styled(Ellipsize)`
  display: flex;
  flex: 1;
`

export const PrimaryButton = styled(ButtonFill)`
  // NOTE: min-width is required to prevent button from changing width when
  // switching between 'Schedule' and 'Send' text.
  min-width: 150px;
  padding: 12px 40px;
`

export const SecondaryButton = styled(ButtonGhost)`
  padding: 12px 24px;
`

// NOTE: double ampersand required to override button ghost icon padding rule
// defined
export const IconButton = styled(ButtonGhostIconOnly)`
  && {
    padding: 16px 10px;
    width: 48px;
  }
`
