import { detect } from 'detect-browser'

import {
  ZELLER_APP_LINK_ANDROID,
  ZELLER_APP_LINK_IOS,
} from 'const/externalLinks'

export const getIsAndriod = () => {
  const browser = detect()
  return browser?.os === 'Android OS'
}

export const getAppLink = () => {
  if (getIsAndriod()) {
    return ZELLER_APP_LINK_ANDROID
  }

  return ZELLER_APP_LINK_IOS
}
