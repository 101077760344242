import { useCallback } from 'react'
import { DocumentType, type HelperFieldsInput } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import {
  rvCustomersInitialMembersData,
  rvCustomersTempData,
  rvKYCData,
  rvOnboardingDataSummary,
  rvOnboardingInitialCustomerData,
  rvOnboardingMetadata,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { omit } from 'ramda'

import { ROOT } from 'const/routes'
import type { OnboardingDataSummary } from 'types/onboarding'

import { getCheckpointFlowConfig } from './utils/getCheckpointFlowConfig'
import { getFlowFlagsCombinedForm } from './utils/getFlowFlagsCombinedForm'

export const useOnboardingDataSummary = () => {
  const flags = useFeatureFlags()

  const getOnboardingDataSummary = useCallback((): OnboardingDataSummary => {
    const entityDetails = rvEntityDetails()
    const onboardingMetadata = rvOnboardingMetadata()
    const kycDetails = rvKYCData()
    const customers = rvCustomersTempData()
    const initialMembers = rvCustomersInitialMembersData()
    const initialCustomerData = rvOnboardingInitialCustomerData()
    const dataSummary = rvOnboardingDataSummary()

    const checkPointFlowConfig = getCheckpointFlowConfig(
      Boolean(getFlowFlagsCombinedForm(onboardingMetadata?.flowFlags)),
      flags.CombinedOnboardingFormPhase1,
      flags.CombinedOnboardingFormPhase2
    )

    const { hasNoTradingName, ...helperFields } =
      onboardingMetadata.helperFields || {}

    const currentFlow = checkPointFlowConfig.get(onboardingMetadata.type)
    const isAfterKyc =
      currentFlow &&
      (currentFlow.get(onboardingMetadata.lastRoute)?.position ?? 0) >
        (currentFlow.get(ROOT.ONBOARDING.KYC.VERIFICATION_FAILED.path)
          ?.position ?? 0)

    const members = (isAfterKyc ? customers : initialMembers).map((customer) =>
      omit(['id'], customer)
    )

    return {
      entityUuid: entityDetails.id,
      flowFlags: onboardingMetadata?.flowFlags,
      name: entityDetails.name,
      acn: entityDetails.acn,
      abn: entityDetails.abn,
      type: entityDetails.type,
      registeredAddress: entityDetails.registeredAddress,
      businessAddress: entityDetails.businessAddress,
      categoryGroup: entityDetails.categoryGroup,
      category: entityDetails.category,
      estimatedAnnualRevenue: entityDetails.estimatedAnnualRevenue,
      facebook: entityDetails.facebook,
      instagram: entityDetails.instagram,
      customerDiscovery: entityDetails.customerDiscovery,
      goodsServicesProvided: entityDetails.goodsServicesProvided,
      twitter: entityDetails.twitter,
      website: entityDetails.website,
      hasNoTradingName,
      regulatorBody: {
        name: entityDetails.regulatorBody?.name ?? '',
        referenceNumber: entityDetails.regulatorBody?.referenceNumber ?? '',
      },
      onboardingFlowType: onboardingMetadata.type,
      lastCheckPoint: onboardingMetadata.lastCheckpoint,
      lastRoute: onboardingMetadata.lastRoute,
      isAfterFullSearch: onboardingMetadata.isAfterFullsearch,
      helperFields: helperFields as HelperFieldsInput,
      membersFilters: onboardingMetadata.membersFilters,
      uploadedFileNames: onboardingMetadata.uploadedFileNames,
      kycInitialData: {
        attempts: {
          [DocumentType.PASSPORT]: kycDetails.attempts?.PASSPORT || 0,
          [DocumentType.DRIVING_LICENCE]:
            kycDetails.attempts?.DRIVING_LICENCE || 0,
          [DocumentType.MEDICARE_CARD]: kycDetails.attempts?.MEDICARE_CARD || 0,
        },
        personalData: {
          street: kycDetails.yourInformation?.street,
          suburb: kycDetails.yourInformation?.suburb,
          state: kycDetails.yourInformation?.state,
          postcode: kycDetails.yourInformation?.postcode,
          firstName: kycDetails.yourInformation?.firstName,
          lastName: kycDetails.yourInformation?.lastName,
          middleName: kycDetails.yourInformation?.middleName,
          dob: kycDetails.yourInformation?.dob,
        },
      },
      initialCustomerData,
      members,
      ...dataSummary,
    }
  }, [flags.CombinedOnboardingFormPhase1, flags.CombinedOnboardingFormPhase2])

  return {
    getOnboardingDataSummary,
  }
}
