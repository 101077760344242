import { gql } from '@apollo/client'

export const RunConfigureXeroBankfeeds = gql`
  mutation RunConfigureXeroBankfeeds(
    $entityUuid: ID!
    $config: XeroBankfeedsConfigInput!
  ) {
    configureXeroBankfeeds(entityUuid: $entityUuid, config: $config) {
      organisationName
      lastDataSyncDateTime
      connection {
        id
        status
        errorType
      }
    }
  }
`
