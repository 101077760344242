import { BodyLargeTypography } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

import { SelectSize } from '../Select.types'
import { SelectTriggerBasic } from '../SelectTrigger/SelectTriggerBasic'

export const StyledSelectStandardTrigger = styled(SelectTriggerBasic)`
  display: flex;
  align-items: center;
  ${BodyLargeTypography}

  &.${SelectSize.Small} {
    height: 3rem;
    padding: 0 0.625rem 0 0.75rem;
  }

  &.${SelectSize.Medium} {
    height: 3.5rem;
    padding: 0 1.125rem 0 0.75rem;
  }

  &.${SelectSize.Large} {
    height: 4rem;
    padding: 0 1.125rem 0 0.75rem;
  }

  &.${SelectSize.ExtraLarge} {
    height: 4.75rem;
    padding: 0 1rem;
  }
`

export const SelectStandardItemStyles = css<{
  hasSelectedIndicator?: boolean
}>`
  cursor: pointer;
  border-radius: 7px;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  padding: 0.5rem;
  border: 1px solid transparent;

  &.${SelectSize.ExtraLarge} {
    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      margin-bottom: 1rem;
    }
  }

  &.isDisabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &[aria-selected='true'] {
    background-color: ${({ hasSelectedIndicator, theme }) =>
      !hasSelectedIndicator && theme.colors.BLUE_80};
    border-color: transparent;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
    border-color: transparent;
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    &[aria-selected='true'],
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.BLUE_80};
      border-color: transparent;
    }
  }
`

export const StyledSelectStandardItem = styled.li<{
  hasSelectedIndicator?: boolean
}>`
  ${SelectStandardItemStyles}
`
