import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { Box, PopperItem } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { ManageDropdown } from 'components/ManageDropdown/ManageDropdown'

import { translations } from './ManageSim.i18n'

export const ManageSim = () => {
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <ManageDropdown>
      {({ setIsOpen }) => (
        <Box p="8px">
          <PopperItem
            role="menuitem"
            title={t('billingAccount')}
            aria-label={t('billingAccount')}
            data-testid="manage-billing-account"
            onClick={() => {
              setIsOpen(false)
              navigate(
                ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.BILLING_ACCOUNT.path
              )
            }}
          />
          <PopperItem
            role="menuitem"
            title={t('simBilling')}
            aria-label={t('simBilling')}
            onClick={() => {
              setIsOpen(false)
              navigate(
                ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.BILLING_HISTORY.path
              )
            }}
          />
        </Box>
      )}
    </ManageDropdown>
  )
}
