import { Box } from '@npco/zeller-design-system'

import { LinkCardError } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { CardStyleTabType } from '../hooks/useCardStyleTabs'
import { CardIdField } from './CardIdField'
import { ErrorInfoBox } from './ErrorInfoBox/ErrorInfoBox'
import { LinkCardIdInfoBox } from './LinkCardInfoBox'

interface LinkCardIdFieldsProps {
  linkCardError: LinkCardError | undefined
  currentTab: CardStyleTabType
}

export const LinkCardIdFields = ({
  linkCardError,
  currentTab,
}: LinkCardIdFieldsProps) => {
  return (
    <Box>
      <Box pb="16px">
        {linkCardError ? (
          <ErrorInfoBox linkCardError={linkCardError} />
        ) : (
          <LinkCardIdInfoBox currentTab={currentTab} />
        )}
      </Box>
      <CardIdField />
    </Box>
  )
}
