import React from 'react'
import { Box, Flex, FlexProps } from '@npco/zeller-design-system'

type SpaceProps = string | number | null | (string | number | null)[]

export interface FormLineProps {
  children: React.ReactNode
  fractions?: (number | string)[]
  dataTestId?: string
  direction?: FlexProps['flexDirection']
  mxContainer?: SpaceProps
  pxField?: SpaceProps
}

export const FormLine = ({
  children,
  dataTestId,
  fractions,
  direction = ['column', 'row'],
  mxContainer,
  pxField,
}: FormLineProps) => (
  <Flex
    mx={mxContainer ?? [0, -2]}
    data-testid={dataTestId}
    flexDirection={direction}
  >
    {React.Children.map(
      children,
      (child, index) =>
        child && (
          <Box
            px={pxField ?? [0, 2]}
            flex={fractions ? fractions[index] : 1}
            style={{ minWidth: '0' }}
          >
            {child}
          </Box>
        )
    )}
  </Flex>
)
