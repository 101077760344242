import { useTranslations } from '@npco/utils-translations'
import { Tabs } from '@npco/zeller-design-system'

import { useCashflowReportingTransactionsContext } from '../../hooks/useCashflowReportingTransactionsContext'
import { incomeExpenseTabsTranslations } from './IncomeExpenseTabs.i18n'

export const IncomeExpenseTabsMobile = () => {
  const t = useTranslations(incomeExpenseTabsTranslations)
  const { incomeOrExpenseSelection, setIncomeOrExpenseSelection } =
    useCashflowReportingTransactionsContext()

  return (
    <Tabs currentTab={incomeOrExpenseSelection} expandOnMobile>
      <Tabs.Item
        value="income"
        onClick={() => setIncomeOrExpenseSelection('income')}
      >
        {t('income')}
      </Tabs.Item>
      <Tabs.Item
        value="expense"
        onClick={() => setIncomeOrExpenseSelection('expense')}
      >
        {t('expense')}
      </Tabs.Item>
    </Tabs>
  )
}
