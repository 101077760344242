import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useGetEntityAddressTimezoneQuery } from './graphql/getEntityAddressTimezone.generated'

interface UseGetEntityAddressTimezoneProps {
  skip?: boolean
}

export const useGetEntityAddressTimezone = ({
  skip,
}: UseGetEntityAddressTimezoneProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useGetEntityAddressTimezoneQuery({
    skip,
    variables: {
      entityUuid,
    },
  })

  return { timezone: data?.getEntityAddressTimeZone, isLoading: loading }
}
