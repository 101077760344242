import { gql } from '@apollo/client'

export const OptOutReceipts = gql`
  mutation OptOutReceipts($code: String!, $id: String!) {
    optOutReceipts(code: $code, id: $id) {
      message
      status
    }
  }
`
