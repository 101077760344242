import { Route, Routes } from 'react-router-dom-v5-compat'

import { DepositsSettingsProvider } from 'pages/Deposits/DepositsSettingsContext/DepositsSettingsContext'
import { NotFound } from 'pages/NotFound'
import { FetchSimBillingAccount } from 'pages/Settings/SimBillingAccount/FetchSimBillingAccount/FetchSimBillingAccount'
import { SimBillingAccount } from 'pages/Settings/SimBillingAccount/SimBillingAccount'

export const SimBillingAccountRoutes = () => {
  return (
    <DepositsSettingsProvider>
      <FetchSimBillingAccount>
        <Routes>
          <Route index element={<SimBillingAccount />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </FetchSimBillingAccount>
    </DepositsSettingsProvider>
  )
}
