import { Outlet } from 'react-router-dom-v5-compat'
import { Box, Flex, PageTemplate, Typography } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { SpinnerWrapped } from 'components/Spinner'

import { useConnections } from '../hooks/useConnections'
import { ConnectionList } from './ConnectionList'

export const ConnectionsLists = () => {
  const { isLoading, linkedConnections, unlinkedConnections } = useConnections()

  if (isLoading) return <SpinnerWrapped variant="centre" />

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            <Breadcrumbs />
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>
        <Typography variant="body-base">
          {translate('page.settings.connections.description')}
        </Typography>
        <Box pb="40px" />
        <ConnectionList
          title={translate('page.settings.connections.cta.linked')}
          connections={linkedConnections}
        />
        <Box pb="40px" />
        <ConnectionList
          title={translate('page.settings.connections.cta.unlinked')}
          connections={unlinkedConnections}
        />
        <Outlet />
      </PageTemplate.Section>
    </PageTemplate>
  )
}
