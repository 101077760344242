import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseSpentFundsCorporateCardDebitCardV2FragmentDoc } from '../../../../../../features/Cards/CardSingle/hooks/useSpentFundsCorporateCard/useSpentFundsCorporateCard.generated'
import { UseCardProgressDebitCardV2FragmentDoc } from '../../../../../../hooks/useCardProgress/useCardProgress.generated'
import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../hooks/useCardStatus/useCardStatus.generated'
import { CardSpendProgressCellContentDebitCardV2FragmentDoc } from './CardSpendProgressCellContent.generated'

export type CardSpendProgressCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    velocityWindow: Types.VelocityWindowEnum
    totalSpentAmount: { __typename?: 'Money'; value: string }
    availableAmount: { __typename?: 'Money'; value: string }
    amountLimit: { __typename?: 'Money'; value: string }
  } | null
}

export const CardSpendProgressCellDebitCardV2FragmentDoc = gql`
  fragment CardSpendProgressCellDebitCardV2Fragment on DebitCardV2 {
    ...UseCardStatusDebitCardV2Fragment
    ...CardSpendProgressCellContentDebitCardV2Fragment
    ...UseSpentFundsCorporateCardDebitCardV2Fragment
    ...UseCardProgressDebitCardV2Fragment
  }
  ${UseCardStatusDebitCardV2FragmentDoc}
  ${CardSpendProgressCellContentDebitCardV2FragmentDoc}
  ${UseSpentFundsCorporateCardDebitCardV2FragmentDoc}
  ${UseCardProgressDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardSpendProgressCellDebitCardV2Fragment,
  undefined
>
