import { ContactType, ImageSize } from '@npco/mp-gql-types'
import { z } from 'zod'

const ImageSchema = z.object({ size: z.nativeEnum(ImageSize), url: z.string() })

const IconSchema = z.object({
  colour: z.nullable(z.string()),
  letter: z.nullable(z.string()),
  images: z.nullable(z.array(ImageSchema)),
})

export const ToContactSchema = z.object({
  id: z.string(),
  icon: z.nullable(IconSchema).optional(),
  contactType: z.nativeEnum(ContactType),
  businessName: z.nullable(z.string()),
  firstName: z.nullable(z.string()),
  lastName: z.nullable(z.string()),
  email: z
    .object({
      email: z.string(),
    })
    .nullable()
    .optional(),
  phone: z.string().nullable().optional(),
  phoneV2: z
    .object({
      phone: z.string(),
    })
    .nullable()
    .optional(),
})

export type ToContact = z.infer<typeof ToContactSchema>

export const ToPaymentInstrumentSchema = z.object({
  id: z.string(),
  bankAccountDetails: z.nullable(
    z.object({
      account: z.nullable(z.string()),
      bsb: z.nullable(z.string()),
      name: z.nullable(z.string()),
    })
  ),
})

export type ToPaymentInstrument = z.infer<typeof ToPaymentInstrumentSchema>

export const TransferContactFieldsSchema = z.object({
  from: z.nullable(z.string()),
  toContact: ToContactSchema.nullable(),
  toPaymentInstrument: ToPaymentInstrumentSchema.nullable(),
  contact: z.string(), // Search string for contact
  amount: z.string(),
  reference: z.string(),
  recipientReference: z.string(),
  isSelf: z.boolean().optional(),
})

export const ExtendedTransferContactFieldsSchema =
  TransferContactFieldsSchema.extend({
    // This field is needed for consistency with other transfer types, to be removed when flows are split
    to: z.string().optional(),
  })

export type TransferContactFields = z.infer<
  typeof ExtendedTransferContactFieldsSchema
>
