import React, { useCallback } from 'react'
import { InvoiceItemUnit } from '@npco/mp-gql-types'
import { INPUT_SIZE, InputAdaptiveField } from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_UNIT_DAY,
  INVOICE_DEFAULT_UNIT_HOUR,
  INVOICE_DEFAULT_UNIT_QUANTITY,
  INVOICE_ITEMS_CALCULATE_QUANTITY_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceItemCalculateFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'

import { convertNumberToLocaleString } from 'utils/localeString'
import { translate } from 'utils/translations'

export const translations = {
  ariaLabel: translate(
    'page.invoice.formSections.items.itemsCalculateQuantityAriaLabel'
  ),
}

export const InvoiceItemCalculateQuantity = () => {
  const [, , helpers] = useField(INVOICE_ITEMS_CALCULATE_QUANTITY_FIELD)
  const { values } = useFormikContext<InvoiceItemCalculateFormFields>()

  const handleOnBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isUnitHour = values.unit === InvoiceItemUnit.HOUR
      const isUnitQuantity = values.unit === InvoiceItemUnit.QUANTITY

      if (!event.target.value) {
        // NOTE: must prevent default otherwise incorrect event.target.value
        // sent to formik on blur
        event.preventDefault()

        if (isUnitQuantity) {
          helpers.setValue(INVOICE_DEFAULT_UNIT_QUANTITY)
        } else if (isUnitHour) {
          helpers.setValue(INVOICE_DEFAULT_UNIT_HOUR)
        } else {
          helpers.setValue(INVOICE_DEFAULT_UNIT_DAY)
        }

        return
      }

      const number = Number(event.target.value)

      if (!Number.isNaN(number)) {
        const numberString = convertNumberToLocaleString(number, {
          useGrouping: false,
          minimumFractionDigits: isUnitQuantity ? 0 : 2,
        })

        helpers.setValue(numberString)
      }
    },
    [helpers, values.unit]
  )

  // NOTE: Although the `onKeyPress` function is marked as deprecated React does
  // not fully support the `onBeforeInput` function (recommended replacement) and
  // there are downstream issues when running in jest.
  // https://github.com/facebook/react/issues/11211
  // https://github.com/testing-library/user-event/issues/858
  const handleOnKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { currentTarget, key } = event
      const { value, selectionStart, selectionEnd } = currentTarget

      const posStart = selectionStart || 0
      const posEnd = selectionEnd || posStart
      const next = `${value.slice(0, posStart)}${key}${value.slice(posEnd)}`

      const isUnitQuantity = values.unit === InvoiceItemUnit.QUANTITY

      const isValidKeyPattern = isUnitQuantity ? /\d/ : /[\d.]/
      const isValidQuantityPattern = isUnitQuantity
        ? /^\d{0,5}$/
        : /^\d{0,5}(\.\d{0,2})?$/

      if (!isValidKeyPattern.test(next) || !isValidQuantityPattern.test(next)) {
        event.preventDefault()
      }
    },
    [values.unit]
  )

  return (
    <InputAdaptiveField
      aria-label={translations.ariaLabel}
      data-testid="invoicing-invoice-line-item-calculate-quantity"
      name={INVOICE_ITEMS_CALCULATE_QUANTITY_FIELD}
      onKeyPress={handleOnKeyPress}
      onBlur={handleOnBlur}
      size={INPUT_SIZE.SMALL}
      style={{ textAlign: 'center' }}
    />
  )
}
