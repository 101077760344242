import { useMemo } from 'react'
import { BREAKPOINT, useIsMobileResolution } from '@npco/zeller-design-system'

import SideBarImageUrl from 'assets/images/sign-up/signUp.png'

import * as styled from '../SignUpSidebar.styled'

export const SidebarImages = () => {
  const isMobileOrTabletBreakpoint = useIsMobileResolution(BREAKPOINT.MD)

  const SidebarImage = useMemo(() => {
    const width = isMobileOrTabletBreakpoint ? '375' : '100%'

    return <img alt="sidebar" src={SideBarImageUrl} width={width} />
  }, [isMobileOrTabletBreakpoint])

  return (
    <styled.ImageWrapper marginX="auto">{SidebarImage}</styled.ImageWrapper>
  )
}
