import { useCallback } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { BodyDefault, Box, COLOR, Heading } from '@npco/zeller-design-system'

import { outstandingExpensesTranslations } from './CardsSettingsOutstandingExpenses.i18n'
import { useGetOutstandingTransactionRequirementConfigQuery } from './graphql/getOutstandingTransactionRequirementConfig.generated'
import { OutstandingExpensesContent } from './OutstandingExpensesContent'

export const CardsSettingsOutstandingExpenses = () => {
  const t = useTranslations(outstandingExpensesTranslations)
  const entityUuid = useSelectedEntityUuid()
  const { loading, data, error, refetch } =
    useGetOutstandingTransactionRequirementConfigQuery({
      notifyOnNetworkStatusChange: true,
      variables: { entityUuid },
    })

  const retry = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <Box maxWidth="504px">
      <Heading.H3>{t('title')}</Heading.H3>
      <Box as={BodyDefault} mt="16px" mb="40px" color={COLOR.GREY_550}>
        {t('description')}
      </Box>
      <OutstandingExpensesContent
        loading={loading}
        error={error}
        entity={data?.getEntity}
        retry={retry}
      />
    </Box>
  )
}
