import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'

import { ROOT } from 'const/routes'

import { DesktopTopBarNav } from './components/DesktopTopBarNav/DesktopTopBarNav'
import { MobileTopBarNav } from './components/MobileTopBarNav/MobileTopBarNav'
import { useMultiEntityTabs } from './hooks/useMultiEntityTabs/useMultiEntityTabs'
import { useTopBarNavData } from './hooks/useTopBarNavData/useTopBarNavData'

type TopBarNavProps = {
  hasLeftSection?: boolean
  isSettingUpEntity?: boolean
}

export const TopBarNav = ({
  hasLeftSection = false,
  isSettingUpEntity,
}: TopBarNavProps) => {
  const { entityRelations } = useTopBarNavData()

  const {
    entities,
    activeEntityId,
    setSelectedEntityId,
    reorderEntities,
    closeEntitySession,
    goToManageBusinessesPage,
  } = useMultiEntityTabs({
    customerEntityRelations: entityRelations,
  })

  const { pathname } = useLocation()
  const isManageBusinessesPage = useMemo(() => {
    return pathname === ROOT.ORGS.MANAGE_BUSINESSES.path
  }, [pathname])

  return (
    <>
      <MobileTopBarNav
        entityTabs={entities}
        activeEntityId={activeEntityId}
        setSelectedEntityId={setSelectedEntityId}
        isManageBusinessesPage={isManageBusinessesPage}
        isSettingUpEntity={isSettingUpEntity}
      />
      <DesktopTopBarNav
        entities={entities}
        activeEntityId={activeEntityId}
        closeEntitySession={closeEntitySession}
        setSelectedEntityId={setSelectedEntityId}
        reorderEntities={reorderEntities}
        goToManageBusinessesPage={goToManageBusinessesPage}
        hasLeftSection={hasLeftSection}
        isManageBusinessesPage={isManageBusinessesPage}
      />
    </>
  )
}

TopBarNav.fragments = {
  CustomerEntityRelation: gql`
    fragment TopBarNavCustomerEntityRelationFragment on CustomerEntityRelation {
      ...UseMultiEntityTabsCustomerEntityRelationFragment
    }

    ${useMultiEntityTabs.fragments.CustomerEntityRelation}
  `,
}
