import { useCallback, useEffect, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  Box,
  COLOR,
  ErrorMessageForm,
  SelectStyle,
} from '@npco/zeller-design-system'
import {
  SelectBasic,
  SelectItemBasic,
  useSelectField,
} from 'design-system/Components/Select'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { rvSelectedContactToTransfer } from 'features/Contacts/rv-deprecated/contacts'
import { useFormikContext } from 'formik'

import { BankAccountDetails } from 'hooks/paymentInstruments/PaymentInstruments.types'
import { validateRequiredCustomMsg } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { AccountHeader } from 'pages/Transfer/ContactDropdown/AccountHeader'
import {
  StyledAccountSelectItem,
  StyledContentWrapper,
} from 'pages/Transfer/ContactDropdown/AccountSelectInput.styled'
import { AccountSelectItem } from 'pages/Transfer/ContactDropdown/AccountSelectItem'
import { AccountSelectTrigger } from 'pages/Transfer/ContactDropdown/AccountSelectTrigger/AccountSelectTrigger'
import { AddBankAccount } from 'pages/Transfer/ContactDropdown/AddBankAccount/AddBankAccount'
import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferEvent } from 'pages/Transfer/Transfer.stateMachine'
import { errorMessages } from 'translations'

import { TransferContactFields } from '../ContactTransfer'
import { useUpdateToPaymentInstrument } from '../ContactTransfer/hooks/useUpdateToPaymentInstrument/useUpdateToPaymentInstrument'

type PaymentInstrument = NonNullable<
  ContactCoreFieldsFragment['paymentInstruments']
>[0]

export interface AccountSelectInputProps {
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const TO = 'to'

type AccountSelectItem = SelectItemBasic & { data: PaymentInstrument }

export const AccountSelectInput = ({
  isModalOpen,
  openModal,
  closeModal,
}: AccountSelectInputProps) => {
  const selectedContact = useReactiveVar(rvSelectedContactToTransfer)

  const { updateToPaymentInstrument } = useUpdateToPaymentInstrument()

  const { setFieldValue, values } = useFormikContext<TransferContactFields>()

  const paymentInstruments = useMemo(() => {
    return (
      selectedContact?.paymentInstruments?.filter(
        (paymentInstrument) => paymentInstrument.bankAccountDetails !== null
      ) || []
    )
  }, [selectedContact?.paymentInstruments])

  const {
    transferState: [, send],
  } = useTransferState()

  useEffect(() => {
    if (values.toPaymentInstrument) {
      send(TransferEvent.AddAccount)
    }
  }, [values.toPaymentInstrument, send])

  const onSaveHandler = useCallback(
    (id: string, values: BankAccountDetails) => {
      setFieldValue(TO, id)
      updateToPaymentInstrument({ id, bankAccountDetails: values })
    },
    [setFieldValue, updateToPaymentInstrument]
  )

  const selectOptions: AccountSelectItem[] = useMemo(() => {
    return paymentInstruments.map((paymentInstrument) => {
      const bankDetails = paymentInstrument.bankAccountDetails
      return {
        label: bankDetails?.name || '',
        value: paymentInstrument?.id || '',
        subLabel1: bankDetails?.bsb,
        subLabel2: bankDetails?.account,
        avatar: {
          bgColor: COLOR.BLUE_80,
          bgActiveColor: COLOR.WHITE,
          color: COLOR.BLUE_1000,
          letter: bankDetails?.name?.charAt(0) || '',
        },
        data: paymentInstrument,
      }
    })
  }, [paymentInstruments])

  const { hasError, onChange, selectedItem, errorMessage } =
    useSelectField<AccountSelectItem>({
      name: TO,
      validate: validateRequiredCustomMsg(errorMessages.accountInvalid),
      items: selectOptions,
    })

  const updatePaymentInstrumentAndToFields = useCallback(
    (selectedItem?: AccountSelectItem | null) => {
      onChange(selectedItem)
      updateToPaymentInstrument(selectedItem?.data ?? null)
    },
    [onChange, updateToPaymentInstrument]
  )

  return (
    <Box mt="1.5rem">
      <SelectBasic<AccountSelectItem>
        items={selectOptions}
        mobileLabel={translate('page.transfer.account')}
        menuStyle={SelectStyle.Standard}
        onChange={updatePaymentInstrumentAndToFields}
        selectedItem={selectedItem}
        renderAdditionalControls={({ closeMenu }) => (
          <AccountHeader closeMenu={closeMenu} onAddNewAccount={openModal} />
        )}
        renderTrigger={(renderProps) => (
          <AccountSelectTrigger
            label={translate('page.transfer.account')}
            placeholder={translate('page.transfer.selectAccountPlaceholder')}
            hasError={hasError}
            selectedItem={selectedItem}
            renderProps={renderProps}
          />
        )}
        renderItem={({ item, ...renderProps }) => (
          <StyledAccountSelectItem {...renderProps}>
            <StyledContentWrapper>
              <AccountSelectItem {...item} />
            </StyledContentWrapper>
          </StyledAccountSelectItem>
        )}
      />

      <ErrorMessageForm hasError={hasError} errorMessage={errorMessage} />

      {isModalOpen && (
        <AddBankAccount closeModal={closeModal} onSaveHandler={onSaveHandler} />
      )}
    </Box>
  )
}
