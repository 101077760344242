import { AnchorBasic, Flex } from '@npco/zeller-design-system'

import {
  EXTERNAL_TIO,
  ZELLER_SIM_SFOA,
  ZELLER_SUPPORT_URL,
} from 'const/externalLinks'
import { summary } from 'translations'

import {
  StyledGeneralSecond,
  StyledH5,
  StyledH6,
  StyledPhone,
  StyledSummaryTableItem,
  StyledSummaryText,
} from './ActivateSimCardModal.styled'

const SummaryTableItem = ({ title, info }: { title: string; info: string }) => (
  <StyledSummaryTableItem>
    <StyledH6>{title}</StyledH6>
    {info}
  </StyledSummaryTableItem>
)

const SummaryText = ({ title, info }: { title: string; info: string }) => (
  <StyledSummaryText>
    <span>{title}&nbsp;</span>
    {info}
  </StyledSummaryText>
)

export const Summary = () => {
  return (
    <Flex flexDirection="column">
      <StyledGeneralSecond>{summary.first}</StyledGeneralSecond>
      <SummaryTableItem
        title={summary.table.first.title}
        info={summary.table.first.info}
      />
      <SummaryTableItem
        title={summary.table.second.title}
        info={summary.table.second.info}
      />
      <SummaryTableItem
        title={summary.table.third.title}
        info={summary.table.third.info}
      />
      <SummaryTableItem
        title={summary.table.fourth.title}
        info={summary.table.fourth.info}
      />
      <SummaryTableItem
        title={summary.table.fifth.title}
        info={summary.table.fifth.info}
      />
      <SummaryTableItem
        title={summary.table.sixth.title}
        info={summary.table.sixth.info}
      />

      <StyledH5>{summary.firstSubheader}</StyledH5>

      <SummaryText
        title={summary.info.first.title}
        info={summary.info.first.info}
      />
      <SummaryText
        title={summary.info.second.title}
        info={summary.info.second.info}
      />
      <SummaryText
        title={summary.info.third.title}
        info={summary.info.third.info}
      />
      <SummaryText
        title={summary.info.fourth.title}
        info={summary.info.fourth.info}
      />
      <SummaryText
        title={summary.info.fifth.title}
        info={summary.info.fifth.info}
      />
      <SummaryText
        title={summary.info.sixth.title}
        info={summary.info.sixth.info}
      />
      <SummaryText
        title={summary.info.seventh.title}
        info={summary.info.seventh.info}
      />

      <StyledH5>{summary.secondSubheader}</StyledH5>

      <StyledSummaryText>
        <span>{summary.otherInfo.first.title}&nbsp;</span>
        {summary.otherInfo.first.partOne}&nbsp;
        <AnchorBasic href={ZELLER_SUPPORT_URL}>
          {summary.otherInfo.first.url}
        </AnchorBasic>
        &nbsp;
        {summary.otherInfo.first.partTwo}&nbsp;
        <StyledPhone>{summary.otherInfo.first.phone}</StyledPhone>
        &nbsp;
        {summary.otherInfo.first.partThree}
      </StyledSummaryText>
      <StyledSummaryText>
        {summary.otherInfo.second.partOne}&nbsp;
        <StyledPhone>{summary.otherInfo.second.phone}</StyledPhone>
        &nbsp;
        {summary.otherInfo.second.partTwo}&nbsp;
        <AnchorBasic href={EXTERNAL_TIO}>
          {summary.otherInfo.second.url}
        </AnchorBasic>
        &nbsp;
        {summary.otherInfo.second.partThree}
      </StyledSummaryText>

      <StyledSummaryText>
        {summary.otherInfo.third.partOne}&nbsp;
        <AnchorBasic href={ZELLER_SIM_SFOA}>
          {summary.otherInfo.third.url}
        </AnchorBasic>
      </StyledSummaryText>
    </Flex>
  )
}
