import { useEffect, useState } from 'react'
import { NetworkStatus, useLazyQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetTransaction } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/transactions'
import { rvSelectedTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'

import {
  GetTransaction as GetTransactionResponse,
  GetTransactionVariables,
} from 'types/gql-types/GetTransaction'
import { Transaction } from 'types/transactions'

export const useFetchTransaction = (transactionUuid: string) => {
  const [isNavigating, setIsNavigating] = useState(false)
  const entityUuid = useSelectedEntityUuid()

  const [fetchTransaction, { loading, error, refetch, networkStatus, data }] =
    useLazyQuery<GetTransactionResponse, GetTransactionVariables>(
      GetTransaction,
      {
        onCompleted: () => setIsNavigating(false),
        onError: () => setIsNavigating(false),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      }
    )

  const hasError = error && !data?.getTransaction

  const updateSelectedTransaction = (id: string) => {
    rvSelectedTransaction({ ...(rvSelectedTransaction() as Transaction), id })
    setIsNavigating(true)
  }

  const isRefetching = networkStatus === NetworkStatus.refetch

  useEffect(() => {
    fetchTransaction({
      variables: { entityUuid, transactionUuid },
    })
  }, [entityUuid, fetchTransaction, transactionUuid])

  useEffect(() => {
    if (data?.getTransaction) {
      rvSelectedTransaction(data.getTransaction)
    }
  }, [data])

  return {
    fetchedTransaction: data?.getTransaction,
    hasError,
    isLoadingFetchTransaction: loading || isRefetching,
    isNavigatingTransaction: isNavigating || isRefetching,
    refetchTransaction: refetch,
    updateSelectedTransaction,
  }
}
