import { SimBillingStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useCancelSimMutation } from '../graphql/cancelSim.generated'
import { translations } from '../SimDrawer.i18n'

type UseCancelSimProps = {
  simId?: string
}
export const useCancelSim = ({ simId = '' }: UseCancelSimProps) => {
  const entityUuid = useSelectedEntityUuid()
  const t = useTranslations(translations)

  const [cancelSim, { loading }] = useCancelSimMutation({
    variables: { entityUuid, simId },
    onCompleted: (result) => {
      if (result.cancelSim) {
        showSuccessToast(t('cancelSimSuccessToast'))
        return
      }
      showApiErrorToast()
    },
    onError: () => showApiErrorToast(),
    update: (cache, { data }) => {
      if (data?.cancelSim) {
        cache.modify({
          id: cache.identify({
            __typename: 'Sim',
            id: simId,
          }),
          fields: {
            billingStatus() {
              return SimBillingStatus.CANCEL_PENDING
            },
          },
        })
      }
    },
  })

  return {
    cancelSim,
    isCancellingSim: loading,
  }
}
