import React from 'react'
import { gql } from '@apollo/client'
import {
  AvatarUserSize,
  ListCardButton,
  ListCardVariant,
} from '@npco/zeller-design-system'

import { getCustomerDisplayName } from 'utils/customers'
import { UserIconAvatar } from 'components/IconAvatar/UserIconAvatar/UserIconAvatar'

import { useUserNavigation } from './hooks/useUserNavigation'
import { UserWithAvatarCustomerFragment } from './UserWithAvatar.generated'

interface UserWithAvatarProps {
  user: UserWithAvatarCustomerFragment
}

export const UserWithAvatar = ({ user }: UserWithAvatarProps) => {
  const { navigateToUser, canNavigate } = useUserNavigation({ userId: user.id })

  const userIconAvatar = React.useMemo(() => {
    return (
      <UserIconAvatar
        icon={user.icon}
        firstName={user.firstname || '-'}
        size={AvatarUserSize.XXSmall}
      />
    )
  }, [user.icon, user.firstname])

  return (
    <ListCardButton
      title={getCustomerDisplayName(user)}
      image={() => userIconAvatar}
      onClick={navigateToUser}
      readonly={!canNavigate}
      variant={ListCardVariant.OnGrey}
    />
  )
}

UserWithAvatar.fragments = {
  Customer: gql`
    fragment UserWithAvatarCustomerFragment on Customer {
      id
      entityUuid
      firstname
      lastname
      icon {
        ...UserIconAvatarIconFragment
      }
    }

    ${UserIconAvatar.fragments.Icon}
  `,
}
