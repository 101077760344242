import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledSuggestionsListWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  padding-top: 8px;
  z-index: 100;
  width: 100%;
`

export const StyledSuggestionsList = styled.ul`
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  list-style: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`

export const StyledSuggestionsListItem = styled.li`
  padding: 15px 0 15px 16px;
`

export const StyledButton = styled.button`
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: left;
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.BLACK};
  cursor: pointer;
  outline: none;
  transition: color 0.3s ease-out;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`
