import { createContext, useMemo } from 'react'

import { FileListItemSelect, FileListSelectItem } from './File.types'

interface FileListProviderProps {
  children: React.ReactNode
  optionItems: FileListItemSelect['optionItems']
  defaultOption?: FileListSelectItem
}

export const FileContext = createContext<FileListItemSelect>({})

export const FileListProvider = ({
  children,
  optionItems,
  defaultOption,
}: FileListProviderProps) => {
  const providerValue = useMemo(
    () => ({ optionItems, defaultOption }),
    [defaultOption, optionItems]
  )

  return (
    <FileContext.Provider value={providerValue}>
      {children}
    </FileContext.Provider>
  )
}
