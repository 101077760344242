import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { COLOR } from '@npco/zeller-design-system'
import { sumBy } from 'lodash-es'

import { getActiveColorList } from '../../../TransactionsSummary/utils/getActiveColorList'
import { categoryDrawerContentTranslations } from '../CategoryDrawerContent.i18n'
import { HoveredSubcategoryNetAmount } from '../CategoryDrawerContent.type'
import { groupSubcategoriesNetAmount } from './groupSubcategoriesNetAmount'
import { useGetPieChartDataCashFlowSubcategoryNetAmountFragment } from './useGetPieChartData.generated'

export const BLUE_GRADIENTS = [
  COLOR.BLUE_80,
  COLOR.BLUE_120,
  '#C5DCEC',
  '#A7C9E2',
  '#71A8D0',
  COLOR.BLUE_700,
  COLOR.BLUE_1000,
  '#0055A6',
  '#004075',
  '#002442',
]

export const useGetPieChartData = (
  subcategoriesNetAmounts: useGetPieChartDataCashFlowSubcategoryNetAmountFragment[],
  hoveredSubcategoryNetAmount: HoveredSubcategoryNetAmount
) => {
  const t = useTranslations(categoryDrawerContentTranslations)

  const groupedSubcategoriesNetAmount = groupSubcategoriesNetAmount(
    subcategoriesNetAmounts
  )

  const activeNetAmount = useMemo(() => {
    if (hoveredSubcategoryNetAmount === undefined) {
      return undefined
    }

    const isHoveringOtherSubcategory = groupedSubcategoriesNetAmount?.every(
      (groupedSubcategoryNetAmount) =>
        groupedSubcategoryNetAmount.subcategoryDetails?.id !==
        hoveredSubcategoryNetAmount.subcategoryDetails?.id
    )

    return groupedSubcategoriesNetAmount?.find(
      (groupedSubcategoryNetAmount) =>
        groupedSubcategoryNetAmount.subcategoryDetails?.id ===
        (isHoveringOtherSubcategory
          ? 'other'
          : hoveredSubcategoryNetAmount.subcategoryDetails?.id)
    )
  }, [groupedSubcategoriesNetAmount, hoveredSubcategoryNetAmount])

  const pieChartData = useMemo(() => {
    const colorList = getActiveColorList(
      groupedSubcategoriesNetAmount.length,
      BLUE_GRADIENTS
    )
    const percentageTotalAmount = sumBy(
      groupedSubcategoriesNetAmount,
      (netAmount) => Math.abs(parseInt(netAmount.total.value, 10))
    )

    return groupedSubcategoriesNetAmount.map(
      (groupedSubcategoryNetAmount, index) => {
        const fill =
          !activeNetAmount ||
          activeNetAmount.subcategoryDetails?.id ===
            groupedSubcategoryNetAmount.subcategoryDetails?.id
            ? colorList[index % colorList.length]
            : COLOR.GREY_90

        const percentage =
          (Math.abs(parseInt(groupedSubcategoryNetAmount.total.value, 10)) /
            percentageTotalAmount) *
          100

        return {
          fill,
          name:
            groupedSubcategoryNetAmount.subcategoryDetails?.name ??
            t('uncategorised'),
          percentage,
        }
      }
    )
  }, [activeNetAmount, groupedSubcategoriesNetAmount, t])

  return { subcategoryNetAmount: activeNetAmount, pieChartData }
}

useGetPieChartData.fragment = {
  CashFlowSubcategoryNetAmount: gql`
    fragment useGetPieChartDataCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      subcategoryDetails {
        id
        name
      }
      total {
        value
      }

      ...groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment
    }

    ${groupSubcategoriesNetAmount.fragment.CashFlowSubcategoryNetAmount}
  `,
}
