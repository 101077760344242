import { useCallback } from 'react'

import { WithPeriod, WithTotalAmount } from 'pages/Dashboard/Dashboard.types'
import {
  getTotalsBetween,
  sumTotalSales,
} from 'pages/Dashboard/utils/transactionTotalsUtils'

export const useGetTotalSalesBetween = (
  salesTotals: (WithPeriod & WithTotalAmount)[]
) => {
  return useCallback(
    (from: Date, to: Date) => {
      return sumTotalSales(getTotalsBetween(salesTotals, from, to))
    },
    [salesTotals]
  )
}
