import { gql } from '@apollo/client'
import { ExtendedColumnDef } from '@npco/ui-table'
import { TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { useSkeletonLoaderWidth } from 'hooks/useSkeletonLoaderWidth'

import { SimDeviceCellFragment } from './SimDeviceCell.generated'

export const SimDeviceCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, SimDeviceCellFragment>) => {
  const columnDefValue = column.columnDef as ExtendedColumnDef<T>
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<SimDeviceCellFragment>()

  const skeletonLoaderWidth = useSkeletonLoaderWidth({
    rowIndex: row.index,
    sampleWidthArray: ['43%', '56%', '68%'],
  })

  return (
    <TableCellText
      key={cell.id}
      cellSize={columnDefValue.cellSize}
      dataTestId={`sim-desktop-row-${row.index}-device-name`}
      isLoading={isLoading}
      text={data?.deviceName ?? '-'}
      skeletonProps={{ width: skeletonLoaderWidth }}
    />
  )
}

SimDeviceCell.fragments = {
  Sim: gql`
    fragment SimDeviceCellFragment on Sim {
      deviceName
    }
  `,
}
