import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'

import {
  Description,
  DocumentOptionButton,
} from './DocumentOptionButton/DocumentOptionButton.styled'

interface IDOptionButtonProps {
  isSelected: boolean
  isDisabled: boolean
  onClick: () => void
  icon: React.ReactNode
  text: React.ReactNode
  description: React.ReactNode
}

export const IDOptionButton = ({
  isSelected,
  isDisabled,
  onClick,
  icon,
  text,
  description,
}: IDOptionButtonProps) => {
  return (
    <DocumentOptionButton
      data-testid="styled-option-button"
      type="button"
      isSelected={isSelected}
      disabled={isDisabled}
      onClick={onClick}
    >
      <Flex alignItems="center">
        <Box mr="14px">
          <SvgIcon
            width="16"
            height="16"
            color={isDisabled ? COLOR.GREY_250 : COLOR.BLUE_1000}
          >
            {icon}
          </SvgIcon>
        </Box>
        {text}
      </Flex>

      {isSelected ? (
        <SvgIcon
          width="12"
          height="12"
          color={COLOR.BLUE_1000}
          data-testid="tick-icon"
        >
          <Tick />
        </SvgIcon>
      ) : (
        <Description isDisabled={isDisabled}>{description}</Description>
      )}
    </DocumentOptionButton>
  )
}
