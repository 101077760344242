import { AnchorBasic } from '@npco/zeller-design-system'

import { PRIVACY_POLICY_URL, TERMS_CONDITIONS_URL } from 'const/externalLinks'
import { component } from 'translations'

import { StyledText } from './TermsAndConditionsLabel.styled'

const {
  TermsAndConditionsLabel: { agree, termsAndConditions, and, privacyPolicy },
} = component

export const TermsAndConditionsLabel: React.FC = () => {
  return (
    <StyledText>
      {agree}{' '}
      <AnchorBasic href={TERMS_CONDITIONS_URL}>
        {termsAndConditions}
      </AnchorBasic>{' '}
      {and} <AnchorBasic href={PRIVACY_POLICY_URL}>{privacyPolicy}</AnchorBasic>
    </StyledText>
  )
}
