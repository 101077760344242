import { gql, type TypedDocumentNode } from '@apollo/client'

export type ActivateButtonEntityFragment = {
  __typename?: 'Entity'
  canCreateAccount: boolean | null
}

export const ActivateButtonEntityFragmentDoc = gql`
  fragment ActivateButtonEntityFragment on Entity {
    canCreateAccount
  }
` as unknown as TypedDocumentNode<ActivateButtonEntityFragment, undefined>
