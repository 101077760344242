import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { SendInviteEmail } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'

import {
  SendInviteEmail as SendInviteEmailResponse,
  SendInviteEmailVariables,
} from 'types/gql-types/SendInviteEmail'
import { page } from 'translations'

interface UseSendInviteEmailProps {
  userId: string
}

interface UseSendInviteEmailArgs {
  isSending: boolean
  sendInviteEmail: () => void
}

export const useSendInviteEmail = ({
  userId,
}: UseSendInviteEmailProps): UseSendInviteEmailArgs => {
  const entityUuid = useSelectedEntityUuid()
  const [sendInviteEmail, { loading }] = useMutation<
    SendInviteEmailResponse,
    SendInviteEmailVariables
  >(SendInviteEmail, {
    onCompleted: () => showSuccessToast(page.settings.users.sentInvitation),
    onError: (error) => showApiErrorToast(error),
  })

  const handleInviteEmail = (): void => {
    sendInviteEmail({
      variables: {
        entityUuid,
        customerUuid: userId,
      },
    })
  }

  return {
    isSending: loading,
    sendInviteEmail: handleInviteEmail,
  }
}
