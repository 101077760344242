import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { decreaseAccountBalance } from 'utils/banking/cacheAccountBalance/cacheAccountBalance'
import { retryOnError } from 'utils/retry'
import { UNEXPECTED_ERROR } from 'types/errors'
import { useSubmitDynamicCrnBpayPaymentMutation } from 'pages/Transfer/BPay/hooks/submitDynamicCrnBpayPayment.generated'
import { useSubmitStaticCrnBpayPaymentMutation } from 'pages/Transfer/BPay/hooks/submitStaticCrnBpayPayment.generated'

interface SubmitBpayTransferArgs {
  isDynamicPayment: boolean
  amountInCents: string
  debitCardAccountUuid: string
  paymentInstrumentUuid: string
  crn: string
}

export const useSubmitBpayTransfer = (retriesLeft: number) => {
  const entityUuid = useSelectedEntityUuid()
  const [submitStaticPayment] = useSubmitStaticCrnBpayPaymentMutation()

  const [submitDynamicPayment] = useSubmitDynamicCrnBpayPaymentMutation()

  const { cache } = useApolloClient()

  // Due to the retry backoff, we need to manually handle loading as there will be times during retry
  // when mutation is not in flight but operation has also not yet completed.
  const [isLoading, setIsLoading] = useState(false)

  const submitBpayTransfer = useCallback(
    async ({
      isDynamicPayment,
      amountInCents,
      debitCardAccountUuid,
      paymentInstrumentUuid,
      crn,
    }: SubmitBpayTransferArgs) => {
      try {
        setIsLoading(true)
        if (isDynamicPayment) {
          const paymentDynamicResponse = await retryOnError(
            () =>
              submitDynamicPayment({
                variables: {
                  entityUuid,
                  input: {
                    paymentInstrumentUuid,
                    debitCardAccountUuid,
                    icrn: crn,
                    amount: {
                      value: amountInCents,
                      currency: 'AUD',
                    },
                  },
                },
              }),
            (error) => {
              return error.message.startsWith('Payment Instrument not found')
            },
            retriesLeft
          )

          if (
            !paymentDynamicResponse.data?.submitDynamicCrnBpayPayment ||
            paymentDynamicResponse.data.submitDynamicCrnBpayPayment.result ===
              null ||
            !paymentDynamicResponse.data.submitDynamicCrnBpayPayment.result.id
          ) {
            return UNEXPECTED_ERROR
          }

          decreaseAccountBalance({
            cache,
            id: debitCardAccountUuid,
            valueInCents: amountInCents,
          })

          return paymentDynamicResponse.data.submitDynamicCrnBpayPayment.result
        }

        const paymentStaticResponse = await retryOnError(
          () =>
            submitStaticPayment({
              variables: {
                entityUuid,
                input: {
                  paymentInstrumentUuid,
                  debitCardAccountUuid,
                  amount: {
                    value: amountInCents,
                    currency: 'AUD',
                  },
                },
              },
            }),
          (error) => {
            return error.message.startsWith('Payment Instrument not found')
          }
        )

        if (
          !paymentStaticResponse.data?.submitStaticCrnBpayPayment ||
          paymentStaticResponse.data.submitStaticCrnBpayPayment.result ===
            null ||
          !paymentStaticResponse.data.submitStaticCrnBpayPayment.result.id
            .length
        ) {
          return UNEXPECTED_ERROR
        }

        decreaseAccountBalance({
          cache,
          id: debitCardAccountUuid,
          valueInCents: amountInCents,
        })

        return paymentStaticResponse.data.submitStaticCrnBpayPayment.result
      } finally {
        setIsLoading(false)
      }
    },
    [cache, retriesLeft, submitDynamicPayment, entityUuid, submitStaticPayment]
  )

  return { submitBpayTransfer, isSubmitBpayLoading: isLoading }
}
