import { gql } from '@apollo/client'

export const GetInvoicesLocalState = gql`
  query GetInvoicesLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      invoices {
        columns
        filter
        sort
        statuses
      }
    }
  }
`
