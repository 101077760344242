import { FileRejection } from 'react-dropzone'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { getDropzoneErrorMessage } from 'utils/getDropzoneErrorMessage'
import { translate } from 'utils/translations'
import { DropZoneErrorType } from 'types/files'

export interface FetchUploadFileToS3BucketArgs {
  s3BucketUrl: string
  file: File
  onSuccess: () => void
  onError: () => void
  signal?: AbortSignal
}

export const fetchUploadFileToS3Bucket = async ({
  s3BucketUrl,
  file,
  onSuccess,
  onError,
  signal,
}: FetchUploadFileToS3BucketArgs) => {
  try {
    const result = await fetch(s3BucketUrl, {
      method: 'PUT',
      body: file,
      signal,
    })

    if (!result.ok) {
      onError()
      return
    }

    onSuccess()
  } catch (err) {
    ErrorLogger.report('[Core] Upload File To S3Bucket', err)
    onError()
  }
}

export const handleOnboardingOnDropReject = (
  error: FileRejection[],
  maxFiles: number
) => {
  showErrorToast(
    getDropzoneErrorMessage(error, {
      [DropZoneErrorType.FileToMany]: translate(
        'form.shared.uploadDocuments.tooManyFilesError',
        { value: maxFiles }
      ),
    })
  )
}
