import { CustomerRole } from '@npco/mp-gql-types'

import { CustomerType as LoggedInUserData } from '../hooks/useCustomerQuery/graphql/getCustomer.types'
import { rvCurrentUserData } from '../hooks/useCustomerQuery/graphql/reactiveVariables'
import * as useLoggedInUserHook from '../hooks/useLoggedInUser/useLoggedInUser'

export const loggedInUserId = '5353e302-aedb-417f-b815-705362ad8b72'

export const getMockLoggedInUserProductTour = (
  overridingValues: NonNullable<
    Partial<LoggedInUserData['productTourStatus']>
  > = {}
) => ({
  showAdminMerchantPortalWelcome: false,
  showCorporateCardsWalkthrough: false,
  showCorporateCardsSettingsWalkthrough: false,
  showInvoiceInstructions: false,
  showInvoiceSendViaInfo: false,
  showInvoicesCustomisationWelcome: false,
  showInvoicesScheduleSendWelcome: false,
  showInvoicesSendBySmsWelcome: false,
  showInvoicesWelcome: false,
  showInvoicingCustomisationSettingsWelcome: false,
  showItemInstructions: false,
  showNotificationsWelcome: false,
  showTapToPayInstructions: false,
  showOnboardingShop: false,
  showCatalogueItemsWelcome: false,
  ...overridingValues,
})

export const getMockLoggedInUserSetupFlow = (
  overridingValues: NonNullable<
    Partial<LoggedInUserData['productTourStatus']>
  > = {}
) => ({
  showAdminMerchantPortalWelcome: true,
  showCorporateCardsWalkthrough: true,
  showCorporateCardsSettingsWalkthrough: true,
  showInvoiceInstructions: true,
  showInvoiceSendViaInfo: true,
  showInvoicesCustomisationWelcome: true,
  showInvoicesScheduleSendWelcome: true,
  showInvoicesSendBySmsWelcome: true,
  showInvoicesWelcome: true,
  showInvoicingCustomisationSettingsWelcome: false,
  showItemInstructions: true,
  showNotificationsWelcome: false,
  showTapToPayInstructions: true,
  showOnboardingShop: false,
  showCatalogueItemsWelcome: false,
  ...overridingValues,
})

export const getMockLoggedInUser = (
  data: Partial<LoggedInUserData> = {}
): LoggedInUserData => ({
  id: loggedInUserId,
  entityUuid: 'c78f99a4-85de-4db8-b1dc-2e9e87a51661',
  email: 'john.doe@example.com',
  emailVerified: null,
  nickname: null,
  firstname: 'John',
  middlename: null,
  lastname: 'Doe',
  phone: null,
  phoneVerified: true,
  role: null,
  address: null,
  director: null,
  secretary: null,
  ceo: null,
  beneficialOwner: null,
  beneficialOwnerAlt: null,
  beneficiary: null,
  partner: null,
  trustee: null,
  settlor: null,
  generalContact: null,
  financialContact: null,
  type: null,
  companyTrustName: null,
  abn: null,
  productTourStatus: getMockLoggedInUserProductTour(),
  isInvitationPending: null,
  registeringIndividual: false,
  kycStatus: null,
  idvAttempts: {
    driversLicence: 0,
    medicareCard: 0,
    passport: 0,
  },
  permissions: {
    allowZellerInvoices: false,
    allowXeroPaymentServices: false,
    allowDiscountManagement: false,
    allowItemManagement: false,
  },
  ...data,
})

export const mockUseLoggedInUser = ({
  isDataAvailable = true,
  setUserData = jest.fn(),
  userData = getMockLoggedInUser(),
  userRole = CustomerRole.ADMIN,
}: Partial<ReturnType<typeof useLoggedInUserHook.useLoggedInUser>> = {}) => {
  jest.spyOn(useLoggedInUserHook, 'useLoggedInUser').mockImplementation(() => ({
    isDataAvailable,
    setUserData,
    userData,
    userRole,
  }))
}

export const setUser = <TCustomer extends Partial<LoggedInUserData>>(
  partialCustomer: TCustomer
): TCustomer => {
  rvCurrentUserData(getMockLoggedInUser(partialCustomer))
  return partialCustomer
}
