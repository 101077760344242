import { TransactionType } from '@npco/mp-gql-types'
import {
  rvLastSelectedDates,
  rvSelectedDates,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { QUnitType } from 'dayjs'
import { descend, identity, pipe, sort, uniqBy } from 'ramda'

import dayjs from 'utils/dayjs'
import { groupByKey } from 'utils/groupByKey'
import { translate } from 'utils/translations'
import { TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { TransactionInList } from 'types/transactions'

const makeGetGroupingKey =
  (groupInterval: QUnitType) => (item: TransactionInList) => {
    const { timestamp } = item

    return String(+dayjs(timestamp).utc().local().startOf(groupInterval))
  }

const sortTransactionGroupsByKeys = (
  transactionGroups: Record<string, TransactionInList[]>
) => {
  const sortedKeys = sort(descend(identity), Object.keys(transactionGroups))

  return sortedKeys.map((key) => transactionGroups[key])
}

export const filterNonNullElements = (
  items: (TransactionInList | null)[]
): TransactionInList[] => items.filter(Boolean) as TransactionInList[]

export const groupTransactions = pipe(
  groupByKey(makeGetGroupingKey('day')),
  sortTransactionGroupsByKeys
)

export const groupTransactionsByYear = pipe(
  groupByKey(makeGetGroupingKey('year')),
  sortTransactionGroupsByKeys
)

export const customDateParse = (item: string | undefined): number => {
  if (typeof item === 'string') {
    return Number(Date.parse(item))
  }
  return 0
}

export const sortDescendingByUpdate = (input: TransactionInList[]) => {
  const diff = (previous: TransactionInList, next: TransactionInList) =>
    customDateParse(next.subscriptionUpdatedAt) -
    customDateParse(previous.subscriptionUpdatedAt)
  return sort(diff, input)
}

export const sortDescendingByTimestamp = (input: TransactionInList[]) => {
  const diff = (previous: TransactionInList, next: TransactionInList) =>
    customDateParse(next.timestamp) - customDateParse(previous.timestamp)
  return sort(diff, input)
}

export const filterUnique = (input: TransactionInList[]) =>
  uniqBy((item) => item.id, input)

export const setLastSelectedDates = (
  widgetKey?: TIME_FILTER_ENABLED_COMPONENTS
) => {
  if (widgetKey) {
    rvLastSelectedDates({
      ...rvLastSelectedDates(),
      [widgetKey]: rvSelectedDates(),
    })
  }
}

export const getTransactionType = (type: string) =>
  type === TransactionType.PURCHASE
    ? translate('component.transaction.sale')
    : translate('component.transaction.refunded')
