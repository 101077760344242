import { COLOR } from '@npco/zeller-design-system'

export const onTooltipRef =
  (tooltipWidth: number, setTooltipWidth: (width: number) => void) =>
  (ref: HTMLDivElement | null) => {
    if (!ref) {
      return
    }

    const { width: refWidth } = ref.getBoundingClientRect()

    const flooredRefWidth = Math.floor(refWidth)

    if (flooredRefWidth !== tooltipWidth) {
      setTooltipWidth(flooredRefWidth)
    }
  }

/**
 * Function to determine the color of the bars in a bar chart.
 *
 * @param {boolean} isLoadingOrDisabled - A flag indicating if the bar is in a
 * loading or disabled state.
 *
 * @returns {string} The color of the bar. Returns COLOR.GREY_60 if the bar is
 * loading or disabled, otherwise returns COLOR.BLUE_1000.
 */
export const getBarColor = (isLoadingOrDisabled: boolean) =>
  isLoadingOrDisabled ? COLOR.GREY_60 : COLOR.BLUE_1000

export const getTransformOriginY = (
  y: number | undefined = 0,
  height: number | undefined = 0
) => {
  return y + height
}
