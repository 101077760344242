import { last } from 'ramda'

import { getDaysFromRange } from 'utils/common'
import { formatDateFullSaveFormat } from 'utils/date'
import dayjs from 'utils/dayjs'
import { DebitCardTransactionAmountUpdate_onDebitCardTransactionUpdate as AmountUpdate } from 'types/gql-types/DebitCardTransactionAmountUpdate'
import { getIsIncoming } from 'pages/Dashboard/utils/debitCardTransactionUpdate'

import { AccountBalanceChartQueryResponse } from './graphql/AccountBalanceChartQuery.generated'

type DebitCardAccountBalances = NonNullable<
  AccountBalanceChartQueryResponse['getDebitCardAccountBalances']
>[0]

const mergeBalancesByDate = (balances: DebitCardAccountBalances[]) => {
  const balanceAmountMap: Record<string, DebitCardAccountBalances> =
    balances.reduce(
      (
        prevMap: { [key: string]: DebitCardAccountBalances },
        balance: DebitCardAccountBalances
      ) => {
        const prevBalance = prevMap[balance.date]
        return {
          ...prevMap,
          [balance.date]: prevBalance
            ? {
                ...prevBalance,
                balance: {
                  value: (
                    parseInt(prevBalance.balance.value, 10) +
                    parseInt(balance.balance.value, 10)
                  ).toString(),
                },
              }
            : { ...balance },
        }
      },
      {}
    )

  return Object.values(balanceAmountMap)
}

export const buildBalances = (
  prevBalances: DebitCardAccountBalances[],
  transactionUpdate: AmountUpdate
) => {
  const latestBalance = last(prevBalances)
  const dateString = formatDateFullSaveFormat(transactionUpdate.timestamp)
  const isAfter = latestBalance && dayjs(dateString).isAfter(latestBalance.date)
  const baseAmount =
    isAfter && latestBalance ? parseInt(latestBalance.balance.value, 10) : 0
  const balanceAmount =
    baseAmount +
    (getIsIncoming(transactionUpdate) ? 1 : -1) *
      parseInt(transactionUpdate.amount.value, 10)

  const dateRanges =
    isAfter && latestBalance
      ? getDaysFromRange(latestBalance.date, dateString).slice(1)
      : [dateString]

  return {
    getDebitCardAccountBalances: mergeBalancesByDate([
      ...prevBalances,
      ...dateRanges.map<DebitCardAccountBalances>((date) => {
        return {
          __typename: 'DebitCardAccountBalance',
          date,
          balance: {
            value:
              date === dateString
                ? balanceAmount.toString()
                : latestBalance?.balance.value || '0',
          },
        }
      }),
    ]),
  }
}
