import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const Popper = styled.ul`
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 11rem;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.GREY_400};
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  list-style: none;
  z-index: 1;
`

export const PopperItem = styled.li`
  align-items: center;
  height: 3rem;
  line-height: 3rem;
  padding-left: 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`
