import { useCallback } from 'react'
import {
  Box,
  InfoBox,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'
import { ThirdPartyBankAccount } from 'types/accounts'
import { DeleteAccountModal } from 'components/DeleteAccountModal/DeleteAccountModal'
import {
  StyledAccountPrimaryAction,
  StyledActionsContainer,
} from 'components/DepositAccountDetailDrawer/AccountDetailAction/AccountDetailAction.styled'
import { ModalConfirm } from 'components/Modal/ModalConfirm/ModalConfirm'

import { useSelectDepositExternalBankListItem } from '../DepositExternalAccountsList/DepositExternalAccountsList.hook'

interface DepositExternalAccountActionProps {
  account: ThirdPartyBankAccount
  redirectOnDeleteSuccess: () => void
  isSelectedAccount: boolean
}

export const DepositExternalAccountAction = ({
  account,
  redirectOnDeleteSuccess,
  isSelectedAccount,
}: DepositExternalAccountActionProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const { changeToExternalAccount, isLoading } =
    useSelectDepositExternalBankListItem()

  const onConfirm = useCallback(async () => {
    if (await changeToExternalAccount(account)) {
      closeModal()
    }
  }, [account, changeToExternalAccount, closeModal])

  if (isSelectedAccount) {
    return (
      <>
        <Box pt="16px">
          <InfoBox size={INFOBOX_SIZE.SMALL} variant={INFOBOX_VARIANT.POSITIVE}>
            <InfoBox.Message>
              {translate('page.settings.deposits.selectedAccount')}
            </InfoBox.Message>
          </InfoBox>
        </Box>
        <DeleteAccountModal
          redirectOnDeleteSuccess={redirectOnDeleteSuccess}
          selectedAccount={account}
        />
      </>
    )
  }

  return (
    <>
      <ModalConfirm
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={onConfirm}
        title={translate(
          'page.settings.deposits.confirmations.setAsDepositAccount.title'
        )}
        description={
          translate(
            'page.settings.deposits.confirmations.setAsDepositAccount.descriptionExternalAccount',
            { accountName: account.accountName }
          ) as string
        }
        isLoading={isLoading}
      />
      <StyledActionsContainer>
        <StyledAccountPrimaryAction onClick={openModal}>
          {translate(
            'page.settings.deposits.confirmations.setAsDepositAccount.action'
          )}
        </StyledAccountPrimaryAction>
        <DeleteAccountModal
          redirectOnDeleteSuccess={redirectOnDeleteSuccess}
          selectedAccount={account}
        />
      </StyledActionsContainer>
    </>
  )
}
