import {
  BodyDefaultTypography,
  BodyLargeTypography,
  BodySmallTypography,
  ButtonGhostIconRight,
  DrawerBasic,
  H2,
  StyledContentContainer,
  StyledGhostButton,
  StyledHeaderContainer,
  StyledIconRight,
} from '@npco/zeller-design-system'
import { Flex } from 'reflexbox'
import styled from 'styled-components'

export const FiltersDrawer = styled(DrawerBasic)`
  padding: 0;

  ${H2} {
    ${BodyLargeTypography}
  }

  ${StyledContentContainer} {
    flex-flow: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
  }

  ${StyledHeaderContainer} {
    padding-top: 12px;
    padding-bottom: 16px;
  }
`

export const OptionsWrapper = styled(Flex)`
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  padding: 0 8px;
  flex: 1 0 50%;
`

export const OptionButton = styled(ButtonGhostIconRight)`
  ${BodyDefaultTypography};
  width: 100%;
  padding: 12px 24px;
  color: ${({ theme }) => theme.colors.BLACK_900};

  ${StyledGhostButton} {
    width: 100%;
  }

  div {
    width: 100%;
  }

  span {
    left: 0;
    width: 100%;
    text-align: left;
  }
  ${StyledIconRight} {
    right: 0;
    width: 16px;
  }
`

export const BottomWrapper = styled(Flex)`
  flex-flow: row nowrap;
  position: relative;
  bottom: 0;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px 16px;

  button {
    width: 100%;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`

export const OptionalContent = styled.span`
  color: ${({ theme }) => theme.colors.BLUE_1000};
  ${BodySmallTypography};
  margin-left: 4px;
`
