import { HeaderContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { InvoiceHeaderCell } from '../InvoiceHeaderCell/InvoiceHeaderCell'
import { AllInvoiceTableColumn, AmountColumnEnum } from '../InvoiceTable.types'
import { AmountLabel, TableColumnPopperWidth } from '../InvoiceTable.utils'
import { AmountFilters } from '../InvoiceTableFilters/AmountFilters/AmountFilters'

export const InvoiceAmountHeaderCell = ({
  header,
  table,
}: HeaderContext<Invoice | null, unknown>) => {
  const { filterInput } = table.options.meta || {}

  const isFiltering = Boolean(filterInput?.amountFilter)

  return (
    <InvoiceHeaderCell
      disablePopper={false}
      header={header}
      isFiltering={isFiltering}
      justifyContent="flex-end"
      label={AmountLabel[header.id as AmountColumnEnum]}
      popperWidth={TableColumnPopperWidth[AllInvoiceTableColumn.NextDue]}
      table={table}
    >
      {({ setIsOpen, setShouldCloseOnOutsideClick }) => (
        <AmountFilters
          setIsOpen={setIsOpen}
          setShouldCloseOnOutsideClick={setShouldCloseOnOutsideClick}
        />
      )}
    </InvoiceHeaderCell>
  )
}
