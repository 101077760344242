import { useContext } from 'react'

import { SourcePickerContext } from './SourcePickerContext'

export const useSourcePicker = () => {
  const context = useContext(SourcePickerContext)

  if (context === null) {
    throw new Error('useSourcePicker must be used within SourcePickerContext')
  }

  return context
}
