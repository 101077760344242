import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import {
  UserAndAccountDetailsCustomerFragmentDoc,
  UserAndAccountDetailsDebitCardAccountV2FragmentDoc,
} from '../../../CardSummary/components/UserAndAccountDetails/UserAndAccountDetails.generated'

export type DebitCardDetailsDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    balance: { __typename?: 'Money'; value: string } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
    } | null
  }
  customer: {
    __typename: 'Customer'
    id: string
    entityUuid: string | null
    firstname: string | null
    lastname: string | null
    icon: {
      __typename?: 'Icon'
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
}

export const DebitCardDetailsDebitCardV2FragmentDoc = gql`
  fragment DebitCardDetailsDebitCardV2Fragment on DebitCardV2 {
    debitCardAccount {
      __typename
      ...UserAndAccountDetailsDebitCardAccountV2Fragment
      balance {
        value
      }
    }
    customer {
      id
      entityUuid
      __typename
      ...UserAndAccountDetailsCustomerFragment
    }
    status
  }
  ${UserAndAccountDetailsDebitCardAccountV2FragmentDoc}
  ${UserAndAccountDetailsCustomerFragmentDoc}
` as unknown as TypedDocumentNode<
  DebitCardDetailsDebitCardV2Fragment,
  undefined
>
