import { useCallback, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  ErrorMessageForm,
  SelectSize,
  SelectStandard,
  useModalState,
} from '@npco/zeller-design-system'
import { useField, useFormikContext } from 'formik'

import { AddBpayDetailsModal } from 'pages/Transfer/BPay/AddBpayDetailsModal/AddBpayDetailsModal'
import { TransferBpayFields } from 'pages/Transfer/Transfer.types'
import { BpayDetailsItemHeader } from 'pages/Transfer/TransferFields/fields/BpayDetails/components/BpayDetailsItemHeader/BpayDetailsItemHeader'

import { BpayAvatar } from './BpayAvatar'
import { bpayDetailsFieldTranslations } from './BpayDetailsField.i18n'
import { useBpayDetailsField } from './hooks/useBpayDetailsField'
import { useBpayDetailsValidate } from './useBpayDetailsValidate'

export const BpayDetailsField = () => {
  const t = useTranslations(bpayDetailsFieldTranslations)

  const { setFieldValue, values: transferFormValues } =
    useFormikContext<TransferBpayFields>()

  const [paymentInstrumentUuid, meta, { setValue, setTouched }] = useField({
    name: 'paymentInstrumentUuid',
    validate: useBpayDetailsValidate(),
  })
  const [contactOrBiller] = useField<TransferBpayFields['to']>({
    name: 'to',
  })

  const items = useMemo(() => {
    return (
      contactOrBiller.value?.billerContacts?.map((contact) => ({
        id: contact.id,
        label: contact.nickname ?? contact.name,
        subLabel1: contact.code,
        subLabel2: contact.reference,
        value: contact.id,
        type: contact?.reference ? 'static' : 'dynamic',
        image: <BpayAvatar />,
      })) ?? []
    )
  }, [contactOrBiller.value?.billerContacts])

  const { isModalOpen, closeModal, openModal } = useModalState()

  const handleModalOpen = useCallback(() => {
    setFieldValue('paymentInstrumentUuid', undefined, false)
    openModal()
  }, [setFieldValue, openModal])

  const { handleAddBpayModelDone } = useBpayDetailsField({
    transferFormValues,
    setFieldValue,
  })

  return (
    <>
      <SelectStandard
        hasError={!!meta.error}
        label={t('label')}
        size={SelectSize.Large}
        items={items}
        onChange={setValue}
        onClose={() => setTouched(true)}
        selectedItem={items?.find(
          (item) => item.id === paymentInstrumentUuid.value?.id
        )}
        renderAdditionalControls={() => (
          <BpayDetailsItemHeader addBpayDetails={handleModalOpen} />
        )}
      />
      <ErrorMessageForm hasError={!!meta.error} errorMessage={meta.error} />
      {isModalOpen && (
        <AddBpayDetailsModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          onDone={handleAddBpayModelDone}
          contactName={transferFormValues?.to?.name}
        />
      )}
    </>
  )
}
