import { gql } from '@apollo/client'

import { getContactOrMerchantLocationData } from '../utils/contacts.utils'
import { AddressItem } from './Items/AddressItem'
import { JobTitleItem } from './Items/JobTitleItem'
import { EmailItem } from './Items/MerchantSecondaryDetailsItems/EmailItem'
import { getEmail } from './Items/MerchantSecondaryDetailsItems/MerchantSecondaryDetailsItems.utils'
import { MobileItem } from './Items/MobileItem'
import {
  PersonContactDetailsContactFragment,
  PersonContactDetailsMerchantDetailsFragment,
} from './PersonContactDetails.generated'

interface PersonContactDetailsProps {
  merchant?: PersonContactDetailsMerchantDetailsFragment | null
  contact: PersonContactDetailsContactFragment | null
}

export const PersonContactDetails = ({
  merchant,
  contact,
}: PersonContactDetailsProps) => {
  const { address } = getContactOrMerchantLocationData({
    contact,
    merchant,
  })

  const email = getEmail(merchant, contact)

  return (
    <>
      <AddressItem address={address} />
      <MobileItem mobile={merchant?.phone || contact?.phoneV2?.phone} />
      <EmailItem email={email} />
      <JobTitleItem jobTitle={contact?.jobTitle} />
    </>
  )
}

PersonContactDetails.fragments = {
  MerchantDetails: gql`
    fragment PersonContactDetailsMerchantDetailsFragment on MerchantDetails {
      ...GetContactOrMerchantLocationDataMerchantDetailsFragment
      ...GetEmailMerchantDetailsFragment
      phone
    }

    ${getContactOrMerchantLocationData.fragments.MerchantDetails}
    ${getEmail.fragments.MerchantDetails}
  `,
  Contact: gql`
    fragment PersonContactDetailsContactFragment on Contact {
      ...GetContactOrMerchantLocationDataContactFragment
      ...GetEmailContactFragment
      phone
      phoneV2 {
        phone
      }
      jobTitle
    }

    ${getContactOrMerchantLocationData.fragments.Contact}
    ${getEmail.fragments.Contact}
  `,
}
