import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardSingleManageDropdownDebitCardV2FragmentDoc } from '../CardSingleManageDropdown/CardSingleManageDropdown.generated'

export type CardSingleLayoutDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  accessibleProfile: boolean | null
  name: string
  status: Types.DebitCardStatus
  colour: Types.DebitCardColour
  format: Types.DebitCardFormat | null
  productType: Types.DebitCardProductType | null
  debitCardAccount: { __typename?: 'DebitCardAccountV2'; id: string }
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
    firstname: string | null
  } | null
  velocityControl: {
    __typename?: 'VelocityControlType'
    velocityWindow: Types.VelocityWindowEnum
    amountLimit: { __typename?: 'Money'; value: string }
    maxTransactionValue: { __typename?: 'Money'; value: string }
    modifiedBy: {
      __typename?: 'VelocityControlModifiedByType'
      actingCustomerUuid: string
      updatedAt: any
    } | null
  } | null
}

export const CardSingleLayoutDebitCardV2FragmentDoc = gql`
  fragment CardSingleLayoutDebitCardV2Fragment on DebitCardV2 {
    id
    ...CardSingleManageDropdownDebitCardV2Fragment
  }
  ${CardSingleManageDropdownDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardSingleLayoutDebitCardV2Fragment,
  undefined
>
