import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { sortAscendingByProperty } from 'utils/common'
import { Customer } from 'types/customers'

const filterOnlyManagers = (customer: Customer) =>
  customer.role === CustomerRole.MANAGER
const filterByNonAssignedCustomers =
  (alreadyAssignedCustomers: Customer[]) => (customer: Customer) =>
    !alreadyAssignedCustomers.some((user) => user.id === customer.id)

export const useUsersToAssignForSite = (allCustomers: Customer[]) => {
  const alreadyAssignedCustomers = useReactiveVar(rvSiteDetails)?.customers

  return useMemo(() => {
    if (!allCustomers) {
      return []
    }

    return sortAscendingByProperty<Customer>('firstname')(allCustomers)
      .filter(filterOnlyManagers)
      .filter(filterByNonAssignedCustomers(alreadyAssignedCustomers ?? []))
  }, [allCustomers, alreadyAssignedCustomers])
}
