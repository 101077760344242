import { useEffect, useState } from 'react'
import {
  Animation,
  fadeInHeightAnimationVariants,
} from '@npco/zeller-design-system'
import Tippy from '@tippyjs/react/headless'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { BREAKPOINT } from 'styles/breakpoints'

import { SignupTooltipContents } from '../SignupTooltipContents/SignupTooltipContents'

interface SignupPortalProps {
  isVisible: boolean
  forceOpen: boolean
  children: React.ReactElement
}

export const SignupTooltipWrapper = ({
  children,
  isVisible,
  forceOpen,
}: SignupPortalProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.MD)
  const [isTooltipMounted, setIsTooltipMounted] = useState(false)

  const onExitComplete = () => {
    setIsTooltipMounted(false)
  }
  useEffect(() => {
    if (isVisible) {
      setIsTooltipMounted(true)
    }
  }, [isVisible])

  if (isMobile) {
    return (
      <>
        {children}
        <Animation
          shouldShow={isVisible || forceOpen}
          variants={fadeInHeightAnimationVariants}
        >
          <SignupTooltipContents />
        </Animation>
      </>
    )
  }

  return (
    <Tippy
      visible={isTooltipMounted || forceOpen}
      placement="right-start"
      offset={[0, 8]}
      render={() => (
        <Animation
          shouldShow={isVisible || forceOpen}
          variants={fadeInHeightAnimationVariants}
          onExitComplete={onExitComplete}
        >
          <SignupTooltipContents />
        </Animation>
      )}
    >
      {children}
    </Tippy>
  )
}
