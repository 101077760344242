import { useRef } from 'react'
import { Box, InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { useFormValidation } from '../../../utils/useFormValidation/useFormValidation'
import { RemittanceData } from '../../TransferRemittancesModal.types'
import { StyledCheckbox } from './EmailRemittanceFields.styled'

type EmailRemittanceFieldsProps = {
  placeholder: string
  checkBoxDescription: string
  currentUserEmail: string
}

export const EmailRemittanceFields = ({
  placeholder,
  checkBoxDescription,
  currentUserEmail,
}: EmailRemittanceFieldsProps) => {
  const wrapperRef = useRef<HTMLInputElement | null>(null)

  const { touched, errors, values, setFieldValue } =
    useFormikContext<RemittanceData>()

  const { validateEmail } = useFormValidation()

  const isCheckboxDisabled = values.email === currentUserEmail

  return (
    <>
      <Box>
        <InputAdaptiveField
          hasError={Boolean(touched.email && errors.email)}
          aria-labelledby={placeholder}
          label={placeholder}
          wrapperRef={wrapperRef}
          validate={(value) => validateEmail(value)}
          onChange={() => {
            setFieldValue('email', wrapperRef.current?.value)
          }}
          name="email"
        />
      </Box>
      {/* Hiding the checkbox for the first release */}
      <Box hidden>
        <StyledCheckbox
          name="emailCheckbox"
          checked={isCheckboxDisabled ? false : values.emailCheckbox}
          onChange={() => {
            setFieldValue('emailCheckbox', !values.emailCheckbox)
          }}
          disabled={isCheckboxDisabled}
        >
          {checkBoxDescription}
        </StyledCheckbox>
      </Box>
    </>
  )
}
