import { useMemo } from 'react'
import {
  Contacts,
  InputSelectComboboxItemBasic,
} from '@npco/zeller-design-system'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { getAllCustomerEmails } from '../InvoiceFormAccordions.utils'

export interface EmailInputSelectComboboxItem
  extends InputSelectComboboxItemBasic {
  id: string
  subLabel: string
}

export const useEmailSelectCombobox = (
  inputValue: string,
  excludeFromFilteredItems?: string[]
) => {
  const {
    values: {
      customer: { payerContact, attentionToContact },
    },
  } = useFormikContext<InvoiceFormFields>()
  const { readFromCache } = useContactCache()

  const payerFromCache = readFromCache(payerContact?.contactUuid)
  // Grab attention to contact from cache to populate all available emails to select
  const attentionToContactFromCache = readFromCache(
    attentionToContact?.contactUuid,
    true
  )

  const allEmailItems: EmailInputSelectComboboxItem[] = useMemo(
    () => getAllCustomerEmails(payerFromCache, attentionToContactFromCache),
    [attentionToContactFromCache, payerFromCache]
  )

  const filteredItems: EmailInputSelectComboboxItem[] = useMemo(() => {
    return allEmailItems.filter(
      (item) =>
        item.label.trim().toUpperCase().includes(inputValue.toUpperCase()) &&
        !excludeFromFilteredItems?.includes(item.value)
    )
  }, [allEmailItems, excludeFromFilteredItems, inputValue])

  return {
    filteredItems,
    renderComboboxItem: Contacts.renderComboboxItem,
  }
}
