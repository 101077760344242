import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledText = styled.span`
  color: ${({ theme }) => theme.colors.BLACK};

  a {
    color: ${({ theme }) => theme.colors.GREY_470};

    &:hover {
      text-decoration-color: ${({ theme }) => theme.colors.GREY_470};
    }
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: block;
    white-space: pre-wrap;
    width: 100%;
  }
`
