import { ModalSize } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { MultiStageModal } from 'components/MultiStageModal'

import { useCreateAccountStage } from '../hooks/useCreateAccountStage/useCreateAccountStage'
import { useGoToCreateAccountStage } from '../hooks/useGoToCreateAccountStage/useGoToCreateAccountStage'
import { AccountNameStage } from './AccountNameStage/AccountNameStage'

export const CreateAccountModal = () => {
  const { stage } = useCreateAccountStage()
  const { goToCreateAccountStage } = useGoToCreateAccountStage()

  if (stage === null) {
    return null
  }

  return (
    <MultiStageModal
      title={translate('page.createAccountModal.modalTitle')}
      isOpen
      onCancel={() => goToCreateAccountStage(undefined)}
      hasCloseButton
      size={ModalSize.SMALL}
    >
      <AccountNameStage />
    </MultiStageModal>
  )
}
