import useOnclickOutside from 'react-cool-onclickoutside'
import { Box, Flex } from '@npco/zeller-design-system'

import { ExpensesItem } from 'components/Sidebar/Elements/ExpensesItem'
import { PosLiteItem } from 'components/Sidebar/Elements/PosLiteItem'
import { SitesItem } from 'components/Sidebar/Elements/SitesItem'
import { VirtualTerminalItem } from 'components/Sidebar/Elements/VirtualTerminalItem'

import { MobileNavHeader } from './components/MobileNavHeader/MobileNavHeader'
import { SidebarHeader } from './components/SidebarHeader/SidebarHeader'
import { SidebarItemSkeleton } from './components/SidebarItemSkeleton/SidebarItemSkeleton'
import { AccountsItem } from './Elements/AccountsItem'
import { CardsItem } from './Elements/CardsItem'
import { ContactsItem } from './Elements/ContactsItem'
import { DashboardItem } from './Elements/DashboardItem'
import { InvoicesItem } from './Elements/InvoicesItem'
import { ItemsItem } from './Elements/ItemsItem'
import { LogoutItem } from './Elements/LogoutItem'
import { NeedHelpItem } from './Elements/NeedHelpItem'
import { NotificationsItem } from './Elements/NotificationsItem'
import { PaymentsItem } from './Elements/PaymentsItem'
import { ReferralItem } from './Elements/ReferralItem'
import { ReportsItem } from './Elements/ReportsItem'
import { SettingsItem } from './Elements/SettingsItem'
import { ShopItem } from './Elements/ShopItem'
import { useSidebarLocalCache } from './hooks/useSidebarLocalCache'
import { useFoldOnMobile } from './Sidebar.hooks'
import * as styled from './Sidebar.styled'

type SidebarProps = {
  isLoading?: boolean
}

export const Sidebar = ({ isLoading }: SidebarProps) => {
  useFoldOnMobile()
  const { isFolded, handleClickOutside } = useSidebarLocalCache()

  const ref = useOnclickOutside(() => handleClickOutside())

  return (
    <Flex
      flexDirection="column"
      width="100%"
      height="100%"
      data-testid="sidebar-container"
    >
      {!isFolded && <MobileNavHeader />}
      <styled.Container
        $isFolded={isFolded}
        data-testid="sidebar"
        id="menu-sidebar"
      >
        <Box ref={ref} width="100%">
          <SidebarHeader />
          {isLoading ? (
            <>
              <SidebarItemSkeleton isFolded={isFolded} />
              <SidebarItemSkeleton isFolded={isFolded} />
              <SidebarItemSkeleton isFolded={isFolded} />
              <SidebarItemSkeleton isFolded={isFolded} />
              <SidebarItemSkeleton isFolded={isFolded} />
            </>
          ) : (
            <>
              <DashboardItem />
              <PaymentsItem />
              <AccountsItem />
              <CardsItem />
              <InvoicesItem />
              <VirtualTerminalItem />
              <PosLiteItem />
              <ItemsItem />
              <NotificationsItem />
              <ExpensesItem />
              <ReportsItem />
              <ContactsItem />
              <SitesItem />
              <SettingsItem />
            </>
          )}
        </Box>
        {!isLoading && (
          <Box width="100%">
            <ShopItem />
            <ReferralItem />
            <NeedHelpItem />
            <LogoutItem />
          </Box>
        )}
      </styled.Container>
    </Flex>
  )
}
