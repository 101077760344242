import { translate } from 'utils/translations'

export const title = translate(
  'page.upgradeAccountsCompleteModal.readyToGo.title'
)

export const description = translate(
  'page.upgradeAccountsCompleteModal.readyToGo.description'
)

export const primaryButtonLabel = translate(
  'page.upgradeAccountsCompleteModal.readyToGo.primaryButtonLabel'
)
