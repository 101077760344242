import { ReactNode } from 'react'

import * as styled from './InvoiceSendSuccessModal.styled'

const DESCRIPTION_REGEX = /{(referenceNumber)}|{(payerName)}/

export const getFormattedDescription = ({
  referenceNumber,
  payerName = '',
  description,
}: {
  referenceNumber: string
  payerName?: string
  description: string
}) => {
  return description
    .split(DESCRIPTION_REGEX)
    .reduce<ReactNode[]>((acc, curr) => {
      switch (curr) {
        case 'referenceNumber':
          acc.push(
            <styled.DescriptionValue
              data-testid="modal-description-reference-number"
              key={`${curr}-${referenceNumber}`}
            >
              {referenceNumber}
            </styled.DescriptionValue>
          )
          break
        case 'payerName':
          acc.push(
            <styled.DescriptionValue
              data-testid="modal-description-payer-name"
              key={`${curr}-${payerName}`}
            >
              {payerName}
            </styled.DescriptionValue>
          )
          break
        default:
          acc.push(curr)
      }

      return acc
    }, [])
}
