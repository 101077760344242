import { H5Style } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ToggleLabel = styled.label`
  ${H5Style}
  margin-right: 8px;
`

export const TooltipMessage = styled.div`
  text-align: center;
`
