import { useCallback, useState } from 'react'
import {
  AmountLabel,
  DateLabel,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { InvoiceTableMobileBottomDrawer } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileBottomDrawer/InvoiceTableMobileBottomDrawer'
import { InvoiceTableMobileFiltersDrawer } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { AmountColumnEnum, DateColumnEnum } from '../../InvoiceTable.types'
import {
  HeaderActiveItem,
  HeaderWrapper,
  MobileHeaderItem,
} from './InvoiceMobileHeaderCell.styled'

export const InvoiceMobileHeaderCell = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedType, setSelectedType] = useState<'amount' | 'date'>('amount')

  const {
    selectedAmountColumn,
    selectedDateColumn,
    setSelectedAmountColumn,
    setSelectedDateColumn,
  } = useInvoicesContext()

  const onSelectedDate = useCallback(
    (value: DateColumnEnum) => {
      setIsDrawerOpen(false)
      setSelectedDateColumn(value)
    },
    [setIsDrawerOpen, setSelectedDateColumn]
  )

  const onSelectedAmount = useCallback(
    (value: AmountColumnEnum) => {
      setIsDrawerOpen(false)
      setSelectedAmountColumn(value)
    },
    [setIsDrawerOpen, setSelectedAmountColumn]
  )

  const onDateClick = useCallback(() => {
    setSelectedType('date')
    setIsDrawerOpen(true)
  }, [setSelectedType, setIsDrawerOpen])

  const onAmountClick = useCallback(() => {
    setSelectedType('amount')
    setIsDrawerOpen(true)
  }, [setSelectedType, setIsDrawerOpen])

  return (
    <>
      <HeaderWrapper>
        <MobileHeaderItem onClick={onDateClick} dataTestId="date-header-button">
          Date:
          <HeaderActiveItem>{DateLabel[selectedDateColumn]}</HeaderActiveItem>
        </MobileHeaderItem>
        <MobileHeaderItem
          onClick={onAmountClick}
          dataTestId="amount-header-button"
        >
          Amount:
          <HeaderActiveItem>
            {AmountLabel[selectedAmountColumn]}
          </HeaderActiveItem>
        </MobileHeaderItem>
      </HeaderWrapper>
      <InvoiceTableMobileBottomDrawer
        isOpen={isDrawerOpen}
        type={selectedType}
        onSelectedDate={onSelectedDate}
        onSelectedAmount={onSelectedAmount}
      />
      <InvoiceTableMobileFiltersDrawer />
    </>
  )
}
