import { useCallback } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useAddMarketingModalSeenMutation } from '../graphql/addMarketingModalSeen.generated'

export const useMarketingModalVisibility = (slug?: string) => {
  const entityUuid = useSelectedEntityUuid()

  const [marketingModalSeenMutation] = useAddMarketingModalSeenMutation({
    errorPolicy: 'all',
  })
  const hideModal = useCallback(async () => {
    if (slug) {
      await marketingModalSeenMutation({
        variables: {
          marketingModalName: slug,
          entityUuid,
        },
      })
    }
  }, [marketingModalSeenMutation, slug, entityUuid])

  return {
    hideModal,
  }
}
