import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, Divider, useModalState } from '@npco/zeller-design-system'

import { getCustomerDisplayName } from 'utils/customers'
import { Title } from 'layouts'
import { page } from 'translations'

import {
  UserRemoveCustomerFragment,
  UserRemoveDebitCardV2Fragment,
  UserRemoveSiteFragment,
} from './UserRemove.generated'
import { userRemoveTranslations } from './UserRemove.i18n'
import { StyledDeleteButton } from './UserRemove.styled'
import { UserRemoveModal } from './UserRemoveModal/UserRemoveModal'

const { deleteUser, removeUser } = page.settings.users

interface UserRemoveProps {
  deleteCustomer: () => void
  registeringIndividual: boolean
  loadingDeleteCustomer: boolean
  associatedCards: UserRemoveDebitCardV2Fragment[]
  associatedSites: UserRemoveSiteFragment[]
  isCardsLoading: boolean
  isSitesLoading: boolean
  isCustomerDetailsLoading: boolean
  customerDetails?: UserRemoveCustomerFragment
}

export const UserRemove = ({
  deleteCustomer,
  registeringIndividual,
  loadingDeleteCustomer,
  associatedCards,
  associatedSites,
  isCardsLoading,
  isSitesLoading,
  isCustomerDetailsLoading: isCustomerLoading,
  customerDetails: data,
}: UserRemoveProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const t = useTranslations(userRemoveTranslations)

  return (
    <div data-testid="users-remove">
      <Divider />
      <Title>{removeUser}</Title>
      <Box p="0 0.125rem 1.5rem" mt="-1rem" width="auto">
        <StyledDeleteButton
          type="button"
          isLoading={isCardsLoading || isSitesLoading || isCustomerLoading}
          disabled={
            Boolean(registeringIndividual) ||
            isCardsLoading ||
            isSitesLoading ||
            isCustomerLoading
          }
          onClick={openModal}
        >
          {deleteUser}
        </StyledDeleteButton>
      </Box>

      <UserRemoveModal
        associatedSites={associatedSites}
        userName={
          data?.firstname && data?.lastname
            ? getCustomerDisplayName(data)
            : t('nameFallback')
        }
        associatedCards={associatedCards}
        isOpen={isModalOpen}
        onCancel={closeModal}
        isLoadingPrimary={loadingDeleteCustomer}
        isDisabledPrimary={loadingDeleteCustomer}
        onRemove={deleteCustomer}
      />
    </div>
  )
}

UserRemove.fragments = {
  Customer: gql`
    fragment UserRemoveCustomerFragment on Customer {
      id
      entityUuid
      firstname
      lastname
    }
  `,
  DebitCardV2: gql`
    fragment UserRemoveDebitCardV2Fragment on DebitCardV2 {
      ...UserRemoveModalDebitCardV2Fragment
    }
    ${UserRemoveModal.fragments.DebitCardV2}
  `,
  Site: gql`
    fragment UserRemoveSiteFragment on Site {
      ...UserRemoveModalSiteFragment
    }
    ${UserRemoveModal.fragments.Site}
  `,
}
