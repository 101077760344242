import { Box, Flex } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { translate } from 'utils/translations'
import { ReceiptDetails } from 'forms/formViews/Receipt/ReceiptDetails'
import { SpinnerWrapped } from 'components/Spinner'

import { FormButtons } from '../Components/FormButtons'
import { ReceiptCompanyLogo } from '../Components/ReceiptCompanyLogo'
import { ReceiptEditFormClosePrompt } from '../Components/ReceiptEditFormClosePrompt'
import { ReceiptFormFields } from './Receipt.types'
import { getInitialValues } from './Receipt.utils'
import { useReceiptHook } from './useReceiptsHook'

export const Receipt = () => {
  const {
    siteSettings,
    handleOnSubmit,
    isUpdatingXeroSiteSettings,
    isLoadingXeroSiteSettings,
  } = useReceiptHook()

  if (isLoadingXeroSiteSettings || !siteSettings?.receipt) {
    return <SpinnerWrapped variant="centre" />
  }

  return (
    <Formik<ReceiptFormFields>
      initialValues={getInitialValues(siteSettings)}
      onSubmit={handleOnSubmit}
    >
      {({ submitForm, resetForm, values }) => (
        <>
          <Flex
            flexDirection="column"
            paddingBottom="32px"
            gridGap="24px"
            data-testid="xero-payment-services-receipt-container"
          >
            <ReceiptCompanyLogo />
            <Box>
              <ReceiptDetails
                heading={translate(
                  'page.settings.connections.xeroPaymentServices.receipt.receiptHeading'
                )}
                messageValue={values.message}
                returnsMessageValue={values.returnsMessage}
                showBusinessNameSubtitle
              />
              <FormButtons
                onPrimaryButtonClick={submitForm}
                onSecondaryButtonClick={resetForm}
                isLoading={isUpdatingXeroSiteSettings}
              />
            </Box>
          </Flex>
          <ReceiptEditFormClosePrompt />
        </>
      )}
    </Formik>
  )
}
