import { useCallback } from 'react'
import { CustomerRole, KycStatus } from '@npco/mp-gql-types'
import {
  Animation,
  Heading,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
  RadioGroupBasic,
  RadioListCard,
  slideDownAnimationVariants,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { CustomerDetailsForm } from 'types/customers'

import { UserPermissionInfoBoxDescription } from './UserPermissions.styled'

const { ADMIN, MANAGER } = CustomerRole

interface UserPermissionsProps {
  isEditForm?: boolean
}

export const UserPermissions = ({ isEditForm }: UserPermissionsProps) => {
  const { values, setValues } = useFormikContext<CustomerDetailsForm>()
  const isRCAbandoned =
    values.role === ADMIN && values.kycStatus === KycStatus.RC_ABANDONED

  const handleToggleChange = useCallback(
    (value: string) => {
      const role = value as CustomerRole
      const isAdmin = value === ADMIN
      setValues({
        ...values,
        allowZellerInvoices: isAdmin,
        allowItemManagement: isAdmin,
        allowDiscountManagement: isAdmin,
        role,
      })
    },
    [setValues, values]
  )

  return (
    <>
      <Heading.H3 mb="24px">
        {translate('page.settings.users.userPermissions')}
      </Heading.H3>

      <RadioGroupBasic
        gap="8px"
        isVerticalDisplay
        name="role"
        onChange={handleToggleChange}
        value={values.role ?? CustomerRole.MANAGER}
      >
        <RadioListCard
          id="admin"
          name="type"
          title={translate('page.settings.users.admin')}
          value={ADMIN}
        />
        <RadioListCard
          id="manager"
          name="type"
          title={translate('page.settings.users.manager')}
          value={MANAGER}
        />
      </RadioGroupBasic>

      <Animation
        shouldShow={isEditForm && isRCAbandoned}
        variants={slideDownAnimationVariants}
      >
        <InfoBox
          dataTestId="info-box"
          priority={INFOBOX_PRIORITY.MEDIUM}
          variant={INFOBOX_VARIANT.NEGATIVE}
        >
          <UserPermissionInfoBoxDescription>
            {translate('page.settings.users.permissions.description1')}
          </UserPermissionInfoBoxDescription>
          <UserPermissionInfoBoxDescription>
            {translate('page.settings.users.permissions.description2')}
          </UserPermissionInfoBoxDescription>
        </InfoBox>
      </Animation>
    </>
  )
}
