import { gql } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'

import { currencyFormatter } from 'utils/common'
import { translate } from 'utils/translations'

import {
  CardTooltipDebitCardAccountV2Fragment,
  GetAccountValueLabelDebitCardAccountV2Fragment,
} from './ListItemAccounts.utils.generated'

export const getAccountValueLabel = (
  account: GetAccountValueLabelDebitCardAccountV2Fragment
) => {
  if (account.status === DebitCardAccountStatus.CLOSED) {
    return translate('shared.cardStatus.closed')
  }

  if (account.status === DebitCardAccountStatus.SUSPENDED) {
    return translate('shared.cardStatus.suspended')
  }

  return currencyFormatter(parseInt(account?.balance?.value ?? '0', 10))
}

export const cardToolTip = (
  account: CardTooltipDebitCardAccountV2Fragment
): string[] => {
  const debitCards = account?.cardsCount?.debit
    ? translate('shared.cardBalance.debit', {
        amount: account?.cardsCount.debit,
      })
    : ''
  const expenseCards = account?.cardsCount?.expense
    ? translate('shared.cardBalance.expense', {
        amount: account?.cardsCount.expense,
      })
    : ''
  return [debitCards, expenseCards].filter(Boolean)
}

cardToolTip.fragments = {
  DebitCardAccountV2: gql`
    fragment CardTooltipDebitCardAccountV2Fragment on DebitCardAccountV2 {
      cardsCount {
        debit
        expense
      }
    }
  `,
}

getAccountValueLabel.fragments = {
  DebitCardAccountV2: gql`
    fragment GetAccountValueLabelDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status
      balance {
        value
      }
    }
  `,
}
