import { gql } from '@apollo/client'

export const GetUpdatedOutstandingTransactionsLocalState = gql`
  query GetUpdatedOutstandingTransactionsLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      updatedOutstandingTransactions {
        transaction
        timestamp
      }
    }
  }
`
