import { ModalBasic } from '@npco/zeller-design-system'

import { useGoToCancelCardStage } from 'hooks/useGoToCancelCardStage/useGoToCancelCardStage'
import { translate } from 'utils/translations'

import { CancelCard } from './CancelCard/CancelCard'
import { useCancelCardState } from './hooks/useCancelCardState'

export const CancelCardModal = () => {
  const { state } = useCancelCardState()
  const { goToCancelCardStage } = useGoToCancelCardStage()

  if (state === null) {
    return null
  }

  return (
    <ModalBasic
      isOpen
      onCancel={() => goToCancelCardStage(undefined)}
      title={translate('page.cancelCardModal.title')}
    >
      <CancelCard />
    </ModalBasic>
  )
}
