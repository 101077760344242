import { COLOR, Flex, SkeletonBasic, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ImageBroken } from 'assets/svg/image-broken.svg'

import { StyledImage } from './ImagePreview.styled'
import { ImagePreviewProps } from './ImagePreview.types'

export const ImagePreview = ({
  src,
  isLoading,
  isBroken,
  onError,
  tabIndex = 0,
}: ImagePreviewProps) => {
  if (isLoading) {
    return <SkeletonBasic showOnLoadAnimation />
  }

  if (isBroken) {
    return (
      <Flex
        justifyContent="center"
        width="100%"
        height="100%"
        borderRadius="4px"
        backgroundColor={COLOR.GREY_60}
      >
        <SvgIcon
          width="40"
          height="40"
          color={COLOR.GREY_470}
          dataTestId="broken-image"
        >
          <ImageBroken />
        </SvgIcon>
      </Flex>
    )
  }

  return (
    <StyledImage
      data-testid="image-preview"
      src={src}
      alt="image"
      tabIndex={tabIndex}
      onError={onError}
    />
  )
}
