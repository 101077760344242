import { gql } from '@apollo/client'

import { CardAvatar } from './CardAvatar'
import { ZellerCardThumbnailDebitCardV2Fragment } from './ZellerCardThumbnail.generated'

interface ZellerCardThumbnailProps {
  card: ZellerCardThumbnailDebitCardV2Fragment
  isDisabled?: boolean
}

export const ZellerCardThumbnail = ({
  card,
  isDisabled,
}: ZellerCardThumbnailProps) => {
  return <CardAvatar card={card} isDisabled={isDisabled || false} />
}

ZellerCardThumbnail.fragments = {
  DebitCardV2: gql`
    fragment ZellerCardThumbnailDebitCardV2Fragment on DebitCardV2 {
      ...CardAvatarDebitCardV2Fragment
    }

    ${CardAvatar.fragments.DebitCardV2}
  `,
}
