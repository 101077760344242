import { useEffect, useState } from 'react'
import {
  Flex,
  PopperMobileTitleGroup,
  StyledPopperModalMobile,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_DATE } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { useDateFilterDatepicker } from 'layouts/AccountLayout/hooks/useDateFilterDatepicker/useDateFilterDatepicker'
import { SelectMobileFilterFooter } from 'components/FiltersList/SelectMobileFilterFooter/SelectMobileFilterFooter'
import { page } from 'translations'

import { DatePickerRangeForFilter } from '../../filters/DatePickerRangeForFilter'
import { getDoesMonthIncludeDaysOutsideRange } from './AccountMobileFilterDate.utils'
import { AccountMobileFilterDateTrigger } from './AccountMobileFilterDateTrigger/AccountMobileFilterDateTrigger'
import { DateRangeLimitInfoBox } from './DateRangeLimitInfoBox/DateRangeLimitInfoBox'

const filterName = page.accounts.transactionsList.filters.date

export const AccountMobileFilterDate = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { date, setDate, resetDate } = useAccountFilters()
  const [localDate, setLocalDate] = useState(date)
  const resetLocalDate = () => {
    setLocalDate(DEFAULT_DATE)
  }

  const {
    data,
    enteredTo,
    selectedPeriod,
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleClearKeyDown,
    onPeriodChange,
    disabledDays,
  } = useDateFilterDatepicker({
    isOpen,
    date: localDate,
    setDate: setLocalDate,
    resetDate: resetLocalDate,
  })

  const onApply = () => {
    setIsOpen(false)
    setDate(localDate)
  }

  const onReset = () => {
    resetLocalDate()
    resetDate()
  }

  const onCancel = () => {
    setIsOpen(false)
    setLocalDate(date)
  }

  useEffect(() => {
    setLocalDate(date)
  }, [date])

  return (
    <>
      <AccountMobileFilterDateTrigger
        openPopper={() => setIsOpen(true)}
        date={date}
      />
      <StyledPopperModalMobile
        isOpen={isOpen}
        overlayClassName="modal-basic-overlay"
      >
        <Flex flexDirection="column" height="100%" overflowY="scroll">
          <PopperMobileTitleGroup onClick={onCancel}>
            {translate(
              'page.accounts.transactionsList.filters.filterMobileHeader',
              {
                filterName,
              }
            )}
          </PopperMobileTitleGroup>
          <DatePickerRangeForFilter
            data={data}
            enteredTo={enteredTo}
            selectedPeriod={selectedPeriod}
            handleResetClick={handleResetClick}
            handleDayClick={handleDayClick}
            handleDayMouseEnter={handleDayMouseEnter}
            handleDayMouseLeave={handleDayMouseLeave}
            onPeriodChange={onPeriodChange}
            handleClearKeyDown={handleClearKeyDown}
            disabledDays={disabledDays}
            renderContent={({ currentlyVisibleFirstMonth }) =>
              getDoesMonthIncludeDaysOutsideRange(
                data,
                currentlyVisibleFirstMonth
              ) && <DateRangeLimitInfoBox />
            }
          />
          <SelectMobileFilterFooter onReset={onReset} onApply={onApply} />
        </Flex>
      </StyledPopperModalMobile>
    </>
  )
}
