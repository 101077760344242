import {
  BodySmall,
  Box,
  ButtonFill,
  Flex,
  Heading,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as ApprovalIcon } from 'assets/svg/approval.svg'
import { translate } from 'utils/translations'

import { useSetPinSuccessParams } from './hooks/useSetPinSuccessParams'

export const SetPinSuccess = () => {
  const { isReplacement } = useSetPinSuccessParams()

  const title = isReplacement
    ? translate('page.setPinModal.replacementSuccess.title')
    : translate('page.setPinModal.activateSuccess.title')
  const subtitle = isReplacement
    ? translate('page.setPinModal.replacementSuccess.subtitle')
    : translate('page.setPinModal.activateSuccess.subtitle')

  return (
    <Box textAlign="center" backgroundColor="white" ml="-12px" height="100%">
      <Box textAlign="center" pb="32px" pt="24px">
        <SvgIcon width="56" height="56">
          <ApprovalIcon />
        </SvgIcon>
      </Box>
      <Heading.H3>{title}</Heading.H3>
      <BodySmall>{subtitle}</BodySmall>
      <Flex justifyContent="center" pt="18px">
        <ButtonFill onClick={() => window.parent?.closePinModal?.()}>
          {translate('page.setPinModal.buttonDoneLabel')}
        </ButtonFill>
      </Flex>
    </Box>
  )
}
