import React from 'react'
import { GetItemPropsOptions } from 'downshift'

export interface PickerItemProps {
  id: number | string
  name: string
  value: string
  icon?: React.ReactNode
}

export type PickerItems = PickerItemProps[]

export type GetItemProps = (option: GetItemPropsOptions<PickerItemProps>) => any

export interface RangeModifierExt {
  from: Date | undefined
  to: Date | undefined
  enteredTo?: Date
}

export enum TIME_FILTER_ENABLED_COMPONENTS {
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTIONS_SPLIT_PAYMENT = 'TRANSACTIONS_SPLIT_PAYMENT',
  CONTACTS = 'CONTACTS',
  OVERVIEW = 'OVERVIEW',
}

export interface LastStoredTimeMap {
  TRANSACTIONS: RangeModifierExt | null
  CONTACTS: RangeModifierExt | null
  OVERVIEW: RangeModifierExt | null
  TRANSACTIONS_SPLIT_PAYMENT: RangeModifierExt | null
}
