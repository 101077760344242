import {
  InputAdaptiveProps,
  StyledInputAdaptiveRightIconWrapper,
  StyledInputLeftIconWrapper,
  TextInputFieldProps,
} from '@npco/zeller-design-system'

import { getInputAdaptiveSizeFromTextInputSize } from './InputSizeConverter'

// This getInputAdaptivePropsFromTextInputProps is deprecated - use new component Props instead
export const getInputAdaptivePropsFromTextInputProps = ({
  isRequired,
  label,
  placeholder,
  size,
  renderLeftControls,
  renderRightControls,
  ...props
}: TextInputFieldProps): InputAdaptiveProps => {
  return {
    isRequired,
    label: label || placeholder,
    placeholder: label ? placeholder : undefined,
    size: getInputAdaptiveSizeFromTextInputSize(size),
    renderLeftControls: renderLeftControls
      ? ({ hasError, resetInput, value }) => {
          return (
            <StyledInputLeftIconWrapper
              $size={getInputAdaptiveSizeFromTextInputSize(size)}
            >
              {renderLeftControls({
                hasError,
                resetInput,
                value: value as string | number,
              })}
            </StyledInputLeftIconWrapper>
          )
        }
      : undefined,
    renderRightControls: renderRightControls
      ? ({ hasError, resetInput, value }) => {
          return (
            <StyledInputAdaptiveRightIconWrapper
              $size={getInputAdaptiveSizeFromTextInputSize(size)}
              hasError={hasError}
            >
              {renderRightControls({
                hasError,
                resetInput,
                value: value as string | number,
              })}
            </StyledInputAdaptiveRightIconWrapper>
          )
        }
      : undefined,
    ...props,
  }
}
