import { zIndexMap } from '@npco/zeller-design-system'
import { createGlobalStyle, keyframes } from 'styled-components'

const appearAnimationWithDelay = keyframes`
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const GlobalStyles = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Inter Zeller', Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    color: ${({ theme }) => theme.colors.BLACK};


    *::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: transparent;
    }

    *::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      background-color: transparent;
    }

    *::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.GREY_150};
      border-radius: 4px;
      border-right: 4px solid transparent;
    }

    .modal-basic-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: ${zIndexMap.modal};
      overflow: hidden;
    }

    /* NOTE: These classes are necessary to animate ReactModal components
     * https://reactcommunity.org/react-modal/styles/transitions/  
     */
    .animated-overlay.ReactModal__Overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: ${zIndexMap.modal};
      overflow: hidden;
      opacity: 0;
      backdrop-filter: blur(2px);
      transition: all 350ms ease-in-out;
    }
    .animated-overlay.ReactModal__Overlay--after-open{
      opacity: 1;
    }
    .animated-overlay.ReactModal__Overlay--before-close{
      opacity: 0;
    }
    .animated-content.ReactModal__Content {
      opacity: 0;
      transform: translate(-50%, -48%);
      transition: opacity 350ms 500ms ease-in-out, transform 350ms 500ms ease-in-out;
    }
    .animated-content.ReactModal__Content--after-open{
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    .animated-content.ReactModal__Content--before-close{
      transform: translate(-50%, -48%);
      opacity: 0;
    }

    .animated-drawer-overlay.ReactModal__Overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0);
      z-index: ${zIndexMap.modal};
      overflow: hidden;

      transition: all 200ms ease-out;
    }

    .animated-drawer-overlay.ReactModal__Overlay--after-open{
      background-color: rgba(0, 0, 0, 0.25);
    }

    .animated-drawer-overlay.ReactModal__Overlay--before-close{
      background-color: rgba(0, 0, 0, 0);
    }

    &.modal-basic-open {
      overflow: hidden;
    }

    button {
      font-family: inherit;
    }
  }

  @supports not selector(::-webkit-scrollbar) {
    * {
        scrollbar-width: thin;
        scrollbar-color: ${({ theme }) => theme.colors.GREY_150} transparent;
    }
  }

  input[type="text"]:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.GREY_250};
  }

  // overriding animation styles for react-joyride
  .__floater__open {
    animation: ${appearAnimationWithDelay} 0.5s forwards ease-out;
    top: -10px !important;
    transition: none !important;
  }

  .__floater__arrow {
    span {
      scale: 0.55;
      top: 4px;
      left: 4px;
    }
  }

  #react-joyride-step-4 {
    .__floater__open {
      top: 0 !important;
      left: -5px !important;
    }
  }

  .react-joyride__spotlight {
    border-radius: 7px !important;
  }
`
