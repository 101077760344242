import React from 'react'
import { Box, InputAdaptiveField, ModalForm } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { EditPosRegisterNameFormValues } from '../EditPosRegisterNameModal.types'
import { validatePosRegisterName } from '../EditPosRegisterNameModal.utils'

interface EditPosRegisterNameModalFormProps {
  onCancel: () => void
  isOpen: boolean
  isLoading: boolean
}

export const EditPosRegisterNameModalForm = ({
  isOpen,
  onCancel,
  isLoading,
}: EditPosRegisterNameModalFormProps) => {
  const { submitForm, isValid, dirty } =
    useFormikContext<EditPosRegisterNameFormValues>()
  return (
    <ModalForm
      title={translate('component.editPosRegisterNameModal.title')}
      isOpen={isOpen}
      onCancel={onCancel}
      onClickPrimary={submitForm}
      secondaryButtonLabel={translate('shared.cancel')}
      primaryButtonLabel={translate('shared.save')}
      isPrimaryButtonDisabled={!isValid || !dirty}
      isLoading={isLoading}
    >
      <form>
        <Box mt="6px">
          <InputAdaptiveField
            name="posRegisterName"
            label={translate(
              'component.editPosRegisterNameModal.posRegisterNameLabel'
            )}
            validate={validatePosRegisterName}
          />
        </Box>
      </form>
    </ModalForm>
  )
}
