import { DebitCardStatus } from '@npco/mp-gql-types'
import {
  Box,
  COLOR,
  Flex,
  LoaderVariant,
  StyledSpinner,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as Decline } from 'assets/svg/decline.svg'
import { ReactComponent as Padlock } from 'assets/svg/padlock.svg'
import { translate } from 'utils/translations'

interface StatusContentProps {
  status: DebitCardStatus
  isLoadingStatus?: boolean
}

export const StatusContent = ({
  status,
  isLoadingStatus = false,
}: StatusContentProps) => {
  if (isLoadingStatus) {
    return (
      <StyledSpinner
        data-testid="status-loader"
        loaderVariant={LoaderVariant.Dark}
      />
    )
  }

  if (status === DebitCardStatus.CLOSED || status === DebitCardStatus.LOST) {
    return (
      <Flex alignItems="center">
        <Box pr="12px">
          <SvgIcon width="16" height="16" color={COLOR.BLACK}>
            <Decline />
          </SvgIcon>
        </Box>
        {translate('component.cardSummary.closed')}
      </Flex>
    )
  }

  if (status === DebitCardStatus.LOCKED) {
    return (
      <Flex alignItems="center">
        <Box pr="12px">
          <SvgIcon width="16" height="16" color={COLOR.BLACK}>
            <Padlock />
          </SvgIcon>
        </Box>
        {translate('component.cardSummary.locked')}
      </Flex>
    )
  }

  if (status === DebitCardStatus.SUSPENDED) {
    return (
      <Flex alignItems="center">
        <Box pr="12px">
          <SvgIcon width="16" height="16" color={COLOR.BLACK}>
            <Padlock />
          </SvgIcon>
        </Box>
        {translate('component.cardSummary.suspended')}
      </Flex>
    )
  }

  return null
}
