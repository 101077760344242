import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  QueryLazyOptions,
  SubscribeToMoreOptions,
  useQuery,
} from '@apollo/client'
import {
  GetInvoicesAmountFilterInput,
  GetInvoicesDateFilterInput,
  GetInvoicesFilterInput,
  GetInvoicesSortInput,
  GetInvoicesStatusFilterInput,
  InvoiceStatus,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useInvoicesLocalCache } from 'features/Invoicing/components/Invoices/hooks/useInvoicesLocalCache'
import {
  AmountColumnEnum,
  DateColumnEnum,
  InvoiceTableColumn,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import { InvoiceTableDefaultAmount } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { InvoicesDefaultLocalState } from 'features/Invoicing/Invoicing.constants'
import { CacheLocalStateInvoices } from 'features/Invoicing/Invoicing.types'
import { isEqual } from 'lodash-es'

import {
  GetInvoices as GetInvoicesResponse,
  GetInvoices_getInvoices_invoices as GetInvoicesInvoice,
  GetInvoicesVariables,
} from 'types/gql-types/GetInvoices'
import {
  InvoiceListUpdate as InvoiceListUpdateResponse,
  InvoiceListUpdateVariables,
} from 'types/gql-types/InvoiceListUpdate'
import { RangeModifierExt } from 'types/picker'
import { RangePickerValue } from 'components/Filters/NewFilters/RangePicker/RangePicker.types'

import { CacheLocalStateEntityScopedVariable } from '../../../../../graphql/cache.types'
import { GetInvoicesLocalState } from '../graphql/getInvoicesLocalState'
import { useInvoices } from '../hooks/useInvoices'

export type InvoicesContextType = {
  filterInput: GetInvoicesFilterInput | null
  getInvoices: (
    options?: QueryLazyOptions<GetInvoicesVariables> | undefined
  ) => void
  hasError: boolean
  hasNoInitialResults: boolean
  hasNoResults: boolean
  invoices?: GetInvoicesInvoice[] | null
  isDefaultFilters: boolean
  isLoading: boolean
  isMobileFiltersOpen: boolean
  loadMore: (
    options: FetchMoreQueryOptions<GetInvoicesVariables, any>
  ) => Promise<ApolloQueryResult<GetInvoicesResponse>> | undefined
  nextToken?: string | null
  refetchInvoices: () => void
  resetState: () => void
  selectedAmount: RangePickerValue
  selectedAmountColumn: AmountColumnEnum
  selectedDateColumn: DateColumnEnum
  selectedDates: RangeModifierExt
  selectedStatuses: InvoiceStatus[]
  setAmountFilter: (value: GetInvoicesAmountFilterInput | null) => void
  setSortingObject: (value: NonNullable<GetInvoicesSortInput> | null) => void
  setDateFilter: (value: GetInvoicesDateFilterInput | null) => void
  setIsMobileFiltersOpen: (value: boolean) => void
  setSearchFilter: (value: string) => void
  setSelectedAmountColumn: (value: AmountColumnEnum) => void
  setSelectedDateColumn: (value: DateColumnEnum) => void
  setSelectedStatuses: (statuses: InvoiceStatus[]) => void
  setStatusFilter: (value: GetInvoicesStatusFilterInput | null) => void
  sortingObject: NonNullable<GetInvoicesSortInput> | null
  subscribeToMore: (
    options: SubscribeToMoreOptions<
      GetInvoicesResponse,
      InvoiceListUpdateVariables,
      InvoiceListUpdateResponse
    >
  ) => void
}

export const InvoicesContext = createContext<InvoicesContextType | undefined>(
  undefined
)

InvoicesContext.displayName = 'Invoices Context'

interface InvoicesProviderProps {
  children: ReactNode | ReactNode[]
}

const InvoicesProvider = ({ children }: InvoicesProviderProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data } = useQuery<
    CacheLocalStateInvoices,
    CacheLocalStateEntityScopedVariable
  >(GetInvoicesLocalState, { variables: { entityUuid } })

  const {
    columns: defaultColumns,
    filter: defaultFilter,
    sort: defaultSort,
    statuses: defaultStatuses,
  } = InvoicesDefaultLocalState

  // NOTE: use query could return undefined so handle fallback here
  const filterInput = data?.local.invoices.filter || defaultFilter
  const selectedAmountColumn =
    data?.local.invoices.columns.amount || defaultColumns.amount
  const selectedDateColumn =
    data?.local.invoices.columns.date || defaultColumns.date
  const selectedStatuses = data?.local.invoices.statuses || defaultStatuses
  const sortingObject = data?.local.invoices.sort || defaultSort

  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false)

  const {
    resetState,
    setFilterInput,
    setSelectedAmountColumn,
    setSelectedDateColumn,
    setSelectedStatuses,
    setSortingObject,
  } = useInvoicesLocalCache({ sortingObject })

  const {
    getInvoices,
    hasError,
    hasNoInitialResults,
    hasNoResults,
    invoices,
    isLoading,
    loadMore,
    nextToken,
    refetchInvoices,
    subscribeToMore,
    variables,
  } = useInvoices({
    filterInput,
    sortingObject,
  })

  const isDefaultFilters = useMemo(
    () =>
      (variables?.input?.sort ?? sortingObject) === defaultSort &&
      isEqual(variables?.input?.filter ?? filterInput, defaultFilter),
    [
      defaultFilter,
      defaultSort,
      filterInput,
      sortingObject,
      variables?.input?.filter,
      variables?.input?.sort,
    ]
  )

  const selectedDates = useMemo<RangeModifierExt>(() => {
    const { startDate, endDate } = filterInput.dateFilter || {}

    const validEndDate = typeof startDate === 'number'
    const validStartDate = typeof endDate === 'number'

    if (startDate && endDate && validEndDate && validStartDate) {
      return {
        from: new Date(startDate * 1000),
        to: new Date(endDate * 1000),
        enteredTo: undefined,
      }
    }

    return {
      from: undefined,
      to: undefined,
      enteredTo: undefined,
    }
  }, [filterInput.dateFilter])

  const selectedAmount = useMemo<RangePickerValue>(() => {
    const { from, to } = filterInput.amountFilter || {}

    const fromNumber = Number(from) || InvoiceTableDefaultAmount[0]
    const toNumber = Number(to) || InvoiceTableDefaultAmount[1]

    return [fromNumber, toNumber]
  }, [filterInput.amountFilter])

  const setAmountFilter = useCallback(
    (input: GetInvoicesAmountFilterInput | null) => {
      const newFilterInput: GetInvoicesFilterInput = {
        ...filterInput,
        amountFilter: input,
      }

      const shouldClearSort =
        Object.values(AmountColumnEnum).includes(
          sortingObject?.columnName as AmountColumnEnum
        ) && input === null

      setFilterInput(newFilterInput, shouldClearSort)
    },
    [filterInput, setFilterInput, sortingObject]
  )

  const setDateFilter = useCallback(
    (input: GetInvoicesDateFilterInput | null) => {
      const newFilterInput: GetInvoicesFilterInput = {
        ...filterInput,
        dateFilter: input,
      }

      const shouldClearSort =
        Object.values(DateColumnEnum).includes(
          sortingObject?.columnName as DateColumnEnum
        ) && input === null

      setFilterInput(newFilterInput, shouldClearSort)
    },
    [filterInput, setFilterInput, sortingObject]
  )

  const setSearchFilter = useCallback(
    (input: string) => {
      const newFilterInput: GetInvoicesFilterInput = {
        ...filterInput,
        textSearchFilter: input,
      }

      setFilterInput(newFilterInput)
    },
    [filterInput, setFilterInput]
  )

  const setStatusFilter = useCallback(
    (input: GetInvoicesStatusFilterInput | null) => {
      const newFilterInput: GetInvoicesFilterInput = {
        ...filterInput,
        statusFilter: input,
      }

      const shouldClearSort =
        sortingObject?.columnName === InvoiceTableColumn.Status &&
        input === null

      setFilterInput(newFilterInput, shouldClearSort)
    },
    [filterInput, setFilterInput, sortingObject]
  )

  const value: InvoicesContextType = useMemo(
    () => ({
      filterInput,
      getInvoices,
      hasError,
      hasNoInitialResults,
      hasNoResults,
      invoices,
      isDefaultFilters,
      isLoading,
      isMobileFiltersOpen,
      loadMore,
      nextToken,
      refetchInvoices,
      resetState,
      selectedAmount,
      selectedAmountColumn,
      selectedDateColumn,
      selectedDates,
      selectedStatuses,
      setAmountFilter,
      setDateFilter,
      setIsMobileFiltersOpen,
      setSearchFilter,
      setSelectedAmountColumn,
      setSelectedDateColumn,
      setSelectedStatuses,
      setSortingObject,
      setStatusFilter,
      sortingObject,
      subscribeToMore,
    }),
    [
      filterInput,
      getInvoices,
      hasError,
      hasNoInitialResults,
      hasNoResults,
      invoices,
      isDefaultFilters,
      isLoading,
      isMobileFiltersOpen,
      loadMore,
      nextToken,
      refetchInvoices,
      resetState,
      selectedAmount,
      selectedAmountColumn,
      selectedDateColumn,
      selectedDates,
      selectedStatuses,
      setAmountFilter,
      setDateFilter,
      setIsMobileFiltersOpen,
      setSearchFilter,
      setSelectedAmountColumn,
      setSelectedDateColumn,
      setSelectedStatuses,
      setSortingObject,
      setStatusFilter,
      sortingObject,
      subscribeToMore,
    ]
  )

  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  )
}

const useInvoicesContext = () => {
  const context = useContext(InvoicesContext)

  if (!context) {
    throw new Error('useInvoicesContext must be used within InvoicesContext')
  }

  return context
}

export { InvoicesProvider, useInvoicesContext }
