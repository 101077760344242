import { gql } from '@apollo/client'

export const GetTransaction = gql`
  query GetTransaction($entityUuid: ID!, $transactionUuid: ID!) {
    getTransaction(entityUuid: $entityUuid, transactionUuid: $transactionUuid) {
      id
      deviceName
      deviceModel
      siteName
      timestamp
      refunded
      refundedAmount
      reversed
      review
      amount
      saleAmount
      tipAmount
      taxAmounts {
        name
        amount
      }
      externalReference
      externalReferenceUrl
      depositShortId
      depositUuid
      posName
      surchargeAmount
      feeCharged
      feeAmount
      scheme
      type
      cardMedia
      status
      depositDate
      maskedPan
      reference
      responseCode
      cardholderEmail
      cardholderPhone
      cardholderUuid
      notes
      source
      sourceFilter
      issuer
      contact {
        id
      }
      refundedTransaction {
        status
        reference
      }
      refundedTransactionUuid
      refundTransactions {
        id
        amount
        status
        reference
        timestamp
        customerName
      }
      splitPaymentUuid
      splitPayment {
        id
        portions
        targetAmount
        type
        transactions {
          id
          saleAmount
          status
          timestamp
        }
      }
      threeDSOutcome
    }
  }
`

export const GetTransactions = gql`
  query GetTransactions(
    $entityUuid: ID!
    $filter: TransactionFilterInput
    $limit: Int!
    $nextToken: TransactionNextTokenInput
  ) {
    getTransactions(
      entityUuid: $entityUuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      transactions {
        id
        deviceName
        deviceModel
        siteName
        timestamp
        refunded
        refundedAmount
        reversed
        review
        amount
        scheme
        type
        cardMedia
        status
        maskedPan
        reference
        responseCode
        source
        sourceFilter
        issuer
        tipAmount
        taxAmounts {
          name
          amount
        }
        surchargeAmount
        feeAmount
      }
      nextToken {
        id
        type
        entityUuid
      }
    }
  }
`

export const GetTransactionsTimestamp = gql`
  query GetTransactionsTimestamp(
    $entityUuid: ID!
    $filter: TransactionFilterInput
    $limit: Int!
    $nextToken: TransactionNextTokenInput
  ) {
    getTransactions(
      entityUuid: $entityUuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      transactions {
        timestamp
      }
    }
  }
`
