import { forwardRef } from 'react'
import {
  InputFieldWithoutLabel,
  InputFieldWithoutLabelProps,
  StyledInputAdaptiveRightIconWrapper,
  TextInputFieldProps,
} from '@npco/zeller-design-system'
import { omit } from 'ramda'

import { getInputAdaptiveSizeFromTextInputSize } from 'components/InputAdaptiveManagers/InputSizeConverter'

// This InputFieldWithoutLabelWrapper is deprecated - use InputFieldWithoutLabel in new implementations
export const InputFieldWithoutLabelWrapper = forwardRef<
  HTMLInputElement,
  TextInputFieldProps & Pick<InputFieldWithoutLabelProps, 'icon'>
>(({ icon, renderRightControls, ...props }, ref) => {
  const { size, ...rest } = props
  return (
    <InputFieldWithoutLabel
      {...omit(['renderLeftControls'], rest)}
      ref={ref}
      size={getInputAdaptiveSizeFromTextInputSize(size)}
      icon={icon}
      renderRightControls={
        renderRightControls
          ? ({ hasError, resetInput, value }) => {
              return (
                <StyledInputAdaptiveRightIconWrapper
                  $size={getInputAdaptiveSizeFromTextInputSize(size)}
                >
                  {renderRightControls({
                    hasError,
                    resetInput,
                    value: value as string | number,
                  })}
                </StyledInputAdaptiveRightIconWrapper>
              )
            }
          : undefined
      }
    />
  )
})
