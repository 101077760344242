import React from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  Flex,
  SvgIcon,
  TileSelector,
  useModalState,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'

import { ColourPickerModal } from '../ColourPickerModal/ColourPickerModal'
import { MAX_COLOR_COUNT } from '../SiteAppearanceForm/SiteAppearanceForm.constants'
import {
  SCREEN_SAVER_FORM_FIELDS,
  SiteAppearanceFormValues,
} from '../SiteAppearanceForm/SiteAppearanceForm.types'
import { translations } from './ColourList.i18n'
import * as styled from './ColourList.styled'

export const ColourList = () => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const t = useTranslations(translations)
  const { values, setFieldValue } = useFormikContext<SiteAppearanceFormValues>()

  const handleOnSave = (color: string) => {
    if (
      !values.customColours.includes(color) &&
      color !== COLOR.BLACK &&
      color !== COLOR.WHITE
    ) {
      setFieldValue(SCREEN_SAVER_FORM_FIELDS.customColours, [
        ...values.customColours,
        color,
      ])
    }
    setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedColour, color)
  }

  return (
    <Flex mb="16px" maxWidth="504px" gap="12px" flexWrap="wrap">
      <TileSelector
        name="default-colour-black"
        content={<styled.BlackTile />}
        onChange={() =>
          setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedColour, COLOR.BLACK)
        }
        value="default-colour-black"
        selected={values.selectedColour === COLOR.BLACK}
      />
      <TileSelector
        name="default-colour-white"
        content={<styled.WhiteTile />}
        onChange={() =>
          setFieldValue(SCREEN_SAVER_FORM_FIELDS.selectedColour, COLOR.WHITE)
        }
        value="default-colour-white"
        selected={values.selectedColour === COLOR.WHITE}
      />
      {values.customColours.map((colour) => {
        return (
          <TileSelector
            key={colour}
            onClear={() => {
              if (values.selectedColour === colour) {
                setFieldValue(
                  SCREEN_SAVER_FORM_FIELDS.selectedColour,
                  COLOR.BLACK
                )
              }
              setFieldValue(
                SCREEN_SAVER_FORM_FIELDS.customColours,
                values.customColours.filter((c) => c !== colour)
              )
            }}
            clearable
            name={`custom-colour-${colour}`}
            content={<styled.ColourTile colour={colour} />}
            onChange={() => setFieldValue('selectedColour', colour)}
            value={`custom-colour-${colour}`}
            selected={values.selectedColour === colour}
          />
        )
      })}
      {values.customColours.length < MAX_COLOR_COUNT && (
        <TileSelector
          name="add-colour"
          content={
            <styled.AddValue>
              <SvgIcon width="16" height="16">
                <Plus />
              </SvgIcon>
            </styled.AddValue>
          }
          onChange={() => openModal()}
          value="add-colour"
        />
      )}

      {isModalOpen && (
        <ColourPickerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSave={handleOnSave}
          title={t('colourPickerTitle')}
          mobileTitle={t('colourPickerMobileTitle')}
        />
      )}
    </Flex>
  )
}
