import { DecisionModal } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const ModalWrapper = styled.article`
  display: flex;
  flex-direction: column;
`

export const SectionWrapper = styled.article<{ gap?: string; mb?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '12px'};
  ${({ mb }) => `margin-bottom: ${mb}`};
`

export const StyledDecisionModal = styled(DecisionModal)<{
  $overflow?: string
}>`
  .transfer-modal-body {
    margin-top: 0;

    ${({ $overflow }) =>
      $overflow &&
      css`
        overflow: ${$overflow};
      `};
  }
  .transfer-modal-footer {
    margin-top: 0;
  }
`
