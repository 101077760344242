import { useEditSpendControlsState } from 'features/EditSpendControls/edit-spend-controls-routing'
import { Form, Formik } from 'formik'

import { useValidation } from 'pages/GlobalModals/components/SpendControlFields'
import { SpendControlTypes } from 'pages/GlobalModals/components/SpendControlFields/SpendControlFields.types'

import { EditSpendControlsModalForm } from './EditSpendControlsModalForm'
import { useEditSpendControlsModal } from './hooks/useEditSpendControlsModal/useEditSpendControlsModal'

export const EditSpendControlsModal = () => {
  const { state } = useEditSpendControlsState()
  const schema = useValidation()
  const {
    submitForm,
    closeModal,
    isEditingSpendControls,
    initialValues,
    isLoadingEntityAddressTimezone,
    entityAddressTimezone,
  } = useEditSpendControlsModal()

  if (state === null) {
    return null
  }

  return (
    <Formik<SpendControlTypes>
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={schema}
    >
      <Form>
        <EditSpendControlsModalForm
          closeModal={closeModal}
          isLoading={isEditingSpendControls}
          accountUuid={state.accountUuid}
          isLoadingTimezone={isLoadingEntityAddressTimezone}
          timezone={entityAddressTimezone}
        />
      </Form>
    </Formik>
  )
}
