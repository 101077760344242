enum SCROLLBAR_SIZE {
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
}

type ScrollbarProps = {
  [key in SCROLLBAR_SIZE]: {
    width: string
    rightSpacing: string
    leftSpacing?: string
  }
}

export const SCROLLBAR: ScrollbarProps = {
  // NOTE: Input Combobox Popper
  [SCROLLBAR_SIZE.SM]: {
    width: '3px',
    rightSpacing: '4px',
    leftSpacing: '2px',
  },
  // NOTE: Modals
  [SCROLLBAR_SIZE.MD]: {
    width: '4px',
    rightSpacing: '4px',
  },
  // NOTE: Page and Drawers
  [SCROLLBAR_SIZE.LG]: {
    width: '7px',
    rightSpacing: '3px',
  },
}
