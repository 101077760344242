import { NetworkStatus } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useContactDetailsQuery as useContactDetailsQueryApollo } from './graphql/ContactDetailsQuery.generated'

type UseContactDetailsQueryProps = {
  contactUuid: string
}

export const useContactDetailsQuery = ({
  contactUuid,
}: UseContactDetailsQueryProps) => {
  const entityUuid = useSelectedEntityUuid()

  const { data, loading, error, refetch, networkStatus } =
    useContactDetailsQueryApollo({
      variables: { entityUuid, contactUuid },
      notifyOnNetworkStatusChange: true,
    })

  return {
    contact: data?.getContact ?? null,
    isLoadingContact: loading || networkStatus === NetworkStatus.refetch,
    error,
    refetch,
  }
}
