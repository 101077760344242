import { Flex, H3, H6 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTransactionContactWrapper = styled.div`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const StyledContactsTitle = styled(H3)`
  margin: 0 0 0 1rem;
`

export const StyledContactsHeader = styled(Flex)`
  align-items: center;
`

export const StyledMapSection = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`

export const StyledPlaceholderWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
`

export const StyledItemWrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 0.625rem;
`

export const StyledLabel = styled(H6)`
  margin: 0;
  padding-right: 1rem;
`

export const StyledValue = styled.div`
  text-align: right;
  ${({ theme }) => theme.typography['body-sm']}
  word-break: break-word;
`

export const StyledLeftColumn = styled(Flex)``

export const StyledRightColumn = styled(Flex)``

export const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`
