import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { ReactComponent as ArrowBadge } from 'assets/svg/setup-flow/arrow-card-details-down.svg'

import { Message } from '../../../../../SetupFlowModal.styled'
import { setupFlowDebitCardDetailsTranslations } from '../DebitCardDetails.i18n'

interface DebitCardDetailsCopyInfoDesktopProps {
  isDetailsVisible: boolean
}
export const DebitCardDetailsCopyInfoDesktop = ({
  isDetailsVisible,
}: DebitCardDetailsCopyInfoDesktopProps) => {
  const t = useTranslations(setupFlowDebitCardDetailsTranslations)

  return (
    <>
      <Box position="absolute" top="-45px" left="-11px">
        <ArrowBadge />
      </Box>
      <Box position="absolute" width="144px" top="-110px" left="13px">
        <Message>
          {t(isDetailsVisible ? 'hideMessageDesktop' : 'showMessageDesktop')}
        </Message>
      </Box>
    </>
  )
}
