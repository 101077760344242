import { useCallback, useState } from 'react'
import { gql } from '@apollo/client'
import { showErrorToast, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as MaskedCard } from 'assets/svg/cards/masked-card.svg'

import { CardDisplayDebitCardV2Fragment } from './CardDisplay.generated'
import * as styled from './CardDisplay.styled'
import { getIsCardRestricted } from './CardDisplay.utils'
import { CardFormatDisplay } from './CardFormatDisplay'
import { SensitiveCardInformation } from './SensitiveCardInformation/SensitiveCardInformation'
import { StatusOverlay } from './StatusOverlay/StatusOverlay'
import { ViewDetails } from './ViewDetails/ViewDetails'

interface CardDisplayProps {
  width?: string
  height?: string
  card: CardDisplayDebitCardV2Fragment
  children: React.ReactNode
  isLoadingStatus?: boolean
}

export const CardDisplay = ({
  width,
  height,
  card,
  children,
  isLoadingStatus = false,
}: CardDisplayProps) => {
  const [isCardRevealed, setIsCardRevealed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isCardInformationVisible =
    isCardRevealed && !getIsCardRestricted(card.status)

  const hideSensitiveInformationAndShowErrorToast = useCallback(() => {
    setIsCardRevealed(false)
    showErrorToast()
  }, [])

  return (
    <SvgIcon width={width} height={height}>
      {children}
      <styled.TransitionWrapper
        isVisible={isCardInformationVisible && !isLoading}
      >
        <styled.SvgIconWithBoxShadow>
          <MaskedCard />
        </styled.SvgIconWithBoxShadow>
      </styled.TransitionWrapper>
      <CardFormatDisplay
        card={card}
        isVisible={isCardInformationVisible && !isLoading}
      />
      <styled.TransitionWrapper
        isVisible={isCardInformationVisible && !isLoading}
      >
        <SensitiveCardInformation
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isVisible={isCardInformationVisible}
          card={card}
          onError={hideSensitiveInformationAndShowErrorToast}
        />
      </styled.TransitionWrapper>
      <ViewDetails
        isLoading={isLoading}
        top="43px"
        left="21px"
        onClick={() => {
          setIsCardRevealed((prev) => {
            const isNowVisible = !prev

            if (isNowVisible) {
              setIsLoading(true)
            }

            return isNowVisible
          })
        }}
        areDetailsDisplayed={isCardInformationVisible}
        card={card}
      />
      {(getIsCardRestricted(card.status) || isLoadingStatus) && (
        <>
          <StatusOverlay card={card} isLoadingStatus={isLoadingStatus} />
          <styled.CardOverlay />
        </>
      )}
    </SvgIcon>
  )
}

CardDisplay.fragments = {
  DebitCardV2: gql`
    fragment CardDisplayDebitCardV2Fragment on DebitCardV2 {
      ...ViewDetailsDebitCardV2Fragment
      ...CardFormatDisplayDebitCardV2Fragment
      ...StatusOverlayDebitCardV2Fragment
      ...SensitiveCardInformationDebitCardV2Fragment
    }

    ${SensitiveCardInformation.fragments.DebitCardV2}
    ${CardFormatDisplay.fragments.DebitCardV2}
    ${ViewDetails.fragments.DebitCardV2}
    ${StatusOverlay.fragments.DebitCardV2}
  `,
}
