import { useContext, useMemo } from 'react'
import { getIsValidCreateAdminAppState, MFAContext } from 'features/MFA'

export const useCreateAdminMFAState = () => {
  const context = useContext(MFAContext)

  if (context === null) {
    throw new Error('useCreateAdminMFAState can only be used within MFAContext')
  }

  const { appState, hasRedirectedBackToApp, setHasRedirectedBackToApp } =
    context

  const createAdminState = useMemo(() => {
    if (!appState || !getIsValidCreateAdminAppState(appState)) {
      return null
    }
    return appState
  }, [appState])

  return { createAdminState, hasRedirectedBackToApp, setHasRedirectedBackToApp }
}
