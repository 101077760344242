import { gql } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { ConfigType } from 'dayjs'

import dayjs from 'utils/dayjs'

import { AVATAR_BADGE_SIZE } from '../../types/common'
import {
  GetAccountAvatarDebitCardAccountV2Fragment,
  GetRightSideLabelDebitCardAccountV2Fragment,
} from './Transfer.utils.generated'

export const BPAY_TRANSFER_MODAL_HEIGHT = 624
export const INTERNAL_TRANSFER_MODAL_HEIGHT = 552

export const getAccountAvatar = (
  account: GetAccountAvatarDebitCardAccountV2Fragment
) => {
  return {
    letter: '',
    userDefinedContent: (
      <IconAvatar
        icon={account.icon}
        defaultLetter={account.name.charAt(0)}
        badgeSize={AVATAR_BADGE_SIZE.SMALL}
      />
    ),
  }
}

export const getRightSideLabel = (
  label: string,
  account: GetRightSideLabelDebitCardAccountV2Fragment
) => {
  if (account.type === DebitCardAccountType.SAVINGS) {
    return label
  }

  return undefined
}

export const formatTimeDate = (date: ConfigType) =>
  dayjs(date).format('h:mma, D MMMM YYYY')

export const formatBsb = (bsb: string) => bsb.replace(/(.{3})/g, '$1-')

getAccountAvatar.fragments = {
  DebitCardAccountV2: gql`
    fragment GetAccountAvatarDebitCardAccountV2Fragment on DebitCardAccountV2 {
      name
      icon {
        ...IconAvatarIconFragment
      }
    }

    ${IconAvatar.fragments.Icon}
  `,
}

getRightSideLabel.fragments = {
  DebitCardAccountV2: gql`
    fragment GetRightSideLabelDebitCardAccountV2Fragment on DebitCardAccountV2 {
      type
    }
  `,
}
