import { Box, COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CrossmarkIcon } from 'assets/svg/close-red.svg'
import { translate } from 'utils/translations'
import { StyledIconBox } from 'pages/CompleteEmailChange/CompleteEmailChange.styled'
import { StyledCopy, StyledHeader } from 'pages/Login/Login.styled'

export const CouldNotUpdateEmailMessage = () => {
  return (
    <>
      <StyledIconBox backgroundColor={COLOR.RED_80}>
        <SvgIcon width="16" height="16">
          <CrossmarkIcon />
        </SvgIcon>
      </StyledIconBox>
      <Box>
        <StyledHeader>
          {translate('page.changeEmail.error2.headline')}
        </StyledHeader>
        <StyledCopy>{translate('page.changeEmail.error2.copy')}</StyledCopy>
      </Box>
    </>
  )
}
