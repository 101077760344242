import { useUpgradeAccountsCompleteState } from './hooks/useUpgradeAccountsCompleteState'
import { UpgradeAccountsCompleteBuildBusinessStage } from './pages/UpgradeAccountsCompleteBuildBusinessStage/UpgradeAccountsCompleteBuildBusinessStage'
import { UpgradeAccountsCompleteDebitCardsRewardsStage } from './pages/UpgradeAccountsCompleteDebitCardsRewardsStage/UpgradeAccountsCompleteDebitCardsRewardsStage'
import { UpgradeAccountsCompleteReadyToGoStage } from './pages/UpgradeAccountsCompleteReadyToGoStage/UpgradeAccountsCompleteReadyToGoStage'
import { UpgradeAccountsCompleteStartStage } from './pages/UpgradeAccountsCompleteStartStage/UpgradeAccountsCompleteStartStage'
import { UpgradeAccountsCompleteXeroIntegrationStage } from './pages/UpgradeAccountsCompleteXeroIntegrationStage/UpgradeAccountsCompleteXeroIntegrationStage'

export const UpgradeAccountsCompleteModal = () => {
  const { state } = useUpgradeAccountsCompleteState()

  if (state === null) {
    return null
  }

  return (
    <>
      <UpgradeAccountsCompleteStartStage />
      <UpgradeAccountsCompleteBuildBusinessStage />
      <UpgradeAccountsCompleteXeroIntegrationStage />
      <UpgradeAccountsCompleteDebitCardsRewardsStage />
      <UpgradeAccountsCompleteReadyToGoStage />
    </>
  )
}
