import { ReactNode } from 'react'

import ANZPath, {
  ReactComponent as ANZ,
} from 'assets/svg/Bank/australia-newzeland.svg'
import BOMPath, {
  ReactComponent as BOM,
} from 'assets/svg/Bank/bank-melbourne.svg'
import BSAPath, { ReactComponent as BSA } from 'assets/svg/Bank/bank-sa.svg'
import BWAPath, { ReactComponent as BWA } from 'assets/svg/Bank/bankwest.svg'
import CBAPath, {
  ReactComponent as CBA,
} from 'assets/svg/Bank/common-wealth.svg'
import DefaultPath, {
  ReactComponent as Default,
} from 'assets/svg/Bank/default.svg'
import MBLPath, { ReactComponent as MBL } from 'assets/svg/Bank/macquarie.svg'
import NABPath, {
  ReactComponent as NAB,
} from 'assets/svg/Bank/national-bank.svg'
import BQLPath, { ReactComponent as BQL } from 'assets/svg/Bank/queensland.svg'
import STGPath, { ReactComponent as STG } from 'assets/svg/Bank/st-george.svg'
import WBCPath, { ReactComponent as WBC } from 'assets/svg/Bank/westpac.svg'

export interface Bank {
  name: string
  code: string
  number: string[]
  numberShort: string[]
  icon: ReactNode
  iconPath: string
}

const CBAName = 'Commonwealth Bank of Australia'

export const banks: Bank[] = [
  {
    name: 'Australia and New Zealand Banking Group',
    code: 'ANZ',
    number: [],
    numberShort: ['01'],
    icon: <ANZ />,
    iconPath: ANZPath,
  },
  {
    name: 'Westpac Banking Corporation',
    code: 'WBC',
    number: [],
    numberShort: ['03', '73'],
    icon: <WBC />,
    iconPath: WBCPath,
  },
  {
    name: CBAName,
    code: 'CBA',
    number: [],
    numberShort: ['06', '76'],
    icon: <CBA />,
    iconPath: CBAPath,
  },
  {
    name: 'National Australia Bank',
    code: 'NAB',
    number: [],
    numberShort: ['08', '78'],
    icon: <NAB />,
    iconPath: NABPath,
  },
  {
    name: 'Reserve Bank of Australia',
    code: 'RBA',
    number: [],
    numberShort: ['09'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BankSA',
    code: 'BSA',
    number: [],
    numberShort: ['10'],
    icon: <BSA />,
    iconPath: BSAPath,
  },
  {
    name: 'St George Bank',
    code: 'STG',
    number: [],
    numberShort: ['11', '33'],
    icon: <STG />,
    iconPath: STGPath,
  },
  {
    name: 'Bank of Queensland',
    code: 'BQL',
    number: [],
    numberShort: ['12'],
    icon: <BQL />,
    iconPath: BQLPath,
  },
  {
    name: 'Rabobank',
    code: 'PIB',
    number: [],
    numberShort: ['14'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Town & Country Bank',
    code: 'T&C',
    number: [],
    numberShort: ['15'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Macquarie Bank',
    code: 'MBL',
    number: [],
    numberShort: ['18'],
    icon: <MBL />,
    iconPath: MBLPath,
  },
  {
    name: 'Bank of Melbourne',
    code: 'BOM',
    number: [],
    numberShort: ['19'],
    icon: <BOM />,
    iconPath: BOMPath,
  },
  {
    name: 'JPMorgan Chase Bank',
    code: 'CMB',
    number: [],
    numberShort: ['21'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BNP Paribas',
    code: 'BNP',
    number: [],
    numberShort: ['22'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of America',
    code: 'BAL',
    number: [],
    numberShort: ['23'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Citibank Australia',
    code: 'CTI',
    number: [],
    numberShort: ['24'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BNP Paribas',
    code: 'BPS',
    number: ['255'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Alex Bank',
    code: 'ALX',
    number: ['259'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BT Financial Group',
    code: 'BTA',
    number: [],
    numberShort: ['26'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'National Mutual Royal Bank',
    code: 'NMB',
    number: [],
    numberShort: ['28'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Tokyo',
    code: 'BOT',
    number: [],
    numberShort: ['29'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bankwest',
    code: 'BWA',
    number: [],
    numberShort: ['30'],
    icon: <BWA />,
    iconPath: BWAPath,
  },
  {
    name: 'Bank Australia',
    code: 'BAU',
    number: [],
    numberShort: ['31'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'KEB Hana Bank',
    code: 'KEB',
    number: ['323'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Beyond Bank Australia',
    code: 'BYB',
    number: ['325'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'HSBC Bank Australia',
    code: 'HBA',
    number: [],
    numberShort: ['34'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of China',
    code: 'BOC',
    number: [],
    numberShort: ['35'],
    icon: <Default />,
    iconPath: DefaultPath,
  },

  {
    name: CBAName,
    code: 'CST',
    number: [],
    numberShort: ['40'],
    icon: <CBA />,
    iconPath: CBAPath,
  },
  {
    name: 'Deutsche Bank Australia',
    code: 'DBA',
    number: [],
    numberShort: ['41'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: CBAName,
    code: 'TBT',
    number: [],
    numberShort: ['42', '52'],
    icon: <CBA />,
    iconPath: CBAPath,
  },
  {
    name: 'OCBC Bank',
    code: 'OCB',
    number: [],
    numberShort: ['45'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Advance Bank',
    code: 'ADV',
    number: [],
    numberShort: ['46'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Challenge Bank',
    code: 'CBL',
    number: [],
    numberShort: ['47'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Suncorp Bank',
    code: 'MET',
    number: [],
    numberShort: ['48'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Citibank NA',
    code: 'CNA',
    number: ['510'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Community First Credit Union',
    code: 'CFC',
    number: ['512'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'RACQ Bank',
    code: 'QTM',
    number: ['514'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Volt Bank',
    code: 'VOL',
    number: ['517'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: CBAName,
    code: 'TBT',
    number: ['527'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bananacoast Community Credit Union',
    code: 'BCC',
    number: ['533'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Melbourne',
    code: 'BML',
    number: [],
    numberShort: ['55'],
    icon: <BOM />,
    iconPath: BOMPath,
  },
  {
    name: 'Australian Settlements',
    code: 'ASL',
    number: [],
    numberShort: ['57'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'State Bank of Victoria',
    code: 'SBV',
    number: [],
    numberShort: ['60'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Adelaide Bank',
    code: 'ADL',
    number: ['610'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Australian Mutual Bank',
    code: 'SEL',
    number: ['611'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'ABS Building Society',
    code: 'ABS',
    number: ['630'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Us',
    code: 'BAE',
    number: ['632'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bendigo & Adelaide Bank',
    code: 'BAB',
    number: ['633'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Uniting Financial Services',
    code: 'UFS',
    number: ['634'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Hay Limited',
    code: 'HAY',
    number: ['636'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Greater Bank',
    code: 'GBS',
    number: ['637'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Heritage Bank',
    code: 'HBS',
    number: ['638'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Queensland',
    code: 'HOM',
    number: ['639'],
    numberShort: [],
    icon: <BQL />,
    iconPath: BQLPath,
  },
  {
    name: 'Hume Bank',
    code: 'HUM',
    number: ['640'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'IMB',
    code: 'IMB',
    number: ['641', '647'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Australian Military Bank',
    code: 'ADC',
    number: ['642'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Auswide Bank',
    code: 'MPB',
    number: ['645'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Maitland Mutual',
    code: 'MMB',
    number: ['646'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Newcastle Permanent Building Society',
    code: 'NEW',
    number: ['650'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Pioneer Permanent Building Society',
    code: 'PPB',
    number: ['653'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Queensland Country Bank',
    code: 'QCB',
    number: ['654'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'MyState Bank',
    code: 'ROK',
    number: ['655'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Auswide Bank',
    code: 'BAY',
    number: ['656'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'G&C Mutual Bank',
    code: 'GCB',
    number: ['659'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Suncorp Bank',
    code: 'SUN',
    number: ['664'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: '86400 Bank (UBank)',
    code: 'YOU',
    number: ['670'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Gateway Bank',
    code: 'GTW',
    number: ['676'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },

  {
    name: 'Indue',
    code: 'CUS',
    number: [],
    numberShort: ['70'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BankFirst',
    code: 'CUS',
    number: ['704191'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BankVic',
    code: 'CUS',
    number: ['704230'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Qudos Bank',
    code: 'CUS',
    number: ['704865'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Regional Australia Bank',
    code: 'HCC',
    number: ['721'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Southern Cross Credit Union',
    code: 'SNX',
    number: ['722'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Heritage Isle',
    code: 'HIC',
    number: ['723'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Summerland Credit Union',
    code: 'SCU',
    number: ['728'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Xinja Bank',
    code: 'XIN',
    number: ['775'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Cuscal (& Other Banks)',
    code: 'CRU',
    number: [],
    numberShort: ['80'],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Zeller',
    code: 'ZLR',
    number: ['803439'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: "People's Choice Credit Union",
    code: 'CUS',
    number: ['805050'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Teachers Mutual Bank',
    code: 'TCU',
    number: ['812'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'The Capricornian',
    code: 'CAP',
    number: ['813'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Credit Union Australia',
    code: 'CUA',
    number: ['814'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Police Bank',
    code: 'PCU',
    number: ['815'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Warwick Credit Union',
    code: 'WCU',
    number: ['817'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Communications',
    code: 'COM',
    number: ['818'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Industrial and Commercial Bank of China',
    code: 'IBK',
    number: ['819'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Defence Bank',
    code: 'DBL',
    number: ['833'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Unity Bank',
    code: 'MMP',
    number: ['882'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'China Construction Bank Corporation',
    code: 'CCB',
    number: ['888'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'DBS Bank',
    code: 'DBS',
    number: ['889'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Australia Post',
    code: 'APO',
    number: ['902'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Sumitomo Mitsui Banking Corporation',
    code: 'SMB',
    number: ['911'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'State Street Bank and Trust Company',
    code: 'SSB',
    number: ['913'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'FNC Agency - Bank One, NA',
    code: 'FNC',
    number: ['915'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Arab Bank Australia',
    code: 'ARA',
    number: ['917'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Mizuho Bank',
    code: 'MCB',
    number: ['918'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'United Overseas Bank',
    code: 'UOB',
    number: ['922'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'ING Bank',
    code: 'ING',
    number: ['923', '936'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Mega International Commercial Bank',
    code: 'ICB',
    number: ['931'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Regional Australia Bank',
    code: 'RAB',
    number: ['932'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'AMP Bank',
    code: 'AMP',
    number: ['939'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Delphi Bank',
    code: 'BCY',
    number: ['941'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of Sydney',
    code: 'LBA',
    number: ['942'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Taiwan Business Bank',
    code: 'TBB',
    number: ['943'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'ME Bank',
    code: 'MEB',
    number: ['944'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'UBS AG',
    code: 'UBS',
    number: ['946'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'BQQ Specialist',
    code: 'INV',
    number: ['951'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Royal Bank of Scotland',
    code: 'RBS',
    number: ['952'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Tyro Payments',
    code: 'MSL',
    number: ['969'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'Bank of China (Australia)',
    code: 'BCA',
    number: ['980'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
  {
    name: 'HSBC Bank Australia',
    code: 'HSB',
    number: ['985'],
    numberShort: [],
    icon: <Default />,
    iconPath: DefaultPath,
  },
]
