import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink } from '@npco/zeller-design-system'

import { useGoToTransfer } from 'hooks/useGoToTransfer/useGoToTransfer'
import { translationsShared } from 'translations'

import { useGoToBlockedAction } from '../../../../../features/BlockedAction/blocked-action-routing'
import { SectionHeader } from '../../../SectionHeader'
import { AccountBalanceListHeaderEntityFragment } from './AccountBalanceListHeader.generated'
import { translations } from './AccountBalanceListHeader.i18n'

interface AccountBalanceListHeaderProps {
  entity?: AccountBalanceListHeaderEntityFragment
}

export const AccountBalanceListHeader = ({
  entity,
}: AccountBalanceListHeaderProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const { goToTransfer } = useGoToTransfer()
  const { goToBlockedAction } = useGoToBlockedAction()

  const goToTransferPage = useCallback(() => {
    if (entity && !entity.canTransferOut) {
      goToBlockedAction({ blockedAction: 'transferOut' })
      return
    }

    goToTransfer()
  }, [entity, goToBlockedAction, goToTransfer])

  return (
    <SectionHeader heading={t('heading')}>
      <ButtonLink onClick={goToTransferPage}>{tShared('transfer')}</ButtonLink>
    </SectionHeader>
  )
}

AccountBalanceListHeader.fragments = {
  Entity: gql`
    fragment AccountBalanceListHeaderEntityFragment on Entity {
      id
      canTransferOut
    }
  `,
}
