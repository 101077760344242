import { makeVar } from '@apollo/client'

import {
  CheckoutAddress,
  ECommerceCartItem,
} from '../../types/onboardingShop.types'

export const rvECommerceCartItem = makeVar([] as ECommerceCartItem[])

export const rvCheckoutAddress = makeVar({} as CheckoutAddress)
