import { Link as BaseLink } from 'react-router-dom'
import { BodyLarge, H1 } from '@npco/zeller-design-system'
import { Form } from 'formik'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledHeader = styled(H1)`
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
  max-width: 12.5rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    max-width: 100%;
  }
`

export const StyledDescription = styled(BodyLarge)`
  padding: 0;
  margin: 0 0 2.5rem;
`

export const StyledFormWrapper = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 19.5rem;
`

export const StyledLink = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.RED_1000};
`
