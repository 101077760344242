import { HeaderContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { InvoiceHeaderCell } from '../InvoiceHeaderCell/InvoiceHeaderCell'
import { AllInvoiceTableColumn, DateColumnEnum } from '../InvoiceTable.types'
import { DateLabel, TableColumnPopperWidth } from '../InvoiceTable.utils'
import { DateFilters } from '../InvoiceTableFilters/DateFilters/DateFilters'

export const InvoiceDateHeaderCell = ({
  header,
  table,
}: HeaderContext<Invoice | null, unknown>) => {
  const { filterInput } = table.options.meta || {}

  const isFiltering = Boolean(filterInput?.dateFilter)

  return (
    <InvoiceHeaderCell
      disablePopper={false}
      header={header}
      isFiltering={isFiltering}
      label={DateLabel[header.id as DateColumnEnum]}
      popperWidth={TableColumnPopperWidth[AllInvoiceTableColumn.NextDue]}
      table={table}
    >
      {({ setIsOpen, setShouldCloseOnOutsideClick }) => (
        <DateFilters
          setIsOpen={setIsOpen}
          setShouldCloseOnOutsideClick={setShouldCloseOnOutsideClick}
        />
      )}
    </InvoiceHeaderCell>
  )
}
