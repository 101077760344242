import { SimBillingStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useAbortSimCancellationMutation } from '../graphql/abortSimCancellation.generated'
import { translations } from '../SimDrawer.i18n'

type UseAbortSimCancellationProps = {
  simId?: string
}

export const useAbortSimCancellation = ({
  simId = '',
}: UseAbortSimCancellationProps) => {
  const entityUuid = useSelectedEntityUuid()
  const t = useTranslations(translations)
  const [abortSimCancellation, { loading }] = useAbortSimCancellationMutation({
    variables: { entityUuid, simId },
    onCompleted: (result) => {
      if (result.abortSimCancellation) {
        showSuccessToast(t('abortCancelSimSuccessToast'))
        return
      }
      showApiErrorToast()
    },
    onError: () => showApiErrorToast(),
    update: (cache, { data }) => {
      if (data?.abortSimCancellation) {
        cache.modify({
          id: cache.identify({
            __typename: 'Sim',
            id: simId,
          }),
          fields: {
            billingStatus() {
              return SimBillingStatus.SUBSCRIBED
            },
          },
        })
      }
    },
  })

  return {
    abortSimCancellation,
    isAbortingSimCancellation: loading,
  }
}
