import { useState } from 'react'
import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg'
import { GetItemProps, PickerItemProps, PickerItems } from 'types/picker'
import { Checkbox } from 'components/Input/Checkbox'

import { SiteRowProps } from '../SiteDevicePicker/SiteDevicePicker.types'
import { StyledItem, StyledRow, StyledSubItem } from './Elements.styled'

const subItems = (
  devices: PickerItems,
  getItemProps: GetItemProps,
  selectedItems: PickerItems
) =>
  devices.map((device: PickerItemProps) => {
    return (
      <StyledSubItem key={device.id}>
        <Flex justifyContent="center" alignItems="center">
          <Checkbox
            type="checkbox"
            label={device.name}
            name={device.name}
            checked={selectedItems.some(({ id }) => id === device.id)}
            onChange={() => null}
            {...getItemProps({ item: device })}
          />
          {device.icon && (
            <Box ml="12px">
              <SvgIcon width="16" height="16" color={COLOR.GREY_250}>
                {device.icon}
              </SvgIcon>
            </Box>
          )}
        </Flex>
      </StyledSubItem>
    )
  })

export const SiteRow: React.FC<SiteRowProps> = ({
  site,
  getItemProps,
  selectedItems,
  toggleSiteDevices,
  areAllSiteDevicesSelected,
}) => {
  const [isOpen, toggle] = useState(false)
  const hasDevices = site.devices.length > 0
  const isDisabled = site.devices.length === 0

  const areSomeSiteDevicesSelected = (devices: PickerItems): boolean => {
    const filteredItems = devices.filter((device) =>
      selectedItems.some(({ id }) => id === device.id)
    )
    return filteredItems.length > 0 && filteredItems.length < devices.length
  }

  return (
    <>
      <StyledRow
        as="li"
        onClick={() => toggle(!isOpen)}
        noPadding
        withTopBorder
        withCursor={!isDisabled}
      >
        <StyledItem>
          <Checkbox
            type="checkbox"
            name={site.name}
            label={site.name}
            indeterminate={areSomeSiteDevicesSelected(site.devices)}
            checked={hasDevices && areAllSiteDevicesSelected(site.devices)}
            onChange={() => toggleSiteDevices(site.devices)}
            disabled={isDisabled}
          />
          <SvgIcon
            dataTestId="site-arrow-down"
            width="16"
            height="16"
            withRotation={hasDevices && isOpen}
          >
            <ArrowDown />
          </SvgIcon>
        </StyledItem>
      </StyledRow>
      {isOpen &&
        site.devices &&
        subItems(site.devices, getItemProps, selectedItems)}
    </>
  )
}
