import { useTranslations } from '@npco/utils-translations'

import { LogoContainer } from 'components/CompanyLogoUpload/CompanyLogoUpload.styled'

import {
  StyledBlueText,
  StyledGreyText,
  StyledLogoPlaceholder,
} from '../../ModalUploadLogo/ModalUploadLogo.styled'
import { translations } from './ModalUploadDropImage.i18n'

interface ModalUploadDropImageProps {
  isDragActive: boolean
  rootProps: Record<string, any>
  label?: string
  subLabel?: string
}

export const ModalUploadDropImage = ({
  label,
  isDragActive,
  rootProps,
  subLabel,
}: ModalUploadDropImageProps) => {
  const t = useTranslations(translations)
  return (
    <LogoContainer
      data-testid="logo-dropzone"
      justifyContent="center"
      alignItems="center"
      mb="1rem"
      isDragActive={isDragActive}
      {...rootProps}
    >
      <StyledLogoPlaceholder
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <StyledGreyText>{label ?? t('label')}</StyledGreyText>
        <StyledBlueText>{subLabel ?? t('subLabel')}</StyledBlueText>
      </StyledLogoPlaceholder>
    </LogoContainer>
  )
}
