import { gql } from '@apollo/client'
import { BoxProps, CellSize } from '@npco/zeller-design-system'

import { useCardBalance } from 'hooks/useCardBalance'
import { useCardStatus } from 'hooks/useCardStatus'

import { CardActivateButton } from '../CardActivateButton/CardActivateButton'
import { CardBalanceCellBase } from '../CardBalanceCellBase/CardBalanceCellBase'
import { CardStatus } from '../CardStatus/CardStatus'
import { CardBalanceCellDebitCardV2Fragment } from './CardBalanceCell.generated'
import { CardBalance } from './CardBalanceCell.styled'

export interface CardBalanceCellProps {
  card?: CardBalanceCellDebitCardV2Fragment
  cellId: string
  isLoading?: boolean
  cellSize?: CellSize
  isActivateButtonEnabled?: boolean
  skeletonPropWidth: BoxProps['width']
}
export const CardBalanceCell = ({
  card,
  isLoading,
  cellId,
  cellSize,
  isActivateButtonEnabled,
  skeletonPropWidth,
}: CardBalanceCellProps) => {
  const formattedCardBalance = useCardBalance(card)

  const { isClosed, isLocked, isSuspended, isInactive } = useCardStatus(card)
  const isActive = !isInactive
  const shouldShowCardStatus = isClosed || isLocked || isSuspended

  const cellProps = {
    cellId,
    skeletonPropWidth,
    isLoading,
    cellSize,
  }

  if (shouldShowCardStatus && card?.status) {
    return <CardStatus {...cellProps} status={card.status} />
  }

  return (
    <CardBalanceCellBase
      {...cellProps}
      content={
        isActive ? (
          <CardBalance>{formattedCardBalance}</CardBalance>
        ) : (
          isActivateButtonEnabled && <CardActivateButton />
        )
      }
    />
  )
}

CardBalanceCell.fragments = {
  DebitCardV2: gql`
    fragment CardBalanceCellDebitCardV2Fragment on DebitCardV2 {
      ...UseCardStatusDebitCardV2Fragment
      ...UseCardBalanceDebitCardV2Fragment
      ...CardStatusDebitCardV2Fragment
    }
    ${useCardStatus.fragments.DebitCardV2}
    ${useCardBalance.fragments.DebitCardV2}
    ${CardStatus.fragments.DebitCardV2}
  `,
}
