import { COLOR } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const DEFAULT_MAX_HEIGHT = '12.5rem'
export const DEFAULT_MAX_HEIGHT_IN_PX = 200

export const createAddNewItem = (newItem: string) => ({
  label: translate('component.selectBasic.addNewItem', {
    newItem,
  }),
  value: newItem,
  color: COLOR.BLUE_1000,
})
