import { useSetupFlowContext } from '../../../../../../contexts/SetupFlowContext'
import {
  DebitCardDetailsClickCover,
  DebitCardPositionWrapper,
} from '../DebitCardDetails.styled'
import { SensitiveCardInformation } from '../SensitiveCardInformation/SensitiveCardInformation'
import { DebitCardDetailsContentProps } from './DebitCardDetailsContent.types'
import { DebitCardDetailsCopyInfoDesktop } from './DebitCardDetailsCopyInfoDesktop'

export const DebitCardDetailsContentDesktop = ({
  toggleCard,
  isCardFlipped,
  onError,
}: DebitCardDetailsContentProps) => {
  const { setupFlowCard } = useSetupFlowContext()

  return (
    <DebitCardPositionWrapper data-testid="debit-card-details-desktop">
      <SensitiveCardInformation
        isFlipped={isCardFlipped}
        debitCard={setupFlowCard}
        onError={onError}
      />
      {/* on desktop view, after sensitive iframe injected by marqeta, event bubble on iframe terminate at the root of the iframe's document
        and do not travel across the boundary into the host document. fixed by handling click action on a transparent cover on the top. */}
      {setupFlowCard && (
        <>
          <DebitCardDetailsClickCover
            aria-label={isCardFlipped ? 'hide details' : 'show details'}
            onClick={toggleCard}
          />
          <DebitCardDetailsCopyInfoDesktop isDetailsVisible={isCardFlipped} />
        </>
      )}
    </DebitCardPositionWrapper>
  )
}
