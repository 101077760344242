import {
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { LinkCardError } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { getErrorMessageMapping } from './ErrorInfoBox.utils'

interface ErrorInfoBoxProps {
  linkCardError: LinkCardError
}

export const ErrorInfoBox = ({ linkCardError }: ErrorInfoBoxProps) => {
  return (
    <InfoBox
      priority={INFOBOX_PRIORITY.DEFAULT}
      variant={INFOBOX_VARIANT.NEGATIVE}
    >
      <InfoBox.Message>{getErrorMessageMapping(linkCardError)}</InfoBox.Message>
    </InfoBox>
  )
}
