import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  Breadcrumb,
  BREAKPOINT,
  Flex,
  PageTemplate,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translationsShared } from 'translations'

import { TransactionAccountsActions } from '../../../components/TransactionAccountsActions/TransactionAccountsActions'
import { Balance } from '../../TransactionAccountsLayout/Balance/Balance'
import { useAccountLayout } from '../hooks/useAccountLayout/useAccountLayout'

export const AccountLayoutPrimaryHeaderRowDeps = {
  useIsMobileResolution,
}

export type AccountLayoutPrimaryHeaderRowProps = {
  accountBalance?: number
  accountId: string
  accountName?: string
  entity: ReturnType<typeof useAccountLayout>['entity']
  isActionsVisible: boolean
  isFiltersVisible: boolean
  isTransactionsRoute: boolean
  isTransferDisabled: boolean
  setIsFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const AccountLayoutPrimaryHeaderRow = ({
  accountBalance,
  accountId,
  accountName,
  entity,
  isActionsVisible,
  isFiltersVisible,
  isTransactionsRoute,
  isTransferDisabled,
  setIsFiltersVisible,
}: AccountLayoutPrimaryHeaderRowProps) => {
  const { useIsMobileResolution } = AccountLayoutPrimaryHeaderRowDeps

  const tShared = useTranslations(translationsShared)
  const isTablet = useIsMobileResolution(BREAKPOINT.MD)

  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex
        flexDirection="column"
        justifyContent="center"
        minHeight="48px"
        width="100%"
      >
        <Flex
          alignItems="center"
          gap="10px"
          justifyContent="space-between"
          width="100%"
        >
          <Flex flex={1} flexDirection="column" overflow="hidden">
            <Breadcrumb>
              {!isTablet && (
                <Breadcrumb.Item>
                  <Breadcrumb.Link
                    to={ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.path}
                  >
                    {tShared('accounts')}
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
              )}
              <Breadcrumb.Item>
                <Breadcrumb.Text>{accountName}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
            {accountBalance !== undefined && isTablet && (
              <Flex>
                <Balance totalBalance={accountBalance} />
              </Flex>
            )}
          </Flex>
          {isActionsVisible && (
            <TransactionAccountsActions
              accountId={accountId}
              entity={entity}
              isFiltersDisabled={false}
              isFiltersVisible={isFiltersVisible}
              defaultFilters={{ selectedAccountID: accountId }}
              isTransferDisabled={isTransferDisabled}
              shouldHideFilterButton={!isTransactionsRoute}
              toggleFilters={() => setIsFiltersVisible((prev) => !prev)}
            />
          )}
        </Flex>
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
