import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useDebitCardPanToken } from 'hooks/useDebitCardPanToken'

import { useSetupFlowAccountsQuery } from '../hooks/useSetupFlowAccountsQuery'
import { useSetupFlowCardsQuery } from '../hooks/useSetupFlowCardsQuery'
import { SetupFlowContext } from './SetupFlowContext'

interface SetupFlowProviderProps {
  children: ReactNode
  onLoaded?: () => void
}

export const SetupFlowProvider = ({
  children,
  onLoaded,
}: SetupFlowProviderProps) => {
  const entityUuid = useSelectedEntityUuid()

  const [isLoaded, setIsLoaded] = useState(false)
  const [exitClicked, setExitClicked] = useState(false)
  const { userData } = useLoggedInUser()
  const { isLoadingCards, cards } = useSetupFlowCardsQuery()
  const { isLoadingAccounts, refetchAccounts, accounts } =
    useSetupFlowAccountsQuery()

  const {
    isLoading: isLoadingDebitCardPanToken,
    clientAccessToken,
    generatePanToken,
    generateTokenError,
  } = useDebitCardPanToken()

  const setupFlowCard = useMemo(() => {
    return (
      (cards ?? []).filter((card) => card?.customer?.id === userData?.id)[0] ||
      undefined
    )
  }, [cards, userData])

  const [isCreateCardAllowedInitialState, setIsCreateCardAllowedInitialState] =
    useState(!setupFlowCard)

  const setupFlowAccount = useMemo(() => accounts?.[0] ?? undefined, [accounts])

  useEffect(() => {
    if (setupFlowCard?.id) {
      generatePanToken({ debitCardId: setupFlowCard.id, entityUuid })
    }
  }, [setupFlowCard, generatePanToken, entityUuid])

  useEffect(() => {
    if (isLoaded || isLoadingAccounts || isLoadingCards) {
      return
    }
    setIsLoaded(true)
    onLoaded?.()
  }, [isLoaded, isLoadingCards, isLoadingAccounts, onLoaded])

  useEffect(() => {
    setIsCreateCardAllowedInitialState(!setupFlowCard)
  }, [setupFlowCard])

  const value = useMemo(
    () => ({
      clientAccessToken,
      exitClicked,
      generatePanToken,
      generateTokenError,
      isLoaded,
      isLoadingAccounts,
      isLoadingCards,
      isLoadingDebitCardPanToken,
      refetchAccounts,
      setExitClicked,
      setupFlowAccount,
      setupFlowCard,
      isCreateCardAllowedInitialState,
      setIsCreateCardAllowedInitialState,
    }),
    [
      clientAccessToken,
      exitClicked,
      generatePanToken,
      generateTokenError,
      isCreateCardAllowedInitialState,
      isLoaded,
      isLoadingAccounts,
      isLoadingCards,
      isLoadingDebitCardPanToken,
      refetchAccounts,
      setExitClicked,
      setupFlowAccount,
      setupFlowCard,
    ]
  )

  return (
    <SetupFlowContext.Provider value={value}>
      {children}
    </SetupFlowContext.Provider>
  )
}
