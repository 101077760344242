import { ContactType } from '@npco/mp-gql-types'
import { Box, Contacts, Heading, InfoBox } from '@npco/zeller-design-system'
import {
  DEFAULT_BUSINESS_NAME,
  DEFAULT_PERSON_NAME,
} from 'features/Contacts/Contacts.constants'
import { getContactName } from 'features/Contacts/Contacts.utils'
import { INVOICE_ATTENTION_TO_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'

import { translate } from 'utils/translations'

import { InvoiceCreateContact } from '../../InvoiceCreateContact/InvoiceCreateContact'
import { useAttentionToContactCombobox } from '../hooks/useAttentionToContactCombobox'

export const translationsContactTypeMap = {
  [ContactType.BUSINESS]: {
    attentionToLabel: translate(
      'page.invoice.formSections.customer.attentionToPersonLabel'
    ),
    attentionToCreateButton: translate(
      'page.invoice.formSections.customer.attentionToCreatePerson'
    ),
    modalFormSubContactHeader: translate('page.contacts.form.businessDetails'),
  },
  [ContactType.PERSON]: {
    attentionToLabel: translate(
      'page.invoice.formSections.customer.attentionToBusinessLabel'
    ),
    attentionToCreateButton: translate(
      'page.invoice.formSections.customer.attentionToCreateBusiness'
    ),
    modalFormSubContactHeader: translate('page.contacts.form.personalDetails'),
  },
}

interface InvoiceAttentionToContactComboboxProps {
  payerId: string
}

export const InvoiceAttentionToContactCombobox = ({
  payerId,
}: InvoiceAttentionToContactComboboxProps) => {
  const {
    attentionToItems,
    contactTypeToCreate,
    error,
    handleChange,
    handleClose,
    handleCreateContact,
    handleInputChange,
    handleInputClear,
    handleOnBlur,
    inputValue,
    isDisabled,
    payerFromCache,
    selectedItem,
    setContactTypeToCreate,
  } = useAttentionToContactCombobox(payerId)

  const {
    attentionToCreateButton = '',
    attentionToLabel = '',
    modalFormSubContactHeader = '',
  } = payerFromCache
    ? translationsContactTypeMap[payerFromCache.contactType]
    : {}

  return (
    <>
      <Contacts.ContactCombobox
        addNewItemContent={attentionToCreateButton}
        error={error}
        inputLabel={attentionToLabel}
        inputValue={inputValue}
        isDisabled={isDisabled}
        items={attentionToItems}
        name={INVOICE_ATTENTION_TO_FIELD}
        onBlur={handleOnBlur}
        onChange={handleChange}
        onClose={handleClose}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        selectedItem={selectedItem}
      />
      {contactTypeToCreate && (
        <InvoiceCreateContact
          contactTypeToCreate={contactTypeToCreate}
          initialName={inputValue}
          onClose={() => setContactTypeToCreate(null)}
          onCreate={handleCreateContact}
          payerId={payerId}
          renderCreateFormBottomSection={({
            values: {
              person: { firstName, lastName },
              business: { businessName },
            },
          }) => {
            const isBusinessType = contactTypeToCreate === ContactType.BUSINESS

            const displayedBusinessName =
              businessName?.trim() || DEFAULT_BUSINESS_NAME

            const displayedPersonName =
              firstName || lastName
                ? `${firstName} ${lastName}`.trim()
                : DEFAULT_PERSON_NAME

            const [contact1, contact2] = isBusinessType
              ? [getContactName(payerFromCache), displayedBusinessName]
              : [displayedPersonName, getContactName(payerFromCache)]

            return (
              <>
                <Box mt="40px" mb="16px">
                  <Heading.H4>{modalFormSubContactHeader}</Heading.H4>
                </Box>
                {/* NOTE: migrate to new InfoBox on a separate JIRA
                  https://npco-dev.atlassian.net/browse/DES-9
              */}
                <InfoBox>
                  <InfoBox.Message>
                    {translate('page.contacts.infoMessage.linkedTo', {
                      contact1,
                      contact2,
                    })}
                  </InfoBox.Message>
                </InfoBox>
              </>
            )
          }}
        />
      )}
    </>
  )
}
