import { gql } from '@apollo/client'

export const GetReportsLocalState = gql`
  query GetReportsLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      reports {
        filters {
          dates
          statuses
          terminals
          types
          sources
        }
        isFiltersVisible
      }
    }
  }
`
