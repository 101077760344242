import { useMemo } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useAccountId } from 'hooks/banking'

import { useAccountDetailsViewQuery as useAccountDetailsViewQueryApollo } from './graphql/AccountDetailsViewQuery.generated'

export const useAccountDetailsViewQuery = () => {
  const entityUuid = useSelectedEntityUuid()
  const { accountId } = useAccountId()

  const { data, loading, error } = useAccountDetailsViewQueryApollo({
    variables: { id: accountId, entityUuid },
  })

  const account = useMemo(() => data?.account, [data?.account])

  return { account, loading, error }
}
