import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const DocumentOptionButton = styled.button<{ isSelected: boolean }>`
  ${BodyDefaultTypography};
  width: 100%;
  font-family: inherit;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 20px 24px;
  margin-bottom: 8px;
  cursor: pointer;
  outline: none;
  outline: none;
  background-color: ${({ theme }) => theme.colors.GREY_30};
  border: 1px solid ${({ theme }) => theme.colors.GREY_30};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out,
    color 0.3s ease-out;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_60};
    border-color: ${({ theme }) => theme.colors.GREY_60};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.BLACK};
      background-color: ${({ theme }) => theme.colors.BLUE_80};

      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.BLUE_1000};
        background-color: ${({ theme }) => theme.colors.BLUE_80};
      }
    `};

  &:disabled {
    color: ${({ theme }) => theme.colors.GREY_250};
    background-color: ${({ theme }) => theme.colors.GREY_60};
    cursor: not-allowed;
  }
`

export const Description = styled.div<{ isDisabled: boolean }>`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.GREY_250 : theme.colors.GREY_550};
  text-align: left;
  padding-left: 30px;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding-left: 0;
  }
`
