import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { DebitCardAccountType } from '@npco/mp-gql-types'

const isValidAccountType = (
  type: string | null
): type is DebitCardAccountType => {
  return Object.values(DebitCardAccountType).includes(
    type as DebitCardAccountType
  )
}

export const useSelectedAccountType = () => {
  const { search } = useLocation()

  const selectedAccountType = useMemo(() => {
    const params = new URLSearchParams(search)
    const accountType = params.get('accountType')

    return {
      accountType: isValidAccountType(accountType)
        ? accountType
        : DebitCardAccountType.ZLR_DEBIT,
    }
  }, [search])

  return selectedAccountType
}
