import 'react-image-crop/dist/ReactCrop.css'

import { Box, Flex } from '@npco/zeller-design-system'

import { useImageCropper } from 'components/ModalUploadImage/ImageCropper/hooks/useImageCropper'

import { StyledReactCrop } from './ImageCropper.styled'

interface ImageCropperProps {
  onCrop?: (nextImage: string) => void
  onCropFile?: (file: File) => void
  uploadedImage: string
  fileType?: string
}

export const ImageCropper = ({
  onCrop,
  onCropFile,
  uploadedImage,
  fileType,
}: ImageCropperProps) => {
  const {
    crop: selectedCrop,
    onCompletedCrop,
    onLoad,
    previewCanvasRef,
    setCrop,
  } = useImageCropper({ onCrop, onCropFile, fileType })

  return (
    <Box width="100%">
      <Flex data-testid="react-cropper" justifyContent="center">
        {uploadedImage && (
          <StyledReactCrop
            aspect={1}
            className="imageCropper"
            crop={selectedCrop}
            onChange={(crop) => setCrop(crop)}
            onComplete={(crop) => onCompletedCrop(crop)}
          >
            <img alt="Cropper" src={uploadedImage} onLoad={onLoad} />
          </StyledReactCrop>
        )}
      </Flex>
      <Box display="none">
        <canvas ref={previewCanvasRef} />
      </Box>
    </Box>
  )
}
