import { createTranslations } from '@npco/utils-translations'

export const TransferRemittancesTranslations = createTranslations({
  smsRemittanceSuccess: 'Remittance SMS sent to {sms}',
  emailRemittanceSuccess: 'Remittance email sent to {email}',
  somethingWentWrong: 'Something went wrong. Please try again.',
  smsRemittanceLimitReached:
    'SMS Remittance limit reached. You may only send this 5 times.',
  emailRemittanceLimitReached:
    'Email Remittance limit reached. You may only send this 5 times.',
  emailNotFound: 'Email not found. Please try again.',
  mobileNumberNotFound: 'Mobile number not found. Please try again.',
  notificationPeriodExpired:
    'Your period to notify has expired. You can download a PDF remittance in the transaction.',
})
