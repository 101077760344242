import { translate } from 'utils/translations'

import * as styled from '../FeesAndPricing.styled'
import { getPaymentValue } from '../FeesAndPricing.utils'

interface PaymentTableProps {
  cardPresentPayments?: number | null
  motoPayments?: number | null
}

export const PaymentTable = ({
  cardPresentPayments,
  motoPayments,
}: PaymentTableProps) => {
  return (
    <styled.Table data-testid="paymentTable">
      <styled.TableCell className="leftCell topCell">
        {translate('page.settings.feesAndPricing.table.cardPresentPayments')}
      </styled.TableCell>
      <styled.TableCell className="topCell rightCell">
        {getPaymentValue(cardPresentPayments)}
      </styled.TableCell>
      <styled.TableCell className="leftCell">
        {translate('page.settings.feesAndPricing.table.motoPayments')}
      </styled.TableCell>
      <styled.TableCell className="rightCell">
        {getPaymentValue(motoPayments)}
      </styled.TableCell>
    </styled.Table>
  )
}
