import { Box, ButtonGhostIconLeft } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledCreateAccountButton = styled(ButtonGhostIconLeft)`
  margin: 0 0 2.5rem 0;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin-left: 0.5rem;
  }
`

export const StyledContainer = styled(Box)`
  flex: 1;
  margin: 0 -0.5rem;
  padding: 0 0.5rem;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (min-width: ${BREAKPOINT.XS}px) {
    margin: 0 -2.5rem;
    padding: 0 2.5rem;
  }

  @media (min-width: ${BREAKPOINT.MD}px) {
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
  }

  @media (min-width: ${BREAKPOINT.LG}px) {
    margin: 0 -2.75rem;
    padding: 0 2.75rem;
  }

  @media (min-width: ${BREAKPOINT.XL}px) {
    margin: 0 -4.25rem;
    padding: 0 4.25rem;
  }
`
