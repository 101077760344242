import { useCallback } from 'react'
import { useField, useFormikContext } from 'formik'

import { BPayTargetInput } from 'pages/Transfer/BPay/BPayTargetInput'
import { ItemContactOrBiller } from 'pages/Transfer/BPay/BPayTargetInput/useBPayTargetItems'
import { useDisabledBillerCodes } from 'pages/Transfer/BPay/hooks/useDisabledBillerCodes/useDisabledBillerCodes'
import { TransferBpayFields } from 'pages/Transfer/Transfer.types'

import { useValidateContactOrBillerField } from './hooks/useValidateContactOrBillerField'

export const ContactOrBillerField = () => {
  const { disabledBillers } = useDisabledBillerCodes()

  const { setFieldValue } = useFormikContext<TransferBpayFields>()

  const validateContactOrBillerField = useValidateContactOrBillerField({
    disabledBillers,
  })

  const [{ value }, { error, touched }, { setValue }] = useField<
    ItemContactOrBiller | undefined
  >({
    name: 'to',
    validate: validateContactOrBillerField,
  })

  const handleChange = useCallback(
    (value: ItemContactOrBiller | undefined) => {
      setValue(value, false)
      setFieldValue('paymentInstrumentUuid', undefined, false)
    },
    [setValue, setFieldValue]
  )

  return (
    <BPayTargetInput
      value={value}
      formError={touched ? error : undefined}
      onValueChange={handleChange}
    />
  )
}
