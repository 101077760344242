import { Formik } from 'formik'

import { PersonaliseAccountValues } from 'components/AccountFields/PersonaliseAccountFields/PersonaliseAccountFields.types'

import { useEditAccount } from '../hooks/useEditAccount'
import { EditAccountFields } from './EditAccountFields'

export const EditAccountModalForm = () => {
  const {
    initialName,
    initialAccountColour,
    isUpdatingAccount,
    handleSubmit,
    isSavingsAccount,
  } = useEditAccount()

  return (
    <Formik<PersonaliseAccountValues>
      initialValues={{ name: initialName, accountColour: initialAccountColour }}
      validateOnMount
      onSubmit={handleSubmit}
    >
      <EditAccountFields
        isLoading={isUpdatingAccount}
        hideAvatar={isSavingsAccount}
      />
    </Formik>
  )
}
