import { ChevronRightIcon } from '@npco/zeller-design-system'

import { StyledNavButton } from './ChartXAxisTick.styled'

type RightNavButtonProps = {
  handleSelectNext?: () => void
}

export const RightNavButton = ({ handleSelectNext }: RightNavButtonProps) => {
  return (
    <StyledNavButton
      data-testid="next-nav-button"
      aria-label="next-nav-button"
      disabled={!handleSelectNext}
      onClick={handleSelectNext}
      type="button"
    >
      <ChevronRightIcon width="12" height="12" />
    </StyledNavButton>
  )
}
