import { useCallback, useEffect, useState } from 'react'
import { ButtonGhost, Divider } from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  AllStatusesSelected,
  StatusLabel,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { DateFilter } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileFilters/DateFilter'
import { SortByFilters } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileFilters/SortByFilters'
import {
  FilterAndSortOptions,
  FilterState,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFilters.utils'
import {
  BottomWrapper,
  FiltersDrawer,
  OptionalContent,
  OptionButton,
  OptionsWrapper,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableMobile/InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer.styled'

import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg'
import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'
import { page } from 'translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { AllInvoiceTableColumn } from '../../InvoiceTable.types'
import { AmountFilters } from '../InvoiceTableMobileFilters/AmountFilters'
import { SearchFilter } from '../InvoiceTableMobileFilters/SearchFilter'
import { StatusFilters } from '../InvoiceTableMobileFilters/StatusFilters'

export const InvoiceTableMobileFiltersDrawer = () => {
  const {
    filterInput,
    isMobileFiltersOpen,
    refetchInvoices,
    resetState,
    setIsMobileFiltersOpen,
    sortingObject,
  } = useInvoicesContext()

  const [filterState, setFilterState] = useState<FilterState>(
    FilterState.FilterAndSort
  )

  const renderHeaderTitle = () => {
    if (filterState !== FilterState.FilterAndSort) {
      return FilterAndSortOptions.find(({ value }) => value === filterState)
        ?.header
    }
    return translate('page.invoiceTable.mobile.filterHeaders.default')
  }

  const onOptionSelected = useCallback(
    (option: FilterState) => () => {
      setFilterState(option)
    },
    [setFilterState]
  )

  const onCloseClick = useCallback(() => {
    if (filterState !== FilterState.FilterAndSort) {
      return setFilterState(FilterState.FilterAndSort)
    }
    return setIsMobileFiltersOpen(false)
  }, [filterState, setFilterState, setIsMobileFiltersOpen])

  const renderFilterOptions = () => {
    if (filterState === FilterState.FilterByDueDate) {
      return <DateFilter setFilterState={setFilterState} />
    }

    if (filterState === FilterState.FilterByStatus) {
      return <StatusFilters setFilterState={setFilterState} />
    }

    if (filterState === FilterState.FilterByAmount) {
      return <AmountFilters setFilterState={setFilterState} />
    }

    if (filterState === FilterState.FilterBySearch) {
      return <SearchFilter setFilterState={setFilterState} />
    }

    return <SortByFilters setFilterState={setFilterState} />
  }

  const renderOptionalSortByContent = () => {
    const columnName = sortingObject?.columnName
    const isAscending = Boolean(sortingObject?.ascending)

    if (columnName === AllInvoiceTableColumn.ID) {
      return isAscending
        ? page.invoiceTable.sortBy.idAscending
        : page.invoiceTable.sortBy.idDescending
    }
    if (columnName === AllInvoiceTableColumn.Contact) {
      return isAscending
        ? page.invoiceTable.sortBy.contactAscending
        : page.invoiceTable.sortBy.contactDescending
    }
    if (columnName === AllInvoiceTableColumn.Status) {
      return isAscending
        ? page.invoiceTable.sortBy.statusAscending
        : page.invoiceTable.sortBy.statusDescending
    }
    if (columnName === AllInvoiceTableColumn.DatePaid) {
      return isAscending
        ? page.invoiceTable.sortBy.dateAscending
        : page.invoiceTable.sortBy.dateDescending
    }
    if (columnName === AllInvoiceTableColumn.AmountPaid) {
      return isAscending
        ? page.invoiceTable.sortBy.amountAscending
        : page.invoiceTable.sortBy.amountDescending
    }
    return null
  }

  const renderOptionalStatusContent = () => {
    const length = filterInput?.statusFilter?.values?.length || 0
    const values = filterInput?.statusFilter?.values || []

    if (length === 0 || length === AllStatusesSelected.length) {
      return null
    }

    return length === 1 ? `(${StatusLabel[values[0]]})` : `(${length})`
  }

  const renderOptionalDateContent = () => {
    if (filterInput?.dateFilter?.startDate && filterInput.dateFilter.endDate) {
      return `(${dayjs(
        new Date(filterInput.dateFilter.startDate * 1000)
      ).format('DD/MM/YY')} - ${dayjs(
        new Date(filterInput.dateFilter.endDate * 1000)
      ).format('DD/MM/YY')})`
    }
    return null
  }

  const renderOptionalAmountContent = () => {
    if (filterInput?.amountFilter?.from && filterInput.amountFilter.to) {
      return `(${currency(filterInput.amountFilter.from).format()} - ${currency(
        filterInput.amountFilter.to
      ).format()})`
    }

    return null
  }

  const renderOptionalSearchContent = () => {
    if (filterInput?.textSearchFilter) {
      return `(${filterInput.textSearchFilter})`
    }

    return null
  }

  useEffect(() => {
    // NOTE: only fetch when the mobile filters have closed
    if (isMobileFiltersOpen === false) {
      refetchInvoices()
    }
  }, [isMobileFiltersOpen, refetchInvoices])

  return (
    <FiltersDrawer
      isOpen={isMobileFiltersOpen}
      onClose={onCloseClick}
      title={renderHeaderTitle()}
      overlayClassName="animated-drawer-overlay"
    >
      <Divider margin="0 0 8px" />
      <OptionsWrapper>
        {filterState === FilterState.FilterAndSort &&
          FilterAndSortOptions.map(({ value, label }) => (
            <OptionButton
              key={value}
              icon={ArrowRight}
              onClick={onOptionSelected(value)}
            >
              <span>
                {label}
                {value === FilterState.SortBy && (
                  <OptionalContent>
                    {renderOptionalSortByContent()}
                  </OptionalContent>
                )}
                {value === FilterState.FilterByStatus && (
                  <OptionalContent>
                    {renderOptionalStatusContent()}
                  </OptionalContent>
                )}
                {value === FilterState.FilterByDueDate && (
                  <OptionalContent>
                    {renderOptionalDateContent()}
                  </OptionalContent>
                )}
                {value === FilterState.FilterByAmount && (
                  <OptionalContent>
                    {renderOptionalAmountContent()}
                  </OptionalContent>
                )}
                {value === FilterState.FilterBySearch && (
                  <OptionalContent>
                    {renderOptionalSearchContent()}
                  </OptionalContent>
                )}
              </span>
            </OptionButton>
          ))}
        {filterState !== FilterState.FilterAndSort && renderFilterOptions()}
      </OptionsWrapper>
      {filterState === FilterState.FilterAndSort && (
        <BottomWrapper>
          <ButtonGhost onClick={resetState} dataTestId="clear-filters-btn">
            {translate('shared.clear')}
          </ButtonGhost>
        </BottomWrapper>
      )}
    </FiltersDrawer>
  )
}
