import { SvgIcon } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { ReactComponent as Close } from 'assets/svg/close-grey.svg'
import { ButtonProps } from 'types/button'
import { component } from 'translations'

import {
  StyledActionLabelWrapper,
  StyledCloseIconWrapper,
  StyledLabel,
} from './Card.styled'

const ModalToggle = ({ onClick, children }: ButtonProps) => (
  <StyledLabel data-testid="card-action-label" onClick={onClick}>
    {children}
  </StyledLabel>
)

interface Props {
  modalComponent?: (fn: () => void) => React.ReactElement
  hasCloseIcon?: boolean
  actionLabel?: string
}

/**
 * @deprecated
 */
export const CardModal = ({
  hasCloseIcon,
  actionLabel,
  modalComponent,
}: Props) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <ModalToggle onClick={openModal}>
        {hasCloseIcon ? (
          <StyledCloseIconWrapper
            tabIndex={0}
            width="32px"
            height="32px"
            justifyContent="center"
            alignItems="center"
          >
            <SvgIcon width="16" height="30">
              <Close />
            </SvgIcon>
          </StyledCloseIconWrapper>
        ) : (
          <StyledActionLabelWrapper>
            {actionLabel || component.card.remove}
          </StyledActionLabelWrapper>
        )}
      </ModalToggle>

      <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
        {modalComponent ? modalComponent(closeModal) : <></>}
      </ModalBasic>
    </>
  )
}
