import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { PageTemplate } from '@npco/zeller-design-system'

import { TransactionUpdates } from 'services/transactionUpdates/TransactionUpdates'

import { GlobalErrorPage } from '../Fallbacks'
import { useDashboardErrors } from '../hooks/useDashboardErrors'
import { DashboardHeader } from './DashboardHeader/DashboardHeader'
import { DashboardTabs } from './DashboardTabs'

interface DashboardLayoutProps {
  children: React.ReactNode
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { userRole } = useLoggedInUser()
  const { shouldDisplayError, retry } = useDashboardErrors()

  if (shouldDisplayError) {
    return <GlobalErrorPage retry={retry} />
  }

  return (
    <TransactionUpdates>
      <PageTemplate
        HeaderPrimaryRow={<DashboardHeader />}
        HeaderSecondaryRow={
          userRole === CustomerRole.ADMIN ? <DashboardTabs /> : null
        }
      >
        {children}
      </PageTemplate>
    </TransactionUpdates>
  )
}
