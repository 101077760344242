import { createTranslations } from '@npco/utils-translations'

export const cardEmptyViewDebitTranslations = createTranslations({
  title: 'Add a Zeller Debit Card for smarter business spending.',
  description:
    'Create a virtual card to spend instantly, or order a physical card to spend in-store.',
  item1: 'Manage team expenses and track spending',
  item2: 'Attach notes, receipts and invoices to transactions',
  item3: 'Unlimited transactions and no monthly card fees',
  ctaDescription:
    'Add your first card and link to your nominated Transaction Account to get started.',
  imageAlt: 'Two Zeller-branded debit cards floating: one white, one black',
})
