import { TransactionExportFormat } from '@npco/mp-gql-types'
import { useExportTransactionsModalContentCommon } from 'features/ExportTransactions/hooks/useExportTransactionsModalContentCommon'

import { useExportTransaction } from 'pages/Dashboard/DashboardTransactions/hooks/useExportTransactions/useExportTransaction'

interface Props {
  filename: string
  closeModal: () => void
  setHasTimePassed: React.Dispatch<React.SetStateAction<boolean>>
  format: TransactionExportFormat
}

export const useExportTransactionsModalContent = ({
  filename,
  closeModal,
  setHasTimePassed,
  format,
}: Props) => {
  const { isLoading, hasError, hasLoaded } = useExportTransaction(
    filename,
    format
  )

  useExportTransactionsModalContentCommon({
    closeModal,
    setHasTimePassed,
    isLoading,
    hasError,
  })

  return {
    isLoading,
    hasError,
    hasLoaded,
  }
}
