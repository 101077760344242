import { Flex } from '@npco/zeller-design-system'

import { ReactComponent as DebitCardLoading } from 'assets/svg/cards/debit-card-loading.svg'
import { CardSummary } from 'components/CardSummary/CardSummary'

import { DebitCardDetailsSkeleton } from './DebitCardDetailsSkeleton/DebitCardDetailsSkeleton'

export const DebitCardSummarySkeleton = () => {
  return (
    <CardSummary
      cardDisplay={
        <Flex data-testid="skeleton-card">
          <DebitCardLoading />
        </Flex>
      }
      cardDetails={<DebitCardDetailsSkeleton />}
    />
  )
}
