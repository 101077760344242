import { Outlet } from 'react-router-dom-v5-compat'
import { Flex, PageTemplate, Tabs } from '@npco/zeller-design-system'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'

import { useManageXeroPaymentServicesTabs } from './hooks/useManageXeroPaymentServicesTabs'

export const ManageXeroPaymentServicesLayout = () => {
  const { tabs, currentTab } = useManageXeroPaymentServicesTabs()
  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            <Breadcrumbs />
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderSecondaryRow={
        <PageTemplate.HeaderSecondaryRow>
          <Tabs
            currentTab={currentTab}
            dataTestId="manage-xero-payment-services"
          >
            {tabs.map((tab) => (
              <Tabs.Item key={tab.value} {...tab} />
            ))}
          </Tabs>
        </PageTemplate.HeaderSecondaryRow>
      }
    >
      <PageTemplate.Section>
        <Outlet />
      </PageTemplate.Section>
    </PageTemplate>
  )
}
