import {
  Flex,
  Heading,
  HeadingStyles,
  PageTemplate,
  Typography,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { ContactSupport } from 'pages/Help/ContactSupport/ContactSupport'

export const Help = () => {
  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px" alignItems="center">
            <Heading.H1 withStyles={HeadingStyles.H2}>
              {translate('page.help.headline')}
            </Heading.H1>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <Flex flexDirection="column" mb="32px">
        <Typography component="p" variant="body-lg">
          {translate('page.help.copy')}
        </Typography>
        <Typography component="p" variant="body-lg">
          {translate('page.help.copyNewline')}
        </Typography>
      </Flex>
      <ContactSupport />
    </PageTemplate>
  )
}
