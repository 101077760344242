import { ReactNode } from 'react'
import { AvatarBasic, AvatarProps } from '@npco/zeller-design-system'

import { AVATAR_BADGE_SIZE } from 'types/common'

import { SelectSize } from '../Select.types'
import {
  StyledIconWrapper,
  StyledLeftAdornmentWrapper,
} from './SelectTriggerAdaptive.styled'

const getAvatarSize = (size: SelectSize) => {
  if (size === SelectSize.Medium || size === SelectSize.Small) {
    return AVATAR_BADGE_SIZE.XSMALL
  }

  return AVATAR_BADGE_SIZE.SMALL
}

interface SelectTriggerLeftAdornmentProps {
  avatar?: Pick<AvatarProps, 'bgColor' | 'color'> & { letter?: ReactNode }
  icon?: ReactNode
  image?: ReactNode
  size: SelectSize
}

export const SelectTriggerLeftAdornment = ({
  avatar,
  image,
  icon,
  size,
}: SelectTriggerLeftAdornmentProps) => {
  if (!image && !icon && !avatar) {
    return null
  }

  return (
    <StyledLeftAdornmentWrapper data-testid="content-left-adornment">
      {image}
      {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
      {avatar && (
        <AvatarBasic
          bgColor={avatar.bgColor}
          color={avatar.color}
          text={avatar.letter as string}
          badgeSize={getAvatarSize(size)}
        />
      )}
    </StyledLeftAdornmentWrapper>
  )
}
