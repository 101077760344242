import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import {
  RunTriggerManualBankfeedsSync as RunTriggerManualBankfeedsSyncResponse,
  RunTriggerManualBankfeedsSyncVariables,
} from 'types/gql-types/RunTriggerManualBankfeedsSync'

import { RunTriggerManualBankfeedsSync } from './TriggerManualBankfeedsSync.graphql'

export const useTriggerManualBankfeedsSync = () => {
  const entityUuid = useSelectedEntityUuid()
  const [manualBankfeedsSync, { loading, error }] = useMutation<
    RunTriggerManualBankfeedsSyncResponse,
    RunTriggerManualBankfeedsSyncVariables
  >(RunTriggerManualBankfeedsSync, {
    onError: (err) => {
      showApiErrorToast(err)
    },
    variables: {
      entityUuid,
    },
    notifyOnNetworkStatusChange: true,
  })
  return {
    isLoading: loading,
    error,
    manualBankfeedsSync,
  }
}

useTriggerManualBankfeedsSync.gql = RunTriggerManualBankfeedsSync
useTriggerManualBankfeedsSync.gqlData = undefined as unknown as boolean
