import { gql, type TypedDocumentNode } from '@apollo/client'

export type IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    income: { __typename?: 'Money'; value: string }
    expense: { __typename?: 'Money'; value: string }
  }
}

export const IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragmentDoc = gql`
  fragment IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
    transactionDirectionTotalAmounts {
      income {
        value
      }
      expense {
        value
      }
    }
  }
` as unknown as TypedDocumentNode<
  IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment,
  undefined
>
