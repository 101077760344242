import { useContext, useEffect } from 'react'

import { DrawerNavigationContext } from '../../DrawerNavigation.context'

interface UseDisableNavigationProps {
  shouldDisableNavigation?: boolean
}

export const useDisableNavigation = ({
  shouldDisableNavigation = true,
}: UseDisableNavigationProps = {}) => {
  const { isNavigationDisabled, setIsNavigationDisabled } = useContext(
    DrawerNavigationContext
  )

  useEffect(() => {
    if (shouldDisableNavigation) {
      setIsNavigationDisabled(true)
    }

    return () => {
      if (shouldDisableNavigation) {
        setIsNavigationDisabled(false)
      }
    }
  }, [shouldDisableNavigation, setIsNavigationDisabled])

  return { isNavigationDisabled }
}
