export const MAX_NUMBER_REMAINDER_LENGTH = 2

export const NUMBER_LOCALE = 'en-AU'

export const NUMBER_LOCALE_OPTIONS = {
  minimumFractionDigits: MAX_NUMBER_REMAINDER_LENGTH,
  maximumFractionDigits: MAX_NUMBER_REMAINDER_LENGTH,
}

export const convertNumberToLocaleString = (
  number: number,
  overrideOptions?: Intl.NumberFormatOptions | undefined
) =>
  number.toLocaleString(NUMBER_LOCALE, {
    ...NUMBER_LOCALE_OPTIONS,
    ...(overrideOptions || {}),
  })

export const convertLocaleStringToNumberOrNull = <T extends number | null>(
  numberString: string,
  fallbackValue: T = null as T
) => {
  // NOTE: number string values can be formatted to locale string so can
  // include commas e.g 45,657.00
  const priceNumberString = numberString.replace(/,/g, '')
  const priceNumber = Number(priceNumberString)
  const priceValue = Number.isFinite(priceNumber) ? priceNumber : fallbackValue

  return priceValue
}

export const convertLocaleStringToNumber = (
  numberString: string,
  fallbackValue = 0
) => convertLocaleStringToNumberOrNull(numberString, fallbackValue)
