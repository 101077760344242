import {
  BodySmall,
  ButtonFill,
  Heading,
  HeadingStyles,
  ModalBasic,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface ErrorContentProps {
  title: string
  subtitle: string
  refetch: () => void
}
export const ErrorContent = ({
  title,
  subtitle,
  refetch,
}: ErrorContentProps) => (
  <ModalBasic.Body alignItems="center" justifyContent="center" height="100%">
    <Heading.H2 withStyles={HeadingStyles.H3}>{title}</Heading.H2>
    <BodySmall>{subtitle}</BodySmall>
    <ButtonFill onClick={() => refetch()} style={{ marginTop: '18px' }}>
      {translate('page.addCardModal.shared.tryAgainButton')}
    </ButtonFill>
  </ModalBasic.Body>
)
