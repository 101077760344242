import { Box, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

const afterBorderStyle = css`
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.GREY_60};
  position: absolute;
  top: 76px;
  z-index: 99;
`

export const TransactionsSummaryHeader = styled(Flex)`
  position: relative;
  flex-direction: column;
  white-space: nowrap;
  gap: 16px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    align-items: center;

    &::after {
      ${afterBorderStyle}
    }
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    &::after {
      content: none;
    }
  }
`

export const TransactionsSummaryContent = styled(Box)`
  position: relative;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    &::after {
      ${afterBorderStyle}
    }
  }
`

export const TransactionsSummaryContentLayout = styled(Flex)`
  flex-direction: column-reverse;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    flex-direction: row;
    justify-content: end;
    gap: 10px;
  }
`

export const TransactionsSummaryContentLayoutTable = styled(Flex)`
  flex-direction: column;
  align-items: start;
  gap: 16px;
  margin-top: 26px;
`

export const TransactionsSummaryContentLayoutChart = styled(Box)`
  display: flex;
  flex-direction: column;
`
