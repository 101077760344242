import { Flex, H6 } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'
import { page } from 'translations'

import { StyledHeader } from '../../ReportsChart.styled'
import { getFormattedDayName } from './TooltipContent.utils'

interface Props {
  label?: number
  value?: number
}

export const TooltipContent = ({ label, value }: Props) => {
  if (label === undefined || value === undefined) {
    return null
  }

  return (
    <Flex
      data-testid="tooltip-content"
      alignItems="center"
      flexDirection="column"
    >
      <StyledHeader data-testid="tooltip-content-header">
        {page.overview.charts.dayOfWeekChartTooltip}
        <br />
        {getFormattedDayName(label)}s
      </StyledHeader>
      <H6 as="div">{currencyFormatter(value)}</H6>
    </Flex>
  )
}
