import {
  BodyDefault,
  BodyDefaultTypography,
  Box,
  BREAKPOINT,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const FieldWrapper = styled(Flex)`
  flex-direction: column;
`

export const ItemWrapper = styled(Box)<{
  $isFullWidth?: boolean
  $isRight?: boolean
}>`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  position: relative;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '50%')};
    padding-left: ${({ $isFullWidth, $isRight }) =>
      $isFullWidth || !$isRight ? '0' : '10px'};
    padding-right: ${({ $isFullWidth, $isRight }) =>
      $isFullWidth || $isRight ? '0' : '10px'};
`

export const TextInputPrefix = styled.span`
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodyDefaultTypography};
  transition: color 0.3s ease-out;
`

export const Description = styled(BodyDefault)`
  margin: 8px 0 0;
`
