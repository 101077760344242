import TextTruncate from 'react-text-truncate'
import { AnchorBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface Props {
  url: string | null | undefined
}

const getWebsiteValue = (website: string | null | undefined) => {
  if (!website) {
    return '-'
  }
  return (
    <AnchorBasic href={website} target="_blank">
      <TextTruncate line={2} text={website} element="span" />
    </AnchorBasic>
  )
}

export const WebsiteItem = ({ url }: Props) => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.website')}
      value={getWebsiteValue(url)}
    />
  )
}
