import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box, Uploader } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { FileList, FileWithId } from 'components/File'
import { useFileList } from 'components/UploadFileModal/hooks/useFileList'
import { UploadFileModal } from 'components/UploadFileModal/UploadFileModal'

import { dragAndDropStepTranslations } from './DragAndDropStep.i18n'

const FIFTY_MB = 500 * 1024 * 1024
const ALLOWED_FILE_TYPES = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/tiff': ['.tiff'],
  'image/png': ['.png'],
  'image/heic': ['.heic'],
  'image/heif': ['.heif'],
  'image/webp': ['.webp'],
}

interface Props {
  maxFiles: number
  remainingFiles: number
  isOpen: boolean
  goToNextStep: (files: FileWithId[]) => void
  onCancel: () => void
  isLoadingImages: boolean
}

export const DragAndDropStep = ({
  maxFiles,
  remainingFiles,
  goToNextStep,
  isOpen,
  onCancel,
  isLoadingImages,
}: Props) => {
  const { files, removeFile, addFiles, error, handleUploadRejected } =
    useFileList(remainingFiles)

  const onSave = () => {
    goToNextStep(files)
  }

  const t = useTranslations(dragAndDropStepTranslations)

  const isMaxFilesReached = remainingFiles - files.length <= 0

  const subText = useMemo(() => {
    if (isLoadingImages) {
      return ''
    }

    return isMaxFilesReached
      ? t('subTextWhenMaxFilesReached', { maxFiles })
      : t('subText', { maxFiles: remainingFiles - files.length })
  }, [
    isLoadingImages,
    isMaxFilesReached,
    remainingFiles,
    files.length,
    maxFiles,
    t,
  ])

  return (
    <UploadFileModal
      title={t('title')}
      isOpen={isOpen}
      onSave={onSave}
      primaryButtonLabel={translate('shared.upload')}
      onCancel={onCancel}
      isPrimaryButtonDisabled={files.length === 0}
    >
      <FileList files={files} removeFile={removeFile} />
      <Uploader
        allowedFileTypes={ALLOWED_FILE_TYPES}
        maxSize={FIFTY_MB}
        maxFiles={remainingFiles - files.length}
        text={!isMaxFilesReached && t('text')}
        linkText={!isMaxFilesReached && t('linkText')}
        icon="document"
        subText={<Box minHeight="20px">{subText}</Box>}
        description={t('description')}
        onUploadAccepted={addFiles}
        onUploadRejected={handleUploadRejected}
        error={error}
        dataTestId="drag-and-drop-uploader"
        isDisabled={isLoadingImages || isMaxFilesReached}
      />
    </UploadFileModal>
  )
}
