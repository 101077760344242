export enum InvoiceStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Ended = 'Ended',
  Overdue = 'Overdue',
  Paid = 'Paid',
  PartPaid = 'PartPaid',
  Sent = 'Sent',
}

export enum AmountColumnEnum {
  AmountPaid = 'AmountPaid',
  Outstanding = 'Outstanding',
  Total = 'Total',
}

export enum DateColumnEnum {
  NextDue = 'NextDue',
  Created = 'Created',
  Issued = 'Issued',
  Sent = 'Sent',
  DatePaid = 'DatePaid',
}

export enum SortableInvoiceTableColumnEnum {
  ID = 'Id',
  Contact = 'Contact',
  Status = 'Status',
}

export enum RemainingInvoiceTableColumnEnum {
  Default = 'Default',
  Title = 'Title',
}

export enum MobileInvoiceTableColumnEnum {
  Mobile = 'Mobile',
}

export const InvoiceTableColumn = {
  ...SortableInvoiceTableColumnEnum,
  ...DateColumnEnum,
  ...AmountColumnEnum,
}

export const AllInvoiceTableColumn = {
  ...InvoiceTableColumn,
  ...RemainingInvoiceTableColumnEnum,
  ...MobileInvoiceTableColumnEnum,
}
