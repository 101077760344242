import { useCallback, useEffect, useState } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { InputSelectComboBoxItem } from 'features/Contacts/Contacts.types'
import { useBPayTransferMFAState } from 'features/MFA'
import { useContactTransferMFAState } from 'features/MFA/MFAContext/hooks/useContactTransferMFAState/useContactTransferMFAState'
import { Formik, FormikHelpers } from 'formik'

import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'
import { TransferFields } from 'pages/Transfer/TransferFields/TransferFields'

import { BPayTransferModal } from './BPay/bpay-transfer-modal'
import { useBPayTransferModal } from './BPay/bpay-transfer-routing'
import { useOpenBPayTransferModal } from './BPay/bpay-transfer-routing/hooks/useOpenBPayTransferModal/useOpenBPayTransferModal'
import { ContactTransferModal } from './ContactTransfer/ContactTransferModal/ContactTransferModal'
import { useGoToContactTransferModal } from './ContactTransfer/ContactTransferModal/hooks/useGoToContactTransferModal/useGoToContactTransferModal'
import { useOpenContactTransferModal } from './hooks/useOpenContactTransferModal/useOpenContactTransferModal'
import { useOpenInternalTransferModal } from './hooks/useOpenInternalTransferModal/useOpenInternalTransferModal'
import { useTransferQuery } from './hooks/useTransferQuery/useTransferQuery'
import { useUpdateContactIsSelf } from './hooks/useUpdateContactIsSelf/useUpdateContactIsSelf'
import { InternalTransferModal } from './Internal/internal-transfer-modal'
import { TransferEvent, TransferGeneralState } from './Transfer.stateMachine'
import { StyledTransferLayout } from './Transfer.styled'

interface TransferContentProps {
  initialFromAccountId: string
  initialToAccountId?: string
  reference?: string
  accountById: ReturnType<typeof useTransferQuery>['accountById']
}
export const TransferContent = ({
  initialFromAccountId,
  initialToAccountId,
  reference,
  accountById,
}: TransferContentProps) => {
  const {
    transferState: [state, send],
    updateTransferValues,
  } = useTransferState()

  const { updateContactInContactTransfer, updateContactInBpayTransfer } =
    useUpdateContactIsSelf()

  const flags = useFeatureFlags()

  const { openContactTransferModal } = useOpenContactTransferModal()
  const { openBPayModal } = useOpenBPayTransferModal()
  const openInternalTransferModal = useOpenInternalTransferModal()

  const {
    hasRedirectedBackToApp: hasRedirectedBackToAppBPay,
    bpayTransferState,
  } = useBPayTransferMFAState()
  const { openBPayTransferModal } = useBPayTransferModal()

  useEffect(() => {
    if (hasRedirectedBackToAppBPay && bpayTransferState) {
      send(TransferEvent.ToBpayTransfer)
      openBPayTransferModal(
        {
          transferDetails: bpayTransferState.values,
          stage: 'summary',
        },
        { replace: true }
      )
    }
  }, [
    hasRedirectedBackToAppBPay,
    bpayTransferState,
    openBPayTransferModal,
    send,
  ])

  const {
    hasRedirectedBackToApp: hasRedirectedBackToAppContact,
    transferState,
  } = useContactTransferMFAState()
  const { openContactTransferModal: openContactTransferModalMFA } =
    useGoToContactTransferModal()

  useEffect(() => {
    if (hasRedirectedBackToAppContact && transferState) {
      openContactTransferModalMFA(
        {
          values: transferState.values,
          stage: 'summary',
        },
        { replace: true }
      )
    }
  }, [
    hasRedirectedBackToAppContact,
    transferState,
    openContactTransferModalMFA,
  ])

  const handleSubmit = useCallback(
    async (
      values: TransferFieldTypes,
      helpers: FormikHelpers<TransferFieldTypes>
    ) => {
      const errors = await helpers.validateForm()
      if (Object.keys(errors).length !== 0) {
        return
      }

      if (flags.SweepingTracking) {
        if (state.matches(TransferGeneralState.ContactTransfer)) {
          updateContactInContactTransfer(values)
        } else if (state.matches(TransferGeneralState.BpayTransfer)) {
          updateContactInBpayTransfer(values)
        }
      }

      updateTransferValues(values)

      if (state.matches(TransferGeneralState.ContactTransfer)) {
        openContactTransferModal({ values, accountById })
        return
      }

      if (state.matches(TransferGeneralState.ZellerTransfer)) {
        openInternalTransferModal(values, accountById)
        return
      }

      if (state.matches(TransferGeneralState.BpayTransfer)) {
        openBPayModal({ values, accountById })
      }
    },
    [
      flags.SweepingTracking,
      updateTransferValues,
      state,
      updateContactInContactTransfer,
      updateContactInBpayTransfer,
      openContactTransferModal,
      accountById,
      openInternalTransferModal,
      openBPayModal,
    ]
  )

  const [selectedContact, setSelectedContact] =
    useState<InputSelectComboBoxItem | null>(null)

  return (
    <StyledTransferLayout>
      <Formik<TransferFieldTypes>
        initialValues={{
          to: initialToAccountId ?? null,
          contact: '',
          from: initialFromAccountId,
          code0: '',
          code1: '',
          code2: '',
          code3: '',
          amount: '',
          reference: reference ?? '',
          recipientReference: '',
          isSelf: undefined,
        }}
        onSubmit={handleSubmit}
      >
        <>
          <ContactTransferModal
            onNewTransfer={() => {
              setSelectedContact(null)
              send(TransferEvent.Reset)
            }}
          />
          <BPayTransferModal />
          <InternalTransferModal />
          <TransferFields
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
        </>
      </Formik>
    </StyledTransferLayout>
  )
}
