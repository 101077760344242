import { rvNewSites } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { SiteCache } from 'types/site'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import { SessionStorageHandlerSlice } from 'services/sessionStorage/SessionStorageHandlerSlice'

export const SitesStorageHandler = () => {
  return (
    <SessionStorageHandlerSlice<SiteCache[]>
      reactiveVarHandler={rvNewSites}
      storageKey={SESSION_STORAGE_KEYS.SITES_NEW_SITES}
      defaultValue={[]}
    />
  )
}
