import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showSuccessToast } from '@npco/zeller-design-system'
import { FormikHelpers } from 'formik'

import { useCompanyLogoS3Bucket } from 'hooks/useCompanyLogoS3Bucket'
import { useRemoveSiteLogo } from 'hooks/useRemoveSiteLogo'
import { useGetXeroSiteSettings } from 'hooks/xeroPaymentServices/useGetXeroSiteSettings'
import { translate } from 'utils/translations'
import { GetXeroSiteSettings_getXeroSiteSettings as GetXeroSiteSettingsResponseData } from 'types/gql-types/GetXeroSiteSettings'

import { useUpdateXeroSiteSettings } from '../hooks/useUpdateXeroSiteSettings'
import { ReceiptFormFields } from './Receipt.types'
import {
  convertReceiptFormFieldsToSettingsInput,
  displayUpdateFailedToast,
} from './Receipt.utils'

export const useReceiptHook = () => {
  const entityUuid = useSelectedEntityUuid()
  const { siteSettings, updateSiteSettingsCache, isLoading } =
    useGetXeroSiteSettings()
  const { updateXeroSiteSettings, isUpdatingXeroSiteSettings } =
    useUpdateXeroSiteSettings()

  const { removeSiteLogo } = useRemoveSiteLogo()

  const { uploadCompanyLogoToS3Bucket } = useCompanyLogoS3Bucket({
    siteUuid: siteSettings?.id,
  })

  const handleUploadLogo = async (values: ReceiptFormFields) => {
    const { logo: logoUrl, logoFile } = values

    const hasChangedLogo = logoUrl !== siteSettings?.receipt?.logo
    const hasRemovedLogo = Boolean(siteSettings?.receipt?.logo && !logoUrl)

    if (!hasChangedLogo) {
      return
    }

    if (hasRemovedLogo) {
      await removeSiteLogo({
        variables: { entityUuid, siteUuid: siteSettings?.id ?? '' },
      })
      return
    }

    if (logoFile) {
      await uploadCompanyLogoToS3Bucket(logoFile)
    }
  }

  const handleOnSubmit = async (
    values: ReceiptFormFields,
    formikHelpers: FormikHelpers<ReceiptFormFields>
  ) => {
    // this theoretically shouldn't happen
    if (!siteSettings) {
      return
    }

    try {
      await handleUploadLogo(values)

      const formattedValues = convertReceiptFormFieldsToSettingsInput(values)
      const result = await updateXeroSiteSettings(formattedValues)

      if (result.data?.updateXeroSiteSettings) {
        const updatedValues: GetXeroSiteSettingsResponseData = {
          ...siteSettings,
          receipt: { ...values, logo: values.logo || '' },
        }

        updateSiteSettingsCache(updatedValues)

        formikHelpers.resetForm({ values })

        showSuccessToast(
          translate(
            'page.settings.connections.xeroPaymentServices.receipt.successToast'
          )
        )
      } else {
        displayUpdateFailedToast()
      }
    } catch (err) {
      displayUpdateFailedToast()
    }
  }

  return {
    siteSettings,
    handleOnSubmit,
    isUpdatingXeroSiteSettings,
    isLoadingXeroSiteSettings: isLoading,
  }
}
