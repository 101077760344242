import { z } from 'zod'

export enum LockCardStage {
  LockCard = 'LockCard',
}

const LockCardStateSchema = z.object({
  stage: z.literal(LockCardStage.LockCard),
  cardId: z.string(),
})

export type LockCardState = z.infer<typeof LockCardStateSchema>

export const LockCardLocationStateSchema = z.object({
  LockCardModal: LockCardStateSchema.optional(),
})
