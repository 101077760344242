import { createTranslations } from '@npco/utils-translations'

export const setupFlowOverviewStageTranslations = createTranslations({
  badge: 'Overview',
  title: 'Track business finances in real time.',
  description:
    "Monitor your business's financial performance with powerful reporting.",
  point1: 'Real-time business financial reporting.',
  point2: 'Compare daily, monthly, and weekly performance.',
  point3: 'Make more informed business decisions.',
  point4: 'Reports available in Zeller Dashboard or App.',
  animationDataFilenameSmall: 'setup-flow-overview-small',
  animationDataFilenameMedium: 'setup-flow-overview-medium',
  animationDataFilenameLarge: 'setup-flow-overview-large',
})
