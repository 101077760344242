import {
  DATAPOINT_TEXT_SIZE,
  DatapointAlignment,
  DatapointCurrency,
  DatapointText,
} from '@npco/zeller-design-system'

import { getCents, getWholePartFromCents } from 'utils/common'
import { translate } from 'utils/translations'

interface AvailableFundsProps {
  availableFunds: number | null
  align?: DatapointAlignment
  isLoading?: boolean
}

export const AvailableFunds = ({
  availableFunds,
  align,
  isLoading = false,
}: AvailableFundsProps) => {
  if (availableFunds === null) {
    return (
      <DatapointText
        size={DATAPOINT_TEXT_SIZE.EXTRA_LARGE}
        title={translate(
          'component.cardSummary.debitCardDetails.availableFunds'
        )}
        text="-"
        align={align}
      />
    )
  }

  const absoluteAvailableFunds = Math.abs(availableFunds)

  const dollarAmount = getWholePartFromCents(absoluteAvailableFunds).slice(1)
  const centAmount = getCents(absoluteAvailableFunds)

  return (
    <DatapointCurrency
      size={DATAPOINT_TEXT_SIZE.EXTRA_LARGE}
      title={translate('component.cardSummary.debitCardDetails.availableFunds')}
      integerText={dollarAmount}
      decimalText={centAmount}
      align={align}
      isNegative={availableFunds < 0}
      isLoading={isLoading}
      skeletonWidth="150px"
    />
  )
}
