import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translations } from '../../../Contacts.i18n'
import { getAddress } from '../../../Contacts.utils'
import { ContactDetailSection } from '../../ContactGeneral/ContactGeneral.styled'
import { CategoryPills } from '../Categories/CategoryPills'
import { mergeEmails, mergePhones } from './ContactDetails.util'
import { DetailField } from './DetailField'
import { DetailFields } from './DetailFields'

type Contact = ContactCoreFieldsFragment

interface PeopleContactDetailsProps {
  contact: Contact
  canEditCategories?: boolean
  useCategoryPills?: boolean
}

export const PEOPLE_CONTACT_DATA_TESTID = 'contact-people-details-section'

export const PeopleContactDetails = ({
  contact,
  canEditCategories = true,
  useCategoryPills = true,
}: PeopleContactDetailsProps) => {
  const t = useTranslations(translations)

  const {
    additionalEmails,
    additionalPhones,
    category,
    email,
    id,
    jobTitle,
    phoneV2,
    subcategoryUuid,
  } = contact

  const emptyState = '-'
  const defaultEmailLabel = t('email')
  const defaultPhoneLabel = t('phone')

  const allEmails = useMemo(
    () =>
      mergeEmails(email, additionalEmails, {
        emptyState,
        defaultLabel: defaultEmailLabel,
      }),
    [email, additionalEmails, defaultEmailLabel]
  )

  const allPhoneNumbers = useMemo(() => {
    return mergePhones(phoneV2, additionalPhones, {
      emptyState,
      defaultLabel: defaultPhoneLabel,
    })
  }, [phoneV2, additionalPhones, defaultPhoneLabel])

  return (
    <ContactDetailSection data-testid={PEOPLE_CONTACT_DATA_TESTID}>
      <DetailField
        label={t('address')}
        value={getAddress(contact) || emptyState}
        dataTestId="contact-address-field"
      />
      <DetailFields list={allPhoneNumbers} type="phone" />
      <DetailFields list={allEmails} type="email" />
      <DetailField
        label={t('jobTitle')}
        value={jobTitle || emptyState}
        dataTestId="contact-job-title-field"
      />
      {useCategoryPills ? (
        <CategoryPills
          category={category || null}
          subcategoryId={subcategoryUuid || null}
          contactUuid={id}
          canEditCategories={canEditCategories}
        />
      ) : (
        <DetailField
          label={t('category')}
          value={emptyState}
          dataTestId="contact-category-field"
        />
      )}
    </ContactDetailSection>
  )
}
