import { TableData } from '@npco/ui-table'

import { DebitCardAccountBalanceCell } from 'components/CardsView/components/CardsTable/components/CardBalanceCell/DebitCardAccountBalanceCell'
import { CardNameIconCell } from 'components/CardsView/components/CardsTable/components/CardNameIconCell/CardNameIconCell'
import { CardOwnerCell } from 'components/CardsView/components/CardsTable/components/CardOwnerCell/CardOwnerCell'

import { AccountCardsTableDebitCardV2Fragment } from './AccountCardsTable.generated'

export const columnsConfig = [
  {
    accessorId: 'cardName',
    cellComponent: CardNameIconCell<
      TableData<AccountCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 15, md: 10 },
    cellSkeletonWidth: [
      { _: 172, MD: 207 },
      { _: 140, MD: 184 },
      { _: 140, MD: 160 },
    ],
  },
  {
    accessorId: 'cardOwner',
    cellComponent: CardOwnerCell<
      TableData<AccountCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 0, md: 10 },
    cellSkeletonWidth: [{ MD: 156 }, { MD: 112 }, { MD: 192 }],
  },
  {
    accessorId: 'debitCardAccountForBalance',
    cellComponent: DebitCardAccountBalanceCell<
      TableData<AccountCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 9, md: 4 },
    cellSkeletonWidth: 0,
  },
]
