import React, { useEffect, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { FormExtensionBasic } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translations } from '../Contacts.i18n'
import * as styled from '../Contacts.styled'
import { ContactFormData } from '../Contacts.types'
import {
  FIELD_ADDITIONAL_EMAIL_PERSON,
  FIELD_ADDITIONAL_PHONE_PERSON,
  FIELD_EMAIL_PERSON,
  FIELD_JOB_TITLE_PERSON,
  FIELD_PHONE_PERSON,
  FIELD_STREET_PERSON,
  FieldProps,
} from './PersonShared.fields'
import { PersonFirstLastNameField } from './PersonSimple.fields'

export const FIELDS = [
  FIELD_STREET_PERSON,
  FIELD_PHONE_PERSON,
  FIELD_ADDITIONAL_PHONE_PERSON,
  FIELD_EMAIL_PERSON,
  FIELD_ADDITIONAL_EMAIL_PERSON,
  FIELD_JOB_TITLE_PERSON,
]

const BUTTON_ORDER = [
  FIELD_STREET_PERSON.itemId,
  FIELD_ADDITIONAL_PHONE_PERSON.itemId,
  FIELD_ADDITIONAL_EMAIL_PERSON.itemId,
  FIELD_JOB_TITLE_PERSON.itemId,
]

interface PersonFieldProps {
  existingContactName?: string | null
}

export const PersonFields = React.memo(
  ({ existingContactName }: PersonFieldProps) => {
    const { values, validateForm } = useFormikContext<ContactFormData>()
    const t = useTranslations(translations)

    useEffect(() => {
      // This is needed to correctly update validation when email or phone updates
      // Otherwise Formik's default validation and field update ordering shows incorrect error messages
      // eslint-disable-next-line no-console
      validateForm().catch(() => console.error('failed to validate form'))
    }, [validateForm, values.person.email.email, values.person.phoneV2?.phone])

    const extendedFormFields = useMemo(() => {
      return FIELDS.map((field: FieldProps) => ({
        ...field,
        initialDisplayedElementCount:
          field.initialDisplayedElementCount?.(values),
        shouldDisplayContentOnMount: field.shouldDisplayContentOnMount(values),
      }))
      // Run on mount to get initial displayed items for form extension
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <PersonFirstLastNameField existingContactName={existingContactName} />
        <styled.FormExtensionContainer>
          <FormExtensionBasic
            buttonOrder={BUTTON_ORDER}
            labelText={t('extendedFormFieldsLabel')}
          >
            {extendedFormFields}
          </FormExtensionBasic>
        </styled.FormExtensionContainer>
      </>
    )
  }
)
