import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { InternalTransferLocationSchema } from '../../InternalTransfer.types'

export const useInternalTransferState = () => {
  const maybeState = useLocationState(InternalTransferLocationSchema)

  const state = useMemo(() => {
    if (maybeState === undefined) {
      return null
    }

    return maybeState.InternalTransferModal
  }, [maybeState])

  return { state }
}
