import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { CellContext } from '@tanstack/react-table'

import { CardNameIconCellDebitCardV2Fragment } from './CardNameIconCell.generated'
import * as styled from './CardNameIconCell.styled'
import { CardNameIconCellBase } from './components/CardNameIconCellBase/CardNameIconCellBase'

export const CardNameIconCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, CardNameIconCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CardNameIconCellDebitCardV2Fragment>()

  return (
    <CardNameIconCellBase
      card={data}
      content={<styled.TruncateText>{data?.name}</styled.TruncateText>}
      skeletonPropWidth={skeletonLoaderWidth}
      cellId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
    />
  )
}

CardNameIconCell.fragments = {
  DebitCardV2: gql`
    fragment CardNameIconCellDebitCardV2Fragment on DebitCardV2 {
      name
      ...CardNameIconCellBaseDebitCardV2Fragment
    }

    ${CardNameIconCellBase.fragments.DebitCardV2}
  `,
}
