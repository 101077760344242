import {
  DebitCardTransactionFilterInputV2,
  DebitCardTransactionsExportFormat,
} from '@npco/mp-gql-types'
import { useExportTransactionsModalContentCommon } from 'features/ExportTransactions/hooks/useExportTransactionsModalContentCommon'

import { useDebitCardTransactionsExport } from './useDebitCardTransactionsExport'

interface Props {
  filename: string
  closeModal: () => void
  setHasTimePassed: React.Dispatch<React.SetStateAction<boolean>>
  format: DebitCardTransactionsExportFormat
  filters: DebitCardTransactionFilterInputV2
}

export const useDebitCardTransactionsExportModalContent = ({
  filename,
  closeModal,
  setHasTimePassed,
  format,
  filters,
}: Props) => {
  const { isLoading, hasError, hasLoaded } = useDebitCardTransactionsExport({
    filename,
    format,
    filters,
  })

  useExportTransactionsModalContentCommon({
    closeModal,
    setHasTimePassed,
    isLoading,
    hasError,
  })

  return {
    isLoading,
    hasError,
    hasLoaded,
  }
}
