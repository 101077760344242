import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { UNEXPECTED_ERROR } from 'types/errors'

import { useSaveOutstandingTransactionRequirementConfigMutation } from '../../../../graphql/saveOutstandingTransactionRequirementConfig.generated'
import { OutstandingExpensesFormFragment } from '../OutstandingExpensesForm.generated'
import { outstandingExpensesFormTranslations } from '../OutstandingExpensesForm.i18n'
import { OutstandingExpensesRequirementConfigValue } from '../OutstandingExpensesForm.types'

export const useOutstandingExpensesForm = (
  entity: OutstandingExpensesFormFragment
) => {
  const t = useTranslations(outstandingExpensesFormTranslations)
  const [saveRequirementConfigMutation, { loading }] =
    useSaveOutstandingTransactionRequirementConfigMutation()

  const handleSaveRequirementConfig = useCallback(
    async (values: OutstandingExpensesRequirementConfigValue) => {
      const updatedConfig = {
        attachments: values.isReceiptRequired,
        category: values.isCategoryRequired,
        note: values.isNoteRequired,
        accountingCategory:
          entity.outstandingTransactionRequirementConfig?.accountingCategory,
      }

      try {
        const response = await saveRequirementConfigMutation({
          variables: {
            entity: {
              id: entity.id,
              outstandingTransactionRequirementConfig: updatedConfig,
            },
          },
          update: (cache, { data }) => {
            if (data?.updateEntity) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Entity',
                  id: entity.id,
                }),
                fields: {
                  outstandingTransactionRequirementConfig() {
                    return updatedConfig
                  },
                },
              })
              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'getDebitCardsV2',
              })
              cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'getDebitCardV2',
              })
              cache.gc()
            }
          },
        })

        if (!response.data || response.errors || !response.data.updateEntity) {
          return UNEXPECTED_ERROR
        }

        return response.data
      } catch (error) {
        return UNEXPECTED_ERROR
      }
    },
    [saveRequirementConfigMutation, entity]
  )

  const handleSubmitForm = useCallback(
    async (
      values: OutstandingExpensesRequirementConfigValue,
      { resetForm }
    ) => {
      const configValue = entity.outstandingTransactionRequirementConfig
      const isValuesModified =
        values.isNoteRequired !== Boolean(configValue?.note) ||
        values.isCategoryRequired !== Boolean(configValue?.category) ||
        values.isReceiptRequired !== Boolean(configValue?.attachments)

      if (!isValuesModified) {
        showSuccessToast(t('successMessage'))
        return
      }

      const result = await handleSaveRequirementConfig(values)

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('errorMessage'))
        return
      }
      resetForm({ values })
      showSuccessToast(t('successMessage'))
    },
    [
      handleSaveRequirementConfig,
      t,
      entity.outstandingTransactionRequirementConfig,
    ]
  )

  return { handleSubmitForm, loading }
}
