import {
  DataMediumTypography,
  Flex,
  Heading,
  zIndexMap,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const commonLinkStyles = css`
  ${DataMediumTypography};

  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.BLACK};
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-left: 10px;
  padding-right: 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
    text-decoration: none;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    height: 40px;
  }
`

const foldedLinkStyles = (isFolded: boolean, isSelected: boolean) => css`
  &:before {
    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      content: '';
      position: absolute;
      left: 0px;
      background-color: ${({ theme }) => theme.colors.BLUE_80};
      border-left: 0px solid transparent;
      ${isSelected &&
      isFolded &&
      css`
        border-left: 4px solid ${({ theme }) => theme.colors.BLUE_1000};
      `}
      width: 100%;
      height: 40px;
      z-index: -1;
      opacity: ${isFolded ? 1 : 0};

      transition: opacity 0.2s ease-in, border-color 0.1s linear,
        border-left-width 0.2s linear;
    }
  }
`

export const commonFoldedLinkStyles = (isFolded: boolean) => css`
  &.active:not(.unselected) {
    ${foldedLinkStyles(isFolded, true)}
  }

  &:hover {
    ${foldedLinkStyles(isFolded, false)}
  }
`

export const Container = styled.section<{
  $isFolded: boolean
}>`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: ${({ $isFolded }) => ($isFolded ? 'none' : 'block')};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 16px 12px;
  position: relative;
  z-index: ${zIndexMap.sidebar};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding: 32px 48px 48px 48px;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    background-color: ${({ theme }) => theme.colors.GREY_20};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.colors.GREY_90};
    min-width: unset;
    padding: 12px;
    width: ${({ $isFolded }) => ($isFolded ? 64 : 232)}px;
    opacity: 1;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    width: ${({ $isFolded }) => ($isFolded ? 64 : 264)}px;
  }

  transition: width 0.24s cubic-bezier(0.42, 0, 0.58, 1),
    padding 0.2s ease-in-out;
`

export const ItemLink = styled.div<{
  $isFolded: boolean
  $marginBottomOverride?: string
}>`
  ${commonLinkStyles}
  ${({ $isFolded }) => commonFoldedLinkStyles($isFolded)}
  margin-bottom: ${({ $marginBottomOverride }) =>
    $marginBottomOverride ?? '8px'};
  background-color: transparent;
  border: 0;
  gap: 10px;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;

  &.active:not(.unselected) {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
  }
`

export const Title = styled(Heading.H4)<{ $isFolded: boolean }>`
  margin: 0 0 0 16px;
  overflow: visible;
  white-space: nowrap;
  display: block;
  opacity: ${({ $isFolded }) => ($isFolded ? 0 : 1)};

  transition: opacity 0.2s ease-in-out;
`

export const BadgeContainer = styled(Flex)`
  white-space: nowrap;
`
