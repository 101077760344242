import { CustomerRole } from '@npco/mp-gql-types'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'

import { ROOT } from 'const/routes'

export const ROUTE_PERMISSIONS = {
  [ROOT.ORGS.ORG().OVERVIEW.path]: [CustomerRole.MANAGER, CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().NOTIFICATIONS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().ACCOUNTS.TRANSFER.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().PAYMENTS.TRANSACTIONS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.path]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROOT.ORGS.ORG().PAYMENTS.TRANSACTIONS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.DEPOSITS.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().PAYMENTS.REPORTS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.CREATE_SITE.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.EDIT.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_TYPE.path]: [
    CustomerRole.ADMIN,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_DETAILS.path]: [
    CustomerRole.ADMIN,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.GENERAL.EDIT.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.GENERAL.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.DEVICES.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.PAYMENTS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.RECEIPT.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.APPEARANCE.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().PAYMENTS.DEVICES.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().INVOICING.path]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROOT.ORGS.ORG().ACCOUNTS.path]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROOT.ORGS.ORG().ACCOUNTS.TRANSACTIONS.path]: [CustomerRole.ADMIN],
  [ACCOUNT().TRANSACTIONS.path]: [CustomerRole.ADMIN],
  [ACCOUNT().CARDS.path]: [CustomerRole.ADMIN],
  [ACCOUNT().DETAILS.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().CARDS.path]: [CustomerRole.ADMIN, CustomerRole.MANAGER],
  [ROOT.ORGS.ORG().CARDS.ACTIVATE.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().CARDS.CORPORATE.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().CARDS.DEBIT.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().PAYMENTS.DEPOSITS.STATEMENTS.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().PAYMENTS.DEPOSITS.SETTINGS.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.PERSONAL.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.PERSONAL.EDIT.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.CHANGE_NUMBER.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.VALIDATE_CODE.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.USERS.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().SETTINGS.USERS.USER_EDIT.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_TYPE.path]: [CustomerRole.ADMIN],
  [ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_DETAILS.path]: [
    CustomerRole.ADMIN,
  ],
  [ROOT.ORGS.ORG().SETTINGS.PROFILE.DOCUMENTS.path]: [
    CustomerRole.ADMIN,
    CustomerRole.MANAGER,
  ],
  [ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.path]: [CustomerRole.ADMIN],
}
