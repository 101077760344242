import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  CancelCardLocationStateSchema,
  CancelCardStage,
} from 'hooks/useGoToCancelCardStage/useGoToCancelCardStage.types'

export const useCancelCardState = () => {
  const locationState = useLocationState(CancelCardLocationStateSchema)

  const state = useMemo(() => {
    if (
      !locationState?.CancelCardModal?.stage ||
      locationState.CancelCardModal.stage !== CancelCardStage.CancelCard
    ) {
      return null
    }

    return locationState.CancelCardModal
  }, [locationState?.CancelCardModal])

  return { state }
}
