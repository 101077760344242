import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Message, ZellerAccount } from '@npco/zeller-design-system'

import {
  CreateAccountStage,
  useGoToCreateAccountStage,
} from '../../../features/CreateAccount'
import { noActiveAccountsPopperTranslations } from './NoActiveAccountsPopper.i18n'

export const NoActiveAccountsPopper = () => {
  const t = useTranslations(noActiveAccountsPopperTranslations)
  const { goToCreateAccountStage } = useGoToCreateAccountStage()

  const openCreateAccountModal = useCallback(() => {
    goToCreateAccountStage(CreateAccountStage.AccountNameStage)
  }, [goToCreateAccountStage])

  return (
    <Message
      image={<ZellerAccount size="medium" />}
      title={t('title')}
      description={t('description')}
      primaryButton={{
        label: t('actionButton'),
        onClick: openCreateAccountModal,
      }}
    />
  )
}
