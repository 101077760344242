import { useCallback, useState } from 'react'
import { GetInvoicesStatusFilterInput } from '@npco/mp-gql-types'
import { ButtonFill, ButtonGhost, Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { AllStatusesSelected } from '../../InvoiceTable.utils'
import { StatusFiltersList } from '../../InvoiceTableFilters/StatusFilters/StatusFiltersList'
import { FilterState } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFilters.utils'
import { BottomWrapper } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer.styled'

interface StatusFiltersProps {
  setFilterState: (value: FilterState) => void
}

export const StatusFilters = ({ setFilterState }: StatusFiltersProps) => {
  const {
    filterInput,
    selectedStatuses,
    setSelectedStatuses,
    setStatusFilter,
  } = useInvoicesContext()

  const [nextSelectedStatuses, setNextSelectedStatuses] =
    useState(selectedStatuses)

  const [nextStatusFilter, setNextStatusFilter] =
    useState<GetInvoicesStatusFilterInput | null>(
      filterInput?.statusFilter ?? null
    )

  const handleApply = useCallback(() => {
    setSelectedStatuses(nextSelectedStatuses)
    setStatusFilter(nextStatusFilter)
    setFilterState(FilterState.FilterAndSort)
  }, [
    nextSelectedStatuses,
    nextStatusFilter,
    setSelectedStatuses,
    setStatusFilter,
    setFilterState,
  ])

  const handleClear = useCallback(() => {
    setNextSelectedStatuses(AllStatusesSelected)
    setNextStatusFilter(null)
  }, [setNextStatusFilter, setNextSelectedStatuses])

  return (
    <Flex flexDirection="column" flexGrow={1} data-testid="status-filters">
      <Flex flexDirection="column" flexGrow={1}>
        <StatusFiltersList
          selectedStatuses={nextSelectedStatuses}
          setSelectedStatuses={setNextSelectedStatuses}
          setStatusFilter={setNextStatusFilter}
        />
      </Flex>
      <BottomWrapper>
        <ButtonGhost onClick={handleClear} dataTestId="clear-filters-btn">
          {translate('shared.clear')}
        </ButtonGhost>
        <ButtonFill dataTestId="apply-filters-btn" onClick={handleApply}>
          {translate('shared.apply')}
        </ButtonFill>
      </BottomWrapper>
    </Flex>
  )
}
