import { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { PageTemplate, showErrorToast } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { SpinnerWrapped } from 'components/Spinner'

import { InvoicePreview } from '../../Invoices/Invoice/components/InvoicePreview/InvoicePreview'
import { InvoiceDrawerDetailsPdf } from '../../Invoices/InvoiceTable/InvoiceDrawer/InvoiceDrawerDetails/InvoiceDrawerDetailsPdf/InvoiceDrawerDetailsPdf'
import { useSettingsCustomisation } from './hooks/useSettingsCustomisation'
import { translations } from './SettingsCustomisation.i18n'
import styles from './SettingsCustomisation.module.css'
import * as styled from './SettingsCustomisation.styled'
import { InvoiceSettingsCustomisationFormFields } from './SettingsCustomisation.types'
import { validateSettingsCustomisationForm } from './SettingsCustomisation.utils'
import { SettingsCustomisationComingSoon } from './SettingsCustomisationComingSoon/SettingsCustomisationComingSoon'
import { SettingsCustomisationForm } from './SettingsCustomisationForm/SettingsCustomisationForm'

export const SettingsCustomisation = () => {
  const {
    handleFormSubmit,
    invoicePdf,
    invoicePdfError,
    isInvoicingEnabled,
    isLoadingSettings,
    isLoadingPreview,
    isUpdatingZellerInvoiceSettings,
    previewValues,
    setPreviewValues,
  } = useSettingsCustomisation()

  const t = useTranslations(translations)

  const [willLoadPreview, setWillLoadPreview] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const onPreviewClick = useCallback(() => {
    setIsModalOpen(true)
    if (invoicePdfError) {
      showErrorToast(t('previewErrorNotification'))
    }
  }, [invoicePdfError, t])

  if (!isInvoicingEnabled) {
    return <SettingsCustomisationComingSoon />
  }

  if (isLoadingSettings || !previewValues || isUpdatingZellerInvoiceSettings) {
    return (
      <styled.FormWrapper>
        <SpinnerWrapped variant="top" />
      </styled.FormWrapper>
    )
  }

  return (
    <>
      <PageTemplate.Section>
        <Formik<InvoiceSettingsCustomisationFormFields>
          initialValues={previewValues}
          onSubmit={handleFormSubmit}
          validate={validateSettingsCustomisationForm}
          // NOTE: only validate on save because terms and conditions validation
          // is handled manually outside the validation schema
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount={false}
        >
          <SettingsCustomisationForm
            isLoadingPreview={isLoadingPreview || willLoadPreview}
            openMobilePreview={onPreviewClick}
            setPreviewValues={setPreviewValues}
            setWillLoadPreview={setWillLoadPreview}
          />
        </Formik>
      </PageTemplate.Section>
      <PageTemplate.Section>
        {!isModalOpen ? (
          <div className={styles.Preview}>
            <InvoiceDrawerDetailsPdf
              disableDownload
              invoicePdf={invoicePdf}
              invoicePdfError={invoicePdfError}
              isLoadingPreview={isLoadingPreview || willLoadPreview}
              referenceNumber={t('referenceNumber')}
            />
          </div>
        ) : (
          !!invoicePdf && (
            <InvoicePreview
              disableDownload
              onClose={() => setIsModalOpen(false)}
              pdfFile={invoicePdf}
              referenceNumber={t('referenceNumber')}
            />
          )
        )}
      </PageTemplate.Section>
    </>
  )
}
