import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardBalanceAvailableCellDebitCardV2FragmentDoc } from './components/CardBalanceAvailableCell/CardBalanceAvailableCell.generated'

export type CorporateCardBalanceCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  status: Types.DebitCardStatus
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
  } | null
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    availableAmount: { __typename?: 'Money'; value: string }
    amountLimit: { __typename?: 'Money'; value: string }
  } | null
}

export const CorporateCardBalanceCellDebitCardV2FragmentDoc = gql`
  fragment CorporateCardBalanceCellDebitCardV2Fragment on DebitCardV2 {
    ...CardBalanceAvailableCellDebitCardV2Fragment
    id
    customer {
      id
      entityUuid
    }
  }
  ${CardBalanceAvailableCellDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CorporateCardBalanceCellDebitCardV2Fragment,
  undefined
>
