import { useCallback } from 'react'
import { CustomerRole, KycStatus } from '@npco/mp-gql-types'
import { CustomerType } from '@npco/mp-utils-logged-in-user'

import { CustomerDetailsForm } from 'types/customers'

interface UseAdminKycCheckProps {
  isEditForm?: boolean
  selectedUsersKycStatus: CustomerType['kycStatus']
}

export const useAdminKycCheck = ({
  isEditForm,
  selectedUsersKycStatus,
}: UseAdminKycCheckProps) => {
  const getIsAdminAndRCAbandoned = useCallback(
    (role: CustomerDetailsForm['role']) => {
      if (!isEditForm) {
        return false
      }
      return (
        role === CustomerRole.ADMIN &&
        selectedUsersKycStatus === KycStatus.RC_ABANDONED
      )
    },
    [isEditForm, selectedUsersKycStatus]
  )

  return {
    getIsAdminAndRCAbandoned,
  }
}
