import { DateRange } from '@npco/mp-gql-types'
import { isEmpty } from 'lodash-es'
import { equals } from 'ramda'

import { getDaysFromRange } from 'utils/common'
import dayjs from 'utils/dayjs'
import { ChartData } from 'components/Charts/Charts.types'

import { ACCOUNT_BALANCE_PLACEHOLDER_VALUES } from './AccountBalanceChart.constants'
import { AccountBalanceChartQueryResponse } from './hooks/useAccountBalanceChartQuery/graphql/AccountBalanceChartQuery.generated'

type DebitCardAccountBalances = NonNullable<
  AccountBalanceChartQueryResponse['getDebitCardAccountBalances']
>[0]

type balancePointType = {
  name: number
  value: number
}
export const getLastThreeMonthsDateRange = (): DateRange => {
  const currentDay = dayjs()
  return {
    startDate: currentDay
      .subtract(3, 'month')
      .add(1, 'day')
      .format('YYYY-MM-DD'),
    endDate: currentDay.format('YYYY-MM-DD'),
  }
}

const buildAreaChartData = (
  balanceData: balancePointType[],
  dateRange: DateRange
): ChartData => {
  const balanceMapByDate = balanceData.reduce(
    (prevBalanceMap: { [key: number]: number }, point: balancePointType) => ({
      ...prevBalanceMap,
      [point.name]: point.value,
    }),
    {}
  )
  return getDaysFromRange(dateRange.startDate, dateRange.endDate).map(
    (dateString) => {
      const mapKey = dayjs(dateString).valueOf()
      return {
        name: mapKey,
        value: balanceMapByDate[mapKey],
      }
    }
  )
}

export const getAccountBalanceForChart = (
  balances: DebitCardAccountBalances[],
  dateRange: DateRange
): ChartData => {
  const balancePoints = balances.map((balance: DebitCardAccountBalances) => ({
    name: dayjs(balance.date).valueOf(),
    value: parseInt(balance.balance.value, 10),
  }))

  if (isEmpty(balances) || equals(balances[0].date, dateRange.startDate)) {
    return buildAreaChartData(balancePoints, dateRange)
  }

  return buildAreaChartData(
    [
      {
        name: dayjs(balances[0].date).subtract(1, 'day').valueOf(),
        value: 0,
      },
      ...balancePoints,
    ],
    dateRange
  )
}

export const getAccountBalancePlaceholderForChart = (dateRange: DateRange) => {
  const placeholderValuesLength = ACCOUNT_BALANCE_PLACEHOLDER_VALUES.length

  return getDaysFromRange(dateRange.startDate, dateRange.endDate).map(
    (dateString, index) => ({
      name: dayjs(dateString).valueOf(),
      value:
        ACCOUNT_BALANCE_PLACEHOLDER_VALUES[index % placeholderValuesLength],
    })
  )
}

export const getMonthAxisDomain = (
  balances: ChartData,
  placeholderBalances: ChartData
) =>
  balances.length
    ? ['dataMin', 'dataMax']
    : [
        placeholderBalances[0].name,
        placeholderBalances[placeholderBalances.length - 1].name,
      ]
