import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { ONBOARDING_SHOP } from '@npco/mp-feature-onboarding-shop'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { rvOnboardingMetadata } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ONBOARDING_ROUTES, ROOT } from 'const/routes'

export const useRedirectBackToOnboarding = () => {
  const onboardingMetadata = useReactiveVar(rvOnboardingMetadata)
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const { userData } = useLoggedInUser()

  useEffect(() => {
    if (
      onboardingMetadata.lastRoute &&
      onboardingMetadata.isInProgress &&
      !Object.values(ONBOARDING_ROUTES).includes(pathname) &&
      !pathname.includes(ONBOARDING_SHOP.DEALS.path) &&
      pathname.includes(ROOT.ONBOARDING.path)
    ) {
      navigate(onboardingMetadata.lastRoute)
    }
  }, [
    onboardingMetadata.lastRoute,
    onboardingMetadata.isInProgress,
    pathname,
    navigate,
    userData?.kycStatus,
    userData?.registeringIndividual,
  ])
}
