import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { DetailsListItem } from 'components/DetailsListItem'

import { transactionDetailsInfoTranslations } from '../../TransactionDetailsInfo.i18n'
import {
  TransferOutReversalDetailsDebitCardAccountTransactionFragment,
  TransferOutReversalDetailsDebitCardTransactionV2Fragment,
} from './TransferOutReversalDetails.generated'

export const TransferOutReversalDetails = ({
  payerDetails,
  reference,
}:
  | TransferOutReversalDetailsDebitCardTransactionV2Fragment
  | TransferOutReversalDetailsDebitCardAccountTransactionFragment) => {
  const t = useTranslations(transactionDetailsInfoTranslations)

  return (
    <Box mt="24px">
      <DetailsListItem
        label={t('account')}
        value={payerDetails?.debitCardAccount?.name ?? '-'}
      />
      <DetailsListItem
        label={t('reference')}
        value={reference ?? '-'}
        isCapitalised={false}
      />
    </Box>
  )
}

TransferOutReversalDetails.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransferOutReversalDetailsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      reference
      payerDetails {
        debitCardAccount {
          name
        }
      }
    }
  `,
  DebitCardAccountTransaction: gql`
    fragment TransferOutReversalDetailsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      reference
      payerDetails {
        debitCardAccount {
          name
        }
      }
    }
  `,
}
