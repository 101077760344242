import { Field, useFormikContext } from 'formik'

import { SignupFields } from 'pages/SignUp/SignUp.types'
import { FormCheckbox } from 'components/Input/Checkbox'
import { TermsAndConditionsLabel } from 'components/TermsAndConditionsLabel'

interface TermsAndConditionsFieldProps {
  dataTestId?: string
}

export const TermsAndConditionsField = ({
  dataTestId = 'terms-and-conditions',
}: TermsAndConditionsFieldProps) => {
  const { values } = useFormikContext<SignupFields>()
  return (
    <Field
      type="checkbox"
      component={FormCheckbox}
      name="termsAndConditions"
      checked={values.termsAndConditions}
      label={<TermsAndConditionsLabel />}
      dataTestId={dataTestId}
    />
  )
}
