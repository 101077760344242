import * as styled from './TransferConfirmationHeader.styled'

type ShowDetailsButtonProps = {
  isExpanded: boolean
  paddingBottom: string
  toggleShowDetails: () => void
  isAnimationComplete: boolean
  buttonText: string
  isTransferRemittancesFlagOn: boolean
}

export const ShowDetailsButton = ({
  isExpanded,
  paddingBottom,
  toggleShowDetails,
  isAnimationComplete,
  buttonText,
  isTransferRemittancesFlagOn,
}: ShowDetailsButtonProps) => {
  return (
    <styled.ActionButton
      $isExpanded={isExpanded}
      $paddingBottom={paddingBottom}
      $isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
      onClick={toggleShowDetails}
    >
      {isAnimationComplete && buttonText}
    </styled.ActionButton>
  )
}
