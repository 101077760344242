import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { validateRequired } from 'utils/formValidation'
import { AddressRadioFieldsFragment } from 'types/gql-types/AddressRadioFieldsFragment'

import { AddressOption } from '../../../DeliveryAddress.types'
import { BusinessAddressOption } from './BusinessAddressOption'
import { RegisteredAddressOption } from './RegisteredAddressOption'

interface AddressRadioFieldsProps {
  addressOptions?: AddressRadioFieldsFragment
  isLoading?: boolean
}

export const AddressRadioFields = ({
  addressOptions,
  isLoading,
}: AddressRadioFieldsProps) => {
  const [field, , handlers] = useField<AddressOption>({
    name: 'address',
    validate: validateRequired,
  })

  const handleOnRegisteredAddressChange = useCallback(() => {
    handlers.setValue(AddressOption.RegisteredAddress)
  }, [handlers])

  const handleOnBusinessAddressChange = useCallback(() => {
    handlers.setValue(AddressOption.BusinessAddress)
  }, [handlers])

  return (
    <Flex flexDirection="column" mt="12px">
      {(addressOptions?.registeredAddress || isLoading) && (
        <RegisteredAddressOption
          onChange={handleOnRegisteredAddressChange}
          isChecked={field.value === AddressOption.RegisteredAddress}
          name="address"
          registeredAddress={addressOptions}
          isLoading={isLoading}
        />
      )}
      {(addressOptions?.businessAddress || isLoading) && (
        <BusinessAddressOption
          onChange={handleOnBusinessAddressChange}
          isChecked={field.value === AddressOption.BusinessAddress}
          name="address"
          businessAddress={addressOptions}
          isLoading={isLoading}
        />
      )}
    </Flex>
  )
}

AddressRadioFields.fragments = {
  Entity: gql`
    fragment AddressRadioFieldsFragment on Entity {
      ...RegisteredAddressOptionFragment
      ...BusinessAddressOptionFragment
    }
    ${RegisteredAddressOption.fragments.Entity}
    ${BusinessAddressOption.fragments.Entity}
  `,
}
