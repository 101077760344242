import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import { PageTemplate } from '@npco/zeller-design-system'

import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { AddSiteButton } from 'pages/Settings/Sites/AddSiteButton/AddSiteButton'
import { SiteListItem } from 'pages/Settings/Sites/SiteListItem/SiteListItem'
import { StyledCopy } from 'pages/Settings/Sites/Sites.styled'
import { EmptyStateMessageOnly } from 'components/EmptyState/EmptyStateMessageOnly'
import { EmptyStateMessageWithCard } from 'components/EmptyState/EmptyStateMessageWithCard'
import { EmptyStateCard } from 'components/EmptyStateCard/EmptyStateCard'
import { InfiniteList } from 'components/Lists/InfiniteList/InfiniteList'
import { SpinnerWrapped } from 'components/Spinner'
import { page } from 'translations'

import { useSitesContext } from '../SitesContext/SitesContext'
import { translations } from './SitesList.i18n'

export const SitesList = () => {
  const t = useTranslations(translations)
  const { sites, isLoading, isLoadingMore, onLoadMore, hasMore } =
    useSitesContext()

  const { userRole } = useLoggedInUser()

  if (!isLoadingMore && isLoading) {
    return <SpinnerWrapped variant="centre" />
  }

  if (!sites.length) {
    return userRole === CustomerRole.MANAGER ? (
      <EmptyStateMessageOnly
        title={t('managerTitle')}
        description={t('managerDescription')}
        illustrationName="SomethingWentWrong"
      />
    ) : (
      <EmptyStateMessageWithCard
        title={t('adminTitle')}
        description={t('adminDescription')}
        illustrationName="HelpBuildYourBusiness"
        card={
          <EmptyStateCard
            heading={t('cardHeading')}
            valuePoints={[t('point1'), t('point2'), t('point3')]}
          />
        }
      />
    )
  }

  return (
    <PageTemplate.Section>
      <InfiniteList<SiteData>
        data={sites}
        dataLength={sites.length}
        onScrollEnd={onLoadMore}
        hasMore={hasMore}
        renderItem={(site) => <SiteListItem key={site.id} site={site} />}
        header={
          <div>
            <StyledCopy>{page.settings.sites.copy}</StyledCopy>
            <AddSiteButton />
          </div>
        }
      />
    </PageTemplate.Section>
  )
}
