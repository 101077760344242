import { useTranslations } from '@npco/utils-translations'

import { setupFlowDownloadMessageTranslations } from './DownloadMessage.i18n'
import * as styled from './DownloadMessage.styled'

export const DownloadMessageMobile = () => {
  const t = useTranslations(setupFlowDownloadMessageTranslations)

  return <styled.Message>{t('mobileDownloadMessage')}</styled.Message>
}
