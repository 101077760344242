import { gql } from '@apollo/client'

import { PredefinedAddressPage } from '../PredefinedAddressPage/PredefinedAddressPage'

export const GetEntityAddress = gql`
  query GetEntityAddress($entityUuid: ID!) {
    getEntity(entityUuid: $entityUuid) {
      ...PredefinedAddressPageFragment
    }
  }
  ${PredefinedAddressPage.fragments.Entity}
`
