import { PdfTemplateType } from '@npco/mp-gql-types'

import {
  INVOICE_CUSTOMISATION_FORM_FIELDS,
  InvoiceSettingsCustomisationFormFields,
} from './SettingsCustomisation.types'

export const INVOICE_MESSAGING_MAX_LENGTH = 1000
export const INVOICE_DEFAULT_TEMPLATE = PdfTemplateType.MINIMAL
export const LOADING_MARKER = '**loading**'

export const DEFAULT_CUSTOMISATION_SETTINGS: InvoiceSettingsCustomisationFormFields =
  {
    [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedTemplateType]:
      INVOICE_DEFAULT_TEMPLATE,
    [INVOICE_CUSTOMISATION_FORM_FIELDS.logos]: [],
    [INVOICE_CUSTOMISATION_FORM_FIELDS.accentColours]: [],
    [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedColor]: '',
    [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedLogo]: '',
    [INVOICE_CUSTOMISATION_FORM_FIELDS.defaultMessage]: '',
    [INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions]: '',
  }
