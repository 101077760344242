import {
  ContactType,
  CreateInvoiceItemInput,
  CustomisationPreviewInvoiceInput,
  PreviewInvoiceInput,
} from '@npco/mp-gql-types'
import { FormikErrors, yupToFormErrors } from 'formik'
import { isNil, omit, omitBy } from 'lodash-es'

import {
  convertFormFieldsToInvoiceInput,
  getInputDate,
} from './components/InvoiceCreateForm/hooks/useInvoiceCreateFormHandlers.utils'
import {
  saveAndCloseInvoiceValidationSchema,
  sendInvoiceValidationSchema,
} from './Invoice.schemas'
import {
  InvoiceCustomisationFormFieldsAndPreviewDefaults,
  InvoiceFormFields,
} from './Invoice.types'

interface ValidateInvoiceFormProps {
  initialValues: InvoiceFormFields
  isFullValidation: boolean
  values: InvoiceFormFields
}

export const validateInvoiceForm = ({
  initialValues,
  isFullValidation,
  values,
}: ValidateInvoiceFormProps): Promise<FormikErrors<InvoiceFormFields>> => {
  const validationSchema = isFullValidation
    ? sendInvoiceValidationSchema
    : saveAndCloseInvoiceValidationSchema

  const attentionToContactType = values.customer.attentionToContact?.contactType
  const payerContactType = values.customer.payerContact?.contactType

  return validationSchema
    .validate(values, {
      abortEarly: false,
      context: {
        attentionToContactType,
        initialValues,
        payerContactType,
        values,
      },
    })
    .then(() => ({}))
    .catch(yupToFormErrors)
}

export const isInvoiceFormValid = (
  values: InvoiceFormFields
): Promise<boolean> => {
  const attentionToContactType = values.customer.attentionToContact?.contactType
  const payerContactType = values.customer.payerContact?.contactType

  return sendInvoiceValidationSchema
    .validate(values, {
      abortEarly: true,
      context: {
        attentionToContactType,
        payerContactType,
        values,
      },
    })
    .then(() => true)
    .catch(() => false)
}

export const isInvoiceScheduled = (values: InvoiceFormFields): boolean => {
  return values.schedule.sendEnabled
}

export const convertInvoiceFormFieldsToPreviewInput = (
  values: InvoiceFormFields
): PreviewInvoiceInput => {
  const {
    customer: { payerContact, attentionToContact },
  } = values
  const attentionTo = attentionToContact
    ? {
        contactType: attentionToContact.contactType,
        contactUuid: attentionToContact.contactUuid,
      }
    : undefined

  const payer = payerContact
    ? {
        contactType: payerContact.contactType,
        contactUuid: payerContact.contactUuid,
      }
    : undefined

  const invoiceInput = omitBy(
    omit(convertFormFieldsToInvoiceInput(values), [
      'customer',
      'itemsApplyTax',
      'sendSchedule',
    ]),
    isNil
  )

  return {
    ...invoiceInput,
    customer: payerContact
      ? {
          attentionContact: attentionTo,
          payerContact: payer,
          payerEmail: values.delivery.email.recipient,
        }
      : undefined,
    items: invoiceInput.items?.map((item: CreateInvoiceItemInput) =>
      omit(item, ['catalogItemUuid', 'id'])
    ),
    referenceNumber: values.referenceNumber,
  }
}

export const convertInvoiceCustomisationFormFieldsToPreviewInput = (
  values: InvoiceCustomisationFormFieldsAndPreviewDefaults
): CustomisationPreviewInvoiceInput => {
  return {
    customer: {
      attentionContact: {
        businessName: values.defaults?.customerBusinessName,
        contactType: ContactType.BUSINESS,
      },
      payerContact: {
        contactType: ContactType.PERSON,
        firstName: values.defaults?.attentionToContactFirstName,
        lastName: values.defaults?.attentionToContactLastName,
      },
    },
    message: values.customisation?.defaultMessage,
    siteSettings: {
      address: {
        postcode: values.defaults?.addressPostcode,
        state: values.defaults?.addressState,
        street: values.defaults?.addressStreet,
        suburb: values.defaults?.addressCity,
      },
      invoice: {
        customisation: values.customisation,
        pdfIncludesAddress: true,
      },
      receipt: {
        email: values.defaults?.businessEmail,
        name: values.defaults?.businessName,
        number: values.defaults?.businessNumber,
        phone: values.defaults?.businessPhone,
        website: values.defaults?.businessWebsite,
        logo: values.customisation?.selectedLogo || values.receiptLogo,
      },
    },
    title: values.defaults?.title,
    referenceNumber: values.defaults?.referenceNumber,
    dueDate: values.defaults
      ? getInputDate(values.defaults.dueDate, 'endOf')
      : undefined,
    items: values.defaults?.items,
    totalAmount: '11000000',
    subtotalAmount: '11000000',
    startDate: values.defaults
      ? getInputDate(values.defaults.dueDate, 'endOf')
      : undefined,
  }
}
