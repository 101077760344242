import { MutableRefObject, useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { useCustomScrollbarIsAllowed } from '../useCustomScrollbarIsAllowed/useCustomScrollbarIsAllowed'
import { getIsScrollbarVisible } from './useShowScrollbar.utils'

interface Arguments {
  scrollWrapperRef?: MutableRefObject<HTMLDivElement | null>
  childWrapperRef?: MutableRefObject<HTMLDivElement | null>
}

export const useShowScrollbar = ({
  scrollWrapperRef: outerScrollWrapperRef,
  childWrapperRef: outerChildWrapperRef,
}: Arguments) => {
  const isCustomScrollbarAllowed = useCustomScrollbarIsAllowed()

  const { height: scrollWrapperHeight, ref: innerScrollWrapperRef } =
    useResizeDetector<HTMLDivElement>({
      targetRef: outerScrollWrapperRef,
      refreshMode: 'debounce',
      refreshRate: 0,
    })

  const { height: childHeight, ref: innerChildRef } = useResizeDetector({
    targetRef: outerChildWrapperRef,
    refreshMode: 'debounce',
    refreshRate: 0,
  })

  const isScrollbarVisible = useMemo(
    () => getIsScrollbarVisible(scrollWrapperHeight, childHeight),
    [childHeight, scrollWrapperHeight]
  )

  return {
    isCustomScrollbarAllowed,
    isScrollbarVisible,
    scrollWrapperRef: innerScrollWrapperRef,
    childRef: innerChildRef,
  }
}
