import { type ReactNode } from 'react'
import { Flex, HeadingStyles } from '@npco/zeller-design-system'

import * as styled from '../../Sidebar.styled'

export interface ItemLabelProps {
  badge?: ReactNode
  children: ReactNode
  isFolded: boolean
  icon: ReactNode
}

export const ItemLabel = ({
  badge,
  children,
  icon,
  isFolded,
}: ItemLabelProps) => (
  <Flex justifyContent="flex-start">
    <Flex aria-hidden>{icon}</Flex>
    <Flex alignItems="center" gridGap="8px">
      <styled.Title withStyles={HeadingStyles.H5} $isFolded={isFolded}>
        {children}
      </styled.Title>
      {!isFolded && badge}
    </Flex>
  </Flex>
)
