import { convertToHTML as convertEditorToHTML } from 'draft-convert'
import { ContentState, EditorState, Modifier, SelectionState } from 'draft-js'

import { KEY_CODE } from 'const/keyboard'

import {
  availableEntityTagsMap,
  EntityTagItem,
} from '../InvoiceDeliveryAccordion.constants'
import { getEmailCompositeDecorator } from '../InvoiceDeliveryAccordion.utils'

const compositeDecorator = getEmailCompositeDecorator()

const getEntityData = (editorValue: EditorState, entityKey: string) => {
  return editorValue.getCurrentContent().getEntity(entityKey).getData()
}

export const getCurrentBlock = (editorValue: EditorState) => {
  const selectionState = editorValue.getSelection()
  const anchorKey = selectionState.getAnchorKey()
  const currentContent = editorValue.getCurrentContent()
  return currentContent.getBlockForKey(anchorKey)
}

export const getTextFromContentBlock = (editorValue: EditorState) => {
  return getCurrentBlock(editorValue).getText()
}

const updateEntityMergeTag = (
  editorState: EditorState,
  entityKey: string,
  entityValue: string,
  selectionState: SelectionState,
  action: 'replace' | 'insert'
) => {
  const modifierAction =
    action === 'insert' ? Modifier.insertText : Modifier.replaceText

  const newContentStateWithText = modifierAction(
    editorState.getCurrentContent(),
    selectionState,
    // cursor positioning when adding an entity is a bit tricky
    // found this https://github.com/facebook/draft-js/issues/627#issuecomment-576649059
    // which makes the cursor to move in front of the added merge tag.
    '\u200A'.concat(entityValue).concat('\u200A'),
    undefined,
    entityKey
  )

  return EditorState.push(
    editorState,
    newContentStateWithText,
    'insert-fragment'
  )
}

export const updateEditorWithNewMergeTagEntity = (
  editorState: EditorState,
  selectionState: SelectionState,
  entityMap: EntityTagItem,
  hasError: boolean,
  action: 'replace' | 'insert'
) => {
  const contentState = editorState.getCurrentContent()

  const contentStateWithNewEntity = contentState.createEntity(
    entityMap.key,
    'IMMUTABLE',
    {
      isFocused: false,
      hasError,
      value: entityMap.text,
    }
  )
  const entityKey = contentStateWithNewEntity.getLastCreatedEntityKey()

  const newEditorStateWithAddedFragment = updateEntityMergeTag(
    editorState,
    entityKey,
    entityMap.text,
    selectionState,
    action
  )

  return newEditorStateWithAddedFragment
}

export const cloneEditorState = (editorValue: EditorState) => {
  return EditorState.acceptSelection(
    EditorState.createWithContent(
      editorValue.getCurrentContent(),
      compositeDecorator
    ),
    editorValue.getSelection()
  )
}

export const removeFocusedDataOnEntity = (
  editorState: EditorState,
  entityKey: string,
  forceSelection = true
) => {
  const entityData = getEntityData(editorState, entityKey)

  const newContent = editorState
    .getCurrentContent()
    .replaceEntityData(entityKey, {
      ...entityData,
      isFocused: false,
    })

  const newEditorState = EditorState.createWithContent(
    newContent,
    compositeDecorator
  )

  if (!forceSelection) {
    return newEditorState
  }

  return EditorState.forceSelection(newEditorState, editorState.getSelection())
}
type EntityRange = {
  start: number
  end: number
  entityKey: string
  blockKey: string
}

export const updateCursorPosition = (
  selection: SelectionState,
  position: number
) =>
  selection.merge({
    anchorOffset: position,
    focusOffset: position,
  })

export const updateErrorEntity = (
  editorValue: EditorState,
  entityKey: string,
  hasError: boolean
) => {
  const entityData = getEntityData(editorValue, entityKey)
  const newContent = editorValue
    .getCurrentContent()
    .replaceEntityData(entityKey, {
      ...entityData,
      hasError,
    })

  return EditorState.createWithContent(newContent, compositeDecorator)
}

export const updateFocusedEntity = (
  editorValue: EditorState,
  { end, entityKey, start }: EntityRange,
  keyCodePressed: string
) => {
  const entityData = getEntityData(editorValue, entityKey)
  const currentContent = editorValue.getCurrentContent()
  const newContent = currentContent.replaceEntityData(entityKey, {
    ...entityData,
    isFocused: true,
  })

  const middleCursorPosition = Math.round((start + end) / 2)
  let position = middleCursorPosition

  if (keyCodePressed === KEY_CODE.ARROW_RIGHT) {
    position = end
  }
  if (keyCodePressed === KEY_CODE.ARROW_LEFT) {
    position = start
  }

  return EditorState.forceSelection(
    EditorState.createWithContent(newContent, compositeDecorator),
    updateCursorPosition(editorValue.getSelection(), position)
  )
}

export const insertEntityMergeTag = (
  entityTagItem: EntityTagItem,
  editorValue: EditorState
) => {
  const selectionState = editorValue.getSelection()

  const newEditorStateWithEntity = updateEditorWithNewMergeTagEntity(
    editorValue,
    selectionState,
    entityTagItem,
    false,
    'insert'
  )

  const newEditorState = EditorState.forceSelection(
    newEditorStateWithEntity,
    newEditorStateWithEntity.getCurrentContent().getSelectionAfter()
  )

  return newEditorState
}

export const geEntityRangesInContentBlock = (
  editorValue: EditorState
): Promise<EntityRange[]> => {
  return new Promise((resolve) => {
    const entityRanges: EntityRange[] = []
    let entityKey = ''

    editorValue
      .getCurrentContent()
      .getBlocksAsArray()
      .forEach((currentBlock) => {
        currentBlock.findEntityRanges(
          (character) => {
            entityKey = character.getEntity()
            return Boolean(entityKey)
          },
          (start, end) => {
            entityRanges.push({
              start,
              end,
              entityKey,
              blockKey: currentBlock.getKey(),
            })
          }
        )
      })

    resolve(entityRanges)
  })
}

const entityTagsApiMap = Object.values(availableEntityTagsMap).reduce(
  (acc: Record<string, string>, curr) => {
    acc[curr.key] = curr.apiString
    return acc
  },
  {}
)

export const convertToHtml = (
  contentState: ContentState,
  removeHtmlTags: boolean
) =>
  convertEditorToHTML({
    ...(removeHtmlTags && {
      blockToHTML: () => {
        return {
          start: '',
          end: '',
        }
      },
    }),
    entityToHTML: (entity) => {
      return entityTagsApiMap[entity.type]
    },
  })(contentState)

export const setMergeTagsValues = (
  editorValue: EditorState,
  mergeTagsMap: typeof availableEntityTagsMap,
  hasFieldError: boolean
) => {
  let editorStateToUpdate = cloneEditorState(editorValue)
  let isUpdated = false

  Object.values(mergeTagsMap).forEach((entityMap) => {
    editorStateToUpdate
      .getCurrentContent()
      .getBlockMap()
      .forEach((contentBlock) => {
        if (!contentBlock) {
          return
        }

        const blockKey = contentBlock.getKey()
        const blockText = contentBlock.getText()

        const selection = SelectionState.createEmpty(blockKey)

        let result = new RegExp(entityMap.regex).exec(blockText)

        if (!result) {
          return
        }

        isUpdated = true

        while (result) {
          const updatedSelection = selection.merge({
            // anchorOffset is the start of the block
            anchorOffset: result.index,
            // focusOffset is the end
            focusOffset: result.index + result[0].length,
          })

          const isMergeTagError =
            entityMap.key !== 'INVOICE_NO' &&
            hasFieldError &&
            entityMap.hasValue === false

          editorStateToUpdate = updateEditorWithNewMergeTagEntity(
            editorStateToUpdate,
            updatedSelection,
            entityMap,
            isMergeTagError,
            'replace'
          )

          const newTextFromContentBlock =
            getTextFromContentBlock(editorStateToUpdate)

          result = new RegExp(entityMap.regex).exec(newTextFromContentBlock)
        }
      })
  })

  return isUpdated ? editorStateToUpdate : null
}

export const updateMergeTags = async (
  editorValue: EditorState,
  mergeTagsMap: typeof availableEntityTagsMap,
  hasError: boolean
): Promise<EditorState | null> => {
  const entityRangesInBlock = await geEntityRangesInContentBlock(editorValue)

  let isUpdated = false
  let editorStateToUpdate = cloneEditorState(editorValue)

  entityRangesInBlock.forEach((entityRange) => {
    const { blockKey, entityKey, end, start } = entityRange
    const entity = editorStateToUpdate.getCurrentContent().getEntity(entityKey)

    const entityMap = Object.values(mergeTagsMap).find(
      (mergeTag) => mergeTag.key === entity.getType()
    )

    const isMergeTagError =
      entityMap?.key !== 'INVOICE_NO' &&
      hasError &&
      entityMap?.hasValue === false

    const entityData = entity.getData()
    const shouldUpdateMergeTagError =
      (!entityData.hasError && isMergeTagError) ||
      (entityData.hasError && !isMergeTagError)

    if (shouldUpdateMergeTagError) {
      editorStateToUpdate = updateErrorEntity(
        editorStateToUpdate,
        entityKey,
        isMergeTagError
      )
      isUpdated = true
    }
    if (!!entityMap?.text && entityData.value !== entityMap.text) {
      const selection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: end,
      })

      editorStateToUpdate = updateEntityMergeTag(
        editorStateToUpdate,
        entityKey,
        entityMap.text,
        selection,
        'replace'
      )
      isUpdated = true
    }
  })

  return isUpdated ? editorStateToUpdate : null
}
