import { useCallback, useRef } from 'react'
import { Popper } from '@npco/zeller-design-system'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

import { AccountFilterDateContent } from './AccountFilterDateContent/AccountFilterDateContent'
import { AccountFilterDateTrigger } from './AccountFilterDateTrigger/AccountFilterDateTrigger'

export const AccountFilterDate = () => {
  const { date, resetDate } = useAccountFilters()
  const triggerRef = useRef<HTMLButtonElement>(null)

  const focusTrigger = useCallback(() => {
    triggerRef.current?.focus()
  }, [])

  return (
    <Popper
      popperWidth="auto"
      adjustHeight
      renderTrigger={({ isOpen, setIsOpen }) => {
        const onToggleOpen = () => setIsOpen((wasOpen) => !wasOpen)

        const onClear = () => {
          setIsOpen(false)
          resetDate()
        }

        return (
          <AccountFilterDateTrigger
            isOpen={isOpen}
            onToggleOpen={onToggleOpen}
            onClear={onClear}
            date={date}
          />
        )
      }}
    >
      {({ isOpen }) => (
        <AccountFilterDateContent isOpen={isOpen} focusTrigger={focusTrigger} />
      )}
    </Popper>
  )
}
