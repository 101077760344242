import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { CardTypeOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { cardDetailsReviewTranslations } from '../CardDetailsReview.i18n'

export const useGetCardNickname = () => {
  const t = useTranslations(cardDetailsReviewTranslations)

  const getCardNickname = useCallback(
    ({
      nickname,
      firstName,
      cardType,
    }: {
      nickname: string
      firstName: string
      cardType: CardTypeOption
    }) => {
      return (
        nickname.trim() ||
        t('nicknameDefault', {
          firstName,
          cardTypeDisplay:
            cardType === CardTypeOption.DebitCard
              ? t('debitCard')
              : t('corporateCard'),
        })
      )
    },
    [t]
  )

  return {
    getCardNickname,
  }
}
