import { useCallback, useEffect, useMemo, useState } from 'react'
import { UseTranslationsFn } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { isEqual } from 'lodash-es'

import { useFileDrop } from 'components/ModalUploadImage/hooks/useFileDrop'

import { useGetInvoiceSettingsLogoUploadUrl } from '../../hooks/useGetInvoiceSettingsLogoUploadUrl'
import { useSettingsCustomisationLogoSubscription } from '../../hooks/useSettingsCustomisationLogoSubscription'
import { translations } from '../../SettingsCustomisation.i18n'
import {
  getDefaultInvoiceSettingsCustomisationFormValues,
  isValidLogo,
} from '../../SettingsCustomisation.utils'

type UseLogoUploadModalProps = {
  isOpen: boolean
  logos: string[] | undefined
  onCancelUpload: (logo: string) => void
  onClose: () => void
  onNewUpload: (logo: string) => void
  onSaveLogo: (logos: string[]) => void
  t: UseTranslationsFn<typeof translations>
}

export const useLogoUploadModal = ({
  isOpen,
  logos,
  onCancelUpload,
  onClose,
  onNewUpload,
  onSaveLogo,
  t,
}: UseLogoUploadModalProps) => {
  const {
    previewUrl: imageUrl,
    isPreviewLoading,
    isPreviewError,
    onDrop,
    reset,
    file,
  } = useFileDrop()
  const [croppedImage, setCroppedImage] = useState<string>()
  const [croppedFile, setCroppedFile] = useState<File>()
  const definedFile = croppedFile ?? file
  const definedImage = croppedImage ?? imageUrl
  const [isUpdatingLogos, setIsUpdatingLogos] = useState(false)

  const handleOnLogosUpdated = useCallback(
    ({
      logos: updatedLogos,
      disableToast,
    }: {
      logos: Array<string | null>
      disableToast?: boolean
    }) => {
      if (!isEqual(updatedLogos, logos)) {
        if (!disableToast) {
          showSuccessToast(t('successNotification'))
        }
        // https://github.com/total-typescript/ts-reset
        const filteredLogos = updatedLogos.filter((item): item is string =>
          Boolean(item)
        )
        onSaveLogo(filteredLogos)
      }
    },
    [logos, onSaveLogo, t]
  )

  const handleClose = useCallback(() => {
    onClose()
    reset()
  }, [onClose, reset])

  const handleOnError = useCallback(() => {
    showErrorToast(t('errorNotification'))
    onCancelUpload(definedImage)
    handleClose()
  }, [definedImage, handleClose, onCancelUpload, t])

  const {
    isLoadingSubscription,
    startSubscription,
    invoiceSettings,
    logosAreSynced,
    updateSettingsCustomisation,
    isUpdatingZellerInvoiceSettings,
  } = useSettingsCustomisationLogoSubscription({
    logos,
    onError: handleOnError,
    onLogosUpdated: handleOnLogosUpdated,
  })

  const { uploadInvoiceSettingsLogo } = useGetInvoiceSettingsLogoUploadUrl({
    onError: handleOnError,
    onSuccess: startSubscription,
  })

  const logosAreValid = useMemo(
    () =>
      Boolean(logos?.length ? logos.find((logo) => isValidLogo(logo)) : true),
    [logos]
  )

  useEffect(() => {
    if (logosAreSynced || !isOpen) {
      // NOTE: ensure updateZellerInvoiceSettings is called only once
      setIsUpdatingLogos(false)
    }
  }, [isOpen, logosAreSynced])

  useEffect(() => {
    if (
      isOpen &&
      !logosAreSynced &&
      !isLoadingSubscription &&
      !isUpdatingLogos &&
      !isUpdatingZellerInvoiceSettings &&
      imageUrl &&
      logosAreValid
    ) {
      const payload = getDefaultInvoiceSettingsCustomisationFormValues({
        ...invoiceSettings?.invoice.customisation,
        logos,
      })

      const callAsyncUpdate = async () => {
        setIsUpdatingLogos(true)
        startSubscription()
        await updateSettingsCustomisation(payload)
      }

      callAsyncUpdate()
    }
  }, [
    invoiceSettings?.invoice.customisation,
    isLoadingSubscription,
    isOpen,
    isUpdatingZellerInvoiceSettings,
    logos,
    logosAreSynced,
    logosAreValid,
    startSubscription,
    updateSettingsCustomisation,
    isUpdatingLogos,
    imageUrl,
  ])

  const handleSaveLogo = () => {
    // NOTE: add the image dataURL to show in the TileSelector while loading
    onNewUpload(definedImage)
    if (definedFile) {
      // NOTE: Upload the file to S3 which will save the URL in the invoice settings,
      // overwriting the dataURL
      uploadInvoiceSettingsLogo(definedFile)
      handleClose()
    }
  }

  return {
    handleClose,
    imageUrl,
    isPreviewLoading,
    isPreviewError,
    updatingLogos: isUpdatingLogos,
    isLoadingSubscription,
    onDrop,
    handleSaveLogo,
    setCroppedImage,
    setCroppedFile,
    handleOnLogosUpdated,
  }
}
