import { SyntheticEvent } from 'react'

import { currencyFormatter } from 'utils/common'
import {
  RangePickerValue,
  RangeType,
  RangeValue,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import { component } from 'translations'

const inputLengthAfterOnChangeEvent = 2

export const printRangeSummary = (minRange: number, maxRange: number) =>
  `${component.rangePicker.range} ${currencyFormatter(minRange, true).slice(
    0,
    -3
  )} – ${currencyFormatter(maxRange, true).slice(0, -3)}`

export const getInputNumericValueFromEvent = (
  e: SyntheticEvent
): RangeValue => {
  const target = e.target as HTMLInputElement
  const nativeEvent = e.nativeEvent as InputEvent
  if (
    nativeEvent.data === '-' &&
    target.value.length <= inputLengthAfterOnChangeEvent
  ) {
    return '-'
  }

  if (target.value === '-0') {
    return 0
  }

  if (target.value === '' || target.value === '-') {
    return target.value
  }

  const inputValue = parseInt(target.value, 10)
  return Number.isNaN(inputValue) ? 0 : inputValue
}

export const getNewRangeFromEvent = (
  initialRange: [RangeValue, RangeValue],
  e: SyntheticEvent,
  type: 'min' | 'max'
): [RangeValue, RangeValue] => {
  const inputValue = getInputNumericValueFromEvent(e)
  const selectedIndex = type === 'min' ? 0 : 1

  const newRange: [RangeValue, RangeValue] = [...initialRange]
  newRange[selectedIndex] = inputValue
  return newRange
}

export const adjustRangeValuesForEdgeConditions = (
  range: [RangeValue, RangeValue],
  type: RangeType,
  edgeRange: RangePickerValue
): RangePickerValue => {
  const updatedRange = range.map((item) =>
    item === '' ? 0 : item
  ) as RangePickerValue
  const [inputMin, inputMax] = updatedRange
  const [defaultMin, defaultMax] = edgeRange

  const isMinAboveMax = type === 'min' && inputMin > inputMax
  const isMinBelowRange = type === 'min' && inputMin < defaultMin
  const isMaxAboveRange = type === 'max' && inputMax > defaultMax
  const isMaxBelowMin = type === 'max' && inputMax < inputMin

  if (isMinAboveMax) {
    return [inputMax, inputMax]
  }

  if (isMinBelowRange) {
    return [defaultMin, inputMax]
  }

  if (isMaxAboveRange) {
    return [inputMin, defaultMax]
  }

  if (isMaxBelowMin) {
    return [inputMin, inputMin]
  }

  return [inputMin, inputMax]
}
