import { ReactNode } from 'react'

import { SelectSize } from '../Select.types'
import {
  StyledContent,
  StyledDividerSubLabel,
  StyledSelectLabel,
  StyledSelectSubLabel,
  StyledSelectSubLabelsWrapper,
} from './SelectTriggerAdaptive.styled'

interface SelectTriggerContentProps {
  size?: SelectSize
  label?: ReactNode
  subLabel1?: ReactNode
  subLabel2?: ReactNode
}

export const SelectTriggerContent = ({
  size,
  label,
  subLabel1,
  subLabel2,
}: SelectTriggerContentProps) => {
  if (!label) {
    return null
  }

  const areTwoLines = Boolean(subLabel1 || subLabel2)

  return (
    <StyledContent>
      <StyledSelectLabel $size={size} $areTwoLines={areTwoLines}>
        {label}
      </StyledSelectLabel>
      {areTwoLines && (
        <StyledSelectSubLabelsWrapper data-testid="labels-wrapper">
          <StyledSelectSubLabel>{subLabel1}</StyledSelectSubLabel>
          {subLabel1 && subLabel2 && (
            <StyledDividerSubLabel data-testid="labels-divider" />
          )}
          <StyledSelectSubLabel>{subLabel2}</StyledSelectSubLabel>
        </StyledSelectSubLabelsWrapper>
      )}
    </StyledContent>
  )
}
