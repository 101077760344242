import { useEffect } from 'react'
import { gql } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'
import { useGetInvoicePreview } from 'features/Invoicing/components/Invoices/Invoice/hooks/useGetInvoicePreview'

import { InvoiceDetailsFragment } from 'types/gql-types/InvoiceDetailsFragment'

import { InvoiceActionPrompt } from './InvoiceActionPrompt'
import { InvoiceDecisionModalProvider } from './InvoiceDecisionModalProvider'
import { InvoiceDrawerDetailsPdf } from './InvoiceDrawerDetailsPdf/InvoiceDrawerDetailsPdf'
import { InvoiceDrawerNotes } from './InvoiceDrawerNotes/InvoiceDrawerNotes'

interface InvoiceDrawerDetailsProps {
  details: InvoiceDetailsFragment
}
export const InvoiceDrawerDetails = ({
  details,
}: InvoiceDrawerDetailsProps) => {
  const { invoicePdf, invoicePdfError, isLoadingPreview, getPreview } =
    useGetInvoicePreview()

  useEffect(() => {
    getPreview(details.referenceNumber)
  }, [getPreview, details.referenceNumber])

  return (
    <Flex gridGap="40px" flexDirection="column">
      <InvoiceDecisionModalProvider details={details}>
        <InvoiceActionPrompt invoicePdf={invoicePdf} />
      </InvoiceDecisionModalProvider>
      <InvoiceDrawerDetailsPdf
        invoiceId={details.id}
        invoicePdf={invoicePdf}
        invoicePdfError={invoicePdfError}
        isLoadingPreview={isLoadingPreview}
        referenceNumber={details.referenceNumber}
      />
      <InvoiceDrawerNotes
        notes={details.notes}
        referenceNumber={details.referenceNumber}
      />
    </Flex>
  )
}

InvoiceDrawerDetails.fragments = {
  InvoiceDetails: gql`
    ${InvoiceDrawerNotes.fragments.InvoiceDrawerNotes}
    fragment InvoiceDetailsFragment on Invoice {
      customer {
        attentionContact {
          businessName
          contactType
          contactUuid
          firstName
          lastName
          status
        }
        payerContact {
          businessName
          contactType
          contactUuid
          firstName
          lastName
          status
        }
        payerEmail
      }
      email {
        recipients {
          bcc
          cc
          sendMeCopy
        }
        subject
        body
        enabled
      }
      sms {
        enabled
        payerContactPhoneNumber
      }
      id
      paymentLink
      dueAmount
      referenceNumber
      status
      requiredEmailUpdateBeforeSend
      requiredPhoneUpdateBeforeSend
      activities {
        __typename
        id
        completedTime
        dueDate
        status
        type
        invoiceEmailDeliveryResult {
          failed
        }
        invoiceSmsDeliveryResult {
          failed
        }
      }
      payments {
        __typename
        id
        paymentTimeISO
      }
      ...InvoiceDrawerNotesFragment
    }
  `,
}
