import { useCallback, useEffect } from 'react'

import { APP_VERSION } from 'const/envs'

export const APP_VERSION_KEY = 'App Version'

export const useConfigData = () => {
  const printConfig = (key: string, value?: string) => {
    // eslint-disable-next-line no-console
    console.log(`${key} : ${value}`)
  }

  const processAppVersion = useCallback(() => {
    printConfig(APP_VERSION_KEY, APP_VERSION)
  }, [])

  useEffect(() => {
    processAppVersion()
  }, [processAppVersion])
}
