import {
  BodySmallTypography,
  Flex,
  InputSelectComboboxStandardItemWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledItem = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`

export const StyledLabel = styled.div`
  color: ${({ theme }) => theme.colors.BLACK_900};
  flex-grow: 1;
  word-break: break-word;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledSubLabel = styled.div`
  display: flex;
  justify-content: end;
  flex-grow: 1;
  text-align: right;
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40%;
  padding-left: 1rem;
  padding-top: 0.1rem;
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodySmallTypography};
`

export const StyledPersonComboboxWrapper = styled(
  InputSelectComboboxStandardItemWrapper
)`
  display: flex;
  flex-direction: row;
  align-items: base-line;
`
