import { useCallback, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Flex, ModalForm } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { useStateIfMounted } from 'hooks/useStateIfMounted'
import { translationsShared } from 'translations'

import { Pairing, Venue } from '../HlPosManage.types'
import { useGetInitialStates } from '../hooks/useGetInitialStates'
import { translations } from './EditVenue.i18n'
import * as styled from './EditVenue.styled'
import { Field } from './Field'
import { getPairingsAssignments } from './getPairingsAssignments'
import { getSiteAssignmentLabel, VenuesById } from './getSiteAssignmentLabel'
import { SitesSelect } from './SitesSelect'
import { StationIdToggle } from './StationIdToggle'

export type EditVenueProps = {
  options: EditVenueOption[]
  venue: Venue
  venueMap?: VenuesById
  initialPairings: Pairing[]
  onSave: (newPairings: Pairing[]) => Promise<void>
  onCancel: () => void
}

export type EditVenueOption = {
  value: string
  label: string
  sublabel?: string
  isDisabled?: boolean
}

export const EditVenue = ({
  options,
  venue,
  venueMap: venueMapProp,
  initialPairings,
  onSave: onSaveProp,
  onCancel,
}: EditVenueProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { initialValues, initialSiteIdMap } = useGetInitialStates({
    venue,
    initialPairings,
  })
  const [siteIdMap, setSiteIdMap] =
    useState<Record<string, string>>(initialSiteIdMap)
  const pairingsAssignments = useMemo(
    () => getPairingsAssignments(initialPairings),
    [initialPairings]
  )
  const venueId = venue.id
  const venuesById = useMemo(
    () => venueMapProp ?? { [venue.id]: venue },
    [venue, venueMapProp]
  )

  const getSiteAssignmentLabelBySiteId = useCallback(
    (siteId: string): string | undefined =>
      getSiteAssignmentLabel({
        siteId,
        venueId,
        pairingsAssignments,
        venuesById,
        i18n: {
          venueAssignment: ({ venueName }) =>
            t('assignedToVenue', { venue: venueName }),
          locationAssignment: ({ venueName, locationName }) =>
            t('assignedToVenueLocation', {
              venue: venueName,
              location: locationName,
            }),
        },
      }),
    [pairingsAssignments, t, venueId, venuesById]
  )

  const getAssignedSites = useCallback(
    (venueOrLocationId: string) => {
      return Object.entries(siteIdMap).reduce((acc, [key, value]) => {
        if (value === venueOrLocationId) {
          return [...acc, key]
        }
        return acc
      }, [] as string[])
    },
    [siteIdMap]
  )

  const onSiteSelect = useCallback(
    (nextSiteIds: string[], venueOrLocationId: string) => {
      setSiteIdMap((prevSiteIdMap: Record<string, string>) => {
        const previouslyAssignedSites = Object.keys(prevSiteIdMap).filter(
          (key) => prevSiteIdMap[key] === venueOrLocationId
        )

        const newSiteIdMapWithoutPrevSites = { ...prevSiteIdMap }
        previouslyAssignedSites.forEach(
          (siteUuid) => delete newSiteIdMapWithoutPrevSites[siteUuid]
        )

        return nextSiteIds.reduce((acc, siteId) => {
          return { ...acc, [siteId]: venueOrLocationId }
        }, newSiteIdMapWithoutPrevSites)
      })
    },
    []
  )

  const [isSaving, setIsSaving] = useStateIfMounted(false)

  const onSave = useCallback(
    (values) => {
      async function sendRequest() {
        setIsSaving(true)
        const newPairings = Object.entries(siteIdMap).reduce(
          (acc, [siteUuid, venueOrLocationId]) => {
            if (venueOrLocationId === venueId) {
              return [
                ...acc,
                {
                  venueId,
                  siteUuid,
                  stationId: values.venueStationIdToggle
                    ? values.venueStationId.toString()
                    : null,
                  locationId: null,
                } as Pairing,
              ]
            }
            return [
              ...acc,
              {
                venueId,
                siteUuid,
                stationId: values.locationStationIdToggle
                  ? values.locations[venueOrLocationId].stationId.toString()
                  : null,
                locationId: venueOrLocationId,
              } as Pairing,
            ]
          },
          [] as Pairing[]
        )

        await onSaveProp(
          newPairings.concat(
            initialPairings.filter((pairing) => pairing.venueId !== venueId)
          )
        )
        setIsSaving(false)
      }

      sendRequest().catch(() => setIsSaving(false))
    },
    [setIsSaving, onSaveProp, initialPairings, siteIdMap, venueId]
  )

  const isLoading = isSaving

  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ submitForm }) => (
        <ModalForm
          isOpen
          isLoading={isLoading}
          onCancel={onCancel}
          onClickPrimary={submitForm}
          onClickSecondary={onCancel}
          primaryButtonLabel={tShared('save')}
          secondaryButtonLabel={tShared('cancel')}
          title={t('title')}
        >
          <Flex flexDirection="column" gap="24px">
            <styled.Description>{t('venueDescription')}</styled.Description>
            <StationIdToggle category="venue" />
            <Field
              name={venue.name}
              category="venue"
              categoryId={venueId}
              siteIdMap={siteIdMap}
            >
              <SitesSelect
                value={getAssignedSites(venueId)}
                onValue={(siteIds) => onSiteSelect(siteIds, venueId)}
                options={options}
                getOptionAssignmentLabel={getSiteAssignmentLabelBySiteId}
              />
            </Field>
          </Flex>
          {!!venue.locations?.length && (
            <>
              <styled.HR />
              <Flex flexDirection="column" gap="24px">
                <styled.Description>
                  {t('locationDescription')}
                </styled.Description>
                <StationIdToggle category="location" />
                {venue.locations.map((location) => (
                  <Field
                    key={location.id}
                    name={location.name}
                    category="location"
                    categoryId={location.id}
                    siteIdMap={siteIdMap}
                  >
                    <SitesSelect
                      value={getAssignedSites(location.id)}
                      onValue={(siteIds) => onSiteSelect(siteIds, location.id)}
                      options={options}
                      getOptionAssignmentLabel={getSiteAssignmentLabelBySiteId}
                    />
                  </Field>
                ))}
              </Flex>
            </>
          )}
        </ModalForm>
      )}
    </Formik>
  )
}
