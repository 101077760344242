import { CompleteCardDeliveryModal } from './CompleteCardDeliveryModal/CompleteCardDeliveryModal'
import { CompleteCardInviteModal } from './CompleteCardInviteModal/CompleteCardInviteModal'
import { CompleteCardSetupModal } from './CompleteCardSetupModal/CompleteCardSetupModal'
import { useCompleteCardState } from './hooks/useCompleteCardState'

export const CompleteCardModal = () => {
  const { state } = useCompleteCardState()

  if (state === null) {
    return null
  }

  return (
    <>
      <CompleteCardSetupModal />
      <CompleteCardInviteModal />
      <CompleteCardDeliveryModal />
    </>
  )
}
