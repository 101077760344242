import { LinkCardIdStage } from './LinkCardIdStage/LinkCardIdStage'
import { LinkEMLCardInfoStage } from './LinkEMLCardInfoStage/LinkEMLCardInfoStage'
import { LinkSelectAccountStage } from './LinkSelectAccountStage/LinkSelectAccountStage'

export const LinkCardStages = () => {
  return (
    <>
      <LinkCardIdStage />
      <LinkSelectAccountStage />
      <LinkEMLCardInfoStage />
    </>
  )
}
