import { BodyDefaultTypography, H4 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  button {
    &:first-of-type {
      margin-right: 8px;
    }
  }
`
export const Title = styled(H4)`
  margin: 0;
`

export const Description = styled.span`
  ${BodyDefaultTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
  margin-bottom: 12px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`
