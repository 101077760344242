import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseStartupModalsConnectionFragmentDoc } from '../useStartupModals/useStartupModals.generated'

export type UseStartupModalsSessionConnectionFragment = {
  __typename?: 'Connection'
  status: Types.ConnectionStatus | null
}

export const UseStartupModalsSessionConnectionFragmentDoc = gql`
  fragment UseStartupModalsSessionConnectionFragment on Connection {
    ...UseStartupModalsConnectionFragment
  }
  ${UseStartupModalsConnectionFragmentDoc}
` as unknown as TypedDocumentNode<
  UseStartupModalsSessionConnectionFragment,
  undefined
>
