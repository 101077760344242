import { useMemo } from 'react'
import {
  SelectItemBasic,
  SelectSelectedItem,
} from 'design-system/Components/Select'
import { uniq } from 'ramda'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { FilterType } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList.constants'

interface Arguments<FilterType> {
  filters: FilterType[]
  alwaysVisibleFilters: FilterType[]
}

export const useFiltersList = ({
  filters,
  alwaysVisibleFilters,
}: Arguments<FilterType>) => {
  const { visibleFilterCategories, setVisibleFilterCategories } =
    useAccountFilters()

  const visibleFilters = useMemo(
    () => uniq([...alwaysVisibleFilters, ...visibleFilterCategories]),
    [alwaysVisibleFilters, visibleFilterCategories]
  )

  const hiddenFilters = useMemo(
    () => filters.filter((filter) => !visibleFilters.includes(filter)),
    [filters, visibleFilters]
  )

  const onSelect = (item: SelectSelectedItem<SelectItemBasic>) => {
    if (!item) {
      return
    }

    setVisibleFilterCategories([
      ...visibleFilterCategories,
      item.value as FilterType,
    ])
  }

  return {
    visibleFilters,
    hiddenFilters,
    onSelect,
  }
}
