import { useMemo, useState } from 'react'
import { SendReceiptMode } from '@npco/mp-gql-types'
import {
  Box,
  ButtonClear,
  Flex,
  TEXT_INPUT_SIZE,
} from '@npco/zeller-design-system'
import { SelectCompactField } from 'design-system/Components/Select'
import { Formik, FormikValues } from 'formik'

import { validateEmail, validatePhoneNumber } from 'utils/formValidation'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import {
  CancelButton,
  ConfirmButton,
  ModalHeader,
} from 'components/Modal/ModalElements/ModalElements'
import { useSendReceipt } from 'components/SendReceiptModal/SendReceiptModal.hooks'
import { SendReceiptModalSuccess } from 'components/SendReceiptModal/SendReceiptModalSuccess/SendReceiptModalSuccess'
import { component } from 'translations'

import { StyledContainer, StyledReceiptForm } from './SendReceiptModal.styled'

type ReceiptTarget = 'email' | 'sms'

interface FormValues {
  sendTarget?: ReceiptTarget
  emailAddress?: string | null
  phoneNumber?: string | null
}

interface SendReceiptModalProps {
  closeModal: () => void
  transactionId: string
  cardHolderEmail?: string | null
  cardHolderPhone?: string | null
}

export const shouldDisabledSending = (
  values: FormikValues,
  isSending: boolean,
  isValid: boolean
) => {
  return (
    !isValid ||
    isSending ||
    (values.sendTarget === 'email' && !values.emailAddress) ||
    (values.sendTarget === 'sms' && !values.phoneNumber) ||
    !values.sendTarget
  )
}

export const SendReceiptModal = ({
  closeModal,
  transactionId,
  cardHolderEmail,
  cardHolderPhone,
}: SendReceiptModalProps) => {
  const [receiptSent, setReceiptSent] = useState('')
  const [isSent, setIsSent] = useState(false)
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  // Temporary check for string "undefined" until BE fix data
  const cardholderEmailAddress =
    cardHolderEmail && cardHolderEmail !== 'undefined' ? cardHolderEmail : null
  const cardholderPhoneNumber =
    cardHolderPhone && cardHolderPhone !== 'undefined' ? cardHolderPhone : null

  const isDefaultModeAvailable = cardholderEmailAddress || cardholderPhoneNumber
  const defaultMode = cardholderEmailAddress ? 'email' : 'sms'

  const sendReceiptOptions = useMemo(
    () => [
      {
        label: component.modal.sendReceipt.emailOption,
        value: 'email',
      },
      {
        label: component.modal.sendReceipt.SMSOption,
        value: 'sms',
      },
    ],
    []
  )

  const initialValues = useMemo<FormValues>(
    () => ({
      sendTarget: isDefaultModeAvailable ? defaultMode : undefined,
      emailAddress: cardholderEmailAddress,
      phoneNumber: cardholderPhoneNumber,
    }),
    [
      cardholderEmailAddress,
      cardholderPhoneNumber,
      defaultMode,
      isDefaultModeAvailable,
    ]
  )

  const { sendReceipt, isSending } = useSendReceipt(() => setIsSent(true))
  const handleOnSubmit = (values: FormikValues) => {
    const shouldSendEmail = values.sendTarget === 'email'
    const shouldSendSms = values.sendTarget === 'sms'

    setReceiptSent(shouldSendEmail ? values.emailAddress : values.phoneNumber)
    sendReceipt({
      variables: {
        input: {
          transactionUuid: transactionId,
          mode: values.sendTarget.toUpperCase(),
          newEmail:
            shouldSendEmail && values.emailAddress !== cardholderEmailAddress
              ? values.emailAddress
              : undefined,
          newPhone:
            shouldSendSms && values.phoneNumber !== cardholderPhoneNumber
              ? values.phoneNumber
              : undefined,
        },
      },
    })
    trackAnalyticsEvent(AnalyticsEventNames.TRANSACTION_RECEIPT_SENT, {
      Type: shouldSendEmail ? SendReceiptMode.EMAIL : SendReceiptMode.SMS,
      Location: locationName.current,
    })
  }

  if (isSent) {
    return (
      <StyledContainer>
        <SendReceiptModalSuccess value={receiptSent} closeModal={closeModal} />
      </StyledContainer>
    )
  }

  return (
    <StyledContainer>
      <Box mb="24px">
        <ModalHeader>{component.modal.sendReceipt.header}</ModalHeader>
      </Box>
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        component={({ submitForm, values, isValid }) => {
          const isDefaultEmailValue = Boolean(
            cardHolderEmail && values.emailAddress === cardHolderEmail
          )
          const isDefaultPhoneValue = Boolean(
            cardHolderPhone && values.phoneNumber === cardHolderPhone
          )

          return (
            <StyledReceiptForm>
              <Box mb="32px">
                <SelectCompactField
                  label={component.modal.sendReceipt.dropdownLabel}
                  name="sendTarget"
                  items={sendReceiptOptions}
                  placeholder={component.modal.sendReceipt.placeholder}
                  mobileLabel={component.modal.sendReceipt.dropdownLabel}
                />
              </Box>

              {values.sendTarget === 'email' && (
                <Box mb="40px">
                  <InputAdaptiveFieldWrapper
                    name="emailAddress"
                    type="text"
                    disabled={isDefaultEmailValue}
                    label={component.modal.sendReceipt.emailInputDescription}
                    validate={isDefaultEmailValue ? undefined : validateEmail}
                    data-testid="email-field"
                    size={TEXT_INPUT_SIZE.SMALL}
                    renderRightControls={({ resetInput, value }) =>
                      isDefaultEmailValue && value ? (
                        <ButtonClear onClick={() => resetInput?.()} />
                      ) : null
                    }
                    autoFocus
                  />
                </Box>
              )}

              {values.sendTarget === 'sms' && (
                <Box mb="40px">
                  <InputAdaptiveFieldWrapper
                    name="phoneNumber"
                    type="text"
                    label={component.modal.sendReceipt.SMSInputLabel}
                    disabled={isDefaultPhoneValue}
                    placeholder={
                      component.modal.sendReceipt.SMSInputPlaceholder
                    }
                    validate={
                      isDefaultPhoneValue ? undefined : validatePhoneNumber
                    }
                    data-testid="phone-field"
                    size={TEXT_INPUT_SIZE.SMALL}
                    renderRightControls={({ resetInput, value }) =>
                      isDefaultPhoneValue && value ? (
                        <ButtonClear onClick={() => resetInput?.()} />
                      ) : null
                    }
                    autoFocus
                  />
                </Box>
              )}

              <Flex justifyContent="space-between">
                <CancelButton onClick={closeModal}>
                  {component.modal.sendReceipt.cancelButtonLabel}
                </CancelButton>
                <ConfirmButton
                  onClick={submitForm}
                  disabled={shouldDisabledSending(values, isSending, isValid)}
                  isLoading={isSending}
                >
                  {component.modal.sendReceipt.sendButtonLabel}
                </ConfirmButton>
              </Flex>
            </StyledReceiptForm>
          )
        }}
      />
    </StyledContainer>
  )
}
