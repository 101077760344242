import { createTranslations } from '@npco/utils-translations'

export const setupFlowCardsStageTranslations = createTranslations({
  badge: 'Cards',
  title: 'Cards to streamline your business spending.',
  debitCard: 'Zeller Debit Card',
  point1: 'Issue unlimited free physical and virtual cards.',
  point2: 'Spend funds in any Zeller Transaction Account.',
  point3: 'Add cards to Apple or Google wallet instantly.',
  corporateCard: 'Zeller Corporate Cards',
  point4: 'Issue physical or virtual expense cards.',
  point5: 'Automate recurring budgets for different time periods.',
  point6: 'Try Zeller Corporate cards free for 60 days.',
})
