import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { getDeviceModelIcon } from 'utils/device'

import type { DevicesFilterInputProps } from '../DevicesFilterInput'
import { translations } from './DevicesFilterInputOk.i18n'
import { GroupSelect, GroupSelectProps } from './shared/GroupSelect'

export const DevicesFilterInputOk = ({
  value,
  onValueChange,
  sites,
}: DevicesFilterInputProps.Items.Ok &
  Pick<DevicesFilterInputProps, 'value' | 'onValueChange'>) => {
  const t = useTranslations(translations)

  const items = useMemo((): GroupSelectProps.Items.Ok => {
    const groups = sites.map((site) => ({
      key: site.id,
      label: site.name,
      items: site.devices.map((device) => ({
        key: device.id,
        label: device.name,
        icon: (
          <SvgIcon width="16" height="16" color={COLOR.GREY_250}>
            {getDeviceModelIcon(device.type)}
          </SvgIcon>
        ),
      })),
    }))
    return {
      $type: 'Ok',
      groups,
      actionSelectAll: { label: t('selectAll') },
    }
  }, [sites, t])

  return (
    <GroupSelect value={value} onValueChange={onValueChange} items={items} />
  )
}
