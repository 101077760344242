import { Dispatch, SetStateAction } from 'react'
import { useReactiveVar } from '@apollo/client'
import { getContactName } from 'features/Contacts/Contacts.utils'
import { rvSelectedContactToTransfer } from 'features/Contacts/rv-deprecated/contacts'

import { BankAccountDetails } from 'hooks/paymentInstruments/PaymentInstruments.types'
import { translate } from 'utils/translations'
import { AccountFields } from 'pages/Transfer/AddModal/fields/AccountFields'
import { TransferAddModal } from 'pages/Transfer/AddModal/TransferAddModal'

interface AddBankAccountModalProps {
  onSave: (values: BankAccountDetails) => void
  onCancel: () => void
  isLoading: boolean
  isLinkedPaymentInstrument: boolean
  setIsLinkedPaymentInstrument: Dispatch<SetStateAction<boolean>>
  initialValues?: BankAccountDetails
}

const defaultInitialValues: BankAccountDetails = {
  name: '',
  bsb: '',
  account: '',
}

export const AddBankAccountModal = ({
  onSave,
  onCancel,
  isLoading,
  isLinkedPaymentInstrument,
  setIsLinkedPaymentInstrument,
  initialValues = defaultInitialValues,
}: AddBankAccountModalProps) => {
  const selectedContact = useReactiveVar(rvSelectedContactToTransfer)
  const selectedContactName = getContactName(selectedContact)

  return (
    <TransferAddModal
      title={translate('page.transfer.addAccountModalTitle')}
      onSave={onSave}
      onCancel={onCancel}
      initialValues={initialValues}
      isOpen
      isLoading={isLoading}
    >
      <AccountFields
        isLinkedPaymentInstrument={isLinkedPaymentInstrument}
        setIsLinkedPaymentInstrument={setIsLinkedPaymentInstrument}
        selectedContactName={selectedContactName ?? ''}
        initialValues={initialValues}
      />
    </TransferAddModal>
  )
}
