import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseGetContactPieChartDataCashFlowContactNetAmountFragmentDoc } from './hooks/useGetContactPieChartData.generated'

export type ContactsChartCashFlowContactNetAmountFragment = {
  __typename?: 'CashFlowContactNetAmount'
  contact: {
    __typename: 'Contact'
    id: string
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    lastName: string | null
  } | null
  total: { __typename?: 'Money'; value: string }
}

export const ContactsChartCashFlowContactNetAmountFragmentDoc = gql`
  fragment ContactsChartCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    ...UseGetContactPieChartDataCashFlowContactNetAmountFragment
  }
  ${UseGetContactPieChartDataCashFlowContactNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  ContactsChartCashFlowContactNetAmountFragment,
  undefined
>
