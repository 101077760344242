import {
  DebitCardTransactionFilterInputV2,
  DebitCardTransactionsExportFormat,
} from '@npco/mp-gql-types'
import {
  ExportTransactionsErrorMessage,
  useExportTransactionsModal,
} from 'features/ExportTransactions'

import { DebitCardExportTransactionsModalContent } from './DebitCardExportTransactionsModalContent/DebitCardExportTransactionsModalContent'

interface Props {
  closeModal: () => void
  filename: string
  format: DebitCardTransactionsExportFormat
  filters: DebitCardTransactionFilterInputV2
}

export const ExportDebitCardTransactionsModalContent = ({
  closeModal,
  filename,
  format,
  filters,
}: Props) => {
  const { renderContent, hasTimePassed, setHasTimePassed, rerenderContent } =
    useExportTransactionsModal()

  return (
    <>
      {renderContent && !hasTimePassed && (
        <DebitCardExportTransactionsModalContent
          filename={filename}
          rerenderContent={rerenderContent}
          closeModal={closeModal}
          setHasTimePassed={setHasTimePassed}
          format={format}
          filters={filters}
        />
      )}
      {hasTimePassed && (
        <ExportTransactionsErrorMessage
          hasTimePassed={hasTimePassed}
          closeModal={closeModal}
          rerenderContent={rerenderContent}
        />
      )}
    </>
  )
}
