import { BodySmallTypography, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTitle = styled(H3)`
  margin: 0;
  padding: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin-bottom: 1rem;
`

export const StyledDescription = styled.div`
  ${BodySmallTypography};
  margin: 0;
  max-width: 18rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
