import { ApolloCache } from '@apollo/client'

interface UpdateCacheOnUnpairOptions {
  cache: ApolloCache<any>
  id: string
}

export const updateCacheOnUnpair = ({
  cache,
  id,
}: UpdateCacheOnUnpairOptions) => {
  cache.modify({
    id: cache.identify({
      __typename: 'Device',
      id,
    }),
    fields: {
      posSettings(existingPosSettings) {
        return {
          ...existingPosSettings,
          oracleSettings: {
            workstationId: '',
          },
        }
      },
    },
  })
  cache.modify({
    id: cache.identify({
      __typename: 'DeviceSettings',
      id,
    }),
    fields: {
      posSettings(existingPosSettings) {
        return {
          ...existingPosSettings,
          oracleSettings: null,
        }
      },
    },
  })
}
