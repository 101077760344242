import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  GetInvoicesFilterInput,
  GetInvoicesSortInput,
  InvoiceStatus,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { Subset } from 'types/utils'

import { CacheLocalStateEntityScopedVariable } from '../../../../../graphql/cache.types'
import { InvoicesDefaultLocalState } from '../../../Invoicing.constants'
import { CacheLocalStateInvoices } from '../../../Invoicing.types'
import { GetInvoicesLocalState } from '../graphql/getInvoicesLocalState'
import {
  AmountColumnEnum,
  DateColumnEnum,
} from '../InvoiceTable/InvoiceTable.types'

interface UseInvoicesLocalCacheProps {
  sortingObject: GetInvoicesSortInput | null
}

export const useInvoicesLocalCache = ({
  sortingObject,
}: UseInvoicesLocalCacheProps) => {
  const { cache } = useApolloClient()
  const entityUuid = useSelectedEntityUuid()

  const updateCache = useCallback(
    (next: Subset<CacheLocalStateInvoices>) =>
      // NOTE: a local custom merge function preserves any other state under the
      // local key so we only need to provide the updates under invoices
      // apps/src/graphql/cache.ts:138
      cache.updateQuery<
        Subset<CacheLocalStateInvoices> | null,
        CacheLocalStateEntityScopedVariable
      >(
        {
          query: GetInvoicesLocalState,
          variables: { entityUuid },
        },
        (previous) => ({
          local: {
            invoices: {
              ...previous?.local?.invoices,
              ...next.local?.invoices,
            },
          },
        })
      ),
    [cache, entityUuid]
  )
  const setSortingObject = useCallback(
    (nextSort: NonNullable<GetInvoicesSortInput> | null) => {
      updateCache({
        local: {
          invoices: {
            sort: nextSort,
          },
        },
      })
    },
    [updateCache]
  )

  const setFilterInput = useCallback(
    (nextFilter: GetInvoicesFilterInput, clearSort = false) => {
      updateCache({
        local: {
          invoices: {
            filter: nextFilter,
            sort: clearSort ? null : sortingObject,
          },
        },
      })
    },
    [sortingObject, updateCache]
  )

  const setSelectedAmountColumn = useCallback(
    (nextColumn: AmountColumnEnum) => {
      updateCache({
        local: {
          invoices: {
            columns: {
              amount: nextColumn,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setSelectedDateColumn = useCallback(
    (nextColumn: DateColumnEnum) => {
      updateCache({
        local: {
          invoices: {
            columns: {
              date: nextColumn,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setSelectedStatuses = useCallback(
    (nextStatuses: InvoiceStatus[]) => {
      updateCache({
        local: {
          invoices: {
            statuses: nextStatuses,
          },
        },
      })
    },
    [updateCache]
  )

  const resetState = useCallback(() => {
    updateCache({
      local: {
        invoices: InvoicesDefaultLocalState,
      },
    })
  }, [updateCache])

  return {
    resetState,
    setFilterInput,
    setSelectedAmountColumn,
    setSelectedDateColumn,
    setSelectedStatuses,
    setSortingObject,
  }
}
