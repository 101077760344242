import { BodyDefault, BodyLarge, H1 } from '@npco/zeller-design-system'
import { Form } from 'formik'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button as BaseButton } from 'components/Buttons/Button'

export const StyledHeader = styled(H1)`
  margin: 0 0 24px;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledLargeText = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.BLUE_1000};
  margin: 0;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 24px 0 0;
  }
`

export const StyledCopy = styled(BodyLarge)`
  margin: 0;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.BLACK_900};
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin-bottom: 36px;
  }
`

export const StyledButton = styled(BaseButton)`
  width: 100%;
  min-width: 12.5rem;
  margin: 2rem 0 0;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
    margin: 2rem 0 1.5rem;
  }
`

export const StyledFormWrapper = styled(Form)`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`

export const StyledFakeImageDownloaderForSignUp = styled.img`
  visibility: hidden;
  width: 0;
  height: 0;
`
