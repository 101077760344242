import type { Pairing } from '../HlPosManage.types'

export type PairingsAssignments = {
  bySiteId: Record<string, { venueId: string; locationId?: string }>
  byVenueId: Record<
    string,
    {
      siteIds: string[]
      siteIdsByLocationId: Record<string, string[]>
    }
  >
}

export const getPairingsAssignments = (pairings: Pairing[]) => {
  return pairings.reduce(
    (acc, pairing) => {
      const { venueId, siteUuid: siteId, locationId } = pairing
      const hasDuplicateAssignment = !!acc.bySiteId[siteId]
      if (!hasDuplicateAssignment) {
        acc.bySiteId[siteId] = {
          venueId,
          locationId: locationId ?? undefined,
        }
        const venueConfig = acc.byVenueId[venueId] ?? {
          siteIds: [],
          siteIdsByLocationId: {},
        }
        acc.byVenueId[venueId] = venueConfig
        if (locationId) {
          const siteIds = venueConfig.siteIdsByLocationId[locationId] ?? []
          siteIds.push(siteId)
          venueConfig.siteIdsByLocationId[locationId] = siteIds
        } else {
          venueConfig.siteIds.push(siteId)
        }
      }
      return acc
    },
    {
      bySiteId: {},
      byVenueId: {},
    } as PairingsAssignments
  )
}
