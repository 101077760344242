import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseAvailableFundsCorporateCardDebitCardV2FragmentDoc } from '../../hooks/useAvailableFundsCorporateCard/useAvailableFundsCorporateCard.generated'

export type ExpenseCardBudgetMessageDisplayDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  id: string
  status: Types.DebitCardStatus
  debitCardAccount: {
    __typename?: 'DebitCardAccountV2'
    id: string
    balance: { __typename?: 'Money'; value: string } | null
  }
  velocityControl: {
    __typename?: 'VelocityControlType'
    velocityWindow: Types.VelocityWindowEnum
    resetsAt: any
    amountLimit: { __typename?: 'Money'; value: string }
    maxTransactionValue: { __typename?: 'Money'; value: string }
    modifiedBy: {
      __typename?: 'VelocityControlModifiedByType'
      actingCustomerUuid: string
      updatedAt: any
    } | null
    availableAmount: { __typename?: 'Money'; value: string }
  } | null
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
  } | null
}

export const ExpenseCardBudgetMessageDisplayDebitCardV2FragmentDoc = gql`
  fragment ExpenseCardBudgetMessageDisplayDebitCardV2Fragment on DebitCardV2 {
    id
    status
    debitCardAccount {
      id
      balance {
        value
      }
    }
    velocityControl {
      amountLimit {
        value
      }
      maxTransactionValue {
        value
      }
      velocityWindow
      modifiedBy {
        actingCustomerUuid
        updatedAt
      }
    }
    customer {
      id
      entityUuid
    }
    ...UseAvailableFundsCorporateCardDebitCardV2Fragment
  }
  ${UseAvailableFundsCorporateCardDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ExpenseCardBudgetMessageDisplayDebitCardV2Fragment,
  undefined
>
