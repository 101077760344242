import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvDepositsPdfFont } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

export function urlContentToDataUri(url: string): Promise<string> {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = () => resolve(reader.result as string)
          reader.onerror = (error) => reject(error)
        })
    )
}

export const useGetFont = () => {
  const font = useReactiveVar(rvDepositsPdfFont)
  const isFontLoaded = useMemo(() => Boolean(font), [font])

  if (!isFontLoaded) {
    urlContentToDataUri(
      `${process.env.PUBLIC_URL}/InterZeller-Regular.ttf`
    ).then((fontBase64) => {
      const parsedFont = fontBase64.replace(/^[^,]*,/i, '')
      rvDepositsPdfFont(parsedFont)
    })
  }

  return {
    font,
    isFontLoaded,
  }
}
