import { useMemo, useState } from 'react'
import { pick } from 'ramda'

import { ChartBar } from '../Charts.types'

type ChartRef = {
  props?: {
    data?: ChartBar[]
  }
}

export const useChartBars = () => {
  const [bars, setBars] = useState<ChartBar[]>([])

  const onChartRef = useMemo(
    () => (ref: ChartRef) => {
      const newBars =
        ref?.props?.data?.map((bar) => pick(['height', 'y'], bar)) ?? []
      setBars(newBars)
    },
    [setBars]
  )

  return {
    onChartRef,
    bars,
  }
}
