import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetCustomerBasicInfo } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'

import {
  GetCustomerBasicInfo as GetCustomerType,
  GetCustomerBasicInfoVariables,
} from 'types/gql-types/GetCustomerBasicInfo'

export const useGetCustomerBasicInfo = (customerUuid: string) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useQuery<
    GetCustomerType,
    GetCustomerBasicInfoVariables
  >(GetCustomerBasicInfo, {
    variables: {
      entityUuid,
      customerUuid,
    },
    skip: !customerUuid,
  })
  return {
    customer: data?.getCustomer,
    isLoading: loading,
  }
}
