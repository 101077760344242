import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CreateSelectAccountState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { GetDebitCardAccountsForCardCreationQueryResponse } from 'pages/GlobalModals/AddCardModal/graphql/accounts.generated'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { SelectAccountFormValues } from 'pages/GlobalModals/AddCardModal/pages/shared/SearchableAccountForm/SearchableAccountForm.types'

import { getAccountNameByAccountId } from './useSelectAccountForm.utils'

type Account = NonNullable<
  GetDebitCardAccountsForCardCreationQueryResponse['getDebitCardAccountsV2']['accounts'][0]
>

export const useSelectAccountForm = (accounts: Account[]) => {
  const { goToAddCardStage } = useGoToAddCardStage()
  const { state } = useAddCardState<CreateSelectAccountState>()
  const selectAccount = useCallback(
    (values: SelectAccountFormValues) => {
      if (state === null) {
        ErrorLogger.report(
          '[Banking] selectAccountStage submitting with null state.'
        )
        showErrorToast()
        return
      }

      const { accountId } = values
      const { cardType, cardholder } = state
      const accountInfo = {
        id: accountId,
        name: getAccountNameByAccountId(accounts, accountId),
      }
      goToAddCardStage({
        stage: AddCardStage.CreateSelectCardFormatStage,
        cardType,
        cardholder,
        account: accountInfo,
      })
    },
    [goToAddCardStage, state, accounts]
  )

  const goBackToSelectCardholderPage = useCallback(() => {
    if (state === null) {
      ErrorLogger.report(
        '[Banking] selectAccountStage back to SelectAccountStage with null state.'
      )
      showErrorToast()
      return
    }

    const { cardType, cardholder } = state
    goToAddCardStage({
      stage: AddCardStage.CreateSelectCardholderStage,
      cardType,
      cardholder,
    })
  }, [goToAddCardStage, state])

  return {
    selectAccount,
    goBackToSelectCardholderPage,
    initialAccountId: state?.account?.id || '',
  }
}
