import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import { convertFromHTML } from 'draft-convert'
import { EditorState, getDefaultKeyBinding } from 'draft-js'
import {
  INVOICE_EMAIL_MESSAGE_FIELD,
  INVOICE_EMAIL_SUBJECT_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'

import { KEY_CODE } from 'const/keyboard'

import { EntityTagItem } from '../InvoiceDeliveryAccordion.constants'
import { getEmailCompositeDecorator } from '../InvoiceDeliveryAccordion.utils'
import { useEmailMergeTags } from './useEmailMergeTags'
import {
  cloneEditorState,
  convertToHtml,
  geEntityRangesInContentBlock,
  getCurrentBlock,
  insertEntityMergeTag,
  removeFocusedDataOnEntity,
  setMergeTagsValues,
  updateEditorWithNewMergeTagEntity,
  updateFocusedEntity,
  updateMergeTags,
} from './useEmailRichTextEditor.utils'

export const compositeDecorator = getEmailCompositeDecorator()

export interface UseEmailRichTextEditorProps {
  fieldName:
    | typeof INVOICE_EMAIL_SUBJECT_FIELD
    | typeof INVOICE_EMAIL_MESSAGE_FIELD
  hasFieldError: boolean
  initialMarkup: string
  isDefaultTemplate: boolean
  onChange: (emailValue: string) => void
  onBlur: () => void
}

export const useEmailRichTextEditor = ({
  fieldName,
  hasFieldError,
  initialMarkup,
  isDefaultTemplate,
  onBlur,
  onChange,
}: UseEmailRichTextEditorProps) => {
  const isFocused = useRef(false)
  const focusedEntityKeys = useRef<string[]>([])
  const keyCodePressed = useRef('')
  const handleChange = useRef(onChange)

  const { mergeTagsMap } = useEmailMergeTags()

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      convertFromHTML(initialMarkup),
      compositeDecorator
    )
  )

  const editorStateRef = useRef<EditorState>(editorState)

  useEffect(() => {
    handleChange.current = onChange
  }, [onChange])

  useEffect(() => {
    // useRef pattern to not execute useEffect below when the editor changes
    // but still keep editorState up to date
    editorStateRef.current = editorState
    const currentContent = editorState.getCurrentContent()

    handleChange.current(
      // We don't want empty <p> tag for validation
      currentContent.getPlainText().trim()
        ? convertToHtml(
            currentContent,
            fieldName === INVOICE_EMAIL_SUBJECT_FIELD
          )
        : ''
    )
  }, [editorState, fieldName])

  const handleSetMergeTagsValues = useCallback(() => {
    const newEditorWidthMergeTagsValues = setMergeTagsValues(
      EditorState.createWithContent(
        convertFromHTML(initialMarkup),
        compositeDecorator
      ),
      mergeTagsMap,
      hasFieldError
    )
    if (newEditorWidthMergeTagsValues) {
      editorStateRef.current = newEditorWidthMergeTagsValues
      setEditorState(cloneEditorState(newEditorWidthMergeTagsValues))
    }
  }, [hasFieldError, initialMarkup, mergeTagsMap])

  useEffect(() => {
    // Will execute effect once template was reset
    if (!isDefaultTemplate) {
      return
    }

    handleSetMergeTagsValues()
  }, [isDefaultTemplate, handleSetMergeTagsValues])

  useEffect(() => {
    handleSetMergeTagsValues()
    // set merge tags values on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectionState = editorState.getSelection()
  const anchorOffset = selectionState.getAnchorOffset()
  const currentBlockKey = getCurrentBlock(editorStateRef.current).getKey()

  useEffect(() => {
    if (!isFocused.current) {
      // ignore effect onBlur
      return
    }

    const executeSearch = async () => {
      const entityRangesInBlock = await geEntityRangesInContentBlock(
        editorStateRef.current
      )

      let isUpdated = false
      let editorStateToUpdate = cloneEditorState(editorStateRef.current)

      // Filter all non-existing focused entities
      focusedEntityKeys.current = focusedEntityKeys.current.filter(
        (entityKey) =>
          entityRangesInBlock.some((entity) => entity.entityKey === entityKey)
      )

      // Update editorState with focused entity first
      entityRangesInBlock.forEach((entityRange) => {
        const { blockKey, start, end, entityKey } = entityRange
        // If cursor is inside the entity, determine cursor
        // selection update and set entity focused state
        if (
          currentBlockKey === blockKey &&
          anchorOffset > start &&
          anchorOffset < end &&
          // Ignore operation if entity is already focused,
          // but execute if left/right arrow was pressed
          (!focusedEntityKeys.current.includes(entityKey) ||
            keyCodePressed.current)
        ) {
          editorStateToUpdate = updateFocusedEntity(
            editorStateToUpdate,
            entityRange,
            keyCodePressed.current
          )
          focusedEntityKeys.current =
            focusedEntityKeys.current.concat(entityKey)
          keyCodePressed.current = ''
          isUpdated = true
        }
      })

      // Update editorState with removed focused state
      entityRangesInBlock.forEach((entityRange) => {
        const { blockKey, start, end, entityKey } = entityRange
        if (
          blockKey === currentBlockKey &&
          anchorOffset > start &&
          anchorOffset < end
        ) {
          return
        }
        if (focusedEntityKeys.current.includes(entityKey)) {
          // Remove focused state on existing entities that have been previously focused
          editorStateToUpdate = removeFocusedDataOnEntity(
            editorStateToUpdate,
            entityKey
          )
          focusedEntityKeys.current = focusedEntityKeys.current.filter(
            (key) => key !== entityKey
          )
          isUpdated = true
        }
      })

      if (isUpdated) {
        setEditorState(editorStateToUpdate)
      }
    }
    executeSearch()
  }, [anchorOffset, currentBlockKey])

  useEffect(() => {
    updateMergeTags(editorStateRef.current, mergeTagsMap, hasFieldError).then(
      (updatedEditorState) => {
        if (updatedEditorState) {
          setEditorState(updatedEditorState)
        }
      }
    )
  }, [hasFieldError, mergeTagsMap, mergeTagsMap.invoiceNumber.text])

  const sendMergeTagToEditor = (entityTagItem: EntityTagItem) => {
    setEditorState(insertEntityMergeTag(entityTagItem, editorState))
    isFocused.current = true
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    const isArrowLeftOrRight =
      event.code === KEY_CODE.ARROW_RIGHT || event.code === KEY_CODE.ARROW_LEFT

    if (!focusedEntityKeys.current.length || !isArrowLeftOrRight) {
      return getDefaultKeyBinding(event)
    }

    keyCodePressed.current = event.code
    return getDefaultKeyBinding(event)
  }

  const handleEditorBlur = () => {
    onBlur()
    isFocused.current = false
  }

  const handleEditorFocus = () => {
    isFocused.current = true
  }

  const handleEditorChange = (state: EditorState) => {
    if (!isFocused.current) {
      const entityKeys = focusedEntityKeys.current
      // Remove focused state on entities on blur
      entityKeys.forEach((entityKey) =>
        setEditorState(
          removeFocusedDataOnEntity(editorStateRef.current, entityKey)
        )
      )

      focusedEntityKeys.current = []
      keyCodePressed.current = ''

      if (entityKeys.length) {
        // Return early if editorState has already been updated
        // to remove focused state on entities
        return
      }
    }
    setEditorState(state)
  }

  return {
    availableMergeTags: mergeTagsMap,
    handleEditorBlur,
    handleEditorChange,
    handleEditorFocus,
    handleKeyPress,
    isFocused: isFocused.current,
    sendMergeTagToEditor,
    textEditorState: editorState,
    updateEditorWithNewMergeTagEntity,
  }
}
