import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { EmptyAccountsEntityFragmentDoc } from '../EmptyAccounts/EmptyAccounts.generated'
import { ListItemAccountsDebitCardAccountV2FragmentDoc } from './ListItemAccounts/ListItemAccounts.generated'

export type ListAccountsDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  type: Types.DebitCardAccountType
  name: string
  id: string
  status: Types.DebitCardAccountStatus
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    image: string | null
    animation: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  cardsCount: {
    __typename?: 'DebitCardAccountV2CardsCount'
    total: number
    debit: number
    expense: number | null
  }
  balance: { __typename?: 'Money'; value: string } | null
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    effectiveInterestRate: string | null
  } | null
}

export type ListAccountsEntityFragment = {
  __typename?: 'Entity'
  canCreateAccount: boolean | null
}

export const ListAccountsDebitCardAccountV2FragmentDoc = gql`
  fragment ListAccountsDebitCardAccountV2Fragment on DebitCardAccountV2 {
    ...ListItemAccountsDebitCardAccountV2Fragment
  }
  ${ListItemAccountsDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ListAccountsDebitCardAccountV2Fragment,
  undefined
>
export const ListAccountsEntityFragmentDoc = gql`
  fragment ListAccountsEntityFragment on Entity {
    ...EmptyAccountsEntityFragment
  }
  ${EmptyAccountsEntityFragmentDoc}
`
