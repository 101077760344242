import { useCallback, useMemo } from 'react'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useReadUpdatedOutstandingTransactionsLocalCache } from 'features/Cards/CardSingle/hooks/useUpdatedOutstandingTransactions'

import { useSubscribeToAccounts, useUnlockCard } from 'hooks/banking'
import { useGetEntityAddressTimezone } from 'hooks/useGetEntityAddressTimezone'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'
import { DebitCardTransactions } from 'pages/DebitCardTransactions/DebitCardTransactions'
import { ListGroupItemDebitCardTransactions } from 'pages/DebitCardTransactions/DebitCardTransactionsList/ListGroupItemDebitCardTransactions/ListGroupItemDebitCardTransactions'

import { CardsErrorPage } from '../CardsErrorPage'
import { cardSingleTranslations } from './CardSingle.i18n'
import { CardSingleLayout } from './CardSingleLayout/CardSingleLayout'
import { CardTransactionsError } from './CardTransactionsError/CardTransactionsError'
import { ZellerCardSummaryDisplay } from './DebitCardSummaryDisplay/ZellerCardSummaryDisplay'
import { useCardId } from './hooks/useCardId'
import { useCardSingleData } from './hooks/useCardSingleData/useCardSingleData'
import { useCardSingleFilters } from './hooks/useCardSingleFilters'
import { useFilteredOutstandingTransactions } from './hooks/useFilteredOutstandingTransactions/useFilteredOutstandingTransactions'
import { useGroupedDebitCardTransactions } from './hooks/useGroupedDebitCardTransactions'
import {
  useGetOutstandingFilter,
  useToggleOutstandingFilter,
} from './hooks/useOutstandingFilter'
import { useOutstandingFilterSettings } from './hooks/useOutstandingFilterSettings'
import { useRedirectToCardList } from './hooks/useRedirectToCardList'
import { OutstandingExpensesEmpty } from './OutstandingExpensesEmpty'
import { OutstandingExpensesToggle } from './OutstandingExpensesToggle/OutstandingExpensesToggle'
import { getForwardCorporateCardTransactionRow } from './utils/getForwardCorporateCardTransactionRow'

export const CardSingle = () => {
  const { cardId } = useCardId()
  const { redirectToCardList } = useRedirectToCardList()
  const { isFilterOn } = useGetOutstandingFilter()
  const { toggleFilter } = useToggleOutstandingFilter()

  const {
    card,
    entity,
    isLoading: isLoadingCard,
    error,
    refetchCardSingleData,
  } = useCardSingleData({
    cardId,
    onNotFound: redirectToCardList,
  })

  const {
    isLoadingOutstandingFilterSettings,
    outstandingSettings,
    isOutstandingFilterSettingsAllInactive,
  } = useOutstandingFilterSettings()

  const cardDetailsError = !card ? error : undefined

  const { isLoading: isLoadingEntityAddressTimezone, timezone } =
    useGetEntityAddressTimezone()

  useSubscribeToAccounts({
    skip: isLoadingCard || Boolean(cardDetailsError),
  })

  const { filters, areFiltersInDefaultState } = useCardSingleFilters()

  const {
    groupedDebitCardTransactions,
    hasNoInitialResults,
    onLoadMore,
    isLoadingTransactions,
    hasMore,
    debitCardTransactionsError,
    refetchTransactions,
    handleSelectedTransactionUpdate,
  } = useGroupedDebitCardTransactions({
    filters,
    areFiltersInDefaultState,
    cardId,
    isOutstandingExpensesFilterOn: isFilterOn,
    isLoadingOutstandingFilterSettings,
    outstandingSettings,
  })

  const { updatedOutstandingTransactions } =
    useReadUpdatedOutstandingTransactionsLocalCache()

  const { filteredOutstandingTransactions } =
    useFilteredOutstandingTransactions({
      isFilterOn,
      groupedTransactions: groupedDebitCardTransactions,
      outstandingSettings,
      updatedOutstandingTransactions: updatedOutstandingTransactions.filter(
        (updatedOutstandingTransaction) =>
          updatedOutstandingTransaction.transaction.debitCardId === cardId
      ),
    })

  const haveAllQueriesFailed = Boolean(
    cardDetailsError && debitCardTransactionsError
  )

  const refetchAllData = useCallback(() => {
    refetchCardSingleData()
    refetchTransactions()
  }, [refetchCardSingleData, refetchTransactions])

  const { unlockCard, isUnlockingCard } = useUnlockCard(cardId)

  const handleUnlockCard = useCallback(async () => {
    const result = await unlockCard()

    if (result === UNEXPECTED_ERROR) {
      showErrorToast()
    }
    showSuccessToast(translate('page.cardActionSuccessMessage.unlock'))
  }, [unlockCard])

  const t = useTranslations(cardSingleTranslations)

  const productType = card?.productType
  const isProductTypeExpense = productType === DebitCardProductType.EXPENSE

  const TransactionRow = useMemo(
    () =>
      isProductTypeExpense
        ? getForwardCorporateCardTransactionRow({
            isFilterOn,
            outstandingSettings,
          })
        : ListGroupItemDebitCardTransactions,
    [isFilterOn, isProductTypeExpense, outstandingSettings]
  )

  return (
    <CardSingleLayout
      card={card}
      areFiltersApplied={!areFiltersInDefaultState}
      areFiltersDisabled={Boolean(
        isLoadingTransactions ||
          isLoadingCard ||
          !!debitCardTransactionsError ||
          hasNoInitialResults
      )}
      unlockCard={handleUnlockCard}
      timezone={timezone ?? null}
    >
      {haveAllQueriesFailed ? (
        <CardsErrorPage retry={refetchAllData} description={t('description')} />
      ) : (
        <>
          <ZellerCardSummaryDisplay
            zellerCard={card}
            entity={entity}
            isLoading={isLoadingCard || isLoadingEntityAddressTimezone}
            timezone={timezone}
            error={cardDetailsError}
            retry={refetchCardSingleData}
            isLoadingStatus={isUnlockingCard}
          />
          <Box mt="40px" position="relative">
            {isProductTypeExpense && !hasNoInitialResults && (
              <OutstandingExpensesToggle
                card={card}
                isFilterOn={isFilterOn}
                toggleFilter={toggleFilter}
                isLoadingOutstandingFilterSettings={
                  isLoadingOutstandingFilterSettings
                }
                isOutstandingFilterSettingsAllInactive={
                  isOutstandingFilterSettingsAllInactive
                }
              />
            )}
            <DebitCardTransactions
              groupedDebitCardTransactions={filteredOutstandingTransactions}
              hasNoInitialResults={hasNoInitialResults}
              onLoadMore={onLoadMore}
              loading={
                isLoadingTransactions ||
                isLoadingCard ||
                isLoadingOutstandingFilterSettings
              }
              hasMore={hasMore}
              error={debitCardTransactionsError}
              refetch={refetchTransactions}
              handleSelectedTransactionUpdate={handleSelectedTransactionUpdate}
              listHeight="100%"
              ErrorDisplay={CardTransactionsError}
              Row={TransactionRow}
              filteredEmptyState={
                isFilterOn && areFiltersInDefaultState ? (
                  <OutstandingExpensesEmpty />
                ) : undefined
              }
            />
          </Box>
        </>
      )}
    </CardSingleLayout>
  )
}
