import { useCallback } from 'react'
import { PageData } from 'analytics'

import { EventMapsForPayload, Events } from './events'
import { useAnalyticsContext } from './useAnalyticsContext'
import { useDeviceType } from './useDeviceType'
import { useUtmSourceAndMedium } from './useUtmSourceAndMedium'

type AnalyticsTrackCustomMethod = <T extends Events>(
  eventName: T,
  payload?: EventMapsForPayload[T],
  options?: any,
  callback?: (...params: any[]) => any
) => void

export const useAnalyticsLogger = () => {
  const { analytics } = useAnalyticsContext()
  const { deviceType } = useDeviceType()
  const { getUtmSourceAndMedium } = useUtmSourceAndMedium()

  const trackAnalyticsEvent = useCallback<AnalyticsTrackCustomMethod>(
    (eventName, payload, options, callback) => {
      const updatedPayload = {
        ...payload,
        DeviceType: deviceType,
        utmSource: getUtmSourceAndMedium()?.utmSource,
        utmMedium: getUtmSourceAndMedium()?.utmMedium,
      }
      analytics?.track(eventName, updatedPayload, options, callback)
    },
    [deviceType, getUtmSourceAndMedium, analytics]
  )

  const identifyAnalyticsUser = useCallback(
    (
      userId: string,
      traits?: any,
      options?: any,
      callback?: (...params: any[]) => any
    ) => {
      analytics?.identify(userId, traits, options, callback)
    },
    [analytics]
  )

  const logAnalyticsGroup = useCallback(
    (groupId: string, traits?: any, options?: any, callback?: () => void) => {
      analytics?.plugins.segment.group(groupId, traits, options, callback)
    },
    [analytics?.plugins.segment]
  )

  const logAnalyticsPage = useCallback(
    (data?: PageData, options?: any, callback?: (...params: any[]) => any) => {
      analytics?.page(data, options, callback)
    },
    [analytics]
  )

  return {
    identifyAnalyticsUser,
    logAnalyticsGroup,
    logAnalyticsPage,
    trackAnalyticsEvent,
  }
}
