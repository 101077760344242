import { DebitCardColour } from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'
import { rgba } from 'polished'

export const getBackgroundColor = (
  cardColor: DebitCardColour,
  isHoveredOrFocused: boolean,
  isCardDetailDisplayed: boolean
) => {
  if (isCardDetailDisplayed) {
    return rgba(COLOR.BLACK, isHoveredOrFocused ? 0.5 : 0.4)
  }

  if (cardColor === DebitCardColour.BLACK) {
    return rgba(COLOR.WHITE, isHoveredOrFocused ? 0.25 : 0.2)
  }

  return rgba(COLOR.BLACK, isHoveredOrFocused ? 0.6 : 0.5)
}

export const getWidth = (isLoading: boolean, isViewingDetails: boolean) => {
  if (isLoading || isViewingDetails) {
    return '48px'
  }

  return '135px'
}
