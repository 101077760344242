import { useTranslations } from '@npco/utils-translations'

import { TransferCompleteAnimation } from 'pages/Transfer/TransferCompleteAnimation/TransferCompleteAnimation'

import { ShowDetailsButton } from './ShowDetailsButton'
import { transferConfirmationHeaderTranslations } from './TransferConfirmationHeader.i18n'
import * as styled from './TransferConfirmationHeader.styled'
import { TransferConfirmationHeaderTitle } from './TransferConfirmationHeaderTitle'

type TransferDetailsHiddenProps = {
  modalMinHeight: number
  behindModalRef: React.RefObject<HTMLDivElement>
  isAnimationComplete: boolean
  setIsAnimationComplete: (isAnimationComplete: boolean) => void
  toggleShowDetails: () => void
  paddingBottom: string
  isExpanded: boolean
  isTransferRemittancesFlagOn: boolean
}

export const TransferDetailsHidden = ({
  modalMinHeight,
  behindModalRef,
  isAnimationComplete,
  setIsAnimationComplete,
  toggleShowDetails,
  paddingBottom,
  isExpanded,
  isTransferRemittancesFlagOn,
}: TransferDetailsHiddenProps) => {
  const t = useTranslations(transferConfirmationHeaderTranslations)
  return (
    <>
      {isTransferRemittancesFlagOn && (
        <ShowDetailsButton
          isExpanded={isExpanded}
          paddingBottom={paddingBottom}
          toggleShowDetails={toggleShowDetails}
          isAnimationComplete={isAnimationComplete}
          buttonText={t('showDetailsText')}
          isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
        />
      )}
      <TransferCompleteAnimation
        modalMinHeight={modalMinHeight}
        behindModalRef={behindModalRef}
        isAnimationComplete={isAnimationComplete}
        setIsAnimationComplete={setIsAnimationComplete}
      />
      <styled.TitleWrapper $isTransferComplete={isAnimationComplete}>
        <TransferConfirmationHeaderTitle
          isTransferring={!isAnimationComplete}
        />
        {isAnimationComplete && !isTransferRemittancesFlagOn && (
          <ShowDetailsButton
            isExpanded={isExpanded}
            paddingBottom={paddingBottom}
            toggleShowDetails={toggleShowDetails}
            isAnimationComplete={isAnimationComplete}
            buttonText={t('showDetailsText')}
            isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
          />
        )}
      </styled.TitleWrapper>
    </>
  )
}
