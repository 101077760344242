import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { CorporateCardWalkthroughStage } from '../../CorporateCardWalkthrough.types'

export const useGoToCorporateCardWalkthroughStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToCorporateCardWalkthroughStage = useCallback(
    (stage: CorporateCardWalkthroughStage | undefined) => {
      navigate(location.pathname, {
        state: {
          CorporateCardWalkthroughModal: { stage },
        },
      })
    },
    [navigate, location.pathname]
  )

  return { goToCorporateCardWalkthroughStage }
}
