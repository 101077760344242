import { createTranslations } from '@npco/utils-translations'

export const spendControlFrequencyTranslations = createTranslations({
  header: 'Spend Control Frequency',
  headerTooltip: 'Select how long this budget will last for before resetting.',
  fieldLabel: 'Reset Period',
  resetDailyDescription: 'Resets 12am every day',
  resetWeeklyDescription: 'Resets 12am every Sunday',
  resetMonthlyDescription: 'Resets 12am first day of the month',
  resetFortnightlyDescription: 'Resets 12am every Sunday every 2 weeks',
  resetQuarterlyDescription: 'Resets first day of Jan, Apr, Jul and Oct',
})
