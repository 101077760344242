import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { DetailsListItem } from 'components/DetailsListItem'

import { StyledDetailsListItemsDivider } from '../../TransactionDetailsInfo.styled'
import {
  BpayDetailsDebitCardAccountTransactionFragment,
  BpayDetailsDebitCardTransactionV2Fragment,
} from './BpayDetails.generated'
import { translations } from './BpayDetails.i18n'

interface Props {
  transaction:
    | BpayDetailsDebitCardTransactionV2Fragment
    | BpayDetailsDebitCardAccountTransactionFragment
}

export const BpayDetails = ({ transaction }: Props) => {
  const t = useTranslations(translations)
  return (
    <>
      <Box mt="24px" mb="24px">
        <DetailsListItem
          label={t('from')}
          value={transaction.payerDetails?.debitCardAccount?.name ?? '-'}
        />
        <DetailsListItem
          label={t('nickname')}
          value={transaction.payeeDetails?.bpayDetails?.nickname ?? '-'}
        />
        <DetailsListItem
          label={t('biller')}
          value={transaction.payeeDetails?.bpayDetails?.billerCode ?? '-'}
        />
        <DetailsListItem
          label={t('billerName')}
          value={transaction.payeeDetails?.bpayDetails?.billerName ?? '-'}
        />
        <DetailsListItem
          label={t('crn')}
          value={transaction.payeeDetails?.bpayDetails?.crn ?? '-'}
        />
      </Box>
      <StyledDetailsListItemsDivider />
    </>
  )
}

BpayDetails.fragments = {
  DebitCardTransactionV2: gql`
    fragment BpayDetailsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payeeDetails {
        bpayDetails {
          billerName
          billerCode
          crn
          nickname
        }
      }
      payerDetails {
        debitCardAccount {
          name
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment BpayDetailsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payeeDetails {
        bpayDetails {
          billerName
          billerCode
          crn
          nickname
        }
      }
      payerDetails {
        debitCardAccount {
          name
        }
      }
    }
  `,
}
