import styled from 'styled-components'

export const InterestPillWrapper = styled.span`
  margin-left: 12px;
`

export const CustomBadge = styled.div`
  border-radius: 999px;
  display: inline-flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.BRIGHT_GREEN};
  color: ${({ theme }) => theme.colors.DARK_BLUE};
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0px 6px;
  min-width: 8px;
`
