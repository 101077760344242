import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'

import { businessContactTranslations } from './BusinessContact.i18n'

export const useBusinessContactValidate = () => {
  const t = useTranslations(businessContactTranslations)

  return useCallback(
    (value: string): string | undefined => {
      if (!value) {
        return t('errorRequired')
      }

      return undefined
    },
    [t]
  )
}
