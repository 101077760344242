import { useCallback, useEffect } from 'react'
import {
  rvCurrentUserData,
  useCustomerQuery,
} from '@npco/mp-utils-logged-in-user'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useEntityQuery } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { setSessionStorageItem } from '@npco/utils-session-storage'
import { useOnboardingEntityStatus } from 'features/OnboardingApp/shared/useOnboardingEntityStatus'

import { sendToDataLayer } from 'utils/gtmHelper'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { useSetupMultiEntity } from 'services/MultiEntityProvider'

import {
  useTokenUserMetadata,
  useZellerAuthenticationContext,
} from './ZellerAuthenticationContext'

export const useFetchSetupData = () => {
  const flags = useFeatureFlags()

  const tokenMetadata = useTokenUserMetadata()

  const tokenCustomerUuid = tokenMetadata?.customerUuid
  const tokenEntityUuid = tokenMetadata?.entityUuid

  const { fetchTokenClaims } = useZellerAuthenticationContext()
  const { logAnalyticsGroup } = useAnalyticsLogger()
  const { identifyAnalyticsUser } = useAnalyticsLogger()
  const { navigateUserAccordingToOnboardingStatus } =
    useOnboardingEntityStatus()

  const {
    getCustomerData,
    loading: isFetchCustomerLoading,
    called: isFetchCustomerCalled,
    error: fetchCustomerError,
    customer,
  } = useCustomerQuery()
  const {
    getEntityData,
    loading: isFetchEntityLoading,
    called: isFetchEntityCalled,
    error: fetchEntityError,
    entity,
  } = useEntityQuery()

  const { setupMultiEntity, isSettingUpActiveEntity } = useSetupMultiEntity({
    getCustomerData,
    getEntityData,
  })

  useEffect(() => {
    if (!entity || !customer) {
      return
    }

    const { id, category, categoryGroup, type } = entity
    const entityTraits = {
      entityId: id,
      entityCategory: category,
      entityCategoryGroup: categoryGroup,
      entityType: type,
    }

    identifyAnalyticsUser(customer.id, {
      ...entityTraits,
      role: customer.role,
    })
    logAnalyticsGroup(id, entityTraits)
    sendToDataLayer({ event: 'Login' })
    navigateUserAccordingToOnboardingStatus(entity, customer)
    setSessionStorageItem('redirect', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, customer, identifyAnalyticsUser, logAnalyticsGroup])

  const getEntityAndCustomer = useCallback(async () => {
    const customerUuid = tokenCustomerUuid ?? rvCurrentUserData()?.id ?? ''
    const entityUuid = tokenEntityUuid ?? rvCurrentUserData()?.entityUuid ?? ''

    if (flags.MultiEntity) {
      setupMultiEntity(customerUuid)
      return
    }

    const entityResult = getEntityData()

    if (!entityUuid) {
      const entityResultId = (await entityResult).data?.getEntity.id

      if (!entityResultId) {
        ErrorLogger.report(
          '[Setup] - No entity id available',
          undefined,
          undefined,
          'error'
        )
      }

      getCustomerData({
        variables: {
          entityUuid: entityResultId ?? '',
          customerUuid,
        },
      })
      return
    }

    getCustomerData({ variables: { entityUuid, customerUuid } })
  }, [
    tokenCustomerUuid,
    tokenEntityUuid,
    getCustomerData,
    getEntityData,
    flags.MultiEntity,
    setupMultiEntity,
  ])

  const fetchSetupData = useCallback(async () => {
    const customerUuid = tokenCustomerUuid
    if (!customerUuid) {
      await fetchTokenClaims()
    }
    getEntityAndCustomer()
  }, [fetchTokenClaims, tokenCustomerUuid, getEntityAndCustomer])

  return {
    fetchSetupData,
    isFetchSetupDataCalled: isFetchEntityCalled && isFetchCustomerCalled,
    isFetchSetupDataLoading:
      isFetchEntityLoading || isFetchCustomerLoading || isSettingUpActiveEntity,
    fetchSetupDataError: fetchEntityError || fetchCustomerError,
  }
}
