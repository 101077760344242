import { useCallback, useMemo, useState } from 'react'
import { InvoiceDiscountConfig, InvoiceItemUnit } from '@npco/mp-gql-types'
import {
  SelectStateChangeProps,
  SelectStateInputChangeProps,
  TabItemProps,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import { useCombobox } from 'downshift'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_DEFAULT_UNIT_QUANTITY,
  INVOICE_ITEMS_FIELD,
  INVOICE_ITEMS_NAME_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'
import { Subject } from 'rxjs'

import { translate } from 'utils/translations'

import {
  InvoiceNameSelectedItem,
  InvoiceNameSelectedTab,
} from '../../../../../InvoiceFormAccordions.types'

export const translations = {
  recentlyUsed: translate(
    'page.invoice.formSections.items.itemsNameRecentlyUsed'
  ),
  savedItems: translate('page.invoice.formSections.items.itemsNameSavedItems'),
}

export interface UseInvoiceNameComboboxProps {
  index: number
  name$: Subject<string>
}

export const useInvoiceNameCombobox = ({
  index,
  name$,
}: UseInvoiceNameComboboxProps) => {
  const { setFieldValue, values } = useFormikContext<InvoiceFormFields>()

  const [selectedItem, setSelectedItem] =
    useState<InvoiceNameSelectedItem | null>(() => {
      const lineItem = values.items[index]

      const initialSelectedItem = lineItem?.catalogItemUuid
        ? {
            catalogItemUuid: lineItem.catalogItemUuid,
            description: lineItem.description,
            label: lineItem.name,
            price: lineItem.price,
            taxApplicable: lineItem.taxApplicable,
            value: lineItem.id || '',
          }
        : null

      return initialSelectedItem
    })

  const [selectedTab, setSelectedTab] = useState<InvoiceNameSelectedTab>(
    InvoiceNameSelectedTab.SavedItems
  )

  const isTaxInclusive = values.itemsTaxInclusive
  const item = values.items[index]

  const itemFieldPath = `${INVOICE_ITEMS_FIELD}.${index}`
  const nameFieldPath = `${itemFieldPath}.${INVOICE_ITEMS_NAME_FIELD}`

  const [field, meta] = useField<string>(nameFieldPath)

  const handleChange = useCallback(
    (changes: SelectStateChangeProps<InvoiceNameSelectedItem>) => {
      if (changes.selectedItem) {
        const { catalogItemUuid, description, label, price, taxApplicable } =
          changes.selectedItem

        setFieldValue(itemFieldPath, {
          ...item,
          catalogItemUuid,
          description,
          discount: {
            config: InvoiceDiscountConfig.PERCENTAGE,
            percentage: INVOICE_DEFAULT_PERCENTAGE,
            price: INVOICE_DEFAULT_PRICE,
          },
          hasCalculation: false,
          initialDescription: description,
          initialName: label,
          initialPrice: price,
          initialQuantity: INVOICE_DEFAULT_UNIT_QUANTITY,
          name: label,
          price,
          taxApplicable,
          quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
          unit: InvoiceItemUnit.QUANTITY,
        })

        setSelectedItem(changes.selectedItem)
      }
    },
    [item, itemFieldPath, setFieldValue]
  )

  const handleInputChange = useCallback(
    (changes: SelectStateInputChangeProps<InvoiceNameSelectedItem>) => {
      if (changes.type !== useCombobox.stateChangeTypes.InputChange) {
        return
      }

      name$.next(changes.inputValue)

      setFieldValue(itemFieldPath, { ...item, name: changes.inputValue })
    },
    [name$, item, itemFieldPath, setFieldValue]
  )

  const handleInputClear = useCallback(() => {
    setSelectedItem(null)

    const nextPrice = currency(0, { precision: 4 })

    setFieldValue(itemFieldPath, {
      ...item,
      catalogItemUuid: '',
      description: '',
      initialDescription: '',
      initialName: '',
      initialPrice: nextPrice,
      initialQuantity: INVOICE_DEFAULT_UNIT_QUANTITY,
      name: '',
      price: nextPrice,
      quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
    })
  }, [itemFieldPath, item, setFieldValue, setSelectedItem])

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: InvoiceNameSelectedTab.SavedItems,
        value: InvoiceNameSelectedTab.SavedItems,
        children: translations.savedItems,
        onClick: () => setSelectedTab(InvoiceNameSelectedTab.SavedItems),
      },
      {
        id: InvoiceNameSelectedTab.RecentlyUsed,
        value: InvoiceNameSelectedTab.RecentlyUsed,
        children: translations.recentlyUsed,
        onClick: () => setSelectedTab(InvoiceNameSelectedTab.RecentlyUsed),
      },
    ],
    [setSelectedTab]
  )

  return {
    error: meta.error,
    handleBlur: field.onBlur,
    handleChange,
    handleInputChange,
    handleInputClear: selectedItem ? handleInputClear : undefined,
    inputValue: field.value || '',
    isTaxInclusive,
    selectedItem,
    selectedTab,
    tabs,
  }
}
