import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDashboardFinanceWrapper = styled.div`
  width: 100%;
  min-width: 0;

  @media (min-width: ${BREAKPOINT.SM}px) {
    margin-bottom: 3.5rem;
    margin-right: 0.5rem;
  }

  @media (min-width: ${BREAKPOINT.MD}px) {
    margin-right: 1.5rem;
  }

  @media (min-width: ${BREAKPOINT.LG}px) {
    margin-right: 2.5rem;
  }
`
