import { useCallback } from 'react'

import { useProductTour } from '../../../hooks/useProductTour/useProductTour'
import { useGoToSetupFlowStage } from '../../hooks/useSetupFlowStage/useGoToSetupFlowStage'
import { SetupFlowStage } from '../../hooks/useSetupFlowStage/useGoToSetupFlowStage.types'

export const useSetupFlowModal = () => {
  const { goToSetupFlowStage } = useGoToSetupFlowStage()
  const { turnOffWelcomeModal } = useProductTour()
  const goToStage = useCallback(
    (stage: SetupFlowStage | undefined) => {
      goToSetupFlowStage(stage ? { stage } : undefined)
    },
    [goToSetupFlowStage]
  )

  const completeFlow = useCallback(() => {
    turnOffWelcomeModal()
  }, [turnOffWelcomeModal])

  return {
    goToStage,
    completeFlow,
  }
}
