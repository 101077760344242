import { MouseEvent } from 'react'
import { storyblokEditable } from '@storyblok/react'
import { StoryblokBannerProps } from 'features/StoryblokContent/Storyblok.types'

import { useQueryParams } from 'hooks/useQueryParams'
import { BannerCMSComponent } from 'pages/Dashboard/DashboardLayout/OverviewBanner/BannerCMSComponent/BannerCMSComponent'

import { useStoryblok } from './hooks/useStoryblok'

export interface BannerContentProps {
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  hideOverviewBanner: () => void
  slug: string
}

export const BannerContent = ({ slug, ...props }: BannerContentProps) => {
  const params = useQueryParams()
  const contentKey = params.get('banner-name') ?? slug
  const slugPrefix = 'dashboard-merchant-portal/dashboard-topline-banners/'
  const slugComplete = slugPrefix + contentKey

  const story = useStoryblok(slugComplete, {
    version: params.get('banner-name') ? 'draft' : 'published', // draft for preview, published for prod
    resolve_links: 'url',
    cv: 1,
  })

  if (!story?.content) {
    return null
  }

  const storyContent = story.content

  return (
    <main {...storyblokEditable(storyContent)}>
      <BannerCMSComponent
        bannerContent={storyContent as StoryblokBannerProps}
        slug={slug}
        {...props}
      />
    </main>
  )
}
