import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const Wrapper = styled.div`
  margin: 96px 0 0;
  width: 100%;

  @media (min-width: ${BREAKPOINT.SM}px) {
    width: calc(50% - 20px);
  }
`
