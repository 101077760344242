import { Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'

import { ROOT } from 'const/routes'
import { SettingsCreateUserType, SettingsUser } from 'pages'
import { NotFound } from 'pages/NotFound'
import { UsersDetailFetcher } from 'pages/Settings/Users/UsersDetailsFetcher/UsersDetailsFetcher'
import { UsersLayout } from 'pages/Settings/Users/UsersLayout/UsersLayout'
import { UsersList } from 'pages/Settings/Users/UsersList/UsersList'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

export const SettingsUsersRoutes = () => {
  return (
    <UsersLayout>
      <UsersDetailFetcher>
        <Routes>
          <Route
            element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
          >
            <Route index element={<UsersList />} />

            <Route
              path={ROOT.ORGS.ORG().SETTINGS.USERS.USER_EDIT.relative}
              element={<SettingsUser isEditForm isUsers />}
            />

            <Route
              path={ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_TYPE.relative}
              element={<SettingsCreateUserType isUsers />}
            />

            <Route
              path={ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_DETAILS.relative}
              element={<SettingsUser isEditForm={false} isUsers />}
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </UsersDetailFetcher>
    </UsersLayout>
  )
}
