import { Contacts } from '@npco/zeller-design-system'
import { INVOICE_PAYER_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { InvoiceContactComboboxHeader } from '../../InvoiceContactComboboxHeader/InvoiceContactComboboxHeader'
import { InvoiceCreateContact } from '../../InvoiceCreateContact/InvoiceCreateContact'
import { usePayerContactCombobox } from '../hooks/usePayerContactCombobox'

export const translations = {
  payerLabel: translate('page.invoice.formSections.customer.payerLabel'),
}

export const InvoicePayerContactCombobox = () => {
  const isMobile = useIsMobileResolution()
  const {
    contactTypeToCreate,
    error,
    handleChange,
    handleClose,
    handleCreateContact,
    handleInputChange,
    handleInputClear,
    handleOnblur,
    hasMore,
    inputValue,
    isDisabled,
    isLoading,
    loadMore,
    payerItems,
    selectedItem,
    setContactTypeToCreate,
  } = usePayerContactCombobox()

  return (
    <>
      <Contacts.ContactCombobox
        hasMore={hasMore}
        inputLabel={translations.payerLabel}
        inputValue={inputValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        items={payerItems}
        loadMore={loadMore}
        name={INVOICE_PAYER_FIELD}
        onBlur={handleOnblur}
        onChange={handleChange}
        onClose={handleClose}
        onInputChange={handleInputChange}
        onInputClear={handleInputClear}
        popperWidth={!isMobile ? '400px' : undefined}
        renderHeader={({ closeMenu }) => (
          <InvoiceContactComboboxHeader
            closeMenu={closeMenu}
            onSelection={setContactTypeToCreate}
          />
        )}
        selectedItem={selectedItem}
        error={error}
      />
      {contactTypeToCreate && (
        <InvoiceCreateContact
          contactTypeToCreate={contactTypeToCreate}
          initialName={inputValue}
          onClose={() => setContactTypeToCreate(null)}
          onCreate={handleCreateContact}
        />
      )}
    </>
  )
}
