import { AnnouncementModal, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CardInHandIllustration } from 'assets/svg/card-in-hand.svg'
import {
  CompleteCardReplacementSetupState,
  CompleteCardSetupState,
  CompleteCardStage,
} from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'
import { useModalHasOpenTransition } from 'pages/GlobalModals/hooks/useModalHasOpenTransition'

import { useCompleteCardModal } from '../hooks/useCompleteCardModal'
import { useCompleteCardState } from '../hooks/useCompleteCardState'
import {
  getCompleteCardSetupModalDescription,
  getCompleteCardSetupModalHeading,
} from './CompleteCardSetupModal.utils'

export const CompleteCardSetupModal = () => {
  const isMobile = useIsMobileResolution()
  const { state } = useCompleteCardState<
    CompleteCardSetupState | CompleteCardReplacementSetupState
  >()

  const { hasOpenTransition } = useModalHasOpenTransition()
  const { viewCard, closeCompleteCardModal } = useCompleteCardModal()

  if (state === null) {
    return null
  }

  return (
    <AnnouncementModal
      isOpen={
        state.stage === CompleteCardStage.CompleteCardSetupStage ||
        state.stage === CompleteCardStage.CompleteCardSetupReplacementStage
      }
      shouldContentFadeIn={hasOpenTransition}
      heading={getCompleteCardSetupModalHeading(state)}
      description={getCompleteCardSetupModalDescription(state)}
      Illustration={
        <SvgIcon
          width={isMobile ? '140' : '180'}
          height={isMobile ? '140' : '180'}
        >
          <CardInHandIllustration />
        </SvgIcon>
      }
      primaryButtonLabel={translate('page.completeCardModal.doneButtonLabel')}
      secondaryButtonLabel={translate('page.completeCardModal.viewCardLabel')}
      onCancel={closeCompleteCardModal}
      onClickSecondary={() => viewCard(state.cardId)}
      onClickPrimary={closeCompleteCardModal}
    />
  )
}
