import { BUTTON_SIZE } from '@npco/zeller-design-system'

import { PlaceholderSize } from './Placeholders.types'
import {
  StyledExtraLargeDescription,
  StyledExtraLargeTitle,
  StyledLargeTitle,
  StyledMediumDescription,
  StyledMediumTitle,
} from './SharedElements.styled'

export const getTitleBasedOnVariant = (variant: PlaceholderSize) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
      return StyledExtraLargeTitle
    case PlaceholderSize.Large:
      return StyledLargeTitle
    case PlaceholderSize.Medium:
    case PlaceholderSize.Small:
      return StyledMediumTitle
    default:
      return StyledLargeTitle
  }
}

export const getDescriptionBasedOnVariant = (variant: PlaceholderSize) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
      return StyledExtraLargeDescription
    case PlaceholderSize.Medium:
    case PlaceholderSize.Small:
      return StyledMediumDescription
    default:
      return StyledExtraLargeDescription
  }
}

export const getButtonSizeBasedOnVariant = (variant: PlaceholderSize) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
      return BUTTON_SIZE.LARGE
    case PlaceholderSize.Medium:
      return BUTTON_SIZE.MEDIUM
    case PlaceholderSize.Small:
      return BUTTON_SIZE.SMALL
    default:
      return BUTTON_SIZE.LARGE
  }
}

export const getIconSizeByVariant = (variant: PlaceholderSize) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
      return 80
    case PlaceholderSize.Medium:
    case PlaceholderSize.Small:
      return 60
    default:
      return 80
  }
}
