import { translate } from 'utils/translations'
import { StyledListKey } from 'components/DetailsListItem/DetailsListItem.styled'

import {
  StyledErrorText,
  StyledOverrideListElement,
} from './CategoryDisplay.styled'

interface CategoryItemErrorProps {
  label: React.ReactNode
}

export const CategoryItemError = ({ label }: CategoryItemErrorProps) => {
  return (
    <StyledOverrideListElement>
      <StyledListKey>{label}</StyledListKey>
      <StyledErrorText>
        {translate('errorMessages.couldNotLoadData')}
      </StyledErrorText>
    </StyledOverrideListElement>
  )
}
