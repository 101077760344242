import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetCustomerQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  customerUuid: Types.Scalars['ID']['input']
}>

export type GetCustomerQueryResponse = {
  __typename?: 'Query'
  getCustomer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
    email: string | null
    emailVerified: boolean | null
    nickname: string | null
    firstname: string | null
    middlename: string | null
    lastname: string | null
    phone: string | null
    phoneVerified: boolean | null
    role: Types.CustomerRole | null
    registeringIndividual: boolean | null
    director: boolean | null
    secretary: boolean | null
    ceo: boolean | null
    beneficialOwner: boolean | null
    beneficialOwnerAlt: boolean | null
    beneficiary: boolean | null
    partner: boolean | null
    trustee: boolean | null
    settlor: boolean | null
    generalContact: boolean | null
    financialContact: boolean | null
    type: Types.EntityType | null
    companyTrustName: string | null
    abn: string | null
    isInvitationPending: boolean | null
    kycStatus: Types.KycStatus | null
    address: {
      __typename?: 'CustomerAddress'
      country: string | null
      street: string | null
      suburb: string | null
      state: string | null
      postcode: string | null
    } | null
    productTourStatus: {
      __typename?: 'ProductTourStatus'
      showAdminMerchantPortalWelcome: boolean | null
      showCorporateCardsWalkthrough: boolean | null
      showCorporateCardsSettingsWalkthrough: boolean | null
      showInvoiceInstructions: boolean | null
      showInvoiceSendViaInfo: boolean | null
      showInvoicesCustomisationWelcome: boolean | null
      showInvoicesScheduleSendWelcome: boolean | null
      showInvoicesSendBySmsWelcome: boolean | null
      showInvoicesWelcome: boolean | null
      showInvoicingCustomisationSettingsWelcome: boolean | null
      showItemInstructions: boolean | null
      showNotificationsWelcome: boolean | null
      showTapToPayInstructions: boolean | null
      showOnboardingShop: boolean | null
      showCatalogueItemsWelcome: boolean | null
    } | null
    idvAttempts: {
      __typename?: 'IdvAttempts'
      driversLicence: number | null
      passport: number | null
      medicareCard: number | null
    } | null
    permissions: {
      __typename?: 'CustomerPermissions'
      allowZellerInvoices: boolean
      allowXeroPaymentServices: boolean
      allowDiscountManagement: boolean
      allowItemManagement: boolean
    } | null
  }
}

export const GetCustomer = gql`
  query GetCustomer($entityUuid: ID!, $customerUuid: ID!) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      email
      emailVerified
      nickname
      firstname
      middlename
      lastname
      phone
      phoneVerified
      role
      address {
        country
        street
        suburb
        state
        postcode
      }
      registeringIndividual
      director
      secretary
      ceo
      beneficialOwner
      beneficialOwnerAlt
      beneficiary
      partner
      trustee
      settlor
      generalContact
      financialContact
      type
      companyTrustName
      abn
      productTourStatus {
        showAdminMerchantPortalWelcome
        showCorporateCardsWalkthrough
        showCorporateCardsSettingsWalkthrough
        showInvoiceInstructions
        showInvoiceSendViaInfo
        showInvoicesCustomisationWelcome
        showInvoicesScheduleSendWelcome
        showInvoicesSendBySmsWelcome
        showInvoicesWelcome
        showInvoicingCustomisationSettingsWelcome
        showItemInstructions
        showNotificationsWelcome
        showTapToPayInstructions
        showOnboardingShop
        showCatalogueItemsWelcome
      }
      isInvitationPending
      kycStatus
      idvAttempts {
        driversLicence
        passport
        medicareCard
      }
      permissions {
        allowZellerInvoices
        allowXeroPaymentServices
        allowDiscountManagement
        allowItemManagement
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetCustomerQueryResponse,
  GetCustomerQueryVariables
>

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerQueryResponse,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCustomerQueryResponse, GetCustomerQueryVariables>(
    GetCustomer,
    options
  )
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerQueryResponse,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCustomerQueryResponse,
    GetCustomerQueryVariables
  >(GetCustomer, options)
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQueryResponse,
  GetCustomerQueryVariables
>
