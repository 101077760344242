import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Divider } from '@npco/zeller-design-system'

import { formatAccountNumber } from 'utils/common'

import { component, page } from '../../../../../../translations'
import { INTERNAL_TRANSFER_MODAL_HEIGHT } from '../../../../Transfer.utils'
import { AmountRow } from '../../../../TransferModal/components/AmountRow/AmountRow'
import { AvatarWithTextRow } from '../../../../TransferModal/components/AvatarWithTextRow/AvatarWithTextRow'
import { DefaultRow } from '../../../../TransferModal/components/DefaultRow/DefaultRow'
import { SectionHeading } from '../../../../TransferModal/components/SectionHeading/SectionHeading'
import { TransferModal } from '../../../../TransferModal/TransferModal'
import { useInternalTransferModal } from '../../../internal-transfer-routing'
import { TransactionDetails } from '../../../internal-transfer-routing/InternalTransfer.types'
import { useSubmitInternalTransfer } from '../../hooks/useSubmitInternalTransfer/useSubmitInternalTransfer'
import { summaryStageTranslations } from './SummaryStage.i18n'

type SummaryStageProps = {
  transferDetails: TransactionDetails
}

export const SummaryStage = ({ transferDetails }: SummaryStageProps) => {
  const { from, to, amount, reference } = transferDetails
  const t = useTranslations(summaryStageTranslations)
  const { closeInternalTransferModal } = useInternalTransferModal()
  const { submitInternalTransfer, submitTransferLoading } =
    useSubmitInternalTransfer()

  const handleEdit = useCallback(() => {
    closeInternalTransferModal()
  }, [closeInternalTransferModal])

  const handlePayNow = useCallback(() => {
    submitInternalTransfer(transferDetails)
  }, [submitInternalTransfer, transferDetails])

  return (
    <TransferModal.DecisionModal
      isOpen
      secondaryButtonLabel={t('secondaryButtonLabel')}
      primaryButtonLabel={t('primaryButtonLabel')}
      onCancel={handleEdit}
      onClickSecondary={handleEdit}
      onClickPrimary={handlePayNow}
      isLoadingPrimary={submitTransferLoading}
      isPrimaryButtonDisabled={submitTransferLoading}
      isSecondaryButtonDisabled={submitTransferLoading}
      className="animated-content"
      overlayClassName="animated-overlay"
      bodyClassName="transfer-modal-body"
      footerClassName="transfer-modal-footer"
      contentStyle={{
        height: INTERNAL_TRANSFER_MODAL_HEIGHT,
      }}
    >
      <TransferModal.ModalWrapper>
        <TransferModal.SectionWrapper
          gap="16px"
          aria-label="Sender account details"
        >
          <SectionHeading>{page.transfer.from}</SectionHeading>
          <AvatarWithTextRow
            avatar={{ icon: from.icon, defaultLetter: from.name }}
            text={from.name}
            mb="16px"
          />
          <DefaultRow
            label={t('accountNumberLabel')}
            value={formatAccountNumber(from.accountNumber ?? '')}
            mb="8px"
            aria-label="Sender Account Number"
          />
        </TransferModal.SectionWrapper>
        <Divider margin="18px 0" />
        <TransferModal.SectionWrapper
          gap="16px"
          aria-label="Recipient account details"
        >
          <SectionHeading>{page.transfer.to}</SectionHeading>
          <AvatarWithTextRow
            avatar={{ icon: to.icon, defaultLetter: to.name }}
            text={to.name}
            mb="18px"
          />
          <DefaultRow
            label={t('accountNumberLabel')}
            value={formatAccountNumber(to.accountNumber ?? '')}
            aria-label="Recipient Account Number"
          />
        </TransferModal.SectionWrapper>
        <Divider margin="18px 0" />
        <TransferModal.SectionWrapper aria-label="Transfer details">
          <AmountRow
            label={component.transaction.amount}
            amountInCents={+amount * 100}
          />
          <DefaultRow
            label={page.transactionDetails.reference}
            value={reference}
            aria-label="Reference"
          />
        </TransferModal.SectionWrapper>
      </TransferModal.ModalWrapper>
    </TransferModal.DecisionModal>
  )
}
