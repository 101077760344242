import {
  Box,
  Flex,
  SkeletonAvatar,
  SkeletonBasic,
} from '@npco/zeller-design-system'

export const DebitCardAccountAvatarSkeleton = () => {
  return (
    <Flex
      alignItems="center"
      width={{ _: 1, SM: 1 / 2 }}
      p="6px 16px 8px 9px"
      border="3px solid transparent"
    >
      <Box width="24px" mr="16px">
        <SkeletonAvatar height={24} width={24} />
      </Box>
      <Box width={1} maxWidth={139}>
        <SkeletonBasic height={12} isRounded />
      </Box>
    </Flex>
  )
}
