import styled from 'styled-components'

export const AvatarWithTextRowWrapper = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 8px 0 0 0;
`

export const Text = styled.h3`
  ${({ theme }) => theme.typography['heading-xl']}
  color: ${({ theme }) => theme.colors.GREY_800};
  margin: 0;
  text-align: center;
  width: 100%;
  text-align: end;
  align-self: center;
  word-break: break-word;
`
