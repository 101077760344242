import { Box, H1 } from '@npco/zeller-design-system'

import { ReactComponent as Rejection } from 'assets/svg/rejection.svg'
import { useLogout } from 'hooks/useLogout'
import { page, shared } from 'translations'

import { OnboardingLayout } from '../../../features/OnboardingApp/OnboardingLayout'
import { OnboardingPageLayout } from '../../../features/OnboardingApp/OnboardingPageLayout'

interface Props {
  title: string
}

export const LoginErrorPage = ({ title }: Props) => {
  const { logout } = useLogout()

  return (
    <OnboardingLayout>
      <OnboardingPageLayout
        description={
          <Box mt={['6.25rem', 0]}>
            <Rejection />
            <H1>{title}</H1>
            {page.errorPages.contact}
          </Box>
        }
        isForm={false}
        nextButtonLabel={shared.signOut}
        onConfirmClick={logout}
        isBackButtonHidden
      />
    </OnboardingLayout>
  )
}
