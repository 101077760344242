import { ButtonLink } from '@npco/zeller-design-system'

import { useShowMore } from '../context/useShowMore'

interface ShowMoreToggleProps {
  showText: React.ReactNode
  hideText: React.ReactNode
  dataTestId?: string
}

export const ShowMoreToggle = ({
  showText,
  hideText,
  dataTestId,
}: ShowMoreToggleProps) => {
  const { isShowingMore, setIsShowingMore } = useShowMore()

  return (
    <ButtonLink
      onClick={() => setIsShowingMore((prev) => !prev)}
      dataTestId={dataTestId}
    >
      {isShowingMore ? hideText : showText}
    </ButtonLink>
  )
}
