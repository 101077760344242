import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  BankAccountDetailsInput,
  PaymentInstrumentStatus,
} from '@npco/mp-gql-types'
import { Box, Flex, NEW_ITEM_VALUE } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { Formik } from 'formik'

import { BsbPaymentInstrument } from 'hooks/paymentInstruments/useBsbPaymentInstruments'
import { translate } from 'utils/translations'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'

import {
  BankAccountFormFields,
  InputComboBoxBankDetailsItem,
} from './BankAccounts.types'
import { BankAccountsInputSelectCombobox } from './BankAccountsInputSelectCombobox'
import { BankAccountsModalForm } from './BankAccountsModal.form'

type PaymentInstrument = NonNullable<
  ContactCoreFieldsFragment['paymentInstruments']
>[0]

const INITIAL_VALUES: BankAccountFormFields = {
  accountName: '',
  bsb: '',
  accountNumber: '',
  status: PaymentInstrumentStatus.ACTIVE,
}

interface BankAccountsModalProps {
  bankAccountToUpdate?: PaymentInstrument | null
  contactId: string
  contactName: string
  isExistingPaymentInstrument: boolean
  isLoading: boolean
  onCancel: () => void
  onSave: (
    bankAccountDetails: BankAccountDetailsInput,
    status: PaymentInstrumentStatus,
    selectedPaymentInstrument: BsbPaymentInstrument | null
  ) => void
  onUnlinkSuccess: () => void
  setIsExistingPaymentInstrument: Dispatch<SetStateAction<boolean>>
  initialSelectedItem?: InputComboBoxBankDetailsItem | null
  initialAccountValues?: BankAccountFormFields
}

export const BankAccountsModal = ({
  bankAccountToUpdate,
  contactId,
  contactName,
  isExistingPaymentInstrument,
  isLoading,
  onCancel,
  onSave,
  onUnlinkSuccess,
  setIsExistingPaymentInstrument,
  initialSelectedItem = null,
  initialAccountValues = INITIAL_VALUES,
}: BankAccountsModalProps) => {
  const [selectedItem, setSelectedItem] =
    useState<InputComboBoxBankDetailsItem | null>(initialSelectedItem)

  const [selectedPaymentIntrument, setSelectedPaymentInstrument] =
    useState<BsbPaymentInstrument | null>(null)

  const [validationError, setValidationError] = useState<string>()

  const showInputSelectCombobox =
    !bankAccountToUpdate &&
    (!selectedItem || selectedItem.value !== NEW_ITEM_VALUE)

  const showModalForm = !!bankAccountToUpdate || !!selectedItem

  const handleFormSubmit = ({
    accountName,
    accountNumber,
    bsb,
    status,
  }: BankAccountFormFields) => {
    const hasAllAccountDetails = accountName && accountNumber && bsb

    if (!hasAllAccountDetails) {
      // NOTE: if we already have a validation error persist
      if (validationError) {
        return
      }

      setValidationError(
        translate(
          'page.contact.sections.bankAccounts.accountNameRequiredValidation'
        )
      )

      return
    }

    setIsExistingPaymentInstrument(false)

    onSave(
      { account: accountNumber, bsb, name: accountName },
      status,
      selectedPaymentIntrument
    )
  }

  useEffect(() => {
    if (!bankAccountToUpdate?.bankAccountDetails) {
      return
    }

    const { id, status, bankAccountDetails } = bankAccountToUpdate

    setSelectedPaymentInstrument({
      contact: null,
      contactUuid: '',
      bankAccountDetails,
      details: bankAccountDetails,
      id,
      status,
    })
  }, [bankAccountToUpdate])

  const initialValues: BankAccountFormFields = !bankAccountToUpdate
    ? initialAccountValues
    : {
        accountName: bankAccountToUpdate.bankAccountDetails?.name || '',
        bsb: bankAccountToUpdate.bankAccountDetails?.bsb || '',
        accountNumber: bankAccountToUpdate.bankAccountDetails?.account || '',
        status: bankAccountToUpdate.status,
      }

  const modalTitle = translate(
    `page.contact.sections.bankAccounts.${
      !bankAccountToUpdate ? 'addModalTitle' : 'editModalTitle'
    }`
  )

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
      {({ handleSubmit, resetForm }) => {
        return (
          <ModalFormScrollable
            isLoading={isLoading}
            isOpen
            onCancel={onCancel}
            onSave={handleSubmit}
            title={modalTitle}
            confirmLabel={translate('shared.save')}
            cancelLabel={translate('shared.cancel')}
          >
            <Flex flexDirection="column">
              {showInputSelectCombobox && (
                <Box mb={showModalForm ? '24px' : 0}>
                  <BankAccountsInputSelectCombobox
                    contactId={contactId}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    setSelectedPaymentInstrument={setSelectedPaymentInstrument}
                    setValidationError={setValidationError}
                    validationError={validationError}
                  />
                </Box>
              )}
              {showModalForm && (
                <BankAccountsModalForm
                  contactId={contactId}
                  contactName={contactName}
                  isAddingNewItem={selectedItem?.value === NEW_ITEM_VALUE}
                  isOnUpdateItem={!!bankAccountToUpdate}
                  isExistingPaymentInstrument={isExistingPaymentInstrument}
                  onUnlinkSuccess={onUnlinkSuccess}
                  resetForm={resetForm}
                  selectedPaymentInstrument={selectedPaymentIntrument}
                />
              )}
            </Flex>
          </ModalFormScrollable>
        )
      }}
    </Formik>
  )
}
