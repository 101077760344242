import { z } from 'zod'

export enum EditCardNameStage {
  EditCardName = 'EditCardName',
}

const EditCardNameStateSchema = z.object({
  stage: z.literal(EditCardNameStage.EditCardName),
  cardId: z.string(),
  name: z.string(),
})

export type EditCardNameState = z.infer<typeof EditCardNameStateSchema>

export const EditCardNameLocationStateSchema = z.object({
  EditCardNameModal: EditCardNameStateSchema.optional(),
})
