import { useEffect } from 'react'

import { useGetSites } from 'hooks/useGetSites'

export const DASHBOARD_SITES_LIMIT = 50

export const useGetDashboardSites = () => {
  const { sites, isLoading, error, onLoadMore, hasMore, refetch } = useGetSites(
    {
      pageSize: DASHBOARD_SITES_LIMIT,
      fetchPolicy: 'cache-first',
    }
  )

  useEffect(() => {
    if (hasMore && !isLoading) {
      onLoadMore()
    }
  }, [hasMore, isLoading, onLoadMore])

  return { sites, isLoading, error, onLoadMore, hasMore, refetch }
}
