import { useTranslations } from '@npco/utils-translations'
import { Message, SomethingWentWrongDominoes } from '@npco/zeller-design-system'

import { SUPPORT_PHONE } from 'const/externalLinks'
import { translations } from 'translations/shared.i18n'

import { cashflowContactErrorPageTranslations } from './CashflowContactErrorPage.i18n'

type CashflowContactErrorPageProps = { retry: () => void }

export const CashflowContactErrorPage = ({
  retry,
}: CashflowContactErrorPageProps) => {
  const t = useTranslations(cashflowContactErrorPageTranslations)
  const tShared = useTranslations(translations)

  return (
    <Message
      image={<SomethingWentWrongDominoes size="large" />}
      title={t('title')}
      description={t('description', { supportPhone: SUPPORT_PHONE })}
      primaryButton={{ label: tShared('tryAgain'), onClick: () => retry() }}
    />
  )
}
