import { gql } from '@apollo/client'

export const TotalAccountBalanceQuery = gql`
  query TotalAccountBalanceQuery(
    $range: DateRange!
    $timeZone: String!
    $entityUuid: ID!
  ) {
    getDebitCardAccountBalances(
      range: $range
      timeZone: $timeZone
      entityUuid: $entityUuid
    ) {
      balance {
        value
      }
    }
  }
`
