import { useFormikContext } from 'formik'

import { FooterButtons } from './FooterButtons'

interface FormButtonsProps {
  onCancel?: () => void
  shouldShowBackButton?: boolean
  nextButtonDisabled?: boolean
  nextButtonLabel?: React.ReactNode
  backButtonLabel?: React.ReactNode
  isLoading?: boolean
  disableOnInvalid?: boolean
}

export const FormButtons = ({
  onCancel,
  shouldShowBackButton = true,
  nextButtonLabel,
  nextButtonDisabled,
  backButtonLabel,
  isLoading,
  disableOnInvalid,
}: FormButtonsProps) => {
  const { isValid } = useFormikContext()
  const isConfirmDisabled = disableOnInvalid && !isValid

  return (
    <FooterButtons
      shouldShowBackButton={shouldShowBackButton}
      onCancel={onCancel}
      nextButtonLabel={nextButtonLabel}
      backButtonLabel={backButtonLabel}
      isLoading={isLoading}
      isConfirmDisabled={isConfirmDisabled || nextButtonDisabled}
    />
  )
}
