import { useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Animation,
  Box,
  ButtonLink,
  Flex,
  slideDownAnimationVariants,
  ToggleForm,
} from '@npco/zeller-design-system'
import {
  INVOICE_EMAIL_BCC_FIELD,
  INVOICE_EMAIL_CC_FIELD,
  INVOICE_EMAIL_ENABLED_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { Field, useFormikContext } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { InvoiceFormFields } from '../../../../../../../Invoice.types'
import {
  DeliveryTitle,
  DeliveryTitleWrapper,
  SmsDescriptionWrapper,
} from '../../../InvoiceDeliveryAccordion.styled'
import { InvoiceSelectCustomerInfobox } from '../../InvoiceSelectCustomerInfobox/InvoiceSelectCustomerInfobox'
import { InvoiceEmailResetButton } from '../InvoiceEmailResetButton/InvoiceEmailResetButton'
import { InvoiceEmailInputField } from './InvoiceEmailInputField/InvoiceEmailInputField'
import { translations } from './InvoiceEmailRecipients.i18n'
import { InvoicePayerEmailAddress } from './InvoicePayerEmailAddress/InvoicePayerEmailAddress'

export const getError = (valueError?: string | string[]) => {
  if (!valueError) {
    return ''
  }

  return typeof valueError === 'string'
    ? valueError
    : Array.from(new Set(valueError)).join(', ')
}

interface InvoiceEmailRecipientsProps {
  onEditCustomerClick: () => void
}

export const InvoiceEmailRecipients = ({
  onEditCustomerClick,
}: InvoiceEmailRecipientsProps) => {
  const isMobileResolution = useIsMobileResolution()
  const t = useTranslations(translations)
  const {
    errors: { delivery },
    values: {
      customer: { payerContact },
      delivery: {
        email: { isEnabled, bcc, cc, recipient },
      },
    },
  } = useFormikContext<InvoiceFormFields>()

  const [showCcInputField, setShowCcInputField] = useState(Boolean(cc.length))
  const [showBccInputField, setShowBccInputField] = useState(
    Boolean(bcc.length)
  )

  const shouldShowCcInputButton = !!recipient && !showCcInputField
  const shouldShowBccInputButton = !!recipient && !showBccInputField
  const isCcOrBccVisible = shouldShowCcInputButton || shouldShowBccInputButton
  const isPayerSelected = !!payerContact?.contactUuid

  return (
    <>
      <Flex gridGap="24px" flexDirection="column">
        <Flex
          alignItems={{ _: 'flex-start', XS: 'center' }}
          flexDirection={{ _: 'column', XS: 'row' }}
          gridGap="16px"
        >
          <DeliveryTitleWrapper>
            <Field
              component={ToggleForm}
              name={INVOICE_EMAIL_ENABLED_FIELD}
              value={isEnabled}
            />
            <DeliveryTitle>{t('title')}</DeliveryTitle>
          </DeliveryTitleWrapper>
          {isEnabled ? (
            <InvoiceSelectCustomerInfobox
              onEditCustomerClick={onEditCustomerClick}
            />
          ) : (
            <SmsDescriptionWrapper $isSendBySmsEnabled>
              {t('details')}
            </SmsDescriptionWrapper>
          )}
        </Flex>

        <Animation
          disableInitialAnimation
          shouldShow={isEnabled && isPayerSelected}
          variants={slideDownAnimationVariants}
        >
          <Box
            display="grid"
            gridGap={{
              _: isCcOrBccVisible ? '24px' : '0px',
              XS: '24px',
            }}
            gridTemplateColumns={{
              _: 'none',
              XS: 'minmax(376px, 1fr) repeat(2, 1fr)',
            }}
          >
            <InvoicePayerEmailAddress />
            <Flex
              alignItems="flex-start"
              gridGap="12px"
              mt={{ _: 0, XS: '12px' }}
            >
              {shouldShowCcInputButton && (
                <ButtonLink onClick={() => setShowCcInputField(true)}>
                  {t('cc')}
                </ButtonLink>
              )}

              {shouldShowBccInputButton && (
                <ButtonLink onClick={() => setShowBccInputField(true)}>
                  {t('bcc')}
                </ButtonLink>
              )}
            </Flex>
            {!isMobileResolution && isPayerSelected && (
              <Flex justifyContent="flex-end" mt="12px">
                <InvoiceEmailResetButton />
              </Flex>
            )}
          </Box>
        </Animation>
      </Flex>
      {isEnabled && (
        <>
          {showCcInputField && (
            <InvoiceEmailInputField
              error={getError(delivery?.email?.cc)}
              name={INVOICE_EMAIL_CC_FIELD}
              label={t('cc')}
            />
          )}
          {showBccInputField && (
            <InvoiceEmailInputField
              error={getError(delivery?.email?.bcc)}
              name={INVOICE_EMAIL_BCC_FIELD}
              label={t('bcc')}
            />
          )}
        </>
      )}
    </>
  )
}
