import { useEffect } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import {
  Box,
  COLOR,
  Flex,
  InfoBox,
  INFOBOX_VARIANT,
  InputAdaptiveField,
} from '@npco/zeller-design-system'
import { SelectCompactField } from 'design-system/Components/Select'
import { FormikHelpers } from 'formik'

import { BsbPaymentInstrument } from 'hooks/paymentInstruments/useBsbPaymentInstruments'
import { translate } from 'utils/translations'
import { GetPaymentInstruments_getPaymentInstruments_contact as PaymentInstrumentContact } from 'types/gql-types/GetPaymentInstruments'
import {
  validateAccountName,
  validateBankAccountNumber,
  validateBSBNumber,
} from 'forms/formViews/BankAccountFields/BankAccountFields.utils'

import { BankAccountFormFields } from './BankAccounts.types'
import { statusItemOptions } from './BankAccounts.utils'
import { UnlinkBankAccount } from './UnlinkBankAccount/UnlinkBankAccount'

const getContactNameFromPaymentInstrumentContact = ({
  businessName,
  contactType,
  firstName,
  lastName,
}: PaymentInstrumentContact) => {
  if (contactType === ContactType.BUSINESS) {
    return businessName ?? ''
  }
  return `${firstName} ${lastName}`
}
interface BankAccountsModalFormProps {
  contactId: string
  contactName: string
  isAddingNewItem: boolean
  isExistingPaymentInstrument: boolean
  isOnUpdateItem: boolean
  onUnlinkSuccess: () => void
  resetForm: FormikHelpers<BankAccountFormFields>['resetForm']
  selectedPaymentInstrument: BsbPaymentInstrument | null
}

export const BankAccountsModalForm = ({
  contactId,
  contactName,
  isAddingNewItem,
  isExistingPaymentInstrument,
  isOnUpdateItem,
  onUnlinkSuccess,
  resetForm,
  selectedPaymentInstrument,
}: BankAccountsModalFormProps) => {
  useEffect(() => {
    return () => {
      // NOTE: reset form on unmount
      // this fixes validations getting executed on all fields when you open the modal again
      resetForm()
    }
  }, [resetForm])

  const originalContactName = selectedPaymentInstrument?.contact
    ? getContactNameFromPaymentInstrumentContact(
        selectedPaymentInstrument.contact
      )
    : ''

  const showMoveToNewContactInfo =
    !isAddingNewItem && selectedPaymentInstrument?.contact && !isOnUpdateItem

  const showLinkToContactInfo =
    isAddingNewItem || (!selectedPaymentInstrument?.contact && !isOnUpdateItem)

  return (
    <>
      {(isAddingNewItem || isOnUpdateItem) && (
        <Box mb="24px">
          <InputAdaptiveField
            disabled={isOnUpdateItem}
            label={translate(
              'page.contact.sections.bankAccounts.fields.accountName'
            )}
            name="accountName"
            validate={validateAccountName}
          />
        </Box>
      )}
      <Flex flexDirection={['column', 'row']}>
        <Box flex={1} mb="24px" mr="1.25rem">
          <InputAdaptiveField
            label={translate('page.contact.sections.bankAccounts.fields.bsb')}
            hasError={Boolean(isExistingPaymentInstrument)}
            name="bsb"
            disabled={!isAddingNewItem}
            validate={validateBSBNumber}
          />
        </Box>
        <Box flex={1} mb="24px">
          <InputAdaptiveField
            label={translate(
              'page.contact.sections.bankAccounts.fields.accountNumber'
            )}
            hasError={Boolean(isExistingPaymentInstrument)}
            name="accountNumber"
            disabled={!isAddingNewItem}
            validate={validateBankAccountNumber}
          />
        </Box>
      </Flex>

      {isExistingPaymentInstrument && (
        <Flex
          alignItems="center"
          color={COLOR.RED_1000}
          flexWrap="wrap"
          mb="1rem"
        >
          {translate('page.contact.sections.bankAccounts.existingBankAccount')}
        </Flex>
      )}

      {!isAddingNewItem && (
        <Box mb="24px">
          <SelectCompactField
            name="status"
            mobileLabel={translate(
              'page.contact.sections.bankAccounts.fields.status'
            )}
            label={translate(
              'page.contact.sections.bankAccounts.fields.status'
            )}
            items={statusItemOptions}
          />
        </Box>
      )}

      {showMoveToNewContactInfo && (
        <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
          <InfoBox.Message>
            {translate('page.contact.sections.bankAccounts.moveToNewContact', {
              originalContactName,
              newContactName: contactName,
            })}
          </InfoBox.Message>
        </InfoBox>
      )}

      {showLinkToContactInfo && (
        <InfoBox>
          <InfoBox.Message>
            {translate('page.contact.sections.bankAccounts.linkToContact', {
              contactName,
            })}
          </InfoBox.Message>
        </InfoBox>
      )}

      {isOnUpdateItem &&
        selectedPaymentInstrument?.bankAccountDetails?.name && (
          <UnlinkBankAccount
            accountName={selectedPaymentInstrument.bankAccountDetails.name}
            contactId={contactId}
            contactName={contactName}
            onUnlinkSuccess={onUnlinkSuccess}
            paymentIntrumentId={selectedPaymentInstrument.id}
          />
        )}
    </>
  )
}
