import { ReactNode } from 'react'

import {
  StyledDetailName,
  StyledDetailValue,
  StyledFieldContainer,
} from './ThirdPartyAccountField.styled'

export const ThirdPartyAccountField = ({
  label,
  value,
  isVisible = true,
}: {
  label: string
  value: ReactNode
  isVisible?: boolean
}) => {
  if (!value || !isVisible) {
    return null
  }
  return (
    <StyledFieldContainer data-testId="account-field">
      <StyledDetailName>{label}</StyledDetailName>
      <StyledDetailValue>{value}</StyledDetailValue>
    </StyledFieldContainer>
  )
}
