import { BodySmall } from '@npco/zeller-design-system'
import { ModalWithButtons } from 'design-system/Components/Modal'
import styled from 'styled-components'

export const StyledDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin-top: 0;
`

export const StyledDragAndDropContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;
`

export const StyledModal = styled(ModalWithButtons)`
  width: 32rem;
  overflow-y: scroll;
`
