import {
  combineValidators,
  validateMaxLength,
  validateRequired,
} from 'utils/formValidation'
import { translate } from 'utils/translations'

export const REFERENCE_MAX_LENGTH = 200

export const validateInternalTransferReference = combineValidators(
  validateRequired,
  validateMaxLength(
    REFERENCE_MAX_LENGTH,
    translate('errorMessages.referenceLength', {
      referenceLength: REFERENCE_MAX_LENGTH,
    })
  )
)
