import { Flex, Label } from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { StyledFade, StyledTextArea } from '../EditNotesModal.styled'
import { EditNotesFormValues } from '../EditNotesModal.types'

interface NotesInputProps {
  placeholder: string
  limit: number
  title: string
}

export const NotesInput = ({ placeholder, limit, title }: NotesInputProps) => {
  const { values } = useFormikContext<EditNotesFormValues>()
  const { notes } = values

  return (
    <>
      <label hidden htmlFor="note">
        {title}
      </label>
      <Field
        component={StyledTextArea}
        id="note"
        maxLength={limit}
        name="notes"
        placeholder={placeholder}
      />
      <StyledFade />
      <Flex justifyContent="flex-end" mt="8px" mr="28px">
        <Label as="div">{`${notes ? notes.length : '0'}/${limit}`}</Label>
      </Flex>
    </>
  )
}
