import { TransactionStatus } from '@npco/mp-gql-types'
import { rvSelectedStatuses } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { TRANSACTION_STATUSES } from 'const/common'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import { TransactionsFiltersInput } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters.types'

// NOTE: BE doesn't have the ability to return declined and refunded
// transactions at the same time. So we need to filter return transactions by
// refunded === true on approved transaction
export const checkIfNeedFilteredByRefundedRV = () => {
  return (
    rvSelectedStatuses().includes(TRANSACTION_STATUSES[2]) &&
    !rvSelectedStatuses().includes(TRANSACTION_STATUSES[0]) &&
    rvSelectedStatuses().length > 1
  )
}

export const checkIfNeedFilteredByRefunded = (
  selectedStatuses: TransactionsFiltersInput['statuses']
) => {
  return (
    selectedStatuses &&
    selectedStatuses.length > 1 &&
    selectedStatuses.includes('REFUNDED') &&
    !selectedStatuses.includes('APPROVED')
  )
}

export const filterGroupedTransactionList = (
  groupedTransactionList: Transaction[][]
): Transaction[][] => {
  return groupedTransactionList.map((transactions) =>
    transactions.filter(
      (transaction) =>
        transaction.status !== TransactionStatus.APPROVED ||
        transaction.refunded
    )
  )
}
