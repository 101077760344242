import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { CustomerAddressInput } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  Box,
  ButtonLink,
  Flex,
  Heading,
  InfoBox,
  showApiErrorToast,
} from '@npco/zeller-design-system'
import {
  rvProfileFetchError,
  rvProfileSettings,
  rvUpdatedEmailNotConfirmed,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'
import { useAppStateMapper } from 'auth/useAppStateMapper'
import { ModalBasic, useModalState } from 'design-system/Components/Modal'
import { ModalVerificationRequired } from 'features/MFA'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { ProfileSettingsApiCallTypeEnum } from 'types/auth'
import { useRequestEmailChange } from 'pages/Settings/Profile/Personal/Edit/hooks/useRequestEmailChange'
import { useHasElevatedScope } from 'pages/Settings/Profile/Personal/useHasElevatedScope'
import { AddressDetails } from 'components/AddressDetails'
import { UserDetailsList } from 'components/UserDetailsList'
import { page } from 'translations'

import { SpinnerWrapped } from '../../../../components/Spinner'
import { CannotUpdateDetailsMessage } from './CannotUpdateDetailsMessage'
import { StyledSectionTitle, StyledWrapper } from './ProfilePersonal.styled'
import { getHasCompletedIDV } from './ProfilePersonal.utils'
import { useMFAProfilePersonal } from './useMFAProfilePersonal'

export const ProfilePersonal = () => {
  const customerDetails = useReactiveVar(rvProfileSettings)
  const customerError = useReactiveVar(rvProfileFetchError)
  const email = rvUpdatedEmailNotConfirmed()
  const { requestEmailChange, isRequestingEmailChange } =
    useRequestEmailChange()
  const { mapProfileSettingsStateToAuthObject } = useAppStateMapper()
  const { stepUpAuth, refetch } = useHasElevatedScope()
  const { isModalOpen, closeModal, openModal } = useModalState({
    isOpenByDefault: false,
  })

  const isRegisteringIndividual = Boolean(customerDetails.registeringIndividual)
  const hasCompletedIDV = getHasCompletedIDV(customerDetails)

  const cannotUpdateDetails = isRegisteringIndividual || hasCompletedIDV

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const { resendEmailChangeRequest } = useMFAProfilePersonal({
    refetch,
    requestEmailChange,
    openModal,
  })

  useEffect(() => {
    if (customerError) {
      showApiErrorToast(customerError)
    }
  }, [customerError])

  const isNotificationDisplayed = useReactiveVar(rvUpdatedEmailNotConfirmed)

  const onRedirect = () => {
    const appState = mapProfileSettingsStateToAuthObject(
      ProfileSettingsApiCallTypeEnum.RESEND_EMAIL,
      { input: { newEmail: email } },
      'resendEmail'
    )
    stepUpAuth(appState)
  }

  return (
    <>
      {isRequestingEmailChange ? (
        <SpinnerWrapped variant="centre" />
      ) : (
        <StyledWrapper
          flexDirection="column"
          justifyContent="space-between"
          pb="16px"
        >
          {isNotificationDisplayed && (
            <Box mb="40px">
              <InfoBox>
                <InfoBox.Message>
                  {translate(
                    'page.settings.users.changeEmail.confirmationCopy'
                  )}
                </InfoBox.Message>
                <Box pt="16px">
                  <InfoBox.CallToAction onClick={resendEmailChangeRequest}>
                    {translate('page.settings.users.changeEmail.resend')}
                  </InfoBox.CallToAction>
                </Box>
              </InfoBox>
            </Box>
          )}
          <Box>
            <Flex justifyContent="space-between" alignItems="center" mb="16px">
              <Heading.H3>{page.settings.users.personalInformation}</Heading.H3>
              <ButtonLink
                onClick={() =>
                  navigate(
                    ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.PERSONAL.EDIT
                      .path
                  )
                }
                data-testid="profile-edit"
              >
                {translate('page.settings.general.edit')}
              </ButtonLink>
            </Flex>
            <UserDetailsList data={customerDetails} />
            <Box mb="16px" mt="32px">
              <StyledSectionTitle>
                {page.settings.users.personalAddress}
              </StyledSectionTitle>
            </Box>
            <AddressDetails
              data={customerDetails.address as CustomerAddressInput}
            />
          </Box>
          <Box
            width={['100%', cannotUpdateDetails ? '100%' : '192px']}
            pt="32px"
          >
            {cannotUpdateDetails && <CannotUpdateDetailsMessage />}
          </Box>
        </StyledWrapper>
      )}
      <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
        <ModalVerificationRequired
          onCancel={closeModal}
          onRedirect={onRedirect}
        />
      </ModalBasic>
    </>
  )
}
