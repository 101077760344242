import { DataMediumTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.GREY_580};
  ${DataMediumTypography};
`

export const Value = styled.div`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.GREY_580};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
