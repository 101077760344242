import { useMemo } from 'react'
import { TransactionStatus } from '@npco/mp-gql-types'
import { Box, Typography } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { GetTransaction_getTransaction_refundTransactions as RefundTransaction } from 'types/gql-types/GetTransaction'
import { Transaction } from 'types/transactions'

import { TransactionRefund } from '../TransactionRefund/TransactionRefund'
import { sortRefundTransactionsByTime } from './TransactionRefunds.utils'

interface TransactionRefundsProps {
  transaction: Transaction
  onClickReference: (id: string) => void
}

export const TransactionRefunds = ({
  transaction,
  onClickReference,
}: TransactionRefundsProps) => {
  const { refundTransactions } = transaction
  const counter = { balance: transaction.amount }

  const refundTransactionsForSort: RefundTransaction[] = useMemo(() => {
    return refundTransactions ? [...refundTransactions] : []
  }, [refundTransactions])

  sortRefundTransactionsByTime(refundTransactionsForSort)

  return (
    <>
      {refundTransactionsForSort.length > 0 && (
        <>
          <Box mt="30px" mb="12px">
            <Typography component="h3" variant="heading-xl">
              {translate('page.transactionDetails.refunds')}
            </Typography>
          </Box>
          {refundTransactionsForSort.map(
            (refundTransaction: RefundTransaction) => {
              if (refundTransaction.status === TransactionStatus.APPROVED) {
                counter.balance -= refundTransaction.amount
              }
              return (
                <TransactionRefund
                  key={refundTransaction.id}
                  balanceAfterRefund={counter.balance}
                  refundTransaction={refundTransaction}
                  onClickReference={onClickReference}
                />
              )
            }
          )}
        </>
      )}
    </>
  )
}
