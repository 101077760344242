import { createTranslations } from '@npco/utils-translations'

export const setupFlowPosLiteStageTranslations = createTranslations({
  title: "The free point-of-sale that's built for your business.",
  description:
    'Meet Zeller POS Lite — our new point-of-sale included free with Zeller Terminal 2 and Zeller App.',
  point1: 'A fully integrated payments and POS solution.',
  point2: 'Provide customers fully itemised receipts.',
  point3: 'Add new items instantly, or bulk upload from your previous POS.',
  point4:
    'Customise your layout to quickly access items, discounts or categories.',
})
