import { useMemo } from 'react'

import { Pairing, Venue } from '../HlPosManage.types'

type Props = {
  venue: Venue
  initialPairings: Pairing[]
}
export const useGetInitialStates = ({ venue, initialPairings }: Props) => {
  const venueId = venue.id
  const currentVenuePairings = useMemo(
    () => initialPairings.filter((pairing) => pairing.venueId === venueId),
    [initialPairings, venueId]
  )

  const initialVenueStationId = useMemo(
    () =>
      currentVenuePairings.find(({ locationId }) => locationId === null)
        ?.stationId,
    [currentVenuePairings]
  )

  const initialLocationStationIds: Record<string, string> = useMemo(
    () =>
      currentVenuePairings.reduce((acc, pairing) => {
        if (pairing.locationId) {
          return { ...acc, [pairing.locationId]: pairing.stationId }
        }
        return acc
      }, {}),
    [currentVenuePairings]
  )

  const initialSiteIdMap = useMemo(() => {
    return currentVenuePairings.reduce((acc, pairing) => {
      if (pairing.locationId) {
        return { ...acc, [pairing.siteUuid]: pairing.locationId }
      }
      return { ...acc, [pairing.siteUuid]: pairing.venueId }
    }, {})
  }, [currentVenuePairings])

  const initialValues = useMemo(() => {
    return {
      venue: venueId,
      venueStationIdToggle: !!initialVenueStationId,
      venueStationId: initialVenueStationId ?? '',
      locationStationIdToggle: !!Object.values(
        initialLocationStationIds
      ).filter(Boolean).length,
      locations: venue?.locations?.reduce((acc, location) => {
        return {
          ...acc,
          [location.id]: {
            stationId: initialLocationStationIds[location.id] ?? '',
          },
        }
      }, {}),
    }
  }, [
    initialLocationStationIds,
    initialVenueStationId,
    venue?.locations,
    venueId,
  ])

  return {
    initialValues,
    initialVenueStationId,
    initialLocationStationIds,
    initialSiteIdMap,
  }
}
