import { Box } from '@npco/zeller-design-system'
import { Form, FormikTouched } from 'formik'

import { translate } from 'utils/translations'
import { BankAccountFieldsProps } from 'forms/formViews/BankAccountFields/BankAccountFields.types'
import {
  validateAccountName,
  validateBankAccountNumber,
  validateBSBNumber,
} from 'forms/formViews/BankAccountFields/BankAccountFields.utils'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import {
  StyledAccountNumberAndBsbContainer,
  StyledAddAccountModal,
} from './AddThirdPartyAccountForm.styled'

interface AddThirdPartyAccountFormProps {
  isOpen: boolean
  onContinue: () => void
  onCancel: () => void
  touched: FormikTouched<BankAccountFieldsProps>
  isValid: boolean
}

export const AddThirdPartyAccountForm = ({
  isOpen,
  onContinue,
  onCancel,
  touched,
  isValid,
}: AddThirdPartyAccountFormProps) => {
  return (
    <StyledAddAccountModal
      isOpen={isOpen}
      onConfirm={() => onContinue()}
      onCancel={onCancel}
      primaryButtonLabel={translate('shared.continue')}
      secondaryButtonLabel={translate('shared.cancel')}
      isPrimaryButtonDisabled={!isValid || Object.keys(touched).length === 0}
      hasCancelButton
      title={translate('page.settings.addExternalAccount')}
    >
      <Form>
        <Box mb="1.5rem">
          <InputAdaptiveFieldWrapper
            name="accountName"
            type="text"
            label={translate('form.newExternalAccount.accountName')}
            aria-label={translate('form.newExternalAccount.accountName')}
            validate={validateAccountName}
          />
        </Box>
        <StyledAccountNumberAndBsbContainer>
          <Box
            mr={[0, 0, '1.25rem']}
            width={{ _: '100%', LG: '50%' }}
            mb="1.5rem"
          >
            <InputAdaptiveFieldWrapper
              name="accountBsb"
              label={translate('form.newExternalAccount.accountBsb')}
              aria-label={translate('form.newExternalAccount.accountBsb')}
              validate={validateBSBNumber}
            />
          </Box>
          <Box width={{ _: '100%', LG: '50%' }} mb="1.5rem">
            <InputAdaptiveFieldWrapper
              name="accountNumber"
              label={translate('form.newExternalAccount.accountNumber')}
              aria-label={translate('form.newExternalAccount.accountNumber')}
              validate={validateBankAccountNumber}
            />
          </Box>
        </StyledAccountNumberAndBsbContainer>
      </Form>
    </StyledAddAccountModal>
  )
}
