import { getCurrentTime } from 'pages/Dashboard/utils/timeUtils'

const PERSIST_SECONDS = 10

export function filterOutdatedTransactions<T extends { timestamp: number }>(
  transactions: T[]
): T[] {
  const currentTimestamp = getCurrentTime().valueOf()
  return transactions.filter((transaction) => {
    const timestampDiffInSecond = Math.floor(
      (currentTimestamp - transaction.timestamp) / 1000
    )
    return timestampDiffInSecond < PERSIST_SECONDS
  })
}
