import { BUTTON_SIZE, ButtonGhost, Flex } from '@npco/zeller-design-system'

import { StyledDescription, StyledTitle } from './CategoriesPlaceholder.styled'

interface Props {
  title: React.ReactNode
  description: React.ReactNode
  buttonLabel?: React.ReactNode
  onClick?: () => void
  className?: string
}

export const CategoriesPlaceholder = ({
  title,
  description,
  buttonLabel,
  onClick,
  className,
}: Props) => {
  return (
    <div className={className}>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      {buttonLabel && onClick && (
        <Flex justifyContent="center" mb="1.5rem">
          <ButtonGhost size={BUTTON_SIZE.SMALL} onClick={onClick}>
            {buttonLabel}
          </ButtonGhost>
        </Flex>
      )}
    </div>
  )
}
