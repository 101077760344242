import { gql, type TypedDocumentNode } from '@apollo/client'

import { UseMultiEntityTabsCustomerEntityRelationFragmentDoc } from './hooks/useMultiEntityTabs/useMultiEntityTabs.generated'

export type TopBarNavCustomerEntityRelationFragment = {
  __typename?: 'CustomerEntityRelation'
  entityUuid: string
  isVisible: boolean
  order: number | null
  entity: { __typename?: 'Entity'; id: string; name: string | null }
}

export const TopBarNavCustomerEntityRelationFragmentDoc = gql`
  fragment TopBarNavCustomerEntityRelationFragment on CustomerEntityRelation {
    ...UseMultiEntityTabsCustomerEntityRelationFragment
  }
  ${UseMultiEntityTabsCustomerEntityRelationFragmentDoc}
` as unknown as TypedDocumentNode<
  TopBarNavCustomerEntityRelationFragment,
  undefined
>
