import { useTranslations } from '@npco/utils-translations'

import {
  StyledContactHeaderButton,
  StyledContactHeaderContainer,
} from 'pages/Transfer/ContactDropdown/ContactHeader.styled'

import { bpayDetailsFieldTranslations } from '../../BpayDetailsField.i18n'

interface BpayDetailsItemHeaderProps {
  addBpayDetails: () => void
}

export const BpayDetailsItemHeader = ({
  addBpayDetails,
}: BpayDetailsItemHeaderProps) => {
  const t = useTranslations(bpayDetailsFieldTranslations)

  return (
    <StyledContactHeaderContainer>
      <StyledContactHeaderButton onClick={addBpayDetails}>
        {t('addBpayDetailsButtonLabel')}
      </StyledContactHeaderButton>
    </StyledContactHeaderContainer>
  )
}
