import { DocumentUploadedType, EntityType } from '@npco/mp-gql-types'

import { translate } from 'utils/translations'

export enum OtherDocumentsMap {
  GOVERNMENT_DOCUMENT = 'GOVERNMENT_DOCUMENT',
  UTILS_DOCUMENT = 'UTILS_DOCUMENT',
}

export const idvDocumentItemLabelMapping = {
  [DocumentUploadedType.ID_DOCUMENT]: translate('form.documentType.id'),
}

export const safeHarbourDocumentItemLabelMapping = {
  [DocumentUploadedType.BANK_STATEMENT_DOCUMENT]: translate(
    'form.documentType.bankStatements'
  ),
  [OtherDocumentsMap.GOVERNMENT_DOCUMENT]: translate(
    'form.documentType.government'
  ),
  [OtherDocumentsMap.UTILS_DOCUMENT]: translate('form.documentType.utility'),
}

const genericDocumentItemLabelMapping = {
  [DocumentUploadedType.BANK_STATEMENT_DOCUMENT]: translate(
    'form.documentType.bankStatements'
  ),
  [DocumentUploadedType.CUSTOMER_SUPPORT_DOCUMENT]: translate(
    'form.documentType.customerSupport'
  ),
  [DocumentUploadedType.ID_DOCUMENT]: translate('form.documentType.id'),
  [DocumentUploadedType.INVOICE_DOCUMENT]: translate(
    'form.documentType.invoice'
  ),
}

const documentItemLabelMapping = {
  ...genericDocumentItemLabelMapping,
  [DocumentUploadedType.OTHER_DOCUMENT]: translate('form.documentType.other'),
}

export const onboardingDocumentsLabelMapping = {
  [DocumentUploadedType.BANK_STATEMENT_DOCUMENT]: translate(
    'form.documentType.bankStatements'
  ),
  [DocumentUploadedType.ID_DOCUMENT]: translate('form.documentType.id'),
  [OtherDocumentsMap.GOVERNMENT_DOCUMENT]: translate(
    'form.documentType.government'
  ),
  [OtherDocumentsMap.UTILS_DOCUMENT]: translate('form.documentType.utility'),
  [DocumentUploadedType.OTHER_DOCUMENT]: translate('form.documentType.other'),
}

export const entityDocumentItemLabelMapping: {
  [key in DocumentUploadedType]?: string
} = {
  [DocumentUploadedType.ASSOCIATION_DOCUMENT]: translate(
    'form.documentType.association'
  ),
  [DocumentUploadedType.PARTNERSHIP_DOCUMENT]: translate(
    'form.documentType.partnership'
  ),
  [DocumentUploadedType.TRUST_DOCUMENT]: translate('form.documentType.trust'),
}

export const getDocumentTypeItemOptions = (mapping: Record<string, string>) => {
  return Object.entries(mapping).map(([value, label]) => ({
    label,
    value,
  }))
}

export const documentTypeItemOptions = getDocumentTypeItemOptions(
  documentItemLabelMapping
)

export const onboardingDocumentTypeItemOptions = getDocumentTypeItemOptions(
  onboardingDocumentsLabelMapping
)

export const documentTypeByEntityMapping: {
  [key in EntityType]?: DocumentUploadedType
} = {
  [EntityType.ASSOCIATION]: DocumentUploadedType.ASSOCIATION_DOCUMENT,
  [EntityType.PARTNERSHIP]: DocumentUploadedType.PARTNERSHIP_DOCUMENT,
  [EntityType.TRUST]: DocumentUploadedType.TRUST_DOCUMENT,
}
