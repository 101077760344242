import { ApolloCache } from '@apollo/client'

interface AccountBalanceCacheOptions {
  cache: ApolloCache<any>
  id: string
  valueInCents: string | number
}

const convertToNumber = (value: number | string) => {
  return typeof value === 'string' ? parseInt(value, 10) : value
}

export const increaseAccountBalance = ({
  cache,
  id,
  valueInCents,
}: AccountBalanceCacheOptions) => {
  const amountInCents = convertToNumber(valueInCents)

  cache.modify({
    id: cache.identify({
      __typename: 'DebitCardAccountV2',
      id,
    }),
    fields: {
      balance(cachedBalance) {
        const originalValue = cachedBalance?.value || '0'

        return {
          ...cachedBalance,
          value: (parseInt(originalValue, 10) + amountInCents).toString(),
        }
      },
    },
  })
}

export const decreaseAccountBalance = ({
  cache,
  id,
  valueInCents,
}: AccountBalanceCacheOptions) => {
  const amountInCents = convertToNumber(valueInCents)

  cache.modify({
    id: cache.identify({
      __typename: 'DebitCardAccountV2',
      id,
    }),
    fields: {
      balance(cachedBalance) {
        const originalValue = cachedBalance?.value || '0'

        return {
          ...cachedBalance,
          value: (parseInt(originalValue, 10) - amountInCents).toString(),
        }
      },
    },
  })
}
