import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledItemName = styled.div`
  outline: none;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
`

export const StyledItem = styled.li<{
  isActive?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 31.5rem;
  width: 100%;
  padding: 0 1.5rem;
  box-sizing: border-box;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.BLUE_80 : theme.colors.WHITE};
  outline: none;
  cursor: pointer;
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.BLUE_400 : theme.colors.GREY_150};
  transition: border-color 0.3s ease-out;
  height: 6rem;
  ${BodyDefaultTypography};
  font-family: inherit;
  color: ${({ theme }) => theme.colors.BLACK};
  border-radius: 4px;
  margin-bottom: 1rem;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_30};
  }
`
