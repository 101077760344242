import { StyledColourTile } from './CardColourTile.styled'
import { CardTileColour } from './CardColourTile.types'

interface CardColourTileProps {
  color: CardTileColour
}

export const CardColourTile = ({ color }: CardColourTileProps) => {
  return <StyledColourTile color={color} data-testid="card-colour-tile" />
}
