import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ModalForm } from '@npco/zeller-design-system'
import { FormikErrors, useFormikContext } from 'formik'

import { transferRemittancesModalTranslations } from '../TransferRemittancesModal.i18n'
import { ModalContentWrapper } from '../TransferRemittancesModal.styled'
import { RemittanceData } from '../TransferRemittancesModal.types'

type FormProps = {
  isOpen: boolean
  onCancel: () => void
  title: string
  description: string
  children: React.ReactNode
  isLoading: boolean
  isPrimaryButtonDisabled: (
    values: RemittanceData,
    errors: FormikErrors<RemittanceData>
  ) => boolean
}
export const TransferRemittancesModalForm = ({
  isOpen,
  onCancel,
  title,
  description,
  children,
  isLoading,
  isPrimaryButtonDisabled,
}: FormProps) => {
  const t = useTranslations(transferRemittancesModalTranslations)
  const { handleSubmit, values, errors } = useFormikContext<RemittanceData>()

  const primaryButtonDisabled = useMemo(() => {
    return isPrimaryButtonDisabled(values, errors)
  }, [isPrimaryButtonDisabled, values, errors])

  return (
    <ModalForm
      onClickPrimary={handleSubmit}
      primaryButtonLabel={t('send')}
      secondaryButtonLabel={t('cancel')}
      isOpen={isOpen}
      onCancel={onCancel}
      title={title}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc
      isLoading={isLoading}
      isPrimaryButtonDisabled={Boolean(primaryButtonDisabled)}
      isSecondaryButtonDisabled={isLoading}
      contentStyle={{
        marginTop: '0',
      }}
      isFullScreen={false}
      showOverflow={false}
      canScroll={false}
      ariaHideApp={false}
    >
      <ModalContentWrapper>
        {description}
        {children}
      </ModalContentWrapper>
    </ModalForm>
  )
}
