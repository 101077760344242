import { gql } from '@apollo/client'

import { DateRange } from '../hooks/useSelectedDateRange'
import { ExpenseDatapoint } from './ExpenseDatapoint/ExpenseDatapoint'
import { useFilterByDateRange } from './hooks/useFilterByDateRange/useFilterByDateRange'
import { IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment } from './IncomeAndExpenseDatapoints.generated'
import * as styled from './IncomeAndExpenseDatapoints.styled'
import { IncomeDatapoint } from './IncomeDatapoint/IncomeDatapoint'

type IncomeAndExpenseDatapointsProps = {
  incomeAndExpenses: IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment[]
  isLoadingIncomeAndExpenses: boolean
  selectedDateRange: DateRange
  timezone: string | undefined
}

export const IncomeAndExpenseDatapoints = ({
  incomeAndExpenses,
  isLoadingIncomeAndExpenses,
  selectedDateRange,
  timezone,
}: IncomeAndExpenseDatapointsProps) => {
  const incomeAndExpensesFilteredByDateRange = useFilterByDateRange(
    incomeAndExpenses,
    { dateRange: selectedDateRange, timezone }
  )

  return (
    <styled.IncomeAndExpenseDatapointsWrapper data-testid="income-and-expense-datapoints">
      <styled.IncomeAndExpenseDatapointWrapper
        mr={{ _: '0', SM: '80px' }}
        mb="8px"
      >
        <IncomeDatapoint
          incomeTotals={incomeAndExpensesFilteredByDateRange}
          isLoading={isLoadingIncomeAndExpenses}
        />
      </styled.IncomeAndExpenseDatapointWrapper>
      <styled.IncomeAndExpenseDatapointWrapper>
        <ExpenseDatapoint
          expenseTotals={incomeAndExpensesFilteredByDateRange}
          isLoading={isLoadingIncomeAndExpenses}
        />
      </styled.IncomeAndExpenseDatapointWrapper>
    </styled.IncomeAndExpenseDatapointsWrapper>
  )
}

IncomeAndExpenseDatapoints.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...IncomeDatapointCashFlowPeriodicTotalAmountFragment
      ...ExpenseDatapointCashFlowPeriodicTotalAmountFragment
      ...UseFilterByDateRangeCashFlowPeriodicTotalAmountFragment
    }

    ${IncomeDatapoint.fragments.CashFlowPeriodicTotalAmount}
    ${ExpenseDatapoint.fragments.CashFlowPeriodicTotalAmount}
    ${useFilterByDateRange.fragments.CashFlowPeriodicTotalAmount}
  `,
}
