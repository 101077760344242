import { Box, BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const AnimationAnchor = styled(Box)<{ $modalMinHeight: number }>`
  position: fixed;
  top: -112px;
  bottom: 112px;
  left: 80px;
  right: -112px;

  @media (min-width: ${BREAKPOINT.XS}px) {
    top: ${({ $modalMinHeight }) => $modalMinHeight / 2 - 413.5}px;
    bottom: ${({ $modalMinHeight }) => $modalMinHeight / 2 - 413.5}px;
    left: -464px;
    right: -464px;
  }
`

export const TickAnimationAnchor = styled.output`
  padding-bottom: 32px;
  width: 140px;
  height: 140px;

  @media (min-width: ${BREAKPOINT.XS}px) {
    width: 148px;
    height: 148px;
  }
`
