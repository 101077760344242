import Downshift from 'downshift'

import { useMultiSelectItemsHandlers } from '../../hooks/useMultiSelectItemsHandlers'
import { useMultiSelectReducer } from '../../hooks/useMultiSelectReducer'
import {
  ALL_VALUE,
  MultiSelectBasicProps,
  MultiSelectItemBasic,
} from '../../MultiSelect.types'
import { MultiSelectBasicDesktopContent } from './MultiSelectBasicDesktopContent'

export const MultiSelectBasicDesktop = <ItemType extends MultiSelectItemBasic>({
  items,
  selectedItems,
  onChange,
  allItemsLabel,
  onClose,
  shouldAutoFocusControl,
  ...rest
}: MultiSelectBasicProps<ItemType>) => {
  const { changeSelectedItems, clearAllSelected } = useMultiSelectItemsHandlers(
    {
      onChange,
      items,
      selectedItems,
    }
  )

  const allItems = allItemsLabel
    ? [{ value: ALL_VALUE, label: allItemsLabel } as ItemType, ...items]
    : items

  const { stateReducer } = useMultiSelectReducer<ItemType>({
    items: allItems,
    changeSelectedItems,
    onClose: () => onClose?.(selectedItems),
  })

  return (
    <Downshift
      onSelect={changeSelectedItems}
      selectedItem={undefined}
      stateReducer={stateReducer}
      itemToString={(item) => item?.value || ''}
      onOuterClick={() => onClose?.(selectedItems)}
    >
      {(renderProps) => (
        <div>
          <MultiSelectBasicDesktopContent
            clearAllSelected={clearAllSelected}
            items={items}
            allItems={allItems}
            allItemsLabel={allItemsLabel}
            selectedItems={selectedItems}
            onClose={onClose}
            shouldAutoFocusControl={shouldAutoFocusControl}
            {...renderProps}
            {...rest}
          />
        </div>
      )}
    </Downshift>
  )
}
