import { Box } from '@npco/zeller-design-system'

import { PersonaliseAccountFields } from 'components/AccountFields/PersonaliseAccountFields/PersonaliseAccountFields'

import { AccountTermsAndConditionsField } from './AccountTermsAndConditionsField'

export const CreateAccountFields = () => {
  return (
    <>
      <PersonaliseAccountFields />
      <Box mt="24px">
        <AccountTermsAndConditionsField />
      </Box>
    </>
  )
}
