import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useCardId } from '../../hooks/useCardId'
import { CardSingleBreadcrumbsQuery } from './graphql/CardSingleBreadcumbsQuery'
import {
  CardSingleBreadcrumbsQueryResponse,
  CardSingleBreadcrumbsQueryVariables,
} from './graphql/CardSingleBreadcumbsQuery.generated'

export const useCardSingleBreadcrumbsQuery = () => {
  const { cardId } = useCardId()

  const entityUuid = useSelectedEntityUuid()

  const { data, loading, error } = useQuery<
    CardSingleBreadcrumbsQueryResponse,
    CardSingleBreadcrumbsQueryVariables
  >(CardSingleBreadcrumbsQuery, {
    variables: {
      entityUuid,
      cardId,
    },
  })

  return {
    card: data?.getDebitCardV2 || null,
    isLoadingBreadcrumbsData: loading,
    error,
  }
}
