import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { GetImageUrlIconFragmentDoc } from '@npco/mp-utils-icon'

export type UserIconAvatarIconFragment = {
  __typename?: 'Icon'
  image: string | null
  images: Array<{
    __typename?: 'Image'
    url: string
    size: Types.ImageSize
  }> | null
}

export const UserIconAvatarIconFragmentDoc = gql`
  fragment UserIconAvatarIconFragment on Icon {
    ...GetImageUrlIconFragment
  }
  ${GetImageUrlIconFragmentDoc}
` as unknown as TypedDocumentNode<UserIconAvatarIconFragment, undefined>
