import { useState } from 'react'

import { PerformanceContent } from 'pages/Dashboard/DashboardPayments/DashboardPerformance/PerformanceContent/PerformanceContent'
import { SourcePicker } from 'pages/Dashboard/SourcePicker/SourcePicker'
import { page } from 'translations'

import { DashboardSection } from '../DashboardSales/DashboardSales.styled'
import { PerformanceTabs } from './Controls/PerformanceTabs'
import {
  StyledPerformanceHeader,
  StyledPerformanceTitle,
  StyledPerformanceWrapper,
  StyledStepperWrapper,
} from './DashboardPerformance.styled'
import { PerformancePeriod } from './DashboardPerformance.types'

export const DashboardPerformance = () => {
  const [performancePeriod, setPerformancePeriod] = useState(
    PerformancePeriod.TODAY
  )

  return (
    <DashboardSection>
      <StyledPerformanceWrapper>
        <StyledPerformanceHeader>
          <StyledPerformanceTitle>
            {page.dashboard.payments.sitePerformance.title}
          </StyledPerformanceTitle>
          <StyledStepperWrapper>
            <SourcePicker />
          </StyledStepperWrapper>
          <PerformanceTabs
            initialTab={performancePeriod}
            onChange={setPerformancePeriod}
          />
        </StyledPerformanceHeader>
        <PerformanceContent performancePeriod={performancePeriod} />
      </StyledPerformanceWrapper>
    </DashboardSection>
  )
}
