import { Accept, FileRejection } from 'react-dropzone'
import { BodySmall, Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { FileUploader } from 'components/File/FileUploader/FileUploader'

import { FileListProvider } from '../File.context'
import {
  FileListItemSelect,
  FileListSelectItem,
  FileQueueItem,
} from '../File.types'
import { FileListGroup } from '../FileListGroup/FileListGroup'
import { FileUploaderError } from '../FileUploaderError/FileUploaderError'

const MAX_FILE_SIZE_KB = 50 * 1024 * 1024
const MAX_FILES_QUANTITY = 5

interface FileUploaderGroupProps {
  fileQueueItems: FileQueueItem[]
  succeededFileQueueItems: FileQueueItem[]
  savedUploadedFiles?: FileQueueItem[]
  addFilesToQueue: (files: File[]) => void
  removeFileFromQueue: (fileId: string) => void
  onDropRejected: (error: FileRejection[]) => void
  acceptedFileTypes?: Accept
  maxFiles?: number
  maxFileSize?: number
  optionItems?: FileListItemSelect['optionItems']
  defaultOption?: FileListSelectItem
}

const defaultAcceptedFileTypes: Accept = {
  'application/pdf': [],
  'image/*': [
    '.bmp',
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.heic',
    '.heif',
    '.webp',
  ],
}

export const FileUploaderGroup = ({
  fileQueueItems,
  addFilesToQueue,
  removeFileFromQueue,
  succeededFileQueueItems,
  onDropRejected,
  savedUploadedFiles = [],
  acceptedFileTypes = defaultAcceptedFileTypes,
  maxFiles = MAX_FILES_QUANTITY,
  maxFileSize = MAX_FILE_SIZE_KB,
  optionItems,
  defaultOption,
}: FileUploaderGroupProps) => {
  const allItemsQuantity =
    fileQueueItems.length + succeededFileQueueItems.length

  const uploadedItems = [...savedUploadedFiles, ...succeededFileQueueItems]
  const allItems = [...uploadedItems, ...fileQueueItems]
  const hasItems = Boolean(allItems.length)

  return (
    <FileListProvider optionItems={optionItems} defaultOption={defaultOption}>
      <Box mb="2.5rem">
        {hasItems && (
          <FileListGroup
            fileQueueItems={fileQueueItems}
            removeFileFromQueue={removeFileFromQueue}
            uploadedItems={uploadedItems}
          />
        )}

        {optionItems && <FileUploaderError />}
      </Box>

      <FileUploader
        maxSize={maxFileSize}
        maxFiles={maxFiles - allItemsQuantity}
        accept={acceptedFileTypes}
        onDrop={addFilesToQueue}
        isDisabled={allItemsQuantity >= maxFiles}
        onDropRejected={onDropRejected}
      />
      <Box mb="2.5rem" mt="1rem">
        <BodySmall as="div">
          {translate('form.shared.uploadDocuments.description', {
            value: maxFiles,
            size: 50,
          })}
          <div>{translate('form.shared.uploadDocuments.allowedFiles')}</div>
        </BodySmall>
      </Box>
    </FileListProvider>
  )
}
