import { useEffect } from 'react'
import { ReactiveVar } from '@apollo/client'

interface UseTrackDashboardErrorProps {
  rvRetry: ReactiveVar<(() => void) | null> | undefined
  retry: (() => void) | undefined
}

export const useTrackDashboardError = ({
  rvRetry,
  retry,
}: UseTrackDashboardErrorProps) => {
  useEffect(() => {
    if (rvRetry && retry) {
      rvRetry(() => retry)
      return () => {
        rvRetry(null)
      }
    }

    return undefined
  }, [retry, rvRetry])
}
