import { BillStatus } from '@npco/mp-gql-types'
import { COLOR, Status } from '@npco/zeller-design-system'

import { ReactComponent as DeclinedIcon } from 'assets/svg/decline.svg'
import { ReactComponent as RefundedIcon } from 'assets/svg/refund.svg'
import { ReactComponent as ApprovedIcon } from 'assets/svg/tick.svg'
import { translate } from 'utils/translations'
import { SimBillStatusVariants } from 'components/StatusBadge/StatusBadge.types'

const variants: SimBillStatusVariants = {
  [BillStatus.CHARGED]: {
    icon: <ApprovedIcon />,
    text: translate('page.settings.sim.billingHistory.statusBadge.charged'),
    color: COLOR.GREEN_1600,
  },
  [BillStatus.CHARGE_FAILED]: {
    icon: <DeclinedIcon />,
    text: translate(
      'page.settings.sim.billingHistory.statusBadge.chargeFailed'
    ),
    color: COLOR.RED_1000,
  },
  [BillStatus.REFUNDED]: {
    icon: <RefundedIcon />,
    text: translate('page.settings.sim.billingHistory.statusBadge.refunded'),
    color: COLOR.RED_1000,
  },
  [BillStatus.REFUND_FAILED]: {
    icon: <DeclinedIcon />,
    text: translate(
      'page.settings.sim.billingHistory.statusBadge.refundFailed'
    ),
    color: COLOR.RED_1000,
  },
}

const SimBillStatusBadge = ({ status }: { status: BillStatus }) => {
  const { icon, text, color } = variants[status]

  return <Status color={color} icon={icon} text={text} />
}

export default SimBillStatusBadge
