import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  cardPaymentsTitle: 'Card payments',
  cardPaymentsSurcharge: 'Card Payments Surcharge',
  cardPaymentsDescription: 'Tap, dip or swipe cards with your Zeller Terminal.',
  processingFee: 'Processing Fee',
  processingFeeAmount: '1.4%',
  description1:
    'Under Australian law, a card surcharge amount is limited to the direct costs incurred by the merchant.',
  description2: "Zeller's maximum surcharge is {value}%",
  totalFees: 'Surcharge my total fees',
  percentage: 'Surcharge a percentage',
  notAvailable: 'Not available',
  cardSurcharge: 'Card Surcharge',
  restrictReportAcess: 'Restrict Report Access',
  restrictReportAcessDesc:
    'Admin or Manager must log in to access Reports on the Zeller Terminal.',
})
