import { useTranslations } from '@npco/utils-translations'
import { Flex, LockAndKey, Message } from '@npco/zeller-design-system'

import { accessDeniedTranslations } from './AccessDenied.i18n'

export const AccessDenied = () => {
  const t = useTranslations(accessDeniedTranslations)

  return (
    <Flex
      height="100%"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      <Message
        image={<LockAndKey size="large" />}
        title={t('title')}
        description={t('description')}
      />
    </Flex>
  )
}
