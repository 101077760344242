import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'

import { GetSimQueryResponse } from '../SimDrawer/graphql/getSim.generated'
import { translations } from './PendingStatusAction.i18n'

interface Props {
  abortSimCancellation: () => void
  selectedSim?: GetSimQueryResponse['getSim']
}
export const PendingStatusAction = ({
  abortSimCancellation,
  selectedSim,
}: Props) => {
  const t = useTranslations(translations)

  return (
    <InfoBox
      variant={INFOBOX_VARIANT.WARNING}
      priority={INFOBOX_PRIORITY.MEDIUM}
    >
      <Flex flexDirection="column" gap="16px" alignItems="flex-start">
        <InfoBox.Message>
          {t('info', {
            rechargeDate: dayjs(selectedSim?.nextBillingDate).format(
              'DD MMM YYYY'
            ),
          })}
        </InfoBox.Message>

        <InfoBox.CallToAction onClick={abortSimCancellation}>
          {t('reactivateSim')}
        </InfoBox.CallToAction>
      </Flex>
    </InfoBox>
  )
}
