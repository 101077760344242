import { SelectItemBasic } from 'design-system/Components/Select'

import { assertUnreachable } from 'utils/assertUnreachable'
import { AccountFilterAmount } from 'layouts/AccountLayout/filters/AccountFilterAmount/AccountFilterAmount'
import { AccountFilterCategory } from 'layouts/AccountLayout/filters/AccountFilterCategory/AccountFilterCategory'
import { AccountFilterContact } from 'layouts/AccountLayout/filters/AccountFilterContact/AccountFilterContact'
import { AccountFilterDate } from 'layouts/AccountLayout/filters/AccountFilterDate/AccountFilterDate'
import { AccountFilterDescription } from 'layouts/AccountLayout/filters/AccountFilterDescription/AccountFilterDescription'
import { AccountFilterReceipt } from 'layouts/AccountLayout/filters/AccountFilterReceipt/AccountFilterReceipt'
import { AccountFilterStatus } from 'layouts/AccountLayout/filters/AccountFilterStatus/AccountFilterStatus'
import { AccountFilterTags } from 'layouts/AccountLayout/filters/AccountFilterTags/AccountFilterTags'
import { AccountFilterTransactionType } from 'layouts/AccountLayout/filters/AccountFilterTransactionType/AccountFilterTransactionType'
import { AccountMobileFilterAmount } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterAmount/AccountMobileFilterAmount'
import { AccountMobileFilterCategory } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterCategory/AccountMobileFilterCategory'
import { AccountMobileFilterContact } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterContact/AccountMobileFilterContact'
import { AccountMobileFilterDate } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterDate/AccountMobileFilterDate'
import { AccountMobileFilterDescription } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterDescription/AccountMobileFilterDescription'
import { AccountMobileFilterReceipt } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterReceipt/AccountMobileFilterReceipt'
import { AccountMobileFilterStatus } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterStatus/AccountMobileFilterStatus'
import { AccountMobileFilterTags } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterTags/AccountMobileFilterTags'
import { AccountMobileFilterTransactionType } from 'layouts/AccountLayout/filtersMobile/AccountMobileFilterTransactionType/AccountMobileFilterTransactionType'
import { CategoryWithSubcategories } from 'layouts/AccountLayout/hooks/useGetCategoriesWithSubcategories/useGetCategoriesWithSubcategories'
import { DefaultFilterOptions } from 'components/FiltersList/FiltersList'
import { MobileFilter } from 'components/FiltersList/FiltersListMobile'
import { FiltersListMobileExportTransactionsItem } from 'components/FiltersList/FiltersListMobileExportTransactionsItem'
import { page } from 'translations'

import {
  FilterType,
  FilterTypeExtra,
  FilterTypeMobile,
} from './useAccountFiltersList.constants'

const filterToLabelMap: Record<FilterType | FilterTypeExtra, string> = {
  [FilterType.Contact]: page.accounts.transactionsList.filters.contact,
  [FilterType.Date]: page.accounts.transactionsList.filters.date,
  [FilterType.Amount]: page.accounts.transactionsList.filters.amount,
  [FilterType.Category]: page.accounts.transactionsList.filters.category,
  [FilterType.Tags]: page.accounts.transactionsList.filters.tags,
  [FilterType.TransactionType]:
    page.accounts.transactionsList.filters.transactionType,
  [FilterType.Status]: page.accounts.transactionsList.filters.status,
  [FilterType.Receipt]: page.accounts.transactionsList.filters.receipt,
  [FilterType.Description]: page.accounts.transactionsList.filters.description,
  [FilterTypeExtra.Export]: 'Export transactions',
}

export const mapFilterToSelectElement = (
  filter: FilterType
): SelectItemBasic => ({
  value: filter,
  label: filterToLabelMap[filter],
})

export const mapFilterToPillComponent =
  (availableTags: string[], categories: CategoryWithSubcategories[]) =>
  (filter: FilterType) => {
    switch (filter) {
      case FilterType.Contact: {
        return <AccountFilterContact key={FilterType.Contact} />
      }
      case FilterType.Date: {
        return <AccountFilterDate key={FilterType.Date} />
      }
      case FilterType.Amount: {
        return <AccountFilterAmount key={FilterType.Amount} />
      }
      case FilterType.Category: {
        return (
          <AccountFilterCategory
            key={FilterType.Category}
            categories={categories}
          />
        )
      }
      case FilterType.Tags: {
        return (
          <AccountFilterTags
            availableTags={availableTags}
            key={FilterType.Tags}
          />
        )
      }
      case FilterType.TransactionType: {
        return <AccountFilterTransactionType key={FilterType.TransactionType} />
      }
      case FilterType.Status: {
        return <AccountFilterStatus key={FilterType.Status} />
      }
      case FilterType.Receipt: {
        return <AccountFilterReceipt key={FilterType.Receipt} />
      }
      case FilterType.Description: {
        return <AccountFilterDescription key={FilterType.Description} />
      }
      default:
        return assertUnreachable(filter)
    }
  }

export const getMobileFilterComponent = (
  filter: FilterTypeMobile,
  availableTags: string[],
  categories: CategoryWithSubcategories[],
  defaultFilters: DefaultFilterOptions
) => {
  switch (filter) {
    case FilterType.Contact: {
      return <AccountMobileFilterContact />
    }
    case FilterType.Date: {
      return <AccountMobileFilterDate />
    }
    case FilterType.Amount: {
      return <AccountMobileFilterAmount />
    }
    case FilterType.Category: {
      return <AccountMobileFilterCategory categories={categories} />
    }
    case FilterType.Tags: {
      return <AccountMobileFilterTags availableTags={availableTags} />
    }
    case FilterType.TransactionType: {
      return <AccountMobileFilterTransactionType />
    }
    case FilterType.Status: {
      return <AccountMobileFilterStatus />
    }
    case FilterType.Receipt: {
      return <AccountMobileFilterReceipt />
    }
    case FilterType.Description: {
      return <AccountMobileFilterDescription />
    }
    case FilterTypeExtra.Export: {
      return (
        <FiltersListMobileExportTransactionsItem
          defaultFilters={defaultFilters}
        />
      )
    }
    default:
      return assertUnreachable(filter)
  }
}

export const mapMobileFilter =
  (
    availableTags: string[],
    categories: CategoryWithSubcategories[],
    defaultFilters: DefaultFilterOptions
  ) =>
  (filter: FilterTypeMobile): MobileFilter => {
    return {
      key: filter,
      renderFilter: () =>
        getMobileFilterComponent(
          filter,
          availableTags,
          categories,
          defaultFilters
        ),
    }
  }
