import { Property } from 'csstype'

interface Props {
  children: React.ReactNode
  x?: string | number
  y?: string | number
  fill?: Property.Fill
}

export const BoldText = ({ children, x, y, fill }: Props) => {
  return (
    <text
      x={x}
      y={y}
      dominantBaseline="middle"
      textAnchor="middle"
      style={{
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: 1.33,
        fill,
      }}
    >
      {children}
    </text>
  )
}
