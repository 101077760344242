import { useCallback, useMemo } from 'react'
import { OnboardingFlowType } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { useGetFlowFlagsCombinedForm } from '../hooks/useGetFlowFlagsCombinedForm'
import { getCheckpointFlowConfig } from '../utils/getCheckpointFlowConfig'

export interface GetIsRouteBeforeLastCheckpointArgs {
  flowType?: OnboardingFlowType
  lastCheckpointPath?: string
  componentPath: string
}

export const useIsRouteBeforeLastCheckpoint = () => {
  const flags = useFeatureFlags()
  const flowFlagsCombinedForm = useGetFlowFlagsCombinedForm()
  const checkpointFlowConfig = useMemo(() => {
    return getCheckpointFlowConfig(
      Boolean(flowFlagsCombinedForm),
      flags.CombinedOnboardingFormPhase1,
      flags.CombinedOnboardingFormPhase2
    )
  }, [
    flowFlagsCombinedForm,
    flags.CombinedOnboardingFormPhase1,
    flags.CombinedOnboardingFormPhase2,
  ])

  const getIsRouteBeforeLastCheckpoint = useCallback(
    ({
      flowType,
      lastCheckpointPath,
      componentPath,
    }: GetIsRouteBeforeLastCheckpointArgs) => {
      const currentFlowOrder = checkpointFlowConfig.get(
        flowType ?? OnboardingFlowType.COMPANY
      )

      if (!lastCheckpointPath || !currentFlowOrder) {
        return false
      }

      const lastCheckpointPosition =
        currentFlowOrder.get(lastCheckpointPath)?.position || 0

      const currentRoutePosition =
        currentFlowOrder.get(componentPath)?.position || 0

      return lastCheckpointPosition > currentRoutePosition
    },
    [checkpointFlowConfig]
  )

  return { getIsRouteBeforeLastCheckpoint }
}
