import { BodySmallTypography, H6 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledLabel = styled(H6)`
  line-height: 1.25rem;
  margin: 0;
`

export const StyledValue = styled.div`
  ${BodySmallTypography}
  line-height: 1.25rem;
  text-align: right;
  overflow-wrap: break-word;
  width: 70%;
`

export const StyledColorBadge = styled.div`
  background-color: ${(props: { badgeColor: string }) => props.badgeColor};
  height: 1rem;
  width: 1rem;
  border-radius: 3px;
  margin: 0.25rem;
`

export const StyledAccountDetailSection = styled.div`
  width: 100%;
`
