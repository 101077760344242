import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  virtualTerminal: 'Virtual Terminal',
  description: 'Manually enter card details on any device.',
  managerInfoText:
    'Only Admins can enable or disable Virtual Terminal for a Site.',
  processingFee: 'Processing Fee',
  cardSurcharge: 'Card Surcharge',
  totalFees: 'Surcharge my total fees',
  percentage: 'Surcharge a percentage',
  virtualTerminalSurcharge: 'Virtual Terminal Surcharge',
  surchargeDescription:
    'Under Australian law, a surcharge amount is limited to the direct costs incurred by the merchant. {maxFeePercentText}.',
  feeFixedText:
    'Note that this option does not support surcharging the {feeFixed}c fixed fee.',
  surchargeInfo:
    'Any outstanding transactions will be updated to reflect these changes.',
})
