import { Formik } from 'formik'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import {
  StyledFormFooterWithOffset,
  StyledFormWithOffset,
} from 'pages/GlobalModals/GlobalModals.styled'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useCardholders } from './hooks/useCardholders'
import { useSelectCardholderForm } from './hooks/useSelectCardholderForm'
import { SearchableCardholderList } from './SearchableCardholderList/SearchableCardholderList'
import { SelectCardholderFormValues } from './SelectCardholderForm.types'
import { validateCardholderSchema } from './utils/validation.utils'

export const SelectCardholderForm = () => {
  useMultiStageModalProgressBar({
    newCurrentStep: 3,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })
  const { cardholders, isLoading, error, refetch } = useCardholders()
  const { selectCardholder, goBackToSelectCardType, initialCardholderId } =
    useSelectCardholderForm({
      cardholders,
    })

  return (
    <Formik<SelectCardholderFormValues>
      initialValues={{
        cardholderId: initialCardholderId,
      }}
      onSubmit={selectCardholder}
      validationSchema={validateCardholderSchema}
      validateOnMount
    >
      <StyledFormWithOffset data-testid="select-cardholder-form">
        <SearchableCardholderList
          cardholders={cardholders}
          isLoading={isLoading}
          error={error}
          refetch={() => refetch()}
        />
        <StyledFormFooterWithOffset
          onCancel={goBackToSelectCardType}
          disableOnInvalid
        />
      </StyledFormWithOffset>
    </Formik>
  )
}
