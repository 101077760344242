import { ButtonBasic } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceItemPopperItem = styled(ButtonBasic)<{
  $isDisabled?: boolean
}>`
  border: none;
  color: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.colors.GREY_250 : theme.colors.BLACK_900};
  justify-content: flex-start;
  padding: 12px 12px;
`

export const InvoiceItemPopperRemoveItem = styled(InvoiceItemPopperItem)`
  color: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.colors.GREY_250 : theme.colors.RED_1000};
`
