import { gql } from '@apollo/client'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import {
  GetRecipientDetailsDebitCardAccountTransactionFragment,
  GetRecipientDetailsDebitCardTransactionV2Fragment,
} from './useTransactionDetailsInfo.utils.generated'

export const cardTransactionTypes = [
  DebitCardTransactionTypeV2.ATM_IN,
  DebitCardTransactionTypeV2.ATM_OUT,
  DebitCardTransactionTypeV2.PURCHASE,
  DebitCardTransactionTypeV2.PURCHASE_CNP,
  DebitCardTransactionTypeV2.REFUND_CNP,
  DebitCardTransactionTypeV2.REFUND,
]

export const cardTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.ATM_IN,
  DebitCardAccountTransactionTypeEnum.ATM_OUT,
  DebitCardAccountTransactionTypeEnum.PURCHASE,
  DebitCardAccountTransactionTypeEnum.PURCHASE_CNP,
  DebitCardAccountTransactionTypeEnum.REFUND_CNP,
  DebitCardAccountTransactionTypeEnum.REFUND,
]

export const externalOutgoingTransactionTypes = [
  DebitCardTransactionTypeV2.DE_OUT,
  DebitCardTransactionTypeV2.NPP_OUT,
]

export const externalOutgoingTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.DE_OUT,
  DebitCardAccountTransactionTypeEnum.NPP_OUT,
]

export const externalIncomingTransactionTypes = [
  DebitCardTransactionTypeV2.DE_IN,
  DebitCardTransactionTypeV2.NPP_IN,
]

export const externalIncomingTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.DE_IN,
  DebitCardAccountTransactionTypeEnum.NPP_IN,
]

export const internalTransferTransactionTypes = [
  DebitCardTransactionTypeV2.TRANSFER_IN,
  DebitCardTransactionTypeV2.TRANSFER_OUT,
]

export const internalTransferTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.TRANSFER_IN,
  DebitCardAccountTransactionTypeEnum.TRANSFER_OUT,
]

export const adjustmentTransactionTypes = [
  DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT,
  DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL,
]

export const adjustmentTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT,
  DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL,
]

export const bpayTransactionTypes = [
  DebitCardTransactionTypeV2.BPAY_IN,
  DebitCardTransactionTypeV2.BPAY_OUT,
]

export const bpayTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.BPAY_IN,
  DebitCardAccountTransactionTypeEnum.BPAY_OUT,
]

export const transferOutReversalTransactionTypes = [
  DebitCardTransactionTypeV2.DE_OUT_RETURN,
  DebitCardTransactionTypeV2.NPP_OUT_RETURN,
]

export const transferOutReversalTransactionTypesV3 = [
  DebitCardAccountTransactionTypeEnum.DE_OUT_RETURN,
  DebitCardAccountTransactionTypeEnum.NPP_OUT_RETURN,
]

export const getRecipientDetails = (
  transaction:
    | GetRecipientDetailsDebitCardTransactionV2Fragment
    | GetRecipientDetailsDebitCardAccountTransactionFragment
) => {
  if (
    (transaction.__typename === 'DebitCardAccountTransaction' &&
      [
        DebitCardAccountTransactionTypeEnum.DE_OUT,
        DebitCardAccountTransactionTypeEnum.NPP_OUT,
      ].includes(transaction.type)) ||
    (transaction.__typename === 'DebitCardTransactionV2' &&
      [
        DebitCardTransactionTypeV2.DE_OUT,
        DebitCardTransactionTypeV2.NPP_OUT,
      ].includes(transaction.type))
  ) {
    return {
      bsbNumber: transaction.payeeDetails?.accountDetails?.bsb,
      recipientAccountName: transaction.payeeDetails?.accountDetails?.name,
      recipientAccountNumber: transaction.payeeDetails?.accountDetails?.account,
      referencePayee: transaction.referencePayee,
    }
  }

  if (
    (transaction.__typename === 'DebitCardAccountTransaction' &&
      [
        DebitCardAccountTransactionTypeEnum.DE_IN,
        DebitCardAccountTransactionTypeEnum.NPP_IN,
      ].includes(transaction.type)) ||
    (transaction.__typename === 'DebitCardTransactionV2' &&
      [
        DebitCardTransactionTypeV2.DE_IN,
        DebitCardTransactionTypeV2.NPP_IN,
      ].includes(transaction.type))
  ) {
    return {
      bsbNumber: transaction.payeeDetails?.accountDetails?.bsb,
      recipientAccountName: transaction.payeeDetails?.accountDetails?.name,
      recipientAccountNumber: transaction.payeeDetails?.accountDetails?.account,
      referencePayee: null,
    }
  }

  return {
    bsbNumber: null,
    recipientAccountName: null,
    referencePayee: null,
  }
}

getRecipientDetails.fragments = {
  DebitCardTransactionV2: gql`
    fragment GetRecipientDetailsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      referencePayee
      type
      payeeDetails {
        accountDetails {
          account
          bsb
          name
        }
        bpayDetails {
          nickname
          billerName
          billerCode
          crn
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment GetRecipientDetailsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      referencePayee
      type
      payeeDetails {
        accountDetails {
          account
          bsb
          name
        }
        bpayDetails {
          nickname
          billerName
          billerCode
          crn
        }
      }
    }
  `,
}
