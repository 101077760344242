import dayjs from 'utils/dayjs'

import { DateRangeValue } from '../DateRange.types'

export type Period = keyof typeof periodRanges

const periodRanges = {
  lastThirtyDays: () =>
    ({
      start: dayjs().subtract(30, 'days').startOf('day').toDate(),
      end: dayjs().endOf('day').toDate(),
    } satisfies DateRangeValue),
  thisWeek: () =>
    ({
      start: dayjs().startOf('week').toDate(),
      end: dayjs().endOf('day').toDate(),
    } satisfies DateRangeValue),
  lastWeek: () =>
    ({
      start: dayjs().subtract(1, 'week').startOf('week').toDate(),
      end: dayjs().subtract(1, 'week').endOf('week').toDate(),
    } satisfies DateRangeValue),
  thisMonth: () =>
    ({
      start: dayjs().startOf('month').toDate(),
      end: dayjs().endOf('day').toDate(),
    } satisfies DateRangeValue),
  lastMonth: () =>
    ({
      start: dayjs().subtract(1, 'month').startOf('month').toDate(),
      end: dayjs().subtract(1, 'month').endOf('month').toDate(),
    } satisfies DateRangeValue),
} as const

export const getDateRangeForPeriod = (period: Period) => periodRanges[period]()

export const getPeriodKey = (
  periodRange: DateRangeValue
): Period | undefined => {
  const periodRangeTimeAdjusted = {
    start: dayjs(periodRange.start).startOf('day').toDate(),
    end: dayjs(periodRange.end).endOf('day').toDate(),
  }

  const match = Object.entries(periodRanges).find(([, range]) => {
    const { start, end } = range()

    return (
      start?.getTime() === periodRangeTimeAdjusted.start?.getTime() &&
      end?.getTime() === periodRangeTimeAdjusted.end?.getTime()
    )
  })
  return match && (match[0] as Period)
}
