import { Balance } from 'layouts/TransactionAccountsLayout/Balance/Balance'
import { DesktopBalanceSkeleton } from 'layouts/TransactionAccountsLayout/skeletons/DesktopBalanceSkeleton'

import { useTotalAccountBalance } from '../../../../hooks/useTotalAccountBalance'

export const AccountBalance = () => {
  const { amountInCents, isLoading, error } = useTotalAccountBalance()

  if (isLoading) {
    return <DesktopBalanceSkeleton />
  }

  if (error) {
    return null
  }

  return <Balance totalBalance={amountInCents} />
}
