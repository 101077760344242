import {
  Box,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

import { SectionHeaderSkeleton } from 'components/SectionHeaderSkeleton'

export const TransactionNotesSkeleton = () => {
  return (
    <Box>
      <SectionHeaderSkeleton pb="7px" />
      <SkeletonTypographyBase type={TypographyTypes.BodyDefault} width="100%" />
      <SkeletonTypographyBase type={TypographyTypes.BodyDefault} width="40%" />
    </Box>
  )
}
