import { useCallback } from 'react'
import { ModalBasic } from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { validateInvoiceForm } from 'features/Invoicing/components/Invoices/Invoice/Invoice.utils'
import { useFormikContext } from 'formik'
import { v4 as randomUUID } from 'uuid'

import { translate } from 'utils/translations'

import * as styled from './InvoiceEditFormSendModal.styled'

export const translations = {
  title: translate('page.invoice.sendInvoiceModalTitle'),
  message: translate('page.invoice.sendInvoiceModalMessage'),
  secondaryButton: translate(
    'page.invoice.sendInvoiceModalSecondaryButtonLabel'
  ),
  primaryButton: translate('page.invoice.sendInvoiceModalPrimaryButtonLabel'),
}

interface InvoiceEditFormSendModalProps {
  closeModal: () => void
  isLoading: boolean
  isOpen: boolean
  onCancel: () => void
  onSend: () => void
}

export const InvoiceEditFormSendModal = ({
  closeModal,
  isLoading,
  isOpen,
  onCancel,
  onSend,
}: InvoiceEditFormSendModalProps) => {
  const { initialValues, values } = useFormikContext<InvoiceFormFields>()

  const handleOnClick = useCallback(async () => {
    const errors = await validateInvoiceForm({
      initialValues,
      isFullValidation: true,
      values,
    })

    if (Object.keys(errors).length) {
      closeModal()
      return
    }

    onSend()
  }, [closeModal, initialValues, onSend, values])

  const Message = useCallback(() => {
    return (
      <>
        {translations.message.split('\n\n').map((paragraph) => {
          return <styled.Message key={randomUUID()}>{paragraph}</styled.Message>
        })}
      </>
    )
  }, [])

  return (
    <ModalBasic
      hasCloseButton
      shouldCloseOnOverlayClick={!isLoading}
      isOpen={isOpen}
      title={translations.title}
      onCancel={closeModal}
    >
      <ModalBasic.Body>
        <Message />
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={onCancel}>
          {translations.secondaryButton}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton isLoading={isLoading} onClick={handleOnClick}>
          {translations.primaryButton}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}
