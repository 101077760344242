import { COLOR, stringSlice } from '@npco/zeller-design-system'

import { PersonaliseAccountValues } from '../PersonaliseAccountFields.types'

const colorPickerListEZTA = [
  COLOR.GREY_90,
  COLOR.BLACK_900,
  COLOR.DARK_BLUE,
  COLOR.BLUE_400,
  COLOR.BLUE_700,
  COLOR.GREEN_1000,
  COLOR.GREEN_700,
  COLOR.YELLOW_1000,
  COLOR.RED_1000,
  COLOR.CORAL,
]

export const getColorPickerColorList = () => {
  return colorPickerListEZTA
}

export const getDefaultNewAccountColor = () => {
  const colorList = getColorPickerColorList()
  return colorList[0]
}

export const createNewAccountIcon = ({
  name,
  accountColour,
}: PersonaliseAccountValues) => {
  const firstLetter = stringSlice(name, 0, 1).toUpperCase()
  const selectedColor =
    Object.keys(COLOR).find(
      (colorName) => COLOR[colorName as keyof typeof COLOR] === accountColour
    ) || getDefaultNewAccountColor()
  return {
    colour: selectedColor,
    letter: firstLetter,
    images: [],
  }
}
