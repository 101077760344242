import { gql, type TypedDocumentNode } from '@apollo/client'

export type UseInterestRateDisplayDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    baseInterestRate: string | null
    bonusInterestRate: string | null
  } | null
}

export const UseInterestRateDisplayDebitCardAccountV2FragmentDoc = gql`
  fragment UseInterestRateDisplayDebitCardAccountV2Fragment on DebitCardAccountV2 {
    savingsAccountDetails {
      baseInterestRate
      bonusInterestRate
    }
  }
` as unknown as TypedDocumentNode<
  UseInterestRateDisplayDebitCardAccountV2Fragment,
  undefined
>
