import { useCallback } from 'react'
import {
  Box,
  InfoBox,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'
import { ThirdPartyBankAccount } from 'types/accounts'
import { DeleteAccountModal } from 'components/DeleteAccountModal/DeleteAccountModal'
import {
  StyledAccountPrimaryAction,
  StyledActionsContainer,
} from 'components/DepositAccountDetailDrawer/AccountDetailAction/AccountDetailAction.styled'
import { ModalConfirm } from 'components/Modal/ModalConfirm/ModalConfirm'

import { useSelectExternalBankTile } from '../SimBillingExternalBankTile/SimBillingExternalBankTile.hook'

interface SimBillingExternalAccountActionProps {
  account: ThirdPartyBankAccount
  redirectOnDeleteSuccess: () => void
  isSelectedAccount: boolean
}

export const SimBillingExternalAccountAction = ({
  account,
  redirectOnDeleteSuccess,
  isSelectedAccount,
}: SimBillingExternalAccountActionProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const { isLoading, changeToExternalAccount } = useSelectExternalBankTile({
    onSuccess: () => closeModal(),
  })

  const onConfirm = useCallback(() => {
    changeToExternalAccount(account)
  }, [account, changeToExternalAccount])

  if (isSelectedAccount) {
    return (
      <>
        <Box pt="16px">
          <InfoBox variant={INFOBOX_VARIANT.POSITIVE} size={INFOBOX_SIZE.SMALL}>
            <InfoBox.Message>
              {translate('page.settings.sim.billingAccount.selectedAccount')}
            </InfoBox.Message>
          </InfoBox>
        </Box>
        <DeleteAccountModal
          selectedAccount={account}
          redirectOnDeleteSuccess={redirectOnDeleteSuccess}
        />
      </>
    )
  }

  return (
    <>
      <ModalConfirm
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={onConfirm}
        title={translate('page.settings.sim.billingAccount.modal.title')}
        description={
          translate('page.settings.sim.billingAccount.modal.description', {
            accountType: 'external',
            accountName: account.name,
          }) as string
        }
        isLoading={isLoading}
      />
      <StyledActionsContainer>
        <StyledAccountPrimaryAction onClick={openModal}>
          {translate('page.settings.sim.billingAccount.setAccount')}
        </StyledAccountPrimaryAction>
        <DeleteAccountModal
          redirectOnDeleteSuccess={redirectOnDeleteSuccess}
          selectedAccount={account}
        />
      </StyledActionsContainer>
    </>
  )
}
