import { useState } from 'react'
import { ModalSize } from 'design-system/Components/Modal'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { useModalCloseTransitionContext } from 'pages/GlobalModals/contexts/ModalCloseTransitionContext/useModalCloseTransitionContext'
import { MultiStageModal } from 'components/MultiStageModal'

import { useAddCardState } from './hooks/useAddCardState'
import { useStageTitle } from './hooks/useStageTitle/useStageTitle'
import { CreateCardStages } from './pages/CreateCardStages/CreateCardStages'
import { LandingPageStage } from './pages/LandingPageStage/LandingPageStage'
import { LinkCardStages } from './pages/LinkCardStages/LinkCardStages'

export const AddCardModal = () => {
  const { state } = useAddCardState()
  const modalTitle = useStageTitle(state?.stage)
  const { goToAddCardStage } = useGoToAddCardStage()
  const {
    isModalOpen,
    hasModalCloseTransition,
    executeTransitionFinishedCallback,
  } = useModalCloseTransitionContext()
  const [isCloseButtonHidden, setIsCloseButtonHidden] = useState(false)

  if (state === null) {
    return null
  }

  return (
    <MultiStageModal
      title={modalTitle}
      isOpen={isModalOpen}
      onCancel={() => goToAddCardStage(undefined)}
      hasCloseButton={!isCloseButtonHidden}
      shouldContentFadeOut={hasModalCloseTransition}
      onAfterClose={executeTransitionFinishedCallback}
      size={ModalSize.MEDIUM}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <MultiStageModal.ProgressBarProvider
        initialCurrentStep={0}
        initialTotalSteps={TOTAL_ADD_CARD_STAGES}
      >
        <MultiStageModal.ProgressBar
          pr={['24px', '32px']}
          my="12px"
          dataTestId="progress-bar"
        />
        <LandingPageStage />
        <LinkCardStages />
        <CreateCardStages setIsCloseButtonHidden={setIsCloseButtonHidden} />
      </MultiStageModal.ProgressBarProvider>
    </MultiStageModal>
  )
}
