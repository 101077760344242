import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'
import { CloseAccountLocationSchema } from 'features/CloseAccount/types/CloseAccountState.types'

export const useCloseAccountState = () => {
  const locationState = useLocationState(CloseAccountLocationSchema)

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.CloseAccountModal
  }, [locationState])

  return { state }
}
