import { useCallback } from 'react'
import { ApolloError, useApolloClient } from '@apollo/client'
import {
  DebitCardTransactionStatusV2,
  FundsTransferFrequency,
  FundsTransferPayeeType,
  PaymentInstrumentType,
  TransferErrorCode,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { getIsMFAError } from 'features/MFA'

import { decreaseAccountBalance } from 'utils/banking/cacheAccountBalance/cacheAccountBalance'
import {
  DAILY_TRANSFER_LIMIT_EXCEEDED,
  MFA_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'

import { useSendContactTransferMutation } from './graphql/SendContactTransferMutation.generated'
import { ContactTransferArguments } from './useSendContactTransfer.types'

export const FALLBACK_TO_DE = 'fallback to DE'

export const useSendContactTransfer = () => {
  const [sendTransfer, { loading }] = useSendContactTransferMutation()
  const entityUuid = useSelectedEntityUuid()

  const { cache } = useApolloClient()

  const sendContactTransfer = useCallback(
    async ({
      payerAccountUuid,
      payeeAccountUuid,
      payerReference,
      payeeReference,
      amountInCents,
      forceDeOut,
    }: ContactTransferArguments) => {
      try {
        const result = await sendTransfer({
          variables: {
            entityUuid,
            input: {
              payerAccountUuid,
              payeeType: FundsTransferPayeeType.EXTERNAL,
              paymentInstrumentType: PaymentInstrumentType.BSB,
              payeeAccountUuid,
              payerReference,
              payeeReference,
              amount: {
                value: amountInCents.toString(),
                currency: 'AUD',
              },
              frequency: FundsTransferFrequency.IMMEDIATE,
              forceDeOut,
            },
          },
        })

        if (
          result.data?.transferFundsDebitCardAccount?.error ===
          TransferErrorCode.DAILY_TRANSFER_LIMIT_EXCEEDED
        ) {
          return DAILY_TRANSFER_LIMIT_EXCEEDED
        }

        if (result.data?.transferFundsDebitCardAccount.fallbackToDe) {
          return FALLBACK_TO_DE
        }

        if (
          !result.data?.transferFundsDebitCardAccount.id ||
          [
            DebitCardTransactionStatusV2.DECLINED,
            DebitCardTransactionStatusV2.CANCELLED,
          ].includes(result.data?.transferFundsDebitCardAccount.status)
        ) {
          throw new Error(
            'Mutation response for SendContactTransfer failed with undefined id or declined/cancelled status'
          )
        }

        decreaseAccountBalance({
          cache,
          id: payerAccountUuid,
          valueInCents: amountInCents,
        })

        return result.data.transferFundsDebitCardAccount
      } catch (err) {
        if (
          typeof err === 'object' &&
          (err as ApolloError).graphQLErrors &&
          getIsMFAError((err as ApolloError).graphQLErrors)
        ) {
          return MFA_ERROR
        }

        return UNEXPECTED_ERROR
      }
    },
    [sendTransfer, entityUuid, cache]
  )

  return { sendContactTransfer, isSendingContactTransfer: loading }
}
