import { useCallback } from 'react'

import { FileWithId } from 'components/File'

import { displayRemovalCompletionMessage } from '../utils/displayRemovalCompletionMessage'
import { useDeleteTransactionReceipt } from './useDeleteTransactionReceipt'

interface Arguments {
  transactionUuid: string
}

interface ResultsSplit {
  successful: FileWithId[]
  failed: FileWithId[]
}

export const useRemoveFiles = ({ transactionUuid }: Arguments) => {
  const { deleteTransactionReceipt } = useDeleteTransactionReceipt()

  const removeImages = useCallback(
    async (filesToRemove: FileWithId[]) => {
      const removePromises = filesToRemove.map(({ file }) =>
        deleteTransactionReceipt(file.name, transactionUuid)
      )

      const results = await Promise.allSettled(removePromises)

      const { successful, failed } = results.reduce<ResultsSplit>(
        (acc, { status }, i) => {
          if (status === 'fulfilled') {
            return {
              ...acc,
              successful: [...acc.successful, filesToRemove[i]],
            }
          }

          return {
            ...acc,
            failed: [...acc.failed, filesToRemove[i]],
          }
        },
        {
          successful: [],
          failed: [],
        }
      )

      displayRemovalCompletionMessage(successful.length, failed.length)

      return { successful, failed }
    },
    [deleteTransactionReceipt, transactionUuid]
  )

  return { removeImages }
}
