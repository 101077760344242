import { ReactNode } from 'react'
import { TooltipBasic } from '@npco/zeller-design-system'

interface OnboardingToolTipProps {
  children: ReactNode
  description: string
}

export const OnboardingStatusToolTip = ({
  children,
  description,
}: OnboardingToolTipProps) => {
  if (!description) {
    return <>{children}</>
  }

  return (
    <TooltipBasic
      renderTrigger={({ ref, handlers }) => (
        <span ref={ref} {...handlers}>
          {children}
        </span>
      )}
    >
      {description}
    </TooltipBasic>
  )
}
