import { ErrorLogger } from '@npco/utils-error-logger'

export const getFileType = ({ fileType }: { fileType?: string }) => {
  switch (fileType) {
    case 'image/png':
      return 'image/png'
    case 'image/jpeg':
    case 'image/jpg':
      return 'image/jpeg'
    default:
      return 'image/webp'
  }
}

type UploadImageToS3BucketProps = {
  formFields: Record<string, string>
  imageUrl: string
  s3BucketUrl: string
  onSuccess: () => void
  onError: () => void
  fileType?: string
  shouldStartWithContentType?: boolean
}

export const uploadImageToS3Bucket = async ({
  formFields,
  imageUrl,
  s3BucketUrl,
  onSuccess,
  onError,
  fileType,
  shouldStartWithContentType = false,
}: UploadImageToS3BucketProps) => {
  try {
    const formData = new FormData()

    if (shouldStartWithContentType) {
      const type = getFileType({ fileType })

      // This is needed in some use cases to prevent a backend error with message (Catalogue/Category image upload)
      // Invalid according to Policy: Policy Condition failed: ["starts-with", "$Content-Type", "image/"]
      // But some other use cases like ContactProfilePicture upload gives error if this is added, hence adding conditionally
      formData.append('Content-Type', type)
    }

    // NOTE: attach form fields with authorization params from aws
    Object.keys(formFields).forEach((prop) =>
      formData.append(prop, formFields[prop])
    )
    const response = await fetch(imageUrl, { method: 'GET' })
    const blob = await response.blob()

    formData.append('file', blob)

    await fetch(s3BucketUrl, {
      method: 'POST',
      body: formData,
    })
      .then((data) => {
        // Using data.status instead of data.ok to be able to test this using fetch-mock-jest
        if (data.status >= 200 && data.status <= 299) {
          onSuccess()
          return undefined
        }
        return data.text()
      })
      .then((text) => {
        if (text?.includes('<Code>EntityTooLarge</Code>')) {
          onError()
        }
      })
  } catch (err) {
    ErrorLogger.report('[Core] Upload to bucket', err, { s3BucketUrl })
    onError()
  }
}
