import { COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ListWell = styled.div`
  display: flex;
  background: ${COLOR.GREY_20};
  border-radius: 8px;
  padding: 24px;
  gap: 8px;
`
