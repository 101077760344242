import { COLOR } from '@npco/zeller-design-system'

import type { ItemContactOrBiller } from '../../../useBPayTargetItems'
import type { GetBillersByCodeQueryResponse } from '../../GetBillersByCode.generated'

export const getBillerItemsFromResponse = (
  responseBillers: GetBillersByCodeQueryResponse['searchBpayBillers']['bpayBillers']
): ItemContactOrBiller[] => {
  return responseBillers.map(
    (biller): ItemContactOrBiller => ({
      type: 'Biller',
      name: biller.billerName,
      nickname: '',
      code: biller.billerCode,
      icon: {
        colour: COLOR.GREY_250,
        image: null,
        letter: biller.billerName.charAt(0),
        images: null,
      },
    })
  )
}
