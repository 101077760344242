import {
  AvatarBasic,
  Flex,
  Heading,
  PillBasic,
  PillSize,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { AddressMap } from 'components/AddressMap'

import { DetailField } from './DetailField'

export const ContactDetailsPlaceholder = () => {
  return (
    <Flex
      flexDirection="column"
      mb="16px"
      opacity={0.5}
      data-testid="contact-details-placeholder"
    >
      <Flex alignItems="center" mb="16px" mr="16px">
        <Flex mr="16px">
          <AvatarBasic text="A" />
        </Flex>
        <Heading.H3>
          {translate('page.transactions.contact.contactName')}
        </Heading.H3>
      </Flex>
      <AddressMap location={null} />
      <Flex flexDirection="column">
        <DetailField
          label={translate('page.contact.sections.generalDetails.address')}
          value="-"
        />
        <DetailField
          label={translate('page.contact.sections.generalDetails.phone')}
          value="-"
        />
        <DetailField
          label={translate(
            'page.contact.sections.generalDetails.combinedLabel'
          )}
          value="-"
        />
        <DetailField
          label={translate('page.contact.sections.generalDetails.category')}
          value={
            <PillBasic
              text={translate(
                'page.contact.sections.generalDetails.uncategorised'
              )}
              size={PillSize.Small}
              editButtonText={translate('shared.edit')}
              closeIconAlt={translate('shared.close')}
            />
          }
        />
      </Flex>
    </Flex>
  )
}
