import { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { COLOR, Typography } from '@npco/zeller-design-system'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { AuthLoadingPage } from 'pages/AuthLoadingPage/AuthLoadingPage'

import { logoutInactivityTimeoutTranslations } from './LogoutInactivityTimeout.i18n'

export const LogoutInactivityTimeout = () => {
  const t = useTranslations(logoutInactivityTimeoutTranslations)
  const { search, pathname } = useLocation()
  const { loginWithInactiveTimeout, logout } = useZellerAuthenticationContext()
  const redirect = new URLSearchParams(search).get('redirect') ?? ''
  const isReturningFromLogout =
    new URLSearchParams(search).get('isReturningFromLogout') === 'true' || false

  useEffect(() => {
    if (!isReturningFromLogout) {
      logout({
        returnTo: `${window.location.origin}${pathname}?redirect=${redirect}&isReturningFromLogout=true`,
      })
    } else {
      loginWithInactiveTimeout(redirect)
    }
  }, [
    logout,
    loginWithInactiveTimeout,
    isReturningFromLogout,
    pathname,
    redirect,
  ])

  return (
    <AuthLoadingPage>
      {[
        <Typography
          key="inactivity-logging-out"
          as="div"
          variant="body-base"
          color={COLOR.BLACK_900}
        >
          {t('loadingMessage')}
        </Typography>,
      ]}
    </AuthLoadingPage>
  )
}
