import { CategoryDisplay } from 'components/CategoryDisplay/CategoryDisplay'

interface SubcategoryDisplayProps {
  isLoadingSubcategory: boolean
  hasError: boolean
  subcategoryName: string | undefined
  onEdit?: () => void
  canEditCategories?: boolean
}

export const SubcategoryItemDisplay = ({
  isLoadingSubcategory,
  hasError,
  subcategoryName,
  onEdit,
  canEditCategories = true,
}: SubcategoryDisplayProps) => {
  const shouldEdit = canEditCategories ? onEdit : undefined

  return (
    <CategoryDisplay
      isLoadingSubcategory={isLoadingSubcategory}
      hasError={hasError}
      subcategoryName={subcategoryName}
      onEdit={shouldEdit}
    />
  )
}
