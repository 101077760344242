import { useTranslations } from '@npco/utils-translations'
import { Flex, Message, MessageSize, Spinner } from '@npco/zeller-design-system'

import { translations } from './ExternalAccountLoading.i18n'

export const ExternalAccountLoading = () => {
  const t = useTranslations(translations)

  return (
    <Flex
      height="100%"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      data-testid="external-account-loading"
    >
      <Message
        image={<Spinner />}
        size={MessageSize.LARGE}
        title={t('title')}
        description={t('description')}
      />
    </Flex>
  )
}
