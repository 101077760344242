import { Flex, SkeletonBasic } from '@npco/zeller-design-system'

export const ViewContactSectionLoader = () => {
  return (
    <Flex flexDirection="column" gridGap="18px">
      <Flex justifyContent="space-between">
        <SkeletonBasic width={200} height={20} isRounded />
        <SkeletonBasic width={63} height={12} isRounded />
      </Flex>
      <SkeletonBasic width={148} height={12} isRounded />
    </Flex>
  )
}
