import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import {
  COLOR,
  Flex,
  ListCardButton,
  showErrorToast,
  SvgIcon,
  Typography,
  useModalState,
} from '@npco/zeller-design-system'
import { ModalBasic } from 'design-system/Components/Modal'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { PERMISSIONS } from 'const/permissions'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { Customer } from 'types/customers'
import { GetCustomerDetails_getCustomer as CustomerDetails } from 'types/gql-types/GetCustomerDetails'
import { withPermissionGuard } from 'services/withPermissionGuard/withPermissionGuard'
import { AddEntityModal } from 'components/AddEntityModal'

import { useGetUsersForSiteQuery } from '../graphql/getUsersForSite.generated'
import { useUsersToAssignForSite } from './AddUserButton.hooks'

export const AddUserButtonDeps = {
  AddEntityModal,
}

const AddUserButton = () => {
  const { AddEntityModal } = AddUserButtonDeps
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const { isModalOpen, openModal, closeModal } = useModalState()
  const entityUuid = useSelectedEntityUuid()
  const { loading, data } = useGetUsersForSiteQuery({
    variables: { entityUuid },
    onError: showErrorToast,
  })
  const allUsers = useMemo(() => data?.getCustomers ?? [], [data?.getCustomers])
  const usersToAssign = useUsersToAssignForSite(allUsers as Customer[])

  const addUserIcon = useMemo(() => {
    const color = loading ? COLOR.GREY_400 : COLOR.BLUE_1000
    return (
      <Flex
        width="40px"
        height="40px"
        justifyContent="center"
        borderRadius="4px"
        border={`1px solid ${color}`}
      >
        <SvgIcon width="16" height="16" color={color}>
          <Plus />
        </SvgIcon>
      </Flex>
    )
  }, [loading])

  if (loading) {
    return (
      <ListCardButton
        title={
          <Typography data-testid="loading" color={COLOR.GREY_400}>
            {translate('page.settings.users.addUser')}
          </Typography>
        }
        readonly
        image={() => addUserIcon}
      />
    )
  }
  if (!data?.getCustomers?.length) {
    return (
      <ListCardButton
        onClick={() =>
          navigate(
            ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.USERS.CREATE_TYPE
              .path
          )
        }
        title={
          <Typography color={COLOR.BLUE_1000}>
            {translate('page.settings.users.addUser')}
          </Typography>
        }
        image={() => addUserIcon}
      />
    )
  }

  return (
    <>
      <ListCardButton
        title={
          <Typography color={COLOR.BLUE_1000}>
            {translate('page.settings.users.addUser')}
          </Typography>
        }
        onClick={openModal}
        image={() => addUserIcon}
      />
      <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
        <AddEntityModal
          closeModal={closeModal}
          entities={usersToAssign as CustomerDetails[]}
        />
      </ModalBasic>
    </>
  )
}

export default withPermissionGuard(PERMISSIONS.SITE.USERS.ADD)(AddUserButton)
