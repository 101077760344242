import { ReactNode } from 'react'

import { StyledListWrapper } from './AccountTileList.styled'
import { BankAccount, RightControlProps } from './AccountTileList.type'
import { DepositAccountListItem } from './DepositAccountListItem'

interface Props<T> {
  onSelect: (account: T) => any
  bankAccounts: T[]
  getTileTitle: (account: T) => string
  getTileIcon: (account: T) => ReactNode
  getTileDescription?: (account: T) => ReactNode
  getIsDisabled?: (account: T) => boolean
  getIsActive: (account: T) => boolean
  renderRightControl?: (rightControlProps: RightControlProps) => React.ReactNode
  dataTestId?: string
}

export const AccountTileList = <T extends BankAccount>({
  onSelect,
  bankAccounts,
  getTileTitle,
  getTileIcon,
  getTileDescription,
  getIsDisabled,
  getIsActive,
  renderRightControl,
  dataTestId,
}: Props<T>) => {
  return (
    <StyledListWrapper data-testid={dataTestId}>
      {bankAccounts.map((account) => (
        <DepositAccountListItem
          key={account.id}
          name={getTileTitle(account)}
          isActive={getIsActive(account)}
          onSelect={() => {
            onSelect(account)
          }}
          isDisabled={getIsDisabled?.(account)}
          graphicIndicator={getTileIcon(account)}
          description={getTileDescription?.(account)}
          renderRightControl={renderRightControl}
        />
      ))}
    </StyledListWrapper>
  )
}
