import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetUnreadNotificationCountQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetUnreadNotificationCountQueryResponse = {
  __typename?: 'Query'
  getUnreadNotificationCount: number
}

export const GetUnreadNotificationCount = gql`
  query GetUnreadNotificationCount($entityUuid: ID!) {
    getUnreadNotificationCount(entityUuid: $entityUuid)
  }
` as unknown as TypedDocumentNode<
  GetUnreadNotificationCountQueryResponse,
  GetUnreadNotificationCountQueryVariables
>

/**
 * __useGetUnreadNotificationCountQuery__
 *
 * To run a query within a React component, call `useGetUnreadNotificationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationCountQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetUnreadNotificationCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnreadNotificationCountQueryResponse,
    GetUnreadNotificationCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUnreadNotificationCountQueryResponse,
    GetUnreadNotificationCountQueryVariables
  >(GetUnreadNotificationCount, options)
}
export function useGetUnreadNotificationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnreadNotificationCountQueryResponse,
    GetUnreadNotificationCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUnreadNotificationCountQueryResponse,
    GetUnreadNotificationCountQueryVariables
  >(GetUnreadNotificationCount, options)
}
export type GetUnreadNotificationCountQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationCountQuery
>
export type GetUnreadNotificationCountLazyQueryHookResult = ReturnType<
  typeof useGetUnreadNotificationCountLazyQuery
>
export type GetUnreadNotificationCountQueryResult = Apollo.QueryResult<
  GetUnreadNotificationCountQueryResponse,
  GetUnreadNotificationCountQueryVariables
>
