import { type ReactElement } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Message } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import type { DevicesFilterInputProps } from '../DevicesFilterInput'
import { translations } from './DevicesFilterInputError.i18n'

export const DevicesFilterInputError = ({
  onRetry,
}: DevicesFilterInputProps.Items.Error): ReactElement => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  return (
    <Message
      title={t('title')}
      description={t('description')}
      primaryButton={{ label: tShared('retry'), onClick: onRetry }}
    />
  )
}
