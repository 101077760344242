import { gql } from '@apollo/client'

export const CustomerNameQuery = gql`
  query CustomerNameQuery($entityUuid: ID!, $customerUuid: ID!) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      firstname
      lastname
    }
  }
`
