import { SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SvgIconWithBoxShadow = styled(SvgIcon)<{
  height?: string
  width?: string
}>`
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.08);
  height: ${({ height }) => height || '210px'};
  width: ${({ width }) => width || '333px'};
`
