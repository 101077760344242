import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  customisationDescription:
    'You can now choose between various templates, select a branded accent, use your own logo and write a custom welcome note for all your Invoices.',
  customisationHeading: 'Customise Your Invoices',
  scheduleSendDescription:
    "With Zeller Invoicing, you can now effortlessly create an Invoice and schedule it to be sent to your customer on a specified future date. It's that simple.",
  scheduleSendHeading: 'Schedule Invoices for Later',
  sendViaSmsDescription:
    "We've been working hard behind the scenes to enhance our Invoicing experience, allowing you to now send Invoices via email and text message (SMS).",
  sendViaSmsHeading: 'Send Invoices via SMS',
})
