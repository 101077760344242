import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface JobTitleItemProps {
  jobTitle: string | null | undefined
}

export const JobTitleItem = ({ jobTitle }: JobTitleItemProps) => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.jobTitle')}
      value={jobTitle || '-'}
    />
  )
}
