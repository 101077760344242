import { gql, type TypedDocumentNode } from '@apollo/client'

export type AccountAmountItemDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  balance: { __typename?: 'Money'; value: string } | null
}

export const AccountAmountItemDebitCardAccountV2FragmentDoc = gql`
  fragment AccountAmountItemDebitCardAccountV2Fragment on DebitCardAccountV2 {
    balance {
      value
    }
  }
` as unknown as TypedDocumentNode<
  AccountAmountItemDebitCardAccountV2Fragment,
  undefined
>
