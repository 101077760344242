import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useUnpairOraclePosMutation } from './graphql/unpairOraclePos.generated'
import { updateCacheOnUnpair } from './utils/updateCacheOnUnpair'

type UseUnpairOraclePosProps = {
  deviceUuid: string
  onSuccess: () => void
  onError: () => void
}

export const useUnpairOraclePos = ({
  deviceUuid,
  onError,
  onSuccess,
}: UseUnpairOraclePosProps) => {
  const entityUuid = useSelectedEntityUuid()

  const [unpairOraclePosMutation, { loading: isUnpairingOraclePos }] =
    useUnpairOraclePosMutation({
      variables: { entityUuid, config: { deviceUuid } },
      onCompleted: (data) => {
        if (data?.unpairOraclePos) {
          onSuccess()
        } else {
          onError()
        }
      },
      errorPolicy: 'all',
      onError: () => onError(),
      update: (cache, { data }) => {
        if (data?.unpairOraclePos) {
          updateCacheOnUnpair({ cache, id: deviceUuid })
        }
      },
    })

  return {
    unpairOraclePos: unpairOraclePosMutation,
    isUnpairingOraclePos,
  }
}
