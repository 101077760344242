import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { GetIconImageUrlBySizeIconFragmentDoc } from './getIconImageUrlBySize/getIconImageUrlBySize.generated'

export type GetImageUrlIconFragment = {
  __typename?: 'Icon'
  image: string | null
  images: Array<{
    __typename?: 'Image'
    url: string
    size: Types.ImageSize
  }> | null
}

export const GetImageUrlIconFragmentDoc = gql`
  fragment GetImageUrlIconFragment on Icon {
    image
    ...GetIconImageUrlBySizeIconFragment
  }
  ${GetIconImageUrlBySizeIconFragmentDoc}
` as unknown as TypedDocumentNode<GetImageUrlIconFragment, undefined>
