import { ButtonGhost, Divider } from '@npco/zeller-design-system'
import { ModalBasic } from 'design-system/Components/Modal'
import styled from 'styled-components'

export const StyledModalBasic = styled(ModalBasic)`
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  padding: 0px;
  top: 100%;
  transform: translate(-50%, -100%);
  width: 100%;
`
export const StyledButtonGhost = styled(ButtonGhost)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  height: 4.5rem;
  width: 100%;
`

export const StyledDivider = styled(Divider)`
  margin: 0;
`
