import { gql } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { useLogout } from 'hooks/useLogout'
import { useSubscription } from 'hooks/useSubscription'
import {
  IdentityForcedLogoff as IdentityForcedLogoffResponse,
  IdentityForcedLogoffVariables,
} from 'types/gql-types/IdentityForcedLogoff'

export const IdentityForcedLogoff = gql`
  subscription IdentityForcedLogoff($customerUuid: ID!) {
    identityForcedLogoff(customerUuid: $customerUuid) {
      customerUuid
      reason
    }
  }
`

export const useIdentityForcedLogoff = () => {
  const { userData } = useLoggedInUser()
  const { logout } = useLogout()
  const customerUuid = userData?.id ?? ''

  useSubscription<IdentityForcedLogoffResponse, IdentityForcedLogoffVariables>(
    IdentityForcedLogoff,
    {
      variables: {
        customerUuid,
      },
      skip: !customerUuid,
      onData: ({ data: subscriptionResult }) => {
        const response = subscriptionResult?.data?.identityForcedLogoff

        if (response?.reason) {
          logout()
        }
      },
    }
  )
}
