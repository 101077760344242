import {
  Box,
  COLOR,
  Flex,
  TickIcon,
  Typography,
} from '@npco/zeller-design-system'

import * as styled from './EmptyStateCard.styled'

type EmptyStateCardProps = { heading: React.ReactNode; valuePoints: string[] }
export const EmptyStateCard = ({
  heading,
  valuePoints,
}: EmptyStateCardProps) => {
  return (
    <styled.Container>
      <Box width="100%" alignItems="center">
        <Typography
          variant="heading-lg"
          color={COLOR.BLUE_1000}
          textAlign="center"
        >
          {heading}
        </Typography>
      </Box>
      <styled.ListContainer>
        {valuePoints.map((point) => {
          return (
            <Flex key={point} gap="8px">
              <TickIcon width="16" height="16" />
              <Typography variant="body-base" textAlign="start">
                {point}
              </Typography>
            </Flex>
          )
        })}
      </styled.ListContainer>
    </styled.Container>
  )
}
