import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  MessageWithIcon,
  SomethingWentWrong,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { accountStatementsErrorTranslations as translations } from './AccountStatementsError.i18n'

interface AccountStatementsErrorProps {
  onRetry?: () => void
}

export const AccountStatementsError = ({
  onRetry,
}: AccountStatementsErrorProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  return (
    <Box data-testid="account-statements-error">
      <MessageWithIcon
        title={t('title')}
        image={<SomethingWentWrong size="large" />}
        primaryButton={
          <ButtonFill size={BUTTON_SIZE.SMALL} onClick={onRetry}>
            {tShared('tryAgain')}
          </ButtonFill>
        }
      />
    </Box>
  )
}
