import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  Box,
  Flex,
  showApiErrorToast,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { RemoveSiteLogo } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { RemoveSiteLogoVariables } from 'types/gql-types/RemoveSiteLogo'
import { ReceiptProps } from 'types/settings'
import { SiteCache } from 'types/site'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
} from 'components/Modal/ModalElements/ModalElements'
import { page, shared } from 'translations'

interface Props {
  handleRemoveLogo: () => void
  isModalOpen: boolean
  closeModal: () => void
}

export const RemoveLogoModal = ({
  handleRemoveLogo,
  closeModal,
  isModalOpen,
}: Props) => {
  const siteDetails = rvSiteDetails() as SiteCache
  const entityUuid = useSelectedEntityUuid()
  const [removeSiteLogo, { loading }] = useMutation<
    { removeSiteLogo: boolean },
    RemoveSiteLogoVariables
  >(RemoveSiteLogo, {
    variables: {
      entityUuid,
      siteUuid: siteDetails.id,
    },
    onCompleted: (data) => {
      if (data.removeSiteLogo) {
        const oldSiteDetails = rvSiteDetails() as SiteCache
        rvSiteDetails({
          ...oldSiteDetails,
          receipt: {
            ...(oldSiteDetails.receipt as ReceiptProps),
            logo: '',
          },
        })
        showSuccessToast(page.settings.receipt.logoRemoved)
        handleRemoveLogo()
      } else {
        showErrorToast()
      }
      closeModal()
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  return (
    <ModalBasic
      title={shared.remove}
      isOpen={isModalOpen}
      onCancel={closeModal}
    >
      <Box>
        <ModalDescription>
          {page.settings.receipt.removeLogoConfirmation}
        </ModalDescription>
        <Flex>
          <ConfirmButton isLoading={loading} onClick={removeSiteLogo}>
            {shared.confirm}
          </ConfirmButton>
          <CancelButton onClick={closeModal}>{shared.cancel}</CancelButton>
        </Flex>
      </Box>
    </ModalBasic>
  )
}
