import { Formik } from 'formik'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import {
  StyledFormBodyWithOffset,
  StyledFormFooterWithOffset,
  StyledFormWithOffset,
} from 'pages/GlobalModals/GlobalModals.styled'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { SelectCardFormatFields } from './fields/SelectCardFormatFields'
import { useSelectCardFormatForm } from './hooks/useSelectCardFormatForm'
import { SelectCardFormatFormValues } from './SelectCardFormatForm.types'

export const SelectCardFormatForm = () => {
  const {
    goToSetCardSettingsStage,
    goBackToSelectAccountStage,
    initialCardFormat,
  } = useSelectCardFormatForm()
  useMultiStageModalProgressBar({
    newCurrentStep: 5,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })

  return (
    <Formik<SelectCardFormatFormValues>
      validateOnMount
      initialValues={{ cardFormat: initialCardFormat }}
      onSubmit={goToSetCardSettingsStage}
    >
      <StyledFormWithOffset data-testid="select-card-format-stage">
        <StyledFormBodyWithOffset>
          <SelectCardFormatFields />
        </StyledFormBodyWithOffset>
        <StyledFormFooterWithOffset
          onCancel={goBackToSelectAccountStage}
          disableOnInvalid
        />
      </StyledFormWithOffset>
    </Formik>
  )
}
