import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

export const useLinkEMLCardInfoStage = () => {
  const { goToAddCardStage } = useGoToAddCardStage()

  const createNewCard = useCallback(() => {
    goToAddCardStage({
      stage: AddCardStage.CreateSelectCardTypeStage,
    })
  }, [goToAddCardStage])

  const handleDismiss = useCallback(
    () => goToAddCardStage(undefined),
    [goToAddCardStage]
  )

  return {
    createNewCard,
    handleDismiss,
  }
}
