import { useTranslations } from '@npco/utils-translations'
import { Box, DrawerBasic } from '@npco/zeller-design-system'
import { AccountsTransactionsMetadataProvider } from 'features/AccountsTransactions'

import { ContactDetails } from './ContactDetails/ContactDetails'
import { contactDrawerTranslations } from './ContactDrawer.i18n'
import { ContactDrawerTabs } from './ContactDrawerTabs/ContactDrawerTabs'
import { useContactDrawerTabs } from './ContactDrawerTabs/hooks/useContactDrawerTabs/useContactDrawerTabs'
import { ContactTransactions } from './ContactTransactions/ContactTransactions'

type ContactDrawerProps = {
  contactUuid: string | null
  onClose: () => void
  timezone: string | undefined
}

export const ContactDrawer = ({
  contactUuid,
  onClose,
  timezone,
}: ContactDrawerProps) => {
  const t = useTranslations(contactDrawerTranslations)
  const { currentTab, setCurrentTab } = useContactDrawerTabs()

  return (
    <DrawerBasic
      isOpen={!!contactUuid}
      overlayClassName="animated-drawer-overlay"
      closeTimeoutMS={300}
      onClose={onClose}
      title={t('title')}
      headerContent={
        <ContactDrawerTabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      }
    >
      <Box width="100%">
        {currentTab === 'Details' && contactUuid && (
          <ContactDetails contactUuid={contactUuid} />
        )}
        {currentTab === 'Transactions' && contactUuid && (
          <AccountsTransactionsMetadataProvider>
            <ContactTransactions
              contactUuid={contactUuid}
              timezone={timezone}
            />
          </AccountsTransactionsMetadataProvider>
        )}
      </Box>
    </DrawerBasic>
  )
}
