import { gql } from '@apollo/client'

export const GetXeroSurchargeSettings = gql`
  query GetXeroSurchargeSettings($entityUuid: ID!) {
    getXeroSiteSettings(entityUuid: $entityUuid) {
      __typename
      id
      name
      surchargesTaxes {
        feesSurchargeXinv {
          feeFixed
          feeFixedIntl
          feePercent
          feePercentIntl
        }
      }
    }
  }
`
