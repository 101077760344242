import { RefObject, useMemo, useRef } from 'react'
import { usePopper } from 'react-popper'
import { zIndexMap } from '@npco/zeller-design-system'
import { ModifierArguments, ModifierPhases, Placement } from '@popperjs/core'
import maxSize from 'popper-max-size-modifier'

const VIRTUAL_PADDING = 30

export const getModifiers = (
  declaredWidth: string | undefined,
  adjustHeight: boolean | undefined = false
) => [
  { name: 'preventOverflow', enabled: false },
  { name: 'hide', enabled: false },
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
  {
    name: 'addZIndex',
    enabled: true,
    phase: 'beforeWrite' as ModifierPhases,
    requires: ['computeStyles'],
    fn: () => undefined,
    effect: ({ state }: ModifierArguments<Record<string, unknown>>) => {
      const effectState = state
      effectState.elements.popper.style.zIndex = zIndexMap.popper
    },
  },
  {
    name: 'sameWidth',
    enabled: declaredWidth === undefined,
    phase: 'beforeWrite' as ModifierPhases,
    requires: ['computeStyles'],
    fn: ({ state }: ModifierArguments<Record<string, unknown>>) => {
      const effectState = state
      effectState.styles.popper.width = `${state.rects.reference.width}px`
    },
    effect: ({ state }: ModifierArguments<Record<string, unknown>>) => {
      const effectState = state
      const reference = state.elements.reference as HTMLDivElement
      effectState.elements.popper.style.width = `${reference.offsetWidth}px`
    },
  },
  {
    ...maxSize,
    enabled: Boolean(adjustHeight),
    options: {
      padding: VIRTUAL_PADDING,
    },
  },
  {
    name: 'applyMaxSize',
    enabled: Boolean(adjustHeight),
    phase: 'beforeWrite' as ModifierPhases,
    requires: ['maxSize'],
    fn: ({ state }: ModifierArguments<Record<string, unknown>>) => {
      const effectState = state
      const { height } = state.modifiersData.maxSize
      effectState.styles.popper.maxHeight = `${height}px`
    },
    effect: () => undefined,
  },
  {
    name: 'flip',
    options: {
      padding: {
        bottom: VIRTUAL_PADDING,
      },
    },
  },
  {
    name: 'transitionend',
    enabled: true,
    phase: 'beforeWrite' as ModifierPhases,
    fn: () => undefined,
    effect: ({ instance }: ModifierArguments<Record<string, unknown>>) => {
      let timeout: number | undefined

      // NOTE: https://gomakethings.com/debouncing-events-with-requestanimationframe-for-better-performance/
      const callback = () => {
        if (timeout) {
          window.cancelAnimationFrame(timeout)
        }

        timeout = window.requestAnimationFrame(instance.update)
      }

      window.addEventListener('transitionend', callback, false)

      return () => {
        window.removeEventListener('transitionend', callback)
      }
    },
  },
]

export const usePopperConfig = (
  placement: Placement = 'bottom-start',
  declaredWidth: string | undefined = undefined,
  wrapperRef: RefObject<HTMLDivElement> | null = null,
  adjustHeight = false
) => {
  const menuElementRef = useRef<HTMLUListElement>(null)
  const wrapperElementRef = useRef<HTMLDivElement>(null)

  const config = useMemo(
    () => ({
      modifiers: getModifiers(declaredWidth, adjustHeight),
      placement,
    }),
    [adjustHeight, declaredWidth, placement]
  )

  const { styles, attributes, update } = usePopper(
    wrapperRef?.current ?? wrapperElementRef.current,
    menuElementRef.current,
    config
  )

  return {
    update,
    menuAttributes: {
      style: styles.popper,
      ...attributes.popper,
    },
    wrapperElementRef,
    menuElementRef,
  }
}
