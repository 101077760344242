import { TableData } from '@npco/ui-table'

import { CardAccountCell } from '../components/CardAccountCell/CardAccountCell'
import { DebitCardBalanceCell } from '../components/CardBalanceCell/DebitCardBalanceCell'
import { CardNameIconCell } from '../components/CardNameIconCell/CardNameIconCell'
import { CardOwnerCell } from '../components/CardOwnerCell/CardOwnerCell'
import { DebitCardsTableDebitCardV2Fragment } from './DebitCardsTable.generated'

export const columnsConfig = [
  {
    accessorId: 'cardName',
    cellComponent: CardNameIconCell<
      TableData<DebitCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 16, md: 8 },
    cellSkeletonWidth: [
      { _: 159, MD: 207 },
      { _: 128, MD: 160 },
      { _: 190, MD: 184 },
    ],
  },
  {
    accessorId: 'cardOwner',
    cellComponent: CardOwnerCell<TableData<DebitCardsTableDebitCardV2Fragment>>,
    cellSize: { _: 0, md: 5 },
    cellSkeletonWidth: [{ MD: 123 }, { MD: 144 }, { MD: 88 }],
  },
  {
    accessorId: 'debitCardAccount',
    cellComponent: CardAccountCell<
      TableData<DebitCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 0, md: 7 },
    cellSkeletonWidth: [{ MD: 165 }, { MD: 80 }, { MD: 122 }],
  },
  {
    accessorId: 'debitCardAccountForBalance',
    cellComponent: DebitCardBalanceCell<
      TableData<DebitCardsTableDebitCardV2Fragment>
    >,
    cellSize: { _: 8, md: 4 },
    cellSkeletonWidth: [
      { _: 26, MD: 68 },
      { _: 54, MD: 84 },
      { _: 36, MD: 52 },
    ],
  },
]
