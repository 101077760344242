import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { useTransferState } from 'pages/Transfer/Transfer.context'
import {
  TransferEvent,
  TransferGeneralState,
  TransferZellerState,
} from 'pages/Transfer/Transfer.stateMachine'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'
import { errorMessages } from 'translations'

export const NEW_RECIPIENT_OPTION = 'new-recipient'

const initialStates = [
  `${TransferGeneralState.ZellerTransfer}.${TransferZellerState.NoRecipients}`,
]

export const useTransitionStateOnDropdownChange = () => {
  const { values } = useFormikContext<TransferFieldTypes>()

  const {
    transferState: [state, send],
  } = useTransferState()

  useEffect(() => {
    if (values.from && values.to && initialStates.some(state.matches)) {
      send(TransferEvent.RecipientInput)
    }
  }, [send, state, values, values.from, values.to])
}

export const useValidateFormOnStateChange = () => {
  const { values, errors, setErrors, validateForm } =
    useFormikContext<TransferFieldTypes>()
  const {
    transferState: [state],
  } = useTransferState()

  useEffect(() => {
    validateForm()
  }, [state, validateForm])

  useEffect(() => {
    if (values.to === null && !errors.to) {
      setErrors({ ...errors, to: errorMessages.fieldRequired })
    }
  }, [values.to, errors, setErrors])
}
