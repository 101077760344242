import { gql } from '@apollo/client'
import { Table, TableData, useTable } from '@npco/ui-table'
import type { Row } from '@tanstack/react-table'

import { SimTableFragment } from './SimTable.generated'
import { SimDeviceCell } from './TableCells/SimDeviceCell'
import { SimIdCell } from './TableCells/SimIdCell'
import { SimSiteCell } from './TableCells/SimSiteCell'
import { SimStatusCell } from './TableCells/SimStatusCell'
import { columnsConfig } from './TableConfig'

type SimTableProps = {
  isLoading: boolean
  onRowClick: (row: Row<TableData<SimTableFragment>>) => void
  sims: SimTableFragment[]
}

export const SimTable = ({ isLoading, onRowClick, sims }: SimTableProps) => {
  const { table } = useTable<SimTableFragment>({
    columnsConfig,
    isLoading,
    listData: sims,
  })

  return (
    <Table<TableData<SimTableFragment>>
      hideHeader
      onRowClick={onRowClick}
      table={table}
    />
  )
}

SimTable.fragments = {
  Sim: gql`
    fragment SimTableFragment on Sim {
      ...SimIdCellFragment
      ...SimDeviceCellFragment
      ...SimSiteCellFragment
      ...SimStatusCellFragment
    }
    ${SimIdCell.fragments.Sim}
    ${SimDeviceCell.fragments.Sim}
    ${SimSiteCell.fragments.Sim}
    ${SimStatusCell.fragments.Sim}
  `,
}
