import { useCallback, useEffect, useState } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { useAddSubcategory } from 'hooks/subcategories/useAddSubcategory'
import { useDeleteSubcategory } from 'hooks/subcategories/useDeleteSubcategory'
import { useEditSubcategory } from 'hooks/subcategories/useEditSubcategory'
import { translate } from 'utils/translations'

import {
  EditState,
  EntityCategoryValues,
  NewSubcategory,
  Subcategory,
} from '../EditZellerCategories.types'

const OTHER_SUBCATEGORY_NAME = 'Other'

interface Props<V extends EntityCategoryValues> {
  category: EntityCategories | undefined
  searchInput: string
  subcategories: Subcategory[]
  onEditStateChange?: (newEditState: EditState) => void
  onEditSubcategory?: (values: V) => void
  onDeleteSubcategory?: (values: V & NewSubcategory) => void
}

export const useEditCategoryState = <V extends EntityCategoryValues>({
  category,
  searchInput,
  onEditStateChange,
  onEditSubcategory,
  onDeleteSubcategory,
  subcategories,
}: Props<V>) => {
  const [previousSubcategory, setPreviousSubcategory] = useState<
    string | null | undefined
  >(null)
  const [editState, setEditState] = useState(EditState.Select)
  const { values, setValues, setTouched } = useFormikContext<V>()

  useEffect(() => {
    onEditStateChange?.(editState)
  }, [editState, onEditStateChange])

  const handleEdit = useCallback(
    (subcategory: Subcategory) => {
      setPreviousSubcategory(subcategory.name)
      setValues({
        ...values,
        subcategory: subcategory.name,
        subcategoryId: subcategory.id,
      })
      setEditState(EditState.Edit)
    },
    [setValues, values]
  )

  const handleAdd = useCallback(() => {
    setPreviousSubcategory(values.subcategory)
    setValues({
      ...values,
      subcategory: searchInput,
    })
    setEditState(EditState.Add)
  }, [searchInput, values, setValues])

  const resetEditState = useCallback(() => {
    setValues({ ...values, subcategory: previousSubcategory })
    setTouched({})
    setPreviousSubcategory(null)
    setEditState(EditState.Select)
  }, [setValues, values, previousSubcategory, setTouched])

  const { addSubcategory, isLoading: isAddingSubcategory } = useAddSubcategory({
    category,
    subcategory: values.subcategory,
    onSuccess: (subcategoryId) => {
      setValues({ ...values, subcategoryId })
      showSuccessToast(
        translate('component.editZellerCategories.addSubcategorySuccess')
      )
      setEditState(EditState.Select)
    },
    onFailure: () =>
      showErrorToast(
        translate('component.editZellerCategories.addSubcategoryFailure')
      ),
  })

  const { editSubcategory, isLoading: isEditingSubcategory } =
    useEditSubcategory({
      category,
      subcategory: values.subcategory,
      subcategoryId: values.subcategoryId,
      oldSubcategory: previousSubcategory,
      onSuccess: () => {
        onEditSubcategory?.(values)
        showSuccessToast(
          translate('component.editZellerCategories.editSubcategorySuccess')
        )
        setEditState(EditState.Select)
      },
      onFailure: () =>
        showErrorToast(
          translate('component.editZellerCategories.editSubcategoryFailure')
        ),
    })

  const { deleteSubcategory, isLoading: isDeletingSubcategory } =
    useDeleteSubcategory({
      category,
      subcategoryId: values.subcategoryId,
      onSuccess: () => {
        onDeleteSubcategory?.({
          ...values,
          newSubcategory: subcategories.find(
            (subcategory) => subcategory.name === OTHER_SUBCATEGORY_NAME
          ),
        })
        showSuccessToast(
          translate('component.editZellerCategories.deleteSubcategorySuccess')
        )
        setValues({
          ...values,
          subcategory: OTHER_SUBCATEGORY_NAME,
          subcategoryId: subcategories.find(
            (subcategory) => subcategory.name === OTHER_SUBCATEGORY_NAME
          )?.id,
        })
        setEditState(EditState.Select)
      },
      onFailure: () =>
        showErrorToast(
          translate('component.editZellerCategories.deleteSubcategoryFailure')
        ),
    })

  const handleSave = useCallback(() => {
    if (editState === EditState.Add) {
      addSubcategory()
    }

    if (editState === EditState.Edit) {
      editSubcategory()
    }
  }, [addSubcategory, editSubcategory, editState])

  const saveButtonLabel =
    editState === EditState.Add
      ? translate('component.editZellerCategories.addLabel')
      : translate('component.editZellerCategories.editLabel')

  return {
    handleEdit,
    handleAdd,
    onSave: handleSave,
    onCancel: resetEditState,
    onDelete: editState === EditState.Edit ? deleteSubcategory : undefined,
    isEditing: editState !== EditState.Select,
    saveButtonLabel,
    isAddingSubcategory,
    isEditingSubcategory,
    isDeletingSubcategory,
  }
}
