import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { ACCORDION_FORM_STATE } from '@npco/zeller-design-system'
import {
  InvoiceFormFields,
  PaymentTerms,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { FormikErrors, FormikTouched } from 'formik'

import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'

export const toDayjsObject = (date: string) =>
  dayjs(date, DATE_FULL_DISPLAY_FORMAT, true)

export const isValidDate = (dateString: string) =>
  toDayjsObject(dateString).isValid()

export const isPastDate = (dateString: string) =>
  toDayjsObject(dateString).startOf('day').isBefore(dayjs().startOf('day'))

export const isPastInvoiceDateWithPaymentTerm = (
  invoiceDate: string,
  paymentTerms: PaymentTerms
) =>
  toDayjsObject(invoiceDate).add(Number(paymentTerms), 'day').isBefore(dayjs())

export const getDueDateByInvoiceDateAndPaymentTerms = (
  invoiceDate: string,
  paymentTerms: PaymentTerms
) =>
  isValidDate(invoiceDate)
    ? toDayjsObject(invoiceDate)
        .add(Number(paymentTerms), 'day')
        .format(DATE_FULL_DISPLAY_FORMAT)
    : ''

export const getAccordionDetails = (dueDate: string) => {
  if (isValidDate(dueDate)) {
    return translate('page.invoice.formSections.schedule.detailsSchedule', {
      dueDate,
    })
  }

  return translate('page.invoice.formSections.schedule.details')
}

interface GetAccordionFormStateProps {
  errors: FormikErrors<InvoiceFormFields>['schedule']
  touched: FormikTouched<InvoiceFormFields>['schedule']
  values: InvoiceFormFields['schedule']
}

export const getAccordionFormState = ({
  errors,
  touched,
  values,
}: GetAccordionFormStateProps) => {
  const fieldsToCheck = values.sendEnabled
    ? (['invoiceDate', 'dueDate', 'sendDate'] as const)
    : (['invoiceDate', 'dueDate'] as const)

  const isAllRequiredFieldsTouched: boolean = fieldsToCheck.every((field) =>
    Boolean(touched?.[field])
  )

  const isAnyRequiredFieldsTouched: boolean = fieldsToCheck.some((field) =>
    Boolean(touched?.[field])
  )

  const isAllRequiredFieldsValid: boolean = fieldsToCheck.every((field) =>
    isValidDate(values?.[field])
  )

  const isAnyRequiredFieldsValid: boolean = fieldsToCheck.some((field) =>
    isValidDate(values?.[field])
  )

  const hasErrorAndIsTouched: boolean = fieldsToCheck.some(
    (field) => Boolean(errors?.[field]) && Boolean(touched?.[field])
  )

  if (
    isAllRequiredFieldsTouched &&
    isAllRequiredFieldsValid &&
    !hasErrorAndIsTouched
  ) {
    return ACCORDION_FORM_STATE.COMPLETE
  }

  if (
    isAnyRequiredFieldsTouched &&
    isAnyRequiredFieldsValid &&
    !hasErrorAndIsTouched
  ) {
    return ACCORDION_FORM_STATE.PARTIAL_COMPLETE
  }

  if (hasErrorAndIsTouched) {
    return ACCORDION_FORM_STATE.ATTENTION
  }

  return ACCORDION_FORM_STATE.EMPTY
}
