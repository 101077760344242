import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const displayDeleteImageErrorToast = () => {
  showErrorToast(translate('errorMessages.failedToRemoveImage'))
}

export const displayDeleteImageSuccessToast = () => {
  showSuccessToast(
    translate('page.transactionDetails.images.preview.removeSuccess')
  )
}
