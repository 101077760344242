import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type UseCardFormatDisplayDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  format: Types.DebitCardFormat | null
  productType: Types.DebitCardProductType | null
}

export const UseCardFormatDisplayDebitCardV2FragmentDoc = gql`
  fragment UseCardFormatDisplayDebitCardV2Fragment on DebitCardV2 {
    format
    productType
  }
` as unknown as TypedDocumentNode<
  UseCardFormatDisplayDebitCardV2Fragment,
  undefined
>
