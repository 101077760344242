import { useTranslations } from '@npco/utils-translations'
import {
  BoatSinking,
  Box,
  Message,
  MessageSize,
} from '@npco/zeller-design-system'

import { translations } from './TransactionContactErrorMessage.i18n'

interface TransactionContactErrorMessageProps {
  retry: () => void
}

export const TransactionContactErrorMessage = ({
  retry,
}: TransactionContactErrorMessageProps) => {
  const t = useTranslations(translations)

  return (
    <Message
      title={t('title')}
      description={
        <>
          <Box textAlign="center">{t('description1')}</Box>
          <Box textAlign="center">{t('description2')}</Box>
        </>
      }
      primaryButton={{ label: t('tryAgain'), onClick: retry }}
      size={MessageSize.MEDIUM}
      align="center"
      image={<BoatSinking size="large" />}
    />
  )
}
