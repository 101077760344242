import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { CardFormatDisplayDebitCardV2FragmentDoc } from './CardFormatDisplay/CardFormatDisplay.generated'
import { SensitiveCardInformationDebitCardV2FragmentDoc } from './SensitiveCardInformation/SensitiveCardInformation.generated'
import { StatusOverlayDebitCardV2FragmentDoc } from './StatusOverlay/StatusOverlay.generated'
import { ViewDetailsDebitCardV2FragmentDoc } from './ViewDetails/ViewDetails.generated'

export type CardDisplayDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  maskedPan: string
  colour: Types.DebitCardColour
  status: Types.DebitCardStatus
  accessibleProfile: boolean | null
  id: string
  format: Types.DebitCardFormat | null
  productType: Types.DebitCardProductType | null
  customer: {
    __typename?: 'Customer'
    id: string
    entityUuid: string | null
    firstname: string | null
    lastname: string | null
  } | null
}

export const CardDisplayDebitCardV2FragmentDoc = gql`
  fragment CardDisplayDebitCardV2Fragment on DebitCardV2 {
    ...ViewDetailsDebitCardV2Fragment
    ...CardFormatDisplayDebitCardV2Fragment
    ...StatusOverlayDebitCardV2Fragment
    ...SensitiveCardInformationDebitCardV2Fragment
  }
  ${ViewDetailsDebitCardV2FragmentDoc}
  ${CardFormatDisplayDebitCardV2FragmentDoc}
  ${StatusOverlayDebitCardV2FragmentDoc}
  ${SensitiveCardInformationDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<CardDisplayDebitCardV2Fragment, undefined>
