import { COLOR } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'

import {
  getHasExceededPositiveSalesPreviousPeriod,
  getRoundedPercentage,
} from '../../../DashboardPerformance.utils'
import { StyledSvgAnimation } from './CentreText.styled'
import { getTodayComparisonText } from './CentreText.utils'
import { BoldText } from './SvgText/BoldText'
import { SmallText } from './SvgText/SmallText'

interface Props {
  todaySales: number
  totalSalesOneWeekAgo: number
}

export const TodayCentreText = ({
  todaySales,
  totalSalesOneWeekAgo,
}: Props) => {
  const hasExceededPreviousPeriod = getHasExceededPositiveSalesPreviousPeriod(
    todaySales,
    totalSalesOneWeekAgo
  )

  const transitionKey = `${todaySales}${totalSalesOneWeekAgo}`

  return (
    <StyledSvgAnimation key={transitionKey}>
      {hasExceededPreviousPeriod && (
        <BoldText key={1} x="50%" y="30%" fill={COLOR.GREEN_1000}>
          {`+${getRoundedPercentage(todaySales, totalSalesOneWeekAgo) - 100}%`}
        </BoldText>
      )}
      <BoldText
        key={2}
        x="50%"
        y="45%"
        fill={hasExceededPreviousPeriod ? COLOR.GREEN_1000 : COLOR.BLUE_1000}
      >
        {currencyFormatter(todaySales)}
      </BoldText>
      <SmallText key={3} x="50%" y="55%" fill={COLOR.GREY_400}>
        {getTodayComparisonText(todaySales, totalSalesOneWeekAgo)}
      </SmallText>
    </StyledSvgAnimation>
  )
}
