import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CellWrapper = styled.div`
  grid-area: icons;
  display: flex;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    margin-right: auto;
  }
`

export const IconButton = styled.button<{ $color: string }>`
  background: none;
  border: none;
  ${({ $color }) => $color && `color: ${$color}`};
  cursor: pointer;
  padding: 0;
  font: inherit;
  outline: inherit;

  svg {
    width: 12px;
    height: 12px;
  }

  path {
    ${({ $color }) => $color && `fill: ${$color}`};
  }

  &:hover,
  &:focus-visible {
    svg path {
      fill: ${({ theme }) => theme.colors.GREY_550};
    }
  }
`
