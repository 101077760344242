import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { getColorFromResponse } from '@npco/utils-colors'
import {
  AVATAR_BADGE_SIZE,
  COLOR,
  TableCellAvatarText,
  TableCellSize,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { CardAccountCellDebitCardV2Fragment } from './CardAccountCell.generated'

export const CardAccountCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<T, CardAccountCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CardAccountCellDebitCardV2Fragment>()
  const debitCardAccountName = data?.debitCardAccount?.name || ''
  const isMobile = useIsMobileResolution()

  return (
    <TableCellAvatarText
      dataTestId={cell.id}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={table.options.meta?.isLoading}
      justifyContent="flex-end"
      key={cell.id}
      skeletonProps={{ width: skeletonLoaderWidth }}
      text={debitCardAccountName}
      size={TableCellSize.MEDIUM}
      cellTextSize={isMobile ? 'Small' : 'Medium'}
      cellSize={cellSize}
      avatarProps={{
        text: debitCardAccountName,
        badgeSize: AVATAR_BADGE_SIZE.XXSMALL,
        bgColor:
          getColorFromResponse(data?.debitCardAccount?.icon?.colour) ??
          COLOR.GREY_30,
      }}
    />
  )
}

CardAccountCell.fragments = {
  DebitCardV2: gql`
    fragment CardAccountCellDebitCardV2Fragment on DebitCardV2 {
      debitCardAccount {
        __typename
        id
        name
        icon {
          colour
        }
      }
    }
  `,
}
