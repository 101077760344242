import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  COLOR,
  Typography,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { formatAccountNumber, formatBsb } from 'utils/common'

import { AccountType } from '../../../../../hooks/useSetupFlowAccountsQuery/graphql/getAccounts.type'
import { setupFlowAccountDetailsTranslations } from './AccountDetails.i18n'
import { AccountDetailsCard } from './AccountDetails.styled'
import { AccountDetailsRow } from './AccountDetailsRow/AccountDetailsRow'

interface AccountDetailsProps {
  account: AccountType
}

export const AccountDetails = ({ account }: AccountDetailsProps) => {
  const t = useTranslations(setupFlowAccountDetailsTranslations)

  const accountBsb = account.accountDetails?.bsb
  const accountName = account.accountDetails?.name
  const accountNumber = account.accountDetails?.account

  const isTablet = useIsMobileResolution(BREAKPOINT.LG)

  return (
    <AccountDetailsCard data-testid="setup-flow-account-details">
      <Typography color={COLOR.GREY_550} variant="body-sm" pb={{ LG: '4px' }}>
        {t('yourFirstAccount')}
      </Typography>
      <AccountDetailsRow
        title={isTablet ? t('accountNameTablet') : accountName ?? '-'}
        value={isTablet ? accountName ?? '-' : ''}
        valueToCopy={accountName ?? ''}
      />
      <AccountDetailsRow
        title={t('bsb')}
        value={accountBsb ? formatBsb(accountBsb) : '-'}
        valueToCopy={accountBsb ?? ''}
      />
      <AccountDetailsRow
        title={t('accountNumber')}
        value={accountNumber ? formatAccountNumber(accountNumber) : '-'}
        valueToCopy={accountNumber ?? ''}
      />
    </AccountDetailsCard>
  )
}
