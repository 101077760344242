import { type ApolloError } from '@apollo/client'
import {
  ErrorState,
  LoadingState,
  ManageBusinessesQueryResponse,
  NoBusinessResult,
} from '@npco/mp-feature-manage-businesses'
import { Entity } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { COLOR, Flex, MobileTopBar } from '@npco/zeller-design-system'

import { entitiesMenuTranslations } from '../EntitiesMenu.i18n'

interface EntitiesMenuItemsProps {
  handleSelectEntity: (entityId: Entity['id']) => void
  activeEntityId: Entity['id'] | null
  isAddBusinessAllowed: boolean
  openRemoveBusinessModal: (props: {
    businessId: Entity['id']
    businessName: Entity['name']
  }) => void
  entityRelations?: ManageBusinessesQueryResponse['getCustomerEntityMapping']['entityRelations']
  filteredEntityRelations?: ManageBusinessesQueryResponse['getCustomerEntityMapping']['entityRelations']
  isLoading: boolean
  hasError?: ApolloError
  refetch: () => void
}

export const EntitiesMenuItems = ({
  handleSelectEntity,
  activeEntityId,
  isAddBusinessAllowed,
  openRemoveBusinessModal,
  entityRelations,
  isLoading,
  hasError,
  filteredEntityRelations,
  refetch,
}: EntitiesMenuItemsProps) => {
  const t = useTranslations(entitiesMenuTranslations)

  if (isLoading) {
    return <LoadingState />
  }

  if (!entityRelations?.length && hasError) {
    return (
      <Flex flexDirection="column" justifyContent="center" flexGrow={1}>
        <ErrorState reload={refetch} />
      </Flex>
    )
  }

  if (!filteredEntityRelations?.length) {
    return (
      <Flex flexDirection="column" justifyContent="center" flexGrow={1}>
        <NoBusinessResult />
      </Flex>
    )
  }

  return (
    <>
      {filteredEntityRelations.map((entityRelation) => (
        <MobileTopBar.MenuItem
          key={entityRelation.entity.id}
          title={entityRelation.entity.name ?? ''}
          onClick={() => handleSelectEntity(entityRelation.entity.id)}
          isActive={activeEntityId === entityRelation.entity.id}
          popperMenu={
            isAddBusinessAllowed && (
              <MobileTopBar.MenuItemPopperMenu
                isActive={activeEntityId === entityRelation.entity.id}
                popperTriggerLabel={t('moreActions')}
              >
                <MobileTopBar.MenuItemPopperMenu.Item
                  onClick={() =>
                    openRemoveBusinessModal({
                      businessId: entityRelation.entity.id,
                      businessName: entityRelation.entity.name,
                    })
                  }
                  title={t('removeBusiness')}
                  color={COLOR.RED_1000}
                />
              </MobileTopBar.MenuItemPopperMenu>
            )
          }
        />
      ))}
    </>
  )
}
