import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getSessionStorageItem } from '@npco/utils-session-storage'
import { rvPathBeforeLoggingIn } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useSetup } from 'auth/useSetup'

import { translate } from 'utils/translations'
import { AuthLoadingPage } from 'pages/AuthLoadingPage/AuthLoadingPage'
import { SpinnerWrapped } from 'components/Spinner'

import { LoginError } from './LoginError/LoginError'

export const AuthenticationScreen = () => {
  const isAfterRedirect: boolean | undefined = getSessionStorageItem('redirect')

  const { fetchSetupDataError, isFetchSetupDataLoading } = useSetup()

  const history = useHistory()

  useEffect(() => {
    if (
      !rvPathBeforeLoggingIn() ||
      isFetchSetupDataLoading ||
      isAfterRedirect
    ) {
      return
    }
    history.push(rvPathBeforeLoggingIn())
  }, [isFetchSetupDataLoading, isAfterRedirect, history])

  if (isFetchSetupDataLoading && isAfterRedirect) {
    return (
      <AuthLoadingPage>
        {translate('page.login.authSuccess.text1')}
        {translate('page.login.authSuccess.text2')}
      </AuthLoadingPage>
    )
  }

  if (fetchSetupDataError) {
    return <LoginError error={fetchSetupDataError} />
  }

  return <SpinnerWrapped variant="centre" />
}
