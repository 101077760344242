import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;

  gap: 0 8px;
  padding: 12px 16px;

  height: 24px;
`

export const liGroup = styled.li`
  display: flex;
  flex-direction: column;
`
