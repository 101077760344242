import { useCallback } from 'react'
import { ActivateDebitCardErrorEnum, CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  LinkCardError,
  LinkCardIdState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToCompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage'
import { CompleteCardStage } from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { useModalCloseTransitionContext } from 'pages/GlobalModals/contexts/ModalCloseTransitionContext/useModalCloseTransitionContext'

import { useActivateCard } from '../../../../../../hooks/useActivateCard'
import { LinkCardIdFormValues } from '../LinkCardIdForm.types'

const EML_CARD_NUMBER_LENGTH = 9

export const useLinkCardIdForm = () => {
  const { userData } = useLoggedInUser()
  const { goToAddCardStage } = useGoToAddCardStage()
  const { goToCompleteCardStage } = useGoToCompleteCardStage()
  const { goToSetPinStage } = useGoToSetPinStage()
  const { activateDebitCard, isActivatingCard } = useActivateCard()
  const { closeWithTransition } = useModalCloseTransitionContext()
  const { state } = useAddCardState<LinkCardIdState>()

  const isAdmin = userData?.role === CustomerRole.ADMIN

  const linkCard = useCallback(
    async (values: LinkCardIdFormValues) => {
      const { cardId } = values

      const activatedCard = await activateDebitCard({ reference: cardId })

      if (
        activatedCard === UNEXPECTED_ERROR ||
        activatedCard === ActivateDebitCardErrorEnum.USER_IS_NOT_VERIFIED
      ) {
        if (cardId.length === EML_CARD_NUMBER_LENGTH) {
          goToAddCardStage({
            stage: AddCardStage.LinkCardIdStage,
            linkCardError: LinkCardError.CardNumberLengthError,
          })
          return
        }

        goToAddCardStage({
          stage: AddCardStage.LinkCardIdStage,
          linkCardError: LinkCardError.CardIDNotFoundError,
        })
        return
      }

      if (activatedCard === ActivateDebitCardErrorEnum.ALREADY_ACTIVATED) {
        showErrorToast(
          translate('page.addCardModal.linkCard.alreadyActivatedError')
        )
        return
      }

      if (activatedCard === ActivateDebitCardErrorEnum.ACCOUNT_REQUIRED) {
        if (isAdmin) {
          goToAddCardStage({
            stage: AddCardStage.LinkSelectAccountStage,
            cardId,
          })
          return
        }

        goToAddCardStage({
          stage: AddCardStage.LinkCardIdStage,
          linkCardError: LinkCardError.ManagerBlankCardError,
        })
        return
      }

      if (activatedCard === ActivateDebitCardErrorEnum.DOES_NOT_OWN_CARD) {
        goToAddCardStage({
          stage: AddCardStage.LinkCardIdStage,
          linkCardError: LinkCardError.NotCardholderError,
        })
        return
      }

      if (activatedCard.accessibleProfile) {
        closeWithTransition(() =>
          goToCompleteCardStage(
            {
              stage: CompleteCardStage.CompleteCardSetupStage,
              cardId: activatedCard.id,
            },
            {
              hasOpenTransition: true,
            }
          )
        )
        return
      }

      goToSetPinStage({
        stage: 'SetPin',
        cardId: activatedCard.id,
      })
    },
    [
      goToAddCardStage,
      activateDebitCard,
      goToSetPinStage,
      goToCompleteCardStage,
      isAdmin,
      closeWithTransition,
    ]
  )

  const goBackToLandingPage = useCallback(() => {
    goToAddCardStage({
      stage: AddCardStage.LandingPageStage,
    })
  }, [goToAddCardStage])

  return {
    linkCard,
    goBackToLandingPage,
    isActivatingCard,
    linkCardError: state?.linkCardError,
    isAdmin,
  }
}
