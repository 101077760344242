import { ReactNode, Suspense } from 'react'

import { SpinnerWrapped } from '../Spinner'

export const LazyLoadComponent = ({ children }: { children: ReactNode }) => {
  return (
    <Suspense fallback={<SpinnerWrapped variant="centre" />}>
      {children}
    </Suspense>
  )
}
