import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { ZellerCardSiteNavIcon } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { component } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const CardsItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <SidebarItem
      className="nav-item-cards"
      icon={<ZellerCardSiteNavIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).CARDS.path}
      title={component.sidebar.items.cards}
    >
      {component.sidebar.items.cards}
    </SidebarItem>
  )
}
