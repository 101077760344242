import { ApolloCache } from '@apollo/client'
import { CustomerRole, KycStatus } from '@npco/mp-gql-types'
import { mockedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetCustomers } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'

import {
  GetCustomers as GetCustomersFragmentType,
  GetCustomers as GetCustomersResponse,
} from 'types/gql-types/GetCustomers'

type CustomerType = GetCustomersFragmentType | null

const initialCustomer: CustomerType & { __typename: 'Customer' } = {
  __typename: 'Customer',
  getCustomers: [
    {
      id: 'placeholder',
      entityUuid: mockedEntityUuid,
      firstname: 'Old Firstname',
      middlename: 'Old Middlename',
      lastname: 'Old Lastname',
      role: CustomerRole.ADMIN,
      isInvitationPending: false,
      kycStatus: KycStatus.IN_REVIEW,
      siteCount: 0,
    },
  ],
}

type CreateCustomerCacheRecordOptions = {
  id: string
  overwritingData?: Partial<typeof initialCustomer>
}

export const createCustomerCacheRecord = (
  cache: ApolloCache<any>,
  { id, overwritingData }: CreateCustomerCacheRecordOptions
) => ({
  [cache.identify({ id, __typename: 'Customer' }) as string]: {
    ...initialCustomer,
    ...overwritingData,
    id,
  },
})

export const readCachedCustomer = (
  cache: ApolloCache<any>,
  entityUuid: string
) => {
  return cache.readQuery<GetCustomersResponse>({
    query: GetCustomers,
    variables: {
      entityUuid,
    },
  })
}
