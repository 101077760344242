import { useSetupFlowContext } from '../../../../../../contexts/SetupFlowContext'
import { DebitCardPositionWrapper } from '../DebitCardDetails.styled'
import { SensitiveCardInformation } from '../SensitiveCardInformation/SensitiveCardInformation'
import { DebitCardDetailsContentProps } from './DebitCardDetailsContent.types'
import { DebitCardDetailsCopyInfoMobile } from './DebitCardDetailsCopyInfoMobile'

export const DebitCardDetailsContentMobile = ({
  toggleCard,
  isCardFlipped,
  onError,
}: DebitCardDetailsContentProps) => {
  const { setupFlowCard } = useSetupFlowContext()

  return (
    <DebitCardPositionWrapper data-testid="debit-card-details-mobile">
      <SensitiveCardInformation
        isFlipped={isCardFlipped}
        debitCard={setupFlowCard}
        onError={onError}
      />
      {setupFlowCard && (
        <DebitCardDetailsCopyInfoMobile
          isDetailsVisible={isCardFlipped}
          onClick={toggleCard}
        />
      )}
    </DebitCardPositionWrapper>
  )
}
