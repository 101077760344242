import { UpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'
import { Condition } from 'components/Condition/Condition'

import { useUpgradeAccountsCompleteState } from '../../hooks/useUpgradeAccountsCompleteState'
import { UpgradeAccountsCompleteReadyToGoStageContent } from './UpgradeAccountsCompleteReadyToGoStageContent/UpgradeAccountsCompleteReadyToGoStageContent'

export const UpgradeAccountsCompleteReadyToGoStage = () => {
  const { state } = useUpgradeAccountsCompleteState()
  return (
    <Condition
      shouldShow={
        state?.stage ===
        UpgradeAccountsCompleteStage.UpgradeAccountsReadyToGoStage
      }
    >
      <UpgradeAccountsCompleteReadyToGoStageContent />
    </Condition>
  )
}
