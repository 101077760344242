import { sumBy } from 'lodash-es'

type HighlightBackgroundShapeProps = {
  payload?: { value: number }[]
} & React.SVGProps<SVGElement>

export const HighlightBackgroundShape = ({
  x,
  width,
  height,
  radius,
  opacity,
  fill,
  style,
  payload,
  'aria-label': ariaLabel,
}: HighlightBackgroundShapeProps) => {
  const hasValue = payload && sumBy(payload, 'value') > 0
  const customStyle = style ?? { cursor: hasValue ? 'pointer' : 'default' }

  return (
    <g>
      <rect
        aria-label={ariaLabel}
        x={x}
        y={0}
        rx={radius}
        ry={radius}
        height={(height ? Number(height) : 0) + 60}
        width={width}
        fill={fill}
        opacity={opacity}
        style={customStyle}
      />
    </g>
  )
}
