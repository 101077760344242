import { useMemo } from 'react'
import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  Heading,
  InfoBox,
  INFOBOX_VARIANT,
  InputAdaptiveFieldWithPrefix,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { useInputHandlers } from 'hooks/useInputHandlers'

import { useCompleteAmountDecimalOnBlur } from '../../../hooks/useCompleteAmountDecimalOnBlur'
import { HeaderTooltipButton } from '../components/HeaderTooltipButton'
import { SPEND_CONTROL_LIMIT_FIELD } from '../SpendControlFields.constants'
import * as styled from '../SpendControlFields.styled'
import { useAccountBalance } from './hooks/useAccountBalance'
import { spendControlLimitTranslations } from './SpendControlLimit.i18n'
import { SpendControlLimitTooltipButton } from './SpendControlLimitTooltipButton/SpendControlLimitTooltipButton'

const renderPrefix = () => <styled.TextInputPrefix>$</styled.TextInputPrefix>

interface SpendControlLimitProps {
  isHeaderVisible?: boolean
  isFullWidth?: boolean
  accountId?: string
}

export const SpendControlLimit = ({
  isHeaderVisible,
  isFullWidth,
  accountId,
}: SpendControlLimitProps) => {
  const t = useTranslations(spendControlLimitTranslations)
  const { balance } = useAccountBalance({
    id: accountId,
  })
  const { handleCurrencyMin1DigitInputKeydown } = useInputHandlers()
  const { onBlurHandler } = useCompleteAmountDecimalOnBlur({
    fieldName: SPEND_CONTROL_LIMIT_FIELD,
  })
  const [field] = useField(SPEND_CONTROL_LIMIT_FIELD)

  const formattedBalance = useMemo(
    () => currencyFormatter(balance ?? 0),
    [balance]
  )
  const isWarningVisible = useMemo(() => {
    return balance !== null && Number(field.value) * 100 > balance
  }, [balance, field.value])

  return (
    <Box>
      {isHeaderVisible && (
        <Flex mb="24px">
          <Heading.H4 mr="8px">{t('header')}</Heading.H4>
          <HeaderTooltipButton content={t('headerTooltip')} />
        </Flex>
      )}

      <styled.ItemWrapper $isFullWidth={isFullWidth}>
        <InputAdaptiveFieldWithPrefix
          name={SPEND_CONTROL_LIMIT_FIELD}
          renderPrefix={renderPrefix}
          label={t('label')}
          aria-label={t('ariaLabel')}
          inputMode="numeric"
          onKeyDown={handleCurrencyMin1DigitInputKeydown}
          autoComplete="off"
          renderRightControls={
            isHeaderVisible ? undefined : SpendControlLimitTooltipButton
          }
          onBlur={onBlurHandler}
        />
      </styled.ItemWrapper>

      {isWarningVisible && (
        <Box mt="24px">
          <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
            <InfoBox.Message>
              {t('warning', { balance: formattedBalance })}
            </InfoBox.Message>
          </InfoBox>
        </Box>
      )}
    </Box>
  )
}
