import { FormEvent, forwardRef, ReactNode, useMemo, useState } from 'react'
import {
  Animation,
  slideDownAnimationVariants,
  StyledErrorBox,
  TextInputSecureField,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { validatePassword } from 'utils/formValidation'
import { SignupFields } from 'pages/SignUp/SignUp.types'
import { component, page } from 'translations'

import { StyledTooltipParentWrapper } from '../SignupTooltipContents/SignupTooltipContents.styled'
import { SignupTooltipWrapper } from '../SignupTooltipWrapper/SignupTooltipWrapper'

interface PasswordFieldProps {
  error?: ReactNode
  setError: (newMsg?: string) => void
  dataTestId?: string
}

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ error, setError, dataTestId = 'password-field' }, ref) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)

    const showTooltip = () => setIsTooltipVisible(true)
    const hideTooltip = () => setIsTooltipVisible(false)

    const { values, handleChange, handleBlur } =
      useFormikContext<SignupFields>()

    const isFieldValid = useMemo(
      () => !validatePassword(values.password),
      [values.password]
    )

    const hasInvalidValue = !!values.password && !isFieldValid
    const hasError = error !== undefined || hasInvalidValue

    return (
      <SignupTooltipWrapper
        isVisible={isTooltipVisible && hasInvalidValue}
        forceOpen={hasInvalidValue}
      >
        <StyledTooltipParentWrapper>
          <TextInputSecureField
            ref={ref}
            name="password"
            aria-label="password"
            showValueText={component.textInput.show}
            hideValueText={component.textInput.hide}
            onChange={(e: FormEvent<HTMLInputElement>) => {
              setError(undefined)
              handleChange(e)
            }}
            onBlur={(e: FormEvent<HTMLInputElement>) => {
              hideTooltip()
              handleBlur(e)
            }}
            onFocus={showTooltip}
            placeholder={page.signUp.createPassword}
            hasError={hasError}
            data-testid={dataTestId}
          />

          <Animation
            shouldShow={hasError}
            variants={slideDownAnimationVariants}
          >
            <StyledErrorBox data-testid="error-field">{error}</StyledErrorBox>
          </Animation>
        </StyledTooltipParentWrapper>
      </SignupTooltipWrapper>
    )
  }
)
