import {
  BodyDefaultTypography,
  BodySmallTypography,
  Box,
  BREAKPOINT,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const StyledBadgeItem = styled(Box)<{
  $hideAvatarOnMobile: boolean
  $showMobileStyle: boolean
}>`
  grid-area: company-avatar;
  width: 40px;
  margin: 0 12px 0 0;

  ${({ $hideAvatarOnMobile, $showMobileStyle }) =>
    $hideAvatarOnMobile &&
    $showMobileStyle &&
    css`
      display: none;
    `}

  ${({ $hideAvatarOnMobile }) =>
    $hideAvatarOnMobile &&
    css`
      @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
        display: none;
      }
    `}
`

export const StyledCompanyNameItem = styled(Box)<{
  $showMobileStyle: boolean
}>`
  grid-area: company-name;
  padding: 0;
  width: 100%;
  ${BodySmallTypography};

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    css`
      @media (min-width: ${BREAKPOINT.XS}px) {
        ${BodyDefaultTypography};
      }

      @media screen and (min-width: ${BREAKPOINT.MD}px) {
        min-width: 10.75rem;
        align-items: center;
        grid-area: auto;
        width: 14rem;
      }
    `}
`
