import { FeeRateSettings } from '@npco/mp-gql-types'

import { MobileAppFeesAndPricing } from './MobileAppFeesAndPricing/MobileAppFeesAndPricing'
import { VirtualTerminalFeesAndPricing } from './VirtualTerminal/VirtualTerminalFeesAndPricing'
import { ZellerPricing } from './ZellerPricing/ZellerPricing'

type CommonFeesAndPricingProps = {
  entityFee?: FeeRateSettings
}
export const CommonFeesAndPricing = ({
  entityFee,
}: CommonFeesAndPricingProps) => {
  const { feeFixedCpoc, feePercentCpoc, feeFixedVt, feePercentVt } =
    entityFee ?? {}
  return (
    <>
      <ZellerPricing />
      <MobileAppFeesAndPricing
        feePercentCpoc={feePercentCpoc}
        feeFixedCpoc={feeFixedCpoc}
      />
      <VirtualTerminalFeesAndPricing
        feeFixedVt={feeFixedVt}
        feePercentVt={feePercentVt}
      />
    </>
  )
}
