import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Checked } from 'assets/svg/checked.svg'
import { ReactComponent as Info } from 'assets/svg/info.svg'

import { RightControlProps } from './AccountTileList.type'
import { StyledDisabledText } from './AccountTileRightControls.styled'

interface AccountTileRightControlProps extends RightControlProps {
  disabledMessage?: string
}

export const AccountTileRightControls = ({
  isActive,
  isDisabled,
  disabledMessage,
}: AccountTileRightControlProps) => {
  return (
    <>
      {isActive && !isDisabled && (
        <SvgIcon height="16" width="16" backgroundColor={COLOR.BLUE_1000}>
          <Checked />
        </SvgIcon>
      )}
      {isDisabled && !isActive && (
        <StyledDisabledText>{disabledMessage}</StyledDisabledText>
      )}
      {isDisabled && isActive && (
        <Flex justifyContent="space-between">
          <Box mr="0.5rem">
            <StyledDisabledText>{disabledMessage}</StyledDisabledText>
          </Box>
          <SvgIcon height="16" width="16" color={COLOR.RED_1000}>
            <Info />
          </SvgIcon>
        </Flex>
      )}
    </>
  )
}
