import { Heading } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { StyledBodyDefault } from '../CreateAccountForm.styled'

export const AccountTermsAndConditionsField = () => {
  return (
    <>
      <Heading.H4>
        {translate('page.createAccountModal.termsAndConditionsTitle')}
      </Heading.H4>
      <StyledBodyDefault>
        {translate('page.createAccountModal.termsAndConditionsPolicy')}
      </StyledBodyDefault>
    </>
  )
}
