import { createTranslations } from '@npco/utils-translations'

export const bpayListTranslations = createTranslations({
  label: 'BPAY',
  billerCodeLabel: 'Biller Code',
  crn: 'CRN',
  noData: 'No BPAY added yet',
  linkLabel: 'Link BPAY Details',
  unLinkLabel: 'Unlink BPAY Details',
  saveLabel: 'Save',
  cancelLabel: 'Cancel',
  editTitle: 'Edit BPAY Details',
  confirmModalTitle: 'Unlink BPAY Details From {contactName}',
  confirmModalDescription:
    'Are you sure you want to unlink these BPAY Details? This will remove transactions from the transaction history.',
  confirmModalPrimary: 'Unlink',
  successUnlinkLabel: 'BPAY Details Unlinked Successfully',
  successUpdateLabel: 'BPAY Details Updated Successfully',
})
