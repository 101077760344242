import { createTranslations } from '@npco/utils-translations'

export const resendCodeModalTranslations = createTranslations({
  titleSuccess: 'Code Resent',
  titleFailure: 'Sorry, something went wrong.',
  titleTooManyAttempts: 'Too many attempts',
  copySuccess:
    'We have resent you a verification code via SMS. For some carriers it can take up to a minute.',
  copyFailure:
    'Unfortunately, we were unable to process this request. Please try again later.',
  tooManyAttempts:
    'You have exceeded the permitted number of SMS verification attempts. Please try again later.',
  buttonLabel: 'OK',
})
