import React from 'react'
import { useTranslations } from '@npco/utils-translations'
import { MagnifyingGlassNoResults, Message } from '@npco/zeller-design-system'

import { translations } from './NoDevices.i18n'

export const NoDevices = () => {
  const t = useTranslations(translations)

  return (
    <Message
      image={<MagnifyingGlassNoResults size="medium" />}
      title={t('title')}
      description={t('description')}
    />
  )
}
