import { ApolloError } from '@apollo/client'

import { OutstandingExpensesError } from './components/OutstandingExpensesError'
import { OutstandingExpensesForm } from './components/OutstandingExpensesForm'
import { OutstandingExpensesLoading } from './components/OutstandingExpensesLoading'
import { OutstandingExpensesContentFragment } from './OutstandingExpensesContent.generated'

interface OutstandingExpensesContentProps {
  loading: boolean
  entity: OutstandingExpensesContentFragment | undefined
  error: ApolloError | undefined
  retry: () => void
}

export const OutstandingExpensesContent = ({
  loading,
  error,
  entity,
  retry,
}: OutstandingExpensesContentProps) => {
  if (loading) {
    return <OutstandingExpensesLoading />
  }

  if (error || !entity) {
    return <OutstandingExpensesError retry={retry} />
  }

  return <OutstandingExpensesForm entity={entity} />
}
