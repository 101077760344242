// TICKET: BANK-557
import { useCallback } from 'react'

import { useTriggerManualBankfeedsSync } from '../hooks/useTriggerManualBankfeedsSync'
import type { MutationResult } from './shared/MutationResult'

export const useMutationManualSync = (): [() => MutationResult<never>] => {
  const { manualBankfeedsSync: execute } = useTriggerManualBankfeedsSync()
  return [
    useCallback(async () => {
      const { errors } = await execute()
      return { data: undefined, error: errors }
    }, [execute]),
  ]
}

useMutationManualSync.gql = useTriggerManualBankfeedsSync.gql
useMutationManualSync.gqlData = useTriggerManualBankfeedsSync.gqlData
