import { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CompleteOptOutReceiptsStatus } from '@npco/mp-gql-types'
import { showApiErrorToast, showErrorToast } from '@npco/zeller-design-system'
import { OptOutReceipts } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/optOutReceipts'

import {
  OptOutReceipts as OptOutReceiptsResponse,
  OptOutReceiptsVariables,
} from 'types/gql-types/OptOutReceipts'
import { ApolloClientWithAPIKey } from 'services/graphql'
import { errorMessages } from 'translations'

interface UseOptOutReceiptsProps {
  setIsOptOutSuccessful: Dispatch<SetStateAction<boolean>>
}

export const useOptOutReceipts = ({
  setIsOptOutSuccessful,
}: UseOptOutReceiptsProps) => {
  const { search } = useLocation()

  const id = new URLSearchParams(search).get('id') || ''
  const code = new URLSearchParams(search).get('code') || ''

  const [optOutReceipts, { loading: isOptingOutReceipts }] = useMutation<
    OptOutReceiptsResponse,
    OptOutReceiptsVariables
  >(OptOutReceipts, {
    client: ApolloClientWithAPIKey,
    onError: (error) =>
      showApiErrorToast(error, errorMessages.somethingWentWrong),
    onCompleted: (response: OptOutReceiptsResponse) => {
      if (
        response.optOutReceipts.status ===
        CompleteOptOutReceiptsStatus.COMPLETED
      ) {
        setIsOptOutSuccessful(true)
      } else {
        showErrorToast(errorMessages.somethingWentWrong)
      }
    },
  })

  return {
    optOutReceipts: () =>
      optOutReceipts({
        variables: {
          code,
          id,
        },
      }),
    isOptingOutReceipts,
  }
}
