import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { EditDebitCardTransactionImagesState } from '../../EditDebitCardTransactionImages.types'

export const useGoToEditDebitCardTransactionImages = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToEditDebitCardTransactionImages = useCallback(
    (state: EditDebitCardTransactionImagesState | null) => {
      navigate(location.pathname, {
        state: {
          EditDebitCardTransactionImagesModal: state,
        },
      })
    },
    [navigate, location.pathname]
  )

  return { goToEditDebitCardTransactionImages }
}
