import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface Props {
  phone: string | null | undefined
}

export const PhoneItem = ({ phone }: Props) => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.phone')}
      value={phone || '-'}
    />
  )
}
