import { createTranslations } from '@npco/utils-translations'

export const cardEmptyViewCorporateTranslations = createTranslations({
  title: 'Manage and track team expenses with Zeller Corporate Cards.',
  description:
    'Manage team expenses, track spending, and reconcile accounts, without hours of paperwork.',
  item1: 'Create a virtual card with a click, or order a physical card.',
  item2: 'Set recurring budgets and transaction limits.',
  item3: 'Attach notes, receipts, and invoices to expenses.',
  item4: 'Manage project or team-level budgets.',
  item5: 'Simpler, real-time expense management for finance teams.',
  ctaDescription:
    'Create your first card and link to your nominated Transaction Account to get started.',
  imageAlt: 'Four Zeller-branded expense cards floating',
})
