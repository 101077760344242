import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { useGetPieChartDataCashFlowSubcategoryNetAmountFragmentDoc } from '../utils/useGetPieChartData.generated'
import { useGetTotalCashFlowSubcategoryNetAmountFragmentDoc } from '../utils/useGetTotal.generated'

export type CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment = {
  __typename: 'CashFlowSubcategoryNetAmount'
  category: Types.EntityCategories | null
  noOfTransaction: number
  subcategoryDetails: {
    __typename?: 'Subcategory'
    name: string
    id: string
    predefined: boolean
  } | null
  total: { __typename?: 'Money'; value: string; currency: string }
  average: { __typename?: 'Money'; value: string; currency: string }
}

export const CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragmentDoc =
  gql`
    fragment CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      __typename
      subcategoryDetails {
        name
      }
      total {
        value
      }
      ...useGetTotalCashFlowSubcategoryNetAmountFragment
      ...useGetPieChartDataCashFlowSubcategoryNetAmountFragment
    }
    ${useGetTotalCashFlowSubcategoryNetAmountFragmentDoc}
    ${useGetPieChartDataCashFlowSubcategoryNetAmountFragmentDoc}
  ` as unknown as TypedDocumentNode<
    CategoryDrawerPieChartCashFlowSubcategoryNetAmountFragment,
    undefined
  >
