import { Link } from 'react-router-dom-v5-compat'
import { BodyDefaultTypography, ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const DetailsValue = styled.div`
  ${BodyDefaultTypography};
  color: ${({ color, theme }) => color ?? theme.colors.BLACK_900};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  text-align: right;
  flex: 1;
  margin: 0;
`

export const RedirectButtonLink = styled(Link)`
  ${BodyDefaultTypography};
  color: ${({ theme: { colors } }) => colors.BLUE_1000};
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
    background: transparent;
  }
`

export const StyledButtonLink = styled(ButtonLink)`
  ${BodyDefaultTypography};
  height: 100%;
`
