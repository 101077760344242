import { ContactType } from '@npco/mp-gql-types'
import { Box, Flex, INPUT_SIZE } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import {
  makeOptional,
  validatePostcode,
  validateSuburb,
} from 'utils/formValidation'
import { Country } from 'forms/formSections/Country/Country'
import { State } from 'forms/formSections/State/State'
import { AddressAutocompleteInput } from 'components/Input/AddressAutocompleteInput'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { page } from 'translations'

import * as styled from '../Contacts.styled'
import { ContactFormData } from '../Contacts.types'
import {
  isCountryNilOrAustralia,
  setParsedGoogleAddressToFormValues,
} from '../Contacts.utils'

export const FIELDS_TEST_ID = {
  country: 'contact-country-field',
  state: 'contact-state-field',
}

interface AddressInputFieldProps {
  contactType: ContactType
}

export const AddressInputFields = ({ contactType }: AddressInputFieldProps) => {
  const { setValues, values } = useFormikContext<ContactFormData>()

  const isBusinessContactType = contactType === ContactType.BUSINESS

  const country = isBusinessContactType
    ? values.business.country
    : values.person.country

  const fieldPrefix = isBusinessContactType ? 'business' : 'person'

  const isNilOrAustralia = isCountryNilOrAustralia(country)

  return (
    <>
      <Box mb="1.5rem">
        <AddressAutocompleteInput
          name={`${fieldPrefix}.street`}
          country=""
          label={page.contacts.fields.street}
          setFullAddressValues={setParsedGoogleAddressToFormValues(
            contactType,
            setValues
          )}
          size={INPUT_SIZE.MEDIUM}
          maxLength={200}
        />
      </Box>
      <Box mb="1.5rem" data-testid={FIELDS_TEST_ID.country}>
        <Country customName={`${fieldPrefix}.country`} />
      </Box>
      <styled.RowContainer>
        <Flex flexDirection="column">
          <InputAdaptiveFieldWrapper
            name={`${fieldPrefix}.postcode`}
            placeholder={page.contacts.fields.postcode}
            validate={
              isNilOrAustralia
                ? (value) => makeOptional(validatePostcode, value)
                : undefined
            }
          />
        </Flex>
        <Flex flexDirection="column">
          <InputAdaptiveFieldWrapper
            name={`${fieldPrefix}.suburb`}
            placeholder={page.contacts.fields.suburb}
            validate={
              isNilOrAustralia
                ? (value) => makeOptional(validateSuburb, value)
                : undefined
            }
          />
        </Flex>
        <Flex flexDirection="column" data-testid={FIELDS_TEST_ID.state}>
          {isNilOrAustralia ? (
            <State customName={`${fieldPrefix}.state`} />
          ) : (
            <InputAdaptiveFieldWrapper
              name={`${fieldPrefix}.state`}
              placeholder={page.contacts.fields.state}
            />
          )}
        </Flex>
      </styled.RowContainer>
    </>
  )
}
