import { useState } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'

import { EditState, EntityCategoryValues } from './EditZellerCategories.types'
import { CategoryField } from './Fields/CategoryField'
import { SubcategoryField } from './Fields/SubcategoryField'

interface Props<V extends EntityCategoryValues> {
  initialCategory: EntityCategories | null
  initialSubcategory: string | null
  onEditSubcategoryStateChange: ((newState: EditState) => void) | undefined
  onEditSubcategory?: (values: V) => void
  onDeleteSubcategory?: (values: V) => void
}

export const ZellerCategoryDropdownFields = <V extends EntityCategoryValues>({
  initialCategory,
  initialSubcategory,
  onEditSubcategoryStateChange,
  onEditSubcategory,
  onDeleteSubcategory,
}: Props<V>) => {
  const [selectedCategory, setSelectedCategory] = useState<
    EntityCategories | undefined
  >(initialCategory || undefined)

  return (
    <>
      <Box mb="1.5rem">
        <CategoryField
          setSelectedCategory={setSelectedCategory}
          initialValue={initialCategory}
        />
      </Box>
      <Box>
        <SubcategoryField
          selectedCategory={selectedCategory}
          initialValue={initialSubcategory}
          onEditStateChange={onEditSubcategoryStateChange}
          onEditSubcategory={onEditSubcategory}
          onDeleteSubcategory={onDeleteSubcategory}
        />
      </Box>
    </>
  )
}
