import { gql } from '@apollo/client'

export const GetCustomerProfileDetails = gql`
  query GetCustomerProfileDetails($entityUuid: ID!, $customerUuid: ID!) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      firstname
      middlename
      lastname
      email
      phone
      address {
        country
        street
        suburb
        state
        postcode
      }
      registeringIndividual
      role
      kycStatus
    }
  }
`

export const GetCustomers = gql`
  query GetCustomers($entityUuid: ID!) {
    getCustomers(entityUuid: $entityUuid) {
      id
      entityUuid
      firstname
      middlename
      lastname
      role
      isInvitationPending
      kycStatus
      siteCount
    }
  }
`

export const GetCustomerBasicInfo = gql`
  query GetCustomerBasicInfo($entityUuid: ID!, $customerUuid: ID!) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      firstname
      middlename
      lastname
      role
      isInvitationPending
      kycStatus
    }
  }
`

export const GetCustomerPhone = gql`
  query GetCustomerPhone($entityUuid: ID!, $customerUuid: ID!) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      phone
    }
  }
`

export const GetCustomerReferralCode = gql`
  query GetCustomerReferralCode($entityUuid: ID!, $customerUuid: ID!) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      referralCode
    }
  }
`

export const GetCustomerDetails = gql`
  query GetCustomerDetails($entityUuid: ID!, $customerUuid: ID) {
    getCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid) {
      id
      entityUuid
      firstname
      middlename
      lastname
      email
      phone
      role
      registeringIndividual
      sites {
        id
        name
        devices {
          name
        }
      }
      isInvitationPending
      kycStatus
      permissions {
        allowDiscountManagement
        allowItemManagement
        allowZellerInvoices
        allowXeroPaymentServices
      }
    }
  }
`
