import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { translations as translationsModal } from '../../../CreateExternalAccountModal.i18n'
import { ExternalAccountFields } from '../../../CreateExternalAccountModal.types'
import { ExternalAccountLoading } from '../ExternalAccountLoading/ExternalAccountLoading'
import { translations } from '../ExternalAccountReviewStage.i18n'
import { StyledInfoDetails } from '../ExternalAccountReviewStage.styled'
import { ReviewItem } from '../ReviewItem/ReviewItem'

interface ExternalAccountReviewContentProps {
  isLoading: boolean
  values: ExternalAccountFields
}
export const ExternalAccountReviewContent = ({
  isLoading,
  values,
}: ExternalAccountReviewContentProps) => {
  const tModal = useTranslations(translationsModal)
  const t = useTranslations(translations)

  return (
    <>
      {isLoading ? (
        <ExternalAccountLoading />
      ) : (
        <>
          <Box width={{ _: '100%', MD: '50%' }} mb="24px">
            <ReviewItem
              label={tModal('nameField')}
              value={values.accountName}
            />
            <ReviewItem label={tModal('bsbField')} value={values.accountBsb} />
            <ReviewItem
              label={tModal('accountNumberField')}
              value={values.accountNumber}
            />
          </Box>
          <StyledInfoDetails>{t('description')}</StyledInfoDetails>
        </>
      )}
    </>
  )
}
