import { useContext } from 'react'

import { CreateExternalAccountState } from '../CreateExternalAccount.types'
import { CreateExternalAccountContext } from './CreateExternalAccountContext'

export const useCreateExternalAccountContext = <
  T extends CreateExternalAccountState
>() => {
  const context = useContext(CreateExternalAccountContext)

  if (!context) {
    throw new Error(
      'useCreateExternalAccountContext must be used within CreateExternalAccountContext'
    )
  }

  const { state, goToCreateExternalAccountState } = context

  return {
    state: state ? (state as T) : null,
    goToCreateExternalAccountState,
  }
}
