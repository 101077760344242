import { gql, type TypedDocumentNode } from '@apollo/client'

import { OutstandingExpensesFormFragmentDoc } from './components/OutstandingExpensesForm/OutstandingExpensesForm.generated'

export type OutstandingExpensesContentFragment = {
  __typename?: 'Entity'
  id: string
  outstandingTransactionRequirementConfig: {
    __typename?: 'OutstandingTransactionRequirementConfig'
    attachments: boolean
    category: boolean
    note: boolean
    accountingCategory: boolean
  } | null
}

export const OutstandingExpensesContentFragmentDoc = gql`
  fragment OutstandingExpensesContentFragment on Entity {
    ...OutstandingExpensesFormFragment
  }
  ${OutstandingExpensesFormFragmentDoc}
` as unknown as TypedDocumentNode<OutstandingExpensesContentFragment, undefined>
