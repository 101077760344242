import { Route, Routes } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { DepositsSettingsProvider } from 'pages/Deposits/DepositsSettingsContext/DepositsSettingsContext'
import { NotFound } from 'pages/NotFound'
import { DepositSettings } from 'pages/Settings/DepositsSettings'
import { DepositSettingsFetcher } from 'pages/Settings/DepositsSettings/DepositSettingsFetcher/DepositSettingsFetcher'
import { ProfileDetailsFetcher } from 'pages/Settings/Profile/ProfileDetailsFetcher/ProfileDetailsFetcher'
import { page } from 'translations'

export const DepositsSettingsRoutes = () => {
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <DepositsSettingsProvider>
      <PageTemplate
        HeaderPrimaryRow={
          <PageTemplate.HeaderPrimaryRow>
            <Flex height="48px">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Link
                    to={ROOT.ORGS.ORG(shortEntityId).PAYMENTS.DEPOSITS.path}
                  >
                    {page.title.deposits}
                  </Breadcrumb.Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>{page.title.settings}</Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Flex>
          </PageTemplate.HeaderPrimaryRow>
        }
      >
        <PageTemplate.Section>
          <DepositSettingsFetcher>
            <ProfileDetailsFetcher>
              <Routes>
                <Route index element={<DepositSettings />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ProfileDetailsFetcher>
          </DepositSettingsFetcher>
        </PageTemplate.Section>
      </PageTemplate>
    </DepositsSettingsProvider>
  )
}
