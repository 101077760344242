import { BillingAccountType } from '@npco/mp-gql-types'
import { showApiErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import { translate } from 'utils/translations'
import { ThirdPartyBankAccount } from 'types/accounts'

import { useSimBillingAccountSelection } from '../hooks/useFilterSimBillingAccount'

interface UseSelectExternalBankTileProps {
  onSuccess?: (account: ThirdPartyBankAccount) => void
}

export const useSelectExternalBankTile = ({
  onSuccess,
}: UseSelectExternalBankTileProps) => {
  const { selectSimBillingAccount, isLoading } =
    useSimBillingAccountSelection<ThirdPartyBankAccount>()

  const changeToExternalAccount = async (
    currentAccount: ThirdPartyBankAccount
  ) => {
    try {
      await selectSimBillingAccount(
        currentAccount,
        BillingAccountType.NOMINATED
      )

      rvSimBillingAccount(currentAccount)
      onSuccess?.(currentAccount)
      showSuccessToast(
        translate(
          'page.settings.sim.billingAccount.changingSimBillingAccountSuccessToast',
          { accountName: currentAccount.name }
        )
      )
    } catch (err) {
      showApiErrorToast()
    }
  }
  return {
    isLoading,
    changeToExternalAccount,
  }
}
