import { useCallback } from 'react'
import { ModalBasic, showSuccessToast } from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import * as styled from './InvoiceScheduleModal.styled'

export const translations = {
  title: translate('page.invoice.scheduleInvoiceModalTitle'),
  message: translate('page.invoice.scheduleInvoiceModalMessage'),
  secondaryButton: translate(
    'page.invoice.scheduleInvoiceModalSecondaryButtonLabel'
  ),
  primaryButton: translate(
    'page.invoice.scheduleInvoiceModalPrimaryButtonLabel'
  ),
  getSaveInvoiceSuccess: (referenceNumber: string) =>
    translate('page.invoice.saveInvoiceSuccess', {
      referenceNumber,
    }),
}

interface InvoiceScheduleModalProps {
  closeModal: () => void
  isLoading: boolean
  isOpen: boolean
  onSend: () => void
}

export const InvoiceScheduleModal = ({
  closeModal,
  isLoading,
  isOpen,
  onSend,
}: InvoiceScheduleModalProps) => {
  const { values } = useFormikContext<InvoiceFormFields>()

  const handleCloseAndToast = useCallback(() => {
    showSuccessToast(translations.getSaveInvoiceSuccess(values.referenceNumber))
    closeModal()
  }, [closeModal, values.referenceNumber])

  return (
    <ModalBasic
      hasCloseButton
      shouldCloseOnOverlayClick={!isLoading}
      isOpen={isOpen}
      title={translations.title}
      onCancel={closeModal}
    >
      <ModalBasic.Body>
        <styled.Message>{translations.message}</styled.Message>
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton onClick={handleCloseAndToast}>
          {translations.secondaryButton}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton isLoading={isLoading} onClick={onSend}>
          {translations.primaryButton}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}
