import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'

import { LandingPageOption } from '../LandingPageForm.types'

export const useLandingPageForm = () => {
  const { goToAddCardStage } = useGoToAddCardStage()

  const handleSubmit = useCallback(
    (values) => {
      const goToCreateCardFlow = () =>
        goToAddCardStage({ stage: AddCardStage.CreateSelectCardTypeStage })

      const goToLinkCardFlow = () =>
        goToAddCardStage({
          stage: AddCardStage.LinkCardIdStage,
          linkCardError: undefined,
        })

      if (values.linkOrCreate === LandingPageOption.CreateCard) {
        goToCreateCardFlow()
      }

      if (values.linkOrCreate === LandingPageOption.LinkCard) {
        goToLinkCardFlow()
      }
    },
    [goToAddCardStage]
  )

  const handleBack = useCallback(
    () => goToAddCardStage(undefined),
    [goToAddCardStage]
  )

  return { handleSubmit, handleBack }
}
