import { TableData } from '@npco/ui-table'

import {
  AverageHeaderCell,
  TotalHeaderCell,
} from '../../../TransactionsSummary/TableCells/TableHeaderCells'
import {
  AverageCell,
  TotalCell,
} from '../../../TransactionsSummary/TableCells/TableRowCells'
import { CategoryDrawerTableCashFlowSubcategoryNetAmountFragment } from './CategoryDrawerTable.generated'
import { SubcategoryHeaderCell } from './TableCells/TableHeaderCells'
import { SubcategoryCell } from './TableCells/TableRowCells'

export const columnsConfig = [
  {
    accessorId: 'subcategoryCell',
    cellComponent: SubcategoryCell<
      TableData<CategoryDrawerTableCashFlowSubcategoryNetAmountFragment>
    >,
    headerComponent: SubcategoryHeaderCell,
    cellSize: { _: 14.5, sm: 10 },
    cellSkeletonWidth: [
      { _: 114, SM: 124 },
      { _: 106, SM: 132 },
      { _: 82, SM: 84 },
    ],
  },
  {
    accessorId: 'averageCell',
    cellComponent: AverageCell<
      TableData<CategoryDrawerTableCashFlowSubcategoryNetAmountFragment>
    >,
    headerComponent: AverageHeaderCell,
    cellSize: { _: 0, sm: 4.5 },
    cellSkeletonWidth: [{ SM: 54 }, { SM: 46 }, { SM: 86 }],
  },
  {
    accessorId: 'totalCell',
    cellComponent: TotalCell<
      TableData<CategoryDrawerTableCashFlowSubcategoryNetAmountFragment>
    >,
    headerComponent: TotalHeaderCell,
    cellSize: { _: 6, sm: 6 },
    cellSkeletonWidth: [
      { _: 60, SM: 89 },
      { _: 52, SM: 65 },
      { _: 36, SM: 41 },
    ],
  },
]
