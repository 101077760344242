import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import {
  GetContactOrMerchantLocationDataContactFragmentDoc,
  GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc,
} from '../utils/contacts.utils.generated'
import {
  BusinessContactDetailsContactFragmentDoc,
  BusinessContactDetailsMerchantDetailsFragmentDoc,
} from './BusinessContactDetails.generated'
import { GoToContactsContactFragmentDoc } from './GoToContacts/GoToContacts.generated'
import {
  PersonContactDetailsContactFragmentDoc,
  PersonContactDetailsMerchantDetailsFragmentDoc,
} from './PersonContactDetails.generated'

export type ContactContentMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  location: string | null
  locationAccuracy: number | null
  phone: string | null
  hours: string | null
  email: string | null
  url: string | null
  abn: string | null
  address: {
    __typename?: 'MerchantAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street1: string | null
    street2: string | null
    suburb: string | null
  } | null
}

export type ContactContentContactFragment = {
  __typename: 'Contact'
  contactType: Types.ContactType
  category: Types.EntityCategories | null
  subcategoryUuid: string | null
  location: string | null
  locationAccuracy: number | null
  phone: string | null
  jobTitle: string | null
  abn: string | null
  acn: string | null
  businessName: string | null
  firstName: string | null
  id: string
  lastName: string | null
  notes: string | null
  isSelf: boolean | null
  tags: Array<string | null> | null
  website: string | null
  subcategory: string | null
  address: {
    __typename?: 'ContactAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street: string | null
    suburb: string | null
  } | null
  phoneV2: {
    __typename?: 'ContactPhone'
    phone: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  } | null
  email: {
    __typename?: 'ContactEmail'
    email: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  } | null
  additionalEmails: Array<{
    __typename?: 'ContactEmail'
    email: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  }> | null
  additionalPhones: Array<{
    __typename?: 'ContactPhone'
    phone: string
    label: {
      __typename?: 'Label'
      id: string
      isEditable: boolean
      labelText: string
      type: Types.LabelType
    } | null
  }> | null
  contacts: Array<{
    __typename: 'Contact'
    abn: string | null
    acn: string | null
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    id: string
    jobTitle: string | null
    lastName: string | null
    phone: string | null
    website: string | null
    location: string | null
    locationAccuracy: number | null
    category: Types.EntityCategories | null
    subcategory: string | null
    subcategoryUuid: string | null
    additionalEmails: Array<{
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    additionalPhones: Array<{
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    address: {
      __typename?: 'ContactAddress'
      country: string | null
      postcode: string | null
      state: string | null
      street: string | null
      suburb: string | null
    } | null
    email: {
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    phoneV2: {
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
  } | null> | null
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  paymentInstruments: Array<{
    __typename?: 'PaymentInstrument'
    id: string
    status: Types.PaymentInstrumentStatus
    bankAccountDetails: {
      __typename?: 'BankAccountDetails'
      account: string | null
      bsb: string | null
      name: string | null
    } | null
    details:
      | { __typename?: 'BankAccountDetails' }
      | { __typename?: 'BpayDetails' }
      | { __typename?: 'NppDetails' }
      | {
          __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
          billerName: string
          billerCode: string
          nickname: string | null
        }
      | {
          __typename: 'PaymentInstrumentBpayStaticCrnDetails'
          billerName: string
          billerCode: string
          nickname: string | null
          crn: string
        }
  }> | null
}

export const ContactContentMerchantDetailsFragmentDoc = gql`
  fragment ContactContentMerchantDetailsFragment on MerchantDetails {
    ...GetContactOrMerchantLocationDataMerchantDetailsFragment
    ...PersonContactDetailsMerchantDetailsFragment
    ...BusinessContactDetailsMerchantDetailsFragment
  }
  ${GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc}
  ${PersonContactDetailsMerchantDetailsFragmentDoc}
  ${BusinessContactDetailsMerchantDetailsFragmentDoc}
` as unknown as TypedDocumentNode<
  ContactContentMerchantDetailsFragment,
  undefined
>
export const ContactContentContactFragmentDoc = gql`
  fragment ContactContentContactFragment on Contact {
    ...GetContactOrMerchantLocationDataContactFragment
    contactType
    category
    subcategoryUuid
    ...PersonContactDetailsContactFragment
    ...BusinessContactDetailsContactFragment
    ...GoToContactsContactFragment
  }
  ${GetContactOrMerchantLocationDataContactFragmentDoc}
  ${PersonContactDetailsContactFragmentDoc}
  ${BusinessContactDetailsContactFragmentDoc}
  ${GoToContactsContactFragmentDoc}
`
