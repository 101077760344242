type ErrorMap<K, V> = {
  [key in keyof K]: V
} & { default: V }

const errorMapContains = <K, V>(errorMap: ErrorMap<K, V>, code: string) => {
  return Boolean(Object.keys(errorMap).find((errorCode) => errorCode === code))
}

export const getErrorMessage = <K, V>(
  errorMap: ErrorMap<K, V>,
  code?: string
): V => {
  if (code && errorMapContains(errorMap, code)) {
    return errorMap[code as keyof K]
  }
  return errorMap.default
}
