import { useTranslations } from '@npco/utils-translations'
import { RadioGroupFormik, RadioListCard } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { MotoSurcharge, SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { getMaxCardSurchargeText } from 'pages/Settings/SitePayments/SitePayments.utils'

import { translations } from './Moto.i18n'
import * as styled from './Moto.styled'
import { MotoSurchargeInput } from './MotoSurchargeInput.fields'

export interface Props {
  siteData: SiteCache
}

export const MotoSurchargeOptions = ({ siteData }: Props) => {
  const { values } = useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)

  return (
    <>
      <RadioGroupFormik
        name="motoPaymentsSurchargeType"
        isVerticalDisplay
        gap="8px"
      >
        <RadioListCard
          id="moto-surcharge-type-total-fees"
          title={t('totalFees')}
          value={MotoSurcharge.TotalFees}
        />
        <RadioListCard
          id="moto-surcharge-type-percentage"
          title={t('percentage')}
          value={MotoSurcharge.Percentage}
        />
      </RadioGroupFormik>

      {!Number(values.motoPaymentsSurchargeType) && (
        <>
          <styled.Description>
            {t('description1')}&nbsp;
            {getMaxCardSurchargeText(siteData?.surchargesTaxes?.feePercentMoto)}
          </styled.Description>
          <div>
            <MotoSurchargeInput siteData={siteData} />
          </div>
        </>
      )}
    </>
  )
}
