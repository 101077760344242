import {
  DebitCardTransactionStatusV2,
  StringFilterInput,
} from '@npco/mp-gql-types'
import { GetDebitCardTransactionsQueryVariables } from 'api/useQueryCardTransactions/graphql/GetDebitCardTransactions.generated'
import { equals } from 'ramda'

import dayjs from 'utils/dayjs'
import {
  mapAmountRangeFilterToApi,
  mapFilterableTypesToApiTypes,
} from 'utils/mapFiltersInputToApiFilter'
import { FilterableTransactionType } from 'layouts/AccountLayout/filters/AccountFilterTransactionType/AccountFilterTransactionType.constants'
import {
  AccountFiltersValues,
  DateRange,
  DEFAULT_STATUS,
  DEFAULT_TRANSACTION_TYPES,
  ReceiptIncluded,
} from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'

export interface Arguments
  extends Omit<
    AccountFiltersValues,
    'areFiltersInDefaultState' | 'resetAllFilters'
  > {
  selectedAccountID: string | undefined
  selectedDebitCardId?: string
  selectedContactId?: string
}

const getTimestampFilter = (
  date: DateRange | null
): StringFilterInput | null => {
  if (date === null) {
    return null
  }

  return {
    between: [
      dayjs(date.from).startOf('day').toISOString(),
      dayjs(date.to).endOf('day').toISOString(),
    ],
  }
}

type Filters = NonNullable<GetDebitCardTransactionsQueryVariables['filter']>

const filterOutNulls = (fields: Filters): Filters => {
  const entries = Object.entries(fields)
  const filteredEntries = entries.filter(([, value]) => value !== null)

  return Object.fromEntries(filteredEntries)
}

const buildTagsFilters = (tags: string[]) =>
  tags.map((tag) => ({ tags: { contains: tag } }))

const mapToAttachmentsFlag = (receiptFilters: ReceiptIncluded[]) => {
  if (receiptFilters.length !== 1) {
    return null
  }

  return receiptFilters[0] === ReceiptIncluded.INCLUDED
}

const shouldTransactionTypeFilterBeNull = (
  transactionType: FilterableTransactionType[] | null
) => {
  return !transactionType || equals(transactionType, DEFAULT_TRANSACTION_TYPES)
}

const shouldStatusFilterBeNull = (
  status: DebitCardTransactionStatusV2[] | null
) => {
  return !status || equals(status, DEFAULT_STATUS)
}

export const mapAccountTransactionFiltersToApiFilters = ({
  selectedAccountID,
  selectedContactId,
  selectedDebitCardId,
  status,
  transactionType,
  amount,
  description,
  tags,
  contact,
  accountingCategory,
  category,
  date,
  receipt,
}: Arguments): Filters => {
  const contactId = contact?.value ?? selectedContactId ?? null

  return filterOutNulls({
    debitCardAccountUuid: selectedAccountID
      ? {
          eq: selectedAccountID,
        }
      : null,
    debitCardId: selectedDebitCardId
      ? {
          eq: selectedDebitCardId,
        }
      : null,
    status: shouldStatusFilterBeNull(status) ? null : { in: status },
    type: shouldTransactionTypeFilterBeNull(transactionType)
      ? null
      : { in: mapFilterableTypesToApiTypes(transactionType) },
    amount: amount ? mapAmountRangeFilterToApi([amount.min, amount.max]) : null,
    accountingCategory: accountingCategory ? { eq: accountingCategory } : null,
    contactUuid: contactId ? { eq: contactId } : null,
    reference: description ? { contains: description } : null,
    category: category?.category ? { eq: category.category } : null,
    subcategory: category?.subcategory ? { eq: category.subcategory } : null,
    timestamp: getTimestampFilter(date),
    or: tags.length > 0 ? buildTagsFilters(tags) : null,
    attachment: mapToAttachmentsFlag(receipt),
  })
}
