import { gql } from '@apollo/client'
import { getImageUrl } from '@npco/mp-utils-icon'
import { COLOR, getColorWithName } from '@npco/zeller-design-system'

import { GetContactAvatarOptionsContactFragment } from './getContactAvatarOptions.generated'

const DEFAULT_ICON_LETTER = 'Z'

export const getContactAvatarOptions = ({
  icon,
}: GetContactAvatarOptionsContactFragment) => {
  const bgColor = getColorWithName(icon?.colour)
  const profilePictureUrl = getImageUrl(icon)

  return {
    bgColor: !bgColor || bgColor === COLOR.GREY_250 ? COLOR.GREY_90 : bgColor,
    text: icon?.letter ?? DEFAULT_ICON_LETTER,
    userDefinedContent: profilePictureUrl ? (
      <img src={profilePictureUrl} alt="contact-profile" />
    ) : undefined,
  }
}

getContactAvatarOptions.fragments = {
  Contact: gql`
    fragment GetContactAvatarOptionsContactFragment on Contact {
      icon {
        colour
        letter
        ...GetImageUrlIconFragment
      }
    }

    ${getImageUrl.fragments.Icon}
  `,
}
