import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetDebitCardTransactionAttachmentDownloadUrls } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/debitCardTransactions'

import { isNotNull } from 'utils/common'
import {
  GetDebitCardTransactionAttachmentDownloadUrls as GetDebitCardTransactionAttachmentDownloadUrlsResponse,
  GetDebitCardTransactionAttachmentDownloadUrlsVariables,
} from 'types/gql-types/GetDebitCardTransactionAttachmentDownloadUrls'

export const useUpdateDebitCardTransactionAttachmentDownloadUrls = () => {
  const { cache } = useApolloClient()
  const entityUuid = useSelectedEntityUuid()

  const removeAttachmentDownloadUrlByDocumentId = useCallback(
    (transactionUuid: string, removeImageId: string) => {
      const outdatedDebitCardTransactionAttachmentDownloadUrlsQueries =
        cache.readQuery<
          GetDebitCardTransactionAttachmentDownloadUrlsResponse,
          GetDebitCardTransactionAttachmentDownloadUrlsVariables
        >({
          query: GetDebitCardTransactionAttachmentDownloadUrls,
          variables: { transactionUuid, entityUuid },
        })

      if (
        !outdatedDebitCardTransactionAttachmentDownloadUrlsQueries?.getDebitCardTxnAttachmentDownloadUrls
      ) {
        return
      }

      const updatedDebitCardTransactionAttachmentDownloadUrlsQueries =
        outdatedDebitCardTransactionAttachmentDownloadUrlsQueries.getDebitCardTxnAttachmentDownloadUrls
          .filter(isNotNull)
          .filter((obj) => obj.documentUuid !== removeImageId)

      cache.writeQuery<
        GetDebitCardTransactionAttachmentDownloadUrlsResponse,
        GetDebitCardTransactionAttachmentDownloadUrlsVariables
      >({
        query: GetDebitCardTransactionAttachmentDownloadUrls,
        variables: { transactionUuid, entityUuid },
        data: {
          getDebitCardTxnAttachmentDownloadUrls:
            updatedDebitCardTransactionAttachmentDownloadUrlsQueries,
        },
      })
    },
    [cache, entityUuid]
  )

  return { removeAttachmentDownloadUrlByDocumentId }
}
