import { ContactType } from '@npco/mp-gql-types'
import {
  ErrorMessageForm,
  InputSelectComboboxItemRenderProps,
  InputSelectComboboxStandard,
} from '@npco/zeller-design-system'

import { page } from 'translations'

import { InputSelectComboBoxItem } from '../Contacts.types'
import { getContactName } from '../Contacts.utils'
import { ContactCoreFieldsFragment } from '../graphql/ContactCoreFields.generated'
import { useComboBoxSelect } from '../hooks/useComboBoxSelect/useComboBoxSelect'
import {
  StyledLabel,
  StyledPersonComboboxWrapper,
  StyledSubLabel,
} from './PersonComboBoxSelect.styled'

interface PersonComboBoxSelectProps {
  isAddingNew: boolean
  isOptional: boolean
  placeholder: string
  setIsAddingNew: (value: boolean, inputValue?: string | null) => void
  selectedItem?: InputSelectComboBoxItem | null
  setSelectedItem: (item: InputSelectComboBoxItem | null) => void
  setValidationError: (validationError: string) => void
  validationError: string
}

export const PersonComboBoxSelect = ({
  isAddingNew,
  isOptional,
  placeholder,
  setIsAddingNew,
  selectedItem,
  setSelectedItem,
  setValidationError,
  validationError,
  ...props
}: PersonComboBoxSelectProps) => {
  const {
    contacts,
    filterItems,
    hasMore,
    inputValue,
    loading,
    loadMore,
    onChange,
    onClose,
    onInputChange,
    onInputClear,
  } = useComboBoxSelect({
    contactType: ContactType.PERSON,
    isOptional,
    setIsAddingNew,
    setSelectedItem,
    setValidationError,
  })

  const items =
    contacts.map((item) => {
      const label = getContactName(item)
      const {
        contacts: subContacts,
        email,
        phone,
      } = item as ContactCoreFieldsFragment
      const subLabel = subContacts?.[0]?.businessName || email?.email || phone

      return {
        label,
        subLabel,
        value: label || null,
        id: item?.id || null,
      } as InputSelectComboBoxItem
    }) || []

  const renderItem = (
    renderProps: InputSelectComboboxItemRenderProps<InputSelectComboBoxItem>
  ) => {
    const renderPropWithCorrectKey = {
      ...renderProps,
      key: renderProps.item.id,
    }
    return (
      <StyledPersonComboboxWrapper {...renderPropWithCorrectKey}>
        <StyledLabel>{renderProps.item.label}</StyledLabel>
        <StyledSubLabel>{renderProps.item.subLabel}</StyledSubLabel>
      </StyledPersonComboboxWrapper>
    )
  }

  return (
    <>
      <InputSelectComboboxStandard
        {...props}
        addNewItemContent={page.contacts.form.createNew}
        filterItems={filterItems}
        hasError={Boolean(validationError)}
        inputValue={inputValue}
        isLoading={loading}
        items={items}
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onInputClear={onInputClear}
        placeholder={placeholder}
        renderItem={renderItem}
        selectedItem={selectedItem}
        shouldHideSuggestions={isAddingNew}
        hasMore={hasMore}
        onLoadMoreItems={loadMore}
      />
      <ErrorMessageForm
        hasError={Boolean(validationError)}
        errorMessage={validationError}
      />
    </>
  )
}
