import { useCallback, useEffect, useRef, useState } from 'react'
import { ApolloError } from '@apollo/client'

import { createFileFromUrl } from 'utils/file'
import { FileWithId } from 'components/File'

import { InvoiceCustomisationFormFieldsAndPreviewDefaults } from '../Invoice.types'
import { useGetInvoicePreviewDownloadLink } from './useGetInvoicePreviewDownloadLink'

interface UseGetInvoicePreviewProps {
  onError?: () => void
  onSuccess?: () => void
  isCustomisationPreview?: boolean
}

export const useGetInvoicePreview = (
  options: UseGetInvoicePreviewProps = {}
) => {
  const isMounted = useRef(false)
  const optionsRef = useRef(options)
  const [invoicePdfError, setInvoicePdfError] = useState<ApolloError>()
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const [invoicePdf, setInvoicePdf] = useState<FileWithId | null>(null)
  const { isCustomisationPreview } = options
  const { getDownloadLink } = useGetInvoicePreviewDownloadLink({
    isCustomisationPreview,
    onError: setInvoicePdfError,
  })

  useEffect(() => {
    optionsRef.current = options
  }, [options])

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  const getPreview = useCallback(
    async (
      values: InvoiceCustomisationFormFieldsAndPreviewDefaults | string
    ) => {
      setIsLoadingPreview(true)
      setInvoicePdfError(undefined)
      try {
        const downloadLink = await getDownloadLink(values)

        if (downloadLink) {
          const file = await createFileFromUrl(
            downloadLink,
            '',
            'application/pdf'
          )
          if (file) {
            setInvoicePdf({ file, id: downloadLink })
          }

          optionsRef.current.onSuccess?.()
        }
      } catch (error) {
        setInvoicePdfError(error as ApolloError)
        optionsRef.current.onError?.()
      } finally {
        if (isMounted.current) {
          setIsLoadingPreview(false)
        }
      }
    },
    [getDownloadLink]
  )

  return {
    getPreview,
    invoicePdfError,
    invoicePdf,
    isLoadingPreview,
    setInvoicePdf,
  }
}
