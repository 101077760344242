import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  EducationPanel,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import CORPORATE_CARDS_EMPTY_LARGE_SRC from 'assets/images/corporate-cards-empty.png'
import CORPORATE_CARDS_EMPTY_MEDIUM_SRC from 'assets/images/corporate-cards-empty-medium.png'
import CORPORATE_CARDS_EMPTY_SMALL_SRC from 'assets/images/corporate-cards-empty-small.png'
import { translationsShared } from 'translations'

import { SideImageContainer } from '../../../../CardsView.styled'
import { cardEmptyViewCorporateTranslations } from './CardEmptyViewCorporate.i18n'
import { SideImage } from './CardEmptyViewCorporate.styled'

export interface CardEmptyViewProps {
  onAddCard: () => void
}

export const CardEmptyViewCorporate = ({ onAddCard }: CardEmptyViewProps) => {
  const t = useTranslations(cardEmptyViewCorporateTranslations)
  const tShared = useTranslations(translationsShared)
  const isMobileOrTabletBreakpoint = useIsMobileResolution(BREAKPOINT.LG)
  const isMobileBreakpoint = useIsMobileResolution(BREAKPOINT.SM)
  const imageSrc = useMemo(() => {
    if (isMobileOrTabletBreakpoint && !isMobileBreakpoint) {
      return CORPORATE_CARDS_EMPTY_MEDIUM_SRC
    }
    if (isMobileOrTabletBreakpoint) {
      return CORPORATE_CARDS_EMPTY_SMALL_SRC
    }
    return CORPORATE_CARDS_EMPTY_LARGE_SRC
  }, [isMobileOrTabletBreakpoint, isMobileBreakpoint])

  return (
    <EducationPanel
      data-testid="card-empty-view-corporate"
      image={
        <SideImageContainer>
          <SideImage src={imageSrc} alt={t('imageAlt')} />
        </SideImageContainer>
      }
      title={t('title')}
      description={t('description')}
      items={[t('item1'), t('item2'), t('item3'), t('item4'), t('item5')]}
      ctaDescription={t('ctaDescription')}
      ctaPrimary={{
        text: tShared('createCard'),
        onClick: onAddCard,
      }}
    />
  )
}
