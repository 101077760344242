import { useCallback } from 'react'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useGoToUpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage'
import { UpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'

export const useUpgradeAccountsCompleteModal = () => {
  const { goToAddCardStage } = useGoToAddCardStage()

  const { goToUpgradeAccountsCompleteStage } =
    useGoToUpgradeAccountsCompleteStage()

  const closeModal = useCallback(() => {
    goToUpgradeAccountsCompleteStage(undefined)
  }, [goToUpgradeAccountsCompleteStage])

  const goCompleteStartStage = useCallback(() => {
    goToUpgradeAccountsCompleteStage({
      stage: UpgradeAccountsCompleteStage.UpgradeAccountsCompleteStartStage,
    })
  }, [goToUpgradeAccountsCompleteStage])

  const goCompleteBuildBusinessStage = useCallback(() => {
    goToUpgradeAccountsCompleteStage({
      stage:
        UpgradeAccountsCompleteStage.UpgradeAccountsCompleteBuildBusinessStage,
    })
  }, [goToUpgradeAccountsCompleteStage])

  const goCompleteDebitCardsRewardsStage = useCallback(() => {
    goToUpgradeAccountsCompleteStage({
      stage: UpgradeAccountsCompleteStage.UpgradeAccountsDebitCardsRewardsStage,
    })
  }, [goToUpgradeAccountsCompleteStage])

  const goCompleteXeroIntegrationStage = useCallback(() => {
    goToUpgradeAccountsCompleteStage({
      stage: UpgradeAccountsCompleteStage.UpgradeAccountsXeroIntegrationStage,
    })
  }, [goToUpgradeAccountsCompleteStage])

  const goCompleteReadyToGoStage = useCallback(() => {
    goToUpgradeAccountsCompleteStage({
      stage: UpgradeAccountsCompleteStage.UpgradeAccountsReadyToGoStage,
    })
  }, [goToUpgradeAccountsCompleteStage])

  const goToAddCardStageFromCompleteStage = useCallback(() => {
    goToUpgradeAccountsCompleteStage(undefined)
    goToAddCardStage({ stage: AddCardStage.LandingPageStage })
  }, [goToUpgradeAccountsCompleteStage, goToAddCardStage])

  return {
    closeModal,
    goCompleteStartStage,
    goCompleteBuildBusinessStage,
    goCompleteXeroIntegrationStage,
    goCompleteDebitCardsRewardsStage,
    goCompleteReadyToGoStage,
    goToAddCardStageFromCompleteStage,
  }
}
