import { Flex } from '@npco/zeller-design-system'

import { ReactComponent as BoatSinking } from 'assets/svg/boat-sinking.svg'
import { component } from 'translations'

import {
  StyledExtraLargeDescription,
  StyledLargeTitle,
  StyledTextContainer,
} from '../Placeholders/SharedElements.styled'

export const TransactionTimeoutError = () => {
  return (
    <Flex
      alignItems={['flex-start', 'center']}
      justifyContent="center"
      height="100%"
      marginBottom={['16px', '200px', '144px']}
    >
      <Flex flexDirection="column" alignItems="center">
        <BoatSinking />
        <StyledTextContainer>
          <StyledLargeTitle>
            {component.noResults.titleTransactionsTimeout}
          </StyledLargeTitle>
          <StyledExtraLargeDescription>
            {component.noResults.description1TransactionsTimeout}
          </StyledExtraLargeDescription>
          <StyledExtraLargeDescription>
            {component.noResults.description2TransactionsTimeout}
          </StyledExtraLargeDescription>
        </StyledTextContainer>
      </Flex>
    </Flex>
  )
}
