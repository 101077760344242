import { ButtonGhost } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledButtonGhostBack = styled(ButtonGhost)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-width: 12.5rem;
    width: 0;
  }
`
