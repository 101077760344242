import {
  DebitCardTransactionFilterInputV2,
  DebitCardTransactionsExportFormat,
} from '@npco/mp-gql-types'

import { ExportFileModalContent } from 'components/ExportFileModalContent/ExportFileModalContent'

import { useDebitCardTransactionsExportModalContent } from './hooks/useDebitCardTransactionsExportModalContent'

interface Props {
  filename: string
  rerenderContent: () => void
  closeModal: () => void
  setHasTimePassed: React.Dispatch<React.SetStateAction<boolean>>
  format: DebitCardTransactionsExportFormat
  filters: DebitCardTransactionFilterInputV2
}

export const DebitCardExportTransactionsModalContent = ({
  filename,
  closeModal,
  rerenderContent,
  setHasTimePassed,
  format,
  filters,
}: Props) => {
  const { isLoading, hasError, hasLoaded } =
    useDebitCardTransactionsExportModalContent({
      filename,
      closeModal,
      setHasTimePassed,
      format,
      filters,
    })

  return (
    <ExportFileModalContent
      closeModal={closeModal}
      rerenderContent={rerenderContent}
      isLoading={isLoading}
      hasError={hasError}
      hasLoaded={hasLoaded}
    />
  )
}
