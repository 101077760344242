import styled from 'styled-components'

import { CategoriesPlaceholder } from 'components/EditCategoriesModal/Fields/Placeholders/CategoriesPlaceholder/CategoriesPlaceholder'

export const StyledPlaceholder = styled(CategoriesPlaceholder)`
  height: 13rem;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`
