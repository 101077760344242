export const yAxisTickFormatter = (value: number) => {
  const amount = value / 100

  if (amount < 1000) {
    return `$${amount}`
  }

  const roundedStartAmount = amount / 1000

  const stringifiedAmount = roundedStartAmount.toFixed(1)

  if (stringifiedAmount.endsWith('0')) {
    return `$${roundedStartAmount.toFixed(0)}k`
  }

  return `$${stringifiedAmount}k`
}
