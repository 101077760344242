import { PageTemplate, Tabs } from '@npco/zeller-design-system'

import { useCardsSettingsTabs } from './hooks/useCardsSettingsTabs'

export const CardsSettingsTabs = () => {
  const { tabs, currentTab } = useCardsSettingsTabs()

  return (
    <PageTemplate.HeaderSecondaryRow>
      <Tabs currentTab={currentTab}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.value} {...tab} />
        ))}
      </Tabs>
    </PageTemplate.HeaderSecondaryRow>
  )
}
