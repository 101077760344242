import { createTranslations } from '@npco/utils-translations'

export const saveAndCloseModalTranslations = createTranslations({
  title: 'Complete onboarding anytime',
  description:
    'You can continue onboarding were you left off by logging in to your account at {link}',
  saveAndLogout: 'Save & Log Out',
  unsavedChanges: 'Unsaved changes can be lost',
  linkLabel: 'www.myzeller.com',
  primaryButtonLabel: 'Save & Log Out',
  secondaryButtonLabel: 'Cancel',
})
