import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import { Flex, TileSelector, Typography } from '@npco/zeller-design-system'

import T1 from 'assets/images/t1.png'
import T1ShellBlack from 'assets/images/t1-shell-black.png'
import T1ShellWhite from 'assets/images/t1-shell-white.png'
import T2 from 'assets/images/t2.png'
import T2ShellBlack from 'assets/images/t2-shell-black.png'
import T2ShellGrey from 'assets/images/t2-shell-grey.png'
import T2ShellWhite from 'assets/images/t2-shell-white.png'
import { ImagePreview } from 'components/ModalUploadImage/ImagePreview/ImagePreview'

import { useSelectTerminal } from '../hooks/useSelectTerminal'
import { translations } from '../SiteAppearanceForm/SiteAppearanceForm.i18n'
import { TerminalModel } from '../TerminalPreview/TerminalPreview.types'
import * as styled from './TerminalSelector.styled'

export const TerminalSelector = () => {
  const t = useTranslations(translations)
  const flags = useFeatureFlags()

  const {
    values,
    handleT2TileClick,
    handleT1TileClick,
    handleBlackTileClick,
    handleWhiteTileClick,
    handleGreyTileClick,
  } = useSelectTerminal()

  return (
    <Flex flexDirection={{ _: 'column', XS: 'row' }}>
      <Flex
        gap="16px"
        flexDirection="column"
        pr={{ _: '0px', XS: '40px' }}
        pb={{ _: '24px', XS: '0px' }}
      >
        <Typography component="h4" variant="heading-lg">
          {t('model')}
        </Typography>
        <Flex gap="12px">
          {flags.T2Uplift && (
            <TileSelector
              name="t2-model"
              content={
                <styled.LogoWrapper>
                  <ImagePreview tabIndex={-1} src={T2} />
                </styled.LogoWrapper>
              }
              selected={values.selectedTerminalModel === TerminalModel.T2}
              value="0"
              onChange={handleT2TileClick}
            />
          )}
          <TileSelector
            name="t1-model"
            content={
              <styled.LogoWrapper>
                <ImagePreview tabIndex={-1} src={T1} />
              </styled.LogoWrapper>
            }
            onChange={handleT1TileClick}
            value="1"
            selected={values.selectedTerminalModel === TerminalModel.T1}
          />
        </Flex>
      </Flex>
      <Flex gap="16px" flexDirection="column">
        <Typography component="h4" variant="heading-lg">
          {t('modelColour')}
        </Typography>
        <Flex gap="12px">
          <TileSelector
            name="default-terminal-black"
            content={<styled.BlackTile />}
            selected={
              values.selectedTerminalModel === TerminalModel.T1
                ? values.selectedTerminalUrl === T1ShellBlack
                : values.selectedTerminalUrl === T2ShellBlack
            }
            value="2"
            onChange={handleBlackTileClick}
          />
          <TileSelector
            name="default-terminal-white"
            content={<styled.WhiteTile />}
            selected={
              values.selectedTerminalModel === TerminalModel.T1
                ? values.selectedTerminalUrl === T1ShellWhite
                : values.selectedTerminalUrl === T2ShellWhite
            }
            value="3"
            onChange={handleWhiteTileClick}
          />
          {values.selectedTerminalModel === TerminalModel.T2 && (
            <TileSelector
              name="default-terminal-grey"
              content={<styled.GreyTile />}
              selected={values.selectedTerminalUrl === T2ShellGrey}
              value="4"
              onChange={handleGreyTileClick}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
