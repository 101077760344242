import { ApolloError } from '@apollo/client'

import { ErrorInfoType, ServerError } from 'types/errors'

export const getErrorInfoType = (error?: ApolloError) => {
  const hasDisabledErrorInfoType = error?.graphQLErrors.some(
    ({ errorInfo }: ServerError) =>
      errorInfo?.type === ErrorInfoType.ACCOUNT_DISABLED
  )
  const hasInActiveErrorInfoType = error?.graphQLErrors.some(
    ({ errorInfo }: ServerError) =>
      errorInfo?.type === ErrorInfoType.ACCOUNT_INACTIVE
  )

  if (hasDisabledErrorInfoType) {
    return ErrorInfoType.ACCOUNT_DISABLED
  }

  if (hasInActiveErrorInfoType) {
    return ErrorInfoType.ACCOUNT_INACTIVE
  }

  return undefined
}
