import { EntityType } from '@npco/mp-gql-types'
import { sortBy } from 'ramda'

import {
  documentTypeByEntityMapping,
  documentTypeItemOptions,
  entityDocumentItemLabelMapping,
  onboardingDocumentTypeItemOptions,
} from 'const/documents'
import { FileListSelectItem } from 'components/File/File.types'

export const curriedSortByLabel = sortBy<FileListSelectItem>(
  ({ label }) => label
)

export const getDocUploadTypeOptionByEntity = (entityType: EntityType) => {
  const documentType = documentTypeByEntityMapping[entityType]

  if (!documentType) {
    return []
  }

  return [
    {
      label: entityDocumentItemLabelMapping[documentType],
      value: documentTypeByEntityMapping[entityType],
    },
  ]
}

export const getDocumentTypeOptionsByEntity = (
  entityType?: EntityType | null
) => {
  if (!entityType) {
    return documentTypeItemOptions
  }

  return curriedSortByLabel([
    ...documentTypeItemOptions,
    ...getDocUploadTypeOptionByEntity(entityType),
  ] as FileListSelectItem[])
}

export const getDocumentTypeWithOtherOptionsByEntity = (
  entityType?: EntityType | null
) => {
  if (!entityType) {
    return onboardingDocumentTypeItemOptions
  }

  return curriedSortByLabel([
    ...onboardingDocumentTypeItemOptions,
    ...getDocUploadTypeOptionByEntity(entityType),
  ] as FileListSelectItem[])
}
