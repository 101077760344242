import { useLocation } from 'react-router-dom-v5-compat'
import { useAccountsTransactionsMetadata } from 'features/AccountsTransactions'

import { ROOT } from 'const/routes'
import { getIsOnPage } from 'utils/common'

import { useGetEntityForAccountsLayout } from '../useGetEntityForAccountsLayout/useGetEntityForAccountsLayout'

export const useAccountsLayoutDeps = {
  useAccountsTransactionsMetadata,
  useGetEntityForAccountsLayout,
  useLocation,
}

export const useAccountsLayout = () => {
  const {
    useAccountsTransactionsMetadata,
    useGetEntityForAccountsLayout,
    useLocation,
  } = useAccountsLayoutDeps

  const {
    areFiltersInDefaultState: isFiltersInDefaultState,
    isFiltersVisible,
    isFiltersDisabled,
    setIsFiltersVisible,
  } = useAccountsTransactionsMetadata()

  const { entity, isLoadingEntity } = useGetEntityForAccountsLayout()
  const { pathname } = useLocation()

  const isAllTransactionsRoute = getIsOnPage(
    ROOT.ORGS.ORG().ACCOUNTS.TRANSACTIONS.path,
    pathname
  )

  return {
    entity,
    isAllTransactionsRoute,
    isFiltersDisabled,
    isFiltersInDefaultState,
    isFiltersVisible,
    isLoadingEntity,
    setIsFiltersVisible,
  }
}
