import { BodyDefault, SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  border-radius: 7px;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

export const FileContainer = styled(SvgIcon)`
  background: ${({ theme }) => theme.colors.BLUE_80};
  border-radius: 7px;
  padding: 0.75rem;
`

export const ItemBox = styled.div`
  align-items: center;
  border-radius: 7px;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  margin-bottom: 0.25rem;
  padding: 0.75rem;

  :hover {
    ${CloseButton} svg path {
      fill: ${({ theme }) => theme.colors.GREY_800};
    }
  }
`

export const Title = styled(BodyDefault)`
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
