import { gql } from '@apollo/client'

import { InvoiceListCoreFields } from './invoiceListCoreFieldsFragment'

export const InvoiceListUpdate = gql`
  ${InvoiceListCoreFields}
  subscription InvoiceListUpdate($entityUuid: ID!) {
    onInvoiceListUpdate(entityUuid: $entityUuid) {
      ...InvoiceListCoreFields
    }
  }
`
