import { Box, InputAdaptiveField } from '@npco/zeller-design-system'

import { CustomValidator } from 'types/common'
import { State } from 'forms/formSections/State'
import { FormLine } from 'components/FormLine'
import { form } from 'translations'

export interface AddressSectionProps {
  isFormDisabled?: boolean
  isStateText?: boolean
  mb?: string | number
  postcodeValidator?: CustomValidator<any>
  shouldValidate?: boolean
  stateValidator?: CustomValidator<string>
  suburbValidator?: CustomValidator<string>
}

export const Address = ({
  isFormDisabled,
  isStateText,
  mb = '40px',
  postcodeValidator,
  shouldValidate,
  stateValidator,
  suburbValidator,
}: AddressSectionProps) => {
  const validateState = shouldValidate ? stateValidator : undefined

  return (
    <FormLine>
      <Box mb={mb}>
        <InputAdaptiveField
          label={form.shared.suburb.label}
          name="suburb"
          type="text"
          validate={shouldValidate ? suburbValidator : undefined}
          disabled={isFormDisabled}
        />
      </Box>

      {isStateText ? (
        <Box mb={mb} data-testid="address-state">
          <InputAdaptiveField
            label={form.shared.state.label}
            placeholder={form.shared.state.alternativePlaceholder}
            name="state"
            type="text"
            validate={validateState}
            disabled={isFormDisabled}
          />
        </Box>
      ) : (
        <Box mb={[mb, '0']} data-testid="address-state">
          <State validator={validateState} isDisabled={isFormDisabled} />
        </Box>
      )}
      <Box mb={mb}>
        <InputAdaptiveField
          label={form.shared.postcode.label}
          name="postcode"
          type="text"
          validate={shouldValidate ? postcodeValidator : undefined}
          disabled={isFormDisabled}
        />
      </Box>
    </FormLine>
  )
}
