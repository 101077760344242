import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { QuestionMarkIcon } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translationsShared } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const NeedHelpItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translationsShared)

  return (
    <SidebarItem
      icon={<QuestionMarkIcon />}
      isFolded={isFolded}
      route={ROOT.ORGS.ORG(shortEntityId).HELP.path}
      isNavLink
    >
      {t('help')}
    </SidebarItem>
  )
}
