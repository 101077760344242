import { useState } from 'react'
import { gql } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useMultiEntityStore } from '@npco/mp-utils-multi-entity-store'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonAdditionalAction,
  ButtonGrey,
  Checkbox,
  COLOR,
  DotsVerticalIcon,
  Flex,
  ListCardButton,
  PillBasic,
  PillSize,
  Popper,
  Typography,
} from '@npco/zeller-design-system'

import { BusinessRowCustomerEntityRelationFragment } from './BusinessRow.generated'
import { businessRowTranslations } from './BusinessRow.i18n'
import { PopperButtonContainer } from './BusinessRow.styled'

interface BusinessRowProps {
  entityRelation: BusinessRowCustomerEntityRelationFragment
  isDisabled: boolean
}

export const BusinessRow = ({
  entityRelation,
  isDisabled,
}: BusinessRowProps) => {
  const t = useTranslations(businessRowTranslations)

  const { addEntitySession, removeEntitySession, changeCurrentEntitySession } =
    useMultiEntityStore()
  const [isHovered, setIsHovered] = useState(false)

  const featureFlags = useFeatureFlags()

  return (
    <Flex
      height="64px"
      alignItems="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      width="100%"
      aria-label={t('businessListLabel')}
    >
      <Box flex="1">
        <ListCardButton
          key={entityRelation.entity.id}
          title={entityRelation.entity.name}
          onClick={() => {
            changeCurrentEntitySession(entityRelation.entity.id)
          }}
          prefix={
            <Box mr="12px">
              <Checkbox
                name={entityRelation.entity.name ?? 'checkbox'}
                checked={entityRelation.isVisible}
                onChange={(event) => {
                  event.stopPropagation()

                  if (entityRelation.isVisible) {
                    removeEntitySession(entityRelation.entity.id)
                    return
                  }

                  addEntitySession(entityRelation.entity.id)
                }}
                disabled={isDisabled}
              />
            </Box>
          }
        >
          {entityRelation.role === CustomerRole.ADMIN ? (
            <PillBasic text={t('admin')} size={PillSize.Small} />
          ) : (
            <Typography variant="body-sm">{t('manager')}</Typography>
          )}
        </ListCardButton>
      </Box>
      <Box width="36px">
        {(featureFlags.MultiEntityLinkExisting ||
          featureFlags.MultiEntityOnboarding) &&
          isHovered && (
            <Box pl="8px" position="relative">
              <Popper
                dataTestId="popper"
                renderTrigger={({ setIsOpen }) => (
                  <ButtonAdditionalAction
                    height="28px"
                    width="28px"
                    onClick={() => {
                      setIsOpen(true)
                    }}
                  >
                    <DotsVerticalIcon />
                  </ButtonAdditionalAction>
                )}
              >
                {({ setIsOpen }) => {
                  return (
                    <PopperButtonContainer>
                      <ButtonGrey
                        onClick={() => {
                          setIsOpen(false)
                          setIsHovered(false)
                        }}
                        style={{ color: COLOR.RED_1000 }}
                      >
                        {t('removeBusiness')}
                      </ButtonGrey>
                    </PopperButtonContainer>
                  )
                }}
              </Popper>
            </Box>
          )}
      </Box>
    </Flex>
  )
}

BusinessRow.fragments = {
  CustomerEntityRelation: gql`
    fragment BusinessRowCustomerEntityRelationFragment on CustomerEntityRelation {
      role
      isVisible
      entity {
        id
        name
      }
    }
  `,
}
