import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { pluralTranslate } from 'utils/translations'

export const displayRemovalCompletionMessage = (
  numberOfSuccessfulRemovals: number,
  numberOfFailedRemovals: number
) => {
  if (numberOfSuccessfulRemovals === 0 && numberOfFailedRemovals === 0) {
    return
  }

  if (numberOfFailedRemovals !== 0) {
    showErrorToast(
      pluralTranslate(
        'errorMessages.failedToRemoveImage',
        'errorMessages.failedToRemoveImages',
        numberOfFailedRemovals,
        {
          numberOfFailedRemovals,
          totalRemovals: numberOfFailedRemovals + numberOfSuccessfulRemovals,
        }
      )
    )
    return
  }

  showSuccessToast(
    pluralTranslate(
      'page.transactionDetails.images.modal.removalSuccessSingular',
      'page.transactionDetails.images.modal.removalSuccessPlural',
      numberOfSuccessfulRemovals,
      {
        numberOfImagesRemoved: numberOfSuccessfulRemovals,
      }
    )
  )
}
