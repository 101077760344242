import { ReactNode } from 'react'
import { AccordionSimple } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export interface ClosedCardsProps {
  numberOfClosedCards: number
  children: ReactNode
}

export const ClosedCards = ({
  numberOfClosedCards,
  children,
}: ClosedCardsProps) => {
  if (numberOfClosedCards === 0) {
    return null
  }

  return (
    <AccordionSimple
      title={translate('page.cards.status.closed', {
        count: numberOfClosedCards,
      })}
      removeContentPadding
    >
      {children}
    </AccordionSimple>
  )
}
