import { createTranslations } from '@npco/utils-translations'

export const setCardSpendControlStageTranslations = createTranslations({
  submitActionErrorMessage:
    'SetCardSpendControlStage submitting with null state.',
  submitActionVelocityWindowMissingErrorMessage:
    'SetCardSpendControlStage submitting with undefined velocityWindow state.',
  backActionErrorMessage:
    'SetCardSpendControlStage back to SetCardSettingsStage with null state.',
})
