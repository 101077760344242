import { useCallback } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { useDisableNavigation } from '@npco/ui-drawer-navigation'
import { Box } from '@npco/zeller-design-system'
import {
  useEditDebitCardTransactionCategoryState,
  useGoToEditDebitCardTransactionCategory,
} from 'features/EditDebitCardTransactionCategory/edit-debit-card-transaction-category-routing'

import { useSubcategoryDisplay } from 'hooks/subcategories/useSubcategoryDisplay/useSubcategoryDisplay'
import { translate } from 'utils/translations'
import { CategoryDisplay } from 'components/CategoryDisplay/CategoryDisplay'
import { CategoryItem } from 'components/CategoryDisplay/CategoryItem'
import { getCategoryDisplay } from 'components/EditZellerCategories/Fields/utils/categories.utils'

interface Props {
  category: EntityCategories | null
  subcategoryId: string | null
  debitCardTransactionUuid: string
  contactUuid?: string
}

export const CategoryPills = ({
  category,
  subcategoryId,
  debitCardTransactionUuid,
  contactUuid,
}: Props) => {
  const categoryDisplay = getCategoryDisplay(category)
  const { subcategory, isLoading, error } = useSubcategoryDisplay({
    category,
    subcategoryId,
  })

  const { state } = useEditDebitCardTransactionCategoryState()

  useDisableNavigation({ shouldDisableNavigation: state !== null })

  const { goToEditDebitCardTransactionCategory } =
    useGoToEditDebitCardTransactionCategory()

  const openModal = useCallback(() => {
    goToEditDebitCardTransactionCategory({
      stage: 'edit',
      debitCardTransactionUuid,
      contactUuid,
      category,
      subcategoryDetails: subcategory || null,
    })
  }, [
    goToEditDebitCardTransactionCategory,
    debitCardTransactionUuid,
    contactUuid,
    category,
    subcategory,
  ])

  return (
    <Box mt="1.5rem">
      <CategoryItem
        label={translate('page.transactionDetails.category')}
        text={categoryDisplay?.text}
        icon={categoryDisplay?.icon}
        onEdit={openModal}
        dataTestId="category-pill"
      />

      <CategoryDisplay
        isLoadingSubcategory={isLoading}
        hasError={Boolean(error)}
        subcategoryName={subcategory?.name}
        onEdit={openModal}
      />
    </Box>
  )
}
