import { BREAKPOINT, ModalBasicProps } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { FooterButtons } from './FooterButtons'
import { FormButtons } from './FormButtons'
import * as styled from './MultiStateModal.styled'
import { ProgressBarProvider } from './ProgressBar/context/ProgressBarProvider'
import { ProgressBar } from './ProgressBar/ProgressBar'

export const MultiStageModal = ({ children, ...rest }: ModalBasicProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.MD)
  return (
    <styled.Modal isFullScreen={isMobile} {...rest}>
      {children}
    </styled.Modal>
  )
}

MultiStageModal.FooterButtons = FooterButtons
MultiStageModal.FormButtons = FormButtons
MultiStageModal.ProgressBar = ProgressBar
MultiStageModal.ProgressBarProvider = ProgressBarProvider
