import {
  DataMediumTypography,
  DataSmallTypography,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const MobilePhoneContainer = styled(Flex)`
  ${DataMediumTypography};
  white-space: nowrap;
  flex-wrap: nowrap;

  > .primary-phone {
    margin-left: 16px;
  }

  > .alternative-phone {
    margin-left: 0.25rem;
  }
`

export const MobileInfoContainer = styled.span`
  margin: 0 0 16px 32px;
  ${DataSmallTypography};
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const EmailInfoContainer = styled(Flex)`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.BLUE_1000};

  > .support-email {
    margin-left: 16px;
  }
`

export const SMSInfoContainer = styled(Flex)`
  ${DataMediumTypography};

  > .support-sms {
    margin-left: 16px;
  }
`
