import { BodyDefaultTypography, DataMedium } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'

export const StyledTicksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.GREY_150};
`
export const StyledAmountInput = styled.input`
  width: 70px;
  ${BodyDefaultTypography}
  outline: none;
  color: ${({ theme }) => theme.colors.BLACK_900};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.GREY_150};
  padding: 4px 8px;
  margin: 8px 0;
  transition: border-color 0.3s ease-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.GREY_400};
  }
`

export const StyledAmountInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCurrency = styled(DataMedium)`
  margin: 0;
`

export const StyledRangeItemsWrapper = styled(StyledItemsWrapper)`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    width: 100%;
    transform: translateY(-56px);
  }
`
