import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { useSendInviteEmail } from 'hooks/useSendEmailInvite'
import { LoaderSimple } from 'components/LoaderSimple'
import { page } from 'translations'

import * as styled from './UserInvitationPendingNotification.styled'

interface UserInvitationPendingNotificationProps {
  userId: string
}

export const UserInvitationPendingNotification = ({
  userId,
}: UserInvitationPendingNotificationProps) => {
  const { isSending, sendInviteEmail } = useSendInviteEmail({ userId })

  return (
    <styled.NotificationContainer>
      <Flex
        alignItems="start"
        data-testid="user-invitation-pending-notification"
      >
        <Box paddingTop="0.3rem">
          <SvgIcon width="16" height="16" color={COLOR.YELLOW_1000}>
            <InfoIcon />
          </SvgIcon>
        </Box>
        <Box paddingLeft="1rem">
          <styled.InvitationPendingText>
            {page.settings.users.onboardingStatus.pending.title}
          </styled.InvitationPendingText>
          <styled.SendInvitationLink
            $isSending={isSending}
            onClick={() => sendInviteEmail()}
          >
            {isSending && (
              <styled.LoaderContainer>
                <LoaderSimple />
              </styled.LoaderContainer>
            )}
            {page.settings.users.sendInvitation}
          </styled.SendInvitationLink>
        </Box>
      </Flex>
    </styled.NotificationContainer>
  )
}
