import { gql } from '@apollo/client'
import { Box, Flex, useIsMobileResolution } from '@npco/zeller-design-system'
import { useAvailableFundsCorporateCard } from 'features/Cards/CardSingle/hooks/useAvailableFundsCorporateCard/useAvailableFundsCorporateCard'

import {
  getIsCardClosed,
  getIsCardRestricted,
} from 'components/CardSummary/components/CardDisplay/CardDisplay.utils'
import { UserAndAccountDetails } from 'components/CardSummary/components/UserAndAccountDetails/UserAndAccountDetails'
import { AvailableFundsDisplay } from 'components/DebitCardSummary/ZellerCardDetails/AvailableFundsDisplay/AvailableFundsDisplay'
import { ShowMore } from 'components/ShowMore'

import { useSpentFundsCorporateCard } from '../../../../features/Cards/CardSingle/hooks/useSpentFundsCorporateCard/useSpentFundsCorporateCard'
import { Separator } from '../../ZellerCardSummary.styled'
import { ShowMoreButton } from '../ShowMoreButton'
import { CorporateCardDetailsDebitCardV2Fragment } from './CorporateCardDetails.generated'
import { CorporateCardProgressBar } from './CorporateCardProgressBar/CorporateCardProgressBar'
import { SpentFunds } from './SpentFunds/SpentFunds'
import { VelocityWindowSummary } from './VelocityWindowSummary/VelocityWindowSummary'

interface CorporateCardDetailsProps {
  card: CorporateCardDetailsDebitCardV2Fragment
  timezone?: string
}

export const CorporateCardDetails = ({
  card,
  timezone,
}: CorporateCardDetailsProps) => {
  const isCardRestricted = getIsCardRestricted(card.status)
  const isCardClosed = getIsCardClosed(card.status)

  const isMobile = useIsMobileResolution()

  const availableInCents = useAvailableFundsCorporateCard({ card, timezone })
  const availableInCentsWithClosedCard = isCardClosed ? 0 : availableInCents

  const amountLimitInCents = parseInt(
    card.velocityControl?.amountLimit?.value || '0',
    10
  )

  const availableFunds =
    isCardRestricted && !isCardClosed ? null : availableInCentsWithClosedCard
  const availableFundsForDisplay =
    availableFunds && availableFunds < 0 ? 0 : availableFunds

  const spentFunds = useSpentFundsCorporateCard({ card, timezone })

  return (
    <Box pl={{ _: '0', SM: '14px' }} maxWidth={{ _: '340px', SM: '100%' }}>
      <ShowMore shouldShowInitially={!isMobile}>
        <Flex
          height={{ _: 'auto' }}
          flexDirection={{ _: 'column', XS: 'row' }}
          justifyContent={{ _: 'start', XS: 'space-between' }}
          alignItems={{ _: 'center', XS: 'flex-start' }}
          pt={{ _: '24px', XS: '0' }}
        >
          <AvailableFundsDisplay
            availableFundsForDisplay={availableFundsForDisplay}
            isMobile={isMobile}
            isLoading={!!card.velocityControl?.isPendingSubscriptionUpdate}
          />
          {!isMobile && !isCardRestricted && (
            <Box mt="14px">
              <SpentFunds
                spentFundsInCents={spentFunds}
                align="end"
                isLoading={!!card.velocityControl?.isPendingSubscriptionUpdate}
              />
            </Box>
          )}
        </Flex>
        <CorporateCardProgressBar
          isCardClosed={isCardClosed}
          isMobile={isMobile}
          availableInCents={availableInCents}
          amountLimitInCents={amountLimitInCents}
          isLoading={!!card.velocityControl?.isPendingSubscriptionUpdate}
        />
        {!isCardClosed && (
          <Box
            textAlign={{ _: 'center', SM: 'end' }}
            minHeight="20px"
            mt="14px"
            mb="14px"
          >
            {card.velocityControl && (
              <VelocityWindowSummary
                velocityControl={card.velocityControl}
                timezone={timezone}
                isLoading={!!card.velocityControl?.isPendingSubscriptionUpdate}
              />
            )}
          </Box>
        )}
        {isMobile && <ShowMoreButton />}
        <ShowMore.TogglableContent>
          {isMobile && (
            <Flex flexDirection="column" alignItems="center" pb="24px">
              <Separator />
              <SpentFunds spentFundsInCents={spentFunds} align="center" />
            </Flex>
          )}
          <Box ml={{ _: '0', SM: '-14px' }} mt={{ _: '0', SM: '27px' }}>
            <UserAndAccountDetails
              user={card.customer}
              account={card.debitCardAccount}
            />
          </Box>
        </ShowMore.TogglableContent>
      </ShowMore>
    </Box>
  )
}

CorporateCardDetails.fragments = {
  DebitCardV2: gql`
    fragment CorporateCardDetailsDebitCardV2Fragment on DebitCardV2 {
      status
      ...UseAvailableFundsCorporateCardDebitCardV2Fragment
      ...UseSpentFundsCorporateCardDebitCardV2Fragment
      velocityControl {
        amountLimit {
          value
        }
        isPendingSubscriptionUpdate @client
        ...VelocityWindowSummaryVelocityControlTypeFragment
      }
      customer {
        id
        entityUuid
        ...UserAndAccountDetailsCustomerFragment
      }
      debitCardAccount {
        ...UserAndAccountDetailsDebitCardAccountV2Fragment
      }
    }

    ${VelocityWindowSummary.fragments.VelocityControlType}
    ${UserAndAccountDetails.fragments.Customer}
    ${UserAndAccountDetails.fragments.DebitCardAccountV2}
    ${useAvailableFundsCorporateCard.fragments.DebitCardV2}
    ${useSpentFundsCorporateCard.fragments.DebitCardV2}
  `,
}
