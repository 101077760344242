import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  headingAppearance: 'Appearance',
  screensaver: 'Terminal Screensaver',
  logo: 'Logo',
  colour: 'Background Colour',
  logoTooltip: 'Maximum of 8 logos allowed.',
  colourTooltip: 'Maximum of 16 colours allowed.',
  preview: 'Preview',
  model: 'Model',
  modelColour: 'Colour',
})
