import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  error: '{referenceNumber} could not be sent. Please try again.',
  errorInvalidEmail:
    '{referenceNumber} could not be sent as it is an invalid email. Please try again.',
  isNotNewEmailError: 'New email can’t match previously used email.',
  isNotNewMobileNumberError:
    'New mobile number can’t match previously used mobile number.',
  emailPlaceholder: 'Enter new email address',
  mobileNumberPlaceholder: 'Enter new mobile number',
  resendEmail: 'Resend Email',
  resendSMS: 'Resend SMS',
  resendInvoice: 'Resend Invoice',
  success: '{referenceNumber} successfully sent.',
  warningEmail:
    'There was an issue delivering your invoice to the email address listed below. Please review and enter a new email address.',
  warningSMS:
    'There was an issue delivering your invoice to the mobile number listed below. Please review and enter a new mobile number.',
  warningEmailAndSMS:
    'There was an issue delivering your invoice to the email address and mobile number listed below. Please review and provide new details to deliver the invoice.',
})
