import { Flex, H3, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledStatementsWrapper = styled(Flex)`
  flex-flow: column nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 1rem);
  margin: 0 -0.5rem;
  padding: 0 0.5rem 1rem;
  height: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding-bottom: 1rem;
    width: 100%;
    padding-right: calc(100% - 32rem);
  }
`

export const StyledTitle = styled(H3)`
  margin: 0;
`

export const StyledSitesHeader = styled(H5)`
  margin: 0.5rem 0 1rem;
`

export const StyledStatementsHeader = styled(H5)`
  margin: 0 0 1rem;
`
