import { gql } from '@apollo/client'
import { BoxProps, CellSize } from '@npco/zeller-design-system'

import { useCardStatus } from 'hooks/useCardStatus'

import { CardActivateButton } from '../CardActivateButton/CardActivateButton'
import { CardBalanceCellBase } from '../CardBalanceCellBase/CardBalanceCellBase'
import { CardStatus } from '../CardStatus/CardStatus'
import { CardStatusCellDebitCardV2Fragment } from './CardStatusCell.generated'

export interface CardStatusCellProps {
  card?: CardStatusCellDebitCardV2Fragment
  cellId: string
  isLoading?: boolean
  cellSize?: CellSize
  isActivateButtonEnabled?: boolean
  skeletonPropWidth: BoxProps['width']
}
export const CardStatusCell = ({
  card,
  isLoading,
  cellId,
  cellSize,
  isActivateButtonEnabled,
  skeletonPropWidth,
}: CardStatusCellProps) => {
  const { isClosed, isLocked, isSuspended, isInactive } = useCardStatus(card)
  const shouldShowCardStatus = isClosed || isLocked || isSuspended

  const cellProps = {
    cellId,
    skeletonPropWidth,
    isLoading,
    cellSize,
  }

  if (shouldShowCardStatus && card?.status) {
    return <CardStatus {...cellProps} status={card.status} />
  }

  return (
    <CardBalanceCellBase
      {...cellProps}
      content={isInactive && isActivateButtonEnabled && <CardActivateButton />}
    />
  )
}

CardStatusCell.fragments = {
  DebitCardV2: gql`
    fragment CardStatusCellDebitCardV2Fragment on DebitCardV2 {
      ...UseCardStatusDebitCardV2Fragment
      ...CardStatusDebitCardV2Fragment
    }
    ${useCardStatus.fragments.DebitCardV2}
    ${CardStatus.fragments.DebitCardV2}
  `,
}
