import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { getCents } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  COLOR,
  DATAPOINT_TEXT_SIZE,
  DatapointCurrency,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { sumBy } from 'lodash-es'

import { getDollarAmountForDatapoint } from '../IncomeAndExpenseDatapoints.utils'
import { IncomeDatapointCashFlowPeriodicTotalAmountFragment } from './IncomeDatapoint.generated'
import { incomeDatapointTranslations } from './IncomeDatapoint.i18n'

type IncomeDatapointProps = {
  incomeTotals: IncomeDatapointCashFlowPeriodicTotalAmountFragment[]
  isLoading: boolean
}

export const IncomeDatapoint = ({
  incomeTotals,
  isLoading,
}: IncomeDatapointProps) => {
  const t = useTranslations(incomeDatapointTranslations)
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)

  const totalIncomingTransactions = useMemo(
    () =>
      sumBy(
        incomeTotals,
        (total) => total.transactionDirectionTotalAmounts.noOfIncome
      ),
    [incomeTotals]
  )

  const incomeAmountInCents = useMemo(
    () =>
      sumBy(incomeTotals, (total) =>
        parseInt(total.transactionDirectionTotalAmounts.income.value, 10)
      ),
    [incomeTotals]
  )

  const dollarAmount = getDollarAmountForDatapoint(incomeAmountInCents)
  const centAmount = getCents(incomeAmountInCents)

  return (
    <DatapointCurrency
      integerText={dollarAmount}
      decimalText={centAmount}
      badgeText={isMobile ? undefined : totalIncomingTransactions.toString()}
      size={isMobile ? DATAPOINT_TEXT_SIZE.SMALL : DATAPOINT_TEXT_SIZE.MEDIUM}
      title={t('title')}
      isNegative={incomeAmountInCents < 0}
      titleColor={COLOR.BLUE_1000}
      badgeBackground={COLOR.GREY_30}
      isLoading={isLoading}
      shouldEllipsize
    />
  )
}

IncomeDatapoint.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment IncomeDatapointCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      transactionDirectionTotalAmounts {
        noOfIncome
        income {
          value
        }
      }
    }
  `,
}
