import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

import AnimatedWebP from 'assets/images/setup-flow/setup-flow-tap-to-pay.webp'

export const PhoneContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 167px;
  height: 363px;

  box-sizing: border-box;

  background: url(${AnimatedWebP});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;

  border-radius: 22px;
  outline: 6px solid black;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 234px;
    height: 508px;

    border-radius: 32px;
    outline: 8px solid black;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    width: 100%;
    height: 464px;
    margin-top: 0;

    border-radius: 28px;
    outline: 8px solid black;
  }
`

export const PhoneCase = styled.img`
  width: calc(100% + 29px);
  height: calc(100% + 29px);
  margin: 0 -20px;
  z-index: 0;
  transform: translate(1.8px, 3.6px);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    transform: translate(2px, 3px);
    height: calc(100% + 39px);
    width: calc(100% + 38px);
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    transform: translate(2px, 3px);
    height: calc(100% + 38px);
    width: calc(100% + 37px);
  }
`

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_60};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 0;
  box-sizing: border-box;
  overflow: hidden;

  > *:first-child {
    margin-top: 8px;
  }

  --modal-width-mobile: calc((100vw - 32px));
  --modal-width-tablet: calc((100vw - 48px));
  --modal-width-desktop-medium: 720px;

  --image-small-ratio: 1.56;
  --image-medium-ratio: 1.7866;

  height: calc(var(--modal-width-mobile) / var(--image-small-ratio));

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    > *:first-child {
      margin-top: 20px;
    }

    padding: 24px 98px;
    justify-content: flex-end;

    height: calc(var(--modal-width-tablet) / var(--image-medium-ratio));
  }

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    > *:first-child {
      margin-top: 20px;
    }

    padding: 24px 116px;
    justify-content: flex-end;

    height: calc(var(--modal-width-desktop-medium) / var(--image-medium-ratio));
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    > *:first-child {
      margin-top: 0;
    }

    padding: 24px 98px;
    justify-content: center;
    align-items: center;

    min-height: 636px;
    height: auto;
    width: 410px;
  }
`
