import {
  AnnouncementModalBodyContent,
  AnnouncementModalBodyDescription,
  Heading,
} from '@npco/zeller-design-system'

interface ModalContentProps {
  description: string
  heading: string
  Illustration: React.ReactNode
  children: React.ReactNode
}
export const ModalContent = ({
  description,
  heading,
  Illustration,
  children,
}: ModalContentProps) => (
  <>
    {Illustration}
    <AnnouncementModalBodyContent>
      <Heading.H2 dataTestId="announcement-modal-heading">{heading}</Heading.H2>
      <AnnouncementModalBodyDescription
        data-testid="announcement-modal-description"
        height={{ _: '100%', MD: '72px' }}
        width={{ _: '100%', MD: '448px' }}
      >
        {description}
      </AnnouncementModalBodyDescription>
      {children}
    </AnnouncementModalBodyContent>
  </>
)
