import {
  Box,
  COLOR,
  InfoFilledIcon,
  TOOLTIP_ZINDEX,
  TooltipButton,
} from '@npco/zeller-design-system'

interface HeaderTooltipButtonProps {
  content: string
  iconColour?: string
}

export const HeaderTooltipButton = ({
  content,
  iconColour = COLOR.GREY_550,
}: HeaderTooltipButtonProps) => {
  return (
    <TooltipButton
      tooltipIcon={<InfoFilledIcon color={iconColour} />}
      zIndex={parseInt(TOOLTIP_ZINDEX, 10)}
      placement="top"
      showArrow={false}
    >
      <Box maxWidth="206px" textAlign="center">
        {content}
      </Box>
    </TooltipButton>
  )
}
