import { DebitCardTransactionStatusV2 } from '@npco/mp-gql-types'

import { component } from 'translations'

interface StatusListItem {
  label: string
  value: DebitCardTransactionStatusV2
}

export const statusItems: StatusListItem[] = [
  {
    label: component.transaction.statusBadge.approved,
    value: DebitCardTransactionStatusV2.APPROVED,
  },
  {
    label: component.transaction.statusBadge.pending,
    value: DebitCardTransactionStatusV2.PROCESSING,
  },
  {
    label: component.transaction.statusBadge.canceled,
    value: DebitCardTransactionStatusV2.CANCELLED,
  },
  {
    label: component.transaction.statusBadge.declined,
    value: DebitCardTransactionStatusV2.DECLINED,
  },
]
