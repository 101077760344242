import { Location } from './StaticMap.types'

interface StylesOptions {
  shouldHideIcons: boolean
  isDisabled: boolean
}

export const buildStyles = ({ shouldHideIcons, isDisabled }: StylesOptions) => {
  const styles = []

  if (shouldHideIcons) {
    styles.push(
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },

      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      }
    )
  }

  if (isDisabled) {
    styles.push({
      featureType: 'all',
      stylers: [{ saturation: -100 }],
    })
  }

  return styles
}

export const navigateToGoogleMap = (location: Location) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`,
    '_blank',
    'noopener,noreferrer'
  )
}
