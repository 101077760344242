import {
  DebitCardTransactionFilterInputV2,
  DebitCardTransactionsExportFormat,
} from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { StatementsModal } from 'components/StatementsModal'

import { exportDebitCardTransactionsModalTranslations } from './ExportDebitCardTransactionsModal.i18n'
import { ExportDebitCardTransactionsModalContent } from './ExportDebitCardTransactionsModalContent'

interface ExportDebitCardTransactionsModalProps {
  isModalOpen: boolean
  closeModal: () => void
  filters: DebitCardTransactionFilterInputV2
}

export const ExportDebitCardTransactionsModal = ({
  isModalOpen,
  closeModal,
  filters,
}: ExportDebitCardTransactionsModalProps) => {
  const t = useTranslations(exportDebitCardTransactionsModalTranslations)

  if (!isModalOpen) {
    return null
  }

  return (
    <StatementsModal
      closeModal={closeModal}
      title={t('title')}
      type={DebitCardTransactionsExportFormat}
      modalContent={(fileFormat: DebitCardTransactionsExportFormat) => (
        <ExportDebitCardTransactionsModalContent
          closeModal={closeModal}
          filename={t('exportTransactionsFilename')}
          format={fileFormat}
          filters={filters}
        />
      )}
      info={t('exportInfo')}
      defaultFormat={DebitCardTransactionsExportFormat.CSV}
    />
  )
}
