import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 2px 4px;
  cursor: pointer;
`

export const AvatarContainer = styled(Flex)`
  height: 44px;
  align-items: center;
  svg {
    background-color: transparent;
  }
`
