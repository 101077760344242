import { useCallback, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { DateUtils } from 'react-day-picker'
import { useReactiveVar } from '@apollo/client'
import { showErrorToast, usePeriods } from '@npco/zeller-design-system'
import {
  rvIsDateRangeAppropriate,
  rvSelectedDates,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { datePickerInitialState } from 'const/common'
import dayjs from 'utils/dayjs'
import { RangeModifierExt, TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'
import { component } from 'translations'

import { useTimePickerState } from './useTimePickerState'

interface Arguments {
  initialState?: RangeModifierExt
  defaultState?: RangeModifierExt
  isFutureDateDisabled?: boolean
  widgetKey?: TIME_FILTER_ENABLED_COMPONENTS
}

export const useDatePickerState = ({
  initialState = datePickerInitialState,
  defaultState = datePickerInitialState,
  isFutureDateDisabled = true,
  widgetKey,
}: Arguments = {}) => {
  const data = useReactiveVar(rvSelectedDates)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [enteredTo, setEnteredTo] = useState<Date>()
  const [selectedRange, setSelectedRange] =
    useState<RangeModifierExt>(initialState)

  useEffect(() => {
    setSelectedRange(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetKey])

  const {
    fromTime,
    toTime,
    setDefaultTime,
    setTimes,
    onFromTimeChange,
    onToTimeChange,
    onFromInputBlur,
    onToInputBlur,
  } = useTimePickerState({ setSelectedRange })

  const { from, to } = selectedRange

  const handleInterruptedSelection = useCallback(() => {
    const isSelectingInterrupted = selectedRange.from && !selectedRange.to

    if (isSelectingInterrupted) {
      setEnteredTo(undefined)
      setSelectedRange(data)
      showErrorToast(component.datePicker.lackOfEndDate)
    }
  }, [data, selectedRange])

  const outsideClickWrapperRef = useOnclickOutside(handleInterruptedSelection)

  const handleResetClick = useCallback(() => {
    rvSelectedDates(defaultState)
    setSelectedRange(defaultState)
    setEnteredTo(defaultState.enteredTo)
    setSelectedPeriod('')
    onFromTimeChange('')
    onToTimeChange('')
  }, [onFromTimeChange, onToTimeChange, defaultState])

  const checkDateRange = useCallback(() => {
    const fromDay = dayjs(from)
    const toDay = dayjs(to)
    const range = dayjs(toDay).diff(dayjs(fromDay), 'month', true)

    if (range > 12 || !from || !to) {
      rvIsDateRangeAppropriate(false)
    } else {
      rvSelectedDates(selectedRange)
      setTimes(fromDay.toDate(), toDay.toDate())
      rvIsDateRangeAppropriate(true)
    }
  }, [from, to, selectedRange, setTimes])

  const { periods } = usePeriods()

  const checkPeriod = useCallback(() => {
    const fromDay = dayjs(from).startOf('day')
    const toDay = dayjs(to).startOf('day')

    const foundPeriod = Object.entries(periods).find(
      ([, range]) =>
        fromDay.isSame(dayjs(range.from).startOf('day')) &&
        toDay.isSame(dayjs(range.to).startOf('day'))
    )

    const newSelectedPeriodName = foundPeriod?.[0] ?? ''

    setSelectedPeriod(newSelectedPeriodName)
  }, [from, to, periods])

  useEffect(() => {
    checkDateRange()
  }, [checkDateRange])

  useEffect(() => {
    checkPeriod()
  }, [checkPeriod])

  useEffect(() => {
    setEnteredTo(to)
  }, [to])

  const isSelectingFirstDay = (
    fromDate: Date | undefined,
    toDate: Date | undefined,
    selectedDay: Date
  ) => {
    const isBeforeFirstDay =
      fromDate && DateUtils.isDayBefore(selectedDay, fromDate)
    const isRangeSelected = fromDate && toDate

    return !fromDate || isBeforeFirstDay || isRangeSelected
  }

  const handleDayClick = (day: Date) => {
    const today = new Date()
    today.setHours(12, 0, 0, 0)

    if (day > today && isFutureDateDisabled) {
      return
    }

    if (isSelectingFirstDay(from, to, day)) {
      setSelectedRange({
        from: dayjs(day).startOf('date').toDate(),
        to: undefined,
      })
      setEnteredTo(undefined)
      setDefaultTime()
    } else {
      setSelectedRange((prev) => ({
        ...prev,
        to: dayjs(day).endOf('date').toDate(),
      }))
      setEnteredTo(day)
    }
  }

  const handleDayMouseEnter = (day: Date) => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day)
    }
  }

  const onPeriodChange = (periodData: RangeModifierExt, periodName: string) => {
    rvSelectedDates({ from: periodData.from, to: periodData.to })
    setSelectedRange(periodData)
    setEnteredTo(periodData.enteredTo)
    setSelectedPeriod(periodName)
    setTimes(periodData.from as Date, periodData.to as Date)
  }

  const hasCustomDateApplied =
    !dayjs(selectedRange.from).isSame(defaultState?.from) ||
    !dayjs(selectedRange.to).isSame(defaultState?.to)

  return {
    data: selectedRange,
    fromTime,
    toTime,
    onFromTimeChange,
    onToTimeChange,
    onFromInputBlur,
    onToInputBlur,
    enteredTo,
    selectedPeriod,
    handleResetClick,
    handleDayClick,
    handleDayMouseEnter,
    onPeriodChange,
    outsideClickWrapperRef,
    handleInterruptedSelection,
    hasCustomDateApplied,
  }
}
