import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  XeroBankfeed as XeroBankfeedResponse,
  XeroBankfeedVariables,
} from 'types/gql-types/XeroBankfeed'

import { XeroBankfeed } from './XeroBankfeed.graphql'

export const useXeroBankfeed = () => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data, error } = useQuery<
    XeroBankfeedResponse,
    XeroBankfeedVariables
  >(XeroBankfeed, { variables: { entityUuid } })

  return {
    isLoading: loading,
    xeroBankFeed: data?.xeroBankFeed,
    error,
  }
}
