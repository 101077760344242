import { useMemo } from 'react'

import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'

import { useTransferQuery } from '../useTransferQuery/useTransferQuery'

export const useIsSingleAccountCase = () => {
  const { allAccounts } = useTransferQuery()
  const {
    transferState: [state],
  } = useTransferState()

  return useMemo(
    () =>
      allAccounts.length === 1 &&
      state.matches(TransferGeneralState.ZellerTransfer),
    [state, allAccounts.length]
  )
}
