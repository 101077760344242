import { useMemo } from 'react'
import { DepositStatus } from '@npco/mp-gql-types'
import {
  Amount,
  AvatarBasic,
  BREAKPOINT,
  Title,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { currencyFormatter } from 'utils/common'
import { formatDayMonth, formatWeekday, formatWeekdayShort } from 'utils/date'
import { AVATAR_BADGE_SIZE } from 'types/common'
import { Deposit } from 'types/deposits'
import DepositStatusBadge from 'components/StatusBadge/DepositStatusBadge/DepositStatusBadge'

import {
  DepositRow,
  HideableItem,
  HighlightContentWrapper,
  MainItem,
  MainItemTextWrapper,
  MobileTitle,
  SiteAvatar,
  StandardItem,
  StatusBadgeMobile,
  StatusBadgeWrapper,
} from './ListGroupDepositItem.styled'

interface Props {
  data: Deposit
  onRecordSelect: (deposit: Deposit) => void
}

export const ListGroupDepositItem = ({ data, onRecordSelect }: Props) => {
  const { timestamp, amountSettled, status } = data

  const isUpdated = useMemo(() => 'subscriptionIsUpdated' in data, [data])
  const siteName = data.siteName ?? ''
  const shouldShortenDay = useIsMobileResolution(BREAKPOINT.LG)
  const isCompletedSettlement = status === DepositStatus.COMPLETED
  const depositDate = isCompletedSettlement ? formatDayMonth(timestamp) : '-'
  const depositDay = shouldShortenDay
    ? formatWeekdayShort(timestamp)
    : formatWeekday(timestamp)

  return (
    <DepositRow
      data-testid="deposit-row"
      onClick={() => onRecordSelect(data)}
      isUpdated={isUpdated}
    >
      <HighlightContentWrapper isUpdated={isUpdated}>
        <MainItem>
          <SiteAvatar>
            <AvatarBasic badgeSize={AVATAR_BADGE_SIZE.SMALL} text={siteName} />
          </SiteAvatar>
          <MainItemTextWrapper>
            <Title data-testid="deposit-site-name">{siteName}</Title>
            <MobileTitle>{depositDate}</MobileTitle>
          </MainItemTextWrapper>
        </MainItem>
        <HideableItem data-testid="deposit-day">
          <Title>{isCompletedSettlement ? depositDay : '-'}</Title>
        </HideableItem>
        <HideableItem data-testid="deposit-date">
          <Title>{depositDate}</Title>
        </HideableItem>
        <HideableItem data-testid="deposit-status">
          <StatusBadgeWrapper>
            <DepositStatusBadge status={status} />
          </StatusBadgeWrapper>
        </HideableItem>
        <StandardItem>
          <Amount data-testid="deposit-amount">
            {currencyFormatter(amountSettled)}
          </Amount>
          <StatusBadgeMobile>
            <DepositStatusBadge status={status} />
          </StatusBadgeMobile>
        </StandardItem>
      </HighlightContentWrapper>
    </DepositRow>
  )
}
