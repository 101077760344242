import { AnnouncementModal, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CardOnDeliveryIllustration } from 'assets/svg/card-on-delivery.svg'
import {
  CompleteCardDeliveryState,
  CompleteCardReplacementDeliveryState,
  CompleteCardStage,
} from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { useModalHasOpenTransition } from 'pages/GlobalModals/hooks/useModalHasOpenTransition'

import { useCompleteCardModal } from '../hooks/useCompleteCardModal'
import { useCompleteCardState } from '../hooks/useCompleteCardState'
import {
  getCompleteCardDeliveryModalButtons,
  getCompleteCardDeliveryModalDescription,
  getCompleteCardDeliveryModalHeading,
} from './CompleteCardDeliveryModal.utils'

export const CompleteCardDeliveryModal = () => {
  const isMobile = useIsMobileResolution()
  const { state } = useCompleteCardState<
    CompleteCardDeliveryState | CompleteCardReplacementDeliveryState
  >()

  const { hasOpenTransition } = useModalHasOpenTransition()
  const { openAddCardModal, closeCompleteCardModal } = useCompleteCardModal()

  const buttonActions = getCompleteCardDeliveryModalButtons({
    state,
    closeAction: closeCompleteCardModal,
    addCardAction: openAddCardModal,
  })

  if (state === null) {
    return null
  }

  return (
    <AnnouncementModal
      isOpen={
        state.stage === CompleteCardStage.CompleteCardDeliveryStage ||
        state.stage === CompleteCardStage.CompleteCardReplacementDeliveryStage
      }
      shouldContentFadeIn={hasOpenTransition}
      heading={getCompleteCardDeliveryModalHeading(state)}
      description={getCompleteCardDeliveryModalDescription(state)}
      Illustration={
        <SvgIcon
          width={isMobile ? '140' : '180'}
          height={isMobile ? '140' : '180'}
        >
          <CardOnDeliveryIllustration />
        </SvgIcon>
      }
      {...buttonActions}
      onCancel={closeCompleteCardModal}
    />
  )
}
