import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetSimQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  simId: Types.Scalars['ID']['input']
}>

export type GetSimQueryResponse = {
  __typename?: 'Query'
  getSim: {
    __typename: 'Sim'
    id: string
    billingAmount: number | null
    billingStatus: Types.SimBillingStatus
    deviceName: string | null
    siteName: string | null
    nextBillingDate: any | null
  }
}

export const GetSim = gql`
  query GetSim($entityUuid: ID!, $simId: ID!) {
    getSim(entityUuid: $entityUuid, simId: $simId) {
      __typename
      id
      billingAmount
      billingStatus
      deviceName
      siteName
      nextBillingDate
    }
  }
` as unknown as TypedDocumentNode<GetSimQueryResponse, GetSimQueryVariables>

/**
 * __useGetSimQuery__
 *
 * To run a query within a React component, call `useGetSimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      simId: // value for 'simId'
 *   },
 * });
 */
export function useGetSimQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimQueryResponse,
    GetSimQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSimQueryResponse, GetSimQueryVariables>(
    GetSim,
    options
  )
}
export function useGetSimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimQueryResponse,
    GetSimQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSimQueryResponse, GetSimQueryVariables>(
    GetSim,
    options
  )
}
export type GetSimQueryHookResult = ReturnType<typeof useGetSimQuery>
export type GetSimLazyQueryHookResult = ReturnType<typeof useGetSimLazyQuery>
export type GetSimQueryResult = Apollo.QueryResult<
  GetSimQueryResponse,
  GetSimQueryVariables
>
