export enum KEY_CODE {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ENTER = 'Enter',
  SPACE = ' ',
  TAB = 'Tab',
  ESCAPE = 'Escape',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
}

export const ALL_KEYBOARD_NUMBERS = '0123456789'.split('')
export const ALL_KEYBOARD_LETTERS_AND_NUMBERS = [
  ...ALL_KEYBOARD_NUMBERS,
  ...'qwertyuiopasdfghjklzxcvbnm'.split(''),
]
