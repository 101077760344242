import { useMemo, useState } from 'react'

import { TRANSACTION_SOURCES } from 'const/common'
import { PickerItemProps } from 'types/picker'

import { SourcePickerContext } from './SourcePickerContext'

interface SourcePickerProviderProps {
  children: React.ReactNode
}

export const SourcePickerProvider = ({
  children,
}: SourcePickerProviderProps) => {
  const transactionSources = TRANSACTION_SOURCES

  const [selectedSources, setSelectedSources] =
    useState<PickerItemProps[]>(transactionSources)

  const providerValue = useMemo(
    () => ({
      selectedSources,
      setSelectedSources,
    }),
    [selectedSources]
  )

  return (
    <SourcePickerContext.Provider value={providerValue}>
      {children}
    </SourcePickerContext.Provider>
  )
}
