import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { MiniTabItemProps } from '@npco/zeller-design-system'

import { accountStatementsTranslations } from '../AccountDetailsStatements.i18n'
import { AccountStatementType } from '../AccountDetailsStatements.types'

export const useAccountStatementTabs = () => {
  const location = useLocation()
  const t = useTranslations(accountStatementsTranslations)

  const tabs: MiniTabItemProps<typeof Link>[] = useMemo(
    () => [
      {
        value: AccountStatementType.MONTHLY,
        children: t('tabMonthly'),
        to: `?statementType=${AccountStatementType.MONTHLY}`,
        component: Link,
      },
      {
        value: AccountStatementType.QUARTERLY,
        children: t('tabQuarterly'),
        to: `?statementType=${AccountStatementType.QUARTERLY}`,
        component: Link,
      },
    ],
    [t]
  )

  const currentTab = useMemo(
    () =>
      new URLSearchParams(location.search).get('statementType') ||
      tabs[0].value,
    [location.search, tabs]
  )

  return {
    tabs,
    currentTab,
  }
}
