import { translate } from 'utils/translations'

import { CategoryItem } from './CategoryItem'
import { CategoryItemError } from './CategoryItemError'
import { CategoryItemSkeleton } from './CategoryItemSkeleton'

interface SubcategoryDisplayProps {
  isLoadingSubcategory: boolean
  hasError: boolean
  subcategoryName: string | undefined
  onEdit?: () => void
}

export const CategoryDisplay = ({
  isLoadingSubcategory,
  hasError,
  subcategoryName,
  onEdit,
}: SubcategoryDisplayProps) => {
  if (isLoadingSubcategory) {
    return <CategoryItemSkeleton paddingTop="0.375rem" />
  }

  if (hasError) {
    return (
      <CategoryItemError
        label={translate('component.categoryDisplay.subcategoryLabel')}
      />
    )
  }

  return (
    <CategoryItem
      label={translate('component.categoryDisplay.subcategoryLabel')}
      text={
        subcategoryName || translate('component.categoryDisplay.uncategorised')
      }
      onEdit={onEdit}
      dataTestId="subcategory-pill"
    />
  )
}
