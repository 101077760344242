import { useTranslations } from '@npco/utils-translations'
import { DecisionModal } from '@npco/zeller-design-system'

import { translations } from './LinkedInvoicesWarningModal.i18n'

interface LinkedInvoicesWarningModalProps {
  closeModal: () => void
  isOpen: boolean
  onConfirm: () => void
  linkedInvoicesLength: number
}

export const LinkedInvoicesWarningModal = ({
  closeModal,
  isOpen,
  onConfirm,
  linkedInvoicesLength,
}: LinkedInvoicesWarningModalProps) => {
  const t = useTranslations(translations)

  const draftInvoiceText = t('draftInvoice')
  return (
    <DecisionModal
      isOpen={isOpen}
      onCancel={closeModal}
      onClickPrimary={onConfirm}
      onClickSecondary={closeModal}
      primaryButtonLabel={t('confirm')}
      secondaryButtonLabel={t('cancel')}
      title={t('updateTitle')}
    >
      {t('updateBody', {
        draftInvoiceCountText: (
          <b>
            {linkedInvoicesLength} {draftInvoiceText}
            {linkedInvoicesLength > 1 && 's'}
          </b>
        ),
      })}
    </DecisionModal>
  )
}
