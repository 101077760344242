import { useTranslations } from '@npco/utils-translations'
import { ComingSoonBadge } from '@npco/zeller-design-system'

import { CardFormatOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'
import { StyledRadioCard } from 'pages/GlobalModals/GlobalModals.styled'
import { translationsShared } from 'translations'

import { PhysicalCardIcon } from './PhysicalCardIcon'

interface PhysicalCardRadioOptionProps {
  onChange?: () => void
  isChecked: boolean
  name: string
  isDisabled?: boolean
}

export const PhysicalCardRadioOption = ({
  onChange,
  isChecked,
  name,
  isDisabled,
}: PhysicalCardRadioOptionProps) => {
  const tShared = useTranslations(translationsShared)

  return (
    <StyledRadioCard
      id={CardFormatOption.Physical}
      value={CardFormatOption.Physical}
      title={translate(
        'page.addCardModal.selectCardFormat.physicalCardOptionTitle'
      )}
      desc={translate(
        'page.addCardModal.selectCardFormat.physicalCardOptionDesc'
      )}
      icon={PhysicalCardIcon}
      onChange={onChange}
      checked={isChecked}
      name={name}
      disabled={isDisabled}
      contextContent={
        isDisabled && <ComingSoonBadge text={tShared('comingSoon')} />
      }
      isRadioIconVisible={!isDisabled}
    />
  )
}
