import {
  BodyDefaultTypography,
  DataMediumTypography,
  Flex,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledWrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 8px;
`

export const StyledDetailsLabel = styled.span`
  color: ${({ theme }) => theme.colors.BLACK_900};
  ${InterZellerMediumFont};
  ${BodyDefaultTypography}
`

export const StyledDetailsValue = styled.div`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledEmailValue = styled.div`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
  overflow-wrap: break-word;
  max-width: calc(100vw - 156px);
`
