import { DateRange } from '../../hooks/useSelectedDateRange/SelectedDateRange.type'
import { dayjs } from '../dayjs/dayjs'

const monthNameToNumber = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
}

export const convertToAWSDate = (
  dateRange: DateRange,
  entityTimezone: string | undefined
) => {
  if (dateRange === 'Last 12 Months') {
    return dayjs().tz(entityTimezone).format('YYYY-MM-DD')
  }

  const month = monthNameToNumber[dateRange.month]

  return dayjs()
    .month(month)
    .year(dateRange.year)
    .endOf('month')
    .format('YYYY-MM-DD')
}
