import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoutIcon } from 'assets/svg/logout.svg'
import { useLogout } from 'hooks/useLogout'
import { component } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const LogoutItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const { logout } = useLogout()

  return (
    <SidebarItem
      icon={
        <SvgIcon>
          <LogoutIcon />
        </SvgIcon>
      }
      isFolded={isFolded}
      onClick={() => logout()}
      $marginBottomOverride="0px"
    >
      {component.sidebar.logOut}
    </SidebarItem>
  )
}
