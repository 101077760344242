import {
  ACCORDION_FORM_STATE,
  Box,
  TooltipBasic,
} from '@npco/zeller-design-system'
import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js'
import { FormikErrors, FormikTouched } from 'formik'

import { InvoiceFormFields } from '../../../../Invoice.types'
import { useEmailMergeTags } from './hooks/useEmailMergeTags'
import {
  availableEntityTagsMap,
  MergeTag,
} from './InvoiceDeliveryAccordion.constants'
import { MergeTagPill } from './InvoiceDeliveryAccordion.styled'

const MAX_TOOLTIP_WIDTH = 216

const mergeTagKeyMap = Object.entries(availableEntityTagsMap).reduce<
  Record<string, MergeTag>
>((acc, [key, { key: entityKey }]) => {
  acc[entityKey] = key as MergeTag
  return acc
}, {})

const EditorPill = ({
  children,
  contentState,
  entityKey,
  offsetKey,
}: {
  children: string
  contentState: ContentState
  entityKey: string
  offsetKey: string
}) => {
  const { mergeTagsMap } = useEmailMergeTags()
  const { hasError, isFocused } = contentState.getEntity(entityKey).getData()
  const entityType = contentState.getEntity(entityKey).getType()

  const tooltip = mergeTagsMap[mergeTagKeyMap[entityType]]?.tooltip

  if (tooltip) {
    return (
      <TooltipBasic
        placement="top"
        renderTrigger={({ ref, handlers }) => {
          return (
            <MergeTagPill
              {...handlers}
              $isFocused={isFocused}
              $hasError={hasError}
              data-offset-key={offsetKey}
              data-testid="invoice-email-pill"
              ref={ref}
            >
              {children}
            </MergeTagPill>
          )
        }}
        showArrow={false}
      >
        <Box maxWidth={MAX_TOOLTIP_WIDTH} textAlign="center">
          {tooltip}
        </Box>
      </TooltipBasic>
    )
  }

  return (
    <MergeTagPill
      data-offset-key={offsetKey}
      data-testid="invoice-email-pill"
      $isFocused={isFocused}
      $hasError={hasError}
    >
      {children}
    </MergeTagPill>
  )
}

const getEntityStrategy = (key: string) => {
  return (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
          return false
        }
        const entity = contentState.getEntity(entityKey)
        return entity.getType() === key
      },
      // cursor positioning when adding an entity is a bit tricky
      // found this https://github.com/facebook/draft-js/issues/627#issuecomment-576649059
      // which makes the cursor to move in front of the added merge tag.
      (start, end) => callback(start + 1, end - 1)
    )
  }
}

export const getEmailCompositeDecorator = () =>
  new CompositeDecorator(
    Object.values(availableEntityTagsMap).map((entityTagMap) => ({
      strategy: getEntityStrategy(entityTagMap.key),
      component: EditorPill,
    }))
  )

type GetAccordionFormStateProps = {
  errors?: FormikErrors<InvoiceFormFields['delivery']>
  touched?: FormikTouched<InvoiceFormFields['delivery']>
  values: InvoiceFormFields['delivery']
}

type GetEmailFormStateProps = {
  errors?: FormikErrors<InvoiceFormFields['delivery']['email']>
  touched?: FormikTouched<InvoiceFormFields['delivery']['email']>
  values: InvoiceFormFields['delivery']['email']
}

const requiredEmailFields = ['recipient', 'message', 'subject'] as const
const optionalEmailFields = ['bcc', 'cc'] as const

type EmailFields = keyof InvoiceFormFields['delivery']['email']

const getEmailFormState = ({
  errors,
  touched,
  values,
}: GetEmailFormStateProps) => {
  if (!values.isEnabled) {
    return null
  }

  const hasValidEmail = Boolean(values.recipient && !errors?.recipient)

  const isFieldTouched = (fieldName: EmailFields) =>
    Boolean(touched?.[fieldName])

  const hasValue = (fieldName: EmailFields) => Boolean(values?.[fieldName])
  const isFieldTouchedWithError = (fieldName: EmailFields) =>
    isFieldTouched(fieldName) && Boolean(errors?.[fieldName])

  const isAllRequiredFieldsTouched = requiredEmailFields.every(isFieldTouched)
  const isRequiredFieldsValid = requiredEmailFields.every(hasValue)

  if (
    hasValidEmail &&
    isAllRequiredFieldsTouched &&
    isRequiredFieldsValid &&
    !errors
  ) {
    return ACCORDION_FORM_STATE.COMPLETE
  }

  const isAnyFieldsWithError = [
    ...requiredEmailFields,
    ...optionalEmailFields,
  ].some(isFieldTouchedWithError)

  if (isAnyFieldsWithError) {
    return ACCORDION_FORM_STATE.ATTENTION
  }

  const isAnyRequiredFieldsTouched = requiredEmailFields.some(isFieldTouched)
  const isAnyRequiredFieldsValid = requiredEmailFields.some(hasValue)

  if (isAnyRequiredFieldsTouched && isAnyRequiredFieldsValid) {
    return ACCORDION_FORM_STATE.PARTIAL_COMPLETE
  }

  return ACCORDION_FORM_STATE.EMPTY
}

type GetSmsFormStateProps = {
  errors?: FormikErrors<InvoiceFormFields['delivery']['sms']>
  touched?: FormikTouched<InvoiceFormFields['delivery']['sms']>
  values: InvoiceFormFields['delivery']['sms']
}

const getSmsFormState = ({ errors, touched, values }: GetSmsFormStateProps) => {
  if (!values.isEnabled) {
    return null
  }

  const isRecipientTouched = touched?.recipient

  const hasError = Boolean(errors)
  const isAllRequiredFieldsTouched = isRecipientTouched
  const isRequiredFieldsValid = values.recipient

  if (isAllRequiredFieldsTouched && isRequiredFieldsValid && !hasError) {
    return ACCORDION_FORM_STATE.COMPLETE
  }

  const isPhoneWithError = isRecipientTouched && Boolean(errors?.recipient)

  if (isPhoneWithError) {
    return ACCORDION_FORM_STATE.ATTENTION
  }

  return ACCORDION_FORM_STATE.EMPTY
}

export const getAccordionFormState = ({
  errors,
  touched,
  values,
}: GetAccordionFormStateProps) => {
  if (typeof errors === 'string') {
    return ACCORDION_FORM_STATE.ATTENTION
  }
  const emailFormState = getEmailFormState({
    errors: errors?.email,
    touched: touched?.email,
    values: values.email,
  })

  const smsFormState = getSmsFormState({
    errors: errors?.sms,
    touched: touched?.sms,
    values: values?.sms,
  })

  const isAllEmpty =
    (!emailFormState && !smsFormState) ||
    (emailFormState === ACCORDION_FORM_STATE.EMPTY &&
      smsFormState === ACCORDION_FORM_STATE.EMPTY)

  if (isAllEmpty) {
    return ACCORDION_FORM_STATE.EMPTY
  }

  if (!emailFormState && smsFormState) {
    return smsFormState
  }

  if (emailFormState && !smsFormState) {
    return emailFormState
  }

  const isAllComplete =
    emailFormState === ACCORDION_FORM_STATE.COMPLETE &&
    smsFormState === ACCORDION_FORM_STATE.COMPLETE

  if (isAllComplete) {
    return ACCORDION_FORM_STATE.COMPLETE
  }

  const isAnyError =
    emailFormState === ACCORDION_FORM_STATE.ATTENTION ||
    smsFormState === ACCORDION_FORM_STATE.ATTENTION

  if (isAnyError) {
    return ACCORDION_FORM_STATE.ATTENTION
  }

  return ACCORDION_FORM_STATE.PARTIAL_COMPLETE
}

type GetAccordionDetailsProps = {
  errors?: FormikErrors<InvoiceFormFields['delivery']>
  values: {
    email: Pick<
      InvoiceFormFields['delivery']['email'],
      'isEnabled' | 'recipient'
    >
    sms: InvoiceFormFields['delivery']['sms']
  }
  fallbackText: string
}

export const getAccordionDetails = ({
  values,
  errors,
  fallbackText,
}: GetAccordionDetailsProps) => {
  const emailValues = { ...values.email, isValid: !errors?.email?.recipient }
  const smsValues = { ...values.sms, isValid: !errors?.sms?.recipient }

  const details = [emailValues, smsValues]
    .filter(({ isEnabled, isValid, recipient }) =>
      Boolean(isEnabled && recipient && isValid)
    )
    .map((delivery) => delivery.recipient)
    .join(', ')

  return details.length ? details : fallbackText
}
