import { gql } from '@apollo/client'

import { SearchableCardholderList } from '../SearchableCardholderList/SearchableCardholderList'

export const GetCardholderList = gql`
  query GetCardholderList($entityUuid: ID!) {
    getCustomers(entityUuid: $entityUuid) {
      id
      entityUuid
      ...SearchableCardholderListCustomerFragment
    }
  }
  ${SearchableCardholderList.fragments.Customer}
`
