import { gql } from '@apollo/client'

import { AccountStatementsView } from '../../../AccountStatementsView'

export const AccountStatementsQuery = gql`
  query AccountStatementsQuery(
    $input: GetBankingAccountStatementsInput!
    $entityUuid: ID!
  ) {
    getBankingAccountStatements(input: $input, entityUuid: $entityUuid) {
      ...AccountStatementsViewBankingAccountStatementFragment
    }
  }

  ${AccountStatementsView.fragments.BankingAccountStatement}
`
