import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import dayjs from 'utils/dayjs'

import { DateRangeValue } from '../../DateRange.types'
import {
  formatDateForInput,
  formatTimeForInput,
  getDateFromInputValue,
  NON_BREAKING_SPACE,
  replaceDateTimeWithTime,
  setSeconds,
} from './DateRangeInputs.utils'

export const useDateRangeInputs = ({
  range,
  hoveredDay,
  format,
  onRangeChange,
}: {
  range: DateRangeValue | undefined
  hoveredDay?: Date
  format: 'date' | 'datetime'
  onRangeChange: (range: DateRangeValue | undefined) => void
}) => {
  const isDateTime = format === 'datetime'

  const formatValueForInput = useMemo(
    () => (isDateTime ? formatTimeForInput : formatDateForInput),
    [isDateTime]
  )
  const formattedData = useMemo(
    () => ({
      start: formatValueForInput(range?.start),
      end: formatValueForInput(range?.end),
    }),
    [range?.start, range?.end, formatValueForInput]
  )

  const [startInputValue, setStartInputValue] = useState(formattedData.start)
  const [endInputValue, setEndInputValue] = useState(formattedData.end)

  useEffect(() => {
    setStartInputValue(formattedData.start)
    setEndInputValue(formattedData.end)
  }, [formattedData.end, formattedData.start])

  const setInputValue = useCallback((value: string, key: 'start' | 'end') => {
    if (key === 'start') {
      setStartInputValue(value)
    } else {
      setEndInputValue(value)
    }
  }, [])

  const handleBlur = useCallback(
    (dateOrTimeValue: string, key: 'start' | 'end') => {
      if (!dateOrTimeValue.length) {
        setInputValue(NON_BREAKING_SPACE, key)
        return
      }

      const dateValue =
        range && isDateTime
          ? replaceDateTimeWithTime(range[key], dateOrTimeValue)
          : getDateFromInputValue(dateOrTimeValue)

      const isDateValid = !!dateValue && dayjs(dateValue).isValid()

      if (!isDateValid) {
        setInputValue(formattedData[key], key)
        return
      }

      setInputValue(formatValueForInput(dateValue), key)

      const shouldSwapKeys =
        !isDateTime &&
        !!(
          (range?.end && key === 'start' && dateValue > range.end) ||
          (range?.start && key === 'end' && dateValue < range.start)
        )

      if (shouldSwapKeys) {
        onRangeChange({
          start: setSeconds({
            date: key === 'start' ? range.end : dateValue,
            key: 'start',
          }),
          end: setSeconds({
            date: key === 'end' ? range.start : dateValue,
            key: 'end',
          }),
        })
        return
      }

      const otherKey = key === 'start' ? 'end' : 'start'
      onRangeChange({
        [otherKey]: setSeconds({ date: range?.[otherKey], key: otherKey }),
        [key]: setSeconds({ date: dateValue, key }),
      })
    },
    [
      formatValueForInput,
      formattedData,
      isDateTime,
      onRangeChange,
      range,
      setInputValue,
    ]
  )

  const handleStartInputBlur = useCallback(() => {
    handleBlur(startInputValue, 'start')
  }, [startInputValue, handleBlur])

  const handleEndInputBlur = useCallback(() => {
    handleBlur(endInputValue, 'end')
  }, [handleBlur, endInputValue])

  const handleStartInputChange = useCallback(
    ({ target: { value } }) => {
      setStartInputValue(value)
    },
    [setStartInputValue]
  )

  const handleEndInputChange = useCallback(
    ({ target: { value } }) => {
      setEndInputValue(value)
    },
    [setEndInputValue]
  )

  const handleEndInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.currentTarget.blur()
      }
    },
    []
  )

  const endInputRef = useRef<HTMLInputElement>(null)
  const handleStartInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        endInputRef.current?.focus()
      }
    },
    []
  )

  useEffect(() => {
    if (isDateTime) return

    if (!hoveredDay || !range?.start) return

    if (range.start && range.end) return

    if (hoveredDay < range.start) {
      setEndInputValue(formatValueForInput(range.start))
      setStartInputValue(NON_BREAKING_SPACE)
    } else {
      setEndInputValue(NON_BREAKING_SPACE)
      setStartInputValue(formatValueForInput(range.start))
    }
  }, [isDateTime, formatValueForInput, hoveredDay, range?.end, range?.start])

  return {
    formattedData,
    startInputValue,
    handleStartInputBlur,
    handleStartInputChange,
    handleStartInputKeyDown,
    handleEndInputBlur,
    handleEndInputChange,
    handleEndInputKeyDown,
    endInputRef,
    endInputValue,
  }
}
