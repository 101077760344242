import { useCallback } from 'react'
import { FieldConfig, useField } from 'formik'

import { SelectItemBasic } from '../../Select.types'

interface Args<ItemType> {
  name: string
  validate?: FieldConfig['validate']
  items: ItemType[]
}

export function useSelectField<ItemType extends SelectItemBasic>({
  name,
  validate,
  items,
}: Args<ItemType>) {
  const [fieldBase, fieldMeta, fieldHandlers] = useField({ name, validate })

  const onChange = useCallback(
    (selectedItem?: ItemType | null) => {
      fieldHandlers.setValue(selectedItem?.value)
    },
    [fieldHandlers]
  )

  const hasError = Boolean(fieldMeta.error && fieldMeta.touched)

  return {
    onChange,
    hasError,
    errorMessage: fieldMeta.error,
    fieldValue: fieldBase.value,
    fieldHandlers,
    setTouched: () => {
      if (!fieldMeta.touched) {
        fieldHandlers.setTouched(true, true)
      }
    },
    selectedItem: items.find((item) => item.value === fieldBase.value),
  }
}
