import { sort, uniqBy } from 'ramda'

import { customDateParse } from 'hooks/useTransactionsList/useTransactionsList.utils'
import { Deposit } from 'types/deposits'

export const filterNonNullDeposits = (items: (Deposit | null)[]): Deposit[] =>
  items.filter(Boolean) as Deposit[]

export const filterUniqueDeposits = (input: Deposit[]) =>
  uniqBy((item) => item.id, input)

export const sortDescendingDepositsTimestamp = (input: Deposit[]) => {
  const diff = (previous: Deposit, next: Deposit) =>
    customDateParse(next.timestamp) - customDateParse(previous.timestamp)
  return sort(diff, input)
}
