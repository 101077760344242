import { Box, formatWeekdayDateTime } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'
import { getNonNullString } from 'utils/string'
import { translate } from 'utils/translations'
import { GetTransaction_getTransaction_refundTransactions as RefundTransaction } from 'types/gql-types/GetTransaction'
import { Transaction } from 'types/transactions'
import { DetailsField } from 'components/DetailsField/DetailsField'
import { getTransactionBadgeVariant } from 'components/StatusBadge/TransactionStatusBadge/TransactionStatusBadge.utils'
import { component } from 'translations'

import * as styled from '../../TransactionDetails.styled'
import { DetailsFieldWithButtonLink } from '../DetailsField/DetailsField'

interface TransactionRefundProps {
  balanceAfterRefund: number
  refundTransaction: RefundTransaction
  onClickReference: (id: string) => void
}

export const TransactionRefund = ({
  balanceAfterRefund,
  refundTransaction,
  onClickReference,
}: TransactionRefundProps) => {
  const transactionStatus = getTransactionBadgeVariant(
    refundTransaction as unknown as Transaction
  )
  return (
    <Box mb="24px" key={refundTransaction.id}>
      <DetailsFieldWithButtonLink
        label={component.transaction.refunded}
        value={`#${refundTransaction.reference}`}
        onClick={() => onClickReference(refundTransaction.id)}
      />
      <DetailsField
        label={component.transaction.date}
        value={formatWeekdayDateTime(refundTransaction.timestamp)}
      />
      <DetailsField
        label={component.transaction.status}
        value={
          <styled.StatusValue color={transactionStatus.color}>
            {transactionStatus.text}
          </styled.StatusValue>
        }
      />
      <DetailsField
        label={translate('component.transaction.processedBy')}
        value={getNonNullString(refundTransaction.customerName, '-')}
      />
      <DetailsField
        label={component.transaction.amount}
        value={`-${currencyFormatter(refundTransaction.amount)}`}
      />
      <DetailsField
        label={component.transaction.refundedBalance}
        value={currencyFormatter(balanceAfterRefund)}
      />
    </Box>
  )
}
