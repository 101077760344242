import {
  Box,
  Flex,
  SkeletonAvatar,
  SkeletonBasic,
  Spinner,
} from '@npco/zeller-design-system'

const DetailsLoader = () => (
  <Flex justifyContent="space-between" padding="6px 0">
    <SkeletonBasic width={118} height={8} isRounded />
    <SkeletonBasic width={118} height={8} isRounded />
  </Flex>
)

export const ContactDetailsLoader = () => {
  return (
    <Flex
      data-testid="contact-details-loader"
      flexDirection="column"
      gridGap="24px"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <SkeletonAvatar />
          <Box ml="16px">
            <SkeletonBasic width={200} height={20} isRounded />
          </Box>
        </Flex>
        <SkeletonBasic width={63} height={12} isRounded />
      </Flex>
      <Flex alignItems="center" height={180} justifyContent="center">
        <Spinner />
      </Flex>
      <Flex flexDirection="column" gridGap="8px">
        <DetailsLoader />
        <DetailsLoader />
        <DetailsLoader />
        <DetailsLoader />
        <DetailsLoader />
        <DetailsLoader />
        <DetailsLoader />
      </Flex>
    </Flex>
  )
}
