import { Box, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const FlipCardContainer = styled(Flex)<{
  $isFlipped: boolean
}>`
  height: 352px;
  width: 100%;
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform: ${({ $isFlipped }) => ($isFlipped ? 'rotateY(180deg)' : 'unset')};
`

export const CardFace = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  top: 0;
  left: 0;
`

export const CardFront = styled(CardFace)`
  z-index: 2;
  transform: rotateY(0deg);
`

export const CardBack = styled(CardFace)<{
  $isVisible: boolean
}>`
  transform: rotateY(180deg);
  z-index: ${({ $isVisible }) => ($isVisible ? 1 : -1)};
`
