import { ReactNode } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Flex } from '@npco/zeller-design-system'
import { rvIsUserSigningUp } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import signupImagePath from 'assets/images/zeller-signup-image.jpg'
import { LogoCorner } from 'components/LogoCorner'
import { SignInNavbar } from 'components/Navbar'

import { SpinnerWrapped } from '../../components/Spinner'
import {
  StyledAsideBox,
  StyledAuthLayoutSignUpMarketingCampaignContent,
  StyledContent,
  StyledContentWrapper,
  StyledNavbar,
  StyledWrapper,
} from './AuthLayout.styled'
import { SignUpSidebar } from './SignUp/SignUpSidebar/SignUpSidebar'

interface AuthProps {
  children?: ReactNode
  isLoading?: boolean
  hasNavBar?: boolean
  logo?: React.ReactNode
  isLogInPage?: boolean
  shouldShowMarketingBanner?: boolean
}

export const AuthLayout = ({
  children,
  isLoading,
  hasNavBar = true,
  logo = <LogoCorner />,
  isLogInPage,
  shouldShowMarketingBanner = true,
}: AuthProps) => {
  const isUserSigningUp = useReactiveVar(rvIsUserSigningUp)

  const isSignUp = !isLogInPage || isUserSigningUp
  // Feature flag will be implemented for marketing banner
  // shouldShowMarketingBanner will be removed and replaced with feature flag
  // https://npco-dev.atlassian.net/browse/BANK-354
  return (
    <StyledWrapper>
      {hasNavBar && (
        <StyledNavbar data-testid="auth-navbar">
          <SignInNavbar isSignUp={Boolean(isSignUp)} />
        </StyledNavbar>
      )}
      {logo}
      {isSignUp && shouldShowMarketingBanner ? (
        <Flex>
          <StyledContentWrapper>
            <Flex
              height="100%"
              overflowY="auto"
              flexDirection={{ _: 'column', MD: 'row' }}
            >
              {isLoading ? (
                <SpinnerWrapped variant="centre" flex={1} />
              ) : (
                <StyledAuthLayoutSignUpMarketingCampaignContent data-testid="auth-content">
                  {children}
                </StyledAuthLayoutSignUpMarketingCampaignContent>
              )}
              <SignUpSidebar />
            </Flex>
          </StyledContentWrapper>
        </Flex>
      ) : (
        <Flex
          height={{
            XS: '100%',
          }}
        >
          <StyledContentWrapper>
            {isLoading ? (
              <SpinnerWrapped variant="centre" flex={1} />
            ) : (
              <StyledContent data-testid="auth-content">
                {children}
              </StyledContent>
            )}
          </StyledContentWrapper>
          {/* 
          * To be updated with feature flag
          * https://npco-dev.atlassian.net/browse/BANK-354
        {isSignUp ? (
          <SignUpSidebar /> <-- restore the old component
        ) : (
          <StyledAsideBox
            data-testid="sign-in-sidebar"
            as="aside"
            backgroundUrl={signupImagePath}
          />
        )} */}

          <StyledAsideBox
            data-testid="sign-in-sidebar"
            as="aside"
            backgroundUrl={signupImagePath}
          />
        </Flex>
      )}
    </StyledWrapper>
  )
}
