import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  refunds: 'Refunds',
  refundsDescription:
    'You will be asked to enter a PIN before refunding a transaction.',
  requirePin: 'Require PIN',
  refundPinLabel: 'PIN',
  sitePinOption: 'Use Site PIN',
  refundPinOption: 'Use Refund PIN',
  setRefundPin: 'Set Refund PIN',
})
