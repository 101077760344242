import { useContext } from 'react'

import { OutstandingFilterContext } from './OutstandingFilterContext'

export const useOutstandingFilterContext = () => {
  const context = useContext(OutstandingFilterContext)

  if (context === null) {
    throw new Error(
      'useOutstandingFilterContext must be used within OutstandingFilterContext'
    )
  }

  return context
}
