import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Assigned Cards',
  adminDescription:
    'Admin role has access to all cards created for the organisation.',
  managerDescription: 'Managers must be assigned to cards to have access.',
  unverifiedInfo:
    '{firstName} needs to log in and complete ID Verification before they can be assigned to a Zeller Debit or Corporate Card. ',
  idvInfoPrefix: 'This can be found in ',
  idvLocation: 'Profile Settings > Security.',
})
