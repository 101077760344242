import { useCallback } from 'react'
import { useDeleteTransactionReceipt } from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-modal/EditDebitCardTransactionImagesModal/UploadReceipts/UploadReceiptsModal/hooks/useDeleteTransactionReceipt'

import { FileWithId } from 'components/File'

interface UseDeleteTransactionAttachmentProps {
  selectedImage: FileWithId | undefined
  transactionUuid: string

  deleteImage: (image: FileWithId) => void
  onSuccessfulDelete: () => void
}

export const useDeleteTransactionAttachment = ({
  selectedImage,
  transactionUuid,

  deleteImage,
  onSuccessfulDelete,
}: UseDeleteTransactionAttachmentProps) => {
  const { deleteTransactionReceipt } = useDeleteTransactionReceipt()
  const handleDeleteImage = useCallback(async () => {
    if (!selectedImage?.id) return

    await deleteTransactionReceipt(selectedImage.id, transactionUuid)

    deleteImage(selectedImage)
    onSuccessfulDelete()
  }, [
    selectedImage,
    deleteTransactionReceipt,
    transactionUuid,

    deleteImage,
    onSuccessfulDelete,
  ])

  return {
    handleDeleteImage,
  }
}
