import {
  InputSelectComboboxItemRenderProps,
  InputSelectComboboxStandardItemAddNew,
} from '@npco/zeller-design-system'

import { LabelSelectComboboxItem } from '../../LabelSelectCombobox.types'

interface LabelSelectComboboxNewItemProps<ItemType> {
  addNewItemContent?: string
  inputValue?: string
  onAddItem: (value: string, onComplete: () => void) => void
  setIsSelectingItem: (bool: boolean) => void
  renderProps: InputSelectComboboxItemRenderProps<ItemType>
}

export const LabelSelectComboboxNewItem = <
  ItemType extends LabelSelectComboboxItem
>({
  addNewItemContent,
  inputValue,
  onAddItem,
  setIsSelectingItem,
  renderProps,
}: LabelSelectComboboxNewItemProps<ItemType>) => {
  if (!addNewItemContent) {
    return null
  }

  return (
    <InputSelectComboboxStandardItemAddNew
      aria-selected={renderProps['aria-selected']}
      data-testid="add-new-item"
      onClick={() => {
        onAddItem(String(inputValue).trim(), () => setIsSelectingItem(false))
      }}
      role={renderProps.role}
      style={renderProps.style}
    >
      {addNewItemContent}
    </InputSelectComboboxStandardItemAddNew>
  )
}
