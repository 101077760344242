import { BodyDefaultTypography, BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const DetailsLabel = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};

  @media screen and (max-width: ${BREAKPOINT.SM - 1}px) {
    margin: 2px;
  }

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    ${BodyDefaultTypography};
  }
`

export const Separator = styled.div`
  height: 1px;
  width: 295px;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.colors.GREY_90};
`
