import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface Props {
  abn: string | null | undefined
}

export const AbnItem = ({ abn }: Props) => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.abn')}
      value={abn || '-'}
    />
  )
}
