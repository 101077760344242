import { useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { AVATAR_BADGE_SIZE, SkeletonAvatar } from '@npco/zeller-design-system'

import { merchantAvatarTranslations } from './MerchantAvatar.i18n'

export interface MerchantAvatarProps {
  merchantAvatarUrl: string | undefined
  onError?: () => void
}

export const MerchantAvatar = ({
  merchantAvatarUrl,
  onError,
}: MerchantAvatarProps) => {
  const t = useTranslations(merchantAvatarTranslations)

  const [isMerchantAvatarImgLoaded, setIsMerchantAvatarImgLoaded] =
    useState(false)

  return (
    <>
      {!isMerchantAvatarImgLoaded && (
        <SkeletonAvatar size={AVATAR_BADGE_SIZE.DEFAULT} />
      )}
      <img
        data-testid="merchant-avatar"
        src={merchantAvatarUrl}
        style={{
          visibility: isMerchantAvatarImgLoaded ? 'visible' : 'hidden',
        }}
        onLoad={() => setIsMerchantAvatarImgLoaded(true)}
        alt={t('avatar')}
        onError={onError}
      />
    </>
  )
}
