import { useTranslations } from '@npco/utils-translations'
import {
  ButtonGhost,
  COLOR,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { OutstandingExpensesButtons } from '../OutstandingExpensesButtons'
import { outstandingExpensesErrorTranslations } from './OutstandingExpensesError.i18n'

interface OutstandingExpensesErrorProps {
  retry: () => void
}
export const OutstandingExpensesError = ({
  retry,
}: OutstandingExpensesErrorProps) => {
  const t = useTranslations(outstandingExpensesErrorTranslations)
  const tShared = useTranslations(translationsShared)

  return (
    <>
      <Flex
        maxWidth="504px"
        height={{ _: '232px', XS: '154px' }}
        backgroundColor={COLOR.GREY_20}
      >
        <Flex flexDirection="column" maxWidth="208px" margin="auto">
          <InfoBox
            variant={INFOBOX_VARIANT.NEGATIVE}
            priority={INFOBOX_PRIORITY.DEFAULT}
          >
            <InfoBox.Message>{t('errorMessage')}</InfoBox.Message>
          </InfoBox>
          <ButtonGhost fullWidth={false} onClick={retry}>
            {tShared('tryAgain')}
          </ButtonGhost>
        </Flex>
      </Flex>
      <OutstandingExpensesButtons isSubmitDisabled />
    </>
  )
}
