import { Box, Flex, InfoBox, INFOBOX_VARIANT } from '@npco/zeller-design-system'

interface ExpenseCardBudgetMessageProps {
  isAdmin: boolean
  variant: INFOBOX_VARIANT
  messageAdmin: string
  messageNonAdmin: string
  buttonMessage: string
  onClick: () => void
  dataTestId?: string
}

export const ExpenseCardBudgetMessage = ({
  isAdmin,
  variant,
  messageAdmin,
  messageNonAdmin,
  buttonMessage,
  onClick,
  dataTestId,
}: ExpenseCardBudgetMessageProps) => {
  return (
    <Box mt={{ _: '24px', XS: '40px' }} data-testid={dataTestId}>
      <InfoBox variant={variant}>
        {isAdmin ? (
          <Flex
            flexDirection={{ _: 'column', MD: 'row' }}
            gap="16px"
            justifyContent="space-between"
            width="100%"
          >
            <InfoBox.Message>{messageAdmin}</InfoBox.Message>
            <InfoBox.ButtonGroup>
              <InfoBox.CallToAction onClick={onClick}>
                {buttonMessage}
              </InfoBox.CallToAction>
            </InfoBox.ButtonGroup>
          </Flex>
        ) : (
          <InfoBox.Message>{messageNonAdmin}</InfoBox.Message>
        )}
      </InfoBox>
    </Box>
  )
}
