import { useCallback, useState } from 'react'

interface ChartLoadingAnimationTrackingProps {
  initialShouldFillChange: boolean
  fillChangeDelay: number
}
/**
 * listen to chart animation start and end, allow a delay to change fill.
 * eg. if animation duration is 1000ms, from 0ms - 1000ms, and with fillChangeDelay = 500,
 * shouldFillChange will change from false to true at 500ms
 */
export const useChartLoadingAnimationTracking = ({
  initialShouldFillChange,
  fillChangeDelay,
}: ChartLoadingAnimationTrackingProps) => {
  const [shouldFillChange, setShouldFillChange] = useState(
    initialShouldFillChange
  )
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const onAnimationStart = useCallback(() => {
    if (shouldFillChange) {
      return
    }

    setTimer(
      setTimeout(() => {
        setShouldFillChange(true)
      }, fillChangeDelay)
    )
  }, [fillChangeDelay, shouldFillChange])

  const onAnimationEnd = useCallback(() => {
    if (timer) {
      setTimer(null)
    }
    setShouldFillChange(true)
  }, [timer])

  return {
    shouldFillChange,
    onAnimationStart,
    onAnimationEnd,
  }
}
