import { useCallback, useMemo, useState } from 'react'
import { debounce } from 'debounce'

interface UseDebounceValueProps<V> {
  initialValue: V
  delay: number
}

export const useDebounceValue = <V,>({
  initialValue,
  delay,
}: UseDebounceValueProps<V>) => {
  const [debouncedValue, setDebouncedValue] = useState(initialValue)

  const updateDebouncedValue = useCallback(
    (value: V) => setDebouncedValue(value),
    [setDebouncedValue]
  )

  const debouncedUpdateValue = useMemo(
    () => debounce(updateDebouncedValue, delay),
    [updateDebouncedValue, delay]
  )

  return { debouncedUpdateValue, debouncedValue }
}
