import { useTranslations } from '@npco/utils-translations'

import { PROMOTION_TERMS_CONDITIONS_URL } from 'const/externalLinks'

import { introDescriptionTranslations } from './IntroDescription.i18n'
import * as styled from './IntroDescription.styled'

export const TermsApply = () => {
  const t = useTranslations(introDescriptionTranslations)

  return (
    <styled.Label>
      {t('termsText', {
        termsLink: (
          <styled.Anchor href={PROMOTION_TERMS_CONDITIONS_URL}>
            {t('termsLink')}
          </styled.Anchor>
        ),
      })}
    </styled.Label>
  )
}
