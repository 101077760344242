import { gql, type TypedDocumentNode } from '@apollo/client'

export type AccountBalanceAreaChartDebitCardAccountBalanceFragment = {
  __typename: 'DebitCardAccountBalance'
  date: any
  balance: { __typename?: 'Money'; value: string }
}

export const AccountBalanceAreaChartDebitCardAccountBalanceFragmentDoc = gql`
  fragment AccountBalanceAreaChartDebitCardAccountBalanceFragment on DebitCardAccountBalance {
    __typename
    date
    balance {
      value
    }
  }
` as unknown as TypedDocumentNode<
  AccountBalanceAreaChartDebitCardAccountBalanceFragment,
  undefined
>
