import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { AccountAvatarField } from './AccountAvatarField/AccountAvatarField'
import { AccountNameField } from './AccountNameField/AccountNameField'
import { PersonaliseAccountValues } from './PersonaliseAccountFields.types'

type PersonaliseAccountFieldsProps = {
  hideAvatar?: boolean
}

export const PersonaliseAccountFields = ({
  hideAvatar = false,
}: PersonaliseAccountFieldsProps) => {
  const { values } = useFormikContext<PersonaliseAccountValues>()

  return (
    <>
      <Box mt="4px">
        <AccountNameField />
      </Box>
      {!hideAvatar && (
        <Box mt="24px">
          <AccountAvatarField accountName={values.name} />
        </Box>
      )}
    </>
  )
}
