import { rgba } from 'polished'
import styled, { css } from 'styled-components'

export const StyledRadioBox = styled.input.attrs({
  type: 'radio',
})<{ withoutMargin: boolean }>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  min-width: 20px;
  width: 20px;
  height: 20px;
  padding: 3px;
  margin: ${(props) => (props.withoutMargin ? 0 : '0 12px 0 0')};
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  border-radius: 50%;
  background-clip: content-box;
  cursor: pointer;

  &&:checked {
    background-color: ${({ theme }) => theme.colors.BLUE_1000};
    border-color: ${({ theme }) => rgba(theme.colors.BLUE_1000, 0.75)};

    &&:hover {
      border-color: ${({ theme }) => theme.colors.BLUE_700};
    }
  }

  &&:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.GREY_400};
  }

  &&:hover {
    border-color: ${({ theme }) => theme.colors.GREY_400};
  }

  &&:disabled {
    border-color: ${({ theme }) => rgba(theme.colors.GREY_250, 0.75)};
    cursor: default;
  }

  &&:disabled&&:checked {
    background-color: ${({ theme }) => rgba(theme.colors.GREY_250, 0.75)};

    &&:hover {
      border-color: ${({ theme }) => rgba(theme.colors.GREY_250, 0.75)};
    }
  }
`

export const StyledRadiobuttonLabel = styled.label<{ isChecked?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isChecked, theme }) =>
    isChecked ? `${theme.colors.BLUE_80}` : 'transparent'};
  cursor: pointer;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease-out;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
  }
`

const checkedStyles = css`
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  border-color: ${({ theme }) => theme.colors.BLUE_1000};
`

export const StyledRadiobuttonCardLabel = styled.label<{ isChecked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.colors.GREY_250};
  border-radius: 7px;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  cursor: pointer;
  ${(props) => props.isChecked && checkedStyles};

  &:hover,
  &:focus {
    ${checkedStyles};
  }
`
