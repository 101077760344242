import {
  BodyDefault,
  BodyLarge,
  InterZellerMediumFont,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledLinkedCardsTitle = styled(BodyLarge)`
  ${InterZellerMediumFont};
  margin: 0 0 8px;
`

export const StyledLinkedCardsDescription = styled(BodyDefault)`
  margin: 0 0 8px;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledLinkedCardsWrapper = styled.ul`
  margin: 0;
  padding: 0;

  li:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.GREY_150};
  }
`
