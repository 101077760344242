import { DebitCardColour } from '@npco/mp-gql-types'

import { ReactComponent as DarkBlueCorporateCardMasked } from 'assets/svg/cards/corporate-card-dark-blue-no-chip.svg'
import { ReactComponent as GreenCorporateCardMasked } from 'assets/svg/cards/corporate-card-green-no-chip.svg'
import { ReactComponent as LightBlueCorporateCardMasked } from 'assets/svg/cards/corporate-card-light-blue-no-chip.svg'
import { ReactComponent as YellowCorporateCardMasked } from 'assets/svg/cards/corporate-card-yellow-no-chip.svg'
import { ReactComponent as BlackDebitCardMasked } from 'assets/svg/cards/debit-card-black-no-chip.svg'
import { ReactComponent as WhiteDebitCardMasked } from 'assets/svg/cards/debit-card-white-no-chip.svg'

interface MaskedZellerCardProps {
  colour: DebitCardColour
}

export const MaskedZellerCard = ({ colour }: MaskedZellerCardProps) => {
  switch (colour) {
    case DebitCardColour.WHITE:
      return <WhiteDebitCardMasked data-testid="white-debit-card-masked" />
    case DebitCardColour.DARKBLUE:
      return (
        <DarkBlueCorporateCardMasked data-testid="dark-blue-corporate-card-masked" />
      )
    case DebitCardColour.LIGHTBLUE:
      return (
        <LightBlueCorporateCardMasked data-testid="light-blue-corporate-card-masked" />
      )
    case DebitCardColour.GREEN:
      return (
        <GreenCorporateCardMasked data-testid="green-corporate-card-masked" />
      )
    case DebitCardColour.YELLOW:
      return (
        <YellowCorporateCardMasked data-testid="yellow-corporate-card-masked" />
      )
    case DebitCardColour.BLACK:
    default:
      return <BlackDebitCardMasked data-testid="black-debit-card-masked" />
  }
}
