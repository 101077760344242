import { createTranslations } from '@npco/utils-translations'

export const unableToRemoveBusinessModalTranslations = createTranslations({
  title: 'Unable to Remove Business',
  primaryButtonLabel: 'Close',
  warningMessage:
    'The above email is the Primary Registered Account Holder of the nominated Business and therefore cannot be removed, unless the Business is Deleted.',
  contactSupportMessage:
    'Please contact {supportLink} if you require assistance.',
  business: 'Business',
  user: 'User',
  zellerSupport: 'Zeller Support',
})
