import { Box } from '@npco/zeller-design-system'
import { AnimatePresence } from 'framer-motion'

import { SidebarSpotlightModal } from '../../common/components/SidebarSpotlightModal/SidebarSpotlightModal'
import { useSetupFlowModal } from './hooks/useSetupFlowModal'
import { useSetupFlowModalState } from './hooks/useSetupFlowModalState'
import { MotionStage } from './SetupFlowModal.styled'

export const SetupFlowModal = () => {
  const { Component, isSpotlightVisible, stage, visibleSpotlightSelector } =
    useSetupFlowModalState()
  const { goToStage } = useSetupFlowModal()

  if (!Component) {
    return null
  }

  const element = document.getElementById('setup-flow-modal-root')

  return (
    <SidebarSpotlightModal
      isOpen
      $isSpotlightVisible={isSpotlightVisible}
      key={`modal-spotlight-on:${isSpotlightVisible}`}
      onCancel={() => goToStage(undefined)}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      spotlightSelector={visibleSpotlightSelector}
      {...(element && { parentSelector: () => element })}
    >
      <Box
        data-testid="setup-flow-modal"
        height="100%"
        overflowY="auto"
        overflowX="hidden"
      >
        <AnimatePresence exitBeforeEnter presenceAffectsLayout>
          <MotionStage key={stage}>
            <Component />
          </MotionStage>
        </AnimatePresence>
      </Box>
    </SidebarSpotlightModal>
  )
}
