import { useCallback } from 'react'
import { useTranslations, UseTranslationsFn } from '@npco/utils-translations'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { InvoiceDetailsFragment } from 'types/gql-types/InvoiceDetailsFragment'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { translationsShared } from 'translations'

import { useInvoiceAnalytics } from '../../../../../hooks/useInvoiceAnalytics'
import { useSendInvoice } from '../../../../../hooks/useSendInvoice'
import { useInvoiceDecisionModal } from '../../hooks/useInvoiceDecisionModal'
import {
  getInvoiceActionOnPartFailedSend,
  InvoiceDecisionModalTypes,
} from '../../InvoiceDrawerDetails.utils'
import { ResendFailedSendInvoice } from './ResendFailedSendInvoice/ResendFailedSendInvoice'
import { translations } from './ResendInvoiceModal.i18n'

const getConfirmationText = (
  details: InvoiceDetailsFragment,
  t: UseTranslationsFn<typeof translations>
) => {
  const isEmailEnabled = Boolean(details.email?.enabled)
  const isSmsEnabled = Boolean(details.sms?.enabled)

  const isSmsDeliveryOnly = !isEmailEnabled && isSmsEnabled
  const isEmailDeliveryOnly = isEmailEnabled && !isSmsEnabled

  const { referenceNumber } = details

  if (isSmsDeliveryOnly) {
    return t('smsOnlyConfirmation', { referenceNumber })
  }

  if (isEmailDeliveryOnly) {
    return t('emailOnlyConfirmation', { referenceNumber })
  }

  return t('smsAndEmailConfirmation', { referenceNumber })
}

export const ResendInvoiceModal = () => {
  const { activeModal, closeModal, details } = useInvoiceDecisionModal()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const {
    customer,
    referenceNumber,
    id,
    requiredEmailUpdateBeforeSend,
    requiredPhoneUpdateBeforeSend,
    sms,
    status,
  } = details

  const { sendInvoice, isSendingInvoice } = useSendInvoice()

  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleSendInvoice = useCallback(async () => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_SEND_INVOICE)
    try {
      const response = await sendInvoice(referenceNumber)

      if (!response.data?.sendInvoice) {
        showErrorToast(t('error', { referenceNumber }))
        return
      }

      showSuccessToast(t('success', { referenceNumber }))
    } catch {
      showErrorToast(t('error', { referenceNumber }))
    } finally {
      closeModal()
    }
  }, [closeModal, referenceNumber, sendInvoice, t, trackInvoiceAction])

  if (activeModal !== InvoiceDecisionModalTypes.ResendInvoice) {
    return null
  }

  const isFailedDelivery = getInvoiceActionOnPartFailedSend(
    status,
    details.activities
  )

  const payerEmailToUpdate = requiredEmailUpdateBeforeSend?.find(
    (email) => email === customer?.payerEmail
  )
  const payerMobileToUpdate = requiredPhoneUpdateBeforeSend?.find(
    (mobile) => mobile === sms?.payerContactPhoneNumber
  )

  if (isFailedDelivery && (payerEmailToUpdate || payerMobileToUpdate)) {
    // NOTE: when a user attempts to resend an invoice which has failed to send we
    // prompt then to enter a new email address as technically the same email is
    // likely to bounce where requiredEmailUpdateBeforeSend field provides the email
    // that hard bounced. However, in the event of a soft bounced email delivery,
    // we need to show the existing resend modal without the email input.
    return (
      <ResendFailedSendInvoice
        payerEmailToUpdate={payerEmailToUpdate}
        payerMobileToUpdate={payerMobileToUpdate}
      />
    )
  }

  const confirmationText = getConfirmationText(details, t)

  return (
    <div data-testid="resend-invoice-modal">
      <DecisionModal
        isLoadingPrimary={isSendingInvoice}
        isOpen={activeModal === InvoiceDecisionModalTypes.ResendInvoice}
        onCancel={closeModal}
        onClickPrimary={handleSendInvoice}
        onClickSecondary={closeModal}
        primaryButtonLabel={tShared('confirm')}
        secondaryButtonLabel={tShared('cancel')}
        title={t('resendInvoice')}
      >
        {confirmationText}
      </DecisionModal>
    </div>
  )
}
