import { FocusEventHandler } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import * as styled from '../field.styled'
import { crnFieldTranslations } from './CrnField.i18n'
import { useCrnValidate } from './useCrnValidate'

interface CrnFieldProp {
  fieldName?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const CrnField = ({ fieldName = 'crn', onBlur }: CrnFieldProp) => {
  const [, { error, touched }] = useField({
    name: fieldName,
    validate: useCrnValidate(),
  })
  const t = useTranslations(crnFieldTranslations)

  return (
    <styled.FieldWrapper>
      <InputAdaptiveFieldWrapper
        hasError={touched && !!error}
        name={fieldName}
        label={t('label')}
        size={TEXT_INPUT_SIZE.LARGE}
        onBlur={onBlur}
      />
    </styled.FieldWrapper>
  )
}
