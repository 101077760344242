import { translate } from 'utils/translations'

import {
  StyledCardPaymentsDescription,
  StyledCardPaymentsTitle,
} from './CardPayments.styled'

export const CardPayments = () => {
  return (
    <>
      <StyledCardPaymentsTitle>
        {translate('page.settings.feesAndPricing.cardPayments.title')}
      </StyledCardPaymentsTitle>
      <StyledCardPaymentsDescription>
        {translate('page.settings.feesAndPricing.cardPayments.description')}
      </StyledCardPaymentsDescription>
    </>
  )
}
