import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { AnalyticsEventNames } from 'services/Analytics/events'
import { translationsShared } from 'translations/index'

import { useInvoiceDecisionModal } from '../hooks/useInvoiceDecisionModal'
import { useSendReminder } from '../hooks/useSendReminder'
import { InvoiceDecisionModalTypes } from '../InvoiceDrawerDetails.utils'
import { translations } from './SendReminderModal.i18n'

export const SendReminderModal = () => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { activeModal, closeModal, details } = useInvoiceDecisionModal()
  const { sendReminder, isSendingReminder } = useSendReminder()

  const { id, referenceNumber, email } = details
  const isEmail = email?.enabled

  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleSendReminder = useCallback(async () => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_SEND_REMINDER)
    try {
      const response = await sendReminder(referenceNumber)

      if (!response.data?.sendReminder) {
        showErrorToast(t('error', { referenceNumber }))
        return
      }

      showSuccessToast(t('success', { referenceNumber }))
    } catch {
      showErrorToast(t('error', { referenceNumber }))
    } finally {
      closeModal()
    }
  }, [closeModal, referenceNumber, sendReminder, t, trackInvoiceAction])

  return (
    <DecisionModal
      isOpen={activeModal === InvoiceDecisionModalTypes.SendReminder}
      isLoadingPrimary={isSendingReminder}
      onCancel={closeModal}
      onClickPrimary={handleSendReminder}
      onClickSecondary={closeModal}
      primaryButtonLabel={tShared('confirm')}
      secondaryButtonLabel={tShared('cancel')}
      title={t('title')}
    >
      {t('body', { channel: isEmail ? t('email') : t('sms') })}
    </DecisionModal>
  )
}
