import { HeaderContext } from '@tanstack/react-table'

import { translate } from 'utils/translations'
import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { InvoiceHeaderCell } from '../InvoiceHeaderCell/InvoiceHeaderCell'
import { AllInvoiceTableColumn } from '../InvoiceTable.types'
import { TableColumnPopperWidth } from '../InvoiceTable.utils'
import { StatusFilters } from '../InvoiceTableFilters/StatusFilters/StatusFilters'

export const InvoiceStatusHeaderCell = ({
  header,
  table,
}: HeaderContext<Invoice | null, unknown>) => {
  const { selectedStatuses: statuses } = table.options.meta || {}

  const isFiltering = Boolean(statuses?.length)

  return (
    <InvoiceHeaderCell
      disablePopper={false}
      header={header}
      isFiltering={isFiltering}
      label={translate('component.invoiceTable.status')}
      popperWidth={TableColumnPopperWidth[AllInvoiceTableColumn.Status]}
      table={table}
    >
      <StatusFilters />
    </InvoiceHeaderCell>
  )
}
