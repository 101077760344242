import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  BUTTON_SIZE,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useGoToSavingsAccountLearnMore } from 'features/SavingsAccountLearnMore/savings-account-learn-more-routing'

import { translations } from 'translations/shared.i18n'

import { LearnMoreButtonEntityFragment } from './LearnMoreButton.generated'
import * as styled from './LearnMoreButton.styled'

type LearnMoreButtonProps = {
  entity: LearnMoreButtonEntityFragment
}

export const LearnMoreButton = ({ entity }: LearnMoreButtonProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.SM)
  const { goToSavingsAccountLearnMore } = useGoToSavingsAccountLearnMore()
  const tShared = useTranslations(translations)

  const openLearnMore = useCallback(() => {
    goToSavingsAccountLearnMore({
      stage: 'intro',
      interestRate: entity.savingsAccountProduct?.effectiveInterestRate ?? '',
      canCreateAccount: entity.canCreateAccount,
    })
  }, [
    entity.canCreateAccount,
    entity.savingsAccountProduct?.effectiveInterestRate,
    goToSavingsAccountLearnMore,
  ])

  return (
    <styled.StyledLearnMoreButton
      size={isMobile ? BUTTON_SIZE.XSMALL : BUTTON_SIZE.SMALL}
      onClick={openLearnMore}
    >
      {tShared('learnMore')}
    </styled.StyledLearnMoreButton>
  )
}

LearnMoreButton.fragments = {
  Entity: gql`
    fragment LearnMoreButtonEntityFragment on Entity {
      savingsAccountProduct {
        effectiveInterestRate
      }
      canCreateAccount
    }
  `,
}
