import { COLOR, ColorName } from '@npco/zeller-design-system'

type colorResponse = string | null | undefined

export const getColorFromResponse = (
  color: colorResponse
): string | undefined => {
  if (!color || !Object.keys(COLOR).includes(color)) {
    return undefined
  }

  return COLOR[color as ColorName]
}
