import { Box, COLOR } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface CvcInformationProps {
  isLoading: boolean
}

export const CvcInformation = ({ isLoading }: CvcInformationProps) => {
  return (
    <>
      {!isLoading && (
        <Box position="absolute" color={COLOR.GREY_250} left="16px" top="174px">
          {translate('component.cardSummary.sensitiveCardInformation.cvcLabel')}
        </Box>
      )}
      <Box id="mq-card-cvv" position="absolute" top="174px" left="290px" />
    </>
  )
}
