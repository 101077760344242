import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  overflow: auto;
  padding-bottom: 32px;
`

export const StyledWrapper = styled.div<{ isEditMode?: boolean }>`
  max-width: ${({ isEditMode }) => (isEditMode ? '546px' : '576px')};
  padding-bottom: 20px;
`
