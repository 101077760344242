import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetContactTransactionTotals } from 'features/Contacts/contacts'

import {
  GetContactTransactionTotals as GetContactTransactionTotalsResponse,
  GetContactTransactionTotalsVariables,
} from 'types/gql-types/GetContactTransactionTotals'

export interface UseGetContactTransactionTotalsProps {
  contactUuid: string
  cardholderUuid?: string | null
}

export const useGetContactTransactionTotals = ({
  contactUuid,
  cardholderUuid,
}: UseGetContactTransactionTotalsProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, error, loading } = useQuery<
    GetContactTransactionTotalsResponse,
    GetContactTransactionTotalsVariables
  >(GetContactTransactionTotals, {
    fetchPolicy: 'cache-and-network',
    skip: !contactUuid,
    variables: {
      entityUuid,
      contactUuid,
      cardholderUuid,
    },
  })

  return {
    isContactTransactionTotalsLoading: loading,
    transactionTotals: data?.getContactTransactionTotals || null,
    error,
  }
}
