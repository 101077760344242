import { InvoiceItemUnit, PdfTemplateType } from '@npco/mp-gql-types'
import { UseTranslationsFn } from '@npco/utils-translations'
import { z } from 'zod'

import dayjs from 'utils/dayjs'

import { initialValues } from '../../Invoices/Invoice/components/InvoiceCreateForm/InvoiceCreateForm.utils'
import { InvoiceCustomisationFormFieldsAndPreviewDefaults } from '../../Invoices/Invoice/Invoice.types'
import { GetZellerInvoiceSettingsQueryResponse } from '../graphql/getZellerInvoiceSettings.generated'
import {
  DEFAULT_CUSTOMISATION_SETTINGS,
  INVOICE_DEFAULT_TEMPLATE,
  LOADING_MARKER,
} from './SettingsCustomisation.constants'
import { translations } from './SettingsCustomisation.i18n'
import {
  type GetZellerInvoiceCustomisationResponse,
  INVOICE_CUSTOMISATION_FORM_FIELDS,
  InvoiceSettingsCustomisationFormFields,
} from './SettingsCustomisation.types'

const hasNoNull = (
  array: (string | null)[] | null | undefined
): array is string[] =>
  array !== undefined && array !== null && !array.includes(null)

const isDataUrl = (value: string) => {
  const regex = /data:|blob:/
  return regex.test(value)
}

const isLogoLoading = (value: string) => value.includes(LOADING_MARKER)

export const isValidLogo = (value?: string) =>
  value && (!isLogoLoading(value) || !isDataUrl(value))

export const settingsCustomisationSchema = z.object({
  [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedTemplateType]:
    z.nativeEnum(PdfTemplateType),
  // NOTE: we don't check for valid logos here, because we don't want to block the user from saving
  // we instead remove the invalid logos from the array before updating
  [INVOICE_CUSTOMISATION_FORM_FIELDS.logos]: z.array(z.string()),
  [INVOICE_CUSTOMISATION_FORM_FIELDS.accentColours]: z.array(z.string()),
  [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedColor]: z.string(),
  [INVOICE_CUSTOMISATION_FORM_FIELDS.selectedLogo]: z
    .string()
    .url()
    .or(z.string().nullable()),
})

export const validateSettingsCustomisationForm = async (
  values: Partial<InvoiceSettingsCustomisationFormFields>
) => {
  const result = await settingsCustomisationSchema.safeParseAsync(values)
  if (!result.success) {
    return result.error
  }
  return {}
}

export const getDefaultInvoiceSettingsCustomisationFormValues = (
  values: Partial<GetZellerInvoiceCustomisationResponse> | null | undefined
): InvoiceSettingsCustomisationFormFields =>
  values === null || values === undefined
    ? DEFAULT_CUSTOMISATION_SETTINGS
    : {
        selectedTemplateType:
          values.selectedTemplateType ?? INVOICE_DEFAULT_TEMPLATE,
        logos: hasNoNull(values.logos) ? values.logos : [],
        accentColours: hasNoNull(values?.accentColours)
          ? values?.accentColours
          : [],
        selectedColor: values.selectedColor ?? '',
        selectedLogo: values.selectedLogo ?? '',
        defaultMessage: values.defaultMessage ?? '',
        termsAndConditions: values.termsAndConditions ?? '',
      }

export const getInvoicePreviewCustomisationInput = ({
  address,
  previewValues,
  receiptSettings,
  t,
}: {
  address?: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']['address']
  previewValues: InvoiceSettingsCustomisationFormFields | undefined
  receiptSettings?: GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']['receipt']
  t: UseTranslationsFn<typeof translations>
}): InvoiceCustomisationFormFieldsAndPreviewDefaults => {
  return {
    ...initialValues,
    customisation: previewValues,
    defaults: {
      addressCity: address?.suburb ?? '',
      addressPostcode: address?.postcode ?? '',
      addressState: address?.state ?? '',
      addressStreet: address?.street ?? '',
      attentionToContactFirstName: t('attentionToFirstName'),
      attentionToContactLastName: t('attentionToLastName'),
      businessEmail: receiptSettings?.email ?? t('businessEmail'),
      businessName: receiptSettings?.name ?? t('businessName'),
      businessNumber: t('businessNumber'),
      businessPhone: receiptSettings?.phone ?? t('businessPhone'),
      businessWebsite: receiptSettings?.website ?? t('businessWebsite'),
      customerBusinessName: t('customerBusinessName'),
      dueDate: dayjs().format('DD/MM/YYYY'),
      items: [
        {
          description: t('itemDescription'),
          name: t('itemName'),
          price: '1000000',
          quantity: 1,
          taxes: [{ enabled: true }],
          unit: InvoiceItemUnit.DAY,
        },
        {
          description: t('itemDescription'),
          name: t('itemName'),
          price: '1000000',
          quantity: 10,
          taxes: [{ enabled: true }],
          unit: InvoiceItemUnit.QUANTITY,
        },
      ],
      itemsTaxInclusive: false,
      referenceNumber: t('referenceNumber'),
      title: t('invoiceTitle'),
    },
  }
}
