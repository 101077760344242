import { useMutation } from '@apollo/client'
import { UpdateInvoiceInput } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  UpdateInvoice as UpdateInvoiceResponse,
  UpdateInvoiceVariables,
} from 'types/gql-types/UpdateInvoice'

import { UpdateInvoice } from '../graphql/updateInvoice'

export const useUpdateInvoice = () => {
  const entityUuid = useSelectedEntityUuid()
  const [updateInvoice, { data, loading: isUpdatingInvoice }] = useMutation<
    UpdateInvoiceResponse,
    UpdateInvoiceVariables
  >(UpdateInvoice)

  return {
    updateInvoice: (input: UpdateInvoiceInput) =>
      updateInvoice({
        variables: {
          entityUuid,
          input,
        },
      }),
    data: data?.updateInvoice,
    isUpdatingInvoice,
  }
}
