import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { InvoiceAction, InvoiceActivityStatus } from '@npco/mp-gql-types'

import dayjs from 'utils/dayjs'
import {
  InvoiceDetailsFragment_activities as Activity,
  InvoiceDetailsFragment_payments as Payments,
} from 'types/gql-types/InvoiceDetailsFragment'

export const getDifferenceInDays = ({
  date,
  diff,
}: {
  date: Date
  diff?: Date
}): number => {
  const startOfDate = dayjs(date).startOf('day')
  const startOfDiff = dayjs(diff).startOf('day')
  return startOfDate.diff(startOfDiff, 'day')
}

export const getEarliestScheduledReminder = (activities: Activity[]) => {
  const scheduledReminders = activities.filter(
    ({ dueDate, type, status }) =>
      dayjs(dueDate).isAfter(dayjs(new Date())) &&
      type === InvoiceAction.SEND_AUTO_REMINDER &&
      status === InvoiceActivityStatus.SCHEDULED
  )

  if (scheduledReminders.length === 0) {
    return null
  }

  return scheduledReminders.reduce((a, b) =>
    dayjs(a?.dueDate).isAfter(dayjs(b?.dueDate)) ? b : a
  )
}

export const getLastPayment = (payments: Payments[]) =>
  payments.reduce((a, b) =>
    dayjs(a.paymentTimeISO).isAfter(dayjs(b.paymentTimeISO)) ? a : b
  )

export const formatDate = (date: string) =>
  dayjs(date).format(DATE_FULL_DISPLAY_FORMAT)
