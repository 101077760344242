import { ModalBasicProps } from '@npco/zeller-design-system'

import { ReactComponent as IllustrationCalendar } from 'assets/svg/graphics/calendar.svg'
import { translate } from 'utils/translations'

import { getFormattedDescription } from '../InvoiceSendSuccessModal/InvoiceSendSuccessModal.utils'
import * as styled from './InvoiceScheduleSuccessModal.styled'

export const translations = {
  primaryButtonLabel: translate(
    'page.invoice.scheduleInvoiceSuccessModalPrimaryButtonLabel'
  ),
  secondaryButtonLabel: translate(
    'page.invoice.scheduleInvoiceSuccessModalSecondaryButtonLabel'
  ),
  modalHeading: translate('page.invoice.scheduleInvoiceSuccessModalHeading'),
  modalDescription: (sendDate: string) =>
    translate('page.invoice.scheduleInvoiceSuccessModalDescription', {
      sendDate,
    }),
}

interface InvoiceScheduleSuccessModalProps
  extends Omit<ModalBasicProps, 'children'> {
  onClickPrimary: () => void
  onClickSecondary: () => void
  payerName: string
  referenceNumber: string
  sendDate: string
}

export const InvoiceScheduleSuccessModal = ({
  isOpen,
  onCancel,
  onClickPrimary,
  onClickSecondary,
  payerName,
  referenceNumber,
  sendDate,
}: InvoiceScheduleSuccessModalProps) => {
  const description = getFormattedDescription({
    referenceNumber,
    payerName,
    description: translations.modalDescription(sendDate),
  })

  return (
    <styled.SuccessAnnouncementModal
      Illustration={<IllustrationCalendar />}
      description={description}
      heading={translations.modalHeading}
      isOpen={isOpen}
      onCancel={onCancel}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      primaryButtonLabel={translations.primaryButtonLabel}
      secondaryButtonLabel={translations.secondaryButtonLabel}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    />
  )
}
