import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  connected: 'Connected',
  notConnected: 'Not connected',
  ethernet: 'Ethernet',
  sim: 'SIM',
  simOperator: 'SIM Operator',
  ssid: 'SSID',
  ipAddress: 'IP Address',
  macAddress: 'MAC Address',
  signalStrength: 'Signal Strength',
  wifi: 'Wi-Fi',
})
