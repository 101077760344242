import { useTranslations } from '@npco/utils-translations'
import {
  ButtonLink,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { setupFlowAccountDetailsErrorTranslations } from './AccountDetailsError.i18n'
import { AccountDetailsErrorCard } from './AccountDetailsError.styled'

interface AccountDetailsErrorProps {
  retry: () => void
  isLoading?: boolean
}

export const AccountDetailsError = ({
  retry,
  isLoading,
}: AccountDetailsErrorProps) => {
  const t = useTranslations(setupFlowAccountDetailsErrorTranslations)
  const tShared = useTranslations(translationsShared)

  return (
    <AccountDetailsErrorCard>
      <InfoBox
        variant={INFOBOX_VARIANT.NEGATIVE}
        priority={INFOBOX_PRIORITY.DEFAULT}
      >
        <InfoBox.Header>{t('errorMessage')}</InfoBox.Header>
      </InfoBox>

      <ButtonLink onClick={retry} isLoading={isLoading}>
        {tShared('tryAgain')}
      </ButtonLink>
    </AccountDetailsErrorCard>
  )
}
