import { useEffect, useMemo, useRef, useState } from 'react'

import { ALL_KEYBOARD_LETTERS_AND_NUMBERS, KEY_CODE } from 'const/keyboard'

import { SelectItemBasic } from '../../Select.types'

interface Args<ItemType> {
  isOpen: boolean
  openMenu: () => void
  onKeyDown: (e: React.KeyboardEvent) => void
  setHighlightedIndex: (index: number) => void
  focusMenu: () => void
  isSearchableByKeypress?: boolean
  items: ItemType[]
  menuId: string
  highlightedIndex: number
  shouldAutoFocusControl?: boolean
}

const NO_FOCUSED_CONTROL_INDEX = -1
const FIRST_FOCUSABLE_CONTROL_INDEX = 0
const INITIAL_LIST_ACTION_INDEX = -1

export function useSelectA11y<ItemType extends SelectItemBasic>({
  isOpen,
  openMenu,
  onKeyDown,
  setHighlightedIndex,
  focusMenu,
  isSearchableByKeypress,
  items,
  menuId,
  highlightedIndex,
  shouldAutoFocusControl,
}: Args<ItemType>) {
  const initialFocusedControlIndex = useMemo(
    () =>
      shouldAutoFocusControl
        ? FIRST_FOCUSABLE_CONTROL_INDEX
        : NO_FOCUSED_CONTROL_INDEX,
    [shouldAutoFocusControl]
  )

  const focusedControlIndexRef = useRef(initialFocusedControlIndex)
  const listItemControlIndexRef = useRef(INITIAL_LIST_ACTION_INDEX)
  const [lastIndex, setLastIndex] = useState(highlightedIndex)

  const getIsListItemControl = (flag: boolean) => (control: HTMLElement) => {
    const isChildOfSelectOption = Array.from(
      document.querySelectorAll('[aria-selected]')
    ).some((listItem) => listItem.contains(control))

    if (isChildOfSelectOption) {
      return flag
    }

    return !flag
  }

  const allControls = Array.from<HTMLElement>(
    document
      .getElementById(menuId)
      ?.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
      ) ?? []
  )

  const listItemControls = allControls.filter(getIsListItemControl(true))

  const accessibleControls = allControls.filter(getIsListItemControl(false))

  const selectItemAndBlurControl = (index: number) => {
    focusMenu()
    openMenu()
    setHighlightedIndex(index)
    focusedControlIndexRef.current = NO_FOCUSED_CONTROL_INDEX
    listItemControlIndexRef.current = INITIAL_LIST_ACTION_INDEX
  }

  const findAndMarkFirstItemByGivenLetter = (eventKey: string) => {
    const index = items.findIndex(
      (item) => item.label.charAt(0).toUpperCase() === eventKey.toUpperCase()
    )

    if (index >= 0) {
      openMenu()
      setHighlightedIndex(index)
    }
  }

  const switchToListItemControl = (selectedListItemControls: HTMLElement[]) => {
    setLastIndex(highlightedIndex)
    const newListActionIndex = listItemControlIndexRef.current + 1
    selectedListItemControls[newListActionIndex].focus()
    selectedListItemControls[newListActionIndex].scrollTo()
    openMenu()
    listItemControlIndexRef.current = newListActionIndex
    setHighlightedIndex(highlightedIndex)
  }

  const switchBetweenControlsOrChooseItemUsingTab = () => {
    const isListFocused =
      focusedControlIndexRef.current === NO_FOCUSED_CONTROL_INDEX

    if (isListFocused) {
      const selectedListItemControls = listItemControls.filter((control) =>
        document
          .querySelector(`#${menuId} [aria-selected="true"]`)
          ?.contains(control)
      )

      const isNoMoreListItemControls =
        listItemControlIndexRef.current >= selectedListItemControls.length - 1

      if (isNoMoreListItemControls) {
        listItemControlIndexRef.current = INITIAL_LIST_ACTION_INDEX
      } else {
        switchToListItemControl(selectedListItemControls)
        return
      }
    }

    if (focusedControlIndexRef.current === accessibleControls.length - 1) {
      focusMenu()
      openMenu()
      setHighlightedIndex(0)
      focusedControlIndexRef.current = NO_FOCUSED_CONTROL_INDEX
      return
    }

    const newId = focusedControlIndexRef.current + 1

    focusedControlIndexRef.current = newId
    accessibleControls[newId]?.focus()

    openMenu()
  }

  const customOnKeyDown = (event: React.KeyboardEvent) => {
    if (!isOpen) {
      onKeyDown(event)
      return
    }

    if (
      event.key === KEY_CODE.ARROW_DOWN &&
      focusedControlIndexRef.current > NO_FOCUSED_CONTROL_INDEX
    ) {
      event.preventDefault()
      selectItemAndBlurControl(0)
      return
    }

    const isFocusedOnListItemControl =
      listItemControlIndexRef.current > INITIAL_LIST_ACTION_INDEX

    if (event.key === KEY_CODE.ARROW_DOWN && isFocusedOnListItemControl) {
      selectItemAndBlurControl(lastIndex)
    }

    if (
      event.key === KEY_CODE.ARROW_UP &&
      focusedControlIndexRef.current > NO_FOCUSED_CONTROL_INDEX
    ) {
      event.preventDefault()
      selectItemAndBlurControl(items.length - 1)
      return
    }

    if (event.key === KEY_CODE.ARROW_UP && isFocusedOnListItemControl) {
      selectItemAndBlurControl(lastIndex)
    }

    if (
      isSearchableByKeypress &&
      ALL_KEYBOARD_LETTERS_AND_NUMBERS.includes(event.key)
    ) {
      findAndMarkFirstItemByGivenLetter(event.key)
      return
    }

    if (event.key === KEY_CODE.SPACE) {
      return
    }

    const hasAccessibleAdditionalControls = Boolean(
      accessibleControls.length || listItemControls.length
    )

    if (event.key === KEY_CODE.TAB && hasAccessibleAdditionalControls) {
      event.preventDefault()
      switchBetweenControlsOrChooseItemUsingTab()
      return
    }

    onKeyDown(event)
  }

  useEffect(() => {
    if (!isOpen) {
      focusedControlIndexRef.current = NO_FOCUSED_CONTROL_INDEX
    } else if (shouldAutoFocusControl) {
      focusedControlIndexRef.current = initialFocusedControlIndex
      accessibleControls[initialFocusedControlIndex]?.focus()

      openMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, accessibleControls.length])

  return {
    customOnKeyDown,
  }
}
