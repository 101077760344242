import { gql } from '@apollo/client'

export const CancelInvoice = gql`
  mutation CancelInvoice($entityUuid: ID!, $referenceNumber: String!) {
    cancelInvoice(entityUuid: $entityUuid, referenceNumber: $referenceNumber) {
      __typename
      id
      paymentLink
      dueAmount
      paidAmount
      referenceNumber
      status
      activities {
        __typename
        balance
        cnpTxnRefNum
        completedTime
        contactName
        contactUuid
        dueDate
        failureReason
        id
        paidAmount
        status
        reminderIndex
        title
        type
      }
      payments {
        __typename
        id
        paymentTimeISO
      }
    }
  }
`
