import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  AnchorBasic,
  Box,
  Divider,
  Flex,
  Heading,
  HeadingStyles,
  PageTemplate,
  showErrorToast,
  showSuccessToast,
  Typography,
} from '@npco/zeller-design-system'

import { copyToClipboard } from 'utils/common'
import { useGetReferralCode } from 'pages/Referral/Referral.hooks'

import { referralTranslations } from './Referral.i18n'
import * as styled from './Referral.styled'

export const REFERRAL_LINK_URL = 'https://www.myzeller.com/referral/a?grsf='
export const REFERRAL_PROGRAM_URL = 'https://www.myzeller.com/referral-program'

const ReferralButton = () => {
  const { error, isLoading, entityReferral } = useGetReferralCode()
  const t = useTranslations(referralTranslations)

  const isReady =
    Boolean(entityReferral.referralCode) && // If referral code is present, then this is ready.
    !error && // If no errors, then this is ready.
    Boolean(isLoading) === false // If loading is false or empty string, then this is ready.

  const triggerErrorToast = useCallback(
    () => showErrorToast(t('somethingWentWrong')),
    [t]
  )

  const handleClick = useCallback(async () => {
    try {
      await copyToClipboard(
        `${REFERRAL_LINK_URL}${entityReferral.referralCode}`
      )
      showSuccessToast(t('referralLinkCopied'))
    } catch (e) {
      triggerErrorToast()
    }
  }, [entityReferral?.referralCode, t, triggerErrorToast])

  return (
    <styled.ReferralLinkActionWrapper>
      <styled.ReferralLinkButton
        dataTestId="copy-referral-link"
        isLoading={isLoading}
        onClick={isReady ? handleClick : triggerErrorToast}
      >
        {t('referralLinkLabel')}
      </styled.ReferralLinkButton>
    </styled.ReferralLinkActionWrapper>
  )
}

export const Referral = () => {
  const t = useTranslations(referralTranslations)

  const BULLET_POINTS = [
    {
      key: 'howItWorksSubHeading1',
      heading: t('howItWorksSubHeading1'),
      description: t('howItWorksDescription1'),
    },
    {
      key: 'howItWorksSubHeading2',
      heading: t('howItWorksSubHeading2'),
      description: t('howItWorksDescription2'),
    },
    {
      key: 'howItWorksSubHeading3',
      heading: t('howItWorksSubHeading3'),
      description: t('howItWorksDescription3'),
    },
  ]

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex alignItems="center" as="header" minHeight="48px">
            <Heading.H1 withStyles={HeadingStyles.H2}>
              {t('headline')}
            </Heading.H1>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>
        <Typography component="p" variant="body-base" mb="24px">
          {t('copy')}
        </Typography>
        <Typography component="p" variant="body-base" mb="24px">
          {t('secondCopy')}
        </Typography>
        <ReferralButton />

        <Divider />

        <Typography component="h3" variant="heading-lg" mb="24px" mt="8px">
          {t('howItWorksTitle')}
        </Typography>

        <styled.NumberedList>
          {BULLET_POINTS.map(({ description, heading, key }) => (
            <styled.Item key={key}>
              <Box marginLeft="16px">
                <styled.ItemHeading>{heading}</styled.ItemHeading>
                <styled.ItemDescription>{description}</styled.ItemDescription>
              </Box>
            </styled.Item>
          ))}
        </styled.NumberedList>

        <Divider />

        <Flex>
          <Typography component="p" variant="body-base">
            {t('termsAndConditions')}
            <AnchorBasic target="_blank" href={REFERRAL_PROGRAM_URL}>
              {t('zellerReferralProgramPage')}
            </AnchorBasic>
          </Typography>
        </Flex>
      </PageTemplate.Section>
    </PageTemplate>
  )
}
