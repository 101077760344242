import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { COLOR } from '@npco/zeller-design-system'
import { sumBy } from 'lodash-es'

import { useGetCategoryName } from '../../../hooks/useGetCategoryName'
import { NetAmountWithOtherCategory } from '../../../TransactionsSummary.types'
import { getActiveColorList } from '../../../utils/getActiveColorList'
import { HoveredCategoryNetAmount } from '../../TransactionsSummaryCategory.types'
import { UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment } from './useGetCategoryPieChartData.generated'

export const CHART_ACTIVE_COLORS = [
  COLOR.BLUE_400,
  COLOR.BLUE_700,
  COLOR.POS_DARK_BLUE,
  COLOR.DARK_BLUE,
  COLOR.GREY_580,
  COLOR.BLACK_900,
  COLOR.POS_MID_GREEN,
  COLOR.POS_LIGHT_GREEN,
  COLOR.POS_DARK_ORANGE,
  COLOR.CORAL,
]

interface UseGetCategoryPieChartDataProps {
  netAmounts: NetAmountWithOtherCategory<UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment>[]
  hoveredCategoryNetAmount: HoveredCategoryNetAmount
}

export const useGetCategoryPieChartData = ({
  netAmounts,
  hoveredCategoryNetAmount,
}: UseGetCategoryPieChartDataProps) => {
  const getCategoryName = useGetCategoryName()

  const totalAmount = useMemo(
    () => sumBy(netAmounts, (netAmount) => parseInt(netAmount.total.value, 10)),
    [netAmounts]
  )

  const activeNetAmount = useMemo(() => {
    if (hoveredCategoryNetAmount === undefined) {
      return undefined
    }

    const isHoveringOtherCategory = netAmounts.every(
      (netAmount) => netAmount.category !== hoveredCategoryNetAmount.category
    )

    return netAmounts.find(
      (netAmount) =>
        netAmount.category ===
        (isHoveringOtherCategory ? 'other' : hoveredCategoryNetAmount.category)
    )
  }, [hoveredCategoryNetAmount, netAmounts])

  const pieChartData = useMemo(() => {
    const colorList = getActiveColorList(netAmounts.length, CHART_ACTIVE_COLORS)
    const percentageTotalAmount = sumBy(netAmounts, (netAmount) =>
      Math.abs(parseInt(netAmount?.total.value ?? 0, 10))
    )

    return netAmounts.map((netAmount, index) => {
      return {
        fill:
          !activeNetAmount || activeNetAmount.category === netAmount.category
            ? colorList[index % colorList.length]
            : COLOR.GREY_90,
        name: getCategoryName(netAmount.category),
        percentage:
          (Math.abs(parseInt(netAmount.total.value ?? 0, 10)) /
            percentageTotalAmount) *
          100,
      }
    })
  }, [netAmounts, getCategoryName, activeNetAmount])

  return {
    pieChartData,
    totalAmount,
    activeNetAmount,
  }
}

useGetCategoryPieChartData.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      category
      total {
        value
      }
    }
  `,
}
