import { type ComponentProps, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  Box,
  Flex,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { rvProfileSettings } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'
import { ModalBasic as Modal } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { translate } from 'utils/translations'
import { getWebAuth } from 'services/auth0'
import {
  CancelButton,
  ConfirmButton,
  ModalDescription,
} from 'components/Modal/ModalElements/ModalElements'
import { errorMessages, page } from 'translations'

type ChangePasswordModalProps = Pick<
  ComponentProps<typeof Modal>,
  'isOpen' | 'onCancel'
> & {
  onComplete: () => void
}

export const ChangePasswordModal = ({
  isOpen,
  onCancel,
  onComplete,
}: ChangePasswordModalProps): JSX.Element => {
  const webAuth = useMemo(() => getWebAuth(), [])

  const { email } = useReactiveVar(rvProfileSettings)

  const description = `${page.settings.profileSettings.changePasswordConfirmation} ${email}. ${page.settings.profileSettings.changePasswordConfirmationNextLine}`

  const handleChangePassword = () => {
    if (email) {
      webAuth.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err) => {
          if (err) {
            showErrorToast(errorMessages.errorChangePassword)
          } else {
            showSuccessToast(
              page.settings.profileSettings.successChangePassword
            )
          }
        }
      )
    }

    onComplete()
  }

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={translate('page.settings.profileSettings.changePassword')}
    >
      <Box data-testid="change-password-modal" width={['100%', '28rem']}>
        <ModalDescription>
          {email ? description : errorMessages.cannotFindEmail}
        </ModalDescription>
        <Flex justifyContent="space-between">
          <CancelButton onClick={onCancel}>
            {translate('shared.cancel')}
          </CancelButton>
          <ConfirmButton onClick={handleChangePassword}>
            {translate('shared.confirm')}
          </ConfirmButton>
        </Flex>
      </Box>
    </Modal>
  )
}
