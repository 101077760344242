import { useCallback } from 'react'
import { SavedEntitySession } from '@npco/mp-utils-multi-entity-store'
import { sortBy } from 'lodash-es'

import { UNEXPECTED_ERROR } from 'types/errors'

import { useUpdateVisibleTabsMutation } from './graphql/UpdateVisibleTabs.generated'

type UseUpdateVisibleTabsOptions = Parameters<
  typeof useUpdateVisibleTabsMutation
>[0]

export const useUpdateVisibleTabs = (
  options: UseUpdateVisibleTabsOptions = {}
) => {
  const [updateVisibleTabsMutation, { loading: isUpdatingVisibleTabs }] =
    useUpdateVisibleTabsMutation(options)

  const updateVisibleTabs = useCallback(
    async (visibleTabs: SavedEntitySession[]) => {
      try {
        const orderedTabs = sortBy(visibleTabs, 'order').map((tab) => ({
          entityUuid: tab.entityUuid,
          isActive: tab.isActive,
        }))

        const result = await updateVisibleTabsMutation({
          variables: {
            input: {
              orderedTabs,
            },
          },
        })

        if (!result.data || result.data.updateVisibleTabs !== true) {
          return UNEXPECTED_ERROR
        }

        return result.data
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [updateVisibleTabsMutation]
  )

  return { updateVisibleTabs, isUpdatingVisibleTabs }
}
