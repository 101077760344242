import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { isEqual } from 'lodash-es'

import { checkIfNeedFilteredByRefunded } from '../../../../features/Transactions/components/Transactions.utils'
import { CacheLocalStateEntityScopedVariable } from '../../../../graphql/cache.types'
import { GetContactTransactionsLocalState } from './getContactTransactionsLocalState'
import { GetTransactionsLocalState } from './getTransactionsLocalState'
import { TransactionsDefaultLocalState } from './TransactionsFilters.constants'
import {
  CacheLocalStateTransactions,
  TransactionsFiltersInput,
} from './TransactionsFilters.types'
import {
  createSplitPaymentApiFilter,
  mapFiltersInputToTransactionsApiFilter,
} from './TransactionsFilters.utils'
import { TransactionsCacheKey } from './useTransactionsLocalCache'

export type UseTransactionFiltersProps = {
  splitPaymentId?: string
  key?: TransactionsCacheKey
}

export const useTransactionsFilters = ({
  splitPaymentId,
  key = 'transactions',
}: UseTransactionFiltersProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const { data: cacheData } = useQuery<
    CacheLocalStateTransactions<typeof key>,
    CacheLocalStateEntityScopedVariable
  >(
    key === 'transactions'
      ? GetTransactionsLocalState
      : GetContactTransactionsLocalState,
    { variables: { entityUuid } }
  )

  const search = cacheData?.local[key].filters.search ?? null
  const amount = cacheData?.local[key].filters.amount ?? null
  const terminals = cacheData?.local[key].filters.terminals ?? null
  const dates = cacheData?.local[key].filters.dates ?? null
  const sources = cacheData?.local[key].filters.sources ?? null
  const statuses = cacheData?.local[key].filters.statuses ?? null
  const types = cacheData?.local[key].filters.types ?? null
  const siteUuid = cacheData?.local[key].filters.siteUuid ?? null

  const { filters, isDefaultFilters } = useMemo(() => {
    const values: TransactionsFiltersInput = {
      dates,
      search,
      amount,
      sources,
      statuses,
      terminals,
      types,
      siteUuid,
    }

    const isDefaultFilters = isEqual(
      values,
      TransactionsDefaultLocalState.filters
    )

    const filters = !splitPaymentId
      ? mapFiltersInputToTransactionsApiFilter(values)
      : createSplitPaymentApiFilter(values, splitPaymentId)

    return { filters, isDefaultFilters }
  }, [
    dates,
    search,
    amount,
    sources,
    statuses,
    terminals,
    types,
    siteUuid,
    splitPaymentId,
  ])

  const isFilteringByRefunded = checkIfNeedFilteredByRefunded(statuses)

  const isFiltersSelected = !isDefaultFilters || !!splitPaymentId

  return {
    filters,
    isDefaultFilters,
    isFilteringByRefunded,
    isFiltersSelected,
    isFiltersVisible: !!cacheData?.local[key].isFiltersVisible,
  }
}
