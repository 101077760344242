import { useCallback, useEffect, useState } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'

import {
  CopyCardPan,
  CopyCvv,
  CopyExpiry,
  Cvv,
  Expiry,
  Pan,
} from '../SensitiveCardInformation.types'

interface UseSensitiveCardInformationProps {
  pan: Pan
  expiry: Expiry
  cvv: Cvv
  copyPan?: CopyCardPan
  copyExpiry?: CopyExpiry
  copyCvv?: CopyCvv
  clientAccessToken: string | undefined
  skip: boolean
  onSuccess?: () => void
  onError?: () => void
  onCopySuccess?: (id: string) => void
}

export const useSensitiveCardInformation = ({
  pan,
  expiry,
  cvv,
  copyPan,
  copyExpiry,
  copyCvv,
  clientAccessToken,
  skip,
  onSuccess,
  onError,
  onCopySuccess,
}: UseSensitiveCardInformationProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleError = useCallback(
    (error: any) => {
      ErrorLogger.report(
        '[Banking] Marqeta sensitive data loading error',
        error
      )
      setIsLoading(false)
      onError?.()
    },
    [onError]
  )

  const handleSuccess = useCallback(() => {
    setIsLoading(false)
    onSuccess?.()
  }, [onSuccess])

  useEffect(() => {
    if (skip) {
      return
    }

    if (clientAccessToken) {
      setIsLoading(true)
      try {
        window.marqeta.bootstrap({
          clientAccessToken,
          component: {
            showPan: {
              cardPan: {
                domId: pan.id,
                format: true,
                styles: {
                  span: {
                    'font-family': 'Arial',
                    'font-size': pan.size,
                    'letter-spacing': '0.4px',
                    color: '#212322',
                  },
                },
              },
              ...(copyPan
                ? {
                    copyCardPan: {
                      domId: copyPan.id,
                      mode: 'transparent',
                      onCopySuccess: () => onCopySuccess?.(copyPan.id),
                    },
                  }
                : {}),
              cardExp: {
                domId: expiry.id,
                format: true,
                styles: {
                  span: {
                    'font-family': 'Arial',
                    'font-size': expiry.size,
                    'letter-spacing': '0.4px',
                    color: '#212322',
                  },
                },
              },
              ...(copyExpiry
                ? {
                    copyCardExp: {
                      domId: copyExpiry.id,
                      mode: 'transparent',
                      onCopySuccess: () => onCopySuccess?.(copyExpiry.id),
                    },
                  }
                : {}),
              cardCvv: {
                domId: cvv.id,
                styles: {
                  span: {
                    'font-family': 'Arial',
                    'font-size': cvv.size,
                    'letter-spacing': '0.4px',
                    color: '#212322',
                  },
                },
              },
              ...(copyCvv
                ? {
                    copyCardCvv: {
                      domId: copyCvv.id,
                      mode: 'transparent',
                      onCopySuccess: () => onCopySuccess?.(copyCvv.id),
                    },
                  }
                : {}),
            },
          },
          callbackEvents: {
            onSuccess: handleSuccess,
            onFailure: handleError,
          },
        })
      } catch (error) {
        handleError(error)
      }
    }
    // only watch the changes of clientAccessToken and skip
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, clientAccessToken])

  return {
    isLoading,
  }
}
