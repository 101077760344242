import { useCallback, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { PaymentInstrumentType } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { rvSelectedContactToTransfer } from 'features/Contacts/rv-deprecated/contacts'
import { ApiCallType, useRedirectToMFA } from 'features/MFA'

import { BankAccountDetails } from 'hooks/paymentInstruments/PaymentInstruments.types'
import { useCreateBsbPaymentInstrument } from 'hooks/paymentInstruments/useCreateBsbPaymentInstrument'
import { useLinkBsbPaymentInstrument } from 'hooks/paymentInstruments/useLinkBsbPaymentInstrument'
import { AuthScope } from 'types/auth'
import {
  MFA_ERROR,
  RESOURCE_ALREADY_EXISTS_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { PaymentInstrumentSource } from 'services/Analytics/events/contact'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { AddBankAccountModal } from 'pages/Transfer/AddModal/AddBankAccountModal/AddBankAccountModal'
import {
  isPaymentInstrumentExisting,
  removePaymentInstrumentFromUnlinkedList,
  showAddAccountToast,
  updateSelectedContactWithPaymentInstrument,
} from 'pages/Transfer/ContactDropdown/AddBankAccount/AddBankAccount.utils'
import {
  CreatePaymentInstrumentError,
  useCreatePaymentInstrumentOnMFARedirect,
} from 'pages/Transfer/hooks/useCreatePaymentInstrumentOnMFARedirect/useCreatePaymentInstrumentOnMFARedirect'

interface AddBankAccountProps {
  closeModal: () => void
  onSaveHandler: (id: string, values: BankAccountDetails) => void
}

export const AddBankAccount = ({
  closeModal,
  onSaveHandler,
}: AddBankAccountProps) => {
  const [isLinkedPaymentInstrument, setIsLinkedPaymentInstrument] =
    useState(false)

  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const handleError = useCallback((err: CreatePaymentInstrumentError) => {
    if (err === RESOURCE_ALREADY_EXISTS_ERROR) {
      setIsLinkedPaymentInstrument(true)
    }

    showAddAccountToast(false)
  }, [])

  const handleCreatePaymentInstrumentSuccess = useCallback(
    (newPaymentInstrumentID: string, values: BankAccountDetails) => {
      showAddAccountToast(true)

      trackAnalyticsEvent(AnalyticsEventNames.PAYMENT_INSTRUMENT_CREATED, {
        Type: PaymentInstrumentType.BSB,
        Location: locationName.current,
      })

      updateSelectedContactWithPaymentInstrument(newPaymentInstrumentID, values)

      trackAnalyticsEvent(
        AnalyticsEventNames.CONTACT_ATTACHED_PAYMENT_INSTRUMENT,
        {
          Type: PaymentInstrumentType.BSB,
          InstrumentSource: PaymentInstrumentSource.New,
          Location: locationName.current,
        }
      )

      closeModal()
      onSaveHandler(newPaymentInstrumentID, values)
    },
    [closeModal, locationName, onSaveHandler, trackAnalyticsEvent]
  )

  const {
    isCreatingPaymentInstrument: isCreatingAccountOnRedirect,
    bankAccountDetails,
    hasRedirectedBackToApp,
  } = useCreatePaymentInstrumentOnMFARedirect({
    onSuccess: handleCreatePaymentInstrumentSuccess,
    onError: handleError,
  })

  const initialValues = useMemo(() => {
    if (hasRedirectedBackToApp) {
      return bankAccountDetails
    }

    return undefined
    // Memoise very first values, on redirect these will be updated
    // but should avoid updating this value until modal is closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { createPaymentInstrument, isCreatingPaymentInstrument } =
    useCreateBsbPaymentInstrument()
  const { linkPaymentInstrument, isLinkingPaymentInstrument } =
    useLinkBsbPaymentInstrument()
  const selectedContact = useReactiveVar(rvSelectedContactToTransfer)
  const { redirectToMFA } = useRedirectToMFA()

  const onSave = useCallback(
    async (values: BankAccountDetails) => {
      const { bsb, account } = values
      const existingPaymentInstrumentId = isPaymentInstrumentExisting(
        bsb,
        account
      )

      if (!selectedContact) {
        return
      }

      try {
        if (!existingPaymentInstrumentId) {
          const createResponse = await createPaymentInstrument(
            values,
            selectedContact.id
          )

          if (createResponse === UNEXPECTED_ERROR) {
            showAddAccountToast(false)
            return
          }

          if (createResponse === MFA_ERROR) {
            redirectToMFA({
              apiCallType: ApiCallType.CreatePaymentInstrument,
              scope: AuthScope.SENSITIVE,
              variables: {
                contactId: selectedContact.id,
                ...values,
              },
              contact: selectedContact,
            })

            return
          }

          if (createResponse === RESOURCE_ALREADY_EXISTS_ERROR) {
            showAddAccountToast(false)
            setIsLinkedPaymentInstrument(true)
            return
          }

          handleCreatePaymentInstrumentSuccess(createResponse.id, values)
          return
        }

        const linkResponse = await linkPaymentInstrument(
          selectedContact?.id,
          existingPaymentInstrumentId
        )

        if (!linkResponse.data?.linkPaymentInstrumentWithContact) {
          showAddAccountToast(false)
          ErrorLogger.report(
            '[Banking] Transfer flow: Failed to link paymentInstrument'
          )
          return
        }
        showAddAccountToast(true)
        updateSelectedContactWithPaymentInstrument(
          existingPaymentInstrumentId,
          values
        )
        removePaymentInstrumentFromUnlinkedList(existingPaymentInstrumentId)
        trackAnalyticsEvent(
          AnalyticsEventNames.CONTACT_ATTACHED_PAYMENT_INSTRUMENT,
          {
            Type: PaymentInstrumentType.BSB,
            InstrumentSource: PaymentInstrumentSource.Unlinked,
            Location: locationName.current,
          }
        )
        closeModal()
        onSaveHandler(existingPaymentInstrumentId, values)
      } catch (error) {
        showAddAccountToast(false)
      }
    },
    [
      closeModal,
      createPaymentInstrument,
      handleCreatePaymentInstrumentSuccess,
      linkPaymentInstrument,
      locationName,
      onSaveHandler,
      redirectToMFA,
      selectedContact,
      trackAnalyticsEvent,
    ]
  )

  // This should never happen as accounts field will be disabled until contact is selected
  if (!selectedContact) {
    return null
  }

  return (
    <AddBankAccountModal
      onSave={onSave}
      onCancel={closeModal}
      isLoading={
        isCreatingPaymentInstrument ||
        isLinkingPaymentInstrument ||
        isCreatingAccountOnRedirect
      }
      isLinkedPaymentInstrument={isLinkedPaymentInstrument}
      setIsLinkedPaymentInstrument={setIsLinkedPaymentInstrument}
      initialValues={initialValues}
    />
  )
}
