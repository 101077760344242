import {
  BodySmallTypography,
  DURATION,
  EASING_FUNCTION,
  getTransition,
  StyledAvatarBasic,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { SelectSize } from '../Select.types'

const CONTENT_LABEL_TRANSITION_PROPERTIES = getTransition(
  DURATION.EXTRA_FAST,
  EASING_FUNCTION.IN_OUT
)
const ADAPTIVE_TRANSITION_PROPERTIES = getTransition(
  DURATION.NORMAL,
  EASING_FUNCTION.IN_OUT
)

const DEFAULT_SELECT_SIZE = SelectSize.Medium

const SIZE = {
  [SelectSize.ExtraLarge]: {
    padding: '0 1.25rem',
    height: '4.75rem',
    lineHeight: '1.75rem',
  },
  [SelectSize.Large]: {
    padding: '0 1.25rem',
    height: '4rem',
    lineHeight: '1.75rem',
  },
  [SelectSize.Medium]: {
    padding: '0 1.25rem',
    height: '3.5rem',
    lineHeight: '1.5rem',
  },
  [SelectSize.Small]: {
    padding: '0 1.25rem',
    height: '3rem',
    lineHeight: '1.5rem',
  },
}

const FONT_SIZE = {
  [SelectSize.ExtraLarge]: '1.125rem',
  [SelectSize.Large]: '1.125rem',
  [SelectSize.Medium]: '1rem',
  [SelectSize.Small]: '1rem',
}

const TRANSLATE_POSITION_Y = {
  [SelectSize.ExtraLarge]: '-2.25rem',
  [SelectSize.Large]: '-2rem',
  [SelectSize.Medium]: '-1.75rem',
  [SelectSize.Small]: '-1.5rem',
}

export const StyledLeftAdornmentWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: opacity ${ADAPTIVE_TRANSITION_PROPERTIES};
  margin-right: 0.25rem;
`

export const StyledSelectAdaptiveIcon = styled.div<{
  isOpen?: boolean
}>`
  margin-left: 0.5rem;
  transition: ${ADAPTIVE_TRANSITION_PROPERTIES};
  transition-property: transform;
  ${({ isOpen }) => isOpen && 'transform: rotateZ(-180deg)'};

  path {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

export const StyledSelectTriggerAdaptiveContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface StyledTriggerAdaptiveLabelProps {
  $size?: SelectSize
  isOpen?: boolean
  isPreviousOpened?: boolean
}

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 1rem;
    height: 1rem;
  }
  path {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

const commonFontSize = css<StyledTriggerAdaptiveLabelProps>`
  font-size: ${({ $size = DEFAULT_SELECT_SIZE }) => FONT_SIZE[$size]};
`

export const StyledSelectPlaceholder = styled.div<StyledTriggerAdaptiveLabelProps>`
  ${commonFontSize}
  color: ${({ theme }) => theme.colors.GREY_250};
  transition: ${ADAPTIVE_TRANSITION_PROPERTIES};
`

export const StyledSelectTriggerFloatingLabel = styled.span<StyledTriggerAdaptiveLabelProps>`
  ${commonFontSize}
  color: ${({ theme }) => theme.colors.GREY_550};
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: top left;
  transition: ${ADAPTIVE_TRANSITION_PROPERTIES};
  position: absolute;
  border-radius: 3px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    width: 0;
    height: 100%;
    background: white;
    z-index: -1;
    transition: width 150ms ease-in-out 0ms;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`

export const StyledTriggerFloatingLabelActive = css<StyledTriggerAdaptiveLabelProps>`
  ${StyledSelectTriggerFloatingLabel} {
    color: ${({ theme }) => theme.colors.GREY_550};
    transform: ${({ $size = DEFAULT_SELECT_SIZE }) =>
      `translate(0, ${TRANSLATE_POSITION_Y[$size]})`};
    font-size: 0.75rem;
    padding: 0.0625rem 0.125rem;
    line-height: 0.9375rem;

    &:before,
    &:after {
      ${({ isOpen, isPreviousOpened }) => {
        if (isPreviousOpened && !isOpen) {
          // If previously opened, animation should not run
          return 'transition: none;'
        }
        // 100ms delay animation with 150ms animation,
        // which gives adaptive label animation to finish 50ms after the curtain animation
        return `transition: width 150ms linear 100ms;`
      }}

      width: 50%;
    }
  }
  ${StyledSelectPlaceholder} {
    color: ${({ theme }) => theme.colors.GREY_250};
    opacity: 1;
  }
`

export const StyledSelectLabel = styled.div<{
  $areTwoLines: boolean
  $size?: SelectSize
}>`
  text-align: left;
  transition: color ${CONTENT_LABEL_TRANSITION_PROPERTIES};
  color: ${({ theme }) => theme.colors.BLACK};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${({ $areTwoLines, $size = DEFAULT_SELECT_SIZE }) =>
    !$areTwoLines ? '1.75rem' : SIZE[$size].lineHeight};

  ${commonFontSize}
`

export const StyledSelectSubLabelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.GREY_550};
  text-align: left;
  transition: color ${CONTENT_LABEL_TRANSITION_PROPERTIES};
  ${BodySmallTypography};
`

interface StyledSelectTriggerAdaptiveBasicProps {
  isOpen: boolean
  isPreviousOpened?: boolean
  $size?: SelectSize
  hasError?: boolean
  hasValue?: boolean
  hasLabel?: boolean
}

export const StyledSelectAdaptiveTrigger = styled.button.attrs({
  type: 'button',
})<StyledSelectTriggerAdaptiveBasicProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0;
  border: none;

  width: 100%;
  background: ${({ theme }) => theme.colors.GREY_30};
  border-radius: 3px;
  cursor: pointer;
  transition: border-color 0.3s ease-out, background-color 0.3s ease;
  text-align: left;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  outline: none;
  outline-offset: -1px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  ${({ $size = DEFAULT_SELECT_SIZE }) => ({ ...SIZE[$size] })}

  ${StyledLeftAdornmentWrapper} {
    margin-right: 1rem;
  }

  ${StyledSelectPlaceholder} {
    opacity: ${({ hasLabel }) => (hasLabel ? 0 : 1)};
  }

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.colors.GREY_60};
  }

  &:focus {
    outline: ${({ hasError, theme }) => {
      if (hasError) {
        return `1px solid ${theme.colors.RED_1000}`
      }
      return `1px solid ${theme.colors.BLUE_1000}`
    }};
  }

  ${({ isOpen, hasValue }) =>
    (isOpen || hasValue) && StyledTriggerFloatingLabelActive}

  &:disabled {
    cursor: not-allowed;
    ${StyledSelectTriggerFloatingLabel}, ${StyledSelectAdaptiveIcon} path, ${StyledSelectLabel}, ${StyledSelectSubLabelsWrapper} {
      color: ${({ theme }) => theme.colors.GREY_250};
    }
    ${StyledAvatarBasic} {
      opacity: 0.4;
    }
  }

  outline: ${({ hasError, theme, isOpen }) => {
    if (hasError) {
      return `1px solid ${theme.colors.RED_1000}`
    }
    if (isOpen) {
      return `1px solid ${theme.colors.BLUE_1000}`
    }
    return 'none'
  }};

  ${({ hasError, theme }) =>
    hasError && `border-color: ${theme.colors.RED_1000}`};
`

export const StyledSelectTriggerMainWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  min-width: 0;
`

export const StyledSelectSubLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledDividerSubLabel = styled.div`
  padding: 0 0.5rem;
  position: relative;

  &:after {
    content: '';
    width: 2px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.GREY_550};
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    left: 50%;
  }
`
