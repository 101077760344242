import { currencyFormatter } from '@npco/component-mp-common'
import * as Yup from 'yup'

import { stringToNumberSchema } from 'utils/schemas'
import { translate } from 'utils/translations'

export const getRefundPaymentSchema = (refundBalance: number) => {
  return Yup.object().shape({
    amount: stringToNumberSchema
      .typeError(
        translate(
          'page.refundTransactionModal.formSections.refundPaymentSchemaError.typeError'
        )
      )
      .max(
        Math.abs(refundBalance) / 100,
        translate(
          'page.refundTransactionModal.formSections.refundPaymentSchemaError.maxError',
          {
            amount: currencyFormatter(refundBalance),
          }
        )
      )
      .min(
        0.01,
        translate(
          'page.refundTransactionModal.formSections.refundPaymentSchemaError.minError'
        )
      ),
  })
}
