import { useMemo } from 'react'

import { useAccountId } from 'hooks/banking'
import { mapAccountTransactionFiltersToApiFilters } from 'utils/banking/mapAccountTransactionsFiltersToApiFilters/mapAccountTransactionsFiltersToApiFilters'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

export const useAccountTransactionsFilters = () => {
  const { areFiltersInDefaultState, ...accountFilters } = useAccountFilters()

  const { accountId } = useAccountId()

  const filterObject = useMemo(
    () =>
      mapAccountTransactionFiltersToApiFilters({
        selectedAccountID: accountId,
        ...accountFilters,
      }),
    [accountFilters, accountId]
  )

  return { filters: filterObject, areFiltersInDefaultState }
}
