import { AsideLayout } from '@npco/ui-aside-layout'

import { StyledWrapper } from './OnboardingLayout.styled'
import { OnboardingSidebar } from './OnboardingLayout/OnboardingSidebar'

interface OnboardingLayoutProps {
  children?: React.ReactNode
  hasAside?: boolean
  hasSaveButton?: boolean
}

export const OnboardingLayout = ({
  children,
  hasAside,
  hasSaveButton,
  ...props
}: OnboardingLayoutProps) => {
  return (
    <AsideLayout
      isSideBarOpen={false}
      isRightSide
      hasAside={hasAside}
      aside={<OnboardingSidebar hasSaveButton={hasSaveButton} />}
    >
      <StyledWrapper {...props}>{children}</StyledWrapper>
    </AsideLayout>
  )
}

OnboardingLayout.Content = StyledWrapper
