import { ButtonBasic } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledNavButton = styled(ButtonBasic)`
  background-color: transparent;
  align-items: flex-end;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;

  svg path {
    fill: ${({ theme }) => theme.colors.BLUE_1000};
  }

  &:disabled {
    svg path {
      fill: ${({ theme }) => theme.colors.GREY_150};
    }
  }
`
