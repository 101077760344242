import { useCallback } from 'react'

import { useReadUpdatedOutstandingTransactionsLocalCache } from './useReadUpdatedOutstandingTransactionsLocalCache'
import { useWriteUpdatedOutstandingTransactionsLocalCache } from './useWriteUpdatedOutstandingTransactionsLocalCache'

export const useRemoveUpdatedOutstandingTransaction = () => {
  const { updatedOutstandingTransactions: localTransactions } =
    useReadUpdatedOutstandingTransactionsLocalCache()
  const { updateCache } = useWriteUpdatedOutstandingTransactionsLocalCache()

  const removeUpdatedOutstandingTransaction = useCallback(
    (transactionId: string) => {
      const transactionInLocalCache = localTransactions.find(
        ({ transaction }) => transaction.id === transactionId
      )

      if (!transactionInLocalCache) {
        return
      }

      updateCache(
        localTransactions.filter(
          ({ transaction }) => transaction.id !== transactionId
        )
      )
    },
    [localTransactions, updateCache]
  )

  return {
    removeUpdatedOutstandingTransaction,
  }
}
