import { useCallback } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { ApolloError } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  BodySmall,
  Box,
  COLOR,
  DownloadIcon,
  Flex,
  Heading,
  Spinner,
} from '@npco/zeller-design-system'
import { InvoicePreview } from 'features/Invoicing/components/Invoices/Invoice/components/InvoicePreview/InvoicePreview'
import { InvoicePreviewButton } from 'features/Invoicing/components/Invoices/Invoice/components/InvoicePreview/InvoicePreview.styled'

import { ReactComponent as ExpandIcon } from 'assets/svg/expand.svg'
import { FileWithId } from 'components/File'

import { InvoicePdfPagination } from '../../../../Invoice/components/InvoiceFormAccordions/components/InvoicePdfPagination/InvoicePdfPagination'
import { useInvoiceDrawerDetailsPdf } from './hooks/useInvoiceDrawerDetailsPdf'
import { translations } from './InvoiceDrawerDetailsPdf.i18n'
import * as styled from './InvoiceDrawerDetailsPdf.styled'

interface InvoiceDrawerDetailsPdfProps {
  invoiceId?: string
  invoicePdf: FileWithId | null
  invoicePdfError?: ApolloError
  isLoadingPreview: boolean
  referenceNumber: string
  disableDownload?: boolean
}

const InvoiceDownloadIcon = () => <DownloadIcon />

export const InvoiceDrawerDetailsPdf = ({
  invoiceId,
  invoicePdf,
  invoicePdfError,
  isLoadingPreview,
  referenceNumber,
  disableDownload,
}: InvoiceDrawerDetailsPdfProps) => {
  const {
    handleDownloadPdf,
    handleOnPdfLoadSuccess,
    handleOnPdfRenderPageSuccess,
    pageNumber,
    pdfHeight,
    setPageNumber,
    setPreviewValue,
    showPreview,
    totalPages,
  } = useInvoiceDrawerDetailsPdf(referenceNumber, invoiceId, invoicePdf?.id)

  const t = useTranslations(translations)

  const { width: pdfWidth, ref } = useResizeDetector({
    handleWidth: true,
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 0,
  })

  const renderLoader = useCallback(
    () => (
      <styled.InvoicePreviewSpinnerWrapper
        data-testid="invoice-pdf-loading-spinner"
        height={pdfHeight}
        width={pdfWidth}
      >
        <Spinner />
      </styled.InvoicePreviewSpinnerWrapper>
    ),
    [pdfHeight, pdfWidth]
  )

  if (!invoicePdf && isLoadingPreview) {
    return (
      <styled.InvoicePreviewSpinnerWrapper
        data-testid="invoice-get-preview-spinner"
        height={pdfHeight}
        width={pdfWidth}
      >
        <Spinner />
      </styled.InvoicePreviewSpinnerWrapper>
    )
  }

  if (invoicePdfError || !invoicePdf) {
    return (
      <styled.InvoicePreviewFallback height={pdfHeight} width={pdfWidth}>
        <Heading.H3>{t('errorTitle')}</Heading.H3>
        <BodySmall>{t('errorMessage')}</BodySmall>
      </styled.InvoicePreviewFallback>
    )
  }

  const pdfHasBeenLoaded = Boolean(totalPages)

  return (
    <Flex flexDirection="column" gridGap="16px" flexGrow={1}>
      {totalPages > 1 && (
        <InvoicePdfPagination
          onNextPageClick={setPageNumber}
          onPreviousPageClick={setPageNumber}
          pageNumber={pageNumber}
          totalPages={totalPages}
        />
      )}
      <Box position="relative" ref={ref}>
        {pdfHasBeenLoaded && (
          <styled.InvoicePdfActionsWrapper
            data-testid="invoice-pdf-actions"
            height={pdfHeight}
            width={pdfWidth}
          >
            {!disableDownload && (
              <InvoicePreviewButton
                data-testid="invoice-download-icon"
                icon={InvoiceDownloadIcon}
                iconEnabledColor={COLOR.WHITE}
                onClick={handleDownloadPdf}
              >
                {t('download')}
              </InvoicePreviewButton>
            )}
            <InvoicePreviewButton
              data-testid="invoice-preview-icon"
              icon={ExpandIcon}
              iconEnabledColor={COLOR.WHITE}
              $full={disableDownload}
              onClick={setPreviewValue(true)}
            >
              {t('preview')}
            </InvoicePreviewButton>
          </styled.InvoicePdfActionsWrapper>
        )}

        {isLoadingPreview && (
          <styled.LoaderWrapper data-testid="invoice-get-preview-spinner">
            <Spinner />
          </styled.LoaderWrapper>
        )}
        <styled.InvoicePdf
          $isLoading={isLoadingPreview}
          data-testid="invoice-pdf"
          image={invoicePdf}
          height={pdfHeight}
          onPdfLoadSuccess={handleOnPdfLoadSuccess}
          onPdfRenderPageSuccess={handleOnPdfRenderPageSuccess}
          pageNumber={pageNumber}
          width={pdfWidth || 0}
          renderLoader={renderLoader}
          renderPdfAnnotation={false}
        />
      </Box>
      {showPreview && (
        <InvoicePreview
          invoiceId={invoiceId}
          onClose={setPreviewValue(false)}
          pdfFile={invoicePdf}
          referenceNumber={referenceNumber}
          disableDownload={disableDownload}
        />
      )}
    </Flex>
  )
}
