import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledHorizontalCardContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-bottom: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px 0px rgba(34, 34, 38, 0.08),
    0px 1px 2px 0px rgba(34, 34, 38, 0.15),
    0px -0.5px 2px 0px rgba(34, 34, 38, 0.15);
  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    box-shadow: 0px 7px 13px 0px rgba(33, 35, 34, 0.15),
      0px 1px 2px 0px rgba(33, 35, 34, 0.05),
      0px -0.5px 2px 0px rgba(34, 34, 38, 0.15);
  }

  &.isDraggable {
    padding-left: 0.75rem;

    @media (min-width: ${BREAKPOINT.XS}px) {
      padding-left: 1.1875rem;
    }
  }

  @media (min-width: ${BREAKPOINT.XS}px) {
    height: 6.5rem;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    border-radius: 1rem;
  }
`

export const StyledContent = styled.div`
  @media (min-width: ${BREAKPOINT.XS}px) {
    align-items: center;
    display: flex;
    flex-grow: 1;
  }
`

export const StyledHorizontalCardImageWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 1rem;
`

export const StyledHorizontalCardImage = styled.div`
  text-align: center;
`

export const StyledIndicatorItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  &::before {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.colors.GREY_60};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    box-shadow: 0 -0.75rem 0 ${({ theme }) => theme.colors.GREY_60},
      0 0.75rem 0 ${({ theme }) => theme.colors.GREY_60};
  }
`
