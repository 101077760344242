import 'mockData/mockServer/browserMockServer'

import React from 'react'
import ReactDOM from 'react-dom'

import { RenderRoot } from './RenderRoot'
import { Root } from './Root'

ReactDOM.render(
  <RenderRoot>
    <Root />
  </RenderRoot>,
  document.getElementById('root')
)
