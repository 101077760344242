import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { DashboardFiltersButton } from '../../../../../layouts/DashboardLayout'
import { useTransactionsContext } from '../../TransactionsContext/TransactionsContext'
import { translations } from './TransactionsPrimaryHeaderRow.i18n'

const deps = {
  useTransactionsContext,
  useTranslations,
}
export { deps as TransactionsPrimaryHeaderRowDeps }

export const TransactionsPrimaryHeaderRow = () => {
  const shortEntityId = useSelectedShortEntityUuid()
  const {
    handleToggleFilters,
    isFiltersDisabled,
    isFiltersVisible,
    isFiltersSelected,
    splitPaymentId,
  } = deps.useTransactionsContext()
  const t = deps.useTranslations(translations)

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex flex={1} justifyContent="space-between">
        <Breadcrumb>
          <Breadcrumb.Item>
            {splitPaymentId ? (
              <Breadcrumb.Link
                to={ROOT.ORGS.ORG(shortEntityId).TRANSACTIONS.path}
              >
                {t('transactions')}
              </Breadcrumb.Link>
            ) : (
              <Breadcrumb.Text>{t('transactions')}</Breadcrumb.Text>
            )}
          </Breadcrumb.Item>
          {splitPaymentId && (
            <Breadcrumb.Item isCurrent>
              <Breadcrumb.Text> {t('relatedTransactions')}</Breadcrumb.Text>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
        {!splitPaymentId && (
          <DashboardFiltersButton
            disabled={isFiltersDisabled}
            isActive={isFiltersVisible}
            onClick={handleToggleFilters}
            showIndicator={isFiltersSelected}
          />
        )}
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
