import { useCallback, useState } from 'react'
import { gql } from '@apollo/client'

import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { AnimatedButton } from './AnimatedButton'
import {
  DownloadSummaryDebitCardAccountTransactionFragment,
  DownloadSummaryDebitCardTransactionV2Fragment,
} from './DownloadSummary.generated'
import { StyledTitle } from './DownloadSummary.styled'
import { useDownloadSummary } from './hooks/useDownloadSummary'

interface Props {
  transaction:
    | DownloadSummaryDebitCardTransactionV2Fragment
    | DownloadSummaryDebitCardAccountTransactionFragment
}

export const DownloadSummary = ({ transaction }: Props) => {
  const [isDownloaded, setIsDownloaded] = useState(false)
  const { locationName } = useAnalyticsContext()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const handleDownloadSuccess = useCallback(() => {
    setIsDownloaded(true)
    trackAnalyticsEvent(AnalyticsEventNames.TRANSACTION_DOWNLOADED_SUMMARY, {
      Location: locationName.current,
    })
  }, [trackAnalyticsEvent, locationName])

  const { downloadSummary, isLoadingSummary } = useDownloadSummary({
    debitCardTransactionUuid: transaction.id,
    onSuccess: handleDownloadSuccess,
  })

  return (
    <>
      <StyledTitle>
        {translate('page.transactionDetails.downloadStatement.title')}
      </StyledTitle>
      <AnimatedButton
        isLoading={isLoadingSummary}
        isCompleted={isDownloaded}
        onClick={downloadSummary}
      >
        {translate('page.transactionDetails.downloadStatement.buttonLabel')}
      </AnimatedButton>
    </>
  )
}

DownloadSummary.fragments = {
  DebitCardTransactionV2: gql`
    fragment DownloadSummaryDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment DownloadSummaryDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
    }
  `,
}
