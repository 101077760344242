import { useMemo } from 'react'
import TextTruncate from 'react-text-truncate'
import { useTranslations } from '@npco/utils-translations'
import { AnchorBasic } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translations } from '../../../Contacts.i18n'
import { getAddress } from '../../../Contacts.utils'
import * as styled from '../../ContactGeneral/ContactGeneral.styled'
import { CategoryPills } from '../Categories/CategoryPills'
import { mergeEmails, mergePhones } from './ContactDetails.util'
import { DetailField } from './DetailField'
import { DetailFields } from './DetailFields'

interface BusinessContactDetailsProps {
  contact: ContactCoreFieldsFragment
  canEditCategories?: boolean
  useCategoryPills?: boolean
}

export const BUSINESS_CONTACT_DATA_TESTID = 'contact-business-details-section'

const emptyState = '-'

const getWebsiteValue = (website: string | null) => {
  if (!website) {
    return emptyState
  }

  return (
    <AnchorBasic href={website}>
      <TextTruncate line={2} text={website} element="span" />
    </AnchorBasic>
  )
}

export const BusinessContactDetails = ({
  contact,
  canEditCategories = true,
  useCategoryPills = true,
}: BusinessContactDetailsProps) => {
  const t = useTranslations(translations)

  const defaultEmailLabel = t('email')
  const defaultPhoneLabel = t('phone')

  const {
    abn,
    acn,
    additionalEmails,
    additionalPhones,
    category,
    email,
    id,
    phoneV2,
    subcategoryUuid,
    website,
  } = contact

  const allEmails = useMemo(
    () =>
      mergeEmails(email, additionalEmails, {
        emptyState,
        defaultLabel: defaultEmailLabel,
      }),
    [email, additionalEmails, defaultEmailLabel]
  )

  const allPhoneNumbers = useMemo(() => {
    return mergePhones(phoneV2, additionalPhones, {
      emptyState,
      defaultLabel: defaultPhoneLabel,
    })
  }, [phoneV2, additionalPhones, defaultPhoneLabel])

  const detailsPresent = Boolean(
    email?.email ||
      additionalEmails?.length ||
      phoneV2?.phone ||
      additionalPhones?.length ||
      website ||
      abn ||
      acn
  )

  return (
    <styled.ContactDetailSection data-testid={BUSINESS_CONTACT_DATA_TESTID}>
      <DetailField
        label={t('address')}
        value={getAddress(contact) || emptyState}
        dataTestId="contact-address-field"
      />
      {detailsPresent ? (
        <>
          <DetailFields list={allPhoneNumbers} type="phone" />
          <DetailFields list={allEmails} type="email" />
          <DetailField
            label={t('website')}
            value={getWebsiteValue(website)}
            dataTestId="contact-website-field"
          />
          <DetailField
            label={t('abn.abbreviation')}
            abbreviationTitle={t('abn.full')}
            value={abn || emptyState}
            dataTestId="contact-abn-field"
          />
          <DetailField
            label={t('acn.abbreviation')}
            abbreviationTitle={t('acn.full')}
            value={acn || emptyState}
            dataTestId="contact-acn-field"
          />
        </>
      ) : (
        <DetailField
          label={t('combinedLabel')}
          value={emptyState}
          dataTestId="contact-combined-field"
        />
      )}
      {useCategoryPills ? (
        <CategoryPills
          category={category || null}
          subcategoryId={subcategoryUuid || null}
          contactUuid={id}
          canEditCategories={canEditCategories}
        />
      ) : (
        <DetailField
          label={t('category')}
          value={emptyState}
          dataTestId="contact-category-field"
        />
      )}
    </styled.ContactDetailSection>
  )
}
