import { Box, ButtonLink, Flex, Heading } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { CompanyLogoLayoutProps } from 'components/InvoiceCompanyLogoUpload/InvoiceCompanyLogoUpload'

import {
  ImageSpecification,
  SettingDescription,
} from './CompanyLogoLayout.styled'

export const CompanyLogoLayout = ({
  children,
  onRemove,
}: CompanyLogoLayoutProps) => {
  return (
    <Box>
      <Flex justifyContent="space-between" mb="24px">
        <Heading.H4>
          {translate(
            'page.settings.connections.xeroPaymentServices.receipt.logoHeading'
          )}
        </Heading.H4>
        {onRemove && (
          <ButtonLink onClick={onRemove}>
            {translate('shared.remove')}
          </ButtonLink>
        )}
      </Flex>
      <SettingDescription>
        {translate(
          'page.settings.connections.xeroPaymentServices.receipt.logoDescription'
        )}
      </SettingDescription>
      {children}
      <ImageSpecification>
        {translate(
          'page.settings.connections.xeroPaymentServices.receipt.logoSpecification'
        )}
      </ImageSpecification>
    </Box>
  )
}
