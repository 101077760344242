import { ReactNode } from 'react'
import { Box, Heading, HeadingStyles } from '@npco/zeller-design-system'

import { formatDate, formatToday } from 'utils/date'

interface Props {
  timestamp: string
  children: ReactNode[]
}

export const DashboardTransactionsListGroup = ({
  timestamp,
  children,
}: Props) => {
  return (
    <Box mb="1.5rem">
      <Box mt="0" mb="0.5rem">
        <Heading.H4 withStyles={HeadingStyles.H6}>
          {formatToday(timestamp, formatDate)}
        </Heading.H4>
      </Box>
      {children}
    </Box>
  )
}
