import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled(Flex)`
  flex-direction: column;
  padding: 32px 24px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  width: 100%;
  box-shadow: 0px 4px 16px 0px rgba(105, 105, 105, 0.12),
    0px 0px 1px 0px rgba(0, 113, 206, 0.25);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    align-items: center;
    max-width: 1024px;
  }
`

export const ListContainer = styled(Flex)`
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    gap: 24px;
    justify-content: center;
  }
`
