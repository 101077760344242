import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, ModalBasic } from '@npco/zeller-design-system'
import { rvHasSeenPushAppModal } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'

import AppImage from 'assets/images/push-app-downloads.png'

import { Item } from './components/Item'
import { translations } from './PushAppModal.i18n'
import * as styled from './PushAppModal.styled'
import { getAppLink } from './PushAppModal.utils'

export const PushAppModal = () => {
  const t = useTranslations(translations)
  const { isModalOpen, closeModal } = useModalState({
    isOpenByDefault: true,
    modalName: t('modalName'),
  })
  const handlePrimaryClick = () => {
    window.open(getAppLink(), '_blank', 'noopener')
    rvHasSeenPushAppModal(true)
  }

  return (
    <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
      <ModalBasic.Body>
        <styled.Content>
          <styled.Title>{t('title')}</styled.Title>
          <Item description={t('description1')} />
          <Item description={t('description2')} />
          <Item description={t('description3')} />
          <Item description={t('description4')} />
          <Item description={t('description5')} />
          <Flex width="100%" mt="20px" justifyContent="center">
            <Box height="312px">
              <styled.Image src={AppImage} alt="Zeller App" />
            </Box>
          </Flex>
        </styled.Content>
      </ModalBasic.Body>

      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton
          onClick={() => {
            closeModal()
            rvHasSeenPushAppModal(true)
          }}
        >
          {t('continue')}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton onClick={handlePrimaryClick}>
          {t('takeMeToZellerApp')}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </ModalBasic>
  )
}
