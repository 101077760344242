import { LogoContainer } from 'components/CompanyLogoUpload/CompanyLogoUpload.styled'

import { ImagePreview } from '../ImagePreview/ImagePreview'

interface ModalUploadPreviewImageProps {
  previewUrl: string
  isPreviewLoading: boolean
  isPreviewError: boolean
}

export const ModalUploadPreviewImage = ({
  previewUrl,
  isPreviewLoading,
  isPreviewError,
}: ModalUploadPreviewImageProps) => {
  return (
    <LogoContainer
      data-testid="image-preview-container"
      justifyContent="center"
      alignItems="center"
      mb="1rem"
      hasLogo={!isPreviewLoading}
    >
      <ImagePreview
        src={previewUrl}
        isLoading={isPreviewLoading}
        isBroken={isPreviewError}
      />
    </LogoContainer>
  )
}
