import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button as BaseButton } from 'components/Buttons/Button'

export const StyledStatementsButton = styled(BaseButton)`
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.5rem;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 0;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
    display: block;
    background-color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

export const StyledText = styled.span`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`
