import { useTranslations } from '@npco/utils-translations'
import { useFormikContext } from 'formik'

import { FormLayout } from 'layouts/FormLayout/FormLayout'
import { translationsShared } from 'translations'

import { TimeForASelfieValues } from '../TimeForASelfie.types'
import { TimeForASelfieDescription } from './TimeForASelfieDescription'
import { timeForASelfieFormWrapperTranslations } from './TimeForASelfieFormWrapper.i18n'

export interface TimeForASelfieFormWrapperProps {
  children: React.ReactNode
  onCancel?: () => void
  descriptionCta?: string
}

export const TimeForASelfieFormWrapper = ({
  children,
  onCancel,
  descriptionCta,
}: TimeForASelfieFormWrapperProps) => {
  const t = useTranslations(timeForASelfieFormWrapperTranslations)
  const tShared = useTranslations(translationsShared)
  const { submitForm, values } = useFormikContext<TimeForASelfieValues>()
  const isBackButtonHidden = !onCancel
  const onBackClick = onCancel
  const backButtonLabel = tShared('cancel')

  return (
    <FormLayout
      title={t('title')}
      description={<TimeForASelfieDescription cta={descriptionCta} />}
      onConfirmClick={submitForm}
      confirmDisabled={!values.idType}
      isBackButtonHidden={isBackButtonHidden}
      onBackClick={onBackClick}
      backButtonLabel={backButtonLabel}
    >
      {children}
    </FormLayout>
  )
}
