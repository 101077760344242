import { ModalForm } from 'design-system/Components/Modal/ModalForm/ModalForm'
import { Formik, FormikValues } from 'formik'

import { translate } from 'utils/translations'
import { StyledForm } from 'components/EditCategoriesModal/EditCategoriesModal.styled'

interface Props<V> {
  title: string
  onSave: (values: V) => void
  onCancel: () => void
  initialValues: V
  isOpen: boolean
  children: React.ReactNode
  isSavingCategories?: boolean
  isSaveDisabled?: boolean
}

export const EditCategoriesModal = <V extends FormikValues>({
  title,
  onSave,
  onCancel,
  initialValues,
  isOpen,
  isSavingCategories = false,
  isSaveDisabled = false,
  children,
}: Props<V>) => {
  return (
    <Formik<V> initialValues={initialValues} onSubmit={onSave}>
      {({ submitForm }) => (
        <ModalForm
          title={title}
          onSave={submitForm}
          confirmLabel={translate('shared.save')}
          cancelLabel={translate('shared.cancel')}
          isOpen={isOpen}
          onCancel={onCancel}
          isLoading={isSavingCategories}
          isConfirmButtonDisabled={isSaveDisabled}
        >
          <StyledForm>{children}</StyledForm>
        </ModalForm>
      )}
    </Formik>
  )
}
