import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  info: 'For a full list of user access level permissions, visit the {link}.',
  infoLinkText: 'Zeller Support Centre',
  adminTitle: 'Admin',
  adminDescription:
    'Admin users have access to all elements of your Zeller Account and can complete actions to move funds.',
  managerTitle: 'Manager',
  managerDescription:
    "Manager users can’t view sensitive data or complete actions to move funds. This is ideal for venue or team managers who don't need access to all site information.",
})
