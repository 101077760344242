import {
  BodyDefaultTypography,
  BodySmallTypography,
  BREAKPOINT,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CardBalance = styled.span`
  ${BodySmallTypography};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyDefaultTypography};
  }
`
