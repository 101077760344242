import { useMemo, useState } from 'react'
import { TabItemProps } from '@npco/zeller-design-system'

import { page } from 'translations'

import { PerformancePeriod } from '../../DashboardPerformance.types'

interface UsePerformanceOptions {
  initialTab: PerformancePeriod
  onChange: (performancePeriod: PerformancePeriod) => void
}

export const usePerformanceTabs = ({
  initialTab,
  onChange,
}: UsePerformanceOptions) => {
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const tabs: TabItemProps[] = useMemo(() => {
    return [
      {
        id: 'dashboard-site-performance-tabs-today',
        children: page.dashboard.payments.sitePerformance.todayTabLabel,
        onClick: () => {
          setSelectedTab(PerformancePeriod.TODAY)
          onChange(PerformancePeriod.TODAY)
        },
        value: PerformancePeriod.TODAY,
      },
      {
        id: 'dashboard-site-performance-tabs-month',
        children: page.dashboard.payments.sitePerformance.thisMonthTabLabel,
        onClick: () => {
          setSelectedTab(PerformancePeriod.THIS_MONTH)
          onChange(PerformancePeriod.THIS_MONTH)
        },
        value: PerformancePeriod.THIS_MONTH,
      },
    ]
  }, [onChange])

  return { tabs, currentTab: selectedTab }
}
