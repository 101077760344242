import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'
import {
  SelectCompactField,
  SelectCompactItemWithIcon,
} from 'design-system/Components/Select'
import { SelectTriggerAdaptive } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerAdaptive'
import { Form, useFormikContext } from 'formik'

import { ReactComponent as CarIcon } from 'assets/svg/car.svg'
import { ReactComponent as HouseIcon } from 'assets/svg/house.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { useGetSites } from 'hooks/useGetSites'
import { SITE_TYPE } from 'const/settings'
import { getUnitWithStreetNumber } from 'utils/address'
import { buildStringWithSeparator } from 'utils/common'
import {
  combineValidators,
  validateAddressString,
  validateCompanyName,
  validateMaxLength,
  validatePin,
  validatePostcode,
  validateRequiredCustomMsg,
  validateSuburb,
  validateUnique,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { Site as SiteType } from 'types/site'
import { Address } from 'forms/formSections/Address'
import { FormLine } from 'components/FormLine'
import { AddressAutocompleteInput } from 'components/Input/AddressAutocompleteInput'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { Tooltip } from 'components/Tooltip'
import { errorMessages, form } from 'translations'

import { validateAddressLineWithPoBox } from '../OfficeAddress/OfficeAddress.validators'
import { translations } from './Site.i18n'
import { Description } from './Site.styled'

interface Option {
  value: string
  label: string
  icon: React.ReactElement
}

const siteTypeOptions = [
  {
    value: SITE_TYPE.MOBILE,
    label: form.site.siteType.mobileSite,
    icon: <CarIcon />,
  },
  {
    value: SITE_TYPE.FIXED,
    label: form.site.siteType.fixedLocationSite,
    icon: <HouseIcon />,
  },
]
const MAX_SITE_NAME_LENGTH = 256

export const Site = () => {
  const { sites } = useGetSites()
  const { values, initialValues, setValues } = useFormikContext<SiteType>()

  const sitesNames = useMemo(
    () => sites.map((site) => site.name.toLowerCase()),
    [sites]
  )
  const t = useTranslations(translations)
  const validateUniqueness = (inputValue: string, initialInputValue: string) =>
    combineValidators(
      validateRequiredCustomMsg(),
      validateUnique(
        inputValue.toLowerCase(),
        initialInputValue.toLowerCase(),
        sitesNames,
        errorMessages.siteNameExists
      ),
      validateMaxLength(MAX_SITE_NAME_LENGTH, t('exceedMaxLength'))
    )

  return (
    <Form>
      <FormLine>
        <Box mb="24px" mt="6px">
          <SelectCompactField<Option>
            name="siteType"
            mobileLabel={form.site.siteType.placeholder}
            items={siteTypeOptions}
            validate={validateRequiredCustomMsg()}
            renderTrigger={(renderProps) => (
              <SelectTriggerAdaptive
                {...renderProps}
                label={form.site.siteType.placeholder}
                placeholder={form.site.siteType.select}
              />
            )}
            renderItem={(renderProps) => (
              <SelectCompactItemWithIcon {...renderProps} />
            )}
          />
        </Box>
      </FormLine>
      <FormLine fractions={[2, 1]}>
        <Box mb="24px">
          <InputAdaptiveFieldWrapper
            label={form.site.name.label}
            name="name"
            validate={validateUniqueness(values.name, initialValues.name)}
            type="text"
          />
        </Box>
        <Box mb="24px">
          <InputAdaptiveFieldWrapper
            label={form.site.pin.label}
            name="pin"
            validate={validatePin}
            renderRightControls={() => (
              <Tooltip
                tooltipIcon={
                  <SvgIcon width="16" height="16" color={COLOR.GREEN_1000}>
                    <InfoIcon />
                  </SvgIcon>
                }
              >
                {form.site.pin.tooltip}
              </Tooltip>
            )}
          />
        </Box>
      </FormLine>
      {values.siteType !== SITE_TYPE.MOBILE && (
        <>
          <FormLine>
            <Box mb="24px">
              <AddressAutocompleteInput
                name="street"
                placeholder={translate('shared.typeForSuggestions')}
                country="au"
                label={translate('form.shared.address')}
                setFullAddressValues={(googleAddressData) => {
                  setValues({
                    ...values,
                    postcode: googleAddressData.postcode,
                    street: buildStringWithSeparator(
                      ' ',
                      getUnitWithStreetNumber(
                        googleAddressData.streetNumber,
                        googleAddressData.unit
                      ),
                      googleAddressData.street
                    ),
                    suburb: googleAddressData.suburb,
                    state: googleAddressData.state,
                  })
                }}
                validate={combineValidators(
                  validateAddressString,
                  validateAddressLineWithPoBox
                )}
                maxLength={40}
              />
            </Box>
          </FormLine>
          <Address
            suburbValidator={validateSuburb}
            stateValidator={validateRequiredCustomMsg()}
            postcodeValidator={validatePostcode}
            shouldValidate
            mb="24px"
          />
        </>
      )}
      <FormLine>
        <Box mb="24px">
          <Flex flexDirection="column">
            <InputAdaptiveFieldWrapper
              label={form.site.businessName.label}
              name="businessName"
              type="text"
              value={values.businessName}
              validate={validateCompanyName}
            />
            <Description>{form.site.businessName.description}</Description>
          </Flex>
        </Box>
      </FormLine>
    </Form>
  )
}
