import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  InlineLoaderVariant,
  InlineSpinner,
  InputAdaptiveFieldWithPrefix,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { useInputWithLimitedDecimalPlaces } from 'hooks/useInputWithLimitedDecimalPlaces'
import { CustomValidator } from 'types/common'
import { useTransferQuery } from 'pages/Transfer/hooks/useTransferQuery/useTransferQuery'
import { zellerTransferTranslations } from 'pages/Transfer/Transfer.i18n'
import {
  TransferFieldTypes,
  TransferZellerFields,
} from 'pages/Transfer/Transfer.types'

import { StyledTextInputPrefix } from './AmountField.styled'

const useSelectedFromAccountBalance = () => {
  const { values } = useFormikContext<TransferFieldTypes>()
  const { accountById } = useTransferQuery()
  const account = accountById(values.from ?? '')

  return account ? parseInt(account.balance?.value ?? '0', 10) / 100 : 0
}

interface AmountFieldProps {
  validate: (fromBalance: number) => CustomValidator<string>
  limitLoading?: boolean
}

export const AmountField = ({ validate, limitLoading }: AmountFieldProps) => {
  const fromBalance = useSelectedFromAccountBalance()
  const t = useTranslations(zellerTransferTranslations)

  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext<TransferZellerFields>()

  const { onChange, onBlur } = useInputWithLimitedDecimalPlaces({
    handleBlur,
    handleChange,
    value: values.amount,
    setValue: (value) => setFieldValue('amount', value),
  })

  return (
    <Box mb="24px">
      <InputAdaptiveFieldWithPrefix
        name="amount"
        renderPrefix={() => <StyledTextInputPrefix>$</StyledTextInputPrefix>}
        validate={validate(fromBalance)}
        label={t('amountLabel')}
        placeholder="0.00"
        onBlur={onBlur}
        onChange={onChange}
        isRequired
        renderRightControls={() => {
          if (limitLoading) {
            return <InlineSpinner loaderVariant={InlineLoaderVariant.Grey} />
          }
          return null
        }}
        disabled={Boolean(limitLoading)}
      />
    </Box>
  )
}
