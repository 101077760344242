import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AverageCellCashFlowCategoryNetAmountFragmentDoc } from '../../TableCells/TableRowCells/AverageCell/AverageCell.generated'
import { CategoryCellCashFlowCategoryNetAmountFragmentDoc } from '../../TableCells/TableRowCells/CategoryCell/CategoryCell.generated'
import { QuantityCellCashFlowCategoryNetAmountFragmentDoc } from '../../TableCells/TableRowCells/QuantityCell/QuantityCell.generated'
import { TotalCellCashFlowCategoryNetAmountFragmentDoc } from '../../TableCells/TableRowCells/TotalCell/TotalCell.generated'

export type CategoriesTableCashFlowCategoryNetAmountFragment = {
  __typename: 'CashFlowCategoryNetAmount'
  noOfTransaction: number
  change: number | null
  category: Types.EntityCategories | null
  subcategoryCount: number | null
  average: { __typename?: 'Money'; currency: string; value: string }
  total: { __typename?: 'Money'; currency: string; value: string }
}

export const CategoriesTableCashFlowCategoryNetAmountFragmentDoc = gql`
  fragment CategoriesTableCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
    __typename
    ...QuantityCellCashFlowCategoryNetAmountFragment
    ...AverageCellCashFlowCategoryNetAmountFragment
    ...TotalCellCashFlowCategoryNetAmountFragment
    ...CategoryCellCashFlowCategoryNetAmountFragment
  }
  ${QuantityCellCashFlowCategoryNetAmountFragmentDoc}
  ${AverageCellCashFlowCategoryNetAmountFragmentDoc}
  ${TotalCellCashFlowCategoryNetAmountFragmentDoc}
  ${CategoryCellCashFlowCategoryNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  CategoriesTableCashFlowCategoryNetAmountFragment,
  undefined
>
