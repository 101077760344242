import { page } from 'translations'

import {
  StyledDocsInfoWrapper,
  StyledInfo,
  StyledSpan,
} from './DocsInfo.styled'

const { selected, methods, xOfTwo } = page.kyc.verifyYourIdentity.documents

interface DocsInfoProps {
  amount: number
  isMobile?: boolean
}

export const DocsInfo = ({
  amount,
  isMobile = false,
}: DocsInfoProps): JSX.Element => (
  <StyledDocsInfoWrapper
    isMobile={isMobile}
    pr={2}
    flex="1"
    justifyContent={isMobile ? 'flex-start' : 'flex-end'}
    alignItems="center"
  >
    <StyledInfo>
      <span>
        {selected}
        <StyledSpan>
          {amount} {xOfTwo}
        </StyledSpan>
        {methods}
      </span>
    </StyledInfo>
  </StyledDocsInfoWrapper>
)
