import { useTranslations } from '@npco/utils-translations'

import { ChartData } from 'components/Charts/Charts.types'

import { translations } from '../Reports.i18n'
import { ReportsSectionHeading } from '../ReportsSectionHeading/ReportsSectionHeading'
import { DayOfWeekChart } from './DayOfWeekChart/DayOfWeekChart'
import { StyledChartsWrapper, StyledChartWrapper } from './ReportsChart.styled'
import { TimeOfDayChart } from './TimeOfDayChart/TimeOfDayChart'
import { TotalChart } from './TotalChart/TotalChart'

interface Props {
  totalSaleChartData: ChartData
  timeOfDayChartData: ChartData
  dayOfWeekChartData: ChartData
  hasNoInitialResults: boolean
  isSelectionMoreThenTwoMonths: boolean
}

export const ReportsChart = ({
  totalSaleChartData,
  timeOfDayChartData,
  dayOfWeekChartData,
  hasNoInitialResults,
  isSelectionMoreThenTwoMonths,
}: Props) => {
  const t = useTranslations(translations)

  return (
    <StyledChartsWrapper>
      <StyledChartWrapper area="total">
        <ReportsSectionHeading>{t('totalSaleHeader')}</ReportsSectionHeading>
        <TotalChart
          data={totalSaleChartData}
          hasNoInitialResults={hasNoInitialResults}
          isSelectionMoreThenTwoMonths={isSelectionMoreThenTwoMonths}
        />
      </StyledChartWrapper>

      <StyledChartWrapper area="day-of-week">
        <ReportsSectionHeading tooltip={t('dayOfWeekTooltip')}>
          {t('dayOfWeekHeader')}
        </ReportsSectionHeading>
        <DayOfWeekChart
          data={dayOfWeekChartData}
          hasNoInitialResults={hasNoInitialResults}
        />
      </StyledChartWrapper>

      <StyledChartWrapper area="time-of-day">
        <ReportsSectionHeading tooltip={t('timeOfDayTooltip')}>
          {t('timeOfDayHeader')}
        </ReportsSectionHeading>
        <TimeOfDayChart
          data={timeOfDayChartData}
          hasNoInitialResults={hasNoInitialResults}
        />
      </StyledChartWrapper>
    </StyledChartsWrapper>
  )
}
