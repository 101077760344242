import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useAddThirdPartyAccount } from 'components/AddThirdPartyAccount/AddThirdPartyAccount.hook'

import { useCreateExternalAccountContext } from '../../../../contexts/useCreateExternalAccountContext'
import {
  CreateExternalAccountStage,
  ExternalAccountReviewState,
} from '../../../../CreateExternalAccount.types'
import { translations } from '../ExternalAccountReviewStage.i18n'

export const useExternalAccountReviewStage = () => {
  const t = useTranslations(translations)
  const { state, goToCreateExternalAccountState } =
    useCreateExternalAccountContext<ExternalAccountReviewState>()

  const { addThirdPartyAccount, isLoadingAddThirdPartyAccount } =
    useAddThirdPartyAccount()

  const checkStateIsDefined = useCallback(
    (
      stateToCheck: ExternalAccountReviewState | null
    ): stateToCheck is ExternalAccountReviewState => {
      if (stateToCheck === null) {
        ErrorLogger.report(
          '[Banking] external account review stage has no state'
        )
        showErrorToast()
        return false
      }

      return true
    },
    []
  )

  const closeModal = useCallback(() => {
    goToCreateExternalAccountState(undefined)
  }, [goToCreateExternalAccountState])

  const goBackToCreateExternalAccountFormStage = useCallback(() => {
    goToCreateExternalAccountState({
      ...(state || {}),
      stage: CreateExternalAccountStage.ExternalAccountFormStage,
    })
  }, [state, goToCreateExternalAccountState])

  const handleConfirm = useCallback(async () => {
    if (!checkStateIsDefined(state)) {
      return
    }

    try {
      const response = await addThirdPartyAccount(
        {
          accountBsb: state.accountBsb,
          accountName: state.accountName,
          accountNumber: state.accountNumber,
        },
        state.shouldUpdateDepositAccount,
        () => {
          showSuccessToast(t('createAndUpdateAccountSuccessNotification'))
        }
      )
      if (!response) {
        showErrorToast(t('errorNotification'))
        closeModal()
        return
      }
      if (!state.shouldUpdateDepositAccount) {
        showSuccessToast(t('createAccountSuccessNotification'))
      }
      closeModal()
    } catch (err) {
      showErrorToast(t('errorNotification'))
      closeModal()
    }
  }, [state, t, addThirdPartyAccount, closeModal, checkStateIsDefined])

  return {
    handleConfirm,
    goBackToCreateExternalAccountFormStage,
    isLoadingAddThirdPartyAccount,
    state,
  }
}
