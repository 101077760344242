import { ReactNode } from 'react'

import { ActiveCardsEmptyMessage } from './components/ActiveCardsEmptyMessage/ActiveCardsEmptyMessage'
import { ActiveCardsEmptyOnDelivery } from './components/ActiveCardsEmptyOnDelivery/ActiveCardsEmptyOnDelivery'

export interface ActiveCardsProps {
  onAddCard: () => void
  hasActiveCards: boolean
  hasOwnedPendingCards: boolean
  hasClosedCards: boolean
  isLoading: boolean
  children: ReactNode
}

export const ActiveCards = ({
  onAddCard,
  isLoading,
  children,
  hasActiveCards,
  hasOwnedPendingCards,
  hasClosedCards,
}: ActiveCardsProps) => {
  if (
    !isLoading &&
    !hasActiveCards &&
    !hasClosedCards &&
    hasOwnedPendingCards
  ) {
    return <ActiveCardsEmptyOnDelivery onAddCard={onAddCard} />
  }

  if (!isLoading && !hasActiveCards) {
    return <ActiveCardsEmptyMessage onAddCard={onAddCard} />
  }

  return <>{children}</>
}
