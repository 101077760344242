import { InputHTMLAttributes } from 'react'
import { FieldProps } from 'formik'

import { StyledRadioBox } from './Radiobutton.styled'

type OmittedTypes = 'onBlur' | 'onChange' | 'value' | 'type' | 'form'

export interface RadioProps
  extends Partial<FieldProps['field']>,
    Omit<InputHTMLAttributes<HTMLInputElement>, OmittedTypes> {
  id?: string
  withoutMargin?: boolean
  onClick?: () => void
}

export const Radiobutton = ({
  withoutMargin = false,
  id,
  name,
  checked,
  ...props
}: RadioProps) => {
  return (
    <StyledRadioBox
      withoutMargin={withoutMargin}
      name={name}
      id={id}
      checked={checked}
      {...props}
    />
  )
}

interface FormRadioButtonProps extends RadioProps, FieldProps {}

export const FormRadioButton = ({ field, ...props }: FormRadioButtonProps) => {
  return <Radiobutton {...field} {...props} />
}
