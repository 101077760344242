import { useContext, useMemo } from 'react'
import { getIsValidBPayTransferAppState } from 'features/MFA/utils/utils'

import { MFAContext } from '../../MFAContext'

export const useBPayTransferMFAState = () => {
  const context = useContext(MFAContext)

  if (context === null) {
    throw new Error('useTransferMFAState can only be used within MFAContext')
  }

  const { appState, hasRedirectedBackToApp, setHasRedirectedBackToApp } =
    context

  const bpayTransferState = useMemo(() => {
    if (!appState || !getIsValidBPayTransferAppState(appState)) {
      return null
    }

    return appState
  }, [appState])

  return {
    bpayTransferState,
    hasRedirectedBackToApp,
    setHasRedirectedBackToApp,
  }
}
