import { Box, Flex, ZellerLogoIcon } from '@npco/zeller-design-system'
import { Form } from 'formik'

import { ActionButtonsGroup } from 'components/Buttons/ActionButtonsGroup'
import { DocsInfo } from 'components/DocsInfo'
import { shared } from 'translations'

import {
  StyledContainer,
  StyledOnboardingDescription,
  StyledTitle,
} from './FormLayout.styled'

const { next } = shared

interface FormLayoutProps {
  dataTestId?: string
  isForm?: boolean
  title?: React.ReactNode
  maxWidth?: string
  innerContainerJustifyContent?: string
  hasLogo?: boolean
  isVerticallyCentered?: boolean
  description?: React.ReactNode
  children?: React.ReactNode
  selectedDocumentsAmount?: number
  controls?: React.ReactNode
  isBackButtonHidden?: boolean
  isNextButtonHidden?: boolean
  backButtonLabel?: string
  onBackClick?: () => void
  onConfirmClick?: () => void
  backDisabled?: boolean
  confirmDisabled?: boolean
  nextButtonLabel?: string
  margin?: string
  headerMarginTop?: string
  marginTop?: string
}

export const FormLayout = ({
  dataTestId,
  isForm = true,
  title,
  maxWidth = '512px',
  innerContainerJustifyContent,
  hasLogo,
  isVerticallyCentered = true,
  description,
  children,
  selectedDocumentsAmount = undefined,
  controls,
  isBackButtonHidden = false,
  isNextButtonHidden = false,
  backButtonLabel,
  onBackClick,
  onConfirmClick,
  backDisabled,
  confirmDisabled,
  nextButtonLabel = next,
  margin = '0 auto',
  headerMarginTop = '100px',
  marginTop = '0',
}: FormLayoutProps) => {
  const handleConfirmClick = isForm ? undefined : onConfirmClick
  const containerComponent = isForm ? Form : 'div'

  return (
    <Flex alignSelf="stretch" width="100%" marginTop={{ _: 0, MD: marginTop }}>
      <StyledContainer
        as={containerComponent}
        id="container"
        data-testid={dataTestId}
        $maxWidth={maxWidth}
        $justifyContent={innerContainerJustifyContent}
        margin={margin}
      >
        <div>
          {title && (
            <Box
              as="header"
              mt={isVerticallyCentered ? 0 : { _: 0, MD: headerMarginTop }}
              mb="1.5rem"
              pt={{ _: '8rem', MD: 0 }}
            >
              {hasLogo && (
                <Flex mb="44px">
                  <ZellerLogoIcon />
                </Flex>
              )}

              <StyledTitle>{title}</StyledTitle>
            </Box>
          )}
          {description && (
            <StyledOnboardingDescription as="div">
              {description}
            </StyledOnboardingDescription>
          )}
          {children}
          {selectedDocumentsAmount !== undefined && (
            <DocsInfo isMobile amount={selectedDocumentsAmount} />
          )}
        </div>
        {controls ?? (
          <>
            <ActionButtonsGroup
              isBackButtonHidden={isBackButtonHidden}
              isNextButtonHidden={isNextButtonHidden}
              backButtonLabel={backButtonLabel}
              onBackClick={onBackClick}
              backDisabled={backDisabled}
              onConfirmClick={handleConfirmClick}
              confirmDisabled={confirmDisabled}
              nextButtonLabel={nextButtonLabel}
            />

            {selectedDocumentsAmount !== undefined && (
              <DocsInfo amount={selectedDocumentsAmount} />
            )}
          </>
        )}
      </StyledContainer>
    </Flex>
  )
}
