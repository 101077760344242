import { PaymentInstrumentStatus } from '@npco/mp-gql-types'
import { DotBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const statusLabelMapping = {
  [PaymentInstrumentStatus.ACTIVE]: translate(
    'page.contact.sections.bankAccounts.fields.statusOptions.active'
  ),
  [PaymentInstrumentStatus.ARCHIVED]: translate(
    'page.contact.sections.bankAccounts.fields.statusOptions.archived'
  ),
  [PaymentInstrumentStatus.PRIMARY]: translate(
    'page.contact.sections.bankAccounts.fields.statusOptions.primary'
  ),
}

export const statusItemOptions = Object.keys(statusLabelMapping).map((key) => ({
  label: statusLabelMapping[key as PaymentInstrumentStatus],
  value: key,
}))

interface CreateBankAccountItemArguments {
  account: string | null
  bsb: string | null
  id: string
  name: string | null
}

export const createBankAccountItem = ({
  account,
  bsb,
  id,
  name,
}: CreateBankAccountItemArguments) => {
  const details = {
    bsb,
    account,
    name,
  }

  return {
    label: name || '',
    subLabel: (
      <>
        {bsb}
        <DotBasic />
        {account}
      </>
    ),
    value: id,
    id,
    data: details,
  }
}
