import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ContactDisplayContactFragmentDoc } from '../../../ContactDisplay/ContactDisplay.generated'

const defaultOptions = {} as const
export type ContactDetailsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  contactUuid: Types.Scalars['ID']['input']
}>

export type ContactDetailsQueryResponse = {
  __typename?: 'Query'
  getContact: {
    __typename: 'Contact'
    id: string
    contactType: Types.ContactType
    category: Types.EntityCategories | null
    subcategoryUuid: string | null
    location: string | null
    locationAccuracy: number | null
    phone: string | null
    jobTitle: string | null
    abn: string | null
    acn: string | null
    businessName: string | null
    firstName: string | null
    lastName: string | null
    notes: string | null
    isSelf: boolean | null
    tags: Array<string | null> | null
    website: string | null
    subcategory: string | null
    address: {
      __typename?: 'ContactAddress'
      country: string | null
      postcode: string | null
      state: string | null
      street: string | null
      suburb: string | null
    } | null
    phoneV2: {
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
    email: {
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    } | null
    additionalEmails: Array<{
      __typename?: 'ContactEmail'
      email: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    additionalPhones: Array<{
      __typename?: 'ContactPhone'
      phone: string
      label: {
        __typename?: 'Label'
        id: string
        isEditable: boolean
        labelText: string
        type: Types.LabelType
      } | null
    }> | null
    contacts: Array<{
      __typename: 'Contact'
      abn: string | null
      acn: string | null
      businessName: string | null
      contactType: Types.ContactType
      firstName: string | null
      id: string
      jobTitle: string | null
      lastName: string | null
      phone: string | null
      website: string | null
      location: string | null
      locationAccuracy: number | null
      category: Types.EntityCategories | null
      subcategory: string | null
      subcategoryUuid: string | null
      additionalEmails: Array<{
        __typename?: 'ContactEmail'
        email: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      }> | null
      additionalPhones: Array<{
        __typename?: 'ContactPhone'
        phone: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      }> | null
      address: {
        __typename?: 'ContactAddress'
        country: string | null
        postcode: string | null
        state: string | null
        street: string | null
        suburb: string | null
      } | null
      email: {
        __typename?: 'ContactEmail'
        email: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      } | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
      phoneV2: {
        __typename?: 'ContactPhone'
        phone: string
        label: {
          __typename?: 'Label'
          id: string
          isEditable: boolean
          labelText: string
          type: Types.LabelType
        } | null
      } | null
    } | null> | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    paymentInstruments: Array<{
      __typename?: 'PaymentInstrument'
      id: string
      status: Types.PaymentInstrumentStatus
      bankAccountDetails: {
        __typename?: 'BankAccountDetails'
        account: string | null
        bsb: string | null
        name: string | null
      } | null
      details:
        | { __typename?: 'BankAccountDetails' }
        | { __typename?: 'BpayDetails' }
        | { __typename?: 'NppDetails' }
        | {
            __typename: 'PaymentInstrumentBpayDynamicCrnDetails'
            billerName: string
            billerCode: string
            nickname: string | null
          }
        | {
            __typename: 'PaymentInstrumentBpayStaticCrnDetails'
            billerName: string
            billerCode: string
            nickname: string | null
            crn: string
          }
    }> | null
  }
}

export const ContactDetailsQuery = gql`
  query ContactDetailsQuery($entityUuid: ID!, $contactUuid: ID!) {
    getContact(entityUuid: $entityUuid, id: $contactUuid) {
      id
      ...ContactDisplayContactFragment
    }
  }
  ${ContactDisplayContactFragmentDoc}
` as unknown as TypedDocumentNode<
  ContactDetailsQueryResponse,
  ContactDetailsQueryVariables
>

/**
 * __useContactDetailsQuery__
 *
 * To run a query within a React component, call `useContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDetailsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      contactUuid: // value for 'contactUuid'
 *   },
 * });
 */
export function useContactDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactDetailsQueryResponse,
    ContactDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContactDetailsQueryResponse,
    ContactDetailsQueryVariables
  >(ContactDetailsQuery, options)
}
export function useContactDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactDetailsQueryResponse,
    ContactDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContactDetailsQueryResponse,
    ContactDetailsQueryVariables
  >(ContactDetailsQuery, options)
}
export type ContactDetailsQueryHookResult = ReturnType<
  typeof useContactDetailsQuery
>
export type ContactDetailsQueryLazyQueryHookResult = ReturnType<
  typeof useContactDetailsQueryLazyQuery
>
export type ContactDetailsQueryQueryResult = Apollo.QueryResult<
  ContactDetailsQueryResponse,
  ContactDetailsQueryVariables
>
