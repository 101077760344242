import { gql } from '@apollo/client'

export const GetXeroSiteCustomers = gql`
  query GetXeroSiteCustomers($entityUuid: ID!) {
    getXeroSiteSettings(entityUuid: $entityUuid) {
      __typename
      id
      name
      customers {
        id
        role
        firstname
        lastname
        __typename
      }
    }
  }
`
