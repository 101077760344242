import { DecisionModal } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface ConfirmNavigationModalProps {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  description: string
}
export const ConfirmNavigationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  description,
}: ConfirmNavigationModalProps) => {
  return (
    <DecisionModal
      isOpen={isOpen}
      onCancel={onCancel}
      onClickPrimary={onConfirm}
      onClickSecondary={onCancel}
      title={translate(
        'page.settings.connections.xeroPaymentServices.interruptFlowModal.title'
      )}
      primaryButtonLabel={translate(
        'page.settings.connections.xeroPaymentServices.interruptFlowModal.primaryLabel'
      )}
      secondaryButtonLabel={translate(
        'page.settings.connections.xeroPaymentServices.interruptFlowModal.secondaryLabel'
      )}
    >
      {description}
    </DecisionModal>
  )
}
