import { useCallback, useEffect, useRef } from 'react'
import { Prompt } from 'react-router-dom'
import { matchPath, useNavigate } from 'react-router-dom-v5-compat'
import {
  DrawerNavigationProvider,
  DrawerWithNavigation,
} from '@npco/ui-drawer-navigation'
import { Tabs } from '@npco/zeller-design-system'
import { rvTransactionCardholderContactMap } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'

import { ROOT } from 'const/routes'
import { getNonNullString } from 'utils/string'
import { Transaction, TransactionInList } from 'types/transactions'
import { useDrawerTabEvents } from 'services/Analytics/useDrawerTabEvents'
import { SwitchAnimationContainer } from 'components/DrawerWithNavigation/DrawerNavigation/SwitchAnimationContainer/SwitchAnimationContainer'
import { TransactionContact } from 'components/TransactionContact/TransactionContact'
import { getLinkedContactUuid } from 'components/TransactionContact/TransactionContact.utils'
import { TransactionDetails } from 'components/TransactionDetails/TransactionDetails'
import { component } from 'translations'

import {
  TRANSACTION_TAB_TYPE,
  useTransactionsDrawerTabs,
} from './hooks/useTransactionsDrawerTabs/useTransactionsDrawerTabs'

interface TransactionsDrawerProps {
  areDetailsOpen: boolean
  onClose: () => void
  onLink?: () => void
  onUnlink?: (unlinkedCardHolderUuid: string) => void
  selectedTransaction: TransactionInList | null
  selectNextTransaction?: () => void
  selectPreviousTransaction?: () => void
}

export const TransactionsDrawer = ({
  areDetailsOpen,
  onClose,
  onLink,
  onUnlink,
  selectedTransaction,
  selectNextTransaction,
  selectPreviousTransaction,
}: TransactionsDrawerProps) => {
  const navigate = useNavigate()
  const drawerCloseAnimationRedirectUrl = useRef('')
  const { onDrawerOpen, onDrawerClose: logEventOnDrawerClose } =
    useDrawerTabEvents({
      drawerName: 'Transaction',
    })
  const cardholderContactMap = rvTransactionCardholderContactMap()
  const contactUuid = (selectedTransaction as Transaction)?.contact?.id ?? null

  const cardholderUuid =
    (selectedTransaction as Transaction)?.cardholderUuid ?? null

  const { selectedTab, setSelectedTab, tabs } = useTransactionsDrawerTabs({
    showContactTab: cardholderUuid !== null,
  })

  const onDrawerClose = useCallback(() => {
    onClose()
    logEventOnDrawerClose()
  }, [onClose, logEventOnDrawerClose])

  const onAfterDrawerClose = useCallback(() => {
    if (drawerCloseAnimationRedirectUrl.current) {
      navigate(drawerCloseAnimationRedirectUrl.current, {
        state: { isRedirectFromLink: true },
      })
    }
  }, [navigate])

  const linkedContactUuid = getLinkedContactUuid(
    cardholderContactMap,
    cardholderUuid,
    contactUuid
  )

  // NOTE: the contact data isn't immediately shown when viewing the
  // transactions drawer however we prefetch so the data is immediately returned
  // for a better ux. As the drawer is mounted before it is visible this will
  // fetch the appropriate contact when the linked contact uuid changes.
  useGetContact({ id: linkedContactUuid })

  useEffect(() => {
    if (!areDetailsOpen) {
      setSelectedTab(TRANSACTION_TAB_TYPE.DETAILS)
      return
    }
    onDrawerOpen(TRANSACTION_TAB_TYPE.DETAILS)
  }, [areDetailsOpen, onDrawerOpen, setSelectedTab])

  const handlePromptMessage = useCallback(
    (location) => {
      const isInInvoicesPath = Boolean(
        matchPath(
          `${ROOT.ORGS.ORG().INVOICING.INVOICES.path}/*`,
          location.pathname
        )
      )
      if (isInInvoicesPath && !drawerCloseAnimationRedirectUrl.current) {
        drawerCloseAnimationRedirectUrl.current = location.pathname
        onDrawerClose()
        return false
      }

      return true
    },
    [onDrawerClose]
  )

  return (
    <>
      <DrawerNavigationProvider
        onPrevious={
          selectedTab === TRANSACTION_TAB_TYPE.DETAILS
            ? selectPreviousTransaction
            : undefined
        }
        onNext={
          selectedTab === TRANSACTION_TAB_TYPE.DETAILS
            ? selectNextTransaction
            : undefined
        }
      >
        <DrawerWithNavigation
          closeTimeoutMS={300}
          headerContent={
            <Tabs currentTab={selectedTab}>
              {tabs.map((tab) => (
                <Tabs.Item key={tab.value} {...tab} />
              ))}
            </Tabs>
          }
          isOpen={areDetailsOpen}
          onClose={onDrawerClose}
          onAfterClose={onAfterDrawerClose}
          title={`${component.transaction.ref} #${getNonNullString(
            selectedTransaction?.reference,
            '-'
          )}`}
        >
          {selectedTab === TRANSACTION_TAB_TYPE.DETAILS &&
            selectedTransaction && (
              <SwitchAnimationContainer animationKey={selectedTransaction.id}>
                <TransactionDetails
                  selectedTransaction={selectedTransaction}
                  closeDrawer={onDrawerClose}
                />
              </SwitchAnimationContainer>
            )}
          {selectedTab === TRANSACTION_TAB_TYPE.CONTACT &&
            selectedTransaction && (
              <TransactionContact
                onLink={onLink}
                onUnlink={onUnlink}
                transaction={selectedTransaction}
              />
            )}
        </DrawerWithNavigation>
      </DrawerNavigationProvider>
      <Prompt message={handlePromptMessage} />
    </>
  )
}
