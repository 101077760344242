import { useTranslations } from '@npco/utils-translations'
import { Flex, ToggleForm } from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { SettingsPayments } from 'types/settings'

import * as styled from '../../SitePayments.styled'
import { translations } from './SplitPayments.i18n'

export const SplitPayments = () => {
  const { values } = useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)
  return (
    <Flex flexDirection="column" gridGap="24px">
      <Flex flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <styled.Title>{t('splitPayments')}</styled.Title>
          <Field
            component={ToggleForm}
            name="splitPaymentEnabled"
            value={values.splitPaymentEnabled}
          />
        </Flex>
        <styled.Description>
          {t('splitPaymentssDescription')}
        </styled.Description>
      </Flex>
    </Flex>
  )
}
