import { useEffect, useRef } from 'react'
import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  AccordionComplex,
  Box,
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import {
  InvoiceFormFields,
  PaymentTerms,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import dayjs from 'utils/dayjs'

import { InvoiceAccordionCommonProps } from '../../InvoiceFormAccordions.types'
import { InvoiceAccordionContentWrapper } from '../InvoiceAccordionContentWrapper/InvoiceAccordionContentWrapper'
import { InvoiceDate } from './InvoiceDate/InvoiceDate'
import { InvoiceDueDate } from './InvoiceDueDate/InvoiceDueDate'
import { InvoicePaymentTerms } from './InvoicePaymentTerms/InvoicePaymentTerms'
import { translations } from './InvoiceScheduleAccordion.i18n'
import {
  getAccordionDetails,
  getAccordionFormState,
  isPastDate,
} from './InvoiceScheduleAccordion.utils'
import { InvoiceScheduleSend } from './InvoiceScheduleSend/InvoiceScheduleSend'

export const InvoiceScheduleAccordion = ({
  isExpanded,
  onChange,
}: InvoiceAccordionCommonProps) => {
  const t = useTranslations(translations)

  const {
    errors,
    initialValues: { schedule: scheduleInitialValues },
    setTouched,
    setValues,
    submitCount,
    touched,
    values: { schedule: scheduleValues, status },
  } = useFormikContext<InvoiceFormFields>()

  const hasBeenExpanded = useRef(false)

  const { dueDate, invoiceDate, sendDate } = scheduleValues
  const { dueDate: initialDueDate } = scheduleInitialValues

  const hasBeenInitialised =
    hasBeenExpanded.current ||
    Boolean(dueDate) ||
    Boolean(invoiceDate) ||
    Boolean(sendDate)

  const isDraftInvoice = status === InvoiceStatus.DRAFT
  const isDueDateInPast = isPastDate(dueDate)
  const isDueDateChanged = initialDueDate !== dueDate

  useEffect(() => {
    // In the event that the form was reset, we also reset ref
    if (!submitCount) {
      hasBeenExpanded.current = false
    }
  }, [submitCount])

  useEffect(() => {
    if (isExpanded) {
      hasBeenExpanded.current = true
    }
  }, [isExpanded])

  useEffect(() => {
    if (!isExpanded || hasBeenInitialised) {
      return
    }

    setValues((prevValues) => ({
      ...prevValues,
      schedule: {
        invoiceDate: dayjs().format(DATE_FULL_DISPLAY_FORMAT),
        dueDate: dayjs().format(DATE_FULL_DISPLAY_FORMAT),
        paymentTerms: PaymentTerms.Immediate,
        sendEnabled: false,
        sendDate: '',
      },
    }))

    setTouched(
      {
        ...touched,
        schedule: {
          dueDate: true,
          invoiceDate: true,
          paymentTerms: true,
        },
      },
      false
    )
  }, [hasBeenInitialised, isExpanded, setTouched, touched, setValues])

  return (
    <AccordionComplex
      dataTestId="invoice-schedule"
      details={getAccordionDetails(dueDate)}
      formItemState={getAccordionFormState({
        errors: errors?.schedule,
        touched: touched?.schedule,
        values: scheduleValues,
      })}
      isExpanded={isExpanded}
      onChange={onChange}
      title={t('scheduleFormTitle')}
    >
      <InvoiceAccordionContentWrapper>
        <Box
          display="grid"
          gridColumnGap="16px"
          gridRowGap="24px"
          gridTemplateColumns={{
            _: 'none',
            XS: 'repeat(2, 1fr)',
            LG: 'repeat(3, 1fr)',
          }}
        >
          <InvoiceDate />
          <InvoicePaymentTerms />
          <InvoiceDueDate />
          <Box gridColumn="1 / 2">
            <InvoiceScheduleSend />
          </Box>
        </Box>
        {isDueDateInPast && !isDueDateChanged && !isDraftInvoice && (
          <InfoBox
            dataTestId="invoice-schedule-due-date-infobox"
            priority={INFOBOX_PRIORITY.DEFAULT}
            variant={INFOBOX_VARIANT.POSITIVE}
          >
            {t('scheduleDueDateWarning')}
          </InfoBox>
        )}
      </InvoiceAccordionContentWrapper>
    </AccordionComplex>
  )
}
