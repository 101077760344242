import { Outlet } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { DefaultAccessDeniedElement } from './DefaultAccessDeniedElement'

interface PrivateRoutesProps {
  allowedRoles: CustomerRole[]
  accessDeniedElement?: JSX.Element
}

export const RoleGuardedRoutes = ({
  allowedRoles,
  accessDeniedElement = <DefaultAccessDeniedElement />,
}: PrivateRoutesProps) => {
  const { userRole } = useLoggedInUser()

  if (!allowedRoles.includes(userRole)) {
    return accessDeniedElement
  }

  return <Outlet />
}
