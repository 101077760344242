import { Field } from 'formik'
import styled from 'styled-components'

export const CreditCardField = styled(Field)`
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${({ theme }) => theme.colors.GREY_550};
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${({ theme }) => theme.colors.GREY_550};
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${({ theme }) => theme.colors.GREY_550};
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: ${({ theme }) => theme.colors.GREY_550};
  }
`
