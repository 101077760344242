import styled from 'styled-components'

import { BUTTON_SIZE } from 'types/common'
import { BREAKPOINT } from 'styles/breakpoints'
import { Button } from 'components/Buttons/Button'

export const StyledButtonNext = styled(Button).attrs(() => ({
  size: BUTTON_SIZE.LARGE,
}))`
  width: 50%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-width: 12.5rem;
    width: 0;
  }
`
