import { NAME_FORMATS, nameFormatter } from '@npco/component-mp-common'
import { CustomerRole } from '@npco/mp-gql-types'
import { COLOR, Flex } from '@npco/zeller-design-system'

import { badgeLabel, getBadgeColorProps } from 'utils/badge'
import { getNonNullString } from 'utils/string'
import { GetCustomerBasicInfo_getCustomer as Customer } from 'types/gql-types/GetCustomerBasicInfo'
import { UserDetails } from 'components/UserDetails'

import { SpinnerWrapped } from '../Spinner'
import {
  StyledDetailsTitle,
  StyledStatusBadge,
  StyledStatusBadgeWrapper,
  StyledUserDrawer,
} from './UserDetails.styled'
import { UserEditButton } from './UserEditButton/UserEditButton'

type UserDrawerProps = {
  isOpen: boolean
  isLoading?: boolean
  canEditUser?: boolean
  selectedUser: Customer | null | undefined
  isUsers?: boolean
  onClose?: () => void
}

export const UserDrawer = ({
  isLoading,
  isOpen,
  canEditUser = true,
  selectedUser,
  isUsers = false,
  onClose,
}: UserDrawerProps) => {
  return (
    <StyledUserDrawer
      testId="user-drawer"
      overlayClassName="animated-drawer-overlay"
      isOpen={isOpen}
      onClose={onClose}
      title=" "
      headerContent={
        <Flex padding="" flexDirection="column">
          <Flex>
            <StyledDetailsTitle $color={COLOR.BLUE_1000}>
              {nameFormatter(
                {
                  firstname: getNonNullString(selectedUser?.firstname),
                  middlename: getNonNullString(selectedUser?.middlename),
                  lastname: getNonNullString(selectedUser?.lastname),
                },
                NAME_FORMATS.FIRST_LAST
              )}
            </StyledDetailsTitle>
            {canEditUser && <UserEditButton isUsers={isUsers} />}
          </Flex>
          <StyledStatusBadgeWrapper>
            <StyledStatusBadge
              justifyContent="start"
              text={badgeLabel[selectedUser?.role as CustomerRole]}
              {...getBadgeColorProps(selectedUser?.role as CustomerRole)}
            />
          </StyledStatusBadgeWrapper>
        </Flex>
      }
    >
      {selectedUser && !isLoading ? (
        <UserDetails selectedUser={selectedUser} />
      ) : (
        <SpinnerWrapped variant="top" />
      )}
    </StyledUserDrawer>
  )
}
