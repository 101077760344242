import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type PersonaliseSavingsAccountMutationVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  input: Types.PersonaliseSavingsAccountInput
}>

export type PersonaliseSavingsAccountMutationResponse = {
  __typename?: 'Mutation'
  personaliseSavingsAccount:
    | { __typename?: 'DebitCardAccount' }
    | { __typename?: 'DebitCardAccountV2'; id: string; name: string }
}

export const PersonaliseSavingsAccount = gql`
  mutation PersonaliseSavingsAccount(
    $entityUuid: ID!
    $input: PersonaliseSavingsAccountInput!
  ) {
    personaliseSavingsAccount(entityUuid: $entityUuid, input: $input) {
      ... on DebitCardAccountV2 {
        id
        name
      }
    }
  }
` as unknown as TypedDocumentNode<
  PersonaliseSavingsAccountMutationResponse,
  PersonaliseSavingsAccountMutationVariables
>
export type PersonaliseSavingsAccountMutationFn = Apollo.MutationFunction<
  PersonaliseSavingsAccountMutationResponse,
  PersonaliseSavingsAccountMutationVariables
>

/**
 * __usePersonaliseSavingsAccountMutation__
 *
 * To run a mutation, you first call `usePersonaliseSavingsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonaliseSavingsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personaliseSavingsAccountMutation, { data, loading, error }] = usePersonaliseSavingsAccountMutation({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonaliseSavingsAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PersonaliseSavingsAccountMutationResponse,
    PersonaliseSavingsAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PersonaliseSavingsAccountMutationResponse,
    PersonaliseSavingsAccountMutationVariables
  >(PersonaliseSavingsAccount, options)
}
export type PersonaliseSavingsAccountMutationHookResult = ReturnType<
  typeof usePersonaliseSavingsAccountMutation
>
export type PersonaliseSavingsAccountMutationResult =
  Apollo.MutationResult<PersonaliseSavingsAccountMutationResponse>
export type PersonaliseSavingsAccountMutationOptions =
  Apollo.BaseMutationOptions<
    PersonaliseSavingsAccountMutationResponse,
    PersonaliseSavingsAccountMutationVariables
  >
