import { useMemo, useState } from 'react'
import { Box, Flex } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { getLogoColor } from '../SiteAppearance.utils'
import { SiteAppearanceFormValues } from '../SiteAppearanceForm/SiteAppearanceForm.types'
import { ControlPanel } from './ControlPanel/ControlPanel'
import { StatusBar } from './StatusBar/StatusBar'
import * as styled from './TerminalPreview.styled'
import { TerminalModel } from './TerminalPreview.types'
import { ZellerLogo } from './ZellerLogo/ZellerLogo'

const MAX_IMAGE_HEIGHT = 992
const MAX_IMAGE_WIDTH = 640

interface TerminalPreviewProps {
  isInModal?: boolean
}

export const TerminalPreview = ({
  isInModal = false,
}: TerminalPreviewProps) => {
  const { values } = useFormikContext<SiteAppearanceFormValues>()
  const [hasRoundedCorners, setHasRoundedCorners] = useState(false)
  const selectedLogo = useMemo(
    () =>
      values.logos.filter((logo) => logo.logoUuid === values.selectedLogo)[0],
    [values.logos, values.selectedLogo]
  )
  const isT1Selected = values.selectedTerminalModel === TerminalModel.T1

  return (
    <Flex
      width="264px"
      height="648px"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      gap="40px"
    >
      <Box position="relative">
        <Box width="264px" height="480px">
          {isT1Selected ? (
            <styled.TerminalShellT1
              src={values.selectedTerminalUrl}
              alt="terminal-shell-t1"
            />
          ) : (
            <styled.TerminalShellT2
              src={values.selectedTerminalUrl}
              alt="terminal-shell-t2"
            />
          )}
        </Box>
        <Flex
          position="absolute"
          top={isT1Selected ? '106px' : '21px'}
          left="10px"
          width="180px"
          height={isT1Selected ? '318px' : '396px'}
          backgroundColor={values.selectedColour}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          zIndex={isT1Selected ? undefined : -1}
        >
          <StatusBar />
          {selectedLogo && (
            <styled.CustomLogo
              data-testid="custom-logo"
              src={selectedLogo.url}
              alt="custom-logo"
              $hasRoundedCorners={hasRoundedCorners}
              $isT1={isT1Selected}
              onLoad={(e) => {
                const image = e.target as HTMLImageElement
                if (
                  image.width >= MAX_IMAGE_WIDTH ||
                  image.height >= MAX_IMAGE_HEIGHT
                ) {
                  setHasRoundedCorners(true)
                } else {
                  setHasRoundedCorners(false)
                }
              }}
            />
          )}
          {!selectedLogo && (
            <ZellerLogo color={getLogoColor(values.selectedColour)} />
          )}
          {selectedLogo && (
            <Box position="absolute" bottom="16px" opacity={0.5}>
              <ZellerLogo
                width="40"
                height="14"
                color={getLogoColor(values.selectedColour)}
              />
            </Box>
          )}
        </Flex>
      </Box>
      {isInModal && <ControlPanel />}
    </Flex>
  )
}
