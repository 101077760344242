import { gql, type TypedDocumentNode } from '@apollo/client'

export type TotalCellCashFlowCategoryNetAmountFragment = {
  __typename: 'CashFlowCategoryNetAmount'
  change: number | null
  total: { __typename?: 'Money'; currency: string; value: string }
}

export type TotalCellCashFlowContactNetAmountFragment = {
  __typename: 'CashFlowContactNetAmount'
  change: number | null
  total: { __typename?: 'Money'; currency: string; value: string }
}

export type TotalCellCashFlowSubcategoryNetAmountFragment = {
  __typename: 'CashFlowSubcategoryNetAmount'
  total: { __typename?: 'Money'; currency: string; value: string }
}

export const TotalCellCashFlowCategoryNetAmountFragmentDoc = gql`
  fragment TotalCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
    __typename
    change
    total {
      currency
      value
    }
  }
` as unknown as TypedDocumentNode<
  TotalCellCashFlowCategoryNetAmountFragment,
  undefined
>
export const TotalCellCashFlowContactNetAmountFragmentDoc = gql`
  fragment TotalCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    __typename
    change
    total {
      currency
      value
    }
  }
`
export const TotalCellCashFlowSubcategoryNetAmountFragmentDoc = gql`
  fragment TotalCellCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
    __typename
    total {
      currency
      value
    }
  }
`
