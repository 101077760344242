import { useFormikContext } from 'formik'

import { TransferFieldTypes } from '../Transfer.types'

export const useResetFields = () => {
  const { setValues, setFieldTouched, values } =
    useFormikContext<TransferFieldTypes>()

  const resetFields = () => {
    setValues(
      {
        to: null,
        contact: '',
        amount: '',
        from: values.from,
        reference: '',
        recipientReference: '',
        isSelf: undefined,
        nickname: undefined,
        crn: undefined,
      },
      false
    )
    setFieldTouched('to', false)
    setFieldTouched('amount', false)
    setFieldTouched('reference', false)
    setFieldTouched('recipientReference', false)
    setFieldTouched('isSelf', false)
    setFieldTouched('nickname', false)
    setFieldTouched('crn', false)
  }

  return {
    resetFields,
  }
}
