import { ReactNode, useEffect } from 'react'
import {
  BadgeSize,
  Flex,
  HeadingStyles,
  NumberBadge,
  NumberBadgeVariant,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg'

import { ContentCollapser } from '../../../ContentCollapser/ContentCollapser'
import { useSidebarLocalCache } from '../../hooks/useSidebarLocalCache'
import { ExpandableSidebarItems, SubItem } from '../../Sidebar.types'
import { SubItemElement } from '../SubItem/SubItemElement'
import { useParseSubitems } from './ItemToggle.hooks'
import * as styled from './ItemToggle.styled'

interface Props {
  badge?: ReactNode
  children?: ReactNode[] | ReactNode
  className?: string
  icon: ReactNode
  name: ExpandableSidebarItems
  numberBadgeCount?: number
  subItems: SubItem[]
}

export const ItemToggle = ({
  badge,
  children,
  className,
  icon,
  name,
  numberBadgeCount,
  subItems,
}: Props) => {
  const {
    isFolded,
    expandedItem,
    expandedItemFromPath,
    setExpandedItem,
    setIsFolded,
  } = useSidebarLocalCache()

  const isOnPath = expandedItemFromPath === name
  const isExpanded = !isFolded && expandedItem === name

  const ariaId = `submenu-for-${name}`

  useEffect(() => {
    setExpandedItem(expandedItemFromPath)
  }, [expandedItemFromPath, setExpandedItem])

  const handleClick = () => {
    if (isFolded) {
      setIsFolded(false)
      setExpandedItem(name)
    } else {
      setExpandedItem(isExpanded ? 'NONE' : name)
    }
  }

  const subItemsWithVisibility = useParseSubitems(subItems)
  const shouldDisplayNumberBadge =
    Boolean(numberBadgeCount) && !isExpanded && !isFolded

  if (subItemsWithVisibility.length === 0) {
    return null
  }

  return (
    <Flex
      flexDirection="column"
      data-testid="item-toggle"
      className={className}
    >
      <styled.ItemToggleWrapper
        $isFolded={isFolded}
        as="button"
        className={isOnPath && !isExpanded ? 'active' : undefined}
        data-testid="toggle-button"
        onClick={handleClick}
        aria-controls={ariaId}
        aria-expanded={isExpanded}
      >
        <Flex alignItems="center" justifyContent="flex-start">
          <div aria-hidden>{icon}</div>
          <Flex alignItems="center" gap="8px">
            <styled.Title withStyles={HeadingStyles.H5} $isFolded={isFolded}>
              {children}
            </styled.Title>
            {shouldDisplayNumberBadge && (
              <NumberBadge
                size={BadgeSize.Small}
                text={`${numberBadgeCount}`}
                variant={NumberBadgeVariant.Alert}
              />
            )}
            {badge}
          </Flex>
        </Flex>
        <styled.IconWrapper
          $isOpen={isExpanded}
          $isFolded={isFolded}
          aria-hidden
        >
          <SvgIcon width="16" height="16" withRotation={isExpanded}>
            <ArrowDown />
          </SvgIcon>
        </styled.IconWrapper>
      </styled.ItemToggleWrapper>
      <ContentCollapser isActive={isExpanded} withOverflowHidden id={ariaId}>
        <styled.SubitemsWrapper>
          {subItemsWithVisibility.map(
            ({
              subitemPath,
              subitemTitle,
              isComingSoon,
              isDisabled,
              isNew,
              numberBadgeCount: subItemNumberBadgeCount,
              excludeSubPaths,
            }) => (
              <SubItemElement
                excludeSubPaths={excludeSubPaths}
                key={subitemTitle}
                title={subitemTitle}
                path={subitemPath}
                isComingSoon={isComingSoon}
                isFolded={isFolded}
                isDisabled={isDisabled}
                isNew={isNew}
                numberBadgeCount={subItemNumberBadgeCount}
              />
            )
          )}
        </styled.SubitemsWrapper>
      </ContentCollapser>
    </Flex>
  )
}
