import { StyledTableEffect } from '@npco/ui-table'
import {
  BodyDefaultTypography,
  BodySmall,
  BodySmallTypography,
  SvgIcon,
  Title,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const TransactionItem = styled.li<{ $isUpdated: boolean }>`
  ${StyledTableEffect}

  background-color: ${({ theme }) => theme.colors.WHITE};
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  padding: 12px 6px 12px 6px;
  text-align: left;
  max-height: none;

  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-areas:
    'cardtype time amount'
    'cardtype cardnumber status';
  grid-template-columns: 52px 1fr minmax(60px, 80px);
  grid-template-rows: repeat(2, 1fr);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    grid-template-areas: 'time cardtype cardnumber devicetype amount';
    grid-template-columns: 72px 84px 1fr 64px minmax(64px, 80px) 32px;
    max-height: 64px;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    grid-template-areas: 'time cardtype cardnumber devicetype site status amount';
    grid-template-columns: 72px 84px 1fr 64px 1fr minmax(100px, 140px) minmax(
        72px,
        80px
      );
    grid-template-rows: 1fr;
  }

  ${({ $isUpdated, theme }) =>
    $isUpdated &&
    css`
      animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
      border-left: 2px solid ${theme.colors.BLUE_1000};
    `}

  .tooltip-button {
    padding: 0;
  }
`

export const Time = styled.time`
  margin: 0;
  grid-area: time;

  &,
  button {
    ${BodyDefaultTypography}
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin: 0;
  }
`

export const CardType = styled.div`
  grid-area: cardtype;
`

export const CardNumber = styled.div`
  grid-area: cardnumber;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const DeviceType = styled.div`
  display: none;
  grid-area: devicetype;
  height: 16px;
  justify-self: center;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`

export const SiteName = styled.div`
  display: none;
  grid-area: site;
  overflow: hidden;
  max-height: 24px;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: block;
  }

  & > button {
    text-align: left;
    width: 100%;
  }
`

export const Status = styled.div`
  display: block;
  grid-area: status;
  text-align: right;

  p {
    ${BodySmallTypography}
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
    text-align: left;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: block;

    p {
      ${BodyDefaultTypography}
    }
  }
`

export const Amount = styled.div<{
  $isDeclined: boolean
  $isIncoming: boolean
}>`
  color: ${({ theme }) => theme.colors.BLACK_900};
  grid-area: amount;
  justify-self: end;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    color: ${({ theme, $isDeclined, $isIncoming }) => {
      if ($isDeclined) {
        return theme.colors.RED_1000
      }
      if ($isIncoming) {
        return theme.colors.GREEN_1600
      }

      return theme.colors.BLACK_900
    }};
  }
`

export const Small = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`

export const ItemText = styled(Title)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const IssuerIcon = styled(SvgIcon)`
  position: absolute;
  right: -2px;
  top: -4px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    right: 14px;
  }
`
