import { StyledTable } from './SummaryTable.styled'

interface Props {
  children?: React.ReactNode
  className?: string
}

export const SummaryTable = ({ children, className }: Props) => {
  return (
    <StyledTable className={className} data-testid="performance-summary-table">
      <tbody>{children}</tbody>
    </StyledTable>
  )
}
