import styled from 'styled-components/macro'

export const StyledInfo = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.GREY_60};
  border-radius: 7px;
  display: flex;
  align-items: center;
  font-size: 0.85rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;

    path {
      fill: ${({ theme }) => theme.colors.GREY_250};
    }
  }

  p {
    margin: 0 0.5rem 0 0;
  }
`
