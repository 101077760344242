import { useCallback, useEffect } from 'react'
import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  GetContact,
  GetContactQueryResponse,
  GetContactQueryVariables,
} from 'features/Contacts/graphql/GetContact.generated'

import { rvContacts } from '../../rv-deprecated/contacts'
import { isContactDeletedFromRv } from '../../rv-deprecated/contacts.utils'

interface UseGetContactProps {
  id?: string | null
  queryOptions?: Omit<
    QueryHookOptions<GetContactQueryResponse, GetContactQueryVariables>,
    'variables' | 'skip' | 'notifyOnNetworkStatusChange'
  >
}

export const useGetContact = (props?: UseGetContactProps) => {
  const id = props?.id ?? ''

  const entityUuid = useSelectedEntityUuid()

  const contact = id ? rvContacts()?.[id] : undefined

  const contactHasBeenDeleted = contact === null

  const {
    data,
    error,
    loading: isLoading,
    networkStatus,
    refetch,
  } = useQuery<GetContactQueryResponse, GetContactQueryVariables>(GetContact, {
    skip: !id || Boolean(contact) || contactHasBeenDeleted,
    notifyOnNetworkStatusChange: true,
    variables: { id, entityUuid },
    ...props?.queryOptions,
  })

  const isRefetching = networkStatus === NetworkStatus.refetch

  useEffect(() => {
    // NOTE: Ignore if no data returned or if cached data has already been deleted locally
    if (!data || isContactDeletedFromRv(data.getContact.id)) {
      return
    }

    rvContacts({ ...rvContacts(), [data.getContact.id]: data.getContact })
  }, [data])

  return {
    // NOTE: always read data from rv. If contact is null, it means contact is already deleted
    // If contact from rv does not exist, always use data that was fetched
    data: contact !== undefined ? contact : data?.getContact ?? null,
    error,
    loading: isLoading || isRefetching,
    refetch: useCallback(
      async (contactUuid: string) => {
        const response = await refetch({ id: contactUuid, entityUuid })
        return response.data.getContact
      },
      [refetch, entityUuid]
    ),
  }
}
