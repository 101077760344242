import { CustomerRole } from '@npco/mp-gql-types'
import { Box, COLOR, Flex, Status, SvgIcon } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { badgeLabel, getBadgeColorProps } from 'utils/badge'
import { component } from 'translations'

import {
  StyledBadgeWrapper,
  StyledCardAddWrapper,
  StyledClickableWrapper,
  StyledIconWrapper,
  StyledLabel,
  StyledNameCard,
  StyledTextLargeClickable,
} from './Card.styled'
import { CardMainContent } from './CardMainContent'
import { CardModal } from './CardModal'

const { ADMIN, MANAGER } = CustomerRole

interface Props {
  actionLabel?: string
  children?: React.ReactNode
  isClickable?: boolean
  isReadOnly?: boolean
  modalComponent?: (fn: () => void) => React.ReactElement
  letterBadgeColor?: string
  text: string | null
  textSmall?: string
  type?: string
  isSmall?: boolean
  hasBadge?: boolean
  hasCloseIcon?: boolean
  onCardClick?: () => void
  onActionButtonClick?: () => void
}

/**
 * @deprecated
 */
export const Card: React.FC<Props> = ({
  actionLabel,
  children,
  letterBadgeColor,
  isClickable = true,
  isReadOnly = false,
  modalComponent,
  text,
  textSmall,
  type,
  onCardClick,
  onActionButtonClick,
  hasBadge = true,
  hasCloseIcon,
  isSmall = false,
}) => {
  const hasOnCardClick = Boolean(onCardClick)
  let textSmallColor: string = COLOR.GREY_550
  const isAdmin = type === ADMIN

  if (isAdmin) {
    textSmallColor = COLOR.RED_1000
  } else if (type === MANAGER) {
    textSmallColor = COLOR.BLUE_1000
  }

  return (
    <StyledNameCard
      data-testid="card"
      isClickable={isClickable}
      hasOnCardClick={hasOnCardClick}
      onClick={onCardClick}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={isClickable || isReadOnly ? '0' : '24px'}
      >
        <CardMainContent
          letterBadgeColor={letterBadgeColor}
          isClickable={isClickable}
          isReadOnly={isReadOnly}
          text={text}
          textSmall={textSmall}
          type={type}
          hasOnCardClick={hasOnCardClick}
          hasBadge={hasBadge}
          isSmall={isSmall}
          isAdmin={isAdmin}
          textSmallColor={textSmallColor}
        />

        <Flex alignItems="center">
          {isClickable && modalComponent && (
            <CardModal
              hasCloseIcon={hasCloseIcon}
              actionLabel={actionLabel}
              modalComponent={modalComponent}
            />
          )}
          {onActionButtonClick && (
            <Box>
              <StyledLabel onClick={onActionButtonClick}>
                {actionLabel}
              </StyledLabel>
            </Box>
          )}
          {type && hasBadge && (
            <StyledBadgeWrapper width="85px" height="32px" pr="12px">
              <Status text={badgeLabel[type]} {...getBadgeColorProps(type)} />
            </StyledBadgeWrapper>
          )}
        </Flex>
        {children && <Box>{children}</Box>}
      </Flex>
    </StyledNameCard>
  )
}

interface CardAddProps {
  label?: string
  modalComponent?: (fn: () => void) => React.ReactElement
  onClick?: () => void
}

export const CardAdd: React.FC<CardAddProps> = ({
  label,
  modalComponent,
  onClick,
}) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <StyledNameCard data-testid="card-add" isClickable>
      {modalComponent ? (
        <>
          <StyledCardAddWrapper
            data-testid="add-card-toggle"
            onClick={openModal}
          >
            <StyledClickableWrapper>
              <StyledIconWrapper
                width="40px"
                height="40px"
                justifyContent="center"
                alignItems="center"
              >
                <SvgIcon width="16" height="16" color={COLOR.BLUE_1000}>
                  <Plus />
                </SvgIcon>
              </StyledIconWrapper>
            </StyledClickableWrapper>
            <StyledTextLargeClickable data-testid="card-add-label">
              {label || component.card.addAnother}
            </StyledTextLargeClickable>
          </StyledCardAddWrapper>

          <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
            {modalComponent(closeModal)}
          </ModalBasic>
        </>
      ) : (
        <Flex
          data-testid="add-card-non-modal"
          alignItems="center"
          onClick={onClick}
        >
          <StyledClickableWrapper onClick={onClick}>
            <StyledIconWrapper
              width="40px"
              height="40px"
              justifyContent="center"
              alignItems="center"
            >
              <SvgIcon width="16" height="16" color={COLOR.BLUE_1000}>
                <Plus />
              </SvgIcon>
            </StyledIconWrapper>
          </StyledClickableWrapper>
          <StyledTextLargeClickable
            data-testid="card-add-label"
            onClick={onClick}
          >
            {label || component.card.addAnother}
          </StyledTextLargeClickable>
        </Flex>
      )}
    </StyledNameCard>
  )
}
