import React from 'react'

import { LogoCorner } from 'components/LogoCorner'

export const LogoCornerLinkExternal = ({
  href,
  'aria-label': ariaLabel,
}: {
  href: string
  ['aria-label']: string
}) => (
  <a href={href} rel="noopener noreferrer" aria-label={ariaLabel}>
    <LogoCorner />
  </a>
)
