import { useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'
import dayjs from 'dayjs'

import { Item } from '../../Item'
import { Title } from '../../ReviewCard.styled'
import { billingReviewTranslations } from './BillingReview.i18n'

export const BillingReview = () => {
  const billingStartPeriod = useMemo(
    () => dayjs().add(1, 'month').startOf('month').format('D MMM YYYY'),
    []
  )

  const t = useTranslations(billingReviewTranslations)

  return (
    <Box>
      <Title>{t('billingReviewTitle')}</Title>
      <Item label={t('billingStartPeriodLabel')} value={billingStartPeriod} />
      <Item label={t('amountToBeChargedLabel')} value="$9.00" />
    </Box>
  )
}
