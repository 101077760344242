import { useCallback, useEffect, useState } from 'react'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { useAccountMultiselectWithAllElement } from 'layouts/AccountLayout/hooks/useAccountMultiselectWithAllElement/useAccountMultiselectWithAllElement'
import { page } from 'translations'

import { useMobileFilterButtonActions } from '../hooks/useMobileFilterButtonActions/useMobileFilterButtonActions'
import { MultiSelectMobileFilter } from '../MultiSelectMobileFilter/MultiSelectMobileFilter'

export const AccountMobileFilterTransactionType = () => {
  const {
    transactionType: transactionTypesExcludingAll,
    setTransactionType,
    resetTransactionType,
    config,
  } = useAccountFilters()

  const [localTransactionType, setLocalTransactionType] = useState(
    transactionTypesExcludingAll
  )

  const resetLocalData = useCallback(() => {
    setLocalTransactionType(DEFAULT_FILTERS_VALUES.transactionType)
  }, [])

  const {
    listItems,
    selectedItems,
    onChange: updateSelectedTypes,
    onClose,
    areAllItemsSelected,
    setNoElementsSelected,
  } = useAccountMultiselectWithAllElement({
    data: localTransactionType || [],
    setData: setLocalTransactionType,
    dataItems: config.transactionType.items,
  })

  const { onApply, onReset, onCancel } = useMobileFilterButtonActions({
    onClose,
    globalData: transactionTypesExcludingAll,
    setGlobalData: setTransactionType,
    resetGlobalData: resetTransactionType,
    localData: localTransactionType,
    setLocalData: setLocalTransactionType,
    resetLocalData,
    setNoElementsSelected,
  })

  useEffect(() => {
    setLocalTransactionType(transactionTypesExcludingAll)
  }, [transactionTypesExcludingAll])

  return (
    <MultiSelectMobileFilter
      items={listItems}
      values={transactionTypesExcludingAll || []}
      selectedItems={selectedItems}
      onChange={updateSelectedTypes}
      onApply={onApply}
      onReset={onReset}
      onCancel={onCancel}
      areAllItemsSelected={areAllItemsSelected}
      filterName={page.accounts.transactionsList.filters.transactionType}
    />
  )
}
