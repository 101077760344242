import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Send Invoice Now?',
  description:
    'Are you sure you want to send {referenceNumber}? The Customer will receive the Invoice now.',
  successToast: '{referenceNumber} successfully sent.',
  errorToast: '{referenceNumber} could not be sent. Please try again.',
  sendNow: 'Send Now',
})
