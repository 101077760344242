import { useCallback } from 'react'
import {
  NavigateOptions,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat'

import { InternalTransferState } from '../../InternalTransfer.types'

type OpenInternalTransferModalOptions = Omit<NavigateOptions, 'state'>

export const useInternalTransferModal = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const openInternalTransferModal = useCallback(
    (
      state: InternalTransferState | null,
      options?: OpenInternalTransferModalOptions
    ) => {
      navigate(location.pathname, {
        state: { InternalTransferModal: state },
        ...options,
      })
    },
    [navigate, location.pathname]
  )

  const closeInternalTransferModal = useCallback(() => {
    navigate(location.pathname, {
      state: { InternalTransferModal: null },
    })
  }, [navigate, location.pathname])

  return { openInternalTransferModal, closeInternalTransferModal }
}
