import { useTranslations } from '@npco/utils-translations'
import { BodyLarge } from '@npco/zeller-design-system'

import { timeForASelfieDescriptionTranslations } from './TimeForASelfieDescription.i18n'
import * as styled from './TimeForASelfieDescription.styled'

type Props = {
  cta?: string
}

export const TimeForASelfieDescription = ({ cta }: Props) => {
  const t = useTranslations(timeForASelfieDescriptionTranslations)

  return (
    <>
      <BodyLarge>{t('description1')}</BodyLarge>
      <BodyLarge as="div">
        {t('description2')}
        <styled.Ol>
          <li>{t('listItem1')}</li>
          <li>{t('listItem2')}</li>
        </styled.Ol>
      </BodyLarge>
      <BodyLarge>{cta ?? t('description3')}</BodyLarge>
    </>
  )
}
