import { Heading, HeadingStyles, Typography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const DetailLabel = styled(Heading.H4).attrs({
  withStyles: HeadingStyles.H5,
})`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  white-space: nowrap;
`

export const DetailValue = styled(Typography).attrs({
  component: 'p',
  variant: 'body-base',
})`
  text-align: right;
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0 0 0 3rem;
  word-break: break-word;
`

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: base-line;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Abbreviation = styled.abbr`
  cursor: help;
  text-decoration: none;
`

export const SubLabel = styled.span`
  color: ${({ theme }) => theme.colors.GREY_550};
  font-weight: 400;
`

export const Dot = styled.span`
  padding: 0 3px 0 4px;
`
