import { gql } from '@apollo/client'

import { DateRange } from '../hooks/useSelectedDateRange'
import { IncomeAndExpenseDatapoints } from '../IncomeAndExpenseDatapoints'
import { IncomeAndExpenseLegend } from '../IncomeAndExpenseLegend'
import { IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment } from './IncomeAndExpenseTopSection.generated'
import * as styled from './IncomeAndExpenseTopSection.styled'

type IncomeAndExpenseTopSectionProps = {
  incomeAndExpenses: IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment[]
  isLoadingIncomeAndExpenses: boolean
  selectedDateRange: DateRange
  timezone: string | undefined
  isFetchingMore: boolean
}

export const IncomeAndExpenseTopSection = ({
  incomeAndExpenses,
  isLoadingIncomeAndExpenses,
  selectedDateRange,
  timezone,
  isFetchingMore,
}: IncomeAndExpenseTopSectionProps) => {
  return (
    <styled.IncomeAndExpenseTopSectionWrapper>
      <IncomeAndExpenseDatapoints
        incomeAndExpenses={incomeAndExpenses}
        isLoadingIncomeAndExpenses={isLoadingIncomeAndExpenses}
        selectedDateRange={selectedDateRange}
        timezone={timezone}
      />
      {!isFetchingMore && (
        <IncomeAndExpenseLegend incomeAndExpenses={incomeAndExpenses} />
      )}
    </styled.IncomeAndExpenseTopSectionWrapper>
  )
}

IncomeAndExpenseTopSection.fragments = {
  CashFlowPeriodicTotalAmount: gql`
    fragment IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment
      ...IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment
    }

    ${IncomeAndExpenseDatapoints.fragments.CashFlowPeriodicTotalAmount}
    ${IncomeAndExpenseLegend.fragments.CashFlowPeriodicTotalAmount}
  `,
}
