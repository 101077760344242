import { ButtonLinkIconLeft, DownloadIcon } from '@npco/zeller-design-system'

interface Props {
  onClick: () => void
  label: string
}

const Icon = () => <DownloadIcon />

export const ButtonExportTransactions = ({ onClick, label }: Props) => (
  <ButtonLinkIconLeft
    icon={Icon}
    onClick={onClick}
    data-testid="export-transactions-button"
  >
    {label}
  </ButtonLinkIconLeft>
)
