import { ReactNode } from 'react'

import {
  StyledDetailName,
  StyledDetailValue,
} from 'pages/Settings/SiteGeneral/SiteGeneral.styled'
import { StyledGeneralField } from 'pages/Settings/SiteGeneral/SiteGeneralField/SiteGeneralField.styled'

export const SiteGeneralField = ({
  label,
  value,
  isVisible = true,
}: {
  label: string
  value: ReactNode
  isVisible?: boolean
}) => {
  if (!value || !isVisible) {
    return null
  }
  return (
    <StyledGeneralField>
      <StyledDetailName>{label}</StyledDetailName>
      <StyledDetailValue>{value}</StyledDetailValue>
    </StyledGeneralField>
  )
}
