import { useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  rvDetailedSimBillingAccount,
  rvDetailedSimBillingAccountType,
  rvSimBillingAccount,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import { ThirdPartyBankAccount } from 'types/accounts'
import { Account } from 'types/settings'
import { ThirdPartyAccountDetailDrawer } from 'components/DepositAccountDetailDrawer/ThirdPartyAccountDetailDrawer'
import { EditThirdPartyAccountDetailsModal } from 'components/EditAccountDetails/EditThirdPartyAccoutDetailsModal'
import { AccountTileList } from 'components/Tile/AccountTileList/AccountTileList'
import { AccountTileRightControls } from 'components/Tile/AccountTileList/AccountTileRightControls'
import {
  ThirdPartyAccountTileDescription,
  ThirdPartyBankIcon,
} from 'components/Tile/ThirdPartyAccountTile/ThirdPartyAccountTile'

import { SimBillingExternalAccountAction } from '../SimBillingAccountDetailAction/SimBillingExternalBankAction'

export const SimBillingExternalBankTile = () => {
  const thirdPartyBankAccounts = useReactiveVar(rvThirdPartyAccounts)

  const simBillingAccount = useReactiveVar(rvSimBillingAccount)

  const detailedSimBillingAccount = useReactiveVar(rvDetailedSimBillingAccount)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const getBankIcon = (account: ThirdPartyBankAccount) => (
    <ThirdPartyBankIcon account={account} />
  )

  return (
    <>
      {isDrawerOpen && detailedSimBillingAccount && (
        <ThirdPartyAccountDetailDrawer
          account={detailedSimBillingAccount as ThirdPartyBankAccount}
          onClose={() => setIsDrawerOpen(false)}
          getIcon={(account) => getBankIcon(account)}
          renderHeaderAction={(account) => (
            <EditThirdPartyAccountDetailsModal
              account={account}
              onSubmit={(updatedAccount) => {
                rvDetailedSimBillingAccount(updatedAccount)
              }}
            />
          )}
          renderBottomAction={(account) => (
            <SimBillingExternalAccountAction
              isSelectedAccount={account.id === simBillingAccount?.id}
              account={account}
              redirectOnDeleteSuccess={() => setIsDrawerOpen(false)}
            />
          )}
        />
      )}
      <AccountTileList<ThirdPartyBankAccount>
        onSelect={(account) => {
          rvDetailedSimBillingAccountType(Account.EXTERNAL)
          rvDetailedSimBillingAccount(account)
          setIsDrawerOpen(true)
        }}
        renderRightControl={({ isActive, isDisabled }) => (
          <AccountTileRightControls
            isActive={isActive}
            isDisabled={isDisabled}
          />
        )}
        getIsActive={(account) => account.id === simBillingAccount?.id}
        bankAccounts={thirdPartyBankAccounts}
        getTileDescription={(account: ThirdPartyBankAccount) => (
          <ThirdPartyAccountTileDescription account={account} />
        )}
        getTileIcon={(account: ThirdPartyBankAccount) => (
          <ThirdPartyBankIcon account={account} />
        )}
        getTileTitle={(account: ThirdPartyBankAccount) =>
          account?.accountName || ''
        }
      />
    </>
  )
}
