import {
  Box,
  BUTTON_SIZE as DESIGN_BUTTON_SIZE,
  ButtonGhost,
  Flex,
} from '@npco/zeller-design-system'

import { BUTTON_SIZE } from 'types/common'
import { ButtonBack } from 'components/Buttons/ButtonBack'
import { SpinnerWrapped } from 'components/Spinner'
import { shared } from 'translations'

import { StyledPrimaryButton } from './SettingsFormLayout.styled'

interface LayoutProps {
  children: React.ReactNode
  dataTestId?: string
  onBackButtonClick?: () => void
  isBackButtonDisabled?: boolean
  onSubmitButtonClick?: () => void
  isSubmitButtonDisabled?: boolean
  submitButtonLabel?: string
  withPreviewButton?: boolean
  onPreviewButtonClick?: () => void
  isLoading?: boolean
  customHeight?: string
}

export const SettingsFormLayout = ({
  children,
  dataTestId,
  onBackButtonClick,
  isBackButtonDisabled,
  onSubmitButtonClick,
  isSubmitButtonDisabled,
  submitButtonLabel,
  withPreviewButton = false,
  onPreviewButtonClick,
  isLoading,
  customHeight = 'auto',
}: LayoutProps) => {
  return isLoading ? (
    <SpinnerWrapped variant="top" />
  ) : (
    <Flex
      data-testid={dataTestId}
      flexDirection="column"
      justifyContent={['space-between', 'flex-start']}
      // INFO: scrollbar padding for ios
      width="97%"
      height={customHeight}
      flex="1 0 auto"
      pb={32}
    >
      <Flex flexDirection="column">{children}</Flex>
      <Flex>
        <Box width={['100%', '12.5rem']} height={56}>
          <StyledPrimaryButton
            dataTestId="primary-button"
            fullWidth
            size={BUTTON_SIZE.LARGE}
            onClick={onSubmitButtonClick}
            disabled={isSubmitButtonDisabled}
          >
            {submitButtonLabel || shared.save}
          </StyledPrimaryButton>
        </Box>
        <Box pl={3} height={56} width={['100%', '12.5rem']}>
          <ButtonBack
            dataTestId="secondary-button"
            disabled={isBackButtonDisabled}
            fullWidth
            onClick={onBackButtonClick}
          />
        </Box>
        {withPreviewButton && (
          <Box pl={3} height={56}>
            <ButtonGhost
              size={DESIGN_BUTTON_SIZE.LARGE}
              onClick={onPreviewButtonClick}
            >
              {shared.preview}
            </ButtonGhost>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
