import { Flex, ModalForm } from '@npco/zeller-design-system'
import { InvoiceDiscountFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { Form, Formik } from 'formik'

import { translate } from 'utils/translations'

import { useInvoiceItemDiscount } from './hooks/useInvoiceItemDiscount'
import { InvoiceItemDiscountConfig } from './InvoiceItemDiscountConfig/InvoiceItemDiscountConfig'
import { validateForm } from './InvoiceItemDiscountModal.utils'
import { InvoiceItemDiscountTotals } from './InvoiceItemDiscountTotals/InvoiceItemDiscountTotals'
import { InvoiceItemDiscountValue } from './InvoiceItemDiscountValue/InvoiceItemDiscountValue'

export const translations = {
  cancel: translate('shared.cancel'),
  save: translate('shared.save'),
  title: translate(
    'page.invoice.formSections.items.itemsDiscountModalHeaderTitle'
  ),
}

export interface InvoiceItemDiscountModalProps {
  index: number
  onClose: () => void
}

export const InvoiceItemDiscountModal = ({
  index,
  onClose: handleOnClose,
}: InvoiceItemDiscountModalProps) => {
  const { handleSubmit, initialValues, invoiceValues, itemValues } =
    useInvoiceItemDiscount({
      onClose: handleOnClose,
      index,
    })

  return (
    <Formik<InvoiceDiscountFormFields>
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validate={validateForm}
    >
      {({
        handleSubmit: handleFormikSubmit,
        submitForm: handleFormikSubmitForm,
      }) => (
        <Form
          data-testid="invoicing-invoice-item-discount-form"
          onSubmit={handleFormikSubmit}
        >
          <ModalForm
            isOpen
            isPrimaryButtonDisabled={false}
            onCancel={handleOnClose}
            onClickPrimary={handleFormikSubmitForm}
            primaryButtonLabel={translations.save}
            secondaryButtonLabel={translations.cancel}
            testId="invoicing-invoice-item-discount-modal"
            title={translations.title}
          >
            <Flex flexDirection="column" paddingBottom="24px" paddingTop="12px">
              <InvoiceItemDiscountConfig />
              <InvoiceItemDiscountValue />
            </Flex>
            <InvoiceItemDiscountTotals
              invoiceValues={invoiceValues}
              itemValues={itemValues}
            />
          </ModalForm>
        </Form>
      )}
    </Formik>
  )
}
