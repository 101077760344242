import React from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Flex, Heading } from '@npco/zeller-design-system'

import * as styled from '../FeesAndPricing.styled'
import { getTotalFee } from '../FeesAndPricing.utils'
import { translations } from './MobileAppFeesAndPricing.i18n'

interface MobileAppFeesAndPricingProps {
  feePercentCpoc?: number | null
  feeFixedCpoc?: number | null
}

export const MobileAppFeesAndPricing = ({
  feePercentCpoc,
  feeFixedCpoc,
}: MobileAppFeesAndPricingProps) => {
  const t = useTranslations(translations)
  const localCardsFee = getTotalFee({
    feeFixed: feeFixedCpoc,
    feePercent: feePercentCpoc,
  })
  return (
    <Flex flexDirection="column" marginTop="20px">
      <Heading.H3>{t('mobileApp')}</Heading.H3>
      <styled.Description>{t('mobileAppDesc')}</styled.Description>
      <styled.Table data-testid="mobileAppTable">
        <styled.TableCell className="leftCell">
          {t('cardPresentPayments')}
        </styled.TableCell>
        <styled.TableCell className="rightCell ">
          {localCardsFee || '-'}
        </styled.TableCell>
      </styled.Table>
    </Flex>
  )
}
