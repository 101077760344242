import { forwardRef } from 'react'
import {
  InputAdaptiveField,
  TextInputFieldProps,
} from '@npco/zeller-design-system'

import { getInputAdaptivePropsFromTextInputProps } from './InputAdaptiveWrapper.utils'

// This InputAdaptiveFieldWrapper is deprecated - use InputAdaptiveField in new implementations
export const InputAdaptiveFieldWrapper = forwardRef<
  HTMLInputElement,
  TextInputFieldProps
>((props, ref) => {
  return (
    <InputAdaptiveField
      ref={ref}
      {...getInputAdaptivePropsFromTextInputProps(props)}
    />
  )
})
