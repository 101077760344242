import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Link Transaction To Contact',
  description:
    'Search for the Business or Person that this transaction relates to. This will link all transactions made via this card.',
  linkButton: 'Link',
  loadingTitle: 'Linking Contact',
  loadingDescription:
    'This should only take a few short moments. Please don’t close your browser.',
  contactSelectLabel: 'Person/Business',
  contactRequiredValidation: 'Person/Business is required.',
  contactUnselectedValidation:
    'An existing contact is required for linking. Select one from the dropdown or create new.',
})
