import { useCallback, useEffect } from 'react'

import { KEY_CODE } from 'const/keyboard'

interface Props {
  menuId: string
  onClose: () => void
  highlightFirstItem: () => void
  highlightLastItem: () => void
  shouldAutoFocusControl?: boolean
}

export const MultiSelectA11yHandler = ({
  menuId,
  highlightFirstItem,
  highlightLastItem,
  onClose,
  shouldAutoFocusControl,
}: Props) => {
  const getAccessibleControls = useCallback(() => {
    return (
      (document
        .getElementById(menuId)
        ?.querySelectorAll(
          'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
        ) as unknown as HTMLElement[]) ?? []
    )
  }, [menuId])

  useEffect(() => {
    if (shouldAutoFocusControl) {
      const accessibleControls = getAccessibleControls()

      if (accessibleControls.length !== 0) {
        accessibleControls[0].focus()
      }
    }
  }, [getAccessibleControls, shouldAutoFocusControl])

  useEffect(() => {
    const accessibleControls = getAccessibleControls()

    const handleMultiselectKeyDown = (event: KeyboardEvent) => {
      if (event.key === KEY_CODE.ESCAPE) {
        onClose()
        return
      }

      if (event.key === KEY_CODE.ARROW_DOWN) {
        event.preventDefault()
        highlightFirstItem()
        return
      }

      if (event.key === KEY_CODE.ARROW_UP) {
        event.preventDefault()
        highlightLastItem()
        return
      }

      if (event.key === KEY_CODE.TAB) {
        event.preventDefault()

        if (
          event.target === accessibleControls[accessibleControls.length - 1]
        ) {
          accessibleControls[0].focus()
          return
        }

        const targetIndex = [...accessibleControls].findIndex(
          (control) => control === event.target
        )

        accessibleControls[targetIndex + 1].focus()
      }
    }

    accessibleControls.forEach((control) => {
      control.addEventListener('keydown', handleMultiselectKeyDown)
    })

    return () => {
      accessibleControls.forEach((control) => {
        control.removeEventListener('keydown', handleMultiselectKeyDown)
      })
    }
  }, [getAccessibleControls, onClose, highlightFirstItem, highlightLastItem])
  return null
}
