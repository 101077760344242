import { useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  rvPersistedFiles,
  rvUploadedFiles,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/debitCardTransactions'
import { useUpdatedOutstandingTransactions } from 'features/Cards/CardSingle/hooks/useUpdatedOutstandingTransactions'

import {
  useUpdateDebitCardTransaction,
  useUpdateDebitCardTransactionRv,
} from 'hooks/banking'
import { FileWithId } from 'components/File'

import { useUpdateDebitCardTransactionAttachmentDownloadUrls } from './useUpdateDebitCardTransactionAttachmentDownloadUrls'

interface Props {
  transactionUuid: string
  fetchedImages: FileWithId[]
}

export const useUploadedImages = ({
  transactionUuid,
  fetchedImages,
}: Props) => {
  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()
  const { updateTransactionRv } = useUpdateDebitCardTransactionRv()
  const { cacheCurrentUpdatedOutstandingTransaction } =
    useUpdatedOutstandingTransactions(transactionUuid)
  const rvUploadedImages = useReactiveVar(rvUploadedFiles)
  const rvPersistedImages = useReactiveVar(rvPersistedFiles)
  const { removeAttachmentDownloadUrlByDocumentId } =
    useUpdateDebitCardTransactionAttachmentDownloadUrls()

  const uploadedImagesByTransactionUuid = useMemo(
    () => rvUploadedImages[transactionUuid] ?? [],
    [transactionUuid, rvUploadedImages]
  )
  const persistedImagesByTransactionUuid = useMemo(
    () => rvPersistedImages[transactionUuid] ?? [],
    [transactionUuid, rvPersistedImages]
  )

  const addImages = useCallback(
    (images: FileWithId[]) => {
      const attachmentImages = [...uploadedImagesByTransactionUuid, ...images]
      const attachments = [
        ...attachmentImages.map((image) => image.id),
        ...persistedImagesByTransactionUuid.map((image) => image.id),
      ]

      rvUploadedFiles({
        ...rvUploadedImages,
        [transactionUuid]: attachmentImages,
      })
      cacheCurrentUpdatedOutstandingTransaction({ update: { attachments } })
      updateTransactionRv({ transactionUuid, update: { attachments } })
      updateDebitCardTransaction(transactionUuid, { attachments })
    },
    [
      rvUploadedImages,
      persistedImagesByTransactionUuid,
      transactionUuid,
      uploadedImagesByTransactionUuid,
      updateDebitCardTransaction,
      updateTransactionRv,
      cacheCurrentUpdatedOutstandingTransaction,
    ]
  )

  const removeImage = useCallback(
    (image: FileWithId) => {
      const attachmentImages = uploadedImagesByTransactionUuid.filter(
        (imageWithId) => imageWithId.id !== image.id
      )
      const attachments = attachmentImages.map((image) => image.id)
      rvUploadedFiles({
        ...rvUploadedImages,
        [transactionUuid]: attachmentImages,
      })
      cacheCurrentUpdatedOutstandingTransaction({ update: { attachments } })
      updateDebitCardTransaction(transactionUuid, { attachments })
      updateTransactionRv({ transactionUuid, update: { attachments } })
      removeAttachmentDownloadUrlByDocumentId(transactionUuid, image.id)
    },
    [
      uploadedImagesByTransactionUuid,
      rvUploadedImages,
      transactionUuid,
      updateDebitCardTransaction,
      updateTransactionRv,
      cacheCurrentUpdatedOutstandingTransaction,
      removeAttachmentDownloadUrlByDocumentId,
    ]
  )

  const fetchedImagesIds = useMemo(
    () => fetchedImages.map(({ id }) => id),
    [fetchedImages]
  )

  const transactionCombinedImages = useMemo(
    () => [
      ...fetchedImages,
      ...uploadedImagesByTransactionUuid.filter(
        ({ id }) => !fetchedImagesIds.includes(id)
      ),
    ],
    [fetchedImages, fetchedImagesIds, uploadedImagesByTransactionUuid]
  )

  return {
    addImages,
    removeImage,
    transactionCombinedImages,
  }
}
