import { gql, type TypedDocumentNode } from '@apollo/client'

export type EmptyAccountsEntityFragment = {
  __typename?: 'Entity'
  canCreateAccount: boolean | null
}

export const EmptyAccountsEntityFragmentDoc = gql`
  fragment EmptyAccountsEntityFragment on Entity {
    canCreateAccount
  }
` as unknown as TypedDocumentNode<EmptyAccountsEntityFragment, undefined>
