import { translate } from 'utils/translations'

export const title = translate('page.upgradeAccountsCompleteModal.start.title')

export const description = translate(
  'page.upgradeAccountsCompleteModal.start.description'
)

export const primaryButtonLabel = translate(
  'page.upgradeAccountsCompleteModal.start.primaryButtonLabel'
)
