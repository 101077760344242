import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetCustomerDetails } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'
import {
  rvCurrentUserSites,
  rvSelectedUser,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { CustomerDetailsForm } from 'types/customers'
import {
  GetCustomerDetails as GetCustomerDetailsResponse,
  GetCustomerDetailsVariables,
} from 'types/gql-types/GetCustomerDetails'
import { SiteCache } from 'types/site'

export const useGetCustomer = (
  defaultValues: CustomerDetailsForm,
  isEditForm = false
) => {
  const entityUuid = useSelectedEntityUuid()
  const customerUuid = rvSelectedUser()
  const shouldSkipQuery = customerUuid === ''

  const [isInvitationPending, setIsInvitationPending] = useState<
    boolean | null
  >()
  const [initialValuesEdit, setInitialValuesEdit] = useState(defaultValues)
  const { loading: loadingGetCustomerDetails, data } = useQuery<
    GetCustomerDetailsResponse,
    GetCustomerDetailsVariables
  >(GetCustomerDetails, {
    variables: {
      entityUuid,
      customerUuid,
    },
    skip: shouldSkipQuery,
    onCompleted: (onCompletedData) => {
      if (isEditForm) {
        const customer = onCompletedData.getCustomer
        const isAdmin = customer.role === CustomerRole.ADMIN

        setInitialValuesEdit({
          email: customer.email as string,
          firstname: customer.firstname as string,
          middlename: customer.middlename as string,
          lastname: customer.lastname as string,
          phone: customer.phone as string,
          role: customer.role,
          registeringIndividual: Boolean(customer.registeringIndividual),
          sites: (customer.sites ?? []) as SiteCache[],
          kycStatus: customer.kycStatus,
          allowDiscountManagement:
            customer?.permissions?.allowDiscountManagement ?? isAdmin,
          allowItemManagement:
            customer?.permissions?.allowItemManagement ?? isAdmin,
          allowZellerInvoices:
            customer?.permissions?.allowZellerInvoices ?? isAdmin,
        })
        setIsInvitationPending(customer.isInvitationPending)
        rvCurrentUserSites(customer.sites as SiteCache[])
      }
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
    fetchPolicy: 'network-only',
  })

  return {
    customerUuid,
    loadingGetCustomerDetails,
    initialValuesEdit,
    setInitialValuesEdit,
    isInvitationPending,
    permissions: data?.getCustomer?.permissions,
  }
}
