import { useCallback, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { NavigateOptions } from 'react-router-dom-v5-compat'
import {
  DecisionModal,
  useModalState,
  zIndexMap,
} from '@npco/zeller-design-system'
import { Location } from 'history'

export interface RouteLeavingPromptGuardProps {
  dataTestId?: string
  hasCloseButton?: boolean
  isLoading: boolean
  message: string
  onCancel?: () => void
  onConfirm?: () => Promise<void>
  onNavigate: (path: string, state?: NavigateOptions) => void
  primaryButtonLabel: string
  secondaryButtonLabel: string
  shouldBlockNavigation: (location: Location) => boolean
  title: string
  when?: boolean
  noNavigationOnConfirm?: boolean
}

export const RouteLeavingPromptGuard = ({
  dataTestId = 'leaving-route-prompt-guard',
  hasCloseButton = true,
  isLoading,
  message,
  onCancel: handleCancel,
  onConfirm: handleConfirm,
  onNavigate: handleNavigate,
  primaryButtonLabel,
  secondaryButtonLabel,
  shouldBlockNavigation,
  title,
  when,
  noNavigationOnConfirm,
}: RouteLeavingPromptGuardProps) => {
  const { isModalOpen, setIsModalOpen } = useModalState()
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        setIsModalOpen(true)
        setLastLocation(nextLocation)

        return false
      }

      return true
    },
    [
      confirmedNavigation,
      setIsModalOpen,
      setLastLocation,
      shouldBlockNavigation,
    ]
  )

  const handleCancelNavigationClick = useCallback(async () => {
    handleCancel?.()

    setIsModalOpen(false)
    setConfirmedNavigation(true)
  }, [handleCancel, setIsModalOpen, setConfirmedNavigation])

  const handleConfirmNavigationClick = useCallback(async () => {
    await handleConfirm?.()

    setIsModalOpen(false)
    setConfirmedNavigation(!noNavigationOnConfirm)
  }, [handleConfirm, setIsModalOpen, noNavigationOnConfirm])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      handleNavigate(lastLocation.pathname, { state: lastLocation.state })
    }
  }, [confirmedNavigation, handleNavigate, lastLocation])

  return (
    <div data-testid={dataTestId}>
      <Prompt when={when} message={handleBlockedNavigation} />
      <DecisionModal
        hasCloseButton={hasCloseButton}
        isLoadingPrimary={isLoading}
        isOpen={isModalOpen}
        onCancel={closeModal}
        onClickPrimary={handleCancelNavigationClick}
        onClickSecondary={handleConfirmNavigationClick}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={secondaryButtonLabel}
        title={title}
        zIndex={zIndexMap.announcement}
      >
        {message}
      </DecisionModal>
    </div>
  )
}
