import {
  AVATAR_BADGE_SIZE,
  Flex,
  ListCardButton,
  SkeletonAvatar,
  SkeletonBasic,
} from '@npco/zeller-design-system'

interface AccountBalanceListSkeletonProps {
  isLoading: boolean
}
export const AccountBalanceListSkeleton = ({
  isLoading,
}: AccountBalanceListSkeletonProps) => {
  return (
    <Flex
      data-testid="account-balance-list-skeleton"
      width="100%"
      flexDirection="column"
      gridGap="4px"
    >
      <ListCardButton
        image={() => (
          <Flex height="44px" alignItems="center">
            <SkeletonAvatar
              size={AVATAR_BADGE_SIZE.SMALL}
              hasAnimationEffect={isLoading}
            />
          </Flex>
        )}
        title={
          <SkeletonBasic
            width="158px"
            height="12px"
            isRounded
            hasAnimationEffect={isLoading}
          />
        }
        readonly
      >
        <SkeletonBasic
          width="58px"
          height="12px"
          isRounded
          hasAnimationEffect={isLoading}
        />
      </ListCardButton>
      <ListCardButton
        image={() => (
          <Flex height="44px" alignItems="center">
            <SkeletonAvatar
              size={AVATAR_BADGE_SIZE.SMALL}
              hasAnimationEffect={isLoading}
            />
          </Flex>
        )}
        title={
          <SkeletonBasic
            width="106px"
            height="12px"
            isRounded
            hasAnimationEffect={isLoading}
          />
        }
        readonly
      >
        <SkeletonBasic
          width="46px"
          height="12px"
          isRounded
          hasAnimationEffect={isLoading}
        />
      </ListCardButton>
      <ListCardButton
        image={() => (
          <Flex height="44px" alignItems="center">
            <SkeletonAvatar
              size={AVATAR_BADGE_SIZE.SMALL}
              hasAnimationEffect={isLoading}
            />
          </Flex>
        )}
        title={
          <SkeletonBasic
            width="158px"
            height="12px"
            isRounded
            hasAnimationEffect={isLoading}
          />
        }
        readonly
      >
        <SkeletonBasic
          width="58px"
          height="12px"
          isRounded
          hasAnimationEffect={isLoading}
        />
      </ListCardButton>
    </Flex>
  )
}
