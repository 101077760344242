import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Flex, PillInput, PillInputProps } from '@npco/zeller-design-system'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'

import { translations } from './Search.i18n'

type SearchProps = {
  searchValue: string
  setSearchValue: (value: string) => void
  debounceDuration?: number
}

export const Search = ({
  searchValue,
  setSearchValue,
  debounceDuration = 300,
}: SearchProps) => {
  const t = useTranslations(translations)

  const [searchState, setSearchState] = useState(searchValue)
  const search$ = useMemo(() => new Subject<string>(), [])

  const handleOnChange: PillInputProps['onChange'] = useCallback(
    (event) => {
      setSearchState(event.target.value)
      search$.next(event.target.value)
    },
    [search$]
  )

  const handleOnClear = useCallback(() => {
    setSearchValue('')
    setSearchState('')
  }, [setSearchValue])

  useEffect(() => {
    const subscription = new Subscription()

    subscription.add(
      search$
        .pipe(
          debounceTime(debounceDuration),
          distinctUntilChanged(
            (previous, current) => previous.trim() === current.trim()
          )
        )
        .subscribe((nextValue) => {
          setSearchValue(nextValue)
        })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [debounceDuration, search$, setSearchValue])

  return (
    <Flex width="176px">
      <PillInput
        aria-label={t('ariaLabel')}
        value={searchState}
        onChange={handleOnChange}
        onClear={handleOnClear}
        placeholder={t('placeholder')}
        icon={<SearchIcon />}
      />
    </Flex>
  )
}
