import { Box, slideSideAnimationVariants } from '@npco/zeller-design-system'

import {
  combineValidators,
  validateMaxValue,
  validateMinValue,
  validateRequiredCustomMsg,
} from 'utils/formValidation'
import { TransferStateCondition as Condition } from 'pages/Transfer/states/TransferState/TransferStateCondition'
import {
  TransferGeneralState,
  TransferZellerState,
} from 'pages/Transfer/Transfer.stateMachine'
import { AmountField } from 'pages/Transfer/TransferFields/fields/AmountField'
import { ReferenceField } from 'pages/Transfer/TransferFields/fields/ReferenceField'
import { errorMessages } from 'translations'

import {
  REFERENCE_MAX_LENGTH,
  validateInternalTransferReference,
} from './TransferZellerFields.utils'

const MIN_AMOUNT = 0.01

export const TransferZellerFields = () => {
  const validateZellerTransfer = (fromBalance: number) =>
    combineValidators(
      validateRequiredCustomMsg(errorMessages.fieldRequired),
      validateMaxValue(fromBalance, errorMessages.transferBiggerThanBalance),
      validateMinValue(MIN_AMOUNT, errorMessages.transferTooSmall)
    )

  return (
    <Condition
      parent={TransferGeneralState.ZellerTransfer}
      allowedStates={[TransferZellerState.TransferDetails]}
      animationVariants={slideSideAnimationVariants}
    >
      <Box mb="2.5rem">
        <AmountField validate={validateZellerTransfer} />
        <ReferenceField
          maxLength={REFERENCE_MAX_LENGTH}
          validate={validateInternalTransferReference}
        />
      </Box>
    </Condition>
  )
}
