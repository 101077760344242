import styled from 'styled-components'

export const StyledCardLetterBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  transition: background-color 0.3s ease-out;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  color: ${({ theme }) => theme.colors.BLUE_1000};
`

export const StyledWrapper = styled.div`
  transition: background-color 0.3s ease-out;
  padding: 10px 12px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.BLUE_80};

    ${StyledCardLetterBadge} {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`
