import {
  rvEntityDetails,
  useSelectedEntityUuid,
} from '@npco/mp-utils-selected-entity'

import { useSubscription } from 'hooks/useSubscription'

import {
  DebitCardAccountUpdate,
  DebitCardAccountUpdateSubscriptionResponse,
  DebitCardAccountUpdateSubscriptionVariables,
} from './graphql/DebitCardAccountUpdate.generated'
import { useCacheAccount } from './useCacheAccount'
import { cacheNewAccount } from './useSubscribeToAccounts.utils'

interface UseSubscribeToAccountsProps {
  shouldCacheNewAccount?: boolean
  skip?: boolean
}

export const useSubscribeToAccounts = ({
  skip = false,
  shouldCacheNewAccount = false,
}: UseSubscribeToAccountsProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const { cacheAccountUpdate } = useCacheAccount()

  useSubscription<
    DebitCardAccountUpdateSubscriptionResponse,
    DebitCardAccountUpdateSubscriptionVariables
  >(DebitCardAccountUpdate, {
    onData: ({ client, data }) => {
      const updateData = data.data?.onDebitCardAccountUpdate
      if (!updateData) {
        return
      }

      cacheAccountUpdate({ ...updateData, __typename: 'DebitCardAccountV2' })

      if (shouldCacheNewAccount) {
        cacheNewAccount(
          client.cache,
          {
            ...updateData,
            __typename: 'DebitCardAccountV2',
          },
          entityUuid,
          'start-of-list'
        )
      }
    },
    variables: {
      entityUuid: rvEntityDetails()?.id,
    },
    skip,
  })
}
