import { COLOR } from '@npco/zeller-design-system'
import {
  SelectItemBasic,
  SelectSize,
  SelectTriggerRenderProps,
} from 'design-system/Components/Select/Select.types'
import { SelectTriggerAdaptive } from 'design-system/Components/Select/SelectTriggerAdaptive'
import { SelectTriggerContent } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerContent'
import { SelectTriggerLeftAdornment } from 'design-system/Components/Select/SelectTriggerAdaptive/SelectTriggerLeftAdornment'

import { StyledAccountName } from '../../TransferSelectInput/TransferSelectInput.styled'
import * as styled from './AccountSelectTrigger.styled'

interface Props {
  label: string
  placeholder?: string
  hasError: boolean
  renderProps: SelectTriggerRenderProps<SelectItemBasic>
  selectedItem?: SelectItemBasic
  isDisabled?: boolean
}

export const AccountSelectTrigger = ({
  label,
  placeholder,
  renderProps,
  hasError,
  selectedItem,
  isDisabled,
}: Props) => {
  const parsedName = selectedItem?.label.trim()

  const bgColor = COLOR.WHITE

  return (
    <SelectTriggerAdaptive
      {...renderProps}
      size={SelectSize.ExtraLarge}
      hasError={hasError}
      label={label}
      disabled={isDisabled}
      placeholder={placeholder}
      data-testid="to-account"
      renderLeftControls={
        selectedItem
          ? () => (
              <SelectTriggerLeftAdornment
                avatar={{
                  letter: selectedItem?.label,
                  bgColor,
                  color: COLOR.BLUE_1000,
                }}
                size={SelectSize.ExtraLarge}
              />
            )
          : undefined
      }
    >
      {selectedItem && (
        <SelectTriggerContent
          label={<StyledAccountName>{parsedName}</StyledAccountName>}
          subLabel1={
            <styled.Details>
              <styled.Property>{selectedItem?.subLabel1}</styled.Property>
              <styled.Property>{selectedItem?.subLabel2}</styled.Property>
            </styled.Details>
          }
        />
      )}
    </SelectTriggerAdaptive>
  )
}
