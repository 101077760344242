import { useTranslations } from '@npco/utils-translations'
import {
  Flex,
  RadioGroupFormik,
  RadioListCard,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { GetSite_getSite_surchargesTaxes as SurchargesTaxes } from 'types/gql-types/GetSite'
import { CardSurcharge, SettingsPayments } from 'types/settings'
import { getMaxCardSurchargeText } from 'pages/Settings/SitePayments/SitePayments.utils'

import { translations } from './Surcharge.i18n'
import * as styled from './Surcharge.styled'
import { SurchargeInput } from './SurchargeInput.fields'

export interface SurchargeOptionsProps {
  surchargesTaxes?: SurchargesTaxes | null
}

export const SurchargeOptions = ({
  surchargesTaxes,
}: SurchargeOptionsProps) => {
  const { values } = useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)

  return (
    <Flex flexDirection="column" gridGap="24px">
      <RadioGroupFormik name="surchargeType" isVerticalDisplay gap="8px">
        <RadioListCard
          id="surcharge-type-total-fees"
          title={t('totalFees')}
          value={CardSurcharge.TotalFees}
        />
        <RadioListCard
          id="surcharge-type-percentage"
          title={t('percentage')}
          value={CardSurcharge.Percentage}
        />
      </RadioGroupFormik>

      {!Number(values.surchargeType) && (
        <>
          <styled.Description style={{ margin: 0 }}>
            {t('description1')}&nbsp;
            {getMaxCardSurchargeText(surchargesTaxes?.feePercent)}
          </styled.Description>

          <div>
            <SurchargeInput surchargesTaxes={surchargesTaxes} />
          </div>
        </>
      )}
    </Flex>
  )
}
