import { ContactType } from '@npco/mp-gql-types'
import { InvoiceContactValues } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'

import {
  availableEntityTagsMap,
  MergeTag,
} from '../InvoiceDeliveryAccordion.constants'

export const makeContactNameByType = (
  payerContact: InvoiceContactValues | null,
  attentionToContact: InvoiceContactValues | null
) => {
  return (contactType: ContactType) => {
    if (payerContact?.contactType === contactType) {
      return payerContact.contactName
    }

    return attentionToContact?.contactType === contactType
      ? attentionToContact.contactName
      : ''
  }
}

export const updateMergeTagText = (
  entityKey: MergeTag,
  text: string | null
) => {
  const defaultText = availableEntityTagsMap[entityKey].text
  const defaultTooltip = availableEntityTagsMap[entityKey].tooltip

  return {
    ...availableEntityTagsMap[entityKey],
    hasValue: Boolean(text),
    text: text || defaultText,
    tooltip: text ? '' : defaultTooltip,
  }
}

interface GetUpdatedEntityTagProps {
  attentionToContact: InvoiceContactValues | null
  dueDate: string
  entityKey: MergeTag
  payerContact: InvoiceContactValues | null
  referenceNumber: string
  totalPrice: string
}

export const getUpdatedEntityTag = ({
  attentionToContact,
  dueDate,
  entityKey,
  payerContact,
  referenceNumber,
  totalPrice,
}: GetUpdatedEntityTagProps) => {
  const getContactNameByType = makeContactNameByType(
    payerContact,
    attentionToContact
  )

  switch (entityKey) {
    case MergeTag.Customer: {
      const personName = getContactNameByType(ContactType.PERSON)
      const businessName = getContactNameByType(ContactType.BUSINESS)

      return updateMergeTagText(entityKey, personName || businessName)
    }
    case MergeTag.BusinessName:
    case MergeTag.PersonName: {
      const contactType =
        entityKey === MergeTag.BusinessName
          ? ContactType.BUSINESS
          : ContactType.PERSON

      return updateMergeTagText(entityKey, getContactNameByType(contactType))
    }
    case MergeTag.InvoiceNumber: {
      return updateMergeTagText(entityKey, referenceNumber)
    }
    case MergeTag.Schedule: {
      return updateMergeTagText(entityKey, dueDate)
    }
    case MergeTag.TotalPrice: {
      return updateMergeTagText(entityKey, totalPrice)
    }
    default: {
      return { ...availableEntityTagsMap[entityKey] }
    }
  }
}
