import { WithTotalAmount } from '../../Dashboard.types'
import { getStartOfCurrentDay, getStartOfMonth } from '../../utils/timeUtils'
import { PerformancePeriod } from './DashboardPerformance.types'

export const getCurrentSalesFromDate = (
  performancePeriod: PerformancePeriod
) => {
  if (performancePeriod === PerformancePeriod.THIS_MONTH) {
    return getStartOfMonth()
  }

  return getStartOfCurrentDay()
}

export const getRoundedPercentage = (numerator: number, denominator: number) =>
  Math.round((numerator / denominator) * 100)

export const getHasNoSales = (transactionTotals: WithTotalAmount[]) => {
  return transactionTotals.every(
    (transactionTotal) => transactionTotal.totalAmount === 0
  )
}

export const getHasExceededPositiveSalesPreviousPeriod = (
  currentSales: number,
  previousSales: number
) => previousSales > 0 && currentSales > previousSales
