import { DayModifiers, Modifier } from 'react-day-picker'
import {
  DatePickerRange,
  PeriodButtons,
  RangeModifierExt,
  RenderContentProps,
} from '@npco/zeller-design-system'

import {
  lastMonthLabel,
  lastWeekLabel,
  thisMonthLabel,
  thisWeekLabel,
} from '../../utils/getPeriodButtonTexts'

interface DatePickerRangeProps {
  data: RangeModifierExt
  enteredTo: Date | undefined
  selectedPeriod: string
  handleResetClick: () => void
  handleDayClick: (day: Date) => void
  handleDayMouseEnter: (day: Date, modifiers?: DayModifiers | undefined) => void
  handleDayMouseLeave: () => void
  handleClearKeyDown: (e: React.KeyboardEvent) => void
  onPeriodChange: (periodData: RangeModifierExt, periodName: string) => void
  disabledDays?: Modifier | Modifier[]
  renderDay?: (date: Date, modifiers: DayModifiers) => React.ReactNode
  renderContent?: (renderContentProps: RenderContentProps) => React.ReactNode
}

export const DatePickerRangeForFilter: React.VFC<DatePickerRangeProps> = ({
  data,
  enteredTo,
  selectedPeriod,
  handleResetClick,
  handleDayClick,
  handleDayMouseEnter,
  handleDayMouseLeave,
  handleClearKeyDown,
  onPeriodChange,
  disabledDays,
  renderDay,
  renderContent,
}) => (
  <DatePickerRange
    data={data}
    enteredTo={enteredTo}
    selectedPeriod={selectedPeriod}
    handleResetClick={handleResetClick}
    handleDayClick={handleDayClick}
    handleDayMouseEnter={handleDayMouseEnter}
    handleDayMouseLeave={handleDayMouseLeave}
    onPeriodChange={onPeriodChange}
    disabledDays={disabledDays}
    renderDay={renderDay}
    renderBottomRow={({ setPeriod }) => (
      <PeriodButtons
        handleResetClick={handleResetClick}
        handleClearKeyDown={handleClearKeyDown}
        handleSetPeriod={setPeriod}
        selectedPeriod={selectedPeriod}
        thisWeekLabel={thisWeekLabel}
        lastWeekLabel={lastWeekLabel}
        thisMonthLabel={thisMonthLabel}
        lastMonthLabel={lastMonthLabel}
      />
    )}
    renderContent={renderContent}
  />
)
