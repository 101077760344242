import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type AccountTransactionsListDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  type: Types.DebitCardAccountType
  status: Types.DebitCardAccountStatus
}

export const AccountTransactionsListDebitCardAccountV2FragmentDoc = gql`
  fragment AccountTransactionsListDebitCardAccountV2Fragment on DebitCardAccountV2 {
    type
    status
  }
` as unknown as TypedDocumentNode<
  AccountTransactionsListDebitCardAccountV2Fragment,
  undefined
>
