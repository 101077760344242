import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Divider,
  Flex,
  HeadingStyles,
  ToggleForm,
} from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { SettingsPayments } from 'types/settings'
import { FormLine } from 'components/FormLine'

import { TipField } from './Tips.fields'
import { translations } from './Tips.i18n'
import * as styled from './Tips.styled'

export const Tips = () => {
  const { values, errors, initialValues, setFieldValue } =
    useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)

  const lastValidTipPercent1 = initialValues.tipPercent1
  const lastValidTipPercent2 = initialValues.tipPercent2
  const lastValidTipPercent3 = initialValues.tipPercent3

  useEffect(() => {
    if (!values.tipEnabled && errors.tipPercent1) {
      setFieldValue('tipPercent1', lastValidTipPercent1)
    }
    if (!values.tipEnabled && errors.tipPercent2) {
      setFieldValue('tipPercent2', lastValidTipPercent2)
    }
    if (!values.tipEnabled && errors.tipPercent3) {
      setFieldValue('tipPercent3', lastValidTipPercent3)
    }
  }, [
    errors.tipPercent1,
    errors.tipPercent2,
    errors.tipPercent3,
    lastValidTipPercent1,
    lastValidTipPercent2,
    lastValidTipPercent3,
    setFieldValue,
    values.tipEnabled,
  ])

  return (
    <>
      <Flex
        data-testid="tips"
        alignItems="center"
        justifyContent="space-between"
      >
        <styled.Title>{t('gstTitle')}</styled.Title>
        <div>
          <Field
            component={ToggleForm}
            name="gstEnabled"
            value={values.gstEnabled}
          />
        </div>
      </Flex>
      <styled.Description>{t('gstDescription')}</styled.Description>
      <Divider />
      <Flex alignItems="center" justifyContent="space-between">
        <styled.Title>{t('tippingTitle')}</styled.Title>
        <div>
          <Field
            component={ToggleForm}
            name="tipEnabled"
            value={values.tipEnabled}
          />
        </div>
      </Flex>
      <styled.Description>{t('tippingDescription')}</styled.Description>
      <styled.Subtitle withStyles={HeadingStyles.H5}>
        {t('percentageTippingValues')}
      </styled.Subtitle>
      <Box mb="40px" mt="16px">
        <FormLine direction="row" mxContainer="-8px" pxField="8px">
          <TipField label={t('tip1')} name="tipPercent1" />
          <TipField label={t('tip2')} name="tipPercent2" />
          <TipField label={t('tip3')} name="tipPercent3" />
        </FormLine>
      </Box>
      <Flex alignItems="center" justifyContent="space-between">
        <styled.Subtitle withStyles={HeadingStyles.H5}>
          {t('customTipAmountTitle')}
        </styled.Subtitle>
        <div>
          <Field
            component={ToggleForm}
            name="customTipAllowed"
            value={values.customTipAllowed}
          />
        </div>
      </Flex>
      <styled.Description>{t('customTipAmountDescription')}</styled.Description>
      <Divider />
    </>
  )
}
