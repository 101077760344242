import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { Box, BoxProps, CellSize } from '@npco/zeller-design-system'

import { useCardProgress } from 'hooks/useCardProgress'
import { useCardStatus } from 'hooks/useCardStatus'
import { translations } from 'translations/shared.i18n'

import { CardActivateButton } from '../CardActivateButton/CardActivateButton'
import { CardBalance } from '../CardBalanceCell/CardBalanceCell.styled'
import { CardBalanceCellBase } from '../CardBalanceCellBase/CardBalanceCellBase'
import { CardStatus } from '../CardStatus/CardStatus'
import { CardBalanceAvailableCellDebitCardV2Fragment } from './CardBalanceAvailableCell.generated'

export interface CardBalanceAvailableCellCellProps {
  card?: CardBalanceAvailableCellDebitCardV2Fragment
  cellId: string
  isLoading?: boolean
  cellSize?: CellSize
  isActivateButtonEnabled?: boolean
  isAvailableLabelEnabled?: boolean
  skeletonPropWidth: BoxProps['width']
}
export const CardBalanceAvailableCell = ({
  card,
  isLoading,
  cellId,
  cellSize,
  isActivateButtonEnabled,
  isAvailableLabelEnabled = true,
  skeletonPropWidth,
}: CardBalanceAvailableCellCellProps) => {
  const tShared = useTranslations(translations)

  const { availableAmount } = useCardProgress(card)
  const { isClosed, isLocked, isSuspended, isInactive } = useCardStatus(card)
  const isActive = !isInactive
  const shouldShowCardStatus = isClosed || isLocked || isSuspended

  const availableAmountWithMinimumZero =
    availableAmount < 0 ? 0 : availableAmount

  const cellProps = {
    cellId,
    skeletonPropWidth,
    isLoading,
    cellSize,
  }

  if (shouldShowCardStatus && card?.status) {
    return <CardStatus {...cellProps} status={card.status} />
  }

  return (
    <CardBalanceCellBase
      {...cellProps}
      content={
        isActive ? (
          <Box textAlign="right">
            <CardBalance>
              {currencyFormatter(availableAmountWithMinimumZero)}
            </CardBalance>
          </Box>
        ) : (
          isActivateButtonEnabled && <CardActivateButton />
        )
      }
      byline={
        isAvailableLabelEnabled &&
        isActive && <Box textAlign="right">{tShared('available')}</Box>
      }
    />
  )
}

CardBalanceAvailableCell.fragments = {
  DebitCardV2: gql`
    fragment CardBalanceAvailableCellDebitCardV2Fragment on DebitCardV2 {
      ...UseCardStatusDebitCardV2Fragment
      ...UseCardProgressDebitCardV2Fragment
      ...CardStatusDebitCardV2Fragment
    }

    ${useCardProgress.fragments.DebitCardV2}
    ${useCardStatus.fragments.DebitCardV2}
    ${CardStatus.fragments.DebitCardV2}
  `,
}
