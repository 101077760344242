import { useEffect } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'

import { useClientAccessToken } from './useClientAccessToken'

interface Pan {
  id: string
  color?: string
}

interface Expiry {
  id: string
  color?: string
}

interface Cvv {
  id: string
  color?: string
}

interface UseSensitiveCardInformationProps {
  isVisible: boolean
  onError?: () => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  pan: Pan
  expiry: Expiry
  cvv: Cvv
  debitCardUuid: string
}

export const useSensitiveCardInformation = ({
  isVisible,
  onError,
  setIsLoading,
  pan,
  expiry,
  cvv,
  debitCardUuid,
}: UseSensitiveCardInformationProps) => {
  const { clientAccessToken, isLoading, generateTokenError } =
    useClientAccessToken({
      skip: !isVisible,
      debitCardUuid,
    })

  useEffect(() => {
    if (!isVisible) {
      return
    }

    const handleError = (error: any) => {
      ErrorLogger.report(
        '[Banking] Marqeta sensitive data loading error',
        error
      )
      onError?.()
      setIsLoading(false)
    }

    if (isLoading) {
      setIsLoading(true)
      return
    }

    if (generateTokenError) {
      handleError(generateTokenError)
      return
    }

    if (clientAccessToken) {
      setIsLoading(true)
      try {
        window.marqeta.bootstrap({
          clientAccessToken,
          component: {
            showPan: {
              cardPan: {
                domId: pan.id,
                format: true,
                styles: {
                  span: {
                    color: pan.color,
                    'font-family': 'Arial',
                    opacity: '0.75',
                  },
                },
              },
              cardExp: {
                domId: expiry.id,
                format: true,
                styles: {
                  span: {
                    color: expiry.color,
                    'font-family': 'Arial',
                    opacity: '0.75',
                  },
                },
              },
              cardCvv: {
                domId: cvv.id,
                styles: {
                  span: {
                    color: cvv.color,
                    'font-family': 'Arial',
                    opacity: '0.75',
                  },
                },
              },
            },
          },
          callbackEvents: {
            onSuccess: () => {
              setIsLoading(false)
            },
            onFailure: handleError,
          },
        })
      } catch (error) {
        handleError(error)
      }
    }
  }, [
    isVisible,
    pan,
    cvv,
    expiry,
    clientAccessToken,
    setIsLoading,
    onError,
    isLoading,
    generateTokenError,
  ])
}
