import {
  DebitCardAccountTransactionStatusEnum,
  DebitCardTransactionStatusV2,
} from '@npco/mp-gql-types'
import { DebitCardTransaction } from 'features/Cards/CardSingle/CardSingle.types'
import { isEmpty } from 'lodash-es'

export const filterApprovedStatus = (
  transaction: Pick<DebitCardTransaction, 'status'>
) =>
  transaction.status === DebitCardTransactionStatusV2.APPROVED ||
  transaction.status === DebitCardAccountTransactionStatusEnum.APPROVED

export const filterByAttachments = (
  transaction: Pick<DebitCardTransaction, 'attachments'>
) => isEmpty(transaction.attachments)

interface FilterByOutstandingProps {
  isReceiptOutstandingFilterSettingsOn: boolean
  isNoteOutstandingFilterSettingsOn: boolean
  isCategoryOutstandingFilterSettingsOn: boolean
}

export const filterByOutstanding =
  ({
    isReceiptOutstandingFilterSettingsOn,
    isNoteOutstandingFilterSettingsOn,
    isCategoryOutstandingFilterSettingsOn,
  }: FilterByOutstandingProps) =>
  (
    transaction: Pick<DebitCardTransaction, 'note' | 'attachments' | 'category'>
  ) =>
    (isReceiptOutstandingFilterSettingsOn &&
      isEmpty(transaction.attachments)) ||
    (isNoteOutstandingFilterSettingsOn && isEmpty(transaction.note)) ||
    (isCategoryOutstandingFilterSettingsOn && isEmpty(transaction.category))
