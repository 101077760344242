import { forwardRef } from 'react'

import { SelectItemBasic, SelectItemRenderProps } from '../Select.types'
import { StyledSelectCompactItem } from './SelectCompact.styled'
import { SelectCompactItemContentWithIcon } from './SelectCompactItemContentWithIcon'

interface Item extends SelectItemBasic {
  icon: React.ReactNode
}

export const SelectCompactItemWithIcon = forwardRef<
  HTMLLIElement,
  SelectItemRenderProps<Item>
>((props, ref) => {
  const { item } = props
  return (
    <StyledSelectCompactItem {...props} ref={ref}>
      <SelectCompactItemContentWithIcon icon={item.icon} text={item.label} />
    </StyledSelectCompactItem>
  )
})
