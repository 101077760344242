import { useLocationState } from '@npco/utils-routing'
import { z } from 'zod'

const BlockedActionSchema = z.enum([
  'createAccount',
  'createCard',
  'transferOut',
])

export type BlockedAction = z.infer<typeof BlockedActionSchema>

const BlockedActionStateSchema = z.object({
  BlockedActionModal: z.object({
    blockedAction: BlockedActionSchema,
    hasBlurAndFade: z.optional(z.boolean()),
  }),
})

export type BlockedActionState = z.infer<typeof BlockedActionStateSchema>

export const useBlockedActionState = (): {
  state: BlockedActionState['BlockedActionModal'] | null
} => {
  const maybeState = useLocationState(BlockedActionStateSchema)

  const state = maybeState?.BlockedActionModal ?? null
  return { state }
}
