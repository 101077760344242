import { useCallback } from 'react'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { UNEXPECTED_ERROR } from '../../../../types/errors'
import { useGetDebitCardAccountsForTransferLazyQuery } from '../graphql/getDebitCardAccountsQuery.generated'

interface UseGetTransferAccountsLazyProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetTransferAccountsLazy = ({
  fetchPolicy = 'cache-first',
}: UseGetTransferAccountsLazyProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const [getAccountsLazyQuery, { loading: isLoadingAccounts, error }] =
    useGetDebitCardAccountsForTransferLazyQuery({
      fetchPolicy,
      errorPolicy: 'all',
      variables: { entityUuid },
    })

  const getAccounts = useCallback(async () => {
    try {
      const result = await getAccountsLazyQuery()

      if (!result.data?.getDebitCardAccountsV2) {
        return UNEXPECTED_ERROR
      }

      return {
        accounts: result.data.getDebitCardAccountsV2,
        entity: result.data.getEntity,
      }
    } catch (err) {
      return UNEXPECTED_ERROR
    }
  }, [getAccountsLazyQuery])

  return {
    getAccounts,
    isLoadingAccounts,
    error,
  }
}
