import { forwardRef, KeyboardEvent, useCallback } from 'react'
import { DocumentNode, gql } from '@apollo/client'
import { COLOR } from '@npco/zeller-design-system'

import { KEY_CODE } from 'const/keyboard'
import { AmountCell } from 'pages/DebitCardTransactions/AmountCell/AmountCell'
import { BadgeAndNameCell } from 'pages/DebitCardTransactions/BadgeAndNameCell/BadgeAndNameCell'
import { AccountTransactionTypeIcon } from 'components/AccountTransactionTypeIcon/AccountTransactionTypeIcon'

import { TransactionListRowBaseProps } from '../DebitCardTransactionsList.types'
import {
  StyledContainer,
  StyledDescriptionItem,
  StyledDescriptionText,
  StyledIconItem,
  StyledIconPlaceHolder,
  StyledPenIcon,
  StyledStatusIconItem,
  StyledTagIcon,
} from './ListGroupItemDebitCardTransactions.styled'
import {
  getDescription,
  hasValue,
} from './ListGroupItemDebitCardTransactions.utils'

export const ListGroupItemDebitCardTransactions = forwardRef<
  HTMLLIElement,
  TransactionListRowBaseProps
>(({ transaction, showDetails, isNew, showMobileStyle = false }, ref) => {
  const hasNotes = hasValue(transaction.note)
  const hasTags = hasValue(transaction.tags)

  const handleEnterKey = useCallback(
    (event: KeyboardEvent<HTMLLIElement>) => {
      if (event.key === KEY_CODE.ENTER) {
        showDetails(transaction)
      }
    },
    [transaction, showDetails]
  )

  return (
    <StyledContainer
      data-testid="account-transaction-row"
      onClick={() => showDetails(transaction)}
      onKeyUp={handleEnterKey}
      tabIndex={0}
      $isNew={isNew}
      ref={ref}
      $showMobileStyle={showMobileStyle}
    >
      <BadgeAndNameCell
        transaction={transaction}
        showMobileStyle={showMobileStyle}
      />
      <StyledStatusIconItem $showMobileStyle={showMobileStyle}>
        <AccountTransactionTypeIcon
          transaction={transaction}
          color={COLOR.BLACK}
        />
      </StyledStatusIconItem>
      <StyledDescriptionItem $showMobileStyle={showMobileStyle}>
        <StyledDescriptionText $showMobileStyle={showMobileStyle}>
          {getDescription(transaction)}
        </StyledDescriptionText>
      </StyledDescriptionItem>
      <StyledIconItem $showMobileStyle={showMobileStyle}>
        {hasNotes ? <StyledPenIcon /> : <StyledIconPlaceHolder />}
        {hasTags ? <StyledTagIcon /> : <StyledIconPlaceHolder />}
      </StyledIconItem>
      <AmountCell transaction={transaction} showMobileStyle={showMobileStyle} />
    </StyledContainer>
  )
}) as React.ForwardRefExoticComponent<
  TransactionListRowBaseProps & React.RefAttributes<HTMLLIElement>
> & {
  fragments:
    | { DebitCardTransactionV2: DocumentNode }
    | { DebitCardAccountTransaction: DocumentNode }
}

ListGroupItemDebitCardTransactions.fragments = {
  DebitCardTransactionV2: gql`
    fragment ListGroupItemDebitCardTransactionsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      amount {
        value
      }
      type
      note
      tags
      ...AmountCellDebitCardTransactionV2Fragment
      ...BadgeAndNameCellDebitCardTransactionV2Fragment
      ...GetDescriptionDebitCardTransactionV2Fragment
    }

    ${AmountCell.fragments.DebitCardTransactionV2}
    ${BadgeAndNameCell.fragments.DebitCardTransactionV2}
    ${getDescription.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment ListGroupItemDebitCardTransactionsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      amount {
        value
      }
      type
      note
      tags
      ...AmountCellDebitCardAccountTransactionFragment
      ...BadgeAndNameCellDebitCardAccountTransactionFragment
      ...GetDescriptionDebitCardAccountTransactionFragment
    }

    ${AmountCell.fragments.DebitCardAccountTransaction}
    ${BadgeAndNameCell.fragments.DebitCardAccountTransaction}
    ${getDescription.fragments.DebitCardAccountTransaction}
  `,
}
