import { BodyLargeTypography, Flex } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { PlaceholderSize } from 'components/Placeholders/Placeholders.types'
import { StyledWrapper } from 'components/Placeholders/SharedElements.styled'

interface WithVariant {
  variant?: PlaceholderSize
}

const getIconBoxStyleByVariant = (variant: PlaceholderSize | undefined) => {
  switch (variant) {
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
      return css`
        width: 5rem;
        height: 5rem;
      `
    case PlaceholderSize.Medium:
    case PlaceholderSize.Small:
      return css`
        width: 3.5rem;
        height: 3.5rem;
      `
    default:
      return css`
        width: 5rem;
        height: 5rem;
      `
  }
}
export const StyledErrorStateWrapper = styled(StyledWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  a {
    margin-top: 2rem;
    ${BodyLargeTypography};
    width: 100%;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      width: auto;
    }
  }

  svg {
    margin-right: 0.5rem;
  }
`
export const StyledIconBox = styled(Flex)`
  ${(props: WithVariant) => getIconBoxStyleByVariant(props.variant)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.RED_80};
`
