import {
  InputBasic,
  InputSelectComboboxInputRenderProps,
} from '@npco/zeller-design-system'

import { component } from 'translations'

import { StyledInputWrapper } from '../../LabelSelectCombobox.styled'

interface LabelSelectComboboxInputProps {
  renderProps: InputSelectComboboxInputRenderProps
}

export const LabelSelectComboboxInput = ({
  renderProps,
}: LabelSelectComboboxInputProps) => {
  return (
    <StyledInputWrapper>
      <InputBasic
        {...renderProps}
        autoFocus
        data-testid="combobox-input"
        maxLength={20}
        placeholder={component.labelSelectCombobox.addLabel}
      />
    </StyledInputWrapper>
  )
}
