import { Fragment, useCallback, useMemo, useState } from 'react'
import { flexRender, Row, Table as TanstackTable } from '@tanstack/react-table'

import * as styled from './Table.styled'
import { TableRow } from './TableRow'

interface TableProps<T> {
  onRowClick?: (row: Row<T>) => void
  onRowMouseEnter?: (row: Row<T>) => void
  onRowMouseLeave?: (row: Row<T>) => void
  table: TanstackTable<T>
  hideHeader?: boolean
  hasLastTrBorderBottom?: boolean
  hasBorderBottom?: boolean
  headBottomSpace?: string
  isDraggable?: boolean
}

export const Table = <T,>({
  onRowClick: handleRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  table,
  hideHeader = true,
  hasLastTrBorderBottom = true,
  hasBorderBottom = true,
  headBottomSpace,
  isDraggable = false,
}: TableProps<T>) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>()

  const isTableLoading = useMemo(
    () => Boolean(table.options.meta?.isLoading),
    [table.options.meta?.isLoading]
  )

  const onMouseEnter = useCallback(
    (row: Row<T>, rowIndex: number) => {
      setHoveredRowIndex(rowIndex)
      onRowMouseEnter?.(row)
    },
    [onRowMouseEnter]
  )

  const onMouseLeave = useCallback(
    (row: Row<T>) => {
      setHoveredRowIndex(undefined)
      onRowMouseLeave?.(row)
    },
    [onRowMouseLeave]
  )

  return (
    <styled.Table
      hasLastTrBorderBottom={hasLastTrBorderBottom}
      hasBorderBottom={hasBorderBottom}
      isRowClickable={Boolean(handleRowClick)}
    >
      <styled.THead headBottomSpace={headBottomSpace}>
        {!hideHeader &&
          table.getHeaderGroups().map((headerGroup, rowIndex) => (
            <styled.TableRowHeader
              data-testid={`header-row-${rowIndex}`}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Fragment>
              ))}
            </styled.TableRowHeader>
          ))}
      </styled.THead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <TableRow<T>
            key={row.id}
            hoveredRowIndex={hoveredRowIndex}
            isTableLoading={isTableLoading}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onRowClick={handleRowClick}
            row={row}
            rowIndex={rowIndex}
            isDraggable={isDraggable}
          />
        ))}
      </tbody>
    </styled.Table>
  )
}
