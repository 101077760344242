import { useTranslations } from '@npco/utils-translations'
import { Box, Flex } from '@npco/zeller-design-system'

import { getNonNullString } from 'utils/string'
import { LoadingSkeletonRow } from 'pages/Devices/LoadingSkeletonRow/LoadingSkeletonRow'
import { DetailsField } from 'components/DetailsField/DetailsField'

import { GetDeviceInformationQueryResponse } from '../../graphql/getDeviceInformation.generated'
import { translations } from '../NetworkDetails.i18n'

type EthernetDetailsProps = {
  isLoading: boolean
  networkDetails: GetDeviceInformationQueryResponse['getDeviceInformation']['network']
}

export const EthernetDetails = ({
  isLoading,
  networkDetails,
}: EthernetDetailsProps) => {
  const t = useTranslations(translations)
  const { ipAddress, macAddress } = networkDetails?.ethernetInfo ?? {}

  return (
    <Flex flexDirection="column">
      <Box pb="8px">
        <LoadingSkeletonRow
          data-testid="Ethernet"
          isLoading={isLoading}
          label={t('ethernet')}
          value={t('connected')}
        />
      </Box>

      <DetailsField
        isLoading={isLoading}
        value={getNonNullString(ipAddress, '-')}
        label={t('ipAddress')}
      />
      <DetailsField
        isLoading={isLoading}
        value={getNonNullString(macAddress, '-')}
        label={t('macAddress')}
      />
    </Flex>
  )
}
