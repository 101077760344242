import React, { useEffect, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  Flex,
  FormExtensionBasic,
  FormExtensionRenderContentProps,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import {
  validateAbnNotRequired,
  validateAcnNotRequired,
} from 'utils/formValidation'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import { translations } from '../Contacts.i18n'
import * as styled from '../Contacts.styled'
import { ContactFormData } from '../Contacts.types'
import { FieldProps } from '../People/PersonShared.fields'
import {
  FIELD_ADDITIONAL_EMAIL_BUSINESS,
  FIELD_ADDITIONAL_PHONE_BUSINESS,
  FIELD_EMAIL_BUSINESS,
  FIELD_PHONE_BUSINESS,
  FIELD_STREET_BUSINESS,
} from './BusinessShared.fields'
import { BusinessNameField } from './BusinessSimple.fields'

interface BusinessFieldsProps {
  existingContactName?: string | null
}

export const BusinessFields = React.memo(
  ({ existingContactName }: BusinessFieldsProps) => {
    const { values, validateForm } = useFormikContext<ContactFormData>()
    const t = useTranslations(translations)

    const WEBSITE: FieldProps = {
      itemId: 'website',
      itemName: t('website'),
      renderContent: ({ key }: FormExtensionRenderContentProps) => (
        <Box mb="24px" key={key}>
          <InputAdaptiveFieldWrapper
            name="business.website"
            placeholder={t('website')}
            maxLength={100}
          />
        </Box>
      ),
      shouldDisplayContentOnMount: (values: ContactFormData) =>
        Boolean(values.business.website),
    }

    const ABN_AND_ACN: FieldProps = {
      itemId: 'abn-and-acn',
      itemName: t('abnAndAcn'),
      renderContent: ({ key }: FormExtensionRenderContentProps) => (
        <styled.RowContainer key={key}>
          <Flex flexDirection="column" mb="24px">
            <InputAdaptiveFieldWrapper
              name="business.abn"
              placeholder={t('abn')}
              validate={validateAbnNotRequired}
            />
          </Flex>
          <Flex flexDirection="column">
            <InputAdaptiveFieldWrapper
              name="business.acn"
              placeholder={t('acn')}
              validate={validateAcnNotRequired}
            />
          </Flex>
        </styled.RowContainer>
      ),
      shouldDisplayContentOnMount: (values: ContactFormData) =>
        Boolean(values.business.abn) || Boolean(values.business.acn),
    }

    const FIELDS = [
      FIELD_STREET_BUSINESS,
      FIELD_PHONE_BUSINESS,
      FIELD_ADDITIONAL_PHONE_BUSINESS,
      FIELD_EMAIL_BUSINESS,
      FIELD_ADDITIONAL_EMAIL_BUSINESS,
      WEBSITE,
      ABN_AND_ACN,
    ]

    const BUTTON_ORDER = [
      FIELD_STREET_BUSINESS.itemId,
      FIELD_ADDITIONAL_PHONE_BUSINESS.itemId,
      FIELD_ADDITIONAL_EMAIL_BUSINESS.itemId,
      WEBSITE.itemId,
      ABN_AND_ACN.itemId,
    ]

    const extendedFormFields = useMemo(() => {
      return FIELDS.map((field: FieldProps) => ({
        ...field,
        initialDisplayedElementCount:
          field.initialDisplayedElementCount?.(values),
        shouldDisplayContentOnMount: field.shouldDisplayContentOnMount(values),
      }))
      // Run on mount to get initial displayed items for form extension
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      // This is needed to correctly update validation when email or phone updates
      // Otherwise Formik's default validation and field update timing shows incorrect error messages
      // eslint-disable-next-line no-console
      validateForm().catch(() => console.error('failed to validate form'))
    }, [
      validateForm,
      values.business.email?.email,
      values.business.phoneV2?.phone,
    ])

    return (
      <>
        <BusinessNameField existingContactName={existingContactName} />
        <styled.FormExtensionContainer>
          <FormExtensionBasic
            buttonOrder={BUTTON_ORDER}
            labelText={t('extendedFormFieldsLabel')}
          >
            {extendedFormFields}
          </FormExtensionBasic>
        </styled.FormExtensionContainer>
      </>
    )
  }
)
