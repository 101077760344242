import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { Divider, Flex } from '@npco/zeller-design-system'

import { SummaryRow } from 'components/SummaryRow/SummaryRow'
import { SummarySubRow } from 'components/SummarySubRow/SummarySubRow'

import {
  PerformancePeriod,
  TransactionTotal,
} from '../DashboardPerformance.types'
import { useTransactionSummary } from '../hooks/useTransactionSummary'
import { translations } from './PerformanceSummary.i18n'

interface Props {
  performancePeriod: PerformancePeriod
  performanceData: TransactionTotal[]
}

export const PerformanceSummary = ({
  performancePeriod,
  performanceData,
}: Props) => {
  const t = useTranslations(translations)
  const {
    collected,
    surcharges,
    tips,
    tax,
    refunds,
    total: subtotal,
    fees,
  } = useTransactionSummary({
    transactionTotals: performanceData,
    performancePeriod,
  })
  const sales = collected - (tips + surcharges)
  const total = subtotal - fees

  return (
    <Flex flexDirection="column" gap="12px">
      <SummaryRow label={t('sales')} value={currencyFormatter(sales)} />
      <SummarySubRow label={t('tip')} value={currencyFormatter(tips)} />
      <SummarySubRow
        label={t('surcharge')}
        value={currencyFormatter(surcharges)}
      />
      <Divider margin="0" />
      <SummaryRow
        label={t('totalProcessed')}
        value={currencyFormatter(collected)}
      />
      <SummarySubRow label={t('refunds')} value={currencyFormatter(-refunds)} />
      <Divider margin="0" />
      <SummaryRow label={t('subtotal')} value={currencyFormatter(subtotal)} />
      <SummarySubRow label={t('fees')} value={currencyFormatter(-fees)} />
      <Divider margin="0" />
      <SummaryRow label={t('total')} value={currencyFormatter(total)} />
      <SummarySubRow label={t('gst')} value={currencyFormatter(tax ?? 0)} />
    </Flex>
  )
}
