import { Box } from '@npco/zeller-design-system'

import { ReactComponent as AppStoreBadge } from 'assets/svg/setup-flow/app-store-badge.svg'
import { ReactComponent as ArrowMobileBadge } from 'assets/svg/setup-flow/arrow-mobile.svg'
import { ReactComponent as GooglePlayBadge } from 'assets/svg/setup-flow/google-play-badge.svg'

import { DownloadMessageMobile } from '../DownloadMessage/DownloadMessageMobile'
import { getAppLink, getIsAndriod } from './DownloadLinkMobile.utils'

export const DownloadLinkMobile = () => {
  return (
    <Box
      data-testid="download-link-mobile"
      py="16px"
      position="relative"
      onClick={() => window.open(getAppLink(), '_blank', 'noopener')}
    >
      {getIsAndriod() ? <GooglePlayBadge /> : <AppStoreBadge />}
      <Box position="absolute" top="-2px" left="100px">
        <ArrowMobileBadge />
      </Box>
      <Box maxWidth="116px" position="absolute" top="-2px" left="150px">
        <DownloadMessageMobile />
      </Box>
    </Box>
  )
}
