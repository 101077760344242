import styled from 'styled-components'

export const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0px;
  gap: 8px;
}
`

// The colour doesn't belong to MP design system. It's a one off for marketing.
// Therefore, hard coded it.
export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  & > p {
    margin: 5px 0;
    line-height: 1.2;
  }

  & > svg {
    margin-right: 11px;
  }
`
