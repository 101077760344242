import { ApolloError, gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, Message, MessageSize } from '@npco/zeller-design-system'

import { ExpandableCardTable } from 'components/CardsView/components/CardsTable/ExpandableCardTable'
import { ContactZellerSupport } from 'components/ContactZellerSupport'
import { ShowMore } from 'components/ShowMore'
import { translationsShared } from 'translations'

import { AssignedCardsListDebitCardV2Fragment } from './AssignedCardsList.generated'
import { translations } from './AssignedCardsList.i18n'

type Props = {
  cards: AssignedCardsListDebitCardV2Fragment[]
  error?: ApolloError
  isLoading: boolean
  refetch: () => void
}

export const AssignedCardsList = ({
  cards,
  error,
  isLoading,
  refetch,
}: Props) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  if (error && cards.length === 0 && !isLoading) {
    return (
      <Message
        title={t('errorTitle')}
        description={
          <Box textAlign="center">
            {t('errorDescription', {
              contactZellerSupport: <ContactZellerSupport />,
            })}
          </Box>
        }
        primaryButton={{ label: tShared('tryAgain'), onClick: refetch }}
        size={MessageSize.MEDIUM}
        align="center"
      />
    )
  }

  return (
    <ShowMore>
      <ExpandableCardTable isLoading={isLoading} cards={cards} />
    </ShowMore>
  )
}

AssignedCardsList.fragments = {
  DebitCardV2: gql`
    fragment AssignedCardsListDebitCardV2Fragment on DebitCardV2 {
      ...ExpandableCardTableDebitCardV2Fragment
    }

    ${ExpandableCardTable.fragments.DebitCardV2}
  `,
}
