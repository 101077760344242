import { AddressPage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { translate } from 'utils/translations'
import { CustomAddressPage } from 'pages/GlobalModals/components/DeliveryAddress/CustomAddressPage/CustomAddressPage'
import { useEntityAddressQuery } from 'pages/GlobalModals/components/DeliveryAddress/hooks/useEntityAddressQuery/useEntityAddressQuery'
import { PredefinedAddressPage } from 'pages/GlobalModals/components/DeliveryAddress/PredefinedAddressPage/PredefinedAddressPage'
import { Condition } from 'components/Condition/Condition'

import { useReportLostAndReplaceCardAddressForm } from './hooks/useReportLostAndReplaceCardAddressForm'
import { useReportLostAndReplaceCardAddressPageToggle } from './hooks/useReportLostAndReplaceCardAddressPageToggle'

interface ReportLostAndReplaceCardAddressFormProps {
  dataTestId?: string
}
export const ReportLostAndReplaceCardAddressForm = ({
  dataTestId,
}: ReportLostAndReplaceCardAddressFormProps) => {
  const {
    data: addressOptions,
    isLoading,
    error,
    retry,
  } = useEntityAddressQuery()

  const { submitCustomAddressPage, submitPredefinedAddressPage, isSubmitting } =
    useReportLostAndReplaceCardAddressForm()

  const {
    goToPredefinedAddressPage,
    goToCustomAddressPage,
    currentPage,
    initialAddress,
  } = useReportLostAndReplaceCardAddressPageToggle()

  return (
    <>
      <Condition shouldShow={currentPage === AddressPage.CustomAddress}>
        <CustomAddressPage
          dataTestId={dataTestId}
          initialAddress={initialAddress}
          submitButtonLabel={translate('shared.confirm')}
          isSubmitting={isSubmitting}
          onSubmit={submitCustomAddressPage}
          goToPredefinedAddressPage={goToPredefinedAddressPage}
        />
      </Condition>
      <Condition shouldShow={currentPage === AddressPage.PredefinedAddress}>
        <PredefinedAddressPage
          dataTestId={dataTestId}
          initialAddress={initialAddress}
          submitButtonLabel={translate('shared.confirm')}
          isSubmitting={isSubmitting}
          isLoading={isLoading}
          error={error}
          retry={retry}
          addressOptions={addressOptions}
          onSubmit={submitPredefinedAddressPage}
          goToCustomAddressPage={goToCustomAddressPage}
        />
      </Condition>
    </>
  )
}
