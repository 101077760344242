import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import {
  COLOR,
  PadlockIcon as SuspendIcon,
  Status,
  TickIcon as ActiveIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as DeclinedIcon } from 'assets/svg/decline.svg'
import { translate } from 'utils/translations'
import { DebitCardAccountStatusVariants } from 'components/StatusBadge/StatusBadge.types'

const variants: DebitCardAccountStatusVariants = {
  [DebitCardAccountStatus.ACTIVE]: {
    icon: <ActiveIcon color={COLOR.GREEN_1600} />,
    text: translate('page.settings.accountDetails.accountStatusValue.active'),
    color: COLOR.GREEN_1600,
  },
  [DebitCardAccountStatus.CLOSED]: {
    icon: <DeclinedIcon />,
    text: translate('page.settings.accountDetails.accountStatusValue.closed'),
    color: COLOR.BLACK_900,
  },
  [DebitCardAccountStatus.SUSPENDED]: {
    icon: <SuspendIcon />,
    text: translate(
      'page.settings.accountDetails.accountStatusValue.suspended'
    ),
    color: COLOR.YELLOW_1000,
  },
}

interface StatusBadgeProps {
  status: DebitCardAccountStatus
}

export const AccountStatusBadge = ({ status }: StatusBadgeProps) => {
  if (!variants[status]) {
    return null
  }

  const { icon, text, color } = variants[status]

  return <Status color={color} icon={icon} text={text} />
}
