import Truncate from 'react-truncate'
import { gql } from '@apollo/client'

import { getBadgeOptions, getTitle } from '../DebitCardTransactions.utils'
import {
  BadgeAndNameCellDebitCardAccountTransactionFragment,
  BadgeAndNameCellDebitCardTransactionV2Fragment,
} from './BadgeAndNameCell.generated'
import {
  StyledBadgeItem,
  StyledCompanyNameItem,
} from './BadgeAndNameCell.styled'

interface BadgeAndNameCellProps {
  transaction:
    | BadgeAndNameCellDebitCardTransactionV2Fragment
    | BadgeAndNameCellDebitCardAccountTransactionFragment
  hideAvatarOnMobile?: boolean
  showMobileStyle?: boolean
}

export const BadgeAndNameCell = ({
  transaction,
  hideAvatarOnMobile = true,
  showMobileStyle = false,
}: BadgeAndNameCellProps) => {
  const badgeOptions = getBadgeOptions(transaction)
  const merchantName = getTitle(transaction)

  return (
    <>
      <StyledBadgeItem
        $hideAvatarOnMobile={hideAvatarOnMobile}
        $showMobileStyle={showMobileStyle}
      >
        {badgeOptions}
      </StyledBadgeItem>
      <StyledCompanyNameItem $showMobileStyle={showMobileStyle}>
        <Truncate lines={1}>{merchantName}</Truncate>
      </StyledCompanyNameItem>
    </>
  )
}

BadgeAndNameCell.fragments = {
  DebitCardTransactionV2: gql`
    fragment BadgeAndNameCellDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetBadgeOptionsDebitCardTransactionV2Fragment
      ...GetTitleDebitCardTransactionV2Fragment
    }

    ${getBadgeOptions.fragments.DebitCardTransactionV2}
    ${getTitle.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment BadgeAndNameCellDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetBadgeOptionsDebitCardAccountTransactionFragment
      ...GetTitleDebitCardAccountTransactionFragment
    }

    ${getBadgeOptions.fragments.DebitCardAccountTransaction}
    ${getTitle.fragments.DebitCardAccountTransaction}
  `,
}
