import { Box, COLOR, Flex } from '@npco/zeller-design-system'

interface CardSummaryProps {
  cardDisplay: React.ReactNode
  cardDetails: React.ReactNode
  backgroundColour?: string
}

export const CardSummary = ({
  cardDisplay,
  cardDetails,
  backgroundColour,
}: CardSummaryProps) => {
  return (
    <Flex
      pb={['24px', '40px']}
      pt={['24px', '40px']}
      px="21px"
      mx={{ _: '-10px', XS: '0px' }}
      backgroundColor={backgroundColour || COLOR.GREY_30}
      borderRadius="8px"
      justifyContent="center"
    >
      <Flex
        flexDirection={{ _: 'column', SM: 'row' }}
        justifyContent={['auto', 'space-between']}
        width={{ _: 'auto', SM: '100%' }}
        maxWidth="880px"
      >
        <Box position="relative" mr={['0', '16px']}>
          {cardDisplay}
        </Box>
        <Flex
          width="100%"
          maxWidth="500px"
          flexDirection="column"
          justifyContent="space-between"
          style={{ minWidth: '0' }}
        >
          {cardDetails}
        </Flex>
      </Flex>
    </Flex>
  )
}
