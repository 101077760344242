import { useNavigate, useParams } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Box, Heading } from '@npco/zeller-design-system'

import { useGetCustomerBasicInfo } from 'hooks/useGetCustomerBasicInfo/useGetCustomerBasicInfo'
import { ROOT, ROUTE_PARAM_NAME } from 'const/routes'
import { badgeLabel } from 'utils/badge'
import { translate } from 'utils/translations'
import { Card } from 'components/Cards/Card'
import { SpinnerWrapped } from 'components/Spinner'
import { UserDrawer } from 'components/UserDetails/UserDrawer'

import { useGetZellerInvoiceSettings } from '../hooks/useGetZellerInvoiceSettings'

export const SettingsUsersDeps = {
  useGetZellerInvoiceSettings,
  useGetCustomerBasicInfo,
  useParams,
  useNavigate,
}

export const SettingsUsers = () => {
  const {
    useGetZellerInvoiceSettings,
    useGetCustomerBasicInfo,
    useParams,
    useNavigate,
  } = SettingsUsersDeps

  const { invoiceSettings, isLoading } = useGetZellerInvoiceSettings()

  const navigate = useNavigate()
  const { invoiceSettingsUserId: customerUuid = '' } = useParams<{
    [ROUTE_PARAM_NAME.PORTAL_INVOICE_SETTINGS_USER_ID]: string
  }>()
  const shortEntityId = useSelectedShortEntityUuid()
  const { customer, isLoading: isLoadingCustomer } =
    useGetCustomerBasicInfo(customerUuid)

  if (isLoading || !invoiceSettings) {
    return <SpinnerWrapped variant="top" />
  }

  const onUserDetailsOpen = (userId: string) => {
    navigate(
      ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.SETTINGS.USERS.USER(
        userId
      ).path
    )
  }

  return (
    <>
      <Box mb="16px">
        <Heading.H3>
          {translate('page.settings.users.assignedUsers')}
        </Heading.H3>
      </Box>
      {invoiceSettings?.customers?.map((user) => (
        <Card
          key={user.id}
          type={user.role as string}
          hasBadge={false}
          text={`${user.firstname} ${user.lastname}`}
          textSmall={user?.role && badgeLabel[user.role]}
          onCardClick={() => onUserDetailsOpen(user.id)}
          isSmall
        />
      ))}
      <UserDrawer
        isOpen={Boolean(customerUuid)}
        onClose={() =>
          navigate(
            ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.SETTINGS.USERS.path
          )
        }
        selectedUser={customer}
        canEditUser={false}
        isLoading={isLoadingCustomer}
      />
    </>
  )
}
