import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { type BlockedActionState } from '../useBlockedActionState/useBlockedActionState'

export const useGoToBlockedAction = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToBlockedAction = useCallback(
    (state: BlockedActionState['BlockedActionModal'] | null, path?: string) => {
      navigate(path ?? location.pathname, {
        state: {
          BlockedActionModal: state,
        },
      })
    },
    [navigate, location.pathname]
  )

  return { goToBlockedAction }
}
