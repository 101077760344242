import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled.div<{ $expanded: boolean }>`
  margin-top: ${({ $expanded }) => ($expanded ? '8px' : '0px')};
  margin-bottom: ${({ $expanded }) => ($expanded ? '8px' : '0px')};
  transition: margin-top 0.2s;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-top: ${({ $expanded }) => ($expanded ? '12px' : '0px')};
    margin-bottom: ${({ $expanded }) => ($expanded ? '24px' : '0px')};
  }
`
