import { useRef } from 'react'

export const useRestartableTimer = (callback: () => void, duration: number) => {
  const idRef = useRef<ReturnType<typeof setTimeout>>()

  const timer = {
    cancel: () => {
      clearTimeout(idRef.current)
      idRef.current = undefined
    },
    restart: () => {
      clearTimeout(idRef.current)
      idRef.current = setTimeout(() => {
        callback()
      }, duration)
    },
  }

  return timer
}
