import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { GetContactNameContactFragmentDoc } from '@npco/mp-utils-contacts-format'

export type UseGetContactPieChartDataCashFlowContactNetAmountFragment = {
  __typename?: 'CashFlowContactNetAmount'
  contact: {
    __typename: 'Contact'
    id: string
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    lastName: string | null
  } | null
  total: { __typename?: 'Money'; value: string }
}

export const UseGetContactPieChartDataCashFlowContactNetAmountFragmentDoc = gql`
  fragment UseGetContactPieChartDataCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    contact {
      __typename
      id
      ...GetContactNameContactFragment
    }
    total {
      value
    }
  }
  ${GetContactNameContactFragmentDoc}
` as unknown as TypedDocumentNode<
  UseGetContactPieChartDataCashFlowContactNetAmountFragment,
  undefined
>
