import { useTranslations } from '@npco/utils-translations'

import { ContactZellerSupport } from 'components/ContactZellerSupport'

import { ErrorDisplay } from '../../ErrorDisplay'
import { translations } from './AccountBalanceListError.i18n'

interface AccountBalanceListErrorProps {
  retry?: () => void
}

export const AccountBalanceListError = ({
  retry,
}: AccountBalanceListErrorProps) => {
  const t = useTranslations(translations)

  return (
    <ErrorDisplay
      title={t('title')}
      description={t('description', {
        contactZellerSupport: <ContactZellerSupport />,
      })}
      retry={retry}
    />
  )
}
