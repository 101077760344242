import { useState } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink, Divider } from '@npco/zeller-design-system'

import { getDevicesText } from 'utils/devices/devices.utils'
import { Card } from 'components/Cards/Card'
import { YourCardListTable } from 'components/CardsView/components/CardsTable/YourCardListTable/YourCardListTable'

import {
  UserRemoveModalDebitCardV2Fragment,
  UserRemoveModalSiteFragment,
} from './UserRemoveModal.generated'
import { userRemoveModalTranslation } from './UserRemoveModal.i18n'
import {
  ModalWrapper,
  SectionWrapper,
  StyledDecisionModal,
  StyledModalDescription,
  StyledModalHeader,
  StyledSectionHeader,
} from './UserRemoveModal.styled'

export interface UserRemoveModalProps {
  associatedSites: UserRemoveModalSiteFragment[]
  associatedCards: UserRemoveModalDebitCardV2Fragment[]
  isOpen: boolean
  onCancel: () => void
  onRemove: () => void
  userName: string
  isLoadingPrimary: boolean
  isDisabledPrimary: boolean
}

export const UserRemoveModal = ({
  associatedSites,
  associatedCards,
  isOpen,
  onCancel,
  onRemove,
  userName,
  isLoadingPrimary,
  isDisabledPrimary,
}: UserRemoveModalProps) => {
  const [showMoreSites, setShowMoreSites] = useState(false)
  const [showMoreCards, setShowMoreCards] = useState(false)
  const t = useTranslations(userRemoveModalTranslation)

  const sitesToMap = showMoreSites
    ? associatedSites
    : associatedSites.slice(0, 3)

  const modalHeight = '662px'

  return (
    <StyledDecisionModal
      isOpen={isOpen}
      onCancel={onCancel}
      primaryButtonLabel={t('removeButton')}
      onClickPrimary={onRemove}
      secondaryButtonLabel={t('cancelButton')}
      onClickSecondary={onCancel}
      isLoadingPrimary={isLoadingPrimary}
      isPrimaryButtonDisabled={isDisabledPrimary}
      isSecondaryButtonDisabled={isLoadingPrimary}
      bodyClassName="remove-user-modal-body"
      footerClassName="remove-user-modal-footer"
      contentStyle={{
        maxHeight: modalHeight,
      }}
    >
      <ModalWrapper>
        <SectionWrapper gap="16px">
          <StyledModalHeader>
            {t('title', { name: userName })}
          </StyledModalHeader>
          <StyledModalDescription>
            {associatedSites.length === 0 && associatedCards.length === 0
              ? t('emptySiteAndCardDescription', { name: userName })
              : t('description', { name: userName })}
          </StyledModalDescription>
        </SectionWrapper>
        {associatedSites && associatedSites.length > 0 && (
          <SectionWrapper direction="row" m="12px 0 8px 0">
            <StyledSectionHeader>
              {t('associatedSites')} ({associatedSites.length})
            </StyledSectionHeader>
            <ButtonLink onClick={() => setShowMoreSites((prev) => !prev)}>
              {associatedSites.length > 3 &&
                (showMoreSites ? t('showLess') : t('showAll'))}
            </ButtonLink>
          </SectionWrapper>
        )}
        {sitesToMap.map((site) => (
          <>
            <Card
              key={site?.id}
              text={site?.name}
              textSmall={getDevicesText(site?.devices?.length)}
              isReadOnly
              isClickable={false}
              isSmall
            />
            {sitesToMap.indexOf(site) !== sitesToMap.length - 1 && (
              <Divider margin="0" key={site.id} />
            )}
          </>
        ))}
        {associatedCards && associatedCards.length > 0 && (
          <SectionWrapper direction="row" m="12px 0 8px 0">
            <StyledSectionHeader>
              {t('associatedCards')} ({associatedCards.length})
            </StyledSectionHeader>
            <ButtonLink onClick={() => setShowMoreCards((prev) => !prev)}>
              {associatedCards.length > 3 &&
                (showMoreCards ? t('showLess') : t('showAll'))}
            </ButtonLink>
          </SectionWrapper>
        )}
        <YourCardListTable
          isLoading={false}
          hasDivider
          isReadOnly
          cards={showMoreCards ? associatedCards : associatedCards.slice(0, 3)}
        />
      </ModalWrapper>
    </StyledDecisionModal>
  )
}

UserRemoveModal.fragments = {
  DebitCardV2: gql`
    fragment UserRemoveModalDebitCardV2Fragment on DebitCardV2 {
      ...YourCardListTableDebitCardV2Fragment
    }
    ${YourCardListTable.fragments.DebitCardV2}
  `,

  Site: gql`
    fragment UserRemoveModalSiteFragment on Site {
      id
      name
      devices {
        id
      }
    }
  `,
}
