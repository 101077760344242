import { useEffect } from 'react'
import { FetchResult } from '@apollo/client'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { rvStepUpAuthProfileSettingsObject } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/auth'
import { rvUpdatedEmailNotConfirmed } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import { ProfileSettingsAppState } from 'types/auth'
import { RequestEmailChange } from 'types/gql-types/RequestEmailChange'
import { requestEmailChangeHandler } from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalEdit.utils'

interface MFAProfilePersonalProps {
  refetch: () => Promise<ApolloQueryResult<any>>
  requestEmailChange: (
    newEmail: string
  ) => Promise<FetchResult<RequestEmailChange>>
  openModal: () => void
  redirect?: () => void
}

export const useMFAProfilePersonal = ({
  refetch,
  requestEmailChange,
  openModal,
  redirect,
}: MFAProfilePersonalProps) => {
  const profileSettingsMfaObject = rvStepUpAuthProfileSettingsObject()

  const resendEmailChangeRequest = () => {
    return refetch()
      .then(() => requestEmailChangeHandler(requestEmailChange))
      .catch(() => openModal())
  }

  useEffect(() => {
    if (
      profileSettingsMfaObject.shouldCallApiAgain &&
      'input' in profileSettingsMfaObject.variables
    ) {
      rvUpdatedEmailNotConfirmed(
        profileSettingsMfaObject.variables.input?.newEmail
      )
      resendEmailChangeRequest()
      rvStepUpAuthProfileSettingsObject({} as ProfileSettingsAppState)
      redirect?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { resendEmailChangeRequest }
}
