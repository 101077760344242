import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetSetupFlowDebitCardsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetSetupFlowDebitCardsQueryResponse = {
  __typename?: 'Query'
  getDebitCardsV2: {
    __typename?: 'DebitCardV2Connection'
    cards: Array<{
      __typename?: 'DebitCardV2'
      id: string
      customer: {
        __typename?: 'Customer'
        id: string
        entityUuid: string | null
        firstname: string | null
        lastname: string | null
      } | null
    } | null>
  }
}

export const GetSetupFlowDebitCards = gql`
  query GetSetupFlowDebitCards($entityUuid: ID!) {
    getDebitCardsV2(limit: 50, entityUuid: $entityUuid) {
      cards {
        id
        customer {
          id
          entityUuid
          firstname
          lastname
        }
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetSetupFlowDebitCardsQueryResponse,
  GetSetupFlowDebitCardsQueryVariables
>

/**
 * __useGetSetupFlowDebitCardsQuery__
 *
 * To run a query within a React component, call `useGetSetupFlowDebitCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSetupFlowDebitCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSetupFlowDebitCardsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetSetupFlowDebitCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSetupFlowDebitCardsQueryResponse,
    GetSetupFlowDebitCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSetupFlowDebitCardsQueryResponse,
    GetSetupFlowDebitCardsQueryVariables
  >(GetSetupFlowDebitCards, options)
}
export function useGetSetupFlowDebitCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSetupFlowDebitCardsQueryResponse,
    GetSetupFlowDebitCardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSetupFlowDebitCardsQueryResponse,
    GetSetupFlowDebitCardsQueryVariables
  >(GetSetupFlowDebitCards, options)
}
export type GetSetupFlowDebitCardsQueryHookResult = ReturnType<
  typeof useGetSetupFlowDebitCardsQuery
>
export type GetSetupFlowDebitCardsLazyQueryHookResult = ReturnType<
  typeof useGetSetupFlowDebitCardsLazyQuery
>
export type GetSetupFlowDebitCardsQueryResult = Apollo.QueryResult<
  GetSetupFlowDebitCardsQueryResponse,
  GetSetupFlowDebitCardsQueryVariables
>
