import { ContactType } from '@npco/mp-gql-types'
import {
  ErrorMessageForm,
  InputSelectComboboxStandard,
} from '@npco/zeller-design-system'

import { page } from 'translations'

import { InputSelectComboBoxItem } from '../Contacts.types'
import { useComboBoxSelect } from '../hooks/useComboBoxSelect/useComboBoxSelect'

interface BusinessComboBoxSelectProps {
  isAddingNew: boolean
  isOptional: boolean
  placeholder: string
  selectedItem?: InputSelectComboBoxItem | null
  setIsAddingNew: (value: boolean, inputValue?: string | null) => void
  setSelectedItem: (item: InputSelectComboBoxItem | null) => void
  setValidationError: (validationError: string) => void
  validationError: string
}

export const BusinessComboBoxSelect = ({
  isAddingNew,
  isOptional,
  placeholder,
  selectedItem,
  setIsAddingNew,
  setSelectedItem,
  setValidationError,
  validationError,
  ...props
}: BusinessComboBoxSelectProps) => {
  const {
    contacts,
    filterItems,
    hasMore,
    inputValue,
    loading,
    loadMore,
    onChange,
    onClose,
    onInputChange,
    onInputClear,
  } = useComboBoxSelect({
    contactType: ContactType.BUSINESS,
    isOptional,
    setIsAddingNew,
    setSelectedItem,
    setValidationError,
  })

  const items =
    contacts.map(
      (item) =>
        ({
          label: item?.businessName || null,
          value: item?.businessName || null,
          id: item?.id || null,
        } as InputSelectComboBoxItem)
    ) || []

  return (
    <>
      <InputSelectComboboxStandard
        {...props}
        addNewItemContent={page.contacts.form.createNew}
        filterItems={filterItems}
        hasError={Boolean(validationError)}
        hasMore={hasMore}
        inputValue={inputValue}
        isLoading={loading}
        items={items}
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onInputClear={onInputClear}
        onLoadMoreItems={loadMore}
        placeholder={placeholder}
        selectedItem={selectedItem}
        shouldHideSuggestions={isAddingNew}
      />
      <ErrorMessageForm
        hasError={Boolean(validationError)}
        errorMessage={validationError}
      />
    </>
  )
}
