import { Box, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { Form } from 'formik'

import { validateAccountName } from 'forms/formViews/BankAccountFields/BankAccountFields.utils'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { page } from 'translations'

export const ThirdPartyDetailAccountForm = () => {
  return (
    <Form>
      <FormLine>
        <Box mb="1.5rem">
          <InputAdaptiveFieldWrapper
            id="accountName"
            name="accountName"
            type="text"
            size={TEXT_INPUT_SIZE.SMALL}
            label={page.accounts.name}
            validate={validateAccountName}
          />
        </Box>
      </FormLine>
    </Form>
  )
}
