import { AVATAR_BADGE_SIZE } from 'types/common'

import { SelectSize } from '../Select.types'

export const getAvatarSize = (size: SelectSize) => {
  if (size === SelectSize.Small) {
    return AVATAR_BADGE_SIZE.XSMALL
  }

  return AVATAR_BADGE_SIZE.SMALL
}
