import {
  ButtonLink,
  ModalBasic,
  Title,
  useModalState,
} from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { useDefaultMarkupValue } from '../../../hooks/useDefaultMarkupValue'

export const translations = {
  reset: translate('page.invoice.formSections.email.resetButton'),
  cancel: translate('shared.cancel'),
  confirm: translate('shared.confirm'),
  title: translate('page.invoice.formSections.email.resetModalTitle'),
  message: translate('page.invoice.formSections.email.resetModalMessage'),
}

export const InvoiceEmailResetButton = () => {
  const { defaultMessage, defaultSubject } = useDefaultMarkupValue()
  const { setValues, values } = useFormikContext<InvoiceFormFields>()

  const { closeModal, isModalOpen, openModal } = useModalState()

  const handleConfirm = () => {
    setValues(
      {
        ...values,
        delivery: {
          ...values.delivery,
          email: { ...values.delivery.email, message: null, subject: null },
        },
      },
      false
    )

    closeModal()
  }

  const hasChanges =
    values.delivery.email.message !== defaultMessage ||
    values.delivery.email.subject !== defaultSubject

  return (
    <>
      <ButtonLink onClick={openModal} disabled={!hasChanges}>
        {translations.reset}
      </ButtonLink>
      <ModalBasic
        isOpen={isModalOpen}
        title={translations.title}
        onCancel={closeModal}
      >
        <ModalBasic.Body marginBottom={12}>
          <Title>{translations.message}</Title>
        </ModalBasic.Body>
        <ModalBasic.Footer>
          <ModalBasic.SecondaryButton onClick={closeModal}>
            {translations.cancel}
          </ModalBasic.SecondaryButton>
          <ModalBasic.PrimaryButton onClick={handleConfirm}>
            {translations.confirm}
          </ModalBasic.PrimaryButton>
        </ModalBasic.Footer>
      </ModalBasic>
    </>
  )
}
