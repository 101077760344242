import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { rvUpdatedDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

import { mergeUpdates } from './useDebitCardTransactionsWithUpdates.utils'

interface UseDebitCardTransactionsWithUpdatesProps {
  transactions: (DebitCardTransactionV3 | DebitCardTransactionV2)[]
  debitCardId?: string
  debitCardAccountUuid?: string
  contactUuid?: string
}

export const useDebitCardTransactionsWithUpdates = ({
  transactions,
  debitCardId,
  debitCardAccountUuid,
  contactUuid,
}: UseDebitCardTransactionsWithUpdatesProps) => {
  const debitCardTransactionUpdates = useReactiveVar(
    rvUpdatedDebitCardTransactions
  )

  const filteredTransactionUpdates = useMemo(() => {
    let filterResult = debitCardTransactionUpdates

    if (debitCardId !== undefined) {
      filterResult = filterResult.filter(
        (transaction) => transaction.debitCardId === debitCardId
      )
    }

    if (debitCardAccountUuid !== undefined) {
      filterResult = filterResult.filter(
        (transaction) =>
          transaction.debitCardAccountUuid === debitCardAccountUuid
      )
    }

    if (contactUuid !== undefined) {
      filterResult = filterResult.filter(
        (transaction) => transaction.contact?.id === contactUuid
      )
    }

    return filterResult
  }, [
    debitCardTransactionUpdates,
    debitCardId,
    debitCardAccountUuid,
    contactUuid,
  ])

  const transactionsWithUpdates = useMemo(
    () => mergeUpdates(filteredTransactionUpdates, transactions),
    [filteredTransactionUpdates, transactions]
  )

  return { transactionsWithUpdates }
}
