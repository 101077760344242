import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import {
  GetEntityTags as GetEntityTagsType,
  GetEntityTagsVariables,
} from 'types/gql-types/GetEntityTags'

interface UseAccountFilterTagsProps {
  skip?: boolean
}

export const useAccountFilterTags = ({
  skip = false,
}: UseAccountFilterTagsProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const { data } = useQuery<GetEntityTagsType, GetEntityTagsVariables>(
    GetEntityTags,
    { skip, variables: { entityUuid } }
  )

  return data?.getEntityTags ?? []
}
