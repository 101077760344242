/**
 * Formats an Australian-formatted phone number to international format.
 * @param phoneNumber The Australian-formatted phone number.
 * @returns The formatted international phone number.
 */
export const formatToInternationalPhoneNumber = (
  phoneNumber: string
): string => {
  const AUSTRALIAN_CODE = '+61'
  const MOBILE_PREFIX = '04'

  if (!phoneNumber) {
    return ''
  }

  if (phoneNumber.startsWith(MOBILE_PREFIX)) {
    return AUSTRALIAN_CODE + phoneNumber.slice(MOBILE_PREFIX.length - 1)
  }

  return phoneNumber
}
