import { useMemo } from 'react'
import { NetworkStatus, useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetDebitCardAccounts } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/accounts'

import {
  GetDebitCardAccounts as DebitCardAccountsResponse,
  GetDebitCardAccountsVariables,
} from 'types/gql-types/GetDebitCardAccounts'

interface UseAccountsQueryProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useAccountsQuery = ({
  fetchPolicy = 'cache-first',
}: UseAccountsQueryProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const {
    loading: isLoadingAccounts,
    error,
    data,
    refetch,
    networkStatus,
  } = useQuery<DebitCardAccountsResponse, GetDebitCardAccountsVariables>(
    GetDebitCardAccounts,
    {
      fetchPolicy,
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      onError: (err) => {
        showApiErrorToast(err)
      },
      variables: {
        entityUuid,
      },
    }
  )

  const accounts = useMemo(() => {
    return data?.getDebitCardAccountsV2.accounts
  }, [data?.getDebitCardAccountsV2.accounts])

  return {
    isLoadingAccounts:
      isLoadingAccounts || networkStatus === NetworkStatus.refetch,
    isRefetchingAccounts: networkStatus === NetworkStatus.refetch,
    error,
    accounts,
    refetchAccounts: refetch,
  }
}
