import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button as BaseButton } from 'components/Buttons/Button'

export const StyledButton = styled(BaseButton)`
  margin: 1.5rem 0 0;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 12.5rem;
  }
`
