import { ReactNode } from 'react'
import {
  Box,
  Flex,
  getTextSplitIntoMultipleLines,
} from '@npco/zeller-design-system'

import { ReactComponent as Rejection } from 'assets/svg/rejection.svg'
import { errorMessages } from 'translations'

import { StyledDescription, StyledTitle } from './UnexpectedErrorMessage.styled'

interface Props {
  children?: ReactNode
}

export const UnexpectedErrorMessage = ({ children }: Props) => {
  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box mb="2rem">
        <Rejection />
      </Box>

      <Flex
        width={['100%', '34.5rem']}
        flexDirection="column"
        alignItems="center"
        px={['0.5rem', 0]}
      >
        <StyledTitle>{errorMessages.smthWentWrong}</StyledTitle>

        <StyledDescription as="div">
          {getTextSplitIntoMultipleLines(errorMessages.couldNotLoadPage)}
        </StyledDescription>
        {children}
      </Flex>
    </Flex>
  )
}
