import { Box } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledListWrapper as InfiniteListWrapper } from 'components/Lists/InfiniteList/InfiniteList.styled'

export const StyledListWrapper = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  height: 0;

  ${InfiniteListWrapper} {
    margin: 0 -8px;
    padding: 0 8px;

    @media screen and (min-width: ${BREAKPOINT.SM}px) {
      margin: 0 -40px;
      padding: 0 40px;
    }

    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      margin: 0 -24px;
      padding: 0 24px;
    }

    @media screen and (min-width: ${BREAKPOINT.LG}px) {
      margin: 0 -44px;
      padding: 0 44px;
    }

    @media screen and (min-width: ${BREAKPOINT.XL}px) {
      margin: 0 -68px;
      padding: 0 68px;
    }
  }
`
