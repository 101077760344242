import { useCallback } from 'react'
import { useCloseAccountModal } from 'features/CloseAccount'

export const useGoToCloseAccount = () => {
  const { openCloseAccountModal } = useCloseAccountModal()

  const goToCloseAccount = useCallback(
    (accountId: string) => {
      openCloseAccountModal(accountId)
    },
    [openCloseAccountModal]
  )

  return { goToCloseAccount }
}
