import { Flex } from '@npco/zeller-design-system'

import { DashboardLeftColumn } from '../DashboardLayout'
import { DashboardTransactions } from '../DashboardTransactions/DashboardTransactions'
import { useRememberDashboardTab } from '../RememberDashboardTab'
import { SourcePickerProvider } from '../SourcePicker/SourcePickerProvider'
import { DashboardPerformance } from './DashboardPerformance/DashboardPerformance'
import { DashboardSales } from './DashboardSales/DashboardSales'

export const DashboardPayments = () => {
  useRememberDashboardTab()

  return (
    <Flex flexDirection={{ _: 'column', MD: 'row' }}>
      <DashboardLeftColumn>
        <DashboardSales />
        <SourcePickerProvider>
          <DashboardPerformance />
        </SourcePickerProvider>
      </DashboardLeftColumn>
      <DashboardTransactions />
    </Flex>
  )
}
