import { FC } from 'react'

import { StyledContainer, StyledMainItem } from './ListGroupTitle.styled'

interface Props {
  hasBorderBottom?: boolean
  hasBottomShadow?: boolean
}

export const ListGroupTitle: FC<Props> = ({
  children,
  hasBorderBottom,
  hasBottomShadow,
}) => {
  return (
    <StyledContainer
      data-testid="list-group-title"
      hasBorderBottom={hasBorderBottom}
      hasShadowBottom={hasBottomShadow}
    >
      <StyledMainItem>{children}</StyledMainItem>
    </StyledContainer>
  )
}
