import { z } from 'zod'

type CreateSavingsAccountStage = 'tfn'

export type CreateSavingsAccountState = {
  stage: CreateSavingsAccountStage
  hasBlurAndFade?: boolean
}

export const CreateSavingsAccountLocationSchema = z.object({
  CreateSavingsAccountModal: z.optional(
    z.nullable(
      z.object({
        stage: z.literal('tfn'),
        hasBlurAndFade: z.optional(z.boolean()),
      })
    )
  ),
})
