import { Amount, Title } from '@npco/zeller-design-system'

import { BILL_STATUS } from 'const/sim'
import { currencyFormatter } from 'utils/common'
import { SimBillType } from 'types/sim'
import {
  StyledContainer,
  StyledHideableItem,
  StyledHideableStatusItem,
  StyledItemText,
  StyledMainItem,
  StyledMobileTitle,
  StyledStandardItem,
  StyledStatusBadgeMobile,
} from 'pages/Settings/Sim/BillingHistory/SimBillsList/ListGroupSimBillItem/ListGroupSimBillItem.styled'
import SimBillStatusBadge from 'components/StatusBadge/SimBillStatusBadge/SimBillStatusBadge'
import { page } from 'translations'

import { DEFAULT_DATA_ITEM } from '../../BillingHistory.utils'

interface Props {
  data: SimBillType
  onRecordSelect: (data: SimBillType) => void
}

export const ListGroupSimBillItem: React.FC<Props> = ({
  data,
  onRecordSelect,
}) => {
  const { simId, siteName, amount, status } = data
  const simText = page.settings.sim.billingHistory.details.sim

  return (
    <StyledContainer
      data-testid="simbill-row"
      onClick={() => onRecordSelect(data)}
    >
      <StyledMainItem>
        <div>
          <Title>
            {simText} {simId}
          </Title>
          <StyledMobileTitle>{siteName ?? DEFAULT_DATA_ITEM}</StyledMobileTitle>
        </div>
      </StyledMainItem>
      <StyledHideableItem>
        <StyledItemText>{siteName ?? DEFAULT_DATA_ITEM}</StyledItemText>
      </StyledHideableItem>
      <StyledHideableStatusItem>
        <SimBillStatusBadge status={BILL_STATUS[status]} />
      </StyledHideableStatusItem>
      <StyledStandardItem>
        <Amount>{currencyFormatter(amount)}</Amount>
        <StyledStatusBadgeMobile>
          <SimBillStatusBadge status={BILL_STATUS[status]} />
        </StyledStatusBadgeMobile>
      </StyledStandardItem>
    </StyledContainer>
  )
}
