import { TransactionFilterInput } from '@npco/mp-gql-types'

import { filterObjectEmptyFields } from 'utils/mapFiltersInputToApiFilter'
import {
  mapAmountRangeFilterToApi,
  mapDateFiltersToApi,
  mapRefundedStatusToApi,
  mapSiteFilterToApi,
  mapSourceFilterToApi,
  mapStatusFilterToApi,
  mapTerminalsFiltersToApi,
  mapTypeFilterToApi,
} from 'components/Filters/PillFilters/shared/filterApi.utils'

import { TransactionsFiltersInput } from './TransactionsFilters.types'

const mapSplitPaymentUuidToApi = (splitPaymentUuid?: string | null) => {
  if (splitPaymentUuid) {
    return { splitPaymentUuid: { eq: splitPaymentUuid } }
  }
  return undefined
}

export const createSplitPaymentApiFilter = (
  input: TransactionsFiltersInput,
  splitPaymentUuid?: string | null
): TransactionFilterInput => {
  const { dates } = input
  return filterObjectEmptyFields({
    timestamp: dates && mapDateFiltersToApi(dates),
    ...mapSplitPaymentUuidToApi(splitPaymentUuid),
  })
}

const mapTransactionsSearchToApi = (search: string | null) => {
  if (!search) {
    return undefined
  }

  return {
    or: [
      { maskedPan: { contains: search } },
      { reference: { contains: search } },
      { depositShortId: { eq: search } },
    ],
  }
}

export const mapFiltersInputToTransactionsApiFilter = ({
  amount,
  dates,
  statuses,
  terminals,
  types,
  search,
  sources,
  siteUuid,
}: TransactionsFiltersInput): TransactionFilterInput => {
  return filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates),
    status: mapStatusFilterToApi(statuses),
    refunded: mapRefundedStatusToApi(statuses),
    amount: mapAmountRangeFilterToApi(amount),
    deviceUuid: mapTerminalsFiltersToApi(terminals),
    siteUuid: mapSiteFilterToApi(siteUuid),
    type: mapTypeFilterToApi(types),
    sourceFilter: mapSourceFilterToApi(sources),
    ...mapTransactionsSearchToApi(search),
  })
}
