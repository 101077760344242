import { InfoBox, INFOBOX_PRIORITY } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { CardStyleTabType } from '../hooks/useCardStyleTabs'

type LinkCardIdInfoBoxProps = {
  currentTab: CardStyleTabType
}

export const LinkCardIdInfoBox = ({ currentTab }: LinkCardIdInfoBoxProps) => {
  return (
    <InfoBox priority={INFOBOX_PRIORITY.DEFAULT}>
      <InfoBox.Message>
        {currentTab === 'style1'
          ? translate('page.addCardModal.linkCard.cardIdInfo')
          : translate('page.addCardModal.linkCard.cardIdInfoCorporate')}
      </InfoBox.Message>
    </InfoBox>
  )
}
