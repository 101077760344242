import { AnnouncementModal, BoatSinking } from '@npco/zeller-design-system'
import { useCloseAccountModal } from 'features/CloseAccount/hooks/useCloseAccountModal/useCloseAccountModal'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

interface CloseAccountErrorProps {
  retry: () => void
}

export const CloseAccountError = ({ retry }: CloseAccountErrorProps) => {
  const isMobile = useIsMobileResolution()
  const { closeModal } = useCloseAccountModal()

  return (
    <AnnouncementModal
      Illustration={<BoatSinking size={isMobile ? 'medium' : 'large'} />}
      heading={translate('page.closeAccountModal.error.heading')}
      description={translate('page.closeAccountModal.error.description')}
      primaryButtonLabel={translate(
        'page.closeAccountModal.error.primaryButtonLabel'
      )}
      onClickPrimary={() => retry()}
      secondaryButtonLabel={translate('shared.close')}
      onClickSecondary={closeModal}
      onCancel={closeModal}
      isOpen
    />
  )
}
