import { BodyLarge, H1 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTitle = styled(H1)`
  margin: 0;
  margin-bottom: 1.5rem;
  text-align: center;
`

export const StyledDescription = styled(BodyLarge)`
  margin: 0;
  margin-bottom: 2rem;
  padding: 0;
  text-align: center;
`

export const StyledLogoWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
`
