import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  MagnifyingGlassNoResults,
  Message,
  MessageSize,
} from '@npco/zeller-design-system'

import { translations } from './TransactionsSummaryEmpty.i18n'

export const TransactionsSummaryEmpty = () => {
  const t = useTranslations(translations)

  return (
    <Box maxWidth="600px" margin="auto">
      <Message
        description={t('description')}
        title={t('title')}
        image={<MagnifyingGlassNoResults size="large" />}
        size={MessageSize.LARGE}
      />
    </Box>
  )
}
