import { useEffect } from 'react'
import { TransactionStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonLink,
  Flex,
  SvgIcon,
  Typography,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { cardIcons, issuerIcons } from 'const/payment-providers'
import { getFormattedTransactionAmount } from 'utils/common'
import { checkIfRefundableTransaction } from 'utils/transactions'
import { translate } from 'utils/translations'
import { TransactionInList } from 'types/transactions'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { SendReceiptModal } from 'components/SendReceiptModal/SendReceiptModal'
import { Notification } from 'components/TransactionDetails/Notification/Notification'
import {
  component,
  shared,
  transactionsErrors,
  translationsShared,
} from 'translations'

import { DetailsContainer } from './DetailsContainer/DetailsContainer'
import { useFetchTransaction } from './hooks/useFetchTransactions'
import { RefundTransactionModal } from './RefundTransactionModal/RefundTransactionModal'
import { translations } from './TransactionDetails.i18n'
import * as styled from './TransactionDetails.styled'
import { TransactionNotes } from './TransactionNotes/TransactionNotes'

interface TransactionDetailsProps {
  selectedTransaction: TransactionInList
  closeDrawer: () => void
}

type TransactionErrorKey = keyof typeof transactionsErrors

export const TransactionDetails = ({
  selectedTransaction,
  closeDrawer,
}: TransactionDetailsProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)
  const { isModalOpen, openModal, closeModal } = useModalState({
    modalName: translate('component.transaction.receipt'),
  })

  const {
    isModalOpen: isRefundModalOpen,
    openModal: openRefundModal,
    closeModal: closeRefundModal,
  } = useModalState({
    modalName: t('refund'),
  })

  const {
    fetchedTransaction,
    updateSelectedTransaction,
    hasError,
    isLoadingFetchTransaction,
    isNavigatingTransaction,
    refetchTransaction,
  } = useFetchTransaction(selectedTransaction.id)

  useEffect(() => {
    if (selectedTransaction.subscriptionUpdatedAt) {
      refetchTransaction()
    }
  }, [refetchTransaction, selectedTransaction.subscriptionUpdatedAt])

  if (hasError || !selectedTransaction) {
    return (
      <GenericErrorPage
        retry={() => refetchTransaction?.()}
        data-testid="fetch-transaction-error"
      />
    )
  }

  const shouldShowErrorDescription =
    selectedTransaction.status === TransactionStatus.DECLINED

  const isRefundable = checkIfRefundableTransaction(selectedTransaction)
  const errorDescription =
    transactionsErrors[
      String(selectedTransaction.responseCode) as TransactionErrorKey
    ] === undefined
      ? transactionsErrors[shared.unknown as TransactionErrorKey].details
      : transactionsErrors[
          String(selectedTransaction.responseCode) as TransactionErrorKey
        ].details
  const transactionTotal = getFormattedTransactionAmount(selectedTransaction)

  return (
    <styled.TransactionDetails data-testid="transaction-details">
      <Flex justifyContent="space-between" alignItems="center" mb="24px">
        <Box position="relative">
          {selectedTransaction.issuer && (
            <styled.IssuerIcon
              width="21"
              height="21"
              dataTestId="issuer-icon"
              ariaLabel={tShared(selectedTransaction.issuer)}
            >
              {issuerIcons[selectedTransaction.issuer]}
            </styled.IssuerIcon>
          )}
          <SvgIcon
            ariaLabel={tShared(selectedTransaction.scheme)}
            height="60"
            width="60"
          >
            {cardIcons[selectedTransaction.scheme]}
          </SvgIcon>
        </Box>
        <styled.TransactionAmount
          isDeclined={selectedTransaction.status === TransactionStatus.DECLINED}
        >
          {transactionTotal}
        </styled.TransactionAmount>
      </Flex>
      {shouldShowErrorDescription && (
        <Notification>
          {errorDescription.heading}
          {errorDescription.text}
        </Notification>
      )}
      <DetailsContainer
        isNavigatingTransaction={isNavigatingTransaction}
        selectedTransaction={selectedTransaction}
        fetchedTransaction={fetchedTransaction}
        updateSelectedTransaction={updateSelectedTransaction}
        closeDrawer={closeDrawer}
      />
      <Box my="48px">
        <TransactionNotes
          notes={fetchedTransaction?.notes}
          transactionUuid={selectedTransaction.id}
        />
      </Box>
      <Box mb="12px">
        <Typography component="h3" variant="heading-xl">
          {component.transaction.actions}
        </Typography>
      </Box>
      <Flex justifyContent="flex-start">
        <Box mr="32px">
          <ButtonLink
            onClick={openModal}
            dataTestId="send-receipt"
            disabled={isLoadingFetchTransaction}
          >
            {component.transaction.receiptSend}
          </ButtonLink>
        </Box>
        {isRefundable && (
          <ButtonLink
            onClick={openRefundModal}
            dataTestId="issue-refund"
            disabled={isLoadingFetchTransaction}
          >
            {t('refund')}
          </ButtonLink>
        )}
      </Flex>
      <ModalBasic isOpen={isModalOpen} onCancel={closeModal}>
        <SendReceiptModal
          closeModal={closeModal}
          transactionId={selectedTransaction.id}
          cardHolderEmail={fetchedTransaction?.cardholderEmail}
          cardHolderPhone={fetchedTransaction?.cardholderPhone}
        />
      </ModalBasic>
      <RefundTransactionModal
        isOpen={isRefundModalOpen}
        onCancel={closeRefundModal}
        transaction={selectedTransaction}
      />
    </styled.TransactionDetails>
  )
}
