import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const ProgressIndicatorWrapper = styled(Flex)`
  margin: 70px 0 52px 0;
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin: 20px 0 4px 0;
  }
`
