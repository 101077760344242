import { BodySmall, BodySmallTypography, H6 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { StyledListElement } from 'components/DetailsListItem/DetailsListItem.styled'

export const StyledTag = styled.div`
  ${BodySmallTypography}
`

export const StyledOverrideListElement = styled(StyledListElement)`
  padding-bottom: 0.5rem;
`

export const StyledListKey = styled(H6)`
  margin: 0;
  padding-right: 1rem;
`

export const StyledErrorText = styled(BodySmall)`
  ${({ theme }) => theme.colors.RED_1000};
  margin: 0;
`
