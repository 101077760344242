import { ModalBasic } from '@npco/zeller-design-system'

import { useGoToLockCardStage } from 'hooks/useGoToLockCardStage/useGoToLockCardStage'
import { translate } from 'utils/translations'

import { useLockCardState } from './hooks/useLockCardState'
import { LockCard } from './LockCard/LockCard'

export const LockCardModal = () => {
  const { state } = useLockCardState()
  const { goToLockCardStage } = useGoToLockCardStage()

  if (state === null) {
    return null
  }

  return (
    <ModalBasic
      isOpen
      onCancel={() => goToLockCardStage(undefined)}
      title={translate('page.lockCardModal.title')}
    >
      <LockCard />
    </ModalBasic>
  )
}
