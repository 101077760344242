import { useMemo } from 'react'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useGetEntityForCardListLayoutQuery } from '../graphql/getEntityForCardListLayout.generated'

interface UseGetEntityForCardsListLayoutProps {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useGetEntityForCardsListLayout = ({
  fetchPolicy = 'cache-first',
}: UseGetEntityForCardsListLayoutProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const {
    loading: isLoadingEntity,
    error,
    data,
  } = useGetEntityForCardListLayoutQuery({
    fetchPolicy,
    variables: { entityUuid },
  })

  const entity = useMemo(() => {
    return data?.getEntity
  }, [data?.getEntity])

  return {
    isLoadingEntity,
    error,
    entity,
  }
}
