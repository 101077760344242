import { useTranslations } from '@npco/utils-translations'

import { TransactionContactUnlinkedTransaction } from 'components/TransactionContactUnlinkedTransaction'

import { DebitCardTransaction } from '../../../TransactionDrawer.types'
import { translations } from './UnlinkedContactDetails.i18n'
import { useTransactionTransferInContact } from './useTransactionTransferInContact/useTransactionTransferInContact'

interface UnlinkedContactDetailsProps {
  senderUuid: string
  updateTransaction: (update: Partial<DebitCardTransaction>) => void
  debitCardTransactionUuid: string
}

export const UnlinkedContactDetails = ({
  senderUuid,
  updateTransaction,
  debitCardTransactionUuid,
}: UnlinkedContactDetailsProps) => {
  const t = useTranslations(translations)

  const {
    handleLinkContact,
    handleOnContactCreateAndLink,
    hasLinkContactError,
    isLinkingContactWithSender,
  } = useTransactionTransferInContact({
    updateTransaction,
    debitCardTransactionUuid,
  })

  return (
    <TransactionContactUnlinkedTransaction
      cardholderOrSenderUuid={senderUuid}
      description={t('description')}
      isLoading={isLinkingContactWithSender}
      hasLinkContactError={hasLinkContactError}
      onCreateContactAndLink={handleOnContactCreateAndLink}
      onLinkContact={handleLinkContact}
    />
  )
}
