import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { useAccountNavigation } from '@npco/mp-utils-accounts-routing'
import { Flex, ListCardButton } from '@npco/zeller-design-system'

import { AVATAR_BADGE_SIZE } from 'types/common'

import { AccountBalance } from './AccountBalance'
import { AccountBalanceCardDebitCardAccountV2Fragment } from './AccountBalanceCard.generated'

interface AccountBalanceCardProps {
  account: AccountBalanceCardDebitCardAccountV2Fragment
}

export const AccountBalanceCard = ({ account }: AccountBalanceCardProps) => {
  const { navigateToAccountTransactions } = useAccountNavigation()

  const AccountAvatar = useCallback(() => {
    return (
      <Flex height="44px" alignItems="center">
        <IconAvatar
          icon={account.icon}
          defaultLetter={account.name}
          badgeSize={AVATAR_BADGE_SIZE.SMALL}
          withCursorPointer
          allowAnimation
        />
      </Flex>
    )
  }, [account.icon, account.name])

  return (
    <ListCardButton
      title={account.name}
      image={AccountAvatar}
      onClick={() => navigateToAccountTransactions({ accountId: account.id })}
    >
      <AccountBalance account={account} />
    </ListCardButton>
  )
}

AccountBalanceCard.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountBalanceCardDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      name
      type
      icon {
        ...IconAvatarIconFragment
      }
      ...AccountBalanceDebitCardAccountV2Fragment
    }

    ${AccountBalance.fragments.DebitCardAccountV2}
    ${IconAvatar.fragments.Icon}
  `,
}
