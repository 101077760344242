import { useMemo, useState } from 'react'
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { zIndexMap } from '@npco/zeller-design-system'

const deps = {
  useFloating,
}
export { deps as UseFloatingPillDeps }

export const useFloatingPill = ({
  placement = 'bottom-start',
  animatePosition = true,
  dismissible = true,
}: {
  placement?: UseFloatingOptions['placement']
  animatePosition?: boolean
  dismissible?: boolean
} = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = deps.useFloating({
    whileElementsMounted: autoUpdate,
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(8), shift({ padding: 16 }), flip()],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context, { enabled: dismissible })
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const { isMounted, styles } = useTransitionStyles(context, {
    common: {
      transitionDelay: '0.1s',
    },
  })

  const referenceProps = useMemo(
    () => ({
      ...getReferenceProps(),
      ref: refs.setReference,
    }),
    [getReferenceProps, refs.setReference]
  )

  const floatingProps = useMemo(
    () => ({
      ...getFloatingProps(),
      ref: refs.setFloating,
      style: {
        ...floatingStyles,
        zIndex: zIndexMap.popper,
        transition: animatePosition ? 'transform 0.1s ease-in-out' : undefined,
      },
    }),
    [animatePosition, floatingStyles, getFloatingProps, refs.setFloating]
  )

  const transitionProps = useMemo(
    () => ({
      isMounted,
      style: styles,
    }),
    [isMounted, styles]
  )

  const close = () => setIsOpen(false)

  return {
    close,
    context,
    floatingProps,
    isOpen,
    referenceProps,
    transitionProps,
  }
}
