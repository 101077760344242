import { gql } from '@apollo/client'

export const SendInvoice = gql`
  mutation SendInvoice($entityUuid: ID!, $referenceNumber: String!) {
    sendInvoice(entityUuid: $entityUuid, referenceNumber: $referenceNumber) {
      __typename
      id
      referenceNumber
      status
    }
  }
`
