import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

const DOWNLOADABLE_SUMMARY_TYPES = [
  DebitCardTransactionTypeV2.DE_OUT,
  DebitCardTransactionTypeV2.PURCHASE,
  DebitCardTransactionTypeV2.PURCHASE_CNP,
  DebitCardTransactionTypeV2.REFUND,
  DebitCardTransactionTypeV2.REFUND_CNP,
  DebitCardTransactionTypeV2.DDA_OUT,
]

const DOWNLOADABLE_SUMMARY_TYPES_V3 = [
  DebitCardAccountTransactionTypeEnum.DE_OUT,
  DebitCardAccountTransactionTypeEnum.PURCHASE,
  DebitCardAccountTransactionTypeEnum.PURCHASE_CNP,
  DebitCardAccountTransactionTypeEnum.REFUND,
  DebitCardAccountTransactionTypeEnum.REFUND_CNP,
]

export const getHasDownloadableSummary = (type: DebitCardTransactionTypeV2) =>
  DOWNLOADABLE_SUMMARY_TYPES.includes(type)

export const getHasDownloadableSummaryV3 = (
  type: DebitCardAccountTransactionTypeEnum
) => DOWNLOADABLE_SUMMARY_TYPES_V3.includes(type)
