import { SignupFields } from '../SignUp.types'

export const getIsFormDisabled = (
  values: SignupFields,
  isEmailSet: boolean,
  hasFormErros: boolean
) => {
  if (hasFormErros) {
    return true
  }

  if (!isEmailSet) {
    return !values.email
  }

  return !values.email || !values.password || !values.termsAndConditions
}
