import { Flex } from '@npco/zeller-design-system'

import { DatePicker } from 'components/Filters/NewFilters/DatePicker'
import { StyledDepositFiltersWrapper } from 'components/Filters/NewFilters/DepositFilters.styled'
import { RangePicker } from 'components/Filters/NewFilters/RangePicker'

export const INITIAL_DEPOSIT_RANGE: [number, number] = [0, 100000]
export const DepositFilters = () => {
  return (
    <StyledDepositFiltersWrapper>
      <Flex
        flexDirection="column"
        mb={['0.75rem', 0]}
        mr={[0, '1rem']}
        width={['100%', '50%']}
      >
        <DatePicker />
      </Flex>
      <Flex flexDirection="column" width={['100%', '50%']}>
        <RangePicker initialRange={INITIAL_DEPOSIT_RANGE} />
      </Flex>
    </StyledDepositFiltersWrapper>
  )
}
