import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

interface CardholderRadioOptionSkeletonProps {
  titleLength: string
  subtitleLength: string
}

export const CardholderRadioOptionSkeleton = ({
  titleLength,
  subtitleLength,
}: CardholderRadioOptionSkeletonProps) => {
  return (
    <Flex
      alignItems="center"
      padding="24px"
      mb="17px"
      mt="1px"
      data-testid="cardholder-radio-skeleton"
    >
      <Box pr="16px">
        <SkeletonBasic isRounded width="40px" height="40px" />
      </Box>
      <Box>
        <Box pb="8px">
          <SkeletonBasic
            height="12px"
            width={titleLength}
            borderRadius="1000px"
          />
        </Box>
        <SkeletonBasic
          height="8px"
          width={subtitleLength}
          borderRadius="1000px"
        />
      </Box>
    </Flex>
  )
}
