import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  GetXeroSiteCustomers as GetXeroSiteCustomersResponse,
  GetXeroSiteCustomersVariables,
} from 'types/gql-types/GetXeroSiteCustomers'

import { GetXeroSiteCustomers } from '../../graphql/GetXeroSiteCustomers'

export const useGetXeroSiteCustomers = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useQuery<
    GetXeroSiteCustomersResponse,
    GetXeroSiteCustomersVariables
  >(GetXeroSiteCustomers, {
    variables: { entityUuid },
  })

  return {
    siteUuid: data?.getXeroSiteSettings?.id,
    siteCustomers: data?.getXeroSiteSettings?.customers,
    isLoadingSiteCustomers: loading,
  }
}
