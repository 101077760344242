import { errorMessages } from 'translations'

interface ConvertImageProps {
  file: File
  onSuccess: (url: string) => void
  onError: (err: Error) => void
}

const convertImage = async ({
  file,
  onError,
  onSuccess,
}: ConvertImageProps) => {
  import('heic2any').then(async (heic2any) => {
    try {
      const preview = await heic2any.default({
        blob: file,
        toType: 'image/png',
        quality: 0.8,
      })
      onSuccess(URL.createObjectURL(preview as Blob))
    } catch (error) {
      onError(new Error(errorMessages.errorConvertHighEfficiencyImageFile))
    }
  })
}

export const highEfficiencyImageConverter = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    convertImage({
      file,
      onSuccess: resolve,
      onError: reject,
    })
  })
