import {
  Animation,
  slideDownAnimationVariants,
} from '@npco/zeller-design-system'

import { useFileUploaderError } from './FileUploaderError.hooks'
import { FileUploaderErrorMessage } from './FileUploaderError.styled'

export const FileUploaderError = () => {
  const { errorMessage } = useFileUploaderError()

  if (!errorMessage) {
    return null
  }

  return (
    <FileUploaderErrorMessage>
      <Animation
        shouldShow={!!errorMessage}
        variants={slideDownAnimationVariants}
      >
        {errorMessage}
      </Animation>
    </FileUploaderErrorMessage>
  )
}
