import { AccordionComplex, Animation, Box } from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { InvoiceAccordionCommonProps } from '../../InvoiceFormAccordions.types'
import { fadeInHeightAnimationVariants } from '../../InvoiceFormAccordions.utils'
import { InvoiceAccordionContentWrapper } from '../InvoiceAccordionContentWrapper/InvoiceAccordionContentWrapper'
import { useCustomerContactStatus } from './hooks/useCustomerContactStatus'
import { InvoiceAttentionToContactCombobox } from './InvoiceAttentionToContactCombobox/InvoiceAttentionToContactCombobox'
import { getAccordionFormState } from './InvoiceCustomerAccordion.utils'
import { InvoiceCustomerContactInfobox } from './InvoiceCustomerContactInfobox/InvoiceCustomerContactInfobox'
import { InvoicePayerContactCombobox } from './InvoicePayerContactCombobox/InvoicePayerContactCombobox'

export const translations = {
  customerFormTitle: translate('page.invoice.formSections.customer.title'),
  customerFormDetails: translate('page.invoice.formSections.customer.details'),
}

export const InvoiceCustomerAccordion = ({
  isExpanded,
  onChange,
}: InvoiceAccordionCommonProps) => {
  const {
    errors: { customer },
    values: {
      customer: { payerContact },
    },
    touched: { customer: touchedCustomerFields },
  } = useFormikContext<InvoiceFormFields>()

  useCustomerContactStatus(isExpanded)

  const payerId = payerContact?.contactUuid ?? ''

  const detailsText =
    payerContact?.contactName ?? translations.customerFormDetails

  return (
    <AccordionComplex
      dataTestId="invoice-customer"
      details={detailsText}
      isExpanded={isExpanded}
      onChange={onChange}
      title={translations.customerFormTitle}
      formItemState={getAccordionFormState({
        errors: customer,
        payerId,
        touchedCustomerFields,
      })}
    >
      <InvoiceAccordionContentWrapper>
        <InvoiceCustomerContactInfobox />
        <Box
          display="grid"
          gridGap="20px"
          gridTemplateColumns={{
            _: 'none',
            XS: 'repeat(2, 1fr)',
            LG: 'repeat(3, 1fr)',
          }}
        >
          <Box className="invoice-customer-input">
            <InvoicePayerContactCombobox />
          </Box>
          <Box>
            <Animation
              disableInitialAnimation
              shouldShow={Boolean(payerId)}
              variants={fadeInHeightAnimationVariants}
            >
              <InvoiceAttentionToContactCombobox payerId={payerId} />
            </Animation>
          </Box>
        </Box>
      </InvoiceAccordionContentWrapper>
    </AccordionComplex>
  )
}
