import { rvUpdatedDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { DebitCardTransactionUpdate_onDebitCardTransactionUpdate as DebitCardTransactionUpdate } from 'types/gql-types/DebitCardTransactionUpdate'

import {
  DebitCardTransaction,
  PartialTransactionUpdateType,
} from '../debitCardTransactionUpdate.types'
import {
  getExistingTransaction,
  padWithNulls,
} from './useDebitCardTransactionUpdate.utils'

interface UseDebitCardTransactionUpdateProps {
  cachedTransactions?: DebitCardTransaction[]
}

export const useDebitCardTransactionUpdate = ({
  cachedTransactions = [],
}: UseDebitCardTransactionUpdateProps = {}) => {
  const updatedDebitCardTransactions = rvUpdatedDebitCardTransactions()

  const updateTransactionWithSubscribeData = (
    update: DebitCardTransactionUpdate,
    onUpdated?: (
      prevTransaction: DebitCardTransaction,
      nextTransaction: DebitCardTransaction
    ) => void
  ) => {
    const updatedDebitCardTransactionsWithoutUpdate =
      updatedDebitCardTransactions.filter(
        (optimisticTransaction) => optimisticTransaction.id !== update.id
      )
    const existingTransaction = getExistingTransaction(
      update.id,
      cachedTransactions,
      updatedDebitCardTransactions
    )

    const newContact = update.contact
      ? { ...update.contact, category: null }
      : null

    const updatedContact = existingTransaction?.contact
      ? { ...existingTransaction.contact, ...update.contact }
      : newContact

    const newMerchant = update.merchant ? padWithNulls(update.merchant) : null
    const updatedMerchant = existingTransaction?.merchant
      ? { ...existingTransaction?.merchant, ...update.merchant }
      : newMerchant

    const newTransaction: DebitCardTransaction = {
      ...update,
      merchant: updatedMerchant,
      contact: updatedContact,
      __typename: 'DebitCardTransactionV2',
    }

    if (existingTransaction) {
      onUpdated?.(existingTransaction, newTransaction)
    }

    rvUpdatedDebitCardTransactions([
      ...updatedDebitCardTransactionsWithoutUpdate,
      newTransaction,
    ])
  }

  const partialUpdateTransaction = (
    updates: PartialTransactionUpdateType,
    id: string
  ) => {
    const updatedDebitCardTransactionsWithoutUpdate =
      updatedDebitCardTransactions.filter(
        (optimisticTransaction) => optimisticTransaction.id !== id
      )
    const existingTransaction = getExistingTransaction(
      id,
      cachedTransactions,
      updatedDebitCardTransactions
    )

    if (!existingTransaction) {
      return
    }

    rvUpdatedDebitCardTransactions([
      ...updatedDebitCardTransactionsWithoutUpdate,
      { ...existingTransaction, ...updates },
    ])
  }

  return { updateTransactionWithSubscribeData, partialUpdateTransaction }
}
