import {
  RefundPinType,
  SurchargesTaxesInput,
  TipSettingsInput,
} from '@npco/mp-gql-types'

import {
  GetSite_getSite_schemes as Schemes,
  GetSite_getSite_schemesMoto as SchemesMoto,
} from 'types/gql-types/GetSite'

export enum Account {
  ZELLER = 'ZELLER',
  EXTERNAL = 'EXTERNAL',
}

export interface Deposits {
  selectedAccount: Account | undefined
  bsb: number | undefined
  accountNumber: number | undefined
  accountNumberConfirmation: boolean
}

export interface SettingsProps {
  deposits: Deposits
}

export interface ReceiptProps {
  address1: string
  address2: string
  email: string
  facebook: string
  instagram: string
  logo: string
  merchantCopy: boolean
  message: string
  name: string
  number: string
  phone: string
  printDeclinedTransaction: boolean
  printLogo: boolean
  printSocials: boolean
  qrCode: boolean
  returnsMessage: string
  twitter: string
  website: string
}

export enum CardSurcharge {
  Percentage = '0',
  TotalFees = '1',
}

export enum MotoSurcharge {
  Percentage = '0',
  TotalFees = '1',
}

export interface SettingsPayments {
  surchargeEnabled?: boolean | null
  surchargePercent?: number | null
  surchargeType?: string | null
  motoEnabled?: boolean | null
  motoDefault?: boolean | null
  motoRequirePIN?: boolean | null
  motoPaymentsSurchargeEnabled?: boolean | null
  motoPaymentsSurchargeType?: boolean | string | null
  motoPaymentsSurchargePercentage?: number | null
  motoPaymentsSurchargeFeePercent?: number | null
  gstEnabled?: boolean | null
  tipEnabled?: boolean | null
  tipPercent1?: number | null
  tipPercent2?: number | null
  tipPercent3?: number | null
  schemes?: Schemes[] | null
  schemesMoto?: SchemesMoto[] | null
  customTipAllowed?: boolean | null
  refundRequiresPin?: boolean | null
  refundPin?: string | null
  refundPinType?: RefundPinType | null
  cpocSurchargeEnabled?: boolean | null
  cpocSurchargeType?: string | null
  cpocSurchargePercent?: number | null
  cpocFeePercent?: number | null
  restrictReportAccessEnabled?: boolean
  splitPaymentEnabled?: boolean
  vtEnabled?: boolean | null
  vtSurchargeEnabled?: boolean | null
  vtSurchargeType?: string | null
  vtSurchargePercent?: number | null
}

export interface GetSiteProps {
  getSite: {
    GetSiteProp: any
    tipping: TipSettingsInput
    surchargesTaxes: SurchargesTaxesInput
    refundRequiresPin: boolean
    receipt: ReceiptProps
  }
}
