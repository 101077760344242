import Modal from 'react-modal'
import {
  BUTTON_SIZE,
  ButtonGhostIconOnly,
  ButtonGhostIconRight,
  COLOR,
  Flex,
} from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { SCROLLBAR } from 'const/scrollbars'

import { InvoicePdfPagination } from '../InvoiceFormAccordions/components/InvoicePdfPagination/InvoicePdfPagination'

const PREVIEW_HEADER_HEIGHT = '88px'

export const InvoicePreviewWrapper = styled(Modal)`
  background: ${({ theme: { colors } }) => rgba(colors.BLACK_900, 0.75)};
  box-sizing: border-box;
  left: 50%;
  height: 100%;
  width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const InvoicePreviewHeader = styled(Flex)`
  color: ${({ theme }) => theme.colors.WHITE};
  height: ${PREVIEW_HEADER_HEIGHT};
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
`

const lightboxButton = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 8px;

  &:hover {
    background: ${({ theme: { colors } }) => rgba(colors.WHITE, 0.05)};
  }

  &:focus-visible {
    outline: 3px solid ${({ theme: { colors } }) => rgba(colors.WHITE, 0.1)};
  }
`

export const InvoicePreviewButton = styled(ButtonGhostIconRight).attrs({
  size: BUTTON_SIZE.XSMALL,
  iconEnabledColor: COLOR.WHITE,
})<{ $full?: boolean }>`
  ${lightboxButton}
  ${({ $full }) =>
    $full &&
    css`
      width: 100%;
      height: 100%;

      &&:hover {
        background: transparent;
        background-color: transparent;
      }
    `}
`

export const InvoicePreviewIconButton = styled(ButtonGhostIconOnly).attrs({
  size: BUTTON_SIZE.XSMALL,
  iconEnabledColor: COLOR.WHITE,
})`
  ${lightboxButton}
`

export const InvoicePreviewBody = styled(Flex)`
  height: calc(100% - ${PREVIEW_HEADER_HEIGHT});
  overflow: auto;
  margin-right: ${SCROLLBAR.LG.rightSpacing};
  &::-webkit-scrollbar {
    width: ${SCROLLBAR.LG.width};
  }
`
export const InvoicePreviewContent = styled(Flex)`
  height: max-content;
  margin: 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  padding: 0 16px;

  .react-pdf__Page__canvas {
    border-radius: 4px;
  }

  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.XS}) {
    max-width: 600px;
    padding: 0;
  }
`

export const InvoicePreviewPagination = styled(InvoicePdfPagination)`
  color: white;

  button {
    ${lightboxButton}

    svg > path {
      fill: ${({ theme: { colors } }) => colors.WHITE};
    }

    &:disabled svg > path {
      fill: ${({ theme: { colors } }) => colors.GREY_250};
    }
  }
`
