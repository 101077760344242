import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  MessageSize,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { Copy } from './InvoiceErrorState.styled'

interface InvoiceErrorStateProps {
  onRetry: () => void
}

export const InvoiceErrorState = ({ onRetry }: InvoiceErrorStateProps) => {
  return (
    <MessageWithIcon
      align="center"
      description={
        <Box textAlign="center">
          <Copy>{translate('page.invoicesErrorPage.copyOne')}</Copy>
          <Copy>{translate('page.invoicesErrorPage.copyTwo')}</Copy>
        </Box>
      }
      primaryButton={
        <ButtonFill size={BUTTON_SIZE.LARGE} onClick={onRetry}>
          {translate('page.invoicesErrorPage.tryAgain')}
        </ButtonFill>
      }
      size={MessageSize.LARGE}
      title={
        <Box textAlign="center">
          {translate('page.invoicesErrorPage.header')}
        </Box>
      }
    />
  )
}
