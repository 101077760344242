import { Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Image = styled.img`
  object-fit: fill;
`
export const Title = styled(Heading.H2)`
  text-align: center;
  margin-bottom: 24px;
  margin-right: 24px;
`

export const Content = styled.div`
  max-height: calc(100vh - 270px);
`
