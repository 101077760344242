import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  mobileApp: 'Mobile App',
  cardPayments: 'Card payments',
  description: 'Take card payments with your Zeller App.',
  totalFees: 'Surcharge my total fees',
  percentage: 'Surcharge a percentage',
  processingFee: 'Processing Fee',
  description1:
    'Under Australian law, a card surcharge amount is limited to the direct costs incurred by the merchant.',
  description2: "Zeller's maximum surcharge is {value}%",
  cardSurcharge: 'Card Surcharge',
  cardPaymentsSurcharge: 'Card Payments Surcharge',
})
