import { useCallback, useMemo } from 'react'

import { useGoToUsersList } from 'hooks/useGoToUsersList/useGoToUsersList'
import { GetCustomers_getCustomers as Customer } from 'types/gql-types/GetCustomers'

import { useUsersListState } from './useUsersListState'

interface UseSelectedCustomerProps {
  customers: Customer[]
}

export const useSelectedUser = ({ customers }: UseSelectedCustomerProps) => {
  const { state } = useUsersListState()
  const { goToUsersList } = useGoToUsersList()

  const selectedUser = useMemo(() => {
    if (!state?.selectedUserId) {
      return null
    }

    return (
      customers.find((customer) => customer.id === state.selectedUserId) || null
    )
  }, [customers, state?.selectedUserId])

  const setSelectedUser = useCallback(
    (id: string | null) => {
      goToUsersList({ selectedUserId: id })
    },
    [goToUsersList]
  )

  return { selectedUser, setSelectedUser }
}
