import { gql } from '@apollo/client'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { getContactName } from '@npco/mp-utils-contacts-format'
import { useTranslations } from '@npco/utils-translations'
import { PillSize } from '@npco/zeller-design-system'

import { DrawerHeading } from 'pages/DebitCardTransactions/TransactionDrawer/TransactionDrawerContent/TransactionHeader/DrawerHeader/DrawerHeader'
import { translations } from 'translations/shared.i18n'

import { ContactHeadingContactFragment } from './ContactHeading.generated'
import * as styled from './ContactHeading.styled'

type ContactHeaderProps = {
  contact: ContactHeadingContactFragment
}

export const ContactHeading = ({ contact }: ContactHeaderProps) => {
  const tShared = useTranslations(translations)
  const title = getContactName(contact)

  return (
    <DrawerHeading>
      <IconAvatar
        icon={
          contact.icon
            ? { ...contact.icon, animation: null, image: null }
            : null
        }
        defaultLetter={title ?? undefined}
      />
      <DrawerHeading.Title
        title={title}
        pill={
          contact.isSelf ? (
            <styled.Pill text={tShared('you')} size={PillSize.Small} />
          ) : undefined
        }
      />
    </DrawerHeading>
  )
}

ContactHeading.fragments = {
  Contact: gql`
    fragment ContactHeadingContactFragment on Contact {
      ...GetContactNameContactFragment
      icon {
        images {
          size
          url
        }
        colour
        letter
      }
      isSelf
    }
    ${getContactName.fragments.Contact}
  `,
}
