import { useEffect } from 'react'
import { Box, Flex, ModalBasic } from '@npco/zeller-design-system'

import {
  CardFormatOption,
  CardTypeOption,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'
import {
  MultiStageModal,
  useMultiStageModalProgressBar,
} from 'components/MultiStageModal'

import { CardDesignReview } from './CardDesignReview/CardDesignReview'
import { CardDetailsReview } from './CardDetailsReview/CardDetailsReview'
import { CorporateCardBillingReview } from './CorporateCardBillingReview/CorporateCardBillingReview'
import { DeliveryAddressReview } from './DeliveryAddressReview/DeliveryAddressReview'
import { useReviewCard } from './hooks/useReviewCard'
import { ReviewDetailContainer } from './ReviewCard.styled'
import { SpendManagementControlReview } from './SpendManagementControlReview/SpendManagementControlReview'

interface ReviewCardProps {
  dataTestId?: string
  setIsCloseButtonHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReviewCard = ({
  dataTestId,
  setIsCloseButtonHidden,
}: ReviewCardProps) => {
  const {
    state,
    goBackToCardDesignStage,
    goBackToCardTypeStage,
    goBackToDeliveryAddressStage,
    goBackToSpendManagementControlStage,
    handleConfirm,
    isLoading,
  } = useReviewCard()
  useMultiStageModalProgressBar({ shouldHideProgressBar: true })

  useEffect(() => {
    setIsCloseButtonHidden(isLoading)
  }, [isLoading, setIsCloseButtonHidden])

  return (
    <>
      <ModalBasic.Body>
        <Flex
          data-testid={dataTestId}
          flexDirection={['column', 'row']}
          width={1}
        >
          <Box minWidth={260} pr={['0', '40px']} pb={['24px', '0']}>
            <CardDesignReview
              cardColour={state.colour}
              isLoading={isLoading}
              onEdit={goBackToCardDesignStage}
            />
          </Box>

          <ReviewDetailContainer>
            {state.cardType === CardTypeOption.ExpenseCard && (
              <CorporateCardBillingReview />
            )}
            <CardDetailsReview
              state={state}
              isLoading={isLoading}
              onEdit={goBackToCardTypeStage}
            />
            {state.velocityControl &&
              state.cardType === CardTypeOption.ExpenseCard && (
                <SpendManagementControlReview
                  velocityControl={state.velocityControl}
                  isLoading={isLoading}
                  onEdit={goBackToSpendManagementControlStage}
                />
              )}
            {state.address &&
              state.cardFormat === CardFormatOption.Physical && (
                <DeliveryAddressReview
                  address={state.address}
                  isLoading={isLoading}
                  onEdit={goBackToDeliveryAddressStage}
                />
              )}
          </ReviewDetailContainer>
        </Flex>
      </ModalBasic.Body>
      <MultiStageModal.FooterButtons
        onCancel={
          state.cardFormat === CardFormatOption.Virtual
            ? goBackToCardDesignStage
            : goBackToDeliveryAddressStage
        }
        isLoading={isLoading}
        isCancelDisabled={isLoading}
        onConfirm={handleConfirm}
        nextButtonLabel={translate('shared.confirm')}
      />
    </>
  )
}
