import { useNavigate } from 'react-router-dom-v5-compat'
import { CustomerType } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { rvKYCData } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/kyc/kyc'

import { ROOT } from 'const/routes'
import {
  getVerificationLinkVisibility,
  getVerificationStatus,
} from 'utils/kyc/kycIdVerification.utils'

import {
  Section,
  SectionButtonLink,
  SectionDescription,
  SectionLabelValue,
  SectionTitle,
} from './components'
import { translations } from './IDVerificationSection.i18n'

export interface IDVerificationSectionProps {
  status: CustomerType['kycStatus']
}

export const IDVerificationSection = ({
  status,
}: IDVerificationSectionProps) => {
  const t = useTranslations(translations)

  const navigate = useNavigate()
  const verificationStatus = getVerificationStatus(status)
  const isVerifyButtonVisible = getVerificationLinkVisibility(status)
  const shortEntityId = useSelectedShortEntityUuid()

  const onVerifyMyIDClick = () => {
    rvKYCData({
      ...rvKYCData(),
      entryPointRoute: ROOT.ORGS.ORG(shortEntityId).SETTINGS.PROFILE.path,
    })
    navigate(ROOT.ORGS.ORG(shortEntityId).KYC.YOUR_INFORMATION.path)
  }

  return (
    <Section>
      <SectionTitle>{t('title')}</SectionTitle>
      <SectionLabelValue
        label={t('statusLabel')}
        value={verificationStatus.display}
      />
      <SectionDescription>{t('description')}</SectionDescription>
      {isVerifyButtonVisible && (
        <div>
          <SectionButtonLink onClick={onVerifyMyIDClick}>
            {t('verifyMyId')}
          </SectionButtonLink>
        </div>
      )}
    </Section>
  )
}
