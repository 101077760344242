import { Flex, StyledGhostButton } from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

export const StyledTabContainer = styled(Flex)`
  flex-direction: row;
  gap: 1rem;
`

export const StyledTab = styled.div<{ active: boolean }>`
  ${StyledGhostButton} {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.BLUE_80 : 'inital'};
  }
`
