import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetEntityTags } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/entityTags'

import {
  GetEntityTags as GetEntityTagsResponse,
  GetEntityTagsVariables,
} from 'types/gql-types/GetEntityTags'

interface UseEntityTagsProps {
  skip?: boolean
}

export const useEntityTags = ({ skip }: UseEntityTagsProps = {}) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading: isLoadingEntityTags } = useQuery<
    GetEntityTagsResponse,
    GetEntityTagsVariables
  >(GetEntityTags, { skip, variables: { entityUuid } })

  const entityTags = useMemo(() => data?.getEntityTags ?? [], [data])

  return {
    entityTags,
    isLoadingEntityTags,
  }
}
