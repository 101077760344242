import BalanceBar from 'assets/animations/corporate-card-walkthrough/balance-bar-animation.json'
import { AnimationBase } from 'components/AnimationBase/AnimationBase'

import { AnimationContainer } from '../ToolTipGraphics.styled'

export const BalanceBarAnimation = () => {
  return (
    <AnimationContainer data-testid="balance-bar-animation">
      <AnimationBase animationData={BalanceBar} width="200px" height="65px" />
    </AnimationContainer>
  )
}
