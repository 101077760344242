import { Flex } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { commonFoldedLinkStyles, commonLinkStyles } from '../../Sidebar.styled'

const blueIconStyle = css`
  svg path {
    fill: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

export const IconWrapper = styled.div<{
  $isOpen: boolean
  $isFolded: boolean
}>`
  ${({ $isOpen }) => $isOpen && blueIconStyle}
  display: ${({ $isFolded }) => ($isFolded ? 'none' : 'block')};
`

export const ItemToggleWrapper = styled(Flex)<{
  $isFolded: boolean
  $isDisabled?: boolean
}>`
  ${commonLinkStyles};
  background: none;
  border: 0;
  cursor: ${({ $isDisabled }) => (!$isDisabled ? 'pointer' : 'default')};
  overflow: hidden;

  &.active:not(.unselected) {
    background-color: ${({ theme }) => theme.colors.BLUE_80};
  }
  ${({ $isFolded }) => commonFoldedLinkStyles($isFolded)}
`

export const SubitemsWrapper = styled.ul`
  margin: 0;
  z-index: 2;
`

export { Title } from '../../Sidebar.styled'
