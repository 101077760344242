import { ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { copyToClipboard } from 'utils/common'
import { translate } from 'utils/translations'

const StyledCopyButton = styled(ButtonLink)<{
  paddingLeft?: string
}>`
  position: absolute;
  padding-left: ${({ paddingLeft }) => paddingLeft ?? '0'};
  transform: translateX(100%); //move the button to the right
  bottom: 0;
  right: 0;
`
type CopyButtonProps = {
  value: string | null | undefined
  title: string
  buttonTestId: string
  paddingLeft?: string
}

export const CopyButton = ({
  value,
  title,
  buttonTestId,
  paddingLeft,
}: CopyButtonProps) => {
  if (!value) {
    return null
  }

  return (
    <StyledCopyButton
      paddingLeft={paddingLeft}
      title={title}
      dataTestId={buttonTestId}
      onClick={() => copyToClipboard(value)}
    >
      {translate('page.settings.accountDetails.actionCopy')}
    </StyledCopyButton>
  )
}
