import { createTranslations } from '@npco/utils-translations'

export const cardDetailsReviewTranslations = createTranslations({
  detailsTitle: 'General',
  cardholderLabel: 'Cardholder',
  nicknameLabel: 'Card Nickname',
  typeLabel: 'Type',
  accountLabel: 'Linked Account',
  cardType: '{cardFormat} · {cardType}',
  debitCard: 'Debit Card',
  corporateCard: 'Corporate Card',
  debitCardAccessible: 'Debit Accessible Card',
  nicknameDefault: "{firstName}'s {cardTypeDisplay}",
})
