import { forwardRef } from 'react'

import { StyledTooltipWrapper } from '../PerformanceChart.styled'

interface Props {
  content: React.ReactNode
  className?: string
}

export const PerformanceTooltip = forwardRef<HTMLDivElement, Props>(
  ({ content, className }: Props, ref) => {
    return (
      <StyledTooltipWrapper className={className} ref={ref}>
        {content}
      </StyledTooltipWrapper>
    )
  }
)
