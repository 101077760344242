import {
  BodyDefaultTypography,
  DataMediumTypography,
  DrawerBasic,
  Flex,
  H4,
  InterZellerMediumFont,
  Status,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledUserDetails = styled.div`
  width: 26.25rem;
  height: 100vh;
  overflow-y: auto;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    width: 100vw;
  }
`

export const StyledDetailsTitle = styled(H4)<{
  $color?: string
}>`
  color: ${({ $color, theme }) => $color ?? theme.colors.BLACK};
  line-height: 1.45;
  margin: 0;
  max-width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    max-width: 18rem;
  }
`

export const StyledWrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 0.5rem;
`

export const StyledDetailsLabel = styled.span`
  color: ${({ theme }) => theme.colors.BLACK_900};
  ${InterZellerMediumFont};
  ${BodyDefaultTypography};
  white-space: nowrap;
`

export const StyledDescription = styled.span`
  color: ${({ theme }) => theme.colors.BLACK_900};
  ${BodyDefaultTypography};
`

export const StyledDetailsValue = styled.div`
  ${DataMediumTypography};
  color: ${({ theme }) => theme.colors.BLACK_900};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 1rem;
`

export const StyledStatusBadgeWrapper = styled.div`
  width: 6rem;
  height: 2rem;
`

export const StyledStatusBadge = styled(Status)`
  justify-content: flex-start;
`

export const StyledUserDrawer = styled(DrawerBasic)`
  padding: 0;

  .drawer-header {
    padding-top: 14px;
  }

  .drawer-title-container {
    margin-bottom: 9px;
  }

  .drawer-content {
    padding-top: 20px;
  }
`
