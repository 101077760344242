import { useCallback } from 'react'
import { useField } from 'formik'

import { getInputAmount } from 'utils/numbers'

interface UseCompleteDecimalOnBlurProps {
  fieldName: string
  decimalPlaces?: number
}

export const useCompleteAmountDecimalOnBlur = ({
  fieldName,
  decimalPlaces = 2,
}: UseCompleteDecimalOnBlurProps) => {
  const [field, , handlers] = useField(fieldName)

  const onBlurHandler = useCallback(() => {
    const parsedValue = Number.parseFloat(field.value)
    if (Number.isNaN(parsedValue)) {
      return
    }
    handlers.setValue(getInputAmount(parsedValue, decimalPlaces))
  }, [field.value, handlers, decimalPlaces])

  return {
    onBlurHandler,
  }
}
