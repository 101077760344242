import { gql } from '@apollo/client'

import { InvoiceCoreFields } from './invoiceCoreFieldsFragment'

export const CreateInvoice = gql`
  ${InvoiceCoreFields}
  mutation CreateInvoice($entityUuid: ID!, $invoice: CreateInvoiceInput!) {
    createInvoice(entityUuid: $entityUuid, input: $invoice) {
      ...InvoiceCoreFields
    }
  }
`
