import { useLocationState } from '@npco/utils-routing'

import { ContactTransferModalLocationStateSchema } from '../../ContactTransferModal.types'

export const useContactTransferModalState = () => {
  const maybeContactTransferModalState = useLocationState(
    ContactTransferModalLocationStateSchema
  )

  return { state: maybeContactTransferModalState?.ContactTransferModal ?? null }
}
