import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SharedProgressIndicatorWrapper = styled(Flex)`
  width: 100%;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-bottom: 4px;
  }
`
