import { useTranslations } from '@npco/utils-translations'
import { ModalBasic } from '@npco/zeller-design-system'

import { useMultiStageModalProgressBar } from 'components/MultiStageModal'
import { FooterButtons } from 'components/MultiStageModal/FooterButtons'
import { translationsShared } from 'translations'

import { TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES } from '../../CreateExternalAccountModal.utils'
import { ExternalAccountReviewContent } from './ExternalAccountReviewContent/ExternalAccountReviewContent'
import { translations } from './ExternalAccountReviewStage.i18n'
import { useExternalAccountReviewStage } from './hooks/useExternalAccountReviewStage'

export const ExternalAccountReviewStage = () => {
  const tShared = useTranslations(translationsShared)

  const t = useTranslations(translations)
  const {
    state,
    handleConfirm,
    goBackToCreateExternalAccountFormStage,
    isLoadingAddThirdPartyAccount,
  } = useExternalAccountReviewStage()

  useMultiStageModalProgressBar({
    newCurrentStep: 19,
    newTotalSteps: TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES,
  })

  return (
    <>
      <ModalBasic.Body data-testid="external-account-review-stage">
        {state && (
          <ExternalAccountReviewContent
            isLoading={isLoadingAddThirdPartyAccount}
            values={state}
          />
        )}
      </ModalBasic.Body>
      <FooterButtons
        shouldShowBackButton
        onCancel={goBackToCreateExternalAccountFormStage}
        backButtonLabel={tShared('back')}
        nextButtonLabel={t('submitButton')}
        onConfirm={handleConfirm}
        isConfirmDisabled={isLoadingAddThirdPartyAccount}
        isCancelDisabled={isLoadingAddThirdPartyAccount}
      />
    </>
  )
}
