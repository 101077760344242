import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { SummaryTableLoadingRow } from 'components/SummaryTable/SummaryTableLoadingRow'

export const PerformanceLoadingSummary = () => {
  return (
    <SummaryTable>
      <SummaryTableLoadingRow
        leftColumnSkeletonWidth={144}
        rightColumnSkeletonWidth={104}
      />
      <SummaryTableLoadingRow
        leftColumnSkeletonWidth={112}
        rightColumnSkeletonWidth={64}
      />
      <SummaryTableLoadingRow
        leftColumnSkeletonWidth={64}
        rightColumnSkeletonWidth={44}
      />
      <SummaryTableLoadingRow
        leftColumnSkeletonWidth={80}
        rightColumnSkeletonWidth={80}
      />
      <SummaryTableLoadingRow
        leftColumnSkeletonWidth={80}
        rightColumnSkeletonWidth={80}
      />
      <SummaryTableLoadingRow
        leftColumnSkeletonWidth={144}
        rightColumnSkeletonWidth={104}
      />
    </SummaryTable>
  )
}
