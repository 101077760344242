import { useCallback } from 'react'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { translate } from 'utils/translations'
import { AnalyticsEventNames } from 'services/Analytics/events'

import { useCancelInvoice } from '../hooks/useCancelInvoice'
import { useInvoiceDecisionModal } from '../hooks/useInvoiceDecisionModal'
import { InvoiceDecisionModalTypes } from '../InvoiceDrawerDetails.utils'

export const translations = {
  confirm: translate('shared.confirm'),
  close: translate('shared.close'),
  title: translate(
    'component.invoiceDrawer.decisionModals.cancelInvoice.title'
  ),
  body: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.cancelInvoice.body', {
      referenceNumber,
    }),
  successToast: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.cancelInvoice.success', {
      referenceNumber,
    }),
  errorToast: (referenceNumber: string) =>
    translate('component.invoiceDrawer.decisionModals.cancelInvoice.error', {
      referenceNumber,
    }),
}

export const CancelInvoiceModal = () => {
  const {
    activeModal,
    closeModal,
    details: { id, referenceNumber },
  } = useInvoiceDecisionModal()
  const { cancelInvoice, isCancellingInvoice } = useCancelInvoice()
  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleCancelInvoice = useCallback(async () => {
    try {
      trackInvoiceAction(AnalyticsEventNames.INVOICE_CANCELLED)
      const response = await cancelInvoice(referenceNumber)

      if (!response.data?.cancelInvoice) {
        showErrorToast(translations.errorToast(referenceNumber))
        return
      }
      showSuccessToast(translations.successToast(referenceNumber))
    } catch {
      showErrorToast(translations.errorToast(referenceNumber))
    } finally {
      closeModal()
    }
  }, [cancelInvoice, closeModal, referenceNumber, trackInvoiceAction])

  return (
    <DecisionModal
      isOpen={activeModal === InvoiceDecisionModalTypes.CancelInvoice}
      isLoadingPrimary={isCancellingInvoice}
      onCancel={closeModal}
      onClickPrimary={handleCancelInvoice}
      onClickSecondary={closeModal}
      primaryButtonLabel={translations.confirm}
      secondaryButtonLabel={translations.close}
      title={translations.title}
    >
      {translations.body(referenceNumber)}
    </DecisionModal>
  )
}
