import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { GetImageUrlIconFragmentDoc } from '@npco/mp-utils-icon'

export type GetContactAvatarOptionsContactFragment = {
  __typename?: 'Contact'
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export const GetContactAvatarOptionsContactFragmentDoc = gql`
  fragment GetContactAvatarOptionsContactFragment on Contact {
    icon {
      colour
      letter
      ...GetImageUrlIconFragment
    }
  }
  ${GetImageUrlIconFragmentDoc}
` as unknown as TypedDocumentNode<
  GetContactAvatarOptionsContactFragment,
  undefined
>
