import { useEffect } from 'react'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useGetContact } from 'features/Contacts/hooks/useGetContact/useGetContact'
import { setNestedObjectValues, useFormikContext } from 'formik'

import { GetInvoice_getInvoice as GetInvoice } from 'types/gql-types/GetInvoice'

import { GetZellerInvoiceSettingsQueryResponse } from '../../../../Settings/graphql/getZellerInvoiceSettings.generated'
import { useSubmitFormValidation } from '../../hooks/useSubmitFormValidation'
import { InvoiceFormFields } from '../../Invoice.types'
import { InvoiceFormAccordions } from '../InvoiceFormAccordions/InvoiceFormAccordions'
import { INVOICE_ACCORDION_ID } from '../InvoiceFormAccordions/InvoiceFormAccordions.constants'
import { InvoiceSkeletonLoader } from '../InvoiceSkeletonLoader/InvoiceSkeletonLoader'
import { convertGetInvoiceResponseToFormFields } from './InvoiceEditForm.utils'

interface InvoiceEditFormContentProps {
  invoice: GetInvoice | null
  invoiceSettings:
    | GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']
    | undefined
  isInvoiceScheduled: boolean
  isSendingInvoice: boolean
  shouldResetInitialExpandedItem: boolean
}

export const InvoiceEditFormContent = ({
  invoice,
  invoiceSettings,
  isSendingInvoice,
  shouldResetInitialExpandedItem,
  isInvoiceScheduled,
}: InvoiceEditFormContentProps) => {
  const { resetForm, status, validateForm } =
    useFormikContext<InvoiceFormFields>()

  useSubmitFormValidation({ isSendingInvoice, isInvoiceScheduled })

  const { loading: isLoadingContact } = useGetContact({
    id: invoice?.customer?.payerContact?.contactUuid,
  })

  useEffect(() => {
    if (!invoice || !invoiceSettings || status.hasResetInitialValues) {
      return
    }

    const values = convertGetInvoiceResponseToFormFields(
      invoice,
      invoiceSettings
    )

    // NOTE: once we've reset the initial values set status as we want to
    // prevent this from occurring again
    resetForm({
      errors: {},
      status: { ...status, hasResetInitialValues: true },
      touched: setNestedObjectValues(values, true),
      values,
    })

    // NOTE: resetting the form does not revalidate the form so call directly
    // to ensure any validation errors will be reset with the latest values
    validateForm(values)
  }, [
    invoice,
    invoiceSettings,
    isSendingInvoice,
    status,
    resetForm,
    validateForm,
  ])

  if (isLoadingContact) {
    return <InvoiceSkeletonLoader dataTestId="invoice-edit-loader" />
  }

  return (
    <InvoiceFormAccordions
      initialExpandedItem={
        invoice?.status !== InvoiceStatus.DRAFT
          ? INVOICE_ACCORDION_ID.TITLE
          : INVOICE_ACCORDION_ID.CUSTOMER
      }
      shouldResetInitialExpandedItem={shouldResetInitialExpandedItem}
    />
  )
}
