import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import {
  Accordion,
  Animation,
  slideDownAnimationVariants,
} from '@npco/zeller-design-system'
import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_ITEMS_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { useInvoiceItems } from './hooks/useInvoiceItems'
import { InvoiceItemCalculator } from './InvoiceItemCalculator/InvoiceItemCalculator'
import { InvoiceItemCreateModal } from './InvoiceItemCreateModal/InvoiceItemCreateModal'
import { InvoiceItemDescription } from './InvoiceItemDescription/InvoiceItemDescription'
import { InvoiceItemDiscount } from './InvoiceItemDiscount/InvoiceItemDiscount'
import { InvoiceItemDiscountModal } from './InvoiceItemDiscountModal/InvoiceItemDiscountModal'
import { InvoiceItemName } from './InvoiceItemName/InvoiceItemName'
import { InvoiceItemPopper } from './InvoiceItemPopper/InvoiceItemPopper'
import { InvoiceItemPrice } from './InvoiceItemPrice/InvoiceItemPrice'
import {
  InvoiceItemCalculatorWrapper,
  InvoiceItemDescriptionAndPriceWrapper,
  InvoiceItemDescriptionWrapper,
  InvoiceItemDiscountWrapper,
  InvoiceItemMoreOptionsWrapper,
  InvoiceItemNameWrapper,
  InvoiceItemPriceWrapper,
  InvoiceItemsWrapper,
  InvoiceItemWrapper,
  InvoiceRowWrapper,
} from './InvoiceItems.styled'
import { InvoiceItemUpdateModal } from './InvoiceItemUpdateModal/InvoiceItemUpdateModal'

interface InvoiceItemsProps {
  onRemoveLineItem: (index: number) => void
}

export const InvoiceItems = ({
  onRemoveLineItem: handleOnRemoveLineItem,
}: InvoiceItemsProps) => {
  const { setFieldValue, values } = useFormikContext<InvoiceFormFields>()

  const {
    addItemDiscountIndexRef,
    handleOnAddItemDiscount,
    handleOnCancelUpdateItem,
    handleOnCloseItemDiscount,
    handleOnCloseSaveItem,
    handleOnSaveCatalogItem,
    handleOnSaveItem,
    handleOnUpdateItem,
    initialItemsValuesRef,
    isItemDiscountModalOpen,
    isSaveItemModalOpen,
    isUpdateItemModalOpen,
    isUpdatingItem,
    saveItemInitialValuesRef,
    updateItemIndexRef,
  } = useInvoiceItems({ setFieldValue, values })

  const items = values.items.map((item, index) => {
    const lineIndex = index + 1

    const shouldDisableAnimation = Boolean(
      initialItemsValuesRef.current?.[index]
    )

    const isDiscountApplied =
      item.discount.config === InvoiceDiscountConfig.PERCENTAGE
        ? item.discount.percentage !== INVOICE_DEFAULT_PERCENTAGE
        : item.discount.price !== INVOICE_DEFAULT_PRICE

    const isDiscountsEnabled = values.discountsEnabled
    const isTaxInclusive = values.itemsTaxInclusive

    return (
      <Animation
        disableInitialAnimation={shouldDisableAnimation}
        variants={slideDownAnimationVariants}
        key={item.key}
      >
        <InvoiceItemWrapper
          data-testid={`invoicing-invoice-line-item-wrapper-${lineIndex}`}
        >
          <InvoiceRowWrapper
            data-testid={`invoicing-invoice-line-item-details-row-${lineIndex}`}
          >
            <InvoiceItemNameWrapper>
              <InvoiceItemName
                index={index}
                lineIndex={lineIndex}
                name={`${INVOICE_ITEMS_FIELD}.${index}.name`}
              />
            </InvoiceItemNameWrapper>

            <InvoiceItemDescriptionAndPriceWrapper>
              <InvoiceItemDescriptionWrapper>
                <InvoiceItemDescription
                  lineIndex={lineIndex}
                  name={`${INVOICE_ITEMS_FIELD}.${index}.description`}
                />
              </InvoiceItemDescriptionWrapper>

              <InvoiceItemCalculatorWrapper>
                <InvoiceItemCalculator
                  index={index}
                  lineIndex={lineIndex}
                  name={`${INVOICE_ITEMS_FIELD}.${index}`}
                />
              </InvoiceItemCalculatorWrapper>

              <InvoiceItemPriceWrapper>
                <InvoiceItemPrice
                  isTaxApplicable={item.taxApplicable}
                  isTaxInclusive={isTaxInclusive}
                  lineIndex={lineIndex}
                  name={`${INVOICE_ITEMS_FIELD}.${index}`}
                />
              </InvoiceItemPriceWrapper>
            </InvoiceItemDescriptionAndPriceWrapper>

            <InvoiceItemMoreOptionsWrapper>
              <Accordion.MoreOptionsButton
                // NOTE: add aria-label prop to accordion more options button
                dataTestId={`invoicing-invoice-line-item-more-options-${lineIndex}`}
                popperWidth="220px"
              >
                {(state: {
                  isOpen: boolean
                  setIsOpen: (isOpen: boolean) => void
                }) => (
                  <InvoiceItemPopper
                    index={index}
                    // NOTE: until inline saving/creating items is supported, hardcode to false
                    isCatalogItem={false}
                    isCatalogItemModified={false}
                    isDiscountsEnabled={isDiscountsEnabled}
                    isOpen={state.isOpen}
                    isTaxInclusive={isTaxInclusive}
                    itemsCount={items.length}
                    name={`${INVOICE_ITEMS_FIELD}.${index}`}
                    onAddItemDiscount={handleOnAddItemDiscount(index)}
                    onRemoveLineItem={handleOnRemoveLineItem}
                    onSaveItem={handleOnSaveItem(index)}
                    onUpdateItem={handleOnUpdateItem(index)}
                    setIsOpen={state.setIsOpen}
                  />
                )}
              </Accordion.MoreOptionsButton>
            </InvoiceItemMoreOptionsWrapper>
          </InvoiceRowWrapper>
          {isDiscountApplied && (
            <InvoiceRowWrapper
              data-testid={`invoicing-invoice-line-item-discount-row-${lineIndex}`}
              mr="24px"
            >
              <InvoiceItemDiscountWrapper>
                <InvoiceItemDiscount
                  isTaxApplicable={item.taxApplicable}
                  lineIndex={lineIndex}
                  name={`${INVOICE_ITEMS_FIELD}.${index}`}
                  onAddItemDiscount={handleOnAddItemDiscount(index)}
                />
              </InvoiceItemDiscountWrapper>
            </InvoiceRowWrapper>
          )}
        </InvoiceItemWrapper>
      </Animation>
    )
  })

  return (
    <>
      <InvoiceItemsWrapper data-testid="invoicing-invoice-line-items">
        {items}
      </InvoiceItemsWrapper>
      {isItemDiscountModalOpen &&
        addItemDiscountIndexRef.current !== undefined && (
          <InvoiceItemDiscountModal
            index={addItemDiscountIndexRef.current}
            onClose={handleOnCloseItemDiscount}
          />
        )}
      {isSaveItemModalOpen &&
        saveItemInitialValuesRef.current !== undefined && (
          <InvoiceItemCreateModal
            initialValues={saveItemInitialValuesRef.current}
            onClose={handleOnCloseSaveItem}
            onComplete={handleOnSaveCatalogItem}
          />
        )}
      {isUpdateItemModalOpen && updateItemIndexRef.current !== undefined && (
        <InvoiceItemUpdateModal
          index={updateItemIndexRef.current}
          isLoading={isUpdatingItem}
          onCancel={handleOnCancelUpdateItem}
          onConfirm={handleOnUpdateItem(updateItemIndexRef.current)}
        />
      )}
    </>
  )
}
