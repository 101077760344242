import { ApolloCache } from '@apollo/client'

interface UpdateCacheOnPairOptions {
  cache: ApolloCache<any>
  id: string
  workstationId?: string
}
export const updateCacheOnPair = ({
  cache,
  id,
  workstationId,
}: UpdateCacheOnPairOptions) => {
  cache.modify({
    id: cache.identify({
      __typename: 'Device',
      id,
    }),
    fields: {
      posSettings(existingPosSettings) {
        return {
          ...existingPosSettings,
          oracleSettings: {
            workstationId,
          },
        }
      },
    },
  })
  cache.modify({
    id: cache.identify({
      __typename: 'DeviceSettings',
      id,
    }),
    fields: {
      posSettings(existingPosSettings) {
        return {
          ...existingPosSettings,
          oracleSettings: {
            workstationId,
          },
        }
      },
    },
  })
}
