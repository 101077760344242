import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { INFOBOX_VARIANT } from '@npco/zeller-design-system'

import { ExpenseCardBudgetMessage } from '../components/ExpenseCardBudgetMessage'
import { expenseCardBudgetOverdrawnTranslations } from './ExpenseCardBudgetOverdrawnErrorMessage.i18n'

interface ExpenseCardBudgetOverdrawnErrorMessageProps {
  isAdmin: boolean
  availableFunds: number
  onEdit: () => void
}

export const ExpenseCardBudgetOverdrawnErrorMessage = ({
  isAdmin,
  availableFunds,
  onEdit,
}: ExpenseCardBudgetOverdrawnErrorMessageProps) => {
  const t = useTranslations(expenseCardBudgetOverdrawnTranslations)
  return (
    <ExpenseCardBudgetMessage
      dataTestId="expense-card-budget-overdrawn-error-message"
      variant={INFOBOX_VARIANT.DANGER}
      isAdmin={isAdmin}
      messageAdmin={
        availableFunds === 0
          ? t('messageAdminZeroBalance')
          : t('messageAdmin', {
              exceededAmount: currencyFormatter(Math.abs(availableFunds)),
            })
      }
      messageNonAdmin={t('message')}
      buttonMessage={t('editButton')}
      onClick={onEdit}
    />
  )
}
