import { ApolloError, gql } from '@apollo/client'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { IconAvatar } from '@npco/mp-ui-icon-avatar'
import { useTranslations } from '@npco/utils-translations'
import {
  AVATAR_BADGE_SIZE,
  Box,
  Flex,
  InlineLoaderVariant,
  InlineSpinner,
  InlineSpinnerSize,
  Message,
  MessageSize,
  PopperItemNew,
} from '@npco/zeller-design-system'

import { accountSelectTranslations } from '../../AccountSelect.i18n'
import { PopperItemsContentDebitCardAccountV2Fragment } from './PopperItemsContent.generated'

interface PopperItemsContentProps {
  accounts?: PopperItemsContentDebitCardAccountV2Fragment[]
  error: ApolloError | undefined
  isLoading: boolean
  refetch: () => void
  filteredAccounts: PopperItemsContentDebitCardAccountV2Fragment[] | undefined
  setSelectedAccount: ({
    id,
    type,
  }: {
    id: string
    type: DebitCardAccountType
  }) => void
  setIsOpen: (value: boolean) => void
  selectedAccountId?: string
}

export const PopperItemsContent = ({
  accounts,
  error,
  isLoading,
  refetch,
  filteredAccounts,
  setSelectedAccount,
  setIsOpen,
  selectedAccountId,
}: PopperItemsContentProps) => {
  const t = useTranslations(accountSelectTranslations)

  if (error && !accounts && !isLoading) {
    return (
      <Message
        title={t('failToLoadHeader')}
        description={t('failToLoadDescription')}
        size={MessageSize.LARGE}
        primaryButton={{
          label: t('failToLoadActionButton'),
          onClick: () => refetch(),
        }}
      />
    )
  }

  if (isLoading) {
    return (
      <Flex
        width="100%"
        justifyContent="center"
        height="175px"
        alignItems="center"
      >
        <InlineSpinner
          loaderVariant={InlineLoaderVariant.Grey}
          size={InlineSpinnerSize.Large}
        />
      </Flex>
    )
  }

  return (
    <Box>
      {filteredAccounts?.map((account) => (
        <PopperItemNew
          key={account.id}
          title={account.name}
          size="large"
          avatar={
            <IconAvatar
              icon={account.icon}
              badgeSize={AVATAR_BADGE_SIZE.SMALL}
            />
          }
          onClick={() => {
            setSelectedAccount({ id: account.id, type: account.type })
            setIsOpen(false)
          }}
          data-testid="popper-item-new"
          isSelected={selectedAccountId === account.id}
        />
      ))}
    </Box>
  )
}

PopperItemsContent.fragments = {
  DebitCardAccountV2: gql`
    fragment PopperItemsContentDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      name
      type
      icon {
        ...IconAvatarIconFragment
      }
    }

    ${IconAvatar.fragments.Icon}
  `,
}
