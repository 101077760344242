import { useCallback } from 'react'
import {
  BankAccountDetailsInput,
  PaymentInstrumentType,
} from '@npco/mp-gql-types'

import { useCreatePaymentInstrument } from './useCreatePaymentInstrument'

export const useCreateBsbPaymentInstrument = () => {
  const { createPaymentInstrument, loading } = useCreatePaymentInstrument()

  const createBsbPaymentInstrument = useCallback(
    async (bankAccountDetails: BankAccountDetailsInput, contactId?: string) => {
      const result = await createPaymentInstrument({
        contactUuid: contactId,
        type: PaymentInstrumentType.BSB,
        bankAccountDetails,
      })
      return result
    },
    [createPaymentInstrument]
  )

  return {
    isCreatingPaymentInstrument: loading,
    createPaymentInstrument: createBsbPaymentInstrument,
  }
}
