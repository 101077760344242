import { translate } from 'utils/translations'

import {
  StyledZellerPricing,
  StyledZellerPricingContainer,
} from './ZellerPricing.styled'

export const ZellerPricing = () => {
  return (
    <StyledZellerPricingContainer>
      <StyledZellerPricing
        dataTestId="zellerPricing"
        href="https://www.myzeller.com/pricing"
      >
        {translate('page.settings.feesAndPricing.learnMore')}
      </StyledZellerPricing>
    </StyledZellerPricingContainer>
  )
}
