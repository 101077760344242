import { BREAKPOINT, useIsMobileResolution } from '@npco/zeller-design-system'

import { useCashflowReportingTransactionsContext } from '../hooks/useCashflowReportingTransactionsContext'
import { useSelectedAccountId } from '../hooks/useSelectedAccount/useSelectedAccountId'
import { useSelectedAccountType } from '../hooks/useSelectedAccount/useSelectedAccountType'
import { CategoryOrContactDropdown } from './CategoryOrContactDropdown'
import { IncomeExpenseTabs, IncomeExpenseTabsMobile } from './IncomeExpenseTabs'
import * as styled from './TransactionsSummary.styled'
import { TransactionsSummaryCategory } from './TransactionsSummaryCategory'
import { TransactionsSummaryContact } from './TransactionsSummaryContact'

type TransactionsSummaryProps = {
  setContactUuid: React.Dispatch<React.SetStateAction<string | null>>
  isLoadingTimezone: boolean
  timezone: string | undefined
}

export const TransactionsSummary = ({
  setContactUuid,
  isLoadingTimezone,
  timezone,
}: TransactionsSummaryProps) => {
  const isMobile = useIsMobileResolution(BREAKPOINT.XS)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)
  const { contactOrCategorySelection } =
    useCashflowReportingTransactionsContext()

  const selectedAccountId = useSelectedAccountId()
  const selectedAccountType = useSelectedAccountType()

  const accountUuid =
    selectedAccountId === 'all accounts'
      ? undefined
      : selectedAccountId.accountId

  const { accountType } = selectedAccountType
  return (
    <>
      <styled.TransactionsSummaryHeader>
        <CategoryOrContactDropdown />
        {isMobile && <IncomeExpenseTabsMobile />}
        {isMobileOrTablet && !isMobile && <IncomeExpenseTabs />}
      </styled.TransactionsSummaryHeader>
      <styled.TransactionsSummaryContent>
        {contactOrCategorySelection === 'category' && (
          <TransactionsSummaryCategory
            isLoadingTimezone={isLoadingTimezone}
            timezone={timezone}
            accountUuid={accountUuid}
            accountType={accountType}
          />
        )}
        {contactOrCategorySelection === 'contact' && (
          <TransactionsSummaryContact
            isLoadingTimezone={isLoadingTimezone}
            timezone={timezone}
            accountUuid={accountUuid}
            setContactUuid={setContactUuid}
            accountType={accountType}
          />
        )}
      </styled.TransactionsSummaryContent>
    </>
  )
}
