import React from 'react'

/**
 * @link https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#sign-up
 */
export enum SignUpError {
  INVALID_PASSWORD = 'invalid_password',
  INVALID_SIGNUP = 'invalid_signup',
  PASSWORD_DICTIONARY_ERROR = 'password_dictionary_error',
  PASSWORD_NO_USER_INFO_ERROR = 'password_no_user_info_error',
  PASSWORD_STRENGTH_ERROR = 'password_strength_error',
  UNAUTHORISED = 'unauthorised',
  USER_EXISTS = 'user_exists',
  USERNAME_EXISTS = 'username_exists',
  EXTENSIBILITY_ERROR = 'extensibility_error',
}

export enum InvalidPasswordError {
  PASSWORD_NO_USER_INFO_ERROR = 'PasswordNoUserInfoError',
  PASSWORD_DICTIONARY_ERROR = 'PasswordDictionaryError',
  PASSWORD_STRENGTH_ERROR = 'PasswordStrengthError',
}

export type SignUpErrorMap = {
  [key in SignUpError]?: React.ReactNode
} & { default: React.ReactNode }

export type InvalidPasswordErrorMap = {
  [key in InvalidPasswordError]: React.ReactNode
}

export interface SignupFields {
  email: string
  password: string
  termsAndConditions: boolean
}
