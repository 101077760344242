import { BodyDefault, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledCardPaymentsTitle = styled(H3)`
  margin-top: 0.5rem;
  margin-bottom: 0;
`

export const StyledCardPaymentsDescription = styled(BodyDefault)`
  width: 100%;
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 31.5rem;
  }
  margin: 1.25rem 0;
`
