import { TypographyTypes } from '@npco/zeller-design-system'

import { getSkeletonTypographyByType } from './SkeletonLoaderTypography.utils'

export const SkeletonH1 = getSkeletonTypographyByType(TypographyTypes.H1)
export const SkeletonH2 = getSkeletonTypographyByType(TypographyTypes.H2)
export const SkeletonH3 = getSkeletonTypographyByType(TypographyTypes.H3)
export const SkeletonH4 = getSkeletonTypographyByType(TypographyTypes.H4)
export const SkeletonH5 = getSkeletonTypographyByType(TypographyTypes.H5)
export const SkeletonH6 = getSkeletonTypographyByType(TypographyTypes.H6)
export const SkeletonBodyLarge = getSkeletonTypographyByType(
  TypographyTypes.BodyLarge
)
export const SkeletonBodyDefault = getSkeletonTypographyByType(
  TypographyTypes.BodyDefault
)
export const SkeletonBodySmall = getSkeletonTypographyByType(
  TypographyTypes.BodySmall
)
