import { Flex, InfoFilledIcon, TooltipBasic } from '@npco/zeller-design-system'

import { StyledHeading } from './ReportsSectionHeading.styled'

interface HeadingProps {
  children: React.ReactNode
  tooltip?: React.ReactNode
}

export const ReportsSectionHeading = ({ children, tooltip }: HeadingProps) => (
  <StyledHeading>
    {children}{' '}
    {tooltip && (
      <TooltipBasic
        showArrow={false}
        renderTrigger={({ handlers }) => (
          <Flex ml="8px" {...handlers}>
            <InfoFilledIcon />
          </Flex>
        )}
      >
        {tooltip}
      </TooltipBasic>
    )}
  </StyledHeading>
)
