import { Box, BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const IncomeAndExpenseDatapointsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 150px);

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    display: block;
    max-width: min(648px, calc(100% - 200px));
    box-sizing: border-box;
    width: 100%;
  }
`

export const IncomeAndExpenseDatapointWrapper = styled(Box)`
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
`
