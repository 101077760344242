import { useMutation } from '@apollo/client'

import {
  UpdateDeviceName as UpdateDeviceNameType,
  UpdateDeviceNameVariables,
} from 'types/gql-types/UpdateDeviceName'

import { UpdateDeviceName } from '../graphql/UpdateDeviceName'

export const useUpdateDeviceName = () => {
  const [updateDeviceName, { loading, error, data }] = useMutation<
    UpdateDeviceNameType,
    UpdateDeviceNameVariables
  >(UpdateDeviceName)

  return {
    data,
    error,
    isLoading: loading,
    updateDeviceName,
  }
}
