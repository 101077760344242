import { Outlet, Route, Routes } from 'react-router-dom-v5-compat'
import { ManageBusinesses } from '@npco/mp-feature-manage-businesses'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { ROOT } from 'const/routes'
import { MultiEntityProvider } from 'services/MultiEntityProvider'
import { NotFound } from 'pages/NotFound'
import { PortalRoutes } from 'components/App/components/PortalRoutes/PortalRoutes'
import { TopBarNav } from 'components/TopBarNav'

export const MultiEntityRoutes = () => {
  const flags = useFeatureFlags()

  return (
    <Routes>
      <Route
        element={
          <MultiEntityProvider>
            <Outlet />
          </MultiEntityProvider>
        }
      >
        <Route
          path={ROOT.ORGS.MANAGE_BUSINESSES.relative}
          element={
            flags.MultiEntity ? (
              <>
                <TopBarNav hasLeftSection />
                <ManageBusinesses />
              </>
            ) : (
              <NotFound />
            )
          }
        />
        <Route
          path={`${ROOT.ORGS.ORG().relative}/*`}
          element={<PortalRoutes />}
        />
      </Route>
    </Routes>
  )
}
