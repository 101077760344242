import { Crop } from 'react-image-crop'
import { ErrorLogger } from '@npco/utils-error-logger'
import canvasSize from 'canvas-size'

import { getFileType } from 'utils/uploadImageToS3Bucket'

const deps = {
  canvasSize,
  getFileType,
}

export { deps as ImageCropperUtilsDeps }

export const initialCrop: Crop = {
  height: 50,
  unit: '%',
  width: 50,
  x: 25,
  y: 25,
}

const getFileName = ({ fileType }: { fileType?: string }) => {
  switch (fileType) {
    case 'image/png':
      return 'image.png'
    case 'image/jpeg':
    case 'image/jpg':
      return 'image.jpeg'
    default:
      return 'image.webp'
  }
}

export const generateImagePreview = (
  canvas: HTMLCanvasElement | null,
  crop: Crop | null,
  onCrop: (image: string) => void,
  fileType?: string
) => {
  if (!crop || !canvas) {
    return
  }

  try {
    const type = deps.getFileType({ fileType })
    const dataUrl = canvas.toDataURL(type)
    if (!dataUrl || dataUrl === 'data:,') {
      return
    }
    onCrop(dataUrl)
  } catch (error) {
    ErrorLogger.report('[Core] Cropping image', error)
  }
}

export const generateImagePreviewFile = async (
  canvas: HTMLCanvasElement | null,
  crop: Crop | null,
  onCropFile: (file: File) => void,
  fileType?: string
) => {
  if (!crop || !canvas) {
    return
  }

  try {
    const type = deps.getFileType({ fileType })
    const fileName = getFileName({ fileType })
    const blob = await new Promise((resolve) => {
      canvas.toBlob(resolve, type)
    })

    const file = new File([blob as Blob], fileName, {
      type,
    })

    onCropFile(file)
  } catch (error) {
    ErrorLogger.report('[Core] Cropping image', error)
  }
}

export const getCanvasConstraints = async () => {
  const defaults = { height: 4096, width: 4096, pixels: 4096 * 4096 }

  try {
    const area = await deps.canvasSize.maxArea()

    const constraints = area.success
      ? {
          height: area.height,
          width: area.width,
          pixels: area.height * area.width,
        }
      : defaults

    return constraints
  } catch {
    return defaults
  }
}

export const processFile = async (
  crop: Crop,
  previewCanvasRef: HTMLCanvasElement,
  image: HTMLImageElement
) => {
  const ctx = previewCanvasRef.getContext('2d')

  if (!ctx || !crop) {
    return
  }

  const constraints = await getCanvasConstraints()
  const pixelRatio = window.devicePixelRatio || 1

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  const cropWidth = crop.width * scaleX * pixelRatio
  const cropHeight = crop.height * scaleY * pixelRatio

  const cropAspectRatio = cropWidth / cropHeight

  let resizedCropWidth = cropWidth
  let resizedCropHeight = cropHeight

  if (resizedCropWidth > constraints.width) {
    resizedCropWidth = constraints.width
    resizedCropHeight = resizedCropWidth / cropAspectRatio
  }
  if (resizedCropHeight > constraints.height) {
    resizedCropHeight = constraints.height
    resizedCropWidth = resizedCropHeight * cropAspectRatio
  }

  ctx.canvas.width = Math.floor(resizedCropWidth)
  ctx.canvas.height = Math.floor(resizedCropHeight)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  ctx.save()

  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight, // Source crop area in the image
    0,
    0,
    resizedCropWidth,
    resizedCropHeight // Destination on the canvas
  )

  ctx.restore()
}
