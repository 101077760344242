import { gql } from '@apollo/client'
import { sortBy } from 'lodash-es'

import { getCustomerDisplayName } from 'utils/customers'
import dayjs from 'utils/dayjs'

import { SortableCardholderFragment as SortableCardholderFragmentType } from './cardholders.utils.generated'

const MAX_DAYS_CREATED_WITHIN = 5

export const getIsNewUser = (createdAt: any) =>
  dayjs(createdAt).isAfter(
    dayjs().startOf('day').subtract(MAX_DAYS_CREATED_WITHIN, 'days')
  )

const createdDate = <T extends SortableCardholderFragmentType>(cardholder: T) =>
  -dayjs(cardholder.createdAt).unix()

const displayNameCaseInsensitive = <T extends SortableCardholderFragmentType>(
  cardholder: T
) => getCustomerDisplayName(cardholder).toLowerCase()

export const sortCardholders = <T extends SortableCardholderFragmentType>(
  cardholders: T[],
  currentUserId: string
) => {
  const splitCardholders = {
    currentUser: [] as T[],
    newUsers: [] as T[],
    otherUsers: [] as T[],
  }

  const finalSections = cardholders.reduce((sections, cardholder) => {
    if (cardholder.id === currentUserId) {
      return { ...sections, currentUser: [cardholder] }
    }

    if (getIsNewUser(cardholder.createdAt)) {
      return { ...sections, newUsers: [...sections.newUsers, cardholder] }
    }

    return { ...sections, otherUsers: [...sections.otherUsers, cardholder] }
  }, splitCardholders)

  return [
    ...finalSections.currentUser,
    ...sortBy(finalSections.newUsers, createdDate),
    ...sortBy(finalSections.otherUsers, displayNameCaseInsensitive),
  ]
}

export const SortableCardholderFragment = gql`
  fragment SortableCardholderFragment on Customer {
    id
    entityUuid
    firstname
    lastname
    createdAt
  }
`
