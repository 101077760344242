// TICKET: BANK-557
import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import type { MutationResult } from './shared/MutationResult'
import type { Values } from './Values'

const MutationUpdate = gql`
  mutation Update($entityUuid: ID!, $config: XeroBankfeedsConfigInput!) {
    configureXeroBankfeeds(entityUuid: $entityUuid, config: $config) {
      organisationName
    }
  }
`

type MutationUpdateData = Record<string, never>

interface MutationUpdateVariables {
  entityUuid: string
  config: {
    bankAccountsEnabled: {
      debitCardAccountUuid: string | null
    }[]
  }
}

export const useMutationUpdate = (): [
  (values: Values) => MutationResult<never>
] => {
  const entityUuid = useSelectedEntityUuid()
  const [execute] = useMutation<MutationUpdateData, MutationUpdateVariables>(
    MutationUpdate,
    {
      onError: (error) => showApiErrorToast(error),
    }
  )
  return [
    useCallback(
      async (value) => {
        const { errors } = await execute({
          variables: {
            entityUuid,
            config: {
              bankAccountsEnabled: value.accounts.map((accountId) => ({
                debitCardAccountUuid: accountId,
              })),
            },
          },
        })
        return { data: undefined, error: errors }
      },
      [entityUuid, execute]
    ),
  ]
}

useMutationUpdate.gql = MutationUpdate
useMutationUpdate.gqlData = undefined as unknown as MutationUpdateData
useMutationUpdate.gqlVariables = undefined as unknown as MutationUpdateVariables
