import { Outlet } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'
import { AccountsAccessDenied } from 'features/Accounts/AccountsAccessDenied'

import { useAccountsIsAllowed } from 'hooks/banking'
import { translationsShared } from 'translations'

export const PrivateAccountsRouteDeps = {
  useAccountsIsAllowed,
}

export const PrivateAccountsRoute = () => {
  const { useAccountsIsAllowed } = PrivateAccountsRouteDeps

  const isAllowed = useAccountsIsAllowed()

  const t = useTranslations(translationsShared)

  if (!isAllowed) {
    return (
      <PageTemplate
        HeaderPrimaryRow={
          <PageTemplate.HeaderPrimaryRow>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              height="48px"
              width="100%"
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>{t('accounts')}</Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Flex>
          </PageTemplate.HeaderPrimaryRow>
        }
      >
        <AccountsAccessDenied />
      </PageTemplate>
    )
  }

  return <Outlet />
}
