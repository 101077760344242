import { ReactNode } from 'react'
import { SelectStyle } from '@npco/zeller-design-system'

import {
  SelectInputBasicProps,
  SelectItemBasic,
  SelectSize,
} from '../Select.types'
import { SelectBasic } from '../SelectBasic/SelectBasic'
import { SelectTriggerAdaptive } from '../SelectTriggerAdaptive'
import { StyledSelectCompactItem } from './SelectCompact.styled'

export interface Props<ItemType>
  extends Omit<
    SelectInputBasicProps<ItemType>,
    'renderItem' | 'renderTrigger'
  > {
  size?: SelectSize
  placeholder?: string
  label?: ReactNode
  renderItem?: SelectInputBasicProps<ItemType>['renderItem']
  renderTrigger?: SelectInputBasicProps<ItemType>['renderTrigger']
}

export const SelectCompact = <ItemType extends SelectItemBasic>({
  size = SelectSize.Medium,
  label,
  placeholder,
  isRequired,
  ...restProps
}: Props<ItemType>) => {
  return (
    <SelectBasic<ItemType>
      menuStyle={SelectStyle.Compact}
      renderTrigger={(renderProps) => {
        return (
          <SelectTriggerAdaptive
            {...renderProps}
            label={label || placeholder}
            placeholder={label ? placeholder : undefined}
            selectStyle={SelectStyle.Compact}
            size={size}
            isRequired={isRequired}
          />
        )
      }}
      renderItem={({ item, ...renderProps }) => (
        <StyledSelectCompactItem {...renderProps}>
          {item.label}
        </StyledSelectCompactItem>
      )}
      {...restProps}
    />
  )
}
