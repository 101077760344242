import { useMemo } from 'react'

import {
  CashflowReportingSubcategoriesNetAmountsQueryVariables,
  useCashflowReportingSubcategoriesNetAmountsQuery,
} from './graphql/getCashflowReportingSubcategoriesNetAmounts.generated'

export const useCashflowReportingSubcategoriesNetAmounts = (
  variables: CashflowReportingSubcategoriesNetAmountsQueryVariables
) => {
  const { data, loading, error } =
    useCashflowReportingSubcategoriesNetAmountsQuery({
      fetchPolicy: 'cache-and-network',
      variables,
    })

  const subcategories = useMemo(
    () => data?.getCashFlowSubcategoriesNetAmounts?.subcategoryNetAmounts ?? [],
    [data?.getCashFlowSubcategoriesNetAmounts?.subcategoryNetAmounts]
  )

  return {
    subcategories,
    isLoading: loading && subcategories.length === 0,
    error,
  }
}
