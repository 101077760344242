import { ButtonGhost, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledContactHeaderContainer = styled(Flex)`
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
  }
`

export const StyledContactHeaderButton = styled(ButtonGhost)`
  justify-content: flex-start;
`
