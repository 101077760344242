import { ButtonFill, StyledButtonContent } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ButtonDesktop = styled(ButtonFill)`
  ${StyledButtonContent} {
    gap: 10px;
  }

  padding: 12px 16px;
  max-height: 48px;
`

export const ButtonMobile = styled(ButtonFill)`
  line-height: 0;
  padding: 16px;
  max-height: 48px;
`
