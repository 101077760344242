import { useCallback } from 'react'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { rvSelectedAccountTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useUpdatedOutstandingTransactions } from 'features/Cards/CardSingle/hooks/useUpdatedOutstandingTransactions'
import { useGoToEditDebitCardTransactionNotes } from 'features/EditDebitCardTransactionNotes/edit-debit-card-transaction-notes-routing'

import {
  useUpdateDebitCardTransaction,
  useUpdateDebitCardTransactionRv,
} from 'hooks/banking'
import { translate } from 'utils/translations'
import { EditNotesModal } from 'components/EditNotesModal/EditNotesModal'

import { useTransactionNotes } from '../hooks/useTransactionNotes'

type EditDebitCardTransactionNotesProps = {
  debitCardTransactionUuid: string
  note: string
}

export const EditDebitCardTransactionNotes = ({
  debitCardTransactionUuid,
  note,
}: EditDebitCardTransactionNotesProps) => {
  const { goToEditDebitCardTransactionNotes } =
    useGoToEditDebitCardTransactionNotes()

  const closeModal = useCallback(() => {
    goToEditDebitCardTransactionNotes(null)
  }, [goToEditDebitCardTransactionNotes])

  const { updateDebitCardTransaction } = useUpdateDebitCardTransaction()
  const { updateTransactionRv } = useUpdateDebitCardTransactionRv()
  const { cacheCurrentUpdatedOutstandingTransaction } =
    useUpdatedOutstandingTransactions(debitCardTransactionUuid)

  const updateTransactionDataRv = useCallback(
    (newNote: string) => {
      const selectedTransaction = rvSelectedAccountTransaction()
      if (selectedTransaction) {
        rvSelectedAccountTransaction({
          ...selectedTransaction,
          note: newNote,
        })
      }
      cacheCurrentUpdatedOutstandingTransaction({
        update: { note: newNote },
      })
      updateTransactionRv({
        transactionUuid: debitCardTransactionUuid,
        update: { note: newNote },
      })
    },
    [
      updateTransactionRv,
      cacheCurrentUpdatedOutstandingTransaction,
      debitCardTransactionUuid,
    ]
  )

  const { saveNote, isLoading } = useTransactionNotes({
    transactionUuid: debitCardTransactionUuid,
    onSuccess: (newNote) => {
      updateTransactionDataRv(newNote)
      closeModal()
      showSuccessToast(
        !note
          ? translate('page.transactionNotes.addedSuccessfully')
          : translate('page.transactionNotes.updatedSuccessfully')
      )
      updateDebitCardTransaction(debitCardTransactionUuid, {
        note: newNote,
      })
    },
    onError: () =>
      showErrorToast(translate('page.transactionNotes.errorMessage')),
  })

  const handleSubmit = useCallback(
    (newNote: string) => {
      if ((!note && !newNote) || note === newNote) {
        closeModal()
        return
      }
      saveNote(newNote)
    },
    [saveNote, note, closeModal]
  )

  return (
    <EditNotesModal
      title={translate('page.transactionNotes.title')}
      notes={note || null}
      onCancel={closeModal}
      isOpen
      placeholder={translate('page.transactionNotes.editPlaceholder')}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  )
}
