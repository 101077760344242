import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { Condition } from 'components/Condition/Condition'

import { useAddCardState } from '../../../hooks/useAddCardState'
import { LinkEMLCardInfoStageContent } from './components/LinkEMLCardInfoStageContent'

export const LinkEMLCardInfoStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition shouldShow={state?.stage === AddCardStage.LinkEMLCardInfoStage}>
      <LinkEMLCardInfoStageContent />
    </Condition>
  )
}
