import { useCallback, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { v4 as randomUUID } from 'uuid'

import { getDragAndDropErrorMessage } from 'utils/getDropzoneErrorMessage'

export interface FileWithId {
  file: File
  id: string
}

export const useFileList = (maxFiles: number) => {
  const [files, setFiles] = useState<FileWithId[]>([])
  const [error, setError] = useState<string | undefined>(undefined)

  const addFiles = useCallback((filesToAdd: File[]) => {
    setError(undefined)

    setFiles((prevFiles) => [
      ...prevFiles,
      ...filesToAdd.map((file) => ({ file, id: randomUUID() })),
    ])
  }, [])

  const removeFile = useCallback((fileId: string) => {
    setFiles((prevFiles) =>
      prevFiles.filter((fileWithId) => fileWithId.id !== fileId)
    )
  }, [])

  const handleUploadRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const options = { maxFiles: maxFiles - files.length }
      const errorMessage = getDragAndDropErrorMessage(options, fileRejections)

      setError(errorMessage)
    },
    [files.length, maxFiles]
  )

  return { addFiles, removeFile, files, error, handleUploadRejected }
}
