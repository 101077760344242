import { gql, type TypedDocumentNode } from '@apollo/client'

export type GetContactOrMerchantLocationDataMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  location: string | null
  locationAccuracy: number | null
  address: {
    __typename?: 'MerchantAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street1: string | null
    street2: string | null
    suburb: string | null
  } | null
}

export type GetContactOrMerchantLocationDataContactFragment = {
  __typename?: 'Contact'
  location: string | null
  locationAccuracy: number | null
  address: {
    __typename?: 'ContactAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street: string | null
    suburb: string | null
  } | null
}

export const GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc = gql`
  fragment GetContactOrMerchantLocationDataMerchantDetailsFragment on MerchantDetails {
    address {
      country
      postcode
      state
      street1
      street2
      suburb
    }
    location
    locationAccuracy
  }
` as unknown as TypedDocumentNode<
  GetContactOrMerchantLocationDataMerchantDetailsFragment,
  undefined
>
export const GetContactOrMerchantLocationDataContactFragmentDoc = gql`
  fragment GetContactOrMerchantLocationDataContactFragment on Contact {
    address {
      country
      postcode
      state
      street
      suburb
    }
    location
    locationAccuracy
  }
`
