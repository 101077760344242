import styled from 'styled-components'

export const divPrompt = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  gap: 24px;
  word-break: break-word;
`

export const divPromptMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const divPromptActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

export const divClearRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
`

export const buttonInputClear = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
`
