import { SelectStyle } from '@npco/zeller-design-system'
import styled, { type CSSProperties } from 'styled-components'

import { StyledWrapper as EmptyListPlaceholderWrapper } from '../../SelectEmptyListPlaceholder/SelectEmptyListPlaceholder.styled'

export const StyledWrapper = styled.div`
  position: relative;
`

export const StyledOptionsListContent = styled.div<{
  $maxHeight: CSSProperties['maxHeight']
}>`
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 40px;
  }

  ${EmptyListPlaceholderWrapper} {
    height: ${({ $maxHeight }) => $maxHeight};
  }

  &.${SelectStyle.Standard}.isScrollbarVisible.isCustomScrollbarAllowed {
    margin-right: -4px;
  }
`
