import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { Box } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { FieldValuesProps, SetFieldProps } from 'types/common'
import { EmailField } from 'pages/SignUp/fields/EmailField'
import { PasswordField } from 'pages/SignUp/fields/PasswordField'
import { TermsAndConditionsField } from 'pages/SignUp/fields/TermsAndConditionsField'
import { SignupFields } from 'pages/SignUp/SignUp.types'
import { SignupSubmitButton } from 'pages/SignUp/SignupSubmitButton/SignupSubmitButton'
import { page } from 'translations'

import { useSignUp, useSignUpErrorMessages } from '../SignUp.hooks'
import {
  StyledDescription,
  StyledFormWrapper,
  StyledHeader,
} from './SignUpMobile.styled'

export const SignUpMobile = () => {
  const {
    emailErrorMessage,
    passwordErrorMessage,
    setEmailErrorMessage,
    setPasswordErrorMessage,
    setSignUpErrors,
  } = useSignUpErrorMessages()
  const [searchParams] = useSearchParams()

  const { handleSignUp } = useSignUp()

  const handleSubmit = useCallback(
    (values: FieldValuesProps, { setSubmitting }: SetFieldProps) => {
      handleSignUp({
        values,
        onError: (err) => {
          setSignUpErrors(err)
          setSubmitting(false)
        },
      })
    },
    [handleSignUp, setSignUpErrors]
  )

  return (
    <>
      <StyledHeader data-testid="signup-page">
        {page.signUp.headline}
      </StyledHeader>
      <StyledDescription>{page.signUp.description}</StyledDescription>
      <Formik<SignupFields>
        initialValues={{
          email: searchParams.get('email') || '',
          password: '',
          termsAndConditions: false,
        }}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, values }) => {
          const hasErrors = Boolean(
            emailErrorMessage || passwordErrorMessage || !isValid
          )
          return (
            <StyledFormWrapper>
              <div>
                <EmailField
                  error={emailErrorMessage}
                  setError={setEmailErrorMessage}
                />
                <PasswordField
                  error={passwordErrorMessage}
                  setError={setPasswordErrorMessage}
                />
                <Box pt={['1rem', '3.25rem']}>
                  <TermsAndConditionsField />
                </Box>
              </div>
              <SignupSubmitButton
                isDisabled={
                  hasErrors || isSubmitting || !values.termsAndConditions
                }
              />
            </StyledFormWrapper>
          )
        }}
      </Formik>
    </>
  )
}
