import { Form } from 'formik'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { TextArea } from 'components/Input/TextArea'
import { StyledTextAreaBody } from 'components/Input/TextArea/TextArea.styled'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { StyledContentContainer } from 'components/ModalFormScrollable/ModalFormScrollable.styled'

const MIN_HEIGHT_THRESHOLD = 46.25

export const StyledTextArea = styled(TextArea)`
  flex: 1;
  margin-bottom: 0;

  ${StyledTextAreaBody} {
    background-color: ${({ theme }) => theme.colors.WHITE};
    border: none;
    margin-top: 12px;
    padding: 0 20px 0 0;
    flex: 1;

    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      padding: 0 28px 0 0;
    }
  }
`

export const StyledModalForm = styled(ModalFormScrollable)`
  display: flex;
  flex-direction: column;
  height: initial;
  min-height: calc(100% - 112px);

  ${StyledContentContainer} {
    flex: 1;
    padding-right: 0;
    overflow: hidden;
  }

  @media screen and (min-height: ${MIN_HEIGHT_THRESHOLD}rem) {
    min-height: ${MIN_HEIGHT_THRESHOLD}rem;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const StyledFade = styled.div`
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: '';
  height: 32px;
  margin-top: -32px;
  pointer-events: none;
  position: relative;
  width: calc(100%);
`
