import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  saveValidationError:
    'Invoice could not be saved. Please review the invoice and try again.',
  sendValidationError:
    'Invoice could not be sent. Please review the invoice and try again.',
  scheduleValidationError:
    'Invoice could not be scheduled. Please review the invoice and try again.',
})
