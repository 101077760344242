import { gql, type TypedDocumentNode } from '@apollo/client'

import { IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragmentDoc } from '../IncomeAndExpenseDatapoints/IncomeAndExpenseDatapoints.generated'
import { IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragmentDoc } from '../IncomeAndExpenseLegend/IncomeAndExpenseLegend.generated'

export type IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    noOfIncome: number
    noOfExpense: number
    income: { __typename?: 'Money'; value: string }
    expense: { __typename?: 'Money'; value: string }
  }
  range: { __typename?: 'CashFlowReportRange'; start: any }
}

export const IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragmentDoc =
  gql`
    fragment IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragment
      ...IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment
    }
    ${IncomeAndExpenseDatapointsCashFlowPeriodicTotalAmountFragmentDoc}
    ${IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragmentDoc}
  ` as unknown as TypedDocumentNode<
    IncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment,
    undefined
  >
