import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'
import { CloseAccount } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/accounts'

import { cacheAccountStatus } from 'utils/banking/cacheAccountStatus/cacheAccountStatus'
import { UNEXPECTED_ERROR } from 'types/errors'
import {
  CloseAccount as CloseAccountResponse,
  CloseAccountVariables,
} from 'types/gql-types/CloseAccount'

export const useCloseAccount = (accountId: string) => {
  const entityUuid = useSelectedEntityUuid()
  const [closeAccountMutation, { loading }] = useMutation<
    CloseAccountResponse,
    CloseAccountVariables
  >(CloseAccount, {
    update: (cache, result) => {
      if (result.data?.closeDebitCardAccount) {
        cacheAccountStatus({
          cache,
          accountId,
          status: DebitCardAccountStatus.CLOSED,
        })
      }
    },
  })

  const closeAccount = useCallback(async () => {
    try {
      const closeAccountResult = await closeAccountMutation({
        variables: {
          entityUuid,
          debitCardAccountId: accountId,
        },
      })

      if (!closeAccountResult.data?.closeDebitCardAccount) {
        throw new Error('Failed to close card with false flag')
      }

      return closeAccountResult.data.closeDebitCardAccount
    } catch (err) {
      ErrorLogger.report('[Banking] Error closing account', err)
      return UNEXPECTED_ERROR
    }
  }, [closeAccountMutation, entityUuid, accountId])

  return { closeAccount, isClosingAccount: loading }
}
