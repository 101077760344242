import { useEffect, useState } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { getSessionStorageItem } from '@npco/utils-session-storage'
import { getDeviceResponse, updateSardineConfig } from '@sardine-ai/web-sdk'

import { AnalyticsEventNames } from 'services/Analytics/events'
import { type SardineDeviceResponsePayload } from 'services/Analytics/events/sardine'
import { useAnalyticsContext } from 'services/Analytics/useAnalyticsContext'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'

type SardineDeviceResponseState = Omit<SardineDeviceResponsePayload, 'flowName'>

export const FLOW_NAME = 'signup'
export const useUpdateSardineConfig = () => {
  const [responseState, setResponseState] = useState<
    SardineDeviceResponseState | undefined
  >()

  const zellerSessionId = getSessionStorageItem(
    SESSION_STORAGE_KEYS.ZELLER_SESSION_ID
  )
  const { trackAnalyticsEvent } = useAnalyticsLogger()
  const { analytics } = useAnalyticsContext()

  useEffect(() => {
    if (analytics && responseState) {
      const { sessionId, deviceId, updateSardineConfigStatus } = responseState
      trackAnalyticsEvent(AnalyticsEventNames.SARDINE_DEVICE_RESPONSE, {
        sessionId,
        deviceId,
        updateSardineConfigStatus,
        flowName: FLOW_NAME,
      })
    }
  }, [analytics, responseState, trackAnalyticsEvent])

  useEffect(() => {
    const updateConfig = async (sessionKey: string) => {
      try {
        const result = await updateSardineConfig({
          sessionKey,
          flow: FLOW_NAME,
        })
        const deviceResponse = getDeviceResponse()
        // Analytics logger is not initialised here.
        // So, we set the state instead and wait for analytics to be ready.
        setResponseState({
          sessionId: sessionKey,
          deviceId: deviceResponse?.deviceId ?? '',
          updateSardineConfigStatus:
            (result as { status?: string })?.status ?? '',
        })
      } catch (e) {
        ErrorLogger.report('[Sardine - Signup] Fail to initialise', e)
      }
    }
    if (typeof zellerSessionId === 'string') {
      updateConfig(zellerSessionId)
    }
  }, [trackAnalyticsEvent, zellerSessionId])
}
