import {
  StyledContainer,
  StyledDescription,
  StyledStatement,
  StyledTitle,
} from 'components/Statements/Statement/Statement.styled'

interface Props {
  title: string
  description: string
  actionButton: React.ReactElement
}

export const Statement = ({ title, description, actionButton }: Props) => {
  return (
    <StyledStatement>
      <StyledContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledContainer>
      {actionButton}
    </StyledStatement>
  )
}
