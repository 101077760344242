import { useEffect } from 'react'
import { DepositExportFormat } from '@npco/mp-gql-types'

import { useExportDeposits } from 'pages/Deposits/hooks/useExportDeposits'

interface Props {
  closeModal: () => void
  filename: string
  format: DepositExportFormat
  closeModalDelay?: number
}

const THREE_SECONDS = 3000

export const useExportDepositsModal = ({
  closeModal,
  filename,
  format,
  closeModalDelay,
}: Props) => {
  const { exportFile, isLoading, hasError, hasLoaded } = useExportDeposits(
    filename,
    format
  )

  useEffect(() => {
    exportFile()
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (!isLoading && !hasError && hasLoaded) {
      timeoutId = setTimeout(() => {
        closeModal()
      }, closeModalDelay ?? THREE_SECONDS)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoading, closeModal, hasError, hasLoaded, closeModalDelay])

  return {
    exportFile,
    isLoading,
    hasError,
    hasLoaded,
  }
}
