import { Box, COLOR } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface ExpiryInformationProps {
  isLoading: boolean
}

export const ExpiryInformation = ({ isLoading }: ExpiryInformationProps) => {
  return (
    <>
      {!isLoading && (
        <Box position="absolute" color={COLOR.GREY_250} left="16px" top="150px">
          {translate(
            'component.cardSummary.sensitiveCardInformation.expiryLabel'
          )}
        </Box>
      )}
      <Box id="mq-card-exp" position="absolute" top="150px" left="275px" />
    </>
  )
}
