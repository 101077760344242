import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslations } from '@npco/utils-translations'
import { PopperWrapper, SelectStyle } from '@npco/zeller-design-system'
import { useShowScrollbar } from 'design-system/hooks/useShowScrollbar/useShowScrollbar'

import { conditionalClassName as cn } from 'utils/conditionalClassName'

import { SelectBaseDesktopProps, SelectItemBasic } from '../../Select.types'
import { DEFAULT_MAX_HEIGHT } from '../../Select.utils'
import { useSelectA11y } from '../hooks/useSelectA11y'
import { useSelectBasic } from '../hooks/useSelectBasic'
import { useSelectReset } from '../hooks/useSelectReset'
import { translations } from './SelectBasicDesktop.i18n'
import {
  StyledOptionsListContent,
  StyledWrapper,
} from './SelectBasicDesktop.styled'

const UNDEFINED = '__unassigned__'

export const SelectBasicDesktop = <ItemType extends SelectItemBasic>({
  items: itemsProp,
  selectedItem,
  onBlur,
  onChange: onChangeProp,
  renderTrigger,
  renderItem,
  onClose,
  onOpen,
  onFocus,
  hasError,
  isDisabled,
  renderLabel,
  isSearchableByKeypress,
  menuStyle = SelectStyle.Compact,
  placement,
  size,
  maxHeight = DEFAULT_MAX_HEIGHT,
  popperWidth,
  renderNoItemsPlaceholder = () => null,
  renderAdditionalControls,
  isLoading = false,
  renderLoadingPlaceholder,
  filterItems = (item: ItemType) => Boolean(item),
  shouldCloseOnSelect = true,
  renderBottomSection,
  isResettable,
  isClearable = false,
  hasSelectedIndicator,
  shouldAutoFocusControl,
}: SelectBaseDesktopProps<ItemType>) => {
  const t = useTranslations(translations)
  const [hasBeenOpen, setHasBeenOpen] = useState(false)

  const items = useMemo(() => {
    const isClearItemVisible = isClearable && selectedItem
    const clearItemLabel = t('clearLabel')
    const actionItems: ItemType[] = isClearItemVisible
      ? [{ label: clearItemLabel, value: UNDEFINED } as ItemType]
      : []
    const allItems = actionItems.concat(itemsProp.filter(filterItems))
    return allItems
  }, [filterItems, isClearable, itemsProp, selectedItem, t])

  const onChange: typeof onChangeProp = useCallback(
    (selectItem) => {
      if (selectItem?.value === UNDEFINED) {
        return onChangeProp(undefined)
      }
      return onChangeProp(selectItem)
    },
    [onChangeProp]
  )

  const {
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    getLabelProps,
    isOpen,
    menuAttributes,
    wrapperElementRef,
    menuElementRef,
    setHighlightedIndex,
    highlightedIndex,
    openMenu,
    closeMenu,
    selectItem,
    reset,
  } = useSelectBasic<ItemType>({
    items,
    onChange,
    onClose,
    onOpen,
    setHasBeenOpen,
    placement,
    shouldCloseOnSelect,
    popperWidth,
  })

  const shouldDisplayLoadingPlaceholder = isLoading && renderLoadingPlaceholder
  const shouldDisplayNoItemsPlaceholder =
    !items.length &&
    !shouldDisplayLoadingPlaceholder &&
    Boolean(renderNoItemsPlaceholder)
  const shouldDisplayItems =
    !shouldDisplayNoItemsPlaceholder && !shouldDisplayLoadingPlaceholder

  useSelectReset({ reset, isResettable, selectedItem })

  const { onKeyDown, ...menuProps } = getMenuProps({
    ref: menuElementRef,
    ...menuAttributes,
  })

  const {
    isCustomScrollbarAllowed,
    isScrollbarVisible,
    childRef,
    scrollWrapperRef,
  } = useShowScrollbar({})

  const { customOnKeyDown } = useSelectA11y<ItemType>({
    onKeyDown,
    isOpen,
    openMenu,
    setHighlightedIndex,
    focusMenu: () => menuElementRef.current?.focus(),
    menuId: menuProps.id,
    items,
    isSearchableByKeypress,
    highlightedIndex,
    shouldAutoFocusControl,
  })

  const triggerProps = getToggleButtonProps()

  useEffect(() => {
    if (hasBeenOpen && !isOpen) {
      document.getElementById(triggerProps.id)?.focus()
      setHasBeenOpen(false)
    }
  }, [hasBeenOpen, isOpen, triggerProps.id])

  return (
    <StyledWrapper data-testid="dropdown-desktop" ref={wrapperElementRef}>
      {renderLabel?.(getLabelProps())}

      {renderTrigger({
        ...triggerProps,
        type: 'button',
        selectedItem,
        isOpen,
        disabled: isDisabled,
        hasError,
        size,
        'data-testid': 'select-trigger',
        onFocus: () => onFocus?.(isOpen),
        onBlur,
      })}

      {ReactDOM.createPortal(
        <PopperWrapper
          {...menuProps}
          data-testid="options-list"
          $width={popperWidth}
          isOpen={isOpen}
          menuStyle={menuStyle}
          onKeyDown={customOnKeyDown}
        >
          {isOpen && (
            <>
              {renderAdditionalControls?.({ closeMenu, selectItem })}
              <StyledOptionsListContent
                $maxHeight={maxHeight}
                ref={scrollWrapperRef}
                className={cn({
                  isScrollbarVisible,
                  isCustomScrollbarAllowed,
                  [menuStyle]: true,
                })}
              >
                <div ref={childRef}>
                  {shouldDisplayLoadingPlaceholder &&
                    renderLoadingPlaceholder?.({
                      isMobileVariant: false,
                      maxHeight,
                    })}
                  {shouldDisplayNoItemsPlaceholder &&
                    renderNoItemsPlaceholder({ closeMenu })}
                  {shouldDisplayItems &&
                    items.map((item, index) => {
                      const key = item.value
                      return (
                        <Fragment key={key}>
                          {renderItem({
                            ...getItemProps({
                              item,
                              key: item.value,
                              index,
                              disabled: item.isDisabled,
                            }),
                            item,
                            isOpen,
                            hasSelectedIndicator,
                          })}
                        </Fragment>
                      )
                    })}
                </div>
              </StyledOptionsListContent>
              {renderBottomSection?.({
                isOpen,
                onClose: closeMenu,
              })}
            </>
          )}
        </PopperWrapper>,
        document.querySelector('body') as HTMLBodyElement
      )}
    </StyledWrapper>
  )
}
