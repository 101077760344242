import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const LegendItem = styled(Flex)`
  align-items: center;
  gap: 6px;
  white-space: nowrap;
  margin-bottom: 8px;
  margin-right: 32px;
`

export const LegendItemIcon = styled.span<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 3px;
`

export const LegendItemText = styled.span``
