import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useApolloClient } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import { useCloseAccountModal } from 'features/CloseAccount/hooks/useCloseAccountModal/useCloseAccountModal'
import { updateCachedCard } from 'features/utils-cards-cache'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'

import { useCloseAccount } from './useCloseAccount/useCloseAccount'

const handleError = () => {
  showErrorToast(translate('errorMessages.somethingWentWrong'))
}

export const useCloseAccountPrompt = (
  accountId: string,
  cardIds: string[] = []
) => {
  const navigate = useNavigate()
  const { closeModal: closeAccountModal } = useCloseAccountModal()
  const { closeAccount, isClosingAccount } = useCloseAccount(accountId)
  const { cache } = useApolloClient()
  const shortEntityId = useSelectedShortEntityUuid()

  const handleSubmit = useCallback(async () => {
    const result = await closeAccount()

    if (result === UNEXPECTED_ERROR) {
      handleError()
      return
    }

    cardIds.forEach((cardId) =>
      updateCachedCard({
        cache,
        cardUpdate: {
          id: cardId,
          status: DebitCardStatus.CLOSED,
        },
      })
    )

    showSuccessToast(
      translate('page.closeAccountModal.closeAccountPrompt.successMessage')
    )
    navigate(ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.path)
  }, [closeAccount, cardIds, navigate, shortEntityId, cache])

  return { handleSubmit, closeAccountModal, isClosingAccount }
}
