import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetScreensaverLogoUploadDetailsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  fileName: Types.Scalars['String']['input']
  siteUuid: Types.Scalars['ID']['input']
}>

export type GetScreensaverLogoUploadDetailsQueryResponse = {
  __typename?: 'Query'
  getScreensaverLogoUploadDetails: {
    __typename?: 'ScreensaverLogoUploadDetails'
    expireDate: any
    logoUuid: string
    presignedDetails: {
      __typename?: 'ScreensaverPresignedDetails'
      jsonFields: string
      url: string
    }
  }
}

export const GetScreensaverLogoUploadDetails = gql`
  query GetScreensaverLogoUploadDetails(
    $entityUuid: ID!
    $fileName: String!
    $siteUuid: ID!
  ) {
    getScreensaverLogoUploadDetails(
      entityUuid: $entityUuid
      fileName: $fileName
      siteUuid: $siteUuid
    ) {
      expireDate
      logoUuid
      presignedDetails {
        jsonFields
        url
      }
    }
  }
` as unknown as TypedDocumentNode<
  GetScreensaverLogoUploadDetailsQueryResponse,
  GetScreensaverLogoUploadDetailsQueryVariables
>

/**
 * __useGetScreensaverLogoUploadDetailsQuery__
 *
 * To run a query within a React component, call `useGetScreensaverLogoUploadDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScreensaverLogoUploadDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScreensaverLogoUploadDetailsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      fileName: // value for 'fileName'
 *      siteUuid: // value for 'siteUuid'
 *   },
 * });
 */
export function useGetScreensaverLogoUploadDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScreensaverLogoUploadDetailsQueryResponse,
    GetScreensaverLogoUploadDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetScreensaverLogoUploadDetailsQueryResponse,
    GetScreensaverLogoUploadDetailsQueryVariables
  >(GetScreensaverLogoUploadDetails, options)
}
export function useGetScreensaverLogoUploadDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScreensaverLogoUploadDetailsQueryResponse,
    GetScreensaverLogoUploadDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetScreensaverLogoUploadDetailsQueryResponse,
    GetScreensaverLogoUploadDetailsQueryVariables
  >(GetScreensaverLogoUploadDetails, options)
}
export type GetScreensaverLogoUploadDetailsQueryHookResult = ReturnType<
  typeof useGetScreensaverLogoUploadDetailsQuery
>
export type GetScreensaverLogoUploadDetailsLazyQueryHookResult = ReturnType<
  typeof useGetScreensaverLogoUploadDetailsLazyQuery
>
export type GetScreensaverLogoUploadDetailsQueryResult = Apollo.QueryResult<
  GetScreensaverLogoUploadDetailsQueryResponse,
  GetScreensaverLogoUploadDetailsQueryVariables
>
