import { gql } from '@apollo/client'
import {
  BREAKPOINT,
  Flex,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { PieChart } from '../../../PieChart/PieChart'
import { NetAmountWithOtherCategory } from '../../TransactionsSummary.types'
import { HoveredCategoryNetAmount } from '../TransactionsSummaryCategory.types'
import { CategoriesChartCashFlowCategoryNetAmountFragment as CategoryNetAmount } from './CategoriesChart.generated'
import { CategoryPieChartText } from './CategoryPieChartText'
import { useGetCategoryPieChartData } from './hooks/useGetCategoryPieChartData'

interface CategoriesChartProps {
  netAmounts: NetAmountWithOtherCategory<CategoryNetAmount>[]
  isLoading?: boolean
  transactionDirection: 'income' | 'expense'
  hoveredCategoryNetAmount: HoveredCategoryNetAmount
}
export const CategoriesChart = ({
  netAmounts,
  isLoading = false,
  transactionDirection,
  hoveredCategoryNetAmount,
}: CategoriesChartProps) => {
  const isBelowBreakpointMD = useIsMobileResolution(BREAKPOINT.MD)
  const isBelowBreakpointLG = useIsMobileResolution(BREAKPOINT.LG)

  const { pieChartData, totalAmount, activeNetAmount } =
    useGetCategoryPieChartData({
      netAmounts,
      hoveredCategoryNetAmount,
    })

  const size = isBelowBreakpointLG && !isBelowBreakpointMD ? 'small' : 'large'
  return (
    <Flex
      py="30px"
      px={{ LG: '36px' }}
      mt="24px"
      justifyContent="center"
      data-testid="category-chart"
    >
      {isLoading ? (
        <PieChart.Loading size={size} />
      ) : (
        <PieChart
          size={size}
          data={pieChartData}
          center={
            <CategoryPieChartText
              size={size}
              totalAmount={totalAmount}
              activeNetAmount={activeNetAmount}
              transactionDirection={transactionDirection}
            />
          }
        />
      )}
    </Flex>
  )
}

CategoriesChart.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment CategoriesChartCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      __typename
      ...UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment
    }

    ${useGetCategoryPieChartData.fragments.CashFlowCategoryNetAmount}
  `,
}
