import { useTranslations } from '@npco/utils-translations'

import { useTotalAccountBalance } from 'pages/Dashboard/hooks/useTotalAccountBalance'

import { SummarySection } from '../../SummarySection'
import { translations } from './AvailableBalanceSummary.i18n'

export const AvailableBalanceSummary = () => {
  const t = useTranslations(translations)
  const { amountInCents, isLoading, error } = useTotalAccountBalance()

  if (error) {
    return null
  }

  return (
    <SummarySection
      title={t('title')}
      amountInCents={amountInCents || 0}
      isLoading={isLoading}
    />
  )
}
