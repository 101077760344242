import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Box, Heading, InfoBox, INFOBOX_SIZE } from '@npco/zeller-design-system'
import { Form, useFormikContext } from 'formik'

import { getIsMobileDevice } from 'utils/device'
import { SettingsPayments } from 'types/settings'
import { SiteCache } from 'types/site'
import { Refunds } from 'pages/Settings/SitePayments/SitePaymentForm/Refunds/Refunds'
import * as styled from 'pages/Settings/SitePayments/SitePayments.styled'

import { Cpoc } from './Cpoc/Cpoc'
import { Moto } from './Moto/Moto'
import { translations } from './SitePaymentsForm.i18n'
import { SplitPayments } from './SplitPayments/SplitPayments'
import { Surcharge } from './Surcharge/Surcharge'
import { Tips } from './Tips/Tips'
import { VirtualTerminal } from './VirtualTerminal/VirtualTerminal'

interface Props {
  siteData: SiteCache
}

export const SitePaymentsForm = ({ siteData }: Props) => {
  const { values, errors, initialValues, validateForm, setFieldValue } =
    useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)

  const lastValidSurchage = initialValues.surchargePercent
  const lastValidMotoSurcharge = initialValues.motoPaymentsSurchargePercentage

  const hasMobileDevice = siteData?.devices?.some((device) =>
    getIsMobileDevice(device.model)
  )

  useEffect(() => {
    // eslint-disable-next-line no-console
    validateForm().catch(() => console.error('failed to validate form'))
  }, [validateForm])

  useEffect(() => {
    if (!values.surchargeEnabled && errors.surchargePercent) {
      setFieldValue('surchargePercent', lastValidSurchage)
    }
  }, [
    lastValidSurchage,
    errors.surchargePercent,
    values.surchargeEnabled,
    setFieldValue,
  ])

  useEffect(() => {
    if (
      errors.motoPaymentsSurchargePercentage &&
      (!values.motoPaymentsSurchargeEnabled || !values.motoEnabled)
    ) {
      setFieldValue('motoPaymentsSurchargePercentage', lastValidMotoSurcharge)
    }
  }, [
    lastValidMotoSurcharge,
    errors.motoPaymentsSurchargePercentage,
    values.motoPaymentsSurchargeEnabled,
    values.motoEnabled,
    setFieldValue,
  ])

  return (
    <Form>
      <Box mb="24px">
        <InfoBox size={INFOBOX_SIZE.SMALL}>
          <InfoBox.Message>{t('notification')}</InfoBox.Message>
        </InfoBox>
      </Box>
      <Box mb="24px">
        <Heading.H2>{t('terminal')}</Heading.H2>
      </Box>
      <Surcharge siteData={siteData} />
      <styled.CustomizedDivider />
      <Moto siteData={siteData} />
      <styled.CustomizedDivider />
      {hasMobileDevice && (
        <>
          <Cpoc siteData={siteData} />
          <styled.CustomizedDivider />
        </>
      )}
      <VirtualTerminal siteData={siteData} />
      <styled.CustomizedDivider />
      <Tips />
      <Refunds />
      <styled.CustomizedDivider />
      <SplitPayments />
      <styled.CustomizedDivider />
    </Form>
  )
}
