import { forwardRef } from 'react'
import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'

import {
  StyledButtonContainer,
  StyledCloseContainer,
  StyledContent,
  StyledIconCloseContainer,
} from './SelectPill.styled'

interface Props {
  children: React.ReactNode
  onClick: () => void
  onClose: () => void
  icon?: React.ReactNode
}

export const SelectPill = forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick, onClose, icon, ...rest }, ref) => {
    return (
      <StyledButtonContainer data-testid="select-pill">
        <StyledContent onClick={onClick} ref={ref} {...rest}>
          {icon && (
            <SvgIcon height="16" width="16">
              {icon}
            </SvgIcon>
          )}
          <span>{children}</span>
        </StyledContent>
        <StyledCloseContainer onClick={onClose} data-testid="close">
          <StyledIconCloseContainer>
            <SvgIcon height="12" width="12">
              <CloseIcon />
            </SvgIcon>
          </StyledIconCloseContainer>
        </StyledCloseContainer>
      </StyledButtonContainer>
    )
  }
)
