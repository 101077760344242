import styled from 'styled-components'

export const DetailsValue = styled.div`
  ${({ theme }) => theme.typography['body-base']};
  color: ${({ color, theme }) => color ?? theme.colors.BLACK_900};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;
`
