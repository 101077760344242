import { ApolloError } from '@apollo/client'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import {
  ErrorMessageForm,
  SelectItemBasic,
  SelectStandard,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { NoActiveAccountsPopper } from '../NoActiveAccountsPopper/NoActiveAccountsPopper'
import { TransferErrorPopper } from '../TransferErrorPopper/TransferErrorPopper'

export interface TransferSelectInputItemType extends SelectItemBasic {
  value: string
  status: DebitCardAccountStatus
  subLabel1?: React.ReactNode
  rightSideLabel?: React.ReactNode
  avatar?: {
    letter: string
    bgColor?: string
    userDefinedContent?: JSX.Element
  }
}

interface Props {
  isDisabled?: boolean
  onChange: (selectedItem?: TransferSelectInputItemType | null) => void
  setTouched: () => void
  chosenItem: TransferSelectInputItemType | null
  hasError: boolean
  selectOptions: TransferSelectInputItemType[]
  isRefetchingAccounts: boolean
  errorMessage?: string
  getAccountsError?: ApolloError
  refetchAccounts: () => void
  filterItems?: (item: TransferSelectInputItemType) => boolean
}

export const TransferSelectInput = ({
  isDisabled = false,
  selectOptions,
  onChange,
  setTouched,
  chosenItem,
  hasError,
  isRefetchingAccounts,
  errorMessage,
  getAccountsError,
  refetchAccounts,
  filterItems,
}: Props) => {
  return (
    <div>
      <SelectStandard<TransferSelectInputItemType>
        label={translate('page.transfer.account')}
        mobileLabel={translate('page.transfer.dropdownPlaceholder')}
        placeholder={translate('page.transfer.selectAccountPlaceholder')}
        items={selectOptions}
        isDisabled={isDisabled}
        onChange={onChange}
        onClose={setTouched}
        selectedItem={chosenItem}
        filterItems={filterItems}
        renderNoItemsPlaceholder={() => {
          if (getAccountsError || isRefetchingAccounts) {
            return (
              <TransferErrorPopper
                isRefetchingAccounts={isRefetchingAccounts}
                refetchAccounts={refetchAccounts}
              />
            )
          }
          return <NoActiveAccountsPopper />
        }}
        maxHeight={
          filterItems && !selectOptions.filter(filterItems).length
            ? 'fit-content'
            : undefined
        }
        hasError={hasError}
      />

      <ErrorMessageForm hasError={hasError} errorMessage={errorMessage} />
    </div>
  )
}
