import { Flex, Heading } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { Description } from 'layouts'

export const XeroPaymentServicesSection = () => {
  return (
    <>
      <Flex justifyContent="space-between">
        <Heading.H3 mb="24px">
          {translate('page.settings.users.xeroPaymentServices.settingsTitle')}
        </Heading.H3>
      </Flex>
      <Description>
        {translate('page.settings.users.xeroPaymentServices.descriptionAdmin')}
      </Description>
    </>
  )
}
