import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardBalanceDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardBalance/useCardBalance.generated'
import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardStatus/useCardStatus.generated'
import { CardStatusDebitCardV2FragmentDoc } from '../CardStatus/CardStatus.generated'

export type CardBalanceCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    balance: { __typename?: 'Money'; value: string } | null
  }
}

export const CardBalanceCellDebitCardV2FragmentDoc = gql`
  fragment CardBalanceCellDebitCardV2Fragment on DebitCardV2 {
    ...UseCardStatusDebitCardV2Fragment
    ...UseCardBalanceDebitCardV2Fragment
    ...CardStatusDebitCardV2Fragment
  }
  ${UseCardStatusDebitCardV2FragmentDoc}
  ${UseCardBalanceDebitCardV2FragmentDoc}
  ${CardStatusDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<CardBalanceCellDebitCardV2Fragment, undefined>
