import { useCallback } from 'react'
import {
  useCreateSavingsAccountState,
  useGoToCreateSavingsAccount,
} from '@npco/mp-utils-create-savings-account-routing'
import { useTranslations } from '@npco/utils-translations'
import { DecisionModal } from '@npco/zeller-design-system'

import { createSavingsAccountModalWrapperTranslations } from './CreateSavingsAccountModalWrapper.i18n'

type CreateSavingsAccountModalWrapperProps = {
  children: React.ReactNode
  onClickPrimary?: () => void
  isLoadingPrimary?: boolean
}

export const CreateSavingsAccountModalWrapper = ({
  children,
  onClickPrimary,
  isLoadingPrimary,
}: CreateSavingsAccountModalWrapperProps) => {
  const t = useTranslations(createSavingsAccountModalWrapperTranslations)

  const { state } = useCreateSavingsAccountState()

  const { goToCreateSavingsAccount } = useGoToCreateSavingsAccount()

  const closeModal = useCallback(() => {
    goToCreateSavingsAccount(null)
  }, [goToCreateSavingsAccount])

  const modalClasses = state?.hasBlurAndFade
    ? { className: 'animated-content', overlayClassName: 'animated-overlay' }
    : {}

  return (
    <DecisionModal
      title={t('title')}
      primaryButtonLabel={t('agreeAndActivate')}
      onClickPrimary={onClickPrimary}
      isLoadingPrimary={isLoadingPrimary}
      secondaryButtonLabel={t('cancel')}
      onClickSecondary={closeModal}
      onCancel={closeModal}
      isOpen
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalClasses}
    >
      {children}
    </DecisionModal>
  )
}
