import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountWithAvatarDebitCardAccountV2FragmentDoc } from './AccountWithAvatar/AccountWithAvatar.generated'
import { UserWithAvatarCustomerFragmentDoc } from './UserWithAvatar/UserWithAvatar.generated'

export type UserAndAccountDetailsCustomerFragment = {
  __typename?: 'Customer'
  id: string
  entityUuid: string | null
  firstname: string | null
  lastname: string | null
  icon: {
    __typename?: 'Icon'
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export type UserAndAccountDetailsDebitCardAccountV2Fragment = {
  __typename: 'DebitCardAccountV2'
  id: string
  name: string
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
  } | null
}

export const UserAndAccountDetailsCustomerFragmentDoc = gql`
  fragment UserAndAccountDetailsCustomerFragment on Customer {
    id
    entityUuid
    ...UserWithAvatarCustomerFragment
  }
  ${UserWithAvatarCustomerFragmentDoc}
` as unknown as TypedDocumentNode<
  UserAndAccountDetailsCustomerFragment,
  undefined
>
export const UserAndAccountDetailsDebitCardAccountV2FragmentDoc = gql`
  fragment UserAndAccountDetailsDebitCardAccountV2Fragment on DebitCardAccountV2 {
    ...AccountWithAvatarDebitCardAccountV2Fragment
  }
  ${AccountWithAvatarDebitCardAccountV2FragmentDoc}
`
