import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { DebitCardTransactionUpdateFragmentDoc } from './debitCardTransactionUpdateFragment.generated'

const defaultOptions = {} as const
export type DebitCardTransactionUpdateV2SubscriptionVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type DebitCardTransactionUpdateV2SubscriptionResponse = {
  __typename?: 'Subscription'
  onDebitCardTransactionUpdate: {
    __typename?: 'DebitCardTransactionV2Update'
    attachments: Array<string> | null
    cardAcceptorName: string | null
    debitCardName: string | null
    id: string
    debitCardAccountUuid: string
    debitCardId: string | null
    debitCardMaskedPan: string | null
    category: Types.EntityCategories | null
    subcategory: string | null
    status: Types.DebitCardTransactionStatusV2
    timestamp: any
    reference: string | null
    referencePayee: string | null
    description: string | null
    type: Types.DebitCardTransactionTypeV2
    note: string | null
    tags: Array<string> | null
    amount: { __typename?: 'Money'; value: string }
    deposit: { __typename?: 'Deposit'; siteName: string; id: string } | null
    subcategoryDetails: {
      __typename?: 'Subcategory'
      id: string
      name: string
      predefined: boolean
    } | null
    contact: {
      __typename?: 'ContactBasicInfo'
      businessName: string | null
      contactType: Types.ContactType
      firstName: string | null
      id: string
      lastName: string | null
      isSelf: boolean | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
    } | null
    merchant: {
      __typename?: 'MerchantBasicInfo'
      id: string
      name: string | null
      icon: {
        __typename?: 'Icon'
        colour: string | null
        letter: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
    } | null
    payerDetails: {
      __typename?: 'DebitCardTransactionCounterpartyEvent'
      debitCardAccountUuid: string | null
      paymentInstrumentUuid: string | null
      senderUuid: string | null
      accountDetails: {
        __typename?: 'BankAccountDetails'
        bsb: string | null
        account: string | null
        name: string | null
      } | null
      debitCardAccount: {
        __typename?: 'DebitCardAccountV2'
        id: string
        name: string
        type: Types.DebitCardAccountType
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          image: string | null
          animation: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
      } | null
    } | null
    payeeDetails: {
      __typename?: 'DebitCardTransactionCounterpartyEvent'
      debitCardAccountUuid: string | null
      paymentInstrumentUuid: string | null
      accountDetails: {
        __typename?: 'BankAccountDetails'
        bsb: string | null
        account: string | null
        name: string | null
      } | null
      bpayDetails: {
        __typename?: 'BpayDetails'
        billerName: string
        billerCode: string
        crn: string
        nickname: string
      } | null
      debitCardAccount: {
        __typename?: 'DebitCardAccountV2'
        id: string
        name: string
        type: Types.DebitCardAccountType
        icon: {
          __typename?: 'Icon'
          colour: string | null
          letter: string | null
          image: string | null
          animation: string | null
          images: Array<{
            __typename?: 'Image'
            size: Types.ImageSize
            url: string
          }> | null
        } | null
      } | null
    } | null
  } | null
}

export const DebitCardTransactionUpdateV2 = gql`
  subscription DebitCardTransactionUpdateV2($entityUuid: ID!) {
    onDebitCardTransactionUpdate(entityUuid: $entityUuid) {
      ...DebitCardTransactionUpdateFragment
    }
  }
  ${DebitCardTransactionUpdateFragmentDoc}
` as unknown as TypedDocumentNode<
  DebitCardTransactionUpdateV2SubscriptionResponse,
  DebitCardTransactionUpdateV2SubscriptionVariables
>

/**
 * __useDebitCardTransactionUpdateV2Subscription__
 *
 * To run a query within a React component, call `useDebitCardTransactionUpdateV2Subscription` and pass it any options that fit your needs.
 * When your component renders, `useDebitCardTransactionUpdateV2Subscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitCardTransactionUpdateV2Subscription({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useDebitCardTransactionUpdateV2Subscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DebitCardTransactionUpdateV2SubscriptionResponse,
    DebitCardTransactionUpdateV2SubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    DebitCardTransactionUpdateV2SubscriptionResponse,
    DebitCardTransactionUpdateV2SubscriptionVariables
  >(DebitCardTransactionUpdateV2, options)
}
export type DebitCardTransactionUpdateV2SubscriptionHookResult = ReturnType<
  typeof useDebitCardTransactionUpdateV2Subscription
>
export type DebitCardTransactionUpdateV2SubscriptionResult =
  Apollo.SubscriptionResult<DebitCardTransactionUpdateV2SubscriptionResponse>
