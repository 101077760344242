import { useLayoutEffect, useRef } from 'react'
import { Box } from '@npco/zeller-design-system'
import lottie from 'lottie-web/build/player/lottie_light'

export interface AnimationBaseProps {
  animationData: unknown
  width: string
  height: string
}
export const AnimationBase = ({
  animationData,
  width,
  height,
}: AnimationBaseProps) => {
  const illustrationRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const loadLottieAnimation = () => {
      if (!illustrationRef.current) return undefined

      return lottie.loadAnimation({
        container: illustrationRef.current,
        animationData,
        loop: true,
      })
    }

    const animation = loadLottieAnimation()
    return () => animation?.destroy()
  }, [animationData])

  return <Box width={width} height={height} ref={illustrationRef} />
}
