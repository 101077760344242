import { Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { SitesStorageHandler } from 'services/sessionStorage/handlers/SitesStorageHandler/SitesStorageHandler'
import { SettingsCreateSite, SettingsSites } from 'pages'
import { NotFound } from 'pages/NotFound'

import { SiteRoutes } from './SiteRoutes/SiteRoutes'

export const SitesRoutes = () => {
  return (
    <>
      <SitesStorageHandler />

      <Routes>
        <Route index element={<SettingsSites />} />
        <Route
          path={ROOT.ORGS.ORG().PAYMENTS.SITES.CREATE_SITE.relative}
          element={<SettingsCreateSite />}
        />
        <Route
          path={`${ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.relative}/*`}
          element={<SiteRoutes />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}
