import { createTranslations } from '@npco/utils-translations'

export const cardDisplayTranslations = createTranslations({
  zellerCardAccessibleFormat: '{productType} Accessible · {format}',
  zellerCardFormat: '{productType} · {format}',
  physical: 'Physical',
  virtual: 'Virtual',
  corporate: 'Corporate',
  debit: 'Debit',
})
