import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

import { CopyButtonWithTooltip } from '../../../../../../../../components/CopyButtonWithTooltip'
import { setupFlowDebitCardSensitiveInformationTranslations } from '../../SensitiveCardInformation.i18n'
import { SensitiveInfoLabel } from '../SensitiveContentMobile.styled'

interface CardExpiryInformationProps {
  isLoading: boolean
  copySuccessMap: Record<string, boolean>
}

export const CardExpiryInformation = ({
  isLoading,
  copySuccessMap,
}: CardExpiryInformationProps) => {
  const t = useTranslations(setupFlowDebitCardSensitiveInformationTranslations)

  return (
    <Flex
      justifyContent="space-between"
      position="relative"
      alignItems="center"
      width="124px"
      gridGap="8px"
    >
      <SensitiveInfoLabel>{t('expiryLabel')}</SensitiveInfoLabel>
      <Flex flexGrow={1}>
        {isLoading && <SkeletonBasic width={56} height={8} isRounded />}
        <Box id="mq-card-exp" position="absolute" top="3px" left="54px" />
        <Box
          opacity={isLoading ? 0 : 1}
          position="absolute"
          top="4px"
          left="102px"
        >
          <CopyButtonWithTooltip
            id="mq-copy-card-exp"
            copySuccessMap={copySuccessMap}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
