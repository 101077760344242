import 'utils/yup'

import { array, boolean, object, string } from 'yup'

import { translate } from 'utils/translations'

import {
  ITEM_CATEGORIES_FIELD,
  ITEM_CATEGORY_MIN,
  ITEM_DESCRIPTION_FIELD,
  ITEM_DESCRIPTION_MAX_CHARACTERS,
  ITEM_IS_TAX_INCLUSIVE,
  ITEM_MAX_AMOUNT,
  ITEM_MIN_AMOUNT,
  ITEM_NAME_FIELD,
  ITEM_NAME_MAX_CHARACTERS,
  ITEM_PRICE_FIELD,
  ITEM_TAX_APPLICABLE_FIELD,
} from './ItemBaseModalForm.constants'
import { ItemTaxApplicableItems } from './ItemBaseModalForm.types'

export const itemSchemaErrors = {
  itemCategoryRequiredError: translate(
    'page.item.formSections.itemCategoryMinError'
  ),
  itemDescriptionMaxLengthError: translate(
    'page.item.formSections.itemDescriptionMaxLengthError',
    { length: ITEM_DESCRIPTION_MAX_CHARACTERS }
  ),
  itemNameMaxLengthError: translate(
    'page.item.formSections.itemNameMaxLengthError',
    { length: ITEM_NAME_MAX_CHARACTERS }
  ),
  itemNameRequiredError: translate(
    'page.item.formSections.itemNameRequiredError'
  ),
  itemPriceTypeError: translate('page.item.formSections.itemPriceTypeError'),
  itemPriceMaxError: translate('page.item.formSections.itemPriceMaxError'),
  itemPriceMinError: translate('page.item.formSections.itemPriceMinError'),
}

export const itemSchema = object({
  [ITEM_CATEGORIES_FIELD]: array().min(
    ITEM_CATEGORY_MIN,
    itemSchemaErrors.itemCategoryRequiredError
  ),
  [ITEM_DESCRIPTION_FIELD]: string()
    .trim()
    .max(
      ITEM_DESCRIPTION_MAX_CHARACTERS,
      itemSchemaErrors.itemDescriptionMaxLengthError
    ),
  [ITEM_IS_TAX_INCLUSIVE]: boolean(),
  [ITEM_NAME_FIELD]: string()
    .trim()
    .max(ITEM_NAME_MAX_CHARACTERS, itemSchemaErrors.itemNameMaxLengthError)
    .required(itemSchemaErrors.itemNameRequiredError),
  [ITEM_PRICE_FIELD]: string()
    .isStringNumber(itemSchemaErrors.itemPriceTypeError)
    .minStringNumber(ITEM_MIN_AMOUNT, itemSchemaErrors.itemPriceMinError)
    .maxStringNumber(ITEM_MAX_AMOUNT, itemSchemaErrors.itemPriceMaxError),
  [ITEM_TAX_APPLICABLE_FIELD]: string().oneOf([
    ItemTaxApplicableItems.NoTax,
    ItemTaxApplicableItems.TaxApplicable,
  ]),
})
