import React, { FC, useCallback } from 'react'
import {
  INPUT_SIZE,
  InputAdaptiveFieldIconRight,
  InputAdaptiveFieldProps,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { useInputHandlers } from 'hooks/useInputHandlers/useInputHandlers'
import { convertNumberToLocaleString } from 'utils/localeString'

import {
  CurrencySymbolWrapper,
  Wrapper,
} from './InputAdaptiveCurrencyField.styled'

const DEFAULT_AMOUNT = '0.00'

const renderLeftControls = () => (
  <CurrencySymbolWrapper>$</CurrencySymbolWrapper>
)

export interface InputAdaptiveCurrencyFieldProps
  extends InputAdaptiveFieldProps {
  ariaLabel: string
  dataTestId?: string
  defaultAmount?: string
  icon?: FC
  isAmountAlignLeft?: boolean
  name: string
  size?: INPUT_SIZE
}

export const InputAdaptiveCurrencyField = ({
  ariaLabel,
  dataTestId,
  defaultAmount = DEFAULT_AMOUNT,
  icon = () => null,
  isAmountAlignLeft,
  name,
  size = INPUT_SIZE.SMALL,
  ...props
}: InputAdaptiveCurrencyFieldProps) => {
  const [field, , helpers] = useField(name)

  const { handleCurrencyMax6DigitInputKeydown } = useInputHandlers()

  const handleOnBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        // NOTE: must prevent default otherwise incorrect event.target.value
        // sent to formik on blur
        event.preventDefault()
        helpers.setValue(defaultAmount)
        return
      }

      const number = Number(event.target.value)

      if (!Number.isNaN(number)) {
        const numberString = convertNumberToLocaleString(number)
        helpers.setValue(numberString)
      }
    },
    [defaultAmount, helpers]
  )

  const handleOnFocus = useCallback(() => {
    const isZeroPrice = field.value === defaultAmount

    // NOTE: if zero price we clear the value so the user can more easily type
    // in their next value
    if (isZeroPrice) {
      helpers.setValue('')
      return
    }

    const replacedCommaString = field.value.replace(/,/g, '')
    helpers.setValue(replacedCommaString)
  }, [defaultAmount, field, helpers])

  return (
    <Wrapper $isLeftAlign={Boolean(isAmountAlignLeft)}>
      <InputAdaptiveFieldIconRight
        aria-label={ariaLabel}
        data-testid={dataTestId}
        icon={icon}
        inputMode="numeric"
        name={name}
        onKeyDown={handleCurrencyMax6DigitInputKeydown}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        renderLeftControls={renderLeftControls}
        size={size}
        style={isAmountAlignLeft ? undefined : { textAlign: 'right' }}
        {...props}
      />
    </Wrapper>
  )
}
