import { type ReactElement, useEffect, useMemo, useState } from 'react'

import { useGetSites } from 'hooks/useGetSites'
import { DeviceModel } from 'types/devices'

import {
  DevicesFilterInput,
  type DevicesFilterInputProps,
} from './DevicesFilter/DevicesFilterInput'

const deps = { DevicesFilterInput }
export { deps as DevicesFilterDeps }

export type DevicesFilterProps = Pick<
  DevicesFilterInputProps,
  'value' | 'onValueChange'
>

export const DevicesFilter = ({
  value,
  onValueChange,
}: DevicesFilterProps): ReactElement => {
  const [query, setQuery] = useState<string>()

  const {
    isLoading,
    isLoadingMore,
    hasMore,
    onLoadMore,
    error,
    sites,
    refetch,
  } = useGetSites()

  useEffect(() => {
    if (hasMore && !isLoadingMore) {
      onLoadMore()
    }
  }, [hasMore, isLoadingMore, onLoadMore])

  const itemsState = useMemo((): DevicesFilterInputProps.Items => {
    if (isLoading || isLoadingMore || hasMore) {
      return { $type: 'Loading' }
    }
    if (error || !sites) {
      return { $type: 'Error', onRetry: () => refetch() }
    }
    if (!sites.length) {
      return { $type: 'Empty' }
    }
    const sitesFormatted: DevicesFilterInputProps.Site[] = []
    sites.forEach((site) => {
      if (!site.id || !site.name || !site.devices) {
        return
      }
      const devicesFormatted: DevicesFilterInputProps.Device[] = []
      site.devices.forEach((device) => {
        // exclude devices with null name
        if (!device.id || !device.name || !device.model) {
          return
        }
        devicesFormatted.push({
          id: device.id,
          name: device.name,
          type: device.model as DeviceModel,
        })
      })
      // exclude sites with 0 devices
      if (!devicesFormatted.length) {
        return
      }
      sitesFormatted.push({
        id: site.id,
        name: site.name,
        devices: devicesFormatted,
      })
    })
    return { $type: 'Ok', sites: sitesFormatted }
  }, [isLoading, isLoadingMore, hasMore, error, sites, refetch])

  return (
    <deps.DevicesFilterInput
      value={value}
      onValueChange={onValueChange}
      query={query}
      onQueryChange={setQuery}
      items={itemsState}
    />
  )
}
