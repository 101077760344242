import { Condition } from 'components/Condition/Condition'

import { useCreateAccountStage } from '../../hooks/useCreateAccountStage/useCreateAccountStage'
import { CreateAccountStage } from '../../hooks/useGoToCreateAccountStage/useGoToCreateAccountStage.types'
import { CreateAccountForm } from './components/CreateAccountForm/CreateAccountForm'

export const AccountNameStage = () => {
  const { stage } = useCreateAccountStage()

  return (
    <Condition shouldShow={stage === CreateAccountStage.AccountNameStage}>
      <CreateAccountForm dataTestId="account-name-stage" />
    </Condition>
  )
}
