import { BodyDefault, ButtonLink } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const LoaderContainer = styled.div`
  display: flex;
  padding-right: 0.5rem;
`

export const NotificationContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.YELLOW_80};
  border-radius: 7px;
  padding: 1.25rem 1.5rem 1.25rem 1rem;
`

export const InvitationPendingText = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0rem;
  padding: 0rem 0rem 1rem 0rem;
`

export const SendInvitationLink = styled(ButtonLink)<{
  $isSending: boolean
}>`
  ${BodyDefault};
  color: ${({ $isSending, theme }) =>
    $isSending ? theme.colors.GREY_250 : theme.colors.BLUE_1000};
  display: flex;
  margin: 0;

  &:hover {
    cursor: ${({ $isSending }) => ($isSending ? 'not-allowed' : 'pointer')};
    text-decoration: underline;
  }
`
