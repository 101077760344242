import { useCallback } from 'react'
import {
  Box,
  ButtonFill,
  Flex,
  Heading,
  Spinner,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'

import { ErrorMessage } from '../../components/Form.styled'
import { BrandingThemeSelect } from '../components/BrandingThemeSelect/BrandingThemeSelect'
import { useGetXeroBrandingThemes } from '../hooks/useGetXeroBrandingThemes'
import { Description } from '../XeroPaymentServicesConnection.styled'
import { XeroPaymentServicesConnetionFormValues } from './XeroPaymentServicesConnectionSetup.types'

export const StepTwo = () => {
  const { values, errors, setFieldValue, setFieldError } =
    useFormikContext<XeroPaymentServicesConnetionFormValues>()
  const { isLoading, xeroBrandingThemes, error, refetch } =
    useGetXeroBrandingThemes(values.organisation)

  const handleNext = useCallback(() => {
    if (values.xeroThemeIds.length === 0) {
      setFieldError(
        'xeroThemeIds',
        translate(
          'page.settings.connections.xeroPaymentServices.errors.noBrandingThemeSelected'
        )
      )
      return
    }
    setFieldValue('step', 3)
  }, [setFieldValue, setFieldError, values])

  if (isLoading) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    )
  }

  if (error) {
    return (
      <GenericErrorPage
        retry={refetch}
        data-testid="fetch-branding-themes-error"
      />
    )
  }

  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex flexDirection="column" width="100%">
        <Box mb="24px">
          <Heading.H3>
            {translate(
              'page.settings.connections.xeroPaymentServices.brandingThemeSelect.title'
            )}
          </Heading.H3>
        </Box>
        <Box mb="40px">
          <Description>
            {translate(
              'page.settings.connections.xeroPaymentServices.brandingThemeSelect.description'
            )}
          </Description>
        </Box>

        <BrandingThemeSelect brandingThemes={xeroBrandingThemes} />
        {errors?.xeroThemeIds && (
          <ErrorMessage>{errors.xeroThemeIds}</ErrorMessage>
        )}
      </Flex>

      <ButtonFill fullWidth onClick={handleNext}>
        {translate('page.settings.connections.xeroBankFeeds.cta.next')}
      </ButtonFill>
    </Flex>
  )
}
