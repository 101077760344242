import { useCallback, useMemo, useState } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { useFormikContext } from 'formik'

import { useSubcategories } from 'hooks/subcategories/useSubcategories'
import { mapSubcategoriesToItems } from 'hooks/subcategories/useSubcategories.utils'
import { validateRequired } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { CategoryDropdown } from 'components/EditCategoriesModal/Fields/CategoryDropdown'
import { EditCategory } from 'components/EditCategoriesModal/Fields/EditCategory'
import { validateSubcategory } from 'components/EditZellerCategories/Fields/utils/validators'

import {
  EditState,
  EntityCategoryValues,
  SubcategoryItem,
  UpdateValuesFields,
} from '../EditZellerCategories.types'
import { useEditCategoryState } from '../hooks/useEditCategoryState'
import { SubcategoriesErrorPlaceholder } from './Placeholders/SubcategoriesErrorPlaceholder'

interface Props<V extends EntityCategoryValues> {
  selectedCategory: EntityCategories | undefined
  initialValue: string | null
  onEditStateChange?: (newState: EditState) => void
  onEditSubcategory?: (values: V) => void
  onDeleteSubcategory?: (values: V) => void
}

export const SubcategoryField = <V extends EntityCategoryValues>({
  selectedCategory,
  initialValue,
  onEditStateChange,
  onEditSubcategory,
  onDeleteSubcategory,
}: Props<V>) => {
  const [searchInput, setSearchInput] = useState('')
  const { values } = useFormikContext<EntityCategoryValues>()

  const { subcategories, isLoading, error, refetch } = useSubcategories({
    category: selectedCategory || null,
  })

  const {
    handleEdit,
    onSave,
    onCancel,
    onDelete,
    handleAdd,
    saveButtonLabel,
    isEditing,
    isAddingSubcategory,
    isEditingSubcategory,
    isDeletingSubcategory,
  } = useEditCategoryState({
    category: selectedCategory,
    searchInput,
    onEditStateChange,
    onEditSubcategory,
    onDeleteSubcategory,
    subcategories,
  })

  const subcategoryItems = useMemo(
    () => mapSubcategoriesToItems(subcategories, handleEdit),
    [subcategories, handleEdit]
  )

  const updateSubcategory = useCallback(
    ({
      formik,
      newItem,
    }: UpdateValuesFields<EntityCategoryValues, SubcategoryItem>) => {
      formik.setValues({
        ...formik.values,
        subcategory: newItem?.value.toString(),
        subcategoryId: newItem?.id,
      })
    },
    []
  )

  const selectedItem = useMemo(() => {
    if (values.subcategory) {
      return (
        subcategoryItems.find((item) => item.value === values.subcategory) || {
          label: values.subcategory,
          value: values.subcategory,
          id: values.subcategoryId || '',
        }
      )
    }

    return null
  }, [values.subcategory, values.subcategoryId, subcategoryItems])

  const subtext =
    values.subcategory !== initialValue && initialValue
      ? translate('component.editZellerCategories.previously', {
          initialValue,
        })
      : undefined

  return isEditing ? (
    <EditCategory
      name="subcategory"
      label={translate('component.editZellerCategories.subcategoryLabel')}
      onSave={onSave}
      onCancel={onCancel}
      onDelete={onDelete}
      deleteButtonLabel={translate(
        'component.editZellerCategories.deleteSubcategory'
      )}
      saveButtonLabel={saveButtonLabel}
      cancelButtonLabel={translate(
        'component.editZellerCategories.cancelLabel'
      )}
      validate={validateSubcategory}
      shouldValidateImmediately
      isSaving={isAddingSubcategory || isEditingSubcategory}
      isDeleting={isDeletingSubcategory}
    />
  ) : (
    <CategoryDropdown
      name="subcategory"
      updateValues={updateSubcategory}
      label={translate('component.editZellerCategories.subcategoryLabel')}
      mobileLabel={translate(
        'component.editZellerCategories.mobileSubcategoryLabel'
      )}
      placeholder={translate(
        'component.editZellerCategories.subcategoryPlaceholder'
      )}
      selectedItem={selectedItem}
      items={subcategoryItems}
      isDisabled={selectedCategory === undefined}
      isSearchDisabled={error && subcategoryItems.length === 0 && !isLoading}
      onChangeSearchInput={setSearchInput}
      renderNoItemsPlaceholder={() =>
        error ? (
          <SubcategoriesErrorPlaceholder retry={() => refetch?.()} />
        ) : null
      }
      subtext={subtext}
      validate={validateRequired}
      isLoading={isLoading}
      handleAddNewItem={handleAdd}
      hasError={Boolean(error)}
    />
  )
}
