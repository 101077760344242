import React from 'react'
import { AnchorBasic, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as MobileIcon } from 'assets/svg/mobile.svg'
import { translate } from 'utils/translations'
import { shared } from 'translations'

import { MobileInfoContainer, MobilePhoneContainer } from '../../Help.styled'

export const MobilePhoneInfo = () => {
  return (
    <div>
      <MobilePhoneContainer flexDirection="row">
        <SvgIcon width="16" height="16">
          <MobileIcon />
        </SvgIcon>
        <AnchorBasic
          href={`tel:${shared.contactSupport.primaryPhone.replaceAll(' ', '')}`}
          className="primary-phone"
        >
          {translate('shared.contactSupport.primaryPhone')}
        </AnchorBasic>
        <span className="alternative-phone">
          {translate('shared.contactSupport.alternativePhone')}
        </span>
      </MobilePhoneContainer>
      <MobileInfoContainer>
        {translate('shared.contactSupport.phoneInfo')}
      </MobileInfoContainer>
    </div>
  )
}
