import { StyledTableEffect } from '@npco/ui-table'
import styled, { css } from 'styled-components'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 1.125rem;
  padding: 0.75rem 0.375rem;
  ${StyledTableEffect}
`

export const StyledClickableWrapper = styled(StyledWrapper)<{
  $isNew?: boolean
}>`
  cursor: pointer;
  appearance: none;
  background: none;
  width: 100%;
  font: inherit;
  text-align: inherit;

  ${({ theme, $isNew }) =>
    $isNew &&
    css`
      animation: ${theme.animation.appearAnimation} 1s linear;
    `}

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_30};
  }
`
