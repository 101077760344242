import { useCallback } from 'react'
import { DebitCardFormat } from '@npco/mp-gql-types'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import {
  ReportLostAndReplaceCardStage,
  ReportLostCardState,
} from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { translate } from 'utils/translations'

import { useReportLostAndReplaceCard } from '../../hooks/useReportLostAndReplaceCard'
import { useReportLostAndReplaceCardState } from '../../hooks/useReportLostAndReplaceCardState'

export const useSubmitReportLostAndReplaceCardForm = () => {
  const { state } = useReportLostAndReplaceCardState<ReportLostCardState>()
  const { reportLostAndReplaceVirtualCard, isReportingLostAndReplaceCard } =
    useReportLostAndReplaceCard({
      velocityControl: state?.velocityControl ?? null,
      entityAddressTimezone: state?.entityAddressTimezone,
    })

  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()

  const handleSubmit = useCallback(async () => {
    if (!state) {
      ErrorLogger.report(
        `[Banking] ${translate(
          'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardPrompt.submitErrorMessage'
        )}`
      )
      showErrorToast()
      return
    }

    if (state.cardFormat === DebitCardFormat.VIRTUAL) {
      reportLostAndReplaceVirtualCard({
        cardId: state.cardId,
        cardColor: state.cardColor,
        isAdmin: state.isAdmin,
        isCardOwner: state.isCardOwner,
        cardOwnerFirstName: state?.cardOwnerFirstName,
      })
      return
    }

    goToReportLostAndReplaceCardStage({
      stage: ReportLostAndReplaceCardStage.ReplaceCardAddress,
      cardId: state.cardId,
      cardColor: state.cardColor,
      cardFormat: state.cardFormat,
      isAdmin: state.isAdmin,
      velocityControl: state?.velocityControl ?? null,
      entityAddressTimezone: state?.entityAddressTimezone,
    })
  }, [
    state,
    goToReportLostAndReplaceCardStage,
    reportLostAndReplaceVirtualCard,
  ])

  return {
    handleSubmit,
    isSubmitting: isReportingLostAndReplaceCard,
  }
}
