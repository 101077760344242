import { useEffect, useState } from 'react'
import {
  Breadcrumb,
  ButtonFill,
  Flex,
  PageTemplate,
} from '@npco/zeller-design-system'

import { useGetSites } from 'hooks/useGetSites'
import { EXTERNAL_SHOP_URL } from 'const/externalLinks'
import { translate } from 'utils/translations'
import { SpinnerWrapped } from 'components/Spinner'

import { DeviceDrawer } from './DeviceDrawer/DeviceDrawer'
import { DeviceWithSiteInfo } from './Devices.types'
import { mapSiteInfoToDevices } from './Devices.utils'
import { DevicesEmptyState } from './DevicesEmptyState/DevicesEmptyState'
import { DeviceSitesList } from './DeviceSitesList/DeviceSitesList'
import { useDeviceAssignment } from './hooks/useDeviceAssignment'

export const Devices = () => {
  const [selectedDevice, setSelectedDevice] =
    useState<DeviceWithSiteInfo | null>(null)
  const [areDetailsOpen, setAreDetailsOpen] = useState(false)

  const { handleReassign, sites, isLoadingMutation, setSites } =
    useDeviceAssignment()

  const {
    sites: sitesData,
    isLoading: isFetchingSites,
    onLoadMore,
    hasMore,
    isLoadingMore,
  } = useGetSites({
    pageSize: 100,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (hasMore && !isLoadingMore) {
      onLoadMore()
    }
  }, [hasMore, isLoadingMore, onLoadMore])

  useEffect(() => {
    if (sitesData) {
      setSites(mapSiteInfoToDevices(sitesData))
    }
  }, [sitesData, setSites])

  const isLoading = isLoadingMutation || isFetchingSites

  const showDetails = (device: DeviceWithSiteInfo) => {
    setSelectedDevice(device)
    setAreDetailsOpen(true)
  }

  const numberOfDevices = sitesData.reduce(
    (acc, site) => acc + (site?.devices?.length || 0),
    0
  )

  const getContent = () => {
    if (isLoading) {
      return <SpinnerWrapped variant="centre" />
    }

    if (numberOfDevices === 0) {
      return <DevicesEmptyState />
    }

    return (
      <DeviceSitesList
        sites={sites}
        hasMoreSites={hasMore}
        onScrollEnd={onLoadMore}
        showDetails={showDetails}
      />
    )
  }

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex justifyContent="space-between" width="100%">
            <Flex height="48px" alignItems="center">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Breadcrumb.Text>
                    {translate('page.title.devices')}
                  </Breadcrumb.Text>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Flex>
            {!isLoading && (
              <ButtonFill
                onClick={() =>
                  window.open(
                    EXTERNAL_SHOP_URL,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                {numberOfDevices > 0
                  ? translate('page.terminals.shopNow')
                  : translate('page.terminals.purchaseTerminal')}
              </ButtonFill>
            )}
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      {getContent()}
      <DeviceDrawer
        selectedDevice={selectedDevice}
        areDetailsOpen={areDetailsOpen}
        setAreDetailsOpen={setAreDetailsOpen}
        setSelectedDevice={setSelectedDevice}
        handleReassign={handleReassign}
      />
    </PageTemplate>
  )
}
