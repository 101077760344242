import { gql } from '@apollo/client'

export const RecordPayment = gql`
  mutation RecordPayment($entityUuid: ID!, $input: InvoiceRecordPaymentInput!) {
    recordPayment(entityUuid: $entityUuid, input: $input) {
      __typename
      id
      activities {
        __typename
        balance
        cnpTxnRefNum
        completedTime
        contactName
        contactUuid
        dueDate
        failureReason
        id
        paidAmount
        status
        reminderIndex
        title
        type
      }
      status
      payments {
        __typename
        id
        paymentTimeISO
      }
      dueAmount
      paidAmount
    }
  }
`
