import { useCallback, useMemo, useState } from 'react'
import { rvIsUserSigningUp } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Auth0Error } from 'auth0-js'

import { FieldValuesProps } from 'types/common'
import { getWebAuth } from 'services/auth0'

import { getSignUpErrorMessage } from './SignUp.utils'

export const useSignUpErrorMessages = () => {
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    React.ReactNode | undefined
  >(undefined)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    React.ReactNode | undefined
  >(undefined)

  const setSignUpErrors = useCallback((err: Auth0Error) => {
    const { emailError, passwordError } = getSignUpErrorMessage(err)
    setEmailErrorMessage(emailError)
    setPasswordErrorMessage(passwordError)
  }, [])

  return {
    emailErrorMessage,
    passwordErrorMessage,
    setSignUpErrors,
    setEmailErrorMessage,
    setPasswordErrorMessage,
  }
}

export const useSignUp = () => {
  const webAuth = useMemo(() => getWebAuth(), [])

  const handleSignUp = useCallback(
    ({
      values: { email, password },
      onError,
    }: {
      values: FieldValuesProps
      onError: (err: Auth0Error) => void
    }) => {
      webAuth.redirect.signupAndLogin(
        {
          connection: 'Username-Password-Authentication',
          email,
          password,
        },
        (err) => {
          if (err) {
            onError(err)
          }
        }
      )

      rvIsUserSigningUp(true)
    },
    [webAuth]
  )

  return { handleSignUp }
}
