import {
  BillFilterInput,
  DebitCardTransactionTypeV2,
  DepositFilterInput,
  TransactionFilterInput,
  TransactionStatus,
} from '@npco/mp-gql-types'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'
import { filter, flatten, map, pipe, uniq } from 'ramda'

import {
  rangePickerInitialValue,
  REPORTS_TRANSACTION_STATUSES,
  TRANSACTION_SOURCES,
  TRANSACTION_STATUSES,
} from 'const/common'
import dayjs from 'utils/dayjs'
import { PickerItemProps } from 'types/picker'
import { TransactionFilterStatus } from 'types/transactions'
import { FilterableTransactionType } from 'layouts/AccountLayout/filters/AccountFilterTransactionType/AccountFilterTransactionType.constants'

interface DateFilterInput {
  from: Date | undefined
  to: Date | undefined
}

export interface TransactionFiltersInput {
  dates?: DateFilterInput
  statuses?: PickerItemProps[]
  rates?: number[]
  phrase?: string
  terminals?: PickerItemProps[]
  types?: PickerItemProps[]
  sources?: PickerItemProps[]
}

export interface OverviewFiltersInput {
  dates?: DateFilterInput
  statuses?: PickerItemProps[]
  terminals?: PickerItemProps[]
  types?: PickerItemProps[]
  sources?: PickerItemProps[]
}

interface DepositFiltersInput {
  dates?: DateFilterInput
  rates?: number[]
}

export interface DebitCardTransactionsFiltersInput {
  dates?: DateFilterInput
  rates?: number[]
}

interface DepositsStatementsFilterInput {
  dates?: DateFilterInput
  siteUuid?: string
}

interface SimStatementsFilterInput {
  dates?: DateFilterInput
  rates?: number[]
  siteUuid?: string
}

const getFormattedDates = (
  dates: DateFilterInput,
  isLocal: boolean,
  isDateTime: boolean
) => {
  const dateUTCStart = dayjs(dates.from).utc().local()
  const dateUTCEnd = dayjs(dates.to).utc().local()

  const dateStartOfDay = isDateTime ? dateUTCStart : dateUTCStart.startOf('day')
  const dateToEndOfDay = isDateTime ? dateUTCEnd : dateUTCEnd.endOf('day')

  const formattedStartDate = isLocal
    ? dateStartOfDay.format()
    : dateStartOfDay.toISOString()
  const formattedEndDate = isLocal
    ? dateToEndOfDay.format()
    : dateToEndOfDay.toISOString()

  return [dates.from ? formattedStartDate : '', formattedEndDate]
}

export const mapDateFiltersToApi = (
  dates?: DateFilterInput,
  isLocal = false,
  isDateTime = false
) => {
  if (dates && (dates.from || dates.to)) {
    if (!rvDeferredFilterPayload()?.Date) {
      rvDeferredFilterPayload({
        ...rvDeferredFilterPayload(),
        Date: 'Date selected',
      })
    }

    return {
      between: getFormattedDates(dates, isLocal, isDateTime),
    }
  }

  return null
}

export const formatStatus = (status: PickerItemProps) => {
  if (status.value === TransactionFilterStatus.REFUNDED) {
    return TransactionStatus.APPROVED
  }
  return status.value
}

export const mapStatusFilterToApi = (statuses?: PickerItemProps[]) => {
  if (statuses && statuses.length > 0) {
    rvDeferredFilterPayload({
      ...rvDeferredFilterPayload(),
      Status: statuses?.map((status) => status.name).join(' & '),
    })
    const formattedStatuses = statuses.map(formatStatus)

    return {
      in: formattedStatuses,
    }
  }

  return null
}

export const mapReportStatusFilterToApi = (statuses?: PickerItemProps[]) => {
  if (statuses?.includes(REPORTS_TRANSACTION_STATUSES[0])) {
    return {
      status: { in: [TransactionStatus.APPROVED] },
    }
  }

  return null
}

export const mapRefundedStatusToApi = (statuses?: PickerItemProps[]) => {
  if (
    statuses &&
    statuses.length === 1 &&
    statuses.includes(TRANSACTION_STATUSES[2])
  ) {
    return { refunded: { eq: true } }
  }
  if (
    statuses?.includes(TRANSACTION_STATUSES[0]) &&
    !statuses?.includes(TRANSACTION_STATUSES[2])
  ) {
    return { refunded: { eq: false } }
  }
  return {}
}

export const mapSourceFilterToApi = (sources?: PickerItemProps[]) => {
  if (sources?.length) {
    rvDeferredFilterPayload({
      ...rvDeferredFilterPayload(),
      Source: sources?.map((source) => source.name).join(' & '),
    })
  }

  if (
    !sources ||
    sources.length === 0 ||
    sources.length === TRANSACTION_SOURCES.length
  ) {
    return null
  }

  const formattedStatuses = sources.map((source) => source.value)

  return {
    in: formattedStatuses,
  }
}

export const mapTypeFilterToApi = (types?: PickerItemProps[]) => {
  if (types && types.length > 0) {
    rvDeferredFilterPayload({
      ...rvDeferredFilterPayload(),
      Type: types?.map((type) => type.name).join(' & '),
    })
    const formattedStatuses = types.map((status) => status.value)

    return {
      in: formattedStatuses,
    }
  }

  return null
}

export const mapAmountRangeFilterToApi = (range?: number[]) => {
  if (
    range &&
    range.length > 1 &&
    (range[0] !== rangePickerInitialValue[0] ||
      range[1] !== rangePickerInitialValue[1])
  ) {
    rvDeferredFilterPayload({
      ...rvDeferredFilterPayload(),
      Amount: true,
    })

    return {
      between: range.map((rate) => rate * 100),
    }
  }
  return null
}

const mapSearchPhraseFilterToApi = (phrase?: string) => {
  return { contains: phrase }
}

const mapTransactionsSearchToApi = (phrase?: string) => {
  if (!phrase) {
    return {}
  }

  return {
    or: [
      { maskedPan: mapSearchPhraseFilterToApi(phrase) },
      { reference: mapSearchPhraseFilterToApi(phrase) },
      { depositShortId: { eq: phrase } },
    ],
  }
}

export const mapTerminalsFiltersToApi = (terminals?: PickerItemProps[]) => {
  if (terminals && terminals.length > 0) {
    rvDeferredFilterPayload({ ...rvDeferredFilterPayload(), Terminal: true })
    const deviceUuids = terminals.map((device) => device.id.toString())

    return {
      in: deviceUuids,
    }
  }

  return null
}

// need to pass object as return type so TS see no error with using rambda filter func
// eslint-disable-next-line @typescript-eslint/ban-types
export const filterObjectEmptyFields = (obj: any): object =>
  filter(Boolean, { ...obj })

export const mapSplitPaymentUuidToApi = (splitPaymentUuid?: string | null) => {
  if (splitPaymentUuid) {
    return { splitPaymentUuid: { eq: splitPaymentUuid } }
  }
  return {}
}

export const createSplitPaymentApiFilter = (
  input: TransactionFiltersInput,
  splitPaymentUuid?: string | null
): TransactionFilterInput => {
  const { dates } = input
  return filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates, false, true),
    ...mapSplitPaymentUuidToApi(splitPaymentUuid),
  })
}

export const mapFiltersInputToTransactionsApiFilter = (
  input: TransactionFiltersInput,
  isLocalDate = false,
  isDateTime = false
): TransactionFilterInput => {
  const { dates, statuses, rates, phrase, terminals, types, sources } = input
  return filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates, isLocalDate, isDateTime),
    status: mapStatusFilterToApi(statuses),
    amount: mapAmountRangeFilterToApi(rates),
    deviceUuid: mapTerminalsFiltersToApi(terminals),
    type: mapTypeFilterToApi(types),
    sourceFilter: mapSourceFilterToApi(sources),
    ...mapTransactionsSearchToApi(phrase),
    ...mapRefundedStatusToApi(statuses),
  })
}

export const mapFiltersInputToOverviewApiFilter = (
  input: OverviewFiltersInput,
  isDateTime = false
): TransactionFilterInput => {
  const { dates, statuses, terminals, types, sources } = input

  return filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates, true, isDateTime),
    deviceUuid: mapTerminalsFiltersToApi(terminals),
    type: mapTypeFilterToApi(types),
    sourceFilter: mapSourceFilterToApi(sources),
    ...mapReportStatusFilterToApi(statuses),
  })
}

export const mapFiltersInputToDepositApiFilter = (
  input: DepositFiltersInput,
  isLocalDate = false
): DepositFilterInput => {
  const { dates, rates } = input

  return filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates, isLocalDate),
    depositAmount: mapAmountRangeFilterToApi(rates),
  })
}

export const mapFiltersInputToDebitCardTransactionsApiFilter = (
  input: DebitCardTransactionsFiltersInput,
  isLocalDate = false
): DebitCardTransactionsFiltersInput => {
  const { dates, rates } = input

  return filterObjectEmptyFields({
    timestamp: mapDateFiltersToApi(dates, isLocalDate),
    amount: mapAmountRangeFilterToApi(rates),
  })
}

export const mapFiltersInputToDepositsStatementsApiFilter = (
  input: DepositsStatementsFilterInput,
  isLocalDate = false
): DepositFilterInput => {
  const { dates, siteUuid } = input

  return filterObjectEmptyFields(
    siteUuid
      ? {
          timestamp: mapDateFiltersToApi(dates, isLocalDate),
          siteUuid: {
            eq: siteUuid,
          },
        }
      : { timestamp: mapDateFiltersToApi(dates, isLocalDate) }
  )
}

export const mapFiltersInputToSimStatementsApiFilter = (
  input: SimStatementsFilterInput,
  isLocalDate = false
): BillFilterInput => {
  const { dates, rates, siteUuid } = input

  return filterObjectEmptyFields(
    siteUuid
      ? {
          timestamp: mapDateFiltersToApi(dates, isLocalDate),
          amount: mapAmountRangeFilterToApi(rates),
          siteUuid: {
            eq: siteUuid,
          },
        }
      : {
          timestamp: mapDateFiltersToApi(dates, isLocalDate),
          amount: mapAmountRangeFilterToApi(rates),
        }
  )
}

const FILTERABLE_TYPE_TO_TRANSACTION_TYPES: {
  [key in FilterableTransactionType]: DebitCardTransactionTypeV2[]
} = {
  [FilterableTransactionType.MoneyIn]: [
    DebitCardTransactionTypeV2.ATM_IN,
    DebitCardTransactionTypeV2.BPAY_IN,
    DebitCardTransactionTypeV2.DEPOSIT,
    DebitCardTransactionTypeV2.DE_IN,
    DebitCardTransactionTypeV2.NPP_IN,
    DebitCardTransactionTypeV2.REFUND,
    DebitCardTransactionTypeV2.REFUND_CNP,
    DebitCardTransactionTypeV2.TRANSFER_IN,
    DebitCardTransactionTypeV2.INTEREST,
  ],
  [FilterableTransactionType.MoneyOut]: [
    DebitCardTransactionTypeV2.ATM_OUT,
    DebitCardTransactionTypeV2.BPAY_OUT,
    DebitCardTransactionTypeV2.DE_OUT,
    DebitCardTransactionTypeV2.NPP_OUT,
    DebitCardTransactionTypeV2.PURCHASE,
    DebitCardTransactionTypeV2.PURCHASE_CNP,
    DebitCardTransactionTypeV2.TRANSFER_OUT,
    DebitCardTransactionTypeV2.WITHDRAWAL,
    DebitCardTransactionTypeV2.DDA_OUT,
  ],
  [FilterableTransactionType.InternalTransfers]: [
    DebitCardTransactionTypeV2.TRANSFER_IN,
    DebitCardTransactionTypeV2.TRANSFER_OUT,
  ],
  [FilterableTransactionType.ZellerDeposits]: [
    DebitCardTransactionTypeV2.DEPOSIT,
  ],
  [FilterableTransactionType.OutgoingTransfer]: [
    DebitCardTransactionTypeV2.DE_OUT,
    DebitCardTransactionTypeV2.NPP_OUT,
    DebitCardTransactionTypeV2.BPAY_OUT,
    DebitCardTransactionTypeV2.DDA_OUT,
  ],
  [FilterableTransactionType.CardPurchases]: [
    DebitCardTransactionTypeV2.PURCHASE,
    DebitCardTransactionTypeV2.PURCHASE_CNP,
    DebitCardTransactionTypeV2.REFUND,
    DebitCardTransactionTypeV2.REFUND_CNP,
  ],
  [FilterableTransactionType.BPAYPayment]: [
    DebitCardTransactionTypeV2.BPAY_OUT,
  ],
  [FilterableTransactionType.Interest]: [DebitCardTransactionTypeV2.INTEREST],
}

const mapFilterableTypeToApiTypes = (
  filterableType: FilterableTransactionType
) => FILTERABLE_TYPE_TO_TRANSACTION_TYPES[filterableType]

export const mapFilterableTypesToApiTypes = (
  filterableTransactionTypes: FilterableTransactionType[] | null
) => {
  if (!filterableTransactionTypes) {
    return null
  }

  return pipe(
    map(mapFilterableTypeToApiTypes),
    flatten,
    uniq
  )(filterableTransactionTypes)
}
