import { AccountStatementType } from '@npco/mp-gql-types'
import { UseTranslationsFn } from '@npco/utils-translations'
import { Dayjs } from 'dayjs'

import { translations } from 'translations/shared.i18n'

interface AccountStatementTitleArguments {
  statementType: AccountStatementType
  startDate: Dayjs
  tShared: UseTranslationsFn<typeof translations>
}

const getMonthlyStatementTitle = ({
  startDate,
}: Omit<AccountStatementTitleArguments, 'statementType' | 'tShared'>) => {
  return startDate.format('MMMM YYYY')
}

const getQuarter = (
  startOfQuarter: Dayjs,
  tShared: AccountStatementTitleArguments['tShared']
) => {
  switch (startOfQuarter.month()) {
    case 0: // Jan
    case 1: // Feb
    case 2: // Mar
      return { quarter: 3, start: tShared('jan'), end: tShared('mar') }
    case 3: // Apr
    case 4: // May
    case 5: // Jun
      return { quarter: 4, start: tShared('apr'), end: tShared('jun') }
    case 6: // Jul
    case 7: // Aug
    case 8: // Sep
      return { quarter: 1, start: tShared('jul'), end: tShared('sep') }
    case 9: // Oct
    case 10: // Nov
    case 11: // Dec
    default:
      return { quarter: 2, start: tShared('oct'), end: tShared('dec') }
  }
}

const getQuarterlyStatementTitle = ({
  startDate,
  tShared,
}: Omit<AccountStatementTitleArguments, 'statementType'>) => {
  const { quarter, start, end } = getQuarter(startDate, tShared)
  const year = startDate.format('YYYY')

  const date = tShared('quarterTitle', {
    quarter: quarter.toString(),
    start,
    end,
    year,
  })

  return date
}

export const getAccountStatementTitle = ({
  statementType,
  startDate,
  tShared,
}: AccountStatementTitleArguments) => {
  switch (statementType) {
    case AccountStatementType.QUARTERLY:
      return getQuarterlyStatementTitle({ startDate, tShared })
    case AccountStatementType.MONTHLY:
    default:
      return getMonthlyStatementTitle({ startDate })
  }
}

export const getStartOfNextStatementPeriod = (
  statementType: AccountStatementType,
  currentTime: Dayjs
) => {
  switch (statementType) {
    case AccountStatementType.MONTHLY:
      return currentTime.add(1, 'month').startOf('month')
    case AccountStatementType.QUARTERLY:
    default:
      return currentTime.add(1, 'quarter').startOf('quarter')
  }
}
