import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type AccountNameAndInterestCellDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  name: string
  type: Types.DebitCardAccountType
  status: Types.DebitCardAccountStatus
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    effectiveInterestRate: string | null
  } | null
}

export const AccountNameAndInterestCellDebitCardAccountV2FragmentDoc = gql`
  fragment AccountNameAndInterestCellDebitCardAccountV2Fragment on DebitCardAccountV2 {
    name
    type
    savingsAccountDetails {
      effectiveInterestRate
    }
    status
  }
` as unknown as TypedDocumentNode<
  AccountNameAndInterestCellDebitCardAccountV2Fragment,
  undefined
>
