import { Component, ReactNode } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'

import { UnexpectedError } from 'pages/ErrorPages/UnexpectedError/UnexpectedError'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: unknown) {
    ErrorLogger.report('Generic warning', { error })
  }

  onRecoverFromError = () => {
    this.setState({ hasError: false })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <UnexpectedError onLogout={this.onRecoverFromError} />
    }

    return children
  }
}
