import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import segmentPlugin from '@analytics/segment'
import Analytics from 'analytics'
import { rvDeferredFilterPayload } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/filters'

import { ANALYTICS_SEGMENT_ACCESS_KEY, IS_SEGMENT_ENABLED } from 'const/envs'

import { AnalyticsContext, AnalyticsInstanceCustom } from './AnalyticsContext'
import { getCustomPageName } from './pages'

interface AnalyticsProviderProps {
  children: ReactNode
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const location = useLocation()
  const locationName = useRef<string>('')

  const [analytics, setAnalytics] = useState<AnalyticsInstanceCustom | null>(
    null
  )

  const value = useMemo(
    () => ({ analytics, locationName }),
    [analytics, locationName]
  )

  useEffect(() => {
    if (!IS_SEGMENT_ENABLED) {
      return
    }

    const analyticsInstance = Analytics({
      app: 'Merchant Portal',
      plugins: [
        segmentPlugin({
          writeKey: ANALYTICS_SEGMENT_ACCESS_KEY,
        }),
      ],
    })

    setAnalytics(analyticsInstance as AnalyticsInstanceCustom)
  }, [])

  useEffect(() => {
    rvDeferredFilterPayload({})
    const currentPage = getCustomPageName(location.pathname)

    if (!currentPage) {
      return
    }

    // Hack to prevent incorrect page names logged for Drawer when opened
    // immediately after page redirect
    // eg: Transaction drawer opened on clicking transaction from overview page.
    if (!locationName.current.startsWith(`${currentPage}_`)) {
      analytics?.page({
        name: currentPage,
      })
      locationName.current = currentPage
    }
  }, [location.pathname, analytics])

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
