import { BodyDefault, Flex, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ItemRow = styled(Flex)`
  white-space: nowrap;
  justify-content: space-between;
  align-content: center;
  margin: 12px 0;
  grid-gap: 10px;
`

export const ItemText = styled(BodyDefault)`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const ReviewDetailContainer = styled(Flex)`
  flex-grow: 1;
  min-width: 0;
  flex-direction: column;
  gap: 40px;
`

export const Title = styled(Heading.H3)`
  font-size: 22px;
  line-height: 30px;
`
