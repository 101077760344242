import { StyledDetailsValue } from '../UserDetails.styled'
import { StyledPartsWrapper } from './DetailsEmailValue.styled'
import { splitEmail } from './DetailsEmailValue.utils'

interface Props {
  email: string
}

export const DetailsEmailValue = ({ email }: Props) => {
  const [user, domain = ''] = email.split('@')
  const emailParts = splitEmail(user)
  const domainParts = splitEmail(domain)

  return (
    <StyledDetailsValue data-testid="user-email-value">
      <StyledPartsWrapper>
        <StyledPartsWrapper>
          {emailParts.map((part, i) => {
            const emailKey = `${part}-${i}`
            return <span key={emailKey}>{part}</span>
          })}
          <StyledPartsWrapper>
            @
            {domainParts.map((part, i) => {
              const domainKey = `${part}-${i}`
              return <span key={domainKey}>{part}</span>
            })}
          </StyledPartsWrapper>
        </StyledPartsWrapper>
      </StyledPartsWrapper>
    </StyledDetailsValue>
  )
}
