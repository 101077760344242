import { Flex, Spinner } from '@npco/zeller-design-system'

export const CreateSavingsAccountLoader = () => {
  return (
    <Flex
      height={{ _: '248px', SM: '206px' }}
      alignItems="center"
      justifyContent="center"
    >
      <Spinner />
    </Flex>
  )
}
