import { forwardRef } from 'react'
import { LoaderSimple, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow-dropdown.svg'

import {
  SelectSize,
  type SelectTriggerAdaptiveProps as BaseSelectTriggerAdaptiveProps,
} from '../Select.types'
import { StyledSelectAdaptiveIcon } from './SelectTriggerAdaptive.styled'
import { SelectTriggerAdaptiveBasic } from './SelectTriggerAdaptiveBasic'
import { SelectTriggerContent } from './SelectTriggerContent'
import { SelectTriggerLeftAdornment } from './SelectTriggerLeftAdornment'

export type SelectTriggerAdaptiveProps = BaseSelectTriggerAdaptiveProps & {
  isLoading?: boolean
}

const SelectTriggerAdaptiveBasicWrapper = forwardRef<
  HTMLButtonElement,
  SelectTriggerAdaptiveProps
>(
  (
    {
      label,
      placeholder,
      placeholderIcon,
      selectedItem,
      size = SelectSize.Medium,
      isLoading = false,
      disabled,
      ...rest
    },
    ref
  ) => {
    return (
      <SelectTriggerAdaptiveBasic
        hasValue={!!selectedItem}
        size={size}
        ref={ref}
        label={!placeholderIcon ? label : null}
        placeholder={!placeholderIcon ? placeholder : label || placeholder}
        renderLeftControls={() => (
          <SelectTriggerLeftAdornment
            avatar={selectedItem?.avatar}
            icon={selectedItem?.icon || placeholderIcon}
            image={selectedItem?.image}
            size={size}
          />
        )}
        renderContent={() => (
          <SelectTriggerContent
            label={selectedItem?.label}
            subLabel1={selectedItem?.subLabel1}
            subLabel2={selectedItem?.subLabel2}
          />
        )}
        renderRightControls={() =>
          isLoading ? (
            <LoaderSimple />
          ) : (
            <StyledSelectAdaptiveIcon
              isOpen={rest.isOpen}
              data-testid="select-trigger-arrow"
            >
              <SvgIcon width="16" height="16">
                <ArrowDown />
              </SvgIcon>
            </StyledSelectAdaptiveIcon>
          )
        }
        disabled={disabled ?? isLoading}
        {...rest}
      />
    )
  }
)

export const SelectTriggerAdaptive = forwardRef<
  HTMLButtonElement,
  SelectTriggerAdaptiveProps
>(({ label, ...props }, ref) => {
  return (
    <SelectTriggerAdaptiveBasicWrapper
      {...props}
      label={label || props.placeholder}
      placeholder={label ? props.placeholder : undefined}
      ref={ref}
    />
  )
})
