import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { IconAvatarIconFragmentDoc } from '@npco/mp-ui-icon-avatar'

export type PopperItemsContentDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  id: string
  name: string
  type: Types.DebitCardAccountType
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    animation: string | null
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export const PopperItemsContentDebitCardAccountV2FragmentDoc = gql`
  fragment PopperItemsContentDebitCardAccountV2Fragment on DebitCardAccountV2 {
    id
    name
    type
    icon {
      ...IconAvatarIconFragment
    }
  }
  ${IconAvatarIconFragmentDoc}
` as unknown as TypedDocumentNode<
  PopperItemsContentDebitCardAccountV2Fragment,
  undefined
>
