import {
  BodyDefault,
  Box,
  Flex,
  ModalBasic,
  RadioOutlineCard,
  SvgIcon,
} from '@npco/zeller-design-system'
import { Form } from 'formik'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { MultiStageModal } from 'components/MultiStageModal'

export const StyledMultiStageModal = styled(ModalBasic)`
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    height: calc(100vh - 160px);
    max-height: 42.5rem;
  }
`

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

export const StyledFormWithOffset = styled(StyledForm)`
  margin-left: -4px;
`

export const StyledFormFooterWithOffset = styled(MultiStageModal.FormButtons)`
  padding-left: 4px;
`

export const StyledFormBodyWithOffset = styled(ModalBasic.Body)`
  padding-left: 4px;
`

export const StyledRadioCard = styled(RadioOutlineCard)`
  width: auto;
`

export const StyledRadioCardIcon = styled(SvgIcon)`
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  width: 40px;
  height: 40px;
  border-radius: 4px;
`

export const StyledDescription = styled(Box)`
  ${BodyDefault}
`

const CommonImageContainerCss = css`
  margin: 0 auto 24px;
  height: 140px;
  width: 140px;
  background-size: cover;
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin: 0 auto 20px;
    width: 180px;
    height: 180px;
  }
`

export const AnnouncementModalSvgBackgroundContainer = styled(Box)<{
  backgroundUrl: string
}>`
  ${CommonImageContainerCss}
  background: url(${({ backgroundUrl }) => backgroundUrl}) 0 0 / cover;
`

export const StyledAbsoluteContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
`
