import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'debounce'

import { RangePickerValue } from 'components/Filters/NewFilters/RangePicker/RangePicker.types'

import { defaultAmountDisplay } from '../../../AccountFilterAmount.utils'
import { useInputValue } from '../useInputValue/useInputValue'

export const SLIDER_RANGE = { min: 0, max: 100000 }

interface Arguments {
  isOpen: boolean
  amount: { min: number; max: number }
  setAmount: (value: { min: number; max: number }) => void
  resetAmount: () => void
}

const boundToSliderRange = (value: number) =>
  Math.min(Math.max(value, SLIDER_RANGE.min), SLIDER_RANGE.max)

const boundMinValue = (value: number, sliderMaxValue: number) =>
  boundToSliderRange(Math.min(value, sliderMaxValue))

const boundMaxValue = (value: number, sliderMinValue: number) =>
  boundToSliderRange(Math.max(value, sliderMinValue))

export const INPUT_VALUE_CHANGE_DEBOUNCE = 500

export const useFilterAmountPopper = ({
  isOpen,
  amount,
  setAmount,
  resetAmount,
}: Arguments) => {
  const [sliderValue, setSliderValue] = useState(amount)
  const debouncedSetAmount = useMemo(
    () => debounce(setAmount, INPUT_VALUE_CHANGE_DEBOUNCE),
    [setAmount]
  )

  const minInput = useInputValue({
    defaultValue: amount.min,
    onChange: (value) => {
      const boundedValue = boundMinValue(value, sliderValue.max)
      setSliderValue({
        min: boundedValue,
        max: sliderValue.max,
      })
      debouncedSetAmount({ min: boundedValue, max: amount.max })
    },
    sliderValue: sliderValue.min,
  })
  const maxInput = useInputValue({
    defaultValue: amount.max,
    onChange: (value) => {
      const boundedValue = boundMaxValue(value, sliderValue.min)
      setSliderValue({
        min: sliderValue.min,
        max: boundedValue,
      })
      debouncedSetAmount({ min: amount.min, max: boundedValue })
    },
    sliderValue: sliderValue.max,
  })

  const onSliderChange = (newValue: RangePickerValue) => {
    const [min, max] = newValue
    setSliderValue({ min, max })
    minInput.setInputValue(String(min))
    maxInput.setInputValue(String(max))
  }

  const onReset = () => {
    resetAmount()
    setSliderValue({
      min: defaultAmountDisplay.min,
      max: defaultAmountDisplay.max,
    })
    minInput.setInputValue(String(defaultAmountDisplay.min))
    maxInput.setInputValue(String(defaultAmountDisplay.max))
  }

  const onAfterChange = (newValue: number | readonly number[]) => {
    const [min, max] = newValue as RangePickerValue
    setAmount({ min, max })
  }

  useEffect(() => {
    if (isOpen) {
      setSliderValue(amount)
      minInput.setInputValue(String(amount.min))
      maxInput.setInputValue(String(amount.max))
    }

    // Only when opening the popper we want to update the input values to match
    // values of slider.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return {
    onSliderChange,
    onAfterChange,
    minInput,
    maxInput,
    onReset,
    sliderValue,
  }
}
