import { COLOR, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const PopperButtonContainer = styled(Flex)`
  width: 200px;
  height: 64px;
  position: absolute;
  right: 0px;
  border: 1px solid ${COLOR.GREY_150};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.WHITE};
`
