import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { mock } from '@npco/utils-apollo-testing-link'

import { cashflowReportingAccountFragementFragment } from '../graphql/cashflowReportingAccountFragement.generated'
import {
  CashflowReportingAccountsListQuery,
  CashflowReportingAccountsListQueryResponse,
  CashflowReportingAccountsListQueryVariables,
} from '../graphql/getCashflowReportingAccountsList.generated'

export const accountId = '327fae99-f10b-522a-a36d-15074809871e'
export const accountName = '0 areg 27 edi'
export const accountType = DebitCardAccountType.ZLR_DEBIT

const icon = {
  colour: 'GREEN_1000',
  letter: '0',
  image: null,
  images: null,
  animation: null,
}
const status = DebitCardAccountStatus.ACTIVE
const typename = 'DebitCardAccountV2'

const accountIconAndStatus = {
  icon,
  status,
}

export const mockAccounts: cashflowReportingAccountFragementFragment[] = [
  {
    id: accountId,
    name: accountName,
    ...accountIconAndStatus,
    __typename: typename,
    type: accountType,
  },
  {
    id: 'acbe594c-3e89-52ba-985f-e9a33a6612ae',
    name: '0 ud account',
    ...accountIconAndStatus,
    __typename: typename,
    type: accountType,
  },
  {
    id: '5ff3cea2-1684-5e57-abe7-05fce1684d8e',
    name: '0-lei-1',
    ...accountIconAndStatus,
    __typename: typename,
    type: accountType,
  },
]

export const mockAccountsWithNoneActive: cashflowReportingAccountFragementFragment[] =
  [
    {
      id: accountId,
      name: accountName,
      __typename: typename,
      ...accountIconAndStatus,
      status: DebitCardAccountStatus.CLOSED,
      type: accountType,
    },
  ]
export const mockSavingAccounts: cashflowReportingAccountFragementFragment[] = [
  {
    id: '5ff3cea2-1684-5e57-abe7-05fce1684d8e',
    name: '0-lei-1',
    ...accountIconAndStatus,
    __typename: typename,
    type: DebitCardAccountType.SAVINGS,
  },
]
export const GetCashflowReportingAccountsListMocks = mock<
  CashflowReportingAccountsListQueryVariables,
  CashflowReportingAccountsListQueryResponse
>(CashflowReportingAccountsListQuery)
