import { DebitCardColour, DebitCardStatus } from '@npco/mp-gql-types'

import { capitalise } from 'utils/common'
import { CardSize } from 'types/cards'

import { useZellerCardAttributesMap } from './hooks/useZellerCardAttributesMap'
import { LockTooltip } from './LockTooltip/LockTooltip'
import {
  StyledCardNumber,
  StyledContainer,
  StyledImage,
  StyledZellerCard,
} from './ZellerCard.styled'
import { renderCardNumber } from './ZellerCard.utils'

interface ZellerCardProps {
  cardColor: DebitCardColour
  size: CardSize
  maskedPan?: string
  isNumberWithDots?: boolean
  isLocked?: boolean
  className?: string
  cardStatus?: DebitCardStatus
}

export const ZellerCard = ({
  cardColor,
  size,
  maskedPan,
  isNumberWithDots,
  isLocked = false,
  className,
  cardStatus,
}: ZellerCardProps) => {
  const zellerCardAttributes = useZellerCardAttributesMap()

  return (
    <StyledContainer className={className}>
      <StyledZellerCard isLocked={isLocked}>
        <StyledImage
          src={zellerCardAttributes[cardColor].src}
          alt={zellerCardAttributes[cardColor].altText}
          width={size === CardSize.LARGE ? 290 : 48}
        />
        <StyledCardNumber
          cardSize={size}
          color={zellerCardAttributes[cardColor].fontColor}
        >
          {renderCardNumber(maskedPan, isNumberWithDots)}
        </StyledCardNumber>
      </StyledZellerCard>
      {isLocked && <LockTooltip size={size} title={capitalise(cardStatus)} />}
    </StyledContainer>
  )
}
