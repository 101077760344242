import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type SendContactTransferMutationVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  input: Types.FundsTransferInput
}>

export type SendContactTransferMutationResponse = {
  __typename?: 'Mutation'
  transferFundsDebitCardAccount: {
    __typename?: 'TransferFundsDebitCardAccountResult'
    id: string
    status: Types.DebitCardTransactionStatusV2
    transferType: Types.DebitCardTransactionTypeV2 | null
    fallbackToDe: boolean | null
    error: Types.TransferErrorCode | null
  }
}

export const SendContactTransfer = gql`
  mutation SendContactTransfer($entityUuid: ID!, $input: FundsTransferInput!) {
    transferFundsDebitCardAccount(entityUuid: $entityUuid, input: $input) {
      id
      status
      transferType
      fallbackToDe
      error
    }
  }
` as unknown as TypedDocumentNode<
  SendContactTransferMutationResponse,
  SendContactTransferMutationVariables
>
export type SendContactTransferMutationFn = Apollo.MutationFunction<
  SendContactTransferMutationResponse,
  SendContactTransferMutationVariables
>

/**
 * __useSendContactTransferMutation__
 *
 * To run a mutation, you first call `useSendContactTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactTransferMutation, { data, loading, error }] = useSendContactTransferMutation({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendContactTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendContactTransferMutationResponse,
    SendContactTransferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendContactTransferMutationResponse,
    SendContactTransferMutationVariables
  >(SendContactTransfer, options)
}
export type SendContactTransferMutationHookResult = ReturnType<
  typeof useSendContactTransferMutation
>
export type SendContactTransferMutationResult =
  Apollo.MutationResult<SendContactTransferMutationResponse>
export type SendContactTransferMutationOptions = Apollo.BaseMutationOptions<
  SendContactTransferMutationResponse,
  SendContactTransferMutationVariables
>
