import { Flex } from '@npco/zeller-design-system'

import {
  SkeletonBodyLarge,
  SkeletonBodySmall,
} from 'components/SkeletonLoader/SkeletonLoaderTypography'

export const TransactionTotalsSkeleton = () => {
  return (
    <Flex data-testid="transaction-load-skeleton" height="52px" mb="3rem">
      <Flex flex="1 1 0" justifyContent="flex-start">
        <Flex flexDirection="column">
          <SkeletonBodySmall width={80} margin="0" />
          <SkeletonBodyLarge width={100} margin="0" />
        </Flex>
      </Flex>
      <Flex flex="1 1 0" justifyContent="center">
        <Flex flexDirection="column">
          <SkeletonBodySmall width={80} margin="0" />
          <SkeletonBodyLarge width={100} margin="0" />
        </Flex>
      </Flex>
      <Flex flex="1 1 0" justifyContent="flex-end">
        <Flex flexDirection="column">
          <SkeletonBodySmall width={80} margin="0" />
          <SkeletonBodyLarge width={100} margin="0" />
        </Flex>
      </Flex>
    </Flex>
  )
}
