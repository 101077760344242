/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Anchor,
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  ButtonLink,
  COLOR,
  CrossmarkRoundEdgeIcon,
  Divider,
  Flex,
  INPUT_SIZE,
  InputWithoutLabel,
  type InputWithoutLabelProps,
  SearchIcon,
  SelectSize,
  SelectStyle,
  SvgIcon,
} from '@npco/zeller-design-system'
import { isEmpty } from 'lodash-es'

import { ReactComponent as ExclamationIcon } from 'assets/svg/exclamation.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ListLoader as LoaderSpinner } from 'components/Lists'
import { LoaderSimple } from 'components/LoaderSimple'
import { MobileFiltersButtonWrapper } from 'components/MobileFilters/MobileFilters.styled'
import { translationsShared } from 'translations'

import {
  SelectTriggerAdaptive,
  type SelectTriggerAdaptiveProps,
} from '../../Select/SelectTriggerAdaptive'
import type {
  MultiSelectBasicProps,
  MultiSelectItemBasic as Option,
} from '../MultiSelect.types'
import { MultiSelectBasic } from '../MultiSelectBasic/MultiSelectBasic'
import { MultiSelectItem } from '../MultiSelectItem/MultiSelectItem'
import * as styled from './MultiSelectAdaptive.styled'
import { LoaderSkeleton } from './MultiSelectAdaptive/LoaderSkeleton'
import { MultiSelectAdaptiveDeprecated } from './MultiSelectAdaptive/MultiSelectAdaptiveDeprecated'
import { SelectAllOptionValue } from './MultiSelectAdaptive/SelectAllValueOption'
import type { MultiSelectAdaptiveProps as Props } from './MultiSelectAdaptiveProps'

export type { Props as MultiSelectAdaptiveProps }
export { SelectAllOptionValue as MultiSelectAdaptiveSelectAllOptionValue } from './MultiSelectAdaptive/SelectAllValueOption'

const MultiSelectAdaptive = <TOption extends Option>({
  multi: multiProp = true,
  value: valueProp,
  onChangeValue: onChangeValueProp,
  onBlur: onBlurProp,
  includeOptions: includeOptionsProp,
  excludeOptionsByValue: excludeOptionsByValueProp,
  options: optionsProp,
  label: labelProp,
  mobileTitle: mobileTitleProp,
  placeholder: placeholderProp,
  placeholderIcon: placeholderIconProp,
  valueLabel: valueLabelProp,
  selectAll: selectAllProp = true,
  search: searchProp = true,
  emptyMessage: emptyMessageProp,
  emptyActions: emptyActionsProp,
  trigger: triggerProp,
  popperWidth: popperWidthProp,
  hasError: hasErrorProp,
  isDisabled: isDisabledProp,
  selectSize: selectSizeProp = SelectSize.Medium,
}: Props<TOption>) => {
  // dependencies
  const tShared = useTranslations(translationsShared)
  const isMobileResolution = useIsMobileResolution()

  // state
  const [searchValue, setSearchValue] = useState('')
  const [fetcherState, setFetcherState] = useState<{
    /**
     * `true` if options are being fetched via the `options.fetcher`.
     */
    isFetching: boolean | 'settling'
    options?: TOption[]
    initialOptions?: TOption[]
    nextToken?: unknown
    error?: boolean | string
    valueIdsOptionsError?: boolean | string
  }>({ isFetching: false })
  const [isFetchingValueOptions, setIsFetchingValueOptions] = useState(false)

  // computed
  const optionsMap = useMemo(() => {
    const optionsMap = new Map<string, TOption>()

    if (optionsProp) {
      if (Array.isArray(optionsProp)) {
        optionsProp.forEach((option) => optionsMap.set(option.value, option))
      } else {
        fetcherState.initialOptions?.forEach((option) =>
          optionsMap.set(option.value, option)
        )
        fetcherState.options?.forEach((option) =>
          optionsMap.set(option.value, option)
        )
      }
    }

    includeOptionsProp?.forEach((option) =>
      optionsMap.set(option.value, option)
    )
    excludeOptionsByValueProp?.forEach((value) => optionsMap.delete(value))

    return optionsMap
  }, [
    optionsProp,
    includeOptionsProp,
    excludeOptionsByValueProp,
    fetcherState.initialOptions,
    fetcherState.options,
  ])

  const optionsAsync =
    optionsProp && !Array.isArray(optionsProp) ? optionsProp : undefined

  const valueOptionsAsync =
    optionsAsync && !Array.isArray(optionsAsync.initialOptions)
      ? optionsAsync.initialOptions
      : undefined

  const value = useMemo(() => {
    if (
      Array.isArray(valueProp) &&
      (valueOptionsAsync ? fetcherState.initialOptions : true)
    ) {
      return valueProp.filter((id) => optionsMap.has(id))
    }
    return valueProp ?? []
  }, [valueProp, optionsMap, valueOptionsAsync, fetcherState.initialOptions])

  const SelectAllOption = useMemo<TOption>(() => {
    const props_selectAll_label =
      typeof selectAllProp === 'object' ? selectAllProp.label : undefined
    const label = props_selectAll_label ?? tShared('selectAll')
    return {
      value: SelectAllOptionValue,
      label,
    } as TOption
  }, [tShared, selectAllProp])

  const clearSearch = useCallback(() => {
    setSearchValue('')
  }, [setSearchValue])

  const {
    options: MultiSelectBasic_items,
    isOptionsEmpty,
    isOptionsFilteredEmpty,
  } = useMemo<{
    options: MultiSelectBasicProps<TOption>['items']
    isOptionsEmpty: boolean
    isOptionsFilteredEmpty: boolean
  }>(() => {
    const isOptionsEmpty = !optionsMap.size

    const actions: Option[] = []
    if (multiProp && selectAllProp && !searchValue && !isOptionsEmpty) {
      actions.push(SelectAllOption)
    }

    let optionsFormatted = Array.from(optionsMap.values())

    if (searchProp) {
      // Default to client-side filtering if options are not dynamically fetched.
      const props_search_filter_default = Array.isArray(optionsProp)
      const props_search_filter =
        typeof searchProp === 'object'
          ? searchProp.filter
          : props_search_filter_default

      if (props_search_filter !== false) {
        const filterSearchValue = searchValue.trim().toLowerCase()
        optionsFormatted = optionsFormatted.filter((option: TOption) =>
          option.label.toLowerCase().includes(filterSearchValue)
        )
      }
    }

    optionsFormatted = optionsFormatted.sort(
      (a, b) =>
        (value?.includes(a.value) ? 0 : 1) - (value?.includes(b.value) ? 0 : 1)
    )

    const isOptionsFilteredEmpty = !optionsFormatted.length

    return {
      options: (actions as TOption[]).concat(optionsFormatted),
      isOptionsEmpty,
      isOptionsFilteredEmpty,
    }
  }, [
    optionsMap,
    multiProp,
    selectAllProp,
    searchValue,
    searchProp,
    SelectAllOption,
    optionsProp,
    value,
  ])
  const callbackState = useMemo(() => {
    const valueIds = Array.isArray(value) ? value : []
    const valueIdsMissing = valueIds.filter((id) => !optionsMap.has(id))
    return {
      clearSearch,
      value,
      valueIds,
      valueIdsMissing,
      search: searchValue,
      options: Array.from(optionsMap.values()),
      optionsMap,
      isOptionsFilteredEmpty,
    }
  }, [value, clearSearch, searchValue, optionsMap, isOptionsFilteredEmpty])

  const isPrefetching = !!(
    valueOptionsAsync && callbackState.valueIdsMissing.length
  )

  // effects
  useEffect(() => {
    // Skip if no valueIds options fetcher. No resolver available.
    if (!valueOptionsAsync) {
      return
    }

    // Skip if already fetching valueIds options. Wait until complete.
    if (isFetchingValueOptions) {
      return
    }

    // Skip if no valueIds available. Nothing to do.
    if (value === SelectAllOptionValue || !value.length) {
      return
    }

    // Skip if all valueIds have a corresponding option. Nothing to do.
    if (!callbackState.valueIdsMissing.length) {
      return
    }

    // Skip if previous valueIdsOptions fetch request failed. Stop until retry.
    if (fetcherState.valueIdsOptionsError) {
      return
    }

    ;(async () => {
      setIsFetchingValueOptions(true)
      const result = await valueOptionsAsync.fetcher(callbackState)
      setFetcherState((state) => ({
        ...state,
        initialOptions: result?.options ?? [],
        valueIdsOptionsError: result?.error,
      }))
      setIsFetchingValueOptions(false)
    })()
  }, [
    value,
    valueOptionsAsync,
    callbackState,
    fetcherState.initialOptions,
    isFetchingValueOptions,
    fetcherState.valueIdsOptionsError,
  ])

  // SelectTriggerAdaptive
  const SelectTriggerAdaptive_selectedItem = useMemo<
    SelectTriggerAdaptiveProps['selectedItem']
  >(() => {
    const label = ((): string | undefined => {
      if (valueOptionsAsync && isFetchingValueOptions) {
        return valueOptionsAsync.loadingPlaceholder ?? `${tShared('loading')}…`
      }

      const valueLabelPropResult =
        typeof valueLabelProp === 'function'
          ? valueLabelProp(callbackState)
          : valueLabelProp
      if (typeof valueLabelPropResult === 'string') {
        return valueLabelPropResult
      }

      if (value === SelectAllOptionValue) {
        return valueLabelPropResult?.all ?? tShared('allSelected')
      }

      if (!value.length) {
        return valueLabelPropResult?.none
      }

      if (value.length === 1) {
        const option = optionsMap.get(value[0])
        if (option) {
          return option.label
        }
      }

      const count = value.length
      return valueLabelPropResult?.many
        ? valueLabelPropResult.many(count)
        : tShared('nSelected', { count })
    })()

    return label ? { label, value: '' } : null
  }, [
    isFetchingValueOptions,
    valueLabelProp,
    callbackState,
    value,
    tShared,
    valueOptionsAsync,
    optionsMap,
  ])

  // MultiSelectBasic
  const MultiSelectBasic_selectedItems: MultiSelectBasicProps<TOption>['selectedItems'] =
    useMemo(() => {
      if (value === SelectAllOptionValue) {
        return [SelectAllOption]
      }
      return value?.map((id) => optionsMap.get(id)!)
    }, [value, optionsMap, SelectAllOption])

  const MultiSelectBasic_onChange = useCallback<
    MultiSelectBasicProps<TOption>['onChange']
  >(
    (selectedOptions) => {
      if (onChangeValueProp) {
        if (!multiProp) {
          const selectedOption = selectedOptions.length
            ? selectedOptions[selectedOptions.length - 1]
            : undefined
          onChangeValueProp(selectedOption ? [selectedOption.value] : [])
        } else if (selectedOptions.includes(SelectAllOption)) {
          onChangeValueProp(SelectAllOptionValue)
        } else {
          onChangeValueProp(selectedOptions.map(({ value }) => value))
        }
      }
    },
    [onChangeValueProp, multiProp, SelectAllOption]
  )

  const MultiSelectBasic_renderItem = useCallback<
    MultiSelectBasicProps<TOption>['renderItem']
  >(
    (renderProps) => (
      <Box
        padding={isMobileResolution ? '8px 0 0 8px' : undefined}
        key={renderProps.key}
      >
        <MultiSelectItem<TOption>
          {...renderProps}
          // Virtually mark Select-All "indeterminate" if more than 1 selected.
          {...(renderProps.item.value === SelectAllOptionValue &&
          Array.isArray(value) &&
          value.length > 0
            ? { isChecked: true, isIndeterminate: true }
            : undefined)}
          // Virtually "check" all non-"Select All" options if Select All active.
          {...(renderProps.item.value !== SelectAllOptionValue &&
          value === SelectAllOptionValue
            ? { isChecked: true, isDisabled: true }
            : undefined)}
          sublabel={renderProps.item.sublabel}
          onClick={(event) => {
            renderProps.onClick(event)
            if (!multiProp) {
              renderProps.close?.()
            }
          }}
          checkStyle={multiProp ? 'multi' : 'single'}
        />
      </Box>
    ),
    [isMobileResolution, multiProp, value]
  )

  const MultiSelectBasic_renderTrigger = useCallback<
    MultiSelectBasicProps<TOption>['renderTrigger']
  >(
    (renderProps) =>
      triggerProp ? (
        triggerProp({
          onClick: renderProps.onClick,
          label: labelProp,
          isOpen: renderProps.isOpen,
        })
      ) : (
        <SelectTriggerAdaptive
          {...renderProps}
          label={labelProp}
          placeholder={placeholderProp}
          placeholderIcon={placeholderIconProp}
          selectedItem={SelectTriggerAdaptive_selectedItem}
          isLoading={isPrefetching}
          aria-label={`open ${labelProp} dropdown`}
        />
      ),
    [
      triggerProp,
      labelProp,
      placeholderProp,
      placeholderIconProp,
      SelectTriggerAdaptive_selectedItem,
      isPrefetching,
    ]
  )

  const MultiSelectBasic_renderTopSection_search = useMemo(():
    | {
        placeholder: string
        onChange: NonNullable<InputWithoutLabelProps['onChange']>
      }
    | undefined => {
    if (
      (typeof searchProp === 'object' &&
        searchProp?.always &&
        !fetcherState.error) ||
      (!isOptionsEmpty && searchProp)
    ) {
      return {
        placeholder:
          (typeof searchProp === 'object' ? searchProp.label : undefined) ??
          tShared('search'),
        onChange: (event) => {
          setSearchValue(event.target.value)
        },
      }
    }

    return undefined
  }, [fetcherState.error, isOptionsEmpty, searchProp, tShared])

  const fetcherErrorMessage = useMemo(() => {
    return (
      (typeof fetcherState.error === 'string'
        ? fetcherState.error
        : undefined) ?? tShared('failedToLoadOptions')
    )
  }, [fetcherState.error, tShared])

  type EmptyConfig = {
    message?: string
    actions?: Props.Action[]
  }

  const empty = useMemo<EmptyConfig>(() => {
    const message =
      (typeof emptyMessageProp === 'function'
        ? emptyMessageProp(callbackState)
        : emptyMessageProp) ?? tShared('noAvailableOptions')
    const actions =
      typeof emptyActionsProp === 'function'
        ? emptyActionsProp(callbackState)
        : emptyActionsProp
    return { message, actions }
  }, [callbackState, emptyActionsProp, emptyMessageProp, tShared])

  const searchEmpty = useMemo<EmptyConfig>(() => {
    const searchEmptyMessageProp =
      typeof searchProp === 'object' ? searchProp.emptyMessage : undefined
    const message =
      (typeof searchEmptyMessageProp === 'function'
        ? searchEmptyMessageProp(callbackState)
        : undefined) ??
      tShared('weCouldntFindAnythingThatMatchesInput', {
        input: searchValue.trim(),
      })
    const searchActionsProp =
      typeof searchProp === 'object' ? searchProp.actions : undefined
    const actions =
      typeof searchActionsProp === 'function'
        ? searchActionsProp(callbackState)
        : searchActionsProp

    if (isOptionsFilteredEmpty) {
      return { message, actions }
    }

    return { actions }
  }, [callbackState, isOptionsFilteredEmpty, searchProp, searchValue, tShared])

  const fetcherRetry = useCallback(() => {
    // Retrying clears the error state to retrigger onScrollAtBottom.
    setFetcherState((state) => ({ ...state, error: undefined }))
  }, [])

  const MultiSelectBasic_renderTopSection_prompt = useMemo(():
    | { message?: string; actions?: Props.Action[] }
    | undefined => {
    if (optionsAsync && fetcherState.isFetching) {
      return undefined
    }

    if (optionsAsync && fetcherState.error) {
      return {
        message: fetcherErrorMessage,
        actions: [
          {
            label: tShared('retry'),
            onClick: fetcherRetry,
          },
        ],
      }
    }

    if (searchValue.trim()) {
      return searchEmpty
    }

    if (isOptionsEmpty) {
      return empty
    }

    return undefined
  }, [
    optionsAsync,
    fetcherState.isFetching,
    fetcherState.error,
    searchValue,
    isOptionsEmpty,
    fetcherErrorMessage,
    tShared,
    fetcherRetry,
    empty,
    searchEmpty,
  ])

  const MultiSelectBasic_renderTopSection = useCallback<
    NonNullable<MultiSelectBasicProps<TOption>['renderTopSection']>
  >(() => {
    if (
      optionsAsync &&
      !fetcherState.options &&
      !fetcherState.error &&
      !optionsMap.size
    ) {
      return <LoaderSkeleton />
    }

    const search = MultiSelectBasic_renderTopSection_search
    const prompt = MultiSelectBasic_renderTopSection_prompt
    const shouldDisplayPrompt = !(isEmpty(prompt?.actions) && !prompt?.message)

    return (
      <Box px={isMobileResolution ? '8px' : undefined}>
        <div style={{ marginBottom: 8 }}>
          {!!search && (
            <InputWithoutLabel
              autoFocus
              renderRightControls={() => (
                <styled.buttonInputClear
                  type="button"
                  aria-label={tShared('clearSearch')}
                  onClick={clearSearch}
                >
                  <SvgIcon color={COLOR.GREY_250} hoverColor={COLOR.BLUE_1000}>
                    <CrossmarkRoundEdgeIcon width="16" height="16" />
                  </SvgIcon>
                </styled.buttonInputClear>
              )}
              name="search"
              value={searchValue}
              onChange={search.onChange}
              icon={<SearchIcon />}
              size={INPUT_SIZE.SMALL}
              placeholder={search.placeholder}
              aria-label={`search ${labelProp ?? 'select'} options`}
            />
          )}
        </div>
        {shouldDisplayPrompt && (
          <styled.divPrompt>
            {prompt?.message && (
              <styled.divPromptMessage>
                <div>
                  <ExclamationIcon
                    style={{ color: COLOR.YELLOW_1000, marginTop: 2 }}
                  />
                </div>
                {prompt.message}
              </styled.divPromptMessage>
            )}
            {prompt?.actions ? (
              <styled.divPromptActions>
                {prompt.actions.map((action) =>
                  'href' in action ? (
                    <Anchor
                      key={action.label}
                      target={action.target}
                      href={action.href}
                      $isDisabled={action.disabled || action.pending}
                      style={{
                        display: 'inline-flex',
                        ...(action.disabled || action.pending
                          ? {
                              textDecoration: 'none',
                              pointerEvents: 'none',
                            }
                          : {}),
                      }}
                    >
                      {action.label}
                      {!!action.pending && (
                        <LoaderSimple size="22" style={{ marginLeft: 8 }} />
                      )}
                    </Anchor>
                  ) : (
                    <ButtonLink
                      key={action.label}
                      onClick={action.onClick}
                      disabled={action.disabled || action.pending}
                      style={{ display: 'inline-flex' }}
                    >
                      {action.label}
                      {!!action.pending && (
                        <LoaderSimple
                          size="22"
                          style={{ marginLeft: 8, marginTop: 1 }}
                        />
                      )}
                    </ButtonLink>
                  )
                )}
              </styled.divPromptActions>
            ) : undefined}
          </styled.divPrompt>
        )}
      </Box>
    )
  }, [
    MultiSelectBasic_renderTopSection_prompt,
    MultiSelectBasic_renderTopSection_search,
    clearSearch,
    fetcherState.error,
    fetcherState.options,
    isMobileResolution,
    labelProp,
    optionsMap.size,
    optionsAsync,
    searchValue,
    tShared,
  ])

  const clearValue = useCallback(() => {
    onChangeValueProp?.([])
  }, [onChangeValueProp])

  const MultiSelectBasic_renderBottomSection = useCallback<
    NonNullable<MultiSelectBasicProps<TOption>['renderBottomSection']>
  >(
    (renderProps) => {
      const isLoading = !!(
        optionsAsync &&
        fetcherState.isFetching &&
        optionsMap.size
      )
      return (
        <>
          {isLoading && <LoaderSpinner />}
          {isMobileResolution
            ? !isOptionsEmpty && (
                <Flex marginTop="auto" padding="8px">
                  <MobileFiltersButtonWrapper>
                    <ButtonGhost
                      dataTestId="filters-clear"
                      onClick={clearValue}
                    >
                      {tShared('clear')}
                    </ButtonGhost>
                    <ButtonFill
                      dataTestId="filters-apply"
                      onClick={renderProps.onClose}
                    >
                      {tShared('apply')}
                    </ButtonFill>
                  </MobileFiltersButtonWrapper>
                </Flex>
              )
            : !isOptionsEmpty && (
                <>
                  <Divider margin="0 0 8px 0" />
                  <styled.divClearRow>
                    <ButtonGhost
                      onClick={clearValue}
                      aria-label={tShared('clear')}
                      size={BUTTON_SIZE.XSMALL}
                      disabled={Array.isArray(value) ? !value.length : !value}
                    >
                      {tShared('clear')}
                    </ButtonGhost>
                  </styled.divClearRow>
                </>
              )}
        </>
      )
    },
    [
      optionsAsync,
      fetcherState.isFetching,
      optionsMap.size,
      isMobileResolution,
      isOptionsEmpty,
      clearValue,
      tShared,
      value,
    ]
  )

  const settleTimeout = useRef<ReturnType<typeof setTimeout>>()
  const MultiSelectBasic_onScrollAtBottom = useCallback(async () => {
    if (!optionsAsync) {
      return
    }

    if (fetcherState.isFetching === true) {
      return
    }

    // Stop attempting to fetch if there is an error.
    if (fetcherState.error) {
      return
    }

    // If fetcher has already fetched at least once and has no more pages fetch.
    if (fetcherState.options && !fetcherState.nextToken) {
      return
    }

    clearTimeout(settleTimeout.current)
    setFetcherState((state) => ({ ...state, isFetching: true }))
    const fetched = await optionsAsync.fetcher({
      ...callbackState,
      nextToken: fetcherState.nextToken,
    })

    if (fetched.error) {
      setFetcherState((state) => ({
        ...state,
        isFetching: false,
        error: fetched.error,
        options: fetched.options ?? state?.options,
        nextToken: fetched.nextToken ?? state?.nextToken,
      }))
      return
    }

    setFetcherState((state) => ({
      ...state,
      isFetching: 'settling',
      options: fetched.options ?? state?.options,
      nextToken: fetched.nextToken,
    }))
    settleTimeout.current = setTimeout(() => {
      setFetcherState((state) => ({ ...state, isFetching: false }))
    }, 100)
  }, [
    optionsAsync,
    fetcherState.isFetching,
    fetcherState.error,
    fetcherState.options,
    fetcherState.nextToken,
    callbackState,
  ])

  const MultiSelectBasic_onClose = useCallback(() => {
    setSearchValue('')
    onBlurProp?.()
  }, [onBlurProp])

  const MultiSelectBasic_mobileLabel =
    mobileTitleProp ?? labelProp ?? tShared('selectOptions')

  return (
    <MultiSelectBasic
      items={MultiSelectBasic_items}
      selectedItems={MultiSelectBasic_selectedItems}
      onChange={MultiSelectBasic_onChange}
      renderItem={MultiSelectBasic_renderItem}
      renderTrigger={MultiSelectBasic_renderTrigger}
      renderTopSection={MultiSelectBasic_renderTopSection}
      renderBottomSection={MultiSelectBasic_renderBottomSection}
      selectSize={selectSizeProp}
      selectStyle={SelectStyle.Standard}
      onScrollAtBottom={MultiSelectBasic_onScrollAtBottom}
      onClose={MultiSelectBasic_onClose}
      mobileLabel={MultiSelectBasic_mobileLabel}
      popperWidth={popperWidthProp}
      isMobileResolution={isMobileResolution}
      mobileOptionsListHeight="fit-content"
      hasError={hasErrorProp}
      isDisabled={isDisabledProp}
    />
  )
}

const MultiSelectAdaptiveWrapper = <TOption extends Option>(
  props: Props<TOption> | Props.Deprecated<TOption>
) => {
  if ('items' in props) {
    return <MultiSelectAdaptiveDeprecated {...props} />
  }
  return <MultiSelectAdaptive {...props} />
}

export { MultiSelectAdaptiveWrapper as MultiSelectAdaptive }
