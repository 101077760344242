import { useMemo } from 'react'
import { COLOR } from '@npco/zeller-design-system'
import { XAxis } from 'recharts'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { BarChart } from 'components/Charts/BarChart/BarChart'
import { ChartData } from 'components/Charts/Charts.types'
import {
  formatDayTicks,
  formatMonthTicks,
} from 'components/Charts/Shared/AxisTickFormatter.utils'
import {
  getXAxisDayTicks,
  getXAxisMonthTicks,
} from 'components/Charts/Shared/XAxisTick.utils'

import { yAxisTickFormatter } from '../ReportsChart.utils'
import { TooltipContentWithDateRange } from './TooltipContent/TooltipContent'

interface Props {
  data: ChartData
  hasNoInitialResults: boolean
  isSelectionMoreThenTwoMonths: boolean
}

export const TotalChart = ({
  data,
  hasNoInitialResults,
  isSelectionMoreThenTwoMonths,
}: Props) => {
  const isMobile = useIsMobileResolution()

  const barProps = useMemo(() => {
    const fillObject = hasNoInitialResults ? {} : { fill: COLOR.BLUE_1000 }

    return {
      ...fillObject,
      barSize: isMobile ? 3 : 5,
      radius: 10,
    }
  }, [hasNoInitialResults, isMobile])

  return (
    <BarChart
      barProps={barProps}
      data={data}
      disabled={hasNoInitialResults}
      height={230}
      TooltipContent={TooltipContentWithDateRange}
      width="100%"
      xAxisProps={{
        dataKey: 'name',
        domain: ['dataMin', 'dataMax'],
        interval: 'preserveStartEnd',
        scale: 'utc',
        hide: isSelectionMoreThenTwoMonths,
        tickFormatter: formatDayTicks,
        tickMargin: 10,
        ticks: getXAxisDayTicks(data),
        type: 'number',
      }}
      xAxisTickFormatter={formatDayTicks}
      yAxisTickFormatter={yAxisTickFormatter}
    >
      <XAxis
        axisLine={false}
        dataKey="name"
        domain={['dataMin', 'dataMax']}
        hide={hasNoInitialResults}
        interval="preserveStartEnd"
        tick={{ fill: COLOR.GREY_400, fontSize: 12 }}
        tickFormatter={formatMonthTicks}
        tickLine={false}
        tickMargin={10}
        ticks={getXAxisMonthTicks(data, 1)}
        type="number"
        xAxisId={1}
      />
    </BarChart>
  )
}
