import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetInvoiceItemsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  input: Types.GetCatalogItemsInput
}>

export type GetInvoiceItemsQueryResponse = {
  __typename?: 'Query'
  getCatalogItems: {
    __typename?: 'CatalogItemConnection'
    nextToken: string | null
    items: Array<{
      __typename?: 'CatalogItem'
      id: string
      name: string
      parentName: string | null
      description: string | null
      type: Types.CatalogItemType
      price: { __typename?: 'CatalogMoney'; currency: string; value: string }
      taxes: Array<{
        __typename?: 'CatalogTax'
        enabled: boolean
        name: string
        percent: number | null
      }> | null
    }> | null
  }
}

export const GetInvoiceItems = gql`
  query GetInvoiceItems($entityUuid: ID!, $input: GetCatalogItemsInput!) {
    getCatalogItems(entityUuid: $entityUuid, input: $input) {
      items {
        id
        name
        parentName
        description
        price {
          currency
          value
        }
        taxes {
          enabled
          name
          percent
        }
        type
      }
      nextToken
    }
  }
` as unknown as TypedDocumentNode<
  GetInvoiceItemsQueryResponse,
  GetInvoiceItemsQueryVariables
>

/**
 * __useGetInvoiceItemsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceItemsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInvoiceItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceItemsQueryResponse,
    GetInvoiceItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInvoiceItemsQueryResponse,
    GetInvoiceItemsQueryVariables
  >(GetInvoiceItems, options)
}
export function useGetInvoiceItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceItemsQueryResponse,
    GetInvoiceItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInvoiceItemsQueryResponse,
    GetInvoiceItemsQueryVariables
  >(GetInvoiceItems, options)
}
export type GetInvoiceItemsQueryHookResult = ReturnType<
  typeof useGetInvoiceItemsQuery
>
export type GetInvoiceItemsLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceItemsLazyQuery
>
export type GetInvoiceItemsQueryResult = Apollo.QueryResult<
  GetInvoiceItemsQueryResponse,
  GetInvoiceItemsQueryVariables
>
