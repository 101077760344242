import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'
import { StyledDropdownWrapper } from 'components/Shared'

export const SearchWrapper = styled.div`
  padding: 12px 12px 10px 12px;
`

export const List = styled.ul`
  max-height: 342px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  box-sizing: border-box;

  p {
    text-align: center;
  }
`

export const Dropdown = styled(StyledDropdownWrapper)`
  width: 100%;
`

export const OpenOptions = styled(StyledItemsWrapper)`
  transform: translateY(56px);

  @media (min-width: ${BREAKPOINT.XS}px) {
    transform: none;
  }
`
