import { BodySmall, Divider, Flex, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledTitle as Title } from 'layouts/SettingsFormLayout/SettingsFormLayout.styled'

export const StyledGreyText = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0 0 1rem 0;
`

export const StyledLabelText = styled(H5)`
  color: ${({ theme }) => theme.colors.BLACK};
  margin: 0 0 1rem;
`

export const StyledDivider = styled(Divider)`
  margin: 0.5rem 0 2rem 0;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-top: 0;
  }
`
export const StyledEndingDivider = styled(Divider)`
  margin: 1rem 0 2.5rem 0;
`

export const StyledTitle = styled(Title)`
  margin-bottom: 1.5rem;
`

export const StyledToggleContainer = styled(Flex)`
  justify-content: space-between;
`
