import { MaximumTransactionLimit } from './MaximumTransactionLimit/MaximumTransactionLimit'
import * as styled from './SpendControlFields.styled'
import { SpendControlFrequency } from './SpendControlFrequency/SpendControlFrequency'
import { SpendControlLimit } from './SpendControlLimit/SpendControlLimit'

interface SpendControlFieldsProps {
  isHeaderVisible?: boolean
  accountId?: string
  fullWidth?: boolean
  isDescriptionOnTop?: boolean
  fieldsGapValue?: number
  timezone?: string
  isLoadingTimezone: boolean
  isSpendControlFrequencyVisible?: boolean
}
export const SpendControlFields = ({
  isHeaderVisible,
  accountId,
  fullWidth = false,
  isDescriptionOnTop = true,
  fieldsGapValue = 40,
  timezone,
  isLoadingTimezone,
  isSpendControlFrequencyVisible = true,
}: SpendControlFieldsProps) => {
  return (
    <styled.FieldWrapper gap={`${fieldsGapValue}px`}>
      <SpendControlLimit
        isHeaderVisible={isHeaderVisible}
        accountId={accountId}
        isFullWidth={fullWidth}
      />
      {isSpendControlFrequencyVisible && (
        <SpendControlFrequency
          isHeaderVisible={isHeaderVisible}
          isFullWidth={fullWidth}
          timezone={timezone}
          isLoadingTimezone={isLoadingTimezone}
        />
      )}
      <MaximumTransactionLimit
        isHeaderVisible={isHeaderVisible}
        isDescriptionOnTop={isDescriptionOnTop}
        isFullWidth={fullWidth}
      />
    </styled.FieldWrapper>
  )
}
