import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ButtonFill } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translationsShared } from 'translations'

export const ActivateCardButton = () => {
  const tShared = useTranslations(translationsShared)
  const { goToAddCardStage } = useGoToAddCardStage()

  const handleActivateCard = useCallback(() => {
    goToAddCardStage({ stage: AddCardStage.LinkCardIdStage })
  }, [goToAddCardStage])

  return (
    <ButtonFill onClick={handleActivateCard}>
      {tShared('activeCard')}
    </ButtonFill>
  )
}
