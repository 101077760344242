import { FetchResult } from '@apollo/client'

import { GraphQLError } from 'graphql'

import { MockOptions, Result } from '../createApolloTestingLink.types'

type Mock<V, R> = {
  mockVariables: (variables: V) => Mock<V, R>
  mockData: (data: R) => Mock<V, R>
  mockGraphQLErrors: <T extends GraphQLError>(errors?: [T]) => Mock<V, R>
  mockNetworkError: (networkError?: Error) => Mock<V, R>
} & MockOptions<V, R>

export interface IMockBuilder<V, R> {
  mockVariables: (variables: V) => MockBuilder<V, R>
  mockData: (data: R) => Mock<V, R>
  mockGraphQLErrors: <T extends GraphQLError>(errors?: [T]) => Mock<V, R>
  mockNetworkError: (networkError?: Error) => Mock<V, R>
}

export class MockBuilder<V, R> implements IMockBuilder<V, R> {
  public readonly operationName: string

  public readonly variables?: V

  public readonly result?: FetchResult<R>

  constructor(operationName: string, variables?: V, result?: Result<R>) {
    this.operationName = operationName
    this.variables = variables
    this.result = result
  }

  public mockVariables = (variables: V) => {
    return new MockBuilder<V, R>(this.operationName, variables, this.result)
  }

  public mockData = (data: R): Mock<V, R> => {
    return new MockBuilder<V, R>(this.operationName, this.variables, {
      ...this.result,
      data,
    } as unknown as Result<R>) as Mock<V, R>
  }

  public mockGraphQLErrors = <T extends GraphQLError>(
    errors: [T] = [new GraphQLError('test') as T]
  ): Mock<V, R> => {
    return new MockBuilder<V, R>(this.operationName, this.variables, {
      ...this.result,
      errors,
    } as unknown as Result<R>) as Mock<V, R>
  }

  public mockNetworkError = (
    networkError: Error = new Error('test')
  ): Mock<V, R> => {
    return new MockBuilder<V, R>(this.operationName, this.variables, {
      ...this.result,
      networkError,
    } as unknown as Result<R>) as Mock<V, R>
  }
}
