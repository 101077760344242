import { Dayjs } from 'dayjs'

export enum PerformancePeriod {
  TODAY = 'TODAY',
  THIS_MONTH = 'THIS_MONTH',
}

export interface TaxAmount {
  name: string
  amount: number
}

export interface TransactionTotal {
  period: any
  refundAmount: number
  surchargeAmount: number
  totalAmount: number
  tipAmount: number
  purchaseAmount: number
  taxAmounts: TaxAmount[] | null
  feeAmount: number
}
export type DateRange = [Dayjs, Dayjs]
