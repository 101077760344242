import { DebitCardAccount, ThirdPartyBankAccount } from 'types/accounts'

export const getAccountFromList = (
  accountId: string,
  zellerAccounts: DebitCardAccount[] | null,
  thirdPartyAccounts: ThirdPartyBankAccount[] | null
) => {
  return (
    zellerAccounts?.find((account) => account.id === accountId) ??
    thirdPartyAccounts?.find((account) => account.id === accountId)
  )
}
