import {
  CompleteCardDeliveryState,
  CompleteCardReplacementDeliveryState,
  CompleteCardStage,
} from 'hooks/useGoToCompleteCardStage/useGoToCompleteCardStage.types'
import { translate } from 'utils/translations'

export const getCompleteCardDeliveryModalHeading = (
  state: CompleteCardDeliveryState | CompleteCardReplacementDeliveryState
): string => {
  switch (state.stage) {
    case CompleteCardStage.CompleteCardDeliveryStage:
      return translate('page.completeCardModal.completeCardDelivery.heading')

    case CompleteCardStage.CompleteCardReplacementDeliveryStage:
      return translate(
        'page.completeCardModal.completeCardReplacementDelivery.heading'
      )

    default:
      return ''
  }
}

export const getCompleteCardDeliveryModalDescription = (
  state: CompleteCardDeliveryState | CompleteCardReplacementDeliveryState
): string => {
  switch (state.stage) {
    case CompleteCardStage.CompleteCardDeliveryStage:
      return translate(
        'page.completeCardModal.completeCardDelivery.description'
      )

    case CompleteCardStage.CompleteCardReplacementDeliveryStage:
      return translate(
        'page.completeCardModal.completeCardReplacementDelivery.description'
      )

    default:
      return ''
  }
}

export const getCompleteCardDeliveryModalButtons = ({
  state,
  closeAction,
  addCardAction,
}: {
  state: CompleteCardDeliveryState | CompleteCardReplacementDeliveryState | null
  closeAction: () => void
  addCardAction: () => void
}) => {
  if (state?.stage === CompleteCardStage.CompleteCardDeliveryStage) {
    return {
      onClickPrimary: closeAction,
      primaryButtonLabel: translate('page.completeCardModal.doneButtonLabel'),
      secondaryButtonLabel: translate(
        'page.completeCardModal.addAnotherCardButtonLabel'
      ),
      onClickSecondary: addCardAction,
    }
  }
  return {
    secondaryButtonLabel: translate('shared.dismiss'),
    onClickSecondary: closeAction,
  }
}
