import { useMemo } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { isNotNull } from 'utils/common'

import { useGetSimsQuery } from '../graphql/getSims.generated'
import { SimTableFragment } from '../SimTable/SimTable.generated'

export const SIM_LIMIT = 50

export const useGetSims = () => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data, error, fetchMore } = useGetSimsQuery({
    variables: { entityUuid, limit: SIM_LIMIT, nextToken: null },
    errorPolicy: 'all',
  })

  const sims = useMemo(
    (): SimTableFragment[] => data?.getSims?.sims.filter(isNotNull) ?? [],
    [data?.getSims?.sims]
  )

  const loadMore = async () => {
    await fetchMore?.({
      variables: {
        limit: SIM_LIMIT,
        nextToken: data?.getSims?.nextToken,
      },
    })
  }

  const nextToken = data?.getSims?.nextToken

  return {
    isLoading: loading,
    sims,
    error,
    loadMore,
    nextToken,
  }
}
