import { AnchorBasic, BodyXSmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  position: relative;
`

export const Anchor = styled(AnchorBasic)`
  ${BodyXSmallTypography}
  color: ${({ theme }) => theme.colors.GREY_580};
  text-decoration: underline;

  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.GREY_580};
  }
`
