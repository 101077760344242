import { ReactNode, useCallback } from 'react'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { getColorFromResponse } from '@npco/utils-colors'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink, Flex } from '@npco/zeller-design-system'

import { useGoToEditAccountStage } from 'hooks/useGoToEditAccountStage/useGoToEditAccountStage'
import { EditAccountStage } from 'hooks/useGoToEditAccountStage/useGoToEditAccountStage.types'
import { capitalise } from 'utils/common'
import { DebitCardAccount } from 'types/accounts'
import { getDefaultNewAccountColor } from 'components/AccountFields/PersonaliseAccountFields/AccountAvatarField/AccountAvatarField.utils'
import { page, translationsShared } from 'translations'

import { AccountDetailBody } from './AccountDetailBody/AccountDetailBody'
import { StyledAccountDetailsDrawer } from './AccountDetailDrawer.styled'

interface AccountDetailDrawerProps {
  account: DebitCardAccount | undefined
  isOpen: boolean
  onClose: () => void
  renderBottomAction: (account: DebitCardAccount) => ReactNode
  getIcon: (bankAccount: DebitCardAccount) => ReactNode
}

interface ZellerAccountStatusProps {
  status: DebitCardAccountStatus | undefined
}

const ZellerAccountStatus = ({ status }: ZellerAccountStatusProps) => {
  return (
    <Flex justifyContent="flex-end" alignItems="center">
      <div>{status ? capitalise(status) : ''}</div>
    </Flex>
  )
}

export const ZellerAccountDetailDrawer = ({
  onClose,
  isOpen,
  account,
  renderBottomAction,
  getIcon,
}: AccountDetailDrawerProps) => {
  const tShared = useTranslations(translationsShared)
  const { goToEditAccountStage } = useGoToEditAccountStage()

  const accountNameLabel = page.settings.depositAccountDetail.accountName
  const accountStatusLabel = page.settings.depositAccountDetail.accountStatus
  const accountStatusValue = <ZellerAccountStatus status={account?.status} />

  const accountProperties = [
    { label: accountNameLabel, value: account?.name },
    { label: accountStatusLabel, value: accountStatusValue },
  ]

  const handleOpenModal = useCallback(
    (account: DebitCardAccount) => {
      const accountAvatarColour =
        getColorFromResponse(account.icon.colour) ??
        account.icon.colour ??
        getDefaultNewAccountColor()

      goToEditAccountStage({
        stage: EditAccountStage.EditAccount,
        accountId: account.id,
        name: account.name,
        accountColour: accountAvatarColour,
        accountType: DebitCardAccountType.ZLR_DEBIT,
      })
    },
    [goToEditAccountStage]
  )

  return (
    <StyledAccountDetailsDrawer
      icon={account ? getIcon(account) : <></>}
      isOpen={isOpen}
      onClose={onClose}
      ariaHideApp={false}
      drawerTitle={account?.name ?? ''}
      renderRightHeader={() => (
        <ButtonLink onClick={() => account && handleOpenModal(account)}>
          {tShared('edit')}
        </ButtonLink>
      )}
      overlayClassName="animated-drawer-overlay"
    >
      {account && (
        <AccountDetailBody<DebitCardAccount>
          renderBottomAction={renderBottomAction}
          accountProperties={accountProperties}
          account={account}
        />
      )}
    </StyledAccountDetailsDrawer>
  )
}
