import styled from 'styled-components/macro'

export const StyledLabelWithTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;

  label {
    &:last-child {
      margin-right: 0;
    }
  }
`
