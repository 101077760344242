import { gql } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { CellContext } from '@tanstack/react-table'

import { CardBalanceAvailableCell } from './components/CardBalanceAvailableCell/CardBalanceAvailableCell'
import { CardBalanceCell } from './components/CardBalanceCell/CardBalanceCell'
import { YourCardListBalanceCellDebitCardV2Fragment } from './YourCardListBalanceCell.generated'

export const YourCardListBalanceCell = <T,>({
  cell,
  row,
  getValue,
  table,
  column,
}: CellContext<T, YourCardListBalanceCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<YourCardListBalanceCellDebitCardV2Fragment>()

  if (data?.productType === DebitCardProductType.EXPENSE) {
    return (
      <CardBalanceAvailableCell
        card={data}
        cellId={cell.id}
        isLoading={isLoading}
        cellSize={cellSize}
        skeletonPropWidth={skeletonLoaderWidth}
        isAvailableLabelEnabled={false}
      />
    )
  }

  return (
    <CardBalanceCell
      card={data}
      cellId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      skeletonPropWidth={skeletonLoaderWidth}
    />
  )
}

YourCardListBalanceCell.fragments = {
  DebitCardV2: gql`
    fragment YourCardListBalanceCellDebitCardV2Fragment on DebitCardV2 {
      productType
      ...CardBalanceAvailableCellDebitCardV2Fragment
      ...CardBalanceCellDebitCardV2Fragment
    }
    ${CardBalanceAvailableCell.fragments.DebitCardV2}
    ${CardBalanceCell.fragments.DebitCardV2}
  `,
}
