import { ReactElement } from 'react'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

type WithPermissionGuard = <Props = any>(
  permissions: CustomerRole[]
) => (Component: React.FC<Props>) => (props: Props) => ReactElement

export const withPermissionGuard: WithPermissionGuard =
  (permissions) => (Component) => (props: any) => {
    const { userRole } = useLoggedInUser()
    if (permissions.includes(userRole)) {
      return <Component {...props} />
    }
    return <></>
  }
