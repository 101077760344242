import { useMemo, useState } from 'react'

import { GetDebitCardAccountsForCardCreationQueryResponse } from 'pages/GlobalModals/AddCardModal/graphql/accounts.generated'

type AccountInfo = NonNullable<
  GetDebitCardAccountsForCardCreationQueryResponse['getDebitCardAccountsV2']['accounts'][0]
>

export const useSearchAccounts = (accountInfo: AccountInfo[] | undefined) => {
  const [searchInput, setSearchInput] = useState('')

  const filteredAccounts = useMemo(
    () =>
      accountInfo?.filter((account) =>
        account.name
          ?.toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
      ) || [],
    [accountInfo, searchInput]
  )

  return { searchInput, setSearchInput, filteredAccounts }
}
