import { z } from 'zod'

const EditDebitCardTransactionNotesStateSchema = z.object({
  stage: z.literal('edit'),
  debitCardTransactionUuid: z.string(),
  note: z.string(),
})

export type EditDebitCardTransactionNotesState = z.infer<
  typeof EditDebitCardTransactionNotesStateSchema
>

export const EditDebitCardTransactionNotesLocationStateSchema = z.object({
  EditDebitCardTransactionNotesModal: EditDebitCardTransactionNotesStateSchema,
})
