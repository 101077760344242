import { createTranslations } from '@npco/utils-translations'

export const expenseCardBudgetOverdrawnTranslations = createTranslations({
  message:
    'Your budget has been fully exhausted for this period. Please contact an Admin if you require a budget increase.',
  messageAdmin:
    'This card has exceeded its original budget by {exceededAmount}. Please increase the spend limit if required.',
  messageAdminZeroBalance:
    'This card has fully exhausted its budget for this period. Please increase the spend limit if required.',
  editButton: 'Edit Spend Limit',
})
