import { ReactNode } from 'react'
import { ErrorMessageForm, HelperText } from '@npco/zeller-design-system'
import { FieldConfig } from 'formik'

import { SelectItemBasic } from '../Select.types'
import { useSelectField } from '../SelectBasic/hooks/useSelectField'
import {
  Props as SelectCompactProps,
  SelectCompact,
} from '../SelectCompact/SelectCompact'

export interface Props<ItemType>
  extends Omit<SelectCompactProps<ItemType>, 'onChange' | 'selectedItem'> {
  name: string
  validate?: FieldConfig['validate']
  label?: ReactNode
  helperText?: ReactNode
}

export const SelectCompactField = <ItemType extends SelectItemBasic>({
  helperText,
  name,
  validate,
  ...props
}: Props<ItemType>) => {
  const { selectedItem, hasError, onChange, errorMessage, setTouched } =
    useSelectField<ItemType>({
      name,
      validate,
      items: props.items,
    })

  return (
    <>
      <SelectCompact
        {...props}
        hasError={hasError}
        selectedItem={selectedItem}
        onChange={onChange}
        onClose={(selecetedItem) => {
          props.onClose?.(selecetedItem)
          setTouched()
        }}
      />

      {hasError && (
        <ErrorMessageForm hasError={hasError} errorMessage={errorMessage} />
      )}
      {helperText && <HelperText hasError={hasError}>{helperText}</HelperText>}
    </>
  )
}
