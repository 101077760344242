import { useEffect } from 'react'
import { apiPlugin, storyblokInit } from '@storyblok/react'

import { STORYBLOK_TOKEN } from 'const/envs'

export const useInitStoryblok = () => {
  useEffect(() => {
    try {
      storyblokInit({
        accessToken: STORYBLOK_TOKEN,
        use: [apiPlugin],
        components: {},
        apiOptions: {
          region: 'us',
          cache: { type: 'memory' },
          rateLimit: 1,
        },
      })
    } catch {
      // No error reporting on storyblok
    }
  }, [])
}
