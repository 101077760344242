import { useCallback, useMemo, useState } from 'react'

import { useAccountFilterContactQuery } from 'hooks/useContactsSearch/useAccountFilterContactQuery'
import { useDebounceValue } from 'hooks/useDebounceValue/useDebounceValue'
import { mapContactToSelectItem } from 'layouts/AccountLayout/filters/AccountFilterContact/AccountFilterContact.utils'

export const DEBOUNCE_DELAY_IN_MS = 500

export const useContactsSearch = () => {
  const [searchInput, setSearchInput] = useState('')

  const {
    debouncedUpdateValue: debouncedUpdateSearch,
    debouncedValue: debouncedSearchInput,
  } = useDebounceValue({ initialValue: '', delay: DEBOUNCE_DELAY_IN_MS })

  const updateSearchValue = useCallback(
    (value: string) => {
      setSearchInput(value)
      debouncedUpdateSearch(value)
    },
    [debouncedUpdateSearch]
  )

  const { contacts, isLoading } = useAccountFilterContactQuery({
    searchInput: debouncedSearchInput,
  })

  const contactItems = useMemo(
    () => (isLoading ? [] : contacts.map(mapContactToSelectItem)),
    [contacts, isLoading]
  )

  const resetSearch = () => {
    setSearchInput('')
    debouncedUpdateSearch.clear()
  }

  const isDebouncing = searchInput !== debouncedSearchInput

  return {
    contactItems,
    resetSearch,
    searchInput,
    isLoading: isLoading || isDebouncing,
    updateSearchValue,
  }
}
