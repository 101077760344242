import { Box } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DetailsListItem } from 'components/DetailsListItem/DetailsListItem'

interface ExternalIncomingDetailsProps {
  accountName: string | undefined
  description: string | null
  reference: string | null
}

export const ExternalIncomingDetails = ({
  accountName,
  description,
  reference,
}: ExternalIncomingDetailsProps) => {
  return (
    <Box mt="24px">
      <DetailsListItem
        label={translate('page.transactionDetails.account')}
        value={accountName || '-'}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.description')}
        value={description || '-'}
        isCapitalised={false}
      />
      <DetailsListItem
        label={translate('page.transactionDetails.reference')}
        value={reference || '-'}
        isCapitalised={false}
      />
    </Box>
  )
}
