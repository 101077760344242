import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  InfoBox,
  INFOBOX_VARIANT,
  ModalBasic,
  Typography,
} from '@npco/zeller-design-system'

import { useMultiStageModalProgressBar } from 'components/MultiStageModal'
import { FooterButtons } from 'components/MultiStageModal/FooterButtons'
import { translationsShared } from 'translations'

import { TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES } from '../../CreateExternalAccountModal.utils'
import { BenefitItem } from './BenefitItem/BenefitItem'
import { translations } from './ExternalAccountMessageStage.i18n'
import { useExternalAccountMessageStage } from './hooks/useExternalAccountMessageStage'

export const ExternalAccountMessageStage = () => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)
  const {
    goToCreateExternalAccountFormStage,
    closeCreateExternalAccountModal,
  } = useExternalAccountMessageStage()

  useMultiStageModalProgressBar({
    newCurrentStep: 6,
    newTotalSteps: TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES,
  })

  return (
    <>
      <ModalBasic.Body data-testid="external-account-message-stage">
        <Box py="24px">
          <InfoBox variant={INFOBOX_VARIANT.POSITIVE}>
            <InfoBox.Header>{t('infoboxHeading')}</InfoBox.Header>
            <InfoBox.Message>{t('infoboxMessage')}</InfoBox.Message>
          </InfoBox>
        </Box>
        <Typography variant="heading-md" component="h3" color={COLOR.BLUE_1000}>
          {t('benefitTitle')}
        </Typography>
        <Flex flexDirection="column" gridGap="4px" pl="12px" my="12px">
          <BenefitItem title={t('benefit1')} />
          <BenefitItem title={t('benefit2')} />
          <BenefitItem title={t('benefit3')} />
          <BenefitItem title={t('benefit4')} />
        </Flex>
        <Typography pt="12px">{t('settlementDelay')}</Typography>
      </ModalBasic.Body>
      <FooterButtons
        shouldShowBackButton
        onCancel={closeCreateExternalAccountModal}
        backButtonLabel={tShared('cancel')}
        nextButtonLabel={tShared('continue')}
        onConfirm={goToCreateExternalAccountFormStage}
      />
    </>
  )
}
