import {
  BodyDefault,
  ButtonLink,
  Flex,
  H4,
  H5,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

const baseTypographyStyle = css`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledSectionTitle = styled(H4)`
  ${baseTypographyStyle}
`

export const StyledDetailName = styled(H5)`
  ${baseTypographyStyle}
`

export const StyledDetailValue = styled(BodyDefault)`
  ${baseTypographyStyle}
`

export const StyledWrapper = styled(Flex)`
  flex: 1 0 auto;
  justify-content: flex-start;
`

export const StyledButtonLink = styled(ButtonLink)`
  margin-top: 1rem;
  background-color: transparent;
`
