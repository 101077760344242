import { useTranslations } from '@npco/utils-translations'
import {
  AnchorBasic,
  Typography,
  TypographyVariant,
} from '@npco/zeller-design-system'

import {
  ZELLER_APP_LINK_ANDROID,
  ZELLER_APP_LINK_IOS,
} from 'const/externalLinks'
import { translationsShared } from 'translations'

import { setupFlowDownloadMessageTranslations } from './DownloadMessage.i18n'

type DownloadMessageProps = {
  variant?: TypographyVariant
}

export const DownloadMessage = ({
  variant = 'heading-md',
}: DownloadMessageProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(setupFlowDownloadMessageTranslations)

  return (
    <Typography variant={variant}>
      {t('downloadMessage', {
        appStore: (
          <AnchorBasic href={ZELLER_APP_LINK_IOS}>
            {tShared('appStore')}
          </AnchorBasic>
        ),
        googlePlay: (
          <AnchorBasic href={ZELLER_APP_LINK_ANDROID}>
            {tShared('googlePlay')}
          </AnchorBasic>
        ),
      })}
    </Typography>
  )
}
