import {
  convertToAWSDate,
  DateRange,
} from '@npco/mp-feature-cashflow-reporting'

import dayjs from 'utils/dayjs'

export const getTimestampFilterFromSelectedDateRange = (
  selectedDateRange: DateRange,
  timezone: string | undefined
) => {
  if (selectedDateRange === 'Last 12 Months') {
    return {
      startDate: dayjs()
        .tz(timezone)
        .subtract(1, 'year')
        .add(1, 'month')
        .startOf('month')
        .toISOString(),
      endDate: dayjs().tz(timezone).endOf('day').toISOString(),
    }
  }

  const awsDate = dayjs(convertToAWSDate(selectedDateRange, timezone))

  if (selectedDateRange.type === 'TTM') {
    return {
      startDate: dayjs()
        .tz(timezone)
        .year(awsDate.year())
        .month(awsDate.month())
        .day(awsDate.day())
        .subtract(1, 'year')
        .add(1, 'month')
        .startOf('month')
        .toISOString(),
      endDate: dayjs()
        .tz(timezone)
        .year(awsDate.year())
        .month(awsDate.month())
        .day(awsDate.day())
        .endOf('month')
        .toISOString(),
    }
  }

  return {
    startDate: dayjs()
      .tz(timezone)
      .year(awsDate.year())
      .month(awsDate.month())
      .day(awsDate.day())
      .startOf('month')
      .toISOString(),
    endDate: dayjs()
      .tz(timezone)
      .year(awsDate.year())
      .month(awsDate.month())
      .day(awsDate.day())
      .endOf('month')
      .toISOString(),
  }
}
