import { COLOR, WalletIcon } from '@npco/zeller-design-system'

import { component } from 'translations'

import { ItemToggle } from '../components/ItemToggle/ItemToggle'
import { usePaymentsSubItems } from '../hooks/usePaymentsSubItems'

export const PaymentsItem = () => {
  const paymentsSubItems = usePaymentsSubItems()
  return (
    <ItemToggle
      icon={<WalletIcon color={COLOR.BLUE_1000} />}
      subItems={paymentsSubItems}
      name="Payments"
      className="nav-item-payments"
    >
      {component.sidebar.items.payments}
    </ItemToggle>
  )
}
