import { useTranslations } from '@npco/utils-translations'
import { inRange, isEmpty } from 'lodash-es'

import { isNumeric } from 'utils/numbers'

import { crnFieldTranslations } from './CrnField.i18n'

export const useCrnValidate = () => {
  const t = useTranslations(crnFieldTranslations)

  return (value: string): string | undefined => {
    if (isEmpty(value)) {
      return t('errorRequired')
    }

    if (!isNumeric(value)) {
      return t('errorNumeric')
    }

    if (!inRange(value.length, 2, 21)) {
      return t('errorInputLength')
    }

    return undefined
  }
}
