import { AccountSelect } from './AccountSelect/AccountSelect'
import * as styled from './CashflowFiltersHeader.styled'
import { MonthSelect } from './MonthSelect/MonthSelect'

type CashflowFiltersHeaderProps = {
  entityTimezone: string | undefined
}
export const CashflowFiltersHeader = ({
  entityTimezone,
}: CashflowFiltersHeaderProps) => {
  return (
    <>
      <styled.StyledCashflowFiltersHeader>
        <AccountSelect />
        <MonthSelect entityTimezone={entityTimezone} />
      </styled.StyledCashflowFiltersHeader>
      <styled.StyledDivider />
    </>
  )
}
