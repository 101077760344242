import { type ReactNode } from 'react'
import { Animation } from '@npco/zeller-design-system'
import { Variants } from 'framer-motion'

import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'

interface TransferStateProps {
  allowedStates?: any[]
  excludedStates?: any[]
  parent?: TransferGeneralState
  children: ReactNode | ReactNode[]
  animationVariants?: Variants
}

type UseShouldShowComponentArgs = Pick<
  TransferStateProps,
  'allowedStates' | 'excludedStates' | 'parent'
>

const useShouldShowComponent = ({
  parent,
  allowedStates,
  excludedStates,
}: UseShouldShowComponentArgs) => {
  const {
    transferState: [state],
  } = useTransferState()

  const matchName = (checkState: string | number) =>
    parent
      ? state.matches(`${parent}.${checkState}`)
      : state.matches(checkState)

  if (excludedStates) {
    return !excludedStates.some(matchName)
  }

  return Boolean(allowedStates?.some(matchName))
}

export const TransferStateCondition = ({
  allowedStates,
  excludedStates,
  parent,
  children,
  animationVariants,
}: TransferStateProps) => {
  const shouldShow = useShouldShowComponent({
    parent,
    allowedStates,
    excludedStates,
  })

  return (
    <Animation variants={animationVariants} shouldShow={shouldShow}>
      {children}
    </Animation>
  )
}
