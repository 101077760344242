import { useTranslations } from '@npco/utils-translations'
import { INPUT_SIZE, InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ToggleFormField } from 'components/ToggleFormField'

import { useIsInvoicingEnabled } from '../../../../../../hooks/invoices/useIsInvoicingEnabled'
import { FormButtons } from '../../FormButtons'
import {
  BCC_COPY_FIELD,
  BCC_EMAIL_FIELD,
  DEFAULT_SEND_BY_EMAIL_FIELD,
  DEFAULT_SEND_BY_SMS_FIELD,
  DISCOUNT_FIELD,
} from '../SettingsGeneral.constants'
import { InvoiceSettingsGeneralFormFields } from '../SettingsGeneral.types'
import { AddressToggleFormField } from './AddressToggleFormField'
import { translations } from './SettingsGeneralForm.i18n'

export const SettingsGeneralForm = () => {
  const isInvoicingEnabled = useIsInvoicingEnabled()

  const t = useTranslations(translations)
  const { values, submitForm, resetForm } =
    useFormikContext<InvoiceSettingsGeneralFormFields>()
  return (
    <>
      <AddressToggleFormField />

      <ToggleFormField
        label={t('bccEmailToggle')}
        marginBottom="24px"
        name={BCC_COPY_FIELD}
        value={values[BCC_COPY_FIELD]}
      >
        <InputAdaptiveField
          size={INPUT_SIZE.SMALL}
          label={t('bccEmailLabel')}
          name={BCC_EMAIL_FIELD}
        />
      </ToggleFormField>
      {isInvoicingEnabled && (
        <>
          <ToggleFormField
            label={t('sendViaEmailLabel')}
            marginBottom="24px"
            name={DEFAULT_SEND_BY_EMAIL_FIELD}
            value={values[DEFAULT_SEND_BY_EMAIL_FIELD]}
            tooltipMessage={t('sendViaEmailTooltip')}
          />
          <ToggleFormField
            label={t('sendViaSMSLabel')}
            marginBottom="24px"
            name={DEFAULT_SEND_BY_SMS_FIELD}
            value={values[DEFAULT_SEND_BY_SMS_FIELD]}
            tooltipMessage={t('sendViaSMSTooltip')}
          />
        </>
      )}
      <ToggleFormField
        label={t('discountsLabel')}
        marginBottom="24px"
        name={DISCOUNT_FIELD}
        value={values[DISCOUNT_FIELD]}
        tooltipMessage={t('discountsTooltip')}
      />

      <FormButtons onSave={submitForm} onCancel={resetForm} />
    </>
  )
}
