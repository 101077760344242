import { AUSTRALIA_COUNTRY_CODE } from '@npco/component-mp-common'

import {
  ProfileSettingFormValuesWithEmail,
  ProfileSettingsData,
} from 'types/profile'

export const getInitialProfilePersonalValues = (
  currentCustomer?: ProfileSettingsData
): ProfileSettingFormValuesWithEmail => ({
  firstname: currentCustomer?.firstname || '',
  middlename: currentCustomer?.middlename || '',
  lastname: currentCustomer?.lastname || '',
  email: currentCustomer?.email || '',
  emailConfirm: '',
  street: currentCustomer?.address?.street || '',
  suburb: currentCustomer?.address?.suburb || '',
  state: currentCustomer?.address?.state || '',
  postcode: currentCustomer?.address?.postcode || '',
  country: currentCustomer?.address?.country || AUSTRALIA_COUNTRY_CODE,
})

export const getIsProfileFormDisabled = (
  values: ProfileSettingFormValuesWithEmail
) =>
  Boolean(
    !values.firstname ||
      !values.lastname ||
      !values.email ||
      !values.country ||
      !values.street ||
      !values.suburb ||
      !values.state ||
      !values.postcode
  )
