import { z } from 'zod'

export enum CreateAccountStage {
  AccountNameStage = 'AccountNameStage',
}

export const CreateAccountLocationSchema = z.object({
  CreateAccountModal: z.object({
    stage: z.nativeEnum(CreateAccountStage),
  }),
})
