import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { SelectAccountForm } from './SelectAccountForm/SelectAccountForm'

export const SelectAccountStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition
      shouldShow={state?.stage === AddCardStage.CreateSelectAccountStage}
    >
      <SelectAccountForm />
    </Condition>
  )
}
