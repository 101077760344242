import { useCallback, useState } from 'react'
import { renameAndDownloadFile } from '@npco/utils-file'
import { useInvoiceAnalytics } from 'features/Invoicing/components/Invoices/hooks/useInvoiceAnalytics'

import { AnalyticsEventNames } from 'services/Analytics/events'
import { PDFPreviewProps } from 'components/ImagePreview/ImagePreview.types'

import { translations } from '../../hooks/useInvoiceActionPrompt'

const DEFAULT_PDF_HEIGHT = 613

export const useInvoiceDrawerDetailsPdf = (
  referenceNumber: string,
  invoiceId?: string,
  pdfLink = ''
) => {
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [pdfHeight, setPdfHeight] = useState(DEFAULT_PDF_HEIGHT)
  const [showPreview, setShowPreview] = useState(false)

  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, invoiceId)

  const handleOnPdfLoadSuccess: PDFPreviewProps['onPdfLoadSuccess'] =
    useCallback(
      ({ numPages }) => {
        setTotalPages(numPages)
      },
      [setTotalPages]
    )

  const handleDownloadPdf = useCallback(() => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_DOWNLOAD_PDF)
    renameAndDownloadFile({
      link: pdfLink,
      filename: translations.getFileName(referenceNumber),
      format: 'PDF',
    })
  }, [pdfLink, referenceNumber, trackInvoiceAction])

  const handleOnPdfRenderPageSuccess: PDFPreviewProps['onPdfRenderPageSuccess'] =
    useCallback(
      ({ height }) => {
        setPdfHeight(height)
      },
      [setPdfHeight]
    )

  const setPreviewValue = useCallback(
    (value: boolean) => () => setShowPreview(value),
    [setShowPreview]
  )

  return {
    handleDownloadPdf,
    handleOnPdfLoadSuccess,
    handleOnPdfRenderPageSuccess,
    pageNumber,
    pdfHeight,
    setPageNumber,
    setPreviewValue,
    showPreview,
    totalPages,
  }
}
