import { createTranslations } from '@npco/utils-translations'

export const cardAltTextTranslations = createTranslations({
  altTextWhiteCard: 'White Zeller Card',
  altTextBlackCard: 'Black Zeller Card',
  altTextDarkblueCard: 'DarkBlue Corporate Card',
  altTextYellowCard: 'Yellow Corporate Card',
  altTextGreenCard: 'Green Corporate Card',
  altTextLightblueCard: 'LightBlue Corporate Card',
})
