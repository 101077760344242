import { useTranslations } from '@npco/utils-translations'
import { Box, Flex } from '@npco/zeller-design-system'

import { getNonNullString } from 'utils/string'
import { LoadingSkeletonRow } from 'pages/Devices/LoadingSkeletonRow/LoadingSkeletonRow'
import { DetailsField } from 'components/DetailsField/DetailsField'

import { GetDeviceInformationQueryResponse } from '../../graphql/getDeviceInformation.generated'
import { translations } from '../NetworkDetails.i18n'
import { SimSignalStrength } from './SignalStrength/SimSignalStrength/SimSignalStrength'

type CellularDetailsProps = {
  isLoading: boolean
  networkDetails: GetDeviceInformationQueryResponse['getDeviceInformation']['network']
}
export const CellularDetails = ({
  isLoading,
  networkDetails,
}: CellularDetailsProps) => {
  const t = useTranslations(translations)
  const { operatorName, strength } = networkDetails?.cellularInfo ?? {}

  return (
    <Flex flexDirection="column">
      <Box pb="8px">
        <LoadingSkeletonRow
          data-testid="SIM"
          isLoading={isLoading}
          label={t('sim')}
          value={t('connected')}
        />
      </Box>
      <DetailsField
        isLoading={isLoading}
        value={getNonNullString(operatorName, '-')}
        label={t('simOperator')}
      />
      <DetailsField
        isLoading={isLoading}
        value={<SimSignalStrength strength={strength ?? undefined} />}
        label={t('signalStrength')}
      />
    </Flex>
  )
}
