import { useCallback, useState } from 'react'
import { GetInvoicesAmountFilterInput } from '@npco/mp-gql-types'
import { ButtonFill, ButtonGhost, Flex } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { AmountColumnEnum } from '../../InvoiceTable.types'
import { InvoiceTableDefaultAmount } from '../../InvoiceTable.utils'
import { AmountFiltersContent } from '../../InvoiceTableFilters/AmountFilters/AmountFiltersContent'
import { useAmountFilters } from '../../InvoiceTableFilters/AmountFilters/useAmountFilters'
import { FilterState } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFilters.utils'
import { BottomWrapper } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer.styled'

interface AmountFiltersProps {
  setFilterState: (value: FilterState) => void
}

export const AmountFilters = ({ setFilterState }: AmountFiltersProps) => {
  const {
    filterInput,
    selectedAmount,
    selectedAmountColumn,
    setSelectedAmountColumn,
    setAmountFilter,
  } = useInvoicesContext()

  const [nextSelectedAmount, setNextSelectedAmount] = useState(selectedAmount)

  const [nextSelectedAmountColumn, setNextSelectedAmountColumn] =
    useState(selectedAmountColumn)

  const [nextAmountFilter, setNextAmountFilter] =
    useState<GetInvoicesAmountFilterInput | null>(
      filterInput?.amountFilter ?? null
    )

  const {
    inputMin,
    inputMax,
    sliderMin,
    sliderMax,
    onInputChange,
    onSliderChange,
    handleResetClick,
    onAfterChange,
  } = useAmountFilters({
    selectedAmount: nextSelectedAmount,
    selectedAmountColumn: nextSelectedAmountColumn,
    setAmountFilter: setNextAmountFilter,
  })

  const handleApply = useCallback(() => {
    setSelectedAmountColumn(nextSelectedAmountColumn)
    setAmountFilter(nextAmountFilter)
    setFilterState(FilterState.FilterAndSort)
  }, [
    nextAmountFilter,
    nextSelectedAmountColumn,
    setAmountFilter,
    setFilterState,
    setSelectedAmountColumn,
  ])

  const handleClear = useCallback(() => {
    setNextSelectedAmount(InvoiceTableDefaultAmount)
    setNextSelectedAmountColumn(AmountColumnEnum.AmountPaid)
    setNextAmountFilter(null)
    handleResetClick()
  }, [
    handleResetClick,
    setNextAmountFilter,
    setNextSelectedAmount,
    setNextSelectedAmountColumn,
  ])

  return (
    <Flex flexDirection="column" flexGrow={1} data-testid="amount-filters">
      <Flex flexDirection="column" flexGrow={1}>
        <AmountFiltersContent
          inputMax={inputMax}
          inputMin={inputMin}
          onAfterChange={onAfterChange}
          onInputChange={onInputChange}
          onSliderChange={onSliderChange}
          selectedAmountColumn={nextSelectedAmountColumn}
          setSelectedAmountColumn={setNextSelectedAmountColumn}
          sliderMax={sliderMax}
          sliderMin={sliderMin}
        />
      </Flex>
      <BottomWrapper>
        <ButtonGhost onClick={handleClear} dataTestId="clear-filters-btn">
          {translate('shared.clear')}
        </ButtonGhost>
        <ButtonFill dataTestId="apply-filters-btn" onClick={handleApply}>
          {translate('shared.apply')}
        </ButtonFill>
      </BottomWrapper>
    </Flex>
  )
}
