import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { ROOT } from 'const/routes'

interface GoToTransferOptions {
  transferFromAccountId?: string
}

export const useGoToTransfer = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const goToTransfer = useCallback(
    ({ transferFromAccountId }: GoToTransferOptions = {}) => {
      navigate(ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.TRANSFER.path, {
        state: {
          initialDropdownAccountId: transferFromAccountId,
        },
      })
    },
    [navigate, shortEntityId]
  )

  return { goToTransfer }
}
