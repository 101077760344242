import {
  AVATAR_BADGE_SIZE,
  AvatarBasic,
  COLOR,
  SvgIcon,
} from '@npco/zeller-design-system'

import { getDeviceModelIcon } from 'utils/device'
import { DeviceModel } from 'types/devices'

import * as styled from './DeviceCard.styled'

type DeviceAvatarProps = {
  deviceModel: DeviceModel
}

export const DeviceAvatar = ({ deviceModel }: DeviceAvatarProps) => {
  return (
    <styled.AvatarContainer>
      <AvatarBasic
        text=""
        badgeSize={AVATAR_BADGE_SIZE.SMALL}
        userDefinedContent={
          <SvgIcon width="16" height="16" color={COLOR.BLACK_900}>
            {getDeviceModelIcon(deviceModel)}
          </SvgIcon>
        }
      />
    </styled.AvatarContainer>
  )
}
