import { gql } from '@apollo/client'

export const CloseAccount = gql`
  mutation CloseAccount($entityUuid: ID!, $debitCardAccountId: ID!) {
    closeDebitCardAccount(
      entityUuid: $entityUuid
      debitCardAccountUuid: $debitCardAccountId
    )
  }
`
