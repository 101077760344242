import { Box, Flex, InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { getUnitWithStreetNumber } from 'utils/address'
import { buildStringWithSeparator } from 'utils/common'
import {
  combineValidators,
  validateAddressString,
  validateCompanyName,
  validatePostcode,
  validateRequiredCustomMsg,
  validateSuburb,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { Address } from 'forms/formSections/Address'
import { validateAddressLineWithPoBox } from 'forms/formViews/OfficeAddress/OfficeAddress.validators'
import { AddressAutocompleteInput } from 'components/Input/AddressAutocompleteInput'

import { XeroPaymentServicesConnetionFormValues } from '../XeroPaymentServicesConnectionSetup.types'
import { Description } from './BusinessDetailForm.styled'

export const BusinessDetailForm = () => {
  const { values, setValues } =
    useFormikContext<XeroPaymentServicesConnetionFormValues>()

  return (
    <div>
      <Box mb="24px">
        <AddressAutocompleteInput
          name="street"
          placeholder={translate('shared.typeForSuggestions')}
          country="au"
          label={translate('form.shared.address')}
          setFullAddressValues={(googleAddressData) => {
            setValues({
              ...values,
              postcode: googleAddressData.postcode,
              street: buildStringWithSeparator(
                ' ',
                getUnitWithStreetNumber(
                  googleAddressData.streetNumber,
                  googleAddressData.unit
                ),
                googleAddressData.street
              ),
              suburb: googleAddressData.suburb,
              state: googleAddressData.state,
            })
          }}
          validate={combineValidators(
            validateAddressString,
            validateAddressLineWithPoBox
          )}
          maxLength={35}
        />
      </Box>

      <Address
        suburbValidator={validateSuburb}
        stateValidator={validateRequiredCustomMsg()}
        postcodeValidator={validatePostcode}
        shouldValidate
        mb="24px"
      />

      <Flex flexDirection="column" mb="24px">
        <InputAdaptiveField
          label={translate('form.site.businessName.label')}
          name="businessName"
          type="text"
          value={values.businessName}
          validate={validateCompanyName}
        />
        <Description>
          {translate(
            'page.settings.connections.xeroPaymentServices.setBusinessDetails.receiptDetailsDescription'
          )}
        </Description>
      </Flex>
    </div>
  )
}
