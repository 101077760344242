import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { getVerificationStatus } from 'utils/kyc/kycIdVerification.utils'
import { translate } from 'utils/translations'
import { CustomerDetailsForm } from 'types/customers'
import { FormLine } from 'components/FormLine'

interface IDVerificationStatusFormLineProps {
  isEditForm?: boolean
}
export const IDVerificationStatusFormLine = ({
  isEditForm,
}: IDVerificationStatusFormLineProps) => {
  const { values } = useFormikContext<CustomerDetailsForm>()

  if (!isEditForm) {
    return null
  }

  return (
    <FormLine fractions={[1, 1]}>
      <Box fontWeight={500}>
        {translate('page.settings.users.idVerificationStatus')}
      </Box>
      <Box textAlign="right">
        {getVerificationStatus(values.kycStatus).display}
      </Box>
    </FormLine>
  )
}
