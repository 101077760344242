import * as UseSelectedEntityUuidHook from '../hooks/useSelectedEntityUuid/useSelectedEntityUuid'

export const mockedEntityUuid = '1b1404d7-5c2b-4a14-bf9e-8bdc494e7234'

export const mockedEntityUuid2 = 'bf7fed24-ced3-41f1-8268-b31b18d696e7'

export const mockUseSelectedEntityUuid = (
  entityUuid: string = mockedEntityUuid
) => {
  jest
    .spyOn(UseSelectedEntityUuidHook, 'useSelectedEntityUuid')
    .mockReturnValue(entityUuid)
}
