import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ZellerCardSummaryDebitCardV2FragmentDoc } from '../../../../components/DebitCardSummary/ZellerCardSummary.generated'
import { CannotPayByCardWarningMessageEntityFragmentDoc } from './CannotPayByCardWarningMessage/CannotPayByCardWarningMessage.generated'
import { ExpenseCardBudgetMessageDisplayDebitCardV2FragmentDoc } from './ExpenseCardBudgetMessageDisplay/ExpenseCardBudgetMessageDisplay.generated'

export type ZellerCardSummaryDisplayDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  colour: Types.DebitCardColour
  id: string
  status: Types.DebitCardStatus
  productType: Types.DebitCardProductType | null
  maskedPan: string
  accessibleProfile: boolean | null
  format: Types.DebitCardFormat | null
  debitCardAccount: {
    __typename: 'DebitCardAccountV2'
    id: string
    name: string
    balance: { __typename?: 'Money'; value: string } | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
    } | null
  }
  velocityControl: {
    __typename?: 'VelocityControlType'
    velocityWindow: Types.VelocityWindowEnum
    resetsAt: any
    isPendingSubscriptionUpdate: boolean | null
    amountLimit: { __typename?: 'Money'; value: string }
    maxTransactionValue: { __typename?: 'Money'; value: string }
    modifiedBy: {
      __typename?: 'VelocityControlModifiedByType'
      actingCustomerUuid: string
      updatedAt: any
    } | null
    availableAmount: { __typename?: 'Money'; value: string }
    totalSpentAmount: { __typename?: 'Money'; value: string }
  } | null
  customer: {
    __typename: 'Customer'
    id: string
    entityUuid: string | null
    firstname: string | null
    lastname: string | null
    icon: {
      __typename?: 'Icon'
      image: string | null
      images: Array<{
        __typename?: 'Image'
        url: string
        size: Types.ImageSize
      }> | null
    } | null
  } | null
}

export type ZellerCardSummaryDisplayGetEntityFragment = {
  __typename?: 'Entity'
  id: string
  canPayByCard: boolean | null
}

export const ZellerCardSummaryDisplayDebitCardV2FragmentDoc = gql`
  fragment ZellerCardSummaryDisplayDebitCardV2Fragment on DebitCardV2 {
    ...ZellerCardSummaryDebitCardV2Fragment
    ...ExpenseCardBudgetMessageDisplayDebitCardV2Fragment
  }
  ${ZellerCardSummaryDebitCardV2FragmentDoc}
  ${ExpenseCardBudgetMessageDisplayDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  ZellerCardSummaryDisplayDebitCardV2Fragment,
  undefined
>
export const ZellerCardSummaryDisplayGetEntityDoc = gql`
  fragment ZellerCardSummaryDisplayGetEntity on Entity {
    ...CannotPayByCardWarningMessageEntityFragment
  }
  ${CannotPayByCardWarningMessageEntityFragmentDoc}
`
