import { RefObject, useCallback, useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

interface UseTrackScrollProps {
  scrollableRef: RefObject<HTMLDivElement>
}

export const useTrackScroll = ({ scrollableRef }: UseTrackScrollProps) => {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)
  const [hasScrolledToTop, setHasScrolledToTop] = useState(false)
  const [isScrollable, setIsScrollable] = useState(false)

  const trackScroll = useCallback(() => {
    if (scrollableRef.current) {
      const { clientHeight, scrollHeight, scrollTop } = scrollableRef.current

      const isScrollVisible = scrollHeight > clientHeight

      // NOTE: In some instances scrollTop + clientHeight is 1 pixel short of
      // scrollHeight for reasons unknown. Add 1 as buffer and check if value
      // is greater or equal to scrollHeight
      const hasReachedBottom =
        Math.abs(scrollTop) + clientHeight + 1 >= scrollHeight

      const hasReachedTop = scrollTop === 0

      setIsScrollable(isScrollVisible)
      setHasScrolledToBottom(hasReachedBottom)
      setHasScrolledToTop(hasReachedTop)
    }
  }, [scrollableRef])

  // NOTE: use on resize callback to track scrollbar on container resize
  useResizeDetector({
    onResize: trackScroll,
    targetRef: scrollableRef,
    refreshMode: 'debounce',
    refreshRate: 0,
  })

  useEffect(() => {
    return () => {
      // NOTE: important! reset state on unmount
      setHasScrolledToBottom(false)
      setIsScrollable(false)
    }
  }, [])

  return {
    handleScroll: trackScroll,
    hasScrolledToBottom,
    hasScrolledToTop,
    isScrollable,
  }
}
