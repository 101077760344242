import { DatePickerInputExact, INPUT_SIZE } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { useInvoiceDate } from '../hooks/useInvoiceDate'
import { DATE_PICKER_POPPER_WIDTH } from '../InvoiceScheduleAccordion.constants'

export const translations = {
  invoiceDateFieldLabel: translate(
    'page.invoice.formSections.schedule.invoiceDateLabel'
  ),
}

export const InvoiceDate = () => {
  const {
    isMobileResolution,
    fieldError,
    fieldName,
    fieldValue,
    handleBlur,
    handleDateChange,
  } = useInvoiceDate()

  return (
    <DatePickerInputExact
      error={fieldError}
      hasError={Boolean(fieldError)}
      inputSize={INPUT_SIZE.SMALL}
      label={translations.invoiceDateFieldLabel}
      name={fieldName}
      onBlur={handleBlur}
      onChange={handleDateChange}
      popperWidth={isMobileResolution ? undefined : DATE_PICKER_POPPER_WIDTH}
      value={fieldValue}
    />
  )
}
