import { useCallback, useEffect, useMemo } from 'react'
import { SelectSelectedItem } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { validateRequired } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { FileListSelectItem } from 'components/File/File.types'
import { useFileContext } from 'components/File/hooks/useFileContext'

interface UseFileListSelectProps {
  id: string
  onDelete?: (id: string) => void
}

export const useFileListSelect = ({ id }: UseFileListSelectProps) => {
  const { optionItems, defaultOption } = useFileContext()
  const [field, meta, handlers] = useField({
    name: `fileItem.${id}`,
    ...(!!optionItems && {
      validate: validateRequired,
    }),
  })

  const handleSelect = useCallback(
    (selectedOptionItem: SelectSelectedItem<FileListSelectItem>) => {
      handlers.setValue(selectedOptionItem)
    },
    [handlers]
  )

  useEffect(() => {
    if (defaultOption) {
      handlers.setValue(defaultOption, false)
    }
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectLabel = useMemo(
    () =>
      field?.value?.label ??
      translate('form.documentType.documentTypePlaceholderLabel'),
    [field?.value?.label]
  )

  return {
    handleSelect,
    hasError: !!meta.error,
    optionItems,
    selectedItem: field?.value,
    selectLabel,
  }
}
