import { matchPath } from 'react-router-dom-v5-compat'
import { memoize } from 'lodash-es'

import { ROOT, type RootRoute } from 'const/routes'

/**
 * Flattened ROOT paths with custom page names
 * for tracking page analytics
 */
export const getRootPathsFlatMap = (
  rootPaths: RootRoute
): [Record<string, string>, string[]] => {
  const pathsWithCustomPageNameMap: Record<string, string> = {}
  const pathsWithoutCustomPageName: string[] = []

  const callback = (rootRoute: RootRoute) => {
    if (rootRoute?.path) {
      if (rootRoute?.customPageName) {
        pathsWithCustomPageNameMap[rootRoute.path] = rootRoute.customPageName
      } else {
        pathsWithoutCustomPageName.push(rootRoute.path)
      }
    }

    const routeKeys = Object.keys(rootRoute).filter(
      (prop) =>
        typeof rootRoute[prop] === 'object' ||
        typeof rootRoute[prop] === 'function'
    )

    routeKeys.forEach((callableKey) => {
      const rootRouteObj = rootRoute[callableKey]

      if (typeof rootRouteObj === 'function') {
        callback(rootRouteObj())
      } else {
        callback(rootRouteObj as RootRoute)
      }
    })
  }
  callback(rootPaths)

  return [pathsWithCustomPageNameMap, pathsWithoutCustomPageName]
}

const [rootPathsWithCustomPageNameMap, rootPathsWithoutCustomPageName] =
  getRootPathsFlatMap(ROOT)

const ignoreUrlChangePaths = [
  ROOT.ORGS.ORG().CONTACTS.path,
  ROOT.CUSTOMER_PREFERENCES.path,
  ROOT.JOIN_WAITLIST.path,
]

/**
 * This function looks at the existing ROUTES enum paths if
 * the current location has a defined custom page name. If the
 * path is not defined in ROUTES, check the flattened ROOT object
 * map which contains all paths that has customPageName property
 * @param locationPathName location path name from useLocation
 * @returns custom page name for analytics
 */
export const getCustomPageName = memoize((locationPathName: string) => {
  const matchingIgnoredPaths = ignoreUrlChangePaths.filter((rootRoute) => {
    return Boolean(matchPath(rootRoute, locationPathName))
  })
  if (matchingIgnoredPaths.length) {
    return null
  }

  const matchingPathsWithoutPageName = rootPathsWithoutCustomPageName.filter(
    (rootRoute) => {
      return Boolean(matchPath(rootRoute, locationPathName))
    }
  )
  if (matchingPathsWithoutPageName.length) {
    return null
  }

  // Get all matched ROOT paths in the cases of dynamic segments path/:id
  const matchingRootPaths = Object.keys(rootPathsWithCustomPageNameMap).filter(
    (rootRoute) => {
      return Boolean(matchPath(rootRoute, locationPathName))
    }
  )

  if (!matchingRootPaths.length) {
    return null
  }

  // This already handles dynamic segments
  if (matchingRootPaths.length === 1) {
    return rootPathsWithCustomPageNameMap[matchingRootPaths[0]]
  }

  // Grab the map key of EXACT path
  const matchingPath = matchingRootPaths.find((rootPath) => {
    // Hack to get the matching path if the rootPath contains '/orgs/:entity-id
    // and locationPathName contains '/orgs/default'
    const rootPathSubString = rootPath.replace(ROOT.ORGS.ORG().path, '')

    const regExp = new RegExp(`${ROOT.ORGS.path}/[a-zA-Z0-9_+&*-]+`)
    const locationPathNameSubString = locationPathName.replace(regExp, '')

    return rootPathSubString === locationPathNameSubString
  })

  return rootPathsWithCustomPageNameMap[matchingPath ?? ''] ?? matchingPath
})
