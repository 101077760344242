import { useCallback, useState } from 'react'

type ClosedCallback = (() => void) | null

export const useModalCloseTransition = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [hasModalCloseTransition, setHasModalCloseTransition] = useState(false)
  const [transitionFinishedCallback, setTransitionFinishedCallback] =
    useState<ClosedCallback>(null)

  const closeWithoutTransition = useCallback(() => {
    if (!isModalOpen) {
      return
    }
    setHasModalCloseTransition(false)
    setIsModalOpen(false)
  }, [isModalOpen])

  const closeWithTransition = useCallback(
    (closedCallback: ClosedCallback) => {
      if (!isModalOpen) {
        return
      }

      setTransitionFinishedCallback(() => closedCallback)
      setHasModalCloseTransition(true)
      setIsModalOpen(false)
    },
    [isModalOpen]
  )

  const executeTransitionFinishedCallback = useCallback(() => {
    transitionFinishedCallback?.()
    setIsModalOpen(true)
    setHasModalCloseTransition(false)
    setTransitionFinishedCallback(null)
  }, [transitionFinishedCallback])

  return {
    isModalOpen,
    hasModalCloseTransition,
    executeTransitionFinishedCallback,
    closeWithoutTransition,
    closeWithTransition,
  }
}
