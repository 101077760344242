import { BoxProps, Flex } from '@npco/zeller-design-system'

type CardRowProps = {
  children: React.ReactNode
  width?: BoxProps['width']
}

export const CardRow = ({ children, width }: CardRowProps) => {
  return (
    <Flex justifyContent={{ _: 'space-between', LG: 'left' }} width={width}>
      {children}
    </Flex>
  )
}
