import { validateEmail } from '@npco/component-mp-common'
import { ErrorLogger } from '@npco/utils-error-logger'
import {
  Flex,
  InputAdaptiveField,
  ModalForm,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import {
  getAdditionalEmailsPayload,
  getContactName,
} from 'features/Contacts/Contacts.utils'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import { useUpdateContact } from 'features/Contacts/hooks/useUpdateContact/useUpdateContact'
import { updateRvContactsOnContactUpdate } from 'features/Contacts/rv-deprecated/contacts.utils'
import { Formik } from 'formik'

import { translate } from 'utils/translations'

import { CreateNewContactEvent } from '../InvoiceEmailRecipients.types'
import { INVOICE_LINK_EMAIL_FIELD } from './InvoiceLinkEmailToContactModal.constants'
import { InvoiceLinkEmailToContactSelect } from './InvoiceLinkEmailToContactSelect/InvoiceLinkEmailToContactSelect'

export const translations = {
  emailLabel: translate(
    'page.invoice.formSections.email.saveEmailToContactEmailLabel'
  ),
  linkToContactButton: translate(
    'page.invoice.formSections.email.linkToContactButton'
  ),
  modalTitle: translate(
    'page.invoice.formSections.email.saveEmailToContactTitle'
  ),
}

export const getUpdateContactMessage = (contactName: string | null) => ({
  successMessage: translate(
    'page.invoice.formSections.email.saveEmailToContactSuccess',
    {
      contactName,
    }
  ),
  failedMessage: translate(
    'page.invoice.formSections.email.saveEmailToContactFailure',
    {
      contactName,
    }
  ),
})

type InvoiceLinkEmailToContactModalFormFields = {
  email: string
  contactUuid: string
}

interface InvoiceLinkEmailToContactModalProps {
  initialEmailValue: string
  onClose: (email?: string) => void
  onCreateNewContact: CreateNewContactEvent
}

export const InvoiceLinkEmailToContactModal = ({
  initialEmailValue,
  onClose,
  onCreateNewContact,
}: InvoiceLinkEmailToContactModalProps) => {
  const { isUpdatingContact, updateContact } = useUpdateContact()
  const { readFromCache, updateInCache } = useContactCache()

  const handleUpdateContact = async (
    values: InvoiceLinkEmailToContactModalFormFields
  ) => {
    const contact = readFromCache(values.contactUuid)
    const newEmailToAddPayload = { email: values.email, labelUuid: null }

    const { failedMessage, successMessage } = getUpdateContactMessage(
      getContactName(contact)
    )

    try {
      const response = await updateContact(
        {
          additionalEmails: contact?.additionalEmails
            ? getAdditionalEmailsPayload(contact.additionalEmails).concat(
                newEmailToAddPayload
              )
            : [newEmailToAddPayload],
        },
        values.contactUuid
      )

      if (!response.data?.updateContact) {
        showErrorToast(failedMessage)
        return
      }
      showSuccessToast(successMessage)

      const newEmailToAddInRv = { email: values.email, label: null }
      const additionalEmailsPayload = {
        additionalEmails: contact?.additionalEmails
          ? contact.additionalEmails.concat(newEmailToAddInRv)
          : [newEmailToAddInRv],
      }

      updateRvContactsOnContactUpdate(
        values.contactUuid,
        additionalEmailsPayload
      )
      updateInCache(values.contactUuid, additionalEmailsPayload)
      onClose(values.email)
    } catch (error) {
      ErrorLogger.report('[Payment] Update Contact', error)
      showErrorToast(failedMessage)
    }
  }

  return (
    <Formik
      initialValues={{ email: initialEmailValue, contactUuid: '' }}
      onSubmit={handleUpdateContact}
    >
      {({ handleSubmit }) => (
        <ModalForm
          isLoading={isUpdatingContact}
          isOpen
          onCancel={() => onClose()}
          onClickPrimary={handleSubmit}
          primaryButtonLabel={translate('shared.save')}
          secondaryButtonLabel={translate('shared.cancel')}
          title={translations.modalTitle}
        >
          <Flex flexDirection="column" gridGap="20px" pt="5px">
            <div>
              <InputAdaptiveField
                name={INVOICE_LINK_EMAIL_FIELD}
                label={translations.emailLabel}
                validate={validateEmail}
              />
            </div>
            <InvoiceLinkEmailToContactSelect
              onCreateNewContact={onCreateNewContact}
            />
          </Flex>
        </ModalForm>
      )}
    </Formik>
  )
}
