import {
  CreatePaymentInstrumentInput,
  PaymentInstrumentType,
} from '@npco/mp-gql-types'

interface GetCreatePaymentInstrumentInputArgs {
  isDynamicPayment: boolean
  contactUuid: string
  billerCode: string
  crn: string
  crnLengths: number[]
  nickname: string
  billerName: string
}

export const getCreatePaymentInstrumentInput = ({
  isDynamicPayment,
  contactUuid,
  billerCode,
  crn,
  crnLengths,
  nickname,
  billerName,
}: GetCreatePaymentInstrumentInputArgs): CreatePaymentInstrumentInput => {
  if (isDynamicPayment) {
    return {
      type: PaymentInstrumentType.BPAY_DYNAMIC_CRN,
      contactUuid,
      bpayDynamicCrnDetails: {
        billerCode,
        billerName,
        crnLengths,
        nickname,
      },
    }
  }

  return {
    type: PaymentInstrumentType.BPAY_STATIC_CRN,
    contactUuid,
    bpayStaticCrnDetails: {
      billerCode,
      crn,
      crnLengths,
      nickname,
      billerName,
    },
  }
}
