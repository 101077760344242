import {
  BodyDefault,
  Box,
  ButtonFill,
  DataSmall,
  H1,
  H3,
} from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

export const StyledHeading = styled(H1)`
  margin: 0 0 1.5rem;
`

export const StyledContactDetails = styled(Box)`
  background-color: ${({ theme }) => theme.colors.GREY_30};
  border: 1px solid ${({ theme }) => theme.colors.GREY_90};
  border-radius: 7px;
  padding-left: 1rem;
  margin-bottom: 1.5rem;
`

export const StyledUnlinkHeading = styled(H3)`
  margin: 0 0 1rem;
`

export const StyledUnlinkContent = styled(BodyDefault)`
  margin: 0;
`

export const StyledUnlinkButton = styled(ButtonFill)`
  margin-top: 1rem;
  width: 91px;
`

export const StyledAgreement = styled(DataSmall)`
  margin: 1rem 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`
