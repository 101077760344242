import { useTranslations } from '@npco/utils-translations'
import { COLOR, SvgIcon, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'

import { ReactComponent as PercentageIcon } from 'assets/svg/percentage.svg'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import {
  getMaxCardSurchargeValue,
  validateSurchargePercent,
} from '../../SitePayments.utils'
import { translations } from './VirtualTerminal.i18n'

export interface Props {
  feePercent: number
}

export const SurchargeInput = ({ feePercent }: Props) => {
  const isDisabledSurchargeInput = !feePercent && feePercent !== 0
  const t = useTranslations(translations)

  return (
    <InputAdaptiveFieldWrapper
      size={TEXT_INPUT_SIZE.SMALL}
      label={t('cardSurcharge')}
      name="vtSurchargePercent"
      placeholder={t('cardSurcharge')}
      step=".01"
      type="number"
      validate={validateSurchargePercent(getMaxCardSurchargeValue(feePercent))}
      disabled={isDisabledSurchargeInput}
      min={0}
      renderRightControls={({ hasError }) => (
        <SvgIcon
          width="16"
          height="16"
          withMarginRight={hasError}
          color={COLOR.BLUE_1000}
        >
          <PercentageIcon />
        </SvgIcon>
      )}
    />
  )
}
