import { useRef } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ApolloError } from '@apollo/client'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  showErrorToast,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

import { useInvoiceAnalytics } from '../../../../hooks/useInvoiceAnalytics'
import { useSaveAndSendInvoice } from '../../../../hooks/useSaveAndSendInvoice'
import { useSendInvoice } from '../../../../hooks/useSendInvoice'
import { useUpdateInvoice } from '../../../../hooks/useUpdateInvoice'
import { useGetInvoicePreview } from '../../../hooks/useGetInvoicePreview'
import { InvoiceFormFields } from '../../../Invoice.types'
import { isInvoiceFormValid, isInvoiceScheduled } from '../../../Invoice.utils'
import { convertFormFieldsToUpdateInvoiceInput } from '../../InvoiceCreateForm/hooks/useInvoiceCreateFormHandlers.utils'
import { useUpdateInvoiceDeliverySettings } from '../../InvoiceCreateForm/hooks/useUpdateInvoiceDeliverySettings'

export const translations = {
  saveInvoiceError: translate('page.invoice.saveInvoiceError'),
  saveInvoiceInvalidEmailError: translate(
    'page.invoice.saveInvoiceInvalidEmailError'
  ),
  getSaveInvoiceSuccess: (referenceNumber: string) =>
    translate('page.invoice.saveInvoiceSuccess', {
      referenceNumber,
    }),
  sendInvoiceError: translate('page.invoice.sendInvoiceError'),
  sendInvoiceInvalidEmailError: translate(
    'page.invoice.sendInvoiceInvalidEmailError'
  ),
  getInvoicePreviewError: translate('page.invoice.getInvoicePreviewError'),
}

export const useInvoiceUpdateFormHandlers = (referenceNumber: string) => {
  const isUpdateFormDirty = useRef(false)

  const updateInvoiceDeliverySettings = useUpdateInvoiceDeliverySettings()

  const { trackSavedInvoice, trackSentInvoice } = useInvoiceAnalytics()

  const { getPreview, invoicePdf, isLoadingPreview, setInvoicePdf } =
    useGetInvoicePreview({
      onError: () => showErrorToast(translations.getInvoicePreviewError),
    })
  const { saveAndSendInvoice, isSavingAndSendingInvoice } =
    useSaveAndSendInvoice()
  const { updateInvoice, data, isUpdatingInvoice } = useUpdateInvoice()
  const { sendInvoice, isSendingInvoice } = useSendInvoice()
  const {
    isModalOpen: isSendSuccessModalOpen,
    openModal: openSendSuccessModal,
    closeModal: closeSendSuccessModal,
  } = useModalState()
  const {
    isModalOpen: isSendModalOpen,
    openModal: openSendModal,
    closeModal: closeSendModal,
  } = useModalState()
  const {
    isModalOpen: isScheduleModalOpen,
    openModal: openScheduleModal,
    closeModal: closeScheduleModal,
  } = useModalState()
  const {
    isModalOpen: isScheduleSuccessModalOpen,
    openModal: openScheduleSuccessModal,
    closeModal: closeScheduleSuccessModal,
  } = useModalState()

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const redirectToInvoices = () =>
    navigate(ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.path)

  const handleSaveInvoice = async (values: InvoiceFormFields) => {
    if (isUpdateFormDirty.current) {
      const response = await updateInvoice(
        convertFormFieldsToUpdateInvoiceInput(values)
      )

      if (!response.data?.updateInvoice) {
        showErrorToast(translations.saveInvoiceError)
        return false
      }

      trackSavedInvoice(values)
    }

    return true
  }

  const handlePostSaveInvoice = async (
    values: InvoiceFormFields,
    redirectUrl?: string
  ) => {
    const validated = await isInvoiceFormValid(values)
    const scheduled = isInvoiceScheduled(values)

    if (validated && scheduled && values.status === InvoiceStatus.DRAFT) {
      openScheduleModal()
    } else {
      showSuccessToast(translations.getSaveInvoiceSuccess(referenceNumber))

      if (
        values.status !== InvoiceStatus.DRAFT &&
        values.status !== InvoiceStatus.SCHEDULED
      ) {
        openSendModal()
      } else if (redirectUrl) {
        navigate(redirectUrl)
      }
    }
  }

  const handleSave = async (
    values: InvoiceFormFields,
    redirectUrl: string
  ): Promise<boolean> => {
    try {
      const result = await handleSaveInvoice(values)

      if (!result) {
        return false
      }

      await handlePostSaveInvoice(values, redirectUrl)

      return true
    } catch (err) {
      if (err instanceof ApolloError) {
        const hasInvalidEmail = /Invalid email/.test(err.message)

        if (hasInvalidEmail) {
          showErrorToast(translations.saveInvoiceInvalidEmailError)

          return false
        }
      }

      showErrorToast(translations.saveInvoiceError)
      return false
    }
  }

  const handleSendInvoice = async (
    referenceNumber: string,
    values: InvoiceFormFields
  ) => {
    if (isSendModalOpen || isScheduleModalOpen || !isUpdateFormDirty.current) {
      await sendInvoice(referenceNumber)

      trackSentInvoice(values)
    } else {
      const response = await saveAndSendInvoice(
        convertFormFieldsToUpdateInvoiceInput(values)
      )

      if (!response.data?.saveAndSendInvoice) {
        showErrorToast(translations.sendInvoiceError)
        return
      }

      trackSentInvoice(values)
    }
  }

  const handlePostSendInvoice = (values: InvoiceFormFields) => {
    if (isInvoiceScheduled(values)) {
      if (isScheduleModalOpen) {
        closeScheduleModal()
      }

      openScheduleSuccessModal()
    } else {
      if (isSendModalOpen) {
        closeSendModal()
      }

      openSendSuccessModal()
    }

    updateInvoiceDeliverySettings({
      isEmailEnabled: values.delivery.email.isEnabled,
      isSMSEnabled: values.delivery.sms.isEnabled,
    })
  }

  const handleSend = async (values: InvoiceFormFields): Promise<void> => {
    try {
      await handleSendInvoice(referenceNumber, values)
      handlePostSendInvoice(values)
    } catch (err) {
      if (err instanceof ApolloError) {
        const hasInvalidEmail = /Invalid email/.test(err.message)

        if (hasInvalidEmail) {
          showErrorToast(translations.sendInvoiceInvalidEmailError)
          return
        }
      }

      showErrorToast(translations.sendInvoiceError)
    }
  }

  const handleClickSettings = () => {
    navigate(ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.relative)
  }

  return {
    closePreviewModal: () => setInvoicePdf(null),
    closeScheduleModal,
    closeSendModal,
    closeSendSuccessModal,
    closeScheduleSuccessModal,
    handleClickPreview: getPreview,
    handleClickSettings,
    handleClose: redirectToInvoices,
    handleSave,
    handleSend,
    invoicePdf,
    isLoadingPreview,
    isScheduleModalOpen,
    isScheduleSuccessModalOpen,
    isSendingInvoice: isSavingAndSendingInvoice || isSendingInvoice,
    isSendModalOpen,
    isSendSuccessModalOpen,
    isUpdatingInvoice,
    openSendSuccessModal,
    openScheduleSuccessModal,
    redirectTo: navigate,
    referenceNumber: data?.referenceNumber ?? '',
    setIsUpdateFormDirty: (isDirty: boolean) => {
      isUpdateFormDirty.current = isDirty
    },
  }
}
