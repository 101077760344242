import { gql } from '@apollo/client'

import { PartialTransactionUpdateType } from 'hooks/transactions'
import { translate } from 'utils/translations'

import { EditTransactionTags } from './EditTransactionTags'
import {
  TagsDebitCardAccountTransactionFragment,
  TagsDebitCardTransactionV2Fragment,
} from './Tags.generated'
import { StyledTags, StyledTagsHeader, StyledTagsTitle } from './Tags.styled'
import { TagsDisplay } from './TagsDisplay'

interface TagsProps {
  transaction:
    | TagsDebitCardTransactionV2Fragment
    | TagsDebitCardAccountTransactionFragment
  updateTransaction: (update: PartialTransactionUpdateType) => void
  className?: string
}

export const Tags = ({
  transaction,
  updateTransaction,
  className,
}: TagsProps) => {
  const tags = transaction.tags || []

  return (
    <StyledTags className={className}>
      <StyledTagsHeader>
        <StyledTagsTitle>
          {translate('page.transactionDetails.tags.title')}
        </StyledTagsTitle>
        <EditTransactionTags
          initialTags={tags}
          transactionUuid={transaction.id}
          updateTransaction={updateTransaction}
          buttonLabel={
            tags.length ? translate('shared.edit') : translate('shared.add')
          }
        />
      </StyledTagsHeader>
      <TagsDisplay
        tags={tags}
        placeholder={translate('page.transactionDetails.tags.placeholder')}
      />
    </StyledTags>
  )
}

Tags.fragments = {
  DebitCardTransactionV2: gql`
    fragment TagsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
      tags
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment TagsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
      tags
    }
  `,
}
