import { CardIcon, COLOR } from '@npco/zeller-design-system'

import { StyledRadioCardIcon } from 'pages/GlobalModals/GlobalModals.styled'

export const PhysicalCardIcon = () => {
  return (
    <StyledRadioCardIcon color={COLOR.BLUE_1000}>
      <CardIcon />
    </StyledRadioCardIcon>
  )
}
