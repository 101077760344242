import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardStatus/useCardStatus.generated'

export type CardStatusDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
}

export const CardStatusDebitCardV2FragmentDoc = gql`
  fragment CardStatusDebitCardV2Fragment on DebitCardV2 {
    ...UseCardStatusDebitCardV2Fragment
  }
  ${UseCardStatusDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<CardStatusDebitCardV2Fragment, undefined>
