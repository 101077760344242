import React from 'react'
import { COLOR, IconProps, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'

export const ZellerLogo = ({
  width = '69',
  height = '24',
  color = COLOR.BLACK,
}: IconProps) => {
  return (
    <SvgIcon
      dataTestId="screen-saver-zeller-logo"
      width={width}
      height={height}
      color={color}
    >
      <LogoIcon data-testid="logo-zeller" />
    </SvgIcon>
  )
}
