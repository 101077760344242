import { ModalBasic } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import {
  MultiStageModal,
  useMultiStageModalProgressBar,
} from 'components/MultiStageModal'

import {
  SpendControlFields,
  useValidation,
} from '../../../../../components/SpendControlFields'
import { StyledForm } from '../../../../../GlobalModals.styled'
import { useSetSpendControlForm } from './hooks/useSetSpendControlForm'
import { SetSpendControlFormValues } from './SetSpendControlForm.types'

export const SetSpendControlForm = () => {
  const {
    accountId,
    initialValues,
    goBackToSetCardSettingsStage,
    submitSpendControlStage,
    isLoadingEntityAddressTimezone,
    entityAddressTimezone,
  } = useSetSpendControlForm()
  useMultiStageModalProgressBar({
    newCurrentStep: 7,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })

  const validationSchema = useValidation()

  return (
    <Formik<SetSpendControlFormValues>
      initialValues={initialValues}
      onSubmit={submitSpendControlStage}
      validationSchema={validationSchema}
    >
      <StyledForm data-testid="set-card-spend-control-stage">
        <ModalBasic.Body>
          <SpendControlFields
            isHeaderVisible
            accountId={accountId}
            timezone={entityAddressTimezone}
            isLoadingTimezone={isLoadingEntityAddressTimezone}
          />
        </ModalBasic.Body>
        <MultiStageModal.FormButtons
          onCancel={goBackToSetCardSettingsStage}
          nextButtonDisabled={isLoadingEntityAddressTimezone}
        />
      </StyledForm>
    </Formik>
  )
}
