import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { useFormikContext } from 'formik'

import { translationsShared } from 'translations'

import { RouteLeavingPromptGuard } from '../RouteLeavingPromptGuard'

type LeavingRoutePromptGuardProps = {
  resetForm?: () => void
  submitForm?: () => Promise<void>
  title: string
  message: string
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  dataTestId?: string
}

export const LeavingRoutePromptGuard = ({
  resetForm: resetFormOverride,
  submitForm: submitFormOverride,
  title,
  message,
  primaryButtonLabel,
  secondaryButtonLabel,
  dataTestId = 'leaving-route-prompt-guard',
}: LeavingRoutePromptGuardProps) => {
  const tShared = useTranslations(translationsShared)
  const {
    dirty,
    isSubmitting,
    resetForm: formikResetForm,
    submitForm: formikSubmitForm,
  } = useFormikContext()

  const [path, setPath] = useState<string>()

  const handleCancel = resetFormOverride ?? formikResetForm
  const submitForm = submitFormOverride ?? formikSubmitForm

  const navigate = useNavigate()

  const handleNavigate = useCallback((path) => navigate(path), [navigate])
  const shouldBlockNavigation = useCallback(() => dirty, [dirty])

  useEffect(() => {
    if (!isSubmitting && path) {
      handleNavigate(path)
    }
  }, [handleNavigate, isSubmitting, path])

  return (
    <RouteLeavingPromptGuard
      dataTestId={dataTestId}
      isLoading={isSubmitting}
      message={message}
      onCancel={handleCancel}
      onConfirm={submitForm}
      onNavigate={setPath}
      primaryButtonLabel={secondaryButtonLabel ?? tShared('discardChanges')}
      secondaryButtonLabel={primaryButtonLabel ?? tShared('save')}
      shouldBlockNavigation={shouldBlockNavigation}
      title={title}
      when={dirty}
    />
  )
}
