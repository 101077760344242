import {
  Flex,
  PopperItemOneLineIcon,
  PopperItemOneLineWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Wrapper = styled(Flex)`
  ${PopperItemOneLineWrapper} {
    justify-content: flex-start;
  }

  ${PopperItemOneLineIcon} {
    margin-right: 24px;
  }
`
