import { useMemo } from 'react'

import { useFetchUsers } from 'hooks/useFetchUsers'
import { isEmpty } from 'utils/isEmpty'

import { byName } from './UserList.utils'

export const useManageUsers = () => {
  const { loading, customers } = useFetchUsers()

  return useMemo(() => {
    if (loading || !customers) {
      return []
    }
    return (
      customers
        .filter(isEmpty)
        .filter((customer) => customer.role !== null)
        .sort(byName) ?? []
    )
  }, [customers, loading])
}
