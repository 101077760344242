import { gql } from '@apollo/client'
import {
  currencyFormatter,
  TERMS_CONDITIONS_URL,
} from '@npco/component-mp-common'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  AnchorBasic,
  Box,
  COLOR,
  Flex,
  Status,
  TickIcon,
} from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'
import { ListItem } from 'components/List'

import { useInterestRateDisplay } from './hooks/useInterestRateDisplay'
import { SavingsAccountDetailsDebitCardAccountV2Fragment } from './SavingsAccountDetails.generated'
import { savingsAccountDetailsTranslations } from './SavingsAccountDetails.i18n'

type SavingsAccountDetailsProps = {
  account: SavingsAccountDetailsDebitCardAccountV2Fragment
}

export const SavingsAccountDetails = ({
  account,
}: SavingsAccountDetailsProps) => {
  const t = useTranslations(savingsAccountDetailsTranslations)
  const { hasBonusInterestRate, interestRateBaseText, interestRateBonusText } =
    useInterestRateDisplay({ account })

  const interestRateText = hasBonusInterestRate ? (
    <Flex
      flexDirection={{ _: 'column', XS: 'row' }}
      lineHeight="24px"
      textAlign={{ _: 'end', XS: 'inherit' }}
    >
      <Box>{interestRateBaseText}</Box>
      <Box>
        {t('plusSign')} {interestRateBonusText}
      </Box>
    </Flex>
  ) : (
    interestRateBaseText
  )

  const isClosed = account.status === DebitCardAccountStatus.CLOSED

  return (
    <>
      <ListItem left={t('typeLabel')} right={t('typeValue')} />
      {!isClosed && (
        <>
          <ListItem left={t('interestRateLabel')} right={interestRateText} />
          {account.savingsAccountDetails?.bonusRateEndsAt &&
            hasBonusInterestRate && (
              <ListItem
                left={t('bonusRateEndsLabel')}
                right={dayjs(
                  account.savingsAccountDetails.bonusRateEndsAt
                ).format('D MMMM YYYY')}
              />
            )}
          {account.savingsAccountProduct?.interestThreshold?.value && (
            <ListItem
              left={t('interestThresholdLabel')}
              right={currencyFormatter(
                parseInt(
                  account.savingsAccountProduct?.interestThreshold?.value,
                  10
                )
              )}
            />
          )}
        </>
      )}
      <ListItem
        left={t('tfnLabel')}
        right={
          <Status
            color={COLOR.GREEN_1600}
            icon={<TickIcon color={COLOR.GREEN_1600} />}
            text={t('tfnSupplied')}
          />
        }
      />
      {!isClosed && (
        <Box pt="20px">
          <AnchorBasic href={TERMS_CONDITIONS_URL}>
            {t('termsApply')}
          </AnchorBasic>
        </Box>
      )}
    </>
  )
}

SavingsAccountDetails.fragments = {
  DebitCardAccountV2: gql`
    fragment SavingsAccountDetailsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      status
      savingsAccountDetails {
        bonusRateEndsAt
        tfnProvided
      }
      savingsAccountProduct {
        id
        interestThreshold {
          value
        }
      }

      ...UseInterestRateDisplayDebitCardAccountV2Fragment
    }

    ${useInterestRateDisplay.fragments.DebitCardAccountV2}
  `,
}
