import { Outlet } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { PageTemplate } from '@npco/zeller-design-system'

import { TransactionUpdates } from 'services/transactionUpdates/TransactionUpdates'

import { NewTransactionsPrimaryHeaderRow } from './NewPrimaryHeaderRow/NewTransactionsPrimaryHeaderRow'
import { NewTransactionsSecondaryHeaderRow } from './NewSecondaryHeaderRow/NewTransactionsSecondaryHeaderRow'
import { TransactionsSecondaryHeaderRow } from './TransactionSecondaryHeaderRow/TransactionsSecondaryHeaderRow'
import { TransactionsPrimaryHeaderRow } from './TransactionsPrimaryHeaderRow/TransactionsPrimaryHeaderRow'

const deps = {
  NewTransactionsPrimaryHeaderRow,
  NewTransactionsSecondaryHeaderRow,
  TransactionsSecondaryHeaderRow,
  TransactionsPrimaryHeaderRow,
  TransactionUpdates,
}
export { deps as TransactionsLayoutDeps }

export const TransactionsLayout = () => {
  const featureFlags = useFeatureFlags()
  return (
    <PageTemplate
      HeaderPrimaryRow={
        featureFlags.TransactionsFilterUplift ? (
          <deps.NewTransactionsPrimaryHeaderRow />
        ) : (
          <deps.TransactionsPrimaryHeaderRow />
        )
      }
      HeaderSecondaryRow={
        featureFlags.TransactionsFilterUplift ? (
          <deps.NewTransactionsSecondaryHeaderRow />
        ) : (
          <deps.TransactionsSecondaryHeaderRow />
        )
      }
    >
      <deps.TransactionUpdates>
        <Outlet />
      </deps.TransactionUpdates>
    </PageTemplate>
  )
}
