import { ModalBasic } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { StyledForm } from 'pages/GlobalModals/GlobalModals.styled'
import {
  MultiStageModal,
  useMultiStageModalProgressBar,
} from 'components/MultiStageModal'

import { useSetCardSettingsForm } from './hooks/useSetCardSettingsForm'
import { SetCardSettingsFields } from './SetCardSettingsFields/SetCardSettingsFields'
import { SetCardSettingsFormValues } from './SetCardSettingsForm.types'

export const SetCardSettingsForm = () => {
  const {
    submitCardSettingStage,
    goBackToSelectCardFormatStage,
    initialNickname,
    initialAccessibleCard,
  } = useSetCardSettingsForm()
  useMultiStageModalProgressBar({
    newCurrentStep: 6,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })

  return (
    <Formik<SetCardSettingsFormValues>
      initialValues={{
        nickname: initialNickname,
        isAccessibleCard: initialAccessibleCard,
      }}
      onSubmit={submitCardSettingStage}
    >
      <StyledForm data-testid="set-card-settings-stage">
        <ModalBasic.Body>
          <SetCardSettingsFields />
        </ModalBasic.Body>
        <MultiStageModal.FormButtons onCancel={goBackToSelectCardFormatStage} />
      </StyledForm>
    </Formik>
  )
}
