import { useTranslations } from '@npco/utils-translations'
import { COLOR, DownloadIcon, TickIcon } from '@npco/zeller-design-system'

import { useDownloadRemittance } from '../../hooks/useDownloadRemittance/useDownloadRemittance'
import * as styled from '../TransferRemittancesButtons.styled'
import { pdfRemittanceButtonTranslations } from './PdfRemittanceButton.i18n'

const DownloadPDFIcon = () => <DownloadIcon height="16px" width="16px" />
const CheckMarkIcon = () => <TickIcon color={COLOR.GREY_250} />

type PdfRemittanceButtonProps = {
  dcaTransactionUuid: string
  downloadCopy?: string
  downloadedCopy?: string
  padding?: string
}

export const PdfRemittanceButton = ({
  dcaTransactionUuid,
  downloadCopy,
  downloadedCopy,
  padding,
}: PdfRemittanceButtonProps) => {
  const t = useTranslations(pdfRemittanceButtonTranslations)

  const {
    downloadPdfRemittance,
    isLoadingPdfRemittance,
    isDownloadingFile,
    isDownloaded,
  } = useDownloadRemittance({ dcaTransactionUuid })

  if (isDownloaded) {
    return (
      <styled.ActionButtonWithIcon
        icon={CheckMarkIcon}
        disabled
        $padding={padding}
      >
        {downloadedCopy ?? t('downloaded')}
      </styled.ActionButtonWithIcon>
    )
  }

  return (
    <styled.ActionButtonWithIcon
      icon={DownloadPDFIcon}
      isLoading={isLoadingPdfRemittance || isDownloadingFile}
      onClick={downloadPdfRemittance}
      $padding={padding}
    >
      {downloadCopy ?? t('download')}
    </styled.ActionButtonWithIcon>
  )
}
