import { TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

export const InvoiceIdRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const isLoading = !row?.original || table.options.meta?.isLoading
  const value = isLoading ? undefined : getValue<string>()

  return (
    <TableCellText
      dataTestId={`invoice-table-row-${row.index}-id`}
      forwardedProps={{ style: { padding: 0 } }}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width: '100%' }}
      text={value}
    />
  )
}
