import { useReactiveVar } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox } from '@npco/zeller-design-system'
import { rvSelectedStatuses } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { REPORTS_TRANSACTION_STATUSES } from 'const/common'
import { PickerItems } from 'types/picker'
import { ChartData } from 'components/Charts/Charts.types'
import { SpinnerWrapped } from 'components/Spinner'

import { translations } from '../Reports.i18n'
import { TransactionTotalsSum } from '../Reports.types'
import { ReportsChart } from '../ReportsCharts/ReportsChart'
import { ReportsHighlights } from '../ReportsHighlights/ReportsHighlights'
import { ReportsTable } from '../ReportsTable/ReportsTable'
import { StyledContentWrapper } from './ReportsContent.styled'

interface Props {
  isLoading: boolean
  hasNoResults: boolean
  hasNoInitialResults: boolean
  totalSaleChartData: ChartData
  timeOfDayChartData: ChartData
  dayOfWeekChartData: ChartData
  totalDeclinedChartData: ChartData
  timeOfDayDeclinedChartData: ChartData
  dayOfWeekDeclinedChartData: ChartData
  transactionsSum: TransactionTotalsSum
  isSelectionMoreThenTwoMonths: boolean
}
const checkIfOnlyDeclinedSelected = (selectedStatus: PickerItems) =>
  selectedStatus.length === 1 &&
  selectedStatus.includes(REPORTS_TRANSACTION_STATUSES[1])

export const ReportsContent = ({
  isLoading,
  hasNoResults,
  hasNoInitialResults,
  transactionsSum,
  totalSaleChartData,
  timeOfDayChartData,
  dayOfWeekChartData,
  totalDeclinedChartData,
  timeOfDayDeclinedChartData,
  dayOfWeekDeclinedChartData,
  isSelectionMoreThenTwoMonths,
}: Props) => {
  const selectedStatus = useReactiveVar(rvSelectedStatuses)
  const shouldShowDeclinedData = checkIfOnlyDeclinedSelected(selectedStatus)

  const t = useTranslations(translations)

  if (isLoading) {
    return <SpinnerWrapped variant="centre" />
  }

  return (
    <StyledContentWrapper>
      {hasNoResults && (
        <Box paddingBottom="24px">
          <InfoBox>
            <InfoBox.Message>{t('noData')}</InfoBox.Message>
          </InfoBox>
        </Box>
      )}

      <ReportsHighlights
        total={
          shouldShowDeclinedData
            ? transactionsSum.declinedTotal
            : transactionsSum.total
        }
        salesCount={transactionsSum.salesCount}
        refundsCount={transactionsSum.refundsCount}
        shouldShowCounts={!shouldShowDeclinedData}
      />

      <ReportsChart
        hasNoInitialResults={hasNoInitialResults}
        totalSaleChartData={
          shouldShowDeclinedData ? totalDeclinedChartData : totalSaleChartData
        }
        timeOfDayChartData={
          shouldShowDeclinedData
            ? timeOfDayDeclinedChartData
            : timeOfDayChartData
        }
        dayOfWeekChartData={
          shouldShowDeclinedData
            ? dayOfWeekDeclinedChartData
            : dayOfWeekChartData
        }
        isSelectionMoreThenTwoMonths={isSelectionMoreThenTwoMonths}
      />
      {!shouldShowDeclinedData && (
        <ReportsTable
          fees={transactionsSum.fees}
          collected={transactionsSum.collected}
          surcharge={transactionsSum.surcharge}
          tip={transactionsSum.tip}
          tax={transactionsSum.tax}
          refunds={transactionsSum.refunds}
          // totalAmount is now used for the subtotal on the reports page
          subtotal={transactionsSum.total}
        />
      )}
    </StyledContentWrapper>
  )
}
