import { TOTAL_LINK_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useGetDebitCardAccounts } from '../../../../hooks/useGetDebitCardAccounts'
import { SearchableAccountForm } from '../../../shared/SearchableAccountForm/SearchableAccountForm'
import { useSelectAccountForm } from './hooks/useSelectAccountForm'

interface SelectAccountFormProps {
  dataTestId?: string
}

export const SelectAccountForm = ({ dataTestId }: SelectAccountFormProps) => {
  useMultiStageModalProgressBar({
    newCurrentStep: 3,
    newTotalSteps: TOTAL_LINK_CARD_STAGES,
  })
  const { accounts, isAccountsLoading, hasError, refetch } =
    useGetDebitCardAccounts()
  const {
    initialAccountId,
    goToLinkCardIdStage,
    selectAccount,
    isActivatingCard,
  } = useSelectAccountForm()

  return (
    <SearchableAccountForm
      accounts={accounts}
      isAccountsLoading={isAccountsLoading}
      hasError={hasError}
      refetch={refetch}
      dataTestId={dataTestId}
      initialAccountId={initialAccountId}
      onBack={goToLinkCardIdStage}
      selectAccount={selectAccount}
      isConfirming={isActivatingCard}
    />
  )
}
