import { ButtonGhost } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledGhostButtonFullWidth = styled(ButtonGhost)`
  border-radius: 7px;
  width: 100%;

  &:hover {
    border: 1px solid transparent;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.GREY_250};
    cursor: not-allowed;
  }
`

export const StyledTryAgainButton = styled(StyledGhostButtonFullWidth)`
  width: fit-content;
`
