import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  ButtonLink,
  COLOR,
  Flex,
  PageTemplate,
  SvgIcon,
  TooltipBasic,
  Typography,
  useModalState,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { translationsShared } from 'translations'

import { ColourList } from '../ColourList/ColourList'
import { LogoList } from '../LogoList/LogoList'
import { TerminalPreview } from '../TerminalPreview/TerminalPreview'
import { TerminalPreviewModal } from '../TerminalPreviewModal/TerminalPreviewModal'
import { TerminalSelector } from '../TerminalSelector/TerminalSelector'
import { translations } from './SiteAppearanceForm.i18n'
import styles from './SiteAppearanceForm.module.css'
import { SiteAppearanceFormValues } from './SiteAppearanceForm.types'

export const SiteAppearanceForm = () => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { resetForm, submitForm } = useFormikContext<SiteAppearanceFormValues>()
  const { isModalOpen, openModal, closeModal } = useModalState()

  return (
    <>
      <PageTemplate.Section>
        <Flex mb="24px" justifyContent="space-between">
          <Typography component="h3" variant="heading-xl">
            {t('screensaver')}
          </Typography>
          <div className={styles.PreviewButton}>
            <ButtonLink onClick={openModal}>{t('preview')}</ButtonLink>
          </div>
        </Flex>
        <Box mb="24px">
          <TerminalSelector />
        </Box>
        <Box mb="24px">
          <Flex mb="16px" alignItems="center" gap="8px">
            <Typography component="h4" variant="heading-lg">
              {t('logo')}
            </Typography>
            <TooltipBasic
              renderTrigger={({ handlers }) => (
                <span {...handlers}>
                  <SvgIcon width="16" height="16" color={COLOR.GREY_550}>
                    <InfoIcon />
                  </SvgIcon>
                </span>
              )}
            >
              {t('logoTooltip')}
            </TooltipBasic>
          </Flex>
          <LogoList />
        </Box>
        <Box mb="40px">
          <Flex mb="16px" alignItems="center" gap="8px">
            <Typography component="h4" variant="heading-lg">
              {t('colour')}
            </Typography>
            <TooltipBasic
              renderTrigger={({ handlers }) => (
                <span {...handlers}>
                  <SvgIcon width="16" height="16" color={COLOR.GREY_550}>
                    <InfoIcon />
                  </SvgIcon>
                </span>
              )}
            >
              {t('colourTooltip')}
            </TooltipBasic>
          </Flex>
          <ColourList />
        </Box>
        <Flex gap="24px">
          <Box width="156px">
            <ButtonFill
              size={BUTTON_SIZE.MEDIUM}
              onClick={submitForm}
              fullWidth
            >
              {tShared('save')}
            </ButtonFill>
          </Box>
          <Box width="156px">
            <ButtonGhost
              size={BUTTON_SIZE.MEDIUM}
              onClick={() => resetForm()}
              fullWidth
            >
              {tShared('cancel')}
            </ButtonGhost>
          </Box>
        </Flex>
      </PageTemplate.Section>
      <PageTemplate.Section>
        {!isModalOpen ? (
          <div className={styles.Preview}>
            <TerminalPreview />
          </div>
        ) : (
          <TerminalPreviewModal onClose={closeModal} />
        )}
      </PageTemplate.Section>
    </>
  )
}
