import React, { createContext } from 'react'

export interface DrawerNavigationContextType {
  changeMode: 'next' | 'previous'
  innerSelectPrevious: () => void
  innerSelectNext: () => void
  drawerRef: React.RefObject<HTMLButtonElement> | null
  drawerContentContainerRef: React.RefObject<HTMLDivElement> | null
  onPrevious?: () => void
  onNext?: () => void
  isNavigationDisabled: boolean
  setIsNavigationDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

export const DrawerNavigationContext =
  createContext<DrawerNavigationContextType>({
    changeMode: 'next',
    innerSelectPrevious: () => undefined,
    innerSelectNext: () => undefined,
    drawerRef: null,
    drawerContentContainerRef: null,
    onPrevious: undefined,
    onNext: undefined,
    isNavigationDisabled: false,
    setIsNavigationDisabled: () => undefined,
  })
