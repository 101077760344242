import { useMemo } from 'react'
import { Column, Row } from '@tanstack/react-table'

import { ExtendedColumnDef } from '../Table.types'

interface UseExtendedColumnConfigProps<T> {
  row?: Row<T>
  column: Column<T>
}

const DEFAULT_SKELETON_WIDTH = '100%'

export const useExtendedColumnConfig = <T>({
  row,
  column,
}: UseExtendedColumnConfigProps<T>) => {
  const columnDefValue = column.columnDef as ExtendedColumnDef<T>
  const skeletonLoaderWidth = useMemo(() => {
    const cellSkeletonWidth = columnDefValue?.cellSkeletonWidth

    if (!cellSkeletonWidth) {
      return DEFAULT_SKELETON_WIDTH
    }

    if (!Array.isArray(cellSkeletonWidth)) {
      return cellSkeletonWidth
    }

    if (cellSkeletonWidth.length === 0) {
      return DEFAULT_SKELETON_WIDTH
    }

    if (!row) {
      return DEFAULT_SKELETON_WIDTH
    }

    return (
      cellSkeletonWidth[row.index % cellSkeletonWidth.length] ??
      DEFAULT_SKELETON_WIDTH
    )
  }, [columnDefValue, row])

  return {
    skeletonLoaderWidth,
    cellSize: columnDefValue.cellSize,
  }
}
