import { ApolloError, gql } from '@apollo/client'

import { ContactContent } from 'pages/DebitCardTransactions/TransactionDrawer/TransactionDrawerContent/TransactionContactDetails/TransactionContact/ContactContent'
import { ContactContentSkeleton } from 'pages/DebitCardTransactions/TransactionDrawer/TransactionDrawerContent/TransactionContactDetails/TransactionContact/ContactContentSkeleton'

import { CashflowContactErrorPage } from '../CashflowContactErrorPage/CashflowContactErrorPage'
import { ContactDisplayContactFragment } from './ContactDisplay.generated'

type ContactDisplayProps = {
  isLoading: boolean
  error: ApolloError | undefined
  refetch: () => void
  contact: ContactDisplayContactFragment | null
}

export const ContactDisplay = ({
  isLoading,
  error,
  refetch,
  contact,
}: ContactDisplayProps) => {
  if (isLoading) {
    return <ContactContentSkeleton />
  }

  if (error) {
    return <CashflowContactErrorPage retry={refetch} />
  }

  return <ContactContent contact={contact} />
}

ContactDisplay.fragments = {
  Contact: gql`
    fragment ContactDisplayContactFragment on Contact {
      ...ContactContentContactFragment
    }
    ${ContactContent.fragments.Contact}
  `,
}
