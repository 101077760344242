import { TRANSACTION_REFUND_TYPE, TRANSACTION_STATUSES } from 'const/common'
import { PickerItems } from 'types/picker'
import { TransactionFilterStatus } from 'types/transactions'

export const shouldOptionDisabled = (
  status: TransactionFilterStatus,
  selectedTypes: PickerItems
) =>
  status === TransactionFilterStatus.REFUNDED &&
  selectedTypes.includes(TRANSACTION_REFUND_TYPE)

export const shouldAllChecked = (
  selectedTypes: PickerItems,
  selectedStatuses: PickerItems
) => {
  if (selectedTypes.includes(TRANSACTION_REFUND_TYPE)) {
    return selectedStatuses.length === TRANSACTION_STATUSES.length - 1
  }
  return selectedStatuses.length === TRANSACTION_STATUSES.length
}
