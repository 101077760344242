import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'

import { parseApiMoney } from 'utils/parseMoney'

import {
  getIsOutgoingTransaction,
  getTransactionAmountColorByStatus,
} from '../DebitCardTransactions.utils'
import {
  AmountCellDebitCardAccountTransactionFragment,
  AmountCellDebitCardTransactionV2Fragment,
} from './AmountCell.generated'
import {
  StyledAmountColumn,
  StyledAmountItem,
  StyledTransactionAmount,
} from './AmountCell.styled'

interface AmountCellProps {
  transaction:
    | AmountCellDebitCardTransactionV2Fragment
    | AmountCellDebitCardAccountTransactionFragment
  showMobileStyle?: boolean
}

export const AmountCell = ({
  transaction,
  showMobileStyle = false,
}: AmountCellProps) => {
  const { amount } = transaction
  const parsedAmount = amount ? parseApiMoney(amount.value) : 0

  const isOutgoingTransaction = getIsOutgoingTransaction(transaction)
  const formattedAmount = currencyFormatter(parsedAmount)

  return (
    <StyledAmountColumn $showMobileStyle={showMobileStyle}>
      <StyledAmountItem $showMobileStyle={showMobileStyle}>
        <StyledTransactionAmount
          color={getTransactionAmountColorByStatus(transaction)}
          $showMobileStyle={showMobileStyle}
        >
          {isOutgoingTransaction ? `-${formattedAmount}` : formattedAmount}
        </StyledTransactionAmount>
      </StyledAmountItem>
    </StyledAmountColumn>
  )
}

AmountCell.fragments = {
  DebitCardTransactionV2: gql`
    fragment AmountCellDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      ...GetIsOutgoingTransactionDebitCardTransactionV2Fragment
      ...GetTransactionAmountColorByStatusDebitCardTransactionV2Fragment
      amount {
        value
      }
    }

    ${getIsOutgoingTransaction.fragments.DebitCardTransactionV2}
    ${getTransactionAmountColorByStatus.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment AmountCellDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      ...GetIsOutgoingTransactionDebitCardAccountTransactionFragment
      ...GetTransactionAmountColorByStatusDebitCardAccountTransactionFragment
      amount {
        value
      }
    }

    ${getIsOutgoingTransaction.fragments.DebitCardAccountTransaction}
    ${getTransactionAmountColorByStatus.fragments.DebitCardAccountTransaction}
  `,
}
