import {
  combineValidators,
  validateIsInteger,
  validateMaxValue,
} from 'utils/formValidation'
import { errorMessages } from 'translations'

const MAX_TIP_VALUE = 99
const errorMessage = errorMessages.invalidTip

export const validateTip = combineValidators(
  validateIsInteger(errorMessage),
  validateMaxValue(MAX_TIP_VALUE, errorMessage)
)
