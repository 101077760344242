import { gql, type TypedDocumentNode } from '@apollo/client'

export type IncomeDatapointCashFlowPeriodicTotalAmountFragment = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  transactionDirectionTotalAmounts: {
    __typename?: 'TransactionDirectionTotalAmounts'
    noOfIncome: number
    income: { __typename?: 'Money'; value: string }
  }
}

export const IncomeDatapointCashFlowPeriodicTotalAmountFragmentDoc = gql`
  fragment IncomeDatapointCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
    transactionDirectionTotalAmounts {
      noOfIncome
      income {
        value
      }
    }
  }
` as unknown as TypedDocumentNode<
  IncomeDatapointCashFlowPeriodicTotalAmountFragment,
  undefined
>
