import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'
import { ApiCallType, useRedirectToMFA } from 'features/MFA'

import { AuthScope } from 'types/auth'
import { useBPayTransferModal } from 'pages/Transfer/BPay/bpay-transfer-routing'
import { TransactionDetails } from 'pages/Transfer/BPay/bpay-transfer-routing/BPayTransfer.types'
import { useCommonSubmitBPayTransfer } from 'pages/Transfer/hooks/useSendBpayTransfer/useCommonSubmitBPayTransfer'
import { bPayTransferTranslations } from 'pages/Transfer/Transfer.i18n'
import {
  BPayTransferResponseType,
  TransferBpayFields,
} from 'pages/Transfer/Transfer.types'

import { convertToBPayFieldsType } from './useSubmitBPayTransfer.utils'

export const useSubmitBpayTransfer = (values: TransactionDetails) => {
  const { openBPayTransferModal } = useBPayTransferModal()
  const convertedBpayFields: TransferBpayFields =
    convertToBPayFieldsType(values)

  const { redirectToMFA } = useRedirectToMFA()
  const tShared = useTranslations(bPayTransferTranslations)
  const { handleClick, isDisabled, isLoading } = useCommonSubmitBPayTransfer({
    values: convertedBpayFields,
    onSuccess: (result: BPayTransferResponseType) => {
      openBPayTransferModal(
        {
          stage: 'complete',
          transferDetails: values,
          transactionID: result.id,
        },
        { replace: true }
      )
    },
    onError: () => {
      showErrorToast(tShared('transferFailed'))
    },
    onMFA: () => {
      redirectToMFA({
        apiCallType: ApiCallType.BpayTransfer,
        scope: AuthScope.SENSITIVE,
        values,
      })
    },
  })

  return {
    handleSubmit: handleClick,
    isDisabled,
    isLoading,
  }
}
