import { UpgradeAccountsCompleteStage } from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'
import { Condition } from 'components/Condition/Condition'

import { useUpgradeAccountsCompleteState } from '../../hooks/useUpgradeAccountsCompleteState'
import { UpgradeAccountsCompleteStartStageContent } from './UpgradeAccountsCompleteStartStageContent/UpgradeAccountsCompleteStartStageContent'

export const UpgradeAccountsCompleteStartStage = () => {
  const { state } = useUpgradeAccountsCompleteState()
  return (
    <Condition
      shouldShow={
        state?.stage ===
        UpgradeAccountsCompleteStage.UpgradeAccountsCompleteStartStage
      }
    >
      <UpgradeAccountsCompleteStartStageContent />
    </Condition>
  )
}
