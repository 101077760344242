import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  AnnouncementModal,
  AnnouncementModalBodyContent,
  AnnouncementModalBodyDescription,
  Flex,
  Funds,
  Heading,
  ProgressIndicator,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useGoToSavingsAccountLearnMore } from 'features/SavingsAccountLearnMore/savings-account-learn-more-routing'

import { translations } from 'translations/shared.i18n'

import { TOTAL_STEPS } from '../SavingsAccountLearnMoreModal.utils'
import { savingsStageTranslations } from './SavingsStage.i18n'

type SavingsStageProps = {
  interestRate: string
  canCreateAccount: boolean | null
}

export const SavingsStage = ({
  interestRate,
  canCreateAccount,
}: SavingsStageProps) => {
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? 'medium' : 'large'

  const t = useTranslations(savingsStageTranslations)
  const tShared = useTranslations(translations)

  const { goToSavingsAccountLearnMore } = useGoToSavingsAccountLearnMore()

  const goToReadyToGoStage = useCallback(() => {
    goToSavingsAccountLearnMore({
      stage: 'ready-to-go',
      interestRate,
      canCreateAccount,
    })
  }, [canCreateAccount, goToSavingsAccountLearnMore, interestRate])

  const goBackToInterestEarningStage = useCallback(() => {
    goToSavingsAccountLearnMore({
      stage: 'interest-earning',
      interestRate,
      canCreateAccount,
    })
  }, [canCreateAccount, goToSavingsAccountLearnMore, interestRate])

  const closeModal = useCallback(() => {
    goToSavingsAccountLearnMore(null)
  }, [goToSavingsAccountLearnMore])

  return (
    <AnnouncementModal
      isOpen
      height={isMobile ? 'auto' : '528px'}
      onClickPrimary={goToReadyToGoStage}
      primaryButtonLabel={tShared('next')}
      onClickSecondary={goBackToInterestEarningStage}
      secondaryButtonLabel={tShared('back')}
      onCancel={closeModal}
    >
      <Funds size={illustrationSize} variant="outlined-green" />
      <AnnouncementModalBodyContent>
        <Heading.H2 dataTestId="announcement-modal-heading">
          {t('title')}
        </Heading.H2>
        <AnnouncementModalBodyDescription data-testid="announcement-modal-description">
          {t('description')}
        </AnnouncementModalBodyDescription>
      </AnnouncementModalBodyContent>
      <Flex
        justifyContent="center"
        width="100%"
        mt="auto"
        pt={{ _: '16px', XS: '8px' }}
      >
        <ProgressIndicator currentStep={3} totalStepCount={TOTAL_STEPS} />
      </Flex>
    </AnnouncementModal>
  )
}
