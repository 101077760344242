import { useTranslations } from '@npco/utils-translations'
import { useFormikContext } from 'formik'

import { CrnField } from 'pages/Transfer/TransferFields/fields/CRN'
import { Nickname } from 'pages/Transfer/TransferFields/fields/Nickname'
import { ModalFormScrollable } from 'components/ModalFormScrollable/ModalFormScrollable'
import { translationsShared } from 'translations'

import { bpayDetailsFieldTranslations } from '../../TransferFields/fields/BpayDetails/BpayDetailsField.i18n'
import { BillerCodedSearchField } from './components/BillerCodeSearchField/BillerCodeSearchField'
import { ContactInfoBox } from './components/ContactInfoBox/ContactInfoBox'

interface AddBpayDetailsFormProps {
  isModalOpen: boolean
  closeModal: () => void
  contactName?: string
}
export const AddBpayDetailsForm = ({
  isModalOpen,
  closeModal,
  contactName,
}: AddBpayDetailsFormProps) => {
  const t = useTranslations(bpayDetailsFieldTranslations)
  const tShared = useTranslations(translationsShared)

  const { submitForm, handleBlur } = useFormikContext()

  return (
    <ModalFormScrollable
      cancelLabel={tShared('cancel')}
      confirmLabel={tShared('done')}
      isOpen={isModalOpen}
      onCancel={closeModal}
      onSave={submitForm}
      title={t('addBpayDetails')}
    >
      <BillerCodedSearchField />
      <CrnField fieldName="crnNew" onBlur={handleBlur} />
      <Nickname fieldName="nicknameNew" onBlur={handleBlur} />
      {contactName && <ContactInfoBox contactName={contactName} />}
    </ModalFormScrollable>
  )
}
