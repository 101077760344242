import { useState } from 'react'
import {
  CashflowFiltersHeader,
  CashflowReportingTransactionsProvider,
  NoAccountBanner,
  NoTransactionsBanner,
  ReportsHeaderPrimaryRow,
  SelectedDateRangeProvider,
  TransactionsSummary,
  useHasActiveAccountOrTransactions,
} from '@npco/mp-feature-cashflow-reporting'
import { PageTemplate } from '@npco/zeller-design-system'

import { useGetEntityAddressTimezone } from 'hooks/useGetEntityAddressTimezone'

import { ContactDrawer } from './ContactDrawer'
import { TotalIncomeAndExpense } from './TotalIncomeAndExpense/TotalIncomeAndExpense'

export const CashflowReporting = () => {
  const { isLoading, timezone } = useGetEntityAddressTimezone()

  const [contactUuid, setContactUuid] = useState<string | null>(null)

  const {
    hasActiveAccounts,
    hasTransactions,
    hasNoActiveAccounts,
    hasNoTransactions,
  } = useHasActiveAccountOrTransactions()

  const hasActiveAccountAndTransaction = hasActiveAccounts && hasTransactions

  return (
    <SelectedDateRangeProvider>
      <PageTemplate
        HeaderPrimaryRow={
          <PageTemplate.HeaderPrimaryRow>
            <ReportsHeaderPrimaryRow />
          </PageTemplate.HeaderPrimaryRow>
        }
        HeaderSecondaryRow={
          hasActiveAccountAndTransaction && (
            <PageTemplate.HeaderSecondaryRow
              mt={{ _: '16px', XS: '0px' }}
              mb={{ _: '16px', MD: '0px' }}
              position="relative"
            >
              <CashflowFiltersHeader entityTimezone={timezone} />
            </PageTemplate.HeaderSecondaryRow>
          )
        }
      >
        {hasNoTransactions && <NoTransactionsBanner />}
        {hasNoActiveAccounts && <NoAccountBanner />}
        {hasActiveAccountAndTransaction && (
          <>
            <TotalIncomeAndExpense
              isLoadingTimezone={isLoading}
              entityTimezone={timezone}
            />
            <CashflowReportingTransactionsProvider>
              <TransactionsSummary
                setContactUuid={setContactUuid}
                isLoadingTimezone={isLoading}
                timezone={timezone}
              />
              <ContactDrawer
                contactUuid={contactUuid}
                onClose={() => setContactUuid(null)}
                timezone={timezone}
              />
            </CashflowReportingTransactionsProvider>
          </>
        )}
      </PageTemplate>
    </SelectedDateRangeProvider>
  )
}
