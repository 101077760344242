import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { isEmpty } from 'lodash-es'

import { billerCodeSearchInputTranslations } from 'pages/Transfer/BPay/AddBpayDetailsModal/components/BillerCodeSearchInput/BillerCodeSearchInput.i18n'
import { bpayTransferFieldsTranslations } from 'pages/Transfer/TransferFields/fields/BpayTransferFields/BpayTransferFields.i18n'

export const useBillerCodeSearchFieldValidate = (
  disabledBillers: { billerCode: string }[]
) => {
  const t = useTranslations(billerCodeSearchInputTranslations)
  const tBpay = useTranslations(bpayTransferFieldsTranslations)

  return useCallback(
    (value: string | undefined): string | undefined => {
      if (isEmpty(value)) {
        return t('errorRequired')
      }

      if (disabledBillers.some(({ billerCode }) => billerCode === value)) {
        return tBpay('billerNotSupported')
      }

      return undefined
    },
    [t, tBpay, disabledBillers]
  )
}
