import { useCallback, useState } from 'react'
import { Box, COLOR, SvgIcon } from '@npco/zeller-design-system'
import { Circle } from '@react-google-maps/api'

import MapPlaceholder from 'assets/images/map-placeholder.png'
import { ReactComponent as Placeholder } from 'assets/svg/marker.svg'
import { StaticMap } from 'components/StaticMap/StaticMap'

import {
  StyledImage,
  StyledMapWrapper,
  StyledSvgWrapper,
} from './AddressMap.styled'
import { useLocationAccuracy } from './hooks/useLocationAccuracy'

export const MAP_DATA_TESTID = 'contact-map-section'

interface AddressMapProp {
  location: string | null
  locationAccuracy?: number | null
}

export const AddressMap = ({ location, locationAccuracy }: AddressMapProp) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [circle, setCircle] = useState<google.maps.Circle | null>(null)

  const { isMapMarkVisible, mapLocation, mapCircleRadius } =
    useLocationAccuracy({
      skip: !map,
      location,
      locationAccuracy,
    })

  const fitCircleBounds = useCallback(() => {
    const bounds = circle?.getBounds()
    if (bounds) {
      map?.fitBounds(bounds, 0)
    }
  }, [circle, map])

  const onCircleLoaded = useCallback(
    (data: google.maps.Circle) => {
      setCircle(data)
      const bounds = data.getBounds()
      if (bounds) {
        map?.fitBounds(bounds, 0)
      }
    },
    [setCircle, map]
  )

  if (!mapLocation) {
    return (
      <StyledMapWrapper
        data-testid={MAP_DATA_TESTID}
        // NOTE: Disable right click for maps placeholder
        onContextMenu={(e) => e.preventDefault()}
      >
        <StyledSvgWrapper>
          <SvgIcon>
            <Placeholder />
          </SvgIcon>
        </StyledSvgWrapper>
        <StyledImage src={MapPlaceholder} />
      </StyledMapWrapper>
    )
  }

  return (
    <Box data-testid={MAP_DATA_TESTID} height="11.25rem" mb="1.5rem">
      <StaticMap
        location={mapLocation}
        onGoogleMapLoad={setMap}
        shouldHideIcons
        shouldHideMarker={!isMapMarkVisible}
      >
        {!!mapCircleRadius && (
          <Circle
            data-testid={`${MAP_DATA_TESTID}-circle`}
            center={mapLocation}
            radius={mapCircleRadius}
            onRadiusChanged={fitCircleBounds}
            onLoad={onCircleLoaded}
            options={{
              clickable: false,
              strokeColor: COLOR.BLUE_1000,
              strokeWeight: 1,
              fillColor: COLOR.BLUE_1000,
              fillOpacity: 0.16,
            }}
          />
        )}
      </StaticMap>
    </Box>
  )
}
