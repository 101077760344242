import styled from 'styled-components/macro'

export const StyledAccountName = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0;
  padding: 0;
`

export const StyledAccountBalance = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.GREY_550};
`
