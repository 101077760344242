import { FocusEvent, KeyboardEvent, useRef, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useField } from 'formik'

import { KEY_CODE } from 'const/keyboard'

const SET_EMAIL_VALUE_KEY_CODES = [KEY_CODE.TAB, KEY_CODE.ENTER, KEY_CODE.SPACE]

export const useInvoiceEmailInputField = (name: string) => {
  const [field, , helpers] = useField<string[]>(name)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [contactTypeToCreate, setContactTypeToCreate] =
    useState<ContactType | null>(null)

  const shouldIgnoreInputBlurEvent = useRef(false)

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false)
    field.onBlur(event)

    if (shouldIgnoreInputBlurEvent.current) {
      return
    }

    if (event.target.value) {
      helpers.setValue(field.value.concat(event.target.value))
    }

    setInputValue('')
  }

  const handleOnFocus = () => setIsFocused(true)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    if (SET_EMAIL_VALUE_KEY_CODES.includes(event.key as KEY_CODE) && value) {
      helpers.setValue(field.value.concat(value))
      setInputValue('')
      event.preventDefault()
      return
    }

    if (event.key === KEY_CODE.BACKSPACE && !event.currentTarget.value) {
      helpers.setValue(field.value.slice(0, field.value.length - 1))
    }
  }

  const handleRemoveEmail = (emailToRemove: string) =>
    helpers.setValue(field.value.filter((email) => email !== emailToRemove))

  const handleOnChange = (selectedItemValue: string) => {
    helpers.setValue(field.value.concat(selectedItemValue))
    setInputValue('')
  }

  const handleMouseEnter = () => {
    shouldIgnoreInputBlurEvent.current = true
  }

  const handleMouseLeave = () => {
    shouldIgnoreInputBlurEvent.current = false
  }

  return {
    contactTypeToCreate,
    emailValues: field.value,
    handleMouseEnter,
    handleMouseLeave,
    handleRemoveEmail,
    handleKeyDown,
    handleOnBlur,
    handleOnChange,
    handleOnFocus,
    handleOnInputChange: setInputValue,
    inputValue,
    isFocused,
    setContactTypeToCreate,
  }
}
