import { useTranslations } from '@npco/utils-translations'

import { EmptyStateMessageOnly } from 'components/EmptyState/EmptyStateMessageOnly'
import { NoResults } from 'components/Placeholders/NoResults'
import { component } from 'translations'

import { translations } from './DepositsEmptyState.i18n'

interface Props {
  hasNoInitialDeposits: boolean
}

export const DepositsEmptyState = ({ hasNoInitialDeposits }: Props) => {
  const t = useTranslations(translations)

  return hasNoInitialDeposits ? (
    <EmptyStateMessageOnly
      title={t('title')}
      description={t('description')}
      illustrationName="Funds"
    />
  ) : (
    <NoResults
      title={component.noResults.titleDeposits}
      description={component.noResults.descDeposits}
    />
  )
}
