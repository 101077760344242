import { useEffect, useMemo, useState } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { Contacts, NEW_ITEM_VALUE } from '@npco/zeller-design-system'
import { getContactsInputComboboxItems } from 'features/Contacts/Contacts.utils'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { setIn, useFormikContext } from 'formik'
import { omit } from 'ramda'

import { translate } from 'utils/translations'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'

import {
  getCustomerFormValues,
  getCustomerSelectedItem,
  isCustomerFieldsDisabled,
} from '../InvoiceCustomerAccordion.utils'
import { useAttentionToField } from './useAttentionToField'

export const translations = {
  getValidationErrorMessages: (contactType?: ContactType) => ({
    CONTACT_REQUIRED: '',
    CONTACT_SELECTION_REQUIRED: translate(
      contactType === ContactType.BUSINESS
        ? 'page.invoice.formSections.customer.personSelectionRequired'
        : 'page.invoice.formSections.customer.businessSelectionRequired'
    ),
  }),
}

export const useAttentionToContactCombobox = (payerId: string) => {
  const { readFromCache, writeToCache } = useContactCache()

  const {
    initialValues: initialFormValues,
    setValues,
    values: {
      customer: { attentionToContact, payerContact },
      status,
    },
  } = useFormikContext<InvoiceFormFields>()

  const {
    clearEmailAddressField,
    clearField,
    fieldError,
    fieldValue,
    handleOnBlur,
    setAttentionToValue,
  } = useAttentionToField()

  const [validationError, setValidationError] = useState('')

  const payerFromCache = readFromCache(payerId)

  const [contactTypeToCreate, setContactTypeToCreate] =
    useState<ContactType | null>(null)

  const {
    handleChange,
    handleClose,
    handleInputChange,
    handleInputClear,
    inputValue,
    selectedItem,
    setInputValue,
    setSelectedItem,
  } = Contacts.useContactCombobox({
    isOptional: true,
    onChange: (changes) => {
      const attentionToContactType =
        payerFromCache?.contactType === ContactType.BUSINESS
          ? ContactType.PERSON
          : ContactType.BUSINESS

      if (changes.selectedItem?.value === NEW_ITEM_VALUE && payerFromCache) {
        setContactTypeToCreate(attentionToContactType)
        return
      }

      const { selectedItem: newSelectedItem } = changes

      if (!newSelectedItem) {
        clearField()
        return
      }

      setAttentionToValue(
        newSelectedItem.value,
        attentionToContactType,
        newSelectedItem.id
      )

      clearEmailAddressField()
    },
    onInputClear: clearField,
    onInputChange: (value) => {
      if (!value) {
        clearField()
      }
    },
    onValidationError: setValidationError,
    validationErrorMessages: translations.getValidationErrorMessages(
      payerContact?.contactType
    ),
  })

  const attentionToItems = useMemo(() => {
    if (!payerFromCache?.contacts?.length) {
      return []
    }

    return getContactsInputComboboxItems(payerFromCache.contacts).map((item) =>
      omit(['subLabel'], item)
    )
  }, [payerFromCache?.contacts])

  const handleCreateContact = (
    contact: CreateContact | CreateContactsContact
  ) => {
    const newContact = getCustomerFormValues(contact)
    clearEmailAddressField()
    setValues((prev) => setIn(prev, 'customer.attentionToContact', newContact))
    setSelectedItem(getCustomerSelectedItem(newContact))

    writeToCache(contact, payerFromCache)
  }

  const { attentionToContact: initialAttentionToContact } =
    initialFormValues.customer

  useEffect(() => {
    setSelectedItem(getCustomerSelectedItem(attentionToContact))

    setValidationError('')
    // Initialise set selected item on mount or when
    // initialAttentionToContact is re-initialised in Edit mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAttentionToContact])

  useEffect(() => {
    // Set selected item to null when payer contact is changed
    // and attention to field is cleared
    if (selectedItem && !fieldValue) {
      setSelectedItem(null)
    }
  }, [selectedItem, setSelectedItem, fieldValue])

  useEffect(() => {
    setInputValue('')
    setValidationError('')
  }, [payerContact, setInputValue, setValidationError])

  return {
    attentionToItems,
    contactTypeToCreate,
    error: fieldError ?? validationError,
    handleChange,
    handleClose,
    handleCreateContact,
    handleInputChange,
    handleInputClear,
    handleOnBlur,
    inputValue,
    isDisabled: isCustomerFieldsDisabled(status),
    payerFromCache,
    selectedItem,
    setContactTypeToCreate,
  }
}
