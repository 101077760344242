import { useRef } from 'react'
import { useLocationState } from '@npco/utils-routing'
import { z } from 'zod'

import { InvoiceFormFields } from '../../../Invoice.types'

export const StateSchema = z.object({
  initialValuesOverrides: z.custom<Partial<InvoiceFormFields>>(),
})

export const useInvoiceInitialValues = (defaultValues: InvoiceFormFields) => {
  const state = useLocationState(StateSchema)

  const defaultInitialValuesRef = useRef<InvoiceFormFields>(defaultValues)

  if (state?.initialValuesOverrides) {
    defaultInitialValuesRef.current = {
      ...defaultInitialValuesRef.current,
      ...state.initialValuesOverrides,
    }
  }

  return defaultInitialValuesRef
}
