import { useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { mergeDeepRight } from 'ramda'

import { CustomerType as LoggedInUserData } from '../useCustomerQuery/graphql/getCustomer.types'
import { rvCurrentUserData } from '../useCustomerQuery/graphql/reactiveVariables'

export const useLoggedInUser = () => {
  const userData = useReactiveVar(rvCurrentUserData)

  const setUserData = useCallback(
    (newUserData: Partial<LoggedInUserData>) => {
      const nonNullUserData = userData ?? ({} as LoggedInUserData)
      const mergedUserData = mergeDeepRight(nonNullUserData, newUserData)
      rvCurrentUserData(mergedUserData)
    },
    [userData]
  )

  const isDataAvailable = useMemo(() => userData !== null, [userData])

  const userRole = useMemo(
    () => userData?.role ?? CustomerRole.ADMIN,
    [userData]
  )

  return {
    userData,
    userRole,
    isDataAvailable,
    setUserData,
  }
}
