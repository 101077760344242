import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react'

import { InvoiceDetailsFragment } from 'types/gql-types/InvoiceDetailsFragment'

import { InvoiceDecisionModalTypes } from './InvoiceDrawerDetails.utils'

export interface InvoiceDecisionModalContextType {
  activeModal: InvoiceDecisionModalTypes | null
  closeModal: () => void
  details: InvoiceDetailsFragment
  setActiveModal: Dispatch<SetStateAction<InvoiceDecisionModalTypes | null>>
}

export const InvoiceDecisionModalContext =
  createContext<InvoiceDecisionModalContextType | null>(null)

interface InvoiceDecisionModalProviderProps {
  details: InvoiceDetailsFragment
  children: React.ReactNode
}

export const InvoiceDecisionModalProvider = ({
  details,
  children,
}: InvoiceDecisionModalProviderProps) => {
  const [activeModal, setActiveModal] =
    useState<InvoiceDecisionModalTypes | null>(null)

  const initialValues = useMemo(
    () => ({
      activeModal,
      closeModal: () => setActiveModal(null),
      details,
      setActiveModal,
    }),
    [activeModal, details, setActiveModal]
  )

  return (
    <InvoiceDecisionModalContext.Provider value={initialValues}>
      {children}
    </InvoiceDecisionModalContext.Provider>
  )
}
