import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  bcc: 'Bcc',
  cc: 'Cc',
  details:
    'Invoice sent via email, including a custom message and payment link.',
  title: 'Email',
  selectCustomer1: 'Please first',
  selectCustomer2: 'select a Customer',
  payerEmail: "Payer's Email",
})
