import { Ref, useMemo } from 'react'
import { Flex, SelectTriggerPill } from '@npco/zeller-design-system'
import {
  MultiSelectItemBasic,
  MultiSelectTriggerRenderProps,
} from 'design-system/Components/MultiSelect'
import { omit } from 'ramda'

import { SelectPill } from 'layouts/AccountLayout/SelectPill/SelectPill'

import { getContent } from './FilterSelectTrigger.utils'

interface Props<ItemType extends MultiSelectItemBasic, ValueType>
  extends Omit<MultiSelectTriggerRenderProps, 'ref'> {
  selectedItems: ItemType[]
  values: ValueType[]
  onReset: () => void
  filterName: string
  icon?: React.ReactNode
  areAllItemsSelected?: boolean
  isOpen: boolean
  shouldShowSelectedCountOnAllSelected?: boolean
  innerRef?: Ref<HTMLButtonElement>
}

export const FilterSelectTrigger = <
  ItemType extends MultiSelectItemBasic,
  ValueType extends string
>({
  selectedItems,
  values,
  onReset,
  filterName,
  icon,
  areAllItemsSelected = false,
  isOpen,
  innerRef,
  shouldShowSelectedCountOnAllSelected = false,
  ...renderTriggerProps
}: Props<ItemType, ValueType>) => {
  const areNoValuesSelected = values.length === 0
  const isInUnchangedState = useMemo(
    () =>
      areNoValuesSelected ||
      (!shouldShowSelectedCountOnAllSelected && areAllItemsSelected),
    [
      areAllItemsSelected,
      areNoValuesSelected,
      shouldShowSelectedCountOnAllSelected,
    ]
  )

  const content = useMemo(
    () => getContent(filterName, selectedItems, values),
    [filterName, selectedItems, values]
  )

  if (isInUnchangedState) {
    return (
      <SelectTriggerPill
        hasChevron
        isOpen={isOpen}
        ref={innerRef}
        {...renderTriggerProps}
      >
        {filterName}
      </SelectTriggerPill>
    )
  }

  if (isOpen) {
    return (
      <SelectTriggerPill
        hasChevron
        isOpen
        ref={innerRef}
        {...renderTriggerProps}
      >
        {content}
      </SelectTriggerPill>
    )
  }

  return (
    <SelectPill
      onClose={onReset}
      icon={icon}
      ref={innerRef}
      {...omit(['size'], renderTriggerProps)}
    >
      <Flex data-testid="filter-select-pill-content">{content}</Flex>
    </SelectPill>
  )
}
