import { useCallback } from 'react'
import {
  Box,
  InfoBox,
  INFOBOX_SIZE,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'
import { DebitCardAccount } from 'types/accounts'
import {
  StyledAccountPrimaryAction,
  StyledActionsContainer,
} from 'components/DepositAccountDetailDrawer/AccountDetailAction/AccountDetailAction.styled'
import { ModalConfirm } from 'components/Modal/ModalConfirm/ModalConfirm'

import { useSelectZellerAccountListItem } from '../DepositZellerAccountsList/DepositZellerAccountsList.hook'

interface DepositZellerAccountActionProps {
  account: DebitCardAccount
  isSelectedAccount: boolean
}

export const DepositZellerAccountAction = ({
  account,
  isSelectedAccount,
}: DepositZellerAccountActionProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const { isLoading, changeToZellerAccount } = useSelectZellerAccountListItem()

  const onConfirm = useCallback(async () => {
    if (await changeToZellerAccount(account)) {
      closeModal()
    }
  }, [account, changeToZellerAccount, closeModal])

  if (isSelectedAccount) {
    return (
      <Box width="100%" pt="16px">
        <InfoBox variant={INFOBOX_VARIANT.POSITIVE} size={INFOBOX_SIZE.SMALL}>
          <InfoBox.Message>
            {translate('page.settings.deposits.selectedAccount')}
          </InfoBox.Message>
        </InfoBox>
      </Box>
    )
  }

  return (
    <>
      <ModalConfirm
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={onConfirm}
        title={translate(
          'page.settings.deposits.confirmations.setAsDepositAccount.title'
        )}
        description={translate(
          'page.settings.deposits.confirmations.setAsDepositAccount.descriptionZellerAccount',
          {
            accountName: account.name,
          }
        )}
        isLoading={isLoading}
      />
      <StyledActionsContainer>
        <StyledAccountPrimaryAction dataTestId="setAccount" onClick={openModal}>
          {translate(
            'page.settings.deposits.confirmations.setAsDepositAccount.action'
          )}
        </StyledAccountPrimaryAction>
      </StyledActionsContainer>
    </>
  )
}
