import { GroupSelect } from './shared/GroupSelect'

export const DevicesFilterInputLoading = () => {
  return (
    <GroupSelect
      value={undefined}
      onValueChange={undefined}
      items={{ $type: 'Loading' }}
    />
  )
}
