import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Box } from '@npco/zeller-design-system'
import { useAvailableFundsCorporateCard } from 'features/Cards/CardSingle/hooks/useAvailableFundsCorporateCard/useAvailableFundsCorporateCard'
import { useGoToEditSpendControls } from 'features/EditSpendControls/edit-spend-controls-routing'

import { ROOT } from 'const/routes'
import { parseApiMoney } from 'utils/parseMoney'
import { getIsCardRestricted } from 'components/CardSummary/components/CardDisplay/CardDisplay.utils'

import { ExpenseCardBudgetExceededWarningMessage } from './ExpenseCardBudgetExceededWarningMessage/ExpenseCardBudgetExceededWarningMessage'
import { ExpenseCardBudgetMessageDisplayDebitCardV2Fragment } from './ExpenseCardBudgetMessageDisplay.generated'
import { ExpenseCardBudgetOverdrawnErrorMessage } from './ExpenseCardBudgetOverdrawnErrorMessage/ExpenseCardBudgetOverdrawnErrorMessage'
import { ExpenseCardLowAvailableBalanceWarningMessage } from './ExpenseCardLowAvailableBalanceWarningMessage/ExpenseCardLowAvailableBalanceWarningMessage'

interface ExpenseCardBudgetMessageDisplayProps {
  card: ExpenseCardBudgetMessageDisplayDebitCardV2Fragment
  timezone?: string
}

export const ExpenseCardBudgetMessageDisplay = ({
  card,
  timezone,
}: ExpenseCardBudgetMessageDisplayProps) => {
  const { userData } = useLoggedInUser()
  const { goToEditSpendControls } = useGoToEditSpendControls()
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const availableFunds = useAvailableFundsCorporateCard({ card, timezone })
  const isAdmin = userData?.role === CustomerRole.ADMIN
  const isCardHolder = userData?.id === card.customer?.id
  const handleOpenEditSpendControlModal = useCallback(() => {
    goToEditSpendControls({
      stage: 'edit',
      cardUuid: card.id,
      accountUuid: card.debitCardAccount.id,
      amountLimit: card.velocityControl?.amountLimit.value,
      maxTransactionValue: card.velocityControl?.maxTransactionValue.value,
      velocityWindow: card.velocityControl?.velocityWindow,
      modifiedBy: card.velocityControl?.modifiedBy ?? undefined,
    })
  }, [card, goToEditSpendControls])

  const handleAddFunds = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.TRANSFER.path)
  }, [navigate, shortEntityId])

  if (getIsCardRestricted(card.status) || (!isAdmin && !isCardHolder)) {
    return null
  }

  const isOverDrawn = availableFunds <= 0
  const amountLimitInCents = parseApiMoney(
    card.velocityControl?.amountLimit?.value ?? '0'
  )
  const isLowAvailableBalance =
    availableFunds <= 0 ? false : availableFunds / amountLimitInCents < 0.1
  const accountBalance = parseApiMoney(card.debitCardAccount.balance?.value)
  const isBudgetExceeded = availableFunds > accountBalance

  return (
    <Box>
      {isBudgetExceeded && (
        <ExpenseCardBudgetExceededWarningMessage
          isAdmin={isAdmin}
          onAddFunds={handleAddFunds}
        />
      )}
      {isOverDrawn && (
        <ExpenseCardBudgetOverdrawnErrorMessage
          isAdmin={isAdmin}
          availableFunds={availableFunds}
          onEdit={handleOpenEditSpendControlModal}
        />
      )}
      {isLowAvailableBalance && (
        <ExpenseCardLowAvailableBalanceWarningMessage
          isAdmin={isAdmin}
          onEdit={handleOpenEditSpendControlModal}
        />
      )}
    </Box>
  )
}

ExpenseCardBudgetMessageDisplay.fragments = {
  DebitCardV2: gql`
    fragment ExpenseCardBudgetMessageDisplayDebitCardV2Fragment on DebitCardV2 {
      id
      status
      debitCardAccount {
        id
        balance {
          value
        }
      }
      velocityControl {
        amountLimit {
          value
        }
        maxTransactionValue {
          value
        }
        velocityWindow
        modifiedBy {
          actingCustomerUuid
          updatedAt
        }
      }
      customer {
        id
        entityUuid
      }
      ...UseAvailableFundsCorporateCardDebitCardV2Fragment
    }

    ${useAvailableFundsCorporateCard.fragments.DebitCardV2}
  `,
}
