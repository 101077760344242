import {
  BREAKPOINT,
  Flex,
  PopperItemOneLineIcon,
  PopperItemOneLineLabel,
  PopperItemOneLineWrapper,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { SCROLLBAR } from 'const/scrollbars'

export const StatusFiltersWrapper = styled(Flex)`
  flex-flow: column nowrap;
  padding: 8px;
  font-weight: normal;
  overflow-y: auto;
  overflow-x: hidden;

  margin: ${SCROLLBAR.MD.rightSpacing} ${SCROLLBAR.MD.rightSpacing}
    ${SCROLLBAR.MD.rightSpacing} 0;
  &::-webkit-scrollbar {
    width: ${SCROLLBAR.MD.width};
  }

  max-height: calc(100dvh - 216px);
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    max-height: calc(100dvh - 230px);
  }
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    max-height: calc(100dvh - 166px);
  }

  ${PopperItemOneLineWrapper} {
    ${PopperItemOneLineIcon} {
      display: block;
      position: relative;
    }
    ${PopperItemOneLineLabel} {
      display: block;
      position: relative;
      right: 80px;
    }
  }
`

export const SortingWrapper = styled(Flex)`
  flex-flow: column nowrap;
`

export const StatusWrapper = styled(Flex)`
  flex-flow: column nowrap;
`

export const BottomRow = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: flex-end;
`
