import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE, ButtonGhost, Flex } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { translationsShared } from 'translations'

import * as styled from './OutstandingExpensesButtons.styled'

interface OutstandingExpensesButtonsProps {
  isSubmitDisabled?: boolean
  isSubmitLoading?: boolean
}
export const OutstandingExpensesButtons = ({
  isSubmitDisabled = false,
  isSubmitLoading = false,
}: OutstandingExpensesButtonsProps) => {
  const tShared = useTranslations(translationsShared)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const redirectToCorporateCards = useCallback(
    () => navigate(ROOT.ORGS.ORG(shortEntityId).CARDS.CORPORATE.path),
    [navigate, shortEntityId]
  )

  return (
    <Flex mt="40px" gap="24px">
      <styled.SaveButton
        type="submit"
        size={BUTTON_SIZE.LARGE}
        disabled={isSubmitDisabled}
        isLoading={isSubmitLoading}
      >
        {tShared('save')}
      </styled.SaveButton>
      <ButtonGhost onClick={redirectToCorporateCards}>
        {tShared('cancel')}
      </ButtonGhost>
    </Flex>
  )
}
