import { ModalBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface FormButtonsProps {
  onCancel?: () => void
  shouldShowBackButton?: boolean
  nextButtonLabel?: React.ReactNode
  backButtonLabel?: React.ReactNode
  isLoading?: boolean
  isConfirmDisabled?: boolean
  isCancelDisabled?: boolean
  onConfirm?: () => void
}

export const FooterButtons = ({
  onCancel,
  shouldShowBackButton = true,
  nextButtonLabel,
  backButtonLabel,
  isLoading,
  isConfirmDisabled,
  isCancelDisabled,
  onConfirm,
}: FormButtonsProps) => {
  return (
    <ModalBasic.Footer>
      {shouldShowBackButton && (
        <ModalBasic.SecondaryButton
          onClick={onCancel}
          disabled={isCancelDisabled}
        >
          {backButtonLabel || translate('shared.back')}
        </ModalBasic.SecondaryButton>
      )}
      <ModalBasic.PrimaryButton
        type={onConfirm ? 'button' : 'submit'}
        isLoading={isLoading}
        disabled={isConfirmDisabled}
        onClick={onConfirm}
      >
        {nextButtonLabel || translate('shared.next')}
      </ModalBasic.PrimaryButton>
    </ModalBasic.Footer>
  )
}
