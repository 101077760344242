import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { Flex, showErrorToast } from '@npco/zeller-design-system'

import { getDropzoneErrorMessage } from 'utils/getDropzoneErrorMessage'
import { form } from 'translations'

import {
  StyledBrowseDescription,
  StyledContent,
  StyledDragDescription,
  StyledFileUploaderWrapper,
} from './FileUploader.styled'

interface Props extends DropzoneOptions {
  isDisabled?: boolean
}

export const FileUploader = ({ isDisabled, ...props }: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isDisabled,
    onDropRejected: (error) => {
      showErrorToast(getDropzoneErrorMessage(error))
    },
    ...props,
  })

  return (
    <StyledFileUploaderWrapper
      isDragActive={isDragActive}
      {...getRootProps({ className: 'dropzone' })}
    >
      <StyledContent isDisabled={isDisabled}>
        <Flex
          height="2.5rem"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <StyledDragDescription>
            {form.shared.uploadDocuments.label1}
          </StyledDragDescription>
          <StyledBrowseDescription>
            {form.shared.uploadDocuments.label2}
          </StyledBrowseDescription>

          <input data-testid="upload-document-input" {...getInputProps()} />
        </Flex>
      </StyledContent>
    </StyledFileUploaderWrapper>
  )
}
