import { type ReactNode, useCallback } from 'react'
import { To, useNavigate } from 'react-router-dom-v5-compat'
import {
  BodyDefault,
  ButtonLink,
  ButtonLinkProps,
  Heading,
  HeadingStyles,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 1rem;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const SectionTitle = styled((props) => (
  <Heading.H2 withStyles={HeadingStyles.H3} {...props} />
))`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const SectionDescription = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const SectionLabel = styled((props) => (
  <Heading.H3 withStyles={HeadingStyles.H5} {...props} />
))`
  margin: 0;
`

export const SectionValue = styled(BodyDefault)`
  margin: 0;
`

export const SectionLabelValue = styled(
  ({
    label,
    value,
    ...forwardedProps
  }: {
    label: ReactNode
    value: ReactNode
  }): JSX.Element => (
    <div {...forwardedProps}>
      <SectionLabel>{label}</SectionLabel>
      <SectionValue>{value}</SectionValue>
    </div>
  )
)`
  display: flex;
  justify-content: space-between;
`

export const SectionLink = ({ to, ...props }: ButtonLinkProps & { to: To }) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(to)
  }, [navigate, to])

  return <ButtonLink isInline onClick={handleClick} {...props} />
}

export const SectionButtonLink = ButtonLink
