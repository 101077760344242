import { gql } from '@apollo/client'

import { CloseAccountViewDebitCardAccountV2Fragment as DebitCardAccountV2 } from 'types/gql-types/CloseAccountViewDebitCardAccountV2Fragment'

import { CloseAccountPrompt } from './CloseAccountPrompt/CloseAccountPrompt'
import { CloseAccountSettlementPrompt } from './CloseAccountSettlementPrompt/CloseAccountSettlementPrompt'
import { CloseAccountSimBillingPrompt } from './CloseAccountSimBillingPrompt/CloseAccountSimBillingPrompt'
import { CloseAccountTransferFundsPrompt } from './CloseAccountTransferFundsPrompt/CloseAccountTransferFundsPrompt'

interface CloseAccountViewProps {
  account: DebitCardAccountV2
  isSettlementAccount: boolean
  isSimBillingAccount: boolean
}

export const CloseAccountView = ({
  account,
  isSettlementAccount,
  isSimBillingAccount,
}: CloseAccountViewProps) => {
  if (isSettlementAccount) {
    return <CloseAccountSettlementPrompt />
  }

  if (isSimBillingAccount) {
    return <CloseAccountSimBillingPrompt />
  }

  if (account.balance?.value !== '0') {
    return <CloseAccountTransferFundsPrompt account={account} />
  }

  return <CloseAccountPrompt account={account} />
}

CloseAccountView.fragments = {
  DebitCardAccountV2: gql`
    fragment CloseAccountViewDebitCardAccountV2Fragment on DebitCardAccountV2 {
      balance {
        value
      }
      ...CloseAccountTransferFundsPromptDebitCardAccountV2Fragment
      ...CloseAccountPromptDebitCardAccountV2Fragment
    }

    ${CloseAccountTransferFundsPrompt.fragments.DebitCardAccountV2}
    ${CloseAccountPrompt.fragments.DebitCardAccountV2}
  `,
  BillingAccount: gql`
    fragment CloseAccountViewBillingAccountFragment on BillingAccount {
      id
    }
  `,
  Entity: gql`
    fragment CloseAccountViewEntityFragment on Entity {
      remitToCard
      debitCardAccountUuid
    }
  `,
}
