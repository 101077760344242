import { InvoiceStatus } from '@npco/mp-gql-types'
import { TableCellText, theme } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { getFormattedDate } from '../InvoiceTable.utils'

export const InvoiceDateRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const isLoading = !row?.original || table.options.meta?.isLoading

  const value = isLoading ? undefined : getValue<string | undefined | number>()

  const colour =
    row.original?.status === InvoiceStatus.OVERDUE
      ? theme?.colors.RED_1000
      : undefined

  return (
    <TableCellText
      color={colour}
      dataTestId={`invoice-table-row-${row.index}-date`}
      forwardedProps={{ style: { padding: 0 } }}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width: '100%' }}
      text={getFormattedDate(value)}
    />
  )
}
