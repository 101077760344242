import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragmentDoc } from './hooks/useGetCategoryPieChartData.generated'

export type CategoriesChartCashFlowCategoryNetAmountFragment = {
  __typename: 'CashFlowCategoryNetAmount'
  category: Types.EntityCategories | null
  total: { __typename?: 'Money'; value: string }
}

export const CategoriesChartCashFlowCategoryNetAmountFragmentDoc = gql`
  fragment CategoriesChartCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
    __typename
    ...UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment
  }
  ${UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  CategoriesChartCashFlowCategoryNetAmountFragment,
  undefined
>
