import { ReactNode } from 'react'
import { ImageSize } from '@npco/mp-gql-types'
import { z } from 'zod'

import { validateReactNode } from '../bpay-transfer-modal/utils/validateReactNode/validateReactNode'

export const ContactToLinkBpayToSchema = z.object({
  label: z.string(),
  subLabel: z.union([z.string(), z.custom<ReactNode>(validateReactNode)]),
  value: z.string(),
  id: z.string(),
})

export const IconSchema = z
  .object({
    colour: z.string().nullable(),
    image: z.string().nullable(),
    letter: z.string().nullable(),
    images: z
      .array(
        z.object({
          size: z.nativeEnum(ImageSize),
          url: z.string(),
        })
      )
      .nullable(),
  })
  .nullable()

const BillerContactSchema = z.object({
  id: z.string(),
  name: z.string(),
  nickname: z.string(),
  code: z.string(),
  reference: z.string().optional(),
})

export type BillerContact = z.TypeOf<typeof BillerContactSchema>

export const ContactOrBillerSchema = z.object({
  type: z.union([z.literal('Biller'), z.literal('Contact')]),
  name: z.string().optional(),
  code: z.string().optional(),
  id: z.string().optional(),
  icon: IconSchema,
  billerContacts: z.array(BillerContactSchema).optional(),
  email: z
    .object({
      email: z.string().nullable(),
    })
    .optional(),
  phone: z.string().nullable().optional(),
  phoneV2: z
    .object({
      phone: z.string().nullable(),
    })
    .optional(),
})

export type ContactOrBiller = z.TypeOf<typeof ContactOrBillerSchema>

export const TransferDropdownTypeSchema = z.string().nullable()

export const SenderSchema = z.object({
  id: z.string(),
  name: z.string(),
  accountNumber: z.string(),
  icon: IconSchema,
})

export const TransactionDetailsSchema = z.object({
  amount: z.string(),
  from: SenderSchema,
  businessName: z.string().optional(),
  to: ContactOrBillerSchema.optional(),
  nickname: z.string().optional(),
  crn: z.string().optional(),
  paymentInstrumentUuid: z
    .object({
      id: z.string().optional(),
      type: z.string().optional(),
      label: z.string().optional(),
      subLabel1: z.union([z.string(), z.custom<ReactNode>(validateReactNode)]),
      subLabel2: z.string().optional(),
      value: z.string().optional(),
    })
    .optional(),
  reference: z.union([z.string(), ContactToLinkBpayToSchema]).optional(),
})

export const BPaySchema = z.object({
  ...TransactionDetailsSchema.shape,
  from: z.string(),
  reference: z.union([z.string(), ContactToLinkBpayToSchema]).optional(),
  recipientReference: z.string().optional(),
})

export const BPayTransferSchema = z.union([
  z.object({
    stage: z.literal('summary'),
    transferDetails: TransactionDetailsSchema,
  }),
  z.object({
    stage: z.literal('complete'),
    transferDetails: TransactionDetailsSchema,
    transactionTimestamp: z.string().optional(),
    transactionID: z.string(),
  }),
])

export type BPayTransferModalState = z.infer<typeof BPayTransferSchema>

export const BPayTransferLocationSchema = z.object({
  BPayTransferModal: BPayTransferSchema,
})

export type TransactionDetails = z.TypeOf<typeof TransactionDetailsSchema>
