import { useMemo } from 'react'
import { times } from 'ramda'

import { useWindowResolution } from 'hooks/useWindowResolution/useWindowResolution'
import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'

import {
  StyledSkeletonItemsWrapper,
  StyledSkeletonRowDesktop,
} from '../SelectLoadingPlaceholder.styled'

const SKELETON_ROW_HEIGHT = 48

export const SelectLoadingPlaceholderMobile = () => {
  const { height } = useWindowResolution()

  const numberOfSkeletonItems = useMemo(
    () => Math.ceil(height / SKELETON_ROW_HEIGHT),
    [height]
  )

  return (
    <StyledSkeletonItemsWrapper $height="100%">
      {times(
        (i) => (
          <StyledSkeletonRowDesktop data-testid="skeleton-dropdown-row" key={i}>
            <SkeletonLoaderBasic height="0.75rem" isRounded />
          </StyledSkeletonRowDesktop>
        ),
        numberOfSkeletonItems
      )}
    </StyledSkeletonItemsWrapper>
  )
}
