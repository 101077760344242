import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { BusinessRowCustomerEntityRelationFragmentDoc } from './BusinessRow/BusinessRow.generated'

export type BusinessesListCustomerEntityMappingFragment = {
  __typename?: 'CustomerEntityMapping'
  entityRelations: Array<{
    __typename?: 'CustomerEntityRelation'
    role: Types.CustomerRole
    isVisible: boolean
    entity: { __typename?: 'Entity'; id: string; name: string | null }
  }> | null
}

export const BusinessesListCustomerEntityMappingFragmentDoc = gql`
  fragment BusinessesListCustomerEntityMappingFragment on CustomerEntityMapping {
    entityRelations {
      ...BusinessRowCustomerEntityRelationFragment
    }
  }
  ${BusinessRowCustomerEntityRelationFragmentDoc}
` as unknown as TypedDocumentNode<
  BusinessesListCustomerEntityMappingFragment,
  undefined
>
