import {
  Box,
  ErrorMessageForm,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
  INPUT_SIZE,
} from '@npco/zeller-design-system'
import { Editor } from 'draft-js'
import { useField } from 'formik'

import { translate } from 'utils/translations'

import { INVOICE_EMAIL_MESSAGE_FIELD } from '../../../../../../../Invoice.constants'
import { useDefaultMarkupValue } from '../../../hooks/useDefaultMarkupValue'
import { useEmailRichTextEditor } from '../../../hooks/useEmailRichTextEditor'
import {
  Label,
  RichTextEditorWrapper,
} from '../../../InvoiceDeliveryAccordion.styled'
import { InvoiceEmailMergeTags } from '../../InvoiceEmailMergeTags/InvoiceEmailMergeTags'

export const translations = {
  paymentLinkInfoBox: translate(
    'page.invoice.formSections.email.paymentLinkInfoBox'
  ),
  messageLabel: translate('page.invoice.formSections.email.messageLabel'),
}

export const InvoiceEmailMessage = () => {
  const { defaultMessage } = useDefaultMarkupValue()
  const [field, meta, helpers] = useField(INVOICE_EMAIL_MESSAGE_FIELD)

  const initialMarkup = field.value ?? defaultMessage
  // This tells the editor to create a new editorState from
  // the default subject markup (on first load or on reset template)
  const isUsingDefaultTemplate = field.value === null

  const error = meta.touched ? meta.error : ''

  const hasFieldError = Boolean(error)

  const {
    availableMergeTags,
    handleEditorBlur,
    handleEditorFocus,
    handleKeyPress,
    handleEditorChange,
    isFocused,
    sendMergeTagToEditor,
    textEditorState,
  } = useEmailRichTextEditor({
    fieldName: INVOICE_EMAIL_MESSAGE_FIELD,
    hasFieldError,
    initialMarkup,
    isDefaultTemplate: isUsingDefaultTemplate,
    onBlur: () => helpers.setTouched(true),
    // Prevent validate when reset
    onChange: (value) => helpers.setValue(value, !isUsingDefaultTemplate),
  })

  const isFloatingLabel = Boolean(isFocused || field.value)

  return (
    <Flex flexDirection="column" data-testid="invoice-email-message-field">
      <RichTextEditorWrapper
        active={isFocused}
        hasError={hasFieldError}
        $isMultiline
      >
        <Label $size={INPUT_SIZE.MEDIUM} isFloatingLabel={isFloatingLabel}>
          {translations.messageLabel}
        </Label>
        <Editor
          editorState={textEditorState}
          onBlur={handleEditorBlur}
          onFocus={handleEditorFocus}
          onChange={handleEditorChange}
          keyBindingFn={handleKeyPress}
        />

        <InvoiceEmailMergeTags
          onSelectTag={(mergeTag) =>
            sendMergeTagToEditor(availableMergeTags[mergeTag])
          }
        />
      </RichTextEditorWrapper>
      <ErrorMessageForm hasError={hasFieldError} errorMessage={error} />
      <Box mt={24}>
        <InfoBox priority={INFOBOX_PRIORITY.DEFAULT}>
          <InfoBox.Message>{translations.paymentLinkInfoBox}</InfoBox.Message>
        </InfoBox>
      </Box>
    </Flex>
  )
}
