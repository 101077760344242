import { translate } from 'utils/translations'
import { GetZellerSurchargeSettings_getZellerInvoiceSettings_surchargesTaxes_feesSurchargeZinv as ZellerInvoiceFees } from 'types/gql-types/GetZellerSurchargeSettings'

import { InvoiceFeesInfo } from '../InvoiceFeesInfo/InvoiceFeesInfo'
import {
  StyledSiteContainer,
  StyledSiteTitle,
} from './InvoiceFeesAndPricingContainer.styled'

export interface ZellerFeeContainerProps {
  zellerFees?: ZellerInvoiceFees
}

export const ZellerFeeContainer = ({ zellerFees }: ZellerFeeContainerProps) => {
  if (!zellerFees) {
    return null
  }

  return (
    <StyledSiteContainer>
      <StyledSiteTitle>
        {translate(
          'page.settings.feesAndPricing.table.zellerInvoiceTransactionCharges'
        )}
      </StyledSiteTitle>
      <InvoiceFeesInfo fees={zellerFees} />
    </StyledSiteContainer>
  )
}
