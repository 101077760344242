import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { translations } from '../../Transfer.i18n'

export const TransferPrimaryHeaderRow = () => {
  const t = useTranslations(translations)

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex
        flexDirection="column"
        justifyContent="center"
        minHeight="48px"
        width="100%"
      >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Breadcrumb.Text>{t('breadcrumb')}</Breadcrumb.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
