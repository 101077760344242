import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const PositionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 318px;
  box-sizing: border-box;
  border-radius: 8px;

  position: absolute;
  right: calc(50% - 160px);
  top: calc((100vw - 32px) / 3 - 52px);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 376px;
    position: absolute;
    right: calc(50% - 188px);
    top: 52px;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    position: relative;
    right: unset;
    top: unset;
  }
`
