import { useHistory } from 'react-router'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Reports } from 'assets/svg/reports-alt.svg'
import { ROOT } from 'const/routes'
import {
  StyledIconWrapper,
  StyledStatementsButton,
  StyledText,
} from 'pages/Settings/Sim/BillingHistory/StatementButton/StatementButton.styled'
import { page } from 'translations'

export const StatementButton = () => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <StyledStatementsButton
      onClick={() =>
        history.push(
          ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.BILLING_HISTORY.STATEMENTS
            .path
        )
      }
      data-testid="sim-statement-button"
    >
      <StyledText>{page.settings.statements.statements}</StyledText>
      <StyledIconWrapper>
        <SvgIcon height="16" width="16" color={COLOR.BLUE_1000}>
          <Reports />
        </SvgIcon>
      </StyledIconWrapper>
    </StyledStatementsButton>
  )
}
