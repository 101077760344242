import { component } from 'translations'

import { LabelSelectComboboxItem } from './LabelSelectCombobox.types'

interface GetAddNewItemContentProps<ItemType> {
  inputValue?: string
  items: ItemType[]
}

export function getAddNewItemContent<ItemType extends LabelSelectComboboxItem>({
  inputValue,
  items,
}: GetAddNewItemContentProps<ItemType>) {
  const isInputValueMatchingAnyItem = items.some(
    (item: ItemType) =>
      // NOTE: labels are not case sensitive
      item.label.toUpperCase() === String(inputValue?.trim().toUpperCase())
  )

  return inputValue && !isInputValueMatchingAnyItem
    ? `${component.labelSelectCombobox.create} "${String(inputValue).trim()}"`
    : undefined
}

interface GetSortedItemsProps<ItemType> {
  items: ItemType[]
}

export function getSortedItems<ItemType extends LabelSelectComboboxItem>({
  items,
}: GetSortedItemsProps<ItemType>) {
  return items.sort(({ label: prevLabel }, { label: nextLabel }) => {
    if (prevLabel < nextLabel) {
      return -1
    }
    if (prevLabel > nextLabel) {
      return 1
    }

    return 0
  })
}
