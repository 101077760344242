import { useTranslations } from '@npco/utils-translations'
import { DownloadIcon, ShareIcon, Tabs } from '@npco/zeller-design-system'

import { useCashflowReportingTransactionsContext } from '../../hooks/useCashflowReportingTransactionsContext'
import { incomeExpenseTabsTranslations } from './IncomeExpenseTabs.i18n'

export const IncomeExpenseTabs = () => {
  const t = useTranslations(incomeExpenseTabsTranslations)
  const { incomeOrExpenseSelection, setIncomeOrExpenseSelection } =
    useCashflowReportingTransactionsContext()

  return (
    <Tabs currentTab={incomeOrExpenseSelection} gridGap="8px" align="right">
      <Tabs.ItemWithIcon
        value="income"
        icon={<DownloadIcon />}
        onClick={() => setIncomeOrExpenseSelection('income')}
      >
        {t('income')}
      </Tabs.ItemWithIcon>
      <Tabs.ItemWithIcon
        value="expense"
        icon={<ShareIcon />}
        onClick={() => setIncomeOrExpenseSelection('expense')}
      >
        {t('expense')}
      </Tabs.ItemWithIcon>
    </Tabs>
  )
}
