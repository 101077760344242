import { TransactionDetails } from 'pages/Transfer/BPay/bpay-transfer-routing/BPayTransfer.types'
import { ContactOrBillerType } from 'pages/Transfer/Transfer.types'

import { mapReferenceToProperties } from '../../BPayModal.utils'

export const convertToBPayFieldsType = (values: TransactionDetails) => {
  const billerDetail = values.to?.billerContacts?.find(
    (item) => item.id === values.paymentInstrumentUuid?.id
  )

  const res = mapReferenceToProperties(values?.reference)

  return {
    amount: values.amount,
    from: values.from.id,
    businessName: values.businessName,
    to: {
      ...values.to,
      type: values.to?.type as ContactOrBillerType,
      name: values.to?.name ?? '',
      code: values.to?.code ?? '',
      icon: values.to?.icon ?? null,
      email: values.to?.email,
      phone: values.to?.phoneV2?.phone ?? values.to?.phone,
      billerContacts: values?.to?.billerContacts ?? [
        {
          id: values.to?.id ?? '',
          name: values.to?.name ?? '',
          nickname: values.nickname ?? '',
          code: values.to?.code ?? '',
          reference: typeof res === 'string' ? '' : res.value,
        },
      ],
    },
    nickname: values.nickname ?? billerDetail?.nickname ?? '',
    crn: values.crn ?? billerDetail?.reference ?? '',
    paymentInstrumentUuid: {
      ...values.paymentInstrumentUuid,
      id: values.paymentInstrumentUuid?.id ?? '',
    },
    reference: res,
  }
}
