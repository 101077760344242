import { sum } from 'ramda'

import {
  WithCountPurchases,
  WithFeeAmount,
  WithPeriod,
  WithPurchaseAmount,
  WithRefundAmount,
  WithSurchargeAmount,
  WithTaxAmounts,
  WithTipAmount,
  WithTotalAmount,
} from '../Dashboard.types'

export const getTotalsBetween = <T extends WithPeriod>(
  transactionTotals: T[],
  from: Date,
  to: Date
) => {
  return transactionTotals.filter((transactionTotal) => {
    const date = new Date(transactionTotal.period as string)
    return from <= date && to >= date
  })
}

export const sumTotalSales = (transactionTotals: WithTotalAmount[]) => {
  return sum(
    transactionTotals.map((transactionTotal) => transactionTotal.totalAmount)
  )
}

export const sumFees = (transactionTotals: WithFeeAmount[]) => {
  return sum(
    transactionTotals.map((transactionTotal) => transactionTotal.feeAmount)
  )
}

export const countSales = (transactionTotals: WithCountPurchases[]) => {
  return sum(
    transactionTotals.map((transactionTotal) => transactionTotal.countPurchases)
  )
}

export const sumPurchaseAmounts = (transactionTotals: WithPurchaseAmount[]) => {
  return sum(
    transactionTotals.map((transactionTotal) => transactionTotal.purchaseAmount)
  )
}

export const sumSurcharges = (transactionTotals: WithSurchargeAmount[]) => {
  return sum(
    transactionTotals.map(
      (transactionTotal) => transactionTotal.surchargeAmount
    )
  )
}

export const sumTips = (transactionTotals: WithTipAmount[]) => {
  return sum(
    transactionTotals.map((transactionTotal) => transactionTotal.tipAmount)
  )
}

const sumTaxAmounts = (taxRecord: WithTaxAmounts) => {
  const taxAmounts = taxRecord.taxAmounts || []
  return sum(taxAmounts.map(({ amount }) => amount))
}
export const sumTax = (transactionTotals: WithTaxAmounts[]) => {
  return sum(transactionTotals.map(sumTaxAmounts))
}

export const sumRefunds = (transactionTotals: WithRefundAmount[]) => {
  return sum(
    transactionTotals.map((transactionTotal) => transactionTotal.refundAmount)
  )
}
