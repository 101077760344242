import { type ReactElement, type ReactNode } from 'react'

import { GroupSelectLoading as ItemsLoading } from './GroupSelect/GroupSelectLoading'
import { GroupSelectOk as ItemsOk } from './GroupSelect/GroupSelectOk'

export type GroupSelectProps = {
  value: string[] | undefined
  onValueChange: ((value: string[] | undefined) => void) | undefined
  items: GroupSelectProps.Items
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GroupSelectProps {
  export type Items = Items.Ok | Items.Loading

  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace Items {
    export type Ok = {
      $type: 'Ok'
      groups: Group[]
      actionSelectAll?: { label: ReactNode }
    }
    export type Loading = { $type: 'Loading' }
  }

  export type Group = {
    key: string
    label: string
    items: Item[]
  }

  export type Item = {
    key: string
    label: string
    icon?: ReactNode
  }
}

export const GroupSelect = ({
  value,
  onValueChange,
  items,
}: GroupSelectProps): ReactElement => {
  return (
    <div aria-busy={items.$type === 'Loading'} aria-live="polite">
      {items.$type === 'Loading' && <ItemsLoading />}
      {items.$type === 'Ok' && (
        <ItemsOk value={value} onValueChange={onValueChange} {...items} />
      )}
    </div>
  )
}
