import { BodyDefaultTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledInputWrapper = styled.div<{ $width?: string }>`
  padding: 0.75rem 0 0.75rem 1rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.GREY_60};
  border: 1px solid ${({ theme }) => theme.colors.GREY_60};
  transition: border-color 0.3s ease-out;
  border-radius: 3px;
  margin-bottom: 0.5rem;

  &:hover {
    border-color: ${({ theme }) => theme.colors.GREY_150};
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.BLUE_1000};
  }
`

export const StyledSearchInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  caret-color: ${({ theme }) => theme.colors.BLUE_1000};
  ${BodyDefaultTypography};
  font-family: inherit;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.GREY_250};
  }
`
