import { ReactComponent as TransactionType } from 'assets/svg/transaction-type.svg'
import { TRANSACTION_TYPES } from 'const/common'
import { getSelectedItemsInfo } from 'utils/picker'
import { PickerItemProps } from 'types/picker'
import { MultiDownshift } from 'components/MultiDownshift'
import { MultiDownshiftComponentName } from 'components/MultiDownshift/MultiDownshift.hooks'
import {
  AllItemsRow,
  BottomRow,
  StatusRow,
  TopRow,
} from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { StyledDataSmall } from 'components/Shared'
import { component } from 'translations'

import {
  StyledDropdown,
  StyledOpenOptions,
  StyledTypesBox,
} from './TypePicker.styled'

export const TypePicker = () => (
  <MultiDownshift
    itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
    componentName={MultiDownshiftComponentName.Type}
  >
    {({
      getToggleButtonProps,
      getRootProps,
      getItemProps,
      closeMenu,
      selectedItems,
      toggleTypes,
      clearAllSelected,
      isOpen,
    }) => (
      <StyledDropdown
        {...getRootProps(undefined, { suppressRefError: true })}
        isOpen={isOpen}
      >
        <PickerTrigger
          getToggleButtonProps={getToggleButtonProps}
          isOpen={isOpen}
          label={getSelectedItemsInfo(selectedItems, 'type')}
          placeholderIcon={<TransactionType />}
          triggerContent={
            selectedItems?.length
              ? getSelectedItemsInfo(selectedItems, 'type')
              : undefined
          }
        />
        {isOpen && (
          <StyledOpenOptions>
            <TopRow
              copy={component.transactionTypePicker.selectType}
              handleCloseMenu={closeMenu}
            />
            <StyledTypesBox>
              {TRANSACTION_TYPES.length > 0 && (
                <AllItemsRow
                  label={component.transactionTypePicker.allTypes}
                  checked={selectedItems.length === TRANSACTION_TYPES.length}
                  handleOnChange={() => toggleTypes(TRANSACTION_TYPES)}
                />
              )}
              {TRANSACTION_TYPES.map((type) => (
                <StatusRow
                  key={type.name}
                  status={type}
                  getItemProps={getItemProps}
                  selectedItems={selectedItems}
                />
              ))}
            </StyledTypesBox>
            <BottomRow handleClearAll={clearAllSelected}>
              <StyledDataSmall data-testid="selected-items">
                {getSelectedItemsInfo(selectedItems, 'type')}
              </StyledDataSmall>
            </BottomRow>
          </StyledOpenOptions>
        )}
      </StyledDropdown>
    )}
  </MultiDownshift>
)
