import { useCallback, useEffect, useMemo } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { ContactType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import {
  GetContacts,
  GetContactsQueryResponse,
  GetContactsQueryVariables,
} from 'features/Contacts/graphql/GetContacts.generated'
import { uniqBy } from 'lodash-es'
import { compose } from 'ramda'

import { usePrevious } from 'hooks/usePrevious'

import { ContactsFilters } from '../../Contacts.types'
import { rvAddedContacts, rvContacts } from '../../rv-deprecated/contacts'
import {
  contactsNameSort,
  getMappedContactsFromRv,
  getMappedRvFromContacts,
  removeSortNameProp,
  splitContactsByIsSelf,
} from './useContacts.utils'

export interface UseContactsProps {
  contactType?: ContactType
  filters: ContactsFilters
  limit: number
  isNetworkOnly?: boolean
  skip?: boolean
}

export interface UseContactsResponse {
  called: boolean
  contacts: (ContactCoreFieldsFragment | null)[]
  hasMore: boolean
  loadMore: () => void
  loading: boolean
}

export const useContacts = ({
  contactType,
  filters,
  limit,
  isNetworkOnly,
  skip,
}: UseContactsProps): UseContactsResponse => {
  const { name, tags } = filters
  const previousName = usePrevious(name)
  const previousTags = usePrevious(tags)
  const entityUuid = useSelectedEntityUuid()

  const reactiveContacts = useReactiveVar(rvContacts)

  const [getContacts, { called, data, fetchMore, loading }] = useLazyQuery<
    GetContactsQueryResponse,
    GetContactsQueryVariables
  >(GetContacts, {
    fetchPolicy: isNetworkOnly ? 'network-only' : 'cache-first',
    nextFetchPolicy: 'cache-first',
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  useEffect(() => {
    if (!data?.getContacts?.contacts) {
      return
    }

    const newMappedRvContacts = getMappedRvFromContacts(
      data.getContacts.contacts
    )

    rvContacts({ ...rvContacts(), ...newMappedRvContacts })
  }, [data?.getContacts.contacts])

  const contacts = useMemo(() => {
    if (!data?.getContacts?.contacts) {
      return []
    }

    // NOTE: newly added contacts are not added in the cache, so we have
    // to manually append them in the contacts list
    const addedContactsByType = rvAddedContacts()
      .map((contact) => reactiveContacts?.[contact])
      .filter(
        (contact): contact is ContactCoreFieldsFragment =>
          !!contact &&
          contact.contactType === contactType &&
          // NOTE: Filter out all added contacts that are included
          // in the response to prevent item duplication
          !data.getContacts.contacts.some(
            (dataContact) => dataContact?.id === contact.id
          )
      )

    const mappedContactsFromRv = getMappedContactsFromRv(
      // Note: Added newly added contacts to list
      uniqBy(data.getContacts.contacts?.concat(addedContactsByType), 'id')
    )

    const [mappedContactsFromRvIsSelf, mappedContactsFromRvNotIsSelf] =
      splitContactsByIsSelf(mappedContactsFromRv)

    return [
      ...compose(
        removeSortNameProp,
        contactsNameSort
      )(mappedContactsFromRvIsSelf),
      ...compose(
        removeSortNameProp,
        contactsNameSort
      )(mappedContactsFromRvNotIsSelf),
    ]
  }, [contactType, data?.getContacts?.contacts, reactiveContacts])

  const nextToken = data?.getContacts?.nextToken || null
  const hasMore = !!nextToken
  const shouldFetchContacts =
    (previousName !== undefined && previousName !== name) ||
    (previousTags !== undefined && previousTags.length !== tags.length)

  const variables = useMemo(() => {
    const tagNames = tags?.map((tag) => tag.name) ?? []

    return {
      contactType,
      filter: { name: { contains: name }, tags: tagNames },
      limit,
      nextToken: null,
      entityUuid,
    }
  }, [contactType, limit, name, tags, entityUuid])

  // NOTE: on mount
  useEffect(() => {
    if (skip) {
      return
    }
    getContacts({ variables })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: on filter name and tags change
  useEffect(() => {
    if (shouldFetchContacts) {
      getContacts({ variables })
    }
  }, [getContacts, shouldFetchContacts, variables])

  const loadMore = useCallback(
    () => fetchMore?.({ variables: { nextToken } }),
    [fetchMore, nextToken]
  )

  return {
    called,
    contacts,
    hasMore,
    loadMore,
    loading,
  }
}
