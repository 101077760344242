import { gql, type TypedDocumentNode } from '@apollo/client'

import { OutstandingExpensesContentFragmentDoc } from './OutstandingExpensesContent/OutstandingExpensesContent.generated'

export type CardsSettingsOutstandingExpensesFragment = {
  __typename?: 'Entity'
  id: string
  outstandingTransactionRequirementConfig: {
    __typename?: 'OutstandingTransactionRequirementConfig'
    attachments: boolean
    category: boolean
    note: boolean
    accountingCategory: boolean
  } | null
}

export const CardsSettingsOutstandingExpensesFragmentDoc = gql`
  fragment CardsSettingsOutstandingExpensesFragment on Entity {
    ...OutstandingExpensesContentFragment
  }
  ${OutstandingExpensesContentFragmentDoc}
` as unknown as TypedDocumentNode<
  CardsSettingsOutstandingExpensesFragment,
  undefined
>
