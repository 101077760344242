import { useCallback } from 'react'
import { ModalBasic } from '@npco/zeller-design-system'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'
import { useMFAState } from 'features/MFA/MFAContext/hooks/useMFAState/useMFAState'

import { useRedirectToMFA } from '../../MFAContext/hooks/useRedirectToMFA/useRedirectToMFA'
import { ModalVerificationRequired } from '../../ModalVerificationRequired/ModalVerificationRequired'

export const RedirectToMFAModal = () => {
  const { appState } = useMFAState()
  const { stepUpAuth } = useZellerAuthenticationContext()
  const { cancelRedirect } = useRedirectToMFA()

  const cancelMFA = useCallback(() => {
    cancelRedirect()
  }, [cancelRedirect])

  return (
    <ModalBasic isOpen onCancel={cancelMFA}>
      <ModalVerificationRequired
        onCancel={cancelMFA}
        onRedirect={() => stepUpAuth(appState)}
      />
    </ModalBasic>
  )
}
