import { gql } from '@apollo/client'
import {
  Box,
  COLOR,
  InfoFilledIcon,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { SimIcon } from '../../components/SimIcon/SimIcon'
import { SimDeviceCell } from './SimDeviceCell'
import { SimIconCellFragment } from './SimIconCell.generated'
import * as styled from './SimIconCell.styled'
import { SimSiteCell } from './SimSiteCell'
import { SimStatusCell } from './SimStatusCell'

export const SimIconCell = <T,>({
  cell,
  getValue,
  row,
  table,
}: CellContext<T, SimIconCellFragment>) => {
  const isMobile = useIsMobileResolution()
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue<SimIconCellFragment>()
  const hasInfoIcon = !data?.siteName || !data?.deviceName

  if (!isMobile) {
    return null
  }

  return (
    <styled.SimIconWrapper
      key={cell.id}
      justifyContent="flex-start"
      forwardedProps={{
        style: { paddingRight: '6px' },
      }}
      cellSize={{ _: '42px' }}
      dataTestId={`sim-mobile-row-${row.index}-icon`}
      isLoading={isLoading}
      icon={
        <Box position="relative">
          <SimIcon billingStatus={data?.billingStatus} />
          {hasInfoIcon && (
            <styled.InfoIconWrapper>
              <InfoFilledIcon color={COLOR.YELLOW_1000} />
            </styled.InfoIconWrapper>
          )}
        </Box>
      }
      skeletonProps={{ width: '100%' }}
    />
  )
}

SimIconCell.fragments = {
  Sim: gql`
    fragment SimIconCellFragment on Sim {
      ...SimSiteCellFragment
      ...SimDeviceCellFragment
      ...SimStatusCellFragment
    }
    ${SimSiteCell.fragments.Sim}
    ${SimDeviceCell.fragments.Sim}
    ${SimStatusCell.fragments.Sim}
  `,
}
