import { AnnouncementModal } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { SpinnerWrapped } from 'components/Spinner'

import { useCloseAccountModal } from '../../../hooks/useCloseAccountModal/useCloseAccountModal'

export const CloseAccountLoading = () => {
  const { closeModal } = useCloseAccountModal()

  return (
    <AnnouncementModal
      Illustration={<SpinnerWrapped pt="24px" />}
      heading={translate('page.closeAccountModal.loading.heading')}
      description={translate('page.closeAccountModal.loading.description')}
      secondaryButtonLabel={translate('shared.cancel')}
      onClickSecondary={closeModal}
      onCancel={closeModal}
      isOpen
    />
  )
}
