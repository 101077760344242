import { gql, type TypedDocumentNode } from '@apollo/client'

export type useGetTotalCashFlowSubcategoryNetAmountFragment = {
  __typename?: 'CashFlowSubcategoryNetAmount'
  total: { __typename?: 'Money'; value: string }
}

export const useGetTotalCashFlowSubcategoryNetAmountFragmentDoc = gql`
  fragment useGetTotalCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
    total {
      value
    }
  }
` as unknown as TypedDocumentNode<
  useGetTotalCashFlowSubcategoryNetAmountFragment,
  undefined
>
