import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { CancelCardState } from './useGoToCancelCardStage.types'

export const useGoToCancelCardStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToCancelCardStage = useCallback(
    (state: CancelCardState | undefined) => {
      navigate(location.pathname, { state: { CancelCardModal: state } })
    },
    [navigate, location]
  )

  return { goToCancelCardStage }
}
