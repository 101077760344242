import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'

import { TransferContactFieldsSchema } from 'pages/Transfer/ContactTransfer'
import { useGoToContactTransferModal } from 'pages/Transfer/ContactTransfer/ContactTransferModal/hooks/useGoToContactTransferModal/useGoToContactTransferModal'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'

import { useTransferQuery } from '../useTransferQuery/useTransferQuery'

type OpenContactTransferModalArgs = {
  values: TransferFieldTypes
  accountById: ReturnType<typeof useTransferQuery>['accountById']
}

export const useOpenContactTransferModal = () => {
  const { openContactTransferModal: open } = useGoToContactTransferModal()

  const openContactTransferModal = useCallback(
    ({ values, accountById }: OpenContactTransferModalArgs) => {
      const contactValues = TransferContactFieldsSchema.safeParse(values)
      const fromAccount = accountById(
        contactValues.success ? contactValues.data.from : null
      )

      if (
        !contactValues.success ||
        !fromAccount ||
        !contactValues.data.toContact ||
        !contactValues.data.toPaymentInstrument
      ) {
        ErrorLogger.report(
          '[Banking] Invalid contact transfer values when opening modal.',
          undefined,
          { contactValues },
          'error'
        )
        return
      }

      open({
        stage: 'summary',
        values: {
          fromAccount: {
            id: fromAccount.id,
            name: fromAccount.name,
            icon: fromAccount.icon,
            accountNumber: fromAccount.accountDetails?.account ?? '-',
          },
          toContact: contactValues.data.toContact,
          toPaymentInstrument: contactValues.data.toPaymentInstrument,
          amount: contactValues.data.amount,
          reference: contactValues.data.reference,
          recipientReference: contactValues.data.recipientReference,
        },
      })
    },
    [open]
  )

  return { openContactTransferModal }
}
