import { Box, InputAdaptiveFieldMasked } from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'
import {
  combineValidators,
  validateDateYearRange,
  validateDoB,
  validateFirstName,
  validateLastName,
  validateMiddleName,
} from 'utils/formValidation'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { form } from 'translations'

interface Values {
  firstName?: string
  middleName?: string
  lastName?: string
  dob?: string
}

export interface IdentitySectionProps {
  values: Values
  withDoB?: boolean
  middleNameLabel?: string
  middleNamePlaceholder?: string
  focusFirstName?: () => void
  focusMiddleName?: () => void
  focusLastName?: () => void
  blurField?: () => void
}

export const Identity = ({
  values,
  withDoB = false,
  middleNameLabel,
  middleNamePlaceholder,
  focusFirstName,
  focusMiddleName,
  focusLastName,
  blurField,
}: IdentitySectionProps) => {
  const validateDOBDateRange = (value: string) =>
    validateDateYearRange(value, {
      min: 1899,
      max: dayjs(new Date()).year(),
    })
  return (
    <>
      <FormLine>
        <Box mb="2.5rem">
          <InputAdaptiveFieldWrapper
            label={form.shared.firstName}
            name="firstName"
            type="text"
            value={values.firstName}
            placeholder={form.identity.firstNamePlaceholder}
            validate={validateFirstName}
            onFocus={focusFirstName}
            onBlur={blurField}
            maxLength={20}
          />
        </Box>
        <Box mb="2.5rem">
          <InputAdaptiveFieldWrapper
            label={middleNameLabel || form.shared.middleName}
            name="middleName"
            value={values.middleName}
            onFocus={focusMiddleName}
            onBlur={blurField}
            placeholder={
              middleNamePlaceholder || form.identity.middleNamePlaceholder
            }
            validate={validateMiddleName}
            maxLength={20}
          />
        </Box>
      </FormLine>
      <FormLine fractions={[1]}>
        <Box mb="2.5rem">
          <InputAdaptiveFieldWrapper
            label={form.shared.lastName}
            name="lastName"
            type="text"
            validate={validateLastName}
            value={values.lastName}
            placeholder={form.identity.lastNamePlaceholder}
            onFocus={focusLastName}
            onBlur={blurField}
            maxLength={40}
          />
        </Box>

        {withDoB && (
          <Box width={['100%', '11rem']} mb="2.5rem">
            <InputAdaptiveFieldMasked
              label={form.shared.dob}
              name="dob"
              type="text"
              validate={combineValidators(validateDoB, validateDOBDateRange)}
              placeholder={form.identity.dobPlaceholder}
            />
          </Box>
        )}
      </FormLine>
    </>
  )
}
