import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetCustomerReferralCode } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'

import {
  GetCustomerReferralCode as GetCustomerReferralCodeType,
  GetCustomerReferralCodeVariables,
} from 'types/gql-types/GetCustomerReferralCode'

export const useGetReferralCode = () => {
  const { userData } = useLoggedInUser()
  const entityUuid = useSelectedEntityUuid()
  const customerId = userData?.id || ''
  const { loading, error, data, called, refetch } = useQuery<
    GetCustomerReferralCodeType,
    GetCustomerReferralCodeVariables
  >(GetCustomerReferralCode, {
    variables: {
      entityUuid,
      customerUuid: customerId,
    },
    skip: !customerId,
  })

  const entityReferral = useMemo(() => {
    return {
      referralCode: data?.getCustomer?.referralCode,
    }
  }, [data])

  return {
    error,
    isLoading: loading || (!!customerId && !called),
    getCustomerReferralCode: refetch,
    entityReferral,
  }
}
