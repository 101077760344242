import { Flex, SkeletonBasic } from '@npco/zeller-design-system'

export const SensitiveContentDesktopAndTabletLoading = () => {
  return (
    <Flex
      flexDirection="column"
      gridGap="4px"
      data-testid="debit-card-info-loading"
    >
      <SkeletonBasic width={48} height={8} isRounded />
      <SkeletonBasic width={122} height={8} isRounded />
      <SkeletonBasic width={88} height={8} isRounded />
    </Flex>
  )
}
