import { COLOR, MODAL_ZINDEX, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as Close } from 'assets/svg/close.svg'
import { ModalHeader } from 'components/Modal/ModalElements/ModalElements'
import { StyledCloseIconBox } from 'components/Modal/ModalElements/ModalElements.styled'

import { StyledModalBasic } from '../Modal.styled'
import { ModalBasicProps } from '../Modal.types'

export const ModalBasic = ({
  children,
  className,
  contentStyle = {},
  hasCloseButton,
  isFullScreen = false,
  isOpen,
  onCancel,
  overlayStyle = {},
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  testId = 'modal-basic',
  title,
  ...otherProps
}: ModalBasicProps) => {
  const content = { ...contentStyle }
  const overlay = { zIndex: MODAL_ZINDEX, ...overlayStyle }

  const style = isFullScreen
    ? { content, overlay: { display: 'flex', ...overlay } }
    : { content, overlay }

  return (
    <StyledModalBasic
      className={className}
      isFullScreen={isFullScreen}
      isOpen={isOpen}
      onRequestClose={onCancel}
      overlayClassName="modal-basic-overlay"
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={style}
      testId={testId}
      {...otherProps}
    >
      {hasCloseButton && (
        <StyledCloseIconBox
          type="button"
          onClick={onCancel}
          data-testid="modal-close-button"
        >
          <SvgIcon
            width="12"
            height="12"
            hoverColor={COLOR.GREY_550}
            withCursor
          >
            <Close />
          </SvgIcon>
        </StyledCloseIconBox>
      )}
      {title && <ModalHeader>{title}</ModalHeader>}
      {children}
    </StyledModalBasic>
  )
}
