import { type ReactNode } from 'react'

import { AccordionAnimation } from 'components/AccordionAnimation/AccordionAnimation'

import * as styled from './FiltersHeaderRow.styled'

export type FiltersHeaderRowProps = {
  isOpen?: boolean
  children?: ReactNode
}

export const FiltersHeaderRow = ({
  isOpen = false,
  children,
}: FiltersHeaderRowProps) => {
  return (
    <styled.Container $expanded={isOpen}>
      <AccordionAnimation isVisible={isOpen}>{children}</AccordionAnimation>
    </styled.Container>
  )
}
