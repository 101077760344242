import { Box } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledModalWrapper = styled(Box)`
  max-width: 576px;
  min-width: 275px;
  min-height: 300px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    max-width: initial;
  }
`

export const StyledUsersListWrapper = styled.div`
  max-height: 275px;
  margin: 0 -12px 0 -12px;
  overflow-y: auto;
`
