import { meanBy, sumBy } from 'lodash-es'

import { NetAmountWithOtherCategory } from '../../TransactionsSummary.types'
import { GetCashflowCategoriesNetAmountsQueryResponse } from '../hooks/useCashflowCategoriesNetAmounts/graphql/getCashflowCategoriesNetAmounts.generated'

export type CategoriesNetAmount = NonNullable<
  GetCashflowCategoriesNetAmountsQueryResponse['getCashFlowCategoriesNetAmounts']['categoryNetAmounts']
>[number]

export const getGroupedNetAmounts = (
  categoryNetAmounts: CategoriesNetAmount[]
): NetAmountWithOtherCategory<CategoriesNetAmount>[] => {
  if (categoryNetAmounts.length <= 10) {
    return categoryNetAmounts
  }

  const remainingCategoryNetAmounts = categoryNetAmounts.slice(9)
  return [
    ...categoryNetAmounts.slice(0, 9),
    {
      __typename: 'CashFlowCategoryNetAmount',
      noOfTransaction: sumBy(remainingCategoryNetAmounts, 'noOfTransaction'),
      average: {
        currency: 'AUD',
        value: meanBy(remainingCategoryNetAmounts, (sub) =>
          parseInt(sub?.average?.value ?? '0', 10)
        ).toString(),
        __typename: 'Money',
      },
      change: null,
      total: {
        currency: 'AUD',
        value: sumBy(remainingCategoryNetAmounts, (sub) =>
          parseInt(sub?.total?.value ?? '0', 10)
        ).toString(),
        __typename: 'Money',
      },
      category: 'other',
      otherCategoryCount: remainingCategoryNetAmounts.length,
      subcategoryCount: null,
    },
  ]
}
