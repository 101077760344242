import { BodyLargeTypography, Flex } from '@npco/zeller-design-system'
import { StyledSelectStandardItem } from 'design-system/Components/Select'
import styled from 'styled-components'

export const SelectStandardItem = styled(StyledSelectStandardItem)<{
  $disabled: boolean
}>`
  &:hover {
    background-color: ${({ $disabled }) => ($disabled ? 'transparent' : null)};
  }
`

export const ExportLabel = styled.p<{ $disabled: boolean }>`{
  ${BodyLargeTypography};
  padding-left: 12px;
  margin: 0;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.colors.GREY_250 : theme.colors.BLUE_1000};
`

export const IconWrapper = styled(Flex)<{ $disabled: boolean }>`
  svg path {
    fill: ${({ $disabled, theme }) =>
      $disabled ? theme.colors.GREY_250 : theme.colors.BLUE_1000};
  }
`
