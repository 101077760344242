import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  UpgradeAccountsCompleteLocationStateSchema,
  UpgradeAccountsCompleteStage,
  UpgradeAccountsCompleteState,
} from 'hooks/useGoToUpgradeAccountsCompleteStage/useGoToUpgradeAccountsCompleteStage.types'

const COMPLETE_CARD_STAGES = Object.values(UpgradeAccountsCompleteStage)

export const useUpgradeAccountsCompleteState = <
  T extends UpgradeAccountsCompleteState
>() => {
  const locationState = useLocationState(
    UpgradeAccountsCompleteLocationStateSchema
  )

  const state = useMemo(() => {
    if (
      !locationState?.UpgradeAccountsCompleteModal?.stage ||
      !COMPLETE_CARD_STAGES.includes(
        locationState.UpgradeAccountsCompleteModal.stage
      )
    ) {
      return null
    }

    return locationState.UpgradeAccountsCompleteModal as T
  }, [locationState?.UpgradeAccountsCompleteModal])

  return { state }
}
