import { Box } from '@npco/zeller-design-system'

import { TransactionTotal } from 'pages/Dashboard/Dashboard.types'
import { getCurrentMonth } from 'pages/Dashboard/utils/timeUtils'
import {
  SkeletonBodyDefault,
  SkeletonH3,
} from 'components/SkeletonLoader/SkeletonLoaderTypography'
import { page } from 'translations'

import { useSalesSummaries } from './hooks/useSalesSummaries'
import {
  StyledLoadingWrapper,
  StyledSummaries,
  StyledSummaryItemContainer,
} from './SalesOverview.styled'
import { SalesSummary } from './SalesSummary'

interface Props {
  salesTotals: TransactionTotal[]
  isLoading: boolean
}

export const SalesOverview = ({ salesTotals, isLoading }: Props) => {
  const { monthToDateSales, monthToDateVolume, todaySales, todayVolume } =
    useSalesSummaries({
      salesTotals,
    })

  if (isLoading) {
    return (
      <StyledLoadingWrapper>
        <Box flex="1">
          <Box maxWidth="11.5rem" width="100%">
            <SkeletonBodyDefault width="4.5rem" margin="0 0 0.5rem 0" />
            <SkeletonH3 width="100%" margin="0 0 1px 0" />
          </Box>
        </Box>
        <Box flex="1">
          <Box maxWidth="11.5rem" width="100%">
            <SkeletonBodyDefault width="4.5rem" margin="0 0 0.5rem 0" />
            <SkeletonH3 width="100%" margin="0 0 1px 0" />
          </Box>
        </Box>
      </StyledLoadingWrapper>
    )
  }

  return (
    <StyledSummaries justifyContent="flex-start">
      <StyledSummaryItemContainer>
        <SalesSummary
          title={getCurrentMonth()}
          value={monthToDateSales}
          volume={monthToDateVolume}
        />
      </StyledSummaryItemContainer>
      <StyledSummaryItemContainer>
        <SalesSummary
          title={page.dashboard.payments.sales.today}
          value={todaySales}
          volume={todayVolume}
        />
      </StyledSummaryItemContainer>
    </StyledSummaries>
  )
}
