import { gql } from '@apollo/client'

export const DeleteInvoice = gql`
  mutation DeleteInvoice($entityUuid: ID!, $referenceNumber: String!) {
    deleteInvoice(entityUuid: $entityUuid, referenceNumber: $referenceNumber) {
      __typename
      id
      referenceNumber
      status
    }
  }
`
