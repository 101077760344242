import { LinkCardError } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translate } from 'utils/translations'

export const getErrorMessageMapping = (linkCardError: LinkCardError) => {
  switch (linkCardError) {
    case LinkCardError.NotCardholderError:
      return translate('page.addCardModal.linkCard.notCardholderInfo')
    case LinkCardError.ManagerBlankCardError:
      return translate('page.addCardModal.linkCard.managerBlankCardError')
    case LinkCardError.CardIDNotFoundError:
    case LinkCardError.CardNumberLengthError:
      return translate('page.addCardModal.linkCard.cardIDNotFoundError')
    default:
      return translate('page.addCardModal.linkCard.cardIDNotFoundError')
  }
}
