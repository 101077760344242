import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseStartupModalsSessionConnectionFragmentDoc } from '../../useStartupModalsSession/useStartupModalsSession.generated'

const defaultOptions = {} as const
export type StartupModalsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type StartupModalsQueryResponse = {
  __typename?: 'Query'
  xeroBankFeed: {
    __typename?: 'Connection'
    id: string | null
    status: Types.ConnectionStatus | null
  }
  xeroPaymentServices: {
    __typename?: 'Connection'
    id: string | null
    status: Types.ConnectionStatus | null
  }
}

export const StartupModalsQuery = gql`
  query StartupModalsQuery($entityUuid: ID!) {
    xeroBankFeed: getConnectionState(
      connectionType: XERO_BANKFEEDS
      entityUuid: $entityUuid
    ) {
      id
      ...UseStartupModalsSessionConnectionFragment
    }
    xeroPaymentServices: getConnectionState(
      connectionType: XERO_PAYMENT_SERVICES
      entityUuid: $entityUuid
    ) {
      id
      ...UseStartupModalsSessionConnectionFragment
    }
  }
  ${UseStartupModalsSessionConnectionFragmentDoc}
` as unknown as TypedDocumentNode<
  StartupModalsQueryResponse,
  StartupModalsQueryVariables
>

/**
 * __useStartupModalsQuery__
 *
 * To run a query within a React component, call `useStartupModalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartupModalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartupModalsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useStartupModalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    StartupModalsQueryResponse,
    StartupModalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    StartupModalsQueryResponse,
    StartupModalsQueryVariables
  >(StartupModalsQuery, options)
}
export function useStartupModalsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartupModalsQueryResponse,
    StartupModalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    StartupModalsQueryResponse,
    StartupModalsQueryVariables
  >(StartupModalsQuery, options)
}
export type StartupModalsQueryHookResult = ReturnType<
  typeof useStartupModalsQuery
>
export type StartupModalsQueryLazyQueryHookResult = ReturnType<
  typeof useStartupModalsQueryLazyQuery
>
export type StartupModalsQueryQueryResult = Apollo.QueryResult<
  StartupModalsQueryResponse,
  StartupModalsQueryVariables
>
