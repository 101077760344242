import { COLOR } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ListItemIcon = styled.div`
  svg circle {
    fill: ${COLOR.GREEN_1000};
  }
`
