import { gql } from '@apollo/client'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import { DebitCardTransaction } from '../../TransactionDrawer.types'
import { TransactionContact } from './TransactionContact/TransactionContact'
import {
  TransactionContactDetailsDebitCardAccountTransactionFragment,
  TransactionContactDetailsDebitCardTransactionV2Fragment,
} from './TransactionContactDetails.generated'
import { UnlinkedContactDetails } from './UnlinkedContactDetails'

interface TransactionContactDetailsProps {
  transaction:
    | TransactionContactDetailsDebitCardTransactionV2Fragment
    | TransactionContactDetailsDebitCardAccountTransactionFragment
  onClose?: () => void
  isUnlinkingContact: boolean
  hasUnlinkingContactError: boolean
  unlinkContact: () => void
  updateTransaction: (update: Partial<DebitCardTransaction>) => void
}

export const TransactionContactDetails = ({
  transaction,
  onClose,
  isUnlinkingContact,
  hasUnlinkingContactError,
  unlinkContact,
  updateTransaction,
}: TransactionContactDetailsProps) => {
  const isAllowedContactLinking =
    transaction.__typename === 'DebitCardTransactionV2'
      ? [
          DebitCardTransactionTypeV2.DE_IN,
          DebitCardTransactionTypeV2.NPP_IN,
          DebitCardTransactionTypeV2.NPP_IN_RETURN,
          DebitCardTransactionTypeV2.DDA_OUT,
        ].includes(transaction.type)
      : [
          DebitCardAccountTransactionTypeEnum.DE_IN,
          DebitCardAccountTransactionTypeEnum.NPP_IN,
          DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN,
        ].includes(transaction.type)

  if (
    isAllowedContactLinking &&
    !transaction.contact?.id &&
    transaction.payerDetails?.senderUuid
  ) {
    return (
      <UnlinkedContactDetails
        senderUuid={transaction.payerDetails.senderUuid}
        updateTransaction={updateTransaction}
        debitCardTransactionUuid={transaction.id}
      />
    )
  }

  return (
    <TransactionContact
      debitCardTransactionUuid={transaction.id}
      contactUuid={transaction.contact?.id}
      onClose={onClose}
      isUnlinkingContact={isUnlinkingContact}
      hasUnlinkingContactError={hasUnlinkingContactError}
      unlinkContact={unlinkContact}
    />
  )
}

TransactionContactDetails.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransactionContactDetailsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      __typename
      id
      type
      contact {
        id
      }
      payerDetails {
        senderUuid
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment TransactionContactDetailsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      __typename
      id
      type
      contact {
        id
      }
      payerDetails {
        senderUuid
      }
    }
  `,
}
