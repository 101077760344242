import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import { translate } from 'utils/translations'

export const translateTransactionDetailsTypesV2 = (
  transactionType: DebitCardTransactionTypeV2
) => {
  switch (transactionType) {
    case DebitCardTransactionTypeV2.ATM_IN:
    case DebitCardTransactionTypeV2.ATM_OUT:
    case DebitCardTransactionTypeV2.PURCHASE:
    case DebitCardTransactionTypeV2.PURCHASE_CNP:
      return translate('page.transactionDetails.transactionTypes.cardPurchase')
    case DebitCardTransactionTypeV2.REFUND_CNP:
    case DebitCardTransactionTypeV2.REFUND:
      return translate('page.transactionDetails.transactionTypes.cardRefund')
    case DebitCardTransactionTypeV2.DE_IN:
    case DebitCardTransactionTypeV2.NPP_IN:
      return translate('page.transactionDetails.transactionTypes.transferIn')
    case DebitCardTransactionTypeV2.DE_OUT:
    case DebitCardTransactionTypeV2.NPP_OUT:
      return translate('page.transactionDetails.transactionTypes.transferOut')
    case DebitCardTransactionTypeV2.NPP_IN_RETURN:
    case DebitCardTransactionTypeV2.NPP_OUT_RETURN:
    case DebitCardTransactionTypeV2.DE_OUT_RETURN:
      return translate(
        'page.transactionDetails.transactionTypes.transferReturn'
      )
    case DebitCardTransactionTypeV2.DEPOSIT:
      return translate('page.transactionDetails.transactionTypes.deposit')
    case DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT:
      return translate(
        'page.transactionDetails.transactionTypes.adjustmentDeposit'
      )
    case DebitCardTransactionTypeV2.WITHDRAWAL:
    case DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL:
      return translate('page.transactionDetails.transactionTypes.withdrawal')
    case DebitCardTransactionTypeV2.BPAY_IN:
    case DebitCardTransactionTypeV2.BPAY_OUT:
      return translate('page.transactionDetails.transactionTypes.bpay')
    case DebitCardTransactionTypeV2.TRANSFER_IN:
      return translate(
        'page.transactionDetails.transactionTypes.internalTransferIn'
      )
    case DebitCardTransactionTypeV2.TRANSFER_OUT:
      return translate(
        'page.transactionDetails.transactionTypes.internalTransferOut'
      )
    case DebitCardTransactionTypeV2.INTEREST:
      return translate('page.transactionDetails.transactionTypes.interest')
    case DebitCardTransactionTypeV2.DDA_OUT:
      return translate('page.transactionDetails.transactionTypes.directDebit')
    default:
      return translate('page.transactionDetails.transactionTypes.other')
  }
}

export const translateTransactionDetailsTypesV3 = (
  transactionType: DebitCardAccountTransactionTypeEnum
) => {
  switch (transactionType) {
    case DebitCardAccountTransactionTypeEnum.ATM_IN:
    case DebitCardAccountTransactionTypeEnum.ATM_OUT:
    case DebitCardAccountTransactionTypeEnum.PURCHASE:
    case DebitCardAccountTransactionTypeEnum.PURCHASE_CNP:
      return translate('page.transactionDetails.transactionTypes.cardPurchase')
    case DebitCardAccountTransactionTypeEnum.REFUND_CNP:
    case DebitCardAccountTransactionTypeEnum.REFUND:
      return translate('page.transactionDetails.transactionTypes.cardRefund')
    case DebitCardAccountTransactionTypeEnum.DE_IN:
    case DebitCardAccountTransactionTypeEnum.NPP_IN:
      return translate('page.transactionDetails.transactionTypes.transferIn')
    case DebitCardAccountTransactionTypeEnum.DE_OUT:
    case DebitCardAccountTransactionTypeEnum.NPP_OUT:
      return translate('page.transactionDetails.transactionTypes.transferOut')
    case DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN:
    case DebitCardAccountTransactionTypeEnum.NPP_OUT_RETURN:
    case DebitCardAccountTransactionTypeEnum.DE_OUT_RETURN:
      return translate(
        'page.transactionDetails.transactionTypes.transferReturn'
      )
    case DebitCardAccountTransactionTypeEnum.DEPOSIT:
      return translate('page.transactionDetails.transactionTypes.deposit')
    case DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT:
      return translate(
        'page.transactionDetails.transactionTypes.adjustmentDeposit'
      )
    case DebitCardAccountTransactionTypeEnum.WITHDRAWAL:
    case DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL:
      return translate('page.transactionDetails.transactionTypes.withdrawal')
    case DebitCardAccountTransactionTypeEnum.BPAY_IN:
    case DebitCardAccountTransactionTypeEnum.BPAY_OUT:
      return translate('page.transactionDetails.transactionTypes.bpay')
    case DebitCardAccountTransactionTypeEnum.TRANSFER_IN:
      return translate(
        'page.transactionDetails.transactionTypes.internalTransferIn'
      )
    case DebitCardAccountTransactionTypeEnum.TRANSFER_OUT:
      return translate(
        'page.transactionDetails.transactionTypes.internalTransferOut'
      )
    default:
      return translate('page.transactionDetails.transactionTypes.other')
  }
}
