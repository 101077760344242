import { useCallback } from 'react'
import { FetchMoreQueryOptions, useLazyQuery } from '@apollo/client'

import type {
  GetInvoices as GetInvoicesResponse,
  GetInvoicesVariables,
} from 'types/gql-types/GetInvoices'

import { GetInvoices } from '../graphql/getInvoices'

export const useGetInvoices = () => {
  const [
    getInvoices,
    { error, loading, data, fetchMore, refetch, subscribeToMore, variables },
  ] = useLazyQuery<GetInvoicesResponse, GetInvoicesVariables>(GetInvoices, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const invoices = data?.getInvoices?.invoices
  const nextToken = data?.getInvoices?.nextToken

  const loadMore = useCallback(
    (options: FetchMoreQueryOptions<GetInvoicesVariables>) =>
      fetchMore?.(options),
    [fetchMore]
  )

  return {
    getInvoices,
    getInvoicesError: error,
    invoices,
    isLoadingInvoices: loading,
    loadMore,
    nextToken,
    refetch,
    subscribeToMore,
    variables,
  }
}
