import { gql, type TypedDocumentNode } from '@apollo/client'
import { IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragmentDoc } from '@npco/mp-feature-cashflow-reporting'

export type AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment =
  {
    __typename?: 'cashFlowPeriodicTotalAmount'
    transactionDirectionTotalAmounts: {
      __typename?: 'TransactionDirectionTotalAmounts'
      income: { __typename?: 'Money'; value: string }
      expense: { __typename?: 'Money'; value: string }
    }
  }

export const AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragmentDoc =
  gql`
    fragment AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment on cashFlowPeriodicTotalAmount {
      ...IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragment
    }
    ${IncomeAndExpenseLegendCashFlowPeriodicTotalAmountFragmentDoc}
  ` as unknown as TypedDocumentNode<
    AccountsIncomeAndExpenseTopSectionCashFlowPeriodicTotalAmountFragment,
    undefined
  >
