import * as Sentry from '@sentry/react'
import { SeverityLevel } from '@sentry/react'

export class ErrorLogger {
  static reportError(
    message: string,
    error: unknown,
    extra?: Record<string, unknown>
  ) {
    Sentry.captureException(error, { extra: { ...extra, message } })
  }

  static report(
    message: string,
    error?: unknown,
    extra?: Record<string, unknown>,
    level: SeverityLevel = 'warning'
  ) {
    Sentry.captureMessage(message, {
      level,
      extra: { ...extra, error },
    })
  }
}
