import { useMemo } from 'react'
import { SelectSelectedItem } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { FileListSelectItem } from '../File.types'
import {
  FileUploaderErrorType,
  getErrorMessageByType,
} from './FileUploaderError.utils'

interface FormValues {
  fileItem: {
    [key: string]: SelectSelectedItem<FileListSelectItem>
  }
}

export const useFileUploaderError = () => {
  const { errors } = useFormikContext<FormValues>()

  const errorMessage = useMemo(() => {
    if (errors.fileItem && Object.keys(errors.fileItem)) {
      return getErrorMessageByType(FileUploaderErrorType.REQUIRED_SELECT_OPTION)
    }

    return null
  }, [errors])
  return {
    errorMessage,
  }
}
