import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  tippingTitle: 'Enable tipping',
  tippingDescription:
    'Allow your customers to add a tip when they pay. Tip amounts are recorded in the Transactions section.',
  percentageTippingValues: 'Percentage Tipping Values',
  customTipAmountTitle: 'Custom Tip Amount',
  customTipAmountDescription:
    'Enable custom tips to allow your customers to enter their own tip amounts.',
  gstTitle: 'Apply GST',
  gstDescription:
    'Goods and Services Tax will be applied to sales at this Site. GST will be included in the sale price, and shown on Tax Invoices and reports.',
  tip1: 'Tip 1',
  tip2: 'Tip 2',
  tip3: 'Tip 3',
})
