import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Crop } from 'react-image-crop'

import {
  generateImagePreview,
  generateImagePreviewFile,
  initialCrop,
  processFile,
} from 'components/ModalUploadImage/ImageCropper/ImageCropper.utils'

interface UseImageCropperProps {
  onCrop?: (nextImage: string) => void
  onCropFile?: (file: File) => void
  fileType?: string
}

const INITIAL_CROP_PERCENTAGE = 90

export const useImageCropper = ({
  onCrop,
  onCropFile,
  fileType,
}: UseImageCropperProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)

  const [crop, setCrop] = useState<Crop>(initialCrop)
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null)

  const onLoad = useCallback((event: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget

    imgRef.current = event.currentTarget

    // NOTE: to prevent the crop ui from intersecting the image boundaries set
    // the appropriate dimension so this appears clearly when in landscape or
    // portrait view

    const dimension = width > height ? 'height' : 'width'

    const distance = Math.round(
      (event.currentTarget[dimension] * INITIAL_CROP_PERCENTAGE) / 100
    )
    const pixelCrop = {
      x: Math.round((width - distance) / 2),
      y: Math.round((height - distance) / 2),
      width: distance,
      height: distance,
      unit: 'px',
      aspect: 1,
    }
    setCrop(pixelCrop as Crop)
    setCompletedCrop(pixelCrop as Crop)
  }, [])

  const onCompletedCrop = (newCrop: Crop) => {
    setCompletedCrop(newCrop)
  }

  useEffect(() => {
    async function generatePreview() {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return
      }

      await processFile(completedCrop, previewCanvasRef.current, imgRef.current)

      if (onCropFile) {
        generateImagePreviewFile(
          previewCanvasRef.current,
          completedCrop,
          onCropFile,
          fileType
        )
      }
      if (onCrop) {
        generateImagePreview(
          previewCanvasRef.current,
          completedCrop,
          onCrop,
          fileType
        )
      }
    }

    generatePreview()
  }, [completedCrop, fileType, onCrop, onCropFile])

  return {
    crop,
    imgRef,
    onCompletedCrop,
    onLoad,
    previewCanvasRef,
    setCompletedCrop,
    setCrop,
  }
}
