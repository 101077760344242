import { InfoBox } from '@npco/zeller-design-system'

import { page } from 'translations'

export const NoSales = () => {
  return (
    <InfoBox>
      <InfoBox.Message>
        {page.dashboard.payments.sales.noResults}
      </InfoBox.Message>
    </InfoBox>
  )
}
