import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { DebitCardAccountType } from '@npco/mp-gql-types'

export const useSetSelectedAccount = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const setSelectedAccount = useCallback(
    ({ id, type }: { id: string; type: DebitCardAccountType }) => {
      navigate(`${location.pathname}?accountId=${id}&accountType=${type}`, {
        replace: true,
      })
    },
    [location.pathname, navigate]
  )

  const resetSelectedAccount = useCallback(() => {
    navigate(location.pathname, {
      replace: true,
    })
  }, [location.pathname, navigate])

  return {
    setSelectedAccount,
    resetSelectedAccount,
  }
}
