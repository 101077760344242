import { ApolloError, gql, useReactiveVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import { Box, Divider } from '@npco/zeller-design-system'
import { rvCurrentUserSites } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { CustomerDetailsForm } from 'types/customers'
import { ProfilePersonalInformation } from 'pages/Settings/Profile/Personal/Edit/ProfilePersonalInformation/ProfilePersonalInformation'
import { SettingsFormLayout, Title } from 'layouts'
import { EmailField } from 'forms/formSections/UserDetails/EmailField'
import { PhoneField } from 'forms/formSections/UserDetails/PhoneField'
import { AssignSites } from 'components/AssignSites'
import { FormLine } from 'components/FormLine'
import { UserInvitationPendingNotification } from 'components/UserInvitationPendingNotification/UserInvitationPendingNotification'
import { page } from 'translations'

import { useAdminKycCheck } from '../hooks/useAdminKycCheck'
import { useGetEntitySites } from '../hooks/useGetEntitySites/useGetEntitySites'
import { useGetUserSites } from '../hooks/useGetUserSites'
import { IDVerificationStatusFormLine } from '../IDVerificationStatusFormLine/IDVerificationStatusFormLine'
import { StyledWrapper } from '../User.styled'
import { AssignedCardsSection } from './AssignedCardsSection/AssignedCardsSection'
import { PermissionSection } from './PermissionSection/PermissionSection'
import {
  UserFormCustomerFragment,
  UserFormDebitCardV2Fragment,
} from './UserForm.generated'
import { StyledUserInvitationPendingContainer } from './UserForm.styled'
import { UserPermissions } from './UserPermissions/UserPermissions'
import { UserRemove } from './UserRemove/UserRemove'
import { XeroPaymentServicesSection } from './XeroPaymentServicesSection/XeroPaymentServicesSection'

const { personalDetails } = page.settings.users

const { ADMIN } = CustomerRole

const formLineFractions = [1, 2]
const nameFieldNames = ['firstname', 'middlename', 'lastname']

type UserFormProps = {
  customerUuid: string
  deleteCustomer: () => void
  customerDetails?: UserFormCustomerFragment
  isCustomerDetailsLoading: boolean
  isEditForm: boolean
  isEmailDisabled: boolean
  isInvitationPending: boolean
  isInvoicesEnabled: boolean
  isXeroPaymentServicesEnabled: boolean
  redirect: () => void
  loadingDeleteCustomer: boolean
  associatedCards: UserFormDebitCardV2Fragment[]
  isCardsLoading: boolean
  cardError?: ApolloError
  cardRefetch: () => void
  isAdminRole: boolean
}

export const UserForm = ({
  customerUuid,
  deleteCustomer,
  customerDetails,
  isCustomerDetailsLoading,
  isEditForm,
  isEmailDisabled,
  isInvitationPending,
  isInvoicesEnabled,
  isXeroPaymentServicesEnabled,
  redirect,
  loadingDeleteCustomer,
  isCardsLoading,
  associatedCards,
  cardRefetch,
  cardError,
  isAdminRole,
}: UserFormProps) => {
  const { initialValues, values, isSubmitting, setFieldValue, submitForm } =
    useFormikContext<CustomerDetailsForm>()
  const isAdmin = values.role === ADMIN
  const { entitySites, isSitesLoading } = useGetEntitySites()
  const currentSites = useReactiveVar(rvCurrentUserSites)

  const { userSites, addSites, removeSite } = useGetUserSites({
    initialRole: initialValues.role as CustomerRole,
    isAdmin,
    isEditForm,
  })

  const { getIsAdminAndRCAbandoned } = useAdminKycCheck({
    isEditForm,
    selectedUsersKycStatus: initialValues.kycStatus,
  })

  return (
    <SettingsFormLayout
      dataTestId={isEditForm ? 'user-edit' : 'user-create'}
      onBackButtonClick={redirect}
      onSubmitButtonClick={submitForm}
      submitButtonLabel={
        isEditForm
          ? translate('page.settings.users.save')
          : translate('page.settings.users.createUser')
      }
      isSubmitButtonDisabled={
        isSubmitting || getIsAdminAndRCAbandoned(values.role)
      }
    >
      <StyledWrapper isEditMode={isEditForm}>
        {isEditForm && isInvitationPending && (
          <StyledUserInvitationPendingContainer>
            <UserInvitationPendingNotification userId={customerUuid} />
          </StyledUserInvitationPendingContainer>
        )}
        <Box mb="16px">
          <Title>{personalDetails}</Title>
        </Box>
        <ProfilePersonalInformation
          areNameFieldsDisabled={isEditForm}
          isRequired={!isEditForm}
          fieldNames={nameFieldNames}
        />
        <FormLine fractions={formLineFractions}>
          <PhoneField isDisabled={isEditForm} phone={values.phone} />
          <EmailField isDisabled={isEmailDisabled} />
        </FormLine>
        <IDVerificationStatusFormLine isEditForm={isEditForm} />
        <Divider />
        <UserPermissions isEditForm={isEditForm} />
        <Divider />
        {isInvoicesEnabled && (
          <PermissionSection
            description={
              isAdmin
                ? translate('page.settings.users.invoices.descriptionAdmin')
                : translate('page.settings.users.invoices.descriptionManager')
            }
            fieldName="allowZellerInvoices"
            isAdmin={isAdmin}
            title={translate('page.settings.users.invoices.settingsTitle')}
          />
        )}
        <PermissionSection
          description={
            isAdmin
              ? translate('page.settings.users.itemManagement.descriptionAdmin')
              : translate(
                  'page.settings.users.itemManagement.descriptionManager'
                )
          }
          fieldName="allowItemManagement"
          isAdmin={isAdmin}
          title={translate('page.settings.users.itemManagement.title')}
        />
        <PermissionSection
          description={
            isAdmin
              ? translate('page.settings.users.discount.descriptionAdmin')
              : translate('page.settings.users.discount.descriptionManager')
          }
          fieldName="allowDiscountManagement"
          isAdmin={isAdmin}
          title={translate('page.settings.users.discount.title')}
        />
        {isXeroPaymentServicesEnabled && isAdmin && (
          <div data-testid="xero-payment-services-section">
            <XeroPaymentServicesSection />
            <Divider />
          </div>
        )}
        <AssignSites
          isAdmin={isAdmin}
          entitySites={entitySites}
          userSites={userSites}
          addSites={addSites}
          removeSite={removeSite}
          setFieldValue={setFieldValue}
        />
        <AssignedCardsSection
          cardError={cardError}
          cardRefetch={cardRefetch}
          isCardsLoading={isCardsLoading}
          cards={associatedCards}
          isAdmin={isAdmin}
          isEditForm={isEditForm}
          kycStatus={values.kycStatus ?? undefined}
        />
        {isEditForm && (
          <UserRemove
            deleteCustomer={deleteCustomer}
            registeringIndividual={Boolean(values.registeringIndividual)}
            loadingDeleteCustomer={loadingDeleteCustomer}
            associatedCards={associatedCards}
            associatedSites={isAdminRole ? entitySites : currentSites}
            isCardsLoading={isCardsLoading}
            customerDetails={customerDetails}
            isCustomerDetailsLoading={isCustomerDetailsLoading}
            isSitesLoading={isSitesLoading}
          />
        )}
      </StyledWrapper>
    </SettingsFormLayout>
  )
}

UserForm.fragments = {
  Customer: gql`
    fragment UserFormCustomerFragment on Customer {
      id
      entityUuid
      ...UserRemoveCustomerFragment
    }
    ${UserRemove.fragments.Customer}
  `,
  DebitCardV2: gql`
    fragment UserFormDebitCardV2Fragment on DebitCardV2 {
      ...UserRemoveDebitCardV2Fragment
      ...AssignedCardsSectionDebitCardV2Fragment
    }
    ${UserRemove.fragments.DebitCardV2}
    ${AssignedCardsSection.fragments.DebitCardV2}
  `,
  Site: gql`
    fragment UserFormSiteFragment on Site {
      ...UserRemoveSiteFragment
      ...AssignSitesSiteFragment
    }
    ${UserRemove.fragments.Site}
    ${AssignSites.fragments.Site}
  `,
}
