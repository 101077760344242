import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  Flex,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { SignUpSidebarItemList } from '../SignUpSidebarItemList/SignUpSidebarItemList'
import { SidebarImages } from './SidebarImages/SidebarImages'
import { signUpSidebarTranslations } from './SignUpSidebar.i18n'
import * as styled from './SignUpSidebar.styled'

export const SignUpSidebar = () => {
  const t = useTranslations(signUpSidebarTranslations)
  const isMobileOrTabletBreakpoint = useIsMobileResolution(BREAKPOINT.MD)
  const benefitList = [
    t('benefit1'),
    t('benefit2'),
    t('benefit3'),
    t('benefit4'),
    t('benefit5'),
  ]

  return (
    <styled.SidebarWrapper data-testid="sign-up-sidebar" as="aside">
      {isMobileOrTabletBreakpoint ? (
        <styled.SidebarContentWrapper>
          <styled.SidebarSubtitle data-testid="sidebar-subtitle">
            {t('subtitle')}
          </styled.SidebarSubtitle>
          <Flex
            flexDirection="column"
            gap="16px"
            width={{ _: '100%', SM: '440px' }}
            marginX="auto"
          >
            <SidebarImages />
            <styled.SidebarTitle>{t('title')}</styled.SidebarTitle>
            <SignUpSidebarItemList items={benefitList} />
          </Flex>
        </styled.SidebarContentWrapper>
      ) : (
        <Flex flexDirection="column" height="100%">
          <styled.SidebarContentWrapper>
            <Flex flexDirection="column" gap="16px">
              <styled.SidebarTitle>{t('title')}</styled.SidebarTitle>
              <styled.SidebarSubtitle data-testid="sidebar-subtitle">
                {t('subtitle')}
              </styled.SidebarSubtitle>
              <SignUpSidebarItemList items={benefitList} />
            </Flex>
          </styled.SidebarContentWrapper>
          <SidebarImages />
        </Flex>
      )}
    </styled.SidebarWrapper>
  )
}
