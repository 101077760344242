import { InvoiceStatus } from '@npco/mp-gql-types'
import {
  BREAKPOINT,
  COLOR,
  Heading,
  HeadingStyles,
} from '@npco/zeller-design-system'
import { StatusColor } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import styled from 'styled-components'

export const StickyHeading = styled(Heading.H2).attrs({
  withStyles: HeadingStyles.H5,
})`
  background: ${COLOR.WHITE};
  padding-left: 8px;
  padding-bottom: 16px;
  line-height: 23px;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;

  & th {
    background: ${COLOR.WHITE};
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    user-select: none;
    white-space: nowrap;
    width: 1px;
  }
`

export const Row = styled.tr<{ $status?: InvoiceStatus }>`
  overflow: hidden;
  position: relative;

  & td {
    vertical-align: top;
    box-sizing: border-box;
  }

  & .date,
  & .status,
  & .total-amount {
    color: ${({ $status = InvoiceStatus.SENT }) =>
      StatusColor[$status] || COLOR.BLACK_900};
  }

  & .row-link {
    text-decoration: none;
    color: ${COLOR.BLACK_900};

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 1px;
      bottom: 1px;
    }
  }

  @media screen and (max-width: ${BREAKPOINT.SM}px) {
    border-bottom: 1px solid ${COLOR.GREY_90};
    display: flex;
    flex-wrap: wrap;
    padding: 12px 6px 12px 6px;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      background: ${COLOR.GREY_20};
    }

    & .title {
      order: 1;
      width: 75%;
    }

    & .total-amount {
      order: 2;
      text-align: right;
      width: 25%;

      color: ${({ $status = InvoiceStatus.SENT }) =>
        $status !== InvoiceStatus.PAID
          ? StatusColor[$status]
          : COLOR.BLACK_900};
    }

    & .date {
      order: 3;
    }

    & .reference-number {
      order: 4;
      flex-grow: 5;

      &::before {
        content: '·';
        padding: 0 4px 0 6px;
      }
    }

    & .status {
      order: 5;
      text-align: right;
      align-self: right;
    }
  }

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: 100%;

    & td {
      border-bottom: 1px solid ${COLOR.GREY_90};
      padding: 22px 6px 22px 6px;
    }

    &:hover td {
      background: ${COLOR.GREY_20};
    }

    & .total-amount {
      text-align: right;
    }

    & .title {
      overflow-wrap: anywhere;
    }

    & .date,
    & .status,
    & .reference-number,
    & .total-amount {
      min-width: 128px;
      width: ${(128 / 1024) * 100}%;
    }
  }
`

export const SkeletonRow = styled.tr`
  @media screen and (max-width: ${BREAKPOINT.SM}px) {
    border-bottom: 1px solid ${COLOR.GREY_90};
    padding: 12px 6px 12px 6px;
    font-size: 14px;
    line-height: 20px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    & td:nth-child(1) {
      grid-column: 1 / span 7;
      grid-row: 1;
    }
    & td:nth-child(2) {
      grid-column: 10 / span 3;
      grid-row: 1;
    }
    & td:nth-child(3) {
      grid-column: 1 / span 2;
      grid-row: 2;
    }
    & td:nth-child(4) {
      grid-column: 3 / span 3;
      grid-row: 2;
      padding-left: 8px;
    }
    & td:nth-child(5) {
      grid-column: 11 / span 2;
      grid-row: 2;
    }
    &:nth-child(3n-1) {
      & td:nth-child(1) {
        grid-column: 1 / span 8;
      }
      & td:nth-child(2) {
        padding-left: 20%;
      }
      & td:nth-child(5) {
        padding-left: 25%;
      }
    }
    &:nth-child(3n) {
      & td:nth-child(1) {
        grid-column: 1 / span 6;
      }
      & td:nth-child(5) {
        padding-left: 5%;
      }
    }
  }
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    width: 100%;
    & td {
      border-bottom: 1px solid ${COLOR.GREY_90};
      box-sizing: border-box;
      min-width: 128px;
      padding: 21.75px 6px;
      width: ${(128 / 1024) * 100}%;
    }
    & td:nth-child(1) {
      p {
        padding-right: ${(24 / 116) * 100}%;
      }
    }
    & td:nth-child(2) {
      p {
        padding-right: ${(40 / 116) * 100}%;
      }
    }
    & td:nth-child(3) {
      width: auto;
      p {
        padding-right: ${(240 / 508) * 100}%;
      }
    }
    & td:nth-child(4) {
      p {
        padding-right: ${(32 / 116) * 100}%;
      }
    }
    & td:nth-child(5) {
      p {
        padding-left: ${(24 / 116) * 100}%;
      }
    }
    &:nth-child(3n-1) {
      & td:nth-child(1),
      & td:nth-child(4) {
        p {
          padding-right: ${(48 / 116) * 100}%;
        }
      }
      & td:nth-child(2) {
        p {
          padding-right: ${(64 / 116) * 100}%;
        }
      }
      & td:nth-child(3) {
        p {
          padding-right: ${(80 / 508) * 100}%;
        }
      }
      & td:nth-child(5) {
        p {
          padding-left: ${(48 / 116) * 100}%;
        }
      }
    }
    &:nth-child(3n) {
      & td:nth-child(1),
      & td:nth-child(2) {
        p {
          padding-right: ${(24 / 116) * 100}%;
        }
      }
      & td:nth-child(3) {
        p {
          padding-right: ${(360 / 508) * 100}%;
        }
      }
      & td:nth-child(4) {
        p {
          padding-right: ${(12 / 116) * 100}%;
        }
      }
    }
  }
`
