import { DebitCardColour } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { ZellerCard } from 'components/ZellerCard/ZellerCard'

import { cardDesignReviewTranslations } from './CardDesignReview.i18n'
import { EditButton } from './CardDesignReview.styled'

interface CardDesignReviewProps {
  cardColour: DebitCardColour
  isLoading: boolean
  onEdit: () => void
}

export const CardDesignReview = ({
  cardColour,
  isLoading,
  onEdit,
}: CardDesignReviewProps) => {
  const t = useTranslations(cardDesignReviewTranslations)

  return (
    <>
      <Box width="219px">
        <ZellerCard
          debitCard={{ colour: cardColour }}
          variant="default"
          alt={t('designAlt')}
          width="219px"
          height="139px"
        />
      </Box>
      <EditButton onClick={onEdit} disabled={isLoading}>
        {t('editStyleButton')}
      </EditButton>
    </>
  )
}
