import React from 'react'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as GaugeIcon } from 'assets/svg/no-transactions.svg'
import { StyledEmptyBillingHistoryWrapper as Wrapper } from 'pages/Settings/Sim/BillingHistory/EmptyBillingHistory/EmptyBillingHistory.styled'
import {
  StyledExtraLargeDescription,
  StyledIconBox,
  StyledLargeTitle,
  StyledTextContainer,
} from 'components/Placeholders/SharedElements.styled'
import { page } from 'translations'

export const EmptyBillingHistory = () => {
  return (
    <Wrapper>
      <StyledIconBox alignItems="center" justifyContent="center">
        <SvgIcon width="22px" height="22px" color={COLOR.BLUE_1000}>
          <GaugeIcon />
        </SvgIcon>
      </StyledIconBox>
      <StyledTextContainer>
        <StyledLargeTitle>
          {page.settings.sim.billingHistory.emptyState.title}
        </StyledLargeTitle>
        <StyledExtraLargeDescription>
          {page.settings.sim.billingHistory.emptyState.description}
        </StyledExtraLargeDescription>
      </StyledTextContainer>
    </Wrapper>
  )
}
