import { EntityCategories } from '@npco/mp-gql-types'

import { useSubcategoryDisplay } from 'hooks/subcategories/useSubcategoryDisplay/useSubcategoryDisplay'
import { CategoryDisplay } from 'components/CategoryDisplay/CategoryDisplay'

interface Props {
  category: EntityCategories | null
  subcategoryId: string | null | undefined
}

export const SubcategoryItem = ({ category, subcategoryId }: Props) => {
  const { subcategory, isLoading, error } = useSubcategoryDisplay({
    category,
    subcategoryId,
  })

  return (
    <CategoryDisplay
      isLoadingSubcategory={isLoading}
      hasError={Boolean(error)}
      subcategoryName={subcategory?.name}
    />
  )
}
