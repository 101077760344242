import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  ErrorMessageForm,
  Flex,
  RadioWithLabel,
  Typography,
} from '@npco/zeller-design-system'
import { useField } from 'formik'

import { shared } from 'translations'

import { markAsSelfTranslations } from './MarkAsSelf.i18n'
import { useMarkAsSelfValidate } from './useMarkAsSelfValidate'

type MarkAsSelfProps = {
  name: string
  contactType?: ContactType
}

export const MarkAsSelf = ({ name, contactType }: MarkAsSelfProps) => {
  const t = useTranslations(markAsSelfTranslations)
  const [{ value }, { error, touched }, { setValue }] = useField<
    boolean | undefined
  >({
    name,
    validate: useMarkAsSelfValidate(t('errorRequired')),
  })

  return (
    <Flex padding="12px 8px 20px 8px" flexDirection="column" aria-label={name}>
      <Flex justifyContent="space-between">
        <Typography variant="heading-md">
          {contactType === ContactType.BUSINESS
            ? t('businessTypeRadioDescription')
            : t('personTypeRadioDescription')}
        </Typography>
        <Flex gap="16px">
          <RadioWithLabel
            id="1"
            value="true"
            title={shared.yes}
            onChange={() => setValue(true)}
            checked={value === true}
          />
          <RadioWithLabel
            id="2"
            value="false"
            title={shared.no}
            onChange={() => setValue(false)}
            checked={value === false}
          />
        </Flex>
      </Flex>
      <ErrorMessageForm hasError={touched && !!error} errorMessage={error} />
    </Flex>
  )
}
