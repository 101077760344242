import { gql } from '@apollo/client'

import { AccountBalanceListHeader } from '../../../AccountBalanceListHeader'
import { AccountBalanceListView } from '../../../AccountBalanceListView/AccountBalanceListView'

export const AccountBalanceListQuery = gql`
  query AccountBalanceListQuery($entityUuid: ID!) {
    getDebitCardAccountsV2(limit: 50, entityUuid: $entityUuid) {
      accounts {
        __typename
        id
        status
        ...AccountBalanceListViewDebitCardAccountV2Fragment
      }
    }
    getSavingsAccounts(entityUuid: $entityUuid) {
      __typename
      id
      status
      ...AccountBalanceListViewDebitCardAccountV2Fragment
    }
    getEntity(entityUuid: $entityUuid) {
      __typename
      id
      ...AccountBalanceListHeaderEntityFragment
      ...AccountBalanceListViewEntityFragment
    }
  }

  ${AccountBalanceListView.fragments.DebitCardAccountV2}
  ${AccountBalanceListHeader.fragments.Entity}
  ${AccountBalanceListView.fragments.Entity}
`
