import { useMemo } from 'react'

import { Deposit } from 'types/deposits'

interface UseDrawerNavigationProps {
  groupedDeposits: Deposit[][]
  selectedDeposit: Deposit | null
  setSelectedDeposit: React.Dispatch<React.SetStateAction<Deposit | null>>
}

export const useDrawerNavigation = ({
  groupedDeposits,
  selectedDeposit,
  setSelectedDeposit,
}: UseDrawerNavigationProps) => {
  const flattenDeposits = useMemo(() => {
    return groupedDeposits.flat(1)
  }, [groupedDeposits])

  const nextDeposit = useMemo(() => {
    if (!selectedDeposit) {
      return null
    }
    const currentDepositIndex = flattenDeposits.findIndex(
      ({ id }) => id === selectedDeposit?.id
    )
    if (currentDepositIndex === -1) {
      return null
    }

    return flattenDeposits[currentDepositIndex + 1] ?? null
  }, [flattenDeposits, selectedDeposit])

  const previousDeposit = useMemo(() => {
    if (!selectedDeposit) {
      return null
    }
    const currentDepositIndex = flattenDeposits.findIndex(
      ({ id }) => id === selectedDeposit?.id
    )
    if (currentDepositIndex === -1) {
      return null
    }

    return flattenDeposits[currentDepositIndex - 1] ?? null
  }, [flattenDeposits, selectedDeposit])

  const onSelectNextDeposit = useMemo(() => {
    if (!nextDeposit) {
      return undefined
    }

    return () => {
      setSelectedDeposit(nextDeposit)
    }
  }, [nextDeposit, setSelectedDeposit])

  const onSelectPreviousDeposit = useMemo(() => {
    if (!previousDeposit) {
      return undefined
    }

    return () => {
      setSelectedDeposit(previousDeposit)
    }
  }, [previousDeposit, setSelectedDeposit])

  return {
    onSelectNextDeposit,
    onSelectPreviousDeposit,
  }
}
