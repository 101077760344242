import { useCallback, useEffect } from 'react'
import { ConnectionType } from '@npco/mp-gql-types'
import {
  BodyDefault,
  ButtonFill,
  Flex,
  H3,
  SelectStandard,
  Spinner,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'
import { head } from 'ramda'

import { translate } from 'utils/translations'

import { ErrorMessage } from '../components/Form.styled'
import { useXeroOrganisations } from '../hooks/useXeroOrganisations'
import { Values } from './OrganisationAccountForm.types'

export const StepOne = () => {
  const { values, errors, setFieldValue, setFieldError } =
    useFormikContext<Values>()
  const { isLoading, organisations } = useXeroOrganisations(
    ConnectionType.XERO_BANKFEEDS
  )

  const handleNext = useCallback(() => {
    if (values.organisation === '') {
      setFieldError(
        'organisation',
        translate(
          'page.settings.connections.xeroBankFeeds.errors.noOrganisationSelected'
        )
      )
      return
    }
    setFieldValue('step', 2)
  }, [setFieldValue, setFieldError, values])

  useEffect(() => {
    if (organisations.length === 1) {
      const firstOrg = head(organisations)
      setFieldValue('organisation', firstOrg?.value)
    }
    // including setFieldValue in deps causes infinte render loop
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [organisations])

  if (isLoading) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <H3>
        {translate(
          'page.settings.connections.xeroBankFeeds.organisationSelect.title'
        )}
      </H3>
      <BodyDefault>
        {translate(
          'page.settings.connections.xeroBankFeeds.organisationSelect.description'
        )}
      </BodyDefault>
      <br />
      <SelectStandard
        placeholder="Organisation"
        items={organisations}
        selectedItem={organisations.find(
          (o) => o.value === values.organisation
        )}
        onChange={(e) => setFieldValue('organisation', e?.value)}
      />

      {errors?.organisation && (
        <ErrorMessage>{errors.organisation}</ErrorMessage>
      )}
      <br />
      <br />
      <ButtonFill fullWidth onClick={handleNext}>
        {translate('page.settings.connections.xeroBankFeeds.cta.next')}
      </ButtonFill>
    </>
  )
}
