import { ReactNode } from 'react'
import {
  BoxProps,
  Flex,
  SkeletonH5,
  Typography,
} from '@npco/zeller-design-system'

import * as styled from './DetailsField.styled'

type DetailsFieldProps = BoxProps & {
  isLoading?: boolean
  label?: ReactNode
  value: ReactNode
  valueColor?: string
  dataTestId?: string
}

export const DetailsField = ({
  isLoading,
  label,
  value,
  valueColor,
  dataTestId,
  ...boxProps
}: DetailsFieldProps) => {
  const formattedValue =
    typeof value === 'string' ? (
      <styled.DetailsValue data-testid="details-field-value" color={valueColor}>
        {value}
      </styled.DetailsValue>
    ) : (
      value
    )

  return (
    <Flex
      justifyContent="space-between"
      pb="8px"
      data-testid={dataTestId ?? 'details-field'}
      {...boxProps}
    >
      <>
        <Typography
          data-testid="details-field-label"
          variant="heading-md"
          component="h4"
        >
          {label}
        </Typography>
        {isLoading ? <SkeletonH5 width="118px" margin="0" /> : formattedValue}
      </>
    </Flex>
  )
}
