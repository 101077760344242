import { AnnouncementModal, ModalBasic } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SuccessAnnouncementModal = styled(AnnouncementModal)`
  ${ModalBasic.SecondaryButton} {
    padding-left: 0;
    padding-right: 0;
  }

  .illustration-schedule {
    margin: 24px 0 16px 0;
  }
`
