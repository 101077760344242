import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { EditDebitCardTransactionCategoryLocationStateSchema } from '../../EditDebitCardTransactionCategory.types'

export const useEditDebitCardTransactionCategoryState = () => {
  const locationState = useLocationState(
    EditDebitCardTransactionCategoryLocationStateSchema
  )

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.EditDebitCardTransactionCategoryModal
  }, [locationState])

  return { state }
}
