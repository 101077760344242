import {
  InvoiceDiscountConfig,
  InvoiceItemUnit,
  InvoiceStatus,
} from '@npco/mp-gql-types'
import currency from 'currency.js'

import {
  INVOICE_DEFAULT_PERCENTAGE,
  INVOICE_DEFAULT_PRICE,
  INVOICE_DEFAULT_UNIT_QUANTITY,
  INVOICE_MAX_AMOUNT_IN_CENTS,
  INVOICE_MIN_AMOUNT_IN_CENTS,
} from '../../Invoice.constants'
import { InvoiceFormFields, InvoiceItem } from '../../Invoice.types'

export const defaultInvoiceLineItem: InvoiceItem = {
  catalogItemUuid: '',
  description: '',
  discount: {
    config: InvoiceDiscountConfig.PERCENTAGE,
    percentage: INVOICE_DEFAULT_PERCENTAGE,
    price: INVOICE_DEFAULT_PRICE,
  },
  hasCalculation: false,
  id: undefined,
  initialDescription: '',
  initialName: '',
  initialPrice: currency(0, { precision: 4 }),
  initialQuantity: INVOICE_DEFAULT_UNIT_QUANTITY,
  key: 1,
  name: '',
  price: currency(0, { precision: 4 }),
  quantity: INVOICE_DEFAULT_UNIT_QUANTITY,
  taxApplicable: true,
  unit: InvoiceItemUnit.QUANTITY,
}

export const initialStatus = {
  emailInitialised: false,
  hasAppliedSettings: false,
  smsInitialised: false,
}

export const initialValues: InvoiceFormFields = {
  customer: {
    attentionToContact: null,
    payerContact: null,
  },
  discount: {
    config: InvoiceDiscountConfig.PERCENTAGE,
    percentage: INVOICE_DEFAULT_PERCENTAGE,
    price: INVOICE_DEFAULT_PRICE,
  },
  discountsEnabled: false,
  title: {
    header: '',
    message: '',
  },
  id: undefined,
  items: [defaultInvoiceLineItem],
  itemsApplyTax: true,
  itemsTaxInclusive: true,
  maximumLimit: currency(INVOICE_MAX_AMOUNT_IN_CENTS, {
    fromCents: true,
    precision: 2,
  }),
  minimumLimit: currency(INVOICE_MIN_AMOUNT_IN_CENTS, {
    fromCents: true,
    precision: 2,
  }),
  schedule: {
    invoiceDate: '',
    paymentTerms: null,
    dueDate: '',
    sendEnabled: false,
    sendDate: '',
  },
  delivery: {
    email: {
      bcc: [],
      cc: [],
      isEnabled: false,
      message: null,
      recipient: '',
      sendMeCopy: false,
      subject: null,
    },
    sms: {
      isEnabled: false,
      recipient: '',
    },
  },
  referenceNumber: '',
  requiredEmailUpdateBeforeSend: [],
  status: InvoiceStatus.DRAFT,
}
