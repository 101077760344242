import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, InfoBox, INFOBOX_VARIANT } from '@npco/zeller-design-system'

import { SUPPORT_PHONE } from 'const/externalLinks'
import { ROOT } from 'const/routes'
import { NoWrapText } from 'components/NoWrapText/NoWrapText'

import { CannotPayByCardWarningMessageEntityFragment } from './CannotPayByCardWarningMessage.generated'
import { cannotPayByCardTranslations } from './CannotPayByCardWarningMessage.i18n'

interface CannotPayByCardWarningMessageProps {
  entity: CannotPayByCardWarningMessageEntityFragment | null
}

export const CannotPayByCardWarningMessage = ({
  entity,
}: CannotPayByCardWarningMessageProps) => {
  const t = useTranslations(cannotPayByCardTranslations)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const cannotPayByCard = useMemo(() => {
    if (entity && !entity.canPayByCard) {
      return false
    }
    return true
  }, [entity])

  const handleGoToContactZellerSupport = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).HELP.path)
  }, [navigate, shortEntityId])

  if (cannotPayByCard) {
    return null
  }

  return (
    <Box mt={{ _: '24px', XS: '40px' }}>
      <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
        <Flex
          flexDirection={{ _: 'column', MD: 'row' }}
          gap="16px"
          justifyContent="space-between"
          width="100%"
        >
          <InfoBox.Message>
            {t('cantPayByCard', {
              supportPhone: <NoWrapText text={SUPPORT_PHONE} />,
            })}
          </InfoBox.Message>
          <InfoBox.ButtonGroup>
            <InfoBox.CallToAction onClick={handleGoToContactZellerSupport}>
              {t('editButton')}
            </InfoBox.CallToAction>
          </InfoBox.ButtonGroup>
        </Flex>
      </InfoBox>
    </Box>
  )
}

CannotPayByCardWarningMessage.fragments = {
  Entity: gql`
    fragment CannotPayByCardWarningMessageEntityFragment on Entity {
      id
      canPayByCard
    }
  `,
}
