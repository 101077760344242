import { createContext } from 'react'

type TransitionFinishedCallback = (() => void) | null

export interface ModalCloseTransitionContextType {
  isModalOpen: boolean
  hasModalCloseTransition: boolean
  executeTransitionFinishedCallback: () => void
  closeWithoutTransition: () => void
  closeWithTransition: (closedCallback: TransitionFinishedCallback) => void
}

export const ModalCloseTransitionContext = createContext<
  ModalCloseTransitionContextType | undefined
>(undefined)

ModalCloseTransitionContext.displayName = 'ModalCloseTransitionContext'
