import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { ChartBarIcon } from '@npco/zeller-design-system'
import { useProductTour } from 'features/StartupModals/hooks/useProductTour/useProductTour'

import { ROOT } from 'const/routes'
import { component } from 'translations'

import { SidebarItem } from '../components/Item/Item'
import { useSidebarLocalCache } from '../hooks/useSidebarLocalCache'

export const DashboardItem = () => {
  const { isFolded } = useSidebarLocalCache()
  const { shouldShowAdminWelcome } = useProductTour()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <SidebarItem
      icon={<ChartBarIcon />}
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).OVERVIEW.path}
      className={`nav-item-overview${
        shouldShowAdminWelcome ? ' unselected' : ''
      }`}
    >
      {component.sidebar.items.overview}
    </SidebarItem>
  )
}
