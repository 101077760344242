import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type CardTooltipDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  cardsCount: {
    __typename?: 'DebitCardAccountV2CardsCount'
    debit: number
    expense: number | null
  }
}

export type GetAccountValueLabelDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  status: Types.DebitCardAccountStatus
  balance: { __typename?: 'Money'; value: string } | null
}

export const CardTooltipDebitCardAccountV2FragmentDoc = gql`
  fragment CardTooltipDebitCardAccountV2Fragment on DebitCardAccountV2 {
    cardsCount {
      debit
      expense
    }
  }
` as unknown as TypedDocumentNode<
  CardTooltipDebitCardAccountV2Fragment,
  undefined
>
export const GetAccountValueLabelDebitCardAccountV2FragmentDoc = gql`
  fragment GetAccountValueLabelDebitCardAccountV2Fragment on DebitCardAccountV2 {
    status
    balance {
      value
    }
  }
`
