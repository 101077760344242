import { AvatarBasic } from '@npco/zeller-design-system'
import { SelectItemBasic } from 'design-system/Components/Select'

import { AVATAR_BADGE_SIZE } from 'types/common'
import {
  StyledAccountSubLabel,
  StyledAccountSubLabelList,
} from 'pages/Transfer/ContactDropdown/AccountSelectInput.styled'
import {
  StyledAccountContent,
  StyledAccountName,
} from 'components/AccountList/AccountList.styled'

export const AccountSelectItem = ({
  label,
  subLabel1,
  subLabel2,
}: SelectItemBasic) => {
  const parsedName = label.trim()
  return (
    <>
      <AvatarBasic badgeSize={AVATAR_BADGE_SIZE.SMALL} text={label} />
      <StyledAccountContent>
        <StyledAccountName>{parsedName}</StyledAccountName>

        <StyledAccountSubLabelList>
          <StyledAccountSubLabel>{subLabel1}</StyledAccountSubLabel>
          <StyledAccountSubLabel>{subLabel2}</StyledAccountSubLabel>
        </StyledAccountSubLabelList>
      </StyledAccountContent>
    </>
  )
}
