import { useCallback } from 'react'
import { TransactionExportFormat } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'

import { useTransactionsList } from 'hooks/useTransactionsList/useTransactionsList'
import { ExportTransactionsModal } from 'pages/Dashboard/DashboardTransactions/PaymentTransactions/ExportTransactionsModal/ExportTransactionsModal'
import { StatementsModal } from 'components/StatementsModal/StatementsModal'
import { page } from 'translations'

import { useTransactionsFilters } from '../../../../components/Filters/PillFilters/TransactionsFilters/useTransactionsFilters'
import { filterGroupedTransactionList } from '../Transactions.utils'
import { useTransactionsContext } from '../TransactionsContext/TransactionsContext'
import { TransactionsList } from './TransactionsList/TransactionsList'

const deps = {
  useTransactionsList,
  useTransactionsContext,
  useTransactionsFilters,
  TransactionsList,
}
export { deps as TransactionsDeps }

export const Transactions = () => {
  const {
    closeStatementModal,
    filters: filtersRV,
    filtersInitialValues: filtersInitialValuesRV,
    isFiltersSelected: isFiltersSelectedRV,
    isFilteringByRefunded: isFilteringByRefundedRV,
    isStatementModalOpen,
    splitPaymentId,
    widgetKey,
  } = deps.useTransactionsContext()

  const flags = useFeatureFlags()

  const {
    filters: filtersNew,
    isFilteringByRefunded: isFiltertingByRefundedNew,
    isFiltersSelected: isFiltersSelectedNew,
  } = deps.useTransactionsFilters({ splitPaymentId, key: 'transactions' })

  const transactionsListInput = {
    widgetKey,
    ...(flags.TransactionsFilterUplift
      ? {
          filterObject: filtersNew,
          areFiltersSelected: isFiltersSelectedNew,
          filterDefaultValues: undefined,
        }
      : {
          filterObject: filtersRV,
          areFiltersSelected: isFiltersSelectedRV,
          filterDefaultValues: filtersInitialValuesRV,
        }),
  }

  const {
    hasMore,
    isLoading,
    isLoadingMore,
    onLoadMore,
    transactionList,
    transactionsError,
  } = deps.useTransactionsList(transactionsListInput)

  const getModelContent = useCallback(
    (fileFormat: TransactionExportFormat) => (
      <ExportTransactionsModal
        closeModal={closeStatementModal}
        filename={page.exportFile.exportTransactionsFilename}
        format={fileFormat}
      />
    ),
    [closeStatementModal]
  )

  const isFilteringByRefunded = flags.TransactionsFilterUplift
    ? isFiltertingByRefundedNew
    : isFilteringByRefundedRV

  return (
    <>
      {isStatementModalOpen && (
        <StatementsModal
          closeModal={closeStatementModal}
          modalContent={getModelContent}
          title={page.exportFile.exportTransactions}
          type={TransactionExportFormat}
        />
      )}

      <deps.TransactionsList
        groupedTransactions={
          isFilteringByRefunded
            ? filterGroupedTransactionList(transactionList)
            : transactionList
        }
        hasMore={hasMore}
        isLoadingMore={isLoadingMore}
        loading={isLoading}
        onLoadMore={onLoadMore}
        transactionsError={transactionsError}
      />
    </>
  )
}
