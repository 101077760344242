import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

export const SecondaryDetailsPlaceholder = () => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.secondaryDetails')}
      value="-"
    />
  )
}
