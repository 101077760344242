import { useState } from 'react'
import { useManageBusinessesQueryData } from '@npco/mp-feature-manage-businesses'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  MobileTopBar,
  TabItemType,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { SidebarMenuButton } from 'components/Sidebar/components/MobileNavHeader/SidebarMenuButton'

import { EntitiesMenu } from '../EntitiesMenu/EntitiesMenu'
import { TopBarUserMenu } from '../TopBarUserMenu/TopBarUserMenu'
import { mobileTopBarNavTranslations } from './MobileTopBarNav.i18n'

export interface MobileTopBarNavProps {
  entityTabs: TabItemType[]
  activeEntityId: TabItemType['id'] | null
  setSelectedEntityId: (id: TabItemType['id']) => void
  isManageBusinessesPage: boolean
  isSettingUpEntity?: boolean
}
export const MobileTopBarNav = ({
  entityTabs,
  activeEntityId,
  setSelectedEntityId,
  isManageBusinessesPage,
  isSettingUpEntity,
}: MobileTopBarNavProps) => {
  const { entityRelations } = useManageBusinessesQueryData()
  const isMobileResolution = useIsMobileResolution()
  const [isEntityMenuOpen, setIsEntityMenuOpen] = useState(false)
  const isSingleBusiness = entityRelations.length < 2
  const t = useTranslations(mobileTopBarNavTranslations)
  const featureFlags = useFeatureFlags()

  const isAddBusinessAllowed =
    featureFlags.MultiEntityLinkExisting || featureFlags.MultiEntityOnboarding

  const showAddBusinessButton =
    isSingleBusiness && isAddBusinessAllowed && !isManageBusinessesPage

  if (!isMobileResolution) {
    return null
  }

  return (
    <MobileTopBar>
      <MobileTopBar.LeftSection isTabsMenuOpen={isEntityMenuOpen}>
        {!isManageBusinessesPage && (
          <Box width="36px" height="36px">
            <SidebarMenuButton isDisabled={isSettingUpEntity} />
          </Box>
        )}
      </MobileTopBar.LeftSection>
      <MobileTopBar.CentreSection>
        <EntitiesMenu
          entityTabs={entityTabs}
          activeEntityId={activeEntityId}
          setSelectedEntityId={setSelectedEntityId}
          isEntityMenuOpen={isEntityMenuOpen}
          setIsEntityMenuOpen={setIsEntityMenuOpen}
          isSingleBusiness={isSingleBusiness}
          isAddBusinessAllowed={isAddBusinessAllowed}
          isManageBusinessesPage={isManageBusinessesPage}
        />
      </MobileTopBar.CentreSection>
      <MobileTopBar.RightSection isTabsMenuOpen={isEntityMenuOpen}>
        {showAddBusinessButton && (
          <MobileTopBar.AddBusinessButton ariaLabel={t('addBusiness')} />
        )}
        <TopBarUserMenu />
      </MobileTopBar.RightSection>
    </MobileTopBar>
  )
}
