import { useEffect } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { setSessionStorageItem } from '@npco/utils-session-storage'

import { LAST_DASHBOARD_TAB_KEY } from '../../utils/sessionStorageKeys'

export const useRememberDashboardTab = () => {
  const location = useLocation()

  useEffect(() => {
    setSessionStorageItem(LAST_DASHBOARD_TAB_KEY, location.pathname)
  }, [location.pathname])
}
