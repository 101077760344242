import { type ReactNode } from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { routeCanBeAccessed } from 'utils/routeHasPermission'

import * as styled from '../../Sidebar.styled'
import { ItemLabel } from './ItemLabel'

interface SidebarItemProps {
  badge?: ReactNode
  children: ReactNode
  isFolded: boolean
  isNavLink?: boolean
  route?: string
  title?: string
  className?: string
  icon: ReactNode
  onClick?: () => void
  $marginBottomOverride?: string
}

export const SidebarItem = ({
  badge,
  children,
  icon,
  isFolded,
  isNavLink,
  onClick,
  route,
  title,
  className,
  $marginBottomOverride,
}: SidebarItemProps) => {
  const Component = isNavLink ? NavLink : 'button'

  const { userRole: currentRole } = useLoggedInUser()
  const hasPermission = routeCanBeAccessed(route, currentRole)

  if (!hasPermission) {
    return null
  }

  return (
    <styled.ItemLink
      data-testid="item-link"
      as={Component}
      onClick={onClick}
      to={route}
      title={title}
      className={className}
      $isFolded={isFolded}
      $marginBottomOverride={$marginBottomOverride}
    >
      <ItemLabel icon={icon} badge={badge} isFolded={isFolded}>
        {children}
      </ItemLabel>
    </styled.ItemLink>
  )
}
