import { useTranslations } from '@npco/utils-translations'

import { translations } from 'translations/shared.i18n'

import { AmountRow, DefaultRow, TransferModal } from '../../../../TransferModal'
import { contactTransferModalTranslations } from '../../ContactTransferModal.i18n'

type DetailsSectionProps = {
  amount: string
  recipientReference: string
  reference: string
  mb?: string
}

export const DetailsSection = ({
  amount,
  recipientReference,
  reference,
  mb,
}: DetailsSectionProps) => {
  const t = useTranslations(contactTransferModalTranslations)
  const tShared = useTranslations(translations)

  return (
    <TransferModal.SectionWrapper aria-label={t('transferDetails')} mb={mb}>
      <AmountRow
        label={tShared('amount')}
        amountInCents={Math.round(Number(amount) * 100)}
      />
      <DefaultRow label={t('description')} value={recipientReference} />
      <DefaultRow label={t('reference')} value={reference} />
    </TransferModal.SectionWrapper>
  )
}
