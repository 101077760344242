import { z } from 'zod'

import {
  ToContactSchema,
  ToPaymentInstrumentSchema,
} from '../ContactTransfer.types'

const IconSchema = z.object({
  image: z.string().nullable(),
  letter: z.string().nullable(),
  colour: z.string().nullable(),
})

const FromAccountSchema = z.object({
  id: z.string(),
  name: z.string(),
  icon: IconSchema.nullable(),
  accountNumber: z.string(),
})

export type FromAccount = z.infer<typeof FromAccountSchema>

const TransferContactValuesSchema = z.object({
  fromAccount: FromAccountSchema,
  toContact: ToContactSchema,
  toPaymentInstrument: ToPaymentInstrumentSchema,
  amount: z.string(),
  reference: z.string(),
  recipientReference: z.string(),
})

export type TransferContactValues = z.infer<typeof TransferContactValuesSchema>

export const ContactTransferModalStateSchema = z.union([
  z.object({
    stage: z.literal('summary'),
    values: TransferContactValuesSchema,
  }),
  z.object({
    stage: z.literal('success'),
    values: TransferContactValuesSchema,
    timestamp: z.string(),
    transactionID: z.string(),
  }),
])

export type ContactTransferModalState = z.infer<
  typeof ContactTransferModalStateSchema
>

export const ContactTransferModalLocationStateSchema = z.object({
  ContactTransferModal: ContactTransferModalStateSchema,
})
