import styled from 'styled-components'

import { StyledDatePickerWrapper } from 'components/Filters/NewFilters/DatePicker/DatePicker.styled'

export const DatePickerWrapper = styled(StyledDatePickerWrapper)`
  .DayPicker-Day--today:not(.DayPicker-Day--selected):not(
      .DayPicker-Day--outside
    )::after {
    background-color: inherit;
    border: 1px solid ${({ theme }) => theme.colors.GREY_250};
    border-radius: 4px;
  }
  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ).DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    )::after {
    border-radius: unset;
  }

  .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ).DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    )::after {
    border-radius: unset;
  }
`
