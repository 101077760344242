import { DebitCardColour } from '@npco/mp-gql-types'
import { Flex, TileSelector } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { translate } from 'utils/translations'
import { CardColourTile } from 'pages/GlobalModals/components/CardColourTile/CardColourTile'
import { ZellerCard } from 'components/ZellerCard/ZellerCard'

import * as styled from './SelectCardDesignFields.styled'

interface ColourTileConfigType {
  isHoverable: boolean
  colours: DebitCardColour[]
}

interface SelectCardDesignFieldsProps {
  colourTileConfig: ColourTileConfigType
}
export const SelectCardDesignFields = ({
  colourTileConfig,
}: SelectCardDesignFieldsProps) => {
  const [field, , handlers] = useField({
    name: 'colour',
  })

  return (
    <Flex
      flexDirection="column"
      justifyContent={{ _: 'start', XS: 'center' }}
      alignItems="center"
      width="100%"
      height="100%"
      py="12px"
    >
      <styled.ZellerCardWrapper
        ariaLabel={translate('page.addCardModal.selectCardDesign.designAlt')}
      >
        <ZellerCard debitCard={{ colour: field.value }} variant="default" />
      </styled.ZellerCardWrapper>
      <styled.TilesWrapper mt={['38px', '46px']}>
        {colourTileConfig.colours.map((colour) => (
          <TileSelector
            hoverable={colourTileConfig.isHoverable}
            key={colour}
            name={colour}
            content={<CardColourTile color={colour} />}
            onChange={() => handlers.setValue(colour)}
            value={colour}
            selected={field.value === colour}
          />
        ))}
      </styled.TilesWrapper>
    </Flex>
  )
}
