import { TooltipRenderProps } from 'react-joyride'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'

import * as styled from 'components/Onboarding/OnboardingTooltip.styled'
import { translationsShared } from 'translations/index'

export const CorporateCardSettingsWalkthroughToolTips = ({
  step,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  const tShared = useTranslations(translationsShared)

  return (
    <styled.TooltipContainer
      {...tooltipProps}
      maxWidth="200px"
      data-testid="corporate-card-settings-walkthrough-tooltip"
    >
      {step.title && <styled.TooltipTitle>{step.title}</styled.TooltipTitle>}
      <styled.TooltipBody>{step.content}</styled.TooltipBody>
      <Flex justifyContent="flex-end">
        <styled.PrimaryTooltipButton
          {...primaryProps}
          title={tShared('dismiss')}
          aria-label={tShared('dismiss')}
          data-action="skip"
        >
          {tShared('dismiss')}
        </styled.PrimaryTooltipButton>
      </Flex>
    </styled.TooltipContainer>
  )
}
