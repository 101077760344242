import { MutableRefObject, useMemo } from 'react'
import { rvSelectedTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'

import { TransactionInList } from 'types/transactions'

import {
  getNextTransaction,
  getPreviousTransaction,
} from './useNextPreviousTransaction.utils'

interface Arguments {
  groupedTransactions: TransactionInList[][]
  selectedTransaction: TransactionInList | null
  transactionsRefs?: MutableRefObject<Record<string, HTMLDivElement | null>>
}

export const useNextPreviousTransaction = ({
  groupedTransactions,
  selectedTransaction,
  transactionsRefs,
}: Arguments) => {
  const flattenedTransactionsList = useMemo(
    () => groupedTransactions.flat(1),
    [groupedTransactions]
  )

  const nextTransaction = useMemo(
    () => getNextTransaction(selectedTransaction, flattenedTransactionsList),
    [flattenedTransactionsList, selectedTransaction]
  )
  const previousTransaction = useMemo(
    () =>
      getPreviousTransaction(selectedTransaction, flattenedTransactionsList),
    [flattenedTransactionsList, selectedTransaction]
  )

  const onSelectNextTransaction = useMemo(() => {
    if (!nextTransaction) {
      return undefined
    }

    return () => {
      transactionsRefs?.current[nextTransaction.id]?.scrollIntoView({
        block: 'start',
      })

      rvSelectedTransaction(nextTransaction)
    }
  }, [nextTransaction, transactionsRefs])

  const onSelectPreviousTransaction = useMemo(() => {
    if (!previousTransaction) {
      return undefined
    }

    return () => {
      transactionsRefs?.current[previousTransaction.id]?.scrollIntoView({
        block: 'start',
      })

      rvSelectedTransaction(previousTransaction)
    }
  }, [previousTransaction, transactionsRefs])

  return {
    onSelectNextTransaction,
    onSelectPreviousTransaction,
  }
}
