import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  slideSideAnimationVariants,
  slideSideReversedAnimationVariants,
} from '@npco/zeller-design-system'
import { InputSelectComboBoxItem } from 'features/Contacts/Contacts.types'
import { useContactsFilters } from 'features/Contacts/hooks/useContactsFilters/useContactsFilters'
import { useTransferMFAState } from 'features/MFA'
import { Form, useFormikContext } from 'formik'

import { getInputAmount } from 'utils/numbers'
import { TransferRecipients } from 'pages/Transfer/states/Receipients/TransferRecipients'
import { TransferStateCondition as Condition } from 'pages/Transfer/states/TransferState/TransferStateCondition'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'
import {
  StyledContent,
  StyledRecipientWrapper,
} from 'pages/Transfer/Transfer.styled'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'
import { TransferActionButtons } from 'pages/Transfer/TransferFields/TransferActionButtons/TransferActionButtons'
import { TransferContactFields } from 'pages/Transfer/TransferFields/TransferContactFields'
import {
  useTransitionStateOnDropdownChange,
  useValidateFormOnStateChange,
} from 'pages/Transfer/TransferFields/TransferFields.hooks'
import { TransferZellerFields } from 'pages/Transfer/TransferFields/TransferZellerFields'

import { useTransferState } from '../Transfer.context'
import { BpayTransferStates } from './BpayTransferStates'

type TransferFieldsProps = {
  selectedContact: InputSelectComboBoxItem | null
  setSelectedContact: Dispatch<SetStateAction<InputSelectComboBoxItem | null>>
}

export const TransferFields = ({
  selectedContact,
  setSelectedContact,
}: TransferFieldsProps) => {
  useTransitionStateOnDropdownChange()
  useValidateFormOnStateChange()
  const [contactValidationError, setContactValidationError] = useState<
    string | undefined
  >(undefined)
  const { filters, name$ } = useContactsFilters()
  const { updateTransferValues } = useTransferState()

  const { values, setValues } = useFormikContext<TransferFieldTypes>()

  const {
    transferState,
    hasRedirectedBackToApp: hasTransferRedirectedBackToApp,
  } = useTransferMFAState()

  useEffect(() => {
    if (hasTransferRedirectedBackToApp && transferState) {
      const transferValues: TransferFieldTypes = {
        to: transferState.variables.payeeAccountUuid,
        from: transferState.variables.payerAccountUuid,
        amount: getInputAmount(transferState.variables.amountInCents / 100, 2),
        reference: transferState.variables.payerReference,
        recipientReference: transferState.variables.payeeReference,
      }
      // update context and form
      updateTransferValues(transferValues)
      setValues({
        ...values,
        ...transferValues,
      })
    }
  }, [
    hasTransferRedirectedBackToApp,
    transferState,
    values,
    setValues,
    updateTransferValues,
  ])

  return (
    <StyledContent>
      <Form>
        <Box>
          <StyledRecipientWrapper>
            <TransferRecipients
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              contactValidationError={contactValidationError}
              setContactValidationError={setContactValidationError}
              contactFilters={filters}
              name$={name$}
            />
            <Condition
              allowedStates={[TransferGeneralState.BpayTransfer]}
              animationVariants={slideSideAnimationVariants}
            >
              <BpayTransferStates
                contactValidationError={contactValidationError}
                setContactValidationError={setContactValidationError}
              />
            </Condition>
          </StyledRecipientWrapper>

          <Condition
            allowedStates={[TransferGeneralState.ZellerTransfer]}
            animationVariants={slideSideReversedAnimationVariants}
          >
            <TransferZellerFields />
          </Condition>

          <Condition
            allowedStates={[TransferGeneralState.ContactTransfer]}
            animationVariants={slideSideAnimationVariants}
          >
            <TransferContactFields />
          </Condition>
        </Box>

        <TransferActionButtons
          setContactValidationError={setContactValidationError}
        />
      </Form>
    </StyledContent>
  )
}
