import { createTranslations } from '@npco/utils-translations'

export const setupFlowItemsStageTranslations = createTranslations({
  title: 'Add the items you sell, to use in POS Lite or Invoices.',
  description:
    'Manage your product categories, variations, and modifiers for easy access.',
  point1: 'Use Items across Zeller Invoices and POS Lite.',
  point2: 'Create new items, assign categories, and update prices.',
  point3: 'Build attribute and modifier sets to streamline checkout.',
  point4: 'Assign items to different sites to manage inventory.',
})
