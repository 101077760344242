import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { AccountSelectDebitCardAccountV2FragmentDoc } from '../../../CashflowFiltersHeader/AccountSelect/AccountSelect.generated'

export type cashflowReportingAccountFragementFragment = {
  __typename: 'DebitCardAccountV2'
  status: Types.DebitCardAccountStatus
  type: Types.DebitCardAccountType
  id: string
  name: string
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    animation: string | null
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export const cashflowReportingAccountFragementDoc = gql`
  fragment cashflowReportingAccountFragement on DebitCardAccountV2 {
    __typename
    status
    type
    ...AccountSelectDebitCardAccountV2Fragment
  }
  ${AccountSelectDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  cashflowReportingAccountFragementFragment,
  undefined
>
