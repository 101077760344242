import { gql } from '@apollo/client'

export const GetContactTransactionsLocalState = gql`
  query GetContactTransactionsLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      contactTransactions {
        filters {
          amount
          dates
          statuses
          types
          sources
          search
          terminals
          siteUuid
        }
        isFiltersVisible
      }
    }
  }
`
