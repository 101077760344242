import { gql } from '@apollo/client'
import { Table, TableData, useTable } from '@npco/ui-table'

import {
  AverageCell,
  TotalCell,
} from '../../../TransactionsSummary/TableCells/TableRowCells'
import { CategoryDrawerTableCashFlowSubcategoryNetAmountFragment as SubcategoryNetAmount } from './CategoryDrawerTable.generated'
import { columnsConfig } from './CategoryDrawerTableConfig'
import { SubcategoryCell } from './TableCells/TableRowCells'

interface CategoryDrawerTableProps {
  subcategoryNetAmount: SubcategoryNetAmount[]
  isLoading?: boolean
  transactionDirection: 'income' | 'expense'
  onSubcategoryClicked?: (netAmount: SubcategoryNetAmount) => void
  onSubcategoryHovered?: (netAmount: SubcategoryNetAmount) => void
  onSubcategoryUnhovered?: (netAmount: SubcategoryNetAmount) => void
}

export const CategoryDrawerTable = ({
  subcategoryNetAmount,
  isLoading = false,
  transactionDirection,
  onSubcategoryClicked,
  onSubcategoryHovered,
  onSubcategoryUnhovered,
}: CategoryDrawerTableProps) => {
  const { table } = useTable({
    columnsConfig,
    isLoading,
    listData: subcategoryNetAmount,
    meta: {
      transactionDirection,
    },
  })

  return (
    <Table<TableData<SubcategoryNetAmount>>
      hideHeader={false}
      table={table}
      hasBorderBottom={false}
      hasLastTrBorderBottom={false}
      onRowClick={(row) => onSubcategoryClicked?.(row.original.data)}
      onRowMouseEnter={(row) => onSubcategoryHovered?.(row.original.data)}
      onRowMouseLeave={(row) => onSubcategoryUnhovered?.(row.original.data)}
    />
  )
}

CategoryDrawerTable.fragments = {
  CashFlowSubcategoryNetAmount: gql`
    fragment CategoryDrawerTableCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      ...SubcategoryCellCashFlowSubcategoryNetAmountFragment
      ...AverageCellCashFlowSubcategoryNetAmountFragment
      ...TotalCellCashFlowSubcategoryNetAmountFragment
    }

    ${SubcategoryCell.fragments.CashFlowSubcategoryNetAmount}
    ${AverageCell.fragments.CashFlowSubcategoryNetAmount}
    ${TotalCell.fragments.CashFlowSubcategoryNetAmount}
  `,
}
