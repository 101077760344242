import { Flex } from '@npco/zeller-design-system'

import { StyledListKey } from 'components/DetailsListItem/DetailsListItem.styled'
import { SkeletonLoaderBasic } from 'components/SkeletonLoader/SkeletonLoaderBasic'

import { StyledTag } from './CategoryDisplay.styled'

interface CategoryItemSkeletonProps {
  paddingBottom?: string
  paddingTop?: string
}

export const CategoryItemSkeleton = ({
  paddingBottom,
  paddingTop,
}: CategoryItemSkeletonProps) => {
  return (
    <Flex
      justifyContent="space-between"
      data-testid="subcategory-skeleton"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <StyledListKey>
        <SkeletonLoaderBasic width={118} height={8} isRounded />
      </StyledListKey>
      <StyledTag>
        <SkeletonLoaderBasic width={118} height={8} isRounded />
      </StyledTag>
    </Flex>
  )
}
