// This is an approximation for including anything except a function.
// An object with apply or call field will also be excluded.
type NotFunction =
  | string
  | number
  | boolean
  | null
  | bigint
  | undefined
  | readonly any[]
  | { apply?: never; [k: string]: any }
  | { call?: never; [k: string]: any }

export type UpdateOption<T extends NotFunction> = T | ((oldValue: T) => T)
export type UpdateOptionFunctionOnly<T extends NotFunction> = (oldValue: T) => T

export const updateWithOption = <T extends NotFunction>(
  updateOption: UpdateOption<T> | undefined,
  oldValue: T
) => {
  if (updateOption === undefined) {
    return oldValue
  }

  if (typeof updateOption === 'function') {
    return updateOption(oldValue)
  }

  return updateOption
}
