import {
  Box,
  BUTTON_SIZE,
  ButtonGhost,
  Flex,
  Heading,
} from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal/hooks/useModalState'
import { ModalBasic } from 'design-system/Components/Modal/ModalBasic/ModalBasic'

import { translate } from 'utils/translations'
import { ModalUploadLogo } from 'components/ModalUploadLogo'

import {
  EmptyStateCopy,
  LogoContainer,
  LogoImage,
  UploadCopy,
} from './CompanyLogoUpload.styled'
import { LogoActions } from './LogoActions/LogoActions'

interface CompanyLogoUploadProps {
  logo: string
  removeLogo: () => void
  setTemporaryLogo: React.Dispatch<React.SetStateAction<string>>
}

export const CompanyLogoUpload = ({
  logo,
  removeLogo,
  setTemporaryLogo,
}: CompanyLogoUploadProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()

  const hasLogo = Boolean(logo)

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading.H4>
          {translate('component.companyLogoUpload.companyLogo')}
        </Heading.H4>
        {hasLogo && (
          <LogoActions
            logo={logo}
            handleRemoveLogo={removeLogo}
            setTemporaryLogo={setTemporaryLogo}
          />
        )}
      </Flex>
      <Box mb="2.5rem">
        <LogoContainer
          mb="1.5rem"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          hasLogo={hasLogo}
        >
          {hasLogo ? (
            <LogoImage src={logo} />
          ) : (
            <>
              <EmptyStateCopy>
                {translate('component.companyLogoUpload.noAddedLogo')}
              </EmptyStateCopy>
              <ButtonGhost size={BUTTON_SIZE.SMALL} onClick={openModal}>
                {translate('component.companyLogoUpload.uploadLogo')}
              </ButtonGhost>
              <ModalBasic
                title={translate('component.modal.uploadImage.uploadNewImage')}
                isOpen={isModalOpen}
                onCancel={closeModal}
                hasCloseButton
              >
                <ModalUploadLogo
                  logo={logo}
                  setTemporaryLogo={setTemporaryLogo}
                  closeModal={closeModal}
                />
              </ModalBasic>
            </>
          )}
        </LogoContainer>
        <UploadCopy>
          {translate('component.companyLogoUpload.uploadLogoCopy')}
        </UploadCopy>
      </Box>
    </>
  )
}
