import { useCallback } from 'react'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { CreateInvoiceItem_createItem as CreateInvoiceItem } from 'types/gql-types/CreateInvoiceItem'
import {
  convertItemFormFieldsToCreateItemInput,
  ItemCreateModalForm,
} from 'components/ItemBaseModalForm'

import { useCreateItem } from '../hooks/useCreateItem'
import { SaveItemFormFields } from './InvoiceItemCreateModal.types'
import { initialValues as defaultInitialValues } from './InvoiceItemCreateModal.utils'

export const translations = {
  cancel: translate('shared.cancel'),
  errorNotification: translate('page.item.formSections.errorNotification'),
  save: translate('shared.save'),
  successNotification: translate('page.item.formSections.successNotification'),
  title: translate('page.item.formSections.saveItem'),
}

export interface InvoiceItemsCreateModalProps {
  initialValues: SaveItemFormFields
  onClose: () => void
  onComplete: (index: number, item: CreateInvoiceItem) => void
}

export const InvoiceItemCreateModal = ({
  initialValues = defaultInitialValues,
  onClose: handleOnClose,
  onComplete: handleOnComplete,
}: InvoiceItemsCreateModalProps) => {
  const { createItem, isCreatingItem } = useCreateItem()

  const handleOnSubmit = useCallback(
    async (formValues: SaveItemFormFields) => {
      try {
        const payload = convertItemFormFieldsToCreateItemInput(formValues)

        const result = await createItem(payload)

        if (result.data?.createItem) {
          showSuccessToast(translations.successNotification)
          handleOnComplete(formValues.index, result.data.createItem)
        } else {
          showErrorToast(translations.errorNotification)
        }
      } catch (error) {
        showErrorToast(translations.errorNotification)
      }
    },
    [createItem, handleOnComplete]
  )

  return (
    <ItemCreateModalForm<SaveItemFormFields>
      initialValues={initialValues}
      isCreatingItem={isCreatingItem}
      onClose={handleOnClose}
      onSubmit={handleOnSubmit}
      title={translations.title}
    />
  )
}
