import { useMutation } from '@apollo/client'
import { ScreensaverInput } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import {
  UpdateScreensaver,
  UpdateScreensaverMutationResponse,
  UpdateScreensaverMutationVariables,
} from '../graphql/updateScreensaver.generated'

export const useUpdateScreensaver = () => {
  const entityUuid = useSelectedEntityUuid()
  const [updateScreensaver, { data, loading: isUpdatingScreensaver }] =
    useMutation<
      UpdateScreensaverMutationResponse,
      UpdateScreensaverMutationVariables
    >(UpdateScreensaver, {
      onError: showApiErrorToast,
    })

  return {
    updateScreensaver: (input: ScreensaverInput) =>
      updateScreensaver({
        variables: {
          entityUuid,
          input,
        },
      }),
    data: data?.updateScreensaver,
    isUpdatingScreensaver,
  }
}
