import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetSites } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/sites'

import { useGetCustomerDetails } from 'hooks/useGetCustomerDetails/useFetchCustomerDetails'
import { ADMIN } from 'const/customers'
import { getVerificationStatus } from 'utils/kyc/kycIdVerification.utils'
import { GetCustomerBasicInfo_getCustomer as Customer } from 'types/gql-types/GetCustomerBasicInfo'
import { GetSites as GetSitesType } from 'types/gql-types/GetSites'
import { SiteCache } from 'types/site'

export const useUserDetails = (selectedUser: Customer) => {
  const [userSites, setUserSites] = useState<SiteCache[]>([])
  const [entitySites, setEntitySites] = useState<SiteCache[]>([])

  const { role } = selectedUser
  const isAdmin = role === ADMIN

  const { data, isLoading: isLoadingUserDetails } = useGetCustomerDetails(
    selectedUser.id
  )
  const { display: idVerificationStatus } = getVerificationStatus(
    data?.getCustomer?.kycStatus ?? null
  )
  const entityUuid = useSelectedEntityUuid()

  useQuery<GetSitesType>(GetSites, {
    variables: {
      entityUuid,
      limit: 30,
    },
    onCompleted: (entitySiteData) => {
      const sitesData = entitySiteData?.getSites.sites
      setEntitySites(sitesData as SiteCache[])
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  useEffect(() => {
    if (selectedUser.role === ADMIN) {
      setUserSites(entitySites)
    } else {
      setUserSites(data?.getCustomer.sites as SiteCache[])
    }
  }, [data, entitySites, selectedUser.role])

  return {
    idVerificationStatus,
    isAdmin,
    isInvitationPending: data?.getCustomer?.isInvitationPending ?? false,
    userSites,
    userDetails: data?.getCustomer,
    isLoadingUserDetails,
  }
}
