import { GetDocumentUploadUrls_getDocumentUploadUrls as UrlData } from 'types/gql-types/GetDocumentUploadUrls'

import { translate } from './translations'

export const getToastMessage = (fileQueueLength: number) =>
  fileQueueLength > 1
    ? translate('form.shared.uploadDocuments.allFilesUploaded', {
        value: fileQueueLength,
      })
    : translate('form.shared.uploadDocuments.singleFilesUploaded')

export const getHasAllFileUploaded = (uploadStatuses: boolean[]) =>
  Boolean(uploadStatuses.length && !uploadStatuses.some((status) => !status))

export const handleUploadFile = async (
  urlData: UrlData | null,
  uploadFile: (urlData: UrlData) => Promise<boolean>
) => {
  if (urlData) {
    return uploadFile(urlData)
  }

  return false
}
