import { BodyLargeTypography } from '@npco/zeller-design-system'
import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledWrapper } from 'components/Placeholders/SharedElements.styled'

export const StyledEmptyBillingHistoryWrapper = styled(StyledWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flexDirection="column";

  a {
    margin-top: 2rem;
    ${BodyLargeTypography};
    width: 100%;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      width: auto;
    }
  }

  svg {
    margin-right: 0.5rem;   
  }
`
