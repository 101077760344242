import { useContext } from 'react'

import { ModalCloseTransitionContext } from './ModalCloseTransitionContext'

export const useModalCloseTransitionContext = () => {
  const context = useContext(ModalCloseTransitionContext)

  if (!context) {
    throw new Error(
      'useModalCloseTransitionContext must be used within ModalCloseTransitionContext'
    )
  }

  return context
}
