import { useCallback, useMemo } from 'react'

import { getInputAmount } from 'utils/numbers'

interface Arguments {
  value: string
  setValue: (value: string) => void
  handleBlur?: (e: React.BaseSyntheticEvent) => void
  handleChange?: (e: React.BaseSyntheticEvent) => void
  decimalPlaces?: number
  allowNegative?: boolean
}

export const useInputWithLimitedDecimalPlaces = ({
  value,
  setValue,
  handleBlur,
  handleChange,
  decimalPlaces = 2,
  allowNegative = false,
}: Arguments) => {
  const validInput = useMemo(() => {
    const regex = decimalPlaces === 0 ? '[0-9]+' : '[0-9]+[.,]?[0-9]*'

    if (allowNegative) {
      return new RegExp(`^(-?${regex}|-)$`)
    }

    return new RegExp(`^${regex}$`)
  }, [allowNegative, decimalPlaces])

  const onChange = (e: React.BaseSyntheticEvent) => {
    const { value: newValue } = e.target

    if (newValue.startsWith('.')) {
      e.preventDefault()
      setValue(`0${newValue}`)
      return
    }

    if (newValue !== '' && !validInput.test(newValue)) {
      e.preventDefault()
      return
    }

    handleChange?.(e)
  }

  const onBlur = useCallback(
    (e: React.BaseSyntheticEvent) => {
      const parsedValue = Number.parseFloat(value?.replace(',', '.'))

      if (value !== '') {
        if (Number.isNaN(parsedValue)) {
          setValue((0).toFixed(decimalPlaces))
        } else {
          setValue(getInputAmount(parsedValue, decimalPlaces))
        }
      }

      handleBlur?.(e)
    },
    [decimalPlaces, handleBlur, setValue, value]
  )

  return {
    onBlur,
    onChange,
  }
}
