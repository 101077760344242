import { BodyLargeTypography, H4, Tabs } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { PlaceholderSize } from 'components/Placeholders/Placeholders.types'

export const StyledPerformanceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_60};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    align-items: center;
    flex-direction: row;
  }
`

export const StyledPerformanceTitle = styled(H4)`
  margin: 0;
`

export const StyledNoResultsWrapper = styled.div`
  height: 250px;
  text-align: center;
`

interface WithVariant {
  variant: PlaceholderSize
}

const getPlaceholderButtonStyleByVariant = (variant: PlaceholderSize) => {
  switch (variant) {
    case PlaceholderSize.Medium:
      return css`
        padding: 0.75rem 1.5rem;
      `
    case PlaceholderSize.Small:
      return css`
        padding: 0.5rem 1.5rem;
      `
    case PlaceholderSize.ExtraLarge:
    case PlaceholderSize.Large:
    default:
      return css`
        padding: 0.875rem 1.5rem;
      `
  }
}

export const StyledPlaceholderButtonWrapper = styled.button`
  ${BodyLargeTypography};
  ${(props: WithVariant) => getPlaceholderButtonStyleByVariant(props.variant)}
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  border-radius: 7px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  cursor: pointer;
  width: 100%;
  text-decoration: none;

  &:hover {
    border-color: ${({ theme }) => theme.colors.BLUE_400};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.GREY_30};
    border-color: ${({ theme }) => theme.colors.BLUE_400};
  }
`

export const StyledStepperWrapper = styled.div`
  margin: 1.5rem 0.1rem 1rem;
  width: 100%;
  display: block;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`

export const StyledTabs = styled(Tabs)`
  height: auto;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    && {
      margin-bottom: -0.1875rem;
    }
  }
`

export const StyledPerformanceWrapper = styled.div`
  padding-top: 3rem;
`
