import Modal from 'react-modal'
import { ButtonGhost } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button } from 'components/Buttons/Button'

import { ModalSize } from './Modal.types'
import { getModalWidth } from './Modal.utils'

export const StyledModalBasic = styled(Modal)<{
  isFullScreen: boolean
  size?: ModalSize
}>`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 0.5rem;
  box-sizing: border-box;
  left: 50%;
  max-height: calc(100vh - 2rem);
  outline: none;
  overflow-y: scroll;
  padding: 1.5rem;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 2rem);

  ${({ isFullScreen }) =>
    isFullScreen && {
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      maxHeight: '100%',
    }};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding: 2rem;
    width: ${({ size }) => getModalWidth(size)};
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  padding-top: 0.75rem;
  flex-flow: column;
  justify-content: stretch;
  align-items: flex-start;
  flex-direction: column-reverse;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const StyledConfirmationButton = styled(Button)<{
  hasCancelButton: boolean
}>`
  width: 100%;
  margin-bottom: ${({ hasCancelButton }) => (hasCancelButton ? '0.5rem' : '0')};

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: ${({ hasCancelButton }) => (hasCancelButton ? '100%' : '50%')};
    margin-bottom: 0;
  }
`

export const StyledCancelButton = styled(ButtonGhost)`
  width: 100%;
  margin-right: 0;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-right: 0.5rem;
  }
`
