import { useCallback, useMemo, useState } from 'react'
import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'
import { TabItemProps } from '@npco/zeller-design-system'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

import {
  adjustmentTransactionTypes,
  adjustmentTransactionTypesV3,
  internalTransferTransactionTypes,
  internalTransferTransactionTypesV3,
} from 'hooks/banking'
import { useDrawerTabEvents } from 'services/Analytics/useDrawerTabEvents'

import { TransactionDrawerTabs } from '../TransactionDrawer.types'

interface UseTransactionDrawerLayoutProps {
  transaction:
    | Pick<DebitCardTransactionV3, '__typename' | 'type'>
    | Pick<DebitCardTransactionV2, '__typename' | 'type'>
    | null
  hasContact: boolean
}

export const useTransactionDrawerLayout = ({
  transaction,
  hasContact,
}: UseTransactionDrawerLayoutProps) => {
  const isDebitCardTransactionV2Type =
    transaction?.__typename === 'DebitCardTransactionV2'
  const isInternalTransfer =
    transaction?.type &&
    (isDebitCardTransactionV2Type
      ? internalTransferTransactionTypes.includes(transaction.type)
      : internalTransferTransactionTypesV3.includes(transaction.type))
  const isDeposit = isDebitCardTransactionV2Type
    ? transaction?.type === DebitCardTransactionTypeV2.DEPOSIT
    : transaction?.type === DebitCardAccountTransactionTypeEnum.DEPOSIT
  const isWithdrawal = isDebitCardTransactionV2Type
    ? transaction?.type === DebitCardTransactionTypeV2.WITHDRAWAL
    : transaction?.type === DebitCardAccountTransactionTypeEnum.WITHDRAWAL
  const isAdjustment =
    transaction?.type &&
    (isDebitCardTransactionV2Type
      ? adjustmentTransactionTypes.includes(transaction.type)
      : adjustmentTransactionTypesV3.includes(transaction.type))

  const isExternalTransferWithoutContact =
    (isDebitCardTransactionV2Type
      ? transaction?.type === DebitCardTransactionTypeV2.DE_OUT
      : transaction?.type === DebitCardAccountTransactionTypeEnum.DE_OUT) &&
    !hasContact

  const { onDrawerTabSwitch } = useDrawerTabEvents({
    drawerName: 'Transaction',
  })

  const shouldDisplayContact = !(
    isInternalTransfer ||
    isDeposit ||
    isWithdrawal ||
    isExternalTransferWithoutContact ||
    isAdjustment
  )

  const [selectedTab, setSelectedTab] = useState(TransactionDrawerTabs.DETAILS)
  const handleTabSwitch = useCallback(
    (tabName) => {
      setSelectedTab(tabName)
      onDrawerTabSwitch(tabName)
    },
    [onDrawerTabSwitch]
  )

  const tabs: TabItemProps[] = useMemo(
    () =>
      shouldDisplayContact
        ? [
            {
              id: 'transaction-drawer-layout-tabs-details',
              children: `${TransactionDrawerTabs.DETAILS}`,
              value: TransactionDrawerTabs.DETAILS,
              onClick: () => handleTabSwitch(TransactionDrawerTabs.DETAILS),
            },
            {
              id: 'transaction-drawer-layout-tabs-contacts',
              children: `${TransactionDrawerTabs.CONTACT}`,
              value: TransactionDrawerTabs.CONTACT,
              onClick: () => handleTabSwitch(TransactionDrawerTabs.CONTACT),
            },
          ]
        : [
            {
              id: 'transaction-drawer-layout-tabs-details',
              children: `${TransactionDrawerTabs.DETAILS}`,
              value: TransactionDrawerTabs.DETAILS,
              onClick: () => handleTabSwitch(TransactionDrawerTabs.DETAILS),
            },
          ],
    [handleTabSwitch, shouldDisplayContact]
  )

  return {
    selectedTab: shouldDisplayContact
      ? selectedTab
      : TransactionDrawerTabs.DETAILS,
    tabs,
  }
}
