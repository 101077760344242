import { useTranslations } from '@npco/utils-translations'
import { RadioButtonUnselectedIcon, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as DebitCardIcon } from 'assets/svg/cards/debit-card-front.svg'
import { CardTypeOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { StyledRadioCard } from 'pages/GlobalModals/GlobalModals.styled'

import { selectCardTypeFormTranslations } from '../SelectCardTypeForm.i18n'

const getDebitCardIcon = () => (
  <SvgIcon width="40" height="25">
    <DebitCardIcon />
  </SvgIcon>
)

interface DebitCardRadioOptionProps {
  onChange: () => void
  isChecked: boolean
  name: string
}

export const DebitCardRadioOption = ({
  onChange,
  isChecked,
  name,
}: DebitCardRadioOptionProps) => {
  const t = useTranslations(selectCardTypeFormTranslations)

  return (
    <StyledRadioCard
      id={CardTypeOption.DebitCard}
      value={CardTypeOption.DebitCard}
      title={t('debitCardOptionTitle')}
      desc={t('debitCardOptionDesc')}
      icon={getDebitCardIcon}
      onChange={onChange}
      checked={isChecked}
      name={name}
      unselectedIcon={RadioButtonUnselectedIcon}
    />
  )
}
