export interface NameCollection {
  firstname: string
  middlename: string
  lastname: string
}

export enum NAME_FORMATS {
  FIRST_MIDDLE_LAST,
  FIRST_LAST,
  FISRT_M_LAST,
  FIRST_L,
}

const firstMiddleLastFormatter = (nameCollection: NameCollection) => {
  const parts: string[] = []
  if (nameCollection.firstname) {
    parts.push(nameCollection.firstname)
  }

  if (nameCollection.middlename) {
    parts.push(nameCollection.middlename)
  }

  if (nameCollection.lastname) {
    parts.push(nameCollection.lastname)
  }

  return parts.join(' ')
}
const firstLastFormatter = (nameCollection: NameCollection) => {
  const parts: string[] = []

  if (nameCollection.firstname) {
    parts.push(nameCollection.firstname)
  }

  if (nameCollection.lastname) {
    parts.push(nameCollection.lastname)
  }

  return parts.join(' ')
}

const firstLFormatter = (nameCollection: NameCollection) => {
  const parts: string[] = []
  const lastname = nameCollection.lastname
    ? `${nameCollection.lastname.charAt(0)}.`
    : ''

  if (nameCollection.firstname) {
    parts.push(nameCollection.firstname)
  }

  if (lastname) {
    parts.push(lastname)
  }

  return parts.join(' ')
}

const firstMLastFormatter = (nameCollection: NameCollection) => {
  const parts: string[] = []
  const middlename = nameCollection.middlename
    ? nameCollection.middlename.charAt(0)
    : ''

  if (nameCollection.firstname) {
    parts.push(nameCollection.firstname)
  }

  if (middlename) {
    parts.push(middlename)
  }

  if (nameCollection.lastname) {
    parts.push(nameCollection.lastname)
  }
  return parts.join(' ')
}

export const nameFormatter = (
  nameCollection: NameCollection,
  type: NAME_FORMATS
) => {
  switch (type) {
    case NAME_FORMATS.FISRT_M_LAST:
      return firstMLastFormatter(nameCollection)
    case NAME_FORMATS.FIRST_LAST:
      return firstLastFormatter(nameCollection)
    case NAME_FORMATS.FIRST_L:
      return firstLFormatter(nameCollection)
    case NAME_FORMATS.FIRST_MIDDLE_LAST:
    default:
      return firstMiddleLastFormatter(nameCollection)
  }
}
