import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox } from '@npco/zeller-design-system'

import { translations } from './AccountBalanceEmptyMessage.i18n'

export const AccountBalanceEmptyMessage = () => {
  const t = useTranslations(translations)

  return (
    <Box mb="40px">
      <InfoBox>
        <InfoBox.Message>{t('description')}</InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
