import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { merge } from 'lodash-es'

import { ROOT } from 'const/routes'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { translations as sharedTranslations } from 'translations/shared.i18n'

import { useInvoiceAnalytics } from '../../../../../hooks/useInvoiceAnalytics'
import { useSaveAndSendInvoice } from '../../../../../hooks/useSaveAndSendInvoice'
import { useInvoiceDecisionModal } from '../../hooks/useInvoiceDecisionModal'
import { InvoiceDecisionModalTypes } from '../../InvoiceDrawerDetails.utils'
import { translations } from './SendNowModal.i18n'

export const SendNowModal = () => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(merge(sharedTranslations, translations))

  const {
    activeModal,
    closeModal,
    details: { id, referenceNumber },
  } = useInvoiceDecisionModal()

  const { saveAndSendInvoice, isSavingAndSendingInvoice } =
    useSaveAndSendInvoice()

  const { trackInvoiceAction } = useInvoiceAnalytics(referenceNumber, id)

  const handleSendInvoice = useCallback(async () => {
    trackInvoiceAction(AnalyticsEventNames.INVOICE_SEND_NOW)

    try {
      const response = await saveAndSendInvoice({
        sendSchedule: null, // Removing the sendSchedule date will send this Invoice now.
        referenceNumber,
      })

      if (!response.data?.saveAndSendInvoice) {
        showErrorToast(t('errorToast', { referenceNumber }))
        return
      }

      showSuccessToast(t('successToast', { referenceNumber }))
      history.push(ROOT.ORGS.ORG(shortEntityId).INVOICING.INVOICES.path)
    } catch {
      showErrorToast(t('errorToast', { referenceNumber }))
    } finally {
      closeModal()
    }
  }, [
    closeModal,
    history,
    referenceNumber,
    saveAndSendInvoice,
    shortEntityId,
    t,
    trackInvoiceAction,
  ])

  return (
    <DecisionModal
      isOpen={activeModal === InvoiceDecisionModalTypes.SendNow}
      isLoadingPrimary={isSavingAndSendingInvoice}
      onCancel={closeModal}
      onClickPrimary={handleSendInvoice}
      onClickSecondary={closeModal}
      primaryButtonLabel={t('sendNow')}
      secondaryButtonLabel={t('cancel')}
      title={t('title')}
    >
      {t('description', { referenceNumber })}
    </DecisionModal>
  )
}
