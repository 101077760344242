import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvSelectedTypes } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ReactComponent as StatusIcon } from 'assets/svg/status.svg'
import { TRANSACTION_STATUSES } from 'const/common'
import { getSelectedItemsInfo } from 'utils/picker'
import { PickerItemProps } from 'types/picker'
import {
  StyledDropdown,
  StyledOpenOptions,
  StyledStatusesBox,
} from 'components/Filters/NewFilters/StatusPicker/StatusPicker.styled'
import { MultiDownshift } from 'components/MultiDownshift'
import { MultiDownshiftComponentName } from 'components/MultiDownshift/MultiDownshift.hooks'
import {
  AllItemsRow,
  BottomRow,
  StatusRow,
  TopRow,
} from 'components/PickerElements'
import { PickerTrigger } from 'components/PickerElements/PickerTrigger'
import { StyledDataSmall } from 'components/Shared'
import { component } from 'translations'

import { shouldAllChecked, shouldOptionDisabled } from './StatusPicke.utils'

export const StatusPicker: React.FC = () => {
  const selectedTypes = useReactiveVar(rvSelectedTypes)
  return (
    <MultiDownshift
      itemToString={(item: PickerItemProps | null) => (item ? item.name : '')}
      componentName={MultiDownshiftComponentName.Status}
    >
      {({
        getToggleButtonProps,
        getRootProps,
        getItemProps,
        closeMenu,
        selectedItems,
        toggleStatuses,
        clearAllSelected,
        isOpen,
      }) => (
        <StyledDropdown
          {...getRootProps(undefined, { suppressRefError: true })}
          isOpen={isOpen}
        >
          <PickerTrigger
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            label={getSelectedItemsInfo(selectedItems, 'status')}
            placeholderIcon={<StatusIcon />}
            triggerContent={
              selectedItems?.length
                ? getSelectedItemsInfo(selectedItems, 'status')
                : undefined
            }
          />
          {isOpen && (
            <StyledOpenOptions>
              <TopRow
                copy={component.statusPicker.selectStatus}
                handleCloseMenu={closeMenu}
              />
              <StyledStatusesBox>
                {TRANSACTION_STATUSES.length > 0 && (
                  <AllItemsRow
                    label={component.statusPicker.allStatuses}
                    checked={shouldAllChecked(selectedTypes, selectedItems)}
                    handleOnChange={() => toggleStatuses(TRANSACTION_STATUSES)}
                  />
                )}
                {TRANSACTION_STATUSES.map((status) => {
                  return (
                    <StatusRow
                      key={status.name}
                      status={status}
                      getItemProps={getItemProps}
                      selectedItems={selectedItems}
                      disabled={shouldOptionDisabled(
                        status.value,
                        selectedTypes
                      )}
                    />
                  )
                })}
              </StyledStatusesBox>
              <BottomRow handleClearAll={clearAllSelected}>
                <StyledDataSmall data-testid="selected-items">
                  {getSelectedItemsInfo(selectedItems, 'status')}
                </StyledDataSmall>
              </BottomRow>
            </StyledOpenOptions>
          )}
        </StyledDropdown>
      )}
    </MultiDownshift>
  )
}
