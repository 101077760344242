import { BREAKPOINT } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { LogoIconLink } from 'components/LogoIconLink'

export const Header = styled.header`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    align-items: center;
    display: flex;
    margin: 27px 10px 24px 10px;
  }
`

export const FoldButton = styled.button`
  background: none;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin: 1px -8px 0 -8px;
  padding: 8px 8px 6px 8px;
`

export const SidebarLogo = styled(LogoIconLink)<{ $isFolded: boolean }>`
  border-radius: 8px;
  display: none;
  pointer-events: none;
  transition: margin 0.2s ease-in-out, opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
  visibility: ${({ $isFolded: isFolded }) => (isFolded ? 'hidden' : 'visible')};

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: flex;
    justify-content: center;
    margin: -3px 0 0 32px;
    opacity: ${({ $isFolded: isFolded }) => (isFolded ? 0 : 1)};
    padding: 7px 8px 7px 8px;
    pointer-events: ${({ $isFolded: isFolded }) => (isFolded ? 'none' : 'all')};
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    margin-left: 52px;
  }
`
