import {
  BodyDefaultTypography,
  BodySmallTypography,
  ModalBasic,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const DescriptionFooter = styled(ModalBasic.Footer)`
  align-items: flex-end;
`

export const DescriptionCharacterLength = styled.div`
  ${BodySmallTypography}

  align-items: flex-end;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const DescriptionTextArea = styled.textarea`
  ${BodyDefaultTypography}

  background-color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  font-family: inherit;
  margin-bottom: 0;
  min-height: 632px;
  outline: none;
  padding: 0;
  resize: none;
`
