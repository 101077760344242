import { gql } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'

import { GetAccountWithHighestFundsDebitCardAccountV2Fragment } from './SavingsAccountEmptyTransactions.utils.generated'

export const getAccountWithHighestFunds = (
  accounts: GetAccountWithHighestFundsDebitCardAccountV2Fragment[]
) => {
  const activeDebitAccounts = accounts.filter(
    (account) =>
      account?.status === DebitCardAccountStatus.ACTIVE &&
      account.type === DebitCardAccountType.ZLR_DEBIT
  )
  if (!activeDebitAccounts.length) {
    return null
  }

  const highestFundsAccount = activeDebitAccounts.reduce((prev, current) => {
    return prev?.balance?.value &&
      current?.balance?.value &&
      parseInt(prev.balance?.value, 10) >= parseInt(current.balance?.value, 10)
      ? prev
      : current
  })
  return highestFundsAccount?.id
}

getAccountWithHighestFunds.fragments = {
  DebitCardAccountV2: gql`
    fragment GetAccountWithHighestFundsDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      status
      type
      balance {
        value
      }
    }
  `,
}
