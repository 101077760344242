import { useFetchSimStatements } from 'pages/Settings/Sim/SimStatements/hooks/useFetchSimStatements'
import { CustomRange } from 'components/Statements/StatementsButtons/CustomRange/CustomRange'
import { CustomRangeButton } from 'components/Statements/StatementsButtons/CustomRangeButton/CustomRangeButton'

export const SimCustomRange = () => {
  const { exportFile, isLoadingStatement, hasLoaded } = useFetchSimStatements()

  return (
    <CustomRange>
      <CustomRangeButton
        getStatement={exportFile}
        isLoadingData={isLoadingStatement}
        hasLoaded={hasLoaded}
      />
    </CustomRange>
  )
}
