import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT, EducationPanel } from '@npco/zeller-design-system'

import CustomisationPreviewImage from 'assets/images/invoicing-customisation-preview.png'
import MobileCustomisationPreviewImage from 'assets/images/invoicing-customisation-preview-mobile.png'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { INVOICING_SETTINGS_CUSTOMISATION } from 'services/localStorage/keys'

import { translations } from './SettingsCustomisationComingSoon.i18n'

export const SettingsCustomisationComingSoon = () => {
  const t = useTranslations(translations)
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.SM)

  useEffect(() => {
    const hasVisited =
      localStorage.getItem(INVOICING_SETTINGS_CUSTOMISATION) === 'true'

    if (!hasVisited) {
      localStorage.setItem(INVOICING_SETTINGS_CUSTOMISATION, 'true')
    }
  }, [])

  const benefits = [
    t('benefits1'),
    t('benefits2'),
    t('benefits3'),
    t('benefits4'),
  ]

  return (
    <EducationPanel
      image={
        <img
          src={
            isMobileResolution
              ? MobileCustomisationPreviewImage
              : CustomisationPreviewImage
          }
          alt="Customisation Preview"
        />
      }
      tag={t('comingSoonPill')}
      title={t('title')}
      description={t('description')}
      items={benefits}
    />
  )
}
