import { createTranslations } from '@npco/utils-translations'

export const productDisplayTranslation = createTranslations({
  title: 'Zeller Terminal Bundle{breakLine}{discountTitle}} {discountPrice}',
  color1: 'White',
  color2: 'Black',
  description:
    "{exclusiveOffer} Australia's favourite EFTPOS solution\n plus 5 bonus items, {only99} Offer only available during sign up.",
  exclusiveOffer: 'Welcome Offer unlocked!',
  // We need $ before injected variable here
  // eslint-disable-next-line no-template-curly-in-string
  only99: 'only ${price}.',
  buyNow: 'Buy Now',
  black: 'Black',
  white: 'White',
  // We need $ before injected variable here
  // eslint-disable-next-line no-template-curly-in-string
  originalPrice: '${originalPrice}',
})
