import { gql, type TypedDocumentNode } from '@apollo/client'

export type AverageCellCashFlowCategoryNetAmountFragment = {
  __typename?: 'CashFlowCategoryNetAmount'
  average: { __typename?: 'Money'; currency: string; value: string }
}

export type AverageCellCashFlowContactNetAmountFragment = {
  __typename?: 'CashFlowContactNetAmount'
  average: { __typename?: 'Money'; currency: string; value: string }
}

export type AverageCellCashFlowSubcategoryNetAmountFragment = {
  __typename?: 'CashFlowSubcategoryNetAmount'
  average: { __typename?: 'Money'; currency: string; value: string }
}

export const AverageCellCashFlowCategoryNetAmountFragmentDoc = gql`
  fragment AverageCellCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
    average {
      currency
      value
    }
  }
` as unknown as TypedDocumentNode<
  AverageCellCashFlowCategoryNetAmountFragment,
  undefined
>
export const AverageCellCashFlowContactNetAmountFragmentDoc = gql`
  fragment AverageCellCashFlowContactNetAmountFragment on CashFlowContactNetAmount {
    average {
      currency
      value
    }
  }
`
export const AverageCellCashFlowSubcategoryNetAmountFragmentDoc = gql`
  fragment AverageCellCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
    average {
      currency
      value
    }
  }
`
