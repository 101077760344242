import { translate } from 'utils/translations'

import { AddressToggle } from '../../AddressToggle/AddressToggle'

interface GoToCustomAddressPageProps {
  toggleDescription?: string
  onClick: () => void
}

export const GoToCustomAddressPage = ({
  toggleDescription = translate('page.deliveryAddressForm.description'),
  onClick,
}: GoToCustomAddressPageProps) => {
  return (
    <AddressToggle
      buttonLabel={translate('page.deliveryAddressForm.customAddress')}
      onClick={onClick}
      toggleDescription={toggleDescription}
    />
  )
}
