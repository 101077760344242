import { BalanceBarAnimation } from './BalanceBarAnimation/BalanceBarAnimation'
import { OutstandingExpensesAnimation } from './OutstandingExpensesAnimation/OutstandingExpensesAnimation'
import { SuccessHiFiveIllustration } from './SuccessHiFiveIllustration/SuccessHiFiveIllustration'

export interface ToolTipGraphicsProps {
  index: number
  isAdmin: boolean
}
export const ToolTipGraphics = ({ index, isAdmin }: ToolTipGraphicsProps) => {
  return (
    <>
      {index === 0 && <OutstandingExpensesAnimation />}
      {index === 1 && <BalanceBarAnimation />}
      {index === 2 && !isAdmin && <SuccessHiFiveIllustration />}
      {index === 3 && isAdmin && <SuccessHiFiveIllustration />}
    </>
  )
}
