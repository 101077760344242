import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { SetPinState, SetPinStateConfigType } from './useGoToSetPinStage.types'

export const useGoToSetPinStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToSetPinStage = useCallback(
    (state: SetPinState | undefined, config?: SetPinStateConfigType) => {
      navigate(config?.pathname || location.pathname, {
        state: {
          SetPinModal: state,
        },
      })
    },
    [navigate, location]
  )

  return { goToSetPinStage }
}
