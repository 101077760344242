import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  notifications: 'Notifications',
  sites: 'Sites',
  virtualTerminal: 'Virtual Terminal',
  reports: 'Reports',
  cashflow: 'Cashflow',
  items: 'Items',
  posLite: 'POS Lite',
  sales: 'Sales',
  expenses: 'Expenses',
})
