import { mock } from '@npco/utils-apollo-testing-link'

import {
  GetProducts,
  GetProductsQueryResponse,
  GetProductsQueryVariables,
} from '../GetProducts.generated'

type ProductNode = NonNullable<
  NonNullable<GetProductsQueryResponse['site']['products']['edges']>[number]
>['node']

type ProductOptionsType = ProductNode['productOptions']

export const MOCK_PICKLIST_OPTIONS = {
  edges: [
    {
      node: {
        __typename: 'MultipleChoiceOption',
        entityId: 23,
        values: {
          edges: [
            {
              node: {
                entityId: 136,
                __typename: 'ProductPickListOptionValue',
              },
              __typename: 'ProductOptionValueEdge',
            },
          ],
          __typename: 'ProductOptionValueConnection',
        },
      },
      __typename: 'ProductOptionEdge',
    },
    {
      node: {
        __typename: 'MultipleChoiceOption',
        entityId: 24,
        values: {
          edges: [
            {
              node: {
                entityId: 137,
                __typename: 'ProductPickListOptionValue',
              },
              __typename: 'ProductOptionValueEdge',
            },
            {
              node: {
                entityId: 139,
                __typename: 'ProductPickListOptionValue',
              },
              __typename: 'ProductOptionValueEdge',
            },
          ],
          __typename: 'ProductOptionValueConnection',
        },
      },
      __typename: 'ProductOptionEdge',
    },
  ],
  __typename: 'ProductOptionConnection',
} satisfies ProductOptionsType

export const GET_PRODUCT_RESPONSE_MOCK = {
  site: {
    products: {
      edges: [
        {
          node: {
            entityId: 150,
            name: 'Zeller Terminal 1 Bundle - White',
            description:
              "<p><strong>Exclusive to myzeller.com</strong>. Buy Australia's favourite<br />EFTPOS solution with 5 free items, <strong>for only $99</strong>.</p>",
            sku: 'TER-WHT-1-AU-BDL',
            plainTextDescription:
              "Exclusive to myzeller.com. Buy Australia's favouriteEFTPOS solution with 5 free items, for only $99.",
            defaultImage: {
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/945/t1_white_-_right_hero_-_320x287pxH_-_v3_1__82982.1724395840.png',
              altText: 'Zeller Terminal 1 White',
              __typename: 'Image',
            },
            images: {
              edges: [
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/945/t1_white_-_right_hero_-_320x287pxH_-_v3_1__82982.1724395840.png',
                    altText: 'Zeller Terminal 1 White',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/839/Debit_Card_-_White__96932.1724395839.png',
                    altText: 'Zeller Debit Card',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/889/T1_Dock_-_White__17594.1724395839.png',
                    altText: 'Charging Dock',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/838/T1_Charger_-_White__32573.1724395839.png',
                    altText: 'Charging Cable',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/840/Sticker_Set__53512.1724395839.png',
                    altText: 'Marketing Kit',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/150/841/Receipt_Paper__61741.1724395839.png',
                    altText: 'Receipt Roll',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
              ],
              __typename: 'ImageConnection',
            },
            prices: {
              basePrice: {
                value: 159,
                __typename: 'Money',
              },
              salePrice: {
                value: 99,
                __typename: 'Money',
              },
              price: {
                value: 99,
                __typename: 'Money',
              },
              __typename: 'Prices',
            },
            productOptions: {
              edges: [
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 23,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 136,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 24,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 137,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
              __typename: 'ProductOptionConnection',
            },
            categories: {
              edges: [
                {
                  node: {
                    entityId: 35,
                    name: 'T1 Terminals',
                    __typename: 'Category',
                  },
                  __typename: 'CategoryEdge',
                },
              ],
              __typename: 'CategoryConnection',
            },
            customFields: {
              edges: [
                {
                  node: {
                    entityId: 72,
                    name: 'Colour',
                    value: 'White',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 93,
                    name: 'ProductDisplayName',
                    value: 'Terminal 1',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 117,
                    name: 'SortId',
                    value: '3',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
              ],
              __typename: 'CustomFieldConnection',
            },
            __typename: 'Product',
          },
          __typename: 'ProductEdge',
        },
        {
          node: {
            entityId: 151,
            name: 'Zeller Terminal 1 Bundle - Black',
            description:
              "<p><strong>Exclusive to myzeller.com</strong>. Buy Australia's favourite<br />EFTPOS solution with 5 free items, <strong>for only $99</strong>.</p>",
            sku: 'TER-BLK-1-AU-BDL',
            plainTextDescription:
              "Exclusive to myzeller.com. Buy Australia's favouriteEFTPOS solution with 5 free items, for only $99.",
            defaultImage: {
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/944/t1_black_-_right_hero_-_320x287pxH_-_v3_1__12664.1724395803.png',
              altText: 'Zeller Terminal 1 Black',
              __typename: 'Image',
            },
            images: {
              edges: [
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/944/t1_black_-_right_hero_-_320x287pxH_-_v3_1__12664.1724395803.png',
                    altText: 'Zeller Terminal 1 Black',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/832/Debit_Card_-_Black__62926.1724395803.png',
                    altText: 'Zeller Debit Card',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/888/T1_Dock_-_Black__25713.1724395803.png',
                    altText: 'Charging Dock',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/837/T1_Charger_-_Black__07238.1724395803.png',
                    altText: 'Charging Cable',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/833/Sticker_Set__80181.1724395803.png',
                    altText: 'Marketing Kit',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/151/834/Receipt_Paper__11083.1724395803.png',
                    altText: 'Receipt Roll',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
              ],
              __typename: 'ImageConnection',
            },
            prices: {
              basePrice: {
                value: 159,
                __typename: 'Money',
              },
              salePrice: {
                value: 99,
                __typename: 'Money',
              },
              price: {
                value: 99,
                __typename: 'Money',
              },
              __typename: 'Prices',
            },
            productOptions: {
              edges: [
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 27,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 139,
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  __typename: 'ProductOptionEdge',
                  node: {
                    __typename: 'MultipleChoiceOption',
                    entityId: 28,
                    values: {
                      __typename: 'ProductOptionValueConnection',
                      edges: [
                        {
                          __typename: 'ProductOptionValueEdge',
                          node: {
                            __typename: 'ProductPickListOptionValue',
                            entityId: 140,
                          },
                        },
                      ],
                    },
                  },
                },
              ],
              __typename: 'ProductOptionConnection',
            },
            categories: {
              edges: [
                {
                  node: {
                    entityId: 35,
                    name: 'T1 Terminals',
                    __typename: 'Category',
                  },
                  __typename: 'CategoryEdge',
                },
              ],
              __typename: 'CategoryConnection',
            },
            customFields: {
              edges: [
                {
                  node: {
                    entityId: 89,
                    name: 'Colour',
                    value: 'Black',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 94,
                    name: 'ProductDisplayName',
                    value: 'Terminal 1',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 118,
                    name: 'SortId',
                    value: '4',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
              ],
              __typename: 'CustomFieldConnection',
            },
            __typename: 'Product',
          },
          __typename: 'ProductEdge',
        },
        {
          node: {
            entityId: 172,
            name: 'Terminal 2 White',
            description: '',
            sku: 'TER-WHT-2-AU-ONB',
            plainTextDescription: '',
            defaultImage: {
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/172/943/t2_white_-_right_hero_-_320x287pxH_-_v3_1__36423.1724395728.png',
              altText: 'Zeller Terminal 2 White',
              __typename: 'Image',
            },
            images: {
              edges: [
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/172/943/t2_white_-_right_hero_-_320x287pxH_-_v3_1__36423.1724395728.png',
                    altText: 'Zeller Terminal',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/172/915/04805977-e9a6-4d2a-9ac5-b1917fa9460a__81909.1724376757.png',
                    altText: 'Zeller Debit Card',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/172/917/cbc70225-d9b6-450b-9d9a-4b55dc571af0__36615.1724390866.png',
                    altText: 'Charging Cable',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/172/916/171177d2-8263-4baf-8f60-ffeb9f974679__29423.1724390866.png',
                    altText: 'Marketing Kit',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
              ],
              __typename: 'ImageConnection',
            },
            prices: {
              basePrice: {
                value: 199,
                __typename: 'Money',
              },
              salePrice: {
                value: 199,
                __typename: 'Money',
              },
              price: {
                value: 199,
                __typename: 'Money',
              },
              __typename: 'Prices',
            },
            productOptions: {
              edges: [],
              __typename: 'ProductOptionConnection',
            },
            categories: {
              edges: [
                {
                  node: {
                    entityId: 37,
                    name: 'T2 Terminals',
                    __typename: 'Category',
                  },
                  __typename: 'CategoryEdge',
                },
              ],
              __typename: 'CategoryConnection',
            },
            customFields: {
              edges: [
                {
                  node: {
                    entityId: 98,
                    name: 'Colour',
                    value: 'White',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 99,
                    name: 'ProductDisplayName',
                    value: 'Terminal 2',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 114,
                    name: 'SortId',
                    value: '0',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
              ],
              __typename: 'CustomFieldConnection',
            },
            __typename: 'Product',
          },
          __typename: 'ProductEdge',
        },
        {
          node: {
            entityId: 176,
            name: 'Terminal 2 Black',
            description: '',
            sku: 'TER-BLK-2-AU-ONB',
            plainTextDescription: '',
            defaultImage: {
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/176/942/t2_black_-_right_hero_-_320x287pxH_-_v3_1__87915.1724395700.png',
              altText: 'Zeller Terminal 2 Black',
              __typename: 'Image',
            },
            images: {
              edges: [
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/176/942/t2_black_-_right_hero_-_320x287pxH_-_v3_1__87915.1724395700.png',
                    altText: 'Zeller Terminal',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/176/933/314e7587-c91a-43bc-9571-49e6a1159c4e__56411.1724395565.png',
                    altText: 'Zeller Debit Card',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/176/932/84d57fca-9e3d-45cd-934c-c07361c22646__79373.1724395565.png',
                    altText: 'Charging Cable',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/176/934/deb03ba5-bda3-460a-b87d-797e0caad515__65588.1724395565.png',
                    altText: 'Marketing Kit',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
              ],
              __typename: 'ImageConnection',
            },
            prices: {
              basePrice: {
                value: 199,
                __typename: 'Money',
              },
              salePrice: {
                value: 199,
                __typename: 'Money',
              },
              price: {
                value: 199,
                __typename: 'Money',
              },
              __typename: 'Prices',
            },
            productOptions: {
              edges: [],
              __typename: 'ProductOptionConnection',
            },
            categories: {
              edges: [
                {
                  node: {
                    entityId: 37,
                    name: 'T2 Terminals',
                    __typename: 'Category',
                  },
                  __typename: 'CategoryEdge',
                },
              ],
              __typename: 'CategoryConnection',
            },
            customFields: {
              edges: [
                {
                  node: {
                    entityId: 100,
                    name: 'Colour',
                    value: 'Black',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 101,
                    name: 'ProductDisplayName',
                    value: 'Terminal 2',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 115,
                    name: 'SortId',
                    value: '1',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
              ],
              __typename: 'CustomFieldConnection',
            },
            __typename: 'Product',
          },
          __typename: 'ProductEdge',
        },
        {
          node: {
            entityId: 177,
            name: 'Terminal 2 Graphite',
            description: '',
            sku: 'TER-GRT-2-AU-ONB',
            plainTextDescription: '',
            defaultImage: {
              url320wide:
                'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/177/941/t2_graphite_-_right_hero_-_320x287pxH_-_v3_1__83035.1724395713.png',
              altText: 'Zeller Terminal 2 Graphite',
              __typename: 'Image',
            },
            images: {
              edges: [
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/177/941/t2_graphite_-_right_hero_-_320x287pxH_-_v3_1__83035.1724395713.png',
                    altText: 'Zeller Terminal',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/177/935/Debit_Card_-_Black__95056.1722832282.1280.1280__62859.1722832698.1280.1280__45615.1724395491.png',
                    altText: 'Zeller Debit Card',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/177/937/T2_Charger_-_Black__65523.1722832282.1280.1280__19944.1722832698.1280.1280__30112.1724395491.png',
                    altText: 'Charging Cable',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
                {
                  node: {
                    url320wide:
                      'https://cdn11.bigcommerce.com/s-1gv38915hb/images/stencil/320w/products/177/936/1722832698.1280.1280__25417.1724395491.png',
                    altText: 'Marketing Kit',
                    __typename: 'Image',
                  },
                  __typename: 'ImageEdge',
                },
              ],
              __typename: 'ImageConnection',
            },
            prices: {
              basePrice: {
                value: 199,
                __typename: 'Money',
              },
              salePrice: {
                value: 199,
                __typename: 'Money',
              },
              price: {
                value: 199,
                __typename: 'Money',
              },
              __typename: 'Prices',
            },
            productOptions: {
              edges: [],
              __typename: 'ProductOptionConnection',
            },
            categories: {
              edges: [
                {
                  node: {
                    entityId: 37,
                    name: 'T2 Terminals',
                    __typename: 'Category',
                  },
                  __typename: 'CategoryEdge',
                },
              ],
              __typename: 'CategoryConnection',
            },
            customFields: {
              edges: [
                {
                  node: {
                    entityId: 104,
                    name: 'Colour',
                    value: 'Graphite',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 105,
                    name: 'ProductDisplayName',
                    value: 'Terminal 2',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
                {
                  node: {
                    entityId: 116,
                    name: 'SortId',
                    value: '2',
                    __typename: 'CustomField',
                  },
                  __typename: 'CustomFieldEdge',
                },
              ],
              __typename: 'CustomFieldConnection',
            },
            __typename: 'Product',
          },
          __typename: 'ProductEdge',
        },
      ],
      __typename: 'ProductConnection',
    },
    __typename: 'Site',
  },
} satisfies GetProductsQueryResponse

const MissingProducts = GET_PRODUCT_RESPONSE_MOCK.site.products.edges.map(
  (data) => ({
    ...data,
    node: {
      ...data.node,
      prices: {
        ...data.node.prices,
        price: { ...data.node.prices.price, value: null },
      },
    },
  })
)

const GET_PRODUCT_RESPONSE_WITH_MISSING_PRICE = {
  site: {
    products: {
      edges: MissingProducts,
    },
  },
} satisfies GetProductsQueryResponse

export const SUCCESS_VARIABLE = {
  pageSize: 10,
} satisfies GetProductsQueryVariables

export const GetProductsMocks = mock<
  GetProductsQueryVariables,
  GetProductsQueryResponse
>(GetProducts)

export const GetProductsSuccessMocks = GetProductsMocks.mockVariables(
  SUCCESS_VARIABLE
).mockData(GET_PRODUCT_RESPONSE_MOCK)

export const GetProductsSuccessMocksCypress = GetProductsMocks.mockData(
  GET_PRODUCT_RESPONSE_MOCK
)

export const GetMissingPriceProductsMock = GetProductsSuccessMocks.mockData(
  GET_PRODUCT_RESPONSE_WITH_MISSING_PRICE
)

export const GetProductsErrorMocks = GetProductsMocks.mockGraphQLErrors()

export const GetProductsNetworkErrorMock = GetProductsMocks.mockNetworkError()
