import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const DeviceStatusWrapper = styled(Flex)`
  gap: 4px;
`

export const TimeStatusWrapper = styled(Flex)`
  font-size: 7px;
  font-style: normal;
  font-weight: 600;
`
