import { BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CardInfoFormWrapper = styled(Flex)`
  width: 100%;
  > form {
    width: 100%;
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: initial;
    > form {
      width: initial;
    }
  }
`
