import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { BREAKPOINT } from 'styles/breakpoints'

import { SelectInputBasicProps, SelectItemBasic } from '../Select.types'
import { DEFAULT_MAX_HEIGHT } from '../Select.utils'
import { SelectBasicDesktop } from './SelectBasicDesktop/SelectBasicDesktop'
import { SelectBasicMobile } from './SelectBasicMobile/SelectBasicMobile'

export const SelectBasic = <ItemType extends SelectItemBasic>({
  items = [],
  selectedItem,
  onBlur,
  onChange,
  renderTrigger,
  renderItem,
  onClose,
  onOpen,
  onFocus,
  hasError,
  isDisabled,
  renderLabel,
  isSearchableByKeypress,
  menuStyle,
  mobileLabel,
  placement,
  maxHeight = DEFAULT_MAX_HEIGHT,
  popperWidth,
  renderNoItemsPlaceholder = () => null,
  renderAdditionalControls,
  filterItems = (item: ItemType) => Boolean(item),
  renderBottomSection,
  shouldCloseOnSelect,
  isLoading = false,
  renderLoadingPlaceholder,
  isResettable,
  isClearable,
  size,
  shouldAutoFocusControl,
}: SelectInputBasicProps<ItemType>) => {
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.MD)

  return isMobileResolution ? (
    <SelectBasicMobile
      mobileLabel={mobileLabel}
      items={items}
      renderTrigger={renderTrigger}
      renderAdditionalControls={renderAdditionalControls}
      menuStyle={menuStyle}
      selectedItem={selectedItem}
      renderItem={renderItem}
      onChange={onChange}
      onOpen={onOpen}
      onFocus={onFocus}
      onClose={onClose}
      isDisabled={isDisabled}
      renderLabel={renderLabel}
      renderNoItemsPlaceholder={renderNoItemsPlaceholder}
      filterItems={filterItems}
      isLoading={isLoading}
      renderLoadingPlaceholder={renderLoadingPlaceholder}
      hasError={hasError}
      shouldCloseOnSelect={shouldCloseOnSelect}
      renderBottomSection={renderBottomSection}
      shouldAutoFocusControl={shouldAutoFocusControl}
    />
  ) : (
    <SelectBasicDesktop<ItemType>
      items={items}
      selectedItem={selectedItem}
      onChange={onChange}
      renderTrigger={renderTrigger}
      renderItem={renderItem}
      onBlur={onBlur}
      onClose={onClose}
      onOpen={onOpen}
      onFocus={onFocus}
      hasError={hasError}
      isDisabled={isDisabled}
      renderLabel={renderLabel}
      isSearchableByKeypress={isSearchableByKeypress}
      menuStyle={menuStyle}
      maxHeight={maxHeight}
      renderNoItemsPlaceholder={renderNoItemsPlaceholder}
      renderAdditionalControls={renderAdditionalControls}
      filterItems={filterItems}
      isLoading={isLoading}
      renderLoadingPlaceholder={renderLoadingPlaceholder}
      placement={placement}
      popperWidth={popperWidth}
      shouldCloseOnSelect={shouldCloseOnSelect}
      renderBottomSection={renderBottomSection}
      isResettable={isResettable}
      size={size}
      shouldAutoFocusControl={shouldAutoFocusControl}
      isClearable={isClearable}
    />
  )
}
