import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT, useIsMobileResolution } from '@npco/zeller-design-system'

import { ACCOUNT_ROUTE, EmptyStateBanner } from '../EmptyStateBanner'
import CashflowDesktop from './assets/cashflow-desktop.png'
import CashflowMobile from './assets/cashflow-mobile.png'
import { noAccountBannerTranslations } from './NoAccountBanner.i18n'

export const NoAccountBanner = () => {
  const t = useTranslations(noAccountBannerTranslations)

  const isMobileOrTabletBreakpoint = useIsMobileResolution(BREAKPOINT.SM)
  const image = useMemo(() => {
    const source = isMobileOrTabletBreakpoint ? CashflowMobile : CashflowDesktop
    const width = isMobileOrTabletBreakpoint ? '343' : '535'
    return <img alt={t('cashflowImageAlt')} src={source} width={width} />
  }, [isMobileOrTabletBreakpoint, t])

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <EmptyStateBanner
      data-testid="no-active-account-banner"
      image={image}
      ctaPrimary={{
        text: t('ctaPrimaryText'),
        onClick: () => navigate(ACCOUNT_ROUTE(shortEntityId)),
      }}
      description={t('description')}
      title={t('title')}
      items={[t('point1'), t('point2'), t('point3'), t('point4')]}
      ctaDescription={t('ctaDescription')}
    />
  )
}
