import { ApolloError } from '@apollo/client'
import { Box } from '@npco/zeller-design-system'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'
import { isEmpty } from 'lodash-es'

import { PartialTransactionUpdateType } from 'hooks/transactions'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { NoSearchResults } from 'components/Placeholders/NoAccountTransactions'
import { PlaceholderSize } from 'components/Placeholders/Placeholders.types'

import { DebitCardTransactionListSkeleton } from './DebitCardTransactionListSkeleton/DebitCardTransactionListSkeleton'
import { DebitCardTransactionsList } from './DebitCardTransactionsList/DebitCardTransactionsList'
import { TransactionListRowBaseProps } from './DebitCardTransactionsList/DebitCardTransactionsList.types'
import { ListGroupItemDebitCardTransactions } from './DebitCardTransactionsList/ListGroupItemDebitCardTransactions/ListGroupItemDebitCardTransactions'
import { NoDebitCardTransactions } from './NoDebitCardTransactions/NoDebitCardTransactions'

interface ErrorDisplayProps {
  retry: () => void
}

interface DebitCardTransactionsListProps {
  groupedDebitCardTransactions: (
    | DebitCardTransactionV3
    | DebitCardTransactionV2
  )[][]
  hasNoInitialResults: boolean
  onLoadMore?: () => void
  loading: boolean
  hasMore?: boolean
  error: ApolloError | undefined
  refetch: () => void
  handleSelectedTransactionUpdate: (
    update: PartialTransactionUpdateType
  ) => void
  listHeight?: string
  ErrorDisplay?: (errorProps: ErrorDisplayProps) => JSX.Element
  Row?: React.ForwardRefExoticComponent<
    TransactionListRowBaseProps & React.RefAttributes<HTMLLIElement>
  >
  unfilteredEmptyState?: JSX.Element
  filteredEmptyState?: JSX.Element
  showMobileStyle?: boolean
}

export const DebitCardTransactions = ({
  groupedDebitCardTransactions,
  hasNoInitialResults,
  onLoadMore,
  loading,
  hasMore = false,
  error,
  refetch,
  handleSelectedTransactionUpdate,
  listHeight,
  ErrorDisplay = ({ retry }) => (
    <GenericErrorPage variant={PlaceholderSize.Large} retry={retry} />
  ),
  Row = ListGroupItemDebitCardTransactions,
  unfilteredEmptyState = <NoDebitCardTransactions />,
  filteredEmptyState = <NoSearchResults />,
  showMobileStyle = false,
}: DebitCardTransactionsListProps) => {
  if (loading) {
    return (
      <DebitCardTransactionListSkeleton showMobileStyle={showMobileStyle} />
    )
  }

  if (error) {
    return <ErrorDisplay retry={() => refetch()} />
  }

  if (hasNoInitialResults) {
    return unfilteredEmptyState
  }

  if (isEmpty(groupedDebitCardTransactions)) {
    return filteredEmptyState
  }

  return (
    <Box overflow="hidden">
      <DebitCardTransactionsList
        groupedTransactions={groupedDebitCardTransactions}
        fetchMore={onLoadMore}
        hasMore={hasMore}
        handleSelectedTransactionUpdate={handleSelectedTransactionUpdate}
        listHeight={listHeight}
        Row={Row}
        showMobileStyle={showMobileStyle}
      />
    </Box>
  )
}
