import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Assign Sites',
  sites: 'Sites',
  venue: 'Venue',
  location: 'Location',
  nSites: '{count} Sites',
  siteFieldLabel: 'Site',
  siteFieldLabelEmpty: 'Assign Site',
  siteOptionsHeaderLabel: 'Select Sites',
  venueDescription:
    'Assign Sites to your venue, enabling terminals to manage payments across all locations and their tables within the venue.',
  locationDescription:
    'Assign Sites to a specific location, allowing terminals to manage payments for the assigned location and its tables.',
  stationId: 'Station ID',
  stationIdRemoveMsg: 'Please remove station ID or assign a Site.',
  stationIdRequiredMsg: 'Please enter a valid station ID or remove Site.',
  stationIdInvalidMsg: 'Please enter a valid Station ID.',
  assignedToVenue: `Assigned to {venue}`,
  assignedToVenueLocation: `Assigned to {venue} in {location}`,
})
