import { gql } from '@apollo/client'
import { PdfRemittanceButton } from '@npco/component-mp-feature-transfer-remittances'
import { useTranslations } from '@npco/utils-translations'
import { Box, Flex } from '@npco/zeller-design-system'

import { useTransactionDetailsInfo } from 'hooks/banking'

import { CategoryPillDisplay } from './CategoryPillDisplay'
import { AccountDetailsListItem } from './Items/AccountDetailsListItem'
import { AdjustmentDetails } from './Items/AdjustmentDetails'
import { BasicDetailsListItems } from './Items/BasicDetailsListItems'
import { BpayDetails } from './Items/BpayDetails'
import { CardDetailsListItems } from './Items/CardDetailsListItems'
import { DirectDebitDetails } from './Items/DirectDebitDetails/DirectDebitDetails'
import { ExternalIncomingDetails } from './Items/ExternalIncomingDetails'
import { ExternalRecipientDetails } from './Items/ExternalRecipientDetails'
import { InternalTransferDetails } from './Items/InternalTransferDetails'
import { TransferInReversalDetails } from './Items/TransferInReversalDetails'
import { TransferOutReversalDetails } from './Items/TransferOutReversalDetails'
import {
  TransactionDetailsInfoDebitCardAccountTransactionFragment,
  TransactionDetailsInfoDebitCardTransactionV2Fragment,
} from './TransactionDetailsInfo.generated'
import { transactionDetailsInfoTranslations } from './TransactionDetailsInfo.i18n'
import { StyledDetailsTable } from './TransactionDetailsInfo.styled'

interface TransactionDetailsInfoProps {
  transaction:
    | TransactionDetailsInfoDebitCardTransactionV2Fragment
    | TransactionDetailsInfoDebitCardAccountTransactionFragment
}

export const TransactionDetailsInfo = ({
  transaction,
}: TransactionDetailsInfoProps) => {
  const { debitCardName, debitCardMaskedPan, reference, description } =
    transaction

  const {
    isExternalOutgoingTransfer,
    isExternalIncomingTransaction,
    isAdjustmentTransaction,
    isBpayTransaction,
    isBpayOutTransaction,
    isCardTransaction,
    isInternalTransfer,
    isDepositType,
    account,
    recipientDetails,
    isInterestTransaction,
    isTransferOutReversalTransaction,
    isTransferInReversalTransaction,
    isDirectDebitTransaction,
  } = useTransactionDetailsInfo(transaction)
  const t = useTranslations(transactionDetailsInfoTranslations)

  return (
    <StyledDetailsTable>
      <BasicDetailsListItems transaction={transaction} />

      {(isDepositType || isInterestTransaction) && (
        <Box mt="1.5rem">
          <AccountDetailsListItem accountName={account?.name} />
        </Box>
      )}

      {isCardTransaction && (
        <CardDetailsListItems
          cardAccountName={account?.name}
          debitCardMaskedPan={debitCardMaskedPan}
          debitCardName={debitCardName}
        />
      )}

      {isInternalTransfer && (
        <InternalTransferDetails
          accountName={account?.name}
          reference={reference}
        />
      )}

      {isAdjustmentTransaction && (
        <AdjustmentDetails
          accountName={account?.name}
          description={description}
        />
      )}

      {isBpayTransaction && <BpayDetails transaction={transaction} />}

      {isExternalOutgoingTransfer && (
        <ExternalRecipientDetails
          account={account?.name}
          reference={transaction.reference}
          bsbNumber={recipientDetails?.bsbNumber}
          recipientAccountName={recipientDetails?.recipientAccountName}
          recipientAccountNumber={recipientDetails?.recipientAccountNumber}
          referencePayee={recipientDetails?.referencePayee}
        />
      )}

      {isExternalIncomingTransaction && (
        <ExternalIncomingDetails
          accountName={account?.name}
          description={transaction.description || transaction.reference}
          reference={transaction.referencePayee}
        />
      )}

      {isTransferOutReversalTransaction && (
        <TransferOutReversalDetails
          payerDetails={transaction.payerDetails}
          reference={transaction.reference}
        />
      )}

      {isTransferInReversalTransaction && (
        <TransferInReversalDetails
          payeeDetails={transaction.payeeDetails}
          description={transaction.description}
        />
      )}

      {isDirectDebitTransaction && (
        <DirectDebitDetails transaction={transaction} />
      )}

      <CategoryPillDisplay transaction={transaction} />

      {(isExternalOutgoingTransfer || isBpayOutTransaction) && (
        <Flex flexDirection="column" alignItems="flex-end" pt={14}>
          <PdfRemittanceButton
            dcaTransactionUuid={transaction.id}
            downloadCopy={t('downloadRemittance')}
            downloadedCopy={t('remittanceDownloaded')}
            padding="4px 0 4px 12px"
          />
        </Flex>
      )}
    </StyledDetailsTable>
  )
}

TransactionDetailsInfo.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransactionDetailsInfoDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
      debitCardName
      debitCardMaskedPan
      reference
      referencePayee
      description

      ...UseTransactionDetailsInfoDebitCardTransactionV2Fragment
      ...BasicDetailsListItemsDebitCardTransactionV2Fragment
      ...CategoryPillDisplayDebitCardTransactionV2Fragment
      ...BpayDetailsDebitCardTransactionV2Fragment
      ...TransferInReversalDetailsDebitCardTransactionV2Fragment
      ...TransferOutReversalDetailsDebitCardTransactionV2Fragment
    }

    ${useTransactionDetailsInfo.fragments.DebitCardTransactionV2}
    ${BasicDetailsListItems.fragments.DebitCardTransactionV2}
    ${CategoryPillDisplay.fragments.DebitCardTransactionV2}
    ${BpayDetails.fragments.DebitCardTransactionV2}
    ${TransferInReversalDetails.fragments.DebitCardTransactionV2}
    ${TransferOutReversalDetails.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment TransactionDetailsInfoDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
      debitCardName
      debitCardMaskedPan
      reference
      referencePayee
      description

      ...UseTransactionDetailsInfoDebitCardAccountTransactionFragment
      ...BasicDetailsListItemsDebitCardAccountTransactionFragment
      ...CategoryPillDisplayDebitCardAccountTransactionFragment
      ...BpayDetailsDebitCardAccountTransactionFragment
      ...TransferInReversalDetailsDebitCardAccountTransactionFragment
      ...TransferOutReversalDetailsDebitCardAccountTransactionFragment
    }

    ${useTransactionDetailsInfo.fragments.DebitCardAccountTransaction}
    ${BasicDetailsListItems.fragments.DebitCardAccountTransaction}
    ${CategoryPillDisplay.fragments.DebitCardAccountTransaction}
    ${BpayDetails.fragments.DebitCardAccountTransaction}
    ${TransferInReversalDetails.fragments.DebitCardAccountTransaction}
    ${TransferOutReversalDetails.fragments.DebitCardAccountTransaction}
  `,
}
