import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'
import { z } from 'zod'

const ModalHasOpenTransitionLocationStateSchema = z.object({
  ModalHasOpenTransition: z.boolean().optional(),
})

export const useModalHasOpenTransition = () => {
  const locationState = useLocationState(
    ModalHasOpenTransitionLocationStateSchema
  )

  const hasOpenTransition = useMemo(() => {
    return Boolean(locationState?.ModalHasOpenTransition)
  }, [locationState?.ModalHasOpenTransition])

  return { hasOpenTransition }
}
