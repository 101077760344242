import { Fragment, ReactNode } from 'react'
import { isEmpty } from 'lodash-es'

import translations, { TranslatePath } from 'translations'

import { getFromObject } from './common'

// NOTE: https://regex101.com/r/T0qLmi/1
const VARIABLE_REGEX = /{\w+}/g

interface Range {
  start: number
  end: number
}

type SimpleVariableValues = string | number | undefined
type ComplexVariableValues = ReactNode
type VariableValues = SimpleVariableValues | ComplexVariableValues
export type SimpleVariablesObject = Record<string, SimpleVariableValues>
export type ComplexVariablesObject = Record<string, ComplexVariableValues>
export type VariablesObject = SimpleVariablesObject | ComplexVariablesObject

const findVariableRanges = (rawTranslation: string): Range[] => {
  const matches = Array.from(rawTranslation.matchAll(VARIABLE_REGEX))

  return matches.map(({ 0: matchedText, index }) => ({
    start: index ?? 0,
    end: (index ?? 0) + matchedText.length,
  }))
}

const breakTranslationIntoBlocks = (
  rawTranslation: string,
  ranges: Range[]
) => {
  const slices: string[] = []

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ranges.length; i++) {
    const previousMatch = ranges[i - 1]
    const currentMatch = ranges[i]

    const previousEnd = previousMatch?.end ?? 0

    const textFromPreviousRangeUntilCurrentRangeStart = rawTranslation.slice(
      previousEnd,
      currentMatch.start
    )
    const textInCurrentRange = rawTranslation.slice(
      currentMatch.start,
      currentMatch.end
    )

    slices.push(textFromPreviousRangeUntilCurrentRangeStart)
    slices.push(textInCurrentRange)
  }

  const lastMatch = ranges[ranges.length - 1]
  const remainingText = rawTranslation.slice(lastMatch.end)
  slices.push(remainingText)

  return slices
}

const replaceVariableStringsInBlock = (
  slices: string[],
  variables: VariablesObject
) => {
  return slices.map((slice): VariableValues | string => {
    const isSliceVariable = VARIABLE_REGEX.test(slice)

    if (!isSliceVariable) {
      return slice
    }

    const variableName = slice.replace(/[{}]/g, '')

    const variableValue = variables[variableName]

    if (variableValue === undefined) {
      return slice
    }

    return variableValue
  })
}

const getAreAllBlocksStringsOrNumbers = (
  blocks: VariableValues[]
): blocks is SimpleVariableValues[] => {
  return blocks.every(
    (part) => typeof part === 'string' || typeof part === 'number'
  )
}

const joinBlocks = (blocks: VariableValues[]) => {
  const areAllBlocksStringsOrNumbers = getAreAllBlocksStringsOrNumbers(blocks)

  // Done for legacy purposes to be 100% sure that it doesn't break anything in
  // places where it was used before JSX replacement has been introduced
  if (areAllBlocksStringsOrNumbers) {
    return blocks.join('')
  }

  return (
    <>
      {blocks.map((text, index) => {
        const key = `${text}-${index}`
        return <Fragment key={key}>{text}</Fragment>
      })}
    </>
  )
}

export function translate(
  path: TranslatePath,
  variables?: SimpleVariablesObject
): string
export function translate(
  path: TranslatePath,
  variables?: ComplexVariablesObject
): string | React.ReactNode
export function translate(
  path: TranslatePath,
  variables?: SimpleVariablesObject | ComplexVariablesObject
): string | React.ReactNode {
  const rawTranslation = getFromObject<string>(path, translations)

  if (!rawTranslation) {
    // eslint-disable-next-line no-console
    console.error(`Path: ${path} was not found in translations`)
    return undefined
  }

  if (!variables || isEmpty(variables)) {
    return rawTranslation
  }

  const rangesOfVariables = findVariableRanges(rawTranslation)

  if (rangesOfVariables.length === 0) {
    return rawTranslation
  }

  const blocks = breakTranslationIntoBlocks(rawTranslation, rangesOfVariables)

  const blocksWithReplacedVariables = replaceVariableStringsInBlock(
    blocks,
    variables
  )

  return joinBlocks(blocksWithReplacedVariables)
}

export function pluralTranslate(
  singularPath: TranslatePath,
  pluralPath: TranslatePath,
  count: number,
  variables?: SimpleVariablesObject
): string
export function pluralTranslate(
  singularPath: TranslatePath,
  pluralPath: TranslatePath,
  count: number,
  variables?: ComplexVariablesObject
): string | React.ReactNode
export function pluralTranslate(
  singularPath: TranslatePath,
  pluralPath: TranslatePath,
  count: number,
  variables?: SimpleVariablesObject | ComplexVariablesObject
): string | React.ReactNode {
  return translate(count === 1 ? singularPath : pluralPath, variables)
}
