import { BodySmall, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const AccountDetailTitle = styled(BodySmall)`
  margin: 0;
  white-space: nowrap;
  flex-grow: 1;
  font-weight: 500;
`

export const AccountDetailValue = styled(BodySmall)`
  margin: 0;
  padding-right: 8px;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    padding-right: 12px;
  }
`

export const AccountDetailItem = styled(Flex)`
  grid-gap: 8px;
`
