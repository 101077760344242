import React, { useMemo } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  Flex,
  FormExtensionBasic,
  InputAdaptiveField,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import {
  combineValidators,
  validateMaxLength,
  validateRequiredCustomMsg,
} from 'utils/formValidation'
import { MarkAsSelf } from 'components/MarkAsSelf/MarkAsSelf'
import { page } from 'translations'

import { translations } from '../Contacts.i18n'
import * as styled from '../Contacts.styled'
import { ContactFormData } from '../Contacts.types'
import {
  FIELD_ADDITIONAL_EMAIL_PERSON,
  FIELD_ADDITIONAL_PHONE_PERSON,
  FIELD_EMAIL_PERSON,
  FIELD_JOB_TITLE_PERSON,
  FIELD_PHONE_PERSON,
  FIELD_STREET_PERSON,
  type FieldProps,
} from './PersonShared.fields'

const MAX_LENGTH_FIRST_NAME = 20
const MAX_LENGTH_LAST_NAME = 40

interface PersonSimpleFieldsProps {
  existingContactName?: string | null
}

export const PersonFirstLastNameField = ({
  existingContactName,
}: PersonSimpleFieldsProps) => {
  const t = useTranslations(translations)
  return (
    <Flex flexDirection="column" mb="16px">
      <styled.RowContainer $mb={0}>
        <Flex flexDirection="column">
          <InputAdaptiveField
            hasError={Boolean(existingContactName)}
            name="person.firstName"
            label={t('firstName')}
            validate={combineValidators(
              validateRequiredCustomMsg(t('firstNameRequired')),
              validateMaxLength(
                MAX_LENGTH_FIRST_NAME,
                t('invalidFirstNameLength')
              )
            )}
          />
        </Flex>
        <Flex flexDirection="column">
          <InputAdaptiveField
            hasError={Boolean(existingContactName)}
            name="person.lastName"
            label={t('lastName')}
            validate={combineValidators(
              validateRequiredCustomMsg(t('lastNameRequired')),
              validateMaxLength(
                MAX_LENGTH_LAST_NAME,
                t('invalidLastNameLength')
              )
            )}
          />
        </Flex>
      </styled.RowContainer>

      {existingContactName && (
        <Flex
          alignItems="center"
          color={COLOR.RED_1000}
          data-testid="existing-contact-message"
          flexWrap="wrap"
          mt="8px"
        >
          {existingContactName} {page.contacts.form.existingPerson}
        </Flex>
      )}

      <MarkAsSelf name="person.isSelf" contactType={ContactType.PERSON} />
    </Flex>
  )
}

export const PersonSimpleFields = React.memo(
  ({ existingContactName }: PersonSimpleFieldsProps) => {
    const { values } = useFormikContext<ContactFormData>()
    const t = useTranslations(translations)

    const PERSON_FIELDS = [
      FIELD_JOB_TITLE_PERSON,
      FIELD_STREET_PERSON,
      {
        ...FIELD_EMAIL_PERSON,
        shouldDisplayContentOnMount: (values: ContactFormData) =>
          Boolean(values.person.email.email),
      },
      FIELD_ADDITIONAL_EMAIL_PERSON,
      {
        ...FIELD_PHONE_PERSON,
        shouldDisplayContentOnMount: (values: ContactFormData) =>
          Boolean(values.person.phoneV2?.phone),
      },
      FIELD_ADDITIONAL_PHONE_PERSON,
    ]

    const BUTTON_ORDER = [
      FIELD_JOB_TITLE_PERSON.itemId,
      FIELD_STREET_PERSON.itemId,
      FIELD_EMAIL_PERSON.itemId,
      FIELD_ADDITIONAL_EMAIL_PERSON.itemId,
      FIELD_PHONE_PERSON.itemId,
      FIELD_ADDITIONAL_PHONE_PERSON.itemId,
    ]

    const extendedFormFields = useMemo(() => {
      return PERSON_FIELDS.map((field: FieldProps) => ({
        ...field,
        initialDisplayedElementCount:
          field.initialDisplayedElementCount?.(values),
        shouldDisplayContentOnMount: field.shouldDisplayContentOnMount(values),
      }))
      // Run on mount to get initial displayed items for form extension
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <PersonFirstLastNameField existingContactName={existingContactName} />
        <styled.FormExtensionContainer>
          <FormExtensionBasic
            labelText={t('extendedFormFieldsLabel')}
            buttonOrder={BUTTON_ORDER}
          >
            {extendedFormFields}
          </FormExtensionBasic>
        </styled.FormExtensionContainer>
      </>
    )
  }
)
