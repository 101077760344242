import { useMemo } from 'react'

import { mapAccountTransactionFiltersToApiFilters } from 'utils/banking/mapAccountTransactionsFiltersToApiFilters/mapAccountTransactionsFiltersToApiFilters'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

export const useAccountsTransactionsFilters = () => {
  const { areFiltersInDefaultState, ...accountFilters } = useAccountFilters()

  const filterObject = useMemo(
    () =>
      mapAccountTransactionFiltersToApiFilters({
        selectedAccountID: undefined,
        ...accountFilters,
      }),
    [accountFilters]
  )

  return {
    areFiltersInDefaultState,
    filters: filterObject,
  }
}
