import { type ReactNode } from 'react'
import {
  Animation,
  slideSideAnimationVariants,
} from '@npco/zeller-design-system'
import { Variants } from 'framer-motion'

interface TransferStateProps {
  when: boolean
  children: ReactNode | ReactNode[]
  animationVariants?: Variants
}

export const FieldCondition = ({
  when,
  children,
  animationVariants = slideSideAnimationVariants,
}: TransferStateProps) => (
  <Animation variants={animationVariants} shouldShow={when}>
    {children}
  </Animation>
)
