import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'

import { PERMISSIONS } from 'const/permissions'
import { ROOT } from 'const/routes'
import { withPermissionGuard } from 'services/withPermissionGuard/withPermissionGuard'
import { CardAdd } from 'components/Cards/Card'
import { page } from 'translations'

export const AddSiteButton = withPermissionGuard(PERMISSIONS.SITE.ADD_SITE)(
  () => {
    const navigate = useNavigate()
    const shortEntityId = useSelectedShortEntityUuid()

    return (
      <CardAdd
        label={page.settings.sites.createNewSite}
        onClick={() =>
          navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.CREATE_SITE.path)
        }
      />
    )
  }
)
