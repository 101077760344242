import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  showApiErrorToast,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'
import { AssignCustomerToSite } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/customer'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { USERS } from 'const/common'
import { getSelectedSite } from 'utils/settings'
import {
  AssignCustomerToSite as AssignCustomerToSiteType,
  AssignCustomerToSiteVariables,
} from 'types/gql-types/AssignCustomerToSite'
import { GetCustomerDetails_getCustomer as CustomerDetails } from 'types/gql-types/GetCustomerDetails'
import { GetSites_getSites_sites as SiteData } from 'types/gql-types/GetSites'
import { SiteCache } from 'types/site'
import { page } from 'translations'

interface Args {
  type?: string
  closeModal: () => void
  onSaveCallback?: (sites: SiteData[]) => void
}

type State = (CustomerDetails | SiteData)[]

export const useEntityModal = ({ type, onSaveCallback, closeModal }: Args) => {
  const [selectedEntities, setSelectedEntities] = useState<State>([])
  const [isSavePending, setIsSavePending] = useState(false)

  const isTypeUsers = type === USERS
  const hasNoSelectedEntities = selectedEntities.length === 0
  const isSaveButtonDisabled = hasNoSelectedEntities || isSavePending
  const entityUuid = useSelectedEntityUuid()

  const [assignCustomerToSite] = useMutation<
    AssignCustomerToSiteType,
    AssignCustomerToSiteVariables
  >(AssignCustomerToSite, {
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  const toggleEntity = (user: CustomerDetails | SiteData) => {
    const isSelectedEntity = selectedEntities.some(
      (selectedEntity: CustomerDetails | SiteData) =>
        selectedEntity.id === user.id
    )

    if (!isSelectedEntity) {
      setSelectedEntities([...selectedEntities, user])
    }

    if (isSelectedEntity) {
      const updatedSelectedUsers = selectedEntities.filter(
        (selectedEntity) => selectedEntity.id !== user.id
      )

      setSelectedEntities(updatedSelectedUsers)
    }

    return undefined
  }

  const onSave = async () => {
    if (isTypeUsers) {
      setIsSavePending(true)
      let isErrorOnSave = false

      await Promise.all(
        selectedEntities.map(async (entity: CustomerDetails | SiteData) => {
          const response = await assignCustomerToSite({
            variables: {
              entityUuid,
              customerUuid: entity.id,
              siteUuid: getSelectedSite(),
            },
          })

          if (response?.data?.assignCustomerToSite === false) {
            isErrorOnSave = true
          } else {
            const currentSiteDetails = rvSiteDetails()

            rvSiteDetails({
              ...currentSiteDetails,
              customers: [entity, ...(currentSiteDetails?.customers || [])],
            } as SiteCache)
          }
        })
      )

      if (isErrorOnSave) {
        showErrorToast(page.settings.users.errorAssignToSite)
      } else {
        showSuccessToast(page.settings.users.successAssignToSite)
      }
    }

    if (onSaveCallback !== undefined) {
      onSaveCallback(selectedEntities as SiteData[])
    }

    closeModal()
  }

  return {
    isSavePending,
    isTypeUsers,
    toggleEntity,
    setSelectedEntities,
    isSaveButtonDisabled,
    onSave,
  }
}
