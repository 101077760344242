import { ReactElement, ReactNode } from 'react'
import { PageTemplate } from '@npco/zeller-design-system'

import { conditionalClassName } from 'utils/conditionalClassName'

import { ListLoader } from './components/ListLoader/ListLoader'
import { StyledInfiniteScroll, StyledListWrapper } from './InfiniteList.styled'

export interface InfiniteListProps<DataType> {
  className?: string
  data: DataType[]
  dataLength?: number
  footer?: ReactElement | null
  hasMore?: boolean
  header?: ReactElement | null
  height?: number | string
  loader?: ReactElement | null
  children?: ReactNode
  onScrollEnd?: () => void
  renderItem: (data: DataType, index: number) => React.ReactNode
}

export const InfiniteList = <DataType,>({
  className,
  data,
  dataLength = data.length,
  footer = null,
  hasMore = false,
  header = null,
  height,
  loader = <ListLoader />,
  children,
  onScrollEnd,
  renderItem,
}: InfiniteListProps<DataType>) => {
  return (
    <StyledListWrapper className={conditionalClassName({ hasMore }, className)}>
      {/*
        NOTE: scrollable target is only called on mount meaning we must wait
        for the scrollableTarget to be set before mounting the component:
        https://github.com/ankeetmaini/react-infinite-scroll-component/blob/d5b4e5250669022db5217763afd22fb3995a505a/src/index.tsx#L78
      */}
      <StyledInfiniteScroll
        dataLength={dataLength}
        next={() => {
          onScrollEnd?.()
        }}
        hasMore={hasMore}
        height={height}
        loader={loader}
        scrollableTarget={PageTemplate.ScrollTargetId}
      >
        {children}
        {header}
        {data.map((element, index) => renderItem(element, index))}
        {footer}
      </StyledInfiniteScroll>
    </StyledListWrapper>
  )
}
