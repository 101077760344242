import styled from 'styled-components'

import { FiltersList as Filters } from 'components/FiltersList/FiltersList'
import { StyledFiltersWrapper } from 'components/FiltersList/FiltersList.styled'

export const FiltersList = styled(Filters)`
  ${StyledFiltersWrapper} {
    padding: 0px;
  }
`
