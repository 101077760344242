import { type MockedResponse } from '@apollo/client/testing'
import {
  type ResultOf,
  type TypedDocumentNode,
  type VariablesOf,
} from '@graphql-typed-document-node/core'
import { keys } from 'ramda'

import { GraphQLError } from 'graphql'
import { type ErrorType } from 'types/errors'

export const createProviderMocks = <
  TResponse,
  TVariables,
  TQuery = TypedDocumentNode<TResponse, TVariables>
>(
  query: TQuery,
  defaultVariables?: VariablesOf<TQuery>,
  defaultResponse?: ResultOf<TQuery>
): ProviderMocks<ResultOf<TQuery>, VariablesOf<TQuery>> => {
  function getCommonRequest(variables?: VariablesOf<TQuery>) {
    return {
      request: {
        query: query as TypedDocumentNode<
          ResultOf<TQuery>,
          VariablesOf<TQuery>
        >,
        variables: variables || defaultVariables,
      },
    }
  }

  return {
    success: (overridingVariables, overridingResponse) => [
      {
        ...getCommonRequest(overridingVariables),
        result: {
          data: overridingResponse || defaultResponse,
        },
      },
    ],
    failure: (overridingVariables, overridingResponse) => [
      {
        ...getCommonRequest(overridingVariables),
        result: {
          data:
            overridingResponse ||
            ({
              [keys(defaultResponse)[0]]: null,
            } as unknown as ResultOf<TQuery>),
        },
      },
    ],
    partialError: (overridingVariables, overridingResponse) => [
      {
        ...getCommonRequest(overridingVariables),
        result: {
          data: overridingResponse || defaultResponse,
          errors: [new GraphQLError('Error')],
        },
      },
    ],
    graphqlError: (overridingVariables, graphqlErrorResponse) => [
      {
        ...getCommonRequest(overridingVariables),
        result: {
          errors: [graphqlErrorResponse || new GraphQLError('Error')],
        },
      },
    ],
    networkError: (overridingVariables) => [
      {
        ...getCommonRequest(overridingVariables),
        error: new Error('network error'),
      },
    ],
  }
}

type ProviderMocks<TResponse, TVariables> = {
  success: (
    variables?: TVariables,
    mockResponse?: TResponse
  ) => MockedResponse<TResponse, TVariables>[]
  failure: (
    variables?: TVariables,
    mockResponse?: TResponse
  ) => MockedResponse<TResponse, TVariables>[]
  graphqlError: <T = unknown>(
    variables?: TVariables,
    mockResponse?: GraphQLError & { errorType: ErrorType; errorInfo?: T }
  ) => MockedResponse<TResponse, TVariables>[]
  networkError: (
    variables?: TVariables
  ) => MockedResponse<TResponse, TVariables>[]
  partialError: (
    variables?: TVariables,
    mockResponse?: TResponse
  ) => MockedResponse<TResponse, TVariables>[]
}
