import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { EditSpendControlsLocationStateSchema } from '../../EditSpendControls.types'

export const useEditSpendControlsState = () => {
  const locationState = useLocationState(EditSpendControlsLocationStateSchema)

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.EditSpendControlsModal
  }, [locationState])

  return { state }
}
