import { useCallback } from 'react'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useUpdateCardName } from 'hooks/banking'
import { useGoToEditCardNameStage } from 'hooks/useGoToEditCardNameStage/useGoToEditCardNameStage'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'

import { useEditCardNameState } from './useEditCardNameState'

export const useEditCardName = () => {
  const { state } = useEditCardNameState()
  const { goToEditCardNameStage } = useGoToEditCardNameStage()
  const { updateCardName, isUpdatingCardName } = useUpdateCardName()

  const closeEditCardNameModal = useCallback(
    () => goToEditCardNameStage(undefined),
    [goToEditCardNameStage]
  )

  const handleSubmit = useCallback(
    async (name: string) => {
      const handleError = () => {
        showErrorToast()
      }

      const cardId = state?.cardId

      if (!cardId) {
        handleError()
        return
      }

      const result = await updateCardName({ name, cardId })

      if (result === UNEXPECTED_ERROR) {
        handleError()
        return
      }

      showSuccessToast(translate('page.cardActionSuccessMessage.editName'))
      closeEditCardNameModal()
    },
    [closeEditCardNameModal, updateCardName, state?.cardId]
  )

  return {
    handleSubmit,
    closeEditCardNameModal,
    isUpdatingCardName,
    initialCardName: state?.name || '',
  }
}
