import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { useInvoiceSettingsAnalytics } from '../../hooks/useInvoiceSettingsAnalytics'
import { INVOICE_CUSTOMISATION_FORM_FIELDS } from '../SettingsCustomisation.types'

export const useSettingsCustomisationFormHandlers = ({
  termsAndConditionsErrorMessage,
}: {
  termsAndConditionsErrorMessage: string
}) => {
  const {
    trackCustomisationSettingsAccentColourUpdated,
    trackCustomisationSettingsLogosUpdated,
  } = useInvoiceSettingsAnalytics()

  const { setFieldError, setFieldValue, setFieldTouched } = useFormikContext()

  const handleTermsAndConditionsLimit = useCallback(
    (isLimited: boolean) => {
      if (isLimited) {
        setFieldTouched(INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions)
        setFieldError(
          INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions,
          termsAndConditionsErrorMessage
        )
      } else {
        setFieldError(
          INVOICE_CUSTOMISATION_FORM_FIELDS.termsAndConditions,
          undefined
        )
      }
    },
    [setFieldError, setFieldTouched, termsAndConditionsErrorMessage]
  )

  const handleSelectLogo = useCallback(
    (logo: string) => {
      setFieldValue(INVOICE_CUSTOMISATION_FORM_FIELDS.selectedLogo, logo)
    },
    [setFieldValue]
  )

  const handleRemoveLogo = useCallback(
    (logos: string[]) => {
      setFieldValue(INVOICE_CUSTOMISATION_FORM_FIELDS.logos, logos)
      trackCustomisationSettingsLogosUpdated('Removed')
    },
    [setFieldValue, trackCustomisationSettingsLogosUpdated]
  )

  const handleAddLogo = useCallback(
    (logos: string[]) => {
      setFieldValue(INVOICE_CUSTOMISATION_FORM_FIELDS.logos, logos)
      trackCustomisationSettingsLogosUpdated('Added')
    },
    [setFieldValue, trackCustomisationSettingsLogosUpdated]
  )

  const handleSelectAccentColour = useCallback(
    (colour: string) => {
      setFieldValue(INVOICE_CUSTOMISATION_FORM_FIELDS.selectedColor, colour)
    },
    [setFieldValue]
  )

  const handleAddAccentColour = useCallback(
    (colours: string[]) => {
      setFieldValue(INVOICE_CUSTOMISATION_FORM_FIELDS.accentColours, colours)
      trackCustomisationSettingsAccentColourUpdated('Added')
    },
    [setFieldValue, trackCustomisationSettingsAccentColourUpdated]
  )

  const handleRemoveAccentColour = useCallback(
    (colours: string[]) => {
      setFieldValue(INVOICE_CUSTOMISATION_FORM_FIELDS.accentColours, colours)
      trackCustomisationSettingsAccentColourUpdated('Removed')
    },
    [setFieldValue, trackCustomisationSettingsAccentColourUpdated]
  )

  return {
    handleAddAccentColour,
    handleAddLogo,
    handleRemoveAccentColour,
    handleRemoveLogo,
    handleSelectAccentColour,
    handleSelectLogo,
    handleTermsAndConditionsLimit,
  }
}
