import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type TransferInDetailsDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  status: Types.DebitCardAccountStatus
  accountDetails: {
    __typename?: 'BankAccountDetails'
    account: string | null
    bsb: string | null
    name: string | null
  } | null
}

export const TransferInDetailsDebitCardAccountV2FragmentDoc = gql`
  fragment TransferInDetailsDebitCardAccountV2Fragment on DebitCardAccountV2 {
    status
    accountDetails {
      account
      bsb
      name
    }
  }
` as unknown as TypedDocumentNode<
  TransferInDetailsDebitCardAccountV2Fragment,
  undefined
>
