import { useMemo } from 'react'
import { NetworkStatus } from '@apollo/client'

import {
  GetCashFlowContactsNetAmountsQueryVariables,
  useGetCashFlowContactsNetAmountsQuery,
} from './graphql/getCashflowContactsNetAmounts.generated'

interface UseCashflowContactsNetAmountsProps {
  skip?: boolean
  variables: GetCashFlowContactsNetAmountsQueryVariables
}

export const useCashflowContactsNetAmounts = ({
  skip = false,
  variables,
}: UseCashflowContactsNetAmountsProps) => {
  const { loading, data, error, refetch, networkStatus } =
    useGetCashFlowContactsNetAmountsQuery({
      skip,
      variables,
      fetchPolicy: 'cache-and-network',
    })

  const contactNetAmounts = useMemo(() => {
    return data?.getCashFlowContactsNetAmounts.contactNetAmounts ?? []
  }, [data?.getCashFlowContactsNetAmounts.contactNetAmounts])

  return {
    error,
    isLoading:
      (loading || networkStatus === NetworkStatus.refetch) &&
      contactNetAmounts.length === 0,
    contactNetAmounts,
    refetch,
  }
}
