import { gql } from '@apollo/client'
import { Avatar } from '@npco/ui-avatar'

import { useIconAvatar } from './hooks/useIconAvatar'
import { IconAvatarProps } from './IconAvatar.types'

export const IconAvatar = ({
  icon,
  badgeSize,
  defaultLetter = '',
  defaultBgColor,
  defaultUrl,
  allowAnimation = false,
  disabled = false,
  withCursorPointer = false,
}: IconAvatarProps) => {
  const avatarProps = useIconAvatar({
    icon,
    defaultLetter,
    defaultBgColor,
    defaultUrl,
    allowAnimation,
  })

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      color={avatarProps.bgColor}
      badgeSize={badgeSize}
      disabled={disabled}
      grayscale={disabled}
      withCursorPointer={withCursorPointer}
    />
  )
}

IconAvatar.fragments = {
  Icon: gql`
    fragment IconAvatarIconFragment on Icon {
      ...UseIconAvatarIconFragment
    }

    ${useIconAvatar.fragments.Icon}
  `,
}
