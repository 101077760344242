import { TickIcon, Typography } from '@npco/zeller-design-system'

import { StyledLi, StyledUl } from './SignUpSidebarItemList.styled'

interface SignUpSidebarItemListProps {
  items: string[]
}

const CheckMarkIcon = () => <TickIcon />

export const SignUpSidebarItemList = ({
  items,
}: SignUpSidebarItemListProps) => {
  return (
    <StyledUl>
      {items.map((item) => {
        return (
          <StyledLi key={item}>
            <CheckMarkIcon />
            <Typography variant="body-base">{item}</Typography>
          </StyledLi>
        )
      })}
    </StyledUl>
  )
}
