import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink, useModalState } from '@npco/zeller-design-system'

import { unlinkContactButtonTranslations } from './UnlinkContactButton.i18n'
import { UnlinkContactModal } from './UnlinkContactModal'

interface UnlinkContactButtonProps {
  unlinkContact: () => void
}

export const UnlinkContactButton = ({
  unlinkContact,
}: UnlinkContactButtonProps) => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  const t = useTranslations(unlinkContactButtonTranslations)

  const handleUnlinkContact = useCallback(() => {
    unlinkContact()
    closeModal()
  }, [unlinkContact, closeModal])

  return (
    <>
      <ButtonLink onClick={openModal}>{t('buttonLabel')}</ButtonLink>
      <UnlinkContactModal
        isOpen={isModalOpen}
        unlinkContact={handleUnlinkContact}
        onCancel={closeModal}
      />
    </>
  )
}
