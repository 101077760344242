import { gql } from '@apollo/client'

export const SelectBillingAccount = gql`
  mutation SelectBillingAccount(
    $entityUuid: ID!
    $input: SelectBillingAccountInput!
  ) {
    selectBillingAccount(entityUuid: $entityUuid, input: $input)
  }
`
