import {
  BodyDefaultTypography,
  BodySmallTypography,
  BREAKPOINT,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledAmountColumn = styled(Flex)<{ $showMobileStyle: boolean }>`
  grid-area: amount;
  flex-direction: row;
  width: auto;

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    `
    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      width: 30%;
    }
  `}
`

export const StyledAmountItem = styled.div<{ $showMobileStyle: boolean }>`
  padding-right: 0;
  width: 100%;

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    `
    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      grid-area: auto;
    }
  `}
`

export const StyledTransactionAmount = styled.p<{
  color: string
  $showMobileStyle: boolean
}>`
  text-align: right;
  margin: 0;
  white-space: nowrap;
  ${BodySmallTypography};

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    `
    @media (min-width: ${BREAKPOINT.XS}px) {
      ${BodyDefaultTypography};
    }
  `}

  color: ${({ color }) => color};
`
