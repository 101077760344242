import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { INPUT_SIZE, InputAdaptive, SvgIcon } from '@npco/zeller-design-system'
import { Box, Flex } from 'reflexbox'

import { ReactComponent as Clock } from 'assets/svg/clock.svg'

import { DateRangeValue } from '../DateRange.types'
import { translations } from './DateRangeInputs.i18n'
import { useDateRangeInputs } from './DateRangeInputs/useDateRangeInputs'

type DateRangeInputsProps = {
  range: DateRangeValue | undefined
  hoveredDay?: Date
  format?: 'date' | 'datetime'
  onRangeChange: (range: DateRangeValue | undefined) => void
}

export const DateRangeInputs = ({
  range,
  hoveredDay,
  format = 'datetime',
  onRangeChange,
}: DateRangeInputsProps) => {
  const t = useTranslations(translations)

  const {
    endInputRef,
    startInputValue,
    endInputValue,
    handleEndInputBlur,
    handleEndInputChange,
    handleEndInputKeyDown,
    handleStartInputBlur,
    handleStartInputChange,
    handleStartInputKeyDown,
  } = useDateRangeInputs({ range, hoveredDay, format, onRangeChange })

  const getIcon = useCallback(
    () =>
      format === 'datetime' ? (
        <SvgIcon withMarginRight>
          <Clock />
        </SvgIcon>
      ) : null,
    [format]
  )

  const startTranslation =
    format === 'datetime' ? t('startTime') : t('startDate')
  const endTranslation = format === 'datetime' ? t('endTime') : t('endDate')

  return (
    <Flex
      flexDirection={['column', 'row']}
      padding={['0 24px 16px', '16px 24px']}
      justifyContent="space-between"
    >
      <Box mr={[0, '16px']} width="100%">
        <InputAdaptive
          aria-label={startTranslation}
          label={startTranslation}
          name={startTranslation}
          onBlur={handleStartInputBlur}
          onChange={handleStartInputChange}
          onKeyDown={handleStartInputKeyDown}
          renderLeftControls={getIcon}
          size={INPUT_SIZE.SMALL}
          value={startInputValue}
        />
      </Box>
      <Box ml={[0, '32px']} mt={['8px', 0]} width="100%">
        <InputAdaptive
          aria-label={endTranslation}
          label={endTranslation}
          name={endTranslation}
          onBlur={handleEndInputBlur}
          onChange={handleEndInputChange}
          onKeyDown={handleEndInputKeyDown}
          ref={endInputRef}
          renderLeftControls={getIcon}
          size={INPUT_SIZE.SMALL}
          value={endInputValue}
        />
      </Box>
    </Flex>
  )
}
