import {
  rvLastSelectedDates,
  rvSelectedDates,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { whereEq } from 'ramda'

import {
  statusPickerInitialValue,
  terminalPickerInitialValue,
  typePickerInitialValue,
} from 'const/common'
import dayjs from 'utils/dayjs'
import { groupByKey } from 'utils/groupByKey'
import {
  GetOverview_getTransactionTotalsBigInt as ApiTotal,
  GetOverview_getTransactionTotalsBigInt_taxAmounts as ApiTaxAmount,
} from 'types/gql-types/GetOverview'
import {
  PickerItems,
  RangeModifierExt,
  TIME_FILTER_ENABLED_COMPONENTS,
} from 'types/picker'
import type { ChartData, ChartDataPoint } from 'components/Charts/Charts.types'

import type { TransactionTotal, TransactionTotalsSum } from './Reports.types'

const convertApiTaxAmounts = (apiTaxAmounts: ApiTaxAmount[]) =>
  apiTaxAmounts.map((apiTaxAmount) => ({
    name: apiTaxAmount.name,
    amount: parseInt(apiTaxAmount.amount, 10),
  }))

export const sumTransactionTotals = (transactionTotals: TransactionTotal[]) =>
  transactionTotals.reduce<TransactionTotalsSum>(
    (
      sums,
      {
        totalAmount,
        declinedAmount,
        countPurchases,
        countRefunds,
        purchaseAmount,
        surchargeAmount,
        tipAmount,
        taxAmounts,
        feeAmount,
        refundAmount,
      }
    ) => ({
      total: sums.total + totalAmount,
      declinedTotal: sums.declinedTotal + declinedAmount,
      salesCount: sums.salesCount + countPurchases,
      refundsCount: sums.refundsCount + countRefunds,
      collected: sums.collected + purchaseAmount,
      surcharge: sums.surcharge + surchargeAmount,
      fees: sums.fees + feeAmount,
      tip: sums.tip + tipAmount,
      tax: (taxAmounts || []).reduce(
        (taxSums, { name, amount }) => ({
          ...taxSums,
          [name]: (taxSums[name] ?? 0) + amount,
        }),
        sums.tax
      ),
      refunds: sums.refunds + refundAmount,
    }),
    {
      total: 0,
      declinedTotal: 0,
      salesCount: 0,
      refundsCount: 0,
      collected: 0,
      surcharge: 0,
      fees: 0,
      tip: 0,
      tax: {},
      refunds: 0,
    }
  )

const getThisMonthPeriod = () => {
  return {
    from: dayjs().startOf('month').startOf('day').toDate(),
    to: dayjs().endOf('day').toDate(),
    enteredTo: dayjs().startOf('day').toDate(),
  }
}

export const getLastThirtyDays = () => {
  return {
    from: dayjs().subtract(30, 'days').startOf('day').toDate(),
    to: dayjs().endOf('day').toDate(),
    enteredTo: dayjs().startOf('day').toDate(),
  }
}

export const setLastSelectedOverviewDates = () =>
  rvLastSelectedDates({
    ...rvLastSelectedDates(),
    [TIME_FILTER_ENABLED_COMPONENTS.OVERVIEW]: rvSelectedDates(),
  })

export const getInitialPeriod = () => {
  const lastSelectedDate =
    rvLastSelectedDates()[TIME_FILTER_ENABLED_COMPONENTS.OVERVIEW]

  if (lastSelectedDate?.from) {
    return {
      from: dayjs(lastSelectedDate.from).toDate(),
      to: dayjs(lastSelectedDate.to).toDate(),
      enteredTo: dayjs(lastSelectedDate.from).toDate(),
    }
  }

  return getLastThirtyDays()
}

const getGroupedByMonthKey = (transactionTotal: TransactionTotal) => {
  return String(+dayjs(transactionTotal.period).startOf('month').startOf('day'))
}

const getGroupedByDayKey = (transactionTotal: TransactionTotal) => {
  return String(+dayjs(transactionTotal.period).startOf('day'))
}

// This function maps the data range to the values that are returned from the backend
// so that the data that is being passed to the chart has no holes, so that it can be
// displayed properly
export const fillMissingData = (
  data: ChartData,
  from: Date,
  to: Date,
  dateOffsetUnit: 'day' | 'month'
): ChartData => {
  const fromDayjsDate = dayjs(from)
  const toDayjsDate = dayjs(to)

  const differenceBetweenDates =
    toDayjsDate.diff(fromDayjsDate, dateOffsetUnit) + 1

  let currentDataIndex = 0

  const chartData = Array.from({ length: differenceBetweenDates }).map(
    (_, dateOffsetIncrement) => {
      const currentDataPointDateInMs = +fromDayjsDate
        .add(dateOffsetIncrement, dateOffsetUnit)
        .startOf(dateOffsetUnit)

      const dataPoint: ChartDataPoint | undefined = data[currentDataIndex]

      if (dataPoint && +dayjs(dataPoint.name) === currentDataPointDateInMs) {
        currentDataIndex += 1

        return dataPoint
      }

      return {
        name: currentDataPointDateInMs,
        value: 0,
      }
    }
  )

  return chartData
}

export const getTotalSaleChartData = (
  transactionTotals: TransactionTotal[],
  isSelectionMoreThenTwoMonths: boolean,
  isDeclined = false
): ChartData => {
  const groupByFunction = isSelectionMoreThenTwoMonths
    ? getGroupedByMonthKey
    : getGroupedByDayKey

  const groupedTransactions = groupByKey(groupByFunction)(transactionTotals)
  const groupKeys = Object.keys(groupedTransactions)

  return groupKeys.map((month) => {
    const total = isDeclined
      ? sumTransactionTotals(groupedTransactions[month]).declinedTotal
      : sumTransactionTotals(groupedTransactions[month]).total

    return {
      name: Number.parseInt(month, 10),
      value: total,
    }
  })
}

export const getTimeOfDayChartData = (
  transactionTotals: TransactionTotal[],
  isDeclined = false
) => {
  const groupByFunction = (transaction: TransactionTotal) =>
    String(dayjs(transaction.period).hour())

  const groupedTransactions = groupByKey(groupByFunction)(transactionTotals)
  const hours = Array.from({ length: 24 }).map((_, index) => String(index + 1))

  return hours.map((hour) => {
    const transactions = groupedTransactions[hour]
    let total = 0
    if (transactions) {
      total = isDeclined
        ? sumTransactionTotals(transactions).declinedTotal
        : sumTransactionTotals(transactions).total
    }
    return {
      name: Number.parseInt(hour, 10),
      value: total,
    }
  })
}

export const getDayOfWeekChartData = (
  transactionTotals: TransactionTotal[],
  isDeclined = false
) => {
  const groupByFunction = (transaction: TransactionTotal) =>
    String(dayjs(transaction.period).day())

  const groupedTransactions = groupByKey(groupByFunction)(transactionTotals)
  // We are offsetting the day by one as in dayjs Sunday = 0 and Monday = 1
  const days = Array.from({ length: 7 }).map((_, index) =>
    String((index + 1) % 7)
  )

  return days.map((day) => {
    const transactions = groupedTransactions[day]
    let total = 0
    if (transactions) {
      total = isDeclined
        ? sumTransactionTotals(groupedTransactions[day]).declinedTotal
        : sumTransactionTotals(groupedTransactions[day]).total
    }

    return {
      name: Number.parseInt(day, 10),
      value: total,
    }
  })
}

export const checkIfFiltersNotSelected = (filters: {
  dates: RangeModifierExt
  statuses: PickerItems
  terminals: PickerItems
  types: PickerItems
}) => {
  const defaultFiltersState = {
    dates: getThisMonthPeriod(),
    statuses: statusPickerInitialValue,
    terminals: terminalPickerInitialValue,
    types: typePickerInitialValue,
  }

  return whereEq(defaultFiltersState)(filters)
}

const getPlaceholderTransactionDay = (
  offset: number,
  dailyAmount: number
): TransactionTotal[] => {
  const hoursAmount = [
    0, 0, 0, 0, 0, 0.2, 0.5, 0.625, 0.75, 0.625, 0.4375, 0.375, 0.8125, 1,
    0.875, 0.625, 0.75, 0.625, 0.2, 0.125, 0, 0, 0, 0,
  ]

  return hoursAmount.map((hourAmount, hour) => ({
    period: dayjs().subtract(offset, 'day').hour(hour).toISOString(),
    totalAmount: dailyAmount * hourAmount,
    countPurchases: 0,
    countRefunds: 0,
    purchaseAmount: 0,
    surchargeAmount: 0,
    tipAmount: 0,
    feeAmount: 0,
    taxAmounts: [],
    refundAmount: 0,
    declinedAmount: 0,
  }))
}

export const getPlaceholderTransactions = (): TransactionTotal[] => [
  ...getPlaceholderTransactionDay(30, 200000),
  ...getPlaceholderTransactionDay(25, 220000),
  ...getPlaceholderTransactionDay(20, 120000),
  ...getPlaceholderTransactionDay(15, 250000),
  ...getPlaceholderTransactionDay(10, 160000),
  ...getPlaceholderTransactionDay(5, 280000),
  ...getPlaceholderTransactionDay(0, 200000),
]

export const getPlaceholderBarTransactions = (): TransactionTotal[] => [
  ...getPlaceholderTransactionDay(30, 200000),
  ...getPlaceholderTransactionDay(29, 204000),
  ...getPlaceholderTransactionDay(28, 208000),
  ...getPlaceholderTransactionDay(27, 212000),
  ...getPlaceholderTransactionDay(26, 216000),
  ...getPlaceholderTransactionDay(25, 220000),
  ...getPlaceholderTransactionDay(24, 200000),
  ...getPlaceholderTransactionDay(23, 180000),
  ...getPlaceholderTransactionDay(22, 160000),
  ...getPlaceholderTransactionDay(21, 140000),
  ...getPlaceholderTransactionDay(20, 120000),
  ...getPlaceholderTransactionDay(19, 146000),
  ...getPlaceholderTransactionDay(18, 172000),
  ...getPlaceholderTransactionDay(17, 198000),
  ...getPlaceholderTransactionDay(16, 224000),
  ...getPlaceholderTransactionDay(15, 250000),
  ...getPlaceholderTransactionDay(14, 232000),
  ...getPlaceholderTransactionDay(13, 214000),
  ...getPlaceholderTransactionDay(12, 196000),
  ...getPlaceholderTransactionDay(11, 178000),
  ...getPlaceholderTransactionDay(10, 160000),
  ...getPlaceholderTransactionDay(9, 184000),
  ...getPlaceholderTransactionDay(8, 208000),
  ...getPlaceholderTransactionDay(7, 232000),
  ...getPlaceholderTransactionDay(6, 256000),
  ...getPlaceholderTransactionDay(5, 280000),
  ...getPlaceholderTransactionDay(4, 264000),
  ...getPlaceholderTransactionDay(3, 248000),
  ...getPlaceholderTransactionDay(2, 232000),
  ...getPlaceholderTransactionDay(1, 216000),
  ...getPlaceholderTransactionDay(0, 200000),
]

export const convertFromApiTotals = (apiTotals: ApiTotal[]) =>
  apiTotals.map((apiTotal) => ({
    period: apiTotal.period,
    totalAmount: parseInt(apiTotal.totalAmount, 10),
    countPurchases: parseInt(apiTotal.countPurchases, 10),
    countRefunds: parseInt(apiTotal.countRefunds, 10),
    purchaseAmount: parseInt(apiTotal.purchaseAmount, 10),
    surchargeAmount: parseInt(apiTotal.surchargeAmount, 10),
    feeAmount: parseInt(apiTotal.feeAmount, 10),
    tipAmount: parseInt(apiTotal.tipAmount, 10),
    taxAmounts: convertApiTaxAmounts(apiTotal.taxAmounts || []),
    refundAmount: parseInt(apiTotal.refundAmount, 10),
    declinedAmount: parseInt(apiTotal.declinedAmount || '0', 10),
  }))
