import SimCardImage from 'assets/images/sim-card-white.png'
import { page } from 'translations'

import { StyledImage, StyledSimCardNumber } from './Header.styled'

type SimCardDrawerHeaderProps = {
  isActive?: boolean
  simId: string
}

export const Header = ({ simId, isActive }: SimCardDrawerHeaderProps) => {
  return (
    <>
      <StyledImage
        src={SimCardImage}
        alt={page.settings.sim.altText}
        isActive={isActive}
      />
      <StyledSimCardNumber>
        {page.settings.sim.details.sim} {simId}
      </StyledSimCardNumber>
    </>
  )
}
