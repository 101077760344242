import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment = {
  __typename?: 'CashFlowCategoryNetAmount'
  category: Types.EntityCategories | null
  total: { __typename?: 'Money'; value: string }
}

export const UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragmentDoc =
  gql`
    fragment UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      category
      total {
        value
      }
    }
  ` as unknown as TypedDocumentNode<
    UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment,
    undefined
  >
