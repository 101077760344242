import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  CancelInvoice as CancelInvoiceResponse,
  CancelInvoiceVariables,
} from 'types/gql-types/CancelInvoice'

import { CancelInvoice } from '../../graphql/cancelInvoice'

export const useCancelInvoice = () => {
  const entityUuid = useSelectedEntityUuid()
  const [cancelInvoice, { loading: isCancellingInvoice }] = useMutation<
    CancelInvoiceResponse,
    CancelInvoiceVariables
  >(CancelInvoice)

  return {
    cancelInvoice: (referenceNumber: string) =>
      cancelInvoice({
        variables: {
          entityUuid,
          referenceNumber,
        },
      }),
    isCancellingInvoice,
  }
}
