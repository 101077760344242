import { makeVar } from '@apollo/client'

import {
  BankAccount,
  DebitCardAccount,
  ThirdPartyBankAccount,
} from 'types/accounts'
import { Account } from 'types/settings'

export const rvSimBillingAccount = makeVar<
  ThirdPartyBankAccount | DebitCardAccount | null
>(null)

export const rvDetailedSimBillingAccount = makeVar<BankAccount>(null)

export const rvDetailedSimBillingAccountType = makeVar<Account>(Account.ZELLER)
