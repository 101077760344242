import { useCallback, useMemo } from 'react'

import { TransactionTotal } from 'pages/Dashboard/Dashboard.types'
import {
  getEndOfCurrentDay,
  getStartOfCurrentDay,
  getStartOfMonth,
} from 'pages/Dashboard/utils/timeUtils'
import {
  countSales,
  getTotalsBetween,
  sumTotalSales,
} from 'pages/Dashboard/utils/transactionTotalsUtils'

interface UseSalesSummariesOptions {
  salesTotals: TransactionTotal[]
}

export const useSalesSummaries = ({
  salesTotals,
}: UseSalesSummariesOptions) => {
  const sumSalesBetween = useCallback(
    (startDate, endDate) => {
      const salesTotalsBetweenDates = getTotalsBetween(
        salesTotals,
        startDate,
        endDate
      )

      return sumTotalSales(salesTotalsBetweenDates)
    },
    [salesTotals]
  )

  const sumVolumeBetween = useCallback(
    (startDate, endDate) => {
      const salesTotalsBetweenDates = getTotalsBetween(
        salesTotals,
        startDate,
        endDate
      )

      return countSales(salesTotalsBetweenDates)
    },
    [salesTotals]
  )

  const monthToDateSales = useMemo(() => {
    const startOfMonth = getStartOfMonth()
    const endOfDay = getEndOfCurrentDay()

    return sumSalesBetween(startOfMonth, endOfDay)
  }, [sumSalesBetween])

  const monthToDateVolume = useMemo(() => {
    const startOfMonth = getStartOfMonth()
    const endOfDay = getEndOfCurrentDay()

    return sumVolumeBetween(startOfMonth, endOfDay)
  }, [sumVolumeBetween])

  const todaySales = useMemo(() => {
    const startOfDay = getStartOfCurrentDay()
    const endOfDay = getEndOfCurrentDay()

    return sumSalesBetween(startOfDay, endOfDay)
  }, [sumSalesBetween])

  const todayVolume = useMemo(() => {
    const startOfDay = getStartOfCurrentDay()
    const endOfDay = getEndOfCurrentDay()

    return sumVolumeBetween(startOfDay, endOfDay)
  }, [sumVolumeBetween])

  return { monthToDateSales, monthToDateVolume, todaySales, todayVolume }
}
