import { FetchResult } from '@apollo/client'

import { LinkSubcategoryToContact as LinkSubcategoryToContactResponse } from 'types/gql-types/LinkSubcategoryToContact'
import { UpdateCategory as UpdateCategoryResponse } from 'types/gql-types/UpdateCategory'

export const getHasAllSucceeded = (
  settledPromises: [
    PromiseSettledResult<FetchResult>,
    PromiseSettledResult<FetchResult> | undefined
  ]
): settledPromises is [
  PromiseFulfilledResult<FetchResult<UpdateCategoryResponse>>,
  (
    | PromiseFulfilledResult<FetchResult<LinkSubcategoryToContactResponse>>
    | undefined
  )
] =>
  !settledPromises.some(
    (settledPromise) =>
      settledPromise?.status === 'rejected' || settledPromise?.value.errors
  )
