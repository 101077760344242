import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { GetCustomers } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'

import { GetCustomers as GetCustomersResponse } from 'types/gql-types/GetCustomers'

export const useFetchUsers = () => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, data } = useQuery<GetCustomersResponse>(GetCustomers, {
    onError: (error) => {
      showApiErrorToast(error)
    },
    variables: {
      entityUuid,
    },
    errorPolicy: 'all',
  })

  return {
    loading,
    customers: data?.getCustomers,
  }
}
