import { Flex, H5Style } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const MaskedPanText = styled.p`
  ${H5Style}
`

export const ViewDetailsButton = styled(Flex)<{
  isLoading?: boolean
}>`
  outline: none;
  cursor: ${({ isLoading }) => (isLoading ? 'auto' : 'pointer')};
  min-width: 48px;
  transition: width 0.5s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
`
