import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { CacheLocalStateEntityScopedVariable } from '../../graphql/cache.types'
import { GetOraclePosLocalState } from './graphql/getOraclePosLocalState'
import {
  CacheLocalStateOraclePos,
  LocalStateOraclePos,
} from './oraclePos.types'

export const useOraclePosLocalCache = () => {
  const { cache } = useApolloClient()
  const entityUuid = useSelectedEntityUuid()
  const updateCache = useCallback(
    async ({
      isOverridePairingModalOpen,
      currentDeviceUuid,
      currentDeviceName,
      newDeviceUuid,
      newDeviceName,
      workstationId,
    }: LocalStateOraclePos) =>
      cache.updateQuery<
        CacheLocalStateOraclePos,
        CacheLocalStateEntityScopedVariable
      >(
        {
          query: GetOraclePosLocalState,
          variables: { entityUuid },
        },
        () => ({
          local: {
            oraclePos: {
              isOverridePairingModalOpen,
              currentDeviceUuid,
              currentDeviceName,
              newDeviceUuid,
              newDeviceName,
              workstationId,
            },
          },
        })
      ),
    [cache, entityUuid]
  )

  return { updateCache }
}
