import { Heading, SvgIcon } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

interface StatusValueProps {
  color: string
}

export const TransactionDetails = styled.div`
  width: calc(100vw - 32px);
  height: 100%;
  padding: 0 4px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 100%;
  }

  h3,
  h6 {
    margin: 0;
  }
`

export const StatusValue = styled.div<StatusValueProps>`
  color: ${({ color }) => color};
`

interface TransactionAmountProps {
  isDeclined: boolean
}

export const TransactionAmount = styled(Heading.H3)<TransactionAmountProps>`
  color: ${({ theme, isDeclined }) =>
    isDeclined ? theme.colors.RED_1000 : theme.colors.GREEN_1600};
`

export const IssuerIcon = styled(SvgIcon)`
  position: absolute;
  right: -6px;
  top: -4px;
`
