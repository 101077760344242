import { getIsAbnValid } from 'utils/abnHelper'
import { getIsAcnValid } from 'utils/acnHelper'
import { removeWhiteSpaces } from 'utils/common'

export const parseToAbnOrAcnData = (value?: string | null) => {
  if (!value) {
    return {
      abn: undefined,
      acn: undefined,
    }
  }

  const preparedValue = removeWhiteSpaces(value)

  return {
    abn: getIsAbnValid(preparedValue) ? value : undefined,
    acn: getIsAcnValid(preparedValue) ? value : undefined,
  }
}
