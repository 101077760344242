import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  PillNonInteractive,
  PillSize,
  SvgIcon,
  Typography,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ReactComponent as ExpenseCardIcon } from 'assets/svg/cards/corporate-card-front.svg'
import { CardTypeOption } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { StyledRadioCard } from 'pages/GlobalModals/GlobalModals.styled'

import { selectCardTypeFormTranslations } from '../SelectCardTypeForm.i18n'
import { ExpenseCardDescription } from './ExpenseCardDescription'

const getExpenseCardIcon = () => (
  <SvgIcon width="40" height="25">
    <ExpenseCardIcon />
  </SvgIcon>
)

const BadgeContainer = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
`

interface ExpenseCardRadioOptionProps {
  onChange: () => void
  isChecked: boolean
  name: string
}

export const ExpenseCardRadioOption = ({
  onChange,
  isChecked,
  name,
}: ExpenseCardRadioOptionProps) => {
  const t = useTranslations(selectCardTypeFormTranslations)

  return (
    <StyledRadioCard
      id={CardTypeOption.ExpenseCard}
      value={CardTypeOption.ExpenseCard}
      title={t('expenseCardOptionTitle')}
      desc={<ExpenseCardDescription />}
      icon={getExpenseCardIcon}
      onChange={onChange}
      checked={isChecked}
      name={name}
      contextContent={
        <BadgeContainer>
          <PillNonInteractive
            backgroundColor={COLOR.GREEN_80}
            size={PillSize.Small}
          >
            <Typography
              variant="heading-sm"
              component="div"
              color={COLOR.GREEN_1600}
            >
              {t('expenseCardCost')}
            </Typography>
          </PillNonInteractive>
        </BadgeContainer>
      }
      isRadioIconVisible
    />
  )
}
