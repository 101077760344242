import { useCallback, useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  DotBasic,
  Flex,
  INPUT_SIZE,
  InputAdaptiveField,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { useInputHandlers } from 'hooks/useInputHandlers'

import { translations } from './EditVenue.i18n'
import { type EditVenueFormType } from './EditVenue.types'
import * as styled from './Field.styled'

export type FieldProps = {
  name: string
  category: 'venue' | 'location'
  categoryId: string
  children?: React.ReactNode
  siteIdMap?: Record<string, string>
}

export const Field = ({
  name,
  category,
  categoryId,
  children,
  siteIdMap,
}: FieldProps) => {
  const t = useTranslations(translations)
  const hasSitesSelected = Object.values(siteIdMap ?? {}).includes(categoryId)
  const { handleIntegerInputKeydown } = useInputHandlers()

  const { values, errors, setFieldError } =
    useFormikContext<EditVenueFormType>()

  const stationIdFieldName =
    category === 'venue'
      ? 'venueStationId'
      : `locations[${categoryId}].stationId`

  const isStationIdEnabled =
    category === 'venue'
      ? values.venueStationIdToggle
      : values.locationStationIdToggle

  const value =
    category === 'venue'
      ? values.venueStationId
      : values.locations[categoryId].stationId

  const error =
    category === 'venue'
      ? errors.venueStationId
      : errors.locations?.[categoryId]?.stationId

  const validateStationID = useCallback(
    (value: string, hasSitesSelected: boolean): string | undefined => {
      if (!hasSitesSelected && value) {
        return t('stationIdRemoveMsg')
      }

      if (hasSitesSelected && !value) {
        return t('stationIdRequiredMsg')
      }

      return undefined
    },
    [t]
  )

  useEffect(() => {
    if (error) {
      setFieldError(
        stationIdFieldName,
        validateStationID(value, hasSitesSelected)
      )
    }
  }, [
    error,
    hasSitesSelected,
    setFieldError,
    stationIdFieldName,
    validateStationID,
    value,
  ])

  return (
    <Flex flexDirection="column" gap="12px">
      <Flex flexDirection="row" gap="4px" alignItems="center">
        <styled.LabelName>{name}</styled.LabelName>
        <DotBasic />
        <styled.LabelCategory>
          {category === 'venue' ? t('venue') : t('location')}
        </styled.LabelCategory>
      </Flex>
      {children}
      {isStationIdEnabled && (
        <Box>
          <InputAdaptiveField
            name={stationIdFieldName}
            onKeyDown={handleIntegerInputKeydown}
            type="number"
            label={t('stationId')}
            size={INPUT_SIZE.MEDIUM}
            validate={() => validateStationID(value, hasSitesSelected)}
          />
        </Box>
      )}
    </Flex>
  )
}
