import { Navigate } from 'react-router-dom-v5-compat'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { EmptyClosed } from 'features/Accounts/AccountCards/AccountCards.EmptyClosed'
import { useOnAddCard } from 'features/Cards/feature-cards'
import { Flex } from 'reflexbox'

import { useAccountId, useSubscribeToAccounts } from 'hooks/banking'
import { useSubscribeToCardUpdate } from 'hooks/useSubscribeToCardUpdate/useSubscribeToCardUpdate'
import { CardsView } from 'components/CardsView'
import { ActiveCards } from 'components/CardsView/ActiveCards'
import { ClosedCards } from 'components/CardsView/ClosedCards'
import { AccountCardsEmpty } from 'components/CardsView/components/CardsEmpty/AccountCardsEmpty/AccountCardsEmpty'
import { AccountCardsTable } from 'components/CardsView/components/CardsTable/AccountCardsTable/AccountCardsTable'
import { AccountCardsTableDebitCardV2Fragment } from 'components/CardsView/components/CardsTable/AccountCardsTable/AccountCardsTable.generated'
import { useFilterCardStatus } from 'components/CardsView/hooks/useFilterCardStatus'
import { PendingCards } from 'components/CardsView/PendingCards'

import { useAccountCards } from './hooks/useAccountCards/useAccountCards'

export const AccountCards = () => {
  useSubscribeToAccounts()
  const { accountId } = useAccountId()

  const { isLoading, account, entity } = useAccountCards()

  const { onAddCard } = useOnAddCard(entity)
  const { userData } = useLoggedInUser()

  useSubscribeToCardUpdate()

  const shortEntityId = useSelectedShortEntityUuid()

  const cards = account?.cards ?? []

  const filteredCards =
    useFilterCardStatus<AccountCardsTableDebitCardV2Fragment>(cards)

  if (account?.type === DebitCardAccountType.SAVINGS) {
    return (
      <Navigate
        to={ACCOUNT(shortEntityId, accountId).TRANSACTIONS.path}
        replace
      />
    )
  }

  const cardListLength = cards?.length
  const hasCards = cardListLength > 0
  const hasActiveCards = filteredCards.active.length > 0
  const hasOwnedPendingCards = filteredCards.pending.some(
    (card: AccountCardsTableDebitCardV2Fragment) =>
      card.customer?.id === userData?.id
  )
  const hasClosedCards = filteredCards.closed.length > 0

  if (hasCards && account?.status === DebitCardAccountStatus.CLOSED) {
    return (
      <Flex mt="40px">
        <AccountCardsTable cards={filteredCards.active} isLoading={isLoading} />
      </Flex>
    )
  }

  if (!hasCards && account?.status === DebitCardAccountStatus.CLOSED) {
    return <EmptyClosed />
  }

  return (
    <CardsView
      hasCards={hasCards}
      isLoading={isLoading}
      emptyView={<AccountCardsEmpty onAddCard={onAddCard} />}
    >
      <ActiveCards
        onAddCard={onAddCard}
        hasActiveCards={hasActiveCards}
        hasOwnedPendingCards={hasOwnedPendingCards}
        hasClosedCards={hasClosedCards}
        isLoading={isLoading}
      >
        <AccountCardsTable cards={filteredCards.active} isLoading={isLoading} />
      </ActiveCards>
      <PendingCards numberOfPendingCards={filteredCards.pending.length}>
        <AccountCardsTable
          cards={filteredCards.pending}
          isLoading={isLoading}
          isReadonly
        />
      </PendingCards>
      <ClosedCards numberOfClosedCards={filteredCards.closed.length}>
        <AccountCardsTable cards={filteredCards.closed} isLoading={isLoading} />
      </ClosedCards>
    </CardsView>
  )
}
