import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowPosLiteLarge from 'assets/images/setup-flow/setup-flow-pos-lite-large.png'
import SetupFlowPosLiteMedium from 'assets/images/setup-flow/setup-flow-pos-lite-medium.png'
import SetupFlowPosLiteSmall from 'assets/images/setup-flow/setup-flow-pos-lite-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { MainContent } from './MainContent/MainContent'

export const FeaturePosLiteStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const { goToStage } = useSetupFlowModal()

  return (
    <Tutorial.TutorialLayout
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage('FeatureItemsStage')}
            onSecondaryButtonClick={() =>
              goToStage('FeatureVirtualTerminalStage')
            }
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowPosLiteSmall}
          imageMedium={SetupFlowPosLiteMedium}
          imageLarge={SetupFlowPosLiteLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
