import { H4 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledHeading = styled(H4)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-top: 0;
`
