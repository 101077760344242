import { useCallback } from 'react'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { useCancelCard } from 'hooks/banking'
import { useGoToCancelCardStage } from 'hooks/useGoToCancelCardStage/useGoToCancelCardStage'
import { translate } from 'utils/translations'
import { UNEXPECTED_ERROR } from 'types/errors'
import { useCancelCardState } from 'pages/GlobalModals/CancelCardModal/hooks/useCancelCardState'

export const useCancelCardForm = () => {
  const { state } = useCancelCardState()
  const { goToCancelCardStage } = useGoToCancelCardStage()
  const { cancelCard, isCancellingCard } = useCancelCard()

  const closeCancelCardModal = useCallback(
    () => goToCancelCardStage(undefined),
    [goToCancelCardStage]
  )

  const handleSubmit = useCallback(async () => {
    const handleError = () => {
      showErrorToast()
    }

    const cardId = state?.cardId

    if (!cardId) {
      handleError()
      return
    }

    const result = await cancelCard(cardId)

    if (result === UNEXPECTED_ERROR) {
      handleError()
      return
    }

    showSuccessToast(translate('page.cardActionSuccessMessage.cancel'))
    closeCancelCardModal()
  }, [closeCancelCardModal, cancelCard, state?.cardId])

  return { handleSubmit, closeCancelCardModal, isCancellingCard }
}
