import { useEffect, useMemo } from 'react'
import { DebitCardTransactionFilterInputV2 } from '@npco/mp-gql-types'
import {
  rvOptimisticDebitCardTransactions,
  rvUpdatedDebitCardTransactions,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useAccountsTransactionsMetadata } from 'features/AccountsTransactions'
import { isEmpty } from 'lodash-es'

import { useDebitCardAccountTransactions } from '../useDebitCardAccountTransactions'

interface UseDebitCardTransactionsProps {
  filters: DebitCardTransactionFilterInputV2 | null
  areFiltersInDefaultState: boolean
  limit?: number
  skip?: boolean
}

export const useDebitCardTransactions = ({
  filters,
  areFiltersInDefaultState,
  limit,
  skip,
}: UseDebitCardTransactionsProps) => {
  const { setIsLoading, setIsEmpty, setAreFiltersInDefaultState } =
    useAccountsTransactionsMetadata()

  const {
    groupedTransactions,
    loading,
    fetchMoreTransactions,
    hasMore,
    error,
    refetchTransactions,
    handleSelectedTransactionUpdate,
  } = useDebitCardAccountTransactions({
    filter: filters,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (!areFiltersInDefaultState) {
        rvOptimisticDebitCardTransactions([])
        rvUpdatedDebitCardTransactions([])
      }
    },
    areFiltersInDefaultState,
    limit,
    skip,
  })

  useEffect(() => {
    if (skip) {
      return
    }
    setIsLoading(loading)
    setIsEmpty(isEmpty(groupedTransactions))
    setAreFiltersInDefaultState(areFiltersInDefaultState)
  }, [
    skip,
    loading,
    groupedTransactions,
    areFiltersInDefaultState,
    setIsLoading,
    setIsEmpty,
    setAreFiltersInDefaultState,
  ])

  const hasNoInitialResults = useMemo(
    () => areFiltersInDefaultState && groupedTransactions.length === 0,
    [areFiltersInDefaultState, groupedTransactions.length]
  )

  return {
    groupedDebitCardTransactions: groupedTransactions,
    hasNoInitialResults,
    onLoadMore: fetchMoreTransactions,
    loading,
    hasMore,
    error,
    refetch: refetchTransactions,
    handleSelectedTransactionUpdate,
  }
}
