import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, COLOR, TooltipBasic } from '@npco/zeller-design-system'
import { useGoToEditDebitCardTransactionImages } from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-routing'
import { useGoToEditDebitCardTransactionNotes } from 'features/EditDebitCardTransactionNotes/edit-debit-card-transaction-notes-routing'

import { ReactComponent as PenIcon } from 'assets/svg/pen.svg'
import { ReactComponent as ReceiptIcon } from 'assets/svg/receipt.svg'

import { MAX_FILES } from '../DebitCardTransactions.utils'
import {
  ReceiptAndNoteCellDebitCardAccountTransactionFragment,
  ReceiptAndNoteCellDebitCardTransactionV2Fragment,
} from './ReceiptAndNoteCell.generated'
import { receiptAndNoteCellTranslations } from './ReceiptAndNoteCell.i18n'
import * as styled from './ReceiptAndNoteCell.styled'

interface ReceiptAndNoteCellProps {
  transaction:
    | ReceiptAndNoteCellDebitCardTransactionV2Fragment
    | ReceiptAndNoteCellDebitCardAccountTransactionFragment
  openImagePreview: () => void
  isEmptyReceiptHighlighted?: boolean
  isEmptyNoteHighlighted?: boolean
}

export const ReceiptAndNoteCell = ({
  transaction,
  openImagePreview,
  isEmptyReceiptHighlighted,
  isEmptyNoteHighlighted,
}: ReceiptAndNoteCellProps) => {
  const hasReceipt = (transaction.attachments ?? []).length !== 0
  const hasNotes = !!transaction.note

  const emptyReceiptIconColor = isEmptyReceiptHighlighted
    ? COLOR.RED_400
    : COLOR.GREY_150
  const emptyNoteIconColor = isEmptyNoteHighlighted
    ? COLOR.RED_400
    : COLOR.GREY_150

  const t = useTranslations(receiptAndNoteCellTranslations)

  const { goToEditDebitCardTransactionImages } =
    useGoToEditDebitCardTransactionImages()

  const openAddReceiptModal = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation()

      if (!hasReceipt) {
        goToEditDebitCardTransactionImages({
          stage: 'edit',
          debitCardTransactionUuid: transaction.id,
          maxFiles: MAX_FILES,
        })

        return
      }

      openImagePreview()
    },
    [
      goToEditDebitCardTransactionImages,
      hasReceipt,
      openImagePreview,
      transaction.id,
    ]
  )

  const { goToEditDebitCardTransactionNotes } =
    useGoToEditDebitCardTransactionNotes()

  const openEditNoteModal = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation()
      goToEditDebitCardTransactionNotes({
        stage: 'edit',
        debitCardTransactionUuid: transaction.id,
        note: transaction.note ?? '',
      })
    },
    [goToEditDebitCardTransactionNotes, transaction.id, transaction.note]
  )

  return (
    <styled.CellWrapper>
      <Box pl="2px" pr="18px">
        <TooltipBasic
          renderTrigger={({ ref, handlers }) => (
            <span ref={ref} {...handlers}>
              <styled.IconButton
                $color={hasReceipt ? COLOR.GREEN_1600 : emptyReceiptIconColor}
                aria-label={hasReceipt ? t('viewReceipt') : t('addReceipt')}
                type="button"
                onClick={openAddReceiptModal}
              >
                <ReceiptIcon />
              </styled.IconButton>
            </span>
          )}
          placement="top"
          showArrow={false}
        >
          {hasReceipt ? t('viewReceipt') : t('addReceipt')}
        </TooltipBasic>
      </Box>
      <Box pl="2px" pr="18px">
        <TooltipBasic
          renderTrigger={({ ref, handlers }) => (
            <span ref={ref} {...handlers}>
              <styled.IconButton
                $color={hasNotes ? COLOR.GREEN_1600 : emptyNoteIconColor}
                aria-label={t('notes')}
                type="button"
                onClick={openEditNoteModal}
              >
                <PenIcon />
              </styled.IconButton>
            </span>
          )}
          placement="top"
          showArrow={false}
        >
          {hasNotes ? t('editNote') : t('addNote')}
        </TooltipBasic>
      </Box>
    </styled.CellWrapper>
  )
}

ReceiptAndNoteCell.fragments = {
  DebitCardTransactionV2: gql`
    fragment ReceiptAndNoteCellDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      id
      attachments
      note
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment ReceiptAndNoteCellDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      id
      attachments
      note
    }
  `,
}
