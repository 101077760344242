import { useCallback } from 'react'
import {
  ButtonGhost,
  Divider,
  PopperItemOneLine,
  PopperItemOneLineSize,
} from '@npco/zeller-design-system'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'
import { translate } from 'utils/translations'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import {
  isAscendingSelected,
  isDescendingSelected,
} from '../../InvoiceTable.utils'
import * as styled from './AmountFilters.styled'
import { AmountFiltersContent } from './AmountFiltersContent'
import { useAmountFilters } from './useAmountFilters'

export interface AmountFiltersProps {
  setIsOpen?: (state: boolean) => void
  setShouldCloseOnOutsideClick?: (state: boolean) => void
}

export const AmountFilters = ({
  setIsOpen,
  setShouldCloseOnOutsideClick,
}: AmountFiltersProps) => {
  const {
    selectedAmount,
    selectedAmountColumn,
    setAmountFilter,
    setSelectedAmountColumn,
    setSortingObject,
    sortingObject,
  } = useInvoicesContext()

  const {
    inputMin,
    inputMax,
    sliderMin,
    sliderMax,
    onInputChange,
    onSliderChange,
    handleResetClick,
    onAfterChange,
  } = useAmountFilters({
    selectedAmount,
    selectedAmountColumn,
    setAmountFilter,
  })

  const handleClick = useCallback(
    (ascending: boolean) => () => {
      if (
        sortingObject?.ascending === ascending &&
        sortingObject?.columnName === selectedAmountColumn
      ) {
        setSortingObject(null)
      } else {
        setSortingObject({ columnName: selectedAmountColumn, ascending })
      }
      setIsOpen?.(false)
    },
    [selectedAmountColumn, setIsOpen, setSortingObject, sortingObject]
  )

  const handleClear = useCallback(() => {
    setAmountFilter(null)
    handleResetClick()
  }, [handleResetClick, setAmountFilter])

  return (
    <styled.AmountFiltersWrapper data-testid="amount-filters">
      <styled.SortingWrapper>
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            sortingObject,
            selectedAmountColumn
          )}
          icon={Tick}
          label={translate('page.invoiceTable.sorting.amountAscending')}
          onClick={handleClick(true)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            sortingObject,
            selectedAmountColumn
          )}
          icon={Tick}
          label={translate('page.invoiceTable.sorting.amountDescending')}
          onClick={handleClick(false)}
          size={PopperItemOneLineSize.Small}
        />
      </styled.SortingWrapper>
      <Divider />
      <AmountFiltersContent
        selectedAmountColumn={selectedAmountColumn}
        setSelectedAmountColumn={setSelectedAmountColumn}
        inputMin={inputMin}
        inputMax={inputMax}
        sliderMin={sliderMin}
        sliderMax={sliderMax}
        onInputChange={onInputChange}
        onSliderChange={onSliderChange}
        onAfterChange={onAfterChange}
        setShouldCloseOnOutsideClick={setShouldCloseOnOutsideClick}
      />
      <Divider />
      <styled.BottomRow>
        <ButtonGhost onClick={handleClear} disabled={false}>
          {translate('shared.clear')}
        </ButtonGhost>
      </styled.BottomRow>
    </styled.AmountFiltersWrapper>
  )
}
