import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonLink,
  Flex,
  InfoBox,
  INFOBOX_PRIORITY,
} from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { InvoiceFormFields } from '../../../../../../Invoice.types'
import { translations } from './InvoiceSelectCustomerInfobox.i18n'
import * as styled from './InvoiceSelectCustomerInfobox.styled'

type InvoiceSelectCustomerInfoboxProps = {
  onEditCustomerClick: () => void
}

export const InvoiceSelectCustomerInfobox = ({
  onEditCustomerClick,
}: InvoiceSelectCustomerInfoboxProps) => {
  const t = useTranslations(translations)
  const {
    values: {
      customer: { payerContact },
    },
  } = useFormikContext<InvoiceFormFields>()

  if (payerContact) {
    return null
  }

  return (
    <styled.Wrapper>
      <InfoBox
        priority={INFOBOX_PRIORITY.DEFAULT}
        dataTestId="select-customer-infobox"
      >
        <InfoBox.Message>
          <Flex>
            <Box mr="4px">{t('selectCustomer1')}</Box>
            <ButtonLink onClick={onEditCustomerClick}>
              {t('selectCustomer2')}
            </ButtonLink>
          </Flex>
        </InfoBox.Message>
      </InfoBox>
    </styled.Wrapper>
  )
}
