export enum KEY_CODE {
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ENTER = 'Enter',
  SPACE = ' ',
  TAB = 'Tab',
  ESCAPE = 'Escape',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
}
