import { Mark, StyledSlider, Thumb, Track } from './Slider.styled'
import { SliderProps } from './Slider.types'
import { createMarksRange } from './Slider.utils'

export const Slider = ({
  min,
  max,
  step,
  onChange,
  value,
  className,
  onAfterChange,
}: SliderProps) => (
  <StyledSlider
    defaultValue={min}
    ariaLabel="Thumb"
    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
    className={className}
    min={min}
    max={max}
    step={step}
    marks={createMarksRange(min, max, step)}
    value={value}
    // react-slider has typings issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange={onChange}
    onAfterChange={onAfterChange}
    renderThumb={(props) => (
      // react-slider has typings issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Thumb data-testid="thumb" value={value} {...props} />
    )}
    // react-slider has typings issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    renderTrack={(props, state) => <Track {...props} index={state.index} />}
    renderMark={(props) => {
      return (
        <Mark
          data-testid={`marks-${props.key}`}
          key={props.key}
          positionLeft={Number(props.style?.left)}
          isActive={Boolean(props.key && value >= (props.key as number))}
        />
      )
    }}
  />
)
