import { useParams } from 'react-router-dom-v5-compat'

import { useConvertToUuid } from '../useConvertToUuid/useConvertToUuid'

export const useSelectedEntityUuid = () => {
  const { convertToUuid } = useConvertToUuid()

  const { entityId } = useParams()

  return convertToUuid(entityId)
}
