import { ReactNode } from 'react'
import {
  ACCORDION_CONTENT_PADDING_LEFT,
  ACCORDION_CONTENT_PADDING_RIGHT,
  Flex,
} from '@npco/zeller-design-system'
import { SpaceProps } from 'styled-system'

export const ACCORDION_CONTENT_PADDING_LEFT_MOBILE = '10px'
const ACCORDION_CONTENT_PADDING_RIGHT_MOBILE = '20px'

interface InvoiceAccordionContentWrapperProps {
  children: ReactNode
  gridGap?: string
  paddingLeft?: SpaceProps['pl']
  paddingRight?: SpaceProps['pr']
  paddingBottom?: SpaceProps['pb']
}

export const InvoiceAccordionContentWrapper = ({
  children,
  gridGap = '24px',
  paddingLeft = {
    _: ACCORDION_CONTENT_PADDING_LEFT_MOBILE,
    MD: ACCORDION_CONTENT_PADDING_LEFT,
  },
  paddingRight = {
    _: ACCORDION_CONTENT_PADDING_RIGHT_MOBILE,
    MD: ACCORDION_CONTENT_PADDING_RIGHT,
  },
  paddingBottom,
}: InvoiceAccordionContentWrapperProps) => {
  return (
    <Flex
      data-testid="invoicing-accordion-content-wrapper"
      flexDirection="column"
      gridGap={gridGap}
      pl={paddingLeft}
      pr={paddingRight}
      pb={paddingBottom}
    >
      {children}
    </Flex>
  )
}
