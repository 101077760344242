import { TransactionCardholderContactMap } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/transactions'
import { isContactDeletedFromRv } from 'features/Contacts/rv-deprecated/contacts.utils'

export const getLinkedContactUuid = (
  cardholderContactMap: TransactionCardholderContactMap,
  cardholderUuid: string | null,
  contactUuid: string | null
): string | null => {
  if (cardholderUuid) {
    // NOTE: if we have a linked contact uuid we always override the existing
    // contact uuid value, note that this could be contactUuid or null. Null
    // defines that the cardholderUuid had their original linked contact
    // removed. Note that the contactUuid could be different to the original
    // contactUuid passed above as the linked contact could have already been
    // changed locally. Furthermore if we know the contact has been deleted
    // locally return null as the transaction data could be stale.
    const linkedContactUuid = cardholderContactMap[cardholderUuid]

    // NOTE: the contact uuid has already been unlinked from the cardholder uuid
    // locally so override contact uuid value and return null.
    if (linkedContactUuid === null) {
      return null
    }

    const isLinkedContactUuidDeleted = isContactDeletedFromRv(linkedContactUuid)

    // NOTE: we've linked the contact uuid locally, we return this only if the
    // contact uuid has not been deleted locally.
    if (linkedContactUuid && !isLinkedContactUuidDeleted) {
      return linkedContactUuid
    }

    const isContactUuidDeleted = isContactDeletedFromRv(contactUuid)

    // NOTE: we've never linked or unlinked the contact uuid locally so we
    // fallback to the contact uuid value on the transaction, we return this
    // only if we have a value and the contact has not been deleted locally.
    if (contactUuid && !isContactUuidDeleted) {
      return contactUuid
    }
  }

  return null
}
