import { useCallback, useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'
import { ErrorInfoType } from 'types/errors'

import { getErrorInfoType } from '../AuthenticationScreen.utils'
import { loginErrorTranslation } from './LoginError.i18n'
import { StyledLogoWrapper } from './LoginError.styled'
import { LoginErrorContent } from './LoginErrorContent'

interface LoginErrorProps {
  error: ApolloError
}

export const LoginError = ({ error }: LoginErrorProps) => {
  const t = useTranslations(loginErrorTranslation)

  const getErrorMessage = useCallback(
    (errorInfoType?: ErrorInfoType) => {
      switch (errorInfoType) {
        case ErrorInfoType.ACCOUNT_DISABLED:
          return {
            title: t('disabledAccountTitle'),
            description: t('description2'),
          }
        case ErrorInfoType.ACCOUNT_INACTIVE:
          return {
            title: t('inactiveAccountTitle'),
            description: t('description2'),
          }
        default:
          return {
            title: t('title'),
            description: t('description1'),
            subTitle: t('subTitle'),
          }
      }
    },
    [t]
  )

  const messages = useMemo(
    () => getErrorMessage(getErrorInfoType(error)),
    [getErrorMessage, error]
  )

  return (
    <>
      <StyledLogoWrapper>
        <SvgIcon width="69" height="24">
          <LogoIcon data-testid="logo-zeller" />
        </SvgIcon>
      </StyledLogoWrapper>
      <LoginErrorContent
        title={messages.title}
        subTitle={messages.subTitle}
        description={messages.description}
      />
    </>
  )
}
