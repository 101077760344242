import { gql, type TypedDocumentNode } from '@apollo/client'

import * as FinaliseEntityOnboardingGQL from 'types/gql-types/FinaliseEntityOnboarding'
import {
  IdentityPhoneRegister as IdentityPhoneRegisterResponse,
  IdentityPhoneRegisterVariables,
} from 'types/gql-types/IdentityPhoneRegister'
import {
  IdentityPhoneRegisterMfa as IdentityPhoneRegisterMfaResponse,
  IdentityPhoneRegisterMfaVariables,
} from 'types/gql-types/IdentityPhoneRegisterMfa'
import {
  IdentityPhoneVerify as IdentityPhoneVerifyResponse,
  IdentityPhoneVerifyVariables,
} from 'types/gql-types/IdentityPhoneVerify'
import {
  RequestCustomerDocumentVerification as RequestCustomerDocumentVerificationResponse,
  RequestCustomerDocumentVerificationVariables,
} from 'types/gql-types/RequestCustomerDocumentVerification'

export const IdentityPhoneRegister = gql`
  mutation IdentityPhoneRegister($phone: String!) {
    identityPhoneRegister(phone: $phone) {
      codeSent
      validUntil
    }
  }
` as unknown as TypedDocumentNode<
  IdentityPhoneRegisterResponse,
  IdentityPhoneRegisterVariables
>

export const IdentityPhoneRegisterMfa = gql`
  mutation IdentityPhoneRegisterMfa($phone: String!) {
    identityPhoneRegisterMfa(phone: $phone) {
      codeSent
      validUntil
    }
  }
` as unknown as TypedDocumentNode<
  IdentityPhoneRegisterMfaResponse,
  IdentityPhoneRegisterMfaVariables
>

export const IdentityPhoneVerify = gql`
  mutation IdentityPhoneVerify($code: String!) {
    identityPhoneVerify(code: $code) {
      codeVerified
    }
  }
` as unknown as TypedDocumentNode<
  IdentityPhoneVerifyResponse,
  IdentityPhoneVerifyVariables
>

export const CustomerDocumentVerification = gql`
  mutation RequestCustomerDocumentVerification(
    $customerInput: VerifyCustomerDocumentsInput!
  ) {
    requestCustomerDocumentVerification(input: $customerInput)
  }
` as unknown as TypedDocumentNode<
  RequestCustomerDocumentVerificationResponse,
  RequestCustomerDocumentVerificationVariables
>

export const FinaliseEntityOnboarding = gql`
  mutation FinaliseEntityOnboarding($entityUuid: ID!) {
    finaliseEntityOnboarding(entityUuid: $entityUuid) {
      bsb
      account
      result
    }
  }
` as TypedDocumentNode<FinaliseEntityOnboardingGQL.FinaliseEntityOnboarding>
