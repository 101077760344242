import { pipe } from 'ramda'

const getEntries = (object: Record<string, boolean>) => Object.entries(object)

const filterElements = (entries: [string, boolean][]) =>
  entries.filter(([, value]) => value)

const mapElementsToGetClassNames = (entries: [string, boolean][]) =>
  entries.map(([className]) => className)

const createClassNames =
  (additionalClassName?: string) => (classNames: string[]) =>
    [...classNames, additionalClassName?.trim()].filter(Boolean).join(' ')

export const conditionalClassName = (
  object: Record<string, boolean>,
  passthrough?: string
) => {
  return pipe(
    getEntries,
    filterElements,
    mapElementsToGetClassNames,
    createClassNames(passthrough)
  )(object)
}
