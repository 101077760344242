import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { Contact } from '../../../TransactionDrawer.types'

export type Merchant = NonNullable<DebitCardTransactionsV2Fragment['merchant']>
export type MerchantAddress = NonNullable<Merchant['address']>

export interface ContactAndMerchant {
  contact: Contact | null
  merchant: Merchant | null
}

export type ContactAddress = NonNullable<ContactCoreFieldsFragment['address']>

export type Address = MerchantAddress | ContactAddress

export interface DailyOpenCloseHours {
  open: string
  close: string
}

export interface DailyHours {
  status?: string
  times?: DailyOpenCloseHours[] | null
}

export interface OpeningHours {
  // eslint-disable-next-line camelcase
  is_always_open?: boolean | null
  sunday?: DailyHours | null
  monday?: DailyHours | null
  tuesday?: DailyHours | null
  wednesday?: DailyHours | null
  thursday?: DailyHours | null
  friday?: DailyHours | null
  saturday?: DailyHours | null
}

export interface OpeningHoursForDay extends Omit<OpeningHoursDisplay, 'days'> {
  day: string
}

export interface OpeningHoursDisplay {
  days: string[]
  hours: string | null
  status: string | null
}

export enum OpeningHoursStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  TwentyFourHours = '24HOURS',
}
