import { CardScheme } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  SvgIcon,
  TooltipBasic,
} from '@npco/zeller-design-system'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { ReactComponent as AmexLogo } from 'assets/svg/payment-providers/amex.svg'
import { ReactComponent as CUPLogo } from 'assets/svg/payment-providers/cup.svg'
import { ReactComponent as DinersLogo } from 'assets/svg/payment-providers/diners.svg'
import { ReactComponent as EftposLogo } from 'assets/svg/payment-providers/eftpos.svg'
import { ReactComponent as JCBLogo } from 'assets/svg/payment-providers/jcb.svg'
import { ReactComponent as MasterCardLogo } from 'assets/svg/payment-providers/mastercard.svg'
import { ReactComponent as OtherLogo } from 'assets/svg/payment-providers/other.svg'
import { ReactComponent as VisaLogo } from 'assets/svg/payment-providers/visa.svg'
import {
  GetSite_getSite_schemes as Schemes,
  GetSite_getSite_schemesMoto as SchemesMoto,
} from 'types/gql-types/GetSite'

import { translations } from './IconsBar.i18n'

interface Props {
  values: (Schemes | SchemesMoto)[] | null | undefined
  isCpoc?: boolean
}

const { AMEX, CUP, DINERS, EFTPOS, JCB, MC, VISA, OTHER } = CardScheme

export const IconsBar = ({ values, isCpoc }: Props) => {
  const t = useTranslations(translations)

  const getLogo = (name: string) => {
    switch (name) {
      case AMEX:
        return <AmexLogo />
      case CUP:
        return <CUPLogo />
      case DINERS:
        return <DinersLogo />
      case EFTPOS:
        return <EftposLogo />
      case JCB:
        return <JCBLogo />
      case MC:
        return <MasterCardLogo />
      case VISA:
        return <VisaLogo />
      case OTHER:
      default:
        return <OtherLogo />
    }
  }

  return (
    <Flex data-testid="icons-bar" width="100%" flexWrap="wrap">
      {values?.map((value) => (
        <Box key={value.name} position="relative" mr="16px">
          {isCpoc && value.name === CardScheme.JCB && (
            <TooltipBasic
              placement="top"
              showArrow={false}
              renderTrigger={({ handlers }) => (
                <Box position="absolute" right="-4px" top="-2px" {...handlers}>
                  <SvgIcon
                    width="14"
                    height="14"
                    color={COLOR.GREY_550}
                    dataTestId="info-icon"
                  >
                    <InfoIcon />
                  </SvgIcon>
                </Box>
              )}
            >
              {t('jcbTooltip')}
            </TooltipBasic>
          )}
          <SvgIcon width="40" height="40">
            {getLogo(value.name as string)}
          </SvgIcon>
        </Box>
      ))}
    </Flex>
  )
}
