import { AnchorBasic, BodySmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Link = styled(AnchorBasic)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  ${BodySmallTypography};

  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.BLACK_900};
  }
`
