import { AccordionSimple } from '@npco/zeller-design-system'

import { Button } from 'components/Buttons/Button'
import { CancelButton } from 'components/Modal/ModalElements/ModalElements'
import { ModalScrollable } from 'components/ModalScrollable/ModalScrollable'
import {
  general,
  mobileDataServices,
  page,
  standardForm,
  summary,
} from 'translations'

import {
  StyledButtonWrapper,
  StyledGeneralFirst,
  StyledGeneralList,
  StyledGeneralSecond,
  StyledSimModalWrapper,
} from './ActivateSimCardModal.styled'
import { MobileDataServices } from './MobileDataServices'
import { StandardForm } from './StandardForm'
import { Summary } from './Summary'

interface Props {
  isOpen: boolean
  onCancel: () => any
  onAccept: () => any
  isLoading: boolean
}

export const ActivateSimCardModal = ({
  isOpen,
  onCancel,
  onAccept,
  isLoading,
}: Props) => {
  return (
    <ModalScrollable
      isOpen={isOpen}
      onCancel={onCancel}
      title={page.settings.sim.modal.title}
      hasCloseButton={false}
    >
      <StyledSimModalWrapper>
        <StyledGeneralFirst>{general.first}</StyledGeneralFirst>
        <StyledGeneralSecond>{general.second}</StyledGeneralSecond>
        <StyledGeneralList>
          <li>{general.list.first}</li>
          <li>{general.list.second}</li>
          <li>{general.list.third}</li>
        </StyledGeneralList>
        <AccordionSimple title={summary.title}>
          <Summary />
        </AccordionSimple>
        <AccordionSimple title={standardForm.title}>
          <StandardForm />
        </AccordionSimple>
        <AccordionSimple title={mobileDataServices.title}>
          <MobileDataServices />
        </AccordionSimple>
        <StyledButtonWrapper>
          <Button onClick={onAccept} disabled={isLoading}>
            {general.accept}
          </Button>
          <CancelButton onClick={onCancel}>{general.cancel}</CancelButton>
        </StyledButtonWrapper>
      </StyledSimModalWrapper>
    </ModalScrollable>
  )
}
