import {
  combineValidators,
  validateRequiredCustomMsg,
} from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'
import { InputAdaptiveField } from '@npco/zeller-design-system'

import { taxFileNumberFieldTranslations } from './TaxFileNumberField.i18n'
import { validateTaxFileNumber } from './TaxFileNumberField.validators'
import { TaxFileNumberTooltip } from './TaxFileNumberTooltip'

export const TaxFileNumberField = () => {
  const t = useTranslations(taxFileNumberFieldTranslations)

  return (
    <InputAdaptiveField
      name="tfn"
      label={t('taxFileNumber')}
      renderRightControls={TaxFileNumberTooltip}
      validate={combineValidators(
        validateRequiredCustomMsg(t('requiredTaxFileNumber')),
        validateTaxFileNumber
      )}
    />
  )
}
