import { useCallback, useMemo } from 'react'
import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { rvCurrentUserData } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { isNotNull } from 'utils/common'

import { YourCardsListQuery } from './graphql/YourCardsListQuery'
import {
  YourCardsListQueryResponse,
  YourCardsListQueryVariables,
} from './graphql/YourCardsListQuery.generated'

const DISPLAYED_STATUSES = [
  DebitCardStatus.ACTIVE,
  DebitCardStatus.SUSPENDED,
  DebitCardStatus.LOCKED,
]

export const useYourCardsListQuery = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error, refetch, networkStatus } = useQuery<
    YourCardsListQueryResponse,
    YourCardsListQueryVariables
  >(YourCardsListQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
    variables: { entityUuid },
  })

  const currentUser = useReactiveVar(rvCurrentUserData)

  const yourCards = useMemo(
    () =>
      (data?.getDebitCardsV2.cards || [])
        .filter(isNotNull)
        .filter((card) => DISPLAYED_STATUSES.includes(card.status))
        .filter((card) => card.customer?.id === currentUser?.id),
    [data?.getDebitCardsV2.cards, currentUser]
  )

  const entity = useMemo(() => {
    return data?.getEntity ?? null
  }, [data?.getEntity])

  const refetchWithoutVariables = useCallback(() => {
    refetch()
  }, [refetch])

  return {
    isLoading:
      (loading && yourCards.length === 0) ||
      networkStatus === NetworkStatus.refetch,
    cards: yourCards,
    entity,
    refetch: refetchWithoutVariables,
    error,
  }
}
