import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionType } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  AnchorBasic,
  Box,
  Flex,
  Heading,
  showSuccessToast,
  useModalState,
} from '@npco/zeller-design-system'

import { MANAGE_XERO_URL } from 'const/externalLinks'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { SpinnerWrapped } from 'components/Spinner'

import { DisconnectModal } from '../../../components/DisconnectModal'
import { useMutationDisconnect } from '../../../hooks/useMutationDisconnect'
import { TextRow } from '../../components/TextRow'
import { useGetSettlementAccountInfo } from '../../hooks/useGetSettlementAccountInfo'
import { useGetXeroOrganisationName } from '../../hooks/useGetXeroOrganisationName'
import { RemoveConnectionButton, Statement } from './General.styled'

export const General = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const disconnectModal = useModalState()
  const { isLoadingXeroOrganisationName, xeroOrganisationName } =
    useGetXeroOrganisationName()
  const { accountName, isLoading: isLoadingAccountName } =
    useGetSettlementAccountInfo()
  const [disconnect] = useMutationDisconnect(
    ConnectionType.XERO_PAYMENT_SERVICES,
    translate(
      'page.settings.connections.xeroPaymentServices.general.errorToast'
    )
  )
  const onClose = useCallback(
    () => navigate(ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.path),
    [navigate, shortEntityId]
  )

  const onConfirmDisconnectModal = useCallback(async () => {
    const result = await disconnect()
    if (result.error) {
      return
    }

    showSuccessToast(
      translate(
        'page.settings.connections.xeroPaymentServices.general.successToast'
      )
    )
    onClose()
  }, [disconnect, onClose])

  if (isLoadingXeroOrganisationName || isLoadingAccountName) {
    return <SpinnerWrapped variant="centre" />
  }

  return (
    <>
      <Flex
        flexDirection="column"
        gridGap="40px"
        data-testid="xero-payment-services-general-container"
      >
        <Flex flexDirection="column" gridGap="8px">
          <TextRow
            label={translate(
              'page.settings.connections.xeroPaymentServices.general.organisation'
            )}
            details={xeroOrganisationName ?? ''}
          />
          <TextRow
            label={translate(
              'page.settings.connections.xeroPaymentServices.general.settlementAccount'
            )}
            details={accountName ?? ''}
          />
        </Flex>
        <Flex flexDirection="column" gridGap="24px">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gridGap="24px"
          >
            <Heading.H3>
              {translate(
                'page.settings.connections.xeroPaymentServices.general.brandingThemes'
              )}
            </Heading.H3>
            <AnchorBasic href={MANAGE_XERO_URL}>
              {translate(
                'page.settings.connections.xeroPaymentServices.general.linkText'
              )}
            </AnchorBasic>
          </Flex>
          <Statement>
            {translate(
              'page.settings.connections.xeroPaymentServices.general.description'
            )}
          </Statement>
        </Flex>
        <Box>
          <RemoveConnectionButton onClick={disconnectModal.openModal}>
            {translate(
              'page.settings.connections.xeroPaymentServices.general.removeConnection'
            )}
          </RemoveConnectionButton>
        </Box>
      </Flex>
      <DisconnectModal
        isOpen={disconnectModal.isModalOpen}
        onCancel={disconnectModal.closeModal}
        onConfirm={onConfirmDisconnectModal}
      >
        <Statement>
          {translate(
            'page.settings.connections.xeroPaymentServices.removeConnectionDialogue.body.lineOne'
          )}
        </Statement>
        <br />
        <Statement>
          {translate(
            'page.settings.connections.xeroPaymentServices.removeConnectionDialogue.body.lineTwo'
          )}
        </Statement>
      </DisconnectModal>
    </>
  )
}
