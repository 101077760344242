import { gql } from '@apollo/client'

import {
  GetContactOrMerchantLocationDataContactFragment,
  GetContactOrMerchantLocationDataMerchantDetailsFragment,
} from './contacts.utils.generated'

interface ContactAndMerchant {
  contact: GetContactOrMerchantLocationDataContactFragment | null
  merchant?: GetContactOrMerchantLocationDataMerchantDetailsFragment | null
}

export const getContactOrMerchantLocationData = ({
  contact,
  merchant,
}: ContactAndMerchant) => {
  if (merchant?.location || merchant?.address) {
    return {
      address: merchant?.address,
      location: merchant?.location,
      locationAccuracy: merchant?.locationAccuracy,
    }
  }
  return {
    address: contact?.address,
    location: contact?.location,
    locationAccuracy: contact?.locationAccuracy,
  }
}

getContactOrMerchantLocationData.fragments = {
  MerchantDetails: gql`
    fragment GetContactOrMerchantLocationDataMerchantDetailsFragment on MerchantDetails {
      address {
        country
        postcode
        state
        street1
        street2
        suburb
      }
      location
      locationAccuracy
    }
  `,
  Contact: gql`
    fragment GetContactOrMerchantLocationDataContactFragment on Contact {
      address {
        country
        postcode
        state
        street
        suburb
      }
      location
      locationAccuracy
    }
  `,
}
