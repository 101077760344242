import {
  Divider,
  Flex,
  ToggleForm,
  Typography,
} from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { CustomerDetailsForm } from 'types/customers'
import { Description } from 'layouts'

type PermissionName =
  | 'allowZellerInvoices'
  | 'allowItemManagement'
  | 'allowDiscountManagement'

type PermissionSectionProps = {
  description: string
  fieldName: PermissionName
  isAdmin: boolean
  title: string
}

export const PermissionSection = ({
  description,
  fieldName,
  isAdmin,
  title,
}: PermissionSectionProps) => {
  const { values } = useFormikContext<CustomerDetailsForm>()
  return (
    <>
      <Flex justifyContent="space-between">
        <Typography component="h3" variant="heading-xl" mb="24px">
          {title}
        </Typography>
        <Field
          component={ToggleForm}
          name={fieldName}
          value={values[fieldName]}
          isDisabled={isAdmin}
        />
      </Flex>
      <Description>{description}</Description>
      <Divider />
    </>
  )
}
