import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, PillSize } from '@npco/zeller-design-system'

import { currencyFormatter } from 'utils/common'
import { parseApiMoney } from 'utils/parseMoney'
import { translationsShared } from 'translations'

import { AVATAR_BADGE_SIZE } from '../../../../../types/common'
import {
  getBadgeOptions,
  getContactIsSelf,
  getTitle,
  getTransactionAmountColorByStatus,
} from '../../../DebitCardTransactions.utils'
import { DrawerHeading } from './DrawerHeader/DrawerHeader'
import {
  TransactionHeaderDebitCardAccountTransactionFragment,
  TransactionHeaderDebitCardTransactionV2Fragment,
} from './TransactionHeader.generated'
import * as styled from './TransactionHeader.styled'
import {
  getCanUnlinkContact,
  getIsAllowedContactUnlinking,
} from './TransactionHeader.utils'
import { UnlinkContactButton } from './UnlinkContactButton'

interface Props {
  transaction:
    | TransactionHeaderDebitCardTransactionV2Fragment
    | TransactionHeaderDebitCardAccountTransactionFragment
  isOnContactTab: boolean
  unlinkContact: () => void
  isUnlinkingContact?: boolean
  hasUnlinkingContactError?: boolean
}

export const TransactionHeader = ({
  transaction,
  isOnContactTab,
  unlinkContact,
  isUnlinkingContact,
  hasUnlinkingContactError,
}: Props) => {
  const tShared = useTranslations(translationsShared)
  const badge = getBadgeOptions(transaction, AVATAR_BADGE_SIZE.DEFAULT)

  const title = getTitle(transaction)

  const parsedAmount = transaction.amount
    ? parseApiMoney(transaction.amount.value)
    : 0
  const formattedAmount = currencyFormatter(parsedAmount)

  const cardAcceptorName = transaction?.cardAcceptorName

  const canUnlinkContact = getCanUnlinkContact({
    transaction,
  })

  const isSelfContact = getContactIsSelf(transaction)

  if (
    (isOnContactTab &&
      getIsAllowedContactUnlinking({ transaction }) &&
      !transaction.contact?.id) ||
    isUnlinkingContact ||
    hasUnlinkingContactError
  ) {
    return null
  }

  return (
    <DrawerHeading>
      {badge}
      <DrawerHeading.Title
        title={title}
        pill={
          isOnContactTab &&
          isSelfContact && (
            <styled.Pill text={tShared('you')} size={PillSize.Small} />
          )
        }
        byline={
          cardAcceptorName && (
            <styled.CardAcceptorName>
              {cardAcceptorName}
            </styled.CardAcceptorName>
          )
        }
        rightAlignedContent={
          !isOnContactTab && (
            <styled.Amount
              color={getTransactionAmountColorByStatus(transaction)}
            >
              {formattedAmount}
            </styled.Amount>
          )
        }
      />
      {isOnContactTab && canUnlinkContact && (
        <Box mt="12px">
          <UnlinkContactButton unlinkContact={unlinkContact} />
        </Box>
      )}
    </DrawerHeading>
  )
}

TransactionHeader.fragments = {
  DebitCardTransactionV2: gql`
    fragment TransactionHeaderDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      amount {
        value
      }
      cardAcceptorName
      ...GetBadgeOptionsDebitCardTransactionV2Fragment
      ...GetTitleDebitCardTransactionV2Fragment
      ...GetCanUnlinkContactDebitCardTransactionV2Fragment
      ...GetContactIsSelfDebitCardTransactionV2Fragment
      ...GetTransactionAmountColorByStatusDebitCardTransactionV2Fragment
    }

    ${getBadgeOptions.fragments.DebitCardTransactionV2}
    ${getTitle.fragments.DebitCardTransactionV2}
    ${getCanUnlinkContact.fragments.DebitCardTransactionV2}
    ${getContactIsSelf.fragments.DebitCardTransactionV2}
    ${getTransactionAmountColorByStatus.fragments.DebitCardTransactionV2}
  `,

  DebitCardAccountTransaction: gql`
    fragment TransactionHeaderDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      amount {
        value
      }
      cardAcceptorName
      ...GetBadgeOptionsDebitCardAccountTransactionFragment
      ...GetTitleDebitCardAccountTransactionFragment
      ...GetCanUnlinkContactDebitCardAccountTransactionFragment
      ...GetContactIsSelfDebitCardAccountTransactionFragment
      ...GetTransactionAmountColorByStatusDebitCardAccountTransactionFragment
    }

    ${getBadgeOptions.fragments.DebitCardAccountTransaction}
    ${getTitle.fragments.DebitCardAccountTransaction}
    ${getCanUnlinkContact.fragments.DebitCardAccountTransaction}
    ${getContactIsSelf.fragments.DebitCardAccountTransaction}
    ${getTransactionAmountColorByStatus.fragments.DebitCardAccountTransaction}
  `,
}
