import {
  AVATAR_BADGE_SIZE,
  AvatarBasic,
  Box,
  COLOR,
  Flex,
} from '@npco/zeller-design-system'

import { badgeLabel } from 'utils/badge'

import {
  StyledMobileBadge,
  StyledTextLarge,
  StyledTextMedium,
  StyledTextSmall,
} from './Card.styled'

interface Props {
  isClickable?: boolean
  isReadOnly?: boolean
  letterBadgeColor?: string
  text: string | null
  textSmall?: string
  type?: string
  isSmall?: boolean
  hasBadge?: boolean
  textSmallColor: string
  hasOnCardClick?: boolean
  isAdmin?: boolean
}

/**
 * @deprecated
 */
export const CardMainContent = ({
  letterBadgeColor,
  isClickable = true,
  isReadOnly = false,
  text,
  textSmall,
  type,
  hasOnCardClick,
  hasBadge = true,
  isSmall = false,
  isAdmin,
  textSmallColor,
}: Props) => {
  return (
    <Flex alignItems="center">
      <Box
        margin={
          isClickable || isReadOnly ? '12px 16px 12px 12px' : '0 16px 0 0'
        }
      >
        <AvatarBasic
          badgeSize={AVATAR_BADGE_SIZE.SMALL}
          bgColor={
            letterBadgeColor ?? (isAdmin ? COLOR.RED_120 : COLOR.GREY_90)
          }
          color={isAdmin ? COLOR.RED_1000 : COLOR.GREY_700}
          text={text ?? ''}
          withCursorPointer={hasOnCardClick}
        />
      </Box>
      <Flex flexDirection="column">
        {isSmall ? (
          <StyledTextMedium hasOnCardClick={hasOnCardClick}>
            {text}
          </StyledTextMedium>
        ) : (
          <StyledTextLarge hasOnCardClick={hasOnCardClick}>
            {text}
          </StyledTextLarge>
        )}
        {textSmall && (
          <StyledTextSmall
            hasOnCardClick
            color={textSmallColor}
            className="cardTextSmall"
          >
            {textSmall}
          </StyledTextSmall>
        )}
        {type && hasBadge && (
          <StyledMobileBadge>{badgeLabel[type]}</StyledMobileBadge>
        )}
      </Flex>
    </Flex>
  )
}
