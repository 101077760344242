import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledListWrapper } from 'components/Lists/InfiniteList/InfiniteList.styled'

export const StyledDepositsListWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  height: 0;

  ${StyledListWrapper} {
    margin: 0 -0.5rem;
    padding: 0 0.5rem;

    @media screen and (min-width: ${BREAKPOINT.XS}px) {
      margin: 0 -2.5rem;
      padding: 0 2.5rem;
    }

    @media screen and (min-width: ${BREAKPOINT.MD}px) {
      margin: 0 -1.5rem;
      padding: 0 1.5rem;
    }

    @media screen and (min-width: ${BREAKPOINT.LG}px) {
      margin: 0 -2.75rem;
      padding: 0 2.75rem;
    }

    @media screen and (min-width: ${BREAKPOINT.XL}px) {
      margin: 0 -4.25rem;
      padding: 0 4.25rem;
    }
  }
`
