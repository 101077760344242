import { ButtonLinkIconLeft } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ActionButtonWithIcon = styled(ButtonLinkIconLeft)<{
  $padding?: string
}>`
  ${({ theme }) => theme.typography['body-base']};
  padding: ${({ $padding }) => $padding ?? '4px 12px'};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  text-decoration: none;

  &:disabled {
    text-decoration: none;
  }
`
export const IconWrapper = styled.div<{
  $isShowingDetails: boolean
}>`
  display: flex;
  margin-top: ${({ $isShowingDetails }) =>
    $isShowingDetails ? '16px' : '32px'};
  justify-content: center;
`
