import { TableCellText } from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

export const InvoiceTitleRowCell = ({
  cell,
  getValue,
  row,
  table,
}: CellContext<Invoice | null, unknown>) => {
  const { isLoading } = table.options.meta || {}

  let width

  if (row.index % 3 === 0) {
    width = '90%'
  } else if (row.index % 2 === 0) {
    width = '70%'
  } else {
    width = '80%'
  }

  const value = isLoading ? undefined : String(getValue<string>() || '-').trim()

  return (
    <TableCellText
      dataTestId={`invoice-table-row-${row.index}-title`}
      forwardedProps={{ style: { padding: 0 } }}
      hasDivider={false}
      isLoading={isLoading}
      key={cell.id}
      skeletonProps={{ width }}
      text={value}
    />
  )
}
