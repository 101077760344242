import {
  BankAccountDetailsInput,
  CreatePaymentInstrumentInput,
} from '@npco/mp-gql-types'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { ContactTransferArguments } from 'hooks/banking/useSendContactTransfer/useSendContactTransfer.types'
import { AuthScope } from 'types/auth'
import { CustomerDetailsForm } from 'types/customers'
import { TransactionDetails } from 'pages/Transfer/BPay/bpay-transfer-routing/BPayTransfer.types'
import { TransferContactValues } from 'pages/Transfer/ContactTransfer/ContactTransferModal/ContactTransferModal.types'

type Contact = ContactCoreFieldsFragment

export enum ApiCallType {
  Transfer = 'Transfer',
  ContactTransfer = 'ContactTransfer',
  CreatePaymentInstrument = 'CreatePaymentInstrument',
  CreateContactlessPaymentInstrument = 'CreateContactlessPaymentInstrument',
  CreateBpayPaymentInstrument = 'CreateBpayPaymentInstrument',
  CreateAdmin = 'CreateAdmin',
  UpdateAdmin = 'UpdateAdmin',
  BpayTransfer = 'BpayTransfer',
}

export interface TransferAppState {
  apiCallType: ApiCallType.Transfer
  scope: AuthScope.SENSITIVE
  variables: ContactTransferArguments
  contact?: Contact
}

export type ContactTransferAppState = {
  apiCallType: ApiCallType.ContactTransfer
  scope: AuthScope.SENSITIVE
  values: TransferContactValues
  forceDeOut: boolean
}

export interface CreatePaymentInstrumentAppState {
  apiCallType: ApiCallType.CreatePaymentInstrument
  scope: AuthScope.SENSITIVE
  variables: { contactId: string } & BankAccountDetailsInput
  contact?: Contact
}

export interface CreateContactlessPaymentInstrument {
  apiCallType: ApiCallType.CreateContactlessPaymentInstrument
  scope: AuthScope.SENSITIVE
  variables: BankAccountDetailsInput
}

export interface CreateBpayPaymentInstrumentState {
  apiCallType: ApiCallType.CreateBpayPaymentInstrument
  scope: AuthScope.SENSITIVE
  variables: {
    isDynamicPayment: boolean
    crn: string
    createPaymentInstrumentInput: CreatePaymentInstrumentInput
    amount: string
    debitCardAccountUuid: string
  }
}

export type BpayTransferState = {
  apiCallType: ApiCallType.BpayTransfer
  scope: AuthScope.SENSITIVE
  values: TransactionDetails
}

export interface CreateAdminAppState {
  apiCallType: ApiCallType.CreateAdmin
  scope: AuthScope.SENSITIVE
  variables: CustomerDetailsForm
}
export interface UpdateAdminAppState {
  apiCallType: ApiCallType.UpdateAdmin
  scope: AuthScope.SENSITIVE
  variables: { id: string } & CustomerDetailsForm
  initialValues: CustomerDetailsForm
}

export type AppState =
  | TransferAppState
  | ContactTransferAppState
  | CreatePaymentInstrumentAppState
  | CreateContactlessPaymentInstrument
  | CreateBpayPaymentInstrumentState
  | CreateAdminAppState
  | UpdateAdminAppState
  | BpayTransferState
