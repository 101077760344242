import { gql } from '@apollo/client'
import { ProgressBar } from '@npco/zeller-design-system'

import { useCardProgress } from 'hooks/useCardProgress'

import { CardSpendProgressCellContentDebitCardV2Fragment } from './CardSpendProgressCellContent.generated'

interface CardSpendProgressCellContentProps {
  card?: CardSpendProgressCellContentDebitCardV2Fragment
}
export const CardSpendProgressCellContent = ({
  card,
}: CardSpendProgressCellContentProps) => {
  const { availableAmount, totalAmount } = useCardProgress(card)

  return (
    <ProgressBar
      dataTestId="progress-bar"
      disableAnimation
      completedAmount={availableAmount}
      totalAmount={totalAmount}
      size="large"
    />
  )
}

CardSpendProgressCellContent.fragments = {
  DebitCardV2: gql`
    fragment CardSpendProgressCellContentDebitCardV2Fragment on DebitCardV2 {
      ...UseCardProgressDebitCardV2Fragment
    }

    ${useCardProgress.fragments.DebitCardV2}
  `,
}
