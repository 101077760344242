import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { TransferLocationSchema } from '../../Transfer.types'

export const useTransferRouteState = () => {
  const location = useLocationState(TransferLocationSchema)

  const state = useMemo(() => {
    if (location?.Transfer?.transferType !== 'internal') {
      return null
    }

    return location.Transfer
  }, [location])

  return { state }
}
