export enum FilterType {
  Contact = 'Contact',
  Date = 'Date',
  Amount = 'Amount',
  Category = 'Category',
  Tags = 'Tags',
  TransactionType = 'TransactionType',
  Status = 'Status',
  Receipt = 'Receipt',
  Description = 'Description',
}

export enum FilterTypeExtra {
  Export = 'Export',
}

export type FilterTypeMobile = FilterType | FilterTypeExtra

export const filtersAll: FilterType[] = [
  FilterType.Contact,
  FilterType.Date,
  FilterType.Amount,
  FilterType.Category,
  FilterType.Tags,
  FilterType.TransactionType,
  FilterType.Status,
  FilterType.Receipt,
  FilterType.Description,
]

export const filters = filtersAll

export const mobileFiltersWithExport = [...filters, FilterTypeExtra.Export]

export const alwaysVisibleFiltersAll = [
  FilterType.Contact,
  FilterType.Date,
  FilterType.Amount,
]

export const alwaysVisibleFilters = alwaysVisibleFiltersAll
