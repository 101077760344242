import { object, string } from 'yup'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useGetDebitCardAccounts } from '../../../../hooks/useGetDebitCardAccounts'
import { SearchableAccountForm } from '../../../shared/SearchableAccountForm/SearchableAccountForm'
import { useSelectAccountForm } from './hooks/useSelectAccountForm'

export const selectAccountFormValidationSchema = object({
  accountId: string().required('Required'),
})
export const SelectAccountForm = () => {
  useMultiStageModalProgressBar({
    newCurrentStep: 4,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })
  const { accounts, isAccountsLoading, hasError, refetch } =
    useGetDebitCardAccounts()
  const { selectAccount, goBackToSelectCardholderPage, initialAccountId } =
    useSelectAccountForm(accounts)

  return (
    <SearchableAccountForm
      initialAccountId={initialAccountId}
      accounts={accounts}
      isAccountsLoading={isAccountsLoading}
      hasError={hasError}
      refetch={refetch}
      selectAccount={selectAccount}
      onBack={goBackToSelectCardholderPage}
      dataTestId="select-account-form"
    />
  )
}
