import type {
  ItemContact,
  ItemContactOrBiller,
} from '../../../useBPayTargetItems'
import { contactFieldsFragment } from '../../ContactFragment.generated'

// Workaround for `getContacts` which returns `[Contact]!` (nullable contact).
type ResponseContacts = (contactFieldsFragment | null)[]

export const getContactItemsFromResponse = (
  responseContacts: ResponseContacts
): ItemContactOrBiller[] => {
  return responseContacts.reduce((acc, contact) => {
    // We can remove optional chain if `getContacts` returns `[Contact!]!`.
    if (contact?.businessName) {
      acc.push({
        type: 'Contact',
        id: contact.id,
        name: contact.businessName,
        icon: contact.icon,
        isSelf: contact.isSelf,
        email: {
          email: contact.email?.email ?? '',
        },
        phone: contact.phone,
        phoneV2: {
          phone: contact.phoneV2?.phone ?? '',
        },
        billerContacts: contact.paymentInstruments?.reduce(
          (acc, instrument) => {
            if (
              instrument.details.__typename ===
              'PaymentInstrumentBpayStaticCrnDetails'
            ) {
              acc.push({
                id: instrument.id,
                name: instrument.details.billerName ?? '',
                nickname: instrument.details.nickname ?? '',
                code: instrument.details.billerCode,
                reference: instrument.details.crn,
              })
            } else if (
              instrument.details.__typename ===
              'PaymentInstrumentBpayDynamicCrnDetails'
            ) {
              acc.push({
                id: instrument.id,
                name: instrument.details.billerName ?? '',
                nickname: instrument.details.nickname ?? '',
                code: instrument.details.billerCode,
              })
            }

            return acc
          },
          [] as NonNullable<ItemContact['billerContacts']>
        ),
      })
    }
    return acc
  }, [] as ItemContactOrBiller[])
}
