import { useEffect, useRef } from 'react'
import { ContactType, LabelType } from '@npco/mp-gql-types'
import { InputAdaptiveField } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { LabelControl } from 'components/LabelControl'
import { errorMessages } from 'translations'

import { ContactFormData } from '../Contacts.types'
import {
  getContactFormValues,
  validateContactEmail,
  validateEmailDuplication,
} from '../Contacts.utils'

const CONTACT_MAPPING = {
  [ContactType.BUSINESS]: {
    labelType: LabelType.BUSINESS,
    emailName: 'business.email.email',
    labelName: 'business.email.label',
  },
  [ContactType.PERSON]: {
    labelType: LabelType.PERSON,
    emailName: 'person.email.email',
    labelName: 'person.email.label',
  },
}

interface EmailInputFieldProps {
  contactType: ContactType
  existingContactName?: string | null
  isOptional?: boolean
}

export const EmailInputField = ({
  contactType,
  existingContactName,
  isOptional,
}: EmailInputFieldProps) => {
  const { setFieldTouched, setFieldValue, values } =
    useFormikContext<ContactFormData>()
  const wrapperRef = useRef<HTMLInputElement | null>(null)

  const contactValues = getContactFormValues(contactType, values)

  const { additionalEmails, email, phoneV2 } = contactValues

  const { labelType, emailName, labelName } = CONTACT_MAPPING[contactType]

  const labelValue = email?.label
  const labelItem = labelValue
    ? {
        id: labelValue.id,
        isEditable: labelValue.isEditable,
        label: labelValue.labelText,
        value: labelValue.labelText,
      }
    : null

  const errorMessageForIsRequiredValidation =
    contactType === ContactType.BUSINESS
      ? errorMessages.phoneNumberOrEmailRequired
      : errorMessages.emailOrMobileRequired

  useEffect(() => {
    const currentValue = email?.email

    const isDuplicateWithAdditionalEmail = validateEmailDuplication(
      currentValue,
      additionalEmails
    )

    if (!isDuplicateWithAdditionalEmail) {
      return
    }

    setFieldTouched(emailName)
  }, [additionalEmails, email, emailName, setFieldTouched])

  return (
    <InputAdaptiveField
      hasError={Boolean(existingContactName)}
      name={emailName}
      label={translate('page.contacts.fields.email')}
      renderRightControls={() => (
        <LabelControl
          labelItem={labelItem}
          labelType={labelType}
          popperWrapperRef={wrapperRef}
          setLabel={(nextLabel) => setFieldValue(labelName, nextLabel)}
        />
      )}
      wrapperRef={wrapperRef}
      validate={(value) =>
        validateContactEmail(
          value,
          additionalEmails,
          isOptional || !!phoneV2?.phone,
          errorMessageForIsRequiredValidation
        )
      }
    />
  )
}
