import { useCallback } from 'react'

import { KEY_CODE } from 'const/keyboard'

import {
  CURRENCY_MAX_5_DIGIT_PATTERN,
  CURRENCY_MAX_6_DIGIT_PATTERN,
  CURRENCY_MAX_9_DIGIT_PATTERN,
  CURRENCY_MIN_1_DIGIT_PATTERN,
  INTEGER_PATTERN,
  PERCENTAGE_PATTERN,
} from './useInputHandlers.constants'

const getNextKeypressValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const { currentTarget, key } = event
  const { value, selectionStart, selectionEnd } = currentTarget

  const posStart = selectionStart || 0
  const posEnd = selectionEnd || posStart
  const next = `${value.slice(0, posStart)}${key}${value.slice(posEnd)}`

  return next
}

export const useInputHandlers = () => {
  const handleInputKeydown = useCallback(
    ({
      event,
      pattern = CURRENCY_MAX_5_DIGIT_PATTERN,
    }: {
      event: React.KeyboardEvent<HTMLInputElement>
      pattern?: RegExp
    }) => {
      if (
        event.key === KEY_CODE.TAB ||
        event.key === KEY_CODE.BACKSPACE ||
        event.key === KEY_CODE.DELETE ||
        event.key === KEY_CODE.ARROW_LEFT ||
        event.key === KEY_CODE.ARROW_RIGHT ||
        (event.key === 'a' && (event.ctrlKey || event.metaKey)) ||
        (event.key === 'A' && (event.ctrlKey || event.metaKey))
      ) {
        return
      }

      if (!pattern.test(getNextKeypressValue(event))) {
        event.preventDefault()
      }
    },
    []
  )

  const handleCurrencyMax5DigitInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleInputKeydown({ event }),
    [handleInputKeydown]
  )

  const handleCurrencyMax9DigitInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleInputKeydown({ event, pattern: CURRENCY_MAX_9_DIGIT_PATTERN }),
    [handleInputKeydown]
  )

  const handleCurrencyMax6DigitInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleInputKeydown({ event, pattern: CURRENCY_MAX_6_DIGIT_PATTERN }),
    [handleInputKeydown]
  )

  const handleCurrencyMin1DigitInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleInputKeydown({ event, pattern: CURRENCY_MIN_1_DIGIT_PATTERN }),
    [handleInputKeydown]
  )

  const handlePercentageInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleInputKeydown({ event, pattern: PERCENTAGE_PATTERN }),
    [handleInputKeydown]
  )

  const handleIntegerInputKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleInputKeydown({ event, pattern: INTEGER_PATTERN }),
    [handleInputKeydown]
  )

  return {
    handleCurrencyMax5DigitInputKeydown,
    handleCurrencyMax6DigitInputKeydown,
    handleCurrencyMax9DigitInputKeydown,
    handleCurrencyMin1DigitInputKeydown,
    handleIntegerInputKeydown,
    handlePercentageInputKeydown,
  }
}
