import { createTranslations } from '@npco/utils-translations'

export const dragAndDropStepTranslations = createTranslations({
  title: 'Add Receipts',
  limitMessage: 'You can upload up to {maxFiles} files',
  text: 'Drag and drop or',
  subText: 'You can upload up to {maxFiles} files',
  subTextWhenMaxFilesReached:
    'You have reached the maximum of {maxFiles} files.',
  linkText: 'select a photo from your device',
  description:
    'Files must be smaller than 50MB. Supported file types include PDF, PNG, JPG/JPEG and TIFF.',
})
