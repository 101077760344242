import { matchPath, PathPattern } from 'react-router-dom-v5-compat'
import { TransactionType } from '@npco/mp-gql-types'
import {
  rvSearchPhrase,
  rvSelectedDates,
  rvSelectedRates,
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
  rvToggleFilters,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import currency from 'currency.js'
import { rvSelectedTags } from 'features/Contacts/rv-deprecated/contacts'
import { isEmpty, values } from 'lodash-es'
import {
  ascend,
  equals,
  includes,
  path as ramdaPath,
  pipe,
  prop,
  sortWith,
  split,
  splitEvery,
  toLower,
  toUpper,
  whereEq,
} from 'ramda'

import {
  datePickerInitialState,
  rangePickerAccountTransactionsInitialValue,
  rangePickerInitialValue,
  searchPhraseInitialValue,
  sourcePickerInitialValue,
  statusPickerInitialValue,
  tagPickerInitialValue,
  terminalPickerInitialValue,
  typePickerInitialValue,
} from 'const/common'
import { DATE_FULL_SAVE_FORMAT } from 'const/date'
import dayjs from 'utils/dayjs'
import { FiltersDefaultValues } from 'types/common'
import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'

// Divided by 100 because incoming amounts are in cents
/*
 * So we need to show only the $ symbol as a currency but due to missed support for option "narrow symbol" (mostly ios) i cannot use it
 * When using currencyDisplay - symbol, we still do get A$ on xiaomi devices. That's why we have this "hacky" split in the end
 * */
export const currencyFormatter = (amount: number, inDollars = false) => {
  const dollarValueString = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'AUD',
  })
    .format(inDollars ? Math.abs(amount) : Math.abs(amount) / 100)
    .split('A')
    .filter(Boolean)[0]

  if (amount < 0) {
    return `-${dollarValueString}`
  }

  return dollarValueString
}

export const formatDollars = (value: number) => currencyFormatter(value, true)

export const getFormattedDollarFromCentiCents = (value?: string) => {
  return currency(
    currency(value ?? '0', {
      fromCents: true,
      precision: 4,
    })
  ).format()
}

export const formatAccountNumber = (accountNumber: string) => {
  return splitEvery(3, accountNumber).join(' ')
}

export const formatBsb = (bsb: string) => {
  return splitEvery(3, bsb).join('-')
}

export const amountFormatter = (amount: number) =>
  new Intl.NumberFormat('en-AU').format(amount)

export const getWholePartFromCents = (amount: number | undefined): string => {
  if (!amount) {
    return `${currencyFormatter(0).split('.')[0]}`
  }

  return `${currencyFormatter(amount).split('.')[0]}`
}

export const getCents = (amount: number | undefined): string => {
  if (!amount) {
    return '00'
  }

  const amountString = Math.abs(amount).toString()
  const formattedAmount =
    amountString.length === 1 ? ['0', amountString].join('') : amountString

  return formattedAmount.slice(
    formattedAmount.length - 2,
    formattedAmount.length
  )
}

export const getFormattedTransactionAmount = (
  transaction: Transaction
): string => {
  if (transaction.type === TransactionType.REFUND) {
    return `-${currencyFormatter(
      transaction.refundedAmount ?? transaction.amount
    )}`
  }
  return currencyFormatter(transaction.amount)
}

export const getFormattedNegativeAmount = (amount: number) =>
  `-${currencyFormatter(amount)}`

export const sortAscendingByName = <T extends { name: string | null }>(
  data: T[]
) => sortWith<T>([ascend<T>(prop('name'))])(data)

export const sortAscendingByProperty = <T extends Record<string, any>>(
  property: string
) => sortWith<T>([ascend(prop(property))])

export const getIsOnPage = (
  pathPattern: string | PathPattern<string>,
  pathName: string
) => Boolean(matchPath(pathPattern, pathName))

export const capitalise = (value: string | undefined): string => {
  if (!value) {
    return ''
  }

  return `${toUpper(value[0])}${toLower(value.slice(1))}`
}

export const defaultFiltersState = {
  rates: rangePickerInitialValue,
  dates: datePickerInitialState,
  statuses: statusPickerInitialValue,
  terminals: terminalPickerInitialValue,
  phrase: searchPhraseInitialValue,
  types: typePickerInitialValue,
  sources: sourcePickerInitialValue,
}

export const checkIfFiltersNotSelected = whereEq(defaultFiltersState)
export const checkIfFiltersNotStored = whereEq({
  ...defaultFiltersState,
  dates: {},
})

export const resetFilters = ({
  selectedRates = rangePickerInitialValue,
  selectedDates = datePickerInitialState,
  selectedStatuses = statusPickerInitialValue,
  selectedTerminals = terminalPickerInitialValue,
  searchPhrase = searchPhraseInitialValue,
  selectedTypes = typePickerInitialValue,
  selectedTags = tagPickerInitialValue,
  selectedSources = sourcePickerInitialValue,
  toggleFilters = false,
}: Partial<FiltersDefaultValues> = {}) => {
  rvSelectedRates(selectedRates)
  rvSelectedDates(selectedDates)
  rvSelectedSources(selectedSources)
  rvSelectedStatuses(selectedStatuses)
  rvSelectedTerminals(selectedTerminals)
  rvSearchPhrase(searchPhrase)
  rvSelectedTypes(selectedTypes)
  rvSelectedTags(selectedTags)
  rvToggleFilters(toggleFilters)
}

export const resetAccountTransactionsFilters = () => {
  rvSelectedRates(rangePickerAccountTransactionsInitialValue)
  rvSelectedDates(datePickerInitialState)
  rvToggleFilters(false)
}

export const resetContactsFilters = () => {
  rvSelectedTags(tagPickerInitialValue)
  rvToggleFilters(false)
}

export const removeWhiteSpaces = (value: string) => value.replace(/\s/g, '')

export const getLastFourDigits = (cardNumber: string) => {
  const arrLength = cardNumber.length

  return cardNumber.slice(arrLength - 4, arrLength)
}

export const copyToClipboard = (text: string) =>
  navigator.clipboard.writeText(text)

export function getHasArraysSameItems<T>(
  arrayToCheck: T[],
  arrayToCompare: T[]
) {
  if (arrayToCheck?.length !== arrayToCompare?.length) {
    return false
  }

  let isMatched = true

  arrayToCheck.forEach((itemToCheck) => {
    const hasItem = arrayToCompare.some((itemToCompare) =>
      equals(itemToCheck, itemToCompare)
    )

    if (!hasItem) {
      isMatched = false
    }
  })

  return isMatched
}

// to make sonarcloud happy
type DefinedValue = string | number

export const buildStringWithSeparator = (
  separator: string,
  ...args: (DefinedValue | undefined | null)[]
) => args.filter((item) => Boolean(item)).join(separator)

type getIsAtLeastOneValueTruthyType = (values: Record<string, any>) => boolean

export const getIsAtLeastOneValueTruthy: getIsAtLeastOneValueTruthyType = pipe(
  values,
  includes(true)
)

export const getFromObject = <T>(path: string, record: Record<string, any>) => {
  if (isEmpty(record)) {
    return undefined
  }

  const pathString = pipe(split(/[[\].]/), ramdaPath)

  const result = pathString(path)(record)

  return result as T
}

export const maskEmail = (maybeEmail: string | undefined | null): string => {
  if (!maybeEmail) {
    return ''
  }
  const emailSplit = maybeEmail.split('@')
  return `${maybeEmail.substring(0, 1)}•••@${
    emailSplit[1].split('')[0]
  }•••${maybeEmail.substring(maybeEmail.length - 1)}`
}

export const ensureIsArray = <T>(value: T | T[]) => {
  if (!Array.isArray(value)) {
    return [value]
  }
  return value
}

export const filterNulls = <T>(totals: (T | null)[]) =>
  totals.filter(Boolean) as T[]

export const trimValue = (
  value: string | undefined | null
): string | undefined => {
  if (!value && value !== '') {
    return undefined
  }

  return value.trim()
}

export function getIsFoundBySearchFilter<T extends Record<string, any>>(
  record: T,
  searchFilterValue: string
) {
  return values(record).some((recordValue) =>
    String(recordValue).toUpperCase().includes(searchFilterValue.toUpperCase())
  )
}

export const isNotNull = <T>(maybeNull: T | null): maybeNull is T => !!maybeNull

export const getDaysFromRange = (
  start: string,
  end: string,
  format = DATE_FULL_SAVE_FORMAT
) => {
  let currentDate = dayjs(start)
  const ranges = []

  while (currentDate.isSame(end) || currentDate.isBefore(end)) {
    ranges.push(currentDate.format(format))
    currentDate = currentDate.add(1, 'day')
  }
  return ranges
}
