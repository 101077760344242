import { gql } from '@apollo/client'

export const LockDebitCard = gql`
  mutation LockDebitCard($entityUuid: ID!, $cardId: DebitCardIdInput!) {
    lockDebitCard(entityUuid: $entityUuid, cardId: $cardId)
  }
`

export const UnlockDebitCard = gql`
  mutation UnlockDebitCard($entityUuid: ID!, $cardId: DebitCardIdInput!) {
    unlockDebitCard(entityUuid: $entityUuid, cardId: $cardId)
  }
`

export const CancelDebitCard = gql`
  mutation CancelDebitCard($entityUuid: ID!, $cardId: DebitCardIdInput!) {
    cancelDebitCard(entityUuid: $entityUuid, cardId: $cardId)
  }
`

export const UpdateCardNameMutation = gql`
  mutation UpdateCardName($entityUuid: ID!, $input: UpdateDebitCardInput!) {
    updateDebitCard(entityUuid: $entityUuid, input: $input)
  }
`

export const ReportLostCardMutation = gql`
  mutation ReportLostDebitCard(
    $entityUuid: ID!
    $input: ReportLostDebitCardInput!
  ) {
    reportLostDebitCard(entityUuid: $entityUuid, input: $input) {
      reference
      result
    }
  }
`
