import { useLocation } from 'react-router-dom-v5-compat'
import { ButtonGhost, Flex } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { BREAKPOINT } from 'styles/breakpoints'
import { ButtonBlack } from 'components/Buttons/ButtonBlack/ButtonBlack'
import { StyledButtonSpecial } from 'components/Buttons/ButtonSpecial'
import { shared } from 'translations'

import { StyledLink } from './SignInNavbar.styled'

interface SignInNavProps {
  isSignUp: boolean
}

export const allowedRoutes = [
  ROOT.path,
  ROOT.SIGNUP.path,
  ROOT.FORGOT_PASSWORD.path,
]

export const SignInNavbar = ({ isSignUp }: SignInNavProps) => {
  const location = useLocation()
  const buttonLabel = isSignUp ? shared.logIn : shared.signUp
  const route = isSignUp ? ROOT.path : ROOT.SIGNUP.path
  const shouldDisplayButton = allowedRoutes.includes(location.pathname)
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.MD)

  const getButton = () => {
    if (isMobileResolution) {
      return <ButtonGhost>{buttonLabel}</ButtonGhost>
    }

    if (isSignUp) {
      return (
        <ButtonBlack data-testid="button-log-in">{buttonLabel}</ButtonBlack>
      )
    }

    return (
      <StyledButtonSpecial data-testid="button-sign-up">
        {buttonLabel}
      </StyledButtonSpecial>
    )
  }

  return shouldDisplayButton ? (
    <Flex
      data-testid="sign-in-navbar"
      justifyContent="flex-end"
      width="100%"
      p={['16px', '24px']}
    >
      <StyledLink to={route} tabIndex={-1}>
        {getButton()}
      </StyledLink>
    </Flex>
  ) : null
}
