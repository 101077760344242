import { Box, COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as CompleteIcon } from 'assets/svg/confirmation.svg'
import { translate } from 'utils/translations'
import {
  StyledIconBox,
  StyledLink,
} from 'pages/CompleteEmailChange/CompleteEmailChange.styled'
import { StyledCopy, StyledHeader } from 'pages/Login/Login.styled'

export const EmailChangedMessage = () => {
  return (
    <>
      <StyledIconBox backgroundColor={COLOR.BLUE_80}>
        <SvgIcon width="16" height="16">
          <CompleteIcon />
        </SvgIcon>
      </StyledIconBox>
      <Box>
        <StyledHeader data-testid="change-email-page">
          {translate('page.changeEmail.success.headline1')}
          <br />
          {translate('page.changeEmail.success.headline2')}
        </StyledHeader>
        <StyledCopy>
          {translate('page.changeEmail.success.copy1')}
          <br />
          {translate('page.changeEmail.success.copy2')}
          <StyledLink to="/">
            &nbsp;
            {translate('page.changeEmail.success.clickHere')}
          </StyledLink>
          .
        </StyledCopy>
      </Box>
    </>
  )
}
