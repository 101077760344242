import { useMemo } from 'react'
import { SkeletonSvgBase } from '@npco/zeller-design-system'

type Props = React.SVGProps<SVGImageElement> & {
  height: number
  width: number
}

export const BaseLoadingXAxisChartTick = ({
  x,
  y,
  width,
  height,
  ...rest
}: Props) => {
  const rectX = useMemo(() => {
    const parsedX = typeof x === 'number' ? x : Number.parseInt(x ?? '0', 10)

    return parsedX - width / 2
  }, [width, x])

  const rectY = useMemo(() => {
    return typeof y === 'number' ? y : Number.parseInt(y ?? '0', 10)
  }, [y])

  return (
    <SkeletonSvgBase
      x={rectX}
      y={rectY}
      width={width}
      height={height}
      {...rest}
    />
  )
}
