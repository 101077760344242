import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  ReportLostCardCompleteLocationStateSchema,
  ReportLostCardCompleteStage,
} from 'hooks/useGoToReportLostCardCompleteStage/useGoToReportLostCardCompleteStage.types'

const REPORT_LOST_CARD_COMPLETE_STAGES = Object.values(
  ReportLostCardCompleteStage
)

export const useReportLostCardCompleteState = () => {
  const locationState = useLocationState(
    ReportLostCardCompleteLocationStateSchema
  )

  const state = useMemo(() => {
    if (
      !locationState?.ReportLostCardCompleteModal?.stage ||
      !REPORT_LOST_CARD_COMPLETE_STAGES.includes(
        locationState.ReportLostCardCompleteModal.stage
      )
    ) {
      return null
    }

    return locationState.ReportLostCardCompleteModal
  }, [locationState?.ReportLostCardCompleteModal])

  return { state }
}
