import { ContactType } from '@npco/mp-gql-types'
import {
  Box,
  FormExtensionRenderContentProps,
} from '@npco/zeller-design-system'

import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { page } from 'translations'

import { AdditionalEmailInputField } from '../components/AdditionalEmailInputField/AdditionalEmailInputField'
import { AdditionalPhoneInputField } from '../components/AdditionalPhoneInputField/AdditionalPhoneInputField'
import { AddressInputFields } from '../components/AddressInputFields'
import { EmailInputField } from '../components/EmailInputField'
import { PhoneInputField } from '../components/PhoneInputField/PhoneInputField'
import type { ContactFormData } from '../Contacts.types'

export interface FieldProps {
  canBeAddedMultipleTimes?: boolean
  dependsOn?: string
  itemName: string
  itemId: string
  renderContent: ({ key }: FormExtensionRenderContentProps) => JSX.Element
  shouldDisplayContentOnMount: (values: ContactFormData) => boolean
  initialDisplayedElementCount?: (values: ContactFormData) => number
}

export const FIELD_JOB_TITLE_PERSON: FieldProps = {
  itemId: 'job-title',
  itemName: page.contacts.fields.jobTitle,
  renderContent: ({ key }: FormExtensionRenderContentProps) => (
    <Box mb="24px" key={key}>
      <InputAdaptiveFieldWrapper
        name="person.jobTitle"
        placeholder={page.contacts.fields.jobTitle}
        maxLength={50}
      />
    </Box>
  ),
  shouldDisplayContentOnMount: (values: ContactFormData) =>
    Boolean(values.person.jobTitle),
}

export const FIELD_STREET_PERSON: FieldProps = {
  itemId: 'address',
  itemName: page.contacts.fields.street,
  renderContent: ({ key }: FormExtensionRenderContentProps) => (
    <AddressInputFields key={key} contactType={ContactType.PERSON} />
  ),
  shouldDisplayContentOnMount: (values: ContactFormData) =>
    Boolean(values.person.country) ||
    Boolean(values.person.postcode) ||
    Boolean(values.person.state) ||
    Boolean(values.person.street) ||
    Boolean(values.person.suburb),
}

export const FIELD_EMAIL_PERSON: FieldProps = {
  itemId: 'email',
  itemName: page.contacts.fields.email,
  renderContent: () => {
    return (
      <Box mb="24px" key="email">
        <EmailInputField contactType={ContactType.PERSON} />
      </Box>
    )
  },
  shouldDisplayContentOnMount: () => true,
}

export const FIELD_ADDITIONAL_EMAIL_PERSON: FieldProps = {
  canBeAddedMultipleTimes: true,
  dependsOn: FIELD_EMAIL_PERSON.itemId,
  itemId: 'additional-email',
  itemName: page.contacts.fields.email,
  renderContent: ({
    contentIndex,
    ...props
  }: FormExtensionRenderContentProps) => {
    return (
      <Box mb="24px" {...props}>
        <AdditionalEmailInputField
          contactType={ContactType.PERSON}
          fieldIndex={contentIndex}
        />
      </Box>
    )
  },
  initialDisplayedElementCount: (values: ContactFormData) =>
    values.person.additionalEmails.length,
  shouldDisplayContentOnMount: (values: ContactFormData) =>
    values.person.additionalEmails.length > 0,
}

export const FIELD_PHONE_PERSON: FieldProps = {
  itemId: 'phone',
  itemName: page.contacts.fields.phone,
  renderContent: () => {
    return (
      <Box mb="24px" key="phone">
        <PhoneInputField
          contactType={ContactType.PERSON}
          placeholder={page.contacts.fields.phone}
        />
      </Box>
    )
  },
  shouldDisplayContentOnMount: () => true,
}

export const FIELD_ADDITIONAL_PHONE_PERSON: FieldProps = {
  canBeAddedMultipleTimes: true,
  dependsOn: FIELD_PHONE_PERSON.itemId,
  initialDisplayedElementCount: (values: ContactFormData) => {
    return values.person.additionalPhones.length
  },
  itemId: 'additional-phone',
  itemName: page.contacts.fields.phone,
  renderContent: ({
    contentIndex,
    ...props
  }: FormExtensionRenderContentProps) => {
    return (
      <Box mb="24px" {...props}>
        <AdditionalPhoneInputField
          contactType={ContactType.PERSON}
          fieldIndex={contentIndex}
          placeholder={page.contacts.fields.phone}
        />
      </Box>
    )
  },
  shouldDisplayContentOnMount: (values: ContactFormData) => {
    return values.person.additionalPhones.length > 0
  },
}
