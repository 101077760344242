import { useMemo } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { useApolloClient } from '@apollo/client'
import { CustomerRole, DebitCardProductType } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useAccounts } from 'hooks/banking'
import { useSubscription } from 'hooks/useSubscription'
import { ROUTE_PARAM_NAME } from 'const/routes'
import {
  SubscribeCardUpdate as SubscribeCardUpdateResponse,
  SubscribeCardUpdateVariables,
} from 'types/gql-types/SubscribeCardUpdate'

import { SubscribeCardUpdate } from './graphql/subscribeCardUpdate'
import {
  handleAccountCardUpdate,
  handleCorporateCardUpdate,
  handleDebitCardUpdate,
} from './useSubscribeToCardUpdate.utils'

export const useSubscribeToCardUpdate = (shouldSkip?: boolean) => {
  const client = useApolloClient()
  const { accounts } = useAccounts()
  const { accountId } = useParams<ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID>()
  const entityUuid = useSelectedEntityUuid()

  const { userData, userRole } = useLoggedInUser()

  const variables = useMemo(() => {
    return {
      entityUuid: userData?.entityUuid ?? '',
      customerUuid:
        userRole === CustomerRole.MANAGER ? userData?.id : undefined,
      debitCardAccountUuid: accountId,
    }
  }, [userRole, accountId, userData?.id, userData?.entityUuid])

  useSubscription<SubscribeCardUpdateResponse, SubscribeCardUpdateVariables>(
    SubscribeCardUpdate,
    {
      onData: (result) => {
        const updatedData = result.data.data?.onDebitCardUpdate

        if (!updatedData) {
          return
        }

        const cachedAccount = accounts.find(
          (account) => account.id === updatedData.debitCardAccountUuid
        )

        // We are not going to update the card cache when no matching account
        if (!cachedAccount) {
          return
        }

        handleAccountCardUpdate({
          client,
          updatedData,
          cachedAccount,
          entityUuid,
        })

        if (updatedData.productType === DebitCardProductType.DEBIT) {
          handleDebitCardUpdate({
            entityUuid,
            client,
            updatedData,
            cachedAccount,
          })
          return
        }

        handleCorporateCardUpdate({
          entityUuid,
          client,
          updatedData,
          cachedAccount,
        })
      },
      variables,
      skip: shouldSkip,
    }
  )
}
