import { gql } from '@apollo/client'

import { DefaultZellerCard } from './DefaultZellerCard'
import { MaskedZellerCard } from './MaskedZellerCard'
import { ZellerCardDebitCardV2Fragment } from './ZellerCard.generated'
import * as styled from './ZellerCard.styled'

interface ZellerCardProps {
  debitCard: ZellerCardDebitCardV2Fragment
  variant: 'default' | 'masked'
  height?: string
  width?: string
  alt?: string
}

export const ZellerCard = ({
  debitCard,
  variant,
  height,
  width,
  alt,
}: ZellerCardProps) => {
  return (
    <styled.SvgIconWithBoxShadow height={height} width={width} ariaLabel={alt}>
      {variant === 'masked' ? (
        <MaskedZellerCard colour={debitCard.colour} />
      ) : (
        <DefaultZellerCard colour={debitCard.colour} />
      )}
    </styled.SvgIconWithBoxShadow>
  )
}

ZellerCard.fragments = {
  DebitCardV2: gql`
    fragment ZellerCardDebitCardV2Fragment on DebitCardV2 {
      colour
    }
  `,
}
