import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { CheckSensitiveAccess } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/checkSensitiveAccess'
import { rvIsE2eInProgress } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/auth'
import { useZellerAuthenticationContext } from 'auth/ZellerAuthenticationContext'

import { CheckSensitiveAccess as CheckSensitiveAccessType } from 'types/gql-types/CheckSensitiveAccess'

export const useHasElevatedScope = () => {
  const { scope, stepUpAuth } = useZellerAuthenticationContext()
  const [hasElevatedScope, setHasElevatedScope] = useState(() =>
    scope?.includes('access:sensitive')
  )

  const { refetch, loading } = useQuery<CheckSensitiveAccessType>(
    CheckSensitiveAccess,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data && !data.checkSensitiveAccess) {
          setHasElevatedScope(false)
        } else {
          setHasElevatedScope(true)
        }
      },
      onError: () => {
        setHasElevatedScope(false)
      },
    }
  )

  const skipScopeCheckDuringE2E = rvIsE2eInProgress()

  return {
    hasElevatedScope: skipScopeCheckDuringE2E ? true : hasElevatedScope,
    stepUpAuth,
    loading,
    refetch,
  }
}
