import { StyledSelectStandardItem } from 'design-system/Components/Select'
import styled from 'styled-components'

import { SkeletonBodyDefault } from 'components/SkeletonLoader/SkeletonLoaderTypography'

export const StyledSkeleton = styled(SkeletonBodyDefault)`
  margin: 0;
`
export const StyledSelectItem = styled(StyledSelectStandardItem)`
  height: 3rem;
  pointer-events: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`
