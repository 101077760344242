import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { EditCardNameState } from './useGoToEditCardNameStage.types'

export const useGoToEditCardNameStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToEditCardNameStage = useCallback(
    (state: EditCardNameState | undefined) => {
      navigate(location.pathname, { state: { EditCardNameModal: state } })
    },
    [navigate, location]
  )

  return { goToEditCardNameStage }
}
