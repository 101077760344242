import {
  Flex,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

export const DesktopBalanceSkeleton = () => {
  return (
    <Flex data-testid="desktop-balance-skeleton">
      <SkeletonTypographyBase
        margin="0"
        type={TypographyTypes.H1}
        width="100px"
      />
      <SkeletonTypographyBase
        margin="9px 0 0 3px"
        type={TypographyTypes.H3}
        width="33px"
      />
    </Flex>
  )
}
