import { useGetCustomerLazyQuery } from './graphql/getCustomer.generated'
import { rvCurrentUserData } from './graphql/reactiveVariables'

export const useCustomerQuery = () => {
  const [getCustomerData, { loading, error, called, data }] =
    useGetCustomerLazyQuery({
      onCompleted: (customerData) => {
        if (!customerData?.getCustomer) {
          return
        }
        rvCurrentUserData(customerData.getCustomer)
      },
    })

  return {
    error,
    loading,
    getCustomerData,
    customer: data?.getCustomer,
    called,
  }
}
