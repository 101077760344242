import { DebitCardTransactionAmountUpdate_onDebitCardTransactionUpdate as AmountUpdate } from 'types/gql-types/DebitCardTransactionAmountUpdate'
import { getIsIncoming } from 'pages/Dashboard/utils/debitCardTransactionUpdate'

export const buildBalance = (
  prevBalanceInCents: number,
  transactionUpdate: AmountUpdate
) => {
  if (getIsIncoming(transactionUpdate)) {
    return {
      getDebitCardAccountBalances: [
        {
          balance: {
            value: (
              prevBalanceInCents + parseInt(transactionUpdate.amount.value, 10)
            ).toString(),
          },
        },
      ],
    }
  }

  return {
    getDebitCardAccountBalances: [
      {
        balance: {
          value: (
            prevBalanceInCents - parseInt(transactionUpdate.amount.value, 10)
          ).toString(),
        },
      },
    ],
  }
}
