import { Flex } from '@npco/zeller-design-system'

import { SkeletonH5 } from 'components/SkeletonLoader/SkeletonLoaderTypography'

import { StyledAmountCell, StyledHeaderCell } from './SummaryTable.styled'

interface Props {
  leftColumnSkeletonWidth: number
  rightColumnSkeletonWidth: number
  headerTestId?: string
  amountTestId?: string
  className?: string
}

export const SummaryTableLoadingRow = ({
  leftColumnSkeletonWidth,
  rightColumnSkeletonWidth,
  headerTestId,
  amountTestId,
  className,
}: Props) => {
  return (
    <tr className={className}>
      <StyledHeaderCell data-testid={headerTestId} className={className}>
        <SkeletonH5 width={leftColumnSkeletonWidth} margin="0" />
      </StyledHeaderCell>
      <StyledAmountCell data-testid={amountTestId} className={className}>
        <Flex justifyContent="flex-end">
          <SkeletonH5 width={rightColumnSkeletonWidth} margin="0" />
        </Flex>
      </StyledAmountCell>
    </tr>
  )
}
