import { BodyLargeTypography, H1 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const StyledLogoWrapper = styled.div`
  display: none;
  position: absolute;
  top: 2.5rem;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`

export const StyledTitle = styled(H1)`
  margin: 0 0 2.5rem;
  text-align: center;
`

export const StyledDescription = styled.p`
  ${BodyLargeTypography};
  text-align: center;
  margin-bottom: 2.5rem;
`
