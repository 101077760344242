import { createContext, useContext } from 'react'

type VisibilityKey = 'settings' | 'addCard' | 'activateCard'
export type HeaderButtonsVisibilityType = Record<VisibilityKey, boolean>

export interface CardsListLayoutContextType {
  isHeaderButtonsVisible: HeaderButtonsVisibilityType
  setIsHeaderButtonsVisible: React.Dispatch<
    React.SetStateAction<HeaderButtonsVisibilityType>
  >
}

export const CardsListLayoutContext =
  createContext<CardsListLayoutContextType | null>(null)

export const useCardsListLayoutContext = () => {
  const context = useContext(CardsListLayoutContext)

  if (!context) {
    throw new Error(
      'useCardsListLayoutContext must be used within CardsListLayoutContext'
    )
  }

  return context
}
