import { z } from 'zod'

const EditDebitCardTransactionImagesStateSchema = z.object({
  stage: z.literal('edit'),
  debitCardTransactionUuid: z.string(),
  maxFiles: z.number(),
})

export type EditDebitCardTransactionImagesState = z.infer<
  typeof EditDebitCardTransactionImagesStateSchema
>

export const EditDebitCardTransactionImagesLocationStateSchema = z.object({
  EditDebitCardTransactionImagesModal:
    EditDebitCardTransactionImagesStateSchema,
})
