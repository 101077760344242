import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  description:
    'By providing your account details and agreeing to our Terms of Service you authorise us (Zeller Australia ABN 95 637 321 147, Debit User ID number 613572) to debit from the nominated account for the purposes outlined in the Terms of Service. This debit or charge will be arranged by Zeller’s financial institution and made through the Bulk Electronic Clearing System Framework (BECS) from your nominated account and will be subject to the Direct Debit Authority included in Part E of the Terms and Service.',
  submitButton: 'Add Account',
  errorNotification: 'Account could not be created. Please try again.',
  createAccountSuccessNotification: 'Account created successfully',
  createAndUpdateAccountSuccessNotification:
    'Account created and set as Settlement Account.',
})
