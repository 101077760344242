import { BREAKPOINT, H2 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { Button } from 'components/Buttons/Button'

// Prevent pass isActive to Button component
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledButton = styled(Button)`
  margin: 0;

  && {
    padding: 1rem;
  }
`
// NOTE: https://npco-dev.atlassian.net/browse/ZD-13352
// This component originally used PageHeaderBasic but was accidentally broken in
// an update. As a quick fix I've used the originally styling and removed the
// use of this component.
export const PageHeaderWrapper = styled.header`
  display: flex;
  flex-flow: row wrap;
  padding: 90px 0 16px;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    padding: 40px 0 32px;
  }
`

export const PageHeaderTitle = styled(H2)`
  margin: 0;
`
