import { gql } from '@apollo/client'

import { GetEntityDailyLimit as QueryData } from 'types/gql-types/GetEntityDailyLimit'

export const query = gql`
  query GetEntityDailyLimit($entityUuid: ID!) {
    getEntityDailyLimit(entityUuid: $entityUuid) {
      __typename
      id
      maximumLimit {
        value
      }
    }
  }
`

export const queryData = undefined as unknown as QueryData

export type { QueryData }
