import { gql, type TypedDocumentNode } from '@apollo/client'

export type SimSiteCellFragment = {
  __typename?: 'Sim'
  siteName: string | null
}

export const SimSiteCellFragmentDoc = gql`
  fragment SimSiteCellFragment on Sim {
    siteName
  }
` as unknown as TypedDocumentNode<SimSiteCellFragment, undefined>
