import {
  SkeletonTypographyBase,
  SkeletonTypographyBaseProps,
  TypographyTypes,
} from '@npco/zeller-design-system'

export const getSkeletonTypographyByType =
  (type: TypographyTypes) =>
  (
    props: Omit<
      SkeletonTypographyBaseProps,
      'type' | 'loaderBackgroundImageUrl'
    >
  ) =>
    <SkeletonTypographyBase type={type} {...props} />
