import { useMemo } from 'react'
import { currencyFormatter } from '@npco/component-mp-common'
import { useTranslations } from '@npco/utils-translations'

import { PieChartCenterContent } from '../../../PieChart/PieChartCenterContent/PieChartCenterContent'
import { useGetCategoryName } from '../../hooks/useGetCategoryName'
import { NetAmountWithOtherCategory } from '../../TransactionsSummary.types'
import { translations } from './CategoryPieChartText.i18n'
import { UseGetCategoryPieChartDataCashFlowCategoryNetAmountFragment as CategoryNetAmount } from './hooks/useGetCategoryPieChartData.generated'

interface CategoryPieChartCenterTextProps {
  activeNetAmount: NetAmountWithOtherCategory<CategoryNetAmount> | undefined
  totalAmount: number
  size: 'small' | 'large'
  transactionDirection: 'income' | 'expense'
}

export const CategoryPieChartText = ({
  activeNetAmount,
  totalAmount,
  size,
  transactionDirection,
}: CategoryPieChartCenterTextProps) => {
  const t = useTranslations(translations)
  const getCategoryName = useGetCategoryName()

  const title = useMemo(() => {
    if (activeNetAmount === undefined) {
      return t('allCategories')
    }

    return getCategoryName(activeNetAmount.category)
  }, [activeNetAmount, getCategoryName, t])

  const total = useMemo(() => {
    const value =
      activeNetAmount === undefined
        ? totalAmount
        : parseInt(activeNetAmount.total.value ?? 0, 10)

    return transactionDirection === 'expense' ? -value : value
  }, [activeNetAmount, totalAmount, transactionDirection])

  return (
    <PieChartCenterContent
      size={size}
      title={title}
      total={currencyFormatter(total)}
    />
  )
}
