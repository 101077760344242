import { matchPath, useLocation } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { ConnectionStatus, CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useProductTour } from 'features/StartupModals/hooks/useProductTour/useProductTour'
import { usePushAppModal } from 'features/StartupModals/PushAppModal/hooks/usePushAppModal'

import { ROOT } from 'const/routes'

import { UseStartupModalsConnectionFragment } from './useStartupModals.generated'

type UseStartupModalsProps = {
  xeroBankFeed: UseStartupModalsConnectionFragment | undefined
  xeroPaymentServices: UseStartupModalsConnectionFragment | undefined
}

export const useStartupModals = ({
  xeroBankFeed,
  xeroPaymentServices,
}: UseStartupModalsProps) => {
  const { userRole } = useLoggedInUser()

  const location = useLocation()

  const { shouldShowAdminWelcome } = useProductTour()

  const { shouldShowPushAppModal } = usePushAppModal()
  const isAdmin = userRole === CustomerRole.ADMIN

  const hasErrorInBankFeeds =
    xeroBankFeed?.status === ConnectionStatus.CONNECTED_WITH_ERROR
  const hasErrorInPaymentService =
    xeroPaymentServices?.status === ConnectionStatus.CONNECTED_WITH_ERROR

  const isInOverviewPath = Boolean(
    matchPath(`${ROOT.ORGS.ORG().OVERVIEW.path}/*`, location.pathname)
  )

  return {
    showSetupFlow: shouldShowAdminWelcome,
    showXeroErrorModal:
      isAdmin &&
      isInOverviewPath &&
      (hasErrorInBankFeeds || hasErrorInPaymentService),
    showPushAppModal: shouldShowPushAppModal,
  }
}

useStartupModals.fragments = {
  Connection: gql`
    fragment UseStartupModalsConnectionFragment on Connection {
      status
    }
  `,
  EntitySavingsAccountProduct: gql`
    fragment UseStartupModalsEntitySavingsAccountProductFragment on EntitySavingsAccountProduct {
      id
    }
  `,
}
