import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledSiteSelectWrapper = styled.div`
  grid-area: picker;
  width: 100%;
  display: none;
  min-width: 0;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`

export const StyledSitePerformanceBreakdownWrapper = styled(Flex)`
  flex-direction: column;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    min-height: 15.625rem;
  }
`

export const StyledSummaryWrapper = styled.div`
  width: 100%;
  align-self: center;
  grid-area: table;
  min-width: 0;
`

export const StyledChartWrapper = styled.div`
  grid-area: chart;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledPerformanceContentWrapper = styled(Flex)`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem 0;
  grid-template-areas:
    'chart'
    'table';

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding-top: 1rem;
    gap: 2rem 0;
    grid-template-areas:
      'picker'
      'chart'
      'table';
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'picker chart'
      'table chart';
    column-gap: 2.5rem;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    column-gap: 5rem;
  }
`

export const StyledContentWrapper = styled.div`
  min-height: 0rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-height: 17.5rem;
  }
`
