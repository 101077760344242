import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  SetPinLocationStateSchema,
  SetPinStage,
  SetPinState,
} from 'hooks/useGoToSetPinStage/useGoToSetPinStage.types'

const SET_PIN_STAGES: SetPinStage[] = ['SetPin']

export const useSetPinState = <T extends SetPinState>() => {
  const locationState = useLocationState(SetPinLocationStateSchema)

  const state = useMemo(() => {
    if (
      !locationState?.SetPinModal?.stage ||
      !SET_PIN_STAGES.includes(locationState.SetPinModal.stage)
    ) {
      return null
    }

    return locationState.SetPinModal as T
  }, [locationState?.SetPinModal])

  return { state }
}
