import { GetItemProps, PickerItemProps } from 'types/picker'
import { Checkbox } from 'components/Input/Checkbox'

import { StyledItem, StyledRow } from './Elements.styled'

interface StatusRowProps {
  status: PickerItemProps
  getItemProps: GetItemProps
  selectedItems: PickerItemProps[]
  disabled?: boolean
}

export const StatusRow: React.FC<StatusRowProps> = ({
  status,
  getItemProps,
  selectedItems,
  disabled = false,
}) => {
  return (
    <StyledRow as="li" noPadding withTopBorder>
      <StyledItem>
        <Checkbox
          type="checkbox"
          name={status.name}
          label={status.name}
          onChange={() => null}
          checked={selectedItems.includes(status)}
          {...getItemProps({ item: status, disabled })}
        />
      </StyledItem>
    </StyledRow>
  )
}
