import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { SavingsAccountLearnMoreLocationSchema } from '../../SavingsAccountLearnMore.types'

export const useSavingsAccountLearnMoreState = () => {
  const maybeState = useLocationState(SavingsAccountLearnMoreLocationSchema)

  const state = useMemo(() => {
    if (maybeState === undefined) {
      return null
    }

    return maybeState.SavingsAccountLearnMoreModal
  }, [maybeState])

  return { state }
}
