import { BodyDefaultTypography, Title } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDescription = styled(Title)`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledBoldText = styled.span`
  ${BodyDefaultTypography}
  font-weight: 600;
`
