import { EntityCategories } from '@npco/mp-gql-types'
import { PillBasic, PillSize } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'
import { getCategoryDisplay } from 'components/EditZellerCategories/Fields/utils/categories.utils'

interface Props {
  category: EntityCategories | null
}

export const CategoryItem = ({ category }: Props) => {
  const categoryDisplay = getCategoryDisplay(category)

  return (
    <DrawerItem
      label={translate('page.transactionContact.category')}
      value={
        <PillBasic
          size={PillSize.Small}
          text={categoryDisplay.text}
          icon={categoryDisplay.icon}
          editButtonText={translate('shared.edit')}
          closeIconAlt={translate('shared.close')}
        />
      }
    />
  )
}
