import { DataSmallTypography, H5 } from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

const afterStyle = css`
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 200%;
    height: 8px;
    left: 50%;
    bottom: 1px;
    transform: translateX(-50%);
    box-shadow: 0 0 5px 1px ${({ theme }) => rgba(theme.colors.BLACK, 0.2)};
    z-index: -1;
    clip-path: polygon(0 0, 100% 0%, 100% 200%, 0% 200%);
  }
`

export const StyledContainer = styled.div<{
  hasBorderBottom?: boolean
  hasShadowBottom?: boolean
}>`
  ${(props) => props.hasShadowBottom && 'position: relative;'}
  ${({ hasBorderBottom, hasShadowBottom, theme }) =>
    hasBorderBottom &&
    !hasShadowBottom &&
    `box-shadow: inset 0 -1px 0 ${rgba(theme.colors.GREY_150, 0.5)};`}
${(props) => props.hasShadowBottom && afterStyle}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.WHITE};
  z-index: 1;
  position: sticky;
  top: 0;
`

export const StyledMainItem = styled(H5)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  font-size: ${DataSmallTypography};
  margin: 0 0 0 8px;
`
