import { CreateSavingsAccountModal } from '@npco/mp-feature-create-savings-account'
import { CloseAccountModal } from 'features/CloseAccount'
import { CreateAccountModal } from 'features/CreateAccount'
import { EditDebitCardTransactionCategoryModal } from 'features/EditDebitCardTransactionCategory/edit-debit-card-transaction-category-modal'
import { EditDebitCardTransactionImagesModal } from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-modal'
import { EditDebitCardTransactionNotesModal } from 'features/EditDebitCardTransactionNotes/edit-debit-card-transaction-notes-modal/EditDebitCardTransactionNotesModal/EditDebitCardTransactionNotesModal'
import { EditSpendControlsModal } from 'features/EditSpendControls/edit-spend-controls-modal'
import { RedirectToMFA } from 'features/MFA'
import { SavingsAccountLearnMoreModal } from 'features/SavingsAccountLearnMore/savings-account-learn-more-modal'

import { ModalCloseTransitionContextProvider } from 'pages/GlobalModals/contexts/ModalCloseTransitionContext/ModalCloseTransitionContextProvider'

import { BlockedActionModal } from '../../features/BlockedAction/blocked-action-modal'
import { AddCardModal } from './AddCardModal/AddCardModal'
import { CancelCardModal } from './CancelCardModal/CancelCardModal'
import { CompleteCardModal } from './CompleteCardModal/CompleteCardModal'
import { EditAccountModal } from './EditAccountModal/EditAccountModal'
import { EditCardNameModal } from './EditCardNameModal/EditCardNameModal'
import { LockCardModal } from './LockCardModal/LockCardModal'
import { ReportLostAndReplaceCardModal } from './ReportLostAndReplaceCardModal/ReportLostAndReplaceCardModal'
import { ReportLostCardCompleteModal } from './ReportLostCardCompleteModal/ReportLostCardCompleteModal'
import { SetPinModal } from './SetPinModal/SetPinModal'
import { UpgradeAccountsCompleteModal } from './UpgradeAccountsCompleteModal/UpgradeAccountsCompleteModal'

export const GlobalModals = () => {
  return (
    <>
      <CreateAccountModal />
      <ModalCloseTransitionContextProvider>
        <AddCardModal />
      </ModalCloseTransitionContextProvider>
      <SetPinModal />
      <CompleteCardModal />
      <UpgradeAccountsCompleteModal />
      <LockCardModal />
      <CancelCardModal />
      <ReportLostAndReplaceCardModal />
      <ReportLostCardCompleteModal />
      <EditCardNameModal />
      <EditSpendControlsModal />
      <CloseAccountModal />
      <RedirectToMFA />
      <EditAccountModal />
      <EditDebitCardTransactionNotesModal />
      <EditDebitCardTransactionImagesModal />
      <EditDebitCardTransactionCategoryModal />
      <CreateSavingsAccountModal />
      <SavingsAccountLearnMoreModal />
      <BlockedActionModal />
    </>
  )
}
