import { translate } from 'utils/translations'

import { NoResultsForSearch } from '../NoResultsForSearch/NoResultsForSearch'

interface AccountNoResultsPageProps {
  searchInput: string
}

export const AccountNoResultsPage = ({
  searchInput,
}: AccountNoResultsPageProps) => {
  return (
    <NoResultsForSearch
      title={translate(
        'page.addCardModal.selectAccount.noResultsForSearchTitle',
        { searchText: searchInput }
      )}
      subtitle={translate(
        'page.addCardModal.selectAccount.noResultsForSearchSubtitle'
      )}
    />
  )
}
