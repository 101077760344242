import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

import { DashboardErrorPage } from './DashboardErrorPage'

export const StyledGlobalErrorPage = styled(DashboardErrorPage)`
  width: 100%;
  height: 100%;
  right: 50%;
  bottom: 50%;
  margin-top: 4rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-top: 0;
  }
`
