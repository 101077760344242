import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'You have no connected payment Devices',
  description:
    'Assign your device to a Site to get started. Once your device is assigned to a Site, it will be listed here.',
  cardHeading: 'Accept contactless payments with just your smartphone',
  point1: 'Take payments from Zeller App',
  point2: 'No hardware needed',
  point3: 'Sell in store or on the go',
})
