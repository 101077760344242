import { FocusEventHandler } from 'react'
import {
  COLOR,
  INPUT_SIZE,
  InputWithoutLabel,
  useInputFocus,
} from '@npco/zeller-design-system'
import { Box, Flex } from 'reflexbox'

import { component } from 'translations'

import { Clock } from './Clock'

interface TimeEntriesProps {
  fromTime: string
  toTime: string
  onFromTimeChange?: (newFromTime: string) => void
  onToTimeChange?: (newToTime: string) => void
  onPropsFromInputBlur?: FocusEventHandler<HTMLInputElement>
  onPropsToInputBlur?: FocusEventHandler<HTMLInputElement>
}

export const FOCUSED_FROM_PLACEHOLDER = '12:00 am'
export const FOCUSED_TO_PLACEHOLDER = '11:59 pm'

export const TimeEntries = ({
  fromTime,
  toTime,
  onFromTimeChange,
  onToTimeChange,
  onPropsFromInputBlur,
  onPropsToInputBlur,
}: TimeEntriesProps) => {
  const {
    onInputFocus: onFromInputFocus,
    onInputBlur: onFromInputBlur,
    isFocused: isFromFocused,
  } = useInputFocus({ onBlur: onPropsFromInputBlur })
  const {
    onInputFocus: onToInputFocus,
    onInputBlur: onToInputBlur,
    isFocused: isToFocused,
  } = useInputFocus({ onBlur: onPropsToInputBlur })

  const fromPlaceholder = isFromFocused
    ? FOCUSED_FROM_PLACEHOLDER
    : component.datePicker.startTime
  const toPlaceholder = isToFocused
    ? FOCUSED_TO_PLACEHOLDER
    : component.datePicker.endTime

  return (
    <Flex
      flexDirection={['column', 'row']}
      padding={['0 1.5rem 1rem', '1rem 1.5rem']}
      data-testid="time-inputs"
    >
      <Box mr={[0, '1rem']}>
        <InputWithoutLabel
          name="from-input"
          icon={
            <Clock
              defaultColor={
                fromTime || isFromFocused ? COLOR.BLUE_1000 : undefined
              }
            />
          }
          placeholder={fromPlaceholder}
          onFocus={onFromInputFocus}
          value={fromTime}
          onChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement
            onFromTimeChange?.(target.value)
          }}
          onBlur={onFromInputBlur}
          size={INPUT_SIZE.SMALL}
        />
      </Box>
      <Box ml={[0, '2rem']} mt={['0.5rem', 0]}>
        <InputWithoutLabel
          name="to-input"
          icon={
            <Clock
              defaultColor={
                fromTime || isFromFocused ? COLOR.BLUE_1000 : undefined
              }
            />
          }
          placeholder={toPlaceholder}
          onFocus={onToInputFocus}
          value={toTime}
          onChange={(e: React.SyntheticEvent) => {
            const target = e.target as HTMLInputElement
            onToTimeChange?.(target.value)
          }}
          onBlur={onToInputBlur}
          size={INPUT_SIZE.SMALL}
          defaultIconColor={toTime || isToFocused ? COLOR.BLUE_1000 : undefined}
        />
      </Box>
    </Flex>
  )
}
