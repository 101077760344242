import { useTranslations } from '@npco/utils-translations'
import {
  EnvelopeIcon,
  MobilePhoneIcon,
  useModalState,
} from '@npco/zeller-design-system'

import { EmailRemittanceModal } from '../TransferRemittancesModal/EmailRemittanceModal/EmailRemittanceModal'
import { SMSRemittanceModal } from '../TransferRemittancesModal/SMSRemittanceModal/SMSRemittanceModal'
import { PdfRemittanceButton } from './PdfRemittanceButton/PdfRemittanceButton'
import { transferRemittancesButtonsTranslations } from './TransferRemittancesButtons.i18n'
import * as styled from './TransferRemittancesButtons.styled'

const SMSIcon = () => <MobilePhoneIcon />
const EmailIcon = () => <EnvelopeIcon />

type TransferRemittancesButtonsProps = {
  isShowingDetails: boolean
  currentUserMobileNumber?: string
  currentUserEmail?: string
  transactionID: string
  contactEmail?: string
  contactPhone?: string
}

export const TransferRemittancesButtons = ({
  isShowingDetails,
  currentUserEmail,
  currentUserMobileNumber,
  transactionID,
  contactEmail,
  contactPhone,
}: TransferRemittancesButtonsProps) => {
  const t = useTranslations(transferRemittancesButtonsTranslations)
  const {
    openModal: openSMSModal,
    closeModal: closeSMSModal,
    isModalOpen: isSMSModalOpen,
  } = useModalState()

  const {
    openModal: openEmailModal,
    closeModal: closeEmailModal,
    isModalOpen: isEmailModalOpen,
  } = useModalState()
  return (
    <styled.IconWrapper $isShowingDetails={isShowingDetails}>
      <styled.ActionButtonWithIcon
        icon={SMSIcon}
        onClick={() => openSMSModal()}
      >
        {t('sms')}
      </styled.ActionButtonWithIcon>
      <styled.ActionButtonWithIcon
        icon={EmailIcon}
        onClick={() => openEmailModal()}
      >
        {t('email')}
      </styled.ActionButtonWithIcon>
      <PdfRemittanceButton dcaTransactionUuid={transactionID} />
      <SMSRemittanceModal
        isOpen={isSMSModalOpen}
        currentUserMobileNumber={currentUserMobileNumber ?? ''}
        closeModal={closeSMSModal}
        transactionID={transactionID}
        contactPhone={contactPhone}
      />
      <EmailRemittanceModal
        isOpen={isEmailModalOpen}
        closeModal={closeEmailModal}
        currentUserEmail={currentUserEmail ?? ''}
        transactionID={transactionID}
        contactEmail={contactEmail}
      />
    </styled.IconWrapper>
  )
}
