import { FileWithId } from 'components/File'

export enum UploadReceiptStage {
  DragAndDrop,
  Uploading,
  FailedUpload,
  Reuploading,
}

interface BaseUploadReceiptModalState {
  stage: UploadReceiptStage
}

interface DragAndDropStageState extends BaseUploadReceiptModalState {
  stage: UploadReceiptStage.DragAndDrop
  goToNextStep: (files: FileWithId[]) => void
}

interface UploadingStageState extends BaseUploadReceiptModalState {
  stage: UploadReceiptStage.Uploading
  filesToUpload: FileWithId[]
  goToNextStep: (failedFiles: FileWithId[]) => void
  uploadedFiles: FileWithId[]
}

interface FailedUploadStageState extends BaseUploadReceiptModalState {
  stage: UploadReceiptStage.FailedUpload
  uploadedFiles: FileWithId[]
  failedFiles: FileWithId[]
  onRetry: () => void
}

interface ReuploadingStageState extends BaseUploadReceiptModalState {
  stage: UploadReceiptStage.Reuploading
  failedFiles: FileWithId[]
  goToNextStep: (failedFiles: FileWithId[]) => void
  uploadedFiles: FileWithId[]
}

export type UploadReceiptModalState =
  | DragAndDropStageState
  | UploadingStageState
  | ReuploadingStageState
  | FailedUploadStageState
