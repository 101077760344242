import {
  AVATAR_BADGE_SIZE,
  AvatarBasic,
  COLOR,
  PosIcon,
} from '@npco/zeller-design-system'

import * as styled from './VenueAvatar.styled'

export const VenueAvatar = () => (
  <styled.Container>
    <AvatarBasic
      badgeSize={AVATAR_BADGE_SIZE.SMALL}
      text=""
      userDefinedContent={<PosIcon color={COLOR.GREY_700} />}
    />
  </styled.Container>
)
