import { useCallback } from 'react'
import {
  NavigateOptions,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat'

import { ContactTransferModalState } from '../../ContactTransferModal.types'

type OpenContactModalOptions = Omit<NavigateOptions, 'state'>

export const useGoToContactTransferModal = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const openContactTransferModal = useCallback(
    (state: ContactTransferModalState, options?: OpenContactModalOptions) => {
      navigate(location.pathname, {
        state: { ContactTransferModal: state },
        ...options,
      })
    },
    [location.pathname, navigate]
  )

  const closeContactTransferModal = useCallback(() => {
    navigate(location.pathname, { state: { ContactTransferModal: null } })
  }, [location.pathname, navigate])

  return { openContactTransferModal, closeContactTransferModal }
}
