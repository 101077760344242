import { FieldMergeFunction } from '@apollo/client'
import { InvoicesDefaultLocalState } from 'features/Invoicing'
import { CatalogueDefaultLocalState } from 'features/Items/components/Catalogue/localCacheState/CatalogueLocalCache.types'
import { CategoriesDefaultLocalState } from 'features/Items/components/Categories/Categories.constants'
import { ItemsReportsDefaultLocalState } from 'features/Items/components/Reports/Items.constants'
import { NotificationsDefaultLocalState } from 'features/Notifications/Notifications.constants'
import { VirtualTerminalDefaultLocalState } from 'features/VirtualTerminal/VirtualTerminal.constants'
import { flatten, mergeDeepRight, path, prop, uniq, uniqBy } from 'ramda'

import { OracleDefaultLocalState } from 'hooks/oraclePos/oraclePos.constants'
import { Subset } from 'types/utils'
import { ReportsDefaultLocalState } from 'pages/Reports/ReportsNew/ReportsNew.constants'
import { ApplicationDefaultLocalState } from 'components/App/App.constants'
import { TransactionsDefaultLocalState } from 'components/Filters/PillFilters/TransactionsFilters/TransactionsFilters.constants'

import { LocalState } from './cache.types'

interface ThirdArg {
  args: any
}

export const mergeEntities = (
  existingDefault: any,
  incomingDefault: any,
  { args }: ThirdArg
) => {
  const existing = existingDefault ?? []
  const incoming = incomingDefault ?? {}

  if (!args?.nextToken) {
    return [incoming]
  }

  return [...existing, incoming]
}

interface MergeWithPaginationProps {
  entityKey: string
  uniquenessId?: string
  nextTokenPath?: string[]
}

export function mergeWithPagination({
  entityKey,
  nextTokenPath = ['nextToken'],
  uniquenessId,
}: MergeWithPaginationProps): FieldMergeFunction<any, any, any> {
  return function merge(existing, incoming, { args }) {
    // NOTE: when no existing cache, or when existing cache but args with no
    // next token we effectively want to overwrite our current cache as we
    // aren't paginating so return incoming only
    if (!existing || !path(nextTokenPath, args)) {
      return incoming
    }

    const mergedValues = [
      ...(existing?.[entityKey] || []),
      ...(incoming?.[entityKey] || []),
    ]

    const uniqueValues = uniquenessId
      ? uniqBy(prop(uniquenessId), mergedValues)
      : uniq(mergedValues)

    return {
      ...existing,
      ...incoming,
      [entityKey]: uniqueValues,
    }
  }
}

export const paginationReadUtil =
  (entityKey: string, uniquenessId?: string) => (existing: any) => {
    if (!existing || existing?.length === 0) {
      return undefined
    }

    const allEntityPages = flatten(existing.map((page: any) => page[entityKey]))

    return {
      [entityKey]: uniquenessId
        ? uniqBy(prop(uniquenessId), allEntityPages)
        : allEntityPages,
      nextToken: existing[existing.length - 1].nextToken,
    }
  }

const localStateDefaults: LocalState = {
  application: ApplicationDefaultLocalState,
  catalogue: CatalogueDefaultLocalState,
  categories: CategoriesDefaultLocalState,
  invoices: InvoicesDefaultLocalState,
  itemsReports: ItemsReportsDefaultLocalState,
  notifications: NotificationsDefaultLocalState,
  oraclePos: OracleDefaultLocalState,
  reports: ReportsDefaultLocalState,
  transactions: TransactionsDefaultLocalState,
  virtualTerminal: VirtualTerminalDefaultLocalState,
  contactTransactions: TransactionsDefaultLocalState,
}

export const readLocalState = (value?: Subset<LocalState>) =>
  value || localStateDefaults

export const mergeLocalState = (
  existing: Subset<LocalState> | undefined,
  incoming: Subset<LocalState>
) => mergeDeepRight(existing || localStateDefaults, incoming)
