import { EntityCategories } from '@npco/mp-gql-types'

import { translate } from 'utils/translations'
import { GetSubcategories_getEntitySubcategories as SubcategoryDetails } from 'types/gql-types/GetSubcategories'
import { CategoryCheckbox } from 'components/EditCategoriesModal/Fields/CategoryCheckbox'
import { EditZellerCategories } from 'components/EditZellerCategories/EditZellerCategories'
import { NewSubcategory } from 'components/EditZellerCategories/EditZellerCategories.types'

import { ContactEntityCategoryValues } from '../CategoryPills.types'

interface Props {
  isModalOpen: boolean
  category: EntityCategories | null
  subcategoryDetails: SubcategoryDetails | null
  onSaveCategory: (values: ContactEntityCategoryValues) => void
  onCancel: () => void
  onEditContactSubcategory?: (values: ContactEntityCategoryValues) => void
  onDeleteContactSubcategory?: (
    values: ContactEntityCategoryValues & NewSubcategory
  ) => void
  shouldShowApplyAll?: boolean
  isSavingCategories?: boolean
}

export const EditContactCategories = ({
  isModalOpen,
  category,
  subcategoryDetails,
  onSaveCategory,
  onCancel,
  onEditContactSubcategory,
  onDeleteContactSubcategory,
  shouldShowApplyAll = false,
  isSavingCategories = false,
}: Props) => {
  return (
    <EditZellerCategories
      isOpen={isModalOpen}
      initialValues={{
        category,
        subcategory: subcategoryDetails?.name,
        subcategoryId: subcategoryDetails?.id,
        applyAll: false,
      }}
      onSave={onSaveCategory}
      onCancel={onCancel}
      onEditSubcategory={onEditContactSubcategory}
      onDeleteSubcategory={onDeleteContactSubcategory}
      isSavingCategories={isSavingCategories}
    >
      {shouldShowApplyAll && (
        <CategoryCheckbox
          name="applyAll"
          label={translate('page.contacts.category.applyAllLabel')}
        />
      )}
    </EditZellerCategories>
  )
}
