import { useReactiveVar } from '@apollo/client'
import { H6 } from '@npco/zeller-design-system'
import { rvSelectedDates } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { currencyFormatter } from 'utils/common'
import dayjs from 'utils/dayjs'

import { StyledHeader } from '../../ReportsChart.styled'
import { getFormattedDateRange } from './TooltipContent.utils'

interface Props {
  label: number
  value: number
}

type TooltipProps = {
  from?: Date
  isSelectionMoreThenTwoMonths: boolean
  label: number
  to?: Date
  value: number
}

const Tooltip = ({
  label,
  value,
  from,
  to,
  isSelectionMoreThenTwoMonths,
}: TooltipProps) => {
  return (
    <>
      <StyledHeader>
        {getFormattedDateRange({
          from,
          to,
          label,
          isSelectionMoreThenTwoMonths,
        })}
      </StyledHeader>
      <H6 as="div">{currencyFormatter(value)}</H6>
    </>
  )
}

export const TooltipContentWithDateRange = ({ label, value }: Props) => {
  const { from, to } = useReactiveVar(rvSelectedDates)
  const isSelectionMoreThenTwoMonths =
    dayjs(to).diff(dayjs(from), 'months') >= 2

  return (
    <Tooltip
      from={from}
      isSelectionMoreThenTwoMonths={isSelectionMoreThenTwoMonths}
      label={label}
      to={to}
      value={value}
    />
  )
}

export const TooltipContent =
  (isSelectionMoreThenTwoMonths: boolean) =>
  ({ label, value }: Props) => {
    return (
      <Tooltip
        isSelectionMoreThenTwoMonths={isSelectionMoreThenTwoMonths}
        label={label}
        value={value}
      />
    )
  }
