import { INPUT_SIZE, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'

// This getInputAdaptiveSizeFromTextInputSize is deprecated - use INPUT_SIZE directly
export const getInputAdaptiveSizeFromTextInputSize = (
  size?: TEXT_INPUT_SIZE
) => {
  if (!size) {
    return undefined
  }
  const sizeMap = {
    [TEXT_INPUT_SIZE.XLARGE]: INPUT_SIZE.XLARGE,
    [TEXT_INPUT_SIZE.LARGE]: INPUT_SIZE.LARGE,
    [TEXT_INPUT_SIZE.MEDIUM]: INPUT_SIZE.MEDIUM,
    [TEXT_INPUT_SIZE.SMALL]: INPUT_SIZE.SMALL,
    [TEXT_INPUT_SIZE.XSMALL]: INPUT_SIZE.SMALL,
    [TEXT_INPUT_SIZE.DEFAULT]: INPUT_SIZE.MEDIUM,
  }
  return sizeMap[size]
}
