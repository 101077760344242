import { gql } from '@apollo/client'

export const GetSidebarLocalState = gql`
  query GetSidebarLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      application {
        sidebar {
          isFolded
          itemExpanded
        }
      }
    }
  }
`
