import { PaymentInstrumentType } from '@npco/mp-gql-types'

export enum ContactType {
  Person = 'Person',
  Business = 'Business',
}

export type ContactCreatedPayload = {
  Type: ContactType
  Linked: boolean
  Multi: boolean
  Location: string
}

export type ContactDeletedPayload = {
  Type: ContactType
}

export type ContactLinkedContactPayload = {
  Type: ContactType
}

export type ContactUnlinkedContactPayload = {
  Type: ContactType
}

export enum PaymentInstrumentSource {
  Linked = 'Linked',
  Unlinked = 'Unlinked',
  New = 'New',
}

export type ContactAttachedPaymentInstrumentPayload = {
  Type: PaymentInstrumentType
  InstrumentSource: PaymentInstrumentSource
  Location: string
}

export type ContactUnlinkedPaymentInstrumentPayload = {
  Type: PaymentInstrumentType
}

export type ContactArchivedPaymentInstrumentPayload = {
  Type: PaymentInstrumentType
}

export type ContactLinkedCardholderPayload = {
  Location: string
}

export type ContactUnlinkedCardholderPayload = {
  Location: string
}
