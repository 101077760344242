import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const Container = styled.div`
  flex: 1 1 auto;
  width: 100%;
  flex-flow: column;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    justify-content: initial;
    max-width: 36rem;
  }
`

export const Description = styled(BodyDefault)`
  margin-top: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.GREY_550};
  width: 100%;

  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    margin-bottom: 2.25rem;
  }
`

export const Subtitle = styled(BodyDefault)`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.GREY_550};
`
