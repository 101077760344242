import { ApolloCache, gql } from '@apollo/client'
import { DebitCardStatus, VelocityWindowEnum } from '@npco/mp-gql-types'

import { TestUpdateCardFragment as TestUpdateCardFragmentType } from './cardCacheHelpers.generated'

export const TestUpdateCardFragment = gql`
  fragment TestUpdateCardFragment on DebitCardV2 {
    id
    name
    velocityControl {
      resetsAt
      velocityWindow
      availableAmount {
        value
      }
      amountLimit {
        value
      }
      totalSpentAmount {
        value
      }
      maxTransactionValue {
        value
      }
      modifiedBy {
        actingCustomerUuid
        updatedAt
      }
      isPendingSubscriptionUpdate @client
    }
    status
  }
`

const initialCard: TestUpdateCardFragmentType & { __typename: 'DebitCardV2' } =
  {
    __typename: 'DebitCardV2',
    id: 'placeholder',
    name: 'Old name',
    status: DebitCardStatus.EXPIRED,
    velocityControl: {
      resetsAt: '2023-10-01T00:00:00+0000',
      velocityWindow: VelocityWindowEnum.MONTH,
      availableAmount: {
        value: '123456',
      },
      amountLimit: {
        value: '654321',
      },
      totalSpentAmount: {
        value: '530865',
      },
      maxTransactionValue: {
        value: '2500000',
      },
      modifiedBy: {
        actingCustomerUuid: 'd794e9ca-8d2f-4855-9063-3c3a5d62ad5c',
        updatedAt: '2023-09-27T00:00:00+0000',
      },
      isPendingSubscriptionUpdate: false,
    },
  }

type CreateCardCacheRecordOptions = {
  id: string
  overwritingData?: Partial<typeof initialCard>
}

export const createCardCacheRecord = (
  cache: ApolloCache<any>,
  { id, overwritingData }: CreateCardCacheRecordOptions
) => ({
  [cache.identify({ id, __typename: 'DebitCardV2' }) as string]: {
    ...initialCard,
    ...overwritingData,
    id,
  },
})

type GetCachedRecordOptions = { id: string }

export const getCachedCardRecord = (
  cache: ApolloCache<any>,
  { id }: GetCachedRecordOptions
) => {
  return cache.readFragment<TestUpdateCardFragmentType>({
    fragment: TestUpdateCardFragment,
    id: cache.identify({ __typename: 'DebitCardV2', id }),
  })
}
