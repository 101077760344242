import {
  BodyLargeTypography,
  InterZellerMediumFont,
  TextInputPrefix,
} from '@npco/zeller-design-system'
import {
  StyledSelectIcon,
  StyledSelectTrigger,
} from 'design-system/Components/Select'
import styled from 'styled-components/macro'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledPageContent } from 'layouts/SettingsPageLayout/SettingsPageLayout.styled'
import {
  ActionButtons,
  StyledCloseButton,
  StyledNextButton,
} from 'components/Buttons/ActionButtonsGroup/ActionButtonsGroup.styled'
import { StyledButtonGhostBack } from 'components/Buttons/ButtonBack/ButtonBack.styled'

export const StyledLabel = styled.label`
  ${BodyLargeTypography};
  ${InterZellerMediumFont};
  display: inline-block;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledLabelFrom = styled((props) => {
  return <StyledLabel {...props} />
})`
  margin-bottom: 1.5rem;
`

export const StyledDropdownWrapper = styled.div`
  min-height: 6.9rem;
  padding-bottom: 2.5rem;
  margin: 0;

  ${StyledSelectTrigger} {
    transition: border-color 0.3s ease-out;
  }

  ${StyledSelectIcon} {
    transition: opacity 0.3s ease-out;
  }
`

export const StyledDropdownToWrapper = styled(StyledDropdownWrapper)`
  min-height: auto;
  padding-bottom: 0;
`

export const StyledRecipientWrapper = styled.div`
  padding-bottom: 16px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding-bottom: 40px;
  }
`

export const StyledContent = styled.div`
  flex: 1;
  padding: 0 1rem 1rem 0;
  max-width: 31.5rem;
`

export const StyledTransferLayout = styled(StyledPageContent)`
  padding: 0 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex: 1;

  ${TextInputPrefix} {
    color: ${({ theme }) => theme.colors.BLACK_900};
    margin-right: 0.25rem;
  }

  .empty ${TextInputPrefix} {
    color: ${({ theme }) => theme.colors.GREY_550};
  }

  ${ActionButtons} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;

    ${StyledNextButton} {
      margin: 0 auto 0.5rem;
      flex: 0 1 100%;

      &:active,
      &:focus {
        background-color: ${({ theme }) => theme.colors.BLUE_1000};
      }
    }
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    padding-left: 0;
    width: 100%;
    ${ActionButtons} {
      align-items: center;
      justify-content: center;
      ${StyledButtonGhostBack}, ${StyledNextButton}, ${StyledCloseButton} {
        max-width: 14.25rem;
        min-width: 0;
        flex: 0 1 100%;
        margin: 0 0.5rem 0.5rem;
      }
    }
  }
`

export const StyledSpinnerWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
