export const TAX_INCLUSIVE_RATE = 1.1
export const TAX_EXCLUSIVE_RATE = 1

export const getTaxRate = (
  isTaxApplicable: boolean,
  isTaxInclusive: boolean
) => {
  return isTaxApplicable && isTaxInclusive
    ? TAX_INCLUSIVE_RATE
    : TAX_EXCLUSIVE_RATE
}
