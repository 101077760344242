import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetDeviceScreenSettings } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/devices'

import {
  GetDeviceScreenSettings as GetDeviceScreenSettingsType,
  GetDeviceScreenSettings_getDeviceSettings_screen as ScreenSettingsType,
  GetDeviceScreenSettingsVariables,
} from 'types/gql-types/GetDeviceScreenSettings'

export const useGetDeviceScreenSettings = (terminalUuid: string) => {
  const entityUuid = useSelectedEntityUuid()
  const { loading, error, data, refetch, networkStatus } = useQuery<
    GetDeviceScreenSettingsType,
    GetDeviceScreenSettingsVariables
  >(GetDeviceScreenSettings, {
    fetchPolicy: 'network-only',
    variables: {
      entityUuid,
      deviceUuid: terminalUuid,
    },
    notifyOnNetworkStatusChange: true,
  })

  const screenSettings: ScreenSettingsType | null | undefined = useMemo(
    () => data?.getDeviceSettings.screen,
    [data]
  )

  return {
    error,
    isLoading: loading || networkStatus === NetworkStatus.refetch,
    screenSettings,
    refetchScreenSettings: refetch,
  }
}
