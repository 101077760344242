import { translate } from 'utils/translations'
import { TOTAL_LINK_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { NotificationContent } from 'pages/GlobalModals/AddCardModal/pages/shared/NotificationContent/NotificationContent'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useLinkEMLCardInfoStage } from './hooks/useLinkEMLCardInfoStageContent'

export const LinkEMLCardInfoStageContent = () => {
  useMultiStageModalProgressBar({
    newCurrentStep: 2,
    newTotalSteps: TOTAL_LINK_CARD_STAGES,
  })
  const { createNewCard, handleDismiss } = useLinkEMLCardInfoStage()
  return (
    <NotificationContent
      title={translate('page.addCardModal.LinkEMLCardInfo.title')}
      content1={translate('page.addCardModal.LinkEMLCardInfo.content1')}
      content2={translate('page.addCardModal.LinkEMLCardInfo.content2')}
      nextButtonLabel={translate(
        'page.addCardModal.LinkEMLCardInfo.nextButtonLabel'
      )}
      backButtonLabel={translate(
        'page.addCardModal.LinkEMLCardInfo.backButtonLabel'
      )}
      handleAction={createNewCard}
      handleDismiss={handleDismiss}
    />
  )
}
