import { COLOR } from '@npco/zeller-design-system'
import { Pie, PieChart } from 'recharts'

import { PIE_CHART_SIZES } from './PieChart.type'

const PieChartLoadingDeps = {
  Pie,
  PieChart,
}

export { PieChartLoadingDeps }

interface PieChartLoadingProps {
  size?: 'large' | 'small'
}

export const PieChartLoading = ({ size = 'large' }: PieChartLoadingProps) => {
  return (
    <PieChart
      width={PIE_CHART_SIZES[size].width}
      height={PIE_CHART_SIZES[size].height}
      aria-label="pie-chart-loading"
    >
      <Pie
        dataKey="percentage"
        innerRadius="80%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        isAnimationActive={false}
        stroke="none"
        data={[
          {
            fill: COLOR.GREY_90,
            name: 'loading',
            percentage: 100,
          },
        ]}
      />
    </PieChart>
  )
}
