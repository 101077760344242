import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  downloadPdf: 'Download PDF',
  draft: 'Draft',
  preview: 'Preview',
  send: 'Send',
  schedule: 'Schedule',
  settings: 'Settings',
  moreOptions: 'More options',
})
