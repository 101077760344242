import { useLayoutEffect, useRef, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { useIsMobileResolution } from '@npco/zeller-design-system'
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light'

import PaperPlaneBehindModal from 'assets/animations/transfer/paper-plane-behind-modal.json'
import PaperPlaneExit from 'assets/animations/transfer/paper-plane-exit-with-shadow.json'
import PaperPlaneMobile from 'assets/animations/transfer/paper-plane-mobile.json'
import PaperPlaneRelease from 'assets/animations/transfer/paper-plane-release.json'
import Tick from 'assets/animations/transfer/tick.json'

import { transferConfirmationHeaderTranslations } from '../TransferConfirmationModal/TransferConfirmationHeader/TransferConfirmationHeader.i18n'
import {
  AnimationAnchor,
  TickAnimationAnchor,
} from './TransferCompleteAnimation.styled'

export type TransferCompleteAnimationProps = {
  modalMinHeight: number
  behindModalRef: React.RefObject<HTMLDivElement>
  isAnimationComplete: boolean
  setIsAnimationComplete: (isAnimationComplete: boolean) => void
}

export const TransferCompleteAnimation = ({
  modalMinHeight,
  behindModalRef,
  isAnimationComplete,
  setIsAnimationComplete,
}: TransferCompleteAnimationProps) => {
  const paperPlaneRef = useRef<HTMLDivElement>(null)
  const tickRef = useRef<HTMLOutputElement>(null)

  const [currentPaperPlaneAnimation, setCurrentPaperPlaneAnimation] =
    useState<AnimationItem | null>(null)
  const [currentBehindModalAnimation, setCurrentBehindModalAnimation] =
    useState<AnimationItem | null>(null)
  const [currentTickAnimation, setCurrentTickAnimation] =
    useState<AnimationItem | null>(null)

  const isMobile = useIsMobileResolution()
  const t = useTranslations(transferConfirmationHeaderTranslations)

  useLayoutEffect(() => {
    const loadTickAnimation = (animationItem: AnimationItem) => {
      animationItem.addEventListener('complete', () => {
        animationItem.destroy()

        const tickAnimation = lottie.loadAnimation({
          container: tickRef.current as Element,
          animationData: Tick,
          loop: false,
        })

        setCurrentTickAnimation(tickAnimation)

        setIsAnimationComplete(true)
      })
    }

    const loadExitAnimation = (animationItem: AnimationItem) => {
      animationItem.addEventListener('complete', () => {
        animationItem.destroy()

        const exitAnimation = lottie.loadAnimation({
          container: paperPlaneRef.current as Element,
          animationData: PaperPlaneExit,
          loop: false,
        })

        setCurrentPaperPlaneAnimation(exitAnimation)

        loadTickAnimation(exitAnimation)
      })
    }

    const loadBehindModalAnimation = (animationItem: AnimationItem) => {
      animationItem.addEventListener('complete', () => {
        animationItem.destroy()
        const behindModalAnimation = lottie.loadAnimation({
          container: behindModalRef.current as Element,
          animationData: PaperPlaneBehindModal,
          loop: false,
        })

        setCurrentBehindModalAnimation(behindModalAnimation)

        loadExitAnimation(behindModalAnimation)
      })
    }

    const loadFullAnimation = () => {
      const releaseAnimation = lottie.loadAnimation({
        container: paperPlaneRef.current as Element,
        animationData: PaperPlaneRelease,
        loop: false,
      })

      setCurrentPaperPlaneAnimation(releaseAnimation)

      loadBehindModalAnimation(releaseAnimation)
    }

    const loadPartialAnimation = () => {
      const tickAnimation = lottie.loadAnimation({
        container: tickRef.current as Element,
        animationData: Tick,
        loop: false,
      })

      setCurrentTickAnimation(tickAnimation)
    }

    const loadMobileAnimation = () => {
      const mobileAnimation = lottie.loadAnimation({
        container: paperPlaneRef.current as Element,
        animationData: PaperPlaneMobile,
        loop: false,
      })

      setCurrentPaperPlaneAnimation(mobileAnimation)

      loadTickAnimation(mobileAnimation)
    }

    const loadAnimation = () => {
      if (isAnimationComplete) {
        loadPartialAnimation()
        return
      }
      if (isMobile) {
        loadMobileAnimation()
        return
      }
      loadFullAnimation()
    }

    loadAnimation()

    return () => {
      currentPaperPlaneAnimation?.destroy()
      currentBehindModalAnimation?.destroy()
      currentTickAnimation?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  return (
    <>
      {!isAnimationComplete && (
        <AnimationAnchor $modalMinHeight={modalMinHeight} ref={paperPlaneRef} />
      )}
      <TickAnimationAnchor ref={tickRef} aria-label={t('title')} />
    </>
  )
}
