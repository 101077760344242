import { Box, Flex } from '@npco/zeller-design-system'

import * as styled from '../TransactionHeader.styled'

type TitleProps = {
  title: React.ReactNode
  pill?: React.ReactNode
  byline?: React.ReactNode
  rightAlignedContent?: React.ReactNode
}

export const Title = ({
  title,
  pill,
  byline,
  rightAlignedContent,
}: TitleProps) => {
  return (
    <Flex flexGrow={1} alignItems="center">
      <Flex justifyContent="space-between" gap="16px" width="100%">
        <Flex flexDirection="column" flexGrow={1}>
          <Box>
            <styled.Title>{title}</styled.Title>
            {pill}
          </Box>
          {byline}
        </Flex>
        {rightAlignedContent}
      </Flex>
    </Flex>
  )
}
