import { SyntheticEvent } from 'react'

import { currencyFormatter } from 'utils/common'
import {
  StyledAmountInput,
  StyledAmountInputWrapper,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.styled'

interface InputManualRangeProps {
  placeholder?: string
  value: string
  onChange: (e: SyntheticEvent) => void
  onBlur?: (e: SyntheticEvent) => void
}

export const InputManualRange = ({
  placeholder,
  onChange,
  value,
  onBlur,
}: InputManualRangeProps) => (
  <StyledAmountInputWrapper>
    <StyledAmountInput
      data-testid="amount-input"
      placeholder={
        placeholder
          ? currencyFormatter(parseInt(placeholder, 10)).slice(0, -3)
          : undefined
      }
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  </StyledAmountInputWrapper>
)
