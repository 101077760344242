import { useCallback } from 'react'

import { useReadUpdatedOutstandingTransactionsLocalCache } from './useReadUpdatedOutstandingTransactionsLocalCache'
import { filterOutdatedTransactions } from './useUpdatedOutstandingTransactions.utils'
import { useWriteUpdatedOutstandingTransactionsLocalCache } from './useWriteUpdatedOutstandingTransactionsLocalCache'

export const useCleanOutdatedOutstandingTransactions = () => {
  const { updatedOutstandingTransactions } =
    useReadUpdatedOutstandingTransactionsLocalCache()
  const { updateCache } = useWriteUpdatedOutstandingTransactionsLocalCache()

  const cleanOutdatedOutstandingTransactions = useCallback(() => {
    updateCache(filterOutdatedTransactions(updatedOutstandingTransactions))
  }, [updatedOutstandingTransactions, updateCache])

  return {
    cleanOutdatedOutstandingTransactions,
  }
}
