import { Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'

import { BiometricsOnfidoPage } from './BiometricsOnfidoPage'
import { BiometricsPage } from './BiometricsPage'

export const BiometricsRoutes = () => {
  return (
    <Routes>
      <Route index element={<BiometricsPage />} />
      <Route
        path={ROOT.ORGS.ORG().BIOMETRICS.ONFIDO.relative}
        element={<BiometricsOnfidoPage />}
      />
    </Routes>
  )
}
