import { COLOR } from '@npco/zeller-design-system'

import { parseApiMoney } from 'utils/parseMoney'
import { GetDebitCardAccounts_getDebitCardAccountsV2_accounts as ApiAccount } from 'types/gql-types/GetDebitCardAccounts'

const DEFAULT_ACCOUNT_COLOR = COLOR.BLUE_80

export const mapToDebitAccount = (apiAccount: ApiAccount) => ({
  id: apiAccount.id,
  name: apiAccount.name,
  balance: {
    currency: apiAccount.balance?.currency,
    value: apiAccount.balance ? parseApiMoney(apiAccount.balance.value) : 0,
  },
  icon: {
    colour: apiAccount.icon?.colour ?? DEFAULT_ACCOUNT_COLOR,
    letter: apiAccount.icon?.letter || apiAccount.name.charAt(0),
    images: [],
  },
  status: apiAccount.status,
  cardsCount: {
    total: apiAccount?.cardsCount?.total,
    debit: apiAccount?.cardsCount?.debit,
    expense: apiAccount?.cardsCount?.expense ?? 0,
  },
  type: apiAccount.type,
})

export const mapToDebitAccounts = (apiAccounts: (ApiAccount | null)[]) => {
  return apiAccounts
    .filter((maybeAccount: ApiAccount | null): maybeAccount is ApiAccount =>
      Boolean(maybeAccount)
    )
    .map(mapToDebitAccount)
}

interface Values {
  accountName: string
  colorPicked?: string
}

export const getIconFromValues = (values: Values) => {
  const { accountName, colorPicked } = values
  const firstLetter = accountName.charAt(0).toUpperCase()
  const selectedColor =
    Object.keys(COLOR).find(
      (colorName) => COLOR[colorName as keyof typeof COLOR] === colorPicked
    ) || 'GREY_250'

  return {
    colour: selectedColor,
    image: null,
    letter: firstLetter,
  }
}
