import { gql, type TypedDocumentNode } from '@apollo/client'

import { AccountBalanceAreaChartDebitCardAccountBalanceFragmentDoc } from './AccountBalanceAreaChart/AccountBalanceAreaChart.generated'

export type AccountBalanceChartViewDebitCardAccountBalanceFragment = {
  __typename: 'DebitCardAccountBalance'
  date: any
  balance: { __typename?: 'Money'; value: string }
}

export const AccountBalanceChartViewDebitCardAccountBalanceFragmentDoc = gql`
  fragment AccountBalanceChartViewDebitCardAccountBalanceFragment on DebitCardAccountBalance {
    ...AccountBalanceAreaChartDebitCardAccountBalanceFragment
  }
  ${AccountBalanceAreaChartDebitCardAccountBalanceFragmentDoc}
` as unknown as TypedDocumentNode<
  AccountBalanceChartViewDebitCardAccountBalanceFragment,
  undefined
>
