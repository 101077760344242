import { useReactiveVar } from '@apollo/client'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { translate } from 'utils/translations'
import { SiteCache } from 'types/site'
import { ModalUploadImage } from 'components/ModalUploadImage/ModalUploadImage'
import { useLogoUploader } from 'components/ModalUploadLogo/hooks/useLogoUploader'

interface UploadProps {
  logo?: string
  closeModal: () => void
  setTemporaryLogo: React.Dispatch<React.SetStateAction<string>>
}

export const ModalUploadLogo = ({
  logo,
  closeModal,
  setTemporaryLogo,
}: UploadProps) => {
  const data = useReactiveVar(rvSiteDetails) as SiteCache
  const previousImageUrl = logo || data?.receipt?.logo

  const {
    onDrop,
    uploadFile,
    previewUrl,
    isPreviewLoading,
    isPreviewError,
    isProcessing,
  } = useLogoUploader({
    closeModal,
    id: data.id,
    previousImageUrl,
    setTemporaryImage: setTemporaryLogo,
  })

  return (
    <ModalUploadImage
      description={translate('component.companyLogoUpload.uploadLogoCopy')}
      imageUrl={previewUrl}
      isProcessing={isProcessing}
      isPreviewLoading={isPreviewLoading}
      isPreviewError={isPreviewError}
      isSaveDisabled={isPreviewLoading || previewUrl === previousImageUrl}
      onDrop={onDrop}
      primaryButtonLabel={translate('page.settings.receipt.done')}
      secondaryButtonLabel={translate(
        'component.modal.uploadImage.changeImage'
      )}
      uploadFile={uploadFile}
    />
  )
}
