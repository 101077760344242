import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type NewSavingsAccountFragment = {
  __typename?: 'DebitCardAccountV2'
  id: string
  type: Types.DebitCardAccountType
  name: string
  status: Types.DebitCardAccountStatus
  balance: { __typename?: 'Money'; currency: string; value: string } | null
  cardsCount: {
    __typename?: 'DebitCardAccountV2CardsCount'
    total: number
    debit: number
    expense: number | null
  }
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    image: string | null
    animation: string | null
    images: Array<{
      __typename?: 'Image'
      size: Types.ImageSize
      url: string
    }> | null
  } | null
  savingsAccountDetails: {
    __typename?: 'SavingsAccountDetails'
    effectiveInterestRate: string | null
  } | null
  accountDetails: {
    __typename?: 'BankAccountDetails'
    account: string | null
  } | null
}

export const NewSavingsAccountFragmentDoc = gql`
  fragment NewSavingsAccountFragment on DebitCardAccountV2 {
    id
    type
    name
    status
    balance {
      currency
      value
    }
    cardsCount {
      total
      debit
      expense
    }
    icon {
      colour
      letter
      image
      images {
        size
        url
      }
      animation
    }
    savingsAccountDetails {
      effectiveInterestRate
    }
    accountDetails {
      account
    }
  }
` as unknown as TypedDocumentNode<NewSavingsAccountFragment, undefined>
