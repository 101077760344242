import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { CorporateCardSettingsWalkthroughLocationSchema } from '../../CorporateCardSettingsWalkthrough.types'

export const useCorporateCardSettingsWalkthroughState = () => {
  const locationState = useLocationState(
    CorporateCardSettingsWalkthroughLocationSchema
  )

  const state = useMemo(() => {
    if (!locationState) {
      return null
    }

    return locationState.CorporateCardSettingsWalkthroughModal
  }, [locationState])

  return { state }
}
