import {
  AVATAR_BADGE_SIZE,
  AvatarBasic,
  COLOR,
  FileLetterheadIcon,
} from '@npco/zeller-design-system'

export const AccountStatementAvatar = () => {
  return (
    <AvatarBasic
      badgeSize={AVATAR_BADGE_SIZE.DEFAULT}
      text=""
      userDefinedContent={<FileLetterheadIcon color={COLOR.GREY_700} />}
      svgBackground={COLOR.GREY_90}
      withCursorPointer
    />
  )
}
