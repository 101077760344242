import { useCallback } from 'react'
import { useFormikContext } from 'formik'

import { TransferContactFields } from '../../ContactTransfer.types'

const TO_PAYMENT_INSTRUMENT = 'toPaymentInstrument'

export const useUpdateToPaymentInstrument = () => {
  const { setFieldValue } = useFormikContext<TransferContactFields>()

  const updateToPaymentInstrument = useCallback(
    (toPaymentInstrument: TransferContactFields['toPaymentInstrument']) => {
      setFieldValue(TO_PAYMENT_INSTRUMENT, toPaymentInstrument)
    },
    [setFieldValue]
  )

  return { updateToPaymentInstrument }
}
