import { Flex } from '@npco/zeller-design-system'
import { useField } from 'formik'

import { validateRequired } from 'utils/formValidation'

import { LandingPageOption } from '../LandingPageForm.types'
import { CreateCardOption } from './CreateCardOption'
import { LinkCardOption } from './LinkCardOption'

export const LinkOrCreateRadioField = () => {
  const [field, , handlers] = useField({
    name: 'linkOrCreate',
    validate: validateRequired,
  })

  return (
    <Flex flexDirection="column" mt="12px">
      <LinkCardOption
        onChange={() => handlers.setValue(LandingPageOption.LinkCard)}
        isChecked={field.value === LandingPageOption.LinkCard}
        name="linkOrCreate"
      />
      <CreateCardOption
        onChange={() => handlers.setValue(LandingPageOption.CreateCard)}
        isChecked={field.value === LandingPageOption.CreateCard}
        name="linkOrCreate"
      />
    </Flex>
  )
}
