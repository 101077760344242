import { useEffect, useState } from 'react'

import { useRestartableTimer } from 'hooks/useRestartableTimer/useRestartableTimer'

const SUBSCRIPTION_TIMEOUT = 20000 // 20 seconds

export type UseLogoUpdateTimeoutProps = {
  callback: () => void
  invoiceSettingsLogos: Array<string | null> | undefined
  logos: Array<string | null> | undefined
  isLoading: boolean
  onError: () => void
  onLogosUpdated: ({
    logos,
    disableToast,
  }: {
    logos: Array<string | null>
    disableToast?: boolean
  }) => void
  setSettingsSubscriptionLogos: (logos: Array<string | null>) => void
}

export const useLogoUpdateTimeout = ({
  callback,
  invoiceSettingsLogos,
  logos,
  isLoading,
  onError,
  onLogosUpdated,
  setSettingsSubscriptionLogos,
}: UseLogoUpdateTimeoutProps) => {
  const [lastUploadFailed, setLastUploadFailed] = useState(false)

  useEffect(() => {
    if (!logos || !invoiceSettingsLogos || isLoading) {
      return
    }

    if (!logos.length && !invoiceSettingsLogos.length) {
      return
    }

    setSettingsSubscriptionLogos(invoiceSettingsLogos)

    if (lastUploadFailed) {
      if (logos.length > invoiceSettingsLogos.length) {
        onError()
      }

      onLogosUpdated({ logos: invoiceSettingsLogos, disableToast: true })

      setLastUploadFailed(false)
    }
  }, [
    invoiceSettingsLogos,
    isLoading,
    lastUploadFailed,
    logos,
    onError,
    onLogosUpdated,
    setSettingsSubscriptionLogos,
  ])

  const timer = useRestartableTimer(() => {
    callback()
    setLastUploadFailed(true)
  }, SUBSCRIPTION_TIMEOUT)

  return { lastUploadFailed, timer }
}
