import {
  DURATION,
  EASING_FUNCTION,
  getTransition,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDrawerContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .enter,
  .enter-active {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: ${({ theme }) => theme.colors.WHITE};
    overflow: hidden;
  }

  &.to-right {
    .enter {
      clip-path: inset(0 100% 0 0);
    }

    .enter-active {
      clip-path: inset(0 0 0 0);
    }
  }

  &.to-left {
    .enter {
      clip-path: inset(0 0 0 100%);
    }

    .enter-active {
      clip-path: inset(0 0 0 0);
    }
  }

  .enter-active {
    transition: ${getTransition(DURATION.NORMAL, EASING_FUNCTION.OUT)};
    transition-property: clip-path;
  }

  /* We're using an ultra quick animation on exit node to trigger the done */
  /* function in addEndListener prop. When we use the timeout property */
  /* sometimes the drawer content flashes white for a split second. */
  .exit {
    opacity: 1;
  }

  .exit-active {
    opacity: 0;
    transition-duration: 0s;
    transition-property: opacity;
    transition-delay: 300ms;
  }

  /* When there are multiple changes while the transition is happening */
  /* the exit nodes aren't unmounted immediately, which results in multiple elements */
  /* being shown at once. */
  /* To circumvent this we are hiding visually all exiting nodes */
  /* Apart from the first one. */
  &.hideExit {
    .exit ~ .exit {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      overflow: hidden;
      clip-path: inset(100%);
    }
  }
`
