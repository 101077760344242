import { useCallback, useMemo, useState } from 'react'

import { useManageBusinessesQueryData } from './useManageBusinessesQueryData'

export const useManageBusinessesQueryWithSearch = () => {
  const {
    entityRelations,
    isLoadingEntityRelations,
    entityRelationsQueryError,
    entityRelationsQueryNetworkStatus,
    refetchEntityRelations,
  } = useManageBusinessesQueryData()

  const [searchValue, setSearchValue] = useState('')

  const onChangeSearchTerm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    []
  )
  const onClearSearchTerm = useCallback(() => {
    setSearchValue('')
  }, [])

  const filteredEntityRelations = useMemo(() => {
    return entityRelations.filter((EntityMappingMock) => {
      return EntityMappingMock.entity.name
        ?.toLowerCase()
        .includes(searchValue.toLowerCase())
    })
  }, [entityRelations, searchValue])

  return {
    entityRelations,
    isLoadingEntityRelations,
    entityRelationsQueryError,
    refetchEntityRelations,
    entityRelationsQueryNetworkStatus,
    searchValue,
    onChangeSearchTerm,
    onClearSearchTerm,
    filteredEntityRelations,
  }
}
