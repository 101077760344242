import { useMemo } from 'react'
import { ErrorMessageForm } from '@npco/zeller-design-system'
import {
  SelectCompact,
  SelectItemBasic,
  useSelectField,
} from 'design-system/Components/Select'

import countryCodes from 'const/country-codes.json'
import { CustomValidator } from 'types/common'
import { form } from 'translations'

interface Props {
  customName?: string
  isDisabled?: boolean
  label?: string
  validator?: CustomValidator<string>
}

export const Country = ({
  customName = 'country',
  isDisabled = false,
  label,
  validator,
}: Props) => {
  const states = useMemo(
    () =>
      countryCodes.map(({ alpha3Code, name }) => ({
        value: alpha3Code,
        label: name,
      })),
    []
  )

  const { hasError, onChange, errorMessage, fieldValue, setTouched } =
    useSelectField<SelectItemBasic>({
      name: customName,
      validate: validator,
      items: states,
    })

  const selectedCountry = useMemo(() => {
    return states.find(
      (state) => state.value === fieldValue || state.label === fieldValue
    )
  }, [fieldValue, states])

  return (
    <>
      <SelectCompact
        mobileLabel={label || form.shared.country}
        placeholder={label || form.shared.country}
        hasError={hasError}
        selectedItem={selectedCountry}
        onChange={onChange}
        onClose={setTouched}
        items={states}
        isSearchableByKeypress
        isDisabled={isDisabled}
      />

      <ErrorMessageForm hasError={hasError} errorMessage={errorMessage} />
    </>
  )
}
