import { createTranslations } from '@npco/utils-translations'

export const stageTitleTranslations = createTranslations({
  linkCardIdStageTitle: 'Activate Card',
  linkEMLCardInfoStageTitle: 'Link Card',
  linkSelectAccountStageTitle: 'Select Account',
  createSelectAccountStageTitle: 'Select Account',
  createSelectCardholderStageTitle: 'Select Cardholder',
  createSelectCardTypeStageTitle: 'Select Type',
  createSelectCardFormatStageTitle: 'Select Card Format',
  createSetCardSettingsStageTitle: 'Card Settings',
  createSpendControlsStageTitle: 'Set Spend Management Controls',
  createSelectCardDesignStageTitle: 'Configure Style',
  createSelectDeliveryAddressStageTitle: 'Choose Delivery Address',
  createReviewCardStageTitle: 'Review Card',
  defaultTitle: 'Add Card',
})
