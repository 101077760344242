import { useCallback } from 'react'
import { ZellerInvoiceSettingsInput } from '@npco/mp-gql-types'

import { useGetZellerInvoiceSettings } from '../../../../../Settings/hooks/useGetZellerInvoiceSettings'
import { useUpdateZellerInvoiceSettings } from '../../../../../Settings/hooks/useUpdateZellerInvoiceSettings'

export const useUpdateInvoiceDeliverySettings = () => {
  const { invoiceSettings, updateInvoiceSettingsCache } =
    useGetZellerInvoiceSettings()
  const { updateZellerInvoiceSettings } = useUpdateZellerInvoiceSettings()

  return useCallback(
    async ({
      isEmailEnabled,
      isSMSEnabled,
    }: {
      isEmailEnabled: boolean
      isSMSEnabled: boolean
    }) => {
      if (!invoiceSettings) {
        return
      }
      if (
        !!invoiceSettings.invoice.sendEmailByDefault ||
        !!invoiceSettings.invoice.sendSmsByDefault
      ) {
        return
      }

      try {
        const result = await updateZellerInvoiceSettings({
          invoice: {
            ...(invoiceSettings.invoice as ZellerInvoiceSettingsInput),
            sendEmailByDefault: isEmailEnabled,
            sendSmsByDefault: isSMSEnabled,
          },
        })

        if (result.data?.updateZellerInvoiceSettings) {
          updateInvoiceSettingsCache({
            ...invoiceSettings,
            invoice: {
              ...invoiceSettings.invoice,
              sendEmailByDefault: isEmailEnabled,
              sendSmsByDefault: isSMSEnabled,
            },
          })
        }
      } catch (e) {
        // NOTE: don't really need to do anything if this fails
        // as this only happens in the background after creating
        // first invoice
      }
    },
    [invoiceSettings, updateInvoiceSettingsCache, updateZellerInvoiceSettings]
  )
}
