import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type AddLabelMutationVariables = Types.Exact<{
  labelText: Types.Scalars['String']['input']
  type: Types.LabelType
  entityUuid: Types.Scalars['ID']['input']
}>

export type AddLabelMutationResponse = {
  __typename?: 'Mutation'
  addLabel: {
    __typename?: 'Label'
    isEditable: boolean
    id: string
    labelText: string
    type: Types.LabelType
  }
}

export const AddLabel = gql`
  mutation AddLabel($labelText: String!, $type: LabelType!, $entityUuid: ID!) {
    addLabel(labelText: $labelText, type: $type, entityUuid: $entityUuid) {
      isEditable
      id
      labelText
      type
    }
  }
` as unknown as TypedDocumentNode<
  AddLabelMutationResponse,
  AddLabelMutationVariables
>
export type AddLabelMutationFn = Apollo.MutationFunction<
  AddLabelMutationResponse,
  AddLabelMutationVariables
>

/**
 * __useAddLabelMutation__
 *
 * To run a mutation, you first call `useAddLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLabelMutation, { data, loading, error }] = useAddLabelMutation({
 *   variables: {
 *      labelText: // value for 'labelText'
 *      type: // value for 'type'
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useAddLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLabelMutationResponse,
    AddLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddLabelMutationResponse,
    AddLabelMutationVariables
  >(AddLabel, options)
}
export type AddLabelMutationHookResult = ReturnType<typeof useAddLabelMutation>
export type AddLabelMutationResult =
  Apollo.MutationResult<AddLabelMutationResponse>
export type AddLabelMutationOptions = Apollo.BaseMutationOptions<
  AddLabelMutationResponse,
  AddLabelMutationVariables
>
