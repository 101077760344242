import {
  AnnouncementModal,
  InterZellerMediumFont,
  ModalBasic,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const SuccessAnnouncementModal = styled(AnnouncementModal)`
  ${ModalBasic.SecondaryButton} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const DescriptionValue = styled.strong`
  ${InterZellerMediumFont}
`
