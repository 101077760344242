import { useRef } from 'react'
import { ModalBasicProps } from 'design-system/Components/Modal'

import { useTrackScroll } from 'hooks/useTrackScroll/useTrackScroll'

import { StyledContentContainer, StyledModal } from './ModalScrollable.styled'

export const ModalScrollable = ({
  children,
  ...otherProps
}: ModalBasicProps) => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const { isScrollable, hasScrolledToBottom, handleScroll } = useTrackScroll({
    scrollableRef,
  })

  const hasScrollableContentShadow = isScrollable && !hasScrolledToBottom

  return (
    <StyledModal {...otherProps}>
      <StyledContentContainer
        data-testid="modal-content-container"
        $hasScrollableContentShadow={hasScrollableContentShadow}
        onScroll={handleScroll}
        ref={scrollableRef}
      >
        {children}
      </StyledContentContainer>
    </StyledModal>
  )
}
