import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  TableCellAvatarText,
  TableCellSize,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'

import { getCategoryIcon } from '../../../../../../utils/getCategoryIcon/getCategoryIcon'
import { categoryDrawerContentTranslations } from '../../../../CategoryDrawerContent.i18n'
import { SubcategoryCellCashFlowSubcategoryNetAmountFragment } from './SubcategoryCell.generated'

export const SubcategoryCell = <T,>({
  cell,
  table,
  getValue,
  row,
  column,
}: CellContext<T, SubcategoryCellCashFlowSubcategoryNetAmountFragment>) => {
  const t = useTranslations(categoryDrawerContentTranslations)

  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading ? undefined : getValue()

  const numberOfTransactions = useMemo(() => {
    const transactionsCount = data?.noOfTransaction ?? 0

    if (transactionsCount === 0) {
      return ''
    }

    return `${transactionsCount} ${
      transactionsCount > 1 ? t('transactionPlural') : t('transactionSingular')
    }`
  }, [data, t])

  const subcategoryText = useMemo(() => {
    return data?.subcategoryDetails?.name ?? t('uncategorised')
  }, [data, t])

  const SubcategoryIcon = useMemo(() => {
    return getCategoryIcon(data?.category)
  }, [data])

  return (
    <TableCellAvatarText
      key={cell.id}
      dataTestId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      avatarProps={{
        text: data?.subcategoryDetails?.name ?? '?',
        bgColor: COLOR.GREY_90,
        svgBackground: COLOR.GREY_90,
        contentHeight: '12px',
        contentWidth: '12px',
        userDefinedContent: SubcategoryIcon && (
          <SubcategoryIcon color={COLOR.GREY_550} withNoTransition />
        ),
      }}
      byline={numberOfTransactions}
      text={subcategoryText}
      size={TableCellSize.SMALL}
      skeletonProps={{ width: skeletonLoaderWidth }}
    />
  )
}

SubcategoryCell.fragments = {
  CashFlowSubcategoryNetAmount: gql`
    fragment SubcategoryCellCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
      category
      noOfTransaction
      subcategoryDetails {
        id
        name
      }
    }
  `,
}
