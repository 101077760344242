import { translate } from 'utils/translations'

import { StyledPlaceholder } from './SubcategoriesErrorPlaceholder.styled'

interface Props {
  retry: () => void
}

export const SubcategoriesErrorPlaceholder = ({ retry }: Props) => {
  return (
    <StyledPlaceholder
      title={translate(
        'component.editZellerCategories.subcategoriesError.title'
      )}
      description={translate(
        'component.editZellerCategories.subcategoriesError.description'
      )}
      buttonLabel={translate(
        'component.editZellerCategories.subcategoriesError.buttonLabel'
      )}
      onClick={retry}
    />
  )
}
