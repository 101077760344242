import { useCallback } from 'react'

import { useCreateExternalAccountContext } from '../../../../contexts/useCreateExternalAccountContext'
import {
  CreateExternalAccountStage,
  ExternalAccountMessageState,
} from '../../../../CreateExternalAccount.types'

export const useExternalAccountMessageStage = () => {
  const { state, goToCreateExternalAccountState } =
    useCreateExternalAccountContext<ExternalAccountMessageState>()

  const goToCreateExternalAccountFormStage = useCallback(() => {
    goToCreateExternalAccountState({
      ...(state || {}),
      stage: CreateExternalAccountStage.ExternalAccountFormStage,
    })
  }, [state, goToCreateExternalAccountState])

  const closeCreateExternalAccountModal = useCallback(() => {
    goToCreateExternalAccountState(undefined)
  }, [goToCreateExternalAccountState])

  return {
    goToCreateExternalAccountFormStage,
    closeCreateExternalAccountModal,
  }
}
