import { useCallback, useEffect, useState } from 'react'
import DayPicker, { RangeModifier } from 'react-day-picker'
import {
  GetInvoicesDateFilterInput,
  GetInvoicesFilterInput,
} from '@npco/mp-gql-types'
import {
  Divider,
  SelectCompact,
  SelectItemBasic,
  SelectSelectedItem,
} from '@npco/zeller-design-system'
import { Dayjs } from 'dayjs'
import { DateColumnEnum } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import { DatePickerWrapper } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/DateFilters/DateFiltersContent.styled'

import dayjs from 'utils/dayjs'
import { translate } from 'utils/translations'
import { RangeModifierExt } from 'types/picker'
import { page } from 'translations'

const SELECT_ITEMS: SelectItemBasic[] = [
  {
    value: DateColumnEnum.NextDue,
    label: page.invoiceTable.dateColumn.nextDue,
  },
  {
    value: DateColumnEnum.Created,
    label: page.invoiceTable.dateColumn.created,
  },
  {
    value: DateColumnEnum.Issued,
    label: page.invoiceTable.dateColumn.issued,
  },
  {
    value: DateColumnEnum.Sent,
    label: page.invoiceTable.dateColumn.sent,
  },
  {
    value: DateColumnEnum.DatePaid,
    label: page.invoiceTable.dateColumn.paid,
  },
]

export interface DateFiltersContentProps {
  setShouldCloseOnOutsideClick?: (state: boolean) => void
  data: RangeModifierExt
  enteredTo: Date | undefined
  handleDayClick: (day: Date) => void
  handleDayMouseEnter: (day: Date) => void
  filterInput: GetInvoicesFilterInput | null
  selectedDateColumn: DateColumnEnum
  setSelectedDateColumn: (date: DateColumnEnum) => void
  setDateFilter: (input: GetInvoicesDateFilterInput | null) => void
}

export const DateFiltersContent = ({
  setShouldCloseOnOutsideClick,
  data,
  enteredTo,
  handleDayClick,
  handleDayMouseEnter,
  filterInput,
  selectedDateColumn,
  setSelectedDateColumn,
  setDateFilter,
}: DateFiltersContentProps) => {
  const [, setCurrentlyVisibleFirstMonth] = useState<Dayjs | undefined>(
    dayjs(data.from).startOf('month')
  )

  const dateModifiers = {
    start: data.from,
    end: enteredTo,
  }
  const onChangeMonth = useCallback((month: Date) => {
    setCurrentlyVisibleFirstMonth(dayjs(month).startOf('month'))
  }, [])

  const handleOnSelectChange = useCallback(
    (item: SelectSelectedItem<SelectItemBasic>) => {
      setSelectedDateColumn(item?.value as DateColumnEnum)
    },
    [setSelectedDateColumn]
  )

  const handleSelectCloseOnOutsideClick = useCallback(
    (bool: boolean) => () => {
      setShouldCloseOnOutsideClick?.(bool)
    },
    [setShouldCloseOnOutsideClick]
  )

  useEffect(() => {
    if (data.from && data.to && filterInput) {
      if (
        filterInput.dateFilter?.startDate ===
          Math.floor(data.from.getTime() / 1000) &&
        filterInput.dateFilter?.endDate ===
          Math.floor(data.to.getTime() / 1000) &&
        filterInput.dateFilter?.columnName === selectedDateColumn
      ) {
        return
      }

      setDateFilter({
        startDate: Math.floor(data.from.getTime() / 1000),
        endDate: Math.floor(data.to.getTime() / 1000),
        columnName: selectedDateColumn,
      })
    }
  }, [data, filterInput, selectedDateColumn, setDateFilter])

  return (
    <>
      <SelectCompact
        items={SELECT_ITEMS}
        selectedItem={SELECT_ITEMS.find(
          ({ value }) => value === selectedDateColumn
        )}
        onChange={handleOnSelectChange}
        label={translate('page.invoiceTable.dateColumn.label')}
        onOpen={handleSelectCloseOnOutsideClick(false)}
        onClose={handleSelectCloseOnOutsideClick(true)}
        mobileLabel={translate('page.invoiceTable.dateColumn.label')}
      />
      <Divider margin="20px 0" />
      <DatePickerWrapper isDateTime={false}>
        <DayPicker
          initialMonth={data.from}
          numberOfMonths={1}
          selectedDays={[
            data.from,
            {
              from: data.from as unknown as RangeModifier['from'], // insufficient library typing
              to: enteredTo as unknown as RangeModifier['to'], // hack to set it properly
            },
          ]}
          modifiers={dateModifiers}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          onMonthChange={onChangeMonth}
          data-testid="day-picker"
        />
      </DatePickerWrapper>
    </>
  )
}
