import { SimBillingStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { DrawerBasic, Flex } from '@npco/zeller-design-system'

import { SimBillingDisplayStatus } from '../Sim.type'
import { mapSimBillingStatusToDisplayStatus } from '../Sim.utils'
import { ActiveStatusAction } from '../SimCardStatusAction/ActiveStatusAction'
import { CancelledStatusAction } from '../SimCardStatusAction/CancelledStatusAction'
import { PendingStatusAction } from '../SimCardStatusAction/PendingStatusAction'
import { SimTableFragment } from '../SimTable/SimTable.generated'
import { DrawerTitle } from './components/DrawerTitle/DrawerTitle'
import { SimImage } from './components/SimImage/SimImage'
import { useGetSimQuery } from './graphql/getSim.generated'
import { useAbortSimCancellation } from './hooks/useAbortSimCancellation'
import { useActivateSim } from './hooks/useActivateSim'
import { useCancelSim } from './hooks/useCancelSim'
import { SimDetails } from './SimDetails'

type SimDrawerProps = {
  isOpen: boolean
  closeModal: () => void
  selectedSim?: SimTableFragment
}

export const SimDrawer = ({
  isOpen,
  closeModal,
  selectedSim,
}: SimDrawerProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useGetSimQuery({
    skip: !selectedSim?.id,
    variables: { entityUuid, simId: selectedSim?.id ?? '' },
  })
  const simBillingDisplayStatus = mapSimBillingStatusToDisplayStatus(
    data?.getSim?.billingStatus ?? SimBillingStatus.NULL
  )

  const { abortSimCancellation } = useAbortSimCancellation({
    simId: selectedSim?.id,
  })
  const { activateSim, isActivatingSim } = useActivateSim({
    simId: selectedSim?.id,
  })
  const { cancelSim, isCancellingSim } = useCancelSim({
    simId: selectedSim?.id,
  })

  return (
    <DrawerBasic
      isOpen={isOpen}
      onClose={closeModal}
      title={
        <DrawerTitle
          isLoading={loading}
          billingStatus={data?.getSim?.billingStatus}
        />
      }
    >
      <Flex flexDirection="column" gap="40px" marginTop="-28px" width="100%">
        {data?.getSim?.billingStatus === 'CANCEL_PENDING' && (
          <PendingStatusAction
            abortSimCancellation={abortSimCancellation}
            selectedSim={data?.getSim}
          />
        )}
        <Flex flexDirection="column" gap="24px" width="100%">
          <SimImage billingStatus={data?.getSim?.billingStatus} />
          <SimDetails loading={loading} selectedSim={data?.getSim} />
          {simBillingDisplayStatus === SimBillingDisplayStatus.ACTIVE && (
            <ActiveStatusAction
              cancelSim={cancelSim}
              isLoading={isCancellingSim}
              selectedSim={data?.getSim}
            />
          )}

          {simBillingDisplayStatus === SimBillingDisplayStatus.CANCELLED && (
            <CancelledStatusAction
              reactivateSim={activateSim}
              isLoading={isActivatingSim}
            />
          )}
        </Flex>
      </Flex>
    </DrawerBasic>
  )
}
