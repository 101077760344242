import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import {
  InputAdaptiveBasic,
  SelectCompact,
  SelectItemBasic,
  SelectSelectedItem,
} from '@npco/zeller-design-system'
import { INPUT_SIZE } from '@npco/zeller-design-system/dist/Components/InputAdaptive/InputAdaptive.types'
import { AmountColumnEnum } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.types'
import { InvoiceTableDefaultAmount } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import {
  Divider,
  FilterWrapper,
  RangeInputWrapper,
} from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/AmountFilters/AmountFilters.styled'

import { translate } from 'utils/translations'
import {
  RangePickerValue,
  RangeSliderValue,
  RangeType,
  RangeValue,
} from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import { Range } from 'components/Input/Range'
import { CurrencySymbolWrapper } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField.styled'
import { page } from 'translations'

const SLIDER_STEP = 100

const SELECT_ITEMS: SelectItemBasic[] = [
  {
    label: page.invoiceTable.amountColumn.paid,
    value: AmountColumnEnum.AmountPaid,
  },
  {
    label: page.invoiceTable.amountColumn.outstanding,
    value: AmountColumnEnum.Outstanding,
  },
  {
    label: page.invoiceTable.amountColumn.total,
    value: AmountColumnEnum.Total,
  },
]

const renderLeftControls = () => (
  <CurrencySymbolWrapper>$</CurrencySymbolWrapper>
)

export interface AmountFiltersContentProps {
  selectedAmountColumn: AmountColumnEnum
  setSelectedAmountColumn: (value: AmountColumnEnum) => void
  inputMin: RangeValue
  inputMax: RangeValue
  sliderMin: number
  sliderMax: number
  onInputChange: (event: SyntheticEvent, type: RangeType) => void
  onSliderChange: (range: RangePickerValue) => void
  onAfterChange: (newValue: number | readonly number[]) => void
  setShouldCloseOnOutsideClick?: (state: boolean) => void
}

export const AmountFiltersContent = ({
  selectedAmountColumn,
  setSelectedAmountColumn,
  setShouldCloseOnOutsideClick,
  inputMin,
  inputMax,
  sliderMin,
  sliderMax,
  onInputChange,
  onSliderChange,
  onAfterChange,
}: AmountFiltersContentProps) => {
  const selectedItem = useMemo(
    () => SELECT_ITEMS.find(({ value }) => value === selectedAmountColumn),
    [selectedAmountColumn]
  )

  const handleOnSelectItemChange = useCallback(
    (item: SelectSelectedItem<SelectItemBasic>) => {
      setSelectedAmountColumn(item?.value as AmountColumnEnum)
    },
    [setSelectedAmountColumn]
  )

  const handleOnRangeChange = useCallback(
    (property: RangeType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.includes('-')) {
        return
      }
      if (property === 'min' && event.target.value >= inputMax) {
        return
      }
      if (
        property === 'max' &&
        +event.target.value > InvoiceTableDefaultAmount[1]
      ) {
        return
      }
      onInputChange(event, property)
    },
    [inputMax, onInputChange]
  )

  const handleOnAfterChange = useCallback(
    (value: number | readonly number[]) => {
      onAfterChange(value)
    },
    [onAfterChange]
  )

  const handleOnRangeSliderChange = useCallback(
    (range: RangeSliderValue) => onSliderChange(range as RangePickerValue),
    [onSliderChange]
  )

  const handleSelectCloseOnOutsideClick = useCallback(
    (bool: boolean) => () => {
      setShouldCloseOnOutsideClick?.(bool)
    },
    [setShouldCloseOnOutsideClick]
  )

  return (
    <FilterWrapper>
      <SelectCompact
        items={SELECT_ITEMS}
        selectedItem={selectedItem}
        onChange={handleOnSelectItemChange}
        label={translate('page.invoiceTable.amountColumn.label')}
        onOpen={handleSelectCloseOnOutsideClick(false)}
        onClose={handleSelectCloseOnOutsideClick(true)}
        mobileLabel={translate('page.invoiceTable.amountColumn.label')}
      />
      <Divider />
      <Range
        min={InvoiceTableDefaultAmount[0]}
        max={InvoiceTableDefaultAmount[1]}
        step={SLIDER_STEP}
        value={[sliderMin, sliderMax]}
        onChange={handleOnRangeSliderChange}
        onAfterChange={handleOnAfterChange}
      />
      <RangeInputWrapper>
        <InputAdaptiveBasic
          placeholder={inputMin.toString()}
          value={inputMin.toString()}
          onChange={handleOnRangeChange('min')}
          onBlur={() => onAfterChange([sliderMin, sliderMax])}
          label={translate('page.invoiceTable.amountColumn.minInput')}
          size={INPUT_SIZE.SMALL}
          renderLeftControls={renderLeftControls}
          data-testid="min-amount-input"
        />
        <InputAdaptiveBasic
          placeholder={inputMax.toString()}
          value={inputMax.toString()}
          onChange={handleOnRangeChange('max')}
          onBlur={() => onAfterChange([sliderMin, sliderMax])}
          label={translate('page.invoiceTable.amountColumn.maxInput')}
          size={INPUT_SIZE.SMALL}
          renderLeftControls={renderLeftControls}
          data-testid="max-amount-input"
        />
      </RangeInputWrapper>
    </FilterWrapper>
  )
}
