import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Tag = styled(Flex)`
  position: absolute;
  top: 0px;
  left: 12px;
  align-items: center;
  justify-content: center;
  background-color: #fb8f6b;
  border: 3px solid #f96939;
  border-radius: 50% 50% 50% 0;
  color: white;
  width: 53px;
  height: 53px;
`

export const DollarSign = styled.span`
  font-size: 12px;
  margin-bottom: 3px;
`
