import {
  BodyDefault,
  BodyDefaultTypography,
  BodySmallTypography,
  Box,
} from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { ReactComponent as PenIcon } from 'assets/svg/pen.svg'
import { ReactComponent as TagIcon } from 'assets/svg/tag.svg'
import { BREAKPOINT } from 'styles/breakpoints'
import { DebitCardTransactionRowWrapper } from 'pages/DebitCardTransactions/DebitCardTransactionRowWrapper/DebitCardTransactionRowWrapper'

export const StyledContainer = styled(DebitCardTransactionRowWrapper)<{
  $showMobileStyle: boolean
}>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-template-areas:
    'company-name company-name icons amount'
    'description description icons icon';

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    css`
      @media screen and (min-width: ${BREAKPOINT.MD}px) {
        display: flex;
        justify-content: space-between;
        grid-template-columns: auto 1fr;
        padding: 1rem 0.5rem;
      }
    `}
`

export const StyledStatusIconItem = styled(Box)<{
  $showMobileStyle: boolean
}>`
  grid-area: icon;
  justify-self: end;
  margin-left: auto;

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    css`
      @media screen and (min-width: ${BREAKPOINT.MD}px) {
        position: relative;
        align-items: center;
        padding-left: 1rem;
      }
    `}
`

export const StyledDescriptionItem = styled.div<{
  $showMobileStyle: boolean
}>`
  grid-area: description;
  margin-right: auto;
  flex-grow: 1;

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    css`
      @media screen and (min-width: ${BREAKPOINT.MD}px) {
        padding-left: 1rem;
        width: 14.5rem;
      }
    `}
`

export const StyledDescriptionText = styled(BodyDefault)<{
  $showMobileStyle: boolean
}>`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodySmallTypography};

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    css`
      @media (min-width: ${BREAKPOINT.XS}px) {
        ${BodyDefaultTypography};
      }

      @media screen and (min-width: ${BREAKPOINT.MD}px) {
        color: ${({ theme }) => theme.colors.BLACK};
      }
    `}
`

export const StyledIconItem = styled.span<{
  $showMobileStyle: boolean
}>`
  display: none;
  grid-area: icons;
  margin-right: auto;

  ${({ $showMobileStyle }) =>
    !$showMobileStyle &&
    css`
      @media screen and (min-width: ${BREAKPOINT.MD}px) {
        display: flex;
      }
    `}
`

const iconStyle = css`
  height: 12px;
  width: 12px;
  padding: 2px;
  padding-right: 18px;
  path {
    fill: ${({ theme }) => theme.colors.GREY_250};
  }
`

export const StyledIconPlaceHolder = styled.span`
  display: inline-block;
  ${iconStyle}
`

export const StyledPenIcon = styled(PenIcon)`
  ${iconStyle}
`

export const StyledTagIcon = styled(TagIcon)`
  ${iconStyle}
`
