import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetZellerSurchargeSettings } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/zellerSurchargeSettings'

import { GetZellerSurchargeSettings as GetZellerSurchargeSettingsResponse } from 'types/gql-types/GetZellerSurchargeSettings'

export const useZellerSurchargeSettings = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading } = useQuery<GetZellerSurchargeSettingsResponse>(
    GetZellerSurchargeSettings,
    { variables: { entityUuid } }
  )

  return {
    zellerSurchargeSettings:
      data?.getZellerInvoiceSettings.surchargesTaxes?.feesSurchargeZinv,
    isLoadingZellerSettings: loading,
  }
}
