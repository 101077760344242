import {
  Box,
  Breadcrumb,
  COLOR,
  Flex,
  SvgIcon,
} from '@npco/zeller-design-system'

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg'
import { translate } from 'utils/translations'
import { AccountMobileFiltersList } from 'layouts/AccountLayout/AccountMobileFiltersList/AccountMobileFiltersList'
import { MobileFilter } from 'components/FiltersList/FiltersListMobile'
import { TransferFlowButton } from 'components/TransferFlowButton'

import * as styled from '../../AccountsLayout.styled'
import { AccountTotalBalance } from '../../AccountTotalBalance/AccountTotalBalance'
import { GetEntityForAccountsLayoutQueryResponse } from '../../hooks/graphql/getEntityForAccountsLayout.generated'

export interface AccountsLayoutPrimaryHeaderRowMobileProps {
  entity?: GetEntityForAccountsLayoutQueryResponse['getEntity']
  isFiltersDisabled: boolean
  isLoadingEntity: boolean
  mobileFilters: MobileFilter[]
  onAddAccount: () => void
  shouldHaveFilters: boolean
}

export const AccountsLayoutPrimaryHeaderRowMobile = ({
  entity,
  isFiltersDisabled,
  isLoadingEntity,
  mobileFilters,
  onAddAccount: handleAddAccount,
  shouldHaveFilters,
}: AccountsLayoutPrimaryHeaderRowMobileProps) => {
  return (
    <Flex
      data-testid="accounts-layout-primary-header-row-mobile"
      flex={1}
      flexDirection="column"
      marginTop="24px"
      width="100%"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height="48px"
        width="100%"
      >
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Breadcrumb.Text>
                {translate('page.accounts.accounts')}
              </Breadcrumb.Text>
            </Breadcrumb.Item>
          </Breadcrumb>
          <styled.MobileBalanceWrapper isVisible>
            <AccountTotalBalance />
          </styled.MobileBalanceWrapper>
        </div>
        <Flex gridGap="0.5rem" position="relative">
          {shouldHaveFilters && (
            <Box mr="0.5rem">
              <AccountMobileFiltersList
                filters={mobileFilters}
                disabled={isFiltersDisabled}
              />
            </Box>
          )}
          {!isLoadingEntity && (
            <>
              <styled.AddAccountButton onClick={handleAddAccount}>
                <SvgIcon color={COLOR.BLUE_1000}>
                  <PlusIcon width={14} height={14} />
                </SvgIcon>
              </styled.AddAccountButton>
              <TransferFlowButton isDisabled={false} entity={entity} />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
