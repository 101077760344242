import { H3 } from '@npco/zeller-design-system'
import { ModalWithButtons } from 'design-system/Components/Modal'
import styled from 'styled-components/macro'

export const StyledTitle = styled(H3)`
  margin: 0 0 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK};
`

export const StyledConsent = styled.p`
  margin: 1.5rem 0 3rem;
  color: ${({ theme }) => theme.colors.BLACK};
  line-height: 1.5rem;
`

export const StyledConfirmModal = styled(ModalWithButtons)`
  min-height: 30rem;
`

export const StyledConfirmModalContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 20rem;
`
