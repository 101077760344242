import { HeaderContext } from '@tanstack/react-table'

import { translate } from 'utils/translations'
import { GetInvoices_getInvoices_invoices as Invoice } from 'types/gql-types/GetInvoices'

import { InvoiceHeaderCell } from '../InvoiceHeaderCell/InvoiceHeaderCell'
import { AllInvoiceTableColumn } from '../InvoiceTable.types'
import { TableColumnPopperWidth } from '../InvoiceTable.utils'
import { IdFilters } from '../InvoiceTableFilters/IdFilters/IdFilters'

export const InvoiceIdHeaderCell = ({
  header,
  table,
}: HeaderContext<Invoice | null, unknown>) => {
  return (
    <InvoiceHeaderCell
      disablePopper={false}
      header={header}
      label={translate('component.invoiceTable.id')}
      popperWidth={TableColumnPopperWidth[AllInvoiceTableColumn.ID]}
      table={table}
    >
      {({ setIsOpen }) => <IdFilters setIsOpen={setIsOpen} />}
    </InvoiceHeaderCell>
  )
}
