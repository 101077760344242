import { gql } from '@apollo/client'
import { DebitCardProductType } from '@npco/mp-gql-types'

import { CorporateCardDetails } from './CorporateCardDetails/CorporateCardDetails'
import { DebitCardDetails } from './DebitCardDetails/DebitCardDetails'
import { ZellerCardDetailsDebitCardV2Fragment } from './ZellerCardDetails.generated'

interface ZellerCardDetailsProps {
  card: ZellerCardDetailsDebitCardV2Fragment
  timezone?: string
}

export const ZellerCardDetails = ({
  card,
  timezone,
}: ZellerCardDetailsProps) => {
  if (card.productType === DebitCardProductType.EXPENSE) {
    return <CorporateCardDetails card={card} timezone={timezone} />
  }

  return <DebitCardDetails cardDetails={card} />
}

ZellerCardDetails.fragments = {
  DebitCardV2: gql`
    fragment ZellerCardDetailsDebitCardV2Fragment on DebitCardV2 {
      productType
      ...DebitCardDetailsDebitCardV2Fragment
      ...CorporateCardDetailsDebitCardV2Fragment
    }

    ${DebitCardDetails.fragments.DebitCardV2}
    ${CorporateCardDetails.fragments.DebitCardV2}
  `,
}
