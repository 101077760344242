import { SelectStyle } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { SCROLLBAR } from 'const/scrollbars'

export const StyledOptionsList = styled.ul<{
  menuStyle?: SelectStyle
  isEmpty: boolean
}>`
  list-style: none;
  outline: none;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow: scroll;

  padding: ${({ menuStyle }) => {
    switch (menuStyle) {
      case SelectStyle.Standard:
        return `0 ${SCROLLBAR.SM.leftSpacing} 0 0.5rem`
      default:
        return '0'
    }
  }};

  &::-webkit-scrollbar {
    width: ${SCROLLBAR.SM.width};
  }

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`
