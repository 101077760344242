import { createTranslations } from '@npco/utils-translations'

export const savingsAccountEmptyTransactionsTranslations = createTranslations({
  title:
    "Your Zeller Savings Account is activated!{lineBreak}Let's add some funds.",
  description:
    "As soon as you transfer funds to your Zeller Savings Account, you'll begin earning interest.",
  actionButton: 'Add Funds',
  savingsText: 'Savings',
})
