import { ApolloCache } from '@apollo/client'
import { DebitCardV2 } from '@npco/mp-gql-types'
import {
  UpdateOption,
  UpdateOptionFunctionOnly,
  updateWithOption,
} from 'features/utils-cache'

import {
  UpdateCachedCardFragment,
  UpdateCachedCardFragmentDoc,
} from './graphql/UpdateCachedCardFragment.generated'

export type CardUpdate = {
  id: string
  velocityControl?: UpdateOptionFunctionOnly<
    NonNullable<UpdateCachedCardFragment['velocityControl']>
  >
  name?: UpdateOption<DebitCardV2['name']>
  status?: UpdateOption<DebitCardV2['status']>
}

type UpdateCachedCardArguments = {
  cache: ApolloCache<any>
  cardUpdate: CardUpdate
}

export const updateCachedCard = ({
  cache,
  cardUpdate,
}: UpdateCachedCardArguments) => {
  cache.updateFragment(
    {
      id: cache.identify({ __typename: 'DebitCardV2', id: cardUpdate.id }),
      fragment: UpdateCachedCardFragmentDoc,
    },
    (data) => {
      if (!data) {
        return data
      }

      return {
        ...data,
        velocityControl: data.velocityControl
          ? updateWithOption(cardUpdate.velocityControl, data.velocityControl)
          : null,
        name: updateWithOption(cardUpdate.name, data.name),
        status: updateWithOption(cardUpdate.status, data.status),
      }
    }
  )
}
