import { useFetchDepositsStatements } from 'pages/Deposits/DepositsStatements/hooks/useFetchDeppositsStatements'
import { CustomRange } from 'components/Statements/StatementsButtons/CustomRange/CustomRange'
import { CustomRangeButton } from 'components/Statements/StatementsButtons/CustomRangeButton/CustomRangeButton'

export const DepositsCustomRange = () => {
  const { exportDepositFile, isLoadingDeposits, hasLoaded } =
    useFetchDepositsStatements()

  return (
    <CustomRange>
      <CustomRangeButton
        getStatement={exportDepositFile}
        isLoadingData={isLoadingDeposits}
        hasLoaded={hasLoaded}
      />
    </CustomRange>
  )
}
