import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type BusinessRowCustomerEntityRelationFragment = {
  __typename?: 'CustomerEntityRelation'
  role: Types.CustomerRole
  isVisible: boolean
  entity: { __typename?: 'Entity'; id: string; name: string | null }
}

export const BusinessRowCustomerEntityRelationFragmentDoc = gql`
  fragment BusinessRowCustomerEntityRelationFragment on CustomerEntityRelation {
    role
    isVisible
    entity {
      id
      name
    }
  }
` as unknown as TypedDocumentNode<
  BusinessRowCustomerEntityRelationFragment,
  undefined
>
