import { ONBOARDING_SHOP } from '@npco/mp-feature-onboarding-shop'

import { ROOT } from '../routes'
import { OnboardingFlowConfigItem } from './onboarding-config.types'
import { SHARED_KYC_FLOW_CONFIG_ITEMS } from './onboarding-config-kyc'

const ONBOARDING_TRUST_CONFIG_COMMON: OnboardingFlowConfigItem[] = [
  {
    path: ROOT.ONBOARDING.TRUST.POSITION.path,
    isCheckpoint: false,
  },
  ...SHARED_KYC_FLOW_CONFIG_ITEMS,
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.SETTLED_SUM.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.SETTLOR_INFORMATION.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.BENEFICIARIES.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.ADD_BENEFICIARIES.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.EDIT_BENEFICIARIES.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.TRUSTEES.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.ADD_TRUSTEES.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.EDIT_TRUSTEES.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.BENEFICIAL_OWNERS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.ADD_BENEFICIAL_OWNERS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.CUSTOMERS.EDIT_BENEFICIAL_OWNERS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.ABOUT_BUSINESS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.TRUST.BUSINESS_REGULATIONS.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.UPLOAD_DOCUMENTS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.TRUST.UPLOAD_BANK_STATEMENTS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.UPLOAD_DOCUMENTS_LATER.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.PRE_ONBOARDED_QUESTIONNAIRE.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.COMPLETE.path,
    isCheckpoint: true,
  },
  { path: ONBOARDING_SHOP.DEALS.path, isCheckpoint: false },
  { path: ONBOARDING_SHOP.DEALS.CHECKOUT.path, isCheckpoint: false },
]

export const ONBOARDING_TRUST_FLOW_CONFIG_ITEMS: OnboardingFlowConfigItem[] = [
  {
    path: ROOT.ONBOARDING.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.COMPANY_INFORMATION.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRY_AGAIN.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.TRADING_NAME.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.TRUST.REGISTERED_OFFICE_ADDRESS.path,
    isCheckpoint: true,
  },
  {
    path: ROOT.ONBOARDING.TRUST.REVENUE.path,
    isCheckpoint: false,
  },
  {
    path: ROOT.ONBOARDING.TRUST.BUSINESS_TYPE.path,
    isCheckpoint: false,
  },
  ...ONBOARDING_TRUST_CONFIG_COMMON,
]

export const ONBOARDING_TRUST_FLOW_CONFIG_ITEMS_PHASE_2: OnboardingFlowConfigItem[] =
  [
    {
      path: ROOT.ONBOARDING.YOUR_BUSINESS_INFORMATION.path,
      isCheckpoint: false,
    },
    {
      path: ROOT.ONBOARDING.TRUST.REGISTERED_OFFICE_ADDRESS.path,
      isCheckpoint: true,
    },
    ...ONBOARDING_TRUST_CONFIG_COMMON,
  ]
