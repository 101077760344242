import { Formik } from 'formik'

import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { ExternalAccountFields } from '../../CreateExternalAccountModal.types'
import { TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES } from '../../CreateExternalAccountModal.utils'
import { ExternalAccountForm } from './ExternalAccountForm/ExternalAccountForm'
import { useExternalAccountFormStage } from './hooks/useExternalAccountFormStage'

export const ExternalAccountFormStage = () => {
  const { initialExternalAccountFields, goToCreateExternalAccountReviewStage } =
    useExternalAccountFormStage()

  useMultiStageModalProgressBar({
    newCurrentStep: 12,
    newTotalSteps: TOTAL_CREATE_EXTERNAL_ACCOUNT_STAGES,
  })

  return (
    <Formik<ExternalAccountFields>
      initialValues={initialExternalAccountFields}
      onSubmit={goToCreateExternalAccountReviewStage}
    >
      <ExternalAccountForm />
    </Formik>
  )
}
