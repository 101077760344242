import { useState } from 'react'
import { EntityCategories } from '@npco/mp-gql-types'
import { SelectItemBasic } from 'design-system/Components/Select'
import { useFormikContext } from 'formik'

import { validateRequired } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { CategoryDropdown } from 'components/EditCategoriesModal/Fields/CategoryDropdown'
import { CategoriesPlaceholder } from 'components/EditCategoriesModal/Fields/Placeholders/CategoriesPlaceholder/CategoriesPlaceholder'

import { EntityCategoryValues } from '../EditZellerCategories.types'
import { getCategoryFromString } from '../EditZellerCategories.utils'
import { getCategoryDisplay } from './utils/categories.utils'

const CATEGORY_ITEMS = Object.values(EntityCategories).map((category) => ({
  label: getCategoryDisplay(category).text,
  value: category,
}))

interface Props {
  setSelectedCategory: (newCategory: EntityCategories | undefined) => void
  initialValue: EntityCategories | null
}

export const CategoryField = ({ setSelectedCategory, initialValue }: Props) => {
  const [searchInput, setSearchInput] = useState('')
  const { values, setFieldValue } = useFormikContext<EntityCategoryValues>()

  const onChange = (item: SelectItemBasic) => {
    setSelectedCategory(
      item.value ? getCategoryFromString(item.value as string) : undefined
    )
    setFieldValue('subcategory', null)
    setFieldValue('subcategoryId', null)
  }

  return (
    <CategoryDropdown
      name="category"
      onChangeSearchInput={setSearchInput}
      label={translate('component.editZellerCategories.categoryLabel')}
      mobileLabel={translate(
        'component.editZellerCategories.mobileCategoryLabel'
      )}
      placeholder={translate(
        'component.editZellerCategories.categoryPlaceholder'
      )}
      items={CATEGORY_ITEMS}
      onChange={onChange}
      renderNoItemsPlaceholder={() => (
        <CategoriesPlaceholder
          title={translate('component.editZellerCategories.noCategories.title')}
          description={translate(
            'component.editZellerCategories.noCategories.description',
            { category: searchInput }
          )}
        />
      )}
      subtext={
        values.category !== initialValue && initialValue
          ? translate('component.editZellerCategories.previously', {
              initialValue: CATEGORY_ITEMS.find(
                (item) => item.value === initialValue
              )?.label,
            })
          : undefined
      }
      validate={validateRequired}
    />
  )
}
