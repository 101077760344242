import { gql } from '@apollo/client'

export const GetTransactionTotals = gql`
  query GetTransactionTotals(
    $filter: TransactionFilterInput!
    $entityUuid: ID!
  ) {
    getTransactionTotalsBigInt(
      filter: $filter
      totalsType: HOURLY
      entityUuid: $entityUuid
    ) {
      period
      totalAmount
      countPurchases
    }
  }
`

export const GetOverviewTransactionTotals = gql`
  query GetOverviewTransactionTotals(
    $filter: TransactionFilterInput!
    $entityUuid: ID!
  ) {
    getTransactionTotalsBigInt(
      filter: $filter
      totalsType: HOURLY
      entityUuid: $entityUuid
    ) {
      period
      refundAmount
      surchargeAmount
      purchaseAmount
      totalAmount
      tipAmount
      taxAmounts {
        name
        amount
      }
      feeAmount
    }
  }
`
