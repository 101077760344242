import { PageTemplate } from '@npco/zeller-design-system'

import { ErrorPage } from 'components/ErrorPage/ErrorPage'

import { HeaderPrimaryRow } from '../components/HeaderPrimaryRow/HeaderPrimaryRow'

export const SimErrorPage = () => {
  return (
    <PageTemplate HeaderPrimaryRow={<HeaderPrimaryRow />}>
      <ErrorPage />
    </PageTemplate>
  )
}
