import { useCallback, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { AccountStatementType } from '@npco/mp-gql-types'
import { renameAndDownloadFile } from '@npco/utils-file'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { UNEXPECTED_ERROR } from 'types/errors'
import { translations } from 'translations/shared.i18n'

import { getTimeUtcOffset10 } from '../../AccountDetailsStatements.utils'
import { accountStatementListTranslations } from '../AccountStatementList.i18n'
import { getAccountStatementTitle, StatementCard } from '../StatementCard'
import { AccountStatementListItemBankingAccountStatementFragment } from './AccountStatementListItem.generated'

interface AccountStatementListItemProps {
  accountStatement: AccountStatementListItemBankingAccountStatementFragment
  statementType: AccountStatementType
  refetchDownloadLink: () => Promise<
    { downloadLink: string } | typeof UNEXPECTED_ERROR
  >
}

export const AccountStatementListItem = ({
  accountStatement,
  statementType,
  refetchDownloadLink,
}: AccountStatementListItemProps) => {
  const t = useTranslations(accountStatementListTranslations)
  const tShared = useTranslations(translations)

  const title = useMemo(() => {
    return getAccountStatementTitle({
      statementType,
      startDate: getTimeUtcOffset10(accountStatement.startDate),
      tShared,
    })
  }, [accountStatement.startDate, statementType, tShared])

  const [isDownloading, setIsDownloading] = useState(false)

  const downloadStatement = useCallback(async () => {
    const downloadFile = async (link: string) => {
      try {
        await renameAndDownloadFile({
          link,
          filename: t('fileName', { date: title }),
          format: 'PDF',
        })

        showSuccessToast(t('downloadSuccess'))
      } catch (err) {
        showErrorToast(t('downloadFailure'))
      } finally {
        setIsDownloading(false)
      }
    }

    const downloadFileUsingNewLink = async () => {
      const newLink = await refetchDownloadLink()

      if (newLink === UNEXPECTED_ERROR) {
        setIsDownloading(false)
        showErrorToast(t('downloadFailure'))
        return UNEXPECTED_ERROR
      }

      return downloadFile(newLink.downloadLink)
    }

    setIsDownloading(true)

    if (getTimeUtcOffset10().isAfter(accountStatement.expires)) {
      downloadFileUsingNewLink()
      return
    }

    downloadFile(accountStatement.downloadLink)
  }, [
    accountStatement.downloadLink,
    title,
    t,
    accountStatement.expires,
    refetchDownloadLink,
  ])

  return (
    <StatementCard
      title={title}
      onClick={downloadStatement}
      isLoading={isDownloading}
    />
  )
}

AccountStatementListItem.fragments = {
  BankingAccountStatement: gql`
    fragment AccountStatementListItemBankingAccountStatementFragment on BankingAccountStatement {
      downloadLink
      expires
      startDate
    }
  `,
}
