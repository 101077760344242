import { COLOR } from '@npco/zeller-design-system'

import { component } from 'translations'

const { admin, association, company, individual, manager, partnership, trust } =
  component.card

export const badgeLabel: any = {
  ADMIN: admin,
  association,
  company,
  individual,
  MANAGER: manager,
  partnership,
  trust,
}

export const getBadgeColorProps = (
  type: string
): Record<string, any> | undefined => {
  switch (type) {
    case 'ADMIN':
      return {
        color: COLOR.RED_1000,
      }
    case 'MANAGER':
      return {
        color: COLOR.BLUE_1000,
      }
    default:
      return undefined
  }
}
