import { useTranslations } from '@npco/utils-translations'
import { ModalForm } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { accountEditModalTranslations } from 'pages/GlobalModals/EditAccountModal/EditAccountModal.i18n'
import { useEditAccount } from 'pages/GlobalModals/EditAccountModal/hooks/useEditAccount'
import { PersonaliseAccountFields } from 'components/AccountFields/PersonaliseAccountFields/PersonaliseAccountFields'

import { ModalBodyContainer } from './EditAccountFields.styled'

interface EditAccountFieldsProps {
  isLoading: boolean
  hideAvatar?: boolean
}
export const EditAccountFields = ({
  isLoading,
  hideAvatar = false,
}: EditAccountFieldsProps) => {
  const t = useTranslations(accountEditModalTranslations)
  const { submitForm, isValid } = useFormikContext()
  const { closeEditAccountModal } = useEditAccount()

  return (
    <ModalForm
      isOpen
      isLoading={isLoading}
      title={t('title')}
      onClickPrimary={submitForm}
      isPrimaryButtonDisabled={!isValid}
      primaryButtonLabel={t('actionSave')}
      onCancel={closeEditAccountModal}
      onClickSecondary={closeEditAccountModal}
      secondaryButtonLabel={t('actionCancel')}
    >
      <ModalBodyContainer>
        <PersonaliseAccountFields hideAvatar={hideAvatar} />
      </ModalBodyContainer>
    </ModalForm>
  )
}
