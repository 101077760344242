import React from 'react'
import { Box } from '@npco/zeller-design-system'

import { useDisplayXeroPaymentServiceConnectionInfo } from 'hooks/xeroPaymentServices/useDisplayXeroPaymentServiceConnectionInfo'
import { translate } from 'utils/translations'

import {
  StyledDescription,
  StyledDetailsLabel,
  StyledDetailsTitle,
  StyledDetailsValue,
  StyledWrapper,
} from '../UserDetails.styled'

export const XeroPaymentServicesSection = () => {
  const shouldDisplayXeroPaymentServicesSection =
    useDisplayXeroPaymentServiceConnectionInfo()
  if (!shouldDisplayXeroPaymentServicesSection) {
    return null
  }

  return (
    <div data-testid="xero-payment-services-section">
      <Box py="16px">
        <StyledDetailsTitle>
          {translate('page.settings.users.xeroPaymentServices.drawerTitle')}
        </StyledDetailsTitle>
      </Box>
      <StyledDescription>
        {translate('page.settings.users.xeroPaymentServices.descriptionAdmin')}
      </StyledDescription>
      <StyledWrapper mt="16px">
        <StyledDetailsLabel>{translate('shared.enabled')}</StyledDetailsLabel>
        <StyledDetailsValue>{translate('shared.yes')}</StyledDetailsValue>
      </StyledWrapper>
    </div>
  )
}
