import { Box, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'
import { shared } from 'translations'

import {
  StyledInputWrapper,
  StyledSearchInput,
} from './SelectSearchInput.styled'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  setValue: (value: string) => void
}

export const SelectSearchInput = ({
  value,
  setValue,
  'aria-label': ariaLabel,
  placeholder = shared.search,
  ...restProps
}: Props) => {
  return (
    <StyledInputWrapper>
      <Box mr="1rem">
        <SvgIcon width="16" height="16">
          <SearchIcon />
        </SvgIcon>
      </Box>

      <StyledSearchInput
        aria-label={ariaLabel}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        placeholder={placeholder}
        {...restProps}
      />
    </StyledInputWrapper>
  )
}
