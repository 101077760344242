import {
  StyledButtonsWrapper,
  StyledDescription,
} from 'components/ExportFileModalContent/ExportFileModalView/ExportFileModalView.styled'
import {
  CancelButton,
  ConfirmButton,
} from 'components/Modal/ModalElements/ModalElements'
import { page, shared } from 'translations'

interface Props {
  closeModal: () => void
  rerenderContent: () => void
}

export const ExportFileExportMessage = ({
  closeModal,
  rerenderContent,
}: Props) => (
  <>
    <StyledDescription>{page.exportFile.generatingFileError}</StyledDescription>
    <StyledButtonsWrapper>
      <ConfirmButton onClick={rerenderContent}>{shared.retry}</ConfirmButton>
      <CancelButton onClick={closeModal}>{shared.cancel}</CancelButton>
    </StyledButtonsWrapper>
  </>
)
