import { useCallback } from 'react'
import { FetchResult } from '@apollo/client'

import { LinkSubcategoryToContact } from 'types/gql-types/LinkSubcategoryToContact'
import { UpdateCategory } from 'types/gql-types/UpdateCategory'

import { TransactionEntityCategoryValues } from '../../EditZellerTransactionCategories/EditTransactionZellerCategories.types'
import { useUpdateContactCategories } from '../../utils/categories/useUpdateContactCategories'
import { useUpdateDebitCardTransactionCategories } from '../useUpdateDebitCardTransactionCategories/useUpdateDebitCardTransactionCategories'
import { CategoryOptions } from './useUpdateCategories.types'
import { getHasAllSucceeded } from './useUpdateCategories.utils'

interface Props {
  debitCardTransactionUuid: string
  contactUuid?: string
  onUpdateTransactionCategories?: (options: CategoryOptions) => void
  onUpdateContactCategories?: (options: CategoryOptions) => void
  onSuccess?: (
    transactionResult: PromiseSettledResult<FetchResult<UpdateCategory>>,
    contactResult?: PromiseSettledResult<FetchResult<LinkSubcategoryToContact>>
  ) => void
  onFailure?: (
    transactionResult: PromiseSettledResult<FetchResult<UpdateCategory>>,
    contactResult?: PromiseSettledResult<FetchResult<LinkSubcategoryToContact>>
  ) => void
}

export const useUpdateCategories = ({
  debitCardTransactionUuid,
  contactUuid,
  onUpdateTransactionCategories,
  onUpdateContactCategories,
  onSuccess,
  onFailure,
}: Props) => {
  const { updateContactCategories, isLinkingSubcategoryToContact } =
    useUpdateContactCategories({
      contactUuid,
      onSuccess: onUpdateContactCategories,
    })

  const { updateTransactionCategories, isUpdatingTransactionCategories } =
    useUpdateDebitCardTransactionCategories({
      debitCardTransactionUuid,
      onSuccess: onUpdateTransactionCategories,
    })

  const updateCategories = useCallback(
    async ({
      category,
      subcategory,
      applyAll,
      subcategoryId,
    }: TransactionEntityCategoryValues) => {
      const results: Promise<FetchResult>[] = []

      if (applyAll) {
        results.push(
          updateTransactionCategories({
            category: null,
            subcategoryName: null,
            subcategoryId: null,
          })
        )

        results.push(
          updateContactCategories({
            category,
            subcategoryId,
            subcategoryName: subcategory,
          })
        )
      } else {
        results.push(
          updateTransactionCategories({
            category,
            subcategoryName: subcategory,
            subcategoryId,
          })
        )
      }

      const resolvedResults = (await Promise.allSettled(results)) as [
        PromiseSettledResult<FetchResult<UpdateCategory>>,
        PromiseSettledResult<FetchResult<LinkSubcategoryToContact>> | undefined
      ]

      if (
        !getHasAllSucceeded(resolvedResults) ||
        !resolvedResults[0].value.data?.updateDebitCardTransactionAnnotations ||
        (resolvedResults[1] &&
          !resolvedResults[1]?.value.data?.linkContactWithSubcategory)
      ) {
        onFailure?.(resolvedResults[0], resolvedResults[1])
      } else {
        onSuccess?.(resolvedResults[0], resolvedResults[1])
      }

      return resolvedResults
    },
    [updateTransactionCategories, updateContactCategories, onSuccess, onFailure]
  )

  return {
    updateCategories,
    isLoading: isUpdatingTransactionCategories || isLinkingSubcategoryToContact,
  }
}
