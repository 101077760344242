import { BodySmall, Box } from '@npco/zeller-design-system'
import { useTheme } from 'styled-components'

import { BorderedButtonFill } from './BorderedButtonFill'
import { CenterAbsoluteContainer } from './CallToAction.styled'

interface CallToActionProps {
  description: React.ReactNode
  icon: React.ReactNode
  dataTestId?: string
  children?: React.ReactNode
}

export const CallToAction = ({
  description,
  icon,
  dataTestId,
  children,
}: CallToActionProps) => {
  const { colors } = useTheme()

  return (
    <CenterAbsoluteContainer data-testid={dataTestId}>
      <Box
        backgroundColor={colors.BLUE_1000}
        color={colors.WHITE}
        width="264px"
        borderRadius="8px"
        position="relative"
      >
        <Box position="absolute" top="-48px" right="-16px">
          {icon}
        </Box>
        <Box p="2px 24px" pb="16px" width="182px">
          <BodySmall>{description}</BodySmall>
          {children}
        </Box>
      </Box>
    </CenterAbsoluteContainer>
  )
}

CallToAction.Button = BorderedButtonFill
