import { COLOR, Flex, SkeletonBasic } from '@npco/zeller-design-system'

import { DataPoint } from './AccountBalanceChart.types'
import { isOdd } from './AccountBalanceChart.utils'
import { BaseLoadingYAxisChartTick } from './BaseLoadingYAxisChartTick/BaseLoadingYAxisChartTick'
import { ChartXAxisTick } from './ChartXAxisTick'
import { LeftNavButton } from './ChartXAxisTick/LeftNavButton'
import { RightNavButton } from './ChartXAxisTick/RightNavButton'

type XAxisTickFunctionProps = {
  x: number
  y: number
  payload: { value: string; index: number; offset: number }
  visibleTicksCount: number
}

type YearVisibleDataType = {
  year?: string
  index: number
}

export const xAxisLoadedTickFunction =
  (
    chartData: DataPoint[],
    isMobile: boolean,
    handleSelectPrevious?: () => void,
    handleSelectNext?: () => void
  ) =>
  ({ x, y, payload, visibleTicksCount }: XAxisTickFunctionProps) => {
    const isFirstAxis = payload.index === 0
    const isLastAxis = payload.index === visibleTicksCount - 1
    const leftNavButton = isFirstAxis ? (
      <LeftNavButton handleSelectPrevious={handleSelectPrevious} />
    ) : undefined
    const rightNavButton = isLastAxis ? (
      <RightNavButton handleSelectNext={handleSelectNext} />
    ) : undefined
    if (isMobile && !isOdd(payload.index)) {
      return (
        <ChartXAxisTick
          x={x}
          y={y}
          payload={payload}
          leftNavButton={leftNavButton}
          rightNavButton={rightNavButton}
        />
      )
    }

    const yearVisibleData: YearVisibleDataType = chartData
      .slice(0, payload.index + 1)
      .reduce(
        (
          acc: YearVisibleDataType,
          currentDataPoint: DataPoint,
          index: number
        ) => {
          if (isMobile ? !isOdd(index) : false) {
            return acc
          }
          if (acc.year !== currentDataPoint.year) {
            return { year: currentDataPoint.year, index }
          }
          return acc
        },
        { year: undefined, index: -1 }
      )

    const { isCurrentMonth } = chartData[payload.index]
    return (
      <ChartXAxisTick
        x={x}
        y={y}
        payload={payload}
        leftNavButton={leftNavButton}
        rightNavButton={rightNavButton}
      >
        <Flex flexDirection="column" alignItems="center" position="relative">
          <span
            style={{
              color: COLOR.BLACK_900,
              fontWeight: isCurrentMonth ? 600 : 'unset',
            }}
          >
            {payload.value}
          </span>
          {yearVisibleData.index === payload.index && (
            <span style={{ color: COLOR.GREY_550 }}>
              {yearVisibleData.year}
            </span>
          )}
        </Flex>
      </ChartXAxisTick>
    )
  }

export const xAxisLoadingTickFunction = ({
  payload,
  x,
  y,
  visibleTicksCount,
}: XAxisTickFunctionProps) => {
  const isFirstAxis = payload.index === 0
  const isLastAxis = payload.index === visibleTicksCount - 1
  return (
    <ChartXAxisTick
      x={x}
      y={y}
      payload={payload}
      leftNavButton={isFirstAxis ? <LeftNavButton /> : undefined}
      rightNavButton={isLastAxis ? <RightNavButton /> : undefined}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="18px"
      >
        <SkeletonBasic
          height={8}
          width={21}
          isRounded
          dataTestId="x-axis-skeleton"
        />
      </Flex>
    </ChartXAxisTick>
  )
}

export const yAxisLoadingTickFunction = (
  props: React.SVGProps<SVGImageElement>
) => {
  return (
    <BaseLoadingYAxisChartTick
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      x={40}
      width={40}
      height={8}
    />
  )
}
