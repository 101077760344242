import { useCallback } from 'react'
import { rvSiteDetails } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { SiteCache } from 'types/site'
import { useFileDrop } from 'components/ModalUploadImage/hooks/useFileDrop'

import { useLogoS3Bucket } from './useLogoS3Bucket'

interface UseLogoUploaderProps {
  closeModal: () => void
  id: string
  previousImageUrl: string | undefined
  setTemporaryImage: React.Dispatch<React.SetStateAction<string>>
}

export const useLogoUploader = ({
  closeModal,
  setTemporaryImage,
  id,
  previousImageUrl,
}: UseLogoUploaderProps) => {
  const { previewUrl, isPreviewLoading, isPreviewError, file, onDrop } =
    useFileDrop(previousImageUrl)
  const handleOnSuccess = useCallback(() => {
    setTemporaryImage(previewUrl)
    const oldSiteDetails = rvSiteDetails() as SiteCache
    rvSiteDetails({
      ...oldSiteDetails,
      receipt: { ...oldSiteDetails.receipt, logo: previewUrl },
    })
    closeModal()
  }, [closeModal, previewUrl, setTemporaryImage])

  const handleOnError = useCallback(() => {
    closeModal()
  }, [closeModal])

  const { isUploadingFileToS3, uploadFileToS3Bucket } = useLogoS3Bucket({
    file,
    id,
    onError: handleOnError,
    onSuccess: handleOnSuccess,
  })

  return {
    isPreviewError,
    isPreviewLoading,
    isProcessing: isUploadingFileToS3,
    onDrop,
    previewUrl,
    uploadFile: uploadFileToS3Bucket,
  }
}
