import { date, object } from 'yup'

import { translate } from 'utils/translations'

import {
  isPlaceholderDateValue,
  toDayjsObject,
  validateScheduleDueDate,
  validateSendDate,
  validateSendIsNotEmpty,
} from './schedule.utils'

export const scheduleSchemaErrors = {
  invoiceDateInvalid: translate(
    'page.invoice.formSections.schedule.invoiceDateInvalid'
  ),
  dueDateOnOrBeforeInvoiceDate: translate(
    'page.invoice.formSections.schedule.dueDateOnOrBeforeInvoiceDateError'
  ),
  dueDateInvalid: translate(
    'page.invoice.formSections.schedule.dueDateInvalid'
  ),
  dueDateOnOrAfterSendDate: translate(
    'page.invoice.formSections.schedule.dueDateOnOrAfterSendDate'
  ),
  sendDateOnOrBeforeDueDate: translate(
    'page.invoice.formSections.schedule.sendDateOnOrBeforeDueDate'
  ),
  sendDateInFuture: translate(
    'page.invoice.formSections.schedule.sendDateInFuture'
  ),
  sendDateInvalid: translate(
    'page.invoice.formSections.schedule.sendDateInvalid'
  ),
}

export const scheduleDateOptionalSchema = date()
  .transform((_, originalValue) => {
    if (!originalValue || isPlaceholderDateValue(originalValue)) {
      return undefined
    }

    return toDayjsObject(originalValue).toDate()
  })
  .typeError(scheduleSchemaErrors.invoiceDateInvalid)

export const scheduleDateRequiredSchema = date()
  .required(scheduleSchemaErrors.invoiceDateInvalid)
  .transform((_, originalValue) => {
    return toDayjsObject(originalValue).toDate()
  })
  .typeError(scheduleSchemaErrors.invoiceDateInvalid)

export const scheduleOptionalSchema = object({
  invoiceDate: scheduleDateOptionalSchema,
  dueDate: scheduleDateOptionalSchema
    .test(
      'isDueDateOnOrBeforeInvoiceDate',
      scheduleSchemaErrors.dueDateOnOrBeforeInvoiceDate,
      validateScheduleDueDate()
    )
    .test(
      'isDueDateValid',
      scheduleSchemaErrors.dueDateInvalid,
      validateScheduleDueDate(true)
    )
    .test(
      'isDueDateOnOrAfterScheduleDate',
      scheduleSchemaErrors.dueDateOnOrAfterSendDate,
      validateSendDate()
    ),
  sendDate: scheduleDateOptionalSchema
    .test(
      'isSendDateValid',
      scheduleSchemaErrors.sendDateInvalid,
      validateSendIsNotEmpty
    )
    .test(
      'isSendDateOnOrBeforeDueDate',
      scheduleSchemaErrors.sendDateOnOrBeforeDueDate,
      validateSendDate()
    )
    .test(
      'isSendInTheFuture',
      scheduleSchemaErrors.sendDateInFuture,
      validateSendDate(true)
    ),
})

export const scheduleRequiredSchema = object({
  dueDate: scheduleDateRequiredSchema,
  invoiceDate: scheduleDateRequiredSchema,
})
