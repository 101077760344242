import {
  Box,
  Flex,
  MiniTabItemProps,
  MiniTabs,
} from '@npco/zeller-design-system'

import CardBackImageStyle1 from 'assets/images/zeller-card-back-style1.png'
import CardBackImageStyle2 from 'assets/images/zeller-card-back-style2.png'
import ZellerCardLoadingBackStyle1 from 'assets/svg/zeller-card-loading-back-style1.svg'
import ZellerCardLoadingBackStyle2 from 'assets/svg/zeller-card-loading-back-style2.svg'

import { CardStyleTabType } from '../../hooks/useCardStyleTabs'
import { LinkCardImage } from '../LinkCardImage/LinkCardImage'

type LinkCardDisplayProps = {
  currentTab: CardStyleTabType
  tabs: MiniTabItemProps<'button'>[]
}

export const LinkCardDisplay = ({ currentTab, tabs }: LinkCardDisplayProps) => {
  return (
    <Flex alignItems="center" flexDirection="column">
      {currentTab === 'style1' ? (
        <LinkCardImage
          dataTestId="card-back-image-style1"
          cardImagePath={CardBackImageStyle1}
          loadingImagePath={ZellerCardLoadingBackStyle1}
        />
      ) : (
        <LinkCardImage
          dataTestId="card-back-image-style2"
          cardImagePath={CardBackImageStyle2}
          loadingImagePath={ZellerCardLoadingBackStyle2}
        />
      )}
      <Box mt="28px">
        <MiniTabs currentTab={currentTab} align="center">
          {tabs.map((tab) => (
            <MiniTabs.Item key={tab.value} {...tab} />
          ))}
        </MiniTabs>
      </Box>
    </Flex>
  )
}
