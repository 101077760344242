import { gql } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { CellContext } from '@tanstack/react-table'

import { CardBalanceAvailableCell } from 'components/CardsView/components/CardsTable/components/CardBalanceCell/components/CardBalanceAvailableCell/CardBalanceAvailableCell'

import { CorporateCardBalanceCellDebitCardV2Fragment } from './CorporateCardBalanceCell.generated'

export const CorporateCardBalanceCell = <T,>({
  cell,
  row,
  getValue,
  table,
  column,
}: CellContext<T, CorporateCardBalanceCellDebitCardV2Fragment>) => {
  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })
  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CorporateCardBalanceCellDebitCardV2Fragment>()

  const cardUserId = data?.customer?.id
  const currentUserId = useLoggedInUser().userData?.id
  const isOwner = !!(
    cardUserId &&
    currentUserId &&
    cardUserId === currentUserId
  )

  return (
    <CardBalanceAvailableCell
      card={data}
      cellId={cell.id}
      isLoading={isLoading}
      cellSize={cellSize}
      isActivateButtonEnabled={isOwner}
      skeletonPropWidth={skeletonLoaderWidth}
    />
  )
}

CorporateCardBalanceCell.fragments = {
  DebitCardV2: gql`
    fragment CorporateCardBalanceCellDebitCardV2Fragment on DebitCardV2 {
      ...CardBalanceAvailableCellDebitCardV2Fragment
      id
      customer {
        id
        entityUuid
      }
    }
    ${CardBalanceAvailableCell.fragments.DebitCardV2}
  `,
}
