import React, { useCallback, useEffect, useMemo } from 'react'
import {
  RemoveBusinessModal,
  useManageBusinessesQueryWithSearch,
  useRemoveBusinessModal,
} from '@npco/mp-feature-manage-businesses'
import { useMultiEntityStore } from '@npco/mp-utils-multi-entity-store'
import { useTranslations } from '@npco/utils-translations'
import { MobileTopBar, TabItemType } from '@npco/zeller-design-system'

import { entitiesMenuTranslations } from './EntitiesMenu.i18n'
import { EntitiesMenuItems } from './EntitiesMenuItems/EntitiesMenuItems'

export interface EntitiesMenuProps {
  entityTabs: TabItemType[]
  activeEntityId: TabItemType['id'] | null
  setSelectedEntityId: (id: TabItemType['id']) => void
  isEntityMenuOpen: boolean
  setIsEntityMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  isSingleBusiness: boolean
  isAddBusinessAllowed: boolean
  isManageBusinessesPage: boolean
}

export const EntitiesMenu = ({
  entityTabs,
  activeEntityId,
  isEntityMenuOpen,
  setIsEntityMenuOpen,
  setSelectedEntityId,
  isSingleBusiness,
  isAddBusinessAllowed,
  isManageBusinessesPage,
}: EntitiesMenuProps) => {
  const t = useTranslations(entitiesMenuTranslations)

  const {
    isRemoveBusinessModalOpen,
    selectedBusinessName,
    onConfirmRemoveBusiness,
    closeRemoveBusinessModal,
    openRemoveBusinessModal,
  } = useRemoveBusinessModal()
  const { addEntitySession } = useMultiEntityStore()

  const {
    entityRelations,
    filteredEntityRelations,
    isLoadingEntityRelations,
    entityRelationsQueryError,
    refetchEntityRelations,
    onChangeSearchTerm,
    onClearSearchTerm,
    searchValue,
  } = useManageBusinessesQueryWithSearch()

  const activeEntityName = useMemo(() => {
    if (isManageBusinessesPage) {
      return t('selectBusiness')
    }
    return (
      entityTabs.find((entityTab) => entityTab.id === activeEntityId)?.title ??
      ''
    )
  }, [entityTabs, isManageBusinessesPage, activeEntityId, t])

  const handleSelectEntity = useCallback(
    (entityId: TabItemType['id']) => {
      if (!entityTabs.find((entityTab) => entityTab.id === entityId)) {
        addEntitySession(entityId)
      }

      setIsEntityMenuOpen(false)
      setSelectedEntityId(entityId)
    },
    [setIsEntityMenuOpen, setSelectedEntityId, entityTabs, addEntitySession]
  )

  useEffect(() => {
    if (isManageBusinessesPage) {
      setIsEntityMenuOpen(true)
    }
  }, [isManageBusinessesPage, setIsEntityMenuOpen])

  if (isSingleBusiness && !isManageBusinessesPage) {
    return null
  }

  return (
    <>
      <MobileTopBar.Menu
        isMenuOpen={isEntityMenuOpen}
        setIsMenuOpen={setIsEntityMenuOpen}
        activeItemName={activeEntityName}
        menuHeader={
          <MobileTopBar.MenuHeaderSearch
            placeholder="Search"
            onChangeSearchTerm={onChangeSearchTerm}
            onClearSearchTerm={onClearSearchTerm}
            name="search"
            searchValue={searchValue}
            ariaLabel="Search businesses"
          />
        }
        closeButtonLabel={t('closeTabsList')}
        menuFooter={
          isAddBusinessAllowed ? (
            <MobileTopBar.MenuFooter buttonText="Add Business" />
          ) : null
        }
        isDisabled={isManageBusinessesPage}
        hideCloseButton={isManageBusinessesPage}
      >
        <EntitiesMenuItems
          openRemoveBusinessModal={openRemoveBusinessModal}
          handleSelectEntity={handleSelectEntity}
          activeEntityId={activeEntityId}
          isAddBusinessAllowed={isAddBusinessAllowed}
          entityRelations={entityRelations}
          filteredEntityRelations={filteredEntityRelations}
          isLoading={isLoadingEntityRelations}
          hasError={entityRelationsQueryError}
          refetch={refetchEntityRelations}
        />
      </MobileTopBar.Menu>
      {isRemoveBusinessModalOpen && (
        <RemoveBusinessModal
          isOpen={isRemoveBusinessModalOpen}
          businessName={selectedBusinessName}
          onConfirm={onConfirmRemoveBusiness}
          hide={closeRemoveBusinessModal}
        />
      )}
    </>
  )
}
