import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import { CreateSavingsAccountLocationSchema } from '../../CreateSavingsAccount.types'

export const useCreateSavingsAccountState = () => {
  const location = useLocationState(CreateSavingsAccountLocationSchema)

  const state = useMemo(() => {
    if (!location?.CreateSavingsAccountModal?.stage) {
      return null
    }

    return location.CreateSavingsAccountModal
  }, [location])

  return { state }
}
