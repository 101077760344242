import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  infoboxHeading: 'Considering settling to a third-party bank?',
  infoboxMessage:
    'Maintaining Zeller Transaction Account as your settlement account is the fastest way to get paid.',
  benefitTitle: 'Benefits of settling to Zeller',
  benefit1: 'Nightly settlements, 365 days a year',
  benefit2: 'Instant spending with Zeller Debit Card',
  benefit3: 'Xero integration for streamlined reconciliation',
  benefit4: 'No monthly account fees or charges',
  settlementDelay:
    'Funds settled into third-party bank accounts will arrive the following business day.',
})
