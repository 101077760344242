import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { useExtendedColumnConfig } from '@npco/ui-table'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  TableCellBasic,
  TableCellSize,
  TableCellSkeleton,
  TooltipBasic,
} from '@npco/zeller-design-system'
import { CellContext } from '@tanstack/react-table'
import { useCorporateCardWalkthroughStage } from 'features/CorporateCardWalkthrough/corporate-card-walkthrough-routing/hooks/useCorporateCardWalkthroughStage/useCorporateCardWalkthroughStage'

import { useCardStatus } from 'hooks/useCardStatus'

import { CardTransactionsMissingDetailsCountCellDebitCardV2Fragment } from './CardTransactionsMissingDetailsCountCell.generated'
import { cardTransactionsMissingDetailsCountCellTranslations } from './CardTransactionsMissingDetailsCountCell.i18n'
import { getOutstandingTransactionsCount } from './CardTransactionsMissingDetailsCountCell.utils'
import { CardTransactionsMissingDetailsCountCellContent } from './CardTransactionsMissingDetailsCountCellContent'

export const CardTransactionsMissingDetailsCountCell = <T,>({
  cell,
  getValue,
  row,
  table,
  column,
}: CellContext<
  T,
  CardTransactionsMissingDetailsCountCellDebitCardV2Fragment
>) => {
  const t = useTranslations(cardTransactionsMissingDetailsCountCellTranslations)
  const { stage } = useCorporateCardWalkthroughStage()

  const { skeletonLoaderWidth, cellSize } = useExtendedColumnConfig<T>({
    row,
    column,
  })

  const isLoading = table.options.meta?.isLoading
  const data = isLoading
    ? undefined
    : getValue<CardTransactionsMissingDetailsCountCellDebitCardV2Fragment>()
  const { isClosed, isInactive } = useCardStatus(data)

  const outstandingTransactionsCount = useMemo(() => {
    return getOutstandingTransactionsCount(
      stage,
      row.index,
      data?.outstandingTransactions ?? 0
    )
  }, [data?.outstandingTransactions, stage, row.index])

  const isOutstandingCountGreaterThanZero = useMemo(
    () => outstandingTransactionsCount > 0,
    [outstandingTransactionsCount]
  )

  return (
    <TooltipBasic
      offset={[0, -20]}
      placement="top"
      renderTrigger={({ handlers }) => (
        <TableCellBasic
          className="card-transactions-missing-details-count-cell"
          dataTestId={cell.id}
          forwardedProps={{ style: { padding: 0 } }}
          isLoading={isLoading}
          justifyContent="center"
          key={cell.id}
          size={TableCellSize.MEDIUM}
          cellTextSize="Small"
          cellSize={cellSize}
          onMouseEnter={
            isOutstandingCountGreaterThanZero
              ? handlers.onMouseEnter
              : undefined
          }
          onMouseLeave={
            isOutstandingCountGreaterThanZero
              ? handlers.onMouseLeave
              : undefined
          }
          onClick={
            isOutstandingCountGreaterThanZero ? handlers.onClick : undefined
          }
        >
          {isLoading && (
            <TableCellSkeleton
              width={skeletonLoaderWidth}
              size={TableCellSize.SMALL}
            />
          )}
          {!isLoading &&
            !isClosed &&
            !isInactive &&
            isOutstandingCountGreaterThanZero && (
              <CardTransactionsMissingDetailsCountCellContent
                count={outstandingTransactionsCount}
              />
            )}
        </TableCellBasic>
      )}
      showArrow={false}
    >
      <Box textAlign="center" width="144px">
        {outstandingTransactionsCount === 1
          ? t('outstandingSingle')
          : t('outstandingPlural', {
              count: outstandingTransactionsCount.toLocaleString(),
            })}
      </Box>
    </TooltipBasic>
  )
}

CardTransactionsMissingDetailsCountCell.fragments = {
  DebitCardV2: gql`
    fragment CardTransactionsMissingDetailsCountCellDebitCardV2Fragment on DebitCardV2 {
      outstandingTransactions
      ...UseCardStatusDebitCardV2Fragment
    }

    ${useCardStatus.fragments.DebitCardV2}
  `,
}
