import { ModalSize } from './Modal.types'

export const modalSmallWidth = '32rem'
export const modalMediumWidth = '50.875rem'

export const getModalWidth = (size: ModalSize | undefined) => {
  switch (size) {
    case ModalSize.MEDIUM:
      return modalMediumWidth
    case ModalSize.SMALL:
    default:
      return modalSmallWidth
  }
}
