export const getTextSplitIntoMultipleLines = (text: string | undefined) => {
  if (!text) {
    return []
  }

  const textSplitIntoMultipleLines = String(text).split('\n')

  return textSplitIntoMultipleLines.map((line, index) => {
    const key = `${line}-${index}`
    return <div key={key}>{line}</div>
  })
}
