import { useTranslations } from '@npco/utils-translations'
import { formatDateMonthYear } from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'

import { translations } from '../DateRange.i18n'
import { DateRangeValue } from '../DateRange.types'
import { DatePickerProps } from './DatePicker.types'
import { translations as periodTranslations } from './PeriodButtons.i18n'
import { getPeriodKey } from './PeriodButtons.utils'

export const getTriggerDateLabel = ({ start, end }: DateRangeValue) => {
  if (!start) {
    return ''
  }

  if (!end) {
    return formatDateMonthYear(start)
  }

  const isRangeSameDay = !!dayjs(start).isSame(end, 'day')

  if (isRangeSameDay) {
    return formatDateMonthYear(end)
  }

  const isRangeSameMonth = !!dayjs(start).isSame(end, 'month')
  if (isRangeSameMonth) {
    const fromLabel = start.getDate()
    const toLabel = formatDateMonthYear(end)
    return [fromLabel, toLabel].filter((a) => a).join(' - ')
  }

  const isRangeSameYear = !!dayjs(start).isSame(end, 'year')
  const fromLabel = isRangeSameYear
    ? dayjs(start).format('D MMM')
    : formatDateMonthYear(start)
  const toLabel = end && formatDateMonthYear(end)
  return [fromLabel, toLabel].filter((a) => a).join(' - ')
}

export const usePeriodOrDateTriggerLabel = () => {
  const t = useTranslations(translations)
  const tPeriod = useTranslations(periodTranslations)

  return (range: DatePickerProps['range']) => {
    const periodKey = range?.end && getPeriodKey(range)
    const periodLabel = periodKey ? tPeriod(periodKey) : ''
    return (
      periodLabel ||
      (range?.start && range.end && getTriggerDateLabel(range)) ||
      t('defaultLabel')
    )
  }
}

export const replaceTimeOfDate = ({ date, time }: { date: Date; time: Date }) =>
  dayjs(date)
    .hour(dayjs(time).hour())
    .minute(dayjs(time).minute())
    .second(dayjs(time).second())
    .toDate()

export const getTimeAdjustedDate = ({
  isDateTime,
  date,
  time,
}: {
  isDateTime: boolean
  date: Date
  time: Date | undefined
}) => {
  return isDateTime && time ? replaceTimeOfDate({ date, time }) : date
}
