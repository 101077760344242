import {
  Box,
  Heading,
  INPUT_SIZE,
  InputAdaptiveField,
  InputAdaptiveTextArea,
} from '@npco/zeller-design-system'
import { Field } from 'formik'

import { validateCompanyName, validateEmail } from 'utils/formValidation'
import { translate } from 'utils/translations'

import { AboutBusinessSocialFields } from '../AboutBusiness/AboutBusinessSocial.fields'
import { StyledGreyText } from './Receipt.styled'

interface ReceiptDetailsProps {
  heading?: string
  messageValue: string | null
  returnsMessageValue: string | null
  showBusinessNameSubtitle?: boolean
}

export const ReceiptDetails = ({
  heading = translate('page.settings.receipt.details'),
  messageValue,
  returnsMessageValue,
  showBusinessNameSubtitle,
}: ReceiptDetailsProps) => {
  return (
    <>
      <Box mb="16px">
        <Heading.H4>{heading}</Heading.H4>
      </Box>
      <Box mb="24px">
        <InputAdaptiveField
          isRequired
          label={translate('page.settings.receipt.name')}
          name="name"
          size={INPUT_SIZE.SMALL}
          validate={validateCompanyName}
        />
        {showBusinessNameSubtitle && (
          <Box mt="8px">
            <StyledGreyText>
              {translate('page.settings.receipt.nameSubtitle')}
            </StyledGreyText>
          </Box>
        )}
      </Box>
      <Box mb="24px">
        <InputAdaptiveField
          label={translate('page.settings.receipt.phone')}
          name="phone"
          size={INPUT_SIZE.SMALL}
        />
      </Box>
      <Box mb="24px">
        <InputAdaptiveField
          label={translate('page.settings.receipt.email')}
          name="email"
          size={INPUT_SIZE.SMALL}
          validate={(value) => validateEmail(value, false)}
        />
      </Box>
      <Field
        component={InputAdaptiveTextArea}
        label={translate('page.settings.receipt.returnsMessage')}
        marginBottom="24px"
        maxLength={500}
        name="returnsMessage"
        rows={3}
        value={returnsMessageValue}
      />
      <Field
        component={InputAdaptiveTextArea}
        label={translate('page.settings.receipt.message')}
        marginBottom="24px"
        maxLength={500}
        name="message"
        rows={3}
        value={messageValue}
      />
      <AboutBusinessSocialFields shouldValidate rowMargin="24px" />
    </>
  )
}
