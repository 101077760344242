import { useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CompleteEmailChange } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/profileSettings'
import { rvEmailChangeStatus } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import {
  CompleteEmailChange as CompleteEmailChangeResponse,
  CompleteEmailChangeVariables,
} from 'types/gql-types/CompleteEmailChange'
import { ApolloClientWithAPIKey } from 'services/graphql'

export const useCompleteEmailChange = () => {
  const { search } = useLocation()

  const code = new URLSearchParams(search).get('code') || ''
  const email = new URLSearchParams(search).get('email') || ''

  const [completeEmailChange, { loading: isCompletingEmailChange }] =
    useMutation<CompleteEmailChangeResponse, CompleteEmailChangeVariables>(
      CompleteEmailChange,
      {
        client: ApolloClientWithAPIKey,
        onCompleted: (responseData) => {
          const status = responseData?.completeEmailChange?.status || null

          rvEmailChangeStatus(status)
        },
        onError: () => null,
      }
    )
  return {
    completeEmailChange: () =>
      completeEmailChange({
        variables: {
          input: { email, code },
        },
      }),
    isCompletingEmailChange,
  }
}
