import { useMemo, useState } from 'react'

import { formatDate, formatToday } from 'utils/date'
import dayjs from 'utils/dayjs'
import { SimBillType } from 'types/sim'
import { groupSimBills } from 'pages/Settings/Sim/BillingHistory/BillingHistory.utils'
import { SimBillDrawer } from 'pages/Settings/Sim/BillingHistory/SimBillDrawer/SimBillDrawer'
import { ListGroupSimBillItem } from 'pages/Settings/Sim/BillingHistory/SimBillsList/ListGroupSimBillItem/ListGroupSimBillItem'
import { StyledSimBillListWrapper } from 'pages/Settings/Sim/BillingHistory/SimBillsList/SimBillsList.styled'
import { renderGroup } from 'components/Lists/GroupedList/Group/Group.utils'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListGroupTitle } from 'components/Lists/ListGroupTitle/ListGroupTitle'

interface SimBillListProps {
  simBills: SimBillType[]
  fetchMoreSimBills: () => void
  hasReachedEnd: boolean
}

export const SimBillList = ({
  simBills,
  fetchMoreSimBills,
  hasReachedEnd,
}: SimBillListProps) => {
  const [selectedSimBills, setSelectedSimBills] = useState<SimBillType | null>(
    null
  )

  const groupedSimBills = useMemo(
    () => groupSimBills(simBills ?? []),
    [simBills]
  )

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = (simBill: SimBillType) => {
    setSelectedSimBills(simBill)
    setIsDrawerOpen(true)
  }

  return (
    <>
      <StyledSimBillListWrapper>
        <GroupedList
          data={groupedSimBills}
          onScrollEnd={fetchMoreSimBills}
          renderGroup={renderGroup({
            getGroupKey: ([firstElement]) =>
              dayjs(firstElement?.timestamp).startOf('day'),
            renderHeader: ([firstElement]) => (
              <ListGroupTitle data-testid="simbills-group-header">
                {formatToday(firstElement?.timestamp, formatDate)}
              </ListGroupTitle>
            ),
          })}
          renderItem={(simBill) => (
            <ListGroupSimBillItem
              data={simBill}
              onRecordSelect={openDrawer}
              key={simBill.simId + simBill.timestamp}
            />
          )}
          hasMore={!hasReachedEnd}
        />
      </StyledSimBillListWrapper>
      <SimBillDrawer
        isOpen={isDrawerOpen}
        data={selectedSimBills}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  )
}
