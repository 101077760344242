import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { page } from 'translations'

import { StyledError, StyledErrorText } from './StaticMapError.styled'

export const StaticMapError = () => (
  <StyledError>
    <Flex alignItems="center" data-testid="static-map-error">
      <SvgIcon width="16" height="16" color={COLOR.RED_1000}>
        <InfoIcon />
      </SvgIcon>
      <Box marginLeft="1rem">
        <StyledErrorText>
          {page.accounts.transactionDetails.staticMap.loadingError}
        </StyledErrorText>
      </Box>
    </Flex>
  </StyledError>
)
