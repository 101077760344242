import { COLOR } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { getCurrentDay } from 'pages/Dashboard/utils/timeUtils'

import { StyledDivider, StyledTooltipLayout } from '../PerformanceChart.styled'
import { PerformanceTooltipSection } from './PerformanceTooltipSection'

interface Props {
  thisTimeLastWeekSales: number
  totalLastWeekSales: number
}

export const TodayTooltipContent = ({
  thisTimeLastWeekSales,
  totalLastWeekSales,
}: Props) => {
  return (
    <StyledTooltipLayout>
      <PerformanceTooltipSection
        label={
          translate(
            'page.dashboard.payments.sitePerformance.chart.today.oneWeekAgoTotal',
            { day: getCurrentDay() }
          ) || ''
        }
        total={totalLastWeekSales}
        color={COLOR.GREY_60}
      />
      <StyledDivider />
      <PerformanceTooltipSection
        label={
          translate(
            'page.dashboard.payments.sitePerformance.chart.today.salesThisDayLastWeek',
            { day: getCurrentDay() }
          ) || ''
        }
        total={thisTimeLastWeekSales}
        color={COLOR.GREY_250}
      />
    </StyledTooltipLayout>
  )
}
