import { useTranslations } from '@npco/utils-translations'
import { ModalBasic } from '@npco/zeller-design-system'

import { ModalUploadImage } from 'components/ModalUploadImage/ModalUploadImage'
import { translationsShared } from 'translations'

import { useLogoUploadModal } from './hooks/useLogoUploadModal'
import { translations } from './LogoUploadModal.i18n'

interface LogoUploadModalProps {
  isOpen: boolean
  logos?: string[]
  onCancelUpload: (logo: string) => void
  onClose: () => void
  onNewUpload: (logo: string) => void
  onSaveLogo: (logos: string[]) => void
}

export const LogoUploadModal = ({
  isOpen,
  logos,
  onCancelUpload,
  onClose,
  onNewUpload,
  onSaveLogo,
}: LogoUploadModalProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(translations)

  const {
    handleClose,
    imageUrl,
    isPreviewLoading,
    isPreviewError,
    isLoadingSubscription,
    onDrop,
    handleSaveLogo,
    setCroppedImage,
    setCroppedFile,
    updatingLogos,
  } = useLogoUploadModal({
    isOpen,
    logos,
    onCancelUpload,
    onClose,
    onNewUpload,
    onSaveLogo,
    t,
  })

  return (
    <ModalBasic
      title={t('title')}
      isOpen={isOpen}
      onCancel={handleClose}
      hasCloseButton
    >
      <ModalBasic.Body>
        <ModalUploadImage
          description={t('body')}
          imageUrl={imageUrl}
          isPreviewLoading={isPreviewLoading}
          isPreviewError={isPreviewError}
          isProcessing={isLoadingSubscription || updatingLogos}
          isSaveDisabled={isPreviewLoading}
          onCrop={setCroppedImage}
          onCropFile={setCroppedFile}
          onDrop={onDrop}
          onSecondaryButtonClick={handleClose}
          primaryButtonLabel={tShared('confirm')}
          secondaryButtonLabel={tShared('cancel')}
          uploadFile={handleSaveLogo}
        />
      </ModalBasic.Body>
    </ModalBasic>
  )
}
