import { BREAKPOINT, Flex, InputAdaptive } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const ResponsiveWrapper = styled.div`
  gap: 16px;
  padding: 16px;
  max-width: 200px;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    max-width: unset;
  }
`

export const InputsWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    flex-direction: row;
  }
`

export const Delimiter = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    display: flex;
    align-self: flex-start;
    height: 55px;
    align-content: center;
    flex-wrap: wrap;
  }
`

export const AmountInput = styled(InputAdaptive)`
  width: 126px;
`
