import { gql, type TypedDocumentNode } from '@apollo/client'

export type UseCardProgressDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    availableAmount: { __typename?: 'Money'; value: string }
    amountLimit: { __typename?: 'Money'; value: string }
  } | null
}

export const UseCardProgressDebitCardV2FragmentDoc = gql`
  fragment UseCardProgressDebitCardV2Fragment on DebitCardV2 {
    velocityControl {
      resetsAt
      availableAmount {
        value
      }
      amountLimit {
        value
      }
    }
  }
` as unknown as TypedDocumentNode<UseCardProgressDebitCardV2Fragment, undefined>
