import { pickBy } from 'ramda'

import { PickerItems } from 'types/picker'
import { component, shared } from 'translations'

const PLACEHOLDER_MAP = {
  status: component.statusPicker.selectStatus,
  type: component.transactionTypePicker.selectType,
  tag: component.entityTagsPicker.selectTags,
  source: component.sourcePicker.selectSource,
}

type PickerUnionType = 'status' | 'type' | 'tag' | 'source'

export const getSelectedItemsInfo = (
  selectedItems: PickerItems,
  type: PickerUnionType
) => {
  const amount = selectedItems.length

  if (amount === 0) {
    return PLACEHOLDER_MAP[type]
  }

  if (amount === 1) {
    return `${selectedItems[0].name}`
  }

  return `${amount} ${type}${
    type === 'status' ? shared.plural2 : shared.plural1
  } ${shared.selected}`
}

export const pickByNil = (data: unknown) => {
  return pickBy((value) => value !== undefined && value !== null, data)
}
