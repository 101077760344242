import { useMemo } from 'react'
import { Box, Flex } from '@npco/zeller-design-system'

import { useSubscribeToAccounts } from 'hooks/banking'
import { useIsScrollActive } from 'hooks/useIsScrollActive'
import { getLastThreeMonthsDateRange } from 'pages/Dashboard/DashboardFinance/AccountBalanceChart/AccountBalanceChart.utils'
import { AccountBalanceChartView } from 'pages/Dashboard/DashboardFinance/AccountBalanceChart/AccountBalanceChartView'
import { useAccountBalanceChartQuery } from 'pages/Dashboard/DashboardFinance/AccountBalanceChart/hooks/useAccountBalanceChartQuery'
import { LoaderList } from 'components/LoaderList'

import { useAccountsListQuery } from './hooks/useAccountsListQuery/useAccountsListQuery'
import { useEntitySavingsAccountProduct } from './hooks/useEntitySavingsAccountProduct/useEntitySavingsAccountProduct'
import { useHasActiveSavingsAccountQuery } from './hooks/useHasActiveSavingsAccountQuery/useHasActiveSavingsAccountQuery'
import { ListAccounts } from './ListAccounts/ListAccounts'
import { SavingsAccountHeader } from './SavingsAccountHeader/SavingsAccountHeader'

export const Accounts = () => {
  const { entity, isLoadingEntity } = useEntitySavingsAccountProduct()
  const { hasActiveSavingsAccount, isLoadingHasActiveSavingsAccount } =
    useHasActiveSavingsAccountQuery()
  const { debitCardAccounts, savingsAccounts, isLoadingAccounts, error } =
    useAccountsListQuery()

  useSubscribeToAccounts({
    shouldCacheNewAccount: true,
    skip: isLoadingAccounts || Boolean(error),
  })

  const dateRange = useMemo(() => getLastThreeMonthsDateRange(), [])
  const {
    accountBalances,
    error: accountBalanceChartQuery,
    refetch,
    isLoadingWithoutCache,
    isTimeZoneUndefined,
  } = useAccountBalanceChartQuery(dateRange)

  const { handleOnScroll } = useIsScrollActive()

  const shouldShowSavingsAccountHeader =
    !isLoadingHasActiveSavingsAccount &&
    !isLoadingEntity &&
    !hasActiveSavingsAccount &&
    entity

  return (
    <Flex flexDirection="column">
      <Box pb="20px">
        <AccountBalanceChartView
          accountBalances={accountBalances}
          error={accountBalanceChartQuery}
          refetch={refetch}
          isLoadingWithoutCache={isLoadingWithoutCache}
          isTimeZoneUndefined={isTimeZoneUndefined}
          dateRange={dateRange}
        />
      </Box>
      <Flex height="100%" minHeight={0} flexDirection="column">
        {isLoadingAccounts ? (
          <Flex height="100%" width="100%">
            <LoaderList />
          </Flex>
        ) : (
          <ListAccounts
            activeAccountHeader={
              shouldShowSavingsAccountHeader && (
                <SavingsAccountHeader entity={entity} />
              )
            }
            accounts={[...savingsAccounts, ...debitCardAccounts]}
            entity={entity}
            handleOnScroll={handleOnScroll}
          />
        )}
      </Flex>
    </Flex>
  )
}
