import {
  BodyDefaultTypography,
  BodySmallTypography,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledLabelsContainer = styled.div`
  flex-grow: 1;
  ${BodySmallTypography};
`

export const StyledHeader = styled.span`
  ${BodyDefaultTypography}
`

export const StyledLabelsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    flex-direction: row;
    gap: 0.375rem;
  }
`

export const StyledDescriptionLabel = styled.div`
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledLabelsDivider = styled.span`
  display: none;
  color: ${({ theme }) => theme.colors.GREY_250};

  &::before {
    content: '•';
  }

  @media (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`
