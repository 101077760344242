import { object } from 'yup'

import {
  customerOptionalSchema,
  customerRequiredSchema,
} from './schemas/customer'
import {
  deliveryOptionalSchema,
  deliveryRequiredSchema,
} from './schemas/delivery'
import { discountSchema } from './schemas/discount'
import {
  itemsApplyTaxSchema,
  itemsExceedsMaxAmountSchema,
  itemsExceedsMinAmountSchema,
  itemsOptionalSchema,
  itemsRequiredSchema,
  itemsTaxInclusiveSchema,
} from './schemas/items'
import {
  scheduleOptionalSchema,
  scheduleRequiredSchema,
} from './schemas/schedule'
import { titleOptionalSchema, titleRequiredSchema } from './schemas/title'

export const saveAndCloseInvoiceValidationSchema = object({
  customer: customerOptionalSchema,
  title: titleOptionalSchema,
  discount: discountSchema,
  delivery: deliveryOptionalSchema,
  items: itemsOptionalSchema,
  itemsApplyTax: itemsApplyTaxSchema,
  itemsTaxInclusive: itemsTaxInclusiveSchema,
  schedule: scheduleOptionalSchema,
  // NOTE: any property defined below is private to errors and will not exist
  // within form values as these are adhoc validation checks
  itemsExceedsMaxAmount: itemsExceedsMaxAmountSchema,
  itemsExceedsMinAmount: itemsExceedsMinAmountSchema,
})

export const sendInvoiceValidationSchema =
  saveAndCloseInvoiceValidationSchema.concat(
    object({
      customer: customerRequiredSchema,
      delivery: deliveryRequiredSchema,
      title: titleRequiredSchema,
      items: itemsRequiredSchema,
      schedule: scheduleRequiredSchema,
    })
  )
