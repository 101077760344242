import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  average: 'Average',
  excellent: 'Excellent',
  good: 'Good',
  unavailable: 'Unavailable',
  veryGood: 'Very Good',
  veryWeak: 'Very Weak',
  weak: 'Weak',
})
