import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'

import { DebitCardTransaction } from '../debitCardTransactionUpdate.types'

type MerchantData = NonNullable<DebitCardTransactionsV2Fragment['merchant']>

type MerchantBasicDetails = Pick<MerchantData, 'id' | 'name' | 'icon'>

export const padWithNulls = (merchant: MerchantBasicDetails): MerchantData => ({
  __typename: 'MerchantDetails',
  id: merchant.id,
  name: merchant.name,
  icon: merchant.icon,
  address: null,
  hours: null,
  phone: null,
  email: null,
  url: null,
  abn: null,
  location: null,
  locationAccuracy: null,
})

export const getExistingTransaction = (
  id: string,
  cachedTransactions: DebitCardTransaction[],
  updatedDebitCardTransactions: DebitCardTransaction[]
) => {
  const existingOptimisticTransaction = updatedDebitCardTransactions.find(
    (optimisticTransaction) => optimisticTransaction.id === id
  )
  const existingCachedTransaction = cachedTransactions.find(
    (cachedTransaction) => cachedTransaction?.id === id
  )

  return existingOptimisticTransaction ?? existingCachedTransaction
}
