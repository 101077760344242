import { useMemo } from 'react'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  rvOptimisticDebitCardTransactions,
  rvUpdatedDebitCardTransactions,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { OutstandingSettingsType } from '../useOutstandingFilterSettings'
import { GetDebitCardTransactionsNewQueryVariables } from './graphql/getDebitCardTransactions.generated'
import { useOptimisticDebitCardTransactions } from './useOptimisticDebitCardTransactions'

interface UseDebitCardTransactionsProps {
  areFiltersInDefaultState: boolean
  limit?: GetDebitCardTransactionsNewQueryVariables['limit']
  filters: GetDebitCardTransactionsNewQueryVariables['filter']
  debitCardId: GetDebitCardTransactionsNewQueryVariables['debitCardId']
  isOutstanding: GetDebitCardTransactionsNewQueryVariables['isOutstanding']
  skip?: boolean
  isLoadingOutstandingFilterSettings: boolean
  outstandingSettings: OutstandingSettingsType
}
export const DEFAULT_TRANSACTIONS_PER_PAGE = 20

export const useDebitCardTransactions = ({
  filters,
  areFiltersInDefaultState,
  limit = DEFAULT_TRANSACTIONS_PER_PAGE,
  debitCardId,
  isOutstanding,
  skip = false,
  outstandingSettings,
  isLoadingOutstandingFilterSettings,
}: UseDebitCardTransactionsProps) => {
  const entityUuid = useSelectedEntityUuid()
  const {
    groupedTransactions,
    loading,
    fetchMoreTransactions,
    hasMore,
    error,
    refetchTransactions,
    handleSelectedTransactionUpdate,
  } = useOptimisticDebitCardTransactions({
    onCompleted: () => {
      if (!areFiltersInDefaultState) {
        rvOptimisticDebitCardTransactions([])
        rvUpdatedDebitCardTransactions([])
      }
    },
    areFiltersInDefaultState,
    variables: {
      entityUuid,
      filter: filters,
      limit,
      debitCardId,
      isOutstanding,
      nextToken: undefined,
    },
    skip,
    outstandingSettings,
    isLoadingOutstandingFilterSettings,
    fetchPolicy: 'network-only',
  })

  const hasNoInitialResults = useMemo(
    () =>
      areFiltersInDefaultState &&
      !isOutstanding &&
      groupedTransactions.length === 0,
    [areFiltersInDefaultState, groupedTransactions.length, isOutstanding]
  )

  return {
    groupedDebitCardTransactions: groupedTransactions,
    hasNoInitialResults,
    onLoadMore: fetchMoreTransactions,
    loading,
    hasMore,
    error,
    refetch: refetchTransactions,
    handleSelectedTransactionUpdate,
  }
}
