import { MultiSelectItemBasic } from 'design-system/Components/MultiSelect'

import { translate } from 'utils/translations'

export const getContent = <T extends string>(
  filterName: string,
  selectedItems: MultiSelectItemBasic[],
  values: T[]
) => {
  const isOnlyOneValueSelected = values.length === 1
  const selectedItem = selectedItems.find(({ value }) => value === values[0])

  return isOnlyOneValueSelected
    ? selectedItem?.label
    : translate('page.accounts.transactionsList.filters.selectedPill', {
        selectedItems: values.length,
        filterName,
      })
}
