import { ReactNode } from 'react'
import { Props as ReactModalProps, Styles } from 'react-modal'

export enum ModalSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export interface ModalBasicProps extends ReactModalProps {
  children: ReactNode
  className?: string
  contentStyle?: React.CSSProperties
  hasCloseButton?: boolean
  isFullScreen?: boolean
  isOpen: boolean
  onCancel: () => void
  overlayStyle?: React.CSSProperties
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  style?: Styles
  title?: React.ReactNode
  size?: ModalSize
}

export interface ModalWithButtonsProps extends ModalBasicProps {
  primaryButtonLabel: string
  onConfirm: () => void
  hasCancelButton?: boolean
  secondaryButtonLabel?: string
  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
}

export interface ModalFormProps
  extends Omit<ModalBasicProps, 'hasCloseButton'> {
  onSave: () => void
  confirmLabel: string
  cancelLabel: string
  isConfirmButtonDisabled?: boolean
  isLoading?: boolean
}
