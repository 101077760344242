import { gql, TypedDocumentNode } from '@apollo/client'

import { GetSubscriptionUrl as GetSubscriptionUrlResponse } from '../../../types/gql-types/GetSubscriptionUrl'

export const GetSubscriptionUrl = gql`
  query GetSubscriptionUrl {
    getSubscriptionUrl {
      __typename
      apiUrl
      wssUrl
      region
    }
  }
` as unknown as TypedDocumentNode<GetSubscriptionUrlResponse>
