import { useCallback } from 'react'
import { List } from '@npco/zeller-design-system'
import { rvSelectedUser } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { GetCustomers_getCustomers as Customer } from 'types/gql-types/GetCustomers'
import { useManageUsers } from 'pages/Settings/Users/UsersList/UserList.hooks'
import { UserDrawer } from 'components/UserDetails/UserDrawer'

import { UserListItem } from '../UserListItem/UserListItem'
import { useSelectedUser } from './hooks/useSelectedUser'

export const UsersList = () => {
  const customers = useManageUsers()
  const { selectedUser, setSelectedUser } = useSelectedUser({ customers })
  const onUserDetailsOpen = useCallback(
    (user: Customer) => {
      setSelectedUser(user.id)
      rvSelectedUser(user.id)
    },
    [setSelectedUser]
  )

  const onDetailsClose = useCallback(() => {
    setSelectedUser(null)
    rvSelectedUser('')
  }, [setSelectedUser])

  return (
    <>
      <List>
        {customers.map((user) => (
          <UserListItem
            customer={user}
            key={user.id}
            onClick={() => onUserDetailsOpen(user)}
          />
        ))}
      </List>

      <UserDrawer
        isOpen={Boolean(selectedUser)}
        selectedUser={selectedUser}
        onClose={onDetailsClose}
        isUsers
      />
    </>
  )
}
