import { useCallback } from 'react'

export const useMarkAsSelfValidate = (errorMessage: string) => {
  return useCallback(
    (value: boolean): string | undefined => {
      if (typeof value !== 'boolean') {
        return errorMessage
      }

      return undefined
    },
    [errorMessage]
  )
}
