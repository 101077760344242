export const MOCK_GRAPHQL_ENABLED_KEY = 'dev.MockGraphQL.isEnabled'
export const getMockGraphQLIsEnabled = () =>
  localStorage.getItem(MOCK_GRAPHQL_ENABLED_KEY) === 'true'
export const setMockGraphQLIsEnabled = (value: boolean) =>
  localStorage.setItem(MOCK_GRAPHQL_ENABLED_KEY, value.toString())

if (getMockGraphQLIsEnabled()) {
  import('mockData/mockServer/handlersMockServer').then(
    ({ setupDevelopmentServiceWorker }) => {
      const mockDevelopmentServiceWorker = setupDevelopmentServiceWorker()

      mockDevelopmentServiceWorker.start()
    }
  )
}
