import { Condition } from 'components/Condition/Condition'

import { useSetPinState } from '../hooks/useSetPinState'
import { SetPinFrame } from './SetPinFrame/SetPinFrame'

export const SetPinStage = () => {
  const { state } = useSetPinState()

  return (
    <Condition shouldShow={state?.stage === 'SetPin'}>
      <SetPinFrame isReplacement={state?.isReplacement} />
    </Condition>
  )
}
