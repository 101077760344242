import { createContext, useContext } from 'react'
import { State } from 'xstate'

import {
  TransferEvent,
  TransferGeneralState,
  TransferZellerState,
} from 'pages/Transfer/Transfer.stateMachine'
import { TransferFieldTypes } from 'pages/Transfer/Transfer.types'

export type TransferStateType = [State<any>, (event: TransferEvent) => void]

export type TransferContextType = {
  transferState: TransferStateType
  transferValues: TransferFieldTypes
  updateTransferValues: (values: TransferFieldTypes) => void
}

export const TransferContext = createContext<TransferContextType | null>(null)

export const useTransferState = () => {
  const context = useContext(TransferContext) as TransferContextType

  if (!context) {
    throw new Error('useTransferState must be used within TransferContext')
  }

  return context
}

export const useIsTransferState = <State = TransferZellerState>(
  parentState: TransferGeneralState
) => {
  const {
    transferState: [state],
  } = useTransferState()
  return (states: State[]) =>
    states.some((zellerState) => state.matches(`${parentState}.${zellerState}`))
}
