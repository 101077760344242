import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex, PageTemplate } from '@npco/zeller-design-system'

import { translations } from './HeaderPrimaryRow.i18n'

type HeaderPrimaryRowProps = {
  children?: React.ReactNode
}
export const HeaderPrimaryRow = ({ children }: HeaderPrimaryRowProps) => {
  const t = useTranslations(translations)

  return (
    <PageTemplate.HeaderPrimaryRow>
      <Flex height="48px" width="100%" justifyContent="space-between">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Breadcrumb.Text>{t('breadcrumb')}</Breadcrumb.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
        {children}
      </Flex>
    </PageTemplate.HeaderPrimaryRow>
  )
}
