import { DebitCardColour, VelocityWindowEnum } from '@npco/mp-gql-types'
import { z } from 'zod'

export enum AddCardStage {
  LandingPageStage = 'LandingPageStage',

  LinkCardIdStage = 'LinkCardIdStage',
  LinkEMLCardInfoStage = 'LinkEMLCardInfoStage',
  LinkSelectAccountStage = 'LinkSelectAccountStage',

  CreateSelectCardTypeStage = 'CreateSelectCardTypeStage',
  CreateSelectCardholderStage = 'CreateSelectCardholderStage',
  CreateSelectAccountStage = 'CreateSelectAccountStage',
  CreateSelectCardFormatStage = 'CreateSelectCardFormatStage',
  CreateSetCardSettingsStage = 'CreateSetCardSettingsStage',
  CreateSpendControlsStage = 'CreateSpendControlsStage',
  CreateSelectCardDesignStage = 'CreateSelectCardDesignStage',
  CreateSelectDeliveryAddressStage = 'CreateSelectDeliveryAddressStage',
  CreateReviewCardStage = 'CreateReviewCardStage',
}

const LandingPageStateSchema = z.object({
  stage: z.literal(AddCardStage.LandingPageStage),
})

export enum LinkCardError {
  NotCardholderError = 'NotCardholderError',
  ManagerBlankCardError = 'ManagerBlankCardError',
  CardIDNotFoundError = 'CardIDNotFoundError',
  CardNumberLengthError = 'CardNumberLengthError',
}

const LinkCardIdStateSchema = z.object({
  stage: z.literal(AddCardStage.LinkCardIdStage),
  linkCardError: z.nativeEnum(LinkCardError).optional(),
})

export type LinkCardIdState = z.TypeOf<typeof LinkCardIdStateSchema>

const LinkEMLCardInfoStageSchema = z.object({
  stage: z.literal(AddCardStage.LinkEMLCardInfoStage),
})

const LinkSelectAccountStateSchema = z.object({
  stage: z.literal(AddCardStage.LinkSelectAccountStage),
  cardId: z.string(),
  accountId: z.string().optional(),
})

export type LinkSelectAccountState = z.TypeOf<
  typeof LinkSelectAccountStateSchema
>

const LinkCardStateSchema = z.union([
  LinkCardIdStateSchema,
  LinkSelectAccountStateSchema,
  LinkEMLCardInfoStageSchema,
])

export enum CardTypeOption {
  DebitCard = 'DebitCard',
  ExpenseCard = 'ExpenseCard',
}

const CreateSelectCardTypeStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSelectCardTypeStage),
  cardType: z.nativeEnum(CardTypeOption).optional(),
})

export type CreateSelectCardTypeState = z.TypeOf<
  typeof CreateSelectCardTypeStateSchema
>

const CardHolderSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
})

const AccountSchema = z.object({
  id: z.string(),
  name: z.string(),
})

const VelocityControlSchema = z.object({
  amountLimit: z.string(),
  maxTransactionValue: z.string(),
  velocityWindow: z.nativeEnum(VelocityWindowEnum),
})

export type VelocityControl = z.TypeOf<typeof VelocityControlSchema>

const CreateSelectCardholderStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSelectCardholderStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema.optional(),
})

export type CreateSelectCardholderState = z.TypeOf<
  typeof CreateSelectCardholderStateSchema
>

const CreateSelectAccountStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSelectAccountStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema,
  account: AccountSchema.optional(),
})

export type CreateSelectAccountState = z.TypeOf<
  typeof CreateSelectAccountStateSchema
>

export enum CardFormatOption {
  Physical = 'Physical',
  Virtual = 'Virtual',
}

const CreateSelectCardFormatStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSelectCardFormatStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema,
  account: AccountSchema,
  cardFormat: z.nativeEnum(CardFormatOption).optional(),
})

export type CreateSelectCardFormatState = z.TypeOf<
  typeof CreateSelectCardFormatStateSchema
>

const CreateSetCardSettingsStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSetCardSettingsStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema,
  account: AccountSchema,
  cardFormat: z.nativeEnum(CardFormatOption),
  nickname: z.string().optional(),
  usePin: z.boolean().optional(),
})

export type CreateSetCardSettingsState = z.TypeOf<
  typeof CreateSetCardSettingsStateSchema
>

const CreateSpendControlsStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSpendControlsStage),
  cardType: z.literal(CardTypeOption.ExpenseCard),
  cardholder: CardHolderSchema,
  account: AccountSchema,
  cardFormat: z.nativeEnum(CardFormatOption),
  nickname: z.string(),
  usePin: z.boolean(),
  velocityControl: VelocityControlSchema.optional(),
})

export type CreateSpendControlsState = z.TypeOf<
  typeof CreateSpendControlsStateSchema
>

const CreateSelectCardDesignStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSelectCardDesignStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema,
  account: AccountSchema,
  cardFormat: z.nativeEnum(CardFormatOption),
  nickname: z.string(),
  usePin: z.boolean(),
  velocityControl: VelocityControlSchema.optional(),
  colour: z.nativeEnum(DebitCardColour).optional(),
  entityAddressTimezone: z.string().optional(),
})

export type CreateSelectCardDesignState = z.TypeOf<
  typeof CreateSelectCardDesignStateSchema
>

export enum AddressPage {
  PredefinedAddress = 'PredefinedAddress',
  CustomAddress = 'CustomAddress',
}

const AddressSchema = z.object({
  street1: z.string().nullable(),
  street2: z.string().nullable(),
  suburb: z.string().nullable(),
  state: z.string().nullable(),
  postcode: z.string().nullable(),
  country: z.string().nullable(),
})

export type Address = z.TypeOf<typeof AddressSchema>

const CreateSelectDeliveryAddressStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateSelectDeliveryAddressStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema,
  account: AccountSchema,
  cardFormat: z.nativeEnum(CardFormatOption),
  nickname: z.string(),
  usePin: z.boolean(),
  velocityControl: VelocityControlSchema.optional(),
  colour: z.nativeEnum(DebitCardColour),
  addressPage: z.nativeEnum(AddressPage).optional(),
  address: AddressSchema.optional(),
  entityAddressTimezone: z.string().optional(),
})

export type CreateSelectDeliveryAddressState = z.TypeOf<
  typeof CreateSelectDeliveryAddressStateSchema
>

const CreateReviewCardStateSchema = z.object({
  stage: z.literal(AddCardStage.CreateReviewCardStage),
  cardType: z.nativeEnum(CardTypeOption),
  cardholder: CardHolderSchema,
  account: AccountSchema,
  cardFormat: z.nativeEnum(CardFormatOption),
  nickname: z.string(),
  usePin: z.boolean(),
  velocityControl: VelocityControlSchema.optional(),
  colour: z.nativeEnum(DebitCardColour),
  address: AddressSchema.optional(),
  entityAddressTimezone: z.string().optional(),
})

export type CreateReviewCardState = z.TypeOf<typeof CreateReviewCardStateSchema>

const CreateCardStateSchema = z.union([
  CreateSelectCardTypeStateSchema,
  CreateSelectCardholderStateSchema,
  CreateSelectAccountStateSchema,
  CreateSelectCardFormatStateSchema,
  CreateSpendControlsStateSchema,
  CreateSetCardSettingsStateSchema,
  CreateSelectCardDesignStateSchema,
  CreateSelectDeliveryAddressStateSchema,
  CreateReviewCardStateSchema,
])

export const AddCardStateSchema = z.union([
  LandingPageStateSchema,
  LinkCardStateSchema,
  CreateCardStateSchema,
])

export type AddCardState = z.TypeOf<typeof AddCardStateSchema>

export const AddCardLocationStateSchema = z.object({
  AddCardModal: AddCardStateSchema,
})
