import { useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { rvThirdPartyAccounts } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  rvDetailedAccount,
  rvDetailedAccountType,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/depositAccount'

import { ThirdPartyBankAccount } from 'types/accounts'
import { Account } from 'types/settings'
import { useDepositAccount } from 'pages/Settings/DepositsSettings/hooks/DepositSettings.hooks'
import { ThirdPartyAccountDetailDrawer } from 'components/DepositAccountDetailDrawer/ThirdPartyAccountDetailDrawer'
import { EditThirdPartyAccountDetailsModal } from 'components/EditAccountDetails/EditThirdPartyAccoutDetailsModal'
import { AccountTileList } from 'components/Tile/AccountTileList/AccountTileList'
import { AccountTileRightControls } from 'components/Tile/AccountTileList/AccountTileRightControls'
import {
  ThirdPartyAccountTileDescription,
  ThirdPartyBankIcon,
} from 'components/Tile/ThirdPartyAccountTile/ThirdPartyAccountTile'

import { DepositExternalAccountAction } from '../DepositAccountDetailAction/DepositExternalAccountAction'

export const DepositExternalAccountsList = () => {
  const { depositAccount } = useDepositAccount()

  const thirdPartyBankAccounts = useReactiveVar(rvThirdPartyAccounts)
  const detailedAccount = useReactiveVar(rvDetailedAccount)

  const getBankIcon = (account: ThirdPartyBankAccount) => (
    <ThirdPartyBankIcon account={account} />
  )

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      {isDrawerOpen && detailedAccount && (
        <ThirdPartyAccountDetailDrawer
          account={detailedAccount as ThirdPartyBankAccount}
          onClose={() => setIsDrawerOpen(false)}
          getIcon={(account) => getBankIcon(account)}
          renderHeaderAction={(account) => (
            <EditThirdPartyAccountDetailsModal
              account={account}
              onSubmit={(updatedAccount) => rvDetailedAccount(updatedAccount)}
            />
          )}
          renderBottomAction={(account) => (
            <DepositExternalAccountAction
              isSelectedAccount={account.id === depositAccount?.id}
              redirectOnDeleteSuccess={() => setIsDrawerOpen(false)}
              account={account}
            />
          )}
        />
      )}
      <AccountTileList<ThirdPartyBankAccount>
        dataTestId="external-accounts-list"
        getIsActive={(account) => depositAccount?.id === account.id}
        renderRightControl={({ isActive, isDisabled }) => (
          <AccountTileRightControls
            isActive={isActive}
            isDisabled={isDisabled}
          />
        )}
        bankAccounts={thirdPartyBankAccounts}
        getTileTitle={(account: ThirdPartyBankAccount) =>
          account.accountName || ''
        }
        getTileIcon={getBankIcon}
        getTileDescription={(account: ThirdPartyBankAccount) => (
          <ThirdPartyAccountTileDescription account={account} />
        )}
        onSelect={(account) => {
          rvDetailedAccountType(Account.EXTERNAL)
          rvDetailedAccount(account)
          setIsDrawerOpen(true)
        }}
      />
    </>
  )
}
