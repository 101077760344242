import { ModalBasicProps, PaperPlaneThrown } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { SuccessAnnouncementModal } from './InvoiceSendSuccessModal.styled'
import { getFormattedDescription } from './InvoiceSendSuccessModal.utils'

export const translations = {
  primaryButtonLabel: translate(
    'page.invoice.sendInvoiceSuccessModalPrimaryButtonLabel'
  ),
  secondaryButtonLabel: translate(
    'page.invoice.sendInvoiceSuccessModalSecondaryButtonLabel'
  ),
  heading: translate('page.invoice.sendInvoiceSuccessModalHeading'),
  description: (withName = true) => {
    return withName
      ? translate('page.invoice.sendInvoiceSuccessModalDescription')
      : translate('page.invoice.sendInvoiceSuccessModalDescriptionWithoutName')
  },
}

interface InvoiceSendSuccessModalProps
  extends Omit<ModalBasicProps, 'children'> {
  onClickPrimary: () => void
  onClickSecondary: () => void
  payerName?: string
  referenceNumber: string
}

export const InvoiceSendSuccessModal = ({
  isOpen,
  onCancel,
  onClickPrimary,
  onClickSecondary,
  payerName,
  referenceNumber,
}: InvoiceSendSuccessModalProps) => {
  const description = getFormattedDescription({
    referenceNumber,
    payerName,
    description: translations.description(Boolean(payerName)),
  })

  return (
    <SuccessAnnouncementModal
      Illustration={<PaperPlaneThrown size="large" />}
      description={description}
      heading={translations.heading}
      isOpen={isOpen}
      onCancel={onCancel}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      primaryButtonLabel={translations.primaryButtonLabel}
      secondaryButtonLabel={translations.secondaryButtonLabel}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    />
  )
}
