import { BREAKPOINT } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { AccountMobileFiltersList } from 'layouts/AccountLayout/AccountMobileFiltersList/AccountMobileFiltersList'
import { useAccountFiltersList } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList'
import {
  DefaultFilterOptions,
  FiltersList,
} from 'components/FiltersList/FiltersList'

interface DebitCardTransactionFiltersProps {
  isFilterButtonDisabled: boolean
  defaultFilters: DefaultFilterOptions
}

export const DebitCardTransactionFilters = ({
  isFilterButtonDisabled,
  defaultFilters,
}: DebitCardTransactionFiltersProps) => {
  const { selectItems, visibleFilters, onSelect, mobileFilters } =
    useAccountFiltersList({ defaultFilters })

  const isBelowBreakpointMD = useIsMobileResolution(BREAKPOINT.MD)

  if (isBelowBreakpointMD) {
    return (
      <AccountMobileFiltersList
        filters={mobileFilters}
        disabled={isFilterButtonDisabled}
      />
    )
  }

  return (
    <FiltersList
      defaultFilters={defaultFilters}
      moreSelectItems={selectItems}
      onSelect={onSelect}
      visibleFilters={visibleFilters}
    />
  )
}
