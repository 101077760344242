import { type Dispatch, type SetStateAction } from 'react'

import { TransferContinueButton } from 'pages/Transfer/TransferFields/TransferSubmitButton/TransferContinueButton'

interface TransferSubmitButtonProps {
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
}

export const TransferSubmitButton = ({
  setContactValidationError,
}: TransferSubmitButtonProps) => {
  return (
    <TransferContinueButton
      setContactValidationError={setContactValidationError}
    />
  )
}
