import { Heading, HeadingStyles } from '@npco/zeller-design-system'

import { ItemRow, ItemText } from './ReviewCard.styled'

interface ItemProps {
  label: React.ReactNode
  value: React.ReactNode
}

export const Item = ({ label, value }: ItemProps) => {
  return (
    <ItemRow>
      <Heading.H4 withStyles={HeadingStyles.H5}>{label}</Heading.H4>
      <ItemText>{value}</ItemText>
    </ItemRow>
  )
}
