import { useMemo } from 'react'
import { DebitCardTransactionStatusV2 } from '@npco/mp-gql-types'
import { SelectTriggerPill, useModalState } from '@npco/zeller-design-system'
import {
  SelectItemBasic,
  SelectSelectedItem,
  SelectSize,
  SelectStandard,
} from 'design-system/Components/Select'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { mapAccountTransactionFiltersToApiFilters } from 'utils/banking/mapAccountTransactionsFiltersToApiFilters/mapAccountTransactionsFiltersToApiFilters'
import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'

import { ExportDebitCardTransactionsModal } from './ExportDebitCardTransactionsModal'
import { ExportTriggerPill } from './ExportTriggerPill/ExportTriggerPill'
import { StyledFiltersWrapper } from './FiltersList.styled'
import { useValidateExportFilterConditions } from './hooks/useValidateExportFilterConditions'

export interface DefaultFilterOptions {
  selectedAccountID: string | undefined
  selectedDebitCardId?: string
  selectedContactId?: string
}

interface Props {
  className?: string
  defaultFilters: DefaultFilterOptions
  moreSelectItems: SelectItemBasic[]
  onSelect: (item: SelectSelectedItem<SelectItemBasic>) => void
  visibleFilters: React.ReactNode[]
}

export const FiltersList = ({
  className,
  defaultFilters,
  moreSelectItems,
  onSelect,
  visibleFilters,
}: Props) => {
  const { ...accountFilters } = useAccountFilters()

  const filters = useMemo(
    () =>
      mapAccountTransactionFiltersToApiFilters({
        ...accountFilters,
        ...defaultFilters,
        // We only allow users to export Approved status.
        // We decided to handle this in the frontend.
        status: [DebitCardTransactionStatusV2.APPROVED],
      }),
    [accountFilters, defaultFilters]
  )

  const { isModalOpen, openModal, closeModal } = useModalState()

  const { shouldDisableExport, tooltipMessage } =
    useValidateExportFilterConditions()

  return (
    <div className={className} data-testid="filters-list">
      <StyledFiltersWrapper data-testid="account-transactions-filters">
        {visibleFilters}
        {moreSelectItems.length > 0 && (
          <SelectStandard
            popperWidth="15rem"
            maxHeight="22rem"
            items={moreSelectItems}
            selectedItem={null}
            onChange={onSelect}
            mobileLabel={translate(
              'page.accounts.transactionsList.filters.moreFiltersTitle'
            )}
            size={SelectSize.Small}
            renderTrigger={(props) => (
              <SelectTriggerPill icon={<Plus />} {...props}>
                {translate('page.accounts.transactionsList.filters.more')}
              </SelectTriggerPill>
            )}
          />
        )}
        <ExportTriggerPill
          isDisabled={shouldDisableExport}
          tooltipMessage={tooltipMessage}
          onClick={openModal}
        />
      </StyledFiltersWrapper>
      <ExportDebitCardTransactionsModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        filters={filters}
      />
    </div>
  )
}
