import { showErrorToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { GetXeroSiteSettings_getXeroSiteSettings as GetXeroSiteSettingsResponseData } from 'types/gql-types/GetXeroSiteSettings'

import { ReceiptFormFields } from './Receipt.types'

export const convertReceiptFormFieldsToSettingsInput = (
  values: ReceiptFormFields
) => ({
  receipt: {
    email: values.email,
    facebook: values.facebook,
    instagram: values.instagram,
    message: values.message,
    name: values.name,
    phone: values.phone,
    returnsMessage: values.returnsMessage,
    twitter: values.twitter,
    website: values.website,
  },
})

export const getInitialValues = (
  invoiceSettings: GetXeroSiteSettingsResponseData
) => ({
  email: invoiceSettings?.receipt?.email ?? '',
  facebook: invoiceSettings?.receipt?.facebook ?? '',
  instagram: invoiceSettings?.receipt?.instagram ?? '',
  logo: invoiceSettings?.receipt?.logo ?? '',
  message: invoiceSettings?.receipt?.message ?? '',
  name: invoiceSettings?.receipt?.name ?? '',
  phone: invoiceSettings?.receipt?.phone ?? '',
  returnsMessage: invoiceSettings?.receipt?.returnsMessage ?? '',
  twitter: invoiceSettings?.receipt?.twitter ?? '',
  website: invoiceSettings?.receipt?.website ?? '',
})

export const displayUpdateFailedToast = () => {
  showErrorToast(
    translate(
      'page.settings.connections.xeroPaymentServices.receipt.errorToast'
    )
  )
}
