import { ApolloCache } from '@apollo/client'

interface CacheDebitCardNameProps {
  cache: ApolloCache<object>
  name: string
  cardId: string
}
export const cacheDebitCardName = ({
  cache,
  cardId,
  name,
}: CacheDebitCardNameProps) => {
  cache.modify({
    id: cache.identify({ id: cardId, __typename: 'DebitCardV2' }),
    fields: {
      name() {
        return name
      },
    },
  })
}
