import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { ContactHeadingContactFragmentDoc } from '../../../../ContactHeading/ContactHeading.generated'

const defaultOptions = {} as const
export type ContactHeadingQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  contactUuid: Types.Scalars['ID']['input']
}>

export type ContactHeadingQueryResponse = {
  __typename?: 'Query'
  getContact: {
    __typename?: 'Contact'
    id: string
    isSelf: boolean | null
    businessName: string | null
    contactType: Types.ContactType
    firstName: string | null
    lastName: string | null
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
  }
}

export const ContactHeadingQuery = gql`
  query ContactHeadingQuery($entityUuid: ID!, $contactUuid: ID!) {
    getContact(entityUuid: $entityUuid, id: $contactUuid) {
      id
      ...ContactHeadingContactFragment
    }
  }
  ${ContactHeadingContactFragmentDoc}
` as unknown as TypedDocumentNode<
  ContactHeadingQueryResponse,
  ContactHeadingQueryVariables
>

/**
 * __useContactHeadingQuery__
 *
 * To run a query within a React component, call `useContactHeadingQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactHeadingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactHeadingQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      contactUuid: // value for 'contactUuid'
 *   },
 * });
 */
export function useContactHeadingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactHeadingQueryResponse,
    ContactHeadingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContactHeadingQueryResponse,
    ContactHeadingQueryVariables
  >(ContactHeadingQuery, options)
}
export function useContactHeadingQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactHeadingQueryResponse,
    ContactHeadingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContactHeadingQueryResponse,
    ContactHeadingQueryVariables
  >(ContactHeadingQuery, options)
}
export type ContactHeadingQueryHookResult = ReturnType<
  typeof useContactHeadingQuery
>
export type ContactHeadingQueryLazyQueryHookResult = ReturnType<
  typeof useContactHeadingQueryLazyQuery
>
export type ContactHeadingQueryQueryResult = Apollo.QueryResult<
  ContactHeadingQueryResponse,
  ContactHeadingQueryVariables
>
