import { useCallback } from 'react'
import {
  ButtonGhost,
  Divider,
  PopperItemOneLine,
  PopperItemOneLineSize,
} from '@npco/zeller-design-system'

import { ReactComponent as Tick } from 'assets/svg/tick.svg'
import { translate } from 'utils/translations'
import { useDatePickerState } from 'components/Filters/NewFilters/DatePicker/hooks/useDatePickerState'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { DateColumnEnum } from '../../InvoiceTable.types'
import {
  isAscendingSelected,
  isDescendingSelected,
} from '../../InvoiceTable.utils'
import * as styled from './DateFilters.styled'
import { DateFiltersContent } from './DateFiltersContent'

export interface DateFiltersProps {
  setIsOpen: (state: boolean) => void
  setShouldCloseOnOutsideClick?: (state: boolean) => void
}

export const DateFilters = ({
  setIsOpen,
  setShouldCloseOnOutsideClick,
}: DateFiltersProps) => {
  const {
    filterInput,
    selectedDateColumn,
    selectedDates,
    setDateFilter,
    setSelectedDateColumn,
    setSortingObject,
    sortingObject,
  } = useInvoicesContext()

  const {
    data,
    enteredTo,
    handleDayClick,
    handleDayMouseEnter,
    handleResetClick,
  } = useDatePickerState({
    initialState: selectedDates,
    isFutureDateDisabled: false,
  })

  const handleClick = useCallback(
    (ascending: boolean) => () => {
      if (
        sortingObject?.ascending === ascending &&
        sortingObject?.columnName === selectedDateColumn
      ) {
        setSortingObject(null)
      } else {
        setSortingObject({ columnName: selectedDateColumn, ascending })
      }
      setIsOpen(false)
    },
    [setSortingObject, selectedDateColumn, setIsOpen, sortingObject]
  )

  const handleClear = useCallback(() => {
    handleResetClick()
    setSelectedDateColumn(DateColumnEnum.NextDue)
    setDateFilter(null)
  }, [handleResetClick, setSelectedDateColumn, setDateFilter])

  return (
    <styled.DateFilersWrapper data-testid="date-filters">
      <styled.SortingWrapper>
        <PopperItemOneLine
          shouldDisplayIcon={isAscendingSelected(
            sortingObject,
            selectedDateColumn
          )}
          icon={Tick}
          label={translate('page.invoiceTable.sorting.dateAscending')}
          onClick={handleClick(true)}
          size={PopperItemOneLineSize.Small}
        />
        <PopperItemOneLine
          shouldDisplayIcon={isDescendingSelected(
            sortingObject,
            selectedDateColumn
          )}
          icon={Tick}
          label={translate('page.invoiceTable.sorting.dateDescending')}
          onClick={handleClick(false)}
          size={PopperItemOneLineSize.Small}
        />
      </styled.SortingWrapper>
      <Divider margin="20px 0" />
      <DateFiltersContent
        setShouldCloseOnOutsideClick={setShouldCloseOnOutsideClick}
        data={data}
        enteredTo={enteredTo}
        handleDayClick={handleDayClick}
        handleDayMouseEnter={handleDayMouseEnter}
        filterInput={filterInput}
        selectedDateColumn={selectedDateColumn}
        setSelectedDateColumn={setSelectedDateColumn}
        setDateFilter={setDateFilter}
      />
      <Divider margin="20px 0" />
      <styled.BottomRow>
        <ButtonGhost onClick={handleClear} disabled={false}>
          {translate('shared.clear')}
        </ButtonGhost>
      </styled.BottomRow>
    </styled.DateFilersWrapper>
  )
}
