import { ModalForm } from '@npco/zeller-design-system'
import { Form, Formik, FormikHelpers } from 'formik'

import { translate } from 'utils/translations'

import { ItemFormFields } from './ItemBaseModalForm.types'
import { validateItemForm } from './ItemBaseModalForm.utils'
import { ItemFields } from './ItemFields/ItemFields'

export const translations = {
  cancel: translate('shared.cancel'),
  errorNotification: translate('page.item.formSections.errorNotification'),
  save: translate('shared.save'),
  successNotification: translate('page.item.formSections.successNotification'),
  title: translate('page.item.formSections.saveItem'),
}

export interface ItemsBaseModalFormProps<T> {
  initialValues: T
  isLoading: boolean
  onClose: () => void
  onSubmit: (values: T, helpers: FormikHelpers<T>) => void
  showAddNewItemCheckbox?: boolean
  showDescription?: boolean
  skuDuplicateError?: string
  title: string
}

export const ItemBaseModalForm = <T extends Partial<ItemFormFields>>({
  initialValues,
  isLoading,
  onClose: handleOnClose,
  onSubmit: handleOnSubmit,
  showAddNewItemCheckbox,
  showDescription,
  skuDuplicateError,
  title,
}: ItemsBaseModalFormProps<T>) => {
  return (
    <Formik<T>
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validate={validateItemForm}
    >
      {({
        handleSubmit: handleFormikSubmit,
        submitForm: handleFormikSubmitForm,
      }) => (
        <Form data-testid="item-create-form" onSubmit={handleFormikSubmit}>
          <ModalForm
            isOpen
            isLoading={isLoading}
            isPrimaryButtonDisabled={isLoading}
            onCancel={handleOnClose}
            onClickPrimary={handleFormikSubmitForm}
            primaryButtonLabel={translations.save}
            secondaryButtonLabel={translations.cancel}
            testId="item-create-modal"
            title={title}
          >
            <ItemFields
              showDescription={showDescription}
              showAddNewItemCheckbox={showAddNewItemCheckbox}
              skuDuplicateError={skuDuplicateError}
            />
          </ModalForm>
        </Form>
      )}
    </Formik>
  )
}
