import { useEffect, useState } from 'react'
import { AccountStatementType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { translations } from 'translations/shared.i18n'

import { getTimeUtcOffset10 } from '../../../../AccountDetailsStatements.utils'
import {
  getAccountStatementTitle,
  getStartOfNextStatementPeriod,
} from '../../../StatementCard'

interface UseInterimTitleProps {
  statementType: AccountStatementType
}

export const useInterimTitle = ({ statementType }: UseInterimTitleProps) => {
  const tShared = useTranslations(translations)

  const [title, setTitle] = useState(
    getAccountStatementTitle({
      statementType,
      startDate: getTimeUtcOffset10(),
      tShared,
    })
  )

  useEffect(() => {
    const currentTime = getTimeUtcOffset10()

    const timeUntilNextStatementPeriod =
      (getStartOfNextStatementPeriod(statementType, currentTime).unix() -
        currentTime.unix()) *
      1000

    const timer = setTimeout(() => {
      setTitle(
        getAccountStatementTitle({
          statementType,
          startDate: getTimeUtcOffset10(),
          tShared,
        })
      )
    }, timeUntilNextStatementPeriod)

    return () => clearTimeout(timer)
  }, [statementType, tShared])

  return { title }
}
