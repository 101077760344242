import { ReactiveVar } from '@apollo/client'

import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import {
  PlaceholderAlignment,
  PlaceholderSize,
} from 'components/Placeholders/Placeholders.types'

import { useTrackDashboardError } from './hooks/useTrackDashboardError'

interface Props {
  retry?: () => void
  rvRetry?: ReactiveVar<(() => void) | null>
  className?: string
  variant?: PlaceholderSize
  alignment?: PlaceholderAlignment
  ['data-testid']?: string
}

export const DashboardErrorPage = ({
  retry,
  rvRetry,
  className,
  variant = PlaceholderSize.Medium,
  alignment = PlaceholderAlignment.Center,
  'data-testid': dataTestId,
}: Props) => {
  useTrackDashboardError({ retry, rvRetry })

  return (
    <GenericErrorPage
      data-testid={dataTestId}
      variant={variant}
      alignment={alignment}
      className={className}
      retry={retry}
    />
  )
}
