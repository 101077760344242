import styled from 'styled-components'

export const StyledLoaderList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
`
