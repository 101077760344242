import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { PopperItemsContentDebitCardAccountV2FragmentDoc } from './PopperItemsContent/PopperItemsContent.generated'

export type PopperItemsDebitCardAccountV2Fragment = {
  __typename?: 'DebitCardAccountV2'
  id: string
  name: string
  type: Types.DebitCardAccountType
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
    animation: string | null
    image: string | null
    images: Array<{
      __typename?: 'Image'
      url: string
      size: Types.ImageSize
    }> | null
  } | null
}

export const PopperItemsDebitCardAccountV2FragmentDoc = gql`
  fragment PopperItemsDebitCardAccountV2Fragment on DebitCardAccountV2 {
    ...PopperItemsContentDebitCardAccountV2Fragment
  }
  ${PopperItemsContentDebitCardAccountV2FragmentDoc}
` as unknown as TypedDocumentNode<
  PopperItemsDebitCardAccountV2Fragment,
  undefined
>
