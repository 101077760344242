import { DepositStatus } from '@npco/mp-gql-types'
import { COLOR, Status } from '@npco/zeller-design-system'

import { ReactComponent as DeclinedIcon } from 'assets/svg/decline.svg'
import { ReactComponent as PendingIcon } from 'assets/svg/pending.svg'
import { ReactComponent as ApprovedIcon } from 'assets/svg/tick.svg'
import { DepositStatusVariants } from 'components/StatusBadge/StatusBadge.types'
import { component } from 'translations'

const variants: DepositStatusVariants = {
  [DepositStatus.SUBMITTED]: {
    icon: <PendingIcon />,
    text: component.transaction.statusBadge.submitted,
    color: COLOR.ORANGE_1000,
  },
  [DepositStatus.COMPLETED]: {
    icon: <ApprovedIcon />,
    text: component.transaction.statusBadge.deposited,
    color: COLOR.GREEN_1600,
  },
  [DepositStatus.FAILED]: {
    icon: <DeclinedIcon />,
    text: component.transaction.statusBadge.failed,
    color: COLOR.RED_1000,
  },
  [DepositStatus.PENDING]: {
    icon: <PendingIcon />,
    text: component.transaction.statusBadge.pending,
    color: COLOR.GREY_550,
  },
  [DepositStatus.SKIPPED]: {
    icon: <DeclinedIcon />,
    text: component.transaction.statusBadge.held,
    color: COLOR.RED_1000,
  },
}

interface DepositStatusBadgeProps {
  status: DepositStatus
}

const DepositStatusBadge = ({ status }: DepositStatusBadgeProps) => {
  const { text, color } = variants[status]

  return <Status color={color} text={text} />
}

export default DepositStatusBadge
