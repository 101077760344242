import { useEffect, useMemo, useState } from 'react'
import DayPicker from 'react-day-picker'
import { useTranslations } from '@npco/utils-translations'
import {
  BUTTON_SIZE,
  ButtonGhost,
  Flex,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { translationsShared } from 'translations/index'

import * as styled from '../DateRange.styled'
import { DateRangeValue } from '../DateRange.types'
import { DatePickerProps } from './DatePicker.types'
import { DateRangeInputs } from './DateRangeInputs'
import { PeriodButtons } from './PeriodButtons'
import { getPeriodKey } from './PeriodButtons.utils'
import { useDateRange } from './useDateRange'

export const DatePicker = ({
  defaultRange,
  range: validRange,
  format = 'datetime',
  onRangeChange,
  showReset = true,
}: DatePickerProps) => {
  const tShared = useTranslations(translationsShared)
  const isXSResolution = useIsMobileResolution()

  const [range, setRange] = useState<DateRangeValue | undefined>({
    start: validRange?.start ?? defaultRange?.start,
    end: validRange?.end ?? defaultRange?.end,
  })

  useEffect(() => {
    if (validRange) {
      setRange(validRange)
    }
  }, [validRange])

  const handleOnRangeChange = (newRange: DateRangeValue | undefined) => {
    setRange(newRange)
    if (
      (newRange?.start && newRange.end) ||
      (!newRange?.start && !newRange?.end)
    ) {
      onRangeChange(newRange)
    }
  }

  const {
    currentlyVisibleFirstMonth,
    handleDayClick,
    handleDayMouseEnter,
    handleDayMouseLeave,
    handleResetClick,
    handleSetPeriod,
    hoveredDay,
    onMonthChange,
    ref,
    resetDisabled,
  } = useDateRange({
    format,
    defaultRange,
    range,
    onRangeChange: handleOnRangeChange,
  })

  const modifiers = useMemo(
    () => ({
      start: range?.start,
      end: range?.end,
      endNotSelected: range?.end,
    }),
    [range?.end, range?.start]
  )
  const periodKey = range?.end && getPeriodKey(range)
  const selectedDays = useMemo(
    () => [
      range?.start,
      {
        from: range?.start,
        to: range?.end ?? hoveredDay,
      },
    ],
    [hoveredDay, range?.end, range?.start]
  )

  return (
    <>
      <styled.DatePickerWrapper
        isDateTime={format === 'datetime'}
        data-testid="day-time-range-picker"
      >
        <DayPicker
          disabledDays={{ after: new Date() }}
          modifiers={modifiers}
          month={currentlyVisibleFirstMonth}
          numberOfMonths={isXSResolution ? 1 : 2}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          onDayMouseLeave={handleDayMouseLeave}
          onMonthChange={onMonthChange}
          ref={ref}
          selectedDays={selectedDays}
          toMonth={new Date()}
        />
      </styled.DatePickerWrapper>
      <DateRangeInputs
        range={range}
        hoveredDay={hoveredDay}
        format={format}
        onRangeChange={handleOnRangeChange}
      />
      <styled.PeriodRow>
        <Flex gap="8px">
          <PeriodButtons
            handleSetPeriod={handleSetPeriod}
            defaultPeriod={periodKey}
          />
        </Flex>
        {showReset && (
          <ButtonGhost
            size={BUTTON_SIZE.XSMALL}
            aria-label={tShared('reset')}
            onClick={handleResetClick}
            disabled={resetDisabled}
          >
            {tShared('reset')}
          </ButtonGhost>
        )}
      </styled.PeriodRow>
    </>
  )
}
