import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import TextTruncate from 'react-text-truncate'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { ButtonLink, Flex } from '@npco/zeller-design-system'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { DeviceCard } from 'components/DeviceCard/DeviceCard'
import { GroupedList } from 'components/Lists/GroupedList/GroupedList'
import { ListLoader } from 'components/Lists/InfiniteList/components/ListLoader/ListLoader'

import { DeviceSite, DeviceWithSiteInfo } from '../Devices.types'
import * as styled from './DeviceSitesList.styled'
import { NoDevices } from './NoDevices'

interface Props {
  sites: DeviceSite[]
  showDetails: (device: DeviceWithSiteInfo) => void
  onScrollEnd: () => void
  hasMoreSites: boolean
}

export const DeviceSitesList = ({
  sites,
  showDetails,
  onScrollEnd,
  hasMoreSites,
}: Props) => {
  const history = useHistory()
  const shortEntityId = useSelectedShortEntityUuid()

  const sitesWithDevices = useMemo(
    () => sites.map(({ devices }) => devices),
    [sites]
  )

  const onSiteSettingsEdit = useCallback(
    (siteId: string) => {
      rvSelectedSite(siteId)
      history.push(
        ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.GENERAL.path
      )
    },
    [history, shortEntityId]
  )

  return (
    <styled.ListWrapper>
      <GroupedList
        data={sitesWithDevices}
        onScrollEnd={onScrollEnd}
        hasMore={hasMoreSites}
        renderGroup={(_, elements, i) => (
          <styled.Group
            key={sites[i].id}
            isLastElement={i === sites.length - 1}
            data-testid="payment-terminals-group"
          >
            <Flex justifyContent="space-between" alignItems="center" mb="1rem">
              <styled.Header data-testid="payment-terminals-group-header">
                <TextTruncate line={2} text={sites[i].name} />
              </styled.Header>
              <ButtonLink
                data-testid={`terminal-settings-site-${sites[i].id}`}
                onClick={() => onSiteSettingsEdit(sites[i].id)}
              >
                {translate('page.terminals.details.editSiteSettingsButton')}
              </ButtonLink>
            </Flex>
            {elements}
            {elements.length === 0 && <NoDevices />}
          </styled.Group>
        )}
        renderItem={(device) => (
          <DeviceCard
            key={device.id}
            device={device}
            onClick={() => showDetails(device)}
          />
        )}
        numberOfElements={sites.length}
        loader={
          <styled.LoaderWrapper>
            <ListLoader />
          </styled.LoaderWrapper>
        }
      />
    </styled.ListWrapper>
  )
}
