import 'utils/yup'

import { InvoiceDiscountConfig } from '@npco/mp-gql-types'
import { object, string } from 'yup'

import { translate } from 'utils/translations'

import {
  INVOICE_DISCOUNT_PERCENTAGE_MAX_AMOUNT,
  INVOICE_DISCOUNT_PERCENTAGE_MIN_AMOUNT,
  INVOICE_DISCOUNT_PRICE_MIN_AMOUNT,
} from '../Invoice.constants'

export const discountSchemaErrors = {
  invoiceDiscountPercentageMaxError: translate(
    'page.invoice.formSections.items.invoiceDiscountPercentageMaxError'
  ),
  invoiceDiscountPercentageMinError: translate(
    'page.invoice.formSections.items.invoiceDiscountPercentageMinError'
  ),
  invoiceDiscountPercentageTypeError: translate(
    'page.invoice.formSections.items.invoiceDiscountPercentageTypeError'
  ),
  invoiceDiscountPriceMinError: translate(
    'page.invoice.formSections.items.invoiceDiscountPriceMinError'
  ),
  invoiceDiscountPriceTypeError: translate(
    'page.invoice.formSections.items.invoiceDiscountPriceTypeError'
  ),
}

export const discountSchema = object({
  config: string().oneOf([
    InvoiceDiscountConfig.AMOUNT,
    InvoiceDiscountConfig.PERCENTAGE,
  ]),
  percentage: string()
    .isStringNumber(discountSchemaErrors.invoiceDiscountPercentageTypeError)
    .maxStringNumber(
      INVOICE_DISCOUNT_PERCENTAGE_MAX_AMOUNT,
      discountSchemaErrors.invoiceDiscountPercentageMaxError
    )
    .minStringNumber(
      INVOICE_DISCOUNT_PERCENTAGE_MIN_AMOUNT,
      discountSchemaErrors.invoiceDiscountPercentageMinError
    ),
  price: string()
    .isStringNumber(discountSchemaErrors.invoiceDiscountPriceTypeError)
    .minStringNumber(
      INVOICE_DISCOUNT_PRICE_MIN_AMOUNT,
      discountSchemaErrors.invoiceDiscountPriceMinError
    ),
})
