import { useTranslations } from '@npco/utils-translations'
import { Box, Flex, SkeletonBasic } from '@npco/zeller-design-system'

import { CopyButtonWithTooltip } from '../../../../../../../../components/CopyButtonWithTooltip'
import { setupFlowDebitCardSensitiveInformationTranslations } from '../../SensitiveCardInformation.i18n'
import { SensitiveInfoLabel } from '../SensitiveContentMobile.styled'

interface CardCVCInformationProps {
  isLoading: boolean
  copySuccessMap: Record<string, boolean>
}

export const CardCVCInformation = ({
  isLoading,
  copySuccessMap,
}: CardCVCInformationProps) => {
  const t = useTranslations(setupFlowDebitCardSensitiveInformationTranslations)

  return (
    <Flex
      justifyContent="space-between"
      position="relative"
      alignItems="center"
      width="94px"
      flexGrow={1}
    >
      <SensitiveInfoLabel>{t('cvcLabel')}</SensitiveInfoLabel>
      <Flex flexGrow={1} justifyContent="flex-end">
        {isLoading && <SkeletonBasic width={52} height={8} isRounded />}
        <Box id="mq-card-cvv" position="absolute" top="3px" left="42px" />
        <Box
          opacity={isLoading ? 0 : 1}
          position="absolute"
          top="4px"
          left="calc(100% - 16px)"
        >
          <CopyButtonWithTooltip
            id="mq-copy-card-cvv"
            copySuccessMap={copySuccessMap}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
