import { convertNumberToLocaleString } from 'utils/localeString'

import { AmountValue } from './Amount.types'

const convertNumberToLabelString = (number: number) => {
  if (number % 1 !== 0) {
    return convertNumberToLocaleString(number)
  }

  return number.toLocaleString()
}

export const getAmountLabel = (amount: AmountValue, currencySymbol: string) => {
  if (amount.max === undefined && amount.min === undefined) {
    return ''
  }

  const min =
    amount.min === undefined
      ? ''
      : `${currencySymbol}${convertNumberToLabelString(amount.min)}`
  const max =
    amount.max === undefined
      ? ''
      : `${currencySymbol}${convertNumberToLabelString(amount.max)}`

  if (amount.max === undefined) {
    return `${min}+`
  }

  if (amount.min === undefined) {
    return `${currencySymbol}0 - ${max}`
  }

  if (amount.min === amount.max) {
    return `${min}`
  }

  return `${min} - ${max}`
}
