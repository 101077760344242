import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { ErrorLogger } from '@npco/utils-error-logger'

import { GeneratePanToken } from './graphql/generatePanToken'
import {
  GeneratePanTokenMutationResponse,
  GeneratePanTokenMutationVariables,
} from './graphql/generatePanToken.generated'

interface UseClientAccessTokenProps {
  skip?: boolean
  debitCardUuid: string
}

export const useClientAccessToken = ({
  skip = false,
  debitCardUuid,
}: UseClientAccessTokenProps) => {
  const entityUuid = useSelectedEntityUuid()

  const [generatePanTokenMutation, { data, loading, error, called }] =
    useMutation<
      GeneratePanTokenMutationResponse,
      GeneratePanTokenMutationVariables
    >(GeneratePanToken, {
      variables: { debitCardId: debitCardUuid, entityUuid },
    })

  useEffect(() => {
    const generatePanToken = async () => {
      try {
        await generatePanTokenMutation()
      } catch (err) {
        ErrorLogger.report('[Banking] Error generating pan token:', err)
      }
    }

    if (!skip) {
      generatePanToken()
    }
  }, [generatePanTokenMutation, skip])

  const clientAccessToken = loading ? undefined : data?.generatePanToken

  return {
    clientAccessToken,
    isLoading: loading || !called,
    generateTokenError: error,
  }
}
