import { createTranslations } from '@npco/utils-translations'

export const summaryStageTranslations = createTranslations({
  payNow: 'Pay Now',
  edit: 'Edit',
  from: 'From',
  accountNumber: 'Account Number',
  to: 'To',
  reference: 'Reference',
  amount: 'Amount',
  crn: 'CRN',
  billerName: 'Biller Name',
  bPayNickname: 'BPAY Nickname',
  billerCode: 'Biller Code',
})
