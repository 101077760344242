import { createTranslations } from '@npco/utils-translations'

export const setupFlowAccountsStageTranslations = createTranslations({
  badge: 'Accounts',
  title: 'Replace your outdated business bank.',
  transactionAccount: 'Zeller Transaction Account',
  point1: 'Real-time fund transfers and BPAY payments.',
  point2: 'Create unlimited free accounts for various sites or projects.',
  point3: 'Seamless reconciliation with Xero integration.',
  savingAccount: 'Zeller Savings Account',
  point4: 'Earn a better interest rate than your big-4 bank.',
  point5: 'Daily interest calculation, paid monthly.',
  point6: 'Withdraw and access funds at any time.',
  checkboxMessage: 'Create a virtual Debit Card',
  errorMessage: 'Card creation was unsuccessful. Please try again later.',
  termsApply: '* Terms apply',
})
