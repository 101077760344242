import { forwardRef, useMemo } from 'react'
import { Source, TransactionStatus, TransactionType } from '@npco/mp-gql-types'
import {
  Box,
  COLOR,
  SvgIcon,
  Title,
  TooltipButton,
} from '@npco/zeller-design-system'

import { cardIcons, issuerIcons } from 'const/payment-providers'
import { getFormattedTransactionAmount } from 'utils/common'
import { formatDayMonth, formatTime } from 'utils/date'
import { checkIfMotoTransaction } from 'utils/transactions'
import { translate } from 'utils/translations'
import { TransactionInList } from 'types/transactions'
import { TransactionStatusBadge } from 'components/StatusBadge/TransactionStatusBadge/TransactionStatusBadge'
import { shared, transactionsErrors } from 'translations'

import * as styled from './ListGroupTransactionItem.styled'
import {
  getPaymentSourceIconAndTranslation,
  getTypeAndMaskedPan,
} from './ListGroupTransactionItem.utils'

interface ListGroupTransactionItemProps {
  data: TransactionInList
  onRecordSelect: (data: TransactionInList) => void
  timestampFormat?: 'time' | 'date'
}

type TransactionErrorKey = keyof typeof transactionsErrors

const Timestamp = ({
  timestamp,
  timestampFormat,
}: {
  timestamp: any
  timestampFormat: 'time' | 'date'
}) => {
  if (timestampFormat === 'date') {
    return (
      <TooltipButton
        placement="top"
        showArrow={false}
        tooltipIcon={<>{formatDayMonth(timestamp)}</>}
        className="tooltip-button"
      >
        {formatTime(timestamp)}
      </TooltipButton>
    )
  }

  return <>{formatTime(timestamp)}</>
}

export const ListGroupTransactionItem = forwardRef<
  HTMLLIElement,
  ListGroupTransactionItemProps
>(({ data, onRecordSelect, timestampFormat = 'time' }, ref) => {
  const isUpdated = useMemo(() => 'subscriptionUpdatedAt' in data, [data])

  const typeAndMaskedPan = getTypeAndMaskedPan(data)
  const { deviceName, siteName, timestamp, scheme, status, type, source } = data
  const transactionErrorText =
    transactionsErrors[String(data.responseCode) as TransactionErrorKey] ===
    undefined
      ? transactionsErrors[shared.unknown as TransactionErrorKey].tooltip
      : transactionsErrors[String(data.responseCode) as TransactionErrorKey]
          .tooltip
  const shouldShowError = status === TransactionStatus.DECLINED
  const motoLabel =
    checkIfMotoTransaction(data) &&
    translate('page.transactions.cardMedia.moto')
  const { icon: paymentSourceIcon, translation: paymentSourceTranslation } =
    getPaymentSourceIconAndTranslation(data)

  return (
    <styled.TransactionItem
      $isUpdated={isUpdated}
      data-testid="transaction-row"
      onClick={() => onRecordSelect(data)}
      ref={ref}
    >
      <styled.Time dateTime={timestamp}>
        <Timestamp timestamp={timestamp} timestampFormat={timestampFormat} />
      </styled.Time>

      <styled.CardType>
        {data?.issuer && (
          <styled.IssuerIcon width="16" height="16" dataTestId="issuer-icon">
            {issuerIcons[data.issuer]}
          </styled.IssuerIcon>
        )}
        <SvgIcon width="40" height="40">
          {cardIcons[scheme]}
        </SvgIcon>
      </styled.CardType>

      <styled.CardNumber>
        <Title>{typeAndMaskedPan}</Title>
        {motoLabel && <styled.Small>&nbsp;· {motoLabel}</styled.Small>}
        {source === Source.VIRTUAL_TERMINAL && (
          <styled.Small>
            &nbsp;· {translate('page.transactions.cardMedia.manualCard')}
          </styled.Small>
        )}
        {source === Source.PAY_BY_LINK && (
          <styled.Small>
            &nbsp;· {translate('page.transactions.cardMedia.payByLink')}
          </styled.Small>
        )}
      </styled.CardNumber>

      <styled.DeviceType>
        <TooltipButton
          placement="top"
          showArrow={false}
          tooltipIcon={
            <SvgIcon width="16" height="16" color={COLOR.BLACK}>
              {paymentSourceIcon}
            </SvgIcon>
          }
        >
          {paymentSourceTranslation}
        </TooltipButton>
      </styled.DeviceType>

      <styled.SiteName>
        {deviceName ? (
          <Box>
            <TooltipButton
              placement="top"
              showArrow={false}
              tooltipIcon={<styled.ItemText>{siteName}</styled.ItemText>}
            >
              {deviceName}
            </TooltipButton>
          </Box>
        ) : (
          <styled.ItemText>{siteName}</styled.ItemText>
        )}
      </styled.SiteName>

      <styled.Status>
        {shouldShowError ? (
          <TooltipButton
            placement="top"
            showArrow={false}
            tooltipIcon={<TransactionStatusBadge transaction={data} />}
          >
            {transactionErrorText}
          </TooltipButton>
        ) : (
          <TransactionStatusBadge transaction={data} />
        )}
      </styled.Status>

      <styled.Amount
        $isDeclined={status === TransactionStatus.DECLINED}
        $isIncoming={type === TransactionType.PURCHASE}
      >
        {getFormattedTransactionAmount(data)}
      </styled.Amount>
    </styled.TransactionItem>
  )
}) as React.ForwardRefExoticComponent<
  ListGroupTransactionItemProps & React.RefAttributes<HTMLDivElement>
>
