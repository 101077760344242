export const splitEmail = (text: string) => {
  const matches = Array.from(text.matchAll(/[+\-.]/g))
  const parts = []

  for (let i = 0; i < matches.length + 1; i += 1) {
    const prevMatch = matches[i - 1]?.index ?? 0
    const match = matches[i]?.index

    parts.push(text.slice(prevMatch, match))
  }

  return parts
}
