import { gql, type TypedDocumentNode } from '@apollo/client'

export type AccountWithAvatarDebitCardAccountV2Fragment = {
  __typename: 'DebitCardAccountV2'
  id: string
  name: string
  icon: {
    __typename?: 'Icon'
    colour: string | null
    letter: string | null
  } | null
}

export const AccountWithAvatarDebitCardAccountV2FragmentDoc = gql`
  fragment AccountWithAvatarDebitCardAccountV2Fragment on DebitCardAccountV2 {
    __typename
    id
    name
    icon {
      colour
      letter
    }
  }
` as unknown as TypedDocumentNode<
  AccountWithAvatarDebitCardAccountV2Fragment,
  undefined
>
