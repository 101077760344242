import { Flex, Spinner } from '@npco/zeller-design-system'

import { OutstandingExpensesButtons } from '../OutstandingExpensesButtons'

export const OutstandingExpensesLoading = () => {
  return (
    <>
      <Flex
        height={{ _: '232px', XS: '154px' }}
        maxWidth="504px"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
      <OutstandingExpensesButtons isSubmitDisabled />
    </>
  )
}
