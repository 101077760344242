import styled from 'styled-components'

export const StyledSelectedValueWrapper = styled.span`
  color: ${({ theme }) => theme.colors.BLUE_1000};
`

export const StyledFilterTextContainer = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`
