import { translate } from 'utils/translations'
import { DrawerItem } from 'components/DrawerItem'

interface Props {
  email: string | null | undefined
}

export const EmailItem = ({ email }: Props) => {
  return (
    <DrawerItem
      label={translate('page.transactionContact.email')}
      value={email || '-'}
    />
  )
}
