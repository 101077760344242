import { LogoFlowContent } from 'layouts/LogoFlowContent/LogoFlowContent'

import { NavbarOnboardingMobile } from './OnboardingContent/NavbarOnboardingMobile'

interface OnboardingContentProps {
  children?: React.ReactNode
  isVerticallyCentered?: boolean
  loaderText?: React.ReactNode
  isLoading?: boolean
  isLoaderContainer?: boolean
}

export const OnboardingContent = ({
  children,
  isVerticallyCentered = true,
  loaderText,
  isLoading,
  isLoaderContainer,
}: OnboardingContentProps) => {
  return (
    <LogoFlowContent
      isVerticallyCentered={isVerticallyCentered}
      loaderText={loaderText}
      isLoading={isLoading}
      isLoaderContainer={isLoaderContainer}
    >
      <NavbarOnboardingMobile />
      {children}
    </LogoFlowContent>
  )
}
