import { gql } from '@apollo/client'
import {
  DebitCardAccountStatus,
  DebitCardAccountType,
} from '@npco/mp-gql-types'

import { errorMessages } from 'translations'

import {
  ValidateAccountFromDebitCardAccountV2Fragment,
  ValidateAccountToDebitCardAccountV2Fragment,
} from './Transfer.validators.generated'

export const validateAccountFrom =
  (
    accounts: (ValidateAccountFromDebitCardAccountV2Fragment | null)[],
    isExternalTransfer?: boolean
  ) =>
  (value: string) => {
    const selectedAccount = accounts.find((account) => account?.id === value)

    if (!selectedAccount) {
      return undefined
    }

    if (selectedAccount.status === DebitCardAccountStatus.SUSPENDED) {
      return errorMessages.transferFromSuspended
    }

    if (selectedAccount.status === DebitCardAccountStatus.CLOSED) {
      return errorMessages.transferFromClosed
    }

    if (
      isExternalTransfer &&
      selectedAccount.type === DebitCardAccountType.SAVINGS
    ) {
      return errorMessages.transferFromSavingsToExternal
    }

    return undefined
  }

export const validateAccountTo =
  (accounts: (ValidateAccountToDebitCardAccountV2Fragment | null)[]) =>
  (value: string) => {
    const selectedAccount = accounts.find((account) => account?.id === value)

    if (!selectedAccount) {
      return undefined
    }

    if (selectedAccount.status === DebitCardAccountStatus.SUSPENDED) {
      return errorMessages.transferToSuspended
    }

    if (selectedAccount.status === DebitCardAccountStatus.CLOSED) {
      return errorMessages.transferToClosed
    }

    return undefined
  }

validateAccountFrom.fragments = {
  DebitCardAccountV2: gql`
    fragment ValidateAccountFromDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      status
      type
    }
  `,
}

validateAccountTo.fragments = {
  DebitCardAccountV2: gql`
    fragment ValidateAccountToDebitCardAccountV2Fragment on DebitCardAccountV2 {
      id
      status
    }
  `,
}
