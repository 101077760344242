import { SimBillingStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import ActiveSimCardImage from 'assets/images/sim-card-black.png'
import DeactivatedSimCardImage from 'assets/images/sim-card-grey.png'

import { translations } from '../../SimTable/SimTable.i18n'

type SimIcon = {
  billingStatus?: SimBillingStatus
  height?: string
  width?: string
}
export const SimIcon = ({ billingStatus, height, width }: SimIcon) => {
  const t = useTranslations(translations)
  const isSimActive =
    billingStatus !== SimBillingStatus.CANCELLED &&
    billingStatus !== SimBillingStatus.CANCELLED_FAILED_PAYMENT

  return (
    <img
      width={width ?? '32px'}
      height={height ?? '20px'}
      src={isSimActive ? ActiveSimCardImage : DeactivatedSimCardImage}
      alt={isSimActive ? t('activeSim') : t('deactivatedSim')}
    />
  )
}
