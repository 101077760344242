import { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { CustomerRole } from '@npco/mp-gql-types'
import {
  rvSelectedUser,
  rvSiteDetails,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { badgeLabel } from 'utils/badge'
import { sortAscendingByProperty } from 'utils/common'
import { Customer, CustomerDetailsWithSites } from 'types/customers'
import AddUserButton from 'pages/Settings/SiteUsers/AddUserButton/AddUserButton'
import { StyledTitle } from 'layouts/SettingsFormLayout'
import { Card } from 'components/Cards/Card'
import { UserDrawer } from 'components/UserDetails/UserDrawer'
import { page } from 'translations'

export const SiteUsers = () => {
  const [areUserDetailsOpen, setAreUserDetailsOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] =
    useState<CustomerDetailsWithSites | null>(null)
  const siteDetails = useReactiveVar(rvSiteDetails)

  useEffect(() => {
    rvSelectedUser('')
  }, [])

  const onUserDetailsOpen = (user: CustomerDetailsWithSites) => {
    setAreUserDetailsOpen(true)
    setSelectedUser(user)
    rvSelectedUser(user.id)
  }

  const onDetailsClose = () => {
    setAreUserDetailsOpen(false)
  }

  const users = useMemo(() => {
    const usersArray = siteDetails?.customers ?? []
    return sortAscendingByProperty<Customer>('firstname')(
      usersArray as Customer[]
    )
  }, [siteDetails])

  return (
    <div data-testid="site-users">
      <StyledTitle>{page.settings.users.assignedUsers}</StyledTitle>

      <AddUserButton />

      {users.map((user) => (
        <Card
          key={user.id}
          type={user.role as string}
          hasBadge={false}
          text={`${user.firstname} ${user.lastname}`}
          textSmall={badgeLabel[user.role as CustomerRole]}
          onCardClick={() =>
            onUserDetailsOpen(user as CustomerDetailsWithSites)
          }
          isSmall
        />
      ))}

      <UserDrawer
        isOpen={areUserDetailsOpen}
        onClose={onDetailsClose}
        selectedUser={selectedUser}
      />
    </div>
  )
}
