import { useMemo } from 'react'
import {
  Contacts,
  InputSelectComboboxItemBasic,
} from '@npco/zeller-design-system'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'
import { uniq } from 'lodash-es'

export type PhoneInputSelectComboboxItem = InputSelectComboboxItemBasic & {
  id: string
}

export const usePhoneSelectCombobox = (
  inputValue: string,
  excludeFromFilteredItems?: string[]
) => {
  const {
    values: {
      customer: { payerContact, attentionToContact },
    },
  } = useFormikContext<InvoiceFormFields>()
  const { readFromCache } = useContactCache()

  const payerFromCache = readFromCache(payerContact?.contactUuid)
  // Grab attention to contact from cache to populate all available emails to select
  const attentionToContactFromCache = readFromCache(
    attentionToContact?.contactUuid,
    true
  )

  const allPhoneItems: PhoneInputSelectComboboxItem[] = useMemo(() => {
    const payerPhone = payerFromCache?.phoneV2?.phone
    const attentionToPhone = attentionToContactFromCache?.phoneV2?.phone

    return uniq(
      [payerPhone, attentionToPhone].filter((phone): phone is string =>
        Boolean(phone)
      )
    ).map((phone) => ({ id: phone, value: phone, label: phone }))
  }, [attentionToContactFromCache, payerFromCache])

  const filteredItems: PhoneInputSelectComboboxItem[] = useMemo(() => {
    return allPhoneItems.filter(
      (item) =>
        item.label.trim().toUpperCase().includes(inputValue.toUpperCase()) &&
        !excludeFromFilteredItems?.includes(item.value)
    )
  }, [allPhoneItems, excludeFromFilteredItems, inputValue])

  return {
    filteredItems,
    renderComboboxItem: Contacts.renderComboboxItem,
  }
}
