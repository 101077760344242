import { createContext } from 'react'
import { AnalyticsInstance } from 'analytics'

type AnalyticsPluginsCustomMethods = AnalyticsInstance['plugins'] & {
  segment: {
    group: (
      groupId: string,
      traits?: any,
      options?: any,
      callback?: () => void
    ) => void
  }
}

export interface AnalyticsInstanceCustom extends AnalyticsInstance {
  plugins: AnalyticsPluginsCustomMethods
}

interface AnalyticsContextProps {
  analytics: AnalyticsInstanceCustom | null
  locationName: React.MutableRefObject<string>
}

export const AnalyticsContext = createContext<AnalyticsContextProps | null>(
  null
)
