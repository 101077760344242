import { useMutation } from '@apollo/client'
import { UpdateDeviceSettings } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'

import {
  UpdateDeviceSettings as UpdateDeviceSettingsType,
  UpdateDeviceSettingsVariables,
} from 'types/gql-types/UpdateDeviceSettings'

export const useUpdateDeviceScreenSettings = () => {
  const [updateDeviceScreenSettings, { loading, error, data }] = useMutation<
    UpdateDeviceSettingsType,
    UpdateDeviceSettingsVariables
  >(UpdateDeviceSettings)

  return {
    data,
    error,
    isLoadingUpdateDeviceScreenSettings: loading,
    updateDeviceScreenSettings,
  }
}
