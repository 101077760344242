import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { DetailsListItem } from 'components/DetailsListItem'
import { page } from 'translations/index'

import { StyledDetailsListItemsDivider } from '../../TransactionDetailsInfo.styled'
import {
  DirectDebitDetailsDebitCardAccountTransactionFragment,
  DirectDebitDetailsDebitCardTransactionV2Fragment,
} from './DirectDebitDetails.generated'
import { directDebitDetailsTranslations } from './DirectDebitDetails.i18n'

interface Props {
  transaction:
    | DirectDebitDetailsDebitCardTransactionV2Fragment
    | DirectDebitDetailsDebitCardAccountTransactionFragment
}

export const DirectDebitDetails = ({ transaction }: Props) => {
  const t = useTranslations(directDebitDetailsTranslations)
  return (
    <>
      <Box mt="24px" mb="24px">
        <DetailsListItem
          label={t('from')}
          value={transaction.payerDetails?.debitCardAccount?.name ?? '-'}
        />
        <DetailsListItem
          label={page.transactionDetails.recipient.accountName}
          value={transaction.payeeDetails?.accountDetails?.name ?? '-'}
        />
      </Box>
      <StyledDetailsListItemsDivider />
    </>
  )
}

DirectDebitDetails.fragments = {
  DebitCardTransactionV2: gql`
    fragment DirectDebitDetailsDebitCardTransactionV2Fragment on DebitCardTransactionV2 {
      payeeDetails {
        accountDetails {
          name
        }
      }
      payerDetails {
        debitCardAccount {
          name
        }
      }
    }
  `,

  DebitCardAccountTransaction: gql`
    fragment DirectDebitDetailsDebitCardAccountTransactionFragment on DebitCardAccountTransaction {
      payeeDetails {
        accountDetails {
          name
        }
      }
      payerDetails {
        debitCardAccount {
          name
        }
      }
    }
  `,
}
