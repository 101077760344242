import { useCallback } from 'react'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  Message,
  MessageSize,
  SvgIcon,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ReactComponent as YourAccount } from 'assets/svg/accounts/your-account.svg'

import { useGoToBlockedAction } from '../../../BlockedAction/blocked-action-routing'
import {
  CreateAccountStage,
  useGoToCreateAccountStage,
} from '../../../CreateAccount'
import { EmptyAccountsEntityFragment } from './EmptyAccounts.generated'
import { emptyAccountsTranslations } from './EmptyAccounts.i18n'
import { Panel } from './EmptyAccounts.styled'

interface EmptyAccountsProps {
  entity?: EmptyAccountsEntityFragment
}

export const EmptyAccounts = ({ entity }: EmptyAccountsProps) => {
  const t = useTranslations(emptyAccountsTranslations)
  const { goToCreateAccountStage } = useGoToCreateAccountStage()
  const { goToBlockedAction } = useGoToBlockedAction()
  const isMobile = useIsMobileResolution()
  const illustrationSize = isMobile ? '140' : '180'

  const openCreateAccountModal = useCallback(() => {
    const isCreateAccountBlocked = entity?.canCreateAccount === false

    if (isCreateAccountBlocked) {
      goToBlockedAction({ blockedAction: 'createAccount' })
      return
    }

    goToCreateAccountStage(CreateAccountStage.AccountNameStage)
  }, [entity?.canCreateAccount, goToBlockedAction, goToCreateAccountStage])

  return (
    <Panel data-testid="empty-accounts-panel">
      <SvgIcon
        width={illustrationSize}
        height={illustrationSize}
        ariaLabel="your-account"
      >
        <YourAccount />
      </SvgIcon>
      <Message
        title={t('title')}
        description={t('description')}
        primaryButton={{
          label: t('actionButton'),
          onClick: openCreateAccountModal,
        }}
        size={MessageSize.LARGE}
        align={isMobile ? 'center' : 'left'}
      />
    </Panel>
  )
}

EmptyAccounts.fragments = {
  Entity: gql`
    fragment EmptyAccountsEntityFragment on Entity {
      canCreateAccount
    }
  `,
}
