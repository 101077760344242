import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type DepositsSettingsContextType = {
  isStepUpAuthRequired: boolean
  setRequiredStepUpAuth: () => void
  depositsAuth0AppState: any
  setDepositsAuth0AppState: (appState: any) => void
  resetOnCancel: () => void
}

export const DepositsSettingContext = createContext<
  DepositsSettingsContextType | undefined
>(undefined)

DepositsSettingContext.displayName = 'DepositsSettingsContext'

const DepositsSettingsProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  const [isStepUpAuthRequired, setIsStepUpAuthRequired] = useState(false)
  const [depositsAuth0AppState, setDepositsAuth0AppState] = useState(undefined)

  const setRequiredStepUpAuth = useCallback(() => {
    setIsStepUpAuthRequired(true)
  }, [setIsStepUpAuthRequired])

  const resetOnCancel = useCallback(() => {
    setDepositsAuth0AppState(undefined)
    setIsStepUpAuthRequired(false)
  }, [setIsStepUpAuthRequired, setDepositsAuth0AppState])

  const value = useMemo(
    () => ({
      isStepUpAuthRequired,
      setRequiredStepUpAuth,
      depositsAuth0AppState,
      setDepositsAuth0AppState,
      resetOnCancel,
    }),
    [
      setRequiredStepUpAuth,
      isStepUpAuthRequired,
      depositsAuth0AppState,
      setDepositsAuth0AppState,
      resetOnCancel,
    ]
  )

  return (
    <DepositsSettingContext.Provider value={value}>
      {children}
    </DepositsSettingContext.Provider>
  )
}

const useDepositsSettingsContext = () => {
  const context = useContext(DepositsSettingContext)

  if (!context) {
    throw new Error(
      'useDepositsSettingsContext must be used within DepositsSettingContext'
    )
  }

  return context
}

export { DepositsSettingsProvider, useDepositsSettingsContext }
