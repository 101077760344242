import { Form, Formik } from 'formik'

import { CreateSavingsAccount } from './CreateSavingsAccount/CreateSavingsAccount'
import { CreateSavingsAccountValues } from './CreateSavingsAccountForm.types'
import { useCreateSavingsAccountForm } from './hooks/useCreateSavingsAccountForm/useCreateSavingsAccountForm'

export const CreateSavingsAccountForm = () => {
  const { activateSavingsAccount, isActivatingSavingsAccount } =
    useCreateSavingsAccountForm()

  return (
    <Formik<CreateSavingsAccountValues>
      initialValues={{ tfn: '' }}
      onSubmit={activateSavingsAccount}
    >
      <Form>
        <CreateSavingsAccount
          isActivatingAccount={isActivatingSavingsAccount}
        />
      </Form>
    </Formik>
  )
}
