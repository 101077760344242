import { useTranslations } from '@npco/utils-translations'
import { INFOBOX_VARIANT } from '@npco/zeller-design-system'

import { ExpenseCardBudgetMessage } from '../components/ExpenseCardBudgetMessage'
import { expenseCardBudgetExceededTranslations } from './ExpenseCardBudgetExceededWarningMessage.i18n'

interface ExpenseCardBudgetExceededWarningMessageProps {
  isAdmin: boolean
  onAddFunds: () => void
}

export const ExpenseCardBudgetExceededWarningMessage = ({
  isAdmin,
  onAddFunds,
}: ExpenseCardBudgetExceededWarningMessageProps) => {
  const t = useTranslations(expenseCardBudgetExceededTranslations)
  return (
    <ExpenseCardBudgetMessage
      dataTestId="expense-card-budget-exceeded-warning-message"
      variant={INFOBOX_VARIANT.WARNING}
      isAdmin={isAdmin}
      messageAdmin={t('messageAdmin')}
      messageNonAdmin={t('message')}
      buttonMessage={t('addFundsButton')}
      onClick={onAddFunds}
    />
  )
}
