import { EntityCategories } from '@npco/mp-gql-types'
import {
  SelectItemBasic,
  SelectSelectedItem,
} from 'design-system/Components/Select'
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikContextType,
} from 'formik'

import { GetSubcategories_getEntitySubcategories as SubcategoryType } from 'types/gql-types/GetSubcategories'

export type Subcategory = SubcategoryType

export interface EntityCategoryValues {
  category: EntityCategories | null
  subcategory: string | null | undefined
  subcategoryId: string | null | undefined
}

export enum EditState {
  Select,
  Add,
  Edit,
}

export type SubcategoryItem = SelectItemBasic & {
  id: string
}

export interface UpdateValuesFields<V, I extends SelectItemBasic> {
  field: FieldInputProps<any>
  meta: FieldMetaProps<any>
  handlers: FieldHelperProps<any>
  formik: FormikContextType<V>
  newItem: SelectSelectedItem<I>
}

export interface NewSubcategory {
  newSubcategory: Subcategory | undefined
}
