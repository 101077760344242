import { ReceiptIncluded } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { page } from 'translations'

interface ReceiptListItem {
  label: string
  value: ReceiptIncluded
}

export const receiptItems: ReceiptListItem[] = [
  {
    label: page.accounts.transactionsList.filters.receiptValues.withReceipt,
    value: ReceiptIncluded.INCLUDED,
  },
  {
    label: page.accounts.transactionsList.filters.receiptValues.withoutReceipt,
    value: ReceiptIncluded.NOT_INCLUDED,
  },
]
