import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledError = styled.div`
  margin-top: 0.5rem;
  padding: 1.25rem 1rem;
  background-color: ${({ theme }) => theme.colors.GREY_30};
  border-radius: 7px;
`

export const StyledErrorText = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`
