import { translate } from 'utils/translations'
import { BankAccountFieldsProps } from 'forms/formViews/BankAccountFields/BankAccountFields.types'

import { ThirdPartyAccountField } from '../ThirdPartyAccountField/ThirdPartyAccountField'
import {
  StyledConfirmModal,
  StyledConfirmModalContentContainer,
  StyledConsent,
} from './AddThirdPartyAccountConfirm.styled'

interface Props {
  values: BankAccountFieldsProps
  onSubmitNewAccount: () => Promise<void>
  isLoadingAddThirdPartyAccount: boolean
  onBack: () => void
  isOpen: boolean
}

export const AddThirdPartyAccountConfirm = ({
  values,
  onSubmitNewAccount,
  isLoadingAddThirdPartyAccount,
  onBack,
  isOpen,
}: Props) => {
  return (
    <StyledConfirmModal
      isOpen={isOpen}
      title={translate('page.settings.addExternalAccount')}
      primaryButtonLabel={translate('page.transfer.addAccountModalTitle')}
      secondaryButtonLabel={translate('shared.back')}
      hasCancelButton
      isPrimaryButtonDisabled={isLoadingAddThirdPartyAccount}
      isPrimaryButtonLoading={isLoadingAddThirdPartyAccount}
      onConfirm={onSubmitNewAccount}
      onCancel={onBack}
    >
      <StyledConfirmModalContentContainer>
        <ThirdPartyAccountField
          label={translate('form.newExternalAccount.accountName')}
          value={values.accountName}
        />
        <ThirdPartyAccountField
          label={translate('form.newExternalAccount.accountBsb')}
          value={values.accountBsb}
        />
        <ThirdPartyAccountField
          label={translate('form.newExternalAccount.accountNumber')}
          value={values.accountNumber}
        />
        <StyledConsent data-testId="confirm-add-account-content">
          {translate('form.bankAccount.consent')}
        </StyledConsent>
      </StyledConfirmModalContentContainer>
    </StyledConfirmModal>
  )
}
