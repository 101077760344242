import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { CreateSavingsAccountState } from '../../CreateSavingsAccount.types'

export const useGoToCreateSavingsAccount = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const createState = useCallback(
    (state: CreateSavingsAccountState | null) => ({
      CreateSavingsAccountModal: state,
    }),
    []
  )

  const goToCreateSavingsAccount = useCallback(
    (state: CreateSavingsAccountState | null) => {
      navigate(location.pathname, {
        state: createState(state),
      })
    },
    [navigate, location.pathname, createState]
  )

  return { goToCreateSavingsAccount, createState }
}
