import { lazy, Suspense } from 'react'

import type { SelfieVerificationProps } from './SelfieVerification'
import { SelfieVerificationLoader } from './SelfieVerificationLoader'

const SelfieVerification = lazy(() => import('./SelfieVerification'))

type SelfieVerificationLazyProps = SelfieVerificationProps

export const SelfieVerificationLazy = (props: SelfieVerificationLazyProps) => (
  <Suspense fallback={<SelfieVerificationLoader />}>
    <SelfieVerification {...props} />
  </Suspense>
)
