import { Customized, Pie, PieChart, Tooltip as RechartsTooltip } from 'recharts'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { TransactionTotal } from '../../DashboardPerformance.types'
import { LoadingCentreText } from './CentreText/LoadingCentreText'
import { TodayCentreText } from './CentreText/TodayCentreText'
import { useTodayPerformanceChartData } from './hooks/useTodayPerformanceChartData'
import { PerformanceTooltip } from './PerformanceTooltip/PerformanceTooltip'
import { TodayTooltipContent } from './PerformanceTooltip/TodayTooltipContent'

interface Props {
  salesTotals: TransactionTotal[]
  disabled: boolean
  isLoading: boolean
}

export const TodayPerformanceChart = ({
  salesTotals,
  disabled,
  isLoading,
}: Props) => {
  const isMobile = useIsMobileResolution()

  const {
    totalLastWeekData,
    thisTimeLastWeekData,
    todayData,
    todaySales,
    totalSalesOneWeekAgo,
    salesThisTimeOneWeekAgo,
  } = useTodayPerformanceChartData({ salesTotals, disabled, isLoading })

  return (
    <PieChart
      width={256}
      height={256}
      data-testid="performance-summary-pi-chart"
    >
      <Customized
        key={`center-text-${todaySales}-${totalSalesOneWeekAgo}-${isLoading}`}
        component={
          isLoading ? (
            <LoadingCentreText />
          ) : (
            <TodayCentreText
              todaySales={todaySales}
              totalSalesOneWeekAgo={totalSalesOneWeekAgo}
            />
          )
        }
      />
      <Pie
        data={totalLastWeekData}
        dataKey="percentage"
        innerRadius="95%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        cornerRadius={5}
        paddingAngle={1}
        stroke=""
      />
      <Pie
        data={thisTimeLastWeekData}
        dataKey="percentage"
        innerRadius="95%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        cornerRadius={5}
        paddingAngle={1}
        stroke=""
      />
      <Pie
        data={todayData}
        dataKey="percentage"
        innerRadius="86%"
        outerRadius="90%"
        startAngle={90}
        endAngle={-270}
        cornerRadius={5}
        blendStroke
      />
      <RechartsTooltip
        isAnimationActive={false}
        position={{ x: isMobile ? 40 : -40, y: isMobile ? -30 : 30 }}
        content={
          <PerformanceTooltip
            content={
              <TodayTooltipContent
                totalLastWeekSales={totalSalesOneWeekAgo}
                thisTimeLastWeekSales={salesThisTimeOneWeekAgo}
              />
            }
          />
        }
      />
    </PieChart>
  )
}
