import {
  BodyDefault,
  BodySmallTypography,
  Box,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Table = styled.div`
  display: grid;
  grid-template-columns: auto 125px;
  border: solid 1px ${({ theme }) => theme.colors.GREY_250};
  border-radius: 10px;
  margin-bottom: 20px;

  .leftCell {
    border-right: solid 1px ${({ theme }) => theme.colors.GREY_250};
  }
  .topCell {
    border-bottom: solid 1px ${({ theme }) => theme.colors.GREY_250};
  }
  .rightCell {
    text-align: center;
  }
`

export const TableCell = styled(Box)`
  padding: 14px 16px;
  color: ${({ theme }) => theme.colors.GREY_550};
  ${BodySmallTypography}
`

export const Description = styled(BodyDefault)`
  width: 100%;
  margin: 20px 0;
`
