import {
  conditionalClassName as cn,
  PlusIcon,
  SelectBasic,
  SelectComponentsContent,
  SelectSize,
  SelectStyle,
  SelectTriggerRenderProps,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

import { MergeTag } from '../../InvoiceDeliveryAccordion.constants'
import {
  InsertButton,
  InsertButtonWrapper,
  MergeTagItem,
} from './InvoiceEmailMergeTags.styled'

export const translations = {
  amountLabel: translate('page.invoice.formSections.email.mergeTagAmountLabel'),
  businessNameLabel: translate(
    'page.invoice.formSections.email.mergeTagBusinessNameLabel'
  ),
  dueDateLabel: translate(
    'page.invoice.formSections.email.mergeTagDueDateLabel'
  ),
  invoiceNumberLabel: translate(
    'page.invoice.formSections.email.mergeTagInvoiceNumberLabel'
  ),
  personNameLabel: translate(
    'page.invoice.formSections.email.mergeTagPersonNameLabel'
  ),
  paymentLink: translate(
    'page.invoice.formSections.email.mergeTagPaymentLinkLabel'
  ),
  insertButton: translate('page.invoice.formSections.email.insertButtonLabel'),
}

const mergeTagsOptionItems: MergeTagOptionItem[] = [
  {
    label: translations.amountLabel,
    value: MergeTag.TotalPrice,
  },
  {
    label: translations.businessNameLabel,
    value: MergeTag.BusinessName,
  },
  {
    label: translations.dueDateLabel,
    value: MergeTag.Schedule,
  },
  {
    label: translations.invoiceNumberLabel,
    value: MergeTag.InvoiceNumber,
  },
  {
    label: translations.personNameLabel,
    value: MergeTag.PersonName,
  },
]

export type MergeTagOptionItem = {
  label: string
  isDisabled?: boolean
  value: MergeTag
}

const renderTrigger = ({
  isOpen,
  onClick,
  ref,
}: SelectTriggerRenderProps<MergeTagOptionItem>) => (
  <InsertButton onClick={onClick} ref={ref} $isOpen={isOpen}>
    <PlusIcon width="14" height="14" />
    <span>{translations.insertButton}</span>
  </InsertButton>
)

interface InvoiceEmailMergeTagsProps {
  onSelectTag: (mergeTag: MergeTag) => void
}

export const InvoiceEmailMergeTags = ({
  onSelectTag,
}: InvoiceEmailMergeTagsProps) => {
  return (
    <InsertButtonWrapper>
      <SelectBasic<MergeTagOptionItem>
        items={mergeTagsOptionItems}
        maxHeight="unset"
        menuStyle={SelectStyle.Standard}
        // No need for onChange event
        onChange={() => undefined}
        popperWidth="179px"
        renderItem={({ item, ...renderProps }) => {
          return (
            <MergeTagItem
              aria-disabled={Boolean(item.isDisabled)}
              data-testid="select-adaptive-item"
              {...renderProps}
              className={cn({
                [SelectSize.Medium]: true,
                isDisabled: Boolean(item.isDisabled),
              })}
              onClick={() => onSelectTag(item.value)}
            >
              <SelectComponentsContent
                {...item}
                hasValue
                size={SelectSize.Small}
              />
            </MergeTagItem>
          )
        }}
        renderTrigger={renderTrigger}
        selectedItem={null}
      />
    </InsertButtonWrapper>
  )
}
