import React from 'react'
import { useTranslations } from '@npco/utils-translations'
import { ButtonFill, ButtonGhost } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { EXTERNAL_SHOP_SIM_URL } from 'const/externalLinks'

import { translations } from './PurchaseSimButton.i18n'

type PurchaseSimButtonProps = {
  isFilled?: boolean
}

export const PurchaseSimButton = ({
  isFilled = false,
}: PurchaseSimButtonProps) => {
  const isMobile = useIsMobileResolution()
  const t = useTranslations(translations)

  const handleClick = () => {
    window.open(EXTERNAL_SHOP_SIM_URL, '_blank', 'noopener,noreferrer')
  }
  if (isFilled) {
    return <ButtonFill onClick={handleClick}>{t('purchaseSimCard')}</ButtonFill>
  }

  return (
    <ButtonGhost onClick={handleClick}>
      {isMobile ? t('mobileLabel') : t('desktopLabel')}
    </ButtonGhost>
  )
}
