import { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectSelectedItem } from '@npco/zeller-design-system'
import {
  INVOICE_SCHEDULE_DUE_DATE_FIELD,
  INVOICE_SCHEDULE_PAYMENT_TERMS_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import {
  InvoiceFormFields,
  PaymentTerms,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'

import { InvoicePaymentTermsSelectedItem } from '../../../InvoiceFormAccordions.types'
import {
  getDueDateByInvoiceDateAndPaymentTerms,
  isPastDate,
  isPastInvoiceDateWithPaymentTerm,
} from '../InvoiceScheduleAccordion.utils'

export const useInvoicePaymentTerms = (
  paymentTermsItems: InvoicePaymentTermsSelectedItem[]
) => {
  const {
    setFieldValue,
    values: {
      schedule: { invoiceDate },
    },
  } = useFormikContext<InvoiceFormFields>()

  const [field, , { setValue }] = useField<PaymentTerms>(
    INVOICE_SCHEDULE_PAYMENT_TERMS_FIELD
  )
  const [selectedItem, setSelectedItem] =
    useState<InvoicePaymentTermsSelectedItem | null>(null)

  useEffect(() => {
    if (!field.value) {
      setSelectedItem(null)
      return
    }
    setSelectedItem(
      paymentTermsItems.find((item) => item.value === field.value) ?? null
    )
  }, [field.value, paymentTermsItems])

  const handleChange = useCallback(
    (item: SelectSelectedItem<InvoicePaymentTermsSelectedItem>) => {
      if (!item) {
        return
      }

      setValue(item.value)

      if (item.value === PaymentTerms.Custom) {
        return
      }

      setFieldValue(
        INVOICE_SCHEDULE_DUE_DATE_FIELD,
        getDueDateByInvoiceDateAndPaymentTerms(invoiceDate, item.value)
      )
    },
    [invoiceDate, setFieldValue, setValue]
  )

  const updatedPaymentTermItems = useMemo(() => {
    const isPastInvoiceDate = isPastDate(invoiceDate)

    if (!isPastInvoiceDate) {
      return paymentTermsItems
    }

    return paymentTermsItems.map((item) => ({
      ...item,
      isDisabled:
        item.value !== PaymentTerms.Custom &&
        isPastInvoiceDateWithPaymentTerm(invoiceDate, item.value),
    }))
  }, [invoiceDate, paymentTermsItems])

  return {
    value: field.value,
    selectedItem,
    handleChange,
    updatedPaymentTermItems,
  }
}
