import { Dispatch, SetStateAction } from 'react'
import { Flex, slideSideAnimationVariants } from '@npco/zeller-design-system'

import { TransferStateCondition as Condition } from 'pages/Transfer/states/TransferState/TransferStateCondition'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'
import { useTransferActionEvents } from 'pages/Transfer/TransferFields/TransferActionButtons/TransferActionButtons.hooks'
import { TransferSubmitButton } from 'pages/Transfer/TransferFields/TransferSubmitButton/TransferSubmitButton'
import { ButtonBack } from 'components/Buttons/ButtonBack'

interface TransferActionButtonsProps {
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
}

export const TransferActionButtons = ({
  setContactValidationError,
}: TransferActionButtonsProps) => {
  const { sendBackEvent } = useTransferActionEvents()

  return (
    <Condition
      animationVariants={slideSideAnimationVariants}
      allowedStates={[
        TransferGeneralState.BpayTransfer,
        TransferGeneralState.ContactTransfer,
        TransferGeneralState.ZellerTransfer,
      ]}
    >
      <Flex flexDirection={{ _: 'column', XS: 'row' }} gap="16px">
        <TransferSubmitButton
          setContactValidationError={setContactValidationError}
        />
        <ButtonBack onClick={sendBackEvent} />
      </Flex>
    </Condition>
  )
}
