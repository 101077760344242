import { useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useMutation } from '@apollo/client'
import { SiteType } from '@npco/mp-gql-types'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import {
  Breadcrumb,
  Flex,
  PageTemplate,
  showApiErrorToast,
} from '@npco/zeller-design-system'
import { CreateSite as CreateSiteMutation } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/sites'
import { rvSelectedSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { Formik } from 'formik'

import { ROOT } from 'const/routes'
import { SITE_TYPE } from 'const/settings'
import { storageManageNewSite } from 'utils/newSitesHelper'
import { translate } from 'utils/translations'
import { CreateSiteVariables } from 'types/gql-types/CreateSite'
import { Site, SiteCache } from 'types/site'
import { baseSiteCache } from 'mockData/mockSiteCache'
import { SettingsFormLayout } from 'layouts'
import { Site as SiteForm } from 'forms/formViews/Site'
import { page } from 'translations'

const initialValues: Site = {
  siteType: '',
  name: '',
  pin: undefined,
  street: '',
  suburb: '',
  state: '',
  postcode: '',
  businessName: '',
}

interface CreateSiteResponse {
  createSite: {
    id: string
    __typename: string
  }
}

export const CreateSite = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const [newSiteData, setNewSiteData] = useState<SiteCache>({
    name: '',
    devices: [],
    id: '',
    pin: undefined,
    type: SiteType.FIXED,
    address: {
      street: '',
      postcode: '',
      suburb: '',
      state: '',
    },
    receipt: null,
    customers: null,
    features: {
      restrictReportAccessEnabled: true,
      splitPaymentEnabled: false,
    },
    screensaver: null,
  })
  const entityUuid = useSelectedEntityUuid()

  const [createSite, { loading }] = useMutation<
    CreateSiteResponse,
    CreateSiteVariables
  >(CreateSiteMutation, {
    onCompleted(completeData: CreateSiteResponse) {
      const { name, pin, address, type, receipt } = newSiteData
      const tempSiteData = {
        ...baseSiteCache,
        id: completeData.createSite.id,
        name,
        pin,
        address,
        type,
        devices: [],
        receipt,
        customers: null,
      }

      storageManageNewSite(tempSiteData)
      rvSelectedSite(tempSiteData.id)
      navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.SITE.GENERAL.path)
    },
    onError: (error) => {
      showApiErrorToast(error)
    },
  })

  const onSubmit = (values: Site) => {
    const { name, street, suburb, state, pin, siteType, businessName } = values

    const address =
      siteType === SITE_TYPE.MOBILE
        ? null
        : {
            street,
            suburb,
            state,
            postcode: values.postcode as string,
          }

    const type: SiteType =
      siteType === SITE_TYPE.MOBILE ? SiteType.MOBILE : SiteType.FIXED

    const formattedBusinessName = businessName?.trim()

    const tempSiteData: SiteCache = {
      ...baseSiteCache,
      name,
      devices: [],
      id: '',
      pin,
      address,
      type,
      receipt: { name: formattedBusinessName },
      customers: null,
    }

    setNewSiteData(tempSiteData)

    createSite({
      variables: {
        entityUuid,
        input: {
          name,
          address,
          pin: pin as string,
          type,
          businessName: formattedBusinessName,
        },
      },
    })
  }

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex height="48px">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Breadcrumb.Link
                  to={ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.path}
                >
                  {page.title.sites}
                </Breadcrumb.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Breadcrumb.Text>{page.title.createSite}</Breadcrumb.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
    >
      <PageTemplate.Section>
        <Formik
          validateOnChange
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, submitForm }) => (
            <SettingsFormLayout
              isSubmitButtonDisabled={isSubmitting}
              submitButtonLabel={translate('shared.create')}
              onBackButtonClick={() =>
                navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SITES.path)
              }
              onSubmitButtonClick={submitForm}
              isLoading={loading}
              customHeight="100%"
            >
              <SiteForm />
            </SettingsFormLayout>
          )}
        </Formik>
      </PageTemplate.Section>
    </PageTemplate>
  )
}
