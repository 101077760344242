import { useMemo, useState } from 'react'
import { Box } from '@npco/zeller-design-system'
import { MultiSelectTriggerRenderProps } from 'design-system/Components/MultiSelect'
import {
  SelectSearchInput,
  SelectSize,
  SelectStandard,
} from 'design-system/Components/Select'

import { translate } from 'utils/translations'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { CategoryWithSubcategories } from 'layouts/AccountLayout/hooks/useGetCategoriesWithSubcategories/useGetCategoriesWithSubcategories'
import { getCategoryDisplay } from 'components/EditZellerCategories/Fields/utils/categories.utils'
import { page } from 'translations'

import { FilterSelectTrigger } from '../FilterSelectTrigger/FilterSelectTrigger'
import { NotFoundPlaceholder } from '../NotFoundPlaceholder/NotFoundPlaceholder'
import {
  getCategoriesItems,
  handleCategoryChange,
} from './AccountFilterCategory.utils'

interface Props {
  categories: CategoryWithSubcategories[]
}

export const AccountFilterCategory = ({ categories }: Props) => {
  const { category, setCategory, resetCategory } = useAccountFilters()
  const [searchInput, setSearchInput] = useState('')
  const categoryItems = useMemo(
    () => getCategoriesItems(categories),
    [categories]
  )
  const items = categoryItems.filter(({ label }) =>
    label.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
  )

  const selectedItem = categoryItems.find(
    ({ category: categoryValue, subcategory }) =>
      categoryValue === category?.category &&
      subcategory === category?.subcategory
  )

  const onClose = () => {
    setSearchInput('')
  }

  const renderTrigger = useMemo(() => {
    const icon = selectedItem?.category
      ? getCategoryDisplay(selectedItem?.category).icon
      : null

    return ({ ref, ...renderTriggerProps }: MultiSelectTriggerRenderProps) => (
      <FilterSelectTrigger
        selectedItems={selectedItem ? [selectedItem] : []}
        values={selectedItem ? [selectedItem.value] : []}
        onReset={resetCategory}
        filterName={page.accounts.transactionsList.filters.category}
        icon={icon}
        innerRef={ref}
        {...renderTriggerProps}
      />
    )
  }, [resetCategory, selectedItem])

  return (
    <SelectStandard
      shouldAutoFocusControl
      items={items}
      selectedItem={selectedItem}
      onChange={(selectedCategory) =>
        handleCategoryChange({
          selectedCategory,
          setCategory,
        })
      }
      maxHeight="19rem"
      popperWidth="26rem"
      onClose={onClose}
      isResettable
      size={SelectSize.Small}
      hasSelectedIndicator
      renderAdditionalControls={() => (
        <Box p="0.5rem">
          <SelectSearchInput
            value={searchInput}
            setValue={setSearchInput}
            aria-label={translate('shared.typeToSearch')}
            placeholder={translate('shared.typeToSearch')}
          />
        </Box>
      )}
      renderNoItemsPlaceholder={() => (
        <NotFoundPlaceholder searchInput={searchInput} />
      )}
      renderTrigger={renderTrigger}
    />
  )
}
