import type { Context, ReactElement, ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type InferLocale<LOCALES extends ReadonlyArray<string>> =
  LOCALES extends ReadonlyArray<infer R> ? R : never

export type LocaleContextValue<LOCALE extends string = string> = {
  locale: LOCALE | undefined
  setLocale: (locale: LOCALE) => void
  clearLocale: () => void
}

const setLocaleNoop: LocaleContextValue['setLocale'] = () => {
  throw new Error('"setLocale" must be called within a "LocaleContext".')
}
const clearLocaleNoop: LocaleContextValue['clearLocale'] = () => {
  throw new TypeError('"clearLocale" must be called within a "LocaleContext".')
}

export type LocaleEnvironment<LOCALE extends string = string> = {
  locales: ReadonlyArray<LOCALE>
  LocaleContext: Context<LocaleContextValue<LOCALE>>
  LocaleContextProvider: (props: {
    children?: ReactNode
    initialLocale?: LOCALE
  }) => ReactElement
  useLocaleContext: () => LocaleContextValue<LOCALE>
}

export type CreateLocaleEnvironmentOptions<LOCALE extends string = string> = {
  locales: ReadonlyArray<LOCALE>
}

export const createLocaleEnvironment = <LOCALE extends string>({
  locales,
}: CreateLocaleEnvironmentOptions<LOCALE>): LocaleEnvironment<LOCALE> => {
  type CONTEXT = LocaleContextValue<LOCALE>
  type ENVIRONMENT = LocaleEnvironment<LOCALE>

  const LocaleContext: ENVIRONMENT['LocaleContext'] = createContext<CONTEXT>({
    locale: undefined,
    setLocale: setLocaleNoop,
    clearLocale: clearLocaleNoop,
  })

  const LocaleContextProvider: ENVIRONMENT['LocaleContextProvider'] = ({
    children,
    initialLocale,
  }) => {
    const [localeState, setLocaleState] =
      useState<CONTEXT['locale']>(initialLocale)

    const setLocale = useCallback<CONTEXT['setLocale']>(
      (locale) => setLocaleState(locale),
      [setLocaleState]
    )
    const clearLocale = useCallback<CONTEXT['clearLocale']>(
      () => setLocaleState(undefined),
      [setLocaleState]
    )

    const value = useMemo<CONTEXT>(
      () => ({ locale: localeState, setLocale, clearLocale }),
      [clearLocale, localeState, setLocale]
    )

    return (
      <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
    )
  }

  const useLocaleContext: ENVIRONMENT['useLocaleContext'] = () => {
    return useContext(LocaleContext)
  }

  return {
    locales,
    LocaleContext,
    LocaleContextProvider,
    useLocaleContext,
  }
}
