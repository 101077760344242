import { useTranslations } from '@npco/utils-translations'
import { Box, InfoBox } from '@npco/zeller-design-system'

import { searchStringLengthRequirementInfoTranslations } from './SearchStringLengthRequirementInfo.i18n'

export const SearchStringLengthRequirementInfo = () => {
  const t = useTranslations(searchStringLengthRequirementInfoTranslations)
  return (
    <Box margin="12px 18px 12px 16px">
      <InfoBox>
        <InfoBox.Message>{t('info')}</InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
