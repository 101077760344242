import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetOraclePosConnectionStateQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetOraclePosConnectionStateQueryResponse = {
  __typename?: 'Query'
  getConnectionState: {
    __typename: 'Connection'
    id: string | null
    status: Types.ConnectionStatus | null
    errorType: Types.ConnectionErrorType | null
  }
}

export const GetOraclePosConnectionState = gql`
  query GetOraclePosConnectionState($entityUuid: ID!) {
    getConnectionState(entityUuid: $entityUuid, connectionType: ORACLE_POS) {
      id
      status
      errorType
      __typename
    }
  }
` as unknown as TypedDocumentNode<
  GetOraclePosConnectionStateQueryResponse,
  GetOraclePosConnectionStateQueryVariables
>

/**
 * __useGetOraclePosConnectionStateQuery__
 *
 * To run a query within a React component, call `useGetOraclePosConnectionStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOraclePosConnectionStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOraclePosConnectionStateQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetOraclePosConnectionStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOraclePosConnectionStateQueryResponse,
    GetOraclePosConnectionStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOraclePosConnectionStateQueryResponse,
    GetOraclePosConnectionStateQueryVariables
  >(GetOraclePosConnectionState, options)
}
export function useGetOraclePosConnectionStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOraclePosConnectionStateQueryResponse,
    GetOraclePosConnectionStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOraclePosConnectionStateQueryResponse,
    GetOraclePosConnectionStateQueryVariables
  >(GetOraclePosConnectionState, options)
}
export type GetOraclePosConnectionStateQueryHookResult = ReturnType<
  typeof useGetOraclePosConnectionStateQuery
>
export type GetOraclePosConnectionStateLazyQueryHookResult = ReturnType<
  typeof useGetOraclePosConnectionStateLazyQuery
>
export type GetOraclePosConnectionStateQueryResult = Apollo.QueryResult<
  GetOraclePosConnectionStateQueryResponse,
  GetOraclePosConnectionStateQueryVariables
>
