import { gql } from '@apollo/client'
import { currencyFormatter } from '@npco/component-mp-common'

import { AccountStatus, shouldDisplayStatus } from 'components/AccountStatus'

import { AccountBalanceDebitCardAccountV2Fragment } from './AccountBalance.generated'

interface AccountBalanceProps {
  account: AccountBalanceDebitCardAccountV2Fragment
}

export const AccountBalance = ({ account }: AccountBalanceProps) => {
  if (shouldDisplayStatus(account)) {
    return <AccountStatus account={account} />
  }

  return <>{currencyFormatter(parseInt(account.balance?.value || '0', 10))}</>
}

AccountBalance.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountBalanceDebitCardAccountV2Fragment on DebitCardAccountV2 {
      balance {
        value
      }
      ...ShouldDisplayStatusDebitCardAccountV2Fragment
      ...AccountStatusDebitCardAccountV2Fragment
    }

    ${shouldDisplayStatus.fragments.DebitCardAccountV2}
    ${AccountStatus.fragments.DebitCardAccountV2}
  `,
}
