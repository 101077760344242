import { AccordionAnimation } from 'components/AccordionAnimation/AccordionAnimation'
import { useAccountFiltersList } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList'
import { FiltersList } from 'components/FiltersList/FiltersList'

import * as styled from './AccountsTransactionsFilters.styled'

const defaultFilters = { selectedAccountID: undefined }

export const AccountsTransactionsFiltersDeps = {
  useAccountFiltersList,
}

export type AccountsTransactionsFiltersProps = {
  isFiltersVisible: boolean
}

export const AccountsTransactionsFilters = ({
  isFiltersVisible,
}: AccountsTransactionsFiltersProps) => {
  const { useAccountFiltersList } = AccountsTransactionsFiltersDeps

  const { selectItems, visibleFilters, onSelect } = useAccountFiltersList({
    defaultFilters,
    skip: !isFiltersVisible,
  })

  return (
    <styled.Container $expanded={isFiltersVisible}>
      <AccordionAnimation isVisible={isFiltersVisible}>
        <FiltersList
          defaultFilters={defaultFilters}
          moreSelectItems={selectItems}
          onSelect={onSelect}
          visibleFilters={visibleFilters}
        />
      </AccordionAnimation>
    </styled.Container>
  )
}
