import { createTranslations } from '@npco/utils-translations'

export const entitiesMenuTranslations = createTranslations({
  mobileMenuTitle: 'Businesses',
  closeTabsList: 'Close entities list',
  moreActions: 'More actions',
  manageBusinesses: 'Manage businesses',
  removeBusiness: 'Remove business',
  selectBusiness: 'Select business',
})
