import { translate } from 'utils/translations'
import { StyledCopy } from 'components/PickerElements/Elements.styled'
import { ResendCodeModalWrapper } from 'components/ValidateCode/ResendCodeModalWrapper'

import { useOnboardingValidateCode } from '../hooks/useOnboardingValidateCode'

export const Description = () => {
  const { phone } = useOnboardingValidateCode()
  return (
    <StyledCopy>
      {`${translate('page.validateCode.copyFirstLine')} ${translate(
        'page.validateCode.copySecondLine'
      )}`}
      {phone && <ResendCodeModalWrapper />}
    </StyledCopy>
  )
}
