import { INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'

import { translate } from 'utils/translations'

import { InvoicePercentageInputField } from '../../../../InvoicePercentageInputField/InvoicePercentageInputField'

export const translations = {
  ariaLabel: translate(
    'page.invoice.formSections.items.itemsDiscountPercentageAriaLabel'
  ),
}

export const InvoiceItemDiscountPercentage = () => {
  return (
    <InvoicePercentageInputField
      ariaLabelId={translations.ariaLabel}
      dataTestId="invoicing-invoice-item-discount-percentage"
      name={INVOICE_ITEMS_DISCOUNT_PERCENTAGE_FIELD}
    />
  )
}
