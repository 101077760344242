import { useCallback } from 'react'
import {
  INPUT_SIZE,
  InputAdaptiveField,
  InputAdaptiveFieldProps,
} from '@npco/zeller-design-system'
import { INVOICE_DEFAULT_PERCENTAGE } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { useField } from 'formik'

import { useInputHandlers } from 'hooks/useInputHandlers/useInputHandlers'
import { convertNumberToLocaleString } from 'utils/localeString'

import { PercentageSymbolWrapper } from './InvoicePercentageInputField.styled'

const renderRightControls = () => (
  <PercentageSymbolWrapper>%</PercentageSymbolWrapper>
)

export interface InvoicePercentageInputFieldProps
  extends InputAdaptiveFieldProps {
  ariaLabelId: string
  dataTestId: string
  name: string
}

export const InvoicePercentageInputField = ({
  ariaLabelId,
  dataTestId,
  name,
  ...props
}: InvoicePercentageInputFieldProps) => {
  const [field, , helpers] = useField(name)

  const { handlePercentageInputKeydown } = useInputHandlers()

  const handleOnBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // NOTE: falsey values will become 0 when passed to number so become finite
      const number = Number(event.target.value)
      const isValidNumber = Number.isFinite(number)

      if (!isValidNumber) {
        return
      }

      if (number === 0) {
        // NOTE: must prevent default otherwise incorrect event.target.value
        // sent to formik on blur
        event.preventDefault()
        helpers.setValue(INVOICE_DEFAULT_PERCENTAGE)
        return
      }

      const numberString = convertNumberToLocaleString(number, {
        useGrouping: false,
      })

      helpers.setValue(numberString)
    },
    [helpers]
  )

  const handleOnFocus = useCallback(() => {
    const isZeroPrice = field.value === INVOICE_DEFAULT_PERCENTAGE

    // NOTE: if zero percentage we clear the value so the user can more easily type
    // in their next value
    if (isZeroPrice) {
      helpers.setValue('')
    }
  }, [field, helpers])

  return (
    <InputAdaptiveField
      aria-label={ariaLabelId}
      data-testid={dataTestId}
      name={name}
      onBlur={handleOnBlur}
      onKeyDown={handlePercentageInputKeydown}
      onFocus={handleOnFocus}
      renderRightControls={renderRightControls}
      size={INPUT_SIZE.SMALL}
      {...props}
    />
  )
}
