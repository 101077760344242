import { useCallback } from 'react'
import { ModalBasic } from '@npco/zeller-design-system'

import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import { translate } from 'utils/translations'

import { StyledDescription } from '../../../GlobalModals.styled'
import { useSubmitReportLostAndReplaceCardForm } from '../hooks/useSubmitReportLostAndReplaceCardForm'

export const ReportLostAndReplaceCardForm = () => {
  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()

  const closeReportLostAndReplaceCardModal = useCallback(
    () => goToReportLostAndReplaceCardStage(undefined),
    [goToReportLostAndReplaceCardStage]
  )

  const { isSubmitting, handleSubmit } = useSubmitReportLostAndReplaceCardForm()

  return (
    <>
      <ModalBasic.Body data-testid="report-lost-card-form" mb="12px">
        <StyledDescription mb={32}>
          {translate(
            'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardPrompt.description'
          )}
        </StyledDescription>
        <StyledDescription>
          {translate(
            'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardPrompt.subDescription'
          )}
        </StyledDescription>
      </ModalBasic.Body>
      <ModalBasic.Footer>
        <ModalBasic.SecondaryButton
          onClick={closeReportLostAndReplaceCardModal}
        >
          {translate('shared.cancel')}
        </ModalBasic.SecondaryButton>
        <ModalBasic.PrimaryButton
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          {translate(
            'page.reportLostAndReplaceCardModal.reportLostAndReplaceCardPrompt.buttonLabel'
          )}
        </ModalBasic.PrimaryButton>
      </ModalBasic.Footer>
    </>
  )
}
