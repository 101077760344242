import { createLazyLoadableTestingLink } from '@npco/utils-apollo-testing-link'

export const getLazyLoadableTestingLink = (
  isCypress: boolean,
  shouldUseApolloTestingLink: boolean | undefined,
  testingUtilPropertyName?: string
) =>
  isCypress && shouldUseApolloTestingLink
    ? [createLazyLoadableTestingLink(testingUtilPropertyName)]
    : []
