import { useMemo } from 'react'

import { getLastThreeMonthsDateRange } from './AccountBalanceChart.utils'
import { AccountBalanceChartView } from './AccountBalanceChartView'
import { useAccountBalanceChartQuery } from './hooks/useAccountBalanceChartQuery'

export const AccountBalanceChart = () => {
  const dateRange = useMemo(() => getLastThreeMonthsDateRange(), [])
  const {
    accountBalances,
    error,
    refetch,
    isLoadingWithoutCache,
    isTimeZoneUndefined,
  } = useAccountBalanceChartQuery(dateRange)

  return (
    <AccountBalanceChartView
      accountBalances={accountBalances}
      error={error}
      refetch={refetch}
      isLoadingWithoutCache={isLoadingWithoutCache}
      isTimeZoneUndefined={isTimeZoneUndefined}
      dateRange={dateRange}
    />
  )
}
