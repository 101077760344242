import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  attributeSets: 'Attribute Sets',
  allItems: 'All Items',
  catalogue: 'Catalogue',
  categories: 'Categories',
  discounts: 'Discounts',
  items: 'Items',
  modifierSets: 'Modifier Sets',
  reports: 'Reports',
  settings: 'Settings',
})
