import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetZellerInvoiceSettingsQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetZellerInvoiceSettingsQueryResponse = {
  __typename?: 'Query'
  getZellerInvoiceSettings: {
    __typename: 'ZellerInvoiceSite'
    id: string
    name: string
    type: Types.SiteType
    address: {
      __typename?: 'SiteAddress'
      suburb: string | null
      street: string | null
      state: string | null
      postcode: string | null
    } | null
    customers: Array<{
      __typename: 'Customer'
      id: string
      entityUuid: string | null
      role: Types.CustomerRole | null
      firstname: string | null
      lastname: string | null
    }> | null
    invoice: {
      __typename?: 'ZellerInvoiceSettings'
      discountsEnabled: boolean | null
      sendBccCopy: boolean
      remindersDaysBeforeDue: Array<number>
      remindersDaysAfterDue: Array<number>
      reminderOnDue: boolean
      pdfIncludesAddress: boolean
      bccEmail: string | null
      sendEmailByDefault: boolean | null
      sendSmsByDefault: boolean | null
      customisation: {
        __typename?: 'InvoicePdfCustomisation'
        accentColours: Array<string | null> | null
        defaultMessage: string | null
        logos: Array<string | null> | null
        selectedColor: string | null
        selectedLogo: string | null
        selectedTemplateType: Types.PdfTemplateType | null
        termsAndConditions: string | null
      } | null
    }
    schemesCnp: Array<{
      __typename?: 'SchemeSettings'
      name: Types.CardScheme | null
    }> | null
    receipt: {
      __typename: 'ReceiptSettings'
      name: string | null
      phone: string | null
      twitter: string | null
      facebook: string | null
      instagram: string | null
      website: string | null
      email: string | null
      message: string | null
      returnsMessage: string | null
      logo: string | null
    } | null
    surchargesTaxes: {
      __typename?: 'SurchargesTaxesSettings'
      feeFixedCnp: number | null
      feePercentCnp: number | null
      surchargeEnabledCnp: boolean | null
      surchargePercentCnp: number | null
      surchargeFullFeesCnp: boolean | null
    } | null
    paymentLimits: {
      __typename?: 'PaymentSettingsLimits'
      maximum: number | null
      minimum: number | null
    } | null
  }
}

export const GetZellerInvoiceSettings = gql`
  query GetZellerInvoiceSettings($entityUuid: ID!) {
    getZellerInvoiceSettings(entityUuid: $entityUuid) {
      __typename
      address {
        suburb
        street
        state
        postcode
      }
      customers {
        id
        entityUuid
        role
        firstname
        lastname
        __typename
      }
      id
      invoice {
        discountsEnabled
        sendBccCopy
        remindersDaysBeforeDue
        remindersDaysAfterDue
        reminderOnDue
        pdfIncludesAddress
        bccEmail
        customisation {
          accentColours
          defaultMessage
          logos
          selectedColor
          selectedLogo
          selectedTemplateType
          termsAndConditions
        }
        sendEmailByDefault
        sendSmsByDefault
      }
      name
      schemesCnp {
        name
      }
      receipt {
        __typename
        name
        phone
        twitter
        facebook
        instagram
        website
        email
        message
        returnsMessage
        logo
      }
      surchargesTaxes {
        feeFixedCnp
        feePercentCnp
        surchargeEnabledCnp
        surchargePercentCnp
        surchargeFullFeesCnp
      }
      paymentLimits {
        maximum
        minimum
      }
      type
    }
  }
` as unknown as TypedDocumentNode<
  GetZellerInvoiceSettingsQueryResponse,
  GetZellerInvoiceSettingsQueryVariables
>

/**
 * __useGetZellerInvoiceSettingsQuery__
 *
 * To run a query within a React component, call `useGetZellerInvoiceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZellerInvoiceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZellerInvoiceSettingsQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetZellerInvoiceSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetZellerInvoiceSettingsQueryResponse,
    GetZellerInvoiceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetZellerInvoiceSettingsQueryResponse,
    GetZellerInvoiceSettingsQueryVariables
  >(GetZellerInvoiceSettings, options)
}
export function useGetZellerInvoiceSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetZellerInvoiceSettingsQueryResponse,
    GetZellerInvoiceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetZellerInvoiceSettingsQueryResponse,
    GetZellerInvoiceSettingsQueryVariables
  >(GetZellerInvoiceSettings, options)
}
export type GetZellerInvoiceSettingsQueryHookResult = ReturnType<
  typeof useGetZellerInvoiceSettingsQuery
>
export type GetZellerInvoiceSettingsLazyQueryHookResult = ReturnType<
  typeof useGetZellerInvoiceSettingsLazyQuery
>
export type GetZellerInvoiceSettingsQueryResult = Apollo.QueryResult<
  GetZellerInvoiceSettingsQueryResponse,
  GetZellerInvoiceSettingsQueryVariables
>
