import { useQuery } from '@apollo/client'
import { ConnectionType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetXeroPaymentServicesOrganisation as GetXeroPaymentServicesOrganisationResponse } from 'types/gql-types/GetXeroPaymentServicesOrganisation'

import { useXeroOrganisations } from '../../hooks/useXeroOrganisations'
import { GetXeroPaymentServicesOrganisation } from '../graphql/GetXeroPaymentServicesOrganisation'

export const useGetXeroOrganisationName = () => {
  const entityUuid = useSelectedEntityUuid()
  const { isLoading: isLoadingXeroOrganisations, organisations } =
    useXeroOrganisations(ConnectionType.XERO_PAYMENT_SERVICES)

  const { loading: isLoadingXeroPaymentServicesOrganisation, data } =
    useQuery<GetXeroPaymentServicesOrganisationResponse>(
      GetXeroPaymentServicesOrganisation,
      {
        variables: {
          entityUuid,
        },
      }
    )

  const xeroOrganisation = organisations.find(
    (organisation) =>
      organisation.value ===
      data?.getXeroPaymentServicesConfiguration?.xeroOrganisationUuid
  )

  return {
    isLoadingXeroOrganisationName:
      isLoadingXeroOrganisations || isLoadingXeroPaymentServicesOrganisation,
    xeroOrganisationName: xeroOrganisation?.label,
  }
}
