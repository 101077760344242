import { BodySmallTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledFailedFilesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  ${BodySmallTypography};
  background-color: ${({ theme }) => theme.colors.RED_80};
  padding: 1.125rem 1rem;
  margin-bottom: 3.25rem;
  border-radius: 4px;
`
