import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'

import { setupFlowDebitCardDetailsTranslations } from '../DebitCardDetails.i18n'
import { ToggleLinkButton } from '../DebitCardDetails.styled'

interface DebitCardDetailsCopyInfoMobileProps {
  isDetailsVisible: boolean
  onClick: () => void
}
export const DebitCardDetailsCopyInfoMobile = ({
  isDetailsVisible,
  onClick,
}: DebitCardDetailsCopyInfoMobileProps) => {
  const t = useTranslations(setupFlowDebitCardDetailsTranslations)

  return (
    <Box position="absolute" left="54px" top="-36px">
      <ToggleLinkButton onClick={onClick}>
        {t(isDetailsVisible ? 'hideMessageMobile' : 'showMessageMobile')}
      </ToggleLinkButton>
    </Box>
  )
}
