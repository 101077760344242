import { GetDebitCardAccountsForCardCreationQueryResponse } from 'pages/GlobalModals/AddCardModal/graphql/accounts.generated'

type Accounts = NonNullable<
  GetDebitCardAccountsForCardCreationQueryResponse['getDebitCardAccountsV2']['accounts'][0]
>

export const getAccountNameByAccountId = (
  accounts: Accounts[],
  accountId: string
) => accounts.find((account) => account.id === accountId)?.name || ''
