import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type CreateSavingsAccountViewQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type CreateSavingsAccountViewQueryResponse = {
  __typename?: 'Query'
  getSavingsAccounts: Array<{
    __typename?: 'DebitCardAccountV2'
    id: string
    status: Types.DebitCardAccountStatus
  } | null>
}

export const CreateSavingsAccountViewQuery = gql`
  query CreateSavingsAccountViewQuery($entityUuid: ID!) {
    getSavingsAccounts(entityUuid: $entityUuid) {
      id
      status
    }
  }
` as unknown as TypedDocumentNode<
  CreateSavingsAccountViewQueryResponse,
  CreateSavingsAccountViewQueryVariables
>

/**
 * __useCreateSavingsAccountViewQuery__
 *
 * To run a query within a React component, call `useCreateSavingsAccountViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateSavingsAccountViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateSavingsAccountViewQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useCreateSavingsAccountViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    CreateSavingsAccountViewQueryResponse,
    CreateSavingsAccountViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CreateSavingsAccountViewQueryResponse,
    CreateSavingsAccountViewQueryVariables
  >(CreateSavingsAccountViewQuery, options)
}
export function useCreateSavingsAccountViewQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreateSavingsAccountViewQueryResponse,
    CreateSavingsAccountViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CreateSavingsAccountViewQueryResponse,
    CreateSavingsAccountViewQueryVariables
  >(CreateSavingsAccountViewQuery, options)
}
export type CreateSavingsAccountViewQueryHookResult = ReturnType<
  typeof useCreateSavingsAccountViewQuery
>
export type CreateSavingsAccountViewQueryLazyQueryHookResult = ReturnType<
  typeof useCreateSavingsAccountViewQueryLazyQuery
>
export type CreateSavingsAccountViewQueryQueryResult = Apollo.QueryResult<
  CreateSavingsAccountViewQueryResponse,
  CreateSavingsAccountViewQueryVariables
>
