import { useTranslations } from '@npco/utils-translations'
import { Tutorial } from '@npco/zeller-design-system'

import SetupFlowItemsLarge from 'assets/images/setup-flow/setup-flow-items-large.png'
import SetupFlowItemsMedium from 'assets/images/setup-flow/setup-flow-items-medium.png'
import SetupFlowItemsSmall from 'assets/images/setup-flow/setup-flow-items-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { MainContent } from './MainContent/MainContent'

export const FeatureItemsStageContent = () => {
  const tShared = useTranslations(translationsShared)
  const { goToStage } = useSetupFlowModal()

  return (
    <Tutorial.TutorialLayout
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={() => goToStage('FeatureContactsStage')}
            onSecondaryButtonClick={() => goToStage('FeaturePosLiteStage')}
            primaryButtonLabel={tShared('next')}
          />
        </MotionFooter>
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowItemsSmall}
          imageMedium={SetupFlowItemsMedium}
          imageLarge={SetupFlowItemsLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          <MainContent />
        </MotionMainContent>
      }
    />
  )
}
