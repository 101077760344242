import { useCallback } from 'react'

import { useAnalyticsContext } from './useAnalyticsContext'
import { useAnalyticsLogger } from './useAnalyticsLogger'

interface UseModalEventsProps {
  modalName: string
}

export const useModalEvents = ({ modalName }: UseModalEventsProps) => {
  const { locationName } = useAnalyticsContext()
  const { logAnalyticsPage } = useAnalyticsLogger()

  const onModalOpen = useCallback(() => {
    if (!modalName) {
      return
    }
    locationName.current = [locationName.current, modalName].join('_')
    logAnalyticsPage({ name: locationName.current })
  }, [logAnalyticsPage, locationName, modalName])

  const onModalClose = useCallback(() => {
    if (!modalName) {
      return
    }

    locationName.current = locationName.current
      .split('_')
      .slice(0, -1)
      .join('_')

    logAnalyticsPage({ name: locationName.current })
  }, [logAnalyticsPage, locationName, modalName])

  return { onModalOpen, onModalClose }
}
