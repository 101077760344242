import { H3, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledAccountsTitle = styled(H5)`
  color: ${({ theme }) => theme.colors.BLACK};
  margin: 0;
`

export const StyledAccountSettingWrapper = styled.div`
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const StyledAccountSettingPageTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.BLACK};
  margin: 0 0 1rem;
`

export const StyledAccountSettingDescription = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.GREY_550};

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    width: 31.5rem;
  }
`
