import { boolean, number, object, string } from 'yup'

import { VALID_EMAIL_REGEX } from 'utils/formValidation'
import { translate } from 'utils/translations'
import { CardSurcharge } from 'types/settings'

import {
  PDF_INCLUDE_ADDRESS_FIELD,
  SURCHARGE_ENABLED_FIELD,
  SURCHARGE_TYPE_FIELD,
} from '../SettingsGeneral.constants'

const generalSettingsSchemaErrors = {
  bccEmailRequired: translate(
    'page.invoicesSettings.general.settings.formErrors.bccEmailRequired'
  ),
  bccEmailValid: translate(
    'page.invoicesSettings.general.settings.formErrors.bccEmailValid'
  ),
  street: translate('page.invoicesSettings.general.settings.formErrors.street'),
  cardSurcharge: translate(
    'page.invoicesSettings.general.settings.formErrors.cardSurcharge'
  ),
  suburb: translate('page.invoicesSettings.general.settings.formErrors.suburb'),
  state: translate('page.invoicesSettings.general.settings.formErrors.state'),
  postcode: translate(
    'page.invoicesSettings.general.settings.formErrors.postcode'
  ),
}

export const generalSettingsSchema = object().shape({
  pdfIncludesAddress: boolean(),
  sendBccCopy: boolean(),
  bccEmail: string()
    .email(generalSettingsSchemaErrors.bccEmailValid)
    .matches(VALID_EMAIL_REGEX, {
      excludeEmptyString: true,
      message: generalSettingsSchemaErrors.bccEmailValid,
    })
    .when('sendBccCopy', {
      is: (sendBccCopy: boolean) => !!sendBccCopy,
      then: string().required(generalSettingsSchemaErrors.bccEmailRequired),
    }),

  surchargeEnabledCnp: boolean(),
  surchargeType: string(),
  surchargePercentCnp: number().when(
    [SURCHARGE_TYPE_FIELD, SURCHARGE_ENABLED_FIELD],
    {
      is: (surchargeType: CardSurcharge, surchargeEnabledCnp: boolean) =>
        surchargeEnabledCnp && surchargeType === CardSurcharge.Percentage,
      then: number().required(generalSettingsSchemaErrors.cardSurcharge),
    }
  ),

  itemsApplyGst: boolean(),
  itemsGstPriceInclusive: boolean(),
  gstDefault: string(),

  street: string().when(PDF_INCLUDE_ADDRESS_FIELD, {
    is: (pdfIncludesAddress: boolean) => !!pdfIncludesAddress,
    then: string().required(generalSettingsSchemaErrors.street),
  }),
  suburb: string()
    .nullable()
    .when(PDF_INCLUDE_ADDRESS_FIELD, {
      is: (pdfIncludesAddress: boolean) => !!pdfIncludesAddress,
      then: string().required(generalSettingsSchemaErrors.suburb),
    }),
  state: string().when(PDF_INCLUDE_ADDRESS_FIELD, {
    is: (pdfIncludesAddress: boolean) => !!pdfIncludesAddress,
    then: string().required(generalSettingsSchemaErrors.state),
  }),
  postcode: string().when(PDF_INCLUDE_ADDRESS_FIELD, {
    is: (pdfIncludesAddress: boolean) => !!pdfIncludesAddress,
    then: string().required(generalSettingsSchemaErrors.postcode),
  }),
})
