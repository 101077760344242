import { useTranslations } from '@npco/utils-translations'

import { SectionHeader } from '../../../SectionHeader'
import { translations } from './YourCardsListHeader.i18n'

export const YourCardsListHeader = () => {
  const t = useTranslations(translations)

  return <SectionHeader heading={t('heading')} />
}
