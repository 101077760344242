import { createContext, useContext } from 'react'

import {
  MultiEntityStoreUpdateEvent,
  SavedEntitySession,
} from './MultiEntityStore.types'

export type MultiEntityStoreContextType = {
  changeCurrentEntitySession: (entityUuid: string | null) => void
  removeEntitySession: (entityUuid: string) => void
  addEntitySession: (entityUuid: string) => void
  reorderEntitySessions: (visibleTabs: SavedEntitySession[]) => void
  subscribe: (
    listener: (
      visibleTabs: SavedEntitySession[],
      event: MultiEntityStoreUpdateEvent
    ) => void
  ) => () => void
}

export const MultiEntityStoreContext = createContext<
  MultiEntityStoreContextType | undefined
>(undefined)

export const useMultiEntityStore = () => {
  const context = useContext(MultiEntityStoreContext)

  if (!context) {
    throw new Error(
      'useMultiEntityStore must be used within MultiEntityStoreContext'
    )
  }

  return context
}
