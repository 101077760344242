import { useCallback, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { BREAKPOINT, showErrorToast } from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { setupFlowDebitCardDetailsTranslations } from './DebitCardDetails.i18n'
import { DebitCardDetailsContentDesktop } from './DebitCardDetailsContent/DebitCardDetailsContentDesktop'
import { DebitCardDetailsContentMobile } from './DebitCardDetailsContent/DebitCardDetailsContentMobile'
import { DebitCardDetailsContentTablet } from './DebitCardDetailsContent/DebitCardDetailsContentTablet'

export const DebitCardDetails = () => {
  const isMobile = useIsMobileResolution(BREAKPOINT.XS)
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.LG)
  const [isCardFlipped, setIsCardFlipped] = useState(false)
  const t = useTranslations(setupFlowDebitCardDetailsTranslations)

  const hideSensitiveInformationAndShowErrorToast = useCallback(() => {
    setIsCardFlipped(false)
    showErrorToast(t('errorMessage'))
  }, [t])

  const toggleCard = useCallback(() => {
    setIsCardFlipped(!isCardFlipped)
  }, [isCardFlipped, setIsCardFlipped])

  return (
    <>
      {!isMobileOrTablet && (
        <DebitCardDetailsContentDesktop
          toggleCard={toggleCard}
          isCardFlipped={isCardFlipped}
          onError={hideSensitiveInformationAndShowErrorToast}
        />
      )}

      {isMobileOrTablet && !isMobile && (
        <DebitCardDetailsContentTablet
          toggleCard={toggleCard}
          isCardFlipped={isCardFlipped}
          onError={hideSensitiveInformationAndShowErrorToast}
        />
      )}

      {isMobile && (
        <DebitCardDetailsContentMobile
          toggleCard={toggleCard}
          isCardFlipped={isCardFlipped}
          onError={hideSensitiveInformationAndShowErrorToast}
        />
      )}
    </>
  )
}
