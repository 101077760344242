import { useTranslations } from '@npco/utils-translations'
import { COLOR } from '@npco/zeller-design-system'

import { HeaderTooltipButton } from '../../components/HeaderTooltipButton'
import { spendControlLimitTranslations } from '../SpendControlLimit.i18n'

export const SpendControlLimitTooltipButton = () => {
  const t = useTranslations(spendControlLimitTranslations)

  return (
    <HeaderTooltipButton
      content={t('headerTooltip')}
      iconColour={COLOR.GREY_250}
    />
  )
}
