import { BREAKPOINT } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { ReactComponent as NotAllowedIcon } from 'assets/svg/not-allowed.svg'
import { ReactComponent as PadlockIcon } from 'assets/svg/padlock.svg'

export const StyledAccountStatus = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

export const StyledAccountStatusLabel = styled.span`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: inline-block;
  }
`

const iconStyle = css`
  padding: 0 12px 0 0;
`

export const StyledIconAccountSuspended = styled(PadlockIcon).attrs({
  'data-testid': 'suspended-icon',
})`
  ${iconStyle}
  path {
    fill: ${({ theme }) => theme.colors.YELLOW_1000};
  }
`

export const StyledIconAccountClosed = styled(NotAllowedIcon).attrs({
  'data-testid': 'closed-icon',
})`
  ${iconStyle}
  path {
    fill: ${({ theme }) => theme.colors.GREY_250};
  }
  display: none;
  @media screen and (min-width: ${BREAKPOINT.XS - 1}px) {
    display: inline-block;
  }
`
