import { DecisionModal } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledDecisionModal = styled(DecisionModal)`
  .remove-user-modal-body {
    margin-top: 0;
  }
  .remove-user-modal-footer {
    margin-top: 0;
  }
`
export const ModalWrapper = styled.article`
  display: flex;
  flex-direction: column;
`

export const SectionWrapper = styled.article<{
  gap?: string
  m?: string
  direction?: 'row' | 'column'
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  gap: ${({ gap }) => gap || '12px'};
  ${({ m }) => `margin: ${m}`};
  justify-content: space-between;
`

export const StyledModalHeader = styled.h1`
  ${({ theme }) => theme.typography['heading-2xl']};
  margin: 0;
`

export const StyledModalDescription = styled.p`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0 0 12px 0;
`

export const StyledSectionHeader = styled.h2`
  ${({ theme }) => theme.typography['heading-lg']};
  font-size: 18px;
  margin: 0;
`
