import { useMemo } from 'react'
import {
  BoxProps,
  Flex,
  Spinner as SpinnerComponent,
} from '@npco/zeller-design-system'

type WrapperVariants = 'centre' | 'top'

const commonWrapperStyleMap: Record<WrapperVariants, BoxProps> = {
  centre: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  top: {
    marginTop: '64px',
    justifyContent: 'center',
    flexGrow: 1,
  },
}

interface SpinnerWrappedProps extends BoxProps {
  variant?: WrapperVariants
}

export const SpinnerWrapped = ({
  variant,
  ...wrapperProps
}: SpinnerWrappedProps) => {
  const variantStyles = useMemo(
    () => (variant ? commonWrapperStyleMap[variant] : undefined),
    [variant]
  )
  return (
    <Flex {...variantStyles} {...wrapperProps}>
      <SpinnerComponent dataTestId="loader" />
    </Flex>
  )
}
