import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'

import {
  CORPORATE_CARD_WALKTHROUGH_STAGE,
  CorporateCardWalkthroughLocationSchema,
} from '../../CorporateCardWalkthrough.types'

export const useCorporateCardWalkthroughStage = () => {
  const location = useLocationState(CorporateCardWalkthroughLocationSchema)

  const stage = useMemo(() => {
    if (
      !location?.CorporateCardWalkthroughModal?.stage ||
      !CORPORATE_CARD_WALKTHROUGH_STAGE.includes(
        location.CorporateCardWalkthroughModal.stage
      )
    ) {
      return null
    }

    return location.CorporateCardWalkthroughModal.stage
  }, [location])

  return { stage }
}
