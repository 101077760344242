import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { CreateNewCategory } from './ItemCategoriesCreateNew.styled'

interface ItemCategoriesCreateNewProps {
  search: string
  onClick: () => void
}

export const ItemCategoriesCreateNew = ({
  search,
  onClick: handleOnClick,
}: ItemCategoriesCreateNewProps) => {
  const tShared = useTranslations(translationsShared)
  return (
    <CreateNewCategory
      data-testid="items-item-create-new-category"
      onClick={handleOnClick}
      size={BUTTON_SIZE.MEDIUM}
    >
      {`${tShared('create')} "${search}"`}
    </CreateNewCategory>
  )
}
