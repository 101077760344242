import { VelocityWindowEnum } from '@npco/mp-gql-types'
import { z } from 'zod'

const EditSpendControlsStageSchema = z.literal('edit')

export type EditSpendControlsStage = z.infer<
  typeof EditSpendControlsStageSchema
>

const ModifiedBySchema = z.object({
  actingCustomerUuid: z.string(),
  updatedAt: z.string(),
})

export type ModifiedBy = z.infer<typeof ModifiedBySchema>

const EditSpendControlsStateSchema = z.object({
  stage: EditSpendControlsStageSchema,
  cardUuid: z.string(),
  accountUuid: z.string(),
  amountLimit: z.string().optional(),
  maxTransactionValue: z.string().optional(),
  velocityWindow: z.nativeEnum(VelocityWindowEnum).optional(),
  modifiedBy: ModifiedBySchema.optional(),
})

export type EditSpendControlsState = z.infer<
  typeof EditSpendControlsStateSchema
>

export const EditSpendControlsLocationStateSchema = z.object({
  EditSpendControlsModal: EditSpendControlsStateSchema,
})
