import { SourceFilter } from '@npco/mp-gql-types'

import { ReactComponent as T2Icon } from 'assets/svg/t2.svg'
import { getTransactionType } from 'hooks/useTransactionsList/useTransactionsList.utils'
import { paymentSourceIconsAndTranslations } from 'const/payment-source'
import { translate } from 'utils/translations'
import { DeviceModel } from 'types/devices'
import { TransactionInList } from 'types/transactions'

const NO_RECORD_ERROR = 'no-record'

export const getTypeAndMaskedPan = (transaction: TransactionInList) => {
  if (transaction.maskedPan) {
    const re = /\d{4}/
    const match = re.exec(transaction.maskedPan)

    if (transaction.maskedPan === NO_RECORD_ERROR || !match) {
      return getTransactionType(transaction.type)
    }

    return `${getTransactionType(
      transaction.type
    )} ••• ${transaction.maskedPan.slice(-4)}`
  }

  return getTransactionType(transaction.type)
}

export const getPaymentSourceIconAndTranslation = (
  transaction: TransactionInList
) => {
  if (transaction.deviceModel === DeviceModel.T2) {
    return {
      icon: <T2Icon />,
      translation: translate('page.transactions.tooltip.terminal'),
    }
  }
  if (
    transaction.sourceFilter &&
    paymentSourceIconsAndTranslations[transaction.sourceFilter]
  ) {
    return paymentSourceIconsAndTranslations[transaction.sourceFilter]
  }
  return paymentSourceIconsAndTranslations[SourceFilter.TERMINAL]
}
