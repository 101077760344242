import {
  ModalBasic,
  SkeletonAvatar,
  SkeletonTypographyBase,
  TypographyTypes,
} from '@npco/zeller-design-system'

import { StyledLoaderList } from 'components/LoaderList/LoaderList.styled'
import { StyledLoaderRow } from 'components/LoaderList/LoaderRow.styled'

const widthArray = [
  { index: 0, width: 200 },
  { index: 1, width: 300 },
  { index: 2, width: 100 },
  { index: 3, width: 200 },
  { index: 4, width: 300 },
  { index: 5, width: 100 },
  { index: 6, width: 200 },
]

export const SelectAccountSkeleton = () => {
  return (
    <ModalBasic.Body
      data-testid="select-account-skeleton"
      className="no-scroll-bar"
    >
      <StyledLoaderList>
        {widthArray.map((width) => (
          <StyledLoaderRow key={width.index}>
            <SkeletonAvatar width="40px" height="40px" />
            <SkeletonTypographyBase
              width={width.width}
              margin="0 0 0 12px"
              type={TypographyTypes.BodyDefault}
            />
          </StyledLoaderRow>
        ))}
      </StyledLoaderList>
    </ModalBasic.Body>
  )
}
