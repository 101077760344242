import { BodyDefault, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { Button } from 'components/Buttons/Button'

export const StyledTitle = styled(H3)`
  margin: 0 0 2rem 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledDescription = styled(BodyDefault)`
  margin: 0 0 1.25rem;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledPrimaryButton = styled(Button)`
  height: 100%;
`
