import { getIsValidInputString } from 'utils/formValidation'
import { CustomValidator } from 'types/common'
import { errorMessages } from 'translations'

const getIsDeviceNameValid = (value: string) => /^.{1,256}$/.test(value)

export const validateDeviceName: CustomValidator<string> = (value) => {
  if (!getIsValidInputString(value)) {
    return errorMessages.deviceNameRequired
  }

  const valueTrimmed = value?.trim()

  if (valueTrimmed && !getIsDeviceNameValid(valueTrimmed)) {
    return errorMessages.deviceNameMaxLength
  }

  return undefined
}
