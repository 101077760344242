import { COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as GaugeIcon } from 'assets/svg/no-account-transactions.svg'
import { ReactComponent as MagnifierIcon } from 'assets/svg/search.svg'
import { component } from 'translations'

import { PlaceholderSize } from '../Placeholders.types'
import {
  getDescriptionBasedOnVariant,
  getIconSizeByVariant,
  getTitleBasedOnVariant,
} from '../Placeholders.utils'
import {
  StyledExtraLargeDescription,
  StyledIconBox,
  StyledLargeTitle,
  StyledTextContainer,
  StyledWrapper,
} from '../SharedElements.styled'

interface Props {
  variant?: PlaceholderSize
  title?: string
  description?: string
}

export const NoInitialResults = ({
  variant = PlaceholderSize.Large,
  title = component.noInitialResults.titleAccountTransactions,
  description = component.noInitialResults.descriptionAccountTransactions,
}: Props) => {
  const StyledTitleVariant = getTitleBasedOnVariant(variant)
  const StyledDescriptionVariant = getDescriptionBasedOnVariant(variant)

  const iconSize = getIconSizeByVariant(variant)

  return (
    <StyledWrapper
      alignItems="center"
      justifyContent="center"
      data-testid="no-initial-results"
    >
      <Flex flexDirection="column" alignItems="center">
        <StyledIconBox
          alignItems="center"
          justifyContent="center"
          variant={variant}
        >
          <SvgIcon color={COLOR.BLUE_1000}>
            <GaugeIcon width={iconSize} height={iconSize} />
          </SvgIcon>
        </StyledIconBox>
        <StyledTextContainer>
          <StyledTitleVariant>{title}</StyledTitleVariant>
          <StyledDescriptionVariant>{description}</StyledDescriptionVariant>
        </StyledTextContainer>
      </Flex>
    </StyledWrapper>
  )
}

export const NoSearchResults = () => {
  const title = component.noResults.titleAccountTransactions
  const description = component.noResults.descTransactions

  return (
    <StyledWrapper
      alignItems="center"
      justifyContent="center"
      height="100%"
      data-testid="no-search-results"
    >
      <Flex flexDirection="column" alignItems="center">
        <StyledIconBox alignItems="center" justifyContent="center">
          <SvgIcon width="22" height="22" color={COLOR.BLUE_1000}>
            <MagnifierIcon />
          </SvgIcon>
        </StyledIconBox>
        <StyledTextContainer>
          <StyledLargeTitle>{title}</StyledLargeTitle>
          <StyledExtraLargeDescription>
            {description}
          </StyledExtraLargeDescription>
        </StyledTextContainer>
      </Flex>
    </StyledWrapper>
  )
}
