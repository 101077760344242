import { useRef } from 'react'
import { Prompt } from 'react-router-dom'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useModalState } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { ConfirmNavigationModal } from '../../components/ConfirmNavigationModal'
import { ReceiptFormFields } from '../Receipt/Receipt.types'

export const ReceiptEditFormClosePrompt = () => {
  const navigate = useNavigate()
  const { openModal, closeModal, isModalOpen } = useModalState()
  const { dirty } = useFormikContext<ReceiptFormFields>()
  const isConfirmedNavigation = useRef(false)
  const redirectUrl = useRef('')

  return (
    <>
      <Prompt
        when={dirty}
        message={(location) => {
          if (isConfirmedNavigation.current) {
            return true
          }

          redirectUrl.current = location.pathname
          openModal()
          return false
        }}
      />
      <ConfirmNavigationModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={() => {
          isConfirmedNavigation.current = true
          navigate(redirectUrl.current)
        }}
        description={translate(
          'page.settings.connections.xeroPaymentServices.interruptFlowModal.editPaymentsDescription'
        )}
      />
    </>
  )
}
