import { useCallback } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'

import { useSendInternalTransfer } from 'pages/Transfer/hooks/useSendInternalTransfer/useSendInternalTransfer'

import { UNEXPECTED_ERROR } from '../../../../../../types/errors'
import { useInternalTransferModal } from '../../../internal-transfer-routing'
import { TransactionDetails } from '../../../internal-transfer-routing/InternalTransfer.types'
import { useSubmitInternalTransferTranslations } from './useSubmitInternalTransfer.i18n'

export const CURRENCY = 'AUD'

export const useSubmitInternalTransfer = () => {
  const t = useTranslations(useSubmitInternalTransferTranslations)
  const { openInternalTransferModal } = useInternalTransferModal()
  const { sendInternalTransfer, sendTransferLoading } =
    useSendInternalTransfer()

  const submitInternalTransfer = useCallback(
    async (transferDetails: TransactionDetails) => {
      const result = await sendInternalTransfer({
        to: transferDetails.to.id,
        from: transferDetails.from.id,
        amountInCents: +transferDetails.amount * 100,
        reference: transferDetails.reference,
      })

      if (result === UNEXPECTED_ERROR) {
        showErrorToast(t('error'))
        return
      }

      openInternalTransferModal(
        {
          stage: 'complete',
          transferDetails,
          transactionTimestamp: new Date().toISOString(),
        },
        { replace: true }
      )
    },
    [openInternalTransferModal, sendInternalTransfer, t]
  )

  return { submitInternalTransfer, submitTransferLoading: sendTransferLoading }
}
