import {
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { translate } from 'utils/translations'

const PrimaryButton = styled(ButtonFill)`
  padding: 14px 57px;
`

interface FormButtonsProps {
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  isLoading?: boolean
}

export const FormButtons = ({
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonLabel = translate('shared.save'),
  secondaryButtonLabel = translate('shared.cancel'),
  isLoading,
}: FormButtonsProps) => {
  return (
    <Flex gridGap="24px" alignItems="flex-start">
      <PrimaryButton
        type="submit"
        size={BUTTON_SIZE.LARGE}
        isLoading={isLoading}
        onClick={onPrimaryButtonClick}
      >
        {primaryButtonLabel}
      </PrimaryButton>
      <ButtonGhost
        disabled={isLoading}
        size={BUTTON_SIZE.LARGE}
        onClick={onSecondaryButtonClick}
      >
        {secondaryButtonLabel}
      </ButtonGhost>
    </Flex>
  )
}
