import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { InvoiceStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  InfoBox,
  INFOBOX_PRIORITY,
  INFOBOX_VARIANT,
  Timeline,
  TimelineItemProps,
} from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'
import { InvoiceActivityFragment } from 'types/gql-types/InvoiceActivityFragment'

import { translations } from './InvoiceDrawerActivity.i18n'
import * as styled from './InvoiceDrawerActivity.styled'
import {
  getDateDisplay,
  getTimelineBodyContent,
  getTimelineHeader,
} from './InvoiceDrawerActivity.utils'
import { InvoiceDrawerActivityPlaceholder } from './InvoiceDrawerActivityPlaceholder'

interface InvoiceDrawerActivityProps {
  activities: InvoiceActivityFragment[]
  referenceNumber: string
  status: InvoiceStatus
}

export const InvoiceDrawerActivity = ({
  activities,
  referenceNumber,
  status,
}: InvoiceDrawerActivityProps) => {
  const t = useTranslations(translations)

  const activityItems: TimelineItemProps[] = useMemo(
    () =>
      activities.map((activity) => {
        const isComplete = dayjs(activity.completedTime).isValid()

        return {
          body: getTimelineBodyContent(activity, t),
          canToggle: !isComplete,
          header: getTimelineHeader(activity, t),
          id: activity.id,
          isActive: isComplete,
          isDefaultExpanded: isComplete,
          label: isComplete
            ? getDateDisplay(activity.completedTime)
            : getDateDisplay(activity.dueDate),
        }
      }),
    [activities, t]
  )

  if (activityItems.length < 2) {
    return (
      <InvoiceDrawerActivityPlaceholder referenceNumber={referenceNumber} />
    )
  }

  return (
    <>
      {(status === InvoiceStatus.DRAFT ||
        status === InvoiceStatus.SCHEDULED) && (
        <styled.InfoBoxWrapper>
          <InfoBox
            priority={INFOBOX_PRIORITY.MEDIUM}
            variant={INFOBOX_VARIANT.POSITIVE}
          >
            <InfoBox.Message>{t('draftInfobox')}</InfoBox.Message>
          </InfoBox>
        </styled.InfoBoxWrapper>
      )}
      <Timeline items={activityItems} />
    </>
  )
}

InvoiceDrawerActivity.fragments = {
  InvoiceActivity: gql`
    fragment InvoiceActivityFragment on InvoiceActivity {
      __typename
      balance
      cnpTxnRefNum
      completedTime
      contactName
      contactUuid
      dueDate
      failureReason
      id
      paidAmount
      status
      reminderIndex
      title
      type
    }
  `,
}
