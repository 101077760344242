import { useLazyQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetInvoiceItemSettings as GetInvoiceItemSettingsResponse } from 'types/gql-types/GetInvoiceItemSettings'

import { GetInvoiceItemSettings } from './graphql/getInvoiceItemSettings'

export const useGetInvoiceItemSettings = () => {
  const entityUuid = useSelectedEntityUuid()

  const [getInvoiceItemSettings, { data, loading: isLoading }] =
    useLazyQuery<GetInvoiceItemSettingsResponse>(GetInvoiceItemSettings, {
      fetchPolicy: 'network-only',
      variables: {
        entityUuid,
      },
    })

  return {
    getInvoiceItemSettings,
    isLoading,
    settings: data?.getCatalogSettings,
  }
}
