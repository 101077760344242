import ReactSlider from 'react-slider'
import styled from 'styled-components'

export const StyledRange = styled(ReactSlider)<{ $margin?: string }>`
  box-sizing: border-box;
  height: 30px;
  margin: ${({ $margin }) => $margin || '0 16px'};
`

export const StyledThumb = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor || theme.colors.WHITE};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.BLUE_1000};
  cursor: grab;
  height: 24px;
  outline: none;
  width: 24px;
`

export const StyledTrack = styled.div<{ index?: number }>`
  background: ${({ index, theme }) =>
    index === 1 ? theme.colors.BLUE_1000 : theme.colors.GREY_60};
  bottom: 0;
  height: 2px;
  top: 12px;
`
