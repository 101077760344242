import { AnchorBasic } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { shared } from 'translations'

export const SupportMobile = () => {
  return (
    <AnchorBasic href={`tel:${shared.contactSupport.primaryPhone}`}>
      {translate('shared.contactSupport.primaryPhone')}
    </AnchorBasic>
  )
}
