import { gql } from '@apollo/client'

export const ExportSimBillingStatement = gql`
  query ExportSimBillingStatement(
    $entityUuid: ID!
    $filter: BillFilterInput!
    $format: SimBillingStatementExportFormat!
  ) {
    exportSimBillingStatement(
      entityUuid: $entityUuid
      filter: $filter
      format: $format
    ) {
      downloadLink
      expire
    }
  }
`

export const GetSimBills = gql`
  query GetSimBills(
    $entityUuid: ID!
    $filter: BillFilterInput
    $limit: Int!
    $nextToken: SimBillNextTokenInput
  ) {
    getSimBills(
      entityUuid: $entityUuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      bills {
        amount
        billingAccountName
        simId
        billingAccountUuid
        siteName
        siteUuid
        status
        taxAmount
        timestamp
      }
      nextToken {
        id
        type
        entityUuid
      }
    }
  }
`

export const GetBillingAccount = gql`
  query GetBillingAccount($entityUuid: ID!) {
    getBillingAccount(entityUuid: $entityUuid) {
      id
      accountBsb
      accountName
      accountNumber
    }
  }
`
