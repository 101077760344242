import { BodyDefault, H5Style } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const Label = styled(BodyDefault)`
  ${H5Style};
`

export const Details = styled(BodyDefault)`
  font-weight: 400;
  margin: 0;
`
