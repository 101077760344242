import { BodyDefault, ButtonClose, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledSubContactName = styled(BodyDefault)`
  padding-left: 1.2rem;
  line-height: 1;
  word-break: break-word;
`

export const StyledButtonClose = styled(ButtonClose)`
  display: none;
  margin-left: 1rem;

  svg path {
    fill: ${({ theme }) => theme.colors.GREY_550};

    :hover {
      fill: ${({ theme }) => theme.colors.BLUE_700};
    }
  }
`

export const StyledContact = styled(Flex)`
  align-items: center;
  flex-direction: row;
  margin-right: 1rem;
  padding: 0.5rem 0;
  cursor: pointer;
`

export const StyledContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  :hover {
    ${StyledContact} {
      border-radius: 7px;
      background: ${({ theme }) => theme.colors.BLUE_80};
      flex: 1;
      margin: 0 -0.5rem;
      padding: 0.5rem 0.5rem;
    }

    ${StyledSubContactName} {
      color: ${({ theme }) => theme.colors.BLUE_1000};
    }

    ${StyledButtonClose} {
      display: block;
    }
  }
`
