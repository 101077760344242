import { useTranslations } from '@npco/utils-translations'

import { ScrollableContent, Title } from '../../../SetupFlowModal.styled'
import { setupFlowStartStageTranslations } from '../SetupFlowStartStage.i18n'
import * as styled from './MainContent.styled'

export const MainContent = () => {
  const t = useTranslations(setupFlowStartStageTranslations)

  return (
    <ScrollableContent
      data-testid="setup-flow-start-stage"
      py={{ _: '120px', SM: '96px', LG: '158px' }}
    >
      <Title>{t('title')}</Title>
      <styled.Description>{t('description')}</styled.Description>
    </ScrollableContent>
  )
}
