import { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { DepositExportFormat } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { renameAndDownloadFile } from '@npco/utils-file'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { ExportDepositStatement } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/deposits'
import { rvSelectedDepositsSite } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import dayjs from 'utils/dayjs'
import { mapFiltersInputToDepositsStatementsApiFilter } from 'utils/mapFiltersInputToApiFilter'
import { DatesRange, StatementPeriod } from 'types/dates'
import { ExportDepositStatement as ExportDepositStatementType } from 'types/gql-types/ExportDepositStatement'
import {
  ALL_SITES,
  getStatementDate,
} from 'components/Statements/Statements.utils'
import { page } from 'translations'

const DATE_FORMAT = 'DD/MM/YYYY'
const isLocalDate = true

export const useFetchDepositsStatements = () => {
  const entityUuid = useSelectedEntityUuid()
  const selectedSite = useReactiveVar(rvSelectedDepositsSite)
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [datesRange, setDatesRange] = useState<DatesRange>({
    from: undefined,
    to: undefined,
  })
  const siteUuid = useMemo(
    () => (selectedSite === ALL_SITES ? undefined : selectedSite),
    [selectedSite]
  )

  const fileName = useMemo(() => {
    return `${page.settings.statements.depositsStatement} ${dayjs(
      datesRange.from
    ).format(DATE_FORMAT)} - ${dayjs(datesRange.to).format(DATE_FORMAT)}`
  }, [datesRange])

  const [getSingleSiteStatementData] = useLazyQuery<ExportDepositStatementType>(
    ExportDepositStatement,
    {
      onError: (err) => {
        showApiErrorToast(err)
        setIsLoading(false)
      },
      onCompleted: async (data) => {
        if (!data?.exportDepositStatement) {
          showApiErrorToast()
          setIsLoading(false)
          return
        }
        await renameAndDownloadFile({
          link: data?.exportDepositStatement.downloadLink,
          filename: fileName,
          format: DepositExportFormat.PDF,
        })
        setIsLoading(false)
        setHasLoaded(true)
      },
    }
  )

  useEffect(() => {
    setHasLoaded(false)
  }, [selectedSite])

  const exportDepositFile = (statementPeriod: StatementPeriod) => {
    setHasLoaded(false)

    const dates = getStatementDate(statementPeriod)
    setDatesRange(dates)
    setIsLoading(true)

    getSingleSiteStatementData({
      variables: {
        entityUuid,
        filter: mapFiltersInputToDepositsStatementsApiFilter(
          { dates, siteUuid },
          isLocalDate
        ),
        format: DepositExportFormat.PDF,
      },
    })
  }

  return {
    exportDepositFile,
    isLoadingDeposits: isLoading,
    hasLoaded,
  }
}
