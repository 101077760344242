import { Box, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'

import {
  combineValidators,
  validateAbnOrAcn,
  validateRequired,
} from 'utils/formValidation'
import { FormLine } from 'components/FormLine'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { form } from 'translations'

export const TrusteeCompanyForm = () => (
  <Box mb="2.5rem" mt="2.5rem">
    <FormLine>
      <Box mb="2.5rem">
        <Box mb="2.5rem">
          <InputAdaptiveFieldWrapper
            label={form.trustees.companyName.label}
            placeholder={form.trustees.companyName.placeholder}
            name="companyTrustName"
            type="text"
            size={TEXT_INPUT_SIZE.SMALL}
            validate={validateRequired}
          />
        </Box>
        <InputAdaptiveFieldWrapper
          label={form.companySearch.abnOrAcn.label}
          placeholder={form.trustees.abnOrAcn.placeholder}
          name="abnOrAcn"
          size={TEXT_INPUT_SIZE.SMALL}
          validate={combineValidators(validateRequired, validateAbnOrAcn)}
        />
      </Box>
    </FormLine>
  </Box>
)
