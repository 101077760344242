import { useMemo } from 'react'
import { SourceFilter } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { Items, MultiSelect } from 'components/Filters/PillFilters/MultiSelect'

import { translations } from './Source.i18n'

type SourcesProps = {
  selectedSources?: `${SourceFilter}`[]
  setSelectedSources: (sources: `${SourceFilter}`[]) => void
}

export const Source = ({
  selectedSources,
  setSelectedSources,
}: SourcesProps) => {
  const t = useTranslations(translations)

  const selectItems = useMemo(
    () =>
      [
        { id: '1', label: t('DASHBOARD'), value: 'DASHBOARD' },
        { id: '2', label: t('INVOICE'), value: 'INVOICE' },
        { id: '3', label: t('POINT_OF_SALE'), value: 'POINT_OF_SALE' },
        { id: '4', label: t('TERMINAL'), value: 'TERMINAL' },
        { id: '5', label: t('ZELLER_APP'), value: 'ZELLER_APP' },
      ] satisfies Items<`${SourceFilter}`>,
    [t]
  )

  return (
    <MultiSelect
      onSelectItem={setSelectedSources}
      selectedItems={selectedSources}
      items={selectItems}
      defaultLabel={t('sourcesLabel')}
    />
  )
}
