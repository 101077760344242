import { BodySmall } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceDrawerActivityPlaceholderWrapper = styled.div`
  ${BodySmall} {
    text-align: center;
  }
`

export const InfoBoxWrapper = styled.div`
  margin-bottom: 40px;
`
