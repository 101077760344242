import { matchPath } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'

import { ROUTE_PERMISSIONS } from 'components/PrivateRoute/PrivateAdminRoute/routesPermissions'

const routeHasPermissionAssigned = (route: string) =>
  Object.keys(ROUTE_PERMISSIONS).some((routeKey) =>
    Boolean(matchPath(routeKey, route))
  )

const routeHasPermission = (route: string, role: CustomerRole) => {
  const matchingRoute = Object.keys(ROUTE_PERMISSIONS).find((routeKey) =>
    Boolean(matchPath(routeKey, route))
  )
  return matchingRoute ? ROUTE_PERMISSIONS[matchingRoute].includes(role) : false
}

export const routeCanBeAccessed = (
  route: string | undefined,
  role: CustomerRole
) => {
  if (!route) {
    return true
  }
  if (routeHasPermissionAssigned(route)) {
    return routeHasPermission(route, role)
  }
  return true
}
