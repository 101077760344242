import { useReactiveVar } from '@apollo/client'
import { SimBillingStatus } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex } from '@npco/zeller-design-system'
import { rvSimBillingAccount } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/simBillingAccount'

import dayjs from 'utils/dayjs'
import { useFilterSimBillingAccount } from 'pages/Settings/SimBillingAccount/hooks/useFilterSimBillingAccount'
import { DetailsField } from 'components/DetailsField/DetailsField'

import { DEFAULT_DATA_ITEM, getPlanDisplayInfo } from '../Sim.utils'
import { GetSimQueryResponse } from './graphql/getSim.generated'
import { translations } from './SimDrawer.i18n'

type SimDetailsProps = {
  loading: boolean
  selectedSim?: GetSimQueryResponse['getSim']
}
export const SimDetails = ({ loading, selectedSim }: SimDetailsProps) => {
  const t = useTranslations(translations)
  useFilterSimBillingAccount()

  const cancelledStatuses = [
    SimBillingStatus.CANCELLED,
    SimBillingStatus.CANCELLED_FAILED_PAYMENT,
  ]
  const isSimCancelled = cancelledStatuses.includes(
    selectedSim?.billingStatus ?? SimBillingStatus.NULL
  )

  const simBillingAccount = useReactiveVar(rvSimBillingAccount)

  return (
    <Flex flexDirection="column">
      <DetailsField
        isLoading={loading}
        label={t('billingAccount')}
        value={simBillingAccount?.name ?? DEFAULT_DATA_ITEM}
      />
      <DetailsField
        isLoading={loading}
        label={t('simNumber')}
        value={selectedSim?.id ?? DEFAULT_DATA_ITEM}
      />
      <DetailsField
        isLoading={loading}
        label={t('site')}
        value={selectedSim?.siteName ?? DEFAULT_DATA_ITEM}
      />
      <DetailsField
        isLoading={loading}
        label={t('terminal')}
        value={selectedSim?.deviceName ?? DEFAULT_DATA_ITEM}
      />
      {!isSimCancelled && (
        <>
          <DetailsField
            isLoading={loading}
            label={t('plan')}
            value={getPlanDisplayInfo(selectedSim?.billingAmount)}
          />
          <DetailsField
            isLoading={loading}
            label={t('nextRecharge')}
            value={dayjs(selectedSim?.nextBillingDate).format('DD MMM YYYY')}
          />
        </>
      )}
    </Flex>
  )
}
