import {
  AmountColumnEnum,
  DateColumnEnum,
} from './components/Invoices/InvoiceTable/InvoiceTable.types'

export const InvoicesDefaultLocalState = {
  columns: {
    amount: AmountColumnEnum.AmountPaid,
    date: DateColumnEnum.NextDue,
  },
  filter: {
    amountFilter: null,
    dateFilter: null,
    statusFilter: null,
    textSearchFilter: '',
  },
  sort: null,
  statuses: [],
}
