import { OnboardingContent } from 'features/OnboardingApp/OnboardingContent'

import { FormLayout } from 'layouts/FormLayout/FormLayout'
import { shared } from 'translations'

import { OnboardingPageLayoutProps } from './OnboardingPageLayout.types'

const { next } = shared

export const OnboardingPageLayout: React.FC<OnboardingPageLayoutProps> = ({
  backButtonLabel,
  backDisabled,
  children,
  confirmDisabled,
  dataTestId,
  description,
  hasLogo,
  isBackButtonHidden = false,
  isNextButtonHidden = false,
  isVerticallyCentered = true,
  loaderText,
  maxWidth = '512px',
  nextButtonLabel = next,
  selectedDocumentsAmount = undefined,
  isForm = true,
  title,
  onBackClick,
  onConfirmClick,
  isLoading,
  innerContainerJustifyContent,
  controls,
  margin,
  marginTop,
}) => {
  return (
    <OnboardingContent
      isVerticallyCentered={isVerticallyCentered}
      loaderText={loaderText}
      isLoading={isLoading}
    >
      <FormLayout
        dataTestId={dataTestId}
        isForm={isForm}
        title={title}
        maxWidth={maxWidth}
        innerContainerJustifyContent={innerContainerJustifyContent}
        hasLogo={hasLogo}
        isVerticallyCentered={isVerticallyCentered}
        description={description}
        selectedDocumentsAmount={selectedDocumentsAmount}
        controls={controls}
        isBackButtonHidden={isBackButtonHidden}
        isNextButtonHidden={isNextButtonHidden}
        backButtonLabel={backButtonLabel}
        onBackClick={onBackClick}
        onConfirmClick={onConfirmClick}
        backDisabled={backDisabled}
        confirmDisabled={confirmDisabled}
        nextButtonLabel={nextButtonLabel}
        margin={margin}
        marginTop={marginTop}
      >
        {children}
      </FormLayout>
    </OnboardingContent>
  )
}
