import * as styled from './TransferConfirmationHeader.styled'

type HideDetailsButtonProps = {
  isExpanded: boolean
  paddingBottom: string
  toggleShowDetails: () => void
  hideDetailsText: string
  isTransferRemittancesFlagOn: boolean
}

export const HideDetailsButton = ({
  isExpanded,
  paddingBottom,
  toggleShowDetails,
  hideDetailsText,
  isTransferRemittancesFlagOn,
}: HideDetailsButtonProps) => {
  return (
    <styled.ActionButton
      $isExpanded={isExpanded}
      $paddingBottom={paddingBottom}
      $isTransferRemittancesFlagOn={isTransferRemittancesFlagOn}
      onClick={toggleShowDetails}
    >
      {hideDetailsText}
    </styled.ActionButton>
  )
}
