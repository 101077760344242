import { useEditDebitCardTransactionImagesState } from 'features/EditDebitCardTransactionImages/edit-debit-card-transaction-images-routing'

import { UploadReceipts } from './UploadReceipts/UploadReceipts'

export const EditDebitCardTransactionImagesModal = () => {
  const { state } = useEditDebitCardTransactionImagesState()

  if (state === null) {
    return null
  }

  return (
    <UploadReceipts
      transactionUuid={state.debitCardTransactionUuid}
      maxFiles={state.maxFiles}
    />
  )
}
