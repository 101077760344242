import { gql } from '@apollo/client'
import { ImageSize } from '@npco/mp-gql-types'

import { getIconImageUrlBySize } from './getIconImageUrlBySize/getIconImageUrlBySize'
import { GetImageUrlIconFragment } from './getImageUrl.generated'

export const getImageUrl = (icon: GetImageUrlIconFragment | null) =>
  getIconImageUrlBySize(icon, ImageSize.Thumbnail) ??
  getIconImageUrlBySize(icon, ImageSize.Native) ??
  icon?.image

getImageUrl.fragments = {
  Icon: gql`
    fragment GetImageUrlIconFragment on Icon {
      image
      ...GetIconImageUrlBySizeIconFragment
    }

    ${getIconImageUrlBySize.fragments.Icon}
  `,
}
