import { AccountConnectionStatus } from '@npco/mp-gql-types'

import { XeroAccount, XeroBankAccount } from '../Components/AccountSelect.types'

export const isAccountSelected = (
  account: XeroAccount | null,
  selectedAccounts: XeroBankAccount[] = []
) => {
  return !!selectedAccounts.find(
    ({ id, accountConnectionStatus }) =>
      id === account?.id &&
      accountConnectionStatus === AccountConnectionStatus.LINKED
  )
}
