import { Box, COLOR, Typography } from '@npco/zeller-design-system'

import { useSelectedDateRange } from '../../hooks/useSelectedDateRange'

interface CategoryDrawerHeaderProps {
  incomeOrExpense: 'Income' | 'Expenses'
}

export const CategoryDrawerHeader = ({
  incomeOrExpense = 'Expenses',
}: CategoryDrawerHeaderProps) => {
  const { selectedDateRange } = useSelectedDateRange()

  const monthSelectText =
    selectedDateRange === 'Last 12 Months'
      ? 'Last 12 Months'
      : `${selectedDateRange.month} ${selectedDateRange.year}`

  return (
    <Typography variant="heading-lg" color={COLOR.GREY_550}>
      <Box>{incomeOrExpense}</Box>
      <Box>{monthSelectText}</Box>
    </Typography>
  )
}
