import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'
import { Condition } from 'components/Condition/Condition'

import { ReviewCard } from './ReviewCard/ReviewCard'

interface ReviewCardStageProps {
  setIsCloseButtonHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReviewCardStage = ({
  setIsCloseButtonHidden,
}: ReviewCardStageProps) => {
  const { state } = useAddCardState()

  return (
    <Condition shouldShow={state?.stage === AddCardStage.CreateReviewCardStage}>
      <ReviewCard
        dataTestId="review-card-stage"
        setIsCloseButtonHidden={setIsCloseButtonHidden}
      />
    </Condition>
  )
}
