import { useCallback } from 'react'
import { ErrorLogger } from '@npco/utils-error-logger'
import { showErrorToast } from '@npco/zeller-design-system'

import { useGoToAddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage'
import {
  AddCardStage,
  CreateSelectCardholderState,
} from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { useAddCardState } from 'pages/GlobalModals/AddCardModal/hooks/useAddCardState'

import { GetCardholderListQueryResponse } from '../graphql/customers.generated'
import { SelectCardholderFormValues } from '../SelectCardholderForm.types'

interface UseSelectCardholderFormProps {
  cardholders: NonNullable<GetCardholderListQueryResponse['getCustomers'][0]>[]
}

export const useSelectCardholderForm = ({
  cardholders,
}: UseSelectCardholderFormProps) => {
  const { goToAddCardStage } = useGoToAddCardStage()
  const { state } = useAddCardState<CreateSelectCardholderState>()

  const selectCardholder = useCallback(
    (values: SelectCardholderFormValues) => {
      const selectedCardholder = cardholders.find(
        (cardholder) => cardholder.id === values.cardholderId
      )

      if (state === null || selectedCardholder === undefined) {
        ErrorLogger.report(
          '[Banking] SelectCardholderStage submitting with null state.'
        )
        showErrorToast()
        return
      }

      goToAddCardStage({
        stage: AddCardStage.CreateSelectAccountStage,
        cardholder: {
          id: selectedCardholder.id,
          firstName: selectedCardholder.firstname || '',
          lastName: selectedCardholder.lastname || '',
        },
        cardType: state.cardType,
      })
    },
    [goToAddCardStage, state, cardholders]
  )

  const goBackToSelectCardType = useCallback(() => {
    if (state === null) {
      ErrorLogger.report(
        '[Banking] SelectCardholderStage back to SelectAccountStage with null state.'
      )
      showErrorToast()
      return
    }

    goToAddCardStage({
      stage: AddCardStage.CreateSelectCardTypeStage,
      cardType: state.cardType,
    })
  }, [goToAddCardStage, state])

  return {
    selectCardholder,
    goBackToSelectCardType,
    initialCardholderId: state?.cardholder?.id || '',
  }
}
