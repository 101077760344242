import { Box, BREAKPOINT, ButtonLink } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

export const DebitCardPositionWrapper = styled(Box)`
  width: 222px;
  height: 140px;
  position: absolute;
  left: calc(50% - 110px);
  top: calc((100vw - 32px) / 3 - 64px);

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 266px;
    height: 168px;
    position: absolute;
    left: 25%;
    top: 122px;
  }

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    top: 122px;
    left: 125px;
  }

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    left: 650px;
    top: 235px;
  }
`

export const DebitCardImageStyles = css`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
`

export const ToggleLinkButton = styled(ButtonLink)`
  ${({ theme }) => theme.typography['heading-sm']}
  background: transparent;
`

export const DebitCardDetailsClickCover = styled.button`
  cursor: pointer;
  z-index: 999;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  ${DebitCardImageStyles}
`
