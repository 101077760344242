import { EntityCategories } from '@npco/mp-gql-types'
import { showErrorToast } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

export const displayCategoryUpdateError = () => {
  showErrorToast(translate('page.contacts.category.updateCategoryFailure'))
}

type NullableString = string | null

interface SubcategoryVariables {
  category: EntityCategories | null
  subcategoryName: NullableString
  subcategoryId: NullableString
  predefined?: boolean
}

export const getSubcategoryUpdate = ({
  category,
  subcategoryName,
  subcategoryId,
  predefined,
}: SubcategoryVariables) => {
  if (subcategoryName && subcategoryId) {
    return {
      category,
      subcategory: subcategoryId,
      subcategoryDetails: {
        id: subcategoryId,
        name: subcategoryName,
        predefined: predefined ?? true,
      },
    }
  }
  return {
    category,
    subcategory: subcategoryId,
    subcategoryDetails: null,
  }
}
