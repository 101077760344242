import { QUnitType } from 'dayjs'
import { descend, identity, pipe, sort } from 'ramda'

import dayjs from 'utils/dayjs'
import { groupByKey } from 'utils/groupByKey'
import { Deposit } from 'types/deposits'

const makeGetGroupingKey = (groupInterval: QUnitType) => (item: Deposit) => {
  const { timestamp } = item

  return String(+dayjs(timestamp).utc().local().startOf(groupInterval))
}

const sortTransactionGroupsByKeys = (
  depositGroups: Record<string, Deposit[]>
) => {
  const sortedKeys = sort(descend(identity), Object.keys(depositGroups))

  return sortedKeys.map((key) => depositGroups[key])
}

export const groupDepositsByYear = pipe(
  groupByKey(makeGetGroupingKey('year')),
  sortTransactionGroupsByKeys
)
