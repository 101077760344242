import { RefundPinType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { Flex, HeadingStyles, ToggleForm } from '@npco/zeller-design-system'
import { Field, useFormikContext } from 'formik'

import { validatePin } from 'utils/formValidation'
import { SettingsPayments } from 'types/settings'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import * as styled from '../../SitePayments.styled'
import { RefundPinTypeOptions } from './RefundPinTypeOptions'
import { translations } from './Refunds.i18n'

export const Refunds = () => {
  const { values } = useFormikContext<SettingsPayments>()
  const t = useTranslations(translations)
  return (
    <Flex flexDirection="column" gridGap="24px">
      <styled.Title>{t('refunds')}</styled.Title>
      <Flex flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between">
          <styled.Subtitle withStyles={HeadingStyles.H5}>
            {t('requirePin')}
          </styled.Subtitle>

          <Field
            component={ToggleForm}
            name="refundRequiresPin"
            value={values.refundRequiresPin}
          />
        </Flex>
        <styled.Description>{t('refundsDescription')}</styled.Description>
      </Flex>
      {values.refundRequiresPin && <RefundPinTypeOptions />}
      {values.refundRequiresPin &&
        values.refundPinType === RefundPinType.REFUND_PIN && (
          <div>
            <InputAdaptiveFieldWrapper
              label={t('refundPinLabel')}
              name="refundPin"
              validate={validatePin}
            />
          </div>
        )}
    </Flex>
  )
}
