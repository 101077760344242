import React, { useCallback } from 'react'
import { rvSelectedAccountTransaction } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import {
  useEditDebitCardTransactionCategoryState,
  useGoToEditDebitCardTransactionCategory,
} from 'features/EditDebitCardTransactionCategory/edit-debit-card-transaction-category-routing'

import {
  EditTransactionCategoryPills,
  EditTransactionCategoryPillsProps,
} from './EditTransactionCategoryPills/EditTransactionCategoryPills'

export const EditDebitCardTransactionCategoryModal = () => {
  const { state } = useEditDebitCardTransactionCategoryState()
  const { goToEditDebitCardTransactionCategory } =
    useGoToEditDebitCardTransactionCategory()

  const updateRvTransaction = useCallback<
    EditTransactionCategoryPillsProps['updateTransaction']
  >(
    ({ category, subcategory, subcategoryDetails }) => {
      const selectedTransaction = rvSelectedAccountTransaction()

      if (
        selectedTransaction &&
        selectedTransaction.id === state?.debitCardTransactionUuid
      ) {
        rvSelectedAccountTransaction({
          ...selectedTransaction,
          category:
            category === undefined ? selectedTransaction.category : category,
          subcategory:
            subcategory === undefined
              ? selectedTransaction.subcategory
              : subcategory,
          subcategoryDetails,
        })
      }
    },
    [state?.debitCardTransactionUuid]
  )

  if (state === null) {
    return null
  }

  return (
    <EditTransactionCategoryPills
      isModalOpen
      category={state.category}
      subcategoryDetails={state.subcategoryDetails}
      updateTransaction={updateRvTransaction}
      closeModal={() => goToEditDebitCardTransactionCategory(null)}
      debitCardTransactionUuid={state.debitCardTransactionUuid}
      contactUuid={state.contactUuid}
    />
  )
}
