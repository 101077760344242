import { useCallback } from 'react'
import { useField, useFormikContext } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import {
  INVOICE_SCHEDULE_DUE_DATE_FIELD,
  INVOICE_SCHEDULE_PAYMENT_TERMS_FIELD,
} from '../../../../../Invoice.constants'
import { InvoiceFormFields, PaymentTerms } from '../../../../../Invoice.types'
import { isPastDate, toDayjsObject } from '../InvoiceScheduleAccordion.utils'

export const useInvoiceDueDate = () => {
  const {
    values: { schedule },
    setFieldValue,
  } = useFormikContext<InvoiceFormFields>()
  const isMobileResolution = useIsMobileResolution()

  const isPastInvoiceDate = isPastDate(schedule.invoiceDate)

  const [field, meta, helpers] = useField<string>(
    INVOICE_SCHEDULE_DUE_DATE_FIELD
  )

  const handleDateChange = useCallback(
    (value: string) => {
      setFieldValue(INVOICE_SCHEDULE_PAYMENT_TERMS_FIELD, PaymentTerms.Custom)
      helpers.setValue(value)
    },
    [helpers, setFieldValue]
  )

  return {
    disabledDatePickerDays: {
      before: isPastInvoiceDate
        ? new Date()
        : toDayjsObject(schedule.invoiceDate).toDate(),
    },
    fieldError: meta.touched && meta.error ? meta.error : '',
    fieldName: field.name,
    fieldValue: field.value,
    handleBlur: field.onBlur,
    handleDateChange,
    isMobileResolution,
  }
}
