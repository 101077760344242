import { useCallback, useState } from 'react'
import { GetInvoicesDateFilterInput } from '@npco/mp-gql-types'
import { ButtonFill, ButtonGhost, Flex } from '@npco/zeller-design-system'
import { DateFiltersContent } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/DateFilters/DateFiltersContent'

import { translate } from 'utils/translations'
import { useDatePickerState } from 'components/Filters/NewFilters/DatePicker/hooks/useDatePickerState'

import { useInvoicesContext } from '../../../InvoicesContext/InvoicesContext'
import { DateColumnEnum } from '../../InvoiceTable.types'
import { FilterState } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFilters.utils'
import { BottomWrapper } from '../InvoiceTableMobileFiltersDrawer/InvoiceTableMobileFiltersDrawer.styled'

interface DateFilterProps {
  setFilterState: (value: FilterState) => void
}

export const DateFilter = ({ setFilterState }: DateFilterProps) => {
  const {
    filterInput,
    selectedDateColumn,
    selectedDates,
    setDateFilter,
    setSelectedDateColumn,
  } = useInvoicesContext()

  const [nextSelectedDateColumn, setNextSelectedDateColumn] =
    useState(selectedDateColumn)

  const [nextDateFilter, setNextDateFilter] =
    useState<GetInvoicesDateFilterInput | null>(filterInput?.dateFilter ?? null)

  const {
    data,
    enteredTo,
    handleDayClick,
    handleDayMouseEnter,
    handleResetClick,
  } = useDatePickerState({
    initialState: selectedDates,
    isFutureDateDisabled: false,
  })

  const handleApply = useCallback(() => {
    setSelectedDateColumn(nextSelectedDateColumn)
    setDateFilter(nextDateFilter)
    setFilterState(FilterState.FilterAndSort)
  }, [
    nextDateFilter,
    nextSelectedDateColumn,
    setDateFilter,
    setFilterState,
    setSelectedDateColumn,
  ])

  const handleClear = useCallback(() => {
    // NOTE: we must reset the date picker state and then clear the filter state
    setNextSelectedDateColumn(DateColumnEnum.NextDue)
    setNextDateFilter(null)
    handleResetClick()
  }, [handleResetClick, setNextDateFilter])

  return (
    <Flex flexDirection="column" flexGrow={1} data-testid="date-filter">
      <Flex flexDirection="column" flexGrow={1}>
        <DateFiltersContent
          data={data}
          enteredTo={enteredTo}
          handleDayClick={handleDayClick}
          handleDayMouseEnter={handleDayMouseEnter}
          filterInput={filterInput}
          selectedDateColumn={nextSelectedDateColumn}
          setSelectedDateColumn={setNextSelectedDateColumn}
          setDateFilter={setNextDateFilter}
        />
      </Flex>
      <BottomWrapper>
        <ButtonGhost onClick={handleClear} dataTestId="clear-filters-btn">
          {translate('shared.clear')}
        </ButtonGhost>
        <ButtonFill dataTestId="apply-filters-btn" onClick={handleApply}>
          {translate('shared.apply')}
        </ButtonFill>
      </BottomWrapper>
    </Flex>
  )
}
