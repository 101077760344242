import { useCallback, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { SelectSize, SelectStyle } from '@npco/zeller-design-system'
import {
  MultiSelectAdaptive,
  MultiSelectAdaptiveProps,
  MultiSelectHeader,
} from 'design-system/Components/MultiSelect'

import type { EditVenueOption } from './EditVenue'
import { translations } from './EditVenue.i18n'

export type VenueInputProps = {
  value: string[] | undefined
  onValue: (value: string[]) => void
  options: EditVenueOption[]
  getOptionAssignmentLabel: (siteId: string) => string | undefined
}

export const SitesSelect = ({
  value,
  onValue,
  options,
  getOptionAssignmentLabel,
}: VenueInputProps) => {
  const t = useTranslations(translations)

  const optionBySiteId = useMemo(
    () => Object.fromEntries(options.map((option) => [option.value, option])),
    [options]
  )

  const selectedItems = useMemo<
    MultiSelectAdaptiveProps.Deprecated<EditVenueOption>['selectedItems']
  >(
    () =>
      (value ?? []).reduce((acc, siteId) => {
        const option = optionBySiteId[siteId]
        if (option) acc.push(option)
        return acc
      }, [] as EditVenueOption[]),
    [value, optionBySiteId]
  )

  const label: MultiSelectAdaptiveProps.Deprecated<EditVenueOption>['label'] =
    selectedItems?.length ? t('siteFieldLabel') : t('siteFieldLabelEmpty')

  const items = useMemo<
    MultiSelectAdaptiveProps.Deprecated<EditVenueOption>['items']
  >(
    () =>
      [...options].sort((a, b) => {
        return (
          (selectedItems?.includes(a) ? 0 : 1) -
          (selectedItems?.includes(b) ? 0 : 1)
        )
      }),
    [options, selectedItems]
  )

  const onChange = useCallback<
    MultiSelectAdaptiveProps.Deprecated<EditVenueOption>['onChange']
  >(
    (nextSelectedOptions) =>
      onValue(nextSelectedOptions.map(({ value: siteId }) => siteId)),
    [onValue]
  )

  const modifyItemProps = useCallback<
    NonNullable<
      MultiSelectAdaptiveProps.Deprecated<EditVenueOption>['modifyItemProps']
    >
  >(
    (props) => {
      const siteId = props.item.value
      const assignmentLabel = getOptionAssignmentLabel(siteId)
      const isAssignedToAnotherVenue = !!assignmentLabel
      if (isAssignedToAnotherVenue) {
        return {
          ...props,
          sublabel: assignmentLabel,
          isDisabled: true,
        }
      }
      return props
    },
    [getOptionAssignmentLabel]
  )

  const triggerContent = useMemo<
    MultiSelectAdaptiveProps.Deprecated<EditVenueOption>['triggerContent']
  >(() => {
    if (!selectedItems?.length) {
      return null
    }
    if (selectedItems.length === 1) {
      const option = selectedItems[0]
      if (option) {
        return option.label
      }
    }
    return t('nSites', { count: `${selectedItems.length}` })
  }, [selectedItems, t])

  return (
    <MultiSelectAdaptive<EditVenueOption>
      label={label}
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      modifyItemProps={modifyItemProps}
      triggerContent={triggerContent}
      selectSize={SelectSize.Medium}
      selectStyle={SelectStyle.Standard}
      renderTopSection={(renderProps) => (
        <MultiSelectHeader {...renderProps}>
          {t('siteOptionsHeaderLabel')}
        </MultiSelectHeader>
      )}
    />
  )
}
