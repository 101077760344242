import { Flex } from '@npco/zeller-design-system'

import { ImageCropper } from 'components/ModalUploadImage/ImageCropper/ImageCropper'

import { ModalUploadCropImageProps } from './ModalUploadCropImage.types'

export const ModalUploadCropImage = ({
  onCrop,
  onCropFile,
  imageUrl,
  fileType,
}: ModalUploadCropImageProps) => {
  return (
    <Flex>
      <ImageCropper
        onCrop={onCrop}
        onCropFile={onCropFile}
        uploadedImage={imageUrl}
        fileType={fileType}
      />
    </Flex>
  )
}
