import { prominent } from 'color.js'

import { GetScreensaverLogoUploadDetailsQueryResponse } from '../graphql/getScreensaverLogoUploadDetails.generated'
import { LogosUploadInfo } from '../SiteAppearanceForm/SiteAppearanceForm.types'

type PrepareUploadInfoProp = {
  screensaverLogoUploadDetails: GetScreensaverLogoUploadDetailsQueryResponse['getScreensaverLogoUploadDetails']
  previewUrl: string
}
export const getLogoUploadInfo = async ({
  screensaverLogoUploadDetails,
  previewUrl,
}: PrepareUploadInfoProp) => {
  const form = {
    url: screensaverLogoUploadDetails.presignedDetails.url,
    fields: JSON.parse(
      screensaverLogoUploadDetails.presignedDetails.jsonFields
    ) as Record<string, string>,
  }
  const formData = new FormData()
  Object.keys(form.fields).forEach((prop) =>
    formData.append(prop, form.fields[prop])
  )
  formData.append('Content-Type', 'image/png')
  const response = await fetch(previewUrl, { method: 'GET' })
  const blob = await response.blob()
  formData.append('file', blob)
  const logoUploadInfo: LogosUploadInfo = {
    logoUuid: screensaverLogoUploadDetails.logoUuid,
    formData,
    url: form.url,
  }

  return logoUploadInfo
}

export const getMostUsedColour = async (imageUrl: string) => {
  const colour = await prominent(imageUrl, {
    amount: 3,
    format: 'hex',
    group: 1,
  })
  if (typeof colour === 'string') {
    return [colour.toUpperCase()]
  }
  return (colour as string[]).map((c) => c.toLocaleUpperCase())
}
