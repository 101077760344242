import { BodyDefault, BodyDefaultTypography } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CheckboxContainer = styled.label`
  display: flex;
`
export const CheckboxMessage = styled(BodyDefault)`
  flex-grow: 1;
  margin: 0 0 0 12px;
  cursor: pointer;
`

export const Description = styled.div`
  ${BodyDefaultTypography}
`
