import {
  SelectSize,
  SelectStandard,
  SelectTriggerPill,
} from '@npco/zeller-design-system'

import { FileListSelectWrapper } from './FileListSelect.styled'
import { useFileListSelect } from './hooks/useFileListSelect'

interface FileListSelectProps {
  id: string
  isDisabled?: boolean
}
export const FileListSelect = ({ id, isDisabled }: FileListSelectProps) => {
  const { hasError, handleSelect, optionItems, selectedItem, selectLabel } =
    useFileListSelect({
      id,
    })

  if (!optionItems) {
    return null
  }

  return (
    <FileListSelectWrapper minWidth="200px">
      <SelectStandard
        shouldAutoFocusControl
        items={optionItems}
        onChange={handleSelect}
        maxHeight="304px"
        selectedItem={selectedItem}
        size={SelectSize.Small}
        popperWidth="320px"
        isDisabled={isDisabled}
        hasSelectedIndicator
        hasError={hasError}
        renderTrigger={(renderTriggerProps) => (
          <SelectTriggerPill hasChevron {...renderTriggerProps}>
            {selectLabel}
          </SelectTriggerPill>
        )}
      />
    </FileListSelectWrapper>
  )
}
