import { useState } from 'react'
import { COLOR, SvgIcon, TileSelector } from '@npco/zeller-design-system'

import { ReactComponent as Decline } from 'assets/svg/decline.svg'
import { ReactComponent as Plus } from 'assets/svg/plus.svg'
import { ImagePreview } from 'components/ModalUploadImage/ImagePreview/ImagePreview'

import { ColourPickerModal } from '../ColourPickerModal/ColourPickerModal'
import { LogoUploadModal } from '../LogoUploadModal/LogoUploadModal'
import { LOADING_MARKER } from '../SettingsCustomisation.constants'
import * as styled from './TileSelectorList.styled'

const NO_VALUE = 'NO_VALUE'
const ADD_VALUE = 'ADD_VALUE'
const MAX_VALUES = 5

type TileSelectorListProps = {
  initialValue?: string
  values?: string[]
  onSelectValue: (value: string) => void
  onAddValue: (values: string[]) => void
  onRemoveValue: (values: string[]) => void
  variant: 'colour' | 'logo'
}

export const TileSelectorList = ({
  initialValue = '',
  values: initialValues,
  onSelectValue,
  onAddValue,
  onRemoveValue,
  variant,
}: TileSelectorListProps) => {
  const [brokenSrcValues, setBrokenSrcValues] = useState<string[]>([])
  const [isModalOpen, setModalOpen] = useState(false)

  const handleSelectValue = (value: string) => {
    onSelectValue(value === NO_VALUE ? '' : value)
  }

  const handleRemoveValue = (value: string) => {
    if (initialValue === value) {
      handleSelectValue(NO_VALUE)
    }
    const newValues = initialValues?.filter((c) => c !== value)

    onRemoveValue(newValues || [])
  }

  const handleAddValue = (value: string) => {
    if (initialValues?.includes(value)) {
      handleSelectValue(value)
      setModalOpen(false)
      return
    }

    const newValues = [...(initialValues || []), value]
    onAddValue(newValues)
    if (variant === 'colour') {
      handleSelectValue(value)
    }
  }

  const handleNewValues = (newValues: string[]) => {
    onAddValue(newValues)
  }

  const hasLoadingValue = initialValues?.some(
    (value) =>
      value.includes(LOADING_MARKER) && !brokenSrcValues.includes(value)
  )

  return (
    <>
      <styled.FlexWrapper>
        <TileSelector
          value={NO_VALUE}
          name={`${NO_VALUE}-${variant}`}
          selected={!initialValue || initialValue === NO_VALUE}
          content={
            <styled.NoValue>
              <SvgIcon width="16" height="16" color={COLOR.BLUE_1000}>
                <Decline />
              </SvgIcon>
            </styled.NoValue>
          }
          onChange={handleSelectValue}
        />
        {initialValues?.map((value) => {
          const name = `${value}-${variant}`
          const isLoading = value.includes(LOADING_MARKER)
          const isBroken = brokenSrcValues.includes(value)
          const valueToUse = value.replace(LOADING_MARKER, '')

          return (
            <TileSelector
              key={name}
              name={name}
              value={valueToUse}
              loading={isLoading && !isBroken}
              selected={valueToUse === initialValue}
              content={
                variant === 'colour' ? (
                  <styled.ColourSquare color={valueToUse} />
                ) : (
                  <ImagePreview
                    tabIndex={-1}
                    src={valueToUse}
                    isBroken={isBroken}
                    onError={() => {
                      setBrokenSrcValues((prev) => [...prev, value])
                    }}
                  />
                )
              }
              onChange={handleSelectValue}
              clearable={!hasLoadingValue}
              onClear={() => handleRemoveValue(value)}
            />
          )
        })}
        {initialValues && initialValues.length >= MAX_VALUES ? null : (
          <TileSelector
            name={`${ADD_VALUE}-${variant}`}
            content={
              <styled.AddValue>
                <SvgIcon width="16" height="16">
                  <Plus />
                </SvgIcon>
              </styled.AddValue>
            }
            onChange={() => setModalOpen(true)}
            value={undefined}
          />
        )}
      </styled.FlexWrapper>
      {variant === 'colour' ? (
        <ColourPickerModal
          initialColour={initialValue === NO_VALUE ? undefined : initialValue}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSaveColour={handleAddValue}
        />
      ) : (
        <LogoUploadModal
          logos={initialValues}
          onSaveLogo={handleNewValues}
          onNewUpload={(logo) => handleAddValue(`${LOADING_MARKER}${logo}`)}
          onCancelUpload={(logo) =>
            handleRemoveValue(`${LOADING_MARKER}${logo}`)
          }
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  )
}
