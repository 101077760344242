import { MFAProvider } from 'features/MFA'

import { Auth0ProviderWrapper } from './Auth0ProviderWrapper'
import { AuthProvider } from './AuthContext'
import { ZellerAuthenticationProvider } from './ZellerAuthenticationProvider'

export const ZellerAuthentication = ({ children }: any) => {
  return (
    <MFAProvider>
      <AuthProvider>
        <Auth0ProviderWrapper>
          <ZellerAuthenticationProvider>
            {children}
          </ZellerAuthenticationProvider>
        </Auth0ProviderWrapper>
      </AuthProvider>
    </MFAProvider>
  )
}
