import {
  COLOR,
  Flex,
  SvgIcon,
  TOOLTIP_ZINDEX,
  TooltipBasic,
  TooltipSize,
} from '@npco/zeller-design-system'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'

import { StyledLabelText } from './TooltipLabel.styled'

interface TooltipLabelProps {
  label: string
  tooltipContent: React.ReactNode
  isDisabled?: boolean
}

export const TooltipLabel = ({
  label,
  tooltipContent,
  isDisabled,
}: TooltipLabelProps) => {
  return (
    <Flex alignItems="center">
      <StyledLabelText isDisabled={isDisabled}>{label}</StyledLabelText>
      <Flex alignItems="center" ml="4px">
        <TooltipBasic
          placement="top"
          renderTrigger={({ handlers, ref }) => (
            <span ref={ref} {...handlers}>
              <SvgIcon color={COLOR.GREY_250} width="16" height="16">
                <InfoIcon data-testid="info-icon" />
              </SvgIcon>
            </span>
          )}
          showArrow={false}
          zIndex={Number.parseInt(TOOLTIP_ZINDEX, 10)}
          size={TooltipSize.Large}
        >
          {tooltipContent}
        </TooltipBasic>
      </Flex>
    </Flex>
  )
}
