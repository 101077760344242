import { useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { Subset } from 'types/utils'
import { GetReportsLocalState } from 'pages/Reports/ReportsNew/getReportsLocalState'

import { CacheLocalStateEntityScopedVariable } from '../../../graphql/cache.types'
import { ReportsDefaultLocalState } from './ReportsNew.constants'
import { CacheLocalStateReports, LocalStateReports } from './ReportsNew.types'

export const useReportsLocalCache = () => {
  const { cache } = useApolloClient()
  const entityUuid = useSelectedEntityUuid()

  const updateCache = (next: Subset<CacheLocalStateReports>) =>
    cache.updateQuery<
      Subset<CacheLocalStateReports>,
      CacheLocalStateEntityScopedVariable
    >(
      {
        query: GetReportsLocalState,
        variables: { entityUuid },
      },
      (previous) => ({
        local: {
          reports: {
            ...previous?.local?.reports,
            ...next.local?.reports,
            filters: {
              ...previous?.local?.reports?.filters,
              ...next.local?.reports?.filters,
              dates: {
                ...previous?.local?.reports?.filters?.dates,
                ...next.local?.reports?.filters?.dates,
              },
            },
          },
        },
      })
    )

  const resetFilters = () => {
    updateCache({
      local: {
        reports: ReportsDefaultLocalState,
      },
    })
  }

  const setSelectedDates = (
    dates: Partial<LocalStateReports['filters']['dates']> | undefined
  ) => {
    updateCache({
      local: {
        reports: {
          filters: {
            dates: dates ?? ReportsDefaultLocalState.filters.dates,
          },
        },
      },
    })
  }

  const setSelectedStatuses = (
    statuses: LocalStateReports['filters']['statuses'] | undefined
  ) => {
    updateCache({
      local: {
        reports: {
          filters: {
            statuses: statuses ?? ReportsDefaultLocalState.filters.statuses,
          },
        },
      },
    })
  }

  const setSelectedTerminals = (
    terminals: LocalStateReports['filters']['terminals'] | undefined
  ) => {
    updateCache({
      local: {
        reports: {
          filters: {
            terminals: terminals ?? ReportsDefaultLocalState.filters.terminals,
          },
        },
      },
    })
  }

  const setSelectedTypes = (
    types: LocalStateReports['filters']['types'] | undefined
  ) => {
    updateCache({
      local: {
        reports: {
          filters: {
            types: types ?? ReportsDefaultLocalState.filters.types,
          },
        },
      },
    })
  }

  const setSelectedSources = (
    sources: LocalStateReports['filters']['sources'] | undefined
  ) => {
    updateCache({
      local: {
        reports: {
          filters: {
            sources: sources ?? ReportsDefaultLocalState.filters.sources,
          },
        },
      },
    })
  }

  const setIsFiltersVisible = (isFiltersVisible: boolean | undefined) => {
    updateCache({
      local: {
        reports: {
          isFiltersVisible:
            isFiltersVisible ?? ReportsDefaultLocalState.isFiltersVisible,
        },
      },
    })
  }

  return {
    resetFilters,
    setIsFiltersVisible,
    setSelectedDates,
    setSelectedSources,
    setSelectedStatuses,
    setSelectedTerminals,
    setSelectedTypes,
  }
}
