import { BodyDefault, ChipBasic, Flex, H3 } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledSummaryItemContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin: 0 auto 0 0;
  }
`

export const StyledSummaryTitle = styled(BodyDefault)`
  margin: 0;
  margin-bottom: 0.5rem;
`

export const StyledSummaryValue = styled(H3)`
  margin: 0 1rem 0 0;
  animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
`

export const StyledSummaries = styled(Flex)`
  width: 100%;
  animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
`

export const StyledChip = styled(ChipBasic)`
  display: none;

  @media screen and (min-width: ${BREAKPOINT.LG}px) {
    display: block;
  }
`

export const StyledLoadingWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
