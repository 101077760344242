import { Flex } from '@npco/zeller-design-system'
import { rgba } from 'polished'
import styled from 'styled-components'

export const StyledLetterBadge = styled(Flex)<{
  isLarge?: boolean
  bgColor: string
  color: string
  withCursorPointer?: boolean
}>`
  background-color: ${({ bgColor, theme }) =>
    rgba(bgColor, bgColor === theme.colors.BLUE_80 ? 1 : 0.4)};
  border-radius: 4px;

  cursor: ${({ withCursorPointer }) =>
    withCursorPointer ? 'pointer' : 'default'};
  height: ${({ isLarge }) => (isLarge ? '40px' : '24px')};
  margin-right: ${({ isLarge }) => (isLarge ? '16px' : '12px')};
  width: ${({ isLarge }) => (isLarge ? '40px' : '24px')};
`
