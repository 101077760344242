import { TableData } from '@npco/ui-table'

import {
  AverageHeaderCell,
  ContactHeaderCell,
  QuantityHeaderCell,
  TotalHeaderCell,
} from '../../TableCells/TableHeaderCells'
import {
  AverageCell,
  ContactCell,
  QuantityCell,
  TotalCell,
} from '../../TableCells/TableRowCells'
import { ContactsTableCashFlowContactNetAmountFragment } from './ContactsTable.generated'

export const columnsConfig = [
  {
    accessorId: 'contactCell',
    cellComponent: ContactCell<
      TableData<ContactsTableCashFlowContactNetAmountFragment>
    >,
    headerComponent: ContactHeaderCell,
    cellSize: { _: 14.5, sm: 10 },
    cellSkeletonWidth: [
      { _: 114, SM: 124 },
      { _: 106, SM: 132 },
      { _: 82, SM: 84 },
    ],
  },
  {
    accessorId: 'quantityCell',
    cellComponent: QuantityCell<
      TableData<ContactsTableCashFlowContactNetAmountFragment>
    >,
    headerComponent: QuantityHeaderCell,
    cellSize: { _: 3, sm: 3.5 },
    cellSkeletonWidth: [
      { _: 19, SM: 27 },
      { _: 19, SM: 27 },
      { _: 19, SM: 27 },
    ],
  },
  {
    accessorId: 'averageCell',
    cellComponent: AverageCell<
      TableData<ContactsTableCashFlowContactNetAmountFragment>
    >,
    headerComponent: AverageHeaderCell,
    cellSize: { _: 0, sm: 4.5 },
    cellSkeletonWidth: [{ SM: 54 }, { SM: 46 }, { SM: 86 }],
  },
  {
    accessorId: 'totalCell',
    cellComponent: TotalCell<
      TableData<ContactsTableCashFlowContactNetAmountFragment>
    >,
    headerComponent: TotalHeaderCell,
    cellSize: { _: 6, sm: 6 },
    cellSkeletonWidth: [
      { _: 60, SM: 89 },
      { _: 52, SM: 65 },
      { _: 36, SM: 41 },
    ],
  },
]
