import { useState } from 'react'

export const useExportTransactionsModal = () => {
  const [hasTimePassed, setHasTimePassed] = useState(false)
  const [renderContent, setRenderContent] = useState(true)

  // Hack to close subscription and resubscribe by remounting component
  const rerenderContent = () => {
    setRenderContent(false)
    setHasTimePassed(false)
    setTimeout(() => {
      setRenderContent(true)
    }, 0)
  }

  return {
    hasTimePassed,
    setHasTimePassed,
    renderContent,
    setRenderContent,
    rerenderContent,
  }
}
