import { Flex, Spinner } from '@npco/zeller-design-system'

import { StyledLoaderText } from './LoaderWithText.styled'

interface Props {
  loaderText: React.ReactNode
}

export const LoaderWithText = ({ loaderText }: Props) => {
  return (
    <Flex
      data-testid="loader-with-text"
      flexDirection="column"
      alignItems="center"
    >
      <Spinner dataTestId="loader" />
      {loaderText && <StyledLoaderText>{loaderText}</StyledLoaderText>}
    </Flex>
  )
}
