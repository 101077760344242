import { useTranslations } from '@npco/utils-translations'

import { BulletPointList } from '../../../../components/BulletPointList/BulletPointList'
import {
  ScrollableContent,
  Subtitle,
  Title,
} from '../../../SetupFlowModal.styled'
import { setupFlowInvoicesStageTranslations } from '../FeatureInvoicesStage.i18n'

export const MainContent = () => {
  const t = useTranslations(setupFlowInvoicesStageTranslations)

  return (
    <ScrollableContent data-testid="setup-flow-feature-invoice-stage">
      <Title>{t('title')}</Title>
      <Subtitle>{t('description')}</Subtitle>
      <BulletPointList
        points={[t('point1'), t('point2'), t('point3'), t('point4')]}
      />
    </ScrollableContent>
  )
}
