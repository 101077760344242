import * as Types from '@npco/mp-gql-types'
import { DrawerNavigationProvider } from '@npco/ui-drawer-navigation'
import { Box } from '@npco/zeller-design-system'

import { useGetCategoryName } from '../TransactionsSummary/hooks/useGetCategoryName'
import * as styled from './CategoryDrawer.styled'
import { CategoryDrawerContent } from './CategoryDrawerContent/CategoryDrawerContent'
import { CategoryDrawerHeader } from './CategoryDrawerHeader/CategoryDrawerHeader'
import { UncategorisedMessage } from './UncategorisedMessage/UncategorisedMessage'

const TRANSACTION_TYPE = 'Expenses'

export interface CategoryDrawerProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  timeZone: string | undefined
  selectedCategory?: Types.EntityCategories | null
  selectNextCategory?: () => void
  selectPreviousCategory?: () => void
  reportType: Types.CashFlowReportType
}

export const CategoryDrawer = ({
  isOpen,
  setIsOpen,
  selectedCategory,
  timeZone,
  selectNextCategory,
  selectPreviousCategory,
  reportType,
}: CategoryDrawerProps) => {
  const getCategoryName = useGetCategoryName()

  if (!isOpen || selectedCategory === undefined) {
    return null
  }

  return (
    <DrawerNavigationProvider
      onNext={selectNextCategory ?? undefined}
      onPrevious={selectPreviousCategory ?? undefined}
    >
      <styled.StyledDrawerWithNavigation
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        title={getCategoryName(selectedCategory)}
        headerContent={
          <CategoryDrawerHeader incomeOrExpense={TRANSACTION_TYPE} />
        }
        ariaHideApp={false}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {!selectedCategory ? (
            <UncategorisedMessage />
          ) : (
            <CategoryDrawerContent
              selectedCategory={selectedCategory}
              timeZone={timeZone}
              reportType={reportType}
            />
          )}
        </Box>
      </styled.StyledDrawerWithNavigation>
    </DrawerNavigationProvider>
  )
}
