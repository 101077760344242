import { useContext, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { DrawerNavigationContext } from '@npco/ui-drawer-navigation'

import { conditionalClassName } from 'utils/conditionalClassName'

import { StyledDrawerContentWrapper } from './SwitchAnimationContainer.styled'

interface Props {
  children: React.ReactNode
  animationKey: string
}

export const SwitchAnimationContainer = ({ children, animationKey }: Props) => {
  const [numberOfElementsExiting, setNumberOfElementsExiting] = useState(0)
  const { changeMode } = useContext(DrawerNavigationContext)

  return (
    <StyledDrawerContentWrapper
      className={conditionalClassName({
        'to-left': changeMode === 'previous',
        'to-right': changeMode === 'next',
        hideExit: numberOfElementsExiting > 1,
      })}
    >
      <TransitionGroup component={null}>
        <CSSTransition
          onEnter={() => setNumberOfElementsExiting((v) => v + 1)}
          onExited={() => setNumberOfElementsExiting((v) => v - 1)}
          key={animationKey}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false)
          }}
        >
          {children}
        </CSSTransition>
      </TransitionGroup>
    </StyledDrawerContentWrapper>
  )
}
