import React, { ReactNode } from 'react'

const checkValidReactElement = (maybeReactNode: ReactNode) => {
  if (typeof maybeReactNode === 'string') {
    return true
  }

  if (React.isValidElement(maybeReactNode)) {
    return true
  }

  if (maybeReactNode === undefined) {
    return true
  }

  if (typeof maybeReactNode === 'boolean') {
    return true
  }

  if (typeof maybeReactNode === 'number') {
    return true
  }

  if (maybeReactNode === null) {
    return true
  }

  return false
}

export const validateReactNode = (maybeReactNode: ReactNode) => {
  if (Array.isArray(maybeReactNode)) {
    return maybeReactNode.every(checkValidReactElement)
  }
  return checkValidReactElement(maybeReactNode)
}
