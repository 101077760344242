import { createContext } from 'react'

import { DateRange } from './SelectedDateRange.type'

type SelectedDateRangeContextType = {
  selectedDateRange: DateRange
  setSelectedDateRange: (dateRange: DateRange) => void
  resetDateRange: () => void
}

export const SelectedDateRangeContext =
  createContext<SelectedDateRangeContextType | null>(null)
