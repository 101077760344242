import { DocumentUploadedType } from '@npco/mp-gql-types'

import { OtherDocumentsMap } from 'const/documents'

export const getOtherDocumentValue = (value?: string) => {
  if (!value || value in OtherDocumentsMap) {
    return DocumentUploadedType.OTHER_DOCUMENT
  }
  return value
}
