import { useMemo } from 'react'
import { useLocationState } from '@npco/utils-routing'
import { z } from 'zod'

const UsersListLocationStateSchema = z.object({
  UsersList: z.object({
    selectedUserId: z.string().nullable(),
  }),
})

export const useUsersListState = () => {
  const locationState = useLocationState(UsersListLocationStateSchema)

  const state = useMemo(() => {
    if (!locationState?.UsersList) {
      return null
    }

    return { selectedUserId: locationState.UsersList.selectedUserId || null }
  }, [locationState?.UsersList])

  return { state }
}
