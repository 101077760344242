import { SourceFilter } from '@npco/mp-gql-types'
import { COLOR, PosIcon } from '@npco/zeller-design-system'

import { ReactComponent as DashboardIcon } from 'assets/svg/payment-source/ecommerce.svg'
import { ReactComponent as InvoiceIcon } from 'assets/svg/payment-source/invoice.svg'
import { ReactComponent as MobileIcon } from 'assets/svg/payment-source/mobile.svg'
import { ReactComponent as TerminalIcon } from 'assets/svg/payment-source/terminal.svg'
import { translate } from 'utils/translations'

export const paymentSourceIconsAndTranslations = {
  [SourceFilter.POINT_OF_SALE]: {
    icon: <PosIcon color={COLOR.BLACK_900} />,
    translation: translate('page.transactions.tooltip.pos'),
  },
  [SourceFilter.TERMINAL]: {
    icon: <TerminalIcon />,
    translation: translate('page.transactions.tooltip.terminal'),
  },
  [SourceFilter.DASHBOARD]: {
    icon: <DashboardIcon />,
    translation: translate('page.transactions.tooltip.dashboard'),
  },
  [SourceFilter.INVOICE]: {
    icon: <InvoiceIcon />,
    translation: translate('page.transactions.tooltip.invoice'),
  },
  [SourceFilter.ZELLER_APP]: {
    icon: <MobileIcon />,
    translation: translate('page.transactions.tooltip.mobile'),
  },
  [SourceFilter.ZELLER_ADMIN]: {
    icon: <DashboardIcon />,
    translation: translate('page.transactions.tooltip.zellerAdmin'),
  },
}
