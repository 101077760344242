import { useTranslations } from '@npco/utils-translations'

import { TransactionContactLoading } from 'components/TransactionContactLoading'

import { unlinkingContactSpinnerTranslations } from './UnlinkingContactSpinner.i18n'

export const UnlinkingContactSpinner = () => {
  const t = useTranslations(unlinkingContactSpinnerTranslations)

  return (
    <TransactionContactLoading
      title={t('unlinkLoadingContactTitle')}
      description={t('unlinkLoadingContactDescription')}
    />
  )
}
