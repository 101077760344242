import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { StyledForm, StyledModalForm } from '../EditNotesModal.styled'
import { NotesInput } from './NotesInput'

interface Props {
  title: string
  isLoading?: boolean
  isOpen: boolean
  placeholder: string
  limit: number
  onCancel: () => void
}

export const NotesModalForm = ({
  title,
  isLoading,
  isOpen,
  placeholder,
  limit,
  onCancel,
}: Props) => {
  const { submitForm } = useFormikContext()

  return (
    <StyledModalForm
      title={title}
      isLoading={isLoading}
      isOpen={isOpen}
      onSave={submitForm}
      confirmLabel={translate('shared.save')}
      cancelLabel={translate('shared.cancel')}
      onCancel={onCancel}
    >
      <StyledForm>
        <NotesInput placeholder={placeholder} limit={limit} title={title} />
      </StyledForm>
    </StyledModalForm>
  )
}
