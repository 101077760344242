import styled from 'styled-components/macro'

const curve = 'cubic-bezier(0.57, 0.53, 0.51, 0.92)'

interface ContentTypes {
  isActive: boolean
  height: number
  withOverflowHidden: boolean
}

export const StyledContentCollapserWrapper = styled.div.attrs(
  ({ isActive }: ContentTypes) => ({
    className: isActive && 'active',
  })
)<ContentTypes>`
  position: relative;
  transition: height 0.3s ${curve}, opacity 0.2s ${curve},
    transform 0.3s ${curve}, visibility 0.2s ${curve};
  opacity: 0;
  height: 0;
  transform-origin: top;
  z-index: 10;
  transform: scaleY(0);
  visibility: hidden;

  overflow: ${({ withOverflowHidden }) =>
    withOverflowHidden ? 'hidden' : 'initial'};

  &.active {
    height: ${({ height }) => height}px;
    opacity: 1;
    overflow: unset;
    transform: scaleY(1);
    transition: height 0.3s ${curve}, opacity 0.4s ${curve} 0.2s,
      transform 0.3s ${curve}, visibility 0.4s ${curve} 0.2s;
    visibility: visible;
  }
`
