import {
  AccordionComplex,
  Box,
  InputAdaptiveField,
  InputAdaptiveTextArea,
} from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { Field, useFormikContext } from 'formik'

import { translate } from 'utils/translations'

import { InvoiceAccordionCommonProps } from '../../InvoiceFormAccordions.types'
import { InvoiceAccordionContentWrapper } from '../InvoiceAccordionContentWrapper/InvoiceAccordionContentWrapper'
import { useInvoiceTitleSection } from '../InvoiceDeliveryAccordion/hooks/useInvoiceTitleSection'
import {
  getAccordionDetailsText,
  getAccordionFormState,
} from './InvoiceTitleAccordion.utils'

export const translations = {
  titleFormTitle: translate('page.invoice.formSections.title.title'),
  titleFormDetails: translate('page.invoice.formSections.title.details'),
  titleFormHeaderLabel: translate(
    'page.invoice.formSections.title.headerLabel'
  ),
  titleFormMessageLabel: translate(
    'page.invoice.formSections.title.messageLabel'
  ),
  titleFormMessageHelperText: translate(
    'page.invoice.formSections.title.messageHelperText'
  ),
}

export const InvoiceTitleAccordion = ({
  isExpanded,
  onChange,
}: InvoiceAccordionCommonProps) => {
  useInvoiceTitleSection({ isExpanded })
  const { errors, touched, values } = useFormikContext<InvoiceFormFields>()

  const accordionFormState = getAccordionFormState({ errors, touched, values })
  const accordionDetailsText = getAccordionDetailsText({
    touched,
    values,
  })

  return (
    <AccordionComplex
      dataTestId="invoice-title"
      details={accordionDetailsText}
      formItemState={accordionFormState}
      isExpanded={isExpanded}
      onChange={onChange}
      title={translations.titleFormTitle}
    >
      <InvoiceAccordionContentWrapper>
        <Box>
          <InputAdaptiveField
            name="title.header"
            label={translations.titleFormHeaderLabel}
          />
        </Box>
        <Box>
          <Field
            component={InputAdaptiveTextArea}
            helperText={translations.titleFormMessageHelperText}
            id="title.message"
            label={translations.titleFormMessageLabel}
            marginBottom="0"
            maxLength={1000}
            name="title.message"
            rows={7}
          />
        </Box>
      </InvoiceAccordionContentWrapper>
    </AccordionComplex>
  )
}
