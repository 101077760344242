import { slideSideAnimationVariants } from '@npco/zeller-design-system'

import { TransferStateCondition } from 'pages/Transfer/states/TransferState/TransferStateCondition'

import {
  TransferBpayState,
  TransferGeneralState,
} from '../Transfer.stateMachine'
import { BpayTransferFields } from './fields/BpayTransferFields/BpayTransferFields'

type BpayTransferStatesProps = {
  contactValidationError: string | undefined
  setContactValidationError: (newError: string | undefined) => void
}

export const BpayTransferStates = ({
  contactValidationError,
  setContactValidationError,
}: BpayTransferStatesProps) => (
  <TransferStateCondition
    parent={TransferGeneralState.BpayTransfer}
    allowedStates={[TransferBpayState.TransferDetails]}
    animationVariants={slideSideAnimationVariants}
  >
    <BpayTransferFields
      contactValidationError={contactValidationError}
      setContactValidationError={setContactValidationError}
    />
  </TransferStateCondition>
)
