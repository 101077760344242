import { FocusEventHandler, useCallback, useMemo, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  INPUT_SIZE,
  InputSelectComboboxStandard,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'

import { NoBillersFoundInfo } from 'pages/Transfer/BPay/BPayTargetInput/components/NoBillersFoundInfo/NoBillersFoundInfo'
import { SearchStringLengthRequirementInfo } from 'pages/Transfer/BPay/BPayTargetInput/components/SearchStringLengthRequirementInfo/SearchStringLengthRequirementInfo'
import { ComboboxItem } from 'pages/Transfer/BPay/BPayTargetInput/useComboboxProps/ComboboxItem'

import { BILLER_OR_CONTACT_ROW_ITEM_HEIGHT } from '../../../BpayTargetInput.utils'
import { billerCodeSearchInputTranslations } from './BillerCodeSearchInput.i18n'
import {
  type BillerSelectOption,
  getSelectOptionItems,
} from './BillerCodeSearchInput.utils'
import { useBillerCodeSearch } from './hooks/useBillerCodeSearch'

interface BillerCodeSearchInputProps {
  onChange: (change: any) => void
  onBlur: FocusEventHandler<HTMLInputElement>
  onClose: () => void
  hasError: boolean
  selectedItem: BillerSelectOption
}

export const BillerCodeSearchInput = ({
  onChange,
  onBlur,
  hasError,
  selectedItem,
  onClose,
}: BillerCodeSearchInputProps) => {
  const t = useTranslations(billerCodeSearchInputTranslations)
  const [searchString, setSearchString] = useState('')
  const { billerData, isLoading } = useBillerCodeSearch(searchString)

  const onInputChange = useCallback(
    (change: SelectStateInputChangeProps<BillerSelectOption>) => {
      const value = change?.inputValue
      setSearchString(value)
    },
    [setSearchString]
  )

  const onInputClear = useCallback(() => {
    setSearchString('')
  }, [setSearchString])

  const items = useMemo(() => {
    return getSelectOptionItems(billerData, searchString)
  }, [billerData, searchString])

  const hasItems = items?.length > 0
  const startSearch = searchString.length >= 4

  const renderHeader = useMemo(() => {
    if (!startSearch) {
      return <SearchStringLengthRequirementInfo />
    }

    if (startSearch && !isLoading && !hasItems) {
      return <NoBillersFoundInfo />
    }

    return null
  }, [startSearch, isLoading, hasItems])

  return (
    <InputSelectComboboxStandard
      placeholder={t('label')}
      label={t('label')}
      items={items}
      isLoading={isLoading}
      selectedItem={selectedItem}
      onChange={(change) => onChange(change)}
      onClose={onClose}
      onBlur={onBlur}
      inputValue={searchString}
      onInputChange={onInputChange}
      onInputClear={onInputClear}
      inputSize={INPUT_SIZE.MEDIUM}
      hasError={hasError}
      renderItem={ComboboxItem}
      rowItemHeight={BILLER_OR_CONTACT_ROW_ITEM_HEIGHT}
      renderHeader={() => renderHeader}
      showPopper={!isLoading}
    />
  )
}
