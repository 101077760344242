import { DATE_FULL_DISPLAY_FORMAT } from '@npco/component-mp-common'
import {
  ContactType,
  InvoiceDiscountConfig,
  InvoiceItemUnit,
  InvoiceStatus,
} from '@npco/mp-gql-types'
import currency from 'currency.js'
import { prop, sortBy } from 'ramda'

import dayjs from 'utils/dayjs'
import { convertNumberToLocaleString } from 'utils/localeString'
import { CreateInvoice_createInvoice as CreateInvoiceResponse } from 'types/gql-types/CreateInvoice'
import {
  GetInvoice_getInvoice as GetInvoiceResponse,
  GetInvoice_getInvoice_customer_attentionContact as CustomerContact,
} from 'types/gql-types/GetInvoice'
import { SaveAndSendInvoice_saveAndSendInvoice as SaveAndSendInvoiceResponse } from 'types/gql-types/SaveAndSendInvoice'

import { GetZellerInvoiceSettingsQueryResponse } from '../../../../Settings/graphql/getZellerInvoiceSettings.generated'
import {
  InvoiceFormFields,
  InvoiceItem,
  PaymentTerms,
} from '../../Invoice.types'
import {
  defaultInvoiceLineItem,
  initialValues,
} from '../InvoiceCreateForm/InvoiceCreateForm.utils'

export const toDateDisplayFormat = (date: string, fallbackDate = '') =>
  date ? dayjs(date).format(DATE_FULL_DISPLAY_FORMAT) : fallbackDate

const sortByOrderIndex = sortBy(prop('orderIndex'))

const isSchedulingAllowed = (status: InvoiceStatus): boolean =>
  status === InvoiceStatus.DRAFT || status === InvoiceStatus.SCHEDULED

export const convertGetInvoiceItemsToItemsFormFields = (
  invoiceItems: GetInvoiceResponse['items']
): InvoiceItem[] => {
  if (!invoiceItems) {
    return initialValues.items
  }

  return sortByOrderIndex(invoiceItems).map((item, index) => {
    const catalogItemUuid =
      item.catalogItem?.id ?? defaultInvoiceLineItem.catalogItemUuid

    const description = item.description ?? defaultInvoiceLineItem.description

    const discount = {
      config: item.discount?.config ?? initialValues.discount.config,
      percentage:
        item.discount?.config === InvoiceDiscountConfig.PERCENTAGE
          ? convertNumberToLocaleString(Number(item.discount.value))
          : initialValues.discount.percentage,
      price:
        item.discount?.config === InvoiceDiscountConfig.AMOUNT
          ? convertNumberToLocaleString(
              // NOTE: Must wrap in another instance of currency as an issue when
              // performing subsequent calculations on an instance using the `fromCents`
              // option https://github.com/scurker/currency.js/issues/425
              currency(
                currency(item.discount.value, {
                  fromCents: true,
                  precision: 4,
                })
              ).value
            )
          : initialValues.discount.price,
    }

    const isQuantityUnit = item.unit === InvoiceItemUnit.QUANTITY
    const isTaxApplicable = item.taxes?.some((tax) => tax.enabled) ?? false

    const hasCalculation =
      convertNumberToLocaleString(
        item.quantity,
        isQuantityUnit
          ? {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }
          : {}
      ) !== defaultInvoiceLineItem.quantity ||
      item.unit !== defaultInvoiceLineItem.unit

    // NOTE: Must wrap in another instance of currency as an issue when
    // performing subsequent calculations on an instance using the `fromCents`
    // option https://github.com/scurker/currency.js/issues/425
    const price = currency(
      currency(item.price, { fromCents: true, precision: 4 }),
      { precision: 4 }
    )

    const quantity = convertNumberToLocaleString(
      item.quantity,
      isQuantityUnit
        ? {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }
        : {}
    )

    return {
      catalogItemUuid,
      description,
      discount,
      hasCalculation,
      id: item.id,
      initialDescription: description,
      initialName: item.name,
      initialPrice: price,
      initialQuantity: quantity,
      key: index + 1,
      name: item.name,
      price,
      quantity,
      taxApplicable: isTaxApplicable,
      unit: item.unit,
    }
  })
}

export const getCustomerContactValues = (
  customerContact?: CustomerContact | null
) => {
  if (!customerContact) {
    return null
  }
  const { firstName, lastName } = customerContact
  const businessName = customerContact.businessName ?? ''
  const personName = `${firstName ?? ''} ${lastName ?? ''}`.trim()

  return {
    contactUuid: customerContact.contactUuid,
    contactName:
      customerContact.contactType === ContactType.BUSINESS
        ? businessName
        : personName,
    contactType: customerContact.contactType,
    status: customerContact.status,
  }
}

const getDeliveryValues = (invoice: GetInvoiceResponse) => ({
  email: {
    bcc: invoice.email?.recipients?.bcc ?? initialValues.delivery.email.bcc,
    cc: invoice.email?.recipients?.cc ?? initialValues.delivery.email.cc,
    isEnabled: Boolean(invoice.email?.enabled),
    message: invoice.email?.body ?? initialValues.delivery.email.message,
    recipient:
      invoice.customer?.payerEmail ?? initialValues.delivery.email.recipient,
    sendMeCopy:
      invoice.email?.recipients?.sendMeCopy ??
      initialValues.delivery.email.sendMeCopy,
    subject: invoice.email?.subject ?? initialValues.delivery.email.subject,
  },
  sms: {
    isEnabled: Boolean(invoice.sms?.enabled),
    recipient:
      invoice.sms?.payerContactPhoneNumber ??
      initialValues.delivery.sms.recipient,
  },
})

const getDiscountValues = (invoice: GetInvoiceResponse) => ({
  config: invoice.discount?.config ?? initialValues.discount.config,
  percentage:
    invoice.discount?.config === InvoiceDiscountConfig.PERCENTAGE
      ? convertNumberToLocaleString(Number(invoice.discount.value))
      : initialValues.discount.percentage,
  price:
    invoice.discount?.config === InvoiceDiscountConfig.AMOUNT
      ? convertNumberToLocaleString(
          // NOTE: Must wrap in another instance of currency as an issue when
          // performing subsequent calculations on an instance using the `fromCents`
          // option https://github.com/scurker/currency.js/issues/425
          currency(
            currency(invoice.discount.value, {
              fromCents: true,
              precision: 4,
            })
          ).value
        )
      : initialValues.discount.price,
})

const getScheduleValues = (invoice: GetInvoiceResponse) => {
  const {
    schedule: { dueDate, invoiceDate, sendEnabled, sendDate },
  } = initialValues

  return {
    dueDate: toDateDisplayFormat(invoice.dueDate, dueDate),
    invoiceDate: toDateDisplayFormat(invoice.startDate, invoiceDate),
    paymentTerms: PaymentTerms.Custom,
    sendEnabled: isSchedulingAllowed(invoice.status)
      ? invoice.sendSchedule?.enabled ?? sendEnabled
      : false,
    sendDate: isSchedulingAllowed(invoice.status)
      ? toDateDisplayFormat(invoice.sendSchedule?.sendDate, sendDate)
      : '',
  }
}

export const convertGetInvoiceResponseToFormFields = (
  invoice:
    | GetInvoiceResponse
    | CreateInvoiceResponse
    | SaveAndSendInvoiceResponse,
  invoiceSettings:
    | GetZellerInvoiceSettingsQueryResponse['getZellerInvoiceSettings']
    | undefined
): InvoiceFormFields => {
  const attentionToContact = invoice.customer?.attentionContact
  const payerContact = invoice.customer?.payerContact
  const maximumLimit = invoiceSettings?.paymentLimits?.maximum
  const minimumLimit = invoiceSettings?.paymentLimits?.minimum

  return {
    customer: {
      attentionToContact: getCustomerContactValues(attentionToContact),
      payerContact: getCustomerContactValues(payerContact),
    },
    discount: getDiscountValues(invoice),
    discountsEnabled: invoice.siteSettings?.invoice.discountsEnabled ?? false,
    id: invoice.id,
    itemsApplyTax: invoice.itemsApplyTax,
    itemsTaxInclusive: invoice.itemsTaxInclusive,
    items: convertGetInvoiceItemsToItemsFormFields(invoice.items),
    delivery: getDeliveryValues(invoice),
    requiredEmailUpdateBeforeSend: invoice.requiredEmailUpdateBeforeSend ?? [],
    schedule: getScheduleValues(invoice),
    title: {
      header: invoice.title ?? initialValues.title.header,
      message: invoice.message ?? initialValues.title.message,
    },
    maximumLimit: maximumLimit
      ? currency(maximumLimit, { fromCents: true, precision: 2 })
      : initialValues.maximumLimit,
    minimumLimit: minimumLimit
      ? currency(minimumLimit, { fromCents: true, precision: 2 })
      : initialValues.minimumLimit,
    referenceNumber: invoice.referenceNumber,
    status: invoice.status,
  }
}
