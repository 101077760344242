import { useTranslations } from '@npco/utils-translations'

import { XeroErrorModalTranslations } from '../XeroErrorModal.i18n'
import {
  getAuthErrorDescription,
  getGenericErrorDescription,
  getServicesToDisplay,
} from './Description.utils'

interface DescriptionProps {
  hasErrorInPaymentService: boolean
  hasAuthErrorInPaymentService: boolean
  hasErrorInBankFeeds: boolean
  hasAuthErrorInBankFeeds: boolean
}
export const Description = ({
  hasErrorInPaymentService,
  hasAuthErrorInPaymentService,
  hasErrorInBankFeeds,
  hasAuthErrorInBankFeeds,
}: DescriptionProps) => {
  const t = useTranslations(XeroErrorModalTranslations)

  if (!hasErrorInPaymentService && !hasErrorInBankFeeds) {
    return null
  }

  const hasNonAuthErrorInBankFeeds =
    hasErrorInBankFeeds && !hasAuthErrorInBankFeeds
  const hasNonAuthErrorInPaymentService =
    hasErrorInPaymentService && !hasAuthErrorInPaymentService

  const servicesWithAuthError = getServicesToDisplay(
    t,
    hasAuthErrorInBankFeeds,
    hasAuthErrorInPaymentService
  )

  const servicesWithGenericError = getServicesToDisplay(
    t,
    hasNonAuthErrorInBankFeeds,
    hasNonAuthErrorInPaymentService
  )

  if (servicesWithAuthError && servicesWithGenericError) {
    return (
      <>
        {getAuthErrorDescription(t, servicesWithAuthError)}
        <br />
        {getGenericErrorDescription(t, servicesWithGenericError)}
      </>
    )
  }

  if (servicesWithAuthError) {
    return getAuthErrorDescription(t, servicesWithAuthError)
  }

  return getGenericErrorDescription(t, servicesWithGenericError)
}
