import { useCallback, useMemo } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { type InputSelectComboboxStandardProps } from '@npco/zeller-design-system'
import type { InputSelectComboBoxItem } from 'features/Contacts/Contacts.types'

import { bpayTargetInputTranslations } from 'pages/Transfer/BPay/BPayTargetInput.i18n'
import { AddBusinessButton } from 'pages/Transfer/BPay/BPayTargetInput/components/AddBusinessButton/AddBusinessButton'
import { NoBillersFoundInfo } from 'pages/Transfer/BPay/BPayTargetInput/components/NoBillersFoundInfo/NoBillersFoundInfo'
import { SearchStringLengthRequirementInfo } from 'pages/Transfer/BPay/BPayTargetInput/components/SearchStringLengthRequirementInfo/SearchStringLengthRequirementInfo'

import { getComboboxItemId } from './getComboboxItemId'
import type { ItemContactOrBiller, SearchResults } from './useBPayTargetItems'
import { ComboboxItem } from './useComboboxProps/ComboboxItem'

export type ComboboxItem = InputSelectComboBoxItem & {
  icon: 'contact' | 'biller'
}
export type ComboboxProps = Required<
  InputSelectComboboxStandardProps<ComboboxItem>
>

export type Options = {
  onAddBusiness: () => void
  onQueryChange: (searchString: string) => void
  onValueChange: (item: ItemContactOrBiller | undefined) => void
  searchResults: SearchResults
  searchString: string
  value: ItemContactOrBiller | undefined
}

export const useComboboxProps = ({
  onAddBusiness,
  onQueryChange,
  onValueChange,
  searchResults,
  searchString,
  value,
}: Options) => {
  const t = useTranslations(bpayTargetInputTranslations)

  const label = t('selectLabel')
  const placeholder = t('selectPlaceholder')

  const shouldTriggerSearch = searchString?.length >= 4

  const toComboboxItem = useCallback(
    (item: ItemContactOrBiller): ComboboxItem => {
      if (item.type === 'Contact') {
        const count = item.billerContacts?.length ?? 0
        return {
          id: getComboboxItemId(item),
          value: item.id,
          label: item.name,
          subLabel: t('selectItemContactSublabel', {
            count,
            plural: count !== 1 ? 's' : '',
          }),
          icon: 'contact',
          isSelf: item.isSelf,
          email: item.email?.email,
          phone: item.phoneV2?.phone ?? item?.phone,
        }
      }
      if (item.type === 'Biller') {
        return {
          id: getComboboxItemId(item),
          value: item.code,
          label: item.code,
          subLabel: item.name,
          icon: 'biller',
          email: item.email?.email,
          phone: item.phoneV2?.phone ?? item?.phone,
        }
      }

      throw new TypeError()
    },
    [t]
  )

  const itemFromValue: ComboboxItem | undefined = useMemo(() => {
    if (!value) {
      return undefined
    }
    return toComboboxItem(value)
  }, [value, toComboboxItem])

  const itemsFromQuery: ComboboxProps['items'] = useMemo(() => {
    if (searchResults.type !== 'Ok') {
      return []
    }
    return searchResults.value.items.map((item) => toComboboxItem(item))
  }, [searchResults, toComboboxItem])

  const items: ComboboxProps['items'] = useMemo(() => {
    if (itemFromValue) {
      return [itemFromValue]
    }

    if (!shouldTriggerSearch) {
      return []
    }

    return (
      itemsFromQuery
        .filter(
          (item) =>
            item.icon === 'contact' ||
            (item.icon === 'biller' && item.value?.startsWith(searchString))
        )
        ?.slice(0, 10) ?? []
    )
  }, [itemFromValue, itemsFromQuery, shouldTriggerSearch, searchString])

  const isLoading: ComboboxProps['isLoading'] = searchResults.type === 'Pending'

  const selectedItem: ComboboxProps['selectedItem'] = useMemo(() => {
    if (!value) {
      return null
    }
    const item = items.find((item) => item.id === getComboboxItemId(value))
    if (!item) {
      return null
    }
    return item
  }, [value, items])

  const onChange: ComboboxProps['onChange'] = useCallback(
    (changes) => {
      const nextValue = (() => {
        const item = changes.selectedItem
        if (!item) {
          return undefined
        }

        if (searchResults.type !== 'Ok') {
          return undefined
        }

        const queryItem = searchResults.value.items.find(
          (queryItem) => getComboboxItemId(queryItem) === item.id
        )
        if (!queryItem) {
          return undefined
        }

        return queryItem
      })()

      onValueChange(nextValue)
    },
    [onValueChange, searchResults]
  )

  const inputValue: ComboboxProps['inputValue'] = searchString || ''

  const onInputChange: ComboboxProps['onInputChange'] = useCallback(
    ({ inputValue, selectedItem }) => {
      if (selectedItem && inputValue !== selectedItem.label) {
        onValueChange(undefined)
      } else {
        onQueryChange(inputValue)
      }
    },
    [onValueChange, onQueryChange]
  )

  const onInputClear = useCallback<ComboboxProps['onInputClear']>(() => {
    onValueChange(undefined)
  }, [onValueChange])

  const hasError: ComboboxProps['hasError'] = searchResults.type === 'Error'

  const renderHeader: ComboboxProps['renderHeader'] = useCallback(
    ({ closeMenu }) => (
      <>
        <AddBusinessButton
          onAddBusiness={onAddBusiness}
          closeMenu={closeMenu}
        />
        {!shouldTriggerSearch && <SearchStringLengthRequirementInfo />}
        {shouldTriggerSearch && !isLoading && items.length < 1 && (
          <NoBillersFoundInfo />
        )}
      </>
    ),
    [shouldTriggerSearch, items, isLoading, onAddBusiness]
  )

  return {
    label,
    placeholder,
    items,
    isLoading,
    selectedItem,
    onChange,
    inputValue,
    onInputChange,
    onInputClear,
    hasError,
    renderHeader,
    renderItem: ComboboxItem,
  }
}
