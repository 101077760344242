import { CustomerRole } from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { CardEmptyViewCorporate } from '../components/CardEmptyViewCorporate/CardEmptyViewCorporate'
import { CardEmptyViewMessage } from '../components/CardEmptyViewMessage/CardEmptyViewMessage'

interface CorporateCardsEmptyProps {
  onAddCard: () => void
}

export const CorporateCardsEmpty = ({
  onAddCard,
}: CorporateCardsEmptyProps) => {
  const { userRole } = useLoggedInUser()

  if (userRole === CustomerRole.ADMIN) {
    return <CardEmptyViewCorporate onAddCard={onAddCard} />
  }

  return <CardEmptyViewMessage />
}
