import { ButtonGhost } from '@npco/zeller-design-system'

import { SaveAndCloseGroup } from 'pages/Onboarding/SaveAndClose/SaveAndCloseGroup/SaveAndCloseGroup'
import { TerminalsImageSidebar } from 'components/TerminalsImageSidebar/TerminalsImageSidebar'
import { shared } from 'translations'

export const OnboardingSidebar: React.FC<{ hasSaveButton?: boolean }> = ({
  hasSaveButton = true,
}) => {
  return (
    <TerminalsImageSidebar dataTestId="onboarding-sidebar">
      {hasSaveButton && (
        <SaveAndCloseGroup>
          {({ isDisabled, onClick }) => (
            <ButtonGhost disabled={isDisabled} onClick={onClick}>
              {shared.saveAndClose}
            </ButtonGhost>
          )}
        </SaveAndCloseGroup>
      )}
    </TerminalsImageSidebar>
  )
}
