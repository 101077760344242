import { useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  rvIsDateRangeAppropriate,
  rvLastSelectedDates,
  rvSearchPhrase,
  rvSelectedDates,
  rvSelectedRates,
  rvSelectedSources,
  rvSelectedStatuses,
  rvSelectedTerminals,
  rvSelectedTypes,
  rvToggleFilters,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { useGetSites } from 'hooks/useGetSites'
import { datePickerInitialState } from 'const/common'
import {
  checkIfFiltersNotSelected,
  checkIfFiltersNotStored,
} from 'utils/common'
import {
  createSplitPaymentApiFilter,
  mapFiltersInputToTransactionsApiFilter,
} from 'utils/mapFiltersInputToApiFilter'
import { PickerItems, TIME_FILTER_ENABLED_COMPONENTS } from 'types/picker'

import { checkIfNeedFilteredByRefundedRV } from '../../Transactions.utils'

export type UseTransactionFiltersProps = {
  defaultSources?: PickerItems
  isOnTransactionsPath: boolean
  splitPaymentId: string
  defaultTypes?: PickerItems
}

export const useTransactionFilters = ({
  defaultSources,
  isOnTransactionsPath,
  splitPaymentId,
  defaultTypes,
}: UseTransactionFiltersProps) => {
  const { isLoading, hasMore, sites } = useGetSites()

  const totalDevicesCount = useMemo<number>(() => {
    if (isLoading || hasMore) {
      return 0
    }

    return sites.reduce((acc, curr) => {
      if (!curr.devices) {
        return acc
      }

      return acc + curr.devices.length
    }, 0)
  }, [isLoading, hasMore, sites])

  const dates = useReactiveVar(rvSelectedDates)
  const phrase = useReactiveVar(rvSearchPhrase)
  const rates = useReactiveVar(rvSelectedRates)
  const devices = useReactiveVar(rvSelectedTerminals)
  const sources = useReactiveVar(rvSelectedSources)
  const statuses = useReactiveVar(rvSelectedStatuses)
  const types = useReactiveVar(rvSelectedTypes)

  const terminals = useMemo(
    () => (devices.length === totalDevicesCount ? [] : devices),
    [devices, totalDevicesCount]
  )

  const isDateRangeAppropriate = useReactiveVar(rvIsDateRangeAppropriate)
  const lastSelectedDates = rvLastSelectedDates()
  const searchPhrase = rvSearchPhrase()

  const widgetKey = isOnTransactionsPath
    ? TIME_FILTER_ENABLED_COMPONENTS.TRANSACTIONS
    : TIME_FILTER_ENABLED_COMPONENTS.TRANSACTIONS_SPLIT_PAYMENT

  const { filters, isDefaultFilters } = useMemo(() => {
    const values = {
      dates,
      phrase,
      rates,
      sources,
      statuses,
      terminals,
      types,
    }

    const isDefaultFilters =
      checkIfFiltersNotSelected(values) || checkIfFiltersNotStored(values)

    const filters = isOnTransactionsPath
      ? mapFiltersInputToTransactionsApiFilter(values, false, true)
      : createSplitPaymentApiFilter(values, splitPaymentId)

    return { filters, isDefaultFilters }
  }, [
    isOnTransactionsPath,
    splitPaymentId,
    dates,
    phrase,
    rates,
    sources,
    statuses,
    terminals,
    types,
  ])

  const filtersInitialValues = useMemo(() => {
    const selectedDates = lastSelectedDates[widgetKey] || datePickerInitialState

    return {
      selectedDates,
      searchPhrase,
      ...(defaultSources && { selectedSources: defaultSources }),
      ...(defaultTypes && { selectedTypes: defaultTypes }),
    }
  }, [defaultSources, defaultTypes, lastSelectedDates, searchPhrase, widgetKey])

  const isFiltersVisible = useReactiveVar(rvToggleFilters)

  const isFilteringByRefunded = checkIfNeedFilteredByRefundedRV()

  const handleToggleFilters = useCallback(
    () => rvToggleFilters(!isFiltersVisible),
    [isFiltersVisible]
  )

  return {
    filters,
    filtersInitialValues,
    handleToggleFilters,
    isDateRangeAppropriate,
    isDefaultFilters,
    isFiltersDisabled: isLoading,
    isFilteringByRefunded,
    isFiltersVisible,
    widgetKey,
  }
}
