import styled from 'styled-components'

export type ListProps = Record<string, never>

export const List = styled.div`
  display: grid;
  width: 100%;
  row-gap: 10px;
  grid-template-columns: 100fr;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  margin-top: 14px;
`
