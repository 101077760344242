import { useCallback } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'

import { useGetEntityDailyLimit } from 'hooks/useGetEntityDailyLimit'
import { amountFormatter } from 'utils/common'
import {
  combineValidators,
  validateMaxValue,
  validateMinValue,
  validateRequiredCustomMsg,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { contactTransferFieldTranslations } from 'pages/Transfer/states/ContactTransferFields/ContactTransferField.i18n'
import { errorMessages } from 'translations'

import { AmountField } from '../../AmountField'

const MIN_AMOUNT = 0.01
const MAX_AMOUNT = 100000

export const BpayAmountField = () => {
  const flags = useFeatureFlags()
  const t = useTranslations(contactTransferFieldTranslations)

  const {
    dailyMaximumLimit,
    loading: dailyMaximumLimitLoading,
    error: dailyMaximumLimitLoadingError,
  } = useGetEntityDailyLimit()

  const validateContactTransferAmount = useCallback(
    (fromBalance: number) => {
      return combineValidators(
        validateRequiredCustomMsg(t('amountRequired')),
        validateMaxValue(fromBalance, errorMessages.transferBiggerThanBalance),
        flags.NoExternalTransferLimit
          ? undefined
          : validateMaxValue(
              MAX_AMOUNT,
              translate('errorMessages.transferTooBig', {
                maxAmount: amountFormatter(MAX_AMOUNT),
              })
            ),
        validateMaxValue(
          dailyMaximumLimit / 100,
          t('dailyMaxLimitExceedError'),
          Boolean(dailyMaximumLimitLoadingError)
        ),
        validateMinValue(MIN_AMOUNT, errorMessages.transferTooSmall)
      )
    },
    [
      t,
      dailyMaximumLimit,
      dailyMaximumLimitLoadingError,
      flags.NoExternalTransferLimit,
    ]
  )

  return (
    <AmountField
      validate={validateContactTransferAmount}
      limitLoading={dailyMaximumLimitLoading}
    />
  )
}
