import { Formik } from 'formik'

import { translate } from 'utils/translations'
import {
  StyledFormBodyWithOffset,
  StyledFormFooterWithOffset,
  StyledFormWithOffset,
} from 'pages/GlobalModals/GlobalModals.styled'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { LandingPageFields } from './fields/LandingPageFields'
import { useLandingPageForm } from './hooks/useLandingPageForm'
import { LandingPageFormValues } from './LandingPageForm.types'

interface LandingPageFormProps {
  dataTestId?: string
}

export const LandingPageForm = ({ dataTestId }: LandingPageFormProps) => {
  const { handleSubmit, handleBack } = useLandingPageForm()
  useMultiStageModalProgressBar({ newCurrentStep: 1 })

  return (
    <Formik<LandingPageFormValues>
      validateOnMount
      initialValues={{ linkOrCreate: '' }}
      onSubmit={handleSubmit}
    >
      <StyledFormWithOffset data-testid={dataTestId}>
        <StyledFormBodyWithOffset>
          <LandingPageFields />
        </StyledFormBodyWithOffset>
        <StyledFormFooterWithOffset
          onCancel={handleBack}
          backButtonLabel={translate('shared.cancel')}
          disableOnInvalid
        />
      </StyledFormWithOffset>
    </Formik>
  )
}
