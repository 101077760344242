import { Customized, Pie, PieChart as RechartsPieChart } from 'recharts'

import { PIE_CHART_SIZES } from './PieChart.type'
import { PieChartLoading } from './PieChartLoading'

type PieDataProps = {
  fill: string
  name: string | undefined
  percentage: number
}

interface PieChartProps {
  size?: 'large' | 'small'
  data: PieDataProps[]
  center: JSX.Element
}

export const PieChart = ({ size = 'large', data, center }: PieChartProps) => {
  const hasSingleDataPoint = data.length === 1

  return (
    <RechartsPieChart
      width={PIE_CHART_SIZES[size].width}
      height={PIE_CHART_SIZES[size].height}
      aria-label="pie-chart"
    >
      <Customized component={center} />
      <Pie
        dataKey="percentage"
        innerRadius="80%"
        outerRadius="100%"
        startAngle={90}
        endAngle={-270}
        data={data}
        paddingAngle={hasSingleDataPoint ? 0 : 0.5}
        animationBegin={0}
        stroke={hasSingleDataPoint ? 'none' : undefined}
        style={{ outline: 'none' }}
      />
    </RechartsPieChart>
  )
}

PieChart.Loading = PieChartLoading
