import type { ReactNode } from 'react'
import {
  BodyDefaultTypography,
  ButtonLink,
  Heading,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { ListItem } from '../ListItem'

export const SectionTitle = styled(Heading.H3)`
  margin: 0;
`

export const SectionTitleAction = styled(ButtonLink)`
  ${BodyDefaultTypography}
`

export interface ListItemSectionTitleProps {
  title: ReactNode
  right?: ReactNode
  action?: {
    label: ReactNode
    onClick?: () => void
  }
}

export const ListItemSectionTitle = styled(
  ({
    title,
    right,
    action,
    className,
  }: ListItemSectionTitleProps & { className?: string }) => (
    <ListItem
      className={className}
      left={<SectionTitle>{title}</SectionTitle>}
      right={
        action ? (
          <SectionTitleAction onClick={action.onClick}>
            {action.label}
          </SectionTitleAction>
        ) : (
          right
        )
      }
    />
  )
)`
  margin-top: 28px;
`
