import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useCombinedOnboardingFormPhase2 } from 'features/OnboardingApp/shared/hooks/useCombinedOnboardingFormPhase2'
import { useSaveFlowFlagsCombinedForm } from 'features/OnboardingApp/shared/hooks/useSaveFlowFlagsCombinedForm'

import { ROOT } from 'const/routes'
import { sendToDataLayer } from 'utils/gtmHelper'

export const useStartOnboarding = () => {
  const navigate = useNavigate()
  const { saveFlowFlagsCombinedForm } = useSaveFlowFlagsCombinedForm()
  const { shouldShowCombinedFormForPhase2, shouldSaveFlowFlags } =
    useCombinedOnboardingFormPhase2()

  const startOnboarding = useCallback(() => {
    if (shouldSaveFlowFlags) {
      saveFlowFlagsCombinedForm(shouldShowCombinedFormForPhase2)
    }

    sendToDataLayer({ event: 'OnboardingStarted' })
    if (shouldShowCombinedFormForPhase2) {
      navigate(ROOT.ONBOARDING.YOUR_BUSINESS_INFORMATION.path)
      return
    }
    navigate(ROOT.ONBOARDING.path)
  }, [
    navigate,
    saveFlowFlagsCombinedForm,
    shouldSaveFlowFlags,
    shouldShowCombinedFormForPhase2,
  ])

  return { startOnboarding }
}
