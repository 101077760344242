import { useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { EntityType } from '@npco/mp-gql-types'
import { Box, COLOR, Flex, SvgIcon } from '@npco/zeller-design-system'
import { rvCustomersCurrentCustomerTempId } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useWatchOnboardingSaveData } from 'features/OnboardingApp/shared/useWatchOnboardingSaveData'
import { Field, useFormikContext } from 'formik'

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg'
import { parseToSaveFullDate } from 'utils/date'
import { getTextSplitIntoMultipleLines } from 'utils/getSplitMultilineText'
import { translate } from 'utils/translations'
import { Roles } from 'types/customers'
import { parseToAbnOrAcnData } from 'pages/Onboarding/Onboarding.utils'
import {
  TrustAddCustomerForm,
  TrustAddCustomerFormValues,
} from 'forms/formViews/TrustAddCustomerForm/TrustAddCustomerForm'
import { FormRadioButton } from 'components/Input/Radiobutton'
import { Tooltip } from 'components/Tooltip'

import { TrusteeCompanyForm } from './TrusteeCompanyForm'
import { StyledLabel, StyledTooltipContent } from './TrusteesForm.styled'

export type TrusteeType = EntityType.COMPANY | EntityType.INDIVIDUAL

export type CompanyFormValues = {
  companyTrustName?: string
  abnOrAcn?: string
}

export type FormValues = CompanyFormValues &
  TrustAddCustomerFormValues & {
    type: TrusteeType | null
    isBeneficialOwner?: boolean
  }

const FIELD_MAP = {
  [EntityType.INDIVIDUAL]: TrustAddCustomerForm,
  [EntityType.COMPANY]: TrusteeCompanyForm,
}

interface BeneficiariesFormProps {
  isDisabled?: boolean
}

export const TrusteesForm = ({ isDisabled }: BeneficiariesFormProps) => {
  const { values } = useFormikContext<FormValues>()
  const currentCustomerId = useReactiveVar(rvCustomersCurrentCustomerTempId)
  const { pathname } = useLocation()

  const FieldsComponent = values.type && FIELD_MAP[values.type]

  const { type, isBeneficialOwner, abnOrAcn, ...restValues } = values

  useWatchOnboardingSaveData({
    initialCustomerData: {
      roles: [
        ...(type ? [values.type as string] : []),
        ...(isBeneficialOwner ? [Roles.beneficialOwner as string] : []),
      ],
      temporaryId: currentCustomerId,
      ...restValues,
      ...parseToAbnOrAcnData(abnOrAcn),
      dob: parseToSaveFullDate(restValues.dob),
    },
    lastRoute: pathname,
  })

  return (
    <div data-testid="trustees-form">
      <Flex
        mb="40px"
        justifyContent={['center', 'space-between']}
        flexDirection={['column', 'row']}
        width="350px"
        role="group"
      >
        <StyledLabel isDisabled={isDisabled} htmlFor="individual">
          <Field
            id="individual"
            name="type"
            data-testid="individual-checkbox"
            value={EntityType.INDIVIDUAL}
            checked={values.type === EntityType.INDIVIDUAL}
            component={FormRadioButton}
            disabled={isDisabled}
          />
          <Box mr="6px" as="span">
            {translate('form.trustees.trusteeType.individual')}
          </Box>
          <Tooltip
            tooltipIcon={
              <SvgIcon color={COLOR.GREY_250} width="15" height="15">
                <InfoIcon />
              </SvgIcon>
            }
          >
            <StyledTooltipContent>
              {getTextSplitIntoMultipleLines(
                translate('form.trustees.individualHint')
              )}
            </StyledTooltipContent>
          </Tooltip>
        </StyledLabel>
        <StyledLabel isDisabled={isDisabled} htmlFor="class">
          <Field
            id="class"
            name="type"
            data-testid="class-checkbox"
            component={FormRadioButton}
            value={EntityType.COMPANY}
            checked={values.type === EntityType.COMPANY}
            disabled={isDisabled}
          />
          <Box mr="6px" as="span">
            {translate('form.trustees.trusteeType.company')}
          </Box>
          <Tooltip
            tooltipIcon={
              <SvgIcon color={COLOR.GREY_250} width="15" height="15">
                <InfoIcon />
              </SvgIcon>
            }
          >
            <StyledTooltipContent>
              {getTextSplitIntoMultipleLines(
                translate('form.trustees.companyHint')
              )}
            </StyledTooltipContent>
          </Tooltip>
        </StyledLabel>
      </Flex>
      {FieldsComponent && <FieldsComponent />}
    </div>
  )
}
