import { AddCardStage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { Condition } from 'components/Condition/Condition'

import { useAddCardState } from '../../../hooks/useAddCardState'
import { LinkCardIdForm } from './components/LinkCardIdForm/LinkCardIdForm'

export const LinkCardIdStage = () => {
  const { state } = useAddCardState()

  return (
    <Condition shouldShow={state?.stage === AddCardStage.LinkCardIdStage}>
      <LinkCardIdForm dataTestId="link-card-id-stage" />
    </Condition>
  )
}
