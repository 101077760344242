import { useTranslations } from '@npco/utils-translations'
import { Box, ModalForm } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { SpendControlFields } from 'pages/GlobalModals/components/SpendControlFields'
import { translations } from 'translations/shared.i18n'

import { editSpendControlsModalFormTranslations } from './EditSpendControlsModalForm.i18n'
import { LastModified } from './LastModified'

interface EditSpendControlsModalFormProps {
  isLoading?: boolean
  closeModal: () => void
  accountUuid: string
  timezone?: string
  isLoadingTimezone: boolean
}

export const EditSpendControlsModalForm = ({
  isLoading,
  closeModal,
  accountUuid,
  timezone,
  isLoadingTimezone,
}: EditSpendControlsModalFormProps) => {
  const { handleSubmit } = useFormikContext()
  const tShared = useTranslations(translations)
  const t = useTranslations(editSpendControlsModalFormTranslations)

  return (
    <ModalForm
      onClickPrimary={handleSubmit}
      primaryButtonLabel={tShared('save')}
      secondaryButtonLabel={tShared('cancel')}
      isOpen
      onCancel={closeModal}
      title={t('title')}
      isLoading={isLoading}
      isPrimaryButtonDisabled={isLoadingTimezone}
    >
      <Box pt="4px" pb="40px">
        <SpendControlFields
          fullWidth
          isDescriptionOnTop={false}
          accountId={accountUuid}
          fieldsGapValue={24}
          timezone={timezone}
          isLoadingTimezone={isLoadingTimezone}
        />
      </Box>
      <LastModified />
    </ModalForm>
  )
}
