import { SelectedDateRangeProvider } from '@npco/mp-feature-cashflow-reporting'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useAccountsTransactionsMetadata } from 'features/AccountsTransactions'

import { useGetEntityAddressTimezone } from 'hooks/useGetEntityAddressTimezone'

import { AccountTransactionsList } from '../AccountTransactionsList/AccountTransactionsList'
import { AccountsTransactionsFilters } from './AccountsTransactionsFilters/AccountsTransactionsFilters'
import { AccountsTransactionsIncomeAndExpense } from './AccountsTransactionsIncomeAndExpense'
import { useAccountsTransactionsFilters } from './hooks/useAccountsTransactionsFilters'

export const AccountsTransactions = () => {
  const flags = useFeatureFlags()
  const { areFiltersInDefaultState, filters } = useAccountsTransactionsFilters()
  const { isFiltersVisible } = useAccountsTransactionsMetadata()
  const { isLoading, timezone } = useGetEntityAddressTimezone()

  return (
    <>
      {flags.AccountsBalanceChartOnTransactions && (
        <>
          <SelectedDateRangeProvider>
            <AccountsTransactionsIncomeAndExpense
              entityTimezone={timezone}
              isLoadingTimezone={isLoading}
            />
          </SelectedDateRangeProvider>
          <AccountsTransactionsFilters isFiltersVisible={isFiltersVisible} />
        </>
      )}
      <AccountTransactionsList
        areFiltersInDefaultState={areFiltersInDefaultState}
        filters={filters}
      />
    </>
  )
}
