import { CancelCardStage } from 'hooks/useGoToCancelCardStage/useGoToCancelCardStage.types'
import { Condition } from 'components/Condition/Condition'

import { useCancelCardState } from '../hooks/useCancelCardState'
import { CancelCardForm } from './CancelCardForm/CancelCardForm'

export const CancelCard = () => {
  const { state } = useCancelCardState()

  return (
    <Condition shouldShow={state?.stage === CancelCardStage.CancelCard}>
      <CancelCardForm dataTestId="cancel-card-form" />
    </Condition>
  )
}
