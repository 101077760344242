import { MouseEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { BannerCMS, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import { StoryblokBannerProps } from 'features/StoryblokContent/Storyblok.types'
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'
import { getTextForDevice } from 'pages/Dashboard/DashboardLayout/OverviewBanner/overviewBannerUtils'
import { MarkdownWrapper } from 'pages/Dashboard/DashboardLayout/OverviewBanner/SavingsAccountOverviewBanner/SavingsAccountOverviewBanner.styled'
import { rvSeenOverviewBannerAnimation } from 'pages/Dashboard/DashboardLayout/reactiveVariables'

interface BannerCMSComponentProps {
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  hideOverviewBanner: () => void
  bannerContent?: StoryblokBannerProps
  slug: string
}

export const BannerCMSComponent = ({
  hideOverviewBanner,
  bannerContent,
  slug,
  ...props
}: BannerCMSComponentProps) => {
  const {
    banner_background_color: bannerBackgroundColor,
    banner_image: bannerImage,
    banner_image_location: bannerImageLocation,
    banner_image_url: bannerImageUrl,
    banner_main_text: bannerMainText,
    banner_main_text_color: bannerMainTextColor,
    banner_main_text_font_size: bannerMainTextFontSize,
    banner_main_text_font_style: bannerMainTextFontStyle,
    banner_main_text_font_weight: bannerMainTextFontWeight,
    banner_main_text_mobile: bannerMainTextMobile,
    banner_main_text_tablet: bannerMainTextTablet,
    call_to_action_link_url: callToActionLinkUrl,
    call_to_action_link_text: callToActionLinkText,
    call_to_action_link_text_color: callToActionLinkTextColor,
    call_to_action_link_text_hover_color: callToActionLinkTextHoverColor,
    call_to_action_link_text_font_size: callToActionLinkTextFontSize,
    call_to_action_link_text_font_style: callToActionLinkTextFontStyle,
    call_to_action_link_text_font_weight: callToActionLinkTextFontWeight,
    call_to_action_link_text_mobile: callToActionLinkTextMobile,
    call_to_action_link_text_tablet: callToActionLinkTextTablet,
    hide_close_button: isCloseButtonHidden,
    pill_text: pillText,
    pill_background_color: pillBackgroundColor,
    pill_text_color: pillTextColor,
    pill_text_font_size: pillTextFontSize,
    pill_text_font_style: pillTextFontStyle,
    pill_text_font_weight: pillTextFontWeight,
    pill_text_mobile: pillTextMobile,
    pill_text_tablet: pillTextTablet,
    shadow_color: shadowColor,
    should_text_in_same_line_as_pill: shouldTextInSameLineAsPill,
    title_text: titleText,
    title_text_color: titleTextColor,
    title_text_font_size: titleTextFontSize,
    title_text_font_style: titleTextFontStyle,
    title_text_font_weight: titleTextFontWeight,
  } = bannerContent ?? {}

  const imageSrc = bannerImage?.filename ?? bannerImageUrl?.url
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.XS + 1)
  const isTabletResolution = useIsMobileResolution(BREAKPOINT.SM + 1)
  const hasSeenAnimation = useReactiveVar(rvSeenOverviewBannerAnimation)
  const shouldReduceMotion = useReducedMotion()
  const navigate = useNavigate()
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEventNames.CMS_BANNER_VIEW, { slug })
  }, [slug, trackAnalyticsEvent])

  const mainTextMobile = getTextForDevice(
    isMobileResolution,
    bannerMainTextMobile,
    bannerMainText
  )

  const mainText = getTextForDevice(
    isTabletResolution,
    bannerMainTextTablet,
    mainTextMobile
  )

  const pillTextForMobile = getTextForDevice(
    isMobileResolution,
    pillTextMobile,
    pillText
  )

  const pillTextUniversal = getTextForDevice(
    isTabletResolution,
    pillTextTablet,
    pillTextForMobile
  )

  const actionTextMobile = getTextForDevice(
    isMobileResolution,
    callToActionLinkTextMobile,
    callToActionLinkText
  )

  const actionText = getTextForDevice(
    isTabletResolution,
    callToActionLinkTextTablet,
    actionTextMobile
  )

  const handleBannerClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    if (callToActionLinkUrl?.url) {
      e.preventDefault()
      trackAnalyticsEvent(AnalyticsEventNames.CMS_BANNER_CALL_TO_ACTION_LINK, {
        slug,
        actionUrl: callToActionLinkUrl.url,
      })
      if (callToActionLinkUrl?.url?.startsWith('/')) {
        navigate(callToActionLinkUrl?.url)
        return
      }
      window.open(callToActionLinkUrl?.url, '_blank', 'noopener,noreferrer')
    }
  }

  const handleBannerClose = () => {
    trackAnalyticsEvent(AnalyticsEventNames.CMS_BANNER_CLOSE, {
      slug,
    })
    hideOverviewBanner()
  }

  return (
    <Flex width="100%" data-testid="overview-banner">
      <AnimatePresence>
        <motion.div
          style={{
            height: '0px',
            width: '100%',
            overflow: 'hidden',
            y: -100,
          }}
          animate={{ y: 0, height: 'fit-content' }}
          exit={{ y: -100, height: 0, transition: { delay: 0 } }}
          transition={{
            duration: shouldReduceMotion || hasSeenAnimation ? 0 : 0.4,
            delay: shouldReduceMotion || hasSeenAnimation ? 0 : 2,
            type: 'spring',
            bounce: 0,
          }}
          onAnimationComplete={() => rvSeenOverviewBannerAnimation(true)}
        >
          <BannerCMS
            imageUrl={imageSrc}
            imageLocation={bannerImageLocation}
            onClickCloseButton={handleBannerClose}
            backgroundColor={bannerBackgroundColor}
            isCloseButtonHidden={isCloseButtonHidden}
            shadowColor={shadowColor}
            shouldTextInSameLineAsPill={shouldTextInSameLineAsPill}
          >
            {(!isMobileResolution || !titleText) && (
              <BannerCMS.Pill
                $color={pillTextColor}
                $backgroundColor={pillBackgroundColor}
                $fontSize={pillTextFontSize}
                $fontStyle={pillTextFontStyle}
                $fontWeight={pillTextFontWeight}
              >
                <MarkdownWrapper>{pillTextUniversal as string}</MarkdownWrapper>
              </BannerCMS.Pill>
            )}
            <BannerCMS.Description
              $color={bannerMainTextColor}
              $fontSize={bannerMainTextFontSize}
              $fontStyle={bannerMainTextFontStyle}
              $fontWeight={bannerMainTextFontWeight}
            >
              {isMobileResolution && titleText && (
                <BannerCMS.Title
                  $color={titleTextColor}
                  $fontWeight={titleTextFontWeight}
                  $fontStyle={titleTextFontStyle}
                  $fontSize={titleTextFontSize}
                >
                  <MarkdownWrapper>{titleText}</MarkdownWrapper>
                </BannerCMS.Title>
              )}
              <MarkdownWrapper>
                {mainText?.replace(/\\n/g, '\n') as string}
              </MarkdownWrapper>{' '}
              <BannerCMS.Anchor
                href={callToActionLinkUrl?.url}
                $color={callToActionLinkTextColor}
                $hoverColor={
                  !isMobileResolution ? callToActionLinkTextHoverColor : ''
                }
                $fontSize={callToActionLinkTextFontSize}
                $fontStyle={callToActionLinkTextFontStyle}
                $fontWeight={callToActionLinkTextFontWeight}
                onClick={handleBannerClick}
                {...props}
              >
                <MarkdownWrapper>{actionText as string}</MarkdownWrapper>
              </BannerCMS.Anchor>
            </BannerCMS.Description>
          </BannerCMS>
        </motion.div>
      </AnimatePresence>
    </Flex>
  )
}
