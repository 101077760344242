import { useCallback, useState } from 'react'
import { CustomerRole } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useGetCustomerDetails } from 'hooks/useGetCustomerDetails/useFetchCustomerDetails'
import { useRedirectToUsers } from 'utils/settings'
import { CustomerDetailsForm } from 'types/customers'
import { useGetActiveAssignedCards } from 'components/AssignedCardsList'

import { useCreateAdminOnMFARedirect } from './hooks/mfa/useCreateAdminOnMFARedirect'
import { useUpdateAdminOnMFARedirect } from './hooks/mfa/useUpdateAdminOnMFARedirect'
import { useCreateCustomer } from './hooks/useCreateCustomer'
import { useDeleteCustomer } from './hooks/useDeleteCustomer'
import { useGetCustomer } from './hooks/useGetCustomer'
import { useUpdateCustomer } from './hooks/useUpdateCustomer'
import { initialValuesCreate, submitCreateForm, submitEditForm } from './utils'

export interface UseSettingsUserProps {
  isEditForm?: boolean
  isUsers?: boolean
  role: CustomerRole | null
  isXeroPaymentServicesEnabled: boolean
}

export const useSettingsUser = ({
  isEditForm,
  isUsers = false,
  role,
  isXeroPaymentServicesEnabled,
}: UseSettingsUserProps) => {
  const entityUuid = useSelectedEntityUuid()
  const [isPendingAssignSites, setIsPendingAssignSites] = useState(false)

  const redirect = useRedirectToUsers(isUsers)

  const isAdmin = role === CustomerRole.ADMIN

  const defaultCustomerDetails: CustomerDetailsForm = {
    ...initialValuesCreate,
    role,
    allowDiscountManagement: isAdmin,
    allowZellerInvoices: isAdmin,
    allowItemManagement: isAdmin,
  }

  const [initialValuesForCreate, setInitialValuesForCreate] = useState(
    defaultCustomerDetails
  )

  const {
    customerUuid,
    loadingGetCustomerDetails,
    initialValuesEdit,
    isInvitationPending,
    setInitialValuesEdit,
  } = useGetCustomer(defaultCustomerDetails, isEditForm)

  const { data, isLoading: isCustomerLoading } =
    useGetCustomerDetails(customerUuid)

  const initialValues: CustomerDetailsForm = isEditForm
    ? initialValuesEdit
    : initialValuesForCreate

  const { cards, isCardsLoading, error, refetch } = useGetActiveAssignedCards({
    selectedUserId: customerUuid,
  })
  const cardIds = cards.map((card) => card.id)

  const {
    updateCustomer,
    loadingUpdateCustomer,
    submitUpdatedValuesForMFA,
    submitInitialValuesForMFA,
  } = useUpdateCustomer(
    initialValues,
    redirect,
    setIsPendingAssignSites,
    isUsers
  )

  const { deleteCustomer, loadingDeleteCustomer } = useDeleteCustomer({
    redirect,
    cardIds,
  })

  const { createCustomer, loadingCreateCustomer, submitValuesForMFA } =
    useCreateCustomer(
      redirect,
      setIsPendingAssignSites,
      setInitialValuesForCreate
    )

  useCreateAdminOnMFARedirect(createCustomer)
  useUpdateAdminOnMFARedirect(updateCustomer)

  const onSubmitCreateForm = useCallback(
    (values) => {
      submitValuesForMFA(values)
      submitCreateForm({
        createCustomer,
        values,
        isXeroPaymentServicesEnabled,
        entityUuid,
      })
    },
    [
      submitValuesForMFA,
      createCustomer,
      isXeroPaymentServicesEnabled,
      entityUuid,
    ]
  )

  const onSubmitEditForm = useCallback(
    (values) => {
      submitUpdatedValuesForMFA(values)
      submitInitialValuesForMFA(initialValues)
      setInitialValuesEdit({ ...initialValues, sites: values.sites })

      submitEditForm({
        initialValues,
        values,
        updateCustomer,
        isXeroPaymentServicesEnabled,
        entityUuid,
      })
    },
    [
      submitUpdatedValuesForMFA,
      submitInitialValuesForMFA,
      initialValues,
      setInitialValuesEdit,
      updateCustomer,
      isXeroPaymentServicesEnabled,
      entityUuid,
    ]
  )

  const isLoading =
    loadingGetCustomerDetails || loadingCreateCustomer || loadingUpdateCustomer

  return {
    customerUuid,
    deleteCustomer,
    loadingDeleteCustomer,
    customerDetails: data,
    isCustomerDetailsLoading: isCustomerLoading,
    initialValues,
    isInvitationPending,
    isLoading,
    cards,
    isCardsLoading,
    cardError: error,
    cardRefetch: refetch,
    isPendingAssignSites,
    onSubmitCreateForm,
    onSubmitEditForm,
    redirect,
  }
}
