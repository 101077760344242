import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import {
  AccountLayoutQuery as AccountLayoutQueryResponse,
  AccountLayoutQueryVariables,
} from 'types/gql-types/AccountLayoutQuery'

import { AccountLayoutQuery } from './graphql/AccountLayoutQuery'

interface UseAccountLayoutQueryProps {
  accountId: string
}

export const useAccountLayoutQuery = ({
  accountId,
}: UseAccountLayoutQueryProps) => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error } = useQuery<
    AccountLayoutQueryResponse,
    AccountLayoutQueryVariables
  >(AccountLayoutQuery, {
    variables: { accountId, entityUuid },
  })

  const account = useMemo(() => {
    return data?.getDebitCardAccountV2 || null
  }, [data?.getDebitCardAccountV2])

  const entity = useMemo(() => {
    return data?.getEntity
  }, [data?.getEntity])

  return { account, entity, isLoadingAccount: loading, error }
}
