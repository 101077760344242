import { useMemo } from 'react'
import {
  CustomerRole,
  DebitCardColour,
  DebitCardFormat,
  DebitCardProductType,
  DebitCardStatus,
} from '@npco/mp-gql-types'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useTranslations } from '@npco/utils-translations'
import { SelectItemBasic } from '@npco/zeller-design-system'
import { useGoToEditSpendControls } from 'features/EditSpendControls/edit-spend-controls-routing'

import { useGoToCancelCardStage } from 'hooks/useGoToCancelCardStage/useGoToCancelCardStage'
import { CancelCardStage } from 'hooks/useGoToCancelCardStage/useGoToCancelCardStage.types'
import { useGoToEditCardNameStage } from 'hooks/useGoToEditCardNameStage/useGoToEditCardNameStage'
import { EditCardNameStage } from 'hooks/useGoToEditCardNameStage/useGoToEditCardNameStage.types'
import { useGoToLockCardStage } from 'hooks/useGoToLockCardStage/useGoToLockCardStage'
import { LockCardStage } from 'hooks/useGoToLockCardStage/useGoToLockCardStage.types'
import { useGoToReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage'
import { ReportLostAndReplaceCardStage } from 'hooks/useGoToReportLostAndReplaceCardStage/useGoToReportLostAndReplaceCardStage.types'
import { useGoToSetPinStage } from 'hooks/useGoToSetPinStage/useGoToSetPinStage'

import { CardSingleManageDropdownDebitCardV2Fragment } from '../CardSingleManageDropdown.generated'
import { cardSingleManageDropdownTranslations } from '../CardSingleManageDropdown.i18n'
import { CardSingleActionType } from '../CardSingleManageDropdown.types'

type DebitCardCustomer = CardSingleManageDropdownDebitCardV2Fragment['customer']

export interface CardSingleManageDropdownOption extends SelectItemBasic {
  label: string
  value: CardSingleActionType
  isVisible: boolean
  onClick?: (cardId: string) => void
}

interface UseCardSingleManageDropdownOptionsProps {
  status: DebitCardStatus
  cardName: string
  cardOwner: DebitCardCustomer | null
  unlockCard: () => void
  isAccessible: boolean
  cardFormat: DebitCardFormat
  colour: DebitCardColour
  productType: DebitCardProductType
  velocityControl: CardSingleManageDropdownDebitCardV2Fragment['velocityControl']
  accountUuid: string
  entityAddressTimezone: string | null
}

export const useCardSingleManageDropdownOptions = ({
  cardName,
  status,
  colour,
  cardOwner,
  cardFormat,
  unlockCard,
  isAccessible,
  productType,
  velocityControl,
  accountUuid,
  entityAddressTimezone,
}: UseCardSingleManageDropdownOptionsProps) => {
  const { goToCancelCardStage } = useGoToCancelCardStage()
  const { goToLockCardStage } = useGoToLockCardStage()
  const { goToSetPinStage } = useGoToSetPinStage()
  const { goToReportLostAndReplaceCardStage } =
    useGoToReportLostAndReplaceCardStage()
  const { goToEditCardNameStage } = useGoToEditCardNameStage()
  const { goToEditSpendControls } = useGoToEditSpendControls()

  const t = useTranslations(cardSingleManageDropdownTranslations)

  const { userData } = useLoggedInUser()

  const isCardOwner = Boolean(
    userData?.id && cardOwner?.id && userData.id === cardOwner?.id
  )
  const isAdmin = userData?.role === CustomerRole.ADMIN

  const isCorporateCard = productType === DebitCardProductType.EXPENSE

  const cardSingleManageDropdownOptions: CardSingleManageDropdownOption[] =
    useMemo(() => {
      const isActionDisabled = [
        DebitCardStatus.CLOSED,
        DebitCardStatus.LOST,
        DebitCardStatus.SUSPENDED,
      ].includes(status)

      if (isActionDisabled) {
        return []
      }

      return [
        {
          label: t('editNickname'),
          value: CardSingleActionType.EditCardName,
          isVisible: isAdmin || isCardOwner,
          onClick: (cardId: string) => {
            goToEditCardNameStage({
              stage: EditCardNameStage.EditCardName,
              cardId,
              name: cardName,
            })
          },
        },
        {
          label: t('resetPin'),
          value: CardSingleActionType.ResetPin,
          isVisible: isCardOwner && !isAccessible,
          onClick: (cardId: string) => {
            goToSetPinStage({ stage: 'SetPin', cardId })
          },
        },
        {
          label: t('spendControls'),
          value: CardSingleActionType.SpendControls,
          isVisible: isCorporateCard && isAdmin,
          onClick: (cardUuid: string) =>
            goToEditSpendControls({
              stage: 'edit',
              cardUuid,
              accountUuid,
              amountLimit: velocityControl?.amountLimit.value,
              maxTransactionValue: velocityControl?.maxTransactionValue.value,
              velocityWindow: velocityControl?.velocityWindow,
              modifiedBy: velocityControl?.modifiedBy ?? undefined,
            }),
        },
        {
          label: t('lockCard'),
          value: CardSingleActionType.LockCard,
          isVisible: status !== DebitCardStatus.LOCKED,
          onClick: (cardId: string) => {
            goToLockCardStage({
              stage: LockCardStage.LockCard,
              cardId,
            })
          },
        },
        {
          label: t('unlockCard'),
          value: CardSingleActionType.UnlockCard,
          isVisible: status === DebitCardStatus.LOCKED,
          onClick: unlockCard,
        },
        {
          label: t('closeCard'),
          value: CardSingleActionType.CloseCard,
          isVisible: true,
          onClick: (cardId: string) => {
            goToCancelCardStage({
              stage: CancelCardStage.CancelCard,
              cardId,
            })
          },
        },
        {
          label: t('reportLost'),
          value: CardSingleActionType.ReportLost,
          isVisible: isAdmin || isCardOwner,
          onClick: (cardId: string) => {
            goToReportLostAndReplaceCardStage({
              stage: ReportLostAndReplaceCardStage.ReportLostCard,
              cardId,
              isAdmin,
              cardColor: colour,
              isCardOwner,
              cardOwnerFirstName: cardOwner?.firstname,
              cardFormat,
              velocityControl: velocityControl
                ? {
                    amountLimit: {
                      value: velocityControl.amountLimit.value,
                      currency: 'AUD',
                    },
                    maxTransactionValue: {
                      value: velocityControl.maxTransactionValue.value,
                      currency: 'AUD',
                    },
                    velocityWindow: velocityControl.velocityWindow,
                  }
                : null,
              entityAddressTimezone,
            })
          },
        },
      ].filter((option) => option.isVisible)
    }, [
      cardName,
      status,
      colour,
      cardOwner,
      cardFormat,
      accountUuid,
      goToEditCardNameStage,
      goToReportLostAndReplaceCardStage,
      goToCancelCardStage,
      goToLockCardStage,
      goToSetPinStage,
      goToEditSpendControls,
      isCardOwner,
      isAdmin,
      isCorporateCard,
      unlockCard,
      isAccessible,
      velocityControl,
      t,
      entityAddressTimezone,
    ])

  return {
    cardSingleManageDropdownOptions,
  }
}
