import React, { ReactNode } from 'react'

import { useFetchUsers } from 'hooks/useFetchUsers'
import { SpinnerWrapped } from 'components/Spinner'

interface Props {
  children: ReactNode
}

export const UsersDetailFetcher = ({ children }: Props) => {
  const { loading } = useFetchUsers()

  return <>{loading ? <SpinnerWrapped variant="centre" /> : children}</>
}
