import { useCallback } from 'react'
import { GetInvoicesStatusFilterInput, InvoiceStatus } from '@npco/mp-gql-types'
import { MultiSelectItem } from 'design-system/Components/MultiSelect/MultiSelectItem/MultiSelectItem'
import { PartialFiltersStatusLabel } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTable.utils'
import { StatusWrapper } from 'features/Invoicing/components/Invoices/InvoiceTable/InvoiceTableFilters/StatusFilters/StatusFilters.styled'

const isInvoiceStatus = (value: string): value is InvoiceStatus =>
  Object.values(InvoiceStatus).includes(value as InvoiceStatus)

interface StatusFiltersListProps {
  selectedStatuses: InvoiceStatus[]
  setSelectedStatuses: (value: InvoiceStatus[]) => void
  setStatusFilter: (value: GetInvoicesStatusFilterInput | null) => void
}

export const StatusFiltersList = ({
  selectedStatuses,
  setSelectedStatuses,
  setStatusFilter,
}: StatusFiltersListProps) => {
  const handleCheckboxClick = useCallback(
    ({ value, isChecked }: { value: InvoiceStatus; isChecked: boolean }) => {
      if (isChecked) {
        setSelectedStatuses(
          selectedStatuses.filter((item: InvoiceStatus) => item !== value)
        )
        setStatusFilter({
          values: selectedStatuses.filter(
            (item: InvoiceStatus) => item !== value
          ),
        })
      } else {
        setSelectedStatuses([...selectedStatuses, value])
        setStatusFilter({
          values: [...selectedStatuses, value],
        })
      }
    },
    [selectedStatuses, setSelectedStatuses, setStatusFilter]
  )

  return (
    <StatusWrapper>
      {Object.entries(PartialFiltersStatusLabel).map(([status, label]) => {
        if (!isInvoiceStatus(status)) {
          return null
        }

        const isChecked = selectedStatuses.includes(status)

        return (
          <MultiSelectItem
            data-testid={label}
            id={label}
            isChecked={isChecked}
            item={{ label, value: status }}
            key={label}
            label={label}
            onClick={() => handleCheckboxClick({ value: status, isChecked })}
            role="option"
          />
        )
      })}
    </StatusWrapper>
  )
}
