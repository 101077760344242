import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { showApiErrorToast } from '@npco/zeller-design-system'

import { XeroAccountWithSelected } from '../Components/AccountSelect.types'
import { onlyActiveAccounts } from '../xeroBankFeeds.utils'
import {
  XeroAccountsQuery,
  XeroAccountsQueryResponse,
  XeroAccountsQueryVariables,
} from './useXeroAccounts.generated'
import { isAccountSelected } from './useXeroAccounts.utils'

export const useXeroAccounts = () => {
  const entityUuid = useSelectedEntityUuid()
  const { data, loading, error } = useQuery<
    XeroAccountsQueryResponse,
    XeroAccountsQueryVariables
  >(XeroAccountsQuery, {
    variables: { entityUuid },
    fetchPolicy: 'network-only',
    onError: (err) => showApiErrorToast(err),
  })

  const [accounts, selectedAccountIds] = useMemo(() => {
    const activeAccounts: XeroAccountWithSelected[] = onlyActiveAccounts(
      data?.getDebitCardAccountsV2.accounts ?? []
    )
      .filter(Boolean)
      .map((account) => {
        return {
          ...account,
          selected: isAccountSelected(
            account,
            data?.getXeroBankfeedsConfiguration.bankAccounts
          ),
        } as XeroAccountWithSelected
      })

    const selectedIds = activeAccounts
      .filter((account) => account.selected)
      .map((account) => account.id)
    return [activeAccounts, selectedIds]
  }, [data])

  return {
    loading,
    error,
    data: {
      accounts,
      selectedAccountIds,
      connection: {
        status: data?.getConnectionState.status,
        errorType: data?.getConnectionState.errorType || undefined,
      },
      organisationName: data?.getXeroBankfeedsConfiguration?.organisationName,
    },
  }
}

useXeroAccounts.gql = XeroAccountsQuery
