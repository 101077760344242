import { ErrorLogger } from '@npco/utils-error-logger'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast } from '@npco/zeller-design-system'

import { mapReferenceToProperties } from 'pages/Transfer/BPay/bpay-transfer-modal/BPayModal.utils'
import { useTransferQuery } from 'pages/Transfer/hooks/useTransferQuery/useTransferQuery'
import {
  ContactOrBillerType,
  TransferFieldTypes,
} from 'pages/Transfer/Transfer.types'
import { translationsShared } from 'translations/index'

import { BPaySchema } from '../../BPayTransfer.types'
import { useBPayTransferModal } from '../useBPayTransferModal/useBPayTransferModal'

export const useOpenBPayTransferModal = () => {
  const { openBPayTransferModal } = useBPayTransferModal()
  const tShared = useTranslations(translationsShared)

  const openBPayModal = ({
    values,
    accountById,
  }: {
    values: TransferFieldTypes
    accountById: ReturnType<typeof useTransferQuery>['accountById']
  }) => {
    const parseResult = BPaySchema.safeParse(values)
    const bPayValues = parseResult.success ? parseResult.data : undefined
    const fromAccount = accountById(bPayValues?.from)
    const res = mapReferenceToProperties(bPayValues?.reference)

    if (!bPayValues || !fromAccount) {
      ErrorLogger.report('[Banking] Failed to open bpay transfer modal')
      showErrorToast(tShared('somethingWentWrong'))
      return
    }
    openBPayTransferModal({
      stage: 'summary',
      transferDetails: {
        amount: bPayValues?.amount,
        from: {
          id: fromAccount?.id,
          name: fromAccount?.name,
          accountNumber: fromAccount?.accountDetails?.account ?? '',
          icon: fromAccount?.icon,
        },
        to: {
          id: bPayValues?.to?.id,
          type: bPayValues?.to?.type as ContactOrBillerType,
          name: bPayValues?.to?.name,
          code: bPayValues?.to?.code,
          icon: bPayValues?.to?.icon ?? null,
          billerContacts: bPayValues?.to?.billerContacts,
          email: bPayValues?.to?.email,
          phone: bPayValues?.to?.phone,
          phoneV2: bPayValues?.to?.phoneV2,
        },
        nickname: bPayValues?.nickname,
        crn: bPayValues?.crn,
        paymentInstrumentUuid: {
          id: bPayValues?.paymentInstrumentUuid?.id,
          type: bPayValues?.paymentInstrumentUuid?.type,
          label: bPayValues?.paymentInstrumentUuid?.label,
          subLabel1:
            typeof bPayValues?.paymentInstrumentUuid?.subLabel1 === 'string'
              ? bPayValues?.paymentInstrumentUuid?.subLabel1
              : '',
          subLabel2: bPayValues?.paymentInstrumentUuid?.subLabel2,
          value: bPayValues?.paymentInstrumentUuid?.value,
        },
        reference: res,
      },
    })
  }

  return { openBPayModal }
}
