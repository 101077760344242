import { useCallback } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { showSuccessToast } from '@npco/zeller-design-system'
import { rvContacts } from 'features/Contacts/rv-deprecated/contacts'

import { UNEXPECTED_ERROR } from 'types/errors'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'

import { DebitCardTransaction } from '../../../../TransactionDrawer.types'
import { translations } from '../UnlinkedContactDetails.i18n'
import { useLinkContact } from './useLinkContact'

interface UseTransactionTransferInContactArgs {
  updateTransaction: (update: Partial<DebitCardTransaction>) => void
  debitCardTransactionUuid: string
}

export const useTransactionTransferInContact = ({
  updateTransaction,
  debitCardTransactionUuid,
}: UseTransactionTransferInContactArgs) => {
  const { linkContact, isLinkingContact, error } = useLinkContact()
  const t = useTranslations(translations)

  const handleLinkContact = useCallback(
    async ({
      cardholderOrSenderUuid: senderUuid,
      contactUuid,
    }: {
      cardholderOrSenderUuid: string
      contactUuid: string
    }) => {
      // Taking shortcut. Cannot use useGetContact.
      // If we don't do this, we need to refactor the form.
      const contactFromRv = rvContacts()?.[contactUuid]

      const result = await linkContact({
        contactUuid,
        senderUuid,
        contact: contactFromRv,
        transactionUuid: debitCardTransactionUuid,
      })

      if (result === UNEXPECTED_ERROR) {
        return
      }

      if (!contactFromRv) {
        // Updating id even if there is no contact in rvContacts just in case.
        updateTransaction({
          contact: {
            id: contactUuid,
            businessName: null,
            contactType: ContactType.BUSINESS,
            firstName: null,
            lastName: null,
            icon: null,
            category: null,
            isSelf: null,
          },
        })
      } else {
        const {
          businessName,
          contactType,
          firstName,
          lastName,
          icon,
          category,
          isSelf,
        } = contactFromRv

        // Update reactive variable
        updateTransaction({
          contact: {
            id: contactUuid,
            businessName,
            contactType,
            firstName,
            lastName,
            icon,
            category,
            isSelf,
          },
        })
      }

      showSuccessToast(t('successMessage'))
    },
    [linkContact, t, updateTransaction, debitCardTransactionUuid]
  )

  const handleOnContactCreateAndLink = useCallback(
    async (senderUuid: string, newContact: CreateContact) => {
      const {
        id,
        businessName,
        contactType,
        firstName,
        lastName,
        category,
        isSelf,
      } = newContact

      const contact = { ...newContact, icon: null }
      const result = await linkContact({
        contactUuid: newContact.id,
        senderUuid,
        contact,
        transactionUuid: debitCardTransactionUuid,
      })

      if (result === UNEXPECTED_ERROR) {
        return
      }
      // Update reactive variable
      updateTransaction({
        contact: {
          id,
          businessName,
          contactType,
          firstName,
          lastName,
          icon: null,
          category,
          isSelf,
        },
      })

      showSuccessToast(t('successMessage'))
    },
    [linkContact, t, debitCardTransactionUuid, updateTransaction]
  )

  return {
    handleLinkContact,
    handleOnContactCreateAndLink,
    hasLinkContactError: Boolean(error),
    isLinkingContactWithSender: isLinkingContact,
  }
}
