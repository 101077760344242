import { useTranslations } from '@npco/utils-translations'
import { Box, ButtonLink, Flex } from '@npco/zeller-design-system'

import { CreateReviewCardState } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { translationsShared } from 'translations'

import { Item } from '../Item'
import { Title } from '../ReviewCard.styled'
import { cardDetailsReviewTranslations } from './CardDetailsReview.i18n'
import { useCardDetailsReviewDisplayValue } from './hooks/useCardDetailsReviewDisplayValue'

interface CardDetailsReviewProps {
  onEdit: () => void
  state: CreateReviewCardState
  isLoading: boolean
}

export const CardDetailsReview = ({
  onEdit,
  state,
  isLoading,
}: CardDetailsReviewProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(cardDetailsReviewTranslations)
  const {
    cardTypeDisplayValue,
    linkedAccountDisplayValue,
    cardNicknameDisplayValue,
    cardholderDisplayValue,
  } = useCardDetailsReviewDisplayValue({ state })

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Title>{t('detailsTitle')}</Title>
        <ButtonLink onClick={onEdit} disabled={isLoading}>
          {tShared('edit')}
        </ButtonLink>
      </Flex>
      <Item label={t('cardholderLabel')} value={cardholderDisplayValue} />
      <Item label={t('nicknameLabel')} value={cardNicknameDisplayValue} />
      <Item label={t('typeLabel')} value={cardTypeDisplayValue} />
      <Item label={t('accountLabel')} value={linkedAccountDisplayValue} />
    </Box>
  )
}
