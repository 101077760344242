import {
  BodyDefault,
  BodyLarge,
  BodyLargeTypography,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledCopyWrapper = styled.div`
  max-width: 33.75rem;
  margin-bottom: 2rem;

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    min-width: 19.5rem;
    width: 100%;
  }
`

export const StyledCopy = styled(BodyLarge)`
  margin: 0;
`

export const StyledCopySmall = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledResendButton = styled.button`
  background-color: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.BLUE_1000};
  border: none;
  outline: none;
  cursor: pointer;
  font: inherit;
  ${BodyLargeTypography}
  padding: 1px 0;
`

export const StyledSubTitle = styled(BodyDefault)`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.GREY_550};
`
