import { useTranslations } from '@npco/utils-translations'
import {
  AccordionComplex,
  Divider,
  InfoBox,
  INFOBOX_VARIANT,
} from '@npco/zeller-design-system'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { InvoiceAccordionCommonProps } from '../../InvoiceFormAccordions.types'
import { InvoiceAccordionContentWrapper } from '../InvoiceAccordionContentWrapper/InvoiceAccordionContentWrapper'
import { InvoiceDeliveryEmail } from './components/InvoiceDeliveryEmail/InvoiceDeliveryEmail'
import { InvoiceDeliverySMS } from './components/InvoiceDeliverySMS/InvoiceDeliverySMS'
import { useInvoiceEmailSection } from './hooks/useInvoiceEmailSection'
import { useInvoiceSMSSection } from './hooks/useInvoiceSMSSection'
import { translations } from './InvoiceDeliveryAccordion.i18n'
import {
  getAccordionDetails,
  getAccordionFormState,
} from './InvoiceDeliveryAccordion.utils'

type InvoiceDeliveryAccordionProps = {
  onEditCustomerClick: () => void
} & InvoiceAccordionCommonProps

export const InvoiceDeliveryAccordion = ({
  isExpanded,
  onChange,
  onEditCustomerClick,
}: InvoiceDeliveryAccordionProps) => {
  const t = useTranslations(translations)

  useInvoiceEmailSection({
    isExpanded,
  })

  useInvoiceSMSSection({ isExpanded })

  const {
    errors: { delivery: deliveryErrors },
    touched,
    values: { delivery: deliveryValues },
  } = useFormikContext<InvoiceFormFields>()

  const details = getAccordionDetails({
    values: deliveryValues,
    errors: deliveryErrors,
    fallbackText: t('accordionDetails'),
  })

  return (
    <AccordionComplex
      dataTestId="invoice-email"
      details={details}
      formItemState={getAccordionFormState({
        errors: deliveryErrors,
        touched: touched.delivery,
        values: deliveryValues,
      })}
      isExpanded={isExpanded}
      onChange={onChange}
      title={t('accordionTitle')}
    >
      <InvoiceAccordionContentWrapper paddingBottom="12px">
        <InvoiceDeliveryEmail onEditCustomerClick={onEditCustomerClick} />

        <Divider margin="12px 0" />

        <InvoiceDeliverySMS onEditCustomerClick={onEditCustomerClick} />

        {typeof deliveryErrors === 'string' && (
          <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
            <InfoBox.Message>{deliveryErrors}</InfoBox.Message>
          </InfoBox>
        )}
      </InvoiceAccordionContentWrapper>
    </AccordionComplex>
  )
}
