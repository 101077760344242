import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import {
  BREAKPOINT,
  ButtonFill,
  ButtonLink,
  COLOR,
  Flex,
  ModalCMS,
} from '@npco/zeller-design-system'
import { ProductLaunchModalCMSProps } from 'features/StartupModals/CMSProductLaunchModal/ProductLaunchModalCMS.types'
import { StoryblokPLMProps } from 'features/StoryblokContent/Storyblok.types'

import { useImagePreloader } from 'hooks/useImagePreloader/useImagePreloader'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { AnalyticsEventNames } from 'services/Analytics/events'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { Illustration } from '../SetupFlow/components/Illustration/Illustration'
import { useMarketingModalVisibility } from './hooks/useMarketingModalVisibility'

export const ProductLaunchModalCMS = ({
  onClick,
  productLaunchModalContent,
  slug = '',
}: ProductLaunchModalCMSProps) => {
  const navigate = useNavigate()
  const { hideModal } = useMarketingModalVisibility(slug)
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.XS + 1)
  const isTabletResolution = useIsMobileResolution(BREAKPOINT.SM + 1)
  const [isOpen, setIsOpen] = useState(true)
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  useEffect(() => {
    trackAnalyticsEvent(AnalyticsEventNames.CMS_MODAL_VIEW, { slug })
  }, [slug, trackAnalyticsEvent])

  const {
    checklist_items: checklistItems,
    mobile_checklist_items: mobileChecklistItems,
    close_button_text: closeButtonText,
    call_to_action_link_url: callToActionLinkUrl,
    call_to_action_text: callToActionText,
    desktop_image: desktopImage,
    mobile_image: mobileImage,
    tablet_image: tabletImage,
    desktop_animation: desktopAnimation,
    mobile_animation: mobileAnimation,
    tablet_animation: tabletAnimation,
    hide_checklist_mobile: hideChecklistMobile,
    ...rest
  } = productLaunchModalContent || ({} as StoryblokPLMProps)

  const desktopPLMImage = desktopImage?.filename as string
  const mobilePLMImage = mobileImage?.filename as string
  const tabletPLMImage = tabletImage?.filename as string

  const { imagesPreloaded } = useImagePreloader([
    desktopPLMImage,
    mobilePLMImage,
    tabletPLMImage,
  ])

  const responsiveAnimationData = isMobileResolution
    ? mobileAnimation
    : desktopAnimation

  useEffect(() => {
    hideModal()
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const bulletPoints = useMemo(() => {
    const listItems =
      isMobileResolution && mobileChecklistItems
        ? mobileChecklistItems
        : checklistItems
    return listItems?.split('|').filter(Boolean) || []
  }, [checklistItems, isMobileResolution, mobileChecklistItems])

  const closeModal = useCallback(() => {
    trackAnalyticsEvent(AnalyticsEventNames.CMS_MODAL_CLOSE, {
      slug,
    })
    setIsOpen(false)
  }, [slug, trackAnalyticsEvent])

  const onPrimaryButtonClick = () => {
    if (callToActionLinkUrl?.url?.startsWith('/')) {
      navigate(callToActionLinkUrl?.url)
      return
    }
    window.open(callToActionLinkUrl?.url, '_blank', 'noopener,noreferrer')
  }

  if (!imagesPreloaded) {
    return null
  }

  return (
    <ModalCMS.CustomizedModal
      className="animated-content"
      closeTimeoutMS={350}
      isOpen={isOpen}
      onCancel={closeModal}
      overlayClassName="animated-overlay"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <ModalCMS.Tutorial.TutorialLayout
        Footer={
          <Flex
            justifyContent="space-between"
            width="100%"
            padding={{
              _: '12px 24px 24px',
              SM: '12px 32px 32px',
              LG: '12px 0 0 0',
            }}
            backgroundColor={COLOR.WHITE}
          >
            <ButtonLink onClick={closeModal}>{closeButtonText}</ButtonLink>
            <ButtonFill
              onClick={(e) => {
                closeModal()
                trackAnalyticsEvent(
                  AnalyticsEventNames.CMS_MODAL_CALL_TO_ACTION_LINK,
                  {
                    slug,
                    actionUrl: callToActionLinkUrl?.url as string,
                  }
                )

                if (onClick) {
                  onClick(e)
                  return
                }

                onPrimaryButtonClick()
              }}
            >
              {callToActionText}
            </ButtonFill>
          </Flex>
        }
        Illustration={
          responsiveAnimationData || tabletAnimation ? (
            <Suspense
              fallback={
                <Illustration
                  imageSmall={mobilePLMImage}
                  imageMedium={tabletPLMImage}
                  imageLarge={desktopPLMImage}
                />
              }
            >
              <ModalCMS.Illustration
                animationData={
                  isTabletResolution ? tabletAnimation : responsiveAnimationData
                }
              />
            </Suspense>
          ) : (
            <Illustration
              imageSmall={mobilePLMImage}
              imageMedium={tabletPLMImage}
              imageLarge={desktopPLMImage}
            />
          )
        }
        MainContent={
          <ModalCMS.MainContent
            bulletPoints={bulletPoints}
            mainCMSContent={{
              hide_checklist_mobile: hideChecklistMobile,
              ...rest,
            }}
            hideChecklist={isMobileResolution && hideChecklistMobile}
          />
        }
      />
    </ModalCMS.CustomizedModal>
  )
}
