import { useCallback } from 'react'
import { gql } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'
import { Table, TableData, useTable } from '@npco/ui-table'
import { BREAKPOINT, useIsMobileResolution } from '@npco/zeller-design-system'
import { Row } from '@tanstack/react-table'

import {
  AverageCell,
  CategoryCell,
  QuantityCell,
  TotalCell,
} from '../../TableCells/TableRowCells'
import { NetAmountWithOtherCategory } from '../../TransactionsSummary.types'
import { CategoriesTableCashFlowCategoryNetAmountFragment as CategoryNetAmount } from './CategoriesTable.generated'
import { columnsConfig } from './CategoriesTableConfig'

type CategoryTableData = TableData<
  NetAmountWithOtherCategory<CategoryNetAmount>
>

interface CategoriesTableProps {
  netAmounts: NetAmountWithOtherCategory<CategoryNetAmount>[]
  isLoading?: boolean
  transactionDirection: 'income' | 'expense'
  expandOtherCategories: () => void
  openDrawer: () => void
  onCategoryHovered: (
    netAmount: NetAmountWithOtherCategory<CategoryNetAmount>
  ) => void
  onCategoryUnhovered: (
    netAmount: NetAmountWithOtherCategory<CategoryNetAmount>
  ) => void
  setSelectedCategory: (category: Types.EntityCategories | null) => void
}

export const CategoriesTable = ({
  netAmounts,
  isLoading = false,
  transactionDirection,
  expandOtherCategories,
  openDrawer,
  onCategoryHovered,
  onCategoryUnhovered,
  setSelectedCategory,
}: CategoriesTableProps) => {
  const isMobileOrTablet = useIsMobileResolution(BREAKPOINT.MD)

  const { table } = useTable<NetAmountWithOtherCategory<CategoryNetAmount>>({
    columnsConfig,
    isLoading,
    listData: netAmounts,
    meta: {
      transactionDirection,
    },
  })

  const handleRowClick = useCallback(
    (row: Row<CategoryTableData>) => {
      if (row.original.data.category === 'other') {
        expandOtherCategories()
        return
      }
      setSelectedCategory(row.original.data.category)
      openDrawer()
    },
    [expandOtherCategories, openDrawer, setSelectedCategory]
  )

  return (
    <Table<CategoryTableData>
      hideHeader={false}
      table={table}
      hasBorderBottom={false}
      hasLastTrBorderBottom={false}
      headBottomSpace={isMobileOrTablet ? '16px' : '40px'}
      onRowClick={handleRowClick}
      onRowMouseEnter={(row) => onCategoryHovered(row.original.data)}
      onRowMouseLeave={(row) => onCategoryUnhovered(row.original.data)}
    />
  )
}

CategoriesTable.fragments = {
  CashFlowCategoryNetAmount: gql`
    fragment CategoriesTableCashFlowCategoryNetAmountFragment on CashFlowCategoryNetAmount {
      __typename
      ...QuantityCellCashFlowCategoryNetAmountFragment
      ...AverageCellCashFlowCategoryNetAmountFragment
      ...TotalCellCashFlowCategoryNetAmountFragment
      ...CategoryCellCashFlowCategoryNetAmountFragment
    }

    ${QuantityCell.fragments.CashFlowCategoryNetAmount}
    ${AverageCell.fragments.CashFlowCategoryNetAmount}
    ${TotalCell.fragments.CashFlowCategoryNetAmount}
    ${CategoryCell.fragments.CashFlowCategoryNetAmount}
  `,
}
