import { Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledNavbar = styled(Flex)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 1px 0 ${({ theme }) => theme.colors.GREY_150};
  z-index: 11;

  @media screen and (max-width: ${BREAKPOINT.MD - 1}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
