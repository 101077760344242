import { gql } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { RadioGroupFormik } from '@npco/zeller-design-system'

import { CardholderListCustomerFragment } from './CardholderList.generated'
import { CardholderRadioOption, fragments } from './CardholderRadioOption'
import { CardholderLoadingPage } from './placeholders/CardholderLoadingPage/CardholderLoadingPage'
import { CardholderNoResultsPage } from './placeholders/CardholderNoResultsPage/CardholderNoResultsPage'

interface CardholderListProps {
  customers: CardholderListCustomerFragment[]
  isLoading: boolean
  searchText: string
}

export const CardholderList = ({
  customers,
  isLoading,
  searchText,
}: CardholderListProps) => {
  const { userData } = useLoggedInUser()

  if (isLoading) {
    return <CardholderLoadingPage />
  }

  if (customers.length === 0) {
    return <CardholderNoResultsPage searchText={searchText} />
  }

  return (
    <RadioGroupFormik name="cardholderId" isVerticalDisplay>
      {customers.map((customer) => (
        <CardholderRadioOption
          key={customer.id}
          value={customer.id}
          cardholder={customer}
          isCurrentUser={userData?.id === customer.id}
        />
      ))}
    </RadioGroupFormik>
  )
}

CardholderList.fragments = {
  Customer: gql`
    fragment CardholderListCustomerFragment on Customer {
      id
      entityUuid
      ...CardholderRadioOptionCustomerFragment
    }
    ${fragments.Customer}
  `,
}
