import { useCallback } from 'react'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '@npco/utils-session-storage'

export const UTM_SOURCE = 'utm_source'
export const UTM_MEDIUM = 'utm_medium'

export const useUtmSourceAndMedium = () => {
  const getUtmSourceAndMedium = useCallback(() => {
    const utmSource = getSessionStorageItem<string | null | undefined>(
      UTM_SOURCE
    )
    const utmMedium = getSessionStorageItem<string | null | undefined>(
      UTM_MEDIUM
    )
    return {
      utmSource,
      utmMedium,
    }
  }, [])
  const setUtmSourceAndMedium = useCallback(
    (utmSource: string | null, utmMdium: string | null) => {
      setSessionStorageItem(UTM_SOURCE, utmSource)
      setSessionStorageItem(UTM_MEDIUM, utmMdium)
    },
    []
  )

  return {
    getUtmSourceAndMedium,
    setUtmSourceAndMedium,
  }
}
