import React from 'react'
import { gql } from '@apollo/client'
import { useAccountNavigation } from '@npco/mp-utils-accounts-routing'
import { Avatar } from '@npco/ui-avatar'
import { getColorFromResponse } from '@npco/utils-colors'
import { ListCardButton, ListCardVariant } from '@npco/zeller-design-system'

import { AVATAR_BADGE_SIZE } from 'types/common'

import { AccountWithAvatarDebitCardAccountV2Fragment } from './AccountWithAvatar.generated'

interface AccountWithAvatarProps {
  account: AccountWithAvatarDebitCardAccountV2Fragment
}

export const AccountWithAvatar = ({ account }: AccountWithAvatarProps) => {
  const { navigateToAccountTransactions, canNavigate } = useAccountNavigation()

  const avatar = React.useMemo(() => {
    return (
      <Avatar
        text={account.icon?.letter || account.name}
        color={getColorFromResponse(account.icon?.colour)}
        badgeSize={AVATAR_BADGE_SIZE.XXSMALL}
      />
    )
  }, [account.icon, account.name])

  return (
    <ListCardButton
      image={() => avatar}
      title={account.name}
      onClick={() =>
        navigateToAccountTransactions({
          accountId: account.id,
        })
      }
      readonly={!canNavigate}
      variant={ListCardVariant.OnGrey}
    />
  )
}

AccountWithAvatar.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountWithAvatarDebitCardAccountV2Fragment on DebitCardAccountV2 {
      __typename
      id
      name
      icon {
        colour
        letter
      }
    }
  `,
}
