import { ContactType } from '@npco/mp-gql-types'
import { Box, Heading } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import { CreateContacts_createContacts as CreateContactsContact } from 'types/gql-types/CreateContacts'
import { page } from 'translations'

import {
  AddSubContact,
  ContactForm,
} from '../components/ContactForm/ContactForm'
import { ContactFormData } from '../Contacts.types'
import { initialValues as defaultInitialValues } from '../Contacts.utils'
import { ContactCoreFieldsFragment } from '../graphql/ContactCoreFields.generated'
import { useContactFormHelpers } from '../hooks/useContactFormHelpers/useContactFormHelpers'

interface PersonFormProps {
  closeModal: () => void
  initialValues?: ContactFormData
  isModalOpen: boolean
  onCreate?: (
    contact: CreateContact | CreateContactsContact,
    subContact?: ContactCoreFieldsFragment | null
  ) => void
  renderBottomSection?: (props: { values: ContactFormData }) => void
  title?: string
}

export const PersonForm = ({
  closeModal,
  initialValues = defaultInitialValues,
  isModalOpen,
  onCreate,
  renderBottomSection,
  title = page.contacts.form.title.person,
}: PersonFormProps) => {
  const {
    isLoading,
    handleSubmit,
    existingBusinessContactName,
    existingPersonContactName,
    setExistingBusinessContactName,
    setExistingPersonContactName,
  } = useContactFormHelpers({
    onCreate,
    contactType: ContactType.PERSON,
  })

  const defaultRenderBottomSection = () => (
    <AddSubContact
      existingBusinessContactName={existingBusinessContactName}
      existingPersonContactName={existingPersonContactName}
    />
  )

  return (
    <ContactForm
      closeModal={closeModal}
      contactType={ContactType.PERSON}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      title={title}
      existingBusinessContactName={existingBusinessContactName}
      existingPersonContactName={existingPersonContactName}
      renderBottomSection={renderBottomSection ?? defaultRenderBottomSection}
      renderTopSection={() => (
        <Box mb="1rem">
          <Heading.H4>
            {translate('page.contacts.form.personalDetails')}
          </Heading.H4>
        </Box>
      )}
      setExistingBusinessContactName={setExistingBusinessContactName}
      setExistingPersonContactName={setExistingPersonContactName}
    />
  )
}
