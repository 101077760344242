import { useEffect } from 'react'
import { SimBillingStatementExportFormat } from '@npco/mp-gql-types'

import { useExportSimBills } from 'pages/Settings/Sim/BillingHistory/hooks/useExportSimBills'

interface Props {
  closeModal: () => void
  filename: string
  format: SimBillingStatementExportFormat
  closeModalDelay?: number
}

const CLOSE_MODAL_TIMEOUT = 3000

export const useExportSimBillsModal = ({
  closeModal,
  filename,
  format,
  closeModalDelay,
}: Props) => {
  const { exportFile, isLoading, hasError, hasLoaded } = useExportSimBills(
    filename,
    format
  )

  useEffect(() => {
    exportFile()
    // componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (!isLoading && !hasError && hasLoaded) {
      timeoutId = setTimeout(() => {
        closeModal()
      }, closeModalDelay ?? CLOSE_MODAL_TIMEOUT)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoading, closeModal, hasError, hasLoaded, closeModalDelay])

  return {
    exportFile,
    isLoading,
    hasError,
    hasLoaded,
  }
}
