import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  BoatSinking,
  Box,
  Flex,
  MessageSize,
  MessageWithIcon,
} from '@npco/zeller-design-system'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'

import { translations } from './ErrorPage.i18n'
import * as styled from './ErrorPage.styled'

export const ErrorPage = () => {
  const t = useTranslations(translations)
  const isMobile = useIsMobileResolution()
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  return (
    <Flex
      alignItems={{ _: 'flex-start', XS: 'center' }}
      height="100%"
      justifyContent="content"
      mx="auto"
      maxWidth="600px"
      textAlign="center"
    >
      <MessageWithIcon
        size={MessageSize.LARGE}
        title={t('title')}
        image={<BoatSinking size={isMobile ? 'medium' : 'large'} />}
        description={
          <Box textAlign="center">
            {t('description1')}
            <br />
            {t('description2', {
              link: (
                <styled.ContactSupportButtonLink
                  onClick={() =>
                    navigate(ROOT.ORGS.ORG(shortEntityId).HELP.path)
                  }
                >
                  {t('linkText')}
                </styled.ContactSupportButtonLink>
              ),
            })}
          </Box>
        }
        primaryButton={{
          label: t('buttonLabel'),
          onClick: () => navigate(0),
        }}
      />
    </Flex>
  )
}
