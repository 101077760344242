import { useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'
import type { FormikHelpers } from 'formik'

import { useIsInvoicingEnabled } from 'hooks/invoices/useIsInvoicingEnabled'
import { useCustomerProductTourStatus } from 'hooks/useCustomerProductTourStatus'

import { useGetInvoicePreview } from '../../../Invoices/Invoice/hooks/useGetInvoicePreview'
import { useInvoiceSettingsAnalytics } from '../../hooks/useInvoiceSettingsAnalytics'
import { translations } from '../SettingsCustomisation.i18n'
import { InvoiceSettingsCustomisationFormFields } from '../SettingsCustomisation.types'
import {
  getDefaultInvoiceSettingsCustomisationFormValues,
  getInvoicePreviewCustomisationInput,
} from '../SettingsCustomisation.utils'
import { useUpdateSettingsCustomisation } from './useUpdateSettingsCustomisation'

export const useSettingsCustomisation = () => {
  const t = useTranslations(translations)
  const isInvoicingEnabled = useIsInvoicingEnabled()
  const { invoicePdf, invoicePdfError, isLoadingPreview, getPreview } =
    useGetInvoicePreview({
      isCustomisationPreview: true,
    })

  const { trackCustomisationSettingsUpdated } = useInvoiceSettingsAnalytics()
  const {
    invoiceSettings,
    isLoadingSettings,
    isUpdatingZellerInvoiceSettings,
    updateSettingsCustomisation,
  } = useUpdateSettingsCustomisation({
    onSuccess: () => showSuccessToast(t('successNotification')),
    onError: () => showErrorToast(t('errorNotification')),
  })

  const { showInvoicingCustomisationSettingsWelcome, updateProductTourStatus } =
    useCustomerProductTourStatus()

  /* previewValues represents the form state and is used to generate the preview */
  const [previewValues, setPreviewValues] = useState<
    InvoiceSettingsCustomisationFormFields | undefined
  >(
    !invoiceSettings
      ? undefined
      : getDefaultInvoiceSettingsCustomisationFormValues(
          invoiceSettings.invoice.customisation
        )
  )

  /* set preview values when invoiceSettings is loaded */
  useEffect(() => {
    if (!previewValues && invoiceSettings) {
      setPreviewValues(
        getDefaultInvoiceSettingsCustomisationFormValues(
          invoiceSettings?.invoice.customisation
        )
      )
    }
  }, [invoiceSettings, previewValues])

  /* get new preview when previewValues changes */
  useEffect(() => {
    if (previewValues && isInvoicingEnabled) {
      const address = invoiceSettings?.invoice.pdfIncludesAddress
        ? invoiceSettings?.address
        : null

      getPreview(
        getInvoicePreviewCustomisationInput({
          address,
          previewValues,
          receiptSettings: invoiceSettings?.receipt,
          t,
        })
      )
    }
  }, [
    isInvoicingEnabled,
    getPreview,
    invoiceSettings?.address,
    invoiceSettings?.invoice.pdfIncludesAddress,
    invoiceSettings?.receipt,
    previewValues,
    t,
  ])

  const handleFormSubmit = async (
    values: InvoiceSettingsCustomisationFormFields,
    { resetForm }: FormikHelpers<InvoiceSettingsCustomisationFormFields>
  ) => {
    updateSettingsCustomisation(values, { resetForm })

    if (showInvoicingCustomisationSettingsWelcome) {
      updateProductTourStatus({
        showInvoicingCustomisationSettingsWelcome: false,
      })
    }

    trackCustomisationSettingsUpdated(
      getDefaultInvoiceSettingsCustomisationFormValues(
        invoiceSettings?.invoice.customisation
      ),
      values
    )
  }

  return {
    isInvoicingEnabled,
    isLoadingSettings,
    isLoadingPreview,
    isUpdatingZellerInvoiceSettings,
    handleFormSubmit,
    previewValues,
    invoicePdf,
    invoicePdfError,
    setPreviewValues,
  }
}

export type UseSettingsCustomisationReturnType = ReturnType<
  typeof useSettingsCustomisation
>
