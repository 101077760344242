import { StyledTableEffect } from '@npco/ui-table'
import { Title } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const DepositRow = styled.div<{ isUpdated: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.75rem 0.375rem;

  ${({ isUpdated }) =>
    isUpdated &&
    css`
      animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
    `}
  ${StyledTableEffect}
`

export const HighlightContentWrapper = styled.div<{ isUpdated: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  column-gap: 10px;

  ${({ isUpdated, theme }) =>
    isUpdated &&
    css`
      border-left: 2px solid ${theme.colors.BLUE_1000};
    `}
`

export const MobileTitle = styled(Title)`
  display: block;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`

export const StandardItem = styled.div`
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 17%;
  }
`

export const HideableItem = styled.div`
  display: none;
  box-sizing: border-box;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 17%;
    display: block;
  }
`

export const MainItem = styled(StandardItem)`
  display: flex;
  align-items: center;
  overflow: hidden;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: 32%;
  }
`

export const SiteAvatar = styled.div`
  margin-right: 16px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-right: 12px;
  }
`

export const MainItemTextWrapper = styled.div`
  overflow: hidden;
  padding-right: 24px;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const StatusBadgeWrapper = styled.div`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    display: none;
  }
`

export const StatusBadgeMobile = styled.div`
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`
