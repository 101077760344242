import {
  DebitCardAccountTransactionTypeEnum,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'

import { ReactComponent as ArrowLeftWithLineIcon } from 'assets/svg/arrow-left-with-line.svg'
import { ReactComponent as CreditCardIcon } from 'assets/svg/credit-card.svg'
import { ReactComponent as OutboundTransactionIcon } from 'assets/svg/inbound-transaction.svg'
import { ReactComponent as PercentageIcon } from 'assets/svg/interest-percentage.svg'
import { ReactComponent as BpayIcon } from 'assets/svg/logos/Bpay-icon.svg'
import { ReactComponent as InboundTransactionIcon } from 'assets/svg/outbound-transaction.svg'
import { ReactComponent as PendingBadgeIcon } from 'assets/svg/pending-badge.svg'
import { ReactComponent as TerminalSettlementIcon } from 'assets/svg/terminal-settlement.svg'
import { ReactComponent as TransferIcon } from 'assets/svg/transaction-type.svg'

export const getTransactionIconsV2 = ({
  type,
}: {
  type: DebitCardTransactionTypeV2
}) => {
  const transactionTypeIcons = {
    [DebitCardTransactionTypeV2.BPAY_IN]: <BpayIcon />,
    [DebitCardTransactionTypeV2.BPAY_OUT]: <BpayIcon />,
    [DebitCardTransactionTypeV2.DEPOSIT]: <TerminalSettlementIcon />,
    [DebitCardTransactionTypeV2.NPP_IN]: <TransferIcon />,
    [DebitCardTransactionTypeV2.NPP_IN_RETURN]: <TransferIcon />,
    [DebitCardTransactionTypeV2.NPP_OUT]: <TransferIcon />,
    [DebitCardTransactionTypeV2.NPP_OUT_RETURN]: <TransferIcon />,
    [DebitCardTransactionTypeV2.WITHDRAWAL]: <TerminalSettlementIcon />,
    [DebitCardTransactionTypeV2.ATM_IN]: <CreditCardIcon />,
    [DebitCardTransactionTypeV2.ATM_OUT]: <CreditCardIcon />,
    [DebitCardTransactionTypeV2.PURCHASE_CNP]: <CreditCardIcon />,
    [DebitCardTransactionTypeV2.REFUND_CNP]: <CreditCardIcon />,
    [DebitCardTransactionTypeV2.DE_IN]: <TransferIcon />,
    [DebitCardTransactionTypeV2.DE_OUT]: <TransferIcon />,
    [DebitCardTransactionTypeV2.DE_OUT_RETURN]: <TransferIcon />,
    [DebitCardTransactionTypeV2.TRANSFER_IN]: <InboundTransactionIcon />,
    [DebitCardTransactionTypeV2.TRANSFER_OUT]: <OutboundTransactionIcon />,
    [DebitCardTransactionTypeV2.REFUND]: <CreditCardIcon />,
    [DebitCardTransactionTypeV2.PURCHASE]: (
      <CreditCardIcon data-testid="card-purchase-icon" />
    ),
    [DebitCardTransactionTypeV2.ADJUSTMENT_DEPOSIT]: <InboundTransactionIcon />,
    [DebitCardTransactionTypeV2.ADJUSTMENT_WITHDRAWAL]: (
      <OutboundTransactionIcon />
    ),
    [DebitCardTransactionTypeV2.INTEREST]: <PercentageIcon />,
    [DebitCardTransactionTypeV2.DDA_OUT]: <ArrowLeftWithLineIcon />,
    [DebitCardTransactionTypeV2.ACQUIRING_REFUND]: <CreditCardIcon />,
    [DebitCardTransactionTypeV2.ACQUIRING_REFUND_RETURN]: <CreditCardIcon />,
  }

  return transactionTypeIcons[type]
}

export const getTransactionIconsV3 = ({
  type,
}: {
  type: DebitCardAccountTransactionTypeEnum
}) => {
  const transactionTypeIcons = {
    [DebitCardAccountTransactionTypeEnum.BPAY_IN]: <BpayIcon />,
    [DebitCardAccountTransactionTypeEnum.BPAY_OUT]: <BpayIcon />,
    [DebitCardAccountTransactionTypeEnum.DEPOSIT]: <TerminalSettlementIcon />,
    [DebitCardAccountTransactionTypeEnum.NPP_IN]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.NPP_IN_RETURN]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.NPP_OUT]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.NPP_OUT_RETURN]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.WITHDRAWAL]: (
      <TerminalSettlementIcon />
    ),
    [DebitCardAccountTransactionTypeEnum.ATM_IN]: <CreditCardIcon />,
    [DebitCardAccountTransactionTypeEnum.ATM_OUT]: <CreditCardIcon />,
    [DebitCardAccountTransactionTypeEnum.PURCHASE_CNP]: <CreditCardIcon />,
    [DebitCardAccountTransactionTypeEnum.REFUND_CNP]: <CreditCardIcon />,
    [DebitCardAccountTransactionTypeEnum.DE_IN]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.DE_OUT]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.DE_OUT_RETURN]: <TransferIcon />,
    [DebitCardAccountTransactionTypeEnum.TRANSFER_IN]: (
      <InboundTransactionIcon />
    ),
    [DebitCardAccountTransactionTypeEnum.TRANSFER_OUT]: (
      <OutboundTransactionIcon />
    ),
    [DebitCardAccountTransactionTypeEnum.REFUND]: <CreditCardIcon />,
    [DebitCardAccountTransactionTypeEnum.PURCHASE]: (
      <CreditCardIcon data-testid="card-purchase-icon" />
    ),
    [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_DEPOSIT]: (
      <InboundTransactionIcon />
    ),
    [DebitCardAccountTransactionTypeEnum.ADJUSTMENT_WITHDRAWAL]: (
      <OutboundTransactionIcon />
    ),
    [DebitCardAccountTransactionTypeEnum.DDA_OUT]: <ArrowLeftWithLineIcon />,
    [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND]: <CreditCardIcon />,
    [DebitCardAccountTransactionTypeEnum.ACQUIRING_REFUND_RETURN]: (
      <CreditCardIcon />
    ),
  }

  return transactionTypeIcons[type]
}

export const pendingIcon = <PendingBadgeIcon data-testid="pending-icon" />
