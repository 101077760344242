import { ButtonLink, Heading } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledButtonLink = styled(ButtonLink)`
  position: absolute;
  bottom: 0;
`

export const StyledHeader = styled(Heading.H4)`
  color: ${({ theme }) => theme.colors.BLACK_900};
  margin: 0;
`
