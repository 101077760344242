import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  Flex,
  Heading,
  useIsMobileResolution,
} from '@npco/zeller-design-system'

import { ReactComponent as CloseIcon } from 'assets/svg/close-grey.svg'
import { translationsShared } from 'translations'

import { TerminalPreview } from '../TerminalPreview/TerminalPreview'
import { translations } from './TerminalPreviewModal.i18n'
import * as styled from './TerminalPreviewModal.styled'

interface TerminalPreviewModalProps {
  onClose: () => void
}

export const TerminalPreviewModal = ({
  onClose,
}: TerminalPreviewModalProps) => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const isMobile = useIsMobileResolution()

  return (
    <styled.TerminalPreviewModalWrapper
      bodyOpenClassName="modal-basic-open"
      overlayClassName="modal-basic-overlay"
      shouldCloseOnEsc
      testId="terminal-preview-modal"
      isOpen
      onRequestClose={onClose}
    >
      <styled.TerminalPreviewHeader>
        <Heading.H3>{t('title')}</Heading.H3>
        {isMobile && (
          <styled.TerminalPreviewIconButton
            data-testid="invoice-preview-close-icon"
            icon={CloseIcon}
            iconEnabledColor={COLOR.WHITE}
            onClick={onClose}
          />
        )}
        {!isMobile && (
          <styled.TerminalPreviewButton
            data-testid="invoice-preview-close"
            icon={CloseIcon}
            iconEnabledColor={COLOR.WHITE}
            onClick={onClose}
          >
            {tShared('close')}
          </styled.TerminalPreviewButton>
        )}
      </styled.TerminalPreviewHeader>
      <styled.TerminalPreviewBody>
        <Flex flexDirection="column" flexGrow={1} overflow="scroll">
          <Flex justifyContent="center" alignItems="center" pb="40px">
            <Box height="648px" width="64px" />
            <TerminalPreview isInModal />
          </Flex>
        </Flex>
      </styled.TerminalPreviewBody>
    </styled.TerminalPreviewModalWrapper>
  )
}
