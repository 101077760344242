import { Flex, Typography } from '@npco/zeller-design-system'

import { BulletPointList } from '../BulletPointList/BulletPointList'

interface FeatureListProps {
  title: string
  points: string[] | React.ReactNode[]
}

export const FeatureList = ({ title, points }: FeatureListProps) => (
  <Flex gap="8px" flexDirection="column">
    <Typography variant="heading-lg">{title}</Typography>
    <BulletPointList points={points} />
  </Flex>
)
