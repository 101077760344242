import { useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useLocationState } from '@npco/utils-routing'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { SitesLocationStateSchema } from 'pages/Settings/Sites/Sites.types'
import { page } from 'translations'

import { getAreTabsVisible } from '../SiteNavigation/SiteNavigation.utils'
import { translations } from './useSiteTabs.i18n'

const TabValues = {
  GENERAL: ROOT.ORGS.ORG().SITES.SITE.GENERAL,
  PAYMENTS: ROOT.ORGS.ORG().SITES.SITE.PAYMENTS,
  RECEIPT: ROOT.ORGS.ORG().SITES.SITE.RECEIPT,
  USERS: ROOT.ORGS.ORG().SITES.SITE.USERS,
  DEVICES: ROOT.ORGS.ORG().SITES.SITE.DEVICES,
  APPEARANCE: ROOT.ORGS.ORG().SITES.SITE.APPEARANCE,
}

export const useSiteTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const t = useTranslations(translations)

  // NOTE: temporary solution to get site name while reactive variables are loading
  const { name } = useLocationState(SitesLocationStateSchema) ?? {}

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'settings-layout-tabs-general',
        children: page.settings.tabs.general,
        value: TabValues.GENERAL.path,
        onClick: () =>
          navigate(TabValues.GENERAL.relative, { state: { name } }),
      },
      {
        id: 'settings-layout-tabs-payments',
        children: page.settings.tabs.payments,
        value: TabValues.PAYMENTS.path,
        onClick: () =>
          navigate(TabValues.PAYMENTS.relative, { state: { name } }),
      },
      {
        id: 'settings-layout-tabs-receipt',
        children: page.settings.tabs.receipt,
        value: TabValues.RECEIPT.path,
        onClick: () =>
          navigate(TabValues.RECEIPT.relative, { state: { name } }),
      },
      {
        id: 'settings-layout-tabs-devices',
        children: page.settings.tabs.devices,
        value: TabValues.DEVICES.path,
        onClick: () =>
          navigate(TabValues.DEVICES.relative, { state: { name } }),
      },
      {
        id: 'settings-layout-tabs-users',
        children: page.settings.tabs.users,
        value: TabValues.USERS.path,
        onClick: () => navigate(TabValues.USERS.relative, { state: { name } }),
      },
      {
        id: 'settings-layout-tabs-appearance',
        children: t('appearance'),
        value: TabValues.APPEARANCE.path,
        onClick: () =>
          navigate(TabValues.APPEARANCE.relative, { state: { name } }),
      },
    ],
    [navigate, name, t]
  )

  return {
    areTabsVisible: getAreTabsVisible(pathname),
    tabs,
    currentTab: (value: string) => Boolean(matchPath(`${value}/*`, pathname)),
  }
}
