import { useTranslations } from '@npco/utils-translations'
import { Box, ButtonGhostIconLeft } from '@npco/zeller-design-system'

import { ReactComponent as Plus } from 'assets/svg/plus.svg'

import { useCreateExternalAccountContext } from './contexts/useCreateExternalAccountContext'
import { translations } from './CreateExternalAccount.i18n'
import { CreateExternalAccountStage } from './CreateExternalAccount.types'
import { CreateExternalAccountModal } from './CreateExternalAccountModal'

export const CreateExternalAccount = () => {
  const t = useTranslations(translations)
  const { goToCreateExternalAccountState } = useCreateExternalAccountContext()

  return (
    <Box ml="20px" mt="28px">
      <ButtonGhostIconLeft
        icon={Plus}
        onClick={() =>
          goToCreateExternalAccountState({
            stage: CreateExternalAccountStage.ExternalAccountMessageStage,
          })
        }
      >
        {t('addExternalAccountButtonText')}
      </ButtonGhostIconLeft>
      <CreateExternalAccountModal />
    </Box>
  )
}
