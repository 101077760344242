import { BodyLargeTypography, ButtonFill } from '@npco/zeller-design-system'
import { BREAKPOINT } from '@npco/zeller-design-system/dist/styles/breakpoints'
import styled from 'styled-components'

export const StyledPrimaryAction = styled(ButtonFill)`
  ${BodyLargeTypography}
  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-width: 18rem;
    width: fit-content;
  }
`
