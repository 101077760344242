import { Box, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ErrorIcon } from 'assets/svg/error.svg'
import { translate } from 'utils/translations'

import { StyledFailedFilesWrapper } from './FileFailedError.styled'

interface Props {
  failedFileNames: string[]
}

export const FileFailedError = ({ failedFileNames }: Props) => {
  return failedFileNames.length > 0 ? (
    <StyledFailedFilesWrapper>
      <Box mr="1rem">
        <SvgIcon height="16" width="16">
          <ErrorIcon />
        </SvgIcon>
      </Box>
      <span>
        {translate('form.shared.uploadDocuments.uploadError', {
          value: failedFileNames.join(', '),
        })}
      </span>
    </StyledFailedFilesWrapper>
  ) : null
}
