interface DescriptionProps {
  children: React.ReactNode
}

interface Props {
  descriptions: React.ReactNode[]
  Description: ({ children }: DescriptionProps) => JSX.Element
}

export const StackedDescriptions = ({ descriptions, Description }: Props) => {
  return (
    <>
      {descriptions.map((desc, index) => {
        const key = `description-${index}`

        return desc ? (
          <Description key={key}>{desc}</Description>
        ) : (
          <br key="br" />
        )
      })}
    </>
  )
}
