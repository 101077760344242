import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import {
  useSelectedEntityUuid,
  useSelectedShortEntityUuid,
} from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'

import { ROOT } from 'const/routes'

import { translations } from '../../TransactionDetails.i18n'
import { DetailsFieldWithButtonLink } from '../DetailsField/DetailsField'
import { useGetOrderReferenceNumberQuery } from './graphql/getOrderReferenceNumber.generated'

type PosLiteDetailsProps = {
  externalReference: string | null
  isNavigatingTransaction: boolean
}

export const PosLiteDetails = ({
  externalReference,
  isNavigatingTransaction,
}: PosLiteDetailsProps) => {
  const navigate = useNavigate()
  const entityUuid = useSelectedEntityUuid()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translations)

  const {
    data,
    error,
    loading: isLoadingReference,
  } = useGetOrderReferenceNumberQuery({
    skip: !externalReference,
    variables: {
      entityUuid,
      id: externalReference ?? '',
    },
  })

  const reference = useMemo(() => {
    if (error || !data?.getOrder.referenceNumber) {
      return t('viewTransaction')
    }

    return data.getOrder.referenceNumber
  }, [data, error, t])

  const handleClick = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).POS_LITE.path, {
      state: { OrderReference: externalReference },
    })
  }, [externalReference, navigate, shortEntityId])

  return (
    <DetailsFieldWithButtonLink
      isLoading={isLoadingReference || isNavigatingTransaction}
      label={t('posLiteDetails')}
      onClick={handleClick}
      value={reference}
    />
  )
}
