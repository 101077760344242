import { BodyDefault, Flex, H3, H5 } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledTitle = styled(H3)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledDetailName = styled(H5)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledDetailValue = styled(BodyDefault)`
  margin: 0;
  color: ${({ theme }) => theme.colors.BLACK_900};
  text-align: right;
  word-break: break-word;
`

export const StyledWrapper = styled(Flex)`
  flex: 1 0 auto;
`
