import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardFormatDisplayDebitCardV2FragmentDoc } from './hooks/useCardFormatDisplay.generated'

export type CardFormatDisplayDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  accessibleProfile: boolean | null
  colour: Types.DebitCardColour
  format: Types.DebitCardFormat | null
  productType: Types.DebitCardProductType | null
}

export const CardFormatDisplayDebitCardV2FragmentDoc = gql`
  fragment CardFormatDisplayDebitCardV2Fragment on DebitCardV2 {
    accessibleProfile
    colour
    ...UseCardFormatDisplayDebitCardV2Fragment
  }
  ${UseCardFormatDisplayDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardFormatDisplayDebitCardV2Fragment,
  undefined
>
