import { type ComponentProps, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'

import { TimeForASelfie } from 'components/SelfieVerification'

import { translations } from './BiometricsPageTemplate.i18n'

type TimeForASelfieProps = ComponentProps<typeof TimeForASelfie>

export const BiometricsPageTemplateDeps = {
  TimeForASelfie,
}

type Props = {
  onSubmitRoute: string
  onCancelRoute?: string
}

export const BiometricsPageTemplate = ({
  onSubmitRoute,
  onCancelRoute,
}: Props) => {
  const { TimeForASelfie } = BiometricsPageTemplateDeps

  const navigate = useNavigate()
  const t = useTranslations(translations)

  const onSubmit: TimeForASelfieProps['onSubmit'] = useCallback(
    (values) => {
      const { idType } = values
      if (!idType) {
        return
      }

      const document = idType
      const search = new URLSearchParams({ document }).toString()
      navigate(`${onSubmitRoute}?${search}`)
    },
    [navigate, onSubmitRoute]
  )

  const onCancel: TimeForASelfieProps['onCancel'] = useMemo(() => {
    if (!onCancelRoute) {
      return undefined
    }
    return () => {
      navigate(onCancelRoute, { state: { animation: 'backward' } })
    }
  }, [navigate, onCancelRoute])

  return (
    <TimeForASelfie
      onSubmit={onSubmit}
      onCancel={onCancel}
      descriptionCta={t('descriptionCta')}
    />
  )
}
