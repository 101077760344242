import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { BUTTON_SIZE, ButtonFill } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { translate } from 'utils/translations'
import { useIsSingleAccountCase } from 'pages/Transfer/hooks/useIsSingleAccountCase/useIsSingleAccountCase'
import { useTransferState } from 'pages/Transfer/Transfer.context'
import { TransferGeneralState } from 'pages/Transfer/Transfer.stateMachine'
import {
  TransferBpayFields,
  TransferFieldTypes,
} from 'pages/Transfer/Transfer.types'

import { TransferContactFields } from '../../ContactTransfer'
import { transferFieldsTranslations } from '../TransferFields.i18n'

interface TransferContinueButtonProps {
  setContactValidationError: Dispatch<SetStateAction<string | undefined>>
}
export const TransferContinueButton = ({
  setContactValidationError,
}: TransferContinueButtonProps) => {
  const t = useTranslations(transferFieldsTranslations)

  const isSingleAccountCase = useIsSingleAccountCase()
  const { values, status, handleSubmit } =
    useFormikContext<TransferFieldTypes>()

  const [isLoading, setIsLoading] = useState(false)

  const {
    transferState: [state],
  } = useTransferState()

  const isContactTransfer = (
    formValues: TransferFieldTypes
  ): formValues is TransferContactFields => {
    return state.matches(TransferGeneralState.ContactTransfer)
  }

  const isBpayTransfer = (
    formValues: TransferFieldTypes
  ): formValues is TransferBpayFields => {
    return state.matches(TransferGeneralState.BpayTransfer)
  }

  const isNewBpayDetails = (formValues: TransferBpayFields) => {
    return formValues.to?.type === 'Biller'
  }

  const handleOnClick = () => {
    if (isContactTransfer(values) && !values.contact) {
      setContactValidationError(t('contactRequired'))
      return
    }

    if (
      isBpayTransfer(values) &&
      isNewBpayDetails(values) &&
      !values.reference
    ) {
      setContactValidationError(t('contactRequired'))
      return
    }

    if (status?.isCheckingAbusiveMessage) {
      setIsLoading(true)
      return
    }

    handleSubmit()
  }

  useEffect(() => {
    if (!status?.isCheckingAbusiveMessage && isLoading) {
      setIsLoading(false)
      handleSubmit()
    }
  }, [handleSubmit, isLoading, status?.isCheckingAbusiveMessage])

  return (
    <ButtonFill
      disabled={isSingleAccountCase}
      onClick={handleOnClick}
      type="button"
      isLoading={isLoading}
      size={BUTTON_SIZE.MEDIUM}
      fullWidth
    >
      {translate('shared.continue')}
    </ButtonFill>
  )
}
