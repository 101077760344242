import { EntityInput, EntityType, OnboardingStatus } from '@npco/mp-gql-types'
import { rvEntityDetails } from '@npco/mp-utils-selected-entity'
import { setSessionStorageItem } from '@npco/utils-session-storage'

export const getIsEntityOnboarded = (entityDetails?: EntityInput) => {
  setSessionStorageItem('redirect', false)
  const onboardingStatus =
    entityDetails?.onboardingStatus || rvEntityDetails().onboardingStatus
  return (
    onboardingStatus === OnboardingStatus.ONBOARDED ||
    onboardingStatus === OnboardingStatus.RC_ONBOARDED
  )
}

export const getIsCompany = () => {
  const { type } = rvEntityDetails()
  return Boolean(type && type === EntityType.COMPANY)
}

export const getIsIndividualWithAbn = () => {
  const { type, abn, acn } = rvEntityDetails()
  return Boolean(type && (abn || acn) && type === EntityType.INDIVIDUAL)
}
