import { useMutation } from '@apollo/client'
import { PaymentInstrumentType } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { LinkPaymentInstrument } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/paymentInstruments'

import {
  LinkPaymentInstrument as LinkPaymentInstrumentResponse,
  LinkPaymentInstrumentVariables,
} from 'types/gql-types/LinkPaymentInstrument'

export const useLinkBsbPaymentInstrument = () => {
  const entityUuid = useSelectedEntityUuid()
  const [linkPaymentInstrument, { loading: isLinkingPaymentInstrument }] =
    useMutation<LinkPaymentInstrumentResponse, LinkPaymentInstrumentVariables>(
      LinkPaymentInstrument
    )

  return {
    isLinkingPaymentInstrument,
    linkPaymentInstrument: (contactId: string, paymentInstrumentId: string) => {
      return linkPaymentInstrument({
        variables: {
          entityUuid,
          contactUuid: contactId,
          id: paymentInstrumentId,
          type: PaymentInstrumentType.BSB,
        },
      })
    },
  }
}
