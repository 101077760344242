import { ROOT } from 'const/routes'
import { page } from 'translations'

import {
  BreadcrumbParts,
  BreadcrumbPath,
  BreadcrumbsVariable,
} from './Breadcrumbs.types'

export const BREADCRUMB: { [part in BreadcrumbParts]: BreadcrumbPath } = {
  [BreadcrumbParts.OVERVIEW]: {
    name: page.title.overview,
    path: ROOT.ORGS.ORG().OVERVIEW.path,
  },
  [BreadcrumbParts.DEPOSITS]: {
    name: page.title.deposits,
    path: ROOT.ORGS.ORG().PAYMENTS.DEPOSITS.path,
  },
  [BreadcrumbParts.DEPOSITS_STATEMENTS]: {
    name: page.title.statements,
    path: ROOT.ORGS.ORG().PAYMENTS.DEPOSITS.STATEMENTS.path,
    parent: BreadcrumbParts.DEPOSITS,
  },
  [BreadcrumbParts.DEPOSIT_SETTINGS]: {
    name: page.title.settings,
    path: ROOT.ORGS.ORG().PAYMENTS.DEPOSITS.SETTINGS.path,
    parent: BreadcrumbParts.DEPOSITS,
  },
  [BreadcrumbParts.PROFILE_PERSONAL]: {
    name: page.settings.profileSettings.title,
    path: ROOT.ORGS.ORG().SETTINGS.PROFILE.PERSONAL.path,
    applyToPaths: [
      ROOT.ORGS.ORG().SETTINGS.PROFILE.PERSONAL.path,
      ROOT.ORGS.ORG().SETTINGS.PROFILE.DOCUMENTS.path,
      ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.path,
    ],
  },
  [BreadcrumbParts.PROFILE_PERSONAL_EDIT]: {
    name: page.settings.general.edit,
    path: ROOT.ORGS.ORG().SETTINGS.PROFILE.PERSONAL.EDIT.path,
    parent: BreadcrumbParts.PROFILE_PERSONAL,
  },
  [BreadcrumbParts.SETTINGS_CONNECTIONS]: {
    name: page.settings.connections.title,
    path: ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.path,
    applyToPaths: [
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_BANK_FEEDS.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_SETUP.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_BANK_FEEDS_MANAGE.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_SETUP.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.ORACLE_POS.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.HL_POS.MANAGE.path,
    ],
  },
  [BreadcrumbParts.XERO_PAYMENT_SERVICES_MANAGE]: {
    name: page.settings.connections.xeroPaymentServices.breadcrumb,
    path: ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE
      .path,
    applyToPaths: [
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.GENERAL
        .path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.RECEIPT
        .path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS
        .path,
      ROOT.ORGS.ORG().SETTINGS.CONNECTIONS.XERO_PAYMENT_SERVICES_MANAGE.USERS.USER()
        .path,
    ],
    parent: BreadcrumbParts.SETTINGS_CONNECTIONS,
  },
  [BreadcrumbParts.PROFILE_SETTINGS_CHANGE_NUMBER]: {
    name: page.settings.profileSettings.changePhoneNumber,
    path: ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.CHANGE_NUMBER.path,
    parent: BreadcrumbParts.PROFILE_PERSONAL,
  },
  [BreadcrumbParts.PROFILE_SETTINGS_VALIDATE_CODE]: {
    name: page.settings.profileSettings.changePhoneNumber,
    path: ROOT.ORGS.ORG().SETTINGS.PROFILE.SECURITY.VALIDATE_CODE.path,
    parent: BreadcrumbParts.PROFILE_PERSONAL,
  },
  [BreadcrumbParts.FEES_AND_PRICING]: {
    name: page.settings.feesAndPricing.title,
    path: ROOT.ORGS.ORG().SETTINGS.FEES_AND_PRICING.path,
  },

  [BreadcrumbParts.SITES]: {
    name: page.title.sites,
    path: ROOT.ORGS.ORG().PAYMENTS.SITES.path,
  },
  [BreadcrumbParts.CREATE_NEW_SITE]: {
    name: page.title.createSite,
    path: ROOT.ORGS.ORG().PAYMENTS.SITES.CREATE_SITE.path,
    parent: BreadcrumbParts.SITES,
  },
  [BreadcrumbParts.SITE]: {
    name: BreadcrumbsVariable.siteName,
    path: ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.GENERAL.path,
    applyToPaths: [
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.GENERAL.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.GENERAL.EDIT.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.DEVICES.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.PAYMENTS.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.RECEIPT.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.APPEARANCE.path,
    ],
    parent: BreadcrumbParts.SITES,
  },
  [BreadcrumbParts.SITE_EDIT]: {
    name: page.title.editSite,
    path: ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.GENERAL.EDIT.path,
    parent: BreadcrumbParts.SITE,
  },
  [BreadcrumbParts.SITE_USERS_ADD]: {
    name: page.title.createNewUser,
    path: ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_TYPE.path,
    applyToPaths: [
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_TYPE.path,
      ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.CREATE_DETAILS.path,
    ],
    parent: BreadcrumbParts.SITE,
  },
  [BreadcrumbParts.SITE_USERS_EDIT]: {
    name: page.title.editUser,
    path: ROOT.ORGS.ORG().PAYMENTS.SITES.SITE.USERS.EDIT.path,
    parent: BreadcrumbParts.SITE,
  },

  [BreadcrumbParts.PAYMENT_DEVICES]: {
    name: page.title.devices,
    path: ROOT.ORGS.ORG().PAYMENTS.DEVICES.path,
  },
  [BreadcrumbParts.TRANSACTIONS]: {
    name: page.title.transactions,
    path: ROOT.ORGS.ORG().PAYMENTS.TRANSACTIONS.path,
  },
  [BreadcrumbParts.SIM]: {
    name: page.title.sim,
    path: ROOT.ORGS.ORG().PAYMENTS.SIM.path,
  },
  [BreadcrumbParts.SIM_BILLING_HISTORY]: {
    name: page.settings.sim.billingHistory.title,
    path: ROOT.ORGS.ORG().PAYMENTS.SIM.BILLING_HISTORY.path,
    parent: BreadcrumbParts.SIM,
  },
  [BreadcrumbParts.SIM_BILLING_ACCOUNT]: {
    name: page.settings.sim.billingAccount.title,
    path: ROOT.ORGS.ORG().PAYMENTS.SIM.BILLING_ACCOUNT.path,
    parent: BreadcrumbParts.SIM,
  },
  [BreadcrumbParts.SIM_BILLING_HISTORY_STATEMENTS]: {
    name: page.settings.sim.statements.title,
    path: ROOT.ORGS.ORG().PAYMENTS.SIM.BILLING_HISTORY.STATEMENTS.path,
    parent: BreadcrumbParts.SIM_BILLING_HISTORY,
  },
  [BreadcrumbParts.CARDS]: {
    name: page.cards.breadcrumb,
    path: ROOT.ORGS.ORG().CARDS.path,
    applyToPaths: [
      ROOT.ORGS.ORG().CARDS.path,
      ROOT.ORGS.ORG().CARDS.ACTIVATE.path,
      ROOT.ORGS.ORG().CARDS.CORPORATE.path,
      ROOT.ORGS.ORG().CARDS.DEBIT.path,
    ],
  },
  [BreadcrumbParts.CONTACTS_PEOPLE]: {
    name: page.contacts.breadcrumb,
    path: ROOT.ORGS.ORG().CONTACTS.PEOPLE.path,
  },
  [BreadcrumbParts.CONTACTS_BUSINESSES]: {
    name: page.contacts.breadcrumb,
    path: ROOT.ORGS.ORG().CONTACTS.BUSINESSES.path,
  },

  [BreadcrumbParts.CONTACT_PERSON]: {
    name: BreadcrumbsVariable.contactName,
    path: ROOT.ORGS.ORG().CONTACTS.PEOPLE.CONTACT().GENERAL.path,
    applyToPaths: [
      ROOT.ORGS.ORG().CONTACTS.PEOPLE.CONTACT().GENERAL.path,
      ROOT.ORGS.ORG().CONTACTS.PEOPLE.CONTACT().PAYMENTS.path,
      ROOT.ORGS.ORG().CONTACTS.PEOPLE.CONTACT().ACCOUNTS.path,
      ROOT.ORGS.ORG().CONTACTS.PEOPLE.CONTACT().INVOICES.path,
      ROOT.ORGS.ORG().CONTACTS.PEOPLE.CONTACT().INVOICES.INVOICE().path,
    ],
    parent: BreadcrumbParts.CONTACTS_PEOPLE,
  },
  [BreadcrumbParts.CONTACT_BUSINESS]: {
    name: BreadcrumbsVariable.contactName,
    path: ROOT.ORGS.ORG().CONTACTS.BUSINESSES.CONTACT().GENERAL.path,
    applyToPaths: [
      ROOT.ORGS.ORG().CONTACTS.BUSINESSES.CONTACT().GENERAL.path,
      ROOT.ORGS.ORG().CONTACTS.BUSINESSES.CONTACT().PAYMENTS.path,
      ROOT.ORGS.ORG().CONTACTS.BUSINESSES.CONTACT().ACCOUNTS.path,
      ROOT.ORGS.ORG().CONTACTS.BUSINESSES.CONTACT().INVOICES.path,
      ROOT.ORGS.ORG().CONTACTS.BUSINESSES.CONTACT().INVOICES.INVOICE().path,
    ],
    parent: BreadcrumbParts.CONTACTS_BUSINESSES,
  },
  [BreadcrumbParts.TRANSFER_CREATE]: {
    name: page.title.transfer,
    path: ROOT.ORGS.ORG().ACCOUNTS.TRANSFER.path,
  },
  [BreadcrumbParts.REPORTS]: {
    name: page.title.reports,
    path: ROOT.ORGS.ORG().PAYMENTS.REPORTS.path,
  },
  [BreadcrumbParts.USERS]: {
    name: page.title.users,
    path: ROOT.ORGS.ORG().SETTINGS.USERS.path,
  },
  [BreadcrumbParts.USERS_EDIT]: {
    name: page.title.editUser,
    path: ROOT.ORGS.ORG().SETTINGS.USERS.USER_EDIT.path,
    parent: BreadcrumbParts.USERS,
  },
  [BreadcrumbParts.USERS_CREATE]: {
    name: page.title.createNewUser,
    path: ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_TYPE.path,
    parent: BreadcrumbParts.USERS,
    applyToPaths: [
      ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_TYPE.path,
      ROOT.ORGS.ORG().SETTINGS.USERS.USER_CREATE_DETAILS.path,
    ],
  },
  [BreadcrumbParts.INVOICING]: {
    name: page.invoices.title,
    path: ROOT.ORGS.ORG().INVOICING.path,
  },
  [BreadcrumbParts.INVOICING_INVOICES_CREATE]: {
    name: page.invoice.draft,
    path: ROOT.ORGS.ORG().INVOICING.INVOICES.NEW.path,
    parent: BreadcrumbParts.INVOICING_INVOICES,
  },
  [BreadcrumbParts.INVOICING_INVOICES_EDIT]: {
    name: BreadcrumbsVariable.invoiceRefNumber,
    path: ROOT.ORGS.ORG().INVOICING.INVOICES.INVOICE().EDIT.path,
    parent: BreadcrumbParts.INVOICING_INVOICES,
  },
  [BreadcrumbParts.INVOICING_INVOICES]: {
    name: page.invoices.title,
    path: ROOT.ORGS.ORG().INVOICING.INVOICES.path,
    applyToPaths: [
      ROOT.ORGS.ORG().INVOICING.INVOICES.path,
      ROOT.ORGS.ORG().INVOICING.INVOICES.INVOICE().path,
    ],
  },
  [BreadcrumbParts.INVOICING_SETTINGS]: {
    name: page.invoicesSettings.title,
    path: ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.path,
    applyToPaths: [
      ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.GENERAL.path,
      ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.REMINDERS.path,
      ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.CUSTOMISATION.path,
      ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.RECEIPTS.path,
      ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.USERS.path,
      ROOT.ORGS.ORG().INVOICING.INVOICES.SETTINGS.USERS.USER().path,
    ],
    parent: BreadcrumbParts.INVOICING,
  },
}
