import { gql, TypedDocumentNode } from '@apollo/client'

import * as CreateCustomerGQL from 'types/gql-types/CreateCustomer'
import * as UpdateCustomerGQL from 'types/gql-types/UpdateCustomer'

export const CreateCustomerForSignUp = gql`
  mutation CreateCustomerForSignUp($input: [CreateCustomerInput!]!) {
    createCustomer(input: $input) {
      id
      entityUuid
      role
      type
      permissions {
        allowDiscountManagement
        allowItemManagement
        allowZellerInvoices
      }
    }
  }
` as TypedDocumentNode<
  CreateCustomerGQL.CreateCustomer,
  CreateCustomerGQL.CreateCustomerVariables
>

export const CreateCustomer = gql`
  mutation CreateCustomer($entityUuid: ID!, $input: [CreateCustomerInput!]!) {
    createCustomer(entityUuid: $entityUuid, input: $input) {
      id
      entityUuid
      role
      type
      permissions {
        allowDiscountManagement
        allowItemManagement
        allowZellerInvoices
      }
    }
  }
` as TypedDocumentNode<
  CreateCustomerGQL.CreateCustomer,
  CreateCustomerGQL.CreateCustomerVariables
>

export const UpdateCustomer = gql`
  mutation UpdateCustomer($entityUuid: ID!, $customer: UpdateCustomerInput!) {
    updateCustomer(entityUuid: $entityUuid, customer: $customer)
  }
` as TypedDocumentNode<
  UpdateCustomerGQL.UpdateCustomer,
  UpdateCustomerGQL.UpdateCustomerVariables
>

export const DeleteCustomer = gql`
  mutation DeleteCustomer($entityUuid: ID!, $customerUuid: ID!) {
    deleteCustomer(entityUuid: $entityUuid, customerUuid: $customerUuid)
  }
`

export const AssignCustomerToSite = gql`
  mutation AssignCustomerToSite(
    $entityUuid: ID!
    $customerUuid: ID!
    $siteUuid: ID!
  ) {
    assignCustomerToSite(
      entityUuid: $entityUuid
      customerUuid: $customerUuid
      siteUuid: $siteUuid
    )
  }
`

export const RemoveCustomerFromSite = gql`
  mutation RemoveCustomerFromSite(
    $entityUuid: ID!
    $customerUuid: ID!
    $siteUuid: ID!
  ) {
    removeCustomerFromSite(
      entityUuid: $entityUuid
      customerUuid: $customerUuid
      siteUuid: $siteUuid
    )
  }
`

export const SendInviteEmail = gql`
  mutation SendInviteEmail($entityUuid: ID!, $customerUuid: ID!) {
    sendInviteEmail(entityUuid: $entityUuid, customerUuid: $customerUuid)
  }
`
export const FinaliseCustomerKYC = gql`
  mutation FinaliseCustomerKYC {
    finaliseCustomerKYC {
      result
      uploadDocument
    }
  }
`
