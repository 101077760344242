import { WebAuth } from 'auth0-js'

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URI,
} from 'const/envs'

export const getWebAuth = () =>
  new WebAuth({
    domain: AUTH0_DOMAIN as string,
    clientID: AUTH0_CLIENT_ID as string,
    audience: AUTH0_AUDIENCE as string,
    responseMode: 'fragment',
    responseType: 'code token id_token',
    redirectUri: AUTH0_REDIRECT_URI,
  })
