import { gql } from '@apollo/client'

export const RequestEmailChange = gql`
  mutation RequestEmailChange($input: RequestEmailChangeInput) {
    requestEmailChange(input: $input) {
      status
      message
    }
  }
`

export const CompleteEmailChange = gql`
  mutation CompleteEmailChange($input: CompleteEmailChangeInput) {
    completeEmailChange(input: $input) {
      status
      message
    }
  }
`
