import { Ellipsize, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

type WithSize = {
  size: 'small' | 'large'
}

type WithColor = {
  color: string
}

export const ContentWrapper = styled(Flex)<WithSize>`
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${({ size }) => (size === 'small' ? '144px' : '206px')};
  text-align: center;
`

export const Title = styled(Ellipsize)<WithSize>`
  ${({ theme }) => theme.typography['body-sm']};
  color: ${({ theme }) => theme.colors.GREY_550};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ size }) => (size === 'small' ? 1 : 3)};
  white-space: normal;
  width: 100%;
`

export const Total = styled(Ellipsize)<WithSize>`
  ${({ theme, size }) =>
    size === 'small'
      ? theme.typography['heading-sm']
      : theme.typography['heading-2xl']};
  color: ${({ theme }) => theme.colors.GREY_800};
  width: 100%;
`

export const ChangeWrapper = styled(Flex)<WithColor & WithSize>`
  ${({ theme }) => theme.typography['heading-sm']};
  color: ${({ color }) => color};
  max-width: 100%;
`

export const ChangeText = styled(Ellipsize)<WithSize>`
  margin-left: ${({ size }) => (size === 'small' ? '2px' : '4px')};
`

export const ChangeDescription = styled.span<WithColor>`
  ${({ theme }) => theme.typography['body-xs']};
  color: ${({ color }) => color};
`
