import {
  Box,
  BREAKPOINT,
  Flex,
  PopperItemOneLineIcon,
  PopperItemOneLineLabel,
  PopperItemOneLineWrapper,
  theme,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

import { SCROLLBAR } from 'const/scrollbars'
import { StyledRange, StyledThumb } from 'components/Input/Range/Range.styled'

export const AmountFiltersWrapper = styled(Flex)`
  flex-flow: column nowrap;
  padding: 8px;
  font-weight: normal;
  overflow-y: auto;
  overflow-x: hidden;

  margin: ${SCROLLBAR.MD.rightSpacing} ${SCROLLBAR.MD.rightSpacing}
    ${SCROLLBAR.MD.rightSpacing} 0;
  &::-webkit-scrollbar {
    width: ${SCROLLBAR.MD.width};
  }

  max-height: calc(100dvh - 216px);
  @media screen and (min-width: ${BREAKPOINT.SM}px) {
    max-height: calc(100dvh - 230px);
  }
  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    max-height: calc(100dvh - 166px);
  }

  ${PopperItemOneLineWrapper} {
    ${PopperItemOneLineIcon} {
      display: block;
      position: relative;
    }
    ${PopperItemOneLineLabel} {
      display: block;
      position: relative;
      right: 152px;
    }
  }
`

export const SortingWrapper = styled(Flex)`
  flex-flow: column nowrap;
`
export const FilterWrapper = styled(Box)`
  height: 220px;
  width: 100%;

  ${StyledRange} {
    margin: 0;
  }

  ${StyledThumb} {
    background-color: ${theme.colors.BLUE_1000};
  }
`

export const BottomRow = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: flex-end;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.GREY_90};
  margin: 20px 0;
`

export const OptionsWrapper = styled.div`
  position: relative;
  top: 8px;
  max-height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  border: 1px solid ${theme.colors.GREY_90};
  box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.04), 2px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 100%;
`

export const RangeInputWrapper = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  div: first-of-type {
    margin-right: 16px;
  }
`
