import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`

export const StyledStepperWrapper = styled.div`
  display: block;

  @media (min-width: ${BREAKPOINT.XS}px) {
    display: none;
  }
`

export const StyledInputWrapper = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`
