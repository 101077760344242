import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { COLOR, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as GeneralExpensesIcon } from 'assets/svg/TransactionCategories/general-expenses.svg'
import { ROOT } from 'const/routes'
import { SidebarItem } from 'components/Sidebar/components/Item/Item'
import { useSidebarLocalCache } from 'components/Sidebar/hooks/useSidebarLocalCache'
import { translations } from 'components/Sidebar/Sidebar.i18n'

export const ExpensesItem = () => {
  const t = useTranslations(translations)
  const { isFolded } = useSidebarLocalCache()
  const shortEntityId = useSelectedShortEntityUuid()
  const flags = useFeatureFlags()

  if (!flags.Expenses) {
    return null
  }

  return (
    <SidebarItem
      // need to update the icon when design is ready
      icon={
        <SvgIcon width="20" height="20" color={COLOR.BLUE_1000}>
          <GeneralExpensesIcon />
        </SvgIcon>
      }
      className="nav-item-expenses"
      isFolded={isFolded}
      isNavLink
      route={ROOT.ORGS.ORG(shortEntityId).EXPENSES.path}
    >
      {t('expenses')}
    </SidebarItem>
  )
}
