import dayjs from 'utils/dayjs'
import type { ChartData, ChartDataPoint } from 'components/Charts/Charts.types'

export const getIsFirst = (index: number) => index === 0

export const getIsTickIndex = (index: number, interval: number) =>
  index % interval === 0

export const getIsFirstTickOfMonth = (
  milliseconds: number,
  interval: number
) => {
  return dayjs(milliseconds).date() <= interval
}

const getIsFirstTickOrFirstTickOfMonth =
  (interval: number) => (milliseconds: number, index: number) =>
    getIsFirst(index) ||
    (getIsTickIndex(index, interval) &&
      getIsFirstTickOfMonth(milliseconds, interval))

export const getXAxisMonthTicks = (chartData: ChartData, interval: number) => {
  return chartData
    .filter(Boolean)
    .map((point) => point.name)
    .filter(getIsFirstTickOrFirstTickOfMonth(interval))
}

export const getXAxisDayTicks = (chartData: ChartData, interval?: number) => {
  if (!chartData?.length || !chartData[0]) return []

  const startDate = dayjs(chartData[0].name)
  const endDate = dayjs(chartData[chartData.length - 1].name)

  const timePeriodInDays = endDate.diff(startDate, 'days')

  // NOTE: we want from the first day of one month to the last day of the next
  // month to be counted as two months - but dayjs calculates the difference
  // excluding the last day. Adding one extra day means that (for example) the
  // 1st of December to the 31st of January is counted as 2 months.
  const timePeriodInMonths = endDate.add(1, 'day').diff(startDate, 'months')

  const filterInPoints: (
    _dataPoint: ChartDataPoint,
    index: number
  ) => boolean = (_dataPoint, index) => {
    if (interval) {
      return index % interval === 0
    }

    switch (true) {
      case timePeriodInDays < 11:
        return true
      case timePeriodInDays < 20:
        return index % 2 === 0
      case timePeriodInDays < 30:
        return index % 3 === 0
      case timePeriodInDays < 40:
        return index % 4 === 0
      case timePeriodInDays < 50:
        return index % 5 === 0
      case timePeriodInMonths <= 2:
        return index % 6 === 0
      default:
        return false
    }
  }

  return chartData
    .filter(Boolean)
    .filter(filterInPoints)
    .map((data) => data.name)
}
