import { createTranslations } from '@npco/utils-translations'

export const selectCardTypeFormTranslations = createTranslations({
  debitCardOptionTitle: 'Debit Card',
  debitCardOptionDesc: 'Spend money from your Zeller Transaction Account.',
  expenseCardOptionTitle: 'Corporate Card',
  expenseCardOptionDesc:
    'Manage team expenses, track spending, and simplify reconciliations with spend limits, recurring budgets, and customisable transaction limits.',
  expenseCardOptionDescFreeTrailEnds:
    'Get Zeller Corporate Cards free! Create as many as you like, then pay only $9/month per card from 60 days after your first card creation.',
  expenseCardCost: '$9/month',
})
