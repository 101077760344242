import { Outlet } from 'react-router-dom-v5-compat'
import { PageTemplate } from '@npco/zeller-design-system'

import { AccountLayoutPrimaryHeaderRow } from './AccountLayoutPrimaryHeaderRow/AccountLayoutPrimaryHeaderRow'
import { AccountLayoutSecondaryHeaderRow } from './AccountLayoutSecondaryHeaderRow/AccountLayoutSecondaryHeaderRow'
import { AccountLayoutTertiaryHeaderRow } from './AccountLayoutTertiaryHeaderRow/AccountLayoutTertiaryHeaderRow'
import { useAccountLayout } from './hooks/useAccountLayout/useAccountLayout'

export const AccountLayoutDeps = {
  useAccountLayout,
}

export const AccountLayout = () => {
  const { useAccountLayout } = AccountLayoutDeps

  const {
    accountBalance,
    accountId,
    accountName,
    accountType,
    entity,
    isActionsVisible,
    isFiltersVisible,
    isLoadingAccount,
    isTransactionsRoute,
    isTransferDisabled,
    setIsFiltersVisible,
  } = useAccountLayout()

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <AccountLayoutPrimaryHeaderRow
          accountBalance={accountBalance}
          accountId={accountId}
          accountName={accountName}
          entity={entity}
          isActionsVisible={isActionsVisible}
          isFiltersVisible={isFiltersVisible}
          isTransactionsRoute={isTransactionsRoute}
          isTransferDisabled={isTransferDisabled}
          setIsFiltersVisible={setIsFiltersVisible}
        />
      }
      HeaderSecondaryRow={
        <AccountLayoutSecondaryHeaderRow
          accountBalance={accountBalance}
          accountId={accountId}
          accountType={accountType}
          isLoadingAccount={isLoadingAccount}
        />
      }
      HeaderTertiaryRow={
        <AccountLayoutTertiaryHeaderRow
          accountId={accountId}
          isFiltersVisible={isFiltersVisible}
          isTransactionsRoute={isTransactionsRoute}
        />
      }
    >
      <Outlet />
    </PageTemplate>
  )
}
