import { makeVar } from '@apollo/client'

import { BankAccount, ThirdPartyAccount } from 'types/accounts'
import { Account } from 'types/settings'

export const rvDetailedAccount = makeVar<BankAccount>(null)

export const rvDetailedAccountType = makeVar<Account>(Account.ZELLER)

export const rvThirdPartyDepositBank = makeVar<ThirdPartyAccount | null>(null)
