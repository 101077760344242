import { StyledAvatarBasic } from '@npco/zeller-design-system'
import { StyledSelectStandardItem } from 'design-system/Components/Select'
import styled from 'styled-components'

export const StyledAccountSelectItem = styled(StyledSelectStandardItem)`
  padding: 0.625rem 0.75rem;
  &[aria-selected='true'] {
    ${StyledAvatarBasic} {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`

export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;

  ${StyledAvatarBasic} {
    transition: background-color 0.3s ease-out;
  }

  &:hover,
  &:focus {
    ${StyledAvatarBasic} {
      background-color: ${({ theme }) => theme.colors.WHITE};
    }
  }
`

export const StyledAccountSubLabelList = styled.div`
  display: flex;
  align-items: center;

  > * {
    padding-right: 0.25rem;
    margin-right: 0.25rem;
    position: relative;

    &:after {
      content: '';
      width: 2px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.GREY_250};
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(50%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`
export const StyledAccountSubLabel = styled.span`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.GREY_550};
  white-space: nowrap;
`
