import styled from 'styled-components'

export const StyledNavButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  margin-left: 0.5rem;

  path {
    fill: ${({ theme }) => theme.colors.BLACK_900};
  }

  &:disabled {
    path {
      fill: ${({ theme }) => theme.colors.GREY_250};
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`
