import { useEffect } from 'react'

interface Args<ItemType> {
  selectedItem: ItemType
  isResettable?: boolean
  reset: () => void
}

export function useSelectReset<ItemType>({
  selectedItem,
  isResettable,
  reset,
}: Args<ItemType>) {
  useEffect(() => {
    if (!selectedItem && isResettable) {
      reset()
    }
  }, [selectedItem, reset, isResettable])
}
