import { ContactType } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { Box } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { TransferBpayFields } from 'pages/Transfer/Transfer.types'
import { BpayDetailsField } from 'pages/Transfer/TransferFields/fields/BpayDetails'
import { ContactOrBillerField } from 'pages/Transfer/TransferFields/fields/ContactOrBiller'
import { FieldCondition } from 'pages/Transfer/TransferFields/fields/FieldCondition'
import { MarkAsSelf } from 'components/MarkAsSelf/MarkAsSelf'

export const BpayToFields = () => {
  const { values: bpayValues } = useFormikContext<TransferBpayFields>()
  const flags = useFeatureFlags()

  return (
    <>
      <ContactOrBillerField />
      <FieldCondition when={bpayValues.to?.type === 'Contact'}>
        {flags.SweepingTracking &&
          typeof bpayValues.to?.isSelf !== 'boolean' && (
            <MarkAsSelf name="isSelf" contactType={ContactType.BUSINESS} />
          )}
        <Box mt="24px">
          <BpayDetailsField />
        </Box>
      </FieldCondition>
    </>
  )
}
