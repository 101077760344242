import { useMemo } from 'react'
import { TransactionType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'

import { Items, MultiSelect } from 'components/Filters/PillFilters/MultiSelect'

import { translations } from './SaleType.i18n'

type SaleTypeProps = {
  selectedTypes?: `${TransactionType}`[]
  setSelectedTypes: (types: `${TransactionType}`[]) => void
}

export const SaleType = ({
  selectedTypes,
  setSelectedTypes,
}: SaleTypeProps) => {
  const t = useTranslations(translations)

  const items = useMemo(
    () =>
      [
        { id: '1', label: t('sale'), value: 'PURCHASE' },
        { id: '2', label: t('refund'), value: 'REFUND' },
      ] satisfies Items<`${TransactionType}`>,
    [t]
  )

  return (
    <MultiSelect
      onSelectItem={setSelectedTypes}
      selectedItems={selectedTypes}
      items={items}
      defaultLabel={t('typeLabel')}
    />
  )
}
