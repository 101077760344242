import { useCallback } from 'react'
import { DebitCardAccountType } from '@npco/mp-gql-types'
import { rvOptimisticDebitCardTransactions } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { rvSelectedContactToTransfer } from 'features/Contacts/rv-deprecated/contacts'

import { FALLBACK_TO_DE, useSendContactTransfer } from 'hooks/banking'
import {
  DAILY_TRANSFER_LIMIT_EXCEEDED,
  MFA_ERROR,
  UNEXPECTED_ERROR,
} from 'types/errors'
import { createContactOptimisticTransaction } from 'pages/Transfer/optimisticTransactions.utils'

import { TransferContactValues } from '../../../ContactTransferModal.types'

type SubmitContactTransferArgs = {
  values: TransferContactValues
  forceDeOut?: boolean
}

export const useSubmitContactTransfer = () => {
  const { sendContactTransfer, isSendingContactTransfer } =
    useSendContactTransfer()

  const submitContactTransfer = useCallback(
    async ({ values, forceDeOut = false }: SubmitContactTransferArgs) => {
      const result = await sendContactTransfer({
        payerAccountUuid: values.fromAccount.id,
        payeeAccountUuid: values.toPaymentInstrument.id,
        payerReference: values.reference,
        payeeReference: values.recipientReference,
        amountInCents: Math.round(Number(values.amount) * 100),
        forceDeOut,
      })

      if (result === UNEXPECTED_ERROR) {
        return UNEXPECTED_ERROR
      }

      if (result === FALLBACK_TO_DE) {
        return FALLBACK_TO_DE
      }

      if (result === MFA_ERROR) {
        return MFA_ERROR
      }

      if (result === DAILY_TRANSFER_LIMIT_EXCEEDED) {
        return DAILY_TRANSFER_LIMIT_EXCEEDED
      }

      const now = new Date().toISOString()

      const updatedOptimisticTransactions = [
        createContactOptimisticTransaction({
          id: result.id,
          amountInCents: Math.round(Number(values.amount) * 100),
          reference: values.reference,
          referencePayee: values.recipientReference,
          selectedContact: rvSelectedContactToTransfer(),
          fromAccount: {
            ...values.fromAccount,
            icon: values.fromAccount.icon
              ? { ...values.fromAccount.icon, images: null, animation: null }
              : null,
            type: DebitCardAccountType.ZLR_DEBIT,
          },
          toAccount: rvSelectedContactToTransfer()?.paymentInstruments?.find(
            (account) => account.id === values.toPaymentInstrument.id
          ),
          timestamp: now,
          type: result.transferType,
        }),
        ...rvOptimisticDebitCardTransactions(),
      ]

      rvOptimisticDebitCardTransactions(updatedOptimisticTransactions)

      return result
    },
    [sendContactTransfer]
  )

  return { submitContactTransfer, isSendingContactTransfer }
}
