import { Invoicing } from '@npco/zeller-design-system'

import { FileWithId } from 'components/File'

import { useInvoiceActionPrompt } from './hooks/useInvoiceActionPrompt'
import { CancelInvoiceModal } from './modals/CancelInvoiceModal'
import { DeleteInvoiceModal } from './modals/DeleteInvoiceModal'
import { MarkAsPaidModal } from './modals/MarkAsPaidModal'
import { RecordPaymentModal } from './modals/RecordPaymentModal'
import { ResendInvoiceModal } from './modals/ResendInvoiceModal/ResendInvoiceModal'
import { SendNowModal } from './modals/SendNowModal/SendNowModal'
import { SendReminderModal } from './modals/SendReminderModal'

interface InvoiceActionPromptProps {
  invoicePdf?: FileWithId | null
}

export const InvoiceActionPrompt = ({
  invoicePdf,
}: InvoiceActionPromptProps) => {
  const { actionPromptProps } = useInvoiceActionPrompt(invoicePdf)

  return (
    <>
      {actionPromptProps && <Invoicing.ActionPrompt {...actionPromptProps} />}
      <CancelInvoiceModal />
      <DeleteInvoiceModal />
      <MarkAsPaidModal />
      <RecordPaymentModal />
      <ResendInvoiceModal />
      <SendReminderModal />
      <SendNowModal />
    </>
  )
}
