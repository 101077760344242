import { useTranslations } from '@npco/utils-translations'
import { Breadcrumb, Flex } from '@npco/zeller-design-system'

import { translations } from '../CashflowReporting.i18n'

export const ReportsHeaderPrimaryRow = () => {
  const t = useTranslations(translations)

  return (
    <Flex width="100%" data-testid="cashflow-primary-row">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Breadcrumb.Text>{t('heading')}</Breadcrumb.Text>
        </Breadcrumb.Item>
      </Breadcrumb>
    </Flex>
  )
}
