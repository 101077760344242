import { useTranslations } from '@npco/utils-translations'
import { ModalBasic } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { FooterButtons } from 'components/MultiStageModal/FooterButtons'
import { translationsShared } from 'translations'

import { useExternalAccountFormStage } from '../hooks/useExternalAccountFormStage'
import { ExternalAccountFormFields } from './ExternalAccountFormFields/ExternalAccountFormFields'
import { UpdateDepositAccountWarning } from './UpdateDepositAccountWarning/UpdateDepositAccountWarning'

export const ExternalAccountForm = () => {
  const tShared = useTranslations(translationsShared)

  const { goBackToCreateExternalAccountMessageStage } =
    useExternalAccountFormStage()

  const { submitForm } = useFormikContext()

  return (
    <>
      <ModalBasic.Body data-testid="external-account-form-stage">
        <ExternalAccountFormFields />
        <UpdateDepositAccountWarning />
      </ModalBasic.Body>
      <FooterButtons
        shouldShowBackButton
        onCancel={goBackToCreateExternalAccountMessageStage}
        backButtonLabel={tShared('back')}
        nextButtonLabel={tShared('continue')}
        onConfirm={submitForm}
      />
    </>
  )
}
