import {
  Box,
  COLOR,
  DownloadIcon,
  SelectTriggerPill,
  TOOLTIP_ZINDEX,
  TooltipBasic,
  TooltipSize,
} from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface ExportTriggerPillProps {
  isDisabled: boolean
  tooltipMessage: string | null
  onClick: () => void
}

export const ExportTriggerPill = ({
  isDisabled,
  tooltipMessage,
  onClick,
}: ExportTriggerPillProps) => {
  return (
    <TooltipBasic
      showArrow={false}
      placement="top"
      zIndex={Number.parseInt(TOOLTIP_ZINDEX, 10)}
      size={TooltipSize.ExtraSmall}
      isDisabled={!isDisabled}
      renderTrigger={({ ref, handlers }) => (
        <span ref={ref} {...handlers}>
          <SelectTriggerPill
            isOpen={false}
            icon={<DownloadIcon />}
            disabled={isDisabled}
            onClick={onClick}
            activeTextColour={COLOR.BLUE_1000}
          >
            {translate('page.accounts.transactionsList.filters.export')}
          </SelectTriggerPill>
        </span>
      )}
    >
      <Box maxWidth="220px" textAlign="center">
        {tooltipMessage}
      </Box>
    </TooltipBasic>
  )
}
