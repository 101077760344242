import { useLocation } from 'react-router-dom-v5-compat'
import { useReactiveVar } from '@apollo/client'
import { EntityType } from '@npco/mp-gql-types'
import { Box, Flex, TEXT_INPUT_SIZE } from '@npco/zeller-design-system'
import { rvCustomersCurrentCustomerTempId } from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { useWatchOnboardingSaveData } from 'features/OnboardingApp/shared/useWatchOnboardingSaveData'
import { Field, useFormikContext } from 'formik'

import { parseToSaveFullDate } from 'utils/date'
import { validateRequired } from 'utils/formValidation'
import { Roles } from 'types/customers'
import {
  TrustAddCustomerForm,
  TrustAddCustomerFormValues,
} from 'forms/formViews/TrustAddCustomerForm/TrustAddCustomerForm'
import { FormRadioButton } from 'components/Input/Radiobutton'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'
import { form } from 'translations'

import { StyledLabel } from './BeneficiariesForm.styled'

export type BeneficiaryType =
  | EntityType.BENEFICIARY_CLASS
  | EntityType.INDIVIDUAL

export type ClassFormValues = {
  class?: string
}

export type FormValues = ClassFormValues &
  TrustAddCustomerFormValues & {
    type: BeneficiaryType | null
    isBeneficialOwner?: boolean
  }

const FIELD_MAP = {
  [EntityType.INDIVIDUAL]: TrustAddCustomerForm,
  [EntityType.BENEFICIARY_CLASS]: () => (
    <Box mb="2.5rem">
      <InputAdaptiveFieldWrapper
        name="class"
        type="text"
        label={form.addBeneficiaries.className.label}
        placeholder={form.addBeneficiaries.className.placeholder}
        validate={validateRequired}
        size={TEXT_INPUT_SIZE.SMALL}
      />
    </Box>
  ),
}

interface BeneficiariesFormProps {
  isDisabled?: boolean
}

export const BeneficiariesForm = ({ isDisabled }: BeneficiariesFormProps) => {
  const { values } = useFormikContext<FormValues>()
  const { pathname } = useLocation()
  const customerTemporaryId = useReactiveVar(rvCustomersCurrentCustomerTempId)

  const FieldsComponent = values.type && FIELD_MAP[values.type]

  const { type, isBeneficialOwner, class: className, ...restValues } = values

  useWatchOnboardingSaveData({
    initialCustomerData: {
      roles: [
        ...(type ? [values.type as string] : []),
        ...(isBeneficialOwner ? [Roles.beneficialOwner as string] : []),
      ],
      companyTrustName: className,
      temporaryId: customerTemporaryId,
      ...restValues,
      dob: parseToSaveFullDate(restValues.dob),
    },
    lastRoute: pathname,
  })

  return (
    <div data-testid="beneficiaries-form">
      <Flex
        mb="40px"
        justifyContent={['center', 'space-between']}
        flexDirection={['column', 'row']}
        role="group"
        width="325px"
      >
        <StyledLabel isDisabled={isDisabled} htmlFor="individual">
          <Field
            id="individual"
            name="type"
            value={EntityType.INDIVIDUAL}
            component={FormRadioButton}
            checked={values.type === EntityType.INDIVIDUAL}
            disabled={isDisabled}
            data-testid="individual-checkbox"
          />
          {form.addBeneficiaries.beneficiaryType.individual}
        </StyledLabel>
        <StyledLabel isDisabled={isDisabled} htmlFor="class">
          <Field
            id="class"
            name="type"
            component={FormRadioButton}
            value={EntityType.BENEFICIARY_CLASS}
            checked={values.type === EntityType.BENEFICIARY_CLASS}
            disabled={isDisabled}
            data-testid="class-checkbox"
          />
          {form.addBeneficiaries.beneficiaryType.class}
        </StyledLabel>
      </Flex>
      {FieldsComponent && <FieldsComponent />}
    </div>
  )
}
