import { identity, map, pipe } from 'ramda'

import { getDeviceModelIcon } from 'utils/device'
import { translate } from 'utils/translations'
import { DeviceModel } from 'types/devices'
import {
  GetSites_getSites_sites as SiteData,
  GetSites_getSites_sites_devices as Device,
} from 'types/gql-types/GetSites'
import { PickerItemProps, PickerItems } from 'types/picker'

import { SiteProps } from './SiteDevicePicker.types'

export const getSelectedSitesInfo = (
  sites: SiteProps[],
  selectedItems: PickerItems
) => {
  const selectedSites = sites.filter((site) =>
    site.devices.some((device) =>
      selectedItems.some((item) => item.id === device.id)
    )
  )

  if (selectedSites.length === 1 && selectedItems.length === 1) {
    return `${selectedSites[0].name} (${selectedItems.length} ${translate(
      'component.siteDevicePicker.device'
    )})`
  }

  if (selectedSites.length === 1) {
    return `${selectedSites[0].name} (${selectedItems.length} ${translate(
      'component.siteDevicePicker.devices'
    )})`
  }

  return `${selectedSites.length} ${translate(
    'component.siteDevicePicker.sites'
  )} (${selectedItems.length} ${translate(
    'component.siteDevicePicker.devices'
  )})`
}

const filterDevices =
  (filterFunction: (device: Device) => boolean) => (devices: Device[]) =>
    devices.filter(filterFunction)

const filterSiteProps =
  (filterFunction: (site: SiteProps) => boolean) => (sites: SiteProps[]) =>
    sites.filter(filterFunction)

export const filterDevicesByNameFunction =
  (input: string) => (device: Device) => {
    if (!device.name) {
      return false
    }

    if (!input) {
      return true
    }

    return device.name.toLowerCase().includes(input.toLowerCase())
  }

export const isSearchTermInSiteName = (input: string, site: SiteData) => {
  if (!site.name) {
    return false
  }

  if (!input) {
    return true
  }

  return site.name.toLowerCase().includes(input.toLowerCase())
}

export const mapDeviceFunction = ({
  id,
  name,
  model,
}: Device): PickerItemProps => ({
  id,
  name: name ?? '',
  value: name ?? '',
  icon: getDeviceModelIcon(model as DeviceModel),
})

export const mapSiteFunction =
  (input: string) =>
  (site: SiteData): SiteProps => {
    const { id, name, devices: nullableDevices } = site
    const devices = nullableDevices ?? []

    if (isSearchTermInSiteName(input, site)) {
      return {
        id,
        name,
        devices: devices.map(mapDeviceFunction),
      }
    }

    const dropdownDevices = pipe(
      filterDevices(filterDevicesByNameFunction(input)),
      map(mapDeviceFunction)
    )(devices)

    return {
      id,
      name,
      devices: dropdownDevices,
    }
  }

export const filterSitesWithoutDevicesFunction = (site: SiteProps) =>
  site.devices.length > 0

export const getSitePropsFromSites = (sites: SiteData[], input: string) =>
  pipe(
    map(mapSiteFunction(input)),
    input ? filterSiteProps(filterSitesWithoutDevicesFunction) : identity
  )(sites)
