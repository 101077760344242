import { useCallback } from 'react'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import {
  BodySmall,
  Box,
  InfoBox,
  INFOBOX_PRIORITY,
} from '@npco/zeller-design-system'

import { useGetEntityDailyLimit } from 'hooks/useGetEntityDailyLimit'
import { amountFormatter } from 'utils/common'
import {
  combineValidators,
  validateMaxValue,
  validateMinValue,
  validateRequiredCustomMsg,
} from 'utils/formValidation'
import { translate } from 'utils/translations'
import { AmountField } from 'pages/Transfer/TransferFields/fields/AmountField'
import { RecipientDescriptionField } from 'pages/Transfer/TransferFields/fields/RecipientDescriptionField'
import { ReferenceField } from 'pages/Transfer/TransferFields/fields/ReferenceField'
import { errorMessages } from 'translations'

import { contactTransferFieldTranslations } from './ContactTransferField.i18n'

const MIN_AMOUNT = 0.01
const MAX_AMOUNT = 100000

export const ContactTransferFields = () => {
  const flags = useFeatureFlags()
  const t = useTranslations(contactTransferFieldTranslations)
  const {
    dailyMaximumLimit,
    loading: dailyMaximumLimitLoading,
    error: dailyMaximumLimitLoadingError,
  } = useGetEntityDailyLimit()

  const validateContactTransfer = useCallback(
    (fromBalance: number) => {
      return combineValidators(
        validateRequiredCustomMsg(errorMessages.fieldRequired),
        validateMaxValue(fromBalance, errorMessages.transferBiggerThanBalance),
        flags.NoExternalTransferLimit
          ? undefined
          : validateMaxValue(
              MAX_AMOUNT,
              translate('errorMessages.transferTooBig', {
                maxAmount: amountFormatter(MAX_AMOUNT),
              })
            ),
        validateMaxValue(
          dailyMaximumLimit / 100,
          t('dailyMaxLimitExceedError'),
          Boolean(dailyMaximumLimitLoadingError)
        ),
        validateMinValue(MIN_AMOUNT, errorMessages.transferTooSmall)
      )
    },
    [
      t,
      dailyMaximumLimit,
      dailyMaximumLimitLoadingError,
      flags.NoExternalTransferLimit,
    ]
  )

  return (
    <Box mb="2.5rem">
      <AmountField
        validate={validateContactTransfer}
        limitLoading={dailyMaximumLimitLoading}
      />
      <RecipientDescriptionField />
      <ReferenceField />
      <InfoBox priority={INFOBOX_PRIORITY.DEFAULT}>
        <InfoBox.Message>
          <BodySmall style={{ margin: '2px 0' }}>
            {t('transferHoldDisclaimer')}
          </BodySmall>
        </InfoBox.Message>
      </InfoBox>
    </Box>
  )
}
