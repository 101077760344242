import {
  DebitCardTransactionStatusV2,
  DebitCardTransactionTypeV2,
} from '@npco/mp-gql-types'
import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

type PaymentInstrument = NonNullable<
  ContactCoreFieldsFragment['paymentInstruments']
>[0]

export const OPTIMISTIC_ID = 'optimistic'

interface TransferDetails {
  transferInUuid?: string | null
  transferOutUuid?: string
  amountInCents: number
  reference: string
  fromRecipient: NonNullable<
    NonNullable<
      DebitCardTransactionsV2Fragment['payerDetails']
    >['debitCardAccount']
  >
  toRecipient: NonNullable<
    NonNullable<
      DebitCardTransactionsV2Fragment['payeeDetails']
    >['debitCardAccount']
  >
  timestamp: string
}

interface ContactTransferDetails {
  id: string
  amountInCents: number
  reference: string
  referencePayee: string
  selectedContact: ContactCoreFieldsFragment | null
  fromAccount: NonNullable<
    NonNullable<
      DebitCardTransactionsV2Fragment['payerDetails']
    >['debitCardAccount']
  >
  toAccount: PaymentInstrument | undefined
  timestamp: string
  type: DebitCardTransactionTypeV2 | null
}

const createInternalTransactionFields = ({
  amountInCents,
  reference,
  fromRecipient,
  toRecipient,
  timestamp,
}: TransferDetails) => ({
  amount: { value: amountInCents.toString() },
  debitCardName: null,
  description: null,
  merchant: null,
  contact: null,
  payerDetails: {
    accountDetails: {
      bsb: null,
      account: null,
      name: fromRecipient.name,
    },
    bpayDetails: null,
    debitCardAccountUuid: fromRecipient.id,
    debitCardAccount: fromRecipient,
    nppDetails: null,
    paymentInstrumentUuid: null,
    senderUuid: null,
  },
  payeeDetails: {
    accountDetails: {
      bsb: null,
      account: null,
      name: toRecipient.name,
    },
    bpayDetails: null,
    debitCardAccountUuid: toRecipient.id,
    debitCardAccount: toRecipient,
    nppDetails: null,
    paymentInstrumentUuid: null,
  },
  status: DebitCardTransactionStatusV2.PROCESSING,
  reference,
  referencePayee: reference,
  timestamp,
})

const createIncomingOptimisticTransaction = ({
  transferInUuid,
  amountInCents,
  reference,
  fromRecipient,
  toRecipient,
  timestamp,
}: TransferDetails): DebitCardTransactionsV2Fragment => ({
  __typename: 'DebitCardTransactionV2',
  attachments: [],
  id: transferInUuid || OPTIMISTIC_ID,
  ...createInternalTransactionFields({
    amountInCents,
    reference,
    fromRecipient,
    toRecipient,
    timestamp,
  }),
  type: DebitCardTransactionTypeV2.TRANSFER_IN,
  debitCardAccountUuid: toRecipient.id,
  cardAcceptorName: 'cardAcceptorName',
  debitCardId: null,
  debitCardMaskedPan: null,
  category: null,
  subcategory: null,
  subcategoryDetails: null,
  note: null,
  tags: null,
  deposit: null,
})

const createOutgoingOptimisticTransaction = ({
  transferOutUuid,
  amountInCents,
  reference,
  fromRecipient,
  toRecipient,
  timestamp,
}: TransferDetails): DebitCardTransactionsV2Fragment => ({
  __typename: 'DebitCardTransactionV2',
  id: transferOutUuid || OPTIMISTIC_ID,
  ...createInternalTransactionFields({
    amountInCents,
    reference,
    fromRecipient,
    toRecipient,
    timestamp,
  }),
  type: DebitCardTransactionTypeV2.TRANSFER_OUT,
  debitCardAccountUuid: fromRecipient.id,
  debitCardId: null,
  debitCardMaskedPan: null,
  cardAcceptorName: 'cardAcceptorName',
  category: null,
  subcategory: null,
  subcategoryDetails: null,
  note: null,
  tags: null,
  attachments: [],
  deposit: null,
})

export const createInternalOptimisticTransactions = (
  details: TransferDetails
) => [
  createIncomingOptimisticTransaction(details),
  createOutgoingOptimisticTransaction(details),
]

export const createContactOptimisticTransaction = ({
  id,
  amountInCents,
  reference,
  referencePayee,
  selectedContact,
  fromAccount,
  toAccount,
  timestamp,
  type,
}: ContactTransferDetails): DebitCardTransactionsV2Fragment => {
  const { bsb, name, account } = toAccount?.bankAccountDetails ?? {}

  return {
    __typename: 'DebitCardTransactionV2',
    amount: { value: amountInCents.toString() },
    debitCardName: null,
    cardAcceptorName: 'cardAcceptorName',
    description: null,
    id,
    merchant: null,
    contact: selectedContact,
    payerDetails: {
      accountDetails: {
        bsb: null,
        account: null,
        name: fromAccount.name,
      },
      debitCardAccountUuid: fromAccount.id,
      debitCardAccount: fromAccount,
      paymentInstrumentUuid: null,
      senderUuid: null,
    },
    payeeDetails: {
      accountDetails: {
        bsb: bsb ?? null,
        account: account ?? null,
        name: name ?? null,
      },
      bpayDetails: null,
      debitCardAccountUuid: null,
      debitCardAccount: null,
      paymentInstrumentUuid: toAccount?.id ?? null,
    },
    status: DebitCardTransactionStatusV2.PROCESSING,
    reference,
    referencePayee,
    timestamp,
    type: type || DebitCardTransactionTypeV2.DE_OUT,
    debitCardAccountUuid: fromAccount.id,
    debitCardId: null,
    debitCardMaskedPan: null,
    category: null,
    subcategory: null,
    note: null,
    tags: null,
    attachments: [],
    subcategoryDetails: null,
    deposit: null,
  }
}

interface BpayTransferDetails {
  id: string
  amount: string
  billerName: string
  billerCode: string
  crn: string
  nickname: string
  contact: ContactCoreFieldsFragment
  fromAccount: NonNullable<
    NonNullable<
      DebitCardTransactionsV2Fragment['payerDetails']
    >['debitCardAccount']
  >
}

export const createBpayOptimisticTransaction = ({
  id,
  amount,
  crn,
  nickname,
  billerName,
  billerCode,
  fromAccount,
  contact,
}: BpayTransferDetails): DebitCardTransactionsV2Fragment => {
  const timestamp = new Date().toISOString()

  return {
    __typename: 'DebitCardTransactionV2',
    amount: { value: amount },
    debitCardName: null,
    cardAcceptorName: null,
    description: null,
    id,
    merchant: null,
    contact,
    attachments: [],
    payerDetails: {
      accountDetails: {
        bsb: null,
        account: null,
        name: null,
      },
      debitCardAccountUuid: fromAccount.id,
      debitCardAccount: fromAccount,
      paymentInstrumentUuid: null,
      senderUuid: null,
    },
    payeeDetails: {
      accountDetails: null,
      bpayDetails: {
        billerName,
        billerCode,
        crn,
        nickname,
      },
      debitCardAccountUuid: null,
      debitCardAccount: null,
      paymentInstrumentUuid: null,
    },
    status: DebitCardTransactionStatusV2.PROCESSING,
    reference: null,
    referencePayee: null,
    timestamp,
    type: DebitCardTransactionTypeV2.BPAY_OUT,
    debitCardAccountUuid: fromAccount.id,
    debitCardId: null,
    debitCardMaskedPan: null,
    category: null,
    subcategory: null,
    note: null,
    tags: null,
    subcategoryDetails: null,
    deposit: null,
  }
}
