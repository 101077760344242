import { gql } from '@apollo/client'

export const UpdateDeviceName = gql`
  mutation UpdateDeviceName(
    $entityUuid: ID!
    $deviceUuid: ID!
    $name: String!
  ) {
    updateDeviceName(
      entityUuid: $entityUuid
      deviceUuid: $deviceUuid
      name: $name
    )
  }
`
