import { useCallback } from 'react'
import { ButtonLink } from '@npco/zeller-design-system'
import { defaultInvoiceLineItem } from 'features/Invoicing/components/Invoices/Invoice/components/InvoiceCreateForm/InvoiceCreateForm.utils'
import {
  InvoiceFormFields,
  InvoiceItem,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useFormikContext } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

export const translations = {
  itemsFormAddLineItem: translate(
    'page.invoice.formSections.items.addLineItem'
  ),
  itemsFormLineItem: translate('page.invoice.formSections.items.lineItem'),
}

interface InvoiceItemsAddLineItemProps {
  onAddLineItem: (values: InvoiceItem) => void
}

export const InvoiceItemsAddLineItem = ({
  onAddLineItem: handleAddLineItem,
}: InvoiceItemsAddLineItemProps) => {
  const { values } = useFormikContext<InvoiceFormFields>()
  const isMobileResolution = useIsMobileResolution()

  const itemCount = values.items.length
  const taxApplicable = values.itemsApplyTax

  const handleClick = useCallback(
    () =>
      handleAddLineItem({
        ...defaultInvoiceLineItem,
        key: itemCount + 1,
        taxApplicable,
      }),
    [handleAddLineItem, itemCount, taxApplicable]
  )

  return (
    <ButtonLink
      aria-label="invoice add line item"
      data-testid="invoicing-invoice-add-line-item"
      onClick={handleClick}
    >
      {isMobileResolution
        ? translations.itemsFormLineItem
        : translations.itemsFormAddLineItem}
    </ButtonLink>
  )
}
