import styled from 'styled-components'

export const StyledDetailsTable = styled.ul`
  padding: 0;
  margin-top: 0;
  margin-bottom: 2.5rem;
`

export const StyledDetailsListItemsDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_150};
`
