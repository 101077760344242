import { gql } from '@apollo/client'

import { ContactContent } from '../../ContactContent'

export const TransactionContactQuery = gql`
  query TransactionContactQuery(
    $debitCardTransactionUuid: ID!
    $entityUuid: ID!
  ) {
    getDebitCardTransactionV2(
      debitCardTransactionUuid: $debitCardTransactionUuid
      entityUuid: $entityUuid
    ) {
      __typename
      id
      merchant {
        __typename
        id
        ...ContactContentMerchantDetailsFragment
      }
    }
  }

  ${ContactContent.fragments.MerchantDetails}
`
