import { useCallback, useMemo, useRef } from 'react'
import {
  ErrorMessageForm,
  INPUT_SIZE,
  InputAdaptive,
} from '@npco/zeller-design-system'
import currency from 'currency.js'
import {
  INVOICE_DEFAULT_PRICE,
  INVOICE_ITEMS_CALCULATE_PRICE_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { useField } from 'formik'

import { useInputHandlers } from 'hooks/useInputHandlers/useInputHandlers'
import { convertNumberToLocaleString } from 'utils/localeString'
import { translate } from 'utils/translations'
import { CurrencySymbolWrapper } from 'components/InputAdaptiveCurrencyField/InputAdaptiveCurrencyField.styled'

import { getTaxRate } from '../../../../InvoiceItemsAccordion.utils'

export const translations = {
  ariaLabel: translate(
    'page.invoice.formSections.items.itemsCalculatePriceAriaLabel'
  ),
}

export interface InvoiceItemCalculatePriceProps {
  isTaxApplicable: boolean
  isTaxInclusive: boolean
}

export const InvoiceItemCalculatePrice = ({
  isTaxApplicable,
  isTaxInclusive,
}: InvoiceItemCalculatePriceProps) => {
  const [field, meta, helpers] = useField<currency>(
    INVOICE_ITEMS_CALCULATE_PRICE_FIELD
  )

  const taxRate = getTaxRate(isTaxApplicable, isTaxInclusive)

  const inputRef = useRef<HTMLInputElement>(null)

  const { handleCurrencyMax6DigitInputKeydown } = useInputHandlers()

  // NOTE: set default value for initial render of editable input
  const defaultValue = useMemo(() => {
    return currency(field.value.multiply(taxRate)).toString()
  }, [field.value, taxRate])

  const hasError = Boolean(meta.error)

  const handleOnBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const calculatePriceValue = event.target.value

      if (!calculatePriceValue && inputRef?.current) {
        inputRef.current.value = INVOICE_DEFAULT_PRICE
        return
      }

      const number = Number(calculatePriceValue)

      if (!Number.isNaN(number) && inputRef?.current) {
        inputRef.current.value = convertNumberToLocaleString(number)

        const nextPrice = currency(calculatePriceValue, {
          precision: 4,
        }).divide(taxRate)

        helpers.setValue(nextPrice)
      }
    },
    [helpers, taxRate]
  )

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      const nextPrice = isTaxInclusive
        ? currency(value, { precision: 4 }).divide(1.1)
        : currency(value, { precision: 4 })

      helpers.setValue(nextPrice)
    },
    [isTaxInclusive, helpers]
  )

  const handleOnFocus = useCallback((event) => {
    const isZeroPrice = event.target.value === INVOICE_DEFAULT_PRICE

    if (inputRef?.current) {
      // NOTE: if zero price we clear the value so the user can more easily type
      // in their next value
      if (isZeroPrice) {
        inputRef.current.value = ''
      } else {
        inputRef.current.value = inputRef.current.value.replace(/,/g, '')
      }
    }
  }, [])

  const renderLeftControls = useCallback(
    () => <CurrencySymbolWrapper>$</CurrencySymbolWrapper>,
    []
  )

  return (
    <>
      <InputAdaptive
        aria-label={translations.ariaLabel}
        data-testid="invoicing-invoice-line-item-calculate-price"
        defaultValue={defaultValue}
        inputMode="numeric"
        name={INVOICE_ITEMS_CALCULATE_PRICE_FIELD}
        onKeyDown={handleCurrencyMax6DigitInputKeydown}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        renderLeftControls={renderLeftControls}
        ref={inputRef}
        size={INPUT_SIZE.SMALL}
        style={{ textAlign: 'right' }}
      />
      <ErrorMessageForm
        hasError={Boolean(hasError)}
        errorMessage={meta.error}
      />
    </>
  )
}
