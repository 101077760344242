import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { StyledItemsWrapper } from 'components/PickerElements/Elements.styled'
import { StyledDropdownWrapper } from 'components/Shared'

export const StyledDropdown = styled(StyledDropdownWrapper)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) and (max-width: ${BREAKPOINT.LG -
    1}px) {
    width: 100%;
  }
`
export const StyledItemsBox = styled(StyledItemsWrapper)`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    width: 100%;
  }
`

export const StyledStatusesBox = styled.ul`
  margin: 0;
  padding: 0;
  overflow: auto;
`

export const StyledOpenOptions = styled(StyledItemsWrapper)`
  @media screen and (max-width: ${BREAKPOINT.XS - 1}px) {
    transform: translateY(-112px);
  }
`
