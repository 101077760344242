import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { ButtonLink } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { SectionHeader } from '../../../SectionHeader'
import { translations } from './LatestTransactionsHeader.i18n'

export const LatestTransactionsHeader = () => {
  const t = useTranslations(translations)

  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const navigateToAllAccountsTransactions = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).ACCOUNTS.TRANSACTIONS.path)
  }, [navigate, shortEntityId])

  return (
    <SectionHeader heading={t('heading')}>
      <ButtonLink onClick={navigateToAllAccountsTransactions}>
        {t('viewAll')}
      </ButtonLink>
    </SectionHeader>
  )
}
