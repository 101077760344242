import { SkeletonAvatar, SkeletonBasic } from '@npco/zeller-design-system'

import * as styled from './LoaderSkeleton.styled'

const Rows = Array.from(Array(6).keys()).map((key) => (
  <styled.liGroup key={key} aria-hidden>
    <styled.Row>
      <div style={{ height: 18 }}>
        <SkeletonAvatar width={18} height={18} />
      </div>
      <SkeletonBasic width={10000} height={14} borderRadius="7px" />
    </styled.Row>
  </styled.liGroup>
))

export const LoaderSkeleton = (): JSX.Element => (
  <div data-testid="loader-skeleton">{Rows}</div>
)
