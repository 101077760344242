import { BREAKPOINT, Flex, zIndexMap } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const NavbarWrapper = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 1px 0 ${({ theme }) => theme.colors.GREY_150};
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  width: 100%;
  z-index: ${zIndexMap.mobileNavBar};

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: none;
  }
`

// NOTE: temporary wrapper for routes using the new page template component
export const PageTemplateWrapper = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.WHITE};
  box-shadow: 0 1px 0 ${({ theme }) => theme.colors.GREY_150};
  justify-content: center;
  min-height: 64px;
  width: 100%;
  z-index: 9999;

  @media screen and (min-width: ${BREAKPOINT.MD}px) {
    display: none;
  }
`

export const MenuIconWrapper = styled.button`
  background: none;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  left: 8px;
  padding: 8px;
  position: absolute;
  top: 16px;

  &:disabled {
    cursor: not-allowed;
  }
`
