import { RangeModifierExt } from '@npco/zeller-design-system'

import dayjs from 'utils/dayjs'

export const getDoesMonthIncludeDaysOutsideRange = (
  data: RangeModifierExt,
  month: Date
) =>
  data.from &&
  !data.to &&
  dayjs(month).endOf('month').isAfter(dayjs(data.from).add(365, 'days'))
