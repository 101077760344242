import { useParams } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import shortUUID from 'short-uuid'

import { DEFAULT_ENTITY, UUID_REGEXP } from '../../constants'
import { rvEntityDetails } from '../../graphql/reactiveVariables'
import { useConvertUuidToShortUuid } from '../useConvertUuidToShortUuid/useConvertUuidToShortUuid'

export const useSelectedShortEntityUuid = () => {
  const translator = shortUUID()
  const flags = useFeatureFlags()
  const convertUuidToShortUuid = useConvertUuidToShortUuid()

  const { entityId } = useParams()

  if (!flags.MultiEntity) {
    return DEFAULT_ENTITY
  }

  if (!entityId || entityId === DEFAULT_ENTITY) {
    // should get the actual default entity id here
    const defaultEntityId = rvEntityDetails().id
    return convertUuidToShortUuid(defaultEntityId)
  }

  if (UUID_REGEXP.test(entityId)) {
    return convertUuidToShortUuid(entityId)
  }

  if (!translator.validate(entityId)) {
    return ''
  }

  return entityId
}
