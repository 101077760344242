import { AnchorBasic } from '@npco/zeller-design-system'

import {
  PRIVACY_POLICY_URL,
  ZELLER_SIM,
  ZELLER_SUPPORT_URL,
} from 'const/externalLinks'
import { standardForm } from 'translations'

import {
  StyledGeneralText,
  StyledListMainItem,
  StyledListNestedItem,
  StyledListRomanItem,
  StyledListSubItem,
  StyledMainList,
  StyledNestedList,
  StyledRomanList,
  StyledSubList,
  StyledSummaryText,
} from './ActivateSimCardModal.styled'

const PointOne = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.first.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.first.pointOne.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.first.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.first.pointOne.items.second}
          </StyledListNestedItem>
        </StyledNestedList>
      </StyledSubList>
    </>
  )
}

const PointTwo = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.second.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.second.pointOne.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.second.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.second.pointOne.items.second}
          </StyledListNestedItem>
        </StyledNestedList>
        <StyledListSubItem>
          {standardForm.list.second.pointTwo.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.second.pointTwo.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.second.pointTwo.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.second.pointTwo.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.second.pointThree.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.second.pointThree.items.general}
        </StyledGeneralText>

        <StyledListSubItem>
          {standardForm.list.second.pointFour.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.second.pointFour.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.second.pointFour.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.second.pointFive.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.second.pointFive.items.general}
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

const PointThree = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.third.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.third.pointOne.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.third.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointOne.items.second}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointOne.items.third}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.third.pointTwo.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.third.pointTwo.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.third.pointTwo.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointTwo.items.second}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointTwo.items.third}
          </StyledListNestedItem>
        </StyledNestedList>
        <StyledGeneralText>
          {standardForm.list.third.pointTwo.items.infoOne}
        </StyledGeneralText>

        <StyledListSubItem>
          {standardForm.list.third.pointThree.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.third.pointThree.items.general}
        </StyledGeneralText>

        <StyledListSubItem>
          {standardForm.list.third.pointFour.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.third.pointFour.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointFour.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.third.pointFive.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.third.pointFive.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointFive.items.second}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.third.pointFive.items.third}
          </StyledListNestedItem>
        </StyledNestedList>
      </StyledSubList>
    </>
  )
}

export const PointFour = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.fourth.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.fourth.pointOne.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.fourth.pointOne.items.general}
        </StyledGeneralText>

        <StyledListSubItem>
          {standardForm.list.fourth.pointTwo.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.fourth.pointTwo.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.fourth.pointTwo.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.fourth.pointThree.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.fourth.pointThree.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.fourth.pointThree.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.fourth.pointFour.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.fourth.pointFour.items.general}
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

const PointFive = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.fifth.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.fifth.pointOne.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.fifth.pointOne.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.fifth.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.fifth.pointOne.items.second}
          </StyledListNestedItem>
          <StyledRomanList>
            <StyledListRomanItem>
              {standardForm.list.fifth.pointOne.items.romanOne}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.fifth.pointOne.items.romanTwo}
            </StyledListRomanItem>
          </StyledRomanList>
          <StyledListNestedItem>
            {standardForm.list.fifth.pointOne.items.third}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.fifth.pointOne.items.fourth}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.fifth.pointTwo.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.fifth.pointTwo.items.general}
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

export const PointSix = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.sixth.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.sixth.pointOne.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.sixth.pointOne.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointOne.items.first}
          </StyledListNestedItem>
          <StyledRomanList>
            <StyledListRomanItem>
              {standardForm.list.sixth.pointOne.items.romanOne}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.sixth.pointOne.items.romanTwo}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.sixth.pointOne.items.romanThree}
            </StyledListRomanItem>
          </StyledRomanList>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointOne.items.second}
          </StyledListNestedItem>
          <StyledRomanList>
            <StyledListRomanItem>
              {standardForm.list.sixth.pointOne.items.romanFour}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.sixth.pointOne.items.romanFive}
            </StyledListRomanItem>
          </StyledRomanList>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.sixth.pointTwo.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.sixth.pointTwo.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointTwo.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointTwo.items.second}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointTwo.items.third}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.sixth.pointThree.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointThree.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointThree.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.sixth.pointFour.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.sixth.pointFour.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointFour.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointFour.items.second}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointFour.items.third}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.sixth.pointFour.items.fourth}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.sixth.pointFive.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.sixth.pointFive.items.general}
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

export const PointSeven = () => {
  return (
    <>
      <StyledListMainItem>
        {standardForm.list.seventh.header}
      </StyledListMainItem>
      <StyledSubList>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.seventh.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.seventh.pointOne.items.second}
          </StyledListNestedItem>
        </StyledNestedList>
      </StyledSubList>
    </>
  )
}

const PointEight = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.eighth.header}</StyledListMainItem>
      <StyledSubList>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.eighth.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.eighth.pointOne.items.second}
          </StyledListNestedItem>
        </StyledNestedList>
      </StyledSubList>
    </>
  )
}

const PointNine = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.ninth.header}</StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.ninth.pointOne.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.ninth.pointOne.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointOne.items.second}
          </StyledListNestedItem>
        </StyledNestedList>
        <StyledGeneralText>
          {standardForm.list.ninth.pointOne.items.infoOne}
        </StyledGeneralText>

        <StyledListSubItem>
          {standardForm.list.ninth.pointTwo.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointTwo.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointTwo.items.second}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointTwo.items.third}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.ninth.pointThree.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.ninth.pointThree.items.general}
        </StyledGeneralText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointThree.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointThree.items.second}
          </StyledListNestedItem>
          <StyledRomanList>
            <StyledListRomanItem>
              {standardForm.list.ninth.pointThree.items.romanOne}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.ninth.pointThree.items.romanTwo}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.ninth.pointThree.items.romanThree}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.ninth.pointThree.items.romanFour}
            </StyledListRomanItem>
            <StyledListRomanItem>
              {standardForm.list.ninth.pointThree.items.romanFive}
            </StyledListRomanItem>
          </StyledRomanList>
          <StyledListNestedItem>
            {standardForm.list.ninth.pointThree.items.third}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.ninth.pointFour.subheader}
        </StyledListSubItem>
        <StyledGeneralText>
          {standardForm.list.ninth.pointFour.items.general}
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

const PointTen = () => {
  return (
    <>
      <StyledListMainItem>{standardForm.list.tenth.header}</StyledListMainItem>
      <StyledSubList>
        <StyledGeneralText>
          {standardForm.list.tenth.pointOne.items.general}
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

const PointEleven = () => {
  return (
    <>
      <StyledListMainItem>
        {standardForm.list.eleventh.header}
      </StyledListMainItem>
      <StyledSubList>
        <StyledGeneralText>
          {standardForm.list.eleventh.pointOne.items.general}
          &nbsp;
          <AnchorBasic href={PRIVACY_POLICY_URL}>
            {standardForm.list.eleventh.pointOne.items.link}
          </AnchorBasic>
        </StyledGeneralText>
      </StyledSubList>
    </>
  )
}

const PointTwelve = () => {
  return (
    <>
      <StyledListMainItem>
        {standardForm.list.twelfth.header}
      </StyledListMainItem>
      <StyledSubList>
        <StyledListSubItem>
          {standardForm.list.twelfth.pointOne.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.twelfth.pointOne.items.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.twelfth.pointOne.items.second}
          </StyledListNestedItem>
        </StyledNestedList>

        <StyledListSubItem>
          {standardForm.list.twelfth.pointTwo.subheader}
        </StyledListSubItem>
        <StyledNestedList>
          <StyledGeneralText>
            {standardForm.list.twelfth.pointTwo.items.general}
          </StyledGeneralText>
        </StyledNestedList>
      </StyledSubList>
    </>
  )
}

const PointThirteen = () => {
  return (
    <>
      <StyledListMainItem>
        {standardForm.list.thirteenth.header}
      </StyledListMainItem>
      <StyledSubList>
        <StyledGeneralText>
          {standardForm.list.thirteenth.list.general}
        </StyledGeneralText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.one.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.one.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.two.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.two.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.three.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.three.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.four.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.four.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.five.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.five.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.six.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.six.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.seven.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.seven.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.eight.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.eight.info}
        </StyledSummaryText>
        <StyledNestedList>
          <StyledListNestedItem>
            {standardForm.list.thirteenth.list.eight.first}
          </StyledListNestedItem>
          <StyledListNestedItem>
            {standardForm.list.thirteenth.list.eight.second}
          </StyledListNestedItem>
        </StyledNestedList>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.nine.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.nine.info}&nbsp;
          <AnchorBasic href={ZELLER_SUPPORT_URL}>
            {standardForm.list.thirteenth.list.nine.link}
          </AnchorBasic>
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.ten.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.ten.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.eleven.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.eleven.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.twelve.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.twelve.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.thirteen.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.thirteen.info}&nbsp;
          <AnchorBasic href={ZELLER_SIM}>
            {standardForm.list.thirteenth.list.thirteen.link}
          </AnchorBasic>
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.fourteen.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.fourteen.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.fifteen.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.fifteen.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.sixteen.title}</span>&nbsp;
          {standardForm.list.thirteenth.list.sixteen.info}
        </StyledSummaryText>
        <StyledSummaryText>
          <span> {standardForm.list.thirteenth.list.seventeen.title}</span>
          &nbsp;
          {standardForm.list.thirteenth.list.seventeen.info}
        </StyledSummaryText>
      </StyledSubList>
    </>
  )
}

export const StandardForm = () => {
  return (
    <StyledMainList>
      <PointOne />

      <PointTwo />

      <PointThree />

      <PointFour />

      <PointFive />

      <PointSix />

      <PointSeven />

      <PointEight />

      <PointNine />

      <PointTen />

      <PointEleven />

      <PointTwelve />

      <PointThirteen />
    </StyledMainList>
  )
}
