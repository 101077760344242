import { PageTemplate } from '@npco/zeller-design-system'

import { CardsSecondaryRow } from './CardsSecondaryRow'
import { CardsTopPanel } from './CardsTopPanel'
import { CardsListLayoutProvider } from './context/CardsListLayoutProvider'
import { useGetEntityForCardsListLayout } from './hooks/useGetEntityForCardListLayout/useGetEntityForCardListLayout'

interface CardsListLayoutProps {
  children: React.ReactNode
}

export const CardsListLayout = ({ children }: CardsListLayoutProps) => {
  const { entity, isLoadingEntity } = useGetEntityForCardsListLayout()

  return (
    <CardsListLayoutProvider>
      <PageTemplate
        HeaderPrimaryRow={
          <CardsTopPanel isLoadingEntity={isLoadingEntity} entity={entity} />
        }
        HeaderSecondaryRow={<CardsSecondaryRow />}
      >
        {children}
      </PageTemplate>
    </CardsListLayoutProvider>
  )
}
