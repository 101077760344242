import { useTranslations } from '@npco/utils-translations'

import { LeavingRoutePromptGuard } from '../LeavingRoutePromptGuard/LeavingRoutePromptGuard'
import { translations } from './SettingsLeavingRoutePromptGuard.i18n'

type SettingsLeavingRoutePromptGuardProps = {
  resetForm?: () => void
  submitForm?: () => Promise<void>
}

export const SettingsLeavingRoutePromptGuard = ({
  resetForm: resetFormOverride,
  submitForm: submitFormOverride,
}: SettingsLeavingRoutePromptGuardProps) => {
  const t = useTranslations(translations)

  return (
    <LeavingRoutePromptGuard
      dataTestId="settings-leaving-route-prompt-guard"
      message={t('message')}
      title={t('title')}
      resetForm={resetFormOverride}
      submitForm={submitFormOverride}
    />
  )
}
