import { useCallback } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { getAllContactEmails } from 'features/Contacts/Contacts.utils'
import { useContactCache } from 'features/Contacts/hooks/useContactCache/useContactCache'
import {
  INVOICE_ATTENTION_TO_FIELD,
  INVOICE_EMAIL_RECIPIENT_FIELD,
} from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'

import { getCustomerFieldError } from '../InvoiceCustomerAccordion.utils'

export const useAttentionToField = () => {
  const { readFromCache } = useContactCache()
  const {
    setFieldValue,
    setValues,
    values: {
      delivery: {
        email: { recipient: emailAddress },
      },
    },
    errors,
  } = useFormikContext<InvoiceFormFields>()

  const [field] = useField({
    name: INVOICE_ATTENTION_TO_FIELD,
  })

  const clearEmailAddressField = useCallback(() => {
    const attentionToEmails = getAllContactEmails(
      readFromCache(field.value, true)
    )
    if (attentionToEmails?.some((email) => email.email === emailAddress)) {
      setFieldValue(INVOICE_EMAIL_RECIPIENT_FIELD, '')
    }
  }, [emailAddress, field.value, readFromCache, setFieldValue])

  const clearField = useCallback(() => {
    setValues((prevValues) => ({
      ...prevValues,
      customer: {
        ...prevValues.customer,
        attentionToContact: null,
        attentionToId: '',
      },
    }))
    clearEmailAddressField()
  }, [clearEmailAddressField, setValues])

  const setAttentionToValue = useCallback(
    (contactName: string, contactType: ContactType, contactUuid: string) => {
      setValues((prevValues) => {
        return {
          ...prevValues,
          customer: {
            ...prevValues.customer,
            attentionToId: contactUuid,
            attentionToContact: {
              contactName,
              contactType,
              contactUuid,
            },
          },
        }
      })
    },
    [setValues]
  )

  return {
    clearEmailAddressField,
    clearField,
    fieldError: getCustomerFieldError('payerContact', errors?.customer),
    fieldValue: field.value,
    handleOnBlur: field.onBlur,
    setAttentionToValue,
  }
}
