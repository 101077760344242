import { Entity } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  DecisionModal,
  InfoBox,
  INFOBOX_VARIANT,
  ModalSize,
  Typography,
} from '@npco/zeller-design-system'

import { removeBusinessModalTranslations } from './RemoveBusinessModal.i18n'

export interface RemoveBusinessModalProps {
  isOpen: boolean
  hide: () => void
  onConfirm: () => void
  businessName: Entity['name']
}

export const RemoveBusinessModal = ({
  isOpen,
  hide,
  onConfirm,
  businessName,
}: RemoveBusinessModalProps) => {
  const t = useTranslations(removeBusinessModalTranslations)
  return (
    <DecisionModal
      title={t('title')}
      isOpen={isOpen}
      onCancel={hide}
      onClickPrimary={onConfirm}
      onClickSecondary={hide}
      primaryButtonLabel={t('primaryButtonLabel')}
      secondaryButtonLabel={t('secondaryButtonLabel')}
      size={ModalSize.MEDIUM}
      zIndex="10005"
      testId="remove-business-modal"
    >
      <Typography component="div" variant="heading-md">
        {businessName}
      </Typography>
      <Box marginTop="24px">
        <InfoBox variant={INFOBOX_VARIANT.WARNING}>
          {t('warningMessage')}
        </InfoBox>
      </Box>
    </DecisionModal>
  )
}
