import { useCallback, useMemo } from 'react'
import {
  BREAKPOINT,
  EducationPanel,
  useModalState,
} from '@npco/zeller-design-system'
import { useGetZellerInvoiceSettings } from 'features/Invoicing/components/Settings/hooks/useGetZellerInvoiceSettings'

import InvoicingDesktop from 'assets/images/invoicing-desktop.png'
import InvoicingMobile from 'assets/images/invoicing-mobile.png'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

import { useInvoicingListHandlers } from '../../../../routes/InvoicingListRoutes/hooks/useInvoicingListHandlers'
import { BusinessNameModal } from './BusinessNameModal/BusinessNameModal'

export const translations = {
  alt: translate('page.invoices.panel.image'),
  createInvoice: translate('page.invoices.createInvoice'),
  ctaDescription: translate('page.invoices.panel.createInvoiceDescription'),
  description: translate('page.invoices.panel.description'),
  invoices: translate('page.invoices.title'),
  item1: translate('page.invoices.panel.item1'),
  item2: translate('page.invoices.panel.item2'),
  item3: translate('page.invoices.panel.item3'),
  item4: translate('page.invoices.panel.item4'),
  title: translate('page.invoices.panel.title'),
}

const items = [
  translations.item1,
  translations.item2,
  translations.item3,
  translations.item4,
]

export const InvoiceEmptyState = () => {
  const { handleClickInvoice } = useInvoicingListHandlers()
  const { isLoading, receiptName } = useGetZellerInvoiceSettings()

  const { isModalOpen, openModal, closeModal } = useModalState()
  const isMobileResolution = useIsMobileResolution(BREAKPOINT.SM)

  const handleOnClick = useCallback(() => {
    if (!isLoading && (!receiptName || receiptName === 'Zeller Invoice')) {
      openModal()
      return
    }
    handleClickInvoice()
  }, [isLoading, receiptName, openModal, handleClickInvoice])

  const ctaPrimary = useMemo(
    () => ({
      text: translations.createInvoice,
      onClick: handleOnClick,
    }),
    [handleOnClick]
  )

  const image = useMemo(() => {
    const source = isMobileResolution ? InvoicingMobile : InvoicingDesktop
    const width = isMobileResolution ? '343' : '417'
    return <img alt={translations.alt} src={source} width={width} />
  }, [isMobileResolution])

  return (
    <>
      <BusinessNameModal isOpen={isModalOpen} closeModal={closeModal} />
      <EducationPanel
        ctaDescription={translations.ctaDescription}
        ctaPrimary={ctaPrimary}
        description={translations.description}
        image={image}
        items={items}
        title={translations.title}
      />
    </>
  )
}
