import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

export const useCloseAccountModal = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const openCloseAccountModal = useCallback(
    (accountId: string) => {
      navigate(location.pathname, {
        state: {
          CloseAccountModal: { accountId },
        },
      })
    },
    [navigate, location.pathname]
  )

  const closeModal = useCallback(() => {
    navigate(location.pathname, { state: { CloseAccountModal: undefined } })
  }, [navigate, location.pathname])

  return { openCloseAccountModal, closeModal }
}
