import { gql } from '@apollo/client'
import { DebitCardStatus } from '@npco/mp-gql-types'
import { Box } from '@npco/zeller-design-system'

import { CardSummary } from 'components/CardSummary/CardSummary'

import { CardDisplay } from '../CardSummary/components/CardDisplay/CardDisplay'
import { ZellerCard } from '../ZellerCard/ZellerCard'
import { ZellerCardDetails } from './ZellerCardDetails/ZellerCardDetails'
import { ZellerCardSummaryDebitCardV2Fragment } from './ZellerCardSummary.generated'
import { getCardSummaryBackground } from './ZellerCardSummary.utils'

interface ZellerCardSummaryProps {
  card: ZellerCardSummaryDebitCardV2Fragment
  timezone?: string
  isLoadingStatus?: boolean
}

export const ZellerCardSummary = ({
  card,
  timezone,
  isLoadingStatus = false,
}: ZellerCardSummaryProps) => {
  const isClosed = card.status === DebitCardStatus.CLOSED
  const backgroundColour = getCardSummaryBackground(card.colour, isClosed)

  return (
    <CardSummary
      backgroundColour={backgroundColour}
      cardDisplay={
        <Box border="solid 1px transparent" position="relative">
          <CardDisplay card={card} isLoadingStatus={isLoadingStatus}>
            <ZellerCard debitCard={card} variant="masked" />
          </CardDisplay>
        </Box>
      }
      cardDetails={<ZellerCardDetails card={card} timezone={timezone} />}
    />
  )
}

ZellerCardSummary.fragments = {
  DebitCardV2: gql`
    fragment ZellerCardSummaryDebitCardV2Fragment on DebitCardV2 {
      colour
      ...ZellerCardDetailsDebitCardV2Fragment
      ...ZellerCardDebitCardV2Fragment
      ...CardDisplayDebitCardV2Fragment
    }

    ${ZellerCardDetails.fragments.DebitCardV2}
    ${ZellerCard.fragments.DebitCardV2}
    ${CardDisplay.fragments.DebitCardV2}
  `,
}
