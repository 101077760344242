import { TransactionStatus } from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'

import { GetTransactions_getTransactions_transactions as Transaction } from 'types/gql-types/GetTransactions'
import {
  TransactionStatusExtended,
  TransactionStatusLocal,
  TransactionStatusVariants,
} from 'components/StatusBadge/StatusBadge.types'
import { component } from 'translations'

const variants: TransactionStatusVariants = {
  [TransactionStatus.APPROVED]: {
    text: component.transaction.statusBadge.approved,
    color: COLOR.GREEN_1600,
  },
  [TransactionStatus.DECLINED]: {
    text: component.transaction.statusBadge.declined,
    color: COLOR.RED_1000,
  },
  [TransactionStatusLocal.REFUNDED]: {
    text: component.transaction.statusBadge.refunded,
    color: COLOR.BLACK,
  },
  [TransactionStatusLocal.PARTIAL_REFUND]: {
    text: component.transaction.statusBadge.partialRefund,
    color: COLOR.BLACK,
  },
  [TransactionStatus.PROCESSING]: {
    text: component.transaction.statusBadge.processing,
    color: COLOR.GREY_550,
  },
  [TransactionStatus.DELETED]: {
    text: component.transaction.statusBadge.deleted,
    color: COLOR.RED_1000,
  },
  [TransactionStatusLocal.CHARGEBACK]: {
    text: component.transaction.statusBadge.chargeback,
    color: COLOR.ORANGE_1000,
  },
}

const getBadgeStatus = (
  transaction: Transaction
): TransactionStatusExtended => {
  if (transaction.refunded) {
    if (transaction.refundedAmount === transaction.amount) {
      return TransactionStatusLocal.REFUNDED
    }
    return TransactionStatusLocal.PARTIAL_REFUND
  }
  if (transaction.review) {
    return TransactionStatusLocal.CHARGEBACK
  }

  return transaction.status
}

export const getTransactionBadgeVariant = (transaction: Transaction) => {
  const status = getBadgeStatus(transaction)
  return variants[status]
}
