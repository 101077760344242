import { Box, BREAKPOINT, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const TruncateText = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ContentContainer = styled(Flex)`
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  gap: 4px;
`

export const ProgressContainer = styled(Box)`
  display: block;

  @media (min-width: ${BREAKPOINT.MD}px) {
    display: none;
  }
`
