import { useMemo, useState } from 'react'
import {
  Flex,
  PopperMobileTitleGroup,
  StyledPopperModalMobile,
} from '@npco/zeller-design-system'
import { v4 as randomUUID } from 'uuid'

import { SCROLLBAR } from 'const/scrollbars'

import { SelectBasicMobileProps, SelectItemBasic } from '../../Select.types'
import { StyledOptionsList } from './SelectBasicMobile.styled'
import { SelectBasicMobileItem } from './SelectBasicMobileItem'

export const SelectBasicMobile = <ItemType extends SelectItemBasic>({
  renderTrigger,
  renderAdditionalControls,
  renderLabel,
  renderItem,
  items,
  menuStyle,
  selectedItem,
  onChange,
  onOpen,
  onClose,
  onFocus,
  isDisabled,
  mobileLabel,
  hasError,
  renderNoItemsPlaceholder = () => null,
  filterItems = (item: ItemType) => Boolean(item),
  shouldCloseOnSelect = true,
  renderBottomSection,
  isLoading = false,
  renderLoadingPlaceholder,
  hasSelectedIndicator,
  shouldAutoFocusControl,
}: SelectBasicMobileProps<ItemType>) => {
  const id = useMemo(() => randomUUID(), [])

  const [isOpen, setIsOpen] = useState(false)

  const open = () => {
    setIsOpen(true)
    onOpen?.(selectedItem)
  }

  const close = () => {
    setIsOpen(false)
    onClose?.(selectedItem)
  }

  const onSelectItem = (item: ItemType) => {
    onChange(item)

    if (shouldCloseOnSelect) {
      setIsOpen(false)
      setTimeout(() => onClose?.(item), 0)
    }
  }

  const filteredItems = items.filter(filterItems)

  const shouldDisplayLoadingPlaceholder = isLoading && renderLoadingPlaceholder
  const shouldDisplayNoItemsPlaceholder =
    !filteredItems.length &&
    !shouldDisplayLoadingPlaceholder &&
    Boolean(renderNoItemsPlaceholder)
  const shouldDisplayItems =
    !shouldDisplayNoItemsPlaceholder && !shouldDisplayLoadingPlaceholder

  return (
    <div data-testid="dropdown-mobile">
      {renderLabel?.({ htmlFor: id, id: `${id}-label` })}
      {renderTrigger({
        id,
        onClick: open,
        onFocus: () => onFocus?.(isOpen),
        isOpen,
        selectedItem,
        disabled: isDisabled,
        hasError,
        'data-testid': 'select-trigger',
      })}

      <StyledPopperModalMobile
        isOpen={isOpen}
        overlayClassName="modal-basic-overlay"
      >
        <Flex
          flexDirection="column"
          style={{ height: '100%' }}
          pr={SCROLLBAR.SM.rightSpacing}
        >
          <PopperMobileTitleGroup onClick={() => close()}>
            {mobileLabel}
          </PopperMobileTitleGroup>

          {renderAdditionalControls?.({
            closeMenu: close,
            shouldAutoFocusControl,
          })}
          <StyledOptionsList
            isEmpty={shouldDisplayNoItemsPlaceholder}
            menuStyle={menuStyle}
          >
            {shouldDisplayLoadingPlaceholder &&
              renderLoadingPlaceholder?.({
                isMobileVariant: true,
              })}
            {shouldDisplayNoItemsPlaceholder &&
              renderNoItemsPlaceholder({ closeMenu: close })}
            {shouldDisplayItems &&
              filteredItems.map((item) => (
                <SelectBasicMobileItem
                  idBase={id}
                  key={String(item.value)}
                  isSelected={selectedItem?.value === item.value}
                  onSelectItem={onSelectItem}
                  item={item}
                  renderItem={renderItem}
                  hasSelectedIndicator={hasSelectedIndicator}
                />
              ))}
          </StyledOptionsList>
          {renderBottomSection?.({
            isOpen,
            onClose: close,
          })}
        </Flex>
      </StyledPopperModalMobile>
    </div>
  )
}
