import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { ReportLostAndReplaceCardState } from './useGoToReportLostAndReplaceCardStage.types'

export const useGoToReportLostAndReplaceCardStage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const goToReportLostAndReplaceCardStage = useCallback(
    (state: ReportLostAndReplaceCardState | undefined) => {
      navigate(location.pathname, {
        state: { ReportLostAndReplaceCardModal: state },
      })
    },
    [navigate, location]
  )

  return { goToReportLostAndReplaceCardStage }
}
