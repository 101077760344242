import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type DebitCardAccountUpdateSubscriptionVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type DebitCardAccountUpdateSubscriptionResponse = {
  __typename?: 'Subscription'
  onDebitCardAccountUpdate: {
    __typename?: 'DebitCardAccountV2Update'
    id: string
    type: Types.DebitCardAccountType
    name: string
    status: Types.DebitCardAccountStatus
    icon: {
      __typename?: 'Icon'
      colour: string | null
      letter: string | null
      image: string | null
      animation: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    balance: { __typename?: 'Money'; value: string } | null
    savingsAccountDetails: {
      __typename?: 'SavingsAccountDetails'
      effectiveInterestRate: string | null
    } | null
  } | null
}

export const DebitCardAccountUpdate = gql`
  subscription DebitCardAccountUpdate($entityUuid: ID!) {
    onDebitCardAccountUpdate(entityUuid: $entityUuid) {
      id
      type
      name
      icon {
        colour
        letter
        image
        images {
          size
          url
        }
        animation
      }
      balance {
        value
      }
      status
      savingsAccountDetails {
        effectiveInterestRate
      }
    }
  }
` as unknown as TypedDocumentNode<
  DebitCardAccountUpdateSubscriptionResponse,
  DebitCardAccountUpdateSubscriptionVariables
>

/**
 * __useDebitCardAccountUpdateSubscription__
 *
 * To run a query within a React component, call `useDebitCardAccountUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDebitCardAccountUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitCardAccountUpdateSubscription({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useDebitCardAccountUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DebitCardAccountUpdateSubscriptionResponse,
    DebitCardAccountUpdateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    DebitCardAccountUpdateSubscriptionResponse,
    DebitCardAccountUpdateSubscriptionVariables
  >(DebitCardAccountUpdate, options)
}
export type DebitCardAccountUpdateSubscriptionHookResult = ReturnType<
  typeof useDebitCardAccountUpdateSubscription
>
export type DebitCardAccountUpdateSubscriptionResult =
  Apollo.SubscriptionResult<DebitCardAccountUpdateSubscriptionResponse>
