import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

export type SimStatusCellFragment = {
  __typename?: 'Sim'
  billingStatus: Types.SimBillingStatus
}

export const SimStatusCellFragmentDoc = gql`
  fragment SimStatusCellFragment on Sim {
    billingStatus
  }
` as unknown as TypedDocumentNode<SimStatusCellFragment, undefined>
