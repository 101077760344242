import { useCallback, useMemo, useState } from 'react'

import { SelectedDateRangeContext } from './SelectedDateRange.context'
import { DateRange } from './SelectedDateRange.type'

export interface SelectedDateRangeProviderProps {
  initialSelectedDateRange?: DateRange
  children?: React.ReactNode
}

export const SelectedDateRangeProvider = ({
  initialSelectedDateRange = 'Last 12 Months',
  children,
}: SelectedDateRangeProviderProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    initialSelectedDateRange
  )

  const resetDateRange = useCallback(() => {
    setSelectedDateRange('Last 12 Months')
  }, [setSelectedDateRange])

  const providerValue = useMemo(
    () => ({
      selectedDateRange,
      setSelectedDateRange,
      resetDateRange,
    }),
    [resetDateRange, selectedDateRange]
  )

  return (
    <SelectedDateRangeContext.Provider value={providerValue}>
      {children}
    </SelectedDateRangeContext.Provider>
  )
}
