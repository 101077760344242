import React from 'react'
import { AnchorBasic, Box, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ChatIcon } from 'assets/svg/chat-bubble-16px.svg'
import { translate } from 'utils/translations'
import { shared } from 'translations'

import { SMSInfoContainer } from '../../Help.styled'

export const SMSInfo = () => {
  return (
    <SMSInfoContainer>
      <SvgIcon width="16" height="16">
        <ChatIcon />
      </SvgIcon>
      <AnchorBasic
        href={`sms:${shared.contactSupport.smsInfo.replaceAll(' ', '')}`}
        className="support-sms"
      >
        {translate('shared.contactSupport.smsInfoText1')}
      </AnchorBasic>
      <Box ml="4px"> {translate('shared.contactSupport.smsInfoText2')}</Box>
    </SMSInfoContainer>
  )
}
