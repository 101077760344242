import { useCallback } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  BREAKPOINT,
  PageTemplate,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { rvSelectedContact } from 'features/Contacts/rv-deprecated/contacts'
import {
  CreateAccountStage,
  useGoToCreateAccountStage,
} from 'features/CreateAccount'

import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { useAccountFiltersList } from 'components/DebitCardTransactionFilters/hooks/useAccountFiltersList/useAccountFiltersList'

import { useAccountsLayout } from '../hooks/useAccountsLayout/useAccountsLayout'
import { AccountsLayoutPrimaryHeaderRowDesktop } from './AccountsLayoutPrimaryHeaderRowDesktop/AccountsLayoutPrimaryHeaderRowDesktop'
import { AccountsLayoutPrimaryHeaderRowMobile } from './AccountsLayoutPrimaryHeaderRowMobile/AccountsLayoutPrimaryHeaderRowMobile'

export const AccountsLayoutPrimaryHeaderRowDeps = {
  useAccountFilters,
  useAccountFiltersList,
  useGoToCreateAccountStage,
  useIsMobileResolution,
  useReactiveVar,
}

export type AccountsLayoutPrimaryHeaderRowProps = {
  entity: ReturnType<typeof useAccountsLayout>['entity']
  isAllTransactionsRoute: boolean
  isFiltersDisabled: boolean
  isFiltersInDefaultState: boolean
  isFiltersVisible: boolean
  isLoadingEntity: boolean
  setIsFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const AccountsLayoutPrimaryHeaderRow = ({
  entity,
  isAllTransactionsRoute,
  isFiltersDisabled,
  isFiltersInDefaultState,
  isFiltersVisible,
  isLoadingEntity,
  setIsFiltersVisible,
}: AccountsLayoutPrimaryHeaderRowProps) => {
  const {
    useAccountFiltersList,
    useGoToCreateAccountStage,
    useIsMobileResolution,
    useReactiveVar,
  } = AccountsLayoutPrimaryHeaderRowDeps

  const isMobile = useIsMobileResolution(BREAKPOINT.MD)

  const { goToCreateAccountStage } = useGoToCreateAccountStage(entity)

  const selectedContact = useReactiveVar(rvSelectedContact)

  const defaultFilters = {
    selectedAccountID: undefined,
    selectedContactId: selectedContact?.id,
  }

  const { mobileFilters } = useAccountFiltersList({
    defaultFilters,
    skip: !isFiltersVisible,
  })

  const handleOnNextStageClick = useCallback(
    () => goToCreateAccountStage(CreateAccountStage.AccountNameStage),
    [goToCreateAccountStage]
  )

  if (isMobile) {
    return (
      <PageTemplate.HeaderPrimaryRow>
        <AccountsLayoutPrimaryHeaderRowMobile
          entity={entity}
          isFiltersDisabled={isFiltersDisabled}
          isLoadingEntity={isLoadingEntity}
          mobileFilters={mobileFilters}
          onAddAccount={handleOnNextStageClick}
          shouldHaveFilters={isAllTransactionsRoute}
        />
      </PageTemplate.HeaderPrimaryRow>
    )
  }

  return (
    <PageTemplate.HeaderPrimaryRow>
      <AccountsLayoutPrimaryHeaderRowDesktop
        entity={entity}
        isFiltersDisabled={isFiltersDisabled}
        isFiltersInDefaultState={isFiltersInDefaultState}
        isFiltersVisible={isFiltersVisible}
        isLoadingEntity={isLoadingEntity}
        onAddAccount={handleOnNextStageClick}
        setIsFiltersVisible={setIsFiltersVisible}
        shouldHaveFilters={isAllTransactionsRoute}
      />
    </PageTemplate.HeaderPrimaryRow>
  )
}
