import { type ReactElement } from 'react'
import { useTranslations } from '@npco/utils-translations'
import { Message } from '@npco/zeller-design-system'

import { translations } from './DevicesFilterInputEmpty.i18n'

export const DevicesFilterInputEmpty = (): ReactElement => {
  const t = useTranslations(translations)
  return <Message title={t('title')} description={t('description')} />
}
