import React from 'react'
import { Flex } from '@npco/zeller-design-system'

import { Label, Value } from './SummaryRow.styled'

interface SummaryRowProps {
  label: string
  value: string
  headerTestId?: string
  amountTestId?: string
}
export const SummaryRow = ({
  label,
  value,
  headerTestId,
  amountTestId,
}: SummaryRowProps) => {
  return (
    <Flex width="100%" justifyContent="space-between">
      <Label data-testid={headerTestId}>{label}</Label>
      <Value data-testid={amountTestId}>{value}</Value>
    </Flex>
  )
}
