import { ButtonLink } from '@npco/zeller-design-system'
import styled, { css, keyframes } from 'styled-components'

export const HeaderWrapper = styled.div<{
  $isExpanded: boolean
  $paddingTop: string
}>`
  display: flex;
  padding-top: ${({ $isExpanded, $paddingTop }) =>
    $isExpanded ? $paddingTop : '0'};
  flex-direction: column;
  align-items: center;
  gap: ${({ $isExpanded }) => ($isExpanded ? '0px' : '16px')};
  flex: 1 0 0;
  align-self: stretch;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const TitleWrapper = styled.div<{ $isTransferComplete?: boolean }>`
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  ${({ $isTransferComplete }) =>
    $isTransferComplete &&
    css`
      animation: ${fadeIn} 0.5s ease-in-out;
    `};
`

export const ActionButton = styled(ButtonLink)<{
  $isExpanded: boolean
  $paddingBottom: string
  $isTransferRemittancesFlagOn: boolean
}>`
  ${({ theme }) => theme.typography['body-base']};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.BLUE_1000};
  padding-bottom: ${({ $isExpanded, $paddingBottom }) =>
    $isExpanded ? $paddingBottom : '0'};
  align-self: ${({ $isTransferRemittancesFlagOn }) =>
    $isTransferRemittancesFlagOn ? 'end' : 'center'};
`
