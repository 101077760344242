import { BodyDefaultTypography, DataMedium } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

import { DashboardTransactionsListItemWrapper } from '../../DashboardTransactionsListItemWrapper/DashboardTransactionsListItemWrapper'

export const StyledWrapper = styled(DashboardTransactionsListItemWrapper)<{
  isUpdated: boolean
}>`
  ${BodyDefaultTypography};
  width: 100%;

  ${({ isUpdated }) =>
    isUpdated &&
    css`
      animation: ${({ theme }) => theme.animation.appearAnimation} 1s linear;
    `}
`

interface TransactionAmountProps {
  isDeclined: boolean
  isIncoming: boolean
}
export const TransactionAmount = styled(DataMedium)<TransactionAmountProps>`
  text-align: right;
  margin: 0;
  color: ${({ theme, isDeclined, isIncoming }) => {
    if (isDeclined) {
      return theme.colors.RED_1000
    }
    if (isIncoming) {
      return theme.colors.GREEN_1600
    }
    return theme.colors.BLACK
  }};
`
