import { PageTemplate, Tabs } from '@npco/zeller-design-system'

import { useSiteTabs } from '../hooks/useSiteTabs'

export const SiteNavigation = () => {
  const { tabs, currentTab, areTabsVisible } = useSiteTabs()

  if (!areTabsVisible) {
    return null
  }

  return (
    <PageTemplate.HeaderSecondaryRow data-testid="site-navigation">
      <Tabs currentTab={currentTab}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.value} {...tab} />
        ))}
      </Tabs>
    </PageTemplate.HeaderSecondaryRow>
  )
}
