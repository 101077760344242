import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragmentDoc } from './groupSubcategoriesNetAmount.generated'

export type useGetPieChartDataCashFlowSubcategoryNetAmountFragment = {
  __typename?: 'CashFlowSubcategoryNetAmount'
  category: Types.EntityCategories | null
  noOfTransaction: number
  subcategoryDetails: {
    __typename?: 'Subcategory'
    id: string
    name: string
    predefined: boolean
  } | null
  total: { __typename?: 'Money'; value: string; currency: string }
  average: { __typename?: 'Money'; value: string; currency: string }
}

export const useGetPieChartDataCashFlowSubcategoryNetAmountFragmentDoc = gql`
  fragment useGetPieChartDataCashFlowSubcategoryNetAmountFragment on CashFlowSubcategoryNetAmount {
    subcategoryDetails {
      id
      name
    }
    total {
      value
    }
    ...groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragment
  }
  ${groupSubcategoriesNetAmountCashFlowSubcategoryNetAmountFragmentDoc}
` as unknown as TypedDocumentNode<
  useGetPieChartDataCashFlowSubcategoryNetAmountFragment,
  undefined
>
