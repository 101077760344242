import { OnboardingStatusToolTip } from './OnboardingStatusToolTip'
import { StyledLabelDivider, StyledPendingInvite } from './UserListItem.styled'

interface OnboardingMessage {
  title: string
  description: string
}

interface OnboardingStatusProps {
  onboardingStatus: OnboardingMessage | null
}

export const OnboardingStatus = ({
  onboardingStatus,
}: OnboardingStatusProps) => {
  if (!onboardingStatus) {
    return null
  }

  return (
    <OnboardingStatusToolTip description={onboardingStatus.description}>
      <StyledPendingInvite>
        <StyledLabelDivider>·</StyledLabelDivider>
        {onboardingStatus.title}
      </StyledPendingInvite>
    </OnboardingStatusToolTip>
  )
}
