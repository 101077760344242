import { StyledSkeletonCSS } from '@npco/zeller-design-system'
import { BREAKPOINT } from '@npco/zeller-design-system/dist/styles/breakpoints'
import styled, { css } from 'styled-components'

export const StyledLoaderRow = styled.li`
  display: flex;
  width: 100%;
  height: 4rem;
  padding: 0.75rem 0.375rem;
  align-items: center;
  justify-content: start;
  user-select: none;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_150};
  background: ${({ theme }) => theme.colors.WHITE};
  gap: 8px;

  &:first-child {
    border-top: 1px solid transparent;
  }
`

interface StyledLoaderPillContainerProps {
  small?: boolean
  align?: 'right' | 'left'
  showMobileStyle: boolean
}

export const StyledLoaderPillContainer = styled.span<StyledLoaderPillContainerProps>`
  display: inline-block;
  max-width: ${({ small }) => (small ? '6.375rem' : '100%')};
  margin-left: ${({ small }) => (small ? '1rem' : '0')};
  text-align: ${({ align = 'left' }) => align};
  flex-grow: 1;

  ${({ showMobileStyle }) =>
    !showMobileStyle &&
    css<StyledLoaderPillContainerProps>`
      @media screen and (min-width: ${BREAKPOINT.XS}px) {
        max-width: ${({ small }) => (small ? '6.375rem' : '100%')};
        margin-left: ${({ small }) => (small ? '1rem' : '0')};
      }
    `}
`

interface StyledLoaderPillProps {
  width?: string
  showMobileStyle: boolean
}

export const StyledLoaderPill = styled.span<StyledLoaderPillProps>`
  ${StyledSkeletonCSS}

  border-radius: 0.5rem;
  display: inline-block;
  height: 0.5rem;
  margin-left: 1rem;
  width: 100%;

  ${({ showMobileStyle }) =>
    !showMobileStyle &&
    css<StyledLoaderPillProps>`
      @media screen and (min-width: ${BREAKPOINT.XS}px) {
        width: ${({ width = '100%' }) => width};
      }
    `}
`

export const StyledAvatarBasic = styled.span`
  ${StyledSkeletonCSS}

  border-radius: 7px;
  display: inline-block;
  height: 40px;
  width: 40px;
`
