import { ImageContainer } from 'components/ImageContainer/ImageContainer'

interface IllustrationProps {
  imageSmall: string
  imageMedium: string
  imageLarge: string
}
export const Illustration = ({
  imageSmall,
  imageMedium,
  imageLarge,
}: IllustrationProps) => {
  return (
    <ImageContainer
      mobileImagePath={imageSmall}
      tabletImagePath={imageMedium}
      desktopImagePath={imageLarge}
    />
  )
}
