import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { type Cache } from '@apollo/client'
import {
  DebitCardAccountTransactionStatusEnum,
  DebitCardTransactionStatusV2,
  EntityCategories,
} from '@npco/mp-gql-types'
import { DebitCardTransactionsV2Fragment } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'

type TransactionsContact = DebitCardTransactionsV2Fragment['contact']
type SubcategoryDetails = DebitCardTransactionsV2Fragment['subcategoryDetails']

type AllowedFieldValues =
  | null
  | number
  | string
  | string[]
  | TransactionsContact
  | SubcategoryDetails

type UpdateOption<T extends AllowedFieldValues, PREV = T> =
  | T
  | ((prev: PREV) => T)

interface UpdateOptions {
  category?: UpdateOption<EntityCategories | null>
  subcategory?: UpdateOption<string | null>
  subcategoryDetails?: UpdateOption<SubcategoryDetails | null>
  status?: UpdateOption<
    DebitCardTransactionStatusV2 | DebitCardAccountTransactionStatusEnum
  >
  note?: UpdateOption<string, string | null>
  tags?: UpdateOption<string[], string[] | null>
  contact?: UpdateOption<TransactionsContact | null>
  attachments?: UpdateOption<string[]>
}

export const useUpdateDebitCardTransaction = () => {
  const { cache } = useApolloClient()

  const updateDebitCardTransaction = useCallback(
    (id: string, options: UpdateOptions) => {
      const updateWithOption = <T extends AllowedFieldValues, R>(
        option: UpdateOption<T, R> | undefined,
        prevValue: R
      ) => {
        if (typeof option === 'function') {
          return option(prevValue)
        }

        return option === undefined ? prevValue : option
      }

      const modifyOptions: Cache.ModifyOptions = {
        fields: {
          category(prevCategory: EntityCategories | null) {
            return updateWithOption(options.category, prevCategory)
          },
          subcategory(prevSubcategory: string | null) {
            return updateWithOption(options.subcategory, prevSubcategory)
          },
          subcategoryDetails(
            prevSubcategoryDetails: SubcategoryDetails | null
          ) {
            return updateWithOption(
              options.subcategoryDetails,
              prevSubcategoryDetails
            )
          },
          status(
            prevStatus:
              | DebitCardTransactionStatusV2
              | DebitCardAccountTransactionStatusEnum
          ) {
            return updateWithOption(options.status, prevStatus)
          },
          note(prevNote: string | null) {
            return updateWithOption(options.note, prevNote)
          },
          tags(prevTags: string[] | null) {
            return updateWithOption(options.tags, prevTags)
          },
          contact(prevContact: TransactionsContact | null) {
            return updateWithOption(options.contact, prevContact)
          },
          attachments(prevAttachments: string[]) {
            return updateWithOption(options.attachments, prevAttachments)
          },
        },
      }

      cache.modify({
        id: cache.identify({ id, __typename: 'DebitCardTransactionV2' }),
        ...modifyOptions,
      })

      cache.modify({
        id: cache.identify({ id, __typename: 'DebitCardAccountTransaction' }),
        ...modifyOptions,
      })
    },
    [cache]
  )

  return { updateDebitCardTransaction }
}
