import { useEffect } from 'react'
import { gql } from '@apollo/client'
import { DebitCardTransactionFilterInputV2 } from '@npco/mp-gql-types'

import {
  DebitCardTransactions,
  useDebitCardTransactions,
} from 'pages/DebitCardTransactions'
import { GenericErrorPage } from 'components/Placeholders/GenericErrorPage/GenericErrorPage'
import { PlaceholderSize } from 'components/Placeholders/Placeholders.types'

interface ErrorDisplayProps {
  retry: () => void
}

export interface AccountTransactionsListProps {
  ErrorDisplay?: (errorProps: ErrorDisplayProps) => JSX.Element
  accountTypeLoading?: boolean
  areFiltersInDefaultState: boolean
  emptyState?: JSX.Element
  filters: DebitCardTransactionFilterInputV2 | null
  isSavingsAccount?: boolean
  setIsActionsVisible?: React.Dispatch<React.SetStateAction<boolean>>
}

export const AccountTransactionsList = ({
  ErrorDisplay = ({ retry }) => (
    <GenericErrorPage variant={PlaceholderSize.Large} retry={retry} />
  ),
  accountTypeLoading = false,
  areFiltersInDefaultState,
  emptyState,
  isSavingsAccount,
  filters,
  setIsActionsVisible,
}: AccountTransactionsListProps) => {
  const {
    groupedDebitCardTransactions,
    hasNoInitialResults,
    onLoadMore,
    loading: isLoadingTransactions,
    hasMore,
    error: debitCardTransactionsError,
    refetch,
    handleSelectedTransactionUpdate,
  } = useDebitCardTransactions({ filters, areFiltersInDefaultState })

  useEffect(() => {
    if (!setIsActionsVisible || isLoadingTransactions || !isSavingsAccount) {
      return
    }

    // NOTE: callback to hide actions for savings accounts with no transactions
    setIsActionsVisible(!hasNoInitialResults)
  }, [
    hasNoInitialResults,
    isLoadingTransactions,
    isSavingsAccount,
    setIsActionsVisible,
  ])

  return (
    <DebitCardTransactions
      groupedDebitCardTransactions={groupedDebitCardTransactions}
      hasNoInitialResults={hasNoInitialResults}
      onLoadMore={onLoadMore}
      loading={isLoadingTransactions || accountTypeLoading}
      hasMore={hasMore}
      error={debitCardTransactionsError}
      refetch={refetch}
      handleSelectedTransactionUpdate={handleSelectedTransactionUpdate}
      ErrorDisplay={ErrorDisplay}
      listHeight="100%"
      unfilteredEmptyState={emptyState}
    />
  )
}

AccountTransactionsList.fragments = {
  DebitCardAccountV2: gql`
    fragment AccountTransactionsListDebitCardAccountV2Fragment on DebitCardAccountV2 {
      type
      status
    }
  `,
}
