import { ReactNode } from 'react'
import { Flex } from '@npco/zeller-design-system'

import { BankAccount } from 'types/accounts'

import {
  StyledAccountDetailSection,
  StyledLabel,
  StyledValue,
} from './AccountDetailBody.styled'

type AccountProperty = {
  label: string
  value: ReactNode
}

interface AccountDetailProps<T> {
  accountProperties: AccountProperty[]
  renderBottomAction?: (account: T) => ReactNode
  account: T
}

export const AccountDetailBody = <T extends BankAccount>({
  accountProperties,
  renderBottomAction,
  account,
}: AccountDetailProps<T>) => {
  return (
    <StyledAccountDetailSection>
      {accountProperties.map(({ label, value }) => (
        <Flex key={label} justifyContent="space-between" mb="0.75rem">
          <StyledLabel>{label}</StyledLabel>
          <StyledValue aria-label={label}>{value}</StyledValue>
        </Flex>
      ))}
      {renderBottomAction?.(account)}
    </StyledAccountDetailSection>
  )
}
