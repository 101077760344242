import { useCallback, useEffect, useState } from 'react'

import { receiptItems } from 'layouts/AccountLayout/filters/AccountFilterReceipt/AccountFilterReceipt.constants'
import { useAccountFilters } from 'layouts/AccountLayout/hooks/useAccountFilters/useAccountFilters'
import { DEFAULT_FILTERS_VALUES } from 'layouts/AccountLayout/hooks/useAccountFiltersValues/useAccountFiltersValues'
import { useAccountMultiselectWithAllElement } from 'layouts/AccountLayout/hooks/useAccountMultiselectWithAllElement/useAccountMultiselectWithAllElement'
import { page } from 'translations'

import { useMobileFilterButtonActions } from '../hooks/useMobileFilterButtonActions/useMobileFilterButtonActions'
import { MultiSelectMobileFilter } from '../MultiSelectMobileFilter/MultiSelectMobileFilter'

export const AccountMobileFilterReceipt = () => {
  const { receipt, setReceipt, resetReceipt } = useAccountFilters()
  const [localReceipt, setLocalReceipt] = useState(receipt)

  const resetLocalData = useCallback(() => {
    setLocalReceipt(DEFAULT_FILTERS_VALUES.receipt)
  }, [])

  const {
    listItems,
    selectedItems,
    onChange,
    onClose,
    areAllItemsSelected,
    setNoElementsSelected,
  } = useAccountMultiselectWithAllElement({
    data: localReceipt,
    setData: setLocalReceipt,
    dataItems: receiptItems,
  })

  const { onApply, onReset, onCancel } = useMobileFilterButtonActions({
    onClose,
    globalData: receipt,
    setGlobalData: setReceipt,
    resetGlobalData: resetReceipt,
    localData: localReceipt,
    setLocalData: setLocalReceipt,
    resetLocalData,
    setNoElementsSelected,
  })

  useEffect(() => {
    setLocalReceipt(receipt)
  }, [receipt])

  return (
    <MultiSelectMobileFilter
      items={listItems}
      values={receipt}
      selectedItems={selectedItems}
      onChange={onChange}
      onApply={onApply}
      onReset={onReset}
      onCancel={onCancel}
      areAllItemsSelected={areAllItemsSelected}
      filterName={page.accounts.transactionsList.filters.receipt}
    />
  )
}
