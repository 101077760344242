import { makeVar } from '@apollo/client'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

import { ThirdPartyBankAccount } from 'types/accounts'
import { OptimisticDebitCardTransaction } from 'pages/Transfer/Transfer.types'

type DebitCardTransaction = DebitCardTransactionV2 | DebitCardTransactionV3

export const rvSelectedAccountTransaction =
  makeVar<DebitCardTransaction | null>(null)

export const rvThirdPartyAccounts = makeVar<ThirdPartyBankAccount[]>([])

export const rvOptimisticDebitCardTransactions = makeVar<
  OptimisticDebitCardTransaction[]
>([])

export const rvUpdatedDebitCardTransactions = makeVar<DebitCardTransaction[]>(
  []
)
