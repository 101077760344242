import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { Box, Heading, HeadingStyles } from '@npco/zeller-design-system'

import { cardDisplayTranslations } from '../CardDisplay.i18n'
import { getTextColour } from '../CardDisplay.utils'
import { CardFormatDisplayDebitCardV2Fragment } from './CardFormatDisplay.generated'
import { useCardFormatDisplay } from './hooks/useCardFormatDisplay'

interface CardFormatDisplayProps {
  card: CardFormatDisplayDebitCardV2Fragment
  isVisible: boolean
}

export const CardFormatDisplay = ({
  card,
  isVisible,
}: CardFormatDisplayProps) => {
  const t = useTranslations(cardDisplayTranslations)
  const { productTypeDisplay, cardFormatDisplay } = useCardFormatDisplay({
    card,
  })
  const textColour = getTextColour({
    cardColour: card.colour,
    isCardRevealed: isVisible,
  })

  return (
    <Box position="absolute" top="13px" left="21px" color={textColour}>
      <Heading.H4 withStyles={HeadingStyles.H6} opacity={0.75}>
        {card.accessibleProfile
          ? t('zellerCardAccessibleFormat', {
              productType: productTypeDisplay,
              format: cardFormatDisplay,
            })
          : t('zellerCardFormat', {
              productType: productTypeDisplay,
              format: cardFormatDisplay,
            })}
      </Heading.H4>
    </Box>
  )
}

CardFormatDisplay.fragments = {
  DebitCardV2: gql`
    fragment CardFormatDisplayDebitCardV2Fragment on DebitCardV2 {
      accessibleProfile
      colour
      ...UseCardFormatDisplayDebitCardV2Fragment
    }

    ${useCardFormatDisplay.fragments.DebitCardV2}
  `,
}
