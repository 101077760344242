import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { AnnouncementModal, SIMCardLocked } from '@npco/zeller-design-system'
import { useCloseAccountModal } from 'features/CloseAccount/hooks/useCloseAccountModal/useCloseAccountModal'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'

export const CloseAccountSimBillingPrompt = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobileResolution()
  const { closeModal } = useCloseAccountModal()
  const shortEntityId = useSelectedShortEntityUuid()

  const gotoManageSimBilling = () =>
    navigate(ROOT.ORGS.ORG(shortEntityId).PAYMENTS.SIM.BILLING_ACCOUNT.path)

  return (
    <AnnouncementModal
      Illustration={<SIMCardLocked size={isMobile ? 'medium' : 'large'} />}
      heading={translate(
        'page.closeAccountModal.closeAccountSimBillingPrompt.heading'
      )}
      description={translate(
        'page.closeAccountModal.closeAccountSimBillingPrompt.description'
      )}
      primaryButtonLabel={translate(
        'page.closeAccountModal.closeAccountSimBillingPrompt.primaryButtonLabel'
      )}
      onClickPrimary={gotoManageSimBilling}
      secondaryButtonLabel={translate('shared.close')}
      onClickSecondary={closeModal}
      onCancel={closeModal}
      isOpen
    />
  )
}
