import { useEffect } from 'react'
import { useTrackModalScroll } from '@npco/zeller-design-system'

interface TrackScrollProps {
  searchInput: string
}

export const TrackScroll = ({ searchInput }: TrackScrollProps) => {
  const trackModalScroll = useTrackModalScroll()

  useEffect(() => {
    trackModalScroll()
  }, [trackModalScroll, searchInput])

  return null
}
