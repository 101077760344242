import { ReactComponent as Rejection } from 'assets/svg/rejection.svg'
import { ensureIsArray } from 'utils/common'

import { PlaceholderPage } from '../PlaceholderPage/PlaceholderPage'
import { PlaceholderAlignment, PlaceholderSize } from '../Placeholders.types'
import {
  getDescriptionBasedOnVariant,
  getTitleBasedOnVariant,
} from '../Placeholders.utils'
import { StyledSvgIcon, StyledTextContainer } from '../SharedElements.styled'
import { StackedDescriptions } from '../StackedDescriptions/StackedDescriptions'

interface Props {
  title: string
  description: React.ReactNode | React.ReactNode[]
  children?: React.ReactNode
  variant?: PlaceholderSize
  alignment?: PlaceholderAlignment
  className?: string
  ['data-testid']?: string
}

export const ErrorPage = ({
  title,
  description,
  children,
  variant = PlaceholderSize.Large,
  alignment = PlaceholderAlignment.Center,
  className,
  'data-testid': dataTestId,
}: Props) => {
  const StyledTitleVariant = getTitleBasedOnVariant(variant)
  const StyledDescriptionVariant = getDescriptionBasedOnVariant(variant)
  const stackedDescriptions = ensureIsArray(description)

  return (
    <PlaceholderPage
      className={className}
      alignment={alignment}
      data-testid={dataTestId}
    >
      <StyledSvgIcon variant={variant}>
        <Rejection />
      </StyledSvgIcon>
      <StyledTextContainer alignment={alignment}>
        <StyledTitleVariant>{title}</StyledTitleVariant>
        <StackedDescriptions
          Description={StyledDescriptionVariant}
          descriptions={stackedDescriptions}
        />
      </StyledTextContainer>
      {children}
    </PlaceholderPage>
  )
}
