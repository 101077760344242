import { DebitCardTransactionFilterInputV2 } from '@npco/mp-gql-types'

import { useDebitCardTransactions as useDebitCardTransactionsNew } from './useDebitCardTransactions'
import { OutstandingSettingsType } from './useOutstandingFilterSettings'

interface UseGroupedDebitCardTransactionsProps {
  filters: DebitCardTransactionFilterInputV2
  areFiltersInDefaultState: boolean
  cardId: string
  isOutstandingExpensesFilterOn: boolean
  outstandingSettings: OutstandingSettingsType
  isLoadingOutstandingFilterSettings: boolean
}

export const useGroupedDebitCardTransactions = ({
  filters,
  areFiltersInDefaultState,
  cardId,
  isOutstandingExpensesFilterOn,
  outstandingSettings,
  isLoadingOutstandingFilterSettings,
}: UseGroupedDebitCardTransactionsProps) => {
  const {
    groupedDebitCardTransactions: groupedDebitCardTransactionsV3,
    hasNoInitialResults: hasNoInitialResultsV3,
    onLoadMore: onLoadMoreV3,
    loading: isLoadingTransactionsV3,
    hasMore: hasMoreV3,
    error: debitCardTransactionsErrorV3,
    refetch: refetchTransactionsV3,
    handleSelectedTransactionUpdate: handleSelectedTransactionUpdateV3,
  } = useDebitCardTransactionsNew({
    filters,
    areFiltersInDefaultState,
    debitCardId: cardId,
    isOutstanding: isOutstandingExpensesFilterOn,
    outstandingSettings,
    isLoadingOutstandingFilterSettings,
  })

  return {
    groupedDebitCardTransactions: groupedDebitCardTransactionsV3,
    hasNoInitialResults: hasNoInitialResultsV3,
    onLoadMore: onLoadMoreV3,
    isLoadingTransactions: isLoadingTransactionsV3,
    hasMore: hasMoreV3,
    debitCardTransactionsError: debitCardTransactionsErrorV3,
    refetchTransactions: refetchTransactionsV3,
    handleSelectedTransactionUpdate: handleSelectedTransactionUpdateV3,
  }
}
