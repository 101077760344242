import { BodyDefault } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const HR = styled.div`
  margin: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.GREY_90};
`

export const Description = styled(BodyDefault)`
  color: ${({ theme }) => theme.colors.GREY_550};
  margin: 0;
`
