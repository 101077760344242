import { BUTTON_SIZE } from 'types/common'
import { page } from 'translations'

import { StyledButton } from './SignupSubmitButton.styled'

interface Props {
  isDisabled?: boolean
}

export const SignupSubmitButton = ({ isDisabled }: Props) => {
  return (
    <StyledButton
      data-testid="submit-button"
      type="submit"
      size={BUTTON_SIZE.LARGE}
      disabled={isDisabled}
    >
      {page.signUp.buttonLabel}
    </StyledButton>
  )
}
