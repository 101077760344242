import { useTranslations } from '@npco/utils-translations'
import { DecisionModal } from '@npco/zeller-design-system'

import { translationsShared } from 'translations'

import { unlinkContactModalTranslations } from './UnlinkContactModal.i18n'

interface UnlinkContactModalProps {
  isOpen: boolean
  onCancel: () => void
  unlinkContact: () => void
}

export const UnlinkContactModal = ({
  isOpen,
  onCancel,
  unlinkContact,
}: UnlinkContactModalProps) => {
  const t = useTranslations(unlinkContactModalTranslations)
  const tShared = useTranslations(translationsShared)

  return (
    <DecisionModal
      isOpen={isOpen}
      title={t('title')}
      primaryButtonLabel={t('primaryButtonLabel')}
      onClickPrimary={unlinkContact}
      secondaryButtonLabel={tShared('cancel')}
      onClickSecondary={onCancel}
      onCancel={onCancel}
    >
      {t('modalText')}
    </DecisionModal>
  )
}
