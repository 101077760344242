import styled from 'styled-components'

export const StyledPendingIconWrapper = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
`

export const StyledWrapper = styled.div`
  position: relative;
`
