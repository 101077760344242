import { ReactNode, useMemo, useState } from 'react'

import { AccountsTransactionsMetadataContext } from './context/AccountsTransactionsMetadata.context'

interface AccountsTransactionsMetadataProviderProps {
  children: ReactNode
}
export const AccountsTransactionsMetadataProvider = ({
  children,
}: AccountsTransactionsMetadataProviderProps) => {
  // NOTE: refactor to isFiltersInDefaultState
  const [areFiltersInDefaultState, setAreFiltersInDefaultState] = useState(true)
  const [isActionsVisible, setIsActionsVisible] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isFiltersDisabled = isLoading || (isEmpty && areFiltersInDefaultState)

  const value = useMemo(
    () => ({
      areFiltersInDefaultState,
      isActionsVisible,
      isEmpty,
      isFiltersDisabled,
      isFiltersVisible,
      isLoading,
      setAreFiltersInDefaultState,
      setIsActionsVisible,
      setIsEmpty,
      setIsFiltersVisible,
      setIsLoading,
    }),
    [
      areFiltersInDefaultState,
      isActionsVisible,
      isEmpty,
      isFiltersDisabled,
      isFiltersVisible,
      isLoading,
      setAreFiltersInDefaultState,
      setIsActionsVisible,
      setIsEmpty,
      setIsFiltersVisible,
      setIsLoading,
    ]
  )

  return (
    <AccountsTransactionsMetadataContext.Provider value={value}>
      {children}
    </AccountsTransactionsMetadataContext.Provider>
  )
}
