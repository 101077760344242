import { Link } from 'react-router-dom'
import { BodyLarge, Flex, H1 } from '@npco/zeller-design-system'
import { Form } from 'formik'
import styled from 'styled-components'

import { BREAKPOINT } from 'styles/breakpoints'
import { Button as BaseButton } from 'components/Buttons/Button'

export const StyledHeader = styled(H1)<{ emailSent?: boolean }>`
  margin: 0 0 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
`

export const StyledCopy = styled(BodyLarge)<{ isCenter?: boolean }>`
  max-width: 33.75rem;
  margin: 0 0 1.5rem;
  color: ${({ theme }) => theme.colors.BLACK_900};
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.isCenter ? ' center' : 'flex-start')};
  text-align: ${(props) => (props.isCenter ? 'center' : 'start')};

  & > span {
    color: ${({ theme }) => theme.colors.BLUE_1000};
  }

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
    text-align: start;
  }
`

export const StyledButton = styled(BaseButton)`
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    max-width: 12.5rem;
  }
`

export const StyledSubmitButton = styled(BaseButton)`
  margin-right: 1rem;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    min-width: 12.5rem;
    width: 0;
    margin-left: 0;
  }
`

export const StyledConfirmIconWrapper = styled(Flex)`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.BLUE_80};
  margin-bottom: 2.5rem;
`

export const StyledConfirmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`

export const StyledButtonWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    width: auto;
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-content: flex-end;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`
export const StyledFormWrapper = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 14rem;
  height: 100%;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    display: block;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`
