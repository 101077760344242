import { COLOR, Divider, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledCashflowFiltersHeader = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`

export const StyledDivider = styled(Divider)`
  position: absolute;
  top: 48px;
  margin: 0;
  border-color: ${COLOR.GREY_60};
`
