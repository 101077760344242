import { useCallback, useMemo } from 'react'
import { QueryHookOptions, useReactiveVar } from '@apollo/client'
import { DebitCardTransactionsV2Fragment as DebitCardTransactionV2 } from 'api/useQueryCardTransactions/graphql/DebitCardTransactionsV2Fragment.generated'
import {
  GetDebitCardTransactionsQueryResponse,
  GetDebitCardTransactionsQueryVariables,
} from 'api/useQueryCardTransactions/graphql/GetDebitCardTransactions.generated'
import {
  DEFAULT_TRANSACTIONS_PER_PAGE,
  useQueryCardTransactions,
} from 'api/useQueryCardTransactions/useQueryCardTransactions'
import {
  rvOptimisticDebitCardTransactions,
  rvSelectedAccountTransaction,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'
import { DebitCardTransactionFragment as DebitCardTransactionV3 } from 'features/Cards/CardSingle/hooks/useDebitCardTransactions/graphql/debitCardTransactionFragment.generated'

import {
  useDebitCardTransactionUpdate,
  useSelectedTransactionUpdate,
} from 'hooks/transactions'
import { useDebitCardTransactionsWithUpdates } from 'hooks/useDebitCardTransactionsWithUpdates/useDebitCardTransactionsWithUpdates'
import { useSubscribeToDebitCardTransactions } from 'hooks/useSubscribeToDebitCardTransactions'
import { groupDebitCardsTransactions } from 'utils/groupDebitCardsTransactions'
import { DebitCardTransactionUpdate_onDebitCardTransactionUpdate as DebitCardTransactionUpdate } from 'types/gql-types/DebitCardTransactionUpdate'

import {
  filterByAccount,
  filterByCardId,
  filterByContactUuid,
  filterDuplicates,
  getIsWithinTimeRange,
} from './utils/useDebitCardAccountTransactions.utils'

type HookOptions = QueryHookOptions<
  GetDebitCardTransactionsQueryResponse,
  GetDebitCardTransactionsQueryVariables
>

type DebitCardTransaction = DebitCardTransactionV2 | DebitCardTransactionV3

interface UseDebitCardAccountTransactionsProps extends HookOptions {
  filter: GetDebitCardTransactionsQueryVariables['filter']
  limit?: number
  skip?: boolean
  groupBy?: (x: (DebitCardTransaction | null)[]) => DebitCardTransaction[][]
  areFiltersInDefaultState?: boolean
}

export const useDebitCardAccountTransactions = ({
  filter,
  limit = DEFAULT_TRANSACTIONS_PER_PAGE,
  groupBy = groupDebitCardsTransactions,
  areFiltersInDefaultState = true,
  skip,
  ...queryProps
}: UseDebitCardAccountTransactionsProps) => {
  const selectedTransaction = useReactiveVar(rvSelectedAccountTransaction)
  const optimisticDebitCardTransactions = useReactiveVar(
    rvOptimisticDebitCardTransactions
  )

  const {
    transactions,
    loading,
    fetchMoreTransactions,
    hasMore,
    error,
    refetchTransactions,
  } = useQueryCardTransactions({
    filter,
    limit,
    skip,
    ...queryProps,
  })

  const selectedAccountID = filter?.debitCardAccountUuid?.eq ?? undefined
  const selectedCardID = filter?.debitCardId?.eq ?? undefined
  const selectedContactID = filter?.contactUuid?.eq ?? undefined

  const { transactionsWithUpdates } = useDebitCardTransactionsWithUpdates({
    transactions,
    debitCardAccountUuid: selectedAccountID,
    debitCardId: selectedCardID,
    contactUuid: selectedContactID,
  })

  const transactionsWithOptimistic = useMemo(
    () => [
      ...optimisticDebitCardTransactions
        .filter(filterByAccount(selectedAccountID))
        .filter(filterByCardId(selectedCardID))
        .filter(filterByContactUuid(selectedContactID))
        .filter(filterDuplicates(transactionsWithUpdates))
        .filter(getIsWithinTimeRange(transactionsWithUpdates, limit)),
      ...transactionsWithUpdates,
    ],
    [
      optimisticDebitCardTransactions,
      selectedAccountID,
      selectedCardID,
      transactionsWithUpdates,
      selectedContactID,
      limit,
    ]
  )

  const groupedTransactions = useMemo(
    () => groupBy(transactionsWithOptimistic),
    [transactionsWithOptimistic, groupBy]
  )

  const {
    updateSelectedTransactionWithSubscribeData,
    partialUpdateSelectedTransaction,
  } = useSelectedTransactionUpdate({
    cachedTransactions: transactions,
  })
  const { updateTransactionWithSubscribeData } = useDebitCardTransactionUpdate({
    cachedTransactions: transactions,
  })

  const handleSubscriptionUpdate = useCallback(
    (transactionUpdate: DebitCardTransactionUpdate) => {
      if (selectedTransaction?.id === transactionUpdate.id) {
        updateSelectedTransactionWithSubscribeData(transactionUpdate)
      } else {
        updateTransactionWithSubscribeData(transactionUpdate)
      }
    },
    [
      updateSelectedTransactionWithSubscribeData,
      updateTransactionWithSubscribeData,
      selectedTransaction,
    ]
  )

  useSubscribeToDebitCardTransactions({
    onUpdate: handleSubscriptionUpdate,
    debitCardId: selectedCardID,
    debitCardAccountUuid: selectedAccountID,
    skip: skip || !areFiltersInDefaultState,
  })

  return {
    groupedTransactions,
    fetchMoreTransactions,
    loading,
    hasMore,
    error,
    refetchTransactions,
    handleSelectedTransactionUpdate: partialUpdateSelectedTransaction,
  }
}
