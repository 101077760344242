import { BodyDefaultTypography, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const InvoiceItemDiscountInputWrapper = styled(Flex)`
  flex-grow: 1;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-grow: unset;
    width: 168px;
  }
`

export const InvoiceItemDiscountText = styled(Flex)`
  ${BodyDefaultTypography}
  font-weight: 400;
`
