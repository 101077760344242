import { BreadcrumbItem } from 'components/Breadcrumbs/BreadcrumbItem'

import { StyledContainer, StyledTitle } from './Breadcrumbs.styled'
import { BreadcrumbPath } from './Breadcrumbs.types'

interface BreadcrumbsBaseProps {
  breadcrumbParts: BreadcrumbPath[]
}

export const BreadcrumbsBase = ({ breadcrumbParts }: BreadcrumbsBaseProps) => {
  return (
    <StyledTitle as="div">
      <StyledContainer data-testid="breadcrumbs">
        {breadcrumbParts.map((route, index, routesArr) => {
          return (
            <BreadcrumbItem
              key={route.name}
              route={route}
              isLastElement={index === routesArr.length - 1}
            />
          )
        })}
      </StyledContainer>
    </StyledTitle>
  )
}
