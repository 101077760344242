import { SourceFilter, TransactionType } from '@npco/mp-gql-types'
import { COLOR } from '@npco/zeller-design-system'

import { LastStoredTimeMap, RangeModifierExt } from 'types/picker'
import { TransactionFilterStatus } from 'types/transactions'
import { RangePickerValue } from 'components/Filters/NewFilters/RangePicker/RangePicker.types'
import { component, shared } from 'translations'

export const TRANSACTION_STATUSES = [
  {
    id: 0,
    name: shared.status.approved,
    value: TransactionFilterStatus.APPROVED,
  },
  {
    id: 1,
    name: shared.status.declined,
    value: TransactionFilterStatus.DECLINED,
  },
  {
    id: 2,
    name: shared.status.refunded,
    value: TransactionFilterStatus.REFUNDED,
  },
]

export const TRANSACTION_TERMINAL_SOURCE = {
  id: 0,
  name: shared.sources.terminal,
  value: SourceFilter.TERMINAL,
}

export const TRANSACTION_POS_SOURCE = {
  id: 1,
  name: shared.sources.pos,
  value: SourceFilter.POINT_OF_SALE,
}

export const TRANSACTION_INVOICE_SOURCE = {
  id: 2,
  name: shared.sources.invoice,
  value: SourceFilter.INVOICE,
}

export const TRANSACTION_DASHBOARD_SOURCE = {
  id: 3,
  name: shared.sources.dashboard,
  value: SourceFilter.DASHBOARD,
}

export const TRANSACTION_ZELLER_APP_SOURCE = {
  id: 4,
  name: shared.sources.zellerApp,
  value: SourceFilter.ZELLER_APP,
}

export const TRANSACTION_SOURCES = [
  TRANSACTION_TERMINAL_SOURCE,
  TRANSACTION_POS_SOURCE,
  TRANSACTION_INVOICE_SOURCE,
  TRANSACTION_DASHBOARD_SOURCE,
  TRANSACTION_ZELLER_APP_SOURCE,
]

export const REPORTS_TRANSACTION_STATUSES = [
  {
    id: 0,
    name: shared.status.approved,
    value: TransactionFilterStatus.APPROVED,
  },
  {
    id: 1,
    name: shared.status.declined,
    value: TransactionFilterStatus.DECLINED,
  },
]

export const TRANSACTION_SALE_TYPE = {
  id: 0,
  value: TransactionType.PURCHASE,
  name: component.transaction.sale,
}

export const TRANSACTION_REFUND_TYPE = {
  id: 1,
  value: TransactionType.REFUND,
  name: component.transaction.refunded,
}

export const TRANSACTION_TYPES = [
  TRANSACTION_SALE_TYPE,
  TRANSACTION_REFUND_TYPE,
]

export const colorPickerList = [
  COLOR.GREY_250,
  COLOR.BLACK,
  COLOR.DARK_BLUE,
  COLOR.BLUE_400,
  COLOR.BLUE_700,
  COLOR.GREEN_1000,
  COLOR.GREEN_700,
  COLOR.ORANGE_1000,
  COLOR.RED_1000,
  COLOR.CORAL,
]

export const TRANSACTIONS = 'transactions'
export const DEPOSITS = 'deposits'
export const USERS = 'users'
export const SITES = 'sites'

export const rangePickerAccountTransactionsInitialValue: RangePickerValue = [
  -100000, 100000,
]
export const rangePickerInitialValue: RangePickerValue = [0, 100000]
export const datePickerInitialState: RangeModifierExt = {
  from: undefined,
  to: undefined,
  enteredTo: undefined,
}

export const lastStoredTimeFilterInitialState: LastStoredTimeMap = {
  TRANSACTIONS: null,
  CONTACTS: null,
  OVERVIEW: null,
  TRANSACTIONS_SPLIT_PAYMENT: null,
}
export const typePickerInitialValue = []
export const statusPickerInitialValue = []
export const sourcePickerInitialValue = []
export const terminalPickerInitialValue = []
export const tagPickerInitialValue = []
export const searchPhraseInitialValue = ''

export const OWNERSHIP_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
  TRUST: 'TRUST',
  PARTNERSHIP: 'PARTNERSHIP',
}

export const THIRD_PARTY_ACCOUNTS_PAGE_SIZE = 30

export const AUSTRALIA_COUNTRY_CODE = 'AUS'
export const ALPHA_2_COUNTRY_CODES = {
  AUSTRALIA: 'AU',
  NORFOLK_ISLAND: 'NF',
  POLAND: 'PL',
  INDIA: 'IN',
}
