import { useCallback, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import { InputFieldWithoutLabel } from '@npco/zeller-design-system'
import { useFormikContext } from 'formik'

import { ReactComponent as MobileIcon } from 'assets/svg/mobile.svg'
import { validateMobileNumber } from 'utils/formValidation'
import { translate } from 'utils/translations'

import { registerPhoneTranslations } from '../RegisterPhone.i18n'
import { RegisterPhoneFormValues } from '../RegisterPhone.types'

interface PhoneInputFieldProps {
  error?: ApolloError
}
export const PhoneInputField = ({ error }: PhoneInputFieldProps) => {
  const { validateForm } = useFormikContext<RegisterPhoneFormValues>()

  const t = useTranslations(registerPhoneTranslations)
  const isRateLimitExceeded = error?.message.includes('Rate limit exceeded')

  useEffect(() => {
    if (isRateLimitExceeded) {
      validateForm()
    }
  }, [isRateLimitExceeded, validateForm])

  const phoneValidator = useCallback(
    (value: string) => {
      if (isRateLimitExceeded) {
        return t('rateLimitExceededError')
      }
      return validateMobileNumber(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRateLimitExceeded, t]
  )

  return (
    <InputFieldWithoutLabel
      icon={MobileIcon}
      name="phone"
      type="tel"
      placeholder={translate('page.registerPhone.inputPlaceholder')}
      validate={phoneValidator}
      autoFocus
    />
  )
}
