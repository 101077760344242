import { useEffect, useMemo, useState } from 'react'
import { PaymentInstrumentStatus } from '@npco/mp-gql-types'
import { Box, ButtonLink } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'

import { NoDataAddedContent } from '../../ContactGeneral/ContactGeneral.styled'
import { BankAccountsListItem } from './BankAccountsListItem'

type PaymentInstrument = NonNullable<
  ContactCoreFieldsFragment['paymentInstruments']
>[0]

const statusSortMap = {
  [PaymentInstrumentStatus.PRIMARY]: 0,
  [PaymentInstrumentStatus.ACTIVE]: 1,
  [PaymentInstrumentStatus.ARCHIVED]: 2,
}

const getBankName = (bankDetails: PaymentInstrument['bankAccountDetails']) =>
  bankDetails?.name || ''

const sortByStatusAndName = (
  { status: status1, bankAccountDetails: bankDetails1 }: PaymentInstrument,
  { status: status2, bankAccountDetails: bankDetails2 }: PaymentInstrument
) => {
  if (statusSortMap[status1] < statusSortMap[status2]) {
    return -1
  }

  if (statusSortMap[status1] > statusSortMap[status2]) {
    return 1
  }

  return getBankName(bankDetails1).localeCompare(getBankName(bankDetails2))
}

interface BankAccountsListProps {
  bankAccounts: PaymentInstrument[]
  onItemClick: (itemToUpdate: string) => void
  recentUpdatedBankAccount: PaymentInstrument | null
}

export const BankAccountsList = ({
  bankAccounts,
  onItemClick,
  recentUpdatedBankAccount,
}: BankAccountsListProps) => {
  const [isArchivedVisible, setIsArchivedVisible] = useState(false)

  useEffect(() => {
    if (!recentUpdatedBankAccount) {
      return
    }

    if (recentUpdatedBankAccount.status === PaymentInstrumentStatus.ARCHIVED) {
      setIsArchivedVisible(true)
    }
  }, [recentUpdatedBankAccount])

  const filteredAndSortedBankAccounts = useMemo(() => {
    return bankAccounts
      ?.filter(
        ({ status }) =>
          isArchivedVisible || status !== PaymentInstrumentStatus.ARCHIVED
      )
      .sort(sortByStatusAndName)
  }, [bankAccounts, isArchivedVisible])

  if (!bankAccounts?.length) {
    return (
      <NoDataAddedContent>
        {translate('page.contact.sections.bankAccounts.noData')}
      </NoDataAddedContent>
    )
  }

  const hasArchivedBankAccounts = bankAccounts?.some(
    (bankAccount) => bankAccount.status === PaymentInstrumentStatus.ARCHIVED
  )

  return (
    <>
      {!filteredAndSortedBankAccounts.length && (
        <NoDataAddedContent>
          {translate('page.contact.sections.bankAccounts.noActiveBankAccounts')}
        </NoDataAddedContent>
      )}

      {filteredAndSortedBankAccounts.map(
        ({ id, bankAccountDetails, status }) => {
          return (
            <BankAccountsListItem
              key={id}
              bankAccountDetails={bankAccountDetails}
              id={id}
              onItemClick={onItemClick}
              status={status}
            />
          )
        }
      )}

      {hasArchivedBankAccounts && (
        <Box mt="1rem">
          <ButtonLink onClick={() => setIsArchivedVisible((prev) => !prev)}>
            {translate(
              !isArchivedVisible
                ? 'page.contact.sections.bankAccounts.showArchived'
                : 'page.contact.sections.bankAccounts.hideArchived'
            )}
          </ButtonLink>
        </Box>
      )}
    </>
  )
}
