import { useEffect } from 'react'
import { useTranslations } from '@npco/utils-translations'
import {
  BREAKPOINT,
  Tutorial,
  useIsMobileResolution,
} from '@npco/zeller-design-system'
import { useSetupFlowContext } from 'features/StartupModals/SetupFlow/contexts/SetupFlowContext'
import { ContentWrapper } from 'features/StartupModals/SetupFlow/SetupFlowModal/pages/FeatureAccountsStage/FeatureAccountsStageContentForm.styled'
import { useFormikContext } from 'formik'

import SetupFlowAccountsLarge from 'assets/images/setup-flow/setup-flow-accounts-large.png'
import SetupFlowAccountsMedium from 'assets/images/setup-flow/setup-flow-accounts-medium.png'
import SetupFlowAccountsSmall from 'assets/images/setup-flow/setup-flow-accounts-small.png'
import { translationsShared } from 'translations'

import { Illustration } from '../../../components/Illustration/Illustration'
import { useSetupFlowModal } from '../../hooks/useSetupFlowModal'
import { MotionFooter, MotionMainContent } from '../../SetupFlowModal.styled'
import { setupFlowAccountsStageTranslations } from './FeatureAccountsStage.i18n'
import { SetupFlowAccountsStageFormFields } from './FeatureAccountsStageContent.types'
import { MainContent } from './MainContent/MainContent'

interface FeatureAccountsStageContentFormProps {
  isLoading: boolean
}
export const FeatureAccountsStageContentForm = ({
  isLoading,
}: FeatureAccountsStageContentFormProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(setupFlowAccountsStageTranslations)
  const { goToStage } = useSetupFlowModal()
  const { submitForm, values } =
    useFormikContext<SetupFlowAccountsStageFormFields>()
  const { setIsCreateCardAllowedInitialState } = useSetupFlowContext()
  const isScreenResolutionLessThanLG = useIsMobileResolution(BREAKPOINT.LG)

  useEffect(() => {
    setIsCreateCardAllowedInitialState(values.isCreateCardAllowed)
  }, [setIsCreateCardAllowedInitialState, values.isCreateCardAllowed])

  return (
    <Tutorial.TutorialLayout
      badgeName={t('badge')}
      Footer={
        <MotionFooter>
          <Tutorial.TutorialFooter
            onPrimaryButtonClick={submitForm}
            onSecondaryButtonClick={() => goToStage('FeaturePaymentsStage')}
            primaryButtonLabel={tShared('next')}
            isPrimaryButtonLoading={isLoading}
          />
        </MotionFooter>
      }
      Illustration={
        <Illustration
          imageSmall={SetupFlowAccountsSmall}
          imageMedium={SetupFlowAccountsMedium}
          imageLarge={SetupFlowAccountsLarge}
        />
      }
      MainContent={
        <MotionMainContent>
          {isScreenResolutionLessThanLG ? (
            <MainContent />
          ) : (
            <ContentWrapper>
              <MainContent />
            </ContentWrapper>
          )}
        </MotionMainContent>
      }
    />
  )
}
