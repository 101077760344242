import { AddressPage } from 'hooks/useGoToAddCardStage/useGoToAddCardStage.types'
import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { CustomAddressPage } from 'pages/GlobalModals/components/DeliveryAddress/CustomAddressPage/CustomAddressPage'
import { useEntityAddressQuery } from 'pages/GlobalModals/components/DeliveryAddress/hooks/useEntityAddressQuery/useEntityAddressQuery'
import { PredefinedAddressPage } from 'pages/GlobalModals/components/DeliveryAddress/PredefinedAddressPage/PredefinedAddressPage'
import { Condition } from 'components/Condition/Condition'
import { useMultiStageModalProgressBar } from 'components/MultiStageModal'

import { useSelectDeliveryAddressForm } from './hooks/useSelectDeliveryAddressForm'
import { useSelectDeliveryAddressPageToggle } from './hooks/useSelectDeliveryAddressPageToggle'

interface SelectDeliveryAddressStageFormProps {
  dataTestId?: string
}

export const SelectDeliveryAddressStageForm = ({
  dataTestId,
}: SelectDeliveryAddressStageFormProps) => {
  useMultiStageModalProgressBar({
    newCurrentStep: 9,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })
  const {
    data: addressOptions,
    isLoading,
    error,
    retry,
  } = useEntityAddressQuery()

  const {
    submitCustomAddressPage,
    submitPredefinedAddressPage,
    goBackToCardDesignStage,
    initialAddress,
  } = useSelectDeliveryAddressForm()

  const { goToPredefinedAddressPage, goToCustomAddressPage, currentPage } =
    useSelectDeliveryAddressPageToggle()

  return (
    <>
      <Condition shouldShow={currentPage === AddressPage.CustomAddress}>
        <CustomAddressPage
          dataTestId={dataTestId}
          initialAddress={initialAddress}
          onSubmit={submitCustomAddressPage}
          onCancel={goBackToCardDesignStage}
          goToPredefinedAddressPage={goToPredefinedAddressPage}
        />
      </Condition>
      <Condition shouldShow={currentPage === AddressPage.PredefinedAddress}>
        <PredefinedAddressPage
          dataTestId={dataTestId}
          isLoading={isLoading}
          error={error}
          retry={retry}
          initialAddress={initialAddress}
          addressOptions={addressOptions}
          onSubmit={submitPredefinedAddressPage}
          onCancel={goBackToCardDesignStage}
          goToCustomAddressPage={goToCustomAddressPage}
        />
      </Condition>
    </>
  )
}
