import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type CancelSimMutationVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
  simId: Types.Scalars['ID']['input']
}>

export type CancelSimMutationResponse = {
  __typename?: 'Mutation'
  cancelSim: boolean
}

export const CancelSimMutation = gql`
  mutation CancelSimMutation($entityUuid: ID!, $simId: ID!) {
    cancelSim(entityUuid: $entityUuid, simId: $simId)
  }
` as unknown as TypedDocumentNode<
  CancelSimMutationResponse,
  CancelSimMutationVariables
>
export type CancelSimMutationMutationFn = Apollo.MutationFunction<
  CancelSimMutationResponse,
  CancelSimMutationVariables
>

/**
 * __useCancelSimMutation__
 *
 * To run a mutation, you first call `useCancelSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSimMutation, { data, loading, error }] = useCancelSimMutation({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *      simId: // value for 'simId'
 *   },
 * });
 */
export function useCancelSimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSimMutationResponse,
    CancelSimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelSimMutationResponse,
    CancelSimMutationVariables
  >(CancelSimMutation, options)
}
export type CancelSimMutationHookResult = ReturnType<
  typeof useCancelSimMutation
>
export type CancelSimMutationMutationResult =
  Apollo.MutationResult<CancelSimMutationResponse>
export type CancelSimMutationMutationOptions = Apollo.BaseMutationOptions<
  CancelSimMutationResponse,
  CancelSimMutationVariables
>
