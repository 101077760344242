import { ReactNode, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { GetCustomerProfileDetails } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'
import {
  rvProfileFetchError,
  rvProfileSettings,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables/users'

import {
  GetCustomerProfileDetails as GetCustomerProfileDetailsType,
  GetCustomerProfileDetailsVariables,
} from 'types/gql-types/GetCustomerProfileDetails'
import { SpinnerWrapped } from 'components/Spinner'

interface Props {
  children: ReactNode
}

export const ProfileDetailsFetcher = ({ children }: Props) => {
  const { userData } = useLoggedInUser()
  const customerUuid = useMemo(() => userData?.id as string, [userData])
  const entityUuid = useSelectedEntityUuid()
  const { loading } = useQuery<
    GetCustomerProfileDetailsType,
    GetCustomerProfileDetailsVariables
  >(GetCustomerProfileDetails, {
    fetchPolicy: 'network-only',
    variables: {
      entityUuid,
      customerUuid,
    },
    onCompleted: (customerData) => {
      if (customerData?.getCustomer) {
        rvProfileSettings(customerData.getCustomer)
        rvProfileFetchError(null)
      }
    },
    onError: rvProfileFetchError,
  })

  if (loading) {
    return <SpinnerWrapped variant="centre" />
  }

  return <>{children}</>
}
