import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  title: 'Oracle - Simphony',
  description:
    'Enhance the checkout experience for your customers by integrating Zeller Terminal with Oracle Simphony.',
  contactSupport: 'Contact Zeller For Setup',
  contactSupportDescription:
    'If you wish to utilise this feature, please get in touch with {support}, who will assist you with getting started. ',
  zellerSupport: 'Zeller Support',
})
