import {
  BodyDefaultTypography,
  BodySmallTypography,
  BREAKPOINT,
  Flex,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const CellContainer = styled(Flex)`
  align-items: center;
  column-gap: 12px;
  flex-grow: 1;
  overflow: hidden;
  ${BodySmallTypography};

  @media (min-width: ${BREAKPOINT.XS}px) {
    ${BodyDefaultTypography};
  }
`
