import { ErrorLogger } from '@npco/utils-error-logger'

export const getSessionStorageItem = <T>(key: string): T | undefined => {
  const item = sessionStorage.getItem(key)

  try {
    return item && item !== 'undefined'
      ? (JSON.parse(item.replace(/undefined/g, 'null')) as T)
      : undefined
  } catch (error) {
    ErrorLogger.report('[Core] parse session storage items', { key, error })
    return undefined
  }
}

export const setSessionStorageItem = <T>(key: string, payload: T): void => {
  const parsedItem = JSON.stringify(payload)

  sessionStorage.setItem(key, parsedItem)
}

export const removeSessionStorageItem = <T>(
  key: string
): T | undefined | Error => {
  const item = sessionStorage.getItem(key)
  sessionStorage.removeItem(key)
  return item ? (JSON.parse(item) as T) : undefined
}

export const clearSessionStorage = <T extends string>(
  ...excludedItemsFull: T[]
) => {
  const excludedItems = excludedItemsFull.filter(Boolean)

  if (excludedItems.length === 0) {
    sessionStorage.clear()
    return
  }

  const excludedSessionStorageData: Partial<Record<T, any>> = {}

  excludedItems.forEach((item) => {
    excludedSessionStorageData[item] = getSessionStorageItem(item)
  })

  sessionStorage.clear()

  excludedItems.forEach((item) => {
    setSessionStorageItem(item, excludedSessionStorageData[item])
  })
}
