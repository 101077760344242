import { useCallback } from 'react'
import { INVOICE_SCHEDULE_INVOICE_DATE_FIELD } from 'features/Invoicing/components/Invoices/Invoice/Invoice.constants'
import { InvoiceFormFields } from 'features/Invoicing/components/Invoices/Invoice/Invoice.types'
import { useField, useFormikContext } from 'formik'

import { useIsMobileResolution } from 'hooks/useIsMobileResolution'

import { getNewScheduleFieldValues } from './useInvoiceDate.utils'

export const useInvoiceDate = () => {
  const isMobileResolution = useIsMobileResolution()
  const {
    setValues,
    values: {
      schedule: { paymentTerms },
    },
  } = useFormikContext<InvoiceFormFields>()

  const [field, meta] = useField(INVOICE_SCHEDULE_INVOICE_DATE_FIELD)

  const handleDateChange = useCallback(
    (newInvoiceDate: string) => {
      setValues(getNewScheduleFieldValues(newInvoiceDate, paymentTerms))
    },
    [paymentTerms, setValues]
  )

  return {
    fieldError: meta.touched && meta.error ? meta.error : '',
    fieldName: field.name,
    fieldValue: field.value,
    handleBlur: field.onBlur,
    handleDateChange,
    isMobileResolution,
  }
}
