import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

import { UseCardProgressDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardProgress/useCardProgress.generated'
import { UseCardStatusDebitCardV2FragmentDoc } from '../../../../../../../../hooks/useCardStatus/useCardStatus.generated'
import { CardStatusDebitCardV2FragmentDoc } from '../CardStatus/CardStatus.generated'

export type CardBalanceAvailableCellDebitCardV2Fragment = {
  __typename?: 'DebitCardV2'
  status: Types.DebitCardStatus
  velocityControl: {
    __typename?: 'VelocityControlType'
    resetsAt: any
    availableAmount: { __typename?: 'Money'; value: string }
    amountLimit: { __typename?: 'Money'; value: string }
  } | null
}

export const CardBalanceAvailableCellDebitCardV2FragmentDoc = gql`
  fragment CardBalanceAvailableCellDebitCardV2Fragment on DebitCardV2 {
    ...UseCardStatusDebitCardV2Fragment
    ...UseCardProgressDebitCardV2Fragment
    ...CardStatusDebitCardV2Fragment
  }
  ${UseCardStatusDebitCardV2FragmentDoc}
  ${UseCardProgressDebitCardV2FragmentDoc}
  ${CardStatusDebitCardV2FragmentDoc}
` as unknown as TypedDocumentNode<
  CardBalanceAvailableCellDebitCardV2Fragment,
  undefined
>
