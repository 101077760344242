import { Link, useNavigate } from 'react-router-dom-v5-compat'
import {
  Box,
  ButtonGhost,
  ButtonGhostIconOnly,
} from '@npco/zeller-design-system'

import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { translate } from 'utils/translations'

interface DashboardSettingsLinkProps {
  path: string
}

export const DashboardSettingsLink = ({ path }: DashboardSettingsLinkProps) => {
  const navigate = useNavigate()
  const isMobile = useIsMobileResolution()

  return (
    <Box marginX="8px">
      {isMobile ? (
        <Link to={path}>
          <ButtonGhostIconOnly dataTestId="gear-button" icon={SettingsIcon} />
        </Link>
      ) : (
        <ButtonGhost
          data-testid="deposit-settings"
          onClick={() => navigate(path)}
        >
          {translate('shared.settings')}
        </ButtonGhost>
      )}
    </Box>
  )
}
