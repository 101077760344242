import { gql, type TypedDocumentNode } from '@apollo/client'

import {
  GetContactOrMerchantLocationDataContactFragmentDoc,
  GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc,
} from '../utils/contacts.utils.generated'
import {
  GetEmailContactFragmentDoc,
  GetEmailMerchantDetailsFragmentDoc,
} from './Items/MerchantSecondaryDetailsItems/MerchantSecondaryDetailsItems.utils.generated'

export type PersonContactDetailsMerchantDetailsFragment = {
  __typename?: 'MerchantDetails'
  phone: string | null
  location: string | null
  locationAccuracy: number | null
  email: string | null
  address: {
    __typename?: 'MerchantAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street1: string | null
    street2: string | null
    suburb: string | null
  } | null
}

export type PersonContactDetailsContactFragment = {
  __typename?: 'Contact'
  phone: string | null
  jobTitle: string | null
  location: string | null
  locationAccuracy: number | null
  phoneV2: { __typename?: 'ContactPhone'; phone: string } | null
  address: {
    __typename?: 'ContactAddress'
    country: string | null
    postcode: string | null
    state: string | null
    street: string | null
    suburb: string | null
  } | null
  email: { __typename?: 'ContactEmail'; email: string } | null
}

export const PersonContactDetailsMerchantDetailsFragmentDoc = gql`
  fragment PersonContactDetailsMerchantDetailsFragment on MerchantDetails {
    ...GetContactOrMerchantLocationDataMerchantDetailsFragment
    ...GetEmailMerchantDetailsFragment
    phone
  }
  ${GetContactOrMerchantLocationDataMerchantDetailsFragmentDoc}
  ${GetEmailMerchantDetailsFragmentDoc}
` as unknown as TypedDocumentNode<
  PersonContactDetailsMerchantDetailsFragment,
  undefined
>
export const PersonContactDetailsContactFragmentDoc = gql`
  fragment PersonContactDetailsContactFragment on Contact {
    ...GetContactOrMerchantLocationDataContactFragment
    ...GetEmailContactFragment
    phone
    phoneV2 {
      phone
    }
    jobTitle
  }
  ${GetContactOrMerchantLocationDataContactFragmentDoc}
  ${GetEmailContactFragmentDoc}
`
