import { useTranslations } from '@npco/utils-translations'
import { Box } from '@npco/zeller-design-system'
import { FieldValidator } from 'formik'

import { translate } from 'utils/translations'
import { zellerTransferTranslations } from 'pages/Transfer/Transfer.i18n'
import { InputAdaptiveFieldWrapper } from 'components/InputAdaptiveManagers/InputAdaptiveFieldWrapper'

import { validateBECSReferenceField } from './ReferenceField.utils'

interface ReferenceFieldProps {
  maxLength?: number
  validate?: FieldValidator
}

export const ReferenceField = ({
  maxLength = 18,
  validate,
}: ReferenceFieldProps) => {
  const t = useTranslations(zellerTransferTranslations)
  return (
    <Box mb="24px">
      <InputAdaptiveFieldWrapper
        label={translate('page.transfer.referenceLabel')}
        placeholder={t('referencePlaceholder', {
          maxLength: maxLength.toString(),
        })}
        name="reference"
        type="text"
        validate={validate || validateBECSReferenceField(maxLength)}
      />
    </Box>
  )
}
