import {
  BodyDefault,
  BodySmall,
  BREAKPOINT,
  H3,
} from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledVerificationWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  text-align: center;
  margin-right: 24px;

  @media screen and (min-width: ${BREAKPOINT.XS}px) {
    margin-right: 32px;
  }
`

export const StyledVerificationHeader = styled(H3)`
  font-size: 22px;
  font-weight: 500;
  line-height: 1.36;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

export const StyledVerificationCopy = styled(BodyDefault)`
  margin: 0 auto 1rem;

  span {
    font-weight: 500;
  }
`

export const StyledVerificationRedirect = styled(BodySmall)`
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.GREY_550};
`

export const StyledImage = styled.img`
  height: 11.25rem;
  width: 11.25rem;
  border-radius: 90px;
  margin: 0 auto;
`
