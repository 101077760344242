import { useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  DotBasic,
  SelectStateChangeProps,
  SelectStateInputChangeProps,
} from '@npco/zeller-design-system'
import { rvUnlinkedPaymentInstruments } from 'apps/component-merchant-portal/src/graphql/reactiveVariables/paymentInstruments'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import { useField } from 'formik'

import { translate } from 'utils/translations'
import { AccountFieldItem } from 'pages/Transfer/AddModal/fields/AccountField.types'
import { validateAccountName } from 'forms/formViews/BankAccountFields/BankAccountFields.utils'

const NAME = 'name'

export const useAccountComboBox = () => {
  const [field, meta, helpers] = useField({
    name: NAME,
    validate: validateAccountName,
  })

  const unlinkedPaymentInstruments = useReactiveVar(
    rvUnlinkedPaymentInstruments
  )

  const items: AccountFieldItem[] = useMemo(
    () =>
      unlinkedPaymentInstruments.map((item) => {
        const { bsb, account, name } = item.details
        return {
          label: name || '',
          subLabel: (
            <>
              {translate('component.tile.bsb', {
                accountBsb: bsb,
              })}
              <DotBasic />
              {translate('component.tile.accountNumber', {
                accountNumber: account,
              })}
            </>
          ),
          value: item.id,
          id: item.id,
          bsb,
          account,
        }
      }),
    [unlinkedPaymentInstruments]
  )

  const [selectedItem, setSelectedItem] = useState<AccountFieldItem | null>(
    null
  )

  const onChange = (changes: SelectStateChangeProps<AccountFieldItem>) => {
    if (changes.selectedItem) {
      helpers.setValue(changes.selectedItem.label)
      setSelectedItem(changes.selectedItem)
    }
  }

  const onClose = (changes: UseComboboxStateChange<AccountFieldItem>) => {
    // NOTE: when a user has an item, changes the input value and navigates away
    // from the menu causing the menu to close reset the selectedItem
    if (changes.selectedItem) {
      if (changes.selectedItem.label !== changes.inputValue) {
        setSelectedItem(null)
      }
    }
  }

  const onInputChange = (
    changes: SelectStateInputChangeProps<AccountFieldItem>
  ) => {
    // NOTE: downshift's behaviour is when selectedItem is set to null, inputValue is set to empty
    // https://github.com/downshift-js/downshift/blob/master/src/hooks/useCombobox/reducer.js#L127
    // Now that items is memoised, useEffect in InputSelectComboBoxBasic doesn't trigger closeMenu
    // which overrides the reducer that stateChangeTypes.FunctionSelectItem is triggering.
    // Below fixes the behaviour that inputValue is being set to empty by downshift
    if (
      changes.type ===
      useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
    ) {
      return
    }

    const value = changes.inputValue

    if (value === '') {
      setSelectedItem(null)
    }

    helpers.setValue(value)
  }

  const onInputClear = () => {
    helpers.setValue('')
    setSelectedItem(null)
  }

  return {
    error: meta.error,
    inputValue: field.value,
    items,
    onChange,
    onClose,
    onBlur: field.onBlur,
    onInputChange,
    onInputClear,
    name: NAME,
    selectedItem,
    touched: meta.touched,
  }
}
