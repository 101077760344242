import { useMemo } from 'react'
import { DebitCardAccountStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useCreateSavingsAccountViewQuery } from './graphql/CreateSavingsAccountViewQuery.generated'

export const useCreateSavingsAccountViewData = () => {
  const entityUuid = useSelectedEntityUuid()

  const { data, loading } = useCreateSavingsAccountViewQuery({
    variables: { entityUuid },
  })

  const activeSavingsAccountId = useMemo(
    () =>
      data?.getSavingsAccounts.find(
        (account) => account?.status === DebitCardAccountStatus.ACTIVE
      )?.id,
    [data]
  )

  return {
    activeSavingsAccountId,
    isLoadingCreateSavingsAccountViewData: loading,
  }
}
