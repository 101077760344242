import { useContext } from 'react'

import { AccountsTransactionsMetadataContext } from '../context/AccountsTransactionsMetadata.context'

export const ERROR_MESSAGE =
  'useAccountsTransactionsMetadata must be used within AccountsTransactionsMetadataContext'

export const useAccountsTransactionsMetadata = () => {
  const context = useContext(AccountsTransactionsMetadataContext)

  if (context === null) {
    throw new Error(ERROR_MESSAGE)
  }

  const {
    areFiltersInDefaultState,
    isActionsVisible,
    isEmpty,
    isFiltersDisabled,
    isFiltersVisible,
    isLoading,
    setAreFiltersInDefaultState,
    setIsActionsVisible,
    setIsEmpty,
    setIsFiltersVisible,
    setIsLoading,
  } = context

  return {
    areFiltersInDefaultState,
    isActionsVisible,
    isEmpty,
    isFiltersDisabled,
    isFiltersVisible,
    isLoading,
    setAreFiltersInDefaultState,
    setIsActionsVisible,
    setIsEmpty,
    setIsFiltersVisible,
    setIsLoading,
  }
}
