import { ButtonLink } from '@npco/zeller-design-system'
import { useModalState } from 'design-system/Components/Modal'

import { translate } from 'utils/translations'

import { Section, SectionDescription, SectionTitle } from './components'
import { ChangePasswordModal } from './PasswordSection/ChangePasswordModal'

export const PasswordSection = (): JSX.Element => {
  const { isModalOpen, openModal, closeModal } = useModalState()
  return (
    <>
      <ChangePasswordModal
        isOpen={isModalOpen}
        onCancel={closeModal}
        onComplete={closeModal}
      />
      <Section>
        <SectionTitle>
          {translate('page.settings.users.password.title')}
        </SectionTitle>
        <SectionDescription>
          {translate('page.settings.profileSettings.passwordDescription')}
        </SectionDescription>
        <div>
          <ButtonLink onClick={openModal}>
            {translate('page.settings.profileSettings.changePassword')}
          </ButtonLink>
        </div>
      </Section>
    </>
  )
}
