import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  categoryNameMaxLengthError:
    'Category Name must not exceed {length} characters.',
  itemCategoryErrorNotification:
    'Item Category could not be saved. Please try again.',
  itemCategoryLabel: 'Category',
  itemCategoryTitle: 'Select Category',
  itemCategorySearchPlaceholder: 'Search or enter New Category',
})
