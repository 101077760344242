import { StyledTableEffect } from '@npco/ui-table'
import { BoxProps } from '@npco/zeller-design-system'
import styled, { css } from 'styled-components'

const FocusedStyles = css`
  &:focus-visible {
    border-radius: 4px;
    outline-offset: -3px;
    outline: solid 3px ${({ theme }) => theme.colors.BLUE_120};
    + li {
      border-top: 1px solid transparent;
    }
    border-bottom: 1px solid transparent;
  }
`

export const DebitCardTransactionRowWrapper = styled.li<
  BoxProps & { $isNew?: boolean }
>`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  max-height: 64px;
  padding: 12px 6px;
  width: 100%;
  scroll-margin-top: 40px;

  ${StyledTableEffect}
  ${FocusedStyles}

  ${({ theme, $isNew }) =>
    $isNew &&
    css`
      animation: ${theme.animation.appearAnimation} 1s linear;
      border-left: 2px solid ${theme.colors.BLUE_1000};
    `}
`
