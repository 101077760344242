import { ModalBasic } from '@npco/zeller-design-system'
import { Formik } from 'formik'

import { TOTAL_ADD_CARD_STAGES } from 'pages/GlobalModals/AddCardModal/AddCardModal.utils'
import { StyledForm } from 'pages/GlobalModals/GlobalModals.styled'
import {
  MultiStageModal,
  useMultiStageModalProgressBar,
} from 'components/MultiStageModal'

import { SelectCardDesignFields } from './fields/SelectCardDesignFields'
import { useSelectCardDesignForm } from './hooks/useSelectCardDesignForm'
import { SelectCardDesignFormValues } from './SelectCardDesignForm.types'

export const SelectCardDesignForm = () => {
  const {
    goBackToPreviousStage,
    submitCardDesign,
    initialColour,
    colourTileConfig,
  } = useSelectCardDesignForm()
  useMultiStageModalProgressBar({
    newCurrentStep: 8,
    newTotalSteps: TOTAL_ADD_CARD_STAGES,
  })

  return (
    <Formik<SelectCardDesignFormValues>
      validateOnMount
      initialValues={{ colour: initialColour }}
      onSubmit={submitCardDesign}
    >
      <StyledForm data-testid="select-card-design-stage">
        <ModalBasic.Body>
          <SelectCardDesignFields colourTileConfig={colourTileConfig} />
        </ModalBasic.Body>
        <MultiStageModal.FormButtons
          onCancel={goBackToPreviousStage}
          disableOnInvalid
        />
      </StyledForm>
    </Formik>
  )
}
