import { ButtonGhost } from '@npco/zeller-design-system'

import { translate } from 'utils/translations'

interface Props {
  closeMenu: () => void
  onAddNewAccount: () => void
}

export const AccountHeader = ({ closeMenu, onAddNewAccount }: Props) => {
  const handleClick = () => {
    onAddNewAccount()
    closeMenu()
  }

  return (
    <ButtonGhost onClick={handleClick}>
      {translate('page.transfer.addNewAccount')}
    </ButtonGhost>
  )
}
