import { ReactElement, useCallback } from 'react'
import { ContactType } from '@npco/mp-gql-types'
import { useTranslations } from '@npco/utils-translations'
import { AvatarBasic, Box, Flex, PillSize } from '@npco/zeller-design-system'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'

import { translate } from 'utils/translations'
import { AddressMap } from 'components/AddressMap'
import { translationsShared } from 'translations'

import { getContactName } from '../../../Contacts.utils'
import { getContactProfilePictureUrl } from '../../ContactGeneral/ContactGeneral.util'
import { BusinessContactDetails } from './BusinessContactDetails'
import { contactDetailsTranslations } from './ContactDetails.i18n'
import * as styled from './ContactDetails.styled'
import { ContactDetailsLoader } from './ContactDetailsLoader'
import { PeopleContactDetails } from './PeopleContactDetails'

interface ContactDetailsProps {
  canEditCategories?: boolean
  contact: ContactCoreFieldsFragment | null
  isLoading?: boolean
  renderHeaderAction?: () => ReactElement
  renderHeaderAvatar?: () => ReactElement
  useCategoryPills?: boolean
}

export const ContactDetails = ({
  canEditCategories,
  contact,
  isLoading,
  renderHeaderAction,
  renderHeaderAvatar,
  useCategoryPills,
}: ContactDetailsProps) => {
  const tShared = useTranslations(translationsShared)
  const t = useTranslations(contactDetailsTranslations)
  const profilePictureUrl = getContactProfilePictureUrl(contact)
  const name = getContactName(contact)

  const renderDefaultAvatar = useCallback(() => {
    return (
      <AvatarBasic
        text={contact?.icon?.letter || translate('shared.zeller')}
        userDefinedContent={
          profilePictureUrl ? (
            <img
              src={profilePictureUrl}
              alt={translate('page.contact.sections.generalDetails.avatar')}
            />
          ) : null
        }
      />
    )
  }, [contact?.icon?.letter, profilePictureUrl])

  if (isLoading) {
    return <ContactDetailsLoader />
  }

  if (!contact) {
    return null
  }

  return (
    <>
      <Flex
        data-testid="contact-name-section"
        aria-label={t('contactNameSectionAriaLabel')}
        marginBottom="24px"
      >
        <Box mr="16px">{renderHeaderAvatar?.() || renderDefaultAvatar()}</Box>
        <Flex flexGrow={1} alignItems="center" gap="16px">
          <Box>
            <styled.Title>{name}</styled.Title>
            {contact.isSelf && (
              <styled.Pill text={tShared('you')} size={PillSize.Small} />
            )}
          </Box>
        </Flex>
        <Box mt="12px">{renderHeaderAction?.()}</Box>
      </Flex>
      <AddressMap
        location={contact.location}
        locationAccuracy={contact.locationAccuracy}
      />

      {contact.contactType === ContactType.BUSINESS ? (
        <BusinessContactDetails
          contact={contact}
          canEditCategories={canEditCategories}
          useCategoryPills={useCategoryPills}
        />
      ) : (
        <PeopleContactDetails
          contact={contact}
          canEditCategories={canEditCategories}
          useCategoryPills={useCategoryPills}
        />
      )}
    </>
  )
}
