import { createContext, ReactNode, useContext, useMemo } from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom-v5-compat'
import { useLocationState } from '@npco/utils-routing'
import { useModalState } from '@npco/zeller-design-system'

import { ROOT, ROUTE_PARAM_NAME } from 'const/routes'

import { TIME_FILTER_ENABLED_COMPONENTS } from '../../../../types/picker'
import { TransactionsStateSchema } from '../../Transactions.types'
import { useTransactionFilters } from './hooks/useTransactionsFilters'

export type TransactionsContextType = {
  closeStatementModal: ReturnType<typeof useModalState>['closeModal']
  filters: ReturnType<typeof useTransactionFilters>['filters']
  filtersInitialValues: ReturnType<
    typeof useTransactionFilters
  >['filtersInitialValues']
  handleToggleFilters: ReturnType<
    typeof useTransactionFilters
  >['handleToggleFilters']
  isDateRangeAppropriate: ReturnType<
    typeof useTransactionFilters
  >['isDateRangeAppropriate']
  isDefaultFilters: ReturnType<typeof useTransactionFilters>['isDefaultFilters']
  isFiltersDisabled: ReturnType<
    typeof useTransactionFilters
  >['isFiltersDisabled']
  isFiltersSelected: boolean
  isFilteringByRefunded: ReturnType<
    typeof useTransactionFilters
  >['isFilteringByRefunded']
  isFiltersVisible: ReturnType<typeof useTransactionFilters>['isFiltersVisible']
  isStatementModalOpen: ReturnType<typeof useModalState>['isModalOpen']
  openStatementModal: ReturnType<typeof useModalState>['openModal']
  splitPaymentId: string
  widgetKey: TIME_FILTER_ENABLED_COMPONENTS
}

export const TransactionsContext = createContext<
  TransactionsContextType | undefined
>(undefined)

TransactionsContext.displayName = 'Transactions Context'

interface TransactionsContextProps {
  children: ReactNode | ReactNode[]
}

export const TransactionsProvider = ({
  children,
}: TransactionsContextProps) => {
  const { pathname } = useLocation()

  const state = useLocationState(TransactionsStateSchema)

  const { splitPaymentId = '' } = useParams<{
    [ROUTE_PARAM_NAME.PORTAL_SPLIT_PAYMENT_ID]: string
  }>()

  const {
    isModalOpen: isStatementModalOpen,
    openModal: openStatementModal,
    closeModal: closeStatementModal,
  } = useModalState()

  const isOnTransactionsPath = Boolean(
    matchPath(ROOT.ORGS.ORG().TRANSACTIONS.path, pathname)
  )

  const {
    filters,
    filtersInitialValues,
    handleToggleFilters,
    isDateRangeAppropriate,
    isDefaultFilters,
    isFilteringByRefunded,
    isFiltersDisabled,
    isFiltersVisible,
    widgetKey,
  } = useTransactionFilters({
    defaultSources: state?.sources,
    defaultTypes: state?.types,
    isOnTransactionsPath,
    splitPaymentId,
  })

  const isFiltersSelected = !isDefaultFilters || !!splitPaymentId

  const value = useMemo(
    () =>
      ({
        closeStatementModal,
        filters,
        filtersInitialValues,
        handleToggleFilters,
        isDateRangeAppropriate,
        isDefaultFilters,
        isFiltersDisabled,
        isFiltersSelected,
        isFilteringByRefunded,
        isFiltersVisible,
        isStatementModalOpen,
        openStatementModal,
        splitPaymentId,
        widgetKey,
      } satisfies TransactionsContextType),
    [
      closeStatementModal,
      filters,
      filtersInitialValues,
      handleToggleFilters,
      isDateRangeAppropriate,
      isDefaultFilters,
      isFiltersDisabled,
      isFilteringByRefunded,
      isFiltersSelected,
      isFiltersVisible,
      isStatementModalOpen,
      openStatementModal,
      splitPaymentId,
      widgetKey,
    ]
  )

  return (
    <TransactionsContext.Provider value={value}>
      {children}
    </TransactionsContext.Provider>
  )
}

export const useTransactionsContext = () => {
  const context = useContext(TransactionsContext)

  if (!context) {
    throw new Error(
      'useTransactionsContext must be used within TransactionsContext'
    )
  }

  return context
}
