import { PageTemplate, Tabs } from '@npco/zeller-design-system'
import { useExpensesTabs } from 'features/Expenses/ExpensesLayout/ExpensesHeaderSecondaryRow/useExpensesTabs/useExpensesTabs'

export const ExpensesHeaderSecondaryRow = () => {
  const { tabs, currentTab } = useExpensesTabs()
  return (
    <PageTemplate.HeaderSecondaryRow>
      <Tabs currentTab={currentTab} expandOnMobile={false}>
        {tabs.map((tab) => (
          <Tabs.Item key={tab.value} {...tab} />
        ))}
      </Tabs>
    </PageTemplate.HeaderSecondaryRow>
  )
}
