import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  advertising: 'Advertising',
  bankFees: 'Bank Fees',
  cleaning: 'Cleaning',
  commission: 'Commission',
  computerEquipment: 'Computer Equipment',
  consultingAccounting: 'Consulting & Accounting',
  costOfGoodsSold: 'Cost of Goods Sold',
  entertainment: 'Entertainment',
  freightCourier: 'Freight & Courier',
  generalExpenses: 'General Expenses',
  incomeTaxExpense: 'Income Tax Expense',
  insurance: 'Insurance',
  interestExpense: 'Interest Expense',
  legalExpenses: 'Legal Expenses',
  lightPowerHeating: 'Light, Power, Heating',
  motorVehicleExpenses: 'Motor Vehicle Expenses',
  officeEquipment: 'Office Equipment',
  officeExpenses: 'Office Expenses',
  printingStationery: 'Printing & Stationery',
  purchases: 'Purchases',
  rent: 'Rent',
  subscriptions: 'Subscriptions',
  superannuation: 'Superannuation',
  telephoneInternet: 'Telephone & Internet',
  travelInternational: 'Travel International',
  travelNational: 'Travel National',
  wageSalaries: 'Wages & Salaries',
  uncategorised: 'Uncategorised',
  other: 'Other',
})
