import { useMemo } from 'react'
import { useLoggedInUser } from '@npco/mp-utils-logged-in-user'

import { routeCanBeAccessed } from 'utils/routeHasPermission'

import { SubItem } from '../../Sidebar.types'

export const useParseSubitems = (subItems: SubItem[]) => {
  const { userRole: currentRole } = useLoggedInUser()

  return useMemo(
    () =>
      subItems.filter((item) => {
        const { isVisible, subitemPath } = item

        const isAccessible = routeCanBeAccessed(subitemPath, currentRole)
        const isSubItemVisible = isVisible === undefined || isVisible

        return isAccessible && isSubItemVisible
      }),
    [currentRole, subItems]
  )
}
