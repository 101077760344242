import { Box } from '@npco/zeller-design-system'

interface ChartXAxisTickProps {
  children?: React.ReactNode
  x: number
  y: number
  payload: { offset: number }
  leftNavButton?: React.ReactNode
  rightNavButton?: React.ReactNode
}

export const ChartXAxisTick = ({
  children,
  x,
  y,
  payload,
  leftNavButton,
  rightNavButton,
}: ChartXAxisTickProps) => {
  return (
    <foreignObject
      height={36}
      width={30}
      x={x - 15}
      y={y}
      style={{
        fontSize: '12px',
        lineHeight: '18px',
        overflow: leftNavButton || rightNavButton ? 'visible' : 'hidden',
      }}
    >
      {leftNavButton && (
        <Box position="absolute" left={-payload.offset - 8} top={-2}>
          {leftNavButton}
        </Box>
      )}
      {children}
      {rightNavButton && (
        <Box position="absolute" right={-payload.offset - 8} top={-2}>
          {rightNavButton}
        </Box>
      )}
    </foreignObject>
  )
}
