import {
  DebitCardColour,
  DebitCardFormat,
  VelocityWindowEnum,
} from '@npco/mp-gql-types'
import { z } from 'zod'

export enum ReportLostAndReplaceCardStage {
  ReportLostCard = 'ReportLostCard',
  ReportLostPhysicalCardError = 'ReportLostPhysicalCardError',
  ReportLostVirtualCardError = 'ReportLostVirtualCardError',
  ReplaceCardAddress = 'ReplaceCardAddress',
  ReplaceCardErrorAdmin = 'ReplaceCardErrorAdmin',
  ReplaceCardErrorManager = 'ReplaceCardErrorManager',
}

export enum AddressPage {
  PredefinedAddress = 'PredefinedAddress',
  CustomAddress = 'CustomAddress',
}

const AddressSchema = z.object({
  street1: z.string().nullable(),
  street2: z.string().nullable(),
  suburb: z.string().nullable(),
  state: z.string().nullable(),
  postcode: z.string().nullable(),
  country: z.string().nullable(),
})

export type Address = z.infer<typeof AddressSchema>

const MoneyInputSchema = z.object({
  currency: z.string(),
  value: z.string(),
})

const VelocityControlSchema = z.object({
  amountLimit: MoneyInputSchema,
  maxTransactionValue: MoneyInputSchema,
  timeZone: z.string().optional(),
  velocityWindow: z.nativeEnum(VelocityWindowEnum),
})

export type VelocityControl = z.infer<typeof VelocityControlSchema>

const ReportLostCardStateSchema = z.object({
  stage: z.literal(ReportLostAndReplaceCardStage.ReportLostCard),
  cardId: z.string(),
  isAdmin: z.boolean(),
  cardColor: z.nativeEnum(DebitCardColour),
  cardFormat: z.nativeEnum(DebitCardFormat),
  isCardOwner: z.boolean(),
  cardOwnerFirstName: z.string().nullable().optional(),
  velocityControl: VelocityControlSchema.nullable(),
  entityAddressTimezone: z.any(),
})

export type ReportLostCardState = z.infer<typeof ReportLostCardStateSchema>

const ReplaceCardAddressStateSchema = z.object({
  stage: z.literal(ReportLostAndReplaceCardStage.ReplaceCardAddress),
  cardId: z.string(),
  isAdmin: z.boolean(),
  cardColor: z.nativeEnum(DebitCardColour),
  cardFormat: z.nativeEnum(DebitCardFormat),
  velocityControl: VelocityControlSchema.nullable(),
  entityAddressTimezone: z.any(),
  address: AddressSchema.optional(),
})

export type ReplaceCardAddressState = z.infer<
  typeof ReplaceCardAddressStateSchema
>

const ReportLostPhysicalCardErrorStateSchema = z.object({
  stage: z.literal(ReportLostAndReplaceCardStage.ReportLostPhysicalCardError),
  cardId: z.string(),
  isAdmin: z.boolean(),
  cardColor: z.nativeEnum(DebitCardColour),
  address: AddressSchema,
  velocityControl: VelocityControlSchema.nullable(),
  entityAddressTimezone: z.any(),
})

const ReportLostVirtualCardErrorStateSchema = z.object({
  stage: z.literal(ReportLostAndReplaceCardStage.ReportLostVirtualCardError),
  cardId: z.string(),
  isAdmin: z.boolean(),
  cardColor: z.nativeEnum(DebitCardColour),
  isCardOwner: z.boolean(),
  cardOwnerFirstName: z.string().nullable().optional(),
  velocityControl: VelocityControlSchema.nullable(),
  entityAddressTimezone: z.any(),
})

const ReplaceCardErrorAdminStateSchema = z.object({
  stage: z.literal(ReportLostAndReplaceCardStage.ReplaceCardErrorAdmin),
  velocityControl: VelocityControlSchema.nullable(),
  entityAddressTimezone: z.any(),
})

const ReplaceCardErrorManagerStateSchema = z.object({
  stage: z.literal(ReportLostAndReplaceCardStage.ReplaceCardErrorManager),
  velocityControl: VelocityControlSchema.nullable(),
  entityAddressTimezone: z.any(),
})

const ReportLostAndReplaceCardErrorStateSchema = z.union([
  ReportLostPhysicalCardErrorStateSchema,
  ReportLostVirtualCardErrorStateSchema,
  ReplaceCardErrorAdminStateSchema,
  ReplaceCardErrorManagerStateSchema,
])

export type ReportLostAndReplaceCardErrorState = z.infer<
  typeof ReportLostAndReplaceCardErrorStateSchema
>

const ReportLostAndReplaceCardStateSchema = z.union([
  ReportLostCardStateSchema,
  ReplaceCardAddressStateSchema,
  ReportLostAndReplaceCardErrorStateSchema,
])

export type ReportLostAndReplaceCardState = z.infer<
  typeof ReportLostAndReplaceCardStateSchema
>

export const ReportLostAndReplaceCardLocationStateSchema = z.object({
  ReportLostAndReplaceCardModal: ReportLostAndReplaceCardStateSchema,
})
