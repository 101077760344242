import { Link } from 'react-router-dom'
import { BodyLarge, Flex } from '@npco/zeller-design-system'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.BLUE_1000};
  text-decoration: none;
`

export const StyledText = styled(BodyLarge)`
  text-align: center;
  color: ${({ theme }) => theme.colors.GREY_250};
`

export const StyledIconBox = styled(Flex)<{ backgroundColor: string }>`
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
`
